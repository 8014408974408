import { NgModule } from '@angular/core';
import { PrivacyResponsibility } from './diagram/resposibility/privacy-responsibility.svc';
import { RolePermissionResponsibility } from './diagram/resposibility/role-permission-responsibility.svc';
import { RolePermService } from './permission/role/role-perm-service.svc';
import { CollabListItem } from './view/share/collab-list-item/collab-list-item.cmp';
import { CollabTypeDropdown } from './view/share/collab-type-dropdown.cmp';
import { FluxCoreUI } from 'flux-core/src/ui';
import { CommonModule } from '@angular/common';
import { FluxCoreDirectives } from 'flux-core';
import { DiagramInfoLocator } from './diagram/locator/diagram-info-locator';
import { ProjectLocator } from './project/locator/project-locator';
import { DiagramSnapshotService } from './diagram/diagram-snapshot.svc';
import { UserListItem } from './view/share/user-list-item/user-list-item.cmp';
import { AlterCollabDropDown } from './view/share/alter-collab-dropdown.cmp';
import { NoUserListItem } from './view/share/no-user-list-item.cmp';
import { MicrosoftTeamsUserResponsibility } from './diagram/resposibility/microsoft-teams-user-responsibility.svc';
import { TeamGroupListItem } from './view/share/team-group-list-item/team-group-list-item.cmp';
import { GroupCollabListItem } from './view/share/group-collab-list-item/group-collab-list-item.cmp';
@NgModule({
    imports: [
        CommonModule, FluxCoreUI, FluxCoreDirectives,
    ],
    providers: [
        MicrosoftTeamsUserResponsibility,
        { provide: 'MicrosoftTeamsUserResponsibility', useExisting: MicrosoftTeamsUserResponsibility },
        PrivacyResponsibility,
        { provide: 'PrivacyResponsibility', useExisting: PrivacyResponsibility },
        RolePermissionResponsibility,
        { provide: 'RolePermissionResponsibility', useExisting: RolePermissionResponsibility },
        RolePermService,
        DiagramInfoLocator,
        ProjectLocator,
        DiagramSnapshotService,
    ],
    declarations: [
        CollabListItem,
        GroupCollabListItem,
        UserListItem,
        TeamGroupListItem,
        NoUserListItem,
        CollabTypeDropdown,
        AlterCollabDropDown,
    ],
    exports: [
        CollabListItem,
        GroupCollabListItem,
        UserListItem,
        TeamGroupListItem,
        NoUserListItem,
        CollabTypeDropdown,
        AlterCollabDropDown,
    ],
    entryComponents: [],
})
export class FluxDiagram {
}
