import { IShapeNode } from '../svg-node/shape-node.i';
import { IStyleDefinition } from 'flux-definition';

/**
 * Gradient usage types.
 * Gradient can be used as a fill for a shape or
 * for a stroke
 */
export enum GradientUsageType {
    fill = 'fill',
    stroke = 'stroke',
}

/**
 * SVG gradient types
 */
export enum GradientType {
    linearGradient = 'linearGradient',
    radialGradient = 'radialGradient',
}

/**
 * This defines the structure of a canvas graphics instruction when transleted from svg.
 * Instruction parameter refers to the name of the instruction, for example:
 * - moveTo
 * - lineTo
 * - drawRect
 * Params holds all the parameters needed for the instruction to draw on canvas.
 */
export interface ICanvasInstruction {
    instruction: string;
    params: any[];
}

/**
 * Internal structure to hold the generated style definitions.
 */
export interface IComputedStyleDefition {
    id: string;
    def: IStyleDefinition;
}

/**
 * Represent gradient factory output when a gradient is processed.
 */
export interface IGradientInstruction {
    gradient: ICanvasInstruction;
    styleDefinitions: IComputedStyleDefition[];
}

/**
 * Define the construct of a Canvas instruction factory. Canvas instruction factories
 * implement logic to translate svg instructions of a specific element to graphics instructions.
 * For each supported svg element type, an instruction factory must be created. For example:
 * - LineInstrcutionFactory --> translate svg line element instructions to graphics
 * - PathInstructionFactory --> translate svg path element instructions to graphics
 */
export interface ICanvasInstructionFactory {
    svgElementName: string;
    createInstruction( data: IShapeNode ): ICanvasInstruction[];
}

/**
 * Define a gradient factory implementation.
 */
export interface IGradientInstructionFactory {
    svgElementName: string;
    createInstruction( data: IShapeNode, gradientUsageType: GradientUsageType ): IGradientInstruction;
}
