import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the COLOR data type. Data items in shape models with the COLOR
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class ColorDataItem extends AbstractDataItem<DataType.COLOR> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof ColorDataItem.prototype.value ): IValidationError {
        if ( value === undefined ) {
            return null;
        }
        if ( typeof value === 'string' ) {
            // TODO: check whether the string value is a valid color
            return null;
        }
        return { message: `"${value}" is not a valid color` };
    }
}
