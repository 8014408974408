import { of, Observable } from 'rxjs';
import { ElementRef, Input, Injector } from '@angular/core';
import { Component, ViewChild, ComponentRef, ViewContainerRef, ComponentFactory,
    ComponentFactoryResolver } from '@angular/core';
import { Animation } from '../animation';

/**
 * onViewReRender is called right after the change detector is attcached
 * to the previous view
 */
export interface IViewStackItem {
    onViewReRender();
}

@Component({
    selector: 'view-stack',
    template: `<div #componentContainer></div>
                <floating-button class="flt-btn-back" *ngIf="size > 1" buttonPosition="{{buttonPosition}}"
                    buttonIcon="{{buttonIcon}}" (click)="pop()"></floating-button>`,
})

/**
 * This is a component that acts as a stack of views.
 * Multiple views can be added to this component. The last added view will
 * be the view that is rendered. If a view is removed, the next view on stack
 * will be rendered.
 *
 * Note: Componenet types added to the view stack MUST be added as entry components in the
 * module that uses the stack. This is so that they can resolved by the ComponentFactoryResolver.
 *
 * @author Ramishka
 * @since 2017-06-25
 */
export class ViewStack {

    /**
     * Define the floating button positon
     */
    @Input()
    public buttonPosition: string;

    /**
     * Define the floating button icon
     */
    @Input()
    public buttonIcon: string;

    /**
     * This holds a reference to the template element where the topmost view
     * in the stack will be added to.
     */
    @ViewChild( 'componentContainer', { read: ViewContainerRef })
    protected componentContainer: ViewContainerRef;

    /**
     * This is an array that contains a list of component instances that are
     * currently added to the stack.
     */
    protected components: Array<ComponentRef<any>>;

    /**
     * This holds the references of the component that is currently being
     * rendered in the view stack.
     */
    protected currentComponent: ComponentRef<any>;

    /**
     * Define the tranistion that animate when the view enters
     */
    protected _enterTransition: Animation;

    /**
     * Define the transition that animate when the view is get removed
     */
    protected _leaveTransition: Animation;

    /**
     * Constructor. Initializes the components array.
     * @param componentFactoryResolver - Used to programatically create a new instance
     *      of a component type.
     * @param injector - Stored injector
     */
    constructor( protected componentFactoryResolver: ComponentFactoryResolver,
                 protected injector: Injector ) {
        this.components = new Array<ComponentRef<any>>();
    }

    @Input()
    public set enterTransition( val: Animation ) {
        this._enterTransition = val;
    }

    @Input()
    public set leaveTransition( val: Animation ) {
        this._leaveTransition = val;
    }

    /**
     * Returns the instance of the component at the top of the stack.
     * If no components are in the stack, undefined will be returned.
     */
    public get top(): any {
        if ( this.currentComponent ) {
            return this.currentComponent.instance;
        }
    }

    /**
     * Returns the size of the view stack.
     */
    public get size(): number {
        return this.components.length;
    }

    /**
     * Create and instance of a given component type and adds it to the the view stack.
     * It will set all input values passed in to the newly created component instance.
     * The component instance will be rendered after creation.
     * If a component is already being rendered, its view and change detector will be
     * detached from the view stack, but the view will not be destroyed.
     * @param type - The type of the component to create.
     *      Note: Componenet types added to the view stack MUST be added as entry components in the
     *      module that uses the stack. This is so that they can resolved by the ComponentFactoryResolver.
     * @param inputs - An object with all the inputs that should be set to the
     *      component instance. Object keys should have the same name as the input field.
     *      Example:
     *          const inputs: any = {};
     *          inputs.diagramId = diagramId;
     *      The parent instance can be set to a component via this same mechanism. i.e.
     *          inputs.parent = this;
     */
    public push( type: any, inputs?: { [inputName: string]: any }, transition?: Animation ) {
        const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory( type );
        const component: ComponentRef<any> = componentFactory.create ( this.injector );
        this.setInputs( component.instance, inputs );

        this.components.push( component );

        if ( !transition ) {
            transition = this._enterTransition;
        }
        this.setTransition( component.location, transition );

        this.insertCurrent( component );
        component.changeDetectorRef.detectChanges();

        this.startAnimation( transition ).subscribe({
            complete: () => this.detach( 0 ),
        });
    }

    /**
     * Removes the last added component from the stack and destroys its view.
     * If any other components are in the stack, the next-to-last added component
     * will be rendered in the removed components place.
     */
    public pop( transition?: Animation ) {
        if ( this.components.length > 1 ) {
            const nextComponent: ComponentRef<any> = this.components[this.components.length - 2];
            const component: ComponentRef<any> = this.components[this.components.length - 1];

            this.insertCurrent( nextComponent, 0 );
            if ( !transition ) {
                transition = this._leaveTransition;
            }
            this.setTransition( component.location, transition );
            this.startAnimation( transition ).subscribe({
                complete: () => {
                    this.componentContainer.remove();
                    this.components.pop();
                    nextComponent.changeDetectorRef.detectChanges();
                    nextComponent.instance.onViewReRender();
                },
            });
        } else {
            throw new Error( 'Can\'t pop last component in the stack' );
        }
    }

    /**
     * This is an accessor function that allows retrieving a component
     * instance based on its index in the stack.
     * @param index - index of component instane to retrieve
     */
    public getByIndex( index: number ): any {
        if ( this.components.length > index ) {
            return this.components[index].instance;
        }
    }

    /**
     * This will keep the first component that pushed to the view stack
     * and removes other components from stack
     */
    public popUntilLast() {
        while ( this.size > 1 ) {
            this.pop();
        }
    }

    /**
     * Sets the input values that are passed in, to the inputs of the component.
     * @param componentInstance - Instance of the component to which the inputs will be set to
     * @param inputs - Object with inputs and their values
     */
    protected setInputs( componentInstance: any, inputs: { [inputName: string]: any }) {
        if ( inputs ) {
            Object.keys( inputs ).forEach( input => {
                componentInstance[input] = inputs[input];
            });
        }
    }


    /**
     * Renders the given components view.
     * @param component - the component to be rendered
     * @param index - Inserts a view into the container at the specified index.
     */
    protected insertCurrent( component: ComponentRef<any>, index?: number ) {
        this.currentComponent = component;
        this.componentContainer.insert( component.hostView, index );
    }

    /**
     * Renders the given component view and detach the last added view when the observable
     * resolves.
     */
    protected detach( index: number ) {
        if ( this.componentContainer.length > index + 1 ) {
            this.componentContainer.detach( index );
        }
    }

    /**
     * This applies the transition to the element and returns an observable
     */
    protected startAnimation( transition: Animation ): Observable<any> {
        if ( transition ) {
            return transition.start();
        }
        return of();
    }

    /**
     * Sets the element where the transition is applied
     */
    protected setTransition( element: ElementRef, transition: Animation ) {
        if ( transition ) {
            transition.element = element.nativeElement.firstElementChild;
        }
    }
}
