import { IPoint, IPoint2D } from 'flux-definition';
import { AbstractConnector } from './connector-abstract';
import { IConnectorPoint, IConnectorBump } from '../../../../shape/model/connector-data-mdl';

/**
 * This class contains the capability to draw a smooth angled connector
 * using the coordinate data stored into the model.
 *
 * Smooth Angled connectors are drawn similar to angled connectors but
 * the turns are smoothed out. See ConnectorAngled for more info.
 *
 */
export class ConnectorSmoothAngled extends AbstractConnector {
    /**
     * The curve radius.
     */
    public radius = 10;

    /**
     * Constructor
     */
    public constructor() {
        super()/* istanbul ignore next */;
    }

    /**
     * Draws the smooth angled connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        let head = this.geometry.createPoint( points[0].x, points[0].y );
        this.moveTo( head );
        // connect all other points except the last
        for ( let i = 1; i < points.length; ++i ) {
            const { x, y, c1, bumps } = points[i];
            const point = this.geometry.createPoint( x, y );
            if ( !c1 ) {
                head = this.lineTo( point, bumps && bumps[0]);
                continue;
            }
            const ctrl = this.geometry.createPoint( c1.x, c1.y );
            // NOTE: if the next segment has a bump too close to starting point
            //       point should be replaced with the point where the bump ends.
            //       On all other cases, end the segment on given point.
            const next = this.nextBumpStart( point, points[ i + 1 ]) || point;
            head = this.drawAngleSegment( head, ctrl, next, bumps );
        }
    }

    /**
     * Returns the point where the next segment first bump starts
     * only when it has to start before the line starting point.
     * This is possible only because angled connector path points are
     * always in the middle of the line part of the path.
     */
    private nextBumpStart( head: IPoint, next: IConnectorPoint ): IPoint {
        if ( !next || !next.bumps || !next.bumps[0] || !next.bumps[0].length ) {
            return null;
        }
        const bump = next.bumps[0][0];
        const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
        if ( head.distanceTo( bump ) > radius ) {
            return null;
        }
        const line = this.geometry.createLine( head, bump );
        return line.splitByLength( radius, false );
    }

    /**
     * Draws a smooth angle connector segment.
     */
    private drawAngleSegment( head: IPoint, ctrl: IPoint, next: IPoint, bumps?: IPoint2D[][]): IPoint {
        const prevToCtrl = head.distanceTo( ctrl );
        const ctrlToNext = ctrl.distanceTo( next );
        if ( prevToCtrl >= this.radius && ctrlToNext >= this.radius ) {
            head = this.drawAngleSegmentNormal( head, ctrl, next, bumps, prevToCtrl, ctrlToNext );
        } else if ( prevToCtrl >= this.radius ) {
            head = this.drawAngleSegmentCtrlNearEnd( head, ctrl, next, bumps, prevToCtrl, ctrlToNext );
        } else if ( ctrlToNext >= this.radius ) {
            head = this.drawAngleSegmentCtrlNearStart( head, ctrl, next, bumps, prevToCtrl, ctrlToNext );
        } else {
            head = this.drawAngleSegmentCtrlNearStartAndEnd( head, ctrl, next, bumps, prevToCtrl, ctrlToNext );
        }
        return next;
    }

    /**
     * Case 1: This is the normal case with enough space to draw the curve.
     * Draw a line to curve start point, then the curve and finally another
     * line from curve end point to the next point.
     */
    private drawAngleSegmentNormal(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const lineEnd = this.geometry.createLine( ctrl, head )
            .split( this.radius / prevToCtrl );
        const ctrlNext = this.geometry.createLine( ctrl, next );
        const curveEnd = ctrlNext.split( this.radius / ctrlToNext );
        head = this.lineTo( lineEnd, bumps && bumps[0]);
        if ( !bumps || !bumps[1] || !bumps[1].length ) {
            head = this.quadraticCurveTo( ctrl, curveEnd );
            head = this.lineTo( next );
        } else {
            const bump: IConnectorBump = bumps[1][0];
            const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
            const distCurveEnd = ctrl.distanceTo( curveEnd );
            const distBumpStart = ctrl.distanceTo( bump ) - radius;
            if ( distCurveEnd > distBumpStart ) {
                const bumpStart = ctrlNext.splitByLength( distBumpStart, true );
                head = this.quadraticCurveTo( ctrl, bumpStart );
                head = this.lineTo( next, bumps && bumps[1]);
            } else {
                head = this.quadraticCurveTo( ctrl, curveEnd );
                head = this.lineTo( next, bumps && bumps[1]);
            }
        }
        return head;
    }

    /**
     * Case 2: The distance between the control point to the end point is
     * less than the radius. Draw a line to the curve starting point and
     * draw the curve to the next point. The control point also has to
     * change to make the curve smoother.
     */
    private drawAngleSegmentCtrlNearEnd(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const lineEnd = this.geometry.createLine( ctrl, head )
            .split( this.radius / prevToCtrl );
        const newCtrl = this.geometry.createLine( ctrl, lineEnd )
            .split(( this.radius - ctrlToNext ) / this.radius );
        head = this.lineTo( lineEnd, bumps && bumps[0]);
        head = this.quadraticCurveTo( newCtrl, next );
        return head;
    }

    /**
     * Case 3: The distance between the control point and the starting point
     * is less than the radius. Draw the curve first and then draw a line to
     * the end point. The control point of the curve has to change to make
     * the curve smoother.
     */
    private drawAngleSegmentCtrlNearStart(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const ctrlNext = this.geometry.createLine( ctrl, next );
        const curveEnd = ctrlNext.split( this.radius / ctrlToNext );
        const newCtrl = this.geometry.createLine( ctrl, curveEnd )
            .split(( this.radius - prevToCtrl ) / this.radius );
        if ( !bumps || !bumps[1] || !bumps[1].length ) {
            head = this.quadraticCurveTo( newCtrl, curveEnd );
            head = this.lineTo( next );
        } else {
            const bump: IConnectorBump = bumps[1][0];
            const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
            const distCurveEnd = ctrl.distanceTo( curveEnd );
            const distBumpStart = ctrl.distanceTo( bump ) - radius;
            if ( distCurveEnd > distBumpStart ) {
                const bumpStart = ctrlNext.splitByLength( distBumpStart, true );
                head = this.quadraticCurveTo( newCtrl, bumpStart );
                head = this.lineTo( next, bumps && bumps[1]);
            } else {
                head = this.quadraticCurveTo( newCtrl, curveEnd );
                head = this.lineTo( next, bumps && bumps[1]);
            }
        }
        return head;
    }

    /**
     * Case 4: The distance between the control point and the starting point
     * and also the distance between the control point and the end point are
     * less than the radius. Draw a curve from start point to the end point.
     * TODO: Calculate a better control point to make the curve smoother.
     */
    private drawAngleSegmentCtrlNearStartAndEnd(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        head = this.quadraticCurveTo( ctrl, next );
        return head;
    }
}

Object.defineProperty( ConnectorSmoothAngled, 'name', {
    value: 'ConnectorSmoothAngled',
});
