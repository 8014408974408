/**
 *  This is an enum that indicates all command scenarios available in Electron.
 *  The same command can be executed in multiple scenarios. For example:
 *   During a normal execute
 *   When performing an undo or a redo action
 *   When performing a collab action
 *  In each of the above scenarios, the command exection can have differences.
 *
 * @author Ramishka
 * @since 2017-02-14
 */
export enum CommandScenario {

    /**
     * The command is used to perform a regular execute action
     */
    EXECUTE = 'Execute',

    /**
     * An action is being undone using the command
     */
    UNDO = 'Undo',

    /**
     * The command is used to perform a redo action
     */
    REDO = 'Redo',

    /**
     * The command is executing as part of a relatime collaboration action
     */
    COLLAB = 'Collab',

    /**
     * The command is being used to preview an action
     */
    PREVIEW = 'Preview',

    /**
     * The command is used to perform a offline execute action
     */
    EXECUTE_OFFLINE = 'Execute_Offline',

}
