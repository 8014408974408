import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';

/**
 * class RectCanvasInstructionFactory
 * Generate canvas instruction for svg rectangle element instructions
 */
export class RectCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'rect';

    /**
     * Return rectangle canvas instruction for given rectangle shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        const x = data.data.x ? +data.data.x : 0;
        const y = data.data.y ? +data.data.y : 0;
        const w = +data.data.width;
        const h = +data.data.height;
        if ( data.transform ) {
            const p1 = data.transform.transform( x, y );
            const p2 = data.transform.transform( x + w, y );
            const p3 = data.transform.transform( x + w, y + h );
            const p4 = data.transform.transform( x, y + h );

            return [
                {
                    instruction: 'moveTo',
                    params: [ `${this.sw} * ${p1.x} + ${this.xo}`, `${this.sh} * ${p1.y} + ${this.yo}` ],
                },
                {
                    instruction: 'lineTo',
                    params: [ `${this.sw} * ${p2.x} + ${this.xo}`, `${this.sh} * ${p2.y} + ${this.yo}` ],
                },
                {
                    instruction: 'lineTo',
                    params: [ `${this.sw} * ${p3.x} + ${this.xo}`, `${this.sh} * ${p3.y} + ${this.yo}` ],
                },
                {
                    instruction: 'lineTo',
                    params: [ `${this.sw} * ${p4.x} + ${this.xo}`, `${this.sh} * ${p4.y} + ${this.yo}` ],
                },
                { instruction: 'closePath', params: []},
            ];
        }
        return [{
            instruction: 'drawRect',
            params: [
                `${this.sw} * ${x} + ${this.xo}`,
                `${this.sh} * ${y} + ${this.yo}`,
                `${this.sw} * ${w} + ${this.xo}`,
                `${this.sh} * ${h} + ${this.yo}`,
            ],
        }];
    }

}
