import { CollaboratorType, CollabModel } from './../../collab/model/collaborator.mdl';
import { StateService } from 'flux-core';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { DataStore } from 'flux-store';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { orderBy, find } from 'lodash';
import { UserModel, UserLocator, PrivacyLevel } from 'flux-user';
import { ProjectModel } from '../model/project.mdl';

/**
 * ProjectLocator provides information of
 * projects to different parts of the application
 */
@Injectable()
export class ProjectLocator {

    constructor( protected dataStore: DataStore,
                 protected state: StateService<any, any>,
                 protected userLocator: UserLocator ) {}

    /**
     * This function returns an array of Project for the given role
     * Also this function returns the home project as the first item and
     * sort the other projects by lastUpdated
     * @param role  Optional param to get the projects that current user has 'role' ( Owenr, Editor, Reviewer ) access
     * @return Observable<Array<DiagramInfoModel>>
     */
    public getProjects( role?: CollaboratorType ): Observable<Array<ProjectModel>> {
        return combineLatest ([
            this.dataStore.find( ProjectModel, {}),
            this.userLocator.getUserData().pipe( filter( u => !!u )),
        ]).pipe( map(([ projects, user ]) => this.sortProjectByUserRole( projects, user, role )));
    }

    /**
     * This function returns an array of Project from state for the given role
     * Also this function returns the home project as the first item and
     * sort the other projects by lastUpdated
     * @param role  Optional param to get the projects that current user has 'role' ( Owenr, Editor, Reviewer ) access
     * @return Observable<Array<DiagramInfoModel>>
     */
    public getProjectsFromState( role?: CollaboratorType ): Observable<Array<ProjectModel>> {
        return this.userLocator.getUserData().pipe(
            filter( u => !!u ),
            map( user => {
                const projects: ProjectModel[] = Object.values( this.state.get( 'ProjectMap' ));
                return this.sortProjectByUserRole( projects, user, role );
            }),
        );
    }

    /**
     * This function is to check if the given project has diagrams which are drawn
     * by other collabs
     * @param projectId string
     * @return Observable<boolean>
     */
    public hasNotOwnedDocuments( projectId: string ): Observable<boolean> {
        return combineLatest ([
            this.state.changes( 'ProjectDiagrams' ).pipe(
                map( projectDiagrams => {
                    let diags = projectDiagrams[ projectId ];
                    diags = !!diags ? diags : [];
                    return diags;
                }),
            ),
            this.userLocator.getUserData(),
        ]).pipe(
            take( 1 ),
            map(([ diagrams, user ]) => !diagrams.every( d => {
                const owner = find( d.collabs, { role: CollaboratorType.OWNER });
                return owner.id === user.id;
            })),
        );
    }

    /**
     * Check if the storage contains any project
     */
    public hasStoredProjects(): Observable<boolean> {
        return this.dataStore
            .findOneRaw( ProjectModel ).pipe(
                take( 1 ),
                map( project => !!project ),
            );
    }

    /**
     * This function returns the matching ProjectModel for a given project id.
     * @param id string
     * @return Observable<ProjectModel>
     */
    public getProject( id: string ): Observable<ProjectModel> {
        return this.dataStore
            .findOne( ProjectModel, { id })
            .pipe(
                filter( project => !!project ),
            );
    }

    /**
     * This function will emit the current project model as the current project changes.
     * @param id string
     * @return Observable<ProjectModel>
     */
    public getCurrentProjectObservable( distinct = true ): Observable<ProjectModel> {
        let changesObs = this.state.changes( 'CurrentProject' );
        if ( distinct ) {
            changesObs = changesObs.pipe( distinctUntilChanged());
        }
        return changesObs.pipe(
            switchMap( projectId => this.getProject( projectId )),
        );
    }

    /**
     * Checks if a given user is a collaborator of the diagram
     * FIXME: Ideally this method should reside in the ProjectCollabLocator or
     * something like a PrivacyService. Moving to ProjectCollabLocator creates
     * a cyclic dependency between ProjectLocator and ProjectCollabLocator.
     * @param userId - id of the user to be checked
     * @param role - optional param to check if the given user has role ( Owenr, Editor, Reviewer ) access
     * @param collabs - full collaborator list
     */
    protected isACollaborator( userId: string, collabs: CollabModel[], role?: CollaboratorType ) {
        const user = ( collabs || []).find( collab => collab.id === userId );
        if ( role !== undefined && user ) {
            return user.role <= role;
        } else {
            return !!user;
        }
    }

    private sortProjectByUserRole(
        projects: ProjectModel[], user: UserModel, role?: CollaboratorType,
    ): Array<ProjectModel> {
        const userProjects = projects.filter( project =>
            project.id === 'home' ||
                this.isACollaborator( user.id, project.collabs, role ) ||
                    ( project.privacy && project.privacy.level === PrivacyLevel.ANYONE_IN_TEAM &&
                        user.team?.id === project.teamId ));
        // FIXME : Should use sort param once it's fixed,
        // Thers's an issue with quering static data from the store
        const sorted = orderBy( userProjects, [ 'lastUpdated' ], [ 'desc' ]);
        const homeIndex = sorted.findIndex( p => p.id === 'home' );
        const home = sorted[ homeIndex ];
        sorted.splice( homeIndex, 1 );
        sorted.unshift( home );
        return sorted;
    }
}
