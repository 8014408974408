import { Injectable } from '@angular/core';
import { EMPTY, Observable, empty } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand,
    StateService, NotifierController, AbstractNotification, NotificationType } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';
import { DataStore } from 'flux-store';
import { UserModel } from 'flux-user';
import { take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

enum Role {
    Admin = 'admin',
    SuAdmin = 'suAdmin',
    Member = 'member',
}

/**
 * This comand send team invitaion using api
 * @author  Sajeeva
 * @since   2024-10-21
 */
@Injectable()
@Command()
export class SendTeamUserInvitaion extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor(
        protected auth: NucleusAuthentication,
        protected dataStore: DataStore,
        protected state: StateService< any, any >,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
    ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': this.auth.token,
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return empty();
    }

    public prepareData(): any {
        const emails = this.data.emails || [];
        const role = 'member';
        const members = [];

        const modelStore = this.dataStore.getModelStore( UserModel );
        return modelStore.findOne({ id: this.state.get( 'CurrentUser' ) }).pipe(
            take( 1 ),
            tap(( user: UserModel ) => {

                const planId = user.team.id;
                emails.forEach(( email: string ) => {
                    members.push({
                        email,
                        role,
                        planId,
                    });
                });

                const query = `mutation{
                    organizationAddMember(orgMember:${this.stringifyObjectArrayWithoutQuotes( members )}){
                        status, errorCode
                    }
                }`;
                this.data.query =  query ;
            }),
        );
    }

    public executeResult(): Observable<any> {
        const result = JSON.parse( this.resultData );
        if ( result.data?.organizationAddMember?.errorCode ) {
            const errorCode = result.data?.organizationAddMember?.errorCode;
            this.handleError( errorCode );
        }
        return EMPTY;
    }

    protected stringifyObjectArrayWithoutQuotes( objects: Array<Record<string, any>> ): string {
        return `[${objects.map( obj => this.stringifyWithoutQuotes( obj )).join( ', ' )}]`;
    }

    protected stringifyWithoutQuotes( obj: Record<string, any> ): string {
        const entries = Object.entries( obj ).map(([ key, value ]) => {
            if ( this.isEnumValue( value )) {
                return `${key}: ${value}`;
            } else if ( typeof value === 'string' ) {
                return `${key}: "${value}"`;
            } else if ( Array.isArray( value )) {
                const arrayValues = value.map( item =>
                typeof item === 'object' && item !== null
                    ? this.stringifyWithoutQuotes( item )
                    : typeof item === 'string'
                    ? `"${item}"`
                    : item,
                );
                return `${key}: [${arrayValues.join( ', ' )}]`;
            } else if ( typeof value === 'object' && value !== null ) {
                return `${key}: ${this.stringifyWithoutQuotes( value )}`;
            } else {
                return `${key}: ${value}`;
            }
        });

        return `{ ${entries.join( ', ' )} }`;
    }

    protected isEnumValue( value: any ): boolean {
        return typeof value === 'string' && ( value === Role.Admin || value === Role.SuAdmin || value === Role.Member );
    }

    protected handleError( errorCode: string ) {
        if ( errorCode === '4111' ) {
            const options = {
                inputs: {
                    heading: this.translate.instant( 'NOTIFICATIONS.SEND_TEAM_INVITATION.NO_SEAT.HEADING' ),
                    description: this.translate.instant( 'NOTIFICATIONS.SEND_TEAM_INVITATION.NO_SEAT.BODY' ),
                    autoDismiss: true,
                    dismissAfter: 5000,
                },
            };
            this.notifierController.show(
                'SEND_TEAM_INVITATION', AbstractNotification, NotificationType.Error, options, false );
        }
    }
}

Object.defineProperty( SendTeamUserInvitaion, 'name', {
    value: 'SendTeamUserInvitaion',
});

