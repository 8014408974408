import { Injector } from '@angular/core';

import { AbstractCommand, CommandInterfaces } from '../abstract.cmd';
import { Logger } from '../../logger/logger.svc';

/**
 * ExecutionStep is the abstract implmentation of the IExecutionStep.
 *
 * This must be extended to create ExecutionStep classes which can be added
 * to the {@link CommandExecutor}. The {@link CommandExecutor} class uses
 * a list of ExecutionStep implmentations, sequentially to complete a command's
 * functionality. See the CommandExecutor.prepareSequence method for more details.
 *
 * This pattern was created to decouple different functionality of a command into
 * pluggable components. This enables the different CommandExecutor types to use
 * different sequence of ExecutionSteps when executing a command.
 *
 * @author  hiraash
 * @since   2016-04-16
 *
 */
export class ExecutionStep {

    /**
     * This is a static method expected to be implemented by ExecutionStep
     * classes. This is a list pattern of {@link CommandInterfaces} which is compared and
     * expected to be met by the Command if this ExecutionStep must be processed. This getter
     * can return an array that has {@link CommandInterfaces} or more arrays of {@link CommandInterfaces}
     *
     * The array pattern will be matched as follows to consider this ExecutionStep for processing.
     *
     *      1) If either of the interfaces in the array are implemented by the command (OR match)
     *      2) If all of the interfaces of one of the inner arrays, are implemented by the command (AND match)
     *
     * This allows matching interfaces with AND, OR or (AND and OR) patterns. See following examples
     *
     *      a) Matches if the command is a IProjectCommand or the command is both an
     *         IDiagramCommand and IStateChangeCommand;
     *         return [ 'IProjectCommand', [ 'IDiagramCommand', 'IStateChangeCommand' ] ];
     *
     *      b) Matches if the command is both IMessageCommand and IStateChangeCommand
     *         return [ [ 'IMessageCommand', 'IStateChangeCommand' ] ];
     *
     *      c) Matches if the command is either IMessageCommand or IRestCommand or IHttpCommand;
     *         return [ 'IMessageCommand', 'IRestCommand', 'IHttpCommand' ];
     *
     *      b) Matches if the command is IMessageCommand and either of IStateChangeCommand or IModelChangeCommand.
     *         return [[ 'IMessageCommand', 'IStateChangeCommand' ], [ 'IMessageCommand', 'IModelChangeCommand' ]];
     *
     * IMPORTANT: If this static member is not implemented in a ExecutionStep class, the
     * ExecutionStep will always be processed.
     *
     * {@see AbstractCommand}
     */
    static get relatedInterfaces(): Array<CommandInterfaces | Array<CommandInterfaces>> {
        return null;
    }

    /**
     * Command instance of type {@link ICommand} or {@link AbstractCommand}
     * This is the command currently being executed and will be handled by this
     * execution step.
     */

    constructor(
        protected command: AbstractCommand,
        protected log: Logger,
        protected injector: Injector,
    ) {}

    /**
     * Indicates if the CommandExecutor is supposed to wait for the Observable to complete before
     * processing the next ExecutionStep. Only valid if the <code>process</code> method returns
     * an Observable.
     */
    public get waitOnObservable(): boolean {
        return true;
    }

    /**
     * Indicates if the resultData of the command must be emitted to the observer after
     * executing this ExecutionStep.
     */
    public get emitResult(): boolean {
        return false;
    }

    /**
     * Indicates if the execution step should be considered as an asynchronous execution step.
     * This indicator is primarily used by the command framework to understand if the command
     * sequence should wait until this step completes. The command framework waits on steps
     * that are not asynchronous.
     *
     * Not all steps that run async code should be marked as asynchronous. In cases where steps
     * are running async code that have definitive short completion times, asynchronous can be
     * set to false to ensure the queueing is not broken.
     *
     * If a step is set as asynchronous, it and another command can run concurrently. To avoid
     * race conditions, we have to make sure the rest of the command sequence would not interfere
     * with other commands. The `alter` hook in command sequences can be used to skip asynchronous
     * execution steps and continue the sequence synchronously.
     *
     */
    public get asynchronous(): boolean {
        return false;
    }

    /**
     * This is to get the name of the class represented by this
     * execution step.
     *
     * @return the name of the class
     */
    public get name(): string {
        return this.constructor.name;
    }

    /**
     * This method must implement the functionality of this execution step. This
     * simply should contain the code to process this specific execution step and return
     * whatever it is supposed to.
     *
     * If an Observable instance is returned, by this method, the <code>waitOnObservable</code>
     * should indicate if the CommandExecutor should wait for the observable to complete before
     * processing to the next step.
     */
    public process(): any {
        // ...
    }
}
