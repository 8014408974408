import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    template: `
        <button class="btn-small nu-btn-icon-text fx-left"
            [ngClass]="primary ? 'btn-primary' : 'btn-secondary'"
            data-testid="share-button"
            placement="bottom"
            tooltip
            [disabled]="disabled"
            (click)="onClick.emit()">
            <svg *ngIf="!!iconName" class="nu-icon nu-icon-med white">
                <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + iconName">
                </use>
            </svg>
            <label translate>{{label}}</label>
            <span class="tooltip-content" *ngIf="!!tooltipHeading">
                <p class="tooltip-header body">{{(tooltipHeading | async)}}</p>
                <p *ngIf="tooltipDescription !== ''" class="tooltip-body">{{(tooltipDescription | async)}}</p>
            </span>
        </button>
    `,
    selector: 'generic-button',
    styleUrls: [ './generic-button.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class GenericButton {
    @Input()
    public label: string;

    @Input()
    public iconName: string;

    @Input()
    public tooltipHeading: Observable<string>;

    @Input()
    public tooltipDescription: Observable<string>;

    @Input()
    public disabled: boolean = false;

    @Input()
    public primary: boolean = true;

    @Input()
    public trackActionData: {
        event: string;
        data?: Object;
    };

    @Output()
    public onClick: EventEmitter<any> = new EventEmitter();
}
