import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component is a tour card which displays it's information.
 * Each card should have a heading, description and an image.
 */
@Component({
    templateUrl: 'message-card.cmp.html',
    selector: 'message-card',
    styleUrls: [ './message-card.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCard {

    /**
     * Title
     */
    @Input() title: string;

    /**
     * Title icon
     */
    @Input() titleIcon: string;

    /**
     * Body
     */
    @Input() body: string[];

    /**
     * Button label & icon
     */
    @Input() button: { icon: string, label: string };

    /**
     * Emitter that emits when the button is clicked
     */
    @Output() public buttonClicked: EventEmitter<boolean> = new EventEmitter();

    constructor( protected translate: TranslateService ) {}

    /**
     * Getter for translated title
     */
    public get translatedTitle() {
        return this.translate.instant( this.title );
    }

    /**
     * Getter for translated button label
     */
    public get translatedButtonLabel() {
        return this.translate.instant( this.button.label );
    }

    /**
     * Function to get body translations based on index passed in
     * @param index
     * @returns
     */
    public translatedBodyItem( index ) {
        return this.translate.instant( this.body[index]);
    }

    public isImage( icon: string ) {
        return icon?.includes( 'assets/' );
    }
}
