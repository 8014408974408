/**
 * Message Type
 * This enum contains all the message types of message protocol
 * @author  mehdhi
 * @since   Mar 2016
 */
export enum MessageType {
    /**
     * Messages that are used for initializing and establishing a
     * connection and maintaining the connection.
     */
    conn,

    /**
     * A command message.
     */
    cmd,

    /**
     * A subscription message.
     */
    sub,

    /**
     * Collaboration initialization and handshake messages
     * sent between collaborating clients.
     */
    colb,

    /**
     * DiagramsAnywhere synchronization initiating and
     * processing messages.
     */
    sync,
}
