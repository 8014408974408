import { AbstractModel, ComplexType } from 'flux-core';
import { UserInfoModel } from '../../user/model/user-info.mdl';
import { TeamSettingModel } from './team-setting.mdl';
import { TeamGroupModel } from './team-group.mdl';

export enum TeamUserRole {

    /**
     * Super admin user role.
     */
    SUPERADMIN = 'SUPERADMIN',

    /**
     * Admin user role
     */
    ADMIN = 'ADMIN',

    /**
     * Member user role
     */
    MEMBER = 'MEMBER',
}

/**
 * This class models a team plan (group plan) in Creately. It includes methods to
 * retrieve different types of data related to the plan including its users, plan details,
 * history, administrators and so on.
 *
 * @author  thisun
 * @since   2016-10-20
 */
export class TeamModel extends AbstractModel {

    /**
     * This variable holds the id of this group plan.
     */
    public id: string;

    /**
     * This variable holds the name of the organization the team plan is purchased to.
     */
    public organizationName: string;

    /**
     * Holds the domain of this team plans organization.
     * i.e. cinergix.com
     */
    public organizationDomain: string;

    /**
     * Holds the team plans size
     */
    public teamSize: number;

    /**
     * Stores the contact email address of the team plan administrator
     */
    public adminContact: string;

    /**
     * Hold the team user role value
     */
    public teamUserRole: TeamUserRole;

    /**
     * This property holds a list of users who are members of this team.
     */
    // @ComplexType( UserInfoModel ) - removing ComplexType decorator as it is very expensive
    public members: UserInfoModel[];

    /**
     * This property holds a list of user groups of this team.
     */
    @ComplexType( TeamGroupModel )
    public teamGroups: TeamGroupModel[];

    /**
     * This property holds the team user setting values.
     */
    @ComplexType( TeamSettingModel )
    public teamSettings: TeamSettingModel[];

}

Object.defineProperty( TeamModel, 'name', {
  writable: true,
  value: 'TeamModel',
});
