import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, StateService, RequestMethod } from 'flux-core';
import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command is used to retrieve the images from google.
 * Search query term will be passed into this command as Input.
 *
 * @since   2020-02-12
 * @author  Vinoch
 */
@Injectable()
@Command()
export class GetGoogleImages extends AbstractHttpCommand  {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    constructor( protected state: StateService<any, any> ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        if ( this.state.get( 'GoogleTokenInfo' )
        && this.state.get( 'GoogleTokenInfo' ).access_token ) {
            return new HttpHeaders({
                Authorization: 'Bearer ' + this.state.get( 'GoogleTokenInfo' ).access_token,
            });
        }
        return;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Returns API endpoint URL for this command.
     * imgSize set to large. This is because we
     * have to limit the images shown by google
     * to less than 5MB adhere to the threshold
     * set in Image File Receiver.
     */
    public get httpUrl(): string {
        const urlInfo = AppConfig.get ( 'GOOGLE_CUSTOM_SEARCH' );
        return urlInfo.url + '?cx=' + urlInfo.id + '&q=' +
        this.data.query + '&searchType=image&imgSize=large'
        + '&siteSearch=' + this.getIgnoreSites() + '&siteSearchFilter=e' +
        '&safe=active&start=' + this.data.startIndex;
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

    /**
     * This will return the sites which
     * needed to be ignored when searching
     * for images using Google Custom Search API.
     * We are ignoring these sites because they pretend
     * to be having images but they provide HTML content
     * to prevent direct links to their resources.
     */
    private getIgnoreSites() {
        const sites =  [
            'www.facebook.com',
            'www.twitter.com',
            'www.instagram.com',
            'www.youtube.com',
        ];
        return sites.join( ', ' );
    }
}

Object.defineProperty( GetGoogleImages, 'name', {
    value: 'GetGoogleImages',
});
