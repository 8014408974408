
/**
 * Returns an array of combinations
 */
// tslint:disable-next-line:only-arrow-functions
export function pairs<T> ( a1: T[], a2: T[]): any[][] {
    if ( !Array.isArray( a1 ) || !Array.isArray( a2 )) {
        throw new Error( 'unexpected error: was expecting an array' );
    }
    const a1Len = a1.length;
    const a2Len = a2.length;
    if ( !a1Len || !a2Len ) {
        return [];
    }
    const result: any[][] = [];
    for ( let i = 0; i < a1Len; ++i ) {
        for ( let j = 0; j < a2Len; ++j ) {
            result.push([ a1[i], a2[j], i, j ]);
        }
    }
    return result;
}
