import { CommonTheme } from 'flux-diagram-composer';

/**
 * Theme settings used on the diagraming platform.
 */
export class Theme extends CommonTheme {

    /**
     * Main theme colors
     */
    public static primarySelectionColor = '#0C5E96';
    public static primarySelectionLineColor = '#0990ED';
    public static primarySelectionLineColorHover = '#0990ED33';
    public static primaryObjectLineColor = '#E87B35';
    public static primaryObjectLineColorHover = '#E87B3533';
    public static selectionLineSpacing = 2;

    /**
     * Shape Selection related theme
     */
    public static selectionHandleRadius = 6;
    public static selectionHandleLineThickness = 1;
    public static primarySelectionHandleColor = '#0990ED';
    public static secondarySelectionHandleColor = '#0990ED';
    public static selectionBoundDashStyle = [ 2, 2 ];

    /**
     * Primary hover main theme colours - March 2022
     * Used when hovering on shape thumbnails
     */
    public static primaryHoverSelectionColor = '#0C5E96';

    /**
     * Plus Create Selection related theme
     */
    public static backgroundColor = '#1381CD';
    public static iconColor = '#627281';
    public static iconColorHover = '#0C5E96';
    // active styles for plus create functionality does not exist

    /**
     * Arrow dropdowns - should be removed when canvas items are replaced by images.
     */
    public static arrowIconColor = '#354B53';
}
