import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { StateService, ModalController, Tracker, AppConfig } from 'flux-core';
import { Subscription } from 'rxjs';
import { PopupWindow } from 'flux-core';
import { IDialogBoxData } from 'flux-definition';
import { TranslateService } from '@ngx-translate/core';

/**
 * This is the upgrade dialog box which will appear everytime a
 * free user or a demo user tries to use a preminum feature of the
 * app.
 */
@Component({
    templateUrl: './upgrade-dialog.cmp.html',
    selector: 'upgrade-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './upgrade-dialog.scss' ],
})
export class UpgradeDialog extends PopupWindow implements OnInit, OnDestroy {

    /**
     * Data used to display information on the window.
     */
    @Input() public dialogData: IDialogBoxData;

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription>;

    constructor(
        protected state: StateService<any, any>,
        protected modalController: ModalController,
        public translate: TranslateService ) {
        super()/* istanbul ignore next */;
        this.subs = [];
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
        Tracker.track(
            'conversion.dialog.upgrade.closeOnOverlay.click', { value1: this.dialogData.id, value1Type: 'dialogId' },
        );
    }

    /**
     * Start the show window animation.
     */
    public ngOnInit(): void {
        Tracker.track(
            'conversion.dialog.upgrade.load', { value1: this.dialogData.id, value1Type: 'dialogId' },
        );
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.subs.push( sub );
    }

    /**
     * Starts the animation for hiding the window and closes the window
     * after it completes. Calls close click handler if available.
     */
    public closeWindow() {
        if ( this.dialogData.closeClickHandler ) {
            this.dialogData.closeClickHandler();
        }

        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * redirect to the team plans page in a new tab
     */
    public goToPlans() {
        window.open( AppConfig.get( 'PLAN_PAGE_URL' ), '_blank' );
    }

    /**
     * Unsubscribes from the subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
    /**
     * This will return the handler function depend on dialogData
     */
    public getButtonHandler() {
        const okButton = ( this.dialogData.buttons || []).find( value => value.type === 'ok' );
        if ( okButton ) {
            return okButton.clickHandler();
        }
        return this.goToPlans();
    }
}
