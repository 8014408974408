import { NotificationType, NotifierController, AbstractNotification,
    Command, CommandInterfaces, StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { IPoint2D, IPosition2D, ShapeType } from 'flux-definition';
import { DataStore } from 'flux-store';
import { tap, take } from 'rxjs/operators';
import { ViewportToDiagramCoordinate } from '../../coordinate/viewport-to-diagram-coordinate.svc';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { CommentModel } from '../../diagram/model/comment.mdl';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { empty, concat, defer, of, combineLatest, Observable } from 'rxjs';
import { ICommentThreadData } from './comment-thread.i';
import { TranslateService } from '@ngx-translate/core';
import { UserLocator } from 'flux-user';
import { Notifications } from '../../notifications/notification-messages';

@Injectable()
@Command()
/**
 * This command will add a comment on the diagram
 */
export class AddComment extends AbstractMessageCommand {
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    /**
     * Static method that sets the given comment thread to the CommentThreadData state.
     * @param comment a comment
     * @param state the state service to use
     */
    public static handleNotificationClick(
        comment: CommentModel,
        state: StateService<any, any>,
        dataStore: DataStore,
    ) {
        if ( !comment.parentId ) {
            const thread = { visible: true, threadId: comment.id, comments: [ comment ]};
            state.set( 'CommentThreadData', thread );
        } else {
            dataStore.findOneLatest( CommentModel, { id: comment.parentId }).pipe(
                tap(( model: CommentModel ) => {
                    const thread: ICommentThreadData = { visible: true, threadId: model.id, comments: [ model ]};
                    state.set( 'CommentThreadData', thread );
                }),
            ).subscribe();
        }
    }

    public data: {
        comment: {
            // NOTE: please check CommentModel for details about these properties.
            id: string,
            comment: string,
            diagramId?: string,
            locOnDiagram: IPoint2D,
            locOnShape?: IPosition2D,
            userId?: string,
            added?: number,
            shapeId?: string,
            parentId?: string,
            comitted?: boolean,
        },
        /**
         * When a new comment ( not a reply ) is asdded, the comments thread container is opened by default
         * This property is to disable that behaviour
         */
        close?: boolean,
    };

    constructor(
        protected userLocator: UserLocator,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected ll: DiagramLocatorLocator,
        protected v2d: ViewportToDiagramCoordinate,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.data.comment.diagramId = this.resourceId;
        this.data.comment.userId = this.state.get( 'CurrentUser' );
        this.data.comment.added = Date.now();
        if ( this.data.comment.parentId ) {
            delete this.data.comment.shapeId;
            delete this.data.comment.locOnDiagram;
            return empty();
        }
        if ( !this.data.comment.shapeId ) {
            return empty();
        }
        return this.ll.forCurrent( false ).getShapeOnce( this.data.comment.shapeId ).pipe(
            tap(( s: ShapeModel ) => {
                if ( s.type === ShapeType.Connector ) {
                    delete this.data.comment.shapeId;
                    return;
                }
                if ( !this.data.comment.locOnDiagram ) { // If the shape location is not specified take center
                    const b = s.bounds;
                    this.data.comment.locOnDiagram = { x: b.centerX, y: b.centerY };
                }
                const pos = s.getPositionOnShape( this.data.comment.locOnDiagram, 5 );
                this.data.comment.locOnShape = pos;
            }),
        );
    }

    public execute() {
        return concat(
            this.dataStore.insert( CommentModel, this.data.comment ),
            defer(() => this.dataStore.findOne( CommentModel, this.data.comment ).pipe(
                take( 1 ),
                tap( model => {
                    if ( !model.parentId ) {
                        const parentSelector = this.state.get( 'CommentThreadData' ).parentSelector;
                        const comment: ICommentThreadData = { visible: !this.data.close,
                            threadId: model.id, parentSelector, comments: [ model ]};
                        this.state.set( 'CommentThreadData', comment );
                    }
                }),
            )),
        );
    }

    public executeResult() {
        const data = { ...this.resultData.comment, diagramId: this.resourceId, comitted: true };
        return combineLatest(
            this.handleNotification(),
            this.dataStore.insert( CommentModel, data ),
        );
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        return this.dataStore.remove( CommentModel, { id: this.data.comment.id, diagramId: this.resourceId });
    }

    /**
     * Show a notification when a comment is added by another collab
     */
    protected handleNotification() {
        if ( this.state.get( 'CurrentUser' ) !== this.resultData.comment.userId &&
            this.state.get( 'CurrentDiagram' ) === this.resultData.comment.diagramId
        ) {
            return this.userLocator.getUserInfo( this.resultData.comment.userId ).pipe(
                take( 1 ),
                tap( userData => {
                    const type = this.resultData.comment.parentId ? 'REPLIED' : 'ADDED';
                    const options = {
                        inputs: {
                            heading:
                                `${userData.firstName} ${this.translate.instant( `NOTIFICATIONS.COMMENT.${type}` )}`,
                            description: this.resultData.comment.comment,
                            user: userData,
                            notificationAction: {
                                action: AddComment.handleNotificationClick,
                                args: [ this.resultData.comment, this.state, this.dataStore ],
                            },
                            autoDismiss: true,
                            dismissAfter: 3000,
                        },
                    };

                    this.notifierController.show( Notifications.COMMENT_ADDED, AbstractNotification,
                        NotificationType.Success, options );
                }),
            );
        }
        return of({});
    }
}

Object.defineProperty( AddComment, 'name', {
    value: 'AddComment',
});
