import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EDataModel } from './../../../../../base/edata/model/edata.mdl';
import { ModalController, PopupWindow, CommandService, StateService } from 'flux-core';
import { BehaviorSubject, Subject, Subscription, Observable } from 'rxjs';
import { DiagramCommandEvent } from '../../../../../editor/diagram/command/diagram-command-event';
import * as JSZip from 'jszip';
import { ImportedFile } from 'apps/nucleus/src/framework/file/imported-file';

const acceptedTypes = [ 'png', 'jpg', 'jpeg', 'svg' ];
const maxFileSize = 100 * 1024 * 1024;

/**
 * This is the data import dialog.
 *
 * When files are selected, check if the name matches the ID of any entity in the DB.
 * Filter and only upload files which match. Also adds the option to rename the files after selecting.
 */
@Component({
    templateUrl: './image-import-window.cmp.html',
    selector: 'image-import-window',
    styleUrls: [ './image-import-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageImportWindow extends PopupWindow implements OnInit, AfterViewInit, OnDestroy {

    public hasData: Subject<boolean> = new BehaviorSubject( false );
    public images: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public fileSize: BehaviorSubject<number> = new BehaviorSubject( 0 );
    public processingZip: BehaviorSubject<boolean> = new BehaviorSubject( false );
    public zipFile: any = null;
    public closeWindowObs: Subject<boolean> = new Subject();

    @Input()
    public importingInto: EDataModel;
    @Input()
    public entityDefId: Observable<string>;

    /**
     * The window overlay.
     */
    @ViewChild( 'window' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner' ) protected containerInner;

    protected subs: Subscription[] = [];

    constructor(
        protected modalController: ModalController,
        protected commandService: CommandService,
        protected state: StateService<any, any>,
        protected translate: TranslateService,
    ) {
        super()/* istanbul ignore next */;
    }

    public displaySize( size: number | undefined ): string {
        if ( size === null ) {
            return 'Unknown Size';
        } else if ( size < 1024 ) {
            return `${size} B`;
        } else if ( size < 1024 * 1024 ) {
            return `${Math.round( size / 1024 )} KB`;
        } else {
            return `${Math.round( size / ( 1024 * 1024 ))} MB`;
        }
    }

    public ngOnInit(): void {
        this.subs.push( this.closeWindowObs.subscribe(() => this.closeWindow()));
    }

    public ngAfterViewInit() {
    }

    /**
     * Closes the window after animation is complete.
     */
    public closeWindow() {
        this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public dragOverHandler( event: DragEvent ) {
        event.preventDefault();
    }

    public dropHandler( event: DragEvent ) {
        event.preventDefault();
        const files: File[] = [];

        if ( event.dataTransfer.items ) {
            // Use DataTransferItemList interface to access the file(s)
            for ( let i = 0; i < event.dataTransfer.items.length; i++ ) {
                // If dropped items aren't files, reject them
                if ( event.dataTransfer.items[i].kind === 'file' ) {
                    const file = event.dataTransfer.items[i].getAsFile();
                    files.push( file );
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for ( let i = 0; i < event.dataTransfer.files.length; i++ ) {
                files.push( event.dataTransfer.files[i]);
            }
        }
        if ( files.length > 0 ) {
            const pics = this.images.getValue();
            for ( let i = 0; i < files.length; i++ ) {
                if ([ ...acceptedTypes, 'zip' ].includes( files[i].name.split( '.' ).pop().toLowerCase()) &&
                    files[i].size <= maxFileSize ) {
                    if ( files[i].name.split( '.' ).pop().toLowerCase() === 'zip' ) {
                        this.zipFile = files[i];
                        this.processingZip.next( true );
                        this.processZip( files[i]);
                    } else {
                        pics.push( files[i]);
                    }
                }
            }
            this.images.next( pics );
        }
    }

    /**
     * Read files, check if they match accepted files and process zip
     * @param event
     */
    public onFileChange( event: Event ) {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        const pics = this.images.value;
        for ( let i = 0; i < files.length; i++ ) {
            if ( files[i].type.split( '/' )[0] === 'image' && acceptedTypes.includes(
                files[i].name.split( '.' ).pop().toLowerCase()) && files[i].size <= maxFileSize ) {
                pics.push( files[i]);
            } else if ( files[i].name.split( '.' ).pop().toLowerCase() === 'zip' && files[i].size <= maxFileSize ) {
                this.zipFile = files[i];
                this.processingZip.next( true );
                this.processZip( files[i]);
            }
        }
        this.images.next( pics );
    }

    /**
     * Unzip file and checks if content match the acceptedTypes
     * @param zipFile
     */
    public processZip( zipFile: File ) {
        const zip = new JSZip();
        zip.loadAsync( zipFile ).then( file => {
            const promises = [];
            zip.forEach(( relativePath, zipEntry ) => {
                if ( zipEntry.dir ) {
                    return;
                }
                // Get files in the zip folder and return of type file
                promises.push( zipEntry.async( 'blob' ).then( blob => {
                    const parsed = new File([ blob ], zipEntry.name, { type: 'image/png' });
                    return parsed;
                }));
            });
            Promise.all( promises ).then( results => {
                const images = [];
                results.forEach( result => {
                    if ( acceptedTypes.includes( result.name.split( '.' ).pop().toLowerCase())) {
                        images.push( result );
                    }
                });
                this.images.next( images );
            });
        });
        setTimeout(() => this.processingZip.next( false ), 1000 );
    }

    public deleteZip() {
        this.processingZip.next( false );
        this.zipFile = null;
        this.images.next([]);
    }

    public deleteImage( index: number ) {
        const images = this.images.getValue();
        images.splice( index, 1 );
        this.images.next( images );
    }

    /**
     * Add property rename to store updated file name to
     * @param index
     * @param name
     */
    public changeFileName( index: number, name: string ) {
        const images = this.images.getValue();
        images[index].rename = name;
        this.images.next( images );
    }

    public terminateEditing( event ) {
        ( <HTMLInputElement>event.target ).blur();
    }

    public uploadImages() {

        this.entityDefId.subscribe( val => {

            // Get entity identifier Ids
            const entities = Object.values( this.importingInto.entities ).filter( i => i.eDefId === val );
            const entityIds = [];

            for ( const ent of entities ) {
                const dataItems = this.importingInto.getEntityDataItems( ent.id );
                if ( Object.keys( dataItems ).includes( 'id' )) {
                    entityIds.push( dataItems.id.value );
                } else if ( Object.keys( dataItems ).includes( 'number' )) {
                    entityIds.push( dataItems.number.value );
                }
            }

            // Check files and filter out files where name does not match any entityIds
            const files = this.images.getValue();
            const filesToUpload = {};
            for ( const file of files ) {
                const entityId = entityIds.find( id => file?.rename ? id === this.getFileName( file.rename ) :
                                                        id === this.getFileName( file.name ));
                if ( entityId ) {
                    const entity = entities[ entityIds.indexOf( entityId ) ];
                    file.extension = file.name.split( '.' ).pop().toLowerCase();
                    filesToUpload[ entity.id ] = new ImportedFile( file );
                }
            }

            this.commandService.dispatch( DiagramCommandEvent.uploadEntityImages, {
                files: filesToUpload,
                edataModel: this.importingInto,
            });

            this.closeWindow();
            const loadingText = this.translate.instant( 'IMAGE_IMPORT.ACKNOWLEDGEMENT' );
            this.state.set( 'AcknowledgementMessage', { open: true, message: loadingText, showLoader: true });

        });
    }

    protected getFileName( fileName: string ): string {
        if ( !fileName ) {
            return fileName;
        }

        const lastDotIndex = fileName.lastIndexOf( '.' );

        if ( lastDotIndex === -1 ) {
            return fileName;
        }

        return fileName.substring( 0, lastDotIndex );
    }
}
