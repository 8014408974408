import { enumerable } from 'flux-core';
import { ResourceModel } from '../../model/resource.mdl';
import { INavItem } from './nav-item';

/**
 * Defines predefined project type Ids
 */
export enum ProjectType {
    HOME = 'home',
    SHARED_HOME = 'shared-home',
}

/**
 * ProjectModel
 * This model is responsible to hold project data. Extends
 * the features of a {@link ResourceModel}.
 *
 * @author  hiraash
 * @since   2015-11-07
 */
export class ProjectModel extends ResourceModel {

    /**
     * Ids of the edata models attached to the project
     */
    public eData: string[];

    /**
     * List of nav items.
     */
    public navLinks?: INavItem[] = [];

    /**
     * Description of the project
     */
    public description?: string;


    /**
     * Which collection this belongs to
     * A single string used to group items in the project sidebar.
     */
    public collection?: string;


    /**
     * group this belongs to
     */
    public group?: string;

    /**
     * The primary team or org that the project belongsTo
     */
    public teamId: string;

    /**
     * Marks if the project is created under an organization.
     */
    public listUnderOrg: boolean;

    /**
     * Home diagram ID
     */
    public homeDiagId?: string;

    constructor( id: string, name: string, extension?: Object ) {
        super( id, name, extension ) /* istanbul ignore next */;
    }

    /**
     * This getter returns true if the project is the home
     * project.
     */
    @enumerable( true )
    public get isHomeProject() {
        return this.id === ProjectType.HOME;
    }

}

Object.defineProperty( ProjectModel, 'name', {
  writable: true,
  value: 'ProjectModel',
});
