import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'logic-label',
    styleUrls: [ 'custom-entity-search.cmp.scss' ],
    template: `
        <!-- <div class="logic-label">
            {{ logic }}
        </div> -->
        <div class="align-self-center button-raw">
            <div class="search-button-row">
                <button *ngIf="(logic | async) === 'AND' || showOptions" class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin inner"
                    mat-stroked-button (click)="addAnd()">AND</button>
                <div class="medium-text" *ngIf="showOptions"> | </div>
                <button *ngIf="(logic | async) === 'OR' || showOptions" class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin inner"
                    mat-stroked-button (click)="addOr()">OR</button>
                <svg *ngIf="!showOptions" (click)="editOptions()" style="width:20px;height:20px;cursor:pointer;" class="nu-icon nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-edit"></use>
                </svg>
            </div>
        </div>
    `,
})

export class LogicLabelComponent implements OnInit {

    @Input() public logic: BehaviorSubject<string>;

    public showOptions: boolean = false;

    constructor() { }

    ngOnInit() { }

    public editOptions() {
        this.showOptions = true;
    }

    public addAnd() {
        this.logic.next( 'AND' );
        this.showOptions = false;
    }
    public addOr() {
        this.logic.next( 'OR' );
        this.showOptions = false;
    }
}
