import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Tracker } from '../../../tracker/tracker';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { StateService } from 'flux-core';

/**
 * NotificationCount component.
 *
 * Displays error and warning notification counts.
 *
 * @author jerome
 * @since 2021-08-05
 */
@Component({
    selector: 'notification-count',
    styleUrls: [ './notification-count.cmp.scss' ],
    templateUrl: './notification-count.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCount implements OnInit, OnDestroy {

    /**
     * Emits when the error and warning icons are clicked.
     */
    @Output()
    public clicked: Subject<any>;

    /**
     * The number of warnings.
     */
    protected _warningCount: number;

    /**
     * The number of errors.
     */
    protected _errorCount: number;

    /**
     * A list of subscriptions.
     */
    protected subs: Subscription[];

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        @Inject( StateService ) protected state: StateService<any, any>,
    ) {
        this.clicked = new Subject();
        this.subs = [];
    }

    /**
     * Set the number of warnings.
     */
    @Input()
    public set warningCount( value: number ) {
        this._warningCount = value;
        this.changeDetectorRef.markForCheck();
    }
    public get warningCount(): number {
        return this._warningCount;
    }

    /**
     * Set the number of errors.
     */
    @Input()
    public set errorCount( value: number ) {
        this._errorCount = value;
        this.changeDetectorRef.markForCheck();
    }
    public get errorCount(): number {
        return this._errorCount;
    }

    /**
     * Initialize component.
     */
    public ngOnInit (): void {
        this.subs.push( this.listenToNotificationCountChanges().subscribe());
    }

    /**
     * Handle click events on the warning icon.
     */
    public handleWarningClicked() {
        Tracker.track( 'notification.warning.click' );
        this.clicked.next();
        this.state.set( 'NotificationCountClick', 'warning' );
    }

    /**
     * Handle click events on the error icon.
     */
    public handleErrorClicked() {
        Tracker.track( 'notification.error.click' );
        this.state.set( 'NotificationCountClick', 'error' );
        this.clicked.next();
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    public ngOnDestroy(): void {
        this.clicked.unsubscribe();
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Listens to notification count changes and updates internal states.
     * @returns an observable that does the above
     */
    protected listenToNotificationCountChanges(): Observable<any> {
        return this.state.changes( 'NotificationCounts' ).pipe(
            filter( counts => !!counts ),
            tap( counts => {
                this.warningCount = counts.warningCount;
                this.errorCount = counts.errorCount;
            }),
        );
    }
}
