import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { EMPTY } from 'rxjs';
import { PresentationModel } from '../model/presentation.mdl';


@Injectable()
@Command()
export class GetPresentations extends AbstractMessageCommand {

    /**
     * This command is sent to the server.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        projectId?: string;
    };

    constructor( protected dataStore: DataStore, protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    prepareData() {
        if ( !this.data ) {
            const projectId = this.state.get( 'CurrentProject' );
            this.data = {
                projectId: projectId,
            };
        }
    }

    execute() {
        this.resourceId = this.state.get( 'CurrentProject' );
        return true;
    }

    public executeResult( ) {
        if ( this.resultData.presentations && this.resultData.presentations.length > 0 ) {
            this.dataStore.insert( PresentationModel, this.resultData.presentations );
        }
        return EMPTY;
    }
}

Object.defineProperty( GetPresentations, 'name', {
    value: 'GetPresentations',
});
