import { IDataItem } from 'flux-definition';
import { IDataItemUIControl } from './data-items-uic.i';
import { map, filter, tap } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ElementRef, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { MapOf, Tracker } from 'flux-core';

/**
 * UI controller for Number Input
 */
@Component({
    selector: 'number-input-uic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <input type="number" [ngAttr]="attrs" class="text-input" [value]="valueSource | async" (keydown)="validateInput($event)" (change)="$event.stopPropagation()">
    `,
})
export class NumberInputUIC implements IDataItemUIControl<number>, OnInit, AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;


    @Input()
    public data?: any;

    @Output()
    change: Subject<number> = new Subject();

    @Output()
    blur: Subject<boolean> = new Subject();

    @Input()
    setFocus: Subject<boolean>;

    @Input()
    attrs: MapOf<string> = {};

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    public valueSource: BehaviorSubject<number>;

    private subs = [];

    /**
     * Constructor
     */
    constructor( protected elementRef: ElementRef ) {
        this.valueSource = new BehaviorSubject( undefined );
    }

    /**
     * An observable that emits when the button is clicked.
     * If an extenal component wants to listen to the button
     * click event, they need to subscribe to this observable.
     */
    ngAfterViewInit() {
        const input  = ( this.elementRef.nativeElement as HTMLElement ).querySelector( 'input' );
        const sub = fromEvent( input, 'blur' ).pipe(
            tap(() => this.blur.next( true )),
            filter(() => parseFloat( input.value ) !== this.valueSource.value ),
            map(() => parseFloat( input.value )),
            tap( val => {
                /* istanbul ignore next */
                if ( this.context ) {
                    Tracker.track( `${this.context}.number.change` );
                }
                this.change.next( val );
            }),
        ).subscribe();
        this.subs.push( sub );
        if ( this.setFocus ) {
            const sub2 = this.setFocus.subscribe(() => {
                setTimeout(() => input.focus());
            });
            this.subs.push( sub2 );
        }
    }

    ngOnInit() {
        if ( this.data ) {
            this.setData( this.data );
        }
    }

    ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        this.valueSource.next( data.value );
    }

    /**
     * Checks if characters entered are numbers.
     * Concatenates newly entered character to previously entered characters.
     * Returns true if resulting value is a valid number.
     */
    validateInput ( event: any ) {
        const acceptedKeys = [ 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight' ];
        if ( acceptedKeys.includes( event.code )) {
            return true;
        } else {
            const input = ( this.elementRef.nativeElement as HTMLElement ).querySelector( 'input' );
            return !isNaN( Number( input.value + event.key )) && event.code !== 'Space';
        }
    }

}
