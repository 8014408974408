import { Tracker } from 'flux-core';
import { IDataItemUIControl } from './data-items-uic.i';
import { AbstractDropDown } from 'flux-core/src/ui';
import { Subject, BehaviorSubject } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'entity-lookup-single-uic',
    template: `
        <div class="text" *ngIf="valueSource | async as data" class="entity-lookup-single-uic">
            <abs-dropdown #dd [settings]="{ closeOnItemClicked: true, openOnHover: false, closeOnBlur: true } "
                [autoPosition]="false"
                class="dropdown-item">
                    <sidebar-dropdown-button ddbutton [items]="data">
                    </sidebar-dropdown-button>
                    <sidebar-list-dropdown-item dditem *ngFor="let item of data"
                    class="fx-margin-bottom-10"
                    [item]="item">
                    </sidebar-list-dropdown-item>
            </abs-dropdown>
        </div>
    `,
    styleUrls: [ './entity-lookup-single-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * UI control for SingleSelectCombo
 */
export class EntityLookupSingleUIC implements IDataItemUIControl<string[]>, AfterViewInit, OnDestroy {
    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
    @Input()
    public context?: string;

    public id: string;

    public change: Subject<string[]>;

    public valueSource: BehaviorSubject<any>;

    @ViewChild( AbstractDropDown, { static: false })
    public absDropdown: AbstractDropDown;

    protected subs = [];

    constructor( private ell: EDataLocatorLocator ) {
        this.valueSource = new BehaviorSubject([]);
        this.change = new Subject();
    }


    public setData( data: any ) {
        const { eDataId, eDefId, eDataDefId } = data.options;
        ( eDataId ? this.ell.getEData( eDataId ).pipe(
            switchMap( locator => locator.getEDataModelOnce()),
        ) : this.ell.currentEDataModels().pipe( map( eDataModels =>
            eDataModels.find( model => model.defId === eDataDefId ) || {
                entities: {},
            }))
        ).subscribe( model => {
            const entities = Object.values( model.entities ).filter( e => e.eDefId === eDefId );
            this.valueSource.next( entities.map( e => {
                const value = e.getDisplayText();
                return {
                    id: e.id,
                    label: value,
                    buttonLabel: value,
                    selected: data.value.includes( e.id ),
                    // value,
                };
            }));
        });

    }


    public ngAfterViewInit() {
        const sub = this.absDropdown.changed.subscribe( ids => {
            this.change.next( ids );
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.lookup.change` );
            }
        });
        this.subs.push( sub );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

}
