<div class="modal-window-container fx-cover fx-center-all {{class}}" #window>
    <div #windowInner class="modal-window-inner fx-center-column fx-no-padding">
        <div class="fx-flex-expand fx-center-column fx-padding fx-span-width">
            <div *ngIf="icon" class="fx-center-all fx-flex-expand">
                <img [src]="iconUrl" width="100">
            </div>
            <div class="fx-padding-bottom-10 fx-padding-top-10 fx-center" *ngIf="message" [class.fx-flex-expand]="!icon">{{message}}</div>
        </div>
        <div class="loading-popup-bubbles fx-padding-top-20 fx-padding-bottom-20 primary fx-span-width">
            <div class="spinner">
                <div class="bounce1 white"></div>
                <div class="bounce2 white"></div>
                <div class="bounce3 white"></div>
            </div>
        </div>
    </div>
</div>