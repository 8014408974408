import { from, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ResourceModelStore } from './resource-model.store';

/**
 * DiagramInfoModelStore
 * DiagramInfoModelStore class is responsible for performing storage related
 * operations for a diagram model type (typeof DiagramInfoModel). DataStore service
 * will create instances of this class for this model type.
 *
 * For more details see the {@link ModelStores}
 *
 * @author: gobiga
 * @since: 2017-08-24
 */
export class DiagramInfoModelStore extends ResourceModelStore {

    /**
     * Insert a snapshot to diagram history metadata collection
     */
    public insertHistoryItem( resourceId: string, snapshot: Object ): Observable<any> {
        const query = { id: resourceId };
        return this.historyMetadataCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return from( this.insertHistoryMetadata({
                        id: resourceId,
                        snapshots: [ snapshot ],
                    }));
                }
                const snapshots = ( model.snapshots || []).concat([ snapshot ]);
                return from( this.updateHistoryMetadata( query, { $set: { snapshots }}));
            }),
        );
    }

    /**
     * Renames an existing history item
     * @param resourceId
     * @param lastChangeId
     * @param name
     * @returns observable returned from the update operation
     */
    public renameHistoryItem( resourceId: string, lastChangeId: string, name: string ): Observable<any> {
        const query = { id: resourceId };
        return this.historyMetadataCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                const snapshots = model.snapshots.slice();
                const index = snapshots.findIndex( s => s.lastChangeId === lastChangeId );
                snapshots[index] = { ...snapshots[index], name };
                return from( this.updateHistoryMetadata( query, { $set: { snapshots }}));
            }),
        );
    }

}
