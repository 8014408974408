import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY, from } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand, StateService } from 'flux-core';
import { HttpHeaders } from '@angular/common/http';
import { DataStore } from 'flux-store';
import { UserModel } from '../model/user.mdl';

/**
 * This command will fetch the user's subscription status data from Creately API.
 *
 * @output  The user's subscription status data.
 * @author  Jerome
 * @since   04-08-2021
 */
@Injectable()
@Command()
export class UpdateUserOnboardingStatus extends AbstractHttpCommand {

    public static get dataDefinition(): {}  {
        return {
            usage: true,
            industry: true,
            role: true,
            purpose: true,
            size: true,
            onboardingProgress: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor(
        protected dataStore: DataStore,
        @Inject( StateService ) protected state: StateService< any, any >,
    ) {
        super();
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': window.gravity.client.getCookie(),
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

    public prepareData(): any {
        if ( !window.gravity.client.hasValidSession()) {
            return;
        }

        let status = '';

        if ( this.data.usage ) {
            status += `intendedUsage: ${JSON.stringify( this.data.usage )},`;
        }
        if ( this.data.industry ) {
            status += `industry: ${JSON.stringify( this.data.industry )},`;
        }
        if ( this.data.role ) {
            status += `jobTitle: ${JSON.stringify( this.data.role )},`;
        }
        if ( this.data.purpose ) {
            status += `purpose: ${JSON.stringify( this.data.purpose )},`;
        }
        if ( this.data.size ) {
            status += `organizationSize: ${JSON.stringify( this.data.size )},`;
        }
        if ( this.data.onboardingProgress ) {
            status += `onboardingProgress: ${JSON.stringify( this.data.onboardingProgress )},`;
        }

        const query = `mutation{
            userUpdateOnboardingInfo(
                onboardingStatus: { ${status} }
            ){ date }
        }`;

        this.data.query = query;
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        // Not executing command if user is not authenticated
        if ( !window.gravity.client.hasValidSession()) {
            return;
        }
        return EMPTY;
    }

    /**
     * execute
     * Add user rating.
     */
    public executeResult(): Observable<any> {
        const result = JSON.parse( this.resultData );
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set[ 'preferences.onboarding_progress' ] = this.data?.onboardingProgress;
        setter.$set[ 'preferences.onboarding_last_updated' ] = result?.data?.userUpdateOnboardingInfo?.date;
        return from( modelStore.update(
            { id: this.state.get( 'CurrentUser' ) },
            setter,
        ));
    }

}

Object.defineProperty( UpdateUserOnboardingStatus, 'name', {
    value: 'UpdateUserOnboardingStatus',
});
