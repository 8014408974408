import { CommandEvent, CommandMapper } from 'flux-core';
import { GetTemplateData } from '../../editor/diagram/templates/get-template-data.cmd';
import { CreateDocument } from './create-document.cmd';

/**
 * PluginCommandEvent
 * ...
 */
// tslint:disable: member-ordering
export class PluginCommandEvent extends CommandEvent {
    /**
     * Creates a document
     */
    static createDocument: PluginCommandEvent = new PluginCommandEvent( 'CreateDocument' );
    protected static registerCreateDocument( mapper: CommandMapper ) {
        mapper.mapSequence( this.createDocument )
            .add( GetTemplateData as any )
            .add( CreateDocument as any, {
                transform: progress => {
                    // FIXME: use template data if available.
                    const modelData = progress.eventData;
                    return modelData;
                },
            });
    }
}
