// tslint:disable:max-file-line-count
import { IGraphics, StrokeCaps, StrokeJoints } from 'flux-definition';
import * as md5 from 'md5';
import { IPoint2D } from 'flux-definition';
import { AbstractShapeModel } from 'flux-diagram-composer';

export class GraphicsSVG implements IGraphics {

    /**
     * Holds all the SVG instructions generated on this instance
     */
    protected instructions: string[];

    /**
     * Holds all SVG gradient instructions generated on this instance.
     * While creating the final SVG document, gradients must be processed first.
     */
    protected gradients: string[];

    /**
     * Holds temporary collection of SVG path instructions, when a closing of a pathing instructions
     * detected, they need to converted into full SVG instructions and this must be cleared.
     */
    protected path: string[];

    /**
     * Fill instructions of currenly processing Graphic instructions, beginFill will start filling the instructions
     * and endFill or start of a new fill will mark the end of the instruction.
     * Until the endFill or new start fill  all shapes will get this fill.
     *
     * Default value for the fill is 'none'
     */
    protected fill: string;
    protected filter: string;

    /**
     * Holds stroke instructions of currently processing Graphic instructions, beginStroke will start collecting stroke
     * instructions until an endStroke is processed. Generated stroke instructions will get applied to
     * all graphics from beginStroke to endStroke or new start command.
     */
    protected stroke: string;

    /**
     * Holds stroke styles, styles will be applied to all graphics until a end command or another start command found.
     */
    protected strokeStyle: string[];

    /**
     * Holds stroke dash styles.
     * Styles will be applied to all graphics until a end command or another start command found.
     */
    protected strokeDash: string[];

    /**
     * Current x and y coordinates
     */
    private currentPoint: IPoint2D = { x: 0, y: 0 };

    /**
     * Initialize the instance for SVG generation
     */
    public constructor( model?: AbstractShapeModel ) {
        this.instructions = [];
        this.gradients = [];
        this.path = [];
        this.fill = 'fill="none"';
        this.stroke = '';
        this.strokeStyle = [];
        this.strokeDash = [];
        this.filter = '';
        if ( model && model.style.shadow ) {
            this.filter = ` filter="url(#shadow${model.id})"` ;
        }
    }

    /**
     * Return complete styling instructions based on current style settings.
     */
    protected get styles(): string {
        return `${this.fill}${this.filter} ${this.stroke} ${this.strokeStyle.join( ' ' )} ${this.strokeDash.join( ' ' )}`.trim();
    }

    /**
     * Clear all SVG instructions of the current instance
     *
     * SVG: None
     */
    public clear(): GraphicsSVG {
        this.instructions = [];
        this.gradients = [];
        this.path = [];
        this.fill = 'fill="none"';
        this.stroke = '';
        this.strokeStyle = [];
        this.strokeDash = [];
        return this;
    }

    /**
     * Expected to move the drawing point to the given coordinate.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     *
     * SVG: path M
     */
    public moveTo( x: number, y: number ): GraphicsSVG {
        this.path.push( `M ${x},${y}` );
        this.currentPoint = { x: x, y: y };
        return this;
    }

    /**
     * Expected to draw a line to the given coordinate from the current
     * point of drawing.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     *
     * SVG: path L
     */
    public lineTo( x: number, y: number ): GraphicsSVG {
        this.path.push( `L ${x},${y}` );
        this.currentPoint = { x: x, y: y };
        return this;
    }

    /**
     * Draws a SVG quadratic curve based on the given parameters.
     * @param cpx control point on X axis
     * @param cpy control point on Y axis
     * @param x end point coodinate on X axis
     * @param y end point coodinate on Y axis
     */
    public curveTo( cpx: number, cpy: number, x: number, y: number ): GraphicsSVG {
        return this.quadraticCurveTo( cpx, cpy, x, y );
    }

    /**
     * Draws a SVG bezier curve based on the given parameters
     * @param cp1x control point 1 on X axis
     * @param cp1y control point 1 on Y axis
     * @param cp2x control point 2 on X axis
     * @param cp2y control point 2 on Y axis
     * @param x end point coodinate on X axis
     * @param y end point coodinate on Y axis
     *
     * SVG: path C
     */
    public bezierCurveTo( cp1x: number, cp1y: number, cp2x: number, cp2y: number, x: number, y: number ): GraphicsSVG {
        this.currentPoint = { x: x, y: y };
        this.path.push( `C ${cp1x},${cp1y} ${cp2x},${cp2y} ${x},${y}` );
        return this;
    }

    /**
     * Draws a SVG curve based on the given parameters.
     * @param cpx control point on X axis
     * @param cpy control point on Y axis
     * @param x end point coodinate on X axis
     * @param y end point coodinate on Y axis
     *
     * SVG: path Q
     */
    public quadraticCurveTo( cpx: number, cpy: number, x: number, y: number ): GraphicsSVG {
        this.currentPoint = { x: x, y: y };
        this.path.push( `Q ${cpx},${cpy} ${x},${y}` );
        return this;
    }

    /**
     * Draws a SVG arc which comply with 2DGraphic arcTo instruction with given parameters.
     * @param x1 coordinate on X axis of control point 1
     * @param y1 coordinate on Y axis of control point 2
     * @param x2 coordinate on X axis of control point 2
     * @param y2 coordinate on Y axis of control point 2
     * @param radius radius of the arc
     *
     * SVG: path A
     * @see https://www.w3.org/TR/2dcontext/#dom-context-2d-arcto
     * Calculations and functionality is referenced from
     * https://github.com/gliffy/canvas2svg
     */
    public arcTo( x1: number, y1: number, x2: number, y2: number, radius: number ): GraphicsSVG {
        // check the radius is not negative
        if ( radius < 0 ) {
            throw new Error( 'Radius can not be negative to an arcTo instruction' );
        }

        const x0 = this.currentPoint.x;
        const y0 = this.currentPoint.y;

        if ((( x0 === x1 ) && ( y0 === y1 )) || (( x1 === x2 ) && ( y1 === y2 )) || ( radius === 0 )) {
            return this.lineTo( x1, y1 );
        }

        const unitVecp1p0 = this.normalizeVector([ x0 - x1, y0 - y1 ]);
        const unitVecp1p2 = this.normalizeVector([ x2 - x1, y2 - y1 ]);
        if ( unitVecp1p0[0] * unitVecp1p2[1] === unitVecp1p0[1] * unitVecp1p2[0]) {
            return this.lineTo( x1, y1 );
        }

        // note that both vectors are unit vectors, so the length is 1
        const cos = ( unitVecp1p0[0] * unitVecp1p2[0] + unitVecp1p0[1] * unitVecp1p2[1]);
        const theta = Math.acos( Math.abs( cos ));

        // Calculate origin
        const unitVecp1Origin = this.normalizeVector([
            unitVecp1p0[0] + unitVecp1p2[0],
            unitVecp1p0[1] + unitVecp1p2[1],
        ]);
        const lenp1Origin = radius / Math.sin( theta / 2 );
        const x = x1 + lenp1Origin * unitVecp1Origin[0];
        const y = y1 + lenp1Origin * unitVecp1Origin[1];

        // Calculate start angle and end angle
        // rotate 90deg clockwise (note that y axis points to its down)
        const unitVecOriginStartTangent = [
            -unitVecp1p0[1],
            unitVecp1p0[0],
        ];
        // rotate 90deg counter clockwise (note that y axis points to its down)
        const unitVecOriginEndTangent = [
            unitVecp1p2[1],
            -unitVecp1p2[0],
        ];

        const startAngle = this.getAngle( unitVecOriginStartTangent );
        const endAngle = this.getAngle( unitVecOriginEndTangent );

        // Connect the point (x0, y0) to the start tangent point by a straight line
        this.path.push( `L ${x + unitVecOriginStartTangent[0] * radius},${y + unitVecOriginStartTangent[1] * radius}` );

        // Connect the start tangent point to the end tangent point by arc
        // and adding the end tangent point to the subpath.
        return this.arc( x, y, radius, startAngle, endAngle );
    }

    /**
     * Draws a SVG arc based on the given parameters
     * @param x center point of the arc on X axis
     * @param y center point of the arc on Y axis
     * @param radius radius of the arc
     * @param startAngle starting angle
     * @param endAngle ending angle
     * @param antiClockwise direction to draw
     *
     * SVG: Path based
     * Calculations and functionality referenced from
     * https://github.com/gliffy/canvas2svg
     */
    public arc(
        x: number,
        y: number,
        radius: number,
        startAngle: number,
        endAngle: number,
        antiClockwise?: boolean,
    ): GraphicsSVG {
        if ( startAngle === endAngle ) {
            return this;
        }
        startAngle = startAngle % ( 2 * Math.PI );
        endAngle = endAngle % ( 2 * Math.PI );
        /* istanbul ignore if */
        if ( startAngle === endAngle ) {
            // circle time! subtract some of the angle so svg is happy (svg elliptical arc can't draw a full circle)
            endAngle = (( endAngle + ( 2 * Math.PI )) - 0.001 * ( antiClockwise ? -1 : 1 )) % ( 2 * Math.PI );
        }

        const endX = x + radius * Math.cos( endAngle );
        const endY = y + radius * Math.sin( endAngle );
        const startX = x + radius * Math.cos( startAngle );
        const startY = y + radius * Math.sin( startAngle );
        const sweepFlag = antiClockwise ? 0 : 1;
        let largeArcFlag = 0;
        let diff = endAngle - startAngle;

        if ( diff < 0 ) {
            diff += 2 * Math.PI;
        }

        if ( antiClockwise ) {
            largeArcFlag = diff > Math.PI ? 0 : 1;
        } else {
            largeArcFlag = diff > Math.PI ? 1 : 0;
        }

        const line = `L ${startX},${startY} `;
        const arc = `A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`;

        this.currentPoint = { x: endX, y: endY };
        this.path.push( line + arc );
        return this;
    }

    /**
     * Draws a SVG rectangle based on the given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param w width of the rectangle
     * @param h height of the rectanle
     *
     * SVG: rect
     */
    public drawRect ( x: number, y: number, w: number, h: number ): GraphicsSVG {
        // Group all previous path instructions before processing current instruction
        this.generatePathInstructions();
        // NOTE: Condition checked below for inverted shapes with height is in minus.
        if ( h < 0 ) {
            y = y + h;
            h = -1 * h;
        }
        if ( w < 0 ) {
            x = x + w;
            w = -1 * w;
        }
        this.instructions.push( `<rect x="${x}" y="${y}" width="${w}" height="${h}" ${this.styles}/>` );
        return this;
    }

    /**
     * Draws a SVG rectangle based on the given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param w width of the rectangle
     * @param h height of the rectanle
     *
     * SVG: rect
     */
    public rect( x: number, y: number, w: number, h: number ): GraphicsSVG {
        return this.drawRect( x, y, w, h );
    }

    /**
     * Draws a SVG circle based on the given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param radius radius of the circle
     *
     * SVG: circle
     */
    public drawCircle( x: number, y: number, radius: number ): GraphicsSVG {
        // Group all previous path instructions before processing current instruction
        this.generatePathInstructions();
        this.instructions.push( `<circle cx="${x}" cy="${y}" r="${radius}" ${this.styles}/>` );
        return this;
    }

    /**
     * Draws a SVG ellipse based on the given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param w width of the ellipse used when drawing it with easeljs
     * @param h height of the ellipse used when drawing it with easeljs
     *
     * SVG: ellipse
     */
    public drawEllipse(  x: number, y: number, w: number, h: number ): GraphicsSVG {
        // Group all previous path instructions before processing current instruction
        this.generatePathInstructions();
        if ( w === 0 && h === 0 ) {
            w = 1;
            h = 1;
        }
        if ( h < 0 ) {
            y = y + h;
            h = -1 * h;
        }
        if ( w < 0 ) {
            x = x + w;
            w = -1 * w;
        }
        this.instructions.push(
            `<ellipse cx="${x + ( w / 2 )}" cy="${y + ( h / 2 )}" rx="${w / 2}" ry="${h / 2}" ${this.styles}/>`,
        );
        return this;
    }

    /**
     * Draws a SVG rounded rectangle based on the given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param w with of the rectangle
     * @param h height of the rectangle
     * @param radius corner radius
     *
     * SVG: rect
     */
    public drawRoundRect( x: number, y: number, w: number, h: number, radius: number ): GraphicsSVG {
        // Group all previous path instructions before processing current instruction
        this.generatePathInstructions();
        // NOTE: Condition checked below for inverted shapes with height/width is in minus.
        if ( h < 0 ) {
            y = y + h;
            h = -1 * h;
        }
        if ( w < 0 ) {
            x = x + w;
            w = -1 * w;
        }
        this.instructions.push(
            `<rect x="${x}" y="${y}" width="${w}" height="${h}" rx="${radius}" ry="${radius}" ${this.styles}/>`,
        );
        return this;
    }

    /**
     * Draws SVG rounded rectangle with variable corner angles based on given parameters.
     * @param x coordinate on X axis
     * @param y coordinate on Y axis
     * @param w width of round rect
     * @param h height of round rect
     * @param radiusTL top left corner radius
     * @param radiusTR top right corner radius
     * @param radiusBR bottom right corner radius
     * @param radisBL bottom left corner radius
     *
     * SVG: path based
     * Calculations and functionality referenced from
     * https://github.com/CreateJS/EaselJS/tree/master/extras/SVGExporter
     */
    public drawRoundRectComplex(
        x: number,
        y: number,
        w: number,
        h: number,
        radiusTL: number,
        radiusTR: number,
        radiusBR: number,
        radiusBL: number,
    ): GraphicsSVG {
        // Group all previous path instructions before processing current instruction
        this.generatePathInstructions();

        // If all corner radis are equal, it is a simple round rect
        if ( radiusTL === radiusTR && radiusTL === radiusBR && radiusTL === radiusBL ) {
            return this.drawRoundRect( x, y, w, h, radiusTL );
        }

        const code = `<path d="M ${x} ${y + radiusTL} ` +
            `a ${radiusTL},${radiusTL} 0 0 1 ${radiusTL},${-radiusTL} ` +
            `h ${w - radiusTL - radiusTR} ` +
            `a ${radiusTR},${radiusTR} 0 0 1 ${radiusTR},${radiusTR} ` +
            `v ${h - radiusTR - radiusBR} ` +
            `a ${radiusBR},${radiusBR} 0 0 1 ${-radiusBR},${radiusBR} ` +
            `h ${-w + radiusBR + radiusBL} ` +
            `a ${radiusBL},${radiusBL} 0 0 1 ${-radiusBL},${-radiusBL} ` +
            `z" ${this.styles} />`;
        this.instructions.push( code );
        return this;
    }

    /**
     * Draws SVG poly star using path instructions based on given parameters.
     * @param x X axis position of the center of the shape
     * @param y Y axis position of the center of the shape
     * @param radius The outer radius of the shape
     * @param sides The number of points on the star or sides on the polygon
     * @param pointSize The depth or "pointy-ness" of the star points. Number between 0 and 1
     * @param angle The angle of the first point / corner
     *
     * SVG: path
     * Calculations and functionality referenced from
     * https://github.com/CreateJS/EaselJS/tree/master/extras/SVGExporter
     */
    public drawPolyStar(
        x: number, y: number, radius: number, sides: number, pointSize: number, angle: number,
    ): GraphicsSVG {
        this.generatePathInstructions();
        let angl = angle / 180 * Math.PI;
        const ps = 1 - pointSize;
        const a = Math.PI / sides;
        const code = [ `M ${x + Math.cos( angl ) * radius} ${y + Math.sin( angl ) * radius}` ];
        for ( let i = 0; i < sides; i++ ) {
            angl += a;
            if ( ps !== 1 ) {
                code.push( `L ${x + Math.cos( angl ) * radius * ps} ${y + Math.sin( angl ) * radius * ps}` );
            }
            angl += a;
            code.push( `L ${x + Math.cos( angl ) * radius} ${y + Math.sin( angl ) * radius}` );
        }
        this.instructions.push( `<path d="${code.join( ' ' )} Z" ${this.styles} />` );
        return this;
    }

    /**
     *
     * @param image
     * @param repetition
     * @param matrix
     *
     * SVG: TBD
     */
    public beginBitmapFill( image: Object, repetition?: string, matrix?: any ): GraphicsSVG {
        throw Error( 'This Graphics API is not implemented in the GraphicsSVG yet.' );
    }

    /**
     *
     * @param image
     * @param repetition
     *
     *  SVG: TBD
     */
    public beginBitmapStroke( image: Object, repetition?: string ): GraphicsSVG {
        throw Error( 'This Graphics API is not implemented in the GraphicsSVG yet.' );
    }

    /**
     *
     * @param color
     *
     * SVG Attribute: fill
     */
    public beginFill( color: string ): GraphicsSVG {
        this.generatePathInstructions();
        // NOTE: EDGE browser and PDF converter
        // cannot handle HEX with opacity value, hence
        // converting that to RGBA.
        if ( color.startsWith( '#' )) {
            color = this.hexToRgbA( color );
        }
        this.fill = `fill="${color}"`;
        return this;
    }

    /**
     * Create a linear gradient fill style from given paramters.
     * Gradient must be referenced by the coresponding element using a fill url having the id of the gradient.
     * Number of offset ratios must match with the number of colors provided. If additional ratios are provided,
     * they will be ignored.
     *
     * @param colors array of colors used for the gradient
     * @param ratios array of offset values ranging from 0 to 1
     * @param x0 the X of the first point defining the line that defines the gradient direction and size
     * @param y0 the Y of the first point defining the line that defines the gradient direction and size
     * @param x1 the X of the second point defining the line that defines the gradient direction and size
     * @param y1 the Y of the second point defining the line that defines the gradient direction and size
     *
     * SVG: linearGradient
     * SVG Attribute: fill
     */
    public beginLinearGradientFill(
        colors: string[],
        ratios: number[],
        x0: number,
        y0: number,
        x1: number,
        y1: number,
    ): GraphicsSVG {
        this.generatePathInstructions();
        // Converting the HEX colors to RGB/RGBA
        for ( let i = 0; i < colors.length; i++ ) {
            if ( colors[i].startsWith( '#' )) {
                colors[i] = this.hexToRgbA( colors[i]);
            }
        }
        // Ratios values should go from 0 - 1, not viceversa.
        const sortedColors = this.sortColorsWithRatios( colors, ratios );
        colors = sortedColors[0] as string[];
        ratios = sortedColors[1] as number[];
        // Crate a string that can be used to generate a gradient id
        const idStr = colors.toString() + ratios.toString() + x0 + y0 + x1 + y1 + 'beginLinearGradientFill';
        const gradientId = this.getID( idStr );

        // Generate gradient string
        let gradientStr = `<linearGradient id="${gradientId}" ` +
            `x1="${x0}" y1="${y0}" x2="${x1}" y2="${y1}" gradientUnits="userSpaceOnUse">`;

        // Add stop color and offset ratios
        // Add all colors given and exclude ratios which do not match a color
        for ( let i = 0; i < colors.length; i++ ) {
            gradientStr += `<stop offset="${ratios[i] * 100}%" stop-color="${colors[i]}"/>`;
        }
        gradientStr += '</linearGradient>';
        this.gradients.push( gradientStr );
        this.fill = `fill="url(#${gradientId})"`;
        return this;
    }

    /**
     * Create a linear gradient stroke style from given parameters
     * @param colors
     * @param ratios
     * @param x0
     * @param y0
     * @param x1
     * @param y1
     *
     * SVG: linearGradient
     * SVG Attribute: stroke
     */
    public beginLinearGradientStroke(
        colors: string[],
        ratios: number[],
        x0: number,
        y0: number,
        x1: number,
        y1: number,
    ): GraphicsSVG {
        this.generatePathInstructions();
        // Converting the HEX colors to RGB/RGBA
        for ( let i = 0; i < colors.length; i++ ) {
            if ( colors[i].startsWith( '#' )) {
                colors[i] = this.hexToRgbA( colors[i]);
            }
        }
        // Ratios values should go from 0 - 1, not viceversa.
        const sortedColors = this.sortColorsWithRatios( colors, ratios );
        colors = sortedColors[0] as string[];
        ratios = sortedColors[1] as number[];
        // Crate a string that can be used to generate a gradient id
        const idStr = colors.toString() + ratios.toString() + x0 + y0 + x1 + y1 + 'beginLinearGradientStroke';
        const gradientId = this.getID( idStr );

        // Generate gradient string
        let gradientStr = `<linearGradient id="${gradientId}" ` +
            `x1="${x0}" y1="${y0}" x2="${x1}" y2="${y1}" gradientUnits="userSpaceOnUse">`;

        // Add stop color and offset ratios
        // Add all colors given and exclude ratios which do not match a color
        for ( let i = 0; i < colors.length; i++ ) {
            gradientStr += `<stop offset="${ratios[i] * 100}%" stop-color="${colors[i]}"/>`;
        }
        gradientStr += '</linearGradient>';
        this.gradients.push( gradientStr );
        this.fill = `stroke="url(#${gradientId})"`;
        return this;
    }

    /**
     *
     * @param colors
     * @param ratios
     * @param x0
     * @param y0
     * @param r0
     * @param x1
     * @param y1
     * @param r1
     *
     * SVG: radialGradient
     * SVG Attribute: fill
     */
    public beginRadialGradientFill(
        colors: string[],
        ratios: number[],
        x0: number,
        y0: number,
        r0: number,
        x1: number,
        y1: number,
        r1: number,
    ): GraphicsSVG {
        this.generatePathInstructions();
        // Converting the HEX colors to RGB/RGBA
        for ( let i = 0; i < colors.length; i++ ) {
            if ( colors[i].startsWith( '#' )) {
                colors[i] = this.hexToRgbA( colors[i]);
            }
        }
        // Ratios values should go from 0 - 1, not viceversa.
        const sortedColors = this.sortColorsWithRatios( colors, ratios );
        colors = sortedColors[0] as string[];
        ratios = sortedColors[1] as number[];
        // Crate a string that can be used to generate a gradient id
        const idStr = colors.toString() + ratios.toString() + x0 + y0 + r0 + x1 + y1 + r1 + 'beginRadialGradientFill';
        const gradientId = this.getID( idStr );

        // FIXME: Out radial gradient support is very basic and because of that complex gradient patterns cannot be
        // represented with data. The gradient data is wrong to produe a perfect style. The disparity exists between
        // Easeljs and SVG spec.
        const xn = r1 / 2;

        // Generate gradient string
        // NOTE: Easeljs and SVG radial gradient behave differently. For the SVG,
        // {x,y,r}0 define end circle, whereas for Easeljs those define the inner circle.
        let gradientStr = `<radialGradient id="${gradientId}" ` +
            `cx="${xn}" cy="${xn}" r="${r1}" fx="${xn}" fy="${xn}" fr="0" gradientUnits="userSpaceOnUse">`;

        // Add stop color and offset ratios
        // Add all colors given and exclude ratios which do not match a color
        for ( let i = 0; i < colors.length; i++ ) {
            gradientStr += `<stop offset="${ratios[i] * 100}%" stop-color="${colors[i]}"/>`;
        }
        gradientStr += '</radialGradient>';
        this.gradients.push( gradientStr );
        this.fill = `fill="url(#${gradientId})"`;
        return this;
    }

    /**
     *
     * @param colors
     * @param ratios
     * @param x0
     * @param y0
     * @param r0
     * @param x1
     * @param y1
     * @param r1
     *
     * SVG: radialGradient
     * SVG Attribute: fill
     */
    public beginRadialGradientStroke(
        colors: string[],
        ratios: number[],
        x0: number,
        y0: number,
        r0: number,
        x1: number,
        y1: number,
        r1: number,
    ): GraphicsSVG {
        this.generatePathInstructions();
        // Converting the HEX colors to RGB/RGBA
        for ( let i = 0; i < colors.length; i++ ) {
            if ( colors[i].startsWith( '#' )) {
                colors[i] = this.hexToRgbA( colors[i]);
            }
        }
        // Ratios values should go from 0 - 1, not viceversa.
        const sortedColors = this.sortColorsWithRatios( colors, ratios );
        colors = sortedColors[0] as string[];
        ratios = sortedColors[1] as number[];
        // Crate a string that can be used to generate a gradient id
        const idStr = colors.toString() + ratios.toString() + x0 + y0 + r0 + x1 + y1 + r1 + 'beginRadialGradientStroke';
        const gradientId = this.getID( idStr );

        // FIXME: Out radial gradient support is very basic and because of that complex gradient patterns cannot be
        // represented with data. The gradient data is wrong to produe a perfect style. The disparity exists between
        // Easeljs and SVG spec.
        const xn = r1 / 2;

        // Generate gradient string
        // NOTE: Easeljs and SVG radial gradient behave differently. For the SVG,
        // {x,y,r}0 define end circle, whereas for Easeljs those define the inner circle.
        let gradientStr = `<radialGradient id="${gradientId}" ` +
            `cx="${xn}" cy="${xn}" r="${r1}" fx="${xn}" fy="${xn}" fr="0" gradientUnits="userSpaceOnUse">`;

        // Add stop color and offset ratios
        // Add all colors given and exclude ratios which do not match a color
        for ( let i = 0; i < colors.length; i++ ) {
            gradientStr += `<stop offset="${ratios[i] * 100}%" stop-color="${colors[i]}"/>`;
        }
        gradientStr += '</radialGradient>';
        this.gradients.push( gradientStr );
        this.fill = `stroke="url(#${gradientId})"`;
        return this;
    }

    /**
     * Set stroke color for the SVG elements drawn later.
     * @param color
     *
     * SVG Attribute: stroke
     */
    public beginStroke( color: string ): GraphicsSVG {
        this.generatePathInstructions();
        this.stroke = `stroke="${color}"`;
        return this;
    }

    /**
     * Ends the current sub-path, and begins a new one with no fill color set.
     * @link https://createjs.com/docs/easeljs/classes/Graphics.html#method_endFill
     *
     * SVG: None
     */
    public endFill(): GraphicsSVG {
        // Group all previous path instructions before unsetting the fill color
        // since this command marks the end of a path instruction set
        this.generatePathInstructions();
        this.fill = 'fill="none"';
        return this;
    }

    /**
     * Ends the current sub-path, and begins a new one with no stroke color set.
     * @link https://createjs.com/docs/easeljs/classes/Graphics.html#method_endStroke
     *
     * SVG: None
     */
    public endStroke(): GraphicsSVG {
        // Group all previous path instructions before unsetting the stroke color
        // since this command marks the end of a path instruction set
        this.generatePathInstructions();
        this.stroke = '';
        return this;
    }

    /**
     * Draws line from current position to starting position of a path instruction.
     *
     * SVG: path Z
     */
    public closePath(): GraphicsSVG {
        this.path.push( 'Z' );
        return this;
    }

    /**
     * Set stroke styles to the SVG shapes being drawn after.
     * Set styles will be used for all SVG shapes until next style is set.
     * @param thickness stroke line thickness
     * @param caps stroke line caps
     * @param joints stroke joint style
     * @param miterLimit mitter limit
     * @param ignoreScale ignore transformations during scale
     *
     * SVG Attribute: stroke-width, stroke-linecap, stroke-linejoin, stroke-miterlimit
     */
    public setStrokeStyle(
        thickness: number,
        caps?: StrokeCaps,
        joints?: StrokeJoints,
        miterLimit?: number,
        ignoreScale?: boolean,
    ): GraphicsSVG {
        this.generatePathInstructions();
        this.strokeStyle = [];
        this.strokeStyle.push( `stroke-width="${thickness}"` );
        if ( caps ) {
            this.strokeStyle.push( `stroke-linecap="${caps}"` );
        }
        if ( joints ) {
            this.strokeStyle.push( `stroke-linejoin="${joints}"` );
        }
        if ( miterLimit ) {
            this.strokeStyle.push( `stroke-miterlimit="${miterLimit}"` );
        }
        if ( ignoreScale ) {
            this.strokeStyle.push( 'vector-effect="non-scaling-stroke"' );
        }
        return this;
    }

    /**
     * Set stroke dash style to the SVG shapes drawn after.
     * Set style will be used for all SVG shapes until a new style is added
     * @param segments
     * @param offset
     *
     * SVG Attribute: stroke-dasharray, stroke-dashoffset
     */
    public setStrokeDash( segments?: number[], offset?: number ): GraphicsSVG {
        this.generatePathInstructions();
        this.strokeDash = [];
        if ( segments ) {
            // NOTE: Example: stroke-dasharray="50,5,0".
            // In order to show a line with spaces in between, required
            // at lease two values greater than zero. Or all of them should
            // be zero to show the line without spaces in between.
            // Unless the dasharray has only one value like stroke-dasharray="5".
            const greaterThanZero = segments.filter( x => x > 0 );
            if ( segments.length > 1 && greaterThanZero.length === 1 ) {
                for ( let i = 0; i < segments.length; i++ ) {
                    segments[i] = 0;
                }
            }
            this.strokeDash.push( `stroke-dasharray="${segments.join( ' ' )}"` );
        }
        if ( offset ) {
            this.strokeDash.push( `stroke-dashoffset="${offset}"` );
        }
        return this;
    }

    /**
     * Returns SVG representation of the graphics instructions as a collection of HTML elements.
     */
    public toSvgString(): string {
        this.generatePathInstructions();
        return this.gradients.join( '' ) + this.instructions.join( '' );
    }

    /**
     * Generate a complete path instruction using the currently
     * set paths on this instance.
     *
     * Every drawing command must call this before processing the command.
     * Current drawing commands are,
     * - drawRect / rect
     * - drawCircle
     * - drawEllipse
     * - drawRoundRect
     * - drawRoundRectComplex
     * - drawPolyStar
     * - endFill
     * - endStroke
     *
     * Path commands must not call this because they are supposed to build a path.
     */
    protected generatePathInstructions() {
        if ( this.path.length > 0 ) {
            this.instructions.push(
                `<path d="${this.path.join( ' ' )}" ${this.styles}/>`,
            );
            this.path = [];
        }
    }

    /**
     * Return a short id string generated from any given string
     * @param str string to be used to generate an id
     */
    protected getID( str: string ): string {
        return md5( str ).substring( 0, 5 );
    }

    /**
     * Return a 2 element array created from given array by deviding each element
     * from squreroot of sum of squre of given elements
     * @param vec number array with 2 elements
     */
    protected normalizeVector( vec: number[]): number[] {
        const len = Math.sqrt( vec[0] * vec[0] + vec[1] * vec[1]);
        return [ vec[0] / len, vec[1] / len ];
    }

    /**
     * Return angle from given vector
     */
    protected getAngle( vector: number[]): number {
        // get angle (clockwise) between vector and (1, 0)
        const x = vector[0];
        const y = vector[1];
        if ( y >= 0 ) { // note that y axis points to its down
            return Math.acos( x );
        } else {
            return -Math.acos( x );
        }
    }

    /**
     * TODO: Use a utility class for converting hex to rgba or use an npm package.
     * This function will convert the HEX with or without opacity to RGB/RGBA.
     * @param hex - HEX with or without alpha/opacity
     * @returns - RGB/RGBA
     * Example: HEX - #FDCEFEDC and RGBA - rgba(253, 206, 254, 0.4)
     */
    private hexToRgbA( hex: string ) {
        hex = hex.replace( '#', '' );
        const r = parseInt( hex.substring( 0, 2 ), 16 ).toString();
        const g = parseInt( hex.substring( 2, 4 ), 16 ).toString();
        const b = parseInt( hex.substring( 4, 6 ), 16 ).toString();
        if ( hex.substring( 6, 8 )) {
            const a = ( parseInt( hex.substring( 6, 8 ), 16 ) / 255 ).toString();
            return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
        }
        return 'rgb(' + r + ',' + g + ',' + b + ')';
    }

    /**
     * This function will sort the ratios in ascending order along with
     * the colors.
     */
    private sortColorsWithRatios( colors: string[], ratios: number[]) {
        // Combine colors and ratios
        const list = [];
        for ( let j = 0; j < ratios.length; j++ ) {
            list.push({ color: colors[j], ratio: ratios[j] });
        }
        // Sort
        list.sort(( a, b ) => a.ratio - b.ratio );
        // Seprate them back
        for ( let k = 0; k < list.length; k++ ) {
            colors[k] = list[k].color;
            ratios[k] = list[k].ratio;
        }
        return [ colors, ratios ];
    }

}
