import { CommandError } from './command-error';
/**
 * CommandResultError is a command error
 * which occurs when sending a message to neutrino server.
 *
 * @author  Gobiga
 * @since   2016-04-12
 *
 */
export class CommandResultError extends CommandError {
    private _code: Number;

    constructor ( errorCode: Number, message: string ) {
        super( message )/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, CommandResultError.prototype );
        this._code = errorCode;
    }

    public get code(): Number {
        return this._code;
    }
}
