import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DataType, EntityLinkType, IDataItem, IEntityLink } from 'flux-definition/src';
import { Observable } from 'rxjs';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { values } from 'lodash';


@Injectable()
@Command()
export class CreateReversedLookupField extends AbstractEDataChangeCommand {
    public data: {
        entityDefId: string;
    };

    prepareData(): void | Observable<any> {
        const entityDef = this.changeModel.customEntityDefs[this.data.entityDefId];
        const lookupFields = values( entityDef.dataItems )
            .filter( d => d.type === DataType.LOOKUP )
            .filter( d => d.options.eDataId === this.changeModel.id ) as IDataItem<DataType.LOOKUP>[];
        const sameTypeFields = {};
        const differentTypeFields = {};
        lookupFields.forEach( lf => {
            if ( lf.options.eDefId === this.data.entityDefId ) {
                sameTypeFields[lf.id] = lf;
            } else {
                differentTypeFields[lf.id] = lf;
            }
        });
        for ( const dId in sameTypeFields ) {
            const reversedId = dId.split( '' ).reverse().join( '' );
            if ( sameTypeFields[reversedId]) {
                continue;
            }
            this.createMirrorField( entityDef, reversedId, sameTypeFields[dId]);
        }
        for ( const dId in differentTypeFields ) {
            const lf = differentTypeFields[dId];
            if ( lf.options.isMirrorField ) {
                continue;
            }
            if ( !this.changeModel.customEntityDefs[lf.options.eDefId]) {
                this.changeModel.customEntityDefs[lf.options.eDefId] = {
                    id: lf.options.eDefId,
                    dataItems: {},
                } as any;
            }
            const eDef = this.changeModel.customEntityDefs[lf.options.eDefId];
            if ( eDef.dataItems[dId]) {
                continue;
            }
            this.createMirrorField( eDef, dId, lf );
        }
    }

    protected createMirrorField(
        entityDef, lookupId: string, lookupField: IDataItem<DataType.LOOKUP>,
    ) {
        const eDefId = this.data.entityDefId;
        const dataItem = {
            id: lookupId,
            type: DataType.LOOKUP,
            layout: 'block',
            value: [],
            default: [],
            options: {
                eDataId: this.changeModel.id,
                eDefId,
                allowMultiple: true,
                isMirrorField: true,
            },
            label: `_${lookupField.label}`,
            labelEditable: false,
            optional: true,
            visibility: [
                { type: 'editor' },
            ],
            validationRules: {},
            isTypeBound: true,
            removeable: false,
        };
        entityDef.dataItems = { ...entityDef.dataItems, [dataItem.id]: dataItem as any };
        if ( Array.isArray( lookupField.value ) && lookupField.value.length > 0 ) { // has connections
            const entityObj = Object.values( this.changeModel.entities )
                .find( e => e.eDefId === eDefId && e.data[lookupField.id] && e.data[lookupField.id].length > 0 );
            const value = entityObj ? entityObj.data[lookupField.id] : [];
            const shouldBeTruthy = lookupField.value.every(( val, index ) => value[index] === val );
            if ( shouldBeTruthy ) {
                lookupField.value.forEach( entId => {
                    const entity = this.changeModel.entities[ entId ];
                    entity.data[lookupId] = [ entityObj.id ];
                    if ( lookupField.id !== lookupId && !!entity.links ) { // same type mirror field
                        let matchedLink: IEntityLink;
                        for ( const linkId in entity.links ) {
                            const link = entity.links[linkId];
                            const matching = link.type === EntityLinkType.LOOKUP &&
                                link.handshake === lookupField.id &&
                                link.eDataId === this.changeModel.id &&
                                link.entityId === entityObj.id;
                            if ( matching ) {
                                matchedLink = link;
                                break;
                            }
                        }
                        if ( !!matchedLink ) {
                            matchedLink.handshake = lookupId;
                        }
                    }
                });
            }
        }
    }
}

Object.defineProperty( CreateReversedLookupField, 'name', {
    value: 'CreateReversedLookupField',
});
