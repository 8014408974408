import { ChangeDetectionStrategy, Component, Input, Output,
    EventEmitter, ViewChild, ElementRef, OnInit, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { uniq, isEmpty } from 'lodash';
/**
 * This is the tags input. In this component, the user will
 * type in a set of strings separated by commas into the input box and when they
 * focus out, these values will be emitted and converted into tag-items.
 *
 * To delete a tag the user can hover over the tag and click on the x.
 *
 * @author nuwanthi
 * @since 2018-18-06
 */

@Component({
    templateUrl: './tags-input-field.cmp.html',
    selector: 'tags-input-field',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TagsInputField implements OnInit, OnChanges  {

    /**
     * Tags input element.
     */
    @ViewChild( 'inputElem' ) public inputElement: ElementRef;

    /**
     * Array containing the tags items.
     */
    @Input() public items: string[] = [];

    /**
     * Label describing the tags input field.
     */
    @Input() public label: string;

    /**
     * placeholder of the tags input field.
     */
    @Input() public placeholder: string;

    /**
     * Event emitter that emits the updated tags.
     */
    @Output() public updateTags: EventEmitter<string> = new EventEmitter();

    /**
     * Defines if the component is readonly.
     */
    @Input() public readonly: boolean = false;

    /**
     * Subject thats used to reflect newly added/removed
     * tags.
     */
    public _items: BehaviorSubject<Array<string>>;

    /**
     * Current tag items.
     */
    protected currentItems: string[] = [];

    /**
     * Returns all the tag items as an array.
     */
    protected get allTags(): Array<string> {
        return [ ...this.items, ...this.currentItems ];
    }

    /**
     * Returns the native element of the tags input element.
     */
    protected get inputNativeElement(): HTMLInputElement {
        return <HTMLInputElement>this.inputElement.nativeElement;
    }

    /**
     * Returns the value of the input element.
     */
    protected get inputText(): string {
        return this.inputNativeElement.value;
    }

    /**
     * Displays the tags in the ui.
     */
    public ngOnInit() {
        this._items = new BehaviorSubject( this.allTags );
    }

    /**
     * Update the _items array when input item change
     */
    public ngOnChanges() {
        this.currentItems = [];
        if ( this._items ) {
            this._items.next( this.items );
        }
    }

    /**
     * Method that combines the new tags to the items array and
     * emits the updated tags string.
     */
    public emitTags() {
        this.updateTags.next( this.allTags.toString());
    }

    /**
     * Method that creates the tags and update the UI.
     */
    public createTag( event: KeyboardEvent ) {
        const newtagsText = this.inputText.trim();

        if ( event.key === 'Enter' || newtagsText.includes( ',' )) {
            const tags = newtagsText.split( ',' ).filter( t => !!t );

            if  ( tags.length ) {
                const items = [ ...this.currentItems, ...tags ];
                this.currentItems = uniq( items ).filter( tag => !this.items.includes( tag ));
                this._items.next( this.allTags );
            }
            this.inputNativeElement.value = '';
        }
    }

    /**
     * Method that removes the selected tag from the items array,
     * update the UI and emits the updated tags string.
     */
    public deleteTag( tag: string ) {
        if ( !isEmpty( tag )) {
            this.currentItems = this.allTags.filter( item => item !== tag );
            this._items.next( this.currentItems );
            this.updateTags.next( this.currentItems.toString());
        }
    }
}

