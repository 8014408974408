import { Subscription } from 'rxjs';
import { StateService } from './state.svc';
import { isArray } from 'lodash';
import { Directive, Input, Inject, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

/**
 * IfStateClass
 * This directive is used to control the presence a given class or classes
 * depending on state
 *
 * Example:
 * <button [ifStateClass]="['MouseControlState','normal','active']" >
 * <button [ifStateClass]="['MouseControlState','pan','active small other']" >
 * <button [ifStateClass]="['MouseControlState','connector','active','line']" >
 *
 * @author  thisun
 * @since   2017-09-20
 */

@Directive({
    selector: '[ifStateClass]',
})

export class IfStateClass implements OnInit, OnDestroy {

    /**
     * Input property to hold state key and value pair and classes as an array
     */
    @Input()
    public ifStateClass: Array<any>;

    /**
     * Statge changes subscription, to be unscribed on ngOnDestroy
     */
    protected subscription: Subscription;

    constructor(
        @Inject( StateService ) protected state: StateService<any, any>,
        protected renderer: Renderer2, protected hostElement: ElementRef,
    ) {}

    public ngOnInit() {
        if ( !this.ifStateClass || !isArray( this.ifStateClass ) ||
        ( this.ifStateClass.length !== 3 && this.ifStateClass.length !== 4 )) {
            throw new Error( 'The ifStateClass directive requires an array ' +
                'containing state name, state value and class name to be passed as an input' );
        }
        const stateKey = this.ifStateClass[0];
        const stateValue = this.ifStateClass[1];
        const className = this.ifStateClass[2];
        const altstate = this.ifStateClass[3];

        this.subscription = this.state.changes( stateKey ).subscribe( state =>  {
            if ( state === stateValue || state === altstate ) {
                this.renderer.addClass( this.hostElement.nativeElement, className );
            } else {
                this.renderer.removeClass( this.hostElement.nativeElement, className );
            }
        });
    }

    public ngOnDestroy() {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }


}
