import { AbstractArrowHead, IDrawArrowheadOptions } from './arrow-head-abstract';
import { IGraphics } from 'flux-definition';

/**
 * PointerDouble arrow head entry class is one of the default arrow head types.
 *
 * @author  Mehdhi
 * @since   2018-08-21
 */
/* istanbul ignore next */
export class PointerDouble extends AbstractArrowHead {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void {
        const bounds = options.bounds;
        const width = bounds.width / 0.885;
        const height = bounds.height / 0.885;
        const gapBetweenArrow = bounds.width / 1.9167;
        const arrowWidth = width - gapBetweenArrow;

        this.moveTo( graphics, { x: bounds.x, y: bounds.y }, options.matrix );
        this.lineTo( graphics, { x: bounds.x - arrowWidth, y: bounds.y - height / 2 }, options.matrix );
        this.moveTo( graphics, { x: bounds.x, y: bounds.y }, options.matrix );
        this.lineTo( graphics, { x: bounds.x - arrowWidth, y: bounds.y + height / 2 }, options.matrix );
        this.moveTo( graphics, { x: bounds.x - gapBetweenArrow, y: bounds.y }, options.matrix );
        this.lineTo( graphics, {
            x: bounds.x - arrowWidth - gapBetweenArrow,
            y: bounds.y - height / 2,
        }, options.matrix );
        this.moveTo( graphics, { x: bounds.x - gapBetweenArrow, y: bounds.y }, options.matrix );
        this.lineTo( graphics, {
            x: bounds.x - arrowWidth - gapBetweenArrow,
            y: bounds.y + height / 2,
        }, options.matrix );
        graphics.closePath();
    }
}

Object.defineProperty( PointerDouble, 'name', {
    value: 'PointerDouble',
});
