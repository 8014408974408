/**
 * Interface that holds the structure of FolderPanelDataChangeEvent
 */
export interface IFolderPanelDataChangeEvent {
    id: string;
    type: 'diagram' | 'project';
    action: 'rename' | 'delete' | 'create' | 'move' | 'archived';
    payload: any;
}

/**
 * Enum to categorize the folder panel categories
 */
export enum FolderPanelCategories {
    RECENT = 'recent-items',
    YOUR_FOLDERS = 'your-folders',
    TEAM_FOLDERS = 'team-folders',
    SHARED_FOLDERS = 'shared-folders',
    ARCHIVED_FOLDERS = 'archived-folders',
}
