import { Component, Input, EventEmitter, Output } from '@angular/core';


/**
 * ThumbnailCarousalItem
 * Thumbnail carousal item with image url and title
 *
 * @author  Thisun
 * @since   2017-07-27
 */

@Component({
    selector: 'thumbnail-carousel-item',
    template: `
        <div (mouseenter)="tcHover.emit($event)" (mouseleave)="tcLeave.emit($event)">
            <h3 class="tci-title fx-ellipsis white">{{title}}</h3>
            <link-thumbnail-image (click)="tcClick.emit($event)" class="tci-image" [url]="thumbnailUrl">
            </link-thumbnail-image>
        <div>`,
})
export class ThumbnailCarousalItem {

    @Input()
    public title: string;

    @Input()
    public thumbnailUrl: string;

    @Output()
    public tcClick: EventEmitter<MouseEvent>;

    @Output()
    public tcHover: EventEmitter<MouseEvent>;

    @Output()
    public tcLeave: EventEmitter<MouseEvent>;

    constructor() {
        this.tcClick = new EventEmitter<MouseEvent>();
        this.tcHover = new EventEmitter<MouseEvent>();
        this.tcLeave = new EventEmitter<MouseEvent>();
    }

}
