import { Matrix } from 'flux-core';

/**
 * Values a fillType can take
 */
export enum FillType {
    solid = 'solid',
    radialGradient = 'radialGradient',
    linearGradient = 'linearGradient',
}

/**
 * Values a strokeLinecap can take
 */
export enum StrokeLinecap {
    butt = 'butt',
    round = 'round',
    square = 'square',
}

/**
 * Values a strokeLinejoin can take
 */
export enum StrokeLinejoin {
    miter = 'miter',
    round = 'round',
    bevel = 'bevel',
}

/**
 * Values a vactorEffect can take
 */
export enum VectorEffect {
    'non-scaling-stroke' = 'non-scaling-stroke',
    default = 'default',
    inherit = 'inherit',
}

/**
 * This defines the structural node which represents structural elements if svg being converted.
 * Each attribute of a structural element will be extracted. There is no default values for any of the
 * attributes and only the attributes of svg structural element must be populated.
 *
 * Transformation matrix need to be calculated each time the node is being created or inherited by another node.
 * Transformation matrix MUST represent final state as it applies to a rendered svg shape.
 */
export interface IStructuralNode {
    /**
     * What type of structural node is this. can be g or svg
     */
    type: string;

    /**
     * If the structure node has parents with specific ids set, they need to be added to this.
     * ie: <g id="repeated-group"><g></g></g>
     */
    parentIds: string[];

    /**
     * What type of fill type the structure will have
     */
    fillType?: FillType;

    /**
     * Fill the structure has, it will be a color string if fillType is solid or
     * a gradient id if othervice
     */
    fill?: string;

    /**
     * Opacity the fill color will have. This can be any number from 0 to 1
     */
    fillOpacity?: number;

    /**
     * Opacity the objects will have, this applies to all objects this structure has any number from 0 to 1
     */
    opacity?: number;

    /**
     * What type of stroke the shape has, this can be solod color, linearGradient of radialGradient
     */
    strokeType?: FillType;

    /**
     * Stroke the shape has, this can be a solid color or an id of a gradient
     */
    stroke?: string;

    /**
     * Defines the pattern of dashes and gaps the shape outlines will have
     */
    strokeDasharray?: number[];

    /**
     * Defines offset while rendering an associated dash array
     */
    strokeDashoffset?: number;

    /**
     * Defines what shape is used when rendering start of sub paths when stroke is applied
     * Value can be butt, round or square
     */
    strokeLinecap?: StrokeLinecap;

    /**
     * Defines what shape is used when rendering end of sub paths when stroke is applied
     * Value can be miter, round or bevel
     */
    strokeLinejoin?: StrokeLinejoin;

    /**
     * Specify a miter limit ratio which controls at what point a mitered joint will be clipped
     */
    strokeMiterlimit?: number;

    /**
     * Opacity applied to a stroke, This can be any number from 0 to 1
     */
    strokeOpacity?: number;

    /**
     * Width of the stroke
     */
    strokeWidth?: number;

    /**
     * Whether the shape outlines should scale with the shape
     * Values can be,
     * non-scaling-stroke : outline must not scale
     * default : defualt behaviour, which allows scaling
     * inherit: inherit the attribute from parent
     */
    vectorEffect?: VectorEffect;

    /**
     * Transformation matrix
     */
    transform?: Matrix;
}
