<div class="user-strip-container">
    <div class="user-strip-img-wrapper"
        *ngFor= "let collabview of (collabs | async) ; trackBy: getCollabTrackingId"
        [ngStyle]="{ 'margin-left': collabview.position + 'px', 'z-index': collabview.index }"
        [ngClass]="{ 'active': collabview.collab && collabview.collab.online, 'inactive': collabview.collab && !collabview.collab.online }">

        <div *ngIf="!!collabview.collab" tooltip placement="bottom" style="display: inline-block;" [class.disable]="!shouldShowToolTip">
            <user-image
                imageSize="small"
                [isUserStripMode]="true"
                [realtimeUserColor]="collabview.realtimeUserColor"
                [hasImage]="collabview.collab.hasImage"
                [imagePath]="collabview.collab.image"
                [userColor]="collabview.collab.displayColor"
                [userInitials]="collabview.collab.initial"
                [userRegistered]="collabview.collab.isRegistered"
                [trackAction]="{ event: 'header.right.avatar.click' }"
                [userOnlineStatus]="collabview.collab.online"
                [spotlightState]="collabview.spotlightState"
                [spotlightRequestingState]="collabview.spotlightRequestingState"
                (click)="selected.next(collabview.collab.id)">
            </user-image>

            <div class="tooltip-content user-info-bubble">
                <p class="body user-info-bubble-capitalize user-info-bubble-fullname">{{collabview.collab.fullName}}</p>
                <div *ngIf="userHasEditPermission">
                    <span class="caption white" *ngIf="collabview.email && !collabview.collab.isGuestUser">
                        {{collabview.email}}{{" - "}}</span>
                    <span class="caption white">
                        <label class="user-info-bubble-capitalize">
                            <span translate>{{getCollabRole(collabview.collab)}}</span>
                            <span *ngIf="collabview.collab.isGuestUser">
                                <span> - </span>
                                <span translate>{{collabview.collab.roleTitle}}</span>
                            </span>
                        </label>
                    </span>
                    <p *ngIf="userHasEditPermission" class="caption user-info-bubble-seen user-info-bubble-capitalize">{{collabview.collab.lastSeenMoment | translate }}</p>
                    <p *ngIf="(showActiveOnly && (collabs | async)?.length > 1)" class="caption user-info-bubble-seen">
                        <label translate>{{getFollowMessageTemplate(collabview.currentUser, collabview.spotlightState, collabview.followState)}}</label>
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!collabview.collab" style="display: inline-block;">
            <abs-dropdown
                class="export-drop-down" #dropdownMenu
                [settings]="{ closeOnItemClicked: true, openOnHover: true, closeOnBlur: true, multiselectable: false }">
                <simple-dropdown-button ddbutton
                    [items]="collabview.removed">
                    <div class="user-count-inner fx-round">
                        <span [ngClass]="{ 'active': collabview.onlineStatus, 'inactive': !collabview.onlineStatus }">+{{collabview.remainingCount}}</span>
                    </div>
                </simple-dropdown-button>
                <simple-dropdown-item dditem *ngFor="let collab of collabview.truncated" [item]="collab" (click)="selected.next( collab.id )">
                    <div class="header-dropdown-item">
                        <a class="menu-dropdown-item" style="display: flex; align-items: center; padding: 2px 0px 2px">
                            <user-image
                                imageSize="xxsmall"
                                [hasImage]="collab.hasImage"
                                [imagePath]="collab.image"
                                [userColor]="collab.displayColor"
                                [userInitials]="collab.initial"
                                [userRegistered]="collab.isRegistered"
                                [userOnlineStatus]="true"
                                [inline]="1">
                            </user-image>
                            &nbsp;{{collab.fullName}}
                        </a>
                    </div>
                </simple-dropdown-item>
            </abs-dropdown>
        </div>
    </div>
</div>
