import { ShapeLinkModel, ShapeLinkType } from 'flux-diagram';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { findIndex } from 'lodash';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { AbstractShapeModel } from 'flux-diagram-composer';

/**
 * This ApplyShapelink command is to update the link model of shapes or groupes
 */
@Injectable()
@Command()
export class ApplyShapelink extends AbstractDiagramChangeCommand {
    public static get dataDefinition(): {}  {
        return {
            source: true,
            link: false,
        };
    }

    /**
     * Command input data format
     */
    public data: {
        source: {
            id: string,
            type: ShapeLinkType.SHAPE | ShapeLinkType.GROUP,
        },
        link: ShapeLinkModel,
        curLink?: ShapeLinkModel,
        isDelete: boolean,
    };

    public prepareData() {
        let model: any;
        if ( this.data.source.type === ShapeLinkType.SHAPE ) {
            model = this.changeModel.shapes[this.data.source.id];
        } else if ( this.data.source.type === ShapeLinkType.GROUP ) {
            model = this.changeModel.groups[this.data.source.id];
        }

        if ( model ) {
            this.data.link.shapeId = this.data.source.id;
            this.addRemoveLinks( model, this.data.link, this.data.isDelete, this.data.curLink );
            // if ( this.data.link.type ===  ShapeLinkType.SHAPE ) {
            //     const targetModel = this.changeModel.shapes[ this.data.link.targetId ];
            //     if ( targetModel ) {
            //         const inverseLink = this.reverseLink( this.data.link );
            //         this.addRemoveLinks( targetModel, inverseLink, this.data.isDelete );
            //     }
            // }
        }
    }

    private addRemoveLinks( model: AbstractShapeModel,
                            sLink: ShapeLinkModel,
                            isDelete: boolean,
                            curLink?: ShapeLinkModel ) {
        if ( model ) {
            if ( !isDelete ) {
                if ( !model.links ) {
                    model.links = [];
                }
                const links = [ ...model.links ];
                const idx = findIndex( links, link => link.link === curLink ? curLink.link : sLink.link );
                if ( idx === -1 ) {
                    links.push( sLink );
                } else {
                    // replace
                    links.splice( idx, 1 );
                    links.push( sLink );
                }
                model.links = links; // so that sakota stores this!
            } else {
                // remove the link
                const links = [ ...model.links ];
                const idx = findIndex( links, link => link.link === sLink.link );
                links.splice( idx, 1 );
                if ( links.length > 0 ) {
                    model.links = links;
                } else {
                    delete model.links;
                }

            }
        }
    }

    // private reverseLink ( link: ShapeLinkModel ): ShapeLinkModel {
    //     const inv: ShapeLinkModel = cloneDeep( link );
    //     inv.shapeId = link.targetId;
    //     inv.targetId = link.shapeId;
    //     inv.link = inv.link.replace( link.targetId, link.shapeId );
    //     inv.isInbound = true;
    //     return inv;
    // }
}

Object.defineProperty( ApplyShapelink, 'name', {
    value: 'ApplyShapelink',
});
