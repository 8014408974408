import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, Random } from 'flux-core';
import { EMPTY, Observable } from 'rxjs';
import { EDataModel } from '../model/edata.mdl';

/**
 * CreateEData abstract command
 */
@Command()
export abstract class CreateEData extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ISyncMessageCommand', 'IProjectCommand' ];
    }

    public static get dataDefinition() {
        return {
            id: false,
            createdTime: false,
            name: false,
            defId: false,
        };
    }

    public prepareData(): void {
        if ( !this.data ) {
            this.data = {};
        }

        if ( !this.data.id ) {
            this.data.id = Random.eDataId();
        }
        if ( !this.data.createdTime ) {
            this.data.createdTime = Date.now();
        }
        if ( !this.data.name ) {
            this.data.name = 'UML Model';
        }
        this.data.rid = this.resourceId;

        if ( !this.resultData ) {
            this.resultData = {};
        }
        this.resultData.eDataId = this.data.id;
        this.resultData.projectId = this.resourceId;
    }

    /**
     * This function will add the newly created diagram into the
     * Recent Diagrams List and Project Diagrams List.
     */
    public executeResult( response: any ): Observable<EDataModel> {
        if ( response && response[ 1 ] && response[ 1 ].model ) {
            return response[ 1 ].model.id;
        }
        return;
    }

    /**
     * Removed adding a empty diagram to the data store.
     * For the moment, the newly created diagram will always
     * be added to the storage when its diagram subscription
     * starts. Diagram can be added to the storage when we
     * add full offline diagram creation support.
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

}

Object.defineProperty( CreateEData, 'name', {
    value: 'CreateEData',
});
