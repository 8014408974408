import { Injectable } from '@angular/core';
import { AbstractCommand, AbstractNotification, Command,
    CommandInterfaces, NotificationType, NotifierController } from 'flux-core';
import { Subject, of } from 'rxjs';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { switchMap, take } from 'rxjs/operators';
import { LOOKUP_CONNECTOR } from '../../../../../../libs/flux-diagram-composer/src';
import { Notifications } from '../../../base/notifications/notification-messages';
import { TranslateService } from '@ngx-translate/core';

/**
 * Ask user if they want to remove the link associate with the connector shape.
 * data: {
 *     shapeIds - ids of the shape that needs to be removed
 *     selected - To indicate whether to delete the selected shapes or given ids.
 *                Delete the selected one if this is set to true or not given in the data
 * }
 */
@Injectable()
@Command()
export class RemoveLinkDialog extends AbstractCommand {

    public static get dataDefinition() {
        return {
            shapeIds: true,
        };
    }

    /**
     * This command is used to retrieve data. Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor(
        private dll: DiagramLocatorLocator,
        private notifierController: NotifierController,
        private translate: TranslateService,
    ) {
        super()/* istanbul ignore next */;
    }

    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    public execute() {
        if ( this.data.shapeIds.length !== 1 ) {
            return of( true );
        }
        const shapeId = this.data.shapeIds[0];
        return this.dll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getShapeOnce( shapeId )),
            switchMap( shape => {
                if ( !shape.isConnector()) {
                    return of( true );
                }
                if ( shape.defId !== LOOKUP_CONNECTOR.defId ) {
                    return of( true );
                }
                const s = new Subject<boolean>();
                this.showRemoveLinkDialog( s, shape );
                return s;
            }),
        );
    }

    protected showRemoveLinkDialog( s: Subject<boolean>, shape ) {
        const translate = this.translate.instant.bind( this.translate );
        const notificationData = {
            id: Notifications.REMOVE_ASSOCIATED_LINK,
            component: AbstractNotification,
            type: NotificationType.Neutral,
            collapsed: false,
            options: {
                inputs: {
                    heading: translate( 'NOTIFICATIONS.EDATA.REMOVE_ENTITY_LINK.HEADING' ),
                    description: translate( 'NOTIFICATIONS.EDATA.REMOVE_ENTITY_LINK.DESCRIPTION' ),
                    autoDismiss: false,
                    buttonOneText: translate( 'NOTIFICATIONS.EDATA.REMOVE_ENTITY_LINK.BUTTON_ONE_TEXT' ),
                    buttonTwoText: translate( 'NOTIFICATIONS.EDATA.REMOVE_ENTITY_LINK.BUTTON_TWO_TEXT' ),
                    onDismiss: () => {
                        s.error( new Error( 'User Opted not to delete' ));
                    },
                    buttonOneAction: () => {
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_LINK );
                        shape.$$removeLinks = true;
                        s.complete();
                    },
                    buttonTwoAction: () => {
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_LINK );
                        s.complete();
                    },
                },
            },
        };
        return this.notifierController.show( Notifications.REMOVE_ASSOCIATED_LINK, notificationData.component,
            notificationData.type, notificationData.options, notificationData.collapsed );
    }

}

Object.defineProperty( RemoveLinkDialog, 'name', {
    value: 'RemoveLinkDialog',
});
