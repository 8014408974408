import { Injectable } from '@angular/core';
import { IResponsibility, Logger } from 'flux-core';
import { DataStore } from 'flux-store';
import {
    InitializationChainStatus,
} from 'flux-core';
import { UserLocator } from 'flux-user';
import { UserModel } from 'flux-user';
import { AbstractPermissionResponsibility, PermissionStatus } from './abstract-permission-responsibility';
import { ResourceModel } from '../../model/resource.mdl';
import { TeamShareModel } from '../../model/team-share.mdl';
import { DiagramInfoModel } from 'flux-diagram/models';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the level of privacy
 * priviliges a user has a on a given resource.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-02-04
 */
@Injectable()
export class PrivacyResponsibility extends AbstractPermissionResponsibility implements IResponsibility  {

    public name: string = 'PrivacyResponsibility';

    constructor( protected logger: Logger,
                 protected dataStore: DataStore,
                 protected userLocator: UserLocator ) {
        super( logger, dataStore, userLocator );
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string [] {
        // if ( status.states.PrivacyResponsibility !== PermissionStatus.GRANTED ) {
            // return [ 'RolePermissionResponsibility' ];
        // }
        // return [];
        return [ 'RolePermissionResponsibility' ];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ) {
        // if ( status.states.PrivacyResponsibility === PermissionStatus.GRANTED ) {
        //     return { type: 'complete' };
        // }
    }

    /**
     * Checks what level of privacy permission the user has on the
     * resource he/she is trying to view or edit.
     * Returns a {@link PermissionStatus} value representing the level
     * of privacy the user has on the said resource.
     * @param status - current status of the sequence
     * @param resource - resource model of the resource attempted to be viewed or edited
     * @param user - user model of the current user
     * @return privacy permission status
     */
    protected checkPermission(
            status: InitializationChainStatus,
            resource: ResourceModel,
            user: UserModel ): PermissionStatus {
        const permStatus: PermissionStatus = this.getTeamSharePermStatus( status, resource, user );
        if ( permStatus !== PermissionStatus.ABOVE_REQUIREMENT && resource instanceof DiagramInfoModel ) {
            const projectPermStatus: PermissionStatus = this.getTeamSharePermStatus( status, resource.project, user );
            return this.getMaxPermissionStatus(  permStatus, projectPermStatus );
        }
        return permStatus;
    }

    /**
     * Get the permission status from given resource for given user.
     * @param status
     * @param resource
     * @param user
     * @returns
     */
    protected getTeamSharePermStatus( status: InitializationChainStatus,
                                      resource: ResourceModel, user: UserModel ): PermissionStatus {
        if ( resource.id === 'home' ) {
            return PermissionStatus.DENIED;
        }
        const publicPerm = resource.teamShare.find( teamShare => teamShare.canAnyoneAccess());
        const teamPerm = this.getUserTeamPerm( user, resource );
        let perStatus: PermissionStatus = PermissionStatus.DENIED;
        let role = 1000; // no role
        if ( publicPerm || teamPerm ) {
            if ( teamPerm ) {
                role = teamPerm.role;
                perStatus = this.checkRolePermission( status, teamPerm.role );
            }
            if ( !teamPerm ||
                ( perStatus !== PermissionStatus.GRANTED && perStatus !== PermissionStatus.ABOVE_REQUIREMENT &&
                    publicPerm && publicPerm.role < teamPerm.role )) {
                role = publicPerm.role;
                perStatus = this.checkRolePermission( status, publicPerm.role );
            }
        }
        if ( perStatus !== PermissionStatus.GRANTED && perStatus !== PermissionStatus.ABOVE_REQUIREMENT &&
            resource.groupShare?.length > 0 && user?.teamGroupIds?.length > 0 ) {
            const groupShares = resource.groupShare?.filter( gs => user.teamGroupIds.includes( gs.id )) || [];
            if ( groupShares.length > 0 ) {
                const gRole = Math.min( ...groupShares.map( gs => gs.role ));
                if ( gRole < role ) {
                    return this.checkRolePermission( status, gRole );
                }
            }
        }
        return perStatus;
    }

    protected getUserTeamPerm( user: UserModel, resource: ResourceModel ): TeamShareModel {
        return user?.team ? resource.teamShare.find( ts => ts.teamId === user.team.id ) : undefined;
    }
}
