import { ITextParser } from 'flux-definition';

class UMLClassExtPointSectionParser implements ITextParser<string> {
    static id = 'creately.uml.extensionpoint';

    public static get instance(): UMLClassExtPointSectionParser {
        return this._instance;
    }

    private static _instance = new UMLClassExtPointSectionParser();

    parse( input: string ) {
        const trimmed = input.trim();
        const parseErrors = [];
        const parsedText = trimmed.replace( /[<>]/gm, '' );
        return {
            ast: parseErrors.length > 0 ? '' : parsedText.trim(),
            lexResult: {},
            parseErrors: parseErrors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        return {
            suggestions: [],
            searchTerm: '',
        };
    }
}

export default UMLClassExtPointSectionParser;
