import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * This component is used to show the shape thumbanil image
 *
 * @author  gobiga
 * @since   26/07/2017
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'thumbnail-image',
    template: `
        <div class="thumb-img-container fx-center-all" [class.loading]="loading | async">
            <div class="thumb-img-loader-container white">
                <shape-thumbnail-loader></shape-thumbnail-loader>
            </div>
            <div class="thumb-img-image-container fx-center-all" [class.preview]="isPreview">
                <img #thumbnail [src]="url" (load)="imageLoaded()" draggable="false" />
            </div>
        </div>`,
    styleUrls: [ './thumbnail-image.scss' ],
})
export class ThumbnailImage {

    /**
     * This property holds the url of the  link thumbnail image
     */
    @Input()
    public url: string;

    /**
     * Preview is used to define a seperate canvas id for RetinaStage.
     */
    @Input() public isPreview: boolean = false;

    /**
     * Subject that is used to show and hide the loading indicator.
     */
    public loading: BehaviorSubject<boolean>;

    constructor() {
        this.loading = new BehaviorSubject( true );
    }

    public imageLoaded() {
        // FIXME : 0 Delay is added because without the delay the template is not updating sometimes.
        setTimeout(() => {
            this.loading.next( false );
        }, 0 );
    }
}
