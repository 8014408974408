import { Injectable } from '@angular/core';
import { AbstractCommand, Command, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { PresentationSubscriptionService } from '../presentation-subscription.svc';
import { PresentationSub } from '../presentation.sub';

/**
 * Starts the task subscription for the current user.
 */
@Injectable()
@Command()
export class StartPresentationSubscription extends AbstractCommand {

    static currentSub: PresentationSub;

    constructor(
        protected presentationSub: PresentationSubscriptionService,
        protected datastore: DataStore,
        protected state: StateService<any, any>,

    ) {
        super()/* istanbul ignore next */;
    }

    public execute() {
        if ( !StartPresentationSubscription.currentSub || !StartPresentationSubscription.currentSub.isActive()) {
            const sub = new PresentationSub( this.data.projectId, this.datastore );
            this.presentationSub.start( sub );
            StartPresentationSubscription.currentSub = sub;
        }
        return true;
    }
}

Object.defineProperty( StartPresentationSubscription, 'name', {
    value: 'StartPresentationSubscription',
});
