import { Observer } from 'rxjs';
import { Logger } from 'flux-core';
import { IMessageJson } from './message-json';
import { ConnectionError } from '../connection/connection-error';

/**
 * MessageWatchList
 * This is a map that can be used to store Subscribser for each message
 * sent by Electron. The class enables you to manage a active list of messages.
 *
 * @author  mehdhi
 * @date    20-04-2016
 */
export class MessageSubscriberList {

    /**
     * This holds all the values ( Observer) for the respective key which is the messasge id
     *
     * @private
     * @type {{ [key: string]: Observer<IMessageJson> }}
     */
    private _map: { [key: string]: Observer<IMessageJson> };

    /**
     * Creates an instance of MessageSubscriberList.
     *
     * @param {Logger} log
     */
    constructor( log: Logger ) {
        this._map = {};
    }

    /**
     * This method adds a Subscriber/Observer to associated to
     * a message to the list
     *
     * @param {string} messageId The unique ID of the message
     * @param {Observer<IMessageJson>} observer The Observer implementation instance
     */
    public add( messageId: string, observer: Observer<IMessageJson> ) {
        if ( this.has( messageId ) && this.get( messageId ) !== observer ) {
            throw new ConnectionError( 'Trying to replace subsciber for a existing messageId' );
        }
        this._map[ messageId ] = observer;
    }

    /**
     * This method resolves a Subscriber/Observer associated to
     * a message in the list with the respective message recieved from server
     *
     * @param {IMessageJson} message The Observer implementation instance
     */
    public emit( message: IMessageJson ) {
        if ( this.has( message.id )) {
            this.get( message.id ).next( message );
        } else {
            throw new ConnectionError( 'Trying to emit to a subsciber that doesnt exist for the message.id' );
        }
    }

    /**
     * This method resolves a Subscriber/Observer associated to
     * a message in the list with the respective message recieved from server
     * and deletes the Subscriber from the list
     *
     * @param {IMessageJson} message The Observer implementation instance
     */
    public resolve( message: IMessageJson ) {
        if ( this.has( message.id )) {
            this.emit( message );
            this.remove( message.id );
        } else {
            throw new ConnectionError( 'Trying to resolve a subsciber that doesnt exist for the messageId' );
        }
    }

    /**
     * This method sends error through a Subscriber/Observer associated to
     * a message in the list. The error can be a response from the from server
     * or from the client itself
     *
     * @param {string} messageId The unique ID of the message
     * @param {Error} error
     */
    public error( messageId: string, error: Error ) {
        if ( this.has( messageId )) {
            this.get( messageId ).error( error );
            this.get( messageId ).complete();
        } else {
            throw new ConnectionError( 'Trying throw error to a subsciber that doesnt exist for the messageId' );
        }
    }

    /**
     * This function checks whether the key exist in a dictionary
     *
     * @param {string} messageId The unique ID of the message
     * @returns {boolean} Returns true if messageId exists in the map
     */
    public has( messageId: string ): boolean {
        return ( this._map[ messageId ] !== undefined );
    }

    /**
     * This method retrieves a stored value for the message ID
     *
     * @protected
     * @param {string} messageId The unique ID of the message
     * @returns {Observer<IMessageJson>} The Observer implementation instance
     */
    protected get( messageId: string ): Observer<IMessageJson> {
        return this._map[ messageId ];
    }

    /**
     * This function deletes the value for the messageId  and
     * here before the value is an observer therefore it
     * completes the observer and deletes it from the list
     *
     * @protected
     * @param {string} messageId The unique ID of the message
     */
    protected remove( messageId: string ) {
        this.get( messageId ).complete();
        delete this._map[ messageId ];
    }

}

