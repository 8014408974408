import { interval, empty, Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { Component, ElementRef } from '@angular/core';

/**
 * A component that enables verticle scrolling for the wrapped
 * content. Everything above has to span to 100% or a fixed height for this to work.
 *
 * @author  hiraash
 * @since   2016-09-27
 */

@Component({
    selector: 'scroll-area',
    template: `
        <div class="scroll-area-content" style="overflow-y: auto; height:100%" >
            <ng-content></ng-content>
        </div>
        `,
})
export class ScrollArea {

    /**
     * The element of this component
     */
    protected element: HTMLElement;

    constructor( elementRef: ElementRef ) {
        this.element = elementRef.nativeElement;
    }

    /**
     * Returns the height of the content thats
     * inside the scroll area. This will be different than
     * the height of the scroll area.
     */
    public get contentHeight(): number {
        return this.scrollContent.getBoundingClientRect().height;
    }


    /**
     * Get the scroll area content element
     */
    protected get scrollContent() {
        if ( this.element ) {
            return this.element.firstElementChild;
        }
    }

    /**
     * Scroll to the given item
     * @param  item HTMLElement
     */
    public scrollToItem( item: HTMLElement , duration?: number ): Observable<any> {
        if ( !item || !this.scrollContent ) {
            return  empty();
        }
        const itemRect = item.getBoundingClientRect();
        const scrollAreaRect = this.scrollContent.getBoundingClientRect();
        let delta: number;
        const belowViewPort: boolean = itemRect.bottom > scrollAreaRect.bottom;
        const aboveViewPort: boolean = itemRect.top < scrollAreaRect.top;

        if ( belowViewPort ) {
            delta = ( itemRect.top - scrollAreaRect.top ) - ( scrollAreaRect.height - itemRect.height );
        } else if ( aboveViewPort ) {
            delta = itemRect.top - scrollAreaRect.top;
        } else {
            return empty();
        }
        return this.scrollBy( delta, duration );
    }

    /**
     * This method scrolls the given element along Y axis
     * by given delta during the given period of time.
     *
     * @param delta     Amout of pixel to scroll along Y
     * @param duration  Scroll animation time
     */
    public scrollBy( delta: number, duration: number = 300 ): Observable<any> {
        const top = this.scrollContent.scrollTop;
        const startTime = Date.now();
        const endTime = startTime + duration;
        return interval( 10 ).pipe(
            take( duration / 10 ),
            map( count => this.smoothStep( startTime, endTime, startTime + count * 10 )),
            tap( point  => {
                this.scrollContent.scrollTop = Math.round( top + ( delta * point ));
            }));
    }

    /**
     * This function returns a number between 0 and 1 for the
     * given point i.e. between start and end, according a non linear function
     *
     * @param start   Element to be scrolled
     * @param end     Amout of pixel to scroll along Y
     * @param point   Scroll animation time
     */
    protected smoothStep( start: number, end: number, point: number ) {
        if ( point <= start ) {
            return 0;
        }
        if ( point >= end ) {
            return 1;
        }
        const x = ( point - start ) / ( end - start );
        return x * x * ( 3 - 2 * x );
    }
}
