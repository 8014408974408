import { ITextParser, ISuggestions } from 'flux-definition';
import { uniq } from 'lodash';

abstract class AbstractStereoTypeParser implements ITextParser<string> {

    parse( input: string ) {
        const trimmed = input.trim();
        const parseErrors = [];
        if ( trimmed.substr( 0, 2 ) !== '<<' ) {
            parseErrors.push( 'stereotype declaration should start with "<<"' );
        }
        if ( trimmed.substr( -2 ) !== '>>' ) {
            parseErrors.push( 'stereotype declaration should end with ">>"' );
        }
        const parsedText = trimmed.replace( /[<>]/gm, '' );
        return {
            ast: parseErrors.length > 0 ? '' : parsedText.trim(),
            lexResult: {},
            parseErrors: parseErrors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        const trimmed = text.trimLeft();
        if ( trimmed.substr( 0, 2 ) === '<<' ) {
            return this.getSuggestions( trimmed.substr( 2 ), context );
        }
        return {
            suggestions: [],
            searchTerm: '',
        };
    }

    protected getSuggestions( searchTerm: string, context: any ): ISuggestions {
        const suggestions = [
            'stereotype',
            'interface',
        ].concat(
            this.getStaticSuggestions(),
            this.getDynamicSuggestions( searchTerm, context ),
        );
        const startsWith = searchTerm.toLowerCase();
        return {
            suggestions: uniq(
                searchTerm ? suggestions.filter( sugg => sugg.toLowerCase().startsWith( startsWith )) : suggestions,
            ),
            searchTerm,
        };
    }

    protected abstract getStaticSuggestions(): string[];
    protected abstract getDynamicSuggestions( searchTerm: string, context: any ): string[];
}

export default AbstractStereoTypeParser;
