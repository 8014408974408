import { EPackage, EObject } from 'ecore';
import { MapOf } from 'flux-core';
import { EDataModel } from '../../edata/model/edata.mdl';
import { EntityModel } from '../../edata/model/entity.mdl';
import { AbstractEcoreModel } from './abstract-ecore-model';

export class EcoreUmlPackage extends AbstractEcoreModel {

    public static eDefId = 'creately.entity.uml.package';
    public static get instance(): EcoreUmlPackage {
        return this._instance;
    }
    private static _instance = new EcoreUmlPackage();

    public createObject( data: any ) {
        return EPackage.create({
            name: data.name,
        });
    }

    public resolve( e: EntityModel, eObjects: MapOf<MapOf<EObject>>, model: EDataModel ) {
        this.resolveLinks( e, eObjects, model.id );
    }

    protected resolveLinks( e: EntityModel, eObjects: MapOf<MapOf<EObject>>, modelId: string ) {
        if ( !!e.links ) {
            for ( const linkId in e.links ) {
                const link = e.links[linkId];
                if ( link.type === 'child' && link.handshake === 'uml.package' ) {
                    eObjects[modelId][e.id].get( 'eClassifiers' ).add( eObjects[link.eDataId][link.entityId]);
                }
            }
        }
    }
}
