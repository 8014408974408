import { Injectable } from '@angular/core';
import { Command, Random, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { CreateDiagram } from './create-diagram.cmd';

/**
 * CreateDiagramPrivate to create private diagrams.
 */
@Injectable()
@Command()
export class CreateDiagramPrivate extends CreateDiagram {
    // Private diagram create command.
    constructor(
        protected random: Random,
        protected state: StateService<any, any>,
        protected dataStore: DataStore,
    ) {
        super( random, state, dataStore );
    }

    /**
     * This function will call the Create Diagram super class,
     * which adds the newly created diagram into Recent Diagrams
     * List.
     */
    public executeResult( response: any ): boolean {
        return super.executeResult( response );
    }
}

Object.defineProperty( CreateDiagramPrivate, 'name', {
    value: 'CreateDiagramPrivate',
});
