import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { Authentication } from 'flux-user';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { findIndex, clone } from 'lodash';

/**
 * Updates the diagram recents shapes and other recent data
 */
@Injectable()
@Command()
export class UpdateRecentShapes extends AbstractDiagramChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        shapes: {
            defId: string;
            weight: number;
            added?: number;
        }[],
    };

    constructor(
        protected ds: DiagramChangeService,
        protected authService: Authentication,
    ) {
        super( ds );
    }

    public prepareData() {
        if ( this.data.shapes ) {
            const recentShapes = this.data.shapes;
            const payload = { userId: this.authService.currentUserId, shapes: recentShapes };
            if ( this.changeModel.recent && this.changeModel.recent.length > 0 ) {
                const previousRecent = clone( this.changeModel.recent );

                // find this users index
                let index = 0;
                if ( previousRecent.length > 0 ) {
                    index = findIndex( previousRecent, lib => lib.userId === this.authService.currentUserId );
                    if ( index === -1 ) {
                        index = previousRecent.length;
                    }
                }
                previousRecent[index] = payload;
                this.changeModel.recent = previousRecent as any;

            } else {
                this.changeModel.recent = [ payload ];
            }
            return;
        }
        throw Error( 'There was an error setting the data to the modifier because data is not valid' );
    }
}

Object.defineProperty( UpdateRecentShapes, 'name', {
    value: 'UpdateRecentShapes',
});
