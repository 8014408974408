import { Injectable } from '@angular/core';
import { Command, Random, Rectangle, StateService } from 'flux-core';
import { IPoint2D, IShapeDefinition, IShapeText, ShapeType } from 'flux-definition';
import { forkJoin, fromEvent, merge, Observable, of } from 'rxjs';
import { ignoreElements, map, take } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { ShapeTextModel } from '../../../base/shape/model/text/shape-text.mdl';
import { Restriction } from '../restriction/restriction.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { isString } from 'lodash';

/**
 * This command adds an and imported image into the document.
 * A position to place the image can be input into the command
 * as data. If this is not specified, image is placed at the center
 * of the current viewport.
 *
 * If the image size is greater than 2000x2000, it will be scaled
 * down to fit 2000x2000 area while maintaining the aspect ratio.
 * This scaling down only affects the default size of the image
 * after insertion and causes no loss of image quality (user can
 * resize it to the original size if needed).
 *
 * Input data:
 *  {
 *      position?: IPoint2D
 *      files: Array<{
 *          name: string,
 *          type: string,
 *          extension: string,
 *          data: any,
 *      }>
 *  }
 *
 * @author  Ramishka
 * @since   2019-02-16
 */
@Injectable()
@Command()
export class AddImageShape extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        position?: IPoint2D,
        files: Array<any>,
    };

    protected RASTER_IMAGE_DEF_ID = 'creately.basic.rasterimage';
    protected VECTOR_IMAGE_DEF_ID = 'creately.basic.vectorimage';

    /**
     * Inject restriction service and the definition locator.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected restriction: Restriction,
        protected defLocator: DefinitionLocator,
        protected random: Random,
        protected state: StateService<any, any>,
        protected vToDcoordinate: ViewportToDiagramCoordinate ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data
     */
    public prepareData(): Observable<void> {
        const observables: Observable<any>[] = [];
        const files = this.data.files;
        if ( !files || files.length === 0 ) {
            return;
        }
        files.forEach( file => {
            observables.push( this.insertImage( file, file.position || this.data.position ));
        });
        return merge( ...observables ).pipe(
            ignoreElements(),
        );
    }

    /**
     * Inserts a image as a shape into the diagram.
     * @param imageFile - image file with data
     * @param position - position to place the image at
     * @return observable
     */
    protected insertImage( imageFile: any, position: IPoint2D ): Observable<void> {
        const shape: any = {};
        shape.id = this.getShapeId();
        shape.type = ShapeType.Image;
        shape.defId =  this.getDefId( imageFile );
        shape.version = 1;

        return forkJoin(
            this.defLocator.getDefinition( shape.defId, shape.version ).pipe( take( 1 )),
            this.getImageDimensions( imageFile.data ),
            of( imageFile.texts ),
        ).pipe(
            map(([ def, dimensions, texts ]) => {
                const zIndex = this.changeModel.getIndexToAdd( <IShapeDefinition>def );
                const defaultBounds = this.getDefaultBounds( dimensions, !texts );
                const { x, y } = this.getPosition( defaultBounds, position );
                shape.x = x;
                shape.y = y;
                const shapeChange = {
                    ...shape,
                    zIndex,
                    defaultBounds,
                    imageType: imageFile.type,
                    hash: imageFile.hash,
                };
                if ( texts ) {
                    shapeChange.texts = this.getTextMap( texts );
                }
                if ( imageFile.replaceText ) {
                    shapeChange.replaceText = imageFile.replaceText;
                }
                this.changeModel.shapes[shape.id] = shapeChange;
            }),
            ignoreElements(),
        );
    }

    /**
     * Generates and returns an object with text id as key and text
     * as value for all the texts in given array. If the text is null
     * or empty then it does not return anything.
     * @param texts
     */
    protected getTextMap( texts: IShapeText[]): { [ id: string ]: ShapeTextModel } {
        if ( texts && texts.length > 0 ) {
            const textMap = {};
            texts.forEach( text => {
                if ( text.height && isString( text.height )) {
                    text.height = parseInt( text.height.toString(), 10 );
                }
                if ( text.width && isString( text.width )) {
                    text.width = parseInt( text.width.toString(), 10 );
                }
                textMap[ text.id ] = Object.assign( new ShapeTextModel(), text );
            });
            return textMap;
        }
    }

    /**
     * Returns a newly generate id for the shape to be added
     */
    protected getShapeId() {
        return this.random.shapeId();
    }

    /**
     * Generates the default bounds of the image.
     * If image size is larger than the max allowed 2000x2000 area,
     * bounds are scaled down to fit this area.
     * @param dimensions - image dimensions
     * @return default bounds
     */
    protected getDefaultBounds( dimensions: Rectangle, scaleDown: boolean = true ): Rectangle {
        const maxDimensions = new Rectangle( 0, 0, 2000, 2000 );
        if ( scaleDown && ( dimensions.width > maxDimensions.width || dimensions.height > maxDimensions.height )) {
            dimensions = dimensions.boundsToFit( maxDimensions );
        }
        return dimensions;
    }

    /**
     * Returns the x y coordinates to place the shape at.
     * If position is not given, shape is placed at the center
     * of the current viewport.
     */
    protected getPosition( dimensions: Rectangle, position: IPoint2D ): IPoint2D {
        if ( position ) {
            return this.restriction.point( position, [ 'GridService' ]);
        } else {
            const viewport: Rectangle = this.state.get( 'DiagramViewPort' );
            const x = this.vToDcoordinate.x( viewport.centerX ) - ( dimensions.width / 2 );
            const y = this.vToDcoordinate.y( viewport.centerY ) - ( dimensions.height / 2 );
            return this.restriction.point({ x, y }, [ 'GridService' ]);
        }
    }

    /**
     * Calculates the images dimensions by loading the base64 data into
     * and HTMLImageElement.
     * TODO: SVG images that do not have width and height properties
     * defined in them will not work well with EaselJS Bitmap which is
     * used to draw these shapes. This information needs to be added
     * for applicable svg files.
     * @param data - image data as base64 string
     * @return observable which emits image dimensions
     */
    protected getImageDimensions( data: string ): Observable<Rectangle> {
        const image = this.getImageInstance();
        const obs = fromEvent( image, 'load' ).pipe(
            take( 1 ),
            map(() => new Rectangle( 0, 0, image.width, image.height )),
        );
        image.src = data;
        return obs;
    }

    /**
     * Returns a new Image instance
     */
    private getImageInstance(): HTMLImageElement {
        return new Image();
    }

    /**
     * Retrieves the defId for the image type.
     * @param fileData image file
     */
    private getDefId( fileData: any ) {
        if ( fileData.extension === 'svg' ) {
            return this.VECTOR_IMAGE_DEF_ID;
        } else {
            return this.RASTER_IMAGE_DEF_ID;
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AddImageShape, 'name', {
    value: 'AddImageShape',
});
