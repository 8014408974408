import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { IDataItem } from 'flux-definition';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { IDataItemUIControl } from './data-items-uic.i';

/**
 * UI contorl for TextInput
 */
@Component({
    selector: 'formula-field-uic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <input type="text" class="text-input" readonly [value]="valueSource | async">
    `,
})
export class FormulaFieldUIC implements IDataItemUIControl<string> {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    public valueSource: BehaviorSubject<string>;

    public change = EMPTY;

    /**
     * Constructor
     */
    constructor( protected elementRef: ElementRef ) {
        this.valueSource = new BehaviorSubject( '' );
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        this.valueSource.next( data.value );
    }

}
