import { IModifier } from 'flux-core';

/**
 * ISplitModifier
 * Modifier broken into entity changes and changes for individual shapes.
 * This helps locators identify which entities have changed and how.
 */
export interface IEDataSplitModifier {
    edata?: IModifier;
    entities?: { [entityId: string]: IModifier };
}

/**
 * splitModifier
 * Separates changes in the modifier into changes to diagram and for individual shapes.
 * The main focus is to identify changes to the diagram itself and the shapes separately
 * so that they can be applied. This function has to be correct for all cases and it should
 * also perform well.
 *
 * Example:
 *
 *      The following modifier has changes to the diagram and also for a shape.
 *
 *      {
 *          $set: {
 *              'name': 'New Diagram',
 *              'shapes.shape1.x': 100,
 *              'shapes.shape1.y': 120,
 *          }
 *      }
 *
 *      The output will have this information separated.
 *
 *      {
 *          diagram: { $set: { 'name': 'New Diagram' }}
 *          shapes: {
 *              shape1: { $set: { x: 100, y: 120 }}
 *          }
 *      }
 *
 */
export const splitModifier = ( modifier: IModifier ): IEDataSplitModifier => {
    const split: IEDataSplitModifier = {};
    for ( const op in modifier ) {
        const opmod = modifier[op];
        for ( const key in opmod ) {
            const value = opmod[key];
            if ( key.indexOf( 'entities.' ) === 0 ) {
                // NOTE: 8 is the position of the first '.' in the key "entities.*"
                const secondDot = key.indexOf( '.', 9 );
                if ( secondDot === -1 ) {
                    const entityId = key.slice( 9 );
                    if ( !split.entities ) {
                        split.entities = { [entityId]: { [op]: value }};
                    } else if ( !split.entities[entityId]) {
                        split.entities[entityId] = { [op]: value };
                    } else if ( !split.entities[entityId][op]) {
                        split.entities[entityId][op] = value;
                    } else {
                        for ( const prop in value ) {
                            split.entities[entityId][op][prop] = value[prop];
                        }
                    }
                } else {
                    const entityId = key.slice( 9, secondDot );
                    const shapeKey = key.slice( secondDot + 1 );
                    if ( !split.entities ) {
                        split.entities = { [entityId]: { [op]: { [shapeKey]: value }}};
                    } else if ( !split.entities[entityId]) {
                        split.entities[entityId] = { [op]: { [shapeKey]: value }};
                    } else if ( !split.entities[entityId][op]) {
                        split.entities[entityId][op] = { [shapeKey]: value };
                    } else {
                        split.entities[entityId][op][shapeKey] = value;
                    }
                }
            } else if ( key === 'entities' ) {
                for ( const entityId in value ) {
                    if ( !split.entities ) {
                        split.entities = { [entityId]: { [op]: value[entityId] }};
                    } else if ( !split.entities[entityId]) {
                        split.entities[entityId] = { [op]: value[entityId] };
                    } else {
                        split.entities[entityId][op] = value[entityId];
                    }
                }
            } else {
                if ( !split.edata ) {
                    split.edata = { [op]: { [key]: value }};
                } else if ( !split.edata[op]) {
                    split.edata[op] = { [key]: value };
                } else {
                    split.edata[op][key] = value;
                }
            }
        }
    }
    return split;
};

/**
 * This is a temporary solution to the issue that data stored on our DB does not
 * maintain correct granularity. Changes to nested properties appear as if they
 * were done on top level properties.
 */
// tslint:disable-next-line: cyclomatic-complexity
export const correctEntityModifier = ( modifier: IModifier ): IModifier => {
    const corrected: IModifier = {};
    for ( const op in modifier ) {
        corrected[op] = {};
        const opmod = modifier[op];
        for ( const key in opmod ) {
            const value = opmod[key];
            if ( key === 'shapes' ) {
                for ( const textId in value ) {
                    const text = value[textId];
                    for ( const propKey in text ) {
                        corrected[op][`shapes.${textId}.${propKey}`] = text[propKey];
                    }
                }
            } else if ( key.indexOf( 'shapes.' ) === 0 ) {
                // NOTE: 7 is the position of the first '.' in the key "shapes.*"
                const secondDot = key.indexOf( '.', 7 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'links' ) {
                for ( const linkId in value ) {
                    const link = value[linkId];
                    for ( const propKey in link ) {
                        corrected[op][`links.${linkId}.${propKey}`] = link[propKey];
                    }
                }
            } else if ( key.indexOf( 'links.' ) === 0 ) {
                // NOTE: 6 is the position of the first '.' in the key "links.*"
                const secondDot = key.indexOf( '.', 6 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            // }  else if ( key === 'data' ) {
            //     for ( const dataItemId in value ) {
            //         const dataItem = value[dataItemId];
            //         for ( const propKey in dataItem ) {
            //             corrected[op][`data.${dataItemId}.${propKey}`] = dataItem[propKey];
            //         }
            //     }
            // } else if ( key.indexOf( 'data.' ) === 0 ) {
            //     // NOTE: 6 is the position of the first '.' in the key "data.*"
            //     const secondDot = key.indexOf( '.', 5 );
            //     if ( secondDot === -1 ) {
            //         for ( const propKey in value ) {
            //             corrected[op][key + '.' + propKey] = value[propKey];
            //         }
            //     } else {
            //         corrected[op][key] = value;
            //     }

            } else if ( key === 'eDefId' && value === 'creately.entity.datasource.github.issue' ) {
                corrected[op][key] = 'createlyEntityDatasourceGithubIssue';
            } else {
                corrected[op][key] = value;
            }
        }
    }
    return corrected;
};
