import { IResponsibility, StateService, Logger, InitializationChainStatus, IChainOutcome  } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    ModelSubscriptionManager,
    SubscriptionStatus,
    AbstractModelSubscription,
    ISubscriptionStatus,
} from 'flux-subscription';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the status of the user
 * subscription.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-12-15
 */
@Injectable()
export class UserSubscriptionResponsibility implements IResponsibility  {

    public name: string = 'UserSubscriptionResponsibility';

    constructor( protected logger: Logger,
                 protected subManager: ModelSubscriptionManager,
                 @Inject( StateService ) protected state: StateService<any, any> ) {}
    /**
     * Determines if the user exists in the local storage.
     * @param status - current status of the sequence
     * @return  Observable which emits user availability
     */
    public checkState( status: InitializationChainStatus ): Observable<ISubscriptionStatus> {
        this.logger.debug( 'UserSubscriptionResponsibility checkState' );
        const sub: AbstractModelSubscription = this.subManager.get( this.state.get( 'CurrentUser' ));
        if ( sub ) {
            return of( sub.currentStatus );
        }
        return of({} as any );
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        const s = this.getStates( status );

        if ( s.terminateAtUserSub ) {
            return [];
        }

        if ( s.userSubStarted ) {
            return [ 'EmailConfirmationResponsibility' ];
        }

        if ( s.hasUser ) {
            return [ 'DiagramResponsibility' ];
        }
        return [];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        const s = this.getStates( status );
        if ( s.userSubErrored ) {
            if ( this.isAuthenticationError( status )) {
                if ( s.isAuthenticated ) {
                    // If authentication is now available, start user subscription
                    return {
                        type: 'command',
                        action: 'StartUserSubscription',
                        wait: true,
                    };
                } else {
                    return { type: 'command', action: 'Authenticate', wait: true };
                }
            }
            // TODO: Handle any ther possible errors that may cause user sub to fail
            return { type: 'complete' };
        }

        if ( !s.userSubStarted ) {
            return {
                type: 'command',
                action: 'StartUserSubscription',
                wait: !s.hasUser,
            };
        }

        if ( s.terminateAtUserSub ) {
            return { type: 'complete' };
        }
    }

    /**
     * This function returns true if the diagram subscription has errored,
     * and the error was due to the user not being authenticated to access
     * the diagram
     * @param status - chain status
     * @return boolean true if it was a permission error, false otherwise
     */
    protected isAuthenticationError( status: InitializationChainStatus ): boolean {
        const error = status.states.UserSubscriptionResponsibility.error;
        if ( error ) {
            return ( error.code === 2201 || error.code === 1301 );
        }
        return false;
    }

    /**
     * Derives the required values from responsibility states and
     * returns a map with them for easy access.
     * @param status chain status
     */
    protected getStates( status: InitializationChainStatus ) {
        const userSub = status.states.UserSubscriptionResponsibility;
        return {
            terminateAtUserSub: !!status.states.terminateAtUserSub,
            userSubStarted: ( userSub && userSub.subStatus === SubscriptionStatus.started ),
            userSubErrored: ( userSub && userSub.subStatus === SubscriptionStatus.errored ),
            hasUser: !!status.states.UserResponsibility,
            isAuthenticated: status.states.AuthResponsibility === 0,
        };
    }
}

