import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { EMPTY } from 'rxjs';
import { TaskModel } from '../../../../../../libs/flux-diagram/src';


@Injectable()
@Command()
export class GetTasks extends AbstractMessageCommand {

    /**
     * This command is sent to the server.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        diagramId?: string;
        entityId?: string;
    };

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    prepareData() {
        if ( !this.data ) {
            this.data = {};
        }
        if ( this.resourceId && !this.data.diagramId ) {
            this.data.diagramId = this.resourceId;
        }
    }

    execute() {
        return !!this.data.diagramId || !!this.data.entityId;
    }

    public executeResult( ) {
        if ( this.resultData.tasks && this.resultData.tasks.length > 0 ) {
            this.dataStore.insert( TaskModel, this.resultData.tasks );
        }
        return EMPTY;
    }
}

Object.defineProperty( GetTasks, 'name', {
    value: 'GetTasks',
});

