import { AbstractModel } from 'flux-core';
import { DiagramModel } from '../model/diagram.mdl';
import { AbstractModelSubscription } from 'flux-subscription';

export class DiagramSub extends AbstractModelSubscription {
    public get subscription(): string {
        return 'Diagram';
    }

    public get modelType(): typeof AbstractModel {
        return DiagramModel;
    }
}
