import { ShapeDataModel } from 'flux-diagram-composer';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { isEmpty } from 'lodash';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * This MigrateToNewRegionType command is to update old array
 * base containerRegions.shape to object base containerRegions.shape.
 */
@Injectable()
@Command()
export class MigrateToNewRegionType extends AbstractDiagramChangeCommand {

    /**
     * Prepares the data necessary for update the containerRegions
     */
    public prepareData() {
        for ( const id in this.changeModel.shapes ) {
            const shape = this.changeModel.shapes[id] as ShapeDataModel;
            if ( !isEmpty( shape.containerRegions )) {
                const regions = shape.containerRegions;
                for ( const regionId in regions ) {
                    if ( regions[regionId].shapes && Array.isArray( regions[regionId].shapes )) {
                        const shapes = ( regions[regionId] as any ).shapes;
                        const newShapes = {};
                        for ( const shapeId of shapes ) {
                            newShapes[shapeId] = true;
                        }
                        regions[regionId].shapes = newShapes;
                    }
                }
            }
        }
    }
}

Object.defineProperty( MigrateToNewRegionType, 'name', {
    value: 'MigrateToNewRegionType',
});
