import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { RuleModel } from 'flux-diagram';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * UpdateDisplayRules
 * This command adds/removes or updates the rules used for formatting and filters.
 */
@Injectable()
@Command()
export class UpdateDisplayRules extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        addUpdate: { [ ruleId: string]: RuleModel },
        remove: string[], // array of ruleId
        removeShapeIds?: string[], // Remove shape by shapeIds from shape-specific rules
        copyShapeIds?: { original: string[], pasted: string[] }, // New and old shape Ids to copy shape-specific rules
    };

    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        state: StateService<any, any> ) {
            super( ds ) /** istanbul ignore next */;
            this.state = state;
        }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        if ( !this.changeModel.rules ) {
            this.changeModel.rules = {};
        }
        if ( this.changeModel.rules.displayIndicators ) {
            if ( this.data.copyShapeIds ) {
                this.copyFromDisplayIndicators();
            }
            if ( this.data.removeShapeIds ) {
                this.removeFromDisplayIndicators();
            }
        }
        for ( const ruleId in this.data.addUpdate ) {
            this.changeModel.rules[ ruleId ] = this.data.addUpdate[ ruleId ];
        }

        if ( this.data.remove && this.data.remove.length > 0 ) {
            this.data.remove.forEach( id => {
                delete this.changeModel.rules[ id ];
            });
        }
    }

    /**
     * Remove shapeIds from all displayIndicators rules
     */
    public removeFromDisplayIndicators() {
        const indicatorsDisplayRules = this.changeModel.rules.displayIndicators;
        if ( indicatorsDisplayRules ) {
            Object.keys( indicatorsDisplayRules.show ).forEach( key => {
                indicatorsDisplayRules.show[key] = indicatorsDisplayRules.show[key]
                    .filter( shapeId => this.data.removeShapeIds.indexOf( shapeId ) === -1 );
            });
            Object.keys( indicatorsDisplayRules.hide ).forEach( key => {
                indicatorsDisplayRules.hide[key] = indicatorsDisplayRules.hide[key]
                    .filter( shapeId => this.data.removeShapeIds.indexOf( shapeId ) === -1 );
            });
            if ( !this.data.addUpdate ) {
                this.data.addUpdate = {};
            }
            this.data.addUpdate.displayIndicators = indicatorsDisplayRules;
        }
    }

    /**
     * Copy indicatorsDisplay rules (for shape copy)
     */
    public copyFromDisplayIndicators() {
        const indicatorsDisplayRules = this.changeModel.rules.displayIndicators;
        if ( indicatorsDisplayRules ) {
            Object.keys( indicatorsDisplayRules.show ).forEach( key => {
                const addShapes = indicatorsDisplayRules.show[key]
                    .map( shapeId => this.data.copyShapeIds.original.indexOf( shapeId  ))
                    .filter( index => index !== -1 )
                    .map( index => this.data.copyShapeIds.pasted[index]);
                indicatorsDisplayRules.show[key] = [
                    ...new Set( indicatorsDisplayRules.show[key].concat( addShapes )) ];
            });
            Object.keys( indicatorsDisplayRules.hide ).forEach( key => {
                const addShapes = indicatorsDisplayRules.hide[key]
                    .map( shapeId => this.data.copyShapeIds.original.indexOf( shapeId  ))
                    .filter( index => index !== -1 )
                    .map( index => this.data.copyShapeIds.pasted[index]);
                indicatorsDisplayRules.hide[key] = [
                    ...new Set( indicatorsDisplayRules.hide[key].concat( addShapes )) ];
            });
            const removeShapes = this.data.removeShapeIds ? this.data.removeShapeIds : [];
            const removedOriginalShapes = this.data.copyShapeIds.original
                .filter( id => !this.changeModel.shapes[id]);
            if ( removedOriginalShapes.length ) {
                this.data.removeShapeIds = [ ...new Set( removeShapes.concat( removedOriginalShapes )) ];
            }
            if ( !this.data.addUpdate ) {
                this.data.addUpdate = {};
            }
            this.data.addUpdate.displayIndicators = indicatorsDisplayRules;
        }
    }
}

Object.defineProperty( UpdateDisplayRules, 'name', {
    value: 'UpdateDisplayRules',
});
