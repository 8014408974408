import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { EntityLinkType } from 'flux-definition';
/**
 * Updates the list of referencing shapes for the given entity
 * When the other shape is updated the entity will keep a marker
 * if the entity 'acknowledges' the change in the shape contents.
 *
 * This is used to add/remove the references.
 */
@Injectable()
@Command()
export class UpdateEntityLinks extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        diagramId: string,
        fromEntityId: string,
        toEDataId?: string,
        toEntityId?: string,
        type: EntityLinkType,
        isSet: boolean,
        connectorId?: string, // not there for containers
        connectorDefId?: string, // not there for containers
        handshake?: string, // not needed for remove
        shapeId?: string,
        shapeDefId?: string, // only for edataRef conns
        identifier?: string, // only for edataRef conns
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }
    public prepareData() {
        this.modelChangeUtils.updateEntityLinks(
            this.changeModel,
            this.data,
        );
    }
}


Object.defineProperty( UpdateEntityLinks, 'name', {
    value: 'UpdateEntityLinks',
});

