import { DataType } from 'flux-definition';
import { Random, Tracker } from 'flux-core';
import { Subject, BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, Output, ElementRef,
    Input, AfterViewInit, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';

/**
 * FormulaFieldCreator
 * This component is to enter/update the options for single select combo ui control
 *
 * @author Damith
 * @since 2022-01-19
 */
@Component({
    selector: 'formula-field-creator',
    template: `
        <div #formulaField class="formula-field-creator-container" >
            <div class="fx-justify-space-between">
                <!-- <a class="primary formula-field-cancel" (click)="closeAndResetComboOptions()">
                    <svg class="nu-icon"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-arrow-back"></use></svg>
                </a> -->
                <input class="creator-label" value="{{type==='update' ? dataItem.label : 'Formula Field'}}">
                <div class="close-btn" (click)="closeAndResetComboOptions()">
                    <svg class="nu-icon nu-icon-med"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
                </div>
            </div>
            <div class="formula-field-expression-container text-input-item" >
                <label class="grey" translate>SHAPE_DATA.DATA_ITEMS.FORMULA_FIELD.OPTIONS_EXPRESSION</label>
                <input
                    class="formula-field-expression text-input"
                    placeholder="type in the formula expression..."
                    value="{{type==='update' ? dataItem.options.expression : ''}}"
                />
            </div>
            <div class="formula-field-footer" >
                <button *ngIf="type==='update' && canCommit | async" class="btn-small btn-secondary formula-field-create"
                    (click)="updateFormulaField()" translate>SHAPE_DATA.DATA_ITEMS.FORMULA_FIELD.DONE_BUTTON</button>
                <button *ngIf="type==='create' && canCommit | async" class="btn-small btn-secondary formula-field-create"
                    (click)="createFormulaField()" translate>SHAPE_DATA.DATA_ITEMS.FORMULA_FIELD.DONE_BUTTON</button>
            </div>
        </div>
    `,
    styleUrls: [ './formula-field-creator.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormulaFieldCreator implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @ViewChild( 'formulaField', { read: ElementRef, static: false })
    public formulaField: ElementRef;

    /**
     * This component can add new or update combo options, this property is to
     * specify if component should update or create options
     */
    @Input()
    public type: 'create' | 'update' = 'create';

    /**
     * FormulaField data item
     */
    @Input()
    public dataItem: any;
    @Output()
    public change: Subject<any>;

    @Output()
    public closed: Subject<any>;

    /**
     * "Done" button should be visible only if there is at least one
     * non-empty option, this BehaviorSubject subject to control the visibility
     * of the done button.
     */
    public canCommit: BehaviorSubject<boolean>;

    protected subs: Subscription[];

    public constructor() {
        this.subs = [];
        this.change = new Subject();
        this.closed = new Subject();
        this.canCommit = new BehaviorSubject( false );
    }

    public get formulaFieldElement(): HTMLElement {
        return this.formulaField.nativeElement as HTMLElement;
    }

    /**
     * Handler function of the "Done" button for the 'Create' scenario
     */
    public createFormulaField() {
        const label = ( this.formulaFieldElement.querySelector( '.creator-label' ) as HTMLInputElement )
            .value;

        const expression = ( this.formulaFieldElement
            .querySelector( 'input.formula-field-expression' ) as HTMLInputElement ).value;

        if ( expression ) { // check the validity of the expression
            this.change.next( this.getDataItem( expression, label ));
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.formula.click`,
                { value1Type: 'action', value1: 'Done' });
            }
            this.closeAndResetComboOptions();
        }
    }

    /**
     * Handler function of the "Done" button for the 'Create' scenario
     */
    public updateFormulaField() {
        const label = ( this.formulaFieldElement.querySelector( '.creator-label' ) as HTMLInputElement )
            .value;
        const expression = ( this.formulaFieldElement
            .querySelector( '.formula-field-expression' ) as HTMLInputElement ).value;
        this.change.next( Object.assign( this.dataItem, { options: { expression }, label }));
        /* istanbul ignore next */
        if ( this.context ) {
            Tracker.track( `${this.context}.formula.click`,
            { value1Type: 'action', value1: 'Done' });
        }
        this.closeAndResetComboOptions();
    }

    public closeAndResetComboOptions() {
        this.canCommit.next( false );
        this.closed.next( true );
    }

    public ngAfterViewInit() {
        this.subs.push(
            this.checkExpression().subscribe( v => this.canCommit.next( v )),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * This observable emits true/false as the user types in formula.
     */
    protected checkExpression() {
        const elem = this.formulaFieldElement
                .querySelector( '.formula-field-expression' ) as HTMLInputElement;
        return fromEvent( elem, 'keyup' ).pipe(
            map( e => elem.value.trim() !== '' ),
        );
    }

    /**
     * Create a new combo data item
     */
    private getDataItem( expression, label ) {
        return {
            id: Random.dataItemId(),
            type: DataType.FORMULA,
            layout: 'block',
            value: '',
            options: {
                expression,
            },
            label,
            labelEditable: true,
            optional: true,
            visibility: [
                { type: 'editor' },
            ],
            validationRules: {},
        };
    }
}
