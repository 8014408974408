import { ITextContent } from 'flux-definition';

/**
 * A utility class which has helper function related to carota text framwork.
 */
export class CarotaUtils {

    /**
     * Converts given text content into Carota supported HTML string.
     *
     * @param texts Array of text contents to convert.
     */
    public static convertToHTML( texts: ITextContent[]) {
        if ( texts && texts.length > 0 ) {
            return texts.map( obj => {
                const span = document.createElement( 'span' );
                Object.keys( obj ).forEach(( k, i ) => {
                    if ( k === 'text' ) {
                        const brAdded = obj[ k ].replace( /\n/g, '<br>' );
                        span.innerHTML = brAdded;
                    }
                    if ( k === 'bold' ) {
                        span.style.fontWeight = obj[ k ] ? 'bold' : 'normal';
                    }
                    if ( k === 'italic' ) {
                        span.style.fontStyle = obj[ k ] ? 'italic' : 'normal';
                    }
                    if ( k === 'underline' ) {
                        span.style.textDecoration = obj[ k ] ? 'underline' : 'normal';
                    }
                    if ( k === 'color' ) {
                        span.style.color = obj[ k ];
                    }
                    if ( k === 'font' ) {
                        span.style.fontFamily = obj[ k ];
                    }
                    if ( k === 'size' && obj[ k ]) {
                        span.style.fontSize = obj[ k ] + 'pt';
                    }
                    if ( k === 'align' ) {
                        span.style.textAlign = obj[ k ];
                    }
                    if ( k === 'script'  ) {
                        span.style.verticalAlign = obj[ k ];
                    }
                });
                if ( obj.strikeout ) {
                    span.innerHTML = '<del>' + span.innerHTML + '</del>';
                }
                return span.outerHTML.replace( /<br>/g, '<br/>' );
            }).join( '' );
        }
    }

    /**
     * Convert given font size to point based on the type of the size. If the type is
     * not specified in the size then it considers as pixel( px ).
     *
     * @param size Size of the font with or without type.
     */
    public static convertToPoint( size: string ): number {
        const browserDefault = 12; // 12pt
        if ( !size || size.length === 0 ) {
            return browserDefault;
        }
        let type: string = 'px';
        if ( isNaN( size as any )) {
            if ( size.indexOf( '%' ) > 0 ) {
                type = '%';
                size = size.substr( 0, size.length - 1 );
            } else {
                type = size.substr( size.length - 2 );
                size = size.substr( 0, size.length - 2 );
            }
        }
        const sizeValue = parseFloat( size );
        switch ( type ) {
            case '%':
                return Math.round(( sizeValue / 100 ) * browserDefault );
            case 'px':
                    return Math.round(( sizeValue / 8 ) * 6 );
            case 'em':
                return Math.round( sizeValue * 12 );
            case 'pt':
                    return Math.round( sizeValue );
        }
    }
}
