import { Injectable } from '@angular/core';
import { Command, MapOf } from 'flux-core';
import { SystemType } from 'flux-definition';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { Observable } from 'rxjs';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * This MigrateTasks command to shape task to taskMap to better retain the relations
 */
@Injectable()
@Command()
export class MigrateTasks extends AbstractDiagramChangeCommand {

    constructor(
        protected ds: DiagramChangeService,
    ) {
        super( ds );
    }

    public prepareData(): void | Observable<any> {
        const shapes = Object.values( this.changeModel.shapes ).filter( shape => ( shape as any ).tasks );
        if ( shapes.length === 0 ) {
            return;
        }
        // taskId -> shapeIds map
        const shapesByTaskId: MapOf<AbstractShapeModel[]> = {};
        shapes.forEach( shape => {
            ( shape as any ).tasks.forEach( taskId => {
                if ( !shapesByTaskId[taskId]) {
                    shapesByTaskId[taskId] = [ shape ];
                } else {
                    shapesByTaskId[taskId].push( shape );
                }
            });
            shape.taskMap = {};
            delete ( shape as any ).tasks;
        });
        for ( const dDefId in this.changeModel.dataDefs ) {
            const dDef = this.changeModel.dataDefs[dDefId];
            for ( const diId in dDef ) {
                const dataItem = dDef[diId];
                if ( dataItem.systemType === SystemType.Role && ( dataItem as any ).taskId ) {
                    if ( shapesByTaskId[ ( dataItem as any ).taskId ]) {
                        shapesByTaskId[ ( dataItem as any ).taskId ].forEach( shape => {
                            shape.taskMap[ dataItem.id ] = ( dataItem as any ).taskId;
                        });
                    }
                    delete ( dataItem as any ).taskId;
                }
            }
        }
    }

    public execute() {
        this.resultData = { skipChangeBinders: true };
        return true;
    }
}

Object.defineProperty( MigrateTasks, 'name', {
    value: 'MigrateTasks',
});
