import { Command, AppConfig, ImageLoader, StateService, Logger, NotifierController, AbstractCommand } from 'flux-core';
import { Observable, empty, defer, concat, of, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator, ITypedDiagramLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { DiagramSVGView } from 'flux-diagram-composer';
import { switchMap, tap, take, map, filter } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import saveAs from 'file-saver';
import { NotificationMessages, Notifications } from '../../notifications/notification-messages';
import { TranslateService } from '@ngx-translate/core';
import { PresentationLocator } from '../presentation-locator.svc';
import { SlideModel } from '../model/slide.mdl';
import { ShapeModel } from '../../shape/model/shape.mdl';

/**
 * This command generates a pdf document from a presentation
 *
 * It uses most of the methods and functions of the GeneratePdf class, but is different in that
 * it creates for presentations which can span multiple workspaces and which does not necessarily
 * include all shapes in the workspace.
 */
@Injectable()
@Command()
export class ExportPresentation extends AbstractCommand {

    public data: {
        presentationId: string,
        data?: any[],
        id?: string,
        lastChangeId?: string,
        type?: string,
        name?: string,
    };

    /**
     * Wait maximum 10 minutes for the pdf to be generated.
     */
    protected maxRetries = 120;
    protected intervalInSeconds = 5;

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
    protected notificationMessages: NotificationMessages;

    constructor( private ll: DiagramLocatorLocator,
                 private resources: ImageLoader,
                 private state: StateService<any, any>,
                 protected translate: TranslateService,
                 protected notifierController: NotifierController,
                 protected pl: PresentationLocator ) {
                    super();
                    this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'PDF_EXPORT_URL' );
    }

    /**
     * Fetches the svg string and attaches to data
     */
    public prepareData(): any {
        const userId = this.state.get( 'CurrentUser' );
        return this.pl.getPresentation( this.data.presentationId, userId ).pipe(
            take( 1 ),
            map( presentation => {
                ( this.data as any ).id = presentation.id;
                this.data.type = 'application/pdf';
                this.data.name = presentation.name + '.pdf';
                this.data.data = [];
                return Object.values( presentation.slides ) as [SlideModel];
            }),
            switchMap( slides => this.fetchSlideSVG( slides )),
        );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        this.showExportStartedNotification();
        fromPromise( this.getPdfData()).subscribe( data => {
            if ( data.data ) {
                this.closeExportStartedNotification();
                this.saveFile( data.data, this.data.name );
            } else if ( data.url ) {
                const promise = this.retryFetchingLink( data.url, this.maxRetries, this.intervalInSeconds )
                    .then( blob => {
                        if ( blob?.type === this.data.type ) {
                            this.closeExportStartedNotification();
                            this.saveFile( blob, this.data.name );
                        } else {
                            Logger.error( 'Unable to obtain a pdf file after maximum retries.' );
                        }
                    })
                    .catch( error => {
                        Logger.error( 'Error in retryFetchingLink:', error );
                    });
                fromPromise( promise ).subscribe();
            }
        });
        return empty();
    }

    protected fetchSlideSVG( slides: [SlideModel]) {
        const subs = slides.map( slide => {
            const locator = this.ll.forDiagram( slide.diagramId, false );
            const svgview = this.createDiagramSVGView( locator );

            return this.ll.forDiagram( slide.diagramId, false ).getDiagramOnce().pipe(
                map( diagram => {
                    this.data.lastChangeId = diagram.lastChangeId;
                    const shapes = slide.shapes.filter( i => typeof diagram.shapes[ i ] !== 'undefined' );
                    const childShapes = [];
                    shapes.forEach( shapeId => {
                        const shape = diagram.shapes[ shapeId ] as ShapeModel;
                        if ( shape.children ) {
                            childShapes.push( ...diagram.getContainerChildrenWithConnectors( shapeId ));
                        }
                    });
                    shapes.push( ...childShapes );
                    slide.shapes = shapes;
                    return slide;
                }),
                filter( s => s.shapes.length > 0 ),
                switchMap(() => concat(
                    defer(() => svgview.populateDiagram()),
                    defer(() => svgview.getSlideFrame( slide ).pipe(
                        take( 1 ),
                        tap( svg => {
                            this.data.data = [ ...this.data.data, svg ];
                        }),
                    )),
                )),
            );
        });
        return forkJoin( subs );
    }

    /* istanbul ignore next */
    protected saveFile( data: any, name: string ) {
        saveAs( data, name );
    }

    protected async getPdfData(): Promise<any> {
        const res = await fetch( this.httpUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: this.data.data,
                id: this.data.id,
                lastChangeId: this.data.lastChangeId,
            }),
            method: 'POST',
        });
        const json = await res.json();
        return json;
    }

    protected async fetchFileBlob ( url ) {
        const response = await fetch( url );
        return await response.blob();
    }

    protected async retryFetchingLink( url: string, maxRetries: number, intervalInSeconds: number ) {
        const expectedType = 'application/pdf';
        let retries = 0;
        let fileType;
        let blob;

        while ( retries < maxRetries && fileType !== expectedType ) {
            Logger.info( `Fetching PDF attempt ${retries + 1}...` );
            try {
                blob = await this.fetchFileBlob( url );
                fileType = blob.type;
            } catch ( error ) {
                Logger.error( 'Error fetching PDF link:', url );
            }
            if ( fileType !== expectedType ) {
                blob = null;
                await new Promise( resolve => setTimeout( resolve, intervalInSeconds * 1000 ));
                retries++;
            }
        }
        return blob;
    }

    protected showExportStartedNotification() {
        const notificationData = this.notificationMessages
            .getNotificationMessage( Notifications.SLIDE_EXPORT_STARTED );
        this.notifierController.show( Notifications.SLIDE_EXPORT_STARTED,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected closeExportStartedNotification() {
        this.notifierController.hide( Notifications.SLIDE_EXPORT_STARTED );
    }

    /**
     * Create a DiagramSVGView instance with the current diagram locator
     */
    protected createDiagramSVGView( locator: ITypedDiagramLocator ): DiagramSVGView {
        return new DiagramSVGView( of( locator ), this.resources, this.state );
    }
}

Object.defineProperty( ExportPresentation, 'name', {
    value: 'ExportPresentation',
});
