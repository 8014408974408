import { Injectable } from '@angular/core';
import { ResourceSet, XMI } from 'ecore/dist/ecore.xmi';
import { MapOf } from 'flux-core';
import { map, switchMap, take } from 'rxjs/operators';
import { EDataService } from '../../edata/edata.svc';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { AbstractEcoreModel } from './abstract-ecore-model';
import models from './ecore-model-registry';

@Injectable()
export class EcoreObjectExporter {

    private _models: MapOf<{instance: AbstractEcoreModel}> = {};
    public constructor(
        private eDataService: EDataService,
        private ll: DiagramLocatorLocator,
    ) {
        models.forEach( model => {
            this._models[model.eDefId] = model;
        });
    }

    public exportEcoreModel( asXmi: boolean = false ) {
        return this.getEcoreModel().pipe(
            switchMap( model => this.ll.forCurrentObserver( false ).pipe(
                take( 1 ),
                switchMap( locator => locator.getDiagramOnce()),
                map( diagram => ({
                    name: diagram.name,
                    data: asXmi ? XMI.to( model, true ) : model.to(),
                })),
            )),
        );
    }

    public getEcoreModel() {
        return this.eDataService.getEdataForCurrentDiagram().pipe(
            // currently support uml models only
            map( eModels => eModels.filter( model => model.defId === 'creately.edata.uml' )),
            map( eModels => {
                const ecoreObjects = {};
                eModels.forEach( model => {
                    ecoreObjects[model.id] = {};
                    Object.values( model.entities ).filter( e => !!e.data ).forEach( entity => {
                        const modelExporter = this.getEcoreModelExporter( entity.eDefId );
                        if ( modelExporter ) {
                            ecoreObjects[model.id][entity.id] = modelExporter.createObject( entity.data );
                        }
                    });
                });
                // resolve should run after creating all the ecore models
                const resourceSet = ResourceSet.create();
                const eResource = resourceSet.create({ uri: 'resources' });
                eModels.forEach( model => {
                    // should we have different resources for different models. in that case what to export?
                    Object.values( model.entities ).filter( e => !!e.data ).forEach( entity => {
                        const modelExporter = this.getEcoreModelExporter( entity.eDefId );
                        if ( !modelExporter ) {
                            return;
                        }
                        modelExporter.resolve( entity, ecoreObjects, model );
                        if ( !modelExporter.hasParent( entity.links )) {
                            eResource.get( 'contents' ).add( ecoreObjects[model.id][entity.id]);
                        }
                    });
                });
                return eResource;
            }),
        );
    }

    protected getEcoreModelExporter( defId: string ): AbstractEcoreModel {
        return this._models[defId] ? this._models[defId].instance : null;
    }
}
