import { Injectable } from '@angular/core';
import { AbstractDiagramChangeCommand } from 'apps/nucleus/src/editor/diagram/command/abstract-diagram-change-command.cmd';
import { Command } from 'flux-core';
import { ShapeDataModel } from 'flux-diagram-composer';

@Injectable()
@Command()
export class RemoveDiagramEntityListReferences extends AbstractDiagramChangeCommand {


    public data: {
        containerIds: string[];
        entityListId: string
    };

    public prepareData() {

    }

    /**
     * execute
     * execute add collaborators to diagram model
     */
    public execute(): any {
        this.data.containerIds.forEach( containerId => {
            const container: ShapeDataModel = this.changeModel.shapes[containerId] as ShapeDataModel;
            Object.keys( container.children ).forEach( shapeId => {
                const childShape =  this.changeModel.shapes[shapeId];
                if ( childShape && childShape.entityListId && childShape.entityListId === this.data.entityListId ) {
                    childShape.entityListId = null;
                }
            });
        });
        return true;
    }
}

Object.defineProperty( RemoveDiagramEntityListReferences, 'name', {
    value: 'RemoveDiagramEntityListReferences',
});
