
 <form [formGroup]="searchForm" >
    <div>
        <!-- <div class="field-full-width"> -->
            <!-- <mat-label>Data item</mat-label> -->
            <input class="form-control-input with-icon" type="text" matInput [formControl]="dataItemSelector"  placeholder="Data field" [matAutocomplete]="dataLabelAuto" required>
            <mat-autocomplete #dataLabelAuto="matAutocomplete" [displayWith]="dataItemListDisplayHelper" (optionSelected)="handleDataItemSelectorChange($event)">
                <mat-option *ngFor="let option of dataLabelListObservable | async" [value]="option">
                {{option.name}}
                </mat-option>
            </mat-autocomplete>
        <!-- </div> -->
        <!-- <div class="field-full-width"> -->
            <!-- <mat-label>Rules</mat-label> -->
            <input class="form-control-input with-icon" type="text" matInput [formControl]="ruleSelector"  placeholder="Rule" [matAutocomplete]="rulesAuto" required>
            <mat-autocomplete #rulesAuto="matAutocomplete">
                <mat-option *ngFor="let option of rulesListObservable | async" [value]="option">
                {{option}}
                </mat-option>
            </mat-autocomplete>
        <!-- </div> -->
        <!-- <div class="field-full-width"> -->
            <!-- <mat-label>Values</mat-label> -->
            <input class="form-control-input" matInput  placeholder="Value" [formControl]="inputValueController">
        <!-- </div> -->
    </div>
 </form>
