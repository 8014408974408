import { Injectable } from '@angular/core';
import { Command, ResourceLoader, ImageLoader, CommandCancelError } from 'flux-core';
import { from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { UploadImage } from './upload-image.cmd';
import { ShapeType } from 'flux-definition/src';
import * as md5 from 'md5';
import ImageResize from 'image-resize';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';

/**
 * This command is used to scale down image shape and upload
 *
 * Input data format:
 *  {
 *      shapeId: string
 *  }
 *
 * @since   2023-01-31
 * @author  Thisun
 */
@Injectable()
@Command()
export class UploadScaledImage extends UploadImage  {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            shapeId: true,
        };
    }

    /**
     * Images below this size won't get affected.
     */
    protected minImageSizeInMB = 0.5;

    constructor( protected auth: NucleusAuthentication,
                 protected loader: ResourceLoader,
                 protected ll: DiagramLocatorLocator,
                 protected resources: ImageLoader ) {
        super( auth, loader ) /*istanbul ignore next */;
    }

    /**
     * Attaches the auth token to command data
     * The REST execution step requires it.
     * Add missing extension and remove the has which
     * is not necessary for uploading.
     */
    public prepareData(): any {
        const shapeId = this.data.shapeId;
        return this.ll.forCurrent( false ).getShapeOnce( shapeId ).pipe(
            switchMap(( s: any ) => {
                // const s = dModel?.shapes[shapeId] as ShapeDataModel;
                const isSvg = s.imageType === 'image/svg+xml';
                if ( s.type === ShapeType.Image && !isSvg && !!s.hash ) {
                    const prevScale = s.getScaleDownValue();
                    return this.ll.forCurrent( true ).getShapeOnce( shapeId ).pipe(
                        switchMap(( shape: ShapeModel ) => {
                            const scale = shape.getScaleDownValue();
                            this.data.auth = this.auth.token;
                            this.hash = shape.hash;
                            if ( scale !== prevScale ) {
                                return this.resources.load( this.getImageUrl()).pipe( switchMap(( base64: any ) => {
                                    const fileSize = this.getSizeInMB( base64.split( ',' )[1]);
                                    if ( fileSize < this.minImageSizeInMB ) {
                                        return of( false );
                                    }
                                    if ( !this.data.extension ) {
                                        this.data.extension = this.imageTypes[ shape.imageType ] || '';
                                    }
                                    this.data.name = shape.name + '.' + this.data.extension;
                                    this.data.type = shape.imageType;

                                    const width = scale * shape.defaultBounds.width;
                                    return from( this.downscaleImage( base64, width, this.data.extension )).pipe(
                                        tap( imgData => {
                                            this.hash = md5( imgData );
                                            this.resultData = {
                                                shapeId,
                                                hash: this.hash,
                                            };
                                            this.data.data = imgData;
                                            return imgData;
                                        }),
                                    );
                                }));
                            }
                            return of( false );
                        }),
                    );
                }
                return of( false );
            }),
        );
    }

    public execute(): any {
        if ( !this.data.data ) {
            // trowing here to discontinue
            throw new CommandCancelError( 'No significant image scaling down detected in shape transformation' );
        }
        return true;
    }

    protected getSizeInMB( base64String: string ) {
        if ( base64String ) {
            const byteCharacters = atob( base64String );
            const byteNumbers = new Array( byteCharacters.length );
            for ( let i = 0; i < byteCharacters.length; i++ ) {
                byteNumbers[i] = byteCharacters.charCodeAt( i );
            }
            const byteArray = new Uint8Array( byteNumbers );
            const blob = new Blob([ byteArray ], { type: this.data.type });
            return blob.size / ( 1024 * 1024 );
        }
        return 0;
    }

    protected downscaleImage( base64: string, width: number, type: string ) {
        const imageResize = new ImageResize({
            format: type,
            width,
            outputType: 'base64',
        });
        return imageResize.play( base64 ) as Promise<string>;
    }

}

Object.defineProperty( UploadScaledImage, 'name', {
    value: 'UploadScaledImage',
});
