import { DataType, IDataItem } from 'flux-definition';
import { Converter } from 'showdown';

export class CsvUtil {

    public static converter: Converter;

    /**
     * This function escapes a given string according to csv format
     * @param value string to be escaped
     * @returns escaped string
     */
    public static getEscaped( value: string ) {
        const shouldQuote = [ ',', '\n' ].some( char => value.includes( char ));
        if ( value.includes( '"' )) {
            value = value.replace( /"/g, '""' );
        }
        if ( shouldQuote ) {
            return '"' + value + '"';
        }
        return value;
    }

    /**
     * this function returns a function that formats a given data item.
     * @param type data item type
     * @returns the formatter for the given type
     */
    public static getFormatter( type: DataType ) {
        // number formatter, string formatter, etc...
        if ( type === DataType.STRING_HTML ) {
            return ( d: IDataItem<DataType> ) => d.value ? d.value.replace( /<[^>]*>/g, '' ) : '';
        }
        if ( type === DataType.BINARY ) {
            return ( d: IDataItem<DataType> ) => ( !!d.value ).toString();
        }
        if ( type === DataType.DATE ) {
            return ( d: IDataItem<DataType> ) => d.value ? ( new Date( d.value )).toUTCString() : '';
        }
        if ( type === DataType.TAGS ) {
            return ( d: IDataItem<DataType> ) => d.value && d.value.length > 0 ?
                d.value.map( t => t.name ).join( ',' ) : '';
        }
        if ( type === DataType.USERS ) {
            return ( d: IDataItem<DataType> ) => d.value && d.value.people && d.value.people.length > 0 ?
                d.value.people.map( t => t.fullName ).join( ',' ) : '';
        }
        return ( d: IDataItem<DataType> ) => ( d.value === null || d.value === undefined ) ? '' : d.value.toString();
    }

    /**
     * this function returns a function that parses given string to data item value
     * @param type data item type
     * @returns the parser for the given type
     */
    public static getParser( type: DataType ) {
        if ( type === DataType.BINARY ) {
            const falsyValues = [ '0', 'false', 'FALSE', '' ];
            return ( str: string ) => falsyValues.indexOf( str ) === -1;
        }
        if ( type === DataType.NUMBER ) {
            return ( str: string ) => Number( str );
        }
        if ( type === DataType.STRING_HTML ) {
            if ( !CsvUtil.converter ) {
                CsvUtil.converter = new Converter();
                CsvUtil.converter.setFlavor( 'github' );
            }
            return ( str: string ) => {
                // if no html tags are present, convert it from markdown.
                // even if its plain text, line breaks will be preserved
                const htmlText = /<\/?[a-z][\s\S]*>/i;
                if ( !htmlText.test( str )) {
                    return CsvUtil.converter.makeHtml( str );
                }
                return str;
            };
        }
        if ( type === DataType.DATE ) {
            return ( str: string ) => {
                if ( parseFloat( str ).toString() === str ) {
                    return Number( str );
                }
                return Date.parse( str );
            };
        }
        if ( type === DataType.TAGS ) {
            return ( str: string ) => str && str.length > 0 ? str.split( ',' )
                .map( s => s.trim())
                .filter( s => s.length > 0 ).map( t => ({
                    name: t,
                })) : [];
        }
        return null;
    }
}
