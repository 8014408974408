import { Injectable } from '@angular/core';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { Command } from 'flux-core';
import { IConnectorEndPoint } from 'flux-diagram-composer';
import { IConnectorEndPointWithRef } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * LockGluepoints
 * Sets gluepoint for a given connector and endpoint data
 */
@Injectable()
@Command()
export class LockGluepoints extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The entryClass is the full class identifier as used in shape definitions.
     */
    public data: {
        id: string,
        endpoints: { from: IConnectorEndPointWithRef, to: IConnectorEndPointWithRef },
        gluepoints: any,
        fromGluepoints: any,
        isAlternativeGP: boolean,
        shapeData: ShapeModel,
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        const { data } = this;
        this.lockGluepoints( data.id, data.endpoints, data.gluepoints, data.fromGluepoints );
    }

    protected fixGluepoints( gluepoints: any ) {
        const fixedGluepoints = {} as any;
        Object.entries( gluepoints ).map(([ key, gluepoint ]: [string, any]) => {
            const { id } = gluepoint;
            let { x, y } = gluepoint;
            if ( id === '8Qj4Lmk9ziK' ) {
                x = 0;
            } else if ( id === 'oFFO6e4i7q8' ) {
                x = 1;
            } else if ( id === 'gpeast' ) {
                y = 0.5;
                x = 1;
            } else if ( id === 'gpwest' ) {
                y = 0.5;
                x = 0;
            } else if ( id === 'gpnorth' ) {
                y = 0;
                x = 0.5;
            } else if ( id === 'gpsouth' ) {
                y = 1;
                x = 0.5;
            }
            const name = x < 0.5 && y > 0.5 ? 'gpwest' : x > 0.5 && y === 1 ? 'gpeast' : x === 0.5 && y < 0.5 ? 'gpnorth' : 'gpsouth';
            fixedGluepoints[key] = { ...gluepoint, x, y, name };
        });
        return fixedGluepoints;
    }

    private lockGluepoints( connectorId: string,
                            endpoints: { from: IConnectorEndPointWithRef, to: IConnectorEndPointWithRef },
                            toGluepoints: any,
                            fromGluepoints: any ) {
        let toGluepointRef = {
            'pbnadF9Wirq': { id: 'pbnadF9Wirq', x: 0, y: 0.5, name: 'gpwest' },
            'P9d4172UDLb': { id: 'P9d4172UDLb', x: 1, y: 0.5, name: 'gpeast' },
            '0FUuJlNPewl': { id: '0FUuJlNPewl', x: 0.5, y: 0, name: 'gpnorth' },
            'BRZfYLOPiRa': { id: 'BRZfYLOPiRa', x: 0.5, y: 1, name: 'gpsouth' },
        };
        let fromGluepointRef = { ...toGluepointRef };

        if ( toGluepoints ) {
            toGluepointRef = this.fixGluepoints( toGluepoints );
        }
        if ( fromGluepoints ) {
            fromGluepointRef = this.fixGluepoints( fromGluepoints );
        }
        const { changeModel } = this;
        const connector = changeModel.shapes[connectorId] as any;
        const h = connector.path.h || connector.path[ connector.path.headId ]  as IConnectorEndPoint;
        const t = connector.path.t || connector.path[ connector.path.tailId ] as IConnectorEndPoint;
        const [ fromGluepointId ] =
            Object.entries( fromGluepointRef ).find(([ , value ]: [string, any]) => {
                const { x, y } = value;
                return endpoints.from && x === endpoints.from.x && y === endpoints.from.y;
        }) || [];
        const [ toGluepointId ] =
            Object.entries( toGluepointRef ).find(([ , value ]: [string, any]) => {
                const { x, y } = value;
                return endpoints.to && x === endpoints?.to.x && y === endpoints?.to.y;
        }) || [];
        if ( toGluepointId ) {
            t.gluepointId = toGluepointId;
            t.gluepointLocked = true;
        }
        if ( fromGluepointId ) {
            h.gluepointLocked = true;
            h.gluepointId = fromGluepointId;
        }

        // We pass this info to the plus create service.
        // this is required for the gluepoints to lock on initial draw,
        // but unlocks them in case if user wants to move the shape around
        this.resultData = {
            [`shapes.${connector.id}.path.h.gluepointLocked`]: false,
            [`shapes.${connector.id}.path.t.gluepointLocked`]: false,
        };
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( LockGluepoints, 'name', {
    value: 'LockGluepoints',
});
