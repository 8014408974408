import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, ResourceLoader, StateService } from 'flux-core';
import { IPoint2D, ShapeType } from 'flux-definition';
import { DataStore } from 'flux-store';
import { keyBy, values } from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { TemplateModel } from './template.mdl';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { TemplateLibraryService } from './template-library.svc';
import { FOLDER_PANEL_SCREEN_SIZE } from 'apps/nucleus/src/left-sidebar/folder-panel/folder-panel-view.i';

/**
 * This command loads templates from the server to the local cache.
 * If the command succeeds, we can assme that all requested templates
 * are available in indexed database cache.
 */
@Injectable()
@Command()
export class GetTemplate extends AbstractMessageCommand {
    /**
     * This command is sent to the server.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        templateId: string;
    };

    /**
     * Inject the data store service for local cache.
     */
    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected resourceLoader: ResourceLoader,
        protected defLocator: DefinitionLocator,
        protected templateLibraryService: TemplateLibraryService,
    ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Remove templates which are already available on the client.
     * FIXME: do not use the diagram id to fetch templates.
     */
    public prepareData(): Observable<any> {
        return this.isAvailable( this.data.templateId ).pipe(
            tap( cached => {
                if ( cached ) {
                    // this.resourceId = null;
                }
            }),
        );
    }

    /**
     * Check whether there are templates to fetch from the server.
     */
    public execute() {
        return !!this.resourceId;
    }

    /**
     * Set the correct id for the template
     * FIXME: server should return the template id.
     */
    public executeResult(): Observable<void> {

        // FIXME Find why some templates have shapes without definition
        // Remove shapes without definition from the template ( broken shapes )
        Object.keys( this.resultData.template.shapes ).forEach( shapeId => {
            const shape = this.resultData.template.shapes[shapeId];
            if ( !shape.defId  ) {
                delete this.resultData.template.shapes[shapeId];
            }
        });

        const shapes = this.fixShapeIndex( this.fixShapeOffset( this.resultData.template.shapes ));
        const template = this.resultData.template;
        const user = this.state.get( 'CurrentUser' );
        const time = new Date().getTime();
        Object.keys( shapes ).forEach( shapeId => {
            shapes[shapeId].createdBy = {
                userId: user,
                time: time,
            };
        });
        const groups = this.resultData.template.groups;
        const dataDefs = this.resultData.template.dataDefs;
        const connections = this.resultData.template.connections;
        const name  =  this.resultData.template.name;

        return fromPromise( this.setDefaultBounds( shapes )).pipe( switchMap(() =>
            fromPromise( this.resourceLoader.getDiagramDefsDataByType( template.type )).pipe(
                switchMap(( def: any ) => {
                    const libs = def?.libraries[0].libs || [];
                    let bounds;
                    if (( template.tags || '' ).includes( 'createlyVIZ' ) && template.promptId ) {
                        const stateData = {
                            open: true,
                            templateName: template.name,
                            templateId: template.id,
                            promptId: template.promptId,
                            promptType: 'template',
                        } as any;
                        const vizSelectGenerate = this.state.get( 'VIZSelectGenerate' );
                        if ( vizSelectGenerate?.shapesJson ) {
                            stateData.promptType = vizSelectGenerate.promptType;
                            stateData.open = false;
                            stateData.shapeIds = vizSelectGenerate.shapeIds;
                            bounds = vizSelectGenerate.bounds;
                            this.state.set( 'VIZSelectGenerate', {});
                        }
                        this.state.set( 'AIPromptPopupState', stateData );
                        this.state.set( 'FolderPanelScreen', FOLDER_PANEL_SCREEN_SIZE.SIDE_SCREEN );
                    }

                    // Select correct shape library panel on FAB when template loads
                    this.templateLibraryService.selectCorrectLibraryPanel( this.state, libs ).subscribe();

                    return this.dataStore.insert( TemplateModel, {
                        id: this.data.templateId,
                        name,
                        content: { shapes, groups, connections, dataDefs },
                        libraries: libs,
                        bounds,
                    });
                }),
            ),
        ));
    }

    // FIXME: Some shapes do not have default bounds, but default bounds are mandatory.
    // Find why some templates have shapes without default bounds.
    protected async setDefaultBounds( shapes: any ) {
        const keys = Object.keys( shapes );
        for ( let index = 0; index < keys.length; index++ ) {
            const shape = shapes[keys[index]];
            if ( !shape.defaultBounds ) {
                const def: any = await this.defLocator.getDefinition( shape.defId, shape.version, false ).toPromise();
                shape.defaultBounds = { ...def.defaultBounds };
            }
        }
    }

    /**
     * The diagram used as the template would not be drawn near 0,0 point.
     * FIXME: server should fix shape offsets before sending it to the client
     * FIXME: add support for connectors in templates
     */
    private fixShapeOffset( shapes: any ) {
        const min = this.findTopLeftPoint( shapes );
        for ( const shapeId in shapes ) {
            const shape = shapes[shapeId];
            if ( shape.type === ShapeType.Connector ) {
                const points = ConnectorModel.getPathPoints( shape.path );
                for ( const point of points ) {
                    point.x -= min.x;
                    point.y -= min.y;
                    if ( point.c1 ) {
                        point.c1.x -= min.x;
                        point.c1.y -= min.y;
                    }
                    if ( point.c2 ) {
                        point.c2.x -= min.x;
                        point.c2.y -= min.y;
                    }
                }
            } else {
                shape.x -= min.x;
                shape.y -= min.y;
            }
        }
        return shapes;
    }

    /**
     * Find the minimum x, y point on the diagram
     */
    private findTopLeftPoint( shapes: any ): IPoint2D {
        let min: IPoint2D = null;
        for ( const shapeId in shapes ) {
            const shape = shapes[shapeId];
            if ( shape.type === ShapeType.Connector ) {
                const points = ConnectorModel.getPathPoints( shape.path );
                for ( const point of points ) {
                    if ( !min ) {
                        min = { x: point.x, y: point.y };
                        continue;
                    }
                    if ( point.x < min.x ) {
                        min.x = point.x;
                    }
                    if ( point.y < min.y ) {
                        min.y = point.y;
                    }
                }
            } else {
                if ( !min ) {
                    min = { x: shape.x, y: shape.y };
                    continue;
                }
                if ( shape.x < min.x ) {
                    min.x = shape.x;
                }
                if ( shape.y < min.y ) {
                    min.y = shape.y;
                }
            }
        }
        return min;
    }

    /**
     * The diagram used as the template would have random z-index values
     * FIXME: server should fix shape z-index before sending it to the client
     */
    private fixShapeIndex( shapes: any ) {
        const shapesArray = values( shapes ).sort(( a, b ) => a.zIndex - b.zIndex );
        for ( let i = 0; i < shapesArray.length; ++i ) {
            shapesArray[i].zIndex = i;
        }
        return keyBy( shapesArray, 'id' );
    }

    /**
     * Check whether a template is available in local cache.
     */
    private isAvailable( id: string ): Observable<boolean> {
        return this.dataStore.findOneLatest( TemplateModel, { id: id }).pipe(
            map( model => Boolean( model )),
        );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( GetTemplate, 'name', {
    value: 'GetTemplate',
});
