import { Event } from '@creately/createjs-module';
/**
 * KeyboardSelectionInteractionEvent
 * This event is used to indicate keyboard interactoins on
 * selected shape(s) or connector(s).
 * The relatedEvent is to get the data for the interaction.
 * The event type name is keyboardSelectionInteraction
 */
export class KeyboardSelectionInteractionEvent extends Event {

    constructor( public relatedEvent: KeyboardEvent ) {
        super( 'keyboardSelectionInteraction' , false, false );
    }

}
