import { jsBezier } from 'jsbezier';
import { Point } from './point';
import { IPoint2D, IPointCurve } from 'flux-definition';
import { Rectangle } from './rectangle';

/**
 * A generic Curve class used for Curves related calculations.
 * The Curve is nth degree bezier curve and the constructor
 * expects an array of points ( IPoint2D ) in the order of
 * [ start, control point 1, ..., control point N, end ]
 */
export class Curve {


    /**
     * Creates a curve form the given [from] and [to] points
     */
    public static fromPoints( from: IPointCurve, to: IPointCurve ): Curve {
        const pts: Array<IPoint2D> = [];
        pts.push( from );
        if ( to.c1 ) {
            pts.push( to.c1 );
        }
        if ( to.c2 ) {
            pts.push( to.c2 );
        }
        pts.push( to );
        return new Curve( pts );
    }

    /**
     * The order of the points is [ start, control point 1, ..., control point N, end ]
     */
    constructor( public points: Array<IPoint2D> ) {}

    /**
     * Returns the approximate length of the curve
     */
    public get length(): number {
        return jsBezier.getLength( this.points );
    }

    /**
     * Returns an approximate bounds retangle.
     * NOTE: only works for cubic bezier curves
     */
    public get bounds(): Rectangle {
        if ( this.points.length === 4 ) {
            return this.calculateCubicBounds();
        }
        throw new Error( 'Curve.bounds is only implemented for cubic curves' );
    }

    /**
     * Calculates the coordinates of the point on the given Bezier curve at the
     * given position.
     *
     * @param ratio number  A decimal in the range [0-1]
     *                      which indicates a point some proportion along the length of the curve.
     *
     * @param start boolean The point to start calculation from, by default it measures
     *                      fron the start point,if false, it measures from the end point
     */
    public getPointByRatio( ratio: number, start: boolean = true ): IPoint2D {
        const location = this.length * ratio;
        return this.getPointByLength( location, start );
    }

    /**
     * Calculates the coordinates of the point on the given Bezier curve at the
     * given position.
     *
     * @param length number    The distance along the curve from start / end point
     * @param start boolean    The point to start calculation from, by default it measures
     *                         fron the start point,if false, it measures from the end point
     */
    public getPointByLength( length: number, start: boolean = true ): IPoint2D {
        if ( start === true ) {
            if ( length === 0 ) {
                return this.points[0];
            }
            // NOTE: location 1 is at the first point given to this function
            return jsBezier.pointAlongCurveFrom( this.points, 1, -length );
        } else {
            if ( length === 0 ) {
                return this.points[ this.points.length - 1 ];
            }
            // NOTE: location 0 is at the last point given to this function
            return jsBezier.pointAlongCurveFrom( this.points, 0, length );
        }
    }

    /**
     * Calculates the nearest point to the given point on the curve.
     * returns the point and length to that point
     * @param The test point
     * @param { point: IPoint2D, location: number } The nearest point cordinates and the length fraction along the curve
     */
    public getNearestPoint( point: IPoint2D ): { point: IPoint2D, location: number } {
        return jsBezier.nearestPointOnCurve( point, this.points );
    }

    /**
     * Calcualtes bounds of a cubic bezier curve.
     * Reference: http://pomax.nihongoresources.com/pages/bezier/
     * Reference: https://stackoverflow.com/a/14429749
     */
    private calculateCubicBounds(): Rectangle {
        const [ p1, p2, p3, p4 ] = this.points;
        const xPoints = [
                ...this.quadraticRoots(
                    -3 * p1.x + 9 * p2.x - 9 * p3.x + 3 * p4.x,
                    6 * p1.x - 12 * p2.x + 6 * p3.x,
                    3 * p2.x - 3 * p1.x,
                ),
                ...this.quadraticRoots(
                    -3 * p1.y + 9 * p2.y - 9 * p3.y + 3 * p4.y,
                    6 * p1.y - 12 * p2.y + 6 * p3.y,
                    3 * p2.y - 3 * p1.y,
                ),
            ]
            .filter( t => t >= 0 && t <= 1 )
            .map( t => this.cubicCurvePoint( t ));
        const min = Point.min( p1, p4, ...xPoints );
        const max = Point.max( p1, p4, ...xPoints );
        return new Rectangle( min.x, min.y, max.x - min.x, max.y - min.y );
    }

    /**
     * Returns ( -b ± sqrt( b² - 4ac ) ) / 2a
     */
    private quadraticRoots( a: number, b: number, c: number ): number[] {
        // NOTE: handle small "a" values
        if ( Math.abs( a ) < 1e-12 && Math.abs( b ) > 1e-12 ) {
            return [ -c / b ];
        }
        const sqrtVal = Math.sqrt( Math.pow( b, 2 ) - 4 * a * c );
        if ( Number.isNaN( sqrtVal )) {
            return [];
        }
        return [
            ( -b + sqrtVal ) / ( 2 * a ),
            ( -b - sqrtVal ) / ( 2 * a ),
        ];
    }

    /**
     * Returns: a(1-x)³ + 3bx(1-x)² + 3cx²(1-x) + dx³
     */
    private cubicValue( a: number, b: number, c: number, d: number, x: number ): number {
        const mx = 1 - x;
        return (
            a * mx * mx * mx +
            3 * b * x * mx * mx +
            3 * c * x * x * mx +
            d * x * x * x
        );
    }

    /**
     * Interpolate a cubic curve with a t value.
     */
    private cubicCurvePoint( t: number ): IPoint2D {
        const [ p1, p2, p3, p4 ] = this.points;
        return {
            x: this.cubicValue( p1.x, p2.x, p3.x, p4.x, t ),
            y: this.cubicValue( p1.y, p2.y, p3.y, p4.y, t ),
        };
    }
}
