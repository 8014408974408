import { cloneDeep } from 'lodash';
import { AbstractCommand, Command, CommandInterfaces, StateService } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * This ApplyTextEditorStyles command is to apply styles to the editor.
 * This command updates the TextToolbarState state that is subscribed
 * for applying editor styles and can apply mutilple styles at once
 * The command date is key value pair simillar to ITextStyles
 */
@Injectable()
@Command()
export class ApplyTextEditorStyles extends AbstractCommand {

    /**
     * The styles that are boolean type
     */
    public static TOGGLE_STYLE_PROPS = [ 'bold', 'italic', 'underline', 'strikeout' ];

    public static get dataDefinition(): {}  {
        return {
            /* {
                    size: nunber,
                    font: string,
                    color: string,
                    bold: boolean,
                    italic: boolean,
                    underline: boolean,
                    strikeout: boolean,
                    align: string,
                    script: string,
                }
            */
            styles: true,
            takeFocus: false, // Used to indicate whether editor should focus, Should be true by default
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            [ 'TextToolbarState-' + this.data.stateContext ]: cloneDeep( this.data ),
        };
    }

    /**
     * Prepares the data necessary for the the state change
     */
    public prepareData(): void {
        if ( this.data.takeFocus !== false ) {
            this.data.takeFocus = true;
        }
        this.handleTogglableStyles();
    }

    public execute (): boolean {
        return true;
    }

    /**
     * bold, italic, underline, strikeout are toggle buttons
     * the value, ( wheather apply or remove the style ) should be decided
     * by considering the current TextEditorState.
     */
    private handleTogglableStyles() {
        const editorState = this.state.get( 'TextEditorState-' + this.data.stateContext ).styles;
        for ( const k of ApplyTextEditorStyles.TOGGLE_STYLE_PROPS ) {
            if ( this.data.styles[k] === null ) {
                this.data.styles[k] = !editorState[k];
            }
        }
    }
}

Object.defineProperty( ApplyTextEditorStyles, 'name', {
    value: 'ApplyTextEditorStyles',
});
