import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command } from 'flux-core';
import { DiagramInfoModel, DiagramInfoModelStore } from 'flux-diagram';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * This is the command which changes the privacy of the diagram and send the
 * request to the server
 *
 * data: {
 *     privacy { level, role }
 *     diagramId id of the diagram that needs to change the privacy
 * }
 */
@Injectable()
@Command()
export class ChangeDiagramTeamShare extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            teamShare: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        return modelStore.getAllTeamShare( this.resourceId ).pipe(
            tap( teamShares => this.previousData.teamShares = teamShares ),
        );
    }

    /**
     * execute
     * change diagram privacy
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        const teamShare = this.data.teamShare;
        if ( teamShare.delete ) { // TODO check if it is working
            return modelStore.storageRemoveTeamShare( this.resourceId, teamShare.id );
        }
        return modelStore.storageUpdateTeamShare( this.resourceId, teamShare.id, teamShare.teamId,
                                                        teamShare.role, teamShare.discoverable,
                                                        teamShare.anonymousAccess, teamShare.hash );
    }

    /**
     * executeResult
     * Update the privacy deatils with the response
     */
    public executeResult( response: any ): Observable<any> {
            const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
            if ( response.teamShare.delete ) {
                return modelStore.storageRemoveTeamShare( this.resourceId, response.teamShare.id );
            }
            return modelStore
                .storageUpdateTeamShare( this.resourceId, response.teamShare.id, response.teamShare.teamId,
                                            response.teamShare.role, response.teamShare.discoverable,
                                            response.teamShare.anonymousAccess, response.teamShare.hash );
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore ;
        if ( !this.previousData.teamShare?.id ) {
            return modelStore.storageRemoveTeamShare( this.resourceId, this.data.teamShare.id );
        }
        return modelStore.storageUpdateTeamShare( this.resourceId, this.previousData.teamShare.id,
                            this.previousData.teamShare.teamId, this.previousData.teamShare.role,
                            this.previousData.teamShare.discoverable, this.previousData.teamShare.anonymousAccess,
                            this.previousData.teamShare.hash );
    }

}

Object.defineProperty( ChangeDiagramTeamShare, 'name', {
    value: 'ChangeDiagramTeamShare',
});
