import { SETTINGS_DATAITEM_ID } from './../../../base/ui/shape-data-editor/shape-data-settings.cmp';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';
import { DiagramChangeService } from './../../../base/diagram/diagram-change.svc';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * CopyShapeData
 * This commad is to change the shape properties
 */
@Injectable()
@Command()
export class CopyShapeData extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapeId: string, // Shape id to copy data from
        option: 'SimilarShapes' | 'SimilarShapesInContainer' | 'AllShapesInContainer' | 'AllShapes';
    };

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
    }

    public prepareData() {
        if ( !this.data.option || !this.data.shapeId ) {
            return false;
        }
        return this[ `copyTo${this.data.option}` ]();
    }

    protected copyToSimilarShapes() {
        const shape = this.changeModel.shapes[ this.data.shapeId ];
        for ( const id in this.changeModel.shapes ) {
            if ( id === this.data.shapeId ) {
                continue;
            }
            const s = this.changeModel.shapes[ id ];
            if ( shape.defId === s.defId ) {
                this.copyData( s, shape );
            }
        }
        return true;
    }

    protected copyToSimilarShapesInContainer() {
        const shape: ShapeModel = this.changeModel.shapes[ this.data.shapeId ] as any;
        if ( !shape.containerId ) {
            return false;
        }
        const container: ShapeModel = this.changeModel.shapes[ shape.containerId ] as any;
        for ( const id in container.children ) {
            if ( id === this.data.shapeId ) {
                continue;
            }
            const s = this.changeModel.shapes[ id ];
            if ( shape.defId === s.defId ) {
                this.copyData( s, shape );
            }
        }
        return true;
    }

    protected copyToAllShapesInContainer() {
        const shape: ShapeModel = this.changeModel.shapes[ this.data.shapeId ] as any;
        if ( !shape.containerId ) {
            return false;
        }
        const container: ShapeModel = this.changeModel.shapes[ shape.containerId ] as any;
        for ( const id in container.children ) {
            if ( id === this.data.shapeId ) {
                continue;
            }
            const s = this.changeModel.shapes[ id ];
            this.copyData( s, shape );
        }
        return true;
    }

    protected copyToAllShapes() {
        const shape: ShapeModel = this.changeModel.shapes[ this.data.shapeId ] as any;
        for ( const id in this.changeModel.shapes ) {
            if ( id === this.data.shapeId ) {
                continue;
            }
            const s = this.changeModel.shapes[ id ];
            this.copyData( s, shape );
        }
        return true;
    }

    protected copyData( toShape, fromShape ) {
        toShape.dataSetId = fromShape.dataSetId;
        const optionalOnly = {};
        const dataItems = fromShape.getDataItems( this.changeModel );
        for ( const key in dataItems ) {
            const data = dataItems[key];
            // "optional" property is true for date items added via the editor
            if ( data.optional || key === SETTINGS_DATAITEM_ID ) {
                optionalOnly[ key ] = fromShape.data[key];
            }
        }
        Object.assign( toShape.data, optionalOnly );
    }

}

Object.defineProperty( CopyShapeData, 'name', {
    value: 'CopyShapeData',
});
