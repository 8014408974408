import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore, DataSync } from 'flux-store';
import { AbstractModelSubscription } from './abstract-model-subscription.sub';
import { ModelSubscriptionService } from './model-subscription.svc';
import { merge, of, empty } from 'rxjs';
import { SubscriptionStatus } from '../framework/subscription-type';
import { switchMap, map, filter, distinctUntilChanged } from 'rxjs/operators';

/**
 * This service is responsible for managing all model subscriptions in nucleus.
 * It makes use of the injected model subscription service to perform subscription operations.
 *
 * When requesting to create subscriptions, the actual type of the subscriptions created may
 * be different than what was originally requested by the user. For this reason, the model
 * subscription manager keeps a map of subscriptions created against their originally requested type.
 *
 * For further details on subscription types, please refer to {@link AbstractSubscription}.
 * More information on model subscriptions can be found at {@link ModelSubscriptionService}
 *
 * TODO: Refactor:
 * The current design assumes there can only be one model subscription for a given
 * resource id.
 *  - Although the subscription manager providers functionality to retrieve the subscription
 *    instance, external services doing operations on the sub instance is discouraged.
 *  - The subscription manager should provide a means for externals to subscribe to a
 *    particular subscriptions' status changes by its resource id.
 *  - When requesting to start a subscription with a type and a resource id, the subscription
 *    manager should first check if a lower level subscription is already active for the same resource
 *    id. If such subscription exists, it needs to be stopped, and the higher level
 *    subscription needs to be created.
 *
 * @author  Ramishka
 * @since   2018-01-15
 */
@Injectable()
export class ModelSubscriptionManager {

    /**
     * Subscriptions map.
     * This holds a subscription instance against their resource id.
     * It is assumed there can be only one model subscription per resource id.
     */
    protected subscriptions: BehaviorSubject<{ [ resourceId: string ]: AbstractModelSubscription }>;

    /**
     * Constructor. Initializes the subscriptions map.
     */
    constructor (
        protected dataStore: DataStore,
        protected dataSync: DataSync,
        protected modelSub: ModelSubscriptionService ) {
        this.subscriptions = new BehaviorSubject({});
    }

    /**
     * Creates and starts a model subscription of a given type.
     * If the same type of subscription with the same topic is already created,
     * it will not be created again unless its in an errored or a completed state.
     * @param givenType - Subscription type
     * @param resourceId - resource id
     * @param pld - payload (optional)
     * @return observable that emits the subscription instance
     */
    public start (
        givenType: typeof AbstractModelSubscription,
        resourceId: string,
        pld?: {[key: string]: any },
    ): Observable<AbstractModelSubscription> {
        const sub: AbstractModelSubscription = this.get( resourceId );
        if ( sub === null ) { // Initiated
            return this.subscriptions.pipe( // Emit when done
                filter( subs => !!subs[ resourceId ]),
                map( subs =>  subs[ resourceId ]),
            );
        }
        if ( sub ) {
            if ( !this.isActive( sub )) {
                return this.stop( resourceId ).pipe(
                    switchMap( prevSub => this.startSubscription( givenType, resourceId, pld )),
                );
            }
            return of( sub );
        } else {
            return this.startSubscription( givenType, resourceId, pld );
        }
    }

    /**
     * Stops a given subscription.
     * @param givenType Subscription type to stop
     * @param resourceId resource id
     * @param sendMessage - whether the sub.end message should be sent to the server
     *                      when closing the subscription
     */
    public stop ( resourceId: string, sendMessage: boolean = true ): Observable<AbstractModelSubscription> {
        const sub: AbstractModelSubscription = this.get( resourceId );
        if ( sub ) {
            this.modelSub.stop( sub, sendMessage );
            const subs = this.subscriptions.value;
            delete subs[ resourceId ];
            this.subscriptions.next( subs );
            return of( sub );
        }
        // If the user goes offline, the subscription will be stopped. Later when user
        // navigate to another document using folder panel, then the 'diagram-thumbnail-item'
        // try to stops the diagram sub which is already stopped due to offline. Hence returning
        // observable to emit undefined so that pipe will still continue.
        return of( undefined );
    }

    /**
     * Fetches a subscription instance from the subscriptions map.
     * @param givenType - subscription type
     * @param resourceId - resource id
     */
    public get ( resourceId: string ): AbstractModelSubscription {
        return this.subscriptions.value[ resourceId ];
    }

    /**
     * Fetches a subscription instance from the subscriptions map.
     * @param givenType - subscription type
     * @param resourceId - resource id
     */
    public getFutureSub( resourceId: string ): Observable<AbstractModelSubscription> {
        return this.subscriptions.pipe(
            map( subs => subs[ resourceId ]),
            filter( sub => !!sub ),
            distinctUntilChanged(),
        );
    }

    /**
     * Stops all subscriptions that are managed by the model subscription manager.
     * @param sendMessage - whether the sub.end message should be sent to the server
     *                      when closing each subscription.
     * @return observable which completes when all subscriptions are stopped
     */
    public stopAll( sendMessage: boolean = true ): Observable<AbstractModelSubscription> {
        const subIds = Object.keys( this.subscriptions.value );
        if ( subIds.length !== 0 ) {
            const observables = [];
            subIds.forEach( subId => observables.push( this.stop( subId, sendMessage )));
            return merge( ...observables );
        }
        return empty();
    }

    /**
     * Starts a model subscription of a given type.
     * @param givenType - Subscription type
     * @param resourceId - resource id
     * @param pld - payload
     * @return observable which emits the subscription instance
     */
    protected startSubscription(
        givenType: typeof AbstractModelSubscription,
        resourceId: string,
        pld?: {[key: string]: any },
    ): Observable<AbstractModelSubscription> {
        const subs = this.subscriptions.value;
        subs[resourceId] = null;
        this.subscriptions.next( subs );
        return this.getSubType( givenType, resourceId ).pipe(
            map( type => {
                const instance: AbstractModelSubscription = new type( resourceId, this.dataStore, this.dataSync );
                subs[resourceId] = instance;
                this.subscriptions.next( subs );
                this.modelSub.start( instance, pld );
                return instance;
            }),
        );
    }

    /**
     * Derives the actual subscription type from a given type.
     * Every type of model subscription has a modelType property which determines which type
     * of model is fetched via that subscription. Models can be in an inheritance hierarchy, each
     * carrying more information than its parent. If a model matching the current resource id already exists
     * in the system, and the said model is lower in the inheritance tree than the requested type,
     * then subscription type to be created should change to map this models type.
     *
     * @param givenType - subscription type
     * @param resourceId - resource id
     * @return Observable which emits the subscription type.
     */
    protected getSubType(
        givenType: typeof AbstractModelSubscription,
        resourceId: string,
    ): Observable<typeof AbstractModelSubscription> {
        const { modelType: givenModelType, alternatives } = givenType.prototype;
        return this.dataStore
            .findOneLatest( givenModelType, { id: resourceId }).pipe(
                map( model => {
                    if ( !model ) {
                        return givenType;
                    }
                    if ( model.modelLevel <= givenModelType.getModelLevel()) {
                        return givenType;
                    }
                    const altType = alternatives
                        .find( type => type.prototype.modelType.getModelLevel() === model.modelLevel );
                    if ( altType ) {
                        return altType;
                    }
                    return givenType;
                }),
            );
    }

    /**
     * Returns true if the given subscription has not completed or errored.
     */
    private isActive( sub: AbstractModelSubscription ): boolean {
        return ( sub.status.value.subStatus === SubscriptionStatus.started ) ||
            ( sub.status.value.subStatus === SubscriptionStatus.created );
    }
}
