import { Injectable } from '@angular/core';


export enum ViewportIntersectionEventType {
    /**
     * Event type to be dispatched on the tartget element when the element itself
     * enteres the viewport or already is in the viewport
     */
    inside = 'enteredViewport',

    /**
     * Event type to be dispatched on the tartget element when the element itself
     * leaves the viewport or already is outside the viewport
     */
    left = 'leftViewport',

}

/**
 * This service is to be used to detect if a given element enters the viewport or already is
 * in the viewport
 *
 * @author  thisun
 * @since   2023-03-29
 *
 */
@Injectable()
export class ViewportIntersectionObserver {

    protected _observer: IntersectionObserver;


    public get observer() {
        return this._observer;
    }

    /**
     * Creates a new IntersectionObserver where the root element is viewport
     * This obserever can be used to check if a particular elemnt enters the viewport
     */
    public initialize() {
        const options = {
            rootMargin: '0px',
            threshold: 0, // isIntersecting will be true if 0.000000001% of the target element enters the viewport
          };
        this._observer = new IntersectionObserver( this.callback, options );
    }

    protected callback = entries => {
        entries.forEach(( entry: any ) => {
            if ( entry.isIntersecting ) {
                const e = new Event( ViewportIntersectionEventType.inside );
                ( entry.target as HTMLElement ).dispatchEvent( e );
            }
        });
    }

}
