import { ICanvasInstruction, IComputedStyleDefition, GradientUsageType, GradientType } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';
import * as TinyColor from 'tinycolor2';
import { Matrix } from 'flux-core';
import { IStyleDefinition } from 'flux-definition';
import * as md5 from 'md5';
import * as stableStringify from 'fast-stable-stringify';
import { max } from 'lodash';

/**
 * class AbstractCanvasInstructionFactory
 * This class implements common functionality required for other canvas factory implementations.
 * Each canvas factory must extend this class.
 */
export class AbstractCanvasInstructionFactory {

    /**
     * Parameter name used to calculate actual width of the shape.
     * The value of this is a scale factor calculated from expected width from the shape model
     */
    protected sw: string = 'sw';

    /**
     * Parameter name used to calculate actual height of the shape.
     * The value of this is a scale factor calculated from expected height from the shape model
     */
    protected sh: string = 'sh';

    /**
     * Offset parameter name for the shape placement on X axis
     */
    protected xo: string = 'xo';

    /**
     * Offset parameter name for the shape placement on Y axis
     */
    protected yo: string = 'yo';

    /**
     * Name of the variable which holds the style definitions on the shape class.
     */
    protected styleDefinitionsRefVar = 'styleDefinitions';

    /**
     * Internal structure to hold all the stye definitions created during style instruction generator process
     */
    protected styleDefinitions: IComputedStyleDefition[];

    /**
     * The parameter values each gradient can have
     */
    private gradientParams = {
        radialGradient: [ 'cx', 'cy', 'r', 'fx', 'fy', 'fr' ],
        linearGradient: [ 'x1', 'y1', 'x2', 'y2' ],
    };

    /**
     * Converts a given string of numbers into a number array.
     * ie: 1 2,4,-5,0 -6 will be converted to [ 1, 2, 4, -5, 0, -6 ]
     * @param numberString String of numbers needs to be converted into an array
     */
    protected numberStringToArray( numberString: string ): number[] {
        const strArray = numberString.trim().replace( /[,\s]+|[\s,]+|[\s]+/g, ',' ).split( ',' );
        // Convert all to numbers
        const numArray = [];
        strArray.forEach( str => {
            numArray.push( +str );
        });
        return numArray;
    }

    /**
     * Generate graphics instructions equavelent to the given svg polyline draw instruction points.
     * @param points Points of the svg polyline instruction
     */
    protected generatePolylineInstructions( points: string ): ICanvasInstruction[] {
        const instructions: ICanvasInstruction[] = [];
        const svgPointsArray = this.numberStringToArray( points );
        for ( let index = 0; index < svgPointsArray.length; index += 2 ) {
            if ( index === 0 ) {
                instructions.push({
                    instruction: 'moveTo',
                    params: [ svgPointsArray[ index ], svgPointsArray[ index + 1 ] ],
                });
            } else {
                instructions.push({
                    instruction: 'lineTo',
                    params: [ svgPointsArray[ index ], svgPointsArray[ index + 1 ] ],
                });
            }
        }
        return instructions;
    }

    /**
     * Exctract colors and ratios from given shape node related to a gradient
     * @param data
     */
    protected exctractGradientColorOffsets( data: IShapeNode ): { colors: string[], ratios: string[] } {
        const colorArray = [];
        const ratioArray = [];
        if ( data.data.stopValues ) {
            data.data.stopValues.forEach( stopValue => {
                const stopColor = stopValue.stopOpacity ?
                    this.colorToRgba( stopValue.stopColor, +stopValue.stopOpacity ) : stopValue.stopColor;
                colorArray.push( stopColor );
                ratioArray.push( stopValue.offset );
            });
        }
        return { colors: colorArray, ratios: ratioArray };
    }

    /**
     * Convert given color string to RGBA format using the opacity given
     * @param color any color string
     * @param opacity
     */
    protected colorToRgba( color: string, opacity: number ): string {
        const converter = new TinyColor( color.replace( '0x', '' ));
        converter.setAlpha( opacity );
        return converter.toRgbString();
    }

    /**
     * Convert an ellipse to a svg path commands. This will accept svg ellipse draw
     * parameters as input.
     * @param cx
     * @param cy
     * @param rx
     * @param ry
     */
    protected ellipseToPath( cx: number, cy: number, rx: number, ry: number ): string {
        let output = `M ${cx - rx},${cy}`;
        output += `A ${rx},${ry},0,1,0,${( 2 * rx ) + ( cx - rx )},${cy}`;
        output += `A ${rx},${ry},0,1,0,${cx - rx},${cy}`;
        return output;
    }

    /**
     * Convert given svg circle parameters to svg path based instructions.
     * @param cx
     * @param cy
     * @param r
     */
    protected circleToPath( cx: number, cy: number, r: number ) {
        return this.ellipseToPath( cx, cy, r, r );
    }

    /**
     * Apply given matrix transformation to the given graphics line or move instruction set.
     * @param instructions graphics instruction set
     * @param matrix transformation matrix
     */
    protected applyTransformationsForMoveAndLines(
        instructions: ICanvasInstruction[],
        matrix: Matrix = new Matrix(),
        ): ICanvasInstruction[] {
        instructions.forEach( instruction => {
            const pointT = matrix.transform( instruction.params[0], instruction.params[1]);
            instruction.params = [
                `${this.sw} * ${pointT.x} + ${this.xo}`,
                `${this.sh} * ${pointT.y} + ${this.yo}`,
            ];
        });
        return instructions;
    }

    /**
     * Apply given transformation matrix to the given bezier curve graphics instructions.
     * @param instructions Bezier curve graphics instructions
     * @param matrix Transformation matrix
     */
    protected applyTransformationsForBezierCurves(
        instructions: ICanvasInstruction[],
        matrix: Matrix = new Matrix(),
        ): ICanvasInstruction[] {
        instructions.forEach( instruction => {
            const point1 = matrix.transform( instruction.params[0], instruction.params[1]);
            const point2 = matrix.transform( instruction.params[2], instruction.params[3]);
            const point3 = matrix.transform( instruction.params[4], instruction.params[5]);
            instruction.params = [
                `${this.sw} * ${point1.x} + ${this.xo}`,
                `${this.sh} * ${point1.y} + ${this.yo}`,
                `${this.sw} * ${point2.x} + ${this.xo}`,
                `${this.sh} * ${point2.y} + ${this.yo}`,
                `${this.sw} * ${point3.x} + ${this.xo}`,
                `${this.sh} * ${point3.y} + ${this.yo}`,
            ];
        });
        return instructions;
    }

    /**
     * Apply given transformation matrix to the given graphics quadratic bezier curve instruction set.
     * @param instructions Quadratic bezier curve instructions
     * @param matrix Transformation matrix
     */
    protected applyTransformationsForQuadraticBezierCurves(
        instructions: ICanvasInstruction[],
        matrix: Matrix = new Matrix(),
        ): ICanvasInstruction[] {
        instructions.forEach( instruction => {
            const point1 = matrix.transform( instruction.params[0], instruction.params[1]);
            const point2 = matrix.transform( instruction.params[2], instruction.params[3]);
            instruction.params = [
                `${this.sw} * ${point1.x} + ${this.xo}`,
                `${this.sh} * ${point1.y} + ${this.yo}`,
                `${this.sw} * ${point2.x} + ${this.xo}`,
                `${this.sh} * ${point2.y} + ${this.yo}`,
            ];
        });
        return instructions;
    }

    /**
     * Generates a style id for the given style definition
     * @param styleDefinition Complete style definiton
     */
    protected generateStyleId( styleDefinition: IStyleDefinition ): string {
        return md5( stableStringify( styleDefinition ));
    }

    /**
     * Check if a given shape node has gradient definitions based on percentage values
     * @param data
     * @param type 'radialGradient' | 'linearGradient'
     */
    protected hasGradientPercentages( data: IShapeNode, type: 'radialGradient' | 'linearGradient' ): boolean {
        return this.hasPercentage( data, this.gradientParams[ type ]);
    }

    /**
     * Return the most visible color of a gradient definition.
     * @param colors
     * @param ratios
     */
    protected getWidelyUsedGradientColor( colors: string[], ratios: string[]): string {
        const intArr = ratios.map( v => parseFloat( v ));
        const maxIndex = intArr.indexOf( max( intArr ));
        return colors[ maxIndex ];
    }

    /**
     * Determine gradient graphics instruction type based on the gradient and the usage type
     * @param type Gradient type
     * @param usageType how the gradient being used
     */
    protected getGradientInstructionType( type: GradientType, usageType: GradientUsageType ): string {
        if ( type === GradientType.linearGradient ) {
            if ( usageType === GradientUsageType.fill ) {
                return 'beginLinearGradientFill';
            }
            if ( usageType === GradientUsageType.stroke ) {
                return 'beginLinearGradientStroke';
            }
        }
        if ( type === GradientType.radialGradient ) {
            if ( usageType === GradientUsageType.fill ) {
                return 'beginRadialGradientFill';
            }
            if ( usageType === GradientUsageType.stroke ) {
                return 'beginRadialGradientStroke';
            }
        }
    }

    /**
     * Return gradient style definitions based on how the gradient being used
     * @param styleId
     * @param type
     */
    protected getGradientStyleDefinition( styleId: string, type: GradientUsageType ): ICanvasInstruction {
        if ( type === GradientUsageType.fill ) {
            return {
                instruction: 'beginFill',
                params: [ `${this.styleDefinitionsRefVar}['${styleId}'].style.fillColor` ],
            };
        }
        if ( type === GradientUsageType.stroke ) {
            return {
                instruction: 'beginStroke',
                params: [ `${this.styleDefinitionsRefVar}['${styleId}'].style.lineColor` ],
            };
        }
    }

    /**
     * Check if percentage values are present for a given set of properties on a shape node.
     * @param data
     * @param params
     */
    private hasPercentage( data: IShapeNode, params: string[]): boolean {
        for ( const key of params ) {
            if ( data.data[ key ] && data.data[ key ].split( '%' ).length > 1 ) {
                return true;
            }
        }
        return false;
    }

    // /**
    //  * Convert gradient values having percentages to coordiate values.
    //  * This require the bounds of the shape the gradient being applied, and the current
    //  * Canvas drawer framework do not support that. To support that, during the SVG inspection,
    //  * the bounds must be calculated and set to the IShapeNode which represents the SVG shape.
    //  * See fabric.js SVG parser to get an understanding how we can calculate bounds from the SVG element itself.
    //  * @param values Gradient values with percentages
    //  * @param bounds Bounds of the shape the gradient being applied to
    //  * @param gradientUnits Gradient unit type being used on the SVG
    //  */
    // protected gradientValues(
    //     values: IGradientValues,
    //     bounds: IGradientElementBounds,
    //     gradientUnits: string = 'objectBoundingBox',
    // ): IGradientValues {
    //     let propValue = 0;
    //     let addFactor = 0;
    //     let multiFactor = 0;

    //     for ( const prop in values ) {
    //         propValue = parseFloat( prop );
    //         if ( typeof prop === 'string' && /^(\d+\.\d+)%|(\d+)%$/.test( prop )) {
    //             multiFactor = 0.01;
    //         } else {
    //             multiFactor = 1;
    //         }
    //         if ( prop === 'x1' || prop === 'x2' || prop === 'r2' ) {
    //             multiFactor *= gradientUnits === 'objectBoundingBox' ? bounds.width : 1;
    //             addFactor = gradientUnits === 'objectBoundingBox' ? bounds.left || 0 : 0;
    //         } else if ( prop === 'y1' || prop === 'y2' ) {
    //             multiFactor *= gradientUnits === 'objectBoundingBox' ? bounds.height : 1;
    //             addFactor = gradientUnits === 'objectBoundingBox' ? bounds.top || 0 : 0;
    //         }
    //         values[prop] = propValue * multiFactor + addFactor;
    //     }
    //     return values;
    // }

}

// /**
//  * FIXME: Being used on gradient percentage converter function
//  * Refactor when implementing the percentage gradient support
//  * This is not the right place to put the interfaces
//  */
// interface IGradientValues {
//     x1: string | number;
//     y1: string | number;
//     x2: string | number;
//     y2: string | number;
//     r1: string | number;
//     r2: string | number;
// }

// interface IGradientElementBounds {
//     width: number;
//     height: number;
//     top: number;
//     left: number;
// }
