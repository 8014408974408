import { Observable, EMPTY, TimeoutError, throwError } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { ExecutionStep } from './execution-step';
import { CommandCancelError } from '../error/command-cancel-error';

/**
 * RunExecutionStep
 * This class is an implementation of the {@link ExecutionStep} which handles
 * the <code>execute</code> method of the command. For more details see {@link AbstractCommand}
 *
 * To see how this is used, see the {@link CommandExecutor}.prepareSequnce method.
 *
 * @author  hiraash
 * @since   2016-04-18
 */
export class RunExecutionStep extends ExecutionStep {

    /**
     * Mark this execution step as an async execution step.
     */
    public get asynchronous(): boolean {
        return this.command.asynchronous;
    }

    /**
     * When execute completes the result data must be emitted if
     * it has changed.
     */
    public get emitResult(): boolean {
        return true;
    }
    /**
     * Runs the execute method on the command and throws an exception in
     * case of a false is returned.
     */
    public process() {
        const result = this.command.execute();

        if ( result instanceof Observable ) {
            const executeTimeout = this.command.executeTimeout;
            if ( executeTimeout > 0 ) {
                return result.pipe(
                    timeout( executeTimeout ),
                    catchError( err => {
                        if ( err instanceof TimeoutError ) {
                            this.log.error( `Execution timed out. command: ${this.command.name}` );
                            return EMPTY;
                        }
                        return throwError( err );
                    }),
                    mergeMap(() => EMPTY ),
                );
            }
            return result.pipe( mergeMap(() => EMPTY ));
        }
        if ( !result ) {
            throw new CommandCancelError( 'Execution of command was cancelled from within the execute method' );
        }
    }
}

Object.defineProperty( RunExecutionStep, 'name', {
    value: 'RunExecutionStep',
});
