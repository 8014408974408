import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

/**
 * This is a helper service for managing routes in the application.
 * Currently it is used to keep track of routes navigated to by the
 * router as well as to modify the browser history.
 *
 * @author  Ramishka
 * @since   2019-03
 */
@Injectable()
export class RouteManager {

    /**
     * Holds the last route navigated to by the router.
     */
    public lastRoute: string;

    constructor( protected router: Router, protected location: Location ) {}

    /**
     * Initializes the route manager.
     * Starts listening to route changes.
     */
    public initialize() {
        this.listenToRouteChanges();
    }

    /**
     * Removes a given route from the browsers history.
     * @param route - the route to be removed. This is a url
     * similar to what is returned by the url property of {@link RouterState}.
     */
    public removeRouteFromHistory( route: string ) {
        this.location.replaceState( route );
    }

    /**
     * Starts listening to route changes.
     * When navigating to a new route has completed,
     * updates the last route to be that route.
     */
    protected listenToRouteChanges() {
        this.router.events.pipe(
            filter( event => event instanceof NavigationEnd ),
            tap(( e: any ) => {
                this.lastRoute = e.url;
            }),
        ).subscribe();
    }
}
