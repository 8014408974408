import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { CommentModel } from '../../diagram/model/comment.mdl';

/**
 * This command will add seen by user Id to the comment model.
 * It will take array of comment Ids as input along with the
 * seen user and update all of those comments with the seen by
 * user.
 */
@Injectable()
@Command()
export class MarkCommentSeen extends AbstractMessageCommand {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            commentIds: true,
            userId: true,
        };
    }

    /**
     * List of commands which will be used by this class.
     */
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    /**
     * This function will add the user to seenBy list.
     */
    public execute() {
        // FIXME: This will remove all other users from seenBy array and update it
        // with the current user.
        return this.dataStore.update( CommentModel,
            { id: { $in: this.data.commentIds }, diagramId: this.resourceId },
            {
                $set: { seenBy: [ this.data.userId ], comitted: false },
            });
    }

    /**
     * This function commits the changes to the datastore upon the success of
     * the execution.
     */
    public executeResult() {
        return this.dataStore.update( CommentModel,
            { id: { $in: this.data.commentIds }, diagramId: this.resourceId },
            {
                $set: { comitted: true },
            });
    }
}

Object.defineProperty( MarkCommentSeen, 'name', {
    value: 'MarkCommentSeen',
});
