<div class="panel-section" [class.close]="!open" [attr.data-testid]="testId">
    
    <div class="panel-heading-container fx-center-vertical" (click)="toggleItem()">
        <span tooltip placement="bottom">
            <svg class="panel-collapse-icon nu-icon-small">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
            </svg>
        </span>
        <svg *ngIf="icon" class="nu-icon">
            <use [attr.xlink:href]="iconHref"></use>
        </svg>
        <span>
            <h2 class="panel-sub-heading body">{{heading}}</h2>
        </span>
        <ng-content select="[header-content]"></ng-content>
    </div>

    <div class="{{panelClass}}">
        <ng-content></ng-content>
    </div>
    
</div>