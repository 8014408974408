import { Inject, Injectable } from '@angular/core';
import { Logger, ModifierUtils } from 'flux-core';
import { FillStyle, LineStylePreset } from 'flux-definition';
import { IEmittedDiagramChange } from 'flux-diagram-composer';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { LineStyle } from './line-style';
import { Palette } from './palette';
import { ColorMap, ColorPairs, PaletteData } from './palette-data';
import { ShapeStyle } from './shape-style';

export enum FillStylePreset {
    None = 'none',
    White = 'white',
    Flat = 'flat',
    Glass = 'glass',
    Gradient = 'gradient',
    Muted = 'muted',
    Vibrant = 'vibrant',
}

/**
 * This is a loader service that can be used to load styles and palettes.
 *
 * @author
 * @since 2017-11-06
 */

@Injectable()
export class StyleLoader {
    protected _palettes: Palette[] = [];
    protected _linePalettes: BehaviorSubject<Palette[]> = new BehaviorSubject<Palette[]>([]);
    protected palettes: { [ id: string ]: Palette } = {};

    private colorLookup: any;
    private paletteData: PaletteData;

    /**
     *
     * @param locator Add shape styles
     */
    constructor( @Inject( DiagramLocatorLocator ) protected ll: DiagramLocatorLocator ) {
        this.paletteData = new PaletteData();
        this.initColorLookup();
        this.createPalettes();
    }

    /**
     * Get all palettes.
     */
    public getPalattes(): Palette[] {
        return this._palettes;
    }

    public getHighlightedPalettes() {
        const palettes = [];
        this.paletteData.getHighlightedLinePalettes().forEach(
            palette => palettes.push( palette ),
        );
        return palettes;
    }

    /**
     * Get line palettes.
     */
    public getLinePalattes(): Observable<Palette[]> {
        return this._linePalettes;
    }

    public createPalettes() {

        this.paletteData.getAllPalettes().forEach(
            palette => this.initPalete( palette ),
        );

        this.paletteData.getAllLinePalettes().forEach(
            palette => this.initPalete( palette, true ),
        );
    }

    public getPalette( name: string ) {
        return this.palettes [ name ];
    }

    public getLineStylePreset( lineStyle: LineStylePreset, currStyle: ShapeStyle ): ShapeStyle {
        let style;
        if ( lineStyle === LineStylePreset.None ) {
            style = new ShapeStyle( 0 );
        } else if ( lineStyle === LineStylePreset.Light ) {
            style = new ShapeStyle( 0.5, undefined, [ 0, 0 ], 1 );
        } else if ( lineStyle === LineStylePreset.Normal ) {
            style = new ShapeStyle( 2, undefined, [ 0, 0 ], 1 );
        } else if ( lineStyle === LineStylePreset.Bold ) {
            style = new ShapeStyle( 4, undefined, [ 0, 0 ], 1 );
        } else if ( lineStyle === LineStylePreset.Dashed ) {
            style = new ShapeStyle( 2, undefined, [ 3, 3 ], 1 );
        } else if ( lineStyle === LineStylePreset.Mixed ) {
            let newLineColor;
            if ( currStyle && currStyle.lineColor ) {
                const lineColor = this.rgbaToHex ( currStyle.lineColor );
                const currColorRef = this.colorLookup[ lineColor ];
                if ( currColorRef && currColorRef.length === 2 ) {
                    const colName = ColorPairs[currColorRef[0]];
                    if ( colName && colName.comp ) {
                        newLineColor = ColorMap[colName.comp][currColorRef[1]];
                    }
                }
            }

            if ( newLineColor ) {
                style = new ShapeStyle( currStyle.lineThickness, newLineColor, currStyle.lineStyle, 1 );
            } else {
                style = new ShapeStyle( currStyle.lineThickness, undefined, currStyle.lineStyle, 1 );
            }

        } else {
            return;
        }
        style.linePreset = lineStyle;
        return style;
    }
    public getFillStylePreset( fillStyle: FillStylePreset, currStyle: ShapeStyle ): ShapeStyle {
        let style;
        try {
            let colorRef;
            let newFillColor;
            let newTextColor;
            let newThemeIndex;
            let newThemeId;
            const white = '#ffffff';
            const transparent = 'rgba(255, 255, 255, 0.01)';
            const text = '#111729';

            if ( !fillStyle ) {
                fillStyle = FillStylePreset.Flat;
            }

            if ( currStyle.themeId && currStyle.themeIndex ) {
                const paletteStyle: any = this.palettes[ currStyle.themeId ].styles[ currStyle.themeIndex - 1 ];
                colorRef = this.colorLookup[ paletteStyle.fillColor ];
                newFillColor = paletteStyle.fillColor;
                if ( fillStyle === FillStylePreset.None ) {
                    newTextColor = paletteStyle.textColor;
                } else {
                    newTextColor = currStyle.textColor;
                }

                newThemeId = paletteStyle.themeId;
                newThemeIndex = paletteStyle.themeIndex;
            } else {
                newFillColor = currStyle.fillColor;
                newTextColor = currStyle.textColor;
                newThemeId = currStyle.themeId;
                newThemeIndex = currStyle.themeIndex;
            }

            if ( newFillColor === 'white' ) {
                newFillColor = '#ffffff';
            }


            if ( fillStyle === FillStylePreset.None ) {
                if ( currStyle.textColor === white || newTextColor === white ) {
                    if ( colorRef && colorRef.length === 2 ) {
                        newTextColor = ColorMap[ colorRef[0] ][ '800' ];
                    } else {
                        newTextColor = '#4b4b4b';
                    }
                }

                style = new ShapeStyle(
                                    currStyle.lineThickness,
                                    currStyle.lineColor,
                                    currStyle.lineStyle,
                                    currStyle.lineAlpha,
                                    transparent,
                                    FillStyle.Solid,
                                    undefined, 1,
                                    text,
                                    currStyle.themeIndex,
                                    currStyle.themeId );

            } else if ( fillStyle === FillStylePreset.White ) {
                style = new ShapeStyle(
                                    currStyle.lineThickness,
                                    currStyle.lineColor,
                                    currStyle.lineStyle,
                                    currStyle.lineAlpha,
                                    white,
                                    FillStyle.Solid,
                                    undefined, 1,
                                    text,
                                    currStyle.themeIndex,
                                    currStyle.themeId  );

            } else if ( fillStyle === FillStylePreset.Vibrant || fillStyle === FillStylePreset.Flat ) {
                    style = new ShapeStyle(
                                    currStyle.lineThickness,
                                    currStyle.lineColor,
                                    currStyle.lineStyle,
                                    currStyle.lineAlpha,
                                    newFillColor ,
                                    FillStyle.Solid,
                                    undefined, 1,
                                    newTextColor,
                                    currStyle.themeIndex,
                                    currStyle.themeId  );
            } else if ( fillStyle === FillStylePreset.Glass ) {
                style = new ShapeStyle(
                                    currStyle.lineThickness,
                                    this.hexToRGBA( currStyle.lineColor, 0.8 ),
                                    currStyle.lineStyle,
                                    currStyle.lineAlpha,
                                    newFillColor,
                                    FillStyle.LinearGradient,
                                    { colors:
                                        [ this.hexToRGBA( newFillColor, 0.7 ),
                                            this.hexToRGBA( newFillColor, 0.9 ),
                                        ],
                                        ratios: [ 0, 1 ], x0: 0.5, y0: 0, x1: 0.5, y1: 0.9 },
                                    1, newTextColor, newThemeIndex, newThemeId,
                                );

            } else if ( fillStyle === FillStylePreset.Gradient ) {
                style = new ShapeStyle(
                                    currStyle.lineThickness,
                                    this.hexToRGBA( currStyle.lineColor, 0.8 ),
                                    currStyle.lineStyle,
                                    currStyle.lineAlpha,
                                    newFillColor,
                                    FillStyle.LinearGradient,
                                    { colors:
                                        [ this.hexToRGBA( newFillColor, 0.4 ),
                                            this.hexToRGBA( newFillColor, 0.9 ),
                                        ],
                                        ratios: [ 0, 1 ], x0: 0.5, y0: 0, x1: 0.5, y1: 0.9 },
                                    1, newTextColor, newThemeIndex, newThemeId,
                                );
            }
            style.fillPreset = fillStyle;

        } catch ( error ) {
            Logger.error( 'Error creating shape style', error );
        }
        return style;
    }

    /**
     * Return a palette with the matching id.
     */
    public getPalatte( id ): Palette {
        return this._palettes.find(( palette: Palette ) => palette.paletteId === id );
    }

    /**
     * Return a palette with the matching id.
     */
    public getPalatteByName( name ): Palette {
        return this._palettes.find(( palette: Palette ) => palette.name === name );
    }

    /**
     * Return a shape style with the matching id.
     */
    public getStyle( id ): LineStyle | ShapeStyle {
        const mergedPalettes: Array<any> =
            this._palettes.concat( this._linePalettes.getValue());

        for ( const palette of mergedPalettes ) {
            const match = palette.styles.find(( style: LineStyle ) => style.styleId === id );
            if ( match ) {
                return match;
            }
        }
        return;
    }


    /**
     * Returns the unique shape styles of the given shapes using shape id
     * @param shapes - Array of shape models or connector models
     */
    protected getDiagramDistinctStyles( shapes: ( ShapeModel | ConnectorModel )[]) {
        const shapeStyles = [];
        const shapeStylesIds = [];
        shapes.forEach( shape => {
            if ( shape.style ) {
                const textModel = shape.previewTextModel;
                if ( textModel && textModel.content && textModel.content[0]) {
                    shape.style.textColor = textModel.content[0].color;
                }
                if ( !shapeStylesIds.includes( shape.style.styleId )) {
                    shapeStylesIds.push( shape.style.styleId );
                    shapeStyles.push( shape.style );
                }
            }
        });
        return shapeStyles;
    }

    /**
     * Emits the diagram model immediately and when there is a style related change.
     */
    protected getDocumentChanges(): Observable<any> {
        return this.ll.forCurrentObserver( false ).pipe(
                switchMap( locator =>
                    merge(
                        locator.getDiagramOnce(),
                        locator.getDiagramChanges().pipe(
                            filter( change => this.hasStyleChanges( change )),
                            map( change => change.model ),
                        ),
                    ),
                ),
            );
    }

    /**
     * Checks whether the emitted change can change the set of styles used on the diagram.
     */
    protected hasStyleChanges( change: IEmittedDiagramChange<DiagramModel, ShapeModel | ConnectorModel> ): boolean {
        if ( change.added && change.added.length ) {
            return true;
        }
        if ( change.split.shapes && Object.keys( change.split.shapes ).length ) {
            for ( const shapeId in change.split.shapes ) {
                if ( !change.split.shapes.hasOwnProperty( shapeId )) {
                    continue;
                }
                // FIXME: IEmittedModelChange should have a `removed` property with removed shapes
                if ( change.modifier.$unset && change.modifier.$unset[`shapes.${shapeId}`]) {
                    return true;
                }
                const shapeMod = change.split.shapes[shapeId];
                if ( ModifierUtils.hasChanges( shapeMod, 'style' )) {
                    return true;
                }
                if ( ModifierUtils.hasChanges( shapeMod, 'texts' )) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Get distinct styles only related to basic, image and connector shape types and
     * update palettes.
     * SVG and text shapes will be filtered out
     * @returns - an observable which emits the styles
     */
    // protected getDocumentShapeStyles() {
    //     return this.getDocumentChanges().pipe(
    //         tap ( diagram => {
    //             let shapes = diagram.getShapes([ ShapeType.Basic ]);
    //             shapes = shapes.filter( shape => shape.isStyleEnabledShape());
    //             const styles = this.getDiagramDistinctStyles( shapes );
    //             this._documentPalette.styles = styles;
    //             this._palettes.next( this._palettes.getValue());

    //             const connectors = diagram.getShapes([ ShapeType.Connector ]);
    //             this._documentLinePalettes.styles = this.getDiagramDistinctStyles( connectors );
    //             this._linePalettes.next( this._linePalettes.getValue());
    //         }),
    //     );
    // }


    private initPalete( pal: Palette, isLine: boolean = false ) {
        this.palettes [ pal.name ] = pal;
        this._palettes.push( pal );
        if ( isLine ) {
            this._linePalettes.value.push( pal );
        }
    }


    /**
     * Initializes the `colorLookup` object by iterating through the `ColorMap` object.
     * The `colorLookup` object has color values as keys and an array containing the color name
     * and shade as the corresponding value.
     */
     private initColorLookup(): void {
        if ( this.colorLookup ) {
            return;
        }

        this.colorLookup = {};

        for ( const color in ColorMap ) {
            const colorSet = ColorMap[color];

            for ( const shade in colorSet ) {
                this.colorLookup[colorSet[shade]] = [ color, shade ];
            }
        }
    }

    /**
     * Converts hex to rgba
     * @param hex
     * @param alpha
     * @returns
     */
     private hexToRGBA( color: string, alpha: number = 1 ): string {
        const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        const rgbaRegex = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:,\s*[\d.]+)?\s*\)$/;

        const rgbaMatch = color.match( rgbaRegex );

        if ( rgbaMatch ) {
            const r = Number( rgbaMatch[1]);
            const g = Number( rgbaMatch[2]);
            const b = Number( rgbaMatch[3]);
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }

        if ( !hexRegex.test( color )) {
            throw new Error( 'Invalid color format. Expected hex or rgba format.' );
        }

        const shorthand = color.length === 4;
        const r1 = parseInt( shorthand ? color[1] + color[1] : color.slice( 1, 3 ), 16 );
        const g1 = parseInt( shorthand ? color[2] + color[2] : color.slice( 3, 5 ), 16 );
        const b1 = parseInt( shorthand ? color[3] + color[3] : color.slice( 5, 7 ), 16 );

        return `rgba(${r1}, ${g1}, ${b1}, ${alpha})`;
    }


    private rgbaToHex( rgba: string ): string {
        const rgbaRegex = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+)\s*)?\)$/;
        const match = rgba.match( rgbaRegex );

        if ( !match ) {
          return rgba.toUpperCase();
        }

        const r = parseInt( match[1], 10 );
        const g = parseInt( match[2], 10 );
        const b = parseInt( match[3], 10 );

        const hex = ( r * 256 * 256 ) + ( g * 256 ) + b;
        const hexString = hex.toString( 16 ).padStart( 6, '0' ).toUpperCase();

        return `#${hexString}`;
      }


// tslint:disable-next-line: max-file-line-count
}
