import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { Command, CommandService, Logger, MapOf, Random, StateService } from 'flux-core';
import { DataType, EntityLinkType, SystemType } from 'flux-definition';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { EDataCommandEvent } from '../../../base/edata/command/edata-command-event';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { EntityLinkService } from '../../../base/edata/entity-link.svc';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
// import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';

@Injectable()
@Command()
export class PasteAsItem extends AbstractDiagramChangeCommand {

    public static get dataDefinition(): {}  {
        return {
            pasteAs: true,
        };
    }

    private eDataModels: MapOf<Proxied<EDataModel>> = {};

    constructor( protected state: StateService<any, any>,
                 protected dcs: DiagramChangeService,
                 private commandService: CommandService,
                 // private defLocator: DefinitionLocator,
                 ) {
        super( dcs )/* istanbul ignore next */;
    }

    public prepareData(): Observable<any>  {
        return null;
    }

    public execute(): boolean {
        const originalLinks = this.state.get( 'originalEDataLinks' );
        const shapeIds = Object.keys( originalLinks );
        const currentOption = this.state.get( 'pastedAs' );
        switch ( currentOption ) {
            case 'newObject':
                switch ( this.data.pasteAs ) {
                    case 'linkedObject':
                        shapeIds.map( sId => this.changeModel.shapes[sId]).forEach( shape => {
                            const { eDataId, entityId } = originalLinks[shape.id];
                            this.newObjectToLinkedObject( shape, entityId, this.getEDataModel( eDataId ));
                        });
                        break;
                    case 'blankObject':
                        shapeIds.map( sId => this.changeModel.shapes[sId]).forEach( shape => {
                            const { eDataId } = originalLinks[shape.id];
                            this.newObjectToBlankObject( shape, this.getEDataModel( eDataId ));
                        });
                        break;
                    case 'basicShape':
                        shapeIds.map( sId => this.changeModel.shapes[sId]).forEach( shape => {
                            const { eDataId } = originalLinks[shape.id];
                            this.newObjectToBasicShape( shape, this.getEDataModel( eDataId ));
                        });
                        break;
                }
                break;
            case 'linkedObject':
                break;
            case 'blankObject':
                break;
            case 'basicShape':
                break;
        }
        setTimeout(() => {
            Object.values( this.eDataModels ).forEach( eDataModel => {
                this.commandService.dispatch( EDataCommandEvent.applyModifierEData, eDataModel.id, {
                    modifier: eDataModel.__sakota__.getChanges(),
                });
            });
        }, this.data.pasteAs === 'linkedObject' ? 100 : 0 );
        this.state.set( 'pastedAs', this.data.pasteAs );
        return true;
    }

    // new object to other formats
    protected newObjectToLinkedObject( shape: AbstractShapeModel, originalEntityId: string, eDataModel: EDataModel ) {
        // remove entity
        const entityId = shape.entityId;
        this.cleanLookupFields( eDataModel.entities[entityId]);
        delete eDataModel.entities[entityId];
        // link to existing entity
        eDataModel.entities[originalEntityId].addShape( this.resourceId, shape as ShapeModel );
        // update shape eData
        shape.eData[shape.eDataId] = originalEntityId;
        delete this.changeModel.dataDefs[shape.dataSetId];
        shape.dataSetId = originalEntityId;
        const connectorIds = this.createLinkedConnectors( shape as ShapeModel, eDataModel.entities[originalEntityId]);
        this.updateLinkConnectors( eDataModel, originalEntityId, shape, connectorIds ).subscribe({
            error: e => Logger.error( 'error updating links', e ),
        });
    }

    protected newObjectToBlankObject( shape: AbstractShapeModel, eDataModel: EDataModel ) {
        // below commented code doesn't work for predefined types
        /* if ( this.changeModel.dataDefs[shape.dataSetId]) {
            Object.keys( this.changeModel.dataDefs[shape.dataSetId]).forEach( key => {
                delete shape.data[key];
            });
            delete this.changeModel.dataDefs[shape.dataSetId];
            delete eDataModel.dataDefs[shape.dataSetId];
        } */
        // reset entity data to blueprint values
        const entityDef = EDataRegistry.instance.getEntityDefById( shape.entityDefId, eDataModel.defId );
        this.cleanLookupFields( eDataModel.entities[shape.entityId]);
        this.resetDataItems( eDataModel.entities[shape.entityId].data, entityDef.dataItems );
        // if (( shape as any ).created ) {
        //     const def = ( this.defLocator as any ).cachedDefs[`${shape.defId}.${shape.version}`] || shape;
        //     ( shape as any ).created( this.changeModel.shapes[shape.id], def, this.changeModel );
        //     eDataModel.entities[shape.entityId].addShape( this.resourceId, shape as ShapeModel, true );
        // }
    }

    protected newObjectToBasicShape( shape: AbstractShapeModel, eDataModel: EDataModel ) {
        // remove entity
        delete eDataModel.entities[shape.entityId];
        delete eDataModel.dataDefs[shape.entityId];
        // remove eData from shape
        this.removeEDataProps( shape );
    }

    // linked object to other formats
    // blank object to other formats
    // basic to other formats


    private removeEDataProps( shape ) {
        delete shape.eData;
        const entityId = shape.dataSetId;
        const entityDefId = shape.entityDefId;
        this.createNewDataSet( shape );
        delete shape.entityDefId;
        shape.triggerNewEData = false;
        if ( !Object.values( this.changeModel.shapes ).some( s => s.dataSetId === entityId )) {
            delete this.changeModel.dataDefs[entityId];
        }
        if ( !Object.values( this.changeModel.shapes ).some( s => s.entityDefId === entityDefId )) {
            delete this.changeModel.dataDefs[entityDefId];
        }
    }

    /**
     * Function to clone data sets in the diagram the shape is copied to
     * @param shape
     * @param dataDef to clone - if it doesn't exist on the diagram already, it is cloned
     * from the pasted data
     */
    private createNewDataSet( shape, dataDef? ) {
        let currDataDef = dataDef || {};
        if ( !dataDef ) {
            if ( shape.entityDefId ) {
                currDataDef = { ...this.changeModel.dataDefs[ shape.entityDefId ] };
            }
            if ( shape.dataSetId && this.changeModel.dataDefs[ shape.dataSetId ]) {
                currDataDef = { ...currDataDef, ...this.changeModel.dataDefs[ shape.dataSetId ] };
            }
        }
        const newDef = cloneDeep( currDataDef );
        const newId = Random.dataItemId();
        this.changeModel.dataDefs[ newId ] = newDef;
        shape.dataSetId = newId;
    }

    private getEDataModel( eDataId: string ) {
        if ( !this.eDataModels[eDataId]) {
            this.eDataModels[eDataId] = Sakota.create( this.state.get( 'projectEDataModels' )[eDataId]);
        }
        return this.eDataModels[eDataId];
    }

    private resetDataItems( entityData, dataItems ) {
        for ( const dId in dataItems ) {
            const dataItem = dataItems[dId];
            if ( dataItem.type === DataType.LOOKUP ) {
                entityData[dId] = dataItem.default || [];
            } else if ( dataItem.type === DataType.TAGS ) {
                entityData[dId] = dataItem.default || [];
            } else if ( dataItem.systemType === SystemType.Role ) {
                entityData[dId] = dataItem.default || {
                    source: {
                        id: 'collabs',
                        name: 'Role',
                    },
                    people: [],
                };
            // } else if ( dataItem.roleBound ) {
            //     entityData[dId] = dataItem.default || '';
            } else {
                entityData[dId] = dataItem.default || '';
            }
        }
    }

    private createLinkedConnectors( shape: ShapeModel, entity ) {
        return EntityLinkService.createLinkedConnectors(
            this.changeModel,
            shape,
            entity,
            this.state.get( 'projectEDataModels' ),
            shape.zIndex,
            {
                defaultBounds: shape.bounds,
            },
        );
    }

    private updateLinkConnectors( eDataModel: EDataModel, entityId: string, shape, connectorIds ) {
        return EntityLinkService.updateLinkConnectors(
            eDataModel,
            this.changeModel,
            eDataId => of( this.getEDataModel( eDataId )),
            entityId,
            {
                ...shape,
                connectorIds,
            },
        );
    }

    // same code from esync to handle entity removal
    private cleanLookupFields(
        removedEntity: EntityModel ) {
        const links = removedEntity.links ? Object.values( removedEntity.links ) : [];
        const lLinks = links.filter( l => l.type === EntityLinkType.LOOKUP );
        lLinks.forEach(({ eDataId, entityId, handshake: lookupId }) => {
            const eDataModel = this.getEDataModel( eDataId );
            if ( eDataModel && eDataModel.entities[entityId]) {
                const entity = eDataModel.entities[entityId];
                if ( entity.eDefId === removedEntity.eDefId ) {
                    const reversedId = lookupId.split( '' ).reverse().join( '' );
                    if ( removedEntity.data[ reversedId ]) { // same type mirror field
                        lookupId = reversedId;
                    }
                }
                if ( entity.data[lookupId]) {
                    const value = entity.data[lookupId].slice();
                    value.splice( value.indexOf( removedEntity.id ), 1 );
                    entity.data[lookupId] = value;
                }
                entity.getMatchingLinks( EntityLinkType.LOOKUP, lookupId )
                    .filter( l => l.entityId === removedEntity.id )
                    .forEach( l => {
                    delete entity.links[l.id];
                });
            }
        });
    }

}

Object.defineProperty( PasteAsItem, 'name', {
    value: 'PasteAsItem',
});
