import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

/**
 * This command is used to show or hide connector line bumps
 * in/from the diagram. If the 'showLineBumps' is true, this command will
 * show connector bumps to all the connectors in the diagram if and only
 * none of the connectors are selected.
 *
 * This command update the property 'showBumps' of the connector model according
 * to show/hide.
 *
 * @author  Vinoch
 * @since   2019-06-11
 */
@Injectable()
@Command()
export class ToggleConnectorLineBumps extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        showLineBumps: boolean,
        selection?: boolean;
    };

    constructor( protected ds: DiagramChangeService,
                 protected stateService: StateService<any, any> ) {
        super( ds );
    }

    /**
     * Prepare command data
     * Update the connector and diagram line bump properties.
     * If the selection is true, connector bumps will apply
     * to the selected connectors only.
     */
    public prepareData() {
        if ( this.data.selection ) {
            const shapeIds = this.stateService.get( 'Selected' );
            for ( const shapeId of shapeIds ) {
                if ( this.changeModel.shapes[shapeId].isConnector()) {
                    const connector = this.changeModel.shapes[shapeId] as ConnectorModel;
                    connector.showBumps = this.data.showLineBumps;
                }
            }
        } else {
            // NOTE: This will set showBumps to all the connectors in the diagram.
            // This is required if we are toggling all connectors bump in a signle click.
            // At the moment this is not used.
            const connectors = this.changeModel.getConnectors();
            connectors.forEach( connectorId => {
                const connector = this.changeModel.shapes[connectorId] as ConnectorModel;
                connector.showBumps = this.data.showLineBumps;
            });
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ToggleConnectorLineBumps, 'name', {
    value: 'ToggleConnectorLineBumps',
});
