import { IModifier } from 'flux-core';

/**
 * ISplitModifier
 * Modifier broken into diagram changes and changes for individual shapes.
 * This helps locators identify which shapes have changed and how.
 */
export interface ISplitModifier {
    diagram?: IModifier;
    shapes?: { [shapeId: string]: IModifier };
}

/**
 * splitModifier
 * Separates changes in the modifier into changes to diagram and for individual shapes.
 * The main focus is to identify changes to the diagram itself and the shapes separately
 * so that they can be applied. This function has to be correct for all cases and it should
 * also perform well.
 *
 * Example:
 *
 *      The following modifier has changes to the diagram and also for a shape.
 *
 *      {
 *          $set: {
 *              'name': 'New Diagram',
 *              'shapes.shape1.x': 100,
 *              'shapes.shape1.y': 120,
 *          }
 *      }
 *
 *      The output will have this information separated.
 *
 *      {
 *          diagram: { $set: { 'name': 'New Diagram' }}
 *          shapes: {
 *              shape1: { $set: { x: 100, y: 120 }}
 *          }
 *      }
 *
 */
export const splitModifier = ( modifier: IModifier ): ISplitModifier => {
    const split: ISplitModifier = {};
    for ( const op in modifier ) {
        const opmod = modifier[op];
        for ( const key in opmod ) {
            const value = opmod[key];
            if ( key.indexOf( 'shapes.' ) === 0 ) {
                // NOTE: 7 is the position of the first '.' in the key "shapes.*"
                const secondDot = key.indexOf( '.', 7 );
                if ( secondDot === -1 ) {
                    const shapeId = key.slice( 7 );
                    if ( !split.shapes ) {
                        split.shapes = { [shapeId]: { [op]: value }};
                    } else if ( !split.shapes[shapeId]) {
                        split.shapes[shapeId] = { [op]: value };
                    } else if ( !split.shapes[shapeId][op]) {
                        split.shapes[shapeId][op] = value;
                    } else {
                        for ( const prop in value ) {
                            split.shapes[shapeId][op][prop] = value[prop];
                        }
                    }
                } else {
                    const shapeId = key.slice( 7, secondDot );
                    const shapeKey = key.slice( secondDot + 1 );
                    if ( !split.shapes ) {
                        split.shapes = { [shapeId]: { [op]: { [shapeKey]: value }}};
                    } else if ( !split.shapes[shapeId]) {
                        split.shapes[shapeId] = { [op]: { [shapeKey]: value }};
                    } else if ( !split.shapes[shapeId][op]) {
                        split.shapes[shapeId][op] = { [shapeKey]: value };
                    } else {
                        split.shapes[shapeId][op][shapeKey] = value;
                    }
                }
            } else if ( key === 'shapes' ) {
                for ( const shapeId in value ) {
                    if ( !split.shapes ) {
                        split.shapes = { [shapeId]: { [op]: value[shapeId] }};
                    } else if ( !split.shapes[shapeId]) {
                        split.shapes[shapeId] = { [op]: value[shapeId] };
                    } else {
                        split.shapes[shapeId][op] = value[shapeId];
                    }
                }
            } else {
                if ( !split.diagram ) {
                    split.diagram = { [op]: { [key]: value }};
                } else if ( !split.diagram[op]) {
                    split.diagram[op] = { [key]: value };
                } else {
                    split.diagram[op][key] = value;
                }
            }
        }
    }
    return split;
};

/**
 * This is a temporary solution to the issue that data stored on our DB does not
 * maintain correct granularity. Changes to nested properties appear as if they
 * were done on top level properties.
 */
// tslint:disable-next-line: cyclomatic-complexity
export const correctShapeModifier = ( modifier: IModifier ): IModifier => {
    const corrected: IModifier = {};
    for ( const op in modifier ) {
        corrected[op] = {};
        const opmod = modifier[op];
        for ( const key in opmod ) {
            const value = opmod[key];
            if ( key === 'texts' ) {
                for ( const textId in value ) {
                    const text = value[textId];
                    for ( const propKey in text ) {
                        corrected[op][`texts.${textId}.${propKey}`] = text[propKey];
                    }
                }
            } else if ( key.indexOf( 'texts.' ) === 0 ) {
                // NOTE: 6 is the position of the first '.' in the key "texts.*"
                const secondDot = key.indexOf( '.', 6 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'data' ) {
                for ( const dataName in value ) {
                    const dataItem = value[dataName];
                    for ( const propKey in dataItem ) {
                        corrected[op][`data.${dataName}.${propKey}`] = dataItem[propKey];
                    }
                }
            } else if ( key.indexOf( 'data.' ) === 0 ) {
                // NOTE: 5 is the position of the first '.' in the key "data.*"
                const secondDot = key.indexOf( '.', 5 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'images' ) {
                for ( const imageId in value ) {
                    const image = value[imageId];
                    for ( const propKey in image ) {
                        if ( propKey === 'mask' ) {
                            for ( const maskPropKey in image[propKey]) {
                                // tslint:disable-next-line: max-line-length
                                corrected[op][`images.${imageId}.${propKey}.${maskPropKey}`] = image[propKey][maskPropKey];
                            }
                        } else {
                            corrected[op][`images.${imageId}.${propKey}`] = image[propKey];
                        }
                    }
                }
            } else if ( key.indexOf( 'images.' ) === 0 ) {
                // NOTE: 7 is the position of the first '.' in the key "images.*"
                const secondDot = key.indexOf( '.', 7 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        if ( propKey === 'mask' ) {
                            for ( const maskPropKey in value[propKey]) {
                                corrected[op][key + '.' + propKey + '.' + maskPropKey] = value[propKey][maskPropKey];
                            }
                        } else {
                            corrected[op][key + '.' + propKey] = value[propKey];
                        }
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'gluepoints' ) {
                for ( const gluepointId in value ) {
                    const gluepoint = value[gluepointId];
                    for ( const propKey in gluepoint ) {
                        corrected[op][`gluepoints.${gluepointId}.${propKey}`] = gluepoint[propKey];
                    }
                }
            } else if ( key.indexOf( 'gluepoints.' ) === 0 ) {
                // NOTE: 11 is the position of the first '.' in the key "gluepoints.*"
                const secondDot = key.indexOf( '.', 11 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'ends' ) {
                for ( const side in value ) {
                    corrected[op][`ends.${side}`] = value[side];
                }
            } else if ( key === 'styleDefinitions' ) {
                for ( const styleDefId in value ) {
                    const styleDef = value[styleDefId];
                    for ( const propKey in styleDef ) {
                        corrected[op][`styleDefinitions.${styleDefId}.${propKey}`] = styleDef[propKey];
                    }
                }
            } else if ( key.indexOf( 'styleDefinitions.' ) === 0 ) {
                // NOTE: 17 is the position of the first '.' in the key "styleDefinitions.*"
                const secondDot = key.indexOf( '.', 17 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'style' ) {
                for ( const propKey in value ) {
                    corrected[op][`style.${propKey}`] = value[propKey];
                }
            } else if ( key === 'vectors' ) {
                for ( const vectorId in value ) {
                    const vector = value[vectorId];
                    for ( const propKey in vector ) {
                        corrected[op][`vectors.${vectorId}.${propKey}`] = vector[propKey];
                    }
                }
            } else if ( key.indexOf( 'vectors.' ) === 0 ) {
                // NOTE: 8 is the position of the first '.' in the key "vectors.*"
                const secondDot = key.indexOf( '.', 8 );
                if ( secondDot === -1 ) {
                    for ( const propKey in value ) {
                        corrected[op][key + '.' + propKey] = value[propKey];
                    }
                } else {
                    corrected[op][key] = value;
                }
            } else if ( key === 'defaultBounds' ) {
                // DefaultBounds is deserialized by the factory. Avoid overriding it.
                for ( const prop in value ) {
                    const propVal = value[prop];
                    corrected[op][`defaultBounds.${prop}`] = propVal;
                }
            } else if ( key === 'entityDefId' && value === 'creately.entity.datasource.github.issue' ) {
                corrected[op][key] = 'createlyEntityDatasourceGithubIssue';
            } else {
                corrected[op][key] = value;
            }
        }
    }
    return corrected;
};
