<div class="editable-label-container fx-ellipsis" [class.editable]="editable" title="{{tooltip}}">
    <div class="editable-label fx-whitespace-nowrap body"
    #editableInput
    contenteditable="true"
    *ngIf="!readonly"
    [ngClass]="{ 'multiline': multiline }"
    (keydown.enter)="emitChangeOnEnter($event)"
    (keydown.esc)="closeWithoutSave($event)"
    (blur)="emitChange(text)"
    (focus)="editable = true; currentVal = text;"
    (paste)="onPaste($event)"
    spellcheck="false">{{value}}</div>

    <div class="editable-label body readonly" *ngIf="readonly">{{value}}</div>
</div>
