import { Injectable } from '@angular/core';
import {
    Command, CommandInterfaces, ImageLoader, NotifierController,
    PostMessageAPI, PostMessageSendEventType, StateService,
} from 'flux-core';
import { ExportAsSvg } from './export-as-svg.cmd';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { PlanPermManager } from 'flux-user';
import { PlanPermission } from 'flux-definition';
/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsSvgAPI extends ExportAsSvg {

    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor(
        ll: DiagramLocatorLocator,
        resources: ImageLoader,
        notifierController: NotifierController,
        translate: TranslateService,
        state: StateService<any, any>,
        protected planPermManager: PlanPermManager,
        protected postMessage: PostMessageAPI,
    ) {
        super( ll, resources, notifierController, translate, state );
    }


    public execute(): Observable<any> {
        if ( !this.planPermManager.check([ PlanPermission.DIAGRAM_EXPORT_AS_SVG ])) {
            this.postMessage.sendToParent( PostMessageSendEventType.commandExecute,
                {
                    commandName: 'exportSVG',
                    documentId: this.resourceId,
                    error: {
                        message: 'Permission is denied',
                        code: 403,
                    },
                },
            );
            throw new Error( 'Permission is denied' );
        }

        const dataUrl = this.data.content;
        this.postMessage.sendToParent( PostMessageSendEventType.commandExecute,
            {
                commandName: 'exportSVG',
                documentId: this.resourceId,
                result: {
                    dataUrl,
                    name: this.data.name,
                    type: this.data.type,
                },
            },
        );

        return of( true );
    }

}

Object.defineProperty( ExportAsSvgAPI, 'name', {
    value: 'ExportAsSvgAPI',
});
