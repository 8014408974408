import { Observable, merge, timer } from 'rxjs';
import { IResponsibility, StateService, Logger, InitializationChainStatus } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { ConnectionStatus } from './connection-status';
import { filter, map, take } from 'rxjs/operators';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the status of network connection.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-12-10
 */
@Injectable()
export class NetworkResponsibility implements IResponsibility  {

    public name: string = 'NetworkResponsibility';

    /**
     * The amount of time (in milliseconds ) this responsibility should
     * wait to determine a connection status if a connection initialization
     * was already in progress.
     */
    protected waitTime: number = 3000;

    constructor ( protected logger: Logger,
                  @Inject( StateService ) protected state: StateService<any, any> ) {}

    /**
     * Determines network state.
     * @param   current status of the sequence
     * @return  Observable which emits the connection status
     */
    public checkState( status: InitializationChainStatus ): Observable<ConnectionStatus> {
        this.logger.debug( 'NetworkResponsibility checkState' );
        // FIXME: If the connection is offline, allow it several seconds to become online
        // ( this is to handle when a reconnection is in progress when state is checked)
        // If the connection status does not change during this time, get the value
        // of the current status.
        // Delay needs to be removed after the connection initialization cases
        // are properly handled
        if ( this.state.is( ConnectionStatus, ConnectionStatus.OFFLINE )) {
            return merge(
                this.state.changes( ConnectionStatus ).pipe(
                    filter( sts => sts === ConnectionStatus.ONLINE ),
                ),
                timer( this.waitTime ).pipe( map(() => this.state.get( ConnectionStatus ))),
            ).pipe( take( 1 ));
        } else {
            return this.state.changes( ConnectionStatus ).pipe( take( 1 ));
        }
    }

    /**
     * Determines the next resposibility in the sequence.
     * In all cases where this responsibility does not yield a result, the sequence will
     * always be forwareded to the user subscription responsibility.
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        const s = this.getStates( status );
        if ( s.isNetworkRequired && s.isOffline ) {
            return [];
        }
        return [ 'AuthResponsibility' ];
    }

    /**
     * Returns a result if a result can be determined.
     * This responsibility can yield a result for the following scenarios:
     *  edit-> no user -> app offline -> *route-to-error-page
     *  edit-> not authenticated -> no user -> app online -> *authenticate
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state.
     *           undefined if no result
     */
    public result( status: InitializationChainStatus ): any {
        const s = this.getStates( status );
        if ( s.isNetworkRequired && s.isOffline ) {
            return { type: 'route', action: 'offline' };
        }
        return undefined;
    }

    /**
     * Derives the required values from responsibility states and
     * returns a map with them for easy access.
     * @param status chain status
     */
    protected getStates( status: InitializationChainStatus ) {
        return {
            isNetworkRequired: !!status.states.networkRequired,
            isOffline: status.states.NetworkResponsibility === ConnectionStatus.OFFLINE,
        };
    }
}
