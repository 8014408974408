import { Injectable, Injector } from '@angular/core';
import { Curve, Line } from 'flux-core';
import { IConnectorPoint } from 'flux-diagram-composer';
import { cloneDeep, flatten } from 'lodash';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { PatherAngled } from './pather-angled';
import { PatherCurved } from './pather-curved';
import { PatherStraight } from './pather-straight';
import { AbstractConnectorPather } from './pather.i';
import { ShapeBoundsLocator } from '../containers/shape-bounds-locator';

/**
 * Pathing ensures that connector points are laid out properly considering
 * the connector type, connector draw style and connected shapes. This can
 * be called by a number of commands in different ways.
 */
@Injectable()
export class PathingService {
    /**
     * Pathing service uses the diagram locator and therefore has full knowledge
     * about all the shapes in the diagram. This will be used later to get information
     * about other shapes in the canvas so they can be avoided when creating the path.
     */
    constructor(
        protected injector: Injector,
        protected sbl: ShapeBoundsLocator,
    ) {}

    /**
     * Using the given connector id, it will decide a more appropriate path for the connector.
     * It will check the `entryClass` field and choose an appropriate ConnectorPather which
     * will do most of the path calculation.
     * shouldIgnoreManuallyAdjustedPaths indicates whether the user
     * manually adjusted paths needs to be considered or not.
     */
    public repath(
        connectorId: string,
        diagramModel: DiagramModel,
        modifiedPoints: IConnectorPoint[],
        shouldIgnoreManuallyAdjustedPaths: boolean,
    ): IConnectorPoint[] {
        const connector = diagramModel.shapes[connectorId] as ConnectorModel;
        const pather = this.getPather( connector );
        return pather.repath( connector, diagramModel, modifiedPoints, shouldIgnoreManuallyAdjustedPaths );
    }

    /**
     * Update bumps on given connector by checking connectors below this connector.
     */
    public setBumps(
        connectorId: string,
        diagramModel: DiagramModel,
        _pathPoints?: IConnectorPoint[],
        ignoredConnectors?: string[],
    ): IConnectorPoint[] {
        const connector = diagramModel.shapes[ connectorId ] as ConnectorModel;
        const pathPoints: IConnectorPoint[] = _pathPoints || cloneDeep( connector.getPoints());
        const pather = this.getPather( connector );
        const segments = this.getIntersectingSegments( connector, diagramModel, ignoredConnectors );
        /**
         * FIXME: make pather.getBumps call asynchronous
         * This can be useful later on if we want to move
         * these parts of code to a web worker.
         */
        const bumpPoints = pather.getBumps( pathPoints, segments );
        for ( let i = 0; i < bumpPoints.length; ++i ) {
            const bumps = bumpPoints[i];
            pathPoints[ i ].bumps = bumps;
        }
        return pathPoints;
    }

    /**
     * Using the given connector id, it will reverse the path and make other required changes.
     * It will check the `entryClass` field and choose an appropriate ConnectorPather which
     * will do most of the path calculation.
     */
    public reverse(
        connectorId: string,
        diagramModel: DiagramModel,
    ): IConnectorPoint[] {
        const connector = diagramModel.shapes[connectorId] as ConnectorModel;
        const pather = this.getPather( connector );
        return pather.reverse( connector );
    }

    /**
     * Returns the correct pather for the given connector model
     */
    protected getPather( connector: ConnectorModel ): AbstractConnectorPather {
        const entryClass = connector.entryClassName;
        if ( entryClass === 'ConnectorStraight' ||
            entryClass === 'ConnectorDouble' ||
            entryClass === 'ConnectorTriple' ||
            entryClass === 'ConnectorDivided' ) {
            return this.injector.get( PatherStraight );
        }
        if ( entryClass === 'ConnectorCurved' ) {
            return this.injector.get( PatherCurved );
        }
        if ( entryClass === 'ConnectorAngled' ||
            entryClass === 'ConnectorSmoothAngled' ||
            entryClass === 'ConnectorWavy' ||
            entryClass === 'ConnectorDoubleWavy' ||
            entryClass === 'ConnectorIndented' ||
            entryClass === 'ConnectorAngledDivided' ||
            entryClass === 'ConnectorPattern' ||
            entryClass === 'ConnectorWavySpiky' ) {
            return this.injector.get( PatherAngled );
        }
        return null;
    }

    /**
     * Returns an array of line/curve segments which may intersect with given connector path.
     */
    private getIntersectingSegments(
        connector: ConnectorModel,
        diagram: DiagramModel,
        ignoredConnectors?: string[],
    ): ( Line | Curve )[] {
        const b = connector.bounds;
        const foundShapes = this.sbl.searchShapes( b.left, b.right, b.top, b.bottom );
        const segments: ( Line | Curve )[] = [];
        foundShapes.forEach( node => {
            if ( node.type === 'connector' ) {
                const conn = diagram.shapes[ node.id ] as ConnectorModel;
                segments.push( ...this.getConnectorSegments( conn ));
            }
        });
        return segments;
    }

    /**
     * Returns an array of connector models which can intersect with given connector model.
     */
    // private getIntersecting(
    //     connector: ConnectorModel,
    //     diagram: DiagramModel,
    //     ignoredConnectors?: string[],
    // ): ConnectorModel[] {
    //     const intersecting: ConnectorModel[] = [];
    //     // tslint:disable-next-line:forin
    //     for ( const shapeId in diagram.shapes ) {
    //         const con = diagram.shapes[shapeId] as ConnectorModel;
    //         if (
    //             con.type === ShapeType.Connector &&
    //             con.zIndex < connector.zIndex &&
    //             ( !ignoredConnectors || ignoredConnectors.indexOf( con.id ) === -1 )
    //         ) {
    //             intersecting.push( con );
    //         }
    //     }
    //     return intersecting;
    // }

    /**
     * Returns an array of line/curve segments on given connector's path.
     */
    private getConnectorSegments( connector: ConnectorModel ): ( Line | Curve )[] {
        const pather = this.getPather( connector );
        return flatten( pather.getSegments( connector.getPoints()));
    }
}
