import { Injectable } from '@angular/core';
import { CommandService, Random, StateService } from 'flux-core';
import { IAbstractDefinition, IEDataDef } from 'flux-definition';
import { uniq } from 'lodash';
import { EMPTY, merge, of } from 'rxjs';
import { filter, last, map, mapTo, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { ModelSubscriptionManager, SubscriptionStatus } from '../../../../../libs/flux-subscription/src';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { EDataRegistry } from '../../base/edata/edata-registry.svc';
import { EDataSub } from '../../base/edata/edata.sub';
import { EDataLocatorLocator } from '../../base/edata/locator/edata-locator-locator';
import { EDataModel } from '../../base/edata/model/edata.mdl';
import { DefinitionLocator } from '../../base/shape/definition/definition-locator.svc';
import { ProjectDiagramCommandEvent } from '../../creator/project/command/project-diagram-command.event';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { EDataManage } from './edata-manage.svc';

/**
 * This service converts a diagram shape to an e database object.
 */
@Injectable()
export class ObjectConvertor {
    constructor(
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        private defLocator: DefinitionLocator,
        private eDataManageSvc: EDataManage,
        private stateSvc: StateService<any, any>,
        private commandService: CommandService,
        private modelSubManager: ModelSubscriptionManager,
    ) {}

    /**
     * This function converts a diagram shape to an eData entity.
     * @param shapeId shape id
     * @param eDataDefId optional eData def if
     * @param dbName optional database name
     * @returns Observable
     */
    public convertToEData( shapeId: string, eDataDefId?: string, dbName?: string ) {
        const diagramId = this.stateSvc.get( 'CurrentDiagram' );
        return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            map( d => d.shapes[ shapeId ]),
            switchMap( shape => {
                if ( shape.eData ) { // already linked to an edata object
                    return EMPTY;
                }
                if ( !shape.eDataCandidates ) {
                    return EMPTY;
                }
                const obs = this.getEDataModel( shapeId, eDataDefId ).pipe(
                    switchMap(( eModel: EDataModel ) => {
                        const entityDefId = EDataRegistry.instance.findEntityDefId( shape.defId, eModel.defId );
                        return this.eDataManageSvc.bindNewEntityToShape( eModel.id, shapeId, entityDefId );
                    }),
                );
                if ( eDataDefId ) {
                    return this.createEDataModel({
                        defId: eDataDefId,
                        name: dbName,
                    } as any ).pipe( switchMapTo( obs ));
                }
                return obs;
            }),
        );
    }

    public convertCandidateShapes( eDataModel: EDataModel ) {
        const diagramId = this.stateSvc.get( 'CurrentDiagram' );
        return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            switchMap( diagram => {
                const shapes = Object.values( diagram.shapes )
                    .filter( s => !s.eData && s.eDataCandidates && s.eDataCandidates.includes( eDataModel.defId ));
                if ( shapes.length === 0 ) {
                    return EMPTY;
                }
                const defIds = uniq( shapes.map( s => s.defId ));
                return merge( ...defIds.map( defId => {
                    const entityDefId = EDataRegistry.instance.findEntityDefId( defId, eDataModel.defId );
                    return this.convertShapesToObjects( defId, eDataModel.id, entityDefId );
                }));
            }),
        );
    }

    public convertShapesToObjects( shapeDefId: string, eDataId: string, entityDefId ) {
        const diagramId = this.stateSvc.get( 'CurrentDiagram' );
        return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            switchMap( diagram => {
                const shapes = Object.values( diagram.shapes )
                    .filter( s => s.defId === shapeDefId && !s.eData );
                if ( shapes.length === 0 ) {
                    return EMPTY;
                }
                return this.ell.getEDataModel( eDataId ).pipe(
                    switchMap( eModel => {
                        const entityDef = EDataRegistry.instance.getEntityDefById( entityDefId, eModel.defId );
                        return this.eDataManageSvc.bindNewEntitiesToShapes( eModel, shapes, entityDef, diagram );
                    }),
                );
            }),
        );
    }

    /**
     * @param shapeDefId shape def id
     * @param def edata def
     * @returns Observable
     */
    public createDatabase( def: IEDataDef ) {
        return this.createEDataModel( def );
    }

    public linkDatabase( eDataId: string ) {
        return this._linkDatabase( eDataId );
    }

    protected getEDataModel( shapeId: string, eDataDefId?: string ) {
        const diagramId = this.stateSvc.get( 'CurrentDiagram' );
        return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            map( d => d.shapes[ shapeId ]),
            switchMap( shape => {
                let added = false;
                return this.ell.currentDiagramEDataModels().pipe(
                    switchMap( edataModels => {
                        const defIds = eDataDefId ? [ eDataDefId ] : shape.eDataCandidates;
                        const eDataModel = edataModels.find( e => defIds.includes( e.defId ));
                        if ( eDataModel ) {
                            return of( eDataModel );
                        }
                        return added ? EMPTY : this.defLocator.getDefinition( defIds[0]).pipe(
                            tap( def => {
                                added = true;
                                this.createEDataModel( def ).subscribe();
                            }),
                            mapTo( null ),
                        );
                    }),
                    filter( e => !!e ),
                    take( 1 ),
                );
            }),
        );
    }

    protected createEDataModel( def: IAbstractDefinition ) {
        const pId = this.stateSvc.get( 'CurrentProject' );
        const eData = this.createEData( pId, def );
        return this.commandService.dispatch( ProjectDiagramCommandEvent.createEData, pId, eData ).pipe(
            last(),
            switchMap(() => this._linkDatabase( eData.id )),
            mapTo( eData.id ),
        );
    }

    protected _linkDatabase( eDataId: string ) {
        return this.commandService.dispatch( DiagramCommandEvent.addEDataModel, {
            eDataModelId: eDataId,
        }).pipe(
            last(),
            switchMap(() => this.modelSubManager.start( EDataSub, eDataId )),
            switchMap( sub => sub.status ),
            filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
        );
    }

    private createEData( resourceId, def  ) {
        const data = {} as any;
        data.id = Random.eDataId();
        data.createdTime = Date.now();
        data.dataDefs = {};
        data.defId = def.defId;
        data.rid = resourceId;
        data.name = def.name;
        return data;
    }
}
