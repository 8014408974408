import { empty, Observable } from 'rxjs';
import { ExecutionStep } from '../command/execution/execution-step';
import { StateService } from './state.svc';
import { CommandInterfaces } from '../command/abstract.cmd';
import { CommandError } from '../command/error/command-error';
import { forEach } from 'lodash';

/**
 * SetStatesExecutionStep is a step that sets the state values as per the
 * command settings. Applicable if the command is a StateChangeCommand and
 * will change the states as the command has set the values.
 *
 * @author hiraash
 * @since 2017-09-20
 */
export class SetStatesExecutionStep extends ExecutionStep {

    /**
     * relatedInterfaces property returns 'IHttpCommand' so only commands
     * which have this interface set will run this execution step.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }
    /**
     * StateService instance that is injected and used for
     * setting the state as per the command.
     */
    protected state: StateService<any, any>;

    /**
     * process the set state execution step and sets all provided states to the
     * given values.
     */
    public process(): Observable<any> {
        const states = this.command.states;

        if ( !states || Object.keys( states ).length === 0 ) {
            throw new CommandError(
                'Command was defined as IStageChangeCommand but no states were provided. Define a valid states getter.',
            );
        }

        const shared = this.command.hasInterface( 'IMessageCommand' );

        this.injectServices();

        forEach( states, ( value, stateId ) => {
            this.setStateValue( stateId, value, shared );
        });

        // Set the states to data if it is a IMessageCommand command so it will be sent
        if ( shared ) {
            this.command.data = { states };
        }

        return empty();
    }

    /**
     * injectServices injects services required by this execution step.
     */
    protected injectServices(): void {
        this.state = this.injector.get( StateService );
    }

    /**
     * Sets the given state to the value and removes the state if
     * the value is not defined. Listens if its an observable
     */
    protected setStateValue( stateId: any, value: any, shared: boolean ) {
        if ( value === undefined ||  value === null ) {
            this.log.warning(
                `Removing the state ${stateId} because no value was provided by the command ${this.command.name}`,
            );
            this.state.remove( stateId );
        } else if ( value instanceof Observable ) {
            this.state.listen( stateId, value );
        } else {
            this.state.set( stateId, value, shared );
        }
    }

}

Object.defineProperty( SetStatesExecutionStep, 'name', {
    value: 'SetStatesExecutionStep',
});
