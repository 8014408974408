import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceModelStore } from '../../storage/resource-model.store';

/**
 * This is the command which changes the role of the diagram group shares and send
 * the request to the server.
 *
 * data: {
 *     groupShares { id, role} An array of changed group shares id and role
 * }
 */
@Injectable()
@Command()
export abstract class ChangeResourceGroupShare extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            groupShare: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.getModelStore();
        return modelStore.getGroupShare( this.resourceId ).pipe(
            tap( groupShares => {
                const gsIds = this.data.groupShare.map( gs => gs.id );
                this.previousData.groupShares = groupShares.filter( groupShare =>
                    gsIds.includes( groupShare.id ));
            }),
        );
    }

    /**
     * execute
     * change group share role
     */
    public execute(): Observable<any> {
        const modelStore = this.getModelStore();
        const groupShares = this.previousData.groupShares;
        const updateGroups = [];
        this.data.groupShare.forEach( groupShare  => {
            const gsData = groupShares.find( gShare => gShare.id === groupShare.id );
            updateGroups.push({ ...gsData, role: groupShare.role });
        });
        return modelStore.storageMergeGroupShares( this.resourceId, updateGroups );
    }

    /**
     * executeResult
     * Update the groupShares with the response
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.getModelStore();
        return modelStore
            .storageMergeGroupShares( this.resourceId, response.groupShare );
    }


    public revert(): Observable<any> {
        const modelStore = this.getModelStore();
        return modelStore.storageMergeGroupShares( this.resourceId, this.previousData.groupShares );
    }

    protected abstract getModelStore(): ResourceModelStore;

}
