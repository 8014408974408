import * as clipboard from 'clipboard-polyfill';
import { AppConfig } from 'flux-core';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { StateService } from 'flux-core';
import { AbstractCommand, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { ShapeLinkType } from 'flux-diagram';
import { copyTextViaFallback } from './../../../base/interaction/dom-utils';

/**
 * The command is to copy shape / gropu link to clipboard
 */
@Injectable()
@Command()
export class CopyShapeLink extends AbstractCommand {

    constructor(
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute(): any {
        return this.ll.forCurrent( false ).getDiagramOnce().pipe(
            switchMap( d => {
                let link;
                const dId = this.state.get( 'CurrentDiagram' );
                const ids = this.state.get( 'Selected' );
                if ( ids.length === 1 ) {
                    link = `${AppConfig.get( 'APP_URL' )}/${ShapeLinkType.DIAGRAM}/${dId}/edit/${ShapeLinkType.SHAPE}/${ids[0]}`;
                } else { // Must be a Group ( Multiselection senario is filtered already in the transformer )
                    const id = d.getGroupId( ids[0]);
                    link = `${AppConfig.get( 'APP_URL' )}/${ShapeLinkType.DIAGRAM}/${dId}/edit/${ShapeLinkType.GROUP}/${id}`;
                }
                return from( clipboard.writeText( link )
                    .catch(() => {
                        copyTextViaFallback( link );
                        return Promise.resolve();
                    }));
            }),
        );

    }
}

Object.defineProperty( CopyShapeLink, 'name', {
    value: 'CopyShapeLink',
});
