/**
 * DiagramTypeModel
 * This model contains the basic information about the diagram type.
 *
 * @author  thisun
 * @since   2016-06-17
 */
export class DiagramTypeModel {

    /**
     * Diagram type Id.
     */
    public id: string;

    /**
     * Diagram type name.
     */
    public name: string;

    /**
     * Tags for the diagram type.
     */
    public tags: string;

}

Object.defineProperty( DiagramTypeModel, 'name', {
  writable: true,
  value: 'DiagramTypeModel',
});
