import { TextPostion } from './../../framework/text/text-position';
import {
    TEXT_PADDING_HORIZONTAL,
    IPosition2D,
    PositionType,
    IPoint2D,
    IShapeText,
    TextAlignment,
    ITextStyles,
    IRectangle,
    ITransform,
} from 'flux-definition';
import { TextDataModel } from './text-data.mdl';
import { Position, Point, Rectangle } from 'flux-core';

/**
 * ShapeDataTextModel is the concrete version of a Shape Text and exends the TextModel
 * to inherit the common text functionalities.
 * This model will contain all data
 * processing and manipulation functionality needed for shape text.
 *
 * @author Thisun
 * @since 2018-01-16
 */
export class ShapeTextDataModel extends TextDataModel implements IShapeText {

    /**
     * The maximum unwrap text width.
     * e.g. 50px width shape can have an external-bottom-centered text of
     * 300px
     */
    public static readonly MAX_UNWRAP_WIDTH = 300;

    public slashCmdFeatures = [
        'h1', 'h2', 'h3', 'h4',
        'num-list', 'bulleted-list', 'task-list',
        'block-quote',
    ];

    /**
     * The type of the text
     */
     public type: 'shape' | 'connector' = 'shape';

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public xType: PositionType = 'relative';

    /**
     * The x position of the text.
     * The value that must be set is based on what is set in xType property.
     * For 'relative' this should a ratio value between 0 and 1.
     * For 'fixed-start' this should be a pixel value positioning from the left of the shape.
     * For 'fixed-end' this should be a pixel value positioning from the right of the shape.
     */
    public x: number = 0.5;

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public yType: PositionType = 'relative';

    /**
     * The y position of the text.
     * The value that must be set is based on what is set in yType property.
     * For 'relative' this should a ratio value between 0 and 1.
     * For 'fixed-start' this should be a pixel value positioning from the top of the shape.
     * For 'fixed-end' this should be a pixel value
     * positioning from the bottom of the shape.
     */
    public y: number = 0.5;

    /**
     * A value of 0, -1 or 1 to align the text vertically
     * 0 for center, negative value for top, positive for bottom.
     * Text also can be aligned through text styles.
     * The defualt value is center.
     */
    public alignY: TextAlignment = TextAlignment.center;

    /**
     * This property is to cache the alignX value
     */
    public _alignX: TextAlignment = undefined;

    /**
     * A rotation angle value in degree. By default angle is 0 and text
     * desplayed horizontally. if rotate is not available it means rotate is 0.
     */
    public angle: number = 0;

    /**
     * Boolean property to decide if text should wrap
     * Wraps the text to the size of the shape.
     * False will flow the text to it's length.
     * The default value is true.
     */
    public wordWrap: boolean = true;

    /**
     * The positioin id of the text
     */
    public positionString: string;

    /**
     * This getter returns IPosition2D that describes the position to place the text
     * considering
     * xType , x and yType , y properties
     */
    public get position(): IPosition2D {
        return {
            x: { type: this.xType, value: this.x },
            y: { type: this.yType, value: this.y },
        };
    }

    /**
     * This getter derives the X alignment from the styles in the text
     */
    public get alignX(): TextAlignment {

        // FIME: 'none' is not a valid align value. Investigate how align is set to 'none'
        if ( this.content?.[ 0 ]?.align === 'none' as any ) {
            return TextAlignment.negative;
        }

        if ( this._alignX !== undefined ) {
            return this._alignX;
        }
        if ( this.rendering === 'tiptapCanvas' || this.rendering === 'dom' ) {
            if ( !this.html ) {
                return TextAlignment.negative;
            }
            // FIXME remove this section after migrating data
            let el: HTMLElement = document.createElement( 'div' );
            el.innerHTML = this.html;
            el = el.querySelector( '*[style*="text-align"]' ) as HTMLElement;
            if ( el && el.style.textAlign === 'left' ) {
                return TextAlignment.negative;
            } else if ( el && el.style.textAlign === 'right' ) {
                return TextAlignment.positive;
            }

            if ( this.xType === 'fixed-start' ) {
                return TextAlignment.negative;
            }
            return TextAlignment.center;
        }
        if ( this.content[ 0 ] && this.content[ 0 ].align ) {
            return this.toAlignment( this.content[ 0 ]);
        }
        return TextAlignment.center;
    }

    /**
     * Returns the posiiton for given alignment concidering the hitArea
     */
    public getPositionForAlign(  alignment: TextAlignment ) {
        const position = {
            x: { type: this.xType, value: this.x },
            y: { type: this.yType, value: this.y },
        };
        if ( !this.hitArea ) {
            if ( alignment === TextAlignment.center ) {
                position.x.type = 'relative';
                position.x.value = 0.5;
            }
            const isInside = this.isInside();
            if ( isInside ) {
                if ( alignment === TextAlignment.negative ) {
                    position.x.type = 'fixed-start';
                    position.x.value = TEXT_PADDING_HORIZONTAL;
                }
                if ( alignment === TextAlignment.positive ) {
                    position.x.type = 'fixed-end';
                    position.x.value = TEXT_PADDING_HORIZONTAL;
                }
            } else { // Outside texts
                const posStr = TextPostion.getTextPositionString( this );
                if ( alignment === TextAlignment.negative ) {
                    if ( posStr.includes( 'ex-top' ) || posStr.includes( 'ex-bottom' )) {
                        position.x.type = 'fixed-start';
                        position.x.value = 0;
                    } else {
                        position.x.type = 'fixed-end';
                        position.x.value = TEXT_PADDING_HORIZONTAL;
                    }
                }
                if ( alignment === TextAlignment.positive ) {
                    if ( posStr.includes( 'ex-top' ) || posStr.includes( 'ex-bottom' )) {
                        position.x.type = 'fixed-end';
                        position.x.value = 0;
                    } else {
                        position.x.type = 'fixed-start';
                        position.x.value = -TEXT_PADDING_HORIZONTAL;
                    }
                }
            }
        } else {
            if ( position.x.type === 'fixed-start' ) {
                if ( alignment === TextAlignment.center ) {
                    this.hitArea.x = - this.hitArea.width / 2;
                    position.x.value = this.getHitAreaX() + this.hitArea.width / 2;
                }
                if ( alignment === TextAlignment.negative ) {
                    this.hitArea.x = 0;
                    position.x.value = this.getHitAreaX() + TEXT_PADDING_HORIZONTAL;
                }
                if ( alignment === TextAlignment.positive ) {
                    this.hitArea.x = - this.hitArea.width;
                    position.x.value = this.getHitAreaX() + this.hitArea.width  - TEXT_PADDING_HORIZONTAL;
                }
            }

            if ( position.x.type === 'fixed-end' ) {
                if ( alignment === TextAlignment.positive ) {
                    position.x.value = TEXT_PADDING_HORIZONTAL;
                }
            }
        }
        return position;
    }

    /**
     * Returns the bounding box of the text
     * @param defaultBounds Rectangle   Default bounds of the shape
     * @param transform ITransform      Shape transform
     */
    public getBoundingBox( defaultBounds: IRectangle, transform: ITransform ): Rectangle {
        if ( this.width === undefined || this.height === undefined ) {
            return new Rectangle( 0, 0, 0, 0 );
        }
        const regPoint = this.getRegPoint( transform );
        const onShape = Position.onRect( this.position, defaultBounds, transform );
        const textPos = new Point(( onShape.x - regPoint.x ) , ( onShape.y - regPoint.y ));
        const rect = new Rectangle( textPos.x, textPos.y, this.width, this.height );
        return rect.getBoundingBox( regPoint, transform.angle );
    }

    /**
     * The resultant wordwrap value,
     * wordWrap property is just to specify if the text should wrap or not
     * ( width is fixed or not when the shape width is changed )
     * This method returns true or false for wordwrap considering the text position
     *
     * e.g. Outside text
     * @returns
     */
     public getWordWrapWidth( shapeWidth: number, autoResize: boolean ): string {
        let width = shapeWidth;
        const posStr = TextPostion.getTextPositionString( this );
        const isInside = TextPostion.isInside( this );

        if ( this.hitArea ) {
            width = this.hitArea.width - ( TEXT_PADDING_HORIZONTAL * 2 );
        } else if ( !this.hitArea && isInside ) {
            if ( autoResize ) {
                return 'max-content';
            }
            width = shapeWidth - ( TEXT_PADDING_HORIZONTAL * 2 );
        } else if ( !isInside && ( posStr.includes( 'ex-top' ) || posStr.includes( 'ex-bottom' ))) {
            width = Math.max( shapeWidth, ShapeTextDataModel.MAX_UNWRAP_WIDTH );
        } else if ( !isInside ) {
            return 'max-content';
        }
        return width + 'px' ;
    }

   /**
    * Returns the registration point for the text model.
    */
    public getRegPoint( transform: ITransform ): IPoint2D {
        return TextPostion
            .getPointOnText({ width: this.width, height: this.height } as any, this.alignX, this.alignY, transform );
    }

    /**
     * Returns true if the text is placed inside the shape bounds
     */
    public isInside() {
        return TextPostion.isInside( this );
    }

    /**
     * Returns true if the text has multiple alignments
     */
    public isPartiallyAlinged() {
        return !!this.content.find( c => c.align !== this.content[0].align );
    }

   /**
    * Returns true if the text is alignable, in order for a text to be alignable,
    * it should be inside the shape bounds and should not include 'align' in fixedForats
    */
    public isAlignable() {
        return this.isInside() && !( this.fixedFormat && this.fixedFormat.align );
    }

   /**
    * Returns true if the text can be positionable, means that the text position control should
    * be enabled if the default text position is one of the available text positions.
    */
    public isPositionable() {
        return TextPostion.getTextPositionString( this ) !== 'none' ;
    }

   /**
    * Returns the x value of the hitArea relative to the shape top left.
    * ( hitArea.x is defined relative to the text position )
    */
    public getHitAreaX() {
        if ( this.hitArea ) {
            if ( this.alignX === 0 ) {
                return this.x - this.hitArea.width / 2;
            }
            if ( this.alignX === -1 ) {
                return this.x - TEXT_PADDING_HORIZONTAL;
            }
            return ( this.x + TEXT_PADDING_HORIZONTAL ) - this.hitArea.width;
        }
    }

    /**
     * Extracts the text alignment from the text styles object.
     */
    protected toAlignment( style: ITextStyles ): TextAlignment {
        if ( style.align === 'left' || style.align === 'justify' ) {
            return TextAlignment.negative;
        }
        if ( style.align === 'right' ) {
            return TextAlignment.positive;
        }
        return TextAlignment.center;
    }
}
