import { LinkService } from './../../../../../base/diagram/link.svc';
import { DiagramCommandEvent } from 'apps/nucleus/src/editor/diagram/command/diagram-command-event';
import { ShapeLinkModel, ShapeLinkType } from 'flux-diagram';
import { TranslateService } from '@ngx-translate/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService, Random } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { FileEmbedTiptapNodeView } from './file-embed-tiptap-nodeview';
import { Clipboard } from '@creately/clipboard';

/**
 * Tiptap nodeview to render when a createlink is pasted on the tiptap editor
 */
export class DiagramLinkTiptapNodeView extends FileEmbedTiptapNodeView {

    /**
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create( injector: Injector, viewCR: ViewContainerRef, diagramId: string ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const commandService = injector.get( CommandService );
        const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const linkSvc = injector.get( LinkService );
        const clipboard = injector.get( Clipboard );
        const translate = injector.get( TranslateService );
        const instance = new DiagramLinkTiptapNodeView(
            cfr, injector, commandService, stateSvc, ll, viewCR, translate, linkSvc, clipboard,
            diagramId,
        );
        return instance;
    }

    public name = 'diagramLinkNode';
    public group = 'block';
    public content = 'inline*';
    public atom = true;

    protected constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected command: CommandService,
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected dir: ViewContainerRef,
        protected translate: TranslateService,
        protected linkSvc: LinkService,
        protected clipboard: Clipboard,
        protected diagramId: string,
    ) {
        super( cfr, injector, command, state, ll, dir, translate, linkSvc, clipboard, diagramId );
    }

    public addNodeView = () =>
        ({
        editor,
        node,
        getPos,
        HTMLAttributes,
        decorations,
        extension,
        }) => {

            const dom = document.createElement( 'span' );
            dom.classList.add( 'file-embed-node' );
            dom.classList.add( 'diagram-link-node' );

            const linkMdl = ShapeLinkModel.fromUrl( node.attrs.link );
            const dId = linkMdl.linkedDiagramId;
            this.ll.forDiagram( dId, false ).getDiagramOnce().subscribe( diagram => {
                let label = diagram.name;
                if ( linkMdl.type === ShapeLinkType.SHAPE ) {
                    const shape = diagram.shapes[ linkMdl.targetId ];
                    if ( shape && shape.primaryTextModel && shape.primaryTextModel.plainText ) {
                        label = shape.primaryTextModel.plainText;
                    } else if ( shape ) {
                        label = shape.name;
                    }
                }
                // NOTE: Pasting the same link multiple times won't add multiple attachments, it's handled
                // in the changeAttachments command
                const id = Random.attachmentId();
                const shapeId = this.state.get( 'Selected' )[0];
                const attachment = {
                    id: id,
                    link: linkMdl.link,
                    name: label,
                    added: Date.now(),
                    source: 'creately',
                    action: 'add',
                    type: 'link/creately',
                    downloadable: false,
                    shapeId,
                };
                this.buildElement( dom, attachment , editor, this.subs );
                this.command.dispatch( DiagramCommandEvent.changeAttachments, this.diagramId, {
                    [id]: attachment,
                });
            });

            return {
                dom,
                destroy: () => {
                    while ( this.subs.length > 0 ) {
                        this.subs.pop().unsubscribe();
                    }
                },
                stopEvent: e => {
                if ( e.key && e.type === 'keydown' && ( e.key === 'ArrowUp' || e.key === 'ArrowDown' )) {
                    editor.commands.focus();
                }
                return true;
                },
                selectNode: () => {
                },
            };

    }

    public addAttributes = () => ({
        link: {
            default: '',
        },
    } as any )

    /**
     * NOTE: Using addPasteRules hook is the recommended way to render a nodeView on paste
     * but there's a tiptap bug
     */
    // public addPasteRules = function ( this ) {
    //     return [
    //     markPasteRule({
    //         find: /^http[s]?:\/\/([a-zA-Z\d-]+\.)*creately\.com\/diagram\/(\w+)?/g,
    //         type: this.type,
    //         getAttributes: match => {

    //             const diagramId = {};
    //             // const [ , , dataItemId ] = match;
    //             return { link: match.input };
    //         },
    //     }),
    //     ];
    // };

}
