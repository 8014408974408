<div class="upgrade-dialog-container modal-window-container fx-cover fx-center-all" #window (click)="closeOnOverlayClick($event)">
    <div #windowInner class="modal-window-inner" data-testid="upgrade-dialog">
        <div class="modal-window-heading fx-margin-top-15">
            <button
                class="upgrade-dialog-close btn-small"
                [trackAction]="{ event: 'conversion.dialog.upgrade.close', data: { value1Type: 'dialogId', value1: dialogData.id } }"
                (click)="closeWindow()">
                    <svg class="nu-icon nu-icon-small nu-ic-close">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                    </svg>
            </button>
            <div class="upgrade-dialog-img fx-margin-bottom-10">
                <svg class="nu-icon nu-icon-large nu-ic-warning-solid">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-circle-warning"></use>
                </svg>
            </div>
            <div class="upgrade-dialog-text">
                <h2 class="fx-margin-bottom-10 body body--lg body--bold" data-testid="upgrade-dialog-heading">{{translate.get( dialogData.heading, dialogData.headingParams )|async}}</h2>
                <p class="body" data-testid="upgrade-dialog-message">{{translate.get( dialogData.description, dialogData.descriptionParams )|async}}</p>
            </div>
        </div>
        <div class="upgrade-dialog-buttons caption">
            <button
                *ngIf="!dialogData.hideCancelButton"
                class="btn-medium btn-secondary fx-margin-right-8"
                (click)="closeWindow()" [trackAction]="{ event: 'conversion.dialog.upgrade.cancel', data: { value1Type: 'dialogId', value1: dialogData.id } }"
                translate>
                    {{translate.get('BUTTONS.CANCEL') | async}}
            </button>
            <button class="btn-medium btn-primary"
                (click)="getButtonHandler()"
                [trackAction]="{ event: 'conversion.dialog.upgrade.viewOptions', data: { value1Type: 'dialogId', value1: dialogData.id }}"
                translate>
                    {{translate.get( dialogData.buttons[0].text ) | async}}
            </button>
        </div>
    </div>
</div>
