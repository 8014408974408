<div class="modal-window-container fx-cover fx-center-all" #container>
    <div class="modal-window-inner file-export-modal" #window>
        
        <div class="modal-window-heading custom-heading">
            <h1>{{ translate.get('EXPORT_POPUP_WINDOW.FILE_EXPORTED')|async }}</h1>
        </div>
        <div class="muted">
            {{ translate.get('EXPORT_POPUP_WINDOW.IF_DOWNLOAD_NOT_STARTED')|async }}, <a (click)="retryDownload()">{{ translate.get('EXPORT_POPUP_WINDOW.CLICK_HERE')|async }}</a>.
        </div>

        <div class="content-box">
            <div class="header">
                <div>
                    <h1>{{ translate.get('EXPORT_POPUP_WINDOW.UNLIMITED_WORKSPACE_HIGH_QUALITY_IMAGES')|async }}</h1>
                    <p class="sub-heading">
                        {{ translate.get('EXPORT_POPUP_WINDOW.PREMIUM_PLAN_PARTS')|async }}
                    </p>
                </div>
                <div>
                    <object data="./assets/icons/svg/celebrate.svg" type="image/svg+xml" width="64" height="32" class="icon-celebrate"></object>
                    <object data="./assets/icons/svg/circle-dash.svg" type="image/svg+xml" width="50" height="50"></object>
                </div>
            </div>
            <div class="content">
                <ul>
                    <li>{{ translate.get('EXPORT_POPUP_WINDOW.UNLIMITED_WORKSPACE')|async }}</li>
                    <li [innerHTML]="translate.get('EXPORT_POPUP_WINDOW.HIGH_RESOLUTION') |async"></li>
                    <li>{{ translate.get('EXPORT_POPUP_WINDOW.UNLIMITED_EXPORTS')|async }}</li>
                </ul>
            </div>
        </div>

        <div class="button-panel">
            <button class="nu-btn-small btn-custom" (click)="showPlansPage()">{{ translate.get('EXPORT_POPUP_WINDOW.VIEW_PLANS')|async }}</button>
            <a (click)="closeWindow()">{{ translate.get('EXPORT_POPUP_WINDOW.CONTINUE_FREE')|async }}</a>
        </div>

    </div>
</div>