import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { CommandService } from 'flux-core';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { EDataCommandEvent } from '../../base/edata/command/edata-command-event';
import { EDataLocatorLocator } from '../../base/edata/locator/edata-locator-locator';
import { EDataModel } from '../../base/edata/model/edata.mdl';
import { ModelChangeUtils } from './model-change-utils';

@Injectable()
export class SyncDiagramEDataService {
    constructor(
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        private mcu: ModelChangeUtils,
        private commandService: CommandService,
    ) {}

    public syncDiagramToEData( diagramModelOrId: DiagramModel | string, isDelete = false ) {
        let diagramModel: Observable<DiagramModel>;
        if ( diagramModelOrId instanceof DiagramModel ) {
            diagramModel = of( diagramModelOrId );
        } else {
            diagramModel = this.ll.forDiagram( diagramModelOrId, false ).getDiagramOnce();
        }
        diagramModel.pipe(
            switchMap( diagram => {
                if ( !diagram.eData || diagram.eData.length === 0 ) {
                    return EMPTY;
                }
                return this.getAllChangeModels( diagram.eData ).pipe(
                    tap( eDataModels => {
                        const eDataById = {};
                        eDataModels.forEach( e => {
                            eDataById[e.id] = e;
                        });
                        const eDataShapes = Object.values( diagram.shapes ).filter( s => !!s.eData );
                        eDataShapes.forEach( s => {
                            const eDataId = Object.keys( s.eData )[0];
                            const entityId = Object.values( s.eData )[0];
                            if ( eDataById[ eDataId ]) {
                                const eData = eDataById[ eDataId ];
                                this.mcu.addShapeToEntity( eData, {
                                    entityId,
                                    shapeId: s.id,
                                    shapeDefId: s.defId,
                                    diagramId: diagram.id,
                                    isDelete,
                                });
                            }
                        });
                        eDataModels.forEach(( model: Proxied<EDataModel> ) => {
                            const modifier = model.__sakota__.getChanges();
                            if ( modifier && (
                                ( !isDelete && modifier.$set && Object.keys( modifier.$set ).length > 0 ) ||
                                ( isDelete && modifier.$unset && Object.keys( modifier.$unset ).length > 0 )
                            )) {
                                this.commandService
                                    .dispatch( EDataCommandEvent.applyModifierEData, model.id, { modifier });
                            }
                        });
                    }),
                );
            }),
        ).subscribe();
    }

    /**
     * Returns all the edata change models available for the diagram
     */
    protected getAllChangeModels( ids: string[]): Observable<Proxied<EDataModel>[]> {
        const obs = ids.map( id => this.ell.getEData( id ).pipe(
            switchMap( locator => locator.getEDataModelOnce().pipe(
                map( model => Sakota.create( model )),
            )),
        ));
        return forkJoin( obs );
    }
}
