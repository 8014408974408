import { union } from 'lodash';
import { Injectable } from '@angular/core';
import { TagMap } from 'flux-core';
import { IConnectorDefinition, IShapePort } from 'flux-definition';

/**
 * Keeps track of all connector definitions in memory so it can be queried by handshakes.
 */
@Injectable()
export class ConnectorRegistry {
    public static get instance(): ConnectorRegistry {
        return ConnectorRegistry._instance;
    }
    /**
     * Holds the singleton instance of ConnectorRegistry, enabling it to be used as a utility
     * class as well as a service. Should not be used until the app is initialized completely.
     */
    private static _instance = null;

    /**
     * A map of handshakes with associated connector definitions.
     */
    private data = new TagMap<IConnectorDefinition>();

    /**
     * A map of connector definition ids and definition objects
     */
    private defs: { [ defId: string ]: IConnectorDefinition } = {};

    /**
     * Creates the singleton ConnectorRegistry instance.
     */
    constructor() {
        if ( ConnectorRegistry._instance ) {
            return ConnectorRegistry._instance;
        }
        ConnectorRegistry._instance = this;
    }

    /**
     * Registers a connector definition
     */
    public register( def: IConnectorDefinition ): void {
        if ( !this.defs[def.defId]) {
            this.defs[def.defId] = def;
            if ( def.handshake ) {
                this.data.set( def.handshake, def );
            }
        }
    }

    /**
     * Returns a connector definition by defId.
     */
    public fetch( defId: string ): IConnectorDefinition {
        return this.defs[defId];
    }

    /**
     * Returns a list of connector definitions with given handshake.
     */
    public search( handshake: string[]): IConnectorDefinition[] {
        return this.data.get( handshake );
    }

    /**
     * Returns an array of connector definitions given connector ports can use.
     */
    public defsForPorts( portA: IShapePort, portB: IShapePort ): IConnectorDefinition[] {
        const handshake = union( portA.handshake, portB.handshake );
        return this.search( handshake );
    }
}
