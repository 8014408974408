import { Injectable, Inject } from '@angular/core';
import { Logger, StateService, SharedStateService, AppConfig, MapOf } from 'flux-core';
import { Observable, Subscription, interval, of } from 'rxjs';
import { distinctUntilChanged, filter, tap, map, take, startWith, switchMap } from 'rxjs/operators';
import { NucleusAuthentication } from '../../system/nucleus-authentication';
import { filter as _filter, find as _find } from 'lodash';
import { IRealtimeStateInfo } from '../base-states';
import { CollabLocator } from '../diagram/locator/collab-locator';
import { PhotonClient, PhotonConnectionStatus } from '@creately/photon-client';
import { reduce } from 'lodash';
import { CollabModel } from 'flux-diagram';

/**
 * RealtimeStateValue is contains the value as well as the user it belongs to.
 */
export interface IRealtimeStateValue {
    peerId: string;
    userId: string;
    value: string;
}

/**
 * An enum which defines all possible states a realtime connection can be in.
 * Status changes are emitted via the 'RealtimeConnectionStatus' state in state
 * service.
 */
export enum RealtimeConnectionStatus {

    /**
     * Realtime connection is offline and unavailable
     */
    OFFLINE,

    /**
     * Connected to the realtime server, but not authenticated
     */
    CONNECTED,

    /**
     * Realtime connection is connected, authenticated and available
     */
    ONLINE,

}

/**
 * RealtimeStateService connects to Photon server and syncs realtime states.
 */
@Injectable()
export class RealtimeStateService {

    /**
     * The diagram we have subscribed for realtime states.
     */
    private _topic: string;

    /**
     * Photon url from the config
     */
    private photonUrl: string;

    /**
     * An instance of Photon
     */
    private _photon: PhotonClient;

    /**
     * Holds the subscription for collabLocator
     */
    private collabSub: Subscription;

    /**
     * Sub for the connection  status to peering server
     */
    private connStatusSub: Subscription;

    constructor(
        private auth: NucleusAuthentication,
        @Inject( StateService ) protected state: StateService<any, any>,
        @Inject( SharedStateService ) protected sharedState: SharedStateService< 'Realtime', IRealtimeStateInfo>,
        private collabLocator: CollabLocator,
    ) {}

    /**
     * Starts the realtime service and initializes the listeners.
     */
    public initialize(): void {
        this.photonUrl = AppConfig.get( 'PHOTON_URL' );

        this.listenToDiagramChange().subscribe();
    }

    /**
     * Getter for photon instance
     */
    public getPhoton(): PhotonClient {
        return this._photon;
    }

    /**
     * Subscribe to a realtime state
     * can also pass a topic, which allows to listen to topics outside
     * the document context.
     */
    public get( key: string, topic?: string ): Observable<IRealtimeStateValue> {
        if ( topic && topic !== this._topic ) {
            throw Error( `Invalid Topic passed ${topic}, current topic is ${this._topic}` );
        }
        return this.getPhoton().getPeerState( key );
    }

    /**
     * Subscribe to a realtime state
     * can also pass a topic, which allows to listen to topics outside
     * the document context.
     */
    public getCurrent( key: string, topic?: string ): MapOf<any> {
        if ( topic && topic !== this._topic ) {
            throw Error( `Invalid Topic passed ${topic}, current topic is ${this._topic}` );
        }
        return this.getPhoton().getPeerCurrentState( key );
    }


    /**
     * Subscribe to a realtime state of a user
     * can also pass a topic, which allows to listen to topics outside
     * the document context.
     */
    public getUser( key: string, userId: string, includeCurrent = true,
                    topic?: string ): Observable<string> {
        const updates = this.get( key, topic ).pipe(
            filter( v => v.userId === userId ),
            map( v => v.value ),
        );
        if ( includeCurrent ) {
            const states = this.getCurrent( key, topic ) || {};
            return updates.pipe(
                startWith( states[userId]),
            );
        }
        return updates;
    }

    /**
     * get user IRealtimeStateInfo from SharedStateService
     */
    public getUserDetails( userId: string ): IRealtimeStateInfo {
        return this.sharedState.getUser( 'Realtime', userId );
    }

    /**
     * Updates a realtime state for the current document
     */
    public set( key: string, val: string ): void {
        this.getPhoton().broadcast( key, val );
    }

    /**
     * Emits when a peer subscribes to a diagram.
     */
    public addedPeers(): Observable<string> {
        return this.getPhoton().addedPeer;
    }

    /**
     * Emits when a peer disconnects or unsubscribes from the diagram.
     */
    public removedPeers(): Observable<string> {
        // verify if the peer also disconnected from the collabLocator.
        return this.getPhoton().removedPeer.pipe(
            switchMap ( userId => {
                const isOnline = !!this.sharedState.getUser( 'Realtime', userId );
                if ( isOnline ) {
                    Logger.debug( ':peer: This user is still online, waiting for connection to come back up' );
                    // if the user is still online, check if he goes offline in next 10 seconds
                    return interval( 1000 ).pipe(
                        take( 10 ),
                        filter(() => !this.sharedState.getUser( 'Realtime', userId )),
                        take( 1 ),
                        map(() => userId ),
                    );
                }
                return of( userId );
            }),
        );
    }


    /**
     * Get a new photonPeer instance
     */
    protected getNewPhotonPeer(): PhotonClient {
        return new PhotonClient( this.photonUrl, this.auth.currentUserId, this._topic );
    }

    /**
     * Setter for photon instance
     */
    protected setPhoton ( val: PhotonClient ) {
        this._photon = val;
    }


    /**
     * Starts Photon activities.
     * This is called everytime the currentDiagram is changed
     * All things done here must be explicitly undone in the stop() function
     */
    protected async start() {
        try {
            this.setPhoton( this.getNewPhotonPeer());
            this.watchConnectionStatus();
            await this.getPhoton().initialize();
            this.listenToCollabChanges();
        } catch ( err ) {
            Logger.error( err );
        }
    }

    /**
     * Stops Photon activities
     * Must be called
     */
    protected async stop() {
        await this.getPhoton().reset();
        this.stopWatchingConnectionStatus();
        this.stopListeningToCollabChanges();
    }

    /**
     * Starts listening to changing diagrams.
     */
    protected listenToDiagramChange(): Observable<string> {
        /**
         * If in presentation maintain current topic
         */
        return this.state.changes( 'CurrentDiagram' )
            .pipe(
                filter( val => Boolean( val )),
                distinctUntilChanged(),
                tap( val => {
                    const presentationStatus = this.state.get( 'PresentationStatus' );
                    if ( presentationStatus && presentationStatus.presentationId ) {
                        return;
                    }
                    this.onDiagramChange( val );
                }),
            );
    }

   /**
    * When a diagram changes, stop photon subscriptions and
    * remove all the collaborators from the state service.
    * @param diagramId
    */
   protected async onDiagramChange( diagramId: string ) {
        try {
            if ( this.getPhoton()) {
                await this.stop();
            }
            // Remove shared state for previous topic if topic exists,
            // This check is needed as collabLocator.getCollabs() will return collabs for the
            // current diagram if the optional diagram id is parameter not passed to it
            if ( this._topic ) {
                this.collabLocator.getCollabs( this._topic ).pipe(
                    tap( collabs => {
                        if ( !collabs || collabs.length === 0 ) { // reset topic if diagram doesn't contain collabs
                            this._topic = undefined;
                        }
                    }),
                    filter( collabs => !!collabs ),
                    take( 1 ),
                ).subscribe({
                    next: async collabs => {
                        collabs.forEach( collab => this.sharedState.removeAll( collab.id ));
                        if ( diagramId === 'start' ) {
                            this._topic = undefined;
                        } else {
                            this.updateTopic( diagramId );
                            await this.start();
                            this.listenToCollabChanges();
                        }
                    },
                });
            } else {
                if ( diagramId !== 'start' ) {
                    this.updateTopic( diagramId );
                    await this.start();
                    this.listenToCollabChanges();
                }
            }
        } catch ( err ) {
            Logger.error( err );
        }
    }

    /**
     * Updates the default topic / diagramID
     * @param newTopic
     */
    protected updateTopic( newTopic: string ) {
        this._topic = newTopic;
    }

    /**
     * Listens to changes in collaborators.
     * Long running sub that is unsubscribed in stop()
     */

    protected listenToCollabChanges() {
        if ( this.collabSub ) {
            this.collabSub.unsubscribe();
        }

        // NOTE: There are cases where the CurrentUser is not set.
        // In such cases the viewing user will not be put into the
        // collab list. Example: A user viewing the public diagram
        // without login into the app.
        if ( !this.state.get( 'CurrentUser' )) {
            return;
        }

        // listen to collabs on this new document
        this.collabSub = this.collabLocator.getCollabs( this._topic, true ).pipe(
            filter( collabs => !!collabs ),
        ).subscribe( collabs  => {
            this.onCollabChange ( collabs );
        });
    }

    /**
     * Invoked on colalboration changes
     * Compare current collabs only activate photon connection when there
     * are other live collabs
     * @param collabs
     */
    protected onCollabChange( collabs: CollabModel[]) {
        if ( !collabs || collabs.length === 0 ) {
            this.getPhoton().disconnect();
            return;
        }

        const hasOnlineUsers = reduce( collabs, ( onlineCount, collab ) => {
            if ( collab && collab.id !== this.auth.currentUserId && collab.online ) {
               onlineCount++;
            }
            return onlineCount;
        }, 0 );

        if ( hasOnlineUsers > 0 ) {
            this.getPhoton().connect();
        } else {
            // this.getPhoton().disconnect();
        }
    }


    /**
     * Listens to changes to photon connection and updates the realtime
     * connection state accordingly. This is a long lived subscription
     * active once the realtime state service is started.
     * TODO: Add states for CONNECTING, and ERRORED
     */
    protected watchConnectionStatus() {
        this.connStatusSub = this.getPhoton().connectionStatus.subscribe( connStatus => {
            if ( connStatus === PhotonConnectionStatus.CONNECTED ) {
                Logger.debug( 'Realtime Service - connected to server.' );
                this.state.set( 'RealtimeConnectionStatus', RealtimeConnectionStatus.CONNECTED );
            } else if ( connStatus === PhotonConnectionStatus.ONLINE ) {
                Logger.debug( 'Realtime Service- online and authenticated.' );
                this.state.set( 'RealtimeConnectionStatus', RealtimeConnectionStatus.ONLINE );
            } else if ( connStatus === PhotonConnectionStatus.OFFLINE ) {
                Logger.debug( 'Realtime Service - offline.' );
                this.state.set( 'RealtimeConnectionStatus', RealtimeConnectionStatus.OFFLINE );
            }
        });
    }

    /**
     * Stop watching the connection status
     */
    private stopWatchingConnectionStatus() {
        if ( this.collabSub ) {
            this.collabSub.unsubscribe();
            this.collabSub = undefined;
        }
    }

    /**
     * Stop listening to collab changes
     */
    private stopListeningToCollabChanges() {
        if ( this.connStatusSub ) {
            this.connStatusSub.unsubscribe();
        }
    }


}
