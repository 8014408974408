import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * This does validation of formula data items.
 * For the moment no validation is required.
 * Can re-evaluate the formula and check against stored value if we really want to validate.
 */
export class FormulaDataItem extends AbstractDataItem<DataType.VIEW> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof FormulaDataItem.prototype.value ): IValidationError {
        return null;
    }
}
