import { Injectable } from '@angular/core';
import { modify } from '@creately/mungo';
import { IModelChange, IModifier, StateService } from 'flux-core';
import { IDiagramChange, IDiagramLocator, PreviewDiagramLocator, StoredDiagramLocator } from 'flux-diagram-composer';
import { DataStore, DataSync } from 'flux-store';
import { cloneDeep } from 'lodash';
import { concat, defer, EMPTY, forkJoin, from, iif, merge, Observable, Subscription } from 'rxjs';
import { concatMap, filter, map, skip, switchMap, take } from 'rxjs/operators';
import { ConnectorModel } from '../../shape/model/connector.mdl';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { DiagramFactory, PreviewDiagramFactory } from '../diagram-factory';
import { DiagramModel } from '../model/diagram.mdl';

/**
 * The IDiagramLocator interface is a generic interface. Create a new type which has
 * correct types for diagram model and shape models set.
 */
export type ITypedDiagramLocator = IDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>;

/**
 * The DiagramLocatorLocator is used to create and get diagram locators by the diagram id.
 */
@Injectable()
export class DiagramLocatorLocator {
    /**
     * Counter used to generate unique ids for subscription observers.
     */
    private counter = 0;

    /**
     * Current diagram ID used by the locator
     */
    private _currentDiagramId: string;

    /**
     * List of subscriptions to allow for
     * destroying this instance.
     */
    private subs: Subscription[];

    /**
     * change stream for preview locator
     */
    private _previewChangeStream: Observable<IDiagramChange>;

    /**
     * A map of active diagram locators.
     */
    private locators: {
        [diagramId: string]: {
            slocator: StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
            plocator: PreviewDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
        },
    } = {};

    constructor(
        private states: StateService<any, any>,
        private datastore: DataStore,
        private dataSync: DataSync,
    ) {
        this.subs = [];
    }

    private get previewChangeStream() {
        if ( !this._previewChangeStream ) {
            this._previewChangeStream = this.createPreviewLocatorChangeStream();
        }
        return this._previewChangeStream;
    }

    /**
     * Initializes the locator locator. Should be called once upon creation.
     * This starts the locator and listens to changes in the current diagram.
     * upon change, cleans up the previous locator references.
     */
    public initialize() {
        const sub = this.states.changes( 'CurrentDiagram' ).subscribe(
            diagId => {
                if ( this._currentDiagramId ) {
                    this.destroyById( this._currentDiagramId );
                }
                this._currentDiagramId = diagId;
            },
        );

        this.subs.push ( sub );
    }

    /**
     * @deprecated - to be deperecated in favor of the forCurrentObservable functoin
     * DO NOT use this for future purposes - 19-09-2019
     *
     * Returns the diagram locator for current diagram.
     * @isPreview flag to state if you need a preview locator or a stored locator
     * @returns the current diagramLocator instance (preview or stored)
     */
    public forCurrent( isPreview: boolean ): ITypedDiagramLocator {
        if ( !this._currentDiagramId ) {
            throw new Error( 'The `CurrentDiagram` id should be set before calling forCurrent' );
        }
        return this.forDiagram( this._currentDiagramId, isPreview );
    }

    /**
     * Returns an observable diagram locator for current diagram.
     * @isPreview flag to state if you need a preview locator or a stored locator
     * @returns the current diagramLocator instance (preview or stored) as an observable
     */
    public forCurrentObserver( isPreview: boolean ): Observable<ITypedDiagramLocator> {
        if ( !this.states.get( 'CurrentDiagram' )) {
            throw new Error( 'The `CurrentDiagram` id should be set before calling forCurrentObservable' );
        }

        return this.states.changes( 'CurrentDiagram' ).pipe(
            map( diagramId => this.forDiagram( <string>diagramId, isPreview ),
        ));
    }


    /**
     * Returns the diagram locator for given diagram id. Create locators if needed.
     * NOTE: returns the preview diagram locator for the preview scenario, returns the
     *       stored diagram locator for all other scenarios.
     */
    public forDiagram( diagramId: string, isPreview: boolean ): ITypedDiagramLocator {
        if ( !this.locators[diagramId]) {
            this.locators[diagramId] = this.createLocators( diagramId );
        }
        if ( isPreview ) {
            return this.locators[diagramId].plocator;
        }
        return this.locators[diagramId].slocator;
    }

    /**
     * Destroy and uncache diagram locators for the given diagram.
     */
    public destroyById( diagramId: string ): void {
        if ( !this.locators[diagramId]) {
            return;
        }
        const locators = this.locators[diagramId];
        locators.plocator.stop();
        locators.slocator.stop();
        delete this.locators[diagramId];
    }

    /**
     * Destroys the locator-locator gracefully
     */
    public destroy() {
        let sub: Subscription;
        while ( sub = this.subs.pop()) {
            sub.unsubscribe();
        }
    }

    /**
     * This creates the locator for given snapshot object
     * @param diagramId diagram id associated with the snapshot
     * @param modelObservable snapshot model as an observable
     * @returns the stored locator for the snapshot
     */
    public createSnapshotLocator( diagramId: string, modelObservable: Observable<Object> ) {
        const slocator = new StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>();
        const schanges = this.createSnapshotChangeStream( diagramId, modelObservable );
        slocator.start({ changes: schanges, factory: new DiagramFactory() });
        return slocator;
    }

    private createSnapshotChangeStream( diagramId: string, modelObservable: Observable<Object> ) {
        const staticObservable = this.createStoredDiagramStaticChangeStream( diagramId );
        return concat(
            forkJoin({
                model: modelObservable,
                staticDoc: staticObservable.pipe( take( 1 )),
            }).pipe(
                map(({ model, staticDoc }) => {
                    const data = Object.assign({}, model, staticDoc );
                    const modifier = { $set: cloneDeep( data ) };
                    return { id: this.createUniqueId(), modifier };
                }),
            ),
            staticObservable.pipe(
                skip( 1 ),
                map( data => {
                    const modifier = { $set: cloneDeep( data ) };
                    return { id: this.createUniqueId(), modifier };
                }),
            ),
        );
    }

    /**
     * Creates the change stream for diagram static changes
     * Note this will always emit updated entity rather than the change
     * @param diagramId - diagram id
     * @return observable which emits all diagram model data as a modifier
     * once and completes.
     */
    private createStoredDiagramStaticChangeStream( diagramId: string ): Observable<any> {
        const modelStore = this.datastore.getModelStore( DiagramModel );
        return modelStore.staticCollection.findOne({ id: diagramId });
    }

    /**
     * Create and cache diagram locators for the given diagram.
     */
    private createLocators( diagramId: string ): {
        slocator: StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
        plocator: PreviewDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
    } {
        const slocator = this.createStoredLocator( diagramId );
        const plocator = this.createPreviewLocator( diagramId, slocator );
        return { slocator, plocator };
    }

    /**
     * Create stored diagram locator for the given diagram.
     * FIXME: stream changes from the indexed db to the stored locator.
     * FIXME: create and provide a diagram factory
     */
    private createStoredLocator( diagramId: string ): StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel> {
        const slocator = new StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>();
        const schanges = this.createStoredLocatorChangeStream( diagramId );
        slocator.start({ changes: schanges, factory: new DiagramFactory() });
        return slocator;
    }

    /**
     * Creates an observable which will emit changes applied to the stored diagram model.
     */
    private createStoredLocatorChangeStream( diagramId: string ): Observable<IDiagramChange> {
        return concat(
            this.createStoredDiagramChangeStream( diagramId ).pipe( map( data => {
                data.source = 'init';
                return data;
            })),
            merge(
                this.createStoredDiagramChangeStreamOnInsert( diagramId ),
                defer(() =>  this.createStoredDiagramChangesChangeStream( diagramId )),
                defer(() => this.createStoredDiagramStaticChangesChangeStream( diagramId )),
            ),
        );
    }

    /**
     * Creates the change stream for diagram model changes
     * @param diagramId - diagram id
     * @return observable which emits all diagram model data as a modifier
     * once and completes.
     */
    private createStoredDiagramChangeStream( diagramId: string ): Observable<any> {
        return this.datastore.findOneRaw( DiagramModel, { id: diagramId }).pipe(
            take( 1 ),
            switchMap( data => {
                data = cloneDeep( data );
                // NOTE: We are synchronizing any changes which are unsaved to the
                // diagram model so that the model will be upto date with the changes.
                // FIXME: Diagram changes while offline is not fully fixed. it is due
                // to some core issues. Example: Some of the changes we do on the canvas,
                // is not captured as change (it's weired and hard to recreate).
                // Noticed that when deleting the shapes and the change of that deleting shape
                // was not avaialble in the diagram changes collection.
                const selector = { modelId: diagramId };
                return this.dataSync.findUnsavedChanges( DiagramModel, selector ).pipe(
                    take( 1 ),
                    map(( change: IModelChange[]) => {
                        // NOTE: Sorting the changes with the client time so the order of applying
                        // changes will not be incorrect.
                        change.sort(( c1, c2 ) => c1.clientTime - c2.clientTime );
                        for ( const doc of change ) {
                            modify( data, doc.modifier );
                        }
                        const modifier = { $set: data };
                        return { id: this.createUniqueId(), modifier };
                    }),
                );
            }),
        );
    }

    /**
     * Creates the change stream for diagram model changes.
     * Watches the change collection for diagram inserts.
     * @param diagramId - diagram id
     * @return observable which continously emits diagram model changes.
     */
    private createStoredDiagramChangeStreamOnInsert( diagramId: string ): Observable<any> {
        const modelStore = this.datastore.getModelStore( DiagramModel );
        return modelStore.collection.watch({ id: diagramId }).pipe(
            filter( change => change.type === 'insert' ),
            switchMap( change => this.createStoredDiagramChangeStream( diagramId )),
            map( data => {
                data.source = 'insert';
                return data;
            }),
        );
    }

    /**
     * Creates the change stream for diagram changes.
     * @param diagramId - diagram id
     * @return observable which continously emits diagram changes.
     */
    private createStoredDiagramChangesChangeStream( diagramId: string ): Observable<any> {
        const modelStore = this.datastore.getModelStore( DiagramModel );
        const appliedChangeIds = [];
        return modelStore.changeCollection.watch({ modelId: diagramId }).pipe(
            filter( change => change.type === 'insert' || change.type === 'remove' ),
            // NOTE: documents in the change collection are model changes
            // NOTE: if a change was emitted as an unsaved change, do not
            //       re-emit when it gets converted to a saved change.
            concatMap( change => {
                change.docs.sort(( c1, c2 ) => c1.clientTime - c2.clientTime );
                const changes = [];
                for ( const doc of change.docs ) {
                    if ( change.type === 'insert' ) {
                        const index = appliedChangeIds.indexOf( doc.id );
                        if ( index === -1 ) {
                            appliedChangeIds.push( doc.id );
                            changes.push({ id: this.createUniqueId(), modifier: doc.modifier });
                        }
                        // remove oldest changes from the list to free up memory.
                        if ( appliedChangeIds.length === 10000 ) {
                            appliedChangeIds.splice( 0, 500 );
                        }
                    } else if ( change.type === 'remove' ) {
                        changes.push({ id: this.createUniqueId(), modifier: doc.reverter });
                    }
                }
                return from( changes );
            }),
            map( data => {
                data.source = 'change';
                return data;
            }),
        );
    }

    /**
     * Creates the change stream for diagram static data changes
     * @param diagramId Creates the change stream for diagram static data changes
     * @return observable which continously emits diagram static data changes.
     */
    private createStoredDiagramStaticChangesChangeStream( diagramId: string ) {
        const modelStore = this.datastore.getModelStore( DiagramModel );
        return modelStore.staticCollection.watch({ id: diagramId }).pipe(
            filter( change => change.type === 'update' || change.type === 'insert' ),
            // NOTE: documents in the static collection are parts of diagram model
            concatMap( change => {
                const changes = change.docs.map( doc => ({
                    id: this.createUniqueId(),
                    modifier: { $set: doc },
                }));
                return from( changes );
            }),
        );
    }

    /**
     * Create preview diagram locator for the given diagram.
     * FIXME: create and provide a diagram factory
     */
    private createPreviewLocator(
        diagramId: string,
        slocator: StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
    ): PreviewDiagramLocator<DiagramModel, ShapeModel | ConnectorModel> {
        const plocator = new PreviewDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>();
        const pchanges = this.createPreviewLocatorChangeStreamForDiagram( diagramId );
        plocator.start({ changes: pchanges, factory: new PreviewDiagramFactory(), parent: slocator });
        return plocator;
    }

    /**
     * preview change stream for the given diagram
     * @param diagramId
     * @returns
     */
    private createPreviewLocatorChangeStreamForDiagram( diagramId: string ): Observable<IDiagramChange> {
        return this.states.changes( 'CurrentDiagram' ).pipe(
            switchMap( cDiagramId => iif(() => cDiagramId === diagramId, this.previewChangeStream, EMPTY )),
        );
    }

    /**
     * Creates an observable which will emit changes applied to the preview diagram model.
     */
    private createPreviewLocatorChangeStream(): Observable<IDiagramChange> {
        return merge(
            this.states.changes( 'PreviewChanges' ),
            this.states.changes( 'RealtimePreviewChanges' ),
        ).pipe(
            skip( 2 ),
            map(( data: IModifier ) => ({ id: this.createUniqueId(), modifier: data })),
        );
    }

    // Other Helpers - helper functions which are not directly related to the logic
    // ----------------------------------------------------------------------------

    /**
     * Returns a unique number each time this method is called. Can be used as unique ids.
     */
    private createUniqueId(): string {
        return `${++this.counter}`;
    }

}
