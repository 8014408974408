<div class="fullscreen-container border-none fx-cover fx-center-all fx-center">

    <div class="fullscreen-message">
        <img src="./assets/images/logo-on-dark-200X80.svg" alt="Creately" class="logo">
        <h1 class="title" translate>MESSAGES.MISSING_REGION_HEADING</h1>
        <p class="body fx-margin-top-15" translate>MESSAGES.MISSING_REGION_SUBTEXT</p>
    </div>

    <a (click)="correctAppUrl()" *ifState="['ApplicationIsEmbedded', false]">
        <button class="btn-medium btn-secondary fullscreen-button">
            <span class="body" translate>BUTTONS.GOTO_DIAGRAMS</span>
        </button>
    </a>
</div>
