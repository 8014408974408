import { Injectable, Injector } from '@angular/core';
import { CommandService, StateService, Tracker } from 'flux-core';
import { IShortCut, KeyCode } from 'flux-definition';
import { cloneDeep, isEqual } from 'lodash';
import { fromEvent, interval, merge, Observable, of } from 'rxjs';
import { filter, map, switchMap, throttle } from 'rxjs/operators';
import { IFeatureItem } from '../framework/feature/feature-item.i';
import { FeatureList } from '../framework/feature/feature-list.svc';
import { FocusContext } from './focus-context.enum';
import { Clipboard } from '@creately/clipboard';
import { DiagramLocatorLocator } from '../base/diagram/locator/diagram-locator-locator';
import { DiagramCommandEvent } from '../editor/diagram/command/diagram-command-event';

/**
 * A central unit that manages keyboard activity that are affecting more than just
 * the current focused element/control.
 *
 * For example if a text area is selected the keyboard activity affects
 * the selected text area → which is intern manage by the text area or what manages that text area.
 * In cases larger than (canvas) the KeyboardController will be able to manage the keyboard activity.
 */
@Injectable()
export class KeyBoardController {

    /**
     * This object holds the list of shortcuts feature for a given keySequence.
     */
    protected shortCutFeatures: {[ keySequence: string ]:  IFeatureItem[] };

    /**
     * This object holds shortcuts related to each shape
     */
    protected shapeShortcuts: { [ shapeDefId: string ]: {[ keySequence: string ]:  IFeatureItem[] } };

    /**
     * This array holds the features list for the
     * Command/Ctrl + V key combination.
     */
    protected featuresListForCtrlV: IFeatureItem[];

    /**
     * This object holds the feature ids of the shorcuts that needs to be ignored on tracking
     */
    protected ignoreFeatureTracking: {[ featureId: string]: boolean } ;

    constructor(
        protected state: StateService<any, any>,
        protected featureList: FeatureList,
        protected commandService: CommandService,
        protected ll: DiagramLocatorLocator,
        protected clipboard: Clipboard,
        protected injector: Injector ) {
        this.shortCutFeatures = {};
        this.shapeShortcuts = {};
        // Adding list of feature ids to ignore tracking
        this.ignoreFeatureTracking = { keyboardSelectionMove: true, editText: true };
        this.registerListeners();
    }

    /**
     * This registers the shortcuts with keySequence and the relevant feature from the feature list.
     * This should be called in every base route change.
     *
     * Note: Whenever the feature list got updated this needs to be called.
     */
    public registerFeatures() {
        this.featuresListForCtrlV = [];
        this.featureList.features.forEach( feature => {
            !!feature.shapeDefId ? this.registerShapeShortcut( feature ) : this.register( feature );
        });
    }

    /**
     * This method removes a given feature from the shorcuts list
     */
    public unregister( featureId: string ) {
        const feature: IFeatureItem = this.featureList.getFeature( featureId );
        if ( feature && feature.shortcuts ) {
            feature.shortcuts.forEach( shortcut => {
                const keySequence = this.getKeyCombination( shortcut );
                if ( keySequence ) {
                    this.removeShortCutFeature( keySequence, feature );
                }
            });
        }
    }

    /**
     * This returns the index of shortcut feature found for the given keySequence and related feature id
     */
    protected findShortCutFeatureIndex( keySequence: string, featureId: string ): number {
        const features: IFeatureItem[] = this.shortCutFeatures[ keySequence ];
        if ( !features ) {
            return -1;
        }
        return features.findIndex( feature => feature.id === featureId );
    }

    /**
     * This returns the string which concat the keycodes values and action given
     * @param keyCodes A list of shortcut key
     * @param action keyboard event that trigger the given key combination.
     */
    protected getKeyCombination( shortcut: IShortCut ): string {
        if ( shortcut.keySequence && shortcut.keySequence.length > 0 ) {
            let key = shortcut.action ? shortcut.action : 'keydown';
            shortcut.keySequence.forEach( keyCode => {
                key += '-' + keyCode;
            });
            return key;
        }
    }


    /**
     * This listens for the keyup, keydown events on
     * specific keys or key sequences.
     */
    protected registerListeners() {
        this.listenForKeyDownEvent()
            .subscribe( data => this.performKeyBoardAction( data.feature, data.event ));
        this.listenForKeyUpEvent()
            .subscribe( data => this.performKeyBoardAction( data.feature, data.event ));
        this.listenForPasteEvent().subscribe();
    }

    /**
     * Listener for the paste event.
     * This listens for the paste event triggered by user on the window.
     */
    protected listenForPasteEvent(): Observable<any> {
        return fromEvent( document, 'paste' ).pipe(
            map(( e: ClipboardEvent ) => {
                this.executePaste( e );
            }),
        );
    }

    /**
     * This function call all the features registered to the Command/Ctrl + V key
     * combination with the clipboard data.
     *
     * FIXME: Keyboard Controller should not know about how we handle
     * the paste event data. This should be done in a separate command.
     *
     * @param e - Clipboard Data
     */
    protected async executePaste( e: ClipboardEvent ) {
        let data: any;
        if ( this.state.get( 'ApplicationIsEmbedded' )) {
            const text = await this.clipboard.paste();
            if ( /^((http|https):\/\/)/.test( text )) {    // if clipboard content is URL, do not JSON.parse
                data = text;
            } else {
                data = JSON.parse( text );
            }
        } else {
            data = [];
            const items = e.clipboardData.items;
            for ( let i = 0; i < items.length; i++ ) {
                if ( items[i].getAsFile()) {
                    data.push( items[i].getAsFile());
                } else {
                    const jsonStr = this.getJsonString( e.clipboardData.getData( items[i].type ));
                    if ( jsonStr ) {
                        data = data.concat( jsonStr );
                    }
                }
            }
        }
        this.featuresListForCtrlV.forEach( feature => {
            if ( data && (( Array.isArray( data ) && data.length > 0 ) || !Array.isArray( data ))) {
                this.commandService.dispatch( feature.commandEvent, data );
            }
        });
    }

    /**
     * Listener for the key down event.
     * This listens for the keydown event and perform the keyboard action based on the shortcut feature found the
     * currently pressed key combination.
     */
    protected listenForKeyDownEvent(): Observable<{ feature: IFeatureItem, event: any }> {
        return merge(
            this.listenForKeyBoardEvent( 'keydown', false ),
            this.listenForKeyHoldDown(),
        ).pipe( map(({ keySequence, feature, event }) =>  ({ feature, event })));
    }

    /**
     * Listener for the keyUp event
     */
    protected listenForKeyUpEvent(): Observable<{ feature: IFeatureItem, event: any }> {
        return this.listenForKeyBoardEvent( 'keyup', false )
            .pipe( map(({ keySequence, feature, event }) =>  ({ feature, event })));
    }

    /**
     * Returns the current shortcut feature for the given event.
     * @param   eventName   A keyboard event that needs to be listen for
     * @param   repeat  Indicates whether event needs to be listen for the key hold or not
     */
    protected listenForKeyBoardEvent(
        eventName: 'keyup' | 'keydown',
        listenRepeat: boolean ): Observable<{ keySequence: any, feature: IFeatureItem, event: any }> {
            return fromEvent( window, eventName ).pipe(
                filter(( event: KeyboardEvent ) => event.repeat === listenRepeat && !event.defaultPrevented ),
                switchMap(( event: KeyboardEvent ) => {
                    const keySequence = this.composeKeySequence( event );
                    const keyCombination = this.getKeyCombination({ keySequence: keySequence, action: eventName });
                    // Differentiate between selected shape shortcut or generic shortcut
                    const selected = this.state.get( 'Selected' );
                    let feature = undefined;
                    if ( selected && selected.length === 1 ) {
                        const shapeId = selected[0];
                        return this.ll.forCurrentObserver( true ).pipe(
                            switchMap( locator => locator.getShapeOnce( shapeId )),
                            filter( shape => !!shape ),
                            switchMap( shape => of( shape.defId + '.' + shape.version )),
                            map( id => {
                                const focusContext = this.getFocusContext( event.target );
                                feature = this.getShapeSpecificShortcutFeature( id, keyCombination, focusContext );
                                if ( !feature ) {
                                    feature = this.getShortcutFeature(
                                        keyCombination,
                                        this.getFocusContext( event.target ),
                                    );
                                }
                                // To allow default shape shortcuts eg: Delete in UML Class
                                if ( feature && feature.commandEvent !== DiagramCommandEvent.doShapeAction ) {
                                    event.preventDefault();
                                }
                                return {
                                    keySequence,
                                    feature,
                                    event,
                                };
                            }),
                        );
                    }
                    if ( !feature ) {
                        feature = this.getShortcutFeature(
                            keyCombination,
                            this.getFocusContext( event.target ),
                        );
                    }
                    if ( feature ) {
                        event.preventDefault();
                    }
                    return of({
                        keySequence,
                        feature,
                        event,
                    });
                }),
                filter( featureWithKeys => featureWithKeys.feature !== undefined ),
            );
    }

    /**
     * Returns the current shortcut feature for keydown event when key is being held down
     * This discards the emitted event until the repeatOnPress time specified in the
     * particular feature.
     */
    protected listenForKeyHoldDown(): Observable<{ keySequence: any, feature: IFeatureItem, event: any }> {
        return this.listenForKeyBoardEvent( 'keydown', true ).pipe(
            map( shortcutFeature => {
                    const duration = this.getDurationToRepeatOnKeypress(
                        shortcutFeature.feature.shortcuts, shortcutFeature.keySequence );
                    if ( duration > 0 ) {
                        return{
                            feature: shortcutFeature,
                            repeatTime: duration,
                        };
                    }
            }),
            filter( repeatInfo => repeatInfo !== undefined ),
            throttle( repeatInfo => interval( repeatInfo.repeatTime )),
            map( repeatInfo => repeatInfo.feature ),
        );
    }

    /**
     * This returns the debounce time for the given keysequence from the shortcut feature and
     * determine action should be repeat or not based on the keyDownEventEmittedAndHandled
     */
    protected getDurationToRepeatOnKeypress( shortcuts: IShortCut[], keySequence: KeyCode[]): number {
        const shortCut = shortcuts.find( shortcut => isEqual( shortcut.keySequence, keySequence ));
        if ( shortCut.repeatOnKeyPress ) {
            return shortCut.repeatOnKeyPress;
        }
        return 0;
    }

    /**
     * Dispatch a relevant command for the give keyboard action
     * and attach the shortcut keyboard event if necessary
     */
    protected performKeyBoardAction( feature: IFeatureItem, event: any ) {
        if ( !this.ignoreFeatureTracking[ feature.id ]) {
            Tracker.track(
                'keyboard.shortcut.keypress',
               { value1Type: 'shortCut', value1: feature.shortcutText, value2Type:  'featureId', value2: feature.id },
            );
        }
        const data = cloneDeep( feature.data || {});
        if ( feature.attachKeyEventToData ) {
            data.keyboardEvent = event;
        }
        // Shape actions: Commit and close does not need to occur when only Delete/Backspace is pressed -
        // default action is what is required
        const isNotDeleteOrBackspace = event.keyCode !== KeyCode.Delete && event.keyCode !== KeyCode.Backspace;
        const isModifierKey = event.shiftKey || event.ctrlKey || event.metaKey;
        const isDoShapeActionCmd = feature.commandEvent === DiagramCommandEvent.doShapeAction;
        if ( isDoShapeActionCmd && ( isModifierKey || isNotDeleteOrBackspace )) {
            const editingState = this.state.get( 'EditingText' );
            const eventData = {
                shapeId: editingState.shapeId,
                text: this.state.get( 'TextContent' ).text,
                content: this.state.get( 'TextContent' ).content,
                textId: editingState.textId,
                cancel: event.keyCode === KeyCode.Delete || event.keyCode === KeyCode.Backspace,
            };
            this.commandService.dispatch( DiagramCommandEvent.applyTextAndCloseEditor, eventData );
        }
        if ( feature.transformer || feature.alterFunctions?.getApplyData ) {
            const transformer = feature.transformer ? this.injector.get( feature.transformer ) : feature.alterFunctions;
            transformer.getApplyData( feature.id, data ).subscribe( transferedData => {
                this.commandService.dispatch( feature.commandEvent, transferedData );
            });
        } else {
            this.commandService.dispatch( feature.commandEvent, data );
        }
    }

    /**
     * This returns the key sequences that was pressed during the given event.
     */
    protected composeKeySequence( event: KeyboardEvent ): KeyCode[] {
        // Temporary
        const keySequence = [];
        if ( event.metaKey ) {
            keySequence.push( KeyCode.Command );
        }
        if ( event.ctrlKey ) {
            keySequence.push( KeyCode.Control );
        }
        if ( event.altKey ) {
            keySequence.push( KeyCode.Alt );
        }
        if ( event.shiftKey ) {
            keySequence.push( KeyCode.Shift );
        }

        // This won't push the keycode if keycode values are same as modifier keys.
        if ( !this.isModifierKeyOnlyPressed( event )) {
            keySequence.push( event.keyCode );
        }
        return keySequence;
    }

    /**
     * This returns whether the modifier key is only pressed or not.
     * True means only modifier key, like Alt, Shift, Ctrl, or Meta key is pressed
     * in this given event.
     */
    protected isModifierKeyOnlyPressed( event: KeyboardEvent ): boolean {
        if (( event.getModifierState( 'Alt' ) && event.keyCode === KeyCode.Alt )
            || ( event.getModifierState( 'Shift' ) && event.keyCode === KeyCode.Shift )
            || ( event.getModifierState( 'Control' ) && event.keyCode === KeyCode.Control )
            || (( event.getModifierState( 'Meta' ) && event.keyCode === KeyCode.Command ))
        ) {
            return true;
        }
        return false;
    }

    /**
     * This returns the shortcut features for the given keySequence and context
     */
    protected getShortcutFeature( keySequence: string, context: FocusContext ): IFeatureItem {
        const features: IFeatureItem[] = this.shortCutFeatures[ keySequence ];
        if ( features &&  features.length > 0 ) {
            return features.find( feature => {
                if ( context === FocusContext.CanvasSelected ) {
                    return feature.context === context ||
                        feature.context === FocusContext.Canvas ||
                        feature.context === FocusContext.All;
                } else if ( context === FocusContext.CanvasNotSelected ) {
                    // Fallback to canvas
                    return feature.context === context ||
                        feature.context === FocusContext.Canvas;
                } else {
                    return feature.context === context ||
                        feature.context === FocusContext.All;
                }
            });
        }
    }

    /**
     * This returns the shape specific shortcut features for the given defId,  keySequence and context
     */
    protected getShapeSpecificShortcutFeature( defId: string, keySequence: string, context: FocusContext )
    : IFeatureItem {
        const features: IFeatureItem[] = this.shapeShortcuts[ defId ]
            ? this.shapeShortcuts[ defId ][ keySequence ] : undefined;
        if ( features &&  features.length > 0 ) {
            return features.find( feature => {
                if ( context === FocusContext.CanvasSelected ) {
                    return feature.context === context ||
                        feature.context === FocusContext.Canvas ||
                        feature.context === FocusContext.All;
                } else if ( context === FocusContext.CanvasNotSelected ) {
                    // Fallback to canvas
                    return feature.context === context ||
                        feature.context === FocusContext.Canvas;
                } else {
                    return feature.context === context ||
                        feature.context === FocusContext.All;
                }
            });
        }
    }

    /**
     * Returns the current focus context for the given target element.
     * TODO: When focus changes on the app, refocus if needed.
     */
    protected getFocusContext( target: any ): FocusContext {
        const name = target.tagName.toLowerCase();
        let context = FocusContext.Canvas;
        if ( this.checkIsTextEditable( target )) {
            if ( target.parentElement.id === 'shapetexteditor' ) {
                context = FocusContext.CanvasSelectedText;
            } else {
                context = FocusContext.Text;
            }
        } else if ( name === 'body' ) {
            // Note: When no element is focused document.body will return
            // Therefore currently refocusing it to canvas
            this.changeFocus( FocusContext.Canvas );
            context =  FocusContext.Canvas;
        }
        if ( context === FocusContext.Canvas ) {
            const selected = this.state.get( 'Selected' );
            if ( selected && selected.length ) {
                context = FocusContext.CanvasSelected;
            } else {
                context = FocusContext.CanvasNotSelected;
            }
        }
        return context;
    }

    /**
     * This checks whether the given target element is text editable.
     * @param target Html Element that needs to be checked for the editable type
     */
    protected checkIsTextEditable( target: any ) {
        /// FIXME: The following css selector is the only way to identify if the
        // quill image resizer is in action. ideally quill image resize module should
        // be forked and implement a better way.
        const imageResize = document.querySelector( '.ql-editor[style="user-select: none;"]' );
        const name = target.tagName.toLowerCase();
        return ( !!imageResize || name === 'input'
            && [ 'text', 'password', 'search', 'email', 'number', 'tel', 'url' ].indexOf( target.type ) > -1 )
            || name === 'textarea'
            || name === 'mat-chip'
            || ( name === 'div' && target.isContentEditable === true );
    }

    /**
     * This changes the focus to the given context
     */
    protected changeFocus( context: FocusContext ) {
        if ( context === FocusContext.Canvas ) {
            const canvas: HTMLElement  = document.getElementById( 'interaction-area-canvas' );
            if ( canvas ) {
                canvas.focus();
            }
        }
    }

    /**
     * This method register a shortcut to the given feature
     * Command/Ctrl + V combination related features are stored
     * in an array for handling it in 'paste' event.
     *
     * @param feature Feature that needs to be added to control through keyboard shortcut
     */
    private register( feature: IFeatureItem ) {
        if ( feature.shortcuts ) {
            feature.shortcuts.forEach( shortcut => {
                const keySequence = this.getKeyCombination( shortcut );
                if ( keySequence ) {
                    if ( keySequence === 'keydown-91-86' || keySequence === 'keydown-17-86' ) {
                        this.featuresListForCtrlV.push( feature );
                    } else {
                        this.addShortCutFeature( keySequence, feature );
                    }
                }
            });
        }
    }

    /**
     * This method register a shape specific shortcut to the given feature
     *
     * @param feature Feature that needs to be added to control through keyboard shortcut
     */
    private registerShapeShortcut( feature: IFeatureItem ) {
        if ( feature.shortcuts ) {
            feature.shortcuts.forEach( shortcut => {
                const keySequence = this.getKeyCombination( shortcut );
                if ( keySequence ) {
                    this.addShapeShortCutFeature( feature.shapeDefId, keySequence, feature );
                }
            });
        }
    }

    /**
     * This adds a given feature to the shortcut list. If feature is already registered this
     * replace the shortcut with new feature.
     */
    private addShortCutFeature( keySequence: string, feature: IFeatureItem ) {
        const shortcuts = this.shortCutFeatures[keySequence];
        if ( shortcuts && shortcuts.length > 0 ) {
            const index = this.findShortCutFeatureIndex( keySequence, feature.id );
            if ( index > -1 ) {
                shortcuts.splice( index, 1, feature );
                return;
            }
            shortcuts.push( feature );
            return;
        }
        this.shortCutFeatures[ keySequence ] = [ feature ];
    }

    /**
     * This adds a given feature to the shape specific shortcut list. If feature is already registered this
     * replaces the shortcut with new feature.
     */
    private addShapeShortCutFeature( defId: string , keySequence: string, feature: IFeatureItem ) {
        const shortcuts = this.shapeShortcuts[ defId ] ? this.shapeShortcuts[ defId ][ keySequence ] : undefined;
        if ( shortcuts && shortcuts.length > 0  ) {
            const index = shortcuts.findIndex( f => f.id === feature.id );
            if ( index > -1 ) {
                shortcuts.splice( index, 1, feature );
                return;
            }
            shortcuts.push( feature );
            return;
        }
        this.shapeShortcuts[ defId ] = this.shapeShortcuts[ defId ] ? this.shapeShortcuts[ defId ] : {};
        this.shapeShortcuts[ defId ][ keySequence ] = [ feature ];
    }

    /**
     * This removes the given feature from registered shortCutFeatures if it's found in the
     * shortvut list.
     */
    private removeShortCutFeature( keySequence: string, feature: IFeatureItem ) {
        const shortcuts = this.shortCutFeatures[keySequence];
        if ( shortcuts && shortcuts.length > 0 ) {
            const index = this.findShortCutFeatureIndex( keySequence, feature.id );
            if (  shortcuts.length === 1 && index === 0 ) {
                delete this.shortCutFeatures[ keySequence ];
                return;
            }
            if ( index > -1 ) {
                shortcuts.splice( index, 1 );
                return;
            }
        }
    }

    /**
     * This function converts the given string into JSON.
     * @param str - string which needs to be converted to JSON.
     * @returns - returns null if the string in not a valid JSON.
     */
    private getJsonString( str: string ): string {
        try {
            return JSON.parse( str );
        } catch ( e ) {
            return null;
        }
    }


}
