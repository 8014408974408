import { EAttribute, EBoolean, EClass, EInt, EObject, EOperation, EParameter, EReference, EString } from 'ecore';
import { MapOf } from 'flux-core';
import { EDataModel } from '../../edata/model/edata.mdl';
import { EntityModel } from '../../edata/model/entity.mdl';
import { AbstractEcoreModel } from './abstract-ecore-model';

export class EcoreUmlClass extends AbstractEcoreModel {

    public static eDefId = 'creately.entity.uml.class';

    static eCoreClassMap: MapOf<EObject> = {
        string: EString,
        boolean: EBoolean,
        int: EInt,
    };
    public static get instance(): EcoreUmlClass {
        return this._instance;
    }
    private static _instance = new EcoreUmlClass();

    public createObject( data: any ) {
        return EClass.create({
            name: data.name,
        });
    }

    public resolve( e: EntityModel, eObjects: MapOf<MapOf<EObject>>, model: EDataModel ) {
        const dynamicTypes = this.getDynamicTypes( e, eObjects, model.entities );
        this.resolveAttributes( e, eObjects[model.id], dynamicTypes );
        this.resolveOperations( e, eObjects[model.id], dynamicTypes );
        this.resolveLinks( e, eObjects );
    }

    public hasParent( links: any ) {
        return this.getPackageId( links ) !== '__global__';
    }

    protected resolveAttributes( e: EntityModel, eObjects: MapOf<EObject>, dynamicTypes ) {
        // Resolve Attr
        const eClass = eObjects[e.id];
        Object.values( e.data.attributes ).forEach( attr => {
            eClass.get( 'eStructuralFeatures' ).add( this.getAttribute( attr, dynamicTypes ));
        });
    }

    protected resolveOperations( e: EntityModel, eObjects: MapOf<EObject>, dynamicTypes ) {
        // Resolve Ops
        const eClass = eObjects[e.id];
        Object.values( e.data.operations ).forEach( op => {
            eClass.get( 'eOperations' ).add( this.getOperation( op, dynamicTypes ));
        });
    }

    protected resolveLinks( e: EntityModel, eObjects: MapOf<MapOf<EObject>> ) {
        // Resolve Associations if not already resolved.
        // resolve super types.
    }

    protected getDynamicTypes( e: EntityModel, eObjects: MapOf<MapOf<EObject>>,
                               entities: MapOf<EntityModel> ): MapOf<EObject> {
        const dynamicTypes = {};
        if ( !!e.links ) {
            const packageId = this.getPackageId( e.links );
            for ( const linkId in e.links ) {
                const link = e.links[linkId];
                if ( link.type === 'connectorBi' ) {
                    const eObject = eObjects[link.eDataId][link.entityId];
                    // what if 2 entities have same name
                    if ( !dynamicTypes.hasOwnProperty( eObject.get( 'name' ))
                        || this.getPackageId( entities[link.entityId].links ) === packageId ) {
                        dynamicTypes[eObject.get( 'name' )] = eObject;
                    }
                }
            }
        }
        return dynamicTypes;
    }

    private getPackageId( links: any ): string {
        if ( !!links ) {
            for ( const linkId in links ) {
                const link = links[linkId];
                if ( link.type === 'parent' && link.handshake === 'uml.package' ) {
                    return link.entityId;
                }
            }
        }
        return '__global__';
    }

    private getAttribute( attr, dynamicTypes ) {
        if ( attr.value.type.value ) {
            if ( EcoreUmlClass.eCoreClassMap.hasOwnProperty( attr.value.type.value )) {
                return EAttribute.create({
                    name: attr.value.name.value,
                    eType: EcoreUmlClass.eCoreClassMap[attr.value.type.value],
                });
            } else if ( dynamicTypes.hasOwnProperty( attr.value.type.value )) {
                // can include more info if the link contains more details.
                return EReference.create({
                    name: attr.value.name.value,
                    eType: dynamicTypes[attr.value.type.value],
                });
            } // else ignore type
        }
        return EAttribute.create({ name: attr.value.name.value });
    }

    private getOperation( op, dynamicTypes ) {
        const operation = EOperation.create({ name: op.value.name.value });
        if ( op.value.returnType.value.name.value ) {
            if ( EcoreUmlClass.eCoreClassMap.hasOwnProperty( op.value.returnType.value.name.value )) {
                operation.set( 'eType', EcoreUmlClass.eCoreClassMap[op.value.returnType.value.name.value]);
            } else if ( dynamicTypes.hasOwnProperty( op.value.returnType.value.name.value )) {
                operation.set( 'eType', dynamicTypes[op.value.returnType.value.name.value]);
            } // else ignore type
        }
        if ( op.value.parameters.value ) {
            Object.values( op.value.parameters.value ).forEach( param => {
                operation.get( 'eParameters' ).add( this.getParameter( param, dynamicTypes ));
            });
        }
        return operation;
    }

    private getParameter( param, dynamicTypes ) {
        if ( param.value.type.value ) {
            if ( EcoreUmlClass.eCoreClassMap.hasOwnProperty( param.value.type.value )) {
                return EParameter.create({
                    name: param.value.name.value,
                    eType: EcoreUmlClass.eCoreClassMap[param.value.type.value],
                });
            } else if ( dynamicTypes.hasOwnProperty( param.value.type.value )) {
                return EParameter.create({
                    name: param.value.name.value,
                    eType: dynamicTypes[param.value.type.value],
                });
            } // else ignore type
        }
        return EParameter.create({ name: param.value.name.value });
    }
}
