import { map } from 'lodash';
import { DiagramInfoModel } from './diagram-info.mdl';
import { AppConfig, enumerable } from 'flux-core';
import { SimpleShapeModel } from '../../shape/model/simple-shape.mdl';
import { ShapeLinkModel, ShapeLinkType } from '../../shape/model/shape-link.mdl';
import { filter, find } from 'lodash';
/**
 * DiagramVievableModel
 * This extends the features of DiagramInfoModel and contains
 * additional property to hold SVG data which needs to render
 * the graphics on the canvas in a diagram viewer. This purly
 * is for the purpose of viewing diagrams
 *
 * DEPRECATED FIELDS: Note that there are fields in this model that
 * is part of old Creately system and they will not be available and
 * used in new systems. Be careful about using them in the correct
 * environment.
 *
 * @author  thisun
 * @since   2016-06-07
 */
export class DiagramViewableModel extends DiagramInfoModel {

    /**
     * The description for the diagram
     */
    public description: string;

    /**
     * Canvas width of the diagram
     * @deprecated DEPRECATED FIELD
     */
    public canvasWidth: number;

    /**
     * Canvas height of the diagram
     * @deprecated DEPRECATED FIELD
     */
    public canvasHeight: number;

    /**
     * Represents all shape instances in the diagram
     * This does not include connectors
     */
    public shapes: { [shapeId: string]: SimpleShapeModel};

    /**
     * _svgImage is the html unescaped SVG string.
     * @deprecated DEPRECATED FIELD
     */
    private _svgImage: string;
    constructor( id: string, name: string, extension?: Object ) {
        super( id, name, extension );
    }

    /**
     * Generate the direct diagram link
     */
    @enumerable( true )
    public get directLink(): string {
        return `https://${AppConfig.appDomain}/diagram/${this.id}/`;
    }

    /**
     * Generate the community share link
     */
    @enumerable( true )
    public get communityLink() {
        if ( this.isPublic ) {
            return `http://${AppConfig.appDomain}/diagram/example/${this.id}`;
        }
    }

    /**
     * @deprecated DEPRECATED FIELD
     */
    @enumerable( true )
    public get svgImage (): string {
        return this._svgImage;
    }
    /**
     * This setter is used to unescape html
     * from the input SVG string.
     *
     * @param  string - HTML escaped string
     * @deprecated DEPRECATED FIELD
     */
    public set svgImage( value: string ) {
        const decodeElement = document.createElement( 'div' );
        decodeElement.innerHTML = value;
        this._svgImage = decodeElement.innerText;
    }

    /**
     * This retrieves all the links that are available on the shapes.
     *
     * @return  Array<ShapeLinkModel>   Return the links that are related to this diagram.
     */
    @enumerable( true )
    public get diagramLinks(): Array<ShapeLinkModel> {
        return this.getLinkedShapes().map(
            shape => shape.getLink(),
        );
    }

    /**
     * This returns the diagrm link based on the diagram state.
     * @deprecated DEPRECATED FIELD
     */
    @enumerable( true )
    public get diagramAccessLink(): string {
        if ( this.isPublic ) {
            return this.communityLink;
        } else {
            return this.directLink;
        }
    }

    /**
     * This returns the link count in this diagram
     */
    @enumerable( true )
    public get linkCount(): number {
        return this.diagramLinks.length;
    }

    /**
     * This function allows setting an escaped or unescaped svg string to the model.
     * If the svg xml need to be set without unescaping html (i.e. if the string
     * is not escaped), the decode parameter should be set to false.
     * @param value - svg xml to be set
     * @param decode - unescape html or not
     * @deprecated DEPRECATED FIELD
     */
    public setSvgImage( value: string, decode: boolean = true ) {
        if ( decode ) {
            this.svgImage = value;
        } else {
            this._svgImage = value;
        }
    }

    /**
     * Gets the shape model for a given id if
     * it exists.
     */
    public getShape( id: string ): SimpleShapeModel {
        if ( this.shapes ) {
            return this.shapes[id];
        }
    }

    /**
     * Indicate if a kobject with a given id exists
     * in the diagram
     */
    public hasShape( id: string ) {
        return ( this.getShape( id ) !== undefined );
    }

    /**
     * This method return true if the given id has shape link
     * @returns boolean
     */
    public hasLinkedShape( id: string ): boolean {
        const shape = find( this.shapes, s => s.id === id );
        return shape && shape.hasLink();
    }

    /**
     * This method retrieves all the ids of shapes with links
     * @returns {Array<string>} Id list of linked shape
     */
    public getLinkedShapeIdList(): Array<string> {
        return map( this.getLinkedShapes(), 'id' );
    }

    /**
     * This retrieves all diagram id from the shapes link where the shapes
     * are linked to another creately diagrams.
     *
     * @return Array<string>  Return the diagramIds that are are linked in this
     *                        diagram.
     */
    public getLinkedDiagramIdList(): Array<string> {
        return this.getLinkedDiagramLinks().map( link => link.linkedDiagramId );
    }

    /**
     * This retrieves all diagram links from the diagram link where the shapes
     * are linked to another creately diagrams.
     */
    public getLinkedDiagramLinks(): Array<ShapeLinkModel> {
        return this.diagramLinks.filter( link => link.shapeLinkType === ShapeLinkType.DIAGRAM );
    }

    /**
     * This retrieves all web links from the diagram link where the shapes
     * are linked to the regular web page.
     */
    public getWebLinks(): Array<ShapeLinkModel> {
        return this.diagramLinks.filter( link =>  link.shapeLinkType === ShapeLinkType.WEB );
    }

    /**
     * Indicates this diagram has links or not
     */
    public hasLinks(): boolean {
        return ( this.diagramLinks && this.diagramLinks.length > 0 );
    }

    /**
     * This retrieves all external web url from the web link
     */
    public getExternalLinks(): Array<string> {
        return this.getWebLinks().map( link => link.link );
    }

    /**
     * This method retrieves all shapes with link
     * @returns {Array<SimpleShapeModel>}
     */
    protected getLinkedShapes(): Array<SimpleShapeModel> {
        return filter( this.shapes, shape => shape.hasLink());
    }
}

Object.defineProperty( DiagramViewableModel, 'name', {
  writable: true,
  value: 'DiagramViewableModel',
});
