import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ExecuteShapeLogicMethodCommand
 *
 * Shape logic is built to contain hook functions that are bound to interactions
 * [eg: clickOnShape( state, changeModel, ...params ), hoverOverShape( state, changeModel, ...params ),
 * onShapeSwitch( state, changeModel, ...params ) ]
 *
 * These hooks are commonly available to all shapes via interfaces in the flux-definition
 * and are called by the framework to do shape-specific actions [eg: in the interaction handlers].
 *
 * Implementation in shapes is not required. However, all changes done in the logic
 * class that update the shape model require the changeModel that will save the changes
 * once the logic class hook completes.
 *
 * This command class is a common platform through which such hooks are run, executing a
 * predefined method passed in with its params as CommandData, along with the shape's change model.
 *
 */
@Command()
export class ExecuteShapeLogicMethod extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: { shapeInfo: {[shapeId: string]: { methodName: string, methodParams: Array<any> }} };

    /**
     * Inject the state service to get the list of selected shape ids.
     */
    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( ds );
    }

    public prepareData() {
        if ( !this.data || !this.data.shapeInfo ) {
            return;
        }
        const shapeIds = Object.keys( this.data.shapeInfo );
        shapeIds.forEach( shapeId => {
            const shapeData = this.data.shapeInfo[ shapeId ];
            const method = shapeData.methodName;
            const methodParams = shapeData.methodParams;
            const shapeChangeModel =  this.changeModel.shapes[ shapeId ];
            if ( shapeChangeModel && ( shapeChangeModel as any )[ method ]) {
                ( shapeChangeModel as any )[ method ]( this.state, shapeChangeModel, ...methodParams );
            }
        });
    }
}

Object.defineProperty( ExecuteShapeLogicMethod, 'name', {
    value: 'ExecuteShapeLogicMethod',
});
