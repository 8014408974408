import { Injectable } from '@angular/core';
import { DateFNS, StateService } from 'flux-core';
import { IEntityDef } from 'flux-definition/src';
import { DataStore } from 'flux-store';
import { UserLocator } from 'flux-user';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, startWith, switchMap, takeWhile } from 'rxjs/operators';
import { EDataLocatorLocator } from '../edata/locator/edata-locator-locator';
import { EDataModel } from '../edata/model/edata.mdl';
import { EntityModel } from '../edata/model/entity.mdl';
import * as TextDiff from 'text-diff';

/**
 * Shape/Entity history Item data.
 */
export interface IShapeHistoryItem {
    id: string;
    // title: string,
    oldValue: string | { key: string, translate: boolean };
    newValue: string | { key: string, translate: boolean };
    date: string;
    time: String;
    userName: string;
    type: string;
    fieldName: string;
    action: string;
}

/**
 * Shape history service which helps to fetch and manage shape/entity history data.
 */
@Injectable()
export class ShapeHistoryService {

    public static ENTITY_HISTORY: string = 'EntityHistory';

    protected textDiff: TextDiff;
    public constructor(
        private stateSvc: StateService<any, any>,
        private userLocator: UserLocator,
        private eDataLocatorLocator: EDataLocatorLocator,
        private dataStore: DataStore,
    ) {
        this.textDiff = new TextDiff({ timeout: 1, editCost: 6 });
    }

    /**
     * Returns cached changes
     * @param eDataId
     * @param entityId
     * @returns
     */
    public getShapeHistoryGroups( eDataId, entityId ): Observable<IShapeHistoryItem[]> {
        return this.stateSvc.changes( this.getStateKey( eDataId, entityId )) as Observable<IShapeHistoryItem[]>;
    }

    /**
     * Cache given entity list for given eDataId and entityId.
     * Before it caches it checks the actual change and extract the data to identify the change.
     * @param eDataId
     * @param entityId
     * @param entityChanges
     * @returns
     */
    public cacheShapeChanges( eDataId: string, entityId: string, entityChanges: any[]): Observable<boolean> {
        if ( entityChanges && entityChanges.length > 0 ) {
            return combineLatest([
                this.eDataLocatorLocator.getEData( eDataId ).pipe(
                    switchMap( locator => locator.getEDataModel()),
                    map( edataMdl => ({
                        entity: edataMdl.getEntity( entityId ),
                        dataDef: edataMdl.getEntityDataItems( entityId ),
                    })),
                ),
                this.getUptoDateChanges( eDataId, entityId, entityChanges ),
            ]).pipe(
                takeWhile(([ { entity } ]) => !!entity ),
                switchMap(([ { entity, dataDef }, entChanges ]) => {
                    if ( entityChanges && entityChanges[0]) {
                        const lastChange = entityChanges[0];
                        if ( lastChange.modifier?.$unset && lastChange.modifier.$unset[`entities.${entityId}`]) {
                            return EMPTY;
                        }
                    }
                    const obs = entChanges.map( change =>
                        this.userLocator.getUserInfo( change.userId ).pipe(
                            map( userInfo => {
                                const changeVal = this.getChangeValues( change, entityId, entity, dataDef );
                                return {
                                    id: change.id,
                                    oldValue: changeVal.oldValue,
                                    newValue: changeVal.newValue,
                                    date: DateFNS.format( change.serverTime, 'EEE, MMM dd yyyy' ),
                                    time: DateFNS.format( change.serverTime, 'hh:mm aaa' ),
                                    userName: userInfo.fullName,
                                    type: changeVal.type,
                                    fieldName: changeVal.fieldName,
                                    action: changeVal.action,
                                } as IShapeHistoryItem;
                            }),
                        ));
                    return combineLatest( obs ).pipe(
                        map( changes => {
                            const filteredChanges = changes.filter( historyItem =>
                                (( historyItem.newValue !== undefined && historyItem.newValue !== '' ) ||
                                        ( historyItem.oldValue !== undefined && historyItem.oldValue !== '' )) &&
                                        ( historyItem.newValue !== historyItem.oldValue ));
                            this.stateSvc.set( this.getStateKey( eDataId, entityId ), filteredChanges );
                            return true;
                        }),
                    );
                }),
            );
        }
        return of( true );
    }

    /**
     * Identifies the meta data about the change form given EData change.
     * @param change
     * @param entityId
     * @param entity
     * @returns Returns extracted change meta data.
     */
    protected getChangeValues( change: any, entityId: string, entity: EntityModel, dataDef: any ): any {
        try {
            const setModifier: any = change?.modifier?.$set;
            const setReverter: any = change?.reverter?.$set;
            const unsetModifier: any = change?.modifier?.$unset;
            const unsetReverter: any = change?.reverter?.$unset;
            let newVal;
            let oldVal;
            let fieldId;

            const entityDef: IEntityDef = entity.getDef();
            if ( setModifier ) {
                // Check "entities.entityId.data" first so that Other items will be deprioritise.
                const key = this.getEntityUpdateKey( setModifier, entityId );
                if ( key ) {
                    if ( key === `entities.${ entityId }` ) {
                        // Entity creation
                        return {
                            fieldId: 'ENTITY_CREATED',
                            newValue: { key: 'ENTITY_CREATED', translate: true },
                            oldValue: '',
                            type: 'ENTITY',
                            fieldName: '',
                            action: 'CREATE',
                        };
                    } else if ( key === `entities.${ entityId }.texts` ) {
                        // Shape text change
                        const texts: any[] = setModifier[ key ];
                        if ( texts ) {
                            newVal = this.htmlTextArrayToText( texts );
                            const reverterTexts = setReverter[ key ];
                            oldVal = this.htmlTextArrayToText( reverterTexts );
                            return {
                                fieldId: 'texts',
                                newValue: newVal,
                                oldValue: oldVal,
                                type: 'SHAPE_TEXT',
                                fieldName: '',
                                action: 'MODIFY',
                            };
                        }
                    } else if ( key.startsWith( `entities.${ entityId }.data` )) {
                        const keyArray = key.split( '.' );
                        if ( keyArray.length === 4 ) {
                            fieldId = keyArray[ 3 ];
                            newVal = setModifier[ key ];
                            oldVal = this.getOldValue( setReverter, unsetReverter, key );
                            const { type, label } = this.getFieldTypeAndLabel( fieldId, entityDef, dataDef );
                            if ( key === `entities.${ entityId }.data.description` ) {
                                // Notes text change
                                const notesHtmlText: string = setModifier[ key ];
                                if ( notesHtmlText ) {
                                    const reverterNotesHtmlText = setReverter[ key ];
                                    return this.getDescriptionDataChanges( reverterNotesHtmlText, notesHtmlText,
                                                                                                        fieldId );
                                }
                            }
                            if ( unsetReverter && unsetReverter[ `entities.${ entityId }.data.${fieldId}` ] === true ) {
                                // NOTE: this can be data added while sync to type operation as well.
                                // in that case label will be incorrect and should be derived from entity type.
                                const deletedLabelOnCreateField = setModifier ?
                                            setModifier[ `dataDefs.${ entityId }.${ fieldId }` ]?.label : 'DELETE';
                                return {
                                    fieldId: fieldId,
                                    newValue: deletedLabelOnCreateField,
                                    oldValue: '',
                                    type: type,
                                    fieldName: label !== 'DELETED' ? label : deletedLabelOnCreateField,
                                    action: 'ADD_NEW_FIELD',
                                };
                            }

                            if ( type === 'date' ) {
                                return {
                                    fieldId: fieldId,
                                    newValue: this.getDateInValueFormat( newVal ),
                                    oldValue: this.getDateInValueFormat( oldVal ),
                                    type: type,
                                    fieldName: label,
                                    action: 'MODIFY',
                                };
                            } else if ( this.isPrimitiveType( type, newVal )) {
                                // Field value changed( text )
                                return this.getPrimitiveFieldData( fieldId, label, type, oldVal, newVal );
                            } else if ( type === 'users' || newVal?.roleData?.roleType ) {

                                return Object.assign(
                                        this.getPeopleChangeData( newVal, oldVal, fieldId, label, entityDef ),
                                        { type });
                            } else if ( Array.isArray( newVal )) {
                                // Reference links
                                if ( Object.keys( setModifier )
                                        .find( k => k.startsWith( `entities.${ entityId }.links` ))) {
                                    return {
                                        fieldId: fieldId,
                                        newValue: { key: 'REFERENCE_ADDED', translate: true },
                                        oldValue: '',
                                        type: type,
                                        fieldName: label,
                                        action: 'ADD',
                                    };
                                } else if ( unsetModifier && Object.keys( unsetModifier ).find( k =>
                                                        k.startsWith( `entities.${ entityId }.links` ))) {
                                    return {
                                        fieldId: fieldId,
                                        newValue: '',
                                        oldValue: { key: 'REFERENCE_REMOVED', translate: true },
                                        type: type,
                                        fieldName: label,
                                        action: 'REMOVE',
                                    };
                                }

                                // Add or remove Tags
                                if ( type === 'tags' || Array.isArray( oldVal )) {

                                    return Object.assign(
                                            this.getTagsChangeData( newVal, oldVal, fieldId, label ),
                                            { type });
                                } else {
                                    const tagName = newVal.map( val => val.name ).join( ', ' );
                                    return {
                                        fieldId: fieldId,
                                        newValue: tagName,
                                        oldValue: '',
                                        type: type,
                                        fieldName: label,
                                        action: 'ADD',
                                    };
                                }
                            }
                        }
                    } else if ( key.startsWith( `entities.${ entityId }.shapes` )) {
                        // This is adding an exising entitie's shape from Database panel
                        return {
                            fieldId: 'SHAPE_ADDED',
                            newValue: { key: 'SHAPE_ADDED', translate: true },
                            oldValue: '',
                            type: 'SHAPE',
                            fieldName: '',
                            action: 'ADD',
                        };
                    }
                }
            } else if ( unsetModifier ) {
                return this.getFieldDeleteChangeData( entityId, unsetModifier, setReverter );
            }

        } catch ( e ) {
            // tslint:disable-next-line: no-console
            console.log( 'Shape history error: ', e );
            // TODO log error and continue, do not break in case of errors.
        }
        // FIXME: non of the changes should end up here. If this value isreturned that means,
        // the specific change is not handled properly and it it a bug.
        return {
            fieldId: undefined,
            newValue: 'newValue',
            oldValue: 'oldValue',
        };
    }

    protected getDescriptionDataChanges( oldHTMLText: string, newHTMLText: string, fieldId: string ) {
        const descriptionTextChange = this.getDescriptionTextChange( oldHTMLText, newHTMLText );
        if ( descriptionTextChange.added === '' &&
                ( descriptionTextChange.removed === '' || descriptionTextChange.removed === '/' )) {
            const addedOrRemovedImg = this.hasImageAddedOrRemoved( oldHTMLText, newHTMLText );
            if ( addedOrRemovedImg > 0 ) {
                // Image added
                return {
                    fieldId: fieldId,
                    newValue: { key: 'IMG_ADDED', translate: true },
                    oldValue: descriptionTextChange.removed,
                    type: 'DESCRIPTION_TEXT',
                    fieldName: '',
                    action: 'IMG_ADD',
                };
            } else if ( addedOrRemovedImg < 0 ) {
                // Image removed
                return {
                    fieldId: fieldId,
                    newValue: '',
                    oldValue: { key: 'IMG_REMOVED', translate: true },
                    type: 'DESCRIPTION_TEXT',
                    fieldName: '',
                    action: 'IMG_REMOVE',
                };
            }
            const addedOrRemovedVideo = this.hasVideoAddedOrRemoved( oldHTMLText, newHTMLText );
            if ( addedOrRemovedVideo > 0 ) {
                // Vedio added
                return {
                    fieldId: fieldId,
                    newValue: { key: 'VIDEO_ADDED', translate: true },
                    oldValue: descriptionTextChange.removed,
                    type: 'DESCRIPTION_TEXT',
                    fieldName: '',
                    action: 'VIDEO_ADD',
                };
            } else if ( addedOrRemovedVideo < 0 ) {
                // Vedio removed
                return {
                    fieldId: fieldId,
                    newValue: '',
                    oldValue: { key: 'VIDEO_REMOVED', translate: true },
                    type: 'DESCRIPTION_TEXT',
                    fieldName: '',
                    action: 'VIDEO_REMOVE',
                };
            }
        }
        return {
            fieldId: fieldId,
            newValue: descriptionTextChange.added.trim(),
            oldValue: descriptionTextChange.removed.trim(),
            type: 'DESCRIPTION_TEXT',
            fieldName: '',
            action: 'MODIFY',
        };
    }

    /**
     * Merge new changes to the entity change list and returns the updated list of changes.
     * @param eDataId
     * @param entityId
     * @param entityChanges
     * @returns
     */
    protected getUptoDateChanges( eDataId: string, entityId: string, entityChanges: any[]): Observable<any[]> {

        return this.eDataLocatorLocator.getEData( eDataId ).pipe(
            switchMap( locator => locator.getEDataChanges()),
            switchMap(() => this.dataStore.getModelStore( EDataModel ).findChanges({ modelId: eDataId })),
            map( eDataChanges => {
                if ( eDataChanges && eDataChanges.length > 0 ) {
                    const lastEntityChangeTime = entityChanges && entityChanges.length > 0 ?
                                                    entityChanges[ 0 ].serverTime : 0;
                    eDataChanges.filter( eDataChange => eDataChange.clientTime > lastEntityChangeTime )
                                .sort(( a, b ) => a.clientTime - b.clientTime )
                                .forEach( eDataChange => {
                                    if ( !eDataChange.userId ) {
                                        eDataChange.userId = this.stateSvc.get( 'CurrentUser' );
                                    }
                                    if ( !eDataChange.serverTime ) {
                                        eDataChange.serverTime = eDataChange.clientTime;
                                    }
                                    if ( this.hasEntityChange( entityId, eDataChange ) &&
                                            entityChanges.findIndex( c => c.id === eDataChange.id ) < 0 ) {
                                        entityChanges.unshift( eDataChange );
                                    }
                                });
                }
                return entityChanges;
            }),
            startWith( entityChanges ),
        );
    }

    protected hasEntityChange( entityId: string, eDataChange: any ): boolean {
        return (( eDataChange.modifier?.$set && Object.keys( eDataChange.modifier?.$set )
                                            .findIndex( key => key.startsWith( `entities.${entityId}` )) >= 0 ) ||
                ( eDataChange.modifier?.$unset && Object.keys( eDataChange.modifier?.$unset )
                                            .findIndex( key => key.startsWith( `entities.${entityId}` )) >= 0 ));
    }

    /**
     * Identifies the changes from given description texts and return the actual change.
     */
    protected getDescriptionTextChange( oldTexts, newTexts ) {
        const oldVal = this.htmlTextToSimpleText( oldTexts );
        const newVal = this.htmlTextToSimpleText( newTexts );
        const diffs: any[][] = this.textDiff.main( oldVal, newVal );
        const removedArr: string[] = [];
        const addedArr: string[] = [];
        this.textDiff.cleanupSemantic( diffs );
        this.textDiff.cleanupEfficiency( diffs );
        diffs.forEach( diff => {
            if ( diff[ 1 ].trim() !== '' ) {
                if ( diff[ 0 ] === -1 ) {
                    removedArr.push( diff[ 1 ]);
                } else if ( diff[ 0 ] === 1 ) {
                    addedArr.push( diff[ 1 ]);
                }
            }
        });
        return {
            added: addedArr.join( '   |   ' ).trim().replace( /\n+/g, ',' ),
            removed: removedArr.join( '   |   ' ).trim().replace( /\n+/g, ',' ),
        };
    }

    /**
     * Retrieves old value of the field( Value on the field before appling the change )
     * @param setReverter
     * @param unsetReverter
     * @param key
     * @returns
     */
    protected getOldValue( setReverter: any, unsetReverter: any, key: string ) {
        return ( setReverter && setReverter[ key ]) ? setReverter[ key ] :
                    ( unsetReverter && unsetReverter[ key ] ? unsetReverter[ key ] : undefined );
    }

    /**
     * Format the date to display on the values of the changes.
     * @param ts
     * @returns
     */
    protected getDateInValueFormat( ts: number ) {
        return !!ts ? DateFNS.format( ts, 'dd/MM/yyyy' ) : '';
    }

    /**
     * Identifies and returns the type of a field or value only if the type is premitive.
     * Object types can not be idenfied by this function.
     * @param type
     * @param val
     * @returns
     */
    protected isPrimitiveType( type: string, val: any ) {
        return ( type === 'number' || type === 'string' || type === 'binary' ||
                    typeof val === 'string' || val instanceof String || typeof val === 'boolean' ||
                    typeof val === 'number' );
    }

    /**
     * Identifies and returns the key which is used to identify the specific field to update.
     * @param setModifier
     * @param entityId
     * @returns
     */
    protected getEntityUpdateKey( setModifier: any, entityId: string ): string {
        return Object.keys( setModifier ).find( k => ( k.startsWith( `entities.${ entityId }.data.title` ))) ||
                Object.keys( setModifier ).find( k => ( k.startsWith( `entities.${ entityId }.texts` ))) ||
                Object.keys( setModifier ).find( k => ( k.startsWith( `entities.${ entityId }.data` ))) ||
                Object.keys( setModifier ).find( k => ( k.startsWith( `entities.${ entityId }` )));
    }

    /**
     * Identifies and returns the type and labe of a field from either entityDef or dataDef.
     * @param fieldId
     * @param entityDef
     * @param dataDef
     * @returns
     */
    protected getFieldTypeAndLabel( fieldId: string, entityDef: IEntityDef, dataDef: any ):
                                                                { type: string, label: string } {
        return ( entityDef && entityDef.dataItems && entityDef.dataItems[ fieldId ] &&
                                                                entityDef.dataItems[ fieldId ]?.type ) ?
                    ({ type: entityDef.dataItems[ fieldId ].type, label: entityDef.dataItems[ fieldId ].label }) :
                    (( dataDef && dataDef[ fieldId ] && dataDef[ fieldId ].type ) ?
                            ({ type: dataDef[ fieldId ].type, label: dataDef[ fieldId ].label }) :
                            ({ type: 'DELETED', label: 'DELETED' }));
    }

    /**
     * Generates pemitive change data from given parameters.
     * @param fieldId
     * @param label
     * @param type
     * @param oldVal
     * @param newVal
     * @returns
     */
    protected getPrimitiveFieldData( fieldId: string, label: string, type: string, oldVal, newVal ) {
        let action = 'MODIFY';
        if ( type === 'number' ) {
            newVal = isNaN( newVal ) || newVal === null || newVal === undefined ? '' : newVal;
            oldVal = isNaN( oldVal ) ? '' : oldVal;
            action = newVal === '' ? 'REMOVE' : 'MODIFY';
        } else if ( type === 'string' ) {
            action = !newVal || newVal.trim() === '' ? 'REMOVE' : 'MODIFY';
        }
        return {
            fieldId: fieldId,
            newValue: newVal,
            oldValue: oldVal,
            type: type,
            fieldName: label,
            action: action,
        };
    }

    /**
     * Get Users/Assignees change data from given values.
     * @param newVal
     * @param oldVal
     * @param fieldId
     * @param entityDef
     * @returns
     */
    protected getPeopleChangeData( newVal, oldVal, fieldId, fieldName: string, entityDef: IEntityDef ): any {
        if ( Array.isArray( newVal?.people )) {
            if ( Array.isArray( oldVal?.people )) {
                let name = '';
                if ( newVal.people.length > oldVal.people.length ) {
                    name = ( newVal.people as any[]).find( ppl =>
                            !(( oldVal.people as any[]).find( oPpl => oPpl.id === ppl.id )),
                        ).fullName;
                    return {
                        fieldId: fieldId,
                        newValue: name,
                        oldValue: '',
                        type: 'users',
                        fieldName: fieldName,
                        action: ( entityDef.dataItems[ fieldId ] as any )?.taskId ?
                                    'TASK_ADD' : 'ADD',
                    };
                } else if ( newVal.people.length < oldVal.people.length ) {
                    name = ( oldVal.people as any[]).find( ppl =>
                            !(( newVal.people as any[]).find( oPpl => oPpl.id === ppl.id )),
                        ).fullName;
                    return {
                        fieldId: fieldId,
                        newValue: '',
                        oldValue: name,
                        type: 'users',
                        fieldName: fieldName,
                        action: ( entityDef.dataItems[ fieldId ] as any )?.taskId ?
                                    'TASK_REMOVE' : 'REMOVE',
                    };
                }
            }
            // TODO This need to be identified as Task create based on the fieldType.
        }
        return {
            fieldId: fieldId,
            newValue: '',
            oldValue: '',
            type: 'users',
            fieldName: fieldName,
            action: 'UPDATE',
        };
    }

    /**
     * Get Tags change data from given values.
     * @param newVal
     * @param oldVal
     * @param fieldId
     * @param entityDef
     * @returns
     */
    protected getTagsChangeData( newVal: any[], oldVal: any[], fieldId: string, fieldName: string ): any {
        if ( !oldVal || oldVal.length === 0 ) {
            const addedTags = newVal?.map( v => v.name ).join( ', ' );
            return {
                fieldId: fieldId,
                newValue: addedTags,
                oldValue: '',
                type: 'tags',
                fieldName: fieldName,
                action: 'ADD',
            };
        } else {
            const { added, removed } = this.getTagDiffs( newVal, oldVal );
            if ( !newVal || newVal.length === 0 ) {
                return {
                    fieldId: fieldId,
                    newValue: added,
                    oldValue: removed,
                    type: 'tags',
                    fieldName: fieldName,
                    action: 'REMOVE',
                };
            }
            return {
                fieldId: fieldId,
                newValue: added,
                oldValue: removed,
                type: 'tags',
                fieldName: fieldName,
                action: 'MODIFY',
            };
        }
    }

    /**
     * Extract the change data for field delete.
     * @param entityId
     * @param unsetModifier
     * @param setReverter
     * @returns
     */
    protected getFieldDeleteChangeData( entityId, unsetModifier, setReverter ) {
        const unsetKey = this.getEntityUpdateKey( unsetModifier, entityId );
        if ( unsetKey.startsWith( `entities.${ entityId }.data` )) {
            const unsetKeyArray = unsetKey.split( '.' );
            if ( unsetKeyArray.length === 4 ) {
                const fieldId = unsetKeyArray[ 3 ];
                if ( unsetModifier[  `dataDefs.${ entityId }.${ fieldId }` ] === true ) {
                    const deletedFieldLable = setReverter ? setReverter[ `dataDefs.${ entityId }.${ fieldId }` ].label : 'DELETE';
                    return {
                        fieldId: fieldId,
                        newValue: '',
                        oldValue: deletedFieldLable,
                        type: 'DELETED',
                        fieldName: deletedFieldLable,
                        action: 'DELETE',
                    };
                }
            }
        }
    }

    /**
     * Finds the difference from given new and old tags array.
     * @param newTags
     * @param oldTags
     * @returns
     */
    protected getTagDiffs( newTags: any[], oldTags: any[]): { added: string, removed: string } {
        const added = newTags.filter( tag1 =>
            !( oldTags.find( tag2 => tag2.name === tag1.name ))).map( tag => tag.name ).join( ', ' );
        const removed = oldTags.filter( tag1 =>
            !( newTags.find( tag2 => tag2.name === tag1.name ))).map( tag => tag.name ).join( ', ' );
        return { added, removed };
    }

    protected getStateKey( eDataId, entityId ): string {
        return `${ShapeHistoryService.ENTITY_HISTORY}-${eDataId}-${entityId}`;
    }

    /**
     * Extract and returns the texts in between HTML text of array and merges them
     * @param texts
     * @returns
     */
    protected htmlTextArrayToText( texts: any[]): string {
        return texts?.length > 0 ? texts.map( val => val.text.value ).join( ' ' ).replace( /<[^>]+>/g, '' ) : '';
    }

    /**
     * Extract and returns simple text in between HTML text.
     * @param htmlText
     * @returns
     */
    protected htmlTextToSimpleText( htmlText: string ): string {
        return !!htmlText ? htmlText.replace( /<\/li><li>/g, '\n' ).replace( /<[^>]+>/g, ' ' ) : '';
    }

    /**
     * Checks if there an image added or removed based on given old and new HTML string
     *
     * @param oldVal
     * @param newVal
     * @returns
     */
    protected hasImageAddedOrRemoved( oldVal: string, newVal: string ) {
        const oldImgCount = ( oldVal.match( /<img src/g ) || []).length;
        const newImgCount = ( newVal.match( /<img src/g ) || []).length;
        return newImgCount - oldImgCount;
    }

    /**
     * Checks if there a vedio added or removed based on given old and new HTML string
     *
     * @param oldVal
     * @param newVal
     * @returns
     */
    protected hasVideoAddedOrRemoved( oldVal: string, newVal: string ) {
        // TODO: Adding video is identified using the span element and data-type property of
        // the span. Fix it by finding the write element to identity add/remove video change.
        const oldImgCount = ( oldVal.match( /<span data-type="fileEmbedNode"/g ) || []).length;
        const newImgCount = ( newVal.match( /<span data-type="fileEmbedNode"/g ) || []).length;
        return newImgCount - oldImgCount;
    }
}
