import { Injectable } from '@angular/core';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { Observable } from 'rxjs';
import { DiagramSub } from '../subscription/diagram.sub';
import { Command, CommandInterfaces } from 'flux-core';
import { AbstractCommand } from 'flux-core';
import { switchMap, filter, take, tap } from 'rxjs/operators';

/**
 * Command for starting diagram subscription when other command required diagram data.
 * Starts user info subscriptions for each collaborator of the diagram.
 * FIXME: Refactor. This is a duplicate of start diagram subcription command,
 * This should not ideally exist, but navigation part of the InitializationCommands
 * need to be separate command and should called after the command is execute
 * @author  Mehdhi
 * @since   201-02-02
 */
@Injectable()
@Command()
export class FetchDiagram extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor( protected modelSubManager: ModelSubscriptionManager ) {
        super()/* istanbul ignore next */;
    }

    public execute(): Observable<any> {
        this.resultData = this.data;
        return this.modelSubManager.start( DiagramSub, this.resourceId ).pipe(
            switchMap( sub => sub.status ),
            filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
            take( 1 ),
            tap( statusSubject => {
                if ( statusSubject.subStatus === SubscriptionStatus.errored ) {
                    this.log.error( 'There was an error starting the diagram subscription : ' + statusSubject.error );
                }
            }),
        );
    }
}

Object.defineProperty( FetchDiagram, 'name', {
    value: 'FetchDiagram',
});
