import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractNotification } from 'flux-core';

/**
 * MarketingNotification Component
 *
 * @author sajeeva
 * @since 2023-11-28
 */
@Component({
    selector: 'marketing-notification',
    styleUrls: [ './marketing-notification.cmp.scss' ],
    templateUrl: './marketing-notification.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingNotification extends AbstractNotification {

    constructor (
        protected sanitizer: DomSanitizer,
    ) {
        super( sanitizer );
    }
}
