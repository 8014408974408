import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';

/**
 * class LineCanvasInstructionFactory
 * Generate canvas instruction for svg line element instructions
 */
export class LineCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'line';

    /**
     * Return line canvas instructions for given line shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        const x1 = data.data.x1 ? +data.data.x1 : 0;
        const x2 = data.data.x2 ? +data.data.x2 : 0;
        const y1 = data.data.y1 ? +data.data.y1 : 0;
        const y2 = data.data.y2 ? +data.data.y2 : 0;
        const instructions = [
            { instruction: 'moveTo', params: [ x1, y1 ]},
            { instruction: 'lineTo', params: [ x2, y2 ]},
        ];
        return this.applyTransformationsForMoveAndLines( instructions, data.transform );
    }

}
