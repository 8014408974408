import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { tap } from 'rxjs/operators';

/**
 * Adds an entity to a shape.
 * Also checks if the document has a reference to the model, if not adds that as well.
 *
 * This usually happens in one of the follwing situations
 * 1. an entity is drag dropped in to the canvas and a shape is created from that
 * entity
 *
 * 2. a eDataTrigger=true shape is dropped to the canvas and an edataModel is active
 *
 * 3. a shape with eData present is added to a container context which will add additional parameters
 * to the containing shape dataItems which in turn will reflect on an EData model. This is the situation when
 * a single shape will have multiple EData references.
 *
 * 4. A new entity type is created. We create a new entity and bind it to the shape that the
 * type created from.
 *
 */
@Injectable()
@Command()
export class AddEntitiesToShapes extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        eDataId: string,
        entityIds: string[],
        entityDefId?: string,
    };


    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected mcu: ModelChangeUtils,
        protected ds: DiagramChangeService,
        private ell: EDataLocatorLocator ) {
        super( ds ) /** istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData() {
        return this.ell.getEDataModel( this.data.eDataId ).pipe(
            tap( eModel => {
                this.data.entityIds.forEach(( entityId, idx ) => {
                    this.mcu.addEntityToShape( this.changeModel, {
                        shapeId: this.data.shapeIds[idx],
                        eDataId: this.data.eDataId,
                        entityId: entityId,
                        entityDefId: this.data.entityDefId,
                        eDataDefId: eModel.defId,
                        data: eModel.getEntityDataItems( entityId, true, true ),
                    });
                });
            }),
        );
    }
}

Object.defineProperty( AddEntitiesToShapes, 'name', {
    value: 'AddEntitiesToShapes',
});
