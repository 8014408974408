import { AbstractModel } from 'flux-core';
/**
 * UserPermModel
 * This holds information about a given permission.
 * Users can have multiple permissions. All permissions a user can have are defined
 * by their plan.
 *
 * @author  Ramishka
 * @since   2018-01-09
 */

export class UserPermModel extends AbstractModel {

    /**
     * Name of the feature. Permission will allow or restrict users
     * to use this feature.
     */
    public feature: string;

    /**
     * Check if this user is allowed to use this permission.
     */
    public allowed: boolean;

    /**
     * Maximum number of usage allowed for this permission.
     * Value 0 means user don't have this permission.
     * Value -1 means user can use this permission unlimited time.
     * Other values defines to allow this permission only limited time defined by the number.
     */
    public maxUsage: number;

    /**
     * Current usage count of the permission.
     */
    public usedCount: number;

}

Object.defineProperty( UserPermModel, 'name', {
  writable: true,
  value: 'UserPermModel',
});
