import { Component, Input,
    ChangeDetectionStrategy, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';

import { Shape } from '@creately/createjs-module';
import { StaticRenderer } from 'flux-diagram-composer';
import { Subscription } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { Theme } from '../../../framework/ui/theme';
import { merge } from 'lodash';
import { Rectangle, StateService } from 'flux-core';
import { tap } from 'rxjs/operators';
import { IStyle, FillStyle } from 'flux-definition';
import { RetinaStageSimplified } from '../../../framework/easeljs/retina-stage-simplified';
import { Router } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'thumbnail-text',
    template: `<div class="thumbnail-text">
                <div class="thumbnail-text-header">
                    <svg *ngIf="icon" class="nu-icon xmedium-grey">
                        <use [attr.xlink:href]="iconHref"></use>
                    </svg>
                    <div class="thumbnail-text-header-names">
                        <div class="thumb-text-container" [class.preview]="isPreview" >
                            {{shapeName}}
                        </div>
                        <div class="thumb-text-container" [class.preview]="isPreview" >
                            <label>{{className}}</label>
                        </div>
                    </div>
                </div>
                <div class="collapsable-items">
                    <collapsable-edata-library-item
                        *ngFor="let item of itemInfo"
                        [label]="item.text.title"
                        [iconName]="item.icon"
                        [shapeCounts]="item.text.shapeCount"
                        [children]="item.children">
                    </collapsable-edata-library-item>
                </div>
               </div>`,
    styleUrls: [ './thumbnail-text.scss' ],
})
export class ThumbnailText implements AfterViewInit, OnDestroy, OnChanges {

    @Input() public shapetype: string;

    /**
     * The definition id of the shape
     */
    @Input() public name: string;

    /**
     * The definition id of the shape
     */
    @Input() public icon: string;

    /**
     * The version of the definiton of the shape
     */
    @Input() public itemInfo: number;

    /**
     * To handle hover event
     */
    @Input() public focus: boolean;

/*
     * The definition id of the shape
     */
    @Input() public defId: string;
    /**
     * The version of the definiton of the shape
     */
    @Input() public version: number;
    /**
     * The style based on which the thumnail must draw
     */
    @Input() public style: IStyle;

    /**
     * Preview is used to define a seperate canvas id for RetinaStage.
     */
    @Input() public isPreview: boolean = false;

    @Input()
    public canvasIdPrefix: string = 'thumb';

    /**
     * Subject that is used to show and hide the loading indicator.
     */
    public loading: BehaviorSubject<boolean>;

    /**
     * Subject that is used to handle hover in, out and pop over preview
     */
    public focusSubject: BehaviorSubject<boolean>;

    protected canvas: RetinaStageSimplified;
    protected thumbnail: Shape;
    protected subs: Subscription[];

    constructor( protected rendered: StaticRenderer,
                // private resourceLoader: ResourceLoaderDynamic,
                 protected state: StateService<any, any>,
                // protected commandService: CommandService,
                // protected modalController: ModalController,
                // protected dialogBoxController: DialogBoxController,
                // protected rolePermService: RolePermService,
                 protected router: Router,
                // protected random: Random,
                // protected logger: Logger,
                // protected planPermManager: PlanPermManager,
                // protected documentManage: DocumentManage,
                // protected elementRef: ElementRef,
                // protected modelSubManager: ModelSubscriptionManager
        ) {
        this.loading = new BehaviorSubject( true );
        this.focusSubject = new BehaviorSubject( false );
        this.subs = [];
    }

    /**
     * Identifier for the canvas element of this thumnail
     * If the same id is used it will draw on same canvas
     */
    public get canvasId(): string {
        return `${this. canvasIdPrefix}-${this.defId}-${this.version}`;
    }

    /**
     * Returns the xlink:href for the use tag
     */
    public get iconHref() {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.icon;
    }

    public get shapeName() {
        return this.name.split( ':' )[0];
    }

    public get className() {
        return this.name.split( ':' )[1];
    }

    public ngAfterViewInit() {
        // this.canvas = new RetinaStageSimplified( this.canvasId );
        // this.canvas.setupStage();
        // this.canvas.preventSelection = false;
        // this.canvas.enableDOMEvents( false );

        // this.thumbnail = new Shape();
        // this.canvas.addChild( this.thumbnail );
        // this.focusSubject.next( this.focus );
        // this.subs.push(
        //     this.focusSubject.pipe( switchMap( hover => this.draw( hover ).pipe(
        //         // FIXME : 0 Delay is added because without the delay the template is not updating sometimes.
        //         delay( 0 ),
        //         tap({ complete: () => {
        //             this.loading.next( false );
        //         }}),
        //     ))).subscribe(),
        // );
    }

    /**
     * When focus or defId being set by the component which is being used, thumbnail has to
     * be re drawn.
     * Change in focus is used to show the hover effect of the library tile.
     * Change in defId is used to update the preview of the library.
     */
    ngOnChanges( changes: SimpleChanges ) {
        // if (( changes.focus && !changes.focus.firstChange ) || ( changes.defId && !changes.defId.firstChange )) {
        //     this.focusSubject.next( this.focus );
        // }
    }

    public ngOnDestroy() {
        if ( this.canvas ) {
            this.canvas.destroy();
        }
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    // EData Panel changes

    public handleClick( docId: string ) {
        if ( this.state.get( 'CurrentDiagram' ) !== docId ) {
            this.router.navigate([ '../', docId, 'edit' ]);
            this.state.set( 'CurrentDiagram', docId );
        }
    }

    /**
     * Draws the thumbnail based on given hover status. Considers the current style
     */
    protected draw( hover: boolean = false ): Observable<any> {
        const graphics = this.thumbnail.graphics;
        const bounds = new Rectangle( 5 , 5 , 70, 70 );
        const styles = this.getStyles();
        if ( hover ) {
            styles.lineColor = Theme.primarySelectionColor;
        }
        if ( !this.isPreview ) {
            styles.lineThickness = styles.lineThickness * 1.4;
        }
        graphics.clear();
        graphics.beginStroke( styles.lineColor );
        graphics.setStrokeStyle( styles.lineThickness );
        graphics.beginFill( styles.fillColor );
        return this.rendered.renderThumbnail( graphics, this.defId, this.version, bounds ).pipe(
            tap({
                complete: () => {
                    graphics.endFill();
                    graphics.endStroke();
                    this.canvas.update();
                },
            }),
        );
    }

    /**
     * Updates the style object attached with missing default values and
     * returns a usable style object
     */
    protected getStyles(): IStyle {
        if ( !this.style ) {
            this.style = <any>{};
        }
        return merge({
            fillStyle: FillStyle.Solid,
            fillColor: Theme.defaultShapeFillColor,
            fillAlpha: 1,
            lineAlpha: 1,
            lineColor: Theme.defaultShapeThumbnailLineColor,
            lineThickness: Theme.defaultShapeLineThickness,
        }, this.style );
    }
}
