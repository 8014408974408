import { of, Observable } from 'rxjs';
import { isObject, isArray, isEmpty, isFunction } from 'lodash';
import { ExecutionStep } from './execution-step';
import { Deserializer } from '../../model/deserializer';

/**
 * DeserializableExecutionStep is an abstract step class which
 */
export abstract class DeserializableExecutionStep extends ExecutionStep {
    /**
     * getCommandClass gets the class (constructor) of current command instance.
     */
    protected getCommandClass(): any {
        return Object.getPrototypeOf( this.command ).constructor;
    }

    /**
     * getResultType gets the value from resultType method from the command class.
     */
    protected getResultType(): any {
        const commandClass = this.getCommandClass();
        return commandClass.resultType;
    }

    /**
     * isResultTypeDeserializable checks whether data can be deserialized into
     * given structure. The structure has to be a class or a template. This is used
     * to check whether the payload is a model type or not.
     */
    protected isResultTypeDeserializable(): boolean {
        const resultType = this.getResultType();
        return resultType && isObject( resultType );
    }

    /**
     * doesPayloadNeedtoBeDeserialized checks whether the payload needs deserialization.
     * The payload may not need to be deserialized if the payload is empty or when
     * the result type is empty (eg: deserialize([]) => []).
     * @param payload: javascript object or array to convert to models
     */
    protected doesPayloadNeedtoBeDeserialized( payload: any ): boolean {
        if ( !isObject( payload )) {
            return false;
        }
        if ( isArray( payload ) && isEmpty( payload )) {
            return false;
        }
        const resultType = this.getResultType();
        if ( !resultType ) {
            return false;
        }
        if ( !isFunction( resultType ) && isEmpty( resultType )) {
            return false;
        }
        return true;
    }

    /**
     * deserialize deserializes given data into the class or template given
     * by resultType function in the command class.
     * @param payload: javascript object or array to convert to models
     */
    protected deserialize( payload: any ): Observable<any> {
        if ( !this.doesPayloadNeedtoBeDeserialized( payload )) {
            return of( payload );
        }
        const resultType = this.getResultType();
        const decoder = Deserializer.create( payload );
        return decoder.structure( resultType ).build();
    }
}
