import { ITextNode, ITextStructuralNode } from '../svg-node/text-node.i';
import { IShapeText, ITextContent, DEFUALT_TEXT_STYLES, TextAlignment } from 'flux-definition';
import { CarotaUtils } from '../../../framework/text/carota-utils';
import { Random, Matrix } from 'flux-core';

/**
 * TextContentFactory which helps to generates text contents from the text data which
 * are extracted from any svg images.
 */
export class SvgTextContentFactory {

    /**
     * Extracts and returns text and their formating data from given list of text data
     * which are extracted from svg. Extracted data will have the relative location
     * based on the svg.
     * @param data
     */
    public createTextContents( nodes: ITextStructuralNode[], svgWidth: number, svgHeight: number ): IShapeText[] {
        let texts: IShapeText[];
        if ( nodes && nodes.length > 0 ) {
            texts = [];
            nodes.forEach( node => {
                const text: IShapeText = <IShapeText>{
                    alignY: TextAlignment.negative,
                    wordWrap: false,
                    width: node.data.width,
                    height: node.data.height,
                    primary: texts.length === 0,
                    id: this.generateTextId( texts ),
                    editable: true,
                    customCreated: false,
                };
                this.extractTextContents( node, text );
                text.value = CarotaUtils.convertToHTML( text.content );
                this.addTextPosition( node, text, svgWidth, svgHeight );
                texts.push( text );
            });

        }
        return texts;
    }

    /**
     * Extract the relative location and the rotation of the text from given svg text.
     * If the text is aligned center then find the relative position of center of the
     * text.
     * @param node Extracted SVG text data.
     * @param text Shape text data.
     * @param svgWidth Width of the svg.
     * @param svgHeight Height of the svg.
     */
    protected addTextPosition( node: ITextStructuralNode, text: IShapeText, svgWidth: number, svgHeight: number ) {
        // Tranformation with in the text element is ignored for the moment.
        // This is moving the text few more pixel lower than actual position.
        // this.finalizeTransformation( node );
        let matrix = node.transform ? node.transform : new Matrix();
        // If the alignment is center then find the center of the text as location.
        if ( text.content && text.content[ 0 ] && text.content[ 0 ].align === 'center' && node.data.width ) {
            matrix = <Matrix>matrix.clone().appendMatrix( new Matrix().translate( node.data.width / 2, 0 ));
        }
        const transform = matrix.toTransform();
        text.xType = 'relative';
        text.x = transform.x / svgWidth;
        text.yType = 'relative';
        text.y = transform.y / svgHeight;
        text.angle = transform.angle;
    }

    /**
     * Extracts and returns text contents from given list of text nodes. This
     * also consider the text position in SVG and adds the line breaks when "y"
     * location of the text is different than previous text content's "y"
     * location.
     * Text alignment is predicted based on the "x" lecation of each line.
     * @param textNodes List of text nodes which have the data.
     */
    protected extractTextContents( node: ITextStructuralNode, shapeText: IShapeText ) {
        let length;
        const textNodes = node.children;
        if ( textNodes && ( length = textNodes.length ) > 0 ) {
            const texts: ITextContent[] = [];
            const xStart = textNodes[ 0 ].data.x;
            let align: 'left' | 'center' | 'right' |  'justify' = 'left';
            // Generate the text conete for each children in given strctual node and add them to given shape text.
            textNodes.forEach(( textContent, index ) => {
                const extText = this.getTextContent( textContent );
                texts.push( extText );
                // If "y" cordinate of this text and the next text are different then add a new line to this text.
                if ( index < length - 1 && textContent.data.y
                        && textContent.data.y !== textNodes[ index + 1 ].data.y ) {
                    extText.text = extText.text + '\n';
                    // If the x cordinate of the new line is different then set the alignment as center.
                    if ( xStart && xStart !== textNodes[ index + 1 ].data.x ) {
                        align = 'center';
                    }
                } else {
                    extText.text += ' ';
                }
            });
            this.setAlignment( texts, align );
            shapeText.content = texts;
        }
    }

    /**
     * Sets given alignment value to all the text contents in given array.
     * @param textContents Text contents to set the alignment.
     * @param align Aligentment value to set.
     */
    protected setAlignment( textContents: ITextContent[], align: 'left' | 'center' | 'right' |  'justify' ) {
        textContents.forEach( textContent => textContent.align = align );
    }

    /**
     * Extracts and returns text content from given text node.
     * @param node Text node which has the data.
     */
    protected getTextContent( node: ITextNode ): ITextContent {
        return {
            text: node.text || '',
            size: CarotaUtils.convertToPoint( node.fontSize ),
            font: node.fontFamily,
            color: node.fill,
            bold: ( node.fontWeight === 'bold' || node.fontWeight === 'bolder' ),
            italic: ( node.fontStyle === 'italic' ),
            underline: ( node.textDecoration === 'underline' ),
            strikeout: ( node.textDecoration === 'line-through' ),
            align: DEFUALT_TEXT_STYLES.align,
            script: ( node.baselineShift ? node.baselineShift : 'normal' ),
        };
    }

    /**
     * Finalize the transformation. Transformation of the first text element is added as
     * the transformation of given text object if the first text element has different
     * transformation than given text object.
     * @param node Text structural node.
     */
    protected finalizeTransformation( node: ITextStructuralNode ) {
        if ( node.children && node.children.length > 0 ) {
            // If entire text transformation is different than its first text's transformation then
            // get the first text's transformation as entire text transformation.
            if ( node.transform.tx !== node.children[ 0 ].transform.tx
                || node.transform.ty !== node.children[ 0 ].transform.ty ) {
                node.transform = node.children[ 0 ].transform;
            }
        }
    }

    /**
     * Generates an id for the text. If a generated id is already used in given list of
     * texts then it generates another one.
     * @param texts List of availabe text to check if the new id is already used or not.
     */
    protected generateTextId( texts: IShapeText[]): string {
        const id = Random.textId();
        if ( texts && texts.length > 0 ) {
            if ( texts.find( text => text.id === id )) {
                return this.generateTextId( texts );
            }
        }
        return id;
    }
}
