import { AbstractModel } from 'flux-core';
import { DataStore } from 'flux-store';
import { AbstractSubscription, SubscriptionStatus } from 'flux-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PresentationModel } from './model/presentation.mdl';


/**
 * Subscription to fetch presentations for a user. This subscription will fetch
 * presentation models but the resourceId will be the current project's resource id.
 */
export class PresentationSub extends AbstractSubscription {
    constructor ( public resourceId: string, protected datastore: DataStore ) {
        super()/* istanbul ignore next */;
    }


    /**
     * The subscription name (should be the same as in the server).
     */
    public get subscription(): string {
        return 'Presentation';
    }

    /**
     * The model type associated with this subscription.
     */
    public get modelType(): typeof AbstractModel {
        return PresentationModel;
    }

    /**
     * This timestamp will be used to identify which tasks to fetch from the server.
     */
    public getLatestSyncedTime(): Observable<number> {
        const selector = { id: this.resourceId };
        const options: any = { sort: { lastUpdated: -1 }};
        return this.datastore.findOneLatest( PresentationModel, selector, options ).pipe(
            map( model => ( model ? model.lastUpdated : null )),
        );
    }

    public isActive(): boolean {
        return ( this.status.value.subStatus === SubscriptionStatus.started ) ||
            ( this.status.value.subStatus === SubscriptionStatus.created );
    }

}
