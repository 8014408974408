import { Injectable } from '@angular/core';
import { AppConfig } from 'flux-core';
import { NucleusAuthentication } from './../../../../../system/nucleus-authentication';

@Injectable({ providedIn: 'root' })
export class EntitySearchService {
    constructor( protected auth: NucleusAuthentication ) { }

    public get httpUrl(): string {
        return AppConfig.get ( 'CREATELY_ENTITY_SEARCH_URL' );
    }

    public async fetchPost<T = any>( body: any = {}): Promise<T> {
        const res = await fetch( `${this.httpUrl}?type=entity_data_item`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'creately-gravity-token': this.auth.token,
            },
            body: JSON.stringify( body ),
        });
        if ( res.status >= 400 && res.status < 600 ) {
            throw new Error( 'Internal server error' );
        }
        return res.json();
    }

}
