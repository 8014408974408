import { interval, fromEvent } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { ThumbnailCarousalItem } from './thumbnail-carousel-item.cmp';
import { Component, ContentChildren, AfterContentInit, ElementRef, ViewChild } from '@angular/core';
import { Rectangle } from '../../geometry/rectangle';

/**
 * ThumbnailCarousal
 * This component is a little slider containing thumnauls with their
 * titles
 *
 * @author  Thisun
 * @since   2017-07-26
 */

@Component({
    selector: 'thumbnail-carousel',
    template: `
            <div class="prev-icon fx-left" #prev >
                <i *ngIf="canSlidePrev" class="boson-icon-prev-thick nav-icon white"></i>
            </div>
            <div class="thumbnail-carousel-parent" #parentContainer >
                <div #container class="thumbnail-carousel-content" style="left: 0px;" >
                    <ng-content></ng-content>
                </div>
            </div>
            <div class="next-icon fx-right" #next >
                <i *ngIf="canSlideNext" class="boson-icon-next-thick nav-icon white"></i>
            </div>`,
})
export class ThumbnailCarousal implements AfterContentInit {

    /**
     * The time interval in seconds
     * to slide to next frame
     */
    public timeInterval: number = 8;

    /**
     * The sliding step in pixels
     */
    public slideStep: number = 3;

    /**
     * The thumbnail-carousel-parent element ref
     */
    @ViewChild( 'parentContainer' )
    public parentContainer: ElementRef;

    /**
     * Thumbnail items
     */
    @ContentChildren( ThumbnailCarousalItem )
    protected thumnails: ThumbnailCarousalItem[];


    /**
     * The previous and next icon element refs
     */
    @ViewChild( 'prev' )
    protected prev: ElementRef;

    @ViewChild( 'next' )
    protected next: ElementRef;

    /**
     * The container element ref
     */
    @ViewChild( 'container' )
    protected container: ElementRef;

    constructor( protected element: ElementRef ) {}

    /**
     * Returns true if it's possible to slide prev
     * and false if not thunbs to slide
     */
    public get canSlideNext(): boolean {
        if ( !this.container || !this.parentContainer ) {
            return false;
        }
        return this.containerLeft + this.containerWidth > this.parentContainerWidth;
    }

    /**
     * Returns true if it's possible to slide next
     * and false if not thunbs to slide
     */
    public get canSlidePrev(): boolean {
        if ( !this.container ) {
            return false;
        }
        return this.containerLeft < 0;
    }

    /**
     * The rendered bounds of the thumbanil carousel at the given time.
     */
    public get bounds(): Rectangle {
        const elem: HTMLElement = this.element.nativeElement.firstElementChild;
        return Rectangle.fromClientRect( elem.getBoundingClientRect());
    }

    /**
     * Returns the left style value of the container element
     */
    protected get containerLeft(): number {
        if ( this.container ) {
            return parseInt( this.container.nativeElement.style.left, 10 );
        }
    }

    /**
     * Sets the left style value of the container element
     */
    protected set containerLeft( value: number ) {
        if ( this.container ) {
            this.container.nativeElement.style.left = value + 'px';
        }
    }

    /**
     * Returns the width of the container element
     * and this width depends on the number of thumb items
     */
    protected get containerWidth(): number {
        if ( this.container ) {
            return this.container.nativeElement.getBoundingClientRect().width;
        }
    }

    /**
     * Returns the width of the parentContainer element
     */
    protected get parentContainerWidth(): number {
        if ( this.parentContainer ) {
            return this.parentContainer.nativeElement.getBoundingClientRect().width;
        }
    }

    public ngAfterContentInit() {
        this.createEvent( this.prev.nativeElement,  this.slideStep );
        this.createEvent( this.next.nativeElement,  -this.slideStep );
    }

    /**
     * Slides to next or prev frames depending on input step value
     * @param number  The amount in pixel that tha carousel slides in each interval
     */
    public slide( step: number ) {
        if ( step > 0 && this.canSlidePrev || step < 0 && this.canSlideNext ) {
            this.containerLeft = this.containerLeft + step;
        }
    }


    /**
     * Adds event listners for click, mouseenter and mouseout events
     * for the given element and slide left or right according to step
     * @param Element   The target element
     * @param step
     *
     */
    protected createEvent( element: Element, step: number ) {
        const mouseEnter = fromEvent( element, 'mouseenter' );
        const mouseLeave = fromEvent( element, 'mouseleave' );

        mouseEnter.pipe(
            switchMap(() =>
                interval( this.timeInterval ).pipe(
                takeUntil( mouseLeave ))),
            ).subscribe(() => this.slide( step ));
    }

}
