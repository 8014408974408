import { PositionType } from 'flux-definition';
import { IConnectorText } from 'flux-definition';
import { TextDataModel } from './text-data.mdl';

/**
 * ConnectorTextModel is the concrete version of a Connector Text and exends the TextModel
 * to inherit the common text functionalities.
 * This model will contain all data
 * processing and manipulation functionality needed for connector text.
 *
 * @author Thisun
 * @since 2018-01-16
 */
export class ConnectorTextDataModel extends TextDataModel implements IConnectorText {

    public slashCmdFeatures = [];

    /**
     * The x position of the text along the connector path.
     * The value that must be set is based on what is set in xType property.
     * For 'relative' this should a ratio value between 0 and 1.
     * For 'fixed-start' this should be a pixel value positioning from the start of the path.
     * For 'fixed-end' this should be a pixel value positioning from the end of the path.
     */
    public pos: number = 0.5;

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public posType: PositionType = 'relative';

    /*
    * The type of the text
    */
    public type: 'shape' | 'connector' = 'connector';

    public getWordWrapWidth( shapeWidth: number, autoResizeWidth ): string {
        return 'max-content';
    }

}
