import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger, StateService } from 'flux-core';
import { DataType, IAbstractDefinition, IDataItem, SystemType } from 'flux-definition';
import { difference, uniq } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DefinitionLocator } from '../../shape/definition/definition-locator.svc';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { CsvUtil } from './csv-util';

@Injectable()
export class DiagramCSVExporter {

    private translations = {
        [SystemType.DueDate]: 'SHAPE_DATA.DATA_ITEMS.DUE_DATE.LABEL',
        [SystemType.EstimateHrs]: 'SHAPE_DATA.DATA_ITEMS.ESTIMATE.LABEL',
        [SystemType.EstimatePts]: 'SHAPE_DATA.DATA_ITEMS.ESTIMATE_PTS.LABEL',
        [SystemType.Location]: 'SHAPE_DATA.DATA_ITEMS.LOCATION.LABEL',
        [SystemType.Priority]: 'SHAPE_DATA.DATA_ITEMS.PRIORITY.LABEL',
        [SystemType.StartDate]: 'SHAPE_DATA.DATA_ITEMS.START_DATE.LABEL',
        [SystemType.Status]: 'SHAPE_DATA.DATA_ITEMS.STATUS.LABEL',
        [SystemType.Role]: 'SHAPE_DATA.DATA_ITEMS.ROLE.LABEL',
        [SystemType.XAxis]: 'SHAPE_DATA.DATA_ITEMS.X_AXIS.LABEL',
        [SystemType.YAxis]: 'SHAPE_DATA.DATA_ITEMS.Y_AXIS.LABEL',
    };

    constructor(
        private ll: DiagramLocatorLocator,
        private state: StateService<string, any>,
        private defLocator: DefinitionLocator,
        private translate: TranslateService,
    ) {}

    public exportCSV() {
        return this.getCsvData( this.state.get( 'Selected' )).pipe(
            map(({ name, csvData }) => ({
                name,
                data: csvData.map( rowData => rowData.join( ',' )).join( '\n' ),
            })),
        );
    }

    public getCsvData( selectedIds: string[] = []) {
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
            switchMap( diagram => {
                let shapes = Object.values( diagram.shapes ).filter( s => s instanceof ShapeModel ) as ShapeModel[];
                const rows = [];
                const joiner = '_|_';
                if ( selectedIds.length > 0 ) {
                    shapes = shapes.filter( s => selectedIds.includes( s.id ));
                }
                for ( const shape of shapes ) {
                    const dataItems = shape.getDataItems( diagram );
                    const data  = Object.values( dataItems ) as IDataItem<DataType>[];
                    let validData = data.filter( d => !!d.type );
                    /* istanbul ignore if */
                    if ( data.length > validData.length ) {
                        Logger.warning( 'shape contains invalid data.', {
                            diagramId: diagram.id,
                            shapeId: shape.id,
                            data,
                            invalid: data.filter( d => !d.type ),
                        });
                    }
                    validData = validData.filter( d => d.type !== DataType.VIEW && d.isPublic !== false );
                    const rowData: any = validData.reduce(( o, d ) => {
                        const formatter = CsvUtil.getFormatter( d.type );
                        let label: string;
                        if ( d.label ) {
                            if ( d.systemType !== undefined ) {
                                const systemLabel = this.translate.instant( this.translations[ d.systemType ]);
                                label = `${d.label} - ${systemLabel}`;
                            } else {
                                label = d.label + joiner + d.type;
                            }
                        } else {
                            label = d.id;
                        }
                        o[label] = CsvUtil.getEscaped( formatter( d ));
                        return o;
                    }, {});
                    rowData.text = shape.primaryTextModel ? CsvUtil.getEscaped( shape.primaryTextModel.plainText ) : '';
                    rowData.typeId = shape.defId;
                    rows.push( rowData );
                }
                if ( rows.length === 0 ) {
                    return of({
                        name: diagram.name,
                        csvData: [[ 'text', 'type', 'typeId' ]],
                    });
                }
                const typeIds = uniq( rows.map( r => r.typeId ));
                return forkJoin( typeIds.map( defId => this.defLocator.getDefinition( defId ))).pipe(
                    map(( defs: IAbstractDefinition[]) => {
                        const defById = {};
                        defs.forEach( def => {
                            defById[ def.defId ] = def.name;
                        });
                        rows.forEach( r => {
                            r.type = defById[ r.typeId ];
                        });
                        const allColumns = rows.map( o => Object.keys( o )).reduce(( cols, keys ) =>
                            cols.concat( keys ), []);
                        let columns = difference( uniq( allColumns ), [ 'text', 'type', 'typeId' ]);
                        columns = [ 'text', 'type', 'typeId' ].concat( columns );
                        const headerRow = columns.map( col => col.includes( joiner ) ? col.split( joiner )[0] : col );
                        const rowsData = rows.map( row =>
                            columns.map( col => row.hasOwnProperty( col ) ? row[col] : '' ));
                        return {
                            name: diagram.name,
                            csvData: [ headerRow ].concat( rowsData ),
                        };
                    }),
                );
            }),
        );
    }
}
