import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { Observable, of } from 'rxjs';

@Injectable()
@Command()
export class GetProjectsAndDiagramsCommand extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected stateService: StateService<any, any>,
                 protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ): Observable<any> {
        const projectDiagrams = {};
        const projectMap = {};
        response.projects.forEach( project => {
            const projId = project.id;
            projectMap[projId] = project;
            projectDiagrams[projId] = response.diagrams.filter( d => d.project === projId );
        });
        this.stateService.set( 'ProjectDiagrams', projectDiagrams );
        this.stateService.set( 'ProjectMap', projectMap );
        return of( true );
    }
}

Object.defineProperty( GetProjectsAndDiagramsCommand, 'name', {
    value: 'GetProjectsAndDiagramsCommand',
});
