import { Injectable } from '@angular/core';
import { StoredDiagramLocator } from 'flux-diagram-composer';
import { DiagramSnapshotService } from 'flux-diagram';
import { DataStore } from 'flux-store';
import { PlanPermManager, UserInfoModel } from 'flux-user';
import { flatMap, uniq } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ConnectorModel } from '../shape/model/connector.mdl';
import { ShapeModel } from '../shape/model/shape.mdl';
import { DiagramLocatorLocator } from './locator/diagram-locator-locator';
import { DiagramModel } from './model/diagram.mdl';
import { PlanPermission } from 'flux-definition/src';

/**
 * shape of the history item
 */
export interface IHistoryItem {
    ts: number; // server time as of snapshot is being created.
    lastChangeId: string; // lastChangeId as of snapshot creation.
    userIds: string[];
    name?: string;
    users?: UserInfoModel[];
}

/**
 * This is the main service class used to handle all history item related code.
 */
@Injectable()
export class DiagramHistoryService {

    private locators: {
        [diagramId: string]: {
            [lastChangeId: string]: StoredDiagramLocator<DiagramModel, ShapeModel | ConnectorModel>,
        },
    } = {};

    public constructor(
        private dataStore: DataStore,
        private diagramSnapshotSvc: DiagramSnapshotService,
        private ll: DiagramLocatorLocator,
        private planPermManager: PlanPermManager,
    ) {}

    /**
     * This method is used to get the associated history items for a diagram.
     * @param diagramId id of the diagram
     * @param once boolean to indicate close the subscription once data is returned
     * @returns observable of history items
     */
    public getHistoryItems( diagramId: string, once: boolean = false ): Observable<IHistoryItem[]> {
        const modelStore = this.dataStore.getModelStore( DiagramModel );
        const historyItems = modelStore.findOneHistoryMetadata({
            id: diagramId,
        }).pipe(
            map( historyRecord => historyRecord ? historyRecord.snapshots || [] : []),
        );
        return once ? historyItems.pipe( take( 1 )) : historyItems;
    }

    public getHistoryItemsWithUsers( diagramId: string, once: boolean = false ): Observable<IHistoryItem[]> {
        return this.getHistoryItems( diagramId, once ).pipe(
            map( snapshots => snapshots.filter( snap => snap.ts > this.getHistoryPermissionDuration())),
            switchMap( snpashots => {
                if ( snpashots.length === 0 ) {
                    return of( snpashots );
                }
                const userIds: string[] = uniq( flatMap( snpashots, s => s.userIds ));
                return forkJoin( userIds.map( id => this.dataStore.findOneLatest( UserInfoModel, { id }))).pipe(
                    map( users => {
                        const usersById = users.filter( Boolean ).reduce(( obj, user ) => {
                            obj[ user.id ] = user;
                            return obj;
                        }, {});
                        return snpashots.map( s => ({
                            ...s,
                            users: ( s.userIds || []).map( id => usersById[ id ]).filter( Boolean ),
                        }));
                    }),
                );
            }),
        );
    }

    /**
     * This method returns the latest history item associated with the given diagram id.
     * @param diagramId id of the diagram
     * @param once boolean to indicate close the subscription once data is returned
     * @returns observable of last history item
     */
    public getLatestHistoryItem( diagramId: string, once: boolean = true ): Observable<IHistoryItem> {
        return this.getHistoryItems( diagramId, once ).pipe(
            map( snapshots => snapshots[snapshots.length - 1]),
        );
    }

    /**
     * This method returns the diagram model for history item.
     * @param diagramId id of the diagram
     * @param lastChangeId id of the history item
     * @returns observable of the diagram model as of history item data.
     */
    public fetchSnapshot( diagramId: string, lastChangeId: string ) {
        return this.getSnapshotLocator( diagramId, lastChangeId ).getDiagramOnce();
    }

    /**
     * This method returns the locator for given history item.
     * @param diagramId id of the diagram
     * @param lastChangeId id of the history item
     * @returns the locator for the snapshot
     */
    public getSnapshotLocator( diagramId: string, lastChangeId: string ) {
        if ( !this.locators[diagramId] || !this.locators[diagramId][lastChangeId]) {
            const locator = this.ll.createSnapshotLocator( diagramId, this.fetchRawSnapshot( diagramId, lastChangeId ));
            if ( !this.locators[diagramId]) {
                this.locators[diagramId] = {};
            }
            this.locators[diagramId][lastChangeId] = locator;
        }
        return this.locators[diagramId][lastChangeId];
    }

    /**
     * Returns the history item raw data
     * @param diagramId id of the diagram
     * @param lastChangeId id of the history item
     * @returns observable that emits history item data
     */
    public fetchRawSnapshot( diagramId: string, lastChangeId: string ) {
        return this.diagramSnapshotSvc.fetchSnapshot( diagramId, lastChangeId );
    }

    /**
     * Check version history permissionn and retun the duration value by milisecond
     * If permission does not have any limitaion this will return -1
     * @returns history pemission duration by milisecond
     */
    public getHistoryPermissionDuration() {
        return this.planPermManager.getMaxUsage( PlanPermission.CREATELY_VERSION_HISTORY ) === -1
            ? this.planPermManager.getMaxUsage( PlanPermission.CREATELY_VERSION_HISTORY )
            : new Date().getTime() - ( this.planPermManager.getMaxUsage( PlanPermission.CREATELY_VERSION_HISTORY )
            * ( 24 * 60 * 60 * 1000 ));
    }
}
