/**
 * Interface for the service responsible for handling tooltip
 * tours & type definitions required.
 * Tooltip Tour Service should Implement TooltipTourServiceI.
 * @author kulathilake
 * @since Jun/2022
 */

import { ElementRef } from '@angular/core';
import { IModalOptions } from 'flux-core';
import { ToolTipPlacement } from 'flux-core/src/ui';
import { BehaviorSubject } from 'rxjs';

export interface ITooltipTourServiceI {
    /**
     * Emits the current tooltip tour step.
     * null when no step is visible
     */
    currentStep: BehaviorSubject<ITooltipTourStep | null>;

    /**
     * Inner tooltips of the current tooltip step.
     */
    currentTourSteps: BehaviorSubject<ITooltipTourStep[]>;

    /**
     * A map of tooltip steps and their viewed state
     * as defined by user tooltip tour status.
     */
    tooltipStatus: { [id: string]: boolean };

    /**
     * clientTop and clientLeft of the element
     * corresponding to current tour step, to be
     * used to place the tooltip component.
     */
    tooltipAnchorPos: { x: number; y: number };

    /**
     * registers an anchor element against a known
     * tooltip tour step id.
     * @param elem
     * @param stepId
     */
    registerElement( elem: ElementRef, stepId: string );

    /**
     * Removes element from anchor map
     * @param stepId
     */
    unregisterElement( stepId: string );
    /**
     * updates an element with view changes.
     * @param elem
     * @param stepId
     */
    updateElement( elem: ElementRef, stepId: string );

    /**
     * shows a tooltip with a given step id
     * sets currentStep.
     * @param id
     */
    showTooltip( id: string );

    /**
     * obtains the status of each tooltip step
     * for the current user.
     */
    setUserTooltipTourStatus();

    /**
     * Sets the viewed status of a tooltip tour step.
     * @param stepId
     * @param viewed
     */
    markStepAsViewed( stepId: string );

    /**
     * persists the current users tooltip tour status
     * either locally or in database based on a passed
     * flag.
     * @param local persists the tooltip status locally.
     */
    persistTooltipTourStatus( stepId: string, local?: boolean );

    /**
     * Traverses the tooltips array to find
     * the child / inner tooltips of a given
     * step.
     * @param step
     */
    findChildTooltips( step: string );

    /**
     * Resets all tooltip tours.
     */
    resetTooltipTour();

    initialiseTooltipTour();
}

/**
 * type def for tooltip tour step
 */
export interface ITooltipTourStep {
    stepId: string;
    level: 0 | 1 | 2 | 3;
    title: string;
    description: string;
    tooltip?: string;
    animationURL?: string;
    animationImageURL?: string;
    playOnce?: boolean;
    customWidth?: number;
    customHeight?: number;
    upgradeText?: string;
    helpText?: string;
    /**
     * related keyboard shortcut
     * (if any)
     * seperate keys with +
     */
    kbd_shortcut?: string;
    /**
     * force state changes before
     * stepping in to this step
     */
    stateChanges?: { [key: string]: any }[];
    /**
     * open a modal
     */
    modal?: {
        type: any;
        options?: IModalOptions;
    };
    /**
     * next step to show.
     */
    nextStepId?: string;
    prevStepId?: string;
    /**
     * Instead of going to the next step
     * go to learn more step.
     * Next is Skip
     */
    learnMoreStep?: string;
    /**
     * resource url to a related support article
     */
    learnMoreArticle?: string;
    /**
     * Can user skip inner steps and skip to nextStep of
     * main tour?
     */
    skippable?: boolean;
    /**
     * Has the user already viewed this tooltip step.
     */
    viewed?: boolean;
    /**
     * element to anchor the tooltip.
     */
    anchor?: IToolTipAnchorElement;
    /**
     * milisecond delay before showing tooltip
     * eg: to allow animations to place element.
     */
    delay?: number;

    /**
     * override the default right placement
     * TODO: Remove
     */
    left?: boolean;
    /**
     * override the default left placement.
     * TODO: Remove
     */
    bottom?: boolean;

    /**
     * feature(s) required to show this tooltip
     */
    prerequisites?: TooltipPrerequisites[];

    /**
     * features required to show the ripple in addition to
     * prerequisites
     */
    ripplePrequisites?: TooltipPrerequisites[];

    /**
     * an array of tooltip step id that need to be
     * in viewed state as a part of the tooltip prereqs.
     * TOOLTIP_VIEWED should be in the prequisites array
     * for this to work.
     */
    preReqTooltips?: string[];

    /**
     * should the tour skip to next if the current feature is unavailable.
     * if not the tour will end here.
     */
    skipIfPrerequisiteNoMet?: boolean;

    /**
     * overrides the calculated position of tooltip anchor
     * by adding the top and left values provided
     */
    positionOverride?: {
        top?: number;
        left?: number;
    };
    /**
     * override the position of the tooltip element only
     * Used when a tooltip needs to opened displaced
     * from the anchoring element and the ripple (if any)
     */
    tooltipPositionOverride?: {
        top?: number;
        left?: number;
    };

    /**
     * add to arrows vertical displacement
     */
    bodyDisplacementOverride?: number;

    /**
     * is the anchor element on canvas.
     */
    canvasElement?: boolean;
    /**
     * An array of known components of the application
     * on which the ripples are not allowed to display
     */
    preventOverlapping?: KnownApplicationLayouts[];

    /**
     * uses the anchor of another element if this doesn't have an anchor
     * only applicable when showing the tooltip ie. will not show
     * ripples when the tooltip is not open for obvious reasons.
     */
    useAnchorOf?: string;

    /**
     * Use own position, tooltip and ripple overrides when anchoring
     * on another tooltip's anchor.
     */
    useOwnOverrides?: boolean;

    /**
     * prevent multiple components from trying to register
     * an element attached to the same tooltip. Only the first
     * element to register will be allowed. Suitable for scenarios
     * where the tooltip has to point to just one element from a list
     * of items populated programmatically.
     */
    registerFirstAnchorOnly?: boolean;
    /**
     * Auto change tour steps after a set period of time
     */
    autoChangeInterval?: number;
    /**
     * Prevents marking viewed steps as coloured dots in UI
     */
    preventMarkingViewedStepsInUI?: boolean;
}

export interface IToolTipAnchorElement {
    stepId: string;
    element: HTMLElement;
    top: number;
    left: number;
    right: number;
    bottom: number;
    placement?: ToolTipPlacement;
    rippleHidden?: boolean;
}

export enum TooltipPrerequisites {
    /**
     * Current user / workspace has eData features
     */
    EDATA = 'edata',
    /**
     * Current user / workspace has tasks
     */
    TASKS = 'tasks',
    /**
     * There's a shape selected
     */
    SHAPE_SELECTED = 'shape_selected',
    TOOLTIP_VIEWED = 'tooltip_viewed',
    // Floating Action Panels
    /**
     * Current Floating Action Panel is Quick Toolbars
     */
    FAB_IS_QTB = 'ftb_is_qtb',
    // Left Sidebar Panels
    /**
     * Current Left Sidebar Panel is Tasks
     */
    LSB_IS_TASKS = 'lsb_is_tasks',
    /**
     * Current Left Sidebar Panel is Navigation
     */
    LSB_IS_NAV = 'lsb_is_nav',
    /**
     * Current Left Sidebar Panel is Data
     */
    LSB_IS_DATA = 'lsb_is_data',
    LSB_IS_NONE = 'lsb_is_none',
}

/**
 * A list of known application layouts
 * which require special whitelisting for
 * a ripple to be drawn on.
 */

export enum KnownApplicationLayouts {
    LEFTSIDEBAR,
    HEADER,
    RIGHTSIDEBAR,
    MODALWINDOW,
    USERMENU,
    DIAGRAMTOOLBAR,
    FLOATINGPANEL,
    SHAPEPROPACTIONS,
}
