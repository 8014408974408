import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Random, StateService, Command } from 'flux-core';
import { SelectionStateService } from '../../selection/selection-state';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { IShapeData } from '../../shape/shape-data.i';
import { CloneShapes } from './clone-shapes.cmd';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { StoredDiagramLocator } from 'flux-diagram-composer';
import { map } from 'rxjs/operators';
import { DiagramToViewportCoordinate } from '../../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { Clipboard } from '@creately/clipboard';

/**
 * This command is used to clone the shapes which got selected in the current diagram.
 * This gets the selected shapes data from the datastore and change
 * the id and position of the shape before creating a new shape.
 *
 * This extends CloneShapes which has all the data to compose a new shape.
 * For more details see the {@link CloneShapes}
 */
@Injectable()
@Command()
export class DuplicateShapes extends CloneShapes {

    protected state: SelectionStateService;

    constructor( protected ll: DiagramLocatorLocator,
                 state: StateService<any, any>,
                 random: Random,
                 vToDcoordinate: ViewportToDiagramCoordinate,
                 dToVcoordinate: DiagramToViewportCoordinate,
                 protected clipboard: Clipboard,
    ) {
        super( random, ll, vToDcoordinate, dToVcoordinate, state, clipboard );
        /* istanbul ignore next */
        this.state = state;
    }

    /**
     * Returns the shapes that needs to be duplicated.
     */
    protected getShapesToAdd(): Observable<{ shapesToCopy: IShapeData[]}> {
        const shapeIds: string[] = this.data.shapeIds === undefined
                    ? this.state.get( 'Selected' ) : this.data.shapeIds;
        const locator: StoredDiagramLocator<any, any> = this.ll.forCurrent( false ) as any;
        return forkJoin( locator.getRawDiagramDataOnce(), locator.getDiagramOnce()).pipe(
            map(([ diagramRaw, diagram ]) => {
                let shapes = shapeIds.map( id => ( diagramRaw as any ).shapes && ( diagramRaw as any ).shapes[id]);
                shapes = shapes.map( shapeData => {
                    const type = diagram.shapes[shapeData.id].type;
                    const bounds = diagram.getBounds( shapeData.id );
                    return { data: shapeData, bounds, type };
                });
                return { shapesToCopy: shapes };
            }),
        );
    }

}

Object.defineProperty( DuplicateShapes, 'name', {
    value: 'DuplicateShapes',
});
