import { AppConfig } from '../app-config';
import HyperDX from '@hyperdx/browser/build/index';
import opentelemetry from '@opentelemetry/api';
import { Span, ROOT_CONTEXT, SpanOptions, Context } from '@opentelemetry/api';
import { Logger } from './logger.svc';

/**
 * Declaire that we have access to __CreatelyShapes__ namespace on window object.
 */
declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        HyperDX: any;
    }
}

/**
 * Manages Opentelemetry related configurations and functionality.
 */
export class OTelManager {

    public static isDisabled() {
        return AppConfig.get( 'HYPERDX_DISABLED' );
    }

    /**
     * Initialize HyperDX
     * IMPORTANT! This should be called only once.
     */
    public static initialize() {
        try {
            // If hyperDX enabled from config then enable it.
            if ( this.isDisabled()) {
                    return;
            }
            const tracePropagationTarget = [
                new RegExp( AppConfig.get( 'API_BASE_URL' ), 'i' ),
                new RegExp( AppConfig.get( 'REST_API_BASE_URL' ), 'i' ),
                new RegExp( AppConfig.get( 'FILE_API_BASE_URL' ), 'i' ),
                new RegExp( AppConfig.get( 'GRAPHQL_API_BASE_URL' ), 'i' ),
            ];
            HyperDX.init({
                apiKey: AppConfig.get( 'HYPERDX_TOKEN' ),
                service: `${AppConfig.get( 'APP_NAME' )}-${AppConfig.get( 'NAME' )}-${AppConfig.get( 'APP_REGION' )}`,
                tracePropagationTargets: tracePropagationTarget,
                captureConsole: true, // Capture console logs (default false)
                disableReplay: true,
                advancedNetworkCapture: false,
                url: AppConfig.get( 'HYPERDX_API_URL' ),
                // INFO: instrumentations has bunch of properties.
                // Leaving this commented so that we can change it later.
                // Please check @hyperdx/otel-web SplunkOtelWebOptionsInstrumentations for more information
                instrumentations: {
                    // document: false, // ?: boolean | InstrumentationConfig;
                    errors: true, // ?: boolean;
                    fetch: { ignoreNetworkEvents: true }, // ?: boolean | HyperDXFetchInstrumentationConfig;
                    interactions: false, // ?: boolean | SplunkUserInteractionInstrumentationConfig;
                    longtask: false, // ?: boolean | InstrumentationConfig;
                    visibility: false, // ?: boolean | InstrumentationConfig;
                    connectivity: false, // ?: boolean | InstrumentationConfig;
                    postload: false, // ?: boolean | SplunkPostDocLoadResourceInstrumentationConfig;
                    // socketio?: boolean | SocketIoClientInstrumentationConfig;
                    // websocket?: boolean | InstrumentationConfig;
                    webvitals: false, // ?: boolean;
                    xhr: false, // ?: boolean | HyperDXXMLHttpRequestInstrumentationConfig;
                },
            });
            HyperDX.disableAdvancedNetworkCapture();
            window.HyperDX = HyperDX;
        } catch ( e ) {
            Logger.warning( 'Otel initialization failed', e );
        }
    }

    /**
     * Set user Id to the Otel context.
     * @param userId
     * @returns
     */
    public static setUserId( userId: string ) {
        try {
            // If hyperDX enabled then set the USER ID to hyperDX context.
            if ( this.isDisabled()) {
                return;
            }
            HyperDX.setGlobalAttributes({
                userId: userId,
            });
        } catch ( e ) {
            Logger.warning( 'Otel failed to set user attribute', e );
        }
    }

    /**
     * Create OTel span with given data.
     * @param spanId
     * @param options
     * @param context
     * @returns
     */
    public static createSpan( spanId: string, options?: SpanOptions, context?: Context ): Span {
        try {
            if ( this.isDisabled()) {
                return;
            }
            const tracer = opentelemetry.trace.getTracer( this.OTEL_TRACER_NAME );
            return tracer.startSpan( spanId, options || {}, context );
        } catch ( e ) {
            Logger.warning( 'Otel failed create span', e );
        }
    }

    /**
     * Creates a span and attach it's context to Given Message Object.
     * @param spanId
     * @param message
     * @param otelCtx
     * @returns
     */
    public static createSpanAndAttachContextToMessage( spanId: string, message: any, otelCtx?: Context ): Span {
        try {
            if ( this.isDisabled()) {
                return;
            }
            const span = this.createSpan( spanId, {}, otelCtx );
            const contextData = {};
            opentelemetry.propagation.inject(
                opentelemetry.trace.setSpan( opentelemetry.context.active(), span ),
                contextData );
            message[ this.OTEL_PROPERTY_NAME_ON_MESSAGE ] = contextData;
            return span;
        } catch ( e ) {
            Logger.warning( 'Otel failed create span and attach context to message', e );
        }
    }

    /**
     * Retriev the otel context from given message and Create a span and attach the context to it.
     * @param spanId
     * @param message
     * @returns
     */
    public static createSpanAndAttachContextFromMessage( spanId: string, message: any ): Span {
        try {
            if ( this.isDisabled()) {
                return;
            }
            const contextFromMessage = opentelemetry.propagation.extract(
                                                                    ROOT_CONTEXT,
                                                                    message[ this.OTEL_PROPERTY_NAME_ON_MESSAGE ]);
            const spanData = { attributes: { message: message.msg + '-' + message.type + '-' + message.rid }};
            return this.createSpan( spanId, spanData, contextFromMessage );
        } catch ( e ) {
            Logger.warning( 'Otel failed create span and attach context from message', e );
        }
    }

    protected static OTEL_PROPERTY_NAME_ON_MESSAGE = 'otel-ctx';
    protected static OTEL_TRACER_NAME = '@hyperdx/browser';
}
