import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, RequestMethod } from 'flux-core';
import { Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { UserModel } from 'flux-user';
import { map, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command get the provided user details from api
 * if not availabe in browser else it loaded from local
 * memory and return
 * @since   2023-10-21
 * @author  Sajeeva
 */
@Injectable()
@Command()
export class GetOrgUserInfo extends AbstractHttpCommand  {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    /**
     * Command input data format
     */
    public data: {
        userId: string;
        orgId: string;
    };

    constructor( protected dataStore: DataStore ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'gravity-token': window.gravity.client.getCookie(),
        });
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        const subUrl = `/${this.data.orgId}/user/${this.data.userId}`;
        return AppConfig.get ( 'ORG_USER_SERVICE_API_BASE_URL' ) + subUrl;
    }

    public prepareData(): Observable<any> {
        return this.isUserAvailable( this.data.userId ).pipe(
            tap( cached => {
                if ( cached ) {
                    this.resourceId = null;
                }
            }),
        );
    }

    /**
     * Command execute
     */
    public execute() {
        return !!this.resourceId;
    }

    public executeResult(): Observable<any> {
        const result: UserModel = this.resultData.user;
        if ( result ) {
            return this.dataStore.insert( UserModel,
                result,
            );
        }
        return empty();
    }

    /**
     * Check whether a template is available in local cache.
     */
    private isUserAvailable( id: string ): Observable<boolean> {
        return this.dataStore.findOneLatest( UserModel, { id: id }).pipe(
            map( model => Boolean( model )),
        );
    }
}

Object.defineProperty( GetOrgUserInfo, 'name', {
    value: 'GetOrgUserInfo',
});
