import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef, Inject, Directive } from '@angular/core';
import { combineLatest, EMPTY, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';
import { ProjectLocator } from '../locator/project-locator';
import { ProjectModel, TeamShareModel } from 'flux-diagram/models';
import { UserLocator, UserModel } from 'flux-user';
import { StateService } from 'flux-core';

/**
 * This abstract class can be used to control features based on users' role of the current project.
 * When specifying which user roles are allowed, pass the minimum allowed role to the directive.
 * For all roles which are less than or equal to the given role will pass the directive and enable
 * the UI feature/element.
 */
@Directive()
export abstract class AbstractProjectRole implements OnInit, OnDestroy {

    /**
     * Subscription which controls the view state based on users' role
     */
    protected abstract subs: Subscription;

    /**
     * Holds the current view state, if it is enabled or removed
     */
    private viewEnabled: boolean = false;


    public constructor(
        @Inject( TemplateRef ) protected templateRef: TemplateRef<any>,
        @Inject( ViewContainerRef ) protected viewContainer: ViewContainerRef,
        @Inject( ChangeDetectorRef ) protected changeDetector: ChangeDetectorRef,
        @Inject( ProjectLocator ) protected projectLocator: ProjectLocator,
        @Inject( UserLocator ) protected userLocator: UserLocator,
        @Inject( StateService ) protected state: StateService<any, any>,
    ) {}

    /**
     * Start controlling the view
     */
    public abstract ngOnInit(): void;

    /**
     * Housekeeping, remove the subscription upon destroying
     */
    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    protected updateElementsOnProjectRole( projectId: string, projectRole: string  ) {
        if ( projectId === 'home' ) {
            // FIXME: If user can somehow have diagrams in their home project that are not their own,
            // then this check needs to change. Currentily this is not possible but this may likely change
            // if move diags on delete is implemented.
            // Consider the performance impact of checking collabs for all diagrams for the home project
            // when doing this (since home project usually has a high number of diagrams )
            this.addElement();
            return EMPTY;
        } else {
            // const uId = this.state.get( 'CurrentUser' );
            return combineLatest([
                this.userLocator.getUserData(),
                this.projectLocator.getProject( projectId ),
            ]).pipe(
                map(([ user, project ]) => {
                    if ( this.hasRolePermission( project, user, projectRole )) {
                        this.addElement();
                    } else {
                        this.removeElement();
                    }
                }),
            );
        }
    }

    /**
     * Checks the user has expected role permission to the project.
     * @param project
     * @param user
     * @param requiredRole
     * @returns
     */
    protected hasRolePermission( project, user, requiredRole ): boolean {
        const role = this.getProjectRole( project, user );
        return role != null && role <= CollaboratorType[ requiredRole ];
    }

    /**
     * Get the role of given user for project
     * @param collabs
     * @param project
     * @param user
     * @returns
     */
    protected getProjectRole( project: ProjectModel, user: UserModel ) {
        // Collabs
        const collabObjs: any[] = [
            project.collabs?.find( collab => collab.id === user.id ),
        ];
        // TeamShare
        if ( user.team?.id ) {
            collabObjs.push( project.teamShare?.find( teamShare => teamShare.teamId === user.team?.id ));
        }
        // Share with anyone
        collabObjs.push( project.teamShare?.find( shareLink => shareLink.teamId === TeamShareModel.ALL ));
        // Share with group
        if ( user.teamGroupIds && user.teamGroupIds.length > 0 ) {
            collabObjs.push( project?.groupShare?.find( gs => user.teamGroupIds.includes( gs.id )));
        }
        let role =  null;
        collabObjs.forEach( collab => {
            if ( !!collab ) {
                if ( role === null ) {
                    role = collab.role;
                } else if ( collab.role < role ) {
                    role = collab.role;
                }
            }
        });
        return role;
    }

    /**
     * Create Embedded View from the TemplateRef and insert to viewContainer
     */
    protected addElement() {
        if ( !this.viewEnabled ) {
            this.viewContainer.createEmbeddedView( this.templateRef );
            this.viewEnabled = true;
            this.changeDetector.detectChanges();
        }
    }

    /**
     * Destroys all the views in the viewContainer
     */
    protected removeElement() {
        this.viewContainer.clear();
        this.viewEnabled = false;
        this.changeDetector.detectChanges();
    }
}
