import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command } from 'flux-core';
import { DiagramInfoModel, DiagramInfoModelStore } from 'flux-diagram';
import { Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

/**
 * This is the command which changes the privacy of the diagram and send the
 * request to the server
 *
 * data: {
 *     privacy { level, role }
 *     diagramId id of the diagram that needs to change the privacy
 * }
 * @deprecated Privacy data structure is deprecated. This is replaced by TeamShare information.
 */
@Injectable()
@Command()
export class ChangeDiagramPrivacy extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            privacy: true,
            diagramId: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        return modelStore.getPrivacy( this.data.diagramId ).pipe(
            tap( privacy => this.previousData.privacy = privacy ),
        );
    }

    /**
     * execute
     * change diagram privacy
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        return of( this.previousData.privacy ).pipe(
            map( privacy => {
                privacy.role = this.data.privacy.role;
                privacy.level = this.data.privacy.level;
                return privacy;
            }),
            switchMap( privacy => modelStore.storgaeUpdatePrivacy( this.data.diagramId, privacy )),
        );
    }

    /**
     * executeResult
     * Update the privacy deatils with the response
     */
    public executeResult( response: any ): Observable<any> {
           const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
           return modelStore
               .storgaeUpdatePrivacy( this.resourceId, response.privacy );
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore ;
        return modelStore.storgaeUpdatePrivacy( this.data.diagramId, this.previousData.privacy );
    }

}

Object.defineProperty( ChangeDiagramPrivacy, 'name', {
    value: 'ChangeDiagramPrivacy',
});
