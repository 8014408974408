import { ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopupWindow, PostMessageAPI, AppConfig } from 'flux-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Post message receive event types for upgrade dialog.
 */
export enum EmailVerificationReceiveEvent {
    Success = 'phoenix:emailVerifySuccess',
}

/**
 * email verification window. This will handle the popup,
 * And show the email verification page.
 *
 * @author  Sajeeva
 * @since   2022-06-21
 */

@Component({
    templateUrl: 'email-verification-window.cmp.html',
    selector: 'email-verification-window',
    styleUrls: [ './email-verification-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationWindow extends PopupWindow implements OnDestroy, OnInit {


    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The file exported window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected modalController: ModalController,
        protected translate: TranslateService,
        protected postMessage: PostMessageAPI,
    ) {
        super();
        this.subs = [];
    }

    /**
     * This returns the IFrame url according to type
     */
    public get emailVerificationUrl(): string {
        return AppConfig.get( 'SITE_URL' ) + 'email-confirmation-required/?inapp=1';
    }

    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
            this.postMessage.recv().subscribe( message => this.handleIncomingMessages( message )),
        );
    }

    /**
     * close the IFrame
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Reload the current application window.
     * This is done to make the plan changes take effect.
     * NOTE: This function can not be tested with unit tests
     */
    /* istanbul ignore next */
    protected reload() {
        window.location.reload();
    }

    /**
     * Handle incoming post messages from email verification page.
     */
    private handleIncomingMessages( message: any ): void {
        if ( !message || typeof message !== 'object' ) {
            return;
        }
        const { event } = message as any;
        if ( event === EmailVerificationReceiveEvent.Success ) {
            this.reload();
            this.handleClose();
        }
    }

    /**
     * close handler
     */
    private handleClose() {
        this.closeWindow();
    }
}
