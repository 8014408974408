import { Injectable } from '@angular/core';
import { AbstractCommand, Command, ModalController, StateService } from 'flux-core';
import { Observable, forkJoin, of } from 'rxjs';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { ConvertToObjectDialog } from '../../view/convert-to-object-dialog/convert-to-object-dialog.cmp';
import { switchMap, tap } from 'rxjs/operators';
import { IEDataDef } from 'flux-definition/src';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';

@Injectable()
@Command()
export class OpenConvertToObjectDialog extends AbstractCommand {

    /**
     * Command input data format
     */
    public data: {
        defId: string;
        learnMore?: boolean;
    };

    constructor(
        protected stateService: StateService<any, any>,
        protected modalController: ModalController,
        private defLocator: DefinitionLocator,
        private ll: DiagramLocatorLocator,
    ) {
        super();
    }

    public execute(): boolean | Observable<any> {
        if ( this.data?.learnMore ) {
            // FIXME: add the correct link.
            window.open( 'https://support.creately.com', '_blank' );
            return;
        }
        const shapeId = this.stateService.get( 'Selected' )[0];
        let initDefId = ConvertToObjectDialog.customEDataDefId;
        if ( this.data && this.data.hasOwnProperty( 'defId' )) {
            initDefId = this.data.defId;
        }
        return this.ll.forCurrent( false ).getShapeOnce( shapeId ).pipe(
            switchMap( shape => this.getCandidateEDataDefs( shape.defId ).pipe(
                tap( defs => {
                    this.modalController.show( ConvertToObjectDialog, { inputs: {
                        defs,
                        shape,
                        initDefId,
                    }});
                }),
            )),
        );
    }

    private getCandidateEDataDefs( shapeDefId: string ): Observable<IEDataDef[]> {
        const getDef: ( defId: string ) => Observable<IEDataDef> =
            this.defLocator.getDefinition.bind( this.defLocator );
        return getDef( shapeDefId ).pipe(
            switchMap( def => {
                if ( !( def as any ).eDataCandidates ) {
                    return of([]);
                }
                return forkJoin((( def as any ).eDataCandidates as string[]).map( defId => getDef( defId )));
            }),
        );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( OpenConvertToObjectDialog, 'name', {
    value: 'OpenConvertToObjectDialog',
});
