import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * This StateChangeCommand commnd is to set the AppStatusIndicatorState state
 * that is used to control the app status tooltip element.
 */
@Injectable()
@Command()
export class AppStatusIndicator extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            open: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            AppStatusIndicatorState: {
                open: this.data.open,
            },
        };
    }

    public prepareData() {
        if ( this.data.keyboardEvent ) {
            this.data.keyboardEvent.preventDefault();
        }
    }

    public execute(): boolean {
        return true;
    }
}

Object.defineProperty( AppStatusIndicator, 'name', {
    value: 'AppStatusIndicator',
});
