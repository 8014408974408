import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * This StateChangeCommand commnd is to set the EditShapelink state
 * that is used to control the ShapeLinkEditor compoenent
 */
@Injectable()
@Command()
export class EditShapelink extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            open: true,
            origin: false,
            link: false,
            state: false,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            ShapelinkEditState: {
                open: this.data.open,
                origin: this.data.origin,
                link: this.data.link,
                state: this.data.state,
            },
        };
    }

    public execute(): boolean {
        return true;
    }
}

Object.defineProperty( EditShapelink, 'name', {
    value: 'EditShapelink',
});
