import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces, Command, CommandResultError } from 'flux-core';
import { Observable, merge, defer, concat, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { UserInfoSub } from 'flux-user';
import { switchMap, filter, take } from 'rxjs/operators';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';

/**
 * This is the command to add collaborators to a project.
 * This command will add the pending collabs to the locla storage and send
 * the request to the server.
 *
 * This extends {@link AbstractMessageCommand} to send a request to the server.
 * data: {
 *     collabs - [{ email, role }]
 * }
 */
@Injectable()
@Command()
export class AddProjectCollaborators extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            collabs: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        protected modelSubManager: ModelSubscriptionManager,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.data.collabs.forEach( collab => {
            collab.addedStatus = 'pending';
            collab.email = ( collab.email as string ).toLowerCase();
        });
    }

    /**
     * execute
     * execute add collaborators to project model
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.storageMergeCollabs( this.resourceId, this.data.collabs );
    }

    /**
     * Start the user subscription
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        const observables = [];
        const collabs = response.collabs;
        collabs.forEach(( collab, index ) => {
            if ( collab ) {
                observables.push(
                    this.modelSubManager.start( UserInfoSub, collab.id ).pipe(
                        switchMap( sub => sub.status ),
                        filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
                        take( 1 ),
                    ),
                );
                // FIXME: Email needs to be removed from the collabs.
                if ( this.data ) {
                    collabs[index].email = this.data.collabs[ index ].email;
                }
            }
        });
        if ( collabs.length > 0 ) {
            return concat(
                merge( ...observables ),
                defer(() => modelStore.storageMergeCollabs(
                    this.resourceId, collabs.filter( collab => collab !== null ))),
                defer(() => this.removePendingCollabs()),
            );
        }
        return EMPTY;
    }

    public revert(): Observable < any > {
        return this.removePendingCollabs();
    }

    /**
     * Checks if a permission error occured and throws a permission error.
     */
    public onError( err: CommandResultError ) {
        if ( err.code ) {
            throw err;
        }
    }

    /**
     * Removes collaborators added in the execute step (if there are any)
     */
    protected removePendingCollabs(): Observable < any > {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        const filterFn = collab => collab.addedStatus === 'pending';
        return modelStore.storageFilterCollabs( this.resourceId, filterFn );
    }
}

Object.defineProperty( AddProjectCollaborators, 'name', {
    value: 'AddProjectCollaborators',
});
