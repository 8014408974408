import { AbstractModel } from 'flux-core';

export class TeamGroupModel extends AbstractModel {

    /**
     * This variable holds the id of this team group.
     */
    public id: string;

    /**
     * This variable holds the name of the team group.
     */
    public displayName: string;

    /**
     * This variable holds the if of containing team plan.
     */
    public groupPlanId: string;

    /**
     * Group description.
     */
    public description: string = 'Group';

    /**
     * Returns a initials of the user which is of two characters.
     */
    public get initials(): string {
        return this.displayName?.substring( 0, 2 ).toUpperCase() || '';
    }
}
