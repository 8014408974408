import { ComplexType, ComplexTypeFactory, enumerable, Matrix, Rectangle, Position } from 'flux-core';
import {
    DataType, FillStyle, IContainerRegion, IDataItem, IGluePoint,
    ILinearGradient, IPoint2D, IPosition2D, IRadialGradient,
    IShapeModel, IStyle, IStyleDefinition, ITransform, ITransformDefinition,
    TransformRenderMethod, IRectangle, IContainerGrid,
} from 'flux-definition';
import { RuleSerializable } from 'json-rules-engine';
import { DataItemFactory } from '../dataitem/data-item-factory';
import { AbstractShapeModel } from './abstract-shape.mdl';
import { ShapeTextDataModel } from './shape-text-data.mdl';

/**
 * ShapeDataModel
 * This is the full representation of a shape. All data necessary to render a shape
 * will be in this model.
 *
 * There will be a extended version of this model outside of this module.
 *
 * @author hiraash
 * @since 2017-09-01
 */
export class ShapeDataModel extends AbstractShapeModel implements IShapeModel {

    /**
     * If the shape is an image and it's scaled down beyond ( 1 - this value ) ( e.g  0.79 , 0.7 .. etc. )
     * A scaled down image is uploaded
     */
    public static imageScaleDownStep = 0.2;

    /**
     * The default bounds of this shape. This defines the size of a shape
     * in its unchanged state. This will be defined by the shape def.
     */
    public defaultBounds: Rectangle;


    /**
     * The height of the shape which is set by the user
     * the initial value of this property is shape's default height and it's set
     * in the shape modeol factory
     */
    public userSetHeight: number;

    /**
     * The width of the shape which is set by the user
     * the initial value of this property is shape's default width and it's set
     * in the shape modeol factory
     */
    public userSetWidth: number;

    /**
     * This property is to enable and disable the shape width resizing on text width changes
     */
    public autoResizeWidth: boolean = false;

    /**
     * This property is to enable and disable the shape height resizing on text height changes
     */
    public autoResizeHeight: boolean = false;

    /**
     * This property is to enable and disable the shape width resizing to fully contain the text.
     */
    public autoResizeToMinTextWidth: boolean = true;

    /**
     * Indicate if this shape is a container or not
     */
    public isContainer: boolean = false;

    /**
     * Indicate if this shape can be containerize
     */
    public isContainerable: boolean = true;

    /**
     * Indicate if this shape allows to upload images if images available.
     */
    public isImageUploadDisabled: boolean = false;

    /**x
     * If this shape is a sticker it can be stuck on any shape regardless
     * isContainer property
     */
    public isSticker: boolean = false;

    /**x
     * If this shape has stickers, this property will be set to true
     */
    public hasStickers: boolean = false;

    /**
     * Supported types of contexts for this container.
     * Communicates to shapes being added how they should adapt to being
     * in this container
     *
     * ex: timeline, calendar, xychart, map, list etc.
     * can be any string as long as the shapes also have this defined and
     * react accordingly.
     */
    public containerContexts: string[];

    /**
     * These context type are used to determine which context the shape is in.
     * Default is '*'
     */
    public shapeContext: string = '*';

    /**
     * This property can be used to identify the default context of the shape.
     * When shape getting covert in to * context which is the default context,
     * it will use this context as the default context if this available at the time.
     */
    public shapeDefaultContext?: string;

    /**
     * If this shape is a different forms default bounds will be
     * store here.Then it can be used when shape transform back to default form.
     */
    public defaultContextBounds: IRectangle;

    /**
     * Indicates whether the shape contains inner shapes that can have their own styles, etc
     * independent of the entire shape
     * Eg: a table made up of cells
     */
    public madeOfInnerShapes: boolean = false;

    /**
     * Indicates whether other actions happen when clicking on a shape,
     * thereby disabling opening text editor on primary text / new text / existing text.
     */
    public disableOpenTextOnShapeClick: boolean = false;

    /**
     * Indicates whether the shape can be switched to other shapes
     * and details on the switch operation
     */
    public switchable: {
        defaultLibrary?: string,
        shapes?: Array<any>,
        shapeIcon: any,
    };

    /**
     * The children of this shape, this property holds the children data
     * if this shape is a container, also this property holds all the stickers
     * stuck on this shape
     */
    public children: { [id: string]: {
        originalZIndex: number,
        relativeZIndex: number,
        relativeX?: number,
        relativeY?: number,
        relativeAngle?: number,
    }} = {};

    /**
     * Shape can have multiple regions,
     * e.g. table cells, headers, lanes in swim lane, quadrants etc.
     * These regions should be updated in the shape logic
     * as the shape is transformed ( or data items changed )
     */
    public containerRegions: { [id: string]: IContainerRegion } = {};

    /**
     * Shape can have grid.Grid is visual separation.Grid Id can be
     * map to certain text id.
     * 2x2 3x2
     */
    public containerGrids: { [id: string]: IContainerGrid } = {};

    /**
     * Shape can have specific padding to draw the shape
     */
     public shapePaddings: {
        top: number,
        right: number,
        bottom: number,
        left: number,
     };

    /**
     * The container shape id
     */
    public containerId: string;

    /**
     * The container shape id
     */
    public layoutIndex?: number = 1;

    /**
     * If the shape is a child shape of a container, the region Id of that container
     */
    public containerRegionId;

    /**
     * When the container connects, it will use a handshake string to define the type of connection
     */
    public containerHandshake?: string;

    /**
     * This contains the shapes which are supported by this shape
     * for containerizing.
     */
    public supportedChildren: string [];


    /**
     * When the shape is a container and it has a layout, this property will hold the layouting id
     */
    public layoutingId: string;

    /**
     * This property is to disable the usual way of container locking
     */
    public unlockContainer: boolean = false;

    /**
     * business rules defined by container for child shapes
     */
    public rules: RuleSerializable[] = [];

    /**
     * Default transformation settings of the all shapes.
     * This will be overridden by the shape definition
     */
    public transformSettings: ITransformDefinition = {
        hScale: true,
        vScale: true,
        rotate: true,
        scaleRender: TransformRenderMethod.redraw,
        rotationRender: TransformRenderMethod.transform,
        fixAspectRatio: false,
    };

    /**
     * This property determine whether the shape can
     * be searched using any shape search component.
     * Default value is True.
     */
    public isSearchable: Boolean = true;

    /**
     * The placement of the shape on the x axis
     * of the diagram space. Units in pixels
     */
    public x: number = 0;

    /**
     * The placement of the shape on the y axis
     * of the diagram space. Units in pixels
     */
    public y: number = 0;

    /**
     * The horizontal scale of the shape.
     */
    public scaleX: number = 1;

    /**
     * The vertical scale of the shape.
     */
    public scaleY: number = 1;

    /**
     * The rotation angle of the shape in degrees.
     */
    public angle: number = 0;

    /**
     * T
     */
    public disableHitArea: boolean = false;

    /**
     * The skew of the shape on x axis
     */
    public skewX: number = 0;

    /**
     * The skew of the shape on the y axis
     */
    public skewY: number = 0;

    /**
     * Data def for the shape
     */
    public dataDef;

    /**
     * A map of shape data properties, keys are identifiers reffered in code
     */
    @ComplexTypeFactory({ '*': data => DataItemFactory.instance.create( data ) })
    public data: { [identifier: string]: IDataItem<DataType> } = {};


    /**
     * Flag to mark if the shape should redraw.
     * If the shape data has updated, it will need to redraw itself.
     *
     */
    public redraw: boolean = false;


    /**
     * Thumbnail class identifier
     */
    public thumbnail: string;

    /**
     * The styling set to this shape.
     */
    public style: IStyle = {
        fillStyle: FillStyle.Solid,
        fillColor: 'white',
        fillAlpha: 1,
        lineAlpha: 1,
        lineColor: 'grey',
        lineThickness: 1.5,
    };

    /**
     * The dynamic style is used by conditional formatting and similar functionality.
     */
    public dynamicStyle?: IStyle;

    /**
     * Contains styles which can be manipulated separately within separate parts
     * of the shape
     */
    public styleDefinitions: { [id: string]: IStyleDefinition } = {};

    public disableTextStyle: boolean = false;

    /**
     * Canvas instructions for freehand shapes.
     */
    public instructions?: any[];

    /**
     * Texts for the shape, single shape can have multiple texts
     * Each text contains data to position itself on the shape
     * and also the text and text styles as html.
     */
    @ComplexType({ '*' : ShapeTextDataModel })
    public texts: { [id: string]: ShapeTextDataModel } = {};

    /**
     * The set of gluepoints that belong to this shape. These
     * are in priciple coming from the definition. The defined
     * gluepoints are not expected to change in principle.
     */
    public gluepoints: { [id: string]: IGluePoint };


    /***********************************************************
    * Properties required by image shape type
     *
     ***********************************************************/

    /**
     * Hash value of the custom image
     */
     public hash: string;

     /**
      * Hash value of the custom scaled image
      */
     public hashScaled: string;

    /**
     * The image type of the custom image.
     * Example values: svg, png, jpeg
     */
    public imageType: string;

    /**
     * If true, the shape can be flipped Horizontally and vice versa
     */
    protected flippableH: boolean =  true;

    /**
     * If true, the shape can be flipped vertiacally and vice versa
     */
    protected flippableV: boolean =  true;

    /**
     * Creates a new model instance
     * @param id id of the shape
     * @param extension
     */
    public constructor( id: string, extension?: Object ) {
        super( id, extension )/* istanbul ignore next */;
    }

    /**
     * Return true if this shape/container has any children
     */
    @enumerable( true )
    public get hasChildren() {
        return Object.keys( this.children || {}).length > 0;
    }

    /**
     * This getter is to decide if the container should be locked or not
     */
    @enumerable( true )
    public get containerLocked() {
        if ( !this.unlockContainer ) {
            return this.isContainer && this.hasChildren;
        }
        return false;
    }

    /**
     * Returns the current width of the shape in pixels
     */
    @enumerable( true )
    public get width(): number {
        return ( this.defaultBounds.width * this.scaleX );
    }

    /**
     * Returns the current height of the shape in pixels
     */
    @enumerable( true )
    public get height(): number {
        return ( this.defaultBounds.height * this.scaleY );
    }

    /**
     * For use when drawing a shape. Returns the current
     * width based on the transformation settings of the shape.
     */
    @enumerable( true )
    public get drawWidth(): number {
        if ( this.transformSettings.scaleRender === TransformRenderMethod.transform ) {
            return this.defaultBounds.width;
        } else {
            return this.width;
        }
    }

    /**
     * For use when drawing a shape. Returns the current
     * height based on the transformation settings of the shape.
     */
    @enumerable( true )
    public get drawHeight(): number {
        if ( this.transformSettings.scaleRender === TransformRenderMethod.transform ) {
            return this.defaultBounds.height;
        } else {
            return this.height;
        }

    }

    /**
     * Returns +1 or -1 to indicate the shape's inversion
     * from the origin on the x axis.
     */
    @enumerable( true )
    public get inversionX(): 1 | -1 {
        return this.scaleX < 0 ? -1 : 1;
    }

    /**
     * Returns +1 or -1 to indicate the shape's inversion
     * from the origin on the y axis.
     */
    @enumerable( true )
    public get inversionY(): 1 | -1 {
        return this.scaleY < 0 ? -1 : 1;
    }

    /**
     * The transformation properties of this shape.
     */
    @enumerable( true )
    public get transform(): ITransform {
        return {
            x: this.x,
            y: this.y,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            angle: this.angle || 0,
            skewX: this.skewX,
            skewY: this.skewY,
        };
    }

    /**
     * Returns the fully transformed bounds of this shape.
     * It absorbes all transformations. In case of rotation and skew
     * the rectangle represents the outer bounds of the transformed shape.
     *  ___________
     *  |   *     |
     *  |  *     *|
     *  | *     * |
     *  |*     *  |
     *  |     *   |
     *  -----------
     */
    @enumerable( true )
    public get bounds(): Rectangle {
        return this.defaultBounds
            .clone()
            .transform( this.transform );
    }

    /**
     * This method is to check if the shape is auto resizable, Shapes that have innner single text
     * can only be auto resized from the framework level
     */
    public isAutoResizeable() {
        const textIds = Object.keys( this.texts );
        if ( textIds.length === 1 || ( textIds.length === 3 && textIds.includes( 'data_items_values' ))) {
            const textModel = this.texts[ textIds[ 0 ]];
            return textModel.rendering !== 'dom' && textModel.isInside() && textModel.isPositionable();
        }
        return false;
    }


    /**
     * If true, the shape can be flipped Horizontally ( H ) or Vertically ( V )
     * @param direction 'H' | 'V'
     */
    public isFlippable( direction: 'H' | 'V' ) {
        if ( this.isContainer ) {
            return false;
        }
        return this[ `flippable${direction}` ];
    }

    /**
     * This function returns the shape bounds considering the text bounds
     */
    public getBoundsWithTexts() {
        if ( !this.hasAnyText ) {
            return this.bounds;
        }
        const boundsWithText = Rectangle.from( this.bounds );
        for ( const key of Object.keys( this.texts )) {
            const text = this.texts[ key ];
            const textBounds =  text.getBoundingBox(
                this.defaultBounds,
                this.transform,
            );
            boundsWithText.absorb( textBounds );
        }
        return boundsWithText;
    }

    /**
     * This function returns the shape bounds considering the image bounds
     */
    /* istanbul ignore next */
    public getBoundsWithImages(): IRectangle {
        if ( !this.hasAnyImage ) {
            return this.bounds;
        }
        const boundsWithImage = Rectangle.from( this.bounds );
        for ( const key of Object.keys( this.images )) {
            const image = this.images[ key ];

            const imageWidth = image.w ? image.w : image.h;
            const imageHeight = image.h ? image.h : image.w;
            const width = imageWidth.type === 'relative' ? imageWidth.value * this.width : imageWidth.value;
            const height = imageHeight.type === 'relative' ? imageHeight.value * this.height : imageHeight.value;

            const imageBoundsRect = new Rectangle();
            const difference = { x: 0, y: 0 };

            if ( image.alignX === 'l' ) {
            } else if ( image.alignX === 'r' ) {
                difference.x = width;
            } else if ( image.alignX === 'c' ) {
                difference.x = width / 2;
            }

            if ( image.alignY === 't' ) {
            } else if ( image.alignY === 'b' ) {
                difference.y = height;
            } else if ( image.alignY === 'c' ) {
                difference.y = height / 2;
            }

            if ( image.x.type === 'relative' ) {
                imageBoundsRect.x = this.x + ( image.x.value * this.width ) - difference.x;
            } else if ( image.x.type === 'fixed-start' ) {
                imageBoundsRect.x = this.x +  image.x.value - difference.x;
            } else if ( image.x.type === 'fixed-end' ) {
                imageBoundsRect.x = ( this.width -  image.x.value ) - difference.x;
            }

            if ( image.y.type === 'relative' ) {
                imageBoundsRect.y = this.y + ( image.y.value * this.height ) - difference.y;
            } else if ( image.y.type === 'fixed-start' ) {
                imageBoundsRect.y = this.y +  image.y.value - difference.y;
            } else if ( image.y.type === 'fixed-end' ) {
                imageBoundsRect.y =  this.y +  image.y.value  - difference.y;
            }

            imageBoundsRect.width = width;
            imageBoundsRect.height = height;

            const rect = new Rectangle( imageBoundsRect.x, imageBoundsRect.y,
                 imageBoundsRect.width, imageBoundsRect.height );
            boundsWithImage.absorb( rect );
        }
        return boundsWithImage;
    }

    /**
     * Returns the coordinate of a point relative to the shape's default state.
     */
    public getPointOnShape( pointOnDiagram: IPoint2D ): IPoint2D {
        const matrix = Matrix.fromTransform( this.transform ).invert();
        return matrix.transformPoint( pointOnDiagram.x, pointOnDiagram.y );
    }

    /**
     * Returns the position of the point relative to the shape (optionally, snap to edges)
     */
    public getPositionOnShape( pointOnDiagram: IPoint2D, threshold = 0 ): IPosition2D {
        const size  = { w: this.defaultBounds.width, h: this.defaultBounds.height };
        const diff = this.getPointOnShape( pointOnDiagram );
        const pos: IPosition2D = {
            x: { type: 'relative', value: 0 },
            y: { type: 'relative', value: 0 },
        };
        if ( diff.x > -threshold && diff.x < threshold ) {
            pos.x.value = 0;
        } else if ( diff.x > size.w - threshold && diff.x < size.w + threshold ) {
            pos.x.value = 1;
        } else {
            pos.x.value = diff.x / size.w;
        }
        if ( diff.y > -threshold && diff.y < threshold ) {
            pos.y.value = 0;
        } else if ( diff.y > size.h - threshold && diff.y < size.h + threshold ) {
            pos.y.value = 1;
        } else {
            pos.y.value = diff.y / size.h;
        }
        return pos;
    }

    /**
     * Returns the x and y coordinates of a point on the shape from the
     * relative position of the shape
     * @param position the relative position of the point on the shape
     */
    public getPointFromPosition( position: any ): IPoint2D {
        const pos: IPosition2D = {
            x: { type: position.xType || 'relative', value: position.x },
            y: { type: position.yType || 'relative', value: position.y },
        };
        const newPos = Position.onRect( pos, this.defaultBounds, this.transform );
        return newPos;
    }

    /**
     * Returns a linear gradient only if the fill style is set to LinearGradient.
     * Otherwise returns undefined.
     *
     * The gradient returned is the actual values in pixels calculated based on the
     * shapes current size and scale. This is the values used to be drawn on the view/canvas
     */
    public getLinearGradient(): ILinearGradient {
        if ( this.style.fillStyle === FillStyle.LinearGradient ) {
            return this.transformGradientPosition( this.style.fillGradient );
        }
    }

    /**
     * Returns a radial gradient only if the fill style is set to RadialGradient.
     * Otherwise returns undefined.
     *
     * The gradient returned is the actual values in pixels calculated based on the
     * shapes current size and scale. This is the values used to be drawn on the view/canvas
     */
    public getRadialGradient(): IRadialGradient {
        if ( this.style.fillStyle === FillStyle.RadialGradient ) {
            const gradient: IRadialGradient = <any> this.transformGradientPosition( this.style.fillGradient );
            if ( gradient ) {
                gradient.r0 *= this.drawWidth <= this.drawHeight ? this.drawWidth : this.drawHeight ;
                gradient.r1 *= this.drawWidth <= this.drawHeight ? this.drawWidth : this.drawHeight ;
            }
            return gradient;
        }
    }

    /**
     * Returns the threshold scale value to scale down the image considering both scaleX and scaleY
     * @param scaleX
     * @param scaleY
     */
    public getScaleDownValue() {
        const getScale = scale => {
            if ( scale >= 1 ) {
                return 1;
            }
            const size = 1 / ShapeDataModel.imageScaleDownStep;
            for ( let index = 0; index < size; index++ ) {
                let level = ( 1 - ( ShapeDataModel.imageScaleDownStep * ( index + 1 )));
                level = Math.round( level * 10 ) / 10;
                if ( scale > level ) {
                    return ( level + ShapeDataModel.imageScaleDownStep );
                }
            }
        };
        return Math.max( getScale( this.scaleX ), getScale( this.scaleY ));
    }

    /**
     * Converts the common properties of linear and radial gradients to the actual pixel
     * values based on shapes current status.
     * @param original The gradient's original values in ratio as it was stored.
     */
    protected transformGradientPosition( original: ILinearGradient  ): ILinearGradient {
        let gradient: ILinearGradient;
        if ( original ) {
            gradient = { ...original };
            if ( gradient.x0 ) {
                gradient.x0 *= this.drawWidth;
            }
            if ( gradient.x1 ) {
                gradient.x1 *= this.drawWidth;
            }
            if ( gradient.y0 ) {
                gradient.y0 *= this.drawHeight;
            }
            if ( gradient.y1 ) {
                gradient.y1 *= this.drawHeight;
            }
        }
        return gradient;
    }

}
