import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagramToViewportCoordinate } from 'apps/nucleus/src/base/coordinate/diagram-to-viewport-coordinate.svc';

/**
 * This state command is to set the <state> to open the quick shape search component
 */
@Injectable()
@Command()
export class OpenQuickShapeSearch extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            open: false, // The open or close state, also this can be undefined ( toggle ), (Used by state)
            shapeId: false, // Id of the corresponding shape
            shape: false, // Id of the corresponding shape
            context: false, // The context of the quick shape search ( Shape switcher, Plus create etc. )
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected ll: DiagramLocatorLocator,
        protected defLocator: DefinitionLocator,
        protected dtoV: DiagramToViewportCoordinate,
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            PlusCreateToolbarState: {
                show: true,
                shapeId: this.data.shapeId,
                context: 'shape-switch',
                shape: this.data.shape,
                point: this.data.point,
                position: 'left',
            },
        };
    }

    public prepareData(): Observable<any> {
        if ( !this.data ) {
            return;
        }
        return this.ll.forCurrent( false ).getDiagramOnce().pipe(
            tap( d => {
                this.data.shapeId = this.state.get( 'Selected' )?.[0];
                this.data.shape = d.shapes[ this.data.shapeId ];
                const toolbar = document.querySelector( '#contextual-toolbar' );
                const tbounds = toolbar.getBoundingClientRect();
                this.data.point = { x:  tbounds.x, y:  tbounds.y + tbounds.height - 5 };
            }),
        );
    }

    public execute(): boolean {
        return true;
    }

}

Object.defineProperty( OpenQuickShapeSearch, 'name', {
    value: 'OpenQuickShapeSearch',
});
