import { Observable, from } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

/**
 * A translate loader to load translation files during build time.
 *
 * Different types of loaders can be used by the ngx-translate module
 * to load translation files. The default suggested loader is HTTPLoader.
 * However, this loads translation files asynchronously via XHR requests
 * and can lead to cases where translation is not available in certain
 * initialization points of the app (i.e. route resolver).
 *
 * Therefore, we use the WebpackTranslateLoader. This ensures translation
 * files are bundled into the app during build time.
 * Please refer to 'Angular CLI/Webpack TranslateLoader Example' section
 * in https://github.com/ngx-translate/http-loader for more information on
 * the usage of webpack translater loader.
 */
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation( lang: string ): Observable<any> {
    return from( import( `../assets/i18n/${lang}.json` ));
  }
}
