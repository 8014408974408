import { Tracker } from './../../tracker/tracker';
import { values } from 'lodash';
import { DynamicComponent } from './../dynamic.cmp';
import {
    OnDestroy,
    Component,
    Input,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    Injector,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ComponentRef,
    ElementRef,
  } from '@angular/core';
import { fromEvent, Subscription, Observable } from 'rxjs';

/**
 * ITabItem is the interface that defines the tab item
 */
export interface ITabItem {
    /**
     * Unique id for the tab elemet
     */
    id: string;

    /**
     * Label for the tab header button
     */
    label: string;

    /** Any angular Component. this component will be rendered in the
     * tab content
     * type: Component name
     * inputs: input data
     */
    content: { type: any, inputs: any };

    /**
     * icon for tab header label
     */
    icon?: string;

    /**
     * The alignment of the tab buttons
     */
    buttonAlignment?: 'left' | 'center' | 'right';

}

export interface ITabComponent {
    /**
     * When a tab is updated this hook will be called,
     * All the inputs are up to date when this is called
     */
    onTabUpdate?();
}


/**
 * AbstractTab Component
 * This component is an abstract tab component that has basic
 * tab factinalities implemented.
 *
 * @author  thisun
 * @since   2020-03-20
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'abs-tab',
    styleUrls: [ './abstract-tab.cmp.scss' ],
    template: `
        <div class="abs-tab-container">
          <div class="abs-tab-header" #tabsHeader></div>
          <perfect-scrollbar class="abs-tab-content" [config]="{suppressScrollX: true}">
            <div #tabsContent></div>
          </perfect-scrollbar>
        </div>
    `,
})
export class AbstractTab extends DynamicComponent implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where the tab component is being used.
     */
    @Input()
    public context?: string;

    /**
     * This input observable emits ITabItems and this helps to dynamicaly add/remove/update
     * the tabs component
     */
    @Input()
    public tabs: Observable<ITabItem[]>;

    /**
     * When the tab is rendered, The tab related to this id will be opened
     */
    @Input()
    public defaultTabId: string;

    @ViewChild( 'tabsHeader', { read: ViewContainerRef, static: false })
    protected tabsHeader: ViewContainerRef;

    @ViewChild( 'tabsContent', { read: ViewContainerRef, static: false })
    protected tabsContent: ViewContainerRef;

    /**
     * Subscriptions - component level
     */
    protected subs = [];

    /**
     * Subscriptions - items level
     */
    protected itemSubs: { [id: string]: Subscription } = {};

    /**
     * Tab items with related header and content elements
     */
    protected items: { [id: string]: { item:  ComponentRef<any>, header: HTMLElement, tabContent: HTMLElement }} = {};


    /**
     * The previously opned tab id should be storeds here to keep it opened,
     * as the tabs observable emits values,
     */
    protected lastOpenedTab: string;

    constructor(
      protected componentFactoryResolver: ComponentFactoryResolver,
      protected injector: Injector,
      protected cr: ChangeDetectorRef,
      protected elementRef: ElementRef,
    ) {
        super( componentFactoryResolver, injector );
    }

    public ngAfterViewInit() {
        const s = this.tabs.subscribe( tabs => {
            if ( tabs ) {
                this.manageTabs( tabs );
            }
            setTimeout(() => {
                this.cr.markForCheck();
                if ( tabs && tabs[0]) {
                    const lastOpenTabExists = tabs.find( t => t.id === this.lastOpenedTab  );
                    if ( lastOpenTabExists ) {
                        this.openTab( this.lastOpenedTab );
                    } else  {
                        this.openTab( this.defaultTabId || tabs[0].id );
                    }
                }
            }, 0 );
        });
        this.subs.push( s );
    }

    public ngOnDestroy() {
        const subs = values( this.itemSubs );
        subs.push( ...this.subs );
        while ( subs.length > 0 ) {
            subs.pop().unsubscribe();
        }
        this.itemSubs = undefined;
        this.tabsHeader.clear();
        this.tabsContent.clear();
        this.tabsHeader.element.nativeElement.innerHTML = '';
        this.tabsContent.element.nativeElement.innerHTML = '';
        Object.keys( this.items ).forEach( key => {
            const item = this.items[ key ];
            item.item.destroy();
        });
    }

    /**
     * Clear all the tabs
     */
    public clearAll() {
        Object.keys( this.items ).forEach( key => this.removeItem( key ));
    }

    protected manageTabs( tabs: ITabItem[]): void {
        const itemsToAdd = [];
        const itemsToUpdate = [];
        tabs.forEach( item => {
            if ( Object.keys( this.items ).includes( item.id )) {
                // NOTE: The currently opened tab should be updated lastly
                if ( item.id !== this.lastOpenedTab ) {
                    itemsToUpdate.unshift( item );
                } else {
                    itemsToUpdate.push( item );
                }
            } else {
                itemsToAdd.push( item );
            }
        });

        const toRemove = Object.keys( this.items )
            .filter( id => !tabs.map( tab => tab.id ).includes( id ));

        toRemove.forEach( id => this.removeItem( id ));
        itemsToUpdate.forEach(( item, index ) => this.updateItem( item ));
        itemsToAdd.forEach(( item, index ) => this.addTab( item ));
    }

    protected removeItem( id: string ) {
        const itemRef = this.items[id].item;
        const header = this.items[id].header;
        const tabContent = this.items[id].tabContent;
        const container = this.tabsContent;
        itemRef.destroy();
        this.remove( container, itemRef );
        tabContent.remove();
        header.remove();
        delete this.items[ id ];
        this.itemSubs[id].unsubscribe();
        delete this.itemSubs[id];
    }

    protected updateItem( tab: ITabItem ) {
        const id = tab.id;
        if ( this.items[id]) {
            const itemRef = this.items[id].item;
            const inputs = tab.content.inputs || {};
            for ( const key in inputs ) {
                itemRef.instance[ key ] = inputs[ key ];
            }
            if ( itemRef.instance.onTabUpdate ) {
                itemRef.instance.onTabUpdate();
            }
        }
    }

    protected addTab( tab: ITabItem ) {

        // Create tsb button
        const tabLink = document.createElement( 'a' );
        tabLink.setAttribute( 'data-id', tab.id );
        tabLink.classList.add( `button-alignment-${tab.buttonAlignment || 'left'}` );
        tabLink.innerHTML = tab.label;
        this.itemSubs[ tab.id ] = fromEvent( tabLink, 'click' ).subscribe( e => {
            Tracker.track( `${this.context}.tab.click`, { value1: tab.id });
            this.openTab( tab.id );
        });
        ( this.tabsHeader.element.nativeElement as HTMLElement ).appendChild( tabLink );

        // Create tab content
        const tabContent = document.createElement( 'div' );
        tabContent.classList.add( 'abs-tab-item' );
        tabContent.setAttribute( 'data-abs-tab-id', tab.id );
        ( this.tabsContent.element.nativeElement as HTMLElement ).appendChild(
        tabContent,
        );
        const cmp = this.createComponent( tab.content.type, this.tabsContent  );
        const inputs = tab.content.inputs || {};
        for ( const key in inputs ) {
            cmp.instance[ key ] = inputs[ key ];
        }
        cmp.instance.name = tab.label;
        tabContent.appendChild( cmp.location.nativeElement );
        this.items[ tab.id ] = { item: cmp, header: tabLink, tabContent };
    }

    protected openTab( id: string ) {
        this.elementRef.nativeElement.querySelectorAll( '.abs-tab-header a' )
            .forEach(( el: HTMLElement ) => el.classList.remove( 'active' ));
        this.elementRef.nativeElement.querySelectorAll( '.abs-tab-item' )
            .forEach(( el: HTMLElement ) => el.style.display = 'none' );
        const header = this.elementRef.nativeElement.querySelector(
            `.abs-tab-header a[data-id="${id}"]` ) as HTMLElement;
        if ( header ) {
            header.classList.add( 'active' );
            const element = this.elementRef.nativeElement.querySelector(
                `div[data-abs-tab-id="${id}"]` ) as HTMLElement;
            element.style.display = 'block';
            element.style.paddingTop = '16px';
            this.lastOpenedTab = id;
        }
    }
  }

