import { cloneDeep } from 'lodash';
import { Logger } from '../logger/logger.svc';

export class JsonUtil {
    public static clone ( obj: any ) {
        try {
            return JSON.parse( JSON.stringify( obj ));
        } catch ( e ) {
            Logger.error( 'JsonUtil.clone error', e, obj );
            return cloneDeep( obj );
        }
    }
}
