import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, Input,
    OnDestroy, Output, ViewChild, ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractCollapsibleMenuItem } from './abstract-collapsible-menu-item';
import { ICollapsibleMenuItem, ISecondaryMenuItem } from './collapsible-menu';
import { FloatingContainer } from '../floating-container/floating-container.dir';
@Component({
    selector: 'collapsible-menu-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './collapsible-menu-item.cmp.html',
    styleUrls: [ './collapsible-menu-item.scss' ],
})
/**
 * Collapsible Menu Item which has a header and the content of a single panel.
 * Header displays the title and the icons. Content consists of custom
 * components.
 *
 * @author  gobiga
 * @since   2017-11-09
 */

export class CollapsibleMenuItem extends AbstractCollapsibleMenuItem implements OnDestroy {

    /**
     * Event emitter to emit when click event fires on
     * the header element.
     */
    @Output()
    public toggleChild: EventEmitter<any>;

    /**
     * Event emitter to emit when click event fires on
     * remove button.
     */
    @Output()
    public removeChild: EventEmitter<any>;

    /**
     * Event emitter to emit when the title is edited
     */
    @Output()
    public titleChanged: EventEmitter<any>;

    /**
     * Closed tooltip container
     */
    @Output()
    public closedTooltip: EventEmitter<any>;

    /**
     * If true enable close button to remove the component from parent panel
     */
    @Input()
    public removable: boolean = true;

    /**
     * Determine whether menu item is collapsable
     */
    @Input()
    public collapsable: boolean = true;

    /**
     * If true enable secondary menu.
     */
    @Input()
    public secondaryMenu: boolean = false;

    /**
     * Secondary menu interface.
     */
    @Input()
    public items: ISecondaryMenuItem[] = [];

    /**
     * An icon to show to the right side of the collabsible menu title( After the collabisible icon )
     */
    @Input()
    public preTitleIcon: string;

    /**
     * Floating tooltip for deisplaying how to use feature
     */
    @Input()
    public hasFloatingTooltip: boolean;


    /**
     * This holds the instance of the component panel that is rendered on the container child
     */
    public menuItem: ICollapsibleMenuItem;

    /**
     * Checked to hide floating tooltip
     */
    public checked: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Floating tooltip
     */
    @ViewChild( 'floatingTooltip', { read: FloatingContainer })
    public floatingTooltip: FloatingContainer;

    /**
     * Expands the item.
     */
    @ViewChild( 'componentContainer', { read: ViewContainerRef, static: true })
    protected componentContainer: ViewContainerRef;


    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef,
        public translate: TranslateService,
    ) {
        super( componentFactoryResolver, injector, cdr, translate );
        this.toggleChild = new EventEmitter();
        this.removeChild = new EventEmitter();
        this.titleChanged = new EventEmitter();
    }


    /**
     * Title to be shown on header
     */
    public get title(): string {
        if ( this.menuItem ) {
            return this.menuItem.title;
        }
    }

    /**
     * Secondary text to be shown on header
     */
    public get subText(): Observable<string> {
        if ( this.menuItem ) {
            return this.menuItem.subText;
        }
    }

    public get options(): any {
        if ( this.menuItem ) {
            return this.menuItem.options;
        }
    }

    public get secondaryOptions(): any {
        if ( this.menuItem ) {
            return this.menuItem.secondaryOptions;
        }
    }

    public get plusButton(): boolean {
        if ( this.menuItem ) {
            return this.menuItem.plusButton;
        }
    }

    public get selected(): boolean {
        if ( this.menuItem ) {
            return this.menuItem.selected.value;
        }
    }

    public get highlightSelected(): boolean {
        if ( this.menuItem ) {
            return this.menuItem.highlightSelected;
        }
    }

    public get plusButtonTooltip(): string {
        if ( this.menuItem ) {
            return this.menuItem.plusButtonTooltip;
        }
    }

    public get tooltipTitle(): string {
        if ( this.menuItem ) {
            return this.menuItem.tooltipTitle;
        }
    }

    public get tooltipContent(): string {
        if ( this.menuItem ) {
            return this.menuItem.tooltipContent;
        }
    }

    /**
     * Id of the compoenent
     */
    @Input()
    public get id(): string {
        return this.menuItem.id;
    }

    public iconURL( icon: string ) {
        return './assets/icons/symbol-defs.svg#' + icon;
    }

    public plusButtonCallback( event ) {
        event.preventDefault();
        this.menuItem.plusButtonCallback();
    }

    /**
     * Enforce ditect changes and rerender the compoennt.
     */
    public detectChanges() {
        this.cdr.detectChanges();
    }

    /**
     * Creates an instance of an menu item and append it to the end of the menu
     * @param type  The type of the component to create.This should be a ICollapsibleMenuItem Component class.
     * @param inputs    An object with all the inputs that should be set to the
     *      type component instance. Object keys should have the same name as the input field.
     */
    public add( type: any, inputs?: { [inputName: string]: any }) {
        const menuItemCmpRef: ComponentRef<any> = this.createComponent( type, this.componentContainer );
        this.menuItem = menuItemCmpRef.instance;
        inputs = { ...inputs, subText: this.subText };
        this.setInputs( this.menuItem, inputs );
        this.menuItem.parent = this;
    }

    public closeTooltip() {
        this.floatingTooltip.hide();
        this.menuItem.closeTooltip( this.checked );
    }


    /**
     * Unsubscribes from all subscriptions.
     */
     public ngOnDestroy() {
        this.toggleChild = null;
        this.removeChild = null;
        this.titleChanged = null;
        this.menuItem = null;
        this._parent = null;
        this._isOpen = false;
    }

}
