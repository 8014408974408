import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, StateService, DateFNS } from 'flux-core';
import { Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';

/**
 * This command is used to get the google access token from creately api
 * server. It will return the access token and expiring duration which
 * can be used to query any Google APIs.
 *
 * @since   2020-09-07
 * @author  Vinoch
 */
@Injectable()
@Command()
export class GetGoogleAccessToken extends AbstractHttpCommand  {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    constructor( protected auth: NucleusAuthentication,
                 protected state: StateService<any, any> ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GOOGLE_ACCESS_TOKEN_URL' );
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return false;
    }

    /**
     * Attaches the auth token to command data
     * The REST execution step requires it.
     * This function will check for the token expiration.
     * If the token expired, it will request for a new token
     * from the server, if not it will cancel the command from
     * executing.
     */
    public prepareData(): any {
        this.data.auth = this.auth.token;
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        if ( this.state.get( 'GoogleTokenInfo' ) &&
             this.state.get( 'GoogleTokenInfo' ).expires_at ) {
                const expires_at = this.state.get( 'GoogleTokenInfo' ).expires_at;
                if ( !DateFNS.isPast( expires_at )) {
                    // NOTE: Returning undefined is to stop this command
                    // from further executing as the token has not expired.
                    return;
                }
        }
        return empty();
    }

    /**
     * Store model data received from the server and remove local changes.
     */
    public executeResult( response: any ): any {
        // NOTE: Storing the expiration time in milliseconds.
        if ( response ) {
            const now = new Date();
            // NOTE: Deducting 60 seconds to renew the token before the expiration.
            // Or sometimes the user may experience interuption when the request
            // triggers when the token about to be expired.
            const expireTime = DateFNS.addSeconds( now, response.expires_in - 60 ).getTime();
            this.state.set( 'GoogleTokenInfo', {
                access_token: response.access_token,
                expires_at: expireTime,
            });
            return true;
        }

        this.state.set( 'GoogleTokenInfo', {});
        return false;
    }
}

Object.defineProperty( GetGoogleAccessToken, 'name', {
    value: 'GetGoogleAccessToken',
});
