import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { StateService } from '../../controller/state.svc';

/**
 * This is responsible of listening to query parameters when
 * the app loads and inform subscribers to take necessary
 * actions according to the given subscriber id.
 *
 * @since   2021-10-06
 * @author  Vinoch
 */
@Injectable()
export class AppNavConfigInterpreter {

    /**
     * Tag identifier regex.
     */
    private regexTagIdentifier = /(\?|\&)([tag=]+)\=([^&]+)/g;

    /**
     * This holds the tag value and emit.
     */
    private tag: BehaviorSubject<string>;

    constructor( @Inject( StateService ) protected state: StateService< any, any > ) {
        this.tag = new BehaviorSubject<string>( '' );
        this.tagListener();
    }

    /**
     * This function receives the query parameter and validate
     * whether it has 'tag'. If so, it will extract that query
     * param and remove it and returns back the query.
     * @param query - url query param
     * @returns - url after the 'tag' extraction
     */
    public receiveQueryParam( query: string ): string {
        const matches = this.regexTagIdentifier.exec( query );
        if ( matches && matches[0] && matches[3]) {
            this.tag.next( matches[3]);
            return query.replace( matches[0], '' );
        }
        return query;
    }

    /**
     * All will subscribe to this who wants to do
     * navigation based on the given query action.
     * @param tagId - unique id to match the
     * app-nav-config file.
     * @returns - tags array.
     */
    public listenToTag( tagId: string ) {
        return this.state.changes( 'QueryTagAppNav' ).pipe(
            filter( tags => !!tags ),
            map( tags => {
                if ( tags[0] === tagId ) {
                    tags.shift();
                    return tags;
                }
                return EMPTY;
            }),
        );
    }

    /**
     * This will subscribe only once to this who wants to do
     * navigation based on the given query action.
     * @param tagId - unique id to match the
     * app-nav-config file.
     * @returns - tags array.
     */
    public listenToTagOnce( tagId: string ) {
        return this.state.changes( 'QueryTagAppNav' ).pipe(
            take( 1 ),
            map( tags => {
                if ( tags && tags[0] === tagId ) {
                    tags.shift();
                    return tags;
                }
                return EMPTY;
            }),
        );
    }

    /**
     * This listen to the tag emit and read the config file.
     */
    private tagListener() {
        this.tag.pipe(
            filter( tag => !!tag ),
            tap( tag => {
                fetch( './assets/app-nav-config/app-nav-config.json' )
                .then( response => response.json().then( data => {
                    if ( data ) {
                        const interpretedTags = this.interpretTag( data, tag );
                        const isValid = this.isValidTags( interpretedTags );
                        if ( isValid ) {
                            this.state.set( 'QueryTagAppNav', interpretedTags );
                        }
                    }
                }));
            }),
        ).subscribe();
    }

    /**
     * This function interpret the given query tag
     * into a readable format by the system.
     * @param data - app-nav-config json data.
     * @param tag - query tag.
     */
    private interpretTag( data: any, tag: string ) {
        let separatedTags = tag.split( '-' );
        separatedTags = separatedTags.map( t => {
            if ( t !== '0000' && data ) {
                data = data[t];
                if ( data ) {
                    return data.key;
                }
                return;
            }
            return '';
        });
        return separatedTags.filter( s => s !== '' );
    }

    /**
     * Validates the intepreted tags to make
     * sure that those are in required format.
     * @param tags - interpreted tags.
     * @return - return whether the tags are
     * valid or not.
     */
    private isValidTags( tags: string[]): boolean {
        tags = tags.filter( s => s !== '' );
        return tags.findIndex( s => s === undefined ) < 0 ? true : false;
    }
}
