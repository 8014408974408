/**
 * ConnectionError defines the errors caused during connecting to server
 * Eg. error caused when there is no connectivity
 *
 * @author  mehdhi
 * @since   2016-04-06
 */
export class ConnectionError extends Error {
    constructor( message ) {
        super()/* istanbul ignore next */;
        this.message = message;
        this.name = 'ConnectionError';
    }
}
