import { IDialogBoxData } from 'flux-definition';
import { PermissionError } from './permission-error';

/**
 * Permission error. This is thrown by creately apps whenever a user
 * tries to invoke and action that does not have sufficient plan
 * permission.
 */
export class DesktopPermissionError extends PermissionError {
    constructor( message?: string, public data?: IDialogBoxData ) {
        super( message )/* istanbul ignore next */;
        Object.setPrototypeOf( this, DesktopPermissionError.prototype );
    }
}
