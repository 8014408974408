import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Logger } from 'flux-core';
import { NeutrinoConnection } from 'flux-connection';
import { MessageFactory } from 'flux-connection';
import { IMessageJson } from 'flux-connection';
import { AbstractAuthentication } from 'flux-connection';
import { AbstractSubscriptionService } from '../framework/abstract-subscription.svc';
import { IListSubscription, SubscriptionStatus } from '../framework/subscription-type';
import { take, switchMap, tap } from 'rxjs/operators';

/**
 * ListSubscriptionService
 * ListSubscriptionService is the subscription service which handles model
 * type subscriptions.
 */
@Injectable()
export class ListSubscriptionService extends AbstractSubscriptionService {
    /**
     * subscriptions
     * subscriptions is a map of topic => subscription instances.
     */
    protected subscriptions: { [ topic: string ]: IListSubscription };

    /**
     * constructor
     * constructor initializes subscription maps and starts listening
     * to connection events. It will call handlers when connection status
     * changes.
     */
    constructor(
        protected logger: Logger,
        protected conn: NeutrinoConnection,
        protected authentication: AbstractAuthentication,
        protected messages: MessageFactory,
    ) {
        super( logger );
    }

    /**
     * startSubscription starts a list type subscription.
     */
    protected startSubscription( sub: IListSubscription ): Observable<any> {
        return sub.getAvailableModelIds().pipe(
            take( 1 ),
            switchMap( modelIds => this.sendSubStartRequest( sub, modelIds )),
            switchMap( message => this.handleSubStartResponse( sub, message )),
            tap({
                error: err => sub.updateStatus( SubscriptionStatus.errored, err ),
                complete: () => sub.updateStatus( SubscriptionStatus.started ),
            }),
        );
    }

    /**
     * closeSubscription closes a subscriptions. It will send a sub.end message.
     */
    protected closeSubscription( sub: IListSubscription ): Observable<any> {
        this.logger.debug( `SubscriptionService: closing subscription: ${sub.topic}` );
        return this.sendSubEndRequest( sub );
    }

    /**
     * sendSubStartRequest sends the sub.start message to Neutrino for list type subscriptions.
     */
    protected sendSubStartRequest( sub: IListSubscription, modelIds: string[]): Observable<IMessageJson> {
        const message = this.messages.createSubscriptionMessage( 'start', sub.subscription, sub.resourceId );
        message.authToken = this.authentication.token;
        message.payload = { modelIds };
        return this.conn.send( message );
    }

    /**
     * sendSubEndRequest sends the sub.end message to Neutrino for model type subscriptions.
     */
    protected sendSubEndRequest( sub: IListSubscription ): Observable<IMessageJson> {
        const message = this.messages.createSubscriptionMessage( 'end', sub.subscription, sub.resourceId );
        message.authToken = this.authentication.token;
        message.payload = {};
        return this.conn.send( message );
    }

    /**
     * handleSubStartResponse handles sub.start response messages.
     */
    protected handleSubStartResponse( sub: IListSubscription, msg: IMessageJson ): Observable<any> {
        // TODO remove any models which got deleted on the server
        // TODO create subscriptions for current models
        return of();
    }
}
