<div class="box-container fx-padding-16">
    <div *ngIf="title" class="header fx-margin-bottom-20" [ngClass]="{ 'fx-justify-space-between empty-icon fx-align-center' : titleIcon }">
        <img *ngIf="isImage(titleIcon)" src="{{titleIcon}}"/>
    </div>
    <div *ngIf="body && body.length > 1">
        <div>
            <div *ngFor="let point of body; let i = index">
                <p class="caption message-text">{{translatedBodyItem(i)}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="body && body.length === 1">
        <p class="caption message-text">{{translatedBodyItem(0)}}</p>
    </div>
    <button *ngIf="button && button.label" class="btn-medium btn-secondary fx-margin-left-0 fx-margin-top-15 fx-flex fx-align-center" (click)="buttonClicked.next(true)">
        <svg *ngIf="button.icon" class="nu-icon nu-icon-xsmall fx-margin-right-5"><use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + button.icon"></use></svg>
        {{translatedButtonLabel}}
    </button>
</div>
