import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { IKeyboardEventData } from 'flux-definition';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * DoShapeAction
 * This command invokes a function which performs an action on a shape.
 * The function invoked here is an alterFunction exposed by a shapes
 * {@link ILogicDefinition} implementation. If the shape ids are not
 * input to the command, change will be applied to the currently selected
 * shapes.
 *
 * @author  Ramishka
 * @since   2018-11-23
 *
 */
@Injectable()
@Command()
export class DoShapeAction extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds?: string[];
        alterFunction: string;
        keyboardEvent?: KeyboardEvent;
    };

    /**
     * Inject the state service to get the list of selected shape ids.
     */
    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( ds );
    }

    /**
     * Invoke the alterFunction on the changeModel.
     */
    public prepareData() {
        const alterFunction = this.data.alterFunction;
        let shapeIds = this.data.shapeIds;
        const keyboardEvent = this.data.keyboardEvent;
        // Block shape action if suggestions is being selected via Enter key
        if ( keyboardEvent && keyboardEvent.defaultPrevented &&
            this.state.get( 'EditorSuggestions' ).suggestions.length > 0 ) {
            return;
        }
        if ( !shapeIds || shapeIds.length === 0 ) {
            shapeIds = this.state.get( 'Selected' );
        }
        if ( !shapeIds || shapeIds.length === 0 || ( !alterFunction && !keyboardEvent )) {
            return;
        }
        for ( const id of shapeIds ) {
            let returnValue;
            const model = this.changeModel;
            if ( !model.shapes[id][alterFunction]) {
                throw new Error( `The alter function '${alterFunction}' was not implemented in shape logic.` );
            }
            if ( keyboardEvent ) {
                returnValue = this.doKeyboardAction( id );
            } else {
                returnValue = model.shapes[id][alterFunction]( model.shapes[id], this.changeModel );
            }

            // Update inner selection
            const innerSelection = this.state.get( 'InnerShapeSelection' );
            if ( !returnValue && innerSelection[id]) {
                // FIXME: Investigate why this was needed
                // delete innerSelection[ id ];
                // this.state.set( 'InnerShapeSelection', innerSelection );
            }
        }
    }

    /**
     * Function to perform the necessary actions for the keyboard
     * event shortcut
     * @param shapeId : shape id
     */
    private doKeyboardAction( shapeId: string ) {
        const keyboardEvent = this.data.keyboardEvent;
        const alterFunction = this.data.alterFunction;

        const model = this.changeModel;
        const eventData: IKeyboardEventData = {
            key: keyboardEvent.key,
            keyCode: keyboardEvent.keyCode,
            shiftKey: keyboardEvent.shiftKey,
            controlKey: keyboardEvent.ctrlKey,
            metaKey: keyboardEvent.metaKey,
            altKey: keyboardEvent.altKey,
            data: {
                textId: this.state.get( 'EditingText' ).textId,
                text: this.state.get( 'TextContent' ).text,
            },
        };

        // get new text id from logic class function and move cursor
        const textId = model.shapes[shapeId][alterFunction]( model.shapes[shapeId], eventData );

        const stateToCommit = this.state.get( 'EditingText' );
        if ( textId && textId !== stateToCommit.textId ) {
            // Assign result data to close current text editor and open on new textid
            this.resultData = [
                Object.assign( this.state.get( 'EditingText' ), { open: false }),
                {
                    open: true,
                    shapeId: shapeId,
                    textId: textId,
                    point: null,
                    caretPosition: 'end',
                },
            ];
        }

        return textId;
    }
}

Object.defineProperty( DoShapeAction, 'name', {
    value: 'DoShapeAction',
});
