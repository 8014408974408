import { AbstractCommand, Command, StateService, CommandInterfaces, Rectangle } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DiagramToViewportCoordinate } from '../../coordinate/diagram-to-viewport-coordinate.svc';
import { IPoint2D } from 'flux-definition';

/**
 * This command will pan to a given point or to a given shape.
 * If a shape is given it will be panned to middle of the shape.
 * TODO: Improve this to handle multiple shapes.
 *
 * @author Shermin
 * @since 28-09-2019
 */
@Injectable()
@Command()
export class FocusToPoint extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            coordinates: false, // Indicates the x,y position to pan to. (Used by state)
            shapeIds: false, // Indicates the shapes to be panned to.
            groupId: false, // Indicates the group to be panned to.
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor( protected state: StateService<any, any>,
                 protected ll: DiagramLocatorLocator,
                 protected diagramToViewPort: DiagramToViewportCoordinate ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            DiagramPan: this.data.panStateData,
        };
    }

    protected get locator() {
        return this.ll.forCurrent( true );
    }

    /**
     * Prepares the data necessary for the pan state change.
     */
    public prepareData(): void {
        if ( this.data.shapeIds && this.data.shapeIds[0] || this.data.groupId ) {
            this.data.panStateData = this.panToShapes( this.data.shapeIds, this.data.groupId );
        } else if ( this.data.coordinates ) {
            this.data.panStateData = this.calculatePan( this.data.coordinates );
        }
    }

    public execute (): boolean {
        return true;
    }

    /**
     * This method will pans the diagram to given method.
     * @param shapeId - shape id to be panned.
     * @return an observable of IPoint2D, emits only once.
     */
    protected panToShapes( shapeIds: string[], gId?: string ):
        Observable<IPoint2D> {
        return this.locator.getDiagramOnce().pipe(
            map( d => {
                shapeIds = shapeIds.filter( id => d.shapes[ id ]);
                let bounds;
                const ids = shapeIds || d.getShapesInGroup( gId );
                if ( ids.length === 0 ) {
                    bounds = d.getBounds();
                } else {
                    bounds = d.getBounds( shapeIds );
                }
                const itemBounds: Rectangle = bounds;
                const point = { x: itemBounds.centerX, y: itemBounds.centerY };
                return this.calculatePan( point );
            }),
        );
    }

    /**
     * Calculates the pan for any given point to center of viewport
     * @param point - point to be centered
     * @returns centered point
     */
    private calculatePan( point: IPoint2D ): IPoint2D {
        const viewport = this.state.get( 'DiagramViewPort' );
        const viewportX = this.diagramToViewPort.x( point.x );
        const viewportY = this.diagramToViewPort.y( point.y );
        const currentPan = this.state.get( 'DiagramPan' );
        return {
            x: currentPan.x - ( viewportX - viewport.centerX ),
            y: currentPan.y - ( viewportY - viewport.centerY ),
        };
    }
}

Object.defineProperty( FocusToPoint, 'name', {
    value: 'FocusToPoint',
});

