

// tslint:disable:max-line-length
import { AppConfig } from 'flux-core';

/**
 * Manage SVG fonts
 */
// FIXME: Ignoring as tests are not written for this class
/* istanbul ignore file */
export class SVGFont {

    /**
     * Return SVG style definition for all fonts available for creately diagraming platform.
     * @param fonts - List of font families for which you want the font face decorators.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public static fontFaces( fonts?: string[]): string {
        const baseUrl = AppConfig.get( 'BASE_URL' );
        let fontFace = '<defs><style type="text/css">';
        if ( !fonts || ( fonts && fonts.includes( 'lt_bold' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt_bold';
                src: url('${baseUrl}/assets/fonts/lato/lt-bold-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/lato/lt-bold-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'lt_bold_italic' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt_bold_italic';
                src: url('${baseUrl}/assets/fonts/lato/lt-bolditalic-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/lato/lt-bolditalic-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'lt_italic' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt_italic';
                src: url('${baseUrl}/assets/fonts/lato/lt-italic-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/lato/lt-italic-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'lt_regular' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt_regular';
                src: url('${baseUrl}/assets/fonts/lato/lt-regular-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/lato/lt-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'lt-hairline' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt-hairline';
                src: url('${baseUrl}/assets/fonts/embed/lt-hairline-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/lt-hairline-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'lt-thinitalic' ))) {
            fontFace += `
            @font-face {
                font-family: 'lt-thinitalic';
                src: url('${baseUrl}/assets/fonts/embed/lt-thinitalic-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/lt-thinitalic-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'champagne' ))) {
            fontFace += `
            @font-face {
                font-family: 'champagne';
                src: url('${baseUrl}/assets/fonts/embed/champagne-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/champagne-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'indie' ))) {
            fontFace += `
            @font-face {
                font-family: 'indie';
                src: url('${baseUrl}/assets/fonts/embed/indie-flower-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/indie-flower-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'bebas' ))) {
            fontFace += `
            @font-face {
                font-family: 'bebas';
                src: url('${baseUrl}/assets/fonts/embed/bebasneue_regular-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/bebasneue_regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'bree' ))) {
            fontFace += `
            @font-face {
                font-family: 'bree';
                src: url('${baseUrl}/assets/fonts/embed/breeserif-regular-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/breeserif-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'spartan' ))) {
            fontFace += `
            @font-face {
                font-family: 'spartan';
                src: url('${baseUrl}/assets/fonts/embed/leaguespartan-bold-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/leaguespartan-bold-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'montserrat' ))) {
            fontFace += `
            @font-face {
                font-family: 'montserrat';
                src: url('${baseUrl}/assets/fonts/embed/montserrat-medium-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/montserrat-medium-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'open_sanscondensed' ))) {
            fontFace += `
            @font-face {
                font-family: 'open_sanscondensed';
                src: url('${baseUrl}/assets/fonts/embed/opensans-condlight-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/opensans-condlight-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'open_sansitalic' ))) {
            fontFace += `
            @font-face {
                font-family: 'open_sansitalic';
                src: url('${baseUrl}/assets/fonts/embed/opensans-italic-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/opensans-italic-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'playfair' ))) {
            fontFace += `
            @font-face {
                font-family: 'playfair';
                src: url('${baseUrl}/assets/fonts/embed/playfairdisplay-regular-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/playfairdisplay-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'raleway' ))) {
            fontFace += `
            @font-face {
                font-family: 'raleway';
                src: url('${baseUrl}/assets/fonts/embed/raleway-regular-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/raleway-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'courier_prime' ))) {
            fontFace += `
            @font-face {
                font-family: 'courier_prime';
                src: url('${baseUrl}/assets/fonts/embed/courier_prime-webfont.woff2') format('woff2'),
                    url('${baseUrl}/assets/fonts/embed/courier_prime-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'droid_serifregular' ))) {
            fontFace += `
            @font-face {
                font-family: 'droid_serifregular';
                src: url('${baseUrl}/assets/fonts/embed/droid-serif-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'Inter' ))) {
            fontFace += `
            @font-face {
                font-family: 'Inter';
                src: url('${baseUrl}/assets/fonts/embed/Inter-Regular.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
            fontFace += `
            @font-face {
                font-family: 'Inter';
                src: url('${baseUrl}/assets/fonts/embed/Inter-Italic.woff') format('woff');
                font-weight: normal;
                font-style: italic;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'abhaya_libreregular' ))) {
            fontFace += `
            @font-face {
                font-family: 'abhaya_libreregular';
                src: url('${baseUrl}/assets/fonts/embed/abhaya-libre-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'gandhi_serifregular' ))) {
            fontFace += `
            @font-face {
                font-family: 'gandhi_serifregular';
                src: url('${baseUrl}/assets/fonts/embed/gandhi-serif-regular-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'noto_bold' ))) {
            fontFace += `
            @font-face {
                font-family: 'noto_bold';
                src: url('${baseUrl}/assets/fonts/noto/noto-bold.woff') format('woff'),
                     url('${baseUrl}/assets/fonts/noto/noto-bold.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'noto_bolditalic' ))) {
            fontFace += `
            @font-face {
                font-family: 'noto_bolditalic';
                src: url('${baseUrl}/assets/fonts/noto/noto-bolditalic.woff') format('woff'),
                     url('${baseUrl}/assets/fonts/noto/noto-bolditalic.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'noto_italic' ))) {
            fontFace += `
            @font-face {
                font-family: 'noto_italic';
                src: url('${baseUrl}/assets/fonts/noto/noto-italic.woff') format('woff'),
                     url('${baseUrl}/assets/fonts/noto/noto-italic.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        if ( !fonts || ( fonts && fonts.includes( 'noto_regular' ))) {
            fontFace += `
            @font-face {
                font-family: 'noto_regular';
                src: url('${baseUrl}/assets/fonts/noto/noto-regular.woff') format('woff'),
                     url('${baseUrl}/assets/fonts/noto/noto-regular.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
            }`;
        }
        fontFace += `
        p {
            margin: 0;
        }
        </style></defs>`;
        return fontFace;
    }
}
