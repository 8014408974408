import { Component, Input } from '@angular/core';
/**
 * This component is used for showing the loading spinner when
 * content is being loaded.
 *
 * @author hiraash
 * @since 25/07/2016
 */

@Component({
    selector: 'loading-indicator',
    template: `<div class="{{classString}}">
                    <div *ngIf="showing" class="fx-spinner fx-center-all">
                    <i class="{{spinnerClass}}"></i>
               </div></div>`,
})
export class LoadingIndicator {

    /**
     * This is a input that can be used to style the loading indicator. This is a
     * string containing one or more css classes. Container styles as well as styles
     * relevant to full screen mode are among the classes that can be passed in here.
     */
    @Input() public classString: string;

    /**
     * This is an input to define css class (or classes) that are used to
     * style the spinner of the loading indicator.
     */
    @Input() public spinnerClass: string;

    /**
     * Internal property to identify if the loader
     * is active
     */
    @Input() public showing: boolean = false;

    /**
     * Indicates if the loading indicator is
     * currently showing.
     */
    public get isLoading(): boolean {
        return this.showing;
    }

    /**
     * Dismisses the loading. Must be called
     * since loader will be active always when starting.
     * This will remove the loading indicator.
     */
    public complete() {
        this.showing = false;
    }

    /**
     * Method can be used to start the loading
     * indicator.
     */
    public start() {
        this.showing = true;
    }

}
