import RBush from 'rbush';
import knn from 'rbush-knn';

/**
 * RTree2D
 * Spatial data structure to store geometric nodes.
 * A node can be a point or a rectangle area.
 * Usefull when searching nodes in a given area.
 *
 * @author thisun
 * @since 2020-09-15
 */
export class RTree2D {

    protected tree;
    protected list;

    constructor( size?: number ) {
        this.tree = new RBush( size );
        this.list = {};
    }

    /**
     * Loads a set of nodes into the treaa
     * @param node
     */
    public load( nodes: IRtreeNode[]) {
        this.tree.load( nodes.map( node => this.createRBushItem( node )));
    }

    /**
     * Inserts a node
     * @param node
     */
    public insert( node: IRtreeNode ) {
        this.tree.insert( this.createRBushItem( node ));
    }

    /**
     * Removes the node from the tree and the list
     * @param id
     */
    public removeById( id: string ) {
        const node = this.list[ id ];
        this.tree.remove( node );
        delete this.list[ id ];
    }

    /**
     * Find the node by Id and updates it
     * @param id
     */
    public update( node: IRtreeNode ) {
        this.removeById( node.id );
        this.insert( node );
    }

    public has( id: string ) {
        return !!this.list[ id ];
    }

    /**
     * Clear all the nodes
     * @param node
     */
    public clear() {
        this.list = {};
        this.tree.clear();
    }

    public all() {
        return this.tree.all().map( item => this.bushItemToRTreeNode( item ));
    }

    /**
     * Returns the neighbours relative to the given x,y point
     * k is the number of neghbours
     */
    public getNeighbors( x: number, y: number, k = Infinity ) {
        return knn( this.tree, x, y, k );
    }

    /**
     * Returns the bounds of the distribution
     */
    public getBounds() {
        const left = this.getNeighbors( -Number.MAX_SAFE_INTEGER, 0, 1 );
        if ( !left[ 0 ]) { // tree is empty
            return { x: 0, y: 0, width: 0, height: 0 };
        }
        const top = this.getNeighbors( 0, -Number.MAX_SAFE_INTEGER, 1 );
        const bottom = this.getNeighbors( 0, Number.MAX_SAFE_INTEGER, 1 );
        const right = this.getNeighbors( Number.MAX_SAFE_INTEGER, 0, 1 );
        const x = left[0].minX;
        const y = top[0].minY;
        const width = right[0].maxX - x;
        const height = bottom[0].maxY - y;
        return { x, y, width, height };
    }

    /**
     * Search for nodes
     * @param node
     */
    public search( minX: number, maxX: number, minY: number, maxY: number ) {
        return this.tree.search({
            minX,
            minY,
            maxX,
            maxY,
        }).map( item => this.bushItemToRTreeNode( item ));
    }

    protected createRBushItem( node: IRtreeNode ) {
        const rBushItem = { ...node } as any;
        delete rBushItem.x;
        delete rBushItem.y;
        delete rBushItem.width;
        delete rBushItem.height;
        rBushItem.minX = node.x;
        rBushItem.minY = node.y;
        rBushItem.maxX = node.x + ( node.width || 0 );
        rBushItem.maxY = node.y + ( node.height || 0 );
        this.list[ node.id ] = rBushItem;
        return rBushItem;
    }

    protected bushItemToRTreeNode( item: any ) {
        const rTreeNode = { ...item } as any;
        delete rTreeNode.maxX;
        delete rTreeNode.maxY;
        delete rTreeNode.minX;
        delete rTreeNode.minY;
        rTreeNode.x = item.minX;
        rTreeNode.y = item.minY;
        rTreeNode.width = item.maxX - item.minX;
        rTreeNode.height = item.maxY - item.minY;
        return rTreeNode;
    }

}

/**
 * IRtreeNode can be either a point or rectangle,
 * if the width and height are defined it's a rectangle
 */
export interface IRtreeNode {
    id: string;
    x: number;
    y: number;
    width?: number;
    height?: number;
    data?: any;
}
