import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IErrorMessageHandler, ModalController } from 'flux-core';
import { UpgradeDialog } from '../view/upgrade-dialog.cmp';
import { DesktopPermissionError } from './desktop-permission-error';
import { PermissionError } from './permission-error';

/**
 * This is an error handler which intercepts and handles all kinds of
 * permission errors.
 *
 * @author  Ramishka
 * @since   2019-06-27
 */
@Injectable()
export class PermissionErrorHandler implements IErrorMessageHandler {

    constructor( protected modalController: ModalController, protected router: Router ) {}
    /**
     * Implementation of IErrorMessageHandler shows the upgrade dialog if the
     * error is a permission error.
     * The error.data passed into the component is of type I
     * @param error Error tho handle.
     */
    public handle( error: Error ): boolean {
        if ( error instanceof DesktopPermissionError ) {
            this.router.navigateByUrl( '../desktop-permission-error' );
            return true;
        } else if ( error instanceof PermissionError ) {
            this.modalController.show( UpgradeDialog, {
                inputs: {
                    dialogData: error.data,
                },
            });
            return true;
        }
        return false;
    }
}
