import { GetUserProjectsInfo } from './get-user-projects-info.cmd';
import { DeleteEData } from './delete-edata.cmd';
import { DeleteProject } from './delete-project.cmd';
import { CommandMapper, CommandEvent, ICommandSequenceProgress } from 'flux-core';
import { CreateProject } from './create-project.cmd';
import { GetUserProjects } from './get-user-projects.cmd';
import { GetProjectDiagrams } from './get-project-diagrams.cmd';
import { RenameProject } from './rename-project.cmd';
import { RemoveProjectCollaborators } from './remove-project-collaborators.cmd';
import { AddProjectCollaborators } from './add-project-collaborators.cmd';
import { ChangeProjectCollaborators } from './change-project-collaborators.cmd';
import { RemoveProjectGroupShare } from './remove-project-group-share.cmd';
import { AddProjectGroupShare  } from './add-project-group-share.cmd';
import { ChangeProjectGroupShare  } from './change-project-group-share.cmd';
import { ChangeProjectPrivacy } from './change-project-privacy.cmd';
import { DeleteDiagram } from './delete-diagram.cmd';
import { MoveDiagram } from './move-diagram.cmd';
import { ProjectChange } from './project-change.cmd';
import { GetProjectEData } from './get-project-edata.cmd';
import { AddEdataToProject } from './add-edata-to-project.cmd';
import { RemoveDiagramTask } from '../../task/command/remove-diagram-task.cmd';
import { RemoveFolderTask } from '../../task/command/remove-folder-task.cmd';
import { DuplicateProject } from './duplicate-project.cmd';
import { UpdateProjectDesc } from './update-project-desc.cmd';
import { UpdateProjectNav } from './update-project-nav.cmd';
import { GetProjectsAndDiagramsCommand } from './get-projects-and-diagrams.cmd';
import { CreateProjectTeam } from './create-project-team.cmd';
import { MoveTasks } from '../../task/command/move-tasks.cmd';
import { ChangeProjectTeamShare } from './change-project-team-share.cmd';
import { ArchiveProject } from './archive-project.cmd';
import { GetArchivedProjects } from './get-archived-projects.cmd';
import { UnarchiveProject } from './unarchive-project.cmd';
import { ImportMiroDiagramPdf } from './import-miro-diagram-pdf.cmd';
import { ImportLucidDiagramSvg } from './import-lucid-diagram-svg.cmd';
import { GetExternalImportProcessingFiles } from './get-external-import-processing-files.cmd';

/**
 * This class holds the defined events for all command invocations
 * that are on project resources and also responsible to register an event
 * to a sequence of commands or parallel of commands.
 * Each event will associate to one or more commands that hold the resource
 * type 'project'.
 *
 * @author  hiraash
 * @since   2016-03-26
 */
// tslint:disable: member-ordering
export class ProjectCommandEvent extends CommandEvent {

    /**
     * Create a new project
     *
     *  Input Data: No input data required but can specify a name, default name will be 'New Project'
     *  Input Data: {
     *      name: string,
     *  }
     *
     *
     */
    static createProject: ProjectCommandEvent = new ProjectCommandEvent( 'CreateProject' );
    protected static registerCreateProject( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.createProject ).add( CreateProject as any );
    }

    /**
     * Create a new project for team
     *
     *  Input Data: No input data required but can specify a name, default name will be 'New Project'
     *  Input Data: {
     *      name: string,
     *  }
     *
     *
     */
    static createProjectTeam: ProjectCommandEvent = new ProjectCommandEvent( 'CreateProjectTeam' );
    protected static registerCreateProjectTeam( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.createProjectTeam ).add( CreateProjectTeam as any );
    }

    /**
     * Create a new project and share with team
     *
     *  Input Data: No input data required but can specify a name, default name will be 'New Project'
     *  Input Data: {
     *      name: string,
     *  }
     *
     *
     */
    static createProjectAndShareWithTeam: ProjectCommandEvent = new ProjectCommandEvent(
        'CreateProjectAndShareWithTeam' );
    protected static registerCreateProjectAndShareWithTeam( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.createProjectAndShareWithTeam )
            .add( CreateProject as any )
            .add( ChangeProjectPrivacy as any, {
                transform: progress => this.transformDataForCreateAndShareWithTeam( progress ),
            });
    }

    /**
     * Duplicate from a project
     *
     * Input Data: No input data required
     *
     */
     static duplicateProject: ProjectCommandEvent = new ProjectCommandEvent( 'DuplicateProject' );
     protected static registerDuplicateProject( mapper: CommandMapper ) {
         mapper.map( ProjectCommandEvent.duplicateProject ).add( DuplicateProject as any );
     }

    /**
     * Get all the projects acceciable for the logged in user
     *
     *  Input Data: No input data required
     *
     */
    static getUserProjects: ProjectCommandEvent = new ProjectCommandEvent( 'getUserProjects' );
    protected static registerGetUserProjects( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.getUserProjects ).add( GetUserProjects as any );
    }

    /**
     * Get all the projects acceciable for the logged in user
     *
     *  Input Data: No input data required
     *
     */
    static getUserProjectsInfo: ProjectCommandEvent = new ProjectCommandEvent( 'getUserProjectsInfo' );
    protected static registerGetUserProjectsInfo( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.getUserProjectsInfo ).add( GetUserProjectsInfo as any );
    }

    /**
     * Get all the diagrams in the project specified by the project id
     *
     *  Input Data: The projectId should be set as the resource id
     *  when dispatching the command.
     *
     */
    static getProjectDiagrams: ProjectCommandEvent = new ProjectCommandEvent( 'GetProjectDiagrams' );
    protected static registerGetProjectDiagrams( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.getProjectDiagrams ).add( GetProjectDiagrams as any );
    }


    /**
     * Get all the edata in the project specified by the project id
     *
     *  Input Data: The projectId should be set as the resource id
     *  when dispatching the command.
     *
     */
     static getProjectEData: ProjectCommandEvent = new ProjectCommandEvent( 'GetProjectEData' );
     protected static registerGetProjectEData( mapper: CommandMapper ) {
         mapper.map( ProjectCommandEvent.getProjectEData ).add( GetProjectEData as any );
     }


    /**
     *  Rename the project
     *
     *  Input Data: {
     *      name: string,
     *  }
     *
     */
    public static renameProject = new ProjectCommandEvent( 'RenameProject' );
    protected static registerRenameProject( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.renameProject )
            .add( RenameProject as any )
            .add( ProjectChange as any, {
                transform( progress ) {
                    return progress.resultData[0];
                },
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Removes project collaborators
     */
    public static removeProjectCollaborators: ProjectCommandEvent
        = new ProjectCommandEvent( 'RemoveProjectCollaborators' );
    protected static registerRemoveProjectCollaborators( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.removeProjectCollaborators ).add( RemoveProjectCollaborators as any );
    }

    /**
     * Adds project collaborators
     */
    public static addProjectCollaborators: ProjectCommandEvent
        = new ProjectCommandEvent( 'AddProjectCollaborators' );
    protected static registerAddProjectCollaborators( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.addProjectCollaborators ).add( AddProjectCollaborators as any );
    }

    /**
     * Changes project collaborators
     */
    public static changeProjectCollaborators: ProjectCommandEvent
        = new ProjectCommandEvent( 'ChangeProjectCollaborators' );
    protected static registerChangeProjectCollaborators( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.changeProjectCollaborators ).add( ChangeProjectCollaborators as any );
    }

    /**
     * Removes project collaborators
     */
    public static removeProjectGroupShares: ProjectCommandEvent
        = new ProjectCommandEvent( 'RemoveProjectGroupShares' );
    protected static registerRemoveProjectGroupShares( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.removeProjectGroupShares ).add( RemoveProjectGroupShare as any );
    }

    /**
     * Adds project collaborators
     */
    public static addProjectGroupShares: ProjectCommandEvent
        = new ProjectCommandEvent( 'AddProjectGroupShares' );
    protected static registerAddProjectGroupShares( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.addProjectGroupShares ).add( AddProjectGroupShare as any );
    }

    /**
     * Changes project collaborators
     */
    public static changeProjectGroupShares: ProjectCommandEvent
        = new ProjectCommandEvent( 'ChangeProjectGroupShares' );
    protected static registerChangeProjectGroupShares( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.changeProjectGroupShares ).add( ChangeProjectGroupShare as any );
    }

    /**
     * Changes project privacy
     */
    public static changeProjectPrivacy: ProjectCommandEvent
        = new ProjectCommandEvent( 'ChangeProjectPrivacy' );
    protected static registerChangeProjectPrivacy( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.changeProjectPrivacy ).add( ChangeProjectPrivacy as any );
    }

    /**
     * Changes project team share
     */
    public static changeProjectTeamShare: ProjectCommandEvent
        = new ProjectCommandEvent( 'ChangeProjectTeamShare' );
    protected static registerChangeProjectTeamShare( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.changeProjectTeamShare ).add( ChangeProjectTeamShare as any );
    }

    /**
     * Deletes the specified project
     */
    public static deleteProject: ProjectCommandEvent
        = new ProjectCommandEvent( 'DeleteProject' );
    protected static registerDeleteProject( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.deleteProject )
        .add( RemoveFolderTask as any )
        .add( DeleteProject as any );
    }

    /**
     * Archives the specified project
     */
    public static archiveProject: ProjectCommandEvent
        = new ProjectCommandEvent( 'ArchiveProject' );
    protected static registerArchiveProject( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.archiveProject )
        .add( ArchiveProject as any );
    }

    /**
     * Unarchives the specified project
     */
    public static unarchiveProject: ProjectCommandEvent
        = new ProjectCommandEvent( 'UnarchiveProject' );
    protected static registerUnarchiveProject( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.unarchiveProject )
        .add( UnarchiveProject as any );
    }

    /**
     * Get all archived projects of the user
     */
    public static getArchivedProjects: ProjectCommandEvent
        = new ProjectCommandEvent( 'GetArchivedProjects' );
    protected static registerGetArchivedProjects( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.getArchivedProjects )
        .add( GetArchivedProjects as any );
    }

    /**
     * Deletes the specified project
     */
    public static moveDiagram: ProjectCommandEvent
        = new ProjectCommandEvent( 'MoveDiagram' );
    protected static registerMoveDiagram( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.moveDiagram )
            .add( MoveDiagram as any )
            .add( MoveTasks as any, {
                transform( progress ) {
                    return progress.resultData[0][0];
                },
            });
    }

    /**
     * Deletes the diagram specified by the diagram id
     *
     *  Input Data: The diagramId should be set as the resource id
     *  when dispatching the command.
     *
     */
    static deleteDiagram: ProjectCommandEvent = new ProjectCommandEvent( 'DeleteDiagram' );
    protected static registerDeleteDiagram( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.deleteDiagram )
            .add( RemoveDiagramTask as any )
            .add( DeleteDiagram as any );
    }

    /**
     * Deletes the edata specified by the id
     *
     *  Input Data: The edataID should be set as the resource id
     *  when dispatching the command.
     *
     */
    static deleteEData: ProjectCommandEvent = new ProjectCommandEvent( 'deleteEData' );
    protected static registerDeleteEdata( mapper: CommandMapper ) {
        mapper.mapSequence( ProjectCommandEvent.deleteEData )
            .add( DeleteEData as any )
            .add( AddEdataToProject as any, {
                transform( progress ) {
                    return progress.resultData[0][0];
            }})
            .add( ProjectChange as any, {
                transform( progress ) {
                    return progress.resultData[1];
                },
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });

    }

    /**
     * Transforms data (if available) so that
     * they can be used with the Create command.
     * @return transformed data
     */
    private static transformDataForCreateAndShareWithTeam( progress: ICommandSequenceProgress ): any {
        const data = progress.data[0];
        if ( data ) {
            data.privacy = {
                level: 2,
                role: 5,
            };
        }
        return data;
    }

    /**
     * updates the project nav in the project
     */
     static updateProjectNav: ProjectCommandEvent = new ProjectCommandEvent( 'updateProjectNav' );
     protected static registerUpdateProjectNav( mapper: CommandMapper ) {
         mapper.mapSequence( ProjectCommandEvent.updateProjectNav )
             .add( UpdateProjectNav as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
             .add( ProjectChange as any, {
                 transform( progress ) {
                     return progress.resultData[0];
                 },
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             });

     }

    /**
     * updates the project nav in the project
     */
     static updateProjectDesc: ProjectCommandEvent = new ProjectCommandEvent( 'updateProjectDesc' );
     protected static registerUpdateProjectDesc( mapper: CommandMapper ) {
         mapper.mapSequence( ProjectCommandEvent.updateProjectDesc )
             .add( UpdateProjectDesc as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
             .add( ProjectChange as any, {
                 transform( progress ) {
                     return progress.resultData[0];
                 },
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             });

     }

    /**
     * Get the projects and diagrams in single payload
     */
    static getProjectsAndDiagramsCommand: ProjectCommandEvent =
        new ProjectCommandEvent( 'getProjectsAndDiagramsCommand' );
    protected static registerGetProjectsAndDiagramsCommand( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.getProjectsAndDiagramsCommand ).add( GetProjectsAndDiagramsCommand as any );
    }

    /**
     * Import miro diagram pdf file
     */
    static importMiroDiagramPdfCommand: ProjectCommandEvent =
        new ProjectCommandEvent( 'importMiroDiagramPdfCommand' );
    protected static registerImportMiroDiagramPdfCommand( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.importMiroDiagramPdfCommand ).add( ImportMiroDiagramPdf as any );
    }

    /**
     * Import lucidchart diagram svg file
     */
    static importImportLucidDiagramSvgCommand: ProjectCommandEvent =
        new ProjectCommandEvent( 'importImportLucidDiagramSvgCommand' );
    protected static registerImportImportLucidDiagramSvgCommand( mapper: CommandMapper ) {
        mapper.map( ProjectCommandEvent.importImportLucidDiagramSvgCommand ).add( ImportLucidDiagramSvg as any );
    }

    static getExternalImportProcessingFiles: ProjectCommandEvent =
        new ProjectCommandEvent( 'getExternalImportProcessingFiles' );
    protected static registerGetExternalImportProcessingFiles( mapper: CommandMapper ) {
        mapper
            .map( ProjectCommandEvent.getExternalImportProcessingFiles )
            .add( GetExternalImportProcessingFiles as any );
    }
}
