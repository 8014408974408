import { once } from 'lodash';
import { AbstractModel } from 'flux-core';
import { AppConfig } from 'flux-core';

const AVAILABLE_COLORS = [
    '#EE0D0D',
    '#7C54C9',
    '#75A75F',
    '#3946B2',
    '#E6BA00',
    '#DA5E8E',
    '#779C9C',
    '#465A83',
    '#4DA037',
    '#FF5F5F',
    '#CA4CDD',
    '#1F8DBC',
    '#24B35A',
    '#F0219B',
    '#3CB0AC',
    '#FB8F65',
    '#8A8269',
    '#FCA200',
    '#006CFF',
    '#FF5100',
    '#00A6D5',
    '#FB316A',
    '#7F8839',
    '#75A810',
    '#693836',
    '#2C6CAC',
    '#A22A49',
    '#9821B7',
    '#1C8879',
    '#C3830B',
    '#B0B500',
    '#003BA7',
    '#B86E2D',
    '#A376FF',
    '#54772E',
];

export enum UserStatus {
    /** * The user is enabled and active.
     */
    ACTIVE,

    /**
     * The user is disabled.
     */
    DISABLED,

    /**
     * The user has to confirm his email address after signup.
     */
    EMAIL_TO_CONFIRM,

    /**
     * The user has payments pending.
     */
    PAYMENT_PENDING,

    /**
     * The user is invited to a team plan. He has to confirm or
     * deny the invitation.
     */
    TEAM_CONFIRM,

    /**
     * The user is not registered yet. The user information is either
     * added by someone else or this user is expected to joiin.
     */
    UNREGISTERED,

    /**
     * Demo user status
     */
    DEMO,

}

/**
 * UserInfoModel
 * This is the smallest form of a User model. Contains
 * very basic details about a user. This is extended by
 * all user related models.
 *
 * @author  hiraash
 * @since   2015-10-07
 */
export class UserInfoModel extends AbstractModel {
    /**
     * The first name of the user
     */
    public firstName: string;

    /**
     * The last name of the user
     */
    public lastName: string;

    /**
     * The email address of the user
     * This is also the username for authentication
     */
    public email: string;

    /**
     * The current status of the user
     * as s string
     */
    public userStatus: string;

    /**
     * This boolean holds email confirmation is pending or not.
     * NOTE: User status has EMAIL_TO_CONFIRM status which also
     * returns the same value. However if a DEMO user confirms
     * the email, still the status returns as DEMO where we
     * cannot identify whether the user has confirmed the email.
     * Due to that, we are using this property.
     */
    public emailToConfirm: boolean;

    /**
     * The imageUrl is 1 if the user has a profile image and
     * otherwise imageUrl will be 0
     */
    public imageUrl: string;

    constructor( id: string, extension?: Object ) {
        super( id, extension );
        this.getColor = once( this.getColor.bind( this ));
    }

    /**
     * The current status of the user
     * as type of UserStatus
     */
    public get status(): UserStatus {
        return UserStatus[ this.userStatus ];
    }

    /**
     * Indicates if the user is a registered user.
     */
    public get isRegistered(): boolean {
        return this.status !== UserStatus.UNREGISTERED;
    }

    /**
     * Returns the the link of the user image by generating
     * the link with the userId
     */
    public get image(): string {
        return AppConfig.get( 'USER_IMAGE_BASE_URL' ) + this.id ;
    }

    /**
     * Returns the full name of the user
     */
    public get fullName(): string {
        const f: string = this.firstName ? this.firstName : '';
        const l: string = this.lastName ? this.lastName : '';
        return ( f + ' ' + l ).trim();
    }

    /**
     * Returns a initials of the user which is of two characters.
     */
    public get initials(): string {
        const f: string = this.firstName ? this.firstName.substr( 0, 2 ).toUpperCase() : '';
        const l: string = this.lastName ? this.lastName.substr( 0, 2 ).toUpperCase() : '';
        const e: string = this.email ? this.email.substr( 0, 2 ).toUpperCase() : '';

        if ( this.isRegistered ===  false || !f && !l ) {
            return e;
        } else if ( f === '' ) {
            return l;
        } else if ( l === '' ) {
            return f;
        } else {
            return f.substr( 0, 1 ) + l.substr( 0, 1 );
        }
    }

    /**
     * Returns the first intial of the user's initials.
     */
    public get initial(): string {
        return this.initials.charAt( 0 );
    }

    /**
     * Indicates if the user has a profile picture saved.
     */
    public get hasImage(): boolean {
        if ( this.imageUrl === '1' ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns whether the user is demo or unregistered
     */
    public get isGuestUser(): boolean {
        return this.status === UserStatus.DEMO || this.status === UserStatus.UNREGISTERED;
    }

    /**
     * Gets a color which can be used to represent this user.
     */
    public getColor(): string {
        let n = 1;
        for ( let i = 0; i < this.id.length; ++i ) {
            n += this.id.charCodeAt( i );
        }
        n = n % AVAILABLE_COLORS.length;
        return AVAILABLE_COLORS[n];
    }
}

Object.defineProperty( UserInfoModel, 'name', {
  writable: true,
  value: 'UserInfoModel',
});
