import { Extension } from '@tiptap/core';

// tslint:disable-next-line:variable-name
export const FontSize = Extension.create({
    name: 'fontSize',
    addOptions() {
        return {
            types: [ 'textStyle' ],
        };
    },
    addGlobalAttributes( this ) {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize?.replace( /['"]+/g, '' ),
                        renderHTML: attributes => {
                            if ( !attributes.fontSize ) {
                                return {};
                            }
                            return {
                                style: `font-size: ${attributes.fontSize}`,
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            setFontSize: fontSize => ({ chain }) =>
                chain()
                    .setMark( 'textStyle', { fontSize })
                    .updateAttributes( 'bulletList', { fontSize })
                    .updateAttributes( 'orderedList', { fontSize })
                    .run(),
            unsetFontSize: () => ({ chain }) =>
                chain()
                    .setMark( 'textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .updateAttributes( 'bulletList', { fontSize: null })
                    .updateAttributes( 'orderedList', { fontSize: null })
                    .run(),

        } as any;
    },
});

// tslint:disable-next-line:variable-name
export const FontWeight = Extension.create({
    name: 'fontWeight',
    addOptions() {
        return {
            types: [ 'textStyle' ],
        };
    },
    addGlobalAttributes( this ) {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontWeight: {
                        default: null,
                        parseHTML: element => element.style.fontWeight?.replace( /['"]+/g, '' ),
                        renderHTML: attributes => {
                            if ( !attributes.fontWeight ) {
                                return {};
                            }
                            return {
                                style: `font-weight: ${attributes.fontWeight}`,
                            };
                        },
                    },
                },
            },
        ];
    },
    addKeyboardShortcuts  ( this ) {
        return {
            'Mod-b': () => {
                if ( this.editor.isActive( 'fontWeight' )) {
                    const attr = this.editor.getAttributes( 'fontWeight' );
                    if ( attr ) {
                        return this.editor.commands.setFontWeight( 'normal' );
                    }
                }
                return this.editor.commands.setFontWeight( 'bold' );
            },
        };
    },
    addCommands() {
        return {
            setFontWeight: fontWeight => ({ chain }) =>
                chain()
                    .setMark( 'textStyle', { fontWeight: fontWeight || 'bold' })
                    .run(),
            unsetFontWeight: () => ({ chain }) =>
                chain()
                    .setMark( 'textStyle', { fontWeight: null })
                    .removeEmptyTextStyle()
                    .run(),
        } as any;
    },
});

// tslint:disable-next-line:variable-name
export const FontFamily = Extension.create({
    name: 'fontFamily',

    addOptions() {
      return {
        types: [ 'textStyle' ],
      };
    },

    addGlobalAttributes( this ) {
      return [
        {
          types: this.options.types,
          attributes: {
            fontFamily: {
              default: null,
              parseHTML: element => element.style.fontFamily?.replace( /['"]+/g, '' ),
              renderHTML: attributes => {
                if ( !attributes.fontFamily ) {
                  return {};
                }

                return {
                  style: `font-family: ${attributes.fontFamily}`,
                };
              },
            },
          },
        },
      ];
    },

    addCommands() {
      return {
        setFontFamily: fontFamily => ({ chain }) =>
          chain()
            .setMark( 'textStyle', { fontFamily })
            .updateAttributes( 'bulletList', { fontFamily })
            .updateAttributes( 'orderedList', { fontFamily })
            .run(),
        unsetFontFamily: () => ({ chain }) =>
          chain()
            .setMark( 'textStyle', { fontFamily: null })
            .updateAttributes( 'bulletList', { fontFamily: null })
            .updateAttributes( 'orderedList', { fontFamily: null })
            .removeEmptyTextStyle()
            .run(),
      };
    },
});

// tslint:disable-next-line:variable-name
export const Color = Extension.create({
    name: 'color',

    addOptions() {
      return {
        types: [ 'textStyle' ],
      };
    },

    addGlobalAttributes( this ) {
      return [
        {
          types: this.options.types,
          attributes: {
            color: {
              default: null,
              parseHTML: element => element.style.color?.replace( /['"]+/g, '' ),
              renderHTML: attributes => {
                if ( !attributes.color ) {
                  return {};
                }

                return {
                  style: `color: ${attributes.color}`,
                };
              },
            },
          },
        },
      ];
    },

    addCommands() {
      return {
        setColor: color => ({ chain }) =>
          chain()
            .setMark( 'textStyle', { color })
            .updateAttributes( 'bulletList', { color })
            .updateAttributes( 'orderedList', { color })
            .run(),
        unsetColor: () => ({ chain }) =>
          chain()
            .setMark( 'textStyle', { color: null })
            .updateAttributes( 'bulletList', { color: null })
            .updateAttributes( 'orderedList', { color: null })
            .removeEmptyTextStyle()
            .run(),
      };
    },
});


declare module '@tiptap/core' {
    // tslint:disable-next-line:interface-name
    interface Commands<ReturnType> {
        fontSize: {
            setFontSize: ( size ) => ReturnType,
            unsetFontSize: () => ReturnType,
        };
        fontWeight: {
            setFontWeight: ( fontWeight ) => ReturnType,
            unsetFontWeight: () => ReturnType,
        };
        fontFamily: {
            setFontFamily: ( fontFamily: string ) => ReturnType,
            unsetFontFamily: () => ReturnType,
        };
        color: {
            setColor: ( color: string ) => ReturnType,
            unsetColor: () => ReturnType,
        };
    }
  }
