import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the NUMBER data type. Data items in shape models with the NUMBER
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class NumberDataItem extends AbstractDataItem<DataType.NUMBER> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: any ): IValidationError {
        if ( !this.validationRules ) {
            return null;
        }
        if ( this.validationRules.required && ( value === undefined || value === null )) {
            return { message: `"${value}" is not allowed, A value is required` };
        }
        if ( isNaN( value )) {
            return { message: `"${value}" is not a valid number` };
        }
        const float = Number.parseFloat( value );
        if ( !this.validationRules.decimal && !Number.isInteger( float )) {
            return { message: `${value} is a decimal` };
        }
        if ( this.validationRules.max !== undefined && float > this.validationRules.max ) {
            return { message : `${value} is greater than ${this.validationRules.max}` };
        }
        if ( this.validationRules.min !== undefined && float < this.validationRules.min ) {
            return { message: `${value} is less than ${this.validationRules.min}` };
        }
        return null;

    }
}
