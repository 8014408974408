import { ShapeType } from 'flux-definition';

/**
 * The definition of the default connector.
 */
export const DEFAULT_CONNECTOR = {
    defId: 'creately.connector.default',
    type: ShapeType.Connector,
    version: 1,
    name: 'Just a Line',
    entryClass: 'connectors.bundle.js#ConnectorSmoothAngled',
    drawAroundShapes: false,
    ends: {
        to: 'connectors.bundle.js#PointerFilled',
    },
    isHighlighted: true,
};


/**
 * The EData reference connector. Eligible when one or more shapes are
 * edata connected
 */
export const EDATAREF_CONNECTOR = {
    defId: 'creately.edata.reference',
    type: ShapeType.Connector,
    version: 1,
    name: 'Reference Link',
    entryClass: 'connectors.bundle.js#ConnectorStraight',
    drawAroundShapes: false,
    ends: {},
    handshake: [ 'edata-reference' ],
    style: {
        lineColor: '#7C7B7A',
        lineThickness: 1,
        lineStyle: [
            3,
            3,
        ],
    },
};


/**
 * The Lookup connector. Represent a lookup selection
 */
export const LOOKUP_CONNECTOR = {
    defId: 'creately.connector.lookup',
    type: ShapeType.Connector,
    version: 1,
    name: 'Lookup Reference',
    entryClass: 'connectors.bundle.js#ConnectorStraight',
    drawAroundShapes: false,
    ends: {},
    handshake: [ 'lookup' ],
};
