import { IDataItem } from 'flux-definition';
import { DataType } from 'flux-definition';
import { BinaryDataItem } from './data-item-binary';
import { NumberDataItem } from './data-item-number';
import { NumberSliderDataItem } from './data-item-number-slider';
import { NumberCounterDataItem } from './data-item-number-counter';
import { StringDataItem } from './data-item-string';
import { StringLongDataItem } from './data-item-string-long';
import { StringHtmlDataItem } from './data-item-string-html';
import { ColorDataItem } from './data-item-color';
import { ImageDataItem } from './data-item-image';
import { OptionDataItem } from './data-item-option';
import { OptionChoiceDataItem } from './data-item-option-choice';
import { OptionListDataItem } from './data-item-option-list';
import { OptionImagesDataItem } from './data-item-option-images';
import { OptionListComboDataItem } from './data-item-option-list-combo';
import { CustomShapeStyleDataItem } from './data-item-custom-shape-style';
import { CustomStylePicker } from './data-item-custom-styles';
import { TextPositionDataItem } from './data-item-text-position';
import { ChildShapeDataItem } from './data-item-child-shape';
import { NumberOptionsDataItem } from './data-item-number-options';
import { NestedDataItemList } from './data-item-nested-list';
import { NestedDataItemObject } from './data-item-nested-object';
import { DateDataItem } from './data-item-date';
import { TagsDataItem } from './data-item-tags';
import { ViewDataItem } from './data-item-view';
import { PeopleDataItem } from './data-item-people';
import { UsersDataItem } from './data-item-users';
import { IdentifierDataItem } from './data-item-identifier';
import { LookupDataItem } from './data-item-lookup';
import { FormulaDataItem } from './data-item-formula';

/**
 * Data item classes should have a a static create method.
 * This method is used instead of a constructor so that the
 * structure of the data passed into the class can be modified.
 */
export interface IFactory<T extends DataType> {
    create: ( data: any ) => IDataItem<T>;
}

export const DATA_DEF_MAP = {
    number : {
        type: DataType.NUMBER,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    string : {
        type: DataType.STRING,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    stringShort : {
        type: DataType.STRING,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    stringOptionalLabelEditable : {
        type: DataType.STRING,
        visibility: [
            { type: 'editor' },
        ],
        labelEditable: true,
        optional: true,
        validationRules: {},
    },
    descriptionRichtext : {
        type: DataType.STRING_HTML,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    userSelector : {
        type: DataType.OPTION_LIST_COMBO,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    singleSelectCombo : {
        type: DataType.OPTION_LIST,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
    checkbox : {
        type: DataType.BINARY,
        visibility: [
            { type: 'editor' },
        ],
        validationRules: {},
    },
};


/**
 * DataItemFactory
 * DataItemFactory creates instances of data items using their data.
 */
export class DataItemFactory {
    public static get instance(): DataItemFactory {
        if ( !DataItemFactory._instance ) {
            DataItemFactory._instance = new DataItemFactory();
        }
        return DataItemFactory._instance;
    }
    /**
     * Holds the singleton instance of DataItemFactory, enabling it to be used as a utility
     * class as well as a service. Should not be used until the app is initialized completely.
     */
    private static _instance = null;

    /**
     * A map of datatypes => constructors which creates data items for that datatype.
     */
    private static factories: { [ T in DataType ]?: IFactory<T> } = {
        [DataType.BINARY]: BinaryDataItem,
        [DataType.NUMBER]: NumberDataItem,
        [DataType.NUMBER_SLIDER]: NumberSliderDataItem,
        [DataType.NUMBER_COUNTER]: NumberCounterDataItem,
        [DataType.NUMBER_OPTIONS]: NumberOptionsDataItem,
        [DataType.STRING]: StringDataItem,
        [DataType.STRING_LONG]: StringLongDataItem,
        [DataType.STRING_HTML]: StringHtmlDataItem,
        [DataType.COLOR]: ColorDataItem,
        [DataType.IMAGE]: ImageDataItem,
        [DataType.OPTION]: OptionDataItem,
        [DataType.OPTION_CHOICE]: OptionChoiceDataItem,
        [DataType.OPTION_LIST]: OptionListDataItem,
        [DataType.OPTION_IMAGES]: OptionImagesDataItem,
        [DataType.GRID_IMAGES]: OptionImagesDataItem,
        [DataType.OPTION_LIST_COMBO]: OptionListComboDataItem,
        [DataType.CUSTOM_SHAPE_STYLE]: CustomShapeStyleDataItem,
        [DataType.CUSTOM_STYLE_PALETTES]: CustomStylePicker,
        [DataType.TEXT_POSITION]: TextPositionDataItem,
        [DataType.CHILD_SHAPE]: ChildShapeDataItem,
        [DataType.NESTED_DATAITEM_LIST]: NestedDataItemList,
        [DataType.NESTED_DATAITEM_OBJECT]: NestedDataItemObject,
        [DataType.DATE]: DateDataItem,
        [DataType.TAGS]: TagsDataItem,
        [DataType.VIEW]: ViewDataItem,
        [DataType.PEOPLE]: PeopleDataItem,
        [DataType.USERS]: UsersDataItem,
        [DataType.IDENTIFIER]: IdentifierDataItem,
        [DataType.LOOKUP]: LookupDataItem,
        [DataType.FORMULA]: FormulaDataItem,
    };

    /**
     * Creates a new data item instance for given data type and data.
     */
    public create<T extends DataType>( data: { type: T, [ key: string ]: any }): IDataItem<T> {
        if (  !data.type && data.def ) {
            Object.assign( data, DATA_DEF_MAP[ data.def ] || DATA_DEF_MAP.stringShort );
        } else if ( !data.type ) {
            return data as any;
        }
        return DataItemFactory.factories[data.type].create( data ) as IDataItem<T>;
    }


}
