import { AbstractConnector } from './connector-abstract';

/**
 * This class contains the capability to draw a straight
 * connector using the coordinate data stored into the model,
 * using the provided graphics object.
 *
 * To draw a straight connector, the model should have
 * a path with each point the connector should connect to.
 *
 * @author  Ramishka
 * @since   2017-10-07
 */
export class ConnectorStraight extends AbstractConnector {

    /**
     * Draws the straight connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        this.moveTo( points[0]);
        // connect all other points
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            this.lineTo( point, point.bumps && point.bumps[0]);
        }
    }
}

Object.defineProperty( ConnectorStraight, 'name', {
    value: 'ConnectorStraight',
});
