import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Command, CommandInterfaces } from 'flux-core';
import { AbstractDiagramExportCommand } from './abstract-diagram-export-command.cmd';

/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsSvg extends AbstractDiagramExportCommand {
    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    /**
     * Prepare the svg image data to be exported.
     */
    public prepareData() {
        return this.prepareSvgData().pipe(
            tap(( data: any ) =>
                this.data = { content: data.svg, name: data.name + '.svg', type: 'image/svg+xml', format: 'file' }),
        );
    }

}

Object.defineProperty( ExportAsSvg, 'name', {
    value: 'ExportAsSvg',
});
