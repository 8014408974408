import { Injectable } from '@angular/core';
import { AbstractCommand } from '../../../../../../libs/flux-core/src';
import { ObjectConvertor } from '../../../framework/edata/object-convertor.svc';

/**
 * This command converts an existing diagram shape to e database object.
 */
@Injectable()
export class ConvertShapesToObjects extends AbstractCommand {
    constructor( private objectConvertor: ObjectConvertor ) {
        super() /* istanbul ignore next */;
    }

    public execute(): boolean {
        const { shapeDefId, eDataId, entityDefId } = this.data;
        this.objectConvertor.convertShapesToObjects( shapeDefId, eDataId, entityDefId ).subscribe();
        return true;
    }
}

Object.defineProperty( ConvertShapesToObjects, 'name', {
    value: 'ConvertShapesToObjects',
});
