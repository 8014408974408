import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DataType, IDataItem } from 'flux-definition/src';
import { Observable } from 'rxjs';
import { EDataModel } from '../model/edata.mdl';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';


@Injectable()
@Command()
export class CreateMirrorLookupField extends AbstractEDataChangeCommand {
    public data: {
        entityDefId: string;
        lookupField: IDataItem<DataType.LOOKUP>;
        lookupOptions: {
            eDataModel: EDataModel;
            eDefId: string;
        };
    };

    prepareData(): void | Observable<any> {
        if ( !this.changeModel.customEntityDefs ) {
            this.changeModel.customEntityDefs = {};
        }
        if ( !this.changeModel.customEntityDefs[this.data.entityDefId]) {
            this.changeModel.customEntityDefs[this.data.entityDefId] = {
                id: this.data.entityDefId,
                dataItems: {},
            } as any;
        }
        const entityDef = this.changeModel.customEntityDefs[this.data.entityDefId];
        const lookupField = this.data.lookupField;
        const { eDataModel, eDefId } = this.data.lookupOptions;
        if ( entityDef.dataItems[lookupField.id]) {
            return;
        }
        const dataItem = {
            id: lookupField.id,
            type: DataType.LOOKUP,
            layout: 'block',
            value: [],
            default: [],
            options: {
                eDataId: eDataModel.id,
                eDefId,
                allowMultiple: true,
                isMirrorField: true,
            },
            label: `_${lookupField.label}`,
            labelEditable: false,
            optional: true,
            visibility: [
                { type: 'editor' },
            ],
            validationRules: {},
            isTypeBound: true,
            removeable: false,
        };
        entityDef.dataItems = { ...entityDef.dataItems, [dataItem.id]: dataItem as any };
        if ( Array.isArray( lookupField.value ) && lookupField.value.length > 0 ) { // has connections
            const entity = Object.values( eDataModel.entities )
                .find( e => e.eDefId === eDefId && e.data[lookupField.id] && e.data[lookupField.id].length > 0 );
            const value = entity ? entity.data[lookupField.id] : [];
            const shouldBeTruthy = lookupField.value.every(( val, index ) => value[index] === val );
            if ( shouldBeTruthy ) {
                lookupField.value.forEach( entId => {
                    this.changeModel.entities[ entId ].data[lookupField.id] = [ entity.id ];
                });
            }
        }
    }
}

Object.defineProperty( CreateMirrorLookupField, 'name', {
    value: 'CreateMirrorLookupField',
});
