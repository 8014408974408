import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MapOf } from '../data/data-types';

/**
 * ngAttr
 * This directive is used to set attributes for a given element
 */

@Directive({
    selector: '[ngAttr]',
})
export class NgAttr implements OnInit {

    @Input()
    ngAttr: MapOf<string>;

    constructor( private elementRef: ElementRef, private renderer: Renderer2 ) {
    }

    ngOnInit(): void {
        if ( this.ngAttr && Object.keys( this.ngAttr ).length > 0 ) {
            for ( const attr in this.ngAttr ) {
                this.renderer.setAttribute( this.elementRef.nativeElement, attr, this.ngAttr[attr]);
            }
        }
    }
}
