import { createFuture, Future } from '@creately/future';
import { modify } from '@creately/mungo';
import { Proxied, Sakota } from '@creately/sakota';
import { ClassUtils, EventIdentifier, Flags, IModifier, Logger } from 'flux-core';
import { ShapeType } from 'flux-definition';
import { EventCollector } from 'flux-core';
import { cloneDeep, size, isEqual, set, isNil } from 'lodash';
import { BehaviorSubject, concat, from, merge, Observable, Observer, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { EDATAREF_CONNECTOR } from '../definition/default-connector.def';
import { DiagramDataModel } from '../model/diagram-data.mdl';
import { IDiagramChange, IEmittedDataDefChange, IEmittedDiagramChange,
        IEmittedEDataShapeChange, IEmittedLinkChange, IEmittedRuleChange, IEmittedShapeChange } from './diagram-change.i';
import { IDiagramFactory } from './diagram-factory.i';
import { IDiagramLocator } from './diagram-locator.i';
import { ISplitModifier, splitModifier } from './split-modifier';

/**
 * IStoredDiagramLocatorOptions
 * Contains input data and services needed by the stored diagram locator.
 */
export interface IStoredDiagramLocatorOptions<
    DT extends DiagramDataModel,
    ST extends AbstractShapeModel,
> {
    changes: Observable<IDiagramChange>;
    factory: IDiagramFactory<DT, ST>;
}

/**
 * StoredDiagramLocator
 * This diagram locator can be used to get commonly used information about the diagram
 * and shapes. This only includes changes which are already stored in the database.
 *
 * The diagram data is split into 3 levels:
 *  - diagram0: plain javascript objects
 *  - diagram1: empty models with correct types
 *  - diagram2: models with deserialized data (proxies diagram1)
 *
 * This is done so that parts of diagram2 can be rebuilt without rebuilding the model.
 * The primary objective here is to maintain good performance and it is done in implementation
 * level as well as in design level.
 *
 */
export class StoredDiagramLocator<
    DT extends DiagramDataModel,
    ST extends AbstractShapeModel
> implements IDiagramLocator<DT, ST> {
    /**
     * Used to create or apply changes to shape or diagram models.
     */
    private factory: IDiagramFactory<DT, ST>;

    /**
     * Indicates whether the diagram locator is active.
     */
    private started = new BehaviorSubject<boolean>( false );

    /**
     * The subscription for the main process of the diagram locator.
     */
    private process: Subscription;

    /**
     * Indicates that the diagram data is being updated at the moment.
     */
    private working?: Future<unknown>;

    /**
     * Counter used to generate unique ids for subscription observers.
     */
    private counter = 0;

    /**
     * A future which is used to identify when the diagram model is ready.
     */
    private oninit = createFuture();

    /**
     * Hold observers to emit new changes to them.
     */
    private observers: {
        diagram: { [id: number]: Observer<IEmittedDiagramChange<DT, ST>> },
        inserts: { [id: number]: Observer<ST[]> },
        removals: { [id: number]: Observer<ST[]> },
        shapes: { [shapeId: string]: { [id: number]: Observer<IEmittedShapeChange<ST>> }},
        eData: { [id: number]: Observer<IEmittedEDataShapeChange<DT, ST>> },
        links: { [id: number]: Observer<IEmittedLinkChange<ST>> },
        rules: { [id: number]: Observer<IEmittedRuleChange<ST>> },
        dataDefs: { [id: number]: Observer<IEmittedDataDefChange<ST>> },
    } = { diagram: {}, inserts: {}, removals: {}, shapes: {}, eData: {}, links: {}, rules: {}, dataDefs: {}};

    /**
     * The diagram data is stored in multiple levels.
     *      Level 0: Plain Data
     *      Level 1: Model Only
     *      Level 2: Model and Data
     */
    private diagram0: any = {};
    private diagram1: DT = null;
    private diagram2: Proxied<DT> = null;

    // Public Methods
    // --------------

    /**
     * Starts listening to changes and starts emitting changes when shapes change.
     * Returned observable will emit once and complete when the diagram model is ready.
     */
    public start( options: IStoredDiagramLocatorOptions<DT, ST> ): Observable<unknown> {
        ClassUtils.disableMethod( this, 'start', 'The start method is called multiple time on StoredDiagramLocator.' );
        this.factory = options.factory;
        this.started.next( true );
        this.process = options.changes.pipe(
            concatMap( change => this.processChanges( change )),
        ).subscribe();
        return from( this.oninit );
    }

    /**
     * Stops all locator activities.
     */
    public stop(): void {
        [
            'start',
            'stop',
            'getDiagramOnce',
            'getDiagramModel',
            'getDiagramName',
            'getDiagramChanges',
            'getDiagramLinkChanges',
            'getDiagramEDataChanges',
            'getShapeOnce',
            'getShapeModel',
            'getAddedShapes',
            'getRemovedShapes',
            'getCurrentShapes',
            'getShapeChanges',
            'executeWhenIdle',
        ].forEach( name => {
            ClassUtils.disableMethod( this, name, `StoredDiagramLocator.${name}() is called after stopping.` );
        });
        if ( this.started.value === true ) {
            this.process.unsubscribe();
            this.started.next( false );
            this.closeSubscriptions();
        }
    }

    /**
     * Emits the diagram once and completes the subscription immediately.
     */
    public getDiagramOnce(): Observable<DT> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getDiagramOnce()),
            );
        }
        return Observable.create(( o: Observer<DT> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.diagram2 );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the diagram immediately and whenever it changes (without modifiers).
     */
    public getDiagramModel(): Observable<DT> {
        return merge(
            this.getDiagramOnce(),
            this.getDiagramChanges().pipe(
                map( value => value.model ),
            ),
        );
    }

    /**
     * Emits the diagram name immediately and whenever it changes (without modifiers).
     */
    public getDiagramName(): Observable<string> {
        return this.getDiagramModel().pipe(
            map( diagram => diagram.name ),
            distinctUntilChanged(),
        );
    }

    /**
     * Emits the edata when it changes
     */
    public getDiagramEData(): Observable<string[]> {
        return this.getDiagramModel().pipe(
            map( diagram => diagram.eData ),
            distinctUntilChanged( isEqual ),
        );
    }

    /**
     * Emits only when there are new changes to the diagram (after subscribing).
     */
    public getDiagramChanges(): Observable<IEmittedDiagramChange<DT, ST>> {
        return Observable.create(( o: Observer<IEmittedDiagramChange<DT, ST>> ) => {
            const id = this.createUniqueId();
            this.observers.diagram[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: Diagram Changes:', size( this.observers.diagram ));
            }
            return () => {
                delete this.observers.diagram[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: Diagram Changes:', size( this.observers.diagram ));
                }
            };
        });
    }

    /**
     * Emits only when there are new changes to shapes which
     * - are eData connected
     * - have dataItem changes
     * after subscribing
     */
    public getDiagramEDataChanges(): Observable<IEmittedEDataShapeChange<DT, ST>> {
        return Observable.create(( o: Observer<IEmittedEDataShapeChange<DT, ST>> ) => {
            const id = this.createUniqueId();
            this.observers.eData[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: eData Changes:', size( this.observers.eData ));
            }
            return () => {
                delete this.observers.eData[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: eData Changes:', size( this.observers.eData ));
                }
            };
        });
    }

    /**
     * Emits only when there are new changes to connectors where
     * - a connector is detached which were between 2 eData shapes
     * - a connector is reattached which from 1 entity to another
     * - a connector is removed which were  between 2 eData shapes
     * - a connector is added which are between 2 eData shapes
     * after subscribing
     */
    public getDiagramLinkChanges(): Observable<IEmittedLinkChange<ST>> {
        return Observable.create(( o: Observer<IEmittedLinkChange<ST>> ) => {
            const id = this.createUniqueId();
            this.observers.links[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: Link Changes:', size( this.observers.links ));
            }
            return () => {
                delete this.observers.links[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: Link Changes:', size( this.observers.links ));
                }
            };
        });
    }

    /**
     * Emits only when there are new changes to a rule
     * after subscribing
     */
     public getDiagramRuleChanges(): Observable<IEmittedRuleChange<ST>> {
        return Observable.create(( o: Observer<IEmittedRuleChange<ST>> ) => {
            const id = this.createUniqueId();
            this.observers.rules[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: rule Changes:', size( this.observers.rules ));
            }
            return () => {
                delete this.observers.rules[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: rule Changes:', size( this.observers.rules ));
                }
            };
        });
    }

    /**
     * Emits only when there are new changes to a dataDef
     * after subscribing
     */
     public getDiagramDataDefChanges(): Observable<IEmittedDataDefChange<ST>> {
        return Observable.create(( o: Observer<IEmittedDataDefChange<ST>> ) => {
            const id = this.createUniqueId();
            this.observers.dataDefs[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: eData Changes:', size( this.observers.dataDefs ));
            }
            return () => {
                delete this.observers.dataDefs[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: eData Changes:', size( this.observers.dataDefs ));
                }
            };
        });
    }

    /**
     * Emits the shape once and completes the subscription immediately.
     */
    public getShapeOnce( shapeId: string ): Observable<ST> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getShapeOnce( shapeId )),
            );
        }
        return Observable.create(( o: Observer<ST> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.diagram2 && this.diagram2.shapes[shapeId] as any || null );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the shape when it's available and whenever it changes (without modifiers)
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        At the moment, it emits twice when created, and then continue normal.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getShapeModel( shapeId: string ): Observable<ST> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getShapeModel( shapeId )),
            );
        }
        const initial = this.getDiagramModel().pipe(
            map( diagram => diagram.shapes[shapeId] as ST ),
            filter( shape => Boolean( shape )),
            take( 1 ),
        );
        const changes = this.getShapeChanges( shapeId ).pipe(
            map( value => value.model ),
        );
        return concat( initial, changes );
    }

    /**
     * Emits only when a new shape is added to the diagram model.
     */
    public getAddedShapes(): Observable<ST[]> {
        return Observable.create(( o: Observer<ST[]> ) => {
            const id = this.createUniqueId();
            this.observers.inserts[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: Added Shapes:', size( this.observers.inserts ));
            }
            return () => {
                delete this.observers.inserts[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: Added Shapes:', size( this.observers.inserts ));
                }
            };
        });
    }

    /**
     * Emits only when a new shape is added to the diagram model.
     * Excluding the shapes already exists when the diagram is being loaded
     */
     public getNewlyAddedShapes(): Observable<ST[]> {
        return this.getDiagramChanges().pipe(
            take( 1 ),
            switchMap(() => this.getAddedShapes()),
        );
    }

    /**
     * Emits only when shapes are removed from the canvas
     */
     public getRemovedShapes(): Observable<ST[]> {
        return Observable.create(( o: Observer<ST[]> ) => {
            const id = this.createUniqueId();
            this.observers.removals[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: Removed Shapes:', size( this.observers.removals ));
            }
            return () => {
                delete this.observers.removals[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'StoredDiagramLocator: Removed Shapes:', size( this.observers.removals ));
                }
            };
        });
    }


    /**
     * Emits all shapes once and completes the subscription immediately.
     */
    public getCurrentShapes(): Observable<ST[]> {
        return this.getDiagramOnce().pipe(
            map( diagram => {
                const shapes: ST[] = [];
                for ( const id in diagram.shapes ) {
                    // TODO: check why we get a type error?
                    shapes.push( diagram.shapes[id] as ST );
                }
                return shapes;
            }),
        );
    }

    /**
     * Emits only when there are new changes to the diagram (after subscribing).
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getShapeChanges( shapeId: string ): Observable<IEmittedShapeChange<ST>> {
        return Observable.create(( o: Observer<IEmittedShapeChange<ST>> ) => {
            const id = this.createUniqueId();
            if ( !this.observers.shapes[ shapeId ]) {
                this.observers.shapes[ shapeId ] = { [id]: o };
            } else {
                this.observers.shapes[ shapeId ][id] = o;
            }
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'StoredDiagramLocator: Shape Changes:', shapeId, size( this.observers.shapes[shapeId]));
            }
            return () => {
                delete this.observers.shapes[ shapeId ][id];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    // tslint:disable-next-line: max-line-length
                    Logger.debug( 'StoredDiagramLocator: Shape Changes:', shapeId, size( this.observers.shapes[shapeId]));
                }
                if ( Object.keys( this.observers.shapes[ shapeId ]).length === 0 ) {
                    delete this.observers.shapes[ shapeId ];
                }
            };
        });
    }

    /**
     * Execute given function when the locator is idle
     * NOTE: This is exposed for other locators, do not use this function.
     */
    public executeWhenIdle( fn: () => void ): void {
        if ( this.working ) {
            this.working.then( fn );
        } else {
            fn();
        }
    }

    // Special Methods
    // ---------------

    /**
     * Emits the diagram raw data once and completes the subscription immediately.
     */
    public getRawDiagramDataOnce(): Observable<object> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getRawDiagramDataOnce()),
            );
        }
        return Observable.create(( o: Observer<DT> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( Sakota.create( this.diagram0 ));
                    o.complete();
                }
            });
            return () => {};
        });
    }

    // Internal Processes
    // ------------------

    /**
     * Processes the diagram change.
     */
    private processChanges( _change: IDiagramChange ): Promise<unknown> {
        /* istanbul ignore if */
        if ( !_change.modifier ) {
            // FIXME this is added as it was triggering an empty change when the first new edataitem
            // is added and the edata model is created
            // need to invesigtae where it is coming form CJ - 7/2/2021
            return Promise.resolve();
        }
        this.working = createFuture();
        const change: IDiagramChange = cloneDeep( _change );
        const split = splitModifier( change.modifier );
        const added = this.addedShapes( split, this.diagram0 );
        const deleted = this.removedShapes( split, this.diagram2 );
        const detached = this.detachedEDataShapes( split, this.diagram2 );
        this.sanitizeChange( change, split, added );
        return this.applyChanges( change, split )
            .then(() => {
                this.working.resolve( null );
                this.working = null;
                if ( this.oninit ) {
                    this.oninit.resolve( null );
                    this.oninit = null;
                }
                EventCollector.log({
                    message: EventIdentifier.DIAGRAM_STORED_LOCATOR_CHANGE_PROCESSED,
                    change, added, deleted, detached,
                });
                this.emitChanges( change, split, added, deleted, detached );
            });
    }

    /**
     * Removes invalid data from given change
     */
    private sanitizeChange( change: IDiagramChange, split: ISplitModifier, added: string[]): void {
        for ( let i = added.length - 1; i >= 0; --i ) {
            const shapeId = added[i];
            const shapeMod = split.shapes[shapeId];

            if ( !this.isValidShapeChange( shapeMod )) {
                const id = this.diagram0?.id || split.diagram?.$set?.id;
                Logger.error( `Found broken shape data: ignoring shape ${shapeId} in diagram ${id}`,
                    JSON.stringify( shapeMod ));
                added.splice( i, 1 );
                delete split.shapes[shapeId];
                for ( const op of Object.keys( change.modifier )) {
                    const opmod = change.modifier[op];
                    for ( const key of Object.keys( opmod )) {
                        if ( key === 'shapes' ) {
                            delete opmod[key][shapeId];
                        }
                        if ( key.startsWith( `shapes.${shapeId}` )) {
                            delete opmod[key];
                        }
                    }
                }
            }
        }
        this.validateConnectorReferences( change, split );
    }

    /**
     * This function will check wether the referenced shape in the connector exists
     * in the split or diagram.
     */
    private validateConnectorReferences( change: IDiagramChange, split: ISplitModifier ): void {
        if ( split.shapes ) {
            const keys = Object.keys( split.shapes );
            for ( let i = keys.length - 1; i >= 0; --i ) {
                const shapeId = keys[i];
                const shapeMod = split.shapes[shapeId];
                if ( this.hasPropertiesSet( shapeMod, [ 'path' ])) {
                    const connectorHeadId = shapeMod.$set.path.headId;
                    const connectorTailId = shapeMod.$set.path.tailId;
                    const headId = shapeMod.$set.path[connectorHeadId].shapeId;
                    const tailId = shapeMod.$set.path[connectorTailId].shapeId;
                    if ( headId ) {
                        const splitHasShape = Boolean( split.shapes[headId]);
                        const diag0HasShape = this.diagram0 && this.diagram0.shapes && this.diagram0.shapes[headId];
                        if ( !splitHasShape && !diag0HasShape ) {
                            this.removeShapeFromConnector( shapeId, connectorHeadId, shapeMod, change );
                        }
                    }
                    if ( tailId ) {
                        const splitHasShape = Boolean( split.shapes[tailId]);
                        const diag0HasShape = this.diagram0 && this.diagram0.shapes && this.diagram0.shapes[tailId];
                        if ( !splitHasShape && !diag0HasShape ) {
                            this.removeShapeFromConnector( shapeId, connectorTailId, shapeMod, change );
                        }
                    }
                }
            }
        }
    }

    /**
     * This function remove the shape Id from head or tail from the connector of the split and change modifier.
     */
    private removeShapeFromConnector( shapeId, section: any, shapeMod: any, change: IDiagramChange ) {
        shapeMod.$set.path[section].shapeId = null;
        shapeMod.$set.path[section].gluepointId = null;
        shapeMod.$set.path[section].gluepointLocked = null;

        for ( const op of Object.keys( change.modifier )) {
            const opmod = change.modifier[op];
            for ( const key of Object.keys( opmod )) {
                // Initial diagram load
                if ( key === 'shapes' ) {
                    opmod[key][shapeId].path[section].shapeId = null;
                    opmod[key][shapeId].path[section].gluepointId = null;
                    opmod[key][shapeId].path[section].gluepointLocked = null;
                }
                // When new shape/connector is added
                if ( key === `shapes.${shapeId}` ) {
                    opmod[key].path[section].shapeId = null;
                    opmod[key].path[section].gluepointId = null;
                    opmod[key].path[section].gluepointLocked = null;
                }
                // When changes happens to already added connector in document
                if ( key === `shapes.${shapeId}.path` ) {
                    opmod[key][section].shapeId = null;
                    opmod[key][section].gluepointId = null;
                    opmod[key][section].gluepointLocked = null;
                }
                // TODO: Handle the granular changes to points.
            }
        }
    }

    /**
     * Checks whether the given shape modifier is valid or not (for added shapes).
     * TODO: Check whether moving this function to the DiagramFactory class makes more sense.
     */
    private isValidShapeChange( modifier: IModifier ): boolean {
        if ( !this.hasPropertiesSet( modifier, [ 'id', 'defId', 'version' ])) {
            return false;
        }
        // NOTE: the type property was optional. If it's not defined, it is a basic shape.
        if ( modifier.$set.type === undefined || modifier.$set.type === ShapeType.Basic ) {
            // NOTE: basic shape specific checks
        }
        if ( modifier.$set.type === ShapeType.Connector ) {
            if ( !this.hasPropertiesSet( modifier, [ 'path' ])) {
                return false;
            }
        }
        if ( modifier.$set.type === ShapeType.Freehand &&
            ( isNil( modifier.$set.scaleX ) || isNil( modifier.$set.scaleY ))) {
                return false;
        }
        return true;
    }

    /**
     * Checks whether the modifier sets all given properties, returns false if any of them are missing.
     */
    private hasPropertiesSet( modifier: IModifier, paths: string[]): boolean {
        if ( !modifier.$set ) {
            return false;
        }
        for ( const path of paths ) {
            // TODO: support nested paths when needed
            if ( modifier.$set[path] === undefined ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Applies changes to the model.
     */
    private applyChanges( change: IDiagramChange, split: ISplitModifier ): Promise<unknown> {
        modify( this.diagram0, change.modifier );
        if ( this.shouldResetDiagram( split.diagram )) {
            // NOTE: diagram2 should be available before creating diagram1 shapes as diagram2
            //       is passed in as a parameter to the createShape method on the factory.
            return this.createDiagram1()
                .then(() => this.createDiagram2())
                .then(() => this.createDiagram1Shapes())
                .then(() => this.applyDiagram2Changes());
        }
        const promises: Promise<any>[] = [];
        if ( split.diagram ) {
            promises.push( this.updateDiagram2( split.diagram ));
        }
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                const shapemod = split.shapes[shapeId];
                if ( this.shouldDeleteShape( shapeId, shapemod )) {
                    if ( !this.diagram1.shapes[shapeId]) {
                        continue;
                    }
                    promises.push(
                        this.removeShape1( shapeId )
                            .then(() => this.removeShape2( shapeId )),
                    );
                } else if ( this.shouldResetShape( shapeId, shapemod )) {
                    promises.push(
                        this.createShape1( shapeId )
                            .then(() => this.createShape2( shapeId, this.getAppliedShapeModifier( shapeId ))),
                    );
                } else {
                    promises.push( this.updateShape2( shapeId, shapemod ));
                }
            }
        }


        // diagram dataDefs changes should reset the shapes which use
        // the changed dataDef
        const data = {};
        if ( split.diagram && change.modifier.$set ) {
            for ( const key in change.modifier.$set ) {
                const regex = /dataDefs\.([^\.]+)\.([^\.]+)\./g; // updated existing
                regex.lastIndex = 0;
                const match = regex.exec( key );
                if ( match && !data[ match[ 1 ] ]) {
                    set( data, `${match[ 1 ]}`, this.diagram2.getShapesByDatasetId( match[ 1 ]));
                }
            }
        }
        Object.keys( data ).forEach( key => data[ key ]
            .forEach( shape => this.updateShape2( shape.id, change.modifier )));
        return Promise.all( promises );
    }


    /**
     * Emits new changes to observers.
     * TODO: check whether it is necessary to include changes caused by def changes.
     */
    private emitChanges( change: IDiagramChange, split: ISplitModifier, inserts: string[], deleted: ST[],
                         detached: {[connId: string]: IEmittedLinkChange<ST>}): void {
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                const shape = this.diagram2.shapes[shapeId] as ( ST );
                const modifier = split.shapes[shapeId];

                this.emitEDataChanges( change, shape, modifier, deleted, detached );

                const shapeObservers = this.observers.shapes[shapeId];
                if ( !shapeObservers ) {
                    continue;
                }
                if ( shape ) {
                    for ( const id in shapeObservers ) {
                        // NOTE: use the shape modifier instead of full modifier
                        const value = { ...change, modifier, model: shape };
                        shapeObservers[id].next( value );
                    }
                } else {
                    for ( const id in shapeObservers ) {
                        shapeObservers[id].complete();
                        delete shapeObservers[id];
                    }
                    /* istanbul ignore if */
                    if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                        Logger.debug( 'StoredDiagramLocator: Shape Deleted:', shapeId );
                    }
                    delete this.observers.shapes[shapeId];
                }
            }
        }
        const insertedShapes = this.getShapeModels( inserts, this.diagram2 );

        // NOTE: diagramObservers should emit only for 'change' and not for diagram init
        if ( change.source !== 'init' ) {
            const diagramObservers = this.observers.diagram;
            for ( const id in diagramObservers ) {
                const value = { ...change, model: this.diagram2, split, added: insertedShapes };
                diagramObservers[id].next( value );
            }
        }
        const insertsObservers = this.observers.inserts;
        if ( insertedShapes.length ) {
            for ( const id in insertsObservers ) {
                insertsObservers[id].next( insertedShapes );
            }
        }
        const removalsObservers = this.observers.removals;
        if ( deleted.length ) {
            for ( const id in removalsObservers ) {
                removalsObservers[id].next( deleted );
            }
        }

        // check if eData connectors are getting deleted
        if ( change.modifier.$unset && deleted && deleted.length > 0 ) {
            deleted.forEach( mdl => {
                if ( mdl && mdl.type === ShapeType.Connector && detached[mdl.id]) {
                    // connector getting deleted
                    const linkObservers = this.observers.links;
                    const valToEmit = { ...change, ...detached[mdl.id],
                                        connector: mdl,
                                    };
                    for ( const id in linkObservers ) {
                        linkObservers[id].next( valToEmit  );
                    }
                }
            });
        }

        this.emitRuleChanges( change, split, deleted );
        this.emitDataDefChanges( change, split, deleted );

    }

    /**
     * Emits new changes to eData observers.
     */
    private emitEDataChanges( change: IDiagramChange, shape: ST, modifier: IModifier, deleted: ST[],
                              detached: {[connId: string]: IEmittedLinkChange<ST> }): void {

        // eData filters
        if ( shape && shape.type === ShapeType.Connector && change.source === 'change' ) {
            // check for connector's thats linking eData
            const tmpConn = shape as any;
            const fromEp = tmpConn.getFromEndpoint ? tmpConn.getFromEndpoint( this.diagram2 ) : null;
            const toEp = tmpConn.getToEndpoint ? tmpConn.getToEndpoint( this.diagram2 ) : null;
            if ( fromEp && toEp
                    && fromEp.shape && toEp.shape // has shapes on both ends
                    // has eData shapes on both ends OR the connectorDef is the ref type
                    &&  ( fromEp.shape.eData || toEp.shape.eData )) {
                const valToEmit: any = { ...change, modifier, model: shape, diagram: this.diagram2 };
                valToEmit.fullModifier = change.modifier;
                const eDataObservers = this.observers.eData;
                for ( const id in eDataObservers ) {
                    eDataObservers[id].next( valToEmit );
                }
            } else if ( detached && detached[shape.id]) { // might be a disconnect
                const linkObservers = this.observers.links;
                const valToEmit = { ...change,
                                    connector: shape,
                                    fromShape: detached[shape.id].fromShape,
                                    toShape: detached[shape.id].toShape };
                for ( const id in linkObservers ) {
                    linkObservers[id].next( valToEmit  );
                }
            }
        } else if ( shape && (( shape.eData &&  this.hasDataChanges( modifier ))
                        || ( shape.eDataRef && this.hasDataChanges( modifier, true )))) {
            const valToEmit: any = { ...change, modifier, model: shape, diagram: this.diagram2 };
            valToEmit.fullModifier = change.modifier;
            const eDataObservers = this.observers.eData;
            for ( const id in eDataObservers ) {
                eDataObservers[id].next( valToEmit );
            }
        } else if ( change.modifier.$unset && deleted && deleted.length > 0 ) {
            deleted.forEach( mdl => {
                if ( mdl && ( mdl.eData || mdl.eDataRef )) {
                    const valToEmit: any = { ...change, modifier, model: mdl, diagram: this.diagram2 };
                    valToEmit.fullModifier = change.modifier;
                    const eDataObservers = this.observers.eData;
                    for ( const id in eDataObservers ) {
                        eDataObservers[id].next( valToEmit );
                    }
                }
            });
        }
    }

    private emitRuleChanges( change: IDiagramChange, split: ISplitModifier, deleted: ST[]): void {
        if ( change.source === 'change' && this.hasTargetChanges( change.modifier, 'rules' )) {
            const ruleObservers = this.observers.rules;
            const valToEmit: any = { ...change, split, diagram: this.diagram2 };
            for ( const id in ruleObservers ) {
                ruleObservers[id].next( valToEmit );
            }
        }
    }

    private emitDataDefChanges( change: IDiagramChange, split: ISplitModifier, deleted: ST[]): void {
        if ( this.hasTargetChanges( change.modifier, 'dataDefs' )) {
            const defObservers = this.observers.dataDefs;
            const valToEmit: any = { ...change, split, diagram: this.diagram2 };
            for ( const id in defObservers ) {
                defObservers[id].next( valToEmit );
            }
        }
    }

    /**
     * Filter out invalid changes for change monitoring
     * @param change
     */
    private hasDataChanges( modifier: IModifier, forShape: boolean = false ): boolean {
        let filterPath = /(data)|(containerId)/;
        if ( forShape ) {
            filterPath = /(data)|(texts)/; // fIXME need regext to goto actual text.
        }
        if ( modifier.$set ) {
            const fNames = Object.keys( modifier.$set ).filter( key => filterPath.test( key ));
            if ( fNames.length > 0 ) { // leave room for unset to be true
                return true;
            }
        }
        if ( modifier.$unset ) {
            const fNames = Object.keys( modifier.$unset ).filter( key => filterPath.test( key ));
            return fNames.length > 0;
        }
        return false;
    }

    /**
     * Does the modifier change anything that we are interested in? like DataDefs, Rules etc.
     * @param modifier
     * @param targetKey
     * @returns
     */
    private hasTargetChanges( modifier: IModifier, targetKey: string ): boolean {

        if ( modifier.$set ) {
            const fNames = Object.keys( modifier.$set ).toString();
            return fNames.indexOf( targetKey ) > -1;
        }

        if ( modifier.$unset ) {
            const fNames = Object.keys( modifier.$unset ).toString();
            return fNames.indexOf( targetKey ) > -1;
        }
        return false;
    }

    // Diagram 0 - diagram data as plain javascript objects and arrays
    // ---------------------------------------------------------------

    // NOTE: add any helper functions related to level 0 diagrams

    // Diagram 1 - diagram structure with correct diagram type and shape types
    // -----------------------------------------------------------------------

    /**
     * Check whether the diagram model has to be created or reset - level 1
     */
    private shouldResetDiagram( modifier: IModifier ): boolean {
        if ( !this.diagram1 ) {
            return true;
        }
        for ( const op in modifier ) {
            if ( modifier[op].type !== undefined ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check whether the shape model has to be created or reset - level 1
     */
    private shouldResetShape( shapeId: string, modifier: IModifier ): boolean {
        if ( !this.diagram1.shapes[shapeId]) {
            return true;
        }
        for ( const op in modifier ) {
            if ( modifier[op].defId !== undefined ||
                modifier[op].entryClass !== undefined ||
                modifier[op].version !== undefined ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check whether the shape model has to be removed - level 1
     */
    private shouldDeleteShape( shapeId: string, modifier: IModifier ): boolean {
        return ( modifier.$unset as any ) === true;
    }

    /**
     * Create the diagram model - level 1
     */
    private createDiagram1(): Promise<unknown> {
        return this.factory.createDiagram( this.diagram0 )
            .then( model => this.diagram1 = model );
    }

    /**
     * Create shape models for the model - level 1
     */
    private createDiagram1Shapes(): Promise<unknown> {
        const promises: Promise<any>[] = [];
        for ( const shapeId in this.diagram0.shapes ) {
            promises.push( this.createShape1( shapeId ));
        }
        return Promise.all( promises );
    }

    /**
     * Create a shape model - level 1
     */
    private createShape1( shapeId: string ): Promise<unknown> {
        return this.factory.createShape( this.diagram0.shapes[shapeId])
            .then( model => this.diagram1.shapes[shapeId] = model );
    }

    /**
     * Remove a shape model - level 1
     */
    private removeShape1( shapeId: string ): Promise<unknown> {
        return this.factory.removeShape( this.diagram1, shapeId );
    }

    // Diagram 2 - diagram model with correct type and data (proxies diagram1)
    // -----------------------------------------------------------------------

    /**
     * Create the diagram model - level 2
     */
    private createDiagram2(): Promise<unknown> {
        this.diagram2 = Sakota.create( this.diagram1 );
        return Promise.resolve( null );
    }

    /**
     * Applies current changes to the diagram model - level 2
     */
    private applyDiagram2Changes(): Promise<unknown> {
        const promises: Promise<any>[] = [];
        const split = splitModifier( this.getAppliedDiagramModifier());
        if ( split.diagram ) {
            promises.push( this.factory.updateDiagram( this.diagram2, split.diagram ));
        }
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                promises.push( this.factory.updateShape(
                    this.diagram2.shapes[shapeId] as any,
                    split.shapes[shapeId],
                    this.diagram2,
                    { $set: this.diagram0.shapes[ shapeId ] },
                ));
            }
        }
        return Promise.all( promises );
    }

    /**
     * Update the diagram model - level 2
     */
    private updateDiagram2( modifier: IModifier ): Promise<unknown> {
        return this.factory.updateDiagram( this.diagram2, modifier );
    }

    /**
     * Create a shape model - level 2
     */
    private createShape2( shapeId: string, modifier: IModifier ): Promise<unknown> {
        ( this.diagram2.shapes as Proxied<object> ).__sakota__.reset( shapeId );
        return this.updateShape2( shapeId, { $set: this.diagram0.shapes[ shapeId ] });
    }

    /**
     * Update a shape model - level 2
     */
    private updateShape2( shapeId: string, modifier: IModifier ): Promise<unknown> {
        const shape = this.diagram2.shapes[shapeId] as Proxied<ST>;
        return this.factory.updateShape(
            shape,
            modifier,
            this.diagram2,
            { $set: this.diagram0.shapes[ shapeId ] },
        );
    }

    /**
     * Remove a shape model - level 2
     */
    private removeShape2( shapeId: string ): Promise<unknown> {
        const shapes = this.diagram2.shapes as Proxied<typeof DiagramDataModel.prototype.shapes>;
        shapes.__sakota__.reset( shapeId );
        return Promise.resolve( null );
    }

    // Other Helpers - helper functions which are not directly related to the logic
    // ----------------------------------------------------------------------------

    /**
     * Returns a unique number each time this method is called. Can be used as unique ids.
     */
    private createUniqueId(): number {
        return ++this.counter;
    }

    /**
     * Returns ids of shapes which are not available on the diagram locator ( new shapes ).
     * NOTE: when shapes are deleted it'll have { $unset: true } as the modifier, ignoring it.
     */
    private addedShapes( split: ISplitModifier, diagram: DT ): string[] {
        const added = [];
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                if (
                    ( !diagram || !diagram.shapes || !diagram.shapes[shapeId]) &&
                    ( !split.shapes[shapeId].$unset || split.shapes[shapeId].$unset !== ( true as any ))
                ) {
                    added.push( shapeId );
                }
            }
        }
        return added;
    }

    /**
     * Returns models of shapes which are being deleted.
     */
    private removedShapes( split: ISplitModifier, diagram: DT ): ST[] {
        const removed = [];
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                if ( split.shapes[shapeId].$unset === ( true as any )
                ) {
                    if ( diagram.shapes[ shapeId]) {
                        removed.push( diagram.shapes[ shapeId]);
                    }
                }
            }
        }
        return removed;
    }

    /**
     * Returns models of shapes which are being disconnected from connectors.
     */
    private detachedEDataShapes( split: ISplitModifier, diagram: DT ):
                                {[connId: string]: IEmittedLinkChange<ST> } {
        const result = {};
        if ( split.shapes && diagram && diagram.shapes ) {
            for ( const shapeId in split.shapes ) {
                const currShape = diagram.shapes[shapeId];
                if ( currShape && currShape.type === ShapeType.Connector ) {
                    const change = split.shapes[shapeId];
                    const conn = currShape as any; // connector
                    // depending on the defined headId/tailId.
                    const currHead: any = conn.path.h || conn.path[ conn.path.headId ];
                    const currTail: any = conn.path.t || conn.path[ conn.path.tailId ];
                    const connDefId = currShape.defId;

                    /* istanbul ignore if */
                    if ( !currHead || !currTail ) {
                        throw Error( 'connector path data invalid' );
                    }

                    // to disconnect, both shuold be currently attached
                    if ( !currHead.shapeId || !currTail.shapeId ) {
                        continue;
                    }

                    const headShape = diagram.shapes[currHead.shapeId];
                    const tailShape = diagram.shapes[currTail.shapeId];

                    if ( change.$set && change.$set.path ) { // connector getting detached or reattached elsehwere
                        const head = change.$set.path[change.$set.path.headId];
                        const tail = change.$set.path[change.$set.path.tailId];

                        /* istanbul ignore if */
                        if ( !tail || !head ) {
                            throw Error( 'connector path data invalid' );
                        }

                        if ( head.shapeId !== headShape.id || tail.shapeId !== tailShape.id ) {
                            // its changing
                            if (( headShape.eData && tailShape.eData )
                                || ( connDefId === EDATAREF_CONNECTOR.defId
                                    && ( headShape.eData || tailShape.eData ))) {
                                // swapping from head/tail to to/from format of connectors
                                result[ shapeId ] = { fromShape: tailShape, toShape: headShape };
                            }
                        }
                    } else if ( change.$unset ) { // the connector is getting deleted
                        if (( headShape.eData && tailShape.eData )
                            || ( connDefId === EDATAREF_CONNECTOR.defId
                            && ( headShape.eData || tailShape.eData ))) {
                            result[ shapeId ] = { fromShape: tailShape, toShape: headShape };
                        }
                    }
                }
            }
        }

        return result;
    }


    /**
     * Returns models of shapes with given ids.
     */
    private getShapeModels( shapeIds: string[], diagram: DT ): ST[] {
        const shapes = [];
        for ( const shapeId of shapeIds ) {
            shapes.push( diagram.shapes[shapeId]);
        }
        return shapes;
    }

    /**
     * Returns all changes applied to the diagram as a modifier.
     * FIXME: granularity is not correct. Unable to fix this without changing how this is stored.
     */
    private getAppliedDiagramModifier(): IModifier {
        return { $set: this.diagram0 };
    }

    /**
     * Returns all changes applied to the shape as a modifier.
     * FIXME: granularity is not correct. Unable to fix this without changing how this is stored.
     */
    private getAppliedShapeModifier( shapeId: string ): IModifier {
        return { $set: this.diagram0.shapes[shapeId] };
    }

    /**
     * Closes all active subscriptions ( calls the complete on observers ).
     */
    private closeSubscriptions(): void {
        for ( const id in this.observers.diagram ) {
            this.observers.diagram[id].complete();
        }
        for ( const id in this.observers.inserts ) {
            this.observers.inserts[id].complete();
        }
        for ( const id in this.observers.removals ) {
            this.observers.removals[id].complete();
        }
        for ( const id in this.observers.eData ) {
            this.observers.eData[id].complete();
        }
        for ( const id in this.observers.links ) {
            this.observers.links[id].complete();
        }
        for ( const shapeId in this.observers.shapes ) {
            for ( const id in this.observers.shapes[shapeId]) {
                this.observers.shapes[shapeId][id].complete();
            }
        }
        for ( const id in this.observers.eData ) {
            this.observers.eData[id].complete();
        }
        for ( const id in this.observers.links ) {
            this.observers.links[id].complete();
        }
        for ( const id in this.observers.rules ) {
            this.observers.rules[id].complete();
        }
        for ( const id in this.observers.dataDefs ) {
            this.observers.dataDefs[id].complete();
        }
    }
// tslint:disable-next-line:max-file-line-count
}
