import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { IStyle, IStyleDefinition } from 'flux-definition';
import { isEmpty } from 'lodash';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { Palette } from '../../feature/style/palette';
import { StyleLoader } from '../../feature/style/style-loader.svc';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { DEFAULT_HIGHTLIGHTED_COLORS } from 'flux-definition';

/**
 * ApplyShapeStyles
 * This will apply style properties which affect lines, fill and text color.
 */
@Injectable()
@Command()
export class ApplyShapeStyles extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        style?: IStyle,
        styleDefinition?: IStyleDefinition,
        format?: any,
        isThemeSwitch?: boolean,
        currPaletteId?: string,
        newPaletteId?: string,
        isLineStyle?: boolean,
        lineStyleId?: string,
        isFillStyle?: boolean,
        fillStyleId?: string,
        isShadow?: boolean,
        processTextStyles?: boolean,
        highlightMode?: boolean,
        isHighlighted?: boolean,
    };


    /**
     * Inject restriction service and the definition locator.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected state: StateService<any, any>,
        protected styleLoader: StyleLoader ) {
        super( ds ) /* istanbul ignore next */;
        this.state = state;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    // tslint:disable-next-line: cyclomatic-complexity
    public prepareData(): void {

        if (
            !this.data ||
            (
                ( !this.data.style || !Object.keys( this.data.style ).length ) &&
                ( !this.data.styleDefinition || !Object.keys( this.data.styleDefinition ).length ) &&
                !this.data.isThemeSwitch && !this.data.isLineStyle && !this.data.isFillStyle &&
                !this.data.highlightMode
            )
        ) {
            // If there are no style data, do not make any changes.
            return;
        }
        let style: any = this.data.style ? this.data.style : {};
        const shapeIds: string[] = this.data.shapeIds === undefined
                    ? this.state.get( 'Selected' ) : this.data.shapeIds;
        const innerShapeSelection = this.state.get( 'InnerShapeSelection' );

        let currPalette: Palette;
        let newPalette: Palette;

        if ( this.data.isThemeSwitch ) {
            currPalette = this.styleLoader.getPalette( this.data.currPaletteId );
            newPalette = this.styleLoader.getPalette( this.data.newPaletteId );
        }

        for ( const shapeId of shapeIds ) {
            const shape = this.changeModel.shapes[shapeId];

            if ( this.data.isThemeSwitch ) {
                if ( shape.themeIndex
                    && currPalette.styles.length >= shape.themeIndex
                    && newPalette.styles.length >= shape.themeIndex ) { // theme index starts with 1
                    // check if there is a diff from the original def.
                    // const currStyle = currPalette.styles [ shape.themeIndex - 1 ];
                    // todo skip items that have been customized.

                    style = newPalette.styles [ shape.themeIndex - 1 ];

                    const currStyle: any = shape.style;
                    if ( currStyle.fillPreset !== style.fillPreset ) {
                        style = this.styleLoader.getFillStylePreset( currStyle.fillPreset as any, style );
                    }

                    if ( style && style.linePreset !== currStyle.linePreset ) {
                        style.linePreset = currStyle.linePreset;
                        style.lineStyle = currStyle.lineStyle;
                        style.lineThickness = currStyle.lineThickness;
                    }

                    // If shape is connector, update text backgrounds
                    if ( shape.isConnector()) {
                        this.editConnectorText(
                            ( shape as ConnectorModel ).isHighlighted,
                            shape,
                            style,
                            false,
                        );
                    }
                } else {
                    continue;
                }
            } else if ( this.data.isFillStyle ) {
                    style = this.styleLoader.getFillStylePreset(
                            this.data.fillStyleId as any, shape.style as any );

                    if (( style as any ).textColor ) {
                        this.data.processTextStyles = true;
                    }
                    // preserve the line style
                    style.linePreset = shape.style.linePreset;
            } else if ( this.data.isLineStyle ) {
                    style = this.styleLoader.getLineStylePreset(
                            this.data.lineStyleId as any, shape.style as any );
                    // preserve the fill preset
                    style.fillPreset = ( shape.style as any ).fillPreset;
            } else if ( this.data.highlightMode ) {
                ( shape as ConnectorModel ).isHighlighted = this.data.isHighlighted;
                this.editConnectorText( this.data.isHighlighted, shape, shape.style, true );
            } else {
                const keys = Object.keys( style );
                if ( keys.length > 2 ) { // this is the style change via the picker
                    const currStyle: any = shape.style;

                    // keep the styles as is
                    if ( style && style.fillPreset !== currStyle.fillPreset ) {
                        style = this.styleLoader.getFillStylePreset( currStyle.fillPreset as any, style );
                    }

                    if ( style && style.linePreset !== currStyle.linePreset ) {
                        style.linePreset = currStyle.linePreset;
                        style.lineStyle = currStyle.lineStyle;
                        style.lineThickness = currStyle.lineThickness;
                    }
                }
                // If shape is connector, update text backgrounds
                if ( shape.isConnector()) {
                    this.editConnectorText(( shape as ConnectorModel ).isHighlighted, shape, this.data.style, false );
                }
            }

            if ( style && ( style as any ).themeIndex ) {
                shape.themeIndex = ( style as any ).themeIndex;
                shape.themeId = ( style as any ).themeId;
            }
            if ( Object.keys( innerShapeSelection ).includes( shapeId ) && innerShapeSelection[ shapeId ].length > 0 ) {
                // Apply styles based on inner selection
                return this.applyStylesForInnerSelection(
                    innerShapeSelection[ shapeId ], shape as ShapeModel, currPalette, newPalette, style,
                );
            }
            const shapeWithDef = shape as ShapeModel;
            if ( this.data.styleDefinition ) {
                // In the event that innerSelection is empty, but the common styles exist
                // apply commonStyles as shape styles as well as replacing the individual styles
                const styleDefIds = Object.keys( this.data.styleDefinition ).filter( id =>
                    id.includes( 'commonStyles' ));
                if ( styleDefIds.length > 0 ) {
                    const styles = styleDefIds.map( id => this.data.styleDefinition[id].style );
                    Object.assign( style, ...styles );
                    Object.keys( shapeWithDef.styleDefinitions ).forEach( key => {
                        Object.assign( shapeWithDef.styleDefinitions[ key ].style, ...styles );
                    });
                } else {
                    Object.assign( shapeWithDef.styleDefinitions, this.data.styleDefinition );
                }
            } else if ( !shape.isConnector() && shapeWithDef.madeOfInnerShapes ) {
                Object.keys( shapeWithDef.styleDefinitions ).forEach( key => {
                    Object.assign( shapeWithDef.styleDefinitions[ key ].style, this.data.style );
                });
            }

            if ( !isEmpty( style )) {
                this.applyStyles( shape as ShapeModel, style, undefined, this.data.isThemeSwitch );
            }

        }
    }

    protected getInnerShapeStyle( currPalette, newPalette, styleDefinition, style ) {
        if ( this.data.isThemeSwitch ) {
            if ( styleDefinition.style.themeIndex
                && currPalette.styles.length >= styleDefinition.style.themeIndex
                && newPalette.styles.length >= styleDefinition.style.themeIndex ) { // theme index starts with 1
                // check if there is a diff from the original def.
                // const currStyle = currPalette.styles [ shape.themeIndex - 1 ];
                // todo skip items that have been customized.

                style = newPalette.styles [ styleDefinition.style.themeIndex - 1 ];
                const currStyle: any = styleDefinition.style;
                if ( currStyle.fillPreset !== style.fillPreset ) {
                    style = this.styleLoader.getFillStylePreset( currStyle.fillPreset as any, style );
                }

                if ( style && style.linePreset !== currStyle.linePreset ) {
                    style.linePreset = currStyle.linePreset;
                    style.lineStyle = currStyle.lineStyle;
                    style.lineThickness = currStyle.lineThickness;
                }

            }
        } else if ( this.data.isFillStyle ) {
                style = this.styleLoader.getFillStylePreset(
                        this.data.fillStyleId as any, styleDefinition.style as any );

                if (( style as any ).textColor ) {
                    this.data.processTextStyles = true;
                }
                // preserve the line style
                style.linePreset = styleDefinition.style.linePreset;
        } else if ( this.data.isLineStyle ) {
                style = this.styleLoader.getLineStylePreset(
                        this.data.lineStyleId as any, styleDefinition.style as any );
                // preserve the fill preset
                style.fillPreset = ( styleDefinition.style as any ).fillPreset;
        } else {
            const keys = Object.keys( style );
            if ( keys.length > 2 ) { // this is the style change via the picker
                const currStyle: any = styleDefinition.style;

                // keep the styles as is
                if ( style && style.fillPreset !== currStyle.fillPreset ) {
                    style = this.styleLoader.getFillStylePreset( currStyle.fillPreset as any, style );
                }

                if ( style && style.linePreset !== currStyle.linePreset ) {
                    style.linePreset = currStyle.linePreset;
                    style.lineStyle = currStyle.lineStyle;
                    style.lineThickness = currStyle.lineThickness;
                }
            }
        }
        return style;
    }

    /**
     * Function to apply styles from styleDefinition based on innerSelection state
     *
     * Styles [ not styleDefinition ] do not exist because a shape with inner selection with
     * custom styles always go through styleDefs from the shape model when constructing
     * the sidebar panel.
     *
     * @param selection innerSelection state; [shapeId]: string[] of innerShape ids
     * @param shape
     */
    protected applyStylesForInnerSelection(
        selection: string[], shape: ShapeModel, currPalette, newPalette, parentStyle = undefined,
    ) {
        selection.forEach( el => {
            if ( !shape.styleDefinitions[ el ]) {
                shape.styleDefinitions[ el ] = {
                    locked: false,
                    priority: 1,
                    style: parentStyle,
                };
            }
            const style = this.getInnerShapeStyle(
                currPalette, newPalette, shape.styleDefinitions[ el ], shape.styleDefinitions[ el ].style,
            );
            const newStyles = {};
            // get styles from style definition
            if ( this.data.styleDefinition ) {
                let styleDefIds = Object.keys( this.data.styleDefinition ).filter( id => id.includes( el ));
                if ( styleDefIds.length === 0 ) {
                    styleDefIds = Object.keys( this.data.styleDefinition ).filter( id =>
                        id.includes( 'commonStyles' ) || id.includes( 'diff' ));
                }
                styleDefIds.forEach( id => {
                    const styleDef = this.data.styleDefinition[ id ];
                    for ( const key in styleDef.style ) {
                        newStyles[ key ] = styleDef.style[ key ];
                    }
                });
            } else {

                if ( !this.data.style && this.data.isLineStyle ) {
                    Object.keys( style ).forEach( key => {
                        if ( key === 'lineThickness' || key === 'lineStyle' || key === 'linePreset' ) {
                            newStyles[ key ] = style[ key ];
                        }
                    });
                } else if ( !this.data.style && this.data.isFillStyle ) {
                    Object.assign( newStyles, style );
                    this.applyStyles( shape, newStyles, el, true );
                    return;
                } else if ( this.data.style ) {
                    Object.assign( newStyles, this.data.style );
                } else {
                    Object.assign( newStyles, style );
                }
            }
            this.applyStyles( shape, newStyles, el );
        });
    }

    /**
     * Function to apply styles to styleDefinitions or shape style as required
     * @param shape
     * @param newStyles
     * @param id styleDef id to apply styles to
     */
    protected applyStyles( shape: ShapeModel, newStyles: Partial<IStyle>, id?: string, skipLineStyle?: boolean ) {
        if ( !id ) {
            // No id, apply styles as shape styles
            for ( const key in newStyles ) {
                if ( this.skipStyleChangeByKey( newStyles, key, skipLineStyle )) {
                    continue;
                }

                // FIXME: Need to consider line and text styles.
                shape.style[key] = newStyles[key];

                // Styles applied to the table should override inner cell spesific styles
                Object.keys( shape.styleDefinitions || {}).forEach( _id => {
                    if ( shape.styleDefinitions[ _id ]) {
                        shape.styleDefinitions[ _id ].style[key] = newStyles[key];
                    }
                });
            }
        } else {
            for ( const key in newStyles ) {
                if ( this.skipStyleChangeByKey( newStyles, key, skipLineStyle )) {
                    continue;
                }
                shape.styleDefinitions[ id ].style[key] = newStyles[key];
            }
        }

    }

    /**
     * Check style key changes can applicable or not and if not return true,
     * else return false
     * @returns boolean
     */
    protected skipStyleChangeByKey( newStyles: Partial<IStyle>, key: string, skipLineStyle?: boolean ): boolean {
        if ( !newStyles.hasOwnProperty( key ) || newStyles[key] === undefined ) {
            return true;
        }

        if ( skipLineStyle &&  ( key === 'lineThickness' || key === 'lineStyle' )) {
            return true;
        } else if ( this.data.isLineStyle &&
                ( key === 'themeIndex' || key === 'themeId' ||
                    ( key === 'lineColor' && newStyles.linePreset !== 'mixed' ) ||
                    key === 'lineAlpha' ||
                    key === 'textColor' ||
                    key === 'fillStyle' ||
                    key === 'fillColor' ||
                    key === 'fillGradient' ||
                    key === 'fillAlpha' ))  {
            return true;
        } else if ( this.data.isFillStyle &&
            ( key === 'themeIndex' || key === 'themeId' ||
                key === 'lineColor' ||
                key === 'lineAlpha' ||
                key === 'lineStyle' ||
                key === 'lineThickness' ))  {
            return true;
        }
        return false;
    }

    /**
     * Edit already existing connector text when highlighted mode toggles
     */
    protected editConnectorText( isHighlighted: boolean, shape: any, style: any, toggling: boolean ) {
        if ( !style.isHighlighted && !style.lineColor && !style.textColor && !toggling ) {
            return;
        }
        if ( isHighlighted ) {
            /**
             * If the line color has not been changed from default then lineColor and
             * textColor will be #1a1a1a. Switch to default highlight colors
             */
            const texts = Object.keys( shape.texts );
            for ( const textId of texts ) {
                if ( style.lineColor === style.textColor && style.lineColor === '#1a1a1a' ) {
                    this.updateText( shape.texts[textId].content,
                        DEFAULT_HIGHTLIGHTED_COLORS.color, style.lineColor );
                } else {
                    this.updateText( shape.texts[textId].content, style.textColor, style.lineColor );
                }
            }
        } else {
            if ( style.textColor === '#FFFFFF' && !toggling && !isHighlighted ) {
                ( shape as ConnectorModel ).isHighlighted = true;
                return this.editConnectorText(
                    true,
                    shape,
                    { ...style, textColor: DEFAULT_HIGHTLIGHTED_COLORS.color },
                    true,
                );
            }
            const texts = Object.keys( shape.texts );
            // ( shape as ConnectorModel ).style.textColor = style.lineColor;
            for ( const textId of texts ) {
                for ( const text of shape.texts[textId].content ) {
                    delete text.backgroundColor;
                    const newColor = style.textColor || text.color;
                    text.color = newColor.toLowerCase() ===
                        DEFAULT_HIGHTLIGHTED_COLORS.color.toLowerCase() ? style.lineColor : newColor;
                }
            }
        }
    }

    protected updateText( content: any, color: string, backgroundColor: string ) {
        for ( const text of content ) {
            text.backgroundColor = backgroundColor;
            text.color = color ? color : text.color;
        }
    }
}

Object.defineProperty( ApplyShapeStyles, 'name', {
    value: 'ApplyShapeStyles',
});
