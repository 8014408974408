import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { StateService } from 'flux-core';
import { UserLocator } from 'flux-user';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';
import { RealtimeStateService } from '../../../realtime/realtime-state.svc';
import { CollabLocator } from '../../../diagram/locator/collab-locator';
import { AbstractUserStrip } from './abstract-user-strip';
import { FollowUserService } from '../../../realtime/follow-user.svc';
import { IStripCollabViewData } from './abstract-user-strip';
import { CollabModel } from 'flux-diagram';


/**
 * This component displays a list of users on the application header.
 *
 * Users are ordered as current user, online, offline and pending. Online users
 * and the current users are given a class "active" and offline and pending users
 * are given a class "inactive".
 *
 * This component will update the user's state and change the user accordingly.
 *
 * @author nuwanthi
 * @since 2018-01-16
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'user-strip',
    templateUrl: './user-strip.cmp.html',
    styleUrls: [ './user-strip.scss' ],
})

export class UserStrip extends AbstractUserStrip implements OnInit {

    /**
     * Indicates how many avatars of the users
     * should be shown.
     */
    @Input()
    public limitCount: number = 0;

    /**
     * This indicated whether the user strip
     * should show active or in-active users.
     * If none passed, it will show both
     * active and in-active users.
     */
    @Input()
    public showActiveOnly: boolean;

    /**
     * This indicates whether the current user
     * has edit permission to the diagram.
     * This is required to restrict the
     * collaborator's personal information.
     */
    @Input()
    public userHasEditPermission: boolean;

    /**
     * ID of the diagram.
     * This is optional, It it's empty or undefined,
     * it will fetch the current diagram
     */
    @Input()
    public diagramId: string;

    /**
     * This indicates whether the tooltip about
     * the user should be shown or not.
     */
    @Input()
    public shouldShowToolTip: boolean = true;

    /**
     * This will emit the selected user id
     */
    @Output()
    public selected: Subject<String> = new Subject();

    constructor(
        @Inject( CollabLocator ) protected cl: CollabLocator,
        protected userLocator: UserLocator,
        protected state: StateService<any, any>,
        protected realtimeStates: RealtimeStateService,
        private followUserService: FollowUserService,
    ) {
        super();
    }

    ngOnInit() {
        const changeFollowUser = this.state.changes( 'FollowUser' ).pipe( map( val => {
            if ( typeof val === 'string' ) {
                return val;
            } else {
                return val?.userId;
            }
        }));
        const collabsObs = this.userLocator.getUserData().pipe(
            filter( user => !!user ),
            debounceTime( 100 ),
            switchMap( user =>
                combineLatest([
                    this.cl.getCollabs( this.diagramId, this.showActiveOnly ),
                    changeFollowUser,
                    this.collabHover,
                ]).pipe(
                    map(([ collabs, fUser, collabId ]) => {
                        collabs = collabs.slice();
                        const currentUser = this.state.get( 'CurrentUser' );
                        const currentUserIdx = collabs.findIndex( c => c.id === currentUser );
                        const removed = collabs.splice( currentUserIdx, 1 ); // first place
                        collabs = removed.concat( collabs );
                        if ( fUser !== '__NONE__' ) {
                            const fUserIdx = collabs.findIndex( c => c.id === fUser );
                            const [ fUserCollab ] = collabs.splice( fUserIdx, 1 );
                            collabs.splice( 1, 0, fUserCollab ); // add it at second place
                        }
                        return this.getCollabViews( collabs, collabId );
                    }),
                ),
            ),
            map( collabViews => {
                const userId = this.state.get( 'CurrentUser' );
                return collabViews.map( collabView => {
                    collabView.currentUser = !!collabView?.collab?.id && collabView.collab.id === userId;
                    return collabView;
                });
            }),
        );

        this.collabs = combineLatest([
            collabsObs,
            changeFollowUser,
            this.followUserService.getSpotlightState(),
            this.followUserService.getSpotlightRequestingState(),
        ]).pipe(
            map(([ collabData, followUser, spotlightState, spotlightRequestingState ]) =>
                collabData.map(( collab: IStripCollabViewData ) => {
                    if ( collab.currentUser ) {
                        collab.spotlightState = spotlightState;
                        collab.followState = false;
                        collab.realtimeUserColor = spotlightState ? this.realtimeStates
                            .getUserDetails( collab.collab.id ).color : '#FFFFFF';
                        collab.spotlightRequestingState = false;
                    } else {
                        if ( !!collab?.collab?.id &&  collab.collab.id === followUser ) {
                            collab.realtimeUserColor = this.realtimeStates.getUserDetails( followUser )?.color || '#FF0000';
                            collab.followState = true;
                        } else {
                            collab.realtimeUserColor = '#FFFFFF';
                            collab.followState = false;
                        }
                        collab.spotlightState = false;
                        collab.spotlightRequestingState = spotlightRequestingState === collab?.collab?.id;
                    }
                    return collab;
                }),
            ),
            startWith([]),
            debounceTime( 100 ),
        );

    }

    public getFollowMessageTemplate( currectUser: boolean, spotlightState: boolean, followState: boolean ): string {
        if ( currectUser ) {
            if ( spotlightState ) {
                return 'FOLLOW.STOP_SPOTLIGHT_ME';
            } else {
                return 'FOLLOW.CLICK_SPOTLIGHT_ME';
            }
        } else {
            if ( followState ) {
                return 'FOLLOW.STOP_FOLLOW';
            } else {
                return 'FOLLOW.CLICK_FOLLOW';
            }
        }
    }

    public getCollabRole( collab: CollabModel ): string {
        if ( collab.isGuestUser ) {
            return 'FOLLOW.GUEST_USER';
        }
        return collab.roleTitle;
    }
}
