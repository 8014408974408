import { Injectable } from '@angular/core';
import { AbstractCommand } from '../../../../../../libs/flux-core/src';
import { ObjectConvertor } from '../../../framework/edata/object-convertor.svc';

/**
 * This command converts an existing diagram shape to e database object.
 */
@Injectable()
export class ConvertToObject extends AbstractCommand {
    constructor( private objectConvertor: ObjectConvertor ) {
        super() /* istanbul ignore next */;
    }

    public execute(): boolean {
        this.objectConvertor.convertToEData( this.data.shapeId ).subscribe();
        return true;
    }
}

Object.defineProperty( ConvertToObject, 'name', {
    value: 'ConvertToObject',
});
