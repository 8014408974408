// tslint:disable:max-classes-per-file
import { StateService, SharedStateService } from 'flux-core';

/**
 * This file contains states and types used with StateService in diffrent context
 * common to editor and viewer interfaces.
 */

/**
 * Expected state data for the InterfaceControlState state
 */
export enum InterfaceControlState {
    Edit = 'edit',
    View = 'view',
}

/**
 * StateService representation for Interface Control State
 */
export class InterfaceControlStateService extends StateService< 'InterfaceControlState', InterfaceControlState > {}


/**
 * Expected state data for the MouseControlState state
 */
export enum MouseControlState {
    Normal = 'normal',
    Pan = 'pan',
    Connector = 'connector',
    Text = 'text',
    Comment = 'comment',
    Pen = 'pen',
    Highlighter = 'highlighter',
    Rectangle = 'rectangle',
    StickyNote = 'sticky-note',
    BasicCard = 'basic-card',
    Line = 'line',
}

/**
 * StateService representation for Mouse Control State
 */
export class MouseControlStateService extends StateService< 'MouseControlState', MouseControlState > {}

/**
 * Expected state data for the IRealtimeStateInfo state
 */
export interface IRealtimeStateInfo {
    action: 'viewing' | 'editing';
    color?: string;
}

/**
 * StateService representation for Mouse Control State
 */
export class RealtimeStateService extends SharedStateService< 'Realtime', IRealtimeStateInfo > {}

/**
 * StateService representation for Available Sidebar Panels
 */
export class AvailableSideBarPanelsStateService
    extends StateService< 'AvailableSideBarPanels', { [name: string]: any } > {}


/**
 * StateService representation status of the editor
 */
export class EditorStatus extends StateService< 'EditorStatus', { status: 'opening' | 'closing' } > {}

/**
 * StateService representation status of the viewer
 */
export class ViewerStatus extends StateService< 'ViewerStatus', { status: 'opening' | 'closing' } > {}


/**
 * Expected state data for the SelectionInteractionState state
 */
export enum SelectionInteractionState {
    Started = 'started',
    Stopped = 'stopped',
}

/**
 * Expected state data for the MultiSelectionInteractionState state
 */
export enum MultiSelectionInteractionState {
    Started = 'started',
    Stopped = 'stopped',
}


/**
 * A Series of instructions to passed into the
 * application state to trigger actions elsewhere
 * to facilitate displaying tooltips.
 */
export enum TooltipAutomateState {
    /**
     * no automation. default
     */
    noAutomation = 'noAutomation',
    /**
     * expands the main header
     */
    expandHeader = 'expandHeader',
    /**
     * collapses the header
     */
    collapseHeader = 'collapseHeader',
    /**
     * collapses the left side bar
     */
    collapseLSB = 'collapseLSB',
    /**
     * shows the edata panel in lsb
     */
    expandEData = 'expandEdata',
    /**
     * shoes the task panel in lsb
     */
    expandTask = 'expandTask',
    expandTaskGroup = 'expandTaskGroup',
    openTaskDetail = 'openTaskDetail',
    showTaskOption = 'showTaskOption',
    hideTaskOption = 'hideTaskOPtion',
    closeFABMenu = 'closeFABMenu',
    openFABMenu = 'openFABMenu',
    addDummyTask = 'addDummyTask',
    showPlusCreateTooltip = 'showPlusCreateTooltip',
    hidePlusCreateTooltip = 'hidePlusCreateTooltip',
}
