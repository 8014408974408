import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { LockType } from 'flux-definition';

/**
 * This command is used to lock and unlock the shapes.
 * Get all selected shapes and update lockType property
 * using shouldLock input data command will decide shapes should lock or unlock
 *
 * @author  Sajeeva
 * @since   2020-11-12
 */
@Injectable()
@Command()
export class ToggleShapeLock extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shouldLock: boolean,
        shapeIds: string[],
    };

    constructor( protected ds: DiagramChangeService,
                 protected state: StateService<any, any> ) {
        super( ds );
    }

    /**
     * Prepare command data. If shouldLock input property true,
     * all selected shapes` lockType change to locked-for-all
     * else all selected shapes` lockType change to locked-for-none
     */
    public prepareData() {
        const shapeIds =  this.data.shapeIds === undefined ? this.state.get( 'Selected' ) :  this.data.shapeIds;
        for ( const shapeId of shapeIds ) {
            if ( !this.changeModel.shapes[shapeId].isConnector()) {
                const shape = this.changeModel.shapes[shapeId] as ShapeModel;
                shape.lockType = this.data.shouldLock ? LockType.LockedForAll : LockType.LockedForNone;
            }
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ToggleShapeLock, 'name', {
    value: 'ToggleShapeLock',
});
