import { cloneDeep, flattenDeep } from 'lodash';
import { Injectable } from '@angular/core';
import {
    Random,
    Rectangle,
    AbstractCommand,
    Command,
    CommandScenario,
    StateService,
} from 'flux-core';
import { Observable, of } from 'rxjs';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { IShapeData } from '../../shape/shape-data.i';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ShapeType, IPoint2D } from 'flux-definition';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { uniq } from 'lodash';
import { from } from 'rxjs';
import { DiagramToViewportCoordinate } from '../../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { Clipboard } from '@creately/clipboard';
import { Stage } from '@creately/createjs-module';
import { Selection } from '../../../editor/selection/selection';

/**
 * This command composes a new shape data from the data copied to
 * clipboard.
 *
 * Before duplicating the shapes it changes the id and position of the shapes.
 *
 * This an AbstractCommand which sets the duplicated shapes details in resultData.
 *
 * data: {
 *    target: { x, y } location where the shape needs to be copied
 *    contextMenuEvent: If this is given new shapes are position based on the context menu
 *                      event targeted point. If not shapes are position based on the
 *                      current location.
 *    onSameLocation: optional boolean property is to specifiy whether the cloned shapes
 *                    should be at the same location of the original shapes.
 * }
 */
@Injectable()
@Command()
export class CloneShapes extends AbstractCommand {

    public static get dataDefinition() {
        return {
           target: false,
           onSameLocation: false,
           externalData: false,
        };
    }

    constructor( protected random: Random,
                 protected ll: DiagramLocatorLocator,
                 protected vToDcoordinate: ViewportToDiagramCoordinate,
                 protected dToVcoordinate: DiagramToViewportCoordinate,
                 protected state: StateService<any, any>,
                 protected clipboard: Clipboard ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Checks whether the browser supports context-menu paste option
     * and if it is not supported, cancels the command from further execution.
     */
    public prepareData() {
        this.updateTargetLocation();
    }

    public execute(): Observable<any> {
        // NOTE: Blocking the clone on the canvas while the shapes are on the move.
        // ** Move iteration includes rotation and scale also.
        const interactionCanvas: Stage = this.state.get( 'InteractionCanvas' );
        const selection: Selection = <Selection> interactionCanvas.getChildByName( 'Selection' );
        const editorStatus = this.state.get( 'EditingText' );
        if ( !editorStatus.open && ( !selection || !selection.interactionInProgress )) {
            const locator = this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
            return forkJoin(
                locator.getDiagramOnce(),
                this.getShapesToAdd(),
            ).pipe(
                map(([ diagram, copiedShapeData ]) => {
                    this.prepareDataToAddShapes( diagram as DiagramModel, copiedShapeData );
                }),
            );
        }
        return;
    }

    /**
     * This function returns the shapes which needs to be added onto canvas.
     */
    protected getShapesToAdd(): Observable<any> {
        if ( this.data.externalData ) {
            return of( this.data.externalData );
        }

        if ( !this.data.contextMenuEvent ) {
            if ( this.data[0]) {
                this.data = { ...this.data[0], ...this.data };
                delete this.data[0];
            }
            return of( this.data );
        } else {
            const data = from( this.clipboard.paste().then( text =>
                JSON.parse( text )));
            return data;
        }
    }

    /**
     * This prepares data to create new shapes and groups from the
     * given shapes data.
     */
    protected prepareDataToAddShapes( diagram: DiagramModel, copiedData: any ) {
        const shapeIds = copiedData.shapeIds;
        const copiedShapes = cloneDeep( copiedData.shapesToCopy );
        // Finds the minimum top left point within the shapes.
        const topLeftCorner = this.getTopLeft( copiedShapes.map( shape => shape.bounds ));
        const targetedLocation = { x: this.data.target.x, y: this.data.target.y };
        const changedIdsMap: any = {};

        // NOTE: create map of old shape ids to new shape ids
        copiedShapes.forEach(({ data: shape, bounds }) => {
            let newShapeId;
            if ( shape.$$preserveId ) {
                newShapeId = shape.id;
            } else {
                newShapeId = this.random.shapeId();
            }
            changedIdsMap[shape.id] = newShapeId;
        });
        // Sorting copied shapes from z index array to maintain original z index
        copiedShapes.sort(( a, b ) => a.data.zIndex - b.data.zIndex );

        // Get copied shapes bounds
        const selectionBounds = this.getShapesBounds( copiedData.shapesToCopy );
        const isInViewPort = this.isSelectionInViewPort( selectionBounds );

        let zIndex = diagram.maxZIndex;
        // NOTE: clone shapes and shift their position a little
        const clonedShapes = copiedShapes.map(({ data: shape, bounds, type }) => {
            shape.id = changedIdsMap[shape.id];
            this.prepareContainerData( shape, changedIdsMap );
            if ( type === ShapeType.Connector ) {
                this.updateConnectorPath( shape.path, targetedLocation, topLeftCorner, isInViewPort, selectionBounds );
                this.updateConnectorConnections( shape.path, changedIdsMap );
            } else if ( !( this.data && this.data.onSameLocation )) {
                const currentLocation = { x: shape.x, y: shape.y };
                const updatedValue = this.updateBasicShapeLoction(
                    currentLocation, targetedLocation, topLeftCorner, isInViewPort, selectionBounds );
                shape.x = updatedValue.x;
                shape.y = updatedValue.y;
            }
            // Update the z-index
            zIndex++;
            shape.zIndex = zIndex;
            shape.containerRegionId = undefined;
            return shape;
        });

        // Clone groups by generate new group Ids
        const clonedGroups = this.getClonedGroups( diagram, copiedData.shapesToCopy, changedIdsMap );

        this.resultData = {
            shapeIds,
            dataDefs: copiedData.dataDefs,
            shapesToBeClone: clonedShapes,
            groupsToBeClone: clonedGroups,
        };
    }

    /**
     * Update the relationship between the container and children with new ids
     */
    protected prepareContainerData( shape, changedIdsMap ) {
        if ( shape.children ) {
            const newChildren = {};
            for ( const key in shape.children ) {
                if ( changedIdsMap[key]) {
                    newChildren[ changedIdsMap[key ]] = shape.children[key];
                }
            }
            shape.children = newChildren;
        }

        // update the shapeIds in container regions
        if ( shape.containerRegions ) {
            for ( const regionId in shape.containerRegions ) {
                if ( shape.containerRegions[regionId] && shape.containerRegions[regionId].shapes ) {
                    for ( const oldShapeId in shape.containerRegions[regionId].shapes ) {
                        const cr = shape.containerRegions[regionId].shapes;
                        if ( cr[oldShapeId]) {
                            const crShape = cloneDeep( cr[oldShapeId]);
                            cr[changedIdsMap[oldShapeId]] = crShape;
                            delete cr[oldShapeId];
                        }
                    }
                }
            }
        }

        if ( shape.containerId ) {
            shape.containerId = changedIdsMap[shape.containerId];
        }
    }

    /**
     * This function returns true if the selected shapes encloses in the current view port.
     * @param bounds - selection bounds
     */
    protected isSelectionInViewPort( bounds: Rectangle ): Boolean {
        const viewport: Rectangle = this.state.get( 'DiagramViewPort' );
        return viewport.contains( this.dToVcoordinate.rect( bounds ));
    }

    /**
     * This updates the target location where the shapes need to be
     * rendered.
     * According to the context menu event this updates the target
     * location for this command
     */
    protected updateTargetLocation() {
        if ( !this.data.target ) {
            this.data.target = {};
        }
        if ( this.data.contextMenuEvent ) {
            this.data.target.x = this.vToDcoordinate.x( this.data.contextMenuEvent.offsetX );
            this.data.target.y = this.vToDcoordinate.y( this.data.contextMenuEvent.offsetY );
        }
    }

    /**
     * Returns the minimum top left point within the given bounds.
     */
    protected getTopLeft( bounds: Rectangle[]): IPoint2D {
        let x = bounds[0].left;
        let y = bounds[0].top;
        bounds.forEach( bound => {
            x = Math.min( bound.left, x );
            y = Math.min( bound.top, y );
        });

        return{
            x: x,
            y: y,
        };
    }

    /**
     * Returns the calculated new value by considering the offset.
     * @param targetValue   location where the shape needs to be rendered
     * @param offset    Value which indicates how much shape needs to be shifted from current targeted
     *                  value.
     */
    protected getUpdatedLocation( currentLocation: IPoint2D, targetLocation: IPoint2D,
                                  offset: IPoint2D, isInViewPort: Boolean, selectionBounds: Rectangle ): IPoint2D {
        if ( targetLocation.x !== undefined && targetLocation.y !== undefined ) {
            return{
                x: targetLocation.x + offset.x,
                y: targetLocation.y + offset.y,
            };
        } else if ( isInViewPort ) {
            return {
                x: currentLocation.x + 50,
                y: currentLocation.y + 50,
            };
        } else {
            const viewport: Rectangle = this.state.get( 'DiagramViewPort' );
            return {
                x: this.vToDcoordinate.x( viewport.centerX ) - ( selectionBounds.centerX - currentLocation.x ),
                y: this.vToDcoordinate.y( viewport.centerY ) - ( selectionBounds.centerY - currentLocation.y ),
            };
        }
    }

    /**
     * Updates the basic shape location with newly calculated value.
     *
     * @param currentLocation current location the shape is rendered
     * @param targetedLocation Point where the shape neeeds to be shifted
     * @param topLeftCorner Point which indicates the currentPath relative point.
     */
    protected updateBasicShapeLoction(
        currentLocation: any, targetedLocation: IPoint2D, topLeftCorner: IPoint2D,
        isInViewPort: Boolean, selectionBounds: Rectangle ): IPoint2D {
        const offset = this.findOffSet( currentLocation, topLeftCorner );
        return this.getUpdatedLocation( currentLocation, targetedLocation, offset, isInViewPort, selectionBounds );
    }

    /**
     * Updates the given connector path with newly calculated values
     * This updates all control points associated with the path.
     * @param currentPath Current path the connector is drawn
     * @param targetedPosition Point where the connector neeeds to be shifted
     * @param topLeftCorner   Point which indicates the currentPath relative point.
     */
    protected updateConnectorPath( currentPath: any, targetedPosition: IPoint2D,
                                   topLeftCorner: IPoint2D, isInViewPort: Boolean, selectionBounds: Rectangle ) {
        Object.keys( currentPath ).forEach( id => {
            const point = currentPath[id];
            if ( typeof point === 'object' ) {
                flattenDeep([ point.c1, point.c2, point, point.bumps ]).forEach( dataPoint => {
                    if ( !dataPoint ) {
                        return;
                    }
                    if ( !( this.data && this.data.onSameLocation )) {
                        const offset = this.findOffSet( dataPoint, topLeftCorner );
                        const updatedPoint = this.getUpdatedLocation( dataPoint,
                            targetedPosition, offset, isInViewPort, selectionBounds );
                        dataPoint.x = updatedPoint.x;
                        dataPoint.y = updatedPoint.y;
                    }
                });
            }
        });
    }

    /**
     * Updates or removes connector connections. If the connected shape is also copied
     * the connector will connect to the copied shape. Otherwise it will be disconnected.
     * @param currentPath cloned connector path
     * @param changedIdsMap map of old shape ids to new shape ids
     */
    protected updateConnectorConnections( currentPath: any, changedIdsMap: any ): void {
        Object.keys( currentPath ).forEach( id => {
            const point = currentPath[id];
            const oldShapeId = point.shapeId;
            if ( !oldShapeId ) {
                return;
            }
            const newShapeId = changedIdsMap[oldShapeId];
            if ( newShapeId ) {
                point.shapeId = newShapeId;
            } else if ( !point.forceExisitingId ) {
                delete point.shapeId;
                delete point.gluepointId;
                delete point.gluepointLocked;
            } else {
                delete point.forceExisitingId;
            }
        });
    }

    /*
     * Returns the difference between the given point and relativePoint value.
     * @param relativePoint Relative point which is used to calculate the offset
     */
    protected findOffSet( point: IPoint2D, relativePoint: IPoint2D ) {
        if ( !point ) {
            return;
        }
        return {
            x: point.x - relativePoint.x,
            y: point.y - relativePoint.y,
        };
    }

    /**
     * This function returns the bounds of the rectangle for
     * the given shapes. Merge all the shapes to create a Rectangle
     * containing all the shapes of the diagram. The size of this
     * Rectangle can be used to determine the size of the diagram.
     */
    private getShapesBounds( shapes: IShapeData[]) {
        let bounds = new Rectangle( 0, 0, 0, 0 );
        shapes.forEach(( shape, i ) => {
            if ( i === 0 ) {
                bounds = new Rectangle( shape.bounds.left,
                    shape.bounds.top, shape.bounds.w, shape.bounds.h );
            } else {
                const shapeBounds = new Rectangle( shape.bounds.left,
                    shape.bounds.top, shape.bounds.w, shape.bounds.h );
                bounds.absorb( shapeBounds );
            }
        });
        return bounds;
    }

    /**
     * This function generates new group Ids for the cloned grouped shapes.
     * Take all groups of the given shapes and generate new group Ids. Using newly created
     * group Ids, create groups map.
     * @param diagram - DiagramModel
     * @param orgShapes - Origin shapes
     * @param shapeIdsMap - Map of the cloned shapes
     */
    private getClonedGroups( diagram: DiagramModel, orgShapes: IShapeData[], shapeIdsMap: any ): any {
        const groupsArray: Array<{ id: string, shapes: string[], groups: string[]}> = [];
        const groupedIdsMap: any = {};
        let groupedIds: string[] = [];
        // Take all groups of the given shapes
        orgShapes.forEach( shapeId => {
            groupedIds = groupedIds.concat( diagram.getGroupHierarchy( shapeId.data.id ));
        });
        if ( groupedIds && groupedIds.length > 0 ) {
            groupedIds = uniq( groupedIds );
            // Generate new group Ids
            groupedIds.forEach( groupId => {
                groupedIdsMap[groupId] = this.random.groupId();
            });
            // Create new groups with newly generated group Ids
            groupedIds.forEach( groupId => {
                const shapes = diagram.groups[groupId].shapes;
                const groups = diagram.groups[groupId].groups;
                const newShapes = [];
                const newGroups = [];
                shapes.forEach( shapeId => {
                    newShapes.push( shapeIdsMap[shapeId]);
                });
                groups.forEach( id => {
                    newGroups.push( groupedIdsMap[id]);
                });
                groupsArray.push({ id: groupedIdsMap[groupId], shapes: newShapes, groups: newGroups });
            });
        }
        return groupsArray;
    }

}

Object.defineProperty( CloneShapes, 'name', {
    value: 'CloneShapes',
});
