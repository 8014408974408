import { INodeView } from './node-view.i';
import { FormulaFieldCreator } from 'apps/nucleus/src/base/ui/shape-data-editor/ui-component-creators/formula-field-creator.cmp';
import { SingleSelectComboCreator } from './../../../../../base/ui/shape-data-editor/ui-component-creators/single-select-combo-creator.cmp';
import { filter, map, take } from 'rxjs/operators';
import { EDataLocatorLocator } from 'apps/nucleus/src/base/edata/locator/edata-locator-locator';
import { DiagramCommandEvent } from 'apps/nucleus/src/editor/diagram/command/diagram-command-event';
import { IDataItem, DataType } from 'flux-definition';
import { LabelEditableDataItem } from './../../label-editable-data-item.cmp';
import { DynamicComponent } from 'flux-core/src/ui';
import { mergeAttributes } from '@tiptap/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService, ModifierUtils, Logger, Random } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { merge, timer } from 'rxjs';
/**
 * DataItemTiptapNodeView is a Tiptap nodeview
 * which renders a given dataitem in the tiptap body
 */
export class DataItemTiptapNodeView implements INodeView {

    /**
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create( injector: Injector, viewCR: ViewContainerRef, diagramId: string ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const commandService = injector.get( CommandService );
        const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const instance = new DataItemTiptapNodeView(
            cfr, injector, commandService, stateSvc, ll, viewCR,
            diagramId,
        );
        return instance;
    }

    public name = 'dataItemNode';
    public group = 'block';
    public atom = true;

    // All the node views created are stored here
    protected items = {};

    private constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected command: CommandService,
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected dir: ViewContainerRef,
        protected diagramId: string,
    ) {
    }

    public destroy() {
        const subs = [];
        Object.values( this.items ).forEach(( itemInstances: any []) => {
            Object.values( itemInstances ).forEach( item =>  subs.push( ...( item.subs || [])));
        });
        while ( subs.length > 0 ) {
            subs.pop().unsubscribe();
        }
    }

    public addAttributes = () => ({
        entityId: {
            default: null,
        },
        edataId: {
            default: null,
        },
        shapeId: {
            default: null,
        },
        dataItemId: {
            default: null,
        },
        data: {
            default: null,
        },
        instanceId: {
            default: null,
        },
        template: {
            default: null,
        },
    })

    public parseHTML = () => [
        {
        tag: 'data-item-node',
        },
    ]

    public renderHTML = ({ HTMLAttributes }) => [ 'data-item-node', mergeAttributes( HTMLAttributes ) ] as any;

    public addNodeView = () =>
        ({
        editor,
        node,
        getPos,
        HTMLAttributes,
        decorations,
        extension,
        }) => {

        const { dataItemId, data, template, edataId, entityId } = node.attrs;
        let shapeId = node.attrs.shapeId;
        const dom = document.createElement( 'div' );
        dom.classList.add( 'data-item-node' );

        let dc: DynamicComponent;
        let itemRef: ComponentRef<LabelEditableDataItem>;

        // Finds upto 1 seconds if the data items is available, if not found
        // show 'Data item has been deleted...'


        merge(
            this.ll.forDiagram( this.diagramId, false ).getDiagramModel().pipe(
                map( d =>  {
                    let shape = d.shapes[ shapeId ];
                    if ( edataId && entityId && !shape ) {
                        shape = d.getShapeFromEntityId( entityId );
                        shapeId = shape.id;
                    }
                    return {
                        diagram: d,
                        data: d.getShapeDataItems( shapeId )[ dataItemId ] || data,
                    };
                }),
                filter( value => value.data ),
                take( 1 ),
            ),
            timer( 1500 ),
        ).pipe(
            take( 1 ),
        ).subscribe(( value: any ) => {
            node.attrs.data = null; // Clear data
            if ( value && value.diagram && value.data ) {
                const dataItem = value.data;
                const d = value.diagram;
                dc = new DynamicComponent( this.cfr, this.injector );
                itemRef = dc.makeComponent( LabelEditableDataItem );
                dc.insert( this.dir, itemRef );
                dom.appendChild( itemRef.location.nativeElement );
                itemRef.instance.data = dataItem;
                itemRef.instance.showLabelVisibilityOptions = true;
                this.addItem( dataItem, itemRef, shapeId );
                this.subscribeToItemChange( itemRef, shapeId, editor );
                this.subscribeToItemRemove( itemRef, d.shapes[ shapeId ], editor );
                this.subscribeToItemUpdate( itemRef, shapeId, editor );
                this.subscribeToRerender( dc, dom, itemRef, shapeId );
                itemRef.changeDetectorRef.detectChanges();
            } else {
                const notFound = document.createElement( 'div' );
                if ( template === 'empty' ) {
                    notFound.innerHTML = '';
                    node.attrs.template = null; // Reset template
                } else {
                    dom.remove();
                    return;
                }
                dom.appendChild( notFound );
            }
        });

        return {
            dom,
            destroy: () => {
            if ( dc && itemRef ) {
                this.destroyInstance( dc, dataItemId, ( itemRef as any ).instanceId );
            }
            },
            stopEvent: e => {
            if ( e.key && e.type === 'keydown' && ( e.key === 'ArrowUp' || e.key === 'ArrowDown' )) {
                editor.commands.focus();
            }
            return true;
            },
            selectNode: () => {
            // const input = itemRef.location.nativeElement.querySelector( 'input' );
            // if ( input ) {
            //   // input.focus();
            // }
            },
        };

    }

    // public addInputRules = function ( this ) {
    //     return [
    //     nodeInputRule({
    //         find: /(?:^|\s)((?:{{)((?:[^~]+))(?:}}))$/,
    //         type: this.type,
    //         getAttributes: match => {
    //         const [ , , dataItemId ] = match;
    //         return { dataItemId };
    //         },
    //     }),
    //     ];
    // };

    public destroyAllInstances( dc, dataItemId ) {
        Object.keys( this.items[ dataItemId ]).forEach( instanceId => {
            this.destroyInstance( dc, dataItemId, instanceId );
        });
    }

    public destroyInstance( dc, dataItemId, instanceId ) {
        if ( this.items[ dataItemId ] && this.items[ dataItemId ][ instanceId ] &&
            this.items[ dataItemId ][ instanceId ].itemRef ) {
                const itemRef = this.items[ dataItemId ][ instanceId ].itemRef;
                itemRef.destroy();
                ( dc as any ).remove( this.dir, itemRef );
                this.items[ dataItemId ][ instanceId ].subs.forEach( s => {
                    s.unsubscribe();
                });
                delete this.items[ dataItemId ][ instanceId ];
        }
    }

    protected addItem( dataItem: IDataItem<DataType>, itemRef: ComponentRef<LabelEditableDataItem>, shapeId ) {
        // Setting inputs
        itemRef.instance.setData( dataItem );
        if ( !this.items[ dataItem.id ]) {
            this.items[ dataItem.id ] = {};
        }
        const instanceId = Random.base62( 4 );
        ( itemRef as any ).instanceId = instanceId;
        this.items[ dataItem.id ][ instanceId ] = { itemRef, subs: []};
    }

    protected subscribeToItemChange( itemRef: ComponentRef<LabelEditableDataItem>, shapeId, editor ) {
        const item = itemRef.instance;
        const sub = item.change.subscribe( change => {
            const data = Object.assign( item.data, change );
            this.command.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                [shapeId]: {
                    [data.id]: data,
                },
            });
        });
        this.items[ item.data.id ][( itemRef as any ).instanceId ].subs.push( sub );
    }

    protected subscribeToRerender( dc, dom: HTMLElement, itemRef: ComponentRef<LabelEditableDataItem>, shapeId ) {
        const item = itemRef.instance;
        const sub = this.ll.forDiagram( this.diagramId, false ).getDiagramChanges().subscribe( c => {
            const dataDefsUpdated = c.split && c.split.diagram &&
                ModifierUtils.hasChanges( c.split.diagram, 'dataDefs' );
            const dataItemUpdated = c.split && c.split.shapes && c.split.shapes[ shapeId ] &&
                ModifierUtils.hasChanges( c.split.shapes[ shapeId ], 'data' );
            if ( dataDefsUpdated || dataItemUpdated ) {
                const newDI = c.model.getShapeDataItems( shapeId )[ item.data.id ];
                if ( newDI ) {
                    item.setData( newDI );
                    itemRef.changeDetectorRef.detectChanges();
                } else {
                    dom.innerHTML =  '';
                    this.destroyInstance( dc, item.data.id, ( itemRef as any ).instanceId );
                    dom.remove();
                }
            }
        });
        this.items[ item.data.id ][( itemRef as any ).instanceId ].subs.push( sub );
    }

    protected subscribeToItemRemove( itemRef: ComponentRef<LabelEditableDataItem>, shape, editor ) {
        const item = itemRef.instance;
        const subscription = item.removed.subscribe( id => {
            /* istanbul ignore next */
            if ( shape && shape.eDataId ) {
                const ell = this.injector.get( EDataLocatorLocator );
                ell.getEntityOnce( shape.eDataId, shape.entityId ).subscribe( entity => {
                    if ( entity.getDirectRefFields( id ).length > 0 ) {
                        Logger.error( 'Cannot remove selected data item as it is referred in formulas' );
                        return;
                    }
                    if ( entity.getLookupRefFields( id ).length > 0 ) {
                        Logger.error( 'Cannot remove selected data item as it is referred in formulas' );
                        return;
                    }
                    subscription.unsubscribe();
                    this.command.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                        [shape.id]: {
                            [id]: undefined,
                        },
                    });
                });
            } else {
                subscription.unsubscribe();
                this.command.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                    [shape.id]: {
                        [id]: undefined,
                    },
                });
            }
        });
    }

    /**
     * Subscribes to the update of the data item ( when the user clicks the update option
     * in the data item's options dropdown )
     * The updator component depends on the the datam.
     * @param item
     */
    protected subscribeToItemUpdate( itemRef: ComponentRef<LabelEditableDataItem>, shapeId, editor ) {
        const item = itemRef.instance;
        const sub = item.update.subscribe( id => {
            item.hide();
            const cmp = this.getUpdatorComponent( item.data.type );
            if ( cmp ) {
            const dc: DynamicComponent = new DynamicComponent( this.cfr, this.injector );
            const updator: ComponentRef<any> = dc.makeComponent( cmp );
                // Setting inputs
            updator.instance.dataItem = item.data;
            updator.instance.type = 'update';
            dc.insert( this.dir, updator );

            updator.changeDetectorRef.detectChanges();
            if ( updator.instance.show ) {
                updator.instance.show();
            }
            item.appendChild( updator.location.nativeElement );
            updator.instance.change.pipe( take( 1 )).subscribe( change => {
                const data = Object.assign( item.data, change );
                this.command.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                    [shapeId]: {
                        [data.id]: data,
                    },
                });
            });
            updator.instance.closed.pipe( take( 1 )).subscribe(() => {
                item.removeChild( updator.location.nativeElement );
                ( dc as any ).remove( this.dir, updator );
                item.show();
            });
            }
        });
        this.items[ item.data.id ][( itemRef as any ).instanceId ].subs.push( sub );
    }

    /**
     * Returns the updator component for the given data item
     * @param dataType
     */
    private getUpdatorComponent( dataType: DataType ) {
        if ( dataType === DataType.OPTION_LIST ) {
            return SingleSelectComboCreator;
        }
        if ( dataType === DataType.FORMULA ) {
            return FormulaFieldCreator;
        }
    }


}
