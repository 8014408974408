import { EObject } from 'ecore';
import { MapOf } from 'flux-core';
import { EDataModel } from '../../edata/model/edata.mdl';
import { EntityModel } from '../../edata/model/entity.mdl';

export abstract class AbstractEcoreModel {
    public abstract createObject( data: any ): EObject;
    public abstract resolve( entity: EntityModel, eObjects: MapOf<MapOf<EObject>>, model: EDataModel );
    public hasParent( links: any ) {
        return false;
    }
}
