import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {
    StateService,
    ContainerEnv,
    CommandService,
    Random,
    Logger,
    CommandResultError, AppConfig, ModalController, NotifierController, AbstractNotification, NotificationType } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractRouteResolver } from '../framework/controller/abstract.resolver';
import { PlanPermManager, UpgradeDialogType, UpgradeDialogWindow } from 'flux-user';
import { IDialogBoxData, LibraryState, PlanPermission } from 'flux-definition';
import { ProjectDiagramCommandEvent } from '../creator/project/command/project-diagram-command.event';
import { PluginCommandEvent } from '../plugin/command/plugin-command-event';
import { concat, defer, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DiagramCommandEvent } from './diagram/command/diagram-command-event';
import { TemplateModel } from './diagram/templates/template.mdl';
import { DataStore } from 'flux-store';
import { ViewportToDiagramCoordinate } from '../base/coordinate/viewport-to-diagram-coordinate.svc';
import { DiagramToViewportCoordinate } from '../base/coordinate/diagram-to-viewport-coordinate.svc';
import { ILoadedLibrary } from './library/loaded-library.state';
import { difference as _difference, cloneDeep as _cloneDeep } from 'lodash';
import { LibraryType } from './library/abstract-shape-library';
import { LibraryList } from './ui/temp-add-libs-menu/library-list';
import { TranslateService } from '@ngx-translate/core';
import { SyncDiagramEDataService } from '../framework/edata/sync-diagram-edata.svc';
import { DefinitionLocator } from '../base/shape/definition/definition-locator.svc';
import { ProjectLocator } from 'flux-diagram';
import { ShapeManageService } from './feature/shape-manage.svc';
import { Restriction } from './diagram/restriction/restriction.svc';


/**
 * This resolver will create a new document if the user has premission to do so.
 */
@Injectable()
export class NewDocumentResolver extends AbstractRouteResolver implements CanActivate {

    constructor(
        protected state: StateService<any, any>,
        protected env: ContainerEnv,
        protected router: Router,
        protected commandService: CommandService,
        protected planPermManager: PlanPermManager,
        protected random: Random,
        protected logger: Logger,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
        protected dToVcoordinate: DiagramToViewportCoordinate,
        protected datastore: DataStore,
        protected libraryList: LibraryList,
        protected modalController: ModalController,
        protected translate: TranslateService,
        protected notifierController: NotifierController,
        protected eDataSyncSvc: SyncDiagramEDataService,
        protected defLocator: DefinitionLocator,
        protected projectLocator: ProjectLocator,
        protected shapeManage: ShapeManageService,
        protected restriction: Restriction,
    ) {
        super( state );
    }

    /**
     * Runs before the route is resolved, we check the passed 'data' prop
     * in the route params for instructions on how to filter and redirect to
     * an external url.
     */
    public canActivate( route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot ): boolean {
        let projectId = route.paramMap.get( 'project' );
        if ( !projectId ) {
            projectId = 'home';
        }

        let templateId = '';
        if ( route.queryParams.diagramId ) {
            if ( route.queryParams.snapshotId ) {
                templateId = [ route.queryParams.diagramId, route.queryParams.snapshotId ].join( '_' );
            } else {
                templateId = route.queryParams.diagramId + '_CURRENT';
            }
        }

        return this.createDiagram( projectId, templateId || route.queryParams.templateId );
    }


    /**
     * Creates the diagram.
     */
    protected createDiagram( projectId: string, templateId: string ): boolean {
        // FIXME - this perm check should be moved!
        // It should be done in the initialization service
        // Avoid using the env check for permission
        // if ( this.env.isDesktop && !this.planPermManager.check([ PlanPermission.CREATELY_DESKTOP_ACCESS ])) {
        //     throw new DesktopPermissionError( 'Desktop access permission is denied' );
        // }

        const diagramId = this.random.diagramId();
        if ( templateId && templateId.includes( '_' )) {
            return this.dispatchCreateCommand(
                {
                    id: diagramId,
                    templateId: templateId.replace( '_CURRENT', '' ),
                    type: 'duplicate',
                }, projectId, '__SNAPSHOT__' );
        }
        return this.dispatchCreateCommand(
            { id: diagramId, type: 'diagram/def/block.js#Block' }, projectId, templateId );
    }

    /**
     * Dispatches commands to create a new diagram using given data
     */
    protected dispatchCreateCommand( commandData: any, projectId: string, templateId: string = undefined  ): boolean {
        let commandEvent: ProjectDiagramCommandEvent;
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            commandEvent = PluginCommandEvent.createDocument;
        } else if ( this.state.get( 'IsDemoMode' )) {
            commandEvent = ProjectDiagramCommandEvent.createDiagramLink;
        } else if ( this.planPermManager.check([ PlanPermission.DIAGRAM_CREATE_PRIVATE ])) {
            commandEvent = ProjectDiagramCommandEvent.createDiagramPrivate;
        } else if ( this.planPermManager.check([ PlanPermission.DIAGRAM_CREATE_TEAM ])) {
            commandEvent = ProjectDiagramCommandEvent.createDiagramTeam;
        } else if ( this.planPermManager.check([ PlanPermission.DIAGRAM_CREATE_PUBLIC ])) {
            commandEvent = ProjectDiagramCommandEvent.createDiagramPublic;
        } else {
            this.showUpgradeDialog( PlanPermission.DIAGRAM_CREATE_PUBLIC, UpgradeDialogType.CreateWorkspace );
            return;
        }
        this.logger.debug( 'Creating diagram with id : ' + commandData.id );
        // Reset Selection before creating diagram.
        this.state.set( 'Selected', []);
        this.commandService
            .dispatch( commandEvent, projectId, commandData )
            .subscribe({
                error: ( error: CommandResultError ) => {
                    this.handleDiagramPermissionError( error, projectId );
                },
                complete: () => {
                    this.gotoEditor( commandData, templateId );
                },
            });

        // if no error was thrown so far, we return false to stop the activateGuard;
        return false;
    }

    /**
     * Dispatch an addTemplate command to add multiple shapes into the canvas
     */
    protected addTemplate( templateId: string ) {
        this.state.set( 'DiagramZoomLevel', 1 );
        const vp = this.state.get( 'DiagramViewPort' );
        this.fetchTemplate( templateId ).pipe(
            switchMap(( tpl: TemplateModel ) => this.defLocator.getDefinition( 'creately.basic.frame', 1 ).pipe(
                switchMap(( frameDef: any ) => {
                    // add the libs
                    if ( tpl.libraries ) {
                        this.addLibraries ( tpl.libraries );
                    }

                    if ( Object.keys( tpl.content.shapes || {}).length === 0 ) {
                        return of({});
                    }

                    if ( tpl.name ) {
                        this.commandService.dispatch( DiagramCommandEvent.setTemplateDef, {
                            templateId,
                            templateName: tpl.name,
                        });
                        frameDef.texts.main.content[0].text = tpl.name;
                    }
                    // add the template
                    const padding = 30;
                    const diagramPoint = {
                        x: this.vToDcoordinate.x( vp.centerX ) - 200,
                        y: this.vToDcoordinate.y( vp.centerY ) - 200,
                    };
                    const templatPos = this.restriction.point( diagramPoint, [ 'GridService' ]);
                    const clone = tpl.clone( templatPos );
                    const framePos = this.restriction.point({
                        x: diagramPoint.x - padding,
                        y: diagramPoint.y - padding,
                    }, [ 'GridService' ]);

                    return this.shapeManage.addShape( frameDef,
                        this.dToVcoordinate.x( framePos.x ),
                        this.dToVcoordinate.y( framePos.y ),
                    ).pipe(
                        switchMap( frame => this.commandService.dispatch( DiagramCommandEvent.addDiagramShape, {
                            cloned: true,
                            shapes: clone.shapes,
                            groups: clone.groups,
                            connections: clone.connections,
                            templateData: {
                                templateId,
                                context: 'Dashboard-Button',
                            },
                            frameId: frame.id,
                        })),
                    );
                }),
            )),
        ).subscribe();
    }

    /**
     * Navigates to the route so that the newly created
     * diagram can be edited.
     */
    protected gotoEditor( commandData: any, templateId: string ): void {
        if ( commandData.type === 'duplicate' ) {
            const options = {
                inputs: {
                    heading: this.translate.instant( 'NOTIFICATIONS.DIAGRAM_DUPLICATE.SUCCESS.HEADING' ),
                    description: this.translate.instant( 'NOTIFICATIONS.DIAGRAM_DUPLICATE.SUCCESS.BODY' ),
                    autoDismiss: true,
                    dismissAfter: 3000,
                },
            };

            this.notifierController.show( 'DIAGRAM_DUPLICATE_SUCCESS', AbstractNotification,
                NotificationType.Success, options );

            this.router.navigate([ '../', commandData.id, 'edit' ]);
            this.state.changes( 'DiagramLoaded' ).pipe(
                filter( dId => dId === commandData.id ),
                take( 1 ),
                tap(() => {
                    this.eDataSyncSvc.syncDiagramToEData( commandData.id );
                }),
            ).subscribe();
        } else if ( templateId && templateId !== '__SNAPSHOT__' ) {
            this.router.navigate([ '../', commandData.id, 'edit' ]);
            this.state.changes( 'DiagramLoaded' ).pipe(
                filter( dId => dId === commandData.id ),
                take( 1 ),
                tap(() => {
                    this.addTemplate( templateId );
                }),
            ).subscribe();
        } else {
            if ( templateId === '__SNAPSHOT__' ) {
                this.state.set( 'SelectedLeftPanel', 'folders' );
            } else if ( !this.state.get( 'ShowTemplateSkip' )) {
                this.state.set( 'ToggleTemplatesModal', true );
            }
            this.state.set( 'ShowTemplateSkip', true );
            this.router.navigate([ '../', commandData.id, 'edit' ]);
        }
        // Open FAB on new doc creation
        this.state.set( 'SelectedFloatingPanel', 'shapes' );
    }

    protected fetchTemplate( templateId: string ): Observable<TemplateModel> {
        return new Observable( subscriber => {
            concat(
                this.commandService.dispatch( DiagramCommandEvent.getTemplate, templateId,
                    { templateId: templateId }),
                defer(() => this.datastore.findOneLatest( TemplateModel, { id: templateId })).pipe(
                    tap(( tpl: TemplateModel ) => {
                        subscriber.next( tpl );
                        subscriber.complete();
                    }),
                ),
            ).subscribe();
        });
    }

    /**
     * Adds libraries that are passed in by the template
     * @param newLibs
     */
    protected addLibraries( newLibs: [ string, LibraryState ][]) {
        if ( !newLibs || !newLibs.length ) {
            return;
        }
        const currLibs: ILoadedLibrary[] = _cloneDeep( this.state.get( 'CurrentLibraries' ));
        const orderedLibs = [];
        newLibs.forEach( item => {
            const index = currLibs.findIndex( l => l.id === item[0]);
            if ( index > -1 ) {
                orderedLibs.push( currLibs[index]);
                currLibs.splice( index, 1 );
            } else {
                orderedLibs.push({
                    id: item[0],
                    type: LibraryType.Static,
                    status: 'loading',
                    libGroup: this.libraryList.getMainGroupForLibrary( item[0]),
                    category: this.libraryList.getCategoryForLibrary( item[0]),
                } as ILoadedLibrary );
            }
        });
        currLibs.forEach( item => orderedLibs.push( item ));
        orderedLibs.forEach(( item, i ) => {
            item.order = i;
        });
        this.state.set( 'CurrentLibraries', orderedLibs );
        this.state.set( 'CurrentLibraryGroup', orderedLibs[0].libGroup );
    }

    /**
     * after checking the error code this will throw the error.
     * @param {CommandResultError} error
     */
    protected handleDiagramPermissionError( error: CommandResultError, projectId?: string ) {
        if ( error.code === 1615 ) {
            this.showUpgradeDialog( PlanPermission.DIAGRAM_CREATE, UpgradeDialogType.CreateWorkspace );
        }
        if ( error.code === 1611 ) {
            this.showUpgradeDialog( PlanPermission.DIAGRAM_CREATE_PUBLIC, UpgradeDialogType.CreateWorkspace );
        }
        if ( error.code === 1612 ) {
            this.showUpgradeDialog( PlanPermission.DIAGRAM_CREATE_PRIVATE, UpgradeDialogType.CreateWorkspace );
        }
        if ( error.code === 1623 ) {
            this.showUpgradeDialog( PlanPermission.DIAGRAM_CREATE_TEAM, UpgradeDialogType.CreateWorkspace );
        }
        if ( error.code === 1202 ) {
            this.showFolderPermissionError( projectId );
        }
    }

    /**
     * This handle the foldet permission error
     * @param projectId project id
     */
    private showFolderPermissionError( projectId?: string ) {
        const teamId = AppConfig.get( 'AU_GOV_TEAM_ID' );
        if ( projectId && teamId ) {
            this.projectLocator.getProject( projectId ).pipe(
                take( 1 ),
                map( project => {
                    if ( project.teamId === teamId ) {
                        this.showDiagramCreateFailedNotification( 'NOTIFICATIONS.DIAGRAM_CREATE_ERROR.AU_GOV_TEAM' );
                    } else {
                        this.showDiagramCreateFailedNotification( 'NOTIFICATIONS.DIAGRAM_CREATE_ERROR.COMMON' );
                    }
                }),
            ).subscribe();
        } else {
            this.showDiagramCreateFailedNotification( 'NOTIFICATIONS.DIAGRAM_CREATE_ERROR.COMMON' );
        }
    }

    /**
     * This show the diagram failed notification
     */
    private showDiagramCreateFailedNotification( message: string ) {
        const options = {
            inputs: {
                heading: this.translate.instant( message ),
                autoDismiss: true,
                dismissAfter: 10000,
            },
        };
        this.notifierController.show(
            'CREATE_DIAGRAM_FAILED', AbstractNotification, NotificationType.Error, options, false );
    }

    /**
     * This will show the upgrade dialog for given type
     */
    private showUpgradeDialog(
        dialogId: string,
        dialogType: string,
        buttonAction: Function = () => {},
        actionButtonVisibility: boolean = false,
    ) {
        const dialogData = {
            id: dialogId,
            iframeType: dialogType,
            buttons: [
                {
                    type: 'action',
                    clickHandler: buttonAction,
                    visibility: actionButtonVisibility ? 'enable' : 'disabled',
                },
                {
                    type: 'upgrade',
                    clickHandler: () => {},
                },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        this.modalController.show( UpgradeDialogWindow, {
                inputs: { dialogData: dialogData },
        });
    }

}
