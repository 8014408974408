import { findIndex } from 'lodash';

/**
 * The interface for an item for the ordered list.
 */
export interface IOrderedListItem {
    /**
     * Items added to the ordered list must have an id property
     */
    id: string;
}

/**
 * An Array implementation that maintains a sorted list of items
 * that are added to it.
 * The sorting is done by a particular field which has a numeric value.
 * TODO: Add support to sort by a given sorter function.
 *
 * Items are sorted when they are added.
 * If the value of the 'sort-by' property changed in one of the items in the array,
 * the update method can be used to efficiently reorder the sorted array to reflect
 * the change.
 *
 * @since 2018-06-13
 * @author  Ramishka
 *
 */
export class OrderedList<T extends IOrderedListItem> extends Array<T> {

    constructor( protected orderBy: string ) {
        super()/* istanbul ignore next */;
        Object.setPrototypeOf( this, OrderedList.prototype );
    }

    /**
     * Adds an item to the list.
     * Maintains sort order while adding the item.
     * @param newItem - item to be added.
     */
    public add ( newItem: T ) {
        if ( !newItem ) {
            return;
        }
        const itemIndex = findIndex( this, { id: newItem.id } as any );
        if ( itemIndex !== -1 ) {
            const oldItem = this[itemIndex];
            this[itemIndex] = newItem;
            if ( oldItem[this.orderBy] !== newItem[this.orderBy]) {
                this.update( newItem.id );
            }
        } else {
            const insertAt = this.getIndexToInsertShape( newItem );
            this.splice( insertAt, 0, newItem );
        }
    }

    /**
     * If an item added to the list had a change to the 'order-by'
     * property, this method can be used to reorder the list so that
     * it remains in the sorted order.
     * @param id - id of the item that changed.
     */
    public update ( id: string ) {
        if ( this.length > 1 ) {
            const indexOfShape = findIndex( this, { id } as any );
            const item = this.splice( indexOfShape, 1 )[0];
            const insertAt = this.getIndexToInsertShape( item );
            this.splice( insertAt, 0, item );
        }
    }

    /**
     * Removes an item from the list
     * @param id - id of the item to be removed.
     */
    public remove ( id: string ) {
        if ( this.length > 0 ) {
            const shapeIndex = findIndex( this, { id } as any );
            if ( shapeIndex !== -1 ) {
                this.splice( shapeIndex, 1 );
            }
        }
    }

    /**
     * Returns the index of a particular item in the ordered list
     * @param id - id of the item to get the index of
     * @return index value if found. -1 if not found
     */
    public findIndexOf( id: string ): number {
        return findIndex( this, { id } as any );
    }

    /**
     * A helper method to find the index to insert a shape to a sorted
     * shapes array.
     * @param item - item to be inserted
     */
    private getIndexToInsertShape( item: T ) {
        let low = 0;
        let high = this.length;
        const order = item[this.orderBy];
        while ( low < high ) {
            const mid = Math.floor(( low + high ) / 2 );
            ( this[mid][this.orderBy] < order ) ? low = mid + 1 : high = mid;
        }
        return low;
    }

}
