import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ConnectorModel } from 'apps/nucleus/src/base/shape/model/connector.mdl';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { DiagramChangeService } from 'apps/nucleus/src/base/diagram/diagram-change.svc';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * ChangeDrawStyle
 * Changes the draw style of one or more connectors by changing the entry class.
 */
@Injectable()
@Command()
export class ChangeDrawStyle extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The entryClass is the full class identifier as used in shape definitions.
     */
    public data: {
        changes: {
            [ shapeId: string ]: { entryClass: string, ends: ''},
        },
    };

    constructor( protected ll: DiagramLocatorLocator, ds: DiagramChangeService,
    ) {
        super( ds );
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<any> {
        let changes;
        for ( const shapeId in this.data.changes ) {
            const shapeData = this.data.changes[shapeId];
            changes =  this.updateEnds( shapeId, shapeData.entryClass ).pipe(
                tap(() => {
                    this.changeModel.shapes[shapeId].entryClass = shapeData.entryClass;
                }),
            );
        }
        return changes;
    }

    /**
     * If the connector draw style is changed to connector-indented and the connector has ends,
     * automatically switch the ends to arrow-head-pointer-indented. And if the connector draw
     * style is changed to another class and the arrow heads are indented, switch them to pointer-filled.
     *
     * @param shapeId
     * @param entryClass
     */
    public updateEnds( shapeId: string, entryClass: string ): Observable<any> {
        const locator = this.ll.forCurrent( false );
        return locator.getShapeOnce( shapeId ).pipe(
            tap(( connector: ConnectorModel ) => {
                if ( entryClass === 'connectors.bundle.js#ConnectorIndented' ) {
                    if ( connector.ends.to ) {
                        ( this.changeModel.shapes[shapeId] as ConnectorModel ).ends.to = 'connectors.bundle.js#PointerIndented';
                    }
                    if ( connector.ends.from ) {
                        ( this.changeModel.shapes[shapeId] as ConnectorModel ).ends.from = 'connectors.bundle.js#PointerIndented';
                    }
                } else {
                    if ( connector.ends.to === 'connectors.bundle.js#PointerIndented' ) {
                        ( this.changeModel.shapes[shapeId] as ConnectorModel ).ends.to = 'connectors.bundle.js#PointerFilled';
                    }
                    if ( connector.ends.from === 'connectors.bundle.js#PointerIndented' ) {
                        ( this.changeModel.shapes[shapeId] as ConnectorModel ).ends.from = 'connectors.bundle.js#PointerFilled';
                    }
                }
            }),
        );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ChangeDrawStyle, 'name', {
    value: 'ChangeDrawStyle',
});
