import { Component, Input,
    ChangeDetectionStrategy, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { Shape } from '@creately/createjs-module';
import { StaticRenderer } from 'flux-diagram-composer';
import { Subscription } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { Theme } from '../../../framework/ui/theme';
import { merge } from 'lodash';
import { Rectangle } from 'flux-core';
import { tap, switchMap, delay } from 'rxjs/operators';
import { IStyle, FillStyle } from 'flux-definition';
import { RetinaStageSimplified } from '../../../framework/easeljs/retina-stage-simplified';

/**
 * This component is used to show canvas drawable thumbnails of a shape specifically
 * for Nucleus shapes that implement the IDrawable for thumbnails for Plus create window
 *
 * NOTE: This is component is duplicated, recommend not use this component for other purposes
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'plus-thumbnail-canvas',
    template: `
        <div class="plus-thumb-img-container fx-center-all" mouseenter="" [class.loading]="loading | async">
            <div class="plus-thumb-img-loader-container white">
                <shape-thumbnail-loader></shape-thumbnail-loader>
            </div>
            <div class="plus-thumb-img-canvas-container" [class.preview]="isPreview">
                <canvas #canvasElement id="{{this.canvasId}}" width="{{this.canvasAreaWidth}}" height="{{this.canvasAreaWidth}}"></canvas>
            </div>
        </div>`,
    styleUrls: [ './plus-thumbnail-canvas.scss' ],
})
export class PlusThumbnailCanvas implements AfterViewInit, OnDestroy, OnChanges {

    /**
     * Collapsible menu element.
     */
     @ViewChild( 'canvasElement', { read: ElementRef })
     public canvasElementref: ElementRef;

    /**
     * The definition id of the shape
     */
    @Input() public defId: string;

    /**
     * Instructions to draw the thumbnail on the canvas
     */
    @Input() public drawCode: any;

    /**
     * Instructions to draw the thumbnail on the canvas
     */
    @Input() public typeStyle: any;

    /**
     * The version of the definiton of the shape
     */
    @Input() public version: number;

    /**
     * The width of the drawing area
     *
     * NOTE: Not required to change unless there is a drawing issue
     */
    @Input() public canvasAreaWidth: number = 80;

    /**
     * The width of the canvas
     */
    @Input() public canvasWidth: number = 80;

    /**
     * The style based on which the thumnail must draw
     */
    @Input() public style: IStyle;

    /**
     * To handle hover event
     */
    @Input() public focus: boolean;

    /**
     * Preview is used to define a seperate canvas id for RetinaStage.
     */
    @Input() public isPreview: boolean = false;

    @Input()
    public canvasIdPrefix: string = 'thumb';

    /**
     * Subject that is used to show and hide the loading indicator.
     */
    public loading: BehaviorSubject<boolean>;

    /**
     * Subject that is used to handle hover in, out and pop over preview
     */
    public focusSubject: BehaviorSubject<boolean>;

    protected canvas: RetinaStageSimplified;
    protected thumbnail: Shape;
    protected subs: Subscription[];

    constructor( protected rendered: StaticRenderer ) {
        this.loading = new BehaviorSubject( true );
        this.focusSubject = new BehaviorSubject( false );
        this.subs = [];
    }

    /**
     * Identifier for the canvas element of this thumnail
     * If the same id is used it will draw on same canvas
     */
    public get canvasId(): string {
        return `${this. canvasIdPrefix}-${this.defId}-${this.version}`;
    }

    public ngAfterViewInit() {
        this.canvas = new RetinaStageSimplified( this.canvasElementref.nativeElement );
        this.canvas.setupStage();
        this.canvas.preventSelection = false;
        this.canvas.enableDOMEvents( false );

        this.thumbnail = new Shape();
        this.canvas.addChild( this.thumbnail );
        this.focusSubject.next( this.focus );
        this.subs.push(
            this.focusSubject.pipe( switchMap( hover => this.draw( hover ).pipe(
                // FIXME : 0 Delay is added because without the delay the template is not updating sometimes.
                delay( 0 ),
                tap({ complete: () => {
                    this.loading.next( false );
                }}),
            ))).subscribe(),
        );
    }

    /**
     * When focus or defId being set by the component which is being used, thumbnail has to
     * be re drawn.
     * Change in focus is used to show the hover effect of the library tile.
     * Change in defId is used to update the preview of the library.
     */
    ngOnChanges( changes: SimpleChanges ) {
        if (( changes.focus && !changes.focus.firstChange ) || ( changes.defId && !changes.defId.firstChange )) {
            this.focusSubject.next( this.focus );
        }
    }

    public ngOnDestroy() {
        if ( this.canvas ) {
            this.canvas.destroy();
        }
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Draws the thumbnail based on given hover status. Considers the current style
     */
    protected draw( hover: boolean = false ): Observable<any> {
        const graphics = this.thumbnail.graphics;
        const bounds = new Rectangle( 5 , 5 , this.canvasWidth, this.canvasWidth );
        const styles = this.getStyles();
        if ( hover ) {
            styles.lineColor = Theme.primaryHoverSelectionColor;
        }
        if ( !this.isPreview ) {
            styles.lineThickness = styles.lineThickness * 1.4;
        }
        graphics.clear();
        graphics.beginStroke( styles.lineColor );
        graphics.setStrokeStyle( styles.lineThickness );
        graphics.beginFill( styles.fillColor );
        return this.rendered.renderThumbnail( graphics, this.defId, this.version,
             bounds, this.drawCode, this.typeStyle ).pipe(
            tap({
                complete: () => {
                    graphics.endFill();
                    graphics.endStroke();
                    this.canvas.update();
                },
            }),
        );
    }

    /**
     * Updates the style object attached with missing default values and
     * returns a usable style object
     */
    protected getStyles(): IStyle {
        if ( !this.style ) {
            this.style = <any>{};
        }
        return merge({
            fillStyle: FillStyle.Solid,
            fillColor: Theme.defaultShapeFillColor,
            fillAlpha: 1,
            lineAlpha: 1,
            lineColor: Theme.defaultShapeThumbnailLineColor,
            lineThickness: Theme.defaultShapeLineThickness,
        }, this.style );
    }
}
