import { AbstractCommandList } from '../mapper/command-list';
import { AbstractCommand } from '../abstract.cmd';
import { ICommandSequenceProgress } from '../command-progress.i';

/**
 * Prepare data before it's used by a command in the sequence.
 *
 * @param data    The initial data given to the command sequence
 * @param results Results from previous commands in the sequence
 *
 * @author thanish
 * @since  2017.01.11
 */
export type TransformerFunction = ( data: ICommandSequenceProgress ) => any;

/**
 * This allow to alter the sequence of command. Value returns from
 * this hook wil decide whether to run next command or not.
 *
 * e.g : If we want to run second command before the first one completes and
 * after the particular execution step completed. We have to define a alter hook
 * to the first command as following
 * cmdProgress  => cmdProgress.status && cmdProgress.stepName === 'ExecutionStep'
 * if above function returns true will execute the next command.
 *
 * @param data    command progress
 * @param result return boolean value to decide whether to run next command or not
 */
export type AlterFunction = ( data: ICommandSequenceProgress ) => any;


/**
 * This class provides a list of commands that need to execute
 * one after another and also used to identify that the commands need to be executed
 * sequentially. Extends <code>AbstractCommandList</code>
 *
 * @author  gobiga
 * @since   2016.03.15
 *
 */

export class SequenceCommandList extends AbstractCommandList {
    protected _hooks: Array<ISequenceHooks> = [];

    constructor() {
        super()/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, SequenceCommandList.prototype );
    }

    public get hooks(): Array<ISequenceHooks> {
        return this._hooks;
    }

    /**
     * Add commands to a command list and optionally add a transformer function.
     * Command types and transformer functions are mapped by the array index.
     * example: this[i] => this.transformers[i]
     *
     * @param commandType An ICommand reference which needs to execute when a particular
     *                    command event occurs.
     * @param hooks Set of function which support to execute the command that runs in sequence
     */
    public add( commandType: typeof AbstractCommand, hooks?: ISequenceHooks ): SequenceCommandList {
        super.add( commandType );
        this.hooks.push( hooks );
        return this;
    }

}

/**
 * This interface defines the hooks which support to execute the command that run
 * in sequence.
 * When we are adding a command pass an object by defining this set of hooks which
 * support to run the command sequence.
 */
export interface ISequenceHooks {
    transform?: TransformerFunction;
    alter?: AlterFunction;
    cancel?: any; //
}

