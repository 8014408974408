import { Logger } from 'flux-core';
import { IRectangle, IShapeImage, IShapeText } from 'flux-definition';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { ShapeRenderView } from '../../shape/view/shape-render-view';
import { GraphicsSVG } from '../graphics/svg-graphics';
import { ShapeSVGImageView } from './svg-shape-image-view';
import { ShapeSVGTextView } from './svg-shape-text-view';

export class ShapeSVGView extends ShapeRenderView {
    /**
     * A map of base64 hashes of shape images
     */
    public images: {
        [hash: string]: {
            data: string;
            size: IRectangle,
        },
    } = {};

    /**
     * The temporary storage of transfromation changes applied to this
     * view. These will be used when generatig the output.
     */
    protected _svgTransform: {
        translate?: string;
        scaleX?: string;
        scaleY?: string;
        rotate?: string;
    };
    protected _graphics: GraphicsSVG;
    protected _shadow: GraphicsSVG;

    constructor( protected _model: AbstractShapeModel ) {
        super( _model )/* istanbul ignore next */;
    }

    /**
     * The svg output of the shape view.
     * Idealy, this function should return a SVGGElement so further modifications,appends and usage
     * will be mush easier. It returns a string for the moment due to lack of functionality support
     * on a NodeJS environment. JSDom may be a good candidate to fix this problem on a non-browser
     * env but still it do not have a working implementation to get it working.
     *
     * If need to return a SVGGElement, make sure the functionality can be performed on a non-browser env
     * like NodeJS.
     * @override
     */
    public get renderedShape(): string {
        const transform = this.getTransformString();
        let transformString = '';
        if ( transform ) {
            transformString = `transform="${transform}"`;
        }
        let svgShape = this._shadow.toSvgString() !== '' ?
         `<g ${transformString}>${this._shadow.toSvgString()}</g>` : '';
        svgShape += `<g ${transformString}>${this._graphics.toSvgString()}</g>`;
        this._texts.forEach(( view: ShapeSVGTextView ) => {
            svgShape += view.svgTextElement;
        });
        this._images.forEach(( view: ShapeSVGImageView ) => {
            svgShape += view.renderedImage;
        });
        return `<g id="${this.model.id}">${svgShape}</g>`;
    }

    /**
     * The svg graphics instance associated with this shape.
     * @override
     */
    public get graphics(): GraphicsSVG {
        return this._graphics;
    }


    /**
     * The svg graphics instance associated with this shape shadow.
     * @override
     */
     public get shadow(): GraphicsSVG {
        return this._shadow;
    }

    /**
     * Hook thats called after shape is created and added to the diagram
     * for the first time.
     */
    public initialize() {
        this._graphics = new GraphicsSVG();
        this._shadow = new GraphicsSVG( this._model );
        this._svgTransform = {};
    }

    public render( change?: any ) {
        try {
            super.render( change );
        } catch ( e ) {
            // Explicitly capturing the failing shape definition
            Logger.error( 'D2S: Shape Render failed for', this.model?.id, this.model?.defId, e );
        }
    }

    /**
     * Translates the shape to the given location
     * @param x the position on the canvas to move to
     * @param y the position on the canvas to move to
     */
    protected move( x: number, y: number ) {
        if ( x === 0 && y === 0 ) {
            this._svgTransform.translate = undefined;
        } else {
            this._svgTransform.translate = `${x} ${y}`;
        }
    }

    /**
     * Scales the shape in height.
     * @param scale The scale multiplier
     */
    protected scaleHeight( scale: number ) {
        if ( scale === 1 ) {
            this._svgTransform.scaleY = undefined;
        } else {
            this._svgTransform.scaleY = `${scale}`;
        }
    }

    /**
     * Scales the shape in width.
     * @param scale The scale multiplier
     */
    protected scaleWidth( scale: number ) {
        if ( scale === 1 ) {
            this._svgTransform.scaleX = undefined;
        } else {
            this._svgTransform.scaleX = `${scale}`;
        }
    }

    /**
     * Rotates the shape to the given angle
     * @param angle The angle to rotate to.
     */
    protected rotate( angle: number ) {
        if ( angle === 0 ) {
            this._svgTransform.rotate = undefined;
        } else {
            this._svgTransform.rotate = `${angle}`;
        }
    }

    /**
     * Generaetes a SVG trasnform string based on the
     * local variable _svgTransform values.
     */
    protected getTransformString(): string {
        let tstring = '';
        if ( this._svgTransform.translate ) {
            tstring += `translate(${this._svgTransform.translate}) `;
        }
        if ( this._svgTransform.scaleX || this._svgTransform.scaleY ) {
            const scaleX = this._svgTransform.scaleX || 1;
            const scaleY = this._svgTransform.scaleY || 1;
            tstring += `scale(${scaleX} ${scaleY}) `;
        }
        if ( this._svgTransform.rotate ) {
            tstring += `rotate(${this._svgTransform.rotate})`;
        }
        return tstring.trim();
    }

    /**
     * Create a svg text view for given text model
     */
    protected addText( text: IShapeText ) {
        const textView = new ShapeSVGTextView( text );
        this._texts.push( textView );
    }

    /**
     * Create an svg view for given shape image model.
     */
    protected addImage( imageModel: IShapeImage ) {
        if ( !this.images[imageModel.file]) {
            return;
        }
        const { data, size } = this.images[imageModel.file];
        const imageView = new ShapeSVGImageView( imageModel, data, size );
        this._images.push( imageView );
    }
}
