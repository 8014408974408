import { throwError, Observable } from 'rxjs';
import { clone } from 'lodash';
import { HttpExecutionStep } from './exec-step-http';
import { CommandInterfaces } from '../abstract.cmd';
import { CommandResultError } from '../error/command-result-error';
import { HttpRequest, HttpResponse } from '@angular/common/http';

/**
 * GraphqlRestExecutionStep is an execution step which can be used
 * to fetch resources through creately api graphql. Commands should extend the
 * AbstractHttpCommand to use this execution step.
 */
export class GraphqlRestExecutionStep extends HttpExecutionStep {

    /**
     * relatedInterfaces property returns 'IGraphqlRestCommand' so only commands
     * which have this interface set will run this execution step.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    /**
     * handleError handles failed responses from @angular/common/http or errors
     * send from the neutrino. If response is a CommandResultError then throw an
     * error observable with CommandResultError otherwise letting the HttpExecutionStep
     * to handle the error response.
     * @param res: The response object returned from @angular/common/http
     */
    protected handleError( req: HttpRequest<any>, res: HttpResponse<any> ): Observable<Error> {
        if ( res instanceof CommandResultError ) {
            return throwError( res );
        }
        return super.handleError( req, res );
    }

    /**
     * handleResponse handles successful responses from the graphql api
     * rest call and if it sends an error throw the CommandResultError
     * otherwise letting the HttpExecutionStep to handle the response
     * @param res: The response object returned from @angular/common/http
     * @param options: The options object used with @angular/common/http
     */
    protected handleResponse( res: HttpResponse<any> ): any {
        if ( res.body.data && res.body.data.errors ) {
            const error = clone( res.body.data.errors ).shift();
            throw new CommandResultError( error.extensions.exception.code, error.message );
        }
        return super.handleResponse( res );
    }

    /**
     * Populate the request parameter values
     */
    protected populateRequestParam(): any {
      const query: any = clone( this.command.data.query );
      const data: any = { query };
      return data;
    }
}

Object.defineProperty( GraphqlRestExecutionStep, 'name', {
    value: 'GraphqlRestExecutionStep',
});
