import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { of, merge, combineLatest, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take, switchMap, tap } from 'rxjs/operators';
import { StateService } from 'flux-core';
import { IDataSource, IDataSourceModel } from './model/data-source.model';
import { cloneDeep, isEqual } from 'lodash';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';


@Component({
    template: `
        <div class="something">
            <div tooltip placement="right" class="search-result-menu">
                <div class="line">
                    <div class="moveble-image">
                        <svg class="nu-icon nu-ic-movable">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-movable"></use>
                        </svg>
                    </div>
                    <div class="medium-menu-text no-left-margin">
                        {{label}}
                    </div>
                </div>
                <div class="line">
                    <svg (click)="onCancel()" class="nu-icon nu-ic-close">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                    </svg>
                </div>
            </div>
        </div>
    `,
    selector: 'data-source-card-item',
    styleUrls: [ './data-source-card-item.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceCardListItem implements OnInit, OnDestroy {
   /**
    * data source model data getting from the source.
    */
   @Input()
   public data: IDataSourceModel[];

   @Input()
   public close: Subject<boolean>;

   @Input()
   public event: BehaviorSubject<any>;

   /**
    * Identify when the entire filtered set is being dragged
    */
   @Input()
   public dragging: BehaviorSubject<boolean>;

   /**
    * The definition id of the datasource EDATA model.
    */
   @Input()
   protected eDefId: string;

   @Input()
   protected dataSource: IDataSource;

   @Input()
   protected definedSearchQuery: string;
   /**
    * The definition id of the datasource SHAPE.
    */
   @Input()
   protected defId: string;

   /**
    * version of the SHAPE definition.
    */
   @Input()
   protected version: number;

   /**
    * Holds the currentLibraries state subscription.
    */
   protected subs: Subscription[];

   constructor(
       protected defLocator: DefinitionLocator,
       private el: EDataLocatorLocator,
       protected ll: DiagramLocatorLocator,
       protected state: StateService<any, any>,
       protected translate: TranslateService,
   ) {
       this.subs = [];
   }

   public get label () {
    return this.translate.instant( 'DATA_SOURCE.GITHUB.FILTERED_RESULTS', {
        count: this.data.length,
    });
   }

   /*
   * Handle the drag start event on the library tile
   * Hide popover preview if it is open
   * @param event
   * @param data
   */
    public handleDragStart( ) {
        this.state.set( 'ImageOverlay', 'entity-group-import' );
        const sub1 = this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramEData().pipe(
                switchMap( eDataList => {
                    if ( !eDataList ) {
                        return of([]);
                    }
                    const eSubs = [];
                    eDataList.forEach( eId => {
                        const eSub = this.el.getEData( eId ).pipe(
                            switchMap( eLocator => eLocator.getEDataModelOnce()));
                        eSubs.push( eSub );
                    });
                    return merge( ...eSubs );
                }),
            )),
        ).subscribe();

        const sub2 = combineLatest(
            this.el.currentEDataModelsOnce(),
            this.defLocator.getDefinition( this.defId, this.version ),
            ).pipe(
            take( 1 ),
            tap(([ eDataModels, shapeDef ]) => {

                const gitEdata = ( eDataModels || []).find( ed => ed.defId === this.eDefId );
                const mappedEntities = gitEdata && gitEdata.entities ? Object.keys( gitEdata.entities )
                    .map( key => gitEdata.entities[ key ]) : null;

                const shapes = {};

                let entityListModel;
                if ( gitEdata && gitEdata.entityLists ) {
                    entityListModel = Object.values( gitEdata.entityLists )
                    .find( el => el.search === this.definedSearchQuery );
                }
                this.data.forEach( item => {
                        const dataSource = this.getDataSource( item.dataSourceId );
                        let entity;
                        if ( gitEdata && gitEdata.entities && mappedEntities ) {
                            entity = mappedEntities.find( e => isEqual( e.dataSource, dataSource ));
                        }
                        const shapeData: ShapeModel =  cloneDeep( shapeDef );
                        shapeData.name = item.title;
                        shapeData.triggerNewEData = true;
                        shapeData.data = {};
                        Object.assign( shapeData.data, shapeData.dataDef );
                        shapeData.data.number.value = item.dataSourceId.toString();
                        shapeData.data.title.value = item.title.toString();
                        shapeData.data.description.value = item.description;
                        shapeData.data.tags.value = item.tags.filter( tag => tag.name !== null );
                        shapeData.data.people.value = {
                            source: item.people.source,
                            people: item.people.people.filter ( p => p.fullName !== null && p.id !== null )},
                        shapeData.data.commentCount.value = 0;
                        ( shapeData as any ).dataSource = dataSource;
                        shapeData.entityListId = entityListModel?.id;
                        if ( gitEdata ) {
                            shapeData.eData = {
                                [gitEdata.id]: entity?.id || null,
                            };
                        }
                        shapes[ item.dataSourceId ] = shapeData;
                });
                this.event.value.dataTransfer.effectAllowed = 'move';
                this.event.value.dataTransfer.setData( 'PreDifQueries',
                    JSON.stringify({
                        eDefId: this.eDefId,
                        definedSearchQuery: this.definedSearchQuery,
                        shapes: shapes,
                    }));
            }),
        ).subscribe();
        this.subs.push( sub1 );
        this.subs.push( sub2 );
    }

    public handleDragEnd(): void {
        this.state.set( 'ImageOverlay', '' );
    }

    public ngOnInit(): void {
        this.subs.push(
            this.dragging.pipe(
                tap( dragging => {
                    if ( this.event.value ) {
                        if ( dragging ) {
                            this.handleDragStart();
                        } else {
                            this.handleDragEnd();
                        }
                    }
                }),
            ).subscribe(),
        );
    }


    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        this.subs.forEach( sub => {
            sub.unsubscribe();
        });
        this.subs = [];
    }

    public onCancel(): void {
        this.close.next( true );
    }

    protected getDataSource( dataSourceId ): IDataSource {
        const dataSource = cloneDeep( this.dataSource );
        dataSource.data.dataSourceId = dataSourceId ;
        return dataSource;
    }

}
