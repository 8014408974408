import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces, Command, CommandResultError } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * This is the command to get edit request to diagram.
 * This command will send edit request to the locla storage and send
 * the request to the server.
 *
 * This extends {@link AbstractMessageCommand} to send a request to the server.
 * data: {
 *     collabs - [{ email, role }]
 *     diagramId - id of the resource
 * }
 */
@Injectable()
@Command()
export class RequestEditDiagram extends AbstractMessageCommand  {


    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    public execute() {
        return true;
    }

    /**
     * Checks if a permission error occured and throws a permission error.
     */
    public onError( err: CommandResultError ) {
        if ( err.code ) {
            throw err;
        }
    }

}

Object.defineProperty( RequestEditDiagram, 'name', {
    value: 'RequestEditDiagram',
});
