import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Pipe to bypass built-in Angular security when using unsafe urls.
 * ie: iframe src
 * You can use this on a angular template as a regular pipe.
 * ie: <iframe [src]="url | safe">
 */
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    public constructor( private sanitizer: DomSanitizer ) {}

    /**
     * Return a marker to by pass angular security sandbox for the given url.
     * @param url url string
     */
    public transform( url: string ): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl( url );
    }

}

/**
 * Pipe to bypass built-in Angular security when setting innerHTML.
 * You can use this on a angular template as a regular pipe.
 * ie: <div [innerHTML]="value | safeHTML">
 */
@Pipe({ name: 'safeHTML' })
export class SafeHTMLPipe implements PipeTransform {
    public constructor( private sanitizer: DomSanitizer ) {}

    /**
     * Return a marker to by pass angular security sandbox for the given html value.
     * @param url url string
     */
    public transform( val: string ): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustHtml( val );
    }
}

/**
 * Sanitize potentially malicious HTML code when setting innerHTML.
 * You can use this on a angular template as a regular pipe.
 * ie: <div [innerHTML]="value | sanitizeHtml">
 */
@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHTMLPipe implements PipeTransform {
    public constructor( private sanitizer: DomSanitizer ) {}

    /**
     * Return escape potential XSS vunerable string/code
     * @param url url string
     */
    public transform( val: string ): string {
        return this.sanitizer.sanitize( SecurityContext.HTML, val );
    }
}
