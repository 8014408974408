import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Observable } from 'rxjs';
import { merge } from 'lodash';
import { ShapeDataModel } from './shape-data.mdl';
import { AbstractShapeModel } from './abstract-shape.mdl';
import { AbstractModelFactory } from '../../framework/factory/abstract-model-factory';
import { Rectangle, ClassUtils, Random, Logger } from 'flux-core';
import { map, switchMap } from 'rxjs/operators';
import { DEFUALT_TEXT_STYLES, IAbstractDefinition, IText, ITextFormat, TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { TextFormatter } from '../../framework/text/text-formatter';
import { ShapeTextDataModel } from './shape-text-data.mdl';

/**
 * This is the stateless factory for creating all types of Shape Models. Any model
 * created must be of the type ShapeDataModel or anything that extends that.
 *
 * This creates the models and merges the definitions into the model. This manages the
 * definition creation and conversion to accessible types. This does not manage shape
 * model data. Does not have knowledge of ths Shape Model composition but only of the
 * IShapeDefinition.
 *
 * @author hiraash
 * @since 2017-09-02
 */
export class ShapeModelFactory extends AbstractModelFactory {
    /**
     * The singleton instance of the ShapeModelFactory.
     */
    public static get instance(): ShapeModelFactory {
        if ( !this._instance ) {
            this._instance = new ShapeModelFactory();
        }
        return this._instance;
    }

    protected static _instance: ShapeModelFactory;
    protected textFormatter: TextFormatter = new TextFormatter();
    protected editableIdPrefix: string = 'e_';

    /**
     * Factory function to create a shape from a definition id. Fetches the
     * definiton as per id and version and creates the Model as per the definition.
     * For certain shape types, a logic class is used to make decisions when updating
     * the shape model. If a shape has a logic class associated with it, its merged
     * to the model during creation.
     * @param defId The definition id for the def to use
     * @param version The version of the definition to use
     * @param type The type of the ShapeModel expected which is or extends ShapeDataModel
     * @param id The unique shape id for the shape. If not given, will be generated.
     */
    public createByDef(
        defId: string,
        version: number,
        type: typeof AbstractShapeModel = ShapeDataModel,
        id?: string,
        eData?: any,
    ): Observable<AbstractShapeModel> {
        return this.defLocator.getDefinition( defId, version ).pipe(
            switchMap(( def: any ) => this.defLocator.getClass( def.logicClass ).pipe(
                map( logicClass => ({ def, logicClass })),
            )),
            map(({ def, logicClass }) => {
                // Create default glue points for shapes that have not defined them
                const defWithGP = this.createDefaultGluePoints( def );
                this.setDefaultStickyGluepoints( defWithGP );
                const model = this.createModel( id, defWithGP, type, logicClass );
                this.displaceDefaultStickyGluepoints( model );
                // TODO: Further conversion of def properties need to happen as they are available ^HT
                // FIXME: This needs to only call for shape models and not connector model. instanceOf
                // check added until shape model factory is abstracted in a seperate PR
                if ( model instanceof ShapeDataModel ) {
                    this.convertDefaultBounds( <ShapeDataModel>model );
                    model.userSetHeight = model.defaultBounds.height;
                    model.userSetWidth = model.defaultBounds.width;

                    // addEdata
                    if ( eData ) {
                        model.eData = eData;
                    }
                }
                this.convertText( <ShapeDataModel>model );
                this.setDataItemsId( model );
                this.setDescriptionDataItem( model );
                return model;
            }),
        );
    }

    /**
     * Factory function to create a shape from a saved shape data. Fetches the
     * definiton as per id and version in the object, and creates the Model as per the definition.
     *
     * Important: This method only creates the Shape Model and does not merge the values from the
     * given data object into the model. It simply uses following fiels from the data.
     *      - defId
     *      - version
     *      - id
     *
     * @param data The shape data that was stored. Expected to have defId and version props
     * @param type The type of the ShapeModel expected which is or extends AbstractShapeModel
     */
    public createByData(
        data: any, type: typeof AbstractShapeModel = ShapeDataModel,
    ): Observable<AbstractShapeModel> {
        this.fixBrokenTexts( data.texts );
        this.setPrimaryTextContent( data );
        this.setInitialNotes( data );
        return this.createByDef( data.defId, data.version, type, data.id, data.eData );
    }

    /**
     * Adds a gluePoint array with basic gluepoint details if it
     * has not been already defiend. If glue points are not supposed
     * to be there simply set the gluepoints prop in the def to a empty array.
     * @param def The raw definition json.
     */
    protected createDefaultGluePoints( def: any ) {
        if ( def && this.isShapeTypeSupportGluePoints( def.type )) {
            if ( !def.gluepoints ) {
                // NOTE: do not modify the def
                return this.setDefaultGluePoints( def );
            }
        }
        return def;
    }

    protected setDataItemsId( model: AbstractShapeModel ) {
        if ( model.data ) {
            for ( const key in model.data ) {
                if ( model.data[ key ] && typeof model.data[ key ] === 'object' ) {
                    model.data[ key ].id = key;
                }
            }
        }
    }

    /**
     * This will contain gluepoint support shapes types.base on the
     * shape type it will return whether it support or not.
     * @param type shape type
     * @returns return wether the shape type supports glue points.
     */
    protected isShapeTypeSupportGluePoints( type: string ) {
        const supportedTypes = [ 'basic', 'dynamic', 'freehand', 'edata' ];
        return supportedTypes.includes( type );
    }

    protected setDescriptionDataItem( model: AbstractShapeModel ) {
        if ( model.data && !model.data.description ) {
            model.data.description = {
                id: 'description',
                def: 'descriptionRichtext',
                label: 'Description',
                value: '<p></p>',
                optional: true,
                visibility: [
                     { type: 'editor' },
                ] as any,
            } as any;
        } else if ( model.data.description && model.data.description.value && model.data.description.value.ops ) {
            const converter = new QuillDeltaToHtmlConverter( model.data.description.value.ops );
            const html =  converter.convert();
            model.data.description.value = html;
        }
    }

    /**
     * Creates an actual rectangle for the shape bounds from defined
     * width and height
     * @param model Shape Model
     */
    protected convertDefaultBounds( model: ShapeDataModel ) {
        model.defaultBounds = new Rectangle( 0, 0, model.defaultBounds.width, model.defaultBounds.height );
    }

    /**
     * Set the key of as the id property of each IText object in texts map
     * @param model Shape Model
     */
    protected convertText( model: AbstractShapeModel ) {
        const texts = model.texts;
        if ( !texts ) {
            return;
        }
        let primaryFound = false;
        Object.keys( texts )
            .forEach( k => {
                model.texts[ k ].id = k;
                if ( !primaryFound && model.texts[ k ].primary === true ) {
                    primaryFound = true;
                    model.texts[ k ].editable = true;
                } else {
                    model.texts[ k ].primary = false;
                }
        });
    }

    /**
     * Function to add editable text models for texts that have an editable data item
     * and the displayed text is different to editable text
     * TODO: Not currently called, as edata models are not in prod yet.
     * @param model
     * @deprecated
     */
    protected addEditableTexts( model: AbstractShapeModel ) {
        const texts = model.texts;
        if ( !texts ) {
            return;
        }
        Object.keys( texts )
            .forEach( k => {
                if ( model.texts[ k ].editableDataItem && !model.texts[ k ].editableTextId ) {
                    const displayTextModel = model.texts[ k ];
                    const text = this.addTextModel( model.texts[ k ],
                        ( model as ShapeDataModel ).data[ model.texts[ k ].editableDataItem ].value );
                    const newId = this.editableIdPrefix + model.texts[ k ].id;
                    text.id = newId;
                    // Set editable text id for switching
                    text.displayableTextId = displayTextModel.id;
                    model.texts[ k ].editableTextId = newId;

                    model.texts[ newId ] = text;
                }
        });
    }


    /**
     * Function to create duplicate text model with new value
     * @param text
     * @param newTextValue
     */
    protected addTextModel( text: IText, newTextValue: string ) {
        const newText = new ShapeTextDataModel();
        Object.keys( text ).forEach( key => {
            newText[ key ] = text[ key ];
        });
        const styles = this.textFormatter.extractCommon( text.value );
        const format: ITextFormat = { indexStart: 0, indexEnd: null, styles: styles };

        const value = this.textFormatter.apply( newTextValue, format );
        newText.value = value;
        const content = this.textFormatter.applyRaw( newTextValue, format );
        newText.content = content;

        return newText;

    }

    private fixBrokenTexts( texts: any ) {
        if ( !texts ) {
            return;
        }
        Object.keys( texts )
            .forEach( k => {
                // FIXME: This is a temporary fix to ignore broken texts. i.e
                // Some connectors were found in templates were
                // text.content: { 0: {backgroundColor: "#1a1a1a", color: "#FFFFFF"} }
                // Investigate how these texts are being created and fix the issue.
                if ( !Array.isArray( texts[ k ].content )) {
                    Logger.error( `Found a broken text. Id: ${ k }` );
                    delete texts[ k ];
                } else if ( texts[k].id && texts[k].id !== k ) {
                    // FIXME: This is a temporary fix to correct the text model id and text key mismatch
                    // Investigate how this happened and fix the issue.
                    Logger.error( `Text key: ${k} mismatches the text id: ${ k }` );
                    texts[k].id = k;
                }
        });
    }


    /**
     * Creates a shape model instance based on a given definition and logic class.
     * @param id - model id. If this is not provided it will be generated.
     * @param def - definition
     * @param type - shape type
     */
    private createModel(
        id: string,
        def: IAbstractDefinition,
        type: typeof AbstractShapeModel,
        logicClass: any,
    ): AbstractShapeModel {
        id = !id ? Random.shapeId() : id;
        const model: AbstractShapeModel = new type( id );
        if ( logicClass ) {
            const logic = new logicClass();
            ClassUtils.mergeInstances( model, def, logic );
        } else {
            merge( model, def );
        }
        return model;
    }

    private setDefaultGluePoints( def: any ) {
        const object = Object.assign({}, def, {
            gluepoints: {
                // WARNING!! do not change these ids!!
                'pbnadF9Wirq': { id: 'pbnadF9Wirq', x: 0, y: 0.5 },
                'P9d4172UDLb': { id: 'P9d4172UDLb', x: 1, y: 0.5 },
                '0FUuJlNPewl': { id: '0FUuJlNPewl', x: 0.5, y: 0 },
                'BRZfYLOPiRa': { id: 'BRZfYLOPiRa', x: 0.5, y: 1 },
            },
        });
        this.displaceDefaultGluepoints( object );
        return object;
    }

    /**
     * Updates the Gluepoint position according to the the text position
     * e.g. if the text is placed outside the shape the glue point should be displaced
     */
    private displaceDefaultGluepoints( object: any ) {
        if ( object && object.texts ) {
            for ( const textId in object.texts ) {
                const text = object.texts[ textId ];
                if ( text && text.content && text.content.length === 1 && !text.content[0].text.trim()) {
                    break;
                }
                if ( text && text.y < 0 && text.yType === 'fixed-end' ) { // bottom outside
                    const gp = object.gluepoints.BRZfYLOPiRa;
                    gp.yType = text.yType;
                    gp.y = ( text.height + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                } else if ( text && text.y < 0 && text.yType === 'fixed-start' ) { // top outside
                    const gp = object.gluepoints[ '0FUuJlNPewl' ];
                    gp.yType = text.yType;
                    gp.y = ( text.height + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                }
                if ( text && text.x < 0 && text.xType === 'fixed-start' ) { // left outside
                    const gp = object.gluepoints.pbnadF9Wirq;
                    gp.xType = text.xType;
                    gp.x = ( text.width + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                } else if ( text && text.x < 0 && text.xType === 'fixed-end' ) { // right outside
                    const gp = object.gluepoints.P9d4172UDLb;
                    gp.xType = text.xType;
                    gp.x = ( text.width + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                }
            }
        }
        return object;
    }
    private displaceDefaultStickyGluepoints( object ) {
        if ( object && object.texts ) {
            for ( const textId in object.texts ) {
                const text = object.texts[ textId ];
                if ( text && text.content && text.content.length === 1 && !text.content[0].text.trim()) {
                    break;
                }
                if ( text && text.y < 0 && text.yType === 'fixed-end' ) { // bottom outside
                    const stickyGluePoints = object.stickyGluePoints.BRZfYLOPiRa;
                    if ( stickyGluePoints ) {
                        stickyGluePoints.map( gp => {
                            gp.yType = text.yType;
                            gp.y = ( text.height + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                        });
                    }
                } else if ( text && text.y < 0 && text.yType === 'fixed-start' ) { // top outside
                    const stickyGluePoints = object.stickyGluePoints[ '0FUuJlNPewl' ];
                    if ( stickyGluePoints ) {
                        stickyGluePoints.map( gp => {
                            gp.yType = text.yType;
                            gp.y = ( text.height + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                        });
                    }
                }
                if ( text && text.x < 0 && text.xType === 'fixed-start' ) { // left outside
                    const stickyGluePoints = object.stickyGluePoints.pbnadF9Wirq;
                    if ( stickyGluePoints ) {
                        stickyGluePoints.map( gp => {
                            gp.xType = text.xType;
                            gp.x = ( text.width + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                        });
                    }
                } else if ( text && text.x < 0 && text.xType === 'fixed-end' ) { // right outside
                    const stickyGluePoints = object.stickyGluePoints.P9d4172UDLb;
                    if ( stickyGluePoints ) {
                        stickyGluePoints.map( gp => {
                            gp.xType = text.xType;
                            gp.x = ( text.width + TEXT_PADDING_HORIZONTAL * 2 )  * -1;
                        });
                    }
                }
            }
        }
        return object;
    }

    private setDefaultStickyGluepoints( def: any ) {
        // Note: by setting stickyGluePoints empty {} in the def, sticky behaviour can be disabled
        // for particular shape that has default GPS
        if ( def.stickyGluePoints || !def.gluepoints ) {
            return;
        }
        def.stickyGluePoints = {};
        if ( def.gluepoints[ '0FUuJlNPewl' ]) {
            def.stickyGluePoints[ '0FUuJlNPewl' ] = [
                { x: 0, y: 0 },
                { x: 1, y: 0 },
            ];
        }
        if ( def.gluepoints.BRZfYLOPiRa ) {
            def.stickyGluePoints.BRZfYLOPiRa = [
                { x: 0, y: 1 },
                { x: 1, y: 1 },
            ];
        }
        if ( def.gluepoints.pbnadF9Wirq  ) {
            def.stickyGluePoints.pbnadF9Wirq = [
                { x: 0, y: 0 },
                { x: 0, y: 1 },
            ];
        }
        if ( def.gluepoints.P9d4172UDLb ) {
            def.stickyGluePoints.P9d4172UDLb = [
                { x: 1, y: 0 },
                { x: 1, y: 1 },
            ];
        }
    }

    private setPrimaryTextContent( shape: any ) {
        if ( shape.initialPrimaryTextContent ) {
            let primaryTextModel: any = Object.values( shape.texts || {})
                .find(( t: any ) => t.primary );
            if ( !primaryTextModel ) {
                primaryTextModel =  Object.values( shape.texts || {})[0];
            }
            if ( !primaryTextModel ) {
                primaryTextModel = {
                    id: 'primary_text',
                    _alignX: 0,
                    content: [
                        {
                            ...DEFUALT_TEXT_STYLES,
                          },
                    ],
                    handlebars: {},
                    height: 10,
                    rendering: 'carota',
                    width: 10,
                    minWidth: 10,
                    primary: true,
                } as any;
                shape.texts = {
                    primary_text: primaryTextModel,
                };
            }
            if ( primaryTextModel.content ) {
                primaryTextModel.content = [
                    {
                        ...primaryTextModel.content[0],
                        ...shape.initialPrimaryTextContent,
                    },
                ];
                primaryTextModel.value = shape.initialPrimaryTextContent.text;
            }

            if ( shape.data?.[ primaryTextModel.id ]?.value ) {
                shape.data[ primaryTextModel.id ].value = shape.initialPrimaryTextContent.text;
            }
        }
    }

    private setInitialNotes( shape: any ) {
        if ( shape.initialNotes ) {
            const descriptionDataItem: any = ( shape.data || {}).description;
            if ( descriptionDataItem ) {
                descriptionDataItem.value = shape.initialNotes;
            } else {
                shape.data = {
                    description: {
                        value: shape.initialNotes,
                    },
                };
            }
        }
    }

}
