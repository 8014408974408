import { Injectable } from '@angular/core';
import { Proxied } from '@creately/sakota';
import { AbstractCommand, Command, CommandError, CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';

/**
 * AbstractDiagramChangeCommand is the base abstract class which can be extended
 * to create diagram change commands. Changes should be done only inside the prepare
 * data hook to the change model. Example:
 *
 *      class RenameDiagram extends AbstractDiagramChangeCommand {
 *          public prepareData() {
 *              this.changeModel.name = this.data.name;
 *          }
 *      }
 *
 */
@Injectable()
@Command()
export abstract class AbstractDiagramChangeCommand extends AbstractCommand {
    /**
     * This command implements the 'IDiagramCommand' interface. This will trigger
     * the SetDiagramIdExecutionStep. Therefore, the diagram id is not required
     * when calling these commands.
     *
     *      Example:
     *      commandService.dispatch( event, data );
     *
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IModelChangeCommand', 'IDiagramCommand' ];
    }

    /**
     * changeModel
     * changeModel is a proxied model object which can record changes done to it.
     */
    public changeModel: Proxied<DiagramModel>;

    /**
     * Constructor injects services required by the command.
     * @param locator The diagram locator
     */
    constructor( protected ds: DiagramChangeService ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Returns an observable which emits the proxied diagram model once.
     */
    public prepareProxy(): Observable<Proxied<DiagramModel>> {
        return this.ds.getChangeModel( this.resourceId, this.eventData.eventId, this.eventData.scenario );
    }

    /**
     * execute
     * execute returns true if and only if the modifier has any changes.
     */
    public execute(): boolean | Observable<boolean> {
        return true;
    }

    /**
     * onError
     * onError checks whether the error is a network related error and ignores them.
     */
    public onError( err: CommandError ) {
        if ( err.name !== 'ConnectionError' ) {
            throw err;
        }
    }
}

Object.defineProperty( AbstractDiagramChangeCommand, 'name', {
    value: 'AbstractDiagramChangeCommand',
});
