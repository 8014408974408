import AbstractStereoTypeParser from './abstract-stereotype-text-parser';
class UMLClassStereoTypeSectionParser extends AbstractStereoTypeParser {
    static id = 'creately.umlclass.stereotype';

    public static get instance(): UMLClassStereoTypeSectionParser {
        return this._instance;
    }

    private static _instance = new UMLClassStereoTypeSectionParser();

    protected getStaticSuggestions() {
        return [
            'interface',
            'enumeration',
            'dataType',
            'primitive',
            'utility',
        ];
    }

    protected getDynamicSuggestions( searchTerm: string, context: any ) {
        const suggestions =  [];
        if ( context.projectEDataModels ) {
            const entities: any = Object.values( context.projectEDataModels )
                .reduce(( list: any[], model: any ) => list.concat( Object.values( model.entities )), []);
            entities.filter( e => e.eDefId === 'creately.entity.uml.class' && !!e.data && e.data.stereotypes )
                .map( e => Object.values( e.data.stereotypes ))
                .forEach( stereotypes => {
                    stereotypes.forEach( stereotype => {
                        suggestions.push( stereotype.value );
                    });
                });
        }
        return suggestions;
    }
}

export default UMLClassStereoTypeSectionParser;
