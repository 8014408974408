import { Injectable } from '@angular/core';
import { ShapeHistoryService } from 'apps/nucleus/src/base/diagram/shape-history.svc';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';

/**
 * Fetch entity history and add it to "entity-change" state.
 * Data
 * {
 *      eDataId: "abcd",
 *      entityId: "efgh",
 *      offset: 0,          // Optional
 *      limit: -1           // Optional
 * }
 */
@Injectable()
@Command()
export class GetEntityHistory extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Input data format for the command
     */
    public data:  {
        entityId: string,
    };

    constructor(
        protected shapeHistoryService: ShapeHistoryService,
    ) {
        super();
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ): boolean | Observable<any> {
        if ( response ) {
            // TODO: Fix this when pagination implemented.
            // Append changes to existing array only if the change is not exist in the array.
            return this.shapeHistoryService.cacheShapeChanges( this.resourceId, this.data.entityId, response as any[]);

        }
        return true;
    }

    // protected getStateKey( eDataId, entityId ): string {
    //     return `${ShapeHistoryService.ENTITY_HISTORY}-${eDataId}-${entityId}`;
    // }
}

Object.defineProperty( GetEntityHistory, 'name', {
    value: 'GetEntityHistory',
});
