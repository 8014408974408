import { Observable, Observer } from 'rxjs';
import { AbstractCommand, CommandInterfaces } from '../abstract.cmd';
import { ExecutionStep } from './execution-step';
import { Logger } from '../../logger/logger.svc';
import { CommandError } from '../error/command-error';
import { CommandStepMapper } from '../mapper/command-step-mapper.svc';
import { CommandScenario } from '../command-scenario.enum';
import { async } from '../../async';
import { Injector } from '@angular/core';
import { ICommandProgress } from '../command-progress.i';
import { CommandCancelError } from '../error/command-cancel-error';
import { ICommandEventData } from '../command-event';
// import { OTelManager } from '../../logger/otel-manager';

/**
 * This is the class where the executon of command will take place.
 * This is a wrapper that understands all types of commands and handles the
 * full execution of a single command. This will mostly be used by the
 * {@link CommandService} which will create an instance every time a
 * command has to be executed. This class always returns a {@link Observable}
 * which can be used to handle the command result/error.
 *
 * This class will be extended to create CommandExecutor classes that handle
 * different {@link ExecutionStep} sequence.
 *
 * @author  Gobiga, hiraash
 * @since   2016-03-24
 */

export class CommandExecutor {

    /**
     * The create method for the CommandExecutor
     * @param command - the command type to be instantiated
     * @param eventData - the command event data to be set to the command instance
     * @param commandScenario - the scenario under which the executor will execute the command
     * @param log - logger
     * @param injector
     * @return command executor instance
     */
    public static create( command: typeof AbstractCommand,
                          eventData: ICommandEventData,
                          commandScenario: CommandScenario,
                          log: Logger,
                          injector: Injector ): CommandExecutor {
        return new CommandExecutor( command, eventData, commandScenario, log, injector );
    }

    /**
     * This holds the current scenario the command/command sequence will be executed in.
     * This can be any one of the sceanrios specified in {@link CommandScenario} enum.
     */
    protected commandScenario: CommandScenario;

    /**
     * This is where the ExecutionStep instances that are prepared for
     * execution will be stored in the order that they have to be executed.
     * See prepareSequence method for more details.
     */
    protected sequence: Array<ExecutionStep>;

    /**
     * This is the Observer attached to the Observable returned by this
     * CommandExecutor, when the executeCommand method is called. This will
     * and has to be written whenever we want to emit to the command caller.
     */
    protected observer: Observer<ICommandProgress>;

    /**
     * ...
     */
    protected moduleInjector: Injector;

    /**
     * The commandType and commandInstance are the Command class and instance
     * of the command class this executor will be handling/executing.
     */

    protected commandType: typeof AbstractCommand;
    protected commandInstance: AbstractCommand;

    /**************************************************
     *
     *  Preparing the ExecutionSteps for this command
     *
     **************************************************/

    /**
     * When creating an CommandExecutor, the command type that must be
     * executed has to be passed in. This is done by the {@link CommandService}
     * as part of the dispatch functionality.
     * The scenario the command would be used in (i.e. EXECUTE, UNDO ) must also
     * be passed in.
     */
    constructor(
        command: typeof AbstractCommand,
        eventData: ICommandEventData,
        commandScenario: CommandScenario,
        protected log: Logger,
        injector: Injector ) {

        this.commandType = command;
        this.moduleInjector = Injector.create( this.getProviders(), injector );
        this.commandInstance = this.moduleInjector.get( this.commandType );
        this.commandInstance.logger = this.log;
        this.commandInstance.eventData = eventData;
        this.commandScenario = commandScenario;
        this.prepareSequence();
    }

    /********************************************************
     *
     *  Executing the command with prepared ExecutionSteps
     *
     ********************************************************/

    /**
     * This is the public method for starting the execution sequence
     * for this CommandExecutor. The data must be passed as expected
     * by the command in question. The method, immediately responds with a
     * Observable instance which can be used to track all emits/changes of the
     * command execution. The actual execution occurs on a new function stack.
     * In other words execution sequence want run as par of this method.
     */
    public executeCommand( resourceId?: string, data?: any, resultData?: any ): Observable<any> {
        this.commandInstance.resourceId = resourceId;
        this.commandInstance.data = data;
        this.commandInstance.resultData = resultData;

        return Observable.create(( observer: Observer<any> ) => {
            this.observer = observer;
            async(() => this.executeSequence());
        });
    }

    /**
     * This is one of the most important functions of the CommandExecutor.
     * This prepares the ExecutionStep classes that are relavant to this CommandExecutor.
     * During this preparation, the <code>sequence</code> property will be filled with the
     * actual steps that are ready for execution in the correct order.
     *
     * Each command executor will be created for a specific command scenario. This method
     * would obtain the step sequence for the current command scenario from the
     * {@link CommandStepMapper} and prepare each step in the sequence.
     *
     */
    protected prepareSequence() {
        if ( this.sequence === undefined ) {
            this.sequence = [];

            const steps: Array<typeof ExecutionStep> =
                ( this.moduleInjector.get( CommandStepMapper )).getSequence( this.commandScenario );
            if ( steps && steps.length > 0 ) {
                steps.forEach( step => this.prepareStep( step ));
            } else {
                throw new CommandError( 'There were no execution steps defined for the current command scenario.' );
            }
        }
    }

    /**
     * This method prepares a specific ExecutionStep by adding it to the sequence.
     * Each {@link ExecutionStep} decides if the class is supposed to be in the sequence
     * and where it is supposed to be. It may even manipulate other ExecutionStep classes.
     * However before we call the <code>prepare</code> method on the ExecutionStep we also
     * ensure that a interface requirement is met by the command. If the ExecutionStep
     * does not relate to any interfaces, that means it is necessary for all commands.
     * {@see ExecutionStep}
     */
    protected prepareStep( stepClass: typeof ExecutionStep ) {
        // If relatedInterfaces static getter is available on the execution step and at least one
        // Matching interface is not found then do not prepare this execution step.
        const relatedInterfaces = stepClass.relatedInterfaces;
        if ( relatedInterfaces && relatedInterfaces.length > 0 ) {
            if ( !this.hasInterfaceRequirement( this.commandInstance, relatedInterfaces )) {
                return;
            }
        }

        const injector = this.moduleInjector;
        const step: ExecutionStep = new stepClass( this.commandInstance, this.log, injector );
        this.sequence.push( step );
    }


    /**
     * Matches the interface of the given command with the given pattern to see if the step must be processed
     * for this command. If it must be processed, returns true.
     *
     * The comparision is done based on the pattern expected by the step which is explained in full detail in
     * {@see ExecutionStep.relatedInterfaces}.
     *
     * @param command The command for which the interfaces have to be matched
     * @param stepInterfaces The interface pattern of the step for which matching has to be done.
     */
    protected hasInterfaceRequirement(
        command: AbstractCommand, stepInterfaces: Array<CommandInterfaces | Array<CommandInterfaces>> ): boolean {

        for ( const stepInterface of stepInterfaces ) {
            if ( Array.isArray( stepInterface )) {
                if ( command.hasAllInterfaces( stepInterface )) {
                    return true;
                }
            } else {
                if ( command.hasInterface( stepInterface )) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * This is the actual method that will run each ExecutionSteps in the
     * sequence. If all ExecutionSteps complete, the observer will emit the complete.
     * An ExecutionStep can inturrupt the sequence of execution if it is waiting for
     * another observable to complete. For more details see executeStep method.
     *
     * This method will catch all exceptions and emit it to the observer and the command instance.
     */
    protected executeSequence() {
        // Disabling command instrumentation is disabled due to huge number of logs and unable to handle
        // by ouer HyoerDX server at the moment.

        // const exeSpan = OTelManager.createSpan( 'EXECUTE SEQUENCE COMMAND', {
        //     attributes: {
        //         eventData: JSON.stringify( this.commandInstance.eventData ),
        //     },
        // });
        // try {
            if ( !this.observer ) {
                return;
            }

            if ( this.sequence && this.sequence.length > 0 ) {
                try {
                    while ( this.sequence.length > 0 ) {
                        if ( !this.executeStep( this.sequence.shift())) {
                            return; // Dont execute rest of the steps.
                        }
                    }
                } catch ( e ) {
                    this.onError( e );
                }
            }

            // If the full sequence has completed successfully then complete
            // The observable.
            this.observer.complete();
            this.observer = null;
        // } finally {
        //     if ( exeSpan ) {
        //         exeSpan.end();
        //     }
        // }
    }

    /**
     * This method is responsible for executing a single step of the sequence.
     *
     * As part of this once the process is complete, we look for a Observable
     * and handle the Observable according to how the ExecutionStep expects us
     * to do.
     *
     * @return boolean Indicates if the rest of the step should be executed in the
     *              sequence. <code>false</code> indiates the next step should not execute
     */
    protected executeStep( step: ExecutionStep ): boolean {
        this.observer.next({
            commandName: this.commandInstance.name,
            stepName:  step.name,
            stepStatus: 'started',
            stepIsAsync: step.asynchronous,
            data: this.commandInstance.data,
            resultData: this.commandInstance.resultData,
            status: true,
        });

        const result: any = step.process();

        if ( result instanceof Observable && step.waitOnObservable ) {
            this.subscribeToObservable( step, result );
            return false;
        }

        this.emitResults( step );
        return true;
    }

    /**
     * Emits the progress of the command ( ICOmmandProgress )to the command
     * observable.
     * Only emits if the resultData was changed. Once completed
     * resets the change status of the resultData
     * @param step The step that was processed.
     */
    protected emitResults( step: ExecutionStep ) {
        if ( this.commandInstance.resultDataChanged ) {
            this.commandInstance.resetResultDataChangedStatus();
        }
        this.observer.next({
            commandName: this.commandInstance.name,
            stepName:  step.name,
            stepStatus: 'completed',
            stepIsAsync: step.asynchronous,
            data: this.commandInstance.data,
            resultData: this.commandInstance.resultData,
            status: true,
        });
    }

    /**
     * This method subscribes to all a given Observable.
     * It handles the observable as part of the command handlers.
     * Use with caution.
     */
    protected subscribeToObservable( step: ExecutionStep, observable: Observable<any> ) {
        observable.subscribe(
            data => this.onNext( step, data ),
            error => this.onError( error ),
            () => this.onComplete( step ),
        );
    }

    /**
     * Observable.next
     * This will set the data recieved every time to the resultData of the command.
     * to do so it has to ensure previously set data does not get lost. For this purpose
     * the resultData is converted to an array to maintain multiple data vectors.
     *
     * The data is also emitted to the observer each time.
     */
    protected onNext( step: ExecutionStep, data: any ) {
        if ( this.commandInstance.resultData === undefined || this.commandInstance.resultData == null ) {
            this.commandInstance.resultData = data;
        } else if ( this.commandInstance.resultData instanceof Array ) {
            this.commandInstance.resultData.push( data );
        } else {
            const resultData = this.commandInstance.resultData;
            this.commandInstance.resultData = [ resultData, data ];
        }
        // FIXME: above code doesnt consider the data being an array.
        // If the data is an array isnt it best to merge?
    }

    /**
     * onError is called when an occurs while executing the command. This will run the
     * command revert step and
     */
    protected onError( error: CommandError ) {
        const revertResult = this.commandInstance.revert();
        if ( revertResult instanceof Observable ) {
            revertResult.subscribe(
                () => {},
                revertError => this.handleRevertFailure( error, revertError ),
                () => this.emitError( error ),
            );
        } else if ( !revertResult ) {
            const revertError = new Error( 'Revert step is cancelled by the revert hook' );
            this.handleRevertFailure( error, revertError );
        } else {
            this.emitError( error );
        }
    }

    /**
     * handleRevertFailure will run when command revert hook fails to run. This will log
     * that the revert has failed and forward the command error to the user.
     */
    protected handleRevertFailure( error: CommandError, revertError: Error ) {
        this.log.error( `Failed to run revert step for command ${this.commandInstance.name}: ${revertError.message}` );
        this.emitError( error );
    }

    /**
     * Observable.error
     * All error occurences are reported to the command and emitted
     * to the observer. AbstractCommand command will always log this
     * the command caller can choose to catch this as well.
     */
    protected emitError( error: CommandError | CommandCancelError ) {
        if ( error instanceof CommandCancelError ) {
            this.observer.complete();
            return;
        }
        try {
            const result = this.commandInstance.onError( error );
            if ( result instanceof Observable ) {
                result.subscribe({
                    next: val => this.observer.next( val ),
                    error: err => this.observer.error( err ),
                    complete: () => this.observer.complete(),
                });
            } else {
                this.observer.complete();
            }
        } catch ( err ) {
            // NOTE Command's onError hook may throw an error.
            this.observer.error( err );
        }
    }

    /**
     * Observable.complete
     * This ensures that when a observable completes, we continue the
     * execution of remaining steps.
     */
    protected onComplete( step: ExecutionStep ) {
        this.emitResults( step );
        this.executeSequence();
    }

    /**
     * Returns an array of providers required to create the command instance.
     */
    private getProviders() {
        const dependencies = Reflect.getMetadata( 'design:paramtypes', this.commandType );
        const provider = {
            provide: this.commandType,
            useClass: this.commandType,
            deps: dependencies || [],
        };
        return [ provider ];
    }
}
