import { uniqBy } from 'lodash';
import { Point } from './point';
import { IPoint2D } from 'flux-definition';
import { Rectangle } from './rectangle';
import { ILine, ILine2D } from 'flux-definition';

/**
 * A generic Line class used for Line related calculations.
 */
export class Line implements ILine {
    /**
     * Creates a line with given x, y values
     * @param x1 x value of the starting point
     * @param y1 y value of the starting point
     * @param x2 x value of the ending point
     * @param y2 y value of the ending point
     */
    public static from( x1: number, y1: number, x2: number, y2: number ): Line {
        return new Line({ x: x1, y: y1 }, { x: x2, y: y2 });
    }

    /**
     * Checks whether given points are on the same line or not.
     * @param points a number of points to check whether they are in line
     */
    public static isInLine( ...allPoints: IPoint2D[]): boolean {
        const points = uniqBy( allPoints, p => `${p.x},${p.y}` );
        if ( points.length < 3 ) {
            return true;
        }
        const firstGradient = new Line( points[0], points[1]).gradient;
        const isInfinity = Infinity === Math.abs( firstGradient );
        for ( let i = 2; i < points.length; i++ ) {
            const nextGradient = new Line( points[0], points[i]).gradient;
            if ( isInfinity ) {
                if ( Infinity !== Math.abs( nextGradient )) {
                    return false;
                }
            } else {
                if ( firstGradient  !== nextGradient ) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Checks whether given points are on the same line and horizontal or Vertical
     * @param points a number of points to check whether they are in line
     */
    public static isInLineHOrV( pointA: IPoint2D, pointB: IPoint2D, pointC: IPoint2D ): boolean {
        const allPointsInLine = this.isInLine( pointA, pointB, pointC );
        if ( allPointsInLine ) {
            const firstGradient = new Line( pointA, pointB ).gradient;
            if ( Infinity === Math.abs( firstGradient ) || Math.abs( firstGradient ) === 0 ) {
                return true;
            }
        }
        return false;
    }

    /**
     * The point where the line begins.
     */
    public from: Point;

    /**
     * The point where the line ends.
     */
    public to: Point;

    /**
     *
     * @param from the starting point of the line
     * @param to   the end point of the line
     */
    constructor( from: IPoint2D, to: IPoint2D ) {
        this.from = from instanceof Point ? from : Point.from( from );
        this.to = to instanceof Point ? to : Point.from( to );
    }

    /**
     * Returns the angle the line makes with the x-axis in degrees.
     */
    public get angle(): number {
        return this.from.angleTo( this.to );
    }

    /**
     * Returns the gradient the line makes with the x-axis.
     */
    public get gradient(): number {
        const ydiff = this.to.y - this.from.y;
        const xdiff = this.to.x - this.from.x;
        if ( ydiff === 0 ) {
            return 0;
        }
        if ( xdiff === 0 ) {
            return ydiff * Infinity;
        }
        return ydiff / xdiff;
    }

    /**
     * Checks whether this line is equal to given line.
     * @param line The line to compare this line with
     */
    public isEqual( line: ILine2D ): boolean {
        return this.from.isEqual( line.from ) && this.to.isEqual( line.to );
    }

    /**
     * Returns a new line with the from and to points swapped.
     */
    public reverse(): Line {
        return new Line( this.to, this.from );
    }

    /**
     * Returns the line making sure it starts from given point.
     */
    public startFrom( point: IPoint2D ): Line {
        if ( Point.isEqual( this.from, point )) {
            return this;
        }
        if ( Point.isEqual( this.to, point )) {
            return this.reverse();
        }
        return null;
    }

    /**
     * Checks whether the given point is an endpoint of the line.
     * @param point The point to check
     */
    public hasEndpoint( point: IPoint2D ): boolean {
        return Point.isEqual( point, this.from ) || Point.isEqual( point, this.to );
    }

    /**
     * Checks whether the given point is on this line segment.
     * @param point The point to check
     */
    public hasPoint( point: IPoint2D, includeEnds = true ): boolean {
        // FIXME: checks if 2 floats are equal! check whether they are close enough instead
        const hasPoint = this.length() === this.from.distanceTo( point ) + this.to.distanceTo( point );
        if ( includeEnds ) {
            return hasPoint;
        } else {
            const isEndpoint = Point.isEqual( point, this.from ) || Point.isEqual( point, this.to );
            return hasPoint && !isEndpoint;
        }
    }

    /**
     * Returns the point between starting point and the end point.
     * Optionally accepts a distance ratio parameter (default 0.5).
     *
     * Example:
     *  If the ratio is 0.25, it will return a point which is
     *  closer to this point than the given point ( 1 : 3 ).
     *
     *    S.....O...............E
     *       d         3d
     *
     * @param ratio The distance ratio between points
     */
    public split( ratio: number = 0.5 ): Point {
        const x = this.from.x + ratio * ( this.to.x - this.from.x );
        const y = this.from.y + ratio * ( this.to.y - this.from.y );
        return new Point( x, y );
    }

    /**
     * Returns the length of the line in number of pixels.
     * This is the distance from the starting point to the
     * ending point
     */
    public length(): number {
        return this.from.distanceTo( this.to );
    }

    /**
     * Returns a rectangle which contains this line
     */
    public bounds(): Rectangle {
        return Rectangle.withPoints( this.from, this.to );
    }

    /**
     * Retuns the point along this line, where the given point
     * forms a perpendicular line. Based on the formula m' = -1/m
     * Reference: https://stackoverflow.com/a/1811636
     * @param p Given point through which the perpendicularity must be calculated.
     */
    public perpendicularTo( p: IPoint2D ): Point {
        const [ x1, y1, x2, y2, x3, y3 ] = [
            this.from.x,
            this.from.y,
            this.to.x,
            this.to.y,
            p.x,
            p.y,
        ];
        const k = (
            ( y2 - y1 ) * ( x3 - x1 ) - ( x2 - x1 ) * ( y3 - y1 )) /
            ( Math.pow( y2 - y1, 2 ) + Math.pow( x2 - x1, 2 ));
        const x = x3 - k * ( y2 - y1 );
        const y = y3 + k * ( x2 - x1 );
        return Point.from({ x, y });
    }

    /**
     * Calculates the coordinates of the point on the given line at the
     * given position.
     *
     * @param length number    The distance along the line from start / end point
     * @param start boolean    The point to start calculation from, by default it measures
     *                         fron the start point,if false, it measures from the end point
     */
    public splitByLength( length: number, start: boolean = true ): Point {
        const totalLength = this.length();
        if ( totalLength === 0 ) {
            return this.from;
        }
        if ( start === false ) {
            length = totalLength - length;
        }
        if ( length === 0 ) {
            return this.from;
        } else if ( length === totalLength ) {
            return this.to;
        }
        const x = ( this.from.x + ( this.to.x - this.from.x ) * length / totalLength );
        const y = ( this.from.y + ( this.to.y - this.from.y ) * length / totalLength );
        return new Point( x, y );
    }

    /**
     * Returns true if the line is horizontal.
     */
    public isHorizontal(): boolean {
        return this.from.y === this.to.y;
    }

    /**
     * Returns true if the line is vertical.
     */
    public isVertical(): boolean {
        return this.from.x === this.to.x;
    }

    /**
     * Returns true if the line is horizontal or vertical.
     */
    public isHorizontalOrVertical(): boolean {
        return this.isHorizontal() || this.isVertical();
    }

    /**
     * Calculates the nearest point to the given point on the line.
     * returns the point and length fraction to that point
     * @param The test point
     * @param { point: IPoint2D, location: number } The nearest point cordinates and the length fraction along the curve
     */
    public getNearestPoint( point: IPoint2D ): { point: Point, location: number } {
        if ( this.length() === 0 ) {
            return {
                point: this.from,
                location: 0,
            };
        }
        const perPoint = this.perpendicularTo( point );
        if ( this.hasPoint( perPoint )) {
            return {
                point: perPoint,
                location: Line.from( perPoint.x, perPoint.y, this.from.x, this.from.y ).length() / this.length(),
            };
        } else {
            const distanceToFromPoint = Line.from( point.x, point.y, this.from.x, this.from.y ).length();
            const distanceToToPoint = Line.from( point.x, point.y, this.to.x, this.to.y ).length();
            const closestPoint = distanceToFromPoint < distanceToToPoint ? this.from : this.to;
            const location = closestPoint === this.from ? 0 : 1;
            return { point: closestPoint, location };
        }
    }

    /**
     * Checks if a line will intersect with another line.
     * Reference: https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public intersects( _line: ILine2D, includeEnds = true ): boolean {
        const line = new Line( _line.from, _line.to );
        if ( !includeEnds && (
            this.hasPoint( line.from ) ||
            this.hasPoint( line.to ) ||
            line.hasPoint( this.from ) ||
            line.hasPoint( this.to )
        )) {
            return false;
        }
        const o1 = Point.orientation( this.from, this.to, line.from );
        const o2 = Point.orientation( this.from, this.to, line.to );
        const o3 = Point.orientation( line.from, line.to, this.from );
        const o4 = Point.orientation( line.from, line.to, this.to );
        return (
            ( o1 !== o2 && o3 !== o4 ) ||
            ( o1 === 0 && this.hasPoint( line.from, includeEnds )) ||
            ( o2 === 0 && this.hasPoint( line.to, includeEnds )) ||
            ( o3 === 0 && line.hasPoint( this.from, includeEnds )) ||
            ( o4 === 0 && line.hasPoint( this.to, includeEnds ))
        );
    }
    /**
     * check the given line coincides the edge
     */
    public liesInside( _line: ILine2D ): boolean {
        const line = new Line( _line.from, _line.to );
        if (
            ( this.hasPoint( line.from )) &&
            ( this.hasPoint( line.to )) &&
            !( this.hasEndpoint( line.from )) &&
            !( this.hasEndpoint( line.to ))
            ) {
            return true;
        }
        return false;
    }

    // FIXME: We're currently considering lines intersect when they overlap. This is not correct.
    //        Replace above function with one below after handling cases where the lines overlap.
    //        This can change the behavior in some parts of the application (eg: connector pathing).
    // /**
    //  * Returns whether given line segments intersect
    //  * http://www.cs.swan.ac.uk/~cssimon/line_intersection.html
    //  */
    // public intersects( line: ILine2D, includeEnds = true ): boolean {
    //     return !!this.intersection( line, includeEnds );
    // }

    /**
     * Returns the intersection point of current line and given line.
     *
     * Reference:
     *     https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection
     *     http://www.cs.swan.ac.uk/~cssimon/line_intersection.html
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public intersection( line: ILine2D, includeEnds = true ): Point {
        const { x: x1, y: y1 } = this.from;
        const { x: x2, y: y2 } = this.to;
        const { x: x3, y: y3 } = line.from;
        const { x: x4, y: y4 } = line.to;
        const de = ( x4 - x3 ) * ( y1 - y2 ) - ( x1 - x2 ) * ( y4 - y3 );
        if ( de === 0 ) {
            return null;
        }
        const ta = (( y3 - y4 ) * ( x1 - x3 ) + ( x4 - x3 ) * ( y1 - y3 )) / de;
        const tb = (( y1 - y2 ) * ( x1 - x3 ) + ( x2 - x1 ) * ( y1 - y3 )) / de;
        if ( !includeEnds ) {
            if ( ta === 0 || ta === 1 || tb === 0 || tb === 1 ) {
                return null;
            }
        }
        if ( ta < 0 || ta > 1 || tb < 0 || tb > 1 ) {
            return null;
        }
        const x = x1 + ta * ( x2 - x1 );
        const y = y1 + ta * ( y2 - y1 );
        return new Point( x, y );
    }
}

/**
 * An enum that represents three positions on a line/coordinate based on ratios.
 * On a single line, this enum represents the origin, end or the middle.
 * This can be used in cases where alignemnt is concenred.
 *
 * Example: on the x coordinate Origin would represent left. End would represent right
 * At the same time on a y coordinate the same would represent up and down.
 */
export enum RatioPosition {
    Origin = 0,
    Middle = 0.5,
    End = 1,
}
