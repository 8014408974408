import { Rectangle } from './rectangle';
import { IPoint2D } from 'flux-definition';

/**
 * This class is a positioning tool for placing an element inside a specific
 * given bound gracefully. It provides functionality to find the placement point
 * of the element to show the element withn the bounds. It will also provide ways
 * to identify if the element should be inverted when placing on a given point.
 *
 * The ideal usecase is for placing elements that position dynamically and must be positioned
 * within a given window/frame. Applicable examples are floating toolbar, contextual dropdown menu
 * tooltip and so on. These sort of elements/controls have to place without getting cut off,
 * change direction, re orient itself when positioning. Following is an example
 *
 * INPUT:
 *
 *    Bounds                Element
 *    *******************   *********
 *    *                 *   *       *
 *    *                 *   *       *
 *    *                 *   *********
 *    *                 *
 *    *                 *
 *    *           Point *
 *    *             *   *
 *    *                 *
 *    *                 *
 *    *******************
 *
 * OUTPUT:
 *
 *    Bounds
 *    *******************
 *    *                 *
 *    *                 *
 *    *     Element     *
 *    *     *********   *
 *    *     *       *   *
 *    *     *       *   *
 *    *     *********   *
 *    *                 *
 *    *                 *
 *    *******************
 *
 */
export class BoundsPosition {

    /**
     * Creates a instance of the Bounds Poisition
     * @param element The element for which the placement must be identified
     */
    public static create( element: HTMLElement ): BoundsPosition {
        return new BoundsPosition( element );
    }

    /**
     * Constructor
     * @param element The element for which the placement must be identified
     */
    constructor( protected element: HTMLElement ) {}

    protected get clientRect(): ClientRect {
        return this.element.getBoundingClientRect();
    }

    /**
     * Returns the shift/change to the given x, y values to place the element
     * within the provided bounds. Considers placing the element within the given
     * bounds gracefully.
     *
     * @param bounds The bounds of where the elements must be placed.
     * @param x x position relative to the bounds where the element has to be placed
     * @param y y position relative to the bounds where the element has to be placed
     * @returns The x,y offset/shift to given x, y where the element has to be placed. This values
     *      can be positive or negative. For some cases the negative values can be used to identify
     *      direction change on the element. i.e. tooltips.
     */
    public getPoint( bounds: Rectangle, x: number, y: number ): IPoint2D  {
        const elementRect = new Rectangle( x, y, this.clientRect.width, this.clientRect.height );
        const shiftX = this.findShift( bounds.width,  elementRect.width, elementRect.right,  x );
        const shiftY = this.findShift( bounds.height, elementRect.height, elementRect.bottom, y );
        return {
            x: shiftX,
            y: shiftY,
        };
    }

    /**
     * Returns change to shift the element on a given axis based on the bound dimention and element
     * dimention on that same axis.
     *
     * @param boundsDimension the length of the bound on the axis
     * @param elementDimension the length of the element on the axis
     * @param elementDimension the ending of the element on the axis
     * @param pointDimension the position to place on the axis
     */
    protected findShift(
        boundsDimension: number, elementDimension: number , elementEndPoint: number, pointDimension: number ): number {
        if ( elementDimension > boundsDimension ) {
            return 0;
        }
        if ( pointDimension < 0 ) {
            return ( -pointDimension );
        }
        if ( elementEndPoint > boundsDimension ) {
            return ( boundsDimension - elementEndPoint );
        } else {
            return 0;
        }
    }


}
