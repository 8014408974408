import { TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { ShapeDataModel } from 'flux-diagram-composer';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import * as Carota from '@creately/carota';
import { DEFUALT_TEXT_STYLES } from 'flux-definition';

/**
 * This UpdateTextBounds command is to calculate and update the text bounds
 */
@Injectable()
@Command()
export class UpdateTextBounds extends AbstractDiagramChangeCommand {

    /**
     * Prepares the data necessary for update the text
     */
    public prepareData() {
        const shapeIds = this.data?.shapeIds || Object.keys( this.changeModel.shapes );
        shapeIds.forEach( id => {
            const shape = this.changeModel.shapes[id];
            if ( shape.hasAnyText ) {
                for ( const key in shape.texts ) {
                    const textModel = shape.texts[ key ];
                    if ( textModel.rendering && ( textModel.rendering !== 'carota' )) {
                        continue;
                    }
                    const content = textModel.content;
                    const defaultBounds = this.getTextBounds( content );
                    textModel.width = defaultBounds.width;
                    textModel.height = defaultBounds.height;
                    if ( !shape.isConnector()) {
                        const newShapeWidth = Math.abs(
                            ( shape as ShapeDataModel ).defaultBounds.width * ( shape as ShapeDataModel ).scaleX );
                        const paddedWidth = ( textModel.hitArea ?
                            ( textModel.hitArea.width - TEXT_PADDING_HORIZONTAL * 2 ) : newShapeWidth
                            - TEXT_PADDING_HORIZONTAL * 2 );
                        const textWrap = defaultBounds.width > paddedWidth;
                        if ( textWrap ) {
                            const textBounds = this.getTextBounds( content, paddedWidth );
                            textModel.width = textBounds.width;
                            textModel.height = textBounds.height;
                        }
                    }
                }
            }
        });
    }

    /**
     * Returns the bounds of the text
     * @param data: Object[]    Carota text objects array
     * @param width: number    Optional param to wrap the text
     * FIXME -  The default width should be infinite and here it is just a big number
     */
     protected getTextBounds( data: Object[] | string, width: number = 10000 ) {
        return Carota.bounds( data, width, DEFUALT_TEXT_STYLES );
    }
}

Object.defineProperty( UpdateTextBounds, 'name', {
    value: 'UpdateTextBounds',
});
