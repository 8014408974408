import { Injectable, Inject } from '@angular/core';
import { StateService } from './state.svc';

/**
 * The various notification types available in the app. This can be used to
 * filter notifications, decide how to display them etc.
 */
export enum NotificationType {
    Neutral = 'neutral',
    Warning = 'warning',
    Error = 'error',
    Success = 'success',
    Push = 'push',
    INFO = 'info',
}

/**
 * An object with all the inputs that should be set to the
 * notification component instance.
 * Object keys should have the same name as the input field.
 */
export interface INotifierOptions {
    inputs?: { [inputName: string]: any };
}

/**
 * This defines the information that gets passed to the notifier component
 * to show/hide the notification.
 */
export interface INotifierData {
    /**
     * This indicates whether the notification should be shown or hidden.
     */
    show?: boolean;

    /**
     * A unique ID for the notification. This is used to
     * to interact with the notification after it has been instantiated.
     */
    id: string;

    /**
     * The notification class. This will be the class that will
     * be instantiated by the notifier component.
     */
    component?: Function;

    /**
     * The notification type.
     */
    type?: NotificationType;

    /**
     * Options to be passed on to the notification instance.
     */
    options?: INotifierOptions;

    /**
     * Whether the notification should be collapsed initially.
     */
    collapsed?: boolean;
}

/**
 * StateService representation for the Notifier.
 */
export class NotifierStateService extends StateService< 'Notification', INotifierData> {}

/**
 * This is responsible for displaying Notifications across the application.
 *
 * A limited number of Notifications should be displayed at at time and the rest of
 * the available notifications should be held in a queue until a displayed notification
 * dismissed itself or is dismissed by a user.
 *
 * @author jerome
 * @since 2020-04-10
 */
@Injectable()
export class NotifierController {

    constructor( @Inject( StateService ) protected state: NotifierStateService ) {}

    /**
     * This shows the notification.
     * @param id The id for the notification for later interaction
     * @param type The type of the notification, i.e. the notification class
     * @param options The optional options to be passed on to the notification as inputs.
     */
    public show( id: string, component: Function, type?: NotificationType,
                 options?: INotifierOptions, collapsed?: boolean ) {
        this.state.set( 'Notification', { id: id, show: true, component: component,
            type: type, options: options, collapsed: collapsed });
    }

    /**
     * This hides a specified notification.
     * @param opt The id of the notification to hide
     */
    public hide( id: string ) {
        this.state.set( 'Notification', { id: id, show: false });
    }

}
