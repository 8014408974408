import { Stage } from '@creately/createjs-module';
import { StateService, Command, CommandService, Rectangle } from 'flux-core';
import { Injectable } from '@angular/core';
import { IShapeLocation, ShapeBoundsLocator } from '../containers/shape-bounds-locator';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { KeyCode } from 'flux-definition/src';
import { tap } from 'rxjs/operators';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { DiagramToViewportCoordinate } from '../../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { InteractionCommandEvent } from '../../../base/interaction/command/interaction-command-event';
import { KeyboardSelectionInteractionEvent } from '../../interaction/event/keyboard-selection-interaction-event';
import { BaseDiagramCommandEvent } from '../../../base/diagram/command/base-diagram-command-event';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

interface IContainerHistory { id: string; childId: string; arrowDirection: number; }

/**
 * This KeyboardSelectionInteraction command is used to commit
 * scale and move interaction via keyboard and this is an intermediate command.
 * There are two main keyboard interactions, Scaling and Moving
 * This command is to be used for both. Command data includes the keyboard event
 * related to the keyboard shortcut defined in the featureList { see editor.module.ts }.
 * According to the keyboard event data, a specific event is dispatched
 * on the intereactionCanvas to do the Moving or Scaling interaction.
 */
@Injectable()
@Command()
export class KeyboardSelectionInteraction extends AbstractDiagramChangeCommand {

    public static get dataDefinition(): {}  {
        return {
            keyboardEvent: true, // The keyboard event related to feature list shortcut
        };
    }

    // width/height of the lookup rectangle
    protected lookupWidth = 10000;
    protected lookupDistance = 100000;
    // weight modifier for pythagorean
    protected oppositeSideWeight = 1.9;
    // main axis weight, to prioritize shapes directionally
    protected mainAxisWeight = 1.04;
    // the less the modifier, the larger the lookup area outside of the viewport would be
    protected viewportOutModifier = 20;

    protected oppositeArrowMap = {
        [KeyCode.ArrowLeft]: KeyCode.ArrowRight,
        [KeyCode.ArrowRight]: KeyCode.ArrowLeft,
        [KeyCode.ArrowUp]: KeyCode.ArrowDown,
        [KeyCode.ArrowDown]: KeyCode.ArrowUp,
    };

    // look up this.getDirection() for variable explanation
    protected directionsMap = {
        [KeyCode.ArrowUp]:
            { x: 0.5, y: 0, w: this.lookupWidth, h: this.lookupDistance, xm: 1, ym: 0, xm1: 0, ym1: 1 },
        [KeyCode.ArrowRight]:
            { x: 1, y: 0.5, w: this.lookupDistance, h: this.lookupWidth, xm: 0, ym: 1, xm1: 0, ym1: 0 },
        [KeyCode.ArrowDown]:
            { x: 0.5, y: 1, w: this.lookupWidth, h: this.lookupDistance, xm: 1, ym: 0, xm1: 0, ym1: 0 },
        [KeyCode.ArrowLeft]:
            { x: 0, y: 0.5, w: this.lookupDistance, h: this.lookupWidth, xm: 0, ym: 1, xm1: 1, ym1: 0 },
    };

    constructor(
        protected commands: CommandService,
        protected vToD: ViewportToDiagramCoordinate,
        protected dToV: DiagramToViewportCoordinate,
        protected state: StateService<any, any>,
        protected shapeBoundsLocator: ShapeBoundsLocator,
        protected dl: DiagramLocatorLocator,
        protected ds: DiagramChangeService,
    ) {
        super( ds )/* istanbul ignore next */;
    }

    public execute (): any {
        if ( !this.data || !this.data.keyboardEvent ) {
            return false;
        }
        if ( this.data.keyboardEvent.shiftKey ) {
            const interactionCanvas: Stage = this.state.get( 'InteractionCanvas' );
            const keyboardInteractionEvent = new KeyboardSelectionInteractionEvent( this.data.keyboardEvent );
            interactionCanvas?.dispatchEvent( keyboardInteractionEvent );
            return true;
        }
        const selected: Array<string> = this.state.get( 'Selected' ) || [];
        if ( selected.length > 1 ) {
            return false;
        }
        const bounds = this.shapeBoundsLocator.getDiagramBounds();
        const { x: bx, y: by, width: bw, height: bh } = bounds;
        const shapes = this.shapeBoundsLocator.searchShapes( bx, bx + bw, by, by + bh )
            .filter( shape => shape.type !== 'connector' );
        const selectedShape = shapes.find( shape => shape.id === selected[0]);
        if ( !selectedShape ) {
            this.selectCenterShape( shapes );
            return;
        }

        const innerSelection = this.state.get( 'InnerShapeSelection' );
        if ( innerSelection && Object.keys( innerSelection ).length === 1 ) {
            return this.selectInnerShapes(
                selectedShape.id, innerSelection[selectedShape.id], this.data.keyboardEvent );
        }

        const { x, y, width: w, height: h } = selectedShape;

        // get modifiers and rect width and height based on arrow key pressed
        const direction = this.getDirection( this.data.keyboardEvent.keyCode );
        const oppositeDirection = this.getDirection( this.oppositeArrowMap[this.data.keyboardEvent.keyCode]);

        const { w: dw, h: dh, xm, ym, xm1, ym1, x: dx, y: dy } = direction;
        const { x: dxo, y: dyo } = oppositeDirection;

        // get starting rectangle coordinates. Note that we cannot use negative width/height.
        // for reference, look up {@this.getNextShape}
        const sx = x + ( w + 1 ) * dx - dw * xm1 - ( dw * xm ) / 2;
        const sy = y + ( h + 1 ) * dy - dh * ym1 - ( dh * ym ) / 2;

        // final points
        const { x: fx, y: fy } = this.correctCoordinates( sx, sy );
        const { x: fxe, y: fye } = this.correctCoordinates( sx + dw, sy + dh );

        const potentialShapes: IShapeLocation[] =
            this.shapeBoundsLocator.searchShapes( fx, fxe, fy, fye, [], false )
                .filter( s => s.id !== selectedShape.id && s.type !== 'connector' );
        const [ filteredShapes, containerId, isChild ] =
            this.processContainerShapes( potentialShapes, selectedShape, shapes );
        if ( !filteredShapes.length && !containerId ) {
            return;
        } else if ( !filteredShapes && containerId && containerId !== selectedShape.id ) {
            // FIXME: Dispatching commands inside a command is anti-pattern and should be refactored later.
            this.commands.dispatch( BaseDiagramCommandEvent.selectShapes, this.state.get( 'CurrentDiagram' ), {
                shapeIds: [ containerId ],
                arrowDirection: this.data.keyboardEvent.keyCode,
            });
            return true;
        }
        let nextShape = this
            .getNextShape( filteredShapes, x + w * dxo, y + h * dyo, dxo, dyo, xm ? 'y' : 'x' );
        // if we don't find an appropriate shape (there are only ones outside the cone) and we have a container,
        // pick the container instead.
        if ( !nextShape?.id && containerId ) {
            if ( !isChild && filteredShapes.length ) {
                nextShape = filteredShapes[0];
            } else {
                nextShape = shapes.find( s => s.id === containerId );
            }
        }
        if ( !nextShape?.id ) {
            return true;
        }

        const inBounds = this.isNextShapeInBounds( nextShape );
        if ( !inBounds ) {
            this.commands.dispatch( InteractionCommandEvent.focusToPoint, {
                shapeIds: [ nextShape.id ],
            });
            this.state.set( 'ShapelinkTarget', { shapeId: nextShape.id });
        }
        this.commands.dispatch( BaseDiagramCommandEvent.selectShapes, this.state.get( 'CurrentDiagram' ), {
            shapeIds: [ nextShape.id ],
            arrowDirection: this.data.keyboardEvent.keyCode,
        });
        return true;
    }

    protected selectCenterShape( shapes: IShapeLocation[]) {
        const vInfo = this.getViewportInfo();
        const centerX = vInfo.minX + ( vInfo.maxX - vInfo.minX ) / 2;
        const centerY = vInfo.minY + ( vInfo.maxY - vInfo.minY ) / 2;
        const selectShape = shapes.reduce(( res, next ) => {
            // get center coords of the next shape
            const nextX = next.x + next.width / 2;
            const nextY = next.y + next.height / 2;
            const diffX = Math.abs( Math.max( nextX, centerX ) - Math.min( nextX, centerX ));
            const diffY = Math.abs( Math.max( nextY, centerY ) - Math.min( nextY, centerY ));
            const diff = Math.sqrt( diffX ** 2 + diffY ** 2 );
            if ( diff < res.distance ) {
                res.distance = diff;
                res.shape = next;
            }
            return res;
        }, { shape: null, distance: Infinity });
        if ( selectShape.shape ) {
            this.commands.dispatch( BaseDiagramCommandEvent.selectShapes, this.state.get( 'CurrentDiagram' ), {
                shapeIds: [ selectShape.shape.id ],
                arrowDirection: this.data.keyboardEvent.keyCode,
            });
        }
    }

    protected selectInnerShapes( shapeId, innerShape, event ) {
        return this.dl.forDiagram( this.state.get( 'CurrentDiagram' ), false ).getDiagramOnce().pipe(
            tap( diagram => {
                // const cells = [];
                const shape = diagram.shapes[ shapeId ];
                if ( shape && 'navigateCells' in shape ) {
                    const method = 'navigateCells';
                    const methodParams = [ innerShape, event ];
                    const shapeChangeModel =  this.changeModel.shapes[ shapeId ];
                    if ( shapeChangeModel && ( shapeChangeModel as any )[ method ]) {
                        ( shapeChangeModel as any )[ method ]( this.state, shapeChangeModel, ...methodParams );
                    }
                }
            }),
        );
    }

    protected correctCoordinates( x, y ) {
        const { minX, maxX, minY, maxY } = this.getViewportInfo();
        if ( x > maxX ) {
            x = maxX;
        } else if ( x < minX ) {
            x = minX;
        }
        if ( y > maxY ) {
            y = maxY;
        } else if ( y < minY ) {
            y = minY;
        }
        return { x, y };
    }

    protected isNextShapeInBounds( nextShape: IShapeLocation ) {
        const viewport = this.state.get( 'DiagramViewPort' );
        const { x, y, width: w, height: h } = nextShape;
        const checkRect = this.dToV.rect( new Rectangle( x, y, w, h ));
        return viewport.contains( checkRect );
    }

    protected getViewportInfo() {
        const viewport = this.state.get( 'DiagramViewPort' );
        const { height: h, width: w } = viewport;
        const w10 = w / 10;
        const h10 = h / 10;
        return {
            maxX: this.vToD.x( w + w10 ),
            maxY: this.vToD.y( h + w10 ),
            minX: this.vToD.x( 0 - w10 ),
            minY: this.vToD.y( 0 - h10 ),
        };
    }

    /**
     * Find closest shape from the list based on the distance between their center points
     * @param shapes list of shapes
     * @param x selected shape x
     * @param y selected shape y
     * @param dxo directional x modifier
     * @param dyo directional y modifier
     * @returns the closest shape
     */
    protected getNextShape( shapes: IShapeLocation[],
                            x: number,
                            y: number,
                            dxo: number, dyo: number,
                            direction: 'x' | 'y',
    ): IShapeLocation {
        const { oppositeSideWeight: ow } = this;
        const { nextShape } = shapes.reduce(( res, next ) => {
            // get center coords of the next shape
            const nx = next.x + next.width / 2;
            const ny = next.y + next.height / 2;
            // get coords that align with selected frame's modifiers
            // for filtering
            const nxo = next.x + next.width * dxo;
            const nyo = next.y + next.height * dyo;
            // if we have very close x/y on unwanted axis, break.
            // if shape is too offset on unwanted axis, break.
            const w = Math.abs( nx - x );
            const h = Math.abs( ny - y );
            const { mainAxisWeight } = this;
            if ( direction === 'x' && ( !w || w <= Math.abs( nx - nxo ) || ( w / h < mainAxisWeight ))) {
                return res;
            } else if ( direction === 'y' && ( !h || h <= Math.abs( ny - nyo ) || ( h / w < mainAxisWeight ))) {
                return res;
            }

            // find distance and use Pythegorean, with opposite side len getting less impact
            const diffX = Math.abs( Math.max( nx, x ) - Math.min( nx, x )) * ( direction === 'y' ? ow : 1 );
            const diffY = Math.abs( Math.max( ny, y ) - Math.min( ny, y )) * ( direction === 'x' ? ow : 1 );

            const diff = Math.sqrt( diffX ** 2 + diffY ** 2 );
            if ( diff < res.prevDiff ) {
                res.prevDiff = diff;
                res.nextShape = next;
            }
            return res;
        }, { nextShape: null, prevDiff: Infinity });
        return nextShape;
    }

    /**
     * Gets all the needed information to create a search rectangle based on a key pressed
     * @param direction Arrow KeyCode
     * @returns {
     *  x:   shape side modifier
     *  y:   shape side modifier
     *  w:   search width
     *  h:   search height
     *  xm:  x modifier for getting correct shape side start/end point
     *  xm1: x modifier for getting correct diagram start/end point
     *  ym:  y modifier for getting correct shape side start/end point
     *  ym1: y modifier for getting correct diagram start/end point
     * }
     * Note: we need xm1 and ym1 because we cannot lookup shapes based on negative values
     */
    protected getDirection( direction: number ):
        { x: number, y: number, w: number, h: number, xm: number, ym: number, xm1: number, ym1: number } {
        return this.directionsMap[direction];
    }

    protected processContainerShapes( shapes: IShapeLocation[],
                                      selectedShape: IShapeLocation,
                                      allShapes: IShapeLocation[]): [ IShapeLocation[], string?, boolean? ] {
        const containerHistory: IContainerHistory[] = [ ...this.state.get( 'selectedContainerHistory' ) ].reverse();
        const containerData = this.shapeBoundsLocator.getContainerData();
        const childrenIds = containerData.children.reduce(( res, next ) => {
            res[next.id] = next.containerId;
            return res;
        }, {});
        const selectedId = selectedShape.id;
        const { keyCode }: { keyCode: number } = this.data.keyboardEvent;
        const lastC = containerHistory[1];
        const lastS = containerHistory[0];

        const isContainer = containerData.containers.includes( selectedId );
        let containerId = containerData.children.find( c => c.id === selectedId )?.containerId;
        let isChild = true;
        const isGoingIntoContainer = isContainer && lastS.arrowDirection === keyCode && !lastC.childId;
        const isGoingBackIntoContainer = isContainer && lastC?.childId &&
            lastC.arrowDirection === this.oppositeArrowMap[keyCode] && lastC.arrowDirection === lastS.arrowDirection;

        if ( isGoingIntoContainer || containerId || isGoingBackIntoContainer ) {
            if ( !containerId ) {
                containerId = selectedId;
                isChild = false;
            }
            const children = containerData.children.filter( c => c.containerId === containerId ).map( c =>
                ( !lastS.childId ? allShapes : shapes ).find( s => s.id === c.id ),
            ).filter( Boolean );
            return [ children, containerId, isChild ];
        }

        return [ shapes.filter( s => !childrenIds[s.id] && s.id !== selectedId ) ];
    }
}

Object.defineProperty( KeyboardSelectionInteraction, 'name', {
    value: 'KeyboardSelectionInteraction',
});
