import { Injectable } from '@angular/core';
import { AbstractCommand, Command } from 'flux-core';
import { tap } from 'rxjs/operators';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';


/**
 * GetConnectedConnectors
 * This command will return a map of all connectors connected to
 * give shapes and their connected endpoints.
 *
 *  data: {
 *      shapeIds: string[],
 *  }
 *
 *  resultData: {
 *      [connectorId: string]: IConnectorEndPointOnPath[],
 *  }
 *
 */
@Injectable()
@Command()
export class GetConnectedConnectors extends AbstractCommand {
    public static get dataDefinition(): any  {
        return {
            shapeIds: true,
        };
    }

    constructor( protected ds: DiagramChangeService ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Reconnects all given connectors to suitable gluepoints using provided data.
     */
    public prepareData() {
        this.resultData = {};
        return this.ds.getChangeModel( this.resourceId, this.eventData.eventId, this.eventData.scenario ).pipe(
            tap( diagram => {
                this.data.shapeIds.forEach( shapeId => {
                    const shape: any = diagram.shapes[shapeId];
                    if ( shape instanceof ConnectorModel ) {
                        return;
                    }
                    ( shape.getConnectors ? shape.getConnectors( diagram ) : []).forEach(({ connector, endpoint }) => {
                        const pointData = connector.path[endpoint.id];
                        this.resultData[ connector.id ] = this.resultData[ connector.id ] || [];
                        this.resultData[ connector.id ].push( pointData );
                    });
                });
            }),
        );
    }

    /**
     * Returns true.
     */
    public execute() {
        return true;
    }
}

Object.defineProperty( GetConnectedConnectors, 'name', {
    value: 'GetConnectedConnectors',
});
