import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';

/**
 * This CategorizeShapes command is to move shapes into container regions
 */
@Injectable()
@Command()
export class CategorizeShapes extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapes: {
            id: string,
            row: string,
            column: string,
            lineColor: string,
            fillColor: string,
            textColor: string,
            width: number,
            height: number,
        }[],
        tableId: string,
        columns: [],
        rows: [],
        cellsMapVertical: any,
        cellsMapHorizontal: any,
        cellsMapVerticalHorizontal: any,
        // [shapeId: string]: {
        //     categories: {
        //         columns: [],
        //         rows: [],
        //     },
        //     items: {
        //         [shapeId: string]: {
        //             lineColor: string,
        //             fillColor: string,
        //             textColor: string,
        //             width: number,
        //             height: number,
        //             columns: string,
        //             rows: string,
        //         },
        //     }
        //     cellsMapVertical: any,
        //     cellsMapHorizontal: any,
        //     cellsMapVerticalHorizontal: any,
        //     template: string,
        // },
    };

    public prepareData() {
        const tableId = this.data.tableId;
        if ( tableId ) {
            this.categorizeInTable( tableId );
        }
        this.modifyShapes();
    }

    protected categorizeInTable( tableId: string ) {
        const value = this.data;
        const columns = value.columns || [];
        const rows = value.rows || [];
        let template = '';
        if ( columns.length > 0  && rows.length === 0 ) {
            template = 'columns';
        } else if ( columns.length === 0  && rows.length > 0 ) {
            template = 'rows';
        } else if ( columns.length > 0  && rows.length > 0 ) {
            template = 'columns-rows';
        } else {

        }

        const table = this.changeModel.shapes[tableId] as ShapeModel;
        if ( table && template === 'columns' ) {
            ( value.shapes ).forEach(( item, i ) => {
                const shapeToMove = this.changeModel.shapes[ item.id ] as ShapeModel;
                const destinationCell = value.cellsMapVertical[ item.column ];
                if ( destinationCell ) {
                    const region = table.containerRegions[ destinationCell.id ];
                    shapeToMove.x  = region.x + table.x + region.width / 2 - shapeToMove.width / 2;
                    shapeToMove.y = region.y + table.y + region.height / 2 - shapeToMove.height / 2;
                }
            });
        }

        if ( table &&  template === 'rows' ) {
            ( value.shapes ).forEach(( item, i ) => {
                const shapeToMove = this.changeModel.shapes[ item.id ] as ShapeModel;
                const destinationCell = value.cellsMapHorizontal[ item.row ];
                if ( destinationCell ) {
                    const region = table.containerRegions[ destinationCell.id ];
                    shapeToMove.x  = region.x + table.x + region.width / 2 - shapeToMove.width / 2;
                    shapeToMove.y = region.y + table.y + region.height / 2 - shapeToMove.height / 2;
                }
            });
        }

        if ( table && template === 'columns-rows' ) {
            ( value.shapes ).forEach(( item, i ) => {
                const shapeToMove = this.changeModel.shapes[ item.id ] as ShapeModel;
                const destinationCell = value
                    .cellsMapVerticalHorizontal[ item.column ][ item.row ];
                if ( destinationCell ) {
                    const region = table.containerRegions[ destinationCell.id ];
                    shapeToMove.x  = region.x + table.x + region.width / 2 - shapeToMove.width / 2;
                    shapeToMove.y = region.y + table.y + region.height / 2 - shapeToMove.height / 2;
                }
            });
        }
    }

    protected modifyShapes() {
        ( this.data.shapes ).forEach( item => {
            const shape = this.changeModel.shapes[ item.id ] as ShapeModel;
            shape.style.lineColor = item.lineColor || shape.style.lineColor;
            shape.style.fillColor = item.fillColor || shape.style.fillColor;
            if ( shape.previewTextModel && item.textColor ) {
                shape.previewTextModel.content = shape.previewTextModel.content.map( run => {
                    run.color = item.textColor;
                    return run;
                });
            }
            if ( item.width !== undefined ) {
                shape.scaleX = item.width / shape.defaultBounds.width;
            }
            if ( item.height !== undefined ) {
                shape.scaleY = item.height / shape.defaultBounds.height;
            }
        });
    }

}

Object.defineProperty( CategorizeShapes, 'name', {
    value: 'CategorizeShapes',
});
