import { CommandInterfaces, DeserializableExecutionStep } from 'flux-core';
import { clone } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractAuthentication } from '../../auth/abstract-auth.svc';
import { MessageFactory } from '../../message/message-factory.svc';
import { Message } from '../../message/message.mdl';

/**
 * Describes the response received from the server.
 */
export interface ICommandResult {
    ppld?: any;
}

/**
 * SendExecutionStep
 * This class is an implementation of the {@link ExecutionStep} which handles
 * the sending of messages to the Neutrino server using the NeutrinoConnection
 *
 * To see how this is used, see the {@link CommandExecutor}.prepareSequnce method.
 *
 * @author  hiraash
 * @since   2016-04-18
 */
export abstract class SendExecutionStep extends DeserializableExecutionStep {
    /**
     * Indicates the interfaces a command must implement for this
     * ExecutionStep step to process during that command execution.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces | CommandInterfaces[]> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Mark this execution step as an async execution step.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * Composes a command message and sends to the server.
     * Returns a observable returned by the connection class
     */
    public process() {
        return of( this.isAuthenticated()).pipe(
            switchMap( isAuthenticated => {
                if ( !isAuthenticated ) {
                    const authentication = this.injector.get( AbstractAuthentication );
                    return authentication.authenticate();
                }
                return of ( true );
            }),
            switchMap(() => this.sendMessage( this.populateMessage()).pipe(
                switchMap( result => this.deserialize( result )),
            )),
        );
    }

    /**
     * Sends given message with an available transport (HTTP or WwbSocket).
     */
    protected abstract sendMessage( message: Message ): Observable<ICommandResult>;

    /**
     * Check whether the command is authorised to be sent.
     */
    private isAuthenticated(): boolean {
        if ( this.command.hasInterface( 'IAllowAnonymous' )) {
            return true;
        }
        const authentication = this.injector.get( AbstractAuthentication );
        return authentication.isAuthenticated;
    }

    /**
     * Creates and populates the message with the command data
     */
    private populateMessage(): Message {
        const messageFactory = this.injector.get( MessageFactory );
        const authentication = this.injector.get( AbstractAuthentication );
        const message = messageFactory.createCommandMessage( this.command.name );
        message.payload = clone( this.command.data );
        // Neutrino has no knowledge of a shared-home project model.
        // Only exists in nucleus for displaying shared workspaces without a folder.
        message.resourceId = this.command.resourceId !== 'shared-home' ? this.command.resourceId : 'home';
        message.messageVersion = this.command.version;
        message.contextData = this.command.contextData;
        message.authToken = authentication.token;
        return message;
    }
}

Object.defineProperty( SendExecutionStep, 'name', {
    value: 'SendExecutionStep',
});
