import { Observable, of } from 'rxjs';
import { IFeatureDataTransformer } from '../../framework/feature/feature-data-transformer.i';
import { IFeatureItem } from '../../framework/feature/feature-item.i';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { StateService } from 'flux-core';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ConvertToObjectTransformer implements IFeatureDataTransformer<IFeatureItem, any, any> {

    constructor( protected ll: DiagramLocatorLocator,
                 protected state: StateService<any, any> ) {}

    isEnabled( featureId: string ): Observable<boolean> {
        if ( this.state.get( 'CurrentProject' ) === 'home' ) {
            return of( false );
        }
        return this.ll.forCurrent( false ).getDiagramOnce().pipe(
            map( diagram  => this.state.get( 'Selected' ).every( id => !diagram.shapes[id].eData )),
        );
    }
    getFeatureData( featureId: string, data?: IFeatureItem ): Observable<any> | Observable<any[]> {
        return of( data );
    }
    getApplyData( featureId: string, data: any ): Observable<any> {
        return of( data );
    }

}
