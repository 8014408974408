import { Injectable } from '@angular/core';
import { Command, Random, StateService } from 'flux-core';
import { cloneDeep } from 'lodash';
import { ShapeType } from 'flux-definition/src';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { Restriction } from '../restriction/restriction.svc';
import { SvgToCanvas } from 'flux-diagram-composer';

/**
 * AddFreehandShape command to add and update freehand shape as user is
 * drawing.
 *
 * @author  lina
 * @since   2021-09-24
 *
 */
@Injectable()
@Command()
export class AddFreehandShape extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        svg: string;
        shapeStart: {
            x: number,
            y: number,
        };
        bounds: {
            width: number,
            height: number,
        };
        shapeId: string,
    };

    /**
     * SvgToCanvas instance
     */
    protected canvasInstance: SvgToCanvas;

    /**
     * Freehand def id
     */
    protected freehandDefId = 'creately.basic.freehand';

    /**
     * Freehand default bounds from def
     */
    protected defaultBounds = 200;

    /**
     * Current freehand version
     */
    protected freehandVersion = 1;

    protected styleMap = {
        lineStyle: 'stroke-dasharray',
        lineThickness: 'stroke-width',
        lineColor: 'stroke',
    };

    /**
     * Inject the state service to get the list of selected shape ids.
     */
    constructor( protected state: StateService<any, any>,
                 protected ds: DiagramChangeService,
                 protected restriction: Restriction,
    ) {
        super( ds ) /* istanbul ignore next*/;
        this.canvasInstance = new SvgToCanvas();
    }

    /**
     * Invoke the alterFunction on the changeModel.
     */
    public prepareData() {
        if ( !this.data || !this.hasPropertiesSet([ 'svg', 'shapeStart', 'bounds' ]) ||
            !this.hasPropertiesSet([ 'width', 'height' ], this.data.bounds ) ||
            !this.hasPropertiesSet([ 'x', 'y' ], this.data.shapeStart )) {
                return;
        }

        this.resultData = { shapeId: this.data?.shapeId };

        const freehandStateStyles = this.state.get( 'FreeHandLastUsedItem' ).styles;
        const styles = {
            lineThickness: freehandStateStyles[ this.styleMap.lineThickness ],
            lineStyle: freehandStateStyles[ this.styleMap.lineStyle ].split( ', ' ).map( val => +val ),
            lineColor: freehandStateStyles[ this.styleMap.lineColor ],
        };

        if ( !!this.data.shapeId ) {
            const shape: any = this.changeModel.shapes[ this.data.shapeId ];
            // Update bounds
            shape.x = this.data.shapeStart.x - styles.lineThickness / 2;
            shape.y = this.data.shapeStart.y - styles.lineThickness / 2;
            shape.defaultBounds.width = this.data.bounds.width + styles.lineThickness;
            shape.defaultBounds.height = this.data.bounds.height + styles.lineThickness;
            shape.scaleX = 1;
            shape.scaleY = 1;
            // Update instructions
            shape.instructions = this.canvasInstance.convert( this.data.svg ).instructions;
            shape.zIndex = this.changeModel.maxZIndex + 1;
            shape.isSticker = true;
        } else {
            const shapeId = this.getShapeId();
            this.resultData.shapeId = shapeId;
            this.state.set( 'CurrentFreeHandShape', shapeId );

            const shape = {
                id: shapeId,
                defId: this.freehandDefId,
                version: this.freehandVersion,
                scaleX: this.data.bounds.width / this.defaultBounds,
                scaleY: this.data.bounds.height / this.defaultBounds,
                type: ShapeType.Freehand,
                x: this.data.shapeStart.x,
                y: this.data.shapeStart.y,
                style: styles,
                instructions: [],
                zIndex: this.changeModel.maxZIndex + 1,
                isSticker: true,
            };

            const position = { x: shape.x || 0, y: shape.y || 0 };
            const restricted = this.restriction.point( position, [ 'GridService' ]);
            shape.x = restricted.x;
            shape.y = restricted.y;

            this.changeModel.shapes[shapeId] = cloneDeep( shape );
        }
    }
    /**
     * Returns a newly generate id for the shape to be added
     */
    protected getShapeId() {
        return Random.shapeId();
    }

    /**
     * Checks whether the modifier sets all given properties, returns false if any of them are missing.
     */
     private hasPropertiesSet( paths: string[], prop?: any ): boolean {
        for ( const path of paths ) {
            const value = prop ? prop[path] : this.data[path];
            if ( value === undefined ) {
                return false;
            }
        }
        return true;
    }
}

Object.defineProperty( AddFreehandShape, 'name', {
    value: 'AddFreehandShape',
});
