import { ProjectSub } from './../subscription/project.sub';
import { DataStore } from 'flux-store';
import { Observable, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommandInterfaces, Command, Random, StateService, CommandResultError } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { get } from 'lodash';
import { ModelSubscriptionManager } from 'flux-subscription';
import { ProjectModel } from '../model/project.mdl';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';
import { last } from 'rxjs/operators';

/**
 * This is the command to create a new project
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class CreateProject extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {
            id: true,
            name: true,
            createdTime: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected dataStore: DataStore,
                 protected modelSubManager: ModelSubscriptionManager,
                 protected state: StateService<any, any> ) {
    super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.data = !this.data ? {} : this.data;
        this.data = {
            id: get( this.data, 'id', Random.projectId()),
            name: get( this.data, 'name', 'Untitled Folder' ),
            listUnderOrg: get( this.data, 'listUnderOrg', false ),
            createdTime: Date.now(),
        };
    }

    public execute(): Observable<any> {
        return this.dataStore.insert( ProjectModel, this.data );
    }

    public executeResult( response: any ): Observable<any> {
        this.modelSubManager.start( ProjectSub, this.data.id )
            .pipe( last())
            .subscribe(() => {
                this.state.set( 'FolderPanelDataChangeEvent', {
                    id: response.model.id,
                    type: 'project',
                    action: 'create',
                    payload: response.model,
                });
            });
        const projectMap = this.state.get( 'ProjectMap' );
        projectMap[ this.data.id ] = response.model;
        this.state.set( 'ProjectMap', projectMap );
        return EMPTY;
    }

    /**
     * This function will update the newly created project model with
     * current user as collaborator and execute 'executeResult' function.
     */
    public executeNetworkOffline(): Observable<any> {
        // NOTE: Since the project creation did not update in the server,
        // we are updating the collabs over here. This is required to filter
        // the projects in project-locator-> getProjects method.
        return this.dataStore.update( ProjectModel, { id: this.data.id },
            {
                $set: { collabs: [{ id: this.state.get( 'CurrentUser' ), role: CollaboratorType.OWNER }]},
            });
    }

    public revert(): Observable<any> {
        return this.dataStore.remove( ProjectModel, { id : this.data.id });
    }

    public onError( error: CommandResultError ) {
        if ( error.code &&
            ( error.code === 1624 || error.code === 1608 )) {
            throw error;
        }
        /* istanbul ignore next */
        super.onError( error );
    }
}

Object.defineProperty( CreateProject, 'name', {
  value: 'CreateProject',
});
