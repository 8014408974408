import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command, StateService } from 'flux-core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';
import { FolderPanelCategories, TeamShareModel } from 'flux-diagram/models';

/**
 * This is the command which changes the teamShare of the project and send the
 * request to the server
 *
 * data: {
 *     teamShare: {
 *          id: string,
 *          teamId: string,
 *          role: CollaboratorType,
 *          delete?: boolean = false,
 *      }
 * }
 */
@Injectable()
@Command()
export class ChangeProjectTeamShare extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            teamShare: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected dataStore: DataStore, protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.getAllTeamShare( this.resourceId ).pipe(
            tap( teamShares => this.previousData.teamShares = teamShares ),
        );
    }

    /**
     * execute
     * change project privacy
     */
    public execute(): Observable<any> {

        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        const teamShare = this.data.teamShare;
        if ( teamShare.delete ) {
            this.updateFolderPanel( teamShare, true );
            return modelStore.storageRemoveTeamShare( this.resourceId, teamShare.id );
        }
        this.updateFolderPanel( teamShare, false );
        return modelStore.storageUpdateTeamShare( this.resourceId, teamShare.id, teamShare.teamId,
                                                            teamShare.role, teamShare.discoverable, false );
    }

    /**
     * executeResult
     * Update the privacy deatils with the response
     */
    public executeResult( response: any ): Observable<any> {

        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        if ( response.teamShare.delete ) {
            return modelStore.storageRemoveTeamShare( this.resourceId, response.teamShare.id );
        }
        return modelStore
            .storageUpdateTeamShare( this.resourceId, response.teamShare.id, response.teamShare.teamId,
                                        response.teamShare.role, response.teamShare.discoverable, false );
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore ;
        const teamShare = this.previousData.teamShares?.find( ts => ts.id === this.data.teamShare.id );
        if ( !teamShare?.id ) {
            this.updateFolderPanel( this.data.teamShare, true );
            return modelStore.storageRemoveTeamShare( this.resourceId, this.data.teamShare.id );
        }
        this.updateFolderPanel( teamShare, false );
        return modelStore.storageUpdateTeamShare( this.resourceId, teamShare.id,
                            teamShare.teamId, teamShare.role, teamShare.discoverable, false );
    }

    protected updateFolderPanel( teamShare: TeamShareModel, remove: boolean = false ) {
        if ( teamShare.teamId !== TeamShareModel.ALL ) {
            this.state.set( 'FolderPanelDataChangeEvent', {
                id: this.resourceId,
                type: 'project',
                action: 'move',
                payload: {
                    teamShare,
                    folderPanelCategories:
                        remove ? FolderPanelCategories.YOUR_FOLDERS : teamShare.teamId,
                },
            });
        }
    }

}

Object.defineProperty( ChangeProjectTeamShare, 'name', {
    value: 'ChangeProjectTeamShare',
});
