import { ITextParser } from 'flux-definition';
import { filter, startsWith } from 'lodash';

class DatabaseIndexParser implements ITextParser<any> {
    static id = 'creately.database.index';

    public static get instance(): DatabaseIndexParser {
        return this._instance;
    }

    private static _instance = new DatabaseIndexParser();

    parse( input: string ) {
        const trimmed = input.trim();
        // TODO: add to errors if input notan attribute name
        const parseErrors = [];
        const parsedText = trimmed.replace( /[<>?]/gm, '' );
        return {
            ast: {
                indexText: parsedText,
                // TODO: get attribute id
                indexAttributeItemId: null,
            },
            lexResult: {},
            parseErrors: parseErrors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        // TODO get dynamic suggestions from parser!
        if ( !context.shapeModel ) {
            return {
                suggestions: [],
                searchTerm: '',
            };
        }
        const shapeModel =  context.shapeModel;
        let suggestions = Object.values( shapeModel.data.attributes.value )
            .map(( attr: any ) => attr.value.name.value );
        if ( text.length > 0 ) {
            suggestions = filter( suggestions, currSuggestion =>
                startsWith( currSuggestion.toLowerCase(), text.toLowerCase()));
        }
        return {
            suggestions: suggestions,
            searchTerm: text,
        };
    }
}

export default DatabaseIndexParser;
