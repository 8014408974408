
/**
 * class Matrix2D
 * This is a one to one implementation of EaseJS Matrix2D class. This class implements all
 * matrix functionality provided by EaseJS. For more details look at the EaselJS documentaton
 * https://www.createjs.com/docs/easeljs/classes/Matrix2D.html
 *
 * This implemntation is temporary and may be replaced by the original EaselJS Matrix2D implementation
 * at any time. This implementation is done to fix runtime errors on a NodeJS environment. Unit tests
 * of this class closely monitor functionality with the EaselJS implementation so, if there are any changes
 * to the EaselJS Matrix2D they will be reflected during unit tests. If you see any unit test failures, you can
 * safely assume that that are changes to EaselJS Matrix2D.
 */
export class Matrix2D {

    /**
     * a property of the matrix
     */
    public a: number;

    /**
     * b property of the matrix
     */
    public b: number;

    /**
     * c property of the matrix
     */
    public c: number;

    /**
     * d property of the matrix
     */
    public d: number;

    /**
     * tx peroperty of the matrix
     */
    public tx: number;

    /**
     * ty property of the matrix
     */
    public ty: number;

    /**
     * Constant value to convert degree values to radians
     */
    private readonly degreeToRadians: number = Math.PI / 180;

    /**
     * Populate new Matrix2D instance with given matrix values.
     *
     * @param a Specifies the a property for the new matrix, defaults to 1 if not specified
     * @param b Specifies the b property for the new matrix, defaults to 0 if not specified
     * @param c Specifies the c property for the new matrix, defaults to 0 if not specified
     * @param d Specifies the d property for the new matrix, defaults to 1 if not specified
     * @param tx Specifies the tx property for the new matrix, defaults to 0 if not specified
     * @param ty Specifies the ty property for the new matrix, defaults to 0 if not specified
     */
    public constructor( a?: number, b?: number, c?: number, d?: number, tx?: number, ty?: number ) {
        this.setValues( a, b, c, d, tx, ty );
    }

    /**
     * Append given matrix to the current matrix. This is equivalent to multiplying
     * ( current matrix ) * ( given matrix )
     * @param a
     * @param b
     * @param c
     * @param d
     * @param tx
     * @param ty
     */
    public append( a: number, b: number, c: number, d: number, tx: number, ty: number ): Matrix2D {
        const a1 = this.a;
        const b1 = this.b;
        const c1 = this.c;
        const d1 = this.d;
        if ( a !== 1 || b !== 0 || c !== 0 || d !== 1 ) {
            this.a  = a1 * a + c1 * b;
            this.b  = b1 * a + d1 * b;
            this.c  = a1 * c + c1 * d;
            this.d  = b1 * c + d1 * d;
        }
        this.tx = a1 * tx + c1 * ty + this.tx;
        this.ty = b1 * tx + d1 * ty + this.ty;
        return this;
    }

    /**
     * Prepend given matrix to the current matrix. This is equivalent to multiplying
     * ( given matrix ) * ( current matrix )
     * @param a
     * @param b
     * @param c
     * @param d
     * @param tx
     * @param ty
     */
    public prepend( a: number, b: number, c: number, d: number, tx: number, ty: number ): Matrix2D {
        const a1 = this.a;
        const c1 = this.c;
        const tx1 = this.tx;

        this.a  = a * a1 + c * this.b;
        this.b  = b * a1 + d * this.b;
        this.c  = a * c1 + c * this.d;
        this.d  = b * c1 + d * this.d;
        this.tx = a * tx1 + c * this.ty + tx;
        this.ty = b * tx1 + d * this.ty + ty;
        return this;
    }

    /**
     * Appends the specified matrix to this matrix
     * @param matrix Matrix2D instance
     */
    public appendMatrix( matrix: Matrix2D ): Matrix2D {
        return this.append( matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty );
    }

    /**
     * Prepends the specified matrix to this matrix
     * @param matrix Matrix2D instance
     */
    public prependMatrix( matrix: Matrix2D ): Matrix2D {
        return this.prepend( matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty );
    }

    /**
     * Generates matrix properties from the specified display object transform properties,
     * and appends them to this matrix
     * @param x
     * @param y
     * @param scaleX
     * @param scaleY
     * @param rotation
     * @param skewX
     * @param skewY
     * @param regX
     * @param regY
     */
    public appendTransform(
        x: number,
        y: number,
        scaleX: number,
        scaleY: number,
        rotation: number,
        skewX?: number,
        skewY?: number,
        regX?: number,
        regY?: number,
    ): Matrix2D {
        let cos = 1;
        let sin = 0;
        const r = rotation * this.degreeToRadians;

        if ( rotation % 360 ) {
            cos = Math.cos( r );
            sin = Math.sin( r );
        }

        if ( skewX || skewY ) {
            skewX *= this.degreeToRadians;
            skewY *= this.degreeToRadians;
            this.append( Math.cos( skewY ), Math.sin( skewY ), -Math.sin( skewX ), Math.cos( skewX ), x, y );
            this.append( cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, 0, 0 );
        } else {
            this.append( cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, x, y );
        }

        if ( regX || regY ) {
            this.tx -= regX * this.a + regY * this.c;
            this.ty -= regX * this.b + regY * this.d;
        }
        return this;
    }

    /**
     * Generates matrix properties from the specified display object transform properties,
     * and prepends them to this matrix
     * @param x
     * @param y
     * @param scaleX
     * @param scaleY
     * @param rotation
     * @param skewX
     * @param skewY
     * @param regX
     * @param regY
     */
    public prependTransform(
        x: number,
        y: number,
        scaleX: number,
        scaleY: number,
        rotation: number,
        skewX?: number,
        skewY?: number,
        regX?: number,
        regY?: number,
    ): Matrix2D {
        let cos = 1;
        let sin = 0;
        const r = rotation * this.degreeToRadians;
        if ( rotation % 360 ) {
            cos = Math.cos( r );
            sin = Math.sin( r );
        }

        if ( regX || regY ) {
            this.tx -= regX; this.ty -= regY;
        }
        if ( skewX || skewY ) {
            skewX *= this.degreeToRadians;
            skewY *= this.degreeToRadians;
            this.prepend( cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, 0, 0 );
            this.prepend( Math.cos( skewY ), Math.sin( skewY ), -Math.sin( skewX ), Math.cos( skewX ), x, y );
        } else {
            this.prepend( cos * scaleX, sin * scaleX, -sin * scaleY, cos * scaleY, x, y );
        }
        return this;
    }

    /**
     * Applies a clockwise rotation transformation to the matrix
     * @param angle
     */
    public rotate( angle: number ): Matrix2D {
        angle = angle * this.degreeToRadians;
        const cos = Math.cos( angle );
        const sin = Math.sin( angle );

        const a1 = this.a;
        const b1 = this.b;

        this.a = a1 * cos + this.c * sin;
        this.b = b1 * cos + this.d * sin;
        this.c = -a1 * sin + this.c * cos;
        this.d = -b1 * sin + this.d * cos;
        return this;
    }

    /**
     * Applies a skew transformation to the matrix
     * @param skewX
     * @param skewY
     */
    public skew( skewX: number, skewY: number ): Matrix2D {
        skewX = skewX * this.degreeToRadians;
        skewY = skewY * this.degreeToRadians;
        this.append( Math.cos( skewY ), Math.sin( skewY ), -Math.sin( skewX ), Math.cos( skewX ), 0, 0 );
        return this;
    }

    /**
     * Applies a scale transformation to the matrix
     * @param x
     * @param y
     */
    public scale( x: number, y: number ): Matrix2D {
        this.a *= x;
        this.b *= x;
        this.c *= y;
        this.d *= y;
        return this;
    }

    /**
     * Translates the matrix on the x and y axes
     * @param x
     * @param y
     */
    public translate( x: number, y: number ): Matrix2D {
        this.tx += this.a * x + this.c * y;
        this.ty += this.b * x + this.d * y;
        return this;
    }

    /**
     * Sets the properties of the matrix to those of an identity matrix (one that applies a null transformation)
     */
    public identity(): Matrix2D {
        this.a = this.d = 1;
        this.b = this.c = this.tx = this.ty = 0;
        return this;
    }

    /**
     * Inverts the matrix, causing it to perform the opposite transformation
     */
    public invert(): Matrix2D {
        const a1 = this.a;
        const b1 = this.b;
        const c1 = this.c;
        const d1 = this.d;
        const tx1 = this.tx;
        const n = a1 * d1 - b1 * c1;

        this.a = d1 / n;
        this.b = -b1 / n;
        this.c = -c1 / n;
        this.d = a1 / n;
        this.tx = ( c1 * this.ty - d1 * tx1 ) / n;
        this.ty = -( a1 * this.ty - b1 * tx1 ) / n;
        return this;
    }

    /**
     * Returns true if the matrix is an identity matrix
     */
    public isIdentity(): boolean {
        return this.tx === 0 && this.ty === 0 && this.a === 1 && this.b === 0 && this.c === 0 && this.d === 1;
    }

    /**
     * Returns true if this matrix is equal to the specified matrix (all property values are equal)
     * @param matrix
     */
    public equals( matrix: Matrix2D ): boolean {
        return this.tx === matrix.tx &&
            this.ty === matrix.ty &&
            this.a === matrix.a &&
            this.b === matrix.b &&
            this.c === matrix.c &&
            this.d === matrix.d;
    }

    /**
     * Transforms a point according to current matrix
     * @param x The x component of the point to transform
     * @param y The y component of the point to transform
     * @param pt 2D point object to copy the result
     */
    public transformPoint( x: number, y: number, pt?: any ): any {
        pt = pt || { x: 0, y: 0 };
        pt.x = x * this.a + y * this.c + this.tx;
        pt.y = x * this.b + y * this.d + this.ty;
        return pt;
    }
    /**
     * Decomposes the matrix into transform properties (x, y, scaleX, scaleY, and rotation).
     * Note that these values may not match the transform properties you used to generate the matrix,
     * though they will produce the same visual results.
     *
     * IMPORTENT :- This decomposition assumes that there is no skew added to the matrix. If skew is
     * required then look at the "decompose" method of
     * https://github.com/CreateJS/EaselJS/blob/master/src/easeljs/geom/Matrix2D.js. It has some issues
     * when the scale is negative and it should be fixed before use it.
     * @param target object to apply transformation properties
     */
    public decompose( target?: any ): any {
        if ( !target ) {
            target = {};
        }

        target.x = this.tx;
        target.y = this.ty;
        target.angle = target.angle || 0;

        const skewX = Math.atan2( -this.c, this.d );
        const skewY = Math.atan2( this.b, this.a );

        target.angle = Math.atan2( this.b, this.a ) / this.degreeToRadians;

        const delta = Math.abs( 1 - skewX / skewY );
        if ( delta < 0.00001 ) { // effectively identical, can use rotation:
            target.angle = skewY / this.degreeToRadians;
            /* istanbul ignore if */
            if ( this.a < 0 && this.d >= 0 ) {
                target.angle += ( target.angle <= 0 ) ? 180 : -180;
            }
            target.skewX = target.skewY = 0;
        }
        target.scaleX = Math.sign( this.a * ( Math.abs( target.angle ) > 90 ? -1 : 1 ))
                                        * Math.sqrt( this.a * this.a + this.b * this.b );
        target.scaleY = Math.sign( this.d * ( Math.abs( target.angle ) > 90 ? -1 : 1 ))
                                        * Math.sqrt( this.c * this.c + this.d * this.d );


        // Note
        // Veriticale flip can be
        // 1. scaleX > 0 , scaleY < 0, angle = 180
        // 2. scaleX < 0 , scaleY > 0, angle = 0
        // we rearrange #1 into #2 because the sign of scaleX is important for some calculations
        if ( target.angle === Math.abs( 180 ) && target.scaleX > 0 && target.scaleY < 0 ) {
            target.angle = 0;
            target.scaleX = -target.scaleX;
            target.scaleY = -target.scaleY;
        }

        target.skewX = 0;
        target.skewY = 0;
        return target;
    }

    /**
     * Copies all properties from the specified matrix to this matrix
     * @param matrix
     */
    public copy( matrix: Matrix2D ): Matrix2D {
        return this.setValues( matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty );
    }
    /**
     * Returns a clone of this Matrix2D instance
     */
    public clone(): Matrix2D {
        return new Matrix2D( this.a, this.b, this.c, this.d, this.tx, this.ty );
    }

    /**
     * Sets given Matrix2D values to this instance
     * @param a Specifies the a property for the new matrix, defaults to 1 if not specified
     * @param b Specifies the b property for the new matrix, defaults to 0 if not specified
     * @param c Specifies the c property for the new matrix, defaults to 0 if not specified
     * @param d Specifies the d property for the new matrix, defaults to 1 if not specified
     * @param tx Specifies the tx property for the new matrix, defaults to 0 if not specified
     * @param ty Specifies the ty property for the new matrix, defaults to 0 if not specified
     */
    public setValues( a: number = 1, b: number = 0, c: number = 0, d: number = 1, tx: number = 0, ty: number = 0 ) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.tx = tx;
        this.ty = ty;
        return this;
    }
}
