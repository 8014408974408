/**
 * Lists of all privacy levels a diagram/project can have
 */
export enum PrivacyLevel {

    /**
     * It can be only access by the people who are already added as collaborator
     */
    SPECIFIC = 0,

    /**
     * It is only accessible to team users who has the link.
     */
    ANYONE_IN_TEAM_WITH_LINK = 1,

    /**
     * It can be explored by anyone in the team.
     */
    ANYONE_IN_TEAM = 2,

    /**
     * This is also an be accessed by anyone. but the link is not publicly available.
     */
    ANYONE_WITH_LINK = 3,

    /**
     * Used to identify public resource which is publicly available to explore.
     */
    PUBLIC = 4,

}
