import 'reflect-metadata';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isObject, isFunction, isArray, isEmpty, each, extend } from 'lodash';
import { JsonMix } from '@creately/jsonmix';

/**
 * @ComplexType Decorator (Reflect)
 * This is a decorator that must be added to properties of complex types that
 * should be deserialized by {@link Deserializer}. Properties that are typed
 * by any TypeScript classes must have this or the deserializer will ignore it.
 *
 * If you are using a collection type with a generic, you need to pass the generic type
 * into this decorator for it to work. You always have the option of passing it but
 * for generics its a must. See examples below.
 *
 * Standard property with a complex type Foo.
 *
 *          @ComplexType()
 *          public bar: Foo;
 *
 * Generic collection example property with complex type Foo.
 *
 *          @ComplexType( Foo )
 *          public bar: Array<Foo>;
 *
 *  Generic map example property with complex type Foo.
 *
 *          @ComplexType( { '*': Foo } )
 *          public bar: { [name: string]: Foo};
 *
 */
export function ComplexType( type?: any ): PropertyDecorator { // tslint:disable-line:only-arrow-functions
    // tslint:disable-next-line:cyclomatic-complexity
    return ( target: any, key: string ) => {
        const definedType = Reflect.getMetadata( 'design:type', target, key );

        if ( definedType === Number || definedType === String || definedType === Boolean ) {
            return;
        }
        if ( definedType === Array && !type ) {
            return;
        }
        if ( !target.hasOwnProperty( 'deserializableProperties' )) {
            let dp = {};
            if ( target.deserializableProperties ) {
                dp = Object.assign({}, target.deserializableProperties );
            }
            target.deserializableProperties = dp;
        }
        if ( definedType === Object && typeof( type ) === 'object' ) {
            Object.keys( type ).forEach( k => {
                target.deserializableProperties[ key + '.' + k ] = type[ k ];
            });
        } else {
            if ( !type ) {
                type = definedType;
            }
            target.deserializableProperties[ key ] = type;
        }
    };
}

/**
 * A type which describes how factory functions should be defined.
 */
export type Factory<T> =
    (( data: any ) => T ) |
    (( data: any ) => Promise<T> ) |
    (( data: any ) => Observable<T> );

/**
 * @ComplexTypeFactory Decorator (Reflect)
 * This is a decorator that must be added to properties of complex types that
 * should be deserialized by {@link Deserializer} using a factory function.
 *
 *          @ComplexTypeFactory( data => ModelFactory.build( data ) )
 *          public bar: Foo;
 *
 */
// tslint:disable-next-line:only-arrow-functions
export function ComplexTypeFactory<T>( factoryOrMap: Factory<T> | { [prop: string]: Factory<T> }): PropertyDecorator {
    return ( target: any, key: string ) => {
        if ( !target.hasOwnProperty( 'deserializableProperties' )) {
            let dp = {};
            if ( target.deserializableProperties ) {
                dp = Object.assign({}, target.deserializableProperties );
            }
            target.deserializableProperties = dp;
        }
        if ( typeof( factoryOrMap ) === 'object' ) {
            Object.keys( factoryOrMap ).forEach( k => {
                target.deserializableProperties[ key + '.' + k ] = { factory: factoryOrMap[k] };
            });
        } else {
            target.deserializableProperties[ key ] = { factory: factoryOrMap };
        }
    };
}

/**
 * Deserializer
 * A Json deserializer that can translate to a given class or set of classes.
 * Uses jsonmix {@link https://github.com/khayll/jsonmix} javascript library. Extends
 * capabilities of said library. See more on the link.
 *
 * This uses the {@link ComplexType} decorator to enable annotating complex typed
 * fields to mark them for deserializing.
 *
 * @author  hiraash
 * @since   2016-04-29
 */
export class Deserializer {

    /**
     * Creates a deserializer with the given data
     * @param data Any data that can be deserialized
     */
    public static create( data: any, model: any = null ): Deserializer {
        return new Deserializer( data, model );
    }

    public static build( type: any, data: any, model: any = null ): Observable<any> {
        if ( model ) {
            // FIXME: temporary fix, deserializer should return the same instance instead.
            return Deserializer.create({ value: data }, { value: model })
                .structure({ value: type })
                .build()
                .pipe( map( obj => obj.value ));
        }
        return Deserializer.create( data )
            .structure( type )
            .build();
    }

    /**
     * The jsonmix instance
     */
    private jsonmix: JsonMix;

    /**
     * Data that has to be deserialized
     */
    private data: any;

    /**
     * Model which is already deserialized
     */
    private model: any;

    /**
     * Flag to identify if we wrapped the data into an array to
     * work around jsonmix functionality. This only happens if
     * the data was a sinlge object that needs to deserialize to a
     * single instance of a type.
     */
    private dataWrappedInArray: boolean = false;

    constructor( data: any, model: any = null ) {
        if ( !data ) {
            throw new Error( 'Cannot create Deserializer without valid data' );
        }
        this.data = data;
        this.model = model;
    }

    /**
     * This is the most important method in this tool.
     *
     * This can take in a single type Foo which will deserialize the
     * given data to an instance of Foo or an array of Foo. All properties
     * in Foo that are annotated with @ComplexType will also be deserialized
     * recursively deep. This is the standard case.
     *
     * You can also pass in a JS object that has the 'path' as the key and
     * value as the 'type' that needs to be used for deserialization. This is
     * in the case of the data needs to be deserialized to a non standard
     * structure but has properties that are complex typed. For explaination
     * on path see the jsonmix doc - https://github.com/khayll/jsonmix
     * Example
     * {
     *      'user': Foo,
     *      'face': Bar
     * }
     *
     * @param   struct  Either a class or a object based on above structure.
     */
    public structure( struct: any ): Deserializer {
        if ( isObject( struct )) {
            if ( isFunction( struct )) {
                // This means its a class.
                this.with( struct );
                this.registerPropertyTypes( struct, '*.' );
            } else {
                // This means its a template.
                this.decodeTemplate( struct );
            }
        } else {
            throw new Error( 'Deserializer: Unknown structure format. Please refer the documentation.' );
        }
        return this;
    }

    /**
     * This is an alternative to the `structure` method where the root
     * model itself needs to be created using a factory.
     */
    public factory( struct: any, factory: Function ): Deserializer {
        if ( isFunction( struct )) {
            this.with({ factory: factory });
            this.registerPropertyTypes( struct, '*.' );
        } else if ( isObject( struct )) {
            throw new Error( 'Deserializer: Factory deserializing with templates is not supported.' );
        } else {
            throw new Error( 'Deserializer: Unknown structure format. Please refer the documentation.' );
        }
        return this;
    }

    /**
     * This is used to deserialize the hashmap value.
     * This deserializes the each mapped value into a given instance of type.
     *
     * All properties in given type that are annotated with @ComplexType will also be deserialized
     * recursively deep.
     *
     * @param   type    The class of the object to be mapped onto the data
     * @returns Deserializer
     */
    public asMap( type: Function ): Deserializer {
        Object.keys( this.data ).forEach( key => {
            this.with( type, key );
            this.registerPropertyTypes( type, key );
        });

        return this;
    }

    /**
     * Method to map object prototype with a path in the data object
     * @param {type} The class of the object to be mapped onto the data
     * @param {string} path to where the data objects are. Example: employees/*
     */
    public with( type: any, path: string = '*' ): Deserializer {
        if ( !this.jsonmix ) {
            if ( !isArray( this.data ) && path === '*' ) {
                this.data = [ this.data ];
                this.dataWrappedInArray = true;
            }
            this.createJsonMix();
        }
        this.jsonmix.withObject( type, path );
        return this;
    }

    /**
     * Deserializes as per set specifics through with method and
     * returns the mixed object.
     */
    public build(): Observable<any> {
        return from( this.jsonmix.build()).pipe(
            map( result => {
                if ( this.dataWrappedInArray && isArray( result ) && result.length === 1 ) {
                    return result.pop();
                }
                return result;
            }));
    }

    /**
     * Creates the jsonmix instance with the data available
     * and sets it to a local property.
     */
    private createJsonMix() {
        this.jsonmix = new JsonMix( this.data, this.model );
    }

    /**
     * Applies a structure to the jsonmix for deserialization.
     * This is what is passed to the structure method.
     * @param template A object as described in the structure method
     */
    private decodeTemplate( template: {}) {
        each( template, ( type: any, path: string ) => {
            this.with( type, path );
            this.registerPropertyTypes( type, `${path}.` );
        });
    }

    /**
     * This method looks in a class for annotated complex types
     * that have to be deserialized. This recursively searches
     * for all stored annotations as such and registers all
     * complex type properties with jsonmix for deserialization
     *
     * @param {type} The class of the object to be mapped onto the data
     * @param {string} path to where the data objects are. Example: employees/*
     */
    private registerPropertyTypes( type: any, property: string ) {
        const properties = this.findDeserializableProperties( type.prototype );

        if ( properties && !isEmpty( properties )) {
            each( properties, ( childType: any, key: string ) => {
                const path: string = property + key;
                this.with( childType, path );
                this.registerPropertyTypes( childType, `${path}.` );
            });
        }
    }
    /**
     * This method looks in a class for annotated complex types
     * that have to be deserialized. This recursively searches
     * for all stored annotations as such and combines all available
     * annotations into a single object.
     *
     * @param   object  The class to look in.
     */
    private findDeserializableProperties( object: any ) {
        if ( !object ) {
            return {};
        }
        let properties = {};
        if ( object.deserializableProperties ) {
            properties = object.deserializableProperties;
        }
        extend( properties, this.findDeserializableProperties( object.__proto__ ));
        return properties;
    }

}
