import { Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
/**
 * Toggle button component that can be used to turn a feature on or off.
 * The component is based on ui component ui-switch.
 * https://github.com/webcat12345/ngx-ui-switch
 *
 * @author nuwanthi
 * @since 2017-17-06
 */

@Component({
    template: `
        <div class="toggle-button-item fx-center-vertical">
            <ui-switch (change)="change.next( $event )"
                [disabled]="disabled"
                [checked]="checked"
                [size]="size"
                color= "#08436B">
            </ui-switch>
            <label class="fx-flex-expand body" [class.fx-fade-out]="disabled">{{label}}</label>
        </div>
    `,
    selector: 'toggle-button',
    styleUrls: [ './toggle-button.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ToggleButton {

    /**
     * Subject to output the change event in ui-switch
     */
    @Output()
    public change: Subject<boolean> = new Subject();

    /**
     * Label describing the toggle button.
     */
    @Input() public label: string;

    /**
     * Toggle button size. Can be small or large.
     */
    @Input() public size: string = 'small';

    /**
     * Specify if the toggle button is disabled.
     */
    @Input() public disabled: boolean = false;

    /**
     * Specify if the toggle button is checked.
     */
    @Input() public checked: boolean = false;
}
