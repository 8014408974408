import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { AbstractCommand, Command, ModalController } from 'flux-core';
import { Observable } from 'rxjs';
import { FileImporter } from '../../../base/view/import/file-importer.cmp';
import { switchMap } from 'rxjs/operators';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { IAbstractDefinition } from 'flux-definition';

/**
 * This command embeds an and imported image into a supporting shape.
 */
@Injectable()
@Command()
export class ShowUploadWindow extends AbstractCommand {
    constructor(
        private modals: ModalController,
        private translate: TranslateService,
        protected ll: DiagramLocatorLocator,
        protected defLocator: DefinitionLocator,
    ) {
        super()/* istanbul ignore next */;
    }

    public get locator() {
        return this.ll.forCurrent( false );
    }

    protected get allowedFileTypes() {
        return this.data && this.data.allowedFileTypes !== undefined ? this.data.allowedFileTypes : [
            '.jpg', '.jpeg', '.bmp', '.gif',
            '.ico', '.svg', '.png',
        ];
    }

    protected get maxFileSize() {
        return this.data && this.data.maxFileSize ? this.data.maxFileSize : 5000000;
    }

    /**
     * Prepare command data
     */
    public prepareData() {
        if ( this.data && this.data.position && this.data.position.shapeId ) {
            return this.locator.getShapeOnce( this.data.position.shapeId ).pipe(
                switchMap(( shape: ShapeModel ) => this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
                    switchMap( shapeDef  => this.showWindow( shape, shapeDef )),
                )),
            );
        } else {
            return this.showWindow( null, null  );
        }
    }

    public execute() {
        return this.resultData && this.resultData.files && this.resultData.files.length;
    }

    /**
     * Show the image upload window
     * @param shape shape model
     * @param shapeDef shape definition
     */
    private showWindow( shape: ShapeModel, shapeDef: IAbstractDefinition ) {
        return Observable.create( observer => {
            const completeHandler = files => {
                this.resultData = { files };
                observer.complete();
            };
            const closehandler = () => {
                observer.complete();
            };
            this.modals.show(
                FileImporter,
                {
                    inputs: {
                        ...this.getImportOptions(),
                        shapeImage: this.getShapeImage( shape, shapeDef ),
                        onComplete: completeHandler,
                        onClose: closehandler,
                    },
                },
            );
        });
    }

    private getImportOptions() {
        return {
            importOptions: {
                allowMultipleUploads: false,
                restrictions: {
                    maxFileSize: this.maxFileSize,
                    maxNumberOfFiles : 1,
                    minNumberOfFiles : 1,
                    allowedFileTypes : this.allowedFileTypes,
                },
            },
            uiOptions: {
                note : this.translate.instant( 'FILE_IMPORTS.FILE_MAX_MSG' ),
            },
        };
    }

    /**
     * Using shape model image and shape definition image
     * check current shape model has default image. If not,
     * return shape model image details else return null
     * @param shape shape model
     * @param shapeDef shape definition
     */
    private getShapeImage( shape: ShapeModel, shapeDef: IAbstractDefinition ) {
        if ( !shape ) {
            return null;
        }
        const defImage = shapeDef.images && shapeDef.images[this.data.position.imageId];
        const shapeImage = shape.images && shape.images[this.data.position.imageId];
        if ( defImage && shapeImage && defImage.file !== shapeImage.file ) {
            return {
                shapeId: shape.id,
                imageId: this.data.position.imageId,
                hash: shapeImage.file,
                defaultHash: defImage.file,
                removed: false,
            };
        } else {
            return null;
        }
    }

}

// NOTE: class names are lost on minification
Object.defineProperty( ShowUploadWindow, 'name', {
    value: 'ShowUploadWindow',
});
