import { Subject } from 'rxjs';

/**
 * Types of Navs available
 */
export enum NavItemType {
    ShapeLink = 'shapeLink',
    DiagramLink = 'diagramLink',
    FolderLink = 'folderLink',
    URL = 'url',
    Action = 'action',
    Header = 'header',
}


export interface INavItem {

    /**
     * random ID for this
     */
    id: string;

    /**
     * Item icon
     */
    icon?: string;

    /**
     * Displayable names
     */
    title: string;

    subtext?: string;

    /**
     * The type of the nav item.
     */
    type: NavItemType;

    /**
     * Folder to navigate to
     */
    folderId?: string;

    /**
     * DiagramID to navigate to
     */
    diagId?: string;

    /**
     * Shape ID to select in the given diagramID
     */
    shapeId?: string;

    /**
     * What is the mode to show the nav in (view, edit, present)
     * if this is not inclued, diag/shape navigation will happen in the current mode.
     */
    mode?: string;


    /**
     * Navigate to a specific URL?
     */
    url?: string;


    /**
     * reference to an action to trigger
     */
    action?: any;

    /**
     * This holds any additional data of the item
     */
    data?: any;

    /**
     * Any child components the library item may have
     */
    children?: any;

    isClosed?: Subject<boolean>;


    /**
     * parent item ID
     */
    parentId?: string;

    childId?: string;

    itemId?: string;

    indent?: number;


    /**
     * The high level group for this item
     * ex. Class or usecase inside the UML models
     */
    grouping?: string;

    /**
     * Does it have any refChanges
     */
    refChangeCount?: number;

    /**
     * If this item is selected
     */
    selected?: boolean;


}
