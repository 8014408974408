import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';
import { RemoveResourceGroupShare } from '../../group-share/command/remove-resource-group-share.cmd';

/**
 * This is the command to remove group shares from diagram.
 *
 * This extends {@link RemoveResourceGroupShare} to send a request to the server.
 *
 */
@Injectable()
@Command()
export class RemoveProjectGroupShare extends RemoveResourceGroupShare  {

    protected getModelStore() {
        return this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
    }
}

Object.defineProperty( RemoveProjectGroupShare, 'name', {
    value: 'RemoveProjectGroupShare',
});
