import { ShapeStyle } from './shape-style';
import { LineStyle } from './line-style';

/**
 * Palettes are styles (ShapeStyle objects) which are
 * grouped together based on similarties. This class defines
 * the properties for a palette.
 *
 * @author
 * @since 2017-11-06
 */

export class Palette {

    /**
     * Palettes are identified by a string name.
     */
    public name: string;

    /**
     * Styles are passed into an array of ShapeStyle.
     * One ShapeStyle object will contain properties such as lineThickness,
     * lineColor, fillStyle, fillColor, fillGradient, lineStyle and text color
     * which together makes up a single style.
     */
    public styles: Array<LineStyle> | Array<ShapeStyle>;

    /**
     * Holds the palette title
     */
    public title: string;

    constructor( name: string, styles: Array<LineStyle> | Array<ShapeStyle>, title?: string ) {
        this.name = name;
        this.styles = styles;
        this.title = title;
    }

    /**
     * Getter to get the palette ID.
     */
    public get paletteId(): string {
        const paletteIdString: string = this.name + JSON.stringify( this.styles );

        return paletteIdString.replace( /['"]+/g, '' );
    }

}
