import { Injectable } from '@angular/core';
import { LAYOUTING_DATA } from '../../../base/shape/model/shape-common';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { Command, Rectangle, Layouting, StateService } from 'flux-core';
import { IRectangle } from 'flux-definition';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

export interface IAlignableShape {
    type: 'shape';
    shapeId: string;
    bounds: Rectangle;
}

export interface IAlignableGroup {
    type: 'group';
    bounds: Rectangle;
    shapeIds: string[];
    shapeBounds: IRectangle[];
}

export type AlignableEntity = IAlignableShape | IAlignableGroup;

/**
 * Aligns shapes on the canvas horizontally or vertically.
 *
 *  data: {
 *      shapeIds: string[],
 *      isVertical: boolean,
 *      alignment: HorizontalAlignment | VerticalAlignment,
 *  }
 *
 */
@Injectable()
@Command()
export class LayoutShapes extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        layoutingId: string,
        bounds: IRectangle,
        containerId: string,
        fixedShapeId: string, // After layouting this shape's original x y should be maintained.
    };

    public constructor(
        ds: DiagramChangeService,
        protected state: StateService<any, any>,
    ) {
        super( ds );
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData() {

        const shapes = this.data.shapeIds.map( id => this.changeModel.shapes[ id ]);

        const origin = this.data.fixedShapeId ? this.changeModel.shapes[ this.data.fixedShapeId ].bounds : undefined;
        // let originX;
        // let originY;
        // if ( origin ) {
        //     originX = origin.x;
        //     originY = origin.y;
        // }

        if ( this.data.containerId && this.changeModel.shapes[ this.data.containerId ]) {
            const innerSelection =  this.state.get( 'InnerShapeSelection' );
            if ( innerSelection && Object.keys( innerSelection[ this.data.containerId ] || {}).length ) {
                ( innerSelection[ this.data.containerId ] || []).forEach( regId => {
                    const reg: any = ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel )
                        .containerRegions[ regId ];
                    if ( this.data.layoutingId !== reg.layoutingId ) {
                        reg.layoutingId = this.data.layoutingId;

                        // layoutingData required in shape logic
                    } else  { // Unset layouting data if the user clickes the same button twice
                        reg.layoutingId = undefined;
                        reg.layoutingData = {
                            algorithm: 'free',
                            source: 'native',
                        };
                    }
                    // Persist changes
                    ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).containerRegions[ regId ] = reg;
                });
            } else {
                const regions = ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).containerRegions;
                const regIds = Object.keys( regions );
                let layoutingId = undefined;
                const common = regIds.map( id => regions[ id ]).every(( reg: any ) =>  {
                    layoutingId = ( regions[ regIds[0]] as any ).layoutingId;
                    return ( regions[ regIds[0]] as any ).layoutingId === reg.layoutingId;
                });
                if ( !common || this.data.layoutingId !== layoutingId ) {
                    let hasRegions = false;
                    Object.values( regions ).forEach(( reg: any ) =>  {
                        hasRegions = true;
                        if ( this.data.layoutingId !== reg.layoutingId ) {
                            reg.layoutingId = this.data.layoutingId;
                            // layoutingData required in shape logic
                            reg.layoutingData = LAYOUTING_DATA[ reg.layoutingId ].layoutingData;
                        }
                    });
                    // Persist changes
                    ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).containerRegions = regions;
                    if ( !hasRegions ) {
                        if (( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).layoutingId
                        === this.data.layoutingId ) {
                            ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).layoutingId
                                = undefined;
                        } else {
                            ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).layoutingId
                                = this.data.layoutingId;
                        }
                    }
                } else { // Unset layouting data if the user clickes the same button twice
                    ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).layoutingId = undefined;
                    Object.values( regions ).forEach(( reg: any ) =>  {
                        if ( this.data.layoutingId === reg.layoutingId ) {
                            reg.layoutingId = undefined;
                            reg.layoutingData = {
                                algorithm: 'free',
                                source: 'native',
                            };
                        }
                    });
                    // Persist changes
                    ( this.changeModel.shapes[ this.data.containerId ] as ShapeModel ).containerRegions = regions;
                }
            }
        }

        if ( !this.data.containerId ) {
            const layout: any = LAYOUTING_DATA[ this.data.layoutingId ];
            return fromPromise( Layouting
                .apply( this.changeModel, shapes as any, this.data.bounds, layout?.layoutingData  )).pipe(
                    tap(() => {
                        if ( origin ) {
                            const originShape = this.changeModel.shapes[ this.data.fixedShapeId ] as ShapeModel;
                            const deltaX = originShape.x - origin.x;
                            const deltaY = originShape.y - origin.y;
                            shapes.forEach(( shape: any ) => {
                                shape.x -= deltaX;
                                shape.y -= deltaY;
                            });
                        }
                    }),
            );
        }
    }

}

Object.defineProperty( LayoutShapes, 'name', {
    value: 'LayoutShapes',
});
