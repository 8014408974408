import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { AbstractModel, ComplexType } from 'flux-core';
import { IEDataDef, IEntityDataItemDef, IEntityDef } from 'flux-definition';
import { EDataRegistry } from '../edata-registry.svc';
import { EntityModel } from './entity.mdl';
import { union, values, isEqual } from 'lodash';
import { EntityListModel } from './entity-list.mdl';
import { DataSourceDomain } from 'apps/nucleus/src/editor/ui/data-sources/model/data-source.model';
import { CollaboratorType } from 'flux-diagram';

export interface IDataSource {
    domain: DataSourceDomain;
}
export interface IDataSourceGoogleSheet extends IDataSource {
    domain: DataSourceDomain.GoogleSheets;
    id: string; // google spreadsheet id
}
export interface IDataSourceM365Excel extends IDataSource {
    domain: DataSourceDomain.M365Excel;
    id: string; // m365 spreadsheet id
}

/**
 * Enum for DI level
 */
export enum DataItemLevel {
    Def, // json def
    CustomDef, // user modified def for the type
    DataDef, // entity only
    Any, // any of the above

    // Def level cannot be overridden.
    // CustomDef can be overridden by DataDef
}


/**
 * A group of entities
 * This can be connected to a document, folder or an org.
 *
 * The source of the entities can be an integration.
 *
 */
@Injectable()
export class EDataModel extends AbstractModel {
    /**
     * DefId for this model
     */
    public defId: string;


    /**
     * List of entities
     */
    @ComplexType({ '*' : EntityModel })
    public entities: { [entityId: string]: EntityModel } = {};


    /**
     * List of entitiesList Models
     */
    @ComplexType({ '*' : EntityListModel })
    public entityLists: { [entityListId: string]: EntityListModel } = {};

    /**
     * Name of this model
     */
    public name: string;


    /**
     * Mapping of entity types, context, to shapes.
     *
     * Type -> 0..n Context -> 1..n Shapes
     *
     * A context is typically a container environment.
     *
     * When adding an entity to a context, nucleus will ask the container what it's context config is
     * and try to match a shapeDef of the entity to match the expectation of the container.
     *
     * Ex. Drop an entity into a UML Sequence container, it should give a lifeline.
     * [type, [ context: [shapeDefId] ] // context can be *
     */
    public typeMap: any = {};


    /**
     * This property is used to store the blueprints of the
     * custom entitites created by the user
     */
    public customEntityDefs: {[entityDefId: string]: IEntityDef } = {};

    /**
     * This map is to keep the common data of data items shared
     * between entities
     */
    public dataDefs: any = {};

    /**
     * external data source bound to this edata model.
     * can be a google spreadsheet, an excel document, a database, etc...
     * ex - dataSource: IDataSourceGoogleSheet | IDataSourceMSExcel | IDataSourceMysql
     */
    public dataSource?: IDataSource;

    /**
     * Last updated timestamp from the source
     */
    public lastUpdatedFromSource?: number;

    public project: string;

    /**
     * This map holds the mappings for external data source per entity type
     */
    public dataSourceMappings?: {
        [eDefId: string]: any;
    } = {};

    public teamAccess?: {
        id: string,
        role: CollaboratorType.EDITOR | CollaboratorType.REVIEWER,
    };


    /**
     * Constructor for the model
     * @param id
     */
    constructor( public id: string ) {
        super( id );
    }

    /**
     * Returns true for custom databases
     */
     public get isCustom(): boolean {
        return EDataRegistry.customEdataDefId === this.defId;
    }

    /**
     * Gets the EDataDef for this entity
     */
    public getDefinition(): IEDataDef {
        return EDataRegistry.instance.getEDataDef( this.defId );
    }

    /**
     * Returns the entity def from the EDataDef when given an entityDefId
     * @param defId
     */
    public getEntityDef( defId: string ): IEntityDef {
        return EDataRegistry.instance.getEntityDefById( defId, this.defId );
    }


    /**
     * gets the id for the entity group
     */
    public getId(): string {
        return this.id;
    }

    /**
     * Id of containing project.
     * @returns
     */
    public getProjectId() {
        return this.project;
    }

    /**
     * Gets an entity
     * @param id
     */
    public getEntity( id: string ): EntityModel {
        return this.entities[id];
    }


    /**
     * Extracts the diagramId's referenced in entities.shapes
     */
    public getUsedDiagIds(): string[] {
        let retArr = [];
        for ( const eId in this.entities ) {
            const diagIds = Object.keys( this.entities[eId].shapes );
            retArr = union( retArr, diagIds );
        }
        return retArr;
    }

    public getEntitiesByDefId( entityDefId: string ): EntityModel[] {
        return values( this.entities ).filter( ent => ent.eDefId === entityDefId );
    }

    public getEntitiesByDefIdAndInDiagram( entityDefId: string, diagId: string ): EntityModel[] {
        return values( this.entities ).filter( ent => ent.eDefId === entityDefId && ent.shapes && ent.shapes[diagId]);
    }

    /**
     * Built the the data items data
     *
     * combine the def, custom def and data def items.
     * @param entityId
     */
    public getEntityDataItems( entityId: string, clone: boolean = true, includeLevel: boolean = false ) {
        const dataItems = {};
        const def = this.getDefinition();
        // core def

        if ( this.entities[ entityId ]) {
            const entity = this.entities[ entityId ];
            const eDef = def.entityDefs [ entity.eDefId ];

            for ( const dataItemId in eDef.dataItems ) {
                dataItems [ dataItemId ] = { ...eDef.dataItems[ dataItemId ] };
                if ( entity.data[ dataItemId ] !== undefined ) {
                    dataItems [ dataItemId ].value = entity.data[ dataItemId ];
                }
                if ( includeLevel ) {
                    dataItems [ dataItemId ].level = DataItemLevel.Def;
                }
            }

            // custom def
            if ( this.customEntityDefs && this.customEntityDefs[ entity.eDefId ]) {
                const currCustomDef = this.customEntityDefs[ entity.eDefId ];
                for ( const dataItemId in currCustomDef.dataItems ) {
                    dataItems [ dataItemId ] = { ...currCustomDef.dataItems[ dataItemId ] };
                    if ( entity.data[ dataItemId ] !== undefined ) {
                        dataItems [ dataItemId ].value = entity.data[ dataItemId ];
                    }
                    if ( includeLevel ) {
                        dataItems [ dataItemId ].level = DataItemLevel.CustomDef;
                    }
                }
            }
        }

        // entity level def
        Object.keys( this.dataDefs[ entityId ] || {}).forEach( dataItemId => {
            if ( this.dataDefs[ entityId ] && this.dataDefs[ entityId ][ dataItemId ]) {
                dataItems[ dataItemId ] = {
                    ...this.dataDefs[ entityId ][ dataItemId ],
                    value: this.entities[ entityId ].data[ dataItemId ],
                };
                if ( includeLevel ) {
                    dataItems [ dataItemId ].level = DataItemLevel.DataDef;
                }
            }
        });

        if ( !clone ) {
            return dataItems;
        }
        return cloneDeep( dataItems );
    }

    /**
     * Updates the dataDefs property
     */
    public updateDataDefs( entityId: string, dataItemId: string, data: any ) {
        if ( data ) {
            const [ level, diDef ] = this.getDataItemLevel( dataItemId, entityId );
            if ( diDef && diDef.label !== data.label ) {
                // we should not update the data def.
                if ( level === DataItemLevel.CustomDef ) {
                    diDef.label = data.label;
                    return;
                } else { // if level === DataItemLevel.DataDef
                    const entity = this.entities[entityId];
                    if ( entity && entity.eDefId && this.customEntityDefs[entity.eDefId]
                        && this.customEntityDefs[entity.eDefId].dataItems.hasOwnProperty( dataItemId )) {
                            this.customEntityDefs[entity.eDefId].dataItems[dataItemId].label = data.label;
                    }
                }
            }

            if ( !this.dataDefs[ entityId ]) {
                this.dataDefs[ entityId ] = {};
            }
            const dataItem = this.dataDefs[ entityId ][ dataItemId ];

            if ( dataItem && !isEqual( dataItem, data )) { // Updating data item
                Object.assign( dataItem, data );

                dataItem.label = data.label;
            } else if ( !dataItem ) { // Add
                this.dataDefs[ entityId ][ dataItemId ] = data;
            }
        } else {
            if ( this.dataDefs[ entityId ] &&  this.dataDefs[ entityId ][ dataItemId ]) {
                delete this.dataDefs[ entityId ][ dataItemId ];
            }
        }
    }

    /**
     * This method filters out the entities created from the types which have been deleted
     * by the user
     */
    public getActiveEntities() {
        if ( !this.isCustom ) {
            return this.entities;
        }
        const entities = {};
        values( this.entities ).forEach(( e: EntityModel ) => {
            if ( !this.customEntityDefs[ e.eDefId ].deleted ) {
                entities[ e.id ] = e;
            }
        });
        return entities;
    }

    public getActiveEntityLists() {
        const entityLists = {};
        Object.values( this.entityLists ).forEach(( e: EntityListModel ) => {
            if ( !e.deleted ) {
                entityLists[ e.id ] = e;
            }
        });
        return entityLists;
    }

    /**
     * This method filters out the deleted custom entity defs
     */
    public getActiveCustomEntityDefs() {
        return values( this.customEntityDefs ).filter(( def: IEntityDef ) => !def.deleted );
    }

    /**
     * getActiveCustomEntityDefIds
     */
    public getActiveCustomEntityDefIds(): string[] {
        return this.getActiveCustomEntityDefs().map(( def: IEntityDef ) => def.id );
    }

    /**
     * Gets what level the dataItem is at.
     * @param dataItemId
     * @param entityId
     */
    public getDataItemLevel( dataItemId: string, entityId: string ): [ DataItemLevel, IEntityDataItemDef ] {
        if ( this.entities[ entityId ]) {
            if ( this.dataDefs[ entityId ] &&
                    this.dataDefs[ entityId ][ dataItemId ]) {
                return [  DataItemLevel.DataDef, this.dataDefs[ entityId ][ dataItemId ] ];
            }
            const entity = this.entities[ entityId ];

            if ( this.customEntityDefs[ entity.eDefId ] &&
                this.customEntityDefs[ entity.eDefId ].dataItems &&
                this.customEntityDefs[ entity.eDefId ].dataItems[ dataItemId ]) {
                    return [ DataItemLevel.CustomDef, this.customEntityDefs[ entity.eDefId ].dataItems[ dataItemId ] ] ;
            }

            const def = this.getDefinition();
            const eDef = def.entityDefs [ entity.eDefId ];
            if ( eDef && eDef.dataItems && eDef.dataItems [ dataItemId ]) {
                return [ DataItemLevel.Def, eDef.dataItems[ dataItemId ] ];
            }
        }
        return [ undefined, undefined ];
    }

    /**
     * Gets a dataItemDef from wherever level it is in this edata model
     * @param dataItemId
     * @param entityId
     * @returns
     */
    public getDataItemDef( dataItemId: string, entityId: string ): IEntityDataItemDef {
        const def = this.getDataItemLevel( dataItemId, entityId )[1];
        // console.log ( ' MRX got def 0 ', def );
        return def;
    }

    /**
     * Add a dataItem to an entity. checks whether it exists at all 3 levels before adding it in
     * @param dataItem
     * @param entityId
     */
    public addDataItemDef( dataItemId: any, entityId: string, dataItem: any ) {
        const def = this.getDataItemDef( dataItemId, entityId );
        // console.log ( ' MRX got def ', def );
        if ( !def ) {
            if ( !this.dataDefs [ entityId ]) {
                this.dataDefs [ entityId ] = {};
            }
            this.dataDefs[ entityId ][ dataItemId ] = dataItem;
        }
    }

    /**
     * CHecks if the EData is shared with the team.
     * @returns
     */
    public isSharedWithTeam(): boolean {
        return !!this.teamAccess?.role;
    }
}

Object.defineProperty( EDataModel, 'name', {
    writable: true,
    value: 'EDataModel',
});
