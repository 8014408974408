import { of, throwError, concat, Observable } from 'rxjs';
import { catchError, last, switchMap } from 'rxjs/operators';
import { CommandInterfaces } from '../abstract.cmd';
import { AbstractHttpCommand, RequestMethod } from '../abstract-http.cmd';
import { CommandHttpError } from '../error/command-http-error';
import { DeserializableExecutionStep } from './exec-step-deserializable';
import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import * as qs from 'qs';

/**
 * HttpExecutionStep is a general purpose execution step which can be used
 * to fetch resources through HTTP. Commands should extend the AbstractHttpCommand
 * to use this execution step. The data property of the command will be
 * used as request body (JSON) or query parameters based on request method.
 *
 * @author thanish
 * @since 2017-03-20
 */
export class HttpExecutionStep extends DeserializableExecutionStep {

    /**
     * relatedInterfaces property returns 'IHttpCommand' so only commands
     * which have this interface set will run this execution step.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }
    /**
     * http is an instance of angular Http service. This
     * will be injected during the process stage of the step.
     */
    private http: HttpClient;

    /**
     * process sends the http request and deserializes the result if possible
     */
    public process(): Observable<any> {
        if ( !( this.command instanceof AbstractHttpCommand )) {
            throw new Error( `Command ${this.command.constructor.name} does not extend AbstractHttpCommand` );
        }
        this.injectServices();
        const request = this.createRequestObject();
        return this.fetchAndDeserialize( request );
    }

    /**
     * injectServices injects services required by this execution step.
     */
    protected injectServices(): void {
        this.http = this.injector.get( HttpClient );
    }

    /**
     * createRequestObject creates a fetch request object from the command
     * instance. Assumes the command instance is an AbstractHttpCommand.
     */
    protected createRequestObject(): HttpRequest<any> {
        const command = this.command as AbstractHttpCommand;
        if ( !command.httpUrl ) {
            throw new Error( `Please set a valid getter for "httpUrl" in ${command.constructor.name}` );
        }
        let body = null;
        const options = {
            params: null,
            headers: command.httpHeaders,
        };
        if ( command.data ) {
            if ( command.httpMethod === RequestMethod.Post ) {
                body = this.populateRequestParam();
            } else {
                const query = qs.stringify( command.data );
                options.params = new HttpParams({ fromString: query });
            }
        }
        return new HttpRequest<any>( command.httpMethod, command.httpUrl, body, options );
    }

    /**
     * Populate the request parameter values
     */
    protected populateRequestParam(): any {
        return this.command.data;
    }

    /**
     * fetchAndDeserialize uses window.fetch to fetch resources from an http url.
     * It also will deserialize the result if a valid resultType is configured.
     * @param request: the request object to use with window.fetch
     */

    protected fetchAndDeserialize( req: HttpRequest<any> ): Observable<any> {
        // http uses rxjs/Obserable thats why the contact operator is used
        return concat( this.http.request( req )).pipe(
            last(),
            switchMap(( res: HttpResponse<any> ) => this.handleResponse( res )),
            catchError( res => this.handleError( req, res )));
    }

    /**
     * handleError handles failed responses from @angular/common/http
     * @param res: The response object returned from @angular/common/http
     */
    protected handleError( req: HttpRequest<any>, res: HttpResponse<any> ): Observable<Error> {
        const error = new CommandHttpError( req, res );
        return throwError( error );
    }

    /**
     * handleResponse handles successful responses from @angular/common/http
     * @param res: The response object returned from @angular/common/http
     * @param options: The options object used with @angular/common/http
     */
    protected handleResponse( res: HttpResponse<any> ): Observable<any> {
        if ( !this.isResultTypeDeserializable()) {
            return of( JSON.stringify( res.body ));
        }
        return this.deserialize( res.body );
    }
}

Object.defineProperty( HttpExecutionStep, 'name', {
    value: 'HttpExecutionStep',
});
