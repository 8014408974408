import { AbstractCommandScenario } from './abstract-command-scenario';
import { CommandScenario } from '../command-scenario.enum';
import { Injectable } from '@angular/core';

/**
 * This command scenario specifies how a command is executed
 * when it's used to perform a regular execute action.
 *
 * For a detiled overview of what command scenarios are and how they
 * affect command execution, please refer to {@link AbstractCommandScenario}.
 *
 * @author  Ramishka
 * @since   2018-08-20
 */
@Injectable()
export class ExecuteScenario extends AbstractCommandScenario {

    protected get scenario(): CommandScenario {
        return CommandScenario.EXECUTE;
    }
}
