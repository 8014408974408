import { Injectable } from '@angular/core';
import { lodashThrottle } from '@creately/rx-lodash';
import { TranslateService } from '@ngx-translate/core';
import { AbstractNotification, CommandService, NotificationType,
    NotifierController, Point, Rectangle, StateService, Tracker } from 'flux-core';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, Subject, timer } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { UserLocator } from '../../../../../libs/flux-user/src';
import { PhotonClient } from '../../../../../libs/photon-client/src';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { InteractionCommandEvent } from '../interaction/command/interaction-command-event';
import { RealtimeConnectionStatus, RealtimeStateService } from './realtime-state.svc';

@Injectable()
export class FollowUserService {

    /**
     * The realtime state key for diagram pans and zooms.
     */
    public static KEY_PAN_ZOOM = 'panZoomState';

    /**
     * The realtime state key for viewport changes.
     */
    public static KEY_VIEWPORT = 'diagramViewport';

    /**
     * The realtime state key for viewport changes.
     */
    public static KEY_FOLLOW = 'followUser';
    public static KEY_SPOTLIGHT = 'spotlight';

    public followers = new BehaviorSubject([]);
    public onSpotlight = new BehaviorSubject( false );
    private zoomThrottle = 120;
    private viewportThrottle = 120;

    private followUserMap = {};

    private onSpotlightRequesting = new BehaviorSubject( '__NONE__' );

    private spotlightRequests = {};
    private removeFollowNotification = null;

    private dismissObsListenSpotlight: Subject<boolean> = new Subject();

    constructor(
        protected realtimeStates: RealtimeStateService,
        protected state: StateService<any, any>,
        protected commandSvc: CommandService,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected userLocator: UserLocator,
    ) {}

    public initialize() {
        this.state.changes( 'RealtimeConnectionStatus' ).pipe(
            filter( connStatus => connStatus === RealtimeConnectionStatus.ONLINE ),
            take( 1 ),
        ).subscribe({
            complete: () => { // now the user is connected to socket cluster
                this.handleUserFollowing();
                this.handleStopFollowing();
                this.sendUpdates();
                this.sendDiagramStates();
                this.listenSpotlight();
            },
        });
    }

    public getSpotlightState(): BehaviorSubject<boolean> {
        return this.onSpotlight;
    }

    public getSpotlightRequestingState(): Observable<string> {
        return this.onSpotlightRequesting;
    }

    public toggleSpotlight() {
        if ( this.onSpotlight.value ) {
            this.turnOffSpotlight();
        } else {
            this.spotlightMe();
        }
    }

    public spotlightMe() {
        this.realtimeStates.set( FollowUserService.KEY_SPOTLIGHT, 'ON' );
        const followers = [];
        const currentUserId = this.state.get( 'CurrentUser' );
        for ( const uId in this.followUserMap ) {
            if ( this.followUserMap[uId] === currentUserId ) {
                followers.push( uId );
            }
        }
        this.followers.next( followers );
        const descriptionText = this.followers.pipe(
            map( list => {
                // user this.translate service
                if ( list.length === 0 ) {
                    return this.translate.instant( 'FOLLOW.WAITING' );
                } else {
                    return this.translate.instant( 'FOLLOW.FOLLOWERS', { followerLength:  list.length });
                }
            }),
        );
        const options = {
            inputs: {
                descriptionText: descriptionText,
                autoDismiss: false,
                dismissWhen: merge(
                    this.state.changes( 'CurrentDiagram' ).pipe( skip( 1 )),
                    this.onSpotlight.pipe( filter( v => !v )),
                ),
                backgroundColor: this.realtimeStates.getUserDetails( currentUserId ).color,
                isUserFollow: true,
                onDismiss: () => {
                    Tracker.track( 'header.collaborator.spotlightme.invite.stop.click' );
                    this.turnOffSpotlight();
                },
            },
        };
        Tracker.track( 'header.collaborator.spotlightme.invite.load', { value1: 'user' });
        this.notifierController.show(
            'ON_SPOTLIGHT', AbstractNotification, NotificationType.Neutral, options );
        this.onSpotlight.next( true );
    }

    public turnOffSpotlight() {
        this.realtimeStates.set( FollowUserService.KEY_SPOTLIGHT, 'OFF' );
        this.followers.next([]);
        this.onSpotlight.next( false );
    }

    protected showFollowingNotification( userId: string, local: boolean ) {
        const dismissObs = new Subject();
        this.userLocator.getUserInfo( userId ).pipe(
            take( 1 ),
            tap( userData => {
                this.dismissObsListenSpotlight.next( true );
                this.onSpotlightRequesting.next( '__NONE__' );
                const options = {
                    inputs: {
                        description:
                        this.translate.instant(
                            local ? 'FOLLOW.FOLLOWING' : 'FOLLOW.SPOTLIGHT_ON',
                            { userFullName: userData.fullName }),
                        backgroundColor: this.realtimeStates.getUserDetails( userData.id ).color,
                        isUserFollow: true,
                        dismissWhen: merge(
                            dismissObs,
                            timer( 5000 ),
                        ),
                    },
                };
                this.notifierController.show(
                    'FOLLOW_USER', AbstractNotification, NotificationType.Neutral, options );
            }),
        ).subscribe();
        dismissObs.pipe(
            take( 1 ),
            tap(() => {
                this.removeFollowNotification = null;
            }),
        ).subscribe();
        return dismissObs;
    }

    protected listenSpotlight() {
        this.realtimeStates.get( FollowUserService.KEY_SPOTLIGHT ).subscribe( data => {
            if ( data.userId === this.state.get( 'CurrentUser' )) {
                return;
            }
            if ( data.value === 'ON' ) {
                if ( this.getFollowingUser() === data.userId ) {
                    // if already following ignore.
                    return;
                }
                this.onSpotlightRequesting.next( data.userId );
                const timeoutId = setTimeout(() => {
                    this.state.set( 'FollowUser', data.userId );
                }, 5000 );
                this.userLocator.getUserInfo( data.userId ).pipe(
                    take( 1 ),
                    tap( userData => {
                        const dismissObs: Subject<boolean> = new Subject();
                        this.spotlightRequests[data.userId] = dismissObs;
                        const options = {
                            inputs: {
                                description:
                                    this.translate.instant( 'FOLLOW.SPOTLIGHTING', { userFullName: userData.fullName }),
                                backgroundColor: this.realtimeStates.getUserDetails( userData.id ).color,
                                dismissWhen: merge(
                                    timer( 10000 ),
                                    dismissObs,
                                    this.dismissObsListenSpotlight,
                                ).pipe(
                                    tap(() => {
                                        delete this.spotlightRequests[data.userId];
                                    }),
                                ),
                                user: userData,
                                isUserFollow: true,
                                onDismiss: () => {
                                    delete this.spotlightRequests[data.userId];
                                    clearTimeout( timeoutId );
                                    Tracker.track( 'header.collaborator.spotlightme.invite.ignore.click' );
                                },
                                notificationAction:  {
                                    action: ( state: any ) => {
                                        state.set( 'FollowUser', data.userId );
                                        dismissObs.next( true );
                                    },
                                    args: [ this.state ],
                                },
                            },
                        };
                        Tracker.track( 'header.collaborator.spotlightme.invite.load', { value1: 'collaborator' });
                        this.notifierController.show(
                            'FOLLOW_SPOTLIGHT', AbstractNotification, NotificationType.Neutral, options );
                    }),
                ).subscribe();
            } else {
                this.onSpotlightRequesting.next( '__NONE__' );
                if ( this.spotlightRequests[data.userId]) {
                    this.spotlightRequests[data.userId].next( true );
                }
                if ( this.state.get( 'FollowUser' ) === data.userId ) {
                    this.state.set( 'FollowUser', '__NONE__' );
                }
            }
        });
    }

    protected handleUserFollowing() {
        this.state.changes( 'FollowUser' ).pipe(
            filter( v => Boolean( v )), // somehow this is getting called before state is set. fixing it.
            distinctUntilChanged(( x, y ) => {
                x = x.userId || x;
                y = y.userId || y;
                return x === y;
            }),
            tap( val => {
                let userId = '__NONE__';
                let local = false;
                if ( typeof val === 'string' ) {
                    userId =  val;
                } else {
                    userId = val?.userId;
                    local = val?.local;
                }
                this.requestStates( userId );
                if ( this.removeFollowNotification ) {
                    this.removeFollowNotification.next( true );
                }
                if ( userId !== '__NONE__' ) {
                    this.removeFollowNotification = this.showFollowingNotification( userId, local );
                }
            }),
            map( val => {
                if ( typeof val === 'string' || val instanceof String ) {
                    return val;
                } else {
                    return val?.userId;
                }
            }),
            switchMap( userId => userId === '__NONE__' ? EMPTY :
            combineLatest([
                this.realtimeStates.getUser( FollowUserService.KEY_VIEWPORT, userId ),
                this.state.changes( 'DiagramViewPort' ),
            ]).pipe(
                    filter(([ v ]) => !!v ),
                    switchMap(([ v, viewport ]) => {
                        const data = JSON.parse( v );
                        const frame = Rectangle.from({
                            x: data.left,
                            y: data.top,
                            width: data.width,
                            height: data.height,
                        });
                        const adjustment = frame.fitToFrame( viewport );
                        return this.getPanZoomState( userId ).pipe(
                            // prevent looping one level
                            filter(() => this.followUserMap[userId] !== this.state.get( 'CurrentUser' )),
                            tap(({ pan, zoom }) => {
                                // below code pan the diagram so that it follows viewport center of the followed user
                                const dCenterX = ( frame.centerX - pan.x ) / zoom;
                                const dCenterY = ( frame.centerY - pan.y ) / zoom;
                                const zoomLevel = zoom * adjustment.scale;
                                this.state.set( 'DiagramZoomLevel', zoomLevel );
                                this.state.set( 'DiagramPan', Point.from({
                                    x: viewport.centerX - dCenterX * zoomLevel,
                                    y: viewport.centerY - dCenterY * zoomLevel,
                                }));
                            }),
                            catchError( e => EMPTY ), // ignore errors
                        );
                    }),
                )),
        ).subscribe();
    }

    protected getPanZoomState( userId: string ) {
        return this.realtimeStates.getUser( FollowUserService.KEY_PAN_ZOOM, userId ).pipe(
            filter( v => !!v ),
            map( value => JSON.parse( value )),
        );
    }

    protected sendUpdates() {
        merge(
            this.state.changes( 'DiagramPan' ).pipe( distinctUntilChanged( Point.isEqual )),
            this.state.changes( 'DiagramZoomLevel' ).pipe( distinctUntilChanged()),
        ).pipe(
            lodashThrottle( this.zoomThrottle, { leading: true, trailing: true }),
            delay( 5 ), // wait till both pan zoom states are set in case of zooming.
        ).subscribe(() => this.realtimeStates.set(
            'panZoomState',
            JSON.stringify({
                pan: this.state.get( 'DiagramPan' ),
                zoom: this.state.get( 'DiagramZoomLevel' ),
            }),
        ));
        this.state.changes( 'DiagramViewPort' ).pipe(
            lodashThrottle( this.viewportThrottle, { leading: true, trailing: true }),
        ).subscribe( viewport => this.realtimeStates.set(
            'diagramViewport',
            JSON.stringify( viewport ),
        ));
    }

    protected requestStates( userId: string ) {
        this.realtimeStates.set( FollowUserService.KEY_FOLLOW, userId );
    }

    protected sendDiagramStates() {
        this.realtimeStates.get( FollowUserService.KEY_FOLLOW ).subscribe( data => {
            if ( data.value !== '__NONE__' ) {
                this.followUserMap[data.userId] = data.value;
                const currentUserId = this.state.get( 'CurrentUser' );
                if ( currentUserId === data.value ) {
                    const states = {
                        [FollowUserService.KEY_VIEWPORT]: JSON.stringify( this.state.get( 'DiagramViewPort' )),
                        [FollowUserService.KEY_PAN_ZOOM]: JSON.stringify({
                            pan: this.state.get( 'DiagramPan' ),
                            zoom: this.state.get( 'DiagramZoomLevel' ),
                        }),
                    };
                    this.realtimeStates.set( PhotonClient.KEY_STATES, JSON.stringify( states ));
                }
                const followers = [];
                for ( const uId in this.followUserMap ) {
                    if ( this.followUserMap[uId] === currentUserId ) {
                        followers.push( uId );
                    }
                }
                this.followers.next( followers );
            } else {
                this.removeFollower( data.userId );
            }
        });
    }

    protected handleStopFollowing() {
        this.realtimeStates.getPhoton().removedPeer.subscribe( peerId => {
            this.removeFollower( peerId );
            if ( this.getFollowingUser() === peerId ) {
                this.state.set( 'FollowUser', '__NONE__' );
            }
        });
        this.state.changes( 'CurrentDiagram' ).pipe(
            distinctUntilChanged(),
            tap(() => this.state.set( 'FollowUser', '__NONE__' )),
        ).subscribe();
        this.commandSvc.dispatchedEvents.subscribe( cEvent => {
            if ( cEvent instanceof InteractionCommandEvent && this.state.isNot( 'FollowUser', '__NONE__' )) {
                this.state.set( 'FollowUser', '__NONE__' );
            }
            if ( cEvent instanceof DiagramCommandEvent && cEvent.getSource() === 'user' && this.state.isNot( 'FollowUser', '__NONE__' )) {
                this.state.set( 'FollowUser', '__NONE__' );
            }
        });
    }

    private removeFollower( userId ) {
        if ( this.followUserMap[userId] === this.state.get( 'CurrentUser' )) {
            this.followers.next( this.followers.value.filter( uId => uId !== userId ));
        }
        delete this.followUserMap[userId];
    }

    private getFollowingUser() {
        const fUser = this.state.get( 'FollowUser' );
        return fUser.userId || fUser;
    }
}
