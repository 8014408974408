import { Injectable } from '@angular/core';
import { Logger } from '../../logger/logger.svc';
import { SequenceCommandList } from './sequence-command-list';
import { CommandError } from '../error/command-error';
import { ParallelCommandList } from './parallel-command-list';
import { ICommandList } from './command-list';
import { CommandEvent } from '../command-event';
import { AbstractCommand } from '../abstract.cmd';

/**
 * This class is resposible to build the command structures and map them
 * to the desired event.
 * All the command initialization of electron application needs to use this class
 * to register an event to a sequence of commands or parallel commands.
 *
 * @author  gobiga
 * @since   2016-03-15
 *
 */
@Injectable()
export class CommandMapper {

    /**
     * commandsMap is a map of command names => command classes.
     */
    protected commandsMap: { [ commandName: string ]: typeof AbstractCommand } = {};

    /**
     * commandEventMap is a map of command event names => CommandEvent classes.
     * This is useful when you don't have the actual command event object to dispatch.
     * Ex:- calling the event dispatching from command line.
     */
    protected commandEventMap: { [ commandEventName: string ]: CommandEvent } = {};

    /*
     * Dictionary of event name to command class mappings
     */
    private commands: { [eventname: string]: ICommandList } = {};

    constructor( log: Logger ) {
        // ...
    }

    /**
     * This function maps an event  to a specific command for a given event.
     * This will return a <code>SequenceCommandList</code>.
     * This can be used with <code>AbstarctCommandList</code> <code>add</add> function to
     * register a specific command for a  given event.
     * @param   event  An event which maps a single command.
     */
    public map( event: CommandEvent ): ICommandList {
        return this.mapSequence( event );
    }

    /**
     * This function maps an event to a sequence of commands that execute
     * one after another for a given event. This will return a <code>SequenceCommandList</code>.
     * This can be used with <code>AbstarctCommandList</code> <code>add</add> function to
     * register a sequence of commands for a given event.
     * @param   event  An event which maps a sequence of commands that call one after another.
     */
    public mapSequence( event: CommandEvent ): SequenceCommandList {
        if ( event != null ) {
            const eventName: string = event.toString();
            const commandList = this.wrapCommandList( new SequenceCommandList());
            this.commands[eventName] = commandList;
            this.commandEventMap[eventName] = event;
            return commandList;
        } else {
            throw new CommandError( 'Mapping a command to an event requires a valid EventName' );
        }
    }


    /**
     * This function maps an event to a sequence of commands that
     * execute parallely for a given event. This will return a <code>ParallelCommandList</code>.
     * This can be used with <code>AbstarctCommandList</code> <code>add</add> function to
     * register a parallel commands for given event.
     * @param   event   An event which maps a sequence of commands that call multiple at once.
     */
    public mapParallel( event: CommandEvent ): ParallelCommandList {
        if ( event != null ) {
            const eventName: string = event.toString();
            const commandList = this.wrapCommandList( new ParallelCommandList());
            this.commands[eventName] = commandList;
            this.commandEventMap[eventName] = event;
            return commandList;
        } else {
            throw new CommandError( 'Mapping a command to an event requires a valid EventName' );
        }
    }

    /**
     * Gets the <code>ICommandList</code> for a given event. Essentially
     * returns a list of commands associated with the particular command event.
     * Note that this can return a <code>SequenceCommandList</code> or a
     * <code>ParallelCommandList</code>
     * @param   eventName   An event for which the command list is needed.
     */
    public getCommandList( event: CommandEvent ): ICommandList {
        if ( event != null ) {
            return this.commands[event.toString()];
        }
    }

    public getCommandEventByName( eventName: string, source?: string ) {
        const commandEvent = this.commandEventMap[eventName];
        if ( !commandEvent ) {
            throw new Error( `Unexpected error: unable to find command event "${eventName}"` );
        }
        if ( source && source !== commandEvent.getSource()) {
            const commandEventClass = Object.getPrototypeOf( commandEvent ).constructor as
                new ( eventName: string, source: string, dataTransformer?: CallableFunction ) => CommandEvent;
            return new commandEventClass( eventName, source, ( commandEvent as any ).dataTransformer );
        }
        return commandEvent;
    }

    /**
     * getCommandByName returns the command class for given command name.
     */
    public getCommandByName( name: string ) {
        const commandType = this.commandsMap[name];
        if ( !commandType ) {
            throw new Error( `Unexpected error: unable to find command "${name}"` );
        }
        return commandType;
    }

    /**
     * registerCommand registers the command on the command mapper.
     */
    public registerCommand( commandType: typeof AbstractCommand ) {
        this.commandsMap[commandType.name] = commandType;
        commandType.aliases.forEach( alias =>  this.commandsMap[alias] = commandType );
    }

    /**
     * wrapCommandListAdd wraps the add method on a command list so that the command will
     * also get registered on the command mapper when they are added on command lists.
     */
    protected wrapCommandList<T extends ICommandList>( source: T ): T {
        const prevAdd = source.add;
        source.add = ( ...args: any[]) => {
            const commandType = args[0];
            this.registerCommand( commandType );
            prevAdd.call( source, ...args );
            return source;
        };
        return source;
    }
}
