import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Updates the diagram description and tags.
 *
 *  data: {
 *      description?: string,
 *      tags?: string,
 *      status?: string
 *      isTemplate?: boolean
 *  }
 *
 */
@Injectable()
@Command()
export class UpdateDiagramInfo extends AbstractDiagramChangeCommand {
    public static get dataDefinition(): any  {
        return {
            description: false,
            tags: false,
            status: false,
            isTemplate: false,
        };
    }

    public prepareData() {
        if (
            this.data.description === undefined &&
            this.data.tags === undefined &&
            this.data.status === undefined &&
            this.data.isTemplate === undefined
        ) {
            throw new Error ( 'There was an error setting the data to the modifier because data is not valid' );
        }
        if ( typeof this.data.description === 'string' ) {
            this.changeModel.description = this.data.description;
        }
        if ( typeof this.data.tags === 'string' ) {
            this.changeModel.tags = this.data.tags;
        }
        if ( typeof this.data.status === 'string' ) {
            this.changeModel.status = this.data.status;
        }
        if ( typeof this.data.isTemplate === 'boolean' ) {
            this.changeModel.isTemplate = this.data.isTemplate;
        }
    }
}

Object.defineProperty( UpdateDiagramInfo, 'name', {
    value: 'UpdateDiagramInfo',
});
