import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { Random, Tracker } from 'flux-core';
import { DataType, IEntityDef } from 'flux-definition';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ISidebarDropdown } from '../../../../../src/framework/ui/dropdown/sidebar-dropdown/sidebar-dropdown.i';
import { EDataModel } from '../../../edata/model/edata.mdl';

/**
 * EntityLookupCreator
 * This component is to create entity lookup options
 *
 * @author Damith
 * @since 2022-01-12
 */
@Component({
    selector: 'entity-lookup-creator',
    template: `
        <div #entityLookup class="entity-lookup-creator-container" >
            <div class="lookup-options-name fx-justify-space-between">
                <!-- <a class="primary lookup-options-cancel" (click)="cancel()">
                    <svg class="nu-icon"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-arrow-back"></use></svg>
                </a> -->
                <input id="lookup-field-label" class="creator-label" value="Entity Lookup">
                <div class="close-btn" (click)="cancel()">
                    <svg class="nu-icon nu-icon-med"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
                </div>
            </div>
            <div class="lookup-options-add" >
                <label class="grey" translate>SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.DATABASE</label>
                <custom-database-selector
                    (dbChanged)="selectedDb.next($event)"
                    [selectedDb]="selectedDb | async"
                    [placeholderText]="'SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.DB_PLACEHOLDER' | translate">
                </custom-database-selector>
            </div>
            <div *ngLet="selectedDb | async as dbSelected" [hidden]="dbSelected === null" class="lookup-options-add" >
                <label class="grey" translate>SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.ENTITY_TYPE</label>
                <entity-type-selector
                    (typeChanged)="selectedEntityType.next($event)"
                    [selectedDb]="selectedDb | async"
                    [selectedType]="selectedEntityType | async"
                    [placeholderText]="'SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.TYPE_PLACEHOLDER' | translate">
                </entity-type-selector>
            </div>
            <div *ngIf="selectedEntityType | async" class="lookup-options-add lookup-options-footer fx-center-vertical" >
                <input type="checkbox" [checked]="allowMultiple | async" (click)="allowMultiple.next(!allowMultiple.value)"
                    [trackAction]="{ event: context + '.dataField.lookup.checkbox.change' }">
                <label class="grey fx-flex-expand" translate>SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.ALLOW_MULTIPLE</label>
                <button *ngIf="selectedEntityType | async" class="btn-secondary btn-small"
                    (click)="commit()" translate>SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.DONE_BUTTON</button>
            </div>
        </div>
    `,
    styleUrls: [ './entity-lookup-creator.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EntityLookupCreator implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @ViewChild( 'entityLookup', { read: ElementRef, static: false })
    public entityLookup: ElementRef;

    /**
     * SingleSelectCombo data item
     */
    @Output()
    public change: Subject<any> = new Subject();

    @Output()
    public closed: Subject<any> = new Subject();

    public databaseOptions: Observable<ISidebarDropdown[]>;
    public entityTypeOptions: Observable<ISidebarDropdown[]>;

    public selectedDb: BehaviorSubject<EDataModel> = new BehaviorSubject( null );
    public selectedEntityType: BehaviorSubject<IEntityDef> = new BehaviorSubject( null );
    public allowMultiple: BehaviorSubject<boolean> = new BehaviorSubject( true );

    protected subs: Subscription[] = [];

    public get entityLookupElement(): HTMLElement {
        return this.entityLookup.nativeElement as HTMLElement;
    }

    public ngAfterViewInit() {
        this.subs.push(
            this.selectedDb.subscribe(() => this.selectedEntityType.next( null )),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public cancel() {
        this.closed.next( true );
    }

    public commit() {
        if ( !this.selectedEntityType.getValue()) {
            return;
        }
        const options = {
            eDataId: this.selectedDb.getValue().id,
            eDefId: this.selectedEntityType.getValue().id,
            allowMultiple: this.allowMultiple.value,
        };
        const label = this.entityLookupElement.querySelector<HTMLInputElement>( '#lookup-field-label' ).value;
        this.change.next( this.getDataItem( options, label ));
        this.closed.next( true );
        /* istanbul ignore next */
        if ( this.context ) {
            Tracker.track( `${this.context}.dataField.lookup.done.click` );
        }
    }

    /**
     * Create a new combo data item
     */
    private getDataItem( options, label ) {
        return {
            id: Random.dataItemId(),
            type: DataType.LOOKUP,
            layout: 'block',
            value: [],
            options,
            label,
            labelEditable: true,
            optional: true,
            visibility: [
                { type: 'editor' },
            ],
            validationRules: {},
        };
    }
}
