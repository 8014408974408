<div class="notification-count-container fx-flex fx-pointer-events-auto">
    <button
        *ngIf="errorCount > 0"
        class="fx-flex fx-align-center fx-no-padding fx-no-margin notification-count-button"
        (click)="handleErrorClicked()">
        <svg class="nu-icon nu-ic-warning-solid nu-ic-error">
            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-solid"></use>
        </svg>
        <span [class.fx-hidden]="errorCount < 2" class="notification-count-errors">{{ errorCount }}</span>
    </button>
    <button
        *ngIf="warningCount > 0"
        class="fx-flex fx-align-center fx-no-padding fx-no-margin notification-count-button"
        (click)="handleWarningClicked()">
        <svg class="nu-icon nu-ic-warning-solid nu-ic-warning">
            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-solid"></use>
        </svg>
        <span [class.fx-hidden]="warningCount < 2" class="notification-count-warnings">{{ warningCount }}</span>
    </button>
</div>