import { Injectable } from '@angular/core';
import { StateService, Command } from 'flux-core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AbstractInitializationCommand } from 'flux-core';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { UserSub } from '../subscription/user.sub';
import { switchMap, filter, take, tap } from 'rxjs/operators';

/**
 * Command for starting the user subscription.
 * User subscription is the primary means user data is fetched for the current
 * user of the app.
 * For this command to work, the 'CurrentUser' state must be set.
 *
 * @author  Ramishka
 * @since   201-02-02
 */
@Injectable()
@Command()
export class StartUserSubscription extends AbstractInitializationCommand {

    constructor( protected modelSubManager: ModelSubscriptionManager,
                 protected state: StateService<any, any>,
                 protected router: Router ) {
        super( router )/* istanbul ignore next */;
    }

    /**
     * Starts the user subscription.
     * @return an observable that emits when the subscription status
     * changes for the first time since sub creation.
     */
    public execute(): Observable<any> {
        if ( this.state.has( 'CurrentUser' )) {
            return this.modelSubManager.start( UserSub, this.state.get( 'CurrentUser' )).pipe(
                switchMap( sub => sub.status ),
                filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
                take( 1 ),
                tap(() => {
                    this.navigateToRoute( this.data.status );
                }),
            );
        } else {
            throw new Error( 'User subscription could not be started as current user was not set' );
        }
    }
}

Object.defineProperty( StartUserSubscription, 'name', {
    writable: true,
    value: 'StartUserSubscription',
});
