import { Observable, of } from 'rxjs';
import { Random, ClassUtils } from 'flux-core';
import { AbstractModelFactory } from 'flux-diagram-composer';
import { IEDataDefClass } from 'flux-definition';
import { map } from 'rxjs/operators';
import { EDataModel } from './edata.mdl';


/**
 * This is the stateless factory for creating all types of Diagram Models. Any model
 * created must be of the type EDataModel or anything that extends that.
 *
 * This creates the models and merges the definitions into the model. This manages the
 * definition creation and conversion to accessible types. This does not manage diagram
 * model data.
 * * FIXME: This is a clone of the EDataModelFactory. Need to investigate if possible to use
 * Generics to reuse that factory. However the issue is the static getter
 */
export class EDataModelFactory extends AbstractModelFactory {

    /**
     * Getter instance
     * Returns current model factory instance
     */
    public static get instance(): EDataModelFactory {
        if ( !this._instance ) {
            this._instance = new EDataModelFactory();
        }
        return this._instance;
    }

    /**
     * The singleton instance of the EDataModelFactory.
     */
    protected static _instance: EDataModelFactory;

    /**
     * Factory function to create a edata from a edata type definition identifier. Fetches the
     * definiton and creates the Model with the definition.
     * @param typeDef The diagram type definition Class Identifier
     * @param type The type of the Diagram Model expected which is or extends DiagramDataModel
     * @param id The unique id for the diagram. If not given, will be generated.
     */
    public createByDef(
        typeDef: string,
        type: typeof EDataModel = EDataModel,
        id?: string,
        name: string = '',
    ): Observable<EDataModel> {
        id = !id ? Random.eDataId() : id;

        if ( !typeDef ) {
            return of( new type( id ));
        }

        return this.defLocator.getClass( typeDef ).pipe(
            map(( defClass: IEDataDefClass ) => {
                const defInstance = new defClass();
                let model = new type( id );

                // NOTE: lodash marge is not sufficient, we use RamiMerge :P
                model = ClassUtils.mergeInstances( model, defInstance );
                return model;
            }),
        );
    }

    /**
     * Factory function to create a edata from a saved edata data. Fetches the
     * definiton as per type property in the data, and creates the Model.
     *
     * Important: This method only creates the EData Model and does not merge the values from the
     * given data object into the model. It simply uses following fiels from the data.
     *      - type
     *      - id
     *
     * @param data The shape data that was stored. Expected to have defId and version props
     * @param type The type of the EntityModel expected
     */
    public createByData(
        data: any, type: typeof EDataModel = EDataModel,
    ): Observable<EDataModel> {
        return this.createByDef( data.type, type, data.id );
    }


}
