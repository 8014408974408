import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractIndexChangeCommand } from './abstract-index-change.cmd';

/**
 * This command brings a given shape or shapes on top of all existing
 * shapes in the diagram.
 * Shapes can either be passed in, or the command will operate on shapes
 * in the current selection
 *
 * @author  Ramishka
 * @since   2019-01-31
 */
@Injectable()
@Command()
export class BringToFront extends AbstractIndexChangeCommand {

    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( state, ds ) /* istanbul ignore next */;
    }

    /**
     * Updates the zIndex of shapes so they are brought to
     * the absolute front of the diagram. If there are no shapes,
     * command is cancelled.
     */
    public prepareData() {
        const shapeIds = this.getShapeIds();
        const selectedShapes = this.changeModel.getShapesOrderedByIndex(  shapeIds );
        let maxIndex = this.changeModel.maxZIndex;
        selectedShapes.forEach( shape => {
            maxIndex++;
            this.changeModel.shapes[shape.id].zIndex = maxIndex;
        });
    }
}

Object.defineProperty( BringToFront, 'name', {
    value: 'BringToFront',
});
