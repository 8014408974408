import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, StateService, Tracker } from 'flux-core';
import { AbstractCollapsibleMenuItem, ICollapsibleMenuItem, ISecondaryMenuItem } from 'libs/flux-core/src/ui';
import { PlanPermManager, UpgradeDialogType, UpgradeDialogWindow } from 'flux-user';
import { Observable } from 'rxjs';
import { IDialogBoxData, PlanPermission } from 'flux-definition';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'library-collapsible-menu-item',
    templateUrl: './library-collapsible-menu-item.cmp.html',
    styleUrls: [ './library-collapsible-menu-item.scss' ],
})
export class LibraryCollapsibleMenuItem extends AbstractCollapsibleMenuItem implements OnDestroy {

    /**
     * Event emitter to emit when click event fires on
     * the header element.
     */
    @Output()
    public toggleChild: EventEmitter<any>;

    /**
     * Event emitter to emit when click event fires on
     * remove button.
     */
    @Output()
    public removeChild: EventEmitter<any>;

    /**
     * Event emitter to emit when the title is edited
     */
    @Output()
    public titleChanged: EventEmitter<any>;

    /**
     * If true enable close button to remove the component from parent panel
     */
    @Input()
    public removable: boolean = true;

    /**
     * Determine whether menu item is collapsable
     */
    @Input()
    public collapsable: boolean = true;

    /**
     * If true enable secondary menu.
     */
    @Input()
    public secondaryMenu: boolean = false;

    /**
     * Secondary menu interface.
     */
    @Input()
    public items: ISecondaryMenuItem[] = [];

    @Input()
    public isPremium: boolean = false;

    /**
     * This holds the instance of the component panel that is rendered on the container child
     */
    public menuItem: ICollapsibleMenuItem;

    /**
     * Whether the user is in free or demo plan or not
     */
    public isFreeOrDemoUser: Observable<boolean>;

    /**
     * Whether the user is in lite plan or not
     */
    public isLitePlanUser: Observable<boolean>;

    /**
     * Expands the item.
     */
    @ViewChild( 'componentContainer', { read: ViewContainerRef, static: true })
    protected componentContainer: ViewContainerRef;

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef,
        protected modalController: ModalController,
        protected planPermManager: PlanPermManager,
        public translate: TranslateService,
        protected state: StateService<any, any>,
    ) {
        super( componentFactoryResolver, injector, cdr, translate );
        this.toggleChild = new EventEmitter();
        this.removeChild = new EventEmitter();
        this.titleChanged = new EventEmitter();

        this.isFreeOrDemoUser = this.planPermManager.isFreeOrDemoUser();
        this.isLitePlanUser = this.planPermManager.isLiteUser();
    }

    /**
     * Title to be shown on header
     */
    public get title(): string {
        if ( this.menuItem ) {
            return this.menuItem.title;
        }
    }

    /**
     * Total shapes count in a library
     */
    public get totalShapes(): number {
        if ( this.menuItem ) {
            return this.menuItem.totalShapes;
        }
    }

    /**
     * Check whether view all shapes button is there
     */
    public get showExpandButton(): boolean {
        if ( this.menuItem ) {
            return this.menuItem.showExpandButton;
        }
    }

    /**
     * Secondary text to be shown on header
     */
    public get subText(): Observable<string> {
        if ( this.menuItem ) {
            return this.menuItem.subText;
        }
    }

    public get options(): any {
        if ( this.menuItem ) {
            return this.menuItem.options;
        }
    }

    /**
     * Id of the compoenent
     */
    @Input()
    public get id(): string {
        return this.menuItem.id;
    }

    /**
     * Creates an instance of an menu item and append it to the end of the menu
     * @param type  The type of the component to create.This should be a ICollapsibleMenuItem Component class.
     * @param inputs    An object with all the inputs that should be set to the
     *      type component instance. Object keys should have the same name as the input field.
     */
    public add( type: any, inputs?: { [inputName: string]: any }) {
        const menuItemCmpRef: ComponentRef<any> = this.createComponent( type, this.componentContainer );
        this.menuItem = menuItemCmpRef.instance;
        inputs = { ...inputs, subText: this.subText };
        this.setInputs( this.menuItem, inputs );
    }

    /**
     * Open the upgrade window
     */
    public openUpgradeWindow( event: MouseEvent ): void {
        // Prevent toggling of menu item collapse
        // when premium button is clicked
        event.stopPropagation();

        // Adding tracker for premium button click
        Tracker.track( 'conversion.tag.premium.click', {
            value1: this.menuItem.id, value1Type: 'feature',
            value2: 'shapes-panel', value2Type: 'location',
        });

        const dialogData = {
            id: PlanPermission.CREATELY_PREMIUM_LIBRARIES,
            iframeType: UpgradeDialogType.PremiumShapelibraries,
            buttons: [
                {
                    type: 'upgrade',
                    clickHandler: () => {},
                },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        // Adding a Timeout for toooltips to disappear
        setTimeout(() => this.modalController.show( UpgradeDialogWindow, {
            inputs: {
                dialogData,
            },
        }), 100 );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy() {
        this.toggleChild = null;
        this.removeChild = null;
        this.titleChanged = null;
        this.menuItem = null;
        this._parent = null;
        this._isOpen = false;
    }
}
