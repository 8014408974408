import { ITextParser } from 'flux-definition';

class DatabaseTypeParser implements ITextParser<any> {
    static id = 'creately.database.type';

    public static get instance(): DatabaseTypeParser {
        return this._instance;
    }

    private static _instance = new DatabaseTypeParser();

    parse( input: string ) {
        const trimmed = input.trim();
        const parseErrors = [];
        const parsedText = trimmed.replace( /[<>?]/gm, '' );
        const isNull = trimmed.indexOf( '?' ) !== -1;
        return {
            ast: {
                type: parsedText,
                nullable: isNull,
                original: trimmed,
            },
            lexResult: {},
            parseErrors: parseErrors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        return {
            suggestions: [
                'varchar',
                'boolean',
                'int',
                'string',
                'bigint',
                'binary',
                'char',
                'date',
                'datetime',
                'decimal',
                'double',
                'float',
                'bit',
            ],
            searchTerm: text,
        };
    }
}

export default DatabaseTypeParser;
