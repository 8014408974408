import { FillStyle } from 'flux-definition';
import { LineStyle } from './line-style';
import { Palette } from './palette';
import { ShapeStyle } from './shape-style';


// tslint:disable-next-line: variable-name
export const ColorMap = {
    Slate: {
        50: '#F8FAFC',
        51: '#F9FAFB',
        52: '#F8FAFC',
        100: '#F2F5F9',
        101: '#F3F5F8',
        102: '#F3F5F8',
        200: '#E3E8EF',
        201: '#E5E8ED',
        202: '#E4E8EE',
        300: '#CDD5E0',
        301: '#D1D6DC',
        302: '#CFD5DE',
        400: '#97A3B6',
        401: '#9DA4B0',
        402: '#9AA4B3',
        500: '#677489',
        501: '#6E7682',
        502: '#6A7586',
        600: '#4A5567',
        601: '#505661',
        602: '#4D5664',
        700: '#364153',
        701: '#3C424D',
        702: '#394250',
        800: '#20293A',
        801: '#252B35',
        802: '#232A37',
        900: '#111729',
        901: '#161924',
        902: '#131827',
    },
    Gray: {
        50: '#F9FAFB',
        51: '#F9FAFB',
        52: '#F9FAFB',
        100: '#F7F8F9',
        101: '#F7F8F9',
        102: '#F7F8F9',
        200: '#E5E7EB',
        201: '#E6E7EA',
        202: '#E6E7EA',
        300: '#D2D5DA',
        301: '#D4D5D8',
        302: '#D3D5D9',
        400: '#9DA3AE',
        401: '#A0A4AB',
        402: '#9FA3AC',
        500: '#6C727F',
        501: '#70737B',
        502: '#6E737D',
        600: '#4D5562',
        601: '#51565E',
        602: '#4F5560',
        700: '#394150',
        701: '#3E424B',
        702: '#3B424E',
        800: '#212936',
        801: '#252A32',
        802: '#232934',
        900: '#121826',
        901: '#161A22',
        902: '#141924',
    },
    Zinc: {
        50: '#FAFAFA',
        51: '#FAFAFA',
        52: '#FAFAFA',
        100: '#F4F4F5',
        101: '#F4F4F5',
        102: '#F4F4F5',
        200: '#E4E4E7',
        201: '#E5E5E6',
        202: '#E4E4E7',
        300: '#D4D4D8',
        301: '#D5D5D7',
        302: '#D4D4D8',
        400: '#A1A1A9',
        401: '#A3A3A7',
        402: '#A2A2A8',
        500: '#717179',
        501: '#737377',
        502: '#727278',
        600: '#52525A',
        601: '#545458',
        602: '#535359',
        700: '#3F3F45',
        701: '#404044',
        702: '#404044',
        800: '#27272A',
        801: '#282829',
        802: '#27272A',
        900: '#18181B',
        901: '#19191A',
        902: '#18181B',
    },
    Neutral: {
        50: '#FAFAFA',
        51: '#FAFAFA',
        52: '#FAFAFA',
        100: '#F5F5F5',
        101: '#F5F5F5',
        102: '#F5F5F5',
        200: '#E5E5E5',
        201: '#E5E5E5',
        202: '#E5E5E5',
        300: '#D4D4D4',
        301: '#D4D4D4',
        302: '#D4D4D4',
        400: '#A3A3A2',
        401: '#A3A3A2',
        402: '#A3A3A2',
        500: '#737373',
        501: '#737373',
        502: '#737373',
        600: '#525252',
        601: '#525252',
        602: '#525252',
        700: '#404040',
        701: '#404040',
        702: '#404040',
        800: '#262626',
        801: '#262626',
        802: '#262626',
        900: '#171717',
        901: '#171717',
        902: '#171717',
    },
    Stone: {
        50: '#FAFAF9',
        51: '#FAFAF9',
        52: '#FAFAF9',
        100: '#F5F5F4',
        101: '#F5F5F4',
        102: '#F5F5F4',
        200: '#E7E5E4',
        201: '#E6E5E5',
        202: '#E7E5E4',
        300: '#D5D3D1',
        301: '#D4D3D2',
        302: '#D5D3D1',
        400: '#A7A29E',
        401: '#A5A2A0',
        402: '#A6A29F',
        500: '#77716D',
        501: '#75716F',
        502: '#76716E',
        600: '#56534F',
        601: '#555350',
        602: '#555350',
        700: '#43403C',
        701: '#42403D',
        702: '#42403D',
        800: '#282524',
        801: '#272525',
        802: '#282524',
        900: '#1B1917',
        901: '#1A1918',
        902: '#1B1917',
    },
    Red: {
        50: '#FCF2F2',
        51: '#FAF4F4',
        52: '#FBF3F3',
        100: '#F9E3E2',
        101: '#F4E7E7',
        102: '#F7E5E4',
        200: '#F6CCCB',
        201: '#EDD4D4',
        202: '#F2D0CF',
        300: '#F0A9A7',
        301: '#E1B7B6',
        302: '#E9B0AE',
        400: '#E77975',
        401: '#D08F8C',
        402: '#DC8480',
        500: '#DD524C',
        501: '#C06D69',
        502: '#CF605A',
        600: '#CA3A31',
        601: '#AB5550',
        602: '#BB4840',
        700: '#AA2E26',
        701: '#904640',
        702: '#9D3A33',
        800: '#8C2822',
        801: '#773B37',
        802: '#81322D',
        900: '#752522',
        901: '#643533',
        902: '#6D2D2A',
    },
    Orange: {
        50: '#FEF7EE',
        51: '#FBF7F1',
        52: '#FCF7F0',
        100: '#FCEED8',
        101: '#F5ECDF',
        102: '#F8EDDC',
        200: '#F8D8B0',
        201: '#EAD7BE',
        202: '#F1D7B7',
        300: '#F3BD7F',
        301: '#DCBC96',
        302: '#E7BD8B',
        400: '#ED974F',
        401: '#CD9A6F',
        402: '#DD995F',
        500: '#E87B35',
        501: '#C48359',
        502: '#D68047',
        600: '#D9622B',
        601: '#B66F4E',
        602: '#C8693C',
        700: '#B34A22',
        701: '#96573F',
        702: '#A55130',
        800: '#8E3B1E',
        801: '#784634',
        802: '#834129',
        900: '#73321B',
        901: '#613B2D',
        902: '#6A3724',
    },
    Amber: {
        50: '#FEFBED',
        51: '#FBF9F0',
        52: '#FCFAEF',
        100: '#FCF3CC',
        101: '#F2EDD6',
        102: '#F7F0D1',
        200: '#F9E796',
        201: '#E5DBAA',
        202: '#EFE1A0',
        300: '#F5D565',
        301: '#D8C582',
        302: '#E7CE73',
        400: '#F2C14B',
        401: '#D1B46C',
        402: '#E1BB5C',
        500: '#E9A23B',
        501: '#C69C5E',
        502: '#D89F4C',
        600: '#CC7B2E',
        601: '#AC7C4E',
        602: '#BC7C3E',
        700: '#A85923',
        701: '#8D5E3E',
        702: '#9B5C30',
        800: '#88451D',
        801: '#734B32',
        802: '#7D4828',
        900: '#70391A',
        901: '#5F3E2B',
        902: '#673C23',
    },
    Yellow: {
        50: '#FEFCEA',
        51: '#FAF9EE',
        52: '#FCFAEC',
        100: '#FCF9C9',
        101: '#F2F0D3',
        102: '#F7F5CE',
        200: '#FDF097',
        201: '#E9E1AB',
        202: '#F3E9A1',
        300: '#F8E164',
        301: '#DACD82',
        302: '#E9D773',
        400: '#F3CE49',
        401: '#D1BB6B',
        402: '#E2C55A',
        500: '#E2B53E',
        501: '#C1A75F',
        502: '#D2AE4E',
        600: '#C18D30',
        601: '#A4854D',
        602: '#B3893E',
        700: '#986523',
        701: '#81623A',
        702: '#8C642F',
        800: '#7D501F',
        801: '#6A4F32',
        802: '#745028',
        900: '#6A411C',
        901: '#5A422C',
        902: '#624224',
    },
    Lime: {
        50: '#F8FEE9',
        51: '#F6FAED',
        52: '#F7FCEB',
        100: '#EFFBD0',
        101: '#EBF2D9',
        102: '#EDF7D4',
        200: '#DFF8A7',
        201: '#D9E8B7',
        202: '#DCF0AF',
        300: '#C9F078',
        301: '#C0D890',
        302: '#C4E484',
        400: '#C9F078',
        401: '#C0D890',
        402: '#C4E484',
        500: '#94CA42',
        501: '#8EAF5D',
        502: '#91BC50',
        600: '#73A131',
        601: '#6F8B47',
        602: '#71963C',
        700: '#587B27',
        701: '#556A38',
        702: '#56732F',
        800: '#476121',
        801: '#44542E',
        802: '#465B27',
        900: '#3C521E',
        901: '#3A4828',
        902: '#3B4D23',
    },
    Green: {
        50: '#F2FDF5',
        51: '#F4FBF6',
        52: '#F3FCF6',
        100: '#E2FBE8',
        101: '#E7F6EB',
        102: '#E5F9E9',
        200: '#C7F5D3',
        201: '#D0ECD8',
        202: '#CCF0D5',
        300: '#A0ECB1',
        301: '#AFDDBA',
        302: '#A8E4B5',
        400: '#77DB89',
        401: '#8BC796',
        402: '#81D190',
        500: '#5EC269',
        501: '#72AE79',
        502: '#68B871',
        600: '#4BA154',
        601: '#5C9062',
        602: '#54985B',
        700: '#3C7E44',
        701: '#49714E',
        702: '#437749',
        800: '#306339',
        801: '#3A5940',
        802: '#355E3C',
        900: '#285231',
        901: '#304A36',
        902: '#2C4E34',
    },
    Emerald: {
        50: '#EFFCF5',
        51: '#F2F9F5',
        52: '#F0FBF5',
        100: '#D9F9E6',
        101: '#DFF3E7',
        102: '#DCF6E7',
        200: '#B8F1D2',
        201: '#C3E6D3',
        202: '#BEEBD3',
        300: '#8EE4BA',
        301: '#9FD3BA',
        302: '#97DBBA',
        400: '#6AD09D',
        401: '#7EBC9D',
        402: '#74C69D',
        500: '#55B685',
        501: '#68A385',
        502: '#5FAC85',
        600: '#43946C',
        601: '#53846C',
        602: '#4B8C6C',
        700: '#347659',
        701: '#416958',
        702: '#3B6F58',
        800: '#295E48',
        801: '#345346',
        802: '#2E5947',
        900: '#214D3C',
        901: '#2A443A',
        902: '#25493B',
    },
    Teal: {
        50: '#F2FDFA',
        51: '#F4FBF9',
        52: '#F3FCFA',
        100: '#D5FAF1',
        101: '#DCF3ED',
        102: '#D9F6EF',
        200: '#AEF4E4',
        201: '#BCE6DD',
        202: '#B5EDE0',
        300: '#86E7D4',
        301: '#99D4C8',
        302: '#90DDCE',
        400: '#68D1BF',
        401: '#7DBCB1',
        402: '#73C7B8',
        500: '#55B5A6',
        501: '#68A299',
        502: '#5FABA0',
        600: '#429288',
        601: '#52827C',
        602: '#4A8A82',
        700: '#35746E',
        701: '#426764',
        702: '#3B6E69',
        800: '#2B5D59',
        801: '#355351',
        802: '#305855',
        900: '#254D4A',
        901: '#2D4543',
        902: '#294947',
    },
    Cyan: {
        50: '#EFFDFF',
        51: '#F2FBFC',
        52: '#F1FCFD',
        100: '#D7F9FD',
        101: '#DFF3F5',
        102: '#DBF6F9',
        200: '#B6F1FA',
        201: '#C4E7EC',
        202: '#BDECF3',
        300: '#89E5F6',
        301: '#9FD6E0',
        302: '#94DDEB',
        400: '#65D0EA',
        401: '#80BFCF',
        402: '#72C7DD',
        500: '#52B3D0',
        501: '#6BA5B7',
        502: '#5FACC3',
        600: '#418FAE',
        601: '#578598',
        602: '#4C8AA3',
        700: '#34728D',
        701: '#466B7B',
        702: '#3D6E84',
        800: '#2D5D73',
        801: '#3B5865',
        802: '#345A6C',
        900: '#274D60',
        901: '#324955',
        902: '#2D4B5A',
    },
    Sky: {
        50: '#F2F9FE',
        51: '#F4F9FC',
        52: '#F3F9FD',
        100: '#E3F1FD',
        101: '#E8F1F8',
        102: '#E6F1FA',
        200: '#C3E5FA',
        201: '#CEE2EF',
        202: '#C8E3F5',
        300: '#91D1F8',
        301: '#A6CCE3',
        302: '#9BCEEE',
        400: '#62BAF3',
        401: '#7FB3D6',
        402: '#71B6E4',
        500: '#4BA3E3',
        501: '#699EC5',
        502: '#5AA0D4',
        600: '#3982C2',
        601: '#5480A7',
        602: '#4781B4',
        700: '#2D679C',
        701: '#436686',
        702: '#386691',
        800: '#265880',
        801: '#38566E',
        802: '#2F5777',
        900: '#21496B',
        901: '#30485C',
        902: '#284864',
    },
    Blue: {
        50: '#F0F6FE',
        51: '#F3F6FB',
        52: '#F1F6FD',
        100: '#DEE9FC',
        101: '#E4EBF6',
        102: '#E1EAF9',
        200: '#C4DAFB',
        201: '#CFDCF0',
        202: '#CADBF6',
        300: '#9DC4F8',
        301: '#AFC6E6',
        302: '#A6C5EF',
        400: '#70A3F3',
        401: '#8AA8D9',
        402: '#7DA5E6',
        500: '#4E80EE',
        501: '#6E8CCE',
        502: '#5E85DE',
        600: '#3662E3',
        601: '#5973C0',
        602: '#476AD2',
        700: '#2A4DD1',
        701: '#4B60B0',
        702: '#3B56C0',
        800: '#263FA9',
        801: '#404F8F',
        802: '#33479C',
        900: '#253985',
        901: '#384472',
        902: '#2F3E7B',
    },
    Indigo: {
        50: '#EFF2FE',
        51: '#F2F4FB',
        52: '#F1F3FC',
        100: '#E1E7FD',
        101: '#E7EAF7',
        102: '#E4E8FA',
        200: '#C9D2FA',
        201: '#D3D8F0',
        202: '#CED5F5',
        300: '#A8B4F6',
        301: '#B8BFE6',
        302: '#B0B9EE',
        400: '#838CF1',
        401: '#999EDB',
        402: '#8E95E6',
        500: '#6466E9',
        501: '#7F7FCE',
        502: '#7172DC',
        600: '#4E46DC',
        601: '#6964BE',
        602: '#5C55CD',
        700: '#4138C2',
        701: '#5954A6',
        702: '#4E46B4',
        800: '#36309D',
        801: '#4A4687',
        802: '#403B92',
        900: '#302E7C',
        901: '#3F3E6C',
        902: '#383674',
    },
    Violet: {
        50: '#F5F3FE',
        51: '#F6F5FC',
        52: '#F6F4FD',
        100: '#ECE9FC',
        101: '#EFEDF8',
        102: '#EDEBFA',
        200: '#DCD6FB',
        201: '#E1DDF4',
        202: '#DFDAF7',
        300: '#C1B6F8',
        301: '#CAC3EB',
        302: '#C6BDF1',
        400: '#A38CF3',
        401: '#AFA1DE',
        402: '#A996E9',
        500: '#845EEE',
        501: '#927BD1',
        502: '#8B6CE0',
        600: '#743EE4',
        601: '#805FC3',
        602: '#7A4FD3',
        700: '#652CD1',
        701: '#704DB0',
        702: '#6B3DC1',
        800: '#5425AF',
        801: '#5D4193',
        802: '#5933A1',
        900: '#46208F',
        901: '#4D3679',
        902: '#4A2B84',
    },
    Purple: {
        50: '#F9F5FE',
        51: '#F9F7FC',
        52: '#F9F6FD',
        100: '#F1E8FD',
        101: '#F2ECF9',
        102: '#F1EAFB',
        200: '#E6D6FC',
        201: '#E7DEF4',
        202: '#E7DAF8',
        300: '#D2B5F9',
        301: '#D4C3EB',
        302: '#D3BCF2',
        400: '#B686F5',
        401: '#B99CDF',
        402: '#B891EA',
        500: '#9D59EF',
        501: '#A077D1',
        502: '#9F68E0',
        600: '#873AE1',
        601: '#8A5BC0',
        602: '#894BD0',
        700: '#7429C6',
        701: '#7648A7',
        702: '#7539B6',
        800: '#6326A2',
        801: '#643F89',
        802: '#643296',
        900: '#512082',
        901: '#51346E',
        902: '#512A78',
    },
    Fuchsia: {
        50: '#FBF4FE',
        51: '#FAF6FC',
        52: '#FBF5FD',
        100: '#F7E9FD',
        101: '#F5EDF9',
        102: '#F6EBFB',
        200: '#EFD1FB',
        201: '#ECD9F3',
        202: '#EDD5F7',
        300: '#E6AEF7',
        301: '#DEBDE8',
        302: '#E2B5F0',
        400: '#DA7FF2',
        401: '#CD96DB',
        402: '#D48AE6',
        500: '#C951E7',
        501: '#B76FC9',
        502: '#C160D8',
        600: '#B135CC',
        601: '#9E53AE',
        602: '#A844BD',
        700: '#952AA9',
        701: '#844390',
        702: '#8D379C',
        800: '#7B248A',
        801: '#6D3876',
        802: '#742E80',
        900: '#672171',
        901: '#5B3161',
        902: '#612969',
    },
    Pink: {
        50: '#FBF2F8',
        51: '#F9F4F7',
        52: '#FAF3F8',
        100: '#F8E8F2',
        101: '#F5EBF1',
        102: '#F6EAF2',
        200: '#F4D1E7',
        201: '#EDD8E5',
        202: '#F1D4E6',
        300: '#EDACD2',
        301: '#E0B9D0',
        302: '#E7B3D1',
        400: '#E479B3',
        401: '#CF8EB1',
        402: '#D984B2',
        500: '#DA5597',
        501: '#BF7097',
        502: '#CD6297',
        600: '#C93B76',
        601: '#AD577A',
        602: '#BB4978',
        700: '#AE2D5D',
        701: '#944763',
        702: '#A13A60',
        800: '#90264D',
        801: '#7B3B52',
        802: '#85314F',
        900: '#782243',
        901: '#673347',
        902: '#6F2B45',
    },
    Rose: {
        50: '#FDF1F2',
        51: '#FBF3F4',
        52: '#FCF2F3',
        100: '#FBE5E6',
        101: '#F7E9EA',
        102: '#F9E7E8',
        200: '#F6CFD3',
        201: '#EED7D9',
        202: '#F2D3D6',
        300: '#EFA8B0',
        301: '#E1B6BB',
        302: '#E8AFB5',
        400: '#E97987',
        401: '#D38F97',
        402: '#DE848F',
        500: '#E14F62',
        501: '#C46C77',
        502: '#D25E6C',
        600: '#CF364C',
        601: '#B05561',
        602: '#C04556',
        700: '#AD2A3F',
        701: '#934450',
        702: '#A03747',
        800: '#92243B',
        801: '#7C3A47',
        802: '#872F41',
        900: '#7D2038',
        901: '#6A3341',
        902: '#74293C',
    },
};

/**
 * Color pairings.
 * Color name : Color Style : Result Color
 */
// tslint:disable-next-line: variable-name
export const ColorPairs = {
    Slate: {
        comp: 'Yellow',
        Tri: 'Example',
    },
    Gray: {
        comp: 'Orange',
    },
    Zinc: {
        comp: 'Yellow',
    },
    Neutral: {
        comp: 'Red',
    },
    Stone: {
        comp: 'Yellow',
    },
    Red: {
        comp: 'Emerald',
    },
    Orange: {
        comp: 'Sky',
    },
    Amber: {
        comp: 'Sky',
    },
    Yellow: {
        comp: 'Purple',
    },
    Lime: {
        comp: 'Fuchsia',
    },
    Green: {
        comp: 'Pink',
    },
    Emerald: {
        comp: 'Red',
    },
    Teal: {
        comp: 'Red',
    },
    Cyan: {
        comp: 'Orange',
    },
    Sky: {
        comp: 'Amber',
    },
    Blue: {
        comp: 'Amber',
    },
    Indigo: {
        comp: 'Yellow',
    },
    Violet: {
        comp: 'Yellow',
    },
    Purple: {
        comp: 'Yellow',
    },
    Fuchsia: {
        comp: 'Lime',
    },
    Pink: {
        comp: 'Green',
    },
    Rose: {
        comp: 'Green',
    },
};

export class PaletteData {

    /**
     * createMetroStyles
     */
    public createMetroStyles(): Array<ShapeStyle> {
        const metroStyles: Array<ShapeStyle> = [
            new ShapeStyle( 2, '#677489', [ 0, 0 ], 1, '#F8FAFC', FillStyle.Solid, undefined,
                1, '#20293A', 1, 'base' ),
            new ShapeStyle( 2, '#4BA3E3', [ 0, 0 ], 1, '#F2F9FE', FillStyle.Solid, undefined,
                1, '#265880', 2, 'base' ),
            new ShapeStyle( 2, '#5EC269', [ 0, 0 ], 1, '#F2FDF5', FillStyle.Solid, undefined,
                1, '#306339', 3, 'base' ),
            new ShapeStyle( 2, '#DA5597', [ 0, 0 ], 1, '#FBF2F8', FillStyle.Solid, undefined,
                1, '#90264D', 4, 'base' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FEFBED', FillStyle.Solid, undefined,
                1, '#88451D', 5, 'base' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#EFFDFF', FillStyle.Solid, undefined,
                1, '#2D5D73', 6, 'base' ),
            new ShapeStyle( 2, '#6466E9', [ 0, 0 ], 1, '#EFF2FE', FillStyle.Solid, undefined,
                1, '#36309D', 7, 'base' ),
            new ShapeStyle( 2, '#55B5A6', [ 0, 0 ], 1, '#F2FDFA', FillStyle.Solid, undefined,
                1, '#2B5D59', 8, 'base' ),

            new ShapeStyle( 2, '#677489', [ 0, 0 ], 1, '#F2F5F9', FillStyle.Solid, undefined,
                1, '#20293A', 9, 'base' ),
            new ShapeStyle( 2, '#4BA3E3', [ 0, 0 ], 1, '#E3F1FD', FillStyle.Solid, undefined,
                1, '#265880', 10, 'base' ),
            new ShapeStyle( 2, '#5EC269', [ 0, 0 ], 1, '#E2FBE8', FillStyle.Solid, undefined,
                1, '#306339', 11, 'base' ),
            new ShapeStyle( 2, '#DA5597', [ 0, 0 ], 1, '#F8E8F2', FillStyle.Solid, undefined,
                1, '#90264D', 12, 'base' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FCF3CC', FillStyle.Solid, undefined,
                1, '#88451D', 13, 'base' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#D7F9FD', FillStyle.Solid, undefined,
                1, '#2D5D73', 14, 'base' ),
            new ShapeStyle( 2, '#6466E9', [ 0, 0 ], 1, '#E1E7FD', FillStyle.Solid, undefined,
                1, '#36309D', 15, 'base' ),
            new ShapeStyle( 2, '#55B5A6', [ 0, 0 ], 1, '#D5FAF1', FillStyle.Solid, undefined,
                1, '#2B5D59', 16, 'base' ),

            new ShapeStyle( 2, '#4A5567', [ 0, 0 ], 1, '#E3E8EF', FillStyle.Solid, undefined,
                1, '#20293A', 17, 'base' ),
            new ShapeStyle( 2, '#3982C2', [ 0, 0 ], 1, '#C3E5FA', FillStyle.Solid, undefined,
                1, '#265880', 18, 'base' ),
            new ShapeStyle( 2, '#4BA154', [ 0, 0 ], 1, '#C7F5D3', FillStyle.Solid, undefined,
                1, '#306339', 19, 'base' ),
            new ShapeStyle( 2, '#C93B76', [ 0, 0 ], 1, '#F4D1E7', FillStyle.Solid, undefined,
                1, '#90264D', 20, 'base' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#F9E796', FillStyle.Solid, undefined,
                1, '#88451D', 21, 'base' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#B6F1FA', FillStyle.Solid, undefined,
                1, '#2D5D73', 22, 'base' ),
            new ShapeStyle( 2, '#4E46DC', [ 0, 0 ], 1, '#C9D2FA', FillStyle.Solid, undefined,
                1, '#36309D', 23, 'base' ),
            new ShapeStyle( 2, '#429288', [ 0, 0 ], 1, '#AEF4E4', FillStyle.Solid, undefined,
                1, '#2B5D59', 24, 'base' ),

            new ShapeStyle( 2, '#364153', [ 0, 0 ], 1, '#677489', FillStyle.Solid, undefined,
                1, '#FFFFFF', 25, 'base' ),
            new ShapeStyle( 2, '#2D679C', [ 0, 0 ], 1, '#4BA3E3', FillStyle.Solid, undefined,
                1, '#FFFFFF', 26, 'base' ),
            new ShapeStyle( 2, '#3C7E44', [ 0, 0 ], 1, '#5EC269', FillStyle.Solid, undefined,
                1, '#FFFFFF', 27, 'base' ),
            new ShapeStyle( 2, '#AE2D5D', [ 0, 0 ], 1, '#DA5597', FillStyle.Solid, undefined,
                1, '#FFFFFF', 28, 'base' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid, undefined,
                1, '#FFFFFF', 29, 'base' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid, undefined,
                1, '#FFFFFF', 30, 'base' ),
            new ShapeStyle( 2, '#4138C2', [ 0, 0 ], 1, '#6466E9', FillStyle.Solid, undefined,
                1, '#FFFFFF', 31, 'base' ),
            new ShapeStyle( 2, '#35746E', [ 0, 0 ], 1, '#55B5A6', FillStyle.Solid, undefined,
                1, '#FFFFFF', 32, 'base' ),

            new ShapeStyle( 2, '#20293A', [ 0, 0 ], 1, '#364153', FillStyle.Solid, undefined,
                1, '#FFFFFF', 33, 'base' ),
            new ShapeStyle( 2, '#265880', [ 0, 0 ], 1, '#2D679C', FillStyle.Solid, undefined,
                1, '#FFFFFF', 34, 'base' ),
            new ShapeStyle( 2, '#306339', [ 0, 0 ], 1, '#3C7E44', FillStyle.Solid, undefined,
                1, '#FFFFFF', 35, 'base' ),
            new ShapeStyle( 2, '#90264D', [ 0, 0 ], 1, '#AE2D5D', FillStyle.Solid, undefined,
                1, '#FFFFFF', 36, 'base' ),
            new ShapeStyle( 2, '#88451D', [ 0, 0 ], 1, '#A85923', FillStyle.Solid, undefined,
                1, '#FFFFFF', 37, 'base' ),
            new ShapeStyle( 2, '#2D5D73', [ 0, 0 ], 1, '#34728D', FillStyle.Solid, undefined,
                1, '#FFFFFF', 38, 'base' ),
            new ShapeStyle( 2, '#36309D', [ 0, 0 ], 1, '#4138C2', FillStyle.Solid, undefined,
                1, '#FFFFFF', 39, 'base' ),
            new ShapeStyle( 2, '#2B5D59', [ 0, 0 ], 1, '#35746E', FillStyle.Solid, undefined,
                1, '#FFFFFF', 40, 'base' ),
        ];
        return metroStyles;
    }


    public createTechnicStyles(): Array<ShapeStyle> {
        const styles: Array<ShapeStyle> = [
            new ShapeStyle( 2, '#677489', [ 0, 0 ], 1, '#F8FAFC', FillStyle.Solid,
                undefined, 1, '#20293A', 1, 'technic' ),
            new ShapeStyle( 2, '#737373', [ 0, 0 ], 1, '#FAFAFA', FillStyle.Solid,
                undefined, 1, '#262626', 2, 'technic' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#EFFDFF', FillStyle.Solid,
                undefined, 1, '#2D5D73', 3, 'technic' ),
            new ShapeStyle( 2, '#E2B53E', [ 0, 0 ], 1, '#FEFCEA', FillStyle.Solid,
                undefined, 1, '#7D501F', 4, 'technic' ),
            new ShapeStyle( 2, '#717179', [ 0, 0 ], 1, '#FAFAFA', FillStyle.Solid,
                undefined, 1, '#27272A', 5, 'technic' ),
            new ShapeStyle( 2, '#94CA42', [ 0, 0 ], 1, '#F8FEE9', FillStyle.Solid,
                undefined, 1, '#476121', 6, 'technic' ),
            new ShapeStyle( 2, '#77716D', [ 0, 0 ], 1, '#FAFAF9', FillStyle.Solid,
                undefined, 1, '#282524', 7, 'technic' ),
            new ShapeStyle( 2, '#6C727F', [ 0, 0 ], 1, '#F9FAFB', FillStyle.Solid,
                undefined, 1, '#212936', 8, 'technic' ),

            new ShapeStyle( 2, '#677489', [ 0, 0 ], 1, '#F2F5F9', FillStyle.Solid,
                undefined, 1, '#20293A', 9, 'technic' ),
            new ShapeStyle( 2, '#737373', [ 0, 0 ], 1, '#F5F5F5', FillStyle.Solid,
                undefined, 1, '#262626', 10, 'technic' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#D7F9FD', FillStyle.Solid,
                undefined, 1, '#2D5D73', 11, 'technic' ),
            new ShapeStyle( 2, '#E2B53E', [ 0, 0 ], 1, '#FCF9C9', FillStyle.Solid,
                undefined, 1, '#7D501F', 12, 'technic' ),
            new ShapeStyle( 2, '#717179', [ 0, 0 ], 1, '#F4F4F5', FillStyle.Solid,
                undefined, 1, '#27272A', 13, 'technic' ),
            new ShapeStyle( 2, '#94CA42', [ 0, 0 ], 1, '#EFFBD0', FillStyle.Solid,
                undefined, 1, '#476121', 14, 'technic' ),
            new ShapeStyle( 2, '#77716D', [ 0, 0 ], 1, '#F5F5F4', FillStyle.Solid,
                undefined, 1, '#282524', 15, 'technic' ),
            new ShapeStyle( 2, '#6C727F', [ 0, 0 ], 1, '#F7F8F9', FillStyle.Solid,
                undefined, 1, '#212936', 16, 'technic' ),

            new ShapeStyle( 2, '#4A5567', [ 0, 0 ], 1, '#E3E8EF', FillStyle.Solid,
                undefined, 1, '#20293A', 17, 'technic' ),
            new ShapeStyle( 2, '#525252', [ 0, 0 ], 1, '#E5E5E5', FillStyle.Solid,
                undefined, 1, '#262626', 18, 'technic' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#B6F1FA', FillStyle.Solid,
                undefined, 1, '#2D5D73', 19, 'technic' ),
            new ShapeStyle( 2, '#C18D30', [ 0, 0 ], 1, '#FDF097', FillStyle.Solid,
                undefined, 1, '#7D501F', 20, 'technic' ),
            new ShapeStyle( 2, '#52525A', [ 0, 0 ], 1, '#E4E4E7', FillStyle.Solid,
                undefined, 1, '#27272A', 21, 'technic' ),
            new ShapeStyle( 2, '#73A131', [ 0, 0 ], 1, '#DFF8A7', FillStyle.Solid,
                undefined, 1, '#476121', 22, 'technic' ),
            new ShapeStyle( 2, '#56534F', [ 0, 0 ], 1, '#E7E5E4', FillStyle.Solid,
                undefined, 1, '#282524', 23, 'technic' ),
            new ShapeStyle( 2, '#4D5562', [ 0, 0 ], 1, '#E5E7EB', FillStyle.Solid,
                undefined, 1, '#212936', 24, 'technic' ),

            new ShapeStyle( 2, '#364153', [ 0, 0 ], 1, '#677489', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 25, 'technic' ),
            new ShapeStyle( 2, '#404040', [ 0, 0 ], 1, '#737373', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 26, 'technic' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 27, 'technic' ),
            new ShapeStyle( 2, '#986523', [ 0, 0 ], 1, '#E2B53E', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 28, 'technic' ),
            new ShapeStyle( 2, '#3F3F45', [ 0, 0 ], 1, '#717179', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 29, 'technic' ),
            new ShapeStyle( 2, '#587B27', [ 0, 0 ], 1, '#94CA42', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 30, 'technic' ),
            new ShapeStyle( 2, '#43403C', [ 0, 0 ], 1, '#77716D', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 31, 'technic' ),
            new ShapeStyle( 2, '#394150', [ 0, 0 ], 1, '#6C727F', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 32, 'technic' ),

            new ShapeStyle( 2, '#20293A', [ 0, 0 ], 1, '#364153', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 33, 'technic' ),
            new ShapeStyle( 2, '#262626', [ 0, 0 ], 1, '#404040', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 34, 'technic' ),
            new ShapeStyle( 2, '#2D5D73', [ 0, 0 ], 1, '#34728D', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 35, 'technic' ),
            new ShapeStyle( 2, '#7D501F', [ 0, 0 ], 1, '#986523', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 36, 'technic' ),
            new ShapeStyle( 2, '#27272A', [ 0, 0 ], 1, '#3F3F45', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 37, 'technic' ),
            new ShapeStyle( 2, '#476121', [ 0, 0 ], 1, '#587B27', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 38, 'technic' ),
            new ShapeStyle( 2, '#282524', [ 0, 0 ], 1, '#43403C', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 39, 'technic' ),
            new ShapeStyle( 2, '#212936', [ 0, 0 ], 1, '#394150', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 40, 'technic' ),
        ];
        return styles;
    }

    public createOpulantStyles(): Array<ShapeStyle> {
        const styles: Array<ShapeStyle> = [
            new ShapeStyle( 2, '#C951E7', [ 0, 0 ], 1, '#FBF4FE', FillStyle.Solid,
                undefined, 1, '#7B248A', 1, 'opulant' ),
            new ShapeStyle( 2, '#DD524C', [ 0, 0 ], 1, '#FCF2F2', FillStyle.Solid,
                undefined, 1, '#8C2822', 2, 'opulant' ),
            new ShapeStyle( 2, '#E14F62', [ 0, 0 ], 1, '#FDF1F2', FillStyle.Solid,
                undefined, 1, '#92243B', 3, 'opulant' ),
            new ShapeStyle( 2, '#9D59EF', [ 0, 0 ], 1, '#F9F5FE', FillStyle.Solid,
                undefined, 1, '#6326A2', 4, 'opulant' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FEFBED', FillStyle.Solid,
                undefined, 1, '#88451D', 5, 'opulant' ),
            new ShapeStyle( 2, '#E2B53E', [ 0, 0 ], 1, '#FEFCEA', FillStyle.Solid,
                undefined, 1, '#7D501F', 6, 'opulant' ),
            new ShapeStyle( 2, '#DA5597', [ 0, 0 ], 1, '#FBF2F8', FillStyle.Solid,
                undefined, 1, '#90264D', 7, 'opulant' ),
            new ShapeStyle( 2, '#E87B35', [ 0, 0 ], 1, '#FEF7EE', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 8, 'opulant' ),

            new ShapeStyle( 2, '#C951E7', [ 0, 0 ], 1, '#F7E9FD', FillStyle.Solid,
                undefined, 1, '#7B248A', 9, 'opulant' ),
            new ShapeStyle( 2, '#DD524C', [ 0, 0 ], 1, '#F9E3E2', FillStyle.Solid,
                undefined, 1, '#8C2822', 10, 'opulant' ),
            new ShapeStyle( 2, '#E14F62', [ 0, 0 ], 1, '#FBE5E6', FillStyle.Solid,
                undefined, 1, '#92243B', 11, 'opulant' ),
            new ShapeStyle( 2, '#9D59EF', [ 0, 0 ], 1, '#F1E8FD', FillStyle.Solid,
                undefined, 1, '#6326A2', 12, 'opulant' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FCF3CC', FillStyle.Solid,
                undefined, 1, '#88451D', 13, 'opulant' ),
            new ShapeStyle( 2, '#E2B53E', [ 0, 0 ], 1, '#FCF9C9', FillStyle.Solid,
                undefined, 1, '#7D501F', 14, 'opulant' ),
            new ShapeStyle( 2, '#DA5597', [ 0, 0 ], 1, '#F8E8F2', FillStyle.Solid,
                undefined, 1, '#90264D', 15, 'opulant' ),
            new ShapeStyle( 2, '#E87B35', [ 0, 0 ], 1, '#FCEED8', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 16, 'opulant' ),

            new ShapeStyle( 2, '#B135CC', [ 0, 0 ], 1, '#EFD1FB', FillStyle.Solid,
                undefined, 1, '#7B248A', 17, 'opulant' ),
            new ShapeStyle( 2, '#CA3A31', [ 0, 0 ], 1, '#F6CCCB', FillStyle.Solid,
                undefined, 1, '#8C2822', 18, 'opulant' ),
            new ShapeStyle( 2, '#CF364C', [ 0, 0 ], 1, '#F6CFD3', FillStyle.Solid,
                undefined, 1, '#92243B', 19, 'opulant' ),
            new ShapeStyle( 2, '#873AE1', [ 0, 0 ], 1, '#E6D6FC', FillStyle.Solid,
                undefined, 1, '#6326A2', 20, 'opulant' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#F9E796', FillStyle.Solid,
                undefined, 1, '#88451D', 21, 'opulant' ),
            new ShapeStyle( 2, '#C18D30', [ 0, 0 ], 1, '#FDF097', FillStyle.Solid,
                undefined, 1, '#7D501F', 22, 'opulant' ),
            new ShapeStyle( 2, '#C93B76', [ 0, 0 ], 1, '#F4D1E7', FillStyle.Solid,
                undefined, 1, '#90264D', 23, 'opulant' ),
            new ShapeStyle( 2, '#D9622B', [ 0, 0 ], 1, '#F8D8B0', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 24, 'opulant' ),

            new ShapeStyle( 2, '#952AA9', [ 0, 0 ], 1, '#C951E7', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 25, 'opulant' ),
            new ShapeStyle( 2, '#AA2E26', [ 0, 0 ], 1, '#DD524C', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 26, 'opulant' ),
            new ShapeStyle( 2, '#AD2A3F', [ 0, 0 ], 1, '#E14F62', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 27, 'opulant' ),
            new ShapeStyle( 2, '#7429C6', [ 0, 0 ], 1, '#9D59EF', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 28, 'opulant' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 29, 'opulant' ),
            new ShapeStyle( 2, '#986523', [ 0, 0 ], 1, '#E2B53E', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 30, 'opulant' ),
            new ShapeStyle( 2, '#AE2D5D', [ 0, 0 ], 1, '#DA5597', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 31, 'opulant' ),
            new ShapeStyle( 2, '#B34A22', [ 0, 0 ], 1, '#E87B35', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 32, 'opulant' ),

            new ShapeStyle( 2, '#7B248A', [ 0, 0 ], 1, '#952AA9', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 33, 'opulant' ),
            new ShapeStyle( 2, '#8C2822', [ 0, 0 ], 1, '#AA2E26', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 34, 'opulant' ),
            new ShapeStyle( 2, '#92243B', [ 0, 0 ], 1, '#AD2A3F', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 35, 'opulant' ),
            new ShapeStyle( 2, '#6326A2', [ 0, 0 ], 1, '#7429C6', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 36, 'opulant' ),
            new ShapeStyle( 2, '#88451D', [ 0, 0 ], 1, '#A85923', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 37, 'opulant' ),
            new ShapeStyle( 2, '#7D501F', [ 0, 0 ], 1, '#986523', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 38, 'opulant' ),
            new ShapeStyle( 2, '#90264D', [ 0, 0 ], 1, '#AE2D5D', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 39, 'opulant' ),
            new ShapeStyle( 2, '#8E3B1E', [ 0, 0 ], 1, '#B34A22', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 40, 'opulant' ),
        ];
        return styles;
    }

    // MUTED STYLES

    public createMetroMutedStyles(): Array<ShapeStyle> {
        return this.createMutedStyles( this.createMetroStyles());
    }

    public createTechnicMutedStyles(): Array<ShapeStyle> {
        return this.createMutedStyles( this.createTechnicStyles());
    }

    public createOpulantMutedStyles(): Array<ShapeStyle> {
        return this.createMutedStyles( this.createOpulantStyles());
    }

    public createApexMutedStyles(): Array<ShapeStyle> {
        return this.createMutedStyles( this.createApexStyles());
    }

    public createApexStyles(): Array<ShapeStyle> {
        const styles: Array<ShapeStyle> = [
            new ShapeStyle( 2, '#717179', [ 0, 0 ], 1, '#FAFAFA', FillStyle.Solid,
                undefined, 1, '#27272A', 1, 'apex' ),
            new ShapeStyle( 2, '#845EEE', [ 0, 0 ], 1, '#F5F3FE', FillStyle.Solid,
                undefined, 1, '#5425AF', 2, 'apex' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FEFBED', FillStyle.Solid,
                undefined, 1, '#88451D', 3, 'apex' ),
            new ShapeStyle( 2, '#94CA42', [ 0, 0 ], 1, '#F8FEE9', FillStyle.Solid,
                undefined, 1, '#476121', 4, 'apex' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#EFFDFF', FillStyle.Solid,
                undefined, 1, '#2D5D73', 5, 'apex' ),
            new ShapeStyle( 2, '#4E80EE', [ 0, 0 ], 1, '#F0F6FE', FillStyle.Solid,
                undefined, 1, '#263FA9', 6, 'apex' ),
            new ShapeStyle( 2, '#6466E9', [ 0, 0 ], 1, '#EFF2FE', FillStyle.Solid,
                undefined, 1, '#36309D', 7, 'apex' ),
            new ShapeStyle( 2, '#9D59EF', [ 0, 0 ], 1, '#F9F5FE', FillStyle.Solid,
                undefined, 1, '#6326A2', 8, 'apex' ),

            new ShapeStyle( 2, '#717179', [ 0, 0 ], 1, '#F4F4F5', FillStyle.Solid,
                undefined, 1, '#27272A', 9, 'apex' ),
            new ShapeStyle( 2, '#845EEE', [ 0, 0 ], 1, '#ECE9FC', FillStyle.Solid,
                undefined, 1, '#5425AF', 10, 'apex' ),
            new ShapeStyle( 2, '#E9A23B', [ 0, 0 ], 1, '#FCF3CC', FillStyle.Solid,
                undefined, 1, '#88451D', 11, 'apex' ),
            new ShapeStyle( 2, '#94CA42', [ 0, 0 ], 1, '#EFFBD0', FillStyle.Solid,
                undefined, 1, '#476121', 12, 'apex' ),
            new ShapeStyle( 2, '#52B3D0', [ 0, 0 ], 1, '#D7F9FD', FillStyle.Solid,
                undefined, 1, '#2D5D73', 13, 'apex' ),
            new ShapeStyle( 2, '#4E80EE', [ 0, 0 ], 1, '#DEE9FC', FillStyle.Solid,
                undefined, 1, '#263FA9', 14, 'apex' ),
            new ShapeStyle( 2, '#6466E9', [ 0, 0 ], 1, '#E1E7FD', FillStyle.Solid,
                undefined, 1, '#36309D', 15, 'apex' ),
            new ShapeStyle( 2, '#9D59EF', [ 0, 0 ], 1, '#F1E8FD', FillStyle.Solid,
                undefined, 1, '#6326A2', 16, 'apex' ),

            new ShapeStyle( 2, '#52525A', [ 0, 0 ], 1, '#E4E4E7', FillStyle.Solid,
                undefined, 1, '#27272A', 17, 'apex' ),
            new ShapeStyle( 2, '#743EE4', [ 0, 0 ], 1, '#DCD6FB', FillStyle.Solid,
                undefined, 1, '#5425AF', 18, 'apex' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#F9E796', FillStyle.Solid,
                undefined, 1, '#88451D', 19, 'apex' ),
            new ShapeStyle( 2, '#73A131', [ 0, 0 ], 1, '#DFF8A7', FillStyle.Solid,
                undefined, 1, '#476121', 20, 'apex' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#B6F1FA', FillStyle.Solid,
                undefined, 1, '#2D5D73', 21, 'apex' ),
            new ShapeStyle( 2, '#3662E3', [ 0, 0 ], 1, '#C4DAFB', FillStyle.Solid,
                undefined, 1, '#263FA9', 22, 'apex' ),
            new ShapeStyle( 2, '#4E46DC', [ 0, 0 ], 1, '#C9D2FA', FillStyle.Solid,
                undefined, 1, '#36309D', 23, 'apex' ),
            new ShapeStyle( 2, '#873AE1', [ 0, 0 ], 1, '#E6D6FC', FillStyle.Solid,
                undefined, 1, '#6326A2', 24, 'apex' ),

            new ShapeStyle( 2, '#3F3F45', [ 0, 0 ], 1, '#717179', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 25, 'apex' ),
            new ShapeStyle( 2, '#652CD1', [ 0, 0 ], 1, '#845EEE', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 26, 'apex' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 27, 'apex' ),
            new ShapeStyle( 2, '#587B27', [ 0, 0 ], 1, '#94CA42', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 28, 'apex' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 29, 'apex' ),
            new ShapeStyle( 2, '#2A4DD1', [ 0, 0 ], 1, '#4E80EE', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 30, 'apex' ),
            new ShapeStyle( 2, '#4138C2', [ 0, 0 ], 1, '#6466E9', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 31, 'apex' ),
            new ShapeStyle( 2, '#7429C6', [ 0, 0 ], 1, '#9D59EF', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 32, 'apex' ),

            new ShapeStyle( 2, '#27272A', [ 0, 0 ], 1, '#3F3F45', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 33, 'apex' ),
            new ShapeStyle( 2, '#5425AF', [ 0, 0 ], 1, '#652CD1', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 34, 'apex' ),
            new ShapeStyle( 2, '#88451D', [ 0, 0 ], 1, '#A85923', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 35, 'apex' ),
            new ShapeStyle( 2, '#476121', [ 0, 0 ], 1, '#587B27', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 36, 'apex' ),
            new ShapeStyle( 2, '#2D5D73', [ 0, 0 ], 1, '#34728D', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 37, 'apex' ),
            new ShapeStyle( 2, '#263FA9', [ 0, 0 ], 1, '#2A4DD1', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 38, 'apex' ),
            new ShapeStyle( 2, '#36309D', [ 0, 0 ], 1, '#4138C2', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 39, 'apex' ),
            new ShapeStyle( 2, '#6326A2', [ 0, 0 ], 1, '#7429C6', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 40, 'apex' ),
        ];
        return styles;
    }

    public getAllPalettes(): Array<Palette> {
        return [
            new Palette( 'base', this.createMetroStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'basem', this.createMetroMutedStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),

            new Palette( 'technic', this.createTechnicStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'technicm', this.createTechnicMutedStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),

            new Palette( 'opulant', this.createOpulantStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'opulantm', this.createOpulantMutedStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),

            new Palette( 'apex', this.createApexStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'apexm', this.createApexMutedStyles(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
        ];
    }

    /**
     * Line Styles
     */
    /**
     * getAllLinePalettes
     */
    public getAllLinePalettes(): Array<Palette> {
       return  [
            new Palette( 'lines-metro', this.createLinesMetroLinedPalette(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-technic', this.createLinesTechnicLinedPalette(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-opulant', this.createLinesOpulantPalette(), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-apex', this.createLinesApexPalette()),
       ];
    }

    public getHighlightedLinePalettes(): Array<Palette> {
        return [
            new Palette( 'lines-metro', this.createHighlightedStyles( this.createLinesMetroLinedPalette()), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-technic', this.createHighlightedStyles( this.createLinesTechnicLinedPalette()), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-opulant', this.createHighlightedStyles( this.createLinesOpulantPalette()), 'PANEL_HEADINGS.CREATELY_STYLES' ),
            new Palette( 'lines-apex', this.createHighlightedStyles( this.createLinesApexPalette())),
        ];
    }

    public createLinesMetroLinedPalette(): Array<LineStyle> {
        const styles: Array<LineStyle> = [
            new ShapeStyle( 2, '#97A3B6', [ 0, 0 ], 1, '#97A3B6', FillStyle.Solid,
                undefined, 1, '#20293A', 1, 'base' ),
            new ShapeStyle( 2, '#62BAF3', [ 0, 0 ], 1, '#62BAF3', FillStyle.Solid,
                undefined, 1, '#265880', 2, 'base' ),
            new ShapeStyle( 2, '#77DB89', [ 0, 0 ], 1, '#77DB89', FillStyle.Solid,
                undefined, 1, '#306339', 3, 'base' ),
            new ShapeStyle( 2, '#E479B3', [ 0, 0 ], 1, '#E479B3', FillStyle.Solid,
                undefined, 1, '#90264D', 4, 'base' ),
            new ShapeStyle( 2, '#F2C14B', [ 0, 0 ], 1, '#F2C14B', FillStyle.Solid,
                undefined, 1, '#88451D', 5, 'base' ),
            new ShapeStyle( 2, '#65D0EA', [ 0, 0 ], 1, '#65D0EA', FillStyle.Solid,
                undefined, 1, '#2D5D73', 6, 'base' ),
            new ShapeStyle( 2, '#838CF1', [ 0, 0 ], 1, '#838CF1', FillStyle.Solid,
                undefined, 1, '#36309D', 7, 'base' ),
            new ShapeStyle( 2, '#68D1BF', [ 0, 0 ], 1, '#68D1BF', FillStyle.Solid,
                undefined, 1, '#2B5D59', 8, 'base' ),

            new ShapeStyle( 2, '#364153', [ 0, 0 ], 1, '#677489', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 9, 'base' ),
            new ShapeStyle( 2, '#2D679C', [ 0, 0 ], 1, '#4BA3E3', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 10, 'base' ),
            new ShapeStyle( 2, '#3C7E44', [ 0, 0 ], 1, '#5EC269', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 11, 'base' ),
            new ShapeStyle( 2, '#AE2D5D', [ 0, 0 ], 1, '#DA5597', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 12, 'base' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 13, 'base' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 14, 'base' ),
            new ShapeStyle( 2, '#4138C2', [ 0, 0 ], 1, '#6466E9', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 15, 'base' ),
            new ShapeStyle( 2, '#35746E', [ 0, 0 ], 1, '#55B5A6', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 16, 'base' ),

            new ShapeStyle( 2, '#4A5567', [ 0, 0 ], 1, '#4A5567', FillStyle.Solid,
                undefined, 1, '#20293A', 17, 'base' ),
            new ShapeStyle( 2, '#3982C2', [ 0, 0 ], 1, '#3982C2', FillStyle.Solid,
                undefined, 1, '#265880', 18, 'base' ),
            new ShapeStyle( 2, '#4BA154', [ 0, 0 ], 1, '#4BA154', FillStyle.Solid,
                undefined, 1, '#306339', 19, 'base' ),
            new ShapeStyle( 2, '#C93B76', [ 0, 0 ], 1, '#C93B76', FillStyle.Solid,
                undefined, 1, '#90264D', 20, 'base' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#CC7B2E', FillStyle.Solid,
                undefined, 1, '#88451D', 21, 'base' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#418FAE', FillStyle.Solid,
                undefined, 1, '#2D5D73', 22, 'base' ),
            new ShapeStyle( 2, '#4E46DC', [ 0, 0 ], 1, '#4E46DC', FillStyle.Solid,
                undefined, 1, '#36309D', 23, 'base' ),
            new ShapeStyle( 2, '#429288', [ 0, 0 ], 1, '#429288', FillStyle.Solid,
                undefined, 1, '#2B5D59', 24, 'base' ),

            new ShapeStyle( 3, '#364153', [ 0, 0 ], 1, '#364153', FillStyle.Solid,
                undefined, 1, '#20293A', 25, 'base' ),
            new ShapeStyle( 3, '#2D679C', [ 0, 0 ], 1, '#2D679C', FillStyle.Solid,
                undefined, 1, '#265880', 26, 'base' ),
            new ShapeStyle( 3, '#3C7E44', [ 0, 0 ], 1, '#3C7E44', FillStyle.Solid,
                undefined, 1, '#306339', 27, 'base' ),
            new ShapeStyle( 3, '#AE2D5D', [ 0, 0 ], 1, '#AE2D5D', FillStyle.Solid,
                undefined, 1, '#90264D', 28, 'base' ),
            new ShapeStyle( 3, '#A85923', [ 0, 0 ], 1, '#A85923', FillStyle.Solid,
                undefined, 1, '#88451D', 29, 'base' ),
            new ShapeStyle( 3, '#34728D', [ 0, 0 ], 1, '#34728D', FillStyle.Solid,
                undefined, 1, '#2D5D73', 30, 'base' ),
            new ShapeStyle( 3, '#4138C2', [ 0, 0 ], 1, '#4138C2', FillStyle.Solid,
                undefined, 1, '#36309D', 31, 'base' ),
            new ShapeStyle( 3, '#35746E', [ 0, 0 ], 1, '#35746E', FillStyle.Solid,
                undefined, 1, '#2B5D59', 32, 'base' ),
        ];

        return styles;
    }
    public createLinesTechnicLinedPalette(): Array<LineStyle> {
        const styles: Array<LineStyle> = [
            new ShapeStyle( 2, '#97A3B6', [ 0, 0 ], 1, '#97A3B6', FillStyle.Solid,
                undefined, 1, '#20293A', 1, 'technic' ),
            new ShapeStyle( 2, '#A3A3A2', [ 0, 0 ], 1, '#A3A3A2', FillStyle.Solid,
                undefined, 1, '#262626', 2, 'technic' ),
            new ShapeStyle( 2, '#65D0EA', [ 0, 0 ], 1, '#65D0EA', FillStyle.Solid,
                undefined, 1, '#2D5D73', 3, 'technic' ),
            new ShapeStyle( 2, '#F3CE49', [ 0, 0 ], 1, '#F3CE49', FillStyle.Solid,
                undefined, 1, '#7D501F', 4, 'technic' ),
            new ShapeStyle( 2, '#A1A1A9', [ 0, 0 ], 1, '#A1A1A9', FillStyle.Solid,
                undefined, 1, '#27272A', 5, 'technic' ),
            new ShapeStyle( 2, '#C9F078', [ 0, 0 ], 1, '#C9F078', FillStyle.Solid,
                undefined, 1, '#476121', 6, 'technic' ),
            new ShapeStyle( 2, '#A7A29E', [ 0, 0 ], 1, '#A7A29E', FillStyle.Solid,
                undefined, 1, '#282524', 7, 'technic' ),
            new ShapeStyle( 2, '#9DA3AE', [ 0, 0 ], 1, '#9DA3AE', FillStyle.Solid,
                undefined, 1, '#212936', 8, 'technic' ),

            new ShapeStyle( 2, '#364153', [ 0, 0 ], 1, '#677489', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 9, 'technic' ),
            new ShapeStyle( 2, '#404040', [ 0, 0 ], 1, '#737373', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 10, 'technic' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 11, 'technic' ),
            new ShapeStyle( 2, '#986523', [ 0, 0 ], 1, '#E2B53E', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 12, 'technic' ),
            new ShapeStyle( 2, '#3F3F45', [ 0, 0 ], 1, '#717179', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 13, 'technic' ),
            new ShapeStyle( 2, '#587B27', [ 0, 0 ], 1, '#94CA42', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 14, 'technic' ),
            new ShapeStyle( 2, '#43403C', [ 0, 0 ], 1, '#77716D', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 15, 'technic' ),
            new ShapeStyle( 2, '#394150', [ 0, 0 ], 1, '#6C727F', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 16, 'technic' ),

            new ShapeStyle( 2, '#4A5567', [ 0, 0 ], 1, '#4A5567', FillStyle.Solid,
                undefined, 1, '#20293A', 17, 'technic' ),
            new ShapeStyle( 2, '#525252', [ 0, 0 ], 1, '#525252', FillStyle.Solid,
                undefined, 1, '#262626', 18, 'technic' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#418FAE', FillStyle.Solid,
                undefined, 1, '#2D5D73', 19, 'technic' ),
            new ShapeStyle( 2, '#C18D30', [ 0, 0 ], 1, '#C18D30', FillStyle.Solid,
                undefined, 1, '#7D501F', 20, 'technic' ),
            new ShapeStyle( 2, '#52525A', [ 0, 0 ], 1, '#52525A', FillStyle.Solid,
                undefined, 1, '#27272A', 21, 'technic' ),
            new ShapeStyle( 2, '#73A131', [ 0, 0 ], 1, '#73A131', FillStyle.Solid,
                undefined, 1, '#476121', 22, 'technic' ),
            new ShapeStyle( 2, '#56534F', [ 0, 0 ], 1, '#56534F', FillStyle.Solid,
                undefined, 1, '#282524', 23, 'technic' ),
            new ShapeStyle( 2, '#4D5562', [ 0, 0 ], 1, '#4D5562', FillStyle.Solid,
                undefined, 1, '#212936', 24, 'technic' ),

            new ShapeStyle( 3, '#364153', [ 0, 0 ], 1, '#364153', FillStyle.Solid,
                undefined, 1, '#20293A', 25, 'technic' ),
            new ShapeStyle( 3, '#404040', [ 0, 0 ], 1, '#404040', FillStyle.Solid,
                undefined, 1, '#262626', 26, 'technic' ),
            new ShapeStyle( 3, '#34728D', [ 0, 0 ], 1, '#34728D', FillStyle.Solid,
                undefined, 1, '#2D5D73', 27, 'technic' ),
            new ShapeStyle( 3, '#986523', [ 0, 0 ], 1, '#986523', FillStyle.Solid,
                undefined, 1, '#7D501F', 28, 'technic' ),
            new ShapeStyle( 3, '#3F3F45', [ 0, 0 ], 1, '#3F3F45', FillStyle.Solid,
                undefined, 1, '#27272A', 29, 'technic' ),
            new ShapeStyle( 3, '#587B27', [ 0, 0 ], 1, '#587B27', FillStyle.Solid,
                undefined, 1, '#476121', 30, 'technic' ),
            new ShapeStyle( 3, '#43403C', [ 0, 0 ], 1, '#43403C', FillStyle.Solid,
                undefined, 1, '#282524', 31, 'technic' ),
            new ShapeStyle( 3, '#394150', [ 0, 0 ], 1, '#394150', FillStyle.Solid,
                undefined, 1, '#212936', 32, 'technic' ),
        ];

        return styles;
    }

    public createLinesOpulantPalette(): Array<LineStyle> {
        const styles: Array<LineStyle> = [
            new ShapeStyle( 2, '#DA7FF2', [ 0, 0 ], 1, '#DA7FF2', FillStyle.Solid,
                undefined, 1, '#7B248A', 1, 'opulant' ),
            new ShapeStyle( 2, '#E77975', [ 0, 0 ], 1, '#E77975', FillStyle.Solid,
                undefined, 1, '#8C2822', 2, 'opulant' ),
            new ShapeStyle( 2, '#E97987', [ 0, 0 ], 1, '#E97987', FillStyle.Solid,
                undefined, 1, '#92243B', 3, 'opulant' ),
            new ShapeStyle( 2, '#B686F5', [ 0, 0 ], 1, '#B686F5', FillStyle.Solid,
                undefined, 1, '#6326A2', 4, 'opulant' ),
            new ShapeStyle( 2, '#F2C14B', [ 0, 0 ], 1, '#F2C14B', FillStyle.Solid,
                undefined, 1, '#88451D', 5, 'opulant' ),
            new ShapeStyle( 2, '#F3CE49', [ 0, 0 ], 1, '#F3CE49', FillStyle.Solid,
                undefined, 1, '#7D501F', 6, 'opulant' ),
            new ShapeStyle( 2, '#E479B3', [ 0, 0 ], 1, '#E479B3', FillStyle.Solid,
                undefined, 1, '#90264D', 7, 'opulant' ),
            new ShapeStyle( 2, '#ED974F', [ 0, 0 ], 1, '#ED974F', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 8, 'opulant' ),

            new ShapeStyle( 2, '#952AA9', [ 0, 0 ], 1, '#C951E7', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 9, 'opulant' ),
            new ShapeStyle( 2, '#AA2E26', [ 0, 0 ], 1, '#DD524C', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 10, 'opulant' ),
            new ShapeStyle( 2, '#AD2A3F', [ 0, 0 ], 1, '#E14F62', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 11, 'opulant' ),
            new ShapeStyle( 2, '#7429C6', [ 0, 0 ], 1, '#9D59EF', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 12, 'opulant' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 13, 'opulant' ),
            new ShapeStyle( 2, '#986523', [ 0, 0 ], 1, '#E2B53E', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 14, 'opulant' ),
            new ShapeStyle( 2, '#AE2D5D', [ 0, 0 ], 1, '#DA5597', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 15, 'opulant' ),
            new ShapeStyle( 2, '#B34A22', [ 0, 0 ], 1, '#E87B35', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 16, 'opulant' ),

            new ShapeStyle( 2, '#B135CC', [ 0, 0 ], 1, '#B135CC', FillStyle.Solid,
                undefined, 1, '#7B248A', 17, 'opulant' ),
            new ShapeStyle( 2, '#CA3A31', [ 0, 0 ], 1, '#CA3A31', FillStyle.Solid,
                undefined, 1, '#8C2822', 18, 'opulant' ),
            new ShapeStyle( 2, '#CF364C', [ 0, 0 ], 1, '#CF364C', FillStyle.Solid,
                undefined, 1, '#92243B', 19, 'opulant' ),
            new ShapeStyle( 2, '#873AE1', [ 0, 0 ], 1, '#873AE1', FillStyle.Solid,
                undefined, 1, '#6326A2', 20, 'opulant' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#CC7B2E', FillStyle.Solid,
                undefined, 1, '#88451D', 21, 'opulant' ),
            new ShapeStyle( 2, '#C18D30', [ 0, 0 ], 1, '#C18D30', FillStyle.Solid,
                undefined, 1, '#7D501F', 22, 'opulant' ),
            new ShapeStyle( 2, '#C93B76', [ 0, 0 ], 1, '#C93B76', FillStyle.Solid,
                undefined, 1, '#90264D', 23, 'opulant' ),
            new ShapeStyle( 2, '#D9622B', [ 0, 0 ], 1, '#D9622B', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 24, 'opulant' ),

            new ShapeStyle( 3, '#952AA9', [ 0, 0 ], 1, '#952AA9', FillStyle.Solid,
                undefined, 1, '#7B248A', 25, 'opulant' ),
            new ShapeStyle( 3, '#AA2E26', [ 0, 0 ], 1, '#AA2E26', FillStyle.Solid,
                undefined, 1, '#8C2822', 26, 'opulant' ),
            new ShapeStyle( 3, '#AD2A3F', [ 0, 0 ], 1, '#AD2A3F', FillStyle.Solid,
                undefined, 1, '#92243B', 27, 'opulant' ),
            new ShapeStyle( 3, '#7429C6', [ 0, 0 ], 1, '#7429C6', FillStyle.Solid,
                undefined, 1, '#6326A2', 28, 'opulant' ),
            new ShapeStyle( 3, '#A85923', [ 0, 0 ], 1, '#A85923', FillStyle.Solid,
                undefined, 1, '#88451D', 29, 'opulant' ),
            new ShapeStyle( 3, '#986523', [ 0, 0 ], 1, '#986523', FillStyle.Solid,
                undefined, 1, '#7D501F', 30, 'opulant' ),
            new ShapeStyle( 3, '#AE2D5D', [ 0, 0 ], 1, '#AE2D5D', FillStyle.Solid,
                undefined, 1, '#90264D', 31, 'opulant' ),
            new ShapeStyle( 3, '#B34A22', [ 0, 0 ], 1, '#B34A22', FillStyle.Solid,
                undefined, 1, '#8E3B1E', 32, 'opulant' ),
        ];

        return styles;
    }
    public createLinesApexPalette(): Array<LineStyle> {
        const styles: Array<LineStyle> = [
            new ShapeStyle( 2, '#A1A1A9', [ 0, 0 ], 1, '#A1A1A9', FillStyle.Solid,
                undefined, 1, '#27272A', 1, 'apex' ),
            new ShapeStyle( 2, '#A38CF3', [ 0, 0 ], 1, '#A38CF3', FillStyle.Solid,
                undefined, 1, '#5425AF', 2, 'apex' ),
            new ShapeStyle( 2, '#F2C14B', [ 0, 0 ], 1, '#F2C14B', FillStyle.Solid,
                undefined, 1, '#88451D', 3, 'apex' ),
            new ShapeStyle( 2, '#C9F078', [ 0, 0 ], 1, '#C9F078', FillStyle.Solid,
                undefined, 1, '#476121', 4, 'apex' ),
            new ShapeStyle( 2, '#65D0EA', [ 0, 0 ], 1, '#65D0EA', FillStyle.Solid,
                undefined, 1, '#2D5D73', 5, 'apex' ),
            new ShapeStyle( 2, '#70A3F3', [ 0, 0 ], 1, '#70A3F3', FillStyle.Solid,
                undefined, 1, '#263FA9', 6, 'apex' ),
            new ShapeStyle( 2, '#838CF1', [ 0, 0 ], 1, '#838CF1', FillStyle.Solid,
                undefined, 1, '#36309D', 7, 'apex' ),
            new ShapeStyle( 2, '#B686F5', [ 0, 0 ], 1, '#B686F5', FillStyle.Solid,
                undefined, 1, '#6326A2', 8, 'apex' ),

            new ShapeStyle( 2, '#3F3F45', [ 0, 0 ], 1, '#717179', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 9, 'apex' ),
            new ShapeStyle( 2, '#652CD1', [ 0, 0 ], 1, '#845EEE', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 10, 'apex' ),
            new ShapeStyle( 2, '#A85923', [ 0, 0 ], 1, '#E9A23B', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 11, 'apex' ),
            new ShapeStyle( 2, '#587B27', [ 0, 0 ], 1, '#94CA42', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 12, 'apex' ),
            new ShapeStyle( 2, '#34728D', [ 0, 0 ], 1, '#52B3D0', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 13, 'apex' ),
            new ShapeStyle( 2, '#2A4DD1', [ 0, 0 ], 1, '#4E80EE', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 14, 'apex' ),
            new ShapeStyle( 2, '#4138C2', [ 0, 0 ], 1, '#6466E9', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 15, 'apex' ),
            new ShapeStyle( 2, '#7429C6', [ 0, 0 ], 1, '#9D59EF', FillStyle.Solid,
                undefined, 1, '#FFFFFF', 16, 'apex' ),

            new ShapeStyle( 2, '#52525A', [ 0, 0 ], 1, '#52525A', FillStyle.Solid,
                undefined, 1, '#27272A', 17, 'apex' ),
            new ShapeStyle( 2, '#743EE4', [ 0, 0 ], 1, '#743EE4', FillStyle.Solid,
                undefined, 1, '#5425AF', 18, 'apex' ),
            new ShapeStyle( 2, '#CC7B2E', [ 0, 0 ], 1, '#CC7B2E', FillStyle.Solid,
                undefined, 1, '#88451D', 19, 'apex' ),
            new ShapeStyle( 2, '#73A131', [ 0, 0 ], 1, '#73A131', FillStyle.Solid,
                undefined, 1, '#476121', 20, 'apex' ),
            new ShapeStyle( 2, '#418FAE', [ 0, 0 ], 1, '#418FAE', FillStyle.Solid,
                undefined, 1, '#2D5D73', 21, 'apex' ),
            new ShapeStyle( 2, '#3662E3', [ 0, 0 ], 1, '#3662E3', FillStyle.Solid,
                undefined, 1, '#263FA9', 22, 'apex' ),
            new ShapeStyle( 2, '#4E46DC', [ 0, 0 ], 1, '#4E46DC', FillStyle.Solid,
                undefined, 1, '#36309D', 23, 'apex' ),
            new ShapeStyle( 2, '#873AE1', [ 0, 0 ], 1, '#873AE1', FillStyle.Solid,
                undefined, 1, '#6326A2', 24, 'apex' ),

            new ShapeStyle( 3, '#3F3F45', [ 0, 0 ], 1, '#3F3F45', FillStyle.Solid,
                undefined, 1, '#27272A', 25, 'apex' ),
            new ShapeStyle( 3, '#652CD1', [ 0, 0 ], 1, '#652CD1', FillStyle.Solid,
                undefined, 1, '#5425AF', 26, 'apex' ),
            new ShapeStyle( 3, '#A85923', [ 0, 0 ], 1, '#A85923', FillStyle.Solid,
                undefined, 1, '#88451D', 27, 'apex' ),
            new ShapeStyle( 3, '#587B27', [ 0, 0 ], 1, '#587B27', FillStyle.Solid,
                undefined, 1, '#476121', 28, 'apex' ),
            new ShapeStyle( 3, '#34728D', [ 0, 0 ], 1, '#34728D', FillStyle.Solid,
                undefined, 1, '#2D5D73', 29, 'apex' ),
            new ShapeStyle( 3, '#2A4DD1', [ 0, 0 ], 1, '#2A4DD1', FillStyle.Solid,
                undefined, 1, '#263FA9', 30, 'apex' ),
            new ShapeStyle( 3, '#4138C2', [ 0, 0 ], 1, '#4138C2', FillStyle.Solid,
                undefined, 1, '#36309D', 31, 'apex' ),
            new ShapeStyle( 3, '#7429C6', [ 0, 0 ], 1, '#7429C6', FillStyle.Solid,
                undefined, 1, '#6326A2', 32, 'apex' ),
        ];


        return styles;
    }

    /**
     * Color Utility Method
     * TODO: Move to a Utility class
     */


    private rgbToHsl( rgb ) {
        if ( typeof rgb !== 'string' ) {
            rgb = String( rgb );
        }
        const r = parseInt( rgb.substring( 1, 3 ), 16 ) / 255;
        const g = parseInt( rgb.substring( 3, 5 ), 16 ) / 255;
        const b = parseInt( rgb.substring( 5, 7 ), 16 ) / 255;

        const max = Math.max( r, g, b );
        const min = Math.min( r, g, b );
        let h;
        let s;
        const l = ( max + min ) / 2;

        if ( max === min ) {
            h = s = 0;
        } else {
            const d = max - min;
            s = ( l > 0.5 ) ? d / ( 2 - max - min ) : d / ( max + min );
            h = 0;
            switch ( max ) {
                case r: h = ( g - b ) / d + (( g < b ) ? 6 : 0 ); break;
                case g: h = ( b - r ) / d + 2; break;
                case b: h = ( r - g ) / d + 4; break;
            }
            h /= 6;
        }

        return [ h * 360, s * 100, l * 100 ];
    }

    private hue2rgb( p, q, t ) {
        if ( t < 0 ) {
            t += 1;
        }
        if ( t > 1 ) {
            t -= 1;
        }
        if ( t < 1 / 6 ) {
            return p + ( q - p ) * 6 * t;
        }
        if ( t < 1 / 2 ) {
            return q;
        }
        if ( t < 2 / 3 ) {
            return p + ( q - p ) * ( 2 / 3 - t ) * 6;
        }
        return p;
    }

    private hslToRgb( hsl ) {
        const h = hsl[0] / 360;
        const s = hsl[1] / 100;
        const l = hsl[2] / 100;

        let r;
        let g;
        let b;

        if ( s === 0 ) {
            r = g = b = l; // achromatic
        } else {

            const q = l < 0.5 ? l * ( 1 + s ) : l + s - l * s;
            const p = 2 * l - q;
            r = this.hue2rgb( p, q, h + 1 / 3 );
            g = this.hue2rgb( p, q, h );
            b = this.hue2rgb( p, q, h - 1 / 3 );
        }

        return '#' + Math.round( r * 255 ).toString( 16 ).padStart( 2, '0' ) +
            Math.round( g * 255 ).toString( 16 ).padStart( 2, '0' ) +
            Math.round( b * 255 ).toString( 16 ).padStart( 2, '0' );
    }

    private hslAdjust( color, hue, saturation, luminosity ) {
        const hsl = this.rgbToHsl( color );
        hsl[0] += hue / 360;
        hsl[1] *= saturation / 100;
        hsl[2] *= luminosity / 100;
        return this.hslToRgb( hsl );
    }

    /**
     * createMutedPalette
     */
    private createMutedStyles( styles: ShapeStyle[]) {
        const mutedStyles = styles.map( shapeStyle =>
            this.createCustomStyles( shapeStyle, shapeStyle.themeId + 'm', 100, 60 ));
        return mutedStyles;
    }
    private createCustomStyles(
        shapeStyle: ShapeStyle, themeId: string, hue: number = 100, saturation: number = 100, luminosity: number = 100,
    ) {
        const fillColor = this.hslAdjust(
            shapeStyle.fillColor, hue, saturation, luminosity );
        const lineColor = this.hslAdjust(
            shapeStyle.lineColor, hue, saturation, luminosity );
        const textColor = this.hslAdjust(
            shapeStyle.textColor, hue, saturation, luminosity );
        return new ShapeStyle(
            shapeStyle.lineThickness, lineColor, shapeStyle.lineStyle, shapeStyle.lineAlpha,
            fillColor, shapeStyle.fillStyle, shapeStyle.fillGradient, shapeStyle.fillAlpha,
            textColor, shapeStyle.themeIndex, themeId,
        );
    }

    private createHighlightedStyles ( styles: LineStyle[]) {
        const mutedStyles = styles.map(( shapeStyle, index ) => {
            if ( index > 15 ) {
                shapeStyle.textColor = '#FFFFFF';
            }
            return shapeStyle;
        });
        return mutedStyles;
    }

}
// tslint:disable-next-line: max-file-line-count

