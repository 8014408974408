import { Injectable } from '@angular/core';
import { Sakota } from '@creately/sakota';
import { applyChange, observableDiff } from 'deep-diff';
import { IModifier } from 'flux-core';

@Injectable()
export class DiagramDiffService {

    /**
     * This method generates Sakota compatible diff between 2 daigram docs.
     * if a property of type array is modified and the array length is not changed
     * then this will generate somewhat different diff compared to full replacement of array.
     * In the above mentioned scenario prop will be treated as an object rather than an array.
     * @param source
     * @param target
     * @returns sakota diff
     */
    public getSakotaDiff( source: any, target: any ): IModifier {
        const sakotaWrapped = Sakota.create( source );
        this._applyChanges( sakotaWrapped, source, target );
        return sakotaWrapped.__sakota__.getChanges();
    }

    /**
     * This method compares the diff between source and target object and applies the diff to source object.
     * @param source source object
     * @param target target object
     */
    public applyChanges( source: any, target: any ) {
        this._applyChanges( source, source, target );
    }

    protected _applyChanges( sakotaWrapped: any, source: any, target: any ) {
        observableDiff( source, target, d => {
            if ( d.kind === 'A' ) {
                const path = d.path.slice(); // path is shared, so creating a copy
                // create a deep temp copy of the array to be modified
                const arrayItem = this.getProp( sakotaWrapped, path ).slice();
                applyChange( this.getWrappedObj( path.slice().reverse(), arrayItem ), target, d );
                const prop = path.pop();
                this.getProp( sakotaWrapped, path )[prop] = arrayItem;
            } else {
                applyChange( sakotaWrapped, target, d );
                if ( d.kind === 'E' && typeof d.path[d.path.length - 1] === 'number' ) {
                    // if the property is a number then it is an array item
                    const path = d.path.slice(); // path is shared, so creating a copy
                    path.pop(); // popping out index
                    const arrayItem = this.getProp( sakotaWrapped, path );
                    if ( Array.isArray( arrayItem )) { // this is mostly true
                        const prop = path.pop();
                        this.getProp( sakotaWrapped, path )[prop] = arrayItem.slice();
                    }
                }
            }
        });
    }

    protected getProp( obj: any, path: string[]) {
        if ( !path || path.length === 0 ) {
            return obj;
        }
        const [ prop, ...newPath ] = path;
        return this.getProp( obj[prop], newPath );
    }

    protected getWrappedObj( path: string[], val: any ) {
        if ( path.length === 0 ) {
            return val;
        }
        const [ prop, ...newPath ] = path;
        return this.getWrappedObj( newPath, {
            [prop]: val,
        });
    }

}
