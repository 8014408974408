import { Injectable } from '@angular/core';
import { IChainOutcome, IResponsibility, InitializationChainStatus } from 'flux-core';
import { Observable } from 'rxjs';
import { filter, mapTo, take } from 'rxjs/operators';
import { UserLocator } from '../user-locator.svc';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * This state use for redirect user to 2 factor authentication setup page
 * if the 2fa is mandate by team admin.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Damith
 * @since   2023-12-06
 */
@Injectable()
export class TwoFactorAuthResponsibility implements IResponsibility  {

    public name: string = 'TwoFactorAuthResponsibility';

    constructor ( protected userLocator: UserLocator ) {}

    /**
     * Returns the user 2fa state.
     * @param status - current status of the sequence
     * @return Observable which emits true if user is required to setup 2fa
     */
    public checkState( status: InitializationChainStatus ): Observable<boolean> {
        return this.userLocator.getUserData().pipe(
            filter( userModel => !!userModel ),
            take( 1 ),
            mapTo( window.gravity.client.is2FASetupRequired()),
        );
    }

    /**
     * Determines the next responsibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        return [ 'DiagramResponsibility' ];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        const is2FASetupRequired = status.states.TwoFactorAuthResponsibility;
        if ( is2FASetupRequired ) {
            return { type: 'command', action: 'Setup2FA', wait: true };
        }
        return undefined;
    }

}
