import { Injectable } from '@angular/core';
import { ResponseType } from '../../command/abstract-http.cmd';
import { ResourceLoaderDynamic } from './resource-loader-dynamic.svc';

/**
 * This is an extended version of the {@link ResourceLoaderDynamic}
 * that specifically caters to loading SVG
 * It retains the standard SVG loader loading and caching functionality
 * but handles responses differently. All content is loaded as text
 *
 * @author  Mehdhi
 * @since   2021-09-05
 */
@Injectable()
export class SVGLoader extends ResourceLoaderDynamic {

    /**
     * Returns the http request options
     * All data is loaded as Texts.
     */
    protected get requestOptions() {
        return {
            observe: 'events',
            responseType: ResponseType.Text,
        };
    }

}
