import { Injectable } from '@angular/core';
import { IFeatureItem } from './feature-item.i';
import { ContainerEnv } from 'flux-core';
import { values, find, clone } from 'lodash';
import { IShapeDefinition, IDataItem, KeyCode, KeyStringMac, KeyStringWindows } from 'flux-definition';
import { IDataFeatureItem } from './data-feature-item.i';
import { IAbstractFeatureItem } from './abstract-feature-item.i';
import { FocusContext } from '../../system/focus-context.enum';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { ShapeActionTransformer } from '../../editor/feature/shape-action-transformer.svc';
import { IEDataFeatureItem } from './edata-feature-item.i';
import { ConvertToObjectTransformer } from '../../editor/feature/convert-to-object-transformer.svc';
import { TranslateService } from '@ngx-translate/core';

/**
 * FeatureList
 * Class representing a set of fetaures that used across the application.
 * i.e Copy shapes, Delete Shapes, Zoom a diagram etc.
 *
 * This provides following functionality:
 * Register a feature against a unique feature id
 * Return the specific feature for a given id
 *
 * This can be injected in the applicable places and get the featureItem for a specific feature.
 * i.e: ContextMenuController can use this to get the context menu items for a specific feature id.
 *      KeyBoardController can use this to get the specific feature for a key event.
 *
 * There are two primary places where features are defined; within the application
 * and on a shapes definition ( shape specific features ). Features that are universal
 * to the application are predefined in nucleus itself - these are registered with the feature
 * list when the application starts. Shape specific featuers are defined in a shapes definition
 * and are registered when a shape of a specific type is used for the first time.
 */
@Injectable()
export class FeatureList {

    /**
     * This object holds the featureItem for a given feature id.
     */
    protected _features: {[ id: string ]: IFeatureItem };

    constructor(
        protected env: ContainerEnv,
        private translate: TranslateService,
    ) {
        this._features = {};
    }

    /**
     * Returns all the registered features
     */
    public get features(): IFeatureItem[]  {
        return values( this._features );
    }

    /**
     * This method registers a set of features against a unique feature id.
     */
    public register( feature: IFeatureItem ) {
        this._features[feature.id] = feature;
        this.generateShortcutText( feature );
    }

    /**
     * This method returns featureitem for a given id.
     */
    public getFeature( id: string ): IFeatureItem {
        return this._features[id];
    }

    /**
     * A shape may contain shape specific features defined in its definition.
     * These features are registered with the feature list as a shape type
     * is loaded for the first time in the application. Once registered, these
     * are available in the feature list just like regular features.
     * For an overview of variants of features that may be registered this way,
     * please refer to {@link IDataFeatureItem} and {@link IFeatureItem} interfaces.
     * @param def - shape definition.
     */
    public registerShapeFeatures( def: IShapeDefinition ) {
        if ( this.shouldRegisterFeatures( def )) {
            def.features.forEach(( fDef: IAbstractFeatureItem ) => {
                let fItem = clone( fDef );
                fItem.id = `${def.defId}.${def.version}.${fItem.id}`;
                fItem.shapeDefId = `${def.defId}.${def.version}`;
                if ( fItem.dataItemId ) {
                    fItem = this.createDataFeatureItem( fItem, def );
                } else if ( fItem.eDefId ) {
                    fItem = this.createEDataFeatureItem( fItem, def );
                } else if ( !fItem.commandEvent ) {
                    fItem.commandEvent = DiagramCommandEvent.doShapeAction;
                    fItem.transformer = ShapeActionTransformer;
                } else if ( fItem.commandEvent === 'generateNewShape' ) {
                    // FIXME: Find another way to attach DiagramCommandEvent to shape feature - Lina
                    fItem.commandEvent = DiagramCommandEvent.generateNewShape;
                }
                if ( !fItem.context ) {
                    fItem.context = FocusContext.Canvas;
                }
                this.register( fItem );
            });
        }
    }

    /**
     * This replace the key sequence by OS
     */
    protected replaceKeySequnce( keycodes: KeyCode[]) {
        keycodes.forEach(( keycode, i ) => {
            if ( keycode === KeyCode.Meta ) {
                if ( this.env.isMac ) {
                    keycodes[i] = KeyCode.Command;
                } else {
                    keycodes[i] = KeyCode.Control;
                }
            }
        });
    }

    /**
     * Get a key combination from a shortcut key binding
     * If there are multiple key combination returns the first combination
     * i.e returns Ctrl+A
     */
    protected getShortCutText( keys: KeyCode[]): string {
        if ( keys.length > 0 ) {
            if ( this.env.isMac ) {
                return this.getKeyString( keys, KeyStringMac ).join( ' ' );
            } else {
                return this.getKeyString( keys, KeyStringWindows ).join( ' + ' );
            }
        }
    }

    /**
     * Gets the correct key string based on keyStringName which can be either
     * KeyStringMac or KeyStringWindows. Returns an array of these strings to match
     * the keycode combination. i.e if keys are [Command, A ]; this returns [ ⌘, A] for Mac
     * and [ Cmd, A ] for Windows.
     */
    protected getKeyString( keys: KeyCode[], keyStringName: any ): string[] {
        const text: string[] = [];
        if ( keys ) {
            keys.forEach( keycode => {
                text.push( keyStringName[KeyCode[keycode]]);
            });
        }
        return text;
    }

    /**
     * Generates the shortcut text to be displayed in the UI
     * representation of the feature.
     * @param feature - feature item
     */
    private generateShortcutText ( feature: IFeatureItem ) {
        if ( !feature.shortcuts || feature.shortcuts.length === 0 ) {
            return;
        }
        feature.shortcuts.forEach( shortcut => this.replaceKeySequnce( shortcut.keySequence ));
        if ( !feature.shortcutText ) {
            Object.defineProperty( feature, 'shortcutText', {
                get : () => {
                    const keySequence = feature.shortcuts[0] && feature.shortcuts[0].keySequence;
                    return this.getShortCutText( keySequence );
                },
            });
        }
    }

    /**
     * Some features defined in a shape definition are bound a data item.
     * Such features define themselves based on the data item. This method
     * derives information from the data item definition creates sets it
     * to the corresponding feature item as needed.
     * @param fItem - feature item
     * @param def - shape definition
     * @return - feature item with information from data item def added to it
     */
    private createDataFeatureItem( fItem: IDataFeatureItem, def: IShapeDefinition ): IDataFeatureItem {
        let dataItem: IDataItem<any> = undefined;
        if ( def.data && def.data[ fItem.dataItemId ]) {
            let dataDef;
            if (( def as any ).dataDef ) { // FIXME this is dodgy. Please revisit! CJ
                dataDef = ( def as any ).dataDef[ fItem.dataItemId ];
            }
            // const dataDef = def.data[ fItem.dataItemId ];
            const dataValue = def.data[ fItem.dataItemId ];
            dataItem = Object.assign( dataValue, dataDef );
        }
        if ( !dataItem ) {
            throw new Error ( `The data item definition for feature ${fItem.id} could not be found.` );
        }
        // TODO: Attach command that updates a data item
        fItem.commandEvent = DiagramCommandEvent.updateDataItems;
        fItem.dataType = dataItem.type;
        return fItem as IDataFeatureItem;
    }

    private createEDataFeatureItem( fItem: IEDataFeatureItem, def: IShapeDefinition ): IFeatureItem {
        return {
            id: `${def.defId}.${def.version}.convertToObject.${fItem.eDefId}`,
            commandEvent: DiagramCommandEvent.openConvertToObjectDialog,
            label: this.translate.instant( 'COMMANDS.CONVERT_TO_SPECIFIC_OBJECT', { type: fItem.eType }),
            shortcuts: [],
            data: {
                defId: fItem.eDefId,
            },
            shortcutText: '',
            icon: 'nu-ic-data',
            context: FocusContext.CanvasSelected,
            transformer: ConvertToObjectTransformer,
        };
    }

    /**
     * Checks if features from the given definition needs to be registered with
     * the feature list.
     */
    private shouldRegisterFeatures( def: IShapeDefinition ): boolean {
        return def && def.features && def.features.length > 0 &&
            !find( this.features, { shapeDefId: `${def.defId}.${def.version}` });
    }

}
