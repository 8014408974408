import { SETTINGS_DATAITEM_ID } from './../../../base/ui/shape-data-editor/shape-data-settings.cmp';
import { DESCRIPTION_DATAITEM_ID } from './../../../base/ui/shape-data-editor/data-items-renderer.cmp';
import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { map } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * UI componet to pick the dataiems of a given shape
 */
@Component({
    selector: 'dataitems-picker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <perfect-scrollbar style="max-height: 320px;" [config]="{ suppressScrollX: 1 }">
            <div *ngFor="let item of items | async ">
                <div class="dataitems-picker-item" >
                    <input type="checkbox" data-id="{{item.id}}" >
                    <labeleditable-dataitem [data]="item" ></labeleditable-dataitem>
                </div>
            </div>
            <div class="dataitems-picker-done">
                <button (click)="onDoneBtnClick()" class="btn-small btn-primary" >Done</button>
            </div>
        </perfect-scrollbar>
    `,
    styleUrls: [ './dataitems-picker.cmp.scss' ],
})
export class DataitemsPicker implements AfterViewInit, OnDestroy {


    @Input()
    public shapeId: string;

    @Input()
    public diagramId: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    /**
     * Data items
     */
    public items: Subject<any[]>;

    /**
     * Emits the ids of the selected dataitems
     */
    public change: Subject<string[]>;

    public subs = [];

    /**
     * Constructor
     */
    constructor(
        protected ll: DiagramLocatorLocator,
        protected er: ElementRef,
    ) {
        this.change = new Subject();
        this.items = new Subject();
    }

    public ngAfterViewInit() {

        const sub = this.ll.forDiagram( this.diagramId, false ).getDiagramOnce().pipe(
            map( d => {
                const dataItems =  Object.values( d.getShapeDataItems( this.shapeId )).filter(
                    ( i: any ) => {
                        const visible = ( i.visibility || []).find( v => v.type === 'editor' );
                        return visible && i.id !== DESCRIPTION_DATAITEM_ID && i.id !== SETTINGS_DATAITEM_ID;
                    },
                );
                return dataItems;
            }),
        ).subscribe( dataItems => this.items.next( dataItems ));
        this.subs.push( sub );

    }

    /**
     * Unsubscribe from all subscriptions
     */
     public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public onDoneBtnClick() {
        const selected = [];
        ( this.er.nativeElement as HTMLElement )
            .querySelectorAll( '.dataitems-picker-item > input' ).forEach(( input: HTMLInputElement ) => {
                if ( input.checked ) {
                    selected.push( input.id );
                }
            });
        this.change.next( selected );
    }

}
