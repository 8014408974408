import { Tracker } from 'flux-core';
import { IDataItemUIControl } from './data-items-uic.i';
import { Subject, BehaviorSubject } from 'rxjs';
import { Component, ChangeDetectionStrategy, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Date picker UI controller
 * The component will emit the date as the timestamp as the user picks
 * or manually types a valid date
 */
@Component({
    selector: 'date-picker-uic',
    template: `
        <mat-form-field appearance="fill">
            <input placeholder="{{ placeHolderText }}"
                matInput [matDatepicker]="picker" [value]="dateSubject | async"
                    (blur)="onDateChange($event)" (dateChange)="onDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    `,
    styleUrls: [ './date-picker-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerUIC implements IDataItemUIControl<number>, OnInit {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @Input()
    public data?: any;

    @Input()
    public placeHolderText = this.translate.instant( 'PLACEHOLDERS.CHOOSE_A_DATE' );

    public id: string;

    public dateSubject: BehaviorSubject<Date>;

    /**
     * This subject will emit the timestamp as the user picks
     * or manually types a valid date
     */
    @Output()
    public change: Subject<number>;

    constructor(
        private translate: TranslateService,
    ) {
        this.change = new Subject();
        this.dateSubject = new BehaviorSubject( null );
    }

    ngOnInit() {
        if ( this.data ) {
            this.setData( this.data );
        }
    }

    public setData( data: any ) {
        if ( data.value ) {
            this.dateSubject.next( new Date( data.value ));
        }
    }

    public onDateChange( e ) {
        if ( e.value instanceof Date && !isNaN( e.value )) {
            const timestamp = e.value.getTime();
            this.change.next( timestamp );
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.date.change` );
            }
        }
    }

}
