<div class="plan-window-container fx-cover">
    <div class="plan-window-inner fx-center-without-flex" data-testid="plan-window">
        <div *ngIf="currentPlan | async as plan">
            <!-- start registration-another-page -->
            <div id="registration-another-page" *ngIf="plan === 'premium'">
                <div class="notification">
                    <div class="wrapper">
                        <div class="main-heading">
                            <h3>{{ translate.get('PLAN_WINDOW.TITLE')|async }}</h3>
                        </div>
                        <p>{{ translate.get('PLAN_WINDOW.DESCRIPTION_2')|async }}</p>
                        <a class="btn-ok"
                            (click)="reloadAfterConversion()"
                            [trackAction]="{ event: 'conversion.dialog.warning.ok.click', data: { value1Type: 'planChoice', value1: 'PREMIUM' } }">
                            {{ translate.get('PLAN_WINDOW.RELOAD_CREATELY')|async }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- end registration-another-page -->
        </div>
        <div *ngIf="showPriceContent | async" class="content">
            <a
                *ngIf="(showOfferPrices | async)"
                (click)="openPlansPage()"
                [trackAction]="{ event: 'conversion.dialog.plans.banner.click', data: { value1Type: '40% off', value1: 'Annual-Promo_Personal+Team' } }"
                class="annual-promo-banner"
                data-testid="annual-promo-banner"
            >
                <span class="annual-promo-banner-text">
                    <strong>
                        {{translate.get( 'PROMO_BANNER_MESSAGES.HEADER_MSG_PART1' ) | async}}
                    </strong>
                        {{translate.get( 'PROMO_BANNER_MESSAGES.HEADER_MSG_PART2' ) | async}}
                    <strong>
                        {{translate.get( 'PROMO_BANNER_MESSAGES.PLANS_LINK_TEXT') | async}}
                    </strong>
                    🎉
                </span>
            </a>

            <div class="container ph-padding-top-20 ph-app-plans plans-section">
                <div class="columns">
                    <div class="column">
                        <div class="ph-demo-plans">
                            <div class="columns">
                                <div class="column">
                                    <table class="ph-demo-plans-table is-fullwidth">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th class="ph-demo-plans-free">{{ translate.get('PLAN_WINDOW.FREE')|async }}</th>
                                                <th class="ph-demo-plans-personal">{{ translate.get('PLAN_WINDOW.PERSONAL')|async }}</th>
                                                <th class="ph-demo-plans-team">{{ translate.get('PLAN_WINDOW.TEAM')|async }}</th>
                                                <th class="ph-demo-plans-enterprise">{{ translate.get('PLAN_WINDOW.ENTERPRISE')|async }}</th>
                                            </tr>
                                            <tr class="ph-demo-plans-price">
                                                <td></td>
                                                <td>
                                                    <span class="price-offer">$0</span>
                                                </td>
                                                <td *ngIf="(showOfferPrices | async) as showPersonalOffer; else personalOriginal">
                                                    <span class="price-offer">$4/{{ translate.get('PLAN_WINDOW.MONTH')|async }}</span>
                                                    <span class="price-without-offer">$6.95/{{ translate.get('PLAN_WINDOW.MONTH')|async }}</span>
                                                </td>
                                                <ng-template #personalOriginal>
                                                    <td>
                                                        <span class="price-offer">$6.95/{{ translate.get('PLAN_WINDOW.MONTH')|async }}</span>
                                                    </td>
                                                </ng-template>
                                                <td *ngIf="(showOfferPrices | async) as showTeamOffer; else teamOriginal">
                                                    <span class="price-offer">$4.75/{{ translate.get('PLAN_WINDOW.MONTH_PER_USER')|async }}</span>
                                                    <span class="price-without-offer">$8/{{ translate.get('PLAN_WINDOW.MONTH_PER_USER')|async }}</span>
                                                </td>
                                                <ng-template #teamOriginal>
                                                    <td>
                                                        <span class="price-offer">$8/{{ translate.get('PLAN_WINDOW.MONTH_PER_USER')|async }}</span>
                                                    </td>
                                                </ng-template>
                                                <td>
                                                    <span class="price-offer">{{ translate.get('PLAN_WINDOW.CUSTOM_PRICING')|async }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.DOCUMENTS')|async }}</td>
                                                <td>3</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.FOLDERS')|async }}</td>
                                                <td>1</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.NUMBER_OF_SHAPES')|async }}</td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-free-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.NUMBER_OF_SHAPES_FREE_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.LIMITED')|async }}
                                                    </span>
                                                </td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.UNLIMITED')|async }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.COLLABORATION')|async }}</td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-free-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.COLLABORATION_FREE_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.BASIC')|async }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-free-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.COLLABORATION_PERSONAL_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.BASIC')|async }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-team-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.COLLABORATION_UNLIMITED_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.ADVANCED')|async }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span
                                                        class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-enterprise-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.COLLABORATION_UNLIMITED_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.ADVANCED')|async }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.IMPORT_EXPORT')|async }}</td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-free-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.IMPORT_EXPORT_FREE_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.LIMITED')|async }}
                                                    </span>
                                                </td>
                                                <td>{{ translate.get('PLAN_WINDOW.ALL')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.ALL')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.ALL')|async }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ translate.get('PLAN_WINDOW.TEAM_ADMIN')|async }}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ translate.get('PLAN_WINDOW.YES')|async }}</td>
                                                <td>{{ translate.get('PLAN_WINDOW.YES')|async }}</td>
                                            </tr>
                                            <tr>
                                                <td>SSO/SCIM</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <span class="tooltip is-tooltip-multiline is-tooltip-bottom ph-demo-plans-enterprise-tooltp"
                                                        [attr.data-tooltip]="translate.get('PLAN_WINDOW.SSO_ENTERPRISE_TOOLTIP')|async">
                                                            {{ translate.get('PLAN_WINDOW.YES')|async }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <a id="ph-free-signup-button"
                                                        class="button is-dark"
                                                        (click)="choosePublicPlan()"
                                                        disabled
                                                        [trackAction]="{ event: 'conversion.dialog.plans.click', data: { value1Type: 'plan', value1: 'FREE' } }">
                                                            {{ translate.get('PLAN_WINDOW.CURRENT_PLAN')|async }}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a id="ph-cloud-per-annual-button"
                                                        class="button is-primary"
                                                        (click)="choosePremiumPlan('PERSONAL')"
                                                        [trackAction]="{ event: 'conversion.dialog.plans.click', data: { value1Type: 'plan', value1: 'PERSONAL' } }">
                                                            {{ translate.get('PLAN_WINDOW.UPGRADE')|async }}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a id="ph-cloud-team-annual-button"
                                                        class="button is-primary"
                                                        (click)="choosePremiumPlan('TEAM')"
                                                        [trackAction]="{ event: 'conversion.dialog.plans.click', data: { value1Type: 'plan', value1: 'TEAM' } }">
                                                            {{ translate.get('PLAN_WINDOW.UPGRADE')|async }}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a id="ph-enterprise-quote-button"
                                                        class="button is-dark"
                                                        (click)="choosePremiumPlan('ENTERPRISES')"
                                                        [trackAction]="{ event: 'conversion.dialog.plans.click', data: { value1Type: 'plan', value1: 'ENTERPRISES' } }">
                                                            {{ translate.get('PLAN_WINDOW.GET_QUOTE')|async }}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
            ​
                        <div id="ph-freshsales-enterprise-request-quote" class="ph-enterprise-request-quote ph-freshsales-enterprise-request-quote modal animated fadeIn faster">
                            <div class="modal-background"></div>
                            <div class="modal-card">
                                <button class="delete" aria-label="close"></button>
                                <section class="modal-card-body">
                                    <script
                                        src="https://creately-cgx.freshsales.io/web_forms/0577b8d22e0def72c4db855f4670f5389ba61ebceee1694213f99047ce219e11/form.js"
                                        crossorigin="anonymous"
                                        id="fs_0577b8d22e0def72c4db855f4670f5389ba61ebceee1694213f99047ce219e11"></script>
                                </section>
                            </div>
                        </div>
            ​
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

​
