import { IValidationError } from 'flux-definition/src';

export interface ICsvMappingError {
    rowIndex: number;
    columnIndex: number | string;
    parsedValue: any;
    value: string | undefined;
    error: IValidationError;
}

/**
 * CSVImportValidationError is a custom error used to identify and handle errors
 * across the command framework.
 *
 */
export class CSVImportValidationError extends Error {
    public errors: ICsvMappingError[];

    constructor ( message: string, errors: ICsvMappingError[] = []) {
        super()/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, CSVImportValidationError.prototype );
        this.message = message;
        this.name = 'CSVImportValidationError';
        this.errors = errors;
    }
}
