import { StateService, Matrix } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { AbstractCoordinate } from './abstract-coordinate';
import { ICoordinateConverter } from './coordinate-converter.i';

/**
 * This service helps convert coordinates from viewport coordinate space
 * into coordinates of the diagram. It considers the diagram
 * zoom and diagram pan when calculating coordinates.
 *
 * @author  Ramishka
 * @since   2017-10-21
 */
@Injectable()
export class ViewportToDiagramCoordinate extends AbstractCoordinate implements ICoordinateConverter {

    /**
     * Constructor. Injects the state service
     * @param state zoom diagram state service
     */
    constructor( @Inject( StateService ) protected state: StateService<any, any> ) {
        super( state );
    }

    /**
     * Converts an x coordinate of viewport into diagram coordinate space
     * @param originalX value to convert
     */
    public x( originalX: number ): number {
        return ( originalX / this.zoomLevel ) - ( this.panX / this.zoomLevel );
    }

    /**
     * Converts a y coordinate of viewport into diagram coordinate space
     * @param originalY value to conver        st
     */
    public y( originalY: number ): number {
        return ( originalY / this.zoomLevel ) - ( this.panY / this.zoomLevel );
    }

    /**
     * Converts an a width value of viewport into diagram coordinate space
     * @param originalWidth value to convert
     */
    public width( originalWidth: number ): number {
        return originalWidth / this.zoomLevel;
    }

    /**
     * Converts an a height value of viewport into diagram coordinate space
     * @param originalHeight value to convert
     */
    public height( originalHeight: number ): number {
        return originalHeight / this.zoomLevel;
    }

    /**
     * Converts a vertical scale value on viewport into diagram coordinate space
     * @param originalScaleY value to convert
     */
    public scale( originalScale: number ): number {
        return originalScale / this.zoomLevel;
    }

    /**
     * Clones and converts a matrix from one coordinate space to another
     * @param matrix - matrix that will be converted
     */
    public matrix( matrix: Matrix ): Matrix {
        const clonedMatrix: Matrix = matrix.clone();
        clonedMatrix.scale( 1 / this.zoomLevel, 1 / this.zoomLevel );
        clonedMatrix.tx = this.x( matrix.tx );
        clonedMatrix.ty = this.y( matrix.ty );
        return clonedMatrix;
    }
}

