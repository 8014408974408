import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the OPTION_LIST data type. Data items in shape models with the OPTION_LIST
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class OptionListDataItem extends AbstractDataItem<DataType.OPTION_LIST> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof OptionListDataItem.prototype.value ): IValidationError {
        if ( value === undefined ) {
            return null;
        }
        if ( Array.isArray( value ) && value.every( v => this.isValidOption( v ))) {
            return null;
        }
        if ( typeof value === 'string' && this.isValidOption( value )) {
            return null;
        }
        return { message: `"${value}" is not a valid option` };
    }

    /**
     * Checks whether the given value is a valid option.
     */
    private isValidOption( value: string ): boolean {
        return this.typeParams.options.findIndex( opt => opt.value === value ) !== -1;
    }
}
