import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractInitializationCommand, Command, ModalController } from 'flux-core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Setup2FAWindow } from '../../ui/auth/setup-2fa-window.cmp';

/**
 * Command for authentication.
 * This is fired by the initialization sequence when authentication is required.
 */
@Injectable()
@Command()
export class Setup2FA extends AbstractInitializationCommand {

    constructor( protected modalController: ModalController,
                 protected router: Router ) {
        super( router )/* istanbul ignore next */;
    }

    /**
     * Number of milliseconds to wait before execute step finishes.
     * Zero or negative means infinite wait.
     */
    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    /**
     * Uses the authentication service to show the login widget.
     * @return  Observable that emits when the login widget closes with
     * a successful or failed authentication.
     */
    public execute(): Observable<any> {
        return this.modalController.show( Setup2FAWindow, { inputs: {
            appLevel: true,
            allowClose: false,
            currentStep: 'step2',
        }}).pipe(
            tap({
                error: error => {
                    this.log.error( '2 FA setup error occurred', error );
                    this.navigateToRoute( this.data.status );
                },
                complete: () => {
                    this.log.debug( '2 FA setup completed' );
                    this.navigateToRoute( this.data.status );
                },
            }),
        );
    }
}

Object.defineProperty( Setup2FA, 'name', {
    writable: true,
    value: 'Setup2FA',
});
