import { Suggestion } from '@tiptap/suggestion';
import { Extension } from '@tiptap/core';
import { DynamicComponent } from 'flux-core/src/ui';
import { Injector, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TipTapEmojiListCmp } from './tiptap-emoji-list.cmp';

// tslint:disable:member-ordering
/**
 * TiptapEmojiService
 */
export class TiptapEmojiService {

    /**
     * Returns 'items' list and 'render' which are required by the tiptap
     * editor suggestions extension
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create( injector: Injector, viewCR: ViewContainerRef ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const instance = new TiptapEmojiService( injector,  cfr, viewCR );
        return { items: instance.items, render: instance.render, subs: instance.subs };
    }

    public subs: Subscription[] = [];

    private constructor(
        protected injector: Injector,
        protected cfr: ComponentFactoryResolver,
        protected viewCR: ViewContainerRef,
    ) {

    }

    /**
     * This callback is called by the tiptap's suggestions extention
     */
     public items = ({ editor, query }) =>
          editor.storage.emoji.emojis
            .filter(({ shortcodes, tags }) =>
                shortcodes.find( shortcode => shortcode.startsWith( query.toLowerCase()))
                || tags.find( tag => tag.startsWith( query.toLowerCase())))
            .slice( 0, 5 )

    /**
     * This callback is called by the tiptap's suggestions extention
     * The TipTapEmojiListCmp angular component is rendered as the
     * menu dropdown
     */
    public render = () => {
        let component: TipTapEmojiListCmp;
        let dc: DynamicComponent;
        let itemRef: ComponentRef<any>;

        return {
            onStart: props => {
                if ( !props.editor.isFocused ) {
                    return;
                }
                dc = new DynamicComponent( this.cfr, this.injector );
                itemRef = dc.makeComponent( TipTapEmojiListCmp );
                itemRef.changeDetectorRef.detectChanges();
                component =  itemRef.instance;
                component.updateProps( props );
                dc.insert( this.viewCR, itemRef );
                itemRef.changeDetectorRef.detectChanges();
            },

            onUpdate:  props => {
                if ( component && itemRef ) {
                    component.updateProps( props );
                    itemRef.changeDetectorRef.detectChanges();
                }
            },
            onKeyDown:  props => {
                if ( props.event.key === 'Escape' && itemRef ) {
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                    return true;
                }
                if ( component && itemRef ) {
                    const val = component.onKeyDown( props );
                    itemRef.changeDetectorRef.detectChanges();
                    return val;
                }
            },
            onExit: () => {
                if ( itemRef ) {
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                }
            },
        };
    }


}

export let suggestionCmd = Extension.create({
    name: 'suggestionCmd',

    addOptions() {
      return {
        suggestion: {
          char: ':',
          command: ({ editor, range, props }) => {
            props.command({ editor, range, props });
          },
        },
      };
    },

    addProseMirrorPlugins( this ) {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
});
