import { Injectable } from '@angular/core';
import { AbstractCommand, CommandInterfaces, Command, EventIdentifier, EventCollector, CommandScenario, JsonUtil } from 'flux-core';
import { IConnectorPoint } from 'flux-diagram-composer';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { IPoint2D } from 'flux-definition';

/**
 * Command converts an array of connector points in viewport coordinates to
 * diagram coordinates.
 *
 *  data: {
 *      points: IPoint2D[]
 *  }
 *
 *  resultData: {
 *      points: IPoint2D[],
 *      // + extra fields
 *  }
 *
 */
@Injectable()
@Command()
export class ConvertViewportForPoints extends AbstractCommand {
    /**
     * This command is used for data conversion purposes only.
     * Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    /**
     * This command converts a map of partial connector points.
     */
    public static get dataDefinition(): {}  {
        return {
            points: true,
        };
    }

    /**
     * Constructor. Injects the coordinate converter.
     */
    constructor( protected converter: ViewportToDiagramCoordinate ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Converts all the matrices in the map using the coordinate converter.
     */
    public prepareData() {
        this.resultData = { ...this.data };
        this.resultData.points = this.data.points.map(( point: IConnectorPoint ) => {
            const convertedPoint: any = this.convertPoint( point );
            if ( point.c1 ) {
                convertedPoint.c1 = this.convertPoint( point.c1 );
            }
            if ( point.c2 ) {
                convertedPoint.c2 = this.convertPoint( point.c2 );
            }
            return convertedPoint;
        });
        if ( this.eventData.scenario === CommandScenario.EXECUTE ) {
            EventCollector.log({
                message: EventIdentifier.ConvertViewportForPoints_RESULT,
                data: JsonUtil.clone( this.data ),
                resultData: JsonUtil.clone( this.resultData ),
                resultDataObj: this.resultData,
            });
        }
    }

    public execute() {
        return true;
    }

    /**
     * Converts the x,y coordinates of a point to diagram coordinates in-place.
     */
    private convertPoint( point: IPoint2D ): IPoint2D {
        const convertedPoint: any = { ...point };
        if ( point.x !== undefined ) {
            convertedPoint.x = this.converter.x( point.x );
        }
        if ( point.y !== undefined ) {
            convertedPoint.y = this.converter.y( point.y );
        }
        return convertedPoint;
    }
}

Object.defineProperty( ConvertViewportForPoints, 'name', {
    value: 'ConvertViewportForPoints',
});
