import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';

/**
 * RenameDiagram
 * Updates the diagram name.
 */
@Injectable()
@Command()
export class RenameDiagram extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        name: string,
    };

    constructor( protected ll: DiagramLocatorLocator,
                 protected ds: DiagramChangeService,
                 protected state: StateService<any, any>   ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Change the command version.
     */
    public get version(): number {
        return 2;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        if ( this.data.name === null ) {
            return;
        }
        if ( typeof this.data.name !== 'string' ) {
            throw new Error ( 'Failed to set the diagram name. Given diagram name is not a string.' );
        }
        this.changeModel.name = this.data.name.trim();
    }

    /**
     * This function will update the diagram name in the recent diagrams list
     * and project diagrams list.
     */

    public execute(): any {
        if ( this.data.name === null ) {
            return;
        }
        const allDiagrams = this.state.get( 'RecentDiagrams' );
        const renamedDiagId = this.changeModel.id;
        const renamedDiagram = allDiagrams.find( item => item.id === renamedDiagId );
        if ( renamedDiagram ) {
            renamedDiagram.name = this.changeModel.name;
            this.state.set( 'RecentDiagrams', allDiagrams );
        }
        const projectDiagrams = this.state.get( 'ProjectDiagrams' );
        if ( Object.keys( projectDiagrams ).length > 0 ) {
            const projectId = typeof this.changeModel.project === 'string' ?
                this.changeModel.project : this.changeModel.project.id;
            if ( projectDiagrams[projectId]) {
                const projectDiagram = projectDiagrams[projectId].find( item => item.id === renamedDiagId );
                if ( projectDiagram ) {
                    projectDiagram.name = this.changeModel.name;
                    this.state.set( 'ProjectDiagrams', projectDiagrams );
                    this.state.set( 'FolderPanelDataChangeEvent', {
                        id: projectDiagram.id,
                        type: 'diagram',
                        action: 'rename',
                        payload: projectDiagram,
                    });
                }
            }
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( RenameDiagram, 'name', {
    value: 'RenameDiagram',
});
