import { Injectable } from '@angular/core';
import { IChainOutcome, IChainStatus, InitializationChainStatus, IResponsibility } from 'flux-core';
import { DataStore } from 'flux-store';
import { UserModel } from 'flux-user';
import { Observable } from 'rxjs';
import { NucleusAuthentication } from '../../system/nucleus-authentication';

/**
 * This is a responsibility used in the initialization sequence.
 * This responsibility will handle loading current user for plugins.
 */
@Injectable()
export class PluginUserResponsibility implements IResponsibility {
    /**
     * Responsibility class name.
     */
    public name = 'PluginUserResponsibility';

    constructor(
        private auth: NucleusAuthentication,
        private data: DataStore,
    ) {}

    /**
     * Setting the PluginUserResponsibility state to auth status.
     */
    public checkState( status: IChainStatus ): Observable<any> {
        const selector = { id: this.auth.currentUserId };
        return this.data.findOneLatest( UserModel, selector );
    }

    /**
     * Execute the PluginDocumentResponsibility after this responsibility
     * unless `terminateAtUserSub` is set to true.
     */
    public nextResponsibility( status: IChainStatus ): string[] {
        if ( status.states.terminateAtUserSub ) {
            return [];
        }
        return [ 'PluginDocumentResponsibility' ];
    }

    /**
     * Call the LoadUser command, wait if a local cache is not available.
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        return {
            type: 'command',
            action: 'LoadUser',
            wait: !status.states.PluginUserResponsibility,
            commandParams: {
                rid: this.auth.currentUserId,
                data: {},
            },
        };
    }
}
