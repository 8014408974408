import { ISidebarDropdown } from './sidebar-dropdown.i';
import { DropDownButton } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { ElementRef } from '@angular/core';

/**
 * SidebarDropdownButton
 * This component is a specific dropdown button component, created for nucleaus sidebar,
 * which extends the DropDownButton and expected to use for both grid and list dropdowns
 */

@Component({
    selector: 'sidebar-dropdown-button',
    template: `
        <a class="dropdown-selection text-input nu-dropdown-container">
            <div class="sidebar-dd-button-icon" *ngIf="item?.buttonIcon">
                <static-image
                    [enableHover]= "true"
                    [type]="item.buttonIcon.type"
                    [value]="item.buttonIcon.value"
                    [width]="item.buttonIcon.width || 35"
                    [height]="item.buttonIcon.height || 20">
                </static-image>
            </div>
            <div *ngIf="item?.buttonLabel" class="sidebar-dd-button-text body">
                {{ item.buttonLabel }}
            </div>
            <div *ngIf="!item && placeholderText" class="sidebar-dd-button-text body">
                {{ placeholderText }}
            </div>
            <img class="nu-dropdown-arrow" src="./assets/icon-hovers/arrow-down-extra-small-thick.svg">
        </a>`,
    styleUrls: [ './sidebar-dropdown-button.scss' ],
    providers: [{ provide: DropDownButton, useExisting: forwardRef(
            /* istanbul ignore next */ () => SidebarDropdownButton ) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarDropdownButton extends DropDownButton<ISidebarDropdown> {

    @Input()
    public placeholderText?: string;

    constructor( el: ElementRef ) {
        super( el )/* istanbul ignore next */;
    }

}
