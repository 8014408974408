import { CommentModel } from './comment.mdl';
import { DiagramViewableModel } from './diagram-viewable.mdl';
import { AppConfig, enumerable } from 'flux-core';

import { filter, reduce } from 'lodash';

/**
 * DiagramReviewableModel
 * This extends the features of DiagramInfoModel and contains
 * additional property to hold SVG data which needs to render
 * the graphics on the canvas in the diagram viewer component.
 *
 * DEPRECATED FIELDS: Note that there are fields in this model that
 * is part of old Creately system and they will not be available and
 * used in new systems. Be careful about using them in the correct
 * environment.
 *
 * @author  hiraash
 * @since   2017-03-19
 */
export class DiagramReviewableModel extends DiagramViewableModel {

    /**
     * The notes for the diagram
     */
    public notes: string;

    /**
     * The viewKey for the diagram
     * @deprecated DEPRECATED FIELD
     */
    public viewKey: string;

    /**
     * The editKey for the diagram
     * @deprecated DEPRECATED FIELD
     */
    public editKey: string;

    /**
     * @deprecated
     */
    public comments: { [commentId: string]: CommentModel };
    constructor( id: string, name: string, extension?: Object ) {
        super( id, name, extension );
    }

    /**
     * Indicates if the diagram notes are available or not.
     * Empty notes sometime come like <div></div>
     *
     * @return true if the diagram has notes
     */
    @enumerable( true )
    public get hasNotes() {
        if ( !this.notes ) {
            return false;
        }
        const element = document.createElement( 'div' );
        element.innerHTML = this.notes;
        return element.innerText.trim() !== '';
    }

    /**
     * Generate the view link
     * @deprecated DEPRECATED FIELD
     */
    @enumerable( true )
    public get shareViewLink(): string {
        if ( this.viewKey ) {
            return `https://${AppConfig.appDomain}/diagram/${this.id}/${this.viewKey}`;
        }
    }

    /**
     * Generate the edit link
     * @deprecated DEPRECATED FIELD
     */
    @enumerable( true )
    public get shareEditLink(): string {
        if ( this.editKey ) {
            return `https://${AppConfig.appDomain}/diagram/${this.id}/${this.editKey}`;
        }
    }

    /**
     * @deprecated
     */
    public getCommentedShapeIdList(): Array<string> {
        return reduce( this.comments, ( ids, comment ) => {
            if ( !!comment.shapeId && ids.indexOf( comment.shapeId ) < 0 )  {
                ids.push( comment.shapeId );
            }
            return ids;
        }, []);
    }

    /**
     * @deprecated
     */
    public getShapeCommentCount( shapeId: string ): number {
        return filter( this.comments, { shapeId : shapeId }).length;
    }

}

Object.defineProperty( DiagramReviewableModel, 'name', {
  writable: true,
  value: 'DiagramReviewableModel',
});
