import { Observable, of } from 'rxjs';
import { FileImportTypes, ImportedFile } from './imported-file';

/**
 * Converts given Lucid SVG file into smaller SVG shapes by calling the converter endpoint.
 */
export class ConvertedLucidSVG extends ImportedFile {

    constructor(
        protected svg: string,
        protected position?: { x: number, y: number },
        protected textShape: boolean = false,
        protected replaceText?: string ) {
        super( undefined );
    }

    /**
     * Returns the file type
     */
    public get type(): string {
        return 'image/svg+xml';
    }

    /**
     * Content type of the imported file
     */
    public get name(): string {
        return 'lucid-shape';
    }

    /**
     * Returns the size of the imported file.
     * This value is in bytes.
     */
    public get size(): number {
         return new Blob([ this.svg ]).size;
    }

    /**
     * Returns the imported file.
     */
    public get file(): File {
        return this._file;
    }

    /**
     * Returns the extension of the imported file.
     */
    public get extension(): string {
        return 'svg';
    }

    /**
     * Sets the import type.
     */
    public set importType( type: FileImportTypes ) {
        this._importType = type;
    }

    /**
     * Returns the import type.
     */
    public get importType(): FileImportTypes {
        return this._importType;
    }

    public get isTextShape(): boolean {
        return this.textShape;
    }

    /**
     * Returns an observable that will emit the files content
     * as a data: URL. In other words, this observable will emit
     * a Base64 encoded string of the files contents.
     * @return observable that will emit once with file data
     * and complete
     */
    public getAsDataURL(): Observable<string> {
        return of( btoa( this.svg ));
    }

    /**
     * Returns an observable that will emit the files content
     * as text.
     * @return observable that will emit once with file data
     * and complete
     */
    public getAsText(): Observable<string> {
        return of( this.svg );
    }

    public getReplaceText(): string {
        return this.replaceText;
    }
}
