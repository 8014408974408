import { Injectable, Injector } from '@angular/core';
import { AbstractCommand, Command, StateService } from 'flux-core';
import { Grid } from '../../../base/grid/grid.svc';

/**
 * Change snap and show settings for Grid and Guides by setting its state
 */
@Injectable()
@Command()
export class ChangeGridGuidesShowSnap extends AbstractCommand {

    protected grid: Grid;
    constructor( protected state: StateService<any, any>, protected injector: Injector ) {
        super();
    }

    public execute(): boolean {
        this.grid = this.injector.get( 'GridService' );
        if ( this.data.showGrid !== undefined ) {
            this.grid.showGrid.next( this.data.showGrid );
        }
        if ( this.data.snapToGrid !== undefined ) {
            this.grid.enableSnapToGrid.next( this.data.snapToGrid );
        }
        if ( this.data.showGuides !== undefined ) {
            this.state.set( 'ShowGuides', this.data.showGuides );
        }
        if ( this.data.snapToGuides !== undefined ) {
            this.state.set( 'SnapToGuides', this.data.snapToGuides );
        }
        return true;
    }
}

Object.defineProperty( ChangeGridGuidesShowSnap, 'name', {
    value: 'ChangeGridGuidesShowSnap',
});
