import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfig, BaseDir } from 'flux-core';

/**
 * Directive used to show a component only when the application mode
 * is set to given value (or values). Example:
 *
 *     <my-component *ifMode="'default'"></my-component>
 *     <my-component *ifMode="['default']"></my-component>
 *
 * Limitations:
 *   - cannot be used with *ifState or *ngIf directives
 */
@Directive({ selector: '[ifMode]' })
export class IfMode extends BaseDir implements OnInit, OnDestroy {
    /**
     * The key of the state which holds the application mode.
     */
    private static get configKey(): string {
        return 'APP_MODE';
    }

    /**
     * Application modes where the component should be visible.
     */
    private values: string[];

    /**
     * The input this directive is based on.
     * FIXME: the type should be an enum.
     */
    @Input()
    public set ifMode( value: string | string[]) {
        if ( Array.isArray( value )) {
            this.values = value;
        } else if ( typeof value === 'string' ) {
            this.values = [ value ];
        } else {
            throw new Error( 'The [ifMode] directive requires a string or string array' );
        }
    }

    /**
     * Show or hide the element when the app mode state changes.
     */
    public ngOnInit() {
        if ( this.values.indexOf( AppConfig.get( IfMode.configKey )) !== -1 ) {
            this.showElement();
        } else {
            this.hideElement();
        }
    }
}
