import { TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { MouseEvent } from '@creately/createjs-module';
import { DiagramCommandEvent } from './../diagram/command/diagram-command-event';
import { MouseControlState } from './../../base/base-states';
import { tap, filter } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { RetinaStage } from './../../framework/easeljs/retina-stage';
import { ViewportToDiagramCoordinate } from './../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { CommandService, Random, StateService } from 'flux-core';
import { ICursor } from './../../base/interaction/cursor-drawable.i';
import { InteractionHandler } from './../../base/interaction/interaction-handler';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { BaseDiagramCommandEvent } from '../../base/diagram/command/base-diagram-command-event';

export const TEXT_SHAPE_DEF_ID = 'creately.basic.text';
const TEXT_SHAPE_VERSION = '2';

/**
 * TextInteractionHandler is related to the text mouse control state
 * in Editor and extends the EditorInteractionHandler. All the interactions
 * in editor when the mouse control state is text, shoud be handled in this class
 * For more details see the {@link EditorInteractionHandler}
 */
@Injectable()
export class TextInteractionHandler extends InteractionHandler {
    constructor (
        protected commandService: CommandService,
        protected ll: DiagramLocatorLocator,
        protected random: Random,
        protected state: StateService<any, any>,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
         ) {
        super( commandService, vToDcoordinate, state );
    }

    /**
     * The default cursor for the handler. When a specific cursor or tail is avalable,
     * this cursor will be concidered.
     */
     public get defaultCursor(): ICursor {
        return { cursor: 'text', tail: null, type: 'css' };
    }


    public initialize (
        diagramCanvas: RetinaStage,
        interactionCanvas: RetinaStage,
        gridCanvas: RetinaStage ): Observable<any> {
            const superInit = super.initialize( diagramCanvas, interactionCanvas, gridCanvas );
            const textCreate = this.getAllCanvasMouseCapture().pipe(
                tap( e => e.stopPropagation()),
                filter( e => e.type === 'pressup' ),
                tap(( e: MouseEvent ) => {
                    // Change back to normal when text object added
                    this.commandService.dispatch( BaseDiagramCommandEvent.changeMouseControlState,
                        { state: MouseControlState.Normal });
                    return this.addTextObject( e );
                }),
            );
            return merge( superInit, textCreate  );
        }

    /**
     * Adds the text object to the given position
     */
    protected addTextObject( e: MouseEvent ) {
        const shapeId = this.random.shapeId();
        const shapeData = {
            shapes: {
                [shapeId]: {
                    id: shapeId,
                    defId: TEXT_SHAPE_DEF_ID,
                    version: TEXT_SHAPE_VERSION,
                    x: this.vToDcoordinate.x( e.nativeEvent.offsetX ) - TEXT_PADDING_HORIZONTAL,
                    y: this.vToDcoordinate.y( e.nativeEvent.offsetY ) - 20,
                },
            },
        };
        this.commandService.dispatch(
            DiagramCommandEvent.addDiagramShape,
            shapeData,
        ).subscribe({
            complete: () => this.commandService.dispatch( DiagramCommandEvent.openTextEditor ),
        });
    }

}

Object.defineProperty( TextInteractionHandler, 'name', {
    value: 'TextInteractionHandler',
});
