import { ExecutionStep, CommandInterfaces } from 'flux-core';
import { AbstractModelChangeCommand } from './abstract-model-change.cmd';

/**
 * PrepareModifierExecutionStep step extracts changes from change model.
 */
export class PrepareModifierExecutionStep extends ExecutionStep {
    /**
     * relatedInterfaces property returns 'IModelChangeCommand' so that
     * only commands which extends abstract model change command runs this.
     *
     * FIXME: Added IDiagramCommand here to ignore the project model changes.
     * Proton uses the modifier to get the changes happened to the project
     * where nucleus using the change model and get the diagram changes.
     * The best approach is using the change model, however proton and
     * nucleus are using the same flux commands (eg: rename-project). So making
     * the change to proton to use the change model instead of modifiers will
     * need the changes in all the commands which used by the proton.
     * For the time being we added IDiagramCommand here to avoid the process
     * method from executing until we implement change model for proton as well.
     * By adding IDiagramCommand here we are saying that the process method should
     * run only for IModelChangeCommand which are IDiagramCommand. (should satisfy)
     * both interfaces to run the process method.
     * ( prepare proxy needs to be implemented for changing the
     * modifier to change model approach).
     */
    public static get relatedInterfaces(): CommandInterfaces[][] {
        return [
            [ 'IModelChangeCommand' ],
        ];
    }

    /**
     * This execution step should be run only for model change commands.
     */
    public command: AbstractModelChangeCommand;

    /**
     * Sets the changeModel property on model change commands.
     */
    public process() {
        // NOTE: ideally all commands should extend the AbstractModelChangeCommand
        //       but the framework does not restrict that so adding a check here.
        if ( !( this.command instanceof AbstractModelChangeCommand )) {
            return;
        }
        const changes = this.command.changeModel.__sakota__.getChanges();
        Object.defineProperty( this.command, 'modifier', {
            get: () => changes,
        });
        // NOTE: also remove the change model, it should not be used after this step.
        delete this.command.changeModel;
        return;
    }
}

Object.defineProperty( PrepareModifierExecutionStep, 'name', {
    value: 'PrepareModifierExecutionStep',
});
