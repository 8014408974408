import { IEntityDef } from 'flux-definition';
import { values } from 'lodash';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';


/**
 * This command is to enable or disable the type updatablity
 */
@Injectable()
@Command()
export class SwitchTypeEditable extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    // tslint:disable:member-ordering
    public data:  {
        entityDefId: string,
        editable: boolean,
    };

    public prepareData() {
        const entityDef: IEntityDef = this.changeModel.customEntityDefs[ this.data.entityDefId ];
        entityDef.editable = this.data.editable;

        // Update the corresponding entity blueprint
        values( entityDef.dataItems ).forEach( di => {
            di.labelEditable = this.data.editable;
            di.removeable = this.data.editable;
        });

        // Update the corresponding dataDefs in the eDataModel
        // values( this.changeModel.entities )
        //     .filter( e => e.eDefId === this.data.entityDefId )
        //     .forEach( e => {
        //         values( this.changeModel.dataDefs[ e.id ]).forEach( di => {
        //             di.labelEditable = this.data.editable;
        //             di.removeable = this.data.editable;
        //         });
        //     });
    }

}

Object.defineProperty( SwitchTypeEditable, 'name', {
    value: 'SwitchTypeEditable',
});
