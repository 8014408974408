import { AbstractShapeModel } from 'flux-diagram-composer';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ShapeType } from 'flux-definition';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * RemoveShape
 * This command removes the given shapes from the diagram.
 */
@Injectable()
@Command()
export class RemoveShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        const shapesToRemove = new Set<string>();
        for ( const shapeId of this.data.shapeIds ) {
            shapesToRemove.add( shapeId );
            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
            this.removeCollapsedShapes( shapesToRemove, shape );
            this.findShapesToRemove(( shape as any ).children, shapesToRemove );
            if ( shape.connectorIds && shape.connectorIds.length > 0 ) {
                shape.connectorIds.forEach( id => {
                    if ( this.changeModel.shapes[id]) {
                        shapesToRemove.add( id );
                    }
                });
            }

        }

        for ( const shapeId of shapesToRemove ) {
            const maybeConnector = this.changeModel.shapes[shapeId] as ConnectorModel;
            if ( !maybeConnector ) {
                continue;
            }
            if ( maybeConnector.type === ShapeType.Connector && maybeConnector.connectionId ) {
                delete this.changeModel.connections[maybeConnector.connectionId];
            }
            this.removeDataDef( this.changeModel.shapes[shapeId]);
            this.detachRemovedShapesFromItsContianer( this.changeModel.shapes[shapeId]);
            delete this.changeModel.shapes[shapeId];
        }
    }

    protected removeCollapsedShapes( shapesToRemove: Set<string>, shape: ShapeModel ) {
        Object.values( shape.gluepoints || {})
            .filter( gp => gp.connectionState === 'collapsed' )
            .forEach( gp => {
                const { shapeIds, connectorIds } = this.changeModel.getAllShapesStartedFromGp( shape.id, gp.id );
                shapeIds.forEach( id => shapesToRemove.add( id ));
                connectorIds.forEach( id => shapesToRemove.add( id ));
            });
    }

    /**
     * This function recursively finds the all the shapes in nested containers to remove
     * @param children
     * @param shapesToRemove Set
     */
    protected findShapesToRemove( children: any, shapesToRemove: Set<string> ) {
        if ( children ) { // If a shape has children, remove all the children and their connections
            Object.keys( children ).forEach( id => {
                const child: ShapeModel = this.changeModel.shapes[ id ] as any;
                if ( child ) {
                    shapesToRemove.add( id );
                    ( child.getConnectors ? child.getConnectors( this.changeModel ) || [] : [])
                        .forEach( c => shapesToRemove.add( c.connector.id ));
                    this.findShapesToRemove( child.children, shapesToRemove );
                }
            });
        }
    }

    /**
     * Remove dataDef if no other shapes need it.
     */
    protected removeDataDef( shape: AbstractShapeModel ) {
        const shapes = this.changeModel.getShapesByDatasetId( shape.dataSetId );
        if ( shapes.length === 1 && shapes[0].id === shape.id ) {
            delete this.changeModel.dataDefs[ shape.dataSetId ];
        }
    }

    /**
     * When a shape is removed, it should be dertached from it's container, this method updates
     * the container's child property
     * @param children
     * @param shapesToRemove Set
     */
    protected detachRemovedShapesFromItsContianer( shape ) {
        if ( shape.containerId
            && this.changeModel.shapes[ shape.containerId ]
            && ( this.changeModel.shapes[ shape.containerId ] as any ).children ) {
                delete ( this.changeModel.shapes[ shape.containerId ] as any ).children[ shape.id ];
        }
    }

}

// NOTE: class names are lost on minification
Object.defineProperty( RemoveShape, 'name', {
    value: 'RemoveShape',
});
