import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * This component is the collapsable panel item. As the name suggests,
 * this is a panel item that can be expanded or collapsed. By default
 * the panel item is expanded.
 *
 * @author nuwanthi
 * @since 2017-12-08
 */
export const COLLAPSABLE_PANEL_CONTENT_CLASS = 'panel-content';

@Component({
    templateUrl: './collapsable-panel-item.cmp.html',
    selector: 'collapsable-panel-item',
    styleUrls: [ 'collapsable-panel-item.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsablePanelItem {
    public panelClass =  COLLAPSABLE_PANEL_CONTENT_CLASS;

    /**
     * The section icon.
     */
    @Input() public icon?: string;

    /**
     * The section heading.
     */
    @Input() public heading: string;

    /**
     * Variable to hold the panel open status.
     */
    @Input() public open: boolean = true;

    /**
     * Variable to hold the panel test ID.
     */
    @Input() public testId: string;

    /**
     * Returns the xlink:href for the use tag
     */
    public get iconHref() {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.icon;
    }

    /**
     * Toggle between setting the panel to close or open.
     */
    public toggleItem() {
        this.open = !this.open;
    }
}
