import { Injectable } from '@angular/core';
import { invert } from '@creately/mungo';
import {
    AbstractCommand, Command, CommandCancelError, CommandInterfaces, CommandScenario,
    EventSource,
    IUnsavedModelChange, Random,
} from 'flux-core';
import { cloneDeep, isEmpty } from 'lodash';
import { concat, defer , EMPTY , Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { SessionHistoryManager } from '../../../system/session-history-manager.svc';
import { IRevertableCommandEventData } from '../../diagram/command/scenario/abstract-revertable-scenario';
import { EDataChangeService } from '../edata-change.svc';
import { EDataLocatorLocator } from '../locator/edata-locator-locator';
import { TiptapDocumentsManagerEdata } from '../../ui/shape-data-editor/tiptap-documents-manager-edata.cmp';

/**
 * This EDataChange command is to update the document by a given change modifier
 */
@Injectable()
@Command()
export class EDataChange extends AbstractCommand {
    /**
     * This command should be sent to the server
     */
    public static get implements(): CommandInterfaces[] {
        return [ 'IMessageCommand', 'IEDataCommand' ];
    }

    public static get aliases(): string[] {
        return [
            'EdataChange', // external edata change
        ];
    }

    /**
     * Inject services and stuff!
     */
    constructor(
        private sessionHistoryManager: SessionHistoryManager,
        private ell: EDataLocatorLocator,
        private es: EDataChangeService,
    ) {
        super();
    }

    /**
     * Inserts a preview model change for the PREVIEW scenario.
     * Inserts an unsaved model change for the EXECUTE scenario.
     */
    public execute(): Observable<unknown> {
        if ( this.eventData.scenario === CommandScenario.REDO ||
            this.eventData.scenario === CommandScenario.UNDO ) {
            return this.runUndoRedo();
        } else if ( this.eventData.scenario === CommandScenario.PREVIEW ) {
            throw Error( 'Preview not supported in EventData' );
        }
        return this.runExecute();
    }

    /**
     * Inserts a saved model change for the EXECUTE scenario.
     */
    public executeResult(): Observable<unknown> {
        const change = this.resultData.change;
        TiptapDocumentsManagerEdata.updateDocumentByModifier( this.resourceId, change.modifier );
        return this.es.addSavedChange({
            ...this.resultData.change,
            modelId: this.resourceId,
            command: this.name,
            status: 'saved',
        });
    }

    protected bindChanges() {
        const eventId = this.eventData.eventId;
        const scenario = this.eventData.scenario;
        return this.es.getChangeModel( this.resourceId, eventId, scenario ).pipe(
            ignoreElements(),
        );
    }

    protected runExecute(): Observable<unknown> {
        return concat(
            this.bindChanges(),
            defer(() => {
                const modifier = this.es.flushChanges( this.eventData.eventId );
                if ( !modifier || ( isEmpty( modifier.$set ) && isEmpty( modifier.$unset ))) {
                    throw new CommandCancelError( 'the modifier is empty, ignoring it' );
                }
                let ctx;
                if ( this.data?.diagramId ) {
                    ctx = { diagramId: this.data.diagramId };
                }
                if ( this.data?.origin ) {
                    ctx = ctx || {};
                    ctx.origin = this.data.origin;
                }
                return this.setUnsavedChange( modifier, ctx );
            }),
        );
    }

    protected runUndoRedo(): Observable<unknown> {
        const modifierData = ( this.eventData as IRevertableCommandEventData ).modifierData.shift();
        const ctx = ( this.eventData as IRevertableCommandEventData ).changeCtx;
        delete this.eventData.changeCtx;
        if ( modifierData.command === this.name ) {
            return this.setUnsavedChange( modifierData.reverter, ctx );
        } else {
            throw new Error( 'Modifier data to be overridden for the command '
                + this.name + ' did not match.' );
        }
    }

    private setUnsavedChange( modifier, ctx ) {
        if ( this.eventData.source !== EventSource.EXTERNAL ) {
            this.sessionHistoryManager.recordExecution(
                this.eventData.eventId,
                this.eventData.scenario,
                this.eventData.source as EventSource,
                'edata',
                this.resourceId,
            );
        }
        return this.ell.getEData( this.resourceId ).pipe(
            switchMap( locator => locator.getEDataModelOnce().pipe(
                switchMap( diagram => {
                    const change: IUnsavedModelChange = {
                        id: Random.changeId(),
                        event: cloneDeep( this.eventData ),
                        userId: '',
                        modelId: this.resourceId,
                        command: this.name,
                        clientTime: Date.now(),
                        modifier,
                        reverter: invert( diagram, modifier ),
                        status: 'unsaved',
                    };
                    if ( ctx ) {
                        change.ctx = ctx;
                    }
                    const skipSaveChange = this.data?.applyLater;
                    this.data = { change: change };
                    if ( skipSaveChange ) {
                        return EMPTY;
                    }
                    return this.es.addUnsavedChange({
                        ...change,
                    });
                }),
            ),
        ));
    }

}

Object.defineProperty( EDataChange, 'name', {
    value: 'EDataChange',
});
