<div #window
    class="fx-cover fx-center-all modal-window-container">
    <div #windowInner class="body modal-window-inner">
        <div class="modal-window-heading">
            <h1 class="title title--lg" translate>
                IMAGE_IMPORT.IMPORT_IMAGES
            </h1>
            <button class="nu-icon-small nu-btn-icon close-button" (click)="closeWindow()">
                <svg class="nu-icon nu-icon-small nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
        </div>
        <div (drop)="dropHandler($event);" (dragover)="dragOverHandler($event)" class="image-upload-body">
            <div [class.reduced]="( images | async ).length > 0 && !( processingZip | async )" class="image-pad">
                <svg class="">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-image-upload"></use>
                </svg>
                <div class="instructions">
                    <span translate>IMAGE_IMPORT.INSTRUCTION_ONE</span>
                    <span translate>IMAGE_IMPORT.INSTRUCTION_TWO</span>
                    <span translate>IMAGE_IMPORT.INSTRUCTION_THREE</span>
                </div>
                <button (click)="file.click()" [class.disabled]="processingZip | async" class="btn-browse">
                    <svg class="nu-icon">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-upload-image"></use>
                    </svg>
                    <span translate>IMAGE_IMPORT.BROWSE_FILES</span>
                </button>
                <input type="file"
                    #file
                    [multiple]="true"
                    (change)="onFileChange($event)"
                    class="fx-hidden" 
                    accept=".png, .jpg, .jpeg, .svg, .zip"
                    max=""
                />
            </div>
            <div class="labels">
                <span translate>IMAGE_IMPORT.SUPPORTED_FORMATS</span>
                <span translate>IMAGE_IMPORT.MAXIMUM_SIZE</span>
            </div>
            <div *ngIf="processingZip | async">
                <span class="status-text" translate>IMAGE_IMPORT.UPLOADING_FILES</span>
                <div class="parsing-zip-display">
                    <div class="icon-container">
                        <svg class="nu-icon">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-file-upload"></use>
                        </svg>
                    </div>
                    <div class="zip-text">
                        <span>{{ zipFile?.name }}</span>
                        <span>{{ displaySize( zipFile?.size ) }}</span>
                    </div>
                    <div (click)="deleteZip()" class="delete-container">
                        <svg class="nu-icon">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-delete"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <div *ngIf="( images | async ).length > 0 && !( processingZip | async )">
                <span class="status-text" [translate]="'IMAGE_IMPORT.UPLOADED_FILES'" [translateParams]="{count: images.value.length}"></span>
                <div class="scroll-view-container fx-margin-top-10">
                    <perfect-scrollbar>
                        <div class="file-row" *ngFor="let file of images | async; index as i">
                            <div class="main-section">
                                <svg class="nu-icon">
                                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-image-new"></use>
                                </svg>
                                <!-- <span class="fx-margin-left-5">{{file.name}}</span> -->
                                <editable-label class="editable-title fx-margin-left-5"
                                    [value]="file.name"
                                    [focus]="false"
                                    [selectAll]="false"
                                    (changedText)="changeFileName(i, $event)"
                                    (click)="$event.target.focus()"
                                    (focusout)="terminateEditing($event);"
                                    [readonly]="false">
                                </editable-label>
                            </div>
                            <div (click)="deleteImage(i)" class="delete-file-btn">
                                <svg class="nu-icon">
                                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                                </svg>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div *ngIf="( images | async ).length > 0 || ( processingZip | async )" class="modal-footer">
            <button (click)="closeWindow()" class="btn-cancel btn-medium fx-margin-right-10" translate>BUTTONS.CANCEL</button>
            <button (click)="uploadImages()" class="btn-primary btn-medium" translate>BUTTONS.ADD_TO_ORG_CHART</button>
        </div>
    </div>
</div>