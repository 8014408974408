import { Injectable } from '@angular/core';
import { AbstractCommand, Command, CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';

/**
 * AbstractPluginCommand is the base abstract class which can be extended
 * to create commands which will only be executed in plugin mode.
 */
@Injectable()
@Command()
export abstract class AbstractPluginCommand extends AbstractCommand {
    /**
     * This command implements the 'IPluginCommand' interface.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IPluginCommand', 'IAllowAnonymous' ];
    }

    /**
     * Command execute
     */
    public execute(): boolean | Observable<unknown> {
        return true;
    }
}

Object.defineProperty( AbstractPluginCommand, 'name', {
    value: 'AbstractPluginCommand',
});
