import { StateService, Rectangle } from 'flux-core';
import { ShapeModel } from '../shape/model/shape.mdl';
import { ConnectorModel } from '../shape/model/connector.mdl';
import { IConnectorPoint } from 'flux-diagram-composer';

/**
 * This is an abstraction for coordinate classes. Common functionality
 * used by all types of coordinate classes are included here.
 *
 * @author  Ramishka
 * @since   2017-10-22
 */
export abstract class AbstractCoordinate {

    constructor( protected state: StateService<any, any> ) {}

    /**
     * Getter for the current zoom level
     */
    protected get zoomLevel(): number {
        return this.state.get( 'DiagramZoomLevel' );
    }

    /**
     * Getter for the current pan x
     */
    protected get panX(): number {
        return this.state.get( 'DiagramPan' ).x;
    }

    /**
     * Getter for the current pan y
     */
    protected get panY(): number {
        return this.state.get( 'DiagramPan' ).y;
    }

    /**
     * Converts an x coordinate of viewport into diagram coordinate space
     * @param originalX value to convert
     */
    public abstract x( originalX: number ): number;

    /**
     * Converts a y coordinate of viewport into diagram coordinate space
     * @param originalY value to conver        st
     */
    public abstract y( originalY: number ): number;

    /**
     * Converts a width value of viewport into diagram coordinate space
     * @param originalWidth value to convert
     */
    public abstract width( originalWidth: number ): number;

    /**
     * Converts a height value of viewport into diagram coordinate space
     * @param originalHeight value to convert
     */
    public abstract height( originalWidth: number ): number;

    /**
     * Converts a vertical or horizontal scale value
     * @param originalScale scale value to convert
     */
    public abstract scale( originalScale: number ): number;

    /**
     * Converts bounds of viewport into diagram coordinate space
     * @param rect Bounding rect value to convert
     */
    public bounds( rect: Rectangle ) {
        return new Rectangle(
            this.x( rect.x ),
            this.y( rect.y ),
            this.width( rect.width ),
            this.height( rect.height ),
        );
    }

    /**
     * Converts all convertable properties of a given model.
     * @return shape - model with the converted values
     */
    public shape ( shape: ShapeModel | ConnectorModel ): ShapeModel | ConnectorModel {
        if ( shape instanceof ConnectorModel ) {
            return this.convertConnectorPoints( shape );
        }
        return this.convertShapeBounds( shape );
    }

    /**
     * Converts properties of a shape model
     * @param shape - shape model
     * @return shape - model with the converted values
     */
    protected convertShapeBounds( shape: ShapeModel ): ShapeModel {
        const x = this.x( shape.x );
        const y = this.y( shape.y );
        const scaleX = this.scale( shape.scaleX );
        const scaleY = this.scale( shape.scaleY );
        shape.viewTransform = { x, y, scaleX, scaleY, angle: shape.angle };
        return shape;
    }

    /**
     * Converts properties of a connector model
     * @param connector - connector model
     * @return model with the converted values
     */
    protected convertConnectorPoints( connector: ConnectorModel ): ConnectorModel {
        const points = [];
        connector.getPoints().forEach(( node: IConnectorPoint ) => {
            const _node: any = {
                id: node.id,
                nextId: node.nextId,
                prevId: node.prevId,
                pathAdjustedManually: node.pathAdjustedManually,
                x: this.x( node.x ),
                y: this.y( node.y ),

            };

            if ( node.c1 ) {
                _node.c1 = {
                    x: this.x( node.c1.x ),
                    y: this.y( node.c1.y ),
                };
            }
            if ( node.c2 ) {
                _node.c2 = {
                    x: this.x( node.c2.x ),
                    y: this.y( node.c2.y ),
                };
            }
            if ( node.bumps ) {
                _node.bumps = {};
                for ( let i = 0; i < node.bumps.length; i++ ) {
                    _node.bumps[i] = {};
                    for ( let j = 0; j < node.bumps[i].length; j++ ) {
                        _node.bumps[i][j] = {
                            x: this.x( node.bumps[i][j].x ),
                            y: this.y( node.bumps[i][j].y ),
                            radius: node.bumps[i][j].radius,
                        };
                    }
                }
            }
            points.push( _node );
        });
        connector.viewPoints = points;
        return connector;
    }

}
