import { IWheelEvent } from './../../editor/interaction/event/wheel-event.i';
import { ViewportToDiagramCoordinate } from './../coordinate/viewport-to-diagram-coordinate.svc';
import { ICursor, ICursorDrawable } from './cursor-drawable.i';
import { tap, map, mergeMap, filter, takeUntil, switchMap, pairwise } from 'rxjs/operators';
import { ShapeRightClickEvent } from './../../editor/interaction/event/shape-right-click-event';
import { Injectable, Injector } from '@angular/core';
import { InteractionCommandEvent } from './command/interaction-command-event';
import { RetinaStage } from './../../framework/easeljs/retina-stage';
import { StateService, CommandService } from 'flux-core';
import { MouseEvent, DisplayObject } from '@creately/createjs-module';
import { Observable, merge, of, fromEvent, concat, range } from 'rxjs';
import { ChildEvent } from '../../framework/easeljs/child-event';
import { Subscription } from 'rxjs';
import { RightClickEvent } from '../../editor/interaction/event/right-click-event';
import { IPoint2D } from 'flux-definition';
import * as normalizeWheel from 'normalize-wheel';

/**
 * The interaction handler is what is used by the diagram area to handle
 * user interactions that happen on all canvases. The interaction handler
 * takes in all existing canvases to manage the user interactions and the outcomes
 * of them. The interactions are different based on context. To mange this a
 * 'CurrentInteractionHandler' state is used to identify the type of the current
 * interaction handler type which will be used by the diagram area.
 *
 * The interaction handler is free to do whatever necessary and manage all interactions
 * within and between the easeljs canvases. The contextual interaction handler is responsible
 * for creating the outcomes expected by different capabilties of the indicators as well.
 *
 * @author hiraash
 * @since 2017-10-17
 */

@Injectable()
export abstract class InteractionHandler {

    /**
     * A variable to adjust the sensitivity of the wheel zoom
     * Increase the denominator of this value to smoothen the wheel zoom
     */
    public static zoomSensitivity: number = 1 / 50;

    /**
     * A variable to adjust the sensitivity of the wheel pan
     * Increase the denominator of this value to smoothen the wheel pan
     */

    public static panSensitivity: number = 2;

    /**
     * The diagram view canvas where the diagram exists
     */
    protected diagramCanvas: RetinaStage;
    /**
     * The interaction canvas where all the indicators live
     */
    protected interactionCanvas: RetinaStage;
    /**
     * The grid canvas which is the bottom most canvas
     */
    protected gridCanvas: RetinaStage;

    /**
     * The list of subscriptions held by this class
     */
    protected subs: Subscription[];


    constructor(
        protected commandService: CommandService,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
        protected state: StateService<any, any>,
    ) {
        this.subs = [];
    }

    /**
     * The default cursor for the handler. When a specific cursor or tail is avalable,
     * this cursor will be concidered.
     */
    public abstract get defaultCursor(): ICursor;

    /**
     * Initializes and sets up the interaction handler
     * @param diagramCanvas The diagram view stage instance
     * @param interactionCanvas The interaction layer stage instance
     * @return Observable that merges all the interactions
     */
    public initialize(
        diagramCanvas: RetinaStage,
        interactionCanvas: RetinaStage,
        gridCanvas: RetinaStage ): Observable<any> {
        this.diagramCanvas = diagramCanvas;
        this.interactionCanvas = interactionCanvas;
        this.gridCanvas = gridCanvas;

        const wheelPan = this.getStageWheelOnNoKeysDown().pipe(
            tap(( e: IWheelEvent ) => {
                    this.updatePan( -e.pixelX * InteractionHandler.panSensitivity ,
                        -e.pixelY * InteractionHandler.panSensitivity );
                },
            ),
        );

        const wheelZoom = this.getStageWheelOnCtrlKeyDown().pipe(
            tap(( e: IWheelEvent ) =>
                this.updateZoom( 1 - e.pixelY * InteractionHandler.zoomSensitivity * ( 1 / 4 ), e.offsetX, e.offsetY ),
            ),
        );

        // Capturing the mousedown event on all canvases to manage the focus in keyboardcontroller.
        const allCanvasMousedown = this.getAllCanvasMouseCapture().pipe(
            tap(() => ( this.interactionCanvas.canvas as HTMLCanvasElement ).focus()),
        );

        return merge( wheelPan, wheelZoom, allCanvasMousedown );
    }

    /**
     * Destroys and clears all the resources used by the
     * interaction handler
     */
    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }

        this.diagramCanvas = undefined;
        this.interactionCanvas = undefined;
        this.gridCanvas = undefined;
    }

    /**
     * Returns an observable that emits ICursor when the cursor changes
     */
    public getCursor(): Observable<ICursor> {
        return of( this.defaultCursor );
    }

    /**
     * Returns an observable that emits shape's mousedown events when they get added
     * to the diagram canvas, until they are removed from the diagram canvas.
     */
    protected getShapeTouch(): Observable<MouseEvent> {
        return this.getShapes().pipe(
            mergeMap( shape => fromEvent( shape, 'mousedown' ).pipe(
                takeUntil( fromEvent( this.diagramCanvas, 'childRemoved' ).pipe(
                    filter(( event: ChildEvent ) => event.child === shape ))),
                ),
            ),
            map( event => <MouseEvent>event ),
        );
    }

    /**
     * Returns an observable that emits all the shapes on the diagram view.
     * Initially emits existing ones and then emits as they are added.
     */
    protected getShapes(): Observable<DisplayObject> {
        const shapeViews = this.state.get( 'ShapeViews' );
        return concat(
            range( 0, shapeViews.length )
                .pipe( map( index => shapeViews[ index ])),
            fromEvent( this.diagramCanvas, 'childAdded' )
                .pipe( map(( event: ChildEvent ) => event.child )),
        );
    }

    /**
     * Returns an observable from right click event on the shape
     */
    protected getShapeRightClick(): Observable<ShapeRightClickEvent> {
        return this.getShapeTouch().pipe(
            filter( event => event.nativeEvent.button === 2 ),
            map(( event: MouseEvent ) => new ShapeRightClickEvent( 'shapeRightClick', event )),
        );
    }


    /**
     * Returns all three canvases as an observable
     */
    protected getAllCanvas(): Observable<RetinaStage> {
        return of( this.diagramCanvas, this.interactionCanvas, this.gridCanvas );
    }

    /**
     * Returns an observable that emits for drag of the given stage
     */
    protected getAllCanvasMouseCapture(): Observable<MouseEvent> {
        return this.getAllCanvas().pipe(
            mergeMap( stage => merge(
                fromEvent( stage, 'mousedown', { capture: true }),
                fromEvent( stage, 'pressmove', { capture: true }),
                fromEvent( stage, 'pressup', { capture: true })),
            ),
            map( e => <MouseEvent>e ),
        );
    }

    /**
     * Returns an observable that merges mouse capture events for all three canvases.
     */
    protected getStageDrag( stage: DisplayObject ): Observable<MouseEvent> {
        return fromEvent( stage, 'stagemousedown' ).pipe(
            switchMap(() => fromEvent( stage, 'stagemousemove' )
                .pipe(
                    pairwise(),
                    tap(([ pre, cur ]: any ) => {
                        // NOTE:
                        // MouseEvent.movementX / movementY are undefined for touch events
                        // have to evalute the movements manually
                        // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/movementX
                        if ( pre && ( cur.nativeEvent.movementX === undefined
                            || cur.nativeEvent.movementY === undefined )) {
                                const movementX = cur.localX - pre.localX;
                                const movementY = cur.localY - pre.localY;
                                cur.nativeEvent.movementX = movementX;
                                cur.nativeEvent.movementY = movementY;
                        }
                    }),
                    takeUntil( fromEvent( stage, 'stagemouseup' )),
                ),
            ),
            map(([ pre, cur ]: any ) => <MouseEvent> cur ),
            filter( v => !!v ),
        );
    }

    /**
     * Returns an observable from mousedown event on given stage
     */
    protected getStageMouseDown( stage: DisplayObject ): Observable<MouseEvent> {
        return fromEvent( stage, 'mousedown' ).pipe( map( e => <MouseEvent> e ));
    }

    /**
     * Returns an observable from pressup event on given stage
     */
    protected getStagePressUp( stage: DisplayObject ): Observable<MouseEvent> {
        return fromEvent( stage, 'pressup' ).pipe( map( e => <MouseEvent> e ));
    }

    /**
     * Returns an observable of the mouse wheel event on stage canvas html element
     * and also prevents the event default actions
     */
    protected getStageWheel(): Observable<IWheelEvent> {
        // NOTE: Safari does not dispatch 'wheel' event when doing pinch zoom.
        // 'Gesture' event will be dispatched when pinch zoom with scale value
        // in Safari.
        return merge(
            fromEvent( this.interactionCanvas.canvas as HTMLElement , 'gesturestart' ),
            fromEvent( this.interactionCanvas.canvas as HTMLElement , 'gesturechange' ),
            fromEvent( this.interactionCanvas.canvas as HTMLElement , 'gestureend' ),
            fromEvent( this.interactionCanvas.canvas as HTMLElement , 'wheel' ),
        ).pipe(
            map( e => {
                if ( e.type === 'gesturestart' || e.type === 'gesturechange' || e.type === 'gestureend' ) {
                    // NOTE: Converting the scale value to pixelY.
                    // When zoom out the scale value is comparatively small so only few pixels will be zoomed out.
                    // For smoothness and reasonable user friendly zoom out, adding 1.5 to make the zoom out
                    // bit faster.
                    const scale = ( e as any ).scale > 1 ? ( e as any ).scale * -1 : ( e as any ).scale + 1.5;
                    ( e as any ).pixelY = scale;
                    ( e as any ).offsetX = ( e as any ).clientX;
                    ( e as any ).offsetY = ( e as any ).clientY;
                    ( e as any ).gesture = true;
                } else {
                    const normalized = normalizeWheel( e );
                    ( e as any ).pixelX = normalized.pixelX;
                    ( e as any ).pixelY = normalized.pixelY;
                    ( e as any ).gesture = false;
                }
                return <IWheelEvent> e;
            }),
            tap( e => e.preventDefault()),
        );
    }

    /**
     * Returns an observable of the mouse wheel event when ctrl key is down
     * on stage canvas html element
     */
    protected getStageWheelOnCtrlKeyDown(): Observable<IWheelEvent> {
        return this.getStageWheel().pipe(
            filter(( e: IWheelEvent ) => e.ctrlKey || e.metaKey || ( e as any ).gesture ),
            map( e => <IWheelEvent> e ),
        );
    }

    /**
     * Returns an observable of the mouse wheel event when no keys are pressed
     * on stage canvas html element
     */
    protected getStageWheelOnNoKeysDown(): Observable<IWheelEvent> {
        return this.getStageWheel().pipe(
            filter(( e: IWheelEvent ) => !e.metaKey && !e.ctrlKey && !( e as any ).gesture ),
            map( e => <IWheelEvent> e ),
        );
    }

    /**
     * Returns an observable from right click event on given stage
     */
    protected getStageRightClick( stage: DisplayObject ): Observable<RightClickEvent> {
        return this.getStagePressUp( stage ).pipe(
            filter( event => event.nativeEvent.button === 2 ),
            map(( event: MouseEvent ) => new RightClickEvent( 'canvasRightClick', event )),
        );
    }

    /**
     * Returns an observable from right click event on given interaction stage
     */
    protected getInteractionRightClick( stage: DisplayObject ): Observable<RightClickEvent> {
        return this.getStageMouseDown( stage ).pipe(
            filter( event => event.nativeEvent.button === 2 ),
            map(( event: MouseEvent ) => new ShapeRightClickEvent( 'shapeRightClick', event )),
        );
    }

    /**
     * Disptach command to update the panDiagran state
     */
    protected updatePan( dx: number , dy: number ) {
        this.commandService.dispatch(
            InteractionCommandEvent.panDiagram,
            { dx, dy, animate: false },
        );
    }

    /**
     * Disptach command to update the panDiagran state
     */
    protected updateZoom( delta: number, toX: number , toY: number ) {
        this.commandService.dispatch(
            InteractionCommandEvent.zoomDiagram,
            { delta, toX, toY, animate: false },
        );
    }

    /**
     * Returns an Array of ICursorDrawable objects as the mouse moves on the diagram area.
     * The objects are selected from both interactoin and diagram canvases the array contains
     * objects in order.
     */
    // protected getObjectsUnderPoint( point: IPoint2D ): ICursorDrawable[] {
    //     const diagramCanvasObjects = this.diagramCanvas.getObjectsUnderPoint(
    //         this.vToDcoordinate.x( point.x ),
    //         this.vToDcoordinate.y( point.y ), 0 );
    //     const objects = [];
    //     diagramCanvasObjects.map(( o: any ) => {
    //         if ( o.children?.length > 0  ) {
    //             objects.push( ...o.children.reverse());
    //         } else {
    //             objects.push( o );
    //         }
    //     });
    //     return [
    //         ...this.interactionCanvas.getObjectsUnderPoint( point.x, point.y, 0 ),
    //         ...objects,
    //     ].filter(( object: any ) => !!object.getCursor ) as any;
    // }

    protected getObjectsUnderPoint( point: IPoint2D ): ICursorDrawable[] {
        return [
            ...this.interactionCanvas.getObjectsUnderPoint( point.x, point.y, 0 ),
            ...this.diagramCanvas.getObjectsUnderPoint(
                this.vToDcoordinate.x( point.x ),
                this.vToDcoordinate.y( point.y ), 0 ),
        ].filter(( object: any ) => !!object.getCursor ) as any;
    }

    /**
     * Returns an observable that emits ICursor by considering objects under the
     * mouse pointer and cursor availability. If no specific cursor is availbale,
     * this default cursor defied for the handler will be considered.
     * *Note*
     * Any Observable that emits the context changes can be switchMapped to
     * this observable to get the correct cursor.
     */
    protected getCursorByContext( shapes: ICursorDrawable[], context: InteractionSubContext[]): ICursor {
        const object = shapes.find( o => {
            const cursor  = o.getCursor( context );
            return cursor && ( !!cursor.cursor || !!cursor.tail );
        });
        if ( object ) {
            return object.getCursor( context );
        }
        return this.defaultCursor;
    }

    /**
     * Returns an Observable that emits the coordinates of the mouse pointer
     * as it moves. The point is conveted from viewport cordinates to diagra cordinates
     */
    protected pointerMove(): Observable<IPoint2D> {
        return fromEvent( this.interactionCanvas, 'stagemousemove' ).pipe(
            map(( e: MouseEvent ) => ({ x: e.stageX, y: e.stageY })),
        );
    }

}

/**
 * StateService representation for the current InteractionHandler
 */
export class InteractionHandlerStateService extends
    StateService<'CurrentInteractionHandler', { injector: Injector, type: typeof InteractionHandler}> {}


/**
 * Interaction Context is a list of sub contexts that defines a particular situation/case on the canvas.
 * Interaction context is used to decide what cursor to show when the mouse pointer is on the canvas.
 * Most of the following sub contexts are related to the noraml mouse control state in editor.
 */
export type InteractionSubContext =

    'editor' |
    'normal' |
    'onShape' |
    'onConnector' |
    'selected' |
    'multiSelected' |
    'notSelected' |
    'multiSelectionDrag' |

    'interactionHappening' |

    'createText' |
    'createConnector' |
    'pan' |

    'viewer' | string;

