import { Injectable } from '@angular/core';
import { Command, StateService, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { Observable, merge } from 'rxjs';
import { AbstractMessageCommand } from 'flux-connection';
import { from } from 'rxjs';
import { DiagramInfoModel } from '../../diagram/model/diagram-info.mdl';
import { ProjectModel } from '../model/project.mdl';

/**
 * This is the command to delete a project, The project can be deleted by it's owner only
 * Also the home project is not allowed to be deleted.
 */
@Injectable()
@Command()
export class DeleteProject extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor(
            protected dataStore: DataStore,
            protected modelSubManager: ModelSubscriptionManager,
            protected state: StateService<any, any> ) {
                super() /* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    /**
     * Markes the project in cache as deleted.
     */
    public execute(): any {

        if ( this.resourceId === 'home' ) {
            return false;
        }

        // FIXME: DB queries needs to further optimized, needs to check whether data exist and delete data
        const projectModelStore = this.dataStore.getModelStore( ProjectModel );
        const ob = from( projectModelStore.updateStatic({ id: this.resourceId }, { $set: { deleted: true }}));

        const allDiagrams = this.state.get( 'ProjectDiagrams' );
        if ( !Object.keys( allDiagrams ).includes( this.resourceId )) {
            return ob;
        }
        const obs: any = [ ob ];
        const diagrams: Array<DiagramInfoModel> = allDiagrams[ this.resourceId ];
        const diagramIds = diagrams.map( diagram => diagram.id );
        // FIXME: DB queries needs to further optimized, needs to check whether data exist and delete data
        // This should not depend on the state to check availability of data
        // It better to use the updateQuery to delete diagrams based on projectId
        const diagramModelStore = this.dataStore.getModelStore( DiagramInfoModel );
        if ( diagramIds.length ) {
            const o = from( diagramModelStore.updateStatic({ id: { $in: diagramIds }}, { $set: { deleted: true }}));
            obs.push( o );
        }
        return merge( ...obs );
    }

    /**
     * Delete the project from cache and stops subscriptions related to the project.
     */
    public executeResult( response: any ): Observable<any> {
        const obs: any = [];
        const allDiagrams = this.state.get( 'ProjectDiagrams' );

        // FIXME: DB queries needs to further optimized, needs to check whether data exist and delete data
        // This should not depend on the state to check availability of data
        // It better to use the updateQuery to delete diagrams based on projectId
        const diagrams: Array<DiagramInfoModel> = allDiagrams[ this.resourceId ];
        if ( diagrams ) {
            const diagramIds = diagrams.map( diagram => diagram.id );
            const diagramModelStore = this.dataStore.getModelStore( DiagramInfoModel );
            const o = from( diagramModelStore.remove({ id: { $in: diagramIds }}));
            obs.push( o );
            diagramIds.forEach( id => {
                this.modelSubManager.stop( id, true );
            });
        }

        const projectModelStore = this.dataStore.getModelStore( ProjectModel );
        this.modelSubManager.stop( this.resourceId, true );
        // FIXME: DB queries needs to further optimized, needs to check whether data exist and delete data
        obs.push( projectModelStore.remove({ id: this.resourceId }));
        this.updateProjectDiagramState();

        return merge( ...obs );
    }

    /**
     * Updates the ProjectDiagrams state and RecentDiagrams state
     */
    private updateProjectDiagramState() {
        const allDiagrams = this.state.get( 'ProjectDiagrams' );
        const projectMap = this.state.get( 'ProjectMap' );

        const deletedDiagrams: DiagramInfoModel[] = allDiagrams[ this.resourceId ];
        if ( deletedDiagrams ) {
            const deletedDiagramIds: string[] = deletedDiagrams.map( d => d.id );
            const allRecentDiagrams: DiagramInfoModel[] = this.state.get( 'RecentDiagrams' );
            const newRecentDiagrams = allRecentDiagrams.filter( d => !deletedDiagramIds.includes( d.id ));
            delete allDiagrams[ this.resourceId ];
            delete projectMap[ this.resourceId ];
            this.state.set( 'ProjectDiagrams', allDiagrams );
            this.state.set( 'ProjectMap', projectMap );
            this.state.set ( 'RecentDiagrams', newRecentDiagrams );
        }
    }

}

Object.defineProperty( DeleteProject, 'name', {
    value: 'DeleteProject',
});
