import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { combineLatest, Observable } from 'rxjs';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { AbstractPluginCommand } from './abstract-plugin-cmd';

/**
 * Fetches a document from the server and saves it in indexed db.
 * When calling this, please set the resourceId to the document id.
 * This command will also remove all local changes of the document.
 */
@Injectable()
@Command()
export class LoadDocument extends AbstractPluginCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ ...AbstractPluginCommand.implements, 'IDiagramCommand' ];
    }
    /**
     * Inject the data store service for local cache.
     */
    constructor( private store: DataStore ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Store model data received from the server and remove local changes.
     */
    public executeResult(): Observable<unknown> {
        const store = this.store.getModelStore( DiagramModel );
        return combineLatest(
            store.removeChanges({ modelId: this.resourceId }),
            store.insert( this.resultData.model ),
            store.insertStatic( this.resultData.modelStatic ),
        );
    }
}

Object.defineProperty( LoadDocument, 'name', {
    writable: true,
    value: 'LoadDocument',
});
