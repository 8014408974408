import { UUID } from 'angular2-uuid';
import { MessageStatus } from './message-status';
import { MessageType } from './message-type';

/**
 * Message class. This class defines the message protocol used
 * in communication with the Neutrino Server
 * @author  mehdhi
 * @since   March 2016
 */
export class Message {

    /*******************************************************************\
    *                                                                   *
    *                Mandatory Fields of Message Model                  *
    *                                                                   *
    \*******************************************************************/

    /**
     * This will hold an unique value to identify the message.
     */
    private id: string;

    /**
     * This will hold the version of the Message Protocol.
     * When breaking changes happen to the message protocol,
     * the protocol version will be changed.
     * Neutrino will be supporting all versions of the protocol
     * to ensure all apps continue to function.
     */
    private ver: number;


    /**
     * This wil hold the type of message.
     * There are set of defined message types which are listed below.
     * The type must be of one of these. Each Message Type will have
     * one or more message values (See next field) expected along
     * with it. Each message type will also expect to have data specific
     * to it.
     */
    private type: string;

    /**
     * This Indicates what this message actually is and works tightly
     * with Message Type. This field will only makes sense with the
     * Message Type field.
     */
    private msg: string;


    /**
     * This holds the time the message was initiated from the client
     * or the server. Specifically the time the message was sent from
     * the client/server. This is the origin time and does not change
     * during the lifetime of the message.
     */
    private ts: number;

    /**
     * This holds status of the message. Based on what has happened
     * to the message this will have different values.
     */
    private stat: string;


    /*******************************************************************\
    *                                                                   *
    *           Other ( optional) Fields of Message Model               *
    *                                                                   *
    \*******************************************************************/


    /**
     * This holds the the Creately client application that is sending
     * the message. The string will be the code name of the client app.
     */
    private app: string;

    /**
     *  This holds the authentication token from Gravity. (Base64 String)
     */
    private auth: string;

    /**
     *  This holds the name of device that is sending the message.
     *  Will contain a string to specify the device type, model or make.
     *  eg. iPhone 6
     */
    private dev: string;

    /**
     *  This indicates the operating system and version
     *  that is sending the message.
     */
    private os: string;

    /**
     * This indicates the browser agent and version of the agent
     * which is sending the message.
     */
    private brow: string;

    /**
     *  This is the id of the resource this message is about/for.
     *  The resource id can be a Diagram ID, a Project ID or a User ID.
     */
    private rid: string;

    /**
     * Payload. This is the data that is sent along with the message. This will
     * mostly be a JSON structure that will translate to a POJO in Neutrino.
     * However this can at times be a string, number or any other type.
     * This is a field that may not even be present in some messages.
     * This pertains to the Payload Version.
     */
    private pld: Object;

    /**
     * This will hold the version of the Payload Structure. When breaking
     * changes happen to the payload structure, the payload version will
     * be changed.
     */
    private pver: number;

    /**
     * This will hold the name of the subscription
     */
    private sub: string;

    /**
     * This will hold the version of the message. when there are multiple version of same command
     * and there is no protocol version change or payload version change then the message version
     * will be changed.
     */
    private mver: number;

    /*******************************************************************\
    *                                                                   *
    *                   Result Fields of Message                        *
    *                                                                   *
    \*******************************************************************/

    /**
     * This holds the time the message was dispatched from the client or
     * the server after processing. This is different from the ts value.
     * Specifically the time the message was sent from the client/server
     * after processing. Presence of this field means the Message Status
     * should be Processed or Error.
     */
    private rts: number;

    /**
     * This holds the error code for the Message Protocol with Neutrino.
     * Will contain a code out of a defined set of errors that can be sent
     * from Neutrino.
     */
    private errc: number;

    /**
     * A holds a human friendly message that most of the time can be shown
     * to the user. The message would make sense in users perspective while
     * the code would provide specific direction
     */
    private errm: string;

    /**
     * This is the data that is sent with the message after the message has
     * been processed. i.e the result. This will mostly be a JSON structure.
     * However this can at times be a string, number or any other type.
     */
    private ppld: Object;

    /**
     * This property can be available on messages sent from the client
     * as well as on messages sent from the server to the client. It can
     * have additional information about the client/server environment.
     */
    private ctx?: object;
    /**
     * Constructor of the MessageModel
     * @param version The message protocol version
     * @param messageType the type of the message of value MessageType
     * @param message the message name that is recognized by Neutrino
     */
    constructor( version: number, messageType: MessageType, message: string ) {
        // Generate globally unique id for message
        this.id = UUID.UUID();

        // Setting message protocol version
        this.ver = version;

        // Message type should be set explicitly with setMessageType method
        this.setMessageType( messageType );
        this.msg = message;

        // Setting the initial timestamp
        this.ts = new Date().getTime();

        // Message status should be set explicitly with setStatus method
        this.setStatus( MessageStatus.act );
    }

    public get messageId() {
        return this.id;
    }

    public get version() {
        return this.ver;
    }

    /**
     * This will return the type of message.
     */
    public get messageType() {
        return MessageType[this.type];
    }

    /**
     * Returns the message from the message protocol
     */
    public get message() {
        return this.msg;
    }

    /**
     * Returns the initiated timestamp
     */
    public get timeStamp() {
        return this.ts;
    }

    /**
     * Returns the status of message
     */
    public get status() {
        return MessageStatus[this.stat];
    }

    public set application( app: string ) {
        this.app = app;
    }

    public get application() {
        return this.app;
    }

    public set authToken( auth: string ) {
        this.auth = auth;
    }

    public get authToken() {
        return this.auth;
    }

    public set device( dev: string ) {
        this.dev = dev;
    }

    public get device() {
        return this.dev;
    }

    public set osName( os: string ) {
        this.os = os;
    }

    public get osName() {
        return this.os;
    }

    public set browser( brow: string ) {
        this.brow = brow;
    }

    public get browser() {
        return this.brow;
    }

    public set resourceId( rid: string ) {
        this.rid = rid;
    }

    public get resourceId() {
        return this.rid;
    }

    public set payload( pld: Object ) {
        this.pld = pld;
    }

    public get payload() {
        return this.pld;
    }

     public set payloadVersion( pver: number ) {
        this.pver = pver;
    }

    public get payloadVersion() {
        return this.pver;
    }
    public get subscription(): string {
        return this.sub;
    }
    public set subscription( sub: string ) {
        this.sub = sub;
    }

     public set messageVersion( mver: number ) {
        this.mver = mver;
    }

    public get messageVersion() {
        return this.mver;
    }

    public set returningTimeStamp( rts: number ) {
        this.rts = rts;
    }

    public get returningTimeStamp() {
        return this.rts;
    }

    public set errorCode( errc: number ) {
        this.errc = errc;
    }

    public get errorCode() {
        return this.errc;
    }

    public set errorMessage( errm: string ) {
        this.errm = errm;
    }

    public get errorMessage() {
        return this.errm;
    }

    public set processedPayload( ppld: Object ) {
        this.ppld = ppld;
    }

    public get processedPayload() {
        return this.ppld;
    }

    public get contextData(): object {
        return this.ctx;
    }

    public set contextData( val: object ) {
        this.ctx = val;
    }

    /**
     * This function will set the message type in string when MessageType enum is passed.
     */
    private setMessageType( type: MessageType ) {
        this.type = MessageType[type];
    }

    /**
     * This function will set the message status in string when MessageStatus enum is passed.
     */
    private setStatus( stat: MessageStatus ) {
        this.stat = MessageStatus[stat];
    }

}
