import { AbstractModel } from 'flux-core';

/**
 * Presenting mode determines what gets rendered on the slides.
 * Default mode should be set on presentation, but should be editable per slide.
 *
 * Presenting mode is set by presenter ( version 1 )
 */
export enum PresentingMode {
    /**
     * Display only the content of the slide
     */
    FOCUSED = 'focused',

    /**
     * Display the entire content of the workspace
     */
    WORKSPACE = 'workspace',
}

/**
 * Basic structure of a slide
 */
export interface ISlide {
    /**
     * Presentation id
     */
    presentationId: string;
    /**
     * title of the slide
     */
    title: string;
    /**
     * slide notes
     */
    notes: string;
    /**
     * workspace where shapes are located
     */
    diagramId: string;
    /**
     * Presenting mode of the slide
     */
    mode?: PresentingMode;
}

export interface IPresentingState {
    /**
     * Boolean which toggles on or off when there is someone presenting this presentation
     */
    isPresenting: boolean;
    /**
     * UserID of presenter
     */
    presenterId: string;
}

/**
 * The presentation model represents a presentation added to a folder.
 * Each presentation belongs to a folder, and slides can come from any wworkspace within that folder.
 *
 */
export class PresentationModel extends AbstractModel {
    /**
     * The id of the folder it belongs to.
     */
    public projectId: string;

    /**
     * The name of the presentation
     */
    public name: string;

    /**
     * Presentation folder visibility
     */
    public isPrivate: boolean;

    /**
     * User who created the presentation
     */
    public userId: string;

    /**
     * People invited to presentation if it is private
     */
    public collaborators: string[];

    /**
     * Slides in the presentation
     */
    public slides: { [slideId: string]: ISlide };

    /**
     * Stores current state of presentation, and if there is someone already presenting
     */
    public presentingState: IPresentingState;

    /**
     * Determines whether a presentation is accessible or not
     */
    public isDeleted: boolean;

    /**
     * Determines whether to show the warning modal when trying to share the presentation.
     */
    public hideWarning: boolean;
}

Object.defineProperty( PresentationModel, 'name', {
    writable: true,
    value: 'PresentationModel',
});
