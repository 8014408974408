import { AbstractCommand, Command, Layouting } from 'flux-core';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { tap } from 'rxjs/operators';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { DataType, IDataItem, IEntityDef } from 'flux-definition';
import { pick } from 'lodash';
import { Injectable } from '@angular/core';

@Command()
@Injectable()
export class FilterShapesToAdd extends AbstractCommand {

    data: {
        shapes: {
            [ shapeId: string ]: {
                id: string,
                entityDefId: string,
                eData: {
                    [eDataId: string]: string;
                },
                // NOTE: "type"   required for non-basic shapes.
                //       "path"   required for connectors.
                //       "zIndex" required for templates.
                [ shapeId: string ]: any,
            },
        },
        containerOptions?: {
            layoutingId: string,
        },
    };

    constructor(
        private ell: EDataLocatorLocator,
    ) {
        super();
    }

    public execute() {
        this.resultData = {
            shapes: this.data.shapes,
        };
        if ( Object.keys( this.data.shapes ).length < 200 ) {
            return true;
        }
        const firstShape = Object.values( this.data.shapes )[ 0 ];
        const eDataId = Object.keys( firstShape.eData || {})[0];
        if ( !eDataId ) {
            return true;
        }
        return this.ell.getEDataModel( eDataId ).pipe(
            tap( eDataModel => {
                if ( eDataModel.defId.startsWith( 'creately.edata.datasource' )) {
                    return true;
                }
                if ( !this.data.containerOptions?.layoutingId.startsWith( 'elk-mrtree' )) {
                    // pick first 200 shapes
                    this.resultData = {
                        shapes: pick( this.data.shapes, Object.keys( this.data.shapes ).slice( 0, 200 )),
                    };
                    return true;
                }
                const entityDefId = eDataModel.entities[firstShape.eData[eDataId]].eDefId;
                let entityDef: IEntityDef;
                if ( eDataModel.isCustom ) {
                    entityDef = eDataModel.customEntityDefs[entityDefId];
                } else {
                    entityDef = EDataRegistry.instance.getEntityDefById( entityDefId, eDataModel.defId );
                }
                const lookupFields = Object.values( entityDef.dataItems )
                    .filter( d => d.type === DataType.LOOKUP )
                    .filter(( d: any ) => d.options.eDefId === entityDefId ) as any as IDataItem<DataType.LOOKUP>[];
                const useMirrorHandshake = firstShape.$$createLinkOptions?.useMirrorHandshake || false;
                const handshakes = [];
                lookupFields.forEach( lf => {
                    if ( useMirrorHandshake && lf.options.isMirrorField ) {
                        handshakes.push( lf.id );
                    } else if ( !useMirrorHandshake && !lf.options.isMirrorField ) {
                        handshakes.push( lf.id );
                    }
                });
                if ( handshakes.length > 1 ) {
                    // ???
                }
                const handshake = handshakes[0];
                const entities: {
                    [ entityId: string ]: {
                        id: string,
                        shapeId: string,
                        parentId: string | null,
                    },
                } = {};
                for ( const shapeId in this.data.shapes ) {
                    const entityId = this.data.shapes[ shapeId ].eData[eDataId];
                    entities[entityId] = {
                        id: entityId,
                        parentId: null,
                        shapeId,
                    };
                }
                for ( const entityId in entities ) {
                    const entity = eDataModel.entities[entityId];
                    ( entity.data[handshake] || []).forEach( entId => {
                        if ( entities[entId]) {
                            entities[entId].parentId = entityId;
                        }
                    });
                }
                const toAdd = Layouting.getNextTreeNodes( Object.values( entities ));
                this.resultData = {
                    shapes: pick( this.data.shapes, toAdd.map( e => e.shapeId )),
                };
            }),
        );
    }
}

Object.defineProperty( FilterShapesToAdd, 'name', {
    value: 'FilterShapesToAdd',
});
