import { AbstractCommandList } from './command-list';
import { AbstractCommand } from '../abstract.cmd';

/**
 * This class provides a list of commands that need to execute parallelly and also
 * used to identify that the commands need to be executed parallely.
 * Extends <code>AbstractCommandList</code>.
 * @author  Gobiga
 * @since   2016.03.15
 *
 */
export class ParallelCommandList extends AbstractCommandList {
    constructor() {
        super()/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, ParallelCommandList.prototype );
    }

    /**
     * Add commands to a command list
     *
     * @param commandType An ICommand reference which needs to execute when a particular
     *                    command event occurs.
     */
    public add( commandType: typeof AbstractCommand ): ParallelCommandList {
        super.add( commandType );
        return this;
    }
}
