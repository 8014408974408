import { TeamUserRole } from './team.mdl';

/**
 * TeamRole related utility functions.
 */
export class TeamUserRoleUtil {

    /**
     * CHecks the team role has required team role's permission.
     * @param requiredRole
     * @param userRole
     * @returns
     */
    public static hasPermission( requiredRole: TeamUserRole, userRole: TeamUserRole ) {
        switch ( requiredRole ) {
            case TeamUserRole.MEMBER :
                return userRole === TeamUserRole.MEMBER ||
                        userRole === TeamUserRole.ADMIN ||
                        userRole === TeamUserRole.SUPERADMIN;
            case TeamUserRole.ADMIN :
                return userRole === TeamUserRole.ADMIN ||
                        userRole === TeamUserRole.SUPERADMIN;
            case TeamUserRole.SUPERADMIN :
                return userRole === TeamUserRole.SUPERADMIN;
            default :
                return false;
        }
    }
}
