import { Component, ComponentFactoryResolver, ComponentRef,
     EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { EDataRegistry } from 'apps/nucleus/src/base/edata/edata-registry.svc';
import { EDataModel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { DynamicComponent } from 'flux-core/src/ui';
import { StateService, CommandService } from 'flux-core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EdataSearchResultItem } from './edata-search-result-item/edata-search-result-item.cmp';
import { FloatingContainer } from 'libs/flux-core/src/ui/floating-container/floating-container.dir';
import { EDataCommandEvent } from 'apps/nucleus/src/base/edata/command/edata-command-event';

@Component({
    selector: 'edata-search-results-panel',
    templateUrl: 'edata-search-results-panel.cmp.html',
    styleUrls: [ './edata-search-results-panel.cmp.scss' ],
})

export class EdataSearchResultsPanel extends DynamicComponent implements OnInit {

    @Input() public searchResult: BehaviorSubject<any[]>;

    @Input() public searchQuery: string;

    @Input() public eDataModel: EDataModel;

    @Output() public goBack: EventEmitter<any> = new EventEmitter();

    @ViewChild( 'searchResultContainer', { read: ViewContainerRef, static: true })
    public searchResultContainer: ViewContainerRef;

    @ViewChild( 'floatingTooltip', { read: FloatingContainer })
    public floatingTooltip: FloatingContainer;

    public resultCount: number = 0;

    public dragging: boolean = false;
    public checked: BehaviorSubject<boolean> = new BehaviorSubject( false );
    // public canSave: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Subscriptions
     */
    protected subs: Array<Subscription> = [];

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected state: StateService<any, any>,
        protected commandService: CommandService,
    ) {
        super( componentFactoryResolver, injector );
    }
    ngOnInit() {
        this.searchResult.subscribe( result => {
            this.resultCount = result.length;
            this.insertItems( result );
        });
        try {
            const hiddenTooltips = JSON.parse( localStorage.getItem( 'HIDDEN_TOOLTIPS' ));
            if ( !hiddenTooltips.savedSet ) {
                setTimeout(() => this.floatingTooltip.show(), 100 );
            }
        } catch ( e ) {
            setTimeout(() => this.floatingTooltip.show(), 100 );
        }
    }

    public back() {
        this.goBack.emit();
    }

    public closeTooltip() {
        this.floatingTooltip.hide();
        if ( this.checked.value ) {
            const tooltipSettings = localStorage.getItem( 'HIDDEN_TOOLTIPS' );
            if ( tooltipSettings && tooltipSettings !== null ) {
                localStorage.setItem( 'HIDDEN_TOOLTIPS',
                    JSON.stringify({ ...JSON.parse( tooltipSettings ), savedSet: true }));
            } else {
                localStorage.setItem( 'HIDDEN_TOOLTIPS',
                    JSON.stringify({ savedSet: true }));
            }
        }
    }


    public insertItems( componentInstances: Array<any> ) {
        componentInstances.forEach( data => {
            const component: ComponentRef<EdataSearchResultItem> = this.makeComponent( EdataSearchResultItem );
            component.instance.data = {};
            component.instance.data.entity = data.entity;
            component.instance.data.eDataModel = data.eDataModel;
            component.instance.data.definition = data.definition;
            component.instance.eDefId = data.eDefId;
            component.instance.defId = data.id;
            component.instance.version = data.version;
            this.insert( this.searchResultContainer, component );
            component.changeDetectorRef.detectChanges();
        });
    }

    public handleSmartSetDragStart( event: DragEvent ) {
        this.dragging = true;
        const shapes = {};
        const dataArray =  this.searchResult.getValue();
        let entityListModel;
        if ( this.eDataModel && this.eDataModel.entityLists ) {
            entityListModel = Object.values( this.eDataModel.entityLists )
            .find( el => el.search === this.searchQuery );
        }
        const copyDataFromEntity = ( entity, entityDef?: any ) => {
            if ( !entityDef ) {
                entityDef = entity.getDef();
            }
            Object.keys( shapes[entity.id].data ).forEach(( key: string ) => {
                if ( entityDef && entityDef.dataItems[key]) {
                    shapes[entity.id].data[key].value = entity.data[key] ||
                    entityDef.dataItems[key].default;
                }
            });
        };
        for ( const data of dataArray ) {
            const entity = data.entity;
            const eDataModel = data.eDataModel;
            const entityId = entity.id;
            if ( entity ) {
                const def = entity.getShapeDefIdForContext();
                const shapeDef =  data.definition;
                if ( def ) {
                    shapes[entityId] = Object.assign({}, shapeDef );
                    const shapeData  = shapeDef.data;
                    shapes[entityId].data = {};
                    if ( entity.style ) {
                        shapes[entityId].style =  entity.style.shape;
                    }
                    Object.assign( shapes[entityId].data, shapeData );
                    Object.assign( shapes[entityId].data, ( shapeDef as any ).dataDef );
                    if ( EDataRegistry.customEdataDefId ===  entity.defId ) {
                        copyDataFromEntity( entity );
                    } else {
                        const entityDef = entity.getDef();
                        if ( entityDef.shapeDefs[shapes[entityId].defId]) {
                            const dataMap = entityDef.shapeDefs[shapes[entityId].defId].dataMap;
                            dataMap.forEach(({ dataItemId, eDataFieldId }) => {
                                shapes[entityId].data[dataItemId].value = entity.data[eDataFieldId] ||
                                     entityDef.dataItems[eDataFieldId].default;
                            });
                        } else {
                            copyDataFromEntity( entity, entityDef );
                        }
                    }

                    shapes[entityId].entityListId = entityListModel?.id;
                    shapes[entityId].triggerNewEData = true;
                    shapes[entityId].eData = {
                        [eDataModel.id]: entity?.id,
                    };
                    if ( entity.texts && entity.texts.length > 0 &&
                        entity.texts.some( t => t.shapeDef === shapeDef.defId )) {
                        const texts = ( shapeDef as any ).texts || {};
                        const primaryTextId = Object.keys( texts ).find( txtId => texts[txtId].primary );
                        if ( primaryTextId ) {
                            const text = entity.texts.find( t => t.shapeDef === shapeDef.defId ).text;
                            shapes[entityId].texts = {
                                [primaryTextId]: text,
                            };
                        }
                    }

                    if ( entity.textStyles ) {
                        shapes[entityId].textStyle = Object.assign({}, entity.textStyles );
                    }

                }
            }
        }
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData( 'PreDifQueries',
            JSON.stringify({
                eDefId: this.eDataModel.defId,
                definedSearchQuery: this.searchQuery,
                shapes: shapes,
            }),
        );
        this.state.set( 'ImageOverlay', 'entity-group-import' );
    }

    public handleSmartSetDragEnd( event ) {
        this.dragging = false;
        this.state.set( 'ImageOverlay', '' );
    }

    protected createSavedSet() {
        const entityIds = this.searchResult.value.map( i => i.entity.id );
        const eDataId = this.eDataModel.id;
        return this.commandService.dispatch( EDataCommandEvent.saveFilteredResults, {
            eDataId, entityIds, definedSearchQuery: this.searchQuery });
    }
}
