import { TranslateService } from '@ngx-translate/core';
import { DynamicComponent } from 'flux-core/src/ui';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { TiptapMentionsListCmp } from './tiptap-mentions-list.cmp';
import { Subscription } from 'rxjs';
import { CollabLocator } from '../../../../../base/diagram/locator/collab-locator';
import { take } from 'rxjs/operators';

// tslint:disable:member-ordering
/**
 * TiptapMentionsService
 * This service contains mentions items and it returns
 * the config object required by the tiptap's Suggestion extention.
 */
export class TiptapMentionsService {

    /**
     * Returns 'items' list and 'render' which are required by the tiptap
     * editor suggestions extension
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create(
        injector: Injector, viewCR: ViewContainerRef ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const commandService = injector.get( CommandService );
        const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const translate = injector.get( TranslateService );
        const collabLocator = injector.get( CollabLocator );
        const instance = new TiptapMentionsService(
            cfr, injector, commandService, stateSvc, ll, viewCR, translate, collabLocator );
        const { items, render, subs } = instance;
        return { items, render, subs, instance };
    }

    public subs: Subscription[] = [];
    public isOpen = false;

    private constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected commandService: CommandService,
        protected stateSvc: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected viewCR: ViewContainerRef,
        protected translate: TranslateService,
        protected collabLocator: CollabLocator,
    ) {}

    /**
     * This callback is called by the tiptap's suggestions extention
     */
    public items = async ({ query = '' }) => {
        const commandsList = [];
        const collabsObs = this.collabLocator.getCollabs( this.stateSvc.get( 'CurrentDiagram' ));
        const collabs = await collabsObs.pipe( take( 1 )).toPromise();
        const lists: any = {};
        if ( collabs.length ) {
            lists.people = [];
            collabs.forEach( collab => lists.people.push({
                title: collab.firstName + ' ' + collab.lastName,
                id: collab.id,
                item: collab,
            }));
        }

        const dataSectionCount = {};
        Object.keys( lists ).forEach( key => {
            lists[key].forEach( item => {
                if ( !dataSectionCount[key]) {
                    dataSectionCount[key] = 0;
                }
                if ( dataSectionCount[key] < 3 ) {
                    if ( !item.title.toLowerCase().startsWith( query.toLowerCase())) {
                        return;
                    }
                    dataSectionCount[item.section]++;
                    commandsList.push({
                        id: item.id,
                        section: key,
                        title: item.title,
                        label: item.title,
                        item: item.item,
                    });
                }
            });
        });

        return commandsList;
    }

    /**
     * This callback is called by the tiptap's suggestions extention
     * The TiptapMentionsListCmp angular component is rendered as the
     * mentions menu dropdown
     */
    public render = () => {
        let component: TiptapMentionsListCmp;
        let dc: DynamicComponent;
        let itemRef: ComponentRef<any>;
        let lastSelectedId: string;
        let scrollY: number;

        return {
            onStart: props => {
                if ( !props.editor.isFocused ) {
                    return;
                }
                dc = new DynamicComponent( this.cfr, this.injector );
                itemRef = dc.makeComponent( TiptapMentionsListCmp );
                itemRef.changeDetectorRef.detectChanges();
                component = itemRef.instance;
                component.updateProps( props );
                dc.insert( this.viewCR, itemRef );
                itemRef.changeDetectorRef.detectChanges();
                component.updatePosition( props );
                this.isOpen = true;
                if ( lastSelectedId ) {
                    ( itemRef.instance as TiptapMentionsListCmp ).selectedId.next( lastSelectedId );
                    ( itemRef.instance as TiptapMentionsListCmp ).setScrollY( scrollY || 0 );
                }
                const destroy = () => {
                    this.isOpen = false;
                    if ( itemRef ) {
                        itemRef.destroy();
                        ( dc as any ).remove( this.viewCR, itemRef );
                    }
                    props.editor.view.dom.removeEventListener( 'tiptap-blur', destroy );
                };
                props.editor.view.dom.addEventListener( 'tiptap-blur', destroy );
            },

            onUpdate:  props => {
                if ( component && itemRef ) {
                    component.updateProps( props );
                    itemRef.changeDetectorRef.detectChanges();
                    component.updatePosition( props );
                }

            },

            onKeyDown:  props => {
                if ( props.event.key === 'Escape' && itemRef ) {
                    this.isOpen = false;
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                    return true;
                }
                if ( component && itemRef ) {
                    const val = component.onKeyDown( props );
                    itemRef.changeDetectorRef.detectChanges();
                    return val;
                }
            },

            onclick: props => {
                if ( !props.editor.isFocused ) {
                    this.isOpen = false;
                    if ( itemRef ) {
                        itemRef.destroy();
                        ( dc as any ).remove( this.viewCR, itemRef );
                    }
                }
            },

            onExit: () => {
                this.isOpen = false;
                if ( itemRef ) {
                    lastSelectedId = ( itemRef.instance as TiptapMentionsListCmp ).selectedId.value;
                    scrollY = ( itemRef.instance as TiptapMentionsListCmp ).getScrollY();
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                }
            },
        };
    }
}
