import { EntityModel } from '../../../../src/base/edata/model/entity.mdl';
import { AbstractNotification } from 'flux-core';
import { NotifierController, NotificationType } from 'flux-core';
import { DESCRIPTION_DATAITEM_ID } from './../../ui/shape-data-editor/data-items-renderer.cmp';
import { switchMap, map, take } from 'rxjs/operators';
import { DiagramLocatorLocator } from './../../diagram/locator/diagram-locator-locator';
import { IDataItem, DataType } from 'flux-definition';
import { Injectable } from '@angular/core';
import { Command, Random } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { values } from 'lodash';


/**
 * This command updates ( add / update / delete ) the entityDefs ( bluepirints of entities ) in the
 * Edata model
 *
 * create:
 * When a new type is created from a shape, the entity def is created with its current data items
 *
 * update:
 * When data items are added or removed from the sahep, this command updated the entity def.
 * Also can use to update other properties e.g. Entity name etc.
 */
@Injectable()
@Command()
export class UpdateEntityDefs extends AbstractEDataChangeCommand {

    // tslint:disable:member-ordering
    constructor(
        protected notifierController: NotifierController,
        protected ll: DiagramLocatorLocator,
        protected es: EDataChangeService,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    /**
     * Input data format for the command
     */
    public data:  {
        shapeId: string,
        entityDefId?: string,
        entityDefName?: string,
        copyDescription?: boolean,
        action?: 'delete' | 'rename',
    };

    public prepareData() {
        if ( this.data.copyDescription === undefined ) {
            this.data.copyDescription = true;
        }
        if ( this.data.action === 'delete' ) {

            // Type can be completely erased only if there's no any entity created from that type.
            const canRemove =
                !values( this.changeModel.entities ).find(( e: EntityModel ) =>  e.eDefId === this.data.entityDefId );
            if ( canRemove ) {
                delete this.changeModel.customEntityDefs[ this.data.entityDefId ];
                return;
            }

            this.changeModel.customEntityDefs[ this.data.entityDefId ].deleted = true;
            // erase the name of the deleted type so that the user can use same name again.
            this.changeModel.customEntityDefs[ this.data.entityDefId ].name = '';
            return;
        }
        if ( this.data.action === 'rename' ) {
            this.changeModel.customEntityDefs[ this.data.entityDefId ].name = this.data.entityDefName;
            return;
        }

        if ( this.data.entityDefName && values( this.changeModel.customEntityDefs )
            .find( def => def.name.toLocaleLowerCase() === this.data.entityDefName.toLocaleLowerCase())) {
            this.showNotification( this.changeModel.name, this.data.entityDefName );
            return;
        }

        // what if dataItem is removed from entity type? should we remove existing one or move them to dateDef?
        return this.ll.forCurrentObserver( false ).pipe(
            switchMap( locator => locator.getDiagramOnce()),
            take( 1 ),
            map( diagram => {
                const shapeModel = diagram.shapes[ this.data.shapeId ];
                const dItems = values( diagram.getShapeDataItems( this.data.shapeId ))
                    .filter( dataItem => ( dataItem.isPublic === true || dataItem.isPublic === undefined ));
                let skipList = [];
                if ( !this.changeModel.isCustom ) {
                    const eDef = this.changeModel.getDefinition();
                    const entityDef = eDef.entityDefs[this.data.entityDefId];
                    if ( entityDef && entityDef.dataItems ) {
                        skipList = Object.keys( entityDef.dataItems );
                        const shapeDef = entityDef.shapeDefs && entityDef.shapeDefs[shapeModel.defId];
                        if ( shapeDef && shapeDef.dataMap ) {
                            const fieldMap = {};
                            shapeDef.dataMap.forEach( m => {
                                fieldMap[m.eDataFieldId] = m.dataItemId;
                            });
                            for ( let i = 0; i < skipList.length; i++ ) {
                                if ( fieldMap[skipList[i]]) {
                                    skipList[i] = fieldMap[skipList[i]];
                                }
                            }
                        }
                    }
                }
                const dataItems = this.getEntityDefsDataItems( dItems, shapeModel.previewText, skipList );
                let defValue;
                if ( !this.changeModel.isCustom ) {
                    // this is a proper def and this is an extension.
                    defValue = {
                        id: this.data.entityDefId,
                        dataItems,
                    };
                } else {
                    defValue = {
                        id: this.data.entityDefId,
                        name: this.data.entityDefName,
                        titleDataItem: 'name',
                        linkIdDataItem: 'name',
                        parentDataItem: 'parent',
                        icon: 'shapes-class',
                        dataItems,
                        defaultShape: {
                            defId: shapeModel.defId,
                            version: shapeModel.version,
                            style: this.getStyle( shapeModel ),
                            drawCode: this.getInstructions( shapeModel ),
                            defaultShapeContext : ( shapeModel as any ).shapeContext,
                            texts: shapeModel.texts,
                        },
                        shapeDefs: {
                            // can't set since mongo doesn't support dotted ids
                            // [shapeModel.defId]: {
                            //     version: shapeModel.version,
                            //     contexts: [ '*' ],
                            // },
                        },
                        grouping: this.data.entityDefName,
                    };
                }

                if ( !this.changeModel.customEntityDefs ) {
                    this.changeModel.customEntityDefs = {};
                }
                const cEntityDef = this.changeModel.customEntityDefs[ this.data.entityDefId ];
                if ( !cEntityDef ) { // No def found, we add a new def
                    this.changeModel.customEntityDefs[ this.data.entityDefId ] = defValue;
                } else {
                    if ( this.data.entityDefName === undefined ) {
                        delete defValue.grouping;
                        delete defValue.name;
                    }
                    Object.assign( cEntityDef, defValue );
                }

                // now that its synced, we can delete the dataDef in the model
                const entityId = shapeModel.entityId;
                if ( this.changeModel.dataDefs ) {
                    if ( this.changeModel.dataDefs[ entityId ]) {
                        delete this.changeModel.dataDefs[ entityId ];
                    }
                    const dataItemIds = Object.keys( dataItems );
                    const entityIds = this.changeModel.getEntitiesByDefId( this.data.entityDefId ).map( e => e.id );
                    entityIds.forEach( eId => {
                        const dDef = this.changeModel.dataDefs[ eId ];
                        if ( dDef ) {
                            dataItemIds.forEach( dId => {
                                delete dDef[dId];
                            });
                            if ( Object.keys( dDef ).length === 0 ) {
                                delete this.changeModel.dataDefs[ eId ];
                            }
                        }
                    });
                }
                return true;
            }),
        );
    }

    private getStyle( model ) {
        return {
            shape : model.style,
            bounds: {
                width: model.width,
                height: model.height,
                angle: model.angle,
                defaultBounds: model.defaultBounds,
            },
        };
    }

    private getInstructions( model ) {
        if ( model.instructions ) {
            return {
                instructions: model.instructions,
                scaleX: model.scaleX,
                scaleY: model.scaleY,
                lineThickness: model.style.lineThickness,
                defaultWidth: model.defaultBounds.width,
                defaultHeight: model.defaultBounds.height,
            };
        }
    }

    protected showNotification( dbName: string, typeName: string ) {
        const options = {
            inputs: {
                heading: 'Error Creating New Type',
                description: `The name you entered for the new type already exists in the database: ${dbName}.
                    Please enter a new name.`,
                autoDismiss: true,
                dismissAfter: 3000,
            },
        };

        this.notifierController.show( 'type_creation_failed', AbstractNotification,
            NotificationType.Error, options, false );
    }

    protected getEntityDefsDataItems( diList: IDataItem<any>[], previewText: string, skipList: string[]) {
        const dataItems = {};
        diList.forEach( item => {
            if ( item.id && ( item.id === DESCRIPTION_DATAITEM_ID || skipList.indexOf( item.id ) === -1 )) {
                dataItems[ item.id ] = {
                    default: this.getDefaultValueForType( item ),
                    ...item,
                };
                if ( this.data.copyDescription && item.id === DESCRIPTION_DATAITEM_ID ) {
                    dataItems[ item.id ].default = item.value;
                }
                dataItems[ item.id ].isTypeBound = true;
                dataItems[ item.id ].removeable = false;
                dataItems[ item.id ].labelEditable = false;
            }
        });
        const hasPrimaryTextBoundDI: boolean = diList
        .some(( item: any ) =>  item.primaryTextBound === true );
        /**
         * Add a hidden data item to bind to the primary text model
         */
        if ( !hasPrimaryTextBoundDI ) {
            const id = Random.dataItemId();
            dataItems[id] = this.createTextDataItem( id, previewText );
        }
        return dataItems;
    }

    private getDefaultValueForType( dataItem: IDataItem<any> ) {
        if ( dataItem.type === DataType.LOOKUP ) {
            return [];
        }
        if ( dataItem.value ) {
            return dataItem.value;
        }
        if ( dataItem.type === DataType.USERS ) {
            return {
                source: {},
                people: [],
            };
        }
        if ( dataItem.type === DataType.TAGS ) {
            return [];
        }
        return '';
    }


    private createTextDataItem( id: string, previewText: string ) {
        return {
            id,
            type: DataType.STRING,
            visibility: [],
            default: previewText || '',
            value: previewText || '',
            label: '',
            labelEditable: true,
            optional: true,
            validationRules: {},
            primaryTextBound: true,
            isPublic: true,
        };
    }

}

Object.defineProperty( UpdateEntityDefs, 'name', {
    value: 'UpdateEntityDefs',
});
