import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ModelChangeUtils } from '../../../framework/edata/model-change-utils';
import { DiagramModel } from '../../diagram/model/diagram.mdl';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { EDataChangeService } from '../edata-change.svc';
import { EntityModel } from '../model/entity.mdl';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

/**
 * Adds an entity to the EDataModel
 */
@Injectable()
@Command()
export class AddEntity extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entity: EntityModel,
        diagram: DiagramModel,
        shape: ShapeModel,
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        // in the first creation run, a temp change model is set by
        // sakota and it's not reflected correctly here.
        // may be because after creation of the document we are not re-fetching it from the DB
        // but using it straight away.
        if ( this.changeModel.id !== this.resourceId ) {
            this.changeModel.id = this.resourceId;
        }

        this.modelChangeUtils.addEntity(
            this.changeModel,
            this.data,
        );
    }

}

Object.defineProperty( AddEntity, 'name', {
    value: 'AddEntity',
});

