import { PlanModel, UserInfoModel } from 'flux-user';
import { ComplexType, DateFNS } from 'flux-core';

/**
 * These are the roles a collaborator can have
 * on a given resource (diagram or project)
 */
export enum CollaboratorType {
    OWNER = 0,
    MODERATOR = 2,
    EDITOR = 5,
    REVIEWER = 10,
}

/**
 * These are sort order priority where collaborators
 * should be list according to there role and
 * registered status. Owner has the highest priority and
 * UNREG_REVIEWER has the lowest priority.
 */
export enum RoleSortOrder {
    ONLINE_OWNER = 0,
    ONLINE_MODERATOR = 1,
    ONLINE_EDITOR = 2,
    ONLINE_REVIEWER = 3,
    OWNER = 4,
    MODERATOR = 5,
    EDITOR = 6,
    REVIEWER = 7,
    UNREG_MODERATOR = 8,
    UNREG_EDITOR = 9,
    UNREG_REVIEWER = 10,
}

/**
 * CollabModel
 * This is a collaborator model and represents a single
 * collaborator of a resource. This extends the {@link UserInfoModel}
 *
 * @author  hiraash
 * @since   2015-10-07
 */
export class CollabModel extends UserInfoModel {

    /**
     * This is the role of the collaborator on
     * the given resource. {@link CollaboratorType}
     */
    public role: CollaboratorType;

    /**
     * Date this collaborator last accessed the resource
     */
    public lastSeen: Date;

    /**
     * Indicates if the collaborator is online on the associated
     * resource. Happens if the user has openned the resource and is active
     */
    public online: boolean = false;

    /**
     * Only valid if {@link online} is true.
     * Indicates what the user is doing.
     */
    public currentActivity: 'viewing' | 'editing';

    /**
     * Only valid if {@link online} is true.
     * The unique color assgined to the user for this resource
     */
    public color: string;

    /**
     * Checks if the collaborator is inharited from it's parent model.
     * For Eg:- Project's collaborator showing in diagram's collab list.
     */
    public inharitedFromParent: boolean = false;

    /**
     * This holds the plan and plan permission related details of this user.
     */
    @ComplexType()
    public plan: PlanModel;
    constructor( id?: string ) {
        super( id );
    }

    /**
     * Returns the sort order of the collaborator on the
     * given resource. Considers the online status of the user in the
     * sort order.
     */
    public get roleSortOrder(): RoleSortOrder {
        if ( this.role === CollaboratorType.OWNER && this.online  ) {
            return RoleSortOrder.ONLINE_OWNER;
        }
        if ( this.role === CollaboratorType.MODERATOR && this.online  ) {
            return RoleSortOrder.ONLINE_MODERATOR;
        }
        if ( this.role === CollaboratorType.EDITOR && this.online ) {
            return RoleSortOrder.ONLINE_EDITOR;
        }
        if ( this.role === CollaboratorType.REVIEWER && this.online ) {
            return RoleSortOrder.ONLINE_REVIEWER;
        }
        return this.roleSortOrderWithoutOnline;
    }

    /**
     * Returns the sort order of the collaborator on the
     * given resource. Does NOT consider the online status of the user in the
     * sort order.
     */
    public get roleSortOrderWithoutOnline(): RoleSortOrder {
        if ( this.role === CollaboratorType.OWNER  ) {
            return RoleSortOrder.OWNER;
        }
        if ( this.role === CollaboratorType.MODERATOR && this.isRegistered ) {
            return RoleSortOrder.MODERATOR;
        }
        if ( this.role === CollaboratorType.MODERATOR && !this.isRegistered ) {
            return RoleSortOrder.UNREG_MODERATOR;
        }
        if ( this.role === CollaboratorType.EDITOR && this.isRegistered ) {
            return RoleSortOrder.EDITOR;
        }
        if ( this.role === CollaboratorType.EDITOR && !this.isRegistered ) {
            return RoleSortOrder.UNREG_EDITOR;
        }
        if ( this.role === CollaboratorType.REVIEWER && this.isRegistered ) {
            return RoleSortOrder.REVIEWER;
        }
        if ( this.role === CollaboratorType.REVIEWER && !this.isRegistered ) {
            return RoleSortOrder.UNREG_REVIEWER;
        }
    }

    /**
     * Returns the string for collaborator type.
     */
    public get roleTitle(): string {
        if ( this.role === CollaboratorType.OWNER  ) {
            return 'ROLES.OWNER';
        } else if ( this.role === CollaboratorType.MODERATOR  ) {
            return 'ROLES.MODERATOR';
        } else if ( this.role === CollaboratorType.EDITOR ) {
            return 'ROLES.EDITOR';
        } else {
            return 'ROLES.REVIEWER';
        }
    }

    /**
     * Getter that returns the user's activity status. This can be the time
     * from which the user was last seen or what the user is doing right now
     * if they are online.
     */
    public get lastSeenMoment(): string {
        if ( this.online ) {
            if ( this.currentActivity ) {
                return this.currentActivity;
            } else {
                return 'ACTIVITIES.ONLINE_NOW';
            }
        }

        if ( !this.lastSeen ) {
            return 'ACTIVITIES.NEVER_SEEN';
        } else {
            return DateFNS.formatDistance( this.lastSeen, new Date().getTime());
        }
    }

    /**
     * The color to use for the user across the app that represents
     * the user based on their current status
     */
    public get displayColor(): string {
        if ( this.color ) {
            return this.color;
        }
        return '#D8D8D8';
    }
}

Object.defineProperty( CollabModel, 'name', {
  writable: true,
  value: 'CollabModel',
});
