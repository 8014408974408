import { findIndex } from 'lodash';
import { CollabModel } from './model/collaborator.mdl';

/**
 * This class contains the functionality related to the collaborators list.
 *
 * @author  gobiga
 * @since   2016.10.24
 */
export class CollabList {

    /**
     * Creates an instance of the CollabList
     */
    public static create( collabs: CollabModel[]): CollabList {
        return new CollabList( collabs );
    }

    constructor( protected collabs: CollabModel[]) {}

    /**
     * Return collabs list with the new set of collaborators
     * with the sorted order.
     */
    public merge( newCollabs: CollabModel[]): CollabModel[] {
        return newCollabs.filter( collab => this.add( collab ));
    }

    /**
     * Insert the given collaborator based on the role and
     * registered status. Returns true if a new collab is inserted.
     */
    public add( newCollab: CollabModel ): boolean {
        // This is to check collaborator is alread exist or not.
        // If collaborator exists remove collaborator and insert new collaborator.
        const index = this.findDuplicate( newCollab );
        const added = index === -1;

        // If newCollab is an owner then don't remove it from the list.
        if ( index !== 0 ) {
            if ( index > 0 ) {
                this.deleteAt( index );
            }
            this.insertBasedOnRoleAndRegisteredStatus( newCollab );
        }

        return added;
    }

    /**
     * Remove collaborators from the list do not pass the filter function.
     * Return an array of removed collaborators.
     */
    public filter( filter: Function ): CollabModel[] {
        const removed = [];
        let index = 0;

        while ( index < this.collabs.length ) {
            if ( filter( this.collabs[ index ])) {
                index++;
            } else {
                const collab = this.deleteAt( index );
                removed.push( collab );
            }
        }

        return removed;
    }

    /**
     * Insert the given collaborator by considering the given role and registered status
     */
    protected insertBasedOnRoleAndRegisteredStatus( newCollab: CollabModel ) {
        for ( let index = this.collabs.length ; index > 0 ; index-- ) {
             if (( this.collabs[ index - 1 ]).roleSortOrder <= newCollab.roleSortOrder ) {
                 this.collabs.splice( index, 0, newCollab );
                 return;
             }
        }
    }

    /**
     * Returns the index found for the given collab email
     */
    protected findDuplicate( newCollab: CollabModel ): number {
        return findIndex( this.collabs, collab => collab.email === newCollab.email );
    }

    /**
     * Removes the collaborator at the given index.
     * Return the removed collaborator model.
     */
    protected deleteAt( index: number ): CollabModel {
        if ( index === -1 ) {
            return null;
        }
        const removed = this.collabs.splice( index, 1 );
        return removed[ 0 ];
    }

}
