import { Observable } from 'rxjs';
import { AbstractModel, IUnsavedModelChange } from 'flux-core';
import { DataStore, DataSync } from 'flux-store';
import { AbstractSubscription } from '../framework/abstract-subscription.sub';
import { IModelSubscription } from '../framework/subscription-type';
import { map } from 'rxjs/operators';

/**
 * ModelSubscription is the abstract class all model subscriptions should extend.
 */
export class AbstractModelSubscription extends AbstractSubscription implements IModelSubscription {
    constructor (
        public resourceId: string,
        protected dataStore: DataStore,
        protected dataSync: DataSync ) {
        super()/* istanbul ignore next */;
    }

    /**
     * subscription is the name of one of the pre defined subscription.
     */
    public get subscription(): string {
        throw new Error( 'not implemented!' );
    }

    /**
     * modelType is the type of the model.
     */
    public get modelType(): typeof AbstractModel {
        throw new Error( 'not implemented!' );
    }

    /**
     * alternative is an array of subscriptions which can be used instead
     * of this subscription. These subscriptions will have model types
     * extending current model type but with higher levels.
     */
    public get alternatives(): ( typeof AbstractModelSubscription )[] {
        return [];
    }

    /**
     * getLatestChangeId should return the latest change id of a model.
     * Returns null if the model does not exist in cached model storage.
     */
    public getLatestChangeId(): Observable<string> {
        return this.dataStore
            .findOneLatest( this.modelType, { id: this.resourceId })
            .pipe( map( d => d && d.lastChangeId ));
    }

    /**
     * getUnsavedChanges gets unsaved changes stored on client for a model.
     */
    public getUnsavedChanges(): Observable<IUnsavedModelChange[]> {
        const selector = { modelId: this.resourceId, status: 'unsaved' };
        return this.dataSync
            .findUnsavedChanges( this.modelType, selector );
    }
}
