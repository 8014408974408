import { ChangeDetectionStrategy, Component, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { IDropDownMenuItem, DropDown } from 'flux-core/src/ui';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';

@Component({
    template: `
                <drop-down #dropdown
                    [menuItems]="collaboratorTypes"
                    [disabled]="disabled"
                    [showSelected]="false"
                    [highlightSelected]="true"
                    [tooltipText]="tooltipText">
                </drop-down>`,
    selector: 'collab-type-drop-down',
    styleUrls: [ './collab-type-drop-down.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollabTypeDropdown implements AfterViewInit, OnDestroy  {

    /**
     * This holds the currently selected role
     */
    @Input() selectedValue: CollaboratorType = CollaboratorType.EDITOR;

    @Input()
    public updateSelectionOnRealtime: boolean = false;

    /**
     * Disable dropdown menu.
     */
    @Input()
    public disabled: boolean = false;

    /**
     * String path to the text tranlsation. If this string is given then it will show the tooltip,
     * otherwise it will not show the tooltip.
     */
    @Input()
    public tooltipText: string;

    /**
     * Excludes given list of CollaboratorTypes from Default list of CollaboratorTypes
     */
    @Input()
    public excludeCollabTypes: CollaboratorType[];

    /**
     * An observable which emits a value that got selected in the
     * menu list.
     */
    public select: Subject<any>;

   /**
    * Access the DropDown component
    */
    @ViewChild( DropDown )
    protected collabType: DropDown;

    /**
     * The list of subscriptions held by this class
     */
    protected subscriptions: Subscription[];

    constructor( protected translate: TranslateService ) {
        this.subscriptions = [];
        this.select = new Subject();
    }

    /**
     * Returns the available collaboartor roles to show in the
     * dropdown.
     */
    public get collaboratorTypes(): IDropDownMenuItem [] {
        const dropDownData = [
            {
                label: this.translate.instant( 'LABELS.CAN_MODERATE' ),
                value: CollaboratorType.MODERATOR,
                select: this.selectedValue === CollaboratorType.MODERATOR,
            },
            {
                label: this.translate.instant( 'LABELS.CAN_EDIT' ),
                value: CollaboratorType.EDITOR,
                select: this.selectedValue === CollaboratorType.EDITOR,
            },
            {
                label: this.translate.instant( 'LABELS.CAN_VIEW' ),
                value: CollaboratorType.REVIEWER,
                select: this.selectedValue === CollaboratorType.REVIEWER,
            },
        ];
        if ( this.excludeCollabTypes && this.excludeCollabTypes.length > 0 ) {
            return dropDownData.filter( item => !this.excludeCollabTypes.includes( item.value ));
        }
        return dropDownData;
    }

    /**
     * returns the currently selected item
     */
    public get selectedItem(): IDropDownMenuItem {
        return this.collabType.selectedItem;
    }

    public ngAfterViewInit() {
        this.subscriptions.push(
            this.collabType.select.subscribe( item => {
                if ( this.updateSelectionOnRealtime ) {
                    this.selectedValue = item.value;
                }
                this.select.next( item );
            }),
        );
    }

    /**
     * Destroys and clears all the resources used by the
     * colab privacy item.
     */
    public ngOnDestroy() {
        while ( this.subscriptions.length > 0 ) {
            this.subscriptions.pop().unsubscribe();
        }
    }

}
