import { CommandInterfaces, ExecutionStep } from 'flux-core';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { AbstractModelChangeCommand } from './abstract-model-change.cmd';

/**
 * PrepareProxyExecutionStep step sets the proxied model.
 */
export class PrepareProxyExecutionStep extends ExecutionStep {
    /**
     * relatedInterfaces property returns 'IModelChangeCommand' so that
     * only commands which extends abstract model change command runs this.
     *
     * FIXME: Added IDiagramCommand here to ignore the project model changes.
     * Proton uses the modifier to get the changes happened to the project
     * where nucleus using the change model and get the diagram changes.
     * The best approach is using the change model, however proton and
     * nucleus are using the same flux commands (eg: rename-project). So making
     * the change to proton to use the change model instead of modifiers will
     * need the changes in all the commands which used by the proton.
     * For the time being we added IDiagramCommand here to avoid the process
     * method from executing until we implement change model for proton as well.
     * By adding IDiagramCommand here we are saying that the process method should
     * run only for IModelChangeCommand which are IDiagramCommand. (should satisfy)
     * both interfaces to run the process method.
     * ( prepare proxy needs to be implemented for changing the
     * modifier to change model approach).
     */
    public static get relatedInterfaces(): CommandInterfaces[][] {
        return [
            [ 'IModelChangeCommand' ],
        ];
    }

    /**
     * This execution step should be run only for model change commands.
     */
    public command: AbstractModelChangeCommand;

    /**
     * Sets the changeModel property on model change commands.
     */
    public process(): Observable<any> {
        return this.command.prepareProxy().pipe(
            tap( m => {
            if ( !m || !m.__sakota__ ) {
                    throw new Error( 'The change model returned by command.prepareProxy() is not valid.' );
                }
            this.command.changeModel = m;
            }),
            ignoreElements(),
        );
    }
}

Object.defineProperty( PrepareProxyExecutionStep, 'name', {
    value: 'PrepareProxyExecutionStep',
});
