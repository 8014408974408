<div class="col-menu-item-container" [ngClass]="{ 'expanded': active, 'collapsed': !active }">
    <div class="col-menu-item-heading fx-no-selection fx-margin-bottom-5 btn-list-item">
        <div class="col-menu-item-heading-inner" (click)="toggle($event)">
            <div class="body col-menu-item-heading-title fx-ellipsis">
                <span class="col-menu-item-heading-title-text" title="{{title}}">{{ title }}</span>
                <span class="col-menu-item-total-shapes" *ngIf="showExpandButton">{{totalShapes}}</span>
                <div class="col-menu-item-premium" *ngIf="isPremium && (isFreeOrDemoUser|async)">
                    <div class="premium btn-secondary" (click)="openUpgradeWindow($event)">
                        <div class="icon">
                            <svg class="nu-icon">
                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-premium-crown"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-menu-item-icon" [hidden]="(icon || !collapsable)">
                <svg class="nu-icon nu-ic-close-thin">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-thin"></use>
                </svg>
            </div>
        </div>
    </div>
    <div class="col-menu-item-sub-label" *ngIf="( subText | async ) as text">
        {{ text }}
    </div>

    <div class="col-menu-item-content">
        <div class="fx-invisible-item" #componentContainer></div>
    </div>
</div>
