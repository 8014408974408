import { AbstractModel } from 'flux-core';
import { UserPermModel } from './user-perm.mdl';

/**
 * PlanModel
 * This holds information about a plan. A user is associated to one
 * plan at a given time. This plan defines all permissions a user can have.
 *
 * @author  Ramishka
 * @since   2018-01-09
 */

export class PlanModel extends AbstractModel {

    /**
     * Type of the current plan.
     */
    public type: string;

    /**
     * This indicate the status of the plan wheather it is ACTIVE, DEPRECATED
     * or DISCONTINUED.
     */
    public status: string;

    /**
     * This holds the description about the plan
     */
    public description: string;

    /**
     * All the permissions that the user has for this plan.
     */
    public permissions: { [feature: string]: UserPermModel };
}

Object.defineProperty( PlanModel, 'name', {
  writable: true,
  value: 'PlanModel',
});
