/**
 * FocusContext
 * Expected focus context which manages to bound specific operations for a
 * set of shortcut keys.
 * Shortcut keys are managed based on this context.
 */
export enum FocusContext {
    /**
     * The diagram on the canvas and most controls supported for editing managing
     * the diagram in the canvas defines as Canvas context
     * This activated when the canvas takes focus or the Application takes focus.
     */
    Canvas,

    /**
     * This context is active when the canvas takes focus and nothing on the canvas is selected,
     */
    CanvasNotSelected,

    /**
     * This context is active when the canvas takes focus and anything on the canvas is selected,
     */
    CanvasSelected,

    /**
     * Text context are all text cursor enable component. This activated When focus
     * is on text editable types
     * i.e textinput, textarea, content editable div etc..
     */
    Text,

    /**
     * This context is active when focus is on text on a canvas selected object.
     */
    CanvasSelectedText,

    /**
     * Special case. This is not an actual Context but an identifier used to denote
     * all contexts
     */
    All,
}
