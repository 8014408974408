import { AbstractSVGTextView } from './abstract-svg-text-view';
import { IConnectorText } from 'flux-definition';
import { TextPostion } from '../../framework/text/text-position';
import { IConnectorTextView } from '../../framework/text/text-view.i';
import { Rectangle } from 'flux-core';
import { ConnectorDataModel } from '../../shape/model/connector-data-mdl';
import { IPoint2D } from 'flux-definition';

/**
 * ConnectorSVGTextView
 * Process and generate SVG element to display texts on connectors
 */
export class ConnectorSVGTextView extends AbstractSVGTextView implements IConnectorTextView {

    /**
     * SVG element which represents the text on the connector.
     */
    public svgTextElement: string;

    /**
     * SVG mask structure for the text only.
     * Connecor view must collect all mask structures and create a single mask element
     * and link it to the svg element.
     */
    public svgTextMaskContent: string;

    /**
     * Name of the text view, This will be used as the instance identfier for text views.
     */
    public name: string;

    /**
     * Constructor
     * @param textModel Text model
     * @param model Connector data model
     */
    public constructor( textModel: IConnectorText, private connectorModel: ConnectorDataModel ) {
        super()/* istanbul ignore next */;
        this.name = textModel.id;
    }

    /**
     * Update current SVG connector text with given updated text model and properties.
     * @param model Connector text model
     * @param change Changes to the text model
     * @param textPosition Position of the text
     */
    public update( textModel: IConnectorText, change?: any, textPosition?: IPoint2D ) {
        if ( textModel.plainText && textModel.plainText.length > 0 ) {
            const textBounds = new Rectangle( 0, 0, textModel.width, textModel.height );
            const point = TextPostion.forConnector( this.connectorModel, textModel, textBounds );
            this.svgTextElement = '<g ' +
                `transform="translate(${point.x}, ${point.y})" ` +
                `width="${textBounds.width}" height="${textBounds.height}">`
                + this.getTextElements( textModel ) +
                '</g>';
            this.createTextMask( textModel, this.connectorModel, textBounds );
        }
    }

    public destroy() {
        // nothing to do
    }

    /**
     * Generate mask for the text on the connector. This creates a rectangle to show the text only.
     * This must be used on a mask element and the view must add the connector rectangle mask to complete the whole
     * mask.
     */
    private createTextMask( textModel: IConnectorText, model: ConnectorDataModel, textBounds: Rectangle ) {
        // Rectangle which contain the text on connector
        const textRectBounds =
            TextPostion.getRectangleOnConnector( model, textModel, textBounds ).pad( 3 );

        // Text rectangle mask
        const textMaskRect =
            `<rect x="${textRectBounds.x}" y="${textRectBounds.y}" ` +
            `width="${textRectBounds.width}" height="${textRectBounds.height}" fill="black" />`;

        this.svgTextMaskContent = textMaskRect;
    }
}
