import { Injectable } from '@angular/core';
import { Command, CommandService, Random } from 'flux-core';
// import { Observable } from 'rxjs';
// import { cloneDeep } from 'lodash';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { EntityListModel } from 'apps/nucleus/src/base/edata/model/entity-list.mdl';
import { Proxied } from '@creately/sakota';
import { EDataModel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { AbstractEDataChangeCommand } from 'apps/nucleus/src/base/edata/command/abstract-edata-change-command.cmd';
import { EDataChangeService } from 'apps/nucleus/src/base/edata/edata-change.svc';

@Injectable()
@Command()
export class AddEntityListModelToEData extends AbstractEDataChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        edataModel: EDataModel,
        definedSearchQuery: string,
        shapes: {
            [ shapeId: string ]: {
                id: string,
                defId: string,
                version: number,
                // NOTE: "type"   required for non-basic shapes.
                //       "path"   required for connectors.
                //       "zIndex" required for templates.
                [ shapeId: string ]: any,
            },
        },
        modifier?: any,
    };

    constructor(
        dc: EDataChangeService,
        protected eDataRegistry: EDataRegistry,
        protected commandService: CommandService,
        ) {
        super( dc );
    }

    public execute(): boolean {
        return this.createEntityList();
    }

    private createEntityList() {
                // const eDataSakotaModel: Proxied<EDataModel>  = Sakota.create( cloneDeep( this.data.edataModel ));
                const eDataSakotaModel: Proxied<EDataModel>  = this.changeModel;
                let entityList;
                if ( eDataSakotaModel && eDataSakotaModel.entityLists ) {
                    entityList = Object.values( eDataSakotaModel.entityLists )
                    .find( el => el.search === this.data.definedSearchQuery );
                }
                if ( eDataSakotaModel && !entityList ) {
                    const newEntityListModel = new EntityListModel( Random.entityListId()
                    , 'creately.edata.pre_defined_queries' );
                    newEntityListModel.search = this.data.definedSearchQuery;
                    if ( !eDataSakotaModel.entityLists ) {
                        eDataSakotaModel.entityLists = {};
                    }
                    eDataSakotaModel.entityLists[newEntityListModel.id] = newEntityListModel;
                    Object.values( this.data.shapes ).forEach( shape => {
                        shape.entityListId = newEntityListModel.id;
                    });
                }
                const edataChanges = eDataSakotaModel.__sakota__.getChanges();
                this.resultData = { edataId : eDataSakotaModel.id, modifier: edataChanges };
                return true;
    }
}
