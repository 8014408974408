<div class="notification-list-item-container user-img-show-indicator btn-list-item"
    [class.notification-list-item-container-seen]="!isRead"
    (click)="openReferingResource()">
    <div class="container-body">
        <div class="container-body-row">
            <div class="notification-list-item-user-image">
                <user-image
                    imageSize="small"
                    [hasImage]="notification.actorInfo.hasImage"
                    [imagePath]="notification.actorInfo.image"
                    [userColor]="notification.actorInfo.getColor()"
                    [userInitials]="notification.actorInfo.initial"
                    [userRegistered]="true"
                    [userOnlineStatus]="true">
                </user-image>
                <svg class="nu-icon nu-icon-med nu-ic-notification-action">
                    <use [attr.xlink:href]="notification.actionSVG"></use>
                </svg>
            </div>
            <div class="notification-list-item-notification-row">
                <div class="container-body-row-name-time">
                    <p class="notification-list-item-notification-email fx-ellipsis body">{{actorName}}</p>
                    <p class="notification-list-item-notification-time  fx-ellipsis grey caption">{{notification.formatedTime}}</p>
                </div>
                <div class="notification-list-item-is-read" *ngIf="!isRead" tooltip placement="bottom"
                    (click)="markAsRead()"
                    [trackAction]="{ event: 'notification.panel.markRead.click' }">
                    <span class="tooltip-content" translate>TOOLTIPS.NOTIFICATION.MARK_AS_READ</span>
                    <svg class="nu-icon nu-icon-xsmall nu-ic-ellipse">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-ellipse"></use>
                    </svg>
                    <svg class="nu-icon nu-icon-xsmall nu-ic-notification-tick">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-notification-tick"></use>
                    </svg>                
                </div>
            </div>
        </div>
        <div class="notification-list-item-inner" [ngStyle]="{'cursor':canNavigateToResource() ? 'pointer' : 'default' }">
            <div class="notification-list-item-notification-text-row">
                <p class="notification-list-item-notification-text body"
                    [innerHTML]="notification.translatedContentHTML | safeHTML"></p>
            </div>
        </div>
    </div>
</div>


