import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit } from '@angular/core';
import { AppConfig, StateService, Tracker } from 'flux-core';
import { BehaviorSubject } from 'rxjs';
import { DataStore } from 'flux-store';
import { UserInfoModel } from '../../user/model/user-info.mdl';
import { TranslateService } from '@ngx-translate/core';

/**
 * Internally used map for user plan types
 */
enum UserPlan {
    FREE = 'free',
    PREMIUM = 'premium',
}

/**
 * This is the plan window component.
 *
 * @author niroshana
 * @since 2019-02-20
 */
@Component({
    selector: 'plan-window',
    templateUrl: 'plan-window.cmp.html',
    styleUrls: [ 'plan-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanWindow implements OnInit, AfterViewInit {

    /**
     * Current user plan after demo conversion
     */
    public currentPlan: BehaviorSubject<UserPlan>;

    /**
     * Show/hide plan pricing content between UI changes
     */
    public showPriceContent: BehaviorSubject<boolean>;

    /**
     * Show/hide original plan prices
     */
    public showOfferPrices: BehaviorSubject<boolean>;

    /**
     * Constructor
     */
    public constructor(
        private dataStore: DataStore,
        private stateService: StateService<any, any>,
        protected translate: TranslateService,
    ) {
        this.showPriceContent = new BehaviorSubject( true );
        this.currentPlan = new BehaviorSubject( null );
        this.showOfferPrices = new BehaviorSubject( false );
    }

    /* istanbul ignore next */
    public ngOnInit() {
        Tracker.track( 'conversion.dialog.plans.load' );
    }

    /**
     * After paln window is rendered, check to see whether to show
     * offer prices or original prices based on the set partner
     * stack cookie
     */
    public ngAfterViewInit() {
        this.showOfferPrices.next( !this.hasPartnerStackCouponCode());
    }

    /**
     * Choose the public plan for the demo conversion.
     * This changes the plan UI to show the public plan message
     */
    public choosePublicPlan() {
        this.stateService.set( 'ShowPlanWindow', false );
        this.showPriceContent.next( false );
        Tracker.track( 'conversion.dialog.warning.ok.click', { value1Type: 'planChoice', value1: 'FREE' });
        this.reloadAfterConversion();
    }

    /**
     * Choose a premium plan for the demo conversion.
     * This opens a new browser window pointing to the purchase page.
     * also changes the plans page UI to display a friendly message to indicate the signup is getting completed on
     * another page.
     */
    public choosePremiumPlan( planType: string = '' ) {
        let pagePath: string;
        if ( planType === 'TEAM' ) {
            pagePath = 'TEAM_PURCHSAE_URL';
        } else if ( planType === 'PERSONAL' ) {
            pagePath = 'PERSONAL_PURCHSAE_URL';
        } else {
            pagePath = 'PLAN_PAGE_URL';
        }
        window.open( AppConfig.get( pagePath ), '_blank' );
        this.showPriceContent.next( false );
        this.currentPlan.next( UserPlan.PREMIUM );
    }

    /**
     * Opens creately site plans page.
     */
    /* istanbul ignore next */
    public openPlansPage() {
        window.open( AppConfig.get( 'PLAN_PAGE_URL' ), '_blank' );
        this.showPriceContent.next( false );
        this.currentPlan.next( UserPlan.PREMIUM );
    }

    /**
     * Show the plan content if user decide not to continue with a limited free plan
     */
    public showPlans() {
        this.currentPlan.next( null );
        this.showPriceContent.next( true );
    }

    /**
     * Open a browser window pointing the diagram community.
     */
    public openCommunity() {
        window.open( AppConfig.get( 'APP_URL' ) + 'diagram-community/', '_blank' );
    }

    /**
     * Reload the current application window after demo conversion
     */
    public reloadAfterConversion() {
        this.dataStore.remove(
            UserInfoModel,
            { id: this.stateService.get( 'CurrentUser' ) },
        ).subscribe({
            complete: () => {
                this.reload();
        }});
    }

    /**
     * Check if partner stack cookie is set
     * @returns availability of partner stack cookie
     */
    public hasPartnerStackCouponCode(): boolean {
        const coupon = this.getCookie( 'createlyCoupon' );
        const couponCode = AppConfig.get( 'PARTNERSTACK_COUPON_CODES' );
        if ( couponCode ) {
            const coupons = couponCode.split( ',' )
                .map( token => token.trim())
                .filter( token => token === coupon );
            return coupons.length > 0;
        }
        return false;
    }

    /**
     * A utility function to get a cookie value from client-side
     * @param cookieName name of the cookie
     * @returns cookie value if found, empty string otherwise
     */
    public getCookie( cookieName: string ): string {
        const match = document.cookie.match( new RegExp( '(^| )' + cookieName + '=([^;]+)' ));
        return match ? match[2] : null;
    }

    /**
     * Reload the current application window.
     * This is done to make the plan changes take effect.
     * NOTE: This function can not be tested with unit tests
     */
    /* istanbul ignore next */
    protected reload() {
        window.location.reload();
    }
}
