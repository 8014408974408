import { Observable, fromEvent } from 'rxjs';
import { IDropdownData } from './abstract-dropdown.cmp';
import { Input, Output, ElementRef, Directive } from '@angular/core';
import { map } from 'rxjs/operators';

/**
 * DropDownButton Component
 * This component is an abstract DropDownButton component that has
 * only the basic functionalities for the dropdown button.
 * This should be extended by a specific dropdown button component that
 * implements the @Component decorator
 */

@Directive()
export class DropDownButton <T extends IDropdownData> {

    /**
     * Dropdown items data array
     */
    @Input()
    public items: Array<T>;

    /**
     * Observable that emits the mouseenter event on this dropdown element
     */
    public mousEentered: Observable<T>;

    /**
     * Observable that emits the mouseleave event on this dropdown element
     */
    public mouseLeft: Observable<T>;

    /**
     * Observable that emits the click event on this element
     */
    @Output()
    public clicked: Observable<T>;

    constructor( protected el: ElementRef ) {
        const element: HTMLElement = this.el.nativeElement as HTMLElement;
        this.clicked = fromEvent( element, 'click' ).pipe( map(() =>  this.item ));
        this.mousEentered = fromEvent( element, 'mouseenter' ).pipe( map(() =>  this.item ));
        this.mouseLeft = fromEvent( element, 'mouseleave' ).pipe( map(() =>  this.item ));
    }

    /**
     * The selected items
     */
    public get selectedItems() {
      return this.items ? this.items.filter( item => item.selected ) : [];
    }

    /**
     * The selected item
     * usefull when the dropdown is used as single selectable dropdown
     */
    public get item() {
        return this.selectedItems[0];
    }

}
