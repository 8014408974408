/**
 * Theme settings shared by the diagram platform. These theme settings are universal to
 * all applications and each individual application ( project ) may set its' own theme settings
 * by extending this class
 */
export class CommonTheme {

    /**
     * Text
     */
    public static defaultFontFamily = 'noto_regular';
    public static defaultFontSize = '14px';
    public static defaultFontWeight = '';
    public static defaultFontStyle = '';
    public static defaultTextColor = '#1a1a1a';
    public static defaultTextAlign = 'center';

    /**
     * Shape
     */
    public static defaultShapeLineThickness = 2;
    public static defaultShapeLineColor = '#4B5356';
    public static defaultShapeFillColor = 'white';
    public static defaultShapeThumbnailLineColor = '#4B5356';

    /**
     * Icon
     */
    public static defaultIconColor = '#627281';
}
