import { cloneDeep, isEqual, unionWith } from 'lodash';
import { Injectable } from '@angular/core';
import { DataStore, DataSync } from 'flux-store';
import { concat, defer, from, merge, Observable, Subscription, BehaviorSubject,
    of, combineLatest, EMPTY, forkJoin } from 'rxjs';
import { concatMap, filter, map, take, switchMap, distinctUntilChanged, tap, mapTo, pairwise } from 'rxjs/operators';
import { EDataModel } from '../model/edata.mdl';
import { EDataLocator } from './edata-locator';
import { EntityModel } from '../model/entity.mdl';
import { EDataFactory } from './edata-factory';
import { IEDataChange } from './edata-change.i';
import { IModelChange, StateService } from 'flux-core';
import { modify } from '@creately/mungo';
import { DiagramModel } from '../../diagram/model/diagram.mdl';
import { ProjectModel } from 'flux-diagram';


/**
 * The DiagramLocatorLocator is used to create and get eData locators by the eData id.
 */
@Injectable()
export class EDataLocatorLocator {
    /**
     * Counter used to generate unique ids for subscription observers.
     */
    private counter = 0;

    /**
     * List of subscriptions to allow for
     * destroying this instance.
     */
    private subs: Subscription[];

    /**
     * A map of active edata locators.
     */
    private locators: BehaviorSubject <{ [id: string]: EDataLocator<EDataModel, EntityModel> }>;

    constructor(
        private dataStore: DataStore,
        private states: StateService<any, any>,
        private dataSync: DataSync,
    ) {
        this.subs = [];
        this.locators = new BehaviorSubject({});
    }

    /**
     * Initializes the locator locator. Should be called once upon creation.
     * This starts the eDatalocator and listens to changes in the current eData.
     * upon change, cleans up the previous locator references.
     */
     public initialize() {
        const sub = this.states.changes( 'CurrentProject' ).pipe(
                filter( val => Boolean( val )),
                distinctUntilChanged(),
                tap( val => {
                    if ( val === 'home' ) {
                        Object.keys( this.locators.value ).forEach( k => {
                            const locator = this.locators.value[ k ];
                            locator.stop();
                        });
                        this.locators.next({});
                    }
                }),
                pairwise(),
                switchMap(( prev, curr ) => {
                    if ( Object.keys( this.locators ).length === 0 ) {
                        return EMPTY;
                    }
                    return forkJoin([
                        this.dataStore.findOne( ProjectModel, { id: prev }),
                        this.dataStore.findOne( ProjectModel, { id: curr }),
                        this.dataStore.findOne( DiagramModel, { id: this.states.get( 'CurrentDiagram' ) }),
                    ]).pipe(
                        tap(([ prevProj, currProj, currDiag ]) => {
                            const prevEData = prevProj?.eData || [];
                            const currEData = currProj?.eData || [];
                            const diagraEData = currDiag?.eData || [];
                            const removed = prevEData.filter( e => !currEData.includes( e )
                                && !diagraEData.includes( e ));
                            const locators = this.locators.value;
                            for ( const id of removed ) {
                                locators[ id ].stop();
                                delete locators[ id ];
                            }
                            this.locators.next( locators );
                        }),
                    );
                }),
            ).subscribe();
        this.subs.push ( sub );
    }

    /**
     * Returns the eDataModels for the current project, depends on the
     * current eData and fetches edata that can be accessed in that context.
     */
    public currentEDataModels( emitModelChanges = false ): Observable<Array<EDataModel>> {
        const modelsObs = combineLatest([ this.currentProjectEDataModels(), this.currentDiagramEDataModels() ]).pipe(
            map(([ projectEDataMdls, diagramEDataMdls ]) =>
                unionWith( projectEDataMdls, diagramEDataMdls, ( a, b ) => a.id === b.id )),
        );
        if ( !emitModelChanges ) {
            return modelsObs.pipe(
                distinctUntilChanged(( prev, curr ) => {
                    const prevIds = prev.map( e => e.id );
                    const currIds = curr.map( e => e.id );
                    return isEqual( prevIds, currIds );
                }),
            );
        }
        return modelsObs;
    }

    /**
     * Returns the eDataModels for the current project, depends on the
     * current eData and fetches edata that can be accessed in that context.
     */
     public getEDataModelSet( idList: string[]): Observable<Array<EDataModel>> {
        return this.states.changes( 'CurrentDiagram' ).pipe(
            distinctUntilChanged(),
            switchMap( projId => this.dataStore.find( EDataModel, { id: { $in: idList }})),
            distinctUntilChanged( isEqual ),

        );
    }

    /**
     * Returns the eDataModels for the current project, depends on the
     * current eData and fetches edata that can be accessed in that context.
     */
    public currentEDataModelsOnce(): Observable<Array<EDataModel>> {
        return this.currentEDataModels().pipe(
                take( 1 ),
            );
    }

    /**
     * Returns and observable that emits the edata model for the given ID
     * Observbale will emit undefined if no edata model found
     */
     public getEDataModel( id: string ): Observable<EDataModel> {
        return this.dataStore.find( EDataModel, { id }).pipe(
            take( 1 ),
            map( v => v[0]),
        );
    }

    /**
     * gets a given EDataModel
     * @param id
     */
    public getEData( id: string ): Observable<EDataLocator<EDataModel, EntityModel>> {
        if ( this.locators.value[ id ] === undefined ) { // Locator has not been created, should create it
            const locators = this.locators.value;
            locators[ id ] = null ; // Set null as the initial value ( Adding a flag 'creation started' )
            this.locators.next( locators );
            this.createEDataLocator( id ).pipe(
                take( 1 ),
                tap( l => {
                    locators[ id ] = l;
                    this.locators.next( locators );
                }),
           ).subscribe();
        }
        return this.locators.pipe(
            filter( ltrs => !!ltrs[ id ]),
            map( ltrs => ltrs[ id ]),
            take( 1 ),
        );
    }


    /**
     * Convinence method to directly get an entity when passed an EDataModel and an Id;
     * @param eDataId
     * @param entityId
     */
    public getEntityOnce( eDataId: string, entityId: string ): Observable<EntityModel> {
        return this.getEData( eDataId ).pipe(
            switchMap( locator => locator.getEntityModel( entityId )),
            take( 1 ),
        );
    }

    /**
     * Destroys the locator-locator gracefully
     */
    public destroy() {
        let sub: Subscription;
        while ( sub = this.subs.pop()) {
            sub.unsubscribe();
        }
    }

    /**
     * Fetches all the EDataModels linked to current diagram.
     * @returns
     */
    public currentDiagramEDataModels(): Observable<Array<EDataModel>> {
        return this.states.changes( 'CurrentDiagram' ).pipe(
            filter( diagramId => !!diagramId ),
            distinctUntilChanged(),
            switchMap( diagramId => this.dataStore.findOne( DiagramModel, { id: diagramId })),
            filter( diagram => !!diagram ),
            switchMap(( diagram: any ) =>
                ( diagram.eData && diagram.eData.length > 0 ) ?
                    this.dataStore.find( EDataModel, { id: { $in: diagram.eData }}) : of([])),
            distinctUntilChanged( isEqual ),
        );
    }

    /**
     * Fetches all the EDataModels for current project and returns it.
     * @returns
     */
    public currentProjectEDataModels(): Observable<Array<EDataModel>> {
        return this.states.changes( 'CurrentProject' ).pipe(
            filter( projId => !!projId ),
            distinctUntilChanged(),
            switchMap( projId => this.dataStore.find( EDataModel, { rid: projId })),
            distinctUntilChanged( isEqual ),

        );
    }

    /**
     * Create stored eData locator for the given eData.
     * FIXME: stream changes from the indexed db to the stored locator.
     * FIXME: create and provide a eData factory
     */
    private createEDataLocator( eDataId: string ): Observable<EDataLocator<EDataModel, EntityModel>> {
        const slocator = new EDataLocator<EDataModel, EntityModel>();
        const schanges = this.createEDataLocatorChangeStream( eDataId );
        return slocator.start({ changes: schanges, factory: new EDataFactory() }).pipe(
            mapTo( slocator ),
        );
    }

    /**
     * Creates an observable which will emit changes applied to the stored eData model.
     */
    private createEDataLocatorChangeStream( eDataId: string ): Observable<IEDataChange> {
        return concat(
            this.createStoreEDataChangeStream( eDataId ).pipe( map( data => {
                data.source = 'init';
                return data;
            })),
            merge(
                this.createStoreEDataChangeStreamOnInsert( eDataId ),
                defer(() =>  this.createEDataChangesChangeStream( eDataId )),
                defer(() => this.createEDataStaticChangesChangeStream( eDataId )),
            ),
        );
    }

    /**
     * Creates the change stream for eData model changes
     * @param eDataId - eData id
     * @return observable which emits all eData model data as a modifier
     * once and completes.
     */
    private createStoreEDataChangeStream( eDataId: string ): Observable<any> {
        return this.dataStore.findOneRaw( EDataModel, { id: eDataId }).pipe(
            take( 1 ),
            switchMap( data => {
                data = cloneDeep( data );
                const selector = { modelId: eDataId };
                return this.dataSync.findUnsavedChanges( EDataModel, selector ).pipe(
                    take( 1 ),
                    map(( change: IModelChange[]) => {
                        // NOTE: Sorting the changes with the client time so the order of applying
                        // changes will not be incorrect.
                        change.sort(( c1, c2 ) => c1.clientTime - c2.clientTime );
                        for ( const doc of change ) {
                            modify( data, doc.modifier );
                        }
                        const modifier = { $set: data };
                        return { id: this.createUniqueId(), modifier };
                    }),
                );
            }),
        );
    }

    /**
     * Creates the change stream for eData model changes.
     * Watches the change collection for eData inserts.
     * @param eDataId - eData id
     * @return observable which continously emits eData model changes.
     */
    private createStoreEDataChangeStreamOnInsert( eDataId: string ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return modelStore.collection.watch({ id: eDataId }).pipe(
            filter( change => change.type === 'insert' ),
            switchMap( change => this.createStoreEDataChangeStream( eDataId )),
            map( data => {
                data.source = 'insert';
                return data;
            }),
        );
    }

    /**
     * Creates the change stream for eData changes.
     * @param eDataId - eData id
     * @return observable which continously emits eData changes.
     */
    private createEDataChangesChangeStream( eDataId: string ): Observable<any> {
        const appliedChangeIds = [];
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return modelStore.changeCollection.watch({ modelId: eDataId }).pipe(
            filter( change => change.type === 'insert' || change.type === 'remove' ),
            // NOTE: documents in the change collection are model changes
            // NOTE: if a change was emitted as an unsaved change, do not
            //       re-emit when it gets converted to a saved change.
            concatMap( change => {
                const changes = [];
                for ( const doc of change.docs ) {
                    if ( change.type === 'insert' ) {
                        const index = appliedChangeIds.indexOf( doc.id );
                        if ( index === -1 ) {
                            appliedChangeIds.push( doc.id );
                            changes.push({
                                id: this.createUniqueId(),
                                modifier: doc.modifier,
                                ctx: doc.ctx,
                            });
                        }
                        // remove oldest changes from the list to free up memory.
                        if ( appliedChangeIds.length === 10000 ) {
                            appliedChangeIds.splice( 0, 500 );
                        }
                    } else if ( change.type === 'remove' ) {
                        changes.push({
                            id: this.createUniqueId(),
                            modifier: doc.reverter,
                            ctx: doc.ctx,
                        });
                    }
                }
                return from( changes );
            }),
            map( data => {
                data.source = 'change';
                return data;
            }),
        );
    }

    /**
     * Creates the change stream for eData static data changes
     * @param eDataId Creates the change stream for eData static data changes
     * @return observable which continously emits eData static data changes.
     */
    private createEDataStaticChangesChangeStream( eDataId: string ) {
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return modelStore.staticCollection.watch({ id: eDataId }).pipe(
            filter( change => change.type === 'update' || change.type === 'insert' ),
            // NOTE: documents in the static collection are parts of diagram model
            concatMap( change => {
                const changes = change.docs.map( doc => ({
                    id: this.createUniqueId(),
                    modifier: { $set: doc },
                }));
                return from( changes );
            }),
        );
    }


    // Other Helpers - helper functions which are not directly related to the logic
    // ----------------------------------------------------------------------------

    /**
     * Returns a unique number each time this method is called. Can be used as unique ids.
     */
    private createUniqueId(): string {
        return `${++this.counter}`;
    }

}
