import { Observable, of } from 'rxjs';
import { Random, ClassUtils, Logger } from 'flux-core';
import { AbstractModelFactory } from '../../framework/factory/abstract-model-factory';
import { DiagramDataModel } from './diagram-data.mdl';
import { IDiagramDefinitionClass } from 'flux-definition';
import { catchError, map } from 'rxjs/operators';

/**
 * This is the stateless factory for creating all types of Diagram Models. Any model
 * created must be of the type DiagramDataModel or anything that extends that.
 *
 * This creates the models and merges the definitions into the model. This manages the
 * definition creation and conversion to accessible types. This does not manage diagram
 * model data.
 */
export class DiagramModelFactory extends AbstractModelFactory {

    /**
     * Getter instance
     * Returns current model factory instance
     */
    public static get instance(): DiagramModelFactory {
        if ( !this._instance ) {
            this._instance = new DiagramModelFactory();
        }
        return this._instance;
    }

    /**
     * The singleton instance of the DiagramModelFactory.
     */
    protected static _instance: DiagramModelFactory;

    /**
     * Factory function to create a diagram from a diagram type definition identifier. Fetches the
     * definiton and creates the Model with the definition.
     * @param typeDef The diagram type definition Class Identifier
     * @param type The type of the Diagram Model expected which is or extends DiagramDataModel
     * @param id The unique id for the diagram. If not given, will be generated.
     * @param name The name of the diagram. Will be empty if not provided
     */
    public createByDef(
        typeDef: string,
        type: typeof DiagramDataModel = DiagramDataModel,
        id?: string,
        name: string = '',
    ): Observable<DiagramDataModel> {
        id = !id ? Random.diagramId() : id;

        if ( !typeDef ) {
            return of( new type( id, name ));
        }

        try {
            return this.defLocator.getClass( typeDef ).pipe(
                map(( defClass: IDiagramDefinitionClass ) => {
                    const defInstance = new defClass();
                    let model = new type( id, name );

                    // NOTE: lodash marge is not sufficient, we use RamiMerge :P
                    model = ClassUtils.mergeInstances( model, defInstance );
                    return model;
                }),
                catchError( e => {
                    Logger.error( 'DiagramModelFactory: DefClass creation faild', e );
                    return of( new type( id, name ));
                }),
            );
        } catch ( error ) {
            Logger.error( 'DiagramModelFactory: DefClass creation faild', error );
            return of( new type( id, name ));
        }
    }

    /**
     * Factory function to create a diagram from a saved diagram data. Fetches the
     * definiton as per type property in the data, and creates the Model.
     *
     * Important: This method only creates the Diagram Model and does not merge the values from the
     * given data object into the model. It simply uses following fiels from the data.
     *      - type
     *      - id
     *      - name
     *
     * @param data The shape data that was stored. Expected to have defId and version props
     * @param type The type of the ShapeModel expected which is or extends AbstractShapeModel
     */
    public createByData(
        data: any, type: typeof DiagramDataModel = DiagramDataModel,
    ): Observable<DiagramDataModel> {
        return this.createByDef( data.type, type, data.id, data.name );
    }


}
