import { AbstractModel, IUnsavedModelChange } from 'flux-core';
import { Observable } from 'rxjs';

/**
 * SubscriptionStatus are the different states of subscriptions.
 */
export enum SubscriptionStatus {
    created,
    started,
    errored,
    completed,
}

/**
 * IAbstractSubscription is the base interface for all subscription types.
 * This interface contains common methods and properties for all subscriptions.
 */
export interface IAbstractSubscription {
    topic: string;
    subscription: string;
    resourceId: string;
    modelType: typeof AbstractModel;
    updateStatus( status: SubscriptionStatus.errored, error: Error );
    updateStatus( status: SubscriptionStatus.started | SubscriptionStatus.completed );
}

/**
 * IModelSubscription is the interface all model subscriptions should implement.
 * These subscriptions will subscribe to changes to a particular model.
 */
export interface IModelSubscription extends IAbstractSubscription {
    getLatestChangeId(): Observable<string>;
    getUnsavedChanges(): Observable<IUnsavedModelChange[]>;
}

/**
 * IListSubscription is the interface all list subscriptions should implement.
 * These subscriptions will subscribe to additions/removals of models in a list.
 */
export interface IListSubscription extends IAbstractSubscription {
    getAvailableModelIds(): Observable<string[]>;
}

/**
 * Subscription
 */
export type Subscription = IAbstractSubscription | IModelSubscription | IListSubscription;
