import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DiagramInfoModel } from '../model/diagram-info.mdl';
import { DiagramInfoModelStore } from '../../storage/diagram-info-model-store';
import { RemoveResourceGroupShare } from '../../group-share/command/remove-resource-group-share.cmd';

/**
 * This is the command to remove group shares from diagram.
 *
 * This extends {@link RemoveResourceGroupShare} to send a request to the server.
 *
 */
@Injectable()
@Command()
export class RemoveDiagramGroupShare extends RemoveResourceGroupShare  {

    protected getModelStore() {
        return this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
    }
}

Object.defineProperty( RemoveDiagramGroupShare, 'name', {
    value: 'RemoveDiagramGroupShare',
});
