import { IText, DEFUALT_TEXT_STYLES } from 'flux-definition';
import { Rectangle } from 'flux-core';
import { CommonTheme } from '../../shape/common-theme';
import * as Carota from '@creately/carota';
import { TextPostion } from '../../framework/text/text-position';

/**
 * AbstractSVGTextView
 * This class implements common functionality required for text rendering on svg.
 * Each extending class must implement functionality to retrieve svg element for the text.
 */
export class AbstractSVGTextView {

    /**
     * Create a HTML div element which contains the text of the given text model
     * @param text Text model
     */
    protected createHTMLElement( text: IText ): HTMLElement {
        const element = document.createElement( 'div' );
        element.classList.add( 'prose' );
        element.setAttribute( 'xmlns', 'http://www.w3.org/1999/xhtml' );
        element.innerHTML = ( text as any ).html;

        element.style.position = 'absolute';
        element.style.fontFamily = CommonTheme.defaultFontFamily;
        element.style.fontSize = CommonTheme.defaultFontSize;
        element.style.color = CommonTheme.defaultTextColor;

        return element;
    }

    /**
     * Create a div element string to represent given shape/connector text.
     * This function preserve the encoding of the text and therefore
     * special charactors can be rendered on the svg.
     * @param element HTMLDivElement
     * @param tragetText Actual text value of the text model
     */
    protected createTextRenderingElement( element: HTMLElement, tragetText: string ): string {
        element.innerHTML = '';
        // We know for sure this element is a div
        const elementHTMLParts = element.outerHTML.split( '</div>' );
        return elementHTMLParts[0] + tragetText + '</div>';
    }

    protected prepareHTML( element: HTMLElement, tragetText: string ) {
        element.innerHTML = tragetText;

        TextPostion.setListPadding( element );

        element.querySelectorAll( 'data-item-node' ).forEach( el => {
            const data = JSON.parse( el.getAttribute( 'rendereddata' ));
            if ( data ) {
                el.innerHTML = this.renderDataItem( data );
            }
        });
        let str =  element.outerHTML;
        str = str.replace( /<\s*input[^>]*>(.*?)/g, input => input.slice( 0, -1 ) + ' />' );
        str = str.replace( /&nbsp;/g, ' ' );
        str = str.replace( /<\s*br[^>]*>(.*?)/g, input => input.slice( 0, -1 ) + ' />' );
        return str.replace( /<\s*img[^>]*>(.*?)/g, input => input.slice( 0, -1 ) + ' />' );
    }

    protected renderDataItem( data: { value: string, label: string, type: string }) {
        if ( data.label ) {
            return `<div>${data.label}: ${data.value}</div>`;
        }
        return `<div>${data.value}</div>`;
    }

    /**
     * Text returns a set of svg text elements as a string that can render
     * in an svg generated with the text model data
     * @param textModel IText
     */
    protected getTextElements( textModel: IText ): string {
        const doc = Carota.document( DEFUALT_TEXT_STYLES ) as any;
        doc.load( textModel.content );
        doc.width( textModel.width );
        const data = doc.getDrawableContent();
        const textElems = data.words.map(( word, index ) => {
            let fillColor = '';
            if ( word.content.fillColor ) {
                fillColor = `<rect x="${word.left}" y="${word.top}" width="${word.width}"
                    height="${word.height}" fill="${word.content.fillColor}" />`;
            }
            const text = `<text text-decoration="${
                    // Only underlin or strike can do at a time, priority is for strike.
                    word.content.strikeout ? 'line-through' :
                    word.content.underline ? 'underline' : 'none'
                }" ` +
                `x="${word.left}" ` +
                `fill="${word.content.color}" ` +
                `${this.getFontString( word.content )} ` +
                `y="${word.baseline}">${ word.content.text }</text>`;
            if ( word.content.link ) {
                word.content.link = this.escapeURL( word.content.link );
            }

            return word.content.link ? `<a target="_bkank" href="${word.content.link}" >${text}</a>`
                : fillColor + text;
            });
        if ( data.words[0].content.backgroundColor ) {
            const width = doc.frame.actualWidth() + ( 2.5 * 2 );
            const height = doc.frame.actualHeight() + 2.5;
            const backgroundColor = `<rect x="-2.5" y="0" width="${width}" height="${
                height}" fill="${data.words[0].content.backgroundColor}" rx="5"/>`;
            textElems.unshift( backgroundColor );
        }
        return textElems.join( '' );
    }

    /**
     * Calculate text bounds for given element.
     * FIXME: This is temporary functionality, this will be removed once the text model
     * start storing bounds itself.
     * @param textElement HTMLElement
     */
    protected calculateTextBounds( textElement: HTMLElement ): Rectangle {
        textElement.style.visibility = 'hidden';
        textElement.setAttribute( 'class' , 'fx-text-reset' );
        const style: HTMLElement  = document.createElement( 'style' );
        style.innerHTML = `
            .fx-text-reset * {
                font-family: initial;
                font-size: initial;
                color: initial;
                font : initial;
                font-style : initial;
                font-variant : initial;
                font-weight : initial;
                letter-spacing : initial;
                line-height : initial;
                text-align : initial;
                text-align-last : initial;
                text-decoration : initial;
                text-decoration-line : initial;
                text-decoration-style : initial;
                text-indent : initial;
                text-shadow : initial;
                text-transform : initial;
                vertical-align : initial;
                white-space : initial;
                word-spacing : initial;
                -webkit-font-smoothing: initial;
            }

            .fx-text-reset i, .fx-text-reset em {
                font-style: italic;
            }

            .fx-text-reset sup {
                vertical-align: super;
                font-size: smaller;
            }

            .fx-text-reset sub {
                vertical-align: sub;
                font-size: smaller;
            }

            .fx-text-reset b {
                font-weight: bold;
            }

            .fx-text-reset s, .fx-text-reset strike, .fx-text-reset del {
                text-decoration: line-through;
            }`;
        document.head.appendChild( style );
        document.body.appendChild( textElement );

        const bounds = new Rectangle( 0, 0, textElement.clientWidth, textElement.clientHeight );

        textElement.parentNode.removeChild( textElement );
        document.head.removeChild( style );
        textElement.removeAttribute( 'class' );
        textElement.style.visibility = 'visible';

        return bounds;
    }

    /**
     * This function convert special characters
     * in a url to ASCII values.
     * @param url - url which needs to be escaped.
     * @returns escaped URL.
     */
    private escapeURL( url: string ): string {
        return url.replace( /[\u00A0-\u9999<>\&]/gim, i =>
            '&#' + i.charCodeAt( 0 ) + ';' );
    }

    private getFontString( run ) {
        let size = ( run && run.size );
        if ( run ) {
            switch ( run.script ) {
                case 'super':
                case 'sub':
                    size *= 0.8;
                    break;
            }
        }
        return ( run && run.italic ? 'font-style="italic" ' : '' ) +
               ( run && run.bold ? 'font-weight="bold" ' : '' ) +
                `font-size="${size}pt" ` +
              ( run && run.font ? `font-family="${run.font}" ` : '' ) ;
    }
}
