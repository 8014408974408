import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ChangeArrowHeads
 * Changes arrow heads of the connector.
 */
@Injectable()
@Command()
export class ChangeArrowHeads extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The from/to fields are full class identifiers as used in shape definitions.
     */
    public data: {
        changes: {
            [ shapeId: string ]: { from?: string, to?: string },
        },
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        for ( const shapeId in this.data.changes ) {
            const shapeData = this.data.changes[shapeId];
            const shapeModel = this.changeModel.shapes[shapeId] as ConnectorModel;
            if ( 'from' in shapeData ) {
                shapeModel.ends.from = shapeData.from;
            }
            if ( 'to' in shapeData ) {
                shapeModel.ends.to = shapeData.to;
            }
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ChangeArrowHeads, 'name', {
    value: 'ChangeArrowHeads',
});
