import { ConnectorDataModel } from '../model/connector-data-mdl';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, take, mapTo } from 'rxjs/operators';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { ConnectorRenderView } from './connector-render-view';
import { AbstractShapeViewFactory } from './abstract-shape-view-factory';
import { union, get } from 'lodash';

/**
 * This is the stateless factory for creating Connector Views.
 * Created view must be of the type of ConnectorRenderView.
 *
 * This creates the view and merges the definition entry class into the view and load the
 * source files for all supported arrow heads.
 */
export class ConnectorViewFactory extends AbstractShapeViewFactory {

    /**
     * The singleton instance of the ShapeViewFactory.
     */
    public static get instance(): ConnectorViewFactory {
        if ( !this._instance ) {
            this._instance = new ConnectorViewFactory();
        }
        return this._instance;
    }

    protected static _instance: ConnectorViewFactory;

    /**
     * A factory method which will create the connector view from the model.
     *
     * @param model The shape model that is an extension of ShapeDataModel
     * @param type The type of the view class which extends ShapeRenderView
     */
    public create( model: ConnectorDataModel, type: typeof ConnectorRenderView ): Observable<AbstractShapeView> {
        return super.create( model, type ).pipe(
            switchMap( view => this.loadAllArrowHeads( model.ends ).pipe( mapTo( view ))),
        );
    }

    /**
     * Loads all the arrow heads defined in the given end definiton and returns an observable
     * that emits a array of all of them
     * @param ends The connector's definition of both ends.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    private loadAllArrowHeads( ends: typeof ConnectorDataModel.prototype.ends ): Observable<any> {
        if ( ends && ( !!ends.allOptions || !!ends.fromOptions || !!ends.toOptions || !!ends.from || !!ends.to )) {
            const arrowHeadIds = union(
                ends.from ? [ ends.from ] : [],
                ends.to ? [ ends.to ] : [],
                get( ends, 'allOptions.options', []),
                get( ends, 'fromOptions.options', []),
                get( ends, 'toOptions.options', []),
            );
            const observables = arrowHeadIds.map( id => this.defLocator.getClass( id ));
            if ( observables && observables.length > 0 ) {
                return forkJoin( ...observables ).pipe( take( 1 ));
            }
        }

        return of( undefined );
    }

}

