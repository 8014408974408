import { IDefinitionLocator } from '../../shape/definition/definition-locator.i';

/**
 * This is the abstract form of the model factory, each model factory class
 * must extend this. All common functionality related to a model factory must be implemented here.
 */
export abstract class AbstractModelFactory {

    /**
     * The definition locator instance which will be used to
     * get/load definitions for shapes.
     */
    protected defLocator: IDefinitionLocator;

    /**
     * Registeres the given Definition Location once in the system's
     * lifetime. Must be done for the all ModelFactory types to function gracefully.
     * @param locator An IDefinitionLocator type
     */
    public registerDefinitionLocator( locator: IDefinitionLocator ) {
        if ( this.defLocator ) {
            throw new Error( 'A valid Definition Locator was already regisrered.' );
        }
        if ( !locator ) {
            throw new Error( 'Provide a valid Definition Locator.' );
        }

        this.defLocator = locator;
    }

}
