import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { Observable, empty } from 'rxjs';
import { AbstractDeleteDiagram } from './abstract-delete-diagram.cmd';
import { Router } from '@angular/router';
import { StateService } from 'flux-core';
import { switchMap } from 'rxjs/operators';

@Injectable()
@Command()
export class DeleteDiagram extends AbstractDeleteDiagram {

    constructor(
            protected state: StateService<any, any>,
            protected dataStore: DataStore,
            protected modelSubManager: ModelSubscriptionManager,
            protected router: Router ) {
        super( dataStore, modelSubManager )/* istanbul ignore next */;
    }

    /**
     * Delete the diagram from cache and stops diagram subscription.
     */
    public executeResult( response: any ): Observable<any> {
        // NOTE: Document Manage service handles the state update and
        // moving to the next available diagram.
        return super.executeResult( response ).pipe(
            switchMap( result => {
                if ( this.state.get( 'CurrentDiagram' ) === this.resourceId ) {
                    this.state.set( 'SelectedLeftPanel', 'dashboard' );
                    return this.router.navigateByUrl( '/start/dashboard' );
                } else {
                    return empty();
                }
            }),
        );
    }
}

Object.defineProperty( DeleteDiagram, 'name', {
    value: 'DeleteDiagram',
});
