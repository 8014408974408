import { IRtreeNode } from 'flux-core';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { isEqual } from 'lodash';
import { RTree2D, ModifierUtils } from 'flux-core';
import { tap, switchMap, debounceTime, map, distinctUntilChanged, filter, mapTo } from 'rxjs/operators';
import { StateService, Rectangle } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { Observable, merge } from 'rxjs';
import { lodashThrottle } from '@creately/rx-lodash';
import { InterfaceControlState } from '../../../base/base-states';

interface IChangeIds {
    addedOrUpdated: string[];
    removed: string[];
}

/**
 * ShapeBoundsLocator is responsible for managing shape bounds in spatial data structure
 * as the diagram and viewport changes
 * @author thisun
 * @since 2020-09-10
 */
@Injectable()
export class ShapeBoundsLocator {

    public static get instance(): ShapeBoundsLocator {
        return ShapeBoundsLocator._instance;
    }

    /**
     * Holds the singleton instance of ShapeBoundsLocator
     */
     private static _instance = null;

     protected MIN_Z_INDEX = -100000;

    /**
     * Throttle time to update the alignment nodes on pan/zoom changes
     */
    protected debounce = 100;


    /**
     * This tree holds all the shapes bounds
     * in the current diagram
     */
     protected shapesTree: RTree2D;

    /**
     * This object holds child-parent relationships
     */
     protected containerData: { containers: string[], children: { id: string, containerId: string }[]};

    /**
     * Holds the zIndexs of shapes in a spatial structure.
     * shapes are placed in the x axis by it's zIndex and
     * The neighbours from -Infinity in the axis will provide the
     * items sorted by zIndex
     */
     protected shapesZIndexTree: RTree2D;

    constructor(
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
    ) {
        if ( ShapeBoundsLocator._instance ) {
            return ShapeBoundsLocator._instance;
        } else {
            this.shapesTree = new RTree2D();
            this.shapesZIndexTree = new RTree2D();
            ShapeBoundsLocator._instance = this;
        }
    }

    public initialize() {
        return merge(

            // Updates the objects in the shapesTree for stored changes
            this.getLocatorObservable().pipe(
                switchMap( l => merge(
                    l.getDiagramOnce().pipe( tap( d =>  {
                        // Load the tree initially
                        const [ shapeNodes, containerData ] = this.getTreeNodes( Object.values( d.shapes ));
                        this.containerData = containerData;
                        this.shapesTree.clear();
                        this.shapesTree.load( shapeNodes );
                    })),
                    l.getDiagramChanges().pipe(
                        map( c =>  {
                            // FIXME: undefined shape id after undo plus create drag connect
                            // also, shapesTree in undefined here
                            if ( !this.shapesTree ) {
                                    return;
                                }
                            if ( c.source !== 'init' && c.split && c.split.shapes ) {
                                const addedUpdatedIds =  Object.keys( c.split.shapes )
                                    .filter( s => s && s !== 'undefined' )
                                    .filter( key => {
                                        const m = c.split.shapes[ key ];
                                        return ModifierUtils.hasChanges( m, 'x' ) || // Shape added/updated
                                            ModifierUtils.hasChanges( m, 'y' ) ||
                                            ModifierUtils.hasChanges( m, 'scaleX' ) ||
                                            ModifierUtils.hasChanges( m, 'scaleY' ) ||
                                            ModifierUtils.hasChanges( m, 'defaultBounds' ) ||
                                            ModifierUtils.hasChanges( m, 'angle' ) ||
                                            ModifierUtils.hasChanges( m, 'path' ) ||
                                            m.$set?.visible === true ||
                                            ModifierUtils.hasChanges( m, 'texts' );
                                    });
                                const removedIds = Object.keys( c.split.shapes )
                                    .filter( s => s && s !== 'undefined' )
                                    .filter( key => ( c.split.shapes[ key ].$unset as any ) === true ||
                                        ( c.split.shapes[ key ]?.$set as any )?.visible === false );
                                // TODO: proof of concept, manage containerData based on set\unset values instead.
                                const [ , containerData ] = this.getTreeNodes( Object.values( c.model.shapes ));
                                this.containerData = containerData;
                                this.manageTree(
                                    this.shapesTree, c.model.shapes, {
                                        addedOrUpdated: addedUpdatedIds,
                                        removed: removedIds,
                                    }, 'getTreeNodes' );
                            }
                        }),
                    ),
                )),
            ),

            // Updates the objects in the shapesTree for stored changes
            this.getLocatorObservable().pipe(
                switchMap( l => merge(
                    l.getDiagramOnce().pipe( tap( d =>  {
                        // Load the tree initially
                        const [ nodes ] = this.getZindexTreeNodes( Object.values( d.shapes ));
                        this.shapesZIndexTree.clear();
                        this.shapesZIndexTree.load( nodes );
                    })),
                    l.getDiagramChanges().pipe(
                        map( c =>  {
                            if ( c.source !== 'init' && c.split && c.split.shapes ) {
                                const addedOrUpdatedIds =  Object.keys( c.split.shapes )
                                    // FIXME: undefined shape id after undo plus create drag connect
                                    .filter( key => key !== 'undefined' )
                                    .filter( key => ModifierUtils.hasChanges( c.split.shapes[ key ], 'zIndex' ));
                                const removedIds = Object.keys( c.split.shapes )
                                    .filter( key => key !== 'undefined' )
                                    .filter( key => c.split.shapes[ key ].$unset as any === true );
                                this.manageTree(
                                    this.shapesZIndexTree, c.model.shapes, {
                                        addedOrUpdated: addedOrUpdatedIds,
                                        removed: removedIds,
                                    }, 'getZindexTreeNodes' );
                            }
                        }),
                    ),
                )),
            ),

            // Update ActiveShapes state
            this.activateShapeViews( 200 ),
        );
    }

    public manageTree( tree: RTree2D, shapes, changedOrRemovedIds: IChangeIds, method ) {
        const { addedOrUpdated, removed } = changedOrRemovedIds;
        removed.forEach( key => tree.removeById( key ));
        addedOrUpdated.forEach( key => {
            if ( !tree.has( key )) { // New shape added
                const [ nodeToAdd ] = this[ method ]([ shapes[ key ] ])[ 0 ];
                tree.insert( nodeToAdd );
            } else {  // Shape bounds updated
                const [ newNode ] = this[ method ]([ shapes[ key ] ])[ 0 ];
                tree.update( newNode );
            }
        });
    }

    public getShapesOrderedByIndex() {
        // Should pass Number.MIN_SAFE_INTEGER or -Infinity but those 2 values
        // are not working
        return this.shapesZIndexTree.getNeighbors( this.MIN_Z_INDEX, 0 );
    }

    public getDiagramBounds() {
        return this.shapesTree.getBounds();
    }

    /**
     * Serach shapes and connectors in the given region
     * The region should be given in the diagram coordinate space
     *
     * e.g.
     * if minX === maxX and minY === infinity and minY === infinity, this method searches for
     * all the nodes on minX/maxX line
     * @param minX, maxX, minY, maxY to specify the area to search
     * @param exclude shape ids to exclude
     * @param precision
     */
     public searchShapes(
        minX: number, maxX: number, minY: number, maxY: number,
        exclude: string[] = [], fullyContained = false, precision = 0.5 ): IShapeLocation[] {
            return this._search( this.shapesTree, minX, maxX, minY, maxY, exclude, fullyContained, precision );
    }

    public getContainerData() {
        return this.containerData;
    }

    protected _search(
        tree: RTree2D,
        minX: number, maxX: number, minY: number, maxY: number,
        exclude: string[] = [], fullyContained = false, precision = 0.5 ): IShapeLocation[] {
            try {
                const bounds = new Rectangle( minX, minY, maxX - minX, maxY - minY );
                return tree
                    .search( minX - precision, maxX + precision, minY -  precision , maxY + precision )
                    .filter( node => {
                        const filtered = !exclude.includes( node.id );
                        if ( fullyContained ) {
                            const enclosed = bounds.contains( new Rectangle( node.x, node.y, node.width, node.height ));
                            return filtered && enclosed;
                        }
                        return filtered;
                    });
            } catch ( e ) {
                return [];
            }
    }

    protected getTreeNodes( shapes: any[]): [ IRtreeNode[], any ] {
        const { nodes, containerData } = shapes.reduce(( res, s ) => {
            // FIXME: undefined shape id after undo plus create drag connect
            if ( !s ) {
                return res;
            }
            const b = s.getBoundsWithTexts();
            res.nodes.push({
                id: s.id, zIndex: s.zIndex, x: b.x, y: b.y, width: b.width, height: b.height, type: s.type,
            });
            if ( !s.children ) {
                return res;
            }
            const children: any[] = Object.keys( s.children );
            if ( children.length ) {
                res.containerData.containers.push( s.id ),
                res.containerData.children.push( ...children.map( id => ({
                    id,
                    containerId: s.id,
                })));
            }
            return res;
        }, { nodes: [], containerData: { containers: [], children: []}});

        return [ nodes, containerData ];
    }

    protected getZindexTreeNodes( shapes: any[]): [ IRtreeNode[] ] {
        // Place zIndexes in the x axis e.g
        // <-----------(-10)---(-2)---(0)----(1)----(2)--------->
        return [ shapes.map( s => ({ id: s.id, zIndex: s.zIndex, x: s.zIndex, y: 0, width: 0, height: 0, type: '' })) ];
    }

    /**
     * Emits pan & zoom changes
     */
    protected getPanZoomChanges( debounce: number ): Observable<any> {
        return merge(
            this.state.changes( 'DiagramPan' ),
            this.state.changes( 'DiagramZoomLevel' ),
        ).pipe(
            debounceTime( debounce ),
        );
    }

    protected activateShapeViews( throttle: number ) {
        return this.emitShapesInTheViewport( throttle ).pipe(
            distinctUntilChanged( isEqual ),
            tap(( ids: Set<string> ) => {
                const presentationStatus = this.state.get( 'PresentationStatus' );
                if ( !presentationStatus || !presentationStatus.presentationId ) {
                    this.state.set( 'ActiveShapes', Array.from( ids ));
                }
            }),
        );
    }

    protected emitShapesInTheViewport( throttle ) {
        return merge(
            this.getPanZoomChanges( 0 ).pipe(
                lodashThrottle( throttle, { leading: true, trailing: true }),
                mapTo( null ),
            ),
            this.state.changes( 'InterfaceControlState' ).pipe(
                switchMap( icState => icState === InterfaceControlState.Edit ?
                    this.ll.forCurrentObserver( false ).pipe( switchMap( l => merge(
                        l.getDiagramChanges().pipe( mapTo( null )),
                        l.getNewlyAddedShapes().pipe( map( shapes => shapes.map( s => s.id ))),
                        l.getRemovedShapes().pipe( mapTo( null )),
                    )),
                    ) : this.ll.forCurrentObserver( true ).pipe( switchMap( l => merge(
                        l.getDiagramChanges().pipe( mapTo( null )),
                        l.getAddedShapes().pipe( map( shapes => shapes.map( s => s.id ))),
                    )),
                )),
            ),
            this.state.changes( 'EditingText' ).pipe(
                filter(() => this.state.get( 'DiagramZoomLevel' ) !== undefined ),
                mapTo( null ),
            ),
        ).pipe(
            map( addedIds => {
                addedIds =  addedIds || [];
                // NOTE: set the window bounds as viewport since
                // opening/closing left side bar is causing invalid viewport values.
                const vp = Rectangle.from({ x: 0, y: 0, width: window.innerWidth, height: window.innerHeight });
                const dvp = this.vToDcoordinate.bounds( vp.pad( 200 ));
                const minX = dvp.left;
                const maxX = dvp.right;
                const minY = dvp.top;
                const maxY = dvp.bottom;
                const shapes = this.searchShapes( minX, maxX, minY, maxY );
                const set = new Set( shapes.map( s => s.id ).concat( addedIds ));
                const { open, shapeId } = this.state.get( 'EditingText' );
                if ( open && shapeId ) {
                    set.add( shapeId );
                }
                return set;
            }),
            filter( v => !!v ),
        );
    }

    private getLocatorObservable() {
        return this.state.changes( 'InterfaceControlState' ).pipe(
            switchMap( icState => icState === InterfaceControlState.Edit ?
                this.ll.forCurrentObserver( false )
                : this.ll.forCurrentObserver( true )),
        );
    }

}

export interface IShapeLocation {
    type: string;
    id: string;
    zIndex: number;
    x: number;
    y: number;
    width: number;
    height: number;
}
export interface IAlignmentNode {
    id: string;
    shapeId: string;
    x: number;
    y: number;
    pos: 'top' | 'bottom' | 'left' | 'right' | 'center';
    type: 'shape' | 'connector' | 'selection';
}
