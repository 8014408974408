import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeManageService } from '../../feature/shape-manage.svc';

/**
 * Add shape by its definition id at contextMenuEvent position
 */
@Injectable()
@Command()
export class AddShapeByDefId extends AbstractDiagramChangeCommand {

    public data: {
        defId: string,
        version: string,
        contextMenuEvent: any,
    };
    constructor(
        protected ds: DiagramChangeService,
        protected state: StateService<any, any>,
        protected shapeManage: ShapeManageService,
    ) {
        super( ds );
    }
    public prepareData(): void {
        const def: any = {
            defId: this.data.defId,
            version: this.data.version,
        };
        this.shapeManage
            .addShape( def, this.data.contextMenuEvent.offsetX , this.data.contextMenuEvent.offsetY ).subscribe();
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AddShapeByDefId, 'name', {
    value: 'AddShapeByDefId',
});
