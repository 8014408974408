import { ViewChild, Input } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CommandService, ModalController, PopupWindow, CommandEvent, Tracker } from 'flux-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * File Exorted Window. This will handle the popup,
 * And show the export benifits of paid plan.
 *
 * @author  Banujan
 * @since   2021-11-08
 */

@Component({
    templateUrl: 'file-exported-window.cmp.html',
    selector: 'file-exported-window',
    styleUrls: [ './file-exported-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExportedWindow extends PopupWindow implements OnDestroy, OnInit {

    /**
     * Data used to display information on the window.
     */
    @Input() public openUpgradePage: Function;
    @Input() public exportCommandEvent: CommandEvent;

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The file exported window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected modalController: ModalController,
        protected commandService: CommandService,
        public translate: TranslateService,
    ) {
        super();
        this.subs = [];
    }

    public ngOnInit(): void {
        // Adding tracker when loading the window
        Tracker.track( 'conversion.dialog.export.load' );

        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
        );
    }

    public retryDownload(): void {
        this.commandService.dispatch( this.exportCommandEvent );
    }

    public showPlansPage(): void {
        // Adding tracker
        Tracker.track( 'conversion.dialog.export.click', { value1Type: 'button', value1: 'view plans' });

        this.closeWindow();
        this.openUpgradePage();
    }

    public closeWindow() {
        // Adding tracker
        Tracker.track( 'conversion.dialog.export.click', { value1Type: 'button', value1: 'No, okay with low resolution images' });

        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
}
