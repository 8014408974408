/**
 * Tooltip Tour Service.
 * @author kulathilake
 * @since June 2022,
 */

import { ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import {
    CommandService,
    ModalController,
    StateService,
    Tracker,
} from 'flux-core';
import { ToolTipPlacement } from 'flux-core/src/ui';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    IToolTipAnchorElement,
    ITooltipTourServiceI,
    ITooltipTourStep,
    TooltipPrerequisites,
} from './tooltip-tour.interface';
import { tooltipData } from './data/tooltip-data';
import { delay, filter, mergeAll, switchMap, takeWhile, tap } from 'rxjs/operators';
import { PlanPermManager, UserCommandEvent, UserLocator, UserModel } from 'flux-user';
import { TranslateService } from '@ngx-translate/core';
import { TaskLocator } from '../../task/task-locator.svc';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { PlanPermission } from 'flux-definition/src';

@Injectable()
export class TooltipTourService
    implements ITooltipTourServiceI, OnInit, OnDestroy {
    public currentStep: BehaviorSubject<ITooltipTourStep | null>;
    /**
     * hide the current step while maintiaing the internal step value
     */
    public currentStepVisible: BehaviorSubject<boolean>;
    public currentTourSteps: BehaviorSubject<ITooltipTourStep[]>; // TODO: Move to Component
    public tooltipStatus: { [id: string]: boolean };
    public tooltipAnchorPos: { x: number; y: number };
    public isTooltipTourVisible: BehaviorSubject<boolean>;
    /**
     * a pre-configured tooltip steps array.
     */
    public tooltipData: BehaviorSubject<{ [id: string]: ITooltipTourStep }>;
    /**
     * A map of tour step ids and the element references for anchoring
     * Emits when an element is registered or its bounds are changed.
     */
    public stepAnchorElementMap: BehaviorSubject<
        Map<string, IToolTipAnchorElement>
    >;

    /**
     * determines which tooltips need to be highlighted with a ripple
     */
    public currentTooltipLevel: BehaviorSubject<0 | 1 | 2 | 3>;

    /**
     * duration in miliseconds to delay ripples from showing
     */
    public tooltipRippleDelay: number;

    /**
     * is a non zero count of tasks available.
     */
    public taskCount: BehaviorSubject<number | null>;

    /**
     * a flag that'll prevent the root tooltip tour
     * from showing if they close it without completing
     * at least two steps.
     */
    public userClosedRootTour: BehaviorSubject<boolean>;

    private subs: Subscription[];

    /**
     * constructor
     */
    constructor(
        protected state: StateService<any, any>,
        protected modal: ModalController,
        protected commandSvc: CommandService,
        protected userLocator: UserLocator,
        protected translate: TranslateService,
        protected taskLocator: TaskLocator,
        protected diagramLocator: DiagramLocatorLocator,
        protected planPermManager: PlanPermManager,
    ) {
        this.stepAnchorElementMap = new BehaviorSubject<
            Map<string, IToolTipAnchorElement>
        >( new Map());
        this.currentTooltipLevel = new BehaviorSubject( 0 );
        this.tooltipRippleDelay = 1000;
        this.tooltipData = new BehaviorSubject( tooltipData );
        this.currentStep = new BehaviorSubject( null );
        this.currentStepVisible = new BehaviorSubject( false );
        this.state.initialize( 'TooltipAutomateState', 'noAutomation' );
        this.currentTourSteps = new BehaviorSubject([]);
        this.tooltipStatus = {};
        this.taskCount = new BehaviorSubject( null );
        this.userClosedRootTour = new BehaviorSubject( false );
        this.isTooltipTourVisible = new BehaviorSubject( false );
        this.subs = [];
        this.subs.push(
            this.setUserTooltipTourStatus().subscribe(),
            this.listenToTaskCountChange().subscribe(),
        );
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    registerElement( elem: ElementRef<HTMLElement>, stepId: string ) {

        if (
            this.tooltipData.value[stepId]?.registerFirstAnchorOnly &&
            this.stepAnchorElementMap.value.has( stepId )
        ) {
            return;
        }
        this.stepAnchorElementMap.value.set( stepId, {
            stepId,
            element: elem.nativeElement,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            placement: ToolTipPlacement.left,
        });
        this.stepAnchorElementMap.next( this.stepAnchorElementMap.value );
    }

    unregisterElement( stepId: string ) {
        this.stepAnchorElementMap.value.delete( stepId );
        this.stepAnchorElementMap.next( this.stepAnchorElementMap.value );
    }

    updateElement( elem: ElementRef<HTMLElement>, stepId: string ) {
        const tooltipAnchorElem = this.stepAnchorElementMap.value.get( stepId );
        const rect = elem.nativeElement.getBoundingClientRect();
        if ( tooltipAnchorElem ) {
            tooltipAnchorElem.left = rect.left;
            tooltipAnchorElem.top = rect.top;
            ( tooltipAnchorElem.right = rect.right ),
                ( tooltipAnchorElem.bottom = rect.bottom );
        } else {

            this.registerElement( elem, stepId );
        }
        this.stepAnchorElementMap.next( this.stepAnchorElementMap.value );
    }
    showTooltip( id: string, beginTourFromHere?: boolean ) {
        this.modal.hide();
        this.isTooltipTourVisible.next( true );

        const tooltip = this.tooltipData.value[id];

        if ( this.areRequiredFeaturesAvailable( tooltip )) {
            if ( tooltip.stateChanges && tooltip.stateChanges.length ) {
                this.changeStates( tooltip.stateChanges );
            }
            if ( tooltip.modal ) {
                this.modal.show( tooltip.modal.type ).subscribe();
            }
            if (
                ( tooltip.level === this.currentTooltipLevel.value &&
                    tooltip.level !== 0 ) ||
                beginTourFromHere
            ) {
                this.currentTourSteps.next([
                    tooltip,
                    ...this.findChildTooltips( id ),
                ]);
            }

            this.stepAnchorElementMap
                .pipe(
                    takeWhile(
                        () => !this.hasValidAnchor( tooltip ),
                        true,
                        ),
                    filter(() => this.hasValidAnchor( tooltip )),
                    delay( tooltip.delay || 0 ),
                    tap(() => {
                        tooltip.anchor = this.getAnchor( tooltip );
                        if ( tooltip.anchor ) {
                            if ( !tooltip.canvasElement ) {
                                this.updateElement(
                                    new ElementRef( tooltip.anchor.element ),
                                    id,
                                );
                            }
                            this.currentStep.next( tooltip );
                            this.currentStepVisible.next( true );
                        }
                    }),
                )
                .subscribe();
            if ( tooltip.level !== 0 ) {
                    this.sendTrackingEventsOnShow( id );
                }
        } else if (
            tooltip.skipIfPrerequisiteNoMet &&
            ( tooltip.nextStepId || tooltip.learnMoreStep )
        ) {
            this.showTooltip( tooltip.learnMoreStep || tooltip.nextStepId );
        } else {
            return;
        }
    }
    setUserTooltipTourStatus() {
        return this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            tap( userInfo => {
                if ( userInfo ) {
                    Object.keys( userInfo.seenTooltips ).forEach( id => {
                        this.tooltipStatus[id] = userInfo.seenTooltips[id][0] || false;
                    });
                }
            }),
        );
    }
    markStepAsViewed( stepId: string ) {
        this.tooltipData.value[stepId].viewed = true;
        this.tooltipData.next( this.tooltipData.value );
        this.tooltipStatus[stepId] = true;
        this.persistTooltipTourStatus( stepId );
    }
    persistTooltipTourStatus( stepId: string, local?: boolean ) {
        if ( local ) {
            localStorage.setItem(
                `tltp-tour`,
                JSON.stringify( this.tooltipStatus ),
            );
        } else {
            this.commandSvc.dispatch( UserCommandEvent.updateUserSeenTooltips, {
                newTooltipId: stepId,
            });
        }
    }
    findChildTooltips( step: string ): ITooltipTourStep[] {
        const _step = this.tooltipData.value[step];
        let children: ITooltipTourStep[] = [];
        if ( _step.nextStepId || _step.learnMoreStep ) {

            const nextStep = this.tooltipData.value[ _step.nextStepId || _step.learnMoreStep ];
            if ( this.areRequiredFeaturesAvailable( nextStep )) {
                children.push(
                    this.tooltipData.value[_step.nextStepId || _step.learnMoreStep],
                    );
            }
            children = children.concat(
                ...this.findChildTooltips(
                    _step.nextStepId || _step.learnMoreStep,
                ),
            );
        } else {
            return [];
        }
        return children;
    }

    resetTooltipTour() {
        this.commandSvc.dispatch( UserCommandEvent.updateUserSeenTooltips, {
            newTooltipId: 'reset_all',
        });
        this.tooltipStatus = {};
        this.tooltipData.next( tooltipData );
    }

    /**
     * checks if the level 0 tooltip is shown,
     * if not display it first.
     * else wait a predefined time and display the ripples.
     */
     public initialiseTooltipTour( showTemplateModal?: boolean, skipRoot?: boolean ) {
        let rootTrackingSent = false;
        return this.tooltipData.pipe(
            switchMap(() => this.stepAnchorElementMap ),
            switchMap(() => this.userClosedRootTour ),
            switchMap(() => this.state.changes( 'CurrentDiagram' )),
            filter( val => Boolean( val ) && val !== 'start' ),
            mergeAll(),
            tap(() => {
                const isRootSeen = this.tooltipStatus.root || skipRoot;
                if ( isRootSeen || this.userClosedRootTour.value ) {
                    this.currentTooltipLevel.next( 1 );
                } else {
                    if ( this.currentStep.value?.stepId !== 'root' && !this.userClosedRootTour.value ) {
                        this.showTooltip( 'root', true );
                        if ( !rootTrackingSent ) {
                            this.sendTrackingEventsOnShow( 'root' );
                            rootTrackingSent = true;
                        }
                    }
                }
                if ( showTemplateModal && this.userClosedRootTour.value ) {
                    showTemplateModal = false;
                    this.state.set( 'ToggleTemplatesModal', true );
                }
            }),
        ).subscribe();
    }

    /**
     * Shows viz upgrade dialog tooltip tour
     */
     public showVizTooltipTour() {
        this.showTooltip( 'creately-viz', true );
        Tracker.track( 'load.canvas.AIFeature.load' );
    }

    /**
     * checks if a tooltip has the required features available
     * @param tooltip
     */
    public areRequiredFeaturesAvailable( tooltip: ITooltipTourStep, checkRipplePreReqs?: boolean ): boolean {
        let checklist = [];
        if ( tooltip.prerequisites?.length || ( checkRipplePreReqs && tooltip.ripplePrequisites?.length )) {
            if ( tooltip.prerequisites?.length ) {
                checklist = checklist.concat( tooltip.prerequisites );
            }
            if ( checkRipplePreReqs && tooltip.ripplePrequisites?.length ) {
                checklist = checklist.concat( tooltip.ripplePrequisites );
            }
            return checklist.every( feat => {
                switch ( feat ) {
                    case TooltipPrerequisites.EDATA:
                        return this.isEDataAvailable();
                    case TooltipPrerequisites.TASKS:
                        return this.isTasksAvailable();
                    case TooltipPrerequisites.SHAPE_SELECTED:
                        return this.isShapeSelected();
                    case TooltipPrerequisites.TOOLTIP_VIEWED:
                        return this.areTooltipsSeen( tooltip.preReqTooltips );
                    case TooltipPrerequisites.FAB_IS_QTB:
                        return this.isPanelOpen( 'shapes' );
                    case TooltipPrerequisites.LSB_IS_NAV:
                        return this.isLeftSidePanelOpen( 'nav' );
                    case TooltipPrerequisites.LSB_IS_TASKS:
                        return this.isLeftSidePanelOpen( 'task' );
                    case TooltipPrerequisites.LSB_IS_DATA:
                        return this.isLeftSidePanelOpen( 'edata' );
                    case TooltipPrerequisites.LSB_IS_NONE:
                        return this.isLeftSidePanelOpen( 'none' );

                }
            });
        } else {
            return true;
        }
    }

    /**
     * checks if a given tooltip has a valid Anchor.
     * @param step
     * @returns
     */
    public hasValidAnchor( step: ITooltipTourStep ): boolean {
        return !!this.getAnchor( step );
    }

    /**
     * Returns the valid anchor to position the tooltip or the ripple on.
     * The search will happen recursively for tooltips with a useAnchorOfValue.
     * @param step
     * @returns
     */
    public getAnchor( step: ITooltipTourStep ): IToolTipAnchorElement {
        const hasOwnAnchor = this.stepAnchorElementMap.value.has( step.stepId );

        if ( !hasOwnAnchor ) {
            if ( !!step.useAnchorOf ) {
                return this.getAnchor( this.tooltipData.value[ step.useAnchorOf ]);
            } else {
                return null;
            }

        } else if ( hasOwnAnchor && step.useAnchorOf ) {
            const anchor = this.stepAnchorElementMap.value.get( step.stepId );
            const anchorBB = anchor.element.getBoundingClientRect();
            const isAnchorVisible = anchorBB.bottom + 50 < window.innerHeight;
            if ( isAnchorVisible ) {
                return anchor;
            } else {
                return this.getAnchor( this.tooltipData.value[ step.useAnchorOf ]);
            }
        } else {
            return this.stepAnchorElementMap.value.get( step.stepId );
        }
    }

    public isUserTourTipCompleted( userInfo: UserModel, tourTipIds: string[]): boolean {
        let result: boolean = true;
        if ( !userInfo ) {
            return false;
        }
        if ( tourTipIds.length > 0 ) {
            for ( const tourId of tourTipIds ) {
                if (  !userInfo.seenTooltips[tourId] || ( userInfo.seenTooltips[tourId][0] !== true )) {
                    result = false;
                    break;
                }
            }
        }
        return result;
    }

    /**
     * makes state changes prior to chaging
     * current tooltip step
     * @param states
     */
    private changeStates( states: { [key: string]: string }[]) {
        states.forEach( state => {
            const key = Object.keys( state )[0];
            this.state.set( key, state[key]);
        });
    }

    /**
     * sends different tracking events based on
     * the level of the tooltip.
     * @param stepId
     */
    private sendTrackingEventsOnShow( stepId: string ) {
        const step = this.tooltipData.value[stepId];
        if ( step.level === 1 && !this.tooltipStatus[ stepId ]) {
            this.translate.get( step.title ).pipe();
            Tracker.track( 'onboarding.tooltip.intro.load', {
                value1: this.translate.instant( step.title ),
                value2: step.stepId,
            });
        } else if ( step.level !== 0 && !this.tooltipStatus[ stepId ]) {
            Tracker.track( 'onboarding.tooltip.load', {
                value1: this.translate.instant( step.title ),
            });
        } else if ( stepId === 'root' && !this.tooltipStatus.root ) {
            Tracker.track( 'tour.load' );
        }
    }

    /**
     * checks whether eData features are available.
     * @returns
     */
    private isEDataAvailable(): boolean {
        return (
            this.planPermManager.check([
                `${PlanPermission.CUSTOM_DATABASES}|${PlanPermission.GITHUB_INTEGRATION}`,
            ]) && this.state.get( 'CurrentProject' ) !== 'home'
        );
    }

    /**
     * checks whether tasks features are available.
     */
    private isTasksAvailable(): boolean {
        return (
            this.planPermManager.check([ PlanPermission.TASK_MANAGEMENT ]) &&
            !!this.taskCount.value
        );
    }

    /**
     * checks whether a shape has been selected
     * @returns
     */
    private isShapeSelected(): boolean {
        return !!( this.state.get( 'Selected' ) as string[]).length;
    }

    /**
     * checks whether a given floating panel is open
     * @param panelId
     * @returns
     */
    private isPanelOpen( panelId: string ) {
        return !!( this.state.get( 'SelectedFloatingPanel' ) === panelId );
    }

    private isLeftSidePanelOpen( panelId: string ) {
        return !!( this.state.get( 'SelectedLeftPanel' ) === panelId );
    }
    /**
     * returns true if all of the provided steps are seen
     * @param tooltips
     */
    private areTooltipsSeen( tooltips: string[]): boolean {
        if ( tooltips && tooltips.length ) {
            return tooltips.every( tip =>
                this.tooltipStatus[ tip ]);
        } else {
            return true;
        }
    }

    /**
     * listens to the task count changes and updates
     * the property taskCount
     * @returns
     */
    private listenToTaskCountChange() {
        return this.taskLocator.getTasks().pipe(
            tap( tasks => {
                this.taskCount.next( tasks.length );
            }),
        );
    }


}
