import { Polygon } from './polygon';
import { Point } from './point';
import { IPoint2D } from 'flux-definition';
import { Line } from './line';
import { pairs } from '../data/collections';

/**
 * A map of points and the distance to each point from the starting point.
 *
 * Example:
 *  {
 *      A: 10,
 *      B: 20,
 *      C: 30,
 *  }
 */
export interface IPointDistanceMap {
    [ point: string ]: number;
}

/**
 * A map of points and the points which are visible to them with distances.
 *
 * Example:
 *  {
 *      A: { B: 10, C: 20 },
 *      B: { A: 10, D: 15 },
 *      C: { A: 20 },
 *      D: { B: 15 },
 *  }
 */
export interface IVisibilityGraph {
    [ point: string ]: IPointDistanceMap;
}

/**
 * Visibility graph and coordinates of all points available in the graph.
 *
 * Example:
 *  {
 *      graph: {
 *          A: { B: 10 },
 *          B: { A: 10, C: 10 },
 *          C: { B: 10 },
 *      },
 *      points: {
 *          A: { x: 10, y: 10 },
 *          B: { x: 20, y: 10 },
 *          C: { x: 20, y: 20 },
 *      },
 *  }
 */
export interface IVisibilityGraphAndPoints {
    vgraph: IVisibilityGraph;
    points: { [ point: string ]: IPoint2D };
}

/**
 * A visibility graph is created by connecting points which are visible to each other.
 * One can be created with a set of polygons and a set additional points. All points
 * on given polygons will be added as graph points.
 *
 * Example:
 *  const vgraph = new VGraph()
 *  vgraph.addPolygon( ...polygons )
 *  vgraph.process()
 *  const visibility = vgraph.create( ...points )
 *
 * After adding, updating or removing polygons (todo!) the process method should be
 * called to update the graph. The process method is expensive therefore only call
 * when needed. It is not recommended to call process during animations. A VGraph
 * instance created with polygons can be reused with multiple sets of graph endpoints.
 *
 * Example:
 *  const visibility1 = vgraph.create( ...points1 )
 *  const visibility2 = vgraph.create( ...points2 )
 *
 * The result visibility info can be used with graph algorithms to find the short
 * path or run other pathing algorithms.
 *
 * TODO: support removing polygons from the graph efficiently.
 * TODO: support non-rectangular polygons ( fix polygon.isInside method )
 *
 */
export class VGraph {
    /**
     * A map of all polygons added to the graph.
     */
    private polygons: { [ id: string ]: Polygon } = {};

    /**
     * A visibility graph with all polygons.
     */
    private vgraph: IVisibilityGraph = {};

    /**
     * A map of points used in the visibility graph.
     */
    private points: { [ pointId: string ]: IPoint2D } = {};

    /**
     * Indicates whether the graph has been modified.
     * If this is true, it will re-calculate visibility between
     * polygon points when creating a new visibility graph.
     */
    private modified = false;

    /**
     * Adds a polygon to the visibility graph.
     */
    public addPolygon( id: string, polygon: Polygon ): void {
        this.polygons[id] = polygon;
        this.addPolygonPoints( id, polygon );
        this.modified = true;
    }

    /**
     * Removes a polygon from the visibiltiy graph.
     * TODO: enable this feature only if it's needed.
     */
    // public removePolygon( id: string ): void {
    //     if ( !this.polygons[id]) {
    //         return;
    //     }
    //     delete this.polygons[id];
    //     this.removePolygonPoints( id );
    //     this.modified = true;
    // }

    /**
     * Creates a visibility graph with polygons and given points.
     * It will Build on top of the polygon-only visibility graph.
     */
    public create( endpoints: { [ pointId: string ]: IPoint2D }): IVisibilityGraphAndPoints {
        if ( this.modified ) {
            this.process();
            this.modified = false;
        }
        // NOTE: creating a shallow clone of the graph which only has
        // the polygons and adding all endpoints to it with visibility.
        const vgraph = { ...this.vgraph };
        const points = { ...this.points };
        // NOTE: check visibility for each point with each other.
        // Also check points with all polygon points in the graph.
        const pointIds = Object.keys( endpoints );
        for ( let i = 0; i < pointIds.length; i++ ) {
            const pointAId = pointIds[i];
            const pointA = endpoints[pointAId];
            points[pointAId] = { x: pointA.x, y: pointA.y };
            // NOTE: checking visibility between endpoints
            for ( let j = i + 1; j < pointIds.length; j++ ) {
                const pointBId = pointIds[j];
                const pointB = endpoints[pointBId];
                if ( this.isVisible( pointA, pointB )) {
                    this.setVisible( pointAId, pointA, pointBId, pointB, vgraph );
                }
            }
            // NOTE: checking visibility with polygon points
            const pointAPolygons = this.polygonsOnPoint( pointA );
            // tslint:disable-next-line:forin
            for ( const polygonBId in this.polygons ) {
                const polygonB = this.polygons[polygonBId];
                const polygonBPoints = polygonB.points;
                POINTS_LOOP:
                for ( let j = 0; j < polygonBPoints.length; j++ ) {
                    const pointB = polygonBPoints[j];
                    for ( const polygonA of pointAPolygons ) {
                        if ( polygonA.isInside( pointB, false )) {
                            continue POINTS_LOOP;
                        }
                    }
                    if ( this.isVisible( pointA, pointB, pointAPolygons, [ polygonB ])) {
                        const pointBId = this.getPointId( polygonBId, j );
                        this.setVisible( pointAId, pointA, pointBId, pointB, vgraph );
                    }
                }
            }
        }
        return { vgraph, points };
    }

    /**
     * Adds polygon points to the points map.
     */
    private addPolygonPoints( id: string, polygon: Polygon ) {
        for ( let i = 0; i < polygon.points.length; i++ ) {
            const point = polygon.points[i];
            const pointId = this.getPointId( id, i );
            this.points[pointId] = { x: point.x, y: point.y };
        }
    }

    /**
     * Get polygons which has an edge which goes through given point.
     */
    private polygonsOnPoint( point: IPoint2D ) {
        const polygons = [];
        // tslint:disable-next-line:forin
        for ( const polygonId in this.polygons ) {
            const polygon = this.polygons[polygonId];
            const edges = polygon.edgesWithPoint( point );
            if ( edges.length ) {
                polygons.push( polygon );
            }
        }
        return polygons;
    }

    /**
     * Returns a uniqe id which can be used to identify a point
     */
    private getPointId( polygonId: string, pointIdx: number ) {
        return `${polygonId}.${pointIdx}`;
    }

    /**
     * Updates the polygon-only visibility graph.
     * Complexity: n³ ( n = number of polygons )
     * TODO: This is really expensive, optimize like hell!!
     */
    private process(): void {
        this.vgraph = {};
        // tslint:disable-next-line:forin
        for ( const polygonId in this.polygons ) {
            const polygon = this.polygons[polygonId];
            this.processPointsWithinPolygon( polygonId, polygon );
            this.processPointsWithOtherPolygons( polygonId, polygon );
        }
    }

    /**
     * Checks points within a polygon for visibility ( only convex ).
     */
    private processPointsWithinPolygon( polygonId: string, polygon: Polygon ) {
        const points = polygon.points;
        let prevPointId = this.getPointId( polygonId, points.length - 1 );
        let prevPoint = points[ points.length - 1 ];
        for ( let i = 0; i < points.length; ++i ) {
            const currPointId = this.getPointId( polygonId, i );
            const currPoint = points[i];
            if ( this.isVisible( prevPoint, currPoint, [ polygon ], [ polygon ])) {
                this.setVisible( prevPointId, prevPoint, currPointId, currPoint );
            }
            prevPointId = currPointId;
            prevPoint = currPoint;
        }
    }

    /**
     * Checks whether points on one polygon is visible to points on other polygons.
     */
    private processPointsWithOtherPolygons( polygonId: string, polygon: Polygon ) {
        for ( const polygonBId in this.polygons ) {
            if ( polygonBId === polygonId ) {
                continue;
            }
            const polygonB = this.polygons[ polygonBId ];
            this.processPointsOnTwoPolygons( polygonId, polygonBId, polygon, polygonB );
        }
    }

    /**
     * Checks whether points on one polygon is visible to points on another polygon.
     */
    private processPointsOnTwoPolygons( polygonAId: string, polygonBId: string, polygonA: Polygon, polygonB: Polygon ) {
        for ( const pair of pairs( polygonA.points, polygonB.points )) {
            const [ pointA, pointB, pointAIdx, pointBIdx ] = pair;
            if ( polygonA.isInside( pointB ) || polygonB.isInside( pointA )) {
                continue;
            }
            if ( this.isVisible( pointA, pointB, [ polygonA ], [ polygonB ])) {
                const pointAId = this.getPointId( polygonAId, pointAIdx );
                const pointBId = this.getPointId( polygonBId, pointBIdx );
                this.setVisible( pointAId, pointA, pointBId, pointB );
            }
        }
    }

    /**
     * Checks whether points from 2 polygon corners are visible to each other.
     * This will ignore the edges the corner points are on.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    private isVisible(
        pointA: IPoint2D,
        pointB: IPoint2D,
        pointAPolygons?: Polygon[],
        pointBPolygons?: Polygon[]) {
            const connectingLine = new Line( pointA, pointB );
            // tslint:disable-next-line:forin
            for ( const polygonId in this.polygons ) {
                const polygon = this.polygons[polygonId];
                const hasPointA = pointAPolygons && pointAPolygons.indexOf( polygon ) >= 0;
                const hasPointB = pointBPolygons && pointBPolygons.indexOf( polygon ) >= 0;
                if ( hasPointA && hasPointB ) {
                    const edges = polygon.edgesWithPoint([ pointA, pointB ]);
                    if ( !edges.length ) {
                        return false;
                    }
                } else if ( hasPointA ) {
                    const edges = polygon.edgesWithoutPoint( pointA );
                    for ( const edge of edges ) {
                        if ( edge.intersects( connectingLine )) {
                            return false;
                        }
                    }
                } else if ( hasPointB ) {
                    const edges = polygon.edgesWithoutPoint( pointB );
                    for ( const edge of edges ) {
                        if ( edge.intersects( connectingLine )) {
                            return false;
                        }
                    }
                } else if ( polygon.intersects( connectingLine ) && !polygon.liesOnEdge( connectingLine )) {
                    return false;
                }
            }
            return true;
    }

    /**
     * Mark 2 points as visible to each other in the visibility graph
     */
    private setVisible(
        pointAId: string,
        pointA: IPoint2D,
        pointBId: string,
        pointB: IPoint2D,
        graph = this.vgraph,
    ) {
        const distance = Point.distanceTo( pointA, pointB );
        graph[ pointAId ] = graph[ pointAId ] || {};
        graph[ pointAId ][ pointBId ] = distance;
        graph[ pointBId ] = graph[ pointBId ] || {};
        graph[ pointBId ][ pointAId ] = distance;
    }
}
