import { AbstractCommand, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * Set state for voting start
 */
@Injectable()
@Command()
export class ShapeVoteState extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            ShapeVoting : {
                progress: 'settings',
            },
        };
    }

    public execute(): boolean {
        return true;
    }
}

Object.defineProperty( ShapeVoteState, 'name', {
    value: 'ShapeVoteState',
});
