import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
    Input, OnDestroy, OnInit, Output, ViewRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Uppy from '@uppy/core';
import * as Dashboard from '@uppy/dashboard';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'uppy-file-importer',
    templateUrl: './uppy-file-importer.cmp.html',
    styleUrls: [ './uppy-file-importer.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UppyFileImporterComponent implements OnInit, AfterViewInit, OnDestroy {

    /**
     * Target element class name
     */
    @Input() target: string;

    /**
     * Uppy dashboard import options
     * Check: https://uppy.io/docs/dashboard/
     */
    @Input() uiOptions: any = {
        inline : true,
        replaceTargetContent : false,
    };

    /**
     * Uppy core import options
     * Check: https://uppy.io/docs/uppy/
     */
    @Input() importOptions: any = {
        allowMultipleUploads: true,
    };

    @Input() uploadEventTrigger: Subject<boolean> = new Subject();

    @Input() removeFileTrigger: Subject<string> = new Subject();

    @Output() onUploadComplete: EventEmitter<Uppy.UploadResult> = new EventEmitter();

    // Uppy instance
    uppy: Uppy.Uppy;

    subs: Subscription[] = [];

    constructor(
        protected ref: ChangeDetectorRef,
        protected translate: TranslateService,
    ) {}

    @Input() afterViewInitCallback: Function = () => {};

    ngOnInit(): void {
        this.subs.push(
            this.listenUploadEventTrigger().subscribe(() => this.uploadEventHandler()),
            this.listenRemoveFileTrigger().subscribe(),
        );
    }

    ngAfterViewInit(): void {
        this.uppy = Uppy( this.importOptions );
        this.uiOptions.target = '.' + this.target;
        this.uppy.use( Dashboard, this.uiOptions ).run();

        this.uppy.on( 'complete', result => {
            this.onUploadComplete.emit( result );
        });
        this.uppy.on( 'file-added', () => !( this.ref as ViewRef ).destroyed && this.ref.detectChanges());
        this.uppy.on( 'file-removed', () => !( this.ref as ViewRef ).destroyed && this.ref.detectChanges());

        this.afterViewInitCallback();
    }

    ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
        this.uppy.off( 'complete', () => {});
        this.uppy.close();
    }

    private listenUploadEventTrigger(): Observable<boolean> {
        return this.uploadEventTrigger.pipe(
            filter(( value: boolean ) => value === true ),
        );
    }

    private listenRemoveFileTrigger(): Observable<string> {
        return this.removeFileTrigger.pipe(
            filter(( value: string ) => value !== null ),
            tap(( fileId: string ) => this.removeFile( fileId )),
        );
    }

    private uploadEventHandler(): void {
        this.uppy.upload();
    }

    private removeFile( fileId: string ): void {
        this.uppy.removeFile( fileId );
    }
}
