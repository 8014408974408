import { AbstractArrowHead, IDrawArrowheadOptions } from './arrow-head-abstract';
import { IGraphics } from 'flux-definition';

/**
 * PointerBarbedLine arrow head entry class is one of the default arrow head types.
 *
 * @author  Mehdhi
 * @since   2018-08-21
 */
/* istanbul ignore next */
export class PointerBarbedLine extends AbstractArrowHead {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void {
        const bounds = options.bounds;
        const width = bounds.width / 1.43;
        const height = bounds.height / 0.88;

        this.moveTo( graphics, { x: bounds.x - width, y: bounds.y - height / 2 }, options.matrix );
        this.curveTo(
            graphics,
            { x: bounds.x - width / 2, y: bounds.y },
            { x: bounds.x, y: bounds.y },
            options.matrix,
        );
        this.moveTo( graphics, { x: bounds.x - width, y: bounds.y + height / 2 }, options.matrix );
        this.curveTo(
            graphics,
            { x: bounds.x - width / 2, y: bounds.y },
            { x: bounds.x, y: bounds.y },
            options.matrix,
        );
        this.moveTo( graphics, { x: bounds.x, y: bounds.y }, options.matrix );
        graphics.closePath();

    }
}

Object.defineProperty( PointerBarbedLine, 'name', {
    value: 'PointerBarbedLine',
});
