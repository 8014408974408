import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../../controller/state.svc';
import { Tracker } from '../../tracker/tracker';

/**
 * This component is a tour card which displays it's information.
 * Each card should have a heading, description and an image.
 */
@Component({
    templateUrl: 'media-card.cmp.html',
    selector: 'media-card',
    styleUrls: [ './media-card.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCard {

    /**
     * Card details.
     */
    @Input() card: IMediaCard;

    /**
     * Boolean to show or hide heading
     */
    @Input() showHeading: boolean = true;

    constructor(
        protected state: StateService<any, any>,
        public translate: TranslateService,
    ) {}

    /**
     * Getter for thumbnail
     */
    public get getThumbnail() {
        return this.card.thumbnail;
    }

    /**
     * Function to play video linked to media card
     */
    public playVideo() {
        Tracker.track( 'helpWidget.videos.window.load', {
            value1Type: 'mediaTitle', value1: this.card.name,
        });
        this.state.set( 'WebLinkViewer', {
            open: true,
            link: this.card.url,
            createlyMediaId: this.card.id,
            mediaName: this.card.name,
        });
    }

}

export interface IMediaCard {
    id: string;
    heading: string;
    name: string;
    url: string;
    thumbnail: string;
    size: 'small' | 'large' | 'xlarge';
    mediaType: string;
}
