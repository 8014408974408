import { fromEvent, Subscription } from 'rxjs';
import { Shape, Stage } from '@creately/createjs-module';
import { Component, ChangeDetectionStrategy, Input, AfterViewInit, ViewChild, OnDestroy,
    ElementRef, AfterViewChecked } from '@angular/core';

/**
 * NOTE: for the canvas, use [hidden] instead of *ngIf so that the same canvas
 *       element will be there. Otherwise the canvas used by Easeljs will change.
 */
@Component({
    selector: 'static-image',
    template: `
        <div #container class="static-image-container fx-center-all">
            <canvas #canvasElement [hidden]="!isCanvas"></canvas>
            <img width="20" #imgElement *ngIf="isImage" />
            <svg tabindex="0" class="nu-icon toolbar-icon" *ngIf="isSVG">
                <use [attr.xlink:href]="iconHref" ></use>
            </svg>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticImage implements AfterViewInit, AfterViewChecked, OnDestroy {

    @Input()
    public type: string;

    @Input()
    public value: string | Function;

    @Input()
    public width: number;

    @Input()
    public height: number;

    /* To specify if the color should change on hover */
    @Input()
    public enableHover: boolean;

    @ViewChild( 'canvasElement' )
    public canvasElementRef: ElementRef;

    @ViewChild( 'imgElement' )
    public imgElementRef: ElementRef;

    @ViewChild( 'container' )
    public containerRef: ElementRef;

    // TODO: use RetinaStageSimplified
    // protected stage: RetinaStageSimplified = null;

    protected stage: Stage = null;
    protected shape: Shape = null;

    /**
     * The list of subscriptions held by this class
     */
    protected subs: Subscription[];

    /**
     * Getter that returns the SVG url for the icons.
     */
    public get iconHref(): string {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.value;
    }

    public get isCanvas() {
        return this.type === 'canvas';
    }

    /**
     * Returns true if the type is an svg.
     */
    public get isSVG(): boolean {
        return this.type === 'svg';
    }

    /**
     * Returns true if the type is an image.
     */
    public get isImage(): boolean {
        return this.type === 'image';
    }

    public ngAfterViewInit() {
        this.subs =  [];
        // TODO subscribe to hover event on the parent element of this cmponent
        if ( this.enableHover ) {
            const element = ( this.containerRef.nativeElement as HTMLElement );
            this.subs.push(
                fromEvent( element, 'mouseover' ).subscribe(() => this.draw( true )),
                fromEvent( element, 'mouseout' ).subscribe(() => this.draw( false )),
            );
        }
    }

    public ngAfterViewChecked() {
        this.draw();
    }

    public ngOnDestroy() {
        if ( this.stage ) {
            // TODO: use RetinaStageSimplified
            // this.stage.destroy();
            this.stage.canvas = null;
            ( this.stage as any )._eventListeners = null;
        }
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    protected draw( hovered: boolean = false ) {
        if ( this.isCanvas ) {
            this.drawCanvas( hovered );
        } else if ( this.isImage ) {
            this.drawImage();
        }
    }

    protected drawImage() {
        const img = this.imgElementRef.nativeElement;
        img.setAttribute( 'src', './assets/icons/svg/' + this.value + '.svg' );
    }

    protected drawCanvas( hovered: boolean ) {
        if ( !this.stage ) {
            const canvasEl = this.canvasElementRef.nativeElement;
            this.stage = this.createRetinaStage( canvasEl );
            this.shape = new Shape();
            canvasEl.width = this.width;
            canvasEl.height = this.height;
            this.stage.addChild( this.shape );
        }
        this.shape.graphics.clear();
        ( this.value as Function )( this.shape.graphics, hovered );
        this.stage.update();
    }

    /* istanbul ignore next */
    private createRetinaStage( el: Element ) {
        // TODO: use RetinaStageSimplified
        // const canvas = new RetinaStageSimplified( el );
        // canvas.setupStage();
        // return canvas;
        return new Stage( el );
    }

}
