import { Directive, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';
import { AbstractProjectRole } from './abstract-project-role';

/**
 * currentProjectRole Directive
 * This directive can be used to control features based on users' role of the current project.
 * When specifying which user roles are allowed, pass the minimum allowed role to the directive.
 * For all roles which are less than or equal to the given role will pass the directive and enable
 * the UI feature/element.
 *
 * How to use:
 * <div class="foo" *currentProjectRole="'EDITOR'"></div>
 * This will enable the element for all user roles upto editor, and lesser roles won't
 * see this element, ie: REVIEWER
 *
 * @author  Ramishka
 * @since   2019-06-21
 */
@Directive({
    selector: '[currentProjectRole]',
})
export class CurrentProjectRole extends AbstractProjectRole implements OnInit {

    /**
     * Get roles from the directive.
     * Any role from CollaboratorType enum is valid
     */
    @Input( 'currentProjectRole' )
    public projectRole: string;

    protected subs: Subscription;

    /**
     * Start controlling the view
     */
    public ngOnInit(): void {
        if ( CollaboratorType[ this.projectRole ] === undefined ) {
            throw new Error( 'CurrentProjectRole directive require a valid collaborator role to be matched against.' );
        }
        this.subs = this.state.changes( 'CurrentProject' ).pipe(
            filter( projectId => !!projectId ),
            switchMap( projectId => this.updateElementsOnProjectRole( projectId, this.projectRole )),
        ).subscribe();
    }
}
