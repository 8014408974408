import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.cmp.html',
    styleUrls: [ './fullscreen-message.scss' ],
})
export class PageNotFound implements OnInit {
    constructor() { }

    get location() {
        return document.location;
    }

    ngOnInit() { }
}
