import { Injectable } from '@angular/core';
import { Proxied } from '@creately/sakota';
import { TranslateService } from '@ngx-translate/core';
import { CommandService, StateService } from 'flux-core';
import { SystemType } from 'flux-definition';
import { map, find, cloneDeep, filter as filterLodash, toArray } from 'lodash';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { DiagramModel } from '../diagram/model/diagram.mdl';
import { ShapeModel } from '../shape/model/shape.mdl';
import { TaskLocator } from './task-locator.svc';
import { IRoleStatus, ProjectLocator, ProjectModel, TaskModel, TaskStatus } from 'flux-diagram';
import { DiagramLocatorLocator } from '../diagram/locator/diagram-locator-locator';
import { BaseDiagramCommandEvent } from '../diagram/command/base-diagram-command-event';
import { ModelSubscriptionManager, SubscriptionStatus } from '../../../../../libs/flux-subscription/src';

/**
 * This service can be used add / update / remove tasks.
 * @author mehdhi
 */
@Injectable()
export class TaskManager {

    constructor(
        protected commandService: CommandService,
        protected taskLocator: TaskLocator,
        protected state: StateService< any, any >,
        protected translate: TranslateService,
        protected projLocator: ProjectLocator,
        protected diagramLocator: DiagramLocatorLocator,
        private subManager: ModelSubscriptionManager,
    ) {}

    initialize() {
        this.state.changes( 'CurrentDiagram' ).pipe(
            distinctUntilChanged(),
            switchMap( diagramId => this.subManager.getFutureSub( diagramId ).pipe(
                switchMap( sub => sub.status ),
                filter( status => status.subStatus === SubscriptionStatus.started ),
                take( 1 ),
                tap(() => this.commandService.dispatch( DiagramCommandEvent.migrateTasks )),
            )),
        ).subscribe();
    }

    public fetchTasksForEntity( entityId: string ) {
        this.commandService.dispatch( BaseDiagramCommandEvent.getTasks, { entityId });
    }

    public addTask(
        taskId: string,
        roleId: string,
        diagram: Proxied<DiagramModel>,
        shape: ShapeModel,
        owners: string[],
        dataDef: any,
    ) {
        const task: any = {};
        task.id = taskId;
        task.roleId = roleId,
        task.roles = map( owners, owner => ({ userId: owner.userId, lastUpdated: Date.now() }));
        task.title = this.getTextFromShape( shape ) || this.translate.instant( 'LABELS.UNTITLED_TASK' );
        task.shapeId = shape.id;
        task.diagramId = diagram.id;
        task.folderId = diagram.project; // TODO: Fixme How should this be managed?
        if ( shape.eData ) {
            const eDataModels = Object.keys ( shape.eData );
            if ( eDataModels.length > 0 ) { // only consider the 1st model
                task.edataId = eDataModels[0];
                task.entityId = shape.eData[task.edataId];
            }
        }
        if ( 'creately.card.datasource' === shape.defId ) {
            task.sourceId = 'github';
        }

        task.creatorId = this.state.get( 'CurrentUser' );
        task.status = TaskStatus.Active;
        task.isActive = true;

        const roleBounds: any[] = filterLodash( dataDef, itemDef => itemDef.roleBound && itemDef.roleId === roleId );
        roleBounds.forEach( itemDef => {
            if ( itemDef.systemType === SystemType.DueDate ) {
                task.dueDate = shape.data[itemDef.id].value;
            } else if ( itemDef.systemType === SystemType.EstimateHrs ) {
                task.estimateHrs = shape.data[itemDef.id].value;
            } else if ( itemDef.systemType === SystemType.EstimatePts ) {
                task.estimatePts = shape.data[itemDef.id].value;
            }
        });

        // These data is added for notification purpose otherwise,
        // Need to fetch diagram, folder, role details by id it would be expensive.
        this.projLocator.getProject( diagram.project as any ).pipe(
            tap(( projModel: ProjectModel ) => {
                task.diagramName = diagram.name;
                task.folderName = projModel.name;
                task.roleName = diagram.getDataItem( shape.id, roleId ).label;
            }),
            switchMap(( projModel: ProjectModel ) => this.commandService.dispatch( DiagramCommandEvent.addTask, {
                task: task,
            })),
            take( 1 ),
        ).subscribe();

        return task;
    }

    public removeTask( taskId: string ) {
        this.state.set( 'CurrentSidebarTask', '' );
        this.commandService.dispatch( DiagramCommandEvent.removeTask, taskId, {
            taskId: taskId,
        }).subscribe();
    }

    public updateTaskTitle( taskId: string, value: string ) {
        const task: any = { title: value };
        this.commandService.dispatch( DiagramCommandEvent.updateTask, {
            taskId: taskId,
            task: {
                $set: task,
            },
        }).subscribe();
    }

    public updateTaskStatus( taskId: string, value: TaskStatus ) {
        this.updateTask( taskId, value, SystemType.Status );
    }

    public updateTaskActiveStatus( taskId: string, isActive: boolean ) {
        const task: any = { isActive: isActive };
        this.commandService.dispatch( DiagramCommandEvent.updateTask, {
            taskId: taskId,
            task: {
                $set: task,
            },
        }).subscribe();
    }

    public updateTaskActiveStatusByRole( roleId: string, isActive: boolean ) {
        this.taskLocator.getTaskByRoleId( roleId ).pipe(
            take( 1 ),
            filter( v => !!v ),
            tap( task => {
                this.updateTaskActiveStatus( task.id, isActive );
            }),
        ).subscribe();
    }

    public markComplete( taskId: string, isComplete: boolean = true ) {
        const userId = this.state.get( 'CurrentUser' );
        return this.taskLocator.getTask( taskId ).pipe(
            take( 1 ),
            filter( task => !!task ),
            switchMap( task => {
                const data: any = { $set: {}};
                data.$set.roles = cloneDeep( task.roles );
                const owner: IRoleStatus = find( data.$set.roles, role => role.userId === userId );
                if ( owner ) {
                    owner.isComplete = isComplete;
                }
                if ( isComplete ) {
                    // FIXME: Need proper UX to indicate any complete, current any assigned role can complete the task
                    // if ( task.anyComplete || data.$set.roles.filter( role => !role.isComplete ).length === 0 ) {
                        data.$set.completed = Date.now();
                        data.$set.completedBy = userId;
                        data.$set.status = TaskStatus.Completed;
                    // }
                } else if ( !task.anyComplete || data.$set.roles.filter( role => !role.isComplete ).length ) {
                    data.$unset = {};
                    data.$unset.completed = true;
                    data.$unset.completedBy = true;
                    if ( task.status === TaskStatus.Completed ) {
                        data.$set.status = TaskStatus.Active;
                    }
                }
                return this.commandService.dispatch( DiagramCommandEvent.updateTask, {
                    taskId: taskId,
                    task: data,
                });
            }),
        ).subscribe();
    }

    public updateTask( taskId: string, value: any, type: SystemType ) {
        if ( type === SystemType.Status && value === TaskStatus.Completed ) {
            return this.markComplete( taskId, true );
        }
        const payload: any = {};
        const taskData: any = {};

        this.taskLocator.getTask( taskId ).pipe(
            switchMap(( task: TaskModel ) => {
                taskData.id = task.id;
                taskData.title = task.title;
                taskData.shapeId = task.shapeId;
                taskData.diagramId = task.diagramId;
                taskData.folderId = task.folderId;
                taskData.roleId = task.roleId;

                return this.diagramLocator.forDiagram( task.diagramId, false ).getDiagramModel();
            }),
            switchMap(( diagram: DiagramModel ) => {

                taskData.diagramName = diagram.name;
                taskData.roleName = diagram.getDataItem( taskData.shapeId, taskData.roleId ).label;

                return this.projLocator.getProject( taskData.folderId );
            }),
            switchMap(( project: ProjectModel ) => {

                taskData.folderName = project.name;

                if ( value ) {
                    payload.$set = {};
                    this.mapChangeToTask ( payload.$set, value, type );
                } else {
                    payload.$unset = {};
                    this.mapChangeToTask ( payload.$unset, true, type );
                }

                return this.commandService.dispatch( DiagramCommandEvent.updateTask, {
                    taskId: taskId,
                    task: payload,
                    taskData,
                });
            }),
            take( 1 ),
        ).subscribe();
    }

    private getTextFromShape( shape: ShapeModel ) {
        return shape.primaryTextModel ? shape.primaryTextModel.plainText :
            shape.previewText || this.extractPlainText( shape );
    }

    private extractPlainText( shape: ShapeModel ): string {
        const txt = toArray( shape.texts ).find( t => !!t );
        let plaintext = '';
        if ( txt ) {
            for ( const block of txt.content ) {
                plaintext += block.text;
            }
        }
        return plaintext.trim();
    }

    private mapChangeToTask( task: any, value: any, type: SystemType ) {
        if ( !task ) {
            return;
        }
        if ( type === SystemType.Role ) {
            task.roles = value?.people.map( user => ({ userId: user.id, lastUpdated: Date.now() }));
        } else if ( type === SystemType.DueDate ) {
            task.dueDate = value;
        } else if ( type === SystemType.EstimateHrs ) {
            task.estimateHrs = value;
        } else if ( type === SystemType.EstimatePts ) {
            task.estimatePts = value;
        } else if ( type === SystemType.Status ) {
            task.status = value;
        }
    }

}
