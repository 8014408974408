import { ResourceModelStore } from './resource-model.store';
import { Observable, empty, forkJoin } from 'rxjs';
import { take, map, switchMap, last } from 'rxjs/operators';
import { difference } from 'lodash';

/**
 * ProjectModelStore
 * ProjectModelStore class is responsible for performing storage related
 * operations for any project resources. DataStore service
 * will create instances of this class for this model type.
 *
 * For more details see the {@link ModelStores}
 *
 * @author: Ramishka
 * @since: 2019-06-21
 */
export class ProjectModelStore extends ResourceModelStore {

    /**
     * Filters the storage so that only project data accessible to the user
     * remains in project and project static collections.
     * @param projectIds - ids of projects accesible to the user
     * @return observable which completes when all removals are done.
     */
    public storageFilterProjects( accessibleProjectIds: string[]): Observable<any> {
        return this.find({}).pipe(
            take( 1 ),
            map( projects => {
                if ( projects ) {
                    const storedIds =  projects.map( p => p.id );
                    return difference( storedIds, accessibleProjectIds );
                }
                return [];
            }),
            switchMap( toDelete => {
                const observables = [];
                if ( toDelete.length > 0 ) {
                    toDelete.forEach( id => {
                        observables.push( this.remove({ id }));
                        observables.push( this.removeStatic({ id }));
                    });
                    return forkJoin( observables ).pipe( last());
                }
                return empty();
            }),
        );
    }

}
