import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { StaticRenderer } from 'flux-diagram-composer';
import { StateService } from 'flux-core';
import { Router } from '@angular/router';

/**
 * This component is used to show canvas drawable thumbnails of a shape specifically
 * for Nucleus shapes that implement the IDrawable for thumbnails.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edata-library-item-count',
    template: `<div class="number-container" (click)="handleClick()">
                    {{count}}
                    <svg class="nu-icon-small" *ngIf="isExternalCount()">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-expand-thin"></use>
                    </svg>
                </div>`,
    styleUrls: [ './edata-library-item.scss' ],
})
export class EDataLibraryItemCount {

    /**
     * Diagram id of count
     */
    @Input() public diagramId: string;

    /**
     * Shape count in document
     */
    @Input() public count: number;

    constructor( protected rendered: StaticRenderer,
                 protected state: StateService<any, any>,
                 protected router: Router,
        ) {}


    public isExternalCount() {
        return this.state.get( 'CurrentDiagram' ) !== this.diagramId;
    }

    public handleClick() {
        if ( this.isExternalCount()) {
            this.router.navigate([ '../', this.diagramId, 'edit' ]);
            this.state.set( 'CurrentDiagram', this.diagramId );
        }
    }
}
