<div (click)="playVideo()">
    <div class="{{card.size}}Thumbnail">
        <img class="{{card.size}}Thumbnail" #thumbnail [src]="getThumbnail"/>
        <svg class="play-btn nu-icon-large" [class.position-play-with-heading]="showHeading">
            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-play"></use>
        </svg>
    </div>
    <div *ngIf="showHeading">
        <h1 class="caption fx-margin-top-5">{{translate.get( card.heading ) | async}}</h1>
    </div>
</div>