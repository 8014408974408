import { DynamicComponent } from 'flux-core/src/ui';

/**
 * This is the Abstract class for datasource implications.This should be extended by
 * the concrete datasource implementations.
 * GitHubDataSource,JiraDataSource, etc.
 */
export abstract class AbstractDataSourceImplementation extends DynamicComponent {

    /**
     * Name of the icon to use in the application for this datasource type.
     */
    public static dataSourceIcon?: string;

   /**
    *  Id of the data source shape definition.
    */
    protected defId: string = 'creately.card.datasource';

   /**
    *  Version of the data source shape definition.
    */
    protected version: number = 1;

    /**
     * Should Implement this to cache the data source shape definition.
     * Because data-source-item dragstart event not supporting async function in the the event.
     * So when we trying to get the data source shape definition it will get run after the dragstart event.
     * So resolve this we will cache above shape when data source panel are loading.
     */
    protected abstract cacheTheDataSourceDefinition(): void;
}
