import { FillStyle, IRadialGradient, ILinearGradient, IStyle, DEFAULT_LINE_THICKNESS,
            FillStylePreset } from 'flux-definition';
import { LineStyle } from './line-style';

/**
 * Class representing style data. A single object
 * contains properties which are used to define
 * and set line / font / fill styles to a shape.
 *
 * @author
 * @since 2017-11-06
 */

export class ShapeStyle extends LineStyle implements IStyle {

    public fillPreset: FillStylePreset = FillStylePreset.Flat;

    /**
     * The fill color is a string. This can be a
     * hexadecimal color code. Eg: #141414
     *
     * Fill style defines whether the shape fill is a solid
     * fill, a linear gradient or a radial gradient. By default
     * fill style is set to solid.
     *
     * Fill gradients can be a radial gradient or a linear gradient.
     * Colors, ratios and directions in which it should flow
     * can be defined.
     *
     * An alpha value to denote the visibility. Should
     * be between 0 and 1. 0 for invisible and 1 for solid.
     */

    constructor(
        public lineThickness: number = 2,
        public lineColor: string = '#1a1a1a',
        public lineStyle: number[] = [ 0, 0 ],
        public lineAlpha: number = 1,
        public fillColor: string = '#C62A2A',
        public fillStyle: FillStyle = FillStyle.Solid,
        public fillGradient: IRadialGradient | ILinearGradient =
            { colors: [ '#46B4B9', '#52C36C' ], ratios: [ 0, 1 ], x0: 0.5, y0: 0, x1: 0.5, y1: 0.9 },
        public fillAlpha: number = 1,
        public textColor: string = '#1a1a1a',
        public themeIndex: number = 0,
        public themeId: string = 'basem'  ) {
            super(
                lineThickness,
                lineColor,
                lineStyle,
                lineAlpha,
                lineColor,
                themeIndex,
                themeId,
            );
    }

    /**
     * Getter to get the style ID.
     */
    public get styleId(): string {
        let fillGradientValue = '';
        let fillColorValue = '';

        if ( this.fillStyle !== FillStyle.Solid ) {
            fillGradientValue = JSON.stringify( this.fillGradient );
        } else {
            fillColorValue = this.fillColor;
        }
        // NOTE: Line thickness value will be '2' for the style panel previews.
        const styleIdString: string = this.fillAlpha + fillColorValue + fillGradientValue + this.fillStyle
                                + this.lineAlpha + this.lineColor + this.lineStyle
                                + DEFAULT_LINE_THICKNESS + this.textColor;
        // Need to remove spaces and parantheses in order to support id in radiants
        return styleIdString.replace( /['"\s()]+/g, '' );
    }

    /**
     * Getter style id for shape style
     */
    public get id(): string {
        return this.styleId;
    }

    /**
     * Indicates if the fill style of this style is a
     * linear gradient.
     */
    public get isLinearGradient(): boolean {
        return this.fillStyle === FillStyle.LinearGradient;
    }

    /**
     * Indicates if the fill style of this style is a
     * radial gradient.
     */
    public get isRadialGradient(): boolean {
        return this.fillStyle === FillStyle.RadialGradient;
    }

    /**
     * Returns whatever the exising gradient with the values converted to pixels based on the
     * given width and height. Essentially gives the gradient that can be applied on something
     * that has the given width and height.
     * If gradient does not exist in the style, returns an empty object.
     */
    public getGradient( width: number, height: number ): ILinearGradient | IRadialGradient {
        if ( this.fillGradient ) {
            const gradient: IRadialGradient = <any>{ ...this.fillGradient };
            if ( gradient.x0 ) {
                gradient.x0 *= width;
            }
            if ( gradient.x1 ) {
                gradient.x1 *= width;
            }
            if ( gradient.y0 ) {
                gradient.y0 *= height;
            }
            if ( gradient.y1 ) {
                gradient.y1 *= height;
            }
            if ( gradient.r0 ) {
                gradient.r0 *= width <= height ? width : height ;
            }
            if ( gradient.r1 ) {
                gradient.r1 *= width <= height ? width : height ;
            }
            return gradient;
        }
        return <any>{};
    }
}
