import { TiptapDocumentsManagerShapeText,
} from './../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { Injectable } from '@angular/core';
import * as Carota from '@creately/carota';
import { Command, StateService } from 'flux-core';
import { ITextFormat, ITextStyles, DEFUALT_TEXT_STYLES } from 'flux-definition';
import { TextDataModel, TextFormatter } from 'flux-diagram-composer';
import { forEach, merge as lodashMerge, pick } from 'lodash';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { tap } from 'rxjs/operators';
import { merge, of } from 'rxjs';

/**
 * Applies text styles on a set of selected shapes.
 *
 * This is a Model Change Command which requires a map of ITextFormat where the id of the map is the text model id.
 * If the format should be appied to all the text models, wildcard should be used
 * e.g. format: { '*' : { indexStart: 0, styles }}
 *
 *
 * data: {
 *    format: { [id: string]: ITextFormat }
 *    reset: boolean
 * }
 */

const DATA_ITEMS_TEXT_IDS = [ 'data_items_labels' , 'data_items_values' ];
@Injectable()
@Command()
export class ApplyTextShapeStyles extends AbstractDiagramChangeCommand {

    /**
     * The styles that are boolean type
     */
    public static TOGGLE_STYLE_PROPS = [ 'bold', 'italic', 'underline', 'strikeout' ];
    public static ALIGN_PADDIN = 10;

    public static get dataDefinition(): {}  {
        return {
            shapeIds: false, // Shape ids to apply
            format: false, // { [id: string]: ITextFormat } object
            reset: false, // A boolean property to reset current styles and apply the given format
        };
    }

    protected state: StateService<any, any>;

    protected formatter: TextFormatter;

    constructor(
            state: StateService<any, any>,
            protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
        this.state = state;
        this.formatter = new TextFormatter();
    }

    /**
     * Prepares the modifiers
     */
    public prepareData() {
        const obs = [ of({}) ];
        this.data.modifier = {};
        if ( !this.data || ( !this.data.format && !this.data.applyTextStyles )) {
            // Return with just empty modifier so the command execution will be cancelled.
            return;
        }
        if ( !this.data.format ) {
            this.data.format = {};
        }
        const commonFormat  = this.data.format['*'];
        const shapeIds = this.data.shapeIds || this.state.get( 'Selected' );
        for ( const id of shapeIds ) {
            const shapeModel = this.changeModel.shapes[id];
            const shapeTextFormat = this.data.applyTextStyles ?
                    { indexStart: 0, styles: { color: ( shapeModel.style as any ).textColor }} : undefined;

            const innerSelection = this.state.get( 'InnerShapeSelection' );
            const innerIds = innerSelection[id];
            forEach( shapeModel.texts, ( txt: TextDataModel, key )  => {
                if ( innerIds && innerIds.length > 0 && !innerIds.includes( key )) {
                    return;
                }

                let textBounds;
                if ( txt && txt.content && txt.rendering !== 'tiptapCanvas' ) {
                    const textFormat: ITextFormat =
                        ( this.data.format[ key ] || shapeTextFormat || commonFormat || { styles: {}}) as ITextFormat;
                    if ( txt.fixedFormat ) {
                       textFormat.styles = lodashMerge({}, textFormat.styles, txt.fixedFormat );
                    }
                    if ( DATA_ITEMS_TEXT_IDS.includes( txt.id )) {
                        delete textFormat.styles.align;
                    }
                    this.handleTogglableStyles( textFormat, this.formatter.extractCommon( txt.value || '<span style="color:#1a1a1a"></span>' ));
                    const content = ( txt.content ).map( run =>
                        lodashMerge( run, pick( textFormat.styles, Object.keys( DEFUALT_TEXT_STYLES ))));
                    // Making copy as formatter replaces empty text with a space
                    const contentCopy = ( txt.content ).map( cntnt => Object.assign({}, cntnt ));
                    const txtFormatterContent = this.formatter.applyRaw( contentCopy, textFormat, this.data.reset );

                    txt.value = Carota.html.html( txtFormatterContent );
                    TiptapDocumentsManagerShapeText
                        .applyTextStyles( this.changeModel.id, id, key, textFormat.styles ).subscribe();
                    txt.content = content;
                    const { width } = this.changeModel.wordWrap( id, key );
                    textBounds = Carota.bounds( content, width, DEFUALT_TEXT_STYLES );
                } else if ( txt && txt.html ) {
                    const textFormat: ITextFormat =
                        ( this.data.format[ key ] || commonFormat || { styles: {}}) as ITextFormat;
                    if ( txt.fixedFormat ) {
                       textFormat.styles = lodashMerge({}, textFormat.styles, txt.fixedFormat );
                    }
                    this.handleTogglableStyles(
                        textFormat,
                        TiptapDocumentsManagerShapeText.extractCommonStyles( this.changeModel.id, id, key ),
                    );
                    textBounds = TiptapDocumentsManagerShapeText.getTextBounds( this.changeModel.id, id, key );
                    const observable = TiptapDocumentsManagerShapeText
                        .applyTextStyles( this.changeModel.id, id, key, textFormat.styles ).pipe(
                            tap( formatted => {
                                if ( formatted ) {
                                    txt.html = formatted;
                                }
                            }),
                        );
                    obs.push( observable );
                }
                txt.width = textBounds.width;
                txt.height = textBounds.height;
            });

            if ( commonFormat ) {
                shapeModel.defaultTextFormat = lodashMerge({}, shapeModel.defaultTextFormat, commonFormat.styles );
            }
        }

        return merge( ...obs );
    }

    /**
     * bold, italic, underline, strikeout are toggle buttons
     * the value, ( wheather apply or remove the style ) should be decided
     * by considering the current TextEditorState.
     */
    protected handleTogglableStyles( format: ITextFormat, currentStyles: ITextStyles ) {
        if ( currentStyles ) {
            for ( const k of ApplyTextShapeStyles.TOGGLE_STYLE_PROPS ) {
                if ( format.styles[k] === null ) {
                    format.styles[k] = !currentStyles[k];
                }
            }
        }
    }

}

Object.defineProperty( ApplyTextShapeStyles, 'name', {
    value: 'ApplyTextShapeStyles',
});
