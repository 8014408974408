import { IModifier, Matrix } from 'flux-core';
import { last } from 'lodash';
import { IConnectorViewDefinition } from '../../framework/connector/definition/connector-view-definition.i';
import { TextPostion } from '../../framework/text/text-position';
import { IConnectorTextView } from '../../framework/text/text-view.i';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { StaticRenderer } from '../../shape/view/static-renderer.svc';
import { ConnectorDataModel, IConnectorEndPoint, IConnectorPoint } from '../model/connector-data-mdl';

export class ConnectorRenderView extends AbstractShapeView implements IConnectorViewDefinition {

    /**
     * The points to draw the connector.
     * Helps to draw the connector with modified points.
     */
    public points?: IConnectorPoint[];

    /**
     * Adds a entry class instance to the cache.
     */
    protected cachedArrowHeadEntryClass: { [arrowHeadId: string]: any } = {};

    /**
     * The model associated with this shape. This is only updated
     * when the model is available and can be undefined.
     * @override
     */
    public get model(): ConnectorDataModel {
        return <ConnectorDataModel>this._model;
    }

    /**
     * Is called after shape is created and added to the view
     * for the first time.
     */
    public initialize() {
        // nothing yet
    }

    /**
     * This method redraws connector on the given
     * drawing space and also draws the given arrow heads in the
     * same container.
     */
    public render( modifier?: IModifier ) {
        this.graphics.clear();
        if ( !this.model.visible ) {
            this._texts.forEach( text => this.removeText( text ));
            this._images.forEach( image => this.removeImage( image ));
            return;
        }
        this.drawText( modifier );
        this.beginDraw();
        this.draw();
        this.drawArrows();
        this.endDraw();
    }

    /**
     * This function updates the text view accoridng to the given text model
     * @param ShapeTextView
     * @param change The text model changes
     * @override
     */
    protected updateText( text: IConnectorTextView, change?: any ) {
        const textmodel = this.model.texts[ text.name ];
        const position = TextPostion.getPointOnConnector( this.model, textmodel );
        text.update( textmodel, change, position );
    }

    /**
     * Drwas the arrow heads.
     */
    protected drawArrows() {
        const points = this.points || this.model.getPoints();
        // NOTE: ignore dash when drawing arrow heads
        this.graphics.setStrokeDash( null );
        // Draw arrow heads at the starting point
        if ( this.model.ends.from ) {
            this.drawArrowHead(
                this.model.ends.from,
                this.getArrowHeadMatrix( points[0] as IConnectorEndPoint ),
            );
        }
        // Draw arrow heads at the ending point
        if ( this.model.ends.to ) {
            this.drawArrowHead(
                this.model.ends.to,
                this.getArrowHeadMatrix( last( points ) as IConnectorEndPoint ),
            );
        }
    }

    /**
     * This drwas the arrow heads from the found arrow entry class
     */
    protected drawArrowHead( arrowHeadId: string, matrix: Matrix ) {
        const bounds = { x: 0, y: 0, width: 10, height: 11.5 };
        StaticRenderer.instance.renderArrowHeadSync(
            this.graphics,
            arrowHeadId,
            bounds,
            matrix,
            this.model.style,
        );
    }

    /**
     * Returns the matrix to transform the arrow heads according to the
     * connector end point given
     */
    protected getArrowHeadMatrix( endPoint: IConnectorEndPoint ): Matrix {
        return Matrix.fromTransform({
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
            x: endPoint.x,
            y: endPoint.y,
            angle: endPoint.direction,
        });
    }

}

