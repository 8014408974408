import { IPoint } from 'flux-definition';
import { AbstractConnector } from './connector-abstract';
import { IConnectorPoint } from '../../../../shape/model/connector-data-mdl';

/**
 * This class contains the capability to draw an angled connector
 * using the coordinate data stored into the model.
 *
 * Angled connectors are drawn similar to straight connectors, the
 * only difference is that intermediate points in the connector path
 * can be given as control points. Points in the path can have a single
 * control point or zero control points.
 *
 * Angled connectors can have either 1 or 2 segments per connector point on the path.
 * If there is only one segment it will connect the starting point to the main point.
 * If there are 2 segments available, the first segment will connect the starting
 * point to the first control point. The second segment will connect the control point
 * to the main connector point.
 *
 *   Example: connector with control points
 *
 *   +-----------------------------+
 *   |                             |
 *   | P1 +---------+ P2.c1        |
 *   |              |              |
 *   |              + P2           |
 *   |              |              |
 *   |        P3.c1 +---------> P3 |
 *   |                             |
 *   +-----------------------------+
 *
 *   Example: connector with control points
 *
 *   +----------------------+
 *   |                      |
 *   | P1 +---------+ P2.c1 |
 *   |              |       |
 *   |              |       |
 *   |              + P2    |
 *   |                      |
 *   +----------------------+
 *
 *   Example: connector without control points
 *
 *   +-------------------+
 *   |                   |
 *   | P1 +---------+ P2 |
 *   |                   |
 *   +-------------------+
 *
 */
export class ConnectorAngled extends AbstractConnector {
    /**
     * Draws the straight connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        this.moveTo( points[0]);
        // connect all other points
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            const head = this.geometry.createPoint( point.x, point.y );
            const next = this.nextBumpStart( head, points[ i + 1 ]) || point;
            if ( !point.c1 ) {
                this.lineTo( next, point.bumps && point.bumps[0]);
            } else {
                this.lineTo( point.c1, point.bumps && point.bumps[0]);
                this.lineTo( next, point.bumps && point.bumps[1]);
            }
        }
    }

    /**
     * Returns the point where the next segment first bump starts
     * only when it has to start before the line starting point.
     * This is possible only because angled connector path points are
     * always in the middle of the line part of the path.
     */
    private nextBumpStart( head: IPoint, next: IConnectorPoint ): IPoint {
        if ( !next || !next.bumps || !next.bumps[0] || !next.bumps[0].length ) {
            return null;
        }
        const bump = next.bumps[0][0];
        const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
        if ( head.distanceTo( bump ) > radius ) {
            return null;
        }
        const line = this.geometry.createLine( head, bump );
        return line.splitByLength( radius, false );
    }
}

Object.defineProperty( ConnectorAngled, 'name', {
    value: 'ConnectorAngled',
});
