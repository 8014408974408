import { Injectable } from '@angular/core';
import { Command, StateService, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { AbstractMessageCommand } from 'flux-connection';
import { Observable } from 'rxjs';
import { DiagramInfoModel, DiagramInfoModelStore } from 'flux-diagram';
import { DiagramPasswordModel } from 'libs/flux-diagram/src/model/diagram-password.mdl';

/**
 * This is the command to enable password protection of a workspace,
 * The protection can be enabled by it's owner or moderator only
 */
@Injectable()
@Command()
export class EnableDiagramPasswordProtection extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor(
            protected dataStore: DataStore,
            protected modelSubManager: ModelSubscriptionManager,
            protected state: StateService<any, any> ) {
                super() /* istanbul ignore next */;
    }

    public execute(): any {
        return true;
    }

    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        const passwordProtection: DiagramPasswordModel = {
            isEnabled: true,
            updatedBy: this.state.get( 'CurrentUser' ),
        };
        return modelStore
            .storageUpdatePasswordProtection( this.resourceId, passwordProtection );
    }
}

Object.defineProperty( EnableDiagramPasswordProtection, 'name', {
    value: 'EnableDiagramPasswordProtection',
});
