import { of, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExecutionStep } from './execution-step';
import { CommandInterfaces } from '../abstract.cmd';
import { CommandError } from '../error/command-error';

/**
 * ExecuteResultExecutionStep
 * This class is an implementation of the {@link ExecutionStep} which handles
 * the executeResult method of the command. For more details see {@link AbstractCommand}
 *
 * To see how this is used, see the {@link CommandExecutor}.prepareSequence method.
 *
 * @author  thanish
 * @since   2017-01-23
 */
export class ExecuteResultExecutionStep extends ExecutionStep {
    /**
     * Indicates the interfaces a command must implement for this
     * ExecutionStep step to process during the command execution.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IHttpCommand', 'IGraphqlRestCommand', 'INeutrinoRestCommand', 'ICollabCommand' ];
    }

    /**
     * When execute result completes the result data must be emited if
     * it has changed.
     */
    public get emitResult(): boolean {
        return true;
    }

    /**
     * Runs the execute method on the command and throws an exception in
     * case of a false is returned.
     */
    public process() {
        const result = this.command.executeResult( this.command.resultData );
        if ( result instanceof Observable ) {
            return result.pipe( mergeMap(() => of()));
        }
        if ( !result ) {
            throw new CommandError( 'Execution of command was cancelled from within the execute method' );
        }
    }
}

Object.defineProperty( ExecuteResultExecutionStep, 'name', {
    value: 'ExecuteResultExecutionStep',
});

