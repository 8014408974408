import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand, RequestMethod } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';

/**
 * This command will be used to fetch template context from server
 */
@Injectable()
@Command()
export class GetTemplateContext extends AbstractHttpCommand {

    public static get dataDefinition(): {}  {
        return {
            id: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }


    constructor( protected auth: NucleusAuthentication ) {
        super()/* istanbul ignore next */;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'TEMPLATE_CONTEXT_API_URL' ) + '/' + this.data.id;
    }

  /**
   * Command execute
   */
    public execute(): Observable<any> {
        return empty();
    }

}

Object.defineProperty( GetTemplateContext, 'name', {
    value: 'GetTemplateContext',
});
