import { Injectable } from '@angular/core';
import { ChangeBindingService } from './change-binding.svc';

/**
 * Change binding service keeps a registry of change binders.
 * The apply method on change binding service can be used to
 * run all change binders which are relevant to the change.
 */
@Injectable()
export class ResultChangeBindingService extends ChangeBindingService  {

    public getBindersList() {
        return [
            // To do register binder
            this.binders.LayoutingBinder,
            this.binders.TiptapShapeTextEditorBinder,
            this.binders.ContainerChildrenLayoutingBinder,
        ];
    }

}
