import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Command, CommandInterfaces, ImageLoader, NotifierController, StateService } from 'flux-core';
import { tap } from 'rxjs/operators';
import { EcoreObjectExporter } from '../export/ecore-object-exporter';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { AbstractDiagramExportCommand } from './abstract-diagram-export-command.cmd';

/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsXmi extends AbstractDiagramExportCommand {

    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    public constructor(
        ll: DiagramLocatorLocator,
        resources: ImageLoader,
        notifierController: NotifierController,
        translate: TranslateService,
        state: StateService<any, any>,
        private ecoreExporter: EcoreObjectExporter,
    ) {
        super( ll, resources, notifierController, translate, state )/* istanbul ignore next */;
    }

    /**
     * Prepare the svg image data to be exported.
     */
    public prepareData() {
        return this.ecoreExporter.exportEcoreModel( true ).pipe(
            tap(( data: any ) =>
                this.data = { content: data.data, name: data.name + '.xmi', type: 'application/xml', format: 'file' }),
        );
    }

}

Object.defineProperty( ExportAsXmi, 'name', {
    value: 'ExportAsXmi',
});
