import { AfterViewInit, Component, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MapOf } from 'flux-core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'search-form-item',
    templateUrl: 'search-form-item.cmp.html',
    styleUrls: [ './search-form-item.cmp.scss' ],
})

export class SearchFormItem implements AfterViewInit, OnDestroy {


    public dataItemSelector: FormControl = new FormControl();
    public ruleSelector: FormControl = new FormControl();
    public inputValueController: FormControl = new FormControl();
    public logicSelector: FormControl = new FormControl();

    public searchForm = this.formBuilder.group({
        dataItemLabel: this.dataItemSelector,
        rules: this.ruleSelector,
        value: this.inputValueController,
        logic : this.logicSelector,
    });

    @Input() public id: number;
    @Input() public dataLabelList: BehaviorSubject<string[]>;
    @Input() public logic: BehaviorSubject<string>;

    @Output() public change: Subject<string> = new Subject();


    public dataLabelListObservable: Observable<string[]>;
    public rulesListObservable: Observable<string[]>;

    private subs: Subscription[] = [];
    private _rulesListSubject: Subject<string[]> = new BehaviorSubject([]);
    private _rulesList: MapOf<string[]>;

    constructor( private formBuilder: FormBuilder ) { }


    @Input() public set rulesList( rList: MapOf<string[]> ) {
        this._rulesList = rList;
        this._rulesListSubject.next( rList.default );
    }

    public get rulesList(): MapOf<string[]> {
        return this._rulesList;
    }

    ngAfterViewInit() {
        if ( this.logic ) {
            this.subs.push(
                this.logic.subscribe( value => {
                this.logicSelector.setValue( value );
            }));
        }
        this.dataLabelListObservable = combineLatest(
            this.dataItemSelector.valueChanges.pipe( startWith( '' )),
            this.dataLabelList ).pipe(
            map(([ value, dataListArray ]) =>
            typeof value === 'string' ?
             this._filterObject( value, dataListArray ) : this._filterObject( value.name, dataListArray )),
        );
        this.rulesListObservable = combineLatest([
            this.ruleSelector.valueChanges.pipe(
                startWith( '' ),
            ),
            this._rulesListSubject,
        ]).pipe(
            map(([ value, rulesList ]) => this._filter( value, rulesList )),
        );
    }

    public handleDataItemSelectorChange( event: MatAutocompleteSelectedEvent ) {
        this._rulesListSubject.next( this._rulesList[event.option.value?.searchType || 'default']);
        this.ruleSelector.reset( '' );
    }

    public dataItemListDisplayHelper( selectionObj ) {
        return selectionObj ? selectionObj.name : '';
    }
    public toggleChange() {
        this.change.next( this.id.toString());
    }

    public selectDataTypeSelector( event: any ) {}

    ngOnDestroy() {
        this.subs.forEach( sub => sub.unsubscribe());
        this.subs = [];
    }

    private _filter( name: string , dataSet: string[]): string[] {
        const filterValue = name.toLowerCase();
        return dataSet.filter( option => option.toLowerCase().includes( filterValue ));
    }
    private _filterObject( name: string , dataSet: any[]): any[] {
        const filterValue = name.toLowerCase();
        return dataSet.filter( option => option.name.toLowerCase().includes( filterValue ));
    }
}
