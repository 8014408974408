import { GetDiagramInfo } from './get-diagram-info.cmd';
import { CommandMapper, CommandEvent } from 'flux-core';
import { GetRecentDiagrams } from './get-recent-diagrams.cmd';

/**
 * This class holds the defined events for all command invocations
 * that are on diagram resources. Each event will associate to one or more
 * commands that hold the resource type 'diagram'.
 */
// tslint:disable: member-ordering
export class DiagramCommandEvent extends CommandEvent {

    /**
     * Get all the recent diagram ids
     *
     *  Input Data: No input data required
     *
     */
    static getRecentDiagrams: DiagramCommandEvent = new DiagramCommandEvent( 'GetRecentDiagrams' );
    protected static registerGetRecentDiagrams( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.getRecentDiagrams ).add( GetRecentDiagrams as any );
    }

    /**
     * Get diagram info for the specified diagram id
     *
     *  Input Data: {
     *      diagramId: string
     *  }
     *
     */
    static getDiagramInfo: DiagramCommandEvent = new DiagramCommandEvent( 'GetDiagramInfo' );
    protected static registerGetDiagramInfo( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.getDiagramInfo ).add( GetDiagramInfo );
    }
}
