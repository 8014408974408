import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CollapsablePanelItem } from '../base/ui/panels/collapsable-panel-item.cmp';
import { DiagramRole } from '../base/diagram/collab/diagram-role.dir';
import { IfMode } from './if-mode.dir';
import { ThumbnailCanvas } from '../editor/ui/library/thumbnail-canvas.cmp';
import { ShapeThumbnailLoader } from '../editor/ui/library/shape-thumbnail-loader.cmp';
import { LibraryPreviewPopover } from '../editor/ui/library/popover/library-preview-popover.cmp';
import { ThumbnailImage } from '../editor/ui/library/thumbnail-image.cmp';
import { LibraryTile } from '../editor/ui/library/library-tile.cmp';
import { ThumbnailText } from '../editor/ui/library/thumbnail-text.cmp';
import { CollapsableEDataLibraryItem } from '../editor/ui/library/edata/edata-item/collapsable-edata-library-item.cmp';
import { EDataLibraryItemCount } from '../editor/ui/library/edata/edata-item/edata-library-item-count.cmp';
import { EDataLibraryItem } from '../editor/ui/library/edata/edata-item/edata-library-item.cmp';
import { TranslateModule } from '@ngx-translate/core';
import { QuickShapeSearch } from '../editor/ui/shape-search/quick-shape-search.cmp';
import { NgxMatSelectSearchModule } from '@creately/ngx-mat-select-search';
import { ShapeSearchItem } from '../framework/ui/search/shape-search-item.cmp';
// import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { SidebarDropdownButton } from './../framework/ui/dropdown/sidebar-dropdown/sidebar-dropdown-button.cmp';
import { StaticImage } from './../framework/ui/dropdown/toolbar-dropdown/static-image.cmp';
import { SidebarListDropdownItem } from '../framework/ui/dropdown/sidebar-dropdown/sidebar-list-dropdown-item.cmp';
import { FluxCoreUI } from 'flux-core/src/ui';

import { LibraryCollapsibleMenuItem } from '../editor/ui/library/library-collapsible-menu-item/library-collapsible-menu-item.cmp';
import { UserStrip } from '../base/ui/user/user-strip/user-strip.cmp';
import { GlobalNotificationNotifications } from '../base/ui/global-notification/global-notification-notifications/global-notification-notifications.cmp';
import { NotificationListItem } from '../base/ui/global-notification/global-notification-notifications/notification-list-item/notification-list-item.cmp';
import { NotificationRecentUpdateItem } from '../base/ui/global-notification/global-notification-notifications/notification-recent-update-item/notification-recent-update-item.cmp';
import { EDataSmartSetGroup } from '../editor/ui/library/edata/edata-item/edata-library-smartset-group.cmp';
import { CustomEntitySearchComponent } from '../editor/ui/library/edata/advance-search/custom-entity-search/custom-entity-search.cmp';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchFormItem } from '../editor/ui/library/edata/advance-search/search-form-item/search-form-item.cmp';
import { LogicLabelComponent } from '../editor/ui/library/edata/advance-search/custom-entity-search/logic-label.cmp';
import { EdataSearchResultsPanel } from '../editor/ui/library/edata/search-results/edata-search-results-panel.cmp';
import { EdataSearchResultItem } from '../editor/ui/library/edata/search-results/edata-search-result-item/edata-search-result-item.cmp';
import { EdataSmartSetLibraryItem } from '../editor/ui/library/edata/edata-item/edata-library-smartset-item.cmp';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { TooltipTourDirective } from '../base/ui/tooltip-tour/tooltip-tour.dir';
import { PlusCreateItem } from '../editor/ui/plus-create/plus-create-item.cmp';
import { PlusThumbnailCanvas } from '../editor/ui/plus-create/plus-thumbnail-canvas.cmp';
import { Loader } from '../base/ui/loader/loader.cmp';
import { LibsDeepviewItem } from '../editor/ui/temp-add-libs-menu/libs-deepview-item/libs-deepview-item.cmp';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        // MatSelectInfiniteScrollModule,
        FluxCoreUI,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatIconModule,
        MatExpansionModule,
    ],
    declarations: [
        IfMode,
        CollapsablePanelItem,
        DiagramRole,
        ThumbnailCanvas,
        PlusThumbnailCanvas,
        ThumbnailImage,
        ThumbnailText,
        ShapeThumbnailLoader,
        LibraryPreviewPopover,
        LibraryTile,
        CollapsableEDataLibraryItem,
        EDataLibraryItemCount,
        EDataLibraryItem,
        QuickShapeSearch,
        ShapeSearchItem,
        PlusCreateItem,
        SidebarDropdownButton,
        SidebarListDropdownItem,
        StaticImage,
        LibraryCollapsibleMenuItem,
        EDataSmartSetGroup,
        CustomEntitySearchComponent,
        SearchFormItem,
        LogicLabelComponent,
        EdataSearchResultsPanel,
        EdataSearchResultItem,
        EdataSmartSetLibraryItem,
        UserStrip,
        GlobalNotificationNotifications,
        NotificationListItem,
        NotificationRecentUpdateItem,
        TooltipTourDirective,
        Loader,
        LibsDeepviewItem,
    ],
    exports: [
        IfMode,
        CollapsablePanelItem,
        DiagramRole,
        ThumbnailCanvas,
        PlusThumbnailCanvas,
        ThumbnailImage,
        ThumbnailText,
        ShapeThumbnailLoader,
        LibraryPreviewPopover,
        LibraryTile,
        CollapsableEDataLibraryItem,
        EDataLibraryItemCount,
        EDataLibraryItem,
        QuickShapeSearch,
        ShapeSearchItem,
        PlusCreateItem,
        SidebarDropdownButton,
        SidebarListDropdownItem,
        StaticImage,
        LibraryCollapsibleMenuItem,
        EDataSmartSetGroup,
        CustomEntitySearchComponent,
        SearchFormItem,
        LogicLabelComponent,
        EdataSearchResultsPanel,
        EdataSearchResultItem,
        EdataSmartSetLibraryItem,
        UserStrip,
        GlobalNotificationNotifications,
        NotificationListItem,
        NotificationRecentUpdateItem,
        TooltipTourDirective,
        Loader,
        LibsDeepviewItem,
    ],
})
export class CommonUI {
}
