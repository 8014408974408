import { EDataManage } from './../../../framework/edata/edata-manage.svc';
import { SingleSelectComboCreator } from './ui-component-creators/single-select-combo-creator.cmp';
import { CollapsablePanelItem, COLLAPSABLE_PANEL_CONTENT_CLASS } from './../panels/collapsable-panel-item.cmp';
import { IEditorVisibility } from 'flux-definition';
import { AddDataItems } from './add-data-items.cmp';
import { DynamicComponent } from 'flux-core/src/ui';
import { SETTINGS_DATAITEM_ID } from './shape-data-settings.cmp';
import { take } from 'rxjs/operators';
import { LabelEditableDataItem } from './../../../framework/ui/components/label-editable-data-item.cmp';
import { DataType } from 'flux-definition';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IDataItem } from 'flux-definition';
import { Subscription } from 'rxjs';
import { get, isMatch, isEqual } from 'lodash';
import {
    AfterViewInit, OnDestroy, Component, ChangeDetectionStrategy,
    ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver,
    Injector, Input, Output, ElementRef, OnInit,
} from '@angular/core';
import { EventIdentifier, Logger, Tracker } from 'flux-core';
import { FormulaFieldCreator } from './ui-component-creators/formula-field-creator.cmp';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { EDataLocatorLocator } from '../../edata/locator/edata-locator-locator';
import { EventCollector } from 'flux-core';

/**
 * This component renderes data items,
 * Note:
 * When the data item id is `description`, it will render spesifically
 * Also data item with `dataitemsttings` id won't render here.. it will render in the settings tab
 *
 * @author thisun
 * @since 2021-01-19
 */

export const DESCRIPTION_DATAITEM_ID = 'description';

@Component({
    template: `
        <div [class.fx-hidden]="liteModeAddDataitems" class="entity-editable-notice" *ngIf="entityEditable" >
            <div class="body text" translate>SHAPE_DATA.ENTITY_UPDATE</div>
            <button class="btn-small btn-secondary" (click)="handleTypeEditableDoneBtn()" translate>LABELS.DONE</button>
        </div>
        <div class="shape-data-item-add" *ngIf="!isEntity">
            <add-data-items class="primary" [contentOnly]="liteModeAddDataitems" [context]="context" [hasEData]="hasEData" [dataItems]="dItems" #addDataItems ></add-data-items>
        </div>
        <div [class.fx-hidden]="liteModeAddDataitems" class="shape-data-items-container" [class.data-items-renderer-show]="showInNotes">
            <div #dataItemsContainer ></div>
        </div>
        <!-- TODO: Remove #dataItemContainerDesc since it's not used anymore. Related things to remove include omitDescription input -->
        <div [class.fx-hidden]="liteModeAddDataitems" class="shape-data-item-desc" >
            <div #dataItemContainerDesc ></div>
        </div>
    `,
    selector: 'data-items-renderer',
    styleUrls: [ './data-items-renderer.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataItemsRenderer extends DynamicComponent implements AfterViewInit, OnDestroy, OnInit {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @ViewChild( 'addDataItems', { read: AddDataItems, static: false })
    public addDataItems: AddDataItems;

    /**
     * associated diagramId
     */
    @Input()
    public diagramId: string;

    /**
     * associated shape
     */
    @Input()
    public shape: ShapeModel;

    /**
     * If the liteModeAddDataitems is true,
     * this component can be used as a contextual dataItems adder.
     */
    @Input()
    public liteModeAddDataitems: boolean = false;

    @Input()
    public showInNotes: boolean = false;
    /**
     * callback function to be called when the data items changed
     * { id: string, data: any }
     */
    @Input()
    public isEntity: boolean;

    /**
     * If true, Description data item won't rendered
     */
    @Input()
    public omitDescription: boolean = true;

    /**
     * Renders the description data item only
     */
    @Input()
    public onlyDescription: boolean = false;

    @Input()
    public onChange: Function;

    @Input()
    public onPropAdded: Function;

    /**
     * To show the Entity editable notice.
     */
    @Input()
    public entityEditable: boolean;

    public hasEData = new BehaviorSubject( false );
    public dItems = new BehaviorSubject({});

    /**
     * Subscriptions
     */
    protected subs = [];

    /**
     * Data items components and their containers
     */
    protected items: { [id: string]:  { item: ComponentRef<any> , container: ViewContainerRef }};


    /**
     * Collapsabale components created for secoondary level data items
     */
    protected collapsables = {};

    @Output()
    protected change: Subject<any>;

    protected changeSubs: { [id: string]:  Subscription };
    protected updateSubs: { [id: string]:  Subscription };

    @ViewChild( 'dataItemsContainer', { read: ViewContainerRef, static: false })
    protected dataItemsContainer: ViewContainerRef;

    @ViewChild( 'dataItemContainerDesc', { read: ViewContainerRef, static: false })
    protected dataItemContainerDesc: ViewContainerRef;

    constructor (
        protected eDataManageSvc: EDataManage,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected elementRef: ElementRef,
    ) {
        super( componentFactoryResolver, injector );
        this.items = {};
        this.changeSubs = {};
        this.updateSubs = {};
        this.change = new Subject();
    }

    /**
     * Data items to be rendered
     */
    @Input()
    public set dataItems( dataItems: { [id: string]: IDataItem<DataType> }) {
        this.dItems.next( dataItems );
    }

    public get dataItems(): { [id: string]: IDataItem<DataType> } {
        return this.dItems.getValue();
    }

    @Input()
    public set dataItemsObs( dataItems: Observable<{ [id: string]: IDataItem<DataType> }> ) {
        this.subs.push( dataItems.subscribe( dItems => this.dataItems = dItems ));
    }

    public get itemIds(): string[] {
        return Object.keys( this.items );
    }

    public onTabUpdate() {
        this.clearSubs();
        if ( this.dataItemsContainer ) {
            this.manage();
        }
        EventCollector.log({
            message: EventIdentifier.SHAPE_DATA_ITEM_UPDATED,
            dataItems: this.dataItems,
        });
    }

    public ngOnInit(): void {
        this.hasEData.next( !!this.shape?.eDataId );
    }

    public ngAfterViewInit() {
        this.manage();
    }

    public handleTypeEditableDoneBtn( delay = 300 ) {
        // NOTE: If we click the Done button without focusing out the labeleditable field
        // the label is not getting updated so we have to manualy focus out the labeleditable field
        // prior to call the eDataManageSvc
        document.body.focus();
        setTimeout(() => {
            this.eDataManageSvc.makeTypeEditable( false ).subscribe();
        }, delay );
    }

    /**
     * Destroys the component
     */
    public ngOnDestroy() {
        Object.keys( this.changeSubs ).forEach( id => {
            this.changeSubs[id].unsubscribe();
        });
        Object.keys( this.updateSubs ).forEach( id => {
            this.updateSubs[id].unsubscribe();
        });
        Object.keys( this.items ).forEach( key => {
            const item = this.items[ key ];
            item.item.destroy();
        });
        this.clearSubs();
        this.change.complete();
    }

    protected manage() {
        if ( this.showInNotes ) {
            this.dataItems = this.getPublishableData();
        }
        if ( !this.dataItems ) {
            this.dataItems = {};
        }
        if (( !this.omitDescription || this.onlyDescription ) &&
            !this.isEntity && !Object.keys( this.dataItems ).includes( DESCRIPTION_DATAITEM_ID )) {
            this.dataItems[ DESCRIPTION_DATAITEM_ID ] = this.getDescriptionDataItem() as any;
        }

        const dataItems = {};
        if ( this.onlyDescription ) {
            dataItems[ DESCRIPTION_DATAITEM_ID ] = this.dataItems[ DESCRIPTION_DATAITEM_ID ];
        } else if ( this.omitDescription ) {
            Object.keys( this.dataItems ).forEach(( key, index ) => {
                if ( key !== DESCRIPTION_DATAITEM_ID && !this.dataItems[ key ].roleBound ) {
                    dataItems[ key ] = this.dataItems[ key ];
                    dataItems[ key ].index = index;
                }
            });
        }

        this.manageDataItems( dataItems );
        this.subs.push(
            this.change.subscribe( change => this.onChange( change )),
        );
        if ( this.addDataItems ) {
            this.hasEData.next( !!this.shape?.eDataId );
            this.subs.push( this.addDataItems.change.subscribe( dataItem => this.addItem( dataItem )));
            this.subs.push( this.addDataItems.propChange.subscribe( change => this.onPropAdded( change )));
        }
    }

    protected getPublishableData() {
        const items = {};
        Object.keys( this.dataItems ).forEach(( key, index ) => {
            if ( this.dataItems[ key ].showInNotes === true ) {
                items[ key ] = this.dataItems[ key ];
            }
        });
        return this.sortDataItemsByIndex( items );
    }

    /**
     * Re arragnge the data items
     */
    protected layOutDataItems() {
        const elements =  this.elementRef.nativeElement.querySelectorAll( '.shape-data-items-container labeleditable-dataitem' );
        const groups = {};
        elements.forEach( el => {
            const val = el.getAttribute( 'data-layout' );
            if ( !groups[ val ]) {
                groups[ val ] = [ el ];
            } else {
                groups[ val ].push( el );
            }
        });
        Object.keys( groups ).forEach( key => {
            const g = groups[ key ];
            const firstEl =  g[0];
            if ( key.includes( 'inline' )) {
                const gridContainer = document.createElement( 'div' );
                gridContainer.className = 'container';
                gridContainer.innerHTML = `<div class="row"></div>`;
                this.insertAfter( gridContainer, firstEl );
                g.forEach( el => {
                    const col = document.createElement( 'div' );
                    col.className = 'col my-auto';
                    col.appendChild( el );
                    gridContainer.querySelector( '.row' ).appendChild( col );
                });
            } else if ( key.includes( 'block' )) {
                const gridContainer = document.createElement( 'div' );
                gridContainer.className = 'container';
                this.insertAfter( gridContainer, firstEl );
                g.forEach( el => {
                    const row = document.createElement( 'div' ) as HTMLElement;
                    row.className = 'row';
                    row.innerHTML = `<div class="col my-auto"></div>`;
                    row.firstChild.appendChild( el );
                    gridContainer.appendChild( row );
                });
            }
        });
    }

    protected manageDataItems( dataItems: { [id: string]: IDataItem<DataType> }) {
        const { toRemove, toUpdate, toAdd, toAddAll } = this.manageDataItemsNested( dataItems );


        if ( toRemove.length === 0 && toAdd.length === 0 && toUpdate.length > 0 ) {
            toUpdate.forEach(( item, index ) => this.updateItem( item ));
            return;
        } else { // FIXME - clear all and rerender all for the moment, can further optimize
            this.clearDataItemsContainer();
            toAddAll
                .filter( item => item.id !== DESCRIPTION_DATAITEM_ID )
                .forEach(( item, index ) => this.addItem( item ));
            this.layOutDataItems();
        }
        // toRemove.forEach( id => this.removeItem( id ));
        toAdd
            .filter( item => item.id === DESCRIPTION_DATAITEM_ID )
            .forEach( item => this.addItem( item ));
        // toRemove.forEach( id => this.removeItem( id ));
        // toUpdate.forEach(( item, index ) => this.updateItem( item ));
    }

    protected clearDataItemsContainer() {
        this.itemIds
            .filter( id => id !== DESCRIPTION_DATAITEM_ID )
            .forEach( id => this.removeItem( id ));
        /* istanbul ignore else */
        if ( this.dataItemsContainer  ) {
            this.dataItemsContainer.clear();
            const el = this.dataItemsContainer.element.nativeElement as HTMLElement;
            [ ...el.parentElement.children as any ].forEach( e => {
                if ( e !== el ) {
                    e.remove();
                }
            });
        }
        this.collapsables = {};
    }

    protected manageDataItemsNested( dataItems: { [id: string]: IDataItem<DataType> }): any {
        const toAdd = [];
        const toAddAll = [];
        const toUpdate = [];
        const toRemove = [];
        const sorted = this.sortDataItemsByIndex( dataItems );

        const innerSelected = Object.values( dataItems )
            .filter( d => d.type === DataType.CHILD_SHAPE && !!d.value.selected );

        Object.keys( sorted ).forEach( key => {

            if ( key === SETTINGS_DATAITEM_ID ) { // Ignore settings item
                return;
            }
            const item = dataItems[ key ] as any;

            if ( innerSelected.length > 0 ) {
                // Don't show normal data items for cell selections
                if ( !innerSelected.every( v => ( item.innerSelection || []).includes( v.value?.id ))) {
                    return;
                }
            } else if ( item.innerSelection && item.innerSelection.length > 0 ) {
                // Don't show cell specific data items for normal selections
                return;
            }

            const visible: IEditorVisibility = ( item.visibility || []).find( di => di.type === 'editor' ) as any;
            if ( !visible ) {
                return;
            }
            if (( visible as any ).containerOnly && !this.shape.isContainer ) {
                return;
            }

            if ( item.isNested ) {
                const labelPath = item.labelPath || 'name';
                if ( item.value[ labelPath ]) {
                    item.label = item.value[ labelPath ].value;
                }
            }
            if ( !item.label ) {
                item.label = item.id;
            }

            // NOTE: parents and path properties are added for interim operations
            // those are not actual data item properties and won't be stored in the data item
            if ( item.path ) {
                 item.key = `${item.path}.${key}`;
            } else {
                 item.key = key;
            }

            item.id = key;
            if ( this.itemIds.includes( item.key )) {
                toUpdate.push( item );
            } else {
                toAdd.push( item );
            }
            toAddAll.push( item );

            if ( item.isNested ) {
                for ( const id in item.value ) {
                    const child = item.value[ id ];
                    child.parentId = key;

                    if ( ! item.parents ) {
                        child.parents = [ key ];
                    } else {
                        child.parents = [ ... item.parents, key ];
                    }

                    // Constructing path here and attaching to the data item
                    // this path is useful in the model change command
                    if ( item.path ) {
                        child.path = `${ item.path}.${key}.value`;
                    } else {
                        child.path = `${key}.value`;
                    }
                }
                const val = this.manageDataItemsNested( item.value ) as any;
                toAdd.push( ...val.toAdd );
                toUpdate.push( ...val.toUpdate );
                toAddAll.push( ...val.toAddAll );
            }

        });
        toRemove.push( ...this.itemIds.filter( id => !get( dataItems, id )));
        return { toAdd: toAdd, toUpdate, toRemove, toAddAll };
    }

    protected addItem<T>( dataItem: IDataItem<DataType> ) {
        const itemRef: ComponentRef<LabelEditableDataItem> = this.makeComponent( LabelEditableDataItem );
        // Setting inputs
        itemRef.instance.data = { ...dataItem };
        itemRef.instance.shapeId = this.shape?.id;
        itemRef.instance.diagramId = this.diagramId;
        itemRef.instance.index = dataItem.index;
        /* istanbul ignore next */
        if ( this.context ) {
            itemRef.instance.context = this.context + '.dataField';
        }
        this.items[( dataItem as any ).key ] = { item: itemRef, container: this.getContainer( dataItem ) };
        this.subscribeToItemChange( itemRef.instance );
        this.subscribeToItemRemove( itemRef.instance );
        this.subscribeToItemUpdate( itemRef.instance );

        const parentKey = (( dataItem as any ).parents || []).join( '.' );
        const itemKey = parentKey ? parentKey + '.' + dataItem.id : dataItem.id;

        if ( !parentKey && !dataItem.isNested ) {
            this.insert( this.getContainer( dataItem ), itemRef );
            itemRef.changeDetectorRef.detectChanges();

            this.getContainer( dataItem ).element.nativeElement.parentElement
                .appendChild( itemRef.location.nativeElement );
            itemRef.changeDetectorRef.detectChanges();
        } else if ( !parentKey && dataItem.isNested && !this.collapsables[ itemKey ]) {
            const collapsMenuRef: ComponentRef<CollapsablePanelItem> = this
                .makeComponent( CollapsablePanelItem );
            ( collapsMenuRef.instance as any ).id = itemKey;
            collapsMenuRef.instance.heading = dataItem.label;
            collapsMenuRef.instance.toggleItem();
            ( collapsMenuRef.location.nativeElement as HTMLElement ).classList.add( 'toplevel-collapsable' );
            this.collapsables[ itemKey ] = collapsMenuRef;
            this.insert( this.getContainer( dataItem ), collapsMenuRef );
            this.getContainer( dataItem ).element.nativeElement.parentElement
                .appendChild( collapsMenuRef.location.nativeElement );
            collapsMenuRef.changeDetectorRef.detectChanges();
        } else if ( parentKey && dataItem.isNested && !this.collapsables[ itemKey ]) {
            const collapsMenuRef: ComponentRef<CollapsablePanelItem> = this
                .makeComponent( CollapsablePanelItem );
            ( collapsMenuRef.instance as any ).id = itemKey;
            collapsMenuRef.instance.heading = dataItem.label;
            collapsMenuRef.instance.toggleItem();
            ( collapsMenuRef.location.nativeElement as HTMLElement ).classList.add( 'inner-collapsable' );
            this.collapsables[ itemKey ] = collapsMenuRef;
            this.insert( this.getContainer( dataItem ), collapsMenuRef );
            collapsMenuRef.changeDetectorRef.detectChanges();


            const collapsible = this.collapsables[ parentKey ];
            ( collapsible.location.nativeElement as HTMLElement ).querySelector( `.${COLLAPSABLE_PANEL_CONTENT_CLASS}` )
                .appendChild( collapsMenuRef.location.nativeElement );
            collapsMenuRef.changeDetectorRef.detectChanges();
        } else if ( parentKey &&  !dataItem.isNested && this.collapsables[ parentKey ]) {
            itemRef.changeDetectorRef.detectChanges();
            const collapsible = this.collapsables[ parentKey ];
            ( collapsible.location.nativeElement as HTMLElement ).querySelector( `.${COLLAPSABLE_PANEL_CONTENT_CLASS}` )
                .appendChild( itemRef.location.nativeElement );
            itemRef.changeDetectorRef.detectChanges();
        } else { // Plain data item
            this.insert( this.getContainer( dataItem ), itemRef );
            itemRef.changeDetectorRef.detectChanges();
        }
        itemRef.instance.change.next( dataItem );

    }

    protected getContainer( dataItem: IDataItem<DataType> ) {
        if ( dataItem.id === DESCRIPTION_DATAITEM_ID ) {
            return this.dataItemContainerDesc;
        }
        return this.dataItemsContainer;
    }

    protected removeItem( id: string ) {
        if ( this.items[id]) {
            const itemRef = this.items[id].item;
            const container = this.items[id].container;
            if ( itemRef && container ) {
                this.remove( container, itemRef );
                delete this.items[ id ];
            }
            this.unsubscribeItemSubs( id );
        }
    }

    protected updateItem<T> ( dataItem: IDataItem<DataType> ) {
        const path = ( dataItem as any ).key || dataItem.id;
        if ( this.items[path] && this.items[path].item ) {
            const itemRef = this.items[path].item;
            if ( !isMatch( itemRef.instance.data , dataItem ) ||
                !isEqual( itemRef.instance.data?.value , dataItem?.value )) {
                itemRef.instance.setData( dataItem );
            }
        }
    }

    protected subscribeToItemChange( item: LabelEditableDataItem ) {
        const path = ( item.data as any ).key || item.data.id;
        const sub = item.change.subscribe( change => {
            this.change.next({ id: path, data: Object.assign({}, item.data, change ) });
            /* istanbul ignore next */
            if ( change?.accessLevels ) {
                /* istanbul ignore next */
                const trackingValue = change.accessLevels[0] ? change.accessLevels[0].diagRole : 'everyone';
                Tracker.track( `${this.context}.dataField.settings.click`, { value1: trackingValue });
            }
        });
        this.changeSubs[path] = sub;
    }

    protected subscribeToItemRemove( item: LabelEditableDataItem ) {
        const subscription = item.removed.subscribe( id => {
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.dataField.settings.click`, { value1: 'remove' });
            }
            if ( this.shape && this.shape.eDataId ) {
                const ell = this.injector.get( EDataLocatorLocator );
                ell.getEntityOnce( this.shape.eDataId, this.shape.entityId ).subscribe( entity => {
                    if ( entity.getDirectRefFields( id ).length > 0 ) {
                        Logger.error( 'Cannot remove selected data item as it is referred in formulas' );
                        return;
                    }
                    if ( entity.getLookupRefFields( id ).length > 0 ) {
                        Logger.error( 'Cannot remove selected data item as it is referred in formulas' );
                        return;
                    }
                    subscription.unsubscribe();
                    this.removeItem( id );
                    this.change.next({ id, data: undefined });
                });
            } else {
                subscription.unsubscribe();
                this.removeItem( id );
                this.change.next({ id, data: undefined });
            }
        });
    }

    /**
     * Subscribes to the update of the data item ( when the user clicks the update option
     * in the data item's options dropdown )
     * The updator component depends on the the datam.
     * @param item
     */
    protected subscribeToItemUpdate( item: LabelEditableDataItem ) {
        const sub = item.update.subscribe( id => {
            item.hide();
            const cmp = this.getUpdatorComponent( item.data.type );
            if ( cmp ) {
                /* istanbul ignore next */
                if ( this.context ) {
                    Tracker.track( `${this.context}.dataField.settings.click`, { value1: 'update' });
                }
                const updator: ComponentRef<any> = this.makeComponent( cmp );
                // Setting inputs
                updator.instance.dataItem = item.data;
                updator.instance.type = 'update';
                this.insert( this.dataItemsContainer, updator );
                updator.changeDetectorRef.detectChanges();
                if ( updator.instance.show ) {
                    updator.instance.show();
                }
                item.appendChild( updator.location.nativeElement );
                updator.instance.change.pipe( take( 1 )).subscribe( change => {
                    const path = ( item.data as any ).key || item.data.id;
                    this.change.next({ id: path, data: Object.assign( item.data, change ) });
                });
                updator.instance.closed.pipe( take( 1 )).subscribe(() => {
                    this.remove( this.dataItemsContainer, updator );
                    item.show();
                });
            }
        });
        this.updateSubs[item.data.id] = sub;
    }

    protected unsubscribeItemSubs( id: string ) {
        if ( this.changeSubs[id]) {
            this.changeSubs[id].unsubscribe();
            delete this.changeSubs[id];
        }
        if ( this.updateSubs[id]) {
            this.updateSubs[id].unsubscribe();
            delete this.updateSubs[id];
        }
    }

    protected clearSubs() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    private insertAfter( newNode, referenceNode ) {
        referenceNode.parentNode.insertBefore( newNode, referenceNode.nextSibling );
    }

    private sortDataItemsByIndex( dataItems: any ) {
        const sortable = [];
        for ( const k in dataItems ) {
            // If the index is not specified, the item will be pushed to the bottom
            sortable.push([ k, ( dataItems[k].index || Number.MAX_SAFE_INTEGER ), dataItems[k] ]);
        }
        sortable.sort(( a, b ) => a[1] - b[1]);
        const objSorted = {};
        sortable.forEach( item => objSorted[item[0]] = item[2]);
        return objSorted;
    }

    private getDescriptionDataItem() {
        return {
            id: DESCRIPTION_DATAITEM_ID,
            value: '',
            type: DataType.STRING_HTML,
            label: 'Description',
            def: 'descriptionRichtext',
            optional: true,
            visibility: [
                { type: 'editor' },
            ],
        };
    }

    /**
     * Returns the updator component for the given data item
     * @param dataType
     */
    private getUpdatorComponent( dataType: DataType ) {
        if ( dataType === DataType.OPTION_LIST ) {
            return SingleSelectComboCreator;
        }
        if ( dataType === DataType.FORMULA ) {
            return FormulaFieldCreator;
        }
    }

}
