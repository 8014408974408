import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Adds an entity refernce to a shape.
 * Also checks if the document has a reference to the model, if not adds that as well.
 *
 * This usually happens when an normal shape is linked to an entity via a edata-reference connection.
 *
 * edata-reference connections will notify the entity, when the shape changes.
 */
@Injectable()
@Command()
export class AddEDataRefToShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeId: string,
        eDataId: string,
        entityId: string,
        isSet: boolean,
    };

    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected modelChangeUtils: ModelChangeUtils,
        state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
        this.state = state;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        this.modelChangeUtils.addEDataRefToShape(
            this.changeModel,
            this.data,
        );
    }
}

Object.defineProperty( AddEDataRefToShape, 'name', {
    value: 'AddEDataRefToShape',
});
