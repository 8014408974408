import { Injectable } from '@angular/core';
import { Observable, empty, BehaviorSubject, concat } from 'rxjs';
import { CommandScenario, CommandService, EventSource, StateService } from 'flux-core';

/**
 * This is the history manager that keeps track of changes that
 * happen during a user session. This service knows about all actions,
 * that cause changes to data (model changes) that happen during a
 * single session. It also acts as the entry point for undoing and redoing
 * such actions. If any undo/redo operations is being performed, it needs
 * to be done through the session history manager.
 *
 * This service works in conjuction with {@link DocumentChange} command to
 * keep track of event executions.
 *
 * @author  Ramishka
 * @since   2018-08-23
 *
 */
@Injectable()
export class SessionHistoryManager {

    /**
     * Subject that emits true as soon as undo events are available.
     * Emits false initially and false each time there are no undo event.
     */
    public canUndo: BehaviorSubject<boolean>;

    /**
     * Subject that emits true as soon as redo events are available.
     * Emits false initially and false each time there are no redo events.
     */
    public canRedo: BehaviorSubject<boolean>;

    /**
     * A list of event ids that can be undone
     */
    protected undoEvents: string[];

    /**
     * A list of user event ids that can be undone
     */
    protected undoUserEvents: string[];

    /**
     * A list of event ids that can be re-done
     */
    protected redoEvents: string[];

    /**
     * This object is to store edata events
     * { 'eventId': 'eDataId' }
     */
    protected edataEvents: { [eventId: string]: string } = {};

    /**
     * A list of user event ids that can be re-done
     */
    protected redoUserEvents: string[];

    private updateUserStack = false;
    private isLastEventTransient = false;

    constructor( protected commandService: CommandService,
                 protected state: StateService<any, any> ) {
        this.initialize();
    }

    private get currentDiagram (): string {
        return this.state.get( 'CurrentDiagram' );
    }

    /**
     * Undoes the last undoable action.
     * @return observable which completes when all commands related to the operation
     * has finished executing. Empty observable is returned if there are no undoable actions.
     */
    public undo(): Observable<any> {
        if ( this.undoUserEvents.length > 0 ) {
            const lastUserEvent = this.undoUserEvents.pop();
            let lastEvent: string;
            const undoActions: Observable<any>[] = [];
            this.updateUserStack = true;
            do {
                lastEvent = this.undoEvents.pop();
                if ( this.edataEvents[ lastEvent ]) {
                    undoActions.push( this.commandService.undo( lastEvent, this.edataEvents[ lastEvent ]));
                    delete this.edataEvents[ lastEvent ];
                } else {
                    undoActions.push( this.commandService.undo( lastEvent, this.currentDiagram ));
                }
            } while ( lastEvent !== lastUserEvent );
            return concat( ...undoActions );
        }
        return empty();
    }

    /**
     * Redoes the last redoable action.
     * @return observable which completes when all commands related to the operation
     * has finished executing. Empty observable is returned if there are no redoable actions.
     */
    public redo(): Observable<any> {
        if ( this.redoUserEvents.length > 0 ) {
            const lastUserEvent = this.redoUserEvents.pop();
            let lastEvent: string;
            const redoActions = [];
            this.updateUserStack = true;
            do {
                lastEvent = this.redoEvents.pop();

                if ( this.edataEvents[ lastEvent ]) {
                    redoActions.push( this.commandService.redo( lastEvent, this.edataEvents[ lastEvent ]));
                    delete this.edataEvents[ lastEvent ];
                } else {
                    redoActions.push( this.commandService.redo( lastEvent, this.currentDiagram ));
                }
            } while ( lastEvent !== lastUserEvent );
            return concat( ...redoActions );
        }
        return empty();
    }

    /**
     * Updates the undo and redo stacks when a command event executes.
     * This method is invoked by the {@link DocumentChange} execute method whenever
     * an execution, undo or redo happens.
     * In this method, the canUndo and canRedo subjects will also emit true if there
     * are events available or emit false if there are none.
     * @param eventId - id of the even being executed
     * @param scenario - command scenario the event is being executed under
     */
    public recordExecution(
        eventId: string,
        scenario: CommandScenario,
        source: EventSource = EventSource.USER,
        type: string = 'document',
        resourceId?: string,
    ) {

        if ( type === 'edata' ) {
            this.edataEvents[ eventId ] = resourceId;
        }
        if ( scenario === CommandScenario.EXECUTE ) {
            this.undoEvents.push( eventId );
            if ( !this.isLastEventTransient && ( source === EventSource.USER || source === EventSource.TRANSIENT )) {
                this.undoUserEvents.push( eventId );
                this.redoEvents.length = 0;
                this.redoUserEvents.length = 0;
            }
            this.isLastEventTransient = source === EventSource.TRANSIENT;
        } else if ( scenario === CommandScenario.UNDO ) {
            this.redoEvents.push( eventId );
            if ( this.updateUserStack ) {
                this.redoUserEvents.push( eventId );
                this.updateUserStack = false;
            }
        } else if ( scenario === CommandScenario.REDO ) {
            this.undoEvents.push( eventId );
            if ( this.updateUserStack ) {
                this.undoUserEvents.push( eventId );
                this.updateUserStack = false;
            }
        }

        this.canUndo.next( this.undoUserEvents.length > 0 );
        this.canRedo.next( this.redoUserEvents.length > 0 );
    }

    protected reset() {
        this.undoEvents = [];
        this.redoEvents = [];
        this.undoUserEvents = [];
        this.redoUserEvents = [];
        this.edataEvents = {};
        this.canUndo = new BehaviorSubject( false );
        this.canRedo = new BehaviorSubject( false );
    }

    protected initialize() {
        this.state.changes( 'CurrentDiagram' ).subscribe({
            next: () => this.reset(),
        });
    }
}
