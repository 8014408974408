import { Injectable, ViewContainerRef } from '@angular/core';
import { ComponentFactoryResolver, Injector,
    ComponentFactory, ComponentRef } from '@angular/core';

/**
 * DynamicComponentService contains the helper functionality to create the components,
 * append and remove a given component within the parent element given.
 *
 * @author  gobiga
 * @since   2017-11-15
 */

@Injectable()
export class DynamicComponentService {

    constructor( protected componentFactoryResolver: ComponentFactoryResolver,
                 protected injector: Injector ) {}


    /**
     * Creates a new component.
     * @param type  The type of the component to create.
     */
    public createComponent( type: any, viewContainerRef: ViewContainerRef ): ComponentRef<any> {
        const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory( type );
        return viewContainerRef.createComponent( componentFactory, viewContainerRef.length, this.injector );
    }

    /**
     * Creates a new component and returns the componentRef of it.
     * The component will not be added to the view utill it's inserted.
     * @param type  The type of the component to create.
     * @returns ComponentRef<any> The component ref of the created component
     */
    public makeComponent( type: any ): ComponentRef<any> {
        const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory( type );
        return componentFactory.create( this.injector );
    }

    /**
     * Inserts the component to the given viewContainer at the given index
     * If the index is not given, the component will be appended
     * @param type  The type of the component to create.
     * @returns ComponentRef<any> The component ref of the created component
     */
    public insert( viewContainer: ViewContainerRef, cmp: ComponentRef<any>, index?: number ) {
        viewContainer.insert( cmp.hostView, index );
        cmp.changeDetectorRef.markForCheck();
    }

    /**
     * Removes a given component element within a given parent element and destroy it.
     */
    public remove( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ) {
        const index = viewContainer.indexOf( cmp.hostView );
        if ( index !== -1 ) {
            viewContainer.remove( index );
            cmp.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Detaches a view from the given viewContainer without destroying it.
     */
    public detachComponent( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ) {
        const index = viewContainer.indexOf( cmp.hostView );
        if ( index !== -1 ) {
            viewContainer.detach( index );
            cmp.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Checks if a given component is already added to a given view container.
     * @param viewContainer - view container to check against
     * @param cmp - component to check for
     * @return true if the container is added
     */
    public contains ( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ): boolean {
        if ( viewContainer && cmp ) {
            return ( viewContainer.indexOf( cmp.hostView ) !== -1 ? true : false );
        }
        return false;
    }

    /**
     * Sets the input values that are passed in, to the inputs of the component.
     * @param componentInstance - Instance of the component to which the inputs will be set to
     * @param inputs - Object with inputs and their values
     */
    public setInputs( componentInstance: any, inputs: { [inputName: string]: any }) {
        if ( inputs ) {
            Object.keys( inputs ).forEach( input => {
                componentInstance[input] = inputs[input];
            });
        }
    }


}
