import { Injectable } from '@angular/core';
import { AbstractHttpCommand, AppConfig, Command, CommandInterfaces } from 'flux-core';
import { ImportedFile } from '../../../framework/file/imported-file';
import { tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { ConvertedLucidSVG } from 'apps/nucleus/src/framework/file/converted-lucid-svg';

/**
 * This command is used upload Lucid diagram SVG to server and convert it to
 * smaller SVG shapes. It uploads the diagram file and returns the shapes data with extracted smaller SVGs.
 *
 * Input data format:
 *  {
 *      files: true,
 *  }
 *
 * @since   2023-06-12
 * @author  Rasekaran
 */
@Injectable()
@Command()
export class ConvertLucidSVG extends AbstractHttpCommand  {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    constructor() {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'EXTERNAL_DIAGRAM_CONVERT_URL' );
    }

    /**
     * Converts imported visio file to base64 string and add it as 'diagram' to command data.
     * This prepared data will be used by the rest command.
     */
    public prepareData(): any {
        if ( this.data && this.data.files && this.data.files.length > 0 ) {
            return ( this.data.files[ 0 ] as ImportedFile ).getAsDataURL().pipe(
                tap( base64 => {
                    const arr = base64.split( ',' );
                    this.data = { diagram: arr[ arr.length - 1 ] };
                }),
            );
        }

    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

    /**
     * Prepare converted shapes to add to current diagram
     * @param response Convert request's response data.
     */
    public executeResult( response: any ): Observable<{ files: ConvertedLucidSVG[] }> {

        const files = (( response.shapeSVGs || []) as any[]).map( shapeSVG =>
                                new ConvertedLucidSVG( shapeSVG, shapeSVG.position ));
        this.data.files = files;
        return of({ files });
    }

}

Object.defineProperty( ConvertLucidSVG, 'name', {
    value: 'ConvertLucidSVG',
});
