import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { EMPTY } from 'rxjs';
import { TaskModel } from '../task.mdl';


/**
 * This command is to move diagram tasks from one folder to another.
 * e.g. If a diagram is moved to another folder, all the tasks of the diagram should be moved to the new folder.
 */
@Injectable()
@Command()
export class MoveTasks extends AbstractMessageCommand {

    /**
     * This command is sent to the server.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        destinationProjectId?: string;
        diagramIds?: string;
    };

    constructor( protected datastore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    prepareData() {
        return true as any;
    }

    execute() {
        if ( this.data.destinationProjectId && this.data.diagramIds ) {
            return true;
        }
        return false as any;
    }

    public executeResult( ) {
        if ( this.resultData?.tasks?.length > 0 ) {
            this.datastore.insert( TaskModel, this.resultData.tasks );
        }
        return EMPTY;
    }
}

Object.defineProperty( MoveTasks, 'name', {
    value: 'MoveTasks',
});

