import { Command, StateService, CommandInterfaces, AbstractNotification, NotificationType, NotifierController } from 'flux-core';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { DataStore } from 'flux-store';
import { PresentationModel } from '../model/presentation.mdl';
import { PresentationHelpers } from '../presentation-helpers';
import { tap, take, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable()
@Command()
/**
 * This command will create a new presentation and name it appropriately
 */
export class CreatePresentation extends AbstractMessageCommand {
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    public data: {
        presentation: {
            id: string,
            projectId: string,
            userId?: string,
            name?: string,
        },
    };

    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        if ( !this.data.presentation.projectId || this.data.presentation.projectId === 'home' ) {
            return;
        }
        if ( !this.data.presentation.userId ) {
            this.data.presentation.userId = this.state.get( 'CurrentUser' );
        }
        if ( !this.data.presentation.name ) {
            return this.dataStore.findRaw( PresentationModel, { projectId: this.data.presentation.projectId }).pipe(
                take( 1 ),
                filter( slides => !!slides ),
                map( slides => slides.filter( s =>
                    s.userId === this.data.presentation.userId || !s.isPrivate ||
                    ( s.collaborators && Object.keys( s?.collaborators ).includes( this.data.presentation.userId )))),
                map( slides => slides.filter( s => !s.isDeleted )),
                tap( p => {
                    this.data.presentation.name = 'Presentation ' +
                    PresentationHelpers.generateName( p.length );
                }),
            );
        }
    }

    public execute() {
        this.resourceId = this.data.presentation.projectId;
        return this.dataStore.insert( PresentationModel, this.data.presentation );
    }

    public executeResult( response: any ) {
        const data = response.presentation;
        return this.dataStore.insert( PresentationModel, data );
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        this.showErrorNotification();
        return this.dataStore.remove( PresentationModel, { id: this.data.presentation.id });
    }

    protected showErrorNotification() {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_PRESENTATION' ),
                description: this.translate.instant(
                    'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_PRESENTATION_DETAILS' ),
                autoDismiss: true,
            },
        };
        this.notifierController.show(
            'FOLLOW_SPOTLIGHT', AbstractNotification, NotificationType.Error, options, false );
    }

}

Object.defineProperty( CreatePresentation, 'name', {
    value: 'CreatePresentation',
});
