import { AbstractModel } from 'flux-core';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionStatus, IAbstractSubscription } from './subscription-type';

/**
 * An interface for subscription status.
 */
export interface ISubscriptionStatus {

    /**
     * Subscription status.
     * Maps to one of the values in {@link SubscriptionStatus} enum
     */
    subStatus: SubscriptionStatus;

    /**
     * Any errors that occured during subscription status change.
     * This is only set when there is an error.
     */
    error?: Error;
}

/**
 * AbstractSubscription is the abstract class all subscriptions should extend.
 */
export abstract class AbstractSubscription implements IAbstractSubscription {

    /**
     * This is a behavior subject that emits when the subscriptions status changes.
     * An external component wanting to know about the status of this subscription
     * may use this subject to listen to changes or to obtain the current value
     * ( or any errors that occured during status change).
     */
    public status: BehaviorSubject<ISubscriptionStatus>;

    /**
     * Consrtructor.
     * Initializes sub status as created.
     */
    constructor() {
        this.status = new BehaviorSubject({ subStatus: SubscriptionStatus.created });
    }

    /**
     * subscription is the name of the subscription which can be one of the pre
     * defined subscriptions provided by Neutrino.
     * More Info: https://sites.google.com/a/cinergix.com/neutrino/subscriptions
     */
    abstract get subscription(): string;

    /**
     * resourceId is the subscription is associated to. On Model type subscriptions
     * this field will contain the id of the module the subscription subscribes to.
     * On list type subscriptions, this refers to the parent model id subscribed
     * models will come under.
     */
    abstract get resourceId(): string;

    /**
     * modelType is the model class which will be used by the subscription.
     */
    abstract get modelType(): typeof AbstractModel;

    /**
     * topic is used to uniquely identify a subscription on the application.
     */
    public get topic(): string {
        return `${this.subscription}.${this.resourceId}`;
    }

    /**
     * A getter to synchronously retrieve the currently set status for this subscription.
     */
    public get currentStatus(): { subStatus: SubscriptionStatus, error?: Error} {
        return this.status.value;
    }

    /**
     * updateStatus hook is called when the subscription status changes.
     */
    public updateStatus( status: SubscriptionStatus.errored, error: Error );
    public updateStatus( status: SubscriptionStatus.started | SubscriptionStatus.completed );
    public updateStatus( status: SubscriptionStatus, error?: Error ) {
        this.status.next({ subStatus: status, error });
        if ( status === SubscriptionStatus.completed ) {
            this.status.complete();
        }
    }

}

