import { Router } from '@angular/router';
import { Command, AbstractCommand, CommandInterfaces } from './abstract.cmd';
import { InitializationChainStatus } from '../controller/initialization/initialization-chain-status';

/**
 * This is an abstraction for commands that are fired by the initialization sequence.
 * These are simple ICommands that do no have additional execution steps.
 * The data passed into these commands need to be the initialization chain status.
 *
 * To read more about the initialization sequence, refer to {@link InitializationChainStatus}
 * and {@link InitializationSequenceController}
 * @author  Ramishka
 * @since   2018-02-02
 */
@Command()
export abstract class AbstractInitializationCommand extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    public static get dataDefinition(): {}  {
        return {
            status: true, // status of the initialization sequence
        };
    }

    constructor ( protected router: Router ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Number of milliseconds to wait before execute step finishes.
     * Zero or negative means infinite wait.
     */
    public get executeTimeout(): number {
        return 45000; // 45 seconds
    }

    /**
     * Navigates to a route as determined by the inputer parameters in
     * the initialization chain status.
     * @param status chain status
     */
    protected navigateToRoute( status: InitializationChainStatus ) {
        let queryParams = '';
        if ( status.states.queryParams ) {
            queryParams = '?' + status.states.queryParams;
        }
        if ( status.input.resourceId ) {
            this.router.navigateByUrl( '/' + status.input.resourceId + '/' + status.input.action + queryParams );
        } else {
            this.router.navigateByUrl( '/' + status.input.action + queryParams );
        }
    }
}
