import { Injectable } from '@angular/core';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import { AbstractCommand, Command, ModalController, StateService } from 'flux-core';
import { IDialogBoxData, PlanPermission } from 'flux-definition';
import { PlanPermManager, UpgradeDialogType, UpgradeDialogWindow } from 'flux-user';
import { LibraryList } from '../../ui/temp-add-libs-menu/library-list';

@Injectable()
@Command()
export class CheckPremiumShapeLimitations extends AbstractCommand {

    public data: {
        shapes: { [key: string]: ShapeModel },
    };

    constructor(
        protected libraryList: LibraryList,
        protected planPermManager: PlanPermManager,
        protected modalController: ModalController,
        protected state: StateService<any, any>,
    ) {
        super();
    }

    public execute() {

        const shapes = this.data.shapes;
        for ( const shapeId in shapes ) {
            const currentShape = shapes[shapeId];
            const currentShapeLibraryId = currentShape.defId.split( '.' )[1];

            const isPremiumShape = this.libraryList.belongsToPremiumGroup( currentShapeLibraryId );

            if ( isPremiumShape && !this.planPermManager.check([ PlanPermission.CREATELY_PREMIUM_LIBRARIES ])) {
                this.showUpgradeDialog();
                throw new Error( 'Permission is denied' );
            }
        }

        this.resultData = this.data;
        return true;
    }

    /**
     * Showing the upgradeDialog
     */
    protected showUpgradeDialog() {

        const dialogData = {
            id: PlanPermission.CREATELY_PREMIUM_LIBRARIES,
            iframeType: UpgradeDialogType.PremiumShape,
            buttons: [
                {
                    type: 'upgrade',
                    clickHandler: () => {},
                },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        this.modalController.show( UpgradeDialogWindow, {
            inputs: {
                dialogData,
            },
        });
    }

}

Object.defineProperty( CheckPremiumShapeLimitations, 'name', {
    value: 'CheckPremiumShapeLimitations',
});
