import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * GetShapeContainer
 * This command will return containers ids of given shapes
 *
 *  data: {
 *      shapeIds: string[],
 *  }
 *
 *  resultData: {
 *      [shapeId: string]: string,
 *  }
 *
 */
@Injectable()
@Command()
export class GetShapeContainer extends AbstractDiagramChangeCommand  {

    public data: {
        shapeIds: string[],
    };

    /**
     * Get parent ids for each shape
     */
    public prepareData() {
        this.resultData = {};
        for ( const shapeId of this.data.shapeIds ) {
            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
            if ( shape.type === 'connector' ) {
                continue;
            }
            const container = shape.getParent( this.changeModel );
            if ( container ) {
                this.resultData[ shapeId ] = container.id;
            }
        }
    }
}

Object.defineProperty( GetShapeContainer, 'name', {
    value: 'GetShapeContainer',
});
