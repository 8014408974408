import { AbstractCommand, Command, StateService, CommandInterfaces, Rectangle } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { map, switchMap, take } from 'rxjs/operators';
import { empty, Observable } from 'rxjs';
import { IPoint2D } from 'flux-definition';
import { AbstractShapeModel, DiagramDataModel, IDiagramLocator } from 'flux-diagram-composer';

/**
 * This command will return the zoom level
 * and pan for the current diagram to fit
 * the screen.
 *
 * @author Vinoch
 * @since 11-11-2021
 */
@Injectable()
@Command()
export class GetDiagramFitSize extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor( protected state: StateService<any, any>,
                 protected ll: DiagramLocatorLocator ) {
        super()/* istanbul ignore next */;
    }

    protected get locator() {
        return this.ll.forCurrentObserver( true ) as Observable<IDiagramLocator<DiagramDataModel, AbstractShapeModel>>;
    }

    public execute(): Observable<any> {
        const viewPort: Rectangle = this.state.get( 'DiagramViewPort' );
        return this.getFitToScreenZoomPan( viewPort ).pipe(
            take( 1 ),
            map( zoomAndPan => {
                this.resultData = {
                    level: zoomAndPan.zoomLevel,
                    x: zoomAndPan.pan.x,
                    y: zoomAndPan.pan.y,
                };
            }),
        );
    }

    /**
     * This funciton returns an Observable that that emits the zoom and pan delta
     * values to fit the diagram to the specified screensize. Also this observable emits only one time.
     * @param screen: Rectangle     The screen to fit the diagram into
     */
     protected getFitToScreenZoomPan( screen: Rectangle ): Observable<{ zoomLevel: number, pan: IPoint2D } | null> {
        if ( !this.state.get( 'CurrentDiagram' )) {
            return empty();
        }
        return this.locator.pipe(
            switchMap( locator => locator.getDiagramOnce()),
            map( diagram => {
                if ( !diagram.hasShapes ) {
                    return null;
                }
                const viewport: Rectangle = screen.pad( 0 );
                const diagramBounds: Rectangle = diagram.getAllFrames().length > 0 ?
                            diagram.getBounds([
                                this.getFrameIdHasMinFrameIndex( diagram.getAllFrames()),
                            ]) : diagram.getBounds();
                const zoomLevel = this.getZoomToViewPortLevel( diagramBounds, viewport );
                const pan = {
                    x: viewport.centerX - diagramBounds.centerX * zoomLevel,
                    y: viewport.centerY - diagramBounds.centerY * zoomLevel,
                };
                return { zoomLevel, pan };
            }),
        );
    }

    /**
     * Return frame shape id which has minimum frame index.
     * If there are multiple frames with same minimum frame index value,
     * then return a random frame id from the given frames.
     * @param frames Set of frames
     * @returns Frame Id with minimum index
     */
    protected getFrameIdHasMinFrameIndex( frames: AbstractShapeModel[]): string {
        const minIndexFrames = frames.filter( frame => frame.data && frame.data.frameIndex &&
                frame.data.frameIndex.value === Math.min( ...frames.map( o => o.data.frameIndex.value )));
        return minIndexFrames.length === 0 ? frames[ 0 ].id :
                minIndexFrames[ Math.floor( Math.random() * minIndexFrames.length ) ].id;
    }

    /**
     * This funciton returns a zoom level to zoomOut the given diagram to
     * fit the given screen size. If the diagram is already inside the screen,
     * zoomLevel will be 1 as there's no need to zoomIn.
     * @param diagramBounds: Rectangle     The diagram bounds
     * @param screen: Rectangle            The screen to fit the diagram into
     */
    protected getZoomToViewPortLevel( diagramBounds: Rectangle, screen: Rectangle ) {
        // Calculate the zoom level to fit the diagram to the screen, this zoomlevel will be 1 or less than 1.
        const deltaX = diagramBounds.width - screen.width;
        const deltaY = diagramBounds.height - screen.height;
        let zoomLevel = 1;
        if ( deltaX / screen.width < deltaY / screen.height && deltaY > 0 ) {
            zoomLevel =  screen.height / diagramBounds.height;
        } else if ( deltaX > 0 ) {
            zoomLevel = screen.width / diagramBounds.width ;
        }
        return zoomLevel;
    }
}

Object.defineProperty( GetDiagramFitSize, 'name', {
    value: 'GetDiagramFitSize',
});

