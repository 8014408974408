/**
 * Command Error is a custom error used to identify and handle errors
 * across the command framework.
 *
 * @author  Gobiga
 * @since   2016-03-17
 *
 */
export class CommandError extends Error {
    constructor ( message: string ) {
        super()/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, CommandError.prototype );
        this.message = message;
        this.name = 'CommandError';
    }
}
