/**
 * This enum has the state that the
 * Global search widget/component can have.
 */
export enum GlobalSearchState {
    OPEN = 'open',
    CLOSE = 'close',
}

/**
 * This enum has the scope that the
 * Global search widget/component can have.
 */
export enum GlobalSearchScope {
    DOCUMENT = 'document',
    EDATA = 'edata',
    GLOBAL = 'global',
    COMMAND = 'command',
}
