import { StateService } from '../controller/state.svc';

/**
 * This file contains states, types and enums used with StateService in different context
 * common to the app ui features.
 */

/**
 * Expected state data for the LoadingIndicatorState state
 */
export interface ILoadingIndicatorState {
    main?: boolean;
    logout?: boolean;
    description?: string;
    subtext?: string;
}

/**
 * Expected state data for the DataLoadingIndicatorState state
 */
export interface IDataLoadingIndicatorState {
    main?: boolean;
    logout?: boolean;
    description?: string;
    subtext?: string;
}

/**
 * StateService representation for loading indicators
 */
export class LoadingIndicatorState extends StateService
    < 'LoadingIndicatorState'|'DataLoadingIndicatorState',
    ILoadingIndicatorState|IDataLoadingIndicatorState > {}

/**
 * The various states of the sidebar in relation to it's display size.
 * The three main states of the sidebar are 'Opened', 'Closed' and 'Expanded'.
 * Other states are intermediary states.
 */
export enum SidebarState {
    /**
     * Sidebar is opening from closed state.
     */
    Opening = 'opening',

    /**
     * Sidebar is open.
     */
    Open = 'open',

    /**
     * The expanded state of the sidebar where panels can show more content.
     */
    Expanded = 'expanded',

    /**
     * Sidebar is closing.
     */
    Closing = 'closing',

    /**
     * Sidebar is closed.
     */
    Closed = 'closed',

    /**
     * No panels are attached to the sidebar.
     */
    None = 'none',
}

