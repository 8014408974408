import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import * as innerText from '@creately/inner-text';
import { KeyCode } from 'flux-definition';

/**
 * The email validation regex as per the RFC 5322
 */

export const EMAIL_VALIDATION_REGEX: RegExp = new RegExp ([ '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:',
                                                            '\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.',
                                                            '[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
                                                            '[a-zA-Z]{1,2}[a-zA-Z0-9-]*))$' ].join( '' ));

/**
 * The email validation regex as per the RFC 5322
 */

/**
 * This component is an email input field which extends the ValidatableInput.
 * This component helps to show the exclamation icon on validation errors and
 * it also helps to pass error message to ErrorBar component.
 *
 * @author  Thisun
 * @since   2016-04-06
 */
@Component({
    selector : 'input-emails',
    templateUrl: './multiple-emails-input.html',
    styleUrls: [ './multiple-emails-input.scss' ],
})

export class MultipleEmailsInput {


    /**
     * Size of the input.
     */
    @Input() public size: string;

    /**
     * Placeholder of the input.
     */
    @Input() public placeholder: string;

    /**
     * Enable the input.
     */
    @Input() public enabled: boolean;

    /**
     * Get a Reference to the content editable div to manipulte it's
     * innterText and innterHTML
     */
    @ViewChild( 'input' )
    protected input: ElementRef;

    /**
     * Holds all the valid and invalid emails,
     * invalid emails are flagged by wrapping span
     * element.
     */
    protected inputElements: string[];

    constructor() { }

    /**
     * Returns true if the text input contains at least
     * one invalid email or the emails are empty, returns
     * false if the input contains only valid emails
     *
     * @return Array<string>    All valid emails
     */
    public get isInvalid(): boolean {
        let emails: Array<string> = this.inputEmails;
        if ( !emails ) {
            return true;
        }
        // Remove empty elements
        emails = emails.filter( Boolean );
        if ( emails.length === 0 ) {
            return true;
        }

        const invalidEmailFound = emails.find( email => !this.isEmailValid( email ));
        if ( invalidEmailFound ) {
            return true;
        }

        return false;
    }

    /**
     * Returns all the available valid emails only.
     *
     * @return Array<string>    All valid emails
     */
    public get emails(): Array<string> {
        const emails: Array<string> = this.inputEmails.filter( email => this.isEmailValid( email ));
        if ( emails.length === 0 ) {
            return;
        }

        return emails;
    }

    /**
     * This getter returns the ElementRef of input as an HTMLElement.
     */
    protected get textInput(): HTMLElement {
        if ( this.input ) {
            return  this.input.nativeElement;
        }
    }

    /**
     * Returns all the valid and invalid email inputs, returns undefined if the
     * text input is empty or undefined
     *
     * @return Array<string>    All valid and invalid email inputs
     */
    protected get inputEmails(): Array<string> {
        if ( !this.input ) {
            return;
        }

        let emails: string[];
        emails = innerText( this.textInput ).split( ',' );

        return emails.map( email => email.trim());
    }


    /**
     * Handles the keydown event when user is typing in the multi email input.
     * @param event keydown event to be handled
     */
    public handleKeyDownEvent( event: any ) {
        if ( event.keyCode === KeyCode.Enter ) {
            event.preventDefault();
        }
        this.handleInputChange( event );
    }

    /**
     * This function handles the event when email addresses are being pasted into the multi email input.
     * @param event clipboard event to be handled
     */
    public handlePasteEvent( event: any ) {
        event.preventDefault();
        const text = event.clipboardData.getData( 'text/plain' );
        event.target.innerHTML = text;
        this.handleInputChange( event );
        this.placeCaretAtEnd();
    }

    /**
     * Removes all entered emails from the text input
     */
    public clearEmailInputs() {
        this.textInput.innerHTML = '';
    }

    /**
     * This function handles the multi email input data when the input has been changed.
     * If the last character entered in the changed input is a comma(','), validateEmails will be called.
     * @param event input change event to be handled
     */
    protected handleInputChange( event: any ) {
        // Proper solution is identifying based on the keycode but this solution is ot working on android.
        // Therfore getting the last character entered and validating based on that.
        const textInput = innerText( event.target ) + event.key;
        if ( textInput && textInput.charAt( textInput.length - 1 ) === ',' ) {
           this.validateEmails();
        }
    }

    /**
     * Validates all the emails available and mark the invalid
     * emails in red.
     *
     */
    protected validateEmails() {
        this.multipleEmailsValidator();
        this.updateTextField();
        this.placeCaretAtEnd();
    }

    /**
     * Updates the text field after each email adress is entered
     */
    protected updateTextField() {
        this.textInput.innerHTML = '';
        if ( this.inputElements ) {
            this.textInput.innerHTML = this.inputElements.join( ',' );
        }
    }

    /**
     * Split the user input to a string array and validate each element
     * and wrap the invalid emails with span element.
     */
    protected multipleEmailsValidator() {
        this.inputElements = innerText( this.textInput ).split( ',' );

        this.inputElements = this.inputElements.map( email => {
            if ( email && ! this.isEmailValid( email.trim())) {
                return '<span class="invalid-email">' + email + '</span>';
            } else {
                return email;
            }
        });
    }

    /**
     * This function is used to move the caret position to end after
     * the content of the contentEditable div is dynamacaly updated.
     */
    protected  placeCaretAtEnd() {
        this.textInput.focus();
        const range = this.createRange();
        range.selectNodeContents( this.textInput );
        range.collapse( false );
        const selection = this.getSelection();
        selection.removeAllRanges();
        selection.addRange( range );
    }

    /**
     * In order to make tests easier and
     * to be called in placeCaretAtEnd method
     */
    protected  createRange() {
        return document.createRange();
    }

    /**
     * In order to make tests easier and
     * to be called in placeCaretAtEnd method
     */
    protected getSelection() {
        return window.getSelection();
    }

    /**
     * Return true is the email is valid.
     */
    protected isEmailValid( email: string ): boolean {
        if ( !email ) {
            return false;
        }
        return email.match( EMAIL_VALIDATION_REGEX ) != null;
    }

}

