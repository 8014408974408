import { Observable, merge, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AbstractCommand, CommandInterfaces, Command } from 'flux-core';
import { DataStore } from '../../model/data-store.svc';

/**
 * SubStoreModels
 *
 * SubStoreModels command stores a model using the data store service
 * which is available in command result data. The model type is also
 * taken from command result data which makes this different from
 * other commands which usually has a specific result data type.
 *
 * This command can be used by the subscription service to store models
 * received from sub type commands.
 */
@Injectable()
@Command()
export class SubStoreModels extends AbstractCommand {
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICollabCommand' ];
    }

    constructor( protected store: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public executeResult(): Observable<any> {
        const { data, type } = this.resultData;
        const observables: Observable<any>[] = [];
        // NOTE: There is a race condition here.
        // When the diagram model is available, it inserts the data
        // into index db and the diagram subscription will be triggered.
        // If the model static is not inserted at that time, static data
        // will be null.
        if ( data.model ) {
            observables.push( this.store.insert( type, data.model ));
        }
        if ( data.modelStatic ) {
            const modelStore = this.store.getModelStore( type );
            observables.push(
                from( modelStore.insertStatic( data.modelStatic )),
            );
        }
        if ( data.historyMeta ) {
            const modelStore = this.store.getModelStore( type );
            observables.push(
                from( modelStore.insertHistoryMetadata( data.historyMeta )),
            );
        }
        return merge( ...observables );
    }
}

Object.defineProperty( SubStoreModels, 'name', {
    writable: true,
    value: 'SubStoreModels',
});
