import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-collapsible-menu-item',
  template: `
    <div class="col-menu-item-container" [ngClass]="{ 'expanded': active, 'collapsed': !active }">
        <div class="col-menu-item-heading fx-no-selection">
            <div class="col-menu-item-heading-inner" (click)="handleClick()">
                <div class="col-menu-item-icon">
                    <svg class="nu-icon nu-icon-small nu-icon-collapse">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                    </svg>
                </div>
                <div class="col-menu-item-heading-title body fx-ellipsis">{{ title }}</div>
                <div class="col-menu-item-heading-subtitle body fx-ellipsis" *ngIf="subtitle">{{ subtitle }}</div>
            </div>
        </div>
        <div class="col-menu-item-content">
            <ng-content></ng-content>
        </div>
    </div>
  `,
  styleUrls: [ './app-collapsible-menu-item.scss' ],
})

/**
 * App Collapsible Menu Item which has a header and the content of a single panel.
 * Header displays the title and the icons. Content consists of custom
 * components.
 *
 * NOTE: This component needs to be always preferred over CollapsibleMenuItem since its light weight
 * This should be used for all scenario.
 */
export class AppCollapsibleMenuItem {
    @Input() active: boolean;
    @Input() title: string;
    @Input() subtitle: string;
    @Output() activeChange = new EventEmitter<boolean>();

    public handleClick() {
        this.active = !this.active;
        this.activeChange.emit( this.active );
    }

}
