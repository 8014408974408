<div class="modal-window-container fx-cover fx-center-all" #container>
    <div class="modal-window-inner" #window>
        <div class="modal-window-heading">
            <button class="whats-new-window-close-button btn-small nu-btn-icon lib-browser-close-btn" (click)="closeClick()">
                <svg class="nu-icon nu-icon-med nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
        </div>
        <iframe #upgradeDialogFrame [src]="iFrameUrl | safe" class="whats-new-window-iframe" allow="clipboard-write"></iframe>
        <div class="modal-window-footer">
            <div class="whats-new-footer-inner">
                <div>
                    <button (click)="seeRecentUpdatesClick()" class="btn-medium btn-secondary" >
                        <label>{{ translate.get('WHATS_NEW_WINDOW.SEE_ALL_RECENT_UPDATES')|async }}</label>
                    </button>
                </div>
                <div *ngIf="showDoNotShowButton">
                    <input (click)="doNotShowAgainClick()" type="checkbox" class="whats-new-window-checkbox"/>
                    <label>{{ translate.get('WHATS_NEW_WINDOW.DO_NOT_SHOW_THIS_TO_ME')|async }}</label>
                </div>
            </div>
        </div>
    </div>
</div>