import { IPosition, IPosition2D } from 'flux-definition';
import { IRectangle, ITransform } from 'flux-definition';
import { Point } from './point';
import { Matrix } from './matrix';
import { Rectangle } from './rectangle';
import { Line } from './line';
import { ILine2D, IPath } from 'flux-definition';

/**
 * Position is a helper class with methods to calculate positions
 * described in IPosition and IPosition2D.
 */
export class Position {
    /**
     * Calculates the value of given position on a single axis with
     * given range. When the position type is 'relative', the position
     * is calculated as given below:
     *
     * ........*.....a....*..........*....b....*........
     *       -0.1    0   0.1        0.9   1   1.1
     *
     * When the end value is larger than or equal to the start value,
     * fixed positions will be calcualetd as given below:
     *
     * ........*.....a....*..........*....b....*........
     *        a-x    a   a+x        b+x   b   b-x
     *
     * If the end value is smaller than the start value on given range,
     * fixed positions will be calcualetd as given below:
     *
     * ........*.....b....*..........*....a....*........
     *        b-x    b   b+x        a+x   a   a-x
     *
     */
    public static onAxis( pos: IPosition, a: number, b: number ): number {
        if ( pos.type === 'fixed-start' ) {
            return ( a > b ) ? a - pos.value : a + pos.value;
        } else if ( pos.type === 'fixed-end' ) {
            return ( a > b ) ? b + pos.value : b - pos.value;
        } else {
            return a + ( b - a ) * pos.value;
        }
    }

    /**
     * Calculates the point of given position on given rectangle.
     */
    public static onRect( pos: IPosition2D, _rect: IRectangle, transform?: ITransform ): Point {
        if ( !transform ) {
            const rect = transform ? Rectangle.from( _rect ).transform( transform ) : _rect;
            const x = this.onAxis( pos.x, rect.x, rect.x + rect.width );
            const y = this.onAxis( pos.y, rect.y, rect.y + rect.height );
            return new Point( x, y );
        }
        const matrix = Matrix.fromTransform( transform );
        const lineX = new Line(
            matrix.transformPoint( _rect.x, _rect.y ),
            matrix.transformPoint( _rect.x + _rect.width, _rect.y ),
        );
        const pointX = this.onLine( pos.x, lineX );
        const lineY = new Line( pointX, pointX.shift( transform.angle + 90, _rect.height * transform.scaleY ));
        return this.onLine( pos.y, lineY );
    }

    /**
     * Calcualtes the point of given position along given line.
     */
    public static onLine( pos: IPosition, line: ILine2D ): Point {
        if ( pos.type === 'fixed-start' ) {
            return Point.from( new Line( line.from, line.to ).splitByLength( pos.value, true ));
        } else if ( pos.type === 'fixed-end' ) {
            return Point.from( new Line( line.from, line.to ).splitByLength( pos.value, false ));
        } else {
            return Point.from( new Line( line.from, line.to ).split( pos.value ));
        }
    }

    /**
     * Calcualtes the point of given position along given path.
     */
    public static onPath( pos: IPosition, path: IPath ): Point {
        if ( pos.type === 'fixed-start' ) {
            return Point.from( path.splitByLength( pos.value, true ));
        } else if ( pos.type === 'fixed-end' ) {
            return Point.from( path.splitByLength( pos.value, false ));
        } else {
            return Point.from( path.split( pos.value, true ));
        }
    }

    /**
     * Returns the data required to locate the child in the container relative to the
     * container's cordinartes
     */
    public static getRelativeTransformData( container: any, child: any ) {
        if ( child.type === 'connector' ) {
            const connector = child;
            // All shapes without defaultBounds needs to be ignored e.g. connectors
            const _containerTb = container.defaultBounds.getTransformedPoints( container.transform );
            const _childTb = Rectangle.from( connector.bounds || {
                x: 0,
                y: 0,
                width: ( connector as any ).$$width,
                height: ( connector as any ).$$height,
            });

            const _baseXPoint = Line
                .from( _containerTb.topLeft.x, _containerTb.topLeft.y,
                    _containerTb.bottomLeft.x, _containerTb.bottomLeft.y )
                .perpendicularTo( _childTb );
            const _relativeX = Line.from( _baseXPoint.x, _baseXPoint.y, _childTb.x, _childTb.y ).length();

            const _baseYPoint = Line
                .from( _containerTb.topLeft.x, _containerTb.topLeft.y,
                    _containerTb.topRight.x, _containerTb.topRight.y )
                .perpendicularTo( _childTb );
            const _relativeY = Line.from( _baseYPoint.x, _baseYPoint.y, _childTb.left, _childTb.top ).length();
            return {
                relativeX: _relativeX,
                relativeY: _relativeY,
                relativeAngle: 0,
            };
        }

        const childShape = child;
        if ( !childShape.defaultBounds ) {
            return;
        }
        const containerTb = Rectangle.fromClientRect( container.defaultBounds ).getTransformedPoints({
            x: container.x,
            y: container.y,
            scaleX: container.scaleX || 1,
            scaleY: container.scaleY || 1,
            angle: container.angle || 0,
        });
        const childTb =  Rectangle.fromClientRect( childShape.defaultBounds ).getTransformedPoints({
            x: childShape.x,
            y: childShape.y,
            scaleX: childShape.scaleX || 1,
            scaleY: childShape.scaleY || 1,
            angle: childShape.angle || 0,
        });
        const relativeAngle = childShape.angle - container.angle || 0;

        const baseXPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y,
                containerTb.bottomLeft.x, containerTb.bottomLeft.y )
            .perpendicularTo( childTb.topLeft );
        const relativeX = Line.from( baseXPoint.x, baseXPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();

        const baseYPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y, containerTb.topRight.x, containerTb.topRight.y )
            .perpendicularTo( childTb.topLeft );
        const relativeY = Line.from( baseYPoint.x, baseYPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();
        return {
            relativeX,
            relativeY,
            relativeAngle,
        };
    }
}
