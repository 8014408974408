import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command } from 'flux-core';
import { DiagramInfoModel, DiagramInfoModelStore } from 'flux-diagram';
import { Observable } from 'rxjs';

/**
 * This is the command which changes the diagram owner and send
 * the request to the server.
 *
 * data: {
 *     diagramId id of the resource
 * }
 */
@Injectable()
@Command()
export class TransferDiagramOwnership extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            diagramId: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

    /**
     * executeResult
     * Update the collaborators with the response
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        return modelStore.replaceOwner( this.resourceId, response.owner );
    }
}

Object.defineProperty( TransferDiagramOwnership, 'name', {
    value: 'TransferDiagramOwnership',
});
