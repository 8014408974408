import { Injectable } from '@angular/core';
import { AppConfig, ResourceLoader } from 'flux-core';

/**
 * DiagramSnapshotService class contains code related to diagram snapshots (history items).
 * @author  Damith
 * @since   2020-09-08
 */
@Injectable()
export class DiagramSnapshotService {

    public constructor(
        private resourceLoader: ResourceLoader,
    ) {}

    protected get filesBaseUrl(): string {
        return AppConfig.get( 'FILE_API_BASE_URL' );
    }

    /**
     * Returns the history item raw data
     * @param diagramId id of the diagram
     * @param lastChangeId id of the history item
     * @returns observable that emits history item data
     */
    public fetchSnapshot( diagramId: string, lastChangeId: string ) {
        const snapshotUrl = `${this.filesBaseUrl}/1/1/snapshot/${diagramId}_${lastChangeId}`;
        return this.resourceLoader.load( snapshotUrl );
    }
}
