import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * This component displays the loader for the shape thumbnails
 * in the library panel.
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'shape-thumbnail-loader',
    templateUrl: './shape-thumbnail-loader.cmp.html',
    styleUrls: [ './shape-thumbnail-loader.scss' ],
})
export class ShapeThumbnailLoader {}
