import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * This StateChangeCommand commnd is to set the ShapeDataEditor state
 * that is used to control the ShapeDataEditor component
 */
@Injectable()
@Command()
export class OpenShapeDataEditor extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            open: true,
            shapeIds: false,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            ShapeDataEditor: {
                open: this.data.open,
                shapeIds: this.data.shapeIds,
            },
        };
    }

    public prepareData() {
        if ( this.data.open && this.state.get( 'OpenRightSidebar' ) === 'none' ) {
            this.state.set( 'OpenRightSidebar', 'shapeData' );
        } else if ( !this.data.open && this.state.get( 'OpenRightSidebar' ) === 'shapeData' ) {
            this.state.set( 'OpenRightSidebar', 'none' );
        }
    }

    public execute(): boolean {
        return true;
    }
}

Object.defineProperty( OpenShapeDataEditor, 'name', {
    value: 'OpenShapeDataEditor',
});
