import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Adds an entity to a shape.
 * Also checks if the document has a reference to the model, if not adds that as well.
 *
 * This usually happens in one of the follwing situations
 * 1. an entity is drag dropped in to the canvas and a shape is created from that
 * entity
 *
 * 2. a eDataTrigger=true shape is dropped to the canvas and an edataModel is active
 *
 * 3. a shape with eData present is added to a container context which will add additional parameters
 * to the containing shape dataItems which in turn will reflect on an EData model. This is the situation when
 * a single shape will have multiple EData references.
 *
 * 4. A new entity type is created. We create a new entity and bind it to the shape that the
 * type created from.
 *
 */
@Injectable()
@Command()
export class AddEntityToShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeId: string,
        eDataId: string,
        entityId: string,
        entityDefId?: string,
        eDataDefId?: string,
        data?: any, // data items to be copied to the shape
    };


    protected state: StateService<any, any>;

    /**
     * Inject state service.
     */
    constructor(
        protected mcu: ModelChangeUtils,
        protected ds: DiagramChangeService,
        state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
        this.state = state;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        let data = this.data;
        if ( !this.data.eDataDefId ) {
            data = {
                ...data,
                eDataDefId: this.state.get( 'projectEDataModels' )[this.data.eDataId].defId,
            };
        }
        this.mcu.addEntityToShape( this.changeModel, data );
    }
}

Object.defineProperty( AddEntityToShape, 'name', {
    value: 'AddEntityToShape',
});
