import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Command, CommandInterfaces, ImageLoader, NotifierController, StateService } from 'flux-core';
import { tap } from 'rxjs/operators';
import { DiagramCSVExporter } from '../export/diagram-csv-exporter';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { AbstractDiagramExportCommand } from './abstract-diagram-export-command.cmd';

/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsCsv extends AbstractDiagramExportCommand {
    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    public constructor(
        ll: DiagramLocatorLocator,
        resources: ImageLoader,
        notifierController: NotifierController,
        translate: TranslateService,
        state: StateService<any, any>,
        private csvExporter: DiagramCSVExporter,
    ) {
        super( ll, resources, notifierController, translate, state )/* istanbul ignore next */;
    }

    /**
     * Prepare the svg image data to be exported.
     */
    public prepareData() {
        return this.csvExporter.exportCSV().pipe(
            tap( data =>
                this.data = { content: data.data, name: data.name + '.csv', type: 'text/csv', format: 'file' }),
        );
    }

}

Object.defineProperty( ExportAsCsv, 'name', {
    value: 'ExportAsCsv',
});
