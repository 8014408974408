import { AbstractArrowHead, IDrawArrowheadOptions } from './arrow-head-abstract';
import { IGraphics } from 'flux-definition';

/**
 * PointerFilled arrow head entry class is one of the default arrow head types.
 */
/* istanbul ignore next */
export class PointerIndented extends AbstractArrowHead {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void {
        const bounds = options.bounds;
        const width = bounds.width / 1.15;
        const height = bounds.height;
        const cnavasDrawWidth = 10;

        // Check if drawing on the canvas or if drawing on the quick menu
        if ( options.bounds.width === cnavasDrawWidth ) {
            graphics.beginFill( options.style.lineColor );
            this.moveTo( graphics, { x: bounds.x - 5, y: bounds.y }, options.matrix );
            this.lineTo( graphics, { x: bounds.x - 5 - width, y: bounds.y - height / 2 }, options.matrix );
            this.lineTo( graphics, { x: bounds.x - 5 - width, y: bounds.y + height / 2 }, options.matrix );
            graphics.closePath();
        } else {
            graphics.beginFill( options.style.lineColor );
            this.moveTo( graphics, { x: bounds.x - 7, y: bounds.y }, options.matrix );
            this.lineTo( graphics, { x: bounds.x - 7 - width, y: bounds.y - height / 2 }, options.matrix );
            this.lineTo( graphics, { x: bounds.x - 7 - width, y: bounds.y + height / 2 }, options.matrix );
            graphics.closePath();
            graphics.endFill();

            this.moveTo( graphics, { x: bounds.x, y: bounds.y - height / 2 }, options.matrix );
            this.lineTo( graphics, { x: bounds.x, y: bounds.y + height / 2 }, options.matrix );
        }
    }
}

Object.defineProperty( PointerIndented, 'name', {
    value: 'PointerIndented',
});
