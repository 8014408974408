import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

/**
 * Apply the given modifier to the change model
 */
@Injectable()
@Command()
export class RemoveEntity extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entityIds: string[],
    };

    public prepareData() {
        this.data.entityIds.forEach( entityId => {
            delete this.changeModel.entities[entityId];
        });
        this.resultData = { applyLater: true };
    }

}

Object.defineProperty( RemoveEntity, 'name', {
    value: 'RemoveEntity',
});
