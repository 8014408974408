import { Injectable } from '@angular/core';

@Injectable()
export class EventManagerService {
    /**
     * Local storage key for diagram subscription
     * Contains JSON string of users and the diagram Ids and access timestamp as follows.
     *  {
     *      "userId1": {
     *          "Diagram": {
     *              "resourceId1": timestamp1,
     *              "resourceId2": timestamp2,
     *              ...
     *          }
     *      },
     *      "userId2": {
     *          "Diagram": {
     *              "resourceId3": timestamp3,
     *              "resourceId4": timestamp4,
     *              ...
     *          }
     *      },
     *      ...
     *  }
     */
    public static USER_DIAGRAM_SUBCRIBED_EVENT: string = 'user-diag-sub-event';

    public setLastLoggedSubscription( resourceType: string, userId: string, resourceId: string ) {
        const accessLogString = localStorage.getItem( EventManagerService.USER_DIAGRAM_SUBCRIBED_EVENT );
        let accessLog = {};
        if ( !!accessLogString ) {
            accessLog = JSON.parse( accessLogString );
        }
        if ( !accessLog[ userId ]) {
            accessLog[ userId ] = {};
        }
        if ( !accessLog[ userId ][ resourceType ]) {
            accessLog[ userId ][ resourceType ] = {};
        }
        accessLog[ userId ][ resourceType ][ resourceId ] = Date.now();
        localStorage.setItem( EventManagerService.USER_DIAGRAM_SUBCRIBED_EVENT, JSON.stringify( accessLog ));
    }

    public shouldTrackSubscribedEvent( resourceType: string, userId: string, resourceId: string ) {
        // For the moment only for diagram sub event is tracked.
        if ( userId && resourceId && resourceType === 'Diagram' ) {
            const accessTimeStr = this.getLastLoggedSubscription( resourceType, userId, resourceId );
            if ( !!accessTimeStr ) {
                return ( Date.now() - +accessTimeStr ) >= 86400000;
            }
            return true;
        }
        return false;
    }

    protected getLastLoggedSubscription( resourceType: string, userId: string, resourceId: string ) {
        const accessLogString = localStorage.getItem( EventManagerService.USER_DIAGRAM_SUBCRIBED_EVENT );
        if ( !!accessLogString ) {
            const accessLog = JSON.parse( accessLogString );
            if ( !!accessLog[ userId ] && !! accessLog[ userId ][ resourceType ]) {
                return accessLog[ userId ][ resourceType ][ resourceId ];
            }
        }
        return undefined;
    }
}
