import { ICursor } from './../../interaction/cursor-drawable.i';
import { InteractionSubContext } from './../../interaction/interaction-handler';
import { ShapeType } from 'flux-definition';
import { Container, Shape } from '@creately/createjs-module';

/**
 * This is the contianer that contains all the peieces that makes up
 * the shape view on the canvas. This class represents the basic most requirements
 * that are common to shape and connector drawing/view.
 */
export abstract class DisplayView extends Container {

    constructor( name: string, protected _type: ShapeType ) {
        super()/* istanbul ignore next */;
        this.name = name;
    }

    /**
     * Returns the shape type that this display object
     * belongs to.
     */
    public get type(): ShapeType {
        return this._type;
    }

    /**
     * Indicates if this display object is part of a ShapeView
     */
    public abstract get isShapeView(): boolean;

    /**
     * Indicates if this display object is part of a ConnectorView
     */
    public abstract get isConnectorView(): boolean;

    /**
     * Returns the cursor and tail defined for the given context.
     * The return value may be null if no cursor is availbale for the given context
     */
    public getCursor( contexts: InteractionSubContext[]): ICursor {
        // NOTE : To be overriden
        return null;
    }

    /**
     * NOTE:
     * This function converts shape instance to ICursorDrawable
     * by attaching the getCursor method.
     * The reason to do this is, getObjectsUnderPoint in easejs
     * stage returns only shapes ( not containers )
     */
    public convertToCursorDrawable( shape: Shape ) {
        ( shape as any ).getCursor = contexts => this.getCursor( contexts );
    }

}
