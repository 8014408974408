import { ShapeTextDataModel } from 'flux-diagram-composer';
import * as innerText from '@creately/inner-text';

/**
 * TextModel is the concrete version of a Shape Text and exends the TextModel
 * to inherit the common text functionalities.
 * This model will contain all data
 * processing and manipulation functionality needed for shape text.
 *
 * @author Thisun
 * @since 2018-01-16
 */
export class ShapeTextModel extends ShapeTextDataModel {

    /**
     * Returns the plain text by extracting text from the value property
     * and discards the styles
     * @override
     * FIXME This method should be removed after the usage of
     * value property is totally replaced by content property.
     */
    public get plainText(): string {
        if ( !this.content ) {
            return innerText( this.html ).trim();
        }
        let plaintext = '';
        for ( const block of this.content ) {
            plaintext += block.text;
        }
        return plaintext.trim();
    }
}
