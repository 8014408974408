import { Injectable } from '@angular/core';
import { AppConfig, IResponsibility } from 'flux-core';
import {
    InitializationChainStatus,
    IChainOutcome,
} from 'flux-core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserStatus } from '../model/user-info.mdl';
import { UserLocator } from '../user-locator.svc';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * This state use for redirect user to confirmation required page
 * if user is not confirm there email and try to access the app.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Sajeeva
 * @since   2021-09-08
 */
@Injectable()
export class EmailConfirmationResponsibility implements IResponsibility  {

    public name: string = 'EmailConfirmationResponsibility';

    constructor ( protected userLocator: UserLocator ) {}

    /**
     * Returns the user email confirmation state.
     * @param status - current status of the sequence
     * @return Observable which emits true if a current user email confirmation state
     */
    public checkState( status: InitializationChainStatus ): Observable<boolean> {
        return this.userLocator.getUserData().pipe(
            filter( userModel => !!userModel ),
            take( 1 ),
            map( user => (
                !user.emailToConfirm ||
                user.status === UserStatus.DEMO
            )),
        );
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        return [ 'TwoFactorAuthResponsibility' ];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        const isEmailConfirmed = status.states.EmailConfirmationResponsibility;
        if ( !isEmailConfirmed ) {
            return {
                type: 'redirect',
                action: AppConfig.get( 'SITE_URL' ) + 'email-confirmation-required',
                wait: false,
            };
        }
        return undefined;
    }

}
