import { AbstractCommand, StateService, Command, CommandInterfaces } from 'flux-core';
import { Injectable } from '@angular/core';
import { GlobalSearchState } from '../../ui/global-search/global-search.cmp';

/**
 * This command is responsible of invoking the global search component
 * and pass the search option to that.
 * A search option can be 'document' | 'command' | 'global'
 * Document search - Searches across all documents.
 * Command search - Will show the available commands to invoke by searching.
 * Global search - Provides a search across everthing including commands and documents.
 *
 * @author  Vinoch
 * @since   2021-03-16
 */

@Injectable()
@Command()
export class OpenGlobalSearch extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            scope: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            GlobalSearch: {
                scope: this.data.scope,
                state: GlobalSearchState.OPEN,
            },
        };
    }

    public prepareData() {
        if ( this.data.keyboardEvent ) {
            this.data.keyboardEvent.preventDefault();
        }
    }

    public execute(): boolean {
        return true;
    }
}

Object.defineProperty( OpenGlobalSearch, 'name', {
    value: 'OpenGlobalSearch',
});
