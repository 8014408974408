import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, debounceTime, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TemplateLibraryService {

    /**
     * Select correct shape library panel on FAB when template loads
     * Based on the template libraries (def.libraries[0].libs)
     * Diagram type has to be set through `Debugger` tool
     * @param state
     * @param def
     */
    selectCorrectLibraryPanel( state: any, def: any ): Observable<any> {
        return state.changes( 'CurrentLibraries' ).pipe(
            filter( x => !!x ),
            debounceTime( 100 ),
            take( 1 ),
            tap(() => {
                const newLibs = state.get( 'CurrentLibraries' );

                const technicalIds = newLibs
                    .filter( obj => obj.category === 'Technical' )
                    .map( obj => obj.id );

                const containsTechnicalLibs = def.some( arr => technicalIds.includes( arr[0]));

                if ( containsTechnicalLibs ) {
                    state.set( 'CurrentLibraryGroup', 'Technical' );
                }
            }),
        );
    }
}
