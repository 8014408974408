<div class="setup-database-dialog-container modal-window-container fx-cover fx-center-all" #window >
    <div class="setup-database-dialog-inner modal-window-inner" #windowInner>
        <div>
            <div class="setup-database-dialog-heading modal-window-heading">
                <h1 class="setup-database-dialog-header title">{{ 'SETUP_DATABASE_DIALOG.MODAL.HEADING' | translate }}</h1>
                <button class="btn-small nu-btn-icon setup-database-dialog-close"
                    (click)="closeWindow()">
                    <svg class="nu-icon nu-icon nu-icon-small nu-ic-close">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                    </svg>
                </button>
            </div>
            <p class="setup-database-dialog-description">{{ 'SETUP_DATABASE_DIALOG.MODAL.DESCRIPTION' | translate }}</p>
        </div>

        <div class="setup-database-dialog-content modal-window-content">
            <div class="setup-database-dialog-name-content">
                <label class="setup-database-dialog-name-lable" translate>SETUP_DATABASE_DIALOG.MODAL.LABEL_ONE</label>
                <input placeholder="{{'SETUP_DATABASE_DIALOG.MODAL.PLACEHOLDER' | translate}}" type="text" [readonly]="readonly | async" class="setup-database-dialog-name-text text-input" [(ngModel)]="dbName" />
            </div>
            <div class="setup-database-db-type-section">
                <label class="setup-database-db-type-lable" translate>SETUP_DATABASE_DIALOG.LABELS.DATABASE_TYPE</label>
                <abs-dropdown class="setup-database-db-type-container" #dropdown
                    [settings]="{ closeOnItemClicked: true, openOnHover: false, closeOnBlur: true, multiselectable: false }"
                    direction="top" alignment="right">
                    <simple-dropdown-button class="setup-database-db-type btn-small secondary" ddbutton [items]="defs">
                        <span>{{(selectedDef|async).name}}</span>
                        <svg class="nu-icon dropdown-arrow">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                        </svg>
                    </simple-dropdown-button>
                    <simple-dropdown-item dditem *ngFor="let def of defs" [item]="type" (click)="selectedDef.next(def)">
                        <div class="header-action-btns-dropdown-item fx-margin-bottom-15">
                            <span>{{def.name}}</span>
                        </div>
                    </simple-dropdown-item>
                </abs-dropdown>
            </div>
        </div>
        
        <div class="divider"></div>

        <div class="setup-database-dialog-btns">
            <button class="btn-medium btn-secondary fx-margin-right-5" (click)="closeWindow()">{{
                'SETUP_DATABASE_DIALOG.BUTTONS.CANCEL' | translate }}</button>
            <button [class.disabled]="!dbName" [disabled]="!dbName" class="btn-medium btn-primary fx-margin-right-5 submit-btn" (click)="createDatabase()">{{
                'SETUP_DATABASE_DIALOG.BUTTONS.CREATE_DATABASE' | translate }}</button>
        </div>
    </div>
</div>