import { GraphicsSVG } from '../graphics/svg-graphics';
import { IShapeText } from 'flux-definition';
import { ImageRenderView } from '../../shape/view/image-render-view';
import { ShapeSVGTextView } from './svg-shape-text-view';
import { AppConfig } from 'flux-core';

/**
 * This view defines how a custom image is rendered on the svg view.
 *
 * TODO: Methods of this class are not implemented.
 * @since   2019-02-19
 * @author  Ramishka
 */
export class ImageSVGView extends ImageRenderView {
    /**
     * The temporary storage of transfromation changes applied to this
     * view. These will be used when generatig the output.
     */
    protected _svgTransform: {
        translate?: string;
        scaleX?: string;
        scaleY?: string;
        rotate?: string;
    };
    protected _graphics: GraphicsSVG;

    protected _svgTransformImage: {
        translate?: string;
        scaleX?: string;
        scaleY?: string;
        rotate?: string;
    };

    /**
     * The svg output of the shape view.
     * Idealy, this function should return a SVGGElement so further modifications,appends and usage
     * will be mush easier. It returns a string for the moment due to lack of functionality support
     * on a NodeJS environment. JSDom may be a good candidate to fix this problem on a non-browser
     * env but still it do not have a working implementation to get it working.
     *
     * If need to return a SVGGElement, make sure the functionality can be performed on a non-browser env
     * like NodeJS.
     * @override
     */
    public get renderedShape(): string {
        const transform = this.getTransformStringForImage();
        const borderTransform = this.getTransformString();
        let transformString = '';
        let borderTransformString = '';
        if ( transform ) {
            transformString = `transform="${transform}"`;
        }
        if ( borderTransform ) {
            borderTransformString = `transform="${borderTransform}"`;
        }

        const _width = this.model.drawWidth - this.model.style.lineThickness;
        const _height = this.model.drawHeight - this.model.style.lineThickness;
        const db = this.model.defaultBounds;

        const scaleX = _width / db.width;
        const scaleY = _height / db.height;

        const width = db.width * scaleX;
        const height = db.height * scaleY;

        // FIXME: There's a bug in Sakota, setters are not working properly with Sakota proxies
        //        because of that `this.imageSource` is undefined instead of having the value.
        const imageProps = [
            `width="${width}"`,
            `height="${height}"`,
            `xlink:href="${this._imageSource}"`,
            `preserveAspectRatio="none"`,
        ].join( ' ' );
        let svgShape = `<g ${transformString}><image ${imageProps} /></g>`;
        svgShape += `<g ${borderTransformString}>${this._graphics.toSvgString()}</g>`;
        this._texts.forEach( textView => {
            const view: ShapeSVGTextView = textView as ShapeSVGTextView;
            svgShape += view.svgTextElement;
        });
        return `<g id="${this.model.id}">${svgShape}</g>`;
    }

    /**
     * The svg graphics instance associated with this shape.
     * @override
     */
    public get graphics(): GraphicsSVG {
        return this._graphics;
    }

    /**
     * Hook thats called after shape is created and added to the diagram
     * for the first time.
     */
    public initialize() {
        this._graphics = new GraphicsSVG();
        this._svgTransform = {};
        this._svgTransformImage = {};
        return this.retry( this.model, 10, 2 );
    }

    /**
     * Translates the shape to the given location
     * @param x the position on the canvas to move to
     * @param y the position on the canvas to move to
     */
    protected move( x: number, y: number ) {
        if ( x === 0 && y === 0 ) {
            this._svgTransform.translate = undefined;
        } else {
            this._svgTransform.translate = `${x} ${y}`;
        }
        const ix = x + this.model.style.lineThickness / 2;
        const iy = y + this.model.style.lineThickness / 2;
        if ( ix === 0 && iy === 0 ) {
            this._svgTransformImage.translate = undefined;
        } else {
            this._svgTransformImage.translate = `${ix} ${iy}`;
        }
    }

    /**
     * Scales the shape in height.
     * @param scale The scale multiplier
     */
    protected scaleHeight( scale: number ) {
        if ( scale === 1 ) {
            this._svgTransform.scaleY = undefined;
        } else {
            this._svgTransform.scaleY = `${scale}`;
        }
    }

    /**
     * Scales the shape in width.
     * @param scale The scale multiplier
     */
    protected scaleWidth( scale: number ) {
        if ( scale === 1 ) {
            this._svgTransform.scaleX = undefined;
        } else {
            this._svgTransform.scaleX = `${scale}`;
        }
    }

    /**
     * Rotates the shape to the given angle
     * @param angle The angle to rotate to.
     */
    protected rotate( angle: number ) {
        if ( angle === 0 ) {
            this._svgTransform.rotate = undefined;
        } else {
            this._svgTransform.rotate = `${angle}`;
        }
    }

    /**
     * Generaetes a SVG trasnform string based on the
     * local variable _svgTransform values.
     */
    protected getTransformStringForImage(): string {
        let tstring = '';
        if ( this._svgTransformImage.translate ) {
            tstring += `translate(${this._svgTransformImage.translate}) `;
        }
        if ( this._svgTransform.scaleX || this._svgTransform.scaleY ) {
            const scaleX = this._svgTransform.scaleX || 1;
            const scaleY = this._svgTransform.scaleY || 1;
            tstring += `scale(${scaleX} ${scaleY}) `;
        }
        if ( this._svgTransform.rotate ) {
            const regX = - this.model.style.lineThickness / 2;
            const regY = - this.model.style.lineThickness / 2;
            tstring += `rotate(${this._svgTransform.rotate},${regX},${regY})`;
        }
        return tstring.trim();
    }

    /**
     * Generaetes a SVG trasnform string based on the
     * local variable _svgTransform values.
     */
    protected getTransformString(): string {
        let tstring = '';
        if ( this._svgTransform.translate ) {
            tstring += `translate(${this._svgTransform.translate}) `;
        }
        if ( this._svgTransform.scaleX || this._svgTransform.scaleY ) {
            const scaleX = this._svgTransform.scaleX || 1;
            const scaleY = this._svgTransform.scaleY || 1;
            tstring += `scale(${scaleX} ${scaleY}) `;
        }
        if ( this._svgTransform.rotate ) {
            tstring += `rotate(${this._svgTransform.rotate})`;
        }
        return tstring.trim();
    }

    /**
     * Create a svg text view for given text model
     */
    protected addText( text: IShapeText ) {
        const textView = new ShapeSVGTextView( text );
        this._texts.push( textView );
    }

    protected updateImageSrc( hash: string, params = '' ): Promise<boolean> {
        const src = AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash + params;
        return new Promise(( resolve, reject ) => {
            // Fetch the image as a blob
            fetch( src )
                .then( response => response.blob())
                .then( blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        // Create the base64 string
                        const base64String = reader.result.toString();
                        this._imageSource = base64String as string;
                        resolve( true );
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL( blob );
                })
                .catch( reject );
        });
    }
}
