import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { IDialogBoxButtonData, IDialogBoxData } from 'flux-definition';
import { DialogBoxController } from '../../controller/dialog-box/dialog-box-controller';
import { TranslateService } from '@ngx-translate/core';
import { take, map } from 'rxjs/operators';
import { StateService } from '../../controller/state.svc';

/**
 * This component is the dialog box that is used to
 * display a message and let the user make an action
 * based on the information provided.
 */

@Component({
    selector: 'dialog-box',
    templateUrl: './dialog-box.cmp.html',
    styleUrls: [ './dialog-box.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBox {

    /**
     * Shows and hides the dialong in the ui.
     */
    public showDialog: BehaviorSubject<boolean>;

    param: any = {};

    /**
     * Heading of the dialog.
     */
    protected heading: BehaviorSubject<string>;

    /**
     * Message displayed to the user.
     */
    protected description: BehaviorSubject<string>;

    /**
     * Color of the dialog. Can be neutral | error | warning | success.
     */
    protected type: 'neutral' | 'error' | 'warning' | 'success' | 'iframe';

    /**
     * Icon that needs to display on the message box.
     */
    protected icon: string;

    /**
     * Button Data.
     */
    protected buttons: BehaviorSubject<IDialogBoxButtonData[]>;

    /**
     * Constructor
     */
    constructor( protected state: StateService<any, any>,
                 protected dialogBoxController: DialogBoxController,
                 protected translateService: TranslateService ) {
        this.showDialog = new BehaviorSubject( false );
        this.heading = new BehaviorSubject( '' );
        this.description = new BehaviorSubject( '' );
        this.buttons = new BehaviorSubject([]);
        this.subscribeToManager();
    }

    /**
     * Set DialogBoxdata
     */
    @Input() public set dialogBoxData( data: IDialogBoxData ) {
        this.translate( data.heading, data.headingParams ).subscribe( val => this.heading.next( val ));
        this.translate( data.description, data.descriptionParams ).subscribe( val => this.description.next( val ));
        this.type = data.type;
        this.icon = data.icon;
        const btns = [];
        data.buttons.forEach( buttonData => {
            const clonedData = {
                type: buttonData.type,
                text: buttonData.text,
                clickHandler: buttonData.clickHandler,
            };
            btns.push( this.translate( clonedData.text ).pipe( map( val => {
                clonedData.text = val;
                return clonedData;
            })));
        });
        forkJoin( ...btns ).pipe( take( 1 )).subscribe( buttons => this.buttons.next( buttons ));
    }

    /**
     * Returns the icon url.
     */
    public get iconURL(): string {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.icon;
    }

    /**
     * Shows the dialog box.
     * FIXME - Show animation should start after true is emitted.
     */
    public showDialogBox() {
        this.showDialog.next( true );
        this.state.set( 'DialogBox', { open: true });
    }

    /**
     * Hides the dialog box.
     * FIXME - Should emit false after hide animation completes.
     */
    public hideDialogBox() {
        this.showDialog.next( false );
        this.state.set( 'DialogBox', { open: false });
    }

    /**
     * Translates given string with parameters and returns an observable.
     * @param str Tranlation string Id
     * @param params Parameters.
     */
    protected translate( str: string, params: any = {}): Observable<string> {
        return this.translateService.get( str, params ).pipe(
            take( 1 ),
        );
    }

    /**
     * DialogBox is subscribed to DialogBoxmanager's dstaStream.
     * It will add the data to dialog box and shows the dialog
     * box whenever a message is emited by the dataStream.
     */
    protected subscribeToManager() {
        this.dialogBoxController.dataStream.subscribe( data => {
            this.dialogBoxData = data;
            this.showDialogBox();
        });
    }
}
