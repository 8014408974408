import { Injectable } from '@angular/core';
import { AbstractCommand, Command, CommandInterfaces, StateService } from 'flux-core';
import { ProjectLocator } from 'flux-diagram';
import { ModelSubscriptionManager } from 'flux-subscription';
// import { isEmpty } from 'lodash';
import { combineLatest, EMPTY, merge, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { EDataSub } from '../../edata/edata.sub';
import { DefinitionLocator } from '../../shape/definition/definition-locator.svc';

/**
 * Command for starting edata subscription.
 * Starts user info subscriptions for each collaborator of the diagram.
 *
 * @author  Ramishka
 * @since   201-02-02
 */
@Injectable()
@Command()
export class StartEDataSubscription extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor( protected modelSubManager: ModelSubscriptionManager,
                 private defLocator: DefinitionLocator,
                 protected pl: ProjectLocator,
                 protected dll: DiagramLocatorLocator,
                 protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public execute (): Observable<any> {
        // FIXME - force load the eDataDefs
        // this.defLocator.getDefinition( 'creately.edata.uml' ).subscribe();
        this.defLocator.getDefinition( 'creately.edata.bpm' ).subscribe();
        this.defLocator.getDefinition( 'creately.edata.datasource.github' ).subscribe();
        this.defLocator.getDefinition( 'creately.edata.pm' ).subscribe();
        this.defLocator.getDefinition( 'creately.edata.people' ).subscribe();

        const projectId = this.data && this.data.projectId ? this.data.projectId : this.state.get( 'CurrentProject' );
        const diagramId = ( this.data && this.data.diagramId ) ?
                                this.data.diagramId : this.state.get( 'CurrentDiagram' );
        if ( !projectId ) {
            return EMPTY;
        }

        return combineLatest([
            this.dll.forDiagram( diagramId, false ).getDiagramModel(),
            this.pl.getProject( projectId ),
        ]).pipe(
            filter(([ diagramMdl, projectMdl ]) => !!diagramMdl && !!projectMdl ),
            take( 1 ),
            switchMap(([ diagramMdl, projectMdl ]) => {
                const allEDataIds = new Set<string>([ ...( diagramMdl.eData || []), ...( projectMdl.eData || []) ]);
                if ( allEDataIds.size < 1 ) {
                    return EMPTY;
                }
                const obs = Array.from( allEDataIds ).map( eId => this.modelSubManager.start( EDataSub, eId ));
                return merge( ... obs );
            }),
        );
    }
}

Object.defineProperty( StartEDataSubscription, 'name', {
    value: 'StartEDataSubscription',
});
