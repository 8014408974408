import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalWindowContainer } from 'flux-core/src/ui';
import { filter, switchMap, tap } from 'rxjs/operators';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-modal-window-container',
    template: '<ng-content></ng-content>',
})


export class AppModalWindowContainer extends ModalWindowContainer {
    /**
     * Starts listening to modal window changes and show/hide
     * modal window based on state.
     *
     * FIXME :This invoke only app level modal controllers, other ModalWindowContainer controls child level modules
     * related modal controllers.
     * When ModalWindowContainer uses in both child and parent levels it makes component factory errors.To fix that
     * introduce this new App level AppModalWindowContainer.
     */
    protected listenToModalWindowChanges() {
        this.subs.push(
            this.state.changes( 'ModalWindow' ).pipe(
                filter( data => !!data ),
                tap( data => {
                    if ( data.blur ) {
                        this.state.set( 'ModalBlur', true );
                    } else {
                        this.state.set( 'ModalBlur', false );
                    }
                }),
                switchMap( data => {
                    const factories = data.type ?
                    this.componentFactoryResolver.resolveComponentFactory( data.type ) : undefined;
                    if ( data.show && data.options && data.options.inputs.appLevel && factories ) {
                        return this.show( data.type, data.options );
                    }
                    return this.destroyModal();
                }),
            ).subscribe(),
        );
    }
}
