import { Injectable } from '@angular/core';
import { Command, CommandScenario, StateService } from 'flux-core';
import { last } from 'lodash';
import { Observable, empty } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { StartMovingSelection } from './start-moving-selection.cmd';

/**
 * Starts the mouse movement of a endpoint of the connector. Waits for the
 * selection to be available and then initates the movment. Requires the connector
 * id for the command to function. If endpoint id is given, the specific end point will
 * start moving. if not the "to" endpoint will start moving.
 */
@Injectable()
@Command()
export class StartMovingConnectorEnd extends StartMovingSelection {

    public static get dataDefinition(): {}  {
        return {
            connectorId: true, // The id of the connector for which we need to start moving the end point
            endPointId: false, // The id if the endpoint which we need to start moving.
            // If not given the "to" end point will move.
        };
    }

    constructor( state: StateService<any, any>, protected ll: DiagramLocatorLocator ) {
        super( state ) /* istanbul ignore next */;
    }

    public prepareData() {
        if ( this.data.endPointId ) {
            return empty();
        }
        const locator = this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
        return locator.getShapeOnce( this.data.connectorId ).pipe(
            tap(( connector: ConnectorModel ) => {
                this.data.endPointId = last( connector.getPoints()).id;
            }),
        );
    }

    public execute (): Observable<any> {
        return this.waitForConnectorSelection( this.data.connectorId ).pipe(
            tap( selection => selection.startEndpointMovement( this.data.endPointId )),
        );
    }

}

Object.defineProperty( StartMovingConnectorEnd, 'name', {
    value: 'StartMovingConnectorEnd',
});
