import { Injectable } from '@angular/core';
import { DataItemLevel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { DateFNS, Position, Random, Rectangle } from 'flux-core';
import { DataType, IPoint2D, IRectangle, SmartContainerType, SystemType } from 'flux-definition';
import { DIReferenceType } from 'flux-diagram-composer';
import { isArray, mergeWith } from 'lodash';
import { DiagramModel } from './../../../base/diagram/model/diagram.mdl';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';

/**
 * The SmartContainerService is responsible for managing the smart container
 * related logic.For specific container which have some data items this service
 * will add new data items to its child shapes.
 * If there is need to recalculate smart container specific data items.it will do here too.
 */
// tslint:disable: member-ordering

@Injectable()
export class SmartContainerService {

    private containerXAxisDataItemId = 'xIdentifier';
    private containerYAxisDataItemId = 'yIdentifier';

    constructor() {
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public updateChildIdentifiers( diagramModel: DiagramModel, container: ShapeModel, child: ShapeModel ) {
        if ( container.data.identifier && container.data.identifier.value && typeof container.data.identifier.value === 'string' ) {
            const path = container.data.identifier.value;
            const regions =  container.containerRegions;
            const regionOptions = [];
            const eventData = {
                [child.id]: {},
            };

            if ( container.data.xIdentifier && container.data.xIdentifier.value
                && container.data.yIdentifier && container.data.yIdentifier.value ) {
                    const xAxis = container.data.xIdentifier.value.replace( /\s/g, '' );
                    const xAxisLabel = container.data.xIdentifier.value;
                    const yAxis = container.data.yIdentifier.value.replace( /\s/g, '' );
                    const yAxisLabel = container.data.yIdentifier.value;
                    const yAxisHigh = container.data.yEnd.value;
                    const yAxisLow = container.data.yStart.value;
                    const xAxisHigh = container.data.xEnd.value;
                    const xAxisLow = container.data.xStart.value;
                    const xAxisDistance = xAxisHigh - xAxisLow;
                    const yAxisDistance = yAxisHigh - yAxisLow;
                    let xAxisChild;
                    let yAxisChild;
                    const maxIndex = this.getMaxIndexOfDataItems( child );
                    Object.keys( child.data ).forEach( dataId => {
                        if ( child.data[dataId].referenceType === DIReferenceType.Shape
                            && child.data[dataId].refItemId === container.id ) {
                                if ( child.data[dataId].refDataItemId === this.containerXAxisDataItemId ) {
                                    xAxisChild = dataId;
                                }
                                if ( child.data[dataId].refDataItemId === this.containerYAxisDataItemId ) {
                                    yAxisChild = dataId;
                                }
                        }
                    });

                    const childXAxis = child.data[xAxisChild];
                    const childYAxis = child.data[yAxisChild];

                    if ( childXAxis && childXAxis.value && childYAxis && childYAxis.value ) {
                        this.updateGridChildPosition( container, child );
                    } else {
                        const childRelativeAxis = container.children[child.id];

                        const widthPadding = container.shapePaddings ?
                        container.shapePaddings.left + container.shapePaddings.right : 0;
                        const heightPadding = container.shapePaddings ?
                        container.shapePaddings.top + container.shapePaddings.bottom : 0;

                        const childXAxisValue = Math.round((((  childRelativeAxis.relativeX /
                             ( container.drawWidth - widthPadding )) * xAxisDistance ) + xAxisLow ));
                        const childYAxisValue = Math.round(((((( container.drawHeight - heightPadding )
                        - childRelativeAxis.relativeY ) /  ( container.drawHeight - heightPadding ))
                        * yAxisDistance ) + xAxisLow ));
                        const xAxisDataItem = this.getRefItemData(
                            xAxis, childXAxisValue, xAxisLabel, DataType.NUMBER, SystemType.XAxis, diagramModel.id,
                            container.id, this.containerXAxisDataItemId, SmartContainerType.XY, path,
                            false, maxIndex + 1,
                            );
                        const yAxisDataItem = this.getRefItemData(
                            yAxis, childYAxisValue, yAxisLabel, DataType.NUMBER, SystemType.YAxis, diagramModel.id,
                            container.id, this.containerYAxisDataItemId, SmartContainerType.XY, path,
                            false, maxIndex + 2 );
                        Object.assign( eventData[child.id], {
                            [xAxis]: xAxisDataItem,
                            [yAxis]: yAxisDataItem,
                        });
                        Object.values( container.containerGrids ).forEach( grid => {
                            const textReact: IRectangle = {
                                x: container.x + grid.x,
                                y: container.y + grid.y,
                                width: grid.width,
                                height: grid.height,
                            };
                            const point: IPoint2D = {
                                x: child.x + child.width / 2,
                                y: child.y + child.height / 2,
                            };
                            if ( Rectangle.from( textReact ).toPolygon().isInside( point )) {
                                const value  = container.texts[grid.id].plainText;
                                const gridDataItemId = Random.dataItemId();
                                const gridStatus = this.getItemData( gridDataItemId, value, path,
                                     DataType.STRING, SystemType.Priority,
                                      SmartContainerType.XY, path, true, maxIndex + 3 );
                                Object.assign( eventData[child.id], {
                                    [gridDataItemId]: gridStatus,
                                });
                            }
                        });
                    }
                } else if ( container.data.startDate && container.data.startDate.value
                    && container.data.endDate && container.data.endDate.value ) {
                        const dataItems = child.getDataItems( diagramModel );
                        const grouping = container.data.grouping.value;
                        const childRelativeAxis = container.children[child.id];
                        const isStartDateKey =  Object.keys( child.data ).find( key => {
                            if ( child.data[key] && child.data[key].systemType ) {
                                return child.data[key].systemType === SystemType.StartDate
                                && child.data[key].label === path;
                            }
                            return dataItems[key].systemType === SystemType.StartDate
                            && dataItems[key].label === path;
                        });
                        const isEndDateKey =  Object.keys( child.data ).find( key => {
                            if ( child.data[key] && child.data[key].systemType ) {
                                return child.data[key].systemType === SystemType.DueDate
                                && child.data[key].label === path;
                            }
                            return dataItems[key].systemType === SystemType.DueDate
                            && dataItems[key].label === path;
                        });
                        const isStartDate = child.data[isStartDateKey];
                        const isEndDate = child.data[isEndDateKey];
                        const maxIndex = this.getMaxIndexOfDataItems( child );
                        if ( isStartDate && isStartDate.value && isEndDate && isEndDate.value ) {
                            this.updateTimeLineChildPosition( diagramModel, container, child );
                        } else {
                            const containerStartValue = Number( container.data.startDate.value );
                            const containerEndValue = Number( container.data.endDate.value );
                            const days = this.getGroupUnitsCount(
                                containerStartValue,
                                containerEndValue,
                                grouping );
                            const daysToStart = childRelativeAxis.relativeX / ( container.drawWidth / days );

                            // dynamicShapeWidth for shape that change default bound have defaultContextBounds
                            // So if we have defaultContextBounds then we need to calculate width using that value
                            const dynamicShapeWidth = child.defaultContextBounds ?
                             child.defaultContextBounds.width * child.scaleX : child.width;

                            const daysToEnd = ( childRelativeAxis.relativeX + dynamicShapeWidth ) /
                             ( container.drawWidth / days );
                            const startDate = DateFNS.addDays( containerStartValue, daysToStart );
                            const endDate = DateFNS.addDays( containerStartValue, daysToEnd );
                            const startDateId = Random.dataItemId();
                            const dueDateId = Random.dataItemId();
                            const bindStartDateTextId = 'startDate';
                            const bindDueDateTextId = 'endDate';
                            if ( !isStartDate || !isStartDate.value ) {
                                const startDateDataItem =
                                this.getUIItemData( startDateId, startDate.getTime(), path , DataType.DATE,
                                SystemType.StartDate, bindStartDateTextId
                                , false, SmartContainerType.XWithWidth, path, true, maxIndex + 1 );
                                Object.assign( eventData[child.id], {
                                    [startDateId]: startDateDataItem,
                                });
                            } else {
                                Object.assign( eventData[child.id], {
                                    [isStartDateKey]: {
                                        smartContainerType: SmartContainerType.XWithWidth,
                                        smartContainerIdentifier: path,
                                    },
                                });
                            }
                            if ( !isEndDate || !isEndDate.value ) {
                                const endDateDataItem =
                                this.getUIItemData( dueDateId, endDate.getTime(), path , DataType.DATE,
                                SystemType.DueDate, bindDueDateTextId, false,
                                 SmartContainerType.XWithWidth, path, true, maxIndex + 2 );
                                Object.assign( eventData[child.id], {
                                    [dueDateId]: endDateDataItem,
                                });
                            } else {
                                Object.assign( eventData[child.id], {
                                    [isEndDateKey]: {
                                        smartContainerType: SmartContainerType.XWithWidth,
                                        smartContainerIdentifier: path,
                                    },
                                });
                            }
                        }
                } else {
                    Object.keys( regions ).forEach( regionId => {
                        const text = container.texts[regionId];
                        regionOptions.push({
                                id: regionId,
                                value: text.plainText,
                                text: text.plainText,
                                buttonLabel: text.plainText,
                                label: text.plainText,
                            },
                        );
                    });

                    let index = 0;
                    if ( child.data && child.data[path])  {
                        index = child.data[path].index;
                    } else {
                        const maxIndex = this.getMaxIndexOfDataItems( child );
                        index = maxIndex + 1;
                    }
                    const regionID = child.containerRegionId;
                    const label = path;
                    if ( regionID ) {
                        const regionText = container.texts[regionID] ? container.texts[regionID].plainText : ' ';
                        const itemData = this.getListDataItem( path, regionText, label,
                             regionOptions, SystemType.Status,
                            SmartContainerType.Region, path, index );
                        Object.assign( eventData[child.id], {
                            [path]: itemData,
                        });
                    } else {
                        const itemData = this.getListDataItem( path, true, label, regionOptions, SystemType.Status,
                            SmartContainerType.Region, path, index  );
                        Object.assign( eventData[child.id], {
                            [path]: itemData,
                        });
                    }
                }
            this.commitDataItemsAndDefs( diagramModel , eventData );
        }
    }

    private getMaxIndexOfDataItems( shape: ShapeModel ): number {
        let maxIndex = 0;
        if ( Object.values( shape.data ).filter( item => item.index ).length > 0 ) {
            maxIndex = Object.values( shape.data )
            .filter( item => item.index ).reduce(( a, b ) => a.index > b.index ? a : b ).index;
        }
        return maxIndex;
    }

    public changeTheIdentifier( diagramModel: DiagramModel, child: ShapeModel,
                                oldIdentifier: string, newIdentifier: string ) {
        const eventData = {
            [child.id]: {},
        };
        Object.keys( child.data ).forEach( dataKey => {
            if ( child.data[dataKey]
                && child.data[dataKey].smartContainerIdentifier === oldIdentifier ) {
                    child.data[dataKey].smartContainerIdentifier = newIdentifier;
            }
        });
        const oldDataItem = Object.assign({},
             { ...child.data[oldIdentifier] }, { id: newIdentifier, label: newIdentifier });
        Object.assign( eventData[child.id], {
            [newIdentifier]: oldDataItem,
        });
        if ( child.data[oldIdentifier]) {
            delete child.data[oldIdentifier];
        }
        this.commitDataItemsAndDefs( diagramModel, eventData );
    }


    public updateGridChildDataItems( diagramModel: DiagramModel, container: ShapeModel, child: ShapeModel ) {
        const eventData = {
            [child.id]: {},
        };
        if ( container.data.xIdentifier && container.data.xIdentifier.value
            && container.data.yIdentifier && container.data.yIdentifier.value ) {
                const yAxisHigh = container.data.yEnd.value;
                const yAxisLow = container.data.yStart.value;
                const xAxisHigh = container.data.xEnd.value;
                const xAxisLow = container.data.xStart.value;
                const xAxisDistance = xAxisHigh - xAxisLow;
                const yAxisDistance = yAxisHigh - yAxisLow;

                const widthPadding = container.shapePaddings ?
                container.shapePaddings.left + container.shapePaddings.right : 0;
                const heightPadding = container.shapePaddings ?
                container.shapePaddings.top + container.shapePaddings.bottom : 0;

                const childRelativeAxis = container.children[child.id];
                const childXAxisValue = Math.round((((( childRelativeAxis.relativeX + ( child.width / 2 )) /
                     ( container.drawWidth - widthPadding )) * xAxisDistance ) + xAxisLow ));
                const childYAxisValue =
                Math.round(((((( container.drawHeight - heightPadding ) -
                 ( childRelativeAxis.relativeY + ( child.height / 2 ))) /
                  ( container.drawHeight - heightPadding )) * yAxisDistance ) + xAxisLow ));

                Object.keys( child.data ).forEach( dataId => {
                    if ( child.data[dataId].referenceType === DIReferenceType.Shape
                        && child.data[dataId].refItemId === container.id ) {
                            if ( child.data[dataId].refDataItemId === this.containerXAxisDataItemId ) {
                                Object.assign( eventData[child.id], {
                                    [dataId]: { value: childXAxisValue },
                                });
                            }
                            if ( child.data[dataId].refDataItemId === this.containerYAxisDataItemId ) {
                                Object.assign( eventData[child.id], {
                                    [dataId]: { value: childYAxisValue },
                                });
                            }
                    }
                });
                if ( Object.keys( eventData[child.id]).length > 0 ) {
                    this.commitDataItemsAndDefs( diagramModel , eventData );
                }
            }
    }

    public updateGridChildActiveText( diagramModel: DiagramModel,  container: ShapeModel, child: ShapeModel ) {
        if ( container.data.identifier && container.data.identifier.value ) {
            const path = container.data.identifier.value;
            const eventData = {
                [child.id]: {},
            };
            Object.values( container.containerGrids ).forEach( grid => {
                const textReact: IRectangle = {
                    x: container.x + grid.x,
                    y: container.y + grid.y,
                    width: grid.width,
                    height: grid.height,
                };
                const point: IPoint2D = {
                    x: child.x + child.width / 2,
                    y: child.y + child.height / 2,
                };
                if ( Rectangle.from( textReact ).toPolygon().isInside( point )) {
                    const value  = container.texts[grid.id].plainText;
                    const gridStatusDataItem =  Object.values( child.data )
                    .find( data => data.systemType === SystemType.Priority && data.label === path );
                    if ( gridStatusDataItem ) {
                        const statusId = gridStatusDataItem.id;
                        Object.assign( eventData[child.id], {
                            [statusId]: {
                                value,
                            },
                        });
                    }
                }
            });
            this.commitDataItemsAndDefs( diagramModel , eventData );
        }
    }

    public updateGridChildPosition( container: ShapeModel, child: ShapeModel ) {
        if ( container.data.xIdentifier && container.data.xIdentifier.value
            && container.data.yIdentifier && container.data.yIdentifier.value ) {
                const yAxisHigh = container.data.yEnd.value;
                const yAxisLow = container.data.yStart.value;
                const xAxisHigh = container.data.xEnd.value;
                const xAxisLow = container.data.xStart.value;
                const xAxisDistance = xAxisHigh - xAxisLow;
                const yAxisDistance = yAxisHigh - yAxisLow;
                let xAxis;
                let yAxis;
                Object.keys( child.data ).forEach( dataId => {
                    if ( child.data[dataId].referenceType === DIReferenceType.Shape
                        && child.data[dataId].refItemId === container.id ) {
                            if ( child.data[dataId].refDataItemId === this.containerXAxisDataItemId ) {
                                xAxis = dataId;
                            }
                            if ( child.data[dataId].refDataItemId === this.containerYAxisDataItemId ) {
                                yAxis = dataId;
                            }
                    }
                });
                const widthPadding = container.shapePaddings ?
                 container.shapePaddings.left + container.shapePaddings.right : 0;
                const heightPadding = container.shapePaddings ?
                 container.shapePaddings.top + container.shapePaddings.bottom : 0;
                if ( xAxis && yAxis ) {
                    const xAxisValue = child.data[xAxis].value;
                    const yAxisValue = child.data[yAxis].value;
                    if ( xAxisValue && yAxisValue ) {
                    const xPositionRatio = (( xAxisValue - container.data.xStart.value ) / xAxisDistance )
                    * ( container.drawWidth - widthPadding );
                    const yPositionRatio = ( container.drawHeight - heightPadding ) - (
                        (( yAxisValue - container.data.yStart.value )
                        / yAxisDistance ) * ( container.drawHeight - heightPadding ));
                    child.x = container.x + xPositionRatio - ( child.width / 2 );
                    child.y = container.y + yPositionRatio - ( child.height / 2 );
                    }
                }
            }
    }
    public updateTimeLineChildPosition( diagramModel: DiagramModel, container: ShapeModel, child: ShapeModel ) {
        const path = container.data.identifier?.value;
        if ( !( path && typeof path === 'string' )) {
            return;
        }
        const dataItems = child.getDataItems( diagramModel );
        const isStartDateKey =  Object.keys( child.data ).find( key => {
            if ( child.data[key] && child.data[key].systemType ) {
                return child.data[key].systemType === SystemType.StartDate && child.data[key].label === path;
            }
            return dataItems[key]
                .systemType === SystemType.StartDate && dataItems[key].label === path;
        });
        const isEndDateKey =  Object.keys( child.data ).find( key => {
            if ( child.data[key] && child.data[key].systemType ) {
                return child.data[key].systemType === SystemType.DueDate && child.data[key].label === path;
            }
            return dataItems[key]
                .systemType === SystemType.DueDate && dataItems[key].label === path;
        });
        const startDate = child.data[isStartDateKey];
        const endDate = child.data[isEndDateKey];
        if ( startDate && startDate.value && endDate && endDate.value  ) {
                const containerStartValue = Number( container.data.startDate.value );
                const containerEndValue = Number( container.data.endDate.value );
                const grouping = container.data.grouping.value;
                const days = this.getGroupUnitsCount(
                    containerStartValue,
                    containerEndValue,
                    grouping );
                const childDayWidth = this.getGroupUnitsCount(
                    startDate.value,
                    endDate.value,
                    grouping );
                const daysToChildStart = this.getGroupUnitsCount(
                    containerStartValue,
                    startDate.value,
                    grouping );
                const childStartX = daysToChildStart * Math.floor(( container.drawWidth / days ));
                const childWidth = childDayWidth * Math.floor(( container.drawWidth / days ));
                if ( child.x !== container.x + childStartX ||
                     child.scaleX !== childWidth / child.defaultBounds.width ) {
                    child.x = container.x + childStartX;
                    child.scaleX = childWidth / child.defaultBounds.width ;
                }
            }
    }

    public updateTimeLineDateChange( diagramModel: DiagramModel, container: ShapeModel, child: ShapeModel ) {
        if ( container.data.startDate && container.data.startDate.value
            && container.data.endDate && container.data.endDate.value  ) {
                const path = container.data.identifier.value;
                const eventData = {
                    [child.id]: {},
                };
                const grouping = container.data.grouping.value;
                const childRelativeAxis = Position.getRelativeTransformData( container , child );

                const days = this.getGroupUnitsCount(
                    container.data.startDate.value,
                    container.data.endDate.value,
                    grouping );
                const daysToStart = childRelativeAxis.relativeX / Math.floor(( container.drawWidth / days ));
                const daysToEnd = ( childRelativeAxis.relativeX + child.width ) /
                 Math.floor(( container.drawWidth / days ));
                const startDate = DateFNS.addDays( container.data.startDate.value, Math.round( daysToStart ));
                const endDate = DateFNS.addDays( container.data.startDate.value, Math.round( daysToEnd ));

                const dataItems = child.getDataItems( diagramModel );
                const childStartDateKey =  Object.keys( child.data ).find( key => {
                    if ( child.data[key] && child.data[key].systemType ) {
                        return child.data[key].systemType === SystemType.StartDate && child.data[key].label === path;
                    }
                    return dataItems[key].systemType === SystemType.StartDate
                    && dataItems[key].label === path;
                });
                const childEndDateKey =  Object.keys( child.data ).find( key => {
                    if ( child.data[key] && child.data[key].systemType ) {
                        return child.data[key].systemType === SystemType.DueDate && child.data[key].label === path;
                    }
                    return dataItems[key].systemType === SystemType.DueDate
                    && dataItems[key].label === path;
                });
                const childStartDate = child.data[childStartDateKey];
                const childEndDate = child.data[childEndDateKey];

                if ( childStartDate.value !== startDate.valueOf()
                || childEndDate.value !== endDate.valueOf()) {
                    Object.assign( eventData[child.id], {
                        [childStartDateKey]: { value: startDate.valueOf() },
                        [childEndDateKey]: { value: endDate.valueOf() },
                    });
                    this.commitDataItemsAndDefs( diagramModel, eventData );
            }
        }
    }

    public removeIdentifierFromChild( diagramModel: DiagramModel, container: ShapeModel, child: ShapeModel ) {
        const path = container.data.identifier.value;
        delete diagramModel.shapes[child.id].data[path];
    }
    protected commitDataItemsAndDefs( diagramModel: DiagramModel, eventData: {
        [shapeId: string]: {
            [path: string]: {
                value?: any,
                label?: string,
                isNested?: boolean,
                optional?: boolean,
            },
        },
    }) {
        Object.keys( eventData ).forEach( childId => {
            mergeWith( diagramModel.shapes[childId].data, eventData[childId], this.customizer );
            for ( const itemPath in eventData[childId]) {
                diagramModel.updateDataDefs( childId, itemPath, eventData[childId][itemPath], DataItemLevel.Any );
            }
        });
    }
    // Since Sakota not support array update this lodash customizer to replace array when doing a merge
    private customizer( objValue: string | any[], srcValue: any ) {
        if ( isArray( objValue )) {
            return  srcValue;
        }
    }

    private getListDataItem (
        id, value, label , options: {id: string; value: string, text: string}[], systemType: SystemType,
        smartContainerType: SmartContainerType, smartContainerIdentifier: string, index: number = 1,
    ) {
        return {
            id: id,
            type: DataType.OPTION_LIST,
            index: index,
            visibility: [
                { type: 'editor' },
            ],
            typeParams: {
                options: options || [],
            },
            value: value,
            smartContainerType: smartContainerType,
            smartContainerIdentifier: smartContainerIdentifier,
            options: options || [],
            label: label,
            labelEditable: false,
            systemType: systemType,
            optional: true,
            validationRules: {},
            trackingId: id,
        };
    }

    private getRefItemData ( id, value , label, dataType: DataType, systemType: SystemType,
                             refHolderId: string, refItemId: string, refDataItemId: string,
                             smartContainerType: SmartContainerType, smartContainerIdentifier: string,
                             readOnly: boolean = false, index: number = 1, options: any[] = []) {
        return {
            id: id,
            type: dataType,
            index: index,
            visibility: [
                { type: 'editor' },
            ],
            typeParams: {
                readOnly: readOnly,
                options: options || [],
            },
            value: value,
            refHolderId: refHolderId,
            referenceType: DIReferenceType.Shape,
            refItemId: refItemId,
            refDataItemId: refDataItemId,
            smartContainerType: smartContainerType,
            smartContainerIdentifier: smartContainerIdentifier,
            options: options || [],
            label: label,
            systemType: systemType,
            labelEditable: false,
            optional: true,
            validationRules: {},
            trackingId: id,
        };
    }

    private getItemData ( id, value , label, dataType: DataType, systemType: SystemType,
                          smartContainerType: SmartContainerType, smartContainerIdentifier: string,
                          readOnly: boolean = false, index: number = 1, options: any[] = []) {
        return {
            id: id,
            type: dataType,
            index: index,
            visibility: [
                { type: 'editor' },
            ],
            typeParams: {
                readOnly: readOnly,
                options: options || [],
            },
            value: value,
            smartContainerType: smartContainerType,
            smartContainerIdentifier: smartContainerIdentifier,
            options: options || [],
            label: label,
            systemType: systemType,
            labelEditable: false,
            optional: true,
            validationRules: {},
            trackingId: id,
        };
    }

    private getUIItemData ( id, value , label, dataType: DataType, systemType: SystemType, bindTextId: string,
                            bindToData: boolean, smartContainerType: SmartContainerType,
                            smartContainerIdentifier: string, readOnly: boolean = false,
                            index: number = 1, options: any[] = []) {
        return {
            id: id,
            type: dataType,
            index: index,
            visibility: [
                { type: 'editor' },
                {
                    type: 'shape-ui',
                    bindToData: bindToData,
                    bindTextId: bindTextId,
                    renderContext: [ 'XWithWidth' ],
                },
            ],
            typeParams: {
                readOnly: readOnly,
                options: options || [],
            },
            value: value,
            smartContainerType: smartContainerType,
            smartContainerIdentifier: smartContainerIdentifier,
            options: options || [],
            label: label,
            labelEditable: false,
            optional: true,
            systemType: systemType,
            validationRules: {},
            trackingId: id,
        };
    }

    private getGroupUnitsCount( startDate: number, endDate: number, groupUnit: string ) {
        return DateFNS.differenceInDays( endDate, startDate );
    }
}
