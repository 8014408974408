import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopupWindow, Random, AbstractNotification, NotificationType, NotifierController } from 'flux-core';
import { TranslateService } from '@ngx-translate/core';
import { IEDataDef, IEntityDef } from 'flux-definition';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { SubscriptionStatus } from 'flux-subscription';
import { Notifications } from './../../../base/notifications/notification-messages';
// import { uniq } from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { ObjectConvertor } from '../../../framework/edata/object-convertor.svc';
import { filter, map, mapTo, startWith, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { EDataManage } from '../../../framework/edata/edata-manage.svc';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { FormControl } from '@angular/forms';

@Component({
    templateUrl: './convert-to-object-dialog.cmp.html',
    selector: 'convert-to-object-dialog',
    styleUrls: [ 'convert-to-object-dialog.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvertToObjectDialog extends PopupWindow implements AfterViewInit, OnInit, OnDestroy {

    public static customEDataDefId = 'customEDataDef';

    @Input() public defs: IEDataDef[] = [];
    @Input() public shape: AbstractShapeModel;
    @Input() public initDefId: string;

    public selectedDbType: Subject<IEDataDef>;
    public dbName: string;

    public dbControl = new FormControl();
    public defControl = new FormControl();

    public selectedDatabase: EDataModel;
    public selectedDef: IEntityDef;
    public selectedEntityType: BehaviorSubject<IEntityDef> = new BehaviorSubject( null );

    public filteredOptions: Observable<EDataModel[]>;
    public filteredDefs: Observable<Partial<IEntityDef>[]>;
    public entityTypeOpts: Observable<Partial<IEntityDef>[]>;

    /**
     * These subjects emit true if the corresponding input is valid
     */
    public isDBValid: Subject<boolean> = new BehaviorSubject( false );
    public isDefValid: Subject<boolean> = new BehaviorSubject( false );
    public readonly = new BehaviorSubject( false );
    public existingDb = new BehaviorSubject( false );

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription> = [];

    private eDataModels: EDataModel[] = [];

    private predefinedModels: {[defId: string]: EDataModel } = {};
    private selectedDbDef: Subject<IEDataDef> = new BehaviorSubject( null );

    constructor(
        private modalController: ModalController,
        private objectConvertor: ObjectConvertor,
        private eDataManage: EDataManage,
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
    ) {
        super();
    }

    public displayFn( edata: EDataModel ): string {
      return edata && edata.name ? edata.name : '';
    }

    public displayDef( def: IEntityDef ): string {
        return def && def.name ? def.name : '';
    }

    /**
     * Starts the show animation.
     */
    public ngOnInit(): void {
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.subs.push( sub );
        this.defs = this.defs.concat([ {
            defId: ConvertToObjectDialog.customEDataDefId,
            name: 'Custom Database',
        } as any ]);
        if ( this.initDefId ) {
            this.selectedDbType = new BehaviorSubject( this.defs.find( def => def.defId === this.initDefId ));
        } else {
            this.selectedDbType = new BehaviorSubject( this.defs[0]);
        }
        // initializing eDataModels
        this.ll.forCurrentObserver( false ).pipe(
            switchMap( locator => locator.getDiagramEData()),
            take( 1 ),
            switchMap( eDataList => {
                if ( !eDataList ) {
                    return of([]);
                }
                const eSubs = eDataList.map( eId => this.ell.getEData( eId ).pipe(
                    switchMap( eLocator => eLocator.getEDataModelOnce()),
                ));
                return forkJoin( eSubs );
            }),
            tap( eDataModels => {
                this.eDataModels = eDataModels;
            }),
        ).subscribe();

        let defIds2 = [];
        // initializing predefinedModels
        combineLatest([
            this.ell.currentProjectEDataModels(),
            this.ell.currentDiagramEDataModels(),
        ]).pipe(
            take( 1 ),
            tap(([ models, dModels ]) => {
                const defIds = this.defs.map( d => d.defId );
                dModels = dModels.filter( m => !m.isCustom && !defIds.includes( m.defId ))
                    .filter( m => !m.defId.startsWith( 'creately.edata.datasource' ));
                defIds2 = dModels.map( m => m.defId );
                const cDef = this.defs.pop();
                this.defs = this.defs.concat( dModels.map( m => EDataRegistry.instance.getEDataDef( m.defId )));
                this.defs.push( cDef );
                models.filter( m => defIds.includes( m.defId )).forEach( m => {
                    this.predefinedModels[ m.defId ] = m;
                });
                dModels.forEach( m => {
                    this.predefinedModels[ m.defId ] = m;
                });
            }),
            switchMapTo( this.selectedDbType ),
            tap( def => {
                if ( this.predefinedModels[ def.defId ]) {
                    this.dbName = this.predefinedModels[ def.defId ].name;
                    this.readonly.next( true );
                } else {
                    this.dbName = '';
                    this.readonly.next( false );
                }
                const existingDb = defIds2.includes( def.defId );
                if ( existingDb ) {
                    const selectedDbDef = this.defs.find( d => d.defId === def.defId );
                    this.selectedDbDef.next( selectedDbDef );
                    this.selectedEntityType.next( Object.values( selectedDbDef.entityDefs )[0]);
                    this.existingDb.next( true );
                } else {
                    this.selectedDbDef.next( null );
                    this.selectedEntityType.next( null );
                    this.existingDb.next( false );
                }
            }),
        ).subscribe();

        this.entityTypeOpts = this.selectedDbDef.pipe(
            map( def => Object.values( def?.entityDefs || {})),
        );
    }

    public ngAfterViewInit() {
        this.filteredOptions = this.dbControl.valueChanges
        .pipe(
            startWith( '' ),
            map( value =>  {
                const name = typeof value === 'string' ? value : value.name;
                const eModels = this.eDataModels.filter( db => db && !!db.isCustom );
                return name ? this._filter( eModels, name ) : eModels;
            }),
        );

        this.filteredDefs = this.defControl.valueChanges.pipe(
            startWith( '' ),
            map( data =>  {
                if ( this.selectedDatabase ) {
                    const name = data && data.name ? data.name : data;
                    const defArr = Object.values( this.selectedDatabase.customEntityDefs ).map(( def: IEntityDef ) =>
                        ({ id: def.id, name: def.name }));
                    return name && typeof name === 'string' ? this._filter( defArr, name ) : defArr;
                }
                return [];
            }),
        );
    }

    ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public closeWindow() {
        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
    }

    public convertToObject() {
        const def = ( this.selectedDbType as BehaviorSubject<IEDataDef> ).getValue();
        if ( def.defId === 'customEDataDef' ) {
            return this.handleSaveClick();
        }
        if ( this.existingDb.value ) {
            // this means it's a diagram model.
            const model = this.predefinedModels[ def.defId ];
            const entityDefId = this.selectedEntityType.getValue().id;
            return this.eDataManage.bindNewEntityToShape( model.id, this.shape.id, entityDefId, true ).pipe(
                tap(() => {
                    this.closeWindow();
                }),
            ).subscribe();
        }
        const name = this.dbName || def.name;
        let obs;
        if ( this.predefinedModels[ def.defId ]) {
            obs = this.objectConvertor.linkDatabase( this.predefinedModels[ def.defId ].id );
        } else {
            obs = this.objectConvertor.createDatabase({ ...def, name });
        }
        return obs.pipe(
            tap(() => {
                this.closeWindow();
            }),
        ).subscribe();
    }

    public handleSaveClick() {
        const nameElValue = this.defControl.value;
        let init = of({});
        let dbId;
        if ( nameElValue || this.selectedDef ) {
            if ( !this.selectedDatabase ) {
                const dbName = this.dbControl.value.trim();
                if ( dbName ) {
                    const mdlFound = this.eDataModels.find( m => m.name === dbName );
                    if ( mdlFound ) {
                        dbId = mdlFound.id;
                    } else {
                        dbId = Random.eDataId();
                        init = this.eDataManage.createCustomDB( dbId, dbName ).pipe(
                            switchMap( eDataSub => eDataSub.status ),
                            filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
                            take( 1 ),
                        );
                    }
                } else {
                    return;
                }
            } else {
                dbId = this.selectedDatabase.id;
            }

            if ( this.selectedDef ) {
                this.eDataManage.bindNewEntityToShape( dbId, this.shape.id, this.selectedDef.id ).pipe(
                    take( 1 ),
                ).subscribe();
            } else {
                init.pipe(
                    switchMap(() => this.eDataManage.createType( dbId, nameElValue ).pipe(
                        // Note: This switchmap is required to make sure the defs property in Edata registry is updated.
                        switchMap( entityDefId =>  EDataRegistry.instance.customEntityDefUpdated( entityDefId )
                            .pipe( mapTo( entityDefId ))),
                        switchMap( entityDefId =>
                            this.eDataManage.bindNewEntityToShape( dbId, this.shape.id, entityDefId )),
                    )),
                ).subscribe();
            }
            const options = {
                inputs: {
                    heading: this.translate.instant( 'NOTIFICATIONS.EDATA.CONVERTED_TO_OBJECT.HEADING' ),
                    description: this.translate.instant( 'NOTIFICATIONS.EDATA.CONVERTED_TO_OBJECT.DESCRIPTION' ),
                    autoDismiss: true,
                },
            };
            this.notifierController.show(
                Notifications.CONVERTED_TO_OBJECT, AbstractNotification, NotificationType.Success, options, false );
            this.closeWindow();
        }
    }

    private _filter<T extends {name?: string}>( options: T[], name: string ): T[] {
        const filterValue = name.toLowerCase();
        return options.filter( option =>
          ( option.name || '' ).toLowerCase().includes( filterValue ));
    }
}
