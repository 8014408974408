import { CommandInterfaces } from 'flux-core';
import { SendHttpExecutionStep } from 'flux-connection';

/**
 * A special version of the SendHttpExecutionStep which will only run
 * when the command is an 'IPluginCommand'.
 */
export class SendPluginCommandsExecutionStep extends SendHttpExecutionStep {
    public static get relatedInterfaces(): Array<CommandInterfaces[]> {
        // NOTE: The confluence plugin can be accessed only when the user
        //       is already authenticated, do not check for auth token here.
        //       Adding 'IAllowAnonymous' temporarily until we figure out
        //       how to provide the authentication service properly.
        return [
            [ 'IMessageCommand', 'IPluginCommand', 'IAllowAnonymous' ],
        ];
    }
}
