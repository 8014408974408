import { AbstractCommand } from '../abstract.cmd';
import { CommandError } from '../error/command-error';

/**
 * <code>ICommandList</code> is used to add a list of commands
 * This can be implemented by a class which needs to add a list of commands
 * @author  gobiga
 * @since   2016.03.17
 *
 */
export interface ICommandList {
    /**
     * Add commands to a command list
     * @param   commandType  An ICommand reference which needs to execute when a partocular
     * command event occurs
     */
    add( commandType: typeof AbstractCommand ): ICommandList;
}


/**
 * This class it self is an array and provides a method to add a list of commands for a
 * particular command event. This extends <code>ICommandList</code> interface.
 * @author  gobiga
 * @since   2016.03.17
 *
 */
export class AbstractCommandList extends Array<typeof AbstractCommand> implements ICommandList {
    constructor() {
        super()/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, AbstractCommandList.prototype );
    }

    /**
     * Adds the ICommand Class to a commandList which needs to be executed one
     * after another for a particular command event.
     * This can return a <code>AbstractCommandList</code> and also used along with the
     * <code><map></code> or <code><mapSequence></code> or <code><mapParallel></code> function to register
     * commands to a particular event name.
     *
     * NOTE: When command lists are created using the CommandMapper, the add method will
     *       be replaced but it will not change the funtionality of the command list.
     *
     * @param   commandType   An ICommand Class reference which can be called when a particular
     * event occurs
     */
    public add( commandType: typeof AbstractCommand ): ICommandList {
        if ( commandType != null ) {
            this.push( commandType );
            return this;
        } else {
            throw new CommandError( 'Invalid value has added.Requires a valid ICommand Class reference' );
        }
    }
}
