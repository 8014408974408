export const diagramTemplatesItems = [

    // {
    //     id: 'blank-document',
    //     name: 'Blank Document',
    //     categories: [],
    //     type: 'diagram/def/block.js#Block',
    // },
    // {
    //     id: 'flowchart',
    //     name: 'Flowchart',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/flowchart-thumb.svg',
    //     type: 'diagram/def/flowchart.js#Flowchart',
    // },
    // {
    //     id: 'block-diagram',
    //     name: 'Block Diagram',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/block-thumb.svg',
    //     type: 'diagram/def/block.js#Block',
    // },
    // {
    //     id: 'dfd',
    //     name: 'Data Flow Diagram (DFD)',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/dfd-thumb.svg',
    //     type: 'diagram/def/dfd.js#DFD',
    // },
    // {
    //     id: 'network',
    //     name: 'Network Diagram',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/network.svg',
    //     type: 'diagram/def/network.js#Network',
    // },
    // {
    //     id: 'aws',
    //     name: 'Amazon Web Services',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/aws.svg',
    //     type: 'diagram/def/aws.js#AWS',
    // },
    // {
    //     id: 'class-diagram',
    //     name: 'Class Diagram',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/uml-class.svg',
    //     type: 'diagram/def/uml-class.js#UMLClass',
    // },
    // {
    //     id: 'database',
    //     name: 'Database',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/database.svg',
    //     type: 'diagram/def/database-def.js#DatabaseDef',
    // },
    // {
    //     id: 'use-case',
    //     name: 'Use Case',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/use-case-thumb.svg',
    //     type: 'diagram/def/usecase.js#UseCase',
    // },
    // {
    //     id: 'er-diagram',
    //     name: 'Entity Relationship',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/er-thumb.svg',
    //     type: 'diagram/def/erd.js#ERD',
    // },
    // {
    //     id: 'concepts-brainstorming',
    //     name: 'Concepts & Brainstorming',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/concept-thumb.svg',
    //     type: 'diagram/def/concept-map.js#ConceptMap',
    // },
    // {
    //     id: 'mindmap',
    //     name: 'Mind Map',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/mind-map.svg',
    //     type: 'diagram/def/mindmap.js#MindMap',
    // },
    // {
    //     id: 'sitemap',
    //     name: 'Site Map',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/sitemaps-thumb.svg',
    //     type: 'diagram/def/site-map.js#SiteMap',
    // },
    // {
    //     id: 'org-chart',
    //     name: 'Organization Chart',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/org-chart.svg',
    //     type: 'diagram/def/org-chart.js#OrgChart',
    // },
    // {
    //     id: 'uml-sequence',
    //     name: 'UML Sequence',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/sequence.svg',
    //     type: 'diagram/def/uml-sequence.js#UMLSequence',
    // },
    // {
    //     id: 'vsm',
    //     name: 'Value Stream Map',
    //     categories: [],
    //     thumbUrl: './assets/images/diagram-thumbs/vsm-thumb.svg',
    //     type: 'diagram/def/vsm.js#VSM',
    // },
    {
        id: 'HzN0Df3TeJ7',
        name: 'Ideas and Brainstorming',
        description: 'Mind-maps and Sticky notes with idea management doc templates built in.',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/uo02wc8w55',
        type: 'diagram/def/mindmap.js#MindMap',
        isProject: true,
    },
    {
        id: 'S05MFQAk0ta',
        name: 'Affinity diagram',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/S05MFQAk0ta',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'C71CRZWfpdP',
        name: 'Affinity diagram template for a meeting',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/C71CRZWfpdP',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'WfGxjzXpLaN',
        name: 'Kanban Board / Agile Planning',
        description: 'Use Kanban Boards and data integrated cards to manage any kind of workflow.',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qyq5sm3ken',
        type: 'diagram/def/concept-map.js#ConceptMap',
        isProject: true,
    },
    {
        id: 'GIUpbp79emx',
        name: 'Roadmap / Timeline',
        description: 'Build a fully customizable data-backed product roadmap, link it to tasks and plans.',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/duit8et45a',
        type: 'diagram/def/concept-map.js#ConceptMap',
        isProject: true,
    },
    {
        id: 'ttQPU8oNU1w',
        name: 'Product Management Toolkit',
        description: 'Idea management, prioritisation, roadmaps, kanban boards all linked together as a complete system.',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/sx3mf8wbqd',
        type: 'diagram/def/flowchart.js#Flowchart',
        isProject: true,
    },
    {
        id: '7xjqeg1mY37',
        name: 'Priority Grid 2x2',
        description: 'Calculate priority scores and rank items in interactive 2x2 grids.',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fidaugryo1',
        type: 'diagram/def/org-chart.js#OrgChart',
        isProject: true,
    },
    {
        id: 'fMPDivvvj5X',
        name: 'OKR & Goal Planner',
        description: 'OKRs, KPI\'s or Strategy alignment templates, which calculates goal completion % in real time.',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/dz7orm5smy',
        type: 'diagram/def/block.js#Block',
        isProject: true,
    },
    {
        id: '726OO3Dzubx',
        name: 'Product Roadmap',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/726OO3Dzubx',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'xdaPFfke1cP',
        name: 'HR Org Structure',
        description: 'Dynamic org structure that allows for master and departmental charts that always stay in sync.',
        categories: [ 'g-process-default', 'hr', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/iw3iryo0ne',
        type: 'diagram/def/block.js#Block',
        isProject: true,
    },
    {
        id: 'ekgxUe8tqMf',
        name: 'Organizational Chart Template',
        categories: [ 'g-process-default', 'g-strategy-default', 'hr' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ekgxUe8tqMf',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: '6Qc5EgrpHhI',
        name: 'Communications Plan Template',
        categories: [ 'g-process-default', 'hr', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6Qc5EgrpHhI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'HDmeQ9hASaL',
        name: 'Website Design Workflow',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/HDmeQ9hASaL',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'lsx3V764aZD',
        name: 'WBS Project Management Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/lsx3V764aZD',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'E2kCwDbs23S',
        name: 'Stakeholder Map - Stakeholder Analysis',
        categories: [ 'g-process-default', 'g-strategy-default', 'hr', 'operations' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/E2kCwDbs23S',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'JFlIbePjxBV',
        name: 'Action Plan Template',
        categories: [ 'g-strategy-default', '' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/JFlIbePjxBV',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'fDwpBy5rOKT',
        name: 'Project Charter Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fDwpBy5rOKT',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'xOHI3jdWGSO',
        name: 'One Page Project Charter',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/xOHI3jdWGSO',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'qC8xpTItKqe',
        name: 'Visual Requirement Specs',
        description: 'Visual requirement specs and detailed notes all in linked to the same canvas.',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zhw2po1ns2',
        type: 'diagram/def/concept-map.js#ConceptMap',
        isProject: true,
    },
    {
        id: 'Kzuw7hJq0iE',
        name: 'Website hierarchy diagram',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Kzuw7hJq0iE',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'RY1qc9KyvUt',
        name: 'Hierarchy diagram template',
        categories: [ 'g-process-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/RY1qc9KyvUt',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'LasZv4XyrB1',
        name: 'Personal SWOT Analysis',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/LasZv4XyrB1',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: '8fCbGdAiFVG',
        name: 'Career Plan Template',
        categories: [ 'g-process-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/8fCbGdAiFVG',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'rsx07OjfJly',
        name: 'Business Contingency Plan Example',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/rsx07OjfJly',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'tHxXFcjllPe',
        name: 'Risk Probability and Impact Matrix',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/tHxXFcjllPe',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'yOaRlppQSzv',
        name: 'Mind Map for Risk Identification',
        categories: [ 'g-meetings-default', 'g-strategy-default', 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yOaRlppQSzv',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'YeN8nvZw6jl',
        name: 'Event Planning Checklist Template',
        categories: [ 'g-meetings-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/YeN8nvZw6jl',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'yeqa2gcnVyZ',
        name: 'Event Planning Organizational Chart',
        categories: [ 'g-meetings-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yeqa2gcnVyZ',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'IsdybRb8ffG',
        name: 'Event Budget Template',
        categories: [ 'g-meetings-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/IsdybRb8ffG',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'nRtiOs6B6XW',
        name: 'Event Timeline Template',
        categories: [ 'g-meetings-default', 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/nRtiOs6B6XW',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6fw84N77EfO',
        name: 'Seating Plan Template',
        categories: [ 'g-meetings-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6fw84N77EfO',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'mbF4eX1edSb',
        name: 'Event schedule Template',
        categories: [ 'g-meetings-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mbF4eX1edSb',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jyjthj0e1',
        name: 'SWOT Analysis',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jyjthj0e1',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: 'jurvba3q8',
        name: 'Porters Five Forces',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jurvba3q8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'mtebvz0Jluh',
        name: 'Customer Profile template',
        categories: [ 'g-sales-default', 'g-strategy-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mtebvz0Jluh',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jf0kajt7Uu8',
        name: 'Product Strategy Canvas',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jf0kajt7Uu8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ko44WgeEfyY',
        name: 'Product Roadmap Template',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ko44WgeEfyY',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'j6de1fn21',
        name: 'Concept Map',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/j6de1fn21',
        type: 'diagram/def/concept-map.js#ConceptMap',
    },
    {
        id: 'x7byzIuNCgh',
        name: 'Mind Map for Creative Thinking',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/x7byzIuNCgh',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'FNnPuf8cohR',
        name: 'Mood board template',
        categories: [ 'g-brainstorm-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/FNnPuf8cohR',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jtqus0ty',
        name: 'Six Thinking Hats Diagram',
        categories: [ 'g-brainstorm-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jtqus0ty',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 's9Ga66IGvvE',
        name: 'Org Chart with Employee Responsibilities',
        categories: [ 'g-process-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/s9Ga66IGvvE',
        type: 'diagram/def/org-chart.js#OrgChart',
        isPremium: true,
    },
    {
        id: 'Tjeti8E0UAi',
        name: 'Candidate Persona',
        categories: [ 'g-process-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Tjeti8E0UAi',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'k2gwoqNlsmF',
        name: 'Recruitment Process',
        categories: [ 'g-process-default', 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/k2gwoqNlsmF',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'Bj8euyVuTmH',
        name: 'Interview Program Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Bj8euyVuTmH',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'fjeEBrXEnjt',
        name: 'Interview Program for candidate',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fjeEBrXEnjt',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'eGInxreqRid',
        name: 'Persuasion Map',
        categories: [ 'g-edu-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/eGInxreqRid',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'juksb6ro2',
        name: 'Speech planning template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/juksb6ro2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jx1nesfe3',
        name: 'Biography worksheet',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jx1nesfe3',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jvoum6r33',
        name: 'Math graphic organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jvoum6r33',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'juksb6ro1',
        name: 'Speech writing template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/juksb6ro1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jx1nesfe1',
        name: 'Biography Graphic Organizer ',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jx1nesfe1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'WjCt692M8uQ',
        name: 'Business capability map',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/WjCt692M8uQ',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'b4uhv8fLAdc',
        name: 'Business capability map template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/b4uhv8fLAdc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '38s9LQpEQrp',
        name: 'Capability Map',
        categories: [ 'g-process-default', 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/38s9LQpEQrp',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'w260VL543i4',
        name: 'IT Capability Map',
        categories: [ 'g-process-default', 'g-diagrams-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/w260VL543i4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jnmo6rg32',
        name: 'Value Chain model',
        categories: [ 'g-process-default', 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jnmo6rg32',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '2EJLmjrfiok',
        name: 'Employee Journey Map',
        categories: [ 'g-process-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/2EJLmjrfiok',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'mR64Sn7aIv6',
        name: 'Candidate Journey Map',
        categories: [ 'g-process-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mR64Sn7aIv6',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Tofz0qbbDcl',
        name: 'Reverse Brainstorming Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Tofz0qbbDcl',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'xl9k89hXKSm',
        name: 'Round Robin Brainstorming Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/xl9k89hXKSm',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '9q2ENhUzoxi',
        name: 'SCAMPER',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/9q2ENhUzoxi',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'ywGCdIheseH',
        name: 'Rose Bud Thorn Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ywGCdIheseH',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'aJhUabFSu2P',
        name: 'SIx Thinking Hats',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/aJhUabFSu2P',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'qMw9sigSkX2',
        name: 'Idea Board Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qMw9sigSkX2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'gilizUwKDXn',
        name: '5 Ws Worksheet',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gilizUwKDXn',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'a8TfpWm0yD6',
        name: 'Performance potential matrix',
        categories: [ 'g-process-default', 'g-diagrams-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/a8TfpWm0yD6',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jNLa5xG2oAA',
        name: 'Talent map template',
        categories: [ 'g-process-default', 'g-diagrams-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jNLa5xG2oAA',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'wdQqxCc1JVy',
        name: 'Scrum Team Org Chart',
        categories: [ 'g-process-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wdQqxCc1JVy',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'CPkfx5wdMEv',
        name: 'Sprint Schedule',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CPkfx5wdMEv',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'a5WdIVb0j1K',
        name: 'Scrum product roadmap',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/a5WdIVb0j1K',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'RamJOJ6YKGk',
        name: 'Product Backlog',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/RamJOJ6YKGk',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'KXK2bT8KzTa',
        name: 'Sprint Backlog',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KXK2bT8KzTa',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '4xKfMq6FF8r',
        name: 'Sprint Tasks Flowchart',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/4xKfMq6FF8r',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'dNofguevDt7',
        name: 'Learning Map Example',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/dNofguevDt7',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'Q8BngEvgquv',
        name: 'Story Map Graphic Organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Q8BngEvgquv',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'NWeaz8u7dCM',
        name: 'Story Map for Kids',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/NWeaz8u7dCM',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'T6fc1HdtKiH',
        name: 'B2B Buyer Persona',
        categories: [ 'g-sales-default', 'g-research-default' , 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/T6fc1HdtKiH',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6ufdRGfSuGB',
        name: 'B2C Buyer Persona',
        categories: [ 'g-sales-default', 'g-research-default' , 'g-strategy-default'  ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6ufdRGfSuGB',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6xGGrPNBg1P',
        name: 'Cluster Diagram Template',
        categories: [ 'g-brainstorm-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6xGGrPNBg1P',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'KFSXoZ7ADlU',
        name: 'Star Diagram Template',
        categories: [ 'g-brainstorm-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KFSXoZ7ADlU',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'DKE43NGvj4t',
        name: 'Goal Setting Worksheet',
        categories: [ 'g-projects-default', 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/DKE43NGvj4t',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Mw3iYnvZif9',
        name: 'Smart Goal Template',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Mw3iYnvZif9',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'dWWGpYe9d9D',
        name: 'Goal Setting Template',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/dWWGpYe9d9D',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'kSS737XxahW',
        name: 'Action Plan for Goal Setting',
        categories: [ 'g-projects-default', 'g-strategy-default'  ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kSS737XxahW',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'o2JCjIUKMRM',
        name: 'Risk Matrix 3x3',
        categories: [ 'g-strategy-default', 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/o2JCjIUKMRM',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'tKsYXhhg2Uf',
        name: '5x5 Risk Matrix Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/tKsYXhhg2Uf',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'If3QJU9kR5i',
        name: 'Risk Matrix Template',
        categories: [ 'g-strategy-default', 'g-projects-default' , 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/If3QJU9kR5i',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'TBUjelOcMf2',
        name: 'Career development Plan',
        categories: [ 'g-process-default', 'g-diagrams-default' , 'g-strategy-default'  ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/TBUjelOcMf2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'avq7yjw0H12',
        name: 'Lotus Diagram',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/avq7yjw0H12',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'l1jLBufUfg2',
        name: 'SMART Goal Worksheet',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/l1jLBufUfg2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'B4NCYFgcr3D',
        name: 'Smart Goals Template for Students',
        categories: [ 'g-edu-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/B4NCYFgcr3D',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'OSwQw2DuulU',
        name: 'Business action plan',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/OSwQw2DuulU',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6CROhs5hQHc',
        name: 'Marketing Action Plan',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6CROhs5hQHc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'KkMMQo8lrqO',
        name: 'Strategic Action Plan',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KkMMQo8lrqO',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'AO61grfHH9S',
        name: 'Action Plan Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AO61grfHH9S',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ErSNlIWrd5o',
        name: 'Corrective Action Plan',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ErSNlIWrd5o',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'yU6C09VzyHB',
        name: 'Simple action plan template',
        categories: [ 'g-projects-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yU6C09VzyHB',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'IY5HERuiqS0',
        name: 'Start stop continue template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/IY5HERuiqS0',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'myfFwi23bgT',
        name: 'Start stop continue template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/myfFwi23bgT',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '91vK8eaGtmB',
        name: 'Sprint Retrospective template',
        categories: [ 'g-projects-default', 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/91vK8eaGtmB',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'bJ8ke7W9Fho',
        name: 'RACI chart template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bJ8ke7W9Fho',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'qSJE4afDprY',
        name: 'RACI matrix template',
        categories: [ 'g-projects-default', 'g-process-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qSJE4afDprY',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CaK15VdznEg',
        name: 'Skills inventory template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CaK15VdznEg',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Vv0Fnt2fNTA',
        name: 'SWOT Analysis for HR Planning',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Vv0Fnt2fNTA',
        type: 'diagram/def/swot.js#Swot',
        isPremium: true,
    },
    {
        id: 'eNuRAhHZC9P',
        name: 'Organizational Chart for HR Planning',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/eNuRAhHZC9P',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'c6r199MLuW7',
        name: '9 Box Grid',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/c6r199MLuW7',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'zu9k7tKeXOX',
        name: 'scenario planning matrix template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zu9k7tKeXOX',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'TgnxbNhEeWD',
        name: 'replacement chart',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/TgnxbNhEeWD',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'elCSCRuhBu8',
        name: 'Strategy Diamond Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/elCSCRuhBu8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Gn2OXb1QukR',
        name: 'Work plan template',
        categories: [ 'g-projects-default', 'g-process-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Gn2OXb1QukR',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'RaDFL2vDvQT',
        name: 'Venn Diagram Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/RaDFL2vDvQT',
        type: 'diagram/def/venn.js#Venn',
    },
    {
        id: '7HonVSbhU3X',
        name: 'Sequence Graphic Organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/7HonVSbhU3X',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hLsQmmuEMIo',
        name: 'KWL Chart',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hLsQmmuEMIo',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '9540WH5ey0N',
        name: 'Analogy graphic organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/9540WH5ey0N',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'RcMEYLmJTWI',
        name: 'Vocabulary graphic organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/RcMEYLmJTWI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '4HAkAWfamUk',
        name: 'Problem solution graphic organizer',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/4HAkAWfamUk',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'iZHxCXvrHtU',
        name: 'Timeline Diagram Template',
        categories: [ 'g-projects-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/iZHxCXvrHtU',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'GFis1JpC7ue',
        name: 'Impact Effort Matrix Template',
        categories: [ 'g-strategy-default', 'g-process-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/GFis1JpC7ue',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'h1sF3b4prwg',
        name: 'Benefit effort matrix',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/h1sF3b4prwg',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CMd4UAmcmQP',
        name: 'Meeting Notes Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CMd4UAmcmQP',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'oSN2nsvb8AS',
        name: 'Meeting Minutes Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/oSN2nsvb8AS',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Du8VscInzEM',
        name: 'Staff Meeting Notes',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Du8VscInzEM',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'uJE97g8KB0X',
        name: 'Business Model Canvas',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/uJE97g8KB0X',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6c7Nt1mzhU9',
        name: 'Business Model Canvas Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6c7Nt1mzhU9',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'zB7yanBaRcs',
        name: 'Customer Journey Map',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zB7yanBaRcs',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'bMe02n078Jq',
        name: 'Star Method Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bMe02n078Jq',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'lpgOuJt8nS1',
        name: 'Star Interview Method Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/lpgOuJt8nS1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'McSPyAWwaiN',
        name: 'Star Interview Method',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/McSPyAWwaiN',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'zajIxYrclTH',
        name: 'TOWS matrix',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zajIxYrclTH',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'GwyguPRK29C',
        name: 'TOWS matrix template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/GwyguPRK29C',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'P5hnMMgl12w',
        name: 'Porters Five Forces',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/P5hnMMgl12w',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'xe1xyFLZYzV',
        name: 'BCG Matrix',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/xe1xyFLZYzV',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'X0YWS9kvsi8',
        name: 'GE McKinsey Matrix',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/X0YWS9kvsi8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'tHNu8ENnss2',
        name: 'Ansoff Matrix',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/tHNu8ENnss2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'qanJJGSX3bC',
        name: 'VRIO Analysis Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qanJJGSX3bC',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'vWB3iD2htHb',
        name: 'McKinsey 7S Model',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vWB3iD2htHb',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'XCUpcF2xkLM',
        name: 'Dichotomous key for insects',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/XCUpcF2xkLM',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CCzBCLmreuI',
        name: 'Dichotomous key for plants',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CCzBCLmreuI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'chFEgPK8XjI',
        name: 'SOAR analysis template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/chFEgPK8XjI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'rE1ZRMLUGKi',
        name: 'Business process reengineering steps',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/rE1ZRMLUGKi',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Kfev2QZGZsl',
        name: 'Team Charter Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Kfev2QZGZsl',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'nbietNLiLM1',
        name: 'ADL Matrix Template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/nbietNLiLM1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'JRGxwN8KK6r',
        name: 'Resource breakdown structure',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/JRGxwN8KK6r',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'H3EsCVLbCuR',
        name: 'Kotters 8 step change model',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/H3EsCVLbCuR',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'juwbtpns',
        name: 'SWOT analysis for strategic planning',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/juwbtpns',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: 'aCueffgXpVe',
        name: 'Project Timeline Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/aCueffgXpVe',
        type: 'diagram/def/gantt.js#Gantt',
    },
    {
        id: 'XT5Dd91l3HE',
        name: 'Hedgehog concept template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/XT5Dd91l3HE',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'xsCFLo8jHiM',
        name: 'Customer Journey Map Template',
        categories: [ 'g-sales-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/xsCFLo8jHiM',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'AffkE2VOrfp',
        name: 'Check sheet template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AffkE2VOrfp',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'UhJjb8diQcC',
        name: 'Histogram',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/UhJjb8diQcC',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Mszf2SYXzA5',
        name: 'Control chart',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Mszf2SYXzA5',
        type: 'diagram/def/g&c.js#G&C',
    },
    {
        id: '5wU7TbZ6yE3',
        name: 'Service Blueprint Template',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/5wU7TbZ6yE3',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'j73OorGDAph',
        name: 'Restaurant Service Blueprint',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/j73OorGDAph',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'B2bVQPlbgLc',
        name: 'Hotel Service Blueprint',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/B2bVQPlbgLc',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: '6lXDYoIPyRY',
        name: 'Hospital service blueprint',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6lXDYoIPyRY',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 's4RR1stVpyd',
        name: 'User registration flow diagram',
        categories: [ 'g-software-default', 'g-diagrams-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/s4RR1stVpyd',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'T2lGyoyFg10',
        name: 'Product Positioning Template',
        categories: [ 'g-sales-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/T2lGyoyFg10',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '46Msp9mCyv0',
        name: 'Blog Post Outline',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/46Msp9mCyv0',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '4414Pk1uhfa',
        name: 'The Writing Process',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/4414Pk1uhfa',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'qMw9sigSkX2',
        name: 'Idea Board Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qMw9sigSkX2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'VDM4HoGAYNr',
        name: 'Force Filed Analysis Worksheet',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/VDM4HoGAYNr',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'g2NPgVHh100',
        name: 'Five Product Levels Template',
        categories: [ 'g-sales-default', 'g-diagrams-default' , 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/g2NPgVHh100',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CsWJdaYbXGh',
        name: 'Horizontal timeline template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CsWJdaYbXGh',
        type: 'diagram/def/timeline.js#Timeline',
        isPremium: true,
    },
    {
        id: 'IHFJv6Fwyu3',
        name: 'Business Timeline Template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/IHFJv6Fwyu3',
        type: 'diagram/def/timeline.js#Timeline',
        isPremium: true,
    },
    {
        id: 'vuHkF8ggHWN',
        name: 'Personal Timeline Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vuHkF8ggHWN',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'bQaYaHZpZ9T',
        name: 'Career timeline template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bQaYaHZpZ9T',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'CMdG1pontCA',
        name: 'Wedding timeline template',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CMdG1pontCA',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'uXploKQWoYN',
        name: 'Event Timeline',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/uXploKQWoYN',
        type: 'diagram/def/timeline.js#Timeline',
        isPremium: true,
    },
    {
        id: 'L8Oj6B6o3Qs',
        name: 'Research Timeline',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/L8Oj6B6o3Qs',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: '1TDK1rGsFJt',
        name: 'American Revolution Timeline',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/1TDK1rGsFJt',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'G31ClrxWbff',
        name: 'Martin Luther King timeline',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/G31ClrxWbff',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'OJQ8Ef3FA9D',
        name: 'PowerPoint timeline template',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/OJQ8Ef3FA9D',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'BwcPsPhCNUC',
        name: 'History Timeline Template',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/BwcPsPhCNUC',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'ZusiE6xz7gb',
        name: 'Monthly Timeline Template',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ZusiE6xz7gb',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'XMyx7DzaIOJ',
        name: 'Vertical Timeline',
        categories: [ 'g-edu-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/XMyx7DzaIOJ',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'd04mtm16pAS',
        name: 'Weisboards Six Box Model',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/d04mtm16pAS',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '5BjM7D076oj',
        name: 'RATER Model Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/5BjM7D076oj',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Ec1LwCuMShz',
        name: 'Porters Diamond Model',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Ec1LwCuMShz',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'riZ3EnPRFdr',
        name: 'Porters Diamond Model Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/riZ3EnPRFdr',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '2UqKSVeag5S',
        name: 'Remote Hiring Process',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/2UqKSVeag5S',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'C13hz4sllYt',
        name: 'AIDA model Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/C13hz4sllYt',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'yxjmU97R8V7',
        name: 'Remote Employee Onboarding Checklist',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yxjmU97R8V7',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'a7jC88BhoEf',
        name: 'Google Cloud Diagram',
        categories: [ 'g-it-network-default', 'g-research-default' , 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/a7jC88BhoEf',
        type: 'diagram/def/gcp.js#GCP',
    },
    {
        id: 'EuyxcUMyK3P',
        name: 'Turtle Diagram Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/EuyxcUMyK3P',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'KNe3MaZ543w',
        name: 'OGSM Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KNe3MaZ543w',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'UdpavweuYmc',
        name: 'Project Management Lifecycle',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/UdpavweuYmc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'zYDRBNquHkg',
        name: 'Microsoft Azure Architecture Diagram Example',
        categories: [ 'g-it-network-default', 'g-research-default' , 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zYDRBNquHkg',
        type: 'diagram/def/azure.js#Azure',
        isPremium: true,
    },
    {
        id: 'm3O1O8AgSKY',
        name: 'STP Model',
        categories: [ 'g-strategy-default', 'g-sales-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/m3O1O8AgSKY',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'u1lNkXdhfMH',
        name: 'Checklist Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/u1lNkXdhfMH',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '0y6f9tp2adO',
        name: 'Synoptic Table Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/0y6f9tp2adO',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'P2NlbIA9PO0',
        name: 'Wardley Map template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/P2NlbIA9PO0',
        type: 'diagram/def/g&c.js#G&C',
    },
    {
        id: 'gTpGwxSJNNe',
        name: 'RAID log template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gTpGwxSJNNe',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'q8EEc8HL93j',
        name: 'Lesson Plan Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/q8EEc8HL93j',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'wf9FRCNye0I',
        name: '5S System PPT',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wf9FRCNye0I',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'pN3OmOjBkid',
        name: '5S Audit Checklist',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/pN3OmOjBkid',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'DsySxhiwVtq',
        name: 'Doughnut Chart Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/DsySxhiwVtq',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CKTgNhpewmv',
        name: 'Doughnut Chart PPT',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CKTgNhpewmv',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '0mpZh6BKJvs',
        name: 'Mind map for project managment',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/0mpZh6BKJvs',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'HJP5a53ver4',
        name: 'Presentation Templates',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/HJP5a53ver4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'juksb6ro5',
        name: 'Brainstorming Map Template ',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/juksb6ro5',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'b3Oe0OdHt0K',
        name: 'User registration flow chart',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/b3Oe0OdHt0K',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'mYWZusnwp3o',
        name: 'Buyer Utility Map Template',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mYWZusnwp3o',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ReutHhh4gus',
        name: 'Confrontation Matrix Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ReutHhh4gus',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'lVP157qKfo0',
        name: 'Knowledge managment concpet map',
        categories: [ 'g-edu-default', 'g-diagrams-default' , 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/lVP157qKfo0',
        type: 'diagram/def/concept-map.js#ConceptMap',
    },
    {
        id: '8LzuPNjdryF',
        name: 'Supply and demand graph template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/8LzuPNjdryF',
        type: 'diagram/def/g&c.js#G&C',
    },
    {
        id: 'fXH7Hh5vhVu',
        name: 'Editable Certificate Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fXH7Hh5vhVu',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'BVJzef5PKaw',
        name: 'Certificate Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/BVJzef5PKaw',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'POuuQBlyGgG',
        name: 'Gift Certificate Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/POuuQBlyGgG',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'AXJI24YP1hE',
        name: 'Certificate of Appreciation',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AXJI24YP1hE',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'MZEJKP3TGkF',
        name: 'Weekly school schedule template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/MZEJKP3TGkF',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'twk7xUDwDuW',
        name: 'School Schedule maker-time table',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/twk7xUDwDuW',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Er88J9nmyru',
        name: 'comic strip template 1',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Er88J9nmyru',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'bquAL7lpJnl',
        name: 'Comic strip template 3',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bquAL7lpJnl',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jvnd8ggz4',
        name: 'Weekly Planning Template ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jvnd8ggz4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'QcPXgDX1J7N',
        name: 'Gantt chart high level project plan',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/QcPXgDX1J7N',
        type: 'diagram/def/gantt.js#Gantt',
    },
    {
        id: 'wemAOVmwO3I',
        name: 'Fibonacci Scale template 1',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wemAOVmwO3I',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'RD0zR2UayYI',
        name: 'Fibonacci Scale template 2',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/RD0zR2UayYI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'KuDjY4V0fbZ',
        name: 'Fibinacci scale template 3',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KuDjY4V0fbZ',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '0cBRljyFOuw',
        name: 'Logic Model Template',
        categories: [ 'g-projects-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/0cBRljyFOuw',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'cA6vJxXO0R2',
        name: 'Logic Model',
        categories: [ 'g-projects-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/cA6vJxXO0R2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Ub2Y285Zrv1',
        name: 'Plus delta chart',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Ub2Y285Zrv1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'deldrL4mZwI',
        name: 'Plus delta chart template',
        categories: [ 'g-meetings-default', 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/deldrL4mZwI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hbZk6jZ7isW',
        name: 'Opportunity Chart template 1',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hbZk6jZ7isW',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'NFYYP1rCXLh',
        name: 'Goal Seeting Worksheet',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/NFYYP1rCXLh',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ZeyCgkKebWt',
        name: 'Cladogram of primates',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ZeyCgkKebWt',
        type: 'diagram/def/g&c.js#G&C',
    },
    {
        id: 'k8ScAZ5pFLG',
        name: 'African Savanna Food Web',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/k8ScAZ5pFLG',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'qteihkemus2',
        name: 'Skill Tree Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qteihkemus2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'kyE4CNcSFiR',
        name: 'Word Web Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kyE4CNcSFiR',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'wxX0nvocHf8',
        name: 'Phylogenetic Tree Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wxX0nvocHf8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'gT4xM94iFai',
        name: 'Creative Matrix Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gT4xM94iFai',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'zgWdUu4KBVk',
        name: 'Brainwriting worksheet',
        categories: [ 'g-brainstorm-default', 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zgWdUu4KBVk',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'WWwtARcBrbw',
        name: 'Brainwriting template',
        categories: [ 'g-brainstorm-default', 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/WWwtARcBrbw',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'HIehYhAMxna',
        name: 'Storyboard Template for Online teaching',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/HIehYhAMxna',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'r83fBhwTJQU',
        name: 'Meeting Canvas Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/r83fBhwTJQU',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'OjJ6SlU3Fio',
        name: 'Standup meeting Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/OjJ6SlU3Fio',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'SyO37AZ3bdO',
        name: 'Assumption Grid Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/SyO37AZ3bdO',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'evnRBZkOxQE',
        name: 'Editorial Calendar Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/evnRBZkOxQE',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'zljQADUS2te',
        name: 'Social Media Editorial Calendar',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zljQADUS2te',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '6bczkyk2bxN',
        name: 'Stratergy Blueprint',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/6bczkyk2bxN',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'yIi8TjOgQd9',
        name: 'Strategy Blueprint 2',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yIi8TjOgQd9',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'kZaJfqje5yf',
        name: 'Lesson Learned Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kZaJfqje5yf',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'EdINvP5cnkM',
        name: 'sequence diagram tool-ATM',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/EdINvP5cnkM',
        type: 'diagram/def/uml.js#UML',
    },
    {
        id: 'yJzQpqlgsV9',
        name: 'Product-market fit canvas template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' , 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yJzQpqlgsV9',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'cffMt76MVrg',
        name: 'Presentation Canvas',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/cffMt76MVrg',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Q0RtzcVZFCa',
        name: 'Checklist Maker',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Q0RtzcVZFCa',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'POrF4flP5e4',
        name: 'OKR Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/POrF4flP5e4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hbPT2y1Pyky',
        name: 'OKR Goal Setting Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hbPT2y1Pyky',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'g4nxEBiLhaI',
        name: 'Flowchart',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/g4nxEBiLhaI',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'ddPVp5iSnAW',
        name: 'Mind Map',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ddPVp5iSnAW',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'bWR4c0jH0fI',
        name: 'Org Chart',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bWR4c0jH0fI',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'vAnBkdf0iQR',
        name: 'Concept Map',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vAnBkdf0iQR',
        type: 'diagram/def/concept-map.js#ConceptMap',
    },
    {
        id: 'vyWUec9kb7U',
        name: 'Infographic',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vyWUec9kb7U',
        type: 'diagram/def/infographics.js#Infographics',
    },
    {
        id: 'QntZ3BLMuEE',
        name: 'UML Class',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/QntZ3BLMuEE',
        type: 'diagram/def/uml-class.js#UMLClass',
    },
    {
        id: 'tN3HFAAsYmP',
        name: 'UML Use Case',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/tN3HFAAsYmP',
        type: 'diagram/def/usecase.js#UseCase',
    },
    {
        id: 'ijS6B7RhEZr',
        name: 'Database',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ijS6B7RhEZr',
        type: 'diagram/def/database.js#Database',
    },
    {
        id: 'zwsG9Afaa1x',
        name: 'Dataflow Diagram',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/zwsG9Afaa1x',
        type: 'diagram/def/dataflow.js#Dataflow',
    },
    {
        id: 'x0tdROM9jnJ',
        name: 'Network Diagram',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/x0tdROM9jnJ',
        type: 'diagram/def/network.js#Network',
        isPremium: true,
    },
    {
        id: 'mDyRnrZOhhT',
        name: 'AWS Architecture',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mDyRnrZOhhT',
        type: 'diagram/def/aws.js#AWS',
        isPremium: true,
    },
    {
        id: '8KsJasqZvJR',
        name: 'GCP Diagram',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/8KsJasqZvJR',
        type: 'diagram/def/gcp.js#GCP',
    },
    {
        id: 'TsYGVyzcQPj',
        name: 'Azure Diagram',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/TsYGVyzcQPj',
        type: 'diagram/def/azure.js#Azure',
        isPremium: true,
    },
    {
        id: 'AGzYegpWoL0',
        name: 'Timeline',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AGzYegpWoL0',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'CF8GdWKUwbD',
        name: 'Service Blueprint',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CF8GdWKUwbD',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'pnVQkHp0S0F',
        name: 'Gantt Chart',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/pnVQkHp0S0F',
        type: 'diagram/def/gantt.js#Gantt',
    },
    {
        id: 'gjpGKi7xseB',
        name: 'UI Mockup',
        categories: [ 'g-popular-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gjpGKi7xseB',
        type: 'diagram/def/ui-mockup.js#UIMockup',
    },
    {
        id: 'UAYc2utB4Rv',
        name: 'Site Map',
        categories: [ 'g-popular-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/UAYc2utB4Rv',
        type: 'diagram/def/site-map.js#SiteMap',
    },
    {
        id: 'jw8sulqr',
        name: '5 Whys Analysis ',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jw8sulqr',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: '3TXbrd3dB0l',
        name: 'Affinity Diagram Example',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/3TXbrd3dB0l',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'vbnXKMzGvdJ',
        name: 'Brainwriting Worksheet',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vbnXKMzGvdJ',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'jqq75c11',
        name: 'Cause and Effect Diagram for DMAIC',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jqq75c11',
        type: 'diagram/def/cause-effect.js#CauseEffect',
    },
    {
        id: 'C9T1mnLB0BK',
        name: 'Concept Map Format',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/C9T1mnLB0BK',
        type: 'diagram/def/concept-map.js#ConceptMap',
    },
    {
        id: 'jrsug0ys',
        name: 'Fishbone Diagram Template ',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jrsug0ys',
        type: 'diagram/def/fishbone.js#FishBone',
    },
    {
        id: 'uoiQEEDCzws',
        name: 'Flowchart Template with Multiple Ends',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/uoiQEEDCzws',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'otTpeHY5q5m',
        name: 'Idea Board',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/otTpeHY5q5m',
        type: 'diagram/def/brainstorming.js#Brainstorming',
        isPremium: true,
    },
    {
        id: 'DmLfCItDIcH',
        name: 'Mind Map Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ymgB0taW3R6',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'jti4pmsd1',
        name: 'Mood Board Template ',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jti4pmsd1',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'jujgf3952',
        name: 'SCAMPER Technique ',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jujgf3952',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'jensmqty2',
        name: 'New Starbusting Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jensmqty2',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'KwFrAYjD2Gl',
        name: 'SWOT Analysis for Situation Analysis',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/KwFrAYjD2Gl',
        type: 'diagram/def/strategic-planning.js#StrategicPlanning',
    },
    {
        id: 'C0H6MUH3LNa',
        name: 'Brainstorming Board Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/C0H6MUH3LNa',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'og3VQvFTWTp',
        name: '4 Ls Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/og3VQvFTWTp',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'hQZkOM5DWl9',
        name: 'Cluster / Word Web Template',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hQZkOM5DWl9',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },
    {
        id: 'l8xQH3gfEkX',
        name: 'Describing Wheel Example',
        categories: [ 'g-brainstorm-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/l8xQH3gfEkX',
        type: 'diagram/def/brainstorming.js#Brainstorming',
    },

    {
        id: 'jtfjhdx11',
        name: 'Ansoff Matrix Examples ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jtfjhdx11',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jrbtfcti1',
        name: 'BCG Matrix Examples ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jrbtfcti1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'h5ujlu1s2',
        name: 'iPhone ordering process in BPMN',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/h5ujlu1s2',
        type: 'diagram/def/bpmn.js#BPMN',
    },
    {
        id: 'jji6j1jv2',
        name: 'Business Model Canvas Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jji6j1jv2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'j20af6gl4',
        name: 'Flowchart Template with Multiple Ends ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/j20af6gl4',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'jne3wmr24',
        name: 'GE- McKinsey Matrix ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jne3wmr24',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'gsx1cnem5',
        name: 'PEST Analysis Example',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gsx1cnem5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jji4zkuq5',
        name: 'Porter Five Forces Template ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jji4zkuq5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'vm0BFZ7j8iB',
        name: 'Simple Product Roadmap ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vm0BFZ7j8iB',
        type: 'diagram/def/gantt.js#Gantt',
        isPremium: true,
    },
    {
        id: 'jl640irm4',
        name: 'Scenario Planning',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jl640irm4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jl640irm5',
        name: 'SOAR Model',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jl640irm5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'mns6ZzuogIs',
        name: 'Strategy Diamond',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/mns6ZzuogIs',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hgsCZFbHjkS',
        name: 'Strategy Map Example',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hgsCZFbHjkS',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'oJUEo5Ci3no',
        name: 'SWOT Analysis for Situation Analysis ',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/oJUEo5Ci3no',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: 'Gsw0hLtjlZU',
        name: 'Assumption & Constraint Analysis Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Gsw0hLtjlZU',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'Rs4c7qLRkn6',
        name: 'Critical-to-quality Tree-Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/Rs4c7qLRkn6',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '7wmAYGjVyzi',
        name: 'Evaporating Cloud-Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/7wmAYGjVyzi',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jn2uxnc55',
        name: 'Action Priority Matrix ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jn2uxnc55',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'QhB4SRrIiDF',
        name: 'Activity Diagram Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/QhB4SRrIiDF',
        type: 'diagram/def/uml-activity.js#UMLActivity',
    },
    {
        id: 'juv3pd4e',
        name: 'Strategic Planning Gantt Chart ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/juv3pd4e',
        type: 'diagram/def/gantt.js#Gantt',
    },
    {
        id: 'pAt3pzqQrnM',
        name: 'Org Chart Starter Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/pAt3pzqQrnM',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'jn4gomx51',
        name: 'PERT Chart for Project Planning',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jn4gomx51',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jjs69bs39',
        name: 'Pick Chart Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jjs69bs39',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'GCsmMu5j0B2',
        name: 'Product Backlog Board ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/GCsmMu5j0B2',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jkm6v7le1',
        name: 'Simple Product Roadmap ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jkm6v7le1',
        type: 'diagram/def/gantt.js#Gantt',
    },
    {
        id: 'jwfvlsl61',
        name: 'Visual Project Charter ',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jwfvlsl61',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jrg3jbf0',
        name: 'Project Schedule Example [classic]',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jrg3jbf0',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'cphjlJTzesu',
        name: 'Resource Breakdown Structure Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/cphjlJTzesu',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jripkdb23',
        name: 'Stakeholder Map for Stakeholder Identification [classic]',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jripkdb23',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'QRyxIY9yu2T',
        name: 'Work Breakdown Structure Template for a Company Project',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/QRyxIY9yu2T',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'geqyowvc6',
        name: 'New Support Process Flowchart for Web App',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/geqyowvc6',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'dFSGPB2RD7c',
        name: 'Daily Standup- Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/dFSGPB2RD7c',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '9FLIWzGGEfI',
        name: 'Resource Calendar Template- Monthly & Yearly',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/9FLIWzGGEfI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'snHIfCaVwIq',
        name: 'Project Dashboard Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/snHIfCaVwIq',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ZFd6vIzLUmZ',
        name: 'Retrospective Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ZFd6vIzLUmZ',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '2VMVWLI1Flb',
        name: 'Timeline for Onboarding Template',
        categories: [ 'g-projects-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/2VMVWLI1Flb',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'DQbqPtuF4f7',
        name: 'Activity Diagram Template',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/DQbqPtuF4f7',
        type: 'diagram/def/uml-activity.js#UMLActivity',
    },
    {
        id: '7p2fJZ0DzW6',
        name: 'Hospital Management System - Class Diagram',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gsxncbybs',
        type: 'diagram/def/uml-class.js#UMLClass',
    },
    {
        id: 'jmk6au3v1',
        name: 'Deployment Diagram for Library Management System [classic]',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jmk6au3v1',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'ingwfn843',
        name: 'Library Management System - Level 1 DFD',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ingwfn843',
        type: 'diagram/def/dataflow.js#Dataflow',
    },
    {
        id: 'inviguus1',
        name: 'Bus Reservation System - ERD',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/inviguus1',
        type: 'diagram/def/erd.js#ERD',
        isPremium: true,
    },
    {
        id: 's4ZK8ts28U8',
        name: 'Flowchart Template with Multiple Ends',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/s4ZK8ts28U8',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'imef77001',
        name: 'Hotel Management System - Sequence Diagram Template',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/imef77001',
        type: 'diagram/def/uml-sequence.js#UMLSequence',
    },
    {
        id: 'WEeWHh19Ztk',
        name: 'Skill Tree Diagram',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/WEeWHh19Ztk',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ipt6bcpc2',
        name: 'Payroll System - UI Mockup Example [classic]',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ipt6bcpc2',
        type: 'diagram/def/ui-mockup.js#UIMockup',
    },
    {
        id: 'PxbdLLkVgSn',
        name: 'Restaurant Order System Use Case',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ie54gr4b1',
        type: 'diagram/def/usecase.js#UseCase',
    },
    {
        id: 'j700m0v8',
        name: 'Online Shopping Website Product Page Wireframe ',
        categories: [ 'g-software-default', 'g-diagrams-default' , 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/j700m0v8',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'UcdLwgGNGoh',
        name: 'Experience Map Template',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/UcdLwgGNGoh',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '4rqKXDLuOrm',
        name: 'Task Flow Template',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/4rqKXDLuOrm',
        type: 'diagram/def/ui-mockup.js#UIMockup',
    },
    {
        id: '8IG7KG62iSI',
        name: 'Design Thinking Process Example',
        categories: [ 'g-software-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/8IG7KG62iSI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'JdPrX3yByFm',
        name: 'Empathy Map Template with Pains and Gains',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/JdPrX3yByFm',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'gvlBMLbRZTa',
        name: 'Task Flow Example',
        categories: [ 'g-software-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gvlBMLbRZTa',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'UaqiLeWRQgI',
        name: 'User Persona Template',
        categories: [ 'g-software-default', 'g-diagrams-default' , 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/UaqiLeWRQgI',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'S7dte3cnZDe',
        name: 'User Story Map Template',
        categories: [ 'g-software-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/S7dte3cnZDe',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'svV5wHbfLtm',
        name: 'AWS Starter Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/svV5wHbfLtm',
        type: 'diagram/def/aws.js#AWS',
        isPremium: true,
    },
    {
        id: 'g6lp80gw1',
        name: 'Basic Network Diagram Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/g6lp80gw1',
        type: 'diagram/def/network.js#Network',
    },
    {
        id: 'iqn5eteo3',
        name: 'Spanning Tree Protacol Cisco Example ',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/iqn5eteo3',
        type: 'diagram/def/cisco.js#Cisco',
    },
    {
        id: 'iqj71w2z2',
        name: 'Internet Firewall Deployment [classic]',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/iqj71w2z2',
        type: 'diagram/def/network.js#Network',
    },
    {
        id: 'SFOexgh5zLz',
        name: 'Kubernetes Diagram Example (RBAC Model)',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/SFOexgh5zLz',
        type: 'diagram/def/kubernetes.js#Kubernetes',
    },
    {
        id: 'crPmaFo9uY4',
        name: 'Site Map Starter Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/crPmaFo9uY4',
        type: 'diagram/def/site-map.js#SiteMap',
    },
    {
        id: 'wGnZfh0VO5P',
        name: 'Incident Identification Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wGnZfh0VO5P',
        type: 'diagram/def/flowchart.js#Flowchart',
        isPremium: true,
    },
    {
        id: 'fTqSiJszvrc',
        name: 'Example for Service Transition Steps for Outsourcing',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fTqSiJszvrc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'HZ2fGSL7Nn5',
        name: 'Google Cloud Platform - Horizontal Framework - Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/HZ2fGSL7Nn5',
        type: 'diagram/def/gcp.js#GCP',
    },
    {
        id: 'fbdvo93cFek',
        name: 'Network Security Diagram Example',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fbdvo93cFek',
        type: 'diagram/def/network.js#Network',
    },
    {
        id: 'ZiuSu0EkfNN',
        name: 'Ownership, Monitoring, Tracking, and Communications Example',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ZiuSu0EkfNN',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'e0yexPzgUvH',
        name: 'Incident Logging Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/e0yexPzgUvH',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: '1pUKTwX9upw',
        name: 'Incident Categorization & Prioratization Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/1pUKTwX9upw',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: '2FiVakprdoO',
        name: 'Initial Diagnosis Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/2FiVakprdoO',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'g6wZBW5kZkg',
        name: 'Application Architecture Example',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/g6wZBW5kZkg',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'c5JMedWsSpq',
        name: 'CI/CD Pipeline Example',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/c5JMedWsSpq',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'DDcd9czHG8c',
        name: 'Change Management High-Level Process Flow Template',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/DDcd9czHG8c',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hVqkmVq3wAs',
        name: 'Cloud Backup Diagram Example',
        categories: [ 'g-it-network-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hVqkmVq3wAs',
        type: 'diagram/def/azure.js#Azure',
    },
    {
        id: 'jl640irm7',
        name: '9 Box Model',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jl640irm7',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'joicw6gm5',
        name: 'Career Development Plan Example',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/joicw6gm5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jw5dl10q1',
        name: 'Grow Model Template ',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jw5dl10q1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'XiO2iyEvIor',
        name: 'Org Chart Starter Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/XiO2iyEvIor',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: '3RuRe4VsCUX',
        name: 'Process Map Starter Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/3RuRe4VsCUX',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'gsy8pdq4f',
        name: 'Recruitment Process Flowchart',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gsy8pdq4f',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'jkwkspm5',
        name: 'Team Canvas ',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jkwkspm5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'kP45F2uelHY',
        name: 'Team Charter Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kP45F2uelHY',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'gzqom7bb1',
        name: 'Work Breakdown Structure Template for a Company Project',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gzqom7bb1',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'jkw3k76e9',
        name: 'Agile Marketing Workflow ',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jkw3k76e9',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'qAWQTkzjYNL',
        name: '1:1 Meeting Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/qAWQTkzjYNL',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '60UqroBxOwB',
        name: 'Inverted Org Charts Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/60UqroBxOwB',
        type: 'diagram/def/org-chart.js#OrgChart',
        isPremium: true,
    },
    {
        id: '2owbQe5jhJc',
        name: '4 Steps to Strategic Human Resources Planning Example',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/2owbQe5jhJc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'oNYFFqhKZfe',
        name: 'Church Org Chart Template',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/oNYFFqhKZfe',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'gSGjPzBhWUy',
        name: 'Circular Org Chart Example',
        categories: [ 'g-process-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/gSGjPzBhWUy',
        type: 'diagram/def/org-chart.js#OrgChart',
    },
    {
        id: 'jr7hddam4',
        name: 'Action Plan Template ',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jr7hddam4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'wexJ9XvNzB5',
        name: 'Affinity Diagram Example',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/wexJ9XvNzB5',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'kQWzj9xprOw',
        name: 'Cause and Effect Diagram for DMAIC',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kQWzj9xprOw',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '478bk9cDMgh',
        name: 'Flowchart Template with Multiple Ends',
        categories: [ 'g-meetings-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/478bk9cDMgh',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'ghICA3b22p6',
        name: 'Goal Setting Worksheet',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ghICA3b22p6',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'NgWxDLnx1NW',
        name: 'Idea Board Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/NgWxDLnx1NW',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jos61nj49',
        name: 'Product Backlog Board ',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jos61nj49',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jvoum6r32',
        name: 'Scenario Planning Example ',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jvoum6r32',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jr93eley4',
        name: 'Six Thinking Hats Template for Brainstorming ',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jr93eley4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'judmi9z12',
        name: 'SWOT Analysis for Situation Analysis ',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/judmi9z12',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: 'jOmBzeIUF7T',
        name: 'Reflection Workshop Example',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jOmBzeIUF7T',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'e5AxlE4G4PX',
        name: 'Retrospective Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/e5AxlE4G4PX',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'emwThGfjVI9',
        name: 'Team Meeting Notes Example',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/emwThGfjVI9',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'h2C0ZnmEfkG',
        name: 'Two Truths and a Lie Template',
        categories: [ 'g-meetings-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/h2C0ZnmEfkG',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jtqz58qc',
        name: 'Affinity Diagram Example',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jtqz58qc',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jr1hnhh61',
        name: 'Cards to Sort Template',
        categories: [ 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jr1hnhh61',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jr1mxf3a1',
        name: 'Empathy Map Template for User Research ',
        categories: [ 'g-software-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jr1mxf3a1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hbyn00kx1',
        name: 'Company Folder Structure Template',
        categories: [ 'g-strategy-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hbyn00kx1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '9VeFNNJUhqp',
        name: 'Graphic Organizer Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/9VeFNNJUhqp',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CLJiOSpGXff',
        name: 'AIDA Model',
        categories: [ 'g-sales-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CLJiOSpGXff',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jji18vg42',
        name: 'Customer Journey Map Template',
        categories: [ 'g-sales-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jji18vg42',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jse4la3w1',
        name: 'Customer Profile Template ',
        categories: [ 'g-sales-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jse4la3w1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jji4zkuq6',
        name: 'Market Segmentation Chart Template',
        categories: [ 'g-sales-default', 'g-research-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jji4zkuq6',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jscz92gn1',
        name: 'Marketing Mix 7 Ps ',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jscz92gn1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jji4zkuq4',
        name: 'Perceptual Map Template ',
        categories: [ 'g-sales-default', 'g-strategy-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jji4zkuq4',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jvyroouw1',
        name: 'Sales Funnel Template ',
        categories: [ 'g-sales-default', 'g-diagrams-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jvyroouw1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'AGfaTujjaiG',
        name: 'SWOT Analysis for Situation Analysis ',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AGfaTujjaiG',
        type: 'diagram/def/swot.js#Swot',
    },
    {
        id: 'jk245xpyb',
        name: 'Value Proposition Canvas Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jk245xpyb',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ukImhqiEu5g',
        name: 'Conversation Tree Example',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ukImhqiEu5g',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: '1O0ng4rHHhp',
        name: 'Lead Processing Example',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/1O0ng4rHHhp',
        type: 'diagram/def/flowchart.js#Flowchart',
    },
    {
        id: 'rMvHKHN7V6B',
        name: 'Sales Playbook Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/rMvHKHN7V6B',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ws1dhoABnkO',
        name: '3 Decisions in SNAP Selling Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ws1dhoABnkO',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hGIPfgn5LSm',
        name: '4 SNAP Selling Rules Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hGIPfgn5LSm',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'EhXVRRMlv5L',
        name: '4 Steps to SPIN Selling Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/EhXVRRMlv5L',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ciXOZ5Gw46n',
        name: '7 Step Sales Process Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ciXOZ5Gw46n',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'MFN30XeR3gn',
        name: '7 Step Sales Process Mind Map Example',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/MFN30XeR3gn',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: 'pHVXRkoLQfA',
        name: 'Account Plan Template',
        categories: [ 'g-sales-default', 'g-strategy-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/pHVXRkoLQfA',
        type: 'diagram/def/block.js#Block',
        isPremium: true,
    },
    {
        id: 'kcTcV0Uqwbl',
        name: 'Five Ws Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/kcTcV0Uqwbl',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'ju2j6v2u1',
        name: 'Clustering Chart ',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ju2j6v2u1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jverg1fa1',
        name: 'Genogram Template ',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jverg1fa1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'hovHPtfI7hA',
        name: 'Graphic Organizer Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/hovHPtfI7hA',
        type: 'diagram/def/education.js#Education',
    },
    {
        id: 'jpmf9vd01',
        name: 'Blank Lesson Plan Template ',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jpmf9vd01',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'vmdLaLOhtFD',
        name: 'Education Links Mind Map',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vmdLaLOhtFD',
        type: 'diagram/def/mindmap.js#MindMap',
    },
    {
        id: '7ROcnRrjrg0',
        name: 'Persuasion Map Example',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/7ROcnRrjrg0',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'fuqwo5qk1',
        name: 'Spider Diagram Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/fuqwo5qk1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'yhegxwnSBfO',
        name: 'Star Diagram Example',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/yhegxwnSBfO',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'jracpzaq',
        name: 'Storyboard Template ',
        categories: [ 'g-edu-default', 'g-diagrams-default', 'g-research-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/jracpzaq',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'CkAu62f9WIe',
        name: 'Timeline Diagram Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/CkAu62f9WIe',
        type: 'diagram/def/timeline.js#Timeline',
    },
    {
        id: 'AEhw9hK5Hw1',
        name: 'Cluster-Word Web',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AEhw9hK5Hw1',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: '1FGZTbApmbS',
        name: 'Fact & Opinion Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/1FGZTbApmbS',
        type: 'diagram/def/block.js#Block',
    },
    {
        id: 'AECPd6PNubN',
        name: 'Observation Chart Template',
        categories: [ 'g-edu-default', 'g-diagrams-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/AECPd6PNubN',
        type: 'diagram/def/block.js#Block',
    },

    // Project templates
    // TODO: Following are sample data which need to be replaced by actual project templates.
    {
        id: '7mbToIYIKRM',
        name: 'Test dup Folder',
        categories: [ 'g-project-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/g4nxEBiLhaI',
        type: 'diagram/def/flowchart.js#Flowchart',
        isProject: true,
    },
    {
        id: 'ddPVp5iSnAW',
        name: 'Project 2',
        categories: [ 'g-project-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/ddPVp5iSnAW',
        type: 'diagram/def/mindmap.js#MindMap',
        isProject: true,
    },
    {
        id: 'bWR4c0jH0fI',
        name: 'Org Chart',
        categories: [ 'g-project-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/bWR4c0jH0fI',
        type: 'diagram/def/org-chart.js#OrgChart',
        isProject: true,
    },
    {
        id: 'vAnBkdf0iQR',
        name: 'Concept Map',
        categories: [ 'g-project-default' ],
        thumbUrl: 'https://neutrino-diagram-svg.s3.amazonaws.com/vAnBkdf0iQR',
        type: 'diagram/def/concept-map.js#ConceptMap',
        isProject: true,
    },
    // tslint:disable-next-line:max-file-line-count
];
