import { AbstractModelChangeCommand } from 'flux-store';
import { ISavedModelChange } from 'flux-core';
import { ICommandEventData } from 'flux-core';
import { Injectable } from '@angular/core';
import {
    Command,
    Random,
    CommandInterfaces,
    IUnsavedModelChange,
    CommandCancelError,
} from 'flux-core';
import { isEmpty } from 'lodash';
import { concat, defer, Observable } from 'rxjs';
import { DataSync } from 'flux-store';
import { ProjectModel } from '../model/project.mdl';
import { ProjectLocator } from '../locator/project-locator';
import { map, switchMap, take } from 'rxjs/operators';
import { invert } from '@creately/mungo';

/**
 * This ProjectChange command is to update the project by a given change modifier
 */
@Injectable()
@Command()
export class ProjectChange extends AbstractModelChangeCommand {
    /**
     * This command should be sent to the server
     */
    public static get implements(): CommandInterfaces[] {
        return [ 'IMessageCommand', 'IProjectCommand' ];
    }

    /**
     * Inject services and stuff!
     */
    constructor(
        private dataSync: DataSync,
        protected projectLocator: ProjectLocator,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute(): any {
        this.resourceId = this.data && this.data.projectId ? this.data.projectId : this.resourceId;
        return this.storeUnsavedModelChanges();
    }

    public executeResult(): any {
        return this.storeSavedModelChanges();
    }

    /**
     * stores unsaved model changes
     */
    protected storeUnsavedModelChanges(): Observable<unknown> {
        return this.getUnsavedModelChange().pipe(
            switchMap( modelChange => {
                const modifier = modelChange.modifier;
                if ( !modifier || ( isEmpty( modifier.$set ) && isEmpty( modifier.$unset ))) {
                    throw new CommandCancelError( 'the modifier is invalid, ignoring it' );
                }
                this.updateCommandData( modelChange );
                return this.dataSync.storeChanges( ProjectModel, [ modelChange ]);
            }),
        );
    }

    /**
     * createModelChange creates a model change using data available in the command.
     */
    protected getUnsavedModelChange(): Observable<IUnsavedModelChange> {
        return this.projectLocator.getProject( this.resourceId ).pipe(
            take( 1 ),
            map( project => ({
                id: Random.changeId(),
                event: this.getChangeEventData(),
                userId: '', // TODO implement!
                modelId: this.resourceId,
                command: this.name,
                clientTime: Date.now(),
                modifier: this.data.modifier,
                reverter: invert( project, this.data.modifier ),
                status: 'unsaved',
            })),
        );
    }

    protected getChangeEventData(): ICommandEventData {
        const cmdEvt = this.eventData;
        const changeEvt: ICommandEventData = {
            eventId: cmdEvt.eventId,
            eventName: cmdEvt.eventName,
        };
        if ( cmdEvt.originalEventId ) {
            changeEvt.originalEventId = cmdEvt.originalEventId;
        }
        if ( cmdEvt.scenario ) {
            changeEvt.scenario = cmdEvt.scenario;
        }
        return changeEvt;
    }

    /**
     * Updates the data field of the command instance.
     */
    protected updateCommandData( modelChange: IUnsavedModelChange ) {
        this.data = {
            change: {
                id: modelChange.id,
                event: modelChange.event,
                clientTime: modelChange.clientTime,
                modifier: modelChange.modifier,
                reverter: modelChange.reverter,
            },
        };
    }

    /**
     * stores saved model changes
     */
    protected storeSavedModelChanges(): Observable<any> {
        const modelChange = this.getSavedModelChange();
        const modifier = modelChange.modifier;
        if ( !modifier || ( isEmpty( modifier.$set ) && isEmpty( modifier.$unset ))) {
            throw new CommandCancelError( 'the modifier is invalid, ignoring it' );
        }
        return concat(
            this.dataSync.storeChanges( ProjectModel, [ modelChange ]),
            defer(() => this.dataSync.applyDirectChange( ProjectModel, modelChange )),
        );
    }

    /**
     * extractModelChange extracts the saved model change from command result data.
     */
    protected getSavedModelChange(): ISavedModelChange {
        const savedChange: ISavedModelChange = this.resultData.change;
        savedChange.modelId = this.resourceId;
        savedChange.command = this.name;
        savedChange.status = 'saved';
        return savedChange;
    }

}

Object.defineProperty( ProjectChange, 'name', {
    value: 'ProjectChange',
});
