import { AbstractCommand, CommandInterfaces, StateService, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { map } from 'rxjs/operators';

/**
 * This command checks provides shape ids are locked or unlocked and returns unlocked or locked shape ids.
 * The checking unlock or locked decide by isLocked data. This command can be use for filter locked or unlocked
 * shape ids list in command event.
 * data: {
 *     shapeIds - ids of the shape that needs to be check
 *     isLocked - To indicate whether to check locked shapes or unlocked shapes
 * }
 *
 * @author  Sajeeva
 * @since   2020-12-07
 */
@Injectable()
@Command()
export class FilterLockedOrUnlockedShapes extends AbstractCommand {

    /**
     * This command is used to retrieve data. Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        isLocked: boolean,
        copyEdata?: any,
    };

    constructor(
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any> ) {
        super();
    }

    public get locator() {
        return this.ll.forCurrent( false );
    }

    public execute() {
        const shapeIds = this.data.shapeIds === undefined ? this.state.get( 'Selected' ) : this.data.shapeIds;
        const isLocked = this.data.isLocked !== undefined ? this.data.isLocked : false;
        return this.locator.getDiagramOnce().pipe(
            map( diagram => {
                this.resultData = {
                    shapeIds: diagram.filterShapeIdsByLockedState( shapeIds , isLocked ),
                    copyEdata: this.data.copyEdata,
                };
                return true;
            }),
        );
    }

}

Object.defineProperty( FilterLockedOrUnlockedShapes, 'name', {
    value: 'FilterLockedOrUnlockedShapes',
});
