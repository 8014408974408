import { ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig, ModalController, PopupWindow } from 'flux-core';
import { Subscription } from 'rxjs';

/**
 * lite plan window component. This will handle the
 * lite plan window popup and incoming post from the window
 *
 * @author  Sajeeva
 * @since   2021-09-04
 */
@Component({
    templateUrl: 'lite-plan-window.cmp.html',
    selector: 'lite-plan-window',
    styleUrls: [ './lite-plan-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LitePlanWindow extends PopupWindow implements OnDestroy, OnInit {

    /**
     * Team user lite plan url
     */
    protected LITE_PLAN_URL = 'lite-plans';

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The team user confiramtion window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected modalController: ModalController,
    ) {
        super();
        this.subs = [];
    }

    /**
     * Return the lite plan window URL with the current page to open.
     */
    public get litePlanWindowUrl(): string {
        return `${AppConfig.get( 'SITE_URL' )}${this.LITE_PLAN_URL}/`;
    }

    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
        );
    }

    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
}
