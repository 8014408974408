import { StateService } from 'flux-core';
import { map, take } from 'rxjs/operators';
import { ProjectLocator } from '../locator/project-locator';

import { Injectable } from '@angular/core';
import { Modifier } from '@creately/rxdata';
import { Proxied, Sakota } from '@creately/sakota';
import { Command } from 'flux-core';
import { Observable } from 'rxjs';
import { ProjectModel } from '../model/project.mdl';
import { AbstractProjectChangeCommand } from './asbtract-project-change-command.cmd';

/**
 * Updates the description of the project model.
 *
 */
@Injectable()
@Command()
export class UpdateProjectDesc extends AbstractProjectChangeCommand {

    public static get dataDefinition(): any  {
        return {
            desc: true,
            projectId: false,
        };
    }

    public get modifier(): Modifier {
        return this.data.modifier;
    }

    public get reverter(): Modifier {
        return this.data.reverter;
    }


    // tslint:disable-next-line:member-ordering
    public constructor(
        protected state: StateService<any, any>,
        protected pl: ProjectLocator ) {
        super();
    }

    public prepareProxy(): Observable<Proxied<ProjectModel>> {
        this.resourceId = this.data && this.data.projectId ? this.data.projectId : this.state.get( 'CurrentProject' );
        return this.pl.getProject( this.resourceId ).pipe(
            take( 1 ),
            map( model => {
                const proxied = Sakota.create( model );
                return proxied;
            }),
        );
    }

    public prepareData() {

        this.data.reverter = { $set: {}};

        this.changeModel.description = this.data.desc;

        this.data.modifier = this.changeModel.__sakota__.getChanges();

        this.resultData = {
            modifier : this.data.modifier,
            reverter : this.data.reverter,
            projectId: this.resourceId,
        };

    }
}

Object.defineProperty( UpdateProjectDesc, 'name', {
    value: 'UpdateProjectDesc',
});
