import { modify } from '@creately/mungo';
import { Proxied } from '@creately/sakota';
import { Deserializer, IModifier, Logger, MapOf } from 'flux-core';
import { DataType, IDataItem, ShapeType } from 'flux-definition';
import {
    DiagramModelFactory,
    IDiagramData,
    IDiagramFactory,
    IShapeData,
    ShapeModelFactory,
    correctShapeModifier,
} from 'flux-diagram-composer';
import { ConnectorModel } from '../shape/model/connector.mdl';
import { ShapeModel } from '../shape/model/shape.mdl';
import { DiagramModel } from './model/diagram.mdl';

type DataItems = MapOf<IDataItem<DataType>>;

/**
 * DiagramFactory
 * Contains factory functions which can be used to create new diagram or shape instances.
 * Using this we can perform shape updating logic outside the diagram locator class.
 */
export class DiagramFactory implements IDiagramFactory<DiagramModel, ShapeModel | ConnectorModel> {

    /**
     * Build data items from the dataDef
     * "dataDef" is defined in the shape's def json file and it contains
     * common data required for data items which are not necessary to be stored in the db.
     * e.g.
     * dataDef: {
     *    isAbstract: {
     *      prop1: ..
     *      prop2: ..
     *    }
     * }
     *
     * data: {
     *    isAbstract: {}
     * }
     *
     * isAbstract props defined in the dataDef will be merged to isAbstract data item
     *
     *
     * "def" is defined to specify the key to lookup in the dataDef
     * e.g.
     * dataDef: {
     *    isAbstract: {
     *      prop1: ..
     *      prop2: ..
     *    }
     * }
     *
     * data: {
     *    whatEver: {
     *       def: "isAbstract"
     *    }
     * }
     *
     */
    public static createData( dataDef, data: DataItems ): DataItems {
        if ( dataDef && data ) {
            const resultingData = {};
            return new Proxy( data, {
                get: ( target: DataItems, key: string ) => {
                    if ( !target.hasOwnProperty( key )) {
                        return Reflect.get( target, key );
                    }
                    if ( !resultingData[ key ]) {
                        if ( key === 'description' && dataDef.description ) {
                            resultingData[key] = { ...dataDef.description, value: data[key].value };
                            return resultingData[ key ];
                        }
                        if ( dataDef[ key ]) {
                            resultingData[key] = Object.assign({}, dataDef[ key ], data[ key ]);
                        } else if ( dataDef[ data[ key ].def ]) {
                            resultingData[key] = Object.assign({}, dataDef[ data[ key ].def ], data[ key ]);
                        }
                        if (( resultingData[ key ] || data[key]).isNested && data[ key ].value ) {
                            resultingData[key] = resultingData[key] || Object.assign({}, data[ key ]);
                            resultingData[key].value = this.createData( dataDef, data[ key ].value );
                        }
                        if ( !resultingData[key]) {
                            resultingData[key] = data[ key ];
                        }
                    }
                    return resultingData[ key ];
                },
            });
        }
        return data;
    }

    protected static replaceData( dataDef, data ) {
        if ( dataDef ) {
            if ( data ) {
                for ( const key in data ) {
                    if ( dataDef[ key ]) {
                        const obj = Object.assign({}, dataDef[ key ], data[ key ]);
                        Object.assign( data[ key ], obj );
                    } else if ( dataDef[ data[ key ].def ]) {
                        const obj = Object.assign({}, dataDef[ data[ key ].def ], data[ key ]);
                        Object.assign( data[ key ], obj );
                    }
                    if ( data[ key ].isNested && data[ key ].value ) {
                        this.replaceData( dataDef, data[ key ].value );
                    }
                }
            }
        }
    }

    /**
     * Creates a diagram model with the correct type. This should only create the structure.
     */
    public createDiagram( data: IDiagramData ): Promise<DiagramModel> {
        const observable = DiagramModelFactory.instance.createByData( data, DiagramModel );
        return observable.toPromise() as Promise<DiagramModel>;
    }

    /**
     * Updates a diagram model in-place. Applies the given modifier to the given diagram model.
     */
    public updateDiagram( model: Proxied<DiagramModel>, modifier: IModifier ): Promise<unknown> {
        model.__sakota__.mergeChanges( modifier );
        return Promise.resolve( model );
    }

    /**
     * Creates a shape model with the correct type. This should only create the structure.
     */
    public createShape( data: IShapeData ): Promise<ShapeModel | ConnectorModel> {
        const type = data.type === ShapeType.Connector ? ConnectorModel : ShapeModel;
        const observable = ShapeModelFactory.instance.createByData( data, type );
        return new Promise(( resolve, reject ) => {
            observable.subscribe(
                val => {
                    resolve( val as any );
                    resolve = () => {};
                },
                err => {
                    reject( err );
                },
                () => resolve( null ),
            );
        });
    }

    /**
     * Removes a shape model from the diagram model.
     */
    public removeShape( model: DiagramModel, shapeId: string ): Promise<unknown> {
        delete model.shapes[ shapeId ];
        return Promise.resolve( null );
    }

    /**
     * Updates a shape model in-place. Applies the given modifier to the given shape model.
     * A modifier with all changes should also be passed in which will be used if needed.
     */
    public updateShape(
        model: Proxied<ShapeModel | ConnectorModel>,
        modifier: IModifier,
        root?: DiagramModel,
        fullModifier?: IModifier,
    ): Promise<unknown> {
        if ( !model ) {
            Logger.debug( 'Shape Model undefined', modifier );
            return Promise.resolve();
        }
        const defId = `${model.defId}_${model.version}`;
        // setting the dataDef to root and removing it from the model so that models are not bloated with dataDef
        if ( model instanceof ShapeModel && model.dataDef ) {
            if ( !root.defaultDataDefs[defId]) {
                root.defaultDataDefs[defId] = model.dataDef;
            }
            delete model.dataDef; // removing the dataDef since it's no longer required by the model.
            if ( fullModifier && fullModifier.$set && root.defaultDataDefs[defId]) {
                DiagramFactory.replaceData( root.defaultDataDefs[defId], fullModifier.$set.data );
            }
        }
        model.__sakota__.reset();
        const cModifier = correctShapeModifier( fullModifier );
        modify( model, cModifier );
        // if ( fullModifier && fullModifier.$set && root.defaultDataDefs[defId]) {
        //     model.data = DiagramFactory.createData( root.defaultDataDefs[defId], model.data );
        // }
        const type = model.type === ShapeType.Connector ? ConnectorModel : ShapeModel;
        return Deserializer.build( type, model, model ).toPromise();
    }

}

/**
 * PreviewDiagramFactory is a lightweight version of the DiagramFactory which does only
 * the bare essential tasks required for preview.
 */
export class PreviewDiagramFactory extends DiagramFactory {
    /**
     * @override
     * Updates a shape model in-place. Applies the given modifier to the given shape model.
     * A modifier with all changes should also be passed in which will be used if needed.
     */
    public updateShape(
        model: Proxied<ShapeModel | ConnectorModel>,
        modifier: IModifier,
    ): Promise<unknown> {
        if ( !model ) {
            Logger.debug( 'Shape Model undefined', modifier );
            return Promise.resolve();
        }
        model.__sakota__.mergeChanges( modifier );
        return Promise.resolve( null );
    }
}
