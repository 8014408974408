import { CollaboratorType } from 'flux-diagram/models';

export class GroupShareModel {

    /**
     * Defines the privacy role
     */
    public role: CollaboratorType;

    /**
     * Defines the id of the group
     */
    public id: string;

    public constructor( groupId: string, role: CollaboratorType ) {
        this.id = groupId;
        this.role = role;
    }
}
