import { Observable } from 'rxjs';

/**
 * This Class  handles data of image
 * @author mehdhi
 * @since 07-12-2019
 */
export class ImageDataURL {


    constructor ( protected imageDataURL: string ) {}

    /**
     * This function calculates the image width and height
     */
    public getBounds() {
        const imageElement = this.createImageElement();
        const observable: Observable<{ width: number, height: number }> = new Observable( subscriber => {
            imageElement.onload = () => {
                const bound = {
                    height: imageElement.height,
                    width: imageElement.width,
                };
                subscriber.next( bound );
                subscriber.complete();
            };
            imageElement.onerror = errorEvt => {
                subscriber.error( errorEvt );
            };
            imageElement.src = this.imageDataURL;
        });
        return observable;
    }

    /**
     * Creates new image element
     */
    protected createImageElement(): HTMLImageElement {
        return new Image();
    }

}
