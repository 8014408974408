import { IGraphics } from 'flux-definition';
export class GraphicsDrawUtils {

    /**
     * This draws the shape using the canvas instructions stored on the model
     *
     * Canvas Instructions from SvgToCanvas eg:
     *      “setStrokeStyle(2,‘round’,undefined,undefined,false)“,
     *      ‘setStrokeDash([0,0],0)’,
     *      “beginStroke(styleDefinitions[‘ac270570e175084da0a96423b3e28bf4’].style.lineColor)“,
     *      ‘moveTo(sw * -25 + xo,sh * -94 + yo)’,
     *      ‘lineTo(sw * 50 + xo,sh * -243 + yo)’,
     *      ‘endStroke()’
     *  where sw, sx are relative bound ratios
     */
     public static drawFromInstructions(
        graphics: IGraphics,
        instructions: string[],
        scaleX: number,
        scaleY: number,
        lineThickness: number,
        defaultWidth: number,
        defaultHeight: number,
        xo: number = 0,
        yo: number = 0,
     ) {
        const sw = scaleX;
        const sh = scaleY;

        // Straight line bounds look like connectors; don't need to account for line thickness.
        const isStraightLine = defaultWidth === 0 || defaultHeight === 0;
        if ( !isStraightLine ) {
            // NOTE: Commented out to resolve freehand shape drawing smoothness issue.
            // sw -= ( lineThickness / defaultWidth );
            // sh -= ( lineThickness / defaultHeight );
            xo += lineThickness / 2;
            yo += lineThickness / 2;
        }

        instructions.forEach(( instruction: string ) => {
            const functionName = instruction.split( '(' )[0];
            const args: any =  instruction.slice( functionName.length + 1, instruction.length - 1 );
            if ( functionName.includes( 'Stroke' )) {
                // Style is done from model styles in beginDraw.
                return;
            }
            // Other arguments are strings to be evaluated ( mathematical, bool, etc )
            let arg2 = args.split( ',' );
            arg2 = arg2.map(( arg: string ) => {
                arg = arg.replace( 'sw', sw.toString());
                arg = arg.replace( 'sh', sh.toString());
                arg = arg.replace( 'xo', xo.toString());
                arg = arg.replace( 'yo', yo.toString());
                // tslint:disable-next-line: no-eval
                return eval( arg );
            });
            graphics[functionName]( ...arg2 );
        });
    }

}
