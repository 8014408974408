<div class="modal-window-container fx-cover fx-center-all onboarding-window-container" #container>
    <div [ngClass]= "{
        'modal-window-inner' : true,
        'modal-window-inner-compact' : isCompact
    }" #window>
        <iframe *ngIf="!(displayPurchaseStarted | async)" #iframe [src]="iFrameUrl | safe" class="onboarding-window-iframe"></iframe>

        <div *ngIf="(displayPurchaseStarted | async)" class="fx-span-height fx-center-all">
            <div class="fx-center">
                <h3 class="nu2-subheading-3 fx-margin-bottom-10">{{ translate.get('PLAN_WINDOW.TITLE') | async }}</h3>
                <p class="body fx-margin-bottom-30">{{ translate.get('PLAN_WINDOW.DESCRIPTION_2') | async }}</p>
                <a class="btn-small btn-secondary"
                    (click)="reloadAfterPurchase()">
                    {{ translate.get('PLAN_WINDOW.RELOAD_CREATELY') | async }}
                </a>
            </div>
        </div>
    </div>
</div>
