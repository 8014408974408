import { ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopupWindow, AppConfig, CommandService, Tracker } from 'flux-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserCommandEvent } from '../../user/command/user-command-event';

/**
 * whats new dialog window. This will handle the popup,
 * And show the whats new window.
 *
 * @author  Sajeeva
 * @since   2023-03-17
 */

@Component({
    templateUrl: 'whats-new-window.cmp.html',
    selector: 'whats-new-window',
    styleUrls: [ './whats-new-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewWindow extends PopupWindow implements OnDestroy, OnInit {

    /**
     * show hide the do not show button
     * by default this set as false, this will enable if need later
     */
    public showDoNotShowButton: boolean = false;

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The file exported window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected commandService: CommandService,
        protected modalController: ModalController,
        public translate: TranslateService,
    ) {
        super();
        this.subs = [];
    }

    /**
     * This returns the IFrame url according to type
     */
    public get iFrameUrl(): string {
        return AppConfig.get( 'SITE_URL' ) + 'whats-new/?inapp=1';
    }

    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
        );
        Tracker.track( 'dialog.whatsnew.load' );
    }

    /**
     * close the IFrame
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Unsubscribe the subscription when component destroy
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Handle the close button click event
     * call the user preference update command and update
     * the whats_new_last_version value with current config value
     */
    public closeClick() {
        Tracker.track( 'dialog.whatsnew.close' );
        this.dispatchUpdateUserPreferenceCommand( 'whats_new_last_version', AppConfig.get( 'APP_VERSION' ));
        this.closeWindow();
    }

    /**
     * Handle the do not show again click event
     * call the user preference update command and set
     * show_in_app_whats_new value to false
     */
    public doNotShowAgainClick() {
        this.dispatchUpdateUserPreferenceCommand( 'show_in_app_whats_new', false );
    }

    /**
     * Handle the see Recent Update click event
     * open app recent update url in new tab
     */
    public seeRecentUpdatesClick() {
        window.open( AppConfig.get( 'SITE_URL' ) + 'whats-new', '_blank' );
        Tracker.track( 'dialog.whatsnew.allUpdates.click' );
    }

    /**
     * Call the updateUserPreference command event with given value
     * @param preferenceName preference property name
     * @param preferenceValue preference value
     * @returns return the commad result
     */
    protected dispatchUpdateUserPreferenceCommand( preferenceName: string , preferenceValue: any ) {
        return this.commandService.dispatch(
            UserCommandEvent.updateUserPreference,
            { preferenceName: preferenceName, value: preferenceValue },
        );
    }

}
