import { Observable } from 'rxjs';
import { Logger } from '../logger/logger.svc';
import { CommandError } from './error/command-error';
import { ICommandEventData } from '..';

/**
 * Default wait time for execute step to finish.
 */
export const DEFAULT_EXECUTE_TIMEOUT = 15000; // 15 seconds

/**
 * This is a base command interface for all commands. This defines the basic
 * command functions. Every command has to have this interface implemented.
 * {@see AbstractCommand}
 */
export interface ICommand {

    /**
     * This is the data for the specific instance of a specific command
     * the data will be set so that it can be accessed from within the command.
     *
     * When the command is created for execution, the framework will ensure this property
     * is set with the data that was passed to it.
     */
    data: any;

    /**
     * This is a method that will be executed at the very begining before a the
     * execute method is run. By the time this method is run only the data property
     * of this command will be set. The primary objective is for the command to be able
     * to prepare the data necessary for the execution of the command. The data property
     * can be manipulated during this method. Once this method completes only the data
     * will be validated against the static dataDefinition defined in the command.
     */
    prepareData();

    /**
     * This is the primary operation of the command. Instructions
     * that will execute the command must be put into this function by overiding it.
     *
     * Overriding this method in all implemented commands is a must.
     *
     * @return  boolean This method returns a boolean to indicate if the command executed
     *          successfully of not. <code>true</code> must be returned if all is well.
     */
    execute(): boolean | Observable<any>;

    /**
     * This is the result of executing the command. If it is an IMessageCommand
     * the result of the payload/data of the message that was received from the
     * Neutrino server as a result of the message that was sent as part of this
     * command. This is only set if the  message received a successful
     * response from the server OR if the execute method itself sets a value to this.
     * This will contain everything that was sent by the server in the second case.
     * The execute method can and will manipulate this property. This is what will be
     * passed to the Observable as the result.
     * By default this will be empty.
     */
    resultData(): any;

    /**
     * Fires when the command fails. This can fire during any part of the execution.
     * In case of a IMessageCommand, when the server message returns with an error
     * this will be triggered. When this occurs, the resultData can be null
     * This same error will be thrown in the Observable that was recieved by
     * the CommandService.dispatch method.
     *
     * @param   error The error that occured during the command. Note that this will be
     *          a {@link CommandError} in case of a standard execution error and will be a
     *          {@link CommandResultError} in case of a server error.
     */
    onError( error: CommandError );

}

/**
 * This decorator should be added on all commands. It will make sure the command
 * type metadata is not removed during AOT compilation.
 */
// tslint:disable-next-line:only-arrow-functions
export function Command() {
    // NOTE: this will make sure the command metadata is kept during AOT
    //       can do some other here as well if needed.
    // tslint:disable-next-line:only-arrow-functions
    return function( target: any ) {
        // ...
    };
}

/**
 * This is the most abstract form of a command which implements
 * {@link ICommand}. This must be extended by every command implementation.
 *
 * Related methods, getter/setters must be overridden and implemented as per
 * the specification laid out below. Failing to do will render the command
 * unusable. Please follow instructions to implement a concreate command
 * that is usable.
 *
 * See the interface {@link ICommand} for more details.
 * @author  Gobiga
 * @since   2016-04-05
 */
@Command()
export class AbstractCommand implements ICommand {

    public static get aliases(): string[] {
        return [];
    }

    /**
     * This is the data for the specific instance of a specific command
     * the data will be set so that it can be accessed from within the command.
     *
     * When the command is created for execution, the framework will ensure this property
     * is set with the data that was passed to it.
     *
     * This property should contain {diagramId} if command implements {@link IDiagramCommand}
     * or it should contain {projectId} if command implements {@link IProjectCommand}.
     */
    public data: any;

    /**
     * This property holds the data, which are expected to be updated in the command success,
     * in order to use in revert function in any case of command failure.
     */
    public previousData: any;

    /**
     * Has additional data about the command which is not essential to the primary function.
     * Example: execution time, container information, etc.
     */
    public contextData?: object;

    /**
     * A logger instance to create a logs.
     */
    protected log: Logger;

    /**
     * This holds information about the {@link CommandEvent}
     * that triggered this commands execution.
     */
    private _eventData: ICommandEventData;

    /**
     * This is id of the resource used by the command. It should be available on
     * commands which implement {@link IDiagramCommand} or {@link IProjectCommand}.
     */
    private _resourceId: string;

    /**
     * This is the result of executing the command. If it is an IMessageCommand
     * the result of the payload/data of the message that was received from the
     * Neutrino server as a result of the message that was sent as part of this
     * command. This is only set if the  message received a successful
     * response from the server OR if the execute method itself sets a value to this.
     * This will contain everything that was sent by the server in the second case.
     * The execute method can and will manipulate this property. This is what will be
     * passed to the Observable as the result.
     * By default this will be empty.
     */
    private _resultData: any = undefined;

    /**
     * Property and methods to track the changes of resultData
     * Can be reset by calling resetResultDataChangedStatus
     */
    private _resultDataChanged = false;

    /**
     * This is a static getter expected to be implemented in each command that
     * is created for this command framework.
     *
     * This defines the data properties that are expected by the specific
     * command for it to successfully execute. This should be return a json object.
     * The object defines the property name as the key and the a boolean as a value
     * indicating if the property is mandatory or optional. An example of a definiton
     * is as follows.
     *      {
     *          projectId: true,
     *          projectName: true,
     *          hasOptions: false
     *      }
     * In the above example, the projectId and the projectName data are mandatory for
     * the command to execute. The hasOptions data is optional.
     *
     * The command framework ensures that the data passed to the command validates
     * to this definition.
     */
    // Override: public static get dataDefinition():{} {}

    /**
     * This is a static getter expected to be implemented in each command that
     * is created for this command framework.
     *
     * This defines the list of interfaces that are implemented by the particular
     * command. The command framework uses the interface names to identify what
     * capabilities they have. This getter is expected to return an array of
     * CommandInterfaces string litrals which are representations of actual interfaces
     * in the command pattern.
     *
     * @return  an array of CommandInterfaces strings.
     */
    // Override: public static get implements(): Array<CommandInterfaces> {}
    /**
     * This is a static getter expected to be implemented in each command that
     * is an IMessageCommand, IHttpCommand, IModelChangeCommand or a IModelStoreCommand.
     *
     * This property should provide an idea of the result data expected in this command.
     * This is only necessary for the parts of data that are complex types. You dont have
     * to define, the simple types such as string, number, date, arrays.
     * There are two ways to do this.
     *
     * First Method
     * If the result is expected to be a specific class or an array of a specific class
     * you can simply return the class/type. For example if the command is supposed to
     * return a ProjectModel instance or an array of ProjectModel instances the property
     * would simply return the class.
     *
     * Note:
     * IModelChangeCommand and IModelStoreCommand type commands only support this method.
     *
     * Second Method
     * If the result is an unstructured object and has complex types inside of it then
     * you should only define the complex typees in a dictionary and send. Examine
     * the following example.
     * Expected data -
     *  {
     *      user: UserInfoModel
     *      userId: string
     *      lastLogin: number/timestamp
     *      preferences: UserPreferenceModel
     *  }
     * This property should return -
     *  {
     *      'user': UserInfoModel,
     *      'preferences': UserPreferenceModel
     *  }
     *
     * All classes/types returned by this property must have all its complex type properties
     * marked with @ComplexType annotation. See more in {@link Deserializer}
     *
     * If there are no complex types expected in the result then this property should not be empty
     * or not defined.
     *
     * @return  A class or a dictionary with classes.
     */
    // Override: public static get resultType(): any {}

    /**
     * This is to get the name of the class represented by this
     * command.
     *
     * @return the name of the class
     */
    public get name(): string {
        const name: string = this.constructor.name;
        if ( !name ) {
            throw Error( 'The name property of the command is undefined' );
        }
        return name;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return false;
    }

    /**
     * Retuns the {@link CommandEvent} data under which
     * this command was executed.
     */
    public get eventData(): ICommandEventData {
        return this._eventData;
    }

    /**
     * Number of milliseconds to wait before execute step finishes.
     * Zero or negative means infinite wait.
     */
    public get executeTimeout(): number {
        return DEFAULT_EXECUTE_TIMEOUT;
    }

    /**
     * Sets the {@link CommandEvent} data under which
     * this command is executed.
     */
    public set eventData( data: ICommandEventData ) {
        this._eventData = data;
    }
    public get resourceId(): string {
        return this._resourceId;
    }
    public set resourceId( resourceId: string ) {
        this._resourceId = resourceId;
    }

    public set resultData( result: any ) {
        this._resultDataChanged = true;
        this._resultData = result;
    }

    public get resultData(): any {
        return this._resultData;
    }
    public get resultDataChanged(): boolean {
        return this._resultDataChanged;
    }

    public set logger( log: Logger ) {
        this.log = log;
    }

    /**
     * This is a getter that must be implemented by a {@link IStateChangeCommand} to specify
     * what states are affected by the command. The getter must return a dictionary with key as
     * the state identifier and value is the state.
     *
     *      get states() {
     *          return {
     *              SelectionState: this.data.shapeId,
     *              ZoomLevel: 2
     *          };
     *      }
     *
     * The getter will be accessed after prepareData hook complete the data validation completes.
     * If the getter is missing or returns empty the controller will error. If no states are specified
     * the controller will error.
     *
     * As a value a observable can be set. In such case the values emitted by the observable will be used
     * as the state. The observable must emit values valid for the state.
     *
     *      get states() {
     *          return {
     *              SelectionState: this.data.selectionStateChangeObservable,
     *          };
     *      }
     *
     */
    public get states(): { [ stateId: string ]: any } {
        return undefined;
    }

    /**
     * This will hold the version of the command. When there are multiple version of the same
     * command is used this needs to be overridden in the command by specifying a version.
     * By default all the command versions are set to 1.
     */
    public get version(): number {
        return 1;
    }

    /**
     * revert hook will run when the command fails and it will reset any changes done by the command.
     *
     * FIXME - Should only run if an error occurs in execute(), currently it is running
     * for errors on executeResult() as well.
     */
    public revert(): boolean | Observable<any> {
        return true;
    }

    public resetResultDataChangedStatus() {
        this._resultDataChanged = false;
    }

    /**
     * This is the primary operation of the command. Instructions
     * that will execute the command must be put into this function by overiding it.
     *
     * Overriding this method in all implemented commands is a must.
     *
     * @return  boolean This method returns a boolean to indicate if the command executed
     *          successfully of not. <code>true</code> must be returned if all is well.
     */
    public execute(): boolean | Observable<any> {
        return false;
    }

    /**
     * This operation will run after receiving data from the server. This hook
     * can be implemented in command classes implementing {@link IMessageCommand} and {@link IHttpCommand}.
     *
     * @return boolean Indicates whether the execute ran successfully or not
     */
    public executeResult( response: any ): boolean | Observable<any> {
        return true;
    }

    /**
     * This operation will run if the app is disconnected from internet / offline.
     * This is a replacement method of HttpExecutionStep or WsExecutionStep or any network
     * request made from the app.
     *
     * This hook can be implemented in command classes
     * implementing {@link IMessageCommand} and {@link IHttpCommand}.
     *
     * @return boolean Indicates whether the execute ran successfully or not
     */
    public executeNetworkOffline(): boolean | Observable<any> {
        return true;
    }

    /**
     * This is a method that will be executed at the very begining before a the
     * execute method is run. By the time this method is run only the data property
     * of this command will be set. The primary objective is for the command to be able
     * to prepare the data necessary for the execution of the command. The data property
     * can be manipulated during this method. Once this method completes only the data
     * will be validated against the static dataDefinition defined in the command.
     */
    public prepareData(): void | Observable<any> {
        // ...
    }

    /**
     * Indicates if the given type of interface is implemented by
     * this command.
     * This would return false if the <code>static implements</code> property
     * is not implemented in the command.
     *
     * @return  boolean If the command implements the interface or not.
     */
    public hasInterface( type: CommandInterfaces ): boolean {
        const cmdImplements: Array<CommandInterfaces> = this.getInterfaces();
        return ( cmdImplements && cmdImplements.indexOf( type ) >= 0 );
    }

    /**
     * Indicates if at least one of the given list of interfaces are implemented
     * by this command.
     * This would return false if the <code>static implements</code> property
     * is not implemented in the command.
     *
     * @return  boolean If the command implements at least one of the interface or not.
     */
    public hasAnInterface( types: Array<CommandInterfaces> ): boolean {
        const cmdImplements: Array<CommandInterfaces> = this.getInterfaces();
        const result = types.filter(( type: CommandInterfaces ) => cmdImplements.indexOf( type ) > -1 );
        return ( result && result.length > 0 );
    }

    /**
     * Indicates if ALL of the given list of interfaces are implemented
     * by this command. Even if one of the interfaces are not implemented then will return false.
     *
     * This would return false if the <code>static implements</code> property
     * is not implemented in the command.
     *
     * @return  boolean If the command implements all of the interface or not.
     */
    public hasAllInterfaces( types: Array<CommandInterfaces> ): boolean {
        const cmdImplements: Array<CommandInterfaces> = this.getInterfaces();
        const result = types.filter( type => cmdImplements.indexOf( type ) > -1 );
        return ( result && result.length === types.length );
    }

    /**
     * Fires when the command fails. This can fire during any part of the execution.
     * When this occurs, the resultData can be null. If an error gets thrown on this hook
     * the same error will be thrown in the Observable that was recieved by the
     * CommandService method.
     *
     * @param   err The error that occured during the command. Note that this will be
     *          a {@link CommandError} in case of a standard execution error and will be a
     *          {@link CommandResultError} in case of a server error.
     */
    public onError( err: CommandError ): any {
        throw err;
    }

    /**
     * Returns the interfaces defined by this command type. These are the CommandInterfaces
     * defined by the `implements` static getter of a command. For more details see the
     * `implements` getter defined here.
     */
    private getInterfaces(): Array<CommandInterfaces> {
        const cmdConstructor = Object.getPrototypeOf( this ).constructor;
        return cmdConstructor.implements || [];
    }
}

Object.defineProperty( AbstractCommand, 'name', {
  writable: true,
  value: 'AbstractCommand',
});

/**
 * These are string representations of all the available
 * command interfaces. These strings wll be used in a command's
 * <code>implements</code> static property for each concreate command
 * that is created. These are used to identify the interfaces implemented
 * during runtime (an inability with Typescirpt)
 */
export type CommandInterfaces =
    /**
     * Every command.
     */
    'ICommand' |
    /**
     * Command that will send the data to neutrino
     * via websocket
     */
    'IMessageCommand' |
    /**
     * Command that will send the data to neutrino and wait for the response
     * via websocket
     */
    'ISyncMessageCommand' |
    /**
     * Command that will make a http request to the
     * given url
     */
    'IHttpCommand' |
    /**
     * Command that will make a http request to neutrino
     * with the given data.
     */
    'INeutrinoRestCommand' |
    /**
     * Command that will make a http request to graphql api
     * with the given data.
     */
    'IGraphqlRestCommand' |

    /**
     * Command that is associated to a diagram resource
     */
    'IDiagramCommand' |

    /**
     * Command that is associated to a edata resource
     */
    'IEDataCommand' |

    /**
     * Command that is associated to a project resource
     */
    'IProjectCommand' |
    /**
     * Command that is associated to a user resource
     */
    'IUserCommand' |


    /**
     * A command that will send to all connected clients
     * OR receive from a connected client and execute.
     */
    'ICollabCommand' |
    /**
     * NOT DEFINED YET
     */
    'IPermissableCommand' |
    /**
     * A command that will be limited based on the users role
     * to the associated resource. Applicabe for IDiagramCommand
     * and IProjectCommand. Usually is a IMessageCommand or INeutrinoRestCommand
     */
    'IRoleCommand' |
    /**
     * This Interface identifies commands which doesn't need
     * user authentication
     */
    'IAllowAnonymous'|


    'ISubscriptionCommand' |
    'IModelChangeCommand' |
    'IExecutedCommand' |

    /**
     * A command that changes local state of the application. This type
     * of command must be used for commands that change the locat state
     * of the application. State set by these commands will be managed
     * centrally through the StateService.
     */
    'IStateChangeCommand' |

    /**
     * Distribution specific commands. These commands will only work
     * when the application is in specific modes. It can also be used
     * to give additional capabilities to commands under specific modes.
     */
    'IOnlineCommand' |
    'IDesktopCommand' |
    'IPluginCommand';
