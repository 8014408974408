import { IResponsibility, AppConfig } from 'flux-core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InitializationChainStatus, IChainOutcome } from 'flux-core';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the route type for a given
 * action.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-12-10
 */
@Injectable()
export class RouteResponsibility implements IResponsibility  {

    public name: string = 'RouteResponsibility';

    /**
     * Determines the type of the route (i.e. Edit, View ).
     * @param   current status of the sequence
     * @return  Observable which emits the route type.
     */
    public checkState( status: InitializationChainStatus ): Observable<string> {
        if ( status && status.input && status.input.action ) {
            return of( status.input.action );
        } else {
            throw new Error( 'A valid input was not provided for the route responsibility' );
        }
    }

    /**
     * Sets states based on the type of route.
     * The following states are set here :
     *  authRequired - Authentication is mandatory to proceed.
     *  userRequired - User must be available to proceed.
     *  networkRequired - App must be online to proceed
     *  terminateAtAuth - Sequence should not continue beyond AuthResponsibility
     *  terminateAtUserSub - Sequence should continue beyond UserSubscriptionResponsiblity
     *  diagramRequired - Diagram is needed for the requested route
     * @param status
     */
    public stateChanges( status: InitializationChainStatus ): {[ state: string ]: any} {
        const states: any = {
            diagramRequired: true,
        };
        switch ( status.states.RouteResponsibility ) {
            case 'dashboard':
                states.authRequired = true;
                states.userRequired = true;
                states.networkRequired = true;
                states.diagramRequired = false;
                break;
            case 'new':
                states.authRequired = true;
                states.userRequired = true;
                states.networkRequired = true;
                states.diagramRequired = false;
                break;
            case 'create':
                states.authRequired = true;
                states.userRequired = true;
                states.networkRequired = true;
                states.terminateAtUserSub = true;
                break;
            case 'edit':
                states.userRequired = true;
                break;
        }
        return states;
    }


    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            return [ 'PluginUserResponsibility' ];
        } else {
            return [ 'AuthResponsibility' ];
        }
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        return undefined;
    }
}
