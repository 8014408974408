import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFeatureDataTransformer } from '../../framework/feature/feature-data-transformer.i';
import { IFeatureItem } from '../../framework/feature/feature-item.i';
import { IToolbarDropdown } from '../../framework/ui/dropdown/toolbar-dropdown/toolbar-dropdown.i';
import { uniqBy } from 'lodash';
import { FeatureList } from '../../framework/feature/feature-list.svc';
import { StateService } from 'flux-core';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { map } from 'rxjs/operators';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { ShapeModel } from '../../base/shape/model/shape.mdl';


/**
 * This transformer is to show the list of options for the shape action.
 * When a shape action is selected, update the contextual toolbar to show
 * the selected item.
 * To know more about data transformers and how they fit into
 * features and their associated UI controls, refer to {@link IFeatureDataTransformer}
 * documentation.
 */
@Injectable()
export class ShapeActionTransformer implements IFeatureDataTransformer<IFeatureItem, any, any> {

    constructor( protected featureList: FeatureList,
                 protected state: StateService<any, any>,
                 protected ll: DiagramLocatorLocator ) {
    }

    /**
     * Return the feature data to update the UI of the component.
     * @param featureId - Feature Id
     * @param data - Feature data.
     */
    public getFeatureData( featureId: string, data: any = {}): Observable<any> {
        // FIXME: This should be common for shapes. Converting items into Toolbar
        // dropdown should be done in shape logic. However this approach has been
        // taken based on the BPMN and Archimate shapes which were not having the
        // proper list of data, due to that had to query the features from the
        // feature list. Shape logic has getShapeSpecificContextualToolbarItems
        // method which needs to be refactored and alterFunction also should be
        // refactored to OPTION.CHOICE.
        const featureList = this.featureList.getFeature( featureId );
        if ( featureList && featureList.data && Array.isArray( featureList.data.value )) {
            const itemsData: IToolbarDropdown[] = featureList.data.value
                .filter( item => data.value.find( d => d.id === item.id ))
                .map( item => ({ id: item.id, label: item.label,
                    itemIcon: item.itemIcon ?
                    { type: item.itemIcon.type, value: item.itemIcon.value } as any
                    : undefined,
                    subLabel: item.subLabel,
                    buttonIcon: { type: item.type, value: item.value } as any,
                    value: data.value.find( x => x.id === item.id ).alterFunction}));
            const uniqItemsData = uniqBy( itemsData, item => item.id );
            return of({ value: uniqItemsData });
        }
        return of( data );
    }

    /**
     * Updates the feature props such as icon, label ( IFeatureItem properties )
     */
    public transformProperties( featureId: string, props: any ): Observable<any> {
        // NOTE: Update the toolbar icon or label with the user selected item only if the
        // updateToolbarIcon is true.
        if ( props.updateToolbarIcon ) {
            const feature = this.featureList.getFeature( featureId );
            const selectedIds = this.state.get( 'Selected' );
            const locator = this.ll.forCurrent( false );
            return locator.getDiagramOnce().pipe(
                map(( diagram: DiagramModel ) => {
                    if ( selectedIds.length === 1 ) {
                        // NOTE: Taking which icon type is set to the shape to show
                        // the contextual toolbar with selected icon.
                        const shape = diagram.getShape( selectedIds[0]) as ShapeModel;
                        /**
                         * Since Feature id include shapeDefId + feature id.Here we extract the feature id
                         * from the uniq feature id.
                         *
                         * Check feature-list.svc.ts for more details.
                         *
                         * fItem.id = `${def.defId}.${def.version}.${fItem.id}`;
                         * fItem.shapeDefId = `${def.defId}.${def.version}`;
                         */

                        const shapeFeatureId =  featureId.replace( feature.shapeDefId + '.', '' );
                        if ( shape && shape.data && shape.data[shapeFeatureId]) {
                            const featureDataItem = shape.data[shapeFeatureId];

                                // FIXME: To identify which item has been selected from the dropdown,
                                // shape logic has been altered to store the selected item id in it's
                                // data. We could not be able to take the selected item from the data-
                                // value because the data value and contextual toolbar value are mis-
                                // matching. That part has to be refactored in shape logic.
                            const selected = feature.data.value.
                                    find(( val: any ) =>
                                    val.value === featureDataItem.value.split( ',' )[0]);
                            if ( selected ) {
                                        props.icon = selected.itemIcon ? selected.itemIcon.value : undefined;
                                        props.label = selected.label;
                                        return props;
                                    }
                        }
                    }
                    return props;
                }),
            );
        }
        return of( props );
    }

    /**
     * Calls when the drop down item has been selected by the user. The input data
     * is corresponding to the selected dropdown item and the data is tranformed to
     * command data
     * @param data The data related to the selected dropdown item
     * @return Observable that emits transformed data once and then completes
     */
    public getApplyData( featureId: string, data: any ): Observable<any> {
        if ( data && data.value ) {
            const cmdData = { alterFunction: data.alterFunction || data.value };
            return of( cmdData );
        }
        return of( data );
    }
}
