import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

/**
 * This command is used to set template def value
 * template def contains parent template id and name
 *
 * @author  Sajeeva
 * @since   2023-08-07
 */
@Injectable()
@Command()
export class SetTemplateDef extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        templateId: string,
        templateName: string,
    };

    constructor( protected ds: DiagramChangeService,
                 protected state: StateService<any, any> ) {
        super( ds );
    }

    /**
     * Prepare command data.
     */
    public prepareData() {
        this.changeModel.templateDef = {
            id: this.data.templateId,
            name: this.data.templateName,
        };
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( SetTemplateDef, 'name', {
    value: 'SetTemplateDef',
});
