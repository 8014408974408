import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommandService, Random } from 'flux-core';
import { SystemType } from 'flux-definition';
import { cloneDeep, mapValues } from 'lodash';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { DiagramLocatorLocator } from '../diagram/locator/diagram-locator-locator';
import { roleItemsMap } from '../data-defs';

@Injectable()
export class TaskSummaryService {

    public static getDefaultTaskPayload() {
        return {
            owner: {
                value: {
                    source: {
                        id: 'collabs',
                        name: 'Role',
                    },
                    people: [],
                },
                isCore: true,
            },
            estimate: { value: '', isCore: true, roleId: 'owner' },
            dueDate: { value: '', isCore: true, roleId: 'owner' },
        };
    }

    private roleItemsMap: typeof roleItemsMap;

    private roleLabels = [ 'Owner', 'Reviewer', 'Designer', 'Developer', 'Tester' ];

    constructor(
        translate: TranslateService,
        private commandSvc: CommandService,
        private ll: DiagramLocatorLocator,
    ) {
        this.roleItemsMap = mapValues( roleItemsMap, roleField => ({
            ...roleField,
            label: translate.instant( roleField.label ),
        }));
    }

    public addRole( diagramId: string, shapeId: string ) {
        this.ll.forDiagram( diagramId, false ).getDiagramOnce().subscribe(
            d => {
                const dataItems = d.getShapeDataItems( shapeId );
                if ( !dataItems.owner ) {
                    return this.commandSvc.dispatch( DiagramCommandEvent.changeShapeDataItems, diagramId, {
                        [shapeId]: TaskSummaryService.getDefaultTaskPayload(),
                    });
                }
                const role = this.roleItemsMap.role;
                const roleId = Random.dataItemId();
                const roleFieldLabels = Object.values( dataItems ).filter( di => di.systemType === SystemType.Role )
                    .map( di => di.label );
                let i = 0;
                let label = this.roleLabels[i];
                while ( roleFieldLabels.includes( label ) && i < this.roleLabels.length - 1 ) {
                    i++;
                    label = this.roleLabels[i];
                }

                if ( i === this.roleLabels.length - 1 ) {
                    let j = 2;
                    label = `Role ${j}`;
                    while ( roleFieldLabels.includes( label )) {
                        j++;
                        label = `Role ${j}`;
                    }
                }

                const itm = cloneDeep( role );
                itm.layout = 'block';
                itm.id = roleId;
                itm.label = label;

                const changePayload = {
                    [roleId]: itm,
                };

                const roleFields = [
                    this.roleItemsMap.estimate,
                    this.roleItemsMap.dueDate,
                ];
                for ( const field of roleFields ) {
                    const itemId = Random.dataItemId();
                    const item = cloneDeep( field );
                    item.layout = 'block';
                    item.roleId = roleId;
                    item.id = itemId;
                    item.label = label;
                    changePayload[itemId] = item;
                }
                this.commandSvc.dispatch( DiagramCommandEvent.changeShapeDataItems, diagramId, {
                    [shapeId]: changePayload,
                });
            },
        );
    }
}
