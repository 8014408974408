import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeatureList } from '../../../framework/feature/feature-list.svc';
import { AbstractNotification } from 'flux-core/src/ui';
import { StateService } from 'flux-core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Notification that appears when the user tries to select
 * copy, paste or cut from the context menu in browsers where this
 * action is not supported. E.g: Firefox.
 */

@Component({
    templateUrl: './copy-paste-notification.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'copy-paste-notification',
    styleUrls: [ './copy-paste-notification.cmp.scss' ],
})

export class CopyPasteNotification extends AbstractNotification {

    public featureList: FeatureList;

    constructor(
        public translate: TranslateService,
        protected injector: Injector,
        protected state: StateService<any, any>,
        protected sanitizer: DomSanitizer,
    ) {
        super( sanitizer );
        this.featureList = this.injector.get( 'FeatureList' );
    }

    protected onInvisible(): void {
        this.state.set( 'CopyPasteNotifier', false );
    }
}
