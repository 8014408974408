import { AbstractShapeViewFactory } from './abstract-shape-view-factory';
/**
 * This is the stateless factory for creating all types of Shape Views. Any view
 * created must be of the type ShapeRenderView or anything that extends that.
 *
 * This creates the view and merges the definition entry class into the view. Makes
 * the shapes view definition accessible from within the view and vice-versa.
 *
 * @author hiraash
 * @since 2017-09-06
 */
export class ShapeViewFactory extends AbstractShapeViewFactory {
    /**
     * The singleton instance of the ShapeViewFactory.
     */
    public static get instance(): ShapeViewFactory {
        if ( !this._instance ) {
            this._instance = new ShapeViewFactory();
        }
        return this._instance;
    }

    protected static _instance: ShapeViewFactory;

}
