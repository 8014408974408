import { IPoint2D, IPosition2D, IRectangle, ITransform, IVisualVector, MovementType } from 'flux-definition';
import { StagePosition } from '../../geometry/stage-position.svc';

/**
 * A visual vector is what enables to modify a shape's internal strcture without
 * applying transformation to the shape. It defines how the alteration can be done
 * and its limitation. Eventhough this exists as a model in the shape it is not a
 * part of the shape data. This actually is only used a visual indicator on the
 * shape during editing.
 */
export class VisualVectorModel implements IVisualVector {

    /**
     * This is a unique value which helps to uniquly identify the visual vector.
     */
    public id: string;

    /**
     * List of points that represents the vector. Each point has x and y vales and the type of values.
     * TODO: In Future points can be extended to hold xLimit and yLimits as follows.
     * xLimit?: IPositionLimit;
     * yLimit?: IPositionLimit;
     * xLimit & yLimit will be used to define movement of the vector points.
     */
    public points: IPosition2D[];

    /**
     * Indicates how the user can move the handle. Possible values are 'free' indicating the handle
     * can be moved in any direction, 'horizontal' indicating handle can only move on the x axis,
     * 'vertical' indicating the handle can only move on the y axis. Default is 'free'.
     */
    public movement?: MovementType = 'free';

    /**
     * Indicates if the visual vector be moved out of its container's bounds or not. By defalt it is
     * false that means it cannot move out of the bounds. If a vector can be moved out of the bounds
     * then explicitly set it as false.
     */
    withInBounds?: boolean = true;

    /**
     * Returns the exact point on the shape where the vector should place
     * @param bounds The original bounds of the shape on which the vector places
     * @param transform The current transformation of the shape on which vector places
     */
    public getPositions( bounds: IRectangle, transform?: ITransform ): IPoint2D[] {
        return this.points.map( point => {
            const position: IPosition2D = {
                x: { type: point.x.type || 'relative', value: point.x.value },
                y: { type: point.y.type || 'relative', value: point.y.value },
            };
            return StagePosition.onViewportRect( position, bounds, transform );
        });
    }

    /**
     * Retrieves single point vector data for position the vector.
     * @param shape Shape which has the vector
     * @param vectorModel VectorModel which has vector data.
     */
    public getVectorData( bounds: IRectangle, transform: ITransform ): any {
        return {
            id: this.id,
            points: this.getPositions( bounds, transform ),
            movement: this.movement,
            withInBounds: this.withInBounds,
        };
    }
}
