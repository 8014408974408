import { IChainStatus } from '../chain-sequence/chain-status.i';

/**
 * This class represents the status of the application initialization sequence.
 * Implements the {@link IChainStatus} interface.
 * For a detailed overview of the chain sequence system please refer to class documentation
 * on {@link ChainSequenceController}.
 *
 * @author  Ramishka
 * @since   2017-12-05
 */
export class InitializationChainStatus implements IChainStatus {

    /**
     * A map of each responsibility so far traversed in the sequence and their
     * states
     */
    public states: { [ name: string]: any };

    /**
     * The outcome of the current chain sequence.
     * It needs to conform to {@link IChainOutcome} interface.
     */
    public outcome: IChainOutcome;

    /**
     * The action that is requeseted to be performed.
     * This will also contain the resource id.
     */
    public input: { action: string, resourceId: string };

    /**
     * Constructor
     * @param action - the requested action
     * @param resourceId - resource id relevant to the requested action
     * @param presetStates - any states that are pre set for this sequence
     */
    constructor ( action: string, resourceId?: string, presetStates?: any ) {
        if ( !presetStates ) {
            this.states = {};
        } else {
            this.states = presetStates;
        }
        this.input = { action, resourceId };
    }

}

/**
 * When the outcode is command, these values will be used to dispatch.
 */
export interface IChainOutcomeCommandParams {
    rid?: string;
    data?: any;
}

/**
 * This interface defines an outcome that can emit in an
 * initialization sequence.
 */
export interface IChainOutcome {

    /**
     * Type of the outcome.
     */
    type: 'command' | 'commandEvent' | 'route' | 'complete' | 'redirect';

    /**
     * The action that is to be performed, as decided by an {@link IResponsibility}.
     * This will not be set for 'complete' type outcomes.
     */
    action?: any;

    /**
     * Whether the sequence should be held until the action to be performed is complete.
     */
    wait?: boolean;

    /**
     * Additional data to pass when dispatching commands (the resourceId and initial data).
     */
    commandParams?: IChainOutcomeCommandParams;
}
