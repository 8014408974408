import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { GlobalNotificationService } from '../global-notification.svc';

/**
 * Receieve realtime notification from server.
 * This command should not trigger from client app.
 */
@Injectable()
@Command()
export class NewGlobalNotification extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected globalNotificationService: GlobalNotificationService ) {
        super()/* istanbul ignore next */;
    }

    /**
     * This method should never ever executed.
     * @returns
     */
    public execute(): boolean {
        // This command should not be triggered from client/app.
        // This command should only generated from server( Through Neutrino from Notification Service )
        return false;
    }

    /**
     * Get the Notification from receieved message and add to the top of global notifications list
     * in the stage service.
     * @param response
     * @returns Returns an observable.
     */
    public executeResult( response: any ): boolean {
        this.globalNotificationService.addNewGlobalNotification( response.notification );
        return true;
    }
}

Object.defineProperty( NewGlobalNotification, 'name', {
    value: 'NewGlobalNotification',
});
