import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
/**
 * This is the tag item component.
 * When a set of strings are committed in the tags-input-field component
 * they will be convered into tag-items.
 *
 * The tag-item will simply display a tag text inside a styled div.
 *
 * The tag-item will also emit the value of the tag to be deleted, so that the parent
 * can remove the item from it's component.
 *
 * @author nuwanthi
 * @since 2018-26-06
 */

@Component({
    templateUrl: './tag-item.cmp.html',
    selector: 'tag-item',
    styleUrls: [ './tag-item.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TagItem {

    /**
     * Text that appears on the tag.
     */
    @Input() public text: string;

    /**
     * Event emitter that emits the delete tags.
     */
    @Output() public deleteTags = new EventEmitter();

    /**
     * Defines if the component is readonly.
     */
    @Input() public readonly: boolean;
}

