<label class="radio-btn-item {{size}}">
    <div class="radio-btn-item-label">
        <p>{{title}}</p>
        <p *ngIf="description" class="grey">{{description}}</p>
    </div>

    <input class="radio-btn-input " 
    type="radio" 
    [value]="value" 
    [name]="name" 
    [disabled]="disabled"
    (change)="handleChange()"
    [checked]="checked">

    <span class="radio-btn-selection fx-round selection-btn"></span>
</label>