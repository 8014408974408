import { DetailsSummary } from '@tiptap-pro/extension-details-summary';
import Details from '@tiptap-pro/extension-details';
import { INodeView } from './node-view.i';
import { mergeAttributes } from '@tiptap/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import DetailsContent from '@tiptap-pro/extension-details-content';

export class TiptapPrimaryTextNodeView implements INodeView {

    public static create( injector: Injector, viewCR: ViewContainerRef, diagramId: string ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const commandService = injector.get( CommandService );
        const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const instance = new TiptapPrimaryTextNodeView(
            cfr, injector, commandService, stateSvc, ll, viewCR,
            diagramId,
        );
        return instance;
    }
    public parentType = Details;
    public name = 'primaryTextNode';
    public content = 'detailsSummary detailsContent';
    public draggable = false;


    // All the node views created are stored here
    protected items = {};

    private constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected command: CommandService,
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected dir: ViewContainerRef,
        protected diagramId: string,
    ) {

    }

    public addOptions = () => ({
        persist: true,
        openClassName: 'is-open',
        HTMLAttributes: {},
    })

    public addAttributes = function ( this ) {
        if ( !this.options.persist ) {
            return [];
        }
        return {
            open: {
                default: false,
                parseHTML: element => element.hasAttribute( 'open' ),
                renderHTML: ({ open }) => {
                    if ( !open ) {
                        return {};
                    }
                    return { open: '' };
                },
            },
            showbutton: {
                default: true,
            },
        };
    };

    public parseHTML = () => [
        {
            tag: 'primary-text-node',
        },
    ]

    public renderHTML = function ( this, {  HTMLAttributes }) {
        return [
            'primary-text-node',
            mergeAttributes( this.options.HTMLAttributes, HTMLAttributes ),
            0,
        ];
    };

    public destroy() {
    }

}


export class TiptapPrimaryTextContent implements INodeView {
    /**\
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create() {
        const instance = new TiptapPrimaryTextContent();
        return instance;
    }

    public parentType = DetailsContent;
    // public name = 'primaryTextContent';
    public content = 'block*';
    // public defining = true;
    // public selectable = false;

    private constructor() {
    }

    public addNodeView = function( this ) {
        return ({
            editor,
            node,
            getPos,
            HTMLAttributes,
            decorations,
            extension,
            }) => {

                const dom = document.createElement( 'div' );
                const attributes = mergeAttributes( this.options.HTMLAttributes, HTMLAttributes, {
                    'data-type': this.name,
                    'hidden': 'hidden',
                });
                Object.entries( attributes ).forEach(([ key, value ]) => dom.setAttribute( key, value ));
                dom.addEventListener( 'toggleDetailsContent', () => {
                    if ( dom?.parentElement?.parentElement.classList.contains( 'is-open' )) {
                        dom.removeAttribute( 'hidden' );
                    } else {
                        dom.setAttribute( 'hidden', 'true' );
                    }
                });
                return {
                    dom,
                    contentDOM: dom,
                    ignoreMutation( mutation ) {
                        if ( mutation.type === 'selection' ) {
                            return false;
                        }
                        return !dom.contains( mutation.target ) || dom === mutation.target;
                    },
                    update: updatedNode => {
                        if ( updatedNode.type !== node.type ) {
                            return false;
                        }
                        return true;
                    },
                };
            };
    };


    public destroy() {
    }

}

export class TiptapPrimaryTextSummary implements INodeView {
    /**\
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create( injector: Injector, viewCR: ViewContainerRef, diagramId: string ) {
        // const cfr = injector.get( ComponentFactoryResolver );
        // const commandService = injector.get( CommandService );
        // const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const instance = new TiptapPrimaryTextSummary( ll, diagramId );
        return instance;
    }

    public parentType = DetailsSummary;

    private constructor( protected ll: DiagramLocatorLocator, protected diagramId: string ) {
    }

    public destroy() {
    }

    public addAttributes = () => ({
        inactive: {
            default: false,
        },
        contenteditable: {
            default: true,
        },
    })

}
