import { Component, Input, OnInit } from '@angular/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { DefinitionLocator } from 'apps/nucleus/src/base/shape/definition/definition-locator.svc';
import { Logger, StateService } from 'flux-core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    template: `
    <div class="edata-search-item" draggable="true" tooltip placement="right" (dragstart)="handleDragStart( $event )" (dragend)="handleDragEnd( $event )">
        <svg class="nu-icon nu-ic-movable">
            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-movable"></use>
        </svg>
        <span>{{title}}</span>
        <span class="tooltip-content">Drag and drop to add to the workspace</span>
    </div>`,
    selector: 'edata-search-result-item',
    styleUrls: [ './edata-search-result-item.cmp.scss' ],
})

export class EdataSearchResultItem implements OnInit {


    @Input() public data: any;

    public title: string;

    /**
     * The definition id of the datasource EDATA model.
     */
    @Input()
    public eDefId: string;

    /**
     * The definition id of the datasource SHAPE.
     */
    @Input()
    public defId: string;

    /**
     * version of the SHAPE definition.
     */
    @Input()
    public version: number;

    /**
     * Holds the currentLibraries state subscription.
     */
    protected subs: Subscription[] = [];


    constructor(
        protected defLocator: DefinitionLocator,
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        ) { }

    ngOnInit() {
            const entity = this.data.entity;
            const def = this.data.definition;

            const eDataDef = entity.getDef();
            this.title = def.name;
            if ( entity.data && entity.data[eDataDef.titleDataItem]) {
                this.title = entity.data[eDataDef.titleDataItem];
            } else {
                const t = entity.getPrimaryText();
                if ( t ) {
                    this.title = t;
                } else {
                    // FIXME: Packages don't have data property of entity
                    Logger.debug( 'EDataLibraryPanel.createLibraryItem FAILED ', entity );
                }
            }
     }

    handleDragStart( event: any ) {
        event.stopPropagation();
        const data = cloneDeep( this.data.definition );
        data.sidebarEntity = this.data.entity;
        data.triggerNewEData = true;
        if ( this.data.entity ) {
            data.eData = {
                [this.data.eDataModel.id]: this.data.entity.id,
            };
        }
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData( 'Text', JSON.stringify( data ));
        this.state.set( 'ImageOverlay', 'entity-import' );
    }

    public handleDragEnd( event ) {
        this.state.set( 'ImageOverlay', '' );
    }
}
