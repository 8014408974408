import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, RequestMethod, StateService } from 'flux-core';
import { EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will fetch the typesense scope search key and
 * add it in to the user state
 * @since   2023-11-13
 * @author  Sajeeva
 */
@Injectable()
@Command()
export class FetchTypesenceSearchKey extends AbstractHttpCommand {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result: Object };
    }

    constructor( protected state: StateService<any, any> ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'gravity-token': window.gravity.client.getCookie(),
        });
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get( 'TYPESENSE_API_KEY_URL' );
    }

    /**
     * Command execute
     */
    public execute() {
        return !this.isKeyAvailable();
    }

    public executeResult() {
        this.state.set( 'typesense', {
            searchKey: this.resultData.searchKey,
            expiresAt: this.resultData.expiresAt,
        });
        return EMPTY;
    }

    /**
     * Check whether a key is available in local cache.
     */
    private isKeyAvailable(): boolean {
        if ( this.state.get( 'typesense' )
                && this.state.get( 'typesense' ).expiresAt ) {
            const expiresAt = this.state.get( 'typesense' ).expiresAt;
            const currentTimestamp = new Date().getTime();
            return expiresAt > currentTimestamp;
        }
        return false;
    }
}

Object.defineProperty( FetchTypesenceSearchKey, 'name', {
    value: 'FetchTypesenceSearchKey',
});
