import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { findIndex } from 'lodash';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractIndexChangeCommand } from './abstract-index-change.cmd';

/**
 * This command sends a given shape or a set of shapes behind another
 * shape.
 * Shapes can either be passed in, or the command will operate on shapes
 * in the current selection
 *
 * @author  Ramishka
 * @since   2019-02-06
 */
@Injectable()
@Command()
export class SendBackward extends AbstractIndexChangeCommand {

    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( state, ds ) /* istanbul ignore next */;
    }

    /**
     * Updates the zIndex of selected shapes so they are sent behind another
     * shape.
     * The shape to be sent backwards of is decided by the following algorithm:
     * - Find the topmost shape in the selection.
     * - Check if the topmost shape overlaps with any other shapes.
     * - If overlaps, send the selected shapes behind the topmost overlapping shape.
     *   shapes.
     * - If it does not overlap, send the selected shapes behind the shape that
     *   has the lowest closest zIndex to that of the topmost slected shape.
     * In the event of a multi selection, the zIndex order between the shapes are
     * maintained.
     */
    public prepareData() {
        const shapes = this.getShapeIds();
        const diagram = this.changeModel;
        if ( !diagram.shapes || Object.keys( diagram.shapes ).length <= 1 ) {
            return;
        }

        const sendBackwardsOf = this.getShapeToSendBackwardsOf( shapes );
        if ( sendBackwardsOf ) {
            const indexes = diagram.getIndexesToSendBackwards( sendBackwardsOf.id, shapes );
            indexes.forEach( a => {
                const shape = this.changeModel.shapes[ a.id ];
                if (( shape as any ).containerId !== sendBackwardsOf.id ) {
                    this.changeModel.shapes[ a.id ].zIndex = a.index;
                }
            });
        }
    }

    /**
     * Finds the shape which other shapes will be send to the back of.
     * Considers the set of shapes being moved and the overlapping shapes
     * to make this decision.
     * @param shapes - shapes being moved
     * @return model of the shape that other shapes will be sent to the back of
     */
    private getShapeToSendBackwardsOf( shapes: string[]): AbstractShapeModel {
        let sendBackwardsOf;
        const diagram = this.changeModel;
        const selectedShapes = diagram.getShapesOrderedByIndex( shapes );
        const topSelected = selectedShapes[0];
        const overlaps = diagram.getOverlappingShapes( topSelected.id )
            .filter( shape => shape.zIndex <= topSelected.zIndex );
        if ( overlaps.length > 1 ) {
            const shapePos = findIndex( overlaps, s => s.zIndex === topSelected.zIndex );
            sendBackwardsOf = overlaps[shapePos - 1];
        } else {
            sendBackwardsOf = diagram.getShapeAtBack( topSelected.id );
        }
        return sendBackwardsOf;
    }
}

Object.defineProperty( SendBackward, 'name', {
    value: 'SendBackward',
});
