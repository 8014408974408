import { CommandScenario } from 'flux-core';
import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { IModifier, ISavedModelChange, IUnsavedModelChange, ModifierUtils } from 'flux-core';
import { DataSync } from 'flux-store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EDataModel } from './model/edata.mdl';
import { EDataLocatorLocator } from './locator/edata-locator-locator';


/**
 * This service can be used to validate, insert and apply changes.
 */
@Injectable()
export class EDataChangeService {
    private changeModels: { [eventId: string]: Proxied<EDataModel> } = {};


    /**
     * Inject stuff! the Java of JavaScript!
     */
    constructor(
        private el: EDataLocatorLocator,
        private ds: DataSync,
    ) {
    }

    /**
     * Returns a change model for the given event id.
     */
    public getChangeModel(
        resourceId: string,
        eventId: string,
        scenario: CommandScenario,
    ): Observable<Proxied<EDataModel>> {
        const cachedModel = this.changeModels[eventId];
        if ( cachedModel ) {
            return of( cachedModel );
        }

        return this.el.getEData( resourceId ).pipe(
            switchMap( locator =>
                locator.getEDataModelOnce().pipe(
                    map( model => {
                        const proxied = Sakota.create( model );
                        this.changeModels[eventId] = proxied;
                        return proxied;
                    }),
                )));
    }

    /**
     * Get currently recorded changes from the change model.
     */
    public flushChanges( eventId: string, clearMap = true ): IModifier {
        const cachedModel = this.changeModels[eventId];
        if ( !cachedModel ) {
            return null;
        }
        if ( clearMap ) {
            // removing the cached model from the map so that it won't grow gradually.
            delete this.changeModels[eventId];
        }
        const modifier = cachedModel.__sakota__.getChanges();
        if ( ModifierUtils.isEmptyModifier( modifier )) {
            return null;
        }
        return modifier;
    }


    /**
     * This method applies the change as an unsaved change.
     */
    public addUnsavedChange( change: IUnsavedModelChange ): Observable<unknown> {
        return this.ds.storeChanges( EDataModel, [ change ]);
    }

    /**
     * This method applies the change as a saved change.
     */
    public addSavedChange( change: ISavedModelChange ): Observable<unknown> {
        return this.ds.applyChanges( EDataModel, [ change ]);
    }
}
