import { AbstractCommand, CommandInterfaces, StateService, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { IShapeVotingState } from '../../feature/shape-voting.svc';
import { of } from 'rxjs';

/**
 *
 * @author  Sajeeva
 * @since   2024-02-05
 */
@Injectable()
@Command()
export class FilterOngoingVotingShapes extends AbstractCommand {

    /**
     * This command is used to retrieve data. Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        copyEdata?: any,
    };

    constructor(
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any> ) {
        super();
    }

    public get locator() {
        return this.ll.forCurrent( false );
    }

    public execute() {
        const shapeVotingState: IShapeVotingState = this.state.get( 'ShapeVoting' );
        const progress = shapeVotingState.progress;
        if ( progress && ( progress === 'active' || progress === 'results' )) {
            const shapeIds: string[] = this.data.shapeIds.filter(
                value => !shapeVotingState.shapeIds.includes( value ));
            this.resultData = {
                    shapeIds,
                    copyEdata: this.data.copyEdata,
                };
            return of( true );

        }
        this.resultData = this.data;
        return of( false );
    }

}

Object.defineProperty( FilterOngoingVotingShapes, 'name', {
    value: 'FilterOngoingVotingShapes',
});
