import { CollabLocator } from './../locator/collab-locator';
import { Directive, Input, OnDestroy, OnInit, ViewContainerRef, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Subscription, empty } from 'rxjs';
import { CollaboratorType } from 'flux-diagram';
import { StateService } from 'flux-core';
import { filter, switchMap, map } from 'rxjs/operators';

/**
 * DiagramRole Directive
 * This directive can be used to control features based on users' role of the current diagram.
 * When specifying which user roles are allowed, pass the minimum allowed role to the directive.
 * For all roles which are less than or equal to the given role will pass the directive and enable
 * the UI feature/element.
 *
 * How to use:
 * <div class="foo" *diagramRole="'EDITOR'"></div>
 * This will enable the element for all user roles upto editor, and lesser roles won't
 * see this element, ie: REVIEWER
 */
@Directive({
    selector: '[diagramRole]',
})
export class DiagramRole implements OnInit, OnDestroy {

    /**
     * Get roles from the directive.
     * Any role from CollaboratorType enum is valid
     */
    @Input()
    public diagramRole: string;

    /**
     * Holds the current view state, if it is enabled or removed
     */
    private viewEnabled: boolean = false;

    /**
     * Subscription which controls the view state based on users' role
     */
    private changeSub: Subscription;

    /**
     * Constructor
     * @param locator Diagram locator
     * @param state State service
     * @param templateRef TemplateRef
     * @param viewContainer ViewContainerRef
     * @param changeDetector ChangeDetectorRef
     */
    public constructor(
        private state: StateService<any, any>,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private changeDetector: ChangeDetectorRef,
        private collabLocator: CollabLocator,
    ) {}

    /**
     * Start controlling the view
     */
    public ngOnInit(): void {
        if ( CollaboratorType[ this.diagramRole ] === undefined ) {
            throw new Error( 'DiagramRole directive require a valid collaborator role to be matched against.' );
        }
        this.changeSub = this.state.changes( 'CurrentUser' ).pipe(
            filter( userId => !!userId ),
            switchMap( userId => {
                if ( this.state.get( 'CurrentDiagram' )) {
                    return this.collabLocator.getCollabs().pipe(
                        map( collabs => {
                            if ( collabs.length > 0 ) {
                                const collabUser = collabs.find( c => c.id === userId );
                                if ( collabUser && collabUser.role <= CollaboratorType[ this.diagramRole ]) {
                                    this.addElement();
                                } else {
                                    this.removeElement();
                                }
                            }
                        }),
                    );
                } else {
                    return empty();
                }
            }),
        ).subscribe();
    }

    /**
     * Housekeeping, remove the subscription upon destroying
     */
    public ngOnDestroy(): void {
        this.changeSub.unsubscribe();
    }

    /**
     * Create Embedded View from the TemplateRef and insert to viewContainer
     */
    private addElement() {
        if ( !this.viewEnabled ) {
            this.viewContainer.createEmbeddedView( this.templateRef );
            this.viewEnabled = true;
            this.changeDetector.detectChanges();
        }
    }

    /**
     * Destroys all the views in the viewContainer
     */
    private removeElement() {
        this.viewContainer.clear();
        this.viewEnabled = false;
        this.changeDetector.detectChanges();
    }
}
