import { Injectable } from '@angular/core';
import { AbstractHttpCommand, AppConfig, Command, CommandInterfaces, ResourceLoader } from 'flux-core';
import { EMPTY, Observable } from 'rxjs';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { ResourceStatus } from 'flux-definition';

/**
 * This command is used to upload an imported image to the
 * server. The image content along with its metadata is sent
 * to the server to be stored.
 *
 * Input data format:
 *  {
 *      hash: image hash value
 *      data: true,
 *      name: true,
 *      type: true,
 *      extension: true,
 *  }
 *
 * @since   2019-02-22
 * @author  Ramishka
 */
@Injectable()
@Command()
export class UploadImage extends AbstractHttpCommand  {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            data: true,
            name: true,
            type: true,
            extension: true,
        };
    }

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IUserCommand', 'INeutrinoRestCommand', 'IPluginCommand', 'IAllowAnonymous',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    /**
     * Image Types used here to create extension from it.
     */
    protected imageTypes = {
        'image/png': 'png', 'image/jpeg': 'jpg', 'image/jpg': 'jpg',
        'image/gif': 'gif', 'image/bmp': 'bmp', 'image/x-icon': 'ico',
        'image/svg+xml': 'svg',
    };

    /**
     * This holds the hash value of the image
     */
    protected hash: string;

    constructor( protected auth: NucleusAuthentication,
                 protected loader: ResourceLoader ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'REST_API_BASE_URL' )
            + '/' + AppConfig.get( 'MESSAGE_PROTOCOL_VERSION' )
            + '/1/user/upload-image';
    }

    /**
     * Attaches the auth token to command data
     * The REST execution step requires it.
     * Add missing extension and remove the has which
     * is not necessary for uploading.
     */
    public prepareData(): any {
        this.data.auth = this.auth.token;
        if ( !this.data.extension ) {
            this.data.extension = this.imageTypes[ this.data.type ] || '';
        }
        this.hash = this.data.hash;
        delete this.data.hash;
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

    /**
     * This function will update the cached resource
     * status as 'uploaded' on the success.
     */
    public executeResult( response: any ) {
        if ( response ) {
            this.loader.setResourceStatus( this.getImageUrl(), ResourceStatus.Uploaded );
        }
        return true;
    }

    /**
     * Retrieves the url of the image.
     */
    protected getImageUrl(): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + this.hash;
    }
}

Object.defineProperty( UploadImage, 'name', {
    value: 'UploadImage',
});
