import { Injectable } from '@angular/core';
import { Logger, AppConfig } from 'flux-core';
import { Observable, Subscription as RxSubscription, timer } from 'rxjs';
import { Subscription, SubscriptionStatus } from './subscription-type';
import { catchError, tap } from 'rxjs/operators';
import { empty } from 'rxjs';
import { MessageResultError } from 'flux-connection';

/**
 * AbstractSubscriptionService
 * AbstractSubscriptionService is the base class for subscription service
 * classes. This level is responsible for functions which are common for
 * both model type and list type subscriptions.
 */
@Injectable()
export abstract class AbstractSubscriptionService {
    /**
     * subscriptions
     * subscriptions is a map of topic => subscription instances.
     */
    protected subscriptions: { [ topic: string ]: Subscription };

    /**
     * This map holds all subscriptions that are scheduled to be stopped.
     * The topic ids are mapped to the Rx.Subscription returned when scheduling.
     */
    protected subscriptionsToExpire: { [ topic: string ]: RxSubscription };

    /**
     * constructor
     * constructor initializes subscription maps.
     */
    constructor( protected logger: Logger ) {
        this.subscriptions = {};
        this.subscriptionsToExpire = {};
    }

    /**
     * Returns true if the app is offline.
     * NOTE: In here the ConnectionStatus is not used because
     * state is not initialized at this moment.
     */
    private get isOffline(): boolean {
        return !window.navigator.onLine;
    }

    /**
     * Starts a given subscription.
     * If the given subscription is already started, stops and starts it again.
     * If the given subscription is scheduled to be removed, it would cancel the schedule.
     * @param sub subscription instance to be started.
     * @return subscription instance that was started.
     */
    public start( sub: Subscription, pld?: {[key: string]: any }): Subscription {
        this.cancelExpiry( sub.topic );
        if ( this.subscriptions[sub.topic]) {
            this.stop( this.subscriptions[sub.topic]);
        }
        this.logger.debug( `SubscriptionService: starting subscription: ${sub.topic}` );
        this.subscriptions[sub.topic] = sub;
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' ) || this.isOffline ) {
            sub.updateStatus( SubscriptionStatus.started );
            return sub;
        }

        // If payload is passed, use it. Otherwise start subscription without using it.
        const sub$ = pld ? this.startSubscription( sub, pld ) : this.startSubscription( sub );

        sub$.pipe(
            tap({
                complete: () => sub.updateStatus( SubscriptionStatus.started ),
            }),
            catchError( err => {
                this.handleSubStartEror( sub, err );
                return empty();
            }),
        ).subscribe();
        return sub;
    }

    /**
     * Stops a given subscription with immediate effect.
     * @param sub subscription instance to be stopped.
     * @param sendMessage - whether the sub.end message should be sent to the server
     *                      when closing the subscription
     * @return subscription instance that was stopped.
     */
    public stop( sub: Subscription, sendMessage: boolean = true ): Subscription {
        this.logger.debug( `SubscriptionService: stopping subscription: ${sub.topic}` );
        if ( sub === this.subscriptions[sub.topic]) {
            delete this.subscriptions[sub.topic];
            if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
                sub.updateStatus( SubscriptionStatus.completed );
                return;
            }
            this.closeSubscription( sub, sendMessage ).subscribe();
        }
        return sub;
    }

    /**
     * Stops a given subscription after a given amount of time.
     * If another service requests to start the same subscription within this time interval,
     * the expiration is cancelled and the same subscription is retained.
     * @param sub subscription instance to be stopped.
     * @param expireIn - time (milliseconds) to expire the subscription
     * @return subscription instance that was scheduled to be stopped.
     */
    public expire( sub: Subscription, expireIn: number ): Subscription {
        this.logger.debug( `SubscriptionService: scheduling to stop subscription: ${sub.topic} in ${expireIn} ms` );
        if ( sub === this.subscriptions[sub.topic]) {
            this.subscriptionsToExpire[ sub.topic ] = timer( expireIn ).pipe(
                tap({
                    complete: () => {
                        this.stop( sub );
                        delete this.subscriptionsToExpire[sub.topic];
                    },
                }),
            ).subscribe();
        }
        return sub;
    }

    /**
     * startSubscription starts a subscriptions.
     */
    protected abstract startSubscription(
        sub: Subscription, pld?: {[key: string]: any }): Observable<unknown>;

    /**
     * closeSubscription closes a subscriptions.
     * @param sub - subscription instance to be stopped
     * @param sendMessage - whether the sub.end message should be sent to the server when closing
     */
    protected abstract closeSubscription( sub: Subscription, sendMessage: boolean ): Observable<unknown>;

    /**
     * This function cancels the expiration of a subscription that is
     * scheduled to be expired.
     * @param topic topic of the subscription to cancel the expiration
     */
    protected cancelExpiry( topic: string ) {
        if ( this.subscriptionsToExpire[topic]) {
            this.subscriptionsToExpire[topic].unsubscribe();
            delete this.subscriptionsToExpire[topic];
        }
    }

    /**
     * Handles subscription start errors
     * @param sub - subscription instance
     * @param err - the error that was thrown
     */
    private handleSubStartEror( sub: Subscription, err: any ) {
        sub.updateStatus( SubscriptionStatus.errored, err );
        if ( this.isKnownError( err )) {
            this.logger.warning(
                `SubscriptionService: Error starting subscription ${sub.subscription}.${sub.resourceId }.
                Error code : ${err.code}`,
            );
        } else {
            this.logger.error(
                `SubscriptionService: Error starting subscription ${sub.subscription}.${sub.resourceId }.
                 Error : ${err}`,
            );
        }
    }

    /**
     * Returns true if the error is caused by a known scenario
     * that would fail the subscription start.
     */
    private isKnownError( error: MessageResultError ) {
        if ( error.code === 1202 || error.code === 1203 ||
                error.code === 1301 || error.code === 2201  ) {
            return true;
        }
        return false;
    }
}
