import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';
import { Matrix } from 'flux-core';
import { IPoint2D } from 'flux-definition';
import { path2curve } from 'raphael';
import * as pathParser from 'svg-path-parser';

/**
 * Represents the basic construct of a svg path instruction when normalized using svg-path-parser
 */
interface IParserPathCommand {
    code: string;
    command: string;
}

/**
 * Representation of svg move and line command after being parsed by the svg-path-parser
 */
interface IParserMoveAndLineCommand extends IParserPathCommand {
    x: number;
    y: number;
}

/**
 * Representation of svg bezier curve command after being parsed by the svg-path-parser
 */
interface IParserBezierCurveCommand extends IParserMoveAndLineCommand {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

/**
 * Representation of svg smooth bezier curve command after being parsed by the svg-path-parser
 */
interface IParserSmoothBezierCurveCommand extends IParserMoveAndLineCommand {
    x2: number;
    y2: number;
}

/**
 * Representation of svg quadratic bezier curve command after being parsed by the svg-path-parser
 */
interface IParserQuadraticBezierCurveCommand extends IParserMoveAndLineCommand {
    x1: number;
    y1: number;
}

/**
 * Representation of svg smooth quadratic bezier curve command after being parsed by the svg-path-parser
 */
interface IParserSmoothQuadraticBezierCurveCommand extends IParserPathCommand {
    x: number;
    y: number;
}

/**
 * Representation of svg elliptical arc command after being parsed by the svg-path-parser
 */
interface IParserEllipticalArcCommand extends IParserMoveAndLineCommand {
    rx: number;
    ry: number;
    xAxisRotation: number;
    largeArc: boolean;
    sweep: boolean;
}

/**
 * class PathCanvasInstructionFactory
 * This class implements required functionality to translate svg path element instructions to
 * graphics instructions. This class only care about the translation of path element only. Any styles contain
 * on the node must be translated by style factory. When merging the styles and the instructions on path node,
 * - Add style commands --> get them translated from style factory
 * - Add path instructions
 * - Apply end style commands --> get them from style factory
 */
export class PathCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {


    /**
     * Which svg element this class translate
     */
    public svgElementName = 'path';

    /**
     * This holds all sub-path handler functions used to translate path commands to
     * canvas instructions.
     */
    private subPathHandlers: { [ subPathType: string ]: Function };

    /**
     * Holds generated canvas instructions
     */
    private instructions: ICanvasInstruction[];

    /**
     * Current x,y coordinates which will be used to calculate
     * absolute coordinates from any relative coordinates
     */
    private currentPoint: IPoint2D;

    /**
     * Current control point which will be used by short hand sub-path instructions
     * such as T, t, S, s. Each sub-path instruction must record the control point since
     * it is not mandatory for above shot hand commands to follow a bezier or quadratic curve command.
     * If the short hand command, last x,y coordinates which act as the starting point becomes the control point.
     * Refer https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d for more detailed explanation.
     */
    private controlPoint: IPoint2D;

    /**
     * Starting point of the path sub commands.
     * During closePath instruction, current path must be set to this point.
     */
    private pathStartPoint: IPoint2D;

    /**
     * Indicate if the current sub path closed by a closePath instruction or not.
     * If the path is not closed, start of each sub path instruction must set the
     * path start point.
     */
    private pathClosed: boolean;

    /**
     * Setup the instance
     */
    public constructor() {
        super()/* istanbul ignore next */;
        this.instructions = [];
        this.currentPoint = { x: 0, y: 0 };
        this.controlPoint = { x: 0, y: 0 };
        this.pathClosed = true;
        this.pathStartPoint = { x: 0, y: 0 };
        this.subPathHandlers = {
            Z: this.handleClosePath.bind( this ),
            z: this.handleClosePath.bind( this ),
            M: this.handleAbsoluteMoveTo.bind( this ),
            m: this.handleRelativeMoveTo.bind( this ),
            L: this.handleAbsoluteLineTo.bind( this ),
            l: this.handleRelativeLineTo.bind( this ),
            H: this.handleAbsoluteHorizontalLineTo.bind( this ),
            h: this.handleRelativeHorizontalLineTo.bind( this ),
            V: this.handleAbsoluteVerticalLineTo.bind( this ),
            v: this.handleRelativeVerticalLineTo.bind( this ),
            C: this.handleAbsoluteBezierCurve.bind( this ),
            c: this.handleRelativeBezierCurve.bind( this ),
            S: this.handleAbsoluteSmoothBezierCurve.bind( this ),
            s: this.handleRelativeSmoothBezierCurve.bind( this ),
            Q: this.handleAbsoluteQuadraticBezierCurve.bind( this ),
            q: this.handleRelativeQuadraticBezierCurve.bind( this ),
            T: this.handleAbsoluteSmoothQuadraticBezierCurve.bind( this ),
            t: this.handleRelativeSmoothQuadraticBezierCurve.bind( this ),
            A: this.handleAbsoluteArc.bind( this ),
            a: this.handleRelativeArc.bind( this ),
        };
    }

    /**
     * Create instructions to draw svg path element on canvas.
     * @param data IShapeNode
     */
    public createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        this.instructions = [];
        if ( data.data.d ) {
            const pathCommands = pathParser( data.data.d );
            pathCommands.forEach( path => {
                const type = path.code;
                this.subPathHandlers[ type ]( path, data.transform );
            });
        }
        return this.instructions;
    }

    /**
     * Sub-path instruction handler for Z/z path commands
     * @param _
     */
    private handleClosePath( _: IParserPathCommand, __: Matrix ) {
        this.currentPoint = Object.assign({}, this.pathStartPoint );
        this.pathClosed = true;
        this.instructions.push({ instruction: 'closePath', params: []});
    }

    /**
     * Sub-path instruction handler for M path command
     * @param pathData
     */
    private handleAbsoluteMoveTo( pathData: IParserMoveAndLineCommand, matrix?: Matrix ) {
        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x, y: pathData.y });
        const instructions = [{
            instruction: 'moveTo',
            params: [ this.currentPoint.x, this.currentPoint.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForMoveAndLines( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for m path command.
     * This converts the relative moveTo instruction arry to absolute coordinates and
     * use absolute path handler to tranlate the instructions.
     * @param pathData
     */
    private handleRelativeMoveTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        pathData.x += this.currentPoint.x;
        pathData.y += this.currentPoint.y;
        this.handleAbsoluteMoveTo( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for L path command
     * @param pathData
     */
    private handleAbsoluteLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x, y: pathData.y });
        const instructions = [{
            instruction: 'lineTo',
            params: [ pathData.x, pathData.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForMoveAndLines( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for l path command
     * @param pathData
     */
    private handleRelativeLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        pathData.x += this.currentPoint.x;
        pathData.y += this.currentPoint.y;
        this.handleAbsoluteLineTo( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for H path command
     * @param pathData
     */
    private handleAbsoluteHorizontalLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        const point = { x: pathData.x, y: this.currentPoint.y };
        this.setPoints( point, point );
        const instructions = [{
            instruction: 'lineTo',
            params: [ this.currentPoint.x, this.currentPoint.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForMoveAndLines( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for h path command
     * @param pathData
     */
    private handleRelativeHorizontalLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        pathData.x += this.currentPoint.x;
        this.handleAbsoluteHorizontalLineTo( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for V path command
     * @param pathData
     */
    private handleAbsoluteVerticalLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        const point = { x: this.currentPoint.x, y: pathData.y };
        this.setPoints( point, point );
        const instructions = [{
            instruction: 'lineTo',
            params: [ this.currentPoint.x, this.currentPoint.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForMoveAndLines( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for v path command
     * @param pathData
     */
    private handleRelativeVerticalLineTo( pathData: IParserMoveAndLineCommand, matrix: Matrix ) {
        pathData.y += this.currentPoint.y;
        this.handleAbsoluteVerticalLineTo( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for C path command
     * @param pathData
     */
    private handleAbsoluteBezierCurve( pathData: IParserBezierCurveCommand, matrix: Matrix ) {
        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x2, y: pathData.y2 });
        const instructions = [{
            instruction: 'bezierCurveTo',
            params: [ pathData.x1, pathData.y1, pathData.x2, pathData.y2, pathData.x, pathData.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForBezierCurves( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for c path command
     * @param pathData
     */
    private handleRelativeBezierCurve( pathData: IParserBezierCurveCommand, matrix: Matrix ) {
        const x = this.currentPoint.x;
        const y = this.currentPoint.y;
        pathData.x1 += x;
        pathData.y1 += y;
        pathData.x2 += x;
        pathData.y2 += y;
        pathData.x += x;
        pathData.y += y;
        this.handleAbsoluteBezierCurve( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for S path command
     * @param pathData
     */
    private handleAbsoluteSmoothBezierCurve( pathData: IParserSmoothBezierCurveCommand, matrix: Matrix ) {
        const cx = 2 * this.currentPoint.x - this.controlPoint.x;
        const cy = 2 * this.currentPoint.y - this.controlPoint.y;

        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x2, y: pathData.y2 });
        const instructions = [{
            instruction: 'bezierCurveTo',
            params: [ cx, cy, pathData.x2, pathData.y2, pathData.x, pathData.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForBezierCurves( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for s path command
     * @param pathData
     */
    private handleRelativeSmoothBezierCurve( pathData: IParserSmoothBezierCurveCommand, matrix: Matrix ) {
        const x = this.currentPoint.x;
        const y = this.currentPoint.y;
        pathData.x2 += x;
        pathData.y2 += y;
        pathData.x += x;
        pathData.y += y;
        this.handleAbsoluteSmoothBezierCurve( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for Q path command
     * @param pathData
     */
    private handleAbsoluteQuadraticBezierCurve( pathData: IParserQuadraticBezierCurveCommand, matrix: Matrix ) {
        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x1, y: pathData.y1 });
        const instructions = [{
            instruction: 'quadraticCurveTo',
            params: [ pathData.x1, pathData.y1, pathData.x, pathData.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForQuadraticBezierCurves( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for q path command
     * @param pathData
     */
    private handleRelativeQuadraticBezierCurve( pathData: IParserQuadraticBezierCurveCommand, matrix: Matrix ) {
        const x = this.currentPoint.x;
        const y = this.currentPoint.y;
        pathData.x1 += x;
        pathData.y1 += y;
        pathData.x += x;
        pathData.y += y;
        this.handleAbsoluteQuadraticBezierCurve( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for T path command
     * @param pathData
     */
    private handleAbsoluteSmoothQuadraticBezierCurve(
        pathData: IParserSmoothQuadraticBezierCurveCommand,
        matrix: Matrix,
    ) {
        const cx = 2 * this.currentPoint.x - this.controlPoint.x;
        const cy = 2 * this.currentPoint.y - this.controlPoint.y;

        this.setPoints({ x: pathData.x, y: pathData.y }, { x: cx, y: cy });
        const instructions = [{
            instruction: 'quadraticCurveTo',
            params : [ cx, cy, pathData.x, pathData.y ],
        }];
        this.instructions.push( ...this.applyTransformationsForQuadraticBezierCurves( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for t path command
     * @param pathData
     */
    private handleRelativeSmoothQuadraticBezierCurve(
        pathData: IParserSmoothQuadraticBezierCurveCommand,
        matrix: Matrix,
    ) {
        pathData.x += this.currentPoint.x;
        pathData.y += this.currentPoint.y;
        this.handleAbsoluteSmoothQuadraticBezierCurve( pathData, matrix );
    }

    /**
     * Sub-path instruction handler for A path command
     * @param pathData
     */
    private handleAbsoluteArc( pathData: IParserEllipticalArcCommand, matrix: Matrix ) {
        const x = this.currentPoint.x;
        const y = this.currentPoint.y;

        this.setPoints({ x: pathData.x, y: pathData.y }, { x: pathData.x, y: pathData.y });

        const largeArc = pathData.largeArc ? 1 : 0;
        const sweep = pathData.sweep ? 1 : 0;
        const svgArc = `A ${pathData.rx} ${pathData.ry} ` +
        `${pathData.xAxisRotation} ${largeArc} ${sweep} ${pathData.x} ${pathData.y}`;

        const instructions: ICanvasInstruction[] = [];
        const curveCommnds = path2curve( `M ${x},${y} ${svgArc}` );
        curveCommnds.forEach( command => {
            if ( command.length === 7 ) {
                instructions.push({
                    instruction: 'bezierCurveTo',
                    params: <any>command.slice( 1 ),
                });
            }
        });
        this.instructions.push( ...this.applyTransformationsForBezierCurves( instructions, matrix ));
    }

    /**
     * Sub-path instruction handler for a path command
     * @param pathData
     */
    private handleRelativeArc( pathData: IParserEllipticalArcCommand, matrix: Matrix ) {
        pathData.x += this.currentPoint.x;
        pathData.y += this.currentPoint.y;
        this.handleAbsoluteArc( pathData, matrix );
    }

    /**
     * Mark the start of a new sub path. This can be the start of a path instruction process or
     * after a closePath instruction.
     */
    private markNewSubPath() {
        this.pathStartPoint = Object.assign({}, this.currentPoint );
        this.pathClosed = false;
    }

    /**
     * This set the current and control point coordinates required for subsequent path commands being processed.
     * @param currentPoint Current coordinates
     * @param controlPoint Control point coordinates
     */
    private setPoints( currentPoint: IPoint2D, controlPoint: IPoint2D ) {
        this.currentPoint = currentPoint;
        this.controlPoint = controlPoint;
        if ( this.pathClosed ) {
            this.markNewSubPath();
        }
    }

}
