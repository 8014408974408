import { MouseControlState } from './../../../base/base-states';
import { AbstractCommand, StateService, CommandInterfaces, Command, Tracker } from 'flux-core';
import { Injectable } from '@angular/core';
import { KeyCode } from 'flux-definition';
import { ICommentThreadData } from '../../interaction/comments/comment-thread.i';

/**
 * This ChangeMouseControlState can be used generally to set the mouse control state.
 * Also this command can be specifically used to set the MouseControl state to Pan
 * When space bar is pressed and switch it back to Normal state when the spacebar is released.
 */
@Injectable()
@Command()
export class ChangeMouseControlState extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            state: false, // The state is set regardless of the keyboardEvent
            keyboardEvent: false, // KeyboardEvent is checked to set the control state
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            MouseControlState: this.data.state,
        };
    }

    public execute (): boolean {
        if ( !this.data ) {
            return false; // At least one of state or keyboardEvent is needed
        }
        const curState = this.state.get( 'MouseControlState' );

        if ( this.data.keyboardEvent && this.data.keyboardEvent.type === 'keydown' ) {
            const state: ICommentThreadData = this.state.get( 'CommentThreadData' );
            if ( this.data.keyboardEvent.keyCode === KeyCode.C && state && !state.visible ) {
                this.data = { state: MouseControlState.Comment };
                Tracker.track( 'right.comment.add.click', { value1Type: 'trigger', value1: 'c' });
            } else if ( this.data.keyboardEvent.keyCode === KeyCode.Space ) {
                this.data = { state: MouseControlState.Pan };
            } else if ( this.data.keyboardEvent.keyCode === KeyCode.A ) {
                if ( curState === MouseControlState.Pen ) {
                    this.data = { state: MouseControlState.Normal };
                } else {
                    this.data = { state: MouseControlState.Pen };
                }
            } else if ( this.data.keyboardEvent.keyCode === KeyCode.H ) {
                if ( curState === MouseControlState.Highlighter ) {
                    this.data = { state: MouseControlState.Normal };
                } else {
                    this.data = { state: MouseControlState.Highlighter };
                }
            }
        } else if ( this.data.keyboardEvent && this.data.keyboardEvent.type === 'keyup' ) {
            // FIXME - Should set the previous state
            this.data = { state: MouseControlState.Normal };
        }

        return !!this.data.state;
    }
}

Object.defineProperty( ChangeMouseControlState, 'name', {
    value: 'ChangeMouseControlState',
});
