import { AppConfig } from 'flux-core';
import { Database } from '@creately/rxdata';
import { Injectable } from '@angular/core';
import * as shapes from './../shape-path';
/**
 * Class ShapeInjector
 *
 * This provide support functionality to import shapes into Nucleus
 * while developing. Any exported shape library on src/shape-path.ts
 * will be loaded into Nucleus and immediately made available for degub and testing.
 * While using `ng serve`, any changes done to the shape library will be automatically
 * updated on the browser.
 *
 * Refer to the README on the repo for instructions on setting up live loading.
 */
@Injectable()
export class ShapeInjector {

    /**
     * Name of indexdb collection which contains shape definitions.
     */
    protected readonly resourceCollectionName: string = 'creately-resources';

    /**
     * Holds all imported shapes.
     * Note: No need to do this, added to make the functionality testable
     */
    protected shapeDefinitions: any;

    constructor( private database: Database ) {
        // No need to do this
        // Only added to make the functionality testable
        this.shapeDefinitions = shapes.defs;
    }

    /**
     * URL to access definitions
     */
    protected get definitionLocation(): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + AppConfig.get( 'SHAPE_RESOURCE_BASE' );
    }

    /**
     * Insert shape definitions on the imported shape project into
     * indexdb shape definition collection.
     */
    public injectShapes() {
        if ( this.shapeDefinitions.length > 0 ) {
            const collection = this.database.collection<any>( this.resourceCollectionName );
            this.shapeDefinitions.forEach( def => {
                const id = this.definitionLocation + def.defId + '.' + def.version + '.json';
                collection.insert({ id: id, data: def });
            });
        }
    }
}
