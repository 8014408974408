import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * VizPrompt
 */
@Injectable()
@Command()
export class VizPrompt extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    /**
     * Command input data format
     */
    public stateData: any;

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return this.data.stateData;
    }

    /**
     * Prepares the data necessary for the zoom state change.
     */
    public prepareData(): void {

    }

    public execute (): boolean {
        return true;
    }

}

Object.defineProperty( VizPrompt, 'name', {
    value: 'VizPrompt',
});
