import { ShapeType } from 'flux-definition/src';
import { AbstractCommand, StateService, CommandInterfaces, Command, CommandScenario } from 'flux-core';
import { Injectable } from '@angular/core';
import { IPoint2D, IText } from 'flux-definition';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';

/**
 * This EditText command is to open or close the text editor and this command is
 * mere a view command and no interaction with model data or server.
 * This command updates the EditingTextState and by listening to that state it's
 * decided if the text editor should open or not.
 * The data requred for this command are open, textId and text where
 * 'open' is a boolean to define the close or opoen state, also this can be undefined
 * to define the toggle
 * 'textId' the id of the particular text that the editor is opened for, ( if this not specified,
 *  the primary text should be considered )
 * 'text' is the initial text to to loaded into the text editor, this is optional
 */
@Injectable()
@Command()
export class EditText extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            open: false, // The open or close state, also this can be undefined ( toggle ), (Used by state)
            textId: false, // Id of the editing text, can be undefined, then pickes the primary text id (Used by state)
            text: false, // The initial text to add to the text editor (Used by state)
            point: false, // The point on connector, can be a point closer to the connector
            shapeId: false, // Id of the shape to open the text editor, if not specifeid, selected shape is considered.
            keyboardEvent: false, // The keyboard event related to feature list shortcut
            editable: false, // for interrim calculations.
            cancel: false,
            shapeLogicData: false,
            rendering: false, // The rendering method for the text in the editor
            caretPosition: false, // can be start or end
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected ll: DiagramLocatorLocator,
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            EditingText: {
                open: this.data.open,
                shapeId: this.data.shapeId,
                textId: this.data.textId,
                text: this.data.text,
                point: this.data.point,
                cancel: this.data.cancel,
                shapeLogicData: this.data.shapeLogicData,
                caretPosition: this.data.caretPosition,
                rendering: this.data.rendering,
                disableRichtext: this.data.disableRichtext,
            },
        };
    }

    /**
     * Prepares the data necessary for the zoom state change.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public prepareData(): Observable<any> {
        if ( !this.data ) {
            this.data = {};
        }
        if ( !!this.data.cancelEditTextCmd ) {
            return;
        }
        this.data.editable = true;
        const selected: Array<string> = this.state.get( 'Selected' );
        if ( selected && selected.length > 0 ) {
            const locator = this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
            if ( !this.data.shapeId ) {
                this.data.shapeId = selected[ 0 ];
            }
            if ( this.data.open === undefined ) {
                this.data.open = this.toggle();
            }
            if ( this.data.keyboardEvent ) {
                this.data.caretPosition = 'end';
            }
            return locator.getShapeOnce( this.data.shapeId ).pipe(
                switchMap( shape =>
                    shape.defId && shape.version ? this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
                    map( def => [ shape, def ])) : of([ shape, undefined ])),
                tap(([ shape, def ]) => {
                    this.data.disableRichtext = false;
                    /**
                     * When the text editor is opened via the toolbar
                     * both "point"" and "textId" are "undefined".
                     * so when a connector is selected and point is undefined plus it already has texts
                     * the id of the primaryTextModel should be set as the textId
                     */
                    const shapeTextEntries = shape.texts ? Object.entries( shape.texts ) : [];
                    if ( shapeTextEntries.length === 1 ) {
                        const [ key, text ] = shapeTextEntries[0];
                        const shapeTextContent = ( text as any )?.content || [];
                        const rendering = ( text as IText ).rendering;
                        // rendering could be undefined
                        if (( !rendering || rendering === 'carota' ) && shapeTextContent.length === 1 ) {
                            const shapeText = shapeTextContent[0]?.text;
                            const defText = def?.texts?.[ key ]?.content?.[0]?.text;
                            if ( shapeText === defText ) {
                                this.data.selectAll = true;
                            }
                        }

                    }
                    if ( this.data.textId && shape.texts[ this.data.textId ]) {
                        this.data.editable = shape.texts[ this.data.textId ].editable;
                        this.data.disableRichtext = shape.type === ShapeType.Connector ||
                            !shape?.enableTiptap;
                    }
                    if ( !this.data.textId && shape.primaryTextModel ) {
                        const innerSelection = this.state.get( 'InnerShapeSelection' );
                        if ( innerSelection[shape.id] && innerSelection[shape.id].length > 0 ) {
                            const innerIds = innerSelection[shape.id];
                            let textId = innerIds[ innerIds.length - 1 ];
                            const group = (( shape as any ).mergedCells || [])
                                .find(( grp: string[]) => grp.includes( textId ));

                            if ( group ) {
                                textId = group.find( id => ( shape as any ).texts[ id ].mergedRegion ) || textId;
                            }
                            this.data.textId = textId;
                        } else if ( shape.type === 'edata' ||
                            shape.isBasic() || shape.isImage() || ( shape.isConnector() && !this.data.point )) {
                                this.data.textId = shape.primaryTextModel.id;
                        }
                    }
                    if ( shape.isLocked ) {
                        this.data.open = false;
                        this.data.editable = false;
                    }
                    // When opening editor for the first time, reset TextContent state
                    if ( !this.state.get( 'EditingText' ).open && this.data.open ) {
                        const contentData: any = {
                            shapeId: this.data.shapeId,
                            text: shape.texts[this.data.textId] ? shape.texts[this.data.textId].value : this.data.text,
                            content: shape.texts[this.data.textId] ? shape.texts[this.data.textId].content : [],
                            textId: this.data.textId,
                            selectAll: this.data.selectAll,
                        };
                        this.state.set( 'TextContent', contentData );
                    }
                }),
            );
        } else {
            this.data.open = false;
            return of();
        }
    }


    public execute(): boolean {
        if ( this.data.open && this.data.open === this.state.get( 'EditingText' ).open ) {
            return false;
        }
        if ( !this.data.editable ) {
            return false;
        }
        return true;
    }

    private toggle(): boolean {
        const editingText: IEditingTextState = this.state.get( 'EditingText' );
        return !editingText.open;
    }
}

Object.defineProperty( EditText, 'name', {
    value: 'EditText',
});

/**
 * EditingTextService representation for shape text editing of the selected shape.
 * Holds IEditingTextState
 */
export class EditingTextStateService extends StateService< 'EditingText', IEditingTextState > {}


/**
 * Expected state data for the InterfaceControlState state
 */
export interface IEditingTextState {

    /**
     * The id of the the shape to open the text editor on
     */
    shapeId: string;

    /**
     * The open or close state of the editor
     */
    open?: boolean;

    /**
     * The id of the text to edit, optional
     */
    textId?: string;

    /**
     * The initial content to add to the editor, optional
     */
    text?: string;

    /**
     * If the open status was triggered by a mouse action the
     * point of the mouse action.
     */
    point?: IPoint2D;

    /**
     * Apply or cancel the text
     */
    cancel?: boolean;

    /**
     * Data required for shape logic methods
     */
     shapeLogicData?: any;

    caretPosition?: 'start' | 'end';

    rendering?: 'carota' | 'tiptapCanvas' | 'dom';
}
