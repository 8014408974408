<div class="text-input-item" [class.text-item-inline]="inline" [class.show-clear]="!(isEmpty | async) && showClearButton" [class.has-icon]="icon">
    <label class="body">{{label}}</label>

    <div class="text-input-inner">

        <div *ngIf="coreType==='textarea'">
            <textarea
                [placeholder]="placeholder"
                #inputItem
                (input)="onInputChange(inputText)"
                (keyup)="onKeyup($event)" ></textarea>
        </div>

        <input [type]="type"
            class="text-input caption"
            [class.text-invalid-input]="invalidInput"
            [class.transparent]="transparentTextBox"
            [class.with-pointer-events]="transparentTextBox?.withPointerEvents"
            #inputItem
            *ngIf="coreType!=='textarea' && !multiLined"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [readonly]="readonly"
            [attr.data-testid]="testId"
            [(ngModel)]="value"
            (input)="onInputChange(inputText)"
            (keydown)="onKeydown($event);"
            (keyup)="onKeyup($event)"
            (blur)="onBlur(inputText, $event)">

        <div class="text-input-tooltip" tooltip [class.fx-hidden]="!tooltip.content">
            <div class="tooltip-content">
                <p *ngIf="tooltip.heading">{{tooltip.heading}}</p>
                <p>{{tooltip.content}}</p>
            </div>
        </div>

        <div *ngIf="coreType!=='textarea' && multiLined">
            <div class="text-input-multilined body" #inputItem
            [class.transparent]="transparentTextBox"
            *ngIf="!readonly"
            contenteditable="true"
            (input)="onInputChange(text)"
            (keydown)="onKeydown($event);"
            (keyup)="onKeyup($event)"
            (blur)="onBlur(text,$event)"
            [attr.data-placeholder]="placeholder"
            [attr.data-testid]="testId"
            [class.fx-overflow-y]="allowScroll">{{ value }}</div>

            <div class="text-input-multilined readonly" *ngIf="readonly">{{ value }}</div>
        </div>

        <div *ngIf="charCounter && charCounter !== -1" class="text-input-char-counter" >
            {{charCount | async}}/{{ charCounter }}
        </div>

        <svg *ngIf="icon" class="text-input-icon nu-icon xmedium-grey">
            <use [attr.xlink:href]="iconURL"></use>
        </svg>
        <span (click)="clear()" class="text-input-clear-btn" tooltip placement="bottom" *ngIf="showClearButton">
            <svg class="nu-icon nu-icon-small grey">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
            </svg>
            <div class="tooltip-content">
                {{translate.get( 'TOOLTIPS.CLEAR_TEXTBOX')|async}}
            </div>
        </span>
    </div>
</div>
