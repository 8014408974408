import { ImportedFile } from '../../../framework/file/imported-file';
import { Injector, Injectable } from '@angular/core';
import { Observable, concat, EMPTY } from 'rxjs';
import { map, switchMap, filter, take } from 'rxjs/operators';
import { IFileReceiver } from '../../../framework/file/file-receiver.i';

/**
 * It receives files in the form of {@link ImportedFile} instances.
 * It hands over the files to IFileReceiver services for handling the files.
 * {@link IFileReceiver} are services that can process a specific
 * form of file being imported into the system.
 * This component creates an array of {@link IFileReceiver}, then
 * invoke the 'detect' method of all the IFileReceivers services.
 *
 * @author  Vinoch
 * @since   2019-06-04
 */

@Injectable()
export class FileReceiver {

    protected receivers: string[] = [ 'ImageFileReceiver' ];

    constructor(
        protected injector: Injector,
    ) {}

    /**
     * The files which needs to be imported can be passed
     * in through this method in the form of ImportedFile.
     * @param importedFiles - An array of dropped/pasted files
     * @param data - Optional parameter to pass any spesific data
     * @return - an observable which emits and completes when
     * all files are done processing.
     */
    public receiveFiles( importedFiles: ImportedFile[], data?: any ): Observable<unknown> {
        if ( importedFiles && importedFiles.length > 0 ) {
            return concat( ...importedFiles.map( file => this.receiveFile( file, data )));
        } else {
            return EMPTY;
        }
    }

    /**
     * Validates and triggers the process to import a single file
     * into the application if the file is compatible by a receiver.
     * @param importedFile - a single file
     * @param data - Optional parameter to pass any spesific data
     * @returns - an observable which completes when the file import is done.
     */
    protected receiveFile( importedFile: ImportedFile, data?: any ): Observable<boolean> {
        return this.getReceiverService( importedFile ).pipe(
            filter( instance => instance !== undefined ),
            switchMap( instance => instance.trigger( importedFile, data )),
        );
    }

    /**
     * This function detect the compatible file receiver
     * for the given file.
     * @param importedFile - a single file
     * @returns - the instance of the IFileReceiver which is
     * compatible for the imported file and returns undefined
     * if there are no compatible receivers.
     */
    protected getReceiverService( importedFile: ImportedFile ): Observable<IFileReceiver> {
        const obs = this.receivers.map( receiver => {
            const fileReceiver = this.injector.get( receiver );
            return fileReceiver.detect( importedFile ).pipe(
                map( value => value === true ? fileReceiver : undefined ),
            );
        });

        return concat( ...obs ).pipe(
            filter( value => !!value ),
            take( 1 ),
        ) as Observable<IFileReceiver>;
    }
}
