import { StateService, CommandInterfaces, Command, AppConfig } from 'flux-core';
import { Injectable } from '@angular/core';
import { InterfaceControlState } from '../../base-states';
import { AbstractMessageCommand } from 'flux-connection';
import { Observable } from 'rxjs';

/**
 * This is a command used to send all shared states to Neutrino, so that they can be collaborated
 * with any current active clients. This ensures that the current user's states are
 * transfered across to Neutrino and other clients.
 *
 * This will usually be fired, when a diagram subscription is successfully created during
 * the application initialization sequence.
 */
@Injectable()
@Command()
export class ChangeInterfaceControlState extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            state: true, // interface control state
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
            return [ 'IStateChangeCommand' ];
        }
        return [ 'IStateChangeCommand', 'IMessageCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return this.data;
    }

    public execute (): boolean {
        this.state.set( 'InterfaceControlState', this.data.state );
        const currentState = this.state.get( 'Realtime' );
        const actionMap = {
            [InterfaceControlState.Edit]: 'ACTIVITIES.EDITING',
            [InterfaceControlState.View]: 'ACTIVITIES.VIEWING',
        };
        const currentAction = actionMap[ this.data.state ];
        if ( !currentState || currentState.action === currentAction ) {
            return false;
        }
        this.resourceId = this.state.get( 'CurrentDiagram' );
        this.data = { Realtime: { ...currentState, action: currentAction }};
        return true;
    }

    public revert(): boolean | Observable<any> {
        // nothing to revert here. InterfaceControlState state change should persist anyway.
        return true;
    }
}

Object.defineProperty( ChangeInterfaceControlState, 'name', {
    value: 'ChangeInterfaceControlState',
});
