import { IPoint2D } from 'flux-definition';
import { IConnectorBump } from '../../../../shape/model/connector-data-mdl';
import { ConnectorViewDefinition } from '../connector-view-definition.i';

/**
 * AbstractConnector is the base class for all connectors. Provides many
 * helper methods which should be used when drawing connectors.
 */
export abstract class AbstractConnector extends ConnectorViewDefinition {

    /**
     * Bump radius to use when it is not set.
     */
    public static get DEFAULT_BUMP_RADIUS(): number {
        return 4;
    }
    /**
     * The current position of the path head.
     */
    public head: IPoint2D = { x: 0, y: 0 };

    /**
     * Starts drawing from the given point
     */
    protected moveTo<T extends IPoint2D>( p: T ): T {
        this.graphics.moveTo( p.x, p.y );
        if ( this.hitArea ) {
            this.hitArea.moveTo( p.x, p.y );
        }
        return this.head = p;
    }

    /**
     * Draws a line to given point drawing bumps where needed.
     * Returns the end point coordinates.
     */
    protected lineTo<T extends IPoint2D>( p: T, bumps: IConnectorBump[] = []): T {
        if ( !this.model.showBumps || !bumps || !bumps.length ) {
            this.graphics.lineTo( p.x, p.y );
            if ( this.hitArea ) {
                this.hitArea.lineTo( p.x, p.y );
            }
            return this.head = p;
        }
        // calculate constant values which are common for all bumps
        const headPoint = this.geometry.createPoint( this.head.x, this.head.y );
        const angleLine = headPoint.angleTo( p ) * Math.PI / 180;
        const clockwise = angleLine <= Math.PI / 2 && angleLine >= -Math.PI / 2;
        for ( const bump of bumps ) {
            const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
            const headToBump = this.geometry.createLine( this.head, bump );
            const bumpBeg = headToBump.splitByLength( radius, false );
            const bumpEnd = headToBump.splitByLength( -radius, false );
            this.graphics.lineTo( bumpBeg.x, bumpBeg.y );
            this.graphics.arc( bump.x, bump.y, radius, angleLine - Math.PI, angleLine, !clockwise );
            if ( this.hitArea ) {
                this.hitArea.lineTo( bumpBeg.x, bumpBeg.y );
                this.hitArea.arc( bump.x, bump.y, radius, angleLine - Math.PI, angleLine, !clockwise );
            }
            this.head = bumpEnd;
        }
        // NOTE: bump has been drawn past the point where the line should end
        if ( headPoint.distanceTo( p ) < headPoint.distanceTo( this.head )) {
            return this.head as T;
        }
        this.graphics.lineTo( p.x, p.y );
        if ( this.hitArea ) {
            this.hitArea.lineTo( p.x, p.y );
        }
        return this.head = p;
    }

    /**
     * Draws a quadraticCurveTo to given point drawing bumps where needed.
     * Returns the end point coordinates.
     *
     * TODO: add support for bumps
     */
    protected quadraticCurveTo<T extends IPoint2D>( cp: T, p: T ): T {
        this.graphics.quadraticCurveTo( cp.x, cp.y, p.x, p.y );
        if ( this.hitArea ) {
            this.hitArea.quadraticCurveTo( cp.x, cp.y, p.x, p.y );
        }
        return this.head = p;
    }

    /**
     * Draws an arc to given point drawing bumps where needed.
     * Returns the end point coordinates.
     *
     * TODO: add support for bumps
     */
    protected arcTo<T extends IPoint2D>( p1: T, p2: T, r: number ): T {
        this.graphics.arcTo( p1.x, p1.y, p2.x, p2.y, r );
        if ( this.hitArea ) {
            this.hitArea.arcTo( p1.x, p1.y, p2.x, p2.y, r );
        }
        return this.head = p2;
    }

    /**
     * Draws a quadraticCurveTo to given point drawing bumps where needed.
     * Returns the end point coordinates.
     *
     * TODO: add support for bumps
     */
    protected bezierCurveTo<T extends IPoint2D>( cp1: T, cp2: T, p: T ): T {
        this.graphics.bezierCurveTo( cp1.x, cp1.y, cp2.x, cp2.y, p.x, p.y );
        if ( this.hitArea ) {
            this.hitArea.bezierCurveTo( cp1.x, cp1.y, cp2.x, cp2.y, p.x, p.y );
        }
        return this.head = p;
    }
}
