/**
 * This interface specifices the fields that should
 * reside it an item that is used with the {@link LinkedList}.
 * Each item should have a unique id, as well has ids of
 * its next and previous elements in the chain.
 */
export interface INode {
    id: string;
    nextId: string;
    prevId: string;
}

/**
 * An interface which describes a linked list of points.
 */
export interface ILinkedList<T extends INode> {
    // headId: string;
    // tailId: string;
    // [ key: Exclude<string, 'headId' | 'tailId'> ]: T;
    [ key: string ]: string | T;
}

/**
 * This is a data structure that resembles a doubly linked list.
 * It uses object fields to store data items. Items added to
 * the list must implement {@link INode} interface or one of
 * its extensions.
 *
 * @since   2017-10-6
 * @author  Ramishka
 */
export class LinkedList<T extends INode> {
    /**
     * fromNodes creates a linked list from an array of nodes.
     */
    public static fromNodes<T extends INode>( nodes: T[]): LinkedList<T> {
        if ( !nodes ) {
            return null;
        }
        const list = new LinkedList<T>();
        if ( nodes.length ) {
            for ( let i = 0; i < nodes.length; ++i ) {
                list.add( nodes[i]);
            }
        }
        return list;
    }

    /**
     * Retains the id of the item at the head of the list
     */
    protected headId: string = undefined;

    /**
     * Retains the id of the item at the end of the list
     */
    protected tailId: string = undefined;

    /**
     * Returns the item at the head of the list
     */
    public get head(): T {
        return this[ this.headId ];
    }

    /**
     * Returns the item and the end of the list
     */
    public get tail(): T {
        return this[ this.tailId ];
    }

    /**
     * Returns an array of points in order.
     */
    public get points(): T[] {
        const points = [];
        let head = this.head;
        while ( head ) {
            points.push( head );
            head = this[head.nextId];
        }
        return points;
    }

    /**
     * Returns the size of this list.
     */
    public get size(): number {
        return this.keyList.length;
    }

    /**
     * Returns a list of all keys in this list
     */
    public get keyList(): string[] {
        const keys = [];
        let head = this.head;
        while ( head ) {
            keys.push( head.id );
            head = this[head.nextId];
        }
        return keys;
    }

    /**
     * Fetches an items from the list by its id
     * @param id id of the element to retrieve
     */
    public get( id: string ): T {
        return this[ id ];
    }

    /**
     * Adds an item to the list.
     * Adds the item to the end of the list
     * @param item - Item to add
     */
    public add( item: T ) {
        if ( !this.headId ) {
            this.insertHead( item );
        } else {
            this.insert( item, this.tail );
        }
    }

    /**
     * Addds an item to the list, before the position of another item
     * @param item - Item to add
     * @param beforeId - element id to insert before
     */
    public addBefore( item: T, beforeId: string ) {
        if ( !this.headId || beforeId === this.headId ) {
            this.insertHead( item );
        } else {
            this.insert( item, this.get( this.get( beforeId ).prevId ));
        }
    }

    /**
     * Addds an item to the list, after the position of another item
     * @param item - Item to add
     * @param afterId - element id to insert after
     */
    public addAfter( item: T, afterId: string ) {
        if ( !this.headId ) {
            this.insertHead( item );
        } else {
            let after = this.get( afterId );
            after = !after ? this.tail : after;
            this.insert( item, after );
        }
    }

    /**
     * Removes an item from the list
     * @param id id of the item to be removed
     */
    public remove( id: string ) {
        const item = this.get( id );
        if ( item ) {
            if ( id === this.headId ) {
                if ( this.head.nextId ) {
                    this.headId = this.head.nextId;
                    this.head.prevId = undefined;
                } else {
                    this.headId = undefined;
                    this.tailId = undefined;
                }
            } else {
                const next = this.get( item.nextId );
                const prev = this.get( item.prevId );
                if ( !next ) {
                    this.tailId = prev.id;
                    prev.nextId = undefined;
                } else {
                    prev.nextId = next.id;
                    next.prevId = prev.id;
                }
            }
            delete this[ id ];
        }
    }

    /**
     * Inserts an item to the list, after another item
     * @param item Item to insert
     * @param after Item at position to insert after
     */
    protected insert( item: T, after: T ) {
        const next: T = this[ after.nextId ];
        after.nextId = item.id;
        item.prevId = after.id;
        this[ item.id ] = item;
        if ( next ) {
            item.nextId = next.id;
            next.prevId = item.id;
        } else {
            item.nextId = undefined;
        }

        if ( after.id === this.tailId ) {
            this.tailId = item.id;
        }
    }

    /**
     * Inserts the iteam as the head of the list
     * @param item - item to insert
     */
    protected insertHead( item: T ) {
        if ( this.headId === undefined ) {
            this.headId = item.id;
            this.tailId = item.id;
        } else {
            const movingHead = this.head;
            this.headId = item.id;
            item.nextId = movingHead.id;
            movingHead.prevId = item.id;
            // If the tail was the head, it moves as well. Nothing to do.
        }
        item.prevId = undefined;
        this[ item.id ] = item;
    }

    /**
     * Checks if the item of the given id is a valid node.
     * This is used as a type guard to prevent members of the LinkedList
     * class from being recognized as nodes of the list.
     * @param id - id of the node to check
     */
    protected isNode( id: string ): boolean {
        const node: Object = this[id];
        if ( node ) {
            return ( node.hasOwnProperty( 'id' ) &&
                node.hasOwnProperty( 'nextId' ) &&
                node.hasOwnProperty( 'prevId' ));
        }
    }
}
