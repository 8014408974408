import {
    Observable,
    Subject,
    from,
    defer,
    of,
    throwError,
    ConnectableObservable,
} from 'rxjs';
import { mergeAll, concat, publish } from 'rxjs/operators';
import { CommandScenario } from '../command-scenario.enum';
import { CommandMapper } from '../mapper/command-mapper.svc';
import { Logger } from '../../logger/logger.svc';
import { Injector, Injectable } from '@angular/core';
import { AbstractDiagramCommandEvent, ICommandEventData, CommandEvent } from '../command-event';
import { ICommandList } from '../mapper/command-list';
import { SequenceCommandList, TransformerFunction, AlterFunction } from '../mapper/sequence-command-list';
import { ParallelCommandList } from '../mapper/parallel-command-list';
import { CommandError } from '../error/command-error';
import { ICommandSequenceProgress } from '../command-progress.i';
import { cloneDeep } from 'lodash';
import { AbstractCommand } from '../abstract.cmd';
import { CommandExecutor } from '../execution/command-executor';
import { Random } from '../../data/random';
import { Flags } from '../../flags';
import { EventCollector, EventIdentifier } from '../../logger/event-collector.svc';

/**
 * This is an abstraction of all command scenarios.
 *
 * A command can be run under multiple scenarios.
 * Example -
 *  - During a normal execute
 *  - When performing an undo or a redo action
 *  - When performing a collab action, etc
 * In each such scenario, the command may execute in different ways.
 * Different sets of {@link ExecutionSteps} can be configured to be run under
 * different scenarios, using the {@link CommandStepMapper}.
 *
 * For a list of available command scenarios, please refer to
 * {@link CommandScenario}.
 *
 * This class captures behaviors that are common across all scenarios.
 * Specific requirements for different scenarios are implemented on
 * extensions of this class.
 *
 * @author  Ramishka
 * @since   2018-08-13
 */
@Injectable()
export abstract class AbstractCommandScenario {

    protected traceEventId = true;

    constructor(
        protected commandMapper: CommandMapper,
        protected log: Logger,
        protected injector: Injector,
    ) {}

    /**
     * Returns the name of the command scenario.
     * Any extending classes must implement this getter as
     * it proves to be the identification of the current scenario.
     */
    protected abstract get scenario(): CommandScenario;

    /**
     * Executes a command event.
     *
     * A valid {@link CommandEvent} which is already mapped to one
     * or more commands will trigger the mapped commands in the desired
     * manner {@see CommandMapper}. Commands can be mapped in parallel or
     * sequential order.
     * In certain execution scenarios, the commands related to the command
     * event may be dynamically generated, rather than mapped using the
     * command mapper.
     *
     * @param event - the command event
     * @param data - data passed into the command
     * @param resourceId - id of the resource the command is being run on
     * @return an observable that emits the progress of the command along
     * with data and resultData. When all execution steps for the scenario
     * completes, the observable will be completed.
     */
    public execute (
        event: AbstractDiagramCommandEvent,
        data?: any,
        resourceId?: string,
    ): Observable<any> {
        // below condition check is only useful when dispatching a command event via debugger.
        if ( !( event instanceof CommandEvent )) {
            event = this.commandMapper.getCommandEventByName(( event as any ).toString(), ( event as any ).getSource());
        }
        const commands: ICommandList = this.commandMapper.getCommandList( event );
        const eventData = this.getEventData( event );
        if ( commands ) {
            return this.processCommandList( commands, eventData, event.transformData( data ), resourceId );
        }
        const error = new CommandError( 'No mapping found for the given CommandEvent ' + event.toString());
        return throwError( error ) ;
    }

    /**
     * Allows executing a command without mapping or dispatching a command event.
     *
     * Although a command executed using this method does not need to be mapped to
     * a command event, they still need to be registered with the CommandMapper.
     * example -
     * <code> this.mapper.registerCommand( CommandClass as any )</code>
     * @param commandName - name of command ( string )
     * @param resourceId - resource id
     * @param data - data passed into the command
     * @param resultData - result data
     * @return an observable that emits the progress of the command along
     * with data and resultData. When all execution steps for the scenario
     * completes, the observable will be completed.
     */
    public executeUnmapped( commandName: string,
                            resourceId?: string,
                            data?: any,
                            resultData?: any ): Observable<any> {
        const command = this.commandMapper.getCommandByName( commandName );
        // Create event data for the unmapped command
        const eventData = {
            eventId: Random.eventId( this.traceEventId ),
            eventName: 'Unmapped.' + commandName,
        };
        const executor = CommandExecutor.create( command, eventData, this.scenario, this.log, this.injector );
        EventCollector.log({
            message: EventIdentifier.COMMAND_UNMAPPED_QUEUED,
            event: eventData,
            data: data,
            commandName,
            resourceId,
            resultData,
        });
        return executor.executeCommand( resourceId, data, resultData );
    }

    /**
     * Extracts event information out of a given {@link CommandEvent} and
     * creates an ICommandEventData instance.
     * @param event - command event
     * @return ICommandEventData
     */
    protected getEventData( event: CommandEvent ): ICommandEventData {
        return {
            eventId: Random.eventId(),
            eventName: event.toString(),
            scenario: this.scenario,
            source: event.getSource(),
        };
    }


    /**
     * Starts processing a command list .
     * Command list is executed based on its type.
     * @param commandList - the list of commands to be exeucted
     * @param eventData - data about the {@link CommandEvent}
     * @param data - data for the commands
     * @param resourceId - resource id
     * @return observable that emits progress of the commands as they are being executed.
     */
    protected processCommandList(
        commandList: ICommandList,
        eventData: ICommandEventData,
        data: any,
        resourceId: string,
    ): Observable<any> {
        if ( commandList instanceof SequenceCommandList ) {
            return this.processSequence( commandList, eventData, data, resourceId );
        } else if ( commandList instanceof ParallelCommandList ) {
            return this.processParallel( commandList, eventData, data, resourceId );
        }
        const error = new CommandError( 'An invalid command list was passed into processCommandList' );
        return throwError( error ) ;
    }

    /**
     * This function is to manage a group of commands where the execution of the next
     * command doesn't happen until the alter hook defined in the sequence returns true or preceding command
     * completes it's execution.
     *
     * Each command in the sequence returns an observable with the progress of the command. The chain starts with the
     * initial data wrapped in an observable.
     *
     * @param   commands    A list of commands that need to execute one after another.
     * @param   commandEventData - data about the {@link CommandEvent}
     * @param   data - data passed into the command
     * @param   resourceId - resource id
     * @return  The result is a {@link Observable} which emits the commandProgress of the last command
     *          executed
     */
    protected processSequence( commands: SequenceCommandList,
                               commandEventData: ICommandEventData,
                               data?: any,
                               resourceId?: string ): Observable<any> {
        const commandSequenceProgress: ICommandSequenceProgress = {
            commandName: '',
            stepName: '',
            stepStatus: null,
            stepIsAsync: null,
            eventData: cloneDeep( data ),
            eventInfo: commandEventData,
            data: [ data ],
            resultData: [],
            status: false,
        };
        if ( commands.length === 0 ) {
            return of( commandSequenceProgress );
        }
        let sequenceProgress =  of( commandSequenceProgress );
        const attachNextCommand = ( index, command, hooks ) => {
            sequenceProgress = sequenceProgress.pipe(
                concat( defer(() => this.executeSequenceCommand(
                    command, commandEventData, index, resourceId, hooks, commandSequenceProgress,
                ))),
            );
        };
        for ( let i = 0; i < commands.length; i++ ) {
            const command = commands[i];
            const hooks = commands.hooks[i];
            attachNextCommand( i, command, hooks );
        }
        const sequenceConnectableObservable = sequenceProgress.pipe( publish()) as ConnectableObservable<any>;
        sequenceConnectableObservable.connect();
        return sequenceConnectableObservable;
    }

    /**
     * This method executes a single command in the sequence.
     * This prepares data for the command based on the transform function given
     * and update the current command progress whenever the executor emits the progress.
     * Also this decides when to run next command sequence based on the command completion or value
     * returns from alter hook.
     * @param command - command that is being executed
     * @param commandEventData - data about the {@link CommandEvent}
     * @param sequence - position of the command in current sequence
     * @param resourceId - resource i
     * @param hooks - any hooks that need to be run
     * @param commandSequenceProgress - progress of the current command sequence
     */
    protected executeSequenceCommand( command: typeof AbstractCommand,
                                      eventData: ICommandEventData,
                                      sequence: number,
                                      resourceId: string,
                                      hooks: any,
                                      commandSequenceProgress: ICommandSequenceProgress ): Observable<any> {
        let transform;
        let alter;
        const seqCommandProgress: Subject<any> = new Subject();

        if ( hooks ) {
            transform = hooks.transform;
            alter = hooks.alter;
        }
        const currentData = this.runTransformHook( transform, commandSequenceProgress, sequence );
        commandSequenceProgress.commandName = command.name;
        commandSequenceProgress.data[ sequence ] = cloneDeep( currentData );
        this.executeCommand( command, eventData, currentData, resourceId ).subscribe({
            next: commandProgress => {
                commandSequenceProgress.stepName = commandProgress.stepName;
                commandSequenceProgress.stepStatus = commandProgress.stepStatus;
                commandSequenceProgress.stepIsAsync = commandProgress.stepIsAsync;
                commandSequenceProgress.status = commandProgress.status;
                commandSequenceProgress.resultData[ sequence ] = commandProgress.resultData;
                if ( Flags.get( 'DEBUG_COMMAND_PROGRESS' )) {
                    this.log.debug(
                        `Progress: ${commandProgress.stepStatus} ${command.name}.${commandProgress.stepName}`,
                    );
                }
                seqCommandProgress.next( commandSequenceProgress );
                this.runAlterHook( alter, commandSequenceProgress, seqCommandProgress );
            },
            error: err => {
                if ( Flags.get( 'DEBUG_COMMAND_PROGRESS' )) {
                    this.log.debug(
                        `Progress: errored ${command.name}.` +
                        `${commandSequenceProgress.stepName}`,
                        commandSequenceProgress,
                        err.stack,
                    );
                }
                seqCommandProgress.error( err );
            },
            complete: () => {
                seqCommandProgress.complete();
            },
        });
        return seqCommandProgress;
    }

    /**
     * This handles the transform function defined in the command sequence and return data
     * for the current sequence
     */
    protected runTransformHook( transform: TransformerFunction,
                                commandSequenceProgress: ICommandSequenceProgress,
                                currentSequence: number ): any {
        let currentData;
        const isFirstCommand = currentSequence === 0;
        if ( isFirstCommand ) {
            currentData =
                transform ? transform( commandSequenceProgress ) : commandSequenceProgress.data[currentSequence];
        } else {
            currentData =
                transform ? transform( commandSequenceProgress ) :
                    commandSequenceProgress.resultData[currentSequence - 1];
        }
        return currentData;
    }

    /**
     * This handles the alter function defined in the sequence.
     * If alter fn returns this emits a progress to run the next
     * command
     */
    protected runAlterHook( alter: AlterFunction,
                            commandSequnceProgress: ICommandSequenceProgress,
                            progressSubject: Subject<any> ) {

        if ( alter && alter( commandSequnceProgress )) {
            progressSubject.complete();
        }
    }

    /**
     * This function is to manage a group of commands that execute parallel.
     * This collects {@link Observable} by executing the command sequence and
     * returns an observable sequence with an array.
     * This will be called by <code>dispatch<code> which gets a list of commands
     * as {@link ParallelCommandList}.
     *
     * @param   commands    A list of commands that need to execute one after another.
     * @param   eventData - data about the {@link CommandEvent}
     * @param   data - data passed into the command
     * @param   resourceId - resource id
     * @return  The result is a {@link Observable} sequence with an array which gives the
     *          collection of ICommandProgress/error.
     */
    protected processParallel( commands: ParallelCommandList,
                               eventData: ICommandEventData,
                               data?: any,
                               resourceId?: string ): Observable<any> {
        const observableBatch = commands.map( command  => this.executeCommand( command, eventData, data, resourceId ));
        return from( observableBatch ).pipe( mergeAll());
    }

    /**
     * This method is used to get a {@link Observable} result of a single command execution.
     * Every time it creates an instance of {@link CommandExecutor} and calls the
     * <code>executeCommand</code> function to excutes the command.
     *
     * @param   command     Command class reference which the command needs to be executed.
     * @param   eventData - data about the {@link CommandEvent}
     * @param   data - data passed into the command
     * @param   resourceId - resource id
     * @return  The result is a {@link Observable} which gives the single command execution progress/error.
     */
    protected executeCommand( command: typeof AbstractCommand,
                              eventData: ICommandEventData,
                              data?: any,
                              resourceId?: string ): Observable<any> {
        const executor = CommandExecutor.create( command, eventData, this.scenario, this.log, this.injector );
        return executor.executeCommand( resourceId, data );
    }
}
