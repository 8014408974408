import { Injectable } from '@angular/core';
import { CommandInterfaces, Command, CommandError, StateService } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { of, Observable } from 'rxjs';

/**
 * This is the command to get a list of eDataModels that belongs to
 * a particular project. Responds with a list of {@link EDataModel}
 * objects with the necessary data.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetProjectEData extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {}; // The projectId should be the resource id
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected stateService: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ): Observable<any> {
        let projectEData = this.stateService.get( 'ProjectEData' );
        if ( !projectEData ) {
            projectEData = {};
        }
        projectEData[ this.resourceId ] = response.projectEData ;
        this.stateService.set( 'ProjectEData', projectEData );
        return of( true );
    }

    /**
     * If this command encounters an error, throw the error so invoker may
     * handle it as needed.
     * FIXME: This is a hack until the proton errors are handled
     * by the initialization sequence.
     */
    public onError( error: CommandError ) {
        throw ( error );
    }

}

Object.defineProperty( GetProjectEData, 'name', {
    value: 'GetProjectEData',
});
