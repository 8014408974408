import { BehaviorSubject } from 'rxjs';
import { LibraryTile } from '../library/library-tile.cmp';
import { Component, ChangeDetectionStrategy, AfterViewInit, Input } from '@angular/core';

/**
 * ShapeSearchItem component will display the search result of a shape.
 * This component has thumbnail image and thumbnail canvas where it will be shown upon
 * thumbnailType.
 * Initially a svg will be shown at loading time ( while user is typing ).
 * After a delay which is defined in ngAfterViewInit, actual shape canvas or image will be shown.
 *
 *  NOTE: This is component is duplicated, recommend not use this component
 *  for other purposes should be only used for plus create
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'plus-create-item',
    templateUrl: './plus-create-item.html',
    styleUrls: [ './plus-create-item.scss' ],
})
export class PlusCreateItem extends LibraryTile implements AfterViewInit {

    /**
     * The width of the canvas
     */
    @Input() public canvasWidth: number = 80;

    @Input() public canvasAreaWidth: number = 80;

    /**
     * This subject is used to notify that the relevant time out is over.
     * Used in template.
     */
    public loadThumb: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Getter for loading svg icon for shapes.
     */
    public get iconHref(): string {
        return './assets/icons/symbol-defs.svg#nu-ic-shapes';
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.loadThumb.next( true );
        }, 500 );
    }

    public changeStyle( style: any ) {
        return {
            ...style,
            lineColor: '#BAC4CB',
        };
    }

}
