import { Tracker } from 'flux-core';
import { IDataItemUIControl } from './data-items-uic.i';
import { Subject, BehaviorSubject } from 'rxjs';
import { Component, ChangeDetectionStrategy, ElementRef, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ITagsDataValueType } from 'flux-definition';

/**
 * This is an input component which can be used to collect tags
 * ( An array of unique strings )
 *
 */
@Component({
    selector: 'tags-input-uic',
    template: `
        <mat-form-field>
            <mat-chip-list #chipList>
                <mat-chip
                    *ngFor="let tag of (tagsSubject | async)"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(tag)">
                    {{tag.name}}
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-chip>
                <input
                    placeholder="New tag..."
                    #tagInput
                    [formControl]="tagCtrl"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (blur)="onBlur()"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
    `,
    styleUrls: [ './tags-input-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TagsInputUIC implements IDataItemUIControl<ITagsDataValueType[]> {

    AVAILABLE_COLORS = [
        '#EF6661',
        '#FAB638',
        '#6D88F0',
        '#B0B4B5',
        '#9678E3',
        '#51C0AA',
    ];

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
    @Input()
    public context?: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    /**
     * This behavior subject is used to hold the tags array as the user
     * keeps adding tags.
     */
    public tagsSubject: BehaviorSubject<ITagsDataValueType[]>;

    /**
     * This subject will emit the current tags when the tags input is focused out
     * or a particular tag is deleted
     */
    public change: Subject<ITagsDataValueType[]>;

    /**
     * Make the tags selectable
     */
    @Input()
    public selectable = false;

    /**
     * Make the tags removable
     */
    @Input()
    public removable = true;

    /**
     * Bind keys to add a new tag
     */
    @Input()
    public separatorKeysCodes: number[] = [ ENTER, COMMA, TAB ];


    public tagCtrl = new FormControl();

    @ViewChild( 'tagInput',  { static: false })
    public tagInput: ElementRef<HTMLInputElement>;


    constructor() {
        this.tagsSubject = new BehaviorSubject([]);
        this.change = new Subject();
    }

    /**
     * To Push a new tag to the tags array ( tagsSubject )
     */
    public add( event: MatChipInputEvent ): void {
        const input = event.input;
        const value = event.value;
        const tags = this.tagsSubject.value || [];
        // Add our tag
        if (( value || '' ).trim()) {
            const tag = value.trim();
            if ( !tags.find( t => t.name === tag )) {
                this.tagsSubject.next( tags.concat({ name: tag, color: this.getColor( tag ) }));
            }
            // TODO: flicker the duplicate item
        }
        // Reset the input value
        if ( input ) {
            input.value = '';
        }
        this.tagCtrl.setValue( null );
    }

    /**
     * Handle the blur event of the tags input,
     * Blur event is used to emit the "change" subject
     */
    public onBlur() {
        const tags = this.tagsSubject.value;
        this.change.next([ ...tags ]);
        /* istanbul ignore next */
        if ( this.context ) {
            Tracker.track( `${this.context}.tags.change` );
        }
    }

    /**
     * Remove the specified tag and emits the "change" subject
     */
    public remove( tag: ITagsDataValueType ): void {
        const tags = this.tagsSubject.value.slice();
        const index = tags.indexOf( tag );
        if ( index >= 0 ) {
            tags.splice( index, 1 );
        }
        this.tagsSubject.next( tags );
        this.change.next( tags );
        /* istanbul ignore next */
        if ( this.context ) {
            Tracker.track( `${this.context}.tags.change` );
        }
    }

    /**
     * Set data to the tags UI component
     */
    public setData( data: any ) {
        if ( data && typeof Array.isArray( data.value )) {
            this.tagsSubject.next( data.value );
        }
    }

    public getColor( name: string ): string {
        let n = 1;
        for ( let i = 0; i < name.length; ++i ) {
            n += name.charCodeAt( i );
        }
        n = n % this.AVAILABLE_COLORS.length;
        return this.AVAILABLE_COLORS[n];
    }

}
