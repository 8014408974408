import { Subject } from 'rxjs';
import { Component, Input, ChangeDetectionStrategy, Output,
    ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ILibraryItem } from './panels/library-panel.cmp';
import { fromEvent, of, merge as rxMerge, BehaviorSubject, Subscription } from 'rxjs';
import { mapTo } from 'rxjs/operators';

/**
 * LibraryTile
 * Compoennt which contains a thumbnail image and title
 *
 * @author gobiga
 * @since 2017-11-16
 */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'library-tile',
    template: `
        <div #libTileElem class="lib-tile-container fx-ellipsis" draggable="true" [attr.data-hook]="item?.id" (click)="clicked.next(item)">
            <!-- FIXME: Do the close button properly, will be done in a separete PR -->
            <div class="lib-tile-remove" *ngIf="item?.closeButton" (click)="closed.next(item?.id)">
                <svg class="nu-icon">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close-thin"></use>
                </svg>
            </div>
            <thumbnail-image
                *ngIf="item?.thumbnailType==='image'"
                class="shape-thumbnail-image"
                [url]="item?.thumbnailUrl">
            </thumbnail-image>
            <thumbnail-text
                *ngIf="item?.thumbnailType==='text'"
                class="shape-thumbnail-text">
            </thumbnail-text>
            <thumbnail-canvas
                *ngIf="item?.thumbnailType==='canvas'"
                class="shape-thumbnail-image"
                [defId]="item?.data.defId"
                [drawCode]="item?.data.drawCode"
                [version]="item?.data.version"
                [style]="item?.data.style"
                [typeStyle]="item?.data.typeStyle"
                [focus]="focused | async"
                [canvasIdPrefix]="item?.canvasIdPrefix"
                >
            </thumbnail-canvas>
        <div>`,
    styleUrls: [ './library-tile.scss' ],
})
export class LibraryTile implements AfterViewInit, OnDestroy {

    /**
     * Subject to notify outside when the close button is clicked
     */
    @Output()
    public closed: Subject<any>;

    /**
     * Subject to notify outside when the Library tile is clicked
     */
    @Output()
    public clicked: Subject<any>;

    /**
     * Library element
     */
    @ViewChild( 'libTileElem' )
    public libTileElem: ElementRef;

    /**
     * ILibraryItem as input for this component
     * Item will contain all the data related to a library
     */
    @Input()
    public item: ILibraryItem;

    /**
     * This will handled the library tile being focused or not
     */
    @Input()
    public focused: BehaviorSubject<boolean> = new BehaviorSubject( false );

    protected subs: Subscription[];

    constructor() {
        this.subs = [];
        this.closed = new Subject();
        this.clicked = new Subject();
    }

    /**
     * This will handle the library tile being focused or not by setting the focused values
     */
    public ngAfterViewInit() {
        this.subs.push(
            rxMerge(
                of( false ),
                fromEvent( this.libTileElem.nativeElement, 'mouseenter' ).pipe( mapTo( true )),
                fromEvent( this.libTileElem.nativeElement, 'mouseleave' ).pipe( mapTo( false )),
            ).subscribe( focus => this.focused.next( focus )),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }
}
