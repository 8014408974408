import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Random, Command, StateService, CommandService, NotifierController, NotificationType,
    AbstractNotification, EventSource,
} from 'flux-core';
import { IConnectorEndPoint, DEFAULT_CONNECTOR } from 'flux-diagram-composer';
import { INewConnectorConnection } from '../../../base/shape/connection/connection.i';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { MouseControlState } from './../../../base/base-states';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from './../../../base/diagram/diagram-change.svc';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';
import { DiagramModel } from './../../../base/diagram/model/diagram.mdl';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { DiagramCommandEvent } from './diagram-command-event';
import { NotificationMessages, Notifications } from '../../../base/notifications/notification-messages';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { KeyCode } from 'flux-definition';


@Injectable()
@Command()
export class AutoDrawConnector extends AbstractDiagramChangeCommand {

    /**
     * When L pressed on single selected shape - shape will be stored here until any other key pressed
     * @private
     */
    private static shapeA: ShapeModel;
    /**
     * Command input data format
     */
    public data: {
        mouseState: MouseControlState,
        id: string,
    };

    protected state: StateService<any, any>;
    protected diagramModel: DiagramModel;
    protected notificationMessages: NotificationMessages;

    constructor(
        state: StateService<any, any>,
        ds: DiagramChangeService,
        protected random: Random,
        protected converter: ViewportToDiagramCoordinate,
        protected commandService: CommandService,
        protected notifierController: NotifierController,
        protected translate: TranslateService ) {
        super( ds )/* istanbul ignore next */;
        this.state = state;
        this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        if ( this.data.mouseState !== MouseControlState.Line && this.data.mouseState !== MouseControlState.Connector ) {
            return;
        }
        const selected = this.state.get( 'Selected' );

        if ( selected.length === 1 && !AutoDrawConnector.shapeA ) {
            this.storeSingleShape( selected[0]);
        } else if ( selected.length === 1 ) {
            selected.unshift( AutoDrawConnector.shapeA );
            AutoDrawConnector.shapeA = null;
        }

        if ( selected.length < 2 ) {
            return;
        }

        for ( let i = 0; i < selected.length - 1; i++ ) {

            const shapeA = this.changeModel.shapes[selected[i]] as ShapeModel;
            const shapeB = this.changeModel.shapes[selected[i + 1]] as ShapeModel;

            this.createConnection( shapeA, shapeB );
        }

        this.state.set( 'MouseControlState', MouseControlState.Normal );
        // this.handleUpdatePaths( selected );
    }

    // Create a connection between two shapes
    protected createConnection( shapeA: ShapeModel, shapeB: ShapeModel ) {
        if ( shapeA.isConnector() || shapeB.isConnector()) {
            const options = {
                inputs: {
                    heading: this.translate.instant( 'AUTOCONNECTOR.CONNECTORS.HEADING' ),
                    description: this.translate.instant( 'AUTOCONNECTOR.CONNECTORS.DESCRIPTION' ),
                    autoDismiss: true,
                    dismissAfter: 3000,
                },
            };
            const notificationData = this.notificationMessages.getNotificationMessage( Notifications.OFFLINE );
            this.notifierController.show( Notifications.OFFLINE, notificationData.component,
                notificationData.type, options, notificationData.collapsed );
            return;
        }

        if ( shapeA.isContainer || shapeB.isContainer ) {
            const options = {
                inputs: {
                    heading: this.translate.instant( 'AUTOCONNECTOR.CONTAINERS.HEADING' ),
                    description: this.translate.instant( 'AUTOCONNECTOR.CONTAINERS.DESCRIPTION' ),
                    autoDismiss: true,
                    dismissAfter: 3000,
                },
            };
            const notificationData = this.notificationMessages.getNotificationMessage( Notifications.OFFLINE );
            this.notifierController.show( Notifications.OFFLINE, notificationData.component,
                notificationData.type, options, notificationData.collapsed );
            return;
        }

        const gluepointsA = Object.values( shapeA.gluepoints );
        const gluepointsB = Object.values( shapeB.gluepoints );

        // get gluepoints for shapeA
        const pointsA = gluepointsA.map( i => ({
            ...shapeA.getGluepointPosition( i.id ),
            shapeId: shapeA.id,
            gluepointId: i.id,
            gluepointLocked: true,
        }));

        // get gluepoints for shapeB
        const pointsB = gluepointsB.map( i => ({
            ...shapeB.getGluepointPosition( i.id ),
            shapeId: shapeB.id,
            gluepointId: i.id,
            gluepointLocked: true,
        }));

        // Compare shape coordinates to decide which gluepoints to use initially.
        // Gluepoints will be updated when shapes are moved on the canvas.
        const points = this.choosePoints( pointsA, pointsB );

        const toCopy = this.getToCopy( shapeA ) || {} as ConnectorModel;
        const connector = this.prepareConnector( this.random.shapeId(), points, toCopy );
        const connection = this.prepareConnection( points );
        if ( connection ) {
            const definition = connection.definitions[0];
            connector.defId = definition.defId;
            connector.version = definition.version;
            connector.connectionId = this.random.connectionId();
            this.changeModel.connections[connector.connectionId] = connection.connection;
        }

        this.changeModel.shapes[connector.id] = connector;
        this.shakeMe( shapeA );
    }

    protected shakeMe( shape ) {
        const $set = {};
        // FIXME: find some way to draw connet
        $set[`shapes.${shape.id}.x`] = shape.x + 1;
        const moveUpdateEvent = new DiagramCommandEvent( 'ApplyModifierDocument',
            EventSource.SYSTEM );
        this.commandService.dispatch( moveUpdateEvent, { modifier: { $set }});
        this.commandService.dispatch( moveUpdateEvent, { modifier: { $set: {
                    [`shapes.${shape.id}.x`]: shape.x,
                }}});
    }

    protected getKeyEvent( name: string ) {
        return fromEvent( window, name )
            .pipe( filter(( event: KeyboardEvent ) => event.keyCode !== KeyCode.L ));
    }

    // trigger connector-reconnect-binder.ts so it updates connector paths
    /*protected handleUpdatePaths( selected ) {
        const $set = {};
        for ( const shapeid of selected ) {
            const shape = this.changeModel.shapes[ shapeid ] as ShapeModel;
            // this modifier doesn't really do anything, but it
            // technically updates the diagram forcing it to redraw connectors
            $set[`shapes.${shape.id}.x`] = shape.x;
        }
        const modified: IModifier = { $set };
        this.commandService.dispatch( DiagramCommandEvent.applyModifierDocument, { modifier: modified }).subscribe();
    }*/

    /**
     * Prepares connector model data
     */
    private prepareConnector( id: string, points: any[], toCopy: ConnectorModel ): any {
        return {
            ...toCopy,
            id: id,
            texts: {},
            type: 'connector',
            entryClass: 'connectors.bundle.js#ConnectorSmoothAngled',
            defId: DEFAULT_CONNECTOR.defId,
            version: DEFAULT_CONNECTOR.version,
            path: ConnectorModel.createPathFromPoints( points ),
            zIndex: this.changeModel.maxZIndex + 1,
        };
    }

    /**
     * Compares coordinates of shapes to decide which sides of the shapes to connect to.
     * When any of the connected shapes is moved, the connector adjusts to sides.
     */
    private choosePoints( pointsA, pointsB ) {
        let head: any;
        let tail: any;
        let distance: number;
        pointsA.forEach( A => {
            pointsB.forEach( B => {
                const curDistance = Math.sqrt(( A.x - B.x ) ** 2 + ( A.y - B.y ) ** 2 );
                if ( distance === undefined || distance > curDistance ) {
                    distance = curDistance;
                    head = A;
                    tail = B;
                }
            });
        });
        return [ head, tail ];
    }

    /**
     * returns last connector added connected to shapeA.
     * Style and line type for new connector will be copied from this connector
     * @param shapeA first shape in sequence
     */
    private getToCopy( shapeA: ShapeModel ): any {
        const connectors = shapeA.connectorIds;
        if ( connectors ) {
            return this.changeModel.shapes[ connectors[connectors.length - 1]] as ConnectorModel;
        }
    }

    /**
     * Returns information to create a new connector connection for a connector.
     */
    private prepareConnection( points: any[]): INewConnectorConnection {
        const shapeAId = ( points[0] as IConnectorEndPoint ).shapeId;
        const shapeBId = ( points[points.length - 1] as IConnectorEndPoint ).shapeId;
        if ( !shapeAId || !shapeBId || shapeAId === shapeBId ) {
            return null;
        }
        const connections = this.changeModel.getPotentialConnections( shapeAId, shapeBId )
            .filter( conn => conn.connection.type === 'connector' );
        return connections[0] || null;
    }

    /**
     * «Press L on a single shape and select another shape and press L again to create a connector.
     * If you pressed any other key other than L or arrow key after the first L, the original L gets discarded.
     * After press the first L show a message indicator asking to select the next shape to connect and press L again.»
     * @param shape - selected shape
     * @private
     */
    private storeSingleShape( shape: ShapeModel ) {
        AutoDrawConnector.shapeA = shape;
        const keySub = this.getKeyEvent( 'keydown' ).pipe( tap(() => {
            AutoDrawConnector.shapeA = null;
            keySub.unsubscribe();
        })).subscribe();
        this.state.set( 'MouseControlState', MouseControlState.Normal );
        const options = {
            inputs: {
                heading: this.translate.instant( 'AUTOCONNECTOR.SELECT_OTHER_SHAPE.HEADING' ),
                description: this.translate.instant( 'AUTOCONNECTOR.SELECT_OTHER_SHAPE.DESCRIPTION' ),
                autoDismiss: true,
                dismissAfter: 5000,
            },
        };
        this.notifierController.show(
            'AUTOCONNECTOR_SELECT_OTHER_SHAPE', AbstractNotification, NotificationType.Neutral, options );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AutoDrawConnector, 'name', {
    value: 'AutoDrawConnector',
});
