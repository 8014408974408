import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';

/**
 * ImageCanvasInstructionFactory
 * Generate canvas instruction for svg image element instructions
 */
export class ImageCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'image';

    /**
     * Return image base64 encoded data for given image shape node
     * @param data
     */
    public createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        return [{
            instruction: 'image',
            params: [ data.data.src ],
        }];
    }
}
