import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { last } from 'lodash';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * AdjustConnector
 * Adjust the connector just after addition if it's not connected to any shape.
 */
@Injectable()
@Command()
export class AdjustConnector extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The entryClass is the full class identifier as used in shape definitions.
     */
    public data: {
        connectorId: string,
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        const connector = this.changeModel.shapes[this.data.connectorId] as ConnectorModel;
        const lastPoint = last( connector.getPoints());
        if ( connector.length < 10 && !lastPoint.gluepointId ) {
            lastPoint.x += 55;
            lastPoint.y -= 35;
            lastPoint.direction = -36;
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AdjustConnector, 'name', {
    value: 'AdjustConnector',
});
