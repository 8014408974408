import { DataStore } from 'flux-store';
import { Command, CommandError, CommandInterfaces, StateService } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { from } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { UserModel } from '../model/user.mdl';

/**
 * This command updates the current user's permissions with the given data.
 * For this command to work, the 'CurrentUser' state must be set.
 *
 * @author  Jerome
 * @since   07-11-2020
 */
@Injectable()
@Command()
export class UpdateUserPermission extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            model: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IUserCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        @Inject( StateService ) protected state: StateService< any, any > ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( UserModel );
        return modelStore.findOne({ id: this.state.get( 'CurrentUser' ) }).pipe(
            take( 1 ),
            tap( user => {
                this.previousData = user.plan.permissions;
            }),
        );
    }

    public execute() {
        const userData: UserModel = this.data.model;
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set[ 'plan.permissions' ] = userData.plan.permissions;
        return from( modelStore.update(
            { id: this.state.get( 'CurrentUser' ) },
            setter,
        ));
    }

    public onError( err: CommandError ): any {
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set[ 'plan.permissions' ] = this.previousData;
        return from( modelStore.update(
            { id: this.state.get( 'CurrentUser' ) },
            setter,
        ));
    }
}

Object.defineProperty( UpdateUserPermission, 'name', {
    value: 'UpdateUserPermission',
});
