import { INodeView } from './node-view.i';
import { LinkService } from './../../../../../base/diagram/link.svc';
import { ShapeLinkModel } from 'flux-diagram';
import { TranslateService } from '@ngx-translate/core';
import { IAttachment } from 'flux-definition';
import { fromEvent } from 'rxjs';
import { Editor, mergeAttributes } from '@tiptap/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import * as fileIcons from 'file-icons-js';
import { Clipboard } from '@creately/clipboard';
import saveAs from 'file-saver';


/**
 * FileEmbedTiptapNodeView will be rendered in the text editor when a file
 * is uploaded
 */
export class FileEmbedTiptapNodeView implements INodeView {

    /**
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create( injector: Injector, viewCR: ViewContainerRef, diagramId: string ) {
        const cfr = injector.get( ComponentFactoryResolver );
        const commandService = injector.get( CommandService );
        const stateSvc = injector.get( StateService );
        const ll = injector.get( DiagramLocatorLocator );
        const linkSvc = injector.get( LinkService );
        const clipboard = injector.get( Clipboard );
        const translate = injector.get( TranslateService );
        const instance = new FileEmbedTiptapNodeView(
            cfr, injector, commandService, stateSvc, ll,
            viewCR, translate, linkSvc, clipboard, diagramId );
        return instance;
    }

    public name = 'fileEmbedNode';
    public group = 'block';
    public content = 'inline*';
    public atom = true;


    public subs = [];

    protected constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected command: CommandService,
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected dir: ViewContainerRef,
        protected translate: TranslateService,
        protected linkSvc: LinkService,
        protected clipboard: Clipboard,
        protected diagramId: string,
    ) {
    }

    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public addAttributes = () => ({
        attachment: {
            default: {},
            parseHTML: element => JSON.parse( element.getAttribute( 'data-attachment' )),
            renderHTML: attributes => {
                const data = JSON.stringify( attributes.attachment );
                return {
                    'data-attachment': data,
                };
            },
        },
        link: {
            default: '',
        },
    })

    public parseHTML = () => [
        {
            tag: `span[data-type="${this.name}"]`,
        },
    ]

    public renderHTML = ({ HTMLAttributes }) => [
        'span',
         mergeAttributes({ 'data-type': this.name }, HTMLAttributes ),
    ] as any

    public addNodeView = () =>
        ({
        editor,
        node,
        getPos,
        HTMLAttributes,
        decorations,
        extension,
        }) => {

            const dom = document.createElement( 'span' );
            dom.classList.add( 'file-embed-node' );

            this.buildElement( dom, node.attrs.attachment, editor, this.subs );

            return {
                dom,
                destroy: () => {
                    while ( this.subs.length > 0 ) {
                        this.subs.pop().unsubscribe();
                    }
                },
                stopEvent: e => {
                if ( e.key && e.type === 'keydown' && ( e.key === 'ArrowUp' || e.key === 'ArrowDown' )) {
                    editor.commands.focus();
                }
                return true;
                },
                selectNode: () => {
                },
            };

    }

    protected buildElement( dom: HTMLElement, attachment: IAttachment, editor, subs ) {
        const { icon, label, options } = this.getFileBlotData( attachment );

        const iconLable = document.createElement( 'span' );
        iconLable.className = 'file-blot body';
        if ( icon.type === 'svg' ) {
            iconLable.innerHTML =
            `<svg class="file-icon"><svg id="nu-ic-styles" viewBox="0 0 32 32"><use xlink:href="${icon.value}"></use></svg></svg><span class="file-label body">${label}</span>`;
        } else {
            iconLable.innerHTML =
            `<i class="${icon.value} file-icon-font"></i><span class="file-label body">${label}</span>`;
        }

        const moreOptContainer = document.createElement( 'span' );
        moreOptContainer.className = 'more-opt-container';
        moreOptContainer.innerHTML = `<svg class="more-opt-icon"><svg id="nu-ic-styles" viewBox="0 0 32 32"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use></svg></svg>`;
        const optionsEl = document.createElement( 'span' );
        optionsEl.className = 'more-opt';
        optionsEl.innerHTML = `<ul class="more-opt-list body"></ul>`;

        ( options || []).forEach( opt => {
            const ul = optionsEl.querySelector( 'ul' );
            const span = document.createElement( 'span' );
            span.innerHTML = opt.label;
            span.setAttribute( 'data-id', opt.id );
            span.classList.add( 'file-embed-option' );
            span.classList.add( 'btn-list-item' );
            span.classList.add( 'btn-free-size' );
            ul.appendChild( span );

            const sub = fromEvent( span, 'click' ).subscribe( event => {
                this.handleOptions( attachment, opt.id, editor );
            });
            subs.push( sub );
        });

        moreOptContainer.appendChild( optionsEl );
        dom.appendChild( iconLable );
        dom.appendChild( moreOptContainer );
    }

    /**
     * Converts and attachment to IFileBlot
     */
    protected getFileBlotData( attachment: IAttachment ): any {
        return {
            id: attachment.id,
            fileId: attachment.fileId,
            label: attachment.name,
            icon: this.getFileIcon( attachment ) as any,
            type: attachment.type.includes( 'image' ) ? 'image' : 'file',
            link: attachment.link,
            source: attachment.source,
            imgSrc: attachment.imgSrc,
            options: this.getFileBlotOptions( attachment ),
        };
    }

    /**
     * Returns the IBlotIcon for the given IAttachment object
     */
    protected getFileIcon( attachment: IAttachment ): any {
        if ( attachment.type === 'link/creately' ) {
            return {
                type: 'svg',
                value: './assets/icons/symbol-defs.svg#nu-ic-logo',
            };
        } else {
            const parts = attachment.name.split( '.' );
            const extention = parts.length > 1 ? parts.reverse()[0] : 'txt';
            const iconName = fileIcons.getClassWithColor( `file.${extention}` );
            return {
                type: 'font',
                value: iconName || fileIcons.getClassWithColor( `file.txt` ),
            };
        }
    }

    /**
     * Returns the options for the file blot. Options depends on the IAttachment
     */
    protected getFileBlotOptions( attachmet: IAttachment ) {
        // TODO enable translation
        const options = [
            { id: 'open', label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.OPEN' ) },
            { id: 'copylink', label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.COPY_LINK' ) },
        ];
        if ( attachmet.downloadable  ) {
            options.splice( 1, 0, { id: 'download',
                label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.DOWNLOAD' ) });
        }
        return options;
    }

    /**
     * Handler function for clicking on blot options
     */
    protected handleOptions(
        blotData: any, action, editor: Editor ) {
        // let action: 'remove' | 'copylink' | 'download' | 'open';
        // scr
        const viewLink = blotData.link;
        let downloadLink = blotData.link;
        if ( blotData.source === 'googleDrive' ) {
            downloadLink = `https://drive.google.com/uc?export=download&id=${blotData.fileId}`;
        }

        if ( action === 'open' ) {
            this.linkSvc.navigate( ShapeLinkModel.fromUrl( viewLink ));
        }

        if ( action === 'copylink' ) {
            this.clipboard.copy( viewLink );
        }
        if ( action === 'download' ) {
            saveAs( downloadLink, blotData.label );
        }

        return true;
    }

}
