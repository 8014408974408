import { isEqual, get } from 'lodash';

/**
 * Supported modifier opeartors
 */
export enum ModifierOperator {
    $set = '$set',
    $unset = '$unset',
}

/**
 * This interface represents a mongo like modifier
 */
export interface IModifierOptions {
    [field: string]: any;
}

/**
 * This interface represents a mongo like modifier
 */
export type IModifier = {
    [operator in ModifierOperator]?: IModifierOptions;
};

/**
 * Modifier utils contains helper methods to work with and extract data from modifiers.
 */
export class ModifierUtils {
    public static isEmptyModifier( modifier: IModifier ): boolean {
        for ( const op in modifier ) {
            const opmod = modifier[op];
            if ( Object.keys( opmod ).length > 0 ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Returns a value which indicates that given path will change with given modifier.
     */
    public static hasChanges( modifier: IModifier, path: string, original?: any ): boolean {
        const parts = path.split( '.' );
        if ( parts.length === 1 ) {
            // NOTE: >>> fast path! >>>
            for ( const op in modifier ) {
                const opmod = modifier[op];
                for ( const propKey in opmod ) {
                    if ( propKey === path ) {
                        if ( original !== undefined ) {
                            // NOTE: propKey only has a single part because it's equal
                            return !isEqual( opmod[path], original[propKey]);
                        }
                        return true;
                    }
                    if ( propKey.startsWith( path + '.' )) {
                        if ( original !== undefined ) {
                            const newPropKey = propKey.substring( path.length + 1 , propKey.length );
                            const fromObject = get( original, newPropKey );
                            return !isEqual( opmod[propKey], fromObject );
                        }
                        return true;
                    }
                }
            }
        } else {
            // NOTE: ||| slow path! |||
            if ( original ) {
                // TODO - Implement original param for multi level paths.
                throw new Error( 'original param is not implemented for multi level path' );
            } else {
                for ( const op in modifier ) {
                    const opmod = modifier[op];
                    for ( const propKey in opmod ) {
                        if ( propKey === path ) {
                            return true;
                        }
                        if ( path.startsWith( propKey + '.' )) {
                            const newPath = path.substring( propKey.length + 1 , path.length );
                            if ( get( opmod[ propKey ], newPath ) !== undefined ) {
                                return true;
                            }
                        }
                        if ( propKey.startsWith( path + '.' )) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

}
