import { map } from 'rxjs/operators';
import { StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureList } from '../../framework/feature/feature-list.svc';
import { StaticLibraryLoader } from '../library/static-library-loader.svc';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { IFeatureDataTransformer } from '../../framework/feature/feature-data-transformer.i';
import { IFeatureItem } from '../../framework/feature/feature-item.i';
import { IDropdownData } from 'flux-core/src/ui';

/**
 * This is a data transformer which transforms data for the shape switch any
 *
 * To know more about data transformers and how they fit into
 * features and their associated UI controls, refer to {@link IFeatureDataTransformer}
 * documentation.
 */
@Injectable()
export class SwitchShapeAnyTransformer implements IFeatureDataTransformer<IFeatureItem, any, any> {

    /**
     * List of shapes that should not be switched from or to
     */
    public static shapesToAvoidSwitching: string[] = [
        'creately.basic.table',
        'creately.flowchart.swimlaneh',
        'creately.flowchart.swimlanev',
        'creately.bpmn.swimlaneh',
        'creately.bpmn.swimlanev',
        'creately.frames.kanban',
        'creately.pm.kanban',
    ];

    constructor( protected state: StateService<any, any>,
                 protected ll: DiagramLocatorLocator,
                 protected libraryLoader: StaticLibraryLoader,
                 protected featureList: FeatureList ) {
    }

    public getFeatureData( featureId: string, data: any ): Observable<IDropdownData> {
        return of( data );
    }

    public isEnabled( featureId: string ): Observable<boolean> {
        const selectedIds = this.state.get( 'Selected' );
        if ( selectedIds.length !== 1 ) {
            return of( false );
        }
        return this.ll.forCurrent( true ).getDiagramOnce().pipe(
            map( d => {
                const shape = d.shapes[ selectedIds[0] ];
                if ( SwitchShapeAnyTransformer.shapesToAvoidSwitching.includes( shape.defId )) {
                    return false;
                }
                return true;
            }),
        );
    }

    public getApplyData( featureId: string, data: any ): Observable<any> {
        const feature = this.featureList.getFeature( featureId );
        return of( feature.data );
    }

}
