// EaselJS Matrix2D implementation is cloned to our own Matrix2D class,
// due to incompatibility issues when used in a non browser env such as NodeJS.
// Both implemntations are exactly same and can be swapped at any time.
// import { Matrix2D } from '@creately/createjs-module';
import { Matrix2D } from './matrix-2d'; // Our own Matrix2D clone
import { Point } from './point';
import { IMatrix, ITransform } from 'flux-definition';

/**
 * A generic Matrix class that extends the EaselJs matrix.
 * This manages all 2D transformation matrix structure and calculations
 * necessary to derive transformation and resulting changes. For more
 * details look at the EaselJS documentaton
 * https://www.createjs.com/docs/easeljs/classes/Matrix2D.html
 *
 * This Matrix facilitates creation of matrix through different approachs
 * and derivation of the transformation in the matrix.
 */
export class Matrix extends Matrix2D implements IMatrix {

    /**
     * Creates a matrix instance that has the given transformation
     * applied to it.
     * @param t transformation properties to create from
     */
    public static fromTransform( t: ITransform ): Matrix {
        const m = new Matrix();
        return <Matrix> m.appendTransform( t.x, t.y, t.scaleX, t.scaleY, t.angle, t.skewX, t.skewY );
    }

    /**
     * Creates a matrix instance out of a EaselJs Matrix
     * @param m Instance of EaselJs matrix
     */
    public static fromMatrix( m: Matrix2D ): Matrix {
        return new Matrix( m.a, m.b, m.c, m.d, m.tx, m.ty );
    }

    /**
     * Transforms the given point as per the this matrix and
     * returns the new transformed point.
     * @param px x of the point to transform
     * @param py y of the point to transform
     */
    public transform( px: number, py: number ): Point {
        const { x, y } = this.transformPoint( px, py );
        return new Point( x, y );
    }

    /**
     * Extracts the transformation properties from this matrix and returns
     * an ITransform object.
     *
     * Currently does not extract skewX and skewY. This is yet to be
     * implemented.
     *
     * This is similar to the EaselJs decompose method. However this is implemented
     * to do the same thing since the decompose method behaves differently than
     * expected.
     */
    public toTransform(): ITransform {
        return this.decompose();
    }

    /**
     * Clones this matrix and returns a new instance of Matrix class
     */
    public clone(): Matrix {
        return new Matrix( this.a, this.b, this.c, this.d, this.tx, this.ty );
    }

}
