import { Injectable } from '@angular/core';
import { CommandService, Random, StateService } from 'flux-core';
import { IAbstractDefinition, IShapeOrigins } from 'flux-definition';
import { cloneDeep, difference, xor } from 'lodash';
import { filter, finalize, last, switchMap, take, tap } from 'rxjs/operators';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { DiagramLocatorLocator } from '../diagram/locator/diagram-locator-locator';
import { ShapeModel } from '../shape/model/shape.mdl';
import { EDataRegistry } from './edata-registry.svc';
import { EDataModel } from './model/edata.mdl';
import { EntityListModel } from './model/entity-list.mdl';
import { EMPTY, of } from 'rxjs';
import { StaticLibraryLoader } from '../../editor/library/static-library-loader.svc';

@Injectable()
export class SmartSetService {

    private definitionCacheMap: Map<string, IAbstractDefinition> = new Map();

    constructor(
        private commandService: CommandService,
        private ll: DiagramLocatorLocator,
        private state: StateService<string, any>,
        private staticLoader: StaticLibraryLoader,
    ) {}

    public updateAllSmartSetContainers(
        data: EntityListModel, eModel: EDataModel, diagramId: string ) {
        const containers =  data.containers[diagramId];
        if ( !containers || Object.keys( containers ).length === 0 ) {
            return;
        }
        const containerData = {};
        const shapes = {};
        const toRemove = {};
        Object.keys( containers ).forEach( containerId => {
            const entities = difference( data.entities, Object.keys( containers[containerId]));
            const tempRemove = difference( Object.keys( containers[containerId]), data.entities );
            if ( tempRemove.length > 0 ) {
                toRemove[containerId] = tempRemove;
            }
            const shapesToAdd = this.getShapes( entities, eModel, containerId );
            Object.assign( shapes, shapesToAdd.shapes );
            Object.assign( containerData, shapesToAdd.containerData );
        });
        for ( const shapeId in shapes ) {
            shapes[shapeId].entityListId = data.id;
            shapes[shapeId].definedSearchQuery = data.search;
        }
        if ( Object.keys( toRemove ).length > 0 ) {
            let toRemoveIds = [];
            this.ll.forDiagram( this.state.get( 'CurrentDiagram' ), false ).getDiagramOnce().pipe(
                tap( diagram => {
                    for ( const containerId in toRemove ) {
                        if ( diagram.shapes[containerId]) {
                            const container = diagram.shapes[containerId] as ShapeModel;
                            const shapeIds = {};
                            for ( const shapeId in container.children ) {
                                const childShape = diagram.shapes[shapeId];
                                if ( childShape.entityId ) {
                                    shapeIds[childShape.entityId] = shapeId;
                                }
                            }
                            toRemoveIds = toRemoveIds.concat( toRemove[containerId].map( eId => shapeIds[eId])
                                .filter( id => !!id ));
                        }
                    }

                    if ( toRemoveIds.length > 0 ) {
                        this.commandService
                            .dispatch( DiagramCommandEvent.removeShapeExternal, { shapeIds: toRemoveIds });
                    }
                }),
            ).subscribe();
        }
        if ( Object.keys( shapes ).length > 0 ) {
            this.commandService
                .dispatch( DiagramCommandEvent.addDiagramShapeExternal, { shapes }).pipe(
                    last(),
                    switchMap(() => this.commandService.dispatch( DiagramCommandEvent.changeContainerDataExternal, {
                        childrenData: containerData,
                        __origin__: 'SmartSetUpdate',
                    })),
                ).subscribe();
        }
    }

    public addMoreShapes( entities: string[], eModel: EDataModel, containerId: string,
                          data: { id?: string; search: string; }, isEntityList = false ) {
        return this._cacheShapeDefs( entities.map( eId => eModel.entities[ eId ])).pipe(
            switchMap(() => this._addMoreShapes( entities, eModel, containerId, data, isEntityList )),
        );
    }

    public updateEntityLists( eModel: EDataModel, diagramId: string ) {
        Object.values( eModel.entityLists || {}).filter( smartSet => {
            const diagramEntities = smartSet.containers[diagramId];
            if ( !diagramEntities ) {
                return false;
            }
            return Object.keys( diagramEntities ).some( containerId => {
                const entities = Object.keys( diagramEntities[containerId]);
                const xorEntities = xor( entities , smartSet.entities );
                return xorEntities && xorEntities.length > 0;
            });
        }).forEach( smartSet => this.updateEntityList( smartSet, eModel, diagramId ));
    }

    public updateEntityList( smartSet, eModel, diagramId ) {
        this.cacheShapeDefs( smartSet, eModel, diagramId ).pipe(
            finalize(() => this.updateAllSmartSetContainers( smartSet, eModel, diagramId )),
        ).subscribe();
    }

    protected _addMoreShapes( entities: string[], eModel: EDataModel, containerId: string,
                              data: { id?: string; search: string; }, isEntityList = false ) {
        const { shapes, containerData } = this.getShapes( entities, eModel, containerId );
        if ( isEntityList ) {
            for ( const shapeId in shapes ) {
                shapes[shapeId].entityListId = data.id;
                shapes[shapeId].definedSearchQuery = data.search;
            }
        } else {
            for ( const shapeId in shapes ) {
                shapes[shapeId].displayListId = data.id;
                shapes[shapeId].definedSearchQuery = data.search;
            }
        }
        return this.commandService.dispatch( DiagramCommandEvent.addDiagramShape, { shapes }).pipe(
            last(),
            switchMap(() => this.commandService.dispatch( DiagramCommandEvent.changeContainerData, {
                childrenData: containerData,
            })),
            last(),
        );
    }

    private getShapes( entities: string[], eModel: EDataModel, containerId: string ) {
        const shapes = {};
        const containerData = {};
        entities.forEach( entityId => {
            const entity = eModel.entities[entityId];
            if ( entity ) {
                const def = entity.getShapeDefIdForContext();
                if ( def ) {
                    const shapeDef = this.definitionCacheMap.get( `${def.id}.${def.version}` ) as any;
                    const shapeId = Random.getIdDiff( entityId, containerId );
                    // below line is not correct. we should not merge everything in the def to shape.
                    // instead we should selectively merge defId, version, ...
                    shapes[shapeId] = Object.assign({}, shapeDef );
                    shapes[shapeId].id = shapeId;
                    shapes[shapeId].texts = cloneDeep( shapeDef.texts );
                    const shapeData  = cloneDeep( shapeDef.data );
                    shapes[shapeId].data = {};
                    shapes[shapeId].containerId = containerId;
                    Object.assign( shapes[shapeId].data, shapeData );

                    Object.assign( shapes[shapeId].data, cloneDeep( shapeDef.dataDef ));
                    if ( EDataRegistry.customEdataDefId ===  entity.defId ) {
                        this.copyEntityData( entity, shapeId, shapes );
                    } else {
                        const entityDef = entity.getDef();
                        if ( entityDef.shapeDefs[shapes[shapeId].defId]) {
                            const dataMap = entityDef.shapeDefs[shapes[shapeId].defId].dataMap;
                            dataMap.forEach(( mappedData: any ) => {
                                shapes[shapeId].data[mappedData.dataItemId].value =
                                entity.data[mappedData.eDataFieldId] ||
                                     entityDef.dataItems[mappedData.eDataFieldId].default;
                            });
                        } else {
                            this.copyEntityData( entity, shapeId, shapes, entityDef );
                        }
                    }
                    // shapes[shapeId].entityListId = data.id;
                    shapes[shapeId].triggerNewEData = true;
                    shapes[shapeId].eData = {
                        [eModel.id]: entity.id,
                    };
                    if ( entity.style ) {
                        shapes[shapeId].style = { ...entity.style.shape };
                    }
                    // shapes[shapeId].definedSearchQuery = data.search;
                    shapes[shapeId].origin = IShapeOrigins.PRE_DEFINED_QUERIES;
                    containerData[ shapeId ] = {
                        action: 'enter',
                        containerId: containerId,
                        force: true,
                    };
                }
            }
        });
        return { shapes, containerData };
    }

    private cacheShapeDefs( data: EntityListModel, eModel: EDataModel, diagramId: string ) {
        const containers =  data.containers[diagramId];
        if ( !containers || Object.keys( containers ).length === 0 ) {
            return EMPTY;
        }
        return EDataRegistry.instance.initialized.pipe(
            filter( models => models.map( e => e.id ).includes( eModel.id )),
            take( 1 ),
            switchMap(() => {
                let entities = [];
                Object.keys( containers ).forEach( containerId => {
                    entities = entities.concat( difference( data.entities, Object.keys( containers[containerId]))
                        .map( entityId => eModel.entities[entityId]));
                });
                return this._cacheShapeDefs( entities );
            }),
        );
    }

    private _cacheShapeDefs( entities ) {
        const shapeDefs = {};
        entities.forEach( e => {
            const def = e.getShapeDefIdForContext();
            if ( def ) {
                const key = `${def.id}.${def.version}`;
                if ( !this.definitionCacheMap.has( key ) && !shapeDefs[key]) {
                    shapeDefs[ key ] = true;
                }
            }
        });
        if ( Object.keys( shapeDefs ).length === 0 ) {
            return of({});
        }
        return this.staticLoader.loadShapeDefinitions( Object.keys( shapeDefs )).pipe(
            tap( defs => defs.forEach( def => {
                this.definitionCacheMap.set( `${def.defId}.${def.version}`, def );
            })),
        );
    }

    private copyEntityData( entity, shapeId, shapes, entityDef?: any ) {
        if ( !entityDef ) {
            entityDef = entity.getDef();
        }
        Object.keys( shapes[shapeId].data ).forEach(( key: string ) => {
            if ( entityDef && entityDef.dataItems[key]) {
                shapes[shapeId].data[key].value = entity.data[key] ||
                entityDef.dataItems[key].default;
            }
        });
    }

}
