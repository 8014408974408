import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';
import { PathCanvasInstructionFactory } from './path-canvas-instruction-factory';

/**
 * class EllipseCanvasInstructionFactory
 * Generate canvas instruction for svg ellipse element instructions
 */
export class EllipseCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'ellipse';

    /**
     * Return ellipse canvas instruction for given ellipse shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        const cx = data.data.cx ? +data.data.cx : 0;
        const cy = data.data.cy ? +data.data.cy : 0;
        const rx = +data.data.rx;
        const ry = +data.data.ry;

        const ellipsePath = this.ellipseToPath( cx, cy, rx, ry );
        const pathFactory = new PathCanvasInstructionFactory();
        data.data.d = ellipsePath;
        return pathFactory.createInstruction( data );
    }

}
