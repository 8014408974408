import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { forEach } from 'lodash';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ChangeImageGallery
 * This will change the imageGallery map  property of the diagram.
 */
@Injectable()
@Command()
export class ChangeImageGallery extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: { [imageId: string]: {
        /**
         * To specify whether add or remove the shape
         */
        action: 'add' | 'remove',

        /**
         * The time added
         */
        addedTime?: number,
    }};

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        forEach( this.data, ( value, key ) => {
            if ( value.action === 'add' ) {
                this.changeModel.imageGallery[ key ] = {
                    added: value.addedTime,
                };
            } else {
                delete this.changeModel.imageGallery[ key ];
            }
        });
    }
}

Object.defineProperty( ChangeImageGallery, 'name', {
    value: 'ChangeImageGallery',
});
