<div class="quick-search-panel">
  <mat-form-field>
      <mat-select #matSelectInfiniteScroll
                  msInfiniteScroll
                  (selectionChange)="handleShapeClick($event)"
                  (infiniteScroll)="getNextBatch()"
                  [formControl]="dataControl"
                  [ngClass]="{'alignTop': alignTop }"
                  placeholder="">
        <div style="position: absolute;top: 0px;">         
            <ngx-mat-select-search
              #search
              [formControl]="searchCtrl"
              placeholderLabel="{{translate('LABELS.SEARCH') | async }}"
              class="search-panel quick-search-panel-input-container"
              noEntriesFoundLabel="{{translate('LABELS.NO_SEARCH_RESULTS') | async }}">
              <div ngxMatSelectSearchIcon class="search-icon-container fx-center-all">
                <svg class="nu-icon nu-ic-search-thin medium-grey">
                  <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-search-thin"></use>
                </svg>
              </div>
              <div ngxMatSelectSearchClear class="search-clear-icon-container fx-center-all">
                <svg class="nu-icon nu-icon-small medium-grey">
                  <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
              </div>
            </ngx-mat-select-search>
        </div>
        <div class="search-result-block" *ngFor="let searchBlock of searchResults | async">
          <mat-option disabled class="caption category-title">
            <span class="caption">{{searchBlock?.category}}</span>
          </mat-option>
          <mat-option *ngFor="let i of searchBlock?.results" [value]="i">
              <plus-create-item #tile
                *ngIf="i.type === 'shape' || i.type === 'viz'"
                [item]="i"
                [canvasWidth]="35"
                [canvasAreaWidth]="canvasAreaWidth"
                draggable="false">
              </plus-create-item>
            </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
</div>
