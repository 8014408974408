import { AbstractModel, ComplexType, enumerable } from 'flux-core';
import { ShapeLinkModel } from './shape-link.mdl';
import { IAbstractDefinition, LockType } from 'flux-definition';
import { ILineStyle } from 'flux-definition';
import { ShapeType } from 'flux-definition';

/**
 * AbstractShape
 * is a the top most abstraction of shapes and connectors. This will be extended by all
 * shape models. This will contain common functionality and data of all types of shapes.
 *
 * @author hiraash
 * @since 2016-06-14
 */
export class SimpleShapeModel extends AbstractModel implements IAbstractDefinition {

    /**
     * The id of the definition for this shape.
     */
    public defId: string;

    /**
     * Visual name for this shape or connector
     */
    public name: string;

    /**
     * The type of this shape
     */
    public type: ShapeType;

    /**
     * The version of the definiton for this shape.
     */
    public version: number;

    /**
     * Styling for the shape. Concrete versions will represent
     * more complex style properties.
     */
    public style: ILineStyle;

    /**
     * The lock type of the shape which will be
     * use for check shape is lock or not for current user
     */
    public lockType: LockType;

    /**
     * This property holds the information about the link, if this shape
     * has a link of any kind.
     */
    @ComplexType( ShapeLinkModel )
    public links: ShapeLinkModel[];

    /**
     * This method will check shape is lock or not
     * according to the lockType value
     */
    @enumerable( true )
    public get isLocked(): boolean {
        // TODO: LockedForNonAdmin is not considered at the moment. Should be considered when admin role is introduced
        if ( this.lockType === LockType.LockedForAll ) {
            return true;
        }
        return false;
    }

    /**
     * This method checks whether the shape has a link
     * @returns {boolean}
     */
    public hasLink(): boolean {
        return  this.links && this.links.length > 0 ? true : false;
    }

    /**
     * Returns shape link model attached to this
     * shape. Attaches the shape id to the link
     * before returning.
     */
    public getLink(): ShapeLinkModel {
        if ( this.hasLink()) {
            this.links[0].shapeId = this.id;
        }
        return this.links && this.links[0];
    }

    /**
     * This method checks whether the shape is a Basic shape
     * @returns {boolean}
     */
    public isBasic(): boolean {
        return  this.type === ShapeType.Basic;
    }

    /**
     * This method checks whether the shape is a shape based
     * on a custom image
     * @returns {boolean} - true if shape is image
     */
    public isImage(): boolean {
        return  this.type === ShapeType.Image;
    }

    /**
     * This method checks whether the shape is a Basic shape
     * @returns {boolean}
     */
    public isDynamic(): boolean {
        return  this.type === ShapeType.Dynamic;
    }

    /**
     * This method checks whether the shape is a Connector
     * @returns {boolean}
     */
    public isConnector(): boolean {
        return  this.type === ShapeType.Connector;
    }


}

Object.defineProperty( SimpleShapeModel, 'name', {
  writable: true,
  value: 'SimpleShapeModel',
});
