<div class="modal-window-container fx-cover" #window>
    <div class="modal-window-left">
            <ng-content select="[left]"></ng-content>
    </div>

    <div class="modal-window-right" #rightPanel>
        <perfect-scrollbar class="modal-window-right-scroll">
            <div class="modal-window-close">
                <button class="btn-medium fx-margin-right-5 nu-btn-icon" (click)="closeWindow()">
                    <svg class="nu-icon nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
                </button>
            </div>

            <div class="modal-window-right-content">
                <ng-content select="[right]"></ng-content>
            </div>
        </perfect-scrollbar>
    </div>
</div>