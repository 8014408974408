import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will be fetch embed image url.this url will use by embed tab to share diagram image.
 * User can share the this private embed url to share the diagram image.
 * Share tab will generate a image tag using this embed diagram image url.
 * @input To generate the utl this command need the respective diagram id.
 * @output This command will return the embed image url.this url contain a svg image.
 * @author  Shashik
 * @since   2020-07-06
 */
@Injectable()
@Command()
export class FetchEmbedImageUrl extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor( protected auth: NucleusAuthentication ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': this.auth.token,
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

  /**
   * Command execute
   */
    public execute(): Observable<any> {
        return empty();
    }

    public prepareData(): any {
        const query = `{
            document(id:"${ this.data.resourceId}"){
            image{
              svg
            }
        }}`;
        this.data.query =  query;
    }
}

Object.defineProperty( FetchEmbedImageUrl, 'name', {
    value: 'FetchEmbedImageUrl',
});
