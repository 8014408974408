import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StateService, ContainerEnv } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractRouteResolver } from '../../framework/controller/abstract.resolver';


/**
 * This resolver can intercept a redirect request and will remap the
 * request based on application state.
 *
 * the request should have a data property in the following form and be called as a
 * canActivate RouteGuard
 * data: {
 *  url: <url for defaut resolution>
 *  options: [ { condition:'<String to identify condition to test for>'
 *               redirect: '<URL to redirect to if the condition passes>' },
 *              ... <array of options>
 *              ]
 * }
 *
 *
 */
@Injectable()
export class RedirectRouteResolver extends AbstractRouteResolver implements CanActivate {

    constructor ( protected state: StateService<any, any>,
                  protected env: ContainerEnv,
                  protected router: Router ) {
        super( state );
    }

    /**
     * Runs before the route is resolved, we check the passed 'data' prop
     * in the route params for instructions on how to filter and redirect to
     * an external url.
     */
    public canActivate( route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot ): boolean {
        if ( route.data.options ) {
            for ( const option of route.data.options ) {
                if ( option.condition === 'env.desktop' && this.env.isDesktop ) {
                    this.router.navigateByUrl( option.redirect );
                    return true;
                }
            }
        }
        this.navigateToAbsoluteURL( route.data.url );
        return true;
    }

    /**
     * navigates to external or internal URL
     * by directly setting the URL on the window
     * @param url url string to navigate to
     */
    private navigateToAbsoluteURL( url: string ) {
        this.getWindow().location.href = url;
    }

    /* istanbul ignore next */
    private getWindow() {
        return window;
    }
}
