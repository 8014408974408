import { mapValues } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { StateService } from 'flux-core';
import { map, switchMap, take } from 'rxjs/operators';
import { IPoint2D } from 'flux-definition';
import { Injectable } from '@angular/core';
import { IEmittedShapeChange, AbstractShapeModel } from 'flux-diagram-composer';
import { ShapeModel } from '../shape/model/shape.mdl';
import { Sakota } from '@creately/sakota';
import { DiagramToViewportCoordinate } from '../coordinate/diagram-to-viewport-coordinate.svc';
import { DiagramLocatorLocator } from './locator/diagram-locator-locator';
import { DiagramModel } from './model/diagram.mdl';

/**
 * This is a helper service that allows access to various functionalities
 * of the diagram viewport.
 *
 * @since   2019-04-25
 * @author  Ramishka
 */
@Injectable()
export class ViewportService {

    constructor( protected state: StateService<any, any>,
                 protected ll: DiagramLocatorLocator,
                 protected coordinate: DiagramToViewportCoordinate ) {}

    /**
     * This function can be used to keep track of coordinate system changes
     * caused by pan and zoom interactions in the diagram.
     * It returns an observable that emits every time a pan or a zoom
     * interaction happens. Coordinate changes are continously emitted
     * long as this observable is subscribed.
     * @return observable that emits current pan and zoom values
     */
    public getCoordinateChanges(): Observable<{ pan: IPoint2D, zoom: number }> {
        return combineLatest(
            this.state.changes( 'DiagramZoomLevel' ),
            this.state.changes( 'DiagramPan' ),
        ).pipe(
            map(([ z, p ]) => ({ pan: p, zoom: z })),
        );
    }

    /**
     * Returns a proxied version of a shape model that has all
     * applicable properties converted to viewport diagram coordinates.
     * The observable is emitted every time a shape changes.
     * @param shapeId - shape id
     * @return - Observable which emits a converted version of the shape model wrapped
     * by Sakota. Emits on each shape change until shape is deleted.
     */
    public getShapeModel( shapeId: string ): Observable<ShapeModel> {
        return this.ll.forCurrentObserver( true ).pipe(
            switchMap( locator => locator.getShapeModel( shapeId )),
            switchMap(( model: ShapeModel ) => this.convertShape( shapeId )),
        );
    }

    /**
     * Returns an array of a proxied version of all or specified shape models that has all
     * applicable properties converted to viewport diagram coordinates.
     * The observable is emitted every time the diagram changes.
     * @param shapeId - shape id
     * @return - Observable which emits an array of converted version of the shape models wrapped
     * by Sakota. Emits on each diagram change.
     */
    public getShapeModels( ids?: string[]): Observable<{ [id: string]: AbstractShapeModel }> {
        if ( ids && ids.length === 0 ) {
            return of({});
        }
        if ( ids && ids.length > 0 ) {
            return this.ll.forCurrentObserver( true ).pipe(
                take( 1 ),
                switchMap( locator => locator.getDiagramModel()),
                map( model => ids.map( id => model.shapes[ id ])),
                map( val =>  {
                    const object = {};
                    val.forEach( v => {
                        if ( v ) {
                            object[ v.id ] = v;
                        }
                    });
                    return object;
                }),
                switchMap( shapes => this.convertShapes( shapes )),
            );
        }
        return this.ll.forCurrentObserver( true ).pipe(
            switchMap( locator => locator.getDiagramModel()),
            switchMap(( model: DiagramModel ) => this.convertShapes( model.shapes )),
        );
    }

    /**
     * Returns a proxied version of a shape model that has all
     * applicable properties converted to viewport diagram coordinates.
     * The observable is emitted only once and is completed after.
     * @param shapeId - shape id
     * @return - observable which emits once a converted version of the shape
     * model wrapped by Sakota.
     */
    public getShapeOnce( shapeId: string ): Observable<ShapeModel> {
        return this.convertShape( shapeId ).pipe(
            take( 1 ),
        );
    }

    /**
     * Returns an observable which emits the shape changes along with a proxied version of a
     * shape model  that has all applicable properties converted to viewport diagram coordinates.
     * The observable is emitted only once and is completed after.
     * @param shapeId - shape id
     * @return - observable which emits a converted version of the shape model wrapped by Sakota.
     */
    public getShapeChanges( shapeId: string ): Observable<IEmittedShapeChange<ShapeModel>> {
        return this.ll.forCurrentObserver( true ).pipe(
            switchMap( locator => locator.getShapeChanges( shapeId )),
            switchMap( emittedChange => this.convertShape( emittedChange.model.id ).pipe(
                map( convertedModel => ({
                    id: emittedChange.id,
                    model: convertedModel,
                    // FIXME: modifier is not converted
                    modifier: emittedChange.modifier,
                })),
            )),
        );
    }

    /**
     * Wraps a shape model using Sakota and then convers applicable properties into
     * viewport coordinates
     * @param model - model to be wrapped and converted
     * @return observable which emits the wrapped and converted model
     */
    private convertShape( shapeId: string ): Observable<ShapeModel> {
        return this.getCoordinateChanges().pipe(
            switchMap(() => this.ll.forCurrentObserver( true )),
            switchMap( locator => locator.getShapeOnce( shapeId )),
            map( model => {
                if ( model ) {
                    let converted: any = Sakota.create( model );
                    converted = this.coordinate.shape( converted );
                    return converted;
                }
                return null;
            }),
        );
    }

    /**
     * Wraps a shape model using Sakota and then convers applicable properties into
     * viewport coordinates
     * @param model - model to be wrapped and converted
     * @return observable which emits the wrapped and converted model
     */
    private convertShapes( shapes: { [id: string]: AbstractShapeModel }):
        Observable<{ [id: string]: AbstractShapeModel }> {
            return this.getCoordinateChanges().pipe(
                map(() => mapValues( shapes, s => {
                    let converted: any = Sakota.create( s );
                    converted = this.coordinate.shape( converted );
                    return converted;
                })),
            );
    }
}
