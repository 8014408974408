import { NgModule } from '@angular/core';
import { MessageFactory } from './message/message-factory.svc';
import { NeutrinoConnection } from './connection/neutrino-connection.svc';
import { NeutrinoRealtime } from './subscription/neutrino-realtime.svc';
import { AbstractAuthentication } from './auth/abstract-auth.svc';
import { NetworkResponsibility } from './connection/network-responsibility.svc';

@NgModule({
    providers: [
        MessageFactory,
        NeutrinoConnection,
        AbstractAuthentication,
        NetworkResponsibility,
        { provide: 'NetworkResponsibility', useExisting: NetworkResponsibility },
    ],
})
export class FluxConnection {
}

@NgModule({
    providers: [
        NeutrinoRealtime,
    ],
})
export class FluxRealtime {
}
