import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * This does validation of Nested data item object.
 * For the moment no validation is required.
 */
export class NestedDataItemObject extends AbstractDataItem<DataType.NESTED_DATAITEM_OBJECT> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof NestedDataItemObject.prototype.value ): IValidationError {
        return null;
    }
}
