/**
 * A map of keys which are associated to multiple values.
 */
export interface ITagMapValues<T> {
    [ key: string ]: T[];
}

/**
 * Tag map is similar to a regular map but holds multiple values for each key.
 *
 *    { key => value[] }
 *
 * The main purpose of this data structure is to store values in such a way that
 * values which are associated to given set of keys can be stored/queried efficiently.
 */
export class TagMap<T> {
    /**
     * Creates a new TagMap instance with given values.
     */
    public static withValues<T>( values: ITagMapValues<T> ): TagMap<T> {
        const map = new TagMap<T>();
        return Object.assign( map, { values });
    }

    /**
     * Values will be stored in this map grouped by keys.
     */
    private values: ITagMapValues<T> = {};

    /**
     * Returns a boolean indicating whether there are values associated with all given keys.
     */
    public has( keys: string[]): boolean {
        const values = this.get( keys );
        return values.length > 0 ;
    }

    /**
     * Returns a boolean indicating whether the given value exists in the map.
     */
    public hasValue( value: T ) {
        // tslint:disable-next-line:forin
        for ( const key in this.values ) {
            if ( this.values[key].indexOf( value ) !== -1 ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns values which are associated with all given keys.
     */
    public get( keys: string[]): T[] {
        if ( !keys.length ) {
            return [];
        }
        const firstKey = keys[0];
        if ( !this.values[ firstKey ]) {
            return [];
        }
        let result = this.values[ firstKey ].slice();
        for ( let i = 1; i < keys.length; ++i ) {
            const key = keys[i];
            const values = this.values[ key ];
            // If any of the given keys are not available in the values map
            // we can definitely say that the result array will be empty.
            if ( !values ) {
                return [];
            }
            // Get the intersection of current results and new values arrays.
            result = result.filter( val => values.indexOf( val ) !== -1 );
            // If the result array is empty, break the loop and return [].
            if ( !result.length ) {
                return [];
            }
        }
        return result;
    }

    /**
     * Assigns a value to all given keys.
     */
    public set( keys: string[], value: T ): void {
        for ( let i = 0; i < keys.length; ++i ) {
            const key = keys[i];
            const values = this.values[ key ];
            if ( !values ) {
                this.values[ key ] = [ value ];
                continue;
            }
            if ( values.indexOf( value ) === -1 ) {
                values.push( value );
            }
        }
    }

    /**
     * Removes the given key from the map.
     */
    public delete( key: string ) {
        delete this.values[key];
    }

    /**
     * Removes all keys from the map.
     */
    public clear() {
        this.values = {};
    }
}
