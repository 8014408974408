import { Injectable } from '@angular/core';
import { AbstractCommand, CommandInterfaces, StateService } from 'flux-core';

/**
 * Open templates window
 */
@Injectable()
export class AddTemplate extends AbstractCommand {
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }
    public static get dataDefinition(): {}  {
        return {
            state: false,
        };
    }
    constructor( protected state: StateService<any, any> ) {
        super();
    }
    public get states(): { [ stateId: string ]: any } {
        return this.data.state;
    }
    public execute(): boolean {
        this.data.state = {
            TemplateOriginLocation: 'context-menu',
            ToggleTemplatesModal: true,
        };
        return true;
    }
}

Object.defineProperty( AddTemplate, 'name', {
    value: 'AddTemplate',
});
