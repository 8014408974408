import { CommandMapper } from './mapper/command-mapper.svc';
import { CommandScenario } from './command-scenario.enum';

/**
 * This interface defines information that a command knows
 * about the {@link CommandEvent} which triggerd it.
 *
 * It holds information that help identify the event, identify
 * any other events that it affects and information about
 * scenario it was executed in.
 *
 * @since   2018-08-22
 * @author  Ramishka
 */
export interface ICommandEventData {
    /**
     * A unique id that is used to identify the event
     */
    eventId: string;

    /**
     * Name of the command event.
     */
    eventName: string;

    /**
     * event source, user triggered or system event
     */
    source?: string;

    /**
     * If this event is based on another event (i.e. an event
     * that undones another event), that events id is specified here.
     */
    originalEventId?: string;

    /**
     * The command scenario this event is being executed under
     */
    scenario?: CommandScenario;

    /**
     * Context data assciated with the command,
     * e.g. When a edata change is dispatched we can set the diagramId in the context
     * ctx = { diagramId: 'd1' }
     */
    changeCtx?: any;

    /**
     * context for the event
     */
    ctx?: any;
}

/**
 * EventSource = 'user' means user triggered events
 * EventSource = 'system' means system triggered events in response to user interaction
 * EventSource = 'external' means events that will fire from listeners in response to external event,
 *                          these events are not tracked in session history.
 * EventSource = 'transient' means events is temporary and will be confirmed by next event.
 *                         these events are undone automatically if the next event is undone by user.
 */
export enum EventSource {
    USER = 'user',
    SYSTEM = 'system',
    EXTERNAL = 'external',
    TRANSIENT = 'transient',
}


/**
 * This class is an abstraction for a list of constants
 * that define the command names of each command. The extending
 * classes such as <code>DiagramCommandEvent</code> will include
 * a list of diagram events that associate to command, for example.
 *
 * These events associate to one or more commands. When the event
 * is dispatched to the command system, the associated commands
 * run in the configured pattern. See more in <code>CommandService</code>
 *
 * @author  hiraash
 * @since   2016-03-26
 */
export class CommandEvent {
    /**
     * Call all static methods which starts with "register" with the mapper.
     */
    public static register( mapper: CommandMapper ) {
        // tslint:disable-next-line:forin
        for ( const propName in this ) {
            const property: any = this[propName];
            if ( propName === 'register' ||
                !propName.startsWith( 'register' ) ||
                typeof property !== 'function' ) {
                continue;
            }
            property.call( this, mapper );
        }
    }

    protected _value: string;
    protected source: string;
    protected dataTransformer?: CallableFunction;

    public constructor( event: string, source: EventSource = EventSource.USER, dataTransformer?: CallableFunction ) {
        this._value = event;
        this.source = source;
        this.dataTransformer = dataTransformer;
    }

    public toString() {
        return this._value;
    }

    public getSource() {
        return this.source;
    }

    public transformData( data: any ) {
        if ( this.dataTransformer ) {
            return this.dataTransformer( data );
        }
        return data;
    }
}

/**
 * AbstractDiagramCommandEvent is the base class for the DiagramCommandEvent
 * class. Currently, this is only used to enforce the resourceId parameter
 * on CommandService dispatch method.
 */
export class AbstractDiagramCommandEvent extends CommandEvent {}

/**
 * AbstractProjectCommandEvent is the base class for the ProjectCommandEvent
 * class. Currently, this is only used to enforce the resourceId parameter
 * on CommandService dispatch method.
 */
export class AbstractProjectCommandEvent extends CommandEvent {}


/**
 * AbstractEDataCommandEvent is the base class for the ProjectCommandEvent
 * class. Currently, this is only used to enforce the resourceId parameter
 * on CommandService dispatch method.
 */
// tslint:disable-next-line:max-classes-per-file
export class AbstractEDataCommandEvent extends CommandEvent {}
