import { Injectable } from '@angular/core';
import { Proxied } from '@creately/sakota';
import { AbstractCommand, Command, CommandError, CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';
import { EDataChangeService } from '../edata-change.svc';
import { EDataModel } from '../model/edata.mdl';

/**
 * AbstractEDataChangeCommand is the base abstract class which can be extended
 * to create edata change commands. Changes should be done only inside the prepare
 * data hook to the change model.
 *
 * This is derived from AbstractDiagramChangeCommand
 * Note that resourceId has to be passed into this command.
 */
@Injectable()
@Command()
export abstract class AbstractEDataChangeCommand extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IModelChangeCommand', 'IEDataCommand' ];
    }

    /**
     * changeModel
     * changeModel is a proxied model object which can record changes done to it.
     */
    public changeModel: Proxied<EDataModel>;

    /**
     * Constructor injects services required by the command.
     * @param locator The diagram locator
     */
    constructor( protected es: EDataChangeService ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Returns an observable which emits the proxied edata model once.
     */
    public prepareProxy(): Observable<Proxied<EDataModel>> {
        return this.es.getChangeModel( this.resourceId, this.eventData.eventId, this.eventData.scenario );
    }

    /**
     * execute
     * execute returns true if and only if the modifier has any changes.
     */
    public execute() {
        return true;
    }

    /**
     * onError
     * onError checks whether the error is a network related error and ignores them.
     */
    public onError( err: CommandError ) {
        if ( err.name !== 'ConnectionError' ) {
            throw err;
        }
    }
}

Object.defineProperty( AbstractEDataChangeCommand, 'name', {
    value: 'AbstractEDataChangeCommand',
});
