import { ProjectChange, AddEdataToProject } from 'flux-diagram';
import { CommandMapper, AbstractProjectCommandEvent, ICommandSequenceProgress, EventSource } from 'flux-core';
import { CreateDiagramPrivate } from './create-diagram-private.cmd';
import { CreateDiagramPublic } from './create-diagram-public.cmd';
import { CreateDiagram } from './create-diagram.cmd';
import { GetTemplateData } from '../../../editor/diagram/templates/get-template-data.cmd';
import { LoadDocument } from '../../../plugin/command/load-document.cmd';
import { SaveDocument } from '../../../plugin/command/save-document.cmd';
import { CreateEData } from '../../../base/edata/command/create-edata.cmd';
import { CreateDiagramTeam } from './create-diagram-team.cmd';
import { CreateDiagramLink } from './create-diagram-link.cmd';

/**
 * ProjectDiagramCommandEvent
 * ...
 */
// tslint:disable: member-ordering
export class ProjectDiagramCommandEvent extends AbstractProjectCommandEvent {

    /**
     * Creates a private diagram
     */
    static createDiagram: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'CreateDiagram' );
    protected static registerCreateDiagram( mapper: CommandMapper ) {
        mapper.mapSequence( this.createDiagram )
            .add( GetTemplateData as any )
            .add( CreateDiagram as any, {
                transform: progress => this.transformDataForCreate( progress ),
            });
    }

    /**
     * Creates a private diagram
     */
    static createDiagramPrivate: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'CreateDiagramPrivate' );
    protected static registerCreateDiagramPrivate( mapper: CommandMapper ) {
        mapper.mapSequence( this.createDiagramPrivate )
            .add( GetTemplateData as any )
            .add( CreateDiagramPrivate as any, {
                transform: progress => this.transformDataForCreate( progress ),
            });
    }

    /**
     * Creates a public diagram
     */
    static createDiagramPublic: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'CreateDiagramPublic' );
    protected static registerCreateDiagramPublic( mapper: CommandMapper ) {
        mapper.mapSequence( this.createDiagramPublic )
            .add( GetTemplateData as any )
            .add( CreateDiagramPublic as any, {
                transform: progress => this.transformDataForCreate( progress ),
            });
    }

    /**
     * Creates a team diagram
     */
     static createDiagramTeam: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'CreateDiagramTeam' );
     protected static registerCreateDiagramTeam( mapper: CommandMapper ) {
         mapper.mapSequence( this.createDiagramTeam )
             .add( GetTemplateData as any )
             .add( CreateDiagramTeam as any, {
                 transform: progress => this.transformDataForCreate( progress ),
             });
     }

    /**
     * Creates a team diagram
     */
    static createDiagramLink: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'CreateDiagramLink' );
    protected static registerCreateDiagramLink( mapper: CommandMapper ) {
        mapper.mapSequence( this.createDiagramLink )
            .add( GetTemplateData as any )
            .add( CreateDiagramLink as any, {
                transform: progress => this.transformDataForCreate( progress ),
            });
    }

    /**
     * create EData model
     */
    static createEData: ProjectDiagramCommandEvent =
        new ProjectDiagramCommandEvent( 'CreateEData', EventSource.SYSTEM );
    protected static registerCreateEData( mapper: CommandMapper ) {
        mapper.mapSequence( this.createEData )
            .add( CreateEData as any )
            .add( AddEdataToProject as any, {
                transform( progress ) {
                    return progress.resultData[0][0];
            }})
            .add( ProjectChange as any, {
                transform( progress ) {
                    return progress.resultData[1];
                },
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Register load document command
     */
    static loadDocument: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'LoadDocument' );
    protected static registerLoadDocument ( mapper: CommandMapper ) {
        mapper.mapSequence( this.loadDocument ).add( LoadDocument as any );
    }

    /**
     * Register save document command
     */
    static saveDocument: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'SaveDocument' );
    protected static registerSaveDocument ( mapper: CommandMapper ) {
        mapper.mapSequence( this.saveDocument ).add( SaveDocument as any );
    }

    /**
     * Transforms template data (if available) so that
     * they can be used with the Create command.
     * @return transformed data
     */
    private static transformDataForCreate( progress: ICommandSequenceProgress ): any {
        const data = progress.data[0];
        if ( progress.resultData && progress.resultData[0]) {
            const templateData = progress.resultData[0].templateData;
            if ( templateData ) {
                data.shapes = templateData.shapes;
                data.type = templateData.type;
                if ( templateData.hasOwnProperty( 'connections' )) {
                    data.connections = templateData.connections;
                }
                if ( templateData.hasOwnProperty( 'groups' )) {
                    data.groups = templateData.groups;
                }
                if ( templateData.hasOwnProperty( 'description' )) {
                    data.description = templateData.description;
                }
                if ( templateData.hasOwnProperty( 'tags' )) {
                    data.tags = templateData.tags;
                }
                if ( templateData.hasOwnProperty( 'dataDefs' )) {
                    data.dataDefs = templateData.dataDefs;
                }
                if ( progress.eventData
                    && progress.eventData.hasOwnProperty( 'type' )
                    && progress.eventData.type === 'duplicate' ) {
                    data.name = templateData.name + ' - Copy';
                }
                if ( templateData.libraries ) {
                    data.libraries = templateData.libraries;
                }
                if ( templateData.eData ) {
                    data.eData = templateData.eData;
                }
                if ( templateData.templateDef ) {
                    data.templateDef = templateData.templateDef;
                }
            }
        }
        delete data.templateId;
        return data;
    }
}
