import { Injectable } from '@angular/core';
import { Modifier } from '@creately/rxdata';
import { Proxied } from '@creately/sakota';
import { CommandInterfaces, CommandError, Command } from 'flux-core';
import { AbstractModelChangeCommand } from 'flux-store';
import { Observable } from 'rxjs';
import { ProjectModel } from '../model/project.mdl';

/**
 * AbstractProjectChangeCommand
 *
 * This is the base abstract class which can be extended by all project model
 * change commands. To create a new model change command, extend this command
 * and add getters for `modifer` and `reverter`.
 */
@Injectable()
@Command()
export abstract class AbstractProjectChangeCommand extends AbstractModelChangeCommand {
    /**
     * This command implements the 'IProjectCommand' interface. This will trigger
     * the SetProjectIdExecutionStep. Therefore, the project id is not required
     * when calling these commands.
     *
     *      Example:
     *      commandService.dispatch( event, data );
     *
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IModelChangeCommand', 'IProjectCommand' ];
    }

    public changeModel: Proxied<ProjectModel>;

    /**
     * modifier
     * modifier returns the modifier of the model change available in this command.
     *
     * Example:
     *  { $set: { x: 1 } }
     */
    public abstract get modifier(): Modifier;

    /**
     * reverter
     * reverter returns the inverse modifier of the model change available in this command.
     *
     * Example:
     *  { $set: { x: 0 } }
     */
    public abstract get reverter(): Modifier;

    /**
     * Returns an observable which emits the proxied diagram model once.
     */
    public abstract prepareProxy(): Observable<Proxied<ProjectModel>>;

    /**
     * execute
     * execute returns true to set it as successful. This makes this method optional.
     */
    public execute() {
        return true;
    }

    /**
     * onError
     * onError checks whether the error is a network related error and ignores them.
     */
    public onError( err: CommandError ) {
        if ( err.name !== 'ConnectionError' ) {
            throw err;
        }
    }
}

Object.defineProperty( AbstractProjectChangeCommand, 'name', {
    value: 'AbstractProjectChangeCommand',
});
