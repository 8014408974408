import { AbstractModelSubscription } from 'flux-subscription';
import { AbstractModel } from 'flux-core';
import { UserSub } from './user.sub';
import { UserInfoModel } from '../model/user-info.mdl';

/**
 * User info subscription.
 * Allows sending and recieving user data at the UserInfo level.
 *
 * Since this inherits AbstractModelSubscription which is currentinly in nucleus,
 * all subscription related classes need to be first moved to flux-subscription package.
 * flux-subscription will have dependencies to flux-store and flux-connection.
 *
 * @author  Ramishka
 * @since   2018-01-23
 */
export class UserInfoSub extends AbstractModelSubscription {

    /**
     * Returns the subscription type
     */
    public get subscription(): string {
        return 'UserInfo';
    }

    /**
     * Returns the model type this subscription is associated with
     */
    public get modelType(): typeof AbstractModel {
        return UserInfoModel;
    }

    /**
     * Returns the alternative subscriptions that can be used in place of this
     * subscription. These are subscription classes that are lower in the inheritance
     * tree than user-info subscription.
     */
    public get alternatives(): ( typeof AbstractModelSubscription )[] {
        return [ UserSub ];
    }
}
