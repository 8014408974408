import { Observable, fromEvent } from 'rxjs';
import { map, take } from 'rxjs/operators';

export enum FileImportTypes {
    ImageImport = 'image-import',
    DragAndDrop = 'drag-and-drop',
    PasteEvent = 'paste-event',
}

/**
 * This class represents a file imported into nucleus.
 * An imported file instance has the raw file data (Blob)
 * but offers additional helpers methods to extract properties
 * as well as file data in various forms for a given file.
 *
 * @author  Ramishka
 * @since   2019-01-28
 */
export class ImportedFile {

    /**
     * This variable holds the way that this file imported.
     * whether using image importer, drag and drop or by pasting.
     */
    protected _importType: FileImportTypes;

    constructor( protected _file: File ) {
    }

    /**
     * Returns the file type
     */
    public get type(): string {
        return this.file.type;
    }

    /**
     * Content type of the imported file
     */
    public get name(): string {
        return this.file.name;
    }

    /**
     * Returns the size of the imported file.
     * This value is in bytes.
     */
    public get size(): number {
         return this.file.size;
    }

    /**
     * Returns the imported file.
     */
    public get file(): File {
        return this._file;
    }

    /**
     * Returns the extension of the imported file.
     */
    public get extension(): string {
        if ( this.file.name ) {
            const parts = this.file.name.split( '.' );
            if ( parts.length > 1 ) {
                return parts.pop();
            }
        }
    }

    /**
     * Sets the import type.
     */
    public set importType( type: FileImportTypes ) {
        this._importType = type;
    }

    /**
     * Returns the import type.
     */
    public get importType(): FileImportTypes {
        return this._importType;
    }

    /**
     * Returns an observable that will emit the files content
     * as a data: URL. In other words, this observable will emit
     * a Base64 encoded string of the files contents.
     * @return observable that will emit once with file data
     * and complete
     */
    public getAsDataURL(): Observable<string> {
        const reader = this.getFileReader();
        const observer = this.getReadObserver( reader );
        reader.readAsDataURL( this.file );
        return observer;
    }

    /**
     * Returns an observable that will emit the files content
     * as text.
     * @return observable that will emit once with file data
     * and complete
     */
    public getAsText(): Observable<string> {
        const reader = this.getFileReader();
        const observer = this.getReadObserver( reader );
        reader.readAsText( this.file );
        return observer;
    }

    /**
     * Returns an observable that will emit once and complete when
     * the file is read.
     * @param reader
     */
    private getReadObserver( reader: FileReader ): Observable<any> {
        return fromEvent( reader, 'loadend' ).pipe(
            map( progressEvent => ( progressEvent.currentTarget as FileReader ).result ),
            take( 1 ),
        );
    }

    /**
     * Returns a new FileReader instance.
     */
    private getFileReader(): FileReader {
        return new FileReader();
    }
}

