import { isObject, isEmpty } from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, of, combineLatest, from } from 'rxjs';
import { Database, Selector, Modifier, FindOptions, Collection } from '@creately/rxdata';
import { AbstractModel, ReferenceBuilder, Deserializer } from 'flux-core';
import { ModelStore } from './model-store';
import { take, mapTo, map, tap, switchMap, ignoreElements } from 'rxjs/operators';

/**
 * DataStore
 * DataStore service is responsible for performing storage related
 * operations for all models in the applications. Model data will be
 * retrieved or modified only through this service.
 */
@Injectable()
export class DataStore {
    /**
     * modelStoreMap
     * modelStoreMap contains a map of model types => model store classes.
     */
    protected modelStoreMap: Map<typeof AbstractModel, ModelStore>;

    /**
     * constructor
     * constructor stores the database instance
     */
    constructor( protected database: Database, protected refBuilder: ReferenceBuilder ) {
        this.modelStoreMap = new Map();
    }

    /**
     * getModelStore
     * getModelStore returns a ModelStore instance for given model type
     */
    public getModelStore( type: typeof AbstractModel ): ModelStore {
        if ( this.modelStoreMap.has( type )) {
            return this.modelStoreMap.get( type );
        }
        const modelStore = new ModelStore( type, this.database );
        this.modelStoreMap.set( type, modelStore );
        return modelStore;
    }

    /**
     * setModelStore
     * setModelStore registers a model store class for a particular
     * model type. If a model store class is already set, overrides it.
     */
    public setModelStore( type: typeof AbstractModel, storage: ModelStore ) {
        this.modelStoreMap.set( type, storage );
    }

    /**
     * getCollection
     * getCollection returns the Collection associated with a model type.
     */
    public getCollection( type: typeof AbstractModel ): Collection<any> {
        const modelStore = this.getModelStore( type );
        return modelStore.collection;
    }

    /**
     * drop
     * drop removes all documents and collections which belongs to this database.
     */
    public drop(): Observable<void> {
        const promise = this.database.drop();
        return this.emptyObservable( promise );
    }

    /**
     * find
     * find creates an observable which emits models which match a given selector.
     * Data will be fetched from the collection and deserialized into models before emitting.
     */
    public find( type: typeof AbstractModel, selector: Selector = {}, options: FindOptions = {}): Observable<any[]> {
        return this.callMethod( 'find', type, selector, options ).pipe(
            switchMap( models => this.addRelationshipMany( type, models as any[])),
        );
    }

    /**
     * findRaw
     * find creates an observable which emits plain js documents which match a given selector.
     */
    public findRaw(
        type: typeof AbstractModel,
        selector: Selector = {},
        options: FindOptions = {}): Observable<any[]> {
        return this.callMethod( 'findRaw', type, selector, options );
    }

    /**
     * findLatest
     * findLatest is similar to find but will only emit once.
     */
    public findLatest(
        type: typeof AbstractModel,
        selector: Selector = {},
        options: FindOptions = {}): Observable<any[]> {
        return this.find( type, selector, options ).pipe( take( 1 ));
    }

    /**
     * findOne
     * findOne creates an observable which emits the first model which match a given selector.
     * Data will be fetched from the collection and deserialized into models before emitting.
     */
    public findOne( type: typeof AbstractModel, selector: Selector = {}, options: FindOptions = {}): Observable<any> {
        return this.callMethod( 'findOne', type, selector, options ).pipe(
            switchMap( model => this.addRelationship( type, model )),
        );
    }

    /**
     * findOneRaw
     * findOneRaw creates an observable which emits the first document which match a given selector.
     */
    public findOneRaw(
        type: typeof AbstractModel,
        selector: Selector = {},
        options: FindOptions = {}): Observable<any> {
        return this.callMethod( 'findOneRaw', type, selector, options );
    }

    /**
     * findOneLatest
     * findOneLatest is similar to findOne but will only emit once.
     */
    public findOneLatest(
        type: typeof AbstractModel,
        selector: Selector = {},
        options: FindOptions = {}): Observable<any> {
        return this.findOne( type, selector, options ).pipe( take( 1 ));
    }

    /**
     * watch
     * watch creates an observable which emits rxdata collection changes.
     */
    public watch( type: typeof AbstractModel, selector: Selector = {}): Observable<any> {
        return this.callMethod( 'watch', type, selector );
    }

    /**
     * iwatch
     * insert stores given document in a collection associated with a model type
     */
    public insert( type: typeof AbstractModel, docOrDocs: any ): Observable<void> {
        const promise = this.callMethod( 'insert', type, docOrDocs );
        return this.emptyObservable( promise );
    }

    /**
     * update
     * update modifies matching documents in a collection associated with a model type
     */
    public update( type: typeof AbstractModel, selector: Selector, modifier: Modifier ): Observable<void> {
        const promise = this.callMethod( 'update', type, selector, modifier );
        return this.emptyObservable( promise );
    }

    /**
     * updateFields
     * updateFields modifies matching documents in a collection associated with a model type
     */
    public updateFields( type: typeof AbstractModel, selector: Selector, fields: any ): Observable<void> {
        return this.update( type, selector, { $set: fields });
    }

    /**
     * remove
     * remove removes matching documents in a collection associated with a model type
     */
    public remove( type: typeof AbstractModel, selector: Selector ): Observable<void> {
        const promise = this.callMethod( 'remove', type, selector );
        return this.emptyObservable( promise );
    }

    /**
     * addRelationship
     * addRelationship subscribes to related documents and adds them to appropriate fields.
     */
    protected addRelationship( type: typeof AbstractModel, model: any ) {
        if ( !model ) {
            return of( model );
        }
        const relationships = ( type.prototype as any ).relationshipProperties;
        if ( !relationships ) {
            return of( model );
        }
        const observables = Object.keys( relationships )
        .map( key => {
                if ( relationships[key].resultType === Array ) {
                    return this.addRelationshipArrayField( model, relationships, key );
                } else {
                    return this.addRelationshipField( type, model, relationships, key );
                }
            });
        return combineLatest( observables ).pipe( mapTo( model ));
    }

    /**
     * addRelationshipField
     * addRelationshipField subscribes to related documents and adds them to a specific field.
     */
    protected addRelationshipField( type: typeof AbstractModel, model: any, relationships: any, key: string ) {
        const { sourceType, resultType } = relationships[key];
        const relData = model[key];
        let observable: Observable<any>;
        if ( typeof relData === 'string' ) {
            observable = this.findOne( sourceType, { id: relData });
        } else if ( isObject( relData )) {
            observable = this.findOne( sourceType, { id: ( relData as any ).id }).pipe(
                map( relModel => relModel && relModel.extender( relData )),
            );
        } else {
            observable = of( null );
        }
        return observable.pipe(
            tap( relModel => {
                if ( !relModel || relModel instanceof resultType ) {
                    model[key] = relModel;
                } else {
                    model[key] = new resultType();
                    model[key].extender( relModel );
                }
            }),
        );
    }

    /**
     * Subscribes to related documents and adds them to an array type field.
     * @param model - model with the field to convert
     * @param relationships - object containing relationship data as consturcted by the decorator
     * @param key - model field name
     */
    protected addRelationshipArrayField( model: any, relationships: any, key: string ): Observable<any> {
        const { sourceType, instanceType } = relationships[key];
        const relData: any[] = model[key];
        const observables: Observable<any>[] = [];
        if ( relData && relData.length > 0 ) {
            relData.forEach(( currentData, index ) => {
                observables.push(
                    this.findOneRaw( sourceType, { id: currentData.id }).pipe(
                        switchMap( newData => this.mergeAndCreate( instanceType, currentData, newData ).pipe(
                            tap( data => {
                                model[key][index] = data;
                            }),
                            mapTo( model ),
                        )),
                    ),
                );
            });
        } else {
            observables.push( of( null ));
        }
        return combineLatest( ...observables );
    }

    /**
     * addRelationshipMany
     * addRelationshipMany subscribes to related documents and adds them to appropriate fields.
     */
    protected addRelationshipMany( type: typeof AbstractModel, models: any[]) {
        if ( !models || !models.length ) {
            return of( models );
        }
        const observables = models.map( model => this.addRelationship( type, model ));
        return combineLatest( observables );
    }

    /**
     * callMethod
     * callMethod calls a ModelStore instance method for given model type
     */
    protected callMethod( method: keyof ModelStore, type: typeof AbstractModel, ...args: any[]): any {
        const modelStore: ModelStore = this.getModelStore( type );
        return modelStore[method]( ...args );
    }

    /**
     * emptyObservable
     * emptyObservable creates an empty observable with a promise.
     * The observable will complete when the promise resolves.
     */
    protected emptyObservable( promise: Promise<any> ): Observable<void> {
        return from( promise ).pipe( ignoreElements());
    }

    /**
     * Merges a given set of data together and deserializes the
     * merged data into a given model type.
     * @param type - type of abstract model to create
     * @param data - data that will be merged and used for model creation
     * @return observable that emits the newly created model instance
     */
    protected mergeAndCreate( type: typeof AbstractModel, ...data: any[]): Observable<any> {
        const mergedData = Object.assign({}, ...data );
        if ( isEmpty( mergedData )) {
            return of( mergedData );
        } else {
            return this.deserialize( mergedData, type );
        }
    }

    /**
     * Deserializes a single document into a model.
     */
    protected deserialize( doc: any, type: typeof AbstractModel ): Observable<any> {
        if ( !doc || !isObject( doc ) || !type ) {
            return of( doc );
        }
        const decoder = new Deserializer( doc );
        return decoder.structure( type ).build();
    }
}
