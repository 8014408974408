import { minBy } from 'lodash';
import { Line } from './line';
import { Curve } from './curve';
import { IPoint2D, IPath, IPointCurve } from 'flux-definition';

/**
 * A generic CurvePath class used for calculations related to
 * path created with bezier curves
 */
export class CurvePath implements IPath {

    /**
     * Cache all the curves of the curve path
     */
    protected curves: Array<Curve>;

    constructor( public points: Array<IPointCurve> ) {
        this.createCurves();
    }

    /**
     * Returns the last first point of the path
     */
    public get first(): IPointCurve {
        return this.points[ 0 ];
    }

    /**
     * Returns the last point of the path
     */
    public get last(): IPointCurve {
        return this.points[ this.points.length - 1 ];
    }

    /**
     * Returns the total length of the path
     */
    public get length() {
        // let from = this.points[0];
        let length = 0;

        for ( let i = 0; i < this.points.length; i++ ) {
            const to = this.points[ i + 1 ];
            if ( to ) {
                const curve = this.curves[ i ];
                length += curve.length;
                // from = to;
            } else {
                break;
            }
        }
        return length;
    }

    /**
     * Calculates the coordinates of the point on the given straigth lines path at the
     * given position. Position is defined by some proportion along the length of the path.
     *
     * @param ratio number  A decimal in the range [0-1]
     *                      which indicates a point some proportion along the length of the path
     *
     * @param start boolean The point to start calculation from, by default it measures
     *                      fron the start point,if false, it measures from the end point
     */
    public split( pos: number, start: boolean = true ): IPoint2D {
        const location = this.length * pos;
        return this.splitByLength( location, start );
    }

    /**
     * Calculates the coordinates of the point on the curve path at given length.
     *
     * @param location number A pixel value indicating the length along the curve
     * @param start boolean   The point to start calculation from, by default it measures
     *                        from the start point,if false, it measures from the end point
     */
    public splitByLength( _location: number, start: boolean = true ): IPoint2D {
        const l = this.length;
        const location = start === false ? l - _location : _location;
        if ( location === 0 || l === 0 ) {
            return this.first;
        }
        if ( location === l ) {
            return this.last;
        }
        const { curve, diff } = this.curveAtLocation( location );
        return curve.getPointByLength( diff, true );
    }

    /**
     * Calculates the nearest point to the given point on the curve.
     * returns the point and length to that point
     * @param The test point
     * @param { point: IPoint2D, length: number } The nearest point cordinates and the length fraction along the curve
     */
    public getNearestPoint( point: IPoint2D ): { point: IPoint2D, location: number } {
        const curveData = this.curves.map(( curve, index ) => {
            const nearestData = curve.getNearestPoint( point );
            const pointOnCurve = nearestData.point;
            const lineLength = Line.from( point.x , point.y, pointOnCurve.x, pointOnCurve.y ).length();
            return { index, length: lineLength, nearestData };
        });

        const indexOfClosestCurve = minBy( curveData, 'length' ).index;
        const closestCurve = this.curves[ indexOfClosestCurve ];

        let distance = 0;
        for ( let index = 0; index < indexOfClosestCurve; index++ ) {
            distance = distance + this.curves[ index ].length;
        }

        const position =  curveData[ indexOfClosestCurve ].nearestData;
        const length = position.location * closestCurve.length;

        return {
            point: position.point,
            location: ( distance + length ) / this.length,
        };
    }

    /**
     * Create the curves from the points and store them in the curves array
     */
    private createCurves() {
        this.curves = [];
        let from = this.points[0];
        for ( let i = 0; i < this.points.length; i++ ) {
            const to = this.points[ i + 1 ];
            if ( to ) {
                this.curves.push( Curve.fromPoints( from, to ));
                from = to;
            } else {
                break;
            }
        }
    }

    /**
     * The curve path is made of multiple curve segments, this method returns the curve
     * which contains the given location and how far along the curve is the location.
     * @param location number A pixel value indicating the length along the curve
     * @return { curve: Curve, diff: number } The curve segment and the length to location
     */
    private curveAtLocation( location: number ): { curve: Curve, diff: number } {
        const pathLength = this.length;
        if ( location <= 0 ) {
            return { curve: this.curves[0], diff: location };
        }
        if ( location >= pathLength ) {
            const lastCurve = this.curves[ this.curves.length - 1 ];
            return { curve: lastCurve, diff: lastCurve.length + location - pathLength };
        }
        let len = 0;
        let curve: Curve;
        for ( let i = 0; i < this.curves.length; ++i ) {
            const currentCurve = this.curves[i];
            const curveLength = currentCurve.length;
            if ( len + curveLength >= location ) {
                curve = currentCurve;
                break;
            }
            len += curveLength;
        }
        return { curve, diff: location - len };
    }
}
