import { AbstractShapeModel } from 'flux-diagram-composer';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * RemoveEdata
 * This commad is to detach edata from all the shapes in the document
 */
@Injectable()
@Command()
export class RemoveEdata extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        eDataId: string,
    };

    public prepareData() {
        this.changeModel.getShapesByEdataId( this.data.eDataId ).forEach(( shape: AbstractShapeModel ) => {
            delete shape.eData;
            delete shape.triggerNewEData;
            delete shape.entityDefId;
        });
        if ( this.changeModel.eData && this.changeModel.eData.length > 0 ) {
            this.changeModel.eData = this.changeModel.eData
                .filter( eid => this.data.eDataId !== eid );
        }
    }

}

Object.defineProperty( RemoveEdata, 'name', {
    value: 'RemoveEdata',
});
