import { IPoint2D, ILineStyle, IRectangle } from 'flux-definition';
import { IGraphics } from 'flux-definition';
import { IDrawable, IDrawOptions } from 'flux-definition';
import { IMatrix } from 'flux-definition';

/**
 * Defines the options object which will be passed to all arrow heads.
 */
export interface IDrawArrowheadOptions  extends IDrawOptions {
    matrix: IMatrix;
    bounds: IRectangle;
    style: ILineStyle;
}

/**
 * This is an abstract form of a differnet types of arrow heads.
 * Provides many helper methods which should be used when drawing arrow heads.
 */
/* istanbul ignore next */
export abstract class AbstractArrowHead implements IDrawable {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public abstract drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void;

    /**
     * Transforms the given point as per the matrix given and
     * start drawing from the tranformed point
     */
    protected moveTo( graphics: IGraphics, point: IPoint2D, matrix: IMatrix ) {
        const transformPoint: IPoint2D = matrix.transform( point.x, point.y );
        graphics.moveTo( transformPoint.x, transformPoint.y );
    }

    /**
     * Transforms the given point as per the matrix given and
     * draws curve using the transformed points
     */
    protected curveTo( graphics: IGraphics, cp: IPoint2D, point: IPoint2D, matrix: IMatrix ) {
        const transformedCp: IPoint2D = matrix.transform( cp.x, cp.y );
        const transformedPoint: IPoint2D = matrix.transform( point.x, point.y );
        graphics.curveTo( transformedCp.x, transformedCp.y, transformedPoint.x, transformedPoint.y );
    }

    /**
     * Transforms the given point as per the matrix given and
     * draws a circle using the transformed points
     */
    protected drawCircle( graphics: IGraphics, cp: IPoint2D, radius: number, matrix: IMatrix ) {
        const transformedCp: IPoint2D = matrix.transform( cp.x, cp.y );
        // FIXME Radius must be transformed using the matrix
        graphics.drawCircle( transformedCp.x, transformedCp.y, radius );
    }

    /**
     * Transforms the given point as per the matrix given and
     * draws a line to transformed point
     */
    protected lineTo( graphics: IGraphics, point: IPoint2D, matrix: IMatrix ) {
        const transformPoint: IPoint2D = matrix.transform( point.x, point.y );
        graphics.lineTo( transformPoint.x, transformPoint.y );
    }
}
