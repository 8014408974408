import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { UserLocator } from 'flux-user';
import { EMPTY, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { TaskLocator } from '../task-locator.svc';
import { TaskModel } from 'flux-diagram';

@Injectable()
@Command()
/**
 * This command will align the task for the user
 * - check if the task exists for the given shape/context
 * - update the existing task with new assignes. if no assignees, delete the task
 * - update the tasks status/ owner details
 */
export class AddTask extends AbstractMessageCommand {
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    public data: {
        task: {
            id: string,
            title: string,
            diagramId: string,
            shapeId: string,
            entityId?: string,
            edataId?: string,
            creatorId: string,
            roles: { userId: string; lastUpdated: number }[],
            dueDate: number,
            estimateHrs: number,
            estimatePts: number,
            isDelete: boolean,
            sourceId: string,
        },
    };

    constructor(
        protected userLocator: UserLocator,
        protected taskLocator: TaskLocator,
        protected translate: TranslateService,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public execute() {
        if ( this.data.task ) {
            // const currUserId = this.state.get( 'CurrentUser' );
            // const userIds = this.data.task.roles.map( r => r.userId );
            // if ( !userIds.includes( currUserId )) {
            //     return EMPTY;
            // }
            return this.dataStore.findOne( TaskModel, { id: this.data.task.id })
                .pipe(
                    take( 1 ),
                    filter( model => !model ),
                    switchMap(() => this.dataStore.insert( TaskModel, this.data.task )),
                );
        }
    }

    public executeResult() {
        if ( this.resultData.task ) {
            const data = { ...this.resultData.task };
            // const currUserId = this.state.get( 'CurrentUser' );
            // const userIds = data.roles.map( r => r.userId );
            // if ( !userIds.includes( currUserId )) {
            //     return EMPTY;
            // }
            return this.dataStore.insert( TaskModel, data );
        } else {
            return EMPTY;
        }
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        if ( this.data && this.data.task ) {
            return this.dataStore.remove( TaskModel, { id: this.data.task.id });
        }
    }


}

Object.defineProperty( AddTask, 'name', {
    value: 'AddTask',
});
