import { DataType } from 'flux-definition';
import { take, map } from 'rxjs/operators';
import { Random, Tracker } from 'flux-core';
import { Subject, BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, Output, ElementRef,
    Input, AfterViewInit, OnDestroy } from '@angular/core';

/**
 * SingleSelectComboCreator
 * This component is to enter/update the options for single select combo ui control
 *
 * @author thisun
 * @since 2021-01-19
 */
@Component({
    selector: 'single-select-combo-creator',
    template: `
        <div #singleSelectCombo class="single-select-combo-creator-container" >
            <div class="combo-options-name fx-justify-space-between">
                <!-- <a class="primary combo-options-cancel" (click)="closeAndResetComboOptions()">
                    <svg class="nu-icon"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-arrow-back"></use></svg>
                </a> -->
                <input *ngIf="type==='create'" class="creator-label" value="Dropdown">
                <input *ngIf="type==='update'" class="creator-label" value="{{dataItem.label}}">
                <div class="close-btn" (click)="closeAndResetComboOptions()">
                    <svg class="nu-icon nu-icon-med"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
                </div>
            </div>
            <div class="combo-options-add text-input-item" >
                <label class="caption" translate>SHAPE_DATA.DATA_ITEMS.SINGLE_SELECT_COMBO.OPTIONS_TITLE</label>
                <div #singleSelectComboOptionsContainer class="combo-options-container" ></div>
                <button class="btn-small btn-secondary add-an-option combo-btn" (click)="addComboOption()" >
                    <span translate class="caption">SHAPE_DATA.DATA_ITEMS.SINGLE_SELECT_COMBO.ADD_NEW</span></button>
            </div>
            <div class="combo-options-footer" >
                <button *ngIf="type==='update' && canCommit | async" class="btn-small btn-secondary combo-btn caption"
                    (click)="updateComboOptions()" translate>SHAPE_DATA.DATA_ITEMS.SINGLE_SELECT_COMBO.DONE_BUTTON</button>
                <button *ngIf="type==='create' && canCommit | async" class="btn-small btn-secondary combo-btn caption"
                    (click)="createComboOptions()" translate>SHAPE_DATA.DATA_ITEMS.SINGLE_SELECT_COMBO.DONE_BUTTON</button>
            </div>
        </div>
    `,
    styleUrls: [ './single-select-combo-creator.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SingleSelectComboCreator implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @ViewChild( 'singleSelectCombo', { read: ElementRef, static: false })
    public singleSelectCombo: ElementRef;

    @ViewChild( 'singleSelectComboOptionsContainer', { read: ElementRef, static: false })
    public singleSelectComboOptionsContainer: ElementRef;

    /**
     * This component can add new or update combo options, this property is to
     * specify if component should update or create options
     */
    @Input()
    public type: 'create' | 'update' = 'create';

    /**
     * SingleSelectCombo data item
     */
    @Input()
    public dataItem: any;

    /**
     * SingleSelectCombo data item
     */
    @Output()
    public change: Subject<any>;

    @Output()
    public closed: Subject<any>;

    /**
     * "Done" button should be visible only if there is at least one
     * non-empty option, this BehaviorSubject subject to control the visibility
     * of the done button.
     */
    public canCommit: BehaviorSubject<boolean>;

    protected subs: Subscription[];

    public constructor() {
        this.subs = [];
        this.change = new Subject();
        this.closed = new Subject();
        this.canCommit = new BehaviorSubject( false );
    }

    public get singleSelectComboElement(): HTMLElement {
        return this.singleSelectCombo.nativeElement as HTMLElement;
    }

    public get singleSelectComboOptionsContainerElement(): HTMLElement {
        return this.singleSelectComboOptionsContainer.nativeElement as HTMLElement;
    }

    public show() {
        this.singleSelectComboElement.style.display = 'block';
    }

    /**
     * Hide the component
     */
    public hide() {
        this.singleSelectComboElement.style.display = 'none';
    }

    public ngAfterViewInit() {
        this.hide();
        if ( this.type === 'update' ) {
            // load values into creator
            ( this.dataItem.options || []).forEach( opt => this.addComboOption( opt.id, opt.value ));
        }
        this.subs.push(
            this.checkOptions().subscribe( v => this.canCommit.next( v )),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Handler function of the "Done" button for the 'Create' scenario
     */
    public createComboOptions() {
        const data = [];
        const label = ( this.singleSelectComboElement.querySelector( '.creator-label' ) as HTMLInputElement )
            .value;
        this.singleSelectComboOptionsContainerElement.querySelectorAll( `.combo-option` )
            .forEach( el => {
                const input = el.querySelector( '.combo-option-name' ) as HTMLInputElement;
                if ( input.value ) {
                    data.push({
                        id: input.value,
                        value: input.value, label: input.value,
                        buttonLabel: input.value });
                }
            });
        if ( data.length ) {
            this.change.next( this.getDataItem( data, label ));
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.dropdown.click`,
                { value1Type: 'action', value1: 'Done' });
            }
            this.closeAndResetComboOptions();
        }
    }

    /**
     * Handler function of the "Done" button for the 'Create' scenario
     */
    public updateComboOptions() {
        const data = [];
        const label = ( this.singleSelectComboElement.querySelector( '.creator-label' ) as HTMLInputElement )
            .value;
        this.singleSelectComboOptionsContainerElement.querySelectorAll( `.combo-option` )
            .forEach( el => {
                const input = el.querySelector( '.combo-option-name' ) as HTMLInputElement;
                if ( input.value ) {
                    data.push({
                        id: input.value,
                        value: input.value, label: input.value,
                        buttonLabel: input.value });
                }
            });
        if ( data.length ) {
            const index = this.dataItem.options.findIndex( o => o.value === this.dataItem.value );
            if ( index > -1 && data[index] && data[index].value !== this.dataItem.value ) {
                this.dataItem.value = data[index].value;
            }
            this.change.next( Object.assign( this.dataItem, { options: data, label }));
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.dropdown.click`,
                { value1Type: 'action', value1: 'Done' });
            }
            this.closeAndResetComboOptions();
        }
    }

    public closeAndResetComboOptions() {
        this.hide();
        this.singleSelectComboOptionsContainerElement.innerHTML = '';
        this.canCommit.next( false );
        this.closed.next( true );
    }

    /**
     * Handler function for "+ Add combo Option" button.
     * Adds a new input element.
     */
    public addComboOption( id = Random.dataItemId(), value = '' ) {
        Tracker.track( `${this.context}.dropdown.click`,
        { value1Type: 'action', value1: 'Add an option' });
        const el = document.createElement( 'div' );
        el.innerHTML = `
            <div data-id="${id}" class="combo-option">
                <input class="combo-option-name text-input" value="${value}">
                <div class="combo-option-remove" >x</div>
            </div>
        `;
        fromEvent( el.querySelector( '.combo-option-remove' ), 'click' ).pipe(
            take( 1 ),
        ).subscribe(() => {
            this.removeComboOption( id );
        });
        this.singleSelectComboOptionsContainerElement.appendChild( el );
    }

    /**
     * Handler function for "X" button.
     * Removes the input element.
     */
    protected removeComboOption( id: string ) {
        const el = this.singleSelectComboOptionsContainerElement.querySelector( `.combo-option[data-id="${id}"]` );
        if ( el ) {
            el.remove();
            this.canCommit.next( true );
        }
    }

    /**
     * This observable emits true/false as the user types in the option inputs.
     * Returns false if all the inputs become empty.
     */
    protected checkOptions() {
        return fromEvent( this.singleSelectComboOptionsContainerElement, 'keyup' ).pipe(
            map( e => {
                let visible = false;
                this.singleSelectComboOptionsContainerElement.querySelectorAll( '.combo-option-name' )
                    .forEach(( el: HTMLInputElement ) => {
                        if ( el.value.trim()) {
                            visible = true;
                            return false;
                        }
                    });
                return visible;
            }),
        );
    }

    /**
     * Create a new combo data item
     */
    private getDataItem( data, label ) {
        data[0].selected = true;
        return {
            id: Random.dataItemId(),
            type: DataType.OPTION_LIST,
            layout: 'block',
            value: data[0].value,
            options: data,
            label,
            labelEditable: true,
            optional: true,
            def: 'singleSelectCombo',
            visibility: [
                { type: 'editor' },
            ],
            validationRules: {},
        };
    }
}
