import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces, Command } from 'flux-core';
import { EMPTY, Observable, merge } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { ResourceModelStore } from '../../storage/resource-model.store';
import { tap } from 'rxjs/operators';

/**
 * This is the command to remove group shares from diagram.
 *
 * This extends {@link AbstractMessageCommand} to send a request to the server.
 *
 */
@Injectable()
@Command()
export abstract class RemoveResourceGroupShare extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            groupIds: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.getModelStore();
        return modelStore.getGroupShare( this.resourceId ).pipe(
            tap( groupShares => {
                this.previousData.groupShare = groupShares.filter(
                    groupShare => this.data.groupIds.indexOf( groupShare.id ) !== -1 );
            }),
        );
    }

    /**
     * execute
     * execute remove group share from diagram model
     */
    public execute(): Observable<any> {
        const modelStore = this.getModelStore();
        const filterFn = c => this.data.groupIds.indexOf( c.id ) > -1 ;
        return modelStore.storageFilterGroupShare( this.resourceId, filterFn );
    }

    /**
     * executeResult
     * According to the response add un removed group shares
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.getModelStore();
        let unRemovedGroups = [];

        // Check whether there are group shares exist without removing in the server side
        if ( this.previousData ) {
            unRemovedGroups = this.previousData.groupShare.filter( groupShare =>
                response.groupIds.indexOf( groupShare.id ) === -1 );
        }
        let addUnRemovedGroups: Observable<any>  = EMPTY;
        if ( unRemovedGroups.length > 0 ) {
            addUnRemovedGroups = modelStore.storageMergeGroupShares( this.resourceId, unRemovedGroups );
        }

        const filterFn = c => response.groupIds.indexOf( c.id ) > -1 ;
        const removeGroups = modelStore.storageFilterGroupShare( this.resourceId, filterFn );

        return merge( addUnRemovedGroups, removeGroups );
    }

    public revert(): Observable<any> {
        const modelStore = this.getModelStore();
        return modelStore.storageMergeGroupShares( this.resourceId, this.previousData.groupShare );

    }

    protected abstract getModelStore(): ResourceModelStore;
}
