import { Modifier } from '@creately/rxdata';
import { Proxied } from '@creately/sakota';
import { AbstractCommand, AbstractModel, Command } from 'flux-core';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';

/**
 * AbstractModelChangeCommand
 * AbstractModelChangeCommand is a base class all commands which implement
 * the IModelChangeCommand interface should extend. To create a model change
 * command, it is recommended to create an abstract command for the model type
 * and create specific commands extending that command. Use the prepare data
 * hook to make changes to the model. Example:
 *
 *      class AbstractDiagramChangeCommand extends AbstractModelChangeCommand {
 *          public static get modelType() {
 *              return DiagramModel;
 *          }
 *          public prepareProxy() {
 *              return this.locator.getDiagram().pipe(
 *                  take(1),
 *                  map(model => Sakota.create( model )),
 *              );
 *          }
 *      }
 *
 *      class RenameDiagram extends AbstractDiagramChangeCommand {
 *          public prepareData() {
 *              this.changeModel.name = this.data.name;
 *          }
 *      }
 *
 */
@Command()
export class AbstractModelChangeCommand extends AbstractCommand {
    /**
     * Data required by model change commands is available inside the change model.
     */
    public static get dataDefinition(): {}  {
        return {};
    }

    /**
     * modelType
     * model type is the type of the model expected to be used inside the command.
     */
    public static get modelType(): typeof AbstractModel {
        return null;
    }

    /**
     * changeModel
     * changeModel is a proxied model object which can record changes done to it.
     */
    public changeModel: Proxied<AbstractModel>;

    /**
     * modifier
     * modifier returns the modifier of the model change available in this command.
     *
     * Example:
     *  { $set: { x: 1 } }
     */
    public get modifier(): Modifier {
        return null;
    }

    /**
     * reverter
     * reverter returns the inverse modifier of the model change available in this command.
     *
     * Example:
     *  { $set: { x: 0 } }
     */
    public get reverter(): Modifier {
        return null;
    }

    /**
     * prepareProxy
     * prepareProxy prepares the proxied model where changes will be applied.
     */
    public prepareProxy(): Observable<Proxied<AbstractModel>> {
        return of( null );
    }

    /**
     * execute
     * execute returns true if and only if the modifier has any changes.
     */
    public execute() {
        return !isEmpty( this.modifier );
    }
}

Object.defineProperty( AbstractModelChangeCommand, 'name', {
    writable: true,
    value: 'AbstractModelChangeCommand',
});
