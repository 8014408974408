import { SimpleShapeModel } from 'flux-diagram/models';
import { Rectangle, Random, enumerable } from 'flux-core';
import { IText, ITextStyles, IServices, IShapeImage, IDataItem, DataType, ITextParser, IShapeOrigins } from 'flux-definition';
import { toArray } from 'lodash';
import { ShapeServices } from '../../framework/shape-service';

/**
 * AbstractShapeModel is the abstract version of a Shape and a Connector
 * that is representable with their full data. This naturally holds common
 * propeties/capabilities of Shapes and Connectors. But most importantly holds
 * those propeties/capabilities that are specific to the full representations
 * of those models.
 */
export class AbstractShapeModel extends SimpleShapeModel {

    /**
     * The z-order of this shape in a diagram. will have a unique
     * index on the diagram which will represent its order. Larger
     * index are on top.
     */
    public zIndex: number = 0;

    /**
     * The key for the dataDefs map in the diagram model
     * dataDefs map is to keep the common properties of data items
     */
    public dataSetId: string;

    /**
     * Searchable tags of the shape
     */
    public tags: Array<string>;

    /**
     * Texts for the shape, single shape can have multiple texts
     * Each text contains data to position itself on the shape
     * and also the text and text styles as html.
     */
    public texts: { [id: string]: IText };

    /**
     * Shapes can have multiple embedded images.
     */
    public images?: { [id: string]: IShapeImage } = {};


    /**
     * Attachment ids. Attachments are availbale in the diagram model
     */
    public attachmentIds: string[] = [];

    /**
     * Enable tiptap feature
     */
    public enableTiptap: boolean = false;

    /**
     * if true will disable adding list items when adding -, +, *
     */
    public usesPlainText: boolean = false;

    /**
     * Enable tiptap feature
     */
    public visible: boolean = true;

    /**
     * The default text format for all the texts contained by the shape
     * When a new text is created for the text it should have this text format.
     * This property will be updated when the shape styles are updated
     */
    public defaultTextFormat: ITextStyles;

    /**
     * Shape switching history, this is used when the shape is switched back to
     * any previous def
     */
    public switchHistory: any;

    /**
     * Shapes can have custom data stored in them which are internally
     * referred to as data items. Data items may affect how the shape
     * gets rendered and how it interacts with other shapes. A data
     * items definition contains information as to where it will be
     * visible to the user and how it will be represented to the user.
     */
    public data: { [identifier: string]: IDataItem<DataType> } = {};

    /**
     * The representation of the entry class which will be used to draw the shape.
     * This is a URI like string having the js file which contain the entry class code
     * followed by a hash ( # ) and the name of the entryClass itself.
     * ie: awesome-shape.js#AwesomeShape
     */
    public entryClass: string;

    /**
     * Should adding this model to the canvas trigger a new
     * eData entity creation if a compatible EData model is present?
     */
    public triggerNewEData?: boolean;

    /**
     * This value indicate how shape got created or how it got originated.
     * EX: Drag and drop from shape Panel or Edata panel or Predef.
     */
    public origin?: IShapeOrigins;

    /**
     * EData references for the shape.
     * [{modelId: entityId}]
     */
    public eData?: { [modelId: string]: string };

    public eDataCandidates?: string[];

    /**
     * The def id on the entity if this shape is attached to a certain entity.
     * ( One shape can be bound to one entity )
     */
    public entityDefId?: string;

    /**
     * EDataRef references for the shape.
     * This means its connected to an entity even though it's
     * not an eData bound shape itself.
     * [{modelId: entityId}]
     */
     public eDataRef?: { [eDataId: string]: string[] };

    /**
     * EntityList reference for the shape.
     */
     public entityListId?: string;

     /**
      * EntityList reference for the shape.
      */
      public displayListId?: string;

    /**
     * EntityList reference for the shape.
     */
     public definedSearchQuery?: string;

    /**
     * Task references for the shape.
     * [{roleId: taskId}]
     */
    public taskMap?: {[roleId: string]: string};


    /**
     * If a theme style has been applied, the index of the style
     */
    public themeIndex?: number;

    /**
     * If a theme style has been applied, the theme id
     */
     public themeId?: string;

    /**
     * This is a template level shape that is not counted for the 'shapeUsageExceeded' on
     * DiagramAddShapesPermHandler.
     *
     * This allows templates to have more shapes than 60 and allow for additional use.
     *
     * These are marked as isTemplateShape = true, manually by the debugger.
     */
    public isTemplateShape?: boolean;

    /**
     * Creates a new model instance
     * @param id id of the shape
     * @param extension
     */
    public constructor( id: string, extension?: Object ) {
        super( id, extension )/* istanbul ignore next */;
    }

    /**
     * FIXME:
     * Currently we store the edata id and entity id in "eData" object
     * but it should be stored in two separate string properties
     * 'eDataId' and 'entityId'
     */
    public get eDataId() {
        return Object.keys( this.eData || {})[ 0 ];
    }

    public get entityId() {
        if ( this.eData ) {
            return this.eData[ this.eDataId ];
        }
    }

    /**
     * This getter returns true if the shape has at least
     * one non empty text
     */
    @enumerable( true )
    public get hasAnyText(): boolean {
        const texts: Array<IText> = toArray( this.texts );
        return texts.findIndex( t => t && !!t.plainText ) > -1;
    }

    /**
     * This getter returns true if the shape has at least
     * one image
     */
    @enumerable( true )
    public get hasAnyImage(): boolean {
        const images: Array<IShapeImage> = toArray( this.images );
        return images.findIndex( t => t && !!t.file ) > -1;
    }

    /**
     * This getter returns a non empty plain text from the texts object
     */
    @enumerable( true )
    public get previewText(): string {
        return this.previewTextModel ? this.previewTextModel.plainText : undefined;
    }

    /**
     * This getter returns a non empty text model from the texts object
     */
    @enumerable( true )
    public get previewTextModel(): IText {
        const texts: Array<IText> = toArray( this.texts );
        const text = texts.find( t => t && !!t.plainText );
        if ( !text ) {
            return;
        }
        return text;
    }

    /**
     * This getter returns the first text model
     */
    @enumerable( true )
    public get primaryTextModel(): IText {
        if ( this.texts ) {
            return ( toArray( this.texts ) as Array<IText> )
                .find( text => text && text.primary === true );
        }
    }

    /**
     * Must be overridden.
     * Supposed to return the current visible bounds of the shape
     * or connector on the diagram coordinate space.
     */
    @enumerable( true )
    public get bounds(): Rectangle {
        throw Error( 'AbstractShapeModel bounds cannot be used. Must be overridden' );
    }

    /**
     * The name of the entry class which will be used to draw the shape.
     * This is the last part of the entryClass string separated by hash ( # ).
     */
    @enumerable( true )
    public get entryClassName(): string {
        return this.entryClass.split( '#' ).pop();
    }

    /**
     * Returns the IServices object which contains functions which can
     * be used to provide many services required by the shape.
     */
    @enumerable( true )
    public get services(): IServices {
        return ShapeServices.instance;
    }

    /**
     * This abstract method returns the shape bounds considering the text bounds
     * Should be overidden
     */
    public getBoundsWithTexts() {
        return;
    }

    /**
     * Returns the text model for the given textId,
     * returns undefined if no text model found
     */
    public getTextExisting( textId: string ): IText {
        if ( this.texts ) {
            return this.texts[ textId ];
        }
    }

    /**
     * Returns the text models which has at least one hyperlink
     */
    public getTextsWithHyperlinks(): IText[] {
        const texts: Array<IText> = toArray( this.texts );
        return texts.filter( t => t && t.content.findIndex( c => !!c.link ) > -1 );
    }

    /**
     * This function returns true if non empty text exists for the
     * given identifier and returns false if no text found or empty text found
     * @param string The name (id) of the text view
     */
    public hasText( name: string ): boolean {
        if ( !this.texts ) {
            return false;
        }
        return !!this.texts[ name ];
    }

    /**
     * Returns the text model for the given text id, if the id is undefined
     * or no text model found for the given id, prmary text model will be returned
     * @param textId  string
     */
    public getText( textId: string ): IText {
        if ( !this.texts ) {
            return;
        }
        return this.texts[ textId ] || this.primaryTextModel;
    }

    /**
     * Returns the text model for the given text id, if the id is undefined
     * or no text model found for the given id, prmary text model will be returned
     * @param textId  string
     */
    public getTextParser( textId: string ): ITextParser {
        const text = this.getText( textId );
        return text && text.parserId ? this.services.getTextParser( text.parserId ) : null;
    }

    /**
     * This abstract method creates a new text model with a generated text id,
     * Should be overidden
     */
    public createText(): IText {
        return;
    }

    /**
     * Generates a uniqe string id that is not already available
     */
    protected generateTextId() {
        const id = Random.textId();
        if ( !this.texts ) {
            return id;
        } else if ( this.texts[ id ]) {
            return this.generateTextId();
        }
        return id;
    }

}
