import { ShapeModel } from './../../../base/shape/model/shape.mdl';
import { isEmpty, forEach, cloneDeep } from 'lodash';
import { StateService } from 'flux-core';
import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { IStyle, ITextFormat } from 'flux-definition';
import { Command, AbstractCommand } from 'flux-core';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
/**
 * AutocorrectTextColor
 * This will apply style properties which affect lines, fill and text color.
 */
@Injectable()
@Command()
export class AutocorrectTextColor extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        style: IStyle,
        format: { [id: string]: ITextFormat },
    };


    constructor(
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<any> {
        this.resultData = { format: cloneDeep( this.data.format ) };
        return this.ll.forCurrent( true ).getDiagramOnce().pipe(
            tap( diagram => {
                const style = this.data.style || {};
                const shapeIds: string[] = this.data.shapeIds === undefined
                            ? this.state.get( 'Selected' ) :  this.data.shapeIds;
                for ( const shapeId of shapeIds ) {
                    const shape = diagram.shapes[shapeId];
                    if ( !isEmpty( style )) {
                        this.autoCorrectTextColor( shape as ShapeModel );
                    }
                }
            }),
        );
    }

    /**
     * This method applies the text color considering the
     * preferredColor property of the text model
     */
    protected autoCorrectTextColor( shape: ShapeModel ) {
        forEach( shape.texts, ( v, id ) => {
            const text = shape.texts[ id ];
            if ( this.resultData.format && !isEmpty( this.resultData.format )) {
                if ( text.preferredColor === 'line' ) {
                    if ( !this.resultData.format[ id ]) {
                        this.resultData.format[ id ] = cloneDeep( this.resultData.format[ '*' ]);
                    }
                    this.resultData.format[ id ].styles.color = this.data.style.lineColor;
                } else if ( text.preferredColor === 'fill' ) {
                    if ( !this.resultData.format[ id ]) {
                        this.resultData.format[ id ] = cloneDeep( this.resultData.format[ '*' ]);
                    }
                    this.resultData.format[ id ].styles.color = this.data.style.fillColor;
                }
            }
        });
    }
}

Object.defineProperty( AutocorrectTextColor, 'name', {
    value: 'AutocorrectTextColor',
});
