/**
 * This service class created to manage Diagram Access Tokens - DAT.
 * For diagrams, If the password protected is enabled,
 * After the successful diagram authentication, A token will be returned by serve.
 * The token will be stored in a session store (to limit the validity to the tab only),
 * Through this service.
 *
 * @author Banujan<banujan@cinergix.com>
 * @since Apr-2024
 */
export class DiagramPasswordService {

    /**
     * SessionStore key prefix,
     * DAT (Diagram Access Token).
     */
    static readonly prefix = 'DAT';

    /**
     * Set a token to a diagram
     *
     * @param diagramId
     * @param token
     * @returns
     */
    static setToken( diagramId: string, token: string ) {
        return window.sessionStorage.setItem( `${DiagramPasswordService.prefix}_${diagramId}`, token );
    }

    /**
     * Get a token for a diagram
     * @param diagramId
     * @returns token string
     */
    static getToken( diagramId ): string {
        return window.sessionStorage.getItem( `${DiagramPasswordService.prefix}_${diagramId}` ) || '';
    }
}
