import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContextMenuController } from './context-menu-controller';

/**
 * This is responsible to display context menu in the UI Components.
 * For more details see the {@link ContextMenuController}
 */
@Injectable()
export class UIContextMenuController extends ContextMenuController {

    /**
     * This handle the right click events on teh UI compoenent and returns
     * the menu item.
     */
    protected getContextMenuItemsId( event: MouseEvent ): Observable<string[]> {
        // TODO: should traverse down the display list to see if any of the elements have the
        // getContextMenuItems method
        const cmp = event.target as any;
        if ( cmp.getContextMenuItems ) {
            return of( cmp.getContextMenuItems());
        }
        return of([]);
    }

}
