import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';
import { AddResourceGroupShare } from '../../group-share/command/add-resource-group-share.cmd';

/**
 * This is the command to remove group shares from diagram.
 *
 * This extends {@link AddResourceGroupShare} to send a request to the server.
 *
 */
@Injectable()
@Command()
export class AddProjectGroupShare extends AddResourceGroupShare  {

    protected getModelStore() {
        return this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
    }
}

Object.defineProperty( AddProjectGroupShare, 'name', {
    value: 'AddProjectGroupShare',
});
