import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from '../../../editor/diagram/command/abstract-diagram-change-command.cmd';

/**
 * DEBUGGER USE ONLY
 * RemoveShapeFromEdata
 * This command is to detach shapes from from all reference edata.
 */
@Injectable()
@Command()
export class RemoveShapeFromEdata extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
    };

    public prepareData() {
        if ( this.data.shapeIds && this.data.shapeIds.length > 0 ) {
            this.data.shapeIds.forEach( shapeId => {
                const shape = this.changeModel.shapes[ shapeId ];
                if ( shape ) {
                    delete shape.eData;
                    delete shape.triggerNewEData;
                    delete shape.entityDefId;
                }
            });
        }
    }
}

Object.defineProperty( RemoveShapeFromEdata, 'name', {
    value: 'RemoveShapeFromEdata',
});
