import { AbstractCommand, CommandInterfaces, Command, StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { KeyCode } from 'flux-definition';

/**
 * This ChangeSnapState cmd is used to
 * Enable / Disable Snapping on Ctrl key down / Up
 */
@Injectable()
@Command()
export class ChangeSnapState extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            keyboardEvent: true, // KeyboardEvent is checked to set the control state
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor(
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            SnapToGuides: this.data.snapToGuides,
            SnapToGuidesFromKey: this.data.snapToGuidesFromKey,
            ShowGuides: this.data.showGuides,
            ShowGuidesFromKey: this.data.showGuidesFromKey,
        };
    }

    public execute (): boolean {
        if ( this.data && this.data.keyboardEvent && this.data.keyboardEvent.keyCode === KeyCode.Control ) {
            if ( this.data.keyboardEvent.type === 'keydown' ) {
                this.data.showGuidesFromKey = !this.state.get( 'ShowGuides' );
                this.data.snapToGuidesFromKey = !this.state.get( 'SnapToGuides' );
                this.data.showGuides = true;
                this.data.snapToGuides = true;
            } else if ( this.data.keyboardEvent.type === 'keyup' ) {
                if ( this.state.get( 'ShowGuidesFromKey' )) {
                    this.data.showGuides = false;
                    this.data.showGuidesFromKey  = false;
                } else {
                    this.data.showGuides = true;
                    this.data.showGuidesFromKey  = true;
                }
                if ( this.state.get( 'SnapToGuidesFromKey' )) {
                    this.data.snapToGuides = false;
                    this.data.snapToGuidesFromKey  = false;
                } else {
                    this.data.snapToGuides = true;
                    this.data.snapToGuidesFromKey  = true;
                }
            }
            return true;
        }
        return false;
    }
}

Object.defineProperty( ChangeSnapState, 'name', {
    value: 'ChangeSnapState',
});
