import { Injectable } from '@angular/core';
import { Sakota } from '@creately/sakota';
import { TranslateService } from '@ngx-translate/core';
import { AbstractCommand, AbstractNotification, Command,
    CommandService, NotificationType, NotifierController, Random, ModalController, StateService } from 'flux-core';
import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, take, tap, filter } from 'rxjs/operators';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { Notifications } from '../../notifications/notification-messages';
import { EDataLocatorLocator } from '../locator/edata-locator-locator';
import { EntityListModel } from '../model/entity-list.mdl';
import { EDataCommandEvent } from './edata-command-event';
// import { ShapeModel } from '../../shape/model/shape.mdl';
import { CreateSavedSetDialog } from 'apps/nucleus/src/editor/view/create-saved-sets-dialog/create-saved-sets-dialog.cmp';

@Injectable()
@Command()
export class SaveFilteredResults extends AbstractCommand {
    public data: {
        eDataId: string;
        entityIds: string[];
        definedSearchQuery: string,
        skipNotification?: boolean;
    };

    public setName: BehaviorSubject<string> = new BehaviorSubject( '' );

    constructor(
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        private commandSvc: CommandService,
        private notifierController: NotifierController,
        private translate: TranslateService,
        private modalContoller: ModalController,
        private state: StateService<any, any>,
        ) {
        super();
    }

    public prepareData(): void | Observable<any> {
        return this.listenForNameChange();
    }

    execute(): boolean | Observable<any> {
        return this.modalContoller.show( CreateSavedSetDialog, {
            inputs: {
                setName: this.setName,
            },
        });
    }

    protected getDiagramModel() {
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
        );
    }

    protected listenForNameChange() {
        this.setName.pipe(
            filter( name => !!name ),
            tap( name => this.createSmartSet( name )),
        ).subscribe();
    }

    protected createSmartSet( name: string ) {
        this.getDiagramModel().pipe(
            switchMap( diagram => {
                const eDataId = this.data.eDataId;
                const definedSearchQuery = this.data.definedSearchQuery;
                return this.ell.getEDataModel( eDataId ).pipe(
                    tap( eDataModel => {
                        const entityListId = Random.entityListId();
                        const eDataSakotaModel = Sakota.create( eDataModel );
                        const newEntityListModel = new EntityListModel( entityListId, eDataModel.defId );
                        newEntityListModel.search = definedSearchQuery;
                        newEntityListModel.name = name;
                        if ( !eDataModel.entityLists ) {
                            eDataSakotaModel.entityLists = {};
                        }
                        eDataSakotaModel.entityLists[entityListId] = newEntityListModel;
                        newEntityListModel.entities = this.data.entityIds;
                        const dModel = Sakota.create( diagram );
                        this.commandSvc.dispatch( EDataCommandEvent.applyModifierEDataUser, eDataId, {
                            modifier: eDataSakotaModel.__sakota__.getChanges(),
                        });
                        this.commandSvc.dispatch( DiagramCommandEvent.applyModifierDocument, diagram.id, {
                            modifier: dModel.__sakota__.getChanges(),
                        });
                    }),
                );
            }),
        ).subscribe(() => {
            this.state.set( 'AcknowledgementMessage', { open: false, message: '', showLoader: false });
            const translate = this.translate.instant.bind( this.translate );
            const notificationData = {
                id: Notifications.CREATED_SAVED_SET,
                component: AbstractNotification,
                type: NotificationType.Success,
                collapsed: false,
                options: {
                    inputs: {
                        heading: translate( `NOTIFICATIONS.EDATA.CREATED_SAVED_SET.HEADING` ),
                        description: translate( `NOTIFICATIONS.EDATA.CREATED_SAVED_SET.DESCRIPTION` ),
                        autoDismiss: true,
                    },
                },
            };
            this.notifierController.show( Notifications.CREATE_SAVED_SET, notificationData.component,
                notificationData.type, notificationData.options, notificationData.collapsed );
        });
    }
}


Object.defineProperty( SaveFilteredResults, 'name', {
    value: 'SaveFilteredResults',
});
