import { Injectable } from '@angular/core';
import { Point, Line, Curve } from 'flux-core';
import { IConnectorPoint, IConnectorEndPoint } from 'flux-diagram-composer';
import { AbstractConnectorPather, IEndpointPairInfoForPathing } from './pather.i';

/**
 * Straight line pather creates connector paths using straight lines which can be drawn
 * in any direction. This pather is used by the straight connector.
 */
@Injectable()
export class PatherStraight extends AbstractConnectorPather {
    /**
     * Returns an array of line/curve segments for each point on given connector path.
     */
    public getSegments( points: IConnectorPoint[]): ( Line | Curve )[][] {
        if ( !points.length ) {
            return [];
        }
        const segments: Line[][] = [[]];
        for ( let i = 1; i < points.length; ++i ) {
            const pointA = points[i - 1];
            const pointB = points[i];
            segments.push([ new Line( pointA, pointB ) ]);
        }
        return segments;
    }

    /**
     * Creates a path from one point to another with current draw style.
     */
    protected createPath(
        endpoints: IEndpointPairInfoForPathing,
    ): IConnectorPoint[] {
        return [
            endpoints.pointA.point,
            endpoints.pointB.point,
        ];
    }

    /**
     * Corrects the given path so that it'll match the current draw style.
     * This can be used to correct errors in the path after modifications
     * and to convert the connector draw style from one to another.
     */
    protected adjustPath(
        endpoints: IEndpointPairInfoForPathing,
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        return currentPath.map( point => ({
            ...point,
            c1: null,
            c2: null,
            bumps: [[]],
        }));
    }

    /**
     * Reverses all points (including endpoints) on the connector path and
     * makes necessary changes to control points to make sure the path would
     * not change visually.
     */
    protected reversePath(
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        return currentPath.reverse();
    }

    /**
     * Sets the 'direction' property on connector endpoints. This value will
     * be stored on connector endpoints which is used when drawing arrow heads.
     * The direction values are angles to endpoints from adjacent points.
     */
    protected setDirections(
        currentPath: IConnectorPoint[],
    ): void {
        const first = currentPath[ 0 ] as IConnectorEndPoint;
        const last = currentPath[ currentPath.length - 1 ] as IConnectorEndPoint;
        const afterFirst = currentPath[ 1 ];
        const beforeLast = currentPath[ currentPath.length - 2 ];
        first.direction = Point.angleTo( afterFirst, first );
        last.direction = Point.angleTo( beforeLast, last );
    }
}
