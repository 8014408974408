import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { Observable } from 'rxjs';
import { filter, map, take, mergeMap } from 'rxjs/operators';
import { PresentationModel } from './model/presentation.mdl';
import { StateService } from 'flux-core';
import { UserLocator } from 'flux-user';

/**
 * Locator to fetch tasks for the current user
 *
 */

@Injectable()
export class PresentationLocator {

    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected userLocator: UserLocator,
    ) {}

    /**
     * Get presentations for the given folder the user has access to
     * @param project
     * @returns
     *
     * FIXME: Only store userids of collaborators for easier access.
     */
    public getPresentations( project: string ): Observable<PresentationModel[]> {
        const userId = this.state.get( 'CurrentUser' );
        return this.userLocator.getUserInfo( userId ).pipe(
            mergeMap( user => this.dataStore.find( PresentationModel, { projectId: project }).pipe(
                filter( slides => !!slides ),
                map( slides => slides.filter( s => !s.isDeleted )),
                map( slides => slides.filter( s =>
                    s.userId === userId || !s.isPrivate ||
                    ( s.collaborators && ( s?.collaborators?.includes( user.email ) ||
                    s?.collaborators?.includes( userId )))),
                )),
            ),
        );
    }

    /**
     * Uses regex to find all presentations with name containing a keyword
     */
    public findPresentationByName( projectId: string, name: string, userId: string ): Observable<PresentationModel[]> {
        return this.userLocator.getUserInfo( userId ).pipe(
            mergeMap( user => this.dataStore.find( PresentationModel, {
                projectId: projectId, name: { $regex: name, $options: 'i' }}).pipe(
                filter( slides => !!slides ),
                map( slides => slides.filter( s =>
                    s.userId === userId || !s.isPrivate ||
                    ( s.collaborators && ( s?.collaborators?.includes( user.email ) ||
                    s?.collaborators?.includes( userId ))))),
                map( slides => slides.filter( s => !s.isDeleted )),
            )),
        );
    }

    /**
     * This function returns the matching PresentationModel for a given id.
     * @param presentationId string
     * @return Observable<PresentationModel>
     */
    public getPresentation( presentationId: string, userId?: string ): Observable<PresentationModel> {
        userId = userId ? userId : this.state.get( 'CurrentUser' );
        return this.userLocator.getUserInfo( userId ).pipe(
            mergeMap( user => this.dataStore.find( PresentationModel, { id: presentationId }).pipe(
                take( 1 ),
                map( slides => slides.filter( s =>
                    s.userId === userId || !s.isPrivate ||
                    ( s.collaborators && ( s?.collaborators?.includes( user.email ) ||
                    s?.collaborators?.includes( userId ))))),
                map( presentations => presentations[0]),
            )),
        );
    }

}
