import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * TipTapSlashCmdListItem renders slash command item and this
 * includes Thumbnail, title and subtitle.
 */
@Component({
    selector: 'tiptap-slash-cmd-list-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div class="tiptap-slash-cmd-list-item-container" >
        <div class="tiptap-slash-cmd-list-item-thumb" >
            <svg><use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + data.thumbnail"></use></svg>
        </div>
        <div class="tiptap-slash-cmd-list-item-info" >
            <div class="tiptap-slash-cmd-list-item-title" >{{data.title}}</div>
            <div class="tiptap-slash-cmd-list-item-subtitle" >{{data.subTitle}}</div>
        </div>
      </div>
    `,
    styleUrls: [ './tiptap-slash-cmd-list-item.cmp.scss' ],
})
export class TipTapSlashCmdListItem {

    @Input()
    public data: ITipTapSlashCmdListItem;

}

export interface ITipTapSlashCmdListItem {
    thumbnail: string;
    title: string;
    subTitle: string;
}
