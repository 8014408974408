import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command } from 'flux-core';
import { Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';

/**
 * This is the command which changes the role of the project collaborator and send
 * the request to the server.
 *
 * data: {
 *     collabs { id, role} An array of changed collaborator id and role
 * }
 */
@Injectable()
@Command()
export class ChangeProjectCollaborators extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            collabs: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.getCollabs( this.resourceId ).pipe(
            tap( collaborators => {
                this.previousData.collabs = collaborators.filter( collab =>
                    this.data.collabs.map( user => user.id ).indexOf( collab.id ) !== -1 );
            }),
        );
    }

    /**
     * execute
     * change collaborator role
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;

        return of( this.previousData.collabs ).pipe(
            map( collaborators => {
                const updateCollabs = [];
                this.data.collabs.forEach( collab  => {
                    const collabData = collaborators.filter( collaborator => collaborator.id === collab.id )[0];
                    collabData.role = collab.role;
                    updateCollabs.push( collabData );
                });
                return updateCollabs;
            }),
            switchMap( collabs => modelStore.storageMergeCollabs( this.resourceId, collabs )),
        );
    }

    /**
     * executeResult
     * Update the collaborators with the response
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore
            .storageMergeCollabs( this.resourceId, response.collabs );
    }


    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore ;
        return modelStore.storageMergeCollabs( this.resourceId, this.previousData.collabs );
    }

}

Object.defineProperty( ChangeProjectCollaborators, 'name', {
    value: 'ChangeProjectCollaborators',
});
