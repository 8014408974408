import { DynamicComponent } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy, ViewChild,
    ElementRef, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { TiptapPopCommandsListCmp } from '../tiptap-pop-commands-list.cmp';

/**
 * TipTapSlashCmdListCmp is the component that renders the
 * slash commands. The source of this has been adopted from the slash commands example
 * https://tiptap.dev/experiments/commands
 */
@Component({
    selector: 'tiptap-slash-cmd-list-cmp',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div data-id="{{id}}" #container class="tiptap-slash-cmd-list-cmp-contianer">
        <perfect-scrollbar #scrollbar style="max-height: {{maxHeight}}px;">
            <div [class.fx-hidden]="hideList | async" class="tiptap-slash-cmd-list">
                <div *ngFor="let group of groupedItems | async " >
                <div class="tiptap-slash-cmd-list-group" >{{group[0].section}}</div>

                <tiptap-slash-cmd-list-item
                    [class.selected]="item.id === ( selectedId | async)"
                    (click)="selectItem(item.id)"
                    [data]="item"
                    *ngFor="let item of group">
                </tiptap-slash-cmd-list-item>
                </div>
            </div>
        </perfect-scrollbar>
        <div #secondery ></div>
      </div>
    `,
    styleUrls: [ './tiptap-slash-cmd-list.cmp.scss' ],
})
export class TipTapSlashCmdListCmp extends TiptapPopCommandsListCmp  {

    /**
     * The max height of the component in pixels
     * This component will enable the scrol bar if the total height of the items
     * exceeds this height.
     */
    public maxHeight = 320;

    @ViewChild( 'secondery', { read: ViewContainerRef, static: false })
    protected secondery: ViewContainerRef;

    constructor(
        protected elementRef: ElementRef,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
        super( elementRef, componentFactoryResolver, injector );
    }

    public position( x, y ) {
        if ( this.container ) {
            const el = this.containerElement;
            el.style.position = 'absolute';
            const thisElement = document.getElementById( this.id );
            if ( thisElement ) {
                const elBounds =  el.getBoundingClientRect();
                const parentBounds  = thisElement.parentElement.parentElement.getBoundingClientRect();
                let top;
                let left =  x - parentBounds.x;
                const deltaX = parentBounds.left + left + elBounds.width - window.innerWidth;
                if ( deltaX > 0 ) {
                    left = left - deltaX;
                }
                const height = elBounds.height || this.maxHeight;
                // If 75% of the component is not visible, palce it on top
                if ( y + ( height * 0.75 )   > window.innerHeight ) {
                    top = y - parentBounds.y - height - 10;
                } else {
                    top = y - parentBounds.y + 30;
                }
                el.style.top = top + 'px';
                el.style.left =  left + 'px';
            }
        }
    }

    public destroy() {
    }

    public selectItem( id ) {
        const item = this.items.value.find( v => v.id === id );
        if ( item && item.component ) {
            this.hideList.next( true );
            this.secondery.clear();
            const dc: DynamicComponent = new DynamicComponent( this.componentFactoryResolver, this.injector );
            const itemRef = dc.makeComponent( item.component.type );
            Object.keys( item.component.inputs ).forEach( key => {
                itemRef.instance[ key ] = item.component.inputs[ key ];
            });
            itemRef.changeDetectorRef.detectChanges();
            dc.insert( this.secondery, itemRef );
            itemRef.changeDetectorRef.detectChanges();
            item.componentInstance = itemRef.instance;
            this.command( item );
        } else if ( item ) {
            this.command( item );
        }
    }

    protected autoScroll() {
        setTimeout(() => {
            this.directiveScroll.directiveRef.scrollToElement(
                `tiptap-slash-cmd-list-item.selected` , -10 , 100 );
        }, 10 );
    }
}
