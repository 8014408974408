import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopupWindow } from 'flux-core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

export enum DataSourceOption {
    GITHUB = 'github',
    JIRA = 'jira',
}

/**
 * This is the data import dialog.
 */
@Component({
    templateUrl: './link-data-source-window.cmp.html',
    selector: 'link-data-source-window',
    styleUrls: [ './link-data-source-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDataSourceWindow extends PopupWindow implements OnInit, OnDestroy {

    public dataSourceOptions = [
        {
            id: DataSourceOption.GITHUB,
            label: 'Github',
            icon: 'github',
        },
        {
            id: DataSourceOption.JIRA,
            label: 'Jira',
            icon: 'jira',
        },
    ];

    public selectedDataSourceOption: Subject<DataSourceOption> = new BehaviorSubject( DataSourceOption.GITHUB );
    public closeWindowObs: Subject<boolean> = new Subject();

    /**
     * The window overlay.
     */
    @ViewChild( 'window' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner' ) protected containerInner;

    protected subs: Subscription[] = [];

    constructor(
        protected modalController: ModalController,
    ) {
        super()/* istanbul ignore next */;
    }

    public ngOnInit(): void {
        this.subs.push( this.closeWindowObs.subscribe(() => this.closeWindow()));
    }

    /**
     * Closes the window after animation is complete.
     */
    public closeWindow() {
        if ( this.container && this.containerInner ) {
            this.hideWindow( this.container, this.containerInner ).subscribe({
                complete: () => {
                    this.modalController.hide();
                },
            });
        }
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
}
