import { size } from 'lodash';
import { createFuture, Future } from '@creately/future';
import { invert } from '@creately/mungo';
import { Proxied, Sakota } from '@creately/sakota';
import { ClassUtils, EventIdentifier, Flags, Logger } from 'flux-core';
import { BehaviorSubject, concat, empty, merge, Observable, Observer, Subscription, EMPTY, from } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { DiagramDataModel } from '../model/diagram-data.mdl';
import { IDiagramChange, IEmittedDiagramChange, IEmittedDataDefChange,
        IEmittedEDataShapeChange, IEmittedLinkChange, IEmittedRuleChange, IEmittedShapeChange } from './diagram-change.i';
import { IDiagramFactory } from './diagram-factory.i';
import { IDiagramLocator } from './diagram-locator.i';
import { ISplitModifier, splitModifier } from './split-modifier';
import { StoredDiagramLocator } from './stored-diagram-locator';
import { EventCollector } from 'flux-core';

/**
 * IPreviewDiagramLocatorOptions
 * Contains input data and services needed by the preview diagram locator.
 */
export interface IPreviewDiagramLocatorOptions<
    DT extends DiagramDataModel,
    ST extends AbstractShapeModel,
> {
    parent: StoredDiagramLocator<DT, ST>;
    changes: Observable<IDiagramChange>;
    factory: IDiagramFactory<DT, ST>;
}

/**
 * ...
 */
export const RESET_PREVIEW = Symbol();

/**
 * PreviewDiagramLocator
 * This diagram locator can be used to get commonly used information about the diagram
 * and shapes. This includes changes stored in the database and also preview changes.
 *
 * The diagram data is split into 2 levels:
 *  - diagram0: model with stored changes
 *  - diagram1: model with preview changes
 *
 * This is done so that the diagram model can be rollbacked to remove preview changes.
 * The primary objective here is to maintain good performance and it is done in implementation
 * level as well as in design level.
 *
 * FIXME: it ignore all preview changes until the locator is initialized.
 * FIXME: stored changes are not immediately visible, emits previous state
 *
 */
export class PreviewDiagramLocator<
    DT extends DiagramDataModel,
    ST extends AbstractShapeModel
> implements IDiagramLocator<DT, ST> {
    /**
     * The source diagram locator which is a source for this locator.
     */
    private parent: StoredDiagramLocator<DT, ST>;

    /**
     * Indicates whether the diagram locator is active.
     */
    private started = new BehaviorSubject<boolean>( false );

    /**
     * The subscription for the main process of the diagram locator.
     */
    private process: Subscription;

    /**
     * Indicates that the diagram data is being updated at the moment.
     */
    private working?: Future<unknown>;

    /**
     * Counter used to generate unique ids for subscription observers.
     */
    private counter = 0;

    /**
     * A future which is used to identify when the diagram model is ready.
     */
    private oninit = createFuture();

    /**
     * Hold observers to emit new changes to them.
     */
    private observers: {
        diagram: { [id: number]: Observer<IEmittedDiagramChange<DT, ST>> },
        inserts: { [id: number]: Observer<ST[]> },
        shapes: { [shapeId: string]: { [id: number]: Observer<IEmittedShapeChange<ST>> }},
    } = { diagram: {}, inserts: {}, shapes: {}};

    /**
     * The diagram data is stored in multiple levels.
     *      Level 0: Stored Data
     *      Level 1: Preview Data
     */
    private diagram0: DT = null;
    private diagram1: Proxied<DT> = null;

    // Public Methods
    // --------------

    /**
     * Starts listening to changes and starts emitting changes when shapes change.
     * Returned observable will emit once and complete when the diagram model is ready.
     */
    public start( options: IPreviewDiagramLocatorOptions<DT, ST> ): Observable<unknown> {
        ClassUtils.disableMethod( this, 'start', 'The start method is called multiple time on PreviewDiagramLocator.' );
        this.parent = options.parent;
        this.started.next( true );
        this.process = merge(
            options.parent.getDiagramOnce().pipe(
                map( data => ({ type: 'inited', data })),
            ),
            options.parent.getDiagramChanges().pipe(
                map( data => ({ type: 'stored', data })),
            ),
            options.changes.pipe(
                map( data => ({ type: 'preview', data })),
            ),
        ).pipe(
            concatMap( action => {
                if ( action.type === 'preview' ) {
                    return this.processPreview( action.data as any );
                } else if ( action.type === 'stored' ) {
                    return this.processStored( action.data as any );
                } else {
                    this.processInitial( action.data as any );
                    return empty();
                }
            }),
        ).subscribe();
        return from( this.oninit );
    }

    /**
     * Stops all locator activities.
     */
    public stop(): void {
        [
            'start',
            'stop',
            'getDiagramOnce',
            'getDiagramModel',
            'getDiagramName',
            'getDiagramChanges',
            'getShapeOnce',
            'getShapeModel',
            'getAddedShapes',
            'getCurrentShapes',
            'getShapeChanges',
            'executeWhenIdle',
        ].forEach( name => {
            ClassUtils.disableMethod( this, name, `PreviewDiagramLocator.${name}() is called after stopping.` );
        });
        if ( this.started.value === true ) {
            this.process.unsubscribe();
            this.started.next( false );
            this.closeSubscriptions();
        }
    }

    /**
     * Emits the diagram once and completes the subscription immediately.
     */
    public getDiagramOnce(): Observable<DT> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getDiagramOnce()),
            );
        }
        return Observable.create(( o: Observer<DT> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.diagram1 );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the diagram immediately and whenever it changes (without modifiers).
     */
    public getDiagramModel(): Observable<DT> {
        return merge(
            this.getDiagramOnce(),
            this.getDiagramChanges().pipe(
                map( value => value.model ),
            ),
        );
    }

    /**
     * Emits the diagram name immediately and whenever it changes (without modifiers).
     */
    public getDiagramName(): Observable<string> {
        return this.getDiagramModel().pipe(
            map( diagram => diagram.name ),
            distinctUntilChanged(),
        );
    }

    /**
     * Emits the diagram edata immediately and whenever it changes (without modifiers).
     * Not used in Preview.
     */
    public getDiagramEData(): Observable<any> {
        return EMPTY;
    }

    /**
     * Emits only when there are new changes to the diagram (after subscribing).
     */
    public getDiagramChanges(): Observable<IEmittedDiagramChange<DT, ST>> {
        return Observable.create(( o: Observer<IEmittedDiagramChange<DT, ST>> ) => {
            const id = this.createUniqueId();
            this.observers.diagram[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'PreviewDiagramLocator: Diagram Changes:', size( this.observers.diagram ));
            }
            return () => {
                delete this.observers.diagram[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'PreviewDiagramLocator: Diagram Changes:', size( this.observers.diagram ));
                }
            };
        });
    }

    /**
     * Returns EDataChanges.
     * This is not available in preview mode.
     */
    public getDiagramEDataChanges(): Observable<IEmittedEDataShapeChange<DT, ST>> {
        return EMPTY;
    }

    /**
     * Returns EData Link Changes.
     * This is not available in preview mode.
     */
    public getDiagramLinkChanges(): Observable<IEmittedLinkChange<ST>> {
        return EMPTY;
    }

    /**
     * Returns EData Link Changes.
     * This is not available in preview mode.
     */
     public getDiagramRuleChanges(): Observable<IEmittedRuleChange<ST>> {
        return EMPTY;
    }

    /**
     * Returns EData Link Changes.
     * This is not available in preview mode.
     */
     public getDiagramDataDefChanges(): Observable<IEmittedDataDefChange<ST>> {
        return EMPTY;
    }


    /**
     * Emits the shape once and completes the subscription immediately.
     */
    public getShapeOnce( shapeId: string ): Observable<ST> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getShapeOnce( shapeId )),
            );
        }
        return Observable.create(( o: Observer<ST> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.diagram1 && this.diagram1.shapes[shapeId] as any || null );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the shape when it's available and whenever it changes (without modifiers)
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        At the moment, it emits twice when created, and then continue normal.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getShapeModel( shapeId: string ): Observable<ST> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getShapeModel( shapeId )),
            );
        }
        const initial = this.getDiagramModel().pipe(
            map( diagram => diagram.shapes[shapeId] as ST ),
            filter( shape => Boolean( shape )),
            take( 1 ),
        );
        const changes = this.getShapeChanges( shapeId ).pipe(
            map( value => value.model ),
        );
        return concat( initial, changes );
    }

    /**
     * Emits only when a new shape is added to the diagram model.
     */
    public getAddedShapes(): Observable<ST[]> {
        return Observable.create(( o: Observer<ST[]> ) => {
            const id = this.createUniqueId();
            this.observers.inserts[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'PreviewDiagramLocator: Added Shapes:', size( this.observers.inserts ));
            }
            return () => {
                delete this.observers.inserts[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'PreviewDiagramLocator: Added Shapes:', size( this.observers.inserts ));
                }
            };
        });
    }

    /**
     * Emits all shapes once and completes the subscription immediately.
     */
    public getCurrentShapes(): Observable<ST[]> {
        return this.getDiagramOnce().pipe(
            map( diagram => {
                const shapes: ST[] = [];
                for ( const id in diagram.shapes ) {
                    // TODO: check why we get a type error?
                    shapes.push( diagram.shapes[id] as ST );
                }
                return shapes;
            }),
        );
    }

    /**
     * Emits only when there are new changes to the diagram (after subscribing).
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getShapeChanges( shapeId: string ): Observable<IEmittedShapeChange<ST>> {
        return Observable.create(( o: Observer<IEmittedShapeChange<ST>> ) => {
            const id = this.createUniqueId();
            if ( !this.observers.shapes[ shapeId ]) {
                this.observers.shapes[ shapeId ] = { [id]: o };
            } else {
                this.observers.shapes[ shapeId ][id] = o;
            }
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'PreviewDiagramLocator: Shape Changes:', shapeId, size( this.observers.shapes[shapeId]));
            }
            return () => {
                delete this.observers.shapes[ shapeId ][id];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    // tslint:disable-next-line: max-line-length
                    Logger.debug( 'PreviewDiagramLocator: Shape Changes:', shapeId, size( this.observers.shapes[shapeId]));
                }
                if ( Object.keys( this.observers.shapes[ shapeId ]).length === 0 ) {
                    delete this.observers.shapes[ shapeId ];
                }
            };
        });
    }

    /**
     * Execute given function when the locator is idle
     * NOTE: This is exposed for other locators, do not use this function.
     */
    public executeWhenIdle( fn: () => void ): void {
        this.parent.executeWhenIdle(() => {
            if ( this.working ) {
                this.working.then( fn );
            } else {
                fn();
            }
        });
    }

    // Internal Processes
    // ------------------

    /**
     * Process initial diagram model.
     */
    private processInitial( model: DT ): Promise<unknown> {
        this.diagram0 = model;
        this.diagram1 = Sakota.create( this.diagram0 );
        this.oninit.resolve( null );
        this.oninit = null;
        return Promise.resolve( null );
    }

    /**
     * Processed stored diagram changes.
     * TODO: emit an inverted modifier before emitting new changes.
     */
    private processStored( change: IEmittedDiagramChange<DT, ST> ): Promise<unknown> {
        const { id, model, modifier, split, added } = change;
        const addedIds = added.map( shape => shape.id );
        this.diagram0 = model;
        this.diagram1 = Sakota.create( this.diagram0 );
        this.emitChanges({ id, modifier }, split, addedIds );
        return Promise.resolve( null );
    }

    /**
     * Processes the diagram change.
     */
    private processPreview( change: IDiagramChange ): Promise<unknown> {
        if ( change.modifier === RESET_PREVIEW ) {
            const modifier = this.diagram1.__sakota__.getChanges();
            if ( !modifier.$set && !modifier.$unset ) {
                return Promise.resolve( null );
            }
            const inverted = invert( this.diagram0, modifier );
            const split = splitModifier( inverted );
            const added = this.addedShapes( split, this.diagram1 );
            this.diagram1 = Sakota.create( this.diagram0 );
            this.emitChanges({ id: '', modifier: inverted }, split, added );
            return Promise.resolve( null );
        } else {
            this.working = createFuture();
            const split = splitModifier( change.modifier );
            const added = this.addedShapes( split, this.diagram1 );
            return this.applyChanges( change, split )
                .then(() => {
                    this.working.resolve( null );
                    this.working = null;
                    this.emitChanges( change, split, added );
                });
        }
    }

    /**
     * Returns ids of shapes which are not available on the diagram locator ( new shapes ).
     * NOTE: when shapes are deleted it'll have { $unset: true } as the modifier, ignoring it.
     */
    /* istanbul ignore next */
    private addedShapes( split: ISplitModifier, diagram: DT ): string[] {
        const added = [];
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                if (
                    ( !diagram || !diagram.shapes || !diagram.shapes[shapeId]) &&
                    ( !split.shapes[shapeId].$unset || split.shapes[shapeId].$unset !== ( true as any ))
                ) {
                    added.push( shapeId );
                }
            }
        }
        return added;
    }

    /**
     * Applies changes to the model.
     * FIXME: assuming preview does not change the diagram/shape type
     * FIXME: assuming preview does not add new shapes or remove shapes
     */
    private applyChanges( change: IDiagramChange, split: ISplitModifier ): Promise<unknown> {
        this.diagram1.__sakota__.mergeChanges( change.modifier );
        return Promise.resolve( null );
    }

    /**
     * Emits new changes to observers.
     */
    private emitChanges( change: IDiagramChange, split: ISplitModifier, inserts: string[] = []): void {
        if ( Flags.get( 'LOG_COLLECTOR_ENABLED' ) && Flags.get( 'LOG_PREVIEW_CHANGES' )) {
            EventCollector.log({
                message: EventIdentifier.DIAGRAM_PREVIEW_LOCATOR_CHANGE_PROCESSED,
                change, inserts,
            });
        }
        if ( split.shapes ) {
            for ( const shapeId in split.shapes ) {
                const shape = this.diagram1.shapes[shapeId] as ( ST );
                const modifier = split.shapes[shapeId];
                const shapeObservers = this.observers.shapes[shapeId];
                if ( !shapeObservers ) {
                    continue;
                }
                if ( shape ) {
                    for ( const id in shapeObservers ) {
                        // NOTE: use the shape modifier instead of full modifier
                        const value = { ...change, modifier, model: shape };
                        shapeObservers[id].next( value );
                    }
                } else {
                    for ( const id in shapeObservers ) {
                        shapeObservers[id].complete();
                        delete shapeObservers[id];
                    }
                    delete this.observers.shapes[shapeId];
                }
            }
        }
        const insertedShapes = this.getShapeModels( inserts, this.diagram1 );
        const diagramObservers = this.observers.diagram;
        for ( const id in diagramObservers ) {
            const value = { ...change, model: this.diagram1, split, added: insertedShapes };
            diagramObservers[id].next( value );
        }
        const insertsObservers = this.observers.inserts;
        if ( insertedShapes.length ) {
            for ( const id in insertsObservers ) {
                insertsObservers[id].next( insertedShapes );
            }
        }
    }

    // Diagram 0 - diagram model with correct type and data (stored data only)
    // -----------------------------------------------------------------------

    // NOTE: add any helper functions related to level 0 diagrams

    // Diagram 1 - diagram model with preview changes applied (proxies diagram0)
    // -----------------------------------------------------------------------

    // Other Helpers - helper functions which are not directly related to the logic
    // ----------------------------------------------------------------------------

    /**
     * Returns a unique number each time this method is called. Can be used as unique ids.
     */
    private createUniqueId(): number {
        return ++this.counter;
    }

    /**
     * Returns models of shapes with given ids.
     */
    private getShapeModels( shapeIds: string[], diagram: DT ): ST[] {
        const shapes = [];
        for ( const shapeId of shapeIds ) {
            shapes.push( diagram.shapes[shapeId]);
        }
        return shapes;
    }

    /**
     * Closes all active subscriptions ( calls the complete on observers ).
     */
    private closeSubscriptions(): void {
        for ( const id in this.observers.diagram ) {
            this.observers.diagram[id].complete();
        }
        for ( const id in this.observers.inserts ) {
            this.observers.inserts[id].complete();
        }
        for ( const shapeId in this.observers.shapes ) {
            for ( const id in this.observers.shapes[shapeId]) {
                this.observers.shapes[shapeId][id].complete();
            }
        }
    }
}
