import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, NotifierController, AppConfig, PostMessageAPI,
    NotificationType, PopupWindow, AbstractNotification } from 'flux-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * An enum containing the available pages on the team portal.
 */
export enum TeamPortalPage {
    Overview = 'overview',
    Organization = 'organization',
    Account = 'account',
    Billing = 'billing',
}

/**
 * Post message receive event types for team portal.
 */
export enum TeamPortalPMReceiveEvent {
    Open = 'teamPortal:open',
    Close = 'teamPortal:close',
    Reload = 'teamPortal:reload',
    ShowNotification = 'teamPortal:showNotification',
    HideNotification = 'teamPortal:hideNotification',
}

/**
 * Team portal window component.
 */
@Component({
    templateUrl: 'team-portal-window.cmp.html',
    selector: 'team-portal-window',
    styleUrls: [ './team-portal-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPortalWindow extends PopupWindow implements OnDestroy, OnInit {
    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The team portal window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    /**
     * The team portal page to open. Defaults to Overview.
     */
    @Input()
    private _page: TeamPortalPage = TeamPortalPage.Overview;

    constructor(
        protected modalController: ModalController,
        protected postMessage: PostMessageAPI,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
    ) {
        super();
        this.subs = [];
    }
    get page(): TeamPortalPage {
        return this._page;
    }
    set page( page: TeamPortalPage ) {
        this._page = page;
    }

    /**
     * Return the team portal URL with the current page to open.
     */
    public get teamPortalUrl(): string {
        return AppConfig.get( 'TEAM_PORTAL_URL' ) + '/'
            + this.page + '?mode=embedded&lng=' + this.translate.currentLang;
    }

    /**
     * Show window and subscribe to post message api.
     */
    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
            this.postMessage.recv().subscribe( message => this.handleIncomingMessages( message )),
        );
    }

    /**
     * Close the team portal window.
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Reload the current window after sign in
     * NOTE: This function is untestable with unit tests.
     */
    /* istanbul ignore next */
    protected navigateAfterSignIn() {
        window.location.reload();
    }

    /**
     * Handle incoming post messages from team portal.
     */
    private handleIncomingMessages( message: any ): void {
        if ( !message || typeof message !== 'object' ) {
            return;
        }
        const { event, data } = message as any;
        if ( event === TeamPortalPMReceiveEvent.Close ) {
            this.handleClose();
        }
        if ( event === TeamPortalPMReceiveEvent.ShowNotification ) {
            this.handleShowNotification( data.id, data.type, data.heading, data.description, data.autoDismiss );
        }
        if ( event === TeamPortalPMReceiveEvent.HideNotification ) {
            this.handleHideNotification( data.id );
        }
        if ( event === TeamPortalPMReceiveEvent.Reload ) {
            this.navigateAfterSignIn();
        }
    }

    /**
     * Handle show notification events sent from the team portal.
     * Shows notification with given parameters.
     * @param id the id of the notification to be shown
     * @param type the NotificationType type of notification
     * @param heading the heading for the notification
     * @param description the description for the notification
     */
    private handleShowNotification(
        id: string,
        type: NotificationType,
        heading: string,
        description: string,
        autoDismiss: boolean = false,
    ): void {
        this.notifierController.show( id, AbstractNotification,
            type,
            { inputs: { heading, description, autoDismiss }},
            false, // Always show notification from team portal.( Do not collapse )
        );
    }

    /**
     * Handle hide notification events sent from team portal.
     * Hides notification with given id.
     * @param id the id of the notification to be hidden.
     */
    private handleHideNotification( id: string ) {
        this.notifierController.hide( id );
    }

    /*
     * Handle close event sent from the team portal.
     * Closes modal.
     */
    private handleClose(): void {
        this.closeWindow();
    }
}
