import { NgModule } from '@angular/core';
import { ModelSubscriptionService } from './model/model-subscription.svc';
import { ListSubscriptionService } from './list/list-subscription.svc';

@NgModule({
    providers: [
        ModelSubscriptionService,
        ListSubscriptionService,
    ],
})
export class FluxSubscription {
}
