import { ConnectorTextDataModel } from 'flux-diagram-composer/models';
import { PositionType } from 'flux-definition';
import * as innerText from '@creately/inner-text';

/**
 * ConnectorTextModel is the concrete version of a Connector Text and exends the TextModel
 * to inherit the common text functionalities.
 * This model will contain all data
 * processing and manipulation functionality needed for connector text.
 *
 * @author Thisun
 * @since 2018-01-16
 */
export class ConnectorTextModel extends ConnectorTextDataModel {

    /**
     * The x position of the text along the connector path.
     * The value that must be set is based on what is set in xType property.
     * For 'relative' this should a ratio value between 0 and 1.
     * For 'fixed-start' this should be a pixel value positioning from the start of the path.
     * For 'fixed-end' this should be a pixel value positioning from the end of the path.
     */
    public pos: number = 0.5;

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public posType: PositionType = 'relative';

    public slashCmdFeatures = [];

    /**
     * Returns the plain text by extracting text from the value property
     * and discards the styles
     * @override
     * FIXME This method should be removed after the usage of
     * value property is totally replaced by content property.
     */
    public get plainText(): string {
        if ( !this.content ) {
            return innerText( this.html ).trim();
        }
        let plaintext = '';
        for ( const block of this.content ) {
            plaintext += block.text;
        }
        return plaintext.trim();
    }

}
