import { EntityModel } from './entity.mdl';

export class EntityListModel extends EntityModel {

    public entities = [];

    public containers: { [diagramId: string ]: {
        [containerId: string]: {
            [entities: string ]: {
                isMoved: boolean,
                pending: boolean,
            }},
    } } = {};

    public search: string;
    public name: string;
    public deleted: boolean = false;
}
