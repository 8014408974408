/**
 * This helper can be used to make sure a function can only be called once.
 * It will throw an error if this gets called multiple times.
 */
// tslint:disable-next-line: only-arrow-functions
export function onlyOnce<T extends Function>( fn: T ): T {
    let called = false;
    // tslint:disable-next-line: only-arrow-functions
    return function ( ...args: any[]) {
        if ( called ) {
            throw new Error( 'expected function to be called only once' );
        }
        called = true;
        return fn( ...args );
    } as any as T;
}
