import { Angle } from './angle';
import { IPoint2D, IPoint } from 'flux-definition';

/**
 * Orientation of 3 ordered points
 */
export enum Orientation {
    Colinear,
    Clockwise,
    CounterClockwise,
}

/**
 * A generic point class that holds a single coodrinate value.
 * This is used point related calculations and manipulations
 */
export class Point implements IPoint {
    /**
     * Creates a new Point instance using given coordinate values.
     * @param point an object with x,y values to create the point from
     */
    public static from( point: IPoint2D ): Point {
        return new Point( point.x, point.y );
    }

    /**
     * Rotates point A around its 0,0 by the given angle. Returns
     * a new point with the values.
     */
    public static rotate( a: IPoint2D, angle: number ) {
        const radians = ( Math.PI / 180 ) * -angle;
        const cos = Math.cos( radians );
        const sin = Math.sin( radians );
        const nx = ( cos * a.x ) + ( sin * a.y );
        const ny = ( cos * a.y ) - ( sin * a.x );
        return new Point( nx, ny );
    }

    /**
     * Returns true if x, y coordinates of given points are equal.
     */
    public static isEqual( a: IPoint2D, b: IPoint2D ): boolean {
        return a.x === b.x && a.y === b.y;
    }

    /**
     * Returns a new point which has the minimum x,y coordinates from given points.
     * @param points points to get the minimum x,y coordinates from
     */
    public static min( ...points: IPoint2D[]): Point {
        if ( !points.length ) {
            return null;
        }
        const min = new Point( points[0].x, points[0].y );
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            if ( min.x > point.x ) {
                min.x = point.x;
            }
            if ( min.y > point.y ) {
                min.y = point.y;
            }
        }
        return min;
    }

    /**
     * Returns a new point which has the maximum x,y coordinates from given points.
     * @param points points to get the maximum x,y coordinates from
     */
    public static max( ...points: IPoint2D[]): Point {
        if ( !points.length ) {
            return null;
        }
        const max = new Point( points[0].x, points[0].y );
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            if ( max.x < point.x ) {
                max.x = point.x;
            }
            if ( max.y < point.y ) {
                max.y = point.y;
            }
        }
        return max;
    }

    /**
     * Returns the coordinates of a point ( new Point instance ) which is given distance
     * away from the base point in the given direction. Angle is measured from the x-axis.
     * @param base The base point to shift from
     * @param angle The angle to move along
     * @param distance The distance to move
     */
    public static shift( base: IPoint2D, angle: number, distance: number ): Point {
        const rad = angle * Math.PI / 180;
        const x = base.x + distance * Math.cos( rad );
        const y = base.y + distance * Math.sin( rad );
        return new Point( x, y );
    }

    /**
     * Returns the angle (in degrees) the line A->B will make with the x-axis ( counter-clockwise ).
     *
     * Reference:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/atan2
     *
     * Examples:
     *
     *         +90
     *          ^ Y
     *          |
     *  180 <-- O --> 0
     *          |   X
     *          v
     *         -90
     *
     */
    public static angleTo( a: IPoint2D, b: IPoint2D ): number {
        const rad = Math.atan2( b.y - a.y, b.x - a.x );
        return Angle.normalize( rad * 180 / Math.PI );
    }

    /**
     * Returns the distance between point A and point B.
     */
    public static distanceTo( a: IPoint2D, b: IPoint2D ): number {
        return Math.sqrt( Math.pow( b.y - a.y, 2 ) + Math.pow( b.x - a.x, 2 ));
    }

    /**
     * Returns the mirror of point A around point B.
     *
     * Example:
     *
     *  A..........B..........M
     *       d          d
     */
    public static mirrorAround( a: IPoint2D, b: IPoint2D ): Point {
        const x = b.x - ( a.x - b.x );
        const y = b.y - ( a.y - b.y );
        return new Point( x, y );
    }

    /**
     * Finds the orientation of 3 points ( in order )
     * Reference: https://stackoverflow.com/q/17592800
     */
    public static orientation( p: IPoint2D, q: IPoint2D, r: IPoint2D ): Orientation {
        const k = ( q.y - p.y ) * ( r.x - q.x ) - ( q.x - p.x ) * ( r.y - q.y );
        if ( k > 0 ) {
            return Orientation.Clockwise;
        } else if ( k < 0 ) {
            return Orientation.CounterClockwise;
        } else {
            return Orientation.Colinear;
        }
    }

    /**
     * Creates a Point instance
     * @param x the x coordinate of the point
     * @param y the y coordinate of the point
     */
    constructor( public x: number, public y: number ) {}

    /**
     * Rotates this point around its 0,0 by the given angle. Returns
     * a new point with the values.
     * @param angle the angle to rotate to.
     */
    public rotate( angle: number ) {
        return Point.rotate( this, angle );
    }

    /**
     * Returns true if x, y coordinates of given points are equal.
     * @param point The point to compare with current point
     */
    public isEqual( point: IPoint2D ): boolean {
        return Point.isEqual( this, point );
    }

    /**
     * Gets the minimum of the x, y values out of given points and this point.
     * This selects the minimum x and minimum y out of either.
     * @param point The point having the minum x and y.
     */
    public min( ...points: IPoint2D[]): Point {
        return Point.min( this, ...points );
    }

    /**
     * Gets the maximum of the x, y values out of given points and this point.
     * This selects the maximum x and maximum y out of either.
     * @param point The point having the minum x and y.
     */
    public max( ...points: IPoint2D[]): Point {
        return Point.max( this, ...points );
    }

    /**
     * Returns the coordinates of a point ( new Point instance ) which is given distance
     * away from current point in the given direction. Angle is measured from the x-axis.
     * @param angle The angle to move along
     * @param distance The distance to move
     */
    public shift( angle: number, distance: number ): Point {
        return Point.shift( this, angle, distance );
    }

    /**
     * Returns the angle (in degrees) the given point will make with the x-axis ( counter-clockwise ).
     *
     * Reference:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/atan2
     *
     * Examples:
     *
     *         +90
     *          ^ Y
     *          |
     *  180 <-- O --> 0
     *          |   X
     *          v
     *         -90
     *
     */
    public angleTo( point: IPoint2D ): number {
        return Point.angleTo( this, point );
    }

    /**
     * Returns the distance between this point and the point given.
     * @param point The point to to measure distance to
     */
    public distanceTo( point: IPoint2D ): number {
        return Point.distanceTo( this, point );
    }

    /**
     * Returns the mirror of current point around given point.
     *
     * Example:
     *
     *  T..........P..........M
     *       d          d
     *
     * @param point The point to mirror around
     */
    public mirrorAround( point: IPoint2D ): Point {
        return Point.mirrorAround( this, point );
    }
}
