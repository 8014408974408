import { Component, Input, ChangeDetectionStrategy, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * A collapsible group for items in the eData view.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edata-smart-set-group',
    template: `<div class="edata-library-item" (click)="toggle()" [style.display]="isVisible ? 'flex' : 'none'">
                    <svg *ngIf="!!(closed | async)" [attr.class]="folderClass | async">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-menu-item-polygon-collapsed"></use>
                    </svg>
                </div>`,
    styleUrls: [ './edata-library-item.scss' ],
})

// <svg *ngIf="!(closed | async)" [attr.class]="folderClass | async">
//                         <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-menu-item-polygon-expanded"></use>
//                     </svg>
// <svg [attr.class]="folderClass | async">
//                         <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-smart-set-group"></use>
//                     </svg>
//                     <div [attr.title]="'SmartSets'" class="text-container fx-ellipsis">Smart Sets</div>

export class EDataSmartSetGroup {


    /**
     * Shape count in document
     */
    @Input() public isVisible: boolean = true;

    /**
     * The subject that emits component feates changes. Exposed
     * outside via the <code>change</code> getter.
     */
    @Output()
    public closed: Subject<any>;

    public isClosed: boolean;

    public folderClass: BehaviorSubject<string>;

    constructor(
        ) {
            this.closed = new Subject();
            this.folderClass = new BehaviorSubject( 'nu-icon nu-ic-menu-item-polygon-expanded' );
        }
    public get options(): any {
        return [];
    }

    public toggle() {
        if ( this.isClosed ) {
            this.folderClass.next( 'nu-icon nu-ic-menu-item-polygon-expanded' );
        } else {
            this.folderClass.next( 'nu-icon nu-ic-menu-item-polygon-collapsed' );

        }
        this.isClosed = !this.isClosed;
        this.closed.next( this.isClosed );
    }
}
