import { Directive, Input, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';
import { AbstractProjectRole } from './abstract-project-role';

/**
 * projectRole Directive
 * This directive can be used to control features based on users' role of the specific project.
 * When specifying which user roles are allowed, pass the minimum allowed role to the directive.
 * For all roles which are less than or equal to the given role will pass the directive and enable
 * the UI feature/element.
 *
 * How to use:
 * <div class="foo" *projectRole="{ role:'EDITOR', id: id }"></div>
 * This will enable the element for all user roles upto editor, and lesser roles won't
 * see this element, ie: REVIEWER
 *
 * @author  Mehdhi
 * @since   2020-02-25
 */
@Directive({
    selector: '[projectRole]',
})
export class ProjectRole extends AbstractProjectRole implements OnInit {

    /**
     * Get roles and project id from the directive.
     * Any role from CollaboratorType enum is valid
     */
    @Input( 'projectRole' )
    public projectData: { id: string, role: string };

    /**
     * Subscription which controls the view state based on users' role
     */
    protected subs: Subscription;

    /**
     * Start controlling the view
     */
    public ngOnInit(): void {
        if ( !this.projectData || CollaboratorType[ this.projectData.role ] === undefined ) {
            throw new Error( 'ProjectRole directive require a valid collaborator role to be matched against.' );
        }

        this.subs = of( this.projectData.id ).pipe(
            filter( projectId => !!projectId ),
            switchMap(
                projectId => this.updateElementsOnProjectRole( this.projectData.id, this.projectData.role ),
            ),
        ).subscribe();
    }
}
