import { Injectable } from '@angular/core';
import {  Command, Random } from 'flux-core';
import { empty,  Observable, of } from 'rxjs';
import { maxBy , merge as _merge } from 'lodash';
import { ShapeBoundsLocator } from './../../../../src/editor/diagram/containers/shape-bounds-locator';
import { IShapeDefinition, ShapeType } from 'flux-definition/src';
import { ignoreElements, switchMap, take, tap } from 'rxjs/operators';
import { AbstractDiagramChangeCommand } from './../../../../src/editor/diagram/command/abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../diagram-change.svc';
import { Restriction } from './../../../../src/editor/diagram/restriction/restriction.svc';
import { DefinitionLocator } from '../../shape/definition/definition-locator.svc';
import { LogicClassFactory } from 'flux-diagram-composer';

/**
 * This command will add a container for pre def queries.If there is already a container,
 * will not add a container.If there isn' a container, will add a container.
 */

@Injectable()
@Command()
export class AddPredefinedQueryContainer extends AbstractDiagramChangeCommand {

    private containerShapeDefId = 'creately.basic.rectangle';
    private containerVersion = 4;
    constructor(
        protected shapeBoundsLocator: ShapeBoundsLocator,
        protected ds: DiagramChangeService,
        protected restriction: Restriction,
        protected defLocator: DefinitionLocator,
    ) {
        super( ds );
    }
    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<unknown> {
        return this.createPredefinedQueryContainer();
    }

    protected createPredefinedQueryContainer() {
        const containerOptions = this.data.containerOptions || {};
        if ( this.data.shapes && Object.keys( this.data.shapes ).length > 0 ) {
            const resultData = {};
            const firstShape: any = Object.values( this.data.shapes )[0];
            const width = firstShape.defaultBounds.width * ( firstShape.scaleX || 1 );
            const height = firstShape.defaultBounds.height * ( firstShape.scaleY || 1 );

            const foundContainer = maxBy(
                this.shapeBoundsLocator.searchShapes( firstShape.x, firstShape.x + width,
                    firstShape.y, firstShape.y + height ), v => v.zIndex );

            let containerId: string;
            let obs: Observable<any> = of( true );
            if ( !foundContainer ) {
                containerId = Random.shapeId();
                const shape = {
                    id: containerId,
                    defId: containerOptions.shapeDefId || this.containerShapeDefId,
                    version: containerOptions.version || this.containerVersion,
                    x: firstShape.x,
                    y: firstShape.y,
                    isContainer: true,
                    layoutingId: containerOptions.layoutingId || 'elk-box',
                    data: {},
                    texts: {},
                };
                obs = this.insertShape( shape, width, height  );
            } else {
                containerId = foundContainer.id;
            }
            Object.keys( this.data.shapes ).forEach( shapeId => {
                this.data.shapes[shapeId].containerId = containerId;
                resultData[ shapeId ] = {
                    action: 'enter',
                    containerId: containerId,
                };
            });
            this.resultData = {
                childrenData: resultData,
            };
            return obs;
        }
        return of( true );
    }

    /**
     * Adds a shape with given data to the diagram.
     */
    private insertShape( shape: any, xWidth, xHeight ): Observable<void> {
        if ( shape.type !== ShapeType.Connector ) {
            const position = { x: shape.x || 0, y: shape.y || 0 };
            const restricted = this.restriction.point( position, [ 'GridService' ]);
            shape.x = restricted.x;
            shape.y = restricted.y;
        }


        return this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
            take( 1 ),
            switchMap(( def: IShapeDefinition ) => {
                const { width, height } = def.defaultBounds;
                let scaleX = ( xWidth + 20 ) / width;
                let scaleY = ( xHeight + 20 ) / height;
                if ( scaleX < 1 ) {
                    scaleX = 1;
                }
                if ( scaleY < 1 ) {
                    scaleY = 1;
                }
                const zIndex = this.changeModel.getIndexToAdd( def );
                this.changeModel.shapes[shape.id] = {
                    ...shape,
                    type: shape.type || ShapeType.Basic,
                    zIndex,
                    scaleX,
                    scaleY,
                } as any;
                if ( !def.style?.fillColor ) {
                    this.changeModel.shapes[shape.id].style = {
                        lineThickness: 0,
                        lineColor: '#c8c8c8',
                        fillColor: '#e9eff4',
                    } as any;
                }
                if ( !def.logicClass ) {
                    return empty();
                }
                return this.defLocator.getClass( def.logicClass ).pipe(
                    tap(( logicClass: any ) => {
                        const instance = LogicClassFactory.instance.create( logicClass );
                        if ( instance.created ) {
                            ( instance as any ).created( this.changeModel.shapes[shape.id], def, this.changeModel );
                        }
                    }),
                );
            }),
            ignoreElements(),
        );
    }

}

Object.defineProperty( AddPredefinedQueryContainer, 'name', {
    value: 'AddPredefinedQueryContainer',
});

