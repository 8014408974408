import { Injectable } from '@angular/core';
import { Logger, StateService, NotifierController } from 'flux-core';
import { DataStore } from 'flux-store';
import { take } from 'rxjs/operators';
import { DiagramModel } from '../diagram/model/diagram.mdl';
import { IErrorReport } from './error-report.i';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessages } from '../notifications/notification-messages';

/**
 * ErrorReporter
 *  - Collect data needed to prepare an error report and prepare an IErrorReport.
 *  - Upload the error report (via Neutrino) and send the url to the support team.
 */
@Injectable()
export class ErrorReporter {
    /**
     * Default file name for error report
     */
    protected errorReportFilename: string = 'Error Report';

    /**
     * Type of file for error report
     */
    protected errorReportFileType: string = 'application/json';

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
     protected notificationMessages: NotificationMessages;

    constructor(
        private datastore: DataStore,
        private stateSvc: StateService<any, any>,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
    ) {
        this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * Creates an error report
     */
    public async createErrorReport(): Promise<IErrorReport> {
        return {
            diagram: await this.getDiagramModel(),
            changes: await this.getDiagramChanges(),
            errors: Logger.getErrors(),
        };
    }

    /**
     * Fetches the raw diagram model data
     */
    private getDiagramModel(): Promise<object> {
        return new Promise( resolve => {
            const modelId = this.getDiagramId();
            const modelstore = this.datastore.getModelStore( DiagramModel );
            modelstore.collection.findOne({ id: modelId })
                .pipe( take( 1 ))
                .subscribe(
                    model => resolve( model ),
                    () => resolve( null ),
                    () => resolve( null ),
                );
        });
    }

    /**
     * Fetches all unsaved diagram changes
     */
    private getDiagramChanges(): Promise<any[]> {
        return new Promise( resolve => {
            const modelId = this.getDiagramId();
            const modelstore = this.datastore.getModelStore( DiagramModel );
            modelstore.changeCollection.find({ modelId, status: 'unsaved' })
                .pipe( take( 1 ))
                .subscribe(
                    changes => resolve( changes ),
                    () => resolve([]),
                    () => resolve([]),
                );
        });
    }

    /**
     * Returns the current diagram id.
     */
    private getDiagramId() {
        return this.stateSvc.get( 'CurrentDiagram' );
    }
}
