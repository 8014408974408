import { Rectangle, Position, Random } from 'flux-core';
import { IShapeImage, IShapeChild, IRectangle, ITransform } from 'flux-definition';
import { IShapeImageView } from '../../framework/image/shape-image-view.i';

/**
 * Private data structure used inside ShapeSVGImageView.
 */
interface IShapeChildTransform {
    bounds: IRectangle;
    rotate: { angle: number, regX: number, regY: number };
}

/**
 * ShapeSVGImageView is used to build the svg string for shape images.
 */
export class ShapeSVGImageView implements IShapeImageView {
    private imageMaskClipPathId = Random.shapeId();
    private imageTransform: IShapeChildTransform;
    private imageMaskTransform: IShapeChildTransform;

    constructor(
        private imageModel: IShapeImage,
        private imageFile: string,
        private imageSize: IRectangle,
    ) {}

    /**
     * Returns the image model id.
     */
    public get name(): string {
        return this.imageModel.id;
    }

    /**
     * Returns the file name
     */
    public get file(): string {
        return this.imageModel.file;
    }

    /**
     * Returns the file name
     */
    public get imageOutline() {
        return null;
    }

    /**
     * Returns the shape image svg string.
     */
    public get renderedImage() {
        if ( !this.imageTransform ) {
            return '';
        }
        const clipPath = this.getImageMaskClipPath();
        const transform = this.getTransformString();
        const groupProps = [
            ( transform ? `transform="${transform}"` : '' ),
        ].join( ' ' );
        const imageProps = [
            `x="${this.imageTransform.bounds.x}"`,
            `y="${this.imageTransform.bounds.y}"`,
            `width="${this.imageTransform.bounds.width}"`,
            `height="${this.imageTransform.bounds.height}"`,
            ( this.imageModel.mask ? `clip-path="url(#${this.imageMaskClipPathId})"` : '' ),
            `xlink:href="${this.imageFile}"`,
            `preserveAspectRatio="none"`,
        ].join( ' ' );
        const image = `<image ${imageProps} />`;
        return `<g ${groupProps}>${clipPath}${image}</g>`;
    }

    /**
     * Initialize the view (nothing to do)
     */
    public initialize(): void {
        // ...
    }

    /**
     * Destroy the view (nothing to do)
     */
    public destroy(): void {
        // ...
    }

    /**
     * Updates the stored image model and calculates image transform and image mask transform
     */
    public update( imageModel: IShapeImage, change?: any, shapeBounds?: Rectangle, transform?: ITransform ) {
        this.imageModel = imageModel;
        this.imageTransform = {
            bounds: { x: 0, y: 0, width: 0, height: 0 },
            rotate: { angle: 0, regX: 0, regY: 0 },
        };
        this.resizeChild( shapeBounds, transform, this.imageModel, this.imageSize, this.imageTransform );
        this.positionChild( shapeBounds, transform, this.imageModel, this.imageSize, this.imageTransform );
        if ( this.imageModel.mask ) {
            this.imageMaskTransform = {
                bounds: { x: 0, y: 0, width: 0, height: 0 },
                rotate: { angle: 0, regX: 0, regY: 0 },
            };
            const maskSize = { x: 0, y: 0, width: 50, height: 50 };
            this.resizeChild( shapeBounds, transform, this.imageModel.mask, maskSize, this.imageMaskTransform );
            this.positionChild( shapeBounds, transform, this.imageModel.mask, maskSize, this.imageMaskTransform );
        }
    }

    /**
     * Updates the width and height on given childTransform considering given parameters.
     */
    private resizeChild(
        shapeBounds: Rectangle,
        shapeTransform: ITransform,
        childModel: IShapeChild,
        childBounds: IRectangle,
        childTransform: IShapeChildTransform,
        ): void {
            const bounds = childTransform.bounds;
            if ( childModel.w && childModel.h ) {
                bounds.width = Math.round( this.getExpectedWidth( shapeBounds, shapeTransform, childModel ));
                bounds.height = Math.round( this.getExpectedHeight( shapeBounds, shapeTransform, childModel ));
            } else if ( childModel.w ) {
                bounds.width = Math.round( this.getExpectedWidth( shapeBounds, shapeTransform, childModel ));
                bounds.height = Math.round( childBounds.height * bounds.width / childBounds.width );
            } else if ( childModel.h ) {
                bounds.height = Math.round( this.getExpectedHeight( shapeBounds, shapeTransform, childModel ));
                bounds.width = Math.round( childBounds.width * bounds.height / childBounds.height );
            } else {
                // NOTE: Fallback to image's original width/height
                bounds.width = Math.round( childBounds.width );
                bounds.height = Math.round( childBounds.height );
            }
    }

    /**
     * Returns the width of the shape child (image/mask) as a number
     */
    private getExpectedWidth(
        shapeBounds: Rectangle,
        shapeTransform: ITransform,
        childModel: IShapeChild,
    ): number {
        if ( childModel.w.type === 'relative' ) {
            return childModel.w.value * shapeBounds.width * shapeTransform.scaleX;
        }
        return childModel.w.value;
    }

    /**
     * Returns the height of the shape child (image/mask) as a number
     */
    private getExpectedHeight(
        shapeBounds: Rectangle,
        shapeTransform: ITransform,
        childModel: IShapeChild,
    ): number {
        if ( childModel.h.type === 'relative' ) {
            return childModel.h.value * shapeBounds.height * shapeTransform.scaleY;
        }
        return childModel.h.value;
    }

    /**
     * Updates the position and rotation on given childTransform considering given parameters.
     */
    private positionChild(
        shapeBounds: Rectangle,
        shapeTransform: ITransform,
        childModel: IShapeChild,
        childBounds: IRectangle,
        childTransform: IShapeChildTransform,
        ): void {
            const pointOnShape = Position.onRect({
                x: childModel.x || { type: 'relative', value: 0 },
                y: childModel.y || { type: 'relative', value: 0 },
            }, shapeBounds, shapeTransform );
            const difference = { x: 0, y: 0 };
            if ( childModel.alignX === 'l' ) {
                // no changes
            } else if ( childModel.alignX === 'r' ) {
                difference.x = childTransform.bounds.width;
            } else {
                difference.x = childTransform.bounds.width / 2;
            }
            if ( childModel.alignY === 't' ) {
                // no changes
            } else if ( childModel.alignY === 'b' ) {
                difference.y = childTransform.bounds.height;
            } else {
                difference.y = childTransform.bounds.height / 2;
            }
            childTransform.bounds.x = Math.round( pointOnShape.x - difference.x );
            childTransform.bounds.y = Math.round( pointOnShape.y - difference.y );
            childTransform.rotate = {
                angle: Math.round( shapeTransform.angle ),
                regX: Math.round( pointOnShape.x ),
                regY: Math.round( pointOnShape.y ),
            };
    }

    /**
     * Returns the string with an svg <clipPath> to use as the mask.
     */
    private getImageMaskClipPath(): string {
        let clipPath = '';
        if ( this.imageModel.mask ) {
            // TODO: implement other mask types (eg. square)
            if ( this.imageModel.mask.type === 'circle' ) {
                const r = Math.round( this.imageMaskTransform.bounds.width / 2 );
                const cx = Math.round( this.imageMaskTransform.bounds.x + r );
                const cy = Math.round( this.imageMaskTransform.bounds.y + r );
                const path = `<circle r="${r}" cx="${cx}" cy="${cy}"/>`;
                clipPath = `<clipPath id="${this.imageMaskClipPathId}">${path}</clipPath>`;
            } else if ( this.imageModel.mask.type === 'square' ) {
                const w = Math.round( this.imageMaskTransform.bounds.width );
                const h = Math.round( this.imageMaskTransform.bounds.height );
                const x = Math.round( this.imageMaskTransform.bounds.x );
                const y = Math.round( this.imageMaskTransform.bounds.y );
                const path = `<rect x="${x}" y="${y}" width="${w}" height="${h}"/>`;
                clipPath = `<clipPath id="${this.imageMaskClipPathId}">${path}</clipPath>`;
            } else if ( this.imageModel.mask.type === 'roundedsquare' ) {
                const w = Math.round( this.imageMaskTransform.bounds.width );
                const h = Math.round( this.imageMaskTransform.bounds.height );
                const x = Math.round( this.imageMaskTransform.bounds.x );
                const y = Math.round( this.imageMaskTransform.bounds.y );
                const path = `<rect x="${x}" y="${y}" width="${w}" height="${h}" rx="5"/>`;
                clipPath = `<clipPath id="${this.imageMaskClipPathId}">${path}</clipPath>`;
            }
        }
        return clipPath;
    }

    /**
     * Returns the svg transform string for the shape image "g" tag.
     */
    private getTransformString(): string {
        let transform = '';
        if ( this.imageTransform.rotate.angle ) {
            const props = [
                this.imageTransform.rotate.angle,
                this.imageTransform.rotate.regX,
                this.imageTransform.rotate.regY,
            ];
            transform += `rotate(${props.join( ',' )})`;
        }
        return transform.trim();
    }
}
