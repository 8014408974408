import { CommandEvent, CommandMapper } from 'flux-core';
import { AddDiagramGroupShare } from './add-diagram-group-share.cmd';
import { RemoveDiagramGroupShare } from './remove-diagram-group-share.cmd';
import { ChangeDiagramGroupShare } from './change-diagram-group-share.cmd';

export class DiagramGroupShareCommandEvent extends CommandEvent {
    static addDiagramGroupShares: DiagramGroupShareCommandEvent = new DiagramGroupShareCommandEvent( 'AddDiagramGroupShares' );
    static changeDiagramGroupShares: DiagramGroupShareCommandEvent
        = new DiagramGroupShareCommandEvent( 'ChangeDiagramGroupShares' );
    static removeDiagramGroupShares: DiagramGroupShareCommandEvent
        = new DiagramGroupShareCommandEvent( 'RemoveDiagramGroupShares' );

    public static register( mapper: CommandMapper ) {
        super.register( mapper );
        mapper.mapSequence( DiagramGroupShareCommandEvent.addDiagramGroupShares )
            .add( AddDiagramGroupShare as any );
        mapper.map( DiagramGroupShareCommandEvent.removeDiagramGroupShares )
                .add( RemoveDiagramGroupShare as any );
        mapper.map( DiagramGroupShareCommandEvent.changeDiagramGroupShares ).add( ChangeDiagramGroupShare as any );
    }
}
