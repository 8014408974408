import { Injectable } from '@angular/core';
import { Command, Random, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { ConnectorModel } from 'apps/nucleus/src/base/shape/model/connector.mdl';
import { ShapeVotingService } from '../../feature/shape-voting.svc';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { CollabLocator } from 'apps/nucleus/src/base/diagram/locator/collab-locator';

/**
 * StartShapeVoting
 * This command starts shape voting based on given parameters
 */
@Injectable()
@Command()
export class StartShapeVoting extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        duration: number, // voting duration in milliseconds
        votesPerPerson: number, // max votes per user
        anonymous: boolean, // is voting anonymous or not
        oneVotePerItem: boolean; // if true, user can only vote once for one shape
    };

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected ll: DiagramLocatorLocator,
        protected shapeVotingService: ShapeVotingService,
        protected collabLocator: CollabLocator,
        protected state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<any> {
        const { changeModel, data } = this;
        const { duration, votesPerPerson, anonymous, oneVotePerItem } = data;
        // changeModel.shapeVotings = {};
        changeModel.shapeVotings = changeModel.shapeVotings || {};
        const isAnotherVotingActive = this.isAnotherVotingActive();

        // only one voting is possible at any given time
        if ( isAnotherVotingActive ) {
            return;
        }

        // if there's no shapes in the diagram, voting is impossible.
        const shapeList = this.getShapesList();
        if ( !shapeList.length ) {
            return;
        }

        const startTime = Number( Date.now());
        const endTime = startTime + ( 1000 * duration );
        const id = Random.votingSessionId();
        return this.collabLocator.getCollabs( this.state.get( 'CurrentDiagram' )).pipe(
            first(),
            tap( collaborators => {
                const users = collaborators.reduce(( res, next ) => {
                    const user = {
                        votedShapes: shapeList.map( s => s.id ).reduce(( obj, userId ) => {
                            obj[userId] = 0;
                            return obj;
                        }, {}),
                        userId: next.id,
                        model: next,
                    };
                    res.push( user );
                    return res;
                }, []);
                this.state.set( 'ShapeVoting', {
                    id,
                    votedShapes: {},
                    active: true,
                    acknowledged: true,
                    collapsed: false,
                    progress: 'active',
                    secondsLeft: Math.floor( this.data.duration ),
                });
                changeModel.shapeVotings[id] = {
                    id,
                    shapeIds: shapeList.map( s => s.id ),
                    anonymous,
                    maxVotes: votesPerPerson,
                    oneVotePerItem,
                    startTime,
                    endTime,
                    createdBy: this.state.get( 'CurrentUser' ),
                    users,
                    isResultSeen: false,
                };
                this.shapeVotingService.startVotingSubscription();
            }),
        );
    }

    protected getShapesList(): Array<ShapeModel> {
        const { changeModel } = this;
        const selected = this.state.get( 'Selected' );
        const selectedShapes = selected
            .map( shapeId => changeModel.shapes[shapeId])
            .filter( s => !( s instanceof ConnectorModel ));
        return selectedShapes.length ? selectedShapes :
            Object.values( changeModel.shapes ).filter( s => !( s instanceof ConnectorModel ));
    }

    protected isAnotherVotingActive(): boolean {
        const { changeModel } = this;
        return Object.values( changeModel.shapeVotings ).reduce(( res, next ) =>
            res || next.endTime > Number( new Date())
        , false );
    }
}

Object.defineProperty( StartShapeVoting, 'name', {
    value: 'StartShapeVoting',
});
