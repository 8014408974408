import { IDialogBoxData } from 'flux-definition';

/**
 * Permission error. This is thrown by creately apps whenever a user
 * tries to invoke and action that does not have sufficient plan
 * permission.
 */
export class PermissionError extends Error {
    constructor( message?: string, public data?: IDialogBoxData ) {
        super( message )/* istanbul ignore next */;
        Object.setPrototypeOf( this, PermissionError.prototype );
    }
}
