import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ApplyTextBounds
 * This command is to update text bounds related properties
 */
@Injectable()
@Command()
export class ApplyTextBounds extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
     public data: {
        [shapeId: string]: {
            [textId: string]: {
                width: number,
                height: number,
                rendering: 'carota' | 'tiptapCanvas' | 'dom' },
        },
    };

    public execute() {
        if ( this.data ) {
            Object.keys( this.data ).forEach( shapeId => {
                Object.keys( this.data[ shapeId ]).forEach( textId => {
                    const { width, height, rendering } = this.data[ shapeId ][ textId ];
                    const textModel = this.changeModel.shapes[ shapeId ].texts[ textId ] as any;
                    if ( textModel && width !== undefined ) {
                        this.changeModel.shapes[ shapeId ].texts[ textId ].width = width;
                    }
                    if ( textModel ) {
                        textModel.rendering  = rendering;
                        textModel.height  = height;
                    }
                });
            });
        }
        return true;
    }

}

Object.defineProperty( ApplyTextBounds, 'name', {
    value: 'ApplyTextBounds',
});
