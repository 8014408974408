import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DiagramInfoModel } from '../../diagram/model/diagram-info.mdl';

/**
 * This is an abstract command marks the diagram as deleted and sends the message
 * to server if the message is success then deletes the document from cache.
 *
 * data: {}
 */
export abstract class AbstractDeleteDiagram extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    constructor( protected dataStore: DataStore, protected modelSubManager: ModelSubscriptionManager ) {
        super() /* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    /**
     * Markes the diagram in cache as deleted.
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel );
        return fromPromise( modelStore.updateStatic({ id: this.resourceId }, { $set: { deleted: true }}));
    }

    /**
     * Delete the diagram from cache and stops diagram subscription.
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel );
        this.modelSubManager.stop( this.resourceId, true );
        return fromPromise( modelStore.remove({ id: this.resourceId }));
    }
}
