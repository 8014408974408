import { Injectable } from '@angular/core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { Command } from 'flux-core';
import { LibraryState } from 'flux-definition';
import { Authentication } from 'flux-user';
import { ILoadedLibrary } from '../../library/loaded-library.state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { findIndex, clone } from 'lodash';

/**
 * Updates the diagram description and tags.
 *
 *
 */
@Injectable()
@Command()
export class UpdateLibraries extends AbstractDiagramChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        libraries: ILoadedLibrary[],
    };

    constructor(
        protected ds: DiagramChangeService,
        protected authService: Authentication,
    ) {
        super( ds );
    }

    public prepareData() {
        if ( this.data.libraries ) {
            const libInfo: any = this.data.libraries.map( libItem => {
                const state = libItem.isCollapsed ? LibraryState.Collapsed : LibraryState.Open;
                return [ libItem.id, state ];
            }); // TODO get the current open state of the panel

            const payload = { userId: this.authService.currentUserId, libs: libInfo };

            if ( this.changeModel.libraries && this.changeModel.libraries.length > 0 ) {
                const libList = clone( this.changeModel.libraries );
                // remove the default diag type libList
                const typeIdx = findIndex( libList, lib => lib.userId === '*' );
                if ( typeIdx !== -1 ) {
                    libList.splice( typeIdx, 1 );
                }

                // find this users index and
                let inputIdx = 0;
                if ( libList.length > 0 ) {
                    inputIdx = findIndex( libList,
                        lib => lib.userId === this.authService.currentUserId );
                    if ( inputIdx === -1 ) {
                        inputIdx = libList.length;
                    }
                }

                libList[inputIdx] = payload;
                this.changeModel.libraries = libList as any;
            } else {

                this.changeModel.libraries = [ payload ];
            }

            return;
        }
        throw Error( 'There was an error setting the data to the modifier because data is not valid' );
    }
}

Object.defineProperty( UpdateLibraries, 'name', {
    value: 'UpdateLibraries',
});
