<div #window
    class="fx-cover fx-center-all modal-window-container">

    <div #windowInner class="modal-window-inner" *ngLet="selectedDataSourceOption | async as selectedPanel">
        <div class="modal-window-heading">
            <h1 class="nu2-caption-large-bold" translate>LINK_DATA_SOURCE.HEADING.LINK_DATA_SOURCE</h1>
            <button class="nu-btn-small nu-btn-icon modal-window-close-btn" (click)="closeWindow()">
                <svg class="nu-icon nu-icon-large nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
        </div>
        <div class="modal-window-content">
            <div class="horizontal-container full-height" *ngLet="selectedDataSourceOption | async as selectedPanel">
                <div class="left-sidebar full-height">
                    <ul>
                        <li *ngFor="let dataSourceOpt of dataSourceOptions" 
                            [class.active]="dataSourceOpt.id === selectedPanel"
                            (click)="selectedDataSourceOption.next(dataSourceOpt.id)">
                            <svg class="nu-icon nu-icon-med">
                                <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + dataSourceOpt.icon"></use>
                            </svg>
                            <span class="nu2-caption-3" >{{dataSourceOpt.label}}</span>
                        </li>
                    </ul>
                </div>
                <div class="content-panel full-height">
                    <connect-github-panel *ngIf="selectedPanel === 'github'"
                        class="full-height"
                        [closeWindow]="closeWindowObs"></connect-github-panel>
                    <div class="coming-soon-container" *ngIf="selectedPanel !== 'github'">
                        <div class="info-container fx-margin-bottom-25" *ngIf="selectedPanel === 'jira'">
                            <div class="info-heading">                            
                                <h1 class="nu2-heading-6 fx-margin-bottom-15" translate>LABELS.COMING_SOON_EX</h1>
                                <img src="./assets/images/data-import/data-source.svg" />
                            </div>
                            <ul class="nu2-body-small">
                                <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_1</span></li>
                                <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_2</span></li>
                                <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_3</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
