import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { OTelManager, Logger, StateService } from 'flux-core';
import { AbstractConnection } from './abstract-connection';
import { ConnectionStatus } from './connection-status';
import { ConnectionError } from './connection-error';
import { MessageFactory, ConnectionMessage } from '../message/message-factory.svc';
import { IMessageJson } from '../message/message-json';
import { Message } from '../message/message.mdl';
import { MessageType } from '../message/message-type';
/**
 * Provider for injecting the NeutrinoConnection. Injects
 * everything necessary for the connection to function.
 */
export const PROVIDE_CONNECTION = [
    MessageFactory,
];

/**
 * NeutrinoConnection
 *
 * This class is injectable service for connecting to neutrino server,
 * this manages connection initiation with handshake, deliver recieved messages,
 * handle opening, closing of connection, handle connection errors.
 * This class can be injected as in class which need to send and recieve messages with neutrino
 *
 * @author  mehdhi
 * @date    2016-04-22
 * @class   NeutrinoConnection
 * @extends {AbstractConnection}
 */
@Injectable()
export class NeutrinoConnection extends AbstractConnection {


    /**********************************************************\
    *                                                          *
    *   Init handshake related code                            *
    *                                                          *
    \**********************************************************/

    /**
     * Indicates if the init handshake has completed or not.
     * If the connection is not active this must be false.
     * This can only be true when the connection is active.
     */
    protected _handshakeComplete: boolean = false;

    /**
     * This holds the no. of connection reconnect attempts
     */
    protected noOfReconnectTries: number = 0;

    /**********************************************************\
    *                                                          *
    *       Network Connectivity Status of the device          *
    *                                                          *
    \**********************************************************/

    /**
     * This property tells whether the device is connected to
     * internet or not
     * @private
     * @type {boolean}
     */
    protected _isDeviceOnline: boolean = false;

    /**
     * Creates an instance of NeutrinoConnection.
     *
     * @param {$WebSocket} connection Instance of Angular2-websocket
     * @param {MessageFactory} messageFactory Instance of MessageFactory class
     * @param {Logger} log
     */
    constructor(
        private messageFactory: MessageFactory,
        log: Logger,
        protected state: StateService<typeof ConnectionStatus, ConnectionStatus>,
    ) {
        super( log, state );
    }

    /**
     * This getter informs whether the connection to server is available or has the
     * ability to create a connection
     */
    public get isConnectionAvailable(): boolean {
        const status: ConnectionStatus = this.state.get( ConnectionStatus );
        return status === ConnectionStatus.ONLINE
            || status === ConnectionStatus.CONNECTED
            || status === ConnectionStatus.SLEEP  && this._isDeviceOnline;
    }

    /**
     * Sets the isDeviceOnline property.
     * FIXME: This needs to merge into connection status.
     */
    public set isDeviceOnline( isDeviceOnline: boolean ) {
        this._isDeviceOnline = isDeviceOnline;
    }

    /**
     * This function sends messages to the neutrino server
     * and notify message sending failures
     * @param {Message} message Message to be sent.
     * @returns {Observable<IMessageJson>}
     */
    public send( message: Message ): Observable<IMessageJson> {
        let wsSpan;
        try {
            wsSpan = OTelManager.createSpanAndAttachContextToMessage( 'SEND NEUTRINO MSG', message );

            if ( this.isConnectionAvailable ) {
                return super.send( message );
            } else {
                const err = new ConnectionError( 'Connection to server is not established. Unable to send message.' );
                return throwError( err );
            }
        } finally {
            if ( !!wsSpan ) {
                wsSpan.end();
            }
        }
    }

    /**
     * This is called when network connectivity is made available to the platform.
     * This method will also attempt to create a connection to neutrino.
     */
    public onNetworkConnect() {
        this._isDeviceOnline = true;
        this.connect();
        this.log.debug( 'Device : Online' );
    }

    /**
     * This method is called when the platform loses network connectivity.
     * It will disconnect the neutrino connection and update the connection status.
     */
    public onNetworkDisconnect() {
        this._isDeviceOnline = false;
        this.disconnect();
        this.updateConnectionStatus();
        this.log.debug( 'Device : Offline' );
    }

    /**********************************************************\
    *                                                          *
    *                 Connection Event Handlers                *
    *                                                          *
    \**********************************************************/

    /**
     * This function validates message logs error if message is invalid.
     *
     * @protected
     * @param {IMessageJson} message Message in the form of JSON
     * @returns {boolean} Returns true if message is valid else false
     */
    protected isValidMessage( message: IMessageJson ): boolean {
        const valid: boolean = super.isValidMessage( message );
        // TODO: Elaborately validate the message. Only specific to the connection
        return valid;
    }

    /**
     * This method handles all connection errors encountered during connection
     * to the neutrino server and updates the connection status.
     *
     * @protected
     * @param {ErrorEvent} error ({@link https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent})
     */
    protected onErrorHandler( error: ErrorEvent ) {
        this.updateConnectionStatus();
        super.onErrorHandler( error );

    }

    /**
     * This method handler handles activities after establishing  a successful connection
     * to neutrino server and updates connection status and initialize the connection
     * handshake
     *
     * @protected
     * @param {Event} event {@link https://developer.mozilla.org/en-US/docs/Web/API/Event}
     */
    protected onOpenHandler( event: Event  ) {
        this.updateConnectionStatus();
        super.onOpenHandler( event );
        this.initializeConnectionHandshake();
    }

    /**
     * This function handler handles activities after successful disconnecting from neutrino server.
     * It also updates the connection status.
     *
     * @protected
     * @param {CloseEvent} event {@link https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent}
     */
    protected onCloseHandler( event: CloseEvent ) {
        this.updateConnectionStatus();
        super.onCloseHandler( event );
    }

    /**
     * This function initialize the connection handshake by sending
     * a connection initiation message to server and handle the server's
     * response.
     */
    protected initializeConnectionHandshake() {
        if ( this._handshakeComplete ) {
            return;
        }
        const message: Message = this.messageFactory.createInitHanshakeMessage();
        // This.log.debug( 'Connection init message ', JSON.stringify( message ));
        const observable: Observable<IMessageJson> = this.send( message );
        observable.subscribe(
            msg => this.onInitResult( msg ),
            err => this.onInitError( err ),
        );
    }

    /**
     * This method handles the connection intitiation message.
     * It validate connection initiation message, completes the connection
     * handshake process and update the connection status.
     *
     * @param {IMessageJson} message Message in the form of JSON
     */
    protected onInitResult( message: IMessageJson ) {
        // Temporary Code
        const validMsgType = message.type === MessageType[ MessageType.conn ];
        const validMsgData = message.msg === ConnectionMessage[ConnectionMessage.init];
        if ( validMsgType && validMsgData ) {
            this._handshakeComplete = true;
            this._isDeviceOnline = true;
            this.log.debug( 'Connection with Neutrino established Successfuly' );
        } else {
            this.log.error( 'Unexpected response was recieved for the server hanshake', message );
        }
        this.updateConnectionStatus();
    }

    /**
     * This method handles the error reponse of connection handshake.
     * If any error occurs it tries to reconnect.
     *
     * @private
     * @param {Error} error
     */
    protected onInitError( error: Error ) {
        this._handshakeComplete = false;
        this.log.error( 'Error occured during init handshake!', error );
        this.reconnect();

        // TODO: Based on the error that occured we need to decide if to retry
        // And if so what to retry. Error such as send faliurs and network errors
        // Must retry.
        // If (this.noOfReconnectTries < 3) {
        //     This.noOfReconnectTries++;
        //     This.log.error( 'Connection Handshake failed, retrying to connect', error);
        //     This.reconnect();
        // } else {
        //     This.log.error( 'Connection Handshake failed', error);
        //     This.disconnect();
        // }
    }


    /**********************************************************\
    *                                                          *
    *             Functions related to application             *
    *                   connection status                      *
    *                                                          *
    \**********************************************************/

    /**
     * This function updates connection status of the application.
     * and updates both asychronous status stream update and synchronus
     * status. This function should be called in places where connection
     * should be updated.
     */
    protected updateConnectionStatus() {
        if ( this.isWebSocketOpen ) {
            this.state.set( ConnectionStatus,
                this._handshakeComplete ? ConnectionStatus.ONLINE : ConnectionStatus.CONNECTED );
        } else {
            this.state.set( ConnectionStatus,
                this._isDeviceOnline ? ConnectionStatus.SLEEP : ConnectionStatus.OFFLINE );
            this._handshakeComplete = false;
        }
    }
}
