import { IShapeDefinition } from 'flux-definition';
import { StateService } from 'flux-core';

/**
 * FIXME: This class must be refactored or removed later.
 * It is currently setting a state that is unused in the app.
 *
 * An abstraction for all the search classes.
 *
 * @author Shermin
 * @since 2019-08-06
 */

export class AbstractSearch  {

    public constructor( protected state: StateService<any, any> ) {}

    /**
     * Setting the state of the result from the search
     * State changes will trigger displaying of the result of search panel
     * @param filteredDefs - An array of shapes definitions
     * @param searchText - Search Query
     */
    protected setState( filteredDefs: IShapeDefinition[], searchText: string ) {
        const filteredDefIds = filteredDefs.map( filteredDef =>
                                ({ defId: ( filteredDef as any ).id ,
                                    version: ( filteredDef as any ).data &&
                                        ( filteredDef as any ).data.version ?
                                        ( filteredDef as any ).data.version : 0,
                                }),
        );
        this.state.set( 'ShapeSearchResults',
                {   results: filteredDefIds,
                    searchQuery: searchText });
    }

}

