import { Tracker } from 'flux-core';
import { IDataItemUIControl } from './data-items-uic.i';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, Input, ElementRef } from '@angular/core';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { map, startWith, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { go } from 'fuzzysort';

@Component({
    selector: 'entity-lookup-uic',
    template: `
        <mat-form-field class="entity-list">
            <mat-label>Linked Entities</mat-label>
            <mat-chip-list #chipList>
                <mat-chip
                    *ngFor="let entity of (selection | async)"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(entity)">
                    <span class="entity-name fx-ellipsis">{{ entity.getDisplayText() }}</span>
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input
                    #lookupInput
                    [formControl]="lookupCtrl"
                    [matChipInputFor]="chipList"
                    [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let entity of filteredEntities | async" [value]="entity">
                    <div class="selected-item" >
                        <div>{{ entity.getDisplayText() }}</div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    `,
    styleUrls: [ './entity-lookup-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * UI control for SingleSelectCombo
 */
export class EntityLookupMultipleUIC implements IDataItemUIControl<string[]> {
    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
    @Input()
    public context?: string;

    public id: string;

    public change: Subject<string[]>;

    public entitiesSubject: BehaviorSubject<EntityModel[]>;
    public filteredEntities: Observable<EntityModel[]>;

    public selection: BehaviorSubject<EntityModel[]>;

    /**
     * Bind keys to add a new item
     */
    public separatorKeysCodes: number[] = [ ENTER, COMMA ];

    public lookupCtrl = new FormControl();

    @ViewChild( 'lookupInput', { static: false })
    public lookupInput: ElementRef<HTMLInputElement>;

    protected subs = [];

    constructor( private ell: EDataLocatorLocator ) {
        this.entitiesSubject = new BehaviorSubject([]);
        this.selection = new BehaviorSubject([]);
        this.change = new Subject();
        this.filteredEntities = this.getFilteredEntities();
    }


    public setData( data: any ) {
        const { eDataId, eDefId, eDataDefId } = data.options;
        ( eDataId ? this.ell.getEData( eDataId ).pipe(
            switchMap( locator => locator.getEDataModelOnce()),
        ) : this.ell.currentEDataModels().pipe( map( eDataModels =>
            eDataModels.find( model => model.defId === eDataDefId ) || {
                entities: {},
            }))
        ).subscribe( model => {
            const entities = Object.values( model.entities ).filter( e => e.eDefId === eDefId );
            this.entitiesSubject.next( entities );
            if ( data.value && data.value.length > 0 ) {
                this.selection.next( entities.filter( e => data.value.includes( e.id )));
            }
        });

    }

    /**
     * Handler function for selecting items from the suggestions list
     */
    public selected( event: MatAutocompleteSelectedEvent ) {
        const entity = event.option.value;
        const entities = this.selection.value;
        if ( !entities.find( e => e.id === entity.id )) {
            entities.push( entity );
            this.selection.next( entities );
            this.emitChanges();
        }
        this.lookupInput.nativeElement.value = '';
        this.lookupCtrl.setValue( null );
    }

    public add( event: MatChipInputEvent ) {
    }

    /**
     * Remove the specified usedr and emits the "change" subject
     */
    public remove( entity: EntityModel ): void {
        const entities = this.selection.value;
        const index = entities.findIndex( u => u.id === entity.id );
        if ( index >= 0 ) {
            entities.splice( index, 1 );
        }
        this.selection.next( entities );
        this.emitChanges();
    }

    /**
     * Map the current CollabModel array to IPerson opjects and emits the change subject
     */
    public emitChanges() {
        this.change.next( this.selection.value.map( e => e.id ));

        if ( this.context ) {
            Tracker.track( `${this.context}.lookup.change` );
        }
    }

    protected getFilteredEntities() {
        return this.lookupCtrl.valueChanges.pipe(
            startWith( null ),
            switchMap( keystrokes => this.entitiesSubject.pipe(
                map( entities => keystrokes ? this._filter( keystrokes, entities ) : entities )),
            ),
        );
    }

    /**
     * Filter out the given objects by the given keyStroke
     */
    private _filter( keyStroke: string, entities: EntityModel[]) {
        try {
            const objects = entities.map( e => ({
                id: e.id,
                name: e.getDisplayText(),
            }));
            const entById = {};
            for ( const entity of entities ) {
                entById[entity.id] = entity;
            }
            /* istanbul ignore else */
            if ( typeof keyStroke === 'string' ) {
                const results = go( keyStroke, objects, {
                    keys: [ 'name' ],
                });
                return results.map( r => entById[r.obj.id]) as any;
            }
        } catch ( error ) /* istanbul ignore next */ {
            // tslint:disable-next-line: no-console
            console.error( error );
        }
        /* istanbul ignore next */
        return [];
    }

}
