import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalController } from 'flux-core';
import { PopupWindow } from 'flux-core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Authentication } from '../../user/authentication.svc';

/**
 * auth window component.
 */
@Component({
    templateUrl: 'desktop-auth-window.cmp.html',
    selector: 'desktop-auth-window',
    styleUrls: [ './desktop-auth-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopAuthWindow extends PopupWindow implements OnDestroy, OnInit {

    public waitingForLogin = false;
    public otiExpired = false;
    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The auth window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected modalController: ModalController,
        protected authService: Authentication,
        protected translate: TranslateService,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        this.subs = [];
    }

    /**
     * Show window and subscribe to post message api.
     */
    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
        );
    }

    public waitForLogin(): void {
        this.waitingForLogin = true;
        this.otiExpired = false;
        this.authService.loginWithOTI().subscribe({
            next: success => {
                this.waitingForLogin = false;
                if ( success ) {
                    this.closeWindow();
                } else {
                    this.otiExpired = true;
                    this.changeDetectorRef.detectChanges();
                }
            },
        });
    }

    /**
     * Close the auth window.
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
}
