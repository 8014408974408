import { CommandInterfaces } from 'flux-core';
import { SendHttpExecutionStep } from './exec-step-send-http';

/**
 * SendWsExecutionStep is a command execution step which will send
 * the command data to the server via WebSocket.
 */
export class SendHttpSyncExecutionStep extends SendHttpExecutionStep {

    public static get relatedInterfaces(): Array<CommandInterfaces | CommandInterfaces[]> {
        return [ 'ISyncMessageCommand' ];
    }

    public get asynchronous(): boolean {
        return false;
    }
}

Object.defineProperty( SendHttpSyncExecutionStep, 'name', {
    value: 'SendHttpSyncExecutionStep',
});
