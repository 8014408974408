import { DataType, IValidationError, IStyleDefinition } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * This does validation of custom shape style data items.
 * For the moment no validation is required.
 */
export class CustomShapeStyleDataItem extends AbstractDataItem<DataType.CUSTOM_SHAPE_STYLE> {
    /**
     * Validate given shape style definition.
     * Validation is not required for the moment.
     * @param value
     */
    public validate( value: IStyleDefinition ): IValidationError {
        return null;
    }
}
