import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { CommentModel } from '../../diagram/model/comment.mdl';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * This command will edit a comment on the diagram. Edit will only edit the
 * comment text.
 */
@Injectable()
@Command()
export class EditComment extends AbstractMessageCommand {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            commentId: true,
        };
    }

    /**
     * List of commands which will be used by this class.
     */
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    /**
     * Data preperation before the execution.
     */
    public prepareData() {
        this.previousData = {};
        return this.dataStore.findOne( CommentModel, { id: this.data.commentId, diagramId: this.resourceId }).pipe(
                        take( 1 ),
                        tap( c => this.previousData.comment = c.comment ),
                    );
    }

    /**
     * This function update the datastore with the new comment model.
     */
    public execute() {
        return this.dataStore.update( CommentModel,
                { id: this.data.commentId, diagramId: this.resourceId },
                {
                    $set: { comment: this.data.comment , edited: new Date().getTime(), comitted: false },
                });
    }

    /**
     * This function commits the changes to the datastore upon the success of
     * the execution.
     * @param response - Response data of the request.
     */
    public executeResult( response: any ) {
        return this.dataStore.update( CommentModel,
                { id: this.resultData.comment.id, diagramId: this.resourceId },
                {
                    $set: { comment: response.comment.comment , edited: response.comment.edited , comitted: true },
                });
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        return this.dataStore.update(
                CommentModel,
                { id: this.data.commentId, diagramId: this.resourceId },
                {
                    $set: { comment: this.previousData.comment },
                    $unset: { edited: '' },
                });
    }
}

Object.defineProperty( EditComment, 'name', {
    value: 'EditComment',
});
