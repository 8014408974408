import { Injectable } from '@angular/core';
import { AbstractCommand, AppConfig, Command, ImageLoader } from 'flux-core';
import { IShapeText, ResourceStatus } from 'flux-definition';
import { SVG, SvgToCanvas } from 'flux-diagram-composer';
import * as md5 from 'md5';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ImportedFile } from '../../../framework/file/imported-file';

/**
 * This command can be used to import an external file into
 * nucleus.
 * It reads data from one or more {@link ImportedFile} objects
 * and formats them in such way that another command or component
 * may use them. The way data is read and formatted will differ
 * based on the file type being imported.
 * Input data:
 *  {
 *      files: Array<ImportedFile>
 *  }
 * Result data:
 *  {
 *      files: Array<{
 *          name: string,
 *          type: string,
 *          extension: string,
 *          data: any, //file data read from ImportedFile
 *      }>
 *  }
 *
 * @author  Ramishka
 * @since   2019-02-20
 */
@Injectable()
@Command()
export class ImportImage extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: {
        files: Array<ImportedFile>,
    };

    /**
     * Image Types used here to create extension from it.
     */
    private imageTypes = {
        'image/png': 'png', 'image/jpeg': 'jpg', 'image/jpg': 'jpg',
        'image/gif': 'gif', 'image/bmp': 'bmp', 'image/x-icon': 'ico',
        'image/svg+xml': 'svg',
    };


    constructor( protected resourceLoader: ImageLoader ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Prepare command data
     */
    public prepareData(): any {
        if ( !this.data || !this.data.files || this.data.files.length < 1 ) {
            return;
        }
    }

    /**
     * Read data from files and store in resultData
     */
    public execute(): Observable<any> {
        const files = this.data.files;
        const observables = [];
        this.resultData = { files: []};
        files.forEach( file => {
            if ( this.isImageFile( file )) {
                observables.push( this.addImageFile( file ));
            }
        });
        if ( observables.length > 0 ) {
            return forkJoin( observables );
        }
        return EMPTY;
    }

    /**
     * Reads and extracts the image data as a base64 string.
     * @param file - The ImportedFile instance
     * @return - image data as base64
     */
    protected readImageFile( file: ImportedFile ): Observable<{ image: string, texts: IShapeText[]}> {
        let textData: IShapeText[];
        if ( this.isVectorImage( file )) {
            return file.getAsText().pipe(
                map( text => {
                    let svg = new SVG( text );
                    svg = this.setSVGBounds( svg );
                    // If the svg is Creately classic diagram then extract the text and add them
                    // as shape texts so that they are editable.
                    if ( svg.isCreatelyClassicDiagram()) {
                        svg.removeCreatelyClassicData();
                        textData = this.extractSvgTexts( svg.getSvg());
                        svg.removeText();
                    }
                    return { image: svg.toDataURL(), texts: textData };
                }),
            );
        }
        return file.getAsDataURL().pipe( map( imageURL => ({ image: imageURL, texts: textData })));
    }

    /**
     * Extract all the texts and styles from given svg and returns as array of shape texts.
     * @param svg SVG string to extract the text data.
     */
    protected extractSvgTexts( svg: string ): IShapeText[] {
        const svgToCanvas = new SvgToCanvas();
        return svgToCanvas.convert( svg, false, true ).texts;
    }

    /**
     * Some of the imported svg images will not have width and height properties set on them.
     * These two properties must be available for svgs to be rendered properly as easeljs bitmaps.
     * This method tries to extract the width and height from the svgs viewbox, and if viewbox too
     * is not set, sets a default width and height.
     * This step can be removed once svg import is changed to have its own view and
     * not rendered as easeljs bitmaps.
     *
     * @param svgStr - SVG instance
     * @return SVG instance modified to have width and height
     *
     * FIXME: In chrome 'svg.isHeightSet()' returns false since the height is 0
     * ( Chrome returns the value asynchronously so the actual value is been set
     * after returning 0 for the height ). If chrome returns the acutal height/
     * width for 'svg.isHeightSet()' it falls into the else condition. However
     * FireFox returns the actual value synchronously.
     * This method should be refactored to get the actual height and width
     * from SVG across all browsers.
     */
    protected setSVGBounds( svg: SVG ): SVG {
        if ( !svg.isHeightSet() || !svg.isWidthSet()) {
            const viewBox = svg.viewBox;
            if ( viewBox.width > 0 && viewBox.height > 0 ) {
                svg.width = viewBox.width;
                svg.height = viewBox.height;
            } else {
                svg.width = 150;
                svg.height = 150;
            }
        } else {
            svg.width = svg.width;
            svg.height = svg.height;
        }
        return svg;
    }

    /**
     * Reads data from an image file type and stores in
     * resultData.
     * @param file - importedFile instance
     */
    private addImageFile( file: ImportedFile ) {
        return this.readImageFile( file ).pipe(
            map(({ image, texts }) => { // base64 image and text.
                const fileData = {
                    name: file.name,
                    type: file.type,
                    extension: file.extension || this.imageTypes[ file.type ],
                    data: image,
                    hash: md5( image ),
                    texts: texts,
                };
                this.resultData.files.push( fileData );
                return fileData;
            }),
            switchMap( fileData =>
                this.resourceLoader.store( this.getImageUrl( fileData.hash ),
                fileData.data, ResourceStatus.PendingUpload ),
            ),
        );
    }

    /**
     * Checks if the file being imported has a content
     * type of 'image/*'.
     * @param file - imported file instance
     * @return true if any image
     */
    private isImageFile( file: ImportedFile ): boolean {
        if ( file.type && file.type.includes( 'image/' )) {
            return true;
        }
    }

    /**
     * Checks if an image file is a svg image
     * @return true if the file is an svg
     */
    private isVectorImage( file: ImportedFile ): boolean {
        return ( file.extension === 'svg' );
    }

    /**
     * Retrieves the url of the image (applicable for image imports only)
     */
    private getImageUrl( hash: string ): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ImportImage, 'name', {
    value: 'ImportImage',
});
