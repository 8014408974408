import { AbstractConnector } from './connector-abstract';

/**
 * This class contains the capability to draw a curved
 * connector using the coordinate data stored into the model,
 * on the provided graphics object.
 *
 * @author  Ramishka
 * @since   2017-10-07
 */
export class ConnectorCurved extends AbstractConnector  {
    /**
     * Draws the curved connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        this.moveTo( points[0]);
        // connect all other points
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            if ( point.c1 ) {
                if ( point.c2 ) {
                    this.bezierCurveTo( point.c1, point.c2, point );
                } else {
                    this.quadraticCurveTo( point.c1, point );
                }
            } else {
                this.lineTo( point );
            }
        }
    }
}

Object.defineProperty( ConnectorCurved, 'name', {
    value: 'ConnectorCurved',
});
