import { DiagramInfoModel } from './../../diagram/model/diagram-info.mdl';
import { Injectable } from '@angular/core';
import { Command, StateService, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { AbstractMessageCommand } from 'flux-connection';
import { Observable, from, merge, of } from 'rxjs';

/**
 * This is the command to move documents to one project project to another project.
 * The source project is always the current project and all the diagrams to be moved
 * should be from the same project ( current porject ) and the current user should be the
 * owner of each diagram and should have the at least editor access to the destiation folder.
 *
 * @author  thisun
 * @since   2019-11-07
 */
@Injectable()
@Command()
export class MoveDiagram extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    public static get dataDefinition(): {}  {
        return {
            diagramIds: true,
            sourceProjectId: true,
        };
    }

    constructor(
            protected dataStore: DataStore,
            protected state: StateService<any, any> ) {
                super() /* istanbul ignore next */;
    }

    public execute(): any {
        this.resultData = {};
        this.resultData.sourceProjectId = this.data.sourceProjectId;
        this.resultData.destinationProjectId = this.resourceId;
        this.resultData.diagramIds = this.data.diagramIds;
        delete this.data.sourceProjectId;
        return true;
    }

    /**
     * Updates the project property of the moved diagrams to the destination project id
     */
    public executeResult( response: any ): Observable<any> {
        const obs: any = [];
        const diagramModelStore = this.dataStore.getModelStore( DiagramInfoModel );

        this.data.diagramIds.forEach( id => {
            const o = from(
                diagramModelStore.updateStatic({ id }, { $set: { project: this.resourceId }}),
            );
            obs.push( o );
        });
        this.updateProjectDiagramState();
        return merge( ...obs );
    }

    /**
     * Execute the 'executeResult' after updating the resultData.
     * NOTE: Updating result data to manipulate the response as
     * it is from server.
     */
    public executeNetworkOffline(): Observable<any> {
        return of({ sourceProjectId: this.resourceId });
    }

    /**
     * Updates the ProjectDiagrams state
     */
    private updateProjectDiagramState() {
        const allDiagrams = this.state.get( 'ProjectDiagrams' );
        const sourceProjectId = this.resultData[0].sourceProjectId;
        const sourceProjectDiagrams: DiagramInfoModel[] = allDiagrams[ sourceProjectId ];
        const destinationProjectDiagrams: DiagramInfoModel[] = allDiagrams[ this.resourceId ];

        const movedDiagrams = sourceProjectDiagrams.filter( d => {
            if ( this.data.diagramIds.includes( d.id )) {
                // FIXME: Project property in the DiagramInfoModel is defined as a ProjectModel
                // but it's always a string as @Relationship() is not working properly,
                // update this after project property issue is fixed.
                d.project = this.resourceId as any;
                return true;
            }
        });
        allDiagrams[ sourceProjectId ] = sourceProjectDiagrams
            .filter( d => !this.data.diagramIds.includes( d.id ));

        if ( destinationProjectDiagrams  ) {
            destinationProjectDiagrams.push( ...movedDiagrams );
            allDiagrams[ this.resourceId ] = destinationProjectDiagrams;
        }

        this.state.set( 'ProjectDiagrams', allDiagrams );
    }
}

Object.defineProperty( MoveDiagram, 'name', {
    value: 'MoveDiagram',
});
