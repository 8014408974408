import { Component, Input, ChangeDetectionStrategy, Output, OnDestroy, OnInit } from '@angular/core';
import { StaticRenderer } from 'flux-diagram-composer';
import { TranslateService } from '@ngx-translate/core';
import { CollapsibleMenuItem, CommandService, StateService, ModalController } from 'flux-core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { EDataManage } from '../../../../../framework/edata/edata-manage.svc';
import { EDataModel } from '../../../../../base/edata/model/edata.mdl';
import { ILibraryItem, LibraryPanel } from '../../panels/library-panel.cmp';
import { ShapeManageService } from '../../../../../editor/feature/shape-manage.svc';
import { ImageImportWindow } from './../image-import/image-import-window.cmp';
/**
 * A collapsible group for items in the eData view.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edata-library-group',
    template: `<div>
                    <edata-library-item
                        *ngFor="let item of entities | async"
                        id="item.id"
                        [label]="item.text.title"
                        [refChangeCount]="item.refChangeCount"
                        [icon]="item.icon"
                        [shapeCounts]="item.text.shapeCount"
                        [indent]="item.indent"
                        [isVisible]="item.isClosed | async"
                        [isParent]="item.children.length > 0"
                        (closed)="handleClose( $event, item )"
                        (dragstart)="handleDragStart( $event, item.data )">
                    </edata-library-item>
                </div>`,
    styleUrls: [ './edata-library-item.scss' ],
})


export class EDataLibraryGroup extends LibraryPanel implements OnInit, OnDestroy {


    /**
     * Shape count in document
     */
    @Input() public label: string;

    @Input() public eDataName: string;
    @Input() public eDataId: any;
    @Input() public entityDefId: Observable<string>;
    @Input() public isCustom: any;
    @Input() public eDataModel: EDataModel;


    @Input() public isVisible: boolean = true;

    @Input() public entities: Subject<{[ id: string]: ILibraryItem }>;
    @Input() public handleDragStart: ( event: any, data: any ) => void;


    /**
     * The subject that emits component feates changes. Exposed
     * outside via the <code>change</code> getter.
     */
    @Output()
    public closed: Subject<any>;

    public isClosed: boolean;

    public folderClass: BehaviorSubject<string>;

    public parent: CollapsibleMenuItem;

    protected enableImageUpload: BehaviorSubject<boolean> = new BehaviorSubject( false );
    protected subs: Subscription[];

    constructor( protected rendered: StaticRenderer,
                 protected state: StateService<any, any>,
                 protected edataManage: EDataManage,
                 protected router: Router,
                 protected cs: CommandService,
                 protected shapeManageService: ShapeManageService,
                 protected translate: TranslateService,
                 protected modalController: ModalController,
    ) {
        super( state, cs, shapeManageService );
        this.closed = new Subject();
        this.folderClass = new BehaviorSubject( 'nu-icon nu-ic-folder-thin medium-grey' );
        this.subs = [];
    }

    public get options(): any {
        const options = [];
        if ( this.enableImageUpload.value ) {
            options.push({
                id: 'importImages',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.IMPORT_IMAGES' ),
                callback: () => {
                    this.modalController.show( ImageImportWindow, {
                        inputs: {
                            importingInto: this.eDataModel,
                            entities: this.entities,
                            entityDefId: this.entityDefId,
                        },
                    });
                },
            });
        }
        if ( this.isCustom ) {
            options.push({
                id: 'delete',
                label: 'Delete',
                callback: () => this.entityDefId.subscribe( entityDefId => {
                    this.edataManage.removeType(
                        this.eDataId, entityDefId, this.label, this.eDataName );
                }),
            });
        }
        return options;
    }

    public ngOnInit() {
        this.subs.push(
            this.entityDefId.subscribe( val => {
                const dataItems = ( this.eDataModel as EDataModel ).getEntityDef( val ).dataItems;
                if ( Object.keys( dataItems ).includes( '_imageURLSource' )) {
                    this.enableImageUpload.next( true );
                    this.parent.detectChanges();
                }
            }),
        );
    }

    public ngOnDestroy() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    public toggle() {
        if ( this.isClosed ) {
            this.folderClass.next( 'nu-icon nu-ic-folder-thin' );
        } else {
            this.folderClass.next( 'nu-icon nu-ic-folder-thin' );

        }
        this.isClosed = !this.isClosed;
        this.closed.next( this.isClosed );
    }
}
