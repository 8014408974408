import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { DiagramInfoModel, DiagramInfoModelStore } from 'flux-diagram';
import { DataStore } from 'flux-store';

/**
 * This command is used to create a history snapshot for current diagram
 * Also this command is used to rename a history item.
 *
 * data: {
 *     diagramId id of the resource
 * }
 */
@Injectable()
@Command()
export class CreateDiagramSnapshot extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            diagramId: true,
            lastChangeId: false,
            name: false,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ) {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
        if ( !response.snapshot ) {
            return modelStore.renameHistoryItem( this.resourceId, this.data.lastChangeId, this.data.name );
        }
        return modelStore.insertHistoryItem( this.resourceId, response.snapshot );
    }
}

Object.defineProperty( CreateDiagramSnapshot, 'name', {
    value: 'CreateDiagramSnapshot',
});
