import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ChangeCollabsCursorsVisibility
 * Shows and hides collabs cursors on diagram level
 */
@Injectable()
@Command()
export class ChangeCollabsCursorsVisibility extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        hideCursors?: boolean,
        showEditorsCursors?: boolean,
        showModeratorCursors?: boolean,
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData() {
        const curSettings =
            this.changeModel.collaboratorsCursors && this.data.hideCursors !== false ?
            this.changeModel.collaboratorsCursors : {
            hideCursors: false,
            showEditorsCursors: false,
            showModeratorCursors: false,
        };
        this.changeModel.collaboratorsCursors = {
            hideCursors: this.data.hideCursors !== undefined ?
                this.data.hideCursors : curSettings.hideCursors || false,
            showEditorsCursors: this.data.showEditorsCursors !== undefined ?
                this.data.showEditorsCursors : curSettings.showEditorsCursors || false,
            showModeratorCursors: this.data.showModeratorCursors !== undefined ?
                this.data.showModeratorCursors : curSettings.showModeratorCursors || false,
        };
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ChangeCollabsCursorsVisibility, 'name', {
    value: 'ChangeCollabsCursorsVisibility',
});
