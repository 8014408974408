import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UserLocator } from 'flux-user';

/**
 * StartShapeVoting
 * This command starts shape voting based on given parameters
 */
@Injectable()
@Command()
export class UpdateShapeVoting extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        voteId: string,
        endTime: number,
        shapeId: string,
        votes: number,
        votesCast: number,
        isResultSeen: boolean,
    };

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected userLocator: UserLocator,
        protected state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<any> {
        const { changeModel, data } = this;
        const currentUserId = this.state.get( 'CurrentUser' );

        return this.userLocator.getUserInfo( currentUserId ).pipe(
            take( 1 ),
            tap( userData => {
                if ( !changeModel.shapeVotings[data.voteId].users.find( user => user.userId === currentUserId )) {
                    const users = changeModel.shapeVotings[data.voteId].users;
                    users.push({
                        userId: currentUserId,
                        votedShapes: {},
                        model: userData,
                        votesCast: 0,
                    });
                    changeModel.shapeVotings[data.voteId].users = users;
                }
                if ( data.endTime ) {
                    changeModel.shapeVotings[data.voteId].endTime = data.endTime;
                }
                if ( data.isResultSeen ) {
                    changeModel.shapeVotings[data.voteId].isResultSeen = true;
                }
                if ( data.shapeId ) {
                    const users = changeModel.shapeVotings[data.voteId].users;
                    const userIndex = users.findIndex( u => u.userId === currentUserId );
                    const user = users[userIndex];
                    user.votesCast = data.votesCast;
                    user.votedShapes[data.shapeId] = data.votes;
                    users[userIndex] = user;
                    changeModel.shapeVotings[data.voteId].users = users;
                }
            }),
        );
    }
}

Object.defineProperty( UpdateShapeVoting, 'name', {
    value: 'UpdateShapeVoting',
});
