import { Injectable } from '@angular/core';
import * as Carota from '@creately/carota';
import { Command, StateService } from 'flux-core';
import { DataType, IShapeText } from 'flux-definition';
import { TextFormatter } from 'flux-diagram-composer';
import { merge, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Change the text position of the texts in the selected shapes
 * FIXME : Currently applies if the selected shape has only one text
 *
 * Input data :
 *  {   shapeIds
 *      alignX
 *      alignY
 *      xType
 *      yType
 *      x
 *      y
 *  }
 */
@Injectable()
@Command()
export class PositionText extends AbstractDiagramChangeCommand {

    /**
     * The styles that are boolean type
     */
    public static TEXT_PADDIN = 10;

    public static get dataDefinition(): {}  {
        return {
            shapeIds: true,
            positionString: false,
            data: false,
            format: false,
        };
    }

    protected formatter: TextFormatter;

    constructor(
            protected state: StateService<any, any>,
            protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
        this.formatter = new TextFormatter();
    }

    /**
     * Prepares the modifiers
     */
    public prepareData() {
        const obs = [ of({}) ];
        if ( !this.data ) {
            // Return with just empty modifier so the command execution will be cancelled.
            return;
        }
        this.data.modifier = {};
        const shapeIds: string[] = this.data.shapeIds === undefined
                    ? this.state.get( 'Selected' ) : this.data.shapeIds;
        for ( const id of shapeIds ) {
            const shapeModel = this.changeModel.shapes[id];
            const textKeys = Object.keys( shapeModel.texts );

            if ( !this.data.data ) {
                this.data.data = {};
            }
            if ( this.data.format?.['*']?.styles?.align ) {
                const align = this.data.format['*'].styles.align;
                if ( align === 'center' ) {
                    this.data.data.alignX = 0;
                    this.data.data.xType = 'relative';
                    this.data.data.x = 0.5;
                } else if ( align === 'left' || align === 'justify' ) {
                    this.data.data.alignX = -1;
                    this.data.data.xType = 'fixed-start';
                    this.data.data.x = 1;
                } else {
                    this.data.data.alignX = 1;
                    this.data.data.xType = 'fixed-end';
                    this.data.data.x = 1;
                }
            }

            if ( textKeys.length !== 1 ) {
                const o = this.handleShapesWithContainerRegions( id );
                obs.push( ...o );
                continue;
            }
            const ob = this.updateText( id, textKeys[0]);
            obs.push( ...ob );
        }
        return merge( ...obs );
    }

    protected updateText( shapeId, textId ) {
        const obs = [ of({}) ];
        const shapeModel = this.changeModel.shapes[shapeId];
        const txt = shapeModel.texts[ textId ] as IShapeText;

        if ( this.data.data.xType && this.data.data.x !== undefined ) {
            txt.xType = this.data.data.xType;
            txt.x = this.data.data.xType === 'relative' ? this.data.data.x :
                this.data.data.x * PositionText.TEXT_PADDIN;
        }

        if ( this.data.data.yType && this.data.data.y !== undefined ) {
            txt.yType = this.data.data.yType;
            txt.y = this.data.data.yType === 'relative' ? this.data.data.y :
                this.data.data.y * PositionText.TEXT_PADDIN;
        }

        if ( this.data.data.alignY !== undefined ) {
            txt.alignY = this.data.data.alignY;
        }

        if ( this.data.data.alignX !== undefined ) {
            const alignX = this.data.data.alignX === 0 ? 'center' :
            this.data.data.alignX === -1 ? 'left' : 'right';
            ( txt as any )._alignX = this.data.data.alignX;

            const textBounds = TiptapDocumentsManagerShapeText.getTextBounds( this.changeModel.id, shapeId, textId );
            if ((( txt as any ).rendering === 'tiptapCanvas' ) || (( txt as any ).rendering === 'dom' )) {
                const observable = TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shapeId, textId, { align: alignX }).pipe(
                    tap( formatted => {
                        if ( formatted ) {
                            txt.html = formatted;
                        }
                    }),
                );
                obs.push( observable );
            } else {
                TiptapDocumentsManagerShapeText.applyTextStyles(
                    this.changeModel.id, shapeId, textId, { align: alignX }).subscribe();
                const content = this.formatter.applyRaw(
                    txt.content, { indexStart: 0, indexEnd: undefined, styles: { align: alignX }}, false );
                txt.content = content;
                txt.value = Carota.html.html( content );
            }

            if ( this.data.data.wordWrap ) {
                txt.wordWrap = this.data.data.wordWrap;
            }

            txt.width = textBounds.width;
            txt.height = textBounds.height;

            if ( this.data.positionString ) {
                ( txt as any ).positionString = this.data.positionString;
            } else if (( txt as any ).positionString && ( txt as any ).positionString !== 'none' ) {
                const parts = ( txt as any ).positionString.split( '-' );
                ( txt as any ).positionString = `${parts[0]}-${parts[1]}-${alignX}`;
            }
        }

        return obs;
    }

    protected handleShapesWithContainerRegions( containerId: string ) {
        const data = this.changeModel.shapes[ containerId ].data;
        const cellIds = Object.keys( data )
            .filter( id => data[ id ].type === DataType.CHILD_SHAPE );
        let selectedCells = cellIds.filter( id => !!data[ id ].value.selected );
        if ( selectedCells.length === 0 ) {
            selectedCells = cellIds;
        }
        const observables = [];
        ( selectedCells )
            .forEach( rId => {
                const obs = this.updateText( containerId, rId );
                observables.push( ... obs );
            });
        return observables;
    }

}

Object.defineProperty( PositionText, 'name', {
    value: 'PositionText',
});
