import { Injectable } from '@angular/core';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Command, StateService } from 'flux-core';
import { DEFUALT_TEXT_STYLES, IShapeDefinition } from 'flux-definition';
import { CarotaUtils, TextFormatter } from 'flux-diagram-composer';
import { take, tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { cloneDeep } from 'lodash';
import * as Carota from '@creately/carota';

/**
 * ReplaceTextShape
 * Switch an svg shape with `repalceText` with a Text shape.
 * ( INFO Spefically implemented to convert Lucid SVG Shape to Text shape so that the user can edit. )
 *
 * @author  Resekaran
 * @since   2023-06-07
 *
 */
@Injectable()
@Command()
export class ReplaceTextShape extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
     public data: {
        id: string;
        shapes: any;
        defaultLibrary: string;
        defaultPreferences: any;
    };

    protected formatter: TextFormatter;

    /**
     * Inject the state service to get the list of selected shape ids.
     * Inject DefinitionLocator to get new shape's definition
     * Add text formatter to cmd to perform text styling related functions
     */
    constructor(
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any>,
        protected ds: DiagramChangeService ) {
        super( ds );
        this.formatter = new TextFormatter();
        this.state = state;
    }

    /**
     * Adjust shape based on preferences and new shape definition.
     */
    public prepareData() {
        this.resultData = { shapeIds: this.state.get( 'Selected' ) };

        const selectedIds = this.state.get( 'Selected' );
        if ( !selectedIds || selectedIds.length > 1  ) {
            return;
        }
        const textDefId = 'creately.basic.text';
        const textDefVersion = 2;

        return this.defLocator.getDefinition( textDefId, textDefVersion ).pipe(
            take( 1 ),
            tap(( defn: IShapeDefinition ) => {
                const textShape = cloneDeep( defn );
                const shapeId = selectedIds[ 0 ];
                const oldShape:  any = this.changeModel.shapes[ shapeId ];

                textShape.id = shapeId;

                const oldShapeWidth = oldShape.width * oldShape.scaleX;
                const oldShapeHeight = oldShape.height * oldShape.scaleY;
                textShape.scaleX = oldShapeWidth / textShape.defaultBounds.width;
                textShape.scaleY = oldShapeHeight / textShape.defaultBounds.height;
                textShape.zIndex = oldShape.zIndex;
                textShape.x = oldShape.x;
                textShape.y = oldShape.y;

                textShape.texts.main.content[ 0 ].text = oldShape.replaceText;
                textShape.texts.main.content[ 0 ].text = oldShape.replaceText;

                const html = CarotaUtils.convertToHTML( textShape.texts.main.content );
                textShape.texts.main.value = html;

                const textBounds = Carota.bounds( textShape.texts.main.content, 10000, DEFUALT_TEXT_STYLES );
                textShape.texts.main.width = textBounds.width;
                textShape.texts.main.height = textBounds.height;

                textShape.texts.main.x = ( oldShapeWidth - textBounds.width ) / 2;
                textShape.texts.main.y = ( oldShapeHeight - textBounds.height ) / 2;


                this.changeModel.shapes[ shapeId ] = textShape;
            }),
        );
    }
}

Object.defineProperty( ReplaceTextShape, 'name', {
    value: 'ReplaceTextShape',
});
