import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EDataModel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { ModalController, PopupWindow } from 'flux-core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

export enum DataImportOption {
    CSV = 'csv',
    GOOGLE_SHEETS = 'google_sheets',
    GITHUB = 'github',
    JIRA = 'jira',
    AZURE_DEVOPS = 'azure_devops',
    M365_EXCEL = 'm365_excel',
}

/**
 * This is the data import dialog.
 */
@Component({
    templateUrl: './data-import-window.cmp.html',
    selector: 'data-import-window',
    styleUrls: [ './data-import-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataImportWindow extends PopupWindow implements OnInit, AfterViewInit, OnDestroy {

    public dataImportOptions = [
        {
            id: DataImportOption.CSV,
            label: 'Import CSV',
            icon: 'csv',
        },
        {
            id: DataImportOption.GITHUB,
            label: 'Github',
            icon: 'github',
        },
        {
            id: DataImportOption.JIRA,
            label: 'Jira',
            icon: 'jira',
        },
        {
            id: DataImportOption.AZURE_DEVOPS,
            label: 'Azure DevOps',
            icon: 'azure',
        },
        {
            id: DataImportOption.GOOGLE_SHEETS,
            label: 'Google Sheets',
            icon: 'gsheets',
        },
        {
            id: DataImportOption.M365_EXCEL,
            label: 'M365 Excel',
            icon: 'gsheets',
        },
    ];

    public selectedImportOption: Subject<DataImportOption> = new BehaviorSubject( DataImportOption.CSV );
    public sidebarVisible: Subject<boolean> = new BehaviorSubject( true );
    public hasData: Subject<boolean> = new BehaviorSubject( false );
    public closeWindowObs: Subject<boolean> = new Subject();
    public importingInto: EDataModel;
    public dataImportOption: DataImportOption;

    /**
     * The window overlay.
     */
    @ViewChild( 'window' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner' ) protected containerInner;

    protected subs: Subscription[] = [];

    constructor(
        protected modalController: ModalController,
    ) {
        super()/* istanbul ignore next */;
    }

    public ngOnInit(): void {
        this.selectedImportOption.next( this.dataImportOption );
        this.subs.push( this.closeWindowObs.subscribe(() => this.closeWindow()));
    }

    public ngAfterViewInit() {
    }

    /**
     * Closes the window after animation is complete.
     */
    public closeWindow() {
        if ( this.container && this.containerInner ) {
            this.hideWindow( this.container, this.containerInner ).subscribe({
                complete: () => {
                    this.modalController.hide();
                },
            });
        }
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }
}
