<div class="modal-window-container fx-cover fx-center-all setup-2fa-window-container" #container>
    <div [ngClass]= "{
        'modal-window-inner-step1' : ( _currentStep | async ) === 'step1',
        'modal-window-inner-step2' : ( _currentStep | async ) === 'step2',
        'modal-window-inner-success' : ( _currentStep | async ) === 'success',
        'modal-window-inner-error' : ( _currentStep | async ) === 'error'
    }" #window>
        <iframe #iframe [src]="iframeUrl | safe" class="setup-2fa-window-iframe"></iframe>
    </div>
</div>
