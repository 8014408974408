import { merge } from 'lodash';
import { IDataItem, SystemType } from 'flux-definition';
import { DataType, IDataTypeParamsLookup, IDataTypeValueTypeLookup, IDataTypeValidationLookup } from 'flux-definition';
import { DataItemVisibility, IValidationError } from 'flux-definition';

/**
 * Abstract class which contains properties and methods which are common for all data item classes.
 * Although this class is a generic class, concrete classes will be for a specific data type.
 * Data items are a map of values stored inside shapes. Values in these maps will be deserialized
 * into one of the classes which extends this abstract class using the DataItemFactory.
 * See `DataItemFactory` and the `data` property on `ShapeDataModel` for more info.
 */
export abstract class AbstractDataItem<T extends DataType> implements IDataItem<T> {
    /**
     * Creates a new data item instance with given data.
     * NOTE: call this function with the correct context.
     */
    public static create( data: any ): IDataItem<any> {
        const item = new ( this as any )();
        return merge( item, data );
    }

    public id: string;

    /**
     * Data items will be sorted by this field
     */
    public index: number;

    /**
     * The type of the data item (string, number, etc.)
     */
    public type: T;

    /**
     * Additional options for customizing the type
     */
    public typeParams: IDataTypeParamsLookup[T];

    /**
     * The value of the data item
     */
    public value: IDataTypeValueTypeLookup[T];

    /**
     * The validation rules for this data item
     */
    public validationRules: IDataTypeValidationLookup[T];

    /**
     * The label should be a short string to identify the data item.
     */
    public label?: string;

    /**
     * An array of objects which describes how the item is visible to the user
     */
    public visibility: DataItemVisibility[];

    /**
     * If this dataItem is defined due to a relationship to another
     * item, this is added.
     *
     * Ex. if this dataItem value is updated because of something that happens
     * on the other related entity/container this referencing is used. Current use cases
     * are Containers like the timeline/2x2 grid.
     *
     * When the source changes it's label or definition, this dataItem
     * should also change.
     *
     * Usually the label is set as
     * <Referring Entity Name>.<Referring Entity DataItem Label>
     */
    public referenceType?: DIReferenceType;

    /**
     * Reference holder is either a diagramId or a eDataId
     */
    public refHolderId?: string;

    /**
     * The referring shapeId or entityId
     */
    public refItemId?: string;

    /**
     * The referring dataItem id
     */
    public refDataItemId?: string;

    /**
     * Is this a reserved system types like Role, Estimate etc.
     */
    public systemType?: SystemType;

    /**
     * Smart container Types. Eg. SmartContainerType.TIMELINE
     */
    public smartContainerType?: SmartContainerType;

    /**
     * Smart container identifier value.
     */
    public smartContainerIdentifier?: string;

    protected _roleId: string;

    public get roleId(): string {
        return this._roleId;
    }

    public set roleId( val: string ) {
        if ( this.systemType === SystemType.Role ) {
            throw Error ( 'Cannot set roleId for role type fields' );
        }
        this._roleId = val;
    }

    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public abstract validate( value: IDataTypeValueTypeLookup[T]): IValidationError;
}


export enum DIReferenceType {
    Shape,
    Entity,
}

export enum SmartContainerType {
    XWithWidth = 'XWithWidth',
    XY = 'XY',
    Region = 'Region',
}
