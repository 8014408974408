import { CommandInterfaces } from 'flux-core';
import { SendWsExecutionStep } from './exec-step-send-ws';

/**
 * SendWsExecutionStep is a command execution step which will send
 * the command data to the server via WebSocket.
 */
export class SendWsSyncExecutionStep extends SendWsExecutionStep {

    public static get relatedInterfaces(): Array<CommandInterfaces | CommandInterfaces[]> {
        return [ 'ISyncMessageCommand' ];
    }

    public get asynchronous(): boolean {
        return false;
    }
}

Object.defineProperty( SendWsSyncExecutionStep, 'name', {
    value: 'SendWsSyncExecutionStep',
});
