import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Command, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CreateProject } from './create-project.cmd';

/**
 * Duplicates project and its content( Diagrams & EData ) on the server side.
 * Created project will be added here immediately.
 *
 * This extends {@link CreateProject} to work in the same approach.
 */
@Injectable()
@Command()
export class DuplicateProject extends CreateProject {

    constructor(
        protected dataStore: DataStore,
        protected modelSubManager: ModelSubscriptionManager,
        protected state: StateService<any, any>,
        protected router: Router,
    ) {
            super( dataStore, modelSubManager, state )/* istanbul ignore next */;
    }

    /**
     * This function will be executed only if the application is online.
     * Oterwise the command will fail.
     */
    public executeNetworkOffline(): Observable<any> {
        // NOTE: This command can only excuted if the user is online.
        return of( false );
    }

    /**
     * When result is receieved get the homeDIagram of duplicated project and
     * navigate to that diagram.
     * @param response
     * @returns
     */
    public executeResult( response: any ): Observable<any> {
        const res = super.executeResult( response );
        if ( response.homeDiagram ) {
            return from( this.router.navigate([ response.homeDiagram + '/edit' ])).pipe(
                switchMap(() => res ),
            );
        }
        return res;
    }
}
Object.defineProperty( DuplicateProject, 'name', {
    value: 'DuplicateProject',
});
