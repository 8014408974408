import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * A generic and stateless badge UI component
 *
 * @author Hashan
 * @since 2021-08-10
 */
@Component({
    selector: 'nu-badge',
    templateUrl: './badge.cmp.html',
    styleUrls: [ './badge.scss' ],
})
export class Badge {
    /**
     * Text to be displayed
     */
    @Input() public badgeText: BehaviorSubject<string>;

    /**
     * Background color of the badge
     */
    @Input() public textColor: string;

    /**
     * Text color of the badge
     */
    @Input() public bgColor: string;

    /**
     * This indicates whether the badge should
     * show the dot or the text.
     */
    @Input() public expanded: boolean;
}
