import { isEqual, mergeWith } from 'lodash';

/**
 * AbstractModel
 * The most abstract form of a model. This contains
 * common functionality that are common to any and all models.
 * Must be extended by every model that is created.
 *
 * @author  hiraash
 * @since   2015-10-07
 */
export class AbstractModel {
    /**
     * isModelType checks whether the given type is a valid model type.
     * The type should be a class which extends the AbstractModel class.
     */
    public static isModelType( type: typeof AbstractModel ): boolean {
        return Boolean((
            ( type instanceof Object ) &&
            ( type.prototype instanceof AbstractModel ) &&
            !type.isAbstractType()
        ));
    }

    /**
     * isAbstract returns whether the model type is an abstract type or
     * a concrete model type. Example: AbstractModel is an abstract type.
     */
    public static isAbstractType(): boolean {
        return this === AbstractModel;
    }

     /**
      * lineage returns all parent models starting from current model.
      * The result will also include the current model (as the first).
      *
      * NOTE:
      *
      * In typescript the parent class can be taken by:
      *
      *   Object.getPrototypeOf( MyClass.prototype ).constructor;
      *
      * This method is different from ES6 classes, for which it should return
      * the parent class for:
      *
      *   Object.getPrototypeOf( MyClass )
      *
      */
    public static getLineage(): ( typeof AbstractModel )[] {
        if ( this.isAbstractType()) {
            return [];
        }
        let root: typeof AbstractModel = this;
        let next: typeof AbstractModel = Object.getPrototypeOf( root.prototype ).constructor;
        const lineage: ( typeof AbstractModel )[] = [ root ];
        while ( !next.isAbstractType()) {
            lineage.push( next );
            root = next;
            next = Object.getPrototypeOf( root.prototype ).constructor;
        }
        return lineage.reverse();
    }

    /**
     * getModelLevel
     * getModelLevel returns the number of valid parent models of current model.
     * This is used to identify the amount of detail the model contains.
     *
     * Example:
     * A model with level 1 is a base model ( extends an abstract model )
     * A model with level 2 contains the base model plus additional info.
     *
     */
    public static getModelLevel(): number {
        return this.getLineage().length;
    }

     /**
      * rootModel returns the top most ancestor type which is not an abstract
      * model type. This will walk the prototype chain of the type to find it.
      */
     public static getRootModel(): typeof AbstractModel {
         return this.getLineage()[0] || null;
     }

     /**
      * Specify whether model includes history metadata also.
      * @return true if the model includes history metadata
      */
      public static hasHistoryMetaInfo(): boolean {
          return false;
      }

    /**
     * The ID field of the model.
     * This will be used by the extending Models
     */
    public id: string;

    /**
     * The latest change id field of the model.
     */
    public lastChangeId: string;

     /**
      * constructor creates a new model instance.
      */
    constructor ( id: string, extension?: Object ) {
        this.id = id;
        if ( extension ) {
            this.extender( extension );
        }
    }

    /**
     * Returns the model's type
     */
    public getModelType(): typeof AbstractModel {
        return Object.getPrototypeOf( this ).constructor;
    }

    /**
     * Extends the current instance with the properties of the given object.
     * If the model contains other models as fields, recivsively call extender.
     * @param ignoreUnwritable - Non writerable properties will not be modified.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public extender( data: Object, ignoreUnwritable: boolean = false  ): AbstractModel {
        for ( const key in data ) {
            if ( !data.hasOwnProperty( key ) || data[key] === undefined ) {
                continue;
            }
            const val = data[key];
            if ( this[key] instanceof AbstractModel ) {
                this[key].extender( val );
            } else if ( this[key] && this[key] instanceof Object && !Array.isArray( this[key])) {
                mergeWith( this[key], val, ( obj, src ) => {
                    if ( Array.isArray( src )) {
                        return src;
                    }
                });
            } else {
                if ( ignoreUnwritable ) {
                    const propDesc: PropertyDescriptor = Object.getOwnPropertyDescriptor( this, key );
                    if ( !propDesc || propDesc.writable ) {
                        this[key] = val;
                    }
                } else {
                    this[key] = val;
                }
            }
        }
        return this;
    }

   /**
    * Check if the data of the given field is changed or not
    * @param   AbstractModel  Model to check the fields
    * @param   String  the name of the field to check
    */
    public isFieldDifferent( model: AbstractModel, field: string ): boolean {
        return !isEqual( this[ field ], model[ field ]);
    }

}

Object.defineProperty( AbstractModel, 'name', {
  writable: true,
  value: 'AbstractModel',
});
