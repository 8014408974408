import DatabaseIndexParser from './db-index-parser';
import DatabaseKeyParser from './db-key-parser';
import DatabaseTypeParser from './db-type-parser';
import UMLClassAttributeSectionParser from './uml-class-attribute-section-text-parser';
import UMLClassFunctionSectionParser from './uml-class-function-section-text-parser';
import UMLClassStereoTypeSectionParser from './uml-class-stereotype-section-text-parser';
import UMLClassHeaderSectionParser from './uml-class-header-section-text-parser';
import UMLClassExtPointSectionParser from './uml-extpoint-text-parser';

const parsers = [
    UMLClassAttributeSectionParser,
    UMLClassFunctionSectionParser,
    UMLClassStereoTypeSectionParser,
    UMLClassHeaderSectionParser,
    DatabaseTypeParser,
    DatabaseKeyParser,
    DatabaseIndexParser,
    UMLClassExtPointSectionParser,
];

export default parsers;
