import { CommandInterfaces, Command } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';

/**
 * This is the command to get all projects for a given user.
 * Responds with a list of {@link ProjectModel} which is accessible
 * to the user.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetUserProjectsInfo extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

}

Object.defineProperty( GetUserProjectsInfo, 'name', {
    value: 'GetUserProjectsInfo',
});
