import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig, RequestMethod } from 'flux-core';
import { Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * This command use for get help center articles from zendesk api
 * Command use input data as search query and numberOfArticle
 * Pass search text query into sendesk articles search api and get the result
 * @since   2021-07-08
 * @author  Sajeeva
 */
@Injectable()
@Command()
export class GetHelpCenterArticles extends AbstractHttpCommand  {

    public static get dataDefinition(): {}  {
        return {
            searchQuery: true,
            numberOfArticle: true,
        };
    }

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    constructor() {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        const query = `?query=${this.data.searchQuery}&page=1&per_page=${this.data.numberOfArticle}`;
        return AppConfig.get ( 'ZENDESK_HELP_CENTER_URL' ) + query;
    }

    /**
     * Encode search text query
     */
    public prepareData(): any {
        this.data.searchQuery = encodeURI( this.data.searchQuery );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return empty();
    }
}

Object.defineProperty( GetHelpCenterArticles, 'name', {
    value: 'GetHelpCenterArticles',
});
