import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeBoundsLocator } from '../containers/shape-bounds-locator';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';


/**
 * GetNearestShape
 * This command will return shapes nearest to each given, excluding given
 *
 *  data: {
 *      shapeIds: string[],
 *  }
 *
 *  resultData: {
 *      [shapeId: string]: { shape: ShapeModel, distance: number },
 *  }
 *
 */
@Injectable()
@Command()
export class GetNearestShape extends AbstractDiagramChangeCommand  {

    public data: {
        shapeIds: string[],
    };
    constructor( protected shapeBoundsLocator: ShapeBoundsLocator,
                 protected state: StateService<any, any>,
                 protected ds: DiagramChangeService ) {
        super( ds );
    }

    public getNearestShape( shape, filteredShapes ) {
        const shapeX = shape.x + shape.width / 2;
        const shapeY = shape.y + shape.height / 2;
        return filteredShapes.reduce(( res, next ) => {
            // get center coords of the next shape
            const nextX = next.x + next.width / 2;
            const nextY = next.y + next.height / 2;
            const diffX = Math.abs( Math.max( nextX, shapeX ) - Math.min( nextX, shapeX ));
            const diffY = Math.abs( Math.max( nextY, shapeY ) - Math.min( nextY, shapeY ));
            const diff = Math.sqrt( diffX ** 2 + diffY ** 2 );
            if ( diff < res.distance ) {
                res.distance = diff;
                res.shape = next;
            }
            return res;
        }, { shape: null, distance: Infinity });
    }

    /**
     * Get parent ids for each shape
     */
    public prepareData() {
        this.resultData = {};
        for ( const shapeId of this.data.shapeIds ) {
            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
            if ( shape.type === 'connector' ) {
                continue;
            }
            const bounds = this.shapeBoundsLocator.getDiagramBounds();
            const { x: bx, y: by, width: bw, height: bh } = bounds;
            const shapes = this.shapeBoundsLocator
                .searchShapes( bx, bx + bw, by, by + bh, this.data.shapeIds )
                .filter( item => item.type !== 'connector' );
            const nearest = this.getNearestShape( shape, shapes );
            if ( nearest ) {
                this.resultData[ shapeId ] = nearest;
            }
        }
    }
}

Object.defineProperty( GetNearestShape, 'name', {
    value: 'GetNearestShape',
});
