/**
 * WindowBoundService is responsible for keeping elements in window
 */

export class WindowBoundService {

    /**
     * Method will move the bounds to keep the element in window if it is outside of the window
     * Return the displacement (distance to be moved) x and y
     */
    public static moveBoundsToContain( rectangle: ClientRect ): { x: number, y: number } {
        const displacementObj = { x: 0, y: 0 };
        if ( rectangle.left + rectangle.width >= window.innerWidth ) {
            displacementObj.x = rectangle.left + rectangle.width - window.innerWidth;
        } else if ( rectangle.left <= 0 ) {
            displacementObj.x = rectangle.left;
        }
        /**
         * TODO
         * need to implement displacement of y
         */
        return displacementObj;
    }
}
