/**
 * Fallback to copy embed text using 'document.execCommand' to
 * the clipboard if ClipboardAPI fails [inside multiple iframes]
 */
const copyTextViaFallback = ( content: string ) => {
    const el = document.createElement( 'textarea' );
    el.value = content;
    el.setAttribute( 'readonly', '' );
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild( el );
    el.select();
    document.execCommand( 'copy' );
    document.body.removeChild( el );
};

export { copyTextViaFallback };
