import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { LinkService } from './../../../base/diagram/link.svc';
import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { ShapeLinkModel, ShapeLinkType } from 'flux-diagram';
import { AppConfig, Random, StateService, NotifierController, Tracker,
    NotificationType, AbstractNotification, CommandService } from 'flux-core';
import { ImportedFile } from './../../file/imported-file';
import { IDataItemUIControl } from './data-items-uic.i';
import { map, filter, take, switchMap, last, tap } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Observer, Subject, fromEvent } from 'rxjs';
import { IDataItem, IAttachment } from 'flux-definition';
import Quill from 'quill';
import ImageUploader from '@creately/quill-image-uploader';
import * as GP from '@creately/google-picker';
import * as fileIcons from 'file-icons-js';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import ImageResize from 'quill-image-resize';
import { Clipboard } from '@creately/clipboard';
import saveAs from 'file-saver';
import { Notifications } from '../../../base/notifications/notification-messages';

/**
 * A rich text editor component which is based on quill.
 * Emits the content in quill's "Delta" format when the text area is focused out.
 * Content should be set in "Delta" format as well
 *
 * This component has following features
 * 1. Import options - can import files from google drive or from computer or by dragging and dropping.
 * 2. File embed blots - Show a blot for attached files/links
 * 3. Resizable images
 * 4. Handle attachments of the document and shape
 */
@Component({
    selector: 'rich-text-editor-uic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div *ngIf="enableUploadOptions" class="more-options">
            <abs-dropdown #dropdown [settings]="{ closeOnItemClicked: true, openOnHover: false,
                closeOnBlur: true, multiselectable: false }" direction="top" alignment="right">
                <simple-dropdown-button ddbutton [items]="getMoreOptions()">
                    <button class="nu-btn-small nu-btn-icon">
                        <svg class="nu-icon">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use>
                        </svg>
                    </button>
                </simple-dropdown-button>
                <simple-dropdown-item dditem *ngFor="let option of getMoreOptions()" [item]="option" (click)="option.action()">
                    <svg class="nu-icon light-nv-blue">
                        <use [attr.xlink:href]="option.icon" ></use>
                    </svg>
                    <a>{{option.label}}</a>
                </simple-dropdown-item>
            </abs-dropdown>
        </div>
        <div class="rich-text-editor-container" ></div>
    `,
    styleUrls: [ './rich-text-editor-uic.cmp.scss' ],
})
export class RichTextEditorUIC implements IDataItemUIControl<{ ops: any }>, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    /**
     * A behavior subject that emits when the source of the button
     * icon changes.
     */
    public valueSource: BehaviorSubject<any>;

    public quill: Quill;

    /**
     * To enable/disable Upload options
     */
    @Input()
    public enableUploadOptions: boolean = true;

    /**
     * Handler methods of blot options can be set externally using this input
     * e.g.
     *    this.richTextEditorUIC.blotOptionCallbacks = {
     *        remove: data => this.removeAttachment( data ),
     *        option1: data => alert( "option1 clicked" ),
     *    };
     */
    @Input()
    public blotOptionCallbacks: {[optionId: string]: Function } = null;

   /**
    * The duration of the notification in ms
    */
    protected notificationAutoclose: number = 3000;

    /**
     * Max file size allowed in bytes
     */
    protected maxFileSize: number = 5000000;

    /**
     * Allowed file types. Setting null will allow any type
     */
    protected allowedFileTypes: string[] = null;

    /**
     * Calling this function will show the google picker window. This method is to be set
     * as soon as the google picker is ready to show.
     */
    protected googlePickerShow: Function;

    /**
     * Subject to emit the data when a file is picked from the google picker window.
     */
    protected googlePickerOnPick: Subject<any>;

    protected subs = [];

    /**
     * This property holds observers created in listenToEvent method.
     */
    protected eventEmitters: Array<any> = [];

    /**
     * This property is to cache the current selection because the selection gets lost
     * if the editor is focused out. This is importent when inserting files from
     * external components like, google picker / uppy etc.
     */
    private selectionCache = { index: 0, length: 0 };

    /**
     * This is property is required to check if the editor content is actually changed.
     */
    private previousValue;

    constructor(
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected elementRef: ElementRef,
        protected linkSvc: LinkService,
        protected ll: DiagramLocatorLocator,
        protected command: CommandService,
        protected clipboard: Clipboard,
        protected state: StateService<any, any>,
    ) {
        this.googlePickerOnPick = new Subject();
        this.valueSource = new BehaviorSubject( '' );
        this.initGooglePicker();
        this.initQuill();
        this.subs.push( this.getSelectionChange().subscribe());
    }

    /**
     * This Observable emits when the user clicks outside the richtext editor
     * once its focused in
     */
    public get change(): Observable<{ ops: any }> {
        return fromEvent( this.quill.root, 'focusin' ).pipe(
            switchMap(() =>  this.focusedOut().pipe(
                map(() => {
                    const current =  this.quill.getContents();
                    if ( !isEqual( current, this.previousValue )) {
                        /* istanbul ignore next */
                        if ( this.context ) {
                            Tracker.track( `${this.context}.richtext.change` );
                        }
                        return current;
                    }
                }),
                filter( v => !!v ),
            )),
        );
    }

    /**
     * Unsubscribe from all subscriptions
     */
    /* istanbul ignore next */
     public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Updates the selectionCache as the selection changes
     */
    public getSelectionChange(): Observable<{ ops: any }> {
        return Observable.create( observer => {
            const handler: any = ( eventName, ...args ) => {
                const selection = this.quill.getSelection();
                if ( selection ) {
                    this.selectionCache = selection;
                }
                observer.next({ eventName, ...args });
            };
            this.quill.on( 'editor-change' as any, handler );
            this.eventEmitters.push( observer );
            return () => {
                // The observable was unsubscribed. So remove the observer from the list
                this.quill.off( 'editor-change' as any, handler );
                this.removeObserver( observer );
            };
        });
    }

    /**
     * This function completes all the observers in eventEmitters property.
     * Expected to be called when the component that extends this class is destroied
     */
    public destroy() {
        while ( this.eventEmitters.length ) {
            this.eventEmitters.pop().complete();
        }
    }

    /**
     * Disables the image resize feature
     */
    public disableResizeOptions() {
        // NOTE: There's no better way to disable resize feature after the resize module is registered.
        // [See quill-image-resize source code.]
        // Ideally this method should be in quill-image-resize module.
        this.quill.root.removeEventListener( 'click', this.quill.theme.modules.imageResize.handleClick );
        this.quill.root.removeEventListener( 'mscontrolselect', this.quill.theme.modules.imageResize.handleClick );
        this.quill.root.removeEventListener( 'scroll', this.quill.theme.modules.imageResize.handleScroll );
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        if ( isEqual( this.previousValue, data.value )) {
            return;
        }
        this.previousValue = data.value;
        this.quill.setContents( data.value as any );
    }


    /**
     * Returns the file upload options array
     */
    public getMoreOptions() {
        return [
            // {   icon: './assets/icons/symbol-defs.svg#nu-ic-gdrive',
            //     label: this.translate.instant( 'RICHTEXT_EDITOR.UPLOAD_OPTIONS.GOOGLEDRIVE' ),
            //     action: () => this.handleImportFromGoogleDrive(),
            // },
            {
                icon: './assets/icons/symbol-defs.svg#nu-ic-computer',
                label: this.translate.instant( 'RICHTEXT_EDITOR.UPLOAD_OPTIONS.COMPUTER' ),
                action: () => this.handleUploadFromComputer(),
            },
        ];
    }

    /**
     * Handler function for uploading files from computer.
     */
    public handleUploadFromComputer() {
        Tracker.track( 'right.data.info.attachFile.click', { value1: 'Your Computer' });
        this.command.dispatch( DiagramCommandEvent.showUploadWindow, {
            maxFileSize: this.maxFileSize,
            allowedFileTypes: this.allowedFileTypes,
         })
        .pipe(
            last(),
            filter( v => v && v.resultData[0] && v.resultData[0].files[ 0 ]),
            tap( v => {
                const file = v.resultData[0].files[0];
                this.quill.theme.modules.imageUploader.handleInsertFile( file.file, this.selectionCache );
            }),
        ).subscribe();
    }

    /**
     * Handler function for uploading files from google drive.
     */
    public handleImportFromGoogleDrive() {
        Tracker.track( 'right.data.info.attachFile.click', { value1: 'Google drive' });
        if ( !this.googlePickerShow ) {
            return;
        }
        this.googlePickerShow();
        this.googlePickerOnPick.pipe( take( 1 )).subscribe( data => {
            const attachments = {};
            data.docs.forEach( file => {
                const shapeId = this.state.get( 'Selected' )[0];
                const downloadable = file.type !== 'document'; // Documents are not downloadable
                const id = Random.attachmentId();
                const attachment = {
                    id,
                    fileId: file.id,
                    hash: file.hash,
                    name: file.name,
                    added: Date.now(),
                    action: 'add',
                    link: file.url,
                    imgSrc: `https://drive.google.com/uc?export=view&id=${file.id}`,
                    source: 'googleDrive',
                    type: file.mimeType,
                    downloadable,
                    shapeId,
                };
                attachments[ id ] = attachment;
            });
            this.command.dispatch( DiagramCommandEvent.changeAttachments, attachments );
            Object.keys( attachments ).forEach( k => {
                const f = attachments[k];
                this.insertToEditor( f );
            });
        });
    }

    /**
     * Insert IAttachment to the editor
     * @param attachment
     * @param isThumbnail Image attachments can be added as a thumbnail or a resizesable image
     */
    public insertToEditor( attachment: IAttachment, isThumbnail = false ) {
        const data = this.getFileBlotData( attachment );
        const range = this.quill.getSelection() || this.selectionCache;
        // Insert the server saved image

        if ( data.type === 'image' && isThumbnail ) {
            this.quill.insertEmbed( range.index, 'imageThumbnail', data, 'user' );
        } else if ( data.type === 'image' ) {
            this.quill.insertEmbed( range.index, 'imageBlot', data.imgSrc || data.link, 'user' );
        } else {
            this.quill.insertEmbed( range.index, 'fileEmbed', data, 'user' );
        }
        range.index++;
        setTimeout(() => {
            this.quill.setSelection( range, 'user' );
        }, 10 );
    }

    /**
     * Returns the IBlotIcon for the given IAttachment object
     */
    protected getFileIcon( attachment: IAttachment ): IBlotIcon {
        if ( attachment.type === 'link/creately' ) {
            return {
                type: 'svg',
                value: './assets/icons/symbol-defs.svg#nu-ic-logo',
            };
        } else {
            const parts = attachment.name.split( '.' );
            const extention = parts.length > 1 ? parts.reverse()[0] : 'txt';
            const iconName = fileIcons.getClassWithColor( `file.${extention}` );
            return {
                type: 'font',
                value: iconName || fileIcons.getClassWithColor( `file.txt` ),
            };
        }
    }

    /**
     * Returns the options for the file blot. Options depends on the IAttachment
     */
    protected getFileBlotOptions( attachmet: IAttachment ) {
        // TODO enable translation
        const options = [
            { id: 'open', label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.OPEN' ) },
            { id: 'copylink', label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.COPY_LINK' ) },
            { id: 'remove', label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.REMOVE' ) },
        ];
        if ( attachmet.downloadable  ) {
            options.splice( 1, 0, { id: 'download',
                label: this.translate.instant( 'RICHTEXT_EDITOR.FILE_BLOT_OPTIONS.DOWNLOAD' ) });
        }
        return options;
    }

    /**
     * Initialize google picker.
     */
    /* istanbul ignore next */
    protected initGooglePicker() {
        const googlePicker = AppConfig.get( 'GOOGLE_PICKER' );
        GP({
            clientId: googlePicker.clientId,
            developerKey: googlePicker.developerKey,
            appId: googlePicker.appId,
            onpick: data => this.googlePickerOnPick.next( data ),
        }).then( show => this.googlePickerShow = show );
    }

    /**
     * Initialize quill editor
     */
    protected initQuill() {
        const toolbarOptions = [
            [ 'bold', 'italic', 'strike', 'underline' ],        // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ size: [ 'small', false, 'large', 'huge' ]}],  // custom dropdown
            [{ color: []}], // dropdown with defaults from theme
            [{ align: []}],
        ];
        const editorElement = document.createElement( 'div' );
        ( this.elementRef.nativeElement as HTMLElement ).appendChild( editorElement );
        Quill.register( 'modules/imageUploader', ImageUploader );
        Quill.register( 'modules/imageResize', ImageResize );
        this.quill = new Quill( editorElement, {
            theme: 'bubble',
            bounds: editorElement,
            placeholder: this.translate.instant( 'RICHTEXT_EDITOR.PLACEHOLDER' ),
            modules: {
                toolbar: toolbarOptions,
                imageUploader: {
                    upload: file => new Promise(( resolve, reject ) => {
                        if ( file.size <= this.maxFileSize ) {
                            this.dispatchUploadFile( file ).subscribe( f => resolve( this.getFileBlotData( f )));
                        } else {
                            const message = this.translate.instant( 'RICHTEXT_EDITOR.ERRORS.FILE_SIZE',
                                { maxFileSize: this.maxFileSize / 1000000 });
                            reject( message );
                            this.showNotification( message );
                        }
                    }),
                    // Note: File blot related features were implemented in the ImageUploader module
                    onOptionClick: v => new Promise(( resolve, reject ) => {
                        const success = this.handleOptions( v.data.data, v.data.optionId );
                        resolve( Object.assign( v, { success }));
                    }),
                },
                imageResize: {
                    modules: [ 'Resize', 'DisplaySize' ],
                },
            },

        });
        this.handleLinkPaste();
    }

    /**
     * Show an app notification.
     * @param message The notification message
     * @param title The notification title
     * @param type The notification type. Defaults to warning
     */
    protected showNotification( message: string, title?: string, type = NotificationType.Warning ) {
        const options = {
            inputs: {
                heading: title || '',
                description: message,
                autoDismiss: true,
                dismissAfter: this.notificationAutoclose,
            },
        };
        this.notifierController.show( Notifications.RICHTEXT_EDITOR_ERROR, AbstractNotification, type, options );
    }

    /**
     * This property holds observers created in listenToEvent method.
     */
    protected handleLinkPaste() {
        this.quill.clipboard.addMatcher( Node.TEXT_NODE, ( node, delta ) => {
            const link = ShapeLinkModel.fromUrl( node.textContent );
            const dId = link.linkedDiagramId;
            if ( dId ) {
                this.ll.forDiagram( dId, false ).getDiagramOnce().subscribe( diagram => {
                    let label = diagram.name;
                    if ( link.type === ShapeLinkType.SHAPE ) {
                        const shape = diagram.shapes[ link.targetId ];
                        if ( shape && shape.primaryTextModel && shape.primaryTextModel.plainText ) {
                            label = shape.primaryTextModel.plainText;
                        } else if ( shape ) {
                            label = shape.name;
                        }
                    }
                    // NOTE: Pasting the same link multiple times won't add multiple attachments, it's handled
                    // in the changeAttachments command
                    const id = Random.attachmentId();
                    const shapeId = this.state.get( 'Selected' )[0];
                    const attachment = {
                        id: id,
                        link: link.link,
                        name: label,
                        added: Date.now(),
                        source: 'creately',
                        action: 'add',
                        type: 'link/creately',
                        downloadable: false,
                        shapeId,
                    };
                    this.command.dispatch( DiagramCommandEvent.changeAttachments, {
                        [id]: attachment,
                    });
                    this.insertToEditor( attachment );
                });
                return { ops: [{ insert: '' }]};
            }
            return delta;
        });
    }

    /**
     * This observable emits when the user clicks outside this component.
     */
    protected focusedOut() {
        const element = document.body;
        const editor = this.elementRef.nativeElement;
        return fromEvent( element, 'mousedown' ).pipe(
            map(( e: MouseEvent ) => {
                let p: HTMLElement = e.target as any;
                let outside = true;
                while ( p !== element ) {
                    if ( editor.tagName === p.tagName ) {
                        outside = false;
                        break;
                    }
                    p = p.parentElement;
                }
                return outside;
            }),
            filter( v => !!v ),
        );
    }

    /**
     * Completes and Removes the given observer from the eventEmitters array
     * @param observer
     */
    protected removeObserver( observer: Observer<any> ) {
        observer.complete();
        const index = this.eventEmitters.findIndex( o => o === observer );
        if ( index > -1 ) {
            this.eventEmitters.splice( index, 1 );
        }
    }

    /**
     * Only one file can be uploaded for the moment
     * @param file
     */
    protected dispatchUploadFile( file ) {
        return this.command.dispatch( DiagramCommandEvent.uploadFile, {
            files: [ new ImportedFile( file ) ],
        }).pipe(
            last(),
            filter( v => v && v.resultData[0] && v.resultData[0].files[ 0 ]),
            map( v => {
                file = v.resultData[0].files[ 0 ];
                const id = Random.attachmentId();
                const shapeId = this.state.get( 'Selected' )[0];
                const attachment = {
                    id: id,
                    link: AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + file.hash,
                    name: file.name,
                    added: Date.now(),
                    source: 's3',
                    action: 'add',
                    downloadable: true,
                    type: file.type,
                    shapeId,
                };
                this.command.dispatch( DiagramCommandEvent.changeAttachments, {
                    [id]: attachment,
                });
                return attachment;
            }),

        );
    }

    /**
     * Handler function for clicking on blot options
     */
    protected handleOptions( blotData: IFileBlot, action: 'remove' | 'copylink' | 'download' | 'open' ) {

        const viewLink = blotData.link;
        let downloadLink = blotData.link;
        if ( blotData.source === 'googleDrive' ) {
            downloadLink = `https://drive.google.com/uc?export=download&id=${blotData.fileId}`;
        }

        if ( action === 'open' ) {
            this.linkSvc.navigate( ShapeLinkModel.fromUrl( viewLink ));
        }

        if ( action === 'copylink' ) {
            this.clipboard.copy( viewLink );
        }
        if ( action === 'download' ) {
            saveAs( downloadLink, blotData.label );
        }

        if ( this.blotOptionCallbacks && this.blotOptionCallbacks.remove && action === 'remove' ) {
            return this.blotOptionCallbacks.remove( blotData );
        }

        return true;
    }

    /**
     * Converts and attachment to IFileBlot
     */
    protected getFileBlotData( attachment: IAttachment ): IFileBlot {
        return {
            id: attachment.id,
            fileId: attachment.fileId,
            label: attachment.name,
            icon: this.getFileIcon( attachment ) as any,
            type: attachment.type.includes( 'image' ) ? 'image' : 'file',
            link: attachment.link,
            source: attachment.source,
            imgSrc: attachment.imgSrc,
            options: this.getFileBlotOptions( attachment ),
        };
    }

}

/**
 * File blot interface
 */
export interface IFileBlot {
    id: string; // A unique string that has no dots or hiphans
    fileId?: string; // This file id is file specific, e.g. google drive file id, and can contian any char
    link: string;
    imgSrc?: string;
    label: string;
    icon: IBlotIcon;
    options: Array<{ id: string, label: string }>;
    source?: string;
    type: 'image' | 'file';
}

/**
 * Blot icon can be 'svg' type of 'font' type
 * svg  e.g. './assets/icons/symbol-defs.svg#nu-ic-creately'
 * font e.g. 'node-icon' ( see file-icons-js module )
 */
export interface IBlotIcon {
    type: 'font' | 'svg';
    value: string;
}
