import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';

/**
 * class PolygonCanvasInstructionFactory
 * Generate canvas instruction for svg line element instructions
 */
export class PolygonCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'polygon';

    /**
     * Return polygon canvas instructions for given polygon shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        const instructions = this.applyTransformationsForMoveAndLines(
            this.generatePolylineInstructions( data.data.points ),
            data.transform,
        );
        instructions.push({ instruction: 'closePath', params: []});
        return instructions;
    }

}
