import { Injectable } from '@angular/core';
import { Command, StateService, Tracker } from 'flux-core';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';

/**
 * This command is used to show or hide connectors
 * in the diagram.
 *
 * This command update the property 'hidden' of the connector model according
 * to show/hide.
 *
 * @author  Nkweti Awa
 * @since   2023-05-19
 */
@Injectable()
@Command()
export class ToggleConnectorVisibility extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        hideConnector: boolean,
        selection?: boolean;
    };

    constructor( protected ds: DiagramChangeService,
                 protected stateService: StateService<any, any> ) {
        super( ds );
    }

    /**
     * Prepare command data
     * Update the connector visibility property.
     * If the selection is true, connector update the selected connector only
     * Else, update style data for all hidden connetors
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public prepareData() {
        // FIXME: This function need to be optimized
        if ( this.data.selection ) {
            const shapeIds = this.stateService.get( 'Selected' );
            for ( const shapeId of shapeIds ) {
                const shape = this.changeModel.shapes[shapeId];
                if ( shape ) {
                    if ( shape.isConnector()) {
                        // If a connector is selected, toggle for that connector
                        let connector = shape as ConnectorModel;
                        if ( this.data.hideConnector === true ) {
                            connector = this.hideConnector( connector );
                            connector.hidden = this.data.hideConnector;
                        } else if ( this.data.hideConnector === false ) {
                            connector = this.showConnector( connector );
                            connector.hidden = this.data.hideConnector;
                        } else {
                            if ( connector.hidden ) {
                                connector = this.previewConnector( connector );
                            }
                        }
                    } else {
                        /**
                         * If a shape is selected, set all hidden connectors connected to that shape
                         * to 0.3 transparency.
                         */
                        const connectors = ( shape as ShapeModel ).connectorIds;
                        for ( const connectorId of connectors ) {
                            let connector = this.changeModel.shapes[connectorId] as ConnectorModel;
                            if ( connector?.hidden && !this.data.hideConnector ) {
                                connector = this.previewConnector( connector );
                            }
                        }
                    }
                }
            }
        } else {
            // When the shape is deselected, hide all hidden connectors
            const shapeIds = this.stateService.get( 'Deselected' );
            const selectedShapes = this.stateService.get( 'Selected' );
            let selectedConnectors = [];
            // Get the connectors of shapes that are currently selected
            for ( const shapeId of selectedShapes ) {
                const shape = this.changeModel.shapes[shapeId];
                if ( shape ) {
                    if ( shape.isConnector()) {
                        selectedConnectors = [ ...selectedConnectors, shapeId ];
                    } else {
                        selectedConnectors = [ ...selectedConnectors, ...( shape as ShapeModel ).connectorIds ];
                    }
                }
            }
            // If deselected shapes were returned,
            if ( shapeIds.length ) {
                for ( const shapeId of shapeIds ) {
                    if ( this.changeModel.shapes[shapeId]) {
                        // If shape is connector and is hidden, set alpha to zero
                        if ( this.changeModel.shapes[shapeId].isConnector()) {
                            let connector = this.changeModel.shapes[shapeId] as ConnectorModel;
                            if ( connector.hidden ) {
                                connector = this.hidePreview( connector );
                            }
                        } else {
                            // If not connector, get all connectors attached to shape
                            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
                            const connectors = shape.connectorIds;
                            for ( const connectorId of connectors ) {
                                // If connector not also attached to a selected shape, and connector is hidden
                                // set alpha to zero
                                if ( !selectedConnectors.includes( connectorId )) {
                                    let connector = this.changeModel.shapes[connectorId] as ConnectorModel;
                                    if ( connector.hidden ) {
                                        connector = this.hidePreview( connector );
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                /**
                 * Sometimes when a shape is deselected by selecting a new shape, the
                 * state changes are not set to 'Deselected'. Here we handle the change.
                 */
                const connectorIds = this.changeModel.getConnectors();
                // tslint:disable-next-line:no-shadowed-variable
                const selectedShapes = this.stateService.get( 'Selected' );
                // tslint:disable-next-line:no-shadowed-variable
                let selectedConnectors = [];
                // Get all selected connectors, and connectors of selected shapes
                for ( const shapeId of selectedShapes ) {
                    if ( this.changeModel.shapes[shapeId]) {
                        if ( this.changeModel.shapes[shapeId].isConnector()) {
                            selectedConnectors = [ ...selectedConnectors, shapeId ];
                        } else {
                            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
                            selectedConnectors = [ ...selectedConnectors, ...shape.connectorIds ];
                        }
                    }
                }
                for ( const connectorId of connectorIds ) {
                    // If not selected, set alpha to zero
                    if ( !selectedConnectors.includes( connectorId )) {
                        let connector = this.changeModel.shapes[connectorId] as ConnectorModel;
                        if ( connector.hidden ) {
                            connector = this.hidePreview( connector );
                        }
                    } else {
                        let connector = this.changeModel.shapes[connectorId] as ConnectorModel;
                        if ( connector.hidden ) {
                            connector = this.previewConnector( connector );
                        }
                    }
                }
            }
        }
    }

    /**
     * Converts a hex color code to corresponding rgba value.
     * @param hex
     * @param alpha
     */
    public hexToRGBA( hex: string, alpha: number ) {
        const r = parseInt( hex.slice( 1, 3 ), 16 );
        const g = parseInt( hex.slice( 3, 5 ), 16 );
        const b = parseInt( hex.slice( 5, 7 ), 16 );

        return `rgba(${r},${g},${b},${alpha})`;
    }

    /**
     * Converts an rgba color code to corresponding hex code.
     * It ignores the alpha value and uses just the rgb values.
     * @param color
     */
    public rgbaToHex( color: string ) {
        const rgba = color.replace( /^rgba?\(|\s+|\)$/g, '' ).split( ',' );
        if ( rgba.length === 3 || rgba.length === 4 ) {
            // tslint:disable-next-line:no-bitwise
            const hex = `#${(( 1 << 24 ) + ( parseInt( rgba[ 0 ], 10 ) << 16 ) + ( parseInt( rgba[ 1 ], 10 ) << 8 ) + parseInt( rgba[ 2 ], 10 )).toString( 16 ).slice( 1 )}`;
            return hex;
        } else {
            return color;
        }
    }

    /**
     * Shows a connector
     * @param connector
     */
    private showConnector( connector: ConnectorModel ): ConnectorModel {
        Tracker.track( 'canvas.contextMenu.showConnectors.click' );
        connector.style.lineColor = this.rgbaToHex( connector.style.lineColor );
        const textIds = Object.keys( connector.texts );
        for ( const textId of textIds ) {
            connector.texts[textId].content[0].color = this.rgbaToHex( connector.texts[textId].content[0].color );
            if ( connector.texts[textId].content[0].backgroundColor ) {
                connector.texts[textId].content[0].backgroundColor =
                    this.rgbaToHex( connector.texts[textId].content[0].backgroundColor );
            }
        }
        const active = this.stateService.get( 'ActiveShapes' );
        this.stateService.set( 'ActiveShapes', [ ...active, connector.id ]);
        return connector;
    }

    private hideConnector( connector: ConnectorModel ): ConnectorModel {
        Tracker.track( 'canvas.contextMenu.hideConnectors.click' );
        connector.style.lineColor = this.hexToRGBA( this.rgbaToHex( connector.style.lineColor ), 0 );
        const textIds = Object.keys( connector.texts );
        for ( const textId of textIds ) {
            connector.texts[textId].content[0].color = this.hexToRGBA(
                this.rgbaToHex( connector.texts[textId].content[0].color ), 0.3 );
            if ( connector.texts[textId].content[0].backgroundColor ) {
                connector.texts[textId].content[0].backgroundColor = this.hexToRGBA(
                    this.rgbaToHex( connector.texts[textId].content[0].backgroundColor ), 0.3 );
            }
        }
        return connector;
    }

    private previewConnector( connector: ConnectorModel ): ConnectorModel {
        connector.style.lineColor = this.hexToRGBA( this.rgbaToHex( connector.style.lineColor ), 0.3 );
        const textIds = Object.keys( connector.texts );
        for ( const textId of textIds ) {
            connector.texts[textId].content[0].color = this.hexToRGBA(
                this.rgbaToHex( connector.texts[textId].content[0].color ), 0.3 );
            if ( connector.texts[textId].content[0].backgroundColor ) {
                connector.texts[textId].content[0].backgroundColor = this.hexToRGBA(
                    this.rgbaToHex( connector.texts[textId].content[0].backgroundColor ), 0.3 );
            }
        }
        const active = this.stateService.get( 'ActiveShapes' );
        this.stateService.set( 'ActiveShapes', [ ...active, connector.id ]);
        return connector;
    }

    private hidePreview( connector: ConnectorModel ): ConnectorModel {
        const active = this.stateService.get( 'ActiveShapes' );
        this.stateService.set( 'ActiveShapes', active.filter( i => i !== connector.id ));
        connector.style.lineColor = this.hexToRGBA( this.rgbaToHex( connector.style.lineColor ), 0 );
        const textIds = Object.keys( connector.texts );
        for ( const textId of textIds ) {
            connector.texts[textId].content[0].color = this.hexToRGBA(
                this.rgbaToHex( connector.texts[textId].content[0].color ), 0 );
            if ( connector.texts[textId].content[0].backgroundColor ) {
                connector.texts[textId].content[0].backgroundColor = this.hexToRGBA(
                    this.rgbaToHex( connector.texts[textId].content[0].backgroundColor ), 0 );
            }
        }
        return connector;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ToggleConnectorVisibility, 'name', {
    value: 'ToggleConnectorVisibility',
});
