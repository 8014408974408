import { ChangeDetectorRef, Component, ComponentFactoryResolver,
    ComponentRef, Inject, Injector, OnDestroy, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { concat, merge, Observable, Subscription, of, Subject, EMPTY, BehaviorSubject, from } from 'rxjs';
import { switchMap, tap, filter, concatMap, take, skip, delay, map, mergeMap } from 'rxjs/operators';
import { INotifierData, NotificationType, NotifierStateService } from '../../controller/notifier-controller';
import { StateService } from '../../controller/state.svc';
import { Flags } from '../../flags';
import { Animation } from '../animation';
import { DynamicComponent } from '../dynamic.cmp';
import { SidebarState } from '../ui-states';
import { INotification } from './notification.i';

/**
 * Holds information about notifications and their position on the screen.
 * Only used internally by the AppNotifier class.
 */
export interface INotificationItem {
    /**
     * The component reference for the notification.
     */
    notification: ComponentRef<INotification>;

    /**
     * The HTML element for the notification.
     */
    element?: HTMLElement;

    /**
     * The time the notification was added at.
     */
    addedAt: number;

    /**
     * The current height of the notification. This is required to calculate
     * it's position on the screen.
     */
    height: number;

    /**
     * The current y translation of the notification. This is required to
     * calculate it's position on the screen.
     */
    y: number;

    /**
     * The unique identifier for the notification. This can be used to interact with the
     * notification after it has been added to queue or displayed.
     */
    id: string;

    /**
     * The type of notification. This can be used to filter notifications to distinct groups.
     */
    type: NotificationType;

    /**
     * Whether the notification is currently collapsed or expanded.
     */
    collapsed: boolean;

    /**
     * Whether the notification is currently displayed.
     */
    displayed: boolean;
}

/**
 * This is a container where Notifications get added and removed.
 *
 * This container needs to placed in the application and whenever the app needs to
 * show or hide a notification, it will be done through this component.
 *
 * @author jerome
 * @since 2020-04-10
 */
@Component({
    selector: 'app-notifier',
    styleUrls: [ './app-notifier.cmp.scss' ],
    templateUrl: './app-notifier.cmp.html',
})
export class AppNotifier extends DynamicComponent implements AfterViewInit, OnDestroy {

    /**
     * The current right sidebar state.
     */
    public sidebarState: SidebarState;

    /**
     * A container reference to insert notifications after.
     */
    @ViewChild( 'viewContainer', { read: ViewContainerRef, static: false })
    protected viewContainer: ViewContainerRef;

    /**
     * The maximum number of notifications to display at a time.
     */
    protected maxNotificationsToDisplay: number = 3;

    /**
     * The vertical gap between notifications, in pixels.
     */
    protected notificationVerticalMargin: number = 16;

    /**
     * A list of subscriptions.
     */
    protected subs: Subscription[];

    /**
     * A subject to queue observables with.
     */
    protected actionQueue: Subject<Observable<any>>;

    /**
     * Holds the list of notifications to be displayed.
     */
    protected notifications: Array<INotificationItem>;

    /**
     * Track if collapsed notifications should be shown.
     */
    protected displayCollapsed: BehaviorSubject<boolean>;

    constructor(
        @Inject( StateService ) protected notifierStateService: NotifierStateService,
        @Inject( StateService ) protected state: StateService<any, any>,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {
        super( componentFactoryResolver, injector );
        this.actionQueue = new Subject();
        this.displayCollapsed = new BehaviorSubject( false );
        this.notifications = [];
        this.subs = [];
    }

    /**
     * Returns if collapsed notifications should be displayed or not.
     */
    protected get shouldDisplayCollapsed(): boolean {
        return this.displayCollapsed.value;
    }

    /**
     * The number of currently displayed notifications.
     */
    protected get displayedNotificationCount(): number {
        return this.notifications.filter( item => item.displayed ).length;
    }

    /**
     * Subscribe to the NotifierStateService and add and remove Notifications that are
     * passed to it.
     */
    public ngAfterViewInit() {
        this.subs.push(
            this.notifierStateService.changes( 'Notification' ).pipe(
                // Filter notifications without data or ID, and errors or warnings if debugger flag is set
                filter(( data: INotifierData ) => !!( data && data.id ) &&
                    !( Flags.get( 'DEBUG_ERROR_NOTIFICATIONS' ) &&
                        ( data.type === NotificationType.Error || data.type === NotificationType.Warning ))),
                tap(( data: INotifierData ) => {
                    let action: Observable<any>;

                    // Handle hide notifications if they exist
                    if ( !data.show && this.getNotificationItems( data.id ).length ) {
                        action = this.dismiss( data.id ).pipe(
                            tap({
                                complete: () => {
                                    // Always enqueue processQueue as many events can trigger it
                                    this.actionQueue.next( this.processQueue());
                                },
                            }),
                        );

                    // Handle show notifications
                    } else if ( data.show ) {
                        const addedAt = Date.now();
                        let collapsed: boolean;

                        // If notification collapse is undefined, collapse if they are warnings or errors
                        if ( data.collapsed === undefined && ( data.type === NotificationType.Error ||
                            data.type === NotificationType.Warning )) {

                            collapsed = true;

                        // Else used set value
                        } else {
                            collapsed = data.collapsed;
                        }

                        action = this.enqueue( data, collapsed, addedAt ).pipe(
                            tap({
                                complete: () => {
                                    // Always enqueue processQueue as many events can trigger it
                                    this.actionQueue.next( this.processQueue());
                                },
                            }),
                        );
                    }

                    // Queue actions
                    if ( action ) {
                        this.actionQueue.next( action );
                    }

                }),
            ).subscribe(),

            // Perform actions when collapsed status changes
            this.displayCollapsed.pipe(
                skip( 1 ),
                tap( display => {
                    if ( display ) {
                        this.actionQueue.next( this.processQueue());
                    } else {
                        this.actionQueue.next( this.collapseNotifications());
                    }
                }),
            ).subscribe(),

            // Observables should be subscribed to sequentially
            this.actionQueue.pipe(
                concatMap( action => action ),
            ).subscribe(),

            this.listenToSidebarStateChanges().subscribe(),

            // Handles click events on the notification count component.
            this.state.changes( 'NotificationCountClick' ).pipe(
                filter( button => !!button ),
                tap(() => this.displayCollapsed.next( !this.shouldDisplayCollapsed )),
            ).subscribe(),
        );
    }

    /**
     * Unsubscribe from all subscriptions
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Listens to sidebar state changes and sets class for notification container.
     */
    protected listenToSidebarStateChanges(): Observable<any> {
        return this.state.changes( 'RightSidebarPanel' ).pipe(
            filter( state => !!state ),
            tap( state => {
                if ( state === 'shapeData' ) {
                    this.sidebarState = SidebarState.Expanded;
                } else if ( state === SidebarState.Opening ||
                    ( state !== SidebarState.None && state !== SidebarState.Closing )) {
                    this.sidebarState = SidebarState.Open;
                } else {
                    this.sidebarState = SidebarState.Closed;
                }
                this.changeDetectorRef.markForCheck();
            }),
        );
    }

    /**
     * Create and enqueue a notification component for display.
     * @param data The iNotifierData containing notification info
     * @param collapsed Whether the notification is initially collapsed or expanded
     * @param addedAt The timestamp for when the notification was received
     */
    protected enqueue(
        data: INotifierData,
        collapsed: boolean,
        addedAt: number,
    ): Observable<any> {
        const notification: ComponentRef<INotification> = this.makeComponent( data.component );
        this.insert( this.viewContainer, notification );

        const element = this.getHtmlElement( notification );
        this.applyDefaultPosition( element );

        this.setInputs( notification.instance, { id: data.id });

        if ( data.type ) {
            this.setInputs( notification.instance, { type: data.type });
        }

        if ( data.options ) {
            this.setInputs( notification.instance, data.options.inputs );
        }

        notification.changeDetectorRef.markForCheck();

        return notification.instance.ready.pipe(
            filter( ready => !!ready ),
            take( 1 ),
            tap(() => {
                const height = element.getBoundingClientRect().height;

                const item: INotificationItem = {
                    notification: notification,
                    element: element,
                    height: height,
                    y: 0,
                    id: data.id,
                    type: data.type,
                    collapsed: collapsed,
                    addedAt: addedAt,
                    displayed: false,
                };

                // Push to queue
                this.notifications.push( item );

                // Handle dismiss event
                this.subs.push(
                    this.handleDismissNotification( item ).subscribe(),
                );

                // Update counts
                this.updateNotificationCounts();
            }),
        );
    }

    /**
     * Process the current notification queue.
     */
    protected processQueue(): Observable<any> {
        return of( this.notifications ).pipe(
            map( notifications =>
                // Take items that are not displayed and not collapsed
                // or not displayed and collapsed if collapsed items should be displayed
                notifications
                    .filter( item => ( !item.displayed && !item.collapsed ) ||
                        !item.displayed && ( item.collapsed && this.shouldDisplayCollapsed ),
                    )
                    .sort(( a, b ) => a.addedAt - b.addedAt ) // Sort by added time
                    .slice( 0, this.maxNotificationsToDisplay - this.displayedNotificationCount ), // Limit to max
            ),
            switchMap( notifications => from( notifications )),
            concatMap( item => concat(
                this.moveDisplayedNotificationsUp( item.height ),
                this.showNotification( item ).pipe(
                    delay( 50 ), // Leave a short delay between notifications
                ),
            )),
        );
    }

    /**
     * Collapses any notifications that are collapsed by default and are currently displayed.
     */
    protected collapseNotifications(): Observable<any> {
        return of( this.notifications ).pipe(
            map( items => items.filter( item => item.collapsed && item.displayed )),
            filter( items => !!items.length ),
            switchMap( items  => {
                const notificationHeightTotal = items.reduce(( accum, item ) => accum + item.height, 0 );
                const marginTotal = this.notificationVerticalMargin * ( items.length - 1 );
                const moveHeight = notificationHeightTotal + marginTotal;
                const hideNotifications = items.map( item => this.hideNotification( item ));

                const moveDisplayedNotificationsDown =
                    this.moveDisplayedNotificationsDown( items[0].y, moveHeight ).pipe(
                        tap({
                            complete: () => {
                                items.forEach( item => {
                                    item.y = 0;
                                    this.applyDefaultPosition( item.element );
                                });
                            },
                        }),
                );

                return concat(
                    merge( ...hideNotifications ), // Slide out notifications simultaneously
                    moveDisplayedNotificationsDown,
                );
            }),
        );
    }

    /**
     * Applies the default notification positioning styles to the given element.
     * @param element The element to apply styles to
     */
    protected applyDefaultPosition( element: HTMLElement ) {
        element.style.willChange = 'transform';
        element.style.position = 'fixed';
        element.style.marginBottom = '80px';
        element.style.marginRight = '10px';
        element.style.transform = 'translate(400px, 0px)';
    }

    /**
     * Subscribe to the given notification instance's dismiss behavior
     * and handle removing notification when dismiss emits.
     * @param item The notification to subscribe to.
     */
    protected handleDismissNotification( item: INotificationItem ): Observable<any> {
        return item.notification.instance.dismiss.pipe(
            filter( dismiss => !!dismiss ),
            take( 1 ),
            switchMap(() => this.removeNotification( item )),
            tap({
                complete: () => {
                    this.actionQueue.next( this.processQueue());

                    // Start hiding collapsed notifications again if all notifications have been dismissed
                    if ( this.notifications.length === 0 ) {
                        this.displayCollapsed.next( false );
                    }
                },
            }),
        );
    }

    /**
     * Shows a notification with an animation and lets the
     * notification know that it is visible after the animation is complete.
     * @param item The notification item to show
     */
    protected showNotification( item: INotificationItem ): Observable<any> {
        return this.showAnimation( item.element )
            .start()
            .pipe(
                tap({
                    complete: () => {
                        item.displayed = true;
                        item.notification.instance.visible.next( true );
                        this.updateNotificationCounts();
                    },
                }),
            );
    }

    /**
     * Hides a notification with an animation and lets the
     * notification know that it is not visible after the animation is complete.
     * @param item The notification item to hide
     */
    protected hideNotification( item: INotificationItem ): Observable<any> {
        return this.hideAnimation( item.element )
            .start()
            .pipe(
                tap({
                    complete: () => {
                        item.displayed = false;
                        item.notification.instance.visible.next( false );
                        this.updateNotificationCounts();
                    },
                }),
            );
    }

    /**
     * Moves the currently displayed notifications up by a specific number of pixels.
     * @param moveHeight The distance ( in px ) to move displayed notifications by
     */
    protected moveDisplayedNotificationsUp( moveHeight: number ): Observable<any> {
        return of( moveHeight ).pipe(
            filter(() => this.displayedNotificationCount > 0 ),
            switchMap( height => from( this.notifications ).pipe(
                filter( item => item.displayed ),
                mergeMap( item => {
                    const moveToY = - ( Math.abs( item.y ) + height + this.notificationVerticalMargin );
                    return this.moveNotification( item, moveToY );
                }),
            )),
        );
    }

    /**
     * Move the displayed notifications above the given notification down.
     * @param limitY the Y axis limit above which notifications should be moved down
     * @param moveHeight the distance ( in px ) that notifications should be moved down by
     */
    protected moveDisplayedNotificationsDown( limitY: number, moveHeight: number ): Observable<any> {
        return of([ limitY, moveHeight ]).pipe(
            switchMap(([ y, height ]) => from( this.notifications ).pipe(
                filter( item => item.displayed ),
                mergeMap( item => {
                    if ( item.y < y ) {
                        const moveToY = - ( Math.abs( item.y )
                            - ( height + this.notificationVerticalMargin ));
                        return this.moveNotification( item, moveToY );
                    }
                    return EMPTY;
                }),
            )),
        );
    }

    /**
     * Move the notification at the given index to the given y co-ordinate.
     * @param item The notification item to move
     * @param moveToY The y co-ordinate to move the notification to
     */
    protected moveNotification( item: INotificationItem, moveToY: number ): Observable<any> {
        return this.slideVertical( item.element, item.y, moveToY )
            .start()
            .pipe(
                tap({
                    complete: () => item.y = moveToY,
                }),
            );
    }

    /**
     * Dismisses notification(s) from view and removes it from DOM.
     * @param id The id with which to locate the notification(s) to remove
     */
    protected dismiss( id: string ): Observable<any> {
        const items = this.getNotificationItems( id );
        if ( items.length ) {
            return from( items ).pipe(
                concatMap( item => {
                    if ( item.displayed ) {
                        return this.removeNotification( item );
                    } else {
                        return this.cleanUp( item ).pipe(
                            tap({
                                complete: () => {
                                    this.updateNotificationCounts();
                                },
                            }),
                        );
                    }
                }),
            );
        }
        return EMPTY;
    }

    /**
     * Remove a given notification from the DOM and notifications list.
     * @param item The notification item to remove
     */
    protected removeNotification( item: INotificationItem ): Observable<any> {
        return concat(
            this.hideAnimation( item.element ).start().pipe(
                tap({
                    complete: () => {
                        this.updateNotificationCounts();
                        item.notification.instance.visible.next( false );
                    },
                }),
            ),
            this.moveDisplayedNotificationsDown( item.y, item.height ),
            this.cleanUp( item ),
        );
    }

    /**
     * Updates notification counts.
     */
    protected updateNotificationCounts(): void {
        const warningCount = this.notifications.filter( item =>
            item.collapsed && !item.displayed && item.type === NotificationType.Warning ).length;
        const errorCount = this.notifications.filter( item =>
            item.collapsed && !item.displayed && item.type === NotificationType.Error ).length;

        this.state.set( 'NotificationCounts', { warningCount, errorCount });
    }

    /**
     * Clean up after removing a notification.
     * @param item The notification item to clean up after
     */
    protected cleanUp( item: INotificationItem ) {
        return of( this.notifications ).pipe(
            tap( notifications => {
                this.notifications = notifications.filter( i  => i !== item );
                this.remove( this.viewContainer, item.notification );
            }),
        );
    }

    /**
     * Find and return a NotificationItems by id.
     * @param id The id of the notification item
     */
    protected getNotificationItems( id: string ): INotificationItem[] {
        return this.notifications.filter( item => item.id === id );
    }

    /**
     * Get the first HTML element within a given ComponentRef.
     * @param componentRef The component to get the HTML element from
     */
    protected getHtmlElement( componentRef: ComponentRef<INotification> ): HTMLElement {
        return componentRef.location.nativeElement.firstElementChild as HTMLElement;
    }

    /**
     * The animation to use when displaying notifications.
     * @param element The HTML element to animate
     */
    protected showAnimation( element: HTMLElement ): Animation {
        if ( !element ) {
            return null;
        }
        return this.slideIn( element );
    }

    /**
     * The animation to use when dismissing notifications.
     * @param element The HTML element to animate
     */
    protected hideAnimation( element: HTMLElement ): Animation {
        if ( !element ) {
            return null;
        }
        return this.slideOut( element );
    }

    /**
     * Slide a given HTML element vertically between the two given y co-ordinates
     * @param element The HTML element to slide
     * @param from The starting y co-ordinate
     * @param to The ending y co-ordinate
     */
    protected slideVertical( element: HTMLElement, fromY: number, toY: number ): Animation {
        const matrix = new WebKitCSSMatrix( element.style.webkitTransform );
        const transformX = matrix.m41;

        const animation = new Animation({
            from: {
                transform: `translate(${transformX}px, ${fromY}px)`,
            },
            to: {
                transform: `translate(${transformX}px, ${toY}px)`,
            },
            transitionProperty: 'transform',
            duration: 300,
            easing: 'ease-in-out',
        });
        animation.element = element;
        return animation;
    }

    /**
     * Slide in the given HTML element from the screen right.
     * @param element The HTML element to slide in
     */
    protected slideIn( element: HTMLElement ): Animation {
        const matrix = new WebKitCSSMatrix( element.style.webkitTransform );
        const transformY = matrix.m42;

        const animation = new Animation({
            from: {
                transform: `translate(400px, ${transformY}px)`, opacity: '0',
            },
            to: {
                transform: `translate(0px, ${transformY}px)`, opacity: '1',
            },
            transitionProperty: 'transform, opacity',
            duration: 300,
            easing: 'ease-in-out',
        });
        animation.element = element;
        return animation;
    }

    /**
     * Slide out the given HTML element to the screen right.
     * @param element The HTML element to slide out
     */
    protected slideOut( element: HTMLElement ): Animation {
        const matrix = new WebKitCSSMatrix( element.style.webkitTransform );
        const transformY = matrix.m42;

        const animation = new Animation({
            from: {
                transform: `translate(0px, ${transformY}px)`, opacity: '1',
            },
            to: {
                transform: `translate(400px, ${transformY}px)`, opacity: '0',
            },
            transitionProperty: 'transform, opacity',
            duration: 200,
            easing: 'ease-in-out',
        });
        animation.element = element;
        return animation;
    }
}
