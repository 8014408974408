<div class="fullscreen-container ne-grey fx-cover fx-center-all fx-center">

    <div class="fullscreen-message">
        <img src="./assets/images/logo-on-dark-200X80.svg" alt="Creately" class="logo">
        <h1 class="title" translate>MESSAGES.APP_NOT_AUTHORIZED_HEADING</h1>
        <p class="body fx-margin-top-15"  [innerHTML]="'MESSAGES.APP_NOT_AUTHORIZED_DESC' | translate"></p>
    </div>
    
    <div class="fullscreen-footer">
        <p class="body fx-margin-top-15"  [innerHTML]="'MESSAGES.APP_NOT_AUTHORIZED_FOOTER' | translate"></p>
    </div>
</div>