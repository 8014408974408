<div class="dialog-box-overlay fx-cover fx-center-all" *ngIf="showDialog | async">
    <div class="dialog-box-container">
        <div class="dialog-box-container-inner">
            <div class="dialog-box-icon-container {{type}}">
                <svg class="nu-icon">
                    <use [attr.xlink:href]="iconURL"></use>
                </svg>
            </div>
            <div class="dialog-box-content">
                <div class="fx-flex-expand fx-margin-bottom-10">
                    <h2 class="body body--lg body--bold fx-margin-bottom-10">{{ heading | async }}</h2>
                    <p class="grey body">{{ description | async }}</p>
                </div>
                <div class="fx-text-right dialog-box-btn-group">
                    <button *ngFor="let button of buttons | async" class="btn-medium fx-margin-right-5" [class.btn-secondary]="button.type !== 'ok'" [class.btn-primary]="button.type === 'ok'"  (click)="button.clickHandler();hideDialogBox()">{{button.text}}</button>
                </div>
            </div>
            <a class="dialog-box-close-btn btn-small nu-btn-icon" (click)="hideDialogBox()" >
                <svg class="nu-icon nu-icon-small">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </a>
        </div>
    </div>
</div>
