import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the NUMBER_OPTIONS data type. Data items in shape models with the NUMBER_OPTIONS
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class NumberOptionsDataItem extends AbstractDataItem<DataType.NUMBER> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: any ): IValidationError {
        if ( !this.validationRules ) {
            return null;
        }
        if ( this.validationRules.required &&
            ( value === undefined || value === null || Object.getPrototypeOf( value ) !== [] || value.length === 0 )) {
            return { message: `"${value}" is not allowed, A list containing values is required` };
        }

        let message = null;
        value.forEach( num => {
            if ( isNaN( num )) {
                message = { message: `"${num}" is not a valid number` };
                return;
            }
            const float = Number.parseFloat( num );
            if ( !this.validationRules.decimal && !Number.isInteger( float )) {
                message = { message: `${num} is a decimal` };
                return;
            }
            if ( this.validationRules.max !== undefined && float > this.validationRules.max ) {
                message = { message : `${num} is greater than ${this.validationRules.max}` };
                return;
            }
            if ( this.validationRules.min !== undefined && float < this.validationRules.min ) {
                message = { message: `${num} is less than ${this.validationRules.min}` };
                return;
            }
        });

        return message;

    }
}
