import { IfStateClass } from './controller/if-state-class.dir';
import { IfState } from './controller/if-state.dir';
import { NgModule, ErrorHandler } from '@angular/core';
import { Logger } from './logger/logger.svc';
import { CommandMapper } from './command/mapper/command-mapper.svc';
import { CommandStepMapper } from './command/mapper/command-step-mapper.svc';
import { AppConfig } from './app-config';
import { CommandService } from './command/command.svc';
import { DeviceScreen } from './system/device/device-screen.svc';
import { Database } from '@creately/rxdata';
import { Database as DatabasePersistence } from '@creately/rxdata-persistence';
import { ResourceLoader } from './system/loader/resource-loader.svc';
import { ScriptLoader } from './system/loader/script-loader.svc';
import { ContainerEnv } from './system/container/container-env.svc';
import { StateService } from './controller/state.svc';
import { Random } from './data/random';
import { ChainSequenceController } from './controller/chain-sequence/chain-sequence-controller.svc';
import { ReferenceBuilder } from './model/reference';
import { SharedStateService } from './controller/state-shared.svc';
import { ExecuteScenario } from './command/scenario/execute-scenario.svc';
import { PreviewScenario } from './command/scenario/preview-scenario.svc';
import { CollabScenario } from './command/scenario/collab-scenario.svc';
import { AppErrorHandler } from './system/app-error-handler';
import { TrackAction } from './tracker/track-action.dir';
import { SecurityErrorHandler } from './system/error/security-error-handler.svc';
import { ModalController } from './controller/modal-controller';
import { NotifierController } from './controller/notifier-controller';
import { NgLet } from './controller/nglet.dir';
import { NgAttr } from './controller/ng-attr.dir';
import { CommandErrorHandler } from './command/error/command-error-handler';
import { AppNavConfigInterpreter } from './system/app-nav-config/app-nav-config-interpreter';
import { EventManagerService } from './event/event-manager.svc';

/**
 * FluxCore
 * ...
 */
@NgModule({
    providers: [
        { provide: 'BrowserWindow', useFactory: FluxCore.getWindow },
        AppErrorHandler,
        { provide: ErrorHandler, useExisting: AppErrorHandler },
        { provide: Database, useFactory: Database.create },
        { provide: DatabasePersistence, useFactory: DatabasePersistence.create },
        Logger, CommandMapper, CommandService, ResourceLoader,
        CommandStepMapper, DeviceScreen, ScriptLoader,
        ContainerEnv, StateService, Random, ChainSequenceController,
        ReferenceBuilder, SharedStateService,
        SecurityErrorHandler,
        ModalController,
        NotifierController, CommandErrorHandler,
        AppNavConfigInterpreter,
        EventManagerService,
    ],
})
export class FluxCore {

    public static getWindow(): Window {
        return window;
    }

    constructor( logger: Logger ) {
        logger.level = AppConfig.logLevel;
        Logger.level = AppConfig.logLevel;
    }

}

/**
 * FluxCoreDirectives
 * ...
 */
@NgModule({
    declarations: [
        IfState,
        IfStateClass,
        TrackAction,
        NgLet,
        NgAttr,
    ],
    exports: [
        IfState,
        IfStateClass,
        TrackAction,
        NgLet,
        NgAttr,
    ],
})
export class FluxCoreDirectives {
}

/**
 * FluxCommandService
 */
@NgModule({
    providers: [
        CommandService,
        { provide: 'ExecuteScenario', useClass: ExecuteScenario },
        { provide: 'PreviewScenario', useClass: PreviewScenario },
        { provide: 'CollabScenario', useClass: CollabScenario },
    ],
})
export class FluxCommandService {}
