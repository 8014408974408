import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { concat, Observable, of } from 'rxjs';
import { ignoreElements, mergeMap } from 'rxjs/operators';
import { CommandService } from '../../command/command.svc';
import { Logger } from '../../logger/logger.svc';
import { ChainSequenceController } from '../chain-sequence/chain-sequence-controller.svc';
import { IChainOutcomeCommandParams, InitializationChainStatus } from './initialization-chain-status';

/**
 * This is an extension of the {@link ChainSequenceController} which is used to control
 * the application initialization. This class adds the ability to handle outcomes
 * in the initialization sequence including dispatching commands or navigating to
 * a given route.
 *
 * For a detailed overview of the chain sequence system please refer to class documentation
 * on {@link ChainSequenceController}.
 *
 * @author  Ramishka
 * @since   2017-12-05
 */
@Injectable()
export class InitializationSequenceController extends ChainSequenceController {

    constructor( protected logger: Logger,
                 protected injector: Injector,
                 protected commandService: CommandService,
                 protected router: Router ) {
        super( injector );
    }

    /**
     * Starts the initialization sequence with a responsibility to start with.
     * @param chainStatus - current chain status
     * @param name - name of the responsibility to start with
     * @return Observable which emits all outcomes in the chain.
     */
    public startSequence( name: string,
                          chainStatus: InitializationChainStatus = <any>{ states: {}, outcome: undefined, input: {}},
      ): Observable<InitializationChainStatus> {
        this.logger.debug( 'InitializationSequenceController startSequence' );
        return this.start( name, chainStatus ).pipe(
            mergeMap( status => {
                if ( status.outcome.type === 'redirect' ) {
                    return this.redirect( status );
                } else if ( status.outcome.type === 'route' ) {
                    return this.navigate( status );
                } else if ( status.outcome.type === 'command' || status.outcome.type === 'commandEvent' ) {
                    return this.dispatchCommand( status );
                } else {
                    return of( status );
                }
            }));
    }

    /**
     * Navigates to a given route based on the outcome.
     * @param chain - Chain status which contains the outcome.
     *                Outcome action contains the route param to navigate to.
     * @return - Returns an observable that resolves into the chain status
     */
    protected navigate ( status: InitializationChainStatus ): Observable<InitializationChainStatus> {
        this.logger.debug( 'InitializationSequenceController navigate action : ' + status.outcome.action );
        this.router.navigateByUrl( status.outcome.action );
        return of( status );
    }

    /**
     * Redirects the browser to the given URL.
     * @param location the URL to redirect to
     * window.location.replace method is hard to spy so
     * unit tests are ignored for this.
     */
    /* istanbul ignore next */
    protected redirect( status: InitializationChainStatus ): Observable<InitializationChainStatus> {
        window.location.replace( status.outcome.action );
        return of( status );
    }


    /**
     * Dispatches a command based on the outcome.
     * The command being executed can be an unmapped command or a command mapped to a command event.
     * @param chain - Chain status which contains the outcome
     * @return observable - If wait parameter of the outcome is true, returns the observable
     *  returned by the command service concatenated into the status. If wait is false, returns
     *  an observable that immediately resolves into the status.
     */
    protected dispatchCommand( status: InitializationChainStatus ): Observable<InitializationChainStatus> {
        const { action, wait } = status.outcome;
        this.logger.debug( 'InitializationSequenceController command action : ' + action + ' with wait ' + wait );
        const commandParams: IChainOutcomeCommandParams = status.outcome.commandParams || {
            rid: status.input.resourceId,
            data: { status },
        };
        let commandObs: Observable<InitializationChainStatus>;
        if ( status.outcome.type === 'commandEvent' ) {
            commandObs = this.commandService.dispatch( action, commandParams.rid, commandParams.data );
        } else {
            commandObs = this.commandService.execute( action, commandParams.rid, commandParams.data );
        }
        if ( wait ) {
            return concat(
                commandObs.pipe(
                    ignoreElements(),
                ),
                of( status ),
            );
        } else {
            commandObs.subscribe();
            return of( status );
        }
    }
}
