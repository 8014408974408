import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { EntityLinkType } from 'flux-definition';
/**
 * If its a refLink called to update the link when the other side has a relevant change
 * OR to mark this one as resolved and flushes the changes.
 */
@Injectable()
@Command()
export class UpdateEDateRefChanges extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entityIds?: string[], // entities to apply this change to
        sourceEDataId?: string, // where id it get updated
        sourceEntityId?: string,
        sourceDiagId?: string,
        sourceShapeId?: string,
        type: EntityLinkType,
        changeId: string,
        linkId?: string, // when marking resolved
        markResolved?: boolean,
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        this.modelChangeUtils.updateEDateRefChanges(
            this.changeModel,
            this.data,
        );
    }

}

Object.defineProperty( UpdateEDateRefChanges, 'name', {
    value: 'UpdateEDateRefChanges',
});

