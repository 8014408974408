import { AbstractModel } from 'flux-core';
import { EDataModel } from './model/edata.mdl';
import { AbstractModelSubscription } from 'flux-subscription';

export class EDataSub extends AbstractModelSubscription {
    public get subscription(): string {
        return 'EData';
    }

    public get modelType(): typeof AbstractModel {
        return EDataModel;
    }
}
