import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces, Command } from 'flux-core';
import { Observable, empty, merge, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { tap, switchMap } from 'rxjs/operators';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';

/**
 * This is the command to remove collaborators from diagram.
 *
 * This extends {@link AbstractMessageCommand} to send a request to the server.
 *
 */
@Injectable()
@Command()
export class RemoveProjectCollaborators extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            collabIds: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        protected modelSubManager: ModelSubscriptionManager,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.getCollabs( this.resourceId ).pipe(
            tap( collaborators => {
                this.previousData.collabs = collaborators.filter(
                    collab => this.data.collabIds.indexOf( collab.id ) !== -1 );
            }),
        );
    }

    /**
     * execute
     * execute add collaborators to diagram model
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        const filterFn = c => this.data.collabIds.indexOf( c.id ) > -1 ;
        return modelStore.storageFilterCollabs( this.resourceId, filterFn );
    }

    /**
     * executeResult
     * According to the response add un removed collaborators
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        let unRemovedCollabs = [];

        // Check whether there are collaborators exist without removing in the server side
        if ( this.previousData ) {
            unRemovedCollabs = this.previousData.collabs.filter( collab => {
                const check = response.collabIds.indexOf( collab.id ) === -1 ;
                return check;
             });
        }
        let addUnRemovedCollabs: Observable<any>  = empty();
        if ( unRemovedCollabs.length > 0 ) {
            addUnRemovedCollabs = modelStore.storageMergeCollabs( this.resourceId, unRemovedCollabs );
        }

        const filterFn = c => response.collabIds.indexOf( c.id ) > -1 ;
        const removeCollabs = modelStore.storageFilterCollabs( this.resourceId, filterFn );
        const stopSubscriptions = this.stopSubscriptions( response.collabIds );

        return merge( addUnRemovedCollabs, removeCollabs, stopSubscriptions );
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore ;
        return modelStore.storageMergeCollabs( this.resourceId, this.previousData.collabs );

    }

    /**
     * Stops the userInfo subscriptions
     */
    protected stopSubscriptions( userIds: string[]): Observable<any> {
        return of( userIds  ).pipe(
            switchMap( collabIds => {
                const observables = [];
                collabIds.forEach( id => {
                    if ( id ) {
                        observables.push( this.modelSubManager.stop( id ));
                    }
                });
                return( merge( ...observables ));
            }),
        );
    }
}

Object.defineProperty( RemoveProjectCollaborators, 'name', {
    value: 'RemoveProjectCollaborators',
});
