import { IText, ITextContent, ITextStyles } from 'flux-definition';
import * as innerText from '@creately/inner-text';
import { isEmpty } from 'lodash';
import * as Handlebars from 'handlebars';

/**
 * TextModel is the abstract version of a shape text and a connector text models.
 * This model contains the properties and functianlities which are common to
 * both shape and connector texts
 *
 * @author Thisun
 * @since 2018-01-16
 */
export abstract class TextDataModel implements IText {

    public type: 'shape' | 'connector';

    /**
     * Rendering method
     */
    public rendering: 'carota' | 'tiptapCanvas' | 'dom';

    /**
     * Enable tiptap feature
     */
    public enableTiptap: boolean = false;

    /**
     * The width of the text if the text is not wrapped
     */
    public maxWidth: number;

    /**
     * The height of the text if the text is not wrapped
     */
    public minHeight: number;

    /**
     * The height of the text if the text is fully wrapped
     */
    public maxHeight: number;

    /**
     * THe HTML form of the text model
     */
    public html?: string = '<p></p>';

    /**
     * The preferredColor color for the text
     * The default value is custom and if its set to line,
     * Text color will be always set to line color of the shape when
     * styles are applied
     */
    public preferredColor: 'line' | 'fill' | 'custom' = 'custom';

    /**
     * Width of the text and expected to be calculated and set when text updated
     */
    public width: number;

    /**
     * The minimum width of the text ( The width of the longest word )
     */
    public minWidth: number = 0;

    /**
     * Height of the text and expected to be calculated and set when text updated
     */
    public height: number;

    /**
     * A unique id for the text. To use a data as the value,
     * the idenfier of the text should be same as the identifier of the data.
     */
    public id: string;

    /**
     * Used to say this text is the primary text and what will be used
     * for all default cases. Only one can be set as primary.
     * If primary is set editable will be true.
     */
    public primary: boolean;

    /**
     * FIXME: remove this property after migrating all current diagrams.
     * @deprecated
     */
    public value: string = '';

    /**
     * The text content stored in Carota format. This property supersedes the `value`
     * property. This new format is more flexible compared to plain HTML which will
     */
    public content: ITextContent[] = [];

    /**
     * Placeholder is to be considered when the text content becomes empty
     */
    public placeholder: ITextContent[] = [];


    /**
     * Boolean property to indicate if the user can edit the text through the UI
     */
    public editable: boolean = true;

    /**
     * hitArea is the default space for the text,
     * If this property is defined for a shape text, that shape text can exist event if the
     * text value is empty
     * i.e. These width, heigh values will be used to draw a vertual hit area when the
     * shape text value becomes empty.
     * The x y for the hit area can also be set, if x and y are undefined the hitArea
     * will be placed considering the center of the text bounds.
     */
    public hitArea: { width: number, height: number, x?: number, y?: number };

    /**
     * fixedFormat is an optional property to lock format for the text.
     * so when new styles are applied, these styles will remain unchanged
     */
    public fixedFormat: ITextStyles;

    /**
     * Id of editable data item to distinguish between different views in edit and display
     * The id will point to the data item that contains the editable text.
     */
    editableDataItem?: string;

    /**
     * Id of text model with editable text
     * Meant to exist on displayable text model for reference
     */
    editableTextId?: string;

     /**
      * Id of text model with displayable text
      * Meant to exist on editable text model for reference
      */
    displayableTextId?: string;

    /**
     * If true, text model will be rendered with tiptapCanvas method always
     */
    forceTiptapRendering?: boolean = false;

    /**
     * True if the text is custom created and false if the text
     * is originated by the definition,
     * The texts defined in the definition should not be deleted
     * This property is useful to decide if a particular text model
     * should be deleted or not.
     */
    public customCreated: boolean = false;

    /**
     * Show this text field?
     */
    public isVisible: boolean = true;


    /**
     * Placeholder plain text
     */
    public get placeholderPlainText(): string {
        return this.getPlainText( this.placeholder );
    }

    /**
     * Returns the plain text
     */
    public get plainText(): string {
        if ( this.rendering === 'tiptapCanvas' || this.rendering === 'dom' ) {
            if ( !this.html ) {
                this.html = '';
            }
            return innerText( this.html ).trim();
        }
        return this.getPlainText( this.content );
    }

    /**
     * This method replaces handlebars with the corresponding values
     * returns undefined if handlebars is not defined
     */
    public get handlebarsReplacedText(): string {
        if ( !isEmpty(( this as any ).handlebars )) {
            const data = JSON.parse(( this as any ).handlebars );
            const modified = this.content.map( run => {
                // Square brackets [] Allow for spaces when replacing
                let newText = run.text.replace( /\{\{/g, '{{{[' );
                newText = newText.replace( /\}\}/g, ']}}}' );
                const source = newText;
                let result = source;
                try {
                    const template = Handlebars.compile( source );
                    result = template( data );
                } catch ( e ) {
                }
                return { ...run, text: result };
            });
            return this.getPlainText( modified );
        }
    }

    public isAlignable() {
        return false;
    }

    public getWordWrapWidth( shapeWidth: number, autoResize: boolean ):  string {
        return shapeWidth + 'px';
    }

    /**
     * Converts and retunrs the ITextContent[] to a plain text string
     */
    private getPlainText( content: ITextContent[]): string {
        let plaintext = '';
        for ( const block of content ) {
            plaintext += block.text;
        }
        return plaintext.trim();
    }

}
