import { AbstractModel } from 'flux-core';

/**
 * User Subscription Model
 * This holds information about a user subscription.
 *
 * @author  Shashik
 * @since   2020-12-21
 */

export enum SubscriptionStatusTypes {

/**
 * The user is enabled and active.
 */
    ACTIVE = 'ACTIVE',

/**
 * The user is paused.
 */
    PAUSED = 'PAUSED',

/**
 * The user cancel the subscription and closed the account.
 */
    CANCELLED = 'CANCELLED',

/**
 * The user scheduled a subscription cancellation.
 */
  NON_RENEWING = 'NON_RENEWING',

}

export class UserSubscription extends AbstractModel {

    /**
     * current subscription plan id.
     */
    public planId: string;

    /**
     * This indicate the status of the subscription whether it is ACTIVE, PAUSE
     * or CANCELLED.
     */
    public status: string;

    public get subStatus(): SubscriptionStatusTypes {
      return SubscriptionStatusTypes[ this.status ];
  }
}

Object.defineProperty( UserSubscription, 'name', {
  writable: true,
  value: 'UserSubscription',
});
