import { Injectable } from '@angular/core';
import { AbstractCommand, Command, ModalController, StateService } from 'flux-core';
import { IDialogBoxData, PlanPermission } from 'flux-definition';
import { PlanPermManager, UpgradeDialog, UpgradeDialogWindow } from 'flux-user';
import { PLAN_PERM_ERROR_MESSAGE } from '../../../base/permission/plan-perm-error-message';

/**
 * CheckPermissions
 * Checks for a given permission for the user and throws an error
 * and displays a permission error dialog if the permission is denied.
 */
@Injectable()
@Command()
export class CheckPermissions extends AbstractCommand {

    /**
     * Command input data format
     */
    public data: {
        permission: PlanPermission,
        permData?: any,
    };

    /**
     * Inject plan perm manager.
     */
    constructor(
        protected planPermManager: PlanPermManager,
        protected modalController: ModalController,
        protected state: StateService<any, any>,
    ) {
        super() /* istanbul ignore next*/;
    }

    public execute() {
        const permission = this.data.permission;

        if ( !this.planPermManager.check([ permission ], this.data.permData )) {
            const dialogData = {
                ...PLAN_PERM_ERROR_MESSAGE[ permission ],
                descriptionParams: {
                    maxUsage: this.planPermManager.getMaxUsage( permission ),
                },
                buttons: [
                    {
                        type: 'upgrade',
                        clickHandler: () => {},
                    },
                ],
                integrationContext: {
                    embedded: this.state.get( 'ApplicationIsEmbedded' ),
                    environment: this.state.get( 'PluginApp' ),
                },
            } as IDialogBoxData;

            if ( dialogData.type === 'iframe' ) {
                this.modalController.show( UpgradeDialogWindow, {
                    inputs: {
                        dialogData: dialogData,
                    },
                });
            } else {
                this.modalController.show( UpgradeDialog, {
                    inputs: {
                        dialogData: dialogData,
                    },
                });
            }

            throw new Error( 'Permission is denied' );
        }
        this.resultData = this.data;
        return true;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( CheckPermissions, 'name', {
    value: 'CheckPermissions',
});
