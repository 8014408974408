import { CommandEvent, CommandMapper } from 'flux-core';
import { GetTeamNamesByIds } from './get-teams-name.cmd';

/**
 * TeamCommandEvent is the base class for the all the commands related to team.
 * When a command is dispatched to the command system, the associated commands.
 *
 * @author Banujan
 * @since 2023-09-15
 */
export class TeamCommandEvent extends CommandEvent {

    /**
     * Fetch the team names for the given team Ids.
     *
     * Input Data: {
     *   teamIds: ['teamId1', 'teamId2']
     * }
     *
     */
    static getTeamsNameByIdsCommand: TeamCommandEvent = new TeamCommandEvent( 'getTeamNamesByIds' );
    protected static registerGetTeamsNameCommand( mapper: CommandMapper ) {
        mapper.mapSequence( TeamCommandEvent.getTeamsNameByIdsCommand ).add( GetTeamNamesByIds as any );
    }
}
