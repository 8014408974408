import { AbstractModel } from 'flux-core';

/**
 * @Relationship
 *
 * The @Relationship decorator can be used to to specify relationships
 * between models. If a relationship is set, the related model will be
 * queried from the local database and attached to the model by the
 * data store service.
 *
 * Example:
 *
 *  @Relationship()
 *  public foo: Foo;
 *
 * In the above example, it defines that "foo" has a relationship with
 * a model of type "Foo". The parent model will have either a string
 * id or an object with an id field for the field "foo" which will be
 * used to select the related model.
 *
 * If the field type and the type the data is stored under are different
 * the source model type can be given as a parameter to the decorator.
 *
 * Example:
 *
 *  @Relationship( Foo )
 *  public bar: Bar;
 *
 * TODO:
 * If the related model should be identified with a different selector,
 * an object with a type and selector can be given to the decorator.
 *
 * Example:
 *
 *  @Relationship({
 *      type: Foo,
 *      selector: val => ({ name: val }),
 *  })
 *  public bar: Bar;
 *
 * The @Relationship decorator can be used on array type fields.
 *
 * Example:
 *
 *  @Relationship( Foo )
 *  public fooArr: Foo[];
 *
 * When using the decorator an on array type field, the generic type of the array
 * must be provided to the decorator at all times. This is due to a limitation
 * in the Reflect API where the generic type of the array cannot be detected at
 * runtime.
 *
 * When using the decorator on an array type field, it is possible to fetch
 * data from a lower level model collection and then to create instances of a
 * higher level model with the fetched data.
 *
 * Example:
 *
 *  @Relationship( Foo, Bar )
 *  public fooArr: Bar[];
 *
 * In the above example, the data store service will fetch data from the Foo collection
 * and create an array of Bar type models by extending each fetched Foo model.
 *
 */
// tslint:disable-next-line:only-arrow-functions
export function Relationship( sourceType?: typeof AbstractModel, instanceType?: typeof AbstractModel ) {
    return ( target: any, key: string ) => {
        const resultType = Reflect.getMetadata( 'design:type', target, key );

        if ( resultType === Array ) {
            if ( !sourceType ) {
                throw new Error( 'A valid model type must be provided to the @Relationship decorator when the field'
                + 'type is an array' );
            }
        } else {
            if ( !AbstractModel.isModelType( resultType )) {
                throw new Error( 'Unexpected Error: the @Relationship decorator should be used on model type fields.' );
            }
        }

        if ( sourceType === undefined ) {
            sourceType = resultType;
        }
        if ( !AbstractModel.isModelType( sourceType )) {
            throw new Error( 'Unexpected Error: the @Relationship decorator should be used with a model type.' );
        }

        if ( instanceType && !AbstractModel.isModelType( instanceType )) {
            throw new Error( 'Unexpected Error: @Relationship decorator instance type should be a model type.' );
        }

        if ( !target.relationshipProperties ) {
            target.relationshipProperties = {};
        }
        target.relationshipProperties[ key ] = { sourceType, resultType };
        if ( instanceType ) {
            target.relationshipProperties[ key ].instanceType = instanceType;
        }
    };
}
