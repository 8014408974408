import { AbstractModel } from 'flux-core';

/* istanbul ignore next */
export class RuleModel extends AbstractModel {

    /**
     * The ID for the model.
     */
    public id: string;

    // <RuleDef|RuleCombinators>
    public ruleSet: any[];

    /**
     * active status of the rule
     * determines if the rule is evaluated
     * and applied to shapes
     */
    public isActive: boolean;

    /**
     * label of the data item being used in evaluation
     */
    public dataItemLabel: string;

    /**
     * potential targets to which the rule will be applied.
     */
    /* istanbul ignore next */
    public targets: IRuleContext[] = [];

    /**
     * logical operator to be used with
     * the two operands - shape value and rule value
     */
    public comparison: RuleLogicalOperators;
    /**
     * value against which a shape's desired data item value
     * is compared.
     */
    public value: any;
    /**
     * an object determining the category of actions that should
     * be applied upon successful evaluation of the rule
     */
    public action: RuleActions;
    /**
     * key pair map of properties which should undergo changes
     * as a result of the rule being evaluated to true on a shape
     * and the values to be applied to those properties.
     */
    public actionData: any;

    /**
     * Current active/inactive status
     */
    public status: RuleState;

    /** Name of the rule */
    public name: string;

    /**
     * Who created this rule?
     */
    public creatorUid: string;

    /**
     * Last updated by who?
     */
    public updatedUid: string;

    /**
     * a number to sort the rules by in client side.
     * The score affects the order in which rules are displayed
     * and evaluated.
     */
    public priorityScore: number;

    /**
     * Lists of shapes to show and hide for rules applied on specific shapes directly such as displayIndicators rule
     */
    public show?: { [indicatorName: string]: string[] };
    public hide?: { [indicatorName: string]: string[] };

    /**
     * false if rule could not be edited directly like displayIndicators rule
     */
    public isEditable?: boolean;

}

export interface IRuleContext {
    dataItemId: string;
}

export enum RuleCombinators {
    AND = 'AND',
    OR = 'OR',
    XOR = 'XOR',
}

export enum RuleLogicalOperators {
    Equals = 'equals',
    GreaterThan = ' > ',
    LesserThan = ' < ',
    Contains = 'contains',
    SubText = 'subtext',
    IsTrue = 'true',
    IsFalse = 'false',
    InBetween = 'inbetween',
}

export enum RuleActions {
    Hide = 'hide',
    Show = 'show',
    Fade = 'fade',
    Style = 'style',
}

export enum RuleState {
    OnForMe = 'OnForMe',
    OnForAll = 'OnForAll',
    OffForMe = 'OffForMe',
    OffForAll = 'OffForAll',
    OnUnsaved = 'OnUnsaved',
    OffUnsaved = 'OffUnsaved',
}
