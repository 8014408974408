import { ComplexType } from 'flux-core';
import { PlanModel } from '../../permission/model/plan.mdl';
import { UserSubscription } from '../../permission/model/user-subscription.mdl';
import { TeamModel } from '../../team/model/team.mdl';
import { UserInfoModel } from './user-info.mdl';

/**
 * Email validation status enum
 */
export enum EmailValidStatus {

  /**
   * The user email is invalid
   */
  INVALID,

  /**
   * The user email is risky
   */
  RISKY,

  /**
   * The user email is safe
   */
  SAFE,

  /**
   * The user email is unknown
   */
  UNKNOWN,
}

/**
 * UserModel
 * This is a user model that represents a given user.
 * Contains all details about a user. Extends {@link UserInfoModel}
 *
 * @author  hiraash
 * @since   2015-10-07
 */

export class UserModel extends UserInfoModel {

    /**
     * This holds the plan and plan permission related details of this user.
     */
    @ComplexType()
    public plan: PlanModel;

    /**
     * All information related to the users team (if any) is held here.
     */
    @ComplexType( TeamModel )
    public team: TeamModel;

    @ComplexType( )
    public userSubscription: UserSubscription;

    public teamGroupIds: string[];

    /**
     * User's preferences as key value pairs.
     */
    public preferences: { [ preferenceName: string ]: any };

    /**
     * User's seen video IDs
     */
    public seenMedia: { [ mediaType: string ]: any };

    /**
     * User's seen tooltips
     */
    public seenTooltips: {[tooltipId: string]: boolean};

    /**
     * This boolean holds usage exceeded state of the user.
     */
    public usageExceeded: boolean;

    /**
     * This boolean holds suspended state of the user.
     */
    public suspended: boolean;

    /**
     * This hold the email validation status of the user
     */
    public emailValid: string;

    constructor( id: string ) {
        super( id );
    }

    /**
     * The current email valid staus of the user
     * as type of EmailValidStatus
     */
    public get emailValidStatus(): EmailValidStatus {
      return EmailValidStatus[ this.emailValid ];
    }

}

Object.defineProperty( UserModel, 'name', {
  writable: true,
  value: 'UserModel',
});
