import { IStructuralNode, FillType } from './svg-node/structural-node.i';
import { IShapeNode } from './svg-node/shape-node.i';
import { Matrix } from 'flux-core';
import { CanvasDrawer } from './canvas-drawer';
import { SvgTransformationToMatrix } from './svg-transform-to-matrix';
import { IStyleDefinition, IShapeText } from 'flux-definition';
import { ITextNode, ITextStructuralNode } from './svg-node/text-node.i';
import { SvgTextContentFactory } from './canvas-instruction/svg-text-content-factory';
import { ICanvasInstruction } from './canvas-instruction/canvas-instruction.i';

/**
 * Define decomposed element attribute used internally while generating nodes.
 * Type related to the name of the attribute and value holds the actual value.
 */
interface IAttribute {
    type: string;
    value: any;
}

/**
 * class SvgToCanvas
 * This implements functionality to extract svg instructions from given svg string and converting them to
 * relevant structures so that they can be used to transform svg instructions to graphics instructions.
 * This svg parser will iterate a given svg as a DOM structure until it finds all shape elements of the svg.
 */
export class SvgToCanvas {

    /**
     * Current svg string converted to DOM object.
     * This will be used to refer elements when generating instructions for linked elements such as,
     * - gradients linked by fill urls
     * - elements linked by use elements
     */
    private svgom: Element;

    /**
     * All svg attributes we treat as style related attributes.
     * Any attribute not on this list is treated as data attributes which is used to
     * draw shapes.
     */
    private readonly styleAttributes = [
        'fill',
        'fillOpacity',
        'opacity',
        'stroke',
        'strokeDasharray',
        'strokeDashoffset',
        'strokeLinecap',
        'strokeLinejoin',
        'strokeMiterlimit',
        'strokeOpacity',
        'strokeWidth',
        'vectorEffect',
        'transform',
        'style',
        'fontSize',
        'fontFamily',
        'fontWeight',
        'fontStyle',
        'textDecoration',
        'baselineShift',
    ];

    /**
     * Svg attributes which are ignored from translation process
     */
    private readonly ignoredAttributes = [
        'fillRule',
        'clipRule',
    ];

    /**
     * Current CanvasDrawer instance
     */
    private canvasDrawer: CanvasDrawer;

    /**
     * Bounds of the current svg.
     * This will be the default selection bounds of the shape while on Nucleus.
     */
    private shapeBounds: { width: number, height: number } = { width: 0, height: 0 };

    /**
     * SVG texts data.
     * THis will have all extracted texts and their styles.
     */
    private texts: ITextStructuralNode[] = [];

    /**
     * Indicator to generate the canvas instructions from given svg or not.
     */
    private generateCanvasInstructions: boolean = true;

    /**
     * Indicator to extract the texts or not.
     */
    private extractTextData: boolean = true;

    /**
     * Holds the top level shape transformation. This should be
     */
    private shapeMatrix: Matrix;

    /**
     * Initialize a canvasDrawer instance to be used
     */
    public constructor() {
        CanvasDrawer.registerFactories();
    }

    /**
     * Convert given svg string to canvas instructions and shape texts and return them as an object.
     * @param svg Svg string
     * @param generateInstructions boolean parameter which indicates whether to generate canvas instructions or not.
     * @param extractText boolean parameter which indicates weather to extract text data or not.
     * @returns { ICanvasInstruction[], IShapeTexts[] }
     */
    public convert( svg: string, generateInstructions: boolean = true, extractText: boolean = true ):
                                                    ({ instructions: string[], texts: IShapeText[]}) {
        this.generateCanvasInstructions = generateInstructions;
        this.extractTextData = extractText;
        this.canvasDrawer = new CanvasDrawer();
        const parser = new DOMParser();
        this.svgom = parser.parseFromString( svg, 'text/html' ).body;
        this.processLinearGradients( this.svgom );
        this.processRadialGradients( this.svgom );
        this.extractShapeMatrix( this.svgom );
        this.traverseSvgom( this.svgom, { type: 'svg', parentIds: []});
        return { instructions: this.generateCanvasInstructions ? this.canvasDrawer.getInstructionStrings() : undefined,
                    texts: this.getTextContent() };
    }

    public getSvgBounds(): { width: number, height: number } {
        return this.shapeBounds;
    }

    /**
     * Return the style definitions generated by the svg to shape conversion process
     */
    public getStyleDefinitions(): { [id: string]: IStyleDefinition } {
        return this.canvasDrawer.getStyleDefinitions();
    }

    /**
     * Return all the graphics draw instructions
     */
    public getInstructions(): ICanvasInstruction[] {
        return this.canvasDrawer.getInstructions();
    }

    /**
     * Converts and return all extratced text data to array Shape texts.
     */
    protected getTextContent(): IShapeText[] {
        // SvgTextContentFactory is not following convas instruction
        // factories. Because unlike svg drawing elements SVG eliment
        // can have nested elements and the internal contents should
        // be handled differently. Also the extracted data should be
        // stored differently than draw code.
        return new SvgTextContentFactory().createTextContents(
            this.texts, this.shapeBounds.width, this.shapeBounds.height );
    }

    /**
     * Extracts the transformation matrix if the shape has transfomations and add it to shapeMatrix.
     * @param svg SVG element to extract shape transformation.
     */
    private extractShapeMatrix( svg: Element ) {
        if ( svg.tagName.toLowerCase() === 'body' ) {
            svg = svg.firstChild as Element;
        }
        if ( svg.tagName.toLowerCase() === 'svg' ) {
            svg = svg.children.item( 0 ) as Element;
        }
        if ( svg.tagName.toLowerCase() === 'g' ) {
            const transform = svg.getAttribute( 'transform' );
            this.shapeMatrix = SvgTransformationToMatrix.getMatrix( transform );
        } else {
            this.shapeMatrix = new Matrix();
        }
    }

    /**
     * Recursively traverse the given svg DOM and translate each node to instructions.
     * Following SVG element types are pre-translated so they will be skipped.
     * - defs
     * - title
     * - linearGradient
     * - radialGradient
     *
     * During initial call to this function, minimal structure node must be provided by the caller.
     * That structure node must have the type and parentIds set to defaults. Default for type is 'svg'
     * and parentIds is an empty array. ie: '{ type: 'svg', parentIds: [] }'
     * @param element Svg DOM element
     * @param node StructuralNode
     */
    private traverseSvgom( element: Element, node: IStructuralNode ) {
        if ( element.nodeName === 'text' ) {
            // Extract the text only if it is requested.
            if ( this.extractTextData ) {
                this.traverseSvgText( element, node );
            }
        } else if ( element.hasChildNodes()) {
            Array.from( element.children ).forEach( child => {
                switch ( child.nodeName ) {
                    case 'defs':
                    case 'desc':
                    case 'title':
                    case 'linearGradient':
                    case 'radialGradient':
                        break;

                    default:
                        if ( child.nodeName === 'svg' ) {
                            this.setShapeBounds( child );
                        }
                        this.traverseSvgom( child, this.extractStructureNode( element, node ));
                        break;
                }
            });
        } else {
            if ( element.nodeName === 'use' ) {
                this.processUseElement( element, node );
            } else if ( this.generateCanvasInstructions && element.nodeName !== 'g' ) {
                // Generate canvas instructions only if it is requested.
                const shapeNode = this.exctractShapeNode( element, node );
                this.canvasDrawer.createInstructions( shapeNode );
            }
        }
    }

    /**
     * Recursively traverse the given svg's text DOM and extract text data for each node.
     *
     * This function should be called only with "Text" node. It will iterate through all
     * its internal nodes to generates the text data.
     *
     * It throws exception if any unsupported inner text node is availabe in given text node.
     *
     * @param element Svg's text DOM element
     * @param node StructuralNode
     */
    private traverseSvgText( element: Element, parent: IStructuralNode ) {
        const textNode = Object.assign({} as ITextStructuralNode, this.extractTextStructureNode( element, parent ));
        if ( element.nodeName === 'text' ) {
            this.texts.push( textNode );
            textNode.children = [];
        }
        const textList = this.texts[ this.texts.length - 1 ].children;
        if ( element.hasChildNodes()) {
            let elementIndex: number = 0;
            const children = element.children;
            Array.from( element.childNodes ).forEach( childNode => {
                if ( childNode.nodeType === childNode.TEXT_NODE ) {
                    if ( childNode.textContent.trim() !== '' ) {
                        const newTextNode = <ITextNode>Object.assign({ text: childNode.textContent.trim() }, textNode );
                        if ( textNode.data.x || textNode.data.y ) {
                            const translateString =
                                'translate(' + ( textNode.data.x || 0 ) + ',' + ( textNode.data.y || 0 ) + ')';
                            newTextNode.transform =
                                this.calculateTransformationMatrix( translateString, newTextNode.transform );
                            const shapeInv = this.shapeMatrix.clone().invert();
                            const mat = shapeInv.appendMatrix( newTextNode.transform );
                            newTextNode.transform = new Matrix( mat.a, mat.b, mat.c, mat.d, mat.tx, mat.ty );
                            // Add the transformation to root text only if there is one child text node.
                            if ( element.tagName.toLowerCase() === 'text' && element.childNodes.length === 1 ) {
                                textNode.transform = newTextNode.transform.clone();
                            }
                        }
                        textList.push( newTextNode );
                    }
                } else {
                    const childElement = children.item( elementIndex++ );
                    if ( childElement.nodeName === 'tspan' ) {
                        this.traverseSvgText( childElement, textNode );
                    } else {
                        throw new Error( 'SVG text element ' + childNode.nodeName + ' is not supported yet.' );
                    }
                }
            });
        }
    }


    /**
     * Exctract svg shape width and height from svg element attributes.
     * This width and height is the default bounds of the shape.
     *
     * @param element svg element
     */
    private setShapeBounds( element: Element ) {
        const svgElementAttrs = this.decomposeElementAttributes( element );
        const boundAttrs = svgElementAttrs
                            .filter( at => at.type === 'width' || at.type === 'height' || at.type === 'viewBox' );
        // convert the bounds attrs to a map
        const boundsMap: { width?: string, height?: string, viewBox?: string } = {};
        boundAttrs.forEach( attr => {
            boundsMap[ attr.type ] = attr.value;
        });
        if ( boundsMap.width && boundsMap.height ) {
            this.shapeBounds.width = +boundsMap.width.replace( 'px', '' );
            this.shapeBounds.height = +boundsMap.height.replace( 'px', '' );
        } else if ( boundsMap.viewBox ) {
            const vBoxVals = boundsMap.viewBox.split( ' ' );
            this.shapeBounds = { width: +vBoxVals[2], height: +vBoxVals[3] };
        } else {
            throw new Error( 'SVG does not have necessary properties to calculate its size.' );
        }
    }

    /**
     * Process all linear gradients of the given svg DOM object.
     * This must be completed before traversing the svg DOM.
     * @param element svg DOM element
     */
    private processLinearGradients( element: Element ) {
        Array.from( element.querySelectorAll( 'linearGradient' )).forEach( node => {
            const shapeNode = this.exctractGradient( node, { type: 'svg', parentIds: []});
            this.canvasDrawer.createInstructions( shapeNode );
        });
    }

    /**
     * Process all radial gradients of the given svg DOM object.
     * This must be completed before traversing the svg DOM.
     * @param element svg DOM element
     */
    private processRadialGradients( element: Element ) {
        Array.from( element.querySelectorAll( 'radialGradient' )).forEach( node => {
            const shapeNode = this.exctractGradient( node, { type: 'svg', parentIds: []});
            this.canvasDrawer.createInstructions( shapeNode );
        });
    }

    /**
     * Process SVG use element.
     * Use element behaves differently than other elements,
     * - Styles on use element has no effect if referenced element contains that style
     * - X and Y values are the translation attributes
     * - Translation mmust be applied before any other transformation
     * - SVG group element can also be referenced by an use element
     * - Use element itself can have transformations set but they must follow initial translation command
     * @param element Svg Element node
     * @param parent Parent structure node
     */
    private processUseElement( element: Element, parent: IStructuralNode ) {
        const structuralNode = Object.assign({}, parent );
        // Get use element attributes
        const attributes = this.decomposeElementAttributes( element );
        const stIns = this.extractStyleInstructions( attributes );
        const nodeData = this.exctractNodeData( attributes );
        const usedElement = this.getElementById( this.fillUrlToId( nodeData.href ));

        Object.assign( structuralNode, stIns );

        // set the translate to parent node
        structuralNode.transform = this.calculateTransformationMatrix(
                `translate(${nodeData.x ? nodeData.x : 0},${nodeData.y ? nodeData.y : 0})`,
                parent.transform,
            );

        // If use element itself has transform, append it to parent transform
        if ( stIns.transform ) {
            structuralNode.transform = this.calculateTransformationMatrix( stIns.transform, structuralNode.transform );
        }

        this.traverseSvgom( usedElement, structuralNode );
    }

    /**
     * Extract structural node attributes from given svg DOM element and merge them with given parent structural node.
     * This will use all attribures for parent node and will update parentIds if the given element has a id attribute.
     * Any transformations will be converted to a matrix and will merge with the parent matrix if exists. One important
     * thing to note is that this will override all parent attributes ( other than parentIds and transform ) with given
     * element attributes.
     * @param element Svg Element node
     * @param parent Parent structure node
     * @returns IStructuralNode
     */
    private extractStructureNode( element: Element, parent: IStructuralNode ): IStructuralNode {
        const structuralNode = Object.assign({}, parent );
        structuralNode.type = element.nodeName;

        const attributes = this.decomposeElementAttributes( element );
        const id = this.getElementId( attributes );
        if ( id ) {
            structuralNode.parentIds.push( id );
        }
        return <IStructuralNode>this.finalizeNode( structuralNode, parent, attributes );
    }

    /**
     * Exctract shape node attributes from given svg DOM element, merge them with given parent node
     * and return a shape node. While creating the shape node, any attribute which is not treated as style
     * attribute will be added to 'data' of the node.
     * @param element Svg Element node
     * @param parent Parent structure node
     * @returns IShapeNode
     */
    private exctractShapeNode( element: Element, parent: IStructuralNode ): IShapeNode {
        const attributes = this.decomposeElementAttributes( element );
        const shapeNode: IShapeNode = Object.assign({ data: this.exctractNodeData( attributes ) }, parent );
        shapeNode.type = element.nodeName;
        return <IShapeNode>this.finalizeNode( shapeNode, parent, attributes );
    }

    /**
     * Exctract text node attributes from given svg DOM element, merge them with given parent node
     * and return a text node. While creating the text node, any attribute which is not treated as style
     * attribute will be added to 'data' of the node.
     * @param element Svg Element node
     * @param parent Parent structure node
     * @returns ITextStructuralNode
     */
    private extractTextStructureNode( element: Element, parent: IStructuralNode | ITextStructuralNode ):
                                                                                    ITextStructuralNode {
        const child = Object.assign({}, parent ) as ITextStructuralNode;
        child.type = element.nodeName;
        const attributes = this.decomposeElementAttributes( element );
        child.data = this.exctractNodeData( attributes );
        return this.finalizeNode( child, parent, attributes ) as ITextStructuralNode;
    }

    /**
     * Finalize the creation of shape node or structural node by adding fill and stroke attributes and merging
     * any tramsformations if exist.
     * @param node Svg element structure node or shape node
     * @param parent Parent structure node
     * @param attributes Node attributes
     */
    private finalizeNode(
        node: IShapeNode | IStructuralNode,
        parent: IStructuralNode,
        attributes: IAttribute[],
    ): IShapeNode | IStructuralNode {
        const stIns = this.extractStyleInstructions( attributes );
        Object.assign( node, stIns );
        if ( node.fill ) {
            const fillAttrs = this.getFillType( node.fill );
            node.fill = fillAttrs.fill;
            node.fillType = fillAttrs.type;
        }
        if ( node.stroke ) {
            const strokeAttrs = this.getFillType( node.stroke );
            node.stroke = strokeAttrs.fill;
            node.strokeType = strokeAttrs.type;
        }
        if ( stIns.transform ) {
            node.transform = this.calculateTransformationMatrix( stIns.transform, parent.transform );
        }
        return this.ConvertToNumberType( node );
    }

    /**
     * Exctract gradient instructions from given avg gradient element and return a constructed shape node.
     * During the exctraction process, attributes from gradient element will only be used for style instructions
     * and all child element attributes contained on the gradient element will contribute to data of the shape node.
     * ie:
     * <radialGradient id="id-string" cx="40.2246" cy="33.0293" r="1.793" gradientUnits="userSpaceOnUse">
     *      <stop offset="0" style="stop-color:#FFFFFF"/>
     *      <stop offset="1" stop-color="#808285"/>
     * </radialGradient>
     * If the gradient contains gradientTransform matrix, it will also be added to data of the node.
     * @param element Svg Element node
     * @param parent Parent structure node
     * @returns IShapeNode
     */
    private exctractGradient( element: Element, parent: IStructuralNode ): IShapeNode {
        const elementAttributes = this.decomposeElementAttributes( element );
        const shapeNode: IShapeNode = Object.assign({ data: this.exctractNodeData( elementAttributes ) }, parent );
        shapeNode.type = element.nodeName;
        Object.assign( shapeNode, this.extractStyleInstructions( elementAttributes ));
        if ( element.children.length > 0 ) {
            const stops = [];
            Array.from( element.children ).forEach( child => {
                const childAttrs = this.decomposeElementAttributes( child );
                stops.push( Object.assign(
                    this.exctractNodeData( childAttrs ),
                    this.extractStyleInstructions( childAttrs ),
                ));
            });
            shapeNode.data.stopValues = stops;
        }
        return shapeNode;
    }

    /**
     * Read all given elements' attributes and return them in an array
     * @param element Svg element
     */
    private decomposeElementAttributes( element: Element ): Array<IAttribute> {
        const attributes = [];
        Array.from( element.attributes ).forEach( attribute => {
            const name = this.convertNameToCamelCase( attribute.name );
            if ( !this.ignoredAttributes.includes( name )) {
                attributes.push({
                    type: name,
                    value: attribute.value.replace( /(\r?\n|\r)|(\s)/g, ' ' ),
                });
            }
        });
        return attributes;
    }

    /**
     * Convert given CSS style string to individual style attribute and value pairs.
     * @param styleString
     */
    private decomposeStyleAttribute( styleString: string ): Array<IAttribute> {
        const attributes = [];
        const styles = styleString.split( ';' );
        styles.forEach( style => {
            if ( style ) {
                const val = style.split( ':' );
                attributes.push({ type: this.convertNameToCamelCase( val[0]), value: val[1] });
            }
        });
        return attributes;
    }

    /**
     * Exctract all svg styling attributes from given set of attributes.
     * This will treat everything on styleAttributes property as styles and everything else
     * will be ignored.
     * @param attributes
     */
    private extractStyleInstructions( attributes: Array<IAttribute> ): any {
        const instructions = {};
        let styles;
        attributes.forEach( attribute => {
            if ( this.styleAttributes.includes( attribute.type )) {
                if ( attribute.type === 'style' ) {
                    // Style attributes have precedence over element attributes when applying styles
                    // Store the style attribute to process after done with current attributes
                    // Note: this assumes that element will have only one style attribute
                    styles = attribute.value;
                } else {
                    instructions[ attribute.type ] = attribute.value;
                }
            }
        });
        if ( styles ) {
            const stAttrs = this.decomposeStyleAttribute( styles );
            stAttrs.forEach( attr => {
                instructions[ attr.type ] = attr.value;
            });
        }
        return instructions;
    }

    /**
     * Exctract data attributes from given set of attributes and return them as an object.
     * Everything not listed on styleAttributes property will be treated as data attributes.
     * @param attributes
     */
    private exctractNodeData( attributes: Array<IAttribute> ): any {
        const data = {};
        attributes.forEach( attribute => {
            if ( !this.styleAttributes.includes( attribute.type )) {
                if ( attribute.type === 'xlink:href' ) {
                    attribute.type = attribute.type.split( ':' ).pop();
                }
                // Anything which is not a style attribute is a data attribute
                // Remove any whitespace or newline characters from data
                data[ attribute.type ] = attribute.value.replace( /(\r?\n|\r)/g, '' );
            }
        });
        return data;
    }

    /**
     * Convert given string with dashes ( - ) to camel case
     * @param name
     * @returns camel case string
     */
    private convertNameToCamelCase( name: string ): string {
        const nameParts = name.split( '-' );
        if ( nameParts.length > 1 ) {
            let convertedName = '';
            nameParts.forEach(( value, index ) => {
                if ( index === 0 ) {
                    convertedName = value;
                } else {
                    convertedName += value.charAt( 0 ).toUpperCase() + value.slice( 1 );
                }
            });
            return convertedName;
        }
        return name;
    }

    /**
     * Returns the type of fill a given fill value has
     * @param fill fill value
     * @returns type of fill the given fill has, solid, radialGradient of linearGradient
     */
    private getFillType( fill: string ): { fill: string, type: FillType } {
        if ( fill.includes( 'url(#' )) {
            const fillId = this.fillUrlToId( fill );
            const type = this.getElementNameById( fillId );
            return { fill: fillId, type: FillType[ type ] };
        }
        return { fill: fill, type: FillType.solid };
    }

    /**
     * Returns the element name which has the given id string
     * @param id id string
     * @returns element name
     */
    private getElementNameById( id: string ): string {
        return this.getElementById( id ).nodeName;
    }

    /**
     * Return Element queried by given id
     * @param id id string
     */
    private getElementById( id: string ): Element {
        return this.svgom.querySelector( `[id="${id}"]` );
    }

    /**
     * Returns id referenced on the given fill url
     * @param fillUrl
     * @returns exctracted fill id from fill url
     */
    private fillUrlToId( fillUrl: string ): string {
        const a = fillUrl.split( '#' );
        return a[1].split( ')' )[0];
    }

    /**
     * Calculate the matrix for given svg transformation string and merge it with given parent matrix.
     * @param transforms svg transform string
     * @param parentMatrix parent Matrix
     */
    private calculateTransformationMatrix( transforms: string, parentMatrix?: Matrix ): Matrix {
        const currentMatrix = SvgTransformationToMatrix.getMatrix( transforms );
        if ( !parentMatrix ) {
            return currentMatrix;
        } else {
            return <Matrix>parentMatrix.clone().appendMatrix( currentMatrix );
        }
    }

    /**
     * Convert the types on current shape node to number types based on shape node interface requirements
     * and return the converted node.
     * @param node
     */
    private ConvertToNumberType( node: IShapeNode | IStructuralNode ): IShapeNode | IStructuralNode {
        if ( node.fillOpacity ) {
            node.fillOpacity = node.fillOpacity * 1;
        }
        if ( node.opacity ) {
            node.opacity = node.opacity * 1;
        }
        if ( node.strokeDasharray ) {
            const dashArray = [];
            const stringNumberArray = node.strokeDasharray
                .toString()
                .replace( /[,\s]+|[\s,]+|[\s]+/g, ',' )
                .split( ',' );
            stringNumberArray.forEach( number => {
                if ( number !== 'none' ) {
                    dashArray.push( +number );
                }
            });
            node.strokeDasharray = dashArray;
        }
        if ( node.strokeDashoffset ) {
            node.strokeDashoffset = node.strokeDashoffset * 1;
        }
        if ( node.strokeMiterlimit ) {
            node.strokeMiterlimit = node.strokeMiterlimit * 1;
        }
        if ( node.strokeOpacity ) {
            node.strokeOpacity = node.strokeOpacity * 1;
        }
        if ( node.strokeWidth ) {
            node.strokeWidth = node.strokeWidth * 1;
        }
        return node;
    }

    /**
     * Return the id attribute value from given attribute array.
     * If id is not found, null is returned.
     * @param attributes
     */
    private getElementId( attributes: IAttribute[]): string {
        const idAttr = attributes.find( attr => attr.type === 'id' );
        return idAttr ? idAttr.value : null;
    }
}
