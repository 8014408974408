import { IPoint, IPoint2D } from 'flux-definition';
import { AbstractConnector } from './connector-abstract';
import { IConnectorPoint, IConnectorBump, IConnectorEndPoint } from '../../../../shape/model/connector-data-mdl';

/**
 * This class contains the capability to draw an arrow connector
 * based on the smooth angled connector and using the
 * coordinate data stored into the model.
 *
 * Arrow connectors are drawn similar to angled connectors but
 * the turns are smoothed out and there is a gap between shapes
 * See ConnectorAngled for more info.
 *
 */
export class ConnectorIndented extends AbstractConnector {
    /**
     * The curve radius.
     */
    public radius = 10;

    /**
     * last point of connector
     */
    public lastPoint: boolean = false;

    /**
     * Constructor
     */
    public constructor() {
        super()/* istanbul ignore next */;
    }

    /**
     * Draws the arrow connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        let head = this.geometry.createPoint( points[0].x, points[0].y );
        const cd = ( points[0] as IConnectorEndPoint ).direction;
        head = this.drawTail( head, cd );
        // connect all other points except the last
        for ( let i = 1; i < points.length; ++i ) {
            const { x, y, c1, bumps } = points[i];
            const direction = ( points[i] as IConnectorEndPoint ).direction || 0;
            const point = this.geometry.createPoint( x, y );
            if ( i === points.length - 1 ) {
                this.lastPoint = true;
            } else {
                this.lastPoint = false;
            }
            if ( !c1 ) {
                head = this.drawLine( head, point, direction, bumps && bumps[0]);
                continue;
            }
            const ctrl = this.geometry.createPoint( c1.x, c1.y );
            // NOTE: if the next segment has a bump too close to starting point
            //       point should be replaced with the point where the bump ends.
            //       On all other cases, end the segment on given point.
            const next = this.nextBumpStart( point, points[ i + 1 ]) || point;
            head = this.drawAngleSegment( head, ctrl, next, direction, bumps );
        }
    }

    /**
     * Draw space at the start of the connector.
     * @param head
     * @param point
     */
    public drawTail( head: IPoint2D, direction: number ) {
        if ( direction === 0 ) {
            this.moveTo({ x: head.x - 5, y: head.y });
            return this.geometry.createPoint( head.x - 5, head.y );
        } else if ( direction === 90 ) {
            this.moveTo({ x: head.x, y: head.y - 5 });
            return this.geometry.createPoint( head.x, head.y - 5 );
        } else if ( direction === 180 ) {
            this.moveTo({ x: head.x + 5, y: head.y });
            return this.geometry.createPoint( head.x + 5, head.y );
        } else {
            this.moveTo({ x: head.x, y: head.y + 5 });
            return this.geometry.createPoint( head.x, head.y + 5 );
        }
    }

    public drawLine( head, point, direction, bump? ) {
        if ( this.lastPoint ) {
            if ( direction === 0 ) {
                return this.lineTo({ x: point.x - 5, y: point.y }, bump && bump );
            } else if ( direction === 90 ) {
                return this.lineTo({ x: point.x, y: point.y - 5 }, bump && bump );
            } else if ( direction === 180 ) {
                return this.lineTo({ x: point.x + 5, y: point.y }, bump && bump );
            } else {
                return this.lineTo({ x: point.x, y: point.y + 5 }, bump && bump );
            }

        } else {
            return this.lineTo( point, bump && bump );
        }
    }

    /**
     * Returns the point where the next segment first bump starts
     * only when it has to start before the line starting point.
     * This is possible only because angled connector path points are
     * always in the middle of the line part of the path.
     */
    private nextBumpStart( head: IPoint, next: IConnectorPoint ): IPoint {
        if ( !next || !next.bumps || !next.bumps[0] || !next.bumps[0].length ) {
            return null;
        }
        const bump = next.bumps[0][0];
        const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
        if ( head.distanceTo( bump ) > radius ) {
            return null;
        }
        const line = this.geometry.createLine( head, bump );
        return line.splitByLength( radius, false );
    }

    /**
     * Draws a smooth angle connector segment.
     */
    private drawAngleSegment(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        direction: number,
        bumps?: IPoint2D[][],
    ): IPoint {
        const prevToCtrl = head.distanceTo( ctrl );
        const ctrlToNext = ctrl.distanceTo( next );
        if ( prevToCtrl >= this.radius && ctrlToNext >= this.radius ) {
            head = this.drawAngleSegmentNormal( head, ctrl, next, direction, bumps, prevToCtrl, ctrlToNext );
        } else if ( prevToCtrl >= this.radius ) {
            head = this.drawAngleSegmentCtrlNearEnd( head, ctrl, next, direction, bumps, prevToCtrl, ctrlToNext );
        } else if ( ctrlToNext >= this.radius ) {
            head = this.drawAngleSegmentCtrlNearStart( head, ctrl, next, direction, bumps, prevToCtrl, ctrlToNext );
        } else {
            head = this.drawAngleSegmentCtrlNearStartAndEnd(
                    head, ctrl, next, bumps, prevToCtrl, ctrlToNext );
        }
        return next;
    }

    /**
     * Case 1: This is the normal case with enough space to draw the curve.
     * Draw a line to curve start point, then the curve and finally another
     * line from curve end point to the next point.
     */
    private drawAngleSegmentNormal(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        direction: number,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const lineEnd = this.geometry.createLine( ctrl, head )
            .split( this.radius / prevToCtrl );
        const ctrlNext = this.geometry.createLine( ctrl, next );
        const curveEnd = ctrlNext.split( this.radius / ctrlToNext );
        head = this.lineTo( lineEnd, bumps && bumps[0]);
        if ( !bumps || !bumps[1] || !bumps[1].length ) {
            head = this.quadraticCurveTo( ctrl, curveEnd );
            head = this.drawLine( head, next, direction );
        } else {
            const bump: IConnectorBump = bumps[1][0];
            const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
            const distCurveEnd = ctrl.distanceTo( curveEnd );
            const distBumpStart = ctrl.distanceTo( bump ) - radius;
            if ( distCurveEnd > distBumpStart ) {
                const bumpStart = ctrlNext.splitByLength( distBumpStart, true );
                head = this.quadraticCurveTo( ctrl, bumpStart );
                head = this.drawLine( head, next, direction, bumps && bumps[1]);
            } else {
                head = this.quadraticCurveTo( ctrl, curveEnd );
                head = this.drawLine( head, next, direction, bumps && bumps[1]);
            }
        }
        return head;
    }

    /**
     * Case 2: The distance between the control point to the end point is
     * less than the radius. Draw a line to the curve starting point and
     * draw the curve to the next point. The control point also has to
     * change to make the curve smoother.
     */
    private drawAngleSegmentCtrlNearEnd(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        direction: number,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const lineEnd = this.geometry.createLine( ctrl, head )
            .split( this.radius / prevToCtrl );
        const newCtrl = this.geometry.createLine( ctrl, lineEnd )
            .split(( this.radius - ctrlToNext ) / this.radius );
        head = this.drawLine( head, lineEnd, direction, bumps && bumps[0]);
        head = this.quadraticCurveTo( newCtrl, next );
        return head;
    }

    /**
     * Case 3: The distance between the control point and the starting point
     * is less than the radius. Draw the curve first and then draw a line to
     * the end point. The control point of the curve has to change to make
     * the curve smoother.
     */
    private drawAngleSegmentCtrlNearStart(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        direction: number,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        const ctrlNext = this.geometry.createLine( ctrl, next );
        const curveEnd = ctrlNext.split( this.radius / ctrlToNext );
        const newCtrl = this.geometry.createLine( ctrl, curveEnd )
            .split(( this.radius - prevToCtrl ) / this.radius );
        if ( !bumps || !bumps[1] || !bumps[1].length ) {
            head = this.quadraticCurveTo( newCtrl, curveEnd );
            head = this.drawLine( head, next, direction );
        } else {
            const bump: IConnectorBump = bumps[1][0];
            const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
            const distCurveEnd = ctrl.distanceTo( curveEnd );
            const distBumpStart = ctrl.distanceTo( bump ) - radius;
            if ( distCurveEnd > distBumpStart ) {
                const bumpStart = ctrlNext.splitByLength( distBumpStart, true );
                head = this.quadraticCurveTo( newCtrl, bumpStart );
                head = this.drawLine( head, next, direction, bumps && bumps[1]);
            } else {
                head = this.quadraticCurveTo( newCtrl, curveEnd );
                head = this.drawLine( head, next, direction, bumps && bumps[1]);
            }
        }
        return head;
    }

    /**
     * Case 4: The distance between the control point and the starting point
     * and also the distance between the control point and the end point are
     * less than the radius. Draw a curve from start point to the end point.
     * TODO: Calculate a better control point to make the curve smoother.
     */
    private drawAngleSegmentCtrlNearStartAndEnd(
        head: IPoint,
        ctrl: IPoint,
        next: IPoint,
        bumps: IPoint2D[][] | undefined,
        prevToCtrl: number,
        ctrlToNext: number,
    ): IPoint {
        head = this.quadraticCurveTo( ctrl, next );
        return head;
    }
}

Object.defineProperty( ConnectorIndented, 'name', {
    value: 'ConnectorIndented',
});
