import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, CommandResultError } from 'flux-core';
import { ResourceModelStore } from '../../storage/resource-model.store';
import { DataStore } from 'flux-store';
import { EMPTY, Observable, concat, defer } from 'rxjs';

/**
 * This is the command to add group share to diagram.
 * This command will add the pending group shares to the local storage and send
 * the request to the server.
 *
 * This extends {@link AbstractMessageCommand} to send a request to the server.
 * data: {
 *     groupShares - [{ id, role }]
 * }
 */
@Injectable()
@Command()
export abstract class AddResourceGroupShare extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            groupShare: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.data.groupShare.forEach( groupShare => {
            groupShare.addedStatus = 'pending';
        });
    }

    /**
     * execute
     * execute add group shares to diagram model
     */
    public execute(): Observable<any> {
        const modelStore = this.getModelStore();
        return modelStore.storageMergeGroupShares( this.resourceId, this.data.groupShare );
    }

    /**
     * Start the user subscription
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.getModelStore();
        const groupShares = response.groupShare;
        if ( groupShares.length > 0 ) {
            return concat(
                defer(() => modelStore.storageMergeGroupShares(
                    this.resourceId, groupShares.filter( groupShare => groupShare !== null ))),
                defer(() => this.removePendingGroupShares()),
            );
        }
        return EMPTY;
    }

    public revert(): Observable < any > {
        return this.removePendingGroupShares();
    }

    /**
     * Checks if a permission error occured and throws a permission error.
     */
    public onError( err: CommandResultError ) {
        if ( err.code ) {
            throw err;
        }
    }

    /**
     * Removes group shares added in the execute step (if there are any)
     */
    protected removePendingGroupShares(): Observable < any > {
        const modelStore = this.getModelStore();
        const filterFn = groupShare => groupShare.addedStatus === 'pending';
        return modelStore.storageFilterGroupShare( this.resourceId, filterFn );
    }

    protected abstract getModelStore(): ResourceModelStore;
}
