import { Observable, empty } from 'rxjs';
import { ExecutionStep } from '../command/execution/execution-step';
import { CommandInterfaces } from '../command/abstract.cmd';

import { each } from 'lodash';
import { SharedStateService } from './state-shared.svc';
import { StateService } from './state.svc';

/**
 * ReceiveStatesExecutionStep is a step that looks for states in the result data which were
 * essentially received from the server. Applicable if the command is a StateChangeCommand and
 * also a IMessageCommand. This will set the values received for multiple users excluding the
 * current user. All are handle the same way
 */
export class ReceiveStatesExecutionStep extends ExecutionStep {

    /**
     * relatedInterfaces property. only commands which have both of these
     * interfaces set will run this execution step.
     */
    public static get relatedInterfaces(): Array<Array<CommandInterfaces>> {
        return [[ 'IStateChangeCommand', 'IMessageCommand' ]];
    }
    /**
     * SharedStateService instance that is injected and used for
     * setting the state as per the command.
     */
    protected state: SharedStateService<any, any>;

    /**
     * The state service.
     * This only has the current users states.
     */
    protected stateService: StateService<any, any>;

    /**
     * process the received states and sets all received states to the
     * shared state service for each user. This will also remove states or all states
     * set to users if the values sent are null/undefined.
     * This does not update the values for current user.
     */
    public process(): Observable<any> {
        if ( !this.command.resultData || !this.command.resultData.states ) {
            this.log.warning( 'StateChangeCommand was received without any state data' );
            return empty();
        }
        const states = this.command.resultData.states;
        this.injectServices();

        each( states, ( userStates, userId ) => {
            if ( this.stateService.is( 'CurrentUser', userId )) {
                each( userStates, ( value, stateId ) => {
                    // Update the current user's state only if its different.
                    if ( this.stateService.isNot( stateId, value )) {
                        this.updateState( userId, stateId, value );
                    }
                });
                return;
            }
            if ( !userStates ) {
                // User id is set to a invalid value means delete the user.
                this.state.removeAll( userId );
                return empty();
            }
            each( userStates, ( value, stateId ) => {
                this.updateState( userId, stateId, value );
            });
        });

        return empty();
    }

    /**
     * injectServices injects services required by this execution step.
     */
    protected injectServices(): void {
        this.state = this.injector.get( SharedStateService );
        this.stateService = this.injector.get( StateService );
    }

    /**
     * Updates or removes the state based on the value. If the value is null or undefined
     * removes the state.
     */
    private updateState( userId, stateId, value ) {
        // The value for a state is not set means remove the state.
        if ( value === undefined || value === null ) {
            this.state.remove( stateId, userId );
        } else {
            this.state.set( stateId, userId, value );
        }
    }

}

Object.defineProperty( ReceiveStatesExecutionStep, 'name', {
    value: 'ReceiveStatesExecutionStep',
});
