import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

/**
 * A generic checkbox component that emit the value and the checked
 * status when the checkbox is clicked.
 */

@Component({
    selector: 'nu-check-box',
    templateUrl: './check-box.cmp.html',
    styleUrls: [ './check-box.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBox {
    /**
     * Label that appears next to the checkbox input.
     */
    @Input() public label: string;

    /**
     * Value it should emit when it is selected.
     */
    @Input() public value: string;

    /**
     * Control the check box status, whether it is checked or not.
     * This is useful if want to automatically check/unckeck the checkbox
     */
    @Input() public checked: boolean;

    /**
     * Determines the checkbox size.
     */
    @Input() public size: string;

    /**
     * Event that is emitted when the checkbox is toggled.
     */
    @Output() public valueChanged: EventEmitter<{value: string, checked: boolean}>;

    constructor() {
        this.valueChanged = new EventEmitter();
    }

    /**
     * Emits the checked status and value when the checkbox is clicked.
     */
    public checkboxToggle( event ) {
        this.valueChanged.next({
            value: event.target.value,
            checked: event.target.checked,
        });
    }
}
