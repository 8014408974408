import { INodeView } from './node-view.i';
import { nodeInputRule } from '@tiptap/core';

// tslint:disable:member-ordering
/**
 * ImageTiptapNodeView is a custom node view to embed resizable images
 * ( based on https://glitch.com/edit/#!/toothsome-shoemaker?path=index.js%3A6%3A19 )
 */
const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;
export class ImageTiptapNodeView implements INodeView {

    /**
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create() {
        const instance = new ImageTiptapNodeView();
        return instance;
    }

    public name = 'image';


    public subs = [];

    protected constructor() {
    }

    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public addOptions = () => ({
        inline: false,
        allowBase64: false,
        HTMLAttributes: {},
    })

    public inline = true;


    public group = 'inline';

    public draggable =  true;

    public addAttributes = () => ({
        src: {
            default: null,
        },
        alt: {
            default: null,
        },
        title: {
            default: null,
        },
        width: {
            default: '10em',
        },
    })

    public parseHTML = function ( this ) {
      return [
        {
            priority: 51, // must be higher than the default image spec
            tag: 'img[src][width]',
            getAttrs( dom ) {
              return {
                src: dom.getAttribute( 'src' ),
                title: dom.getAttribute( 'title' ),
                alt: dom.getAttribute( 'alt' ),
                width: dom.getAttribute( 'width' ),
              };
            },
        },
      ];
    };

    public renderHTML =  function ( this, { node, HTMLAttributes }) {
        const attrs = { style: `width: ${node.attrs.width}` };
        return [ 'img', { ...node.attrs, ...attrs }];
    };


    // public addCommands = function ( this ) {
    //   return {
    //     setImage: options => ({ commands }) =>
    //       commands.insertContent({
    //         type: this.name,
    //         attrs: options,
    //       })
    //     ,
    //   };
    // };


    public addNodeView = () =>
        ({
        editor,
        node,
        getPos,
        HTMLAttributes,
        decorations,
        extension,
        }) => {

            const outer = document.createElement( 'span' );
            outer.style.position = 'relative';
            node.attrs.width = typeof node.attrs.width === 'string'
              && node.attrs.width.includes( 'em' ) ? node.attrs.width : ( parseFloat( node.attrs.width ) / 16 ) + 'em';
            outer.style.width = node.attrs.width;
            // outer.style.border = "1px solid blue"
            outer.style.display = 'inline-block';
            // outer.style.paddingRight = "0.25em"
            outer.style.lineHeight = '0'; // necessary so the bottom right arrow is aligned nicely

            const img = document.createElement( 'img' );
            img.setAttribute( 'src', node.attrs.src );
            img.style.width = '100%';
            // img.style.border = "1px solid red"

            const handle = document.createElement( 'span' );
            handle.style.position = 'absolute';
            handle.style.bottom = '-5px';
            handle.style.right = '-5px';
            handle.style.width = '10px';
            handle.style.height = '10px';
            handle.style.border = '3px solid black';
            handle.style.borderTop = 'none';
            handle.style.borderLeft = 'none';
            handle.style.display = 'none';
            handle.style.cursor = 'nwse-resize';

            handle.onmousedown = e => {
              e.preventDefault();

              const startX = e.pageX;

              const fontSize = parseFloat( getComputedStyle( outer ).fontSize );

              const startWidth = parseFloat( node.attrs.width.match( /(.+)em/ )[1]);

              const onMouseMove = ( me: any ) => {
                const currentX = me.pageX;

                const diffInPx = currentX - startX;
                const diffInEm = diffInPx / fontSize;

                outer.style.width = `${startWidth + diffInEm}em`;
                node.attrs.width = `${startWidth + diffInEm}em`;
              };

              const onMouseUp = ue => {
                ue.preventDefault();

                document.removeEventListener( 'mousemove', onMouseMove );
                document.removeEventListener( 'mouseup', onMouseUp );
              };

              document.addEventListener( 'mousemove', onMouseMove );
              document.addEventListener( 'mouseup', onMouseUp );
            };
            outer.appendChild( handle );
            outer.appendChild( img );
            return {
                dom: outer,
                handle: handle,
                img: img,
                selectNode: () => {
                    img.classList.add( 'ProseMirror-selectednode' );
                    handle.style.display = '';
                },
                deselectNode: () => {
                    img.classList.remove( 'ProseMirror-selectednode' );
                    handle.style.display = 'none';
                },
            };

    }


    public addInputRules = function ( this ) {
      return [
        nodeInputRule({
          find: inputRegex,
          type: this.type,
          getAttributes: match => {
            const [ , , alt, src, title ] = match;
            return { src, alt, title };
          },
        }),
      ];
    };

}
