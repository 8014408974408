import { Component, Input, ElementRef } from '@angular/core';

/**
 * FloatingButton
 *
 * A button that can be floated to the left or right side of the screen,
 * usually used as an exit or back button.
 *
 * @author  thisun
 * @since   2017-05-30
 */

@Component({
    selector: 'floating-button',
    template : `<button class="fx-button fx-round fx-floating light-grey {{buttonPosition}}">
                    <i class="{{buttonIcon}}"></i>
                </button>`,
})

export class FloatingButton {
    @Input() buttonPosition: string;
    @Input() buttonIcon: string;

    /**
     * Element of this component
     */
    protected element: HTMLElement;

    constructor( e1: ElementRef ) {
        this.element = e1.nativeElement;
    }

    /**
     * This sets the button left, right property based on the
     * value given.
     */
    public updateButtonPostion( position: string, val: string ) {
        ( this.element.firstElementChild as HTMLElement ).style[ position ] = val;
    }

}
