import { MapOf } from 'flux-core';
import { IServices, ITextParser } from 'flux-definition';
import parsers from './text/parsers';
import { TextFormatter } from './text/text-formatter';

/**
 * This class provides services required for shape
 * @author mehdhi
 * @since 3/26/2019
 */
export class ShapeServices implements IServices {
    public static get instance(): ShapeServices {
        return this._instance;
    }

    /**
     * A constant getter for an instance of this class.
     */
    private static _instance = new ShapeServices();

    /**
     * This holds instance of TextFormatter
     */
    protected _textFormatter: TextFormatter;
    protected _parsers: MapOf<{instance: ITextParser}> = {};

    constructor() {
        this._textFormatter = new TextFormatter();
        parsers.forEach( parser => {
            this._parsers[parser.id] = parser;
        });
    }

    public get TextFormatter(): TextFormatter {
        return this._textFormatter;
    }

    public getTextParser( parserId: string ): ITextParser {
        if ( this._parsers[parserId]) {
            return this._parsers[parserId].instance;
        }
        return null;
    }

}
