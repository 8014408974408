import { ExecutionStep, StateService, CommandInterfaces } from 'flux-core';
import { CurrentDiagramStateService } from '../../../base/diagram/diagram-states';

/**
 * SetDiagramIdExecutionStep step sets the resource id for diagram
 * model change commands to the current diagram.
 */
export class SetDiagramIdExecutionStep extends ExecutionStep {
    /**
     * relatedInterfaces property returns both 'IDiagramCommand' so that
     * only commands which affect the diagram will trigger this execution step.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IDiagramCommand' ];
    }

    /**
     * Sets the resourceId of the command to the current diagram using
     * the current diagram state service if the resourceId is not set.
     */
    public process() {
        if ( !this.command.resourceId ) {
            const states = this.injector.get<CurrentDiagramStateService>( StateService );
            this.command.resourceId = states.get( 'CurrentDiagram' );
        }
    }
}

Object.defineProperty( SetDiagramIdExecutionStep, 'name', {
    value: 'SetDiagramIdExecutionStep',
});
