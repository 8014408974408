import { Directive, Input, OnInit } from '@angular/core';
import { ProjectRole } from './project-role.dir';
// import { EMPTY, Observable, combineLatest } from "rxjs";
// import { filter, map, switchMap } from "rxjs/operators";
// import { CollaboratorType, ProjectModel } from "flux-diagram/models";
import { TeamUserRole, TeamUserRoleUtil } from 'flux-user';

/**
 * Check if the user has necessory role permission on the Project, If not, it checks
 * weather the user has required team level permission such as ADMIN or SUADMIN.
 */
@Directive({
    selector: '[projectOrTeamRole]',
})
export class ProjectOrTeamRole extends ProjectRole implements OnInit {

    /**
     * Get roles and project id from the directive.
     * Any role from CollaboratorType enum is valid
     */
    @Input( 'projectOrTeamRole' )
    public projectData: { id: string, role: string, teamRole: string };

    /**
     * Checks the user has expected role permission to the project or admin of project's team.
     * @param project
     * @param user
     * @param requiredRole
     * @returns
     */
    protected hasRolePermission( project, user, requiredRole ): boolean {
        return super.hasRolePermission( project, user, requiredRole ) ||
            ( this.projectData.teamRole && project.teamId && user.team?.id &&
                TeamUserRoleUtil.hasPermission( TeamUserRole[ this.projectData.teamRole ], user.team.teamUserRole ));
    }
}
