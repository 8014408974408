<div class="notification-list-item-container user-img-show-indicator"
    (click)="openReferingResource()">
    <div class="notification-list-item-user-image">
        <user-image
            imageSize="small"
            [hasImage]="notification.actorInfo.hasImage"
            [imagePath]="notification.actorInfo.image"
            [userColor]="notification.actorInfo.getColor()"
            [userInitials]="notification.actorInfo.initial"
            [userRegistered]="true"
            [userOnlineStatus]="true">
        </user-image>
        <svg class="nu-icon nu-icon-med nu-ic-notification-action">
            <use [attr.xlink:href]="notification.actionSVG"></use>
        </svg>
    </div>
    <div class="notification-list-item-inner" [ngStyle]="{'cursor':canNavigateToResource() ? 'pointer' : 'default' }">
        <div class="notification-list-item-notification-text-row">
            <div class="body">{{actorName}} <span [innerHTML]="notification.translatedContentHTML | safeHTML"></span></div>
            <div class="notification-list-item-notification-time fx-ellipsis caption">{{notification.formatedTime}}</div>
        </div>
    </div>
</div>


