<div [id]="id" class="col-menu-item-container fx-margin-top-5 expanded">
    <div class="col-menu-item-heading fx-no-selection fx-margin-bottom-5">
        <div class="col-menu-item-heading-inner">
            <div class="form-check form-input-wrapper" >
                <input type="checkbox" class="form-check-input" [checked]="checked | async" (click)="handleSelection($event)" />
                <span class="form-custom-checkbox">
                    <img class="form-custom-checkbox-tick" src="./assets/images/decoratives/tick.svg" width="18">
                </span>
                <label class="form-check-label">
                    <div class="library-name">{{ title }}</div>
                </label>
            </div>
        </div>
    </div>

    <div class="col-menu-item-content library-panel-view">
        <div class="fx-invisible-item" #componentContainer></div>
    </div>
</div>