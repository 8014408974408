import { Injectable } from '@angular/core';
import { StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { values, find, orderBy } from 'lodash';
import { CollabModel } from '../../collab/model/collaborator.mdl';
import { DiagramInfoModel } from '../model/diagram-info.mdl';

/**
 * This ia a diagram info locator that is used specifically for proton.
 * It does not work based on storage - all data is stored in the state
 * service and the locator listens to state changes.
 * This is a temporary solution to fix some urgen performance issues.
 * This whole design neeeds to be re thought.
 *
 * @author  Ramishka
 * @since   2019-07-19
 */
@Injectable()
export class DiagramInfoLocator {

    constructor(
        protected dataStore: DataStore,
        protected stateService: StateService<any, any> ) {}

    /**
     * Retrieves a list of diagrams in a project.
     * Only diagrams accessible to the user are included.
     * The list is sorted by the last updated date (most recent diagrams at the front).
     * @param projectId - project id to fetch dor
     * @return observable which emits as diagram state changes
     */
    public getDiagrams( projectId: string ): Observable<Array<DiagramInfoModel>> {
        return this.stateService.changes( 'ProjectDiagrams' ).pipe(
            map( projectDiagrams => {
                let diags = projectDiagrams[ projectId ];
                diags = !!diags ? diags : [];
                return diags;
            }),
            switchMap( userDiags =>
                this.getUserDiagrams( userDiags,  this.stateService.get( 'CurrentUser' )).pipe(
                    map(( userDiagrams: any ) => {
                        const filtered = userDiagrams.filter( d => d.project === projectId );
                        return orderBy( filtered, [ 'lastUpdated' ], [ 'desc' ]);
                    }),
                )),
        );
    }

   /**
    * Retrieves a list of recently update diagrams.
    * The list is sorted by the last updated date (most recent diagrams at the front).
    * @param projectId - project id to fetch dor
    * @return observable which emits as diagram state changes
    */
    public getRecentDiagrams(): Observable<Array<DiagramInfoModel>> {
        return this.stateService.changes( 'RecentDiagrams' ).pipe(
            map( recentDiagrams => !!recentDiagrams ? recentDiagrams : []),
            switchMap(( diagrams: DiagramInfoModel[]) =>
                this.getUserDiagrams( diagrams,  this.stateService.get( 'CurrentUser' )).pipe(
                    map( userDiagrams => orderBy( userDiagrams,
                        [ ( item: DiagramInfoModel ) => {
                            const currCollab = item.collabs.find(
                                collab => collab.id === this.stateService.get( 'CurrentUser' ));
                            return currCollab ? currCollab.lastSeen : 0;
                        } ],
                        [ 'desc' ])),
                )),
        );
    }

    /**
     * Checks if a given projects diagrams are available in the state.
     * @param projectId - project to check for
     * @return true if at least 1 diagram exists
     */
    public hasProjectDiagrams( projectId: string ): boolean {
        const projectDiagrams = this.stateService.get( 'ProjectDiagrams' );
        return projectDiagrams &&
                projectDiagrams[projectId] &&
                ( values(  projectDiagrams[projectId]).length > 0 );
    }

    /**
     * Checks if recent diagrams are available in the state
     * @return true if at least 1 recent diagram is available.
     */
    public hasRecentDiagrams(): boolean {
        const recentDiagrams = this.stateService.get( 'RecentDiagrams' );
        return recentDiagrams && recentDiagrams.length > 0;
    }

    /**
     * Fetches diagram info for a given diagram.
     * Fetches data if the diagram is either available in the
     * project list or in the recent list.
     * Recent list is checked if diagram data is required before project
     * diagrams are loaded (there can be a case when only recent diagrams are loaded,
     * without fetching project diagrams).
     * @param diagramId - diagram to fetch
     * @return observable which emits the diagram once and completes
     */
    public getDiagram( diagramId: string ): Observable<DiagramInfoModel> {
        const projectDiagrams = this.stateService.get( 'ProjectDiagrams' );
        const projectDiagramsList = values( projectDiagrams );
        const recentList = this.stateService.get( 'RecentDiagrams' );
        let diagram;
        diagram = find( recentList, { id: diagramId });
        if ( !diagram ) {
            for ( let i = 0; i < projectDiagramsList.length; i++ ) {
                const diagramList = projectDiagramsList[i];
                diagram = find( diagramList, { id: diagramId });
                if ( diagram ) {
                    break;
                }
            }
        }
        return of( diagram );
    }

    /**
     * Filter and exctract diagrams belonging to a user, from a given set of diagrams.
     * @param allDiagrams Set of diagrams to be filtered
     * @param userId user id
     * @return Filtered array of diagrams belongs to the user
     */
    protected getUserDiagrams( allDiagrams: Array<DiagramInfoModel>, userId: string ): Observable<DiagramInfoModel[]> {
        // All the diagrams availabe in the state( cache ) belongs to current user. No need to filter again.
        return of( allDiagrams );
    }

    /**
     * Checks if a given user is a collaborator of the diagram
     * FIXME: Ideally this method should reside in the ProjectCollabLocator or
     * something like a PrivacyService. Moving to ProjectCollabLocator creates
     * a cyclic dependency between ProjectLocator and ProjectCollabLocator.
     * @param userId - id of the user to be checked
     * @param collabs - full collaborator list
     */
    protected isACollaborator( userId: string, collabs: CollabModel[]) {
        return ( collabs && collabs.filter( collab =>
            collab.id === userId ).length > 0 );
    }


}
