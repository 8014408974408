import { CommandError } from './command-error';
import { HttpRequest, HttpResponse } from '@angular/common/http';

/**
 * CommandHttpError is a CommandError which occurs when
 * executing an Http request.
 */
export class CommandHttpError extends CommandError {
    constructor( protected _request: HttpRequest<any>, protected _response: HttpResponse<any> ) {
        super( '' )/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, CommandHttpError.prototype );
        this.message = this.createErrorMessage();
    }

    /**
     * The @angular/common/http request object which is created when this error occurred.
     */
    public get request(): HttpRequest<any> {
        return this._request;
    }

    /**
     * The @angular/common/http response object which is created when this error occurred.
     */
    public get response(): HttpResponse<any> {
        return this._response;
    }

    /**
     * createErrorMessage creates the error message string using the request and response
     */
    protected createErrorMessage(): string {
        let message = `Failed to fetch "${this._request.url}".`;
        if ( this._response.status ) {
            message += ` Server returned status code "${this._response.status}" (${this._response.statusText})`;
        } else {
            message += ` Please make sure the url is valid and accessible.`;
        }
        return message;
    }
}
