import { DataStore } from 'flux-store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { ModelSubscriptionManager } from 'flux-subscription';
import { CreateProject } from './create-project.cmd';

@Injectable()
@Command()
export class CreateProjectTeam extends CreateProject {

    constructor( protected dataStore: DataStore,
                 protected modelSubManager: ModelSubscriptionManager,
                 protected state: StateService<any, any> ) {
        super( dataStore, modelSubManager, state )/* istanbul ignore next */;
    }

    /*istanbul ignore next */
    public executeResult( response: any ): Observable<any> {
        return super.executeResult( response );
    }
}

Object.defineProperty( CreateProjectTeam, 'name', {
  value: 'CreateProjectTeam',
});
