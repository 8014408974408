import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { findIndex } from 'lodash';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractIndexChangeCommand } from './abstract-index-change.cmd';

/**
 * This command sends a given shape or a set of shapes at the front of
 * another shape.
 * Shapes can either be passed in, or the command will operate on shapes
 * in the current selection
 *
 * @author  Ramishka
 * @since   2019-02-12
 */
@Injectable()
@Command()
export class BringForward extends AbstractIndexChangeCommand {

    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( state, ds ) /* istanbul ignore next */;
    }

    /**
     * Updates the zIndex of selected shapes so they are sent to the front of
     * another shape.
     * The shape to be sent backwards of is decided by the following algorithm:
     * - Find the topmost shape in the selection.
     * - Check if the topmost shape overlaps with any other shapes.
     * - If overlaps, send the selected shapes to the front of lowermost
     *   overlapping shapes.
     * - If it does not overlap, send the selected shapes to the front of the shape
     *   that highest closest zIndex to that of the topmost slected shape.
     * In the event of a multi selection, the zIndex order between the shapes are
     * maintained.
     */
    public prepareData() {
        const shapes = this.getShapeIds();
        const diagram = this.changeModel;
        if ( !diagram.shapes || Object.keys( diagram.shapes ).length <= 1 ) {
            return;
        }
        const bringForwardOf = this.getShapeToBringForwardOf( shapes );
        if ( bringForwardOf ) {
            const indexes = diagram.getIndexesToBringForward( bringForwardOf.id, shapes );
            indexes.forEach( a => {
                this.changeModel.shapes[ a.id ].zIndex = a.index;
            });
        }
    }

    /**
     * Finds the shape which other shapes will be send to the front of.
     * Considers the set of shapes being moved and the overlapping shapes
     * to make this decision.
     * @param shapes - shapes being moved
     * @return model of the shape that other shapes will be sent to the front of
     */
    private getShapeToBringForwardOf( shapes: string[]): AbstractShapeModel {
        let bringForwardOf;
        const diagram = this.changeModel;
        const selectedShapes = diagram.getShapesOrderedByIndex( shapes );
        const topSelected = selectedShapes[selectedShapes.length - 1];
        const overlaps = diagram.getOverlappingShapes( topSelected.id )
            .filter( shape => shape.zIndex >= topSelected.zIndex );
        if ( overlaps.length > 1 ) {
            const shapePos = findIndex( overlaps, s => s.zIndex === topSelected.zIndex );
            bringForwardOf = overlaps[shapePos + 1];
        } else {
            bringForwardOf = diagram.getShapeAtFront( topSelected.id );
        }
        return bringForwardOf;
    }
}

Object.defineProperty( BringForward, 'name', {
    value: 'BringForward',
});
