import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * SwapShape
 * This command updates a given set of data items on a given set of shapes.
 * If the shape ids are not given, change will be applied to the currently selected shapes.
 */
@Injectable()
@Command()
export class SwapShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds?: Array<string>,
        newDefId: string,
        newVersion: number,
    };


    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
        this.state = state;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        if ( !this.data.shapeIds ) {
            const shapeIds: string[] = this.state.get( 'Selected' );
            this.data.shapeIds = shapeIds;
        }
        const shape = this.changeModel.shapes[this.data.shapeIds[0]] as ShapeModel;
        if ( shape.vectors ) { // remove vectors
                shape.vectors = undefined;
        }
        shape.defId =  this.data.newDefId;
        shape.version = this.data.newVersion;
    }
}

Object.defineProperty( SwapShape, 'name', {
    value: 'SwapShape',
});
