/**
 * Declare the __CreatelyContainerInfo__ variable globally (typescript).
 */
declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        __CreatelyContainerInfo__: {
            currentUserId?: string,
            pageId?: string,
            exitUrl?: string,
        };
    }
}

/**
 * Stores information related to the container where the app is loaded.
 * This is different `ContainerEnv` which is about the user's browser.
 * Example: these info can be included when sending messages to the server.
 */
export class ContainerInfo {
    public static get(): typeof window.__CreatelyContainerInfo__ {
        return window.__CreatelyContainerInfo__ || {};
    }
}
