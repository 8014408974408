import { enGB, de, es, fr, ja, ko, pt, ru, zhCN } from 'date-fns/locale';
import {
    format, formatRelative, formatDistance, isSameDay,
    isPast, addSeconds, differenceInDays, addDays,
} from 'date-fns';
import { Logger } from '../logger/logger.svc';
import { merge } from 'lodash';

/**
 * This is a wrapper that wraps around functionalities of DateFNS library.
 * The way DateFNS works, it is required to pass the current locale for some of its
 * formatter functions to work. This class wraps around the functionality to pass
 * in the current locale and other defeault options we need into these functions.
 *
 * NOTE: When a new language is added, it is required to add the newly added language
 * to the locales map (all such locales are individually imported for tree shaking to work).
 * If this is not done, the date time translations in non English languages will fall back
 * to English.
 *
 * @author  Ramishka
 * @since   2019-08-23
 */
export class DateFNS {

    /**
     * Sets the current locale of the app
     * @param locale - new locale string, as returned by device info
     */
    public static setLocale( locale: string ) {
        this.currentLocale = this.locales[ locale ];
    }

    /**
     * Return the formatted date string in the given format.
     * Format of the string is based on Unicode Technical Standard #35:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     * with a few additions.
     * Examples: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - date to be formatted
     * @param dateFormat - the format string
     * @param options - options
     */
    public static format( date: Date | number, dateFormat: string, options?: {
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
        locale?: any
        firstWeekContainsDate?: number,
        useAdditionalWeekYearTokens?: boolean,
        useAdditionalDayOfYearTokens?: boolean,
    }): string {
        try {
            options = this.getOptions( options );
            return format( date, dateFormat, options );
        } catch ( error ) {
            Logger.error( 'Error formatting date at DateFNS.format.' );
            return '';
        }
    }

    /**
     * Represent the date in words in relation to the given base date.
     * i.e. 'last Friday at 7:26 p.m.'
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - the date you want the relative value to
     * @param baseDate - date to calculate relative value from
     * @param options - options for the method
     * @return the date in words
     */
    public static formatRelative( date: Date | number, baseDate: number | Date, options?: {
        locale?: Locale,
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    }): string {
        try {
            options = this.getOptions( options );
            return formatRelative( date, baseDate, options );
        } catch ( error ) {
            Logger.error( 'Error formatting date at DateFNS.formatRelative.' );
            return '';
        }
    }

    /**
     * Returns the distance between two dates.
     * i.e. '3 days ago'
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - the date you want the distance to
     * @param baseDate - the date you want the distance from
     * @param options - options
     * @return - the distance in words
     */
    public static formatDistance( date: Date | number, baseDate: number | Date, options?: {
        includeSeconds?: boolean,
        addSuffix?: boolean,
        locale?: Locale,
    }): string {
        try {
            options = this.getOptions( options );
            return formatDistance( date, baseDate, options );
        } catch ( error ) {
            Logger.error( 'Error formatting distance at DateFNS.formatDistance.' );
            return '';
        }
    }

    /**
     * Returns the difference between two dates.
     * i.e. '3 days ago'
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - the date you want the distance to
     * @param baseDate - the date you want the distance from
     * @param options - options
     * @return - the distance in words
     */
    public static differenceInDays( date: Date | number, baseDate: number | Date ): number {
        try {
            return differenceInDays( date, baseDate );
        } catch ( error ) /* istanbul ignore next */ {
            Logger.error( 'Error formatting distance at DateFNS.formatDistance.' );
            return 0;
        }
    }

    /**
     * Returns true if the dates are the same.
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - the start date
     * @param baseDate - the end date you want to compare with
     * @return - true/false
     */
    public static isSameDay( date: Date | number, baseDate: number | Date ): boolean {
        return isSameDay( date, baseDate );
    }

    /**
     * Returns true if the date is in the past compare to current date.
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - the comparing date
     * @return - true/false
     */
    public static isPast( date: Date | number ): boolean {
        return isPast( date );
    }

    /**
     * Add the specified number of seconds to the given date.
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - The date you want to add the seconds to.
     * @return - new date with added seconds.
     */
    public static addSeconds( date: Date | number, seconds: number ): Date {
        return addSeconds( date, seconds );
    }

    /**
     * Add the specified number of days to the given date.
     * More details: https://date-fns.org/docs/Getting-Started
     * @param date - The date you want to add the dates to.
     * @return - new date with added days.
     */
    public static addDays( date: Date | number, seconds: number ): Date {
        return addDays( date, seconds );
    }
    // TODO - add ar back after angular 8 update complete


    /**
     * Holds the current locale of the app
     */
   protected static currentLocale: any;

   /**
    * A map which contains all supported localizations
    */
   protected static locales = { en: enGB, de, es, fr, ja, ko, pt, ru, zh: zhCN };

    /**
     * Adds the default options to a given DateFNS options object.
     * If options is not defined, it will be created.
     * Fields that are already set will not be overridden.
     * @param options - options object with localization params added
     */
    protected static getOptions( options: any ): any {
        const defaultOptions = {
            locale: this.currentLocale,
            addSuffix: true,
        };
        return merge ({}, defaultOptions, options );
    }
}
