import { Injectable } from '@angular/core';
import { Changes, Proxied, Sakota } from '@creately/sakota';
import { CommandScenario, CommandService, EventSource, Logger, MapOf, Random, StateService } from 'flux-core';
import { DataType, DEFUALT_TEXT_STYLES, EntityLinkType, IDataItem, IEDataDef, IEntityDef, IShapeOrigins } from 'flux-definition';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { merge as _merge, clone, find, set, uniq, cloneDeep } from 'lodash';
import { EMPTY, Observable, forkJoin, from, merge, of } from 'rxjs';
import { filter, last, map, mapTo, shareReplay, switchMap, switchMapTo, take, tap, toArray } from 'rxjs/operators';
import { BaseDiagramCommandEvent } from '../../../base/diagram/command/base-diagram-command-event';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { EDataCommandEvent } from '../../../base/edata/command/edata-command-event';
import { EntityLinkService } from '../../../base/edata/entity-link.svc';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { EntityListModel } from '../../../base/edata/model/entity-list.mdl';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
import { ExpressionEvaluator } from '../../../base/formula/expression-evaluator.svc';
import { SlideModel } from '../../../base/presentation/model/slide.mdl';
import { PresentationLocator } from '../../../base/presentation/presentation-locator.svc';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { ProjectDiagramCommandEvent } from '../../../creator/project/command/project-diagram-command.event';
import { IChangeBinder } from '../../../framework/diagram/bindings/change-binder.i';
import { EDataRegistry } from './../../../base/edata/edata-registry.svc';
import { EDataLocatorLocator } from './../../../base/edata/locator/edata-locator-locator';
import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';

/**
 * ShapeAddedBinder
 *
 * The ShapeAddedBinder can be used to do anything to the changeModel
 * when a shape is added to the canvas by any means.
 */
@Injectable()
export class ShapeAddedBinder implements IChangeBinder {

    public static initEntity( entity: EntityModel, entityDef: IEntityDef ) {
        if ( !entity.data ) {
            entity.data = {};
        }
        if ( !entity.refData ) {
            entity.refData = {};
        }
        Object.keys( entityDef.dataItems ).forEach( dataItemId => {
            const dataItem = entityDef.dataItems[ dataItemId ];
            if ( dataItem.type === DataType.LOOKUP ) {
                entity.data[ dataItemId ] = [];
            } else {
                entity.data[ dataItemId ] = dataItem.value ? dataItem.value : dataItem.default;
            }
            if ( dataItem.type === DataType.FORMULA ) {
                const parsedExpression = ( dataItem as any ).options.parsedExpression;
                const dataRegex = /data\['([a-zA-Z0-9]+)'\]/g;
                const refDataRegex = /refData\['([a-zA-Z0-9]+)'\]\['([a-zA-Z0-9]+)'\]/g;
                let match: RegExpExecArray | null;
                let hasDeps = false;

                while (( match = dataRegex.exec( parsedExpression )) !== null ) {
                    hasDeps = true;
                    entity.addRefField( match[1], dataItemId );
                }

                while (( match = refDataRegex.exec( parsedExpression )) !== null ) {
                    hasDeps = true;
                    const lookupId = match[1];
                    const propId = match[2];
                    if ( !entity.refData[lookupId]) {
                        entity.refData[lookupId] = {};
                    }
                    if ( !entity.refData[lookupId][propId]) {
                        entity.refData[lookupId][propId] = {
                            value: [],
                            refFields: [],
                        };
                    }
                    if ( entity.refData[lookupId][propId].refFields.indexOf( dataItemId ) === -1 ) {
                        entity.refData[lookupId][propId].refFields.push( dataItemId );
                    }
                }
                if ( !hasDeps ) {
                    entity.data[ dataItemId ] = ExpressionEvaluator.evaluate( parsedExpression );
                }
            }
        });
    }


    /**
     * The diagram change model
     * This change model is to be updated by this binder
     */
    protected changeModel;

    /**
     * Here we Cache the EdataModel with eDataDefId because we need to
     * reuse if some other also needed that same model.
     */
    protected eDataMemo = new Map();

    protected eDataModelCache = new Map();

    private currentEDataModels: Observable<EDataModel[]> = null;

    constructor(
        protected mcu: ModelChangeUtils,
        protected state: StateService<any, any >,
        protected eDataRegistry: EDataRegistry,
        protected commandService: CommandService,
        protected ell: EDataLocatorLocator,
        protected subManager: ModelSubscriptionManager,
        protected defLocator: DefinitionLocator,
        protected pl: PresentationLocator,
    ) {
    }

    /**
     * Unique name to identify the binder.
     */
    public get name(): string {
        return 'ShapeAddedBinder';
    }

    public get paths(): string[] {
        return [];
    }

    /**
     * This hook will be called by the change binding service.
     */
    public apply( diagram: Proxied<DiagramModel>, cs: CommandScenario, ctx: any ): Observable<any> {
        if ( cs !== CommandScenario.EXECUTE ) {
            return EMPTY;
        }
        this.changeModel = diagram;
        const changes = diagram.__sakota__.getChanges();
        if ( !changes.$set ) {
            return;
        }
        return this.shapeAdded( diagram, changes ).pipe(
            filter ( s => s ),
            toArray(),
            tap(( edataModels: Proxied<EDataModel>[]) => {
                const mergeEdataChanges = {};
                for ( const edata of edataModels ) {
                    const edataId  = edata.id;
                    if ( !mergeEdataChanges[edataId]) {
                        mergeEdataChanges[edataId] = {};
                    }
                    const modifier = edata.__sakota__.getChanges();
                    _merge( mergeEdataChanges[edataId], modifier );
                    edata.__sakota__.reset();
                }
                this.commitEdataChanges( mergeEdataChanges, ctx );
            }),
        );
    }

    protected commitEdataChanges( mergeEdataChanges: any, ctx ) {
        Object.keys( mergeEdataChanges ).forEach( edataId => {
            if ( mergeEdataChanges[edataId]) {
                this.subManager.getFutureSub( edataId ).pipe(
                    switchMap( sub => sub.status ),
                    filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
                    take( 1 ),
                ).subscribe(() => {
                    if ( ctx.eventData.source === EventSource.EXTERNAL ) {
                        this.commandService.dispatch( EDataCommandEvent.applyModifierEDataExternal, edataId,
                            { modifier: mergeEdataChanges[edataId], origin: 'ShapeAddedBinder' });
                    } else {
                        this.commandService.dispatch( EDataCommandEvent.applyModifierEData, edataId,
                            { modifier: mergeEdataChanges[edataId], origin: 'ShapeAddedBinder' });
                    }
                });
            }
        });

    }

    /**
     * Updates edata relarted properties of the diagram model and shape model
     * and dispatch external commands.
     */
    protected updateEdata( item ): Observable<EDataModel> {
        if ( item.triggerNewEData ) {
            // this already has EData and consider as a new shape added.
            if ( item.eData ) { //
                const keys = Object.keys( item.eData );
                if ( keys.length === 1 ) {
                    const eDataId = keys[0];
                    const entityId = item.eData[eDataId];
                    let obs = this.getEDataChangeModel( eDataId ).pipe(
                        map(  eDataChangeModel => {
                            if ( eDataChangeModel ) {
                                let entity;
                                if ( !entityId ) {
                                    entity = this.addEntityToEData( eDataChangeModel, item );
                                } else {
                                    entity = eDataChangeModel.entities[ entityId ];
                                }
                                if ( item.origin === IShapeOrigins.PRE_DEFINED_QUERIES ) {
                                    this.createEntityList( eDataChangeModel, item, entity );
                                    this.addEntityToEntityList( eDataChangeModel, item, entity, this.changeModel.id );
                                }
                                this.mcu.addEntityToShape( this.changeModel, {
                                    shapeId: item.id,
                                    eDataId: eDataChangeModel.id,
                                    entityId: entity.id,
                                    entityDefId: entity.eDefId,
                                    eDataDefId: eDataChangeModel.defId,
                                    data: eDataChangeModel.getEntityDataItems( entity.id, true, true ),
                                    taskMap: entity.taskMap,
                                });
                                this.addShapeToEntity( this.changeModel.id, entity.id, item, eDataChangeModel );
                                return eDataChangeModel;
                            }
                            return null;
                        },
                    ));
                    if ( !entityId ) {
                        return obs.pipe(
                            switchMap( eDataModel => this.createEntityLinks( eDataModel, item )),
                            switchMap( eDataModels => {
                                const entId = item.eData[ eDataId ];
                                const connectorIds = EntityLinkService.createLinkedConnectors(
                                    this.changeModel,
                                    item,
                                    eDataModels[eDataId].entities[entId],
                                    eDataModels,
                                    item.zIndex,
                                    {
                                        defaultBounds: item.defaultBounds,
                                    },
                                );
                                item.connectorIds = connectorIds;
                                return this.updateLinkConnectors( eDataModels[eDataId], entId, item, eDataModels ).pipe(
                                    switchMapTo( from( Object.values( eDataModels ))),
                                );
                            }),
                            // mergeMap( eDataModels => from( Object.values( eDataModels ))),
                        );
                    } else if ( item.definedSearchQuery ) {
                        obs = obs.pipe(
                            tap( eDataModel => {
                                const entity = eDataModel.entities[ entityId ];
                                if ( entity?.links ) {
                                    const connectorIds = EntityLinkService.createLinkedConnectors(
                                        this.changeModel,
                                        item,
                                        entity,
                                        {
                                            ...this.state.get( 'projectEDataModels' ),
                                            [eDataId]: eDataModel,
                                        },
                                        item.zIndex,
                                        {
                                            defaultBounds: item.defaultBounds,
                                        },
                                        item.$$createLinkOptions || item.smartSetOptions?.createLinkConfig || {},
                                    );
                                    item.connectorIds = connectorIds;
                                }
                            }),
                        );
                    }
                    return obs.pipe(
                        switchMap( eDataModel => this.updateLinkConnectors( eDataModel, entityId, item )),
                    );
                } else {
                    Logger.error( 'Invalid eData config passed on drop' );
                }
            } else {
                // see if we have a EDataDef that matches the shapes request
                const entityDef  = this.eDataRegistry.searchEntityDef ([ `${item.defId}` ]);
                if ( entityDef ) {
                    const eDataDef = this.eDataRegistry.getEDataDef( entityDef.id );
                    if ( eDataDef ) {
                        return this.getEDataModelByDefId( eDataDef ).pipe(
                            map( mdl => {
                                if ( mdl ) {
                                    const changeMdl =  Sakota.create( mdl );
                                    const entity = this.addEntityToEData( changeMdl, item );
                                    if ( item.origin === IShapeOrigins.PRE_DEFINED_QUERIES ) {
                                        this.createEntityList( changeMdl, item, entity );
                                        this.addEntityToEntityList( changeMdl, item, entity, this.changeModel.id );
                                    }
                                    this.mcu.addEntityToShape( this.changeModel, {
                                        shapeId: item.id,
                                        eDataId: mdl.id,
                                        entityId: entity.id,
                                        entityDefId: entity.eDefId,
                                        eDataDefId: eDataDef.defId,
                                    });
                                    this.eDataMemo.set( entityDef.id, changeMdl );
                                    return changeMdl;
                                }
                                return null;
                            }),
                        );
                    } else {
                        throw Error(
                            `Definition ${entityDef.id} is missing and cannot start for shape` );
                    }
                }
            }
        } else if ( item.eDataCandidates && item.eDataCandidates.length > 0 ) {
            return this.ell.currentDiagramEDataModels().pipe(
                take( 1 ),
                map( eDataModels => {
                    const eDataDefIds = item.eDataCandidates;
                    const mdl = eDataModels.find( e => eDataDefIds.includes( e.defId ));
                    if ( mdl ) {
                        const changeMdl =  Sakota.create( mdl );
                        const entity = this.addEntityToEData( changeMdl, item );
                        this.mcu.addEntityToShape( this.changeModel, {
                            shapeId: item.id,
                            eDataId: mdl.id,
                            entityId: entity.id,
                            entityDefId: entity.eDefId,
                            eDataDefId: mdl.defId,
                        });
                        this.eDataMemo.set( mdl.defId, changeMdl );
                        return changeMdl;
                    }
                    return null;
                }),
            );
        }
    }

    protected updateLinkConnectors( eDataModel: EDataModel, entityId: string, item, changeModels?: MapOf<EDataModel> ) {
        return EntityLinkService.updateLinkConnectors(
            eDataModel,
            this.changeModel,
            changeModels ? eId => of( changeModels[eId]) : this.getEDataChangeModel.bind( this ),
            entityId,
            item,
        );
    }

    protected createEntityLinks( eDataModel: Proxied<EDataModel>, item ) {
        const entityId = item.eData[eDataModel.id];
        const entity = eDataModel.entities[entityId];
        const dataItems: MapOf<IDataItem<DataType>> = eDataModel.getEntityDataItems( entityId );
        const dItems = Object.values( dataItems ).filter( dataItem =>
            dataItem.type === DataType.LOOKUP && dataItem.value?.length > 0 );
        if ( dItems.length === 0 ) {
            return of({
                [eDataModel.id]: eDataModel,
            });
        }
        if ( !entity.links ) {
            entity.links = {};
        }
        const eDataIds: string[] = uniq( dItems
            .map( dItem => this.getRefEDataId( dItem.options )).filter( eDataId => eDataId !== eDataModel.id ));
        const changeModels = {
            [eDataModel.id]: eDataModel,
        };
        let obs = of([ eDataModel ]);
        if ( eDataIds.length > 0 ) {
            obs = forkJoin( eDataIds.map( eId => this.getEDataChangeModel( eId ))).pipe(
                tap( eDataModels => eDataModels.forEach( eModel => changeModels[eModel.id] = eModel )),
            );
        }
        return obs.pipe(
            tap(() => {
                dItems.forEach( dItem => {
                    const eDataId = this.getRefEDataId( dItem.options );
                    let mirrorFieldId = dItem.id;
                    if ( eDataId === eDataModel.id ) {
                        mirrorFieldId = dItem.id.split( '' ).reverse().join( '' );
                    }
                    const refEData = changeModels[eDataId];
                    dItem.value.forEach( entId => {
                        const refEntity = refEData.entities[entId];
                        if ( refEntity.data[mirrorFieldId]) {
                            refEntity.data[mirrorFieldId] = refEntity.data[mirrorFieldId].concat([ entityId ]);
                        }
                        ModelChangeUtils.instance.updateEntityLinks( refEData, {
                            fromEntityId: entId,
                            toEDataId: eDataModel.id,
                            toEntityId: entityId,
                            type: EntityLinkType.LOOKUP,
                            isSet: true,
                            handshake: mirrorFieldId,
                            identifier: entity.getPrimaryText(),
                        });
                        ModelChangeUtils.instance.updateEntityLinks( eDataModel, {
                            fromEntityId: entityId,
                            toEDataId: eDataId,
                            toEntityId: entId,
                            type: EntityLinkType.LOOKUP,
                            isSet: true,
                            handshake: dItem.id,
                            identifier: refEntity.getPrimaryText(),
                        });
                    });
                });
            }),
            mapTo( changeModels ),
        );
    }

    private createEntityList( eDataSakotaModel: Proxied<EDataModel>, shape: ShapeModel, entity: EntityModel ) {
        if ( shape.entityListId ) {
            return eDataSakotaModel;
        }
        let entityList;
        if ( eDataSakotaModel.entityLists ) {
            entityList = Object.values( eDataSakotaModel.entityLists )
            .find( el => el.search === shape.definedSearchQuery );
        }
        if ( entityList ) {
            shape.entityListId = entityList.id;
        }
        return eDataSakotaModel;
    }

    /**
     * Returns all the edata change models available for the diagram
     */
    private getEDataChangeModel( id: string ): Observable<Proxied<EDataModel>> {
        if ( this.eDataModelCache.has( id )) {
            return of( this.eDataModelCache.get( id ));
        }
        return this.ell.getEData( id ).pipe(
            switchMap( locator =>
            locator.getEDataModelOnce().pipe(
                map( model => {
                    if ( this.eDataModelCache.has( id )) {
                        return this.eDataModelCache.get( id );
                    }
                    const proxied = Sakota.create( model );
                    this.eDataModelCache.set( id, proxied );
                    return proxied as any;
                }),
            )),
        );
    }

    /**
     * Checks the modifier and detects if and shape or shapes were added and ignores
     * adding connectors.
     */
    private shapeAdded( diagram: Proxied<DiagramModel>, changes: Partial<Changes> ) {
        const obs: Observable<any>[] = [ of() ];
        this.eDataMemo.clear();
        this.currentEDataModels = null;
        let addedAny = false;
        for ( const key in changes.$set ) {
            const parts = key.split( '.' );
            // FIXME: This check is not 100% foolproof way to detect the shape added scenario
            // but it works currently.
            const shapeAdded = parts.length === 2 && parts[0] === 'shapes';
            if ( !shapeAdded ) {
                continue;
            }
            addedAny = true;
            const shapeId = parts[ 1 ];
            const shapeModel = diagram.shapes[shapeId] as ShapeModel;

            this.setPrimaryTextContent( shapeModel );
            this.setInitialNotes( shapeModel );

            if ( shapeModel.type === 'connector' ) {
                continue;
            }
            this.addShapeToSlide( shapeModel ).subscribe();
            const val = this.updateEdata( shapeModel );
            if ( val instanceof Observable ) {
                obs.push( val as any );
            }
        }
        if ( addedAny ) {
            TiptapDocumentsManagerShapeText.newShapesAdded.next( true );
        }
        return merge( ...obs );
    }


    /**
     * This will update the edataModel with newly added entity.
     */
    private addShapeToEntity( diagramId: string, entityId: string,
                              shape: ShapeModel, eDataModel: Proxied<EDataModel> ) {
        const data =  {
            entityId: entityId,
            shapeId: shape.id,
            shapeDefId: shape.defId,
            diagramId: diagramId,
        };
        this.mcu.addShapeToEntity(
            eDataModel,
            data,
        );
    }

    private addEntityToEntityList( eDataModel: Proxied<EDataModel>, shape: ShapeModel,
                                   entity: EntityModel, diagramId: string ) {
        if ( shape.entityListId && eDataModel.entityLists[shape.entityListId] && shape.containerId ) {
            const entityList: EntityListModel = eDataModel.entityLists[shape.entityListId];
            if ( entity.entityListsIds && !entity.entityListsIds.includes( shape.entityListId )) {
                eDataModel.entities[entity.id].entityListsIds = [ ...entity.entityListsIds, shape.entityListId ];
                entityList.entities = [ ...entityList.entities, entity.id ];
            }
            const containerId =  shape.containerId;
            set( entityList.containers, [ diagramId, containerId, entity.id, 'isMoved' ], false );
            delete entityList.containers[diagramId][containerId][entity.id].pending;
        }
    }
    /**
     * If in presentation mode and currently presenting, add shape to slide
     */
    private addShapeToSlide( shape ) {
        const presentationStatus = this.state.get( 'PresentationStatus' );
        if ( presentationStatus && presentationStatus.presentationId ) {
            const currentSlide = this.state.get( 'ActiveSlide' );
            if ( currentSlide ) {
                return this.pl.getPresentation( presentationStatus.presentationId ).pipe(
                    take( 1 ),
                    tap( presentation => {
                        const activeShapes = this.state.get( 'ActiveShapes' ) || [];
                        const slide = presentation.slides[ currentSlide ] as SlideModel;
                        slide.shapes.push( shape.id );
                        activeShapes.push( shape.id );
                        presentation.slides[ currentSlide ] = slide;
                        this.state.set( 'ActiveShapes', activeShapes );
                        this.commandService.dispatch( BaseDiagramCommandEvent.updatePresentation, {
                            presentation: { $set: presentation },
                            presentationId: presentation.id,
                        });
                    }),
                );
            }
        }
        return EMPTY;
    }


    /**
     * Adds an entity to EData either via a direct entity or to be derived from a shape
     * @param entity
     * @param shape
     * @param eData
     */
     private addEntityToEData( eData: Proxied<EDataModel>, shape?: ShapeModel, entity?: EntityModel ): EntityModel {
        if ( !entity ) {
            if ( !shape ) {
                throw Error( 'Cannot add an Entity without a Shape reference' );
            }

            // FIXME this is a special case when the 2nd edata trigger item is dropped
            // to the canvas when first created a diagram. the defID is not set on the eData
            // so deriving it out of the entity. must investigate why.
            let eDefId = eData.defId;
            if ( !eDefId ) {
                const ents = Object.values( eData.entities );
                if ( ents && ents[0]) {
                    eDefId = ents[0].defId;
                }
            }

            let entityDef;
            if ( shape.entityDefId ) {
                entityDef = eData.customEntityDefs[ shape.entityDefId ];
                if ( !entityDef ) {
                    entityDef = EDataRegistry.instance.getEntityDefById( shape.entityDefId, eData.defId );
                }
            }
            if ( !shape.entityDefId || !entityDef ) {
                entityDef = EDataRegistry.instance.getEntityDefByShapeId( shape.defId, eDefId );
            }
            entity = new EntityModel( Random.entityId(), entityDef.id );

            entity.defId = eDefId;
            entity.dataSource = ( shape as any ).dataSource;
            entity.style = {
                shape: { ...shape.style },
                bounds: {
                    width: shape?.defaultBounds?.width * ( shape.scaleX || 1 ),
                    height: shape?.defaultBounds?.height * ( shape.scaleY || 1 ),
                    angle: shape.angle || 0,
                    defaultBounds: shape.defaultBounds,
                },
            };
            entity.defaultShapeContext = shape.shapeContext;

            // populate the entity with entityDef.dataItems ( entity blueprint )
            if ( entityDef && entityDef.dataItems ) {
                ShapeAddedBinder.initEntity( entity, entityDef );
            }
        }
        this.mcu.addEntity( eData, {
            entity,
            shape,
            diagram: this.changeModel,
        });
        return entity;
    }

    private getEDataModelByDefId( eDataDef: IEDataDef ) {
        return this.getCurrentEDataModels().pipe(
            map( projModels => this.getEDataModelByDefIdForProjectEData( eDataDef, projModels )),
        );
    }

    private getCurrentEDataModels() {
        if ( !this.currentEDataModels ) {
            this.currentEDataModels = this.ell.currentEDataModelsOnce().pipe( shareReplay( 1 ));
        }
        return this.currentEDataModels;
    }

    /**
     * Get entity model or create it
     * @param eDef
     */
    private getEDataModelByDefIdForProjectEData( eDataDef: IEDataDef, projModels: EDataModel[]): EDataModel {
        let eModel;

        if ( this.eDataMemo.has( eDataDef.defId )) {
            return this.eDataMemo.get( eDataDef.defId );
        } else if ( projModels.length > 0 ) {
            eModel = find( projModels, ( item: EDataModel ) => {
                if ( item.defId ) {
                    return item.defId === eDataDef.defId;
                 } else if ( item.entities ) {
                     // FIXME / INVESTIGATE - when its the first time adding the model, the defId is not there.
                     // its there after refresh.
                     const ents = Object.values( item.entities );
                     if ( ents && ents[0]) {
                         return ents[0].defId === eDataDef.defId;
                     }
                 }
            });
        }

        if ( eModel ) {
            this.addEdataModel( eModel.id );
            this.commandService.dispatch( BaseDiagramCommandEvent.startEDataSubscription, eModel.id );
            this.eDataMemo.set( eDataDef.defId, eModel );
            return eModel;
        } else {
            // you cant init a model when in home folder
            if ( this.state.get( 'CurrentProject' ) === 'home' ) {
                return;
            }
            let eData = this.createEdata(
                this.state.get( 'CurrentProject' ),
                eDataDef.defId,
            );
            this.addEdataModel( eData.id );
            this.commandService.dispatch(
                ProjectDiagramCommandEvent.createEData,
                this.state.get( 'CurrentProject' ), eData,
            ).pipe(
                last(),
                switchMap(() =>
                    this.commandService.dispatch( BaseDiagramCommandEvent.startEDataSubscription, eData.id )
                        .pipe( last()),
                ),
            ).subscribe();
            eData = _merge( new EDataModel( eData.id ), eData );
            this.eDataMemo.set( eDataDef.defId, eData );
            return eData;
        }
    }

    /**
     * Updates the eData property of the diagram mdl
     */
    private addEdataModel( eDataModelId ) {
        if ( eDataModelId ) {
            let eDataArr;
            if ( this.changeModel.eData ) {
                eDataArr = clone( this.changeModel.eData );
                if ( this.changeModel.eData.indexOf( eDataModelId ) === -1 ) {
                    eDataArr.push( eDataModelId );
                }
            } else {
                eDataArr = [ eDataModelId ];
            }
            this.changeModel.eData = eDataArr;
        }
    }


    private createEdata( resourceId, defId  ) {
        const data = {} as any;
        const def = this.eDataRegistry.getEDataDef( defId );
        data.id = Random.eDataId();
        data.createdTime = Date.now();
        data.dataDefs = {};
        data.defId = defId;
        data.rid = resourceId;
        data.name = def.name;
        return data;
    }

    private getRefEDataId( options ) {
        return options.eDataId || EDataRegistry.getEDataId( options.eDataDefId );
    }

    private setPrimaryTextContent( shape: any ) {
        if ( shape.initialPrimaryTextContent ) {
            let primaryTextModel: any = Object.values( shape.texts || {})
                .find(( t: any ) => t.primary );
            if ( !primaryTextModel ) {
                primaryTextModel =  Object.values( shape.texts || {})[0];
            }
            if ( !primaryTextModel ) {
                primaryTextModel = {
                    id: 'primary_text',
                    _alignX: 0,
                    content: [
                        {
                            ...DEFUALT_TEXT_STYLES,
                          },
                    ],
                    handlebars: {},
                    height: 10,
                    rendering: 'carota',
                    width: 10,
                    minWidth: 10,
                    primary: true,
                } as any;
                shape.texts = {
                    primary_text: primaryTextModel,
                };
            }

            if ( Array.isArray( shape.initialPrimaryTextContent )) {
                if ( primaryTextModel.content ) {
                    primaryTextModel.content = cloneDeep( shape.initialPrimaryTextContent );
                }
                if ( shape.data?.[ primaryTextModel.id ]?.value ) {
                    shape.data[ primaryTextModel.id ].value = shape
                        .initialPrimaryTextContent.map( run => run.text ).join( '' );
                }
            } else {
                if ( primaryTextModel.content ) {
                    primaryTextModel.content = [
                        {
                            ...primaryTextModel.content[0],
                            ...shape.initialPrimaryTextContent,
                        },
                    ];
                    primaryTextModel.value = shape.initialPrimaryTextContent.text;
                }
                if ( shape.data?.[ primaryTextModel.id ]?.value ) {
                    shape.data[ primaryTextModel.id ].value = shape.initialPrimaryTextContent.text;
                }
            }
            delete shape.initialPrimaryTextContent;
        }
    }

    private setInitialNotes( shape: any ) {
        if ( shape.initialNotes ) {
            const descriptionDataItem: any = ( shape.data || {}).description;
            if ( descriptionDataItem ) {
                descriptionDataItem.value = shape.initialNotes;
            } else {
                shape.data = {
                    description: {
                        value: shape.initialNotes,
                    },
                };
            }
            delete shape.initialNotes;
        }
    }


}
