import { of, merge, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';
import { IChainStatus } from './chain-status.i';
import { IResponsibility } from './responsibility.i';

/**
 * This class is an implementation of the Chain of Responsibility design pattern.
 * This can be used to solve problems in which we need to find an outcome which is
 * determined by multiple concerns (that are decoupled from each other) and
 * their possible permutations. The main components of this system are the controller
 * (this class), responsibilities (concerns) and requester (whoever requests the controller
 * for the solution). A detailed description of what a responsibility is defined in
 * {@link IResponsibility} interface.
 *
 * - The requester only knows the responsibility to start with.
 * - Controller does not know what the responsibilities in the sequence are.
 * - Each responsibility is a seperate concern that defines a primary state.
 * - Each responsibility knows what the next responsibility in the sequence is.
 * - There can be any number of responsibilities in the chain with different permutations.
 * - A responsibility is able to decide on the result (outcome) of the sequence.
 *
 * Example of problems which can be solved by a sequence conotroller:
 * Triathalon game - A version of a Triathalon game where a single player competes. The next stage the player
 * has to undertake is determined by the time taken to complete the previous stage (i.e. if
 * the player completed the swimming stage in less than 15 minutes, next stage will be running.
 * If they took less than 10 minutes, next stage will be biking. If they completed at least
 * two stages in less than 2 minutes, they get to skip a stage, etc). In this scenario, the
 * responsibilities are the management of each stage. They decide which stage the player should go
 * next based on the time taken to complete current and previous stages.
 * Other examples include automated phone help desk( options list, sequence for each option the
 * dialler chooses and the end result) and the possible sequence and different outcomes when
 * withdrawing money from an ATM.
 *
 * {@link IChainStatus}
 * @author  Ramishka
 * @since   2017-12-05
 */
@Injectable()
export class ChainSequenceController {

    /**
     * Constructor. Injects the injector to create responsibilities.
     * @param injector
     */
    constructor( protected injector: Injector ) {}

    /**
     * Starts the sequence chain with a responsibility to start with.
     * @param name - name of the responsibility to start with
     * @param status - current chain status
     * @return Observable that emits the chain status after each outcome in the sequence
     */
    public start( name: string,
                  status: IChainStatus = { states: {}, outcome: undefined, input: {}}): Observable<IChainStatus> {
        status = Object.assign({}, status );
        const responsibility: IResponsibility = this.createResponsiblity( name );
        return responsibility.checkState( status ).pipe( switchMap( currentState => {
            this.updateStates( responsibility, status, currentState );
            const nextResponsibilities: Array<string> = responsibility.nextResponsibility( status );
            let checks: Array<Observable<any>>;
            if ( nextResponsibilities ) {
                checks = nextResponsibilities.map( responsibilityName => this.start( responsibilityName, status ));
            }
            status.outcome = responsibility.result( status );
            return this.merge( status, checks );
        }));
    }

    /**
     * Updates all states pertaining to a given responsibility.
     * Updates the state of the responsibility as well as
     * any other states updated by that responsibility.
     * Does not set or alter any states that are already set.
     * @param responsibility - responsibility whichs states will be updated
     * @param status - current chain status
     * @param currentState - current value of the responsibilities state
     */
    protected updateStates ( responsibility: IResponsibility, status: IChainStatus, currentState: any ) {
        status.states[responsibility.name] = currentState;
        if ( responsibility.stateChanges ) {
            const newStates = responsibility.stateChanges( status );
            if ( newStates ) {
                Object.keys( newStates ).forEach( newState => {
                    if ( !status.states.hasOwnProperty( newState )) {
                        status.states[newState] = newStates[newState];
                    }
                });
            }
        }
    }

    /**
     * Merges the current outcome (if any) and pending state checks. This is done is such way
     * that the chain status is emitted after each outcome in the sequence.
     * @param status - current chain status
     * @param checks -  state checks to be performed
     * @return Observable that emits the status of each outcome in the sequence.
     */
    protected merge( status: IChainStatus, checks: Array<Observable<any>> ): Observable<IChainStatus> {
        if ( status.outcome ) {
            if ( checks && checks.length > 0 ) {
                return merge( of( status ), ...checks );
            }
            return of( status );
        } else {
            if ( checks ) {
                return merge( ...checks );
            }
            return of();
        }
    }

    /**
     * This function creates an instance of a responsibility given its name.
     * Uses the injector and the name to create the instance.
     *
     * Responsibility names MUST always match their class name.
     * They need to be provided by the name string token:
     * example -
     * <code>
     *  providers: [
     *      ResponsibilityClass,
     *      { provide: 'ResponsibilityClass', useExisting: ResponsibilityClass },
     *  ]
     * </code>
     *
     * @param name - name (id) of the responsibility
     * @return instance of the responsibility class that was created
     */
    protected createResponsiblity( name: string ): IResponsibility {
        return this.injector.get( name );
    }


}
