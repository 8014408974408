import { minBy } from 'lodash';
import { Line } from './line';
import { Point } from './point';
import { IPoint2D, IPath } from 'flux-definition';


/**
 * A generic LinePath class used for calculations related to
 * a path created by straight lines. the constructor expects
 * an array of points in order.
 */
export class LinePath implements IPath {

    protected lines: Array< Line >;

    constructor( public points: Array<IPoint2D> ) {
        this.createLines();
    }


    /**
     * Returns the first point of the path
     */
    public get first(): IPoint2D {
        return this.points[ 0 ];
    }

    /**
     * Returns the last point of the path
     */
    public get last(): IPoint2D {
        return this.points[ this.points.length - 1 ];
    }

    /**
     * Returns the total length of the path
     */
    public get length() {
        let from = this.points[0];
        let length = 0;

        for ( let i = 0; i < this.points.length; i++ ) {
            const to = this.points[ i + 1 ];
            if ( to ) {
                const line = new Line( new Point( from.x, from.y ), new Point( to.x, to.y ));
                length += line.length();
                from = to;
            } else {
                break;
            }
        }

        return length;
    }

    /**
     * Calculates the coordinates of the point on the given straigth lines path at the
     * given position. Position is defined by some proportion along the length of the path.
     *
     * @param ratio number  A decimal in the range [0-1]
     *                      which indicates a point some proportion along the length of the path
     *
     * @param start boolean The point to start calculation from, by default it measures
     *                      fron the start point,if false, it measures from the end point
     */
    public split( pos: number, start: boolean = true ) {
        const l = this.length;
        const location = start ? l * pos : l * ( 1 - pos );
        return this.splitByLength( location );
    }

    /**
     * Calculates the coordinates of the point on the line path at given length.
     *
     * @param location number A pixel value indicating the length along the line
     * @param start boolean   The point to start calculation from, by default it measures
     *                        from the start point,if false, it measures from the end point
     */
    public splitByLength( _location: number, start: boolean = true ) {
        const l = this.length;
        const location = start === false ? l - _location : _location;
        if ( location === 0 || l === 0 ) {
            return this.first;
        }
        if ( location === l ) {
            return this.last;
        }
        const { line, diff } = this.lineAtLocation( location );
        return line.splitByLength( diff, true );
    }

    /**
     * Calculates the nearest point to the given point on the line path.
     * returns the point and length to that point
     * @param The test point
     * @param { point: IPoint2D, location: number } The nearest point cordinates and the length fraction along the path
     */
    public getNearestPoint( point: IPoint2D ): { point: IPoint2D, location: number } {
        const lineData = this.lines.map(( line, index ) => {
            const nearestData = line.getNearestPoint( point );
            const pointOnLine = nearestData.point;
            const lineLength = Line.from( point.x , point.y, pointOnLine.x, pointOnLine.y ).length();
            return { index, length: lineLength, nearestData };
        });

        const indexOfClosestLine = minBy( lineData, 'length' ).index;
        const closestLine = this.lines[ indexOfClosestLine ];

        let distance = 0;
        for ( let index = 0; index < indexOfClosestLine; index++ ) {
            distance = distance + this.lines[ index ].length();
        }

        const position =  lineData[ indexOfClosestLine ].nearestData;
        const length = position.location * closestLine.length();

        return {
            point: position.point,
            location: ( distance + length ) / this.length,
        };
    }

    private createLines() {
        this.lines = [];
        let from = this.points[0];
        for ( let i = 0; i < this.points.length; i++ ) {
            const to = this.points[ i + 1 ];
            if ( to ) {
                this.lines.push( Line.from( from.x, from.y, to.x, to.y ));
                from = to;
            } else {
                break;
            }
        }
    }

    /**
     * The line path is made of multiple line segments, this method returns the line
     * which contains the given location and how far along the line is the location.
     * @param location number A pixel value indicating the length along the line
     * @return { line: Line, diff: number } The line segment and the length to location
     */
    private lineAtLocation( location: number ): { line: Line, diff: number } {
        const pathLength = this.length;
        if ( location <= 0 ) {
            return { line: this.lines[0], diff: location };
        }
        if ( location >= pathLength ) {
            const lastLine = this.lines[ this.lines.length - 1 ];
            return { line: lastLine, diff: lastLine.length() + location - pathLength };
        }
        let len = 0;
        let line: Line;
        for ( let i = 0; i < this.lines.length; ++i ) {
            const currentLine = this.lines[i];
            const lineLength = currentLine.length();
            if ( len + lineLength >= location ) {
                line = currentLine;
                break;
            }
            len += lineLength;
        }
        return { line, diff: location - len };
    }
}
