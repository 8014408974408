import { GraphicsDrawUtils } from './graphics-draw-utils';
import { IModifier } from 'flux-core';
import {
    IAbstractViewDefinition,
    IDrawViewOptions,
    IGeometry,
    IGraphics,
    IText,
    StrokeCaps,
    StrokeJoints,
    IShapeImage,
    ShapeType,
    IStyle,
} from 'flux-definition';
import { forEach, cloneDeep, isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { Geometry } from '../geometry';
import { ITextView } from '../text/text-view.i';
import { IShapeImageView } from '../image/shape-image-view.i';

/**
 * The top level view abstraction of all types of shapes and connectors
 * in all creately applications. This view will be extended by other shape
 * views and connector views that is able to render in various different
 * types of environments.
 *
 * @author hiraash
 * @since 2017-09-06
 */
export class AbstractShapeView implements IAbstractViewDefinition {

    /**
     * Subscriptions that need to be destroyed.
     */
    protected _subs: Array<Subscription>;

    /**
     * The list of text views that are part of this view.
     * These are abstract representations of text view that
     * will be impemented by different types of shape/connector views.
     */
    protected _texts: ITextView[] = [];

    /**
     * An array of embedded images
     */
    protected _images: IShapeImageView[] = [];


    protected _dynamicStyle: IStyle;

    protected _dynamicBounds: any;

    protected _dynamicViewState: any;


    constructor( protected _model: AbstractShapeModel ) {
        this._subs = [];
        this._texts = [];
    }

    /**
     * Unique identifier of the shape.
     * Picked up from the model
     */
    public get id(): string {
        return this._model.id;
    }

    /**
     * The model associated with this shape.
     */
    public get model(): AbstractShapeModel {
        return this._model;
    }

    /**
     * Returns the IGraphics instance attached to this shape
     * where all drawing is done and recorded.
     */
    public get graphics(): IGraphics {
        return null;
    }

    /**
     * Returns the IGeometry object which contains functions which can
     * be used to make geometry calculations.
     */
    public get geometry(): IGeometry {
        return Geometry.instance;
    }

    /**
     * This is the visual output of a single shape
     *
     * This returns an object that has the full rendered shape
     * for use by a diagram composing class. This return a different
     * thing based on the environemnt and implementation of this class
     */
    public get renderedShape(): any {
        return null;
    }

    /**
     * This function returns all the text view connected to the
     * shape view
     */
    public get allTextViews(): ITextView[] {
        return this._texts;
    }

    /**
     * Expected IGraphics instance attached to this shadow shape and
     * and need to draw a graphics to show the shadow.This will be bottom
     * layer of the shape.
     * @override
     */
    public get shadow(): IGraphics {
        return null;
    }

    /**
     * Sets the dynamic style and renders the shape again.
     */
    public set dynamicStyle( style: IStyle ) {
        if ( !isEqual( this._dynamicStyle, style )) {
            this._dynamicStyle = style;
            this.render( undefined, { omitText: true });
        }
    }

    public updateModel( value: AbstractShapeModel ) {
        this._model = value;
    }

    /**
     * This method ultimately redraws this shape on the given
     * drawing space. The method would ensure that all data changes
     * are translated into the drawing space as intended by this shape.
     * This means the full shape will be up-to-date on the drawing space
     * when this method completes until the next data change on the model.
     * @param change A json with all the changes that happened to the model.
     *          Can be used to check which props changed or what changes happened.
     */
    public render( modifier?: IModifier, options: any = {}) {
        this.graphics.clear();
        if ( this.shadow ) {
            this.shadow.clear();
        }
        if ( !this.model.visible ) {
            this._texts.forEach( text => this.removeText( text ));
            this._images.forEach( image => this.removeImage( image ));
            return;
        }
        if ( !options.omitText ) {
            this.drawText( modifier );
        }
        this.drawImages();
        this.beginDraw();
        if ( this.model.type === ShapeType.Freehand && ( this.model as any ).instructions ) {
            const model: any = cloneDeep( this.model );
            GraphicsDrawUtils.drawFromInstructions(
                this.graphics,
                model.instructions,
                model.scaleX,
                model.scaleY,
                model.style.lineThickness,
                model.defaultBounds.width,
                model.defaultBounds.height,
            );
        } else {
            this.draw();
        }
        this.endDraw();
    }

    /**
     * This method is called when a shape view is added to the drawing
     * space
     */
    public initialize() {
        // Overridden by the concrete implementation
    }

    /**
     * This draws the shape using the drawToSize function.
     * This will prepare any data required by the drawToSize to
     * render the shape.
     */
    public draw() {
        // Will be overridden by the Shape View Definition
    }

    /**
     * The shape's draw method which will be implemented by
     * the definition of the view.
     * This is ultimatly what draws the shape's visual part
     * and is the most important part of the view.
     * @param graphics
     * @param options
     */
    public drawToSize( graphics: IGraphics, options: IDrawViewOptions ) {
        // Will be overridden by the Shape View Definition
    }

    /**
     * Supporting method which is fired before draw. Setting styles
     * and draw preparation can be done here. This may be replaced by
     * the Entry Class of the View Definition
     */
    public beginDraw() {
        if ( this.model.style.lineThickness ) {
            this.graphics.setStrokeStyle( this.model.style.lineThickness, StrokeCaps.ROUND, StrokeJoints.ROUND );
        }
        if ( this.model.style.lineStyle && this.model.style.lineStyle.length > 1 ) {
            this.graphics.setStrokeDash( this.model.style.lineStyle );
        }
        // If line thinkness is 0, then this will not render shape line.
        if ( this.model.style.lineColor && this.model.style.lineThickness !== 0 ) {
            this.graphics.beginStroke( this.model.style.lineColor );
        }
    }

    /**
     * Supporting method which is fired after draw. Closing styles
     * and draw ending can be done here. This may be replaced by
     * the Entry Class of the View Definition
     */
    public endDraw() {
        this.graphics.endStroke();
    }

    /**
     * The primary method that will manage all text drawing and rendering
     * functionality. Responsible for ensuring all text are rendered exactly
     * as per the model.
     *
     * This method will run every render cycle and the stack must be controlled
     * carefully to ensure that only necessary functionality are executed.
     */
    public drawText( modifier: IModifier ) {
        this.addTexts();
        this.removeTexts();
        this._texts.forEach( text => {
            this.updateText( text, modifier );
        });
    }

    public drawImages(): void {
        this.removeImages();
        this.addImages();
        this._images.forEach( image => this.updateImage( image ));
    }

    /**
     * This function returns the text view for the
     * given text model id
     * @param string  The name (id) of the text view
     */
    public getTextView( name: string ): ITextView {
        return this._texts.find( t => t.name === name );
    }

    /**
     * This function returns true a textView exists for the
     * given name and returns false if no textview found
     * @param string  The name (id) of the text view
     */
    public hasTextView( name: string  ): boolean {
        return this._texts.findIndex( t => t.name === name ) > -1;
    }

    // IMAGES
    // ------

    public getImageView( id: string ): IShapeImageView {
        return this._images.find( imageView => imageView.name === id );
    }

    public hasImageView( id: string  ): boolean {
        return !!this.getImageView( id );
    }

    /**
     * Destroys all resources associated to this view.
     */
    public destroy() {
        this._texts.forEach( text => text.destroy());
        this._images.forEach( image => image.destroy());
        this._subs.forEach( sub => sub.unsubscribe());
    }

    /**
     * This function calls addText method only for texts
     * that are not already added and also considers non empty texts only
     */
    protected addTexts() {
        const texts = this._model.texts;
        forEach( texts, ( text: IText, key ) => {
            if ( text && text.isVisible && !this.hasTextView( key )) {
                this.addText( text );
            }
        });
    }

    /**
     * This function calls the removeText function for
     * texts that dont exist anymore also considers empty texts as well
     */
    protected removeTexts() {
        for ( let i = this._texts.length - 1; i > -1; i-- ) {
            const textView = this._texts[i];
            if ( !this.model.texts[ textView.name ]) {
                this.removeText( textView );
            }
        }
    }

    /**
     * Must be implemented by extending class.
     * Must create a text view and add it to the text view list.
     * @param text The text model.
     */
    protected addText( text: IText ) {}

    /**
     * Must be implemented by extending class.
     * This removes the text view from the text view list. This also
     * must ensure it is removed from the rendering.
     * @param text The text view to remove.
     */
    protected removeText( text: ITextView ) {
        text.destroy();
        const i = this._texts.indexOf( text );
        if ( i > -1 ) {
            this._texts.splice( i , 1 );
        }
    }

    /**
     * This render's the text view as per the model data
     * and config. It also positions the text view in relation
     * to the shape.
     * @param text The text view to render.
     */
    protected updateText( text: ITextView, modifier: IModifier ) {
        text.update( this._model.texts[ text.name ]);
    }

    protected addImages() {
        const images = this._model.images;
        forEach( images, ( imageModel: IShapeImage, key ) => {
            if ( imageModel && !this.hasImageView( key )) {
                this.addImage( imageModel );
            }
        });
    }

    protected addImage( imageModel: IShapeImage ) {}

    protected removeImages() {
        for ( let i = this._images.length - 1; i > -1; i-- ) {
            const imageView = this._images[i];
            const imageModel = this._model.images[ imageView.name ];
            if ( !imageModel || imageModel.file !== imageView.file ) {
                this.removeImage( imageView );
            }
        }
    }

    protected removeImage( imageView: IShapeImageView ) {
        imageView.destroy();
        this.renderedShape.removeChild( imageView.renderedImage );
        if ( imageView.imageOutline ) {
            imageView.imageOutline.graphics.clear();
            this.renderedShape.removeChild( imageView.imageOutline );
        }
        const i = this._images.indexOf( imageView );
        if ( i > -1 ) {
            this._images.splice( i , 1 );
        }
    }

    protected updateImage( imageView: IShapeImageView ) {
        imageView.update( this._model.images[ imageView.name ]);
    }
}
