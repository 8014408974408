import { ModelChangeUtils } from '../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { EDataChangeService } from '../../edata/edata-change.svc';
import { AbstractEDataChangeCommand } from '../../edata/command/abstract-edata-change-command.cmd';

/**
 * Delete provided shape id from entity data. Use this only with Debugger mode.
 */
@Injectable()
@Command()
export class DeleteEntityShapes extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        diagramId: string,
        shapeIds: string[],
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        if ( this.changeModel && this.changeModel.entities ) {
            // tslint:disable: no-console
            console.log( 'Debugger: This will take some times' );
            console.log( 'Debugger: Searching for shape references in Edata' );
            Object.keys( this.changeModel.entities ).forEach( entityId => {
                const diagramsShapes  = this.changeModel.entities[ entityId ].shapes;
                if ( diagramsShapes ) {
                    if ( this.data.diagramId ) {
                        const docShapes = diagramsShapes[ this.data.diagramId ];
                        this.deleteShapeIds( docShapes, this.data.shapeIds, entityId );
                    } else {
                        Object.keys( diagramsShapes ).forEach( diagramId => {
                            const docShapes = diagramsShapes[ diagramId ];
                            this.deleteShapeIds( docShapes, this.data.shapeIds, entityId );
                        });
                    }
                }
            });
        }
    }

    private deleteShapeIds( docShapes: any , shapeIds: string[], entityId: string ) {
        if ( docShapes ) {
            Object.keys( docShapes ).forEach( shapeId => {
                if ( shapeIds.includes( shapeId ) && docShapes[ shapeId ]) {
                    console.log( 'Debugger: Found a shape reference in entityId :', entityId );
                    delete docShapes[ shapeId ];
                    console.log( 'Debugger: Deleted the shape reference', shapeId );
                }
            });
        }
    }
}

Object.defineProperty( DeleteEntityShapes, 'name', {
    value: 'UpdateEntityData',
});
