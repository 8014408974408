import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
    ModalController,
    NotifierController,
    AppConfig,
    PostMessageAPI,
    Tracker, StateService,
    CommandService,
    AppNavConfigInterpreter,
    PopupWindow,
} from 'flux-core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataStore } from 'flux-store';
import { catchError, last, map, tap } from 'rxjs/operators';
import { UserInfoModel } from '../../user/model/user-info.mdl';
import { UserCommandEvent } from '../../user/command/user-command-event';
import { Router } from '@angular/router';

/**
 * An enum containing the available pages on the onboarding window.
 */
export enum OnboardingPage {
    SignUp = 'signup-in-app', // Step 1
    SignIn = 'signin-in-app', // Step 1
    Plans = 'plans-in-app', // Step 2
    Onboarding = 'onboarding-in-app', // Step 3
    DemoEmailVerify = 'demo/email/verify', // Demo email verification
    PurchaseStarted = 'purchase-started',
}

/**
 * Post message receive event types for onboarding window.
 */
export enum OnboardingWindowPMReceiveEvent {
    SIGN_IN_SUCCESS = 'phoenix:signInSuccess',
    SIGN_UP_SUCCESS = 'phoenix:signUpSuccess',
    EMAIL_VERIFY_SUCCESS = 'phoenix:emailVerifySuccess',
    PURCHASE_STARTED = 'phoenix:purchaseStarted',
    PURCHASE_SUCCESS = 'phoenix:purchaseSuccess',
    CONTINUE_FREE = 'phoenix:continueFree',
    ONBOARDING_SUCCESS = 'phoenix:onboardingSuccess',
    ONBOARDING_ERROR = 'phoenix:onboardingError',
    ONBOARDING_CLOSE = 'phoenix:onboardingClose',
    DEMO_EMAIL_VERIFY_SUCCESS = 'phoenix:demoEmailVerifySuccess',
    DEMO_EMAIL_CONTINUE = 'phoenix:demoEmailContinue',
    ONBOARDING_SELECTION_CHANGE = 'phoenix:onboardingSelectionChange',
    SIGN_UP_FAIL = 'phoenix:signUpFail',
    SIGN_IN_SUCCESS_IN_APP_SIGN_UP = 'phoenix:signInSuccessInAppSignUp',
    SIGN_UP_CLOSE = 'phoenix:signUpClose',
    RELOAD_APP = 'phoenix:reloadApp',
}

/**
 * Section IDs for sections on the onboarding flow page.
 */
export enum OnboardingSection {
    Usage = 'usage',
    Industry = 'industry',
    Role = 'role',
    Purpose = 'purpose',
    Size = 'size',
}


/**
 * Language code which support withing site Iframe url
 */
export const supportedTranslateCode = [
   'ar', 'de', 'el', 'es', 'fr', 'he', 'id', 'it', 'ja', 'ko',
   'ms', 'nl', 'no', 'pl', 'pt', 'ru', 'tr', 'vi', 'zh',
];

export enum TemplateTypeUrl {
    BrainstormingIdeation = '?type=popular&cat=brainstorming',
    OrganiseProject = '?type=popular&cat=project',
    StrategyPlanning = '?type=popular&cat=strategy',
    MeetingsWorkshops = '?type=popular&cat=meetings',
    DiagramsFlowcharts = '?type=popular&cat=diagrams',
    ResearchDesign = '?type=popular&cat=research',
    Popular = '?type=popular',
    CreatelyViz = '?type=createlyviz',
    All = '?type=all',  // This will be the default value
}

/**
 * Onboarding Window component.
 * Handles authentication, onboarding and other user onboarding related functionality.
 *
 * @author  Jerome
 * @since   21-07-2021
 */
@Component({
    templateUrl: 'onboarding-window.cmp.html',
    selector: 'onboarding-window',
    styleUrls: [ './onboarding-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingWindow extends PopupWindow implements OnDestroy, OnInit {

    /**
     * Display purchase started message.
     */
    public displayPurchaseStarted: BehaviorSubject<Boolean>;

    /**
     * A UI Toggle based on type of page to limit the max dimensions of the modal.
     * defaults to false.
     */
    public isCompact: boolean = false;

    @Input()
    public recentDiagramId: string;

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * An array containing all temporary email domains.
     */
    protected temporaryEmailDomains: string[];

    /**
     * The wrapper container.
     */
    @ViewChild( 'window', { static: true })
    protected window: ElementRef<HTMLDivElement>;

    /**
     * The window container.
     */
    @ViewChild( 'container', { static: true })
    protected container:  ElementRef<HTMLDivElement>;

    /**
     * The window page to open. Defaults to Demo.
     */
    private _page: OnboardingPage = OnboardingPage.Plans;

    /**
     * Track the current plan upon component initialization.
     */
    private currentPlan: string;

    /**
     * The user email.
     */
    private _email: string;

    private showInAppPlans: boolean;

    /**
     * This value set true if user confirmed the email
     */
    private isEmailConfirmed: boolean;

    constructor(
        protected modalController: ModalController,
        protected postMessage: PostMessageAPI,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected changeDetectorRef: ChangeDetectorRef,
        protected commandService: CommandService,
        protected appNavConfig: AppNavConfigInterpreter,
        protected router: Router,
    ) {
        super();
        this.subs = [];
        this.displayPurchaseStarted = new BehaviorSubject( false );
        this.temporaryEmailDomains = AppConfig.get( 'TEMPORARY_EMAIL_DOMAINS' );
        this.currentPlan = window.gravity.client.hasValidSession() ? window.gravity.client.getCurrentUserPlan() : '';
        this.isEmailConfirmed = false;
    }

    @Input()
    public get page(): OnboardingPage {
        return this._page;
    }

    public set page( page: OnboardingPage ) {
        this._page = page;

        if ( page === OnboardingPage.Onboarding ) {
            this.isCompact = true;
            Tracker.track( 'onboarding.load' );
        }

        if ( page === OnboardingPage.Plans ) {
            this.isCompact = false;
            Tracker.track( 'conversion.dialog.plans.load' );
        }

        if ( page === OnboardingPage.DemoEmailVerify ) {
            Tracker.track( 'dialog.demo.load' );
            this.isCompact = true;
        }

        if ( page === OnboardingPage.SignIn ) {
            Tracker.track( 'modal.login.open' );
            this.isCompact = true;
        }

        if ( page === OnboardingPage.SignUp ) {
            Tracker.track( 'modal.signup.open' );
            this.isCompact = true;
        }
    }

    @Input()
    public get email(): string {
        return this._email;
    }

    public set email( email: string ) {
        this._email = email;
    }

    /**
     * Return the onboarding window URL with the current page to open.
     */
    public get iFrameUrl(): string {

        let siteUrl = AppConfig.get( 'SITE_URL' );
        if ( this.page !== OnboardingPage.Onboarding && supportedTranslateCode.includes( this.translate.currentLang )) {
            siteUrl = `${AppConfig.get( 'SITE_URL' )}${this.translate.currentLang}/`;
        }
        let url = `${siteUrl}${this.page}/`;

        if ( this.recentDiagramId ) {
            url = `${url}?skip=step-3&`;
        } else {
            url = `${url}?`;
        }

        const hasParams = this.email || this.isEmailConfirmed;
        if ( !hasParams && ( this.page === OnboardingPage.SignIn || this.page === OnboardingPage.SignUp )) {
            return `${url}region=${this.getDeploymentRegion()}`;
        }

        if ( this.page === OnboardingPage.DemoEmailVerify &&  this.checkDemoUserSignIn()) {
            this.page = OnboardingPage.SignIn;
            this.setDemoUserSignIn( false );
            if ( this.recentDiagramId ) {
                return `${siteUrl}${this.page}/?skip=step-3&w=warning-demo`;
            } else {
                return `${siteUrl}${this.page}/?w=warning-demo`;
            }
        }
        if ( this.email ) {
            const isTempEmails = this.temporaryEmailDomains.filter( domain => this.email.indexOf( domain ) !== -1 );
            if ( !( isTempEmails.length > 0 )) {
                if ( this.isEmailConfirmed ) {
                    return `${url}v=${btoa( this.email )}&ref=register-success`;
                } else {
                    return `${url}v=${btoa( this.email )}`;
                }
            }
        }
        if ( this.isEmailConfirmed ) {
            return `${url}ref=register-success`;
        }
        return url;
    }

    /**
     * Show window and subscribe to post message api.
     */
    public ngOnInit(): void {
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
            this.postMessage.recv().subscribe( message => this.handleIncomingMessages( message )),
            this.listenToShowPlansTag(),
        );

        if ( localStorage.getItem( 'isAnonymousAccess' ) === 'true' ) {
            this.closeWindow();
        }
    }

    /**
     * Close the onboarding window.
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Reload the current application window after demo user purchases a plan.
     * This forces the user model to be updated after reload so the new plan will take effect.
     */
    public reloadAfterPurchase() {
        this.dataStore.remove(
            UserInfoModel,
            { id: this.state.get( 'CurrentUser' ) },
        ).subscribe({
            complete: () => {
                this.reload();
            },
        });
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    protected getDeploymentRegion() {
        return AppConfig.get( 'APP_REGION' );
    }

    /**
     * Reload the current application window.
     * NOTE: This function can not be tested with unit tests
     */
    /* istanbul ignore next */
    protected reload() {
        window.location.href = `${AppConfig.get( 'APP_URL' )}/start/dashboard`;
    }

    /**
     * redirect the application url
     * NOTE: This function can not be tested with unit tests
     */
    /* istanbul ignore next */
    protected redirect( url: string ) {
        window.location.href = url;
    }


    /**
     * Handle incoming post messages from the window iframe.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    protected handleIncomingMessages( message: any ): void {
        if ( !message || typeof message !== 'object' ) {
            return;
        }

        const { event, data } = message as any;

        // User has signed in successfully
        // or signed up with a free plan
        if ( event === OnboardingWindowPMReceiveEvent.SIGN_IN_SUCCESS ||
            ( event === OnboardingWindowPMReceiveEvent.CONTINUE_FREE && !this.currentPlan.startsWith( 'demo' ))) {

            this.dispatchFetchUserOnboardingStatus().pipe(
                tap( resultData => {
                    if ( this.showInAppPlans ) {
                        // if show in app plans is true, show the plans page instead
                        this.isCompact = false;
                        this.page = OnboardingPage.Plans;
                        this.changeDetectorRef.markForCheck();
                        this.showInAppPlans = false;
                    } else if ( resultData && this.shouldShowOnboarding({ ...data, ...resultData })) {
                        // Onboarding modal requires full dimensions.
                        this.isCompact = false;
                        this.page = OnboardingPage.Onboarding;
                        this.changeDetectorRef.markForCheck();
                    } else {
                        if ( event === OnboardingWindowPMReceiveEvent.SIGN_IN_SUCCESS ) {
                            Tracker.track( 'dialog.signin.closed' );
                            Tracker.track( 'modal.login.closed' );
                        } else if ( event === OnboardingWindowPMReceiveEvent.CONTINUE_FREE ) {
                            Tracker.track( 'conversion.dialog.warning.ok.click', {
                                value1Type: 'planChoice',
                                value1: 'FREE',
                            });
                        }
                        this.closeWindow();
                    }
                }),
                catchError(() => {
                    this.closeWindow();
                    return EMPTY;
                }),
            ).subscribe();
        }

        if ( event === OnboardingWindowPMReceiveEvent.EMAIL_VERIFY_SUCCESS ) {
            if ( data && data.plan ) {
                this.redirect( `${AppConfig.get( 'SITE_URL' )}${data.plan}` );
            }
            this.isEmailConfirmed = true;
        }

        // User has signed up successfully or verify email successfully
        if ( event === OnboardingWindowPMReceiveEvent.SIGN_UP_SUCCESS
            || event === OnboardingWindowPMReceiveEvent.EMAIL_VERIFY_SUCCESS ) {
            const newPlan = window.gravity.client.hasValidSession() && window.gravity.client.getCurrentUserPlan();

            // If the user is on a team plan after sign up, they have accepted a team plan
            // since the default plan after sign up is a free plan.
            if ( newPlan && newPlan.startsWith( 'team' )) {
                this.closeWindow();
            // If sign_up_purchase_load cookie is there
            // show phoenix purchase started page
            } else if ( this.getCookie( 'sign_up_purchase_load' )) {
                this.page = OnboardingPage.PurchaseStarted;
                this.changeDetectorRef.markForCheck();
            } else {
                // Plans modal requires full dimensions.
                this.isCompact = false;
                this.page = OnboardingPage.Plans;
                this.changeDetectorRef.markForCheck();
            }
        }

        // User has completed onboarding
        if ( event === OnboardingWindowPMReceiveEvent.ONBOARDING_SUCCESS ) {
            if ( data.lastCompletedSectionId ) {
                Tracker.track( 'onboarding.startNow.click', {
                    value1: this.getSectionIdForTracker( data.lastCompletedSectionId ),
                    value1Type: 'Last completed section',
                });
            } else {
                Tracker.track( 'onboarding.startNow.click' );
            }
            this.showInAppPlans = false;
            this.updateOnboardingAndClose( data );
        }

        // User has closed the onboarding window
        if ( event === OnboardingWindowPMReceiveEvent.ONBOARDING_CLOSE ) {
            if ( data.lastCompletedSectionId ) {
                Tracker.track( 'onboarding.close', {
                    value1: this.getSectionIdForTracker( data.lastCompletedSectionId ),
                    value1Type: 'Last completed section',
                });
            } else {
                Tracker.track( 'onboarding.close' );
            }

            this.updateOnboardingAndClose( data );
        }

        if ( event === OnboardingWindowPMReceiveEvent.ONBOARDING_SELECTION_CHANGE &&
            data.sectionId && data.optionId ) {
            const sectionId = data.sectionId;
            const optionId = data.optionId;
            const sectionIdForTracker = this.getSectionIdForTracker( sectionId );
            const optionLabel = data.optionLabel || optionId;
            const trackingId = `onboarding.q.${sectionIdForTracker}.click`;

            let value2: string = null;
            let value2Type: string = null;

            if ( sectionId === OnboardingSection.Industry ) {
                value2 = data.usageLabel;
                value2Type = 'Profile';
            }

            if ( sectionId === OnboardingSection.Role || sectionId === OnboardingSection.Purpose
                || sectionId === OnboardingSection.Size ) {
                value2 = data.industryLabel;
                value2Type = 'Industry';
            }

            if ( value2 && value2Type ) {
                Tracker.track( trackingId, {
                    value1: optionLabel,
                    value1Type: 'Selected Option',
                    value2,
                    value2Type,
                });
            } else {
                Tracker.track( trackingId, {
                    value1: optionLabel,
                    value1Type: 'Selected Option',
                });
            }
        }

        // There was an error during user completing onboarding page, let them continue
        if ( event === OnboardingWindowPMReceiveEvent.ONBOARDING_ERROR ) {
            this.closeWindow();
        }

        // User has successfully verified their email during demo flow,
        // close the window and let them continue
        if ( event === OnboardingWindowPMReceiveEvent.DEMO_EMAIL_VERIFY_SUCCESS ) {
            Tracker.track( 'dialog.demo.closed' );
            this.closeWindow();
        }

        // User has opted to continue the demo session
        // close the window and let them continue
        if ( event === OnboardingWindowPMReceiveEvent.DEMO_EMAIL_CONTINUE ) {
            Tracker.track( 'dialog.demo.closed' );
            this.closeWindow();
        }

        // User has started a purchase, show purchase started message
        if ( event === OnboardingWindowPMReceiveEvent.PURCHASE_STARTED ) {
            this.displayPurchaseStarted.next( true );
        }

        // User has completed a purchase
        // or was on a demo plan but registered
        if ( event === OnboardingWindowPMReceiveEvent.PURCHASE_SUCCESS ||
            ( event === OnboardingWindowPMReceiveEvent.CONTINUE_FREE && this.currentPlan.startsWith( 'demo' ))) {
            this.reload();
        }

        // User has try to sign up using existing email, show login page on new window
        if ( event === OnboardingWindowPMReceiveEvent.SIGN_UP_FAIL ) {
            this.setDemoUserSignIn( true );
            this.openAppInNewWindow();
        }

        // User has try to sign up using goole auth, open app in new window
        if ( event === OnboardingWindowPMReceiveEvent.SIGN_IN_SUCCESS_IN_APP_SIGN_UP ) {
            this.openAppInNewWindow();
        }

        // when user try close button on sign up flow, close the iFrame
        if ( event === OnboardingWindowPMReceiveEvent.SIGN_UP_CLOSE ) {
            Tracker.track( 'dialog.signup.closed' );
            this.closeWindow();
        }

        if ( event === OnboardingWindowPMReceiveEvent.RELOAD_APP ) {
            this.reload();
        }
    }


    /**
     * Updates onboarding data and closes the modal window.
     */
    protected updateOnboardingAndClose( data: any ) {
        this.showInAppPlans = false; // Prevent in app plans from showing up after.
        if ( Object.keys( data ).length !== 0 ) {
            const sub = this.dispatchUpdateUserOnboardingStatus( data ).pipe(
                tap(() => {
                    this.closeWindow();
                    this.redirectToRecentDiagram( data.purpose );
                }),
                catchError(() => {
                    this.closeWindow();
                    this.redirectToRecentDiagram( data.purpose );
                    return EMPTY;
                }),
            ).subscribe();
            this.subs.push( sub );
        } else {
            this.closeWindow();
            this.redirectToRecentDiagram();
        }
    }

    /**
     * This will redirect user to recent worked diagram after complete the
     * onboarding flow. If not load the template panel with relevent category
     * @param purpose string
     */
    protected redirectToRecentDiagram( purpose: string = 'popular' ) {
        if ( this.recentDiagramId ) {
            this.router.navigate([ '../', this.recentDiagramId, 'edit' ]).then( _ => {
                this.state.set( 'SelectedLeftPanel', 'none' );
            });
        } else {
            const aiUpgradeDialogCookie = this.getCookie( 'ai_upgrade_dialog' );

            // Prevent showing template panel if `ai_upgrade_dialog` cookie is there or
            // cookie has closed value as false
            // A/B test - https://github.com/creately/phoenix/issues/6154
            if ( !aiUpgradeDialogCookie || JSON.parse( aiUpgradeDialogCookie ).closed ) {
                this.showTemplatePanelWithCategory( purpose );
            }
        }
    }

    /**
     * Call show template panel method with selected template Category
     * @param purpose user onbording purpose value
     * Open viz templates section if viz_upgrade cookie is present
     * Else open section based on user selected purpose
     */
    protected showTemplatePanelWithCategory( purpose: string = 'popular' ) {
        const vizUpgradeCookie = this.getCookie( 'viz_upgrade' );
        if ( vizUpgradeCookie ) {
            this.state.set( 'TemplatesModalTemplateGroupId', TemplateTypeUrl.CreatelyViz );
        } else {
            this.state.set( 'TemplatesModalTemplateGroupId', this.resolveTemplateCategoryIds( purpose ));
        }
        this.state.set( 'TemplateOriginLocation', 'onboarding' );
        this.state.set( 'ToggleTemplatesModal', true );
    }

    /**
     * This function will execute the command that will fetch the user's onboarding status data.
     */
    protected dispatchFetchUserOnboardingStatus(): Observable<any> {
        return this.commandService.dispatch( UserCommandEvent.fetchUserOnboardingStatus ).pipe(
            last(),
            map( results => {
                if ( results && results.resultData[0]) {
                    const resultObject = JSON.parse( results.resultData[0]);
                    const onboardingData = resultObject.data.user;
                    return onboardingData;
                }
                return null;
            }),
        );
    }

    /**
     * This function will execute the command that will update the user's onboarding status data.
     */
    protected dispatchUpdateUserOnboardingStatus( data: any ): Observable<any> {
        return this.commandService.dispatch( UserCommandEvent.updateUserOnboardingStatus, {
            usage: data.usage,
            industry: data.industry,
            role: data.role,
            purpose: data.purpose,
            size: data.size,
            onboardingProgress: data.onboardingProgress,
        }).pipe(
            last(),
        );
    }

    /**
     * Checks whether onboarding page should be displayed.
     * @param data Onboarding data
     */
    protected shouldShowOnboarding( data: any ): boolean {
        // Close window if there was an API error
        if ( data.apiError ) {
            return false;

        // Close if onboarding has been completed
        } else if ( data.onboardingProgress === 'complete' ) {
            return false;

        // User has partially completed the onboarding flow
        } else if ( data.onboardingProgress && data.onboardingProgress.startsWith( 'step' )
                && data.onboardingLastUpdated ) {
            return false;

        // User has not attempted the onboarding flow
        } else {
            return true;
        }
    }

    /**
     * Get the section ID for tracking as it may differ from the keys used on the app.
     */
    protected getSectionIdForTracker( sectionId: string ) {
        if ( sectionId === OnboardingSection.Industry || sectionId === OnboardingSection.Role ||
            sectionId === OnboardingSection.Purpose ) {
            return sectionId;
        }

        switch ( sectionId ) {
            case OnboardingSection.Usage:
                return 'profile';
            case OnboardingSection.Size:
                return 'teamSize';
            default:
                return sectionId;
        }
    }

    private listenToShowPlansTag(): Subscription {
        return this.appNavConfig.listenToTag( 'post-sign-up' )
        .pipe(
            tap( data => {
                this.showInAppPlans = ( data[0] === 'show' && data[1] === 'in-app-plans' );
            }),
        )
        .subscribe();
    }

    /**
     * Open app in new window
     */
    private openAppInNewWindow() {
        window.open( AppConfig.get( 'APP_URL' ), '_blank' );
    }

    /**
     * if demo user try to sign in using email
     * demoUserSignIn in local storage set as true
     * @param isDemoUserSignIn is demo user sign in
     */
    private setDemoUserSignIn( isDemoUserSignIn: boolean ) {
        localStorage.setItem( 'demoUserSignIn', isDemoUserSignIn.toString());
    }

    /**
     * return true is demo user try to sign in
     * @returns boolean
     */
    private checkDemoUserSignIn() {
        return localStorage.getItem( 'demoUserSignIn' ) === 'true';
    }

    /**
     * map the user purpose with tempalte panel category id
     * @param purpose user purpose value which user input within onbording
     * @returns mapped template category id
     */
    private resolveTemplateCategoryIds( purpose: string ): TemplateTypeUrl {

        const teamplateCategoryMap: { [s: string]: TemplateTypeUrl } = {
            'diagrams-flowcharts': TemplateTypeUrl.DiagramsFlowcharts,
            'organise-project': TemplateTypeUrl.OrganiseProject,
            'strategy-planning': TemplateTypeUrl.StrategyPlanning,
            'brainstorm-ideate': TemplateTypeUrl.BrainstormingIdeation,
            'meetings-workshops': TemplateTypeUrl.MeetingsWorkshops,
            'research-design': TemplateTypeUrl.ResearchDesign,
            'popular': TemplateTypeUrl.Popular,
            'all': TemplateTypeUrl.All,
        };

        const newPlanName = teamplateCategoryMap[purpose];
        if ( newPlanName ) {
            return newPlanName;
        } else {
            //  as a default value retuns all category
            return TemplateTypeUrl.Popular;
        }
    }

    /**
     * Return cookie value if exists, otherwise return false
     * @param {string} name cookie name
     */
    private getCookie( name: string ) {
        const match = document.cookie.match( new RegExp( '(^| )' + name + '=([^;]+)' ));
        if ( match ) {
            return match[2];
        } else {
            return false;
        }
    }
}
