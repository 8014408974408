import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the STRING_LONG data type. Data items in shape models with the STRING_LONG
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class StringLongDataItem extends AbstractDataItem<DataType.STRING_LONG> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof StringLongDataItem.prototype.value ): IValidationError {
        if ( value === undefined ) {
            return null;
        }
        if ( typeof value === 'string' ) {
            return null;
        }
        return { message : `"${value}" is not a valid string` };
    }
}
