import { Injectable } from '@angular/core';
import { Command, ModalController, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { PlanPermManager, UpgradeDialogWindow } from 'flux-user';
import { PLAN_PERM_ERROR_MESSAGE } from '../../../base/permission/plan-perm-error-message';
import { IDialogBoxData, IShapeDefinition, PlanPermission } from 'flux-definition';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { LogicClassFactory } from 'flux-diagram-composer';
import { EMPTY, Observable } from 'rxjs';

/**
 * PasteShapesAsNew
 * This command adds given shapes to the current diagram.
 */
@Injectable()
@Command()
export class PasteShapesAsNew extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapesToBeClone: any[],
        groupsToBeClone: any[];
    };

    private SHAPE_TYPE_IMAGE = 'image';

    constructor(
        protected ds: DiagramChangeService,
        protected planPermManager: PlanPermManager,
        protected modalController: ModalController,
        protected state: StateService<any, any>,
        protected defLocator: DefinitionLocator,
    ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<any> {
        const shape = this.data.shapesToBeClone[0];
        // Cancel command if no permission to paste images
        if ( shape.type === this.SHAPE_TYPE_IMAGE
            && !this.planPermManager.check([ PlanPermission.DIAGRAM_IMPORT_IMAGE ])) {

            const dialogData = {
                ...PLAN_PERM_ERROR_MESSAGE[ PlanPermission.DIAGRAM_IMPORT_IMAGE ],
                    descriptionParams: {
                        maxUsage: this.planPermManager.getMaxUsage( PlanPermission.DIAGRAM_IMPORT_IMAGE ),
                    },
                    buttons: [
                        {
                            type: 'upgrade',
                            clickHandler: () => {},
                        },
                    ],
                    integrationContext: {
                        embedded: this.state.get( 'ApplicationIsEmbedded' ),
                        environment: this.state.get( 'PluginApp' ),
                    },
                } as IDialogBoxData;

            this.modalController.show( UpgradeDialogWindow, {
                inputs: {
                    dialogData: dialogData,
                },
            });

            return EMPTY;
        }
        this.resultData = this.data;

        if ( shape.eData ) {
            delete shape.eData;
        }

        shape.defId = 'creately.db.table';
        shape.version = 1;

        return this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
            take( 1 ),
            filter(( def: IShapeDefinition ) => !!def.logicClass ),
            switchMap(( def: IShapeDefinition ) =>
                this.defLocator.getClass( def.logicClass ).pipe(
                    tap(( logicClass: any ) => {
                        const instance = LogicClassFactory.instance.create( logicClass );
                        if (( instance as any ).buildShapeFromDataItems ) {
                            const mergeDef = { ...def, ...shape };
                            ( instance as any ).buildShapeFromDataItems( mergeDef );
                            this.changeModel.shapes[shape.id] = shape;
                        }
                    }),
                )),
        );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( PasteShapesAsNew, 'name', {
    value: 'PasteShapesAsNew',
});
