import { IPeople, IPeopleSource } from '../../../../base/ui/shape-data-editor/people-locator';
import { ITagsDataValueType } from 'flux-definition';

/**
 * Interface DataSource Model which we use in
 * dataSource shape.
 * Cyclr will send all the datasource data in this format.
 */
export interface IDataSourceModel {
    dataSourceId: string;
    title: string;
    description: string;
    tags: ITagsDataValueType[];
    people: { source: IPeopleSource, people: IPeople[] };
    commentCount: number;
    dataSource: IDataSource;
 }

/**
 * Interface DataSource data.This includes all the data
 * about that where this datasouce data belong to and this gives a
 * uniqueness to each data set.
 * We will use this for identify origin of data when data store as entity.
 */
export interface IDataSource {

    /**
     * Belong domain of the data source.
     * Eg : Github, Favor, Asana, etc.
     */
    domain: DataSourceDomain;

    /**
     * There are should be interface for each data source
     * ex - data: IDataSourceGithub | IDataSourceFavro | JiraDataSource
     */
    data: IDataSourceGithub | IDataSourceGoogleSheet;
}


/**
 * enum of data source domain.
 */
export enum DataSourceDomain {
    Github = 'github.com',
    GoogleSheets = 'google/sheets',
    M365Excel = 'm365/excel',
}

/**
 * This is the base interface for each new data sources Interface.
 * When creating new DataSourcesData Interface this should be extended to it.
 * Ex - GitHubDataSource,JiraDataSource, etc.
 */
export interface IDataSourceData<T> {
    type: T;
    dataSourceId?: string;
}

/**
 * Interface GitHub DataSource.Depend on the source of data
 * interface can be changed.So need implements interface for each data source.
 * Should implement new datasource interfaces by extending the IDataSourceData
 *
 * Ex - GitHubDataSource,JiraDataSource, etc.
 *
 *    export interface JiraDataSource extends IDataSourceData {
 *        owner: string;
 *        ticket: string;
 *        project: string;
 *    }
 */
export interface IDataSourceGithub extends IDataSourceData<GithubDataSourceTypes> {
    owner: string;
    repo: string;
    filter?: string;
}

export interface IDataSourceGoogleSheet {
    dataSourceId: string;
    rowNumber: number;
}

/**
 * enum of github data source types.
 */
export enum GithubDataSourceTypes {
    Issue = 'issue',
    PullRequest = 'pullRequest',
}
