import { Injectable } from '@angular/core';
import { Command, CommandInterfaces, Random } from 'flux-core';
import { CollaboratorType } from 'flux-diagram';
import { DataStore } from 'flux-store';
import { PrivacyLevel } from 'flux-user';
import { defaultsDeep } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { NucleusAuthentication } from '../../system/nucleus-authentication';
import { AbstractPluginCommand } from './abstract-plugin-cmd';

/**
 * When the document is created, it'll be an empty SVG unless it uses a template.
 */
const EMPTY_SVG = `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"></svg>`;

/**
 * Sends the locally saved document to the server to save it.
 */
@Injectable()
@Command()
export class CreateDocument extends AbstractPluginCommand {
    /**
     * This command implements the 'IPluginCommand' interface.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ ...AbstractPluginCommand.implements, 'IProjectCommand' ];
    }

    constructor( private auth: NucleusAuthentication, private store: DataStore ) {
        /* istanbul ignore next */
        super();
    }

    /**
     * Add default values for properties not available on data.
     */
    public prepareData(): void {
        if ( !this.data ) {
            this.data = {};
        }
        defaultsDeep( this.data, {
            id: Random.diagramId(),
            name: 'Untitled Workspace',
            type: 'diagram/def/block.js#Block',
        });
        const now = Date.now();
        this.data = {
            svg: EMPTY_SVG,
            model: this.data,
            modelStatic: {
                id: this.data.id,
                createdTime: now,
                project: this.resourceId,
                source: {
                    type: 'creately',
                    fileId: this.data.id,
                },
                privacy: {
                    level: PrivacyLevel.PUBLIC,
                    role: CollaboratorType.EDITOR,
                    updatedBy: this.auth.currentUserId,
                    updatedTime: now,
                },
                collabs: [
                    {
                        id: this.auth.currentUserId,
                        role: CollaboratorType.OWNER,
                        createdTime: now,
                        lastSeen: now,
                    },
                ],
                // TODO: implement!
                // thumbnailUrl: '',
            },
        };
    }

    /**
     * Store the created document info in the datastore after getting a success response.
     */
    public executeResult(): Observable<unknown> {
        const store = this.store.getModelStore( DiagramModel );
        return combineLatest(
            store.insert( this.data.model ),
            store.insertStatic( this.data.modelStatic ),
        );
    }
}

Object.defineProperty( CreateDocument, 'name', {
    writable: true,
    value: 'CreateDocument',
});
