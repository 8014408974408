import { Injectable } from '@angular/core';
import { AbstractModel } from 'flux-core';
import { DataType, EntityLinkType, IDataItem, IEntity,
     IEntityDataMap, IEntityDef, IEntityLink, IStyle,
     ITextStyles } from 'flux-definition';
import { DataItemFactory } from 'flux-diagram-composer';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { find, values } from 'lodash';
import { EDataRegistry } from '../edata-registry.svc';
import { IDataSource } from '../../../editor/ui/data-sources/model/data-source.model';
import { EDataModel } from './edata.mdl';

/**
 * The best way to describe  an entity is that multiple shapes can be connected to the same
 * entity.
 *
 * entity holds a subset of shape's data items.
 * the entity can have several shapes that are connected to the same entity.
 * if one shape changes the dataItems on the entity, that same change is reflected in all
 * other shapes connected to the entity.
 */
@Injectable()
export class EntityModel extends AbstractModel implements IEntity {

    /**
     * The defId of this EData model which owns it
     * Typically namespaced by eDataDefId.entityDefId
     * This may be empty upon creation until this entity is populated
     */
    public defId: string;

    public dataSource?: IDataSource;

    public entityListsIds: string[] = [];

    /**
     * set of fields
     */
    public data: { [id: string]: any };

    /**
     * data referenced in formula fields
     */
    public refData?: { [id: string]: any };

    /**
     * This will store the shape style data when the entity is created.
     * Shape can use it to add shape shape when creating new shape for entity.
     * This shape style data should not sync with other shapes or change after create entity.
     */
    public style?: {
        shape?: IStyle,
        bounds?: {
            width: number,
            height: number
            angle: number,
            defaultBounds?: {
                top: number,
                left: number,
                width: number,
                height: number,
            },
        },
    };

    /**
     * Each entity can have preferred shape context when it is created.
     * This context can be use to render shape with a specific context (e.g. '*' or 'basic'..)
     * base graphics.
     */
    public defaultShapeContext?: string;

    /**
     * When saving a basic shape as an entity this will be used when no context is provided.
     */
    public preferredShapeDef?: { id: string , version: number};

    /**
     * any transformer that will change the contents of the field
     */
    public transformers: any;

    /**
     * Last updated time from the data source.
     */
    public lastUpdated: Date;

    public texts: {
        shapeDef: string,
        text: any,
    }[] = [];

    public textStyles?: {
        [textId: string]: ITextStyles;
    };

    /**
     * List of shapes that are represent this entity
     * { docId: { shapeId: shapeDefId }[] }
     */
    public shapes: { [docId: string ]: { [shapeId: string]: string } };

    /**
     * Links the entity has to other entities.
     */
    public links?: { [ linkId: string ]: IEntityLink };

    /**
     * Are there any refChanges that are not resolved?
     * Convinence flag.
     */
    public refChangeCount: number;

    /**
     * Task references for the shape.
     * [{roleId: taskId}]
     */
    public taskMap?: {[roleId: string]: string};

    /**
     * constructor
     * @param id unique identifier for this row/set/unit of data
     */
    constructor(
        public id: string,
        /** FIXME this is being written to the DB. how can we stop this? */
        public eDefId: string ) {
        super( id );
    }


    /**
     * When the shape data is updated, it will send
     * the updated shapeData.
     *
     * @param shapeId
     */
    public shapeUpdated( shapeId: string, changes: any ) {
        // get the shape model
        // check if the changes are in a field that the entity cares about
        // update the entity
        // notify other shapes in the same doc
        // update the data source upstream

        /**
         * [data source on neutrino: find other documents that use the same datasource,
         * update the shapeModels in them. [but not this same doc]
         */
    }

    /**
     * should be called by the EDataModel to set the shape.
     * @param shape
     */
    public addShape( docId: string, shape: ShapeModel, inheritData: boolean = false ) {
        if ( !this.shapes ) {
            this.shapes = {};
        }
        if ( !this.shapes[docId ]) {
            this.shapes[docId] = {};
        }
        this.shapes[docId][shape.id] = shape.defId;
        if ( inheritData ) {
            this.populateFromShapeData( shape );
        }
    }

    /**
     * Returns the entity def for this entity.
     * Can optionally pass a shape def when it is not initialized to get
     * a def.
     * @param shapeDef
     */
    public getDef( shapeDefId?: string ): IEntityDef {
        let def;
        if ( !this.defId ) {
            // if ( !shapeDefId ) {
            //     throw Error( 'Cannot determine entity type without any Shape context' );
            // }
            def = this.getEntityDefForShapeDef( shapeDefId );
            this.defId = def.id;
        } else  {
            def = EDataRegistry.instance.getEntityDefById( this.eDefId, this.defId );
        }

        return def;
    }


    /**
     * Returns the definition name
     * @param shapeDefId
     * @returns
     */
    public getDefName ( shapeDefId?: string ): string {
        return this.getDef( shapeDefId ).name;
    }

    /**
     * Gets a compatible shapeDef for this entity given the context.
     * If multiple options exist, the last is returned.
     * @param context
     */
    public getShapeDefIdForContext( context: string = '*' ): { id: string , version: number} {
        if ( context === '*' && this.preferredShapeDef ) {
            return this.preferredShapeDef;
        }
        const def = this.getDef();
        let result;
        Object.keys( def.shapeDefs ).forEach( key => {
            if ( def.shapeDefs[key].contexts &&  def.shapeDefs[key].contexts.indexOf( context ) > -1 ) {
                result = { id: key, version: def.shapeDefs[key].version };
            }
        });
        if ( !result && def.defaultShape ) {
            result = { id: def.defaultShape.defId, version: def.defaultShape.version };
        }
        return result;
    }

    public getShapeIds( docId: string ): string[] {
        return Object.keys( this.shapes[docId]);
    }

    public getShapeDataMap( diagramId: string, shapeId: string ): IEntityDataMap[] {
        const shapeDefId = this.getShapeDefId( diagramId, shapeId );
        if ( shapeDefId ) {
            const def = this.getDef();
            if ( def && def.shapeDefs ) {
                const shapeDef = def.shapeDefs[ shapeDefId];
                if ( shapeDef ) {
                    return shapeDef.dataMap;
                }
            }
        }
        // DataMap is not a must for custom databases, so commented out throwing error
        // throw Error( `No DataMap found for ${diagramId}, ${shapeId} in ${this.id} entity` );
    }


    /**
     * Merges data values with their defintion to give a
     * return type of IDataItem[]
     * Only gives out items that have labels
     */
    public getDisplayDataItems(): IDataItem<DataType>[] {
        const def = this.getDef();
        const retItems = [];
        for ( const dataDefId in def.dataItems ) {
            const defItem = def.dataItems[dataDefId];
            if ( defItem.label && this.data ) {
                const val = this.data[dataDefId];
                const dataItem = {
                    id: dataDefId,
                    eData: this.id,
                    type: undefined,
                    def: defItem.type,
                    label: defItem.label,
                    value: val,
                };
                const di = DataItemFactory.instance.create( dataItem );
                retItems.push( di );
            }
        }
        return retItems;
    }

    /**
     * Get links to be shown on the datawindow.
     */
    public getDisplayLinks(): { [ linkId: string ]: IEntityLink } {
        return this.links;
    }

    /**
     * Adds a link to the entity.
     * @param eDataId
     * @param entityId
     * @param type
     */
    public addLink( link: IEntityLink ) {
        if ( !this.links ) {
            this.links = {};
        }
        this.links[ link.id ] = link;
    }

    /**
     * Removes an existing link in eData
     * @param eDataId
     * @param entityId
     * @param type
     */
    public removeLink ( id: string ) {
        if ( !this.links ) {
            throw Error( 'Should not call when links are not initialized' );
        }
        delete this.links[id];
    }

    /**
     * Finds the link based on search params
     * @param eDataId
     * @param entityId
     * @param type
     * @param handshake
     */
    public getLinkId( type: EntityLinkType,
                      handshake: string,
                      eDataId?: string,
                      entityId?: string,
                      diagId?: string,
                      shapeId?: string ): string {
        for ( const linkId in this.links ) {
            const item = this.links[linkId];
            if ( item.type === EntityLinkType.REFERENCE ) {
                if ( eDataId && entityId
                    && item.eDataId === eDataId
                    && item.entityId === entityId ) {
                        return linkId;
                } else if ( diagId
                            && diagId === item.diagramId
                            && shapeId
                            && item.shapeId === shapeId ) {
                        return linkId;
                }
            } else if ( item.eDataId === eDataId
                && item.entityId === entityId
                && item.type === type
                && ( handshake ? item.handshake === handshake : true )) {
                    return linkId;
            }
        }
    }

    /**
     * get the link by connector ID.
     * @param diagramId
     * @param connId
     */
    public getLinkByConnectorId( diagramId: string, connId: string ): IEntityLink {
        for ( const linkId in this.links ) {
            const item = this.links[linkId];
            if ( item.connectors && item.connectors[diagramId]
                    && Object.keys( item.connectors[diagramId]).indexOf( connId ) > -1 ) {
                        return item;
                    }
        }
    }


    /**
     * Gets the link when given the ID
     * @param linkId
     */
    public getLink( linkId: string ): IEntityLink {
        return this.links[linkId];
    }

    public getConnectedEntities( handshake: string ): string[] {
        const entities = [];
        for ( const linkId in this.links ) {
            const link = this.links[linkId];
            if ( link.type === EntityLinkType.REFERENCE ) {
                entities.push( link.entityId );
            }
        }
        return entities;
    }

    /**
     * Returns links that match the given type and handshake.
     * @param type
     * @param handshake
     */
    public getMatchingLinks( type: EntityLinkType, handshake?: string ): IEntityLink[] {
        // const xx = filter( this.links, item => item.handshake === handshake
        //     && item.type === type );

        const match = [];
        for ( const linkId in this.links ) {
            if ( this.links[linkId].type === type &&
                ( handshake === undefined || this.links[linkId].handshake === handshake )) {
                match.push( this.links[linkId]);
            }
        }

        // console.log( 'MATCESS ', xx );
        return match;
    }


    /**
     * Gets the value for the link identifier data item.
     */
    public getLinkIdDataValue(): any {
        const def = this.getDef();
        if ( def.linkIdDataItem ) {
            if ( this.data ) {
                return this.data[def.linkIdDataItem];
            }
        }
    }

    /**
     * Returns the text of the primary text model bound to
     * the shapes of this entity
     */
    public getPrimaryText(): string {
        const def = this.getDef();
        const primaryTextBoundDataItem = values( def.dataItems ).find( di => di.primaryTextBound );
        if ( primaryTextBoundDataItem ) {
            return this.data[ primaryTextBoundDataItem.id ];
        }
    }

    /**
     * Returns the text of the primary text model bound to
     * the shapes of this entity
     */
    public getDisplayText(): string {
        const def = this.getDef();
        if ( this.data && this.data[def.titleDataItem] !== undefined ) {
            return this.data[def.titleDataItem];
        }
        return this.getPrimaryText();
    }

    /**
     * this method return formula field on the same entity that refer to the given data item
     * @param dataItemId
     */
    public getDirectRefFields( dataItemId: string ) {
        if ( !this.refData ) {
            return [];
        }
        if ( !this.refData[dataItemId]) {
            return [];
        }
        return this.refData[dataItemId].refFields;
    }

    /**
     * returns formula fields that refer to given data item via a lookup reference
     * @param dataItemId
     */
    public getLookupRefFields( dataItemId: string ) {
        return this.getMatchingLinks( EntityLinkType.LOOKUP )
            .filter( l => l.refFields && l.refFields.includes( dataItemId ))
            .map( l => ({
                eDataId: l.eDataId,
                entityId: l.entityId,
                lookupId: l.handshake,
            }));
    }

    /**
     * add a reference data items to formula field
     * @param dId reference property data item id
     * @param formulaFieldId formula field id
     */
    public addRefField( dId: string, formulaFieldId: string ) {
        if ( !this.refData ) {
            this.refData = {};
        }
        if ( !this.refData[dId]) {
            this.refData[dId] = {
                refFields: [],
            };
        }
        if ( this.refData[dId].refFields.indexOf( formulaFieldId ) === -1 ) {
            this.refData[dId].refFields = this.refData[dId].refFields.concat([ formulaFieldId ]);
        }
    }

    /**
     * removes formula reference
     * @param dId reference property data item id
     * @param formulaFieldId formula field id
     */
    public removeRefField( dId: string, formulaFieldId: string ) {
        if ( this.refData[dId].refFields.length === 1 ) {
            delete this.refData[dId];
        } else {
            const refFields: string[] = this.refData[dId].refFields.slice();
            refFields.splice( refFields.indexOf( formulaFieldId ), 1 );
            this.refData[dId].refFields = refFields;
        }
    }

    /**
     * add a lookup reference data items to formula field
     * @param lookupId lookup data item id
     * @param diId reference property data item id
     * @param lookupModel lookup eData model
     * @param formulaFieldId formula field id
     */
    public addLookupRefField( lookupId: string, diId: string, lookupModel: EDataModel, formulaFieldId: string ) {
        if ( !this.refData ) {
            this.refData = {};
        }
        if ( !this.refData[lookupId]) {
            this.refData[lookupId] = {};
        }
        if ( !this.refData[lookupId][diId]) {
            this.refData[lookupId][diId] = {
                value: this.data[lookupId]
                    .map( eId => lookupModel.entities[eId].data[diId]),
                refFields: [],
            };
            const lfId = this.getRefLinkHandshake( lookupId, lookupModel );
            this.data[lookupId].forEach( entId => {
                const link = lookupModel.entities[entId]
                    .getMatchingLinks( EntityLinkType.LOOKUP, lfId )
                    .filter( l => l.entityId === this.id )[0];
                if ( !link.refFields ) {
                    link.refFields = [];
                }
                if ( !link.refFields.includes( diId )) {
                    link.refFields = link.refFields.concat([ diId ]);
                }
            });
        }
        if ( this.refData[lookupId][diId]
                .refFields.indexOf( formulaFieldId ) === -1 ) {
            this.refData[lookupId][diId].refFields = this.refData[lookupId][diId].refFields.concat([ formulaFieldId ]);
        }
    }

    /**
     * this function is getting called when referenced lookup field value is modified
     * @param lookupId lookup field id
     * @param lookupModel lookup field model
     */
    public updateLookupRefField( lookupId: string, lookupModel: EDataModel ) {
        if ( !this.refData ) {
            return;
        }
        if ( !this.refData[lookupId]) {
            return;
        }
        const entities: EntityModel[] = this.data[lookupId].map( entId => lookupModel.entities[entId]);
        for ( const propId in this.refData[lookupId]) {
            this.refData[lookupId][propId].value = entities.map( e => e.data[propId]);
        }
        const lfId = this.getRefLinkHandshake( lookupId, lookupModel );
        entities.forEach( entity => {
            const link = entity
                .getMatchingLinks( EntityLinkType.LOOKUP, lfId )
                .filter( l => l.entityId === this.id )[0];
            if ( !link ) { // this condition should not evaluate to true
                return;
            }
            if ( !link.refFields ) {
                link.refFields = [];
            }
            for ( const propId in this.refData[lookupId]) {
                if ( !link.refFields.includes( propId )) {
                    link.refFields = link.refFields.concat([ propId ]);
                }
            }
        });
    }

    /**
     * removes a lookup reference from formula field
     * @param lookupId lookup data item id
     * @param diId reference property data item id
     * @param formulaFieldId formula field id
     * @param lookupModel lookup field model
     */
    public removeLookupRefField( lookupId: string, diId: string, formulaFieldId: string, lookupModel: EDataModel ) {
        if ( this.refData[lookupId][diId].refFields.length === 1 ) {
            delete this.refData[lookupId][diId];
            const lfId = this.getRefLinkHandshake( lookupId, lookupModel );
            const links: IEntityLink[] = this.data[lookupId].map( entId => lookupModel.entities[entId]
                .getMatchingLinks( EntityLinkType.LOOKUP, lfId )
                .filter( l => l.entityId === this.id )[0]);
            if ( Object.keys( this.refData[lookupId]).length === 0 ) {
                delete this.refData[lookupId];
                links.forEach( l => delete l.refFields );
            } else {
                links.forEach( l => {
                    const refFields = l.refFields.slice();
                    refFields.splice( refFields.indexOf( diId ), 1 );
                    l.refFields = refFields;
                });
            }
        } else {
            const refFields: string[] = this.refData[lookupId][diId].refFields.slice();
            refFields.splice( refFields.indexOf( formulaFieldId ), 1 );
            this.refData[lookupId][diId].refFields = refFields;
        }
    }

    protected populateFromShapeData( shape: ShapeModel ) {
        const entityDef = this.getDef( `${shape.defId}` );
        const shapeDataDef = entityDef.shapeDefs[`${shape.defId}`];
        if ( !this.data ) {
            this.data = {};
        }
        if ( shape.data ) {
            if ( shapeDataDef && shapeDataDef.dataMap ) {
                // populate the entities dataItem values from the shape
                Object.keys( shape.data ).forEach( dataItem => {
                    const mappedEDataField = find( shapeDataDef.dataMap, item =>
                        item.dataItemId === dataItem );
                    if ( mappedEDataField ) {
                        this.data[ mappedEDataField.eDataFieldId ] = shape.data[ mappedEDataField.dataItemId ].value;
                    } else {
                        this.data[ dataItem ] = shape.data[ dataItem ].value;
                    }
                });
            } else { // if no shapeDef found -> custom def
                // populate the entities dataItem values from the shape
                Object.keys( shape.data ).forEach( key => {
                    this.data[ key ] = shape.data[ key ].value;
                });
            }
        }
        if ( shape.taskMap && Object.keys( shape.taskMap ).length > 0 ) {
            if ( !this.taskMap ) {
                this.taskMap = {};
            }
            Object.assign( this.taskMap, shape.taskMap );
        }
    }

    /**
     * Entities can have multiple possible shape defs.
     * @param shapeDefId
     */
    protected getEntityDefForShapeDef( shapeDefId: string ): IEntityDef {
        return EDataRegistry.instance.getEntityDefByShapeId( shapeDefId, this.defId );
    }


    /**
     * Gets the defId for the shape tat's defined.
     * @param diagramId
     * @param shapeId
     */
    protected getShapeDefId( diagramId: string, shapeId: string ) {
        if ( this.shapes[diagramId] && this.shapes[diagramId][shapeId]) {
            return this.shapes[diagramId][shapeId];
        }
    }

    private getRefLinkHandshake( lookupId: string, lookupModel: EDataModel ) {
        const dataDef = lookupModel.getEntityDataItems( this.id );
        if ( dataDef && dataDef[lookupId]) {
            const reversedId = lookupId.split( '' ).reverse().join( '' );
            const reversedField = dataDef[reversedId];
            const lf = dataDef[lookupId];
            if ( lf.isTypeBound && reversedField?.isTypeBound && reversedField.type === DataType.LOOKUP ) {
                return reversedId;
            }
        }
        return lookupId;
    }
}
