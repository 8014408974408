import { createFuture, Future } from '@creately/future';
import { invert, modify } from '@creately/mungo';
import { Proxied, Sakota } from '@creately/sakota';
import { ClassUtils, EventIdentifier, Flags, IModifier, Logger } from 'flux-core';
import { EventCollector } from 'flux-core';
import { cloneDeep, intersection, size } from 'lodash';
import { BehaviorSubject, concat, from, merge, Observable, Observer, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { EDataModel } from '../model/edata.mdl';
import { EntityModel } from '../model/entity.mdl';
import { IEDataChange, IEmittedEDataChange, IEmittedEntityChange } from './edata-change.i';
import { EDataFactory } from './edata-factory';
import { IEDataSplitModifier, splitModifier } from './edata-split-modifier';


/**
 * IEDataLocatorOptions
 * Contains input data and services needed by the stored diagram locator.
 */
export interface IEDataLocatorOptions<
    DT extends EDataModel,
    ET extends EntityModel,
> {
    changes: Observable<IEDataChange>;
    factory: EDataFactory<DT, ET>;
}


/**
 * EDataLocator
 * This diagram locator can be used to get commonly used information about the diagram
 * and shapes. This only includes changes which are already stored in the database.
 *
 * The diagram data is split into 3 levels:
 *  - eData0: plain javascript objects
 *  - eData1: empty models with correct types
 *  - eData2: models with deserialized data (proxies eData1)
 *
 * This is done so that parts of eData2 can be rebuilt without rebuilding the model.
 * The primary objective here is to maintain good performance and it is done in implementation
 * level as well as in design level.
 *
 */
export class EDataLocator<DT extends EDataModel, ET extends EntityModel> {
    /**
     * Used to create or apply changes to shape or diagram models.
     */
    private factory: EDataFactory<DT, ET>;

    /**
     * Indicates whether the diagram locator is active.
     */
    private started = new BehaviorSubject<boolean>( false );

    /**
     * The subscription for the main process of the diagram locator.
     */
    private process: Subscription;

    /**
     * Indicates that the diagram data is being updated at the moment.
     */
    private working?: Future<unknown>;

    /**
     * Counter used to generate unique ids for subscription observers.
     */
    private counter = 0;

    /**
     * A future which is used to identify when the diagram model is ready.
     */
    private oninit = createFuture();

    /**
     * Hold observers to emit new changes to them.
     */
    private observers: {
        eData: { [id: number]: Observer<IEmittedEDataChange<DT, ET>> },
        diagram: { [diagramId: string]: { [id: number]: Observer<IEmittedEntityChange<ET>> }},
        inserts: { [id: number]: Observer<ET[]> },
        entities: { [entityId: string]: { [id: number]: Observer<IEmittedEntityChange<ET>> }},
    } = { eData: {}, diagram: {}, inserts: {}, entities: {}};

    /**
     * The diagram data is stored in multiple levels.
     *      Level 0: Plain Data
     *      Level 1: Model Only
     *      Level 2: Model and Data
     */
    private eData0: any = {};
    private eData1: DT = null;
    private eData2: Proxied<DT> = null;

    // Public Methods
    // --------------

    /**
     * Starts listening to changes and starts emitting changes when shapes change.
     * Returned observable will emit once and complete when the diagram model is ready.
     */
    public start( options: IEDataLocatorOptions<DT, ET> ): Observable<unknown> {
        ClassUtils.disableMethod( this, 'start', 'The start method is called multiple time on EDataLocator.' );
        this.factory = options.factory;
        this.started.next( true );
        this.process = options.changes.pipe(
            concatMap( change => this.processChanges( change )),
        ).subscribe();
        return from( this.oninit );
    }

    /**
     * Stops all locator activities.
     */
    public stop(): void {
        [
            'start',
            'stop',
            'getEDataModelOnce',
            'getEDataModel',
            'getDiagramName',
            'getEDataChanges',
            'getEntityOnce',
            'getEntityModel',
            'getAddedEntities',
            'getCurrentEntities',
            'getEntityChanges',
            'executeWhenIdle',
        ].forEach( name => {
            ClassUtils.disableMethod( this, name, `EDataLocator.${name}() is called after stopping.` );
        });
        if ( this.started.value === true ) {
            this.process.unsubscribe();
            this.started.next( false );
            this.closeSubscriptions();
        }
    }

    /**
     * Emits the diagram once and completes the subscription immediately.
     */
    public getEDataModelOnce(): Observable<DT> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getEDataModelOnce()),
            );
        }
        return Observable.create(( o: Observer<DT> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.eData2 );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the diagram immediately and whenever it changes (without modifiers).
     */
    public getEDataModel(): Observable<DT> {
        return merge(
            this.getEDataModelOnce(),
            this.getEDataChanges().pipe(
                map( value => value.model ),
            ),
        );
    }

    /**
     * Emits the diagram ( model ) and changes ( modifier ) immediately when subscribed and whenever it changes
     */
     public getEDataChangesWithCurrent(): Observable<{ model: DT, modifier: IModifier, reverter?: IModifier, source: 'init' | 'insert' | 'change', ctx?: any }> {
        return merge(
            this.getEDataModelOnce().pipe(
                map(( model: any ) => ({ model, modifier: model.__sakota__.getChanges()
                    , source: 'init' })),
            ),
            this.getEDataChanges().pipe(
                map(({ model, modifier, source, reverter, ctx }) => {
                    if ( source === 'insert' ) {
                        return {
                            model,
                            modifier: ( model as Proxied<DT> ).__sakota__.getChanges(),
                            source,
                        };
                    }
                    return { model, modifier, source, reverter, ctx };
                }),
            ),
        );
    }

    /**
     * Emits only when there are new changes to the eDataModel (after subscribing).
     */
    public getEDataChanges(): Observable<IEmittedEDataChange<DT, ET>> {
        return Observable.create(( o: Observer<IEmittedEDataChange<DT, ET>> ) => {
            const id = this.createUniqueId();
            this.observers.eData[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'EDataLocator: edata Changes:', size( this.observers.eData ));
            }
            return () => {
                delete this.observers.eData[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'EDataLocator: edata Changes:', size( this.observers.eData ));
                }
            };
        });
    }

    /**
     * Emits the shape once and completes the subscription immediately.
     */
    public getEntityOnce( entityId: string ): Observable<ET> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getEntityOnce( entityId )),
            );
        }
        return Observable.create(( o: Observer<ET> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( this.eData2 && this.eData2.entities[entityId] as any || null );
                    o.complete();
                }
            });
            return () => {};
        });
    }

    /**
     * Emits the shape when it's available and whenever it changes (without modifiers)
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        At the moment, it emits twice when created, and then continue normal.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getEntityModel( entityId: string ): Observable<ET> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getEntityModel( entityId )),
            );
        }
        const initial = this.getEDataModel().pipe(
            map( diagram => diagram.entities[entityId] as ET ),
            filter( entity => Boolean( entity )),
            take( 1 ),
        );
        const changes = this.getEntityChanges( entityId ).pipe(
            map( value => value.model ),
        );
        return concat( initial, changes );
    }

    /**
     * Emits only when a new shape is added to the diagram model.
     */
    public getAddedEntities(): Observable<ET[]> {
        return Observable.create(( o: Observer<ET[]> ) => {
            const id = this.createUniqueId();
            this.observers.inserts[ id ] = o;
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'EDataLocator: Added Entities:', size( this.observers.inserts ));
            }
            return () => {
                delete this.observers.inserts[ id ];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    Logger.debug( 'EDataLocator: Added Entities:', size( this.observers.inserts ));
                }
            };
        });
    }

    /**
     * Emits when a change is there for an entity that has a shape in the given diagram
     * @param diagramId
     */
    public getEntityChangesForDiagram( diagramId: string ): Observable<IEmittedEntityChange<ET>> {
        return Observable.create(( o: Observer<IEmittedEntityChange<ET>> ) => {
            const id = this.createUniqueId();
            if ( !this.observers.diagram[ diagramId ]) {
                this.observers.diagram[ diagramId ] = { [id]: o };
            } else {
                this.observers.diagram[ diagramId ][id] = o;
            }
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'EDataLocator: entity Changes:',
                            diagramId, size( this.observers.diagram[diagramId]));
            }
            return () => {
                delete this.observers.diagram[ diagramId ][id];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    // tslint:disable-next-line: max-line-length
                    Logger.debug( 'EDataLocator: entity Changes:', diagramId, size( this.observers.diagram[diagramId]));
                }
                if ( Object.keys( this.observers.diagram[ diagramId ]).length === 0 ) {
                    delete this.observers.diagram[ diagramId ];
                }
            };
        });
    }

    /**
     * Emits all entities
     */
    public getCurrentEntities(): Observable<ET[]> {
        return this.getEDataModel().pipe(
            distinctUntilChanged(),
            // tap( ed => { console.log( '--- EDataLocator.getCurrentEntities', ed ); }),
            map( edata => {
                const entities: ET[] = [];
                for ( const id in edata.entities ) {
                    entities.push( edata.entities[id] as ET );
                }
                return entities;
            }),
        );
    }

    /**
     * Emits only when there are new changes to the diagram (after subscribing).
     * The subscription will complete automatically if the shape gets deleted.
     * FIXME: Handle the case where user subscribes before creating the shape.
     *        If the shape never gets created, the observer will wait indefinitely.
     */
    public getEntityChanges( entityId: string ): Observable<IEmittedEntityChange<ET>> {
        return Observable.create(( o: Observer<IEmittedEntityChange<ET>> ) => {
            const id = this.createUniqueId();
            if ( !this.observers.entities[ entityId ]) {
                this.observers.entities[ entityId ] = { [id]: o };
            } else {
                this.observers.entities[ entityId ][id] = o;
            }
            /* istanbul ignore if */
            if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                Logger.debug( 'EDataLocator: Shape Changes:',
                            entityId, size( this.observers.entities[entityId]));
            }
            return () => {
                delete this.observers.entities[ entityId ][id];
                /* istanbul ignore if */
                if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                    // tslint:disable-next-line: max-line-length
                    Logger.debug( 'EDataLocator: Shape Changes:', entityId, size( this.observers.entities[entityId]));
                }
                if ( Object.keys( this.observers.entities[ entityId ]).length === 0 ) {
                    delete this.observers.entities[ entityId ];
                }
            };
        });
    }

    /**
     * Execute given function when the locator is idle
     * NOTE: This is exposed for other locators, do not use this function.
     */
    public executeWhenIdle( fn: () => void ): void {
        if ( this.working ) {
            this.working.then( fn );
        } else {
            fn();
        }
    }

    // Special Methods
    // ---------------

    /**
     * Emits the diagram raw data once and completes the subscription immediately.
     */
    public getRawEDataModelOnce(): Observable<object> {
        if ( this.oninit ) {
            return from( this.oninit ).pipe(
                switchMap(() => this.getRawEDataModelOnce()),
            );
        }
        return Observable.create(( o: Observer<DT> ) => {
            this.executeWhenIdle(() => {
                if ( !o.closed ) {
                    o.next( Sakota.create( this.eData0 ));
                    o.complete();
                }
            });
            return () => {};
        });
    }

    // Internal Processes
    // ------------------

    /**
     * Processes the edata change.
     */
    private processChanges( _change: IEDataChange ): Promise<unknown> {
        this.working = createFuture();
        const change: IEDataChange = cloneDeep( _change );
        const split = splitModifier( change.modifier );
        const added = this.addedEntities( split, this.eData0 );
        this.sanitizeChange( change, split, added );
        if ( change.source === 'change' ) {
            change.reverter = invert( this.eData2, change.modifier );
        }
        return this.applyChanges( change, split )
            .then(() => {
                this.working.resolve( null );
                this.working = null;
                if ( this.oninit ) {
                    this.oninit.resolve( null );
                    this.oninit = null;
                }
                EventCollector.log({
                    message: EventIdentifier.EDATA_LOCATOR_CHANGE_PROCESSED,
                    change, added,
                });
                this.emitChanges( change, split, added );
            });
    }

    /**
     * Removes invalid data from given change
     */
    private sanitizeChange( change: IEDataChange, split: IEDataSplitModifier, added: string[]): void {
        for ( let i = added.length - 1; i >= 0; --i ) {
            const entityId = added[i];
            const entityMod = split.entities[entityId];
            if ( !this.isValidEntityChange( entityMod )) {
                const id = this.eData0?.id || split.edata?.$set?.id;
                Logger.error( `Found broken entity data: ignoring entity ${entityId} in edata ${id}`,
                    JSON.stringify( entityMod ));
                added.splice( i, 1 );
                delete split.entities[entityId];
                for ( const op of Object.keys( change.modifier )) {
                    const opmod = change.modifier[op];
                    for ( const key of Object.keys( opmod )) {
                        if ( key === 'entities' ) {
                            delete opmod[key][entityId];
                        }
                        if ( key.startsWith( `entities.${entityId}` )) {
                            delete opmod[key];
                        }
                    }
                }
            }
        }
    }


    /**
     * Checks whether the given entity modifier is valid or not (for added entities).
     */
    private isValidEntityChange( modifier: IModifier ): boolean {
        if ( !this.hasPropertiesSet( modifier, [ 'id', 'defId', 'eDefId', 'shapes' ])) {
            // check if its data we are setting
            const modPaths = Object.keys( modifier.$set );

            let isData = false;
            modPaths.forEach( path => {
                if (    path.match( /\.data$/ ) || path.match( /\.shapes\./ )) {
                    isData = true;
                }
            });
            return isData;
        }
        return true;
    }

    /**
     * Checks whether the modifier sets all given properties, returns false if any of them are missing.
     */
    private hasPropertiesSet( modifier: IModifier, paths: string[]): boolean {
        if ( !modifier.$set ) {
            return false;
        }
        for ( const path of paths ) {
            // TODO: support nested paths when needed
            if ( modifier.$set[ path ] === undefined ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Applies changes to the model.
     */
    private applyChanges( change: IEDataChange, split: IEDataSplitModifier ): Promise<unknown> {
        modify( this.eData0, change.modifier );
        if ( this.shouldResetEData( split.edata )) {
            // NOTE: eData2 should be available before creating eData1 shapes as eData2
            //       is passed in as a parameter to the createShape method on the factory.
            return this.createEData1()
                .then(() => this.createEData2())
                .then(() => this.createEData1Entities())
                .then(() => this.applyEData2Changes());
        }
        const promises: Promise<any>[] = [];
        if ( split.edata ) {
            promises.push( this.updateEData2( split.edata ));
        }
        if ( split.entities ) {
            for ( const entityId in split.entities ) {
                const entitymod = split.entities[entityId];
                if ( this.shouldDeleteEntity( entityId, entitymod )) {
                    if ( !this.eData1.entities[entityId]) {
                        continue;
                    }
                    promises.push(
                        this.removeEntity1( entityId )
                            .then(() => this.removeEntity2( entityId )),
                    );
                } else if ( this.shouldResetEntity( entityId, entitymod )) {
                    promises.push(
                        this.create1( entityId )
                            .then(() => this.createEntity2( entityId, this.getAppliedEntityModifier( entityId ))),
                    );
                } else {
                    promises.push( this.updateEntity2( entityId, entitymod ));
                }
            }
        }
        return Promise.all( promises );
    }

    /**
     * Emits new changes to observers.
     * TODO: check whether it is necessary to include changes caused by def changes.
     */
    private emitChanges( change: IEDataChange, split: IEDataSplitModifier, inserts: string[]): void {
        // console.log( '-- EDataLocator.emitChanges', change, split );

        if ( split.entities ) {
            const observerDiagIds = Object.keys( this.observers.diagram );
            const diagsToEmit = {};
            for ( const entityId in split.entities ) {
                const entity = this.eData2.entities[entityId] as ( ET );
                const modifier = split.entities[entityId];

                // collect diagrams to emit to if there are observers there
                if ( entity  && entity.shapes && this.hasDataChanges( entity, modifier )) {
                    const diagramIds = Object.keys( entity.shapes );
                    const toEmit = intersection( observerDiagIds, diagramIds );
                    for ( const dId in toEmit ) {
                        if ( !diagsToEmit[toEmit[dId]]) {
                            diagsToEmit[toEmit[dId]] = [];
                        }
                        diagsToEmit[toEmit[dId]].push({ ...change, modifier, model: entity });
                    }
                }

                // emit the entity itself
                const entityObservers = this.observers.entities[entityId];
                if ( !entityObservers ) {
                    continue;
                }
                if ( entity ) {
                    for ( const id in entityObservers ) {
                        // NOTE: use the entity modifier instead of full modifier
                        const value = { ...change, modifier, model: entity };
                        entityObservers[id].next( value );
                    }
                } else {
                    for ( const id in entityObservers ) {
                        entityObservers[id].complete();
                        delete entityObservers[id];
                    }
                    /* istanbul ignore if */
                    if ( Flags.get( 'DEBUG_OBSERVER_COUNTS' )) {
                        Logger.debug( 'EntityLocator: Entity Deleted:', entityId );
                    }
                    delete this.observers.entities[entityId];
                }
            }

            // emit for all diagrams listening
            // entity changes are emmitted as an array
            for ( const diagId in diagsToEmit ) {
                const diagramObservers = this.observers.diagram[diagId];
                for ( const id in diagramObservers ) {
                    diagramObservers[id].next( diagsToEmit[diagId]);
                }
            }

        }
        const insertedEntities = this.getEntityModels( inserts, this.eData2 );
        const eDataObservers = this.observers.eData;
        for ( const id in eDataObservers ) {
            const value = { ...change, model: this.eData2, split, added: insertedEntities };
            eDataObservers[id].next( value );
        }
        const insertsObservers = this.observers.inserts;
        if ( insertedEntities.length ) {
            for ( const id in insertsObservers ) {
                insertsObservers[id].next( insertedEntities );
            }
        }
    }

    /**
     * Filter out invalid changes for change monitoring
     * @param change
     */
    private hasDataChanges( entity: ET, modifier: IModifier ): boolean {
        const filterPath = /(data.)|(links)|(shapes.)/;
        if ( modifier.$set ) {
            if ( modifier.$set.data ) {
                return true;
            }
            const fNames = Object.keys( modifier.$set ).filter( key => filterPath.test( key ));
            if ( fNames.length > 0 ) { // leave room for unset to be true
                return true;
            }
        }
        if ( modifier.$unset ) {
            const fNames = Object.keys( modifier.$unset ).filter( key => filterPath.test( key ));
            return fNames.length > 0;
        }
        return false;

    }

    // Diagram 0 - diagram data as plain javascript objects and arrays
    // ---------------------------------------------------------------

    // NOTE: add any helper functions related to level 0 diagrams

    // Diagram 1 - diagram structure with correct diagram type and shape types
    // -----------------------------------------------------------------------

    /**
     * Check whether the edata model has to be created or reset - level 1
     */
    private shouldResetEData( modifier: IModifier ): boolean {
        if ( !this.eData1 ) {
            return true;
        }
        for ( const op in modifier ) {
            if ( modifier[op].type !== undefined ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check whether the shape model has to be created or reset - level 1
     */
    private shouldResetEntity( entityId: string, modifier: IModifier ): boolean {
        if ( !this.eData1.entities[entityId]) {
            return true;
        }
        for ( const op in modifier ) {
            if ( modifier[op].defId !== undefined || modifier[op].version !== undefined ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check whether the shape model has to be removed - level 1
     */
    private shouldDeleteEntity( shapeId: string, modifier: IModifier ): boolean {
        return ( modifier.$unset as any ) === true;
    }

    /**
     * Create the edata model - level 1
     */
    private createEData1(): Promise<unknown> {
        return this.factory.createEData( this.eData0 )
            .then( model => this.eData1 = model as any );
    }

    /**
     * Create entity models for the model - level 1
     */
    private createEData1Entities(): Promise<unknown> {
        const promises: Promise<any>[] = [];
        for ( const shapeId in this.eData0.entities ) {
            promises.push( this.create1( shapeId ));
        }
        return Promise.all( promises );
    }

    /**
     * Create a entity model - level 1
     */
    private create1( entityId: string ): Promise<unknown> {
        return this.factory.createEntity( this.eData0.entities[entityId])
            .then( model => this.eData1.entities[entityId] = model );
    }

    /**
     * Remove a entity model - level 1
     */
    private removeEntity1( entityId: string ): Promise<unknown> {
        return this.factory.removeEntity( this.eData1, entityId );
    }

    // EData 2 - edata model with correct type and data (proxies eData1)
    // -----------------------------------------------------------------------

    /**
     * Create the edata model - level 2
     */
    private createEData2(): Promise<unknown> {
        this.eData2 = Sakota.create( this.eData1 );
        return Promise.resolve( null );
    }

    /**
     * Applies current changes to the edata model - level 2
     */
    private applyEData2Changes(): Promise<unknown> {
        const promises: Promise<any>[] = [];
        const split = splitModifier( this.getAppliedEDataModifier());
        if ( split.edata ) {
            promises.push( this.factory.updateEData( this.eData2, split.edata ));
        }
        if ( split.entities ) {
            for ( const entityId in split.entities ) {
                promises.push( this.factory.updateEntity(
                    this.eData2.entities[entityId] as any,
                    split.entities[entityId],
                    this.eData2,
                    { $set: this.eData0.entities[ entityId ] },
                ));
            }
        }
        return Promise.all( promises );
    }

    /**
     * Update the diagram model - level 2
     */
    private updateEData2( modifier: IModifier ): Promise<unknown> {
        return this.factory.updateEData( this.eData2, modifier );
    }

    /**
     * Create a shape model - level 2
     */
    private createEntity2( entityId: string, modifier: IModifier ): Promise<unknown> {
        ( this.eData2.entities as Proxied<object> ).__sakota__.reset( entityId );
        return this.updateEntity2( entityId, { $set: this.eData0.entities[ entityId ] });
    }

    /**
     * Update a shape model - level 2
     */
    private updateEntity2( entityId: string, modifier: IModifier ): Promise<unknown> {
        const entity = this.eData2.entities[entityId] as Proxied<ET>;
        return this.factory.updateEntity(
            entity,
            modifier,
            this.eData2,
            { $set: this.eData0.entities[ entityId ] },
        );
    }

    /**
     * Remove a shape model - level 2
     */
    private removeEntity2( entityId: string ): Promise<unknown> {
        const entities = this.eData2.entities as Proxied<typeof EDataModel.prototype.entities>;
        entities.__sakota__.reset( entityId );
        return Promise.resolve( null );
    }

    // Other Helpers - helper functions which are not directly related to the logic
    // ----------------------------------------------------------------------------

    /**
     * Returns a unique number each time this method is called. Can be used as unique ids.
     */
    private createUniqueId(): number {
        return ++this.counter;
    }

    /**
     * Returns ids of shapes which are not available on the diagram locator ( new shapes ).
     * NOTE: when shapes are deleted it'll have { $unset: true } as the modifier, ignoring it.
     */
    private addedEntities( split: IEDataSplitModifier, diagram: DT ): string[] {
        const added = [];
        if ( split.entities ) {
            for ( const entityId in split.entities ) {
                if (
                    ( !diagram || !diagram.entities || !diagram.entities[entityId]) &&
                    ( !split.entities[entityId].$unset || split.entities[entityId].$unset !== ( true as any ))
                ) {
                    added.push( entityId );
                }
            }
        }
        return added;
    }

    /**
     * Returns models of shapes with given ids.
     */
    private getEntityModels( entityIds: string[], diagram: DT ): ET[] {
        const entities = [];
        for ( const entityId of entityIds ) {
            entities.push( diagram.entities[entityId]);
        }
        return entities;
    }

    /**
     * Returns all changes applied to the diagram as a modifier.
     * FIXME: granularity is not correct. Unable to fix this without changing how this is stored.
     */
    private getAppliedEDataModifier(): IModifier {
        return { $set: this.eData0 };
    }

    /**
     * Returns all changes applied to the shape as a modifier.
     * FIXME: granularity is not correct. Unable to fix this without changing how this is stored.
     */
    private getAppliedEntityModifier( entityId: string ): IModifier {
        return { $set: this.eData0.entities[entityId] };
    }

    /**
     * Closes all active subscriptions ( calls the complete on observers ).
     */
    private closeSubscriptions(): void {
        for ( const id in this.observers.eData ) {
            this.observers.eData[id].complete();
        }
        for ( const id in this.observers.inserts ) {
            this.observers.inserts[id].complete();
        }
        for ( const entityId in this.observers.entities ) {
            for ( const id in this.observers.entities[entityId]) {
                this.observers.entities[entityId][id].complete();
            }
        }
    }
}
