import { Injectable } from '@angular/core';
import { Proxied } from '@creately/sakota';
import { TranslateService } from '@ngx-translate/core';
import {
    AbstractNotification, Command,
    EventSource, MapOf, NotificationType, NotifierController,
} from 'flux-core';
import { Subject, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractShapeModel } from '../../../../../../libs/flux-diagram-composer/src';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { Notifications } from '../../../base/notifications/notification-messages';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Ask user if they want to remove the link associate with the connector shape.
 * data: {
 *     shapeIds - ids of the shape that needs to be removed
 *     selected - To indicate whether to delete the selected shapes or given ids.
 *                Delete the selected one if this is set to true or not given in the data
 * }
 */
@Injectable()
@Command()
export class RemoveEntityDialog extends AbstractDiagramChangeCommand {

    constructor(
        cs: DiagramChangeService,
        private ell: EDataLocatorLocator,
        private notifierController: NotifierController,
        private translate: TranslateService,
    ) {
        super( cs )/* istanbul ignore next */;
    }

    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    public execute() {
        if ( this.eventData.source !== EventSource.USER ) {
            return of( true );
        }
        const changes = ( this.changeModel.shapes as Proxied<MapOf<AbstractShapeModel>> ).__sakota__.getChanges();
        const shapeIds = Object.keys( changes.$unset || {}).filter( key => key.indexOf( '.' ) === -1 );
        const refShapes = this.changeModel.__sakota__.getTarget().shapes;
        const shapes = shapeIds.map( id => refShapes[id]).filter( s => !s.isConnector() && s.eData );
        const entityIdsByEDataId = {};
        shapes.forEach( s => {
            if ( !entityIdsByEDataId[s.eDataId]) {
                entityIdsByEDataId[s.eDataId] = {};
            }
            entityIdsByEDataId[s.eDataId][s.entityId] = true;
        });
        for ( const eDataId in entityIdsByEDataId ) {
            entityIdsByEDataId[eDataId] = Object.keys( entityIdsByEDataId[eDataId]);
        }
        return forkJoin(
            Object.keys( entityIdsByEDataId ).map( eDataId => this.ell.getEDataModel( eDataId ))).pipe(
            switchMap( eDataModels => {
                const removables: MapOf<string[]> = {};
                eDataModels.forEach( eDataModel => {
                    const entities = entityIdsByEDataId[eDataModel.id]
                        .map( eId => eDataModel.entities[eId])
                        .filter( e => Object.keys( e.shapes ).length > 1 ||
                            Object.keys( e.shapes[this.resourceId]).length > 1 );
                    if ( entities.length > 0 ) {
                        removables[eDataModel.id] = entities.map( e => e.id );
                    }
                });
                if ( Object.keys( removables ).length > 0 ) {
                    const s = new Subject<boolean>();
                    this.showRemoveLinkDialog( removables, s );
                    return s;
                }
                return of( true );
            }),
        );
    }

    protected showRemoveLinkDialog( removables: MapOf<string[]>, s: Subject<boolean> ) {
        let placeholder = 'REMOVE_ENTITY';
        if ( Object.keys( removables ).length > 1 || Object.values( removables )[0].length > 1 ) {
            placeholder = 'REMOVE_ENTITIES';

        }
        const translate = this.translate.instant.bind( this.translate );
        const notificationData = {
            id: Notifications.REMOVE_ASSOCIATED_ENTITY,
            component: AbstractNotification,
            type: NotificationType.Neutral,
            collapsed: false,
            options: {
                inputs: {
                    heading: translate( `NOTIFICATIONS.EDATA.${placeholder}.HEADING` ),
                    description: translate( `NOTIFICATIONS.EDATA.${placeholder}.DESCRIPTION` ),
                    autoDismiss: false,
                    buttonOneText: translate( `NOTIFICATIONS.EDATA.${placeholder}.BUTTON_ONE_TEXT` ),
                    buttonTwoText: translate( `NOTIFICATIONS.EDATA.${placeholder}.BUTTON_TWO_TEXT` ),
                    onDismiss: () => {
                        s.error( new Error( 'User Opted not to delete' ));
                    },
                    buttonOneAction: () => {
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_ENTITY );
                        s.complete();
                    },
                    buttonTwoAction: () => {
                        if ( !this.eventData.ctx ) {
                            this.eventData.ctx = {};
                        }
                        this.eventData.ctx.__removeEntities = removables;
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_ENTITY );
                        s.complete();
                    },
                },
            },
        };
        return this.notifierController.show( Notifications.REMOVE_ASSOCIATED_ENTITY, notificationData.component,
            notificationData.type, notificationData.options, notificationData.collapsed );
    }

}

Object.defineProperty( RemoveEntityDialog, 'name', {
    value: 'RemoveEntityDialog',
});
