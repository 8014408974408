import { Command, AppConfig, ImageLoader, StateService, Logger, NotifierController, AbstractCommand } from 'flux-core';
import { Observable, empty, defer, concat, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator, ITypedDiagramLocator } from '../locator/diagram-locator-locator';
import { DiagramSVGView } from 'flux-diagram-composer';
import { switchMap, tap, take } from 'rxjs/operators';
import { DiagramModel } from '../model/diagram.mdl';
import { fromPromise } from 'rxjs/internal-compatibility';
import saveAs from 'file-saver';
import { NotificationMessages, Notifications } from '../../notifications/notification-messages';
import { TranslateService } from '@ngx-translate/core';

/**
 * This command is used to generate a PDF document by inserting the current
 * diagrams svg into it.
 *
 * It uses the creately export-utils API to perform the document generation.
 * The exported PDF data is retained in resultData.data property of this command.
 * @since   2019-10-10
 * @author  Ramishka
 */
@Injectable()
@Command()
export class GeneratePdf extends AbstractCommand {


    /**
     * Wait maximum 10 minutes for the pdf to be generated.
     */
    protected maxRetries = 120;
    protected intervalInSeconds = 5;

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
    protected notificationMessages: NotificationMessages;

    constructor( private ll: DiagramLocatorLocator,
                 private resources: ImageLoader,
                 private state: StateService<any, any>,
                 protected translate: TranslateService,
                 protected notifierController: NotifierController ) {
                    super();
                    this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'PDF_EXPORT_URL' );
    }

    /**
     * Fetches the svg string and attaches to data
     */
    public prepareData(): any {
        this.data = {};
        const locator = this.ll.forCurrent( true );
        const svgview = this.createDiagramSVGView( locator );

        return locator.getDiagramOnce().pipe(
            switchMap(( diagram: DiagramModel ) => concat(
                defer(() => svgview.populateDiagram()),
                defer(() => svgview.toSvgFrames().pipe(
                    take( 1 ),
                    tap( svg => {
                        this.data.data = svg;
                        this.data.id = diagram.id;
                        this.data.lastChangeId = diagram.lastChangeId;
                        this.data.type = 'application/pdf';
                        this.data.name = diagram.name + '.pdf';
                    }),
                )),
            )),
        );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        this.showExportStartedNotification();
        fromPromise( this.getPdfData()).subscribe( data => {
            if ( data.data ) {
                this.closeExportStartedNotification();
                this.saveFile( data.data, this.data.name );
            } else if ( data.url ) {
                const promise = this.retryFetchingLink( data.url, this.maxRetries, this.intervalInSeconds )
                    .then( blob => {
                        if ( blob?.type === this.data.type ) {
                            this.closeExportStartedNotification();
                            this.saveFile( blob, this.data.name );
                        } else {
                            Logger.error( 'Unable to obtain a pdf file after maximum retries.' );
                        }
                    })
                    .catch( error => {
                        Logger.error( 'Error in retryFetchingLink:', error );
                    });
                fromPromise( promise ).subscribe();
            }
        });
        return empty();
    }

    /* istanbul ignore next */
    protected saveFile( data: any, name: string ) {
        saveAs( data, name );
    }

    protected async getPdfData(): Promise<any> {
        const res = await fetch( this.httpUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: this.data.data,
                id: this.data.id,
                lastChangeId: this.data.lastChangeId,
            }),
            method: 'POST',
        });
        const json = await res.json();
        return json;
    }

    protected async fetchFileBlob ( url ) {
        const response = await fetch( url );
        return await response.blob();
    }

    protected async retryFetchingLink( url: string, maxRetries: number, intervalInSeconds: number ) {
        const expectedType = 'application/pdf';
        let retries = 0;
        let fileType;
        let blob;

        while ( retries < maxRetries && fileType !== expectedType ) {
            Logger.info( `Fetching PDF attempt ${retries + 1}...` );
            try {
                blob = await this.fetchFileBlob( url );
                fileType = blob.type;
            } catch ( error ) {
                Logger.error( 'Error fetching PDF link:', url );
            }
            if ( fileType !== expectedType ) {
                blob = null;
                await new Promise( resolve => setTimeout( resolve, intervalInSeconds * 1000 ));
                retries++;
            }
        }
        return blob;
    }

    protected showExportStartedNotification() {
        const notificationData = this.notificationMessages
            .getNotificationMessage( Notifications.PDF_EXPORT_STARTED );
        this.notifierController.show( Notifications.PDF_EXPORT_STARTED,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected closeExportStartedNotification() {
        this.notifierController.hide( Notifications.PDF_EXPORT_STARTED );
    }

    /**
     * Create a DiagramSVGView instance with the current diagram locator
     */
    protected createDiagramSVGView( locator: ITypedDiagramLocator ): DiagramSVGView {
        return new DiagramSVGView( of( locator ), this.resources, this.state );
    }
}

Object.defineProperty( GeneratePdf, 'name', {
    value: 'GeneratePdf',
});
