import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractCommand, Command } from './abstract.cmd';
import { CommandError } from './error/command-error';
import { CommandHttpError } from './error/command-http-error';

/**
 * These are the available request methods which
 * can be used when making a http requests.
 */
export enum RequestMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Options = 'OPTIONS',
    Head = 'HEAD',
    Patch = 'PATCH',
}

/**
 * These are the available response types
 * which can be used.
 */
export enum ResponseType {
    Blob = 'blob',
    Json = 'json',
    Text = 'text',
}

/**
 * AbstractHttpCommand contains abstract methods for commands which
 * implements the IHttpCommand interface. Commands which needs to
 * make http requests should extend this command.
 */
@Command()
export class AbstractHttpCommand extends AbstractCommand {
    /**
     * resultType sets the type of the response. If a non-null resultType is given
     * it will be used to deserialize the response. Check AbstractCommand.resultType
     * for more information on valid values for resultType.
     */
    public static get resultType(): any {
        return null;
    }

    /**
     * httpUrl property returns the url to make the request to.
     */
    public get httpUrl(): string {
        throw new Error( `Please override the getter for httpUrl in ${this.constructor.name}` );
    }

    /**
     * httpHeaders returns the headers needed to make the request.
     * This may include Authorization, Content-Type, etc.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'content-type': 'application/json;charset=UTF-8',
        });
    }

    /**
     * httpMethod property returns the http method to use for the request.
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Post;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * This operation will run after receiving data from the server. This hook
     * can be implemented in command classes implementing IHttpCommand.
     *
     * @return boolean Indicates whether the execute ran successfully or not
     */
    public executeResult( response: any ): boolean | Observable<any> {
        return true;
    }

    /**
     * This will be triggered when the server message comes back with an error.
     * When this occurs, the resultData can be null.
     * This same error will be thrown in the Observable that was recieved by
     * the CommandService.dispatch method.
     *
     * @param   error The error that occured during the message command.
     */
    public onError( error: CommandError ) {
        const httpError = error as CommandHttpError;
        this.log.error( httpError.message );
        throw error;
    }
}

Object.defineProperty( AbstractHttpCommand, 'name', {
  writable: true,
  value: 'AbstractHttpCommand',
});
