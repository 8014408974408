import { PlanPermission, IDialogBoxData } from 'flux-definition';
import { AppConfig } from 'flux-core';
import { UpgradeDialogType } from 'flux-user';

const redirectHandler: Function = () => window.open( AppConfig.get( 'PLAN_PERM_ERROR_REDIRECT_URL' ), '_blank' );

/**
 * Plan permission error messages and related data. This is in a single file as
 * constant so that it can be modify the text easily in future by maketing team
 * based on their findings.
 */
export const PLAN_PERM_ERROR_MESSAGE: { [ key: string ]: IDialogBoxData } = {

    [ PlanPermission.DIAGRAM_CREATE ]: {
        id: PlanPermission.DIAGRAM_CREATE,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{
            type: 'ok',
            text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.WORKSPACE_LIMIT_EXCEEDED',
            clickHandler: redirectHandler,
        }],
    },
    [ PlanPermission.DIAGRAM_CREATE_PUBLIC ]: {
        id: PlanPermission.DIAGRAM_CREATE_PUBLIC,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PUBLIC.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PUBLIC.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{
            type: 'ok',
            text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.WORKSPACE_LIMIT_EXCEEDED',
            clickHandler: redirectHandler,
        }],
    },
    [ PlanPermission.DIAGRAM_CREATE_PRIVATE ]: {
        id: PlanPermission.DIAGRAM_CREATE_PRIVATE,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PRIVATE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PRIVATE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{
            type: 'ok',
            text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.WORKSPACE_LIMIT_EXCEEDED',
            clickHandler: redirectHandler,
        }],
    },
    [ PlanPermission.DIAGRAM_CREATE_PRIVATE_NONE ]: {
        id: PlanPermission.DIAGRAM_CREATE_PRIVATE_NONE,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PRIVATE_NONE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_CREATE_PRIVATE_NONE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{
            type: 'ok',
            text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.WORKSPACE_LIMIT_EXCEEDED',
            clickHandler: redirectHandler,
        }],
    },
    [ PlanPermission.DIAGRAM_ADD_EDITORS ]: {
        id: PlanPermission.DIAGRAM_ADD_EDITORS,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_ADD_EDITORS.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_ADD_EDITORS.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_COLLABORATOR_ADD_EDITORS ]: {
        id: PlanPermission.DIAGRAM_COLLABORATOR_ADD_EDITORS,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_COLLABORATOR_ADD_EDITORS.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_COLLABORATOR_ADD_EDITORS.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
    },
    [ PlanPermission.DIAGRAM_ADD_SHAPES ]: {
        id: PlanPermission.DIAGRAM_ADD_SHAPES,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_ADD_SHAPES.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_ADD_SHAPES.DESCRIPTION',
        type: 'iframe',
        iframeType: UpgradeDialogType.ItemLimit,
        icon: 'warning',
        buttons: [{
            type: 'ok',
            text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.SHAPES_LIMIT_EXCEEDED',
            clickHandler: redirectHandler,
        }],
    },
    [ PlanPermission.DIAGRAM_IMPORT_IMAGE ]: {
        id: PlanPermission.DIAGRAM_IMPORT_IMAGE,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_IMPORT_IMAGE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_IMPORT_IMAGE.DESCRIPTION',
        type: 'iframe',
        iframeType: UpgradeDialogType.UnlimitedImport,
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_EXPORT_AS_PNG ]: {
        id: PlanPermission.DIAGRAM_EXPORT_AS_PNG,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_PNG.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_PNG.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.SIGN_UP', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_EXPORT_AS_SVG ]: {
        id: PlanPermission.DIAGRAM_EXPORT_AS_SVG,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_SVG.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_SVG.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.EXPORT_SVG', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_EXPORT_AS_JPEG ]: {
        id: PlanPermission.DIAGRAM_EXPORT_AS_JPEG,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_JPEG.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_JPEG.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_EXPORT_AS_CSV ]: {
        id: PlanPermission.DIAGRAM_EXPORT_AS_CSV,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_CSV.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_CSV.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.EXPORT_CSV', clickHandler: redirectHandler }],
    },
    [ PlanPermission.DIAGRAM_EXPORT_AS_PDF ]: {
        id: PlanPermission.DIAGRAM_EXPORT_AS_PDF,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_PDF.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_EXPORT_AS_PDF.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.EXPORT_PDF', clickHandler: redirectHandler }],
    },
    [ PlanPermission.PROJECT_CREATE ]: {
        id: PlanPermission.PROJECT_CREATE,
        heading: 'PLAN_PERM.PERM_DENIED.PROJECT_CREATE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.PROJECT_CREATE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.PROJECT_COLLBORATOR_ADD_EDITORS ]: {
        id: PlanPermission.PROJECT_COLLBORATOR_ADD_EDITORS,
        heading: 'PLAN_PERM.PERM_DENIED.PROJECT_COLLBORATOR_ADD_EDITORS.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.PROJECT_COLLBORATOR_ADD_EDITORS.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
    },
    [ PlanPermission.CREATELY_VIDEO_CONFERENCE ]: {
        id: PlanPermission.CREATELY_VIDEO_CONFERENCE,
        heading: 'PLAN_PERM.PERM_DENIED.CREATELY_VIDEO_CONFERENCE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.CREATELY_VIDEO_CONFERENCE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.CREATELY_PREMIUM_LIBRARIES ]: {
        id: PlanPermission.CREATELY_PREMIUM_LIBRARIES,
        heading: 'PLAN_PERM.PERM_DENIED.CREATELY_PREMIUM_LIBRARIES.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.CREATELY_PREMIUM_LIBRARIES.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.COLLABORATE_OFFLINE ]: {
        id: PlanPermission.COLLABORATE_OFFLINE,
        heading: 'PLAN_PERM.PERM_DENIED.COLLABORATE_OFFLINE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.COLLABORATE_OFFLINE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
    },
    [ PlanPermission.DIAGRAM_DUPLICATE ]: {
        id: PlanPermission.DIAGRAM_DUPLICATE,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_DUPLICATE.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_DUPLICATE.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.CREATELY_VIZ_ACCESS ]: {
        id: PlanPermission.CREATELY_VIZ_ACCESS,
        type: 'iframe',
    },
    [ PlanPermission.CREATELY_VOTING_ACCESS ]: {
        id: PlanPermission.CREATELY_VIZ_ACCESS,
        type: 'iframe',
    },
    [ PlanPermission.CREATELY_DIAGRAM_MARK_AS_TEMPLATE_ACCESS ]: {
        id: PlanPermission.CREATELY_DIAGRAM_MARK_AS_TEMPLATE_ACCESS,
        type: 'iframe',
    },
};
