import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { ModelSubscriptionManager } from 'flux-subscription';
import { InteractionCommandEvent } from './../interaction/command/interaction-command-event';
import { ExtendedShapeLinkType, ShapeLinkModel, ShapeLinkType } from 'flux-diagram';
import { AppConfig, CommandService, StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramNavigation } from '../../system/diagram-navigation.svc';
import { EMPTY } from 'rxjs';

/**
 * LinkService manages navigation to links from anywhere in the app.
 * Internal Shape / Group link - Focus the shape / group
 * Diagram Link - Open the diagram
 * External Shape / Group link - Open the diagram and focus the shape / group
 * External links - Open the link in weblinks viewer
 *
 * @author thisun
 * @since 2020-10-05
 */
@Injectable()
export class LinkService {

    constructor(
        protected modelSubManager: ModelSubscriptionManager,
        protected commandService: CommandService,
        protected state: StateService<any , any>,
        protected router: Router,
        protected diagramNavigation: DiagramNavigation,
    ) {}


    /**
     * Navigate to the target specified in the ShapeLinkModel
     */
    public navigate( link: ShapeLinkModel ) {
        if ( !( link instanceof ShapeLinkModel )) {
            link = ShapeLinkModel.fromUrl(( link as any ).link );
        }
        const dId = link.linkedDiagramId;
        if ( dId === this.state.get( 'CurrentDiagram' )) {
            if ( link.type === ShapeLinkType.SHAPE || ( link.type as any ) === ExtendedShapeLinkType.SHAPE ) {
                this.navigateToShape( link.targetId );
            }  else if ( link.type === ShapeLinkType.GROUP || ( link.type as any ) === ExtendedShapeLinkType.GROUP ) {
                this.navigateToGroup( link.targetId );
            }
        } else if ( dId ) { // Navigate to another diagram
            const suffix = (
                link.type === ShapeLinkType.SHAPE ||
                ( link.type as any ) === ExtendedShapeLinkType.SHAPE
            ) ? [ 's', link.targetId ] : (
                link.type === ShapeLinkType.GROUP ||
                ( link.type as any ) === ExtendedShapeLinkType.GROUP
            ) ? [ 'g', link.targetId ] : [];
            this.navigateToDiagram( dId, suffix ).subscribe();
        } else {
            this.state.set( 'WebLinkViewer', {
                open: true,
                link: link.link,
            });
        }
    }

    /**
     * Navigate to the shape specified by the id in the current diagram.
     */
    public navigateToShape( id: string ) {
        this.commandService.dispatch( InteractionCommandEvent.focusToPoint, {
            shapeIds: [ id ],
        });
        this.state.set( 'ShapelinkTarget', { shapeId: id });
    }

    /**
     * Navigates to a selection of shapes
     */
    public navigateToShapes( ids: string[]) {
        this.commandService.dispatch( InteractionCommandEvent.focusToPoint, {
            shapeIds: ids,
        });
    }

    /**
     * Navigate to the group specified ny the id in the current diagram.
     */
    public navigateToGroup( id: string ) {
        this.commandService.dispatch( InteractionCommandEvent.focusToPoint, {
            groupId: id,
        });
        this.state.set( 'ShapelinkTarget', { groupId: id });
    }


    /**
     * This function navigates to the selected diagram or focus to shape / group
     * after removing the subscription to the current
     * diagram in selection.
     * @param diagramId Id of the dagram
     * @param suffix e.g. [ 's', 'shapeId' ] or [ 'g', 'groupId' ]
     */
    public navigateToDiagram( diagramId: string, suffix = []) {
        const currDiag = this.state.get( 'CurrentDiagram' );
        if ( currDiag !== diagramId ) {
            return this.modelSubManager.stop( this.state.get( 'CurrentDiagram' ), true ).pipe(
                take( 1 ),
                tap(() => {
                    this.diagramNavigation.navigateToDiagram( diagramId, 'edit', suffix );
                }),
            );
        } else {
            if ( suffix ) {
                if ( suffix[0] === ShapeLinkType.SHAPE ) {
                    this.navigateToShape( suffix[1]);
                } else if ( suffix[0] === ShapeLinkType.GROUP ) {
                    this.navigateToGroup( suffix[1]);
                }
            }
        }
        return EMPTY;
    }

    /**
     * returns a shape link
     * @param shapeId
     * @param diagramId
     */
    public buildShapeLink( shapeId: string, diagramId: string ): string {
        return `${AppConfig.get( 'APP_URL' )}/${ShapeLinkType.DIAGRAM}/${diagramId}/edit/${ShapeLinkType.SHAPE}/${shapeId}`;
    }

}
