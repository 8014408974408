/**
 * Message Status
 * This enum contains all the message status of message protocol
 * @author  mehdhi
 * @since   Mar 2016
 */
export enum MessageStatus {
    /**
     * Active status is when the message has been dispatched and has
     * still not been processed.
     */
    act,

    /**
     * Processed state is when Neutrino has successfully processed
     * the message with a valid result. In this state the
     * <code>processedPayload</code> field in <code>Message</code> object
     * may be set depending on the message type and value.
     */
    prs,

    /**
     * Error status is when the message failed to process and the message
     * has resulted in an error. In this state the <code>errorCode</code>
     * and <code>errorMessage</code> fields of the <code>Message</code> object
     * would be set.
     */
    err,
}
