import { Injectable } from '@angular/core';
import { IErrorMessageHandler } from '../../system/app-error-handler';
import { CommandError } from './command-error';

/**
 * This is an error handler which intercepts and handles all kinds of
 * command errors.
 *
 * @author  Vinoch
 * @since   2020-12-09
 */
@Injectable()
export class CommandErrorHandler implements IErrorMessageHandler {

    constructor() {}

    /**
     * Handling these errors to log them
     * as 'info' in datadog rather 'error'
     */
    public handle( error: Error ): boolean {
        if ( error instanceof CommandError ) {
            return true;
        }
        return false;
    }
}
