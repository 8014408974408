<div class="abstract-notification-container copy-paste-notification-container fx-floating">
    <div class="abstract-notification-content copy-paste-notification-content">
        <div class="abstract-notification-message">
            <strong class="abstract-notification-heading" translate>NOTIFIERS.COPY_PASTE.HEADING</strong> 
            <span class="abstract-notification-description" translate>NOTIFIERS.COPY_PASTE.DESC</span>
        </div>
        <div class="copy-paste-notification-shortcuts-container row fx-margin-top-20">
            <div class="col-3">
                <p class="copy-paste-notification-shortcut">{{featureList.getFeature( 'copyShapes' ).shortcutText}}</p>
                <p translate>NOTIFIERS.COPY_PASTE.COPY</p>
            </div>
            <div class="col-3">
                <p class="copy-paste-notification-shortcut">{{featureList.getFeature( 'cutShapes' ).shortcutText}}</p>
                <p translate>NOTIFIERS.COPY_PASTE.CUT</p>
            </div>
            <div class="col-3">
                <p class="copy-paste-notification-shortcut">{{featureList.getFeature( 'pasteShapes' ).shortcutText}}</p>
                <p translate>NOTIFIERS.COPY_PASTE.PASTE</p>
            </div>
            <div class="col-3">
                <p class="copy-paste-notification-shortcut">{{featureList.getFeature( 'duplicateShapes' ).shortcutText}}</p>
                <p translate>NOTIFIERS.COPY_PASTE.DUPLICATE</p>
            </div>
        </div>
        <div class="abstract-notification-buttons copy-paste-notification-buttons">
            <button class="nu-btn-med primary" (click)="dismissNotification(true)" translate>NOTIFIERS.COPY_PASTE.OK_BTN</button>
        </div>
    </div>
</div>