import { GraphicsDrawUtils } from './../../framework/view/graphics-draw-utils';
import { EMPTY, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Rectangle } from 'flux-core';
import { AbstractDefinitionLocator } from '../definition/abstract-definition-locator';
import { IGraphics } from 'flux-definition';
import { IDrawable } from 'flux-definition';
import { IRectangle, ILineStyle, IMatrix } from 'flux-definition';

/**
 * This is a service that can be used to render an {@link IDrawable} object
 * on a canvas. It can be used to draw such objects with customizations such
 * as drawing to a specific size, transformations, different styles, etc.
 *
 * It uses an {@link IGraphics} object to render the {@link IDrawable}. This
 * graphics object must always be passed into the StaticRenderer by whomever
 * service that makes use of it.
 */
@Injectable()
export class StaticRenderer {
    public static get instance(): StaticRenderer {
        return StaticRenderer._instance;
    }

    /**
     * Holds the singleton instance of StaticRenderer, enabling it to be used as a utility
     * class as well as a service. Should not be used until the app is initialized completely.
     */
    private static _instance = null;

    /**
     * Creates the singleton StaticRenderer instance.
     */
    constructor( protected deflocator: AbstractDefinitionLocator ) {
        if ( StaticRenderer._instance ) {
            return StaticRenderer._instance;
        }
        StaticRenderer._instance = this;
    }

    /**
     * Renders the arrow head using given graphics object.
     * The arrow head id should be given in Path#Class format.
     *  ( example: './bundle.js#PointerFilled' )
     * @param graphics - the graphics object that will be used to render the arrow head
     * @param arrowHeadId - the id of the arrow head to be drawn
     * @param matrix - matrix which contains transformation and rotation information of the arrow head
     * @param style - object with style data that needs to be applied to the arrow head
     * @return observable which completes when arrowHead is drawn
     */
    public renderArrowHead(
        graphics: IGraphics,
        arrowHeadId: string,
        bounds: IRectangle,
        matrix: IMatrix,
        style: ILineStyle ): Observable<void> {
        return this.deflocator.getClass( arrowHeadId ).pipe(
            map(( type: any ) => {
                const instance: IDrawable = new type();
                const options: any = { matrix, bounds, style };
                instance.drawToSize( graphics, options );
            }),
            filter(() => false ),
        );
    }

    /**
     * Synchronously renders the arrow head using given graphics object.
     * The arrow head id should be given in Path#Class format.
     *  ( example: './bundle.js#PointerFilled' )
     * @param graphics - the graphics object the arrow head will be drawn on
     * @param arrowHeadId - the id of the arrow head to be drawn
     * @param matrix - matrix which contains transformation and rotation information of the arrowHead
     * @param style - object with style data that needs to be applied to the arrow head
     */
    public renderArrowHeadSync(
        graphics: IGraphics,
        arrowHeadId: string,
        bounds: IRectangle,
        matrix: IMatrix,
        style: ILineStyle ): void {
        const type: any = this.deflocator.getCachedClass( arrowHeadId );
        const instance: IDrawable = new type();
        const options: any = { matrix, bounds, style };
        instance.drawToSize( graphics, options );
    }

    /**
     * Renders a thumbnail for a shape identified by the definition id and the version.
     * The thumbnail will be drawn on a given bounds and will be resized to fit
     * these bounds while retaining its apsect ratio.
     * @param graphics - graphic object that will be used to render the thumbnail
     * @param defId - defId of the shape that the thumbnail belongs to
     * @param version - version of the definition
     * @param bounds - bounds that will determine the thumbnail size.
     * @return observable that comples when thumbnail drawing has finished.
     */
    public renderThumbnail(
        graphics: IGraphics,
        defId: string,
        version: number,
        b: IRectangle,
        drawCode?: any,
        typeStyle?: any ): Observable<void> {
        const bounds: Rectangle = b instanceof Rectangle ? b : Rectangle.from( b );
        if ( drawCode ) {
            const rect = Rectangle.from({
                x: 0, y: 0, width: drawCode.defaultWidth, height: drawCode.defaultHeight,
            });
            const fit = rect.fitToFrame( bounds );
            GraphicsDrawUtils.drawFromInstructions(
                graphics,
                drawCode.instructions,
                fit.scale,
                fit.scale,
                drawCode.lineThickness,
                drawCode.defaultWidth,
                drawCode.defaultHeight,
                fit.x,
                fit.y,
            );
            return EMPTY;
        }
        return this.deflocator.getThumbnailClass( defId, version ).pipe(
            map(({ class: type, def }: any ) => {
                const instance: IDrawable = new type();
                let rect;
                if ( typeStyle && typeStyle.bounds ) {
                     rect =  new Rectangle( 0, 0, typeStyle.bounds.width, typeStyle.bounds.height );
                } else {
                    rect =  new Rectangle( 0, 0, def.defaultBounds.width, def.defaultBounds.height );
                }

                const options = { bounds: rect.scaleToCenter( bounds ),
                    shapeContext: typeStyle?.defaultShapeContext, definition: def };
                instance.drawToSize( graphics, options );
            }),
            filter(() => false ),
        );
    }
}
