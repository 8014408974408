import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { GlobalNotificationService } from '../../../../notification/global-notification.svc';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { TeamSettingManager, UserInfoModel } from 'flux-user';
import { CommandService } from 'flux-core';
import { NotificationCommandEvent } from '../../../../notification/command/notification-command-event';
import { tap } from 'rxjs/operators';

/**
 * Global Notification Notifications Component
 * Notification tab of the main global notification component
 * List of notification view in this tab
 *
 * @author  Sajeeva
 * @since   2021-09-18
 */
@Component({
    templateUrl: './global-notification-notifications.cmp.html',
    selector: 'global-notification-notifications',
    styleUrls: [ './global-notification-notifications.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalNotificationNotifications implements OnInit {

    @Input()
    showAsRecentUpdateItem: boolean = false;

    @Input()
    enableFilterByResourceIds: boolean = false;

    @Input()
    filterResourceIds: Subject<Array<string>> = new BehaviorSubject([]);

    /**
     * array with all notifications
     */
    public notifications: Subject<IRichNotification[]> = new BehaviorSubject([]);

    /**
     * Whether the can show site link or not
     */
    public showSiteLink: boolean = false;

    protected readonly NOTIFICATIONS_FETCH_LIMIT: number = 20;

    constructor(
        protected globalNotificationService: GlobalNotificationService,
        protected commandService: CommandService,
        protected teamSettingManager: TeamSettingManager,
    ) {}

    /**
     * Getter for assets message title
     */
    public get msgTitle() {
        return 'FILE_IMPORTS.ADD_IMAGES';
    }

    /**
     * Getter for assets panel message body
     */
    public get msgBody(): string[] {
        return [ 'ALERTS.EMPTY_SCREEN.INSTRUCTION_1', 'ALERTS.EMPTY_SCREEN.INSTRUCTION_2' ];
    }

    /**
     * Empty state message icon
     */
     public get emptyStateIcon(): string {
        return './assets/images/alert/notifications.svg';
    }

    ngOnInit(): void {
        this.getGlobalNotifications().subscribe();
        this.showSiteLink = this.teamSettingManager.check( 'ShowAppExternalLinks' );
    }

    /**
     * load more older notifications.
     */
    public loadMore() {
        const offset = this.globalNotificationService.getNoOfGlobalNotifications();
        this.commandService.dispatch(
            NotificationCommandEvent.getGlobalNotifications,
            { offset: offset, limit: this.NOTIFICATIONS_FETCH_LIMIT },
        );
    }

    private getGlobalNotifications(): Observable<any> {

        const globalNotifications$ = this.globalNotificationService.getGlobalNotifications();

        if ( !this.enableFilterByResourceIds ) {
            return globalNotifications$.pipe(
                tap( notifications => this.notifications.next( notifications )),
            );
        }

        return combineLatest([ this.filterResourceIds, globalNotifications$ ]).pipe(
            tap(( response: [ string[], IRichNotification[]]) => {
                const [ ids, notifications ]  = response;
                this.notifications.next(
                    notifications.filter(
                        ( notification: IRichNotification ) => ids.includes( notification.resourceId ),
                    ),
                );
            }),
        );
    }
}

/**
 * Notification object data strcture.
 */
export interface INotification {
    _id?: string;
    actor: string;
    key: string;
    resourceType: string;
    resourceId: string;
    resourceName: string;
    description: string;
    seen: boolean;
    global: boolean;
    push: 'resourceOpen' | 'any';
    type: string;
    data: any;
    serverTime: number;
}

/**
 * Enriched notification data.
 */
export interface IRichNotification extends INotification {
    actorInfo: UserInfoModel;
    formatedTime: string;
    isAffectedUser: boolean;
    translatedContentHTML: string;
    actionSVG: string;
}

