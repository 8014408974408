import { Component, Input, ChangeDetectionStrategy, Output } from '@angular/core';
import { StaticRenderer } from 'flux-diagram-composer';
import { StateService } from 'flux-core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

/**
 * This component is used to show canvas drawable thumbnails of a shape specifically
 * for Nucleus shapes that implement the IDrawable for thumbnails.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edata-library-item',
    template: `<div class="edata-library-item btn-list-item" [style.display]="isVisible ? 'none' : 'flex'" [draggable]="isDraggable" [style.padding-left]="indentation + 'px'">
                    <span class="data-source-id">{{dataSourceId}}</span>
                    <div [attr.title]="label.length > 15 ? label: ''" class="text-container body fx-ellipsis">{{label}} <span *ngIf="refChangeCount>0">{{refChangeCount}}</span></div>
                </div>`,
    styleUrls: [ './edata-library-item.scss' ],
})

export class EDataLibraryItem {

    /**
     * Diagram id of count
     */
    @Input() public icon: string;

    /**
     * Shape count in document
     */
    @Input() public label: string;

    @Input() public indent: number;

    @Input() public isParent: boolean = false;

    @Input() public refChangeCount: number;

    @Input() public dataSourceId: any;

    /**
     * Shape count in document
     */
    @Input() public isDraggable: boolean = true;


    @Input() public isVisible: boolean = true;

    /**
     * Boolean to identify external doc count
     */
    @Input() public shapeCounts: { [ diagramId: string]: number };


    /**
     * Observable that emits whenever a change is made on any feature
     */


    /**
     * The subject that emits component feates changes. Exposed
     * outside via the <code>change</code> getter.
     */
    @Output()
    public closed: Subject<any>;


    public isClosed: boolean;

    constructor( protected rendered: StaticRenderer,
                 protected state: StateService<any, any>,
                 protected router: Router,
        ) {
            this.closed = new Subject();
        }


    /**
     * Returns the xlink:href for the use tag
     */
    public get iconHref() {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.icon;
    }

    /**
     * Returns the indentation that should be set using CSS.
     */
    public get indentation(): number {
        if ( this.isParent ) {
            return this.indent * 10;
        } else {
            return this.indent * 20;
        }
    }

    public toggle() {
        this.isClosed = !this.isClosed;
        this.closed.next( this.isClosed );
    }
}
