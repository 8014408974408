import { Injectable } from '@angular/core';
import { Modifier } from '@creately/rxdata';
import { Proxied } from '@creately/sakota';
import { Command, StateService } from 'flux-core';
import { EMPTY, Observable } from 'rxjs';
import { ProjectModel } from '../model/project.mdl';
import { AbstractProjectChangeCommand } from './asbtract-project-change-command.cmd';

/**
 * Updates the project name.
 *
 *  data: {
 *      name: string,
 *  }
 *
 */
@Injectable()
@Command()
export class RenameProject extends AbstractProjectChangeCommand {

    public static get dataDefinition(): any  {
        return {
            name: true,
        };
    }

    public constructor( protected state: StateService<any, any> ) {
        super();
    }

    public get modifier(): Modifier {
        return this.data.modifier;
    }

    public get reverter(): Modifier {
        return this.data.reverter;
    }

    public get version(): number {
        return 2;
    }

    /**
     * Returns an empty observable since it is not used to prepare the change.
     */
    public prepareProxy(): Observable<Proxied<ProjectModel>> {
        return EMPTY;
    }

    public prepareData() {
        this.data.modifier = { $set: {}};
        this.data.reverter = { $set: {}};

        if ( !this.data.name ) {
            throw new Error ( 'There was an error setting the data to the project name because data is not valid' );
        } else {
            this.data.modifier.$set[`name`] = this.data.name;

            // FIXME - Added temporary to pass the modifier and reverter to
            // ProjectChange command.
            this.resultData = {
                modifier : this.data.modifier,
                reverter : this.data.reverter,
            };

            // Update project state data
            const projectMap = this.state.get( 'ProjectMap' );
            const project: ProjectModel = projectMap[ this.resourceId ];
            project.name = this.data.name;
            this.state.set( 'ProjectMap', projectMap );

        }
    }
}

Object.defineProperty( RenameProject, 'name', {
    value: 'RenameProject',
});
