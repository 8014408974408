import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { IShapeNode } from '../svg-node/shape-node.i';
import { SvgTransformationToMatrix } from '../svg-transform-to-matrix';
import { IStyle, FillStyle } from 'flux-definition';
import {
    IGradientInstruction,
    IGradientInstructionFactory,
    GradientUsageType,
    GradientType,
} from './canvas-instruction.i';

/**
 * class LinearGradientCanvasInstructionFactory
 * This class implements functionality to translate svg linear gradients to canvas instructions.
 * NOTE: This currently support linear gradient fills only, strokes to be implemented
 */
export class LinearGradientCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements IGradientInstructionFactory {

    public svgElementName: string = 'linearGradient';

    /**
     * Create linear gradient canvas instructions from given shape node
     * @param data
     */
    public createInstruction( data: IShapeNode, gradientUsageType: GradientUsageType ): IGradientInstruction {
        this.styleDefinitions = [];
        const colorAndOffsets = this.exctractGradientColorOffsets( data );
        if ( this.hasGradientPercentages( data, 'linearGradient' )) {
            const color = this.getWidelyUsedGradientColor( colorAndOffsets.colors, colorAndOffsets.ratios );
            const style: Partial<IStyle> = { fillColor: color, fillStyle: FillStyle.Solid };
            const styleDef = { locked: false, priority: 1, style };
            const styleId = this.generateStyleId( styleDef );
            this.styleDefinitions.push({ id: styleId, def: styleDef });
            return {
                gradient: this.getGradientStyleDefinition( styleId, gradientUsageType ),
                styleDefinitions: this.styleDefinitions,
            };
        }
        const points = this.getPoints( data );
        return {
            gradient: {
                instruction: this.getGradientInstructionType( GradientType.linearGradient, gradientUsageType ),
                params: [
                    `[${colorAndOffsets.colors.map( c => `'${c}'` ).join( ',' )}]`,
                    `[${colorAndOffsets.ratios.map( r => +r ).join( ',' )}]`,
                    `${this.sw} * ${points.x0} + ${this.xo}`,
                    `${this.sh} * ${points.y0} + ${this.yo}`,
                    `${this.sw} * ${points.x1} + ${this.xo}`,
                    `${this.sh} * ${points.y1} + ${this.yo}`,
                ],
            },
            styleDefinitions: this.styleDefinitions,
        };
    }

    /**
     * Return the gradient coordinates from given shape node. If there is a gradient transformation matrix is
     * applied, points need to calculated based on the matrix.
     * @param data
     */
    private getPoints( data: IShapeNode ): { x0: number, y0: number, x1: number, y1: number } {
        if ( data.data.gradientTransform ) {
            const matrix = SvgTransformationToMatrix.getMatrix( data.data.gradientTransform );
            const point1 = matrix.transform( +data.data.x1, +data.data.y1 );
            const point2 = matrix.transform( +data.data.x2, +data.data.y2 );
            return {
                x0: point1.x,
                y0: point1.y,
                x1: point2.x,
                y1: point2.y,
            };
        }
        return {
            x0: +data.data.x1,
            y0: +data.data.y1,
            x1: +data.data.x2,
            y1: +data.data.y2,
        };
    }

}
