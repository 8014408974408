import { mergeWith } from 'lodash';
/**
 * Set of utility methods to get class related information.
 */

/**
 * The @enumerable(true) decorator will change the non-enumerable property to enumerable.
 * This it modifies the enumerable property of the property descriptor.
 * @param value enumerable value
 * @returns
 */
export const enumerable = ( value: boolean ) =>
    ( target: any, propertyKey: string, descriptor: PropertyDescriptor ) => {
        descriptor.enumerable = value;
      };

export class ClassUtils {
    /**
     * Returns the prototype of t ( if available );
     */
    public static getPrototype( t: any ) {
        return Object.getPrototypeOf( t );
    }

    /**
     * Returns the type of t ( if available )
     */
    public static getConstructor( t: any ) {
        const proto = this.getPrototype( t );
        return proto && proto.constructor;
    }

    /**
     * Disables a method on a class instance.
     */
    public static disableMethod( instance: any, method: string, msg: string ) {
        instance[method] = () => {
            throw new Error( msg );
        };
    }

    /**
     * This method merges two or more object instances together.
     * It uses the standard lodash merge functionality with some additions.
     *  - Functions and properties are copied over similar to standard merge.
     *  - Getters and setters are copied over - they are defined on the destination object
     *    using property descriptors on the source objects prototype. In a standard merge
     *    only the getter value would have been copied.
     *  - The destination property is replaced with that of the source in following scenarios:
     *      - A value of the same name exists on both
     *      - Getter of the same name exists on both
     *      - Setter of the same name exists on both
     *      - A value exists on destination and a getter of the same name is defined on source
     *  - If a setter and a value share the same name and the setter exists on the destination,
     *    the setter is invoked with the sources value.
     *  - An error is thown in following scenarios:
     *      - Source object has a value with the same name as a getter/setter on the destination.
     *      - Source object has a setter with the same name as a getter on the destination and vice versa.
     *      - Both source and destination have a function of the same name.
     * The above applies to all properties in an objects inheritance chain.
     * Source objects are merged into the destination from left to right.
     * @param obj - destination object - the object the sources will be copied into.
     * @param sources - the source objects which will be merged into the dest object. These are not mutated.
     * @return dest instance with all sources merged in.
     */
    public static mergeInstances( obj: any, ...sources: any[]): any {
        // tslint:disable-next-line:cyclomatic-complexity
        mergeWith( obj, ...sources, ( objValue, srcValue, key, object, source ) => {
            const srcDesc = this.getDescriptor( source, key );
            const objDesc = this.getDescriptor( object, key );
            if ( objDesc && srcDesc ) {
                if ( this.isValue( srcDesc ) && this.isSetter( objDesc )) {
                    return;
                }
                if (( this.isGetter( srcDesc ) && ( this.isFunction( objDesc ) || this.isSetter( objDesc ))) ||
                    ( this.isValue( srcDesc ) && this.isAccessor( objDesc )) ||
                    ( this.isSetter( srcDesc ) && !this.isSetter( objDesc )) ||
                    ( this.isFunction( srcDesc ))
                ) {
                    throw new Error( 'Cannot merge. Property ' + key + ' conflicted.' );
                } else {
                    Object.defineProperty( object, key, srcDesc );
                }
            } else {
                if ( this.isAccessor( srcDesc )) {
                    Object.defineProperty( object, key, srcDesc );
                }
            }
        });
        return obj;
    }

    /**
     * Gets the property descriptor for a given property name.
     * The property may be defined on the object or on any of the
     * protorypes of its prototype chain.
     * @param object - object instance
     * @param key - property name
     * @return descriptor if it exists. Undefined if not found
     */
    public static getDescriptor( object: any, key: string ): any {
        for ( let o = object; o !== Object.prototype; o = Object.getPrototypeOf( o )) {
            const descriptor = Object.getOwnPropertyDescriptor( o, key );
            if ( descriptor ) {
                return descriptor;
            }
        }
    }

    /**
     * Checks if a given property is either a getter or a setter by examining
     * its property descriptor.
     * @param descriptor - the property descriptor
     * @return true if a getter or a setter
     */
    public static isAccessor( descriptor: PropertyDescriptor ): boolean {
        return descriptor && ( this.isGetter( descriptor ) || this.isSetter( descriptor ));
    }

    /**
     * Checks if a given property is a getter by examining
     * its property descriptor.
     * @param descriptor - the property descriptor
     * @return true if a getter
     */
    public static isGetter ( descriptor: PropertyDescriptor ): boolean {
        return !!( descriptor && descriptor.get );
    }

    /**
     * Checks if a given property is a setter by examining
     * its property descriptor.
     * @param descriptor - the property descriptor
     * @return true if a setter
     */
    public static isSetter ( descriptor: PropertyDescriptor ): boolean {
        return !!( descriptor && descriptor.set );
    }

    /**
     * Checks if a given property is a function by examining its property
     * descriptor
     * @param descriptor - the property descriptor
     * @return true if a function
     */
    public static isFunction( descriptor: PropertyDescriptor ): boolean {
        return descriptor && (( typeof descriptor.value ) === 'function' );
    }

    /**
     * Checks if a given property is a value ( not an accessor or a function)
     * by examining its property descriptor.
     * @param descriptor - the property descriptor
     * @return true if a value
     */
    public static isValue( descriptor: PropertyDescriptor ): boolean {
        return descriptor && !this.isAccessor( descriptor ) && !this.isFunction( descriptor );
    }

}
