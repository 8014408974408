import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { EntityLinkType } from 'flux-definition';
/**
 * Each link has an identifier which you recieve from the other side. This sets it.
 */
@Injectable()
@Command()
export class UpdateEntityLinkValue extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entityId: string,
        sourceEDataId: string,
        sourceEntityId: string,
        type: EntityLinkType,
        handshake: string,
        identifier: any,
        linkId?: string,
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        this.modelChangeUtils.updateEntityLinkValue(
            this.changeModel,
            this.data,
        );
    }

}

Object.defineProperty( UpdateEntityLinkValue, 'name', {
    value: 'UpdateEntityLinkValue',
});

