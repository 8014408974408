import { CommandInterfaces, Command, AbstractHttpCommand, AppConfig } from 'flux-core';
import { Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';

/**
 * This command is used fetch data from the given URL to base64.
 *
 * @since   2020-02-27
 * @author  Vinoch
 */
@Injectable()
@Command()
export class FetchUrl extends AbstractHttpCommand  {

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IHttpCommand',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    constructor( protected auth: NucleusAuthentication ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns API endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'FETCH_DATA_URL' );
    }

    /**
     * Attaches the auth token to command data
     * The REST execution step requires it
     */
    public prepareData(): any {
        this.data.auth = this.auth.token;
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return empty();
    }
}

Object.defineProperty( FetchUrl, 'name', {
    value: 'FetchUrl',
});
