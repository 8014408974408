import { AbstractModelSubscription } from 'flux-subscription';
import { AbstractModel } from 'flux-core';
import { UserModel } from '../model/user.mdl';

/**
 * User subscription.
 * Allows sending and recieving of complete user information.
 *
 * Since this inherits AbstractModelSubscription which is currentinly in nucleus,
 * all subscription related classes need to be first moved to flux-subscription package.
 * flux-subscription will have dependencies to flux-store and flux-connection.
 *
 * @author  Ramishka
 * @since   2018-01-23
 */
export class UserSub extends AbstractModelSubscription {

    /**
     * Returns the subscription type
     */
    public get subscription(): string {
        return 'User';
    }

    /**
     * Returns the model type this subscription is associated with
     */
    public get modelType(): typeof AbstractModel {
        return UserModel;
    }
}
