import { AppConfig, Logger, PostMessageAPI } from 'flux-core';
import { Injectable } from '@angular/core';
import { NucleusAuthentication } from './../../../system/nucleus-authentication';
import { DataSourceDomain } from './model/data-source.model';
import Nango from '../../../vendor/nango/frontend';

const connectorPrefix = {
    [DataSourceDomain.Github]: 'git_',
    [DataSourceDomain.GoogleSheets]: 'google_sheets_',
    [DataSourceDomain.M365Excel]: 'm365_excel_',
};

const integrationIds = {
    [DataSourceDomain.Github]: 'github',
    [DataSourceDomain.GoogleSheets]: 'google-sheet',
    [DataSourceDomain.M365Excel]: 'microsoft-teams',
};

@Injectable()
export class NangoService {
    constructor( protected auth: NucleusAuthentication, protected postMessage: PostMessageAPI ) {
    }

    public get httpUrl(): string {
        return AppConfig.get ( 'NANGO_API_COMMANDS_URL' );
    }
    public async fetchPost<T = any>( body: any = {}): Promise<T> {
        const res = await fetch( `${this.httpUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'creately-gravity-token': this.auth.token,
            },
            body: JSON.stringify( body ),
        });
        if ( res.status >= 400 && res.status < 600 ) {
            throw new Error( 'Internal server error' );
        }
        return res.json();
    }

    public async isAuthenticated( domain: DataSourceDomain ) {
        return this.fetchPost<boolean>({
            dataSource: domain,
            method: 'isAuthenticated',
        });
    }

    /**
     * Authenticates the cyclr connector for a specific domain.
     * @param domain authentication domain
     * @param authWaitTime number of seconds to wait before operation fails
     * @returns Observable
     */
    public async authenticate( integrationId: string, userId: string ) {
        const nango = new Nango({
            host: AppConfig.get ( 'NANGO_API_HOST' ),
            publicKey: AppConfig.get ( 'NANGO_PUBLIC_KEY' ),
        });
        if ( !connectorPrefix[integrationId]) {
            throw new Error( 'Invalid integration' );
        }
        return nango.auth( integrationIds[integrationId], connectorPrefix[integrationId] + userId )
            .catch( error  => {
                Logger.error( error );
                throw error;
            });
    }
}
