import { ChangeDetectionStrategy, Component, AfterViewInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { ModalController } from '../../controller/modal-controller';
import { Animation } from '../animation';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './fullscreen-modal-window.cmp.html',
    selector: 'fullscreen-modal-window',
    styleUrls: [ './fullscreen-modal-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This is a modal window component that opens in full screen mode.
 * It has two panes; left and right. Any content can be embedded in
 * these two sections.
 *
 * @author  Ramishka
 * @since   2019-06-18
 *
 */
export class FullScreenModalWindow implements AfterViewInit  {

    /**
     * Window element
     */
    @ViewChild( 'window' ) protected windowElem;

    /**
     * Right panel element
     */
    @ViewChild( 'rightPanel' ) protected rightPanelElem;

    constructor( protected mdlCtrl: ModalController ) {
    }

    public ngAfterViewInit() {
        merge(
            this.fadeWindow( '0', '1', 200 ).start(),
            this.slidePanel( 'translate3d(100% , 0, 0 )', 'translate3d(0 , 0, 0 )' ).start(),
        ).subscribe();
    }

    public closeWindow() {
        this.getCloseWindowAnimation().subscribe({ complete: () => this.mdlCtrl.hide() });
    }

    /**
     * Method that returns the close window animation.
     */
    protected getCloseWindowAnimation(): Observable<any> {
        return merge(
            this.slidePanel( 'translate3d(0 , 0, 0 )', 'translate3d(100% , 0, 0 )' ).start(),
            this.fadeWindow( '1', '0', 200 ).start(),
        );
    }

    /**
     * Returns an animation that animates the window element from
     * and to to a given opacity and duration.
     */
    protected fadeWindow( from: string, to: string, time: number ): Animation {
        const animation = new Animation({
            from: { opacity: from },
            to: { opacity: to },
            transitionProperty: 'opacity',
            duration: time,
            easing: 'ease-in-out',
        });
        animation.element = this.windowElem.nativeElement;
        return animation;
    }

    /**
     * Returns an animation that animates the right panel element from
     * and to to a given transformation value.
     */
    protected slidePanel( from: string, to: string ): Animation {
        const animation = new Animation({
            from: { transform: from },
            to: { transform: to },
            transitionProperty: 'transform',
            duration: 200,
            easing: 'ease-out',
        });
        animation.element = this.rightPanelElem.nativeElement;
        return animation;
    }
}
