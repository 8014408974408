import { Tween } from '@creately/createjs-module';
import { Observable, empty } from 'rxjs';
import { pick } from 'lodash';
import { Subject } from 'rxjs';

/**
 * Animation class that extends the capabilities of TweenJS.
 * This adds rxJs to the changes events and also enables a simulation mode where
 * an actual createJs shape is not required to run the animation. This can be used
 * to simulate the value changes of an animation. By listening to the changes() observable
 * all changes can be captured that happens to the properties set in the to() method.
 *
 * @author hiraash
 * @since 2017-09-25
 */
export class Animation extends Tween {

    /**
     * Static method to create an Animation class which can be chained to setup the
     * aniimation and listen to it.
     * @param target The target object on which the animation is applied
     * @param props Properties that need to change and the values that need to set
     */
    public static get( target, props?, pluginData?, override? ): Animation {
        if ( override ) {
            Tween.removeTweens( target );
        }
        return new Animation( target, props );
    }

    /**
     * Static method to simulate the properties changes of a given animation. Returns the instance of
     * the animation. The values specified here and the values specified in to() method will be the animation
     * start and end respectively. To capture the simulation use the Observable returned by the changes() method.
     * @param initialProps The properties and inital values of them from which the animation
     *      needs to start the simulation.
     */
    public static simulate( initialProps: Object ) {
        return this.get( initialProps );
    }

    /**
     * Indicates if the tween is stopoed. Can be used to stop the tween.
     * NOTE: This is a original TweenJs property which is not in the
     * definition. Simply creating for type requirement. Expected to
     * be handler by easel js.
     */
    protected _paused: boolean;

    /**
     * Property names that are transitioned in this animation
     */
    protected propNames: Array<string>;

    /**
     * The subject that emits the progress of the animation.
     * Only becomes available once the changes method is called.
     */
    protected _progress: Subject<any>;

    /**
     * Indicates if the animation has completed fully. If it was cancelled
     * without completing, this will return false.
     */
    public get completed(): boolean {
        // Completed if the position has moved to the end.
        return this.position >= this.duration;
    }

    /**
     * Queues a tween from the current values to the target properties. Set duration to 0 to jump to these value.
     * Numeric properties will be tweened from their current value in the tween to the target value. Non-numeric
     * properties will be set at the end of the specified duration.
     * @param props Properties and values to which the transition must happen
     * @param duration Duration of animation in milliseconds
     * @param ease Easing method which can be represented by {@link Ease} class methods.
     */
    public to( props: Object, duration?: number, ease?: ( t: number ) => number ): Animation {
        super.to( props, duration, ease );
        this.propNames = Object.keys( props );
        return this;
    }

    /**
     * Cancels the current tween.
     * Stops it at the current position and clears all resources associated.
     */
    public cancel() {
        if ( !this.completed ) {
            this.removeFromTweens();
            this.clear();
        }
    }

    /**
     * Returns an observable which will emit every change that happens to the given
     * set of properties in the to() method. This will emit all the properties with
     * their current values.
     */
    public changes(): Observable<any> {
        // Changes subject is already created.
        if ( this._progress ) {
            return this._progress;
        }

        // Tween already completed.
        if ( this.completed ) {
            return empty();
        }

        // Create a new subject and emit changes and completion.
        this._progress = new Subject();
        this.addEventListener( 'change', () => {
            this._progress.next( pick( this.target, this.propNames ));
        });
        this.call(() => {
            this._progress.next( pick( this.target, this.propNames ));
            this.clear();
        });
        return this._progress;

    }

    /**
     * Removes the tween from the global list of tweens.
     */
    private removeFromTweens() {
        const tweens = ( <any>Tween )._tweens;
        const i = tweens.indexOf( this );
        if ( i > -1 ) {
            tweens.splice( i , 1 );
        }
        this._paused = true;
    }

    /**
     * Completes the progress subject and clears the resources attached to it.
     */
    private clear() {
        if ( this._progress ) {
            this._progress.complete();
            this.removeAllEventListeners( 'change' );
            this._progress = undefined;
        }
    }

}
