import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UserLocator } from '../../user/user-locator.svc';
import { Crisp } from 'crisp-sdk-web';
import { AppConfig } from 'flux-core';

/**
 * Any action on the Crisp API must be performed via this class and
 * this will be the wrapper for Crisp functionality
 */
@Injectable()
export class CrispChat {

    public tokenId: string;
    public myAskAiScriptLoaded: Promise<void>;
    public crispSessionLoaded: Promise<void>;
    private crispSessionLoadedInitialized = false;

    constructor( protected userLocator: UserLocator ) {
        this.userLocator.getUserData().pipe(
            filter( user => !!user ),
        ).subscribe( user => {
            this.setUser( user.fullName, user.email );
            this.tokenId = btoa( user.id );
        });
    }

    /**
     * Method that loads MyAskAI script & Crisp chat
     * Both are kept hidden initially
     * TokenID is set to keep crisp chat session continuity
     */
    public load() {
        this.myAskAiScriptLoaded = new Promise<void>( resolve => {
            this.loadMyAskAi( resolve );
        });
        Crisp.configure( AppConfig.get( 'CRISP_CHAT' ));
        Crisp.chat.hide();
        Crisp.setTokenId( this.tokenId );
        this.setupCrispSessionLoadedEvent();
    }

    /**
     * Loads/appends MyAskAi script to head tag & keeps it hidden
     * @param callback - for when script is loaded
     */
    public loadMyAskAi( callback?: () => void ) {
        const myAskAiId = AppConfig.get( 'MY_ASKAI_ID' );

        if ( !document.getElementById( myAskAiId )) {
            const script = document.createElement( 'script' );
            script.defer = true;
            script.type = 'text/javascript';
            script.src = `https://myaskai.com/api/1.1/wf/embed-v2?i=${myAskAiId}`;
            script.id = myAskAiId;
            script.dataset.openOnLoad = 'true';

            // Append the script element to the head
            document.head.appendChild( script );

            // Create a style element to hide the floating button and widget frame
            const style = document.createElement( 'style' );
            style.id = 'hide-askai-style';
            style.textContent = `
                #askai-floating-button,
                .askai-frame-widget {
                    display: none !important;
                }
            `;

            // Append the style element to the head
            document.head.appendChild( style );

            // Wait for the script to load before executing the callback
            script.onload = () => {
                if ( callback ) {
                    callback();
                }
            };
        } else if ( callback ) {
            // If script is already loaded, execute the callback immediately
            callback();
        }
    }

    /**
     * Opens support chat
     */
    public async openSupportChat() {
        // Wait until Crisp session has loaded
        await this.crispSessionLoaded;

        // If MyAskAi chat had been transferred to crisp, open crisp chat
        // Otherwise open MyAskAi chat
        if ( Crisp.session.getData( 'transferred_to_crisp' )) {
            Crisp.chat.show();
            Crisp.chat.open();
        } else {
            this.openMyAskAi();
        }
    }

    /**
     * Method that shows and opens the MyAskAi chat widget
     * Note - MyAskAI will open crisp chat automatically
     * if chat is transferred to a person
     */
    public async openMyAskAi() {
        // Wait until MyAskAi script has been loaded
        await this.myAskAiScriptLoaded;

        const askAiWidget = document.querySelector( '.askai-frame-widget' );
        const askAiButton = document.getElementById( 'askai-floating-button' );

        askAiWidget.setAttribute( 'style', 'display: block !important; opacity: 1;' );
        askAiButton.setAttribute( 'style', 'display: block !important;' );
        askAiButton.classList.add( 'maa-close-floating-button' );

        askAiButton.addEventListener( 'click', () => {
            askAiButton.setAttribute( 'style', 'display: none !important;' );
        });
    }

    /**
     * Method that searches and returns help articles as an array.
     * @param query article search query
     */
    public search( query: string ): Promise<any> {
        return new Promise( resolve => {
            Crisp.chat.queryHelpdesk( query );
            Crisp.chat.onHelpdeskQueried( data => {
                // Executed once a helpdesk search has been queried
                if ( data.results.length > 0 ) {
                    resolve( data.results );
                } else {
                    resolve([]); // Resolve with an empty array if no results
                }
            });
        });
    }

    public setupCrispSessionLoadedEvent() {
        if ( !this.crispSessionLoadedInitialized ) {
            this.crispSessionLoadedInitialized = true;

            this.crispSessionLoaded = new Promise<void>( resolve => {
                Crisp.session.onLoaded(() => {
                    // Executed once the Crisp session is loaded
                    resolve();
                    this.setupCrispChatOpenedEvent();
                    this.setupCrispChatClosedEvent();
                });
            });
        }
    }

    public setupCrispChatOpenedEvent() {
        Crisp.chat.onChatOpened(() => {
            // Executed once the Crisp chat is opened
            Crisp.session.setData({
                transferred_to_crisp: true,
              });
          });
    }

    /**
     * Method that hides Crisp chat when the chat is closed
     */
    public setupCrispChatClosedEvent() {
        Crisp.chat.onChatClosed(() => {
            Crisp.chat.hide();
        });
    }

    /**
     * Method that redirects to the crisp help center
     */
    public helpCenter() {
        window.open( 'https://support.creately.com', '_blank' );
    }

    /**
     * Method that adds user details to Crisp
     * @param fullName full name of the app user
     * @param email email of the app user
     */
    private setUser( fullName: string, email: string ) {
        Crisp.user.setEmail( email );
        Crisp.user.setNickname( fullName );
    }

}
