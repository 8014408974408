import { AbstractCommand, StateService } from 'flux-core';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';

/**
 * The common abstraction of commands that allow starting mouse movement of
 * shapes or connectors or the handles of those. This will have common functionality
 * for starting the movement of interactions. This class is only an abstraction and
 * not a command that can be executed.
 */
export abstract class StartMovingSelection extends AbstractCommand {

    constructor( protected state: StateService<any, any> ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Waits for a connector selection to be added to the interaction canvas with
     * given connector in it. It will emit the connector selection when available.
     */
    protected waitForConnectorSelection( id: string ): Observable<any> {
        // FIXME: Move starting the connector interaction out of the command to connector interaction class.
        return this.state.changes( 'CurrentSelection' ).pipe(
            filter(( selection: any ) => (
                // FIXME: The state service does not allow null, using `{}` for no selection.
                ( selection.selectedIds || []).includes( id )
            )),
            take( 1 ),
        );
    }
}

Object.defineProperty( StartMovingSelection, 'name', {
    value: 'StartMovingSelection',
});
