import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { IShapeNode } from '../svg-node/shape-node.i';
import { SvgTransformationToMatrix } from '../svg-transform-to-matrix';
import { IStyle, FillStyle } from 'flux-definition';
import {
    IGradientInstruction,
    IGradientInstructionFactory,
    GradientUsageType,
    GradientType,
} from './canvas-instruction.i';

/**
 * class RadialGradientCanvasInstructionFactory
 * This class implements functionality to translate svg radial gradients to canvas instructions.
 * NOTE: This currently support radial gradient fills only, strokes to be implemented
 */
export class RadialGradientCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements IGradientInstructionFactory {

    public svgElementName: string = 'radialGradient';

    /**
     * Create radial gradient canvas instructions from given shape node
     * @param data
     */
    public createInstruction( data: IShapeNode, gradientUsageType: GradientUsageType ): IGradientInstruction {
        this.styleDefinitions = [];
        const colorAndOffsets = this.exctractGradientColorOffsets( data );
        if ( this.hasGradientPercentages( data, 'radialGradient' )) {
            const color = this.getWidelyUsedGradientColor( colorAndOffsets.colors, colorAndOffsets.ratios );
            const style: Partial<IStyle> = { fillColor: color, fillStyle: FillStyle.Solid };
            const styleDef = { locked: false, priority: 1, style };
            const styleId = this.generateStyleId( styleDef );
            this.styleDefinitions.push({ id: styleId, def: styleDef });
            return {
                gradient: this.getGradientStyleDefinition( styleId, gradientUsageType ),
                styleDefinitions: this.styleDefinitions,
            };
        }

        const point = this.getPoints( data );
        return {
            gradient: {
                instruction: this.getGradientInstructionType( GradientType.radialGradient, gradientUsageType ),
                params: [
                    `[${colorAndOffsets.colors.map( c => `'${c}'` ).join( ',' )}]`,
                    `[${colorAndOffsets.ratios.map( r => +r ).join( ',' )}]`,
                    `${this.sw} * ${point.fx} + ${this.xo}`,
                    `${this.sh} * ${point.fy} + ${this.yo}`,
                    `${this.sw} * ${point.fr} + ${this.xo}`,
                    `${this.sw} * ${point.cx} + ${this.xo}`,
                    `${this.sh} * ${point.cy} + ${this.yo}`,
                    `${this.sw} * ${point.r} + ${this.xo}`,
                ],
            },
            styleDefinitions: this.styleDefinitions,
        };
    }

    /**
     * Return the gradient coordinates from given shape node. If there is a gradient transformation matrix is
     * applied, points need to be calculated based on the matrix.
     * @param data
     */
    private getPoints( data: IShapeNode ): { cx: number, cy: number, r: number, fx: number, fy: number, fr: number } {
        const cx = +data.data.cx;
        const cy = +data.data.cy;
        const r = +data.data.r;
        const fx = ( data.data.fx ) ? +data.data.fx : cx;
        const fy = ( data.data.fy ) ? +data.data.fy : cy;
        const fr = ( data.data.fr ) ? +data.data.fr : 0;

        if ( data.data.gradientTransform ) {
            const matrix = SvgTransformationToMatrix.getMatrix( data.data.gradientTransform );
            const pointC = matrix.transform( cx, cy );
            const pointR = matrix.transform( cx + r, cy );
            const pointFC = matrix.transform( fx, fy );
            const pointFR = matrix.transform( fx + fr, fy );
            return {
                cx: pointC.x,
                cy: pointC.y,
                r: Math.abs( pointR.x - pointC.x ),
                fx: pointFC.x,
                fy: pointFC.y,
                fr: Math.abs( pointFR.x - pointFC.x ),
            };
        }
        return { cx, cy, r, fx, fy, fr };
    }

}
