import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, PopupWindow, StateService } from 'flux-core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    templateUrl: './create-saved-sets-dialog.cmp.html',
    selector: 'create-saved-sets',
    styleUrls: [ 'create-saved-sets-dialog.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSavedSetDialog extends PopupWindow implements OnInit, OnDestroy {

    @Input() public setName: BehaviorSubject<string>;
    public name: string;

    public readonly = new BehaviorSubject( false );

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription> = [];

    constructor(
        private modalController: ModalController,
        protected state: StateService<any, any>,
        protected translate: TranslateService,
    ) {
        super();
    }

    /**
     * Starts the show animation.
     */
    public ngOnInit(): void {
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.subs.push( sub );
    }

    ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public closeWindow() {
        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    public createSavedSet() {
        this.setName.next( this.name );
        const loadingText = this.translate.instant( 'NOTIFICATIONS.EDATA.CREATE_SAVED_SET.ACKNOWLEDGEMENT' );
        this.state.set( 'AcknowledgementMessage', { open: true, message: loadingText, showLoader: true });
        this.closeWindow();
    }

}
