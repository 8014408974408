import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { StaticRenderer } from 'flux-diagram-composer';
import { StateService } from 'flux-core';
import { Router } from '@angular/router';

/**
 * This component is used to show canvas drawable thumbnails of a shape specifically
 * for Nucleus shapes that implement the IDrawable for thumbnails.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'collapsable-edata-library-item',
    template:  `<collapsable-panel-item
                    [heading]="label"
                    [icon]="iconName">
                    <div header-content *ngFor="let i of shapeCounts | keyvalue">
                        <edata-library-item-count id="item.id"
                            *ngIf="i.value > 0"
                            [diagramId]="i.key"
                            [count]="i.value">
                        </edata-library-item-count>
                    </div>
                    <div class="collapsable-edata-library-item" content>
                        <edata-library-item
                            *ngFor="let child of children"
                            id="child.id"
                            [label]="child.text.title"
                            [icon]="child.icon"
                            [shapeCounts]="child.text.shapeCount"
                            [isDraggable]="false">
                        </edata-library-item>
                    </div>
                </collapsable-panel-item>`,
    styleUrls: [ './edata-library-item.scss' ],
})
export class CollapsableEDataLibraryItem {

    /**
     * Item icon
     */
    @Input() public iconName: string;

    /**
     * Item name
     */
    @Input() public label: number;

    /**
     * Item count in documents
     */
    @Input() public shapeCounts: { [ diagramId: string]: number };

    /**
     * Item children for inside the collapsible
     */
    @Input() public children: any[];

    constructor( protected rendered: StaticRenderer,
                 protected state: StateService<any, any>,
                 protected router: Router,
        ) {}
}
