import { Component, ChangeDetectionStrategy, Input, ElementRef, AfterViewInit } from '@angular/core';

/**
 * The user image component is used to display a user's image anywhere
 * in the application.
 *
 * Information such as the user image url, user state, user color,
 * inititals and image size should be passed into the component.
 *
 * @author nuwanthi
 * @since 2018-01-16
 */


@Component({
    selector: 'user-image',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './user-image.cmp.html',
    styleUrls: [ './user-image.scss' ],
})
export class UserImage implements AfterViewInit {

    /**
     * Specifies the size of the user image (xsmall, small, medium, large).
     */
    @Input() public imageSize: string;

    /**
     * Boolean check for if the user has an image or not.
     */
    @Input() public hasImage: boolean;

    /**
     * The path to the user image.
     */
    @Input() public imagePath: string;

    /**
     * Specifies if the user is registered. This is used to add a class "pending"
     * to the user image container.
     */
    @Input() public userRegistered: boolean;

    /**
     * An online user will have a color assigned to them. This color is displayed
     * in an indicator on the bottom right corner of the user's image. If the user doesn't have
     * an image, this color will be used to fill the background in place of the image. If the user
     * is offline or pending, this color will be grey.
     */
    @Input() public userColor: string = '#D8D8D8';

    /**
     * In a scenario where the user does not have an image, the user's initials will be displayed.
     * If the user does not have a name (ex: a pending user), the first letter of their email will be displayed.
     */
    @Input() public userInitials: string;

    /**
     * Specifies if the user is online or not. This is used to add a class "online"
     * to the user image container when the user is online and a class "offline" if the
     * user is offline.
     */
    @Input() public userOnlineStatus: boolean;

    /**
     * To activate user strip mode
     * default false means in default mode
     */
    @Input() public isUserStripMode: boolean = false;

    /**
     * realtime user color should be only activated by isUserStipMode
     */
    @Input() public realtimeUserColor: string;


    /**
     * spotlight state
     */
    @Input() public spotlightState: boolean;

    /**
     * spotlight requesting state
     */
    @Input() public spotlightRequestingState: boolean;

    /**
     * display image inline
     */
    @Input() public inline: boolean = false;

    /**
     * in component user strip hover
     */
    public userStripHover = false;

    /**
     * in component user strip stryle ref
     */
    public style: any;

    constructor( private elRef: ElementRef ) {}

    ngAfterViewInit() {
        this.style = this.elRef.nativeElement.querySelector( '.user-img-container' ).style;
        this.style.setProperty( '--background-color-user-img-container', 'transparent' );
    }

    public getBorder() {
        if ( this.isUserStripMode ) {
            let borderStyle = 'solid';
            let borderColor = '#ffffff';

            if ( !!this.realtimeUserColor && this.realtimeUserColor !== '#ffffff' ) {
                borderStyle = 'solid';
                borderColor = this.realtimeUserColor;
            } else {
                if ( this.userStripHover ) {
                    borderStyle = 'solid';
                    borderColor = this.userColor;
                } else {
                    borderStyle = 'solid';
                    borderColor = '#ffffff';
                }
            }
            /* istanbul ignore next */
            if (( this.spotlightState || this.spotlightRequestingState ) &&  this.style ) {
                this.style.setProperty( '--background-color-user-img-container',
                (() => {
                    let color = 'transparent';
                    if ( !!this.realtimeUserColor ) {
                        if ( this.realtimeUserColor === '#ffffff' ) {
                             color =  this.userColor ?? color;
                        } else {
                            color = this.realtimeUserColor ?? color;
                        }
                    }
                    return color;
                  })());
                return {};
            }
            return { border: `2px ${borderStyle} ${borderColor}` };
        } else {
            return {};
        }
    }
}
