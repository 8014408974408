import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    AbstractCommand, AbstractNotification, Command,
    CommandInterfaces, NotificationType, NotifierController,
} from 'flux-core';
import { DataType } from 'flux-definition';
import { LOOKUP_CONNECTOR } from 'flux-diagram-composer';
import { pickBy } from 'lodash';
import { Subject, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { Notifications } from '../../../base/notifications/notification-messages';
import { ConnectorModel, IConnectorTypeDefiner } from '../../../base/shape/model/connector.mdl';

/**
 * Ask user if they want to replace the link associate with the connector shape.
 * data: {
 *     shapeIds - ids of the shape that needs to be removed
 * }
 */
@Injectable()
@Command()
export class ReplaceLinkDialog extends AbstractCommand {

    /**
     * This command is used to retrieve data. Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    data: {
        changes: {
            [connectorId: string]: IConnectorTypeDefiner | null,
        },
    };

    constructor(
        private dll: DiagramLocatorLocator,
        private notifierController: NotifierController,
        private translate: TranslateService,
    ) {
        super()/* istanbul ignore next */;
    }

    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    public execute() {
        if ( Object.keys( this.data.changes ).length !== 1 ) {
            return of( true );
        }
        const connectorId = Object.keys( this.data.changes )[0];
        return this.dll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
            switchMap( d => {
                const shape = d.shapes[connectorId] as ConnectorModel;
                if ( shape.defId !== LOOKUP_CONNECTOR.defId ) {
                    return of( true );
                }
                const fromShape = shape.getFromEndpoint( d ).shape;
                const lookupFields = pickBy( d.getShapeDataItems( fromShape.id ),
                    di => di.type === DataType.LOOKUP );
                const params: any = {};
                params.oldLinkType = lookupFields[shape.handshake[0]].label;
                const s = new Subject<boolean>();
                if ( this.data.changes[connectorId].defId === LOOKUP_CONNECTOR.defId ) {
                    params.newLinkType = lookupFields[this.data.changes[connectorId].handshake[0]].label;
                    this.showRemoveLinkDialog( s, shape, params, true );
                } else {
                    this.showRemoveLinkDialog( s, shape, params );
                }
                return s;
            }),
        );
    }

    protected showRemoveLinkDialog( s: Subject<boolean>, shape, params, replace = false ) {
        const key = replace ? 'REPLACE_ENTITY_LINK' : 'DISCARD_ENTITY_LINK';
        const translate = this.translate.instant.bind( this.translate );
        const notificationData = {
            id: Notifications.REMOVE_ASSOCIATED_LINK,
            component: AbstractNotification,
            type: NotificationType.Neutral,
            collapsed: false,
            options: {
                inputs: {
                    heading: translate( `NOTIFICATIONS.EDATA.${key}.HEADING`, params ),
                    description: translate( `NOTIFICATIONS.EDATA.${key}.DESCRIPTION`, params ),
                    autoDismiss: false,
                    buttonOneText: translate( `NOTIFICATIONS.EDATA.${key}.BUTTON_ONE_TEXT` ),
                    buttonTwoText: translate( `NOTIFICATIONS.EDATA.${key}.BUTTON_TWO_TEXT` ),
                    onDismiss: () => {
                        s.error( new Error( 'User Opted not to delete' ));
                    },
                    buttonOneAction: () => {
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_LINK );
                        shape.$$removeLinks = true;
                        s.complete();
                    },
                    buttonTwoAction: () => {
                        this.notifierController.hide( Notifications.REMOVE_ASSOCIATED_LINK );
                        s.complete();
                    },
                },
            },
        };
        return this.notifierController.show( Notifications.REMOVE_ASSOCIATED_LINK, notificationData.component,
            notificationData.type, notificationData.options, notificationData.collapsed );
    }

}

Object.defineProperty( ReplaceLinkDialog, 'name', {
    value: 'ReplaceLinkDialog',
});
