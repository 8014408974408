import { DiagramToViewportCoordinate } from './../../coordinate/diagram-to-viewport-coordinate.svc';
import { CommandService, ModifierUtils, Rectangle } from 'flux-core';
import { ConnectorModel } from './../../shape/model/connector.mdl';
import { ShapeModel } from './../../shape/model/shape.mdl';
import { ConnectorTextModel } from './../../shape/model/text/connector-text.mdl';
import { TextDataModel, TextPostion } from 'flux-diagram-composer';
import { IPoint2D, IText, ShapeType } from 'flux-definition';
import { DiagramLocatorLocator } from './../../diagram/locator/diagram-locator-locator';
import { Injectable } from '@angular/core';
import { StateService } from 'flux-core';
import { filter, map, mapTo, startWith, switchMap, delay, tap } from 'rxjs/operators';
import { merge, Observable, of, NEVER, Subject } from 'rxjs';
import { ShapeTextModel } from '../../shape/model/text/shape-text.mdl';


export enum EditorActions {
    Init,
    ContentUpdate,
    PanZoomChange,
    ShapeSizeChange,
}

// tslint:disable:member-ordering
/**
 * This service is to get the location of the shape text editor child nodeviews
 */
@Injectable()
export class ShapeTextEditorPositionService {

    public static updateManualy = new Subject();

    constructor(
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        protected commandService: CommandService,
        protected dToVcoordinate: DiagramToViewportCoordinate,
    ) {

    }

    /**
     * Calculate the position of the shape text editor
     *
     * @param editorElSelector tiptap-child-editor-content selector
     * @param shapeId
     * @param textModel
     * @param editorContentUpdate
     * @param beforeWordWrap beforeWordWrap Function
     * @returns beforeWordWrap Function
     */
    public updateLocation(
        editorNodeSelector: string,
        shapeId: string,
        textModel: IText,
        editorContentUpdate: Observable<any>,
        beforeWordWrap = () => {},
        ):
        Observable<{ action: EditorActions, positionData: IPositionData, positionStyles }> {
            /**
             * The position of the text editor should be updated whenever
             * the text editor contenet updates and zoom pan change occurs
             */
            let positionData;
            return merge(
                ShapeTextEditorPositionService.updateManualy.pipe(
                    mapTo( EditorActions.ContentUpdate ),
                    delay( 10 ),
                ),
                editorContentUpdate.pipe( mapTo( EditorActions.ContentUpdate )),
                this.getPanZoomChanges().pipe( mapTo( EditorActions.PanZoomChange )),
                this.getShapeScaleChange( shapeId ).pipe( mapTo( EditorActions.ShapeSizeChange )),
            ).pipe(
                startWith( EditorActions.Init ),
                tap( action => {
                    if ( action === EditorActions.ContentUpdate ) {
                        beforeWordWrap();
                    }
                }),
                 // beforeWordWrap triggers applyText priview command which results in updating
                 // preview text model, this model is added to make sure text model is
                 // updated in the following step
                delay( 0 ),
                switchMap( action => this.ll.forCurrent( true ).getShapeOnce( shapeId ).pipe(
                    map( shape => ({ shape, action })),
                )),
                switchMap(({ shape, action }) => {
                    /**
                     * Text model calculation should be avoided for pan zoom changes
                     */
                    const editorEl = document.createElement( 'div' );
                    const parentEl = document.createElement( 'div' );
                    parentEl.appendChild( editorEl );
                    const editorElOrig = document.querySelector( editorNodeSelector ) as HTMLElement;
                    if ( !editorElOrig ) {
                        return NEVER;
                    }
                    const childStyles = {
                        width: '',
                        transform: `scale(${this.state.get( 'DiagramZoomLevel' )})`,
                        whiteSpace: '',
                    };
                    const parentStyles = {
                        top: '',
                        left: '',
                        transform: '',
                        transformOrigin: '',
                    };
                    textModel = !textModel ? shape.texts[ textModel.id ] : textModel;
                    // const { width, wordWrap } = this.wordWrap( shape, textModel );
                    // childStyles.width = wordWrap ? width + 'px' : 'fit-content';
                    // childStyles.whiteSpace = wordWrap ? 'unset' : 'nowrap';

                    const shapeWidth = shape instanceof ShapeModel ?
                        ( shape as ShapeModel ).defaultBounds.width * ( shape as ShapeModel ).scaleX :
                        shape.bounds.width;
                    const width = ( textModel as TextDataModel )
                        .getWordWrapWidth( shapeWidth, ( shape as any ).autoResizeWidth );
                    childStyles.width = width;
                    Object.keys( childStyles ).forEach( style => {
                        editorEl.style[ style ] = childStyles[ style ];
                    });

                    if ( action !== EditorActions.PanZoomChange ||
                        positionData?.editorBounds?.width === 0 ||
                        shape.texts.iframe ) {
                        positionData = this.calculatePosition( editorElOrig, shape, textModel );
                    }

                    if ( !positionData ) {
                        return NEVER;
                    }

                    const onShapeX = this.dToVcoordinate.x( positionData.pointOnShape.x );
                    const onShapeY = this.dToVcoordinate.y( positionData.pointOnShape.y );
                    const onTextX = this.dToVcoordinate.width( positionData.pointOnText.x );
                    const onTextY = this.dToVcoordinate.height( positionData.pointOnText.y );

                    parentStyles.top = onShapeY + 'px';
                    parentStyles.left = onShapeX + 'px';
                    parentStyles.transform = `
                        rotate(${positionData.angle}deg)
                        translateX(${-onTextX}px)
                        translateY(${-onTextY}px)
                    `;
                    parentStyles.transformOrigin = `top left`;

                    Object.keys( parentStyles ).forEach( style => {
                        editorEl.parentElement.style[ style ] = parentStyles[ style ];
                    });

                    const parentStyleString = editorEl.parentElement.getAttribute( 'style' );
                    const childStylesString = editorEl.getAttribute( 'style' );
                    return of({ action, positionData, positionStyles: { childStylesString, parentStyleString }});
                }),
            );
    }

    protected getShapeScaleChange( shapeId ) {
        return this.ll.forCurrent( true ).getShapeChanges( shapeId ).pipe(
            filter( c => {
                if ( c.source !== 'init' && c && c.modifier ) {
                    return ModifierUtils.hasChanges( c.modifier, 'scaleX' ) ||
                        ModifierUtils.hasChanges( c.modifier, 'scaleY' );
                }
            }),
        );
    }

    protected calculatePosition( el, shapeModel, textModel ): any {
        const editorBounds = this.getBounds( el );
        let pointOnShape;
        let pointOnText;
        let angle = 0;
        if ( shapeModel.type === ShapeType.Connector ) {
            pointOnShape = TextPostion.getPointOnConnector( shapeModel as ConnectorModel,
                textModel as ConnectorTextModel );
            pointOnText = { x : editorBounds.width / 2, y: editorBounds.height / 2 };
        } else {
            const position = {
                x: { type: textModel.xType, value: textModel.x },
                y: { type: textModel.yType, value: textModel.y },
            };
            pointOnShape = TextPostion
                .getPointOnShape( position,
                    ( shapeModel as ShapeModel ).defaultBounds, ( shapeModel as ShapeModel ).transform );
            pointOnText = TextPostion.getPointOnText(
                editorBounds, textModel.alignX,  ( textModel as ShapeTextModel ).alignY,
                ( shapeModel as ShapeModel ).transform );

            // FIXME Do Dev Testing!! CJ - 28/01/2021 - @thisun check
            angle =  TextPostion.getTextAngle(( shapeModel as ShapeModel ).transform );
            angle =  angle + ( textModel as any ).angle || 0;
        }
        return { pointOnShape, pointOnText, angle, editorBounds };
    }

    /**
     * Returns the bounds of the text editor
     */
    protected getBounds( el ): Rectangle {
        const rect = el.getBoundingClientRect();
        const y = rect.top;
        const x = rect.left;
        const width = el.clientWidth;
        const height  = el.clientHeight;
        return new Rectangle( x, y, width, height );
    }

    /**
     * This observable emits for any change in
     * diagram pan or zoom
     */
     protected getPanZoomChanges(): Observable<any> {
        return merge(
            this.state.changes( 'DiagramPan' ),
            this.state.changes( 'DiagramZoomLevel' ),
        );
    }

}

/**
 * Interace to define data required to position the text editor on the shape text view
 */
export interface IPositionData {
    pointOnShape: IPoint2D;
    pointOnText: IPoint2D;
    angle: number;
}
