import { Injectable } from '@angular/core';
import { Command, CommandScenario } from 'flux-core';
import { ShapeType } from 'flux-definition';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { PathingService } from '../../diagram/pathing/pathing.svc';
import { isEqual } from 'lodash';

/**
 * Update connector bumps on given connector and all connectors above it (by z-index).
 * Optionally user can also pass an array of deleted shapes, it'll be used for z-index
 * calculation but they will be ignored when calculating segment intersecting points.
 *
 *  data: {
 *      changes: {
 *          [shapeId string]: {}
 *      }
 *      removed: string[],
 *      minIndex: number,
 *      bounds: IRectangle  // TODO: to only run for shapes intersecting given bounds
 *  }
 *
 */
@Injectable()
@Command()
export class AddConnectorBumps extends AbstractDiagramChangeCommand {
    constructor(
        protected ds: DiagramChangeService,
        protected pathingSvc: PathingService,
    ) {
        super( ds );
    }

    public prepareData() {
        // FIXME: this is breaking the concept that commands should not be aware of the
        //        the execution scenario but needed to improve the frame rate.
        if ( this.eventData.scenario === CommandScenario.PREVIEW ) {
            return;
        }
        const removed = this.data.removed;
        const connectors = Object.keys( this.changeModel.shapes )
            .map( shapeId => this.changeModel.shapes[ shapeId ] as ConnectorModel )
            .filter( shape => (
                shape.type === ShapeType.Connector &&
                ( !removed || removed.indexOf( shape.id ) === -1 )
            ));
        for ( const con of connectors ) {
            // Calculate draw bumps only if the connector bumb is enabled for the connetor or globally.
            if ( con.showBumps ) {
                // TODO: ignore connectors whose bounds do not overlap
                const points = this.pathingSvc.setBumps( con.id, this.changeModel, null, removed );
                // TODO: ignore connectors which did not get affected
                const newPath = ConnectorModel.createPathFromPoints( points );
                if ( !isEqual( con.path, newPath )) {
                    con.path = newPath;
                }
            }
        }
    }
}

Object.defineProperty( AddConnectorBumps, 'name', {
    value: 'AddConnectorBumps',
});
