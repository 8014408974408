import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { IFeatureItem } from 'apps/nucleus/src/framework/feature/feature-item.i';
import { CommandService, onlyOnce, Tracker } from 'flux-core';
import { concat, defer, fromEvent, Observable, Subscription, of, forkJoin, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FeatureList } from '../../../framework/feature/feature-list.svc';
import { IContextMenuItem } from '../../../framework/ui/interaction/context-menu/context-menu-item.i';
import { ContextMenu } from '../../ui/context-menu/context-menu.cmp';

/**
 * ContextMenuController
 * This is responsible for displaying a context menu all across the application.
 * This shows the context menu on the component which implements IContextMenuAware.
 *
 * Event which needs to show the context menu should be registered through an observable
 * in this controller.
 */
@Injectable()
export abstract class ContextMenuController {

    /**
     * Container where context menu can be attached.
     */
    public viewContainerRef: ViewContainerRef;

    /**
     * The list of subscriptions held by this controller
     */
    protected subs: Subscription[];

    /**
     * Holds the current context menu
     */
    protected currentContextMenu: ContextMenu;

    /**
     * Sources of events which will trigger the contextual menu.
     */
    protected sources: { [ id: string ]: Subscription } = {};

    constructor( protected injector: Injector,
                 protected commandService: CommandService,
                 protected featureList: FeatureList ) {
        // FIXME: This disables the default browser context menu therfore needs to add a
        // solution to enable the contextmenu whereever the IContextmenuAware interface is
        // not implemented.
        const contextMenuSub = this.getContextMenuEvent()
            .subscribe( e => {
                const enableOSRichtClick = document.activeElement?.getAttribute( 'contenteditable' ) === 'true'
                    || document.activeElement.nodeName === 'INPUT'
                    || document.activeElement.nodeName === 'TEXTAREA';
                if ( !enableOSRichtClick ) {
                    e.preventDefault();
                }
            });

        this.subs = [ contextMenuSub ];
    }

    /**
     * This registered an event which needs to show the context menu.
     * This shows the context menu on the component where the given event is fired.
     * This needs to be called from the compoenent which needs to show
     * the context menu.
     * @param eventSource An obseravble from the event fired to show the context menu.
     */
    public registerEventSource( id: string, eventSource: Observable<Event> ) {
        this.unregisterEventSource( id );
        this.sources[ id ] = eventSource
            .pipe( switchMap( event =>
                this.getContextMenuItems( event as any ).pipe(
                    switchMap( items =>  this.createMenu( event as any, items )),
                ),
            ))
            .subscribe();
    }

    /**
     * Removes an event source which will show the context menu by id.
     */
    public unregisterEventSource( id: string ): void {
        if ( this.sources[ id ]) {
            this.sources[ id ].unsubscribe();
            delete this.sources[ id ];
        }
    }

    /**
     * Destroys and clears all the subscription used by this controller
     */
    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
        // tslint:disable-next-line:forin
        for ( const id in this.sources ) {
            this.unregisterEventSource( id );
        }
    }

    /**
     * This returns the context menu item for the given item id
     */
    protected getContextMenuItems( event: MouseEvent ): Observable<IContextMenuItem[]> {
        return this.getContextMenuItemsId( event ).pipe(
            map( ids => ids.map( id => this.featureList.getFeature( id )),
        ));
    }

    /**
     * This should be overriden to return the list of context menu items that must be shown
     * based on the context
     */
    protected abstract getContextMenuItemsId( event: MouseEvent ): Observable<string[]>;

    /**
     * This destroys the previous menu and creates new context menu
     */
    protected createMenu( event: MouseEvent, items: IContextMenuItem[]): Observable<any> {
        return concat(
            this.prepareItems( items ),
            this.clearMenu(),
            defer(() => this.showMenu( event, items )),
        );
    }

    /**
     * Creates and shows the context menu
     */
    protected showMenu( event: MouseEvent, items: IContextMenuItem[]): Observable<any> {
        this.currentContextMenu = ContextMenu.create( this.injector, items );
        this.currentContextMenu.menuItemclick
            .subscribe( val => this.dispatchMenuAction( val.item, val.event, event ));
        return this.currentContextMenu.show( this.viewContainerRef, event.clientX, event.clientY );
    }

    /**
     * This dispatches an action of the given context menu item.
     */
    protected dispatchMenuAction( item: IContextMenuItem, mouseEvent: MouseEvent, event: any ) {
        Tracker.track( `canvas.contextMenu.${item.id}.click` );
        const cItem = item as IFeatureItem;
        if ( item.switch !== undefined ) {
            mouseEvent.stopPropagation();
        }
        if ( cItem.transformer || cItem.alterFunctions?.getApplyData ) {
            const transformer = cItem.transformer ? this.injector.get( cItem.transformer ) : cItem.alterFunctions;
            const dispatchFn = onlyOnce( data => {
                item.data.contextMenuEvent = {
                    offsetX: event.offsetX,
                    offsetY: event.offsetY,
                };
                this.commandService.dispatch( cItem.commandEvent, data );
                if ( item.switch !== undefined ) {
                    item.switch = !item.switch;
                    item.switchSubject.next( item.switch );
                }
            });
            transformer.getApplyData( cItem.id, cItem.data ).subscribe( dispatchFn );
            return;
        }
        item.data.contextMenuEvent = {
            offsetX: event.offsetX,
            offsetY: event.offsetY,
        };
        this.commandService.dispatch( item.commandEvent, item.data );
    }

    /**
     * Destroys the current context menu
     */
    protected clearMenu(): Observable<any> {
        if ( this.currentContextMenu ) {
            return this.currentContextMenu.destroy().pipe(
                tap({
                    complete: () => {
                        this.currentContextMenu = undefined;
                    },
                }),
            );
        }
        return of({});
    }

    protected prepareItems( items ): Observable<any> {
        let obs: Observable<any>[];
        obs = items.map( item => this.isSwitchOn( item ));
        return forkJoin( obs ).pipe(
            map( data => items.map(( item, index ) => {
                if ( data[index] !== undefined ) {
                    item.switch = data[index];
                    item.switchSubject = new BehaviorSubject( item.switch );
                }
                return item;
            })),
        );
    }

    protected isSwitchOn( feature: IFeatureItem ) {
        if ( feature?.transformer ) {
            const transformer = this.injector.get( feature.transformer );
            if ( transformer.isSwitchOn ) {
                return transformer.isSwitchOn( feature.id );
            }
        }
        return of( undefined );
    }

    /**
     * Returns Obervable from contextmenu event on the app
     */
    protected getContextMenuEvent(): Observable<Event> {
        return fromEvent( document, 'contextmenu' );
    }
}
