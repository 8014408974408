import { Injectable } from '@angular/core';
import { DataSourceDomain } from '../model/data-source.model';
import { NangoService } from '../nango-service';
import { Logger } from 'flux-core';

/**
 * Cached github service.
 */
@Injectable()
export class GithubService extends NangoService {
    private authenticated: Promise<boolean>;
    private ownersAndRepos: Promise<any>;
    private userPromise: Promise<any>;

    public isAuthenticated(): Promise<boolean> {
        if ( !this.authenticated ) {
            this.authenticated = this.fetch( 'isAuthenticated' );
        }
        return this.authenticated;
    }

    public async authorize( authWaitTime: number ): Promise<boolean> {
        let timeoutId: any;
        return Promise.race([
            this.authenticate( DataSourceDomain.Github, window.gravity.client.getCurrentUserID())
                .then( authResult => {
                    clearTimeout( timeoutId );
                    return !authResult.isPending;
                }).then( success => {
                    this.clearCached();
                    this.authenticated = Promise.resolve( success );
                    return success;
                }),
            new Promise( resolve => {
                timeoutId = setTimeout(() => {
                    Logger.error( 'Github authentication timeout' );
                    resolve( false );
                }, authWaitTime );
            }) as Promise<boolean>,
        ]);
    }

    public getUser( forceReload: boolean = false ) {
        if ( !this.userPromise || forceReload ) {
            this.userPromise = this.fetch( 'getUser' );
        }
        return this.userPromise;
    }

    public fetchOwnersAndRepos( forceReload: boolean = false ) {
        if ( !this.ownersAndRepos || forceReload ) {
            this.ownersAndRepos = this.fetch( 'getAllRepos' );
        }
        return this.ownersAndRepos;
    }

    public getIssuesOrRequests( params: any ) {
        return this.fetch( 'getIssuesOrRequests', params );
    }

    public fetch( method: string, params?: any ) {
        const payload: any = {
            dataSource: DataSourceDomain.Github,
            method,
        };
        if ( params ) {
            payload.params = params;
        }
        return this.fetchPost( payload );
    }

    protected clearCached() {
        this.authenticated = null;
        this.ownersAndRepos = null;
        this.userPromise = null;
    }
}
