import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { EMPTY, from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserLocator } from 'flux-user';
import { TaskModel } from 'flux-diagram';
import { TaskLocator } from '../task-locator.svc';

@Injectable()
@Command()
/**
 * This command will align the task for the user
 * - check if the task exists for the given shape/context
 * - update the existing task with new assignes. if no assignees, delete the task
 * - update the tasks status/ owner details
 */
export class RemoveTask extends AbstractMessageCommand {
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    public data: {
        taskId: string,
    };

    constructor(
        protected userLocator: UserLocator,
        protected taskLocator: TaskLocator,
        protected translate: TranslateService,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public execute() {
        if ( this.data.taskId ) {
            const modelStore = this.dataStore.getModelStore( TaskModel );
            return from( modelStore.updateStatic({ id: this.data.taskId }, {
                $set: { deleted: true },
            }));
        }
        return EMPTY;
    }

    public executeResult() {
        return this.dataStore.remove( TaskModel, { id: this.resultData.task.id });
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( TaskModel );
        return from( modelStore.updateStatic({ id: this.data.taskId }, {
            $unset: { deleted: true },
        }));
    }
}

Object.defineProperty( RemoveTask, 'name', {
    value: 'RemoveTask',
});
