import { IGluePoint, IPoint2D, IPosition2D, PositionType, ITransform } from 'flux-definition';
import { Rectangle, Position } from 'flux-core';

/**
 * There are 2 types of gluepoints:
 *  defined: specified on the definition or dynamically added by logioc classes
 *  custom:  gluepoints added by users by hovering over a shape for some time.
 */
export type GluepointType = 'defined' | 'custom';

/**
 * A gluepoint is a point on a shape where a connector
 * can stick to. This is the model of a point as such.
 * Eventhough this exists as a model in the shape it is not a
 * part of the shape data. This actually is only used a visual indicator on
 * the shape during editing.
 *
 * The gluepoint can exist in relation to the shape's size or
 * in a fix position on the shape surface.
 *
 */
export class GluePointModel implements IGluePoint {

    /**
     * Returns the exact point on the shape where the gp should place
     * @param gp The GluePointModel to get the position
     * @param bounds The original bounds of the shape on which the gp places
     * @param transform The current transformation of the shape on which gp places
     */
    public static getPosition(  gp: GluePointModel, bounds: Rectangle, transform?: ITransform ): IPoint2D {
        const position: IPosition2D = {
            x: { type: gp.xType || 'relative', value: gp.x },
            y: { type: gp.yType || 'relative', value: gp.y },
        };
        return Position.onRect( position, bounds, transform );
    }

    public static side(  gp: GluePointModel, bounds: Rectangle, defBounds: Rectangle, transform?: ITransform ) {
        const point = GluePointModel.getPosition( gp, defBounds, transform );
        // Calculate distances from the point to each side of the rectangle
        const distances = {
            top: Math.abs( bounds.y - point.y ),
            bottom: Math.abs( bounds.y + bounds.height - point.y ),
            left: Math.abs( bounds.x - point.x ),
            right: Math.abs( bounds.x + bounds.width - point.x ),
        };
        // Find the side with the minimum distance
        let minDistance = Infinity;
        let closestSide = '';
        for ( const side in distances ) {
            if ( distances[side] < minDistance ) {
                minDistance = distances[side];
                closestSide = side;
            }
        }
        return closestSide;
    }

    /**
     * A unique id to identify the gluepoint.
     */
    public id: string;

    /**
     * Describes whether the gluepoint is a defined gluepoint (available on the definition)
     * or a custom gluepoint added by the user.
     */
    public type: GluepointType = 'defined';

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public xType: PositionType;

    /**
     * The x position of the gluepoint. The value that must be set is based on what is set in xType
     * property. For 'relative' this should a ratio value between 0 and 1. For 'fixed-start' this
     * should be a pixel value positioning from the left of the shape. For 'fixed-end' this should
     * be a pixel value positioning from the right of the shape.
     */
    public x: number;

    /**
     * Can be any of 'relative', 'fixed-start', 'fixed-end'. If not specified default is 'relative'.
     */
    public yType: PositionType;

    /**
     * The y position of the gluepoint. The value that must be set is based on what is set in yType
     * property. For 'relative' this should a ratio value between 0 and 1. For 'fixed-start' this
     * should be a pixel value positioning from the top of the shape. For 'fixed-end' this should
     * be a pixel value positioning from the bottom of the shape.
     */
    public y: number;

    /**
     * Should indicate howmany connectors can connect to this gluepoint.
     * Default is -1 which is unlimited.
     */
    public limit: number;

    /**
     * Limits which endpoints of a connector can connect to this gluepoint.
     *   all:  Any endpoint can connect to this gluepoint (default)
     *   one:  Only one of "from" or "to" ends can connect (multiple connections)
     *   from: Only the "from" endpoint can connect
     *   to:   Only the "to" endpoint can connect
     * Default would be 'all';
     */
    public limitEnd?: 'all' | 'one' | 'from' | 'to';

    /**
     * Describes how to calculate the connecting angle when endpoints connect
     * to this gluepoint. It can be one of the given calculating methods.
     *   closest-edge: select one of [ 0, 90, -90, 180 ] based on closest edge
     *   from-center:  angle to gluepoint position from the center of the shape
     *   [ number ]:   exact angle value ( if shape is not rotated/flipped )
     * Default value would be 'closest-edge'
     */
    public angleBy?: 'closest-edge' | 'from-center' | number = 'closest-edge';

    public connectionState: 'expanded' | 'collapsed' = 'expanded';
}
