import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { DiagramChangeService } from './../../../base/diagram/diagram-change.svc';
import { Injectable } from '@angular/core';
import { Command, CommandService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DataItemLevel } from 'apps/nucleus/src/base/edata/model/edata.mdl';

/**
 * UpdateShapeDataDefs
 * This commad is to add/update/remove data items from shapes
 * Only optional data items can be removed.
 */
@Injectable()
@Command()
export class UpdateShapeDataDefs extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
     public data: {
        [shapeId: string]: {
            [path: string]: any,
        },
    };

    constructor(
        protected modelChangeUtils:  ModelChangeUtils,
        protected command: CommandService,
        protected ds: DiagramChangeService,
    ) {
        super( ds );
    }

    public prepareData() {
        this.modelChangeUtils.updateShapeDataDefs(
            this.changeModel,
            this.data,
            DataItemLevel.Any,
        );
    }

}

Object.defineProperty( UpdateShapeDataDefs, 'name', {
    value: 'UpdateShapeDataDefs',
});
