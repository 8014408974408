import * as Carota from '@creately/carota';
import * as innerText from '@creately/inner-text';
import { DEFUALT_TEXT_STYLES, ITextContent, ITextFormat, ITextStyles, ITextFormatter } from 'flux-definition';
import { reduce } from 'lodash';
import { ICarotaDocument } from './carota-document.i';

/**
 * TextFormatter
 * This class is for doing text formating operations on html / text object
 * The main functionalties are to extrat the styles out of an html and and apply styles
 */
export class TextFormatter implements ITextFormatter {

    /**
     * Extracts the styles out of the given html within the specified range.
     * @param html  string  The HTML string to taxtract styles from
     * @param indexStart  number Optional, The index of the first character to include in extracting styles
     * @param indexEnd  number Optional, The index of the first character to exclude from extracting styles
     * @returns  Array of ITextStyle found for the given html within the specified range
     */
    public extract( texts: string | Object[], indexStart: number = 0, indexEnd?: number ): ITextStyles[] {
        const doc: ICarotaDocument = Carota.document( DEFUALT_TEXT_STYLES );
        doc.load( texts );
        indexEnd ? doc.select( indexStart, indexEnd ) : doc.selectFrom( indexStart );
        return doc.selectedRange().save().map( run => {
            delete run.text;
            return run;
        });
    }

    /**
     * Extracts the styls that are common to a partially formatted selection
     * @param html  string  The HTML string to taxtract styles from
     * @param indexStart  number Optional, The index of the first character to include in extracting styles
     * @param indexEnd  number Optional, The index of the first character to exclude from extracting styles
     * @returns  ITextStyle containing only the common style items
     */
    public extractCommon( texts: string | Object[], indexStart: number = 0, indexEnd?: number ): ITextStyles {
        const doc: ICarotaDocument = Carota.document( DEFUALT_TEXT_STYLES );
        doc.load( texts );
        if ( typeof texts === 'string' ) {
            const span = Carota.html.parse( texts, doc )[0];
            if ( span.text === '' ) {
                delete span.text;
                return span;
            }
        } else if ( texts.length === 1 ) {
            delete ( texts[0] as any ).text;
            return texts[0];
        }
        indexEnd ? doc.select( indexStart, indexEnd ) : doc.selectFrom( indexStart );
        const stylesArray: any [] = doc.selectedRange().save();
        const commonStyles = this.commonDifferentProperties( stylesArray.filter( run => run.text.trim())).common;
        delete commonStyles.text;
        return commonStyles;
    }

    /**
     * Extracts fonts from given html or carota content.
     */
    public extractFonts( texts: string | Object[]): string[] {
        const fonts = [];
        const styles = this.extract( texts );
        for ( const style of styles ) {
            if ( style.font && fonts.indexOf( style.font ) === -1 ) {
                fonts.push( style.font );
            }
        }
        return fonts;
    }

    /**
     * Returns the styles that are common
     * @param syles ITextStyles[]  Array of styles
     */
    public getCommonStyles( styles: ITextStyles[]): ITextStyles {
        return this.commonDifferentProperties( styles ).common;
    }

    /**
     * This is used to get the bounds for texts
     * @param data content array of the Carota
     * @param width shape width
     * @returns text bounds (width, height)
     */
    public getTextBounds( data: Object[] | string, width: number = 10000, fontSize: number = 10 ) {
        const mergedStyle = Object.assign({}, DEFUALT_TEXT_STYLES, { size: fontSize });
        return Carota.bounds( data, width, mergedStyle );
    }

    /**
     * Applies the ITextFormat to the given html or text object,
     * @param html  string
     * @param format  ITextFormat
     * @param reset  boolean If true removes the current styles otherwise preserve the exsiting styles
     * @returns  ITextContent[] with the applied the styles
     */
    public applyRaw( texts: string | ITextContent[], format: ITextFormat, reset: boolean = false ): ITextContent[] {
        let textToApply: any = texts;
        const isTextEmpty = this.plainText( texts ).length === 0;
        // fixme If the text content that is being processed it empty carota will return an empty array when
        // document is saved after setting the format. To workaround this, we add a whitespace to each text content
        // object and then apply the formatting. Ideally this should be properly handled by carota doc.save
        if ( isTextEmpty ) {
            textToApply = typeof texts === 'string' ? 'a' : [ ...texts ].map( t => ({ ...t, text: ' ' }));
        }
        const doc: ICarotaDocument = Carota.document( DEFUALT_TEXT_STYLES );
        doc.load( textToApply );
        format.indexStart ? doc.select( format.indexStart, format.indexEnd ) : doc.selectFrom( format.indexStart );
        const range = doc.selectedRange();
        if ( reset ) {
            Object.keys( DEFUALT_TEXT_STYLES ).forEach( k => {
                range.setFormatting( k , format.styles[ k ]);
            });
        }
        Object.keys( format.styles ).forEach( k => {
            range.setFormatting( k , format.styles[ k ]);
        });
        const resultText = doc.save();
        // fixme if the given text content is empty, need to remove the whitespaces added before returning
        if ( isTextEmpty ) {
            resultText.forEach( text => text.text = '' );
        }
        return resultText;
    }

    /**
     * Applies the ITextFormat to the given html or text object,
     * @param html  string
     * @param format  ITextFormat
     * @param reset  boolean If true removes the current styles otherwise preserve the exsiting styles
     * @returns  string  Html with the applied the styles
     */
    public apply( texts: string | ITextContent[], format: ITextFormat, reset: boolean = false ): string {
        try {
            return Carota.html.html( this.applyRaw( texts, format, reset ));
        } catch ( e ) {
            return '';
        }
    }

    /**
     * Extracts the plain text from an html string. This will remove all html elements but it will
     * add line breaks where necessary.
     *
     * @param html The html string to extract the text from
     */
    public plainText( text: string | ITextContent[]): string {
        if ( typeof text === 'string' ) {
            return innerText( text ).trim();
        }
        let plaintext = '';
        for ( const block of text ) {
            plaintext += block.text;
        }
        return plaintext.trim();
    }

    /**
     * Returns the common or different properties
     */
    private commonDifferentProperties( objects: any[]): { common: any, different: any } {
        const common = reduce( objects, ( acc, obj ) => {
            for ( const p in obj ) {
                acc[p] = obj[p];
            }
            return acc;
        }, {});
        const different = reduce( objects, ( acc, obj ) => {
            for ( const p in common ) {
                if ( common[p] !== obj[p]) {
                    delete common[p];
                    acc.push( p );
                }
            }
            return acc;
        }, []);
        return { common, different };
    }

}

/**
 * ITextStyleSet
 * This interface defines the type for styles in ITextFormat
 * that should be a map where key is the style name
 */
export interface ITextStyleSet {
    [styleName: string]: string | number;
}
