import { Observable, forkJoin } from 'rxjs';
import { IAbstractDefinition } from 'flux-definition';
import { IDefinitionLocator } from './definition-locator.i';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { IShapeDefinition } from 'flux-definition';

/**
 * This is an abstraction of the Definitions Locator. Definitions locator
 * functionality that is common to all implementations are included here.
 *
 * @author  Ramishka
 * @since   2017-09-24
 */
export abstract class AbstractDefinitionLocator implements IDefinitionLocator {

    /**
     * This method retrieves the JSON definition of a shape for the
     * given defId. It uses the resource loader to load the json either from
     * the serving location or the local cache.
     * @param defId is the uniqe definition id
     * @param version is the optional version of the spec.
     */
    public abstract getDefinition( defId: string, version?: number ): Observable<IAbstractDefinition>;

    /**
     * This method will load the source files of the given definition and get the
     * entry class of definition. The entry class can be instantiated to create
     * a shape view definition.
     * @param defId is the uniqe definition id
     * @param version is the optional version of the spec.
     * @return Observable that emits an object which contains the entry class
     * as well as the definition for the given defId.
     */
    public getEntryClass( defId: string, version?: number )
            : Observable<{ class: Function, def: IAbstractDefinition }> {
        return this.getDefinition( defId, version ).pipe(
            switchMap( def =>
                forkJoin(
                    this.getClass( def.entryClass ),
                    this.getSourceFiles( def.source ),
                    of( def ),
                ),
            ),
            map( emits => ({ class: emits[0], def: emits[2] })),
        );
    }

    /**
     * This method will load the source files of the given definition and get the
     * thumnail class of definition. The thumnail class can be used to draw different sizes of the thumbnail.
     * @param defId is the uniqe definition id
     * @param version is the optional version of the spec.
     * @return Observable that emits an object which contains the entry class
     * as well as the definition for the given defId.
     */
    public getThumbnailClass( defId: string, version?: number )
            : Observable<{ class: Function, def: IAbstractDefinition }> {
        return this.getDefinition( defId, version ).pipe(
            switchMap(( def: IShapeDefinition ) =>
                forkJoin(
                    this.getClass( def.thumbnail ),
                    this.getSourceFiles( def.source ),
                    of( def ),
                ),
            ),
            map( emits => ({ class: emits[0], def: emits[2] })),
        );
    }

    /**
     * This method will load the class based on the given Class Identifier. The
     * passed value should be Class Identifier which is a url which should have the class name as the hash
     * section of the url as "./path/to/file.js#ClassName".
     */
    public abstract getClass( classIdentifier: string ): Observable<Function>;

    /**
     * This method will load the class based on the given Class Identifier. The
     * passed value should be Class Identifier which is a url which should have the class name as the hash
     * section of the url as "./path/to/file.js#ClassName". This returns the value synchronously.
     */
    public abstract getCachedClass( classIdentifier: string ): Function;

    /**
     * This method must load the given source array of the definition to make available
     * withing the environment
     * @param source Array of strings which are source urls
     */
    protected abstract getSourceFiles( source: string[]): Observable<any>;

    /**
     * Validates a given Class Identifier and returns the URL and Class Name parts as two strings.
     * @param classIdentifier the string passed as class identifier
     * @returns an array that has the URL of the file and the Class Name, in that order.
     */
    protected getClassParameters(  classIdentifier: string ): [ string, string ] {
        if ( classIdentifier.includes( '#' )) {
            const classParams = classIdentifier.split( '#' );
            if ( classParams[0] && classParams[1]) {
                return [ classParams[0], classParams[1] ];
            }
        }
        throw new Error( 'The provided class identifier was invlaid: ' + classIdentifier );
    }
}
