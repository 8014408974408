import { Injectable } from '@angular/core';
import { Command, CommandService, StateService } from 'flux-core';
import { TaskModel } from 'flux-diagram';
import { DataStore } from 'flux-store';
import { merge, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AbstractDiagramChangeCommand } from '../../../editor/diagram/command/abstract-diagram-change-command.cmd';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import { DiagramChangeService } from '../../diagram/diagram-change.svc';
import { EDataLocatorLocator } from '../../edata/locator/edata-locator-locator';

/**
 * Deletes the associated tasks when shapes are deleted.
 * Use in the remove-shape command chain.
 * Does not delete tasks which has entityId's in them. In that case, it unsets the shapeId in it.
 */
@Injectable()
@Command()
export class RemoveShapeTasks extends AbstractDiagramChangeCommand {

    public data: {
        shapeIds: string[],
    };

    constructor(
        protected ds: DiagramChangeService,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected commandService: CommandService,
        protected edatall: EDataLocatorLocator,
    ) {
        super( ds )/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public prepareData() {
        this.previousData = [];
        const obList = [];
        if ( this.data && this.data.shapeIds ) {
            this.data.shapeIds.forEach( shapeId => {
                const ob = this.dataStore.find( TaskModel, { shapeId: shapeId }).pipe(
                    take( 1 ),
                    tap( task => {
                        this.previousData.push( ...task );
                    }),
                );
                obList.push( ob );
            });
        }
        return merge( ...obList );
    }

    public execute() {
        const obs: Observable<boolean>[] = [];
        if ( this.previousData ) {
            this.previousData.forEach(( task: TaskModel ) => {
                if ( task.entityId && task.edataId ) {
                    const entityObs = this.edatall.getEntityOnce( task.edataId, task.entityId ).pipe(
                        take( 1 ),
                        switchMap( entityModel => {
                            let pickedDiagramId = task.diagramId;
                            let pickedShapeId;
                            // Get shapes in current diagram for the entity type
                            let shapesInDiagram = Object.keys( entityModel.shapes[task.diagramId])
                                .filter( shapeId => shapeId !== task.shapeId );
                            if ( shapesInDiagram.length  ) {
                                pickedShapeId = this.pickAvailableShapeFromDiagram( shapesInDiagram );
                            } else { // Search for entity instance on other diagrams
                                const diagramsWhereSameEntityExists = Object.keys( entityModel.shapes )
                                    .filter( diagramId => diagramId !== task.diagramId );
                                for ( const diagId of diagramsWhereSameEntityExists ) {
                                    shapesInDiagram = Object.keys( entityModel.shapes[diagId]);
                                    if ( shapesInDiagram.length ) {
                                        pickedDiagramId = diagId;
                                        pickedShapeId = shapesInDiagram[0];
                                        break;
                                    }
                                }
                            }
                            if ( pickedShapeId ) {
                                const data = { $set: { diagramId: pickedDiagramId, shapeId: pickedShapeId }};
                                this.commandService.dispatch( DiagramCommandEvent.updateTask, pickedDiagramId, {
                                    taskId: task.id,
                                    task: data,
                                }).subscribe();
                            } else {
                                this.dispatchDeleteTask( task.id ).subscribe();
                            }
                            return of( true );
                        }),
                    );
                    obs.push( entityObs );
                } else {
                    this.dispatchDeleteTask( task.id ).subscribe();
                }
                if ( this.state.get( 'CurrentSidebarTask' ) === task.id ) {
                    this.state.set( 'CurrentSidebarTask', '' );
                }
            });
            if ( obs.length ) {
                return merge ( ...obs );
            }
        }
        return true;
    }


    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        if ( this.previousData ) {
            const obsList = [];
            let obs;
            this.previousData.forEach( task => {
                if ( task.entityId ) {
                    obs = this.dataStore.update( TaskModel, { id: task.id },
                            { $set: { diagramId: task.diagramId, shapeId: task.shapeId }});
                } else {
                    obs = this.dataStore.insert( TaskModel, task );
                }
                obsList.push( obs );
            });
            return merge ( ...obsList );
        }
        return of();
    }

    private dispatchDeleteTask( taskId ) {
        return this.commandService.dispatch( DiagramCommandEvent.removeTask, taskId, {
            taskId: taskId,
        });
    }

    /**
     * This function picks the available shape id in the diagram
     * @param shapeIds list of shapeIds
     */
    private pickAvailableShapeFromDiagram( shapeIds: string[]) {
        let pickedShapeId;
        for ( let index = 0; index < shapeIds.length; index++ ) {
            const shapeId = shapeIds[index];
            if ( this.changeModel.shapes[ shapeId ]) {
                pickedShapeId = shapeId;
                break;
            }
        }
        return pickedShapeId;
    }

}

Object.defineProperty( RemoveShapeTasks, 'name', {
    value: 'RemoveShapeTasks',
});
