import { IResponsibility, Logger, InitializationChainStatus } from 'flux-core';
import { Injectable } from '@angular/core';
import { AuthenticationStatus, Authentication } from '../authentication.svc';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the status of authentication.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2018-02-15
 */
@Injectable()
export class AuthResponsibility implements IResponsibility  {

    public name: string = 'AuthResponsibility';

    constructor ( protected logger: Logger,
                  protected auth: Authentication ) {}

    /**
     * Determines authentication state.
     * @param   current status of the sequence
     * @return  Observable which emits the authentication status.
     */
    public checkState( status: InitializationChainStatus ): Observable<AuthenticationStatus> {
        this.logger.debug( 'AuthResponsibility checkState' );
        return of( this.auth.check());
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        const s = this.getStates( status );
        if ( s.isAuthRequired && ( !s.isAuthenticated || s.terminateAtAuth )) {
            return[];
        }
        return [ 'UserResponsibility' ];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): any {
        const s = this.getStates( status );
        if ( s.isAuthRequired ) {
            if ( !s.isAuthenticated ) {
                return { type: 'command', action: 'Authenticate', wait: true };
            }
        }
        if ( s.terminateAtAuth ) {
            return { type: 'complete' };
        }
        // If user is not authenticated and current mode is edit then redirect to view mode.
        // If the diagram does not have view permission it will prompt to login.
        // If a user is switching from view to edit then it should not switch back to view mode.
        // ( It will endup in view -> edit -> view loop )
        if ( !s.isAuthenticated && status.input.action === 'edit' &&
                !status.states.isUserSwitchViewToEdit ) {
            return { type: 'redirect', action: `${status.input.resourceId}/view` };
        }
        return undefined;
    }

    /**
     * Derives the required values from responsibility states and
     * returns a map with them for easy access.
     * @param status chain status
     */
    protected getStates( status: InitializationChainStatus ) {
        return {
            isAuthRequired: !!status.states.authRequired,
            terminateAtAuth: !!status.states.terminateAtAuth,
            isAuthenticated: status.states.AuthResponsibility === AuthenticationStatus.AUTHENTICATED,
        };
    }

}
