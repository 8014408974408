import { IRectangle, IPoint2D } from 'flux-definition';
import { Point } from './point';

export enum HorizontalAlignment {
    Left,
    Center,
    Right,
}

export enum VerticalAlignment {
    Top,
    Center,
    Bottom,
}

// tslint:disable-next-line:only-arrow-functions
export function alignH( rects: IRectangle[], align: HorizontalAlignment ): IPoint2D[] {
    if ( align === HorizontalAlignment.Left ) {
        const minX = Point.min( ...rects ).x;
        return rects.map( r => ({ x: minX, y: r.y }));
    }
    if ( align === HorizontalAlignment.Right ) {
        const maxX = Math.max( ...rects.map( r => r.x + r.width ));
        return rects.map( r => ({ x: maxX - r.width, y: r.y }));
    }
    if ( align === HorizontalAlignment.Center ) {
        const minX = Point.min( ...rects ).x;
        const maxX = Math.max( ...rects.map( r => r.x + r.width ));
        const midX = ( minX + maxX ) / 2;
        return rects.map( r => ({ x: midX - r.width / 2, y: r.y }));
    }
    throw new Error( 'invalid alignment' );
}

// tslint:disable-next-line:only-arrow-functions
export function alignV( rects: IRectangle[], align: VerticalAlignment ): IPoint2D[] {
    if ( align === VerticalAlignment.Top ) {
        const minY = Point.min( ...rects ).y;
        return rects.map( r => ({ x: r.x, y: minY }));
    }
    if ( align === VerticalAlignment.Bottom ) {
        const maxY = Math.max( ...rects.map( r => r.y + r.height ));
        return rects.map( r => ({ x: r.x, y: maxY - r.height }));
    }
    if ( align === VerticalAlignment.Center ) {
        const minY = Point.min( ...rects ).y;
        const maxY = Math.max( ...rects.map( r => r.y + r.height ));
        const midY = ( minY + maxY ) / 2;
        return rects.map( r => ({ x: r.x, y: midY - r.height / 2 }));
    }
    throw new Error( 'invalid alignment' );
}

// tslint:disable-next-line:only-arrow-functions
export function distributeH( rects: IRectangle[], edges: boolean ): IPoint2D[] {
    const minX = Point.min( ...rects ).x;
    const maxX = Math.max( ...rects.map( r => r.x + r.width ));
    const used = rects.reduce(( w, r ) => r.width + w, 0 );
    const spaces = rects.length - ( edges ? 0 : 1 );
    const spaceW = ( maxX - minX - used ) / spaces;
    if ( edges && spaceW < 0 ) {
        return rects.map( r => ({ x: r.x, y: r.y }));
    }
    const data = rects
        .map(( rect, idx ) => ({ rect, idx, pos: { x: 0, y: 0 }}))
        .sort(( a, b ) => a.rect.x - b.rect.x );
    let nextX = minX + ( edges ? ( spaceW / 2 ) : 0 );
    for ( const item of data ) {
        item.pos.x = nextX;
        item.pos.y = item.rect.y;
        nextX += item.rect.width + spaceW;
    }
    return data.sort(( a, b ) => a.idx - b.idx )
        .map( item => item.pos );
}

// tslint:disable-next-line:only-arrow-functions
export function distributeV( rects: IRectangle[], edges: boolean ): IPoint2D[] {
    const minY = Point.min( ...rects ).y;
    const maxY = Math.max( ...rects.map( r => r.y + r.height ));
    const used = rects.reduce(( w, r ) => r.height + w, 0 );
    const spaces = rects.length - ( edges ? 0 : 1 );
    const spaceH = ( maxY - minY - used ) / spaces;
    if ( edges && spaceH < 0 ) {
        return rects.map( r => ({ x: r.x, y: r.y }));
    }
    const data = rects
        .map(( rect, idx ) => ({ rect, idx, pos: { x: 0, y: 0 }}))
        .sort(( a, b ) => a.rect.y - b.rect.y );
    let nextY = minY + ( edges ? ( spaceH / 2 ) : 0 );
    for ( const item of data ) {
        item.pos.x = item.rect.x;
        item.pos.y = nextY;
        nextY += item.rect.height + spaceH;
    }
    return data.sort(( a, b ) => a.idx - b.idx )
        .map( item => item.pos );
}
