import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { cloneDeep } from 'lodash';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand, RequestMethod } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';

/**
 * This command will be used to fetch template context from server
 */
@Injectable()
@Command()
export class UpdateTemplateContext extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }


    constructor( protected auth: NucleusAuthentication ) {
        super()/* istanbul ignore next */;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Post;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'TEMPLATE_CONTEXT_API_URL' ) + '/' + this.data.id;
    }

    public prepareData(): void | Observable<any> {
        if ( this.data.contextData.type !== 'elk' ) {
            const data = cloneDeep( this.data.contextData.content );
            Object.values( data ).forEach(( item: any ) => {
                // Item is an array of objects and we need to keep the Title property in each object and remove the rest
                item.forEach(( obj: any ) => {
                    Object.keys( obj ).forEach(( key: any ) => {
                        if ( key !== 'Title' ) {
                            delete obj[key];
                        }
                    });
                });
            });
            this.data = {
                id: this.data.contextData.id,
                content: data,
            };
        }
    }

  /**
   * Command execute
   */
    public execute(): any {
        return empty();
    }

}

Object.defineProperty( UpdateTemplateContext, 'name', {
    value: 'UpdateTemplateContext',
});
