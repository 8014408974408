import { Command } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { EntityModel } from '../model/entity.mdl';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { Proxied } from '@creately/sakota';

@Command()
export class ImportEntities extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entities: EntityModel[],
    };

    constructor(
        protected es: EDataChangeService,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        if ( !this.changeModel.entities ) {
            this.changeModel.entities = {};
        }
        this.data.entities.forEach( entity => {
            if ( this.changeModel.entities[entity.id]) {
                const currentEntity = this.changeModel.entities[entity.id] as Proxied<EntityModel>;
                currentEntity.__sakota__.mergeChanges(( entity as Proxied<EntityModel> ).__sakota__.getChanges());
            } else {
                this.changeModel.entities[entity.id] = entity;
            }
        });

        // TODO: update formulas?
    }
}

Object.defineProperty( ImportEntities, 'name', {
    value: 'ImportEntities',
});
