import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * UpdateDataItems
 * This command updates a given set of data items on a given set of shapes.
 * If the shape ids are not given, change will be applied to the currently selected shapes.
 */
@Injectable()
@Command()
export class UpdateDataItems extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        dataItems: { id: string, value: any }[],
    };

    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected ds: DiagramChangeService,
        state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
        this.state = state;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        const dataItems = this.data.dataItems;
        if ( !dataItems || dataItems.length === 0 ) {
            return;
        }
        let shapeIds = this.data.shapeIds;
        if ( !shapeIds || shapeIds.length === 0 ) {
            shapeIds = this.state.get( 'Selected' );
        }
        if ( !shapeIds || shapeIds.length === 0 ) {
            return;
        }
        for ( const shapeId of shapeIds ) {
            const shape = this.changeModel.shapes[shapeId] as ShapeModel;
            for ( const dataItem of dataItems ) {
                shape.data[dataItem.id].value = dataItem.value;
            }
        }
    }
}

Object.defineProperty( UpdateDataItems, 'name', {
    value: 'UpdateDataItems',
});
