import { last } from 'lodash';
import { Point } from './point';
import { IPoint2D } from 'flux-definition';
import { Line } from './line';
import { ILine2D } from 'flux-definition';

/**
 * A generic polygon class which contains points and edges.
 */
export class Polygon {
    /**
     * All points in the polygon.
     */
    public points: Point[];

    /**
     * Lines which connects points in the polygon in order.
     */
    public edges: Line[];

    /**
     * Creates a new Polygon with given points.
     */
    constructor( points: IPoint2D[]) {
        this.points = points.map( p => Point.from( p ));
        this.edges = this.getConnectingEdges( points );
    }

    /**
     * Returns edges which start or end on given point
     */
    public edgesFromPoint( point: IPoint2D ): Line[] {
        return this.edges
            .filter( edge => edge.hasEndpoint( point ))
            .map( edge => edge.startFrom( point ));
    }

    /**
     * Returns edges which start or end on given point
     */
    public edgesWithPoint( pointOrPoints: IPoint2D | IPoint2D[]): Line[] {
        const points: IPoint2D[] = [].concat( pointOrPoints );
        return this.edges.filter( edge => {
            for ( const point of points ) {
                if ( !edge.hasPoint( point )) {
                    return false;
                }
            }
            return true;
        });
    }

    /**
     * Returns edges which start or end on given point
     */
    public edgesWithoutPoint( pointOrPoints: IPoint2D | IPoint2D[]): Line[] {
        const points: IPoint2D[] = [].concat( pointOrPoints );
        return this.edges.filter( edge => {
            for ( const point of points ) {
                if ( edge.hasPoint( point )) {
                    return false;
                }
            }
            return true;
        });
    }

    /**
     * isInside checks whether a point is inside the given polygon.
     * This will also return true if the point is on an edge of the polygon.
     * FIXME: Only works for rectangles at the moment. Support any polygon.
     */
    public isInside( point: IPoint2D, includeEnds = true ) {
        const min = Point.min( ...this.points );
        const max = Point.max( ...this.points );
        if ( includeEnds ) {
            return (
                ( point.x >= min.x && point.x <= max.x ) &&
                ( point.y >= min.y && point.y <= max.y )
            );
        } else {
            return (
                ( point.x > min.x && point.x < max.x ) &&
                ( point.y > min.y && point.y < max.y )
            );
        }
    }

    /**
     * Checks if a line intersects with the given polygon.
     */
    public intersects( _line: ILine2D ): boolean {
        for ( let i = 0; i < this.edges.length; ++i ) {
            const edge = this.edges[ i ];
            if ( edge.intersects( _line )) {
                return true;
            }
        }
        return false;
    }
    /**
     * Checks if a line coincides any edge the given polygon.
     */
    public liesOnEdge( _line: ILine2D ): boolean {
        for ( let i = 0; i < this.edges.length; ++i ) {
            if ( this.edges[ i ].liesInside( _line )) {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns an array of lines which will connect given points.
     */
    private getConnectingEdges( points: IPoint2D[]): Line[] {
        if ( points.length < 3 ) {
            return [];
        }
        const edges: Line[] = [];
        for ( let i = 1; i < points.length; ++i ) {
            edges.push( new Line( points[ i - 1 ], points[i]));
        }
        edges.push( new Line( last( points ), points[ 0 ]));
        return edges;
    }
}
