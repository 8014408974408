import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';

/**
 * LogLevel
 * This valriable defines all the log levels with a numerical
 * value
 */
export enum LogLevel {
    /**
     * debug log level will log all types of logs
     */
    debug,

    /**
     * info log level will log info, warning and error logs only
     */
    info,

    /**
     * warning log level will log warning and error logs only
     */
    warning,

    /**
     * error log level will log error logs only
     */
    error,

    /**
     * mute log level will hide all logs
     */
    mute,
}


/**
 * This class extends the LogService and globally set the log level as defined
 * in the AppConfig
 *
 * @author  Thisun
 * @since   2016-04-05
 */
@Injectable()
export class Logger {

    /**
     * The minimum log level to log (default: warning)
     */
    public static set level( value: LogLevel ) {
        this.instance.level = value;
    }

    /**
     * Logs input parameters as debug log
     */
    public static debug( ...args: any[]) {
        return this.instance.debug( ...args );
    }

    /**
     * Logs input parameters as info log
     */
    public static info( ...args: any[]) {
        return this.instance.info( ...args );
    }

    /**
     * Logs input parameters as warning log
     */
    public static warning( ...args: any[]) {
        return this.instance.warning( ...args );
    }

    /**
     * Logs input parameters as error log
     */
    public static error( ...args: any[]) {
        return this.instance.error( ...args );
    }

    /**
     * Logs input parameters as error log
     */
    public static getErrors() {
        return this.instance.getErrors();
    }
    /**
     * The static logger instance.
     */
    private static instance = new Logger();

    protected _level: LogLevel = LogLevel.warning;

    /**
     * Collect error logs separately
     */
    protected errors: any[] = [];
    protected errorCount = 10;

    /**
     * The minimum log level to be logged by
     * this logger. By default is set to LogLevel.warning
     */
    public set level( value: LogLevel ) {
        this._level = value;
    }

    /**
     * This function logs input parameter data
     * as a debug log
     */
    public debug( ...args: any[]) {
        if ( this._level > LogLevel.debug ) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.debug( ...args );
    }

    /**
     * This function logs input parameter data
     * as an info log
     */
    public info( ...args: any[]) {
        if ( this._level > LogLevel.info ) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.info( ...args );
    }

    /**
     * This function logs input parameter data
     * as a warning log
     */
    public warning( ...args: any[]) {
        if ( this._level > LogLevel.warning ) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.warn( ...args );
    }

    /**
     * This function logs input parameter data
     * as an error log
     */
    public error( ...args: any[]) {
        if ( this._level > LogLevel.error ) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.error( ...args );
        this.collectErrorData( ...args );
    }

    /**
     * This function logs input error
     * and adds a tag to indicate the input error is handled
     */
    public unhandledError( error: Error ) {
        if ( this._level > LogLevel.error ) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.error( error.name, error.message, error.stack );
        this.collectErrorData( error );
    }

    /**
     * Returns an array of collected errors
     */
    public getErrors(): any[] {
        return this.errors;
    }

    /**
     * Record logs to DataDog.
     * @param type
     * @param tags
     * @param args
     */
    protected recordToDataDog( type: string, tags: {[id: string]: string }, ...args ) {
        // NOTE: Sending only the errors to DataDog, nothing to do if datadog is not setup
        if (( type !== 'error' && type !== 'warning' ) ||
         !AppConfig.get( 'DATADOG_COLLECTOR_TOKEN' ) || !( window as any ).DD_LOGS ) {
            return;
        }
        const attributes = {};
        if ( tags ) {
            Object.keys( tags ).forEach( key => {
                attributes[ key ] = tags[key];
            });
        }
        let message = '';
        if ( args[0] instanceof Error ) {
            attributes[ `stack` ] = args[0].stack;
            message = args[0].message;
        } else {
            message = args.join( ' | ' );
        }

        // NOTE: Sending only the errors to DataDog
        // If want to send other logs, use DD_LOGS.logger.log( message, attrs, logLevel ) function
        if ( tags && tags.unhandled && tags.unhandled === 'true' ) {
            ( window as any ).DD_LOGS.logger.error( message, attributes );
         } else {
             message = `Handled Exception : ${message}`;
             ( window as any ).DD_LOGS.logger.warn( message, attributes );
         }
    }

    /**
     * Collect error logs separately for reports.
     * Only keep the latest 10 error logs on memory.
     */
    private collectErrorData( ...args: any[]) {
        this.errors.push( args );
        while ( this.errors.length > this.errorCount ) {
            this.errors.shift();
        }
    }
}
