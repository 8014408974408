/* istanbul ignore file */

import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import {  concat, EMPTY, Observable, of } from 'rxjs';
import { TaskModel } from '../task.mdl';
import { take, tap } from 'rxjs/operators';

@Injectable()
@Command()
/**
 * This command will delete the task for the folder
 */
export class RemoveFolderTask extends AbstractMessageCommand {
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public prepareData() {
        if ( this.resourceId ) {
            this.previousData = {};
            return this.dataStore.find( TaskModel, { folderId: this.resourceId }).pipe(
                            take( 1 ),
                            tap( tasks => this.previousData.tasks = tasks ),
                        );
        }
    }

    public execute() {
        if ( this.resourceId === 'home' ) {
            return false;
        }
        if ( this.resourceId ) {
            this.state.set( 'CurrentSidebarTask', '' );
            return this.dataStore.remove( TaskModel, { folderId: this.resourceId });
        }
    }

    public executeResult() {
        return EMPTY;
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        if ( this.resourceId && this.previousData.tasks ) {
            const obs = [];
            this.previousData.tasks.forEach( task => {
                obs.push ( this.dataStore.insert( TaskModel, task ));
            });
            return concat( ...obs );
        }
        return of();
    }
}

Object.defineProperty( RemoveFolderTask, 'name', {
    value: 'RemoveFolderTask',
});
