import { StateService } from 'flux-core';
import { ParamMap } from '@angular/router';
import { isString } from 'util';
import { Subscription } from 'rxjs';

/**
 * An abstract version of the route resolver which contains
 * common functionality.
 *
 * @author  Ramishka
 * @since   2019-02-28
 */
export class AbstractRouteResolver {

    /**
     * The list of subscriptions held by this class
     */
    protected subs: Subscription[];

    constructor ( protected state: StateService<any, any> ) {
        this.subs = [];
    }

    /**
     * Destroys and clears all the resources used,
     * when naviagate away from this route
     */
    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Hides the loading screen
     */
    protected hideLoadingScreen() {
        if ( this.state.has( 'LoadingIndicatorState' ) && this.state.get( 'LoadingIndicatorState' ).main ) {
            this.state.set( 'LoadingIndicatorState', { main: false });
        }
    }

    /**
     * Returns this any query parameters passed into this route
     * as a query parameters string i.e. a=1&b=2&c=3
     * @param paramMap - query parameters map
     * @return query string
     */
    protected getQueryString( paramMap: ParamMap ): string {
        let queryString = '';
        const keys = paramMap.keys;
        if ( keys && keys.length > 0 ) {
            keys.forEach(( key, idx ) => {
                if ( idx !== 0 ) {
                    queryString = queryString + '&';
                }
                queryString = queryString + key + '=' + paramMap.get( key );
            });
        }
        return queryString;
    }

    /**
     * Sets the current diagram state.
     * @param id - diagra id to be set
     */
    protected setCurrentDiagram( id: string ) {
        // FIXME Need to add a proper diagram id validation
        if ( !!id && isString( id )) {
            // Set the current diagram if not already set
            if ( !this.state.has( 'CurrentDiagram' )
                || this.state.isNot( 'CurrentDiagram', id )) {
                this.state.set( 'CurrentDiagram', id );
            }
        } else {
            // FIXME Need to navigate to a valid error page.
            throw new Error( 'Diagram id was not valid int the given url' );
        }
    }

    /**
     * Sets the current project state.
     * The default state is always the home project
     * @param projectId - project id to be set
     */
    protected setCurrentProject( projectId: string ) {
        if ( !this.state.has( 'CurrentProject' )) {
            this.state.set( 'CurrentProject', 'home' );
        }
        if ( projectId ) {
            this.state.set( 'CurrentProject', projectId );
        }
    }

    /**
     * Retrieves the route action (i.e. view, edit ) from the currently routed url
     * @param url url the router is routing to
     * @return route action
     */
    protected getRouteAction( url: string ) {
        const paths: Array<string> = url.split( '?' )[0].split( '/' );
        if ( paths[ paths.length - 2 ] === 's'
            ||  paths[ paths.length - 2 ] === 'g' || paths[ paths.length - 2 ] === 'slide' ) {
            return paths[paths.length - 3 ];
        } else if ( paths && paths.length > 0 ) {
            return paths[paths.length - 1];
        }
    }

}
