/**
 * This has the link types that can be present
 * in a shape.
 */
export enum ShapeLinkType {

    /**
     * This refers that the link is a for a regular web page.
     */
    WEB = 'web',

    /**
     * This refers that the link is to the creately diagram.
     */
    DIAGRAM = 'd',

    /**
     * This refers that the link is to a shape
     */
    SHAPE = 's',

    /**
     * This refers that the link is to to a group
     */
    GROUP = 'g',
}

/**
 * @HACK
 * This has the extended naming of above ShapeLinkType Enum,
 * Some logics still using he older naming of link, This is created to fix that.
 */
export enum ExtendedShapeLinkType {

    // Extended name of ShapeLinkType:DIAGRAM
    DIAGRAM = 'diagram',

    // Extended name of ShapeLinkType:SHAPE
    SHAPE = 'shape',

    // Extended name of ShapeLinkType:GROUP
    GROUP = 'group',
}

/**
 * ShapeLinkModel
 * This model contains the information about the links
 * in a diagram shape.
 *
 * @author  gobiga
 * @since   2016-07-25
 */
export class ShapeLinkModel {

    /**
     * Creates a ShapeLinkModel instance from the givern url.
     */
    public static fromUrl( url: string ) {
        const link = new ShapeLinkModel();
        link.type = ShapeLinkType.WEB;
        link.link = url;
        if ( link.linkedDiagramId ) { // A creately link
            const parts: Array<string> = url.split( '/' );
            if ((
                parts[6] === ShapeLinkType.SHAPE ||
                parts[6] === ExtendedShapeLinkType.SHAPE ||
                parts[6] === ShapeLinkType.GROUP ||
                parts[6] === ExtendedShapeLinkType.GROUP
            ) && parts[7]) {
                link.type = parts[6] as any;
                link.targetId = parts[7];
                link.placeHolder = `${parts[6]}-${parts[7]}`;
            } else if ((
                parts[3] === ShapeLinkType.DIAGRAM ||
                parts[3] === ExtendedShapeLinkType.DIAGRAM
            ) && parts[4]) {
                link.type = ShapeLinkType.DIAGRAM;
                link.targetId = parts[4];
                link.placeHolder = `${parts[3]}-${parts[4]}`;
            }
        }
        return link;
    }

    /**
     * id
     */
    public id: string;

    /**
     * The ID of the target, target can be a shape, group or diagram, this id is valid only for
     * internal links ( If the "type" is one of diagram, shape , group )
     */
    public targetId: string;

    /**
     * The place holder / labe of the link
     */
    public placeHolder: string;

    /**
     * The ID of the shape that this link
     * is attached to.
     */
    public shapeId: string;

    /**
     * URL of the link
     */
    public link: string;

    /**
     * Title of the link
     */
    public title: string;

    /**
     * Type of the link.
     * This can be link to a web page or link to the creately
     * diagram.
     */
    public type: ShapeLinkType;

    /**
     * The URL of the standard thumbnail image of the diagram
     */

    public thumbnailUrl: string;


    /**
     * If this is an inbound link.
     * These happen when you create a link from another shape to this
     * and an inverse link is created to the originator
     */
    public isInbound: boolean;

    /**
     * This will return the ShapeLinkType for the link type.
     */
    public get shapeLinkType(): ShapeLinkType {
        return this.type;
    }

    /**
     * Returns the diagramId from this link. If this is
     * a DIAGRAM link this returns a valid diagramId otherwise
     * returns null.
     *
     * @return  string  Returns the Id of the diagram that has linked to
     *                  this link.
     */
    public get linkedDiagramId(): string {
        if ( this.link ) {
            const communityLinkRegEx: RegExp = new RegExp ([ '^http[s]?:\\/\\/([a-zA-Z\\d-]+\\.)*creately\\.com',
                                                             '\\/diagram\\/example\\/(\\w+)?' ].join( '' ), 'g' );

            const viewLinkRegEx: RegExp = new RegExp ([ '^http[s]?:\\/\\/([a-zA-Z\\d-]+\\.)*creately\\.com\\/',
                                                            'diagram\\/(\\w+)?' ].join( '' ), 'g' );

            const newViewLinkRegEx: RegExp = new RegExp ([ '^http[s]?:\\/\\/([a-zA-Z\\d-]+\\.)*creately\\.com\\/',
                                                            'd\\/(\\w+)?' ].join( '' ), 'g' );

            const editLinkRegEx: RegExp = new RegExp ([ '^http[s]?:\\/\\/([a-zA-Z\\d-]+\\.)*creately\\.com\\/',
                                                        'app?.diagid=([^&]+)' ].join( '' ), 'g' );


            const communityLinkMatch = communityLinkRegEx.exec( this.link );
            if ( communityLinkMatch ) {
                return communityLinkMatch[2];
            }

            const viewLinkMatch = viewLinkRegEx.exec( this.link );
            if ( viewLinkMatch ) {
                return viewLinkMatch[2];
            }

            const newViewLinkMatch = newViewLinkRegEx.exec( this.link );
            if ( newViewLinkMatch ) {
                return newViewLinkMatch[2];
            }

            const editLinkMatch = editLinkRegEx.exec( this.link );
            if ( editLinkMatch ) {
                return editLinkMatch[2];
            }

            return null;

        }

    }

}

Object.defineProperty( ShapeLinkModel, 'name', {
  writable: true,
  value: 'ShapeLinkModel',
});
