import { isEmpty } from 'lodash';
import { ExecutionStep, CommandInterfaces } from 'flux-core';
import { ContainerInfo } from '../../container-info.svc';

/**
 * This execution step attaches container context info into the payload.
 */
export class AttachContainerInfoExecutionStep extends ExecutionStep {
    /**
     * Indicates the interfaces a command must implement for this
     * ExecutionStep step to process during that command execution.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    /**
     * Sets the contextData property of the command if they are available.
     */
    public process(): boolean {
        const containerInfo = ContainerInfo.get();
        if ( !containerInfo || isEmpty( containerInfo )) {
            return true;
        }
        if ( !this.command.contextData ) {
            this.command.contextData = {};
        }
        Object.assign(
            this.command.contextData,
            { containerInfo },
        );
        return true;
    }
}

Object.defineProperty( AttachContainerInfoExecutionStep, 'name', {
    value: 'AttachContainerInfoExecutionStep',
});
