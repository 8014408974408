<div class="create-saved-sets-dialog modal-window-container fx-cover fx-center-all" #window >
    <div class="create-saved-sets-dialog-inner modal-window-inner" #windowInner>
        <div>
            <div class="create-saved-sets-dialog-heading modal-window-heading">
                <h1 class="create-saved-sets-dialog-header title">{{ 'CREATE_SAVED_SETS_DIALOG.HEADING' | translate }}</h1>
            </div>
            <p class="create-saved-sets-dialog-description">{{ 'CREATE_SAVED_SETS_DIALOG.DESCRIPTION' | translate }}</p>
        </div>

        <div class="create-saved-sets-dialog-content modal-window-content">
            <div class="create-saved-sets-dialog-name-content">
                <label class="create-saved-sets-dialog-name-lable" translate>CREATE_SAVED_SETS_DIALOG.LABEL</label>
                <input placeholder="{{'CREATE_SAVED_SETS_DIALOG.LABEL' | translate}}" type="text" [readonly]="readonly | async" class="create-saved-sets-dialog-name-text text-input" [(ngModel)]="name" />
            </div>
        </div>
        
        <div class="divider"></div>

        <div class="create-saved-sets-dialog-btns">
            <button class="btn-medium btn-secondary fx-margin-right-5" (click)="closeWindow()">{{
                'SETUP_DATABASE_DIALOG.BUTTONS.CANCEL' | translate }}</button>
            <button [class.disabled]="!name" [disabled]="!name" class="btn-medium btn-primary fx-margin-right-5 submit-btn" (click)="createSavedSet()">{{
                'CREATE_SAVED_SETS_DIALOG.CREATE_SAVED_SET' | translate }}</button>
        </div>
    </div>
</div>