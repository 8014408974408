import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand, RequestMethod } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will be used to fetch viz Request Id from the server
 */
@Injectable()
@Command()
export class GetVizRequestId extends AbstractHttpCommand {

    public static get dataDefinition(): {}  {
        return {
            shapesJson: false,
            request: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }


    constructor( protected auth: NucleusAuthentication ) {
        super()/* istanbul ignore next */;
    }

    /**
     * This property is to specify whether the RunExecutionStep of the command should be asynchronous
     * or not.
     */
    public get asynchronous(): boolean {
        return true;
    }

    /**
     * httpHeaders returns the headers needed to make the request.
     * This may include Authorization, Content-Type, etc.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': this.auth.token,
        });
    }

    /**
     * Returns the method used for the request
     */
    public get httpMethod(): RequestMethod {
        return RequestMethod.Post;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'VIZ_REQUEST_ID_URL' );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return empty();
    }

}

Object.defineProperty( GetVizRequestId, 'name', {
    value: 'GetVizRequestId',
});
