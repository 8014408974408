import { IDialogBoxData } from 'flux-definition';

/**
 * A DialogBoxError. Any errors that should be displayed on DialogBox
 * should extend this error.
 */
export abstract class DialogBoxError extends Error {
    constructor( message?: string, public data?: IDialogBoxData ) {
        super( message );
        Object.setPrototypeOf( this, DialogBoxError.prototype );
    }
}
