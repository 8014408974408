import {
    Command, CommandInterfaces, CommandResultError, Random, StateService,
} from 'flux-core';
import { merge } from 'lodash';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { map } from 'rxjs/operators';
import { CollaboratorType } from 'flux-diagram';
import { PrivacyLevel } from 'flux-user';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';

/**
 * CreateDiagram command
 */
@Injectable()
@Command()
export class CreateDiagram extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IProjectCommand', 'IMessageCommand' ];
    }

    public static get dataDefinition() {
        return {
            id: false,
            createdTime: false,
            name: false,
            type: false,
        };
    }

    constructor(
        // DO NOT IMPORT Nucleus Authentication over here.
        protected random: Random,
        protected state: StateService<any, any>,
        protected dataStore: DataStore,
    ) {
        /* istanbul ignore next */
        super();
    }

    public prepareData(): void {
        if ( !this.data ) {
            this.data = {};
        }
        if ( !this.data.id ) {
            this.data.id = this.random.diagramId();
        }
        if ( !this.data.createdTime ) {
            this.data.createdTime = Date.now();
        }
        if ( !this.data.name ) {
            this.data.name = 'Untitled Workspace';
        }
        if ( !this.data.type ) {
            this.data.type = 'diagram/def/block.js#Block';
        }
        if ( this.data.templateDef?.id ) {
            const templateId = this.data.templateDef.id;
            this.data.templates = [{
                id: templateId,
                context: 'URL',
            }];
        }
        // NOTE: DO NOT set Auth Token over here on the payload.
        // Message Command already sets auth token by default in
        // request. If you set auth token over here, it will end up
        // in Diagram Model and that will leads to a security risk.
        // DO NOT set resouce id 'rid' as well.
    }

    /**
     * This function will add the newly created diagram into the
     * Recent Diagrams List and Project Diagrams List.
     */
    public executeResult( response: any ): boolean {
        const result = merge( response.model, response.modelStatic );
        const allDiagrams = this.state.get( 'RecentDiagrams' );
        if ( allDiagrams ) {
            const diagram = allDiagrams.find( item => item.id === response.model.id );
            if ( !diagram ) {
                allDiagrams.unshift( result );
                this.state.set( 'RecentDiagrams', allDiagrams );
            }
        }
        const projectDiagrams = this.state.get( 'ProjectDiagrams' );
        if ( projectDiagrams && Object.keys( projectDiagrams ).length > 0 ) {
            const projectId = typeof result.project === 'string' ? result.project : result.project.id;
            if ( projectDiagrams[projectId]) {
                projectDiagrams[projectId].push( result );
                this.state.set( 'ProjectDiagrams', projectDiagrams );
            }
        }

        const diagrams = this.state.get( 'FolderPanelDiagrams' );
        if ( diagrams ) {
            diagrams.push( result );
            this.state.set( 'FolderPanelDiagrams', diagrams );
        }

        return true;
    }

    /**
     * This function will add the newly created workspace/diagram into index db
     * and emit the model and modelStatic.
     */
    public executeNetworkOffline(): Observable<any> {
        const model = this.getPreparedModel( this.data );
        const modelStatic = this.getPreparedModelStatic( this.data );
        const modelStore = this.dataStore.getModelStore( DiagramModel );
        return combineLatest(
            modelStore.insert( model ),
            modelStore.insertStatic( modelStatic ),
        ).pipe(
            map(() => ({ model, modelStatic })),
        );
    }

    /**
     * Removed adding a empty diagram to the data store.
     * For the moment, the newly created diagram will always
     * be added to the storage when its diagram subscription
     * starts. Diagram can be added to the storage when we
     * add full offline diagram creation support.
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

    /**
     * If this command encounters a permission error, throw the error so invoker may
     * handle it as needed. All other errors will be logged.
     */
    public onError( error: CommandResultError ) {
        if ( error.code &&
            ( error.code === 1615 || error.code === 1611 ||
                error.code === 1612 || error.code === 1623 || error.code === 1202 )) {
            throw error;
        }
        /* istanbul ignore next */
        super.onError( error );
    }

    /**
     * This function will prepare and return the model data
     * for the given data.
     */
    private getPreparedModel( data: any ) {
        const currentUser = this.state.get( 'CurrentUser' );
        const model: any = {
            id: data.id,
            updatedBy: currentUser,
            type: data.type,
            name: data.name,
        };
        if ( data.shapes ) {
            model.shapes = data.shapes;
        }
        if ( data.libraries ) {
            model.libraries = data.libraries;
        }
        if ( data.templateDef ) {
            model.templateDef = data.templateDef;
        }
        return model;
    }

    /**
     * This function will prepare and return the modelStatic data
     * for the given data.
     */
    private getPreparedModelStatic( data: any ) {
        const currentUser = this.state.get( 'CurrentUser' );
        const modelStatic = {
            collabs: [{
                id: currentUser,
                createdTime: data.createdTime,
                role: CollaboratorType.OWNER,
                lastSeen: data.createdTime,
            }],
            createdTime: data.createdTime,
            id: data.id,
            privacy: {
                level: PrivacyLevel.SPECIFIC,
                role: CollaboratorType.OWNER,
                updatedBy: currentUser,
                updatedTime: data.createdTime,
            },
            project: this.resourceId ? this.resourceId : 'home',
        };
        return modelStatic;
    }
}

Object.defineProperty( CreateDiagram, 'name', {
    value: 'CreateDiagram',
});
