<div class="search-input-field">
    <text-input #textInput
        [showClearButton]="true"
        icon="search-thin"
        [emitForInputChange]="true"
        placeholder="{{placeholder | translate}}"
        [debounce]="100"
        (updateText)="this.onSearchQueryChange.next( $event )"
        (keydown)="handleKeydown( $event )">
    </text-input>
</div>
<div class="search-result-dropdown" *ngIf="isOpen | async">
        <div *ngIf="searchResults | async as results" class="search-results-dropdown-results">
            <perfect-scrollbar class="search-result-scrollbar" [config]="{ scrollYMarginOffset: 1 }">
                <div class="search-result-header">
                    <div class="fx-margin-bottom-5" (click)="filterShapesAndSetState()" *ngIf="results.length > 0">
                        <label class="grey">{{getSearchResultsLabel() | async}}</label>
                    </div>
                    <div *ngIf="results.length === 0" translate>
                        LABELS.NO_SEARCH_RESULTS
                    </div>
                </div>

                <!-- This part is hidden when no result found -->
                <div class="search-result-content">
                    <div *ngFor="let i of results">
                        <shape-search-item #tile
                            *ngIf="i.type === 'shape'"
                            [item]="i"
                            (click)="handleShapeClick( i.data )"
                            (dragstart)="handleDragStart( $event, i.data )">
                        </shape-search-item>
                        <library-search-item
                            (click)="handleLibraryClick()"
                            *ngIf="i.type === 'libs'"
                            [item]="i">
                        </library-search-item>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    <div class="search-result-footer">
        <button class="nu-btn-med secondary" (click)="openGoogleSearch()" translate>
            SHAPES_PANEL.SHAPE_SEARCH.GOOGLE_SEARCH
        </button>
        <button class="nu-btn-med secondary" (click)="openLibBrowser()" translate>
            SHAPES_PANEL.SHAPE_SEARCH.MORE_SHAPES
        </button>
    </div>
</div>