import { ViewContainerRef } from '@angular/core';
import { ComponentFactoryResolver, Injector, ComponentRef } from '@angular/core';
import { DynamicComponentService } from './dynamic.cmp.svc';

/**
 * DynamicComponent contains the helper funticonality to create the components,
 * append and remove a given component within the parent element given.
 *
 * @author  gobiga
 * @since   2017-11-15
 * @deprecated since 2023-08-04 use DynamicComponentService instead
 */
// tslint:disable:member-ordering
export class DynamicComponent {

    private dynamicComponentSvc: DynamicComponentService;

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
        this.dynamicComponentSvc = new DynamicComponentService( componentFactoryResolver, injector );
    }


    /**
     * Creates a new component.
     * @param type  The type of the component to create.
     */
    protected createComponent( type: any, viewContainerRef: ViewContainerRef ): ComponentRef<any> {
        return this.dynamicComponentSvc.createComponent( type, viewContainerRef );
    }

    /**
     * Creates a new component and returns the componentRef of it.
     * The component will not be added to the view utill it's inserted.
     * @param type  The type of the component to create.
     * @returns ComponentRef<any> The component ref of the created component
     */
    public makeComponent( type: any ): ComponentRef<any> {
        return this.dynamicComponentSvc.makeComponent( type );
    }

    /**
     * Inserts the component to the given viewContainer at the given index
     * If the index is not given, the component will be appended
     * @param type  The type of the component to create.
     * @returns ComponentRef<any> The component ref of the created component
     */
    public insert( viewContainer: ViewContainerRef, cmp: ComponentRef<any>, index?: number ) {
        this.dynamicComponentSvc.insert( viewContainer, cmp, index );
    }

    /**
     * Removes a given component element within a given parent element and destroy it.
     */
    protected remove( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ) {
        this.dynamicComponentSvc.remove( viewContainer, cmp );
    }

    /**
     * Detaches a view from the given viewContainer without destroying it.
     */
    protected detachComponent( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ) {
        this.dynamicComponentSvc.detachComponent( viewContainer, cmp );
    }

    /**
     * Checks if a given component is already added to a given view container.
     * @param viewContainer - view container to check against
     * @param cmp - component to check for
     * @return true if the container is added
     */
    protected contains ( viewContainer: ViewContainerRef, cmp: ComponentRef<any> ): boolean {
        return this.dynamicComponentSvc.contains( viewContainer, cmp );
    }

    /**
     * Sets the input values that are passed in, to the inputs of the component.
     * @param componentInstance - Instance of the component to which the inputs will be set to
     * @param inputs - Object with inputs and their values
     */
    protected setInputs( componentInstance: any, inputs: { [inputName: string]: any }) {
        this.dynamicComponentSvc.setInputs( componentInstance, inputs );
    }


}
