import { Component, Input } from '@angular/core';

/**
 * This component encapsulates the functionality
 * for displaying an error message.
 *
 * @since 23/03/2017
 */

@Component({
    selector: 'error-view',
    template: `<div class="fx-center fx-center-all fx-span-both {{containerClass}}">
                    <div class="error-view-inner">
                        <i *ngIf="iconClass" class="error-view-icon {{iconClass}}"></i>
                        <div class="error-view-text fx-margin-top-5">
                            <ng-content></ng-content>
                            <p class="grey caption caption--sm">{{errorText}}</p>
                        </div>
                    </div>
                </div>
                <ng-content></ng-content>`,
})
export class ErrorView {
    @Input() public iconClass: string;

    @Input() public errorText: string = 'Whoops! Something went wrong. Please try that again.';

    @Input() public containerClass: string;
}
