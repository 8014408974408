import {
    AfterViewInit, ChangeDetectionStrategy, Component,
    ComponentFactoryResolver,
    ComponentRef, ElementRef, Injector, Input,
    OnDestroy,
    Output, ViewChild, ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'flux-core';
import { DynamicComponent, EditableLabel } from 'flux-core/src/ui';
import { DataType, IDataItem, SystemType } from 'flux-definition';
import { BehaviorSubject, Subject } from 'rxjs';
import { TaskManager } from '../../../base/task/task-manager.svc';
import { IUIControl } from './../ui-control.i';
import { CheckBoxUIC } from './check-box-uic.cmp';
import { DatePickerUIC } from './date-picker-uic.cmp';
import { EntityLookupSingleUIC } from './entity-lookup-single-uic.cmp';
import { EntityLookupMultipleUIC } from './entity-lookup-uic.cmp';
import { FormulaFieldUIC } from './formula-field-uic.cmp';
import { IdentifierInputUIC } from './identifier-input-uic.cmp';
import { NumberInputUIC } from './number-input-uic.cmp';
import { PeoplePickerUIC } from './people-picker-uic.cmp';
import { SingleSelectComboUIC } from './single-select-combo-uic.cmp';
import { TagsInputUIC } from './tags-input-uic.cmp';
import { TextInputUIC } from './text-input-uic.cmp';
import { TiptapEditor } from './tiptap/tiptap-editor.cmp';

/**
 * This component is to allow user to edit the lable of the
 * Data item and value
 * @author thisun
 * @since 2021-01-19
 */
@Component({
    selector: 'labeleditable-dataitem',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div #container  class="label-editable-data-item-container"
            [ngClass]="{ 'hidden': !(visibility | async) }" >
            <div [ngClass]="{ 'hidden': dataItem.hideLabel }"  class="label-editable-data-item-label" *ngIf="dataSubject | async as dataItem" >
                <editable-label class="body" #editableLabel
                    [tooltip]="dataItem.label"
                    [value]="dataItem.label"
                    (click)="$event.target.focus()"
                    (changedText)="labelChanged($event)"
                    [readonly]="!dataItem.labelEditable">
                </editable-label>
                <div *ngIf="data.systemType !== undefined" class="label-editable-data-item-caption overline">{{getUITypeLabel()}}</div>
            </div>
            <div class="label-editable-data-item-input-container">
                <div class="label-editable-data-item-input body" #dataItemContainer ></div>
            </div>

            <div *ngIf="dataSubject | async as di" class="more-options">
                <div *ngIf="getMoreOptions().length && di.optional && di.id!=='description'" >

                    <abs-dropdown #dropdown [settings]="{ closeOnItemClicked: true, openOnHover: false,
                        closeOnBlur: true, multiselectable: false }" direction="top" alignment="right">
                        <simple-dropdown-button ddbutton [items]="getMoreOptions()">
                            <button class="btn-small nu-btn-icon">
                                <svg class="nu-icon">
                                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use>
                                </svg>
                            </button>
                        </simple-dropdown-button>
                        <simple-dropdown-item data-id="{{option.id}}" dditem *ngFor="let option of getMoreOptions()" [item]="option" (mousedown)="option.subject.next( di.id )">
                            <span class="body">{{option.label}}</span>
                        </simple-dropdown-item>
                    </abs-dropdown>
                </div>
            </div>
        </div>
    `,
    styleUrls: [ './label-editable-data-item.scss' ],
})
export class LabelEditableDataItem extends DynamicComponent implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    /**
     * Data item to edit label and change the value
     */
    @Input()
    public data: IDataItem<DataType>;

    /**
     * associated diagramId
     */
    @Input()
    public diagramId: string;

    /**
     * associated shapeId
     */
    @Input()
    public shapeId: string;

    /**
     * associated index
     */
    @Input()
    public index: number;

    /**
     * This subject is used to update the view
     */
    public dataSubject: Subject<IDataItem<DataType>>;

    /**
     * This subject is to controll the visibility of this component
     * e.g. if the LabelEditableDataItem is used to update the single select
     * combo data item options, the intermediate component is shown and
     * this component should be hidden until the user edits the options
     */
    public visibility: BehaviorSubject<boolean>;

    /**
     * Emits the this subject when the data item changed
     */
    @Output()
    public change: Subject<any>;

    /**
     * In the more options, there are two options 'Show label' && 'Hide label'
     * Those options are visible to depending on this property
     */
    @Input()
    public showLabelVisibilityOptions: boolean = false;

    /**
     * Emits when the data item is to be removed ( When the user clicks remove in the options dropdown )
     */
    @Output()
    public removed: Subject<string>;

    /**
     * Emits when the data item is to be updated ( When the user clicks update in the options dropdown )
     */
    @Output()
    public update: Subject<string>;

    protected subs = [];

    @ViewChild( 'container', { read: ViewContainerRef, static: false })
    protected container: ViewContainerRef;

    @ViewChild( 'dataItemContainer', { read: ViewContainerRef, static: false })
    protected dataItemContainer: ViewContainerRef;

    @ViewChild( 'editableLabel', { read: EditableLabel, static: false })
    protected editableLabel: EditableLabel;

    /**
     * The itemRef of the the data item's ui component
     */
    protected itemRef: any;

    constructor (
        protected translate: TranslateService,
        protected elementRef: ElementRef,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected state: StateService<any, any>,
        protected taskManager: TaskManager,
    ) {
        super( componentFactoryResolver, injector );
        this.change = new Subject();
        this.removed = new Subject();
        this.update = new Subject();
        this.dataSubject = new Subject();
        this.visibility = new BehaviorSubject( true );
    }

    public ngAfterViewInit() {
        this.dataSubject.next( this.data );
        if ( this.data.isNested ) {
            return;
        }
        const controlType = this.getUIControlType( this.data );
        const itemRef: ComponentRef<any> = this.makeComponent( controlType );
        this.itemRef = itemRef;
        itemRef.instance.id = this.data.id;
        /* istanbul ignore next */
        if ( this.context ) {
            itemRef.instance.context = this.context;
        }
        ( itemRef.instance as any ).shapeId = this.shapeId;
        ( itemRef.instance as any ).diagramId = this.diagramId;
        itemRef.instance.setData( this.data );
        this.subscribeToItemChange( itemRef.instance );
        this.insert( this.dataItemContainer, itemRef );
        const layout = ( this.data as any ).layout || 'inline';
        const parents = (( this.data as any ).parents || []).join( '.' );
        ( this.elementRef.nativeElement as HTMLElement )
            .setAttribute( 'data-layout', parents + '-' + this.data.index + '-' + layout );
        setTimeout(() => {
            itemRef.changeDetectorRef.detectChanges();
        }, 0 );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Set data to this component
     */
    public setData( data ) {
        this.data = { ...data };
        this.dataSubject.next( this.data );
        if ( this.itemRef ) {
            this.itemRef.instance.setData( this.data );
        }
    }

    /**
     * Appends element to this component
     * @param el
     */
    public appendChild( el: HTMLElement ) {
        ( this.elementRef.nativeElement as HTMLElement ).appendChild( el );
    }

    /**
     * Appends element to this component
     * @param el
     */
    public removeChild( el: HTMLElement ) {
        ( this.elementRef.nativeElement as HTMLElement ).removeChild( el );
    }

    /**
     * Items to show in the more options drop down
     */
    public getMoreOptions() {
        const options = [];
        /* istanbul ignore else */
        if ( this.data.removeable === undefined || this.data.removeable === true ) {
            options.push({
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.REMOVE' ),
                subject: this.removed,
            });
            // TODO This need to be properly refactored to set multiple permissions in the future.
            // If a permission already set then show the reset.( Anyone can access )
            if ( this.data.accessLevels && this.data.accessLevels.length > 0  ) {
                options.push({
                    label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.VISIBLE_TO_EVERYONE' ),
                    subject: {
                        next: () => this.change.next({ accessLevels: []}),
                    },
                });
            }
            if (( !this.data.accessLevels ) || this.data.accessLevels.length === 0 ||
                            this.data.accessLevels[ 0 ].diagRole !== 'owner' ) {
                // If permission is not set or it is not "owner" then show owner only permission option.
                options.push({
                    label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.VISIBLE_TO_OWNER' ),
                    subject: {
                        next: () => this.change.next({
                            accessLevels: [{
                                permission: 'write',
                                diagRole: 'owner',
                                diagramId: this.state.get( 'CurrentDiagram' ),
                            }],
                        }),
                    },
                });
            }
            if (( !this.data.accessLevels ) || this.data.accessLevels.length === 0 ||
                            this.data.accessLevels[ 0 ].diagRole !== 'editor' ) {
                // If permission is not set or it is not "editor" then show editor only permission option.
                options.push({
                    label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.VISIBLE_TO_EDITOR' ),
                    subject: {
                        next: () => this.change.next({
                            accessLevels: [{
                                permission: 'write',
                                diagRole: 'editor',
                                diagramId: this.state.get( 'CurrentDiagram' ),
                            }],
                        }),
                    },
                });
            }
        }

        if ( this.data.type === DataType.OPTION_LIST || this.data.type === DataType.FORMULA ) {
            options.push({
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.UPDATE' ),
                subject: this.update,
            });
        }

        if ( this.showLabelVisibilityOptions && ( this.data as any ).hideLabel ) {
            options.push({
                id: 'show-label',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.SHOW_LABEL' ),
                subject: {
                    next: () => this.change.next({ hideLabel: false }),
                },
            });
        } else if ( this.showLabelVisibilityOptions ) {
            options.push({
                id: 'hide-label',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.HIDE_LABEL' ),
                subject: {
                    next: () => this.change.next({ hideLabel: true }),
                },
            });
        }

        if ( this.data.roleBound && this.data.systemType === SystemType.Role ) {
            options.push({
                id: 'task-active',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.TASK_ACTIVE' ),
                subject: {
                    next: () => this.updateTaskActiveStatus( true ),
                },
            });
            options.push({
                id: 'task-inactive',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.TASK_INACTIVE' ),
                subject: {
                    next: () => this.updateTaskActiveStatus( false ),
                },
            });
        }

        if ( this.data.showInNotes === undefined || this.data.showInNotes === false ) {
            options.push({
                id: 'show-in-notes',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.SHOW_IN_NOTES' ),
                subject: {
                    next: () => this.change.next({ showInNotes: true, index: this.index }),
                },
            });
        } else if ( this.data.showInNotes ) {
            options.push({
                id: 'remove-in-notes',
                label: this.translate.instant( 'SHAPE_DATA.DATA_ITEMS_OPTIONS.HIDE_IN_NOTES' ),
                subject: {
                    next: () => this.change.next({ showInNotes: false, index: this.index }),
                },
            });
        }

        return options;
    }

    public show() {
        this.visibility.next( true );
    }

    public hide() {
        this.visibility.next( false );
    }

    /**
     * emits when the data item changed
     */
    protected subscribeToItemChange( item: IUIControl<any> ) {
        this.subs.push(
            item.change.subscribe( value => {
                this.change.next({
                    value,
                    label: ( this.editableLabel || {} as any ).value,
                });
            }),
        );
    }

    /**
     * Label changed handler
     */
    protected labelChanged( label ) {
        this.change.next({ label });
    }

    /**
     * Finds the label type for the control
     */
    protected getUITypeLabel(): Function {
        const sysType = this.data.systemType;
        if ( sysType !== undefined ) { // role is 0.
            if ( sysType === SystemType.DueDate ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.DUE_DATE.LABEL' );
            } else if ( sysType === SystemType.EstimateHrs ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.ESTIMATE.LABEL' );
            } else if ( sysType === SystemType.EstimatePts ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.ESTIMATE_PTS.LABEL' );
            } else if ( sysType === SystemType.Location ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.LOCATION.LABEL' );
            } else if ( sysType === SystemType.Priority ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.PRIORITY.LABEL' );
            } else if ( sysType === SystemType.StartDate ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.START_DATE.LABEL' );
            } else if ( sysType === SystemType.Status ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.STATUS.LABEL' );
            } else if ( sysType === SystemType.Role ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.ROLE.LABEL' );
            } else if ( sysType === SystemType.XAxis ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.X_AXIS.LABEL' );
            } else if ( sysType === SystemType.YAxis ) {
                return  this.translate.instant( 'SHAPE_DATA.DATA_ITEMS.Y_AXIS.LABEL' );
            }
        }
    }


    /**
     * Finds the UI controller type for the given datatype
     */
    protected getUIControlType( dataItem: IDataItem<DataType> ): Function {
        const dataType: DataType = dataItem.type;
        if ( dataType === DataType.STRING_HTML ) {
            return TiptapEditor;
        }
        if ( dataType === DataType.OPTION_LIST ) {
            return SingleSelectComboUIC;
        }
        if ( dataType === DataType.NUMBER ) {
            return NumberInputUIC;
        }
        if ( dataType === DataType.BINARY ) {
            return CheckBoxUIC;
        }
        if ( dataType === 'tags' as any ) {
            return TagsInputUIC;
        }
        if ( dataType === 'users' as any ) {
            return PeoplePickerUIC;
        }
        if ( dataType === DataType.FORMULA ) {
            return FormulaFieldUIC;
        }
        if ( dataType === DataType.DATE ) {
            return DatePickerUIC;
        }
        if ( dataType === DataType.IDENTIFIER ) {
            return IdentifierInputUIC;
        }
        if ( dataType === DataType.LOOKUP ) {
            if ( !dataItem.options.allowMultiple ) {
                return EntityLookupSingleUIC;
            }
            return EntityLookupMultipleUIC;
        }
        return TextInputUIC;
    }

    protected updateTaskActiveStatus( isActive: boolean ) {
        // FIXME: use different approach to update task active status without injecting
        if ( this.data.roleBound && this.data.systemType === SystemType.Role ) {
            this.taskManager.updateTaskActiveStatusByRole( this.data.id, isActive );
        }
    }

}
