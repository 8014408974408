import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit,
    ViewChild, ElementRef, OnDestroy, AfterViewInit, OnChanges, SecurityContext } from '@angular/core';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This component is the text input component. This component allows
 * us to add a text input that can be either single lined or multi-lined.
 *
 * @author nuwanthi
 * @since 2018-06-14
 */

@Component({
    templateUrl: './text-input.cmp.html',
    selector: 'text-input',
    styleUrls: [ 'text-input.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TextInput implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    /**
     * Subject that emits for all the input changes
     */
    public inputChange: Subject<string> = new Subject();

    /**
     * Subject that emits when the input becomes empty
     */
    public isEmpty: BehaviorSubject<boolean> = new BehaviorSubject( true );

    /**
     * Value entered into the input field.
     */
    @Input() public value: string = '';

    /**
     * Type describing what the input field is allowed to accept.
     */
    @Input() public type: string = 'text';

    /**
     * This can be `textarea` to make the input tetarea.
     */
    @Input() public coreType: string = '';

    /**
     * Tooltip information
     */
    @Input() public tooltip: { heading?: string, content: string } = { heading: null, content: null };

    /**
     * Label describing what the input field is about.
     */
    @Input() public label: string;

    /**
     * The icon for the text field,
     */
    @Input() public icon: string;

    /**
     * To enable / diable clear button
     */
    @Input() public showClearButton: boolean = false;

    /**
     * Enable styles for invalid input
     */
    @Input() public invalidInput: boolean = false;

    /**
     * Placeholder value.
     */
    @Input() public placeholder: string = 'Enter your text here';

    /**
     * Make the input readonly.
     */
    @Input() public readonly: boolean = false;

    /**
     * Disable the input field.ackage
     */
    @Input() public disabled: boolean = false;

    /**
     * Specifies whether the labels and the control should be
     * inline. Default layout is for the controls to show below
     * the label.
     */
    @Input() public inline: boolean = false;

    /**
     * Specifies whether this component should emit values
     * for each and every input change or only when focus out
     */
    @Input() public emitForInputChange: boolean = false;

    /**
     * The debounce time when emitting the input changes
     */
    @Input() public debounce: number = 0;

    /**
     * Boolean to specify if the field is multi lined or
     * single lined. By default this will be single lined.
     */
    @Input() public multiLined: boolean = false;

    /**
     * Submits current text when the text input loses focus.
     */
    @Input() public submitOnBlur: boolean = false;

    /**
     * Submits current text when the user presses these keys
     */
    @Input() public submitOnCode: string[] = [ 'Tab', 'Enter' ];


    /**
     * Clear the text immediately after emitting it on submit.
     */
    @Input() public clearOnSubmit: boolean = false;

    /**
     * Focus the cursor on the text field when input is shown.
     */
    @Input() public autoFocus: boolean = false;

    /**
     * Shows the character counter.
     * If the value is -1, the character counter will not be shown.
     * Otherwise, the character counter will be shown with the given value.
     */
    @Input() public charCounter: number = -1;

    /**
     * Boolean that enables the scrollbar.
     */
    @Input() public allowScroll: boolean = false;

    /**
     * Show a transparent texbox.
     * withPointerEvents - do not disable pointer events on transparent textbox
     */
    @Input() public transparentTextBox: ( boolean | { withPointerEvents?: boolean }) = false;

    /**
     * Variable to hold the panel test ID.
     */
    @Input() public testId: string;

    /**
     * Emits an event when the text is edited on the input.
     */
    @Output() public updateText = new EventEmitter();

    /**
     * Emits an event when the text is edited on the input without a delay.
     */
    @Output() public updateTextNoDelay = new EventEmitter();

    /**
     * The blur event output
     */
    @Output() public blur: Subject<any> = new Subject();

    /**
     * The emits true when the input is empty
     */
    @Output() public charCount: BehaviorSubject<number> = new BehaviorSubject( 0 );

    /**
     * The key down event output
     */
    @Output() public submit: Subject<string> = new Subject();

    /**
     * Stores all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * Input item
     */
    @ViewChild( 'inputItem' )
    protected inputElement: ElementRef;

    /**
     * This is a non-standard way of setting innerHTML.
     * This approach has been taken to fix the issue
     * related to the text is being duplicated/repeated
     * on 'onblur' in DIV element.
     * Async pipe didn't work.
     * Getter Setter didn't work.
     * Changing the div to textarea didn't work.
     * Adding textarea-autosize plugin didn't work.
     */
    constructor( public translate: TranslateService, protected sanitizer: DomSanitizer ) {
        this.subs = [];
        const sub = this.updateText.subscribe( value => {
            if ( this.inputElement ) {
                // NOTE: To prevent xss attack
                const sanitizedValue = this.sanitizer.sanitize( SecurityContext.HTML, value );
                this.inputElement.nativeElement.value = sanitizedValue;
                // FIXME: This check !this.multiLined && this.emitForInputChange is a patch done to fix
                // issues on multilined text input component which listens for input changes which
                // resets tbe cursor of the input div element to the begining.
                // When the innerHTML is set the cursor in the text input is reset
                // W(e cannot manually set the cursor to users original position
                if ( !( this.multiLined && this.emitForInputChange )) {
                    this.inputElement.nativeElement.innerHTML = sanitizedValue;
                }
            }
        });
        this.subs.push( sub );
    }


    /**
     * Returns the text on the content-editable html element.
     */
    public get text(): string {
        return this.inputElement.nativeElement.innerText;
    }

    /**
     * Returns the text on the input box.
     */
    public get inputText(): string {
        return this.sanitizer.sanitize(
            SecurityContext.HTML,
            ( <HTMLInputElement>this.inputElement.nativeElement ).value,
        );
    }

    /**
     * Returns the icon URL.
     */
    public get iconURL() {
        return './assets/icons/symbol-defs.svg#nu-ic-' + this.icon;
    }

    /**
     * Checks whether input contains only whitespaces. This function returns true
     * if the text only contains white spaces or tabs
     */
    public get inputContainsWhitespaceOnly(): boolean {
        if ( this.multiLined ) {
            return ( this.text !== '' && this.text.replace( /\s/g, '' ) === '' );
        } else {
            return ( this.inputText !== '' && this.inputText.replace( /\s/g, '' ) === '' );
        }
    }

    public ngAfterViewInit(): void {
        if ( this.autoFocus ) {
            this.inputElement.nativeElement.focus();
        }
    }

    public ngOnInit() {
        this.subs.push(
            this.inputChange.pipe(
                tap( val => {
                    if ( val === '' && !this.isEmpty.value ) {
                        this.isEmpty.next( true );
                    } else if ( val !== '' && this.isEmpty.value ) {
                        this.isEmpty.next( false );
                    }
                    this.updateTextNoDelay.next( val );
                }),
                debounceTime( this.debounce ),
            ).subscribe( text => this.updateText.next( text )),
        );
    }

    /**
     * If input value change, change the native element innerText and value
     * This helps for update value when input value change without reinitialize the component
     */
    public ngOnChanges() {
        if ( this.inputElement ) {
            this.inputElement.nativeElement.innerText = this.value;
            this.inputElement.nativeElement.value = this.value;
        }
    }

    /**
     * Focus the text input
     */
    public focus() {
        if ( this.inputElement && this.inputElement.nativeElement ) {
            this.inputElement.nativeElement.focus();
        }
    }

    /**
     * Cleares the input
     */
    public clear() {
        if ( this.inputElement ) {
            this.updateText.next( '' );
            this.isEmpty.next( true );
        }
    }

    public onBlur( text: string, event: any ) {
        this.blur.next( event );
        if ( !this.emitForInputChange ) {
            this.inputChange.next( text );
        }

        // NOTE: This check has been added here to prevent the text input
        // committing text changes with only white spaces or new line characters. We cannot
        // take this check further above as it might break the UX.
        if ( this.inputContainsWhitespaceOnly ) {
            return;
        }

        if ( this.submitOnBlur ) {
            this.multiLined ? this.submit.next( this.text ) : this.submit.next( this.inputText );
        }
    }

    public onInputChange( text: string ) {
        if ( this.emitForInputChange ) {
            this.inputChange.next( text );
        }
    }

    /**
     * Unsubscribes from the subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public onKeydown( event: KeyboardEvent ) {
        // Ignore Shift + Enter key combination if multiline
        if ( this.multiLined && event.key === 'Enter' && event.shiftKey ) {
            return;
        }

        if ( this.submitOnCode.indexOf( event.key ) !== -1 ) {

            // NOTE: This check has been added here to prevent the text input
            // committing text changes with only white spaces or new line characters. We cannot
            // take this check further above as it might break the UX.
            if ( this.inputContainsWhitespaceOnly ) {
                // NOTE: We should return false value to prevent moving cursor to next line,
                // if only contains whitespaces.
                return false;
            }

            this.multiLined ? this.submit.next( this.text ) : this.submit.next( this.inputText );
            if ( this.clearOnSubmit ) {
                this.inputChange.next( '' );
            }
            // NOTE: We should return false value to prevent moving cursor to next line
            // after submitting the content.
            return false;
        }
    }

    public onKeyup( event: KeyboardEvent ) {
        const textarea = ( event.target as HTMLTextAreaElement );
        if ( this.charCounter !== -1 ) {
            textarea.maxLength = this.charCounter;
        }
        this.charCount.next( textarea.value.length );
    }

    /**
     * Set the value of the Text Input.
     */
    public setText( text: string ) {
        this.inputChange.next( text );
    }
}
