import { IResourceLoader, ResourceStatus } from 'flux-definition';
import { of, throwError, ConnectableObservable, from } from 'rxjs';
import { publishReplay,  switchMap, tap, map, catchError, take, filter, last, share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Database, Collection } from '@creately/rxdata-persistence';
import { ResponseType } from '../../command/abstract-http.cmd';
import { AppConfig } from '../../app-config';

/**
 * This is a loader service that can be used to load any type of resource.
 * It works with a cache. All resources loaded via the resourced loader are
 * cached. When a load request comes, the local cache is checked and if the
 * resource is found in the cache, it's fetched and returned. If the resource
 * is not found in the cache, it will be fetched via HTTP, added to cache and
 * then returned.
 *
 * @since   2017-09-07
 * @author  Ramishka
 */
@Injectable()
export class ResourceLoader implements IResourceLoader {

    /**
     * The rxdata collection instance which will act as the cache.
     */
    protected collection: Collection<any>;

    /**
     * Name of the rxdata collection instance
     */
    protected readonly resourceCollectionName: string = 'creately-resources';

    /**
     * A map of all HTTP requests in progress.
     */
    protected requestsInProgress: { [ url: string ]: Observable<Response> };

    /**
     * Constructor. Initializes the collection for caching resources (creates if
     * it does not exist).
     * @param http Angular HTTP service
     * @param database Rxdata database
     */
    constructor( private http: HttpClient, database: Database ) {
        this.requestsInProgress = {};
        this.collection = database.collection( this.resourceCollectionName );
    }

    /**
     * Returns any request options (headers) that needs to be sent'
     * with the GET request.
     * This can be overidden by specific types of loaders to customize
     * the request as required.
     */
    protected get requestOptions(): any {
        return {
            observe: 'events',
            responseType: ResponseType.Json,
        };
    }

    /**
     * This function caches a resource for a URL.
     * @param url - the URL of the resource (unique identifier)
     * @param data - the resource to cache in the resource loader.
     * @param status - indicates whether the resource is uploaded
     * to server or pending for upload.
     */
    public store( url: string, data: any,
                  status: ResourceStatus.Uploaded | ResourceStatus.PendingUpload ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        return this.cache( url, data, status );
    }

    /**
     * This function loads a given resource by a URL.
     * If the resource is cached, its fetched from the cache. If it's
     * not cached, it's fetched using an HTTP request.
     * @param url - resource URL to be loaded
     * @return - an observable that resolves the fetched resource
     */
    public load( url: string, urlParts?: string, ignoreCache = false ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        return this.fetchCachedResource( url ).pipe(
            switchMap( cachedResource => {
                if ( cachedResource && !ignoreCache ) {
                    return of( cachedResource.data );
                } else {
                    return this.fetchServerResourceAndCache( url );
                }
            }),
            take( 1 ),
        );
    }

    public async getDiagramDefsData( ignoreCache = false ) {
        return await this.load(
            AppConfig.get( 'RESOURCE_LOCATION' ) + 'diagram/def/diagram-def-sum.json', undefined , ignoreCache,
        ).toPromise();
    }

    public async getDiagramDefsDataByType( type: string ) {
        const defs = await this.getDiagramDefsData();
        return Object.values( defs || {}).find(( d: any ) => d.type === type );
    }

    /**
     * Load a given resource url.
     * This function always try to load given url directly and
     * if it can't be loaded, will load it from cache.
     * @param url - external resource url to be loaded
     */
    public forceLoad( url: string ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        return this.fetchServerResourceAndCache( url ).pipe(
            catchError( error => this.fetchCachedResource( url ).pipe(
                map( cachedResource => {
                    if ( cachedResource ) {
                        return cachedResource.data;
                    } else {
                        throw new Error( `Failed to load resource ${url} from server and cache` );
                    }
                }),
            )),
        );
    }

    /**
     * Removes  cached data for given url is removed from cache.
     * @param url to remove from cache.
     * @returns return an observable which resolves to null.
     */
    public remove( url: string ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        const promise = this.collection.remove({ id: url });
        return from( promise );
    }

    /**
     * Retrieves and returns the data from the cache and returns in an observable.
     * @param url key to listen to data changes in the cache.
     * @returns Returns an observable that emits when the data changes.
     */
    public watch( url ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        return this.fetchCachedResource( url ).pipe(
            filter( cachedResource => cachedResource ),
            map( cachedResource => cachedResource.data ),
        );
    }

    /**
     * This returns the list of resource which are not
     * uploaded to the server.
     */
    public getPendingUploadResources(): Observable<any> {
        return this.collection.find({ $or: [
            { status: 'pending-upload' },
            { status: null },
          ]});
    }

    /**
     * This returns the status of the give resource
     * which is cached.
     * @param url - key of the cached resource
     */
    public getResourceStatus( url ): Observable<any> {
        return this.fetchCachedResource( url ).pipe(
            map( cachedResource => {
                if ( cachedResource ) {
                    return cachedResource.status;
                } else {
                    throw new Error( `Failed to load resource ${url} from cache` );
                }
            }),
        );
    }

    /**
     * This update the status property of the given resource key with
     * the given status value.
     * @param url - key of the cached resource.
     * @param status - 'uploaded' or 'pending-upload'
     */
    public setResourceStatus( url: string, status: ResourceStatus.Uploaded | ResourceStatus.PendingUpload ) {
        this.collection.update({ id: url }, { $set : { status : status }});
    }

    public getServerResource( resourceURL: string ): Observable<any> {
        return this.http.get( resourceURL ).pipe(
            share(),
        );
    }

    /**
     * Fetches the resource for given URL from server and cahces it. Retrieved
     * resource data is returned.
     * @param url URL where the resource is available.
     */
    protected fetchServerResourceAndCache( url ): Observable<any> {
        return this.fetchServerResource( url ).pipe(
            tap( data => this.cache( url, data, ResourceStatus.Uploaded )),
        );
    }

    /**
     * Fetch given url from web server. It makes sure only a single http
     * request is created
     * @param resourceURL
     */
    protected fetchServerResource( resourceURL: string ): Observable<any> {
        const requestInProgress = this.requestsInProgress[resourceURL];
        if ( requestInProgress ) {
            return requestInProgress;
        }
        let requestOptions = this.requestOptions || {};
        if ( this.isNeutrinoUrl( resourceURL )) {
            requestOptions = { ...requestOptions, withCredentials: true };
        }
        const connectable = this.http.get<any>( resourceURL, requestOptions )
            .pipe( publishReplay()) as ConnectableObservable<any>;
        const resultObservable = this.requestsInProgress[resourceURL] = connectable.pipe(
            last(),
            tap({
                error: () => delete this.requestsInProgress[resourceURL],
                complete: () => delete this.requestsInProgress[resourceURL],
            }),
            switchMap(( response: HttpResponse<any> ) => this.handleResponse( response )),
            catchError( response => this.handleError( resourceURL, response )),
        );
        // start the http request
        connectable.connect();
        return resultObservable;
    }

    /**
     * Fetch cached data for the given key
     * @param cacheIndex key to search for
     */
    protected fetchCachedResource( cacheIndex: string ): Observable<any> {
        return this.collection.findOne({ id: cacheIndex }).pipe(
            switchMap( obj => {
                if ( obj && this.isUrl( obj.data )) {
                    return this.collection.findOne({ id: obj.data });
                } else {
                    return of( obj );
                }
            }),
        );
    }

    /**
     * This function handles successful responses from @angular/common/http.
     * @param res - The response object returned from @angular/common/http
     * @return observable of data extracted from the response
     */
    protected handleResponse( res: HttpResponse<any> ): Observable<any> {
        return of( res.body );
    }

    /**
     * Inserts given url and data to caching database.
     * @param url URL to cache the resource.
     * @param data Resource data to cache.
     * @param status Indicates whether the resource is uploaded
     * to server or pending for upload.
     */
    protected cache( url: string, data: any,
                     status: ResourceStatus.Uploaded | ResourceStatus.PendingUpload ): Observable<any> {
        return from( this.collection.insert({ id: url, data: data, status: status }));
    }

    /**
     * This function handles failed responses from @angular/common/http
     * @param res The response object returned from @angular/common/http
     */
    protected handleError( url: string, res: HttpResponse<any> ): Observable<Error> {
        const error: Error = new Error( 'Could not load the resource for ' + url
            + '. HTTP request failed with response code ' + res.status );
        return throwError( error );
    }

    /**
     * This function checks given input is in url format or not.
     * Returns true if the given input string is a url.
     */
    private isUrl( url: string ) {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test( url );
    }

    /**
     * This function checks given url is a neutrino url
     */
    private isNeutrinoUrl( url: string ) {
        return url.includes( '/neutrino/' );
    }
}
