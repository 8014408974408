import { AbstractMessageCommand } from 'flux-connection';
import { CommandInterfaces } from 'flux-core';

export class GetExternalImportProcessingFiles extends AbstractMessageCommand {
    public static get dataDefinition(): {} {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    public get version(): number {
        return 1;
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ): any {
        return response;
    }
}

Object.defineProperty( GetExternalImportProcessingFiles, 'name', {
    value: 'GetExternalImportProcessingFiles',
});
