import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { GlobalNotificationService } from '../global-notification.svc';

/**
 * GetGlobalNotifications
 * Retrieve global notifications for the user and store them in state service.
 */
@Injectable()
@Command()
export class GetGlobalNotifications extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected globalNotificationService: GlobalNotificationService ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

    /**
     * Retrieve the notification data from response and add them to state service.
     * @param response
     * @returns
     */
    public executeResult( response: any ): boolean {
        this.globalNotificationService.setGlobalNotifications( response.data.notifications );
        return true;
    }
}

Object.defineProperty( GetGlobalNotifications, 'name', {
    value: 'GetGlobalNotifications',
});
