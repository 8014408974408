import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { EMPTY, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TaskModel, TaskStatus } from 'flux-diagram';

/**
 * Locator to fetch tasks for the current user
 *
 */

@Injectable()
export class TaskLocator {

    constructor( protected dataStore: DataStore ) {}

    /**
     * Gets the task groupings for the user
     * @returns
     */
    public getTaskGroups(): Observable<string[]> {
        // TODO
        return EMPTY;
    }

    /**
     * Get tasks for the given group for the user
     * @param groupName
     * @returns
     */
    public getTasks( groupName?: string, limit?: number, skip?: number  ): Observable<TaskModel[]> {
        return this.dataStore.find( TaskModel );
    }

    public getTasksForShape( shapeId: string ) {
        const selector = { shapeId: shapeId };
        return this.dataStore.find( TaskModel, selector );
    }

    /**
     * This function returns the matching TaskModel for a given task id.
     * @param taskId string
     * @return Observable<TaskModel>
     */
    public getTask( taskId: string ): Observable<TaskModel> {
        return this.dataStore.find( TaskModel, { id: taskId }).pipe(
            map( task => task[0]),
        );
    }

    /**
     * This function returns the matching TaskModel for a given role id.
     * @param taskId string
     * @return Observable<TaskModel>
     */
    public getTaskByRoleId( roleId: string ): Observable<TaskModel> {
        return this.dataStore.find( TaskModel, { roleId: roleId }).pipe(
            map( task => task[0]),
        );
    }

    /**
     * Retrieves Folder categories
     */
    public getTasksByStatus( status: TaskStatus, userId?: string ) {
        return this.getTasks().pipe(
            filter( tasks => !!tasks ),
            map( tasks => tasks.filter(
                task => this.filterTask( task, status, userId ),
            )),
        );
    }

    public filterTask( task: TaskModel, status: TaskStatus, userId?: string ) {
        if ( userId && userId.length
            && !task.roles.find( role => role.userId === userId )) {
            return false;
        }

        if ( status === TaskStatus.Blocked ) {
            return task.isInactive();
        }

        if ( status === TaskStatus.Completed ) {
            return task.isCompleted();
        }
        const active = task.isActive === undefined || task.isActive; // To show older task
        return active && task.status === status;
    }

}
