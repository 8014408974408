import { empty, defer, Observable } from 'rxjs';
import { tap, switchMap, concat, filter } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, ViewContainerRef, Injector, ComponentFactoryResolver,
    ComponentRef, OnInit, OnDestroy } from '@angular/core';
import { IModalOptions } from '../../controller/modal-controller';
import { Animation } from '../animation';
import { DynamicComponent } from '../dynamic.cmp';
import { StateService } from '../../controller/state.svc';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'modal-window-container',
    template: '<ng-content></ng-content>',
})
/**
 * This is a container where the modal window is get added and removed.
 *
 * This container needs to placed in the applciation and whenever user wants to show/hide modal
 * the modal is get added and removed from this container.
 */
export class ModalWindowContainer extends DynamicComponent implements OnInit, OnDestroy {

    /**
     * Holds the current modal reference
     */
    protected currentModalInfo: { ref: ComponentRef<any>, enterAnimation: Animation, leaveAnimation: Animation };

    /**
     * Holds a list of subscriptions
     */
    protected subs = [];

    constructor( protected state: StateService<any, any>,
                 protected vcrf: ViewContainerRef,
                 protected componentFactoryResolver: ComponentFactoryResolver,
                 protected injector: Injector ) {
        super( componentFactoryResolver, injector );
    }

    /**
     * Listens for the onEnter and onDismiss events and based on that show/hide
     * the modal window.
     */
    public ngOnInit() {
        this.listenToModalWindowChanges();
        this.listenToDialogBoxChanges();
    }

    /**
     * Unsubscribe from all subscriptions
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Starts listening to modal window changes and show/hide
     * modal window based on state.
     */
    protected listenToModalWindowChanges() {
        this.subs.push(
            this.state.changes( 'ModalWindow' ).pipe(
                filter( data => !!data ),
                tap( data => {
                    if ( data.blur ) {
                        this.state.set( 'ModalBlur', true );
                    } else {
                        this.state.set( 'ModalBlur', false );
                    }
                }),
                switchMap( data => {
                    if ( data.show && !data.options?.inputs?.appLevel ) {
                        return this.show( data.type, data.options );
                    }
                    return this.destroyModal();
                }),
            ).subscribe(),
        );
    }

    /**
     * Starts listening to the dialog box state and hides/show the
     * modal window based on whether the dialog box is open or closed.
     */
    protected listenToDialogBoxChanges() {
        this.subs.push(
            this.state.changes( 'DialogBox' ).pipe(
                tap ( data => {
                   if ( this.state.get( 'ModalWindow' ) && this.state.get( 'ModalWindow' ).show ) {
                        if ( data && data.open ) {
                            this.getModalWindowInnerComponent().classList.add( 'fx-hidden' );
                        } else {
                            this.getModalWindowInnerComponent().classList.remove( 'fx-hidden' );
                        }
                   }
                }),
            ).subscribe(),
        );
    }

    /**
     * Returns the container of the component added into the modal window.
     */
    protected getModalWindowInnerComponent(): Element {
        if ( this.state.get( 'ModalWindow' ).show ) {
            return  document.getElementsByClassName( 'modal-window-container' )[0];
        }
    }

    /**
     * Creates and show a modal.
     */
    protected show( type: Function, opt?: IModalOptions ): Observable<any> {
        this.state.set( 'LoadingIndicatorState', { main: false });
        return this.destroyModal().pipe(
            concat( defer(() => this.createModal( type, opt ))),
            tap(() => {
                this.state.set( 'DataLoadingIndicatorState', { main: false });
                this.state.set( 'LoadingIndicatorState', { main: false });
            }),
        );
    }

    /**
     * Creates a modal to display
     */
    protected createModal( type: Function, options?: IModalOptions ): Observable<any> {
        const modalRef: ComponentRef<any> = this.makeComponent( type );
        this.currentModalInfo = {} as any;
        this.currentModalInfo.ref =  modalRef ;
        this.vcrf.insert( modalRef.hostView );
        if ( options ) {
            this.setInputs( modalRef.instance, options.inputs );
            this.currentModalInfo.enterAnimation = options.enterAnimation;
            this.currentModalInfo.leaveAnimation = options.leaveAnimation;
        }
        modalRef.changeDetectorRef.markForCheck();
        const animation = this.showAnimation( modalRef );
        if ( !animation ) {
            return empty();
        }
        return animation.start();
    }

    /**
     * Removes the modal from display
     */
    protected destroyModal(): Observable<any> {
        if ( this.currentModalInfo ) {
            const animation = this.hideAnimation( this.currentModalInfo.ref );
            if ( !animation ) {
                this.currentModalInfo.ref.destroy();
                this.currentModalInfo = undefined;
                return empty();
            }
            return animation.start().pipe(
                tap({
                    complete: () => {
                        this.currentModalInfo.ref.destroy();
                        this.currentModalInfo = undefined;
                    },
                }));
        }
        return empty();
    }

    /**
     * Animate the given component by setting leaveAnimation and return
     * the animation.
     * FIXME: this code should be inside the modal component
     */
    protected hideAnimation( cmp: ComponentRef<any> ): Animation {
        const element = this.getChildElement( cmp );
        if ( !element ) {
            return null;
        }
        let leaveAnimation = this.currentModalInfo.leaveAnimation;
        if ( !leaveAnimation ) {
            leaveAnimation = new Animation({
                to: { opacity: 0 },
                transitionProperty: 'opacity',
                duration: 10,
                easing: 'linear',
            }); // animation needs to be decided
        }
        leaveAnimation.element = element;
        return leaveAnimation;
    }

    /**
     * Animate the given component by setting enterAnmation and
     * return observable
     * FIXME: this code should be inside the modal component
     */
    protected showAnimation( cmp: ComponentRef<any> ): Animation {
        const element = this.getChildElement( cmp );
        if ( !element ) {
            return null;
        }
        let enterAnimation = this.currentModalInfo.enterAnimation;
        if ( !enterAnimation ) {
            enterAnimation = new Animation({
                to: { opacity: 1 },
                transitionProperty: 'opacity',
                duration: 10,
                easing: 'linear',
            }); // animation needs to be decided
        }
        enterAnimation.element = element;
        return enterAnimation;
    }

    /**
     * FIXME: this refers to html elements inside child components. Remove this.
     */
    private getChildElement( cmp: ComponentRef<any> ) {
        return cmp.location.nativeElement.firstElementChild as HTMLElement;
    }
}
