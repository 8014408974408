import { RealtimeStateService } from './../../realtime/realtime-state.svc';
import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandError, CommandInterfaces, CommandScenario, EventSource, StateService } from 'flux-core';
import { isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectionContainerStateService } from '../../../editor/selection/selection-state';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { DiagramModel } from '../model/diagram.mdl';
import { ShapeBoundsLocator } from '../../../editor/diagram/containers/shape-bounds-locator';

/**
 * Add selection to the shapes requested from the canvas.
 * Handles all cases for single selection as well as multi selection,
 * adding or removing shapes from an existing multi selection.
 */
@Injectable()
@Command()
export class SelectShapes extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            shapeIds: false, // The list of shape id's to select or unselect
            add: false, // Boolean to indicate if to add to the existing selection. Default false.
            remove: false, // Boolean to indicate to remove the existing selection. Defalt false.
            arrowDirection: false, // arrow direction if selection has been triggered by an arrow key
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand', 'IDiagramCommand' ];
    }

    protected state: StateService<any, any>;
    protected containerState: SelectionContainerStateService;

    constructor(
        state: StateService<any, any>,
        protected realtimeStates: RealtimeStateService,
        protected shapeBoundsLocator: ShapeBoundsLocator,
        protected ll: DiagramLocatorLocator ) {
        super()/* istanbul ignore next */;
        this.state = state;
        this.containerState = state;
    }

    public get states(): { [ stateId: string ]: any } {
        if ( this.data.selectionList.length === 0 ) {
            this.state.set( 'SelectionInteraction', {});
        }
        return {
            Selected: this.data.selectionList,
        };
    }

    public get locator() {
        return this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
    }

    /**
     * This function execute the shape selections for the selected shapes.
     * list - the already selected shapes in the canvas.
     * ids - the shapes which needs to be selected.(merged with the previous selection)
     */
    public execute(): Observable<boolean> {
        if ( this.eventData.source === EventSource.EXTERNAL ) {
            return;
        }
        const selection = this.updateSelection();
        if ( selection ) {
            return selection.pipe(
                switchMap( s => this.locator.getDiagramOnce().pipe(
                    map( model => ({ model, s })),
                )),
                map(({ model, s }) => {
                    this.data.selectionList =  this.data.selectionList.filter( id => model.shapes[id]);
                    this.blockSelection();
                    const containerData = this.shapeBoundsLocator.getContainerData() || { children: [], containers: []};
                    this.realtimeStates.set( 'selected', JSON.stringify( this.data.selectionList ));
                    const currentHistory = this.containerState.get( 'selectedContainerHistory' ) || [];
                    const { length } = currentHistory;
                    const { arrowDirection } = this.data;
                    if ( this.data.selectionList.length === 1 ) {
                        const selected = this.data.selectionList[0];
                        const isContainerSelected = containerData.containers.includes( selected );
                        const selectedChild = containerData.children.find( c => c.id === selected );
                        if ( isContainerSelected ) {
                            currentHistory.push({ id: selected, childId: undefined, arrowDirection });
                        } else if ( selectedChild ) {
                            currentHistory
                                .push({ id: selectedChild.containerId, childId: selectedChild.id, arrowDirection });
                        }

                    }

                    // if we didn't find a container/children selected
                    if ( currentHistory.length === length ) {
                        currentHistory.push({ id: null, childId: null, arrowDirection });
                    }
                    // only keep 3 last selections
                    if ( currentHistory.length > 3 ) {
                        currentHistory.shift();
                    }
                    this.containerState.set( 'selectedContainerHistory', currentHistory );
                    return s;
                }),
            );
        }
    }

    /**
     * onError
     * Ignore errors occurring while trying to select shapes where possible.
     */
    public onError( err: CommandError ) {
        // FIXME: check the error type when the command is available
        this.log.error( `Failed to select shapes: ${err.message}` );
    }

    /**
     * Merges the selection state with the current requested selection in
     * the proper way that selection is added or removed. In multi selection
     * user can unselect specific shapes
     * @param state The current selection state value
     * @param current The change requested in this command
     */
    protected mergeSelection( state: string[], current: string[]) {
        current.forEach( id => {
            if ( state.includes( id )) { // Dont add if already in selection
                if (  state.length > 1 ) { // Remove if is a multi selection
                    const index = state.indexOf( id );
                    state.splice( index, 1 );
                }
            } else { // Add if not in selection
                state.push( id );
            }
        });
    }

    /**
     * Returns true of the requested selection is already a subset of the
     * current selection in the state.
     * @param state The current selection state value.
     * @param current the change requested in this command
     */
    protected isSubSet( state: string[], current: string[]): boolean {
        if ( current.length === 0 ) {
            return false;
        }
        return current.filter( id => !state.includes( id )).length === 0;
    }

    /**
     * Remove the ids which should be blocked, from the selection.
     */
    protected blockSelection() {
        if ( this.data.selectionList && this.data.selectionList.length > 0 ) {
            // NOTE: Disabling realtime selection blocking,
            // const blocking: Array<string> = flatten(
            //     ( this.state as StateService<any, any> ).get( 'SelectionBlocked' ).map( data => data.selected || []),
            // );
            this.data.selectionList = ( this.data.selectionList as Array<string> );
                // .filter( id => !blocking.includes( id ));
        }
    }

    protected updateSelection(): Observable<boolean> {

        const list = this.state.get( 'Selected' ).slice();

        let ids: string[] = [];

        if ( this.data.shapeIds ) {
            ids = [ ...this.data.shapeIds ];
        }

        if ( !this.data.remove && isEqual( ids, list )) {
            return;
        }

        if ( this.data.remove ) {
            // Only remove the selection from given ids
            // If ids are empty means current selection won't get removed
            if ( list.length > 0 && ids.length > 0 ) {
                this.removeSelection( list, ids );
                this.data.selectionList = list;
                return of( true );
            }
            return;
        }

        return this.mergeGroupedShapes( ids ).pipe(
            map( mergedIds => {
                if ( this.data.add ) {
                    this.mergeSelection( list, mergedIds );
                    this.data.selectionList = list;
                }  else if ( !this.isSubSet( list, mergedIds )) {
                    this.data.selectionList = mergedIds;
                } else {
                    this.data.selectionList = list;
                }
                return true;
            }),
        );
    }

    /**
     * This removes the given ids from the selected states
     */
    protected removeSelection( state: string[], ids: string[]) {
        ids.forEach( id => {
            if ( state.includes( id )) {
                const index = state.indexOf( id );
                state.splice( index, 1 );
            }
        });
    }

    /**
     * This function merges all the grouped shapes in to the
     * current shapes.
     * @param current - The current selected shapes which needs to
     * merged to/ removed from the selection.
     */
    private mergeGroupedShapes( current: string[]): Observable<string[]> {
        return this.locator.getDiagramOnce().pipe(
            map(( diagram: DiagramModel ) => {
                const includingGroups = diagram.getAllShapesInGroupHierarchy( current );
                // NOTE: Removing the broken shapes ids which are in the selection
                const validShapesOnly = includingGroups.filter( id => diagram.shapes[id]);
                return validShapesOnly;
            }),
        );
    }
}

Object.defineProperty( SelectShapes, 'name', {
    value: 'SelectShapes',
});
