import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { SelectionStateService } from '../../selection/selection-state';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { IEntityDef } from 'flux-definition';

/**
 * SyncEDataChanges
 * This command updates a set of properties on a given set of shapes
 * This is expected to be triggered by an eData Change.
 */
@Injectable()
@Command()
export class SyncEDataChanges extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeIds: string[],
        set: { path: string, value?: any, def?: any }[],
        entityDef: IEntityDef,
    };

    protected state: SelectionStateService;

    /**
     * Inject state service.
     */
    constructor(
        protected modelChangeUtils:  ModelChangeUtils,
        protected ds: DiagramChangeService,
        state: StateService<any, any> ) {
        super( ds ) /** istanbul ignore next */;
        this.state = state;
    }


    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        this.modelChangeUtils.syncEDataChanges(
            this.changeModel,
            this.data,
        );
    }


}

Object.defineProperty( SyncEDataChanges, 'name', {
    value: 'SyncEDataChanges',
});
