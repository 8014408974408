import { PlanPermission, IDialogBoxData } from 'flux-definition';
import { AppConfig } from 'flux-core';

const redirectHandler: Function = () => window.open( AppConfig.get( 'PLAN_PERM_ERROR_REDIRECT_URL' ), '_blank' );

/**
 * Plan permission error messages and related data. This is in a single file as
 * constant so that it can be modify the text easily in future by maketing team
 * based on their findings.
 */
export const PLAN_PERM_ERROR_MESSAGE: { [ key: string ]: IDialogBoxData } = {
    [ PlanPermission.PROJECT_ADD_EDITORS ]: {
        id: PlanPermission.PROJECT_ADD_EDITORS,
        heading: 'PLAN_PERM.PERM_DENIED.PROJECT_ADD_EDITORS.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.PROJECT_ADD_EDITORS.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
        buttons: [{ type: 'ok', text: 'BUTTONS.VIEW_UPGRAGE_OPTIONS.DEFAULT', clickHandler: redirectHandler }],
    },
    [ PlanPermission.PROJECT_COLLBORATOR_ADD_EDITORS ]: {
        id: PlanPermission.PROJECT_COLLBORATOR_ADD_EDITORS,
        heading: 'PLAN_PERM.PERM_DENIED.DIAGRAM_COLLABORATOR_ADD_EDITORS.HEADING',
        description: 'PLAN_PERM.PERM_DENIED.DIAGRAM_COLLABORATOR_ADD_EDITORS.DESCRIPTION',
        type: 'warning',
        icon: 'warning',
    },
};
