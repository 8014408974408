import { IResourceLoader } from 'flux-definition';
import { AbstractShapeViewFactory } from './abstract-shape-view-factory';
import { ImageRenderView } from './image-render-view';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from 'flux-core';
import { ShapeDataModel } from '../model/shape-data.mdl';

/**
 * This is the stateless factory for creating all types of Image Shape Views. Any view
 * created must be of the type ImageRenderView or anything that extends that.
 *
 * This creates the image view and merges the definition entry class into the view. Makes
 * the shapes view definition accessible from within the view and vice-versa.
 * It also loads the image the shape is based on, and sets its source to the view.
 *
 * @author Ramishka
 * @since 2019-02-20
 */
export class ImageShapeViewFactory extends AbstractShapeViewFactory {

    /**
     * The singleton instance of the ImageShapeViewFactory.
     */
    public static get instance(): ImageShapeViewFactory {
        if ( !this._instance ) {
            this._instance = new ImageShapeViewFactory();
        }
        return this._instance;
    }

    protected static _instance: ImageShapeViewFactory;

    /**
     * The resource loader that will be used to fetch the image
     * the view is based on (either from cache or from remote url)
     */
    protected resourceLoader: IResourceLoader;

    /**
     * Registers the resource loader that will be used by this view factory.
     * This is mandatory for the view factory to function.
     */
    public registerResourceLoader( loader: IResourceLoader ) {
        if ( this.resourceLoader ) {
            throw new Error( 'A valid resource loader was already regisrered.' );
        }
        if ( !loader ) {
            throw new Error( 'Provide a valid Resource Loader.' );
        }
        this.resourceLoader = loader;
    }

    /**
     * A factory method which will create the shape view from the model. Creates the view
     * of the given type which extends a ShapeRenderView.
     * Loads the image the image shape is based on, and sets its data to the view.
     * @param model The shape model that is an extension of ShapeDataModel
     * @param type The type of the view class which extends ImageRenderView
     */
    public create( model: ShapeDataModel, type: typeof ImageRenderView ): Observable<ImageRenderView> {
        const transparentImage =
            'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        const scale = model.getScaleDownValue();
        if ( scale !== 1 ) {
            // Try to load scaled down image but for old diagrams it may not availble
            // so we should try to load the original one
            return this.resourceLoader.load( this.getImageUrl( model.hashScaled )).pipe(
                catchError(() => this.resourceLoader.load( this.getImageUrl( model.hash ))),
                // Render a transparent image if any error occured to prevent
                // the canvas from breaking and to make sure other shapes are rendered
                catchError(() =>
                    of( transparentImage )),
                map( base64 => {
                    const view: any = new type( model );
                    view.imageSource = base64;
                    return view;
                }),
            );
        }
        return this.resourceLoader.load( this.getImageUrl( model.hash )).pipe(
            catchError(() =>
                of( transparentImage )),
            map( base64 => {
                const view: any = new type( model );
                view.imageSource = base64;
                return view;
            }),
        );
    }

    /**
     * Retrieves the URL for the image
     * @param hash - image hash
     */
    protected getImageUrl( hash: string ): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash;
    }
}
