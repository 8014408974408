import { AfterViewInit, ChangeDetectionStrategy,
    Component, Input, Output, OnDestroy, SecurityContext } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { INotification } from '../notification.i';
import { take, tap } from 'rxjs/operators';
import { Tracker } from '../../../tracker/tracker';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationType } from '../../../controller/notifier-controller';

/**
 * AbstractNotification Component
 * This component is an abstract Notification component that has only basic
 * notification facilities implemented. The component is meant to be instantiated
 * programmatically as needed through a NotifierController injected into whichever
 * component needs to show notifications.
 *
 * Note: The functions passed to button actions should be bound with the calling object if needed.
 *
 * For example:
 *
 * const options: INotifierOptions = {
 *   inputs: {
 *     heading: 'Example notification',
 *     description: '<p>Example description</p>',
 *     type: NotificationType.Warning,
 *     buttonOneText: this.translate.instant( 'SOME_KEY' ),
 *     buttonOneAction: this.sendMessage.bind( this ),
 *     buttonTwoText: getThisText(),
 *     buttonTwoAction: this.performAction.bind( this ),
 *     linkText?: string;
 *     link?: string;
 *     autoDismiss: false,
 *     dismissAfter: 5000,
 *     notificationAction: {
 *       action: this.someAction(this),
 *       args?: [ 'argA' ],
 *     },
 *     icon: 'tick',
 *     user: 'user-id-a'
 *   },
 * };
 *
 * this.notifierController.show( 'n2', AbstractNotification, NotificationType.Warning, options );
 *
 * And some time later,
 *
 * this.notifierController.hide( 'n2' );
 *
 * Or set the autoDismiss (and optionally dismissAfter) properties on inputs
 * to have the notification dismiss itself after a given period.
 *
 * @author jerome
 * @since 2020-04-10
 */
@Component({
    selector: 'abs-notification',
    styleUrls: [ './abstract-notification.cmp.scss' ],
    templateUrl: './abstract-notification.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbstractNotification implements INotification, AfterViewInit, OnDestroy {

    /**
     * The identifier for the notification.
     */
    @Input()
    public id: string;

    /**
     * The heading for the notification.
     */
    @Input()
    public heading: string;

    /**
     * The description for the notification.
     */
    @Input()
    public description: string;

    /**
     * The description for the notification.
     */
    @Input()
    public descriptionSubPrice?: string;

    /**
     * The description to display on the notification.
     */
    @Input()
    public descriptionSub1?: string;

    /**
     * The description to display on the notification.
     */
    @Input()
    public descriptionSub2?: string;

    /**
     * The description to display on the notification.
     */
    @Input()
    public descriptionSub3?: string;

    /**
     * The description to display on the notification.
     */
    @Input()
    public descriptionSub4?: string;

    /**
     * The realtime description for the notification.
     */
    @Input()
    public descriptionText: Observable<string>;

    /**
     * The realtime follow user color for the notification.
     */
    @Input()
    public backgroundColor: string;

    /**
     * The text for the first button.
     */
    @Input()
    public buttonOneText: string;

    /**
     * The function to call when the first button is clicked.
     */
    @Input()
    public buttonOneAction: Function;

    /**
     * The text for the second button.
     */
    @Input()
    public buttonTwoText: string;

    /**
     * The function to call when the second button is clicked.
     */
    @Input()
    public buttonTwoAction: Function;

    /**
     * The function to call when the notification is closed (dismissed)
     */
    @Input()
    public onDismiss: Function;

    /**
     * The text for the link.
     */
    @Input()
    public linkText: string;

    /**
     * The function to call when the notification is clicked.
     */
    @Input()
    public notificationAction: { action: Function, args: any[] };

    /**
     * The icon to be used on the notification.
     * The default notification icon is info.
     */
    @Input()
    public icon: string = 'tick';

    /**
     * The user who is the source of the notification.
     * If set, the user's profile image or display icon will be shown
     * instead of the notification icon.
     */
    @Input()
    public user: any;

    /**
     * Whether the notification should be dismissed automatically.
     */
    @Input()
    public autoDismiss: boolean = false;

    /**
     * The duration the notification should be displayed before dismissing automatically.
     */
    @Input()
    public dismissAfter: number = 5000;

    /**
     * When this observable emits it will dismiss the notification.
     */
    @Input()
    public dismissWhen: Observable<any>;

    /**
     * Lets the notification know if it is visible or not.
     */
    @Input()
    public visible: BehaviorSubject<boolean>;

    /**
     * Only use for user follow
     */
    @Input()
    public isUserFollow: boolean = false;

    /**
     * Emits when the notification is dismissed.
     */
    @Output()
    public dismiss: BehaviorSubject<boolean>;

    /**
     * Emits when the notification view is ready.
     */
    @Output()
    public ready: BehaviorSubject<boolean>;

    /**
     * Stores all the subscriptions.
     */
    protected subs: Subscription[];

    /**
     * The link to open when clicked.
     */
    protected _link: string;

    /**
     * The type of notification. Acceptable types are neutral, warning
     * error and info.
     */
    protected _type: NotificationType = NotificationType.Neutral;

    constructor (
        protected sanitizer: DomSanitizer,
    ) {
        this.dismiss = new BehaviorSubject<boolean>( false );
        this.ready = new BehaviorSubject<boolean>( false );
        this.visible = new BehaviorSubject<boolean>( null );
        this.subs = [];
    }

    @Input()
    public set link( link: string ) {
        this._link = this.sanitizer.sanitize( SecurityContext.URL, link );
    }
    public get link(): string {
        return this._link;
    }

    @Input()
    public set type( type: NotificationType ) {
        this._type = type;
        this.setIcon();
    }

    public get type(): NotificationType {
        return this._type;
    }

    public getNgClass() {
        return {
            [ 'abstract-notification-' + this.type ]: true,
            'small-notification': !this.buttonOneText && !this.buttonTwoText,
        };
    }

    /**
     * Emit ready and start countdown to dismiss notification
     * if autoDismiss is set to true.
     */
    ngAfterViewInit(): void {
        this.ready.next( true );
        this.subs.push(
            this.visible.pipe(
                tap( visible => {
                    if ( visible === true ) {
                        this.onVisible();
                    } else if ( visible === false ) {
                        this.onInvisible();
                    }
                }),
            ).subscribe(),
        );

        if ( this.type === NotificationType.Warning ) {
            Tracker.track( 'notification.warning.load', {
                value1: this.heading,
                value1Type: 'title',
                value2: this.description,
                value2Type: 'description',
            });
        } else if ( this.type === NotificationType.Error ) {
            Tracker.track( 'notification.error.load', {
                value1: this.heading,
                value1Type: 'title',
                value2: this.description,
                value2Type: 'description',
            });
        }
    }

    /**
     * Dismiss this notification.
     */
    public dismissNotification( onUserAction: boolean, event?: MouseEvent ): void {
        if ( this.dismiss.value ) {
            // already dismissed
            return;
        }
        if ( event ) {
            event.stopPropagation();
        }
        if ( this.onDismiss ) {
            this.onDismiss();
        }
        if ( onUserAction ) {
            Tracker.track( 'notification.close' );
        }
        this.dismiss.next( true );
    }

    /**
     * Handle the click event on the first button.
     */
    public handleButtonOneClick( event: MouseEvent ): void {
        event.stopPropagation();
        if ( this.buttonOneAction ) {
            this.buttonOneAction();
            if ( this.type === NotificationType.Warning ) {
                Tracker.track( 'notification.warning.button.click', {
                    value1: this.buttonOneText,
                    value1Type: 'label',
                });
            } else if ( this.type === NotificationType.Error ) {
                Tracker.track( 'notification.error.button .click', {
                    value1: this.buttonOneText,
                    value1Type: 'label',
                });
            }
        }
    }

    /**
     * Handle the click event on the second button.
     */
    public handleButtonTwoClick( event: MouseEvent ): void {
        event.stopPropagation();
        if ( this.buttonTwoAction ) {
            this.buttonTwoAction();
            if ( this.type === NotificationType.Warning ) {
                Tracker.track( 'notification.warning.button.click', {
                    value1: this.buttonTwoText,
                    value1Type: 'label',
                });
            } else if ( this.type === NotificationType.Error ) {
                Tracker.track( 'notification.error.button .click', {
                    value1: this.buttonTwoText,
                    value1Type: 'label',
                });
            }
        }
    }

    /**
     * Handle the click event on the link.
     */
    public handleLinkClick(): void {
        if ( this.type === NotificationType.Warning ) {
            Tracker.track( 'notification.warning.button.click', {
                value1: this.linkText,
                value1Type: 'label',
            });
        } else if ( this.type === NotificationType.Error ) {
            Tracker.track( 'notification.error.button .click', {
                value1: this.linkText,
                value1Type: 'label',
            });
        }
    }

    /**
     * Handle the click event on the notification body.
     */
    public handleNotificationClick( event: MouseEvent ): void {
        event.stopPropagation();
        if ( this.notificationAction && this.notificationAction.action ) {
            const args = this.notificationAction.args || [];
            this.notificationAction.action( ...args );
        }
    }

    /**
     * Returns the icon URL.
     */
    public iconUrl(): string {
        return './assets/icons/symbol-defs.svg#nu-ic-su-' + this.icon;
    }

    /**
     * Unsubscribes from all subscriptions on destroy.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public getBackgroundColor(): Object {
        return !!this.backgroundColor ? { 'background-color': this.backgroundColor } : {};
    }

    /**
     * Set the notification to dismiss automatically when visible
     * if autoDismiss is true.
     */
    protected onVisible(): void {
        if ( this.autoDismiss ) {
            setTimeout(() => {
                this.dismissNotification( false );
            }, this.dismissAfter );
        }
        if ( this.dismissWhen ) {
            this.dismissWhen.pipe(
                take( 1 ),
            ).subscribe(() => this.dismissNotification( false ));
        }
    }

    /**
     * Called when the notification is no longer visible.
     */
    protected onInvisible(): void { }

    /**
     * Set the icon for the notification.
     */
    protected setIcon(): void {
        switch ( this.type ) {
            case NotificationType.Warning:
                this.icon = 'warning';
                break;
            case NotificationType.Error:
                this.icon = 'error';
                break;
            case NotificationType.INFO:
                this.icon = 'info';
                break;
            case NotificationType.Success:
                this.icon = 'success';
                break;
            default:
                this.icon = 'check';
                break;
        }
    }
}
