import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DiagramNavigation } from 'apps/nucleus/src/system/diagram-navigation.svc';
import { CommandService } from 'libs/flux-core/src/command/command.svc';
import { StateService } from 'libs/flux-core/src/controller/state.svc';
import { ModelSubscriptionManager } from 'libs/flux-subscription/src/model/model-subscription-manager.svc';
import { NotificationListItem } from '../notification-list-item/notification-list-item.cmp';

/**
 * Notification Recent Update Item Component
 * extended from NotificationListItem just for the design changes
 *
 * @author  Banujan
 * @since   2022-09-05
 */
@Component({
    selector: 'notification-recent-update-item',
    templateUrl: './notification-recent-update-item.cmp.html',
    styleUrls: [ './notification-recent-update-item.scss' ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class NotificationRecentUpdateItem extends NotificationListItem {

    constructor(
        protected translateService: TranslateService,
        protected commandService: CommandService,
        protected stateService: StateService<any, any>,
        protected modelSubManager: ModelSubscriptionManager,
        protected diagramNavigation: DiagramNavigation,
    ) {
        super(
            translateService,
            commandService,
            stateService,
            modelSubManager,
            diagramNavigation,
        );
    }
}
