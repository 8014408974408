import { Component, ChangeDetectionStrategy, forwardRef, ElementRef } from '@angular/core';
import { DropDownItem } from '../abstract-dropdown/dropdown-item.cmp';

/**
 * SimpleDropdownItem
 * This component is the simple dropdown item which allows us to add
 * desired html items into the abstract dropdown menu.
 */

@Component({
    selector: 'simple-dropdown-item',
    template: `
    <div class="simple-dd-item-container" [class.selected]="selected" [class.active]="selected"
    [class.btn-free-size]="isFreeSize()" [class.btn-list-item]="isListItemStyles()"
    [class.disabled]="item?.disabled">
        <ng-content></ng-content>
    </div>`,
    providers: [{
            provide: DropDownItem,
            useExisting: forwardRef(
            /* istanbul ignore next */ () => SimpleDropdownItem /* istanbul ignore next */ ),
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SimpleDropdownItem extends DropDownItem<ISimpleDropdownItem> {
    constructor( el: ElementRef ) {
        super( el );
    }

    public isFreeSize(): boolean {
        return !this.item?.isSeparator && !this.item?.isHidden;
    }

    public isListItemStyles(): boolean {
        return !this.item?.isSeparator;
    }
}

export interface ISimpleDropdownItem {
    id: string;
    label: string;
    selected: boolean;
    isSeparator?: boolean;
    isHidden?: boolean;
    disabled?: boolean;
}
