import { Injectable } from '@angular/core';
import { AbstractCommand, AppConfig, Command, ImageLoader, Random } from 'flux-core';
import * as md5 from 'md5';
import { forkJoin, Observable, EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ImportedFile } from '../../../framework/file/imported-file';
import { ResourceStatus } from 'flux-definition';

/**
 * This command can be used to import an external file into
 * nucleus.
 * It reads data from one or more {@link ImportedFile} objects
 * and formats them in such way that another command or component
 * may use them. The way data is read and formatted will differ
 * based on the file type being imported.
 * Input data:
 *  {
 *      files: Array<ImportedFile>
 *  }
 * Result data:
 *  {
 *      files: Array<{
 *          name: string,
 *          type: string,
 *          extension: string,
 *          data: any, //file data read from ImportedFile
 *      }>
 *  }
 *
 * @author  Ramishka
 * @since   2019-02-20
 */
@Injectable()
@Command()
export class ImportFile extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: {
        files: Array<ImportedFile>,
    };

    /**
     * Image Types used here to create extension from it.
     */
    private fileTypes = {
        'image/png': 'png', 'image/jpeg': 'jpg', 'image/jpg': 'jpg',
        'image/gif': 'gif', 'image/bmp': 'bmp', 'image/x-icon': 'ico',
        'image/svg+xml': 'svg',
    };


    constructor( protected resourceLoader: ImageLoader ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Prepare command data
     */
    public prepareData(): any {
        if ( !this.data || !this.data.files || this.data.files.length < 1 ) {
            return;
        }
    }

    /**
     * Read data from files and store in resultData
     */
    public execute(): Observable<any> {
        const files = this.data.files;
        const observables = [];
        this.resultData = { files: []};
        files.forEach( file => {
            observables.push( this.addFile( file ));
        });
        if ( observables.length > 0 ) {
            return forkJoin( ...observables );
        }
        return EMPTY;
    }

    /**
     * Reads data from an image file type and stores in
     * resultData.
     * @param file - importedFile instance
     */
    protected addFile( file: ImportedFile ) {
        return this.readFile( file ).pipe(
            map( f => { // base64
                const fileData = {
                    id: Random.attachmentId(),
                    name: file.name,
                    type: file.type,
                    extension: file.extension || this.fileTypes[ file.type ],
                    data: f,
                    hash: md5( f ),
                };
                this.resultData.files.push( fileData );
                return fileData;
            }),
            switchMap( fileData =>
                this.resourceLoader.store( this.getUrl( fileData.hash ),
                fileData.data, ResourceStatus.PendingUpload ),
            ),
        );
    }

    /**
     * Reads and extracts the image data as a base64 string.
     * @param file - The ImportedFile instance
     * @return - image data as base64
     */
    protected readFile( file: ImportedFile ): Observable<string> {
        return file.getAsDataURL();
    }

    /**
     * Retrieves the url of the image (applicable for image imports only)
     */
    private getUrl( hash: string ): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( ImportFile, 'name', {
    value: 'ImportFile',
});
