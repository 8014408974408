import { Injectable } from '@angular/core';
import { CommandService, StateService, Tracker } from 'flux-core';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { delay, distinctUntilChanged, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { UserLocator } from 'flux-user';
import { CollabModel } from 'flux-diagram';
import { BehaviorSubject, Observable, combineLatest, interval, merge, of } from 'rxjs';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { DiagramCommandEvent } from '../diagram/command/diagram-command-event';
import { CollabLocator } from '../../base/diagram/locator/collab-locator';
import { isEqual as _isEqual } from 'lodash';

/**
 * Shape voting object which we keep with diagram
 */
export interface IShapeVoting {
    id: string;
    shapeIds: Array<string>;
    maxVotes: number;
    anonymous: boolean;
    oneVotePerItem: boolean;
    isResultSeen: boolean;
    startTime: number;
    endTime: number;
    createdBy: string;
    users:  {
        userId: string,
        votedShapes: {
            [id: string]: number;
        };
        votesCast: number,
        model: CollabModel,
    }[];
}

/**
 * shape voting state
 */
export interface IShapeVotingState {
    id: string;
    active: boolean;
    acknowledged: boolean;
    collapsed: boolean;
    progress: 'settings' | 'started' | 'active' | 'results' | 'recent' | null;
    endSession: false;
    secondsLeft: number;
    endTime?: number;
    shapeIds: string[];
    votedShapes: {
        [id: string]: number;
    };
}

/**
 * Shape voting result object
 */
export interface IShapeVotingResult {
    shapeId: string;
    label: string;
    votes: number;
    users?: CollabModel[];
    shapeDef?: any;
}

/**
 * This service keep the voting related data and logic
 *
 * @author  Sajeeva
 * @since   2024-01-24
 */
@Injectable()
export class ShapeVotingService {

    private shapeVoting: BehaviorSubject<IShapeVoting> = new BehaviorSubject<IShapeVoting>({} as IShapeVoting );

    private recentShapeVoting: BehaviorSubject<IShapeVoting> = new BehaviorSubject<IShapeVoting>({} as IShapeVoting );

    private currentUserId: string;

    private currentVotingId: string;

    private isUserOwnerOrModerater: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

    private subs = [];

    constructor(
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected userLocator: UserLocator,
        protected commandService: CommandService,
        protected collabLocator: CollabLocator,
    ) {}

    /**
     * All the voting related subscription start from this method
     */
    public initialize() {

        this.state.changes( 'CurrentDiagram' ).pipe(
            distinctUntilChanged(),
            map( _ => {
                this.destroyTimerSubscription();
                this.shapeVoting.next({} as IShapeVoting );
                this.recentShapeVoting.next({} as IShapeVoting );
                this.state.set( 'ShapeVoting', {} as IShapeVotingState );
                this.currentVotingId = null;
            }),
            switchMap( _ => this.getActiveVoting().pipe(
                first(),
                tap( shapeVoting => {
                    if ( !shapeVoting ) {
                        return;
                    }
                    this.setShapeVotingState( shapeVoting );
                    this.setOrChangeShapeVotingTimers( shapeVoting );
                    this.addCurrentUserToVoting( shapeVoting );
                }),
            ),
        )).subscribe();

        this.currentUserId = this.state.get( 'CurrentUser' );

        this.checkUserCurrentDiagramRole();
    }


    public startVotingSubscription() {
        this.stopVotingSubscription();
        this.subs.push(
            this.getVotingSub().pipe(
                filter( shapeVoting => !!shapeVoting ),
                tap( shapeVoting => {
                    this.setOrChangeShapeVotingTimers( shapeVoting );
                    this.setShapeVotingState( shapeVoting );
                    this.addCurrentUserToVoting( shapeVoting );
                }),
            ).subscribe(),
        );
    }

    public stopVotingSubscription() {
        this.destroyTimerSubscription();
        this.shapeVoting.next({} as IShapeVoting );
        this.state.set( 'ShapeVoting', {} as IShapeVotingState );
        this.currentVotingId = null;
    }


    public getVotingSub(): Observable<IShapeVoting> {
        return this.ll.forCurrentObserver( false ).pipe(
            switchMap( l => l.getDiagramChanges()),
            filter(({ modifier }) => modifier.$set && Object.keys( modifier.$set )
                .map( k => k.split( '.' )[0])
                .includes( 'shapeVotings' )),
            map( change => this.getVoting( change )),
            filter( voting => !!voting ),
        );
    }

    public getActiveVoting(): Observable<IShapeVoting> {
        return this.ll.forCurrentObserver( false ).pipe(
            switchMap( l => l.getDiagramOnce()),
            first(),
            map(( d: DiagramModel ) => {
                const currentTime = ( new Date()).getTime();
                const votings = d.shapeVotings || {};
                if ( !Object.values( votings ).length ) {
                    return;
                }
                const currentVoting = Object.values( votings ).find( voting =>
                    voting.startTime < currentTime && voting.endTime > currentTime,
                );
                if ( currentVoting ) {
                    return currentVoting;
                } else {
                    const recentVoting = Object.values( votings ).reduce(( prev, curr ) =>
                        Math.abs( curr.endTime - currentTime ) < Math.abs( prev.endTime - currentTime ) ? curr : prev );

                    if ( recentVoting ) {
                        this.recentShapeVoting.next( recentVoting );
                    }
                }
                return Object.values( votings ).find( voting => !voting.isResultSeen );
            },
        ));
    }

    public endVoting( votingId: string = this.shapeVoting.value.id ) {
        const data = {
            voteId: votingId,
            endTime: ( new Date()).getTime(),
        };
        this.applyVotingChanges( data );
        this.updateVotingEndTime( data.endTime );
    }

    public isRecentVotingAvilable() {
        return !!this.recentShapeVoting.value.id;
    }

    public addOneMoreMinToEndTime( votingId: string = this.shapeVoting.value.id ) {
        const data = {
            voteId: votingId,
            endTime: this.shapeVoting.value.endTime + 60000,
        };
        this.applyVotingChanges( data );
        this.updateVotingEndTime( data.endTime );
    }

    public updateResultSeen( votingId: string = this.shapeVoting.value.id ) {
        const data = {
            voteId: votingId,
            isResultSeen: true,
        };
        this.state.set( 'ShapeVoting', {} as IShapeVotingState );
        this.applyVotingChanges( data );
        this.recentShapeVoting.next( this.shapeVoting.value );
        this.currentVotingId = null;
    }

    public updateVotingEndTime( newEndTime: number ) {
        if ( this.shapeVoting.value.id ) {
            const newShapeVoting = { ...this.shapeVoting.value, endTime: newEndTime } as IShapeVoting;
            this.setOrChangeShapeVotingTimers( newShapeVoting, true );
            this.state.set( 'ShapeVoting', { ...this.state.get( 'ShapeVoting' ), endTime: newEndTime });
        }
    }

    public getSessionCreatedUser( recent: boolean = false ) {
        if ( recent ) {
            const userId = this.recentShapeVoting.value.createdBy;
            return this.recentShapeVoting.value.users.find( user => user.userId === userId ).model;
        }
        const createdUserId = this.shapeVoting.value.createdBy;
        return this.shapeVoting.value.users.find( user => user.userId === createdUserId ).model;
    }

    public getSessionStartedTime( recent: boolean = false ) {
        if ( recent ) {
            return this.recentShapeVoting.value.startTime;
        }
        return this.shapeVoting.value.startTime;
    }

    public getCurrentUserVotes() {
        const state = this.state.get( 'ShapeVoting' ) as IShapeVotingState;
        return state.votedShapes;
    }

    public incrementShapeVote( shapeId: string ) {

        if ( this.currentUserRemainingVotes() < 1 ) {
            return;
        }

        const userShapeVotes = this.getCurrentUserVotes();

        const isOneVotePerItem = this.shapeVoting.value.oneVotePerItem;

        let votesCast = Object.values( userShapeVotes )
            .reduce(( res, next ) =>
                res + next,
            0 );

        if ( isOneVotePerItem && userShapeVotes[shapeId] === 1 ) {
            --votesCast;
        }

        userShapeVotes[shapeId] = isOneVotePerItem ? 1 : ( userShapeVotes[shapeId] || 0 ) + 1;

        const data = {
            voteId: this.shapeVoting.value.id,
            shapeId,
            votes: userShapeVotes[shapeId],
            votesCast: ( votesCast + 1 ),
        };
        this.updateCurrentVotedShapeState( shapeId, userShapeVotes[shapeId]);
        this.applyVotingChanges( data );
    }

    public decrementShapeVote( shapeId: string ) {

        const userVotes = this.getCurrentUserVotes();
        if ( !userVotes[shapeId]) {
            return;
        }

        const votesCast = this.shapeVoting.value.users.find( u => u.userId === this.currentUserId ).votesCast || 0;
        if ( votesCast === 0 ) {
            return;
        }

        const votedForShape = userVotes[shapeId] === 0 ? 0 : ( userVotes[shapeId] - 1 );

        const data  = {
            voteId: this.shapeVoting.value.id,
            shapeId,
            votes: votedForShape,
            votesCast: ( votesCast - 1 ),
        };
        this.updateCurrentVotedShapeState( shapeId, votedForShape );
        this.applyVotingChanges( data );
    }

    public currentUserRemainingVotes() {

        if ( !this.shapeVoting.value.users || !this.shapeVoting.value.users.find(
            user => user.userId === this.currentUserId )) {
            return 0;
        }

        const votesCast = Object.values( this.shapeVoting.value.users.find(
            user => user.userId === this.currentUserId ).votedShapes )
            .reduce(( res, next ) =>
                res + next,
            0 );
        const votesLeft = this.shapeVoting.value.maxVotes - votesCast;

        return votesLeft;
    }

    public getVotingUsers() {
        return Object.values( this.shapeVoting.value.users ).filter( user => user.model );
    }

    public getVotedUsersCount() {
        if ( !this.shapeVoting.value.id ) {
            return 0;
        }
        const { maxVotes } = this.shapeVoting.value;
        return this.getVotingUsers().reduce(( res, next ) => {
            res += Object.values( next.votedShapes || {}).reduce(( totalUserVotes: number, shapeVotes: number ) => {
                totalUserVotes += shapeVotes;
                return totalUserVotes;
            }, 0 ) === maxVotes ? 1 : 0;
            return res;
        }, 0 );
    }

    public getVotedUsers() {
        this.addCurrentUserToVoting( this.shapeVoting.value );
        if ( !this.shapeVoting.value.id ) {
            return [];
        }
        const { maxVotes } = this.shapeVoting.value;
        return this.getVotingUsers().reduce(( res, next ) => {
            const totalVotes = Object.values( next.votedShapes || {}).reduce(
            ( totalUserVotes: number, shapeVotes: number ) => {
                totalUserVotes += shapeVotes;
                return totalUserVotes;
            }, 0 );

            const user = Object.assign( new CollabModel( next.userId ), next.model );
            const voted = totalVotes === maxVotes;
            const isCurrentUser = user.id === this.currentUserId;
            res.push({ user, voted, isCurrentUser  });
            return res;
        }, []);
    }

    public isAnonymous( recent: boolean = false ): boolean {
        if ( recent ) {
            return this.recentShapeVoting.value.anonymous;
        }
        return this.shapeVoting.value.anonymous;
    }

    public getVotingResult( recent: boolean = false ): IShapeVotingResult[] {
        const shapeVoting = recent ? this.recentShapeVoting.value : this.shapeVoting.value;

        const result = {};

        shapeVoting.shapeIds.forEach( shapeId => {
            const obj = { shapeId: shapeId, votes: 0, users: []} as IShapeVotingResult;
            result[shapeId] = obj;
        });

        shapeVoting.users.forEach( user => {
            for ( const shapeId in user.votedShapes ) {
                if ( user.votedShapes[shapeId] && user.votedShapes[shapeId] > 0 ) {
                    result[shapeId].votes += user.votedShapes[shapeId];
                    const colab = Object.assign( new CollabModel( user.userId ), user.model );
                    result[shapeId].users.push( colab );
                }
            }
        });

        return Object.values( result );
    }

    protected updateClock( secondsLeft: number ) {
        const progress = this.state.get( 'ShapeVoting' ).progress;
        if ( progress === 'active' || progress === 'started' ) {
            this.state.set( 'ShapeVoting', { ...this.state.get( 'ShapeVoting' ), secondsLeft });
        }
    }

    protected setOrChangeShapeVotingTimers( shapeVoting: IShapeVoting, endTimeChange: boolean = false ) {
        if ( !endTimeChange ) {
            this.shapeVoting.next( shapeVoting );
        }

        if ( shapeVoting.id && (( shapeVoting.id !== this.currentVotingId ) || endTimeChange )) {

            this.destroyTimerSubscription();
            this.currentVotingId = shapeVoting.id;
            const timeLeft = this.getTimeLeft( shapeVoting.endTime );
            this.setEndTimer( timeLeft );
            this.setClock( timeLeft, shapeVoting.endTime );
        }
    }

    protected destroyTimerSubscription() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    protected setShapeVotingState( shapeVoting: IShapeVoting ) {
        const existingShapeVoting = this.state.get( 'ShapeVoting' ) as IShapeVoting;
        const currentTime = ( new Date()).getTime();
        const currentVoting = shapeVoting.startTime < currentTime && shapeVoting.endTime > currentTime;
        if ( !existingShapeVoting.endTime && shapeVoting.id && currentVoting ) {

            const secondsLeft = Math.floor( this.getTimeLeft( shapeVoting.endTime ) / 1000 );
            const userVotingObj = shapeVoting.users.find( user => user.userId === this.currentUserId );
            const votedShapes = userVotingObj ? ( userVotingObj.votedShapes || {}) : {};

            this.state.set( 'ShapeVoting', {
                anonymous: shapeVoting.anonymous,
                id: shapeVoting.id,
                active: true,
                acknowledged: true,
                collapsed: false,
                progress: this.shapeVoting.value.createdBy === this.currentUserId ? 'active' : 'started',
                secondsLeft,
                shapeIds: shapeVoting.shapeIds,
                votedShapes,
                endTime: shapeVoting.endTime,
            });
        }
    }

    protected updateCurrentVotedShapeState( votedShapeId: string, numberOfVote: number ) {
        Tracker.track( 'canvas.CanvasItem.VoteCombination.click' );
        const votedShapes = this.state.get( 'ShapeVoting' ).votedShapes || {};
        votedShapes[votedShapeId] = numberOfVote;
        this.state.set( 'ShapeVoting', { ...this.state.get( 'ShapeVoting' ), votedShapes });
    }

    protected getVoting( change ): IShapeVoting {
        const [ , shapeVotingObject ] = Object.entries( change.modifier.$set ).find(([ k ]) =>
            k.split( '.' )[0]  === 'shapeVotings',
        );
        if (( shapeVotingObject as any ).id ) {
            return shapeVotingObject as IShapeVoting;
        }
        if ((( shapeVotingObject as any ).length > 0 ) && shapeVotingObject[0].userId ) {
            const newShapeVoting = { ...this.shapeVoting.value, users: shapeVotingObject } as IShapeVoting;
            this.shapeVoting.next( newShapeVoting );
            return newShapeVoting;
        }
        const result = Object.values( shapeVotingObject || {})[0] as IShapeVoting;
        return result;
    }

    protected addCurrentUserToVoting( shapeVoting: IShapeVoting ) {
        const userVotingObj = shapeVoting.users.find( user => user.userId === this.currentUserId );
        if ( !userVotingObj ) {
            const data = {
                voteId: shapeVoting.id,
            };
            this.applyVotingChanges( data );
        }
    }

    protected applyVotingChanges( data: any ) {
        this.commandService.dispatch( DiagramCommandEvent.updateShapeVoting, { ...data }).pipe(
            take( 1 ),
        ).subscribe();
    }

    protected getTimeLeft( endTime: number ) {
        return endTime - ( new Date()).getTime();
    }

    protected setEndTimer( timeLeft: number ) {
        const sub = of( null ).pipe(
            delay( timeLeft ),
            tap(() => {
                if ( this.isUserOwnerOrModerater.value ) {
                    this.recentShapeVoting.next( this.shapeVoting.value );
                    this.state.set( 'ShapeVoting', { progress: 'results' });
                } else {
                    this.state.set( 'ShapeVoting', {} as IShapeVotingState );
                    this.currentVotingId = null;
                }
                this.destroyTimerSubscription();
            }),
        ).subscribe();

        this.subs.push( sub );
    }

    protected setClock( timeLeft: number, endTime: number ) {
        const secondsLeft = Number( Math.ceil(( timeLeft / 1000 )));
        let secondsPassed = 0;
        const sub = interval( 1000 ).pipe(
            take( secondsLeft ),
            map(() => {
                secondsPassed++;
                return secondsLeft - secondsPassed;
            }),
            tap( secondsLefts => {
                const leftTimeSecond = Math.ceil((( endTime - ( new Date()).getTime()) / 1000 ));
                this.updateClock( leftTimeSecond );
            }),
        ).subscribe();

        this.subs.push( sub );
    }

    protected checkUserCurrentDiagramRole() {
        return combineLatest([
            this.state.changes( 'CurrentUser' ),
            this.state.changes( 'CurrentDiagram' ),
        ]).pipe(
            filter(([ userId, diagramId ]) => !!userId && !!diagramId ),
            switchMap(([ userId, diagramId ]) => merge(
                this.collabLocator.getCollabs( diagramId ).pipe(
                    distinctUntilChanged( _isEqual ),
                    tap( collabs => {
                        const ownerOrModerater = collabs.find( collab => collab.role < 3
                            && collab.id === userId );
                        if ( ownerOrModerater ) {
                            this.isUserOwnerOrModerater.next( true );
                        } else {
                            this.isUserOwnerOrModerater.next( false );
                        }

                    }),
                ),
            )),
        ).subscribe();
    }
}
