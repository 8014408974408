import { AbstractConnector } from './connector-abstract';

/**
 * This class contains the capability to draw a triple line
 * connector using the coordinate data stored into the model,
 * using the provided graphics object.
 *
 * To draw a triple line connector, the model should have
 * a path with each point the connector should connect to.
 *
 * @author  Nkweti Awa
 * @since   2023-30-08
 */
export class ConnectorTriple extends AbstractConnector {

    /**
     * Draws the wavy connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }

        const firstPoint =  points[ 0 ];
        const lastPoint = points[ points.length - 1 ];

        this.moveTo({ x: firstPoint.x, y: firstPoint.y - 10 });
        this.lineTo({ x: lastPoint.x, y: lastPoint.y - 10 });

        this.moveTo({ x: firstPoint.x, y: firstPoint.y });
        this.lineTo({ x: lastPoint.x, y: lastPoint.y });

        this.moveTo({ x: firstPoint.x, y: firstPoint.y + 10 });
        this.lineTo({ x: lastPoint.x, y: lastPoint.y + 10 });

    }

}

Object.defineProperty( ConnectorTriple, 'name', {
    value: 'ConnectorTriple',
});
