/**
 * AbstractShapeLibrary
 * Abstract of the library definition.
 * This holds the information about the set of shape definition.
 *
 * @author  gobiga
 * @since   2017-11-14
 */

export abstract class AbstractShapeLibrary {

    /**
     * Auto generated md5 hash of a specific library definition
     */
    public hash: string;

    /**
     * Type of the libarary.
     */
    public type: LibraryType;

    /**
     * Displayable name for the library.
     */
    public name: string;

    /**
     * A description of the library.
     */
    public description: string;

    /**
     * Tags for searching or categorizing
     */
    public tags: Array<string>;

    /**
     * This would be creately for all static libraries. The user id for custom ones.
     */
    public ownerId: string;

    /**
     * Preview image path
     */
    public previewImageUrl: string;

    /**
     * Id's of all shape defs attached to this lib.
     * Initially this holds the set of defId's and then this
     * will be overridden by the setof {@link IDefinitionSummary}.
     */
    public defs: Array<any>;

    /**
     * This is to specify whether library is shown in minimal view
     * and user can expand if want. If value is false all shapes of
     * the library will be shown in panel without expand and minimize
     * feature
     */
    public showMinimalView: boolean = true;

    /**
     * This is to specify top shapes to switch to in this library
     * ie most used shapes in the library with switch preferences
     */
    public switchDefaults: {
        defaultPreferences: any,
        shapes: {
            id: string,
            defId: string,
            data: any,
            defaultPreferences: any,
        },
    };

    /**
     * Total shapes count in the library (Excluding connectors)
     */
    public shapesCount: number;

    /**
     * Unique identifier for the library
     * This can be libId for static and  projId for project as a libraray.
     */
    protected id: string ;

}

export const CUSTOM_SHAPE_LIB_ID_PREFIX = 'custom-database-';
export const QUICK_TOOL_SHAPE_LIB_ID_PREFIX = 'quick-tool-';

/**
 * The type of the library
 */
export enum LibraryType {
    Static = 'static',
    Custom = 'custom',
    Special = 'special',
    Project = 'project',
    EData = 'edata',
    Icon = 'icon',
}
