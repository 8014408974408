import { CollabModel } from 'flux-diagram';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * This component displays a list of users
 *
 * Users are ordered as current user, online, offline and pending. Online users
 * and the current users are given a class "active" and offline and pending users
 * are given a class "inactive".
 *
 * This component will update the user's state and change the user accordingly.
 *
 * @author mehdhi
 * @since 2021-08-29
 */
export class AbstractUserStrip {

    /**
     * The observable that emits the collaborators for the diagram.
     * We are storing the observable that is prepared and using that to make
     * sure the anuglar rendering does not constantly create the observable
     */
    public collabs: Observable<IStripCollabViewData[]>;

    /**
     * This indicates the count of users to show
     * 0 indicates unlimited
     */
    public limitCount: number = 0;

    /**
     * This indicates whether to show only the active
     * users or inactive users.
     */
    public showActiveOnly: boolean;

    /**
     * Subject that emits the collaborator id when a collab image is hovered on.
     * This will emit null on mouseout.
     */
    public collabHover: Subject<string>;

    constructor() {
        this.collabHover = new BehaviorSubject( null );
    }

    /**
     * The tracking id for the angular rendering of each collab
     */
    public getCollabTrackingId( index: number, collabView: IStripCollabViewData ) {
        return collabView.collab ? collabView.collab.id : 'collabCount';
    }

    /**
     * Converts the collab models into view which have position
     * and order information needed for rendering by the user strip component
     *
     * All whats done here are things needed to prepare for the showing and interactions
     * of the user strip components. This converts the collabs array into a array of objects
     * that represent the info needed for the view, in reverse order. This also considers the
     * mouse hover on item.
     */
    protected getCollabViews( collabs: CollabModel[], hoverId: string ): IStripCollabViewData[] {
        const result: IStripCollabViewData[] = [];
        let position = 0;
        let index = 0;
        let counter;
        let sortedCollabs;

        // NOTE: If the user strip wants to show only either active or inactive
        // users only, then we can use this showActiveOnly property. If we required
        // to show both together, then this will be undefined by default.
        if ( this.showActiveOnly !== undefined ) {
            sortedCollabs = collabs.filter( c => c.online === this.showActiveOnly );
        } else {
            sortedCollabs = collabs.sort(( a, b ) => ( a.online === b.online ) ? 0 : a.online ? -1 : 1 );
        }

        if ( this.limitCount > 0 && this.limitCount < sortedCollabs.length ) {
            const removed: CollabModel[] = sortedCollabs.splice( this.limitCount, sortedCollabs.length );
            const remainingCount = removed.length > 100 ? 99 : removed.length;
            counter = {
                index: this.limitCount + 1,
                position: -10,
                remainingCount: remainingCount,
                onlineStatus: this.showActiveOnly,
                truncated: removed,
            };
        }

        sortedCollabs.forEach(( c: any, i: number ) => {
            position = i === 0 ? 0 : counter ? -10 : -2;
            index += 1;

            let email: string;
            if ( c.plan?.type !== 'DEMO' && c.email?.indexOf( '@temp.creately' ) === -1 ) {
                email = c.email;
            }
            result.push({ position, index, collab: c, email });
        });
        // NOTE: Adding the remaining counter at last.
        if ( counter ) {
            result.push( counter );
        }
        return result;
    }
}

/**
 * Collaborator view data which has position and order information
 * needed for rendering by the user strip component
 */
export interface IStripCollabViewData {
    position: number;
    index: number;
    collab?: CollabModel;
    remainingCount?: number;
    onlineStatus?: boolean;
    currentUser?: boolean;
    spotlightState?: boolean;
    followState?: boolean;
    realtimeUserColor?: string;
    spotlightRequestingState?: boolean;
    email?: string; // If collab have displayable email (non DEMO)
}
