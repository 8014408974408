import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { EDataChangeService } from './../edata-change.svc';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

/**
 * UpdateEdataDefs
 * This commad is to add/update/remove data items from shapes
 * Only optional data items can be removed.
 */
@Injectable()
@Command()
export class UpdateEdataDefs extends AbstractEDataChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        [entityId: string]: {
            [path: string]: any,
        },
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        this.modelChangeUtils.updateEdataDefs(
            this.changeModel,
            this.data,
        );
    }
}

Object.defineProperty( UpdateEdataDefs, 'name', {
    value: 'UpdateEdataDefs',
});
