import { NgModule, ModuleWithProviders } from '@angular/core';
import { Authentication } from './user/authentication.svc';
import { AbstractAuthentication } from 'flux-connection';
import { UserLocator } from './user/user-locator.svc';
import { AuthResponsibility } from './user/responsibility/auth-responsibility.svc';
import { UserResponsibility } from './user/responsibility/user-responsibility.svc';
import { UserSubscriptionResponsibility } from './user/responsibility/user-subscription-responsibility.svc';
import { CommandMapper, FluxCoreDirectives, PostMessageAPI } from 'flux-core';
import { StartUserSubscription } from './user/command/start-user-subscription.cmd';
import { Authenticate } from './user/command/authenticate.cmd';
import { UserCommandEvent } from './user/command/user-command-event';
import { PlanPermManager } from './permission/plan-perm-manager';
import { DemoMessage } from './ui/demo-message/demo-message.cmp';
import { CommonModule } from '@angular/common';
import { FluxCoreUI } from 'flux-core/src/ui';
import { PermissionErrorHandler } from './permission/error/permission-error-handler';
import { UpgradeDialog } from './permission/view/upgrade-dialog.cmp';
import { GeneralPlanPermHandler } from './permission/handler/general-plan-perm-handler';
import { UpdateUserPermission } from './user/command/update-user-permission.cmd';
import { OnboardingWindow } from './ui/auth/onboarding-window.cmp';
import { Setup2FAWindow } from './ui/auth/setup-2fa-window.cmp';
import { PaymentFailedMessage } from './ui/payment-failed-message/payment-failed-message.cmp';
import { DesktopAuthWindow } from './ui/auth/desktop-auth-window.cmp';
import { TeamPortalWindow } from './ui/team-portal-window/team-portal-window.cmp';
import { TeamUserConfirmationWindow } from './ui/team-user-confirmation-window/team-user-confirmation-window.cmp';
import { LitePlanWindow } from './ui/lite-plan-window/lite-plan-window.cmp';
import { EmailConfirmationResponsibility } from './user/responsibility/email-confirmation-responsibility.svc';
import { FetchUserOnboardingStatus } from './user/command/fetch-user-onboarding-status.cmd';
import { UpdateUserOnboardingStatus } from './user/command/update-user-onboarding-status.cmd';
import { PlanWindow } from './ui/plan-window/plan-window.cmp';
import { FileExportedWindow } from './ui/file-exported-window/file-exported-window.cmp';
import { UpgradeDialogWindow } from './ui/upgrade-dialog-window/upgrade-dialog-window.cmp';
import { EmailVerificationWindow } from './ui/email-verification-window/email-verification-window.cmp';
import { AccountPauseMessage } from './ui/account-pause-message/account-pause-message.cmp';
import { WhatsNewWindow } from './ui/whats-new-window/whats-new-window.cmp';
import { TeamSettingManager } from './team/team-setting-manager';
import { Setup2FA } from './user/command/setup-2fa.cmd';
import { TwoFactorAuthResponsibility } from './user/responsibility/2fa-responsibility.svc';
import { CrispChat } from './ui/crisp/crisp';

@NgModule({
    declarations: [
        DemoMessage, UpgradeDialog, OnboardingWindow, PaymentFailedMessage,
        DesktopAuthWindow, TeamPortalWindow, TeamUserConfirmationWindow,
        LitePlanWindow, PlanWindow, FileExportedWindow, UpgradeDialogWindow,
        EmailVerificationWindow, AccountPauseMessage, WhatsNewWindow,
        Setup2FAWindow,
    ],
    exports: [
        DemoMessage, PaymentFailedMessage, TeamPortalWindow,
        TeamUserConfirmationWindow, LitePlanWindow, PlanWindow,
        AccountPauseMessage, WhatsNewWindow,
    ],
    imports: [ CommonModule, FluxCoreUI, FluxCoreDirectives ],
    entryComponents: [ UpgradeDialog, OnboardingWindow, DesktopAuthWindow, TeamPortalWindow,
        TeamUserConfirmationWindow, LitePlanWindow, PlanWindow, Setup2FAWindow,
    ],
})
export class FluxUser {
    public static forRoot(): ModuleWithProviders<FluxUser> {
        return {
            ngModule: FluxUser,
            providers: [
                Authentication,
                {
                    provide: AbstractAuthentication,
                    useExisting: Authentication,
                },
                UserLocator,
                AuthResponsibility,
                { provide: 'AuthResponsibility', useExisting: AuthResponsibility },
                UserResponsibility,
                { provide: 'UserResponsibility', useExisting: UserResponsibility },
                UserSubscriptionResponsibility,
                { provide: 'UserSubscriptionResponsibility', useExisting: UserSubscriptionResponsibility },
                EmailConfirmationResponsibility,
                { provide: 'EmailConfirmationResponsibility', useExisting: EmailConfirmationResponsibility },
                TwoFactorAuthResponsibility,
                { provide: 'TwoFactorAuthResponsibility', useExisting: TwoFactorAuthResponsibility },
                PlanPermManager,
                PermissionErrorHandler,
                GeneralPlanPermHandler,
                PostMessageAPI,
                TeamSettingManager,
                CrispChat,
            ],
        };
    }
    constructor( protected mapper: CommandMapper, protected auth: Authentication ) {
        UserCommandEvent.register( mapper );
        this.registerUnmappedCommands();
    }

    /**
     * Register commands which does not have an event associated with them. If the command
     * is mapped to an event, they will be registered automatically. Commands which needs
     * to be registered are exceptions.
     */
    protected registerUnmappedCommands() {
        this.mapper.registerCommand( StartUserSubscription as any );
        this.mapper.registerCommand( Authenticate as any );
        this.mapper.registerCommand( Setup2FA as any );
        this.mapper.registerCommand( UpdateUserPermission as any );
        this.mapper.registerCommand( FetchUserOnboardingStatus as any );
        this.mapper.registerCommand( UpdateUserOnboardingStatus as any );
    }
}
