<div class="fullscreen-container ne-grey fx-cover fx-center-all fx-center">

    <div class="fullscreen-message">
        <img src="./assets/images/logo-on-dark-200X80.svg" alt="Creately" class="logo">
        <h1 class="title" translate>MESSAGES.OFFLINE_ERROR_HEADING</h1>
        <p class="body fx-margin-top-15" translate>MESSAGES.OFFLINE_ERROR</p>
    </div>
    
    <div class="fullscreen-footer">
        <p class="body" [innerHTML]="'MESSAGES.OFFLINE_ERROR_FOOTER' | translate"></p>
    </div>
</div>