import { IPosition2D, IRectangle, ITransform } from 'flux-definition';
import { Injectable } from '@angular/core';
import { StateService, Position, Point } from 'flux-core';

@Injectable()
export class StagePosition {

    /**
     * Returns the singleton instance of StagePosition.
     */
    public static get instance(): StagePosition {
        return StagePosition._instance;
    }

    /**
     * Finds the viewport location of a point on a rectangle. This considers the zoom state of the viewport.
     * @param point Point relative to rectangle.
     * @param bounds Bounds of the rectangle.
     * @param viewPortShapeTransform Transformation of the rectangle in viewport.
     */
    public static onViewportRect( point: IPosition2D, bounds: IRectangle, viewPortShapeTransform: ITransform ): Point {
        const zoom: number = this.instance.state.get( 'DiagramZoomLevel' );
        const cPoint = {
            x: { type: point.x.type, value: point.x.value },
            y: { type: point.y.type, value: point.y.value },
        };
        if ( point.x.type === 'fixed-start' || point.x.type === 'fixed-end' ) {
            cPoint.x.value = cPoint.x.value * zoom;
        }
        if ( point.y.type === 'fixed-start' || point.y.type === 'fixed-end' ) {
            cPoint.y.value = cPoint.y.value * zoom;
        }
        return Position.onRect( cPoint, bounds, viewPortShapeTransform );
    }

    /**
     * Holds the singleton instance of StagePosition, enabling it to be used as a utility
     * class as well as a service. Should not be used until the app is initialized completely.
     */
    private static _instance = null;

    /**
     * Creates the singleton StagePosition instance.
     */
    constructor( protected state: StateService<any, any> ) {
        if ( StagePosition._instance ) {
            return StagePosition._instance;
        }
        StagePosition._instance = this;
    }
}
