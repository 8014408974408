/**
 * Holds helper functions for angle related calculations.
 */
export class Angle {
    /**
     * Returns a normalized value for angle.
     *
     *         +90
     *          ^ Y
     *          |
     *  180 <-- O --> 0
     *          |   X
     *          v
     *         -90
     *
     */
    public static normalize( deg: number ): number {
        const mod = deg % 360;
        if ( mod > 180 ) {
            return mod - 360;
        }
        if ( mod <= -180 ) {
            return mod + 360;
        }
        return mod;
    }

    /**
     * Returns the opposite angle value in degrees.
     */
    public static opposite( deg: number ): number {
        return Angle.normalize( deg + 180 );
    }
}
