import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractIndexChangeCommand } from './abstract-index-change.cmd';

/**
 * This command sends a given shape or shapes below of all existing
 * shapes in the diagram.
 * Shapes can either be passed in, or the command will operate on shapes
 * in the current selection
 *
 * @author  Ramishka
 * @since   2019-01-31
 */
@Injectable()
@Command()
export class SendToBack extends AbstractIndexChangeCommand {

    constructor( protected state: StateService<any, any>, protected ds: DiagramChangeService ) {
        super( state, ds ) /* istanbul ignore next */;
    }

    /**
     * Updates the zIndex of shapes so they are sent to
     * the absolute back of the diagram. If there are no shapes,
     * command is cancelled.
     */
    public prepareData() {
        const shapeIds = this.getShapeIds();
        const selectedShapes = this.changeModel.getShapesOrderedByIndex(  shapeIds );
        let minIndex = this.changeModel.minZIndex;
        selectedShapes.reverse().forEach( shape => {
            if (( shape as any ).containerId ) {
                const container: any = this.changeModel.shapes[( shape as any ).containerId];
                const relativeZIndex =  container.children[ shape.id ].relativeZIndex;
                for ( const key in container.children ) {
                    const c = container.children[ key ];
                    if ( c.relativeZIndex < relativeZIndex ) {
                        c.relativeZIndex++;
                        this.changeModel.shapes[ key ].zIndex++;
                    }
                }
                container.children[ shape.id ].relativeZIndex = 0;
                shape.zIndex = container.zIndex + 1;
            } else {
                minIndex--;
                this.changeModel.shapes[shape.id].zIndex = minIndex;
            }
        });
    }
}

Object.defineProperty( SendToBack, 'name', {
    value: 'SendToBack',
});
