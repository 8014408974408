import { IDropdownData } from './abstract-dropdown.cmp';
import { Subject ,  fromEvent, Observable, Subscription } from 'rxjs';
import { Input, ElementRef, OnDestroy, Output, Directive } from '@angular/core';
import { map } from 'rxjs/operators';

/**
 * DropDownItem Component
 * This component is an abstract dropdown item component and expected to
 * be extended to create more spesific dropdown items
 */
@Directive()
export class DropDownItem<T extends IDropdownData> implements OnDestroy {

    @Input()
    public item: T;

    /**
     * Observable that emits the mouseenter event on this dropdown element
     */
    public mousEentered: Observable<string>;

    /**
     * Observable that emits when the dropdown item selected
     * and it emits the id of the selected item
     */
    @Output()
    public changed: Subject<string>;

    /**
     * The list of subscriptions held by this class
     */
    protected subs: Subscription[];

    constructor( protected el: ElementRef ) {
        this.changed = new Subject();
        this.subs =  [];
        const element: HTMLElement = this.el.nativeElement as HTMLElement;
        this.subs.push( fromEvent( element, 'mouseup' ).subscribe(() => this.select()));
        this.mousEentered = fromEvent( element, 'mouseenter' ).pipe( map(() =>  this.item?.id ));
    }

    public get selected() {
        return this.item ? this.item.selected : false;
    }

    /**
     * Destroys and clears all the resources used by the
     * interaction handler
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Select this DropDownItem
     */
    public select() {
        if ( this.item &&  this.item.id && !this.item.disabled && !this.item.isSeparator ) {
            this.changed.next( this.item.id );
        }
    }
}
