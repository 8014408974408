import { DiagramModel } from './../../diagram/model/diagram.mdl';
import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command, IModifier } from 'flux-core';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

// import { Sakota } from '@creately/sakota';
/**
 * Updates an entities data from a shape using the dataMapping via IEntityDataMap
 */
@Injectable()
@Command()
export class UpdateEntityData extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entityId: string,
        shape: ShapeModel,
        diagram: DiagramModel,
        modifier: IModifier,
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        this.modelChangeUtils.updateEntityData(
            this.changeModel,
            this.data,
        );
    }
}

Object.defineProperty( UpdateEntityData, 'name', {
    value: 'UpdateEntityData',
});
