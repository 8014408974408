<div class="marketing-notification-container fx-pointer-events-auto fx-flex"  [ngStyle]="getBackgroundColor()" (click)="handleNotificationClick($event)">
    <div class="marketing-notification-content">
        <div class="marketing-notification-title-container fx-flex fx-align-center"
            [class.marketing-notification-has-heading]="!!heading">
            <span class="marketing-notification-heading body body--bold">{{ heading }}</span>
        </div>

        <div class="marketing-notification-description-container"
            *ngIf="heading && ( description || descriptionText )">
            <div class="marketing-notification-description-main" *ngIf="description">
                <div class="marketing-notification-description" [innerHTML]="description | safeHTML"></div>
                <span class="marketing-notification-description-sub-price" *ngIf="descriptionSubPrice"><s>{{ descriptionSubPrice }}</s></span>
            </div>
            <div *ngIf="descriptionSub1"
            class="marketing-notification-description-sub" [innerHTML]="descriptionSub1 | safeHTML"></div>
            <div *ngIf="descriptionSub2"
            class="marketing-notification-description-sub" [innerHTML]="descriptionSub2 | safeHTML"></div>
            <div *ngIf="descriptionSub3"
            class="marketing-notification-description-sub" [innerHTML]="descriptionSub3 | safeHTML"></div>
            <div *ngIf="descriptionSub4"
            class="marketing-notification-description-sub" [innerHTML]="descriptionSub4 | safeHTML"></div>
            <div *ngIf="descriptionText"
            class="marketing-notification-description-sub" [innerHTML]="descriptionText | async | safeHTML"></div>
        </div>

        <div class="marketing-notification-actions-container">
            <button
                class="btn-small btn-secondary marketing-notification-button marketing-notification-primary-button"
                *ngIf="buttonOneText && buttonOneAction"
                (click)="handleButtonOneClick($event)">
                    {{ buttonOneText }}
            </button>
            <a
                class="marketing-notification-button marketing-notification-second-button"
                *ngIf="buttonTwoText && buttonTwoAction"
                (click)="handleButtonTwoClick($event)">
                    {{ buttonTwoText }}
            </a>
        </div>
    </div>
</div>