import { DataStore } from 'flux-store';
import { Command, CommandInterfaces, StateService, CommandError } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Observable, from } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';
import { UserModel } from '../model/user.mdl';

/**
 * This is the command add/updates user's rating for the app.
 */
@Injectable()
@Command()
export class UpdateUserPreference extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            preferenceName: true,
            value: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IUserCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        @Inject( StateService ) protected state: StateService< any, any > ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Preserve previous data to revert in case of error.
     */
    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( UserModel );
        return modelStore.findOne({ id: this.state.get( 'CurrentUser' ) }).pipe(
            take( 1 ),
            map( user => ( user.preferences ? user.preferences[ this.data.preferenceName ] : undefined )),
            tap( rating => {
                this.previousData.preferenceName = this.data.preferenceName;
                this.previousData.value = rating;
            }),
        );
    }

    /**
     * execute
     * Add user rating.
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set[ 'preferences.' + this.data.preferenceName ] = this.data.value;
        return from( modelStore.update(
            { id: this.state.get( 'CurrentUser' ) },
            setter,
        ));
    }

    /**
     * onError
     * Revert app rating to previous state if the command failed in server/client.
     */
    public onError( err: CommandError ): any {
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set[ 'preferences.' + this.data.preferenceName ] = this.previousData.value;
        return from( modelStore.update(
            { id: this.state.get( 'CurrentUser' ) },
            setter,
        ));
    }
}

Object.defineProperty( UpdateUserPreference, 'name', {
    value: 'UpdateUserPreference',
});
