import * as formulajs from '@formulajs/formulajs';
import { pickBy } from 'lodash';
import { Jexl } from 'jexl';

export class ExpressionEvaluator {

    public static _instance = null;

    static get instance() {
        if ( !this._instance ) {
            this._instance = new Jexl();
            this._instance.addFunctions( pickBy( formulajs, v => typeof v === 'function' ));
            this._instance.addTransform( 'DATE', data => data ? new Date( data ) : null );
            this._instance.addTransform( 'DATE_ARRAY', data => {
                if ( !Array.isArray( data )) {
                    throw new Error( 'Input is not an array' );
                }
                return data.map( d => d ? new Date( d ) : null );
            });
            this._instance.addTransform( 'BOOL_ARRAY', data => {
                if ( !Array.isArray( data )) {
                    throw new Error( 'Input is not an array' );
                }
                return data.map( d => Boolean( d ));
            });
            this._instance.addTransform( 'TAGS', ( data: any, prop: 'name' | 'color' ) => {
                if ( !Array.isArray( data )) {
                    return [];
                }
                return data.map( d => d[prop]);
            });
            this._instance.addFunction( 'CONTAINS',
                ( haystack: string | any[], needle: any ) => haystack.includes( needle ));
        }

        return this._instance;
    }

    static evaluate( expression: string, context?: any ) {
        return this.instance.evalSync( expression, context );
    }
}
