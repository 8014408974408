import { of, throwError, Observable } from 'rxjs';
import { map, switchMap, take, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResourceLoader } from './resource-loader.svc';
import { ResourceStatus } from 'flux-definition';

/**
 * This is a loader service that can be used to load any type of resource.
 * It works with a cache. All resources loaded via the resource loader are
 * cached with given part of the URL as key. When a load request comes with
 * the URL and URL part, the local cache is checked and if the resource is
 * found in the cache with urlPart, it's fetched and returned. If the
 * resource is not found in the cache, it will be fetched via HTTP from given
 * url, added to cache with urlPart as key and then returned.
 *
 * ResourceLoader is implemented to cache the resource for actual URL, but
 * this is implemented to cache the resource with part of the URL. This helps
 * to cache resources with static part of the URL. For example, server returns
 * deferent URL everytime when a diagram subscription is requested. It is
 * hard to manage the cache with dynamic URL. But part of the URL is static
 * ( base URL ). We can use the static part of the URL to cache resource data.
 * This loader helps to handle caches for dynamic URL.
 *
 * @since 2018-09-19
 * @author Rasekaran
 */
@Injectable()
export class ResourceLoaderDynamic extends ResourceLoader {

    /**
     * This function loads a given resource by a URL.
     * If the resource is already cached with given urlPart, its fetched from
     * the cache. If it's not cached, it's fetched using an HTTP request from
     * given URL location.
     * @param url Actual URL where the resource data is available.
     * @param urlPart Part of the URL which is used to cache the resource.
     */
    public load( url: string, urlPart?: string ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        if ( !urlPart ) {
            return super.load( url );
        }
        return this.fetchCachedResource( urlPart ).pipe(
            switchMap( cachedResource => {
                if ( cachedResource ) {
                    return of( cachedResource.data );
                } else {
                    return this.fetchServerResourceAndCache( url, urlPart );
                }
            }), take( 1 ));
    }

    /**
     * Load a given resource url.
     * This function always try to load given url directly and if it can't be
     * loaded, will load it from cache. It also caches loaded resource data
     * with given urlPart as key.
     * @param url Actual URL where the resource data is available.
     * @param urlPart Part of the URL which is used to cache the resource.
     */
    public forceLoad( url: string, urlPart?: string ): Observable<any> {
        if ( !url ) {
            return throwError( new Error( `${url} is not a valid resource url.` ));
        }
        if ( !urlPart ) {
            return super.forceLoad( url );
        }
        return this.fetchServerResourceAndCache( url, urlPart ).pipe(
            catchError( error => this.fetchCachedResource( urlPart ).pipe(
                map( cachedResource => {
                    if ( cachedResource ) {
                        return cachedResource.data;
                    } else {
                        throw new Error( `Failed to load resource ${url} from server and cache` );
                    }
                })),
            ));
    }

    /**
     * Fetches the resource for given URL from server and cahces it with given
     * urlPart. Retrieved resource data is returned.
     * @param url URL where the resource is available.
     */
    protected fetchServerResourceAndCache( url: string, urlPart?: string ): Observable<any> {
        if ( !urlPart ) {
            urlPart = url;
        }
        return this.fetchServerResource( url ).pipe(
            tap( data => this.cache( urlPart, data, ResourceStatus.Uploaded )),
        );
    }
}
