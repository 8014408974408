import * as Graph from 'node-dijkstra';
import { Injectable } from '@angular/core';
import { roundN } from '@creately/round-n';
import { VGraph, Point, Polygon, Line, Curve } from 'flux-core';
import { IConnectorPoint, IConnectorEndPoint, IConnectorBump } from 'flux-diagram-composer';
import { IPoint2D } from 'flux-definition';
import {
    AbstractConnectorPather,
    IEndpointInfoForPathing,
    IEndpointPairInfoForPathing,
} from './pather.i';

/**
 * Angled line pather creates connector paths using straight
 * lines which can only be drawn horizontally or vertically.
 * This pather is used by angled and smooth angle connectors.
 */
@Injectable()
export class PatherAngled extends AbstractConnectorPather {
    /**
     * Shape padding size in pixels. This is added so that when the path moves around shapes
     * there will be a gap between the shape and the connector path.
     */
    public static get SHAPE_PADDING(): number {
        return 20;
    }

    /**
     * Returns an array of line/curve segments for each point on given connector path.
     */
    public getSegments( points: IConnectorPoint[]): ( Line | Curve )[][] {
        if ( !points.length ) {
            return [];
        }
        const segments: Line[][] = [[]];
        for ( let i = 1; i < points.length; ++i ) {
            const pointA = points[i - 1];
            const pointB = points[i];
            if ( pointB.c1 ) {
                segments.push([ new Line( pointA, pointB.c1 ), new Line( pointB.c1, pointB ) ]);
            } else {
                segments.push([ new Line( pointA, pointB ) ]);
            }
        }
        return segments;
    }

    /**
     * Remove bumps which are too close to control points of angled connectors.
     */
    public getBumps( points: IConnectorPoint[], segmentsBelow: ( Line | Curve )[]): IPoint2D[][][] {
        const bumps = super.getBumps( points, segmentsBelow );
        for ( let i = 0; i < points.length; ++i ) {
            const point = points[i];
            if ( !point.c1 ) {
                continue;
            }
            const ctrl = Point.from( point.c1 );
            const defaultRad = 4; // FIXME: this should be exported from flux-diagram-composer
            const filterBump = ( bump: IConnectorBump ) => ctrl.distanceTo( bump ) >= ( bump.radius || defaultRad );
            const pointBumps = bumps[i];
            pointBumps[0] = pointBumps[0].filter( filterBump );
            pointBumps[1] = pointBumps[1].filter( filterBump );
        }
        return bumps;
    }

    /**
     * Creates a path from one point to another with current draw style.
     */
    protected createPath(
        endpoints: IEndpointPairInfoForPathing,
    ): IConnectorPoint[] {
        // round endpoint angles to the nearest 90 degrees
        endpoints.pointA.angle = roundN( endpoints.pointA.angle, 90 );
        endpoints.pointB.angle = roundN( endpoints.pointB.angle, 90 );
        return this.pathTwoPoints( endpoints );
    }

    /**
     * Corrects the given path so that it'll match the current draw style.
     * This can be used to correct errors in the path after modifications
     * and to convert the connector draw style from one to another.
     */
    protected adjustPath(
        endpoints: IEndpointPairInfoForPathing,
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        this.processPathPoints( currentPath );
        this.resetControlPoints( endpoints, currentPath );
        this.centerPathPoints( endpoints, currentPath );
        this.removeExtraPoints( endpoints, currentPath );
        this.setDefaultBumps( endpoints, currentPath );
        return currentPath;
    }

    /**
     * this function clears all intermediate extra path points which are on
     * horizontal or vertical line and returns the start and end points.
     */
    protected processPathPoints(
        currentPath: IConnectorPoint[],
        ): void {
            let len = currentPath.length;
            let i = 2;
            if ( len > 3 ) {
                while ( i < len ) {
                    const pointA = currentPath[i - 2];
                    const pointB = currentPath[i - 1];
                    const pointC = currentPath[i];
                    if ( Line.isInLineHOrV( pointA, pointB, pointC ) &&
                    !Point.isEqual( pointA, pointB ) &&
                    !Point.isEqual( pointB, pointC )) {
                        this.removePointAt( currentPath, i - 1 );
                        len = len - 1;
                    } else {
                        i = i + 1;
                    }
                }
            }
    }

    /**
     * Reverses all points (including endpoints) on the connector path and
     * makes necessary changes to control points to make sure the path would
     * not change visually.
     */
    protected reversePath(
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        const reversed: IConnectorPoint[] = [];
        const length = currentPath.length;
        for ( let i = 0; i < length; ++i ) {
            const point = { ...currentPath[i] };
            const next = currentPath[i + 1];
            if ( next ) {
                point.c1 = next.c1;
            } else {
                point.c1 = null;
            }
            reversed[ length - 1 - i ] = point;
        }
        return reversed;
    }

    /**
     * Sets the 'direction' property on connector endpoints. This value will
     * be stored on connector endpoints which is used when drawing arrow heads.
     * The direction values are angles to endpoints from adjacent points (as they are drawn).
     */
    protected setDirections( currentPath: IConnectorPoint[]): void {
        const first = currentPath[ 0 ] as IConnectorEndPoint;
        const last = currentPath[ currentPath.length - 1 ] as IConnectorEndPoint;
        const afterFirst = currentPath[ 1 ];
        const beforeLast = currentPath[ currentPath.length - 2 ];
        first.direction = Point.angleTo( afterFirst.c1 || afterFirst, first );
        last.direction = Point.angleTo( last.c1 || beforeLast, last );
    }

    /**
     * Calculates a path between 2 points. It will attempt to connect the endpoints
     * using different strategiesincluding some basic cases and using a visibility graph.
     * If all of them fail, it'll fallback to a simple L shaped connector.
     */
    protected pathTwoPoints( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        if ( this.canReconnectWithOneLine( endpoints )) {
            return this.reconnectWithOneLine( endpoints );
        }
        if ( this.canReconnectWithTwoLines( endpoints )) {
            return this.reconnectWithTwoLines( endpoints );
        }
        if ( this.canReconnectWithThreeLines( endpoints )) {
            return this.reconnectWithThreeLines( endpoints );
        }
        if ( this.canReconnectAsCShapedConnector( endpoints )) {
            return this.reconnectAsCShapedConnector( endpoints );
        }
        const reconnectWithVisibilityResult = this.reconnectWithVisibility( endpoints );
        if ( reconnectWithVisibilityResult ) {
            return reconnectWithVisibilityResult;
        }
        // NOTE: falling back to a very basic 2 line connection
        return this.reconnectWithTwoLines( endpoints );
    }

    /**
     * Resetting all control points on the path.
     */
    private resetControlPoints( endpoints: IEndpointPairInfoForPathing, path: IConnectorPoint[]): void {
        let isVerticalLineToCtrl = endpoints.pointA.angle === 90 ||
            endpoints.pointA.angle === -90;
        for ( let i = 1; i < path.length; ++i ) {
            const pointA = path[i - 1];
            const pointB = path[i];
            // sometime sakota doesn't update the pointB values.since here we trying reset the value
            // deleting and resetting will update values
            delete pointB.c1;
            delete pointB.c2;
            pointB.c2 = null;
            if ( isVerticalLineToCtrl ) {
                pointB.c1 = { x: pointA.x, y: pointB.y };
            } else {
                pointB.c1 = { x: pointB.x, y: pointA.y };
            }
            isVerticalLineToCtrl = !isVerticalLineToCtrl;
        }
    }

    /**
     * Centering path points on lines they are on.
     */
    private centerPathPoints( endpoints: IEndpointPairInfoForPathing, path: IConnectorPoint[]): void {
        let isVerticalLineToCtrl = endpoints.pointA.angle === 90 ||
            endpoints.pointA.angle === -90;
        for ( let i = 2; i < path.length; ++i ) {
            const pointA = path[i - 2];
            const pointB = path[i - 1];
            const pointC = path[i];
            if ( isVerticalLineToCtrl ) {
                pointB.x = ( pointA.x + pointC.x ) / 2;
            } else {
                pointB.y = ( pointA.y + pointC.y ) / 2;
            }
            isVerticalLineToCtrl = !isVerticalLineToCtrl;
        }
    }

    /**
     * Remove additional/unnecessary path points
     */
    private removeExtraPoints( endpoints: IEndpointPairInfoForPathing, path: IConnectorPoint[]): void {
        // NOTE: temporary fix! fix the adjust method instead.
        for ( let i = 0; i < path.length; ++i ) {
            const point = path[i];
            if ( point.c1 && Point.isEqual( point, point.c1 )) {
                point.c1 = null;
            }
        }
        // NOTE: start from 2 to skip first endpoint
        for ( let i = 2; i < path.length; ++i ) {
            const pointA = path[i - 1];
            const pointB = path[i];
            if ( Point.isEqual( pointA, pointB ) ||
                ( pointA.c1 && Line.isInLine( pointA.c1, pointA, pointB ))) {
                pointB.c1 = pointA.c1;
                this.removePointAt( path, i - 1 );
            }
        }
    }

    /**
     * Remove additional/unnecessary path points
     */
    private setDefaultBumps( endpoints: IEndpointPairInfoForPathing, path: IConnectorPoint[]): void {
        const segments = this.getSegments( path );
        for ( let i = 0; i < segments.length; ++i ) {
            const segmentsForPoint = segments[i];
            path[i].bumps = [];
            for ( let j = 0; j < segmentsForPoint.length; ++j ) {
                path[i].bumps[j] = [];
            }
        }
    }

    /**
     * Removes a point from the given path and updates the linked list.
     */
    private removePointAt( path: IConnectorPoint[], index: number ): void {
        const prev = path[ index - 1 ];
        const next = path[ index + 1 ];
        prev.nextId = next.id;
        next.prevId = prev.id;
        path.splice( index, 1 );
    }

    /**
     * Returns true if 2 points can be connected using a simple straight line.
     */
    private canReconnectWithOneLine( endpoints: IEndpointPairInfoForPathing ): boolean {
        return this.isHorizontalOrVertical( endpoints.pointA.point, endpoints.pointB.point ) &&
            this.areOppositeDirections( endpoints.pointA.angle, endpoints.pointB.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointA.point, endpoints.pointB.point, endpoints.pointA.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointB.point, endpoints.pointA.point, endpoints.pointB.angle );
    }

    /**
     * Returns the path which connects 2 points with a simple straight line.
     */
    private reconnectWithOneLine( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        return [
            endpoints.pointA.point,
            endpoints.pointB.point,
        ];
    }

    /**
     * Returns true if 2 points can be connected using an L shaped connector.
     */
    private canReconnectWithTwoLines( endpoints: IEndpointPairInfoForPathing ): boolean {
        return this.arePerpendicularDirections( endpoints.pointA.angle, endpoints.pointB.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointA.point, endpoints.pointB.point, endpoints.pointA.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointB.point, endpoints.pointA.point, endpoints.pointB.angle );
    }

    /**
     * Returns the path which connects 2 points with an L shaped line path.
     */
    private reconnectWithTwoLines( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        return [
            endpoints.pointA.point,
            endpoints.pointB.point,
        ];
    }

    /**
     * Returns true if 2 points can be connected using a Z? shaped connector.
     */
    private canReconnectWithThreeLines( endpoints: IEndpointPairInfoForPathing ): boolean {
        return this.areOppositeDirections( endpoints.pointA.angle, endpoints.pointB.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointA.point, endpoints.pointB.point, endpoints.pointA.angle ) &&
            this.isSecondInFirstDirection( endpoints.pointB.point, endpoints.pointA.point, endpoints.pointB.angle );
    }

    /**
     * Returns the path which connects 2 points with a Z? shaped line path.
     */
    private reconnectWithThreeLines( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        const midPoint = {
            ...this.getMidPoint( endpoints.pointA.point, endpoints.pointB.point ),
        };
        return [
            endpoints.pointA.point,
            midPoint as IConnectorPoint,
            endpoints.pointB.point,
        ];
    }
    /**
     * Returns true if 2 points can be connected using a C? shaped connector.
     */
    private canReconnectAsCShapedConnector( endpoints: IEndpointPairInfoForPathing ): boolean {
        if ( endpoints.pointA.angle === endpoints.pointB.angle && endpoints.pointA.shape && endpoints.pointB.shape ) {
            if ( endpoints.pointA.angle === 0 ) {
                return endpoints.pointA.point.x < endpoints.pointB.shape.bounds.x ? (
                    endpoints.pointA.point.y < endpoints.pointB.shape.bounds.top - 10 ||
                    endpoints.pointA.point.y > endpoints.pointB.shape.bounds.top +
                        endpoints.pointB.shape.bounds.height + 10
                ) :
                (
                    endpoints.pointB.point.y < endpoints.pointA.shape.bounds.top - 10 ||
                    endpoints.pointB.point.y > endpoints.pointA.shape.bounds.top +
                        endpoints.pointA.shape.bounds.height + 10
                );
            } else if ( endpoints.pointA.angle === 180 ) {
                return endpoints.pointA.point.x > endpoints.pointB.shape.bounds.x ? (
                    endpoints.pointA.point.y < endpoints.pointB.shape.bounds.top - 10 ||
                    endpoints.pointA.point.y > endpoints.pointB.shape.bounds.top +
                        endpoints.pointB.shape.bounds.height + 10
                ) :
                (
                    endpoints.pointB.point.y < endpoints.pointA.shape.bounds.top - 10 ||
                    endpoints.pointB.point.y > endpoints.pointA.shape.bounds.top +
                        endpoints.pointA.shape.bounds.height + 10
                );
            } else if ( endpoints.pointA.angle === 90 ) {
                return endpoints.pointA.point.y < endpoints.pointB.shape.bounds.y ? (
                    endpoints.pointA.point.x < endpoints.pointB.shape.bounds.left - 10 ||
                    endpoints.pointA.point.x > endpoints.pointB.shape.bounds.left +
                        endpoints.pointB.shape.bounds.width + 10
                ) :
                (
                    endpoints.pointB.point.x < endpoints.pointA.shape.bounds.left - 10 ||
                    endpoints.pointB.point.x > endpoints.pointA.shape.bounds.left +
                        endpoints.pointA.shape.bounds.width + 10
                );
            } else {
                return endpoints.pointA.point.y > endpoints.pointB.shape.bounds.y ? (
                    endpoints.pointA.point.x < endpoints.pointB.shape.bounds.left - 10 ||
                    endpoints.pointA.point.x > endpoints.pointB.shape.bounds.left +
                        endpoints.pointB.shape.bounds.width + 10
                ) :
                (
                    endpoints.pointB.point.x < endpoints.pointA.shape.bounds.left - 10 ||
                    endpoints.pointB.point.x > endpoints.pointA.shape.bounds.left +
                        endpoints.pointA.shape.bounds.width + 10
                );
            }
        } else {
            return false;
        }
    }
    /**
     * Returns the path which connects 2 points with a C? shaped line path.
     */
    private reconnectAsCShapedConnector( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        let padding = 0;
        if ( endpoints.pointA.angle === 0 ) {
            padding = endpoints.pointA.point.x >= endpoints.pointB.point.x ?
                20 : Math.abs( endpoints.pointA.point.x - endpoints.pointB.point.x ) + 20;
        } else if ( endpoints.pointA.angle === 90 ) {
            padding = endpoints.pointA.point.y >= endpoints.pointB.point.y ?
                20 : Math.abs( endpoints.pointA.point.y - endpoints.pointB.point.y ) + 20;
        } else if ( endpoints.pointA.angle === 180 ) {
            padding = endpoints.pointA.point.x <= endpoints.pointB.point.x ?
                -20 : endpoints.pointB.point.x - endpoints.pointA.point.x - 20;
        } else {
            padding = endpoints.pointA.point.y <= endpoints.pointB.point.y ?
                -20 : endpoints.pointB.point.y - endpoints.pointA.point.y - 20;
        }
        const point1 = {
            ...this.getAdjacentPoint( endpoints.pointA.point, endpoints.pointA.angle, padding ),
        };
        const point2 = {
            ...this.getAdjacentPoint( endpoints.pointB.point, endpoints.pointA.angle, padding ),
        };
        return [
            endpoints.pointA.point,
            point1 as IConnectorPoint,
            point2 as IConnectorPoint,
            endpoints.pointB.point,
        ];
    }

    /**
     * returns ajdacnt point for a given endpoint with a padding.
     */
    private getAdjacentPoint( endpoint: IPoint2D, angle: number , padding: number ): IPoint2D {
        if ( angle === 0 || angle === 180 ) {
            return {
                x: endpoint.x + padding,
                y: endpoint.y,
            };
        } else {
            return{
                x: endpoint.x,
                y: endpoint.y + padding,
            };
        }
    }

    /**
     * Returns the path which connects 2 points with the shortest valid path using a visibility graph.
     * Visibility graphs can be used to efficiently find available paths from one points to another.
     * It can also be used with a an algorithm such as the dijkstras algorithm to find the shortest path.
     */
    private reconnectWithVisibility( endpoints: IEndpointPairInfoForPathing ): IConnectorPoint[] {
        const padding = PatherAngled.SHAPE_PADDING;
        const polygons: { [ shapeId: string ]: Polygon } = {};
        const shapeA = endpoints.pointA.shape;
        if ( shapeA ) {
            const shapeATextKeys = Object.keys( shapeA.texts );
            if ( shapeA.texts && shapeATextKeys.length === 1 && shapeA.texts[ shapeATextKeys[0] ] &&
                shapeA.texts[ shapeATextKeys[0] ].isPositionable()) {
                    polygons[shapeA.id] = shapeA.getBoundsWithTexts().pad( padding ).toPolygon();
           } else {
                polygons[shapeA.id] = shapeA.bounds.pad( padding ).toPolygon();
           }
        }
        const shapeB = endpoints.pointB.shape;
        if ( shapeB ) {
            const shapeBTextKeys = Object.keys( shapeB.texts );
            if ( shapeB.texts && shapeBTextKeys.length === 1 && shapeB.texts[ shapeBTextKeys[0] ] &&
                shapeB.texts[ shapeBTextKeys[0] ].isPositionable()) {
                    polygons[shapeB.id] = shapeB.getBoundsWithTexts().pad( padding ).toPolygon();
            } else {
                polygons[shapeB.id] = shapeB.bounds.pad( padding ).toPolygon();
            }
        }
        const endpointA = shapeA ? this.awayFromShape( endpoints.pointA, padding ) : endpoints.pointA.point;
        const endpointB = shapeB ? this.awayFromShape( endpoints.pointB, padding ) : endpoints.pointB.point;
        const visibility = this.createVisiblityGraph( polygons );
        const shortestPath = this.findShortestPath( visibility, endpointA, endpointB );
        if ( !shortestPath ) {
            return null;
        }
        return [
            ...( shapeA ? [ endpoints.pointA.point ] : []),
            ...shortestPath,
            ...( shapeB ? [ endpoints.pointB.point ] : []),
        ];
    }

    /**
     * Helper Functions
     * ----------------
     */

    /**
     * Creates a new visibility graph with given polygons.
     */
    private createVisiblityGraph( polygons: { [ shapeId: string ]: Polygon }) {
        const vgraph = new VGraph();
        // tslint:disable-next-line:forin
        for ( const shapeId in polygons ) {
            const polygon = polygons[shapeId];
            vgraph.addPolygon( shapeId, polygon );
        }
        return vgraph;
    }

    /**
     * Finds the shortest path from one point to another point using
     * the visibility graph. Starting and ending with given points.
     */
    private findShortestPath(
        vgraph: VGraph,
        endpointA: IConnectorPoint,
        endpointB: IConnectorPoint ): IConnectorPoint[] {
        try {
            const result = vgraph.create({
                s: endpointA,
                e: endpointB,
            });
            const points = new Graph( result.vgraph )
                .path( 's', 'e', { trim: true })
                .map( key => ({ ...result.points[key] }));
            return [ endpointA, ...points, endpointB ];
        } catch ( err ) {
            return null;
        }
    }

    /**
     * Returns a new point which is 'n' pixels away from the connected shape.
     */
    private awayFromShape( point: IEndpointInfoForPathing, n: number ): IConnectorPoint {
        return {
            ...Point.from( point.point ).shift( point.angle, n ),
        } as any;
    }

    /**
     * Returns the point which is in the middle of two given points.
     */
    private getMidPoint( a: IPoint2D, b: IPoint2D ): IPoint2D {
        return {
            x: ( a.x + b.x ) / 2,
            y: ( a.y + b.y ) / 2,
        };
    }

    /**
     * Checks whether one point is in another point's given direction.
     */
    private isSecondInFirstDirection( a: IPoint2D, b: IPoint2D, angle: number ): boolean {
        return ( angle === 0 && ( b.x > a.x )) ||
            ( angle === 90 && ( b.y > a.y )) ||
            ( angle === 180 && ( b.x < a.x )) ||
            ( angle === -180 && ( b.x < a.x )) ||
            ( angle === -90 && ( b.y < a.y ));
    }

    /**
     * Checks whether directions of given angles are opposite to each other
     */
    private areOppositeDirections( a: number, b: number ): boolean {
        return a !== b && Math.abs( a - b ) % 180 === 0;
    }

    /**
     * Checks whether directions of given angles are perpendicular to each other
     */
    private arePerpendicularDirections( a: number, b: number ): boolean {
        return Math.abs( a - b ) % 180 === 90;
    }

    /**
     * Check whether the line connecting given points is horizontal or vertical.
     */
    private isHorizontalOrVertical( a: IPoint2D, b: IPoint2D ) {
        return a.x === b.x || a.y === b.y;
    }
}
