/**
 * Abstract class for the ErrorReporter.
 * Used to create the error report or submit it from flux core.
 */
export abstract class AbstractErrorReporter {
    public abstract createErrorReport(): Promise<IErrorReport>;
    public abstract submitErrorReport( userMessage?: string, sendToZendesk?: boolean ): Promise<void>;
}

/**
 * When an oops error occurs and the user wants to report the error,
 * the following data will be collected and sent as the error report.
 */
export interface IErrorReport {
    /**
     * The diagram model data at a specific moment.
     */
    diagram: object;

    /**
     * Changes which are not synced with the server.
     */
    changes: object[];

    /**
     * Error logs collected over the time.
     */
    errors: any;
}
