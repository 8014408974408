import { TranslateService } from '@ngx-translate/core';
import { AbstractCommand, Command, ImageLoader, CommandScenario, AppConfig,
    NotifierController, StateService } from 'flux-core';
import { Observable, of, concat, defer, from } from 'rxjs';
import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import { DiagramLocatorLocator, ITypedDiagramLocator } from '../locator/diagram-locator-locator';
import { DiagramSVGView } from 'flux-diagram-composer';
import { take, switchMap, map, tap, filter } from 'rxjs/operators';
import { DiagramModel } from '../model/diagram.mdl';
import { NotificationMessages, Notifications } from '../../notifications/notification-messages';
import { ConnectionStatus } from 'flux-connection';

/**
 * AbstractDiagramExportCommand
 * This abstracts the common functionally required by diagram export commands.
 * Diagram can be exported as images ( PNG, JPEG, BMP ) or other formats such as
 * SVG, PDF or any Creately format we may define. For each type of export we support must have a
 * command implemented by extending this.
 *
 * Exporter implementations must implement the "prepareData" public function to generate data to export.
 */
@Injectable()
@Command()
export class AbstractDiagramExportCommand extends AbstractCommand {

    /**
     * If the diagram width or height ( when the scale is 1 ) exceeds this number,
     * png and jpg files should be generated in the server.
     */
    protected maxClientExportWidthOrHeight = 6000;

    /**
     * The maximum width that server can generate.
     */
    protected maxServerExportWidth = 32000;

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
    protected notificationMessages: NotificationMessages;

    /**
     * Constructor
     * @param locator DiagramLocator
     */
    public constructor(
        protected ll: DiagramLocatorLocator,
        private resources: ImageLoader,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected state: StateService<any, any>,
    ) {
        super();
        this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * @returns - true if the app is offline.
     */
    private get isOffline(): boolean {
        return this.state.get( ConnectionStatus ) === ConnectionStatus.OFFLINE;
    }

    /**
     * Command executor function, this function will be called by the command service
     * to trigger the download functionality. Extended classes may override this function
     * if necessary but won't be required to do so for the moment.
     */
    public execute(): Observable<any> {
        return this.triggerDownload();
    }

    /**
     * Generates the svg for the current diagram or selected area
     * @returns { name, svg } Where the name is the diagram name and svg is the svh string
     */
    protected prepareSvgData() {
        const locator = this.ll.forCurrentObserver( this.eventData.scenario === CommandScenario.PREVIEW );
        const svgview = this.createDiagramSVGView( locator );

        return locator.pipe(
            take( 1 ),
            switchMap( dLocator => dLocator.getDiagramOnce()),
            switchMap(( diagram: DiagramModel ) => concat(
                defer(() => svgview.populateDiagram()),
                defer(() => {
                    const shapes = this.state.get( 'Selected' ).length === 0 ? undefined : this.state.get( 'Selected' );
                    return svgview.toSvg( shapes ).pipe(
                        take( 1 ),
                        map( svg => ({ svg, name: diagram.name, id: diagram.id })),
                    );
                }),
            )),
        );
    }

    /**
     * Create a DiagramSVGView instance with the current diagram locator
     */
    protected createDiagramSVGView( locator: Observable<ITypedDiagramLocator> ): DiagramSVGView {
        return new DiagramSVGView( locator, this.resources, this.state );
    }

    /**
     * This triggers the file download with the current data on the instance and the file name.
     * Returns an Observable which can be useful to trigger some other functionality after the download is completed.
     * Format can be 'file' or 'blob'.
     * SVG or PDF uses 'file' format to save the document to disk.
     * PNG or JPEG uses 'blob' format to save the document to disk.
     */
    protected triggerDownload(): Observable<any> {
        if ( this.data.format === 'file' ) {
            // Note: Blob has been used here because 'File' constructor is not
            // supported by Microsoft Edge and IE.
            const file = new Blob([ this.data.content ], { type: this.data.type });
            saveAs( file, this.data.name );
            return of( true );
        } else if ( this.data.format === 'blob' ) {
            saveAs( this.data.content, this.data.name );
            return of( true );
        }
    }

    /**
     * @param width - Width of the diagram.
     * @param height - Height of the diagram.
     * @returns true if the diagram exceeded the maximum
     * possible client side export.
     */
    protected isDiagramExceededMaxClientExport( width, height ): boolean {
        // FIXME: Disabling serverside generation temporarily
        return false;
        // return width > this.maxClientExportWidthOrHeight || height > this.maxClientExportWidthOrHeight;
    }

    protected showExportNotification() {
        const notificationData = this.notificationMessages.getNotificationMessage( Notifications.IMAGE_EXPORT_STARTED );
        this.notifierController.show( Notifications.IMAGE_EXPORT_STARTED,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected showLargeExportNeedInternetNotification() {
        const notificationData = this.notificationMessages.getNotificationMessage( Notifications.IMAGE_EXPORT_OFFLINE );
        this.notifierController.show( Notifications.IMAGE_EXPORT_OFFLINE,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected showExportLimitExceededNotification() {
        const notificationData = this.notificationMessages
            .getNotificationMessage( Notifications.IMAGE_EXPORT_SIZE_EXCEEDED );
        this.notifierController.show( Notifications.IMAGE_EXPORT_SIZE_EXCEEDED,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected showErrorNotification() {
        const notificationData = this.notificationMessages.getNotificationMessage( Notifications.IMAGE_EXPORT_FAILED );
        this.notifierController.show( Notifications.IMAGE_EXPORT_FAILED,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected hideExportNotification() {
        this.notifierController.hide( Notifications.IMAGE_EXPORT_STARTED );
    }

    /**
     * Convert the svg to raster image in the server only if the app is online.
     */
    protected fetchDiagramFromServer( type: 'png' | 'jpg', width: number ): Observable<Blob> {
        const exportableWidth = Math.min( this.maxServerExportWidth, Math.round( width ));
        return of( this.isOffline ).pipe(
            filter( offline => {
                if ( offline ) {
                    this.showLargeExportNeedInternetNotification();
                }
                return !offline;
            }),
            switchMap(() =>
                this.prepareSvgData().pipe(
                    tap(() =>  this.showExportNotification()),
                    switchMap(( data: any ) => from(
                        fetch( AppConfig.get( 'SVG_TO_RASTER_URL' ), {
                            headers: {
                            'Content-Type': 'application/json',
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                type,
                                width: exportableWidth,
                                svg: data.svg,
                                id: data.id,
                            }),
                        })),
                    ),
                    switchMap( response => from( response.blob())),
                )),
        );
    }
}

Object.defineProperty( AbstractDiagramExportCommand, 'name', {
    value: 'AbstractDiagramExportCommand',
});
