import { ResourceLoader } from './resource-loader.svc';
import { Observable, fromEvent } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ResponseType } from '../../command/abstract-http.cmd';

/**
 * This is an extended version of the {@link ResourceLoader}
 * that specifically caters to loading all types of vector
 * and raster images.
 * It retains the standard image loader loading and caching functionality
 * but handles responses differently. All content is loaded as Blobs and
 * then converted to base64. The load function would always emit a base64
 * string representing the image.
 *
 * @author  Ramishka
 * @since   2019-02-21
 */
@Injectable()
export class ImageLoader extends ResourceLoader {

    /**
     * Returns the http request options
     * All data is loaded as Blobs.
     */
    protected get requestOptions() {
        return {
            observe: 'events',
            responseType: ResponseType.Blob,
        };
    }

    /**
     * Handles the load response.
     * @return observable that emits the base64 string of
     * the image.
     */
    protected handleResponse( res: HttpResponse<any> ): Observable<any> {
        const reader = this.getFileReader();
        const observer = this.getReadObserver( reader );
        reader.readAsDataURL( res.body );
        return observer;
    }

    /**
     * Returns an observable that will emit once and complete when
     * the blob is read.
     * @param reader
     */
    private getReadObserver( reader: FileReader ): Observable<any> {
        return fromEvent( reader, 'loadend' ).pipe(
            map( progressEvent => ( progressEvent.currentTarget as FileReader ).result ),
            take( 1 ),
        );
    }

    /**
     * Returns File reader instance
     */
    private getFileReader(): FileReader {
        return new FileReader();
    }
}
