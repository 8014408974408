import { AbstractMessageCommand } from 'flux-connection';
import { DiagramInfoModel } from '../model/diagram-info.mdl';
import { CommandInterfaces } from 'flux-core';

/**
 * This is the command to retrieve a diagram details for a given diagram id.
 * Responds with a  {@link DiagramInfoModel}.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
export class GetDiagramInfo extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return { diagramId: true };

     }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
        ];
    }

    public static get resultType(): any {
        return DiagramInfoModel;
    }

    public get version(): number {
        return 2;
    }

    public execute (): boolean {
       return true;
    }

}

Object.defineProperty( GetDiagramInfo, 'name', {
    value: 'GetDiagramInfo',
});
