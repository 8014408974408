import { Component, Input, ViewChild } from '@angular/core';
import { LoadingIndicator } from './loading-indicator.cmp';

/**
 * This component is used to show the link thumbanil image
 *
 * @author  gobiga
 * @since   26/07/2017
 */

@Component({
    selector: 'link-thumbnail-image',
    template: `
        <div class="fx-link-thumbnail fx-center-all">
            <loading-indicator classString="fx-small"
                [showing]= "true" #loading ></loading-indicator>
            <i class="boson-icon-warning light-grey"
                *ngIf="!loading.isLoading && loadError"></i>
            <img #thumbnail [class.fx-hidden]="loading.isLoading || loadError"
                src="{{url}}"
                (error)="onError()"
                (load)="onLoad()"/>
        </div>`,
})
export class LinkThumbnailImage {

    /**
     * This property holds the url of the  link thumbnail image
     */
    @Input() public url: string;

    /**
     * This indicates whether image load is success or not.
     */
    public loadError: boolean = false;

    /* The loading indicator. This will show a spinner while the thumbnail
     * is being loaded and hide once loading completes.
     */
    @ViewChild( LoadingIndicator )
    protected loading: LoadingIndicator;

    /**
     * This gets called when an image loading completes
     */
    public onLoad() {
        this.loading.complete();
    }

    /**
     * This gets called when an image load fails
     */
    public onError() {
        this.loadError = true;
        this.loading.complete();
    }


}
