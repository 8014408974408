import { Tracker } from 'flux-core';
import { IDataItemUIControl } from './data-items-uic.i';
import { AbstractDropDown } from 'flux-core/src/ui';
import { Subject, BehaviorSubject } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';

@Component({
    selector: 'single-select-combo-uic',
    template: `
        <div class="text" *ngIf="valueSource | async as data" class="single-select-combo-uic">
            <label class="single-select-combo-uic-label" *ngIf="data?.label">
                {{data?.label}}
            </label>
            <abs-dropdown #dd [settings]="{ closeOnItemClicked: true, openOnHover: false, closeOnBlur: true } "
                direction="{{data?.dropdownDirection}}" [autoPosition]="false"
                class="dropdown-item">
                    <sidebar-dropdown-button ddbutton [items]="data?.options">
                    </sidebar-dropdown-button>
                    <sidebar-list-dropdown-item dditem *ngFor="let item of data?.options"
                    class="fx-margin-bottom-10"
                    [item]="item">
                    </sidebar-list-dropdown-item>
            </abs-dropdown>
        </div>
    `,
    styleUrls: [ './single-select-combo-uic.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * UI control for SingleSelectCombo
 */
export class SingleSelectComboUIC implements IDataItemUIControl<any>, AfterViewInit, OnDestroy {
    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
    @Input()
    public context?: string;

    public id: string;

    public change: Subject<any>;

    public selected: any;

    public valueSource: BehaviorSubject<any>;

    @ViewChild( AbstractDropDown, { static: false })
    public absDropdown: AbstractDropDown;

    protected subs = [];

    constructor() {
        this.valueSource = new BehaviorSubject( null );
        this.change = new Subject();
    }


    public setData( data: any ) {
        data.options.forEach( op =>  {
            if ( op.value === data.value ) {
                op.selected = true;
            } else {
                delete op.selected;
            }
        });
        this.valueSource.next( data );
    }


    public ngAfterViewInit() {
        const sub = this.absDropdown.changed.subscribe( ids => {
            const changeValue =  this.valueSource.getValue();
            const valueItem = changeValue.options.filter( op => op.id === ids[0])[0];
            this.change.next( valueItem.value );
            /* istanbul ignore next */
            if ( this.context ) {
                Tracker.track( `${this.context}.dropdown.change` );
            }
        });
        this.subs.push( sub );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

}
