/**
 * This enum holds the application status
 */
export enum ConnectionStatus {

    /**
     * The application is not connected to the server.
     * This should be app status when there is no internet access.
     */
    OFFLINE = 0,

    /**
     * The application has contacted the server and initiated connection
     * but the connection process is not fully completed / connection is
     * still not ready for communication.
     */
    CONNECTED = 1,

    /**
     * The application has successfully connected to the server and connection is
     * active for communication
     */
    ONLINE = 2,

    /**
     * The application has connected to server, and closed by the server for being idle
     */
    SLEEP = 3,
}
