import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollaboratorType, DiagramInfoModel, ProjectModel, ResourceModelStore } from 'flux-diagram';

/**
 * This command check the team diagram share permission for guest.
 */
@Injectable()
@Command()
export class CheckTeamShareLimitation extends AbstractMessageCommand {
    /**
     * This command is sent to the server.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    public static get dataDefinition(): {}  {
        return {
            isFolder: false,
        };
    }

    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData(): Observable<unknown> {
        return this.getDiagramOwner();
    }

    public execute() {
        return true;
    }

    protected getDiagramOwner(): Observable<void> {
        const isFolder = this.data.isFolder;
        const modelStore = isFolder ? this.dataStore.getModelStore( ProjectModel ) as ResourceModelStore
                                    : this.dataStore.getModelStore( DiagramInfoModel ) as ResourceModelStore;

        return modelStore.getCollabs( this.resourceId ).pipe(
            map( collabs => {
                const collabUser = collabs.find( c => c.role === CollaboratorType.OWNER );
                this.data = { ownerId : collabUser.id };
            }),
        );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( CheckTeamShareLimitation, 'name', {
    value: 'CheckTeamShareLimitation',
});
