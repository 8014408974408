import { mergeAttributes, getHTMLFromFragment } from '@tiptap/core';
import { INodeView } from './node-view.i';
import { DiagramLocatorLocator } from '../../../../../base/diagram/locator/diagram-locator-locator';
import { Injector } from '@angular/core';
import { StateService, CommandService, NotifierController, AbstractNotification, NotificationType } from 'flux-core';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { DiagramCommandEvent } from '../../../../../editor/diagram/command/diagram-command-event';
import {
    TiptapDocumentsManagerShapeText,
} from '../../../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ShapeModel } from '../../../../../base/shape/model/shape.mdl';
import { ShapeLinkModel, ShapeLinkType } from 'flux-diagram';
import { Plugin } from 'prosemirror-state';

/**
 * Tiptap nodeView to insert an iframe shape on diagram
 */
export class IframeNodeView implements INodeView {

    /**
     * Returns the nodeView that renders iframe items in tiptap editor
     * @returns
     */
    public static create( injector: Injector, diagramId: string ) {
        const ll = injector.get( DiagramLocatorLocator );
        const stateSvc = injector.get( StateService );
        const commandService = injector.get( CommandService );
        const translateService = injector.get( TranslateService );
        const notifierController = injector.get( NotifierController );
        return new IframeNodeView( injector, ll, stateSvc, diagramId, commandService, translateService,
            notifierController );
    }

    public name = 'tiptapIframe';
    public group = 'block';
    public content = 'block+';
    public atom = true;
    public selectable = false;
    public draggable = true;
    public isolating = true;
    public allowGapCursor = true;
    public editor;

    /**
     * Data and subscribers to be stored by shape after shape has been created
     * @private
     */
    private paramsByShape: {
        [shapeId: string]: {
            /**
             * Styles appended to <head>, stored by CSS selector: CSS styles as {} and <style> element
             */
            appendedStyles: {
                [selector: string]: {
                    currentStyles?: {
                        [style: string]: string,
                    },
                    element?: HTMLStyleElement,
                },
            },
            url?: string, // Iframe src url
            textContent?: string, // Whatever was last typed into the content div
            incorrectUrl?: boolean, // true if textContent was not a URL

            Selected?: Subscription, // 'Selected' changes subscription
            SelectionInteractionState?: Subscription, // 'SelectionInteractionState' changes subscription

            iframe: HTMLIFrameElement,
            content: HTMLDivElement, // URL should be typed or pasted into the content element
            placeholder: HTMLDivElement, // Placeholder text
            item: HTMLDivElement, // Parent dom element

            shape?: ShapeModel,

            updateHandler?: () => void, // handler for editor.on( 'update' )
            focusHandler?: () => void, // handler for editor.on( 'focus' )
            blurHandler?: () => void, // handler for editor.on( 'blur' )
        },
    } = {};

    private regs = [{
        reg: /https?:\/\/[\w-.]*youtube\.com\/watch[\w=?&-]*[?&]v=([\w-]+)/,
        position: 1,
        prefix: 'https://www.youtube.com/embed/',
        size: { width: 480, height: 280 },
    }, {
        reg: /https?:\/\/[\w-.]*youtube\.com\/(v|e|embed|shorts)\/([\w-]+)/,
        position: 2,
        prefix: 'https://www.youtube.com/embed/',
        size: { width: 480, height: 280 },
    }, {
        reg: /https?:\/\/youtu\.be\/([\w-]+)/,
        position: 1,
        prefix: 'https://www.youtube.com/embed/',
        size: { width: 480, height: 280 },
    }, {
        reg: /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
        position: 0,
        prefix: 'https://www.figma.com/embed?embed_host=cleately&url=',
        size: { width: 640, height: 480 },
    }, {
        reg: /https:\/\/\w*\.?vimeo\.com\/(video\/)?(\d+\/?.*)/,
        position: 2,
        prefix: 'https://player.vimeo.com/video/',
        size: { width: 480, height: 280 },
    }, {
        reg: /https?:\/\/docs\.google\.com[\w.\-/&?=%()+@:~#;!]+/,
        position: 0,
        size: { width: 640, height: 480 },
    }, {
        reg: /https?:\/\/drive\.google\.com\/file\/d\/(.+)\/view/,
        position: 1,
        prefix: 'https://drive.google.com/file/d/',
        postfix: '/preview',
        size: { width: 640, height: 480 },
    }, {
        reg: /https:\/\/www\.loom\.com\/(embed|share)\/(\w+)/,
        position: 2,
        prefix: 'https://www.loom.com/embed/',
        size: { width: 440, height: 280 },
    }, {
        reg: /https:\/\/onedrive\.live\.com[\w.\-/&?=%()+@:~#;!]+/,
        position: 0,
        fixURL: url => url.replace( /&amp;/g, '&' ),
        size: { width: 640, height: 480 },
    }];

    private diagramChangesSub = this.ll.forDiagram( this.diagramId, false )
        .getDiagramChanges().subscribe( change => {

            const shapeIds = change.split && change.split.shapes && change.split.shapes ?
                Object.keys( change.split.shapes ) : [];
            shapeIds.forEach( shapeId => {

                if ( !this.paramsByShape[shapeId]) {
                    return;
                }

                const modifier = change.split.shapes[shapeId] ? change.split.shapes[shapeId] : false;
                const url = modifier && modifier.$set && modifier.$set['data.srcUrl.value'] ?
                    modifier.$set['data.srcUrl.value'] : false;
                const link = modifier && modifier.$set && modifier.$set.links ?
                    modifier.$set.links[0]?.link : false;
                const height = modifier && modifier.$set && modifier.$set.scaleY ?
                    modifier.$set.scaleY * this.paramsByShape[shapeId].shape.defaultBounds.height : false;
                const width = modifier && modifier.$set && modifier.$set.scaleX ?
                    modifier.$set.scaleX * this.paramsByShape[shapeId].shape.defaultBounds.width : false;

                if ( url || height || width || link ) {
                    this.fillIframe({ url, height, width, shapeId, link });
                }
                if (( modifier as any ).$unset === true ) {
                    this.unsubscribeShape( shapeId );
                }
            });
        });

    protected constructor(
        protected injector: Injector,
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        protected diagramId: string,
        protected commandService,
        protected translate,
        protected notifierController: NotifierController,
    ) {
    }

    /**
     * Unsubscribing shape subscriptions and removing its <style> elements
     * @param shapeId string
     */
    public unsubscribeShape ( shapeId ) {
        if ( !this.paramsByShape[shapeId]) {
            return;
        }
        const {
            Selected,
            SelectionInteractionState,
            appendedStyles,
            updateHandler,
            focusHandler,
            blurHandler } = this.paramsByShape[shapeId];
        if ( Selected ) {
            Selected.unsubscribe();
        }
        if ( SelectionInteractionState ) {
            SelectionInteractionState.unsubscribe();
        }
        Object.keys( appendedStyles ).
        forEach( selector => {
            const element = appendedStyles[selector].element;
            if ( element.parentNode ) {
                element.parentNode.removeChild( element );
            }
        });
        this.editor.off( 'update', updateHandler );
        this.editor.off( 'focus', focusHandler );
        this.editor.off( 'blur', blurHandler );
        delete this.paramsByShape[shapeId];
    }

    public destroy() {
        Object.keys( this.paramsByShape ).forEach( this.unsubscribeShape.bind( this ));
        this.diagramChangesSub.unsubscribe();
    }

    public addOptions = () => ({
        HTMLAttributes: {},
    })

    public addAttributes = () => ({
        shapeId: {
            default: '',
        },
        textId: {
            default: '',
        },
        type: {
            default: '',
        },
    })

    public parseHTML = () => [
        {
            tag: 'tiptap-iframe-node-view',
        },
    ]

    public renderHTML = function ( this, {  HTMLAttributes }) {
        return [ 'tiptap-iframe-node-view', mergeAttributes( this.options.HTMLAttributes, HTMLAttributes ), 0 ];
    };

    public appendStyle = params => {
        const { shapeId, selector, styles } = params;
        const { appendedStyles } = this.paramsByShape[shapeId];
        if ( !appendedStyles[selector]) {
            appendedStyles[selector] = {};
        }
        const currentStyles = appendedStyles[selector].currentStyles ? appendedStyles[selector].currentStyles : {};
        Object.keys( styles ).forEach( key => currentStyles[key] = styles[key]);
        appendedStyles[selector].currentStyles = currentStyles;
        const element = appendedStyles[selector].element ?
            appendedStyles[selector].element : document.createElement( 'style' );
        let stylesString = `.tiptap-child-editor-content div[shapeid="${shapeId}"] ${selector} {`;
        Object.keys( currentStyles ).forEach( key => {
            stylesString += `${key}: ${currentStyles[key]};`;
        });
        stylesString += '}';
        element.innerHTML = stylesString;
        document.head.append( element );
        appendedStyles[selector].element = element;
    }

    public addNodeView = () =>
        ({
             editor,
             node,
             getPos,
             HTMLAttributes,
             decorations,
             extension,
         }) => {
            const { shapeId, type } = node.attrs;
            this.editor = editor;

            if ( !this.paramsByShape[shapeId]) {
                this.paramsByShape[shapeId] = {
                    appendedStyles: {},
                    item: document.createElement( 'div' ),
                    iframe: document.createElement( 'iframe' ),
                    content: document.createElement( 'div' ),
                    placeholder: document.createElement( 'div' ),
                };
            }

            const {
                item,
                iframe,
                content,
                placeholder,
            } = this.paramsByShape[shapeId];

            iframe.src = this.paramsByShape[shapeId].url ? this.paramsByShape[shapeId].url : '';
            iframe.setAttribute( 'contenteditable', 'false' );
            iframe.onload = () => {
                this.appendStyle({
                    shapeId,
                    selector: 'iframe',
                    styles: {
                        background: 'transparent',
                    },
                });
            };
            this.appendStyle({
                shapeId,
                selector: 'iframe',
                styles: {
                    'display': 'none',
                    'background': 'rgba( 240, 240, 240, 0.5 ) url("/assets/images/shapes-assets/loader.gif")',
                    'background-size': 'cover',
                },
            });
            this.paramsByShape[shapeId].iframe = iframe;

            if ( !content.className ) {
                content.className = 'content';
            }
            this.appendStyle({
                shapeId,
                selector: '.content',
                styles: {
                    'pointer-events': 'auto !important',
                    'display': 'none',
                },
            });

            this.appendStyle({
                shapeId,
                selector: '.placeholder',
                styles: {
                    'font-size': '10pt',
                    'display': 'none',
                    'padding-left': '14px',
                },
            });
            if ( !placeholder.className ) {
                placeholder.className = 'placeholder';
                placeholder.setAttribute( 'contenteditable', 'false' );
            }
            if ( !placeholder.textContent ) {
                if ( type === 'youtube' ) {
                    content.classList.add( 'youtube' );
                    placeholder.classList.add( 'youtube' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.YOUTUBE.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.YOUTUBE.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.YOUTUBE.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'figma' ) {
                    content.classList.add( 'figma' );
                    placeholder.classList.add( 'figma' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.FIGMA.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.FIGMA.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.FIGMA.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'vimeo' ) {
                    content.classList.add( 'vimeo' );
                    placeholder.classList.add( 'vimeo' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.VIMEO.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.VIMEO.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.VIMEO.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'loom' ) {
                    content.classList.add( 'loom' );
                    placeholder.classList.add( 'loom' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.LOOM.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.LOOM.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.LOOM.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'word' ) {
                    content.classList.add( 'word' );
                    placeholder.classList.add( 'word' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.WORD.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.WORD.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.WORD.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'excel' ) {
                    content.classList.add( 'excel' );
                    placeholder.classList.add( 'excel' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.EXCEL.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.EXCEL.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.EXCEL.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'powerpoint' ) {
                    content.classList.add( 'powerpoint' );
                    placeholder.classList.add( 'powerpoint' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PP.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PP.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PP.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'google-docs' ) {
                    content.classList.add( 'google-docs' );
                    placeholder.classList.add( 'google-docs' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_DOCS.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_DOCS.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_DOCS.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'google-sheets' ) {
                    content.classList.add( 'google-sheets' );
                    placeholder.classList.add( 'google-sheets' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate
                        .instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SHEETS.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SHEETS.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SHEETS.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'google-slides' ) {
                    content.classList.add( 'google-slides' );
                    placeholder.classList.add( 'google-slides' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate
                        .instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SLIDES.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SLIDES.PLACEHOLDER_EXAMPLE' );
                    const note = document.createElement( 'p' );
                    note.className = 'note';
                    note.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.GOOGLE_SLIDES.PLACEHOLDER_NOTE' );
                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( note );
                } else if ( type === 'pdf' ) {
                    content.classList.add( 'pdf' );
                    placeholder.classList.add( 'pdf' );
                    const header = document.createElement( 'p' );
                    header.className = 'header';
                    header.innerHTML = this.translate
                        .instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_HEADER' );
                    const hint = document.createElement( 'p' );
                    hint.className = 'hint';
                    hint.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_EXAMPLE' );

                    const noteMsHeader = document.createElement( 'p' );
                    noteMsHeader.className = 'note';
                    noteMsHeader.innerHTML = this.translate
                        .instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_NOTE_MS_h' );
                    const noteMs = document.createElement( 'p' );
                    noteMs.className = 'note';
                    noteMs.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_NOTE_MS' );

                    const noteGoogleHeader = document.createElement( 'p' );
                    noteGoogleHeader.className = 'note';
                    noteGoogleHeader.innerHTML = this.translate
                        .instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_NOTE_GOOGLE_h' );
                    const noteGoogle = document.createElement( 'p' );
                    noteGoogle.className = 'note';
                    noteGoogle.innerHTML = this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PDF.PLACEHOLDER_NOTE_GOOGLE' );

                    placeholder.append( header );
                    placeholder.append( hint );
                    placeholder.append( noteMsHeader );
                    placeholder.append( noteMs );
                    placeholder.append( noteGoogleHeader );
                    placeholder.append( noteGoogle );
                } else {
                    placeholder.append( this.translate.instant( 'TIPTAP_EDITOR.IFRAME.PLACEHOLDER' ));
                    this.appendStyle({
                        shapeId,
                        selector: '.placeholder',
                        styles: { 'padding-top': '15px' },
                    });
                }
            }

            item.append( iframe );
            item.append( placeholder );
            item.append( content );

            Object.entries( HTMLAttributes ).forEach(([ key, value ]) => {
                item.setAttribute( key, value as any );
            });

            this.ll.forDiagram( this.diagramId, false ).getDiagramOnce().subscribe( diagram => {
                this.domElementsVisibility( diagram.shapes[shapeId]);
            });

            if ( !this.paramsByShape[shapeId].Selected ) {
                this.paramsByShape[shapeId].Selected = this.state.changes( 'Selected' ).pipe(
                    distinctUntilChanged( isEqual ),
                ).subscribe( shapesSelected => {
                    const selectionInteractionState = this.state.get( 'SelectionInteractionState' );
                    const pointerEvensOn = (
                        shapesSelected.includes( shapeId ) &&
                        selectionInteractionState !== 'started' );
                    this.appendStyle({
                        shapeId,
                        selector: 'iframe',
                        styles: { 'pointer-events': pointerEvensOn ? 'auto' : 'none' },
                    });
                });
                this.paramsByShape[shapeId].SelectionInteractionState = this.state.
                changes( 'SelectionInteractionState' ).pipe(
                    distinctUntilChanged( isEqual ),
                ).subscribe( selectionInteractionState => {
                    const shapesSelected = this.state.get( 'Selected' );
                    const pointerEvensOn = ( shapesSelected.includes( shapeId ) && selectionInteractionState !== 'started' );
                    this.appendStyle({
                        shapeId,
                        selector: 'iframe',
                        styles: { 'pointer-events': pointerEvensOn ? 'auto' : 'none' },
                    });
                });
            }

            if ( !this.paramsByShape[shapeId].updateHandler ) {
                this.setEditorListeners({ editor, shapeId, content });
            }

            return {
                dom: item,
                getPos,
                contentDOM: this.paramsByShape[shapeId].url ? null : content,
                ignoreMutation: ( mutation: MutationRecord ) =>
                    !item.contains( mutation.target ) || item === mutation.target,
            };

        }

    public setEditorListeners = ({ editor, shapeId, content }) => {
        this.paramsByShape[shapeId].updateHandler = () => {
            if ( !shapeId || !this.paramsByShape[shapeId] || this.paramsByShape[shapeId].url ) {
                return;
            }
            const _node = TiptapDocumentsManagerShapeText
                .getChildEditorNode( this.diagramId, shapeId, 'iframe' );
            const html = _node?.node?.content ? getHTMLFromFragment(
                _node.node.content, editor.schema ) : '';
            const div = document.createElement( 'div' );
            div.innerHTML = html;
            this.paramsByShape[shapeId].textContent = div.textContent;
            const { incorrectUrl, url } = this.paramsByShape[shapeId];
            this.appendStyle({
                shapeId,
                selector: '.content',
                styles: { 'background-color': div.textContent && !url ? '#ffffff' : 'transparent' },
            });
            this.appendStyle({
                shapeId,
                selector: '.placeholder',
                styles: { display: !url ? 'block' : 'none' },
            });
            if ( !div.textContent && incorrectUrl && !url ) {
                this.showIncorrectUrlNotification( 'NODE_IFRAME_INCORRECT_URL' );
            }
        };
        this.paramsByShape[shapeId].focusHandler = () => {
            if ( this.paramsByShape[shapeId] && !this.paramsByShape[shapeId].url ) {
                editor.on( 'update', this.paramsByShape[shapeId].updateHandler );
            }
        };
        editor.on( 'focus', this.paramsByShape[shapeId].focusHandler );
        this.paramsByShape[shapeId].blurHandler = () => {
            const _node = TiptapDocumentsManagerShapeText
                .getChildEditorNode( this.diagramId, shapeId, 'iframe' );
            const html = _node?.node?.content ? getHTMLFromFragment(
                _node.node.content, editor.schema ) : '';
            const div = document.createElement( 'div' );
            div.innerHTML = html;
            this.paramsByShape[shapeId].textContent = div.textContent;
            if ( div.textContent ) {
                this.getUrlFromHTML({
                    html,
                    shapeId,
                });
            }
            editor.off( 'update', this.paramsByShape[shapeId].updateHandler );
        };
        editor.on( 'blur', this.paramsByShape[shapeId].blurHandler );
        content.addEventListener( 'paste', ev => {
            const text = ev.clipboardData.getData( 'text' );
            if ( text.match( /https?:\/\// )) {
                this.getUrlFromHTML({
                    html: ev.clipboardData.getData( 'text' ),
                    shapeId,
                });
                ev.preventDefault();
            }
        });
    }
    addProseMirrorPlugins = () => [
        new Plugin({ // Check if tiptap selection in a same shape when typing (deselect if not)
            props: {
                handleDOMEvents: {
                    keydown: ( view, event ) => {
                        if ( view.state.selection.ranges[0]) {
                            const { $from, $to } = view.state.selection.ranges[0] as any;
                            let fromShape = '';
                            let toShape = '';
                            for ( let d = $from.depth; d > 0; d-- ) {
                                const node = $from.node( d );
                                if ( node.attrs.shapeId ) {
                                    fromShape = node.attrs.shapeId;
                                    break;
                                }
                            }
                            for ( let d = $to.depth; d > 0; d-- ) {
                                const node = $to.node( d );
                                if ( node.attrs.shapeId ) {
                                    toShape = node.attrs.shapeId;
                                    break;
                                }
                            }
                            if ( fromShape !== toShape ) {
                                this.editor.commands.setTextSelection( view.state.selection.$anchor.pos );
                            }
                        }
                    },
                },
            },
        }),
    ]
    public showIncorrectUrlNotification = id => {
        const messages = {
            NODE_IFRAME_INCORRECT_URL : 'TIPTAP_EDITOR.IFRAME.ERRORS.INCORRECT_URL',
            NODE_IFRAME_NOT_WHITELIST_URL : 'TIPTAP_EDITOR.IFRAME.ERRORS.NOT_WHITELIST_URL',
        };
        const options = {
            inputs: {
                description: this.translate.instant( messages[id]),
                autoDismiss: true,
                dismissAfter: 3000,
            },
        };
        this.notifierController.show(
            id, AbstractNotification, NotificationType.Error, options, false );
    }

    private async checkLinkIsVerified( url: string ) {
        const whitelistDomains = await fetch( './assets/security/whitelist.txt' ).then( content => content.text());
        if ( whitelistDomains ) {
            const hostname = this.extractDomain( url );
            return ( hostname && whitelistDomains.includes( hostname ));
        }
    }

    /**
     * Extract domain from url.
     * @returns - domain
     */
    private extractDomain( url: string ): string {
        let result: any;
        let match: any;
        if ( match = url.match( /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im )) {
            result = match[1];
            if ( match = result.match( /^[^\.]+\.(.+\..+)$/ )) {
                result = match[1];
            }
        }
        return result;
    }

    private domElementsVisibility = shape => {
        const shapeId = shape.id;
        const url = shape.data.srcUrl.value;
        this.paramsByShape[shapeId].shape = shape;
        this.appendStyle({
            shapeId,
            selector: '.content',
            styles: {
                display: url ? 'none' : 'block',
            },
        });

        this.appendStyle({
            shapeId,
            selector: '.placeholder',
            styles: {
                display: !url ? 'block' : 'none',
            },
        });

        const displayErrorholder = (
            !this.paramsByShape[shapeId].textContent &&
            !url &&
            this.paramsByShape[shapeId].incorrectUrl );
        if ( displayErrorholder ) {
            this.showIncorrectUrlNotification( 'NODE_IFRAME_INCORRECT_URL' );
        }
        const width = shape.scaleX * shape.defaultBounds.width;
        const height = shape.scaleY * shape.defaultBounds.height; // shape.bounds.height;
        if ( shape.data.srcUrl.value ) {
            this.fillIframe({
                url: shape.data.srcUrl.value,
                width,
                height,
                shapeId,
            });
        }
    }
    private modifyShape = ( shape, key, value ) => {
        const $set = {
            [`shapes.${shape.id}.${key}`]: value,
        };
        this.commandService.dispatch( DiagramCommandEvent.applyModifierDocument, { modifier: { $set }});
    }

    private getUrlFromHTML = ({ html, shapeId }) => {
        const match = html.match( /https?:\/\/[\w.\-/&?=%()+@:~#;!]+/ );
        const { shape, content } = this.paramsByShape[shapeId];
        const div = document.createElement( 'div' );
        div.innerHTML = html;
        const url = match ? match[0] : '';
        if ( !url ) {
            if ( div.textContent ) {
                if ( !url ) {
                    this.paramsByShape[shapeId].incorrectUrl = true;
                    this.paramsByShape[shapeId].textContent = '';
                    content.innerHTML = '';
                    this.appendStyle({
                        shapeId,
                        selector: '.content',
                        styles: { 'background-color': 'transparent' },
                    });
                    this.modifyShape( shape, 'style.lineColor', '#D92626' );
                    this.modifyShape( shape, 'style.lineStyle', [ 3, 3 ]);
                    this.showIncorrectUrlNotification( 'NODE_IFRAME_INCORRECT_URL' );
                    this.paramsByShape[shapeId].incorrectUrl = false;
                }
            }
        } else {
            this.fillIframe({ url, shapeId });
            this.paramsByShape[shapeId].textContent = '';
        }
    }

    private fillIframe = async params => {
        const { shapeId, link } = params;
        let { url, width, height } = params;
        if ( !url && link ) {
            url = link;
        }
        if ( !this.paramsByShape[shapeId]) {
            return;
        }
        const { iframe, shape } = this.paramsByShape[shapeId];
        const shapeWidth = shape.scaleX * shape.defaultBounds.width;
        const shapeHeight = shape.scaleY * shape.defaultBounds.height; // shape.bounds.height;
        const defaultSize = { width: 480, height: 320 };
        if ( !iframe ) {
            return;
        }

        if ( url ) {
            this.regs.find(({ reg, position, prefix = '', size, fixURL, postfix = '' }) => {
                const match = url.match( reg );
                if ( match && match[position]) {
                    url = prefix + match[position] + postfix;
                    if ( fixURL ) {
                        url = fixURL( url );
                    }
                    if ( size ) {
                        defaultSize.height = size.height;
                        defaultSize.width = size.width;
                    }
                    return true;
                }
            });
        }

        const fullUrl = url ? url : this.paramsByShape[shapeId].url;
        if ( !fullUrl ) {
            this.modifyShape( shape, 'scaleY', 1 );
            this.modifyShape( shape, 'scaleX', 1 );
            return;
        }
        if ( !await this.checkLinkIsVerified( fullUrl )) {
            this.showIncorrectUrlNotification( 'NODE_IFRAME_NOT_WHITELIST_URL' );
            return;
        }
        this.paramsByShape[shapeId].url = fullUrl;
        if ( !shape.data.srcUrl.value ) {
            width = defaultSize.width;
            height = defaultSize.height;
            this.modifyShape( shape, 'scaleY', defaultSize.height / shape.defaultBounds.height );
            this.modifyShape( shape, 'scaleX', defaultSize.width / shape.defaultBounds.width );
        }

        this.appendStyle({
            shapeId,
            selector: '.content',
            styles: { display: 'none' },
        });
        this.appendStyle({
            shapeId,
            selector: '.placeholder',
            styles: { display: 'none' },
        });
        this.appendStyle({
            shapeId,
            selector: 'iframe',
            styles: { display: 'block' },
        });

        const frameWidth = width ? width - 20 : shapeWidth ? shapeWidth - 20 : 280;
        const frameHeight = height ? height - 20 : shapeHeight ? shapeHeight - 20 : 160;
        this.appendStyle({
            shapeId,
            selector: 'iframe',
            styles: { width: frameWidth + 'px', height: frameHeight + 'px' },
        });

        if ( iframe.getAttribute( 'src' ) !== fullUrl ) {
            this.modifyShape( shape, 'data.srcUrl.value', fullUrl );
            const iframeParams = {
                src: fullUrl,
                allow: 'accelerometer; autoplay; clipboard-write; ' +
                    'encrypted-media; gyroscope; picture-in-picture; web-share',
                sandbox : 'allow-forms allow-scripts allow-same-origin allow-presentation allow-popups allow-modals ' +
                    'allow-storage-access-by-user-activation',
                allowfullscreen: 'true',
            };
            Object.keys( iframeParams ).forEach( key => iframe.setAttribute( key, iframeParams[key]));

            if ( !link ) { // If URL is changed by applying link, then link is already applied
                const shapeLink = ShapeLinkModel.fromUrl( fullUrl );
                this.commandService.dispatch( DiagramCommandEvent.applyShapelink, {
                    source: { type: ShapeLinkType.WEB, id: shape.id },
                    link: shapeLink,
                });
            }

        }
        if ( shape.data.srcUrl.value !== fullUrl ) {
            this.modifyShape( shape, 'style.lineColor', 'rgba(42, 82, 94, 0.3)' );
            this.modifyShape( shape, 'style.lineStyle', [ 0, 0 ]);
        }
    }
}
