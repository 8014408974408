import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Command, NotificationType, NotifierController, StateService } from 'flux-core';
import { AbstractNotification } from 'flux-core/src/ui';
import { UserLocator } from 'flux-user';
import { combineLatest, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { ChangeBindingService } from '../../../framework/diagram/bindings/change-binding.svc';
import { ResultChangeBindingService } from '../../../framework/diagram/bindings/result-change-binding.svc';
import { SessionHistoryManager } from '../../../system/session-history-manager.svc';
import { DocumentChange } from './document-change.cmd';

/**
 * This class handles restore notification in addition to what documentChange command does.
 */
@Injectable()
@Command()
export class RestoreDocumentChange extends DocumentChange {

    /**
     * Inject services and stuff!
     */
    constructor(
        sessionHistoryManager: SessionHistoryManager,
        ll: DiagramLocatorLocator,
        cs: DiagramChangeService,
        changeBindingService: ChangeBindingService,
        resultBindingService: ResultChangeBindingService,
        state: StateService<any, any>,
        private userLocator: UserLocator,
        private translate: TranslateService,
        private notifierController: NotifierController,
    ) {
        super( sessionHistoryManager, ll, cs, changeBindingService, resultBindingService, state );
    }

    public executeResult(): Observable<unknown> {
        return combineLatest(
            super.executeResult(),
            this.handleNotification(),
        );
    }

    protected handleNotification() {
        if ( this.state.get( 'CurrentDiagram' ) === this.resourceId ) {
            if ( this.state.get( 'CurrentUser' ) === this.resultData.change.userId ) {
                // restore success notification to the user initiated the restore
                const options = {
                    inputs: {
                        description: this.translate.instant( 'NOTIFICATIONS.DIAGRAM.RESTORE_SUCCESS' ),
                        autoDismiss: true,
                        dismissAfter: 3000,
                    },
                };
                this.notifierController.show(
                    'DIAGRAM_RESTORED', AbstractNotification, NotificationType.Success, options );
            } else {
                // diagram restored message to the other users
                return this.userLocator.getUserInfo( this.resultData.change.userId ).pipe(
                    take( 1 ),
                    tap( userData => {
                        const options = {
                            inputs: {
                                description: this.translate.instant( 'NOTIFICATIONS.DIAGRAM.RESTORED', {
                                    username: userData.firstName,
                                }),
                                user: userData,
                                autoDismiss: true,
                                dismissAfter: 3000,
                            },
                        };
                        this.notifierController.show(
                            'DIAGRAM_RESTORED', AbstractNotification, NotificationType.Neutral, options );
                    }),
                );
            }
        }
        return of({});
    }

}

Object.defineProperty( RestoreDocumentChange, 'name', {
    value: 'RestoreDocumentChange',
});
