/**
 * Folder type view types enum
 */
export enum FOLDER_PANEL_VIEW_TYPE {
    CATEGORY = 'category',
    PROJECT = 'project',
}

export enum FOLDER_PANEL_SCREEN_SIZE {
    FULL_SCREEN = 'full_screen',
    SIDE_SCREEN = 'side_screen',
    NO_SCREEN = 'no_screen',
}
