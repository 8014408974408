import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, Command, ImageLoader, NotifierController, StateService } from 'flux-core';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EcoreObjectExporter } from '../export/ecore-object-exporter';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { AbstractDiagramExportCommand } from './abstract-diagram-export-command.cmd';

/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsCode extends AbstractDiagramExportCommand {

    public static get dataDefinition(): {}  {
        return {
            type: true,
        };
    }

    public constructor(
        ll: DiagramLocatorLocator,
        resources: ImageLoader,
        notifierController: NotifierController,
        translate: TranslateService,
        state: StateService<any, any>,

        private ecoreExporter: EcoreObjectExporter,
    ) {
        super( ll, resources, notifierController, translate, state )/* istanbul ignore next */;
    }

    /**
     * Prepare the svg image data to be exported.
     */
    public execute() {
        return this.fetchCodeFromServer( this.data.type ).pipe(
            tap(( data: any ) =>
                this.data = {
                    content: data.blob,
                    name: data.name + '.zip',
                    type: 'application/zip',
                    format: 'blob',
                }),
            switchMap(() => super.execute()),
        );
    }

    protected fetchCodeFromServer( type: 'java' | 'mysql' ) {
        return this.ecoreExporter.exportEcoreModel( true ).pipe(
            switchMap(( data: any ) => from(
                fetch( AppConfig.get( 'CODE_GENERATOR_URL' ), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        type,
                        model: data.data,
                    }),
                })).pipe(
                    switchMap( response => from( response.blob())),
                    map( blob => ({
                        blob,
                        name: data.name,
                    })),
                ),
            ),
        );
    }

}

Object.defineProperty( ExportAsCode, 'name', {
    value: 'ExportAsCode',
});
