import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceScreen } from './../system/device/device-screen.svc';

/**
 * LandscapeClass
 * This directive is used to add a class 'landscape' to its
 * HTML element when the screen is landscape and remove it
 * when the screen is portrait.
 *
 * @author  nuwanthi
 * @since   29-09-2016
 */

@Directive({
    selector: '[landscapeClass]',
})

export class LandscapeClass implements OnInit, OnDestroy {

    @Input( 'landscapeClass' )
    protected className: string;

    protected element: Element;

    /**
     * Subscription which detects the orientation change
     */
    protected _orientationChangeSub: Subscription;

    constructor( protected elementRef: ElementRef, protected screen: DeviceScreen ) {
        this.element = elementRef.nativeElement;

        this._orientationChangeSub = this.screen.orientationChange.subscribe(
            () => this.toggleLandscapeClass(),
        );
    }

    public ngOnInit() {
        this.toggleLandscapeClass();
    }

    /**
     * Angular Component distruction hook
     * Clean all subscriptions created in this component in this hook.
     */
    public ngOnDestroy() {
        this.cleanOrientationChangeSub();
    }

    /**
     * Disposes the orientationChange subscription
     */
    protected cleanOrientationChangeSub() {
        if ( this._orientationChangeSub ) {
            this._orientationChangeSub.unsubscribe();
        }
    }

    protected toggleLandscapeClass() {
        if ( this.screen.isLandscape ) {
            this.element.classList.add( this.className );
        } else {
            this.element.classList.remove( this.className );
        }
    }

}
