import {
    ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, EventEmitter,
    Injector, ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { DynamicComponent } from '../dynamic.cmp';
import { CollapsibleMenu, ICollapsibleMenuItem } from './collapsible-menu';

/**
 * Collapsible Menu Item which has a header and the content of a single panel.
 * Header displays the title and the icons. Content consists of custom
 * components.
 *
 * NOTE: This component needs to be used for cases when there is necessity to dynamically
 * build component otherwise please use "app-collapisble-menu-item" which is very light weight
 *
 * @author  gobiga
 * @since   2017-11-09
 */
export abstract class AbstractCollapsibleMenuItem extends DynamicComponent {

    /**
     * Subjec to enable and disbale the title editing
     */
    public titleEditable: BehaviorSubject<boolean>;

    /**
     * Icon to be shown on header
     */
    public icon: string;


    /**
     * Event emitter to emit when click event fires on
     * the header element.
     */
    public abstract toggleChild: EventEmitter<any>;

     /**
      * Event emitter to emit when click event fires on
      * remove button.
      */
    public abstract removeChild: EventEmitter<any>;

    /**
     * This holds the instance of the component panel that is rendered on the container child
     */
    public menuItem: ICollapsibleMenuItem;

    /**
     * If true enable close button to remove the component from parent panel
     */
    public abstract removable: boolean;

    /**
     * Determine whether menu item is collapsable
     */
    public collapsable: boolean = true;

    /**
     * This holds a reference to the component element
     */
    protected abstract componentContainer: ViewContainerRef;

    /**
     * Holds the property to show/hide the collapsible
     * component.
     */
    protected _isOpen: boolean = true;

    /**
     * Holds the value of the parent.
     */
    protected _parent: CollapsibleMenu;

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef,
        public translate: TranslateService,
    ) {
        super( componentFactoryResolver, injector );
        this.titleEditable = new BehaviorSubject( false );
    }

    /**
     * A getter that returns the value for isOpen.
     */
    public get active(): boolean {
        return this._isOpen;
    }

    public set parent( instance: CollapsibleMenu ) {
        this._parent = instance;
    }

    /**
     * Title to be shown on header
     */
    public get title(): string {
        if ( this.menuItem ) {
            return this.menuItem.title;
        }
    }

    /**
     * A setter to set isOpen to a given value.
     */
    protected set isOpen( val ) {
        this._isOpen = val;
        this.cdr.detectChanges();
    }

    /**
     * Creates an instance of an menu item and append it to the end of the menu
     * @param type  The type of the component to create.This should be a ICollapsibleMenuItem Component class.
     * @param inputs    An object with all the inputs that should be set to the
     *      type component instance. Object keys should have the same name as the input field.
     */
    public add( type: any, inputs?: { [inputName: string]: any }) {
        const menuItemCmpRef: ComponentRef<any> = this.createComponent( type, this.componentContainer );
        // console.log( 'AbstractCollapsibleMenuItem inputs', inputs );
        this.menuItem = menuItemCmpRef.instance;
        this.setInputs( this.menuItem, inputs );
        this.menuItem.parent = this;
    }

    /**
     * Expands the item.
     */
    public open(): Observable<any> {
        this.isOpen = true;
        return of();
    }

    /**
     * Close the item.
     */
    public close(): Observable<any> {
        this.isOpen = false;
        return of();
    }

    /**
     * Handles the click event on the header element.
     */
    public toggle( event?: MouseEvent ) {
        /* istanbul ignore next */
        if ( !this.collapsable ) {
            return;
        }
        if ( !( event.target as HTMLElement ).classList.contains( 'editable-label' )) {
            this.toggleChild.emit( this );
        }
    }

    /**
     * Handles the click event on the remove button.
     */
    public detach() {
        this.removeChild.emit( this );
    }

}
