import { Component, ChangeDetectionStrategy, ViewContainerRef, Inject } from '@angular/core';
import { NeutrinoConnection } from 'flux-connection';
import { ContainerEnv, DateFNS, StateService, AppConfig } from 'flux-core';
import { merge, of, fromEvent } from 'rxjs';
import { UIContextMenuController } from './editor/interaction/context-menu/ui-context-menu-controller';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter, mapTo } from 'rxjs/operators';
import { UserLocator } from 'flux-user';
import { ViewportIntersectionObserver } from './system/viewport-intersection-observer.svc';


/**
 * This is the base application component for the overall nucleus app.
 * This loads everything necessary for the viewer, editor and creator.
 *
 * For each part of the application module structure has been created
 * that define components and routes. Please refer each sub folder
 * structure to understand further
 *  - Base for the viewer and editor: ./base#BaseModule
 *  - Viewer: ./viewer#ViewerModule
 *  - Editor: ./editor#EditorModule
 *  - Creator: ./creator#CreatorModule
 *
 * @author hiraash
 * @since 2017-08-02
 */

@Component({
    selector: 'app-root',
    templateUrl: './app.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class App {

    /**
     * The number of seconds a user can be inactive before they are considered
     * idle.
     */
    protected idleTimeout: number = 300;

    /**
     * Constructor for the app component. Sets up the core components
     * and initializes the network.
     * @param connection
     * @param browserWindow
     */
    constructor( private connection: NeutrinoConnection,
                 @Inject( 'BrowserWindow' ) private browserWindow: Window,
                 protected menuController: UIContextMenuController,
                 protected viewContainerRef: ViewContainerRef,
                 protected translate: TranslateService,
                 protected env: ContainerEnv,
                 protected idle: Idle,
                 protected user: UserLocator,
                 protected state: StateService<any, any>,
                 protected viewportIntersectionObserver: ViewportIntersectionObserver,
                 protected keepAlive: Keepalive ) {
        this.setupTranslation();
        if ( AppConfig.get( 'APP_MODE' ) !== 'plugin' ) {
            this.initializeNetwork();
            this.initializeKeepAlive();
        }
        this.initializeAppStates();
        this.viewportIntersectionObserver.initialize();
        this.menuController.viewContainerRef = this.viewContainerRef;
        this.disableMouseWheelWindowZoom();
    }

    protected disableMouseWheelWindowZoom() {
        window.addEventListener( 'wheel', event => {
            // NOTE
            // This condition is to check if the wheel event is zooming the window
            // event.ctrlKey becomes true for pinch zoom reagrdless of the control key being pressed or not.
              if ( event.ctrlKey ) {
                event.preventDefault();
              }
        }, { passive: false });
    }

    /**
     * Sets up the default language and the current language to be used.
     */
    protected setupTranslation() {
        const defaultLang = 'en';
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang( defaultLang );

        // check language preference of the user
        this.user.getUserData().pipe(
            filter( userModel => !!userModel ),
        ).subscribe( usr => {
            if ( usr.preferences && usr.preferences.language_preference ) {
                this.translate.use( usr.preferences.language_preference );
                return;
            }
        });
        // NOTE: When adding a new language here, make sure to add the language to DateFNS translations in the
        // DateFNS class. If not, date time string translations will fall back to English.
        this.translate.addLangs([ 'en', 'ar', 'de', 'es', 'fr', 'id', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru',
        'si', 'tr', 'vi', 'zh', 'he', 'ms', 'el' ]);
        for ( const elem of this.translate.getLangs()) {
            if ( elem === this.env.currentLang  ) {
                this.translate.use( this.env.currentLang )
                .subscribe(() => {
                    DateFNS.setLocale( this.env.currentLang );
                });
                return;
            }
        }
        this.translate.use( defaultLang );
    }

    /**
     * Initializes the networks and starts listening to network connect and
     * disconnect events.
     */
    protected initializeNetwork() {
        merge(
            of( this.browserWindow.navigator.onLine ),
            fromEvent( this.browserWindow, 'online' ).pipe( mapTo( true )),
            fromEvent( this.browserWindow, 'offline' ).pipe( mapTo( false )),
        ).subscribe( status => {
            ( status ) ? this.connection.onNetworkConnect() : this.connection.onNetworkDisconnect();
        });
    }

    /**
     * Manages sending keep alive messages to the server.
     *
     * Keep alive messages are only sent when the user is active and
     * using the application. There is a predefined interval between
     * keep alive messages. No messages will be sent when the user is
     * inactive - however they will immediately start begin sending as
     * soon as the user becomes active again.
     *
     * User activity is detected by listening to touches, scrolls and clicks
     * on the document and window.
     *
     * User idle detection and keep alive is managed using ng2-idle
     * library. For more info please refer to https://github.com/HackedByChinese/ng2-idle
     */
    protected initializeKeepAlive() {
        this.idle.setIdle( this.idleTimeout );
        this.idle.setTimeout( false );
        this.idle.setInterrupts( DEFAULT_INTERRUPTSOURCES );
        this.keepAlive.interval( this.idleTimeout );
        this.keepAlive.onPing.subscribe(() => this.connection.keepAlive());
        this.idle.watch();
    }

    /**
     * Initialize application level states
     *
     */
    protected initializeAppStates() {
        this.state.initialize( 'RightSidebarPanel', 'none' );
        this.state.initialize( 'SelectedLeftPanel', 'none' );
    }

}
