import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * This does validation of tags data items.
 * Data item class for the DATE data type. Data items in shape models with the DATE
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class DateDataItem extends AbstractDataItem<DataType.DATE> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof DateDataItem.prototype.value ): IValidationError {
        if ( value === undefined ) {
            return null;
        }
        if ( typeof value === 'number' ) {
            return null;
        }
        return { message:  `"${value}" is not a valid number` };
    }
}
