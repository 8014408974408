import { CommandEvent, CommandMapper } from 'flux-core';
import { UpdateUserPreference } from './update-user-preference';
import { UpdateUserPermission } from './update-user-permission.cmd';
import { FetchUserOnboardingStatus } from './fetch-user-onboarding-status.cmd';
import { UpdateUserOnboardingStatus } from './update-user-onboarding-status.cmd';
import { UpdateUserSeenMedia } from './update-user-seen-media.cmd';
import { UpdateUserSeenTooltips } from './update-user-seen-tooltip.cmd';
import { GetOrgUserInfo } from './get-org-user-info.cmd';

/**
 * UserCommandEvent
 */
// tslint:disable: member-ordering
export class UserCommandEvent extends CommandEvent {

    public static updateUserPreference = new UserCommandEvent( 'UpdateUserPreference' );
    protected static registerUpdateUserPreference( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.updateUserPreference )
            .add( UpdateUserPreference as any );
    }

    public static updateUserPermission = new UserCommandEvent( 'UpdateUserPermission' );
    protected static registerUpdateUserPermission( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.updateUserPermission )
            .add( UpdateUserPermission as any );
    }

    public static fetchUserOnboardingStatus = new UserCommandEvent( 'FetchUserOnboardingStatus' );
    protected static registerFetchUserOnboardingStatus( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.fetchUserOnboardingStatus )
            .add( FetchUserOnboardingStatus as any );
    }

    public static updateUserOnboardingStatus = new UserCommandEvent( 'UpdateUserOnboardingStatus' );
    protected static registerUpdateUserOnboardingStatus( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.updateUserOnboardingStatus )
            .add( UpdateUserOnboardingStatus as any );
    }

    public static updateUserSeenMedia = new UserCommandEvent( 'UpdateUserSeenMedia' );
    protected static registerUpdateUserSeenMedia( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.updateUserSeenMedia )
            .add( UpdateUserSeenMedia as any );
    }

    public static updateUserSeenTooltips = new UserCommandEvent( 'UpdateUserSeenTooltips' );
    protected static registerUpdateUserSeenTooltips( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.updateUserSeenTooltips )
            .add( UpdateUserSeenTooltips as any );
    }

    public static getOrgUserInfo = new UserCommandEvent( 'GetOrgUserInfo' );
    protected static registerGetOrgUserInfo( mapper: CommandMapper ) {
        mapper.map( UserCommandEvent.getOrgUserInfo )
            .add( GetOrgUserInfo as any );
    }
}
