import {
    Component,
    ChangeDetectionStrategy,
    AfterViewInit,
    OnDestroy,
    Input,
    ViewChild,
    ChangeDetectorRef,
    ViewRef } from '@angular/core';
import * as Dashboard from '@uppy/dashboard';
import * as Uppy from '@uppy/core';
import { AppConfig, CommandService, ModalController, Tracker } from 'flux-core';
import { ImportedFile, FileImportTypes } from '../../../framework/file/imported-file';
import { merge } from 'lodash';
import { Animation } from 'flux-core/src/ui';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { filter, tap } from 'rxjs/operators';
import { KeyCode } from 'flux-definition';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';


/**
 * This is a component that will manage the ui workflow of all kinds of file imports.
 * It currently allows users to choose which files to import through the native file
 * chooser (or allows users to drag drop or paste files) and outsputs the data of
 * imported files. This component uses Uppy (www.uppy.io) for this functionality.
 *
 * The options and restrictions to customize the nature of the file import
 * can be inputted to the component via {@link IFileImportOptions}. Options to
 * customize the file importer ui can be passed in via the uiImport input as
 * {@link IFileImportUIOptions}.
 * Users of this component will be notified of file upload completion via the
 * callback function passed into the component. This function will be invoked
 * with data for files that were just imported in the form of {@link IImportedFile}.
 *
 * @author  Ramishka
 * @since   2019-01-25
 */
@Component({
    templateUrl: './file-importer.cmp.html',
    selector: 'file-importer',
    styleUrls: [ 'file-importer.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FileImporter implements AfterViewInit, OnDestroy {

    /**
     * Import options and restrictions that can customize the nature of
     * the file import.
     */
    @Input()
    public importOptions: IFileImportOptions;

    /**
     * Options that can be used to customized the user interface of the file
     * import component.
     */
    @Input()
    public uiOptions: IFileImportUIOptions;

    /**
     * Shape image data, use for preview the shape image
     * and remove and replace with default image
     */
    @Input()
    public shapeImage?: IShapeImageData;

    /**
     * The function that will be invoked when the file import has completed.
     * This function is invoked with data for all imported files.
     * It needs to be able to accept an Array of {@link IImportedFile}.
     */
    @Input()
    public onComplete: Function;

    @Input()
    public onClose: Function = (() => {});

    @Input()
    public imageImport?: boolean;

    @Input()
    public imageImportSection?: string;

    /**
     * The uppy instance that will manage the ui workflow.
     */
    protected uppy: any;

    /**
     * The window overlay.
     */
    @ViewChild( 'window' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner' ) protected containerInner;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription>;

    /**
     * Constructor
     */
    constructor (
        protected modalController: ModalController,
        protected translate: TranslateService,
        protected ref: ChangeDetectorRef,
        protected commandService: CommandService ) {
            this.subs = [];
    }

    /**
     * returns true if uppy instance has any image
     */
    public get isFilesUploded(): boolean {
        return this.uppy && this.uppy.getFiles().length > 0;
    }

    public get isImageImport(): boolean {
        return this.imageImport;
    }

    public get isFabImageImport(): boolean {
        return this.imageImportSection === 'fab-image-import';
    }

    /**
     * Returns an object with default import options
     * and restrictions for uppy.
     * If options are not explicityly passed in as components inputs,
     * defaults will be used.
     */
    private get defaultImportOptions(): any {
        return {
            autoProceed: false,
            allowMultipleUploads: false,
            restrictions: {
                maxFileSize: null,
                maxNumberOfFiles : null,
                minNumberOfFiles : null,
                allowedFileTypes : null,
            },
            onBeforeFileAdded: currentFile => {
                currentFile.name = currentFile.name.toLowerCase();
            },
            locale: {
                strings: {
                  browse: this.isImageImport ? this.translate.instant( 'FILE_IMPORTS.IMAGE_BROWSE' )
                  : this.translate.instant( 'FILE_IMPORTS.BROWSE' ),
                  dropPaste: this.isImageImport ? this.translate.instant( 'FILE_IMPORTS.IMAGE_DROP_PASTE' ) + ' %{browse}'
                  : this.translate.instant( 'FILE_IMPORTS.DROP_PASTE' ) + ' %{browse}',
                  uploadXFiles: {
                    0: this.translate.instant( 'FILE_IMPORTS.UPLOAD' ) + ( this.isFabImageImport ? '' : ' %{smart_count} '
                     + this.translate.instant( 'FILE_IMPORTS.FILE' )),
                    1: this.translate.instant( 'FILE_IMPORTS.UPLOAD' ) + ' %{smart_count} '
                     + this.translate.instant( 'FILE_IMPORTS.FILES' ),
                  },
                  cancel: this.translate.instant( 'FILE_IMPORTS.CANCEL' ),
                  xFilesSelected: {
                    0: '%{smart_count} ' + this.translate.instant( 'FILE_IMPORTS.FILE_SELECTED' ),
                    1: '%{smart_count} ' + this.translate.instant( 'FILE_IMPORTS.FILES_SELECTED' ),
                  },
                  exceedsSize: this.translate.instant( 'FILE_IMPORTS.EXCEED_SIZE' ),
                },
            },
        };
    }

    /**
     * Returns an object with default ui options for uppy dashboard
     * plugin.
     * If options are not explicityly passed in as components inputs,
     * defaults will be used.
     */
    private get defaultUIOptions(): any {
        return {
            inline : true,
            replaceTargetContent : false,
            target : '.uppy-container',
            note : '',
            height : 200,
            width: 600,
        };
    }

    /**
     * Click handler for uppy container click
     */
    public onContainerClick( e ) {
        Tracker.track( 'dialog.image.import.upload.click' );
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
    }

    /**
     * Sets up the uppy instance.
     * Renders the UI and starts listening to file import events.
     */
    public ngAfterViewInit() {
        if ( !( this.onComplete instanceof Function )) {
            throw new Error( 'A complete function must be defined when using the file importer.' );
        }
        const importOptions = merge ({}, this.defaultImportOptions, this.importOptions );
        const uiOptions = merge ({}, this.defaultUIOptions, this.uiOptions );
        this.uppy = this.getUppyInstance( importOptions );
        this.uppy.use( Dashboard, uiOptions ).run();
        const sub = merge(
            this.fadeAnimation( this.container.nativeElement, 1, 0 ).start(),
            this.showAnimation( this.containerInner.nativeElement ).start(),
        ).subscribe();
        this.subs.push( sub );
        this.uppy.on( 'complete', result => this.handleOnComplete( result ));
        this.uppy.on( 'file-added', () => !( this.ref as ViewRef ).destroyed && this.ref.detectChanges());
        this.uppy.on( 'file-removed', () => !( this.ref as ViewRef ).destroyed && this.ref.detectChanges());
        this.subs.push( this.onEscapeOrPaste().pipe( tap(() => this.closeWindow())).subscribe());

        if ( this.isFabImageImport ) {
            this.customStylingForFabImageImport();
            this.uppy.on( 'file-added', () => this.onFileAdded());
        }
    }

    /**
     * Closes the file import modal window
     */
    public closeWindow() {
        const sub = merge(
            this.fadeAnimation( this.container.nativeElement, 0, 1 ).start(),
            this.hideAnimation( this.containerInner.nativeElement ).start(),
        ).subscribe({ complete: () => {
            this.modalController.hide();
        }});
        this.subs.push( sub );
        this.onClose();
    }

    /**
     * remove shape image from ui
     */
    public removePreviewImage() {
        this.shapeImage.removed = true;
    }

    /**
     * Invoked when modal window is closed. Unregisters event listeners and
     * shuts down the uppy instance.
     */
    public ngOnDestroy() {
        /**
         * NOTE: Replace shape image from default shape image,
         * if user removed image and not added a new image
         */
        if ( !this.isFilesUploded && this.shapeImage && this.shapeImage.removed ) {
            this.removeShapeImage();
        }
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
        this.uppy.off( 'complete' );
        this.uppy.close();
    }

    /**
     * Retrieves the url of the image
     */
    public getImageUrl( hash: string ): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash;
    }

    /**
     * Returns the uppy instance.
     */
    protected getUppyInstance( importOptions: any ) {
        return Uppy( importOptions );
    }

    /**
     * This function is invoked when all files imports have completed.
     * @param result - object with arrays of successful and failed uploads.
     */
    protected handleOnComplete( result: any ) {
        this.onComplete( this.getFiles( result.successful ));
        this.closeWindow();
    }

    /**
     * Extracts all successful imported files and creates an
     * array of {@link IImportedFile}.
     */
    protected getFiles( files: Array<any> ): Array<ImportedFile> {
        const importedFiles = [];
        if ( files && files.length > 0 ) {
            files.forEach( uppyFile => {
                const importedFile = new ImportedFile( uppyFile.data );
                importedFile.importType = FileImportTypes.ImageImport;
                importedFiles.push( importedFile );
            });
        }
        return importedFiles;
    }


    /**
     * dispatch shape image command to remove the current shape image
     */
    protected removeShapeImage() {
        this.commandService.dispatch( DiagramCommandEvent.changeShapeImage, {
            position: { shapeId: this.shapeImage.shapeId, imageId: this.shapeImage.imageId },
            imageFile: { hash: this.shapeImage.defaultHash },
        });
    }

    /**
     * Returns an observable that emits for the Escape key down
     * and cmd + v/paste.
     */
    protected onEscapeOrPaste(): Observable<any> {
        return fromEvent( window, 'keydown' )
            .pipe( filter(( event: KeyboardEvent ) =>
                event.keyCode === KeyCode.Escape ||
                (( event.metaKey || event.ctrlKey )
                && event.keyCode === KeyCode.V ),
            ));
    }

    /**
     * Animation that shows the window.
     */
    protected showAnimation( element ): Animation {
        const transition = new Animation({
            from: { transform: 'translateY(30px)', opacity: '0' },
            to: { transform: 'translateY(0)', opacity: '1' },
            transitionProperty: 'transform, opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }

    /**
     * Animation that hides the window.
     */
    protected hideAnimation( element ): Animation {
        const transition = new Animation({
            from: { transform: 'translateY(0)', opacity: '1' },
            to: { transform: 'translateY(30px)', opacity: '0' },
            transitionProperty: 'transform, opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }

    /**
     * Animation that is used to fade in/out the overlay.
     */
    protected fadeAnimation( element, to, from ) {
        const transition = new Animation({
            from: { opacity: from },
            to: { opacity: to },
            transitionProperty: 'opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }

    protected customStylingForFabImageImport(): void {
        document.querySelector<HTMLElement>( '.uppy-Dashboard-dropFilesTitle' ).style.cssText = `
            color: ##88A1AA !important;
            font-size: 11px !important;
            line-height: 13px !important;
            font-style: normal !important;
            font-weight: 400 !important;
        `;
        document.querySelector<HTMLElement>( '.uppy-Dashboard-browse' ).style.cssText = `
            height: 36px !important;
            margin-left: 6px !important;
            padding-inline: 35px 6px !important;
            background-color: #E1F1FB !important;
            background-image: url(./assets/images/fab/fab-upload-image.svg) !important;
            background-repeat: no-repeat !important;
            background-position: 6px center !important;
            color: #1381CD !important;
            font-size: 11px !important;
            line-height: 13px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            border: none !important;
            border-radius: 4px !important;
        `;
    }

    protected onFileAdded() {
        setTimeout(() => {
            const backButton = document.querySelector( '.uppy-DashboardContent-back' );
            const statusBarActions = document.querySelector( '.uppy-StatusBar-actions' );

            if ( backButton && statusBarActions ) {
                statusBarActions.appendChild( backButton );
                backButton.setAttribute( 'style', 'opacity: 1' );
            }
        }, 500 );
    }
}

/**
 * This interface defines various options that can be used to customize a file
 * import in uppy.
 */
export interface IFileImportOptions {

    /**
     * Whether to allow multiple uploads or not.
     * Default is false.
     */
    allowMultipleUploads?: boolean;

    /**
     * Options that can be used to manage various import restrictions.
     */
    restrictions?: {

        /**
         * Maximum file size in bytes for each individual file.
         * Default is unlimited.
         */
        maxFileSize ?: number,

        /**
         * The maximum number of files that can be selected.
         * Default is no limit.
         */
        maxNumberOfFiles ?: number,

        /**
         * Minimum number of files that needs to be selected for import to continue.
         * Default is no limit..
         */
        minNumberOfFiles ?: number,

        /**
         * A list of allowed file types.
         * This can be
         *  - an array of wildcards i.e image/*,
         *  - exact mime types i.e. image/jpeg,
         *  - file extensions .jpg.
         * Example: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
         * Default is all types.
         */
        allowedFileTypes ?: Array<string>,
    };
}

export interface IFileImportUIOptions {

    /**
     * The width of the file importer window.
     * If not specified, will enlarge to fit a larger portion of the viewport.
     */
    width ?: number;

    /**
     * The height of the file importer window.
     * If not specified, will enlarge to fit a larger portion of the viewport.
     */
    height ?: number;

    /**
     * The footer note to be displayed on the import dialog.
     */
    note ?: string;
}

export interface IShapeImageData {

    /**
     * shape Id which has shape image
     */
    shapeId: string;

    /**
     * shape image id
     */
    imageId: string;

    /**
     * current shape image hash
     */
    hash: string;

    /**
     * default shape image hash
     */
    defaultHash: string;

    /**
     * use to remove preview image from UI
     */
    removed: boolean;
}
