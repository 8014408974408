import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

/**
 * RenameShape
 * Updates the shape's name.
 */
@Injectable()
@Command()
export class RenameShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        shapeId: string,
        name: string,
    };

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        this.changeModel.shapes[ this.data.shapeId ].name = this.data.name.trim();
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( RenameShape, 'name', {
    value: 'RenameShape',
});
