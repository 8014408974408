import { Injectable } from '@angular/core';
import { Command, AbstractCommand, StateService } from 'flux-core';
import { RESET_PREVIEW } from 'flux-diagram-composer';

/**
 * ResetDiagramPreview
 */
@Injectable()
@Command()
export class ResetDiagramPreview extends AbstractCommand {
    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public execute() {
        this.state.set( 'PreviewChanges', RESET_PREVIEW );
        return true;
    }
}

Object.defineProperty( ResetDiagramPreview, 'name', {
    value: 'ResetDiagramPreview',
});
