import { ConnectorRenderView } from '../../shape/view/connector-render-view';
import { GraphicsSVG } from '../graphics/svg-graphics';
import { IConnectorText } from 'flux-definition';
import { ConnectorSVGTextView } from './svg-connector-text-view';
import * as md5 from 'md5';

export class ConnectorSVGView extends ConnectorRenderView {
    protected _graphics: GraphicsSVG;

    /**
     * The svg output of the connector view
     * Idealy, this function should return a SVGGElement so further modifications,appends and usage
     * will be mush easier. It returns a string for the moment due to lack of functionality support
     * on a NodeJS environment. JSDom may be a good candidate to fix this problem on a non-browser
     * env but still it do not have a working implementation to get it working.
     *
     * If need to return a SVGGElement, make sure the functionality can be performed on a non-browser env
     * like NodeJS.
     * @override
     */
    public get renderedShape(): string {
        let svg = '';
        let textElement = '';
        let textMaskContent = '';
        if ( this._texts.length > 0 ) {
            this._texts.forEach( textView => {
                const view: ConnectorSVGTextView = textView as ConnectorSVGTextView;
                textMaskContent += view.svgTextMaskContent;
                textElement += view.svgTextElement;
            });
            const maskId = this.generateMaskId( this.model.id );
            svg += this.createConnectorMask( textMaskContent, maskId );
            svg += `<g mask="url(#${maskId})">${this._graphics.toSvgString()}</g>`;
            svg += textElement;
        } else {
            svg = `<g>${this._graphics.toSvgString()}</g>`;
        }
        return `<g id="${this.model.id}">${svg}</g>`;
    }

    /**
     * The svg graphics instance associated with this connector.
     * @override
     */
    public get graphics(): GraphicsSVG {
        return this._graphics;
    }

    /**
     * Hook thats called after shape is created and added to the diagram
     * for the first time.
     */
    public initialize() {
        this._graphics = new GraphicsSVG();
    }

    /**
     * Create a svg text view for given text model
     */
    protected addText( text: IConnectorText ) {
        const textView = new ConnectorSVGTextView( text, this.model );
        this._texts.push( textView );
    }

    /**
     * Generate a short random string to be used as the mask id for the text
     */
    private generateMaskId( text: string ): string {
        return md5( text ).substring( 0, 5 );
    }

    /**
     * Create a mask covering the connector rectangle.
     * @param maskElements invisible pasts of the mask
     * @param maskId mask id to be used
     */
    private createConnectorMask( maskElements: string, maskId: string ): string {
        // Get the connector bounds
        const bounds = this.model.bounds.pad(( 2 * this.model.style.lineThickness ) + 10 );
        return `<mask id="${maskId}" x="${bounds.x}" y="${bounds.y}" ` +
        `width="${bounds.width}" height="${bounds.height}" maskUnits="userSpaceOnUse">` +
        `<rect x="${bounds.x}" y="${bounds.y}" ` +
        `width="${bounds.width}" height="${bounds.height}" fill="white" />` +
        maskElements +
        `</mask>`;
    }
}
