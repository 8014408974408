import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DiagramInfoModel } from '../model/diagram-info.mdl';
import { DiagramInfoModelStore } from '../../storage/diagram-info-model-store';
import { AddResourceGroupShare } from '../../group-share/command/add-resource-group-share.cmd';

/**
 * This is the command to add group share to diagram.
 * This command will add the pending group shares to the local storage and send
 * the request to the server.
 *
 * This extends {@link AddResourceGroupShare} to send a request to the server.
 * data: {
 *     groupShares - [{ id, role }]
 *     diagramId - id of the resource
 * }
 */
@Injectable()
@Command()
export class AddDiagramGroupShare extends AddResourceGroupShare  {

    protected getModelStore(): DiagramInfoModelStore {
        return this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore;
    }
}

Object.defineProperty( AddDiagramGroupShare, 'name', {
    value: 'AddDiagramGroupShare',
});
