<div #window
    class="fx-cover fx-center-all modal-window-container">
    <perfect-scrollbar>
        <div #windowInner class="modal-window-inner" [class.mappings-step]="(sidebarVisible | async) === false" *ngLet="selectedImportOption | async as selectedPanel">
            <div class="modal-window-heading">
                <h1 class="nu2-caption-large-bold window-heading fx-margin-bottom-15" translate>DATA_IMPORT.IMPORT_DATABASE</h1>
                <button class="nu-btn-small nu-btn-icon modal-window-close-btn" (click)="closeWindow()">
                    <svg class="nu-icon nu-icon-small nu-ic-close">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                    </svg>
                </button>
            </div>
            <div class="modal-window-content">
                <div class="horizontal-container full-height" *ngLet="selectedImportOption | async as selectedPanel">
                    <div [class.fx-hidden]="(sidebarVisible | async) === false" class="left-sidebar full-height">
                        <ul>
                            <li *ngFor="let dataImportOpt of dataImportOptions" 
                                [class.active]="dataImportOpt.id === selectedPanel"
                                (click)="selectedImportOption.next(dataImportOpt.id)">
                                <svg class="nu-icon nu-icon-med">
                                    <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + dataImportOpt.icon"></use>
                                </svg>
                                <span class="nu2-caption-3" >{{dataImportOpt.label}}</span>
                                <svg *ngIf="(hasData | async) && selectedPanel === dataImportOpt.id" class="nu-icon nu-icon-small fx-margin-left-10">
                                    <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-db-connected'"></use>
                                </svg>
                            </li>
                        </ul>
                    </div>
                    <div class="separator" [class.fx-hidden]="(sidebarVisible | async) === false"></div>
                    <div class="content-panel full-height">
                        <csv-data-import-panel *ngIf="selectedPanel === 'csv'"
                            class="full-height"
                            [importingInto]="importingInto"
                            [showSidebar]="sidebarVisible"
                            [hasData]="hasData"
                            [closeWindow]="closeWindowObs"></csv-data-import-panel>
                        <google-sheets-data-import-window *ngIf="selectedPanel === 'google_sheets'"
                            class="full-height"
                            [importingInto]="importingInto"
                            [showSidebar]="sidebarVisible"
                            [closeWindow]="closeWindowObs"></google-sheets-data-import-window>
                        <m365-excel-data-import-window *ngIf="selectedPanel === 'm365_excel'"
                            class="full-height"
                            [importingInto]="importingInto"
                            [showSidebar]="sidebarVisible"
                            [closeWindow]="closeWindowObs"></m365-excel-data-import-window>
                        <div class="coming-soon-container fx-margin-top-15" *ngIf="selectedPanel !== 'csv' && selectedPanel !== 'google_sheets'">
                            <div class="info-container fx-margin-bottom-25" *ngIf="selectedPanel === 'azure_devops'">
                                <div class="info-heading">                            
                                    <h1 class="nu2-heading-6 fx-margin-bottom-15" translate>LABELS.COMING_SOON_EX</h1>
                                    <img src="./assets/images/data-import/data-source.svg" />
                                </div>
                                <ul class="nu2-body-small">
                                    <li><span translate>AZURE_DEVOPS_IMPORT.INFO.LIST_ITEM_1</span></li>
                                    <li><span translate>AZURE_DEVOPS_IMPORT.INFO.LIST_ITEM_2</span></li>
                                    <li><span translate>AZURE_DEVOPS_IMPORT.INFO.LIST_ITEM_3</span></li>
                                </ul>
                            </div>
                            <div class="info-container fx-margin-bottom-25" *ngIf="selectedPanel === 'jira'">
                                <div class="info-heading">                            
                                    <h1 class="nu2-heading-6 fx-margin-bottom-15" translate>LABELS.COMING_SOON_EX</h1>
                                    <img src="./assets/images/data-import/data-source.svg" />
                                </div>
                                <ul class="nu2-body-small">
                                    <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_1</span></li>
                                    <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_2</span></li>
                                    <li><span translate>JIRA_IMPORT.INFO.LIST_ITEM_3</span></li>
                                </ul>
                            </div>
                            <div class="info-container fx-margin-bottom-25" *ngIf="selectedPanel === 'github'">
                                <div class="info-heading">                            
                                    <h1 class="nu2-heading-6 fx-margin-bottom-15" translate>LABELS.COMING_SOON_EX</h1>
                                    <img src="./assets/images/data-import/data-source.svg" />
                                </div>
                                <ul class="nu2-body-small">
                                    <li><span translate>GITHUB_IMPORT.INFO.LIST_ITEM_1</span></li>
                                    <li><span translate>GITHUB_IMPORT.INFO.LIST_ITEM_2</span></li>
                                    <li><span translate>GITHUB_IMPORT.INFO.LIST_ITEM_3</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</div>
