// tslint:disable no-string-literal
/**
 * This is the command that flags the tooltips seen by the user
 */

import { Inject, Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import {
    Command,
    CommandError,
    CommandInterfaces,
    StateService,
} from 'flux-core';
import { DataStore } from 'flux-store';
import { UserModel } from '../model/user.mdl';
import { take, map, tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

@Injectable()
@Command()
export class UpdateUserSeenTooltips extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {
            newTooltipId: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IUserCommand' ];
    }

    constructor(
        protected dataStore: DataStore,
        @Inject( StateService ) protected state: StateService<any, any>,
    ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Preserve previous data to revert in case of error.
     */
    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( UserModel );
        return modelStore.findOne({ id: this.state.get( 'CurrentUser' ) }).pipe(
            take( 1 ),
            map( user => ( user?.seenTooltips ? user.seenTooltips : undefined )),
            tap( tooltipIds => {
                this.previousData.tooltipIds = tooltipIds;
            }),
        );
    }

    /**
     * execute
     * NOTE:
     * there's a known issue where an error `Failed to execute 'put' on 'IDBObjectStore'`
     * is thrown by modelStore.update for certain users. Catching the error here will
     * prevent the command execution from being aborted. Requires further investigation.
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        const prevIds = this.previousData.tooltipIds || {};
        setter.$set['seenTooltips'] = { ...prevIds, [this.data.newTooltipId]: [ true ]};
        return from(
            modelStore.update(
                {
                    id: this.state.get( 'CurrentUser' ),
                },
                setter,
            ).catch(() => {}),
        );
    }

    /**
     * onError
     */
    public onError( err: CommandError ): any {
        const modelStore = this.dataStore.getModelStore( UserModel );
        const setter = { $set: {}};
        setter.$set['seenTooltips'] = this.previousData.tooltipIds;
        return from(
            modelStore.update({ id: this.state.get( 'CurrentUser' ) }, setter ),
        );
    }
}

Object.defineProperty( UpdateUserSeenTooltips, 'name', {
    value: 'UpdateUserSeenTooltips',
});
