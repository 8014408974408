import { BoundsPosition, Rectangle } from 'flux-core';
import { Animation } from 'flux-core/src/ui';
import { Observable, fromEvent } from 'rxjs';
import { Component, ChangeDetectionStrategy, Output, Injector,
    ComponentFactory, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewContainerRef, ComponentRef, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IContextMenuItem } from '../../../framework/ui/interaction/context-menu/context-menu-item.i';
import { filter, switchMap, tap, delay } from 'rxjs/operators';

interface IElementOffsets {
    isOutsideViewport: boolean;
    offsets: { top: number, left: number, bottom: number, right: number };
}

/**
 * ContextMenu
 * This class provides functionality to creates the context menu and shows/renders it
 * on the given view container and also listen for the mousedown event outside the menu to destroys the menu
 * compoennt.
 *
 * @author gobiga
 * @since 2017-12-14
 */

@Component({
    selector: 'context-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="cm-container">
            <ul class="cm-inner">
                <li class="cm-item btn-free-size btn-list-item" *ngFor="let item of items; let i = index"
                    (mousedown)="menuItemclick.emit({ item: item, event: $event })">
                    <svg class="nu-icon nu-icon-small fx-margin-right-10" *ngIf="item.icon">
                        <use attr.xlink:href="./assets/icons/symbol-defs.svg#{{item.icon}}"></use>
                    </svg>
                    <toggle-button *ngIf="item.switch !== undefined" [checked]="item.switchSubject | async">
                    </toggle-button>
                    <span class="body">{{item.label}}</span>
                    <label *ngIf="item.shortcutText !== ''" class="caption">{{item.shortcutText}}</label>
                </li>
          </ul>
        </div>`,
    styleUrls: [ './context-menu.scss' ],
})
export class ContextMenu implements AfterViewInit, OnDestroy {

    /**
     * This method creates a context menu and returns the menu itself.
     */
    public static create( injector: Injector, items: any[]): ContextMenu {
        const componentFactoryResolver: ComponentFactoryResolver = injector.get( ComponentFactoryResolver );
        const componentFactory: ComponentFactory<any> = componentFactoryResolver.resolveComponentFactory( ContextMenu );
        const contextMenuRef: ComponentRef<any> =  componentFactory.create( injector );
        const contextMenu: ContextMenu = contextMenuRef.instance;
        contextMenu.componentRef = contextMenuRef;
        contextMenu.items = items;
        return contextMenu;
    }

    /**
     * Items that needs to be added to the menu.
     */
    public items: IContextMenuItem[];

    /**
     * Event emitter to emit when click event fires on
     * menu item
     */
    @Output()
    public menuItemclick: EventEmitter<{ item: IContextMenuItem, event: MouseEvent }>;

    /**
     * This holds the reference of the component
     */
    protected componentRef: ComponentRef<any>;

    /**
     * The element that contains the context menu
     */
    protected element: HTMLElement;

    /**
     * The list of subscriptions held by this class
     */
    protected interactionSub: Subscription[] = [];

    protected elementOffsets: IElementOffsets;

    constructor( elem: ElementRef ) {
        this.element = elem.nativeElement;
        this.menuItemclick = new EventEmitter();
        const clickSub = this.listenForOutSideClick().subscribe();

        this.interactionSub.push( clickSub );
    }

    public ngAfterViewInit() {
        this.elementOffsets = this.checkElementOffsets( this.element );
      }

    public ngOnDestroy() {
        while ( this.interactionSub.length > 0 ) {
            this.interactionSub.pop().unsubscribe();
        }
     }

    /**
     * This render the context menu into the given view container and position it based on the
     * given container element bounds.
     *
     * @param vcrf - view container ref of the element where the context menu needs to be inserted
     * @param x - x position related to the given element bounds
     * @param y - y position related to the given element bounds
     */
    public show( vcrf: ViewContainerRef, x: number = 0, y: number = 0 ): Observable<any> {
        vcrf.insert( this.componentRef.hostView );
        this.componentRef.changeDetectorRef.markForCheck();
        const transition = this.showMenu( this.componentRef );
        this.position( x, y, vcrf.element.nativeElement );
        return transition.start();
    }

    /**
     * This destroys the context menu component instance.
     */
    public destroy(): Observable<any> {
        return this.hideMenu( this.componentRef ).start().pipe(
            // FIX ME: The delay added in this destroy event because of a race condition between,
            // hiding the context menu and the context menu item click event. If the context menu
            // hides/destroys before the click event fires, then the command will not be executed.
            delay( 50 ),
            tap({
                complete: () => this.componentRef.destroy(),
            }),
        );
    }

    /**
     * Place the context menu based on the given element bounds.
     *
     * @param x x position relative to the given elem bounds
     * @param y y position relative to the given elem bounds
     * @param elem The element where this contextmenu element needs to be placed.
     */
    protected position( x: number, y: number, elem: HTMLElement ) {
        this.element.style.position = 'absolute';
        const position = BoundsPosition.create( this.element );
        const relativePoint = position.getPoint( Rectangle.fromClientRect( elem.getBoundingClientRect()), x, y );
        this.element.style.opacity = '0';
        this.element.style.left =  x + relativePoint.x + 'px';
        this.element.style.top = y + relativePoint.y + 'px';
        // Fix position if element is outside the viewport
        // Timeout added to wait for the menu to be positioned initially
        setTimeout(() => {
            if ( this.elementOffsets && this.elementOffsets.isOutsideViewport ) {
                if ( this.elementOffsets.offsets.left > 0 || this.elementOffsets.offsets.right > 0 ) {
                    x += this.elementOffsets.offsets.left - this.elementOffsets.offsets.right - 16;
                    this.element.style.left =  x + relativePoint.x + 'px';
                }

                if ( this.elementOffsets.offsets.top > 0 || this.elementOffsets.offsets.bottom > 0 ) {
                    y += this.elementOffsets.offsets.top - this.elementOffsets.offsets.bottom - 16;
                    this.element.style.top = y + relativePoint.y + 'px';
                }
            }
            this.element.style.opacity = '1';
        }, 100 );
    }

    /**
     * Checks offsets for the given element and returns the offsets &
     * whether the element is outside the viewport
     * @param element
     */
    protected checkElementOffsets( element: HTMLElement ): IElementOffsets {
        const rect = element.getBoundingClientRect();

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const offsets = {
            top: Math.min( 0, rect.top ),
            left: Math.min( 0, rect.left ),
            bottom: Math.max( 0, rect.bottom - viewportHeight ),
            right: Math.max( 0, rect.right - viewportWidth ),
        };

        const isOutsideViewport = offsets.top < 0 || offsets.left < 0 || offsets.bottom > 0 || offsets.right > 0;

        return { isOutsideViewport: isOutsideViewport, offsets };
    }


    /**
     * This listens for the mousedown event outside of the context menu to destroy the
     * the menu component.
     */
    protected listenForOutSideClick(): Observable<any> {
        return fromEvent( document, 'mousedown' ).pipe(
            filter(( event: MouseEvent ) => event.button !== 2  ),
            switchMap(() => this.destroy()),
        );
    }

    /**
     * Animate the given component by setting the opacity and return
     * the animation
     */
    protected hideMenu( cmp: ComponentRef<any> ): Animation {
        const transition = new Animation({
            from: { opacity: 1 },
            to: { opacity: 0 },
            transitionProperty: 'opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = cmp.location.nativeElement.firstElementChild;
        return transition;
    }

    /**
     * Animate the given component by setting the opacity to 1 and
     * return observable
     */
    protected showMenu( cmp: ComponentRef<any> ): Animation {
        const transition =  new Animation({
            from: { opacity: 0.5 },
            to: { opacity: 1 },
            transitionProperty: 'opacity',
            duration: 50,
            easing: 'linear',
        });
        transition.element = cmp.location.nativeElement.firstElementChild;
        return transition;
    }

}
