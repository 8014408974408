import { Matrix } from 'flux-core';
// import { matrix } from 'raphael';

/**
 * Convert given svg transformation string to a Matrix.
 */
export class SvgTransformationToMatrix {

    /**
     * Return Matrix instance created from given transformation string
     * @param transformation
     */
    public static getMatrix( transformation: string ): Matrix {
        const matrix = new Matrix();
        const transformInstructions = transformation ? transformation.match( /[a-zA-Z][^\(]*\([^\)]*\)/g ) : [];
        // tslint:disable-next-line:cyclomatic-complexity
        transformInstructions.forEach( ins => {
            const type = ins.split( '(' )[0];
            const params = this.getSvgTransformationParameters( ins );
            switch ( type ) {
                case 'matrix':
                    matrix.append( params[0], params[1], params[2], params[3], params[4], params[5]);
                    break;

                case 'translate':
                    matrix.translate( params[0], params[1] ? params[1] : 0 );
                    break;

                case 'scale':
                    matrix.scale( params[0], params[1] ? params[1] : params[0]);
                    break;

                case 'rotate':
                    const angle = params[0] * Math.PI / 180;
                    const cos = Math.cos( angle );
                    const sin = Math.sin( angle );
                    const x = params[1] ? params[1] : 0;
                    const y = params[2] ? params[2] : 0;
                    matrix.append( cos, sin, -sin, cos, x, y );
                    matrix.append( 1, 0, 0, 1, -x, -y );
                    break;

                case 'skewX':
                    matrix.append( 1, 0, Math.tan( params[0] * Math.PI / 180 ), 1, 0, 0 );
                    // matrix.skew( params[0], 0 );
                    break;

                case 'skewY':
                    matrix.append( 1, Math.tan( params[0] * Math.PI / 180 ), 0, 1, 0, 0 );
                    // matrix.skew( 0, params[0]);
                    break;
            }
        });

        return matrix;
    }

    /**
     * Return matrix parameters from given marix string
     * @param paramString
     */
    private static getSvgTransformationParameters( paramString: string ): number[] {
        const paramArray = paramString
            .match( /[^\(]+(?=\))/g )
            .pop()
            .replace( /[,\s]+|[\s,]+|[\s]+/g, ',' )
            .split( ',' );
        const numArray = [];
        paramArray.forEach( value => {
            if ( value !== '' ) {
                numArray.push( +value );
            }
        });
        return numArray;
    }
}
