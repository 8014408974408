import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs';
@Injectable()
@Command()
export class DeleteEData extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IProjectCommand' ];
    }

    /**
     * Input data format for the command
     */
    public data:  {
        modelType: any,
    };

    constructor( protected dataStore: DataStore, protected modelSubManager: ModelSubscriptionManager ) {
        super() /* istanbul ignore next */;
    }

    /**
     * Markes the edata in cache as deleted.
     */
    public execute() {
        const modelStore = this.dataStore.getModelStore( this.data.modelType );
        if ( !this.resultData ) {
            this.resultData = {};
        }
        this.resultData.eDataId = this.resourceId;
        this.resultData.remove = true;
        return fromPromise( modelStore.updateStatic({ id: this.resourceId }, { $set: { deleted: true }}));
    }

    /**
     * Delete the edata from cache and stops edata subscription.
     */
     public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( this.data.modelType );
        this.modelSubManager.stop( this.resourceId, true );
        return fromPromise( modelStore.remove({ id: this.resourceId }));
    }

}

Object.defineProperty( DeleteEData, 'name', {
    value: 'DeleteEData',
});
