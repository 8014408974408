import { IAbstractViewDefinition } from 'flux-definition';
import { IGraphics } from 'flux-definition';
import { IGeometry } from 'flux-definition';
import { ConnectorDataModel, IConnectorPoint } from '../../../shape/model/connector-data-mdl';

/**
 * This is the interface that will be implemented by all connector views.
 * This defines how a connector looks like and defines how a connector is drawn.
 *
 * @author  Ramishka
 * @since   2017-09-23
 */
export interface IConnectorViewDefinition extends IAbstractViewDefinition {

    /**
     * The data model attached to the connector which contains all the connector
     * data and state. Contains the definition and any changes to that
     * definition. Any current information about a connector should be taken from
     * this.
     */
    model: ConnectorDataModel;
}

/**
 * This is a concrete implementation of the connector view definition.
 * This just declares the properties of the view definition but does
 * not implement any of them.
 * @author  Ramishka
 * @since   2017-10-13
 */
export class ConnectorViewDefinition implements IConnectorViewDefinition {
    /**
     * Refers to the IGraphics instance attached to the connector view
     */
    public graphics: IGraphics;

    /**
     * Returns the IGeometry instance which contains classes which can be
     * used to perform common geometric calculations.
     */
    public geometry: IGeometry;

    /**
     * Refers to the hitArea IGraphics instance attached to the connector view
     */
    public hitArea?: IGraphics;

    /**
     * The points to draw the connector.
     * Helps to draw the connector with modified points.
     */
    public points?: IConnectorPoint[];

    /**
     * Refers to the data model attached to the connector which contains all the connector
     * data and state.
     */
    public model: ConnectorDataModel;

    /**
     * This draws the connector as a shape
     */
    public draw() {
        // Must be implemented by extending class
    }
}
