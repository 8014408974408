import { ComplexType } from 'flux-core';
import { PlanModel } from '../../permission/model/plan.mdl';
import { UserInfoModel } from './user-info.mdl';

/**
 * UserPlanInfoModel
 * This is a user model that represents a given user.
 * Contains all details about a user. Extends {@link UserInfoModel}
 *
 * @author  Jerome
 * @since   26-10-2020
 */

export class UserPermInfoModel extends UserInfoModel {

  /**
   * This holds the plan and plan permission related details of this user.
   */
  @ComplexType()
  public plan: PlanModel;

  constructor( id: string ) {
      super( id );
  }
}

Object.defineProperty( UserPermInfoModel, 'name', {
  writable: true,
  value: 'UserPermInfoModel',
});
