import { Component, ChangeDetectionStrategy, ElementRef,
    Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap, filter, delay } from 'rxjs/operators';
import { StateService } from 'flux-core';
import { ILibraryItem } from '../panels/library-panel.cmp';

/**
 * This popover component is a popup box which will show up when library-tile being hover.
 * Thumbnail canvas has been used to show the thumbnail. If the component doesn't have space to display the popover,
 * it should adjust the content area to make space.
 *
 * Component inputs are handled by the state of CurrentLibraryPreview only. If the relevant data are set to state
 * popover will be shown with the data. When the state is empty popover will be hidden.
 *
 * @author Shermin
 * @since 2019-05-29
 */

@Component({
    selector: 'library-preview-popover',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<div #popoverOuter *ngIf="preparePopover | async"
                class="left-sidebar-popover-container {{this.popoverCssClass}}"
                [ngClass]="{'show': showPopover | async }">
                    <div class="lib-preview-tile-container fx-ellipsis" *ngIf="(libraryItemSubject | async) as item" >
                        <div class="lib-preview-thumbnail fx-center-all">
                            <thumbnail-image
                                *ngIf="item.thumbnailType==='image'"
                                class="shape-thumbnail-image"
                                [url]="item.thumbnailUrl"
                                [isPreview]="true">
                            </thumbnail-image>
                            <thumbnail-canvas
                                *ngIf="item.thumbnailType==='canvas'"
                                class="shape-thumbnail-image"
                                [defId]="item.data.defId"
                                [version]="item.data.version"
                                [style]="item.data.style"
                                [isPreview]="true"
                                [drawCode]="item.data.drawCode"
                                [typeStyle]="item.data.typeStyle"
                                [focus]="false"
                                canvasIdPrefix="thumb-preview">
                            </thumbnail-canvas>
                            <thumbnail-text
                                *ngIf="item.thumbnailType==='text'"
                                [shapetype]="item.data.defId"
                                [name]="item.text.title"
                                [itemInfo]="item.itemInfo"
                                [isPreview]="true"
                                [focus]="true"
                                canvasIdPrefix="thumb-preview">
                            </thumbnail-text>
                        </div>
                        <div *ngIf="this.text && item.thumbnailType!=='text'"
                            class="lib-preview-text body">
                                <p *ngIf="this.text.title">{{this.text.title}}<p>
                                <p *ngIf="this.text.line1">{{this.text.line1}}<p>
                                <p *ngIf="this.text.line2">{{this.text.line2}}<p>
                                <p *ngIf="this.text.line3">{{this.text.line3}}<p>
                                <p *ngIf="this.text.line4">{{this.text.line4}}<p>
                        </div>
                    </div>
                </div>`,
    styleUrls: [ './library-preview-popover.scss' ],
})
export class LibraryPreviewPopover implements OnInit, OnDestroy {

    /**
     * Text info of the shape/image
     */
    public text: {
        title?: string,
        line1?: string,
        line2?: string,
        line3?: string,
        line4?: string,
    };

    /**
     * Specifies if the popover is open or closed.
     */
    public showPopover = new BehaviorSubject( false );

    /**
     * Prepare the popover with the position.
     */
    public preparePopover = new BehaviorSubject( false );

    /**
     * Subject to update preview item values
     */
    public libraryItemSubject: BehaviorSubject<ILibraryItem> ;

    /**
     * This defines the css class of the popover container.
     * This popover is used in Shape, Image and Google panels
     * and required different sizes and style.
     */
    public popoverCssClass: string;

    /**
     * Element which is used to handle position
     */
    @ViewChild( 'popoverOuter' )
    public popoverOuter: ElementRef;

    private subs: Subscription[];

    constructor( @Inject( StateService ) protected state: StateService<any, any> ) {
        this.libraryItemSubject = new BehaviorSubject( null );
        this.subs = [];
    }

    /**
     * Subscribing to state changes
     * If there are data in state, popover will be shown, otherwise hidden
     * Positioning will happen after popover being rendered
     */
    public ngOnInit() {
        this.subs.push(
            this.preparePopover.pipe(
                filter( v => !!v ),
                delay( 0 ),
                tap( v => {
                    const data = this.state.get( 'CurrentLibraryPreview' );
                    this.position( data.x, data.y );
                    this.showPopover.next( true );
                }),
            ).subscribe(),
            this.state.changes( 'CurrentLibraryPreview' ).subscribe(( data: any ) => {
                if ( data && Object.keys( data ).length > 0 ) {
                    this.libraryItemSubject.next( data.item );
                    this.text =  {
                        title: data.item.text.title,
                        line1: data.item.text.line1,
                        line2: data.item.text.line2,
                        line3: data.item.text.line3,
                        line4: data.item.text.line4,
                    };
                    this.popoverCssClass = data.item.popoverCssClass;
                    this.prepare();
                } else {
                    this.showPopover.next( false );
                    this.preparePopover.next( false );
                }
            }),
        );
    }

    /**
     * This method prepares the popover with the position
     * to be shown.
     */
    public prepare() {
        this.preparePopover.next( true );
    }

    public ngOnDestroy() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Positions the popover on the document.
     * @param x = library panel container width
     * @param y = library tile top
     */
    protected position( x: number, y: number ) {
        if ( !this.popoverOuter ) {
            return;
        }
        const bounds = this.popoverOuter.nativeElement.getBoundingClientRect();
        if ( bounds.height + y >= window.innerHeight ) {
            y = window.innerHeight - bounds.height - 60;
        } else if ( y < 0 ) {
            y = 20;
        } else {
            // Positioning has been set, 75 pixels has been reduced to centre the popover to tile
            y -= 75;
        }
        this.popoverOuter.nativeElement.style.left = x + 'px';
        this.popoverOuter.nativeElement.style.top = y + 'px';
    }
}
