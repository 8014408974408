import { PanDiagram } from './pan-diagram.cmd';
import { CommandMapper, CommandEvent } from 'flux-core';
import { ZoomDiagram } from './zoom-diagram.cmd';
import { FocusToPoint } from './focus-to-point.cmd';
import { GetDiagramFitSize } from './get-diagram-fitsize.cmd';
/**
 * This class holds all events that are defined for invoking diagram commands.
 * An event can associate one or more commands.
 *
 * @author  Hiraash
 * @since   2017-09-21
 */
// tslint:disable: member-ordering
export class InteractionCommandEvent extends CommandEvent {
    static zoomDiagram: InteractionCommandEvent = new InteractionCommandEvent( 'ZoomDiagram' );
    static panDiagram: InteractionCommandEvent = new InteractionCommandEvent( 'PanDiagram' );
    static zoomAndPanDiagram: InteractionCommandEvent = new InteractionCommandEvent( 'ZoomAndPanDiagram' );
    static focusToPoint: InteractionCommandEvent = new InteractionCommandEvent( 'FocusToPoint' );
    static hyperlinkNavigate: InteractionCommandEvent = new InteractionCommandEvent( 'HyperlinkNavigate' );
    /**
     * Register an event to a sequence of commands or parallel of commands
     * @param   mapper  CommandMapper instance which maps the commands to the
     *                  desired event
     */
    public static register( mapper: CommandMapper ) {
        mapper.map( InteractionCommandEvent.zoomDiagram ).add( <any>ZoomDiagram );
        mapper.map( InteractionCommandEvent.panDiagram ).add( <any>PanDiagram );
        mapper.map( InteractionCommandEvent.focusToPoint ).add( <any>FocusToPoint );
        mapper.mapSequence( InteractionCommandEvent.zoomAndPanDiagram )
            .add( GetDiagramFitSize as any )
            .add( ZoomDiagram as any, {
                transform: progress => ({
                    level: progress.resultData[0].level,
                    x: progress.resultData[0].x,
                    y: progress.resultData[0].y,
                }),
            })
            .add( PanDiagram as any, {
                transform: progress => ({
                    x: progress.data[1].x,
                    y: progress.data[1].y,
                }),
            });
    }
}
