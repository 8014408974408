import { AbstractModel } from 'flux-core';
import { AbstractModelSubscription } from 'flux-subscription';
import { ProjectModel } from '../model/project.mdl';


export class ProjectSub extends AbstractModelSubscription {
    public get subscription(): string {
        return 'Project';
    }

    public get modelType(): typeof AbstractModel {
        return ProjectModel;
    }
}
