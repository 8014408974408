/**
 * Definitions for videos shown to users via the floating card and help widget
 */
export const videoDefs = [
    {
        id: '0',
        name: 'Getting Started With Creately', // For tracking purposes
        heading: 'VIDEOS.VIDEO2.HEADING',
        videoId: '2dppwut02j',
        url: 'https://www.youtube.com/embed/9CyRNoGLDc8?autoplay=1',
        thumbnail: './assets/images/help-videos/creately-getting-started.jpg',
        tags: [ '*', 'demo' ],
        priority: 1,
        size: 'large',
        mediaType: 'in_app_video',
    },
];
