import { Animation } from '../animation';
import { merge, Observable } from 'rxjs';
import { Component } from '@angular/core';

/**
 * This component is used to encapsulate UI features on a popup window
 * such as the open/hide animation.
 *
 * @since 23/03/2017
 */

@Component({
    selector: 'popup-window',
    template: '<div class="popup-window-container"></div>',
})

export class PopupWindow {

    /**
     * Animation that fades in the container and transforms the window
     * to slide up while fading in.
     * @param container: The overlay containing the window.
     * @param window: The window itself.
     */
    public showWindow( container, window ): Observable<any> {
        return merge(
            this.fadeAnimation( container.nativeElement, 1, 0 ).start(),
            this.showAnimation( window.nativeElement ).start(),
        );
    }

    /**
     * Animation that fades out the container and transforms the window
     * to slide down while fading out.
     * @param container: The overlay containing the window.
     * @param window: The window itself.
     */
    public hideWindow( container, window ): Observable<any> {
        return merge(
            this.fadeAnimation( container.nativeElement, 0, 1 ).start(),
            this.hideAnimation( window.nativeElement ).start(),
        );
    }

    /**
     * Animation that shows the window.
     */
    protected showAnimation( element ): Animation {
        const transition = new Animation({
            from: { transform: 'translateY(30px)', opacity: '0' },
            to: { transform: 'translateY(0)', opacity: '1' },
            transitionProperty: 'transform, opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }

    /**
     * Animation that hides the window.
     */
    protected hideAnimation( element ): Animation {
        const transition = new Animation({
            from: { transform: 'translateY(0)', opacity: '1' },
            to: { transform: 'translateY(30px)', opacity: '0' },
            transitionProperty: 'transform, opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }

    /**
     * Animation that is used to fade in/out the overlay.
     */
    protected fadeAnimation( element, to, from ) {
        const transition = new Animation({
            from: { opacity: from },
            to: { opacity: to },
            transitionProperty: 'opacity',
            duration: 100,
            easing: 'linear',
        });
        transition.element = element;
        return transition;
    }
}
