import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DiagramInfoModel } from '../model/diagram-info.mdl';
import { DiagramInfoModelStore } from '../../storage/diagram-info-model-store';
import { ChangeResourceGroupShare } from '../../group-share/command/change-resource-group-share.cmd';

/**
 * This is the command which changes the role of the diagram group shares and send
 * the request to the server.
 *
 * data: {
 *     groupShares { id, role} An array of changed group shares id and role
 *     diagramId id of the resource
 * }
 */
@Injectable()
@Command()
export class ChangeDiagramGroupShare extends ChangeResourceGroupShare  {

    protected getModelStore() {
        return this.dataStore.getModelStore( DiagramInfoModel ) as DiagramInfoModelStore ;
    }

}

Object.defineProperty( ChangeDiagramGroupShare, 'name', {
    value: 'ChangeDiagramGroupShare',
});
