import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierController, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { ConnectionStatus } from 'flux-connection';
import { EMPTY, Observable, of } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';
import { DiagramModel } from '../base/diagram/model/diagram.mdl';
import { Router } from '@angular/router';
import { NotificationMessages, Notifications } from '../base/notifications/notification-messages';
import { fromPromise } from 'rxjs/internal-compatibility';

/**
 * This service can be used to navigate to a diagram.
 * This primarily check whether the app is offline and if it is,
 * checks whether the given diagram Id is available in local indexed db.
 *
 * @since   2021-07-22
 * @author  Vinoch
 */
@Injectable()
export class DiagramNavigation {

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
    protected notificationMessages: NotificationMessages;

    constructor( protected state: StateService<any, any>,
                 protected router: Router,
                 protected dataStore: DataStore,
                 protected translate: TranslateService,
                 protected notifierController: NotifierController,
    ) {
        this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * This returns true if the app is offline.
     */
    private get isOffline(): boolean {
        return this.state.get( ConnectionStatus ) === ConnectionStatus.OFFLINE;
    }

    /**
     * This function navigates to the given diagram id only
     * if the diagram is possible to load.
     * @param diagramId - Diagram Id to which need to navigate.
     * @param mode - View or Edit mode.
     * @param suffix - This can be used to attach any query parameter
     * in the diagram navigation url.
     */
    public navigateToDiagram( diagramId: string, mode: 'view' | 'edit', suffix = []) {
        this.navigateToDiagramAnd( diagramId, mode, suffix ).subscribe();
    }

    /**
     * This function navigates to the given diagram id only
     * if the diagram is possible to load.
     * @param diagramId - Diagram Id to which need to navigate.
     * @param mode - View or Edit mode.
     * @param suffix - This can be used to attach any query parameter
     * in the diagram navigation url.
     */
     public navigateToDiagramAnd( diagramId: string, mode: 'view' | 'edit', suffix = []) {
        return this.canNavigateToDiagram( diagramId ).pipe(
            take( 1 ),
            switchMap( isPossible => {
                if ( isPossible ) {
                    return fromPromise( this.router.navigate([ '../', diagramId, mode, ...suffix ]));
                }
                this.showDocumentNotAvailableNotification();
                return EMPTY;
            }),
        );
    }

    /**
     * Returns observable true if the diagram is available in local storage
     * or possible to navigate.
     * @param diagramId - Diagram Id
     */
    public canNavigateToDiagram( diagramId: string ): Observable<boolean> {
        if ( this.isOffline ) {
            return this.isDiagramInStorage( diagramId ).pipe(
                switchMap( isAvail => of( isAvail )),
            );
        }
        return of( true );
    }

    /**
     * This show the notification which inform user that the document is not accessible
     * while offline as it is not available in local / indexed db.
     */
    protected showDocumentNotAvailableNotification() {
        const notificationData = this.notificationMessages
            .getNotificationMessage( Notifications.DOCUMENT_NOT_AVAILABLE_OFFLINE );
        this.notifierController.show( Notifications.DOCUMENT_NOT_AVAILABLE_OFFLINE,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }

    /**
     * Checks if a given diagram is in the local storage
     * @param diagramId - diagram id to check
     * @return observable which emits true if diagram exists
     */
    private isDiagramInStorage( diagramId: string ): Observable<boolean> {
        return this.getDiagram( diagramId ).pipe(
            map( diagram => !!diagram ),
        );
    }

    /**
     * Fetches a diagram from the local storage
     * @param diagramId
     * @return observable that emits the diagram data once
     */
    private getDiagram( diagramId: string ): Observable<any> {
        return this.dataStore
            .findOneRaw( DiagramModel, { id: diagramId }).pipe(
                take( 1 ),
            );
    }
}
