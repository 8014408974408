import { Subject ,  Observable, merge, Subscription, fromEvent, of } from 'rxjs';
import { switchMap, startWith, tap, delay, takeUntil } from 'rxjs/operators';
import { DropDownButton } from './dropdown-button.cmp';
import { DropDownItem } from './dropdown-item.cmp';
import { Component, ChangeDetectionStrategy, ElementRef, Input, OnDestroy, AfterContentInit,
    ContentChildren, QueryList, ViewChild, ContentChild, Output } from '@angular/core';
import { merge as lodashMerge } from 'lodash';
import { WindowBoundService } from '../../window-bound.svc';

/**
 * AbstractDropDown Component
 * This component is an abstract drop-down component that has only basic
 * drop down factinalities implemented. There are two other abstract components
 * related to this component, DropDownItem and DropDownButton.
 * These components can be extended to build customized drop-downs.
 * The example usage of this component
 *  <abs-dropdown [settings]="{ closeOnItemClicked: true }" >
 *      <dropdown-button ddbutton [items]="items"></dropdown-button>
 *          <dropdown-item dditem *ngFor="let item of items"
 *              [item]="item">
 *          </dropdown-item>
 *  </abs-dropdown>
 *
 * @author  thisun
 * @since   2018-06-20
 */

@Component({
    selector: 'abs-dropdown',
    styleUrls: [ './abstract-dropdown.scss' ],
    template: `
    <div #ddContainer class="abs-dropdown-container">
        <div  [class.fx-hidden]="settings.alwaysOpen" class="btn-container-abs-dropdown">
            <ng-content select="[ddbutton]"></ng-content>
        </div>
        <div class="abs-dropdown-inner-wrapper">
            <div #dropDownInnerContainer class="abs-dropdown-inner {{direction}} {{ autoPosition ? alignment : '' }}"
                (wheel)="preventEdgeScroll($event)">
                    <ul tabindex="-1" #itemsContainer class="abs-dropdown-items-container" >
                        <perfect-scrollbar class="scrollbar-abs-dropdown-2" >
                            <ng-content select="[dditemheader]"></ng-content>
                            <ng-content select="[dditem]"></ng-content>
                        </perfect-scrollbar>
                    </ul>
            </div>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbstractDropDown implements OnDestroy, AfterContentInit {

    /**
     * A class which specifies in which direction the dropdown should open.
     * This can be up or down.
     */
    @Input() public direction: 'up' | 'down' = 'down';

    /**
     * Specifies the alignment of the dropdown.
     * This can be left or right.
     */
    @Input() public alignment: 'left' | 'right' = 'left';

    /**
     * Specifies the right alignment offet of the dropdown.
     * This is for right.
     */
    @Input() public rightOffset?: number = 0;

    /**
     * Specifies the left alignment offet of the dropdown.
     * This is for left.
     */
    @Input() public leftOffset?: number = 0;

    /**
     * This is to enable or disable the auto adjusting of the dropdown position
     * FIXME - This can be added as a dropdown setting, but currently dropdwon settings are not working
     *         as expected when merging input settings with default settings.
     */
    @Input() public autoPosition: boolean = true;

    /**
     * Specifies the max height for the dropdown.
     * Dropdown list will start to scroll beyond this height
     */
    @Input() public maxHeight: string = 'auto';

    /**
     * To determine if the dropdown component is opened or closed
     */
    public opened: boolean = false;

    /**
     * Drop dowon list settings - IDropDownSettings
     * Settings can be specified trhough an input to the drop-down component.
     */
    @Input()
    public settings: IDropDownSettings;

    /**
     * This subject emits the selected dropdown ids
     */
    @Output()
    public changed: Subject<string[]>;

    /**
     * Array that holds the selected id list
     */
    protected selectedIds: Array<string>;

    protected defaultSettings: IDropDownSettings = {
        closeOnItemClicked: true,
        openOnHover: false,
        closeOnBlur: true,
        closeOnBlurDelay: 0,
        multiselectable: false,
        alwaysOpen: false,
        closeOnClickOutside: false,
        shouldScroll: false,
      };

    /**
     * Drop down container ElementRef
     */
    @ViewChild( 'itemsContainer', { static: true })
    protected itemsContainer: ElementRef;

    /**
     * The dropdown container elementRef
     */
    @ViewChild( 'ddContainer', { static: true })
    protected ddContainer: ElementRef;

    /**
     * Drop down items container element
     * Drop down inner container ElementRef
     */
    @ViewChild( 'dropDownInnerContainer', { static: true })
    protected dropDownInnerContainer: ElementRef;

    /**
     * DropDown items list - DropDownItem
     */
    @ContentChildren( DropDownItem, { descendants: true })
    protected dropDownItems: QueryList<DropDownItem<any>>;

    /**
     * DropDown items list - DropDownItem
     */
    @ContentChild( DropDownButton, { static: true })
    protected dropDownButton: DropDownButton<any>;

    /**
     * The list of subscriptions held by this class
     */
    protected subs: Subscription[];

    constructor ( protected element: ElementRef ) {
      this.subs =  [];
      this.selectedIds =  [];
      this.changed = new Subject();
      this.settings = lodashMerge({}, this.defaultSettings, this.settings );
    }

    /**
     * public getter for the down inner container element
     */
    /* istanbul ignore next */
    public get innerContainer(): HTMLElement {
        return this.innerContainerElement;
    }

    /**
     * public getter for the drop down container element.
     */
    /* istanbul ignore next */
    public get dropdownContainer(): HTMLElement {
        return this.ddContainerElement;
    }

    /**
     * Drop down container element
     */
    protected get containerElement(): HTMLElement {
        return ( this.itemsContainer.nativeElement as HTMLMapElement );
    }

    /**
     * The element that containes both button and dd items
     */
    protected get ddContainerElement(): HTMLElement {
        return ( this.ddContainer.nativeElement as HTMLMapElement );
    }

    /**
     * Drop down inner container element
     */
    protected get innerContainerElement(): HTMLElement {
        return ( this.dropDownInnerContainer.nativeElement as HTMLMapElement );
    }

    /**
     * Returns the inner dropdown element.
     */
    protected get dropdownElement() {
        return this.element.nativeElement.querySelector( '.abs-dropdown-inner' );
    }

    /**
     * Returns the container height.
     */
    protected get dropdownContainerHeight(): number {
        return this.element.nativeElement.children[0].offsetHeight;
    }

    public ngAfterContentInit() {
        this.manageDropdown();
        ( this.containerElement.querySelector( 'perfect-scrollbar' ) as HTMLElement )
            .style.maxHeight = this.maxHeight;

        this.subs.push( this.getNewSelect().subscribe(() => this.onItemChange()));
        if ( !this.settings.alwaysOpen ) {
            this.close();
        }
    }

    /**
     * Destroys and clears all the resources used by the
     * interaction handler
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Toggle the current state ( show / hide ) of the drop down container
     */
    public toggle() {
      this.opened ? this.close() : this.open();
    }

    public open() {
        setTimeout(() => this._open());
    }

    /**
     * Open the drop down container
     */
    protected _open() {

        /**
         * Set direction 'up' if 'down' cuts off the window
         */
        this.containerElement.style.visibility = 'hidden';
        this.containerElement.style.display = 'block';
        this.containerElement.style.height = 'auto';
        const b = this.containerElement.getBoundingClientRect();
        this.containerElement.style.display = 'none';
        this.containerElement.style.visibility = 'visible';
        this.innerContainerElement.style.bottom = 'unset';
        this.innerContainerElement.style.top = 'unset';
        let direction = this.direction;
        if ( this.autoPosition && b.bottom > window.innerHeight ) {
            direction = 'up';
        }
        this.setDirection( direction );
        this.containerElement.style.display = 'block';
        this.opened = true;
        if ( this.autoPosition ) {
            this.position();
        }


        // Adjust the height of the dropdown container to fit the window
        const windowHeight = window.innerHeight;
        const ddHeight = b.height;
        if ( ddHeight >= windowHeight * 0.4 ) {
            setTimeout(() => {
                this.containerElement.style.height = windowHeight * 0.4 + 'px';
            }, 0 );
        }
    }

    // tslint:disable:member-ordering
    protected setDirection( direction: 'up' | 'down' ) {
        // Reducing 1 from the height so that the dropdown aligns with parents that have
        // borders. Assuming border is 1px.

        const offset = 1;
        const height = this.dropdownContainerHeight - offset;

        if ( direction === 'up' ) {
            this.innerContainerElement.style.bottom = height + 'px';
            this.containerElement.style.animation = 'showingAnimationUp 300ms';
        } else {
            this.innerContainerElement.style.top = '-' + offset + 'px';
            this.containerElement.style.animation = ' showingAnimationDown 300ms';
        }
    }

    public position() {
        // Bounds are calculated from an offset of zero which is the default offset

        if ( this.alignment === 'left' ) {
            this.innerContainerElement.style.left = '0px';
            const displacement =
                WindowBoundService.moveBoundsToContain( this.innerContainerElement.getBoundingClientRect());
            this.innerContainerElement.style.left =  (( -1 * displacement.x ) + this.leftOffset ) + 'px';

        } else {
            this.innerContainerElement.style.right = '0px';
            const displacement =
                WindowBoundService.moveBoundsToContain( this.innerContainerElement.getBoundingClientRect());
            this.innerContainerElement.style.right = ( displacement.x + this.rightOffset ) + 'px';
        }

        // TODO - Need to implement proper direction up or down
    }

    /**
     * close the drop down container
     */
    public close() {
      this.containerElement.style.display = 'none';
      this.setDirection( this.direction );
      this.opened = false;
    }

    /**
     * Prevent scrolling after the scroll reaches the end of dropdown items.
     * This stops parents element from scrolling after the child element
     * reach it's top or end.
     */
    public preventEdgeScroll( e: WheelEvent ) {
        if ( this.settings.shouldScroll ) {
            return;
        }
        const element = this.dropDownInnerContainer.nativeElement;
        if ( element.scrollHeight - element.scrollTop === element.clientHeight ) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /**
     * This function enables open and closing the dropdown on hover
     */
    protected manageDropdown() {
        if ( this.settings.alwaysOpen ) {
            this.open();
            return;
        }
        if ( this.settings.closeOnBlur ) {
            this.subs.push(
                merge(
                    fromEvent( this.ddContainerElement, 'mouseleave' ),
                ).subscribe(() => this.close()),
            );
        } else if ( this.settings.closeOnClickOutside ) {
            this.subs.push(
                merge(
                    fromEvent( document, 'mousedown' ),
                ).subscribe( event => {
                    if ( this.ddContainerElement.contains( event.target as any )) {
                        return;
                    }
                    if ( this.opened ) {
                        this.close();
                    }
                }),
            );
        }
        if ( this.settings.openOnHover ) {
            this.subs.push(
                fromEvent( this.ddContainerElement, 'mouseenter' ).pipe(
                    switchMap(() =>
                        of({}).pipe(
                            // Adding some delay before the dropdown opens
                            delay( this.settings.delayToDrop ? this.settings.delayToDrop : 0 ),
                            takeUntil( fromEvent( this.ddContainerElement, 'mouseleave' )),
                        ),
                    ),
                ).subscribe(() => this.open()),
            );
        } else {
            this.subs.push( this.dropDownButton.clicked.subscribe(() => this.onClick()));
        }
    }

    /**
     * Returns an observable that emits the seleced item.
     * Initialy it will emit the default dropdown list item which
     * is specified by the 'selected' input or the fist item if
     * no item is pecified. Also this observable is shared to prevent
     * running codes multiple times as it will be the source for
     * multiple subscriptions, ( Subscribe to get selected item and to update drop down button )
     */
    protected getNewSelect(): Observable<string> {
        if ( !this.dropDownItems ) {
            throw new Error( 'Drop Down component has no items.' );
        }
        return this.dropDownItems.changes.pipe(
            startWith( this.dropDownItems ),
            switchMap(( dropDownItems: any ) => {
                this.selectedIds = [];
                const observables = dropDownItems.map( item => {
                    if ( item.selected ) {
                          this.selectedIds.push( item.item.id );
                    }
                    return item.changed;
                });
                return  merge( ...observables );
            }),
            tap(( id: string ) => {
                if ( this.settings.multiselectable ) {
                    const index = this.selectedIds.indexOf( id );
                    if ( index >= 0 ) {
                        this.selectedIds.splice( index, 1 );
                    } else {
                        this.selectedIds.push( id );
                    }
                } else {
                    this.selectedIds = [ id ];
                }
            }),
        );
    }

    protected onItemChange() {
        if ( this.settings.closeOnItemClicked ) {
            this.close();
        }
        this.changed.next( this.selectedIds );
    }

    /**
     * Click event handler function for clicking on the drop down button
     */
    protected onClick() {
        this.toggle();
    }

}

/**
 * IDropDownSettings Interface is used to define the
 * settings for the DropDown
 */
export interface IDropDownSettings {
    /**
     * This setting defines if the drop down should open for the hover event
     */
    openOnHover?: boolean;

    /**
     * This setting defines if the drop down should close on focus out ( blur ) event
     */
    closeOnBlur?: boolean;

    /**
     * This delay in milisecods when the side bar is closed by focus out.
     */
    closeOnBlurDelay?: number;

    /**
     * This setting defines if the drop down should close when clicked on an item
     */
    closeOnItemClicked?: boolean;

    /**
     * This setting defines if the drop down items can be multiselected
     */
    multiselectable?: boolean;

    // This property will add a delay to dropdown in ms
    delayToDrop?: number;

    /**
     * If this is true, show the dropdown always
     */
    alwaysOpen?: boolean;

    /**
     * Close dropdown on click anywhere
     */
    closeOnClickOutside?: boolean;

    /**
     * if we're using perfect scrollbar or not
     */
    shouldScroll?: boolean;

}


/**
 * IDropdownData
 * Dropdown item should have follwing data
 */
export interface IDropdownData {
    /**
     * A uniq value to identify the dropdown item
     */
    id: string;

    /**
     * A boolean To indicate if the item is selected or not
     */
    selected?: boolean;

    /**
     * To indicate if the item is disabled or not.
     * Disabled item won't emit when clicked
     */
    disabled?: boolean;

    /**
     * To indicate if the item is a separator.
     * Separators item won't emit when clicked, nor will they have regular styles
     */
    isSeparator?: boolean;

}
