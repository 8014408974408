import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { EDataRegistry } from './../../../base/edata/edata-registry.svc';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TreeviewItem, TreeviewConfig, TreeviewComponent, TreeviewModule, TreeviewI18n } from '@creately/ngx-treeview';
import { StateService, ModalController, PopupWindow } from 'flux-core';
import { CUSTOM_SHAPE_LIB_ID_PREFIX, LibraryType } from '../../library/abstract-shape-library';
import { map, take, distinctUntilChanged, switchMap, filter, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, forkJoin, merge } from 'rxjs';
import { LibraryList } from './library-list';
import { ILoadedLibrary } from '../../library/loaded-library.state';
import { uniq, isEqual, isEmpty } from 'lodash';
import { TempAddLibsTreeviewI18n } from './temp-add-libs-treeview.i18n';
import { PlanPermManager } from 'flux-user';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { UpgradeDialogType, UpgradeDialogWindow } from 'flux-user';
import { PlanPermission, IDialogBoxData } from 'flux-definition/src';
import { DefinitionLocator } from './../../../base/shape/definition/definition-locator.svc';

import { SearchService, ISearchResultItem } from 'apps/nucleus/src/framework/search/search.svc';


/**
 * This is the temporary add libs menu component.
 * This component allows the user to add remove libraries from
 * the library panel.
 */
@Component({
    templateUrl: './temp-add-libs-menu.cmp.html',
    selector: 'temp-add-libs-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './temp-add-libs-menu.scss' ],
    providers: [
        TreeviewModule,
        { provide: TreeviewI18n, useClass: TempAddLibsTreeviewI18n },
    ],
})
export class TempAddLibsMenu extends PopupWindow implements OnInit, OnDestroy {

    /**
     * The complete library list that is fed to the ngx-treeview
     * component so that it can render the ui.
     */
    public items: BehaviorSubject<TreeviewItem[]>;

    /**
     * Will determine if the premium indicator should be visible
     */
    public isPremium: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Config settings for the ngx-treeview component.
     */
    public config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasFilter: false,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 9999,
    });

    /**
     * Whether the user is in free or demo plan or not
     */
    public isFreeOrDemoUser: Observable<boolean>;

    /**
     * Whether the user is lite plan user or not
     */
    public isLitePlanUser: Observable<boolean>;

    /**
     * Limit access to premium libraries
     */
    public limitAccess: boolean = false;

    protected customDBtitle: string = 'Custom Databases';

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * The the tree view component.
     */
    @ViewChild( 'treeView', { static: true }) protected treeView: TreeviewComponent;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription>;

    constructor(
        protected state: StateService<any, any>,
        protected modalController: ModalController,
        protected planPermManager: PlanPermManager,
        protected libraryList: LibraryList,
        protected ell: EDataLocatorLocator,
        protected ll: DiagramLocatorLocator,
        protected searchService: SearchService,
        protected defLocator: DefinitionLocator,
    ) {
        super();
        this.subs = [];
        this.items = new BehaviorSubject([]);
        this.isFreeOrDemoUser = this.planPermManager.isFreeOrDemoUser();
        this.isLitePlanUser = this.planPermManager.isLiteUser();
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
    }

    /**
     * Creates the initial treeview and sets the default selected libraries.
     */
    public ngOnInit(): void {
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.state.changes( 'CurrentLibraries' ).pipe(
            take( 1 ),
            map( selectedLibraries => {
                this.state.set( 'CurrentLibrariesClone', selectedLibraries );
                this.createTreeView( selectedLibraries.map( lib => lib.id ));
            }),
        ).subscribe();

        const sub2 = merge(
            this.planPermManager.isFreeOrDemoUser(),
            this.planPermManager.isLiteUser(),
        ).pipe(
            filter( value => !!value ),
            tap( value => this.limitAccess = value ),
        ).subscribe();

        this.subs.push( sub, sub2 );
    }

    /**
     * Method that is called by the filter text box.
     * Accepts a string that is used to filter the items displayed on the treeview.
     */
    onFilterChange( event: string ): void {
        if ( event ) {
            this.searchShapes( event, [ 'name' ]).pipe(
                switchMap( result => {
                    // Create an array of observables to avaid multiple subscriptions
                    const observables = result.map( resultItem =>
                        this.defLocator.getDefLibraries( resultItem.id, ( resultItem as any ).version ).pipe(
                        map( libraries => ({
                            resultItem,
                            libraries,
                        })),
                    ));
                    return forkJoin( observables );
                }),
            ).subscribe(
                results => {
                    this.treeView.items.forEach( category => {
                        let catCount = 0;
                        category.children.forEach( group => {
                            let count = 0;
                            if ( !group.children ) {
                                const libraryId = group.value;
                                count = results.filter( item => item.libraries &&
                                    item.libraries?.includes( libraryId )).length;
                                if ( group.value.includes( event )) {
                                    count += 1;
                                }
                                ( group as any ).count = count;
                                catCount += count;
                            } else {
                                group.children.forEach( lib => {
                                    const libraryId = group.value;
                                    let libCount = results.filter( resultItem => resultItem.libraries &&
                                        resultItem.libraries?.includes( libraryId )).length;
                                    if ( lib.text.toLocaleLowerCase().includes( event.toLocaleLowerCase())) {
                                        libCount += 1;
                                    }
                                    ( lib as any ).count = libCount;
                                    count += libCount;
                                });
                                ( group as any ).count = count;
                                // group.setCollapsedRecursive( count === 0 );
                                group.collapsed = count === 0;
                                catCount += count;
                            }
                        });
                        category.collapsed = catCount === 0;
                    });
                    // Focus on library with highest score
                    // const library = results[0]?.libraries[ 0 ];
                    // if ( library ) {
                    //     this.state.set( 'FocusedLibrary', { value: library, type: 'library' });
                    // }
                },
            );
        } else {
            this.treeView.items.forEach( category => {
                category.children.forEach( group => {
                    if ( !group.children ) {
                        ( group as any ).count = 0;
                    } else {
                        group.children.forEach( lib => {
                            ( lib as any ).count = 0;
                        });
                        ( group as any ).count = 0;
                    }
                });
            });
        }
    }

    /**
     * Method that fires when a selection changes on the tree.
     * Updates the state with the current selected items.
     */
    public onSelectionChange( event ) {
        // Note: uniq added because, when the same library exist in multiple groups, when a single library
        // selected, both will be selected.
        const distinctLibs = uniq( event );
        const currentLibs: ILoadedLibrary[] = this.state.get( 'CurrentLibrariesClone' );
        // Empty list emitted on open of tree view
        if ( event.length === 0 || distinctLibs.length === currentLibs.length ) {
                return;
        }
        const updatedLibs = currentLibs.filter( lib => distinctLibs.includes( lib.id ));
        const libIds = updatedLibs.map( lib => lib.id );
        const newlyAddedLibs = distinctLibs.filter( libId => !libIds.includes( libId ));
        let currGroup = this.state.get( 'CurrentLibraryGroup' );
        newlyAddedLibs.forEach( libId => {
            const newLibrary: ILoadedLibrary = {
                id: libId,
                type: LibraryType.Static,
                libGroup: this.libraryList.getMainGroupForLibrary( libId ),
                category: this.libraryList.getCategoryForLibrary( libId ),
                isCollapsed: false,
                status: 'loading',
            };
            currGroup = newLibrary.libGroup;
            updatedLibs.push( newLibrary );
        });
        this.state.set( 'CurrentLibrariesClone', updatedLibs );

        // Check if library group has been removed -
        if ( currGroup !== 'Shapes' && !( updatedLibs.map( lib => lib.category ).includes( currGroup ))) {
            currGroup = updatedLibs[updatedLibs.length - 1].category;
        }
        this.state.set( 'CurrentLibraryGroup', currGroup );
    }

    /**
     * Commit changes and close window
     */
    public saveAndCloseWindow() {
        const libraries = this.state.get( 'CurrentLibrariesClone' );
        this.state.set( 'CurrentLibraries', libraries );
        this.closeWindow();
    }

    /**
     * Starts the animation for hiding the window and closes the window
     * after it completes.
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Show upgrade dialog
     */
    public openUpgradeDialog() {
        this.closeWindow();

        const dialogData = {
            id: PlanPermission.CREATELY_PREMIUM_LIBRARIES,
            iframeType: UpgradeDialogType.PremiumShapelibraries,
            buttons: [
                // {
                //     type: 'action',
                //     clickHandler: buttonAction,
                //     visibility: actionButtonVisibility ? 'enable' : 'disabled',
                // },
                // {
                //     type: 'upgrade',
                //     clickHandler: () => {},
                // },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        this.modalController.show( UpgradeDialogWindow, {
                inputs: { dialogData: dialogData },
        });
    }

    /**
     * Unsubscribes from the subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Check whether the library is premium or not
     * @param libId
     * @returns
     */
    public isPremiumLibrary( libId: string ): boolean {
        return this.libraryList.belongsToPremiumGroup( libId );
    }

    /**
     * Check whether the group is premium or not
     * @param grpId
     * @returns
     */
    public isPremiumGroup( grpId: string ): boolean {
        return this.libraryList.isPremiumGroup( grpId );
    }

    /**
     * Sets current focus to category, group, or library
     */
    public handleFocus( childItem: any ) {
        if ( !childItem.children ) {
            this.state.set( 'FocusedLibrary', { value: childItem.value, type: 'library' });
        } else if ( childItem.value === childItem.text ) {
            this.state.set( 'FocusedLibrary', { value: childItem.value, type: 'category' });
        } else {
            this.state.set( 'FocusedLibrary', { value: childItem.text, type: 'group' });
        }
        this.isPremium.next( this.isPremiumLibrary( childItem.value ) || this.isPremiumGroup( childItem.text ));
    }

    /**
     * Update treeview component when group checked changes
     */
    public updateGroupData( category, group, checked ) {
        const item = this.treeView.items.find( i => i.value === category );
        const groupItem = item.children.find( i => i.text === group );
        groupItem.setCheckedRecursive( checked );
    }

    /**
     * Update treeview component when library checked changes
     */
    public updateLibraryData( category, libId, checked, group? ) {
        const item = this.treeView.items.find( i => i.value === category );
        if ( group ) {
            const groupItem = item.children.find( i => i.text === group );
            const lib = groupItem.children.find( i => i.value === libId );
            lib.setCheckedRecursive( checked );
        } else {
            const lib = item.children.find( i => i.value === libId );
            lib.setCheckedRecursive( checked );
        }
    }

    /**
     * Returns the custom databases info
     */
    public getCustomDatabases() {
        return this.ll.forCurrent( false ).getDiagramEData().pipe(
            switchMap( ids => this.ell.currentEDataModels().pipe(
                map( models => ( ids || []).map( id => models.find( m => m.id === id )))),
            ),
            distinctUntilChanged(( pre, curr ) => {
                const preItems =  pre.map( v => Object.keys( v.customEntityDefs || {}));
                const currItems =  curr.map( v => Object.keys( v.customEntityDefs || {}));
                return isEqual( preItems, currItems );
            }),
            map( models => {
                const itemsData = [];
                models
                    .filter( m => m && m.defId === EDataRegistry.customEdataDefId &&
                        !isEmpty( m.getActiveCustomEntityDefs()))
                    .forEach( edataModel => {
                        itemsData.push({
                            id: CUSTOM_SHAPE_LIB_ID_PREFIX + edataModel.id,
                            label: edataModel.name,
                            groups: [ this.customDBtitle ],
                            libGroup: this.customDBtitle,
                        });
                    });
                return itemsData;
            }));
    }

    /**
     * Method that creates the treeview and emits "items" so that
     * it can be rendered in the ui.
     */
    protected createTreeView( selectedLibIds: string[]) {
        const sub = this.getCustomDatabases().subscribe( customDbs => {
            const allLibs = [];
            this.libraryList.getAllLibraryCategories().forEach( category => {
                const children = [];
                let collapsed = true;
                const { libraries, groups } = this.libraryList.getCategoryChildren( category );
                libraries.forEach( lib => {
                    children.push({
                        text: lib.label,
                        value: lib.id,
                        count: 0,
                        checked: this.isLibrarySelected( lib.id, selectedLibIds ),
                    });
                    collapsed = collapsed ? !this.isLibrarySelected( lib.id, selectedLibIds ) : collapsed;
                });
                groups.forEach( group => {
                    const groupChildren = [];
                    let groupCollapsed = true;
                    this.libraryList.getLibrariesInGroup( group ).map( lib => {
                        groupChildren.push({
                            text: lib.label,
                            value: lib.id,
                            count: 0,
                            checked: this.isLibrarySelected( lib.id, selectedLibIds ),
                            disabled: ( this.isPremiumLibrary( group ) && this.limitAccess ),
                        });

                        groupCollapsed = collapsed ? !this.isLibrarySelected( lib.id, selectedLibIds ) : collapsed;
                    });
                    children.push({
                        text: group,
                        value: group.toLowerCase(),
                        checked: groupCollapsed,
                        children: groupChildren,
                        count: 0,
                        disabled: ( this.isPremiumGroup( group ) && this.limitAccess ),
                    });
                });
                const treeViewItem = new TreeviewItem({
                    text: category,
                    value: category,
                    children: children,
                    collapsed: collapsed,
                    disableChildrenCheck: false,
                });
                treeViewItem.correctChecked();
                allLibs.push( treeViewItem );
            });
            this.handleFocus( allLibs[0]);

            if ( !isEmpty( customDbs )) {
                const tv = new TreeviewItem({
                    text: this.customDBtitle,
                    value: this.customDBtitle,
                    children: customDbs.map( lib => ({
                        text: lib.label,
                        value: lib.id,
                        checked: this.isLibrarySelected( lib.id, selectedLibIds ),
                    })),
                    collapsed: false,
                    disableChildrenCheck: false,
                });
                tv.correctChecked();
                allLibs.push( tv );
            }
            this.items.next( allLibs );
        });

        this.subs.push( sub );
    }

    /**
     * Returns true or false based on whether the library is selected.
     */
    protected isLibrarySelected( libraryId: string, selectedLibs: string[]): boolean {
        return selectedLibs && selectedLibs.includes( libraryId );
    }

    protected searchShapes( searchQuery: string, keys: string[]): Observable<ISearchResultItem[]> {
        return this.searchService.search( searchQuery, [ 'shape' ],
            { keys: keys, threshold: -10000 });
    }

    protected searchShapesByTags( searchQuery: string ): Observable<ISearchResultItem[]> {
        return this.searchService.search( searchQuery, [ 'shape' ], { keys: [ 'tags' ], threshold: -10000 });
    }
}
