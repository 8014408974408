import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Component that will add the loading bubbles indicator to the HTML.
 * @author nuwanthi
 * @since 22-11-2019
 */

@Component({
    selector: 'loading-bubbles',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './loading-bubbles.cmp.html',
    styleUrls: [ './loading-bubbles.scss' ],
})

export class LoadingBubbles {
    /**
     * Color of the loading bubbles.
     */
    @Input() public color: string;

    /**
     * Unique class to refer to a specific loading indicator if needed.
     */
    @Input() public class: string;

    /**
     * Define how big the spinner bubbles should be.
     * Currently not in use.
     */
    @Input() public size: 'large' | 'medium' | 'small';

    /**
     * Set to true if you want the spinner container to cover the entire parent.
     * This can be used with centerAll = true to make the spinner absolutely center
     * to it's parent.
     */
    @Input() public coverParent: boolean;

    /**
     * Centers the spinner vertically and horizontally inside the container.
     */
    @Input() public centerAll: boolean;
}
