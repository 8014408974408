import { AbstractCommand, StateService, CommandInterfaces, Rectangle, Command } from 'flux-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Animation } from '../../../framework/ui/animation/tween-animation';
import { Ease, Point } from '@creately/createjs-module';
import { map } from 'rxjs/operators';

/**
 * Zooms the diagram to a given level or by a delta and This is a local state command.
 * Also this can be be used to zoom to a specific point specified by
 * toX and toY state data. if only the zoomLevel or delta is specified there will no be change
 * in the pan state. if the toCenter data is true, the diagram will be zoomed to the
 * center point of the viewport.
 * e.g.
 * { level } or { delta } - Just zoom, no pan state change.
 * { level, toCenter } or { delta, toCenter } - zoom to viewport center, pan state will be changed.
 * { level, toX, toY } or { delta, toX, toY } - zoom to [ toX, toY ] point, pan state will be changed.
 */
@Injectable()
@Command()
export class ZoomDiagram extends AbstractCommand {

    public static maxZoomLevel = 10;

    public static minZoomLevel = 0.05;

    public static get dataDefinition(): {}  {
        return {
            level: true, // The zoom level (Used by state)
            currentLevel: false, // Current zoom level for interrim calculations.
            toX: false, // Indicates the x position to zoom to.
            toY: false, // Indicates the y position to zoom to.
            toCenter: false, // Boolean to specify whether zoom to center (Used by state).
            currentPan: false, // Current pan for interrim calculations.
            delta: false, // Change to the zoom level in positive or negative
            animate: false, // Indicates if to animate the change. Default is true
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return this.data.panStateData ? {
            DiagramZoomLevel: this.data.zoomStateData,
            DiagramPan: this.data.panStateData,
        } : { DiagramZoomLevel: this.data.zoomStateData };
    }

    /**
     * Prepares the data necessary for the zoom state change.
     */
    public prepareData(): void {
        this.setZoomLevel();
        this.setPan();
        const updatePan: boolean = this.data.toX !== undefined && this.data.toY !== undefined;

        if ( this.data.animate === false ) {
            this.data.zoomStateData = this.data.level;
            if ( updatePan ) {
                this.data.panStateData = this.getPan();
            }
        } else {
            const animation = this.simulateAnimation();
            this.data.zoomStateData = animation.pipe( map( change => change.level ));
            if ( updatePan ) {
                this.data.panStateData = animation.pipe( map( change => new Point( change.x, change.y )));
            }
        }

    }

    public execute (): boolean {
        return true;
    }

    protected limitZoom( level: number ) {
        return Math.min(
            ZoomDiagram.maxZoomLevel,
            Math.max( ZoomDiagram.minZoomLevel , level ),
        );
    }

    /**
     * Prepares the zoom level data that is necessary for the state change.
     * @return The point to pan to
     */
    protected getPan() {
        const delta =  this.data.level / this.data.currentLevel;
        const x = ( this.data.currentPan.x  - this.data.toX ) * delta + this.data.toX;
        const y = ( this.data.currentPan.y - this.data.toY ) * delta + this.data.toY;
        return new Point( x , y );
    }

    /**
     * Set the current pan and toX, toY point that are necessary to calculate the future pan point
     * toX and toY are set to the view port center if not included in the command data.
     */
    protected setPan() {
        this.data.currentPan = this.state.get( 'DiagramPan' );
        const viewPort: Rectangle = this.state.get( 'DiagramViewPort' );
        if ( this.data.toCenter ) {
            this.data.toX = viewPort.centerX;
            this.data.toY = viewPort.centerY;
        }
    }

    /**
     * Prepares the zoom level data that is necessary for the state change.
     */
    protected setZoomLevel() {
        this.data.currentLevel = this.state.get( 'DiagramZoomLevel' );
        if ( !this.data.level && this.data.delta > 0 ) {
            this.data.level = this.limitZoom(  this.data.currentLevel * this.data.delta );
        }
    }

    /**
     * Creates a animation simulation for the zoom state change which
     * returns an observable that will emit the zoom change.
     */
    protected simulateAnimation(): Observable<any> {
        const pan = this.getPan();
        return Animation
            .simulate({ level: this.data.currentLevel, x: this.data.currentPan.x, y: this.data.currentPan.y })
            .to({ level: this.data.level, x: pan.x, y: pan.y }, 300, Ease.sineOut )
            .changes();
    }
}

Object.defineProperty( ZoomDiagram, 'name', {
    value: 'ZoomDiagram',
});
