import { Injectable } from '@angular/core';
import {
    Command, CommandInterfaces, ImageLoader, NotifierController,
    PostMessageAPI, PostMessageSendEventType, StateService,
} from 'flux-core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { ExportAsPng } from './export-as-png.cmd';
import { delay, filter, switchMap, take, tap } from 'rxjs/operators';
import { PlanPermManager } from 'flux-user';
import { PlanPermission } from 'flux-definition';
/**
 * Command to export diagram as a svg
 */
@Injectable()
@Command()
export class ExportAsPngAPI extends ExportAsPng {

    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor(
        ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        resources: ImageLoader,
        notifierController: NotifierController,
        translate: TranslateService,
        protected planPermManager: PlanPermManager,
        protected postMessage: PostMessageAPI,
    ) {
        super( ll, state,  resources, notifierController, translate );
    }


    public execute(): Observable<any> {
        if ( !this.planPermManager.check([ PlanPermission.DIAGRAM_EXPORT_AS_PNG ])) {
            this.postMessage.sendToParent( PostMessageSendEventType.commandExecute,
                {
                    commandName: 'exportPNG',
                    documentId: this.resourceId,
                    error: {
                        message: 'Permission is denied',
                        code: 403,
                    },
                },
            );
            throw new Error( 'Permission is denied' );
        }
        this.state.changes( 'DiagramLoadComplete' ).pipe(
            filter( load => !!load ),
            delay( 1200 ),      // Add delay for shapes views to be rendered on canvas
            take( 1 ),
            switchMap( _ => this.preparePNGData().pipe(
                tap(() => {
                    const dataUrl = this.data.content;
                    this.postMessage.sendToParent( PostMessageSendEventType.commandExecute,
                        {
                            commandName: 'exportPNG',
                            documentId: this.resourceId,
                            result: {
                                dataUrl,
                                name: this.data.name,
                                type: this.data.type,
                            },
                        },
                    );
                }),
            )),
        ).subscribe();
        return of({});
    }

}

Object.defineProperty( ExportAsPngAPI, 'name', {
    value: 'ExportAsPngAPI',
});
