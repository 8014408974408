import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import imageCompression from 'browser-image-compression';
import { AbstractCommand, Command } from 'flux-core';
import { EMPTY, forkJoin, from, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ImportedFile } from '../../../framework/file/imported-file';

/**
 * This command can be used to compress images to be inserted into shapes.
 * Images will only be compressed if they are compressible, e.g. SVG would not be compressed.
 * It reads data from one or more {@link ImportedFile} objects and compresses them
 * to fit into the shape referenced in the position object. It then adds the result images to
 * the result data to be utilized by any other commands.
 *
 * Input data:
 *  {
 *      position: { shapeId: string, imageId: string },
 *      files: Array<ImportedFile>
 *  }
 * Result data:
 *  {
 *      files: Array<ImportedFile>
 *  }
 *
 * @author  Jerome
 * @since   2021-09-23
 */
@Injectable()
@Command()
export class CompressShapeImage extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: {
        position: { shapeId: string, imageId: string },
        files: Array<ImportedFile>,
    };

    /**
     * The maximum size for after compression.
     */
    protected MAXIMUM_IMAGE_SIZE_MB = 0.3;

    /**
     * Images types which can be compressed.
     */
    protected compressibleTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/gif',
        'image/bmp',
    ];

    constructor(
        protected ll: DiagramLocatorLocator,
    ) {
        super();
    }

    /**
     * Prepare command data
     */
    public prepareData(): any {
        if ( !this.data || !this.data.files || this.data.files.length < 1 || !this.data.position ) {
            return;
        }
    }

    /**
     * Read data from files, compress images and store in resultData
     */
    public execute(): Observable<any> {
        const importedFiles = this.data.files;
        const position = this.data.position;
        const observables = [];
        this.resultData = { files: []};

        importedFiles.forEach( importedFile => {
            // Scale and compress image to shape dimensions if image is compressible
            if ( this.isCompressible( importedFile )) {
                const obs = this.ll.forCurrentObserver( true ).pipe(
                    switchMap( locator => locator.getShapeModel( position.shapeId )),
                    take( 1 ),
                    map(( model: ShapeModel ) => model.drawWidth ),
                    switchMap( width => this.compressImage( importedFile.file, width )),
                    map( compressedFile => new ImportedFile( compressedFile )),
                    tap( compressedImportedFile => {
                        this.resultData.files.push( compressedImportedFile );
                    }),
                );

                observables.push( obs );

            // Else use the image as is
            } else {
                this.resultData.files.push( importedFile );
            }
        });

        if ( observables.length > 0 ) {
            return forkJoin( observables );
        }

        return EMPTY;
    }

    /**
     * Compresses the image and reduces it's size to the given width (height scales proportionately).
     * @param file The image file
     * @param width The width to scale to
     * @returns an observable that emits the compressed file
     */
    // Ignoring tests as the compressImage module exports a function and namespace
    // with the same name, making it difficult to mock
    /* istanbul ignore next */
    protected compressImage( file: File, width: number ) {
        return from( imageCompression( file, {
            maxSizeMB: this.MAXIMUM_IMAGE_SIZE_MB, maxWidthOrHeight: width }),
        );
    }

    /**
     * Checks if the image being imported can be compressed.
     * @param file imported file instance
     * @return true if image can be compressed
     */
    protected isCompressible( file: ImportedFile ) {
        return file.type && this.compressibleTypes.includes( file.type );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( CompressShapeImage, 'name', {
    value: 'CompressShapeImage',
});
