import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';

/**
 * class PolylineCanvasInstructionFactory
 * Generate canvas instruction for svg line element instructions
 */
export class PolylineCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'polyline';

    /**
     * Return polyline canvas instructions for given polyline shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        return this.applyTransformationsForMoveAndLines(
            this.generatePolylineInstructions( data.data.points ),
            data.transform,
        );
    }

}
