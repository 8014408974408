import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * TipTapEmojiListItem renders emoji item in the list
 */
@Component({
    selector: 'tiptap-emoji-list-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div class="tiptap-emoji-list-item-container" >
        <div class="tiptap-emoji-list-item-thumb" >{{data.emoji}}</div>
        <div class="tiptap-emoji-list-item-title fx-ellipsis" >{{data.name}}</div>
      </div>
    `,
    styleUrls: [ './tiptap-emoji-list-item.cmp.scss' ],
})
export class TipTapEmojiListItem {

    @Input()
    public data: ITipTapEmojiListItem;

}

export interface ITipTapEmojiListItem {
    emoji: string;
    emoticons: any[];
    fallbackImage: string;
    group: string;
    name: string;
    shortcodes: string[];
    tags: string[];
}
