import { StateService, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { SelectionStateService } from '../../selection/selection-state';
import { union } from 'lodash';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from 'apps/nucleus/src/base/diagram/diagram-change.svc';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';


/**
 * This command gets the ids of shape to remove from the diagram
 * data: {
 *     shapeIds - ids of the shape that needs to be removed
 *     selected - To indicate whether to delete the selected shapes or given ids.
 *                Delete the selected one if this is set to true or not given in the data
 * }
 */
@Injectable()
@Command()
export class GetRemovedShapeIds extends AbstractDiagramChangeCommand {

    public static get dataDefinition() {
        return {
            shapeIds: true,
            selected: false,
        };
    }

    protected state: SelectionStateService;

    constructor(  state: StateService<any, any>, ds: DiagramChangeService ) {
        super( ds )/* istanbul ignore next */;
        this.state = state;
    }

    public prepareData() {
        if ( !this.data ) {
            this.data = {};
        }
        if ( !this.data.shapeIds ) {
            this.data.shapeIds = [];
        }
    }

    public execute() {
        let shapeIds;
        if ( this.data.selected === false ) {
            shapeIds = this.data.shapeIds;
        } else {
            const selected: string[] = this.state.get( 'Selected' );
            shapeIds = union( this.data.shapeIds, selected );
        }

        const shapeIdsSet =  new Set( shapeIds ) as any;

        shapeIds.forEach( id => {
            this.removeCollapsedShapes( shapeIdsSet, id );
        });

        this.resultData = { shapeIds:  Array.from( shapeIdsSet ) };
        return true;
    }

    protected removeCollapsedShapes( shapesToRemove: Set<string>, shapeId: string ) {
        const shape = this.changeModel.shapes[shapeId] as ShapeModel;
        Object.values( shape.gluepoints || {})
            .filter( gp => gp.connectionState === 'collapsed' )
            .forEach( gp => {
                const { shapeIds, connectorIds } = this.changeModel.getAllShapesStartedFromGp( shape.id, gp.id );
                shapeIds.forEach( id => shapesToRemove.add( id ));
                connectorIds.forEach( id => shapesToRemove.add( id ));
            });
        return;
    }

}

Object.defineProperty( GetRemovedShapeIds, 'name', {
    value: 'GetRemovedShapeIds',
});
