import { Injectable } from '@angular/core';
import { Authentication, AuthenticationStatus } from 'flux-user';
import { Observable } from 'rxjs';
import { ContainerInfo } from '../system/container-info.svc';
import { AppPlatform, Logger } from 'flux-core';

/**
 * Authentication service for the app when running in plugin mode.
 */
@Injectable()
export class PluginAuthentication extends Authentication {
    /**
     * Returns the auth token of the logged in user.
     */
    public get token(): string {
        if ( !this.isAuthenticated ) {
            return null;
        }
        // NOTE: create a pseudo user auth token.
        return `token::${this.currentUserId}`;
    }

    /**
     * Returns the auth token of the logged in user.
     */
    public set token( token: string ) {
        Logger.error( 'Attempted to set the auth token for plugin auth service' );
    }

    /**
     * Returns whether a valid user has logged in.
     */
    public get isAuthenticated(): boolean {
        return !!this.currentUserId;
    }

    /**
     * Returns the logged-in user's id.
     */
    public get currentUserId(): string {
        // TODO: return the user id from app context
        return ContainerInfo.get().currentUserId || null;
    }

    /**
     * Returns true for platform access.
     */
     public isAppPlatformAuthorized( app: AppPlatform ) {
        return true;
    }

    /**
     * Logs the user out and clears all locally cached data.
     */
    public logOut(): Observable<any> {
        throw new Error( 'Method not supported.' );
    }

    /**
     * Override method which shows the gravity login widget.
     * @unsupported
     */
    public showLogin(): Observable<AuthenticationStatus> {
        throw new Error( 'Method not supported.' );
    }

    /**
     * Override method which shows the gravity signup widget.
     * @unsupported
     */
    public showSignUp(): Observable<AuthenticationStatus> {
        throw new Error( 'Method not supported.' );
    }

    /**
     * Initializes the authentication service.
     * FIXME: this is a bad way to stop init!
     * NOTE: do not call super.initialize();
     */
    public initialize(): void {
        // NOTE: do not initialize gravity!
        this.updateStates();
    }
}
