// import { Stage } from '@creately/createjs-module';
import { AbstractCommand, StateService, Command, CommandService } from 'flux-core';
import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { delay, filter, first, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { KeyCode } from 'flux-definition/src';
import { PlusCreateService } from '../../interaction/plus-create/plus-create-handler.svc';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { combineLatest, of } from 'rxjs';
import { PlusCreateHelpers } from '../../interaction/plus-create/plus-create-helpers';
// import { KeyboardPlusCreateInteractionEvent } from '../../interaction/event/keyboard-plus-create-interaction-event';

/**
 * This KeyboardPlusCreateInteraction command
 */
@Injectable()
@Command()
export class KeyboardPlusCreateInteraction extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            keyboardEvent: true, // The keyboard event related to feature list shortcut
        };
    }

    constructor(
        protected state: StateService<any, any>,
        protected commandService: CommandService,
        protected plusCreateService: PlusCreateService,
        protected defLocator: DefinitionLocator,
        protected ll: DiagramLocatorLocator,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute (): boolean {
        if ( !this?.data?.keyboardEvent ) {
            return false;
        }

        const { keyboardEvent } = this.data;
        const actionType = keyboardEvent.keyCode === KeyCode.Tab ? 'child' : 'sibling';
        const selectedShapes = this.state.get( 'Selected' );
        if ( selectedShapes.length !== 1 ) {
            return false;
        }
        const shapeId = selectedShapes[0];
        this.ll.forCurrentObserver( true ).pipe(
            first(),
            switchMap( o => o.getDiagramOnce()),
            switchMap( diagram => {
                const shape = diagram.shapes[shapeId];
                return combineLatest([ of( shape ), of( diagram ), this.defLocator.getDefinition( shape.defId ) ]);
            }),
            switchMap( v => this.waitUntilShapeTextEditorClosed().pipe( mapTo( v ))),
            tap(([ shape, diagram, def ]) => {
                const libId = shape.defId.split( '.' ) [ 1 ];
                if ( PlusCreateHelpers.isPlusCreateEnabled( def ) && !libId.includes( 'icons' )) {
                    this.plusCreateService.handleKeyboardAction( actionType, diagram, shape, def );
                }
            }),
        ).subscribe();
        return true;
    }

    protected waitUntilShapeTextEditorClosed() {
        return this.state.changes( 'EditingText' ).pipe(
            filter( state => !state.open ),
            take( 1 ),
            delay( 200 ),
        );
    }
}

Object.defineProperty( KeyboardPlusCreateInteraction, 'name', {
    value: 'KeyboardPlusCreateInteraction',
});
