import { ISidebarDropdown } from './sidebar-dropdown.i';
import { DropDownItem } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy, forwardRef, ElementRef, ViewEncapsulation } from '@angular/core';

/**
 * SidebarListDropdownItem
 * This component is a specific dropdown item component, created for nucleaus sidebar,
 * which extends the DropDownItem and expected to use for dropdowns with an icon and label
 */
@Component({
    selector: 'sidebar-list-dropdown-item',
    template: `
        <!-- can't focus a non input element without setting tabIndex,
            this list item should be captured as the activeElement when
            its clicked ( requred for isFocused method in sidebar )  -->
            <li tabindex="-1" class="sidebar-dd-item-container abs-dropdown-item fx-center-all"
            [class.has-icons]="item.itemIcon && item.label">
            <div class="btn-list-item btn-free-size fx-span-width fx-flex fx-align-center" [class.active]="selected">
                <static-image
                    *ngIf="item.itemIcon"
                    [enableHover]= "true"
                    [type]="item.itemIcon.type"
                    [value]="item.itemIcon.value"
                    [width]="70"
                    [height]="20">
                </static-image>

                <a *ngIf="item.label" class="sidebar-dd-item-label body fx-flex-expand" [innerHTML]="item.label | safeHTML" >
                </a>
            </div>
        </li>
    `,
    providers: [{
            provide: DropDownItem,
            useExisting: forwardRef(
            /* istanbul ignore next */ () => SidebarListDropdownItem /* istanbul ignore next */ ),
        }],
    styleUrls: [ './sidebar-list-dropdown-item.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,

    // The css classes of the element that is set to [innerHTML]
    // are removed unless encapsulation is set to none
    encapsulation: ViewEncapsulation.None,
})

export class SidebarListDropdownItem extends DropDownItem<ISidebarDropdown> {
    constructor( el: ElementRef ) {
        super( el );
    }
}
