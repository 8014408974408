import * as md5 from 'md5';
import { Observable, throwError, forkJoin, of, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { ResourceLoader, AppConfig, ScriptLoader, MapOf, Rectangle } from 'flux-core';
import {
    IDefinitionSummary,
    IConnectorDefinition,
    IShapeDefinition,
    IAbstractDefinition,
    ShapeType,
    IEDataDef,
    ResourceStatus,
} from 'flux-definition';
import { ConnectorRegistry } from './connector-registry.svc';
import { AbstractDefinitionLocator, DEFAULT_CONNECTOR } from 'flux-diagram-composer';
import { KeyBoardController } from '../../../system/keyboard-controller';
import { EDataRegistry } from '../../edata/edata-registry.svc';
import { cloneDeep } from 'lodash';
import { TemplateModel } from '../../../editor/diagram/templates/template.mdl';

/**
 * Declaire that we have access to __CreatelyShapes__ namespace on window object.
 */
declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        __CreatelyShapes__: { [ key: string ]: Function };
    }
}

/**
 * This is the definition location implementation for Nucleus. This is able to load a definition spec or source when
 * requested by the defId.
 *
 * @author Ramishka
 * @since 2017-09-10
 */
@Injectable()
export class DefinitionLocator extends AbstractDefinitionLocator {
    /**
     * In memory cache of definitions.
     */
    private cachedDefs = {};

    /**
     * This holds the latest version number of the shapes against
     * their shape def Id.
     * example: "creately.basic.cone : 4"
     */
    private shapesLatestVersion = {};

    private defSumLoaded = new BehaviorSubject( false );
    private manifestObs: Observable<MapOf<string>>;
    private diagramManifestObs: Observable<MapOf<string>>;

    constructor(
        protected loader: ResourceLoader,
        protected scriptLoader: ScriptLoader,
        protected connectorReg: ConnectorRegistry,
        protected eDataReg: EDataRegistry,
        protected injector: Injector,
        protected keyBoardController: KeyBoardController,
    ) {
        super()/* istanbul ignore next */;
    }

    /**
     * URL to access definitions
     */
    protected get definitionLocation(): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + AppConfig.get( 'SHAPE_RESOURCE_BASE' );
    }

    /**
     * URL to access definitions
     */
    protected get diagramDefinitionLocation(): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + 'diagram/def/';
    }

    /**
     * Key name to access definition summery data from browser data store
     */
    protected get definitionSummaryKey(): string {
        return this.definitionLocation + 'def-sum.json';
    }

    /**
     * key name to access the shape-library-map file
     */
    protected get shapeLibraryMapKey(): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + AppConfig.get( 'LIBRARY_RESOURCE_BASE' ) + 'shape-libs.json';
    }

    /**
     * Key name to access shape manifest.json data from browser data store
     */
    protected get shapeManifestKey(): string {
        return this.definitionLocation + 'manifest.json';
    }

    /**
     * Key name to access diagram manifest.json data from browser data store
     */
    protected get diagramManifestKey(): string {
        return this.diagramDefinitionLocation + 'manifest.json';
    }

    /**
     * Returns all the deinitions as an observable
     */
    public getDefs(): Observable<{ defs: IDefinitionSummary[]}> {
        return this.loader.load( this.definitionSummaryKey );
    }

    /**
     * Returns all cached shape definitions.
     * This will return the IAbstractDefinition object.
     */
    public getCachedDefs() {
        return this.cachedDefs;
    }

    /**
     * This function checks the shapes latest version number
     * from the def sum and store it in a variable. This will be
     * used to identify the latest version number for the given shape
     * in "getDefVersion" method.
     */
    public prepareShapesLatestVersion() {
        this.manifestObs = this.loader.getServerResource( this.shapeManifestKey ).pipe(
            tap( manifest => this.manifestObs = of( manifest )),
        );
        this.diagramManifestObs = this.loader.getServerResource( this.diagramManifestKey ).pipe(
            tap( manifest => this.diagramManifestObs = of( manifest )),
        );
        this.manifestObs.subscribe();
        this.getDefs().pipe(
            take( 1 ),
            tap( defSum => {
                defSum.defs.forEach( def => {
                    if ( !this.shapesLatestVersion[def.defId]) {
                        this.shapesLatestVersion[def.defId] = def.version;
                    } else if ( this.shapesLatestVersion[def.defId] < def.version ) {
                        this.shapesLatestVersion[def.defId] = def.version;
                    }
                });
                this.defSumLoaded.next( true );
            }),
        ).subscribe();
    }

    /**
     * This method retrieves the JSON definition of a shape for the given defId. It uses the resource loader to load
     * the json either from the serving location or the local cache.
     *
     * @param defId is the uniqe definition id
     * @param version is the optional version of the spec.
     */
    public getDefinition( defId: string, version?: number, clone = true ): Observable<IAbstractDefinition> {
        const cacheKey = `${defId}.${version}`;
        if ( this.cachedDefs[cacheKey]) {
            if ( clone ) {
                return of( cloneDeep( this.cachedDefs[cacheKey]));
            }
            return of( this.cachedDefs[cacheKey]);
        }
        if ( !defId ) {
            return throwError( new Error( 'The definition id to be loaded was invalid.' ));
        }
        if ( version < 0 ) {
            return throwError( new Error( 'Definition version provided for def ' + defId + ' was invalid.' ));
        }
        return this.defSumLoaded.pipe(
            filter( loaded => !!loaded || this.getDefVersion( defId, version ) > -1 ),
            take( 1 ),
            switchMap(() => {
                const defVersion = this.getDefVersion( defId, version );
                const defLocation = this.getDefLocation( defId, defVersion );
                return forkJoin( this.getDefSummary( defId, defVersion ), this.loader.load( defLocation )).pipe(
                    switchMap(([ defSum, def ]) => {
                        if ( this.shouldForceLoad( defSum, def )) {
                            return this.loader.forceLoad( defLocation );
                        }
                        if ( clone ) {
                            return of( cloneDeep( def ));
                        }
                        return of( def );
                    }),
                    take( 1 ),
                    tap( definition => {
                        this.cachedDefs[cacheKey] = cloneDeep( definition );
                        this.onDefinitionLoad( definition );
                    }),
                );
            }),
        );
    }

    /**
     * This method will load the source files of the given path and return the entry class of the given className.
     * The url parameter should have the class name as the hash section of the url as "./path/to/file.js#ClassName".
     * Emits a null value if the given identifier is empty or invalid.
     */
    public getClass( classIdentifier: string ): Observable<Function> {
        if ( !classIdentifier ) {
            return of( null );
        }
        const [ classPath, className ] = this.getClassParameters( classIdentifier );
        const cachedSource = this.getCachedSourceFile( className );
        if ( cachedSource ) {
            return of( cachedSource );
        }
        return this.loadScript( classPath ).pipe(
            map(() => this.getCachedSourceFile( className )),
        );
    }

    /*
     * This method will get the cached source files of the given class and return the
     * entry class of the given className.
     * @param className is the class name to load
     */
    public getCachedClass( classIdentifier: string ): Function {
        const  className = this.getClassParameters( classIdentifier )[1];
        const cachedSource = this.getCachedSourceFile( className );
        if ( !cachedSource ) {
            throw new Error( 'There were no cached source file for ' + className );
        }
        return cachedSource;
    }

    /**
     * Adds a definition file to the cache.
     */
    public cacheDefinition( definition: IAbstractDefinition ): Observable<any> {
        const defVersion = this.getDefVersion( definition.defId, definition.version );
        const defLocation = this.getDefLocation( definition.defId, defVersion );
        this.cachedDefs[definition.defId + '.' + definition.version] = definition;
        return this.loader.store( defLocation, definition, ResourceStatus.Uploaded );
    }

    /**
     * Adds a source file to the cache.
     */
    public cacheSourceFile( sourceClass: Function, className: string ): void {
        window.__CreatelyShapes__ = window.__CreatelyShapes__ || {};
        window.__CreatelyShapes__[ className ] = sourceClass;
    }

    /**
     * This function retrieves the version of the def id to be loaded. If def it is not given, it fetches the latest
     * version for the given def id from def-sum.json.
     *
     * @param defId definition id
     * @param version definition version (optional)
     */
    public getDefVersion( defId: string, version?: number ): number {
        if ( defId === 'creately.connector.default' ) {
            return DEFAULT_CONNECTOR.version;
        }
        if ( !version ) {
            return this.shapesLatestVersion[defId];

        } else  {
            return version;
        }
    }

    /**
     * This method is used to get the bounds of the template.
     * @param template
     * @returns
     */
    public async getTemplateBounds( template: TemplateModel ) {
        if ( template.bounds ) {
            return template.bounds;
        }
        const rect = new Rectangle();
        const shapesArray = Object.values( template.content?.shapes || {})
            .filter(( shape: any ) => shape.type !== 'connector' );
        for ( let index = 0; index < shapesArray.length; index++ ) {
            const shape = shapesArray[index] as any;
            const def = await this.getDefinition( shape.defId, shape.version ).toPromise() as any;
            const width = def.defaultBounds.width * ( shape.scaleX || 1 );
            const height = def.defaultBounds.height * ( shape.scaleY || 1 );
            const bounds = new Rectangle( shape.x, shape.y, width, height );
            rect.absorb( bounds );
        }
        return rect;
    }

    /**
     * Returns all the libraries a def belongs to
     */
    public getDefLibraries( defId, version ) {
        return this.loader.load( this.shapeLibraryMapKey ).pipe(
            map( mapfile => mapfile[ `${defId}.${version}` ]),
        );
    }

    /**
     * This method will load the given source array of the definition to make available
     * withing the environment.
     * NOTE: It is assumed that source array contains relative paths to the script file, from
     * resource base url.
     * @param source Array of strings which are source urls
     */
    protected getSourceFiles( source: string[]): Observable<any>  {
        if ( !source || source.length === 0 ) {
            return of([]);
        }
        return forkJoin( source.map( url => this.loadScript( url )));
    }

    protected loadScript( url: string ) {
        const fileName = url.split( '/' ).pop();
        const manifestObs = url.startsWith( 'diagram/def/' ) ? this.diagramManifestObs : this.manifestObs;
        return manifestObs.pipe(
            switchMap( manifest => {
                if ( manifest[fileName]) {
                    url = url.slice( 0, url.lastIndexOf( '/' ) + 1 ) + manifest[fileName];
                }
                return this.scriptLoader.load( this.getSourcePath( url ));
            }),
        );
    }

    /**
     * Given a incomplete path of a file specified in a definition this returns the
     * fully qualified url to the resource based on the current env.
     * @param incompletePath The relative path of a file as given in the def
     */
    protected getSourcePath( incompletePath: string ): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + incompletePath;
    }

    /**
     * Returns the cached entry class.
     */
    protected getCachedSourceFile( name: string ): Function {
        return window.__CreatelyShapes__[ name ];
    }

    /**
     * Invoked after a definition is loaded. Contains functionality to be
     * performed after a definition load.
     * @param def - definition
     */
    private onDefinitionLoad( def: IAbstractDefinition ): void {
        if ( def.type === ShapeType.EData ) {
            this.eDataReg.register( def as IEDataDef );
        }
        if ( def.type === ShapeType.Connector ) {
            // FIXME: fix connector registry to replace the def if it has changed.
            // for the moment it will just ignore it if it is already loaded.
            this.connectorReg.register( def as IConnectorDefinition );
        }  else {
            // featurelist is retrieved from injector to avoid cyclic dependency
            const featureList = this.injector.get( 'FeatureList' );
            featureList.registerShapeFeatures( def as IShapeDefinition );
            this.keyBoardController.registerFeatures();
        }
    }

    /**
     * Returns the definition json file location.
     */
    private getDefLocation( defId: string, version: number ): string {
        return this.definitionLocation + defId + '.' + version + '.json';
    }

    /**
     * Locate given summary of a shape definition on the definition summary and return an Observable which will emit
     * summary data when suscribed. Definition ID must have the full URL to the resource on the server since the same
     * URL act as the key for the browser cache.
     */
    private getDefSummary( defId: string, version: number ): Observable<IDefinitionSummary> {
        return this.loader.load( this.definitionSummaryKey ).pipe(
            map( summary => summary.defs.find( d => d.defId === defId && d.version === version )),
        );
    }

    /**
     * Check if a given cached definition is outdated, if outdated hashes do not match. Will return true if it is
     * outdated and must be reloaded from server.
     *
     * @param defSum IDefinitionSummary
     * @param def IAbstractDefinition
     * @return boolean
     */
    private shouldForceLoad( defSum: IDefinitionSummary, def: IAbstractDefinition ): boolean {
        if ( !defSum ) {
            return false;
        }
        const defHash: string = md5( JSON.stringify( def ));
        return defSum.hash !== defHash;
    }
}
