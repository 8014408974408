import { Injectable, Inject } from '@angular/core';
import { StateService } from './state.svc';
import { Animation } from '../ui/animation';
import { filter, take } from 'rxjs/operators';

/**
 * This defines the information needed to create a modal.
 */
export interface IModalOptions {
    /**
     * An object with all the inputs that should be set to the
     * ModalWinodw component instance.
     * Object keys should have the same name as the input field.
     */
    inputs?: { [inputName: string]: any };

    /**
     * Define the tranistion that animate when the modal view enters
     */
    enterAnimation?: Animation;

    /**
     * Define the transition that animate when the modal view is get removed
     */
    leaveAnimation?: Animation;
}

/**
 * This defines the information that get passed to the modal container
 * to show/hide the modal.
 */
export interface IModalData {
    show: boolean; // Indicates whether the modal needs to be show/hide
    type?: any; // Modal window component that needs to be show/hide
    options?: IModalOptions; // Modal options
    blur?: boolean; // Blurs the background behind the modal window
}

/**
 * StateService representation for modal window show/hide
 */
export class ModalWindowStateService extends StateService< 'ModalWindow', IModalData> {}

/**
 * This is responsible for displaying a modal window all across the application.
 *
 * One modal should be present at a time in the application. Whenever user needs to show a modal window
 * existing modal should be destroyed and new modal window should be shown.
 *
 * Add/Remove modal is controlled through this controller therefore this service needs to be injected
 * whereever user wants to show/hide the modal.
 */
@Injectable()
export class ModalController {


    constructor( @Inject( StateService ) protected state: ModalWindowStateService ) {}

    /**
     * This shows the modal window instance.
     * This emits a value to the onEnter subscriber to show the
     * modal window.
     * As a default backgrounds behind all modal windows should be blurred. Therefore
     * blur property is set to true by default and can be overridden when needed.
     * model window first hide change will emit.
     * @param type The modal window component to display.
     * @returns Observable<IModalData> will return ModalWindow state change.after getting
     *  first false value observable will get completed
     */
    public show( type: any, opt?: IModalOptions, blur: boolean = true ) {
        this.state.set( 'ModalWindow', { show: true, type: type, options:  opt, blur: blur });
        return this.state.changes( 'ModalWindow' ).pipe(
            filter( change => !change.show ),
            take( 1 ),
            );
    }

    /**
     * This hides the modal window instance.
     * This emits a value to the onDismiss subscriber to hide the
     * modal window.
     */
    public hide() {
        this.state.set( 'ModalWindow', { show: false });
    }

    /**
     * This hides the modal window instance.
     * This emits a value to the onDismiss subscriber to hide the
     * modal window.
     */
    public hideByType( type: any ) {
        const modalWindowState = this.state.get( 'ModalWindow' );
        if ( modalWindowState?.type && modalWindowState.type === type ) {
            this.state.set( 'ModalWindow', { show: false });
        }
    }

}
