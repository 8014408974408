import { Injectable } from '@angular/core';
import { Command, ImageLoader, CommandInterfaces, StateService } from 'flux-core';
import { DiagramSVGView } from 'flux-diagram-composer';
import { DataStore } from 'flux-store';
import { combineLatest, concat, defer, Observable, of, from } from 'rxjs';
import { ignoreElements, take, tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { AbstractPluginCommand } from './abstract-plugin-cmd';

/**
 * Sends the locally saved document to the server to save it.
 * This command will also remove all local changes of the document.
 */
@Injectable()
@Command()
export class SaveDocument extends AbstractPluginCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ ...AbstractPluginCommand.implements, 'IDiagramCommand' ];
    }

    /**
     * Inject the data store service for local cache.
     */
    constructor(
        private ll: DiagramLocatorLocator,
        private resources: ImageLoader,
        private store: DataStore,
        private state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Store the user info in the datastore.
     */
    public prepareData(): Observable<any> {
        const store = this.store.getModelStore( DiagramModel );
        const selector = { id: this.resourceId };
        return combineLatest(
            store.findOneRawModel( selector ),
            store.findOneStatic( selector ),
            this.createDocumentSvg( this.resourceId ),
        ).pipe(
            take( 1 ),
            tap(([ model, modelStatic, svg ]) => {
                this.data = { model, modelStatic, svg };
            }),
        );
    }

    /**
     * Update the document on the client to include all unsaved changes.
     */
    public execute(): Observable<unknown> {
        const store = this.store.getModelStore( DiagramModel );
        return from( store.insert( this.data.model ));
    }

    /**
     * Remove all the changes after saving the document successfully.
     */
    public executeResult(): Observable<unknown> {
        const store = this.store.getModelStore( DiagramModel );
        return from( store.removeChanges({ modelId: this.resourceId }));
    }

    /**
     * Returns an observable which will emit the SVG of the diagram once.
     */
    private createDocumentSvg( id: string ): Observable<string> {
        const locator = this.ll.forDiagram( id, true );
        const svgview = new DiagramSVGView( of( locator ), this.resources, this.state );
        return concat(
            defer(() => svgview.populateDiagram().pipe( ignoreElements())),
            defer(() => svgview.toSvg()),
        );
    }
}

Object.defineProperty( SaveDocument, 'name', {
    writable: true,
    value: 'SaveDocument',
});
