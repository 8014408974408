import { AbstractTab } from './tab/abstract-tab.cmp';
import { AbstractDropDown } from './drop-down/abstract-dropdown/abstract-dropdown.cmp';
import { ThumbnailCarousalItem } from './thumbnail-carousel/thumbnail-carousel-item.cmp';
import { ThumbnailCarousal } from './thumbnail-carousel/thumbnail-carousel.cmp';
import { ScrollArea } from './scroll-area.cmp';
import { LandscapeClass } from './landscape-class.dir';
import { FloatingButton } from './floating-button.cmp';
import { NgModule } from '@angular/core';
import { LoadingIndicator } from './loading-indicator.cmp';
import { ErrorView } from './error-view/error-view.cmp';
import { CommonModule } from '@angular/common';
import { ToolTip } from './tooltip/tooltip.dir';
import { ViewStack } from './view-stack/view-stack.cmp';
import { LinkThumbnailImage } from './link-thumbnail-image.cmp';
import { CollapsibleMenu } from './collapsible-menu/collapsible-menu';
import { CollapsibleMenuItem } from './collapsible-menu/collapsible-menu-item.cmp';
import { AppCollapsibleMenu } from './collapsible-menu/app-collapsible-menu.cmp';
import { AppCollapsibleMenuItem } from './collapsible-menu/app-collapsible-menu-item.cmp';
import { DropDown } from './drop-down/drop-down.cmp';
import { MultipleEmailsInput } from './multiple-email-input/multiple-emails-input.cmp';
import { ModalWindowContainer } from './modal-window/modal-window-container.cmp';
import { RadioButton } from './radio-button/radio-button.cmp';
import { TagsInputField } from './tags-input-field/tags-input-field.cmp';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToggleButton } from './toggle-button/toggle-button.cmp';
import { TextInput } from './text-input/text-input.cmp';
import { FormsModule } from '@angular/forms';
import { EditableLabel } from './editable-label/editable-label.cmp';
import { LottieModule } from 'ngx-lottie';
import { UserImage } from './user-image/user-image.cmp';
import { MainLoader } from './main-loader/main-loader.cmp';
import { TagItem } from './tags-input-field/tag-item.cmp';
import { SimpleDropdownItem } from './drop-down/simple-dropdown/simple-dropdown-item.cmp';
import { SimpleDropdownButton } from './drop-down/simple-dropdown/simple-dropdown-button.cmp';
import { DialogBox } from './dialog-box/dialog-box.cmp';
import { SafePipe, SafeHTMLPipe, SanitizeHTMLPipe } from './safe-pipe';
import { DialogBoxController } from '../controller/dialog-box/dialog-box-controller';
import { PerfectScrollbarModule,
    PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PopupWindow } from './popup-window/popup-window.cmp';
import { FullScreenModalWindow } from './modal-window/fullscreen-modal-window.cmp';
import { CheckBox } from './check-box/check-box.cmp';
import { LoadingPopup } from './loading-popup/loading-popup.cmp';
import { LoadingBubbles } from './loading-bubbles/loading-bubbles.cmp';
import { AppNotifier } from './notifier/app-notifier.cmp';
import { NotificationCount } from './notifier/notification-count/notification-count.cmp';
import { AbstractNotification } from './notifier/abstract-notification/abstract-notification.cmp';
import { Badge } from './badge/badge.cmp';
import { MediaCard } from './media-card/media-card.cmp';
import { FloatingContainer } from './floating-container/floating-container.dir';
import { MessageCard } from './message-card/message-card.cmp';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { DynamicComponentService } from './dynamic.cmp.svc';
import { UppyFileImporterComponent } from './uppy-file-importer/uppy-file-importer.cmp';
import { GenericButton } from './button/generic-button.cmp';
import { VerticalContainer } from './layouting/vertical-container.cmp';
import { HorizontalContainer } from './layouting/horizontal-container.cmp';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

@NgModule({
    declarations: [
        MediaCard,
        LoadingIndicator,
        ErrorView,
        FloatingButton,
        ToolTip,
        FloatingContainer,
        ViewStack,
        LandscapeClass,
        ScrollArea,
        LinkThumbnailImage,
        ThumbnailCarousal,
        ThumbnailCarousalItem,
        CollapsibleMenu,
        CollapsibleMenuItem,
        AppCollapsibleMenu,
        AppCollapsibleMenuItem,
        TagItem,
        MainLoader,
        EditableLabel,
        DropDown,
        MultipleEmailsInput,
        ModalWindowContainer,
        RadioButton,
        TagsInputField,
        TextInput,
        ToggleButton,
        AbstractDropDown,
        VerticalContainer,
        HorizontalContainer,
        AbstractTab,
        UserImage,
        SimpleDropdownItem,
        SimpleDropdownButton,
        DialogBox,
        SafePipe,
        SafeHTMLPipe,
        SanitizeHTMLPipe,
        PopupWindow,
        FullScreenModalWindow,
        CheckBox,
        LoadingBubbles,
        LoadingPopup,
        AppNotifier,
        AbstractNotification,
        Badge,
        NotificationCount,
        MessageCard,
        UppyFileImporterComponent,
        GenericButton,
    ],
    imports: [
        CommonModule,
        UiSwitchModule,
        FormsModule,
        PerfectScrollbarModule,
        TranslateModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [
        MediaCard,
        LoadingIndicator,
        ErrorView,
        FloatingButton,
        ToolTip,
        FloatingContainer,
        ViewStack,
        LandscapeClass,
        ScrollArea,
        LinkThumbnailImage,
        ThumbnailCarousal,
        ThumbnailCarousalItem,
        CollapsibleMenu,
        CollapsibleMenuItem,
        AppCollapsibleMenu,
        AppCollapsibleMenuItem,
        TagItem,
        MainLoader,
        EditableLabel,
        DropDown,
        MultipleEmailsInput,
        ModalWindowContainer,
        RadioButton,
        TagsInputField,
        ToggleButton,
        TextInput,
        AbstractDropDown,
        VerticalContainer,
        HorizontalContainer,
        AbstractTab,
        UserImage,
        SimpleDropdownItem,
        SimpleDropdownButton,
        DialogBox,
        SafePipe,
        SafeHTMLPipe,
        SanitizeHTMLPipe,
        PerfectScrollbarModule,
        PopupWindow,
        FullScreenModalWindow,
        CheckBox,
        LoadingBubbles,
        LoadingPopup,
        AppNotifier,
        AbstractNotification,
        Badge,
        NotificationCount,
        MessageCard,
        UppyFileImporterComponent,
        GenericButton,
    ],
    entryComponents: [
        LoadingPopup,
        AbstractNotification,
        AbstractTab,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        DynamicComponentService,
    ],

})
export class FluxCoreUI {
}


@NgModule({
    providers: [
        DialogBoxController,
    ],
})
export class FluxCoreDialogBoxController {
}

/**
 * We need a separate function as it's required
 * by the AOT compiler.
 */
/* istanbul ignore next */
// tslint:disable-next-line:only-arrow-functions
export function playerFactory() {
    return player;
}
