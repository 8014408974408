<div class="fullscreen-container ne-red fx-cover fx-center-all fx-center">

    <div class="fullscreen-message">
        <img src="./assets/images/logo-on-dark-200X80.svg" alt="Creately" class="logo">
        <h1 class="title" translate>MESSAGES.SERVER_ERROR_HEADING</h1>
        <p class="body fx-margin-top-15" translate>MESSAGES.SERVER_ERROR</p>
    </div>

    <button class="btn-medium btn-secondary fullscreen-button">
        <span translate>BUTTONS.TRY_NOW</span>
    </button>
   
    <div class="fullscreen-footer">
        <p class="body fx-margin-top-15"  [innerHTML]="'MESSAGES.SERVER_ERROR_FOOTER' | translate"></p>
    </div>
</div>