import { Injectable } from '@angular/core';
import { StateService } from 'flux-core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * This command is an abstraction for all commands that alter the index of a
 * shape.
 *
 * @author  Ramishka
 * @since   2019-01-31
 */
@Injectable()
export class AbstractIndexChangeCommand extends AbstractDiagramChangeCommand {

    /**
     * Input data format for this command.
     * The list of shape ids of which the zIndex is being altered in can be
     * passed in to the command. If this is not passed in, shapes in
     * the current selection will be modified.
     */
    public data: {
        shapeIds?: Array<string>,
    };

    constructor( protected state: StateService<any, any>,
                 protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Returns a the list of shape ids the zIndex needs to be altered.
     * If the shape ids are not passed in, shapes in the current selection
     * are considered.
     * @return list of shape ids
     */
    protected getShapeIds(): Array<string> {
        let shapeIds = this.data.shapeIds;
        if ( !shapeIds || shapeIds.length === 0 ) {
            shapeIds = this.state.get( 'Selected' );
        }
        if ( !shapeIds || shapeIds.length === 0 ) {
            throw new Error( 'An index change command cannot be fired without any shapes.' );
        }
        return shapeIds;
    }
 }
