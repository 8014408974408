import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * TiptapMentionsListItem renders mention list item with image and name
 */
@Component({
    selector: 'tiptap-mentions-list-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div class="tiptap-mentions-list-item-container" >
        <div class="tiptap-mentions-list-item-thumb" >
            <user-image
                    imageSize="xxsmall"
                    [hasImage]="data.item.hasImage"
                    [imagePath]="data.item.image"
                    [userColor]="data.item.displayColor"
                    [userInitials]="data.item.initial"
                    [userRegistered]="data.item.isRegistered"
                    [userOnlineStatus]="data.item.online">
            </user-image>
        </div>
        <div class="tiptap-mentions-list-item-info" >
            <div class="tiptap-mentions-list-item-title">{{data.title}}</div>
        </div>
      </div>
    `,
    styleUrls: [ './tiptap-mentions-list-item.cmp.scss' ],
})
export class TiptapMentionsListItem {

    @Input()
    public data: ITiptapMentionsListItem;

}

export interface ITiptapMentionsListItem {
    thumbnail: string;
    title: string;
    subTitle: string;
    item: any;
}
