import { cloneDeep } from 'lodash';
import { ITemplateDefinition, ShapeType, ILineStyle, IPoint2D, LibraryState } from 'flux-definition';
import { AbstractModel, Random } from 'flux-core';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { IConnectorPoint, IConnectorEndPoint } from 'flux-diagram-composer';

/**
 * Data available in a diagram template.
 */
export interface ITemplateContent {
    connections?: any;
    groups?: any;
    shapes?: any;
    dataDefs?: any;
}

/**
 * The template model represents a diagram template. This model will be
 * used to store templates using the data store service.
 *
 * NOTE: This model will not be available on the diagram model.
 */
export class TemplateModel extends AbstractModel implements ITemplateDefinition {
    /**
     * The id of the template eg: `${defId}:${version}`
     */
    public id: string;

    /**
     * The definition id.
     */
    public defId: string;

    /**
     * The expected topleft position
     */
    public bounds?: { x: number, y: number, width: number, height: number };

    /**
     * The definition version.
     */
    public version: number;

    /**
     * Type of the shape. This will be Template for these shapes.
     */
    public type = ShapeType.Template;

    /**
     * A displayable name for the definition or shape type.
     */
    public name: string;

    /**
     * An explainer string for the definition or shape type.
     */
    public description?: string;

    /**
     * The id of the diagram the template is based on.
     */
    public diagram: string;

    /**
     * Shape, groups and connections added by the template.
     */
    public content: ITemplateContent;

    /**
     * Libraries to load for the template. Optional for templates
     */
    public libraries?: [ string, LibraryState ][];

    /**
     * NOTE: this property is not available for templates.
     */
    public style?: ILineStyle;

    /**
     * NOTE: this property is not available for templates.
     */
    public source?: string[];

    /**
     * NOTE: this property is not available for templates.
     */
    public entryClass?: string;

    /**
     * Clones all shapes and other data inside the template.
     * FIXME: add support for connectors in templates
     */
    public clone( position: IPoint2D ): ITemplateContent {
        const cloned: ITemplateContent = {
            shapes: {},
            groups: {},
            connections: {},
            dataDefs: {},
        };
        const oldShapeIds = Object.keys( this.content.shapes || {});
        const oldDataDefsIds = Object.keys( this.content.dataDefs || {});
        const oldGroupIds = Object.keys( this.content.groups || {});
        const oldConnectionIds = Object.keys( this.content.connections || {});
        const newShapeIds: { [id: string]: string } = {};
        const newGroupIds: { [id: string]: string } = {};
        const newConnectionIds: { [id: string]: string } = {};
        const newDataDefsIds: { [id: string]: string } = {};
        for ( const oldShapeId of oldShapeIds ) {
            newShapeIds[oldShapeId] = Random.shapeId();
        }
        for ( const oldGroupId of oldGroupIds ) {
            newGroupIds[oldGroupId] = Random.groupId();
        }
        for ( const oldDataDefsId of oldDataDefsIds ) {
            newDataDefsIds[oldDataDefsId] = Random.dataItemId();
        }
        for ( const oldConnectionId of oldConnectionIds ) {
            newConnectionIds[oldConnectionId] = Random.connectionId();
        }
        for ( const oldShapeId of oldShapeIds ) {
            const newShapeId = newShapeIds[oldShapeId];
            const shape = cloneDeep( this.content.shapes[oldShapeId]);
            shape.id = newShapeId;

            if ( shape.dataSetId  ) {
                shape.dataSetId = newDataDefsIds[ shape.dataSetId ];
            }

            if ( shape.type === ShapeType.Connector ) {
                if ( shape.connectionId ) {
                    shape.connectionId = newConnectionIds[shape.connectionId];
                }
                const points: IConnectorPoint[] = ConnectorModel.getPathPoints( shape.path );
                const head: IConnectorEndPoint = shape.path[shape.path.headId];
                const tail: IConnectorEndPoint = shape.path[shape.path.tailId];
                if ( head.shapeId ) {
                    head.shapeId = newShapeIds[head.shapeId];
                }
                if ( tail.shapeId ) {
                    tail.shapeId = newShapeIds[tail.shapeId];
                }
                for ( const point of points ) {
                    point.x += position.x;
                    point.y += position.y;
                    if ( point.c1 ) {
                        point.c1.x += position.x;
                        point.c1.y += position.y;
                    }
                    if ( point.c2 ) {
                        point.c2.x += position.x;
                        point.c2.y += position.y;
                    }
                }
            } else {
                if ( shape.containerId ) {
                    shape.containerId = newShapeIds[shape.containerId];
                }
                if ( shape.containerRegions ) {
                    Object.values( shape.containerRegions ).forEach(( val: any ) => {
                        const shapes = {};
                        if ( val.shapes ) {
                            Object.keys( val.shapes ).forEach( key => {
                                shapes[newShapeIds[ key ]] = val.shapes[key];
                            });
                            val.shapes = shapes;
                        }
                    });
                }
                if ( shape.children ) {
                    Object.keys( shape.children ).forEach( childId => {
                        const child = shape.children[childId];
                        const newChildId = newShapeIds[childId];
                        shape.children[newChildId] = child;
                        delete shape.children[childId];
                    });
                }
                shape.x += position.x;
                shape.y += position.y;
            }
            cloned.shapes[newShapeId] = shape;
        }
        for ( const oldGroupId of oldGroupIds ) {
            const group = cloneDeep( this.content.groups[oldGroupId]);
            if ( group.shapes ) {
                group.shapes = group.shapes.map( id => newShapeIds[id]);
            }
            if ( group.groups ) {
                group.groups = group.groups.map( id => newGroupIds[id]);
            }
            const newGroupId = newGroupIds[oldGroupId];
            cloned.groups[newGroupId] = group;
        }
        for ( const oldConnectionId of oldConnectionIds ) {
            const connection = cloneDeep( this.content.connections[oldConnectionId]);
            if ( connection.shapeA ) {
                connection.shapeA.shapeId = newShapeIds[connection.shapeA.shapeId];
            }
            if ( connection.shapeB ) {
                connection.shapeB.shapeId = newShapeIds[connection.shapeB.shapeId];
            }
            const newConnectionId = newConnectionIds[oldConnectionId];
            cloned.connections[newConnectionId] = connection;
        }
        for ( const oldDataDefsId of oldDataDefsIds ) {
            const datadef = cloneDeep( this.content.dataDefs[oldDataDefsId]);
            const newId = newDataDefsIds[oldDataDefsId];
            cloned.dataDefs[newId] = datadef;
        }
        return cloned;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( TemplateModel, 'name', {
    value: 'TemplateModel',
});
