import { Notifications } from './../../../../../base/notifications/notification-messages';
import { TranslateService } from '@ngx-translate/core';
import { SETTINGS_DATAITEM_ID } from './../../../../../base/ui/shape-data-editor/shape-data-settings.cmp';
import { filter, last, map, take, tap, switchMap } from 'rxjs/operators';
import { Suggestion } from '@tiptap/suggestion';
import { Editor, Extension } from '@tiptap/core';
import { DynamicComponent } from 'flux-core/src/ui';
import { DiagramCommandEvent } from 'apps/nucleus/src/editor/diagram/command/diagram-command-event';
import { DataItemsRenderer, DESCRIPTION_DATAITEM_ID } from './../../../../../base/ui/shape-data-editor/data-items-renderer.cmp';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { CommandService, StateService, Random, AppConfig,
    NotifierController, NotificationType, AbstractNotification, Tracker } from 'flux-core';
import { Injector, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { TipTapSlashCmdListCmp } from './tiptap-slash-cmd-list.cmp';
import { go } from 'fuzzysort';
import { Subscription, BehaviorSubject, EMPTY, of } from 'rxjs';
import { DataitemsPicker } from '../../dataitems-picker.cmp';
import { ImportedFile } from '../../../../file/imported-file';
import { TaskSummaryService } from '../../../../../base/task/task-summary.svc';
// import { CoreDataFieldService } from '../../../../../base/data-defs';
// import { CoreDataItemsPicker } from '../../core-dataitems-picker.cmp';
// import { without } from 'lodash';

// tslint:disable:member-ordering
/**
 * TiptapSlashCommandsService
 * This service contains all the slash command items and it returns
 * the config object required by the tiptap's Suggestion extention.
 *
 * All the static slash commands items are included in the commandsList array
 * and it also can be altered to add/remove items dynamically
 */
export class TiptapSlashCommandsService {

   /**
    * The duration of the notification in ms
    */
    protected notificationAutoclose: number = 3000;

    /**
     * Max file size allowed in bytes
     */
     protected maxFileSize: number = 5000000;

     /**
      * Allowed file types. Setting null will allow any type
      */
     protected allowedFileTypes: string[] = null;

    /**
     * Returns 'items' list and 'render' which are required by the tiptap
     * editor suggestions extension
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create(
        injector: Injector, viewCR: ViewContainerRef, diagramId: string,
        shapeIdSubject: BehaviorSubject<string> ) {
            const cfr = injector.get( ComponentFactoryResolver );
            const commandService = injector.get( CommandService );
            const stateSvc = injector.get( StateService );
            const ll = injector.get( DiagramLocatorLocator );
            const translate = injector.get( TranslateService );
            const notifierController = injector.get( NotifierController );
            const taskSummary = injector.get( TaskSummaryService );
            const instance = new TiptapSlashCommandsService(
                cfr, injector, commandService, stateSvc, ll, viewCR, translate, notifierController,
                taskSummary, diagramId, shapeIdSubject,
            );
            return { items: instance.items, render: instance.render, subs: instance.subs };
    }

    public subs: Subscription[] = [];

    private constructor(
        protected cfr: ComponentFactoryResolver,
        protected injector: Injector,
        protected commandService: CommandService,
        protected stateSvc: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected viewCR: ViewContainerRef,
        protected translate: TranslateService,
        protected notifierController: NotifierController,
        protected taskSummary: TaskSummaryService,
        protected diagramId: string,
        protected shapeIdSubject: BehaviorSubject<string>, // = 'RllLJF8YSEY',
    ) {
        this.handleDynamicSlashCommnds();
    }

    protected commandsListCached = [];

    protected handleDynamicSlashCommnds() {
        const translate = this.translate.instant.bind( this.translate );

        /**
         * Adds Existing Data Field dynamically
         */
        const existingFieldsSub = this.shapeIdSubject.pipe( switchMap( shapeId =>
            !shapeId ? EMPTY :
            this.ll.forDiagram( this.diagramId, false )
            .getDiagramOnce())).subscribe( d => {
                const edataId = d.shapes[ this.shapeIdSubject.value ]?.eDataId;
                const entityId = d.shapes[ this.shapeIdSubject.value ]?.entityId;
                const dataItems =  Object.values( d.getShapeDataItems( this.shapeIdSubject.value )).filter(
                    ( i: any ) => {
                        const visible = ( i.visibility || []).find( v => v.type === 'editor' );
                        return visible && i.id !== DESCRIPTION_DATAITEM_ID && i.id !== SETTINGS_DATAITEM_ID;
                    },
                );
                const index = this.commandsList.findIndex( item => item.id === 'add-existing-data-field' );
                if ( index > -1 ) {
                    this.commandsList.splice( index, 1 );
                }
                if ( dataItems.length ) {
                    this.commandsList.push({
                        id: 'add-existing-data-field',
                        title: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS.EXISTING_DATA_FIELD.TITLE' ),
                        subTitle: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS.EXISTING_DATA_FIELD.SUB_TITLE' ),
                        section: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
                        thumbnail: 'slash-cmd-datafield',
                        keywords: '',
                        component: {
                            type: DataitemsPicker,
                            inputs: {
                                shapeId: this.shapeIdSubject.value,
                                diagramId: this.diagramId,
                            },
                        } as any,
                        command: ({ editor, range, props }) => {
                            ( props.componentInstance as DataitemsPicker ).change.pipe(
                                take( 1 ),
                            ).subscribe( ids => {
                                const contents = ids.map( dataItemId => ({
                                    type: 'dataItemNode',
                                    attrs: {
                                        shapeId: this.shapeIdSubject.value,
                                        entityId,
                                        edataId,
                                        dataItemId,
                                    // template: 'empty',
                                    },
                                }));
                                editor
                                    .chain()
                                    .focus()
                                    .deleteRange( range )
                                    .insertContent( contents ).run();
                            });
                        },
                    });
                }
            });

        /**
         * Adds Core Data Fields dynamically
         */
        /*const coreDataFieldsSub = this.shapeIdSubject.pipe(
            switchMap( shapeId => {
                if ( !shapeId ) {
                    return EMPTY;
                }
                // NOTE: manual core field removals are not emitted by below observable.
                const coreFieldChanges = new BehaviorSubject( null );
                const diagramObs = coreFieldChanges.pipe(
                    switchMap(() => this.ll.forDiagram( this.diagramId, false ).getDiagramOnce()),
                );
                return diagramObs.pipe(
                    tap( d => {
                        const index = this.commandsList.findIndex( item => item.id === 'add-new-property' );
                        if ( index > -1 ) {
                            this.commandsList.splice( index, 1 );
                        }
                        const shapeData = d.shapes[shapeId]?.data || {};
                        const coreDataFields = CoreDataFieldService.getCoreDataDefs();
                        const remainingFields = Object.keys( coreDataFields ).filter( path => !shapeData[ path ]);
                        if ( remainingFields.length > 0 ) {
                            this.commandsList.push({
                                id: 'add-new-property',
                                title: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS.NEW_PROPERTY.TITLE' ),
                                subTitle: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS.NEW_PROPERTY.SUB_TITLE' ),
                                section: translate( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
                                thumbnail: 'slash-cmd-datafield',
                                keywords: '',
                                component: {
                                    type: CoreDataItemsPicker,
                                    inputs: {
                                        coreDataDefs: Object.values( coreDataFields ),
                                        remainingFields,
                                    },
                                } as any,
                                command: ({ editor, range, props }) => {
                                    ( props.componentInstance as CoreDataItemsPicker ).change.pipe(
                                        take( 1 ),
                                    ).subscribe( ids => {
                                        if ( ids.length > 0 ) {
                                            const roleFields = [ 'owner', 'dueDate', 'estimate' ];
                                            if ( ids.some( id => roleFields.includes( id ))) {
                                                this.taskSummary.addRole( this.diagramId, this.shapeIdSubject.value );
                                            }
                                            without( ids, ...roleFields ).forEach( id => {
                                                this.commandService.dispatch(
                                                    DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                                                    [this.shapeIdSubject.value]: {
                                                        [id]: {
                                                            value: CoreDataFieldService.getDefaultValue( id ),
                                                            isCore: true,
                                                        },
                                                    },
                                                });
                                            });
                                            setTimeout(() => {
                                                coreFieldChanges.next( ids );
                                            }, 1000 );
                                        }
                                        editor.chain()
                                            .focus()
                                            .deleteRange( range )
                                            .run();
                                    });
                                },
                            });
                        }
                    }),
                );
            }),
        ).subscribe();

        /**
         * If shape text editing mode is on, the slash commands are restricted based on
         * the text model which is being edited.
         */
        this.commandsListCached = [ ...this.commandsList ];
        const editTextSub = this.stateSvc.changes( 'EditingText' ).pipe( switchMap( state => {
            if ( state.open && state.shapeId && state.textId ) {
                return this.ll.forDiagram( this.diagramId, false ).getShapeOnce( state.shapeId ).pipe( tap(
                    shape => {
                        const text = shape?.texts[ state.textId ];
                        if ( shape?.enableTiptap && text && text.slashCmdFeatures ) {
                            this.commandsList = this.commandsList.filter( f => text.slashCmdFeatures.includes( f.id ));
                        } else {
                            this.commandsList = [];
                        }
                    },
                ));
            }
            this.commandsList = [ ...this.commandsListCached ];
            return EMPTY;
        })).subscribe();

        this.subs.push( existingFieldsSub, /* coreDataFieldsSub, */ editTextSub );
    }

    /**
     * List of all the static slash command list
     */
    protected commandsList: ISlashCommandItem[] = [
        {
            id: 'add-data-field',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.NEW_DATA_FIELD.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.NEW_DATA_FIELD.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
            thumbnail: 'slash-cmd-datafield',
            keywords: '',
            component: {
                type: DataItemsRenderer,
                inputs: {
                    liteModeAddDataitems: true,
                    shape: { id: this.shapeIdSubject.value },
                    dataItemsObs: this.shapeIdSubject.pipe(
                        switchMap( shapeId => !shapeId ? of({}) : this.ll.forDiagram( this.diagramId, false )
                            .getDiagramOnce().pipe( map( d => d.getShapeDataItems( this.shapeIdSubject.value )))),
                    ),
                },
            },
            command: ({ editor, range, props }) => {
                ( props.componentInstance as DataItemsRenderer ).onChange = change => {

                    this.ll.forDiagram( this.diagramId, false ).getDiagramOnce().subscribe( d => {
                        const edataId = d.shapes[ this.shapeIdSubject.value ]?.eDataId;
                        const entityId = d.shapes[ this.shapeIdSubject.value ]?.entityId;
                        if ( change.data ) {
                            if ( change.data.labelEditable ) {
                                change.data.isPublic = true;
                            }
                        }
                        if ( this.shapeIdSubject.value ) {
                            if ( change.data && change.data.__sakota__ && !change.data.__sakota__.hasChanges()) {
                                // if this is a sakota change obj, drop it if it does not have changes
                                // TODO - can we fix this at the component? this avoids blank changes coming in
                                return;
                            }
                            editor
                            .chain()
                            .blur()
                            .deleteRange( range )
                            .insertContent({
                                type: 'dataItemNode',
                                attrs: {
                                    edataId,
                                    entityId,
                                    shapeId: this.shapeIdSubject.value,
                                    dataItemId: change.id,
                                    data: change.data,
                                },
                            }).run();
                            this.commandService.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                                [this.shapeIdSubject.value]: {
                                    [change.id]: change.data,
                                },
                            });
                        }
                    });
                };
                ( props.componentInstance as DataItemsRenderer ).onPropAdded = change => {
                    const shapeId = this.shapeIdSubject.value;
                    if ( shapeId ) {
                        this.commandService.dispatch( DiagramCommandEvent.changeShapeDataItems, this.diagramId, {
                            [shapeId]: change,
                        }).subscribe();
                    }
                };
            },
        },
        {
            id: 'addRole',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.ADD_ROLE.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.ADD_ROLE.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
            thumbnail: 'slash-cmd-add-role',
            keywords: 'role',
            command: ({ editor, range }) => {
                this.taskSummary.addRole( this.diagramId, this.shapeIdSubject.value );
            },
        },
        {
            id: 'h1',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H1.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H1.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
            thumbnail: 'slash-cmd-h1',
            keywords: 'h1',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setNode( 'heading', { level: 1, textAlign: this.getAlignment( editor ) })
                .run();
            },
        },
        {
            id: 'h2',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H2.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H2.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
            thumbnail: 'slash-cmd-h2',
            keywords: 'h2',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setNode( 'heading', { level: 2, textAlign: this.getAlignment( editor ) })
                .run();
            },
        },
        {
            id: 'h3',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H3.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.H3.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.BASIC_BLOCKS' ),
            thumbnail: 'slash-cmd-h3',
            keywords: 'h3',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setNode( 'heading', { level: 3, textAlign: this.getAlignment( editor ) })
                .run();
            },
        },

        // List and blocks
        {
            id: 'num-list',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.NUMBER_LIST.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.NUMBER_LIST.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.LISTS_AND_BLOCKS' ),
            thumbnail: 'slash-cmd-numberedlist',
            keywords: '',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .focus( range.from )
                .setTextAlign( 'left' )
                .toggleOrderedList()
                .run();
            },
        },
        {
            id: 'bulleted-list',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.BULLET_LIST.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.BULLET_LIST.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.LISTS_AND_BLOCKS' ),
            thumbnail: 'slash-cmd-bulletlist',
            keywords: '',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setTextAlign( 'left' )
                .toggleBulletList()
                .run();
            },
        },
        {
            id: 'task-list',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.TASK_LIST.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.TASK_LIST.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.LISTS_AND_BLOCKS' ),
            thumbnail: 'slash-cmd-todolist',
            keywords: 'check,todo',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setTextAlign( 'left' )
                .toggleTaskList()
                .run();
            },
        },
        {
            id: 'block-quote',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.BLOCK_QUOTE.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.BLOCK_QUOTE.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.LISTS_AND_BLOCKS' ),
            thumbnail: 'slash-cmd-blockquote',
            keywords: '',
            command: ({ editor, range }) => {
            editor
                .chain()
                .focus()
                .deleteRange( range )
                .setTextAlign( this.getAlignment( editor ))
                .toggleBlockquote()
                .run();
            },
        },
        // {
        //     id: 'code-block',
        //     section: 'LISTS AND BLOCKS',
        //     thumbnail: 'slash-cmd-codeblock',
        //     title: 'Code block',
        //     subTitle: 'Create a code block',
        //     keywords: '',
        //     command: ({ editor, range }) => {
        //     editor
        //         .chain()
        //         .focus()
        //         .deleteRange( range )
        //         .toggleCodeBlock()
        //         .run();
        //     },
        // },
        {
            id: 'table',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.TABLE.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.TABLE.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.LISTS_AND_BLOCKS' ),
            thumbnail: 'slash-cmd-table',
            keywords: '',
            command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange( range )
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run();
          },
        },
        // {
        //     id: 'collapsible-section',
        //     section: 'LISTS AND BLOCKS',
        //     thumbnail: 'slash-cmd-collapse',
        //     title: 'Collapsible section',
        //     subTitle: 'Create a collapsible section',
        //     keywords: '',
        //     command: ({ editor, range }) => {
        //         editor
        //             .chain()
        //             .focus()
        //             .deleteRange( range )
        //             .setDetails()
        //             .run();
        //   },
        // },

        // Media and files
        {
            id: 'upload',
            title: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.UPLOAD.TITLE' ),
            subTitle: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS.UPLOAD.SUB_TITLE' ),
            section: this.translate.instant( 'TIPTAP_EDITOR.SLASH_COMMANDS_SECTIONS.MEDIA' ),
            thumbnail: 'slash-cmd-file',
            keywords: 'picture,screenshot,photo',
            command: ({ editor, range }) => {
                ( editor as Editor )
                    .chain()
                    .focus()
                    .deleteRange( range ).run();
                this.commandService.dispatch( DiagramCommandEvent.showUploadWindow, {
                    maxFileSize: this.maxFileSize,
                    allowedFileTypes: this.allowedFileTypes,
                })
                .pipe(
                    last(),
                    filter( v => v && v.resultData[0] && v.resultData[0].files[ 0 ]),
                    tap(( v: any ) => {
                        const file = v.resultData[0].files[0];
                        if ( file.size <= this.maxFileSize ) {
                            this.dispatchUploadFile( file.file ).subscribe( attachment => {
                                if ( attachment.type.includes( 'image' )) {
                                    editor
                                        .chain()
                                        .focus()
                                        .insertContentAt( range.from - 1, {
                                            type: 'image',
                                            attrs: { src: attachment.link },
                                        }).run();
                                } else {
                                    editor
                                        .chain()
                                        .focus()
                                        .insertContentAt( range.from - 1, {
                                            type: 'fileEmbedNode',
                                            attrs: { attachment },
                                        }).run();
                                }
                                this.commandService.dispatch( DiagramCommandEvent.changeAttachments, this.diagramId, {
                                    [attachment.id]: attachment,
                                });
                            });
                        } else {
                            const message = this.translate.instant( 'RICHTEXT_EDITOR.ERRORS.FILE_SIZE',
                                { maxFileSize: this.maxFileSize / 1000000 });
                            this.showNotification( message );
                        }
                    }),
                ).subscribe();
            },
        },
    ];

    protected getAlignment( editor: Editor ) {
        return editor.isActive({ textAlign: 'right' }) ? 'right' :
            editor.isActive({ textAlign: 'left' }) ? 'left' :
            editor.isActive({ textAlign: 'center' }) ? 'center' :
            editor.isActive({ textAlign: 'justify' }) ? 'justify' : 'left';
    }

    /**
     * This callback is called by the tiptap's suggestions extention
     */
    public items = ({ query }) => {
        const input =  query.toLowerCase();
        if ( input ) {
            const sorted = go( query.toLowerCase(), this.commandsList, {
                keys: [ 'keywords', 'title', 'subTitle' ],
            });
            return sorted.map( r => r.obj );
        }
        return this.commandsList;
    }

    /**
     * This callback is called by the tiptap's suggestions extention
     * The TipTapSlashCmdListCmp angular component is rendered as the
     * slash menu dropdown
     */
    public render = () => {
        let component: TipTapSlashCmdListCmp;
        let dc: DynamicComponent;
        let itemRef: ComponentRef<any>;
        let lastSelectedId: string;
        let scrollY: number;

        return {
            onStart: props => {
                if ( !props.editor.isFocused ) {
                    return;
                }
                dc = new DynamicComponent( this.cfr, this.injector );
                itemRef = dc.makeComponent( TipTapSlashCmdListCmp );
                itemRef.changeDetectorRef.detectChanges();
                component =  itemRef.instance;
                component.updateProps( props );
                dc.insert( this.viewCR, itemRef );
                itemRef.changeDetectorRef.detectChanges();
                component.updatePosition( props );

                if ( lastSelectedId ) {
                    ( itemRef.instance as TipTapSlashCmdListCmp ).selectedId.next( lastSelectedId );
                    ( itemRef.instance as TipTapSlashCmdListCmp ).setScrollY( scrollY || 0 );
                }
                const destroy = () => {
                    if ( itemRef ) {
                        itemRef.destroy();
                        ( dc as any ).remove( this.viewCR, itemRef );
                    }
                    props.editor.view.dom.removeEventListener( 'tiptap-blur', destroy );
                };
                props.editor.view.dom.addEventListener( 'tiptap-blur', destroy );
            },

            onUpdate:  props => {
                if ( component && itemRef ) {
                    component.updateProps( props );
                    itemRef.changeDetectorRef.detectChanges();
                    component.updatePosition( props );
                }

            },

            onKeyDown:  props => {
                if ( props.event.key === 'Escape' && itemRef ) {
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                    return true;
                }
                if ( component && itemRef ) {
                    const val = component.onKeyDown( props );
                    itemRef.changeDetectorRef.detectChanges();
                    return val;
                }
            },

            onclick: props => {
                if ( !props.editor.isFocused ) {
                    if ( itemRef ) {
                        itemRef.destroy();
                        ( dc as any ).remove( this.viewCR, itemRef );
                    }
                }
            },

            onExit: () => {
                if ( itemRef ) {
                    lastSelectedId = ( itemRef.instance as TipTapSlashCmdListCmp ).selectedId.value;
                    scrollY = ( itemRef.instance as TipTapSlashCmdListCmp ).getScrollY();
                    itemRef.destroy();
                    ( dc as any ).remove( this.viewCR, itemRef );
                }
            },
        };
    }

    /**
     * Only one file can be uploaded for the moment
     * @param file
     */
    protected dispatchUploadFile( file ) {
        return this.commandService.dispatch( DiagramCommandEvent.uploadFile, {
            files: [ new ImportedFile( file ) ],
        }).pipe(
            last(),
            filter( v => v && v.resultData[0] && v.resultData[0].files[ 0 ]),
            map( v => {
                file = v.resultData[0].files[ 0 ];
                const id = Random.attachmentId();
                const shapeId = this.shapeIdSubject.value;
                const attachment = {
                    id: id,
                    link: AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + file.hash,
                    name: file.name,
                    added: Date.now(),
                    source: 's3',
                    action: 'add',
                    downloadable: true,
                    type: file.type,
                    shapeId,
                };
                return attachment;
            }),

        );
    }

    /**
     * Show an app notification.
     * @param message The notification message
     * @param title The notification title
     * @param type The notification type. Defaults to warning
     */
    protected showNotification( message: string, title?: string, type = NotificationType.Warning ) {
        const options = {
            inputs: {
                heading: title || '',
                description: message,
                autoDismiss: true,
                dismissAfter: this.notificationAutoclose,
            },
        };
        this.notifierController.show( Notifications.RICHTEXT_EDITOR_ERROR, AbstractNotification, type, options );
    }

}

export let suggestionCmd = Extension.create({
    name: 'suggestionCmd',

    addOptions() {
      return {
        suggestion: {
          char: '/',
          command: ({ editor, range, props }) => {
            editor?.getContext().subscribe( v =>
                Tracker.track( 'text.format.tiptap.click', { value1: props?.id, value2: v }));
            props.command({ editor, range, props });
          },
        },
      };
    },

    addProseMirrorPlugins( this ) {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
});

/**
 * The interface for tiptap slash command item
 */
export interface ISlashCommandItem {

    /**
     * Unique identifieer
     */
    id: string;

    /**
     * Can be any string, use to group the slash command items
     */
    section: string;

    thumbnail: string;
    title: string;
    subTitle: string;

    /**
     * Angular component type to render if the slash command item requires additional
     * intermediate UIs, e.g. Inser data fields etc.
     */
     component?: {
        /**
         * Angular component type
         */
        type: any,
        /**
         * Inputs required for the Angular component
         */
        inputs: any,
     };

    /**
     * Any string to add search keywords
     */
    keywords: string;

    /**
     * The callback method for the slash command
     */
    command: Function;

}
