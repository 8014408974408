import { Point, Line } from 'flux-core';
import { IPoint2D, IGeometry } from 'flux-definition';

export class Geometry implements IGeometry {
    public static get instance(): Geometry {
        return this._instance;
    }
    /**
     * A constant getter for an instance of this class.
     */
    private static _instance = new Geometry();

    /**
     * Creates a point instance with given (x,y) values.
     * @param x The x value on the point
     * @param y The y value on the point
     */
    public createPoint( x: number, y: number ): Point {
        return new Point( x, y );
    }

    /**
     * Creates a line instance with given start and end points.
     * @param p1 The starting point
     * @param p2 The finishing point
     */
    public createLine( p1: IPoint2D, p2: IPoint2D ): Line {
        return new Line( p1, p2 );
    }
}
