import { ILineStyle, DEFAULT_LINE_THICKNESS, LineStylePreset } from 'flux-definition';

/**
 * Class representing line styles. A single object
 * contains properties which are used to define
 * and line thickness, line color, line style and line alpha.
 *
 * @author
 * @since 2017-11-06
 */

export class LineStyle implements ILineStyle {

    public linePreset: LineStylePreset = LineStylePreset.Normal;

    /**
     * lineThickness is a number which defines the width
     * of a line.
     *
     * The color of the line is a string. Colors
     * can be set using hexadecimal color codes. Eg: #ffffff
     *
     * Defines whether the line is solid or dashed.
     * A minimum of two numbers should be passed into the array.
     * The two numbers represents the lengths of a dash and a gap.
     *
     * An alpha value to denote the visibility. Should
     * be between 0 and 1. 0 for invisible and 1 for solid.
     *
     * Sets the text color as a string.
     */

    constructor(
        public lineThickness: number = 2,
        public lineColor: string = '#1a1a1a',
        public lineStyle: number[] = [ 0, 0 ],
        public lineAlpha: number = 1,
        public textColor: string = '#1a1a1a',
        public themeIndex: number = 0,
        public themeId: string = 'base' ) {
    }


    /**
     * Getter that generates and returns the style id.
     */
    public get styleId(): string {
        // NOTE: Line thickness value will be '2' for the style panel previews.
        const styleIdString: string = this.lineAlpha + this.lineColor +
            this.lineStyle + DEFAULT_LINE_THICKNESS + this.textColor;

        return styleIdString.replace( /['"]+/g, '' );
    }

    /**
     * Getter style id for line style
     */
    public get id(): string {
        return this.styleId;
    }

}
