import { IModifier } from 'flux-core';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

/**
 * Apply the given modifier to the change model
 */
@Injectable()
@Command()
export class ApplyModifierEData extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        modifier: IModifier,
    };

    public prepareData() {
        const modifier = this.data.modifier;
        const shapeAttachedRegex = /entities\.([^\.]+).shapes\.([^\.]+)$/;
        if ( Object.keys( modifier.$set || {}).some( k => shapeAttachedRegex.test( k ))) {
            Object.keys( modifier.$set ).filter( k => shapeAttachedRegex.test( k )).forEach( k => {
                const [ , entId, , diagId ] = k.split( '.' );
                if ( this.changeModel.entities[entId].shapes[diagId]) {
                    for ( const sId in modifier.$set[k]) {
                        modifier.$set[`${k}.${sId}`] = modifier.$set[k][sId];
                    }
                    delete modifier.$set[k];
                }
            });
        }
        this.changeModel.__sakota__.mergeChanges( modifier );
    }

}

Object.defineProperty( ApplyModifierEData, 'name', {
    value: 'ApplyModifierEData',
});
