<div class="modal-window-container fx-cover fx-center-all {{isFabImageImport ? 'fab-image-import' : ''}}" #window (click)="closeOnOverlayClick($event)">
    <div #windowInner>
        <div class="uppy-container" (click)="onContainerClick($event)"></div>
        <div *ngIf="isFabImageImport" class="file-importer-title-container">
            <span class="file-importer-title">Upload Images</span>
        </div>
        <img *ngIf="isFabImageImport && !isFilesUploded" src="./assets/images/fab/fab-empty-state.svg" alt="Empty state" class="file-importer-empty-state-image" width="90" />
        <div class="file-importer-close-btn">
            <button class="btn-small" (click)="closeWindow()"
                [trackAction]="{ event: 'dialog.image.import.upload.cancel' }">
                <svg class="nu-icon nu-icon-med nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
            </button>
        </div>
        <div *ngIf="( shapeImage && shapeImage.hash && !shapeImage.removed && !isFilesUploded )" class="file-importer-preview">
            <img [src]="getImageUrl( shapeImage.hash )" >
            <button class="btn-small" (click)="removePreviewImage()">
                <svg class="nu-icon nu-icon-med nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
            </button>
        </div>
    </div>
</div>
