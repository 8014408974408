import { IModifier } from 'flux-core';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * Apply the given modifier to the change model
 */
@Injectable()
@Command()
export class ApplyModifierDocument extends AbstractDiagramChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        modifier: IModifier,
    };

    public prepareData() {
        this.changeModel.__sakota__.mergeChanges( this.data.modifier );
    }

}

Object.defineProperty( ApplyModifierDocument, 'name', {
    value: 'ApplyModifierDocument',
});
