<div class="user-img-container fx-round {{imageSize}}"
    (mouseenter)="userStripHover = true"
    (mouseleave)="userStripHover = false"
    [ngStyle]="getBorder()"
    data-testid="user-image"
    [class.user-strip-opacity]="isUserStripMode"
    [class.animate-border]="isUserStripMode && (spotlightState || spotlightRequestingState)"
    [ngClass]="{
        'active': userOnlineStatus, 
        'offline': !userOnlineStatus,
        'pending': !userRegistered,
        'inline': inline }">

    <div *ngIf="hasImage"
        class="user-img-inner fx-round"
        [ngStyle]="{'background-image': 'url('+ imagePath + ')'}">
    </div>

    <div *ngIf="!hasImage"
        class="user-img-inner fx-round"
        [ngStyle]="{'background-color': userColor}">
        {{userInitials}}
    </div>
</div>
