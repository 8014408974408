import { Component, ChangeDetectionStrategy,
    ElementRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { TiptapPopCommandsListCmp } from '../tiptap-pop-commands-list.cmp';

/**
 * TiptapMentionsListCmp is the component for @mentions in tiptap
 */
@Component({
    selector: 'tiptap-mentions-list-cmp',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div data-id="{{id}}" #container class="tiptap-mentions-list-cmp-container">
        <perfect-scrollbar #scrollbar>
            <div [class.fx-hidden]="hideList | async" class="tiptap-mentions-list">
                <div *ngFor="let group of groupedItems | async " >
                    <div class="tiptap-slash-cmd-list-group" >
                        {{'TIPTAP_EDITOR.MENTIONS_SECTIONS.' + group[0].section.toUpperCase() | translate}}</div>
                    <tiptap-mentions-list-item
                        [class.selected]="item.id === ( selectedId | async)"
                        (click)="item.id ? selectItem(item.id) : false"
                        [data]="item"
                        *ngFor="let item of group">
                    </tiptap-mentions-list-item>
                </div>
            </div>
        </perfect-scrollbar>
      </div>
    `,
    styleUrls: [ './tiptap-mentions-list.cmp.scss' ],
})
export class TiptapMentionsListCmp extends TiptapPopCommandsListCmp {

    constructor(
        protected elementRef: ElementRef,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
        super( elementRef, componentFactoryResolver, injector );
    }

    public destroy() {
    }

    public position( x, y ) {
        if ( this.container ) {
            const el = this.containerElement;
            const thisElement = document.getElementById( this.id );
            if ( thisElement ) {
                const parentBounds  = thisElement.parentElement.getBoundingClientRect();
                let top;
                let left = x - parentBounds.x;
                const height = el.getBoundingClientRect().height;
                const width = el.getBoundingClientRect().width;
                // If 75% of the component is not visible, place it on top

                if ( y + ( height * 0.75 ) > window.innerHeight ) {
                    top = - ( parentBounds.y - y ) - height - 10;
                } else {
                    top = - ( parentBounds.y - y ) + 20;
                }
                if ( x + ( width * 0.75 ) > window.innerWidth ) {
                    left = left - width;
                }
                el.style.top = top + 'px';
                el.style.left =  left + 'px';
            }
        }
    }

    protected autoScroll() {
        setTimeout(() => {
            this.directiveScroll.directiveRef.scrollToElement(
                `tiptap-mentions-list-item.selected` , -10 , 100 );
        }, 10 );
    }
}
