import { Injectable } from '@angular/core';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Command, StateService, Tracker } from 'flux-core';
import { DEFUALT_TEXT_STYLES, IShapeDefinition, ITextFormat } from 'flux-definition';
import { LogicClassFactory, TextFormatter } from 'flux-diagram-composer';
import { mapTo, switchMap, take, tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { cloneDeep } from 'lodash';
import { forkJoin, of } from 'rxjs';

const DATA_ITEMS_TEXT_IDS = [ 'data_items_labels' , 'data_items_values' ];

/**
 * SwitchShape
 * Switch shape A to shape B, retaining properties as defined in shape A's shape model
 * definition
 *
 * @author  Lina
 * @since   2021-05-07
 *
 */
@Injectable()
@Command()
export class SwitchShape extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
     public data: {
        id: string;
        shapes: any;
        defaultLibrary: string;
        defaultPreferences: any;
    };

    protected formatter: TextFormatter;

    /**
     * Inject the state service to get the list of selected shape ids.
     * Inject DefinitionLocator to get new shape's definition
     * Add text formatter to cmd to perform text styling related functions
     */
    constructor(
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any>,
        protected ds: DiagramChangeService ) {
        super( ds );
        this.formatter = new TextFormatter();
        this.state = state;
    }

    /**
     * Adjust shape based on preferences and new shape definition.
     */
    public prepareData() {
        this.resultData = { shapeIds: this.state.get( 'Selected' ) };

        const selectedIds = this.state.get( 'Selected' );
        const newShapeDetails = this.data.shapes.find( item => item.id === this.data.id );
        const preferences = Object.assign( this.data.defaultPreferences, newShapeDetails );

        let def;
        return this.defLocator.getDefinition( newShapeDetails.defId, newShapeDetails.version ).pipe(
            take( 1 ),
            switchMap(( defn: IShapeDefinition ) => {
                def = cloneDeep( defn );
                const obs = [ of( def ),
                    ...selectedIds.map( id => {
                        const shape:  any = this.changeModel.shapes[id];
                        // Add tracking
                        Tracker.track( 'canvas.toolbar.shape.change', {
                            value1: def.name,
                            value2: shape.name,
                            value3: def.defId.split( '.' )[1],
                        });

                        // 1. Generic shape switcher stuff that has to be done for *every* switch
                        // Change def id and version
                        shape.defId = newShapeDetails.defId;
                        shape.version = def.version;
                        // Remove any existing vectors and style definitions: these are shape specific
                        if ( !preferences.preserveVectors ) {
                            shape.vectors = {};
                        }
                        shape.styleDefinitions = {};

                        if ( def.logicClass ) {
                            return this.defLocator.getClass( def.logicClass ).pipe(
                                tap(( logicClass: any ) => {
                                    const instance = LogicClassFactory.instance.create( logicClass );
                                    if ( instance.created ) {
                                        ( instance as any ).created( shape, def, this.changeModel );
                                    }
                                }),
                            );
                        }
                        return of( def );
                    }),
                ];
                return forkJoin( ...obs ).pipe( mapTo( def ));
            }),
            tap( defn => {
                def = cloneDeep( defn );
                selectedIds.forEach( id => {
                    const shape:  any = this.changeModel.shapes[id];

                    const prevBounds = Object.assign({}, shape.defaultBounds );
                    Object.assign( shape.defaultBounds, def.defaultBounds );
                    shape.ports = shape.ports ? def.ports : [];

                    // Mapping primary texts
                    const textsFromDef = def.texts ? def.texts : {};
                    if ( !shape.texts ) {
                        shape.texts = cloneDeep( textsFromDef );
                    } else {
                        Object.keys( shape.texts ).forEach( tid => {
                            const defTextKeys = Object.keys( textsFromDef );
                            if ( shape.texts[tid].primary && defTextKeys.length ) {
                                const primaryFromDef = defTextKeys.find( tid2 => def.texts[tid2].primary );
                                if ( primaryFromDef && def.texts[primaryFromDef]) {
                                    let text = cloneDeep( shape.texts[tid]);
                                    if ( !preferences.preserveTextStyles ) {
                                        text = this.copyTextStyles(
                                            shape.texts[tid], def.texts[primaryFromDef]);
                                        text.id = primaryFromDef;
                                    }
                                    if ( !shape.texts[primaryFromDef]) {
                                        shape.texts[primaryFromDef] = {};
                                    }
                                    Object.keys( text ).forEach( key => shape.texts[primaryFromDef][key] = text[key]);
                                    shape.texts[primaryFromDef].id = primaryFromDef;
                                    if ( tid !== primaryFromDef ) {
                                        delete shape.texts[tid];
                                    }
                                }
                            } else if ( !shape.texts[tid].primary && ( !defTextKeys.length || ( defTextKeys.length
                                && !defTextKeys.includes( tid ))) && !DATA_ITEMS_TEXT_IDS.includes( tid )) {
                                    // Remove texts that don't exist on shape B
                                    // Preserve text for dataItems
                                    delete shape.texts[tid];
                            } else if ( !defTextKeys.length && shape.texts[tid].primary ) {
                                // Keep primary only if def has no texts
                                Object.keys( shape.texts[tid]).forEach( key =>
                                    shape.texts[tid][key] = shape.texts[tid][key]);
                            }
                        });
                    }

                    // BY DEFAULT: The following are preserved
                    /// Scaling
                    /// Inversion
                    /// Styles
                    /// Rotation
                    /// Text styles

                    // Scaling
                    if ( preferences.preserveScaling ) {
                        shape.scaleX = ( prevBounds.width * shape.scaleX ) / def.defaultBounds.width;
                        shape.scaleY = ( prevBounds.height * shape.scaleY ) / def.defaultBounds.height;
                        if ( def.transformSettings && def.transformSettings.fixAspectRatio ) {
                            const widthToHeightRatio = def.defaultBounds.width / def.defaultBounds.height;
                            // Preserve inversion - default is no inversion
                            let sign = 1;
                            if ( preferences.preserveInversion ) {
                                sign = Math.sign( shape.scaleX );
                            }
                            shape.scaleX = sign * Math.abs( shape.scaleY ) * widthToHeightRatio;
                        }
                    } else {
                        // Preserves inversion
                        shape.scaleX /= Math.abs( shape.scaleX );
                        shape.scaleY /= Math.abs( shape.scaleY );
                    }

                    // Inversion
                    if ( !preferences.preserveInversion ) {
                        shape.scaleX = Math.abs( shape.scaleX );
                        shape.scaleY = Math.abs( shape.scaleY );
                    }

                    if ( !preferences.preserveRotation ) {
                        shape.angle = def.angle ? def.angle : 0;
                    }

                    if ( !preferences.preserveStyles ) {
                        shape.style = cloneDeep( def.style );
                        // Set text color to default
                        Object.keys( shape.texts ).forEach( tid => {
                            const format: ITextFormat = {
                                indexStart: 0,
                                indexEnd: shape.texts[tid].plainText ? shape.texts[tid].plainText.length : 0,
                                styles: { color: DEFUALT_TEXT_STYLES.color },
                            };
                            shape.texts[tid].content = this.formatter.applyRaw( shape.texts[tid].content, format );
                        });
                    }
                });

            }),
        );
    }

    /**
     * Copy styles from text model A to B, and return B with new styles.
     * @param textModelWithoutStyles (A)
     * @param textModelWithStyles (B)
     * @returns
     */
    private copyTextStyles( textModelWithoutStyles, textModelWithStyles ) {
        const styles = this.formatter.extractCommon( textModelWithStyles.content );
        const format: ITextFormat = { indexStart: 0, indexEnd: null, styles: styles };
        const content = this.formatter.applyRaw( textModelWithoutStyles.plainText, format );
        textModelWithoutStyles.content = content;

        return textModelWithoutStyles;
    }
}

Object.defineProperty( SwitchShape, 'name', {
    value: 'SwitchShape',
});
