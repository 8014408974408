import { CollaboratorType } from '../collab/model/collaborator.mdl';
import { PrivacyLevel } from 'flux-user';

/**
 * This is an enum representing the access level set for a particular
 * resource. This is a flattened version of the {@link PrivacyLevel}
 * enum with access levels pertaining to discoverability omitted.
 */
export enum ResourcePrivacyLevel {

    /**
     * It can be only access by the people who are already added as collaborator
     */
    SPECIFIC = 0,

    /**
     * It can be explored by anyone in the team.
     */
    TEAM = 1,

    /**
     * Used to identify public resource which is publicly available to explore.
     */
    PUBLIC = 2,
}

/**
 * This is a model representing the privacy of a resource.
 * A resource's privacy defines who can access the said resource and
 * at which level.
 *
 * When it comes to privacy of a resource, there are two main factors to consider;
 * 1. The access level - A resource will have a defined set of access levels.
 *    At a given time a resource will be set to a specific access level.
 *    Each access level would be less or more private than the other one.
 *    All of the currently supported access levels are defined in
 *   {@link ResourcePrivacyLevel} enum.
 * 2. The Role - This defines what role a user can play in the resource. Users may be
 *    restricted or allowed from performing certain actions on the resource based on
 *    their role. All currently supported roles are defined in
 *    {@link CollaboratorType} enum.
 *
 * @since   2018-05-18
 * @author  Ramishka
 */
export class PrivacyModel {

    /**
     * Defines the privacy role
     */
    public role: CollaboratorType;

    /**
     * Defines the access level
     */
    public level: PrivacyLevel;

    /**
     * The number of privacy levels can be reduced (or flattened)
     * by omitting the levels that consider the discoverability
     * of a resource.
     * This function checks the currently set access level and
     * returns once such base access level.
     */
    public get actualPrivacy(): ResourcePrivacyLevel {
        switch ( this.level ) {
            case PrivacyLevel.SPECIFIC:
                return ResourcePrivacyLevel.SPECIFIC;
            case PrivacyLevel.ANYONE_IN_TEAM_WITH_LINK:
            case PrivacyLevel.ANYONE_IN_TEAM:
                return ResourcePrivacyLevel.TEAM;
            case PrivacyLevel.ANYONE_WITH_LINK:
            case PrivacyLevel.PUBLIC:
                return ResourcePrivacyLevel.PUBLIC;
        }
    }

    /**
     * Returns true if the resources access level is set to public.
     */
    public isPublic(): boolean {
        return this.actualPrivacy === ResourcePrivacyLevel.PUBLIC;
    }

    /**
     * Returns true if the resources access level is set to team
     */
    public isTeam(): boolean {
        return this.actualPrivacy === ResourcePrivacyLevel.TEAM;
    }
}

Object.defineProperty( ResourcePrivacyLevel, 'name', {
  writable: true,
  value: 'ResourcePrivacyLevel',
});
