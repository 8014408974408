import { IDataItem } from 'flux-definition';
import { IDataItemUIControl } from './data-items-uic.i';
import { map, filter, tap } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ElementRef, Input } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { Tracker } from 'flux-core';

/**
 * UI contorl for Identifier text
 */
@Component({
    selector: 'identifier-input-uic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <input [class.invalid-character]="validationsFailed | async" type="text" class="text-input" (keyup)="validate($event)" [value]="valueSource | async">
    `,
    styleUrls: [ './identifier-input-uic.cmp.scss' ],
})
export class IdentifierInputUIC implements IDataItemUIControl<string> {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    public valueSource: BehaviorSubject<string>;
    public validationsFailed: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Constructor
     */
    constructor( protected elementRef: ElementRef ) {
        this.valueSource = new BehaviorSubject( '' );
    }

    /**
     * An observable that emits when the button is clicked.
     * If an extenal component wants to listen to the button
     * click event, they need to subscribe to this observable.
     */
    public get change(): Observable<string> {
        const input  = ( this.elementRef.nativeElement as HTMLElement ).querySelector( 'input' );
        return fromEvent( input, 'blur' ).pipe(
            filter(() => input.value !== this.valueSource.value && this.validationsFailed.getValue() === false ),
            map(() => input.value ),
            tap(() => {
                /* istanbul ignore next */
                if ( this.context ) {
                    Tracker.track( `${this.context}.text.change` );
                }
            }),
        );
    }

    public validate( event: any ) {
        const value = event.target.value;
        const validatorRegex = /^[a-zA-Z0-9_-]+$/;
        const validatorAtoZRegex = /[a-zA-Z]+/;
        if ( Number.isInteger( +value )) {
            this.validationsFailed.next( true );
        } else if ( !validatorAtoZRegex.test( value )) {
            this.validationsFailed.next( true );
        } else {
            this.validationsFailed.next( !validatorRegex.test( value ));
        }
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        this.valueSource.next( data.value );
    }

}
