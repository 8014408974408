import { DESCRIPTION_DATAITEM_ID } from './data-items-renderer.cmp';
import { Subscription } from 'rxjs';
import { RichTextEditorUIC } from './../../../framework/ui/components/rich-text-editor-uic.cmp';
import { DataType } from 'flux-definition';
import { DynamicComponent } from 'flux-core/src/ui';
import { IDataItem } from 'flux-definition';
import { AfterViewInit, OnDestroy, Component, ChangeDetectionStrategy,
    ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver, Injector, Input } from '@angular/core';

/**
 * ShapeDataSettings panel is to setup the content to be shown in the tooltip popover cmp
 * @author thisun
 * @since 2021-01-19
 */

export const SETTINGS_DATAITEM_ID = 'dataitemsttings';

@Component({
    template: `
        <div #richTextArea ></div>
    `,
    selector: 'shape-data-settings',
    styleUrls: [ './shape-data-settings.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShapeDataSettings extends DynamicComponent implements AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    @Input()
    public dataItems: { [id: string]: IDataItem<DataType> };

    @Input()
    public onChange: Function;

    protected subs: Subscription[] = [];

    protected item;

    @ViewChild( 'richTextArea', { read: ViewContainerRef, static: false })
    protected richTextArea: ViewContainerRef;

    constructor (
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
            super( componentFactoryResolver, injector );
    }

    public onTabUpdate() {
        this.manage();
    }

    public ngAfterViewInit() {
        this.manage();
    }

    /**
     * Destroys the toolbar
     */
    public ngOnDestroy() {
        this.clearSubs();
    }

    protected manage() {
        if ( !this.dataItems ) {
            this.dataItems = {};
        }
        if ( !Object.keys( this.dataItems ).includes( SETTINGS_DATAITEM_ID )) {
            this.dataItems[ SETTINGS_DATAITEM_ID ] = this.getSettingsDataItem() as any;
        }
        if ( !this.item ) {
            this.createTextArea();
        } else {
            this.updateTextArea();
        }
    }

    /**
     * Create and return the settings data item
     */
    protected getSettingsDataItem() {
        return {
            id: SETTINGS_DATAITEM_ID,
            value: this.defaultSettingsValue(),
            type: DataType.STRING_HTML,
            label: '',
            def: 'descriptionRichtext',
        };
    }

    /**
     * If the shape has custom data items, default value for the settings
     * tab is generated by this method.
     * FIXME: This is a temp solution done for the 1st phase, to be improved in next phases.
     */
    protected defaultSettingsValue() {
        const val = [];
        for ( const key in this.dataItems ) {
            const dataItem = this.dataItems[ key ];
            if ( key !== DESCRIPTION_DATAITEM_ID && dataItem.optional ) {
                val.push({ insert: `${dataItem.label} {{${dataItem.label}}}` + '\n' });
            }
        }
        // Description data item should be at the bottom
        if ( this.dataItems && this.dataItems[ DESCRIPTION_DATAITEM_ID ]) {
            const dataItem = this.dataItems[ DESCRIPTION_DATAITEM_ID ];
            val.push({ insert: `${dataItem.label} {{${dataItem.label}}}` + '\n' });
        }
        return val;
    }

    /**
     * Create RichTextEditorUIC component and set initial value
     */
     protected createTextArea() {
        const itemRef: ComponentRef<RichTextEditorUIC> = this.makeComponent( RichTextEditorUIC );
        this.item = itemRef;
        const dataItem = this.dataItems[ SETTINGS_DATAITEM_ID ];
        itemRef.instance.enableUploadOptions = false;
        /* istanbul ignore next */
        if ( this.context ) {
            ( itemRef.instance as any ).context = this.context;
        }
        itemRef.instance.setData( dataItem );
        this.subscribeToTextAreaChange( itemRef.instance );
        this.insert( this.richTextArea, itemRef );
        itemRef.changeDetectorRef.detectChanges();
    }

    /**
     * Updates the text editor
     */
     protected updateTextArea() {
        const dataItem = this.dataItems[ SETTINGS_DATAITEM_ID ];
        this.item.instance.setData( dataItem );
        this.item.changeDetectorRef.detectChanges();
    }


    /**
     * onChange callback is called as the RichTextEditorUIC emits values
     */
    protected subscribeToTextAreaChange( item: RichTextEditorUIC ) {
        const sub = item.change.subscribe( change => {
            this.onChange({ id: SETTINGS_DATAITEM_ID, data: { value: change }});
        });
        this.subs.push( sub );
    }

    protected clearSubs() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }


}
