import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { ShapeDataModel } from '../model/shape-data.mdl';
import { StrokeCaps, StrokeJoints, TransformRenderMethod } from 'flux-definition';
import { FillStyle, ILinearGradient, IRadialGradient, IShapeViewDefinition, IStyle } from 'flux-definition';
import { IShapeTextView } from '../../framework/text/text-view.i';
import { IShapeImageView } from '../../framework/image/shape-image-view.i';

/**
 * The generic version of the shape view that can render
 * on any space. This must be extended by other views which
 * will be desinged to render on specific space such as canvas or SVG.
 *
 * @author hiraash
 * @since 2017-09-06
 */
export class ShapeRenderView extends AbstractShapeView implements IShapeViewDefinition {

    /**
     * The model associated with this shape. This is only updated
     * when the model is available and can be undefined.
     * @override
     */
    public get model(): ShapeDataModel {
        return <ShapeDataModel>this._model;
    }

    protected get mergedStyles(): IStyle {
        if ( !this._dynamicStyle ) {
            return this.model.style;
        }
        return {
            fillStyle: FillStyle.Solid,
            lineColor: this._dynamicStyle.lineColor ? this._dynamicStyle.lineColor :
                                this.model.style.lineColor,
            lineThickness: this._dynamicStyle.lineThickness ? this._dynamicStyle.lineThickness :
                        this.model.style.lineThickness,
            fillColor: this._dynamicStyle.fillColor ? this._dynamicStyle.fillColor :
                            this.model.style.fillColor,

        };
    }

    /**
     * Is called after shape is created and added to the view
     * for the first time.
     */
    public initialize() {
        // nothing yet
    }

    /**
     * This method ultimately redraws this shape on the given
     * drawing space. The method would ensure that all data changes
     * are translated into the drawing space as intended by this shape.
     * This means the full shape will be up-to-date on the drawing space
     * when this method completes until the next data change on the model.
     * @param change A json with all the changes that happened to the model.
     *          Can be used to check which props changed or what changes happened.
     * @override
     */
    public render( change?: any ) {
        super.render( change );

        this.move( this.model.x, this.model.y );

        if ( this.model.transformSettings.scaleRender === TransformRenderMethod.transform ) {
            this.scaleWidth( this.model.scaleX );
            this.scaleHeight( this.model.scaleY );
        }

        this.rotate( this.model.angle );
    }

    /**
     * Supporting method which is fired before draw. Setting styles
     * and draw preparation can be done here. This may be replaced by
     * the Entry Class of the View Definition
     */
    public beginDraw() {

        // support conditional formatting
        const style = this._dynamicStyle ? this._dynamicStyle : this.model.style;

        if ( style.lineThickness ) {
            this.graphics.setStrokeStyle( style.lineThickness, StrokeCaps.ROUND, StrokeJoints.ROUND );
        }
        if ( style.lineStyle && style.lineStyle.length > 1 ) {
            this.graphics.setStrokeDash( style.lineStyle );
        }
        // If line thinkness is 0, then this will not render shape line.
        if ( style.lineColor && style.lineThickness !== 0 ) {
            this.graphics.beginStroke( style.lineColor );
        }

        switch ( style.fillStyle ) {
            case FillStyle.Solid:
                if ( style.fillColor ) {
                    this.graphics.beginFill( style.fillColor );
                }
                break;
            case FillStyle.LinearGradient:
                const lg: ILinearGradient = this.model.getLinearGradient();
                if ( lg ) {
                    this.graphics.beginLinearGradientFill( lg.colors, lg.ratios, lg.x0, lg.y0, lg.x1, lg.y1 );
                }
                break;
            case FillStyle.RadialGradient:
                const rg: IRadialGradient = this.model.getRadialGradient();
                if ( rg ) {
                    this.graphics.beginRadialGradientFill(
                        rg.colors, rg.ratios, rg.x0, rg.y0, rg.r0, rg.x1, rg.y1, rg.r1,
                    );
                }
                break;
        }

    }

    /**
     * Supporting method which is fired after draw. Closing styles
     * and draw ending can be done here. This may be replaced by
     * the Entry Class of the View Definition
     */
    public endDraw() {
        super.endDraw();
        this.graphics.endFill();
    }

    /**
     * This function updates the text view accoridng to the given text model
     * @param ShapeTextView
     * @param change The text model changes
     * @override
     */
    protected updateText( text: IShapeTextView, change?: any ) {
        text.update( this.model.texts[ text.name ], change, this.model.defaultBounds, this.model.transform );
    }

    /**
     * This function updates the text view accoridng to the given text model
     * @param ShapeTextView
     * @param change The text model changes
     * @override
     */
    protected updateImage( image: IShapeImageView, change?: any ) {
        image.update( this.model.images[ image.name ], change, this.model.defaultBounds, this.model.transform );
    }


    /**
     * Moves the shape to the given location
     * @param x the position on the canvas to move to
     * @param y the position on the canvas to move to
     */
    protected move( x: number, y: number ) {}

    /**
     * Scales the shape in height.
     * @param scale The scale multiplier
     */
    protected scaleHeight( scale: number ) {}

    /**
     * Scales the shape in width.
     * @param scale The scale multiplier
     */
    protected scaleWidth( scale: number ) {}

    /**
     * Rotates the shape to the given angle
     * @param angle The angle to rotate to.
     */
    protected rotate( angle: number ) {}

}

