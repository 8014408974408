import { enableProdMode } from '@angular/core';
import { Sakota } from '@creately/sakota';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from './config/config';
import { AppConfig } from 'flux-core';

/**
 * Declare the __CreatelyContainerInfo__ variable globally (typescript).
 */
declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __CreatelyConfig__: {
          [ key: string ]: any,
      };
  }
}

if ( environment.NAME === 'stage' || environment.NAME === 'prod' || environment.NAME === 'confluence' ) {
  enableProdMode();
  Sakota.enableProdMode();
}

/**
 * Set the environment instance to AppConfig
 */
AppConfig.setConfig({
  ...environment,
  ...( window.__CreatelyConfig__ || {}),
});

platformBrowserDynamic().bootstrapModule( AppModule );
