<div *ngIf="item.type === 'shape'" #libTileElem class="plus-create-item-container plus-create-item fx-ellipsis" draggable="false" >
    <div *ngIf="(loadThumb | async);then thumbnail else defaultImage" ></div>
    <ng-template #thumbnail>
        <thumbnail-image
            *ngIf="item.thumbnailType==='image'"
            class="shape-thumbnail-image"
            [url]="item.thumbnailUrl">
        </thumbnail-image>
        <plus-thumbnail-canvas
            *ngIf="item.thumbnailType==='canvas'"
            class="shape-thumbnail-image"
            [defId]="item.data.defId"
            [version]="item.data.version"
            [style]="changeStyle(item.data.style)"
            [focus]="focused | async"
            [canvasWidth]="canvasWidth"
            [canvasAreaWidth]="canvasAreaWidth"
            [canvasIdPrefix]="item.canvasIdPrefix"
            >
        </plus-thumbnail-canvas>
    </ng-template>
    <ng-template #defaultImage>
        <svg class="nu-icon light-nv-blue" >
            <use [attr.xlink:href]="iconHref" ></use>
        </svg>
    </ng-template>
    <label class="caption fx-ellipsis">{{item.title}}</label>
</div>

<div *ngIf="item.type === 'viz'" #libTileElem class="plus-create-item-container plus-create-item fx-ellipsis" draggable="false" >
    <label class="caption fx-ellipsis">{{item.title}}</label>
</div>