import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InterfaceControlState } from '../../../../../base/base-states';
import { NotificationCommandEvent } from '../../../../../notification/command/notification-command-event';
import { DiagramNavigation } from '../../../../../system/diagram-navigation.svc';
import { CommandService, StateService } from 'flux-core';
import { ModelSubscriptionManager } from 'flux-subscription';
import { take, tap } from 'rxjs/operators';
import { IRichNotification } from '../global-notification-notifications.cmp';


/**
 * Notification List Item Component
 * Represent a single notification of the notification list
 *
 * @author  Sajeeva
 * @since   2021-09-18
 */
@Component({
    selector: 'notification-list-item',
    templateUrl: './notification-list-item.cmp.html',
    styleUrls: [ './notification-list-item.scss' ],
    // Changing the statergy to default as the changes are not updated for notification description.
    // User can not do any action on the app when the notification is open. Because of that,
    // it is ok to have the change statergy to Default
    changeDetection: ChangeDetectionStrategy.Default,
})
export class NotificationListItem {

    @Input() public notification: IRichNotification;

    constructor(
        protected translateService: TranslateService,
        protected commandService: CommandService,
        protected stateService: StateService<any, any>,
        protected modelSubManager: ModelSubscriptionManager,
        protected diagramNavigation: DiagramNavigation,
    ) {}
    /**
     * Retruns the event owner's name.
     */
    public get actorName() {
        return this.notification.actorInfo?.fullName || this.notification.actorInfo?.email;
    }


    /**
     * Boolean if the notification is seen or not.
     */
    public get isRead(): boolean {
        return this.notification.seen;
    }

    /**
     * Mark the notification as read( seen ).
     */
    public markAsRead() {
        this.commandService.dispatch( NotificationCommandEvent.markGlobalNotificationRead,
                                        { notificationIds: [ this.notification._id ]});
    }

    /**
     * Opens the resource which is referred in the notification. It will
     * ignore if the resource is not available.
     */
    public openReferingResource() {
        if ( !this.notification.seen ) {
            this.markAsRead();
        }
        if ( this.canNavigateToResource()) {
            if ( this.notification.resourceType === 'DIAGRAM' ) {
                this.navToSelectedDiagram( this.notification.resourceId );
            } else if ( this.notification.resourceType === 'PROJECT' ) {
                this.openFolderpanel( this.notification.resourceId );
            }
        }
    }

    /**
     * Checks if the user can navigate to the resource associated with the notification event.
     * @returns
     */
    public canNavigateToResource(): boolean {
        return !this.notification.key.endsWith( '.delete' );
    }

    /**
     * This function navigates to the selected diagram
     * after removing the subscription to the current
     * diagram in selection.
     * @param diagramId
     */
    private navToSelectedDiagram( diagramId: string ) {
        const currentDiag = this.stateService.get( 'CurrentDiagram' );
        if ( currentDiag !== 'start' ) {
            this.modelSubManager.stop( this.stateService.get( 'CurrentDiagram' ), true ).pipe(
                take( 1 ),
                tap(() => this.navigateToDiagram( diagramId )),
            ).subscribe();
        } else {
            // avoid calling the model sub as there is no 'start' doc in the DB.
            this.navigateToDiagram( diagramId );
        }
    }

    /**
     * Open folder panel and select given project.
     * @param projectId
     */
    private openFolderpanel( projectId: string ) {
        this.stateService.set( 'SelectedLeftPanel', 'folders' );
        this.stateService.set( 'FolderPanelSelectedTab', 'diagrams' );
        this.stateService.set( 'FolderPanelSelectedProject', projectId );
    }

    /**
     * Navigates to given diagram. Opens the diagram in view or edit mode baed on the current interface state.
     * @param diagramId
     */
    private navigateToDiagram( diagramId: string ) {
        const interfaceState =
            this.stateService.get( 'InterfaceControlState' ) === InterfaceControlState.View ? 'view' : 'edit';
        this.diagramNavigation.navigateToDiagram( diagramId, interfaceState );
    }
}
