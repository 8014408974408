import { modify } from '@creately/mungo';
import { Proxied } from '@creately/sakota';
import { Deserializer, IModifier, Logger } from 'flux-core';
import { IEDataModel } from 'flux-definition';
import { IEntity } from 'flux-definition';
import { EDataModel } from '../model/edata.mdl';
import { EntityModel } from '../model/entity.mdl';
import { EDataModelFactory } from '../model/edata-model-factory';
import { EntityModelFactory } from '../model/entity-model-factory';
import { correctEntityModifier } from './edata-split-modifier';


/**
 * EDataFactory
 * Contains factory functions which can be used to create new edata or entity instances.
 * Using this we can perform entity updating logic outside the edata locator class.
 */
export class EDataFactory<DT extends EDataModel, ET extends EntityModel> {

    /**
     * Creates a diagram model with the correct type. This should only create the structure.
     */
    public createEData( data: IEDataModel ): Promise<EDataModel> {
        const observable = EDataModelFactory.instance.createByData( data, EDataModel );
        return observable.toPromise() as Promise<EDataModel>;
    }

    /**
     * Updates a diagram model in-place. Applies the given modifier to the given diagram model.
     */
    public updateEData( model: Proxied<EDataModel>, modifier: IModifier ): Promise<unknown> {
        modify( model, modifier );
        return Promise.resolve( model );
    }

    /**
     * Creates a shape model with the correct type. This should only create the structure.
     */
    public createEntity( data: IEntity ): Promise<EntityModel> {
        const observable = EntityModelFactory.instance.createByData( data );
        return new Promise(( resolve, reject ) => {
            observable.subscribe(
                val => {
                    resolve( val as any );
                    resolve = () => {};
                },
                err => {
                    reject( err );
                },
                () => resolve( null ),
            );
        });
    }

    /**
     * Removes a shape model from the diagram model.
     */
    public removeEntity( model: EDataModel, entityId: string ): Promise<unknown> {
        delete model.entities[ entityId ];
        return Promise.resolve( null );
    }

    /**
     * Updates a shape model in-place. Applies the given modifier to the given shape model.
     * A modifier with all changes should also be passed in which will be used if needed.
     */
    public updateEntity(
        model: Proxied<EntityModel>,
        modifier: IModifier,
        root?: EDataModel,
        fullModifier?: IModifier,
    ): Promise<unknown> {
        if ( !model ) {
            Logger.debug( 'Entity Model undefined', modifier );
            return Promise.resolve();
        }
        model.__sakota__.reset();
        modify( model, correctEntityModifier( fullModifier ));
        if ( !model.shapes ) {
            model.shapes = {};
        }
        const type = EntityModel;
        return Deserializer.build( type, model, model ).toPromise();
    }
}
