import { Injectable } from '@angular/core';
import { Command, AbstractCommand, CommandInterfaces, StateService } from 'flux-core';
import { SessionHistoryManager } from '../../../system/session-history-manager.svc';

/**
 * This command uses the {@link SessionHistoryManager}
 * to to undo the last undoable change.
 *
 * @author  Ramishka
 * @since   2018-08-25
 */
@Injectable()
@Command()
export class UndoAction extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor( protected historyMgr: SessionHistoryManager,
                 protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public execute() {
        if ( this.state.get( 'InterfaceControlState' ) === 'edit' ) {
            this.historyMgr.undo().subscribe();
            return true;
        }
        return false;
    }
}

Object.defineProperty( UndoAction, 'name', {
    value: 'UndoAction',
});
