import { CommandError } from './command-error';

/**
 * CommandCancelError is a special type of error thrown only when the command is
 * cancelled by the user. CommandExecutor will stop running the command
 */
export class CommandCancelError extends CommandError {
    constructor ( message: string ) {
        super( message )/* istanbul ignore next */;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, CommandCancelError.prototype );
        this.name = 'CommandCancelError';
    }
}
