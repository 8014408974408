import { CommandError, AbstractCommand, CommandResultError, Command } from 'flux-core';

/**
 * This is an abstraction for concrete {@link IMessageCommand} implementations.
 * It extends {@link AbstractCommand} and contains all the abstractions and overrides
 * that are specific to message commands.Any command that involes communication with
 * the server should extend this class.
 *
 * @author  Ramishka
 * @since   2017-03-08
 */
@Command()
export class AbstractMessageCommand extends AbstractCommand {
    /**
     * This will be triggered when the server message comes back with an error.
     * When this occurs, the resultData can be null.
     * This same error will be thrown in the Observable that was recieved by
     * the CommandService.dispatch method.
     *
     * @param   error The error that occured during the message command.
     */
    public onError( error: CommandError ) {
        const messageError = error as CommandResultError;
        this.log.error( 'Command: ' + messageError.code + '. ' + messageError.message );
    }

}
