import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractNotification, CommandService, Logger, NotificationType,
    NotifierController, StateService } from 'flux-core';
import { BehaviorSubject, EMPTY, merge, Observable, Subject } from 'rxjs';
import { delay, filter, map, take, tap, mergeMap, switchMap } from 'rxjs/operators';
import { UserLocator } from '../../../../../libs/flux-user/src';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { RealtimeConnectionStatus, RealtimeStateService } from './../realtime/realtime-state.svc';
import { IPresentingState, PresentationModel, PresentingMode } from './model/presentation.mdl';
import { PresentationLocator } from './presentation-locator.svc';
import { SlideModel } from './model/slide.mdl';
import { ModelSubscriptionManager } from 'flux-subscription';
import { InterfaceControlState } from '../../base/base-states';
import { DiagramNavigation } from '../../system/diagram-navigation.svc';
import { DiagramLocatorLocator } from '../diagram/locator/diagram-locator-locator';
import { LinkService } from '../diagram/link.svc';
import { Rectangle } from 'flux-core';
import { ViewportService } from '../diagram/viewport.svc';
import { values } from 'lodash';
import { BaseDiagramCommandEvent } from '../diagram/command/base-diagram-command-event';
import { ShapeModel } from '../shape/model/shape.mdl';

const VIEWPORT_PADDING = 120;
/**
 * This class is responsible for handling presentation functionality like joinging presentations,
 * navigating between slides, and exiting a presentation.
 */
@Injectable()
export class PresentingService {
    /*tslint:disable:max-line-length*/

    /**
     * The realtime state key for presentation sessions changes.
     */
    public static KEY_PRESENTING = 'presenting';
    /**
     * realtime state for presentationId on this topic.
     */
    public static KEY_PRESENTATION_ID = 'presentationId';
    /**
     * realtime state for presenter's current slide, update viewwer's slide when presenter's slide changes
     */
    public static KEY_CURRENT_SLIDE = 'currentSlide';
    /**
     * State for sending notifications
     */
    public static KEY_SEND_INVITATIONS = 'sendinvitations';
    /**
     * realtime state for tracking viewers on the presentation
     */
    public static KEY_VIEWER = 'viewer';

    public followers = new BehaviorSubject([]);

    public presenter = new BehaviorSubject({});

    protected presenterSlide: string = '';

    private onPresenting = new BehaviorSubject( false );
    private showingInvite = new BehaviorSubject( false );

    private onPresentationStarted = new BehaviorSubject( '__NONE__' );

    private dismissObsListenPresent: Subject<boolean> = new Subject();

    constructor(
        protected realtimeStates: RealtimeStateService,
        protected state: StateService<any, any>,
        protected commandSvc: CommandService,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected userLocator: UserLocator,
        protected dl: DiagramLocatorLocator,
        protected pl: PresentationLocator,
        protected modelSubManager: ModelSubscriptionManager,
        protected diagramNavigation: DiagramNavigation,
        protected linkService: LinkService,
        protected viewport: ViewportService,
    ) {}

    public initialize() {
        this.state.changes( 'RealtimeConnectionStatus' ).pipe(
            filter( connStatus => connStatus === RealtimeConnectionStatus.ONLINE ),
            take( 1 ),
        ).subscribe({
            complete: () => { // now the user is connected to socket cluster
                this.listenForPresentations();
                this.listenToPresenterSlideChange();
                this.listenForFollowers();
                // this.listenForProjectChange();
            },
        });
    }

    /**
     * Get current presentation model
     * @param presentationId
     * @returns
     */
    public getPresentation( presentationId ): Observable<PresentationModel> {
        return this.pl.getPresentation( presentationId );
    }

    /**
     * Changes between Start presenting or stop presenting
     * @param presentationId
     */
    public togglePresenting( presentationId: string ) {
        if ( this.onPresenting.value ) {
            this.stopPresenting( presentationId );
        } else {
            this.startPresenting( presentationId );
        }
    }

    /**
     * Adds a user to a presentation. Takes presentationID and presenter's ID
     * @param presentationId
     * @param presenterId
     */
    public joinPresentation( presentationId: string, presenterId: string ) {
        this.userLocator.getUserInfo( presenterId as string ).pipe(
            take( 1 ),
            tap( userData => {
                this.presenter.next( userData );
                this.dismissObsListenPresent.next( true );
                this.state.set( 'PresentationStatus', { presentationId: presentationId, presenting: false });
                this.state.set( 'PresenterId', presenterId );
                this.state.set( 'LeftSidebarVisibility', false );
                this.realtimeStates.set( PresentingService.KEY_VIEWER, 'ON' );
                const loadingText = this.translate.instant( 'FOLLOW.FOLLOWING', { userFullName: userData.fullName });
                this.state.set( 'AcknowledgementMessage', { open: true, message: loadingText, showLoader: false, color: userData.getColor() });
                /**
                 * Wait for leftsidebar to be closed before loading the slide
                 */
                setTimeout(() => this.loadSlide( presentationId, 0, false ).subscribe(), 500 );
            }),
        ).subscribe();
    }

    /**
     * Start a presentation.
     * @param presentationId
     */
    public startPresenting( presentationId: string ) {
        this.state.set( 'PresentationStatus', { presentationId: presentationId, presenting: true });
        this.realtimeStates.set( PresentingService.KEY_PRESENTING, 'ON' );
        this.realtimeStates.set( PresentingService.KEY_PRESENTATION_ID, presentationId );
        this.onPresenting.next( true );
        const followers = [];
        const currentUserId = this.state.get( 'CurrentUser' );
        this.followers.next( followers );

        this.pl.getPresentation( presentationId ).pipe(
            take( 1 ),
            tap( presentation => {
                const presentingState: IPresentingState = { isPresenting: true, presenterId: currentUserId };
                presentation.presentingState = presentingState;
                this.commandSvc.dispatch( DiagramCommandEvent.updatePresentation, {
                    presentation: { $set: presentation },
                    presentationId: presentation.id,
                });
            }),
        ).subscribe();
        const loadingText = this.translate.instant( 'FOLLOW.WAITING' );
        this.state.set( 'AcknowledgementMessage', { open: true, message: loadingText, showLoader: true });
        merge(
            this.onPresenting.pipe( filter( v => !v )),
            this.followers.pipe(
                filter( data => data.length > 0 ),
                take( 1 ),
            ),
        ).subscribe(() => {
            this.state.set( 'AcknowledgementMessage', { open: false, message: '', showLoader: false });
        });
        this.state.set( 'LeftSidebarVisibility', false );
        this.state.set( 'CurrentSlide', 0 );
        /**
         * Add delay for leftsidebar to close before loading slide. Having the leftsidebar open
         * reduces the viewport and affects accuracy when calculating zoom level.
         */
        setTimeout(() => this.loadSlide( presentationId, 0, false ).subscribe(), 500 );
    }

    /**
     * Join a presentation from the presentation link. Checks if user has folder access,
     * then checks if presentation is still in progress.
     * @param presentationId
     * @returns
     */
    public joinPresentationFromLink ( presentationId: string ) {
        if ( this.state.get( 'CurrentProject' )) {
            this.pl.getPresentation( presentationId ).pipe(
                take( 1 ),
                tap( presentation => {
                    if ( presentation?.presentingState?.presenterId && presentation?.presentingState?.isPresenting ) {
                        this.joinPresentation( presentation.id, presentation.presentingState.presenterId );
                    } else {
                        // Handle no presnter now
                        this.showNoPresenterNotification( presentation );
                    }
                }),
            ).subscribe();
        } else {
            this.dl.forDiagram( this.state.get( 'CurrentDiagram' ), false ).getDiagramModel().pipe(
                tap( diagram => {
                    this.commandSvc.dispatch( BaseDiagramCommandEvent.getPresentations, { projectId: diagram.project });
                }),
                switchMap(() => this.pl.getPresentation( presentationId ).pipe(
                    take( 1 ),
                    // add a delay between getting presentation from neutrino and getting presentation from datastore.
                    delay( 1200 ),
                    tap( presentation => {
                        if ( presentation?.presentingState?.presenterId && presentation?.presentingState?.isPresenting ) {
                            this.joinPresentation( presentation.id, presentation.presentingState.presenterId );
                            this.state.set( 'CurrentProject', presentation.projectId );
                        } else {
                            // Handle no presnter now
                            this.showNoPresenterNotification( presentation );
                        }
                    }),
                )),
            ).subscribe();
        }
    }

    /**
     * is called when a viewer decides to leave the presentation
     */
    public leavePresentation ( presentationId ) {
        this.realtimeStates.set( PresentingService.KEY_VIEWER, 'OFF' );
        this.state.set( 'AcknowledgementMessage', { open: false, message: '', showLoader: false });
        this.state.set( 'PresentationStatus', { });
        this.showingInvite.next( false );
        this.onPresenting.next( false );
        this.getAllActiveShapes( this.state.get( 'CurrentDiagram' )).subscribe();
    }

    /**
     * Is called when the presenter stops presenting
     * @param presentationId
     */
    public stopPresenting( presentationId: string ) {
        this.realtimeStates.set( PresentingService.KEY_PRESENTING, 'OFF' );
        this.state.set( 'PresentationStatus', { });
        this.followers.next([]);
        this.onPresenting.next( false );
        this.pl.getPresentation( presentationId ).pipe(
            take( 1 ),
            tap( presentation => {
                presentation.presentingState = { isPresenting: false, presenterId: '' };
                this.commandSvc.dispatch( DiagramCommandEvent.updatePresentation, {
                    presentation: { $set: presentation },
                    presentationId: presentation.id,
                });
            }),
            switchMap(() => this.getAllActiveShapes( this.state.get( 'CurrentDiagram' ))),
        ).subscribe();
    }

    /**
     * Adds collaborators to the presentation
     * @param users
     */
    public inviteCollaborator( users ) {
        const presentationStatus = this.state.get( 'PresentationStatus' );
        this.pl.getPresentation( presentationStatus.presentationId ).pipe(
            take( 1 ),
            tap( presentation => {
                const collaborators = presentation.collaborators || [];
                users.forEach( user => {
                    if ( user.id && !collaborators.includes( user.id )) {
                        collaborators.push( user.id );
                        // Send invitations to user id
                    }
                });
                presentation.collaborators = collaborators;
                this.commandSvc.dispatch( DiagramCommandEvent.updatePresentation, {
                    presentation: { $set: presentation },
                    presentationId: presentation.id,
                });
            }),
        ).subscribe(() => {
            // Wait for collab changes to sync up before sending invitations
            setTimeout(() => {
                this.realtimeStates.set( PresentingService.KEY_SEND_INVITATIONS, 'ON' );
                this.realtimeStates.set( PresentingService.KEY_PRESENTATION_ID, presentationStatus.presentationId );
            }, 2000 );
        });
    }

    /**
     * Go to the presenter's current slide
     */
    public goToPresenter() {
        const presentationStatus = this.state.get( 'PresentationStatus' );
        return this.getPresentation( presentationStatus.presentationId ).pipe(
            take( 1 ),
            tap( presentation => {
                if ( presentation && presentation.slides ) {
                    const slide = presentation.slides[ this.presenterSlide ] as SlideModel;
                    if ( slide.shapes && slide.shapes.length > 0 ) {
                        const currentDiagram = this.state.get( 'CurrentDiagram' );
                        if ( currentDiagram !== slide.diagramId ) {
                            this.navToDiagram( slide.diagramId, slide );
                        } else {
                            this.dl.forDiagram( currentDiagram, false ).getDiagramModel().pipe(
                                take( 1 ),
                                tap( diagram => this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ))),
                            ).subscribe();
                        }
                        this.onSlideLoad( slide );
                    } else {
                        Logger.error( 'Cannot find presenter\'s slide' );
                    }
                }
            }),
        );
    }

    /**
     * Reload the current slide. Is called when new shapes are added to the canvas during the presentation
     * @param presentationId
     * @returns
     */
    public reloadSlide( presentationId ) {
        const presentationStatus = this.state.get( 'PresentationStatus' );
        if ( presentationStatus && presentationStatus.presentationId === presentationId ) {
            return this.pl.getPresentation( presentationId ).pipe(
                take( 1 ),
                delay( 1200 ),      // Adding delay for presentation to be updated in datastore before fetching
                map( presentation => {
                    const slideId = this.state.get( 'ActiveSlide' );
                    const slide = presentation.slides[ slideId ] as SlideModel;
                    const currentDiagram = this.state.get( 'CurrentDiagram' );
                    return { slide, currentDiagram };
                }),
                switchMap( data => this.dl.forDiagram( data.currentDiagram, false ).getDiagramModel().pipe(
                    take( 1 ),
                    tap( diagram => {
                        const shapes = data.slide.shapes.filter( i => typeof diagram.shapes[ i ] !== 'undefined' );
                        const childShapes = [];
                        shapes.forEach( shapeId => {
                            const shape = diagram.shapes[ shapeId ] as ShapeModel;
                            if ( shape.children ) {
                                childShapes.push( ...diagram.getContainerChildrenWithConnectors( shapeId ));
                            }
                        });
                        shapes.push( ...childShapes );
                        data.slide.shapes = shapes;
                        if ( data.slide.mode === PresentingMode.FOCUSED ) {
                            this.state.set( 'ActiveShapes', data.slide.shapes );
                        } else {
                            this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ));
                        }
                    }),
                )),
            );
        }
        return EMPTY;
    }

    /**
     * Loads a slide into the viewport
     * @param presentationId Id of the presentation
     * @param slideIndex Index of the slide to be loaded
     * @param broadcast If true, this slide index will be loaded for all followers.
     * @returns
     */
    public loadSlide( presentationId: string, slideIndex: number = 0, broadcast: boolean = false ) {
        return this.getPresentation( presentationId ).pipe(
            take( 1 ),
            tap( presentation => {
                if ( presentation && presentation.slides ) {
                    const slideId = Object.keys( presentation.slides )[slideIndex];
                    const slide = presentation.slides[ slideId ] as SlideModel;
                    if ( slide.shapes && slide.shapes.length > 0 ) {
                        const currentDiagram = this.state.get( 'CurrentDiagram' );
                        if ( currentDiagram !== slide.diagramId ) {
                            this.navToDiagram( slide.diagramId, slide );
                        } else {
                            this.dl.forDiagram( currentDiagram, false ).getDiagramModel().pipe(
                                take( 1 ),
                                tap( diagram => this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ))),
                            ).subscribe();
                            this.onSlideLoad( slide );
                        }
                        if ( broadcast ) {
                            this.realtimeStates.set( PresentingService.KEY_CURRENT_SLIDE, slideId );
                        }
                    } else {
                        // Handle empty slide
                    }
                }
            }),
        );
    }

    /**
     * Is called after a slide is loaded.
     * Checks is the diagram load is complete then adjust zoom level, pan, and sets active shapes
     * @param slide
     * @param controller
     */
    public onSlideLoad ( slide: SlideModel, controller?: boolean ) {
        this.state.set( 'ActiveSlide', slide.id );
        let allShapes = [];
        const presentationStatus = this.state.get( 'PresentationStatus' );
        if ( this.state.get( 'DiagramLoadComplete' ) && !controller ) {
            // Clean slide, remove all deleted shapes from slide
            const currentDiagram = this.state.get( 'CurrentDiagram' );
            this.dl.forDiagram( currentDiagram, false ).getDiagramOnce().pipe(
                take( 1 ),
                map( diagram => {
                    const shapes = slide.shapes.filter( i => typeof diagram.shapes[ i ] !== 'undefined' );
                    allShapes = Object.keys( diagram.shapes );
                    const childShapes = [];
                    const connectors = [];
                    shapes.forEach( shapeId => {
                        const shape = diagram.shapes[ shapeId ] as ShapeModel;
                        if ( shape.children ) {
                            childShapes.push( ...diagram.getContainerChildrenWithConnectors( shapeId ));
                        }
                    });
                    shapes.push( ...childShapes, ...connectors );
                    slide.shapes = shapes;
                    return slide;
                }),
                filter( s => s.shapes.length > 0 ),
                mergeMap( cleanSlide => {
                    this.linkService.navigateToShapes( cleanSlide.shapes );
                    this.state.set( 'DiagramZoomLevel', 1 );
                    this.state.set( 'ActiveShapes', []);
                    return this.viewport.getShapeModels( cleanSlide.shapes ).pipe(
                        take( 1 ),
                        map( data =>  {
                            const shapesArray = values( data );
                            const bounds: Rectangle = shapesArray[ 0 ].viewBounds;
                            shapesArray.forEach( s => {
                                bounds.absorb( s.viewBounds );
                            });
                            const viewport: Rectangle = this.state.get( 'DiagramViewPort' );
                            const scaleX = ( viewport.width - 2 * VIEWPORT_PADDING ) / bounds.width;
                            const scaleY = ( viewport.height - 2 * VIEWPORT_PADDING ) / bounds.height;
                            const zoomLevel = Math.min( scaleX, scaleY );
                            this.state.set( 'DiagramZoomLevel', zoomLevel );
                        }),
                        delay( 2 ),     // Adding a delay for state changes to be absorbed
                        tap(() => {
                            const currentPan = this.state.get( 'DiagramPan' );
                            this.state.set( 'DiagramPan', { x: currentPan.x, y: currentPan.y - 60 });

                            const mode = cleanSlide.mode;
                            if ( mode === PresentingMode.FOCUSED && presentationStatus?.presentationId  ) {
                                // Show only shapes that have to been shown
                                this.state.set( 'ActiveShapes', cleanSlide.shapes );
                            } else {
                                this.state.set( 'ActiveShapes', allShapes );
                            }
                            if ( !presentationStatus || !presentationStatus.presentationId ) {
                                this.commandSvc.dispatch(
                                    BaseDiagramCommandEvent.selectShapes,
                                    this.state.get( 'CurrentDiagram' ),
                                    { shapeIds: cleanSlide.shapes, add: false },
                                );
                            }
                        }),
                    );
                }),
            ).subscribe();
        } else {
            // FIXME: Delay time is small for low end devices and devices with slower connection.
            // Need to run when diagram load and rendering is complete
            this.state.changes( 'DiagramLoadComplete' ).pipe(
                filter( x => !!x ),
                delay( 3000 ),
                switchMap(() => this.dl.forDiagram( slide.diagramId, false ).getDiagramModel().pipe(
                    take( 1 ),
                    tap( diagram => this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ))),
                    tap ( loaded => {
                        if ( loaded ) {
                            this.onSlideLoad( slide );
                        }
                    }),
                )),
            ).subscribe();
        }
    }

    /**
     * Close subscription to current diagram and load a new diagram containing the new slide
     * @param diagramId
     * @param slide
     * @returns
     */
    public navToDiagram( diagramId, slide ) {
        const currentDiag = this.state.get( 'CurrentDiagram' );

        if ( currentDiag === diagramId ) {
            return;
        }

        if ( currentDiag !== 'start' ) {
            if ( currentDiag !== diagramId ) {
                this.modelSubManager.stop( this.state.get( 'CurrentDiagram' ), true ).pipe(
                    take( 1 ),
                    tap(() => {
                        if ( this.state.get( 'InterfaceControlState' ) === InterfaceControlState.View ) {
                            this.diagramNavigation.navigateToDiagram( diagramId, 'view' );
                        } else {
                            this.diagramNavigation.navigateToDiagram( diagramId, 'edit' );
                        }
                    }),
                    tap(() => {
                        this.onSlideLoad( slide, true );
                    }),
                ).subscribe();
            }
        } else {
            if ( this.state.get( 'InterfaceControlState' ) === InterfaceControlState.View ) {
                this.diagramNavigation.navigateToDiagram( diagramId, 'view' );
            } else {
                this.diagramNavigation.navigateToDiagram( diagramId, 'edit' );
            }
        }
    }

    public showErrorNotification() {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_PRESENTATION' ),
                description: this.translate.instant(
                    'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_PRESENTATION_MOVE_WORKSPACE' ),
                autoDismiss: true,
            },
        };
        this.notifierController.show(
            'ERROR_CREATING_PRESENTATION', AbstractNotification, NotificationType.Error, options, false );
    }

    public showErrorNotificationForNewSlide() {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_SLIDE' ),
                description: this.translate.instant(
                    'NOTIFICATIONS.PRESENTATIONS.ERROR_CREATING_SLIDE_DETAILS' ),
                autoDismiss: true,
            },
        };
        this.notifierController.show(
            'ADD_NEW_SLIDE', AbstractNotification, NotificationType.Error, options, false );
    }

    public showErrorNotificationForEditSlide() {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.INVALID_SLIDE_INPUT' ),
                description: this.translate.instant(
                    'NOTIFICATIONS.PRESENTATIONS.ADD_SELECTION_TO_UPDATE_SLIDE' ),
                autoDismiss: true,
            },
        };
        this.notifierController.show(
            'UPDATE_SLIDE', AbstractNotification, NotificationType.Error, options, false );
    }

    /**
     * Display a notification when user joins a presentation using the link but the presentation is over.
     * @param presentation
     */
    protected showNoPresenterNotification( presentation ) {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.NO_PRESENTER' ),
                description: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.NO_PRESENTER_DETAIL' ),
                buttonOneText: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.START_PRESENTING' ),
                buttonOneAction: () => [ this.startPresenting( presentation?.id ) ],
                buttonTwoText: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.DISMISS' ),
                buttonTwoAction: () => [ this.dismissObsListenPresent.next( true ) ],
                autoDismiss: false,
                dismissWhen: merge(
                    this.dismissObsListenPresent,
                    this.onPresenting.pipe(
                        filter( v => !!v ),
                    ),
                    this.showingInvite.pipe(
                        filter( v => !!v ),
                    ),
                ),
            },
        };
        this.notifierController.show(
            'FOLLOW_SPOTLIGHT', AbstractNotification, NotificationType.Neutral, options );
    }

    /**
     * Listen for presentation invitations on current topic.
     */
    protected listenForPresentations() {
        merge(
            this.realtimeStates.get( PresentingService.KEY_PRESENTING ),
            this.realtimeStates.get( PresentingService.KEY_SEND_INVITATIONS ),
        ).subscribe( data => {
            if ( data.userId === this.state.get( 'CurrentUser' )) {
                return;
            }
            if ( data.value === 'ON' ) {
                if ( this.state.get( 'PresentationStatus' )?.presentationId || this.showingInvite.value ) {
                    return;
                }
                this.realtimeStates.get( PresentingService.KEY_PRESENTATION_ID ).subscribe( pdata => {
                    if ( pdata.value ) {
                        this.pl.getPresentation( pdata.value, this.state.get( 'CurrentUser' )).pipe(
                            take( 1 ),
                            tap( presentation => {
                                if ( !presentation ||  this.showingInvite.value ) {
                                    return;
                                }
                                this.onPresentationStarted.next( data.userId );
                                this.userLocator.getUserInfo( data.userId ).pipe(
                                    take( 1 ),
                                    tap( userData => {
                                        const dismissObs: Subject<boolean> = new Subject();
                                        this.presenter.next({ ...userData });
                                        const options = {
                                            inputs: {
                                                user: userData,
                                                heading: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.HEADING', { presenterName: userData.fullName, presentationName: presentation?.name }),
                                                description: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.DESCRIPTION' ),
                                                buttonOneText: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.JOIN_NOW' ),
                                                buttonOneAction: () => [ this.joinPresentation( presentation?.id, data.userId ), this.showingInvite.next( false ) ],
                                                buttonTwoText: this.translate.instant( 'NOTIFICATIONS.PRESENTATIONS.DISMISS' ),
                                                buttonTwoAction: () => [ this.dismissObsListenPresent.next( true ), this.showingInvite.next( false ) ],
                                                autoDismiss: false,
                                                dismissWhen: merge(
                                                    dismissObs,
                                                    this.dismissObsListenPresent,
                                                ).pipe(
                                                    tap(() => {
                                                        this.showingInvite.next( false );
                                                    }),
                                                ),
                                            },
                                        };
                                        if ( this.showingInvite.value ) {
                                            return;
                                        }
                                        this.showingInvite.next( true );
                                        this.notifierController.show(
                                            'FOLLOW_SPOTLIGHT', AbstractNotification, NotificationType.Neutral, options );
                                    }),
                                ).subscribe();
                            })).subscribe();
                        }
                    });
            } else {
                this.dismissObsListenPresent.next( true );
                if ( this.state.get( 'PresentationStatus' )?.presentationId ) {
                    this.leavePresentation( this.state.get( 'PresentationStatus' ).presentationId );
                }
            }
        });
    }

    /**
     * Listens to presenter's slide changes. Will update viewer's slide as presenter's slide changes.
     */
    protected listenToPresenterSlideChange() {
        this.realtimeStates.get( PresentingService.KEY_CURRENT_SLIDE ).subscribe( data => {
            const slideId = data.value;
            const presentationStatus = this.state.get( 'PresentationStatus' );
            const presenterId = this.state.get( 'PresenterId' );
            if ( !presentationStatus || !presentationStatus.presentationId ) {
                return;
            }
            if ( !presenterId || presenterId !== data.userId ) {
                return;
            }
            this.presenterSlide = slideId;
            this.getPresentation( presentationStatus.presentationId ).pipe(
                take( 1 ),
                tap( presentation => {
                    if ( presentation && presentation.slides ) {
                        const slide = presentation.slides[ slideId ] as SlideModel;
                        if ( slide.shapes && slide.shapes.length > 0 ) {
                            const currentDiagram = this.state.get( 'CurrentDiagram' );
                            if ( currentDiagram !== slide.diagramId ) {
                                this.navToDiagram( slide.diagramId, slide );
                            } else {
                                this.dl.forDiagram( currentDiagram, false ).getDiagramModel().pipe(
                                    take( 1 ),
                                    tap( diagram => this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ))),
                                ).subscribe(() => this.onSlideLoad( slide ));
                            }
                        } else {
                        }
                    }
                }),
            ).subscribe();
        });
    }

    /**
     * Listen to viewer's joining the presentation.
     */
    protected listenForFollowers() {
        this.realtimeStates.get( PresentingService.KEY_VIEWER ).subscribe( data => {
            if ( data.value === 'ON' ) {
                if ( data.userId !== this.state.get( 'CurrentUser' ) && !this.followers.value.includes( data.userId )) {
                    const followers = this.followers.value;
                    followers.push( data.userId );
                    this.followers.next( followers );
                }
            } else {
                const followers = this.followers.value.filter( uid => uid !== data.userId );
                this.followers.next( followers );
            }
        });
    }

    protected getAllActiveShapes( diagramId ) {
        return this.dl.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            tap( diagram => this.state.set( 'ActiveShapes', Object.keys( diagram.shapes ))),
        );
    }
}
