import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * A loading popup window which is shown/hidden using the
 * modal controller which can contain a icon, a message or both.
 * This will have the loading bubbles fixed to the bottom
 * of the popup which will animate infinitely.
 * How to use:
 * To show: this.modalController.show( LoadingPopup, { inputs: { message: string, icon: string, class: string } } );
 * To close: this.modalController.hide() or use the state 'ModalWindow';
 *
 * @author nuwanthi
 * @since 16-10-2019
 */


@Component({
    selector: 'loading-popup',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './loading-popup.cmp.html',
    styleUrls: [ './loading-popup.scss' ],
})
export class LoadingPopup {

    /**
     * Optional icon that can accompany the message.
     */
    @Input() public icon: string;

    /**
     * Message or text that appears on the loading popup.
     */
    @Input() public message: string;

    /**
     * Unique class to refer to a specific loading popup if needed.
     */
    @Input() public class: string;

    /**
     * Returns the icon's full URL.
     */
    public get iconUrl() {
        return './assets/images/' + this.icon;
    }
}
