import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { Command, CommandService, StateService } from 'flux-core';
import { DataType, IDataItem } from 'flux-definition';
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { filter, switchMap, switchMapTo, take, tap, timeout } from 'rxjs/operators';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { EDataCommandEvent } from '../../../base/edata/command/edata-command-event';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { DESCRIPTION_DATAITEM_ID } from '../../../base/ui/shape-data-editor/data-items-renderer.cmp';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * This MigrateDataDefs command is to update old array
 * base containerRegions.shape to object base containerRegions.shape.
 */
@Injectable()
@Command()
export class MigrateDataDefs extends AbstractDiagramChangeCommand {

    private eDataModels: Proxied<EDataModel>[] = [];

    constructor(
        protected ds: DiagramChangeService,
        protected stateSvc: StateService<any, any>,
        private ell: EDataLocatorLocator,
        private commandSvc: CommandService,
        private subManager: ModelSubscriptionManager,
    ) {
        super( ds );
    }

    public prepareData(): void | Observable<any> {
        if ( !this.changeModel.eData || this.changeModel.eData.length === 0 ) {
            return EMPTY;
        }
        const eDataShapes = Object.values( this.changeModel.shapes ).filter( s => s.eData );
        if ( eDataShapes.length === 0 ) {
            return EMPTY;
        }
        if ( eDataShapes.every( s => s.dataSetId === s.entityId )) {
            return EMPTY;
        }
        const tempDefIds = {};
        eDataShapes.forEach( s => tempDefIds[ s.entityDefId ] = true );
        const eDefIds = Object.keys( tempDefIds );
        eDataShapes.filter( s => s.dataSetId !== s.entityId ).forEach( s => {
            this.changeModel.dataDefs[s.entityId] = this.changeModel.dataDefs[s.dataSetId];
            delete this.changeModel.dataDefs[s.dataSetId];
            this.changeModel.shapes[s.id].dataSetId = s.entityId;
        });
        return this.getAllChangeModels( this.changeModel.eData ).pipe(
            tap( eDataList => {
                const dItemsById = {};
                eDataList.forEach( eData => {
                    const eDataModel = Sakota.create( eData );
                    this.eDataModels.push( eDataModel );
                    const customEDefs = eData.customEntityDefs;
                    for ( const eDefId in customEDefs ) {
                        const dataItems = customEDefs[eDefId].dataItems;
                        dItemsById[eDefId] = dataItems;
                        const dItems = {};
                        for ( const dataItemId in dataItems ) {
                            dItems[dataItemId] = omit( dataItems[dataItemId], [ 'value', 'label' ]);
                        }
                        const entities = eData.getEntitiesByDefId( eDefId );
                        entities.forEach( e => {
                            if ( !eData.dataDefs[ e.id ]) {
                                delete this.changeModel.dataDefs[ e.id ];
                                return;
                            }
                            const dataDefs = eData.dataDefs[ e.id ];
                            for ( const dataItemId in dataItems ) {
                                if ( !dataDefs[dataItemId]) {
                                    if ( this.changeModel.dataDefs[ e.id ]) {
                                        delete this.changeModel.dataDefs[ e.id ][dataItemId];
                                    }
                                    continue;
                                }
                                const dDef = omit( dataDefs[dataItemId], [ 'value', 'label' ]);
                                if ( this.isDataDefSame( dDef, dItems[dataItemId])) {
                                    delete eDataModel.dataDefs[ e.id ][dataItemId];
                                    if ( this.changeModel.dataDefs[ e.id ]) {
                                        delete this.changeModel.dataDefs[ e.id ][dataItemId];
                                    }
                                }
                            }
                            if ( Object.keys( eDataModel.dataDefs[ e.id ]).length === 0 ) {
                                delete eDataModel.dataDefs[ e.id ];
                                delete this.changeModel.dataDefs[ e.id ];
                            }
                        });
                    }
                });
                for ( const eDefId of eDefIds ) {
                    const dataItems = dItemsById[eDefId];
                    this.changeModel.dataDefs[eDefId] = cloneDeep( omit( dataItems, [ DESCRIPTION_DATAITEM_ID ]));
                }
            }),
        );
    }

    public execute() {
        this.resultData = { skipChangeBinders: true };
        this.eDataModels.forEach( model => {
            const modifier = model.__sakota__.getChanges();
            if ( !isEmpty( modifier )) {
                this.commandSvc.dispatch( EDataCommandEvent.applyModifierEData, model.id,
                    { modifier, diagramId: this.stateSvc.get( 'CurrentDiagram' ) });
            }
        });
        return true;
    }

    protected getAllChangeModels( ids: string[]): Observable<EDataModel[]> {
        const obs = ids.map( id => this.subManager.get( id ).status.pipe(
            filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
            take( 1 ),
            switchMapTo( this.ell.getEData( id )),
            switchMap( locator => locator.getEDataModelOnce()),
        ));
        return combineLatest( obs ).pipe(
            timeout( 45000 ),
        );
    }

    private isDataDefSame( dataDef1: IDataItem<DataType>, dataDef2: IDataItem<DataType> ) {
        if ( dataDef1.type !== dataDef2.type ) {
            return false;
        }
        if ([ DataType.OPTION_LIST, DataType.FORMULA ].includes( dataDef1.type )) {
            return isEqual( dataDef1.options, dataDef2.options );
        }
        return true;
    }
}

Object.defineProperty( MigrateDataDefs, 'name', {
    value: 'MigrateDataDefs',
});
