import { first, last } from 'lodash';
import { Injectable } from '@angular/core';
import { IConnectorPoint, IConnectorEndPoint } from 'flux-diagram-composer';
import { AbstractConnectorPather, IEndpointPairInfoForPathing, IEndpointInfoForPathing } from './pather.i';
import { Point, Line, Curve } from 'flux-core';
import { IPoint2D } from 'flux-definition';

/**
 * Pather Curved
 * =============
 *
 * The pather for curved connectors will draw a connected curved path
 * using cubic curves which will connect 2 points in given directions.
 * To create these cubic curves, positions of all path points and their
 * control points need to be calcualted.
 *
 * Calculating path point positions
 * --------------------------------
 *
 * No calculations are done for path points, any fixed points given by
 * the user will be used as connector path points.
 *
 *  - TODO: remove unnecessary path points
 *
 * Calculating control point positions
 * -----------------------------------
 *
 * Control point are positioned near connector points. The distance the
 * control point is placed from the connector point and the angle to the
 * control point from the connector point should be calculated. A number
 * of things have to be considered to keep the curve smooth.
 *
 *  - Control points associated with a connector points and the connector
 *    point itself should be on the same line. Control points should be on
 *    opposite sides of the connector point. Ref: https://goo.gl/FFwB6W
 *
 *  - The curve looks better if for both control points associated with a
 *    connector point the distance to the connector point is the same.
 *
 *  - The curve looks better if the distance from connector point and an
 *    associated control point depends on positions of surrounding points.
 *
 *
 *                      XXXXXXXXXXXXXXXXX
 *                  XXXXX       B      XXXXX
 *               XXXX                       XXX
 *            XXXX                            XXX
 *           XX                                  XXX
 *        XXX                                       XX
 *       XX                                          XXX
 *      XX                                             XX
 *     XX                                               XX
 *     .                                                 .
 *     A                                                 C
 *
 * The figure above shows a section of the curved connector with path
 * points A, B and C. There are 2 curves in this section (AB and BC).
 *
 * As the 2nd control point of AB (Q) and the 1st control point of BC (R)
 * must be on opposite sides of B, and they should have the same distance
 * from point B, one point can be derived by calculating the other one.
 *
 * First, find (X) on extended line AC (between points A and C or outside)
 * where BX is perpendicular to AC.
 *
 *
 *            Q <-------XXXXXXXXXXXXXXXXX-------> R
 *                  XXXXX       B      XXXXX
 *               XXXX           .           XXX
 *     P      XXXX              .             XXX        S
 *     ▲     XX                 .                XXX     ▲
 *     |  XXX                   .                   XX   |
 *     | XX                     .                    XXX |
 *     |XX                      .                      XX|
 *     XX                       .                       XX
 *     .                        .                        .
 *     A------------------------X------------------------C
 *
 * BQ Distance:
 * This value can be calculated by multiplying XA distance by a factor (0.5).
 * The distance is not allowed to go below a minimum value (50).
 *
 * BQ Direction:
 * Lines BQ and XA should be parallel to each other, therefore the anngle BQ
 * can be taken using XA pointing towards C. There's a special case where X
 * is outside points A and C.
 *
 * For connector endpoints, the direction should be taken from endpoint info
 * and the distance should be calculated in a similar way.
 */
@Injectable()
export class PatherCurved extends AbstractConnectorPather {
    private factor = 0.5;
    private minCtrl = 50;

    /**
     * Returns an array of line/curve segments for each point on given connector path.
     */
    public getSegments( points: IConnectorPoint[]): ( Line | Curve )[][] {
        if ( !points.length ) {
            return [];
        }
        const segments: Line[][] = [[]];
        for ( let i = 1; i < points.length; ++i ) {
            segments.push([]);
        }
        return segments;
    }

    /**
     * Creates a path from one point to another with current draw style.
     */
    protected createPath(
        endpoints: IEndpointPairInfoForPathing,
    ): IConnectorPoint[] {
        return [
            endpoints.pointA.point,
            endpoints.pointB.point,
        ];
    }

    /**
     * Corrects the given path so that it'll match the current draw style.
     * This can be used to correct errors in the path after modifications
     * and to convert the connector draw style from one to another.
     */
    protected adjustPath(
        endpoints: IEndpointPairInfoForPathing,
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        if ( this.shouldConnectStraight( endpoints, currentPath )) {
            return currentPath.map( point => ({
                ...point,
                c1: null,
                c2: null,
                bumps: [[]],
            }));
        }
        this.handleFirstEndPoint( endpoints.pointA, currentPath );
        this.handleLastEndPoint( endpoints.pointB, currentPath );
        this.handlePathPoints( currentPath );
        return currentPath;
    }

    /**
     * Reverses all points (including endpoints) on the connector path and
     * makes necessary changes to control points to make sure the path would
     * not change visually.
     */
    protected reversePath(
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[] {
        const reversed: IConnectorPoint[] = [];
        const length = currentPath.length;
        for ( let i = 0; i < length; ++i ) {
            const point = { ...currentPath[i] };
            const next = currentPath[i + 1];
            if ( next ) {
                point.c1 = next.c2;
                point.c2 = next.c1;
            } else {
                point.c1 = null;
                point.c2 = null;
            }
            reversed[ length - 1 - i ] = point;
        }
        return reversed;
    }

    /**
     * If the connector only has 2 points and is not connected to any shapes
     * it should be drawn as a straight line.
     */
    protected shouldConnectStraight(
        endpoints: IEndpointPairInfoForPathing,
        currentPath: IConnectorPoint[],
    ): boolean {
        return currentPath.length === 2 &&
            !endpoints.pointA.shape &&
            !endpoints.pointB.shape;
    }

    /**
     * Sets the 'direction' property on connector endpoints. This value will
     * be stored on connector endpoints which is used when drawing arrow heads.
     * The direction values are angles of tangents drawn on the curve at endpoints.
     */
    protected setDirections( currentPath: IConnectorPoint[]): void {
        const firstPoint = currentPath[ 0 ] as IConnectorEndPoint;
        const lastPoint = currentPath[ currentPath.length - 1 ] as IConnectorEndPoint;
        const afterFirst = currentPath[ 1 ];
        const beforeLast = currentPath[ currentPath.length - 2 ];
        // set the direction for first endpoint
        if ( afterFirst.c1 && afterFirst.c2 ) {
            const firstCurve = new Curve([ firstPoint, afterFirst.c1, afterFirst.c2, afterFirst ]);
            const firstDelta = firstCurve.length > 10 ?
                firstCurve.getPointByLength( 10, true ) :
                firstCurve.getPointByRatio( 0.01, true );
            firstPoint.direction = Point.angleTo( firstDelta, firstPoint );
        } else {
            firstPoint.direction = Point.angleTo( afterFirst, firstPoint );
        }
        // set the direction for last endpoint
        if ( lastPoint.c1 && lastPoint.c2 ) {
            const lastCurve = new Curve([ beforeLast, lastPoint.c1, lastPoint.c2, lastPoint ]);
            const lastDelta = lastCurve.length > 10 ?
                lastCurve.getPointByLength( 10, false ) :
                lastCurve.getPointByRatio( 0.01, false );
            lastPoint.direction = Point.angleTo( lastDelta, lastPoint );
        } else {
            lastPoint.direction = Point.angleTo( beforeLast, lastPoint );
        }
    }

    /**
     * The first poin of the path does not have any control points. Reset it
     * and also sets the first control point of the point after the first point.
     */
    private handleFirstEndPoint( endpoint: IEndpointInfoForPathing, path: IConnectorPoint[]): void {
        const firstPoint = first( path );
        const afterFirst = path[ 1 ];
        const shifted = Point.shift( firstPoint, endpoint.angle, 1 );
        const pointP = new Line( firstPoint, shifted ).perpendicularTo( afterFirst );
        const c2Len = this.calculateCtrlLength( pointP, firstPoint );
        firstPoint.c1 = null;
        firstPoint.c2 = null;
        afterFirst.c1 = Point.shift( firstPoint, endpoint.angle, c2Len );
    }

    /**
     * Sets the last control point of the last point in the path. The
     * first control point of the last points path is set by `handlePathPoints`.
     */
    private handleLastEndPoint( endpoint: IEndpointInfoForPathing, path: IConnectorPoint[]): void {
        const lastPoint = last( path );
        const beforeLast = path[ path.length - 2 ];
        const shifted = Point.shift( lastPoint, endpoint.angle, 1 );
        const pointP = new Line( lastPoint, shifted ).perpendicularTo( beforeLast );
        const c1Len = this.calculateCtrlLength( pointP, lastPoint );
        lastPoint.c2 = Point.shift( lastPoint, endpoint.angle, c1Len );
    }

    /**
     * Control points of path points are calculated considering three
     * points in the array at a time. Check algorithm in class comments.
     */
    private handlePathPoints( path: IConnectorPoint[]): void {
        for ( let i = 2; i < path.length; ++i ) {
            const pointA = path[i - 2];
            const pointB = path[i - 1];
            const pointC = path[i];
            const pointP = new Line( pointA, pointC ).perpendicularTo( pointB );
            const c1Len = this.calculateCtrlLength( pointP, pointA );
            const c1Dir = this.calculateCtrlDirection( pointA, pointC, pointP );
            const c2Len = this.calculateCtrlLength( pointP, pointC );
            const c2Dir = this.calculateCtrlDirection( pointC, pointA, pointP );
            const cpLen = Math.min( c1Len, c2Len );
            pointB.c2 = Point.shift( pointB, c1Dir, cpLen );
            pointC.c1 = Point.shift( pointB, c2Dir, cpLen );
        }
    }

    /**
     * Calculates the distance from the control point to the associated point.
     * In the algorithm described above, the BQ
     */
    private calculateCtrlLength( pointA: IPoint2D, pointB: IPoint2D ) {
        const length = Point.distanceTo( pointA, pointB ) * this.factor;
        return Math.max( length, this.minCtrl );
    }

    /**
     * Calculates the angle the control point should be placed on from P.
     * Point P can exist either in between points A and B or outside it
     * as shown in the figure below.
     *
     *    XXXXXXXXXXXX
     *   XXX         XXXX
     *   XX             XXX
     *   XX                XXX
     *   . XX                XXX
     *   .   XXX                XX
     *   .     XXX               XXXX
     *   .       XXXX               XXXX
     *   .          XXX                XXX
     *   .           .                  .
     *   P           A--> C1            B
     *
     */
    private calculateCtrlDirection( pointA: IPoint2D, pointB: IPoint2D, pointP: IPoint2D ): number {
        const a2b = Point.distanceTo( pointA, pointB );
        const p2a = Point.distanceTo( pointP, pointA );
        const p2b = Point.distanceTo( pointP, pointB );
        if ( p2b > a2b && p2b > p2a ) {
            return Point.angleTo( pointA, pointP );
        } else {
            return Point.angleTo( pointP, pointA );
        }
    }
}
