
import { IResourceLoader, IStyle } from 'flux-definition';
import { AppConfig, Logger, Rectangle, StateService } from 'flux-core';
import { values } from 'lodash';
import { from, Observable, of, forkJoin, combineLatest, merge } from 'rxjs';
import { map, mergeMap, switchMap, mapTo, catchError, take, last } from 'rxjs/operators';
import { DiagramRenderView } from '../../diagram/view/diagram-render-view';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { ConnectorSVGView } from './svg-connector-view';
import { SVGFont } from './svg-font';
import { ImageSVGView } from './svg-image-view';
import { ShapeSVGView } from './svg-shape-view';
import { DiagramDataModel } from '../../diagram/model/diagram-data.mdl';
import { IDiagramLocator } from '../../diagram/locator/diagram-locator.i';
import { uniq } from 'lodash';
import { tiptapCSS, tiptapShapeTextCSS } from './tiptap.css';
import { fromPromise } from 'rxjs/internal-compatibility';

// FIXME: Ignoring as tests are not written for this class
/* istanbul ignore file */
export class DiagramSVGView extends DiagramRenderView {

    /**
     * Regular expression to extract the font-family from the html text
     */
    private regexFontFamily = /font-family="([^"]*)|font-family[ ]*:[^;"']*;?\s*/gm;

    /**
     * True if any shape has rich texsts
     */
    private hasRichTexts: boolean;

    /**
     * Constructor
     * @param locator - DiagramLocator to get the diagram data.
     * @param completes A boolean value. If it is true then this DiagramSVGView instance
     * completes when an SVG is rendered once. If it is false then continue updating
     * whenever the diagram changes without completing the changes. Default is true.
     */
    constructor(
        protected locator: Observable<IDiagramLocator<DiagramDataModel, AbstractShapeModel>>,
        protected resources: IResourceLoader,
        protected state: StateService<any, any>,
        protected completes: boolean = true,
    ) {
        super( state )/* istanbul ignore next */;
    }

    /**
     * The type of shape view that should be created for the diagram view.
     * The type provided for the shape view must extend the ShapeRenderView
     * @override
     */
    protected get shapeViewType(): typeof ShapeSVGView {
        return ShapeSVGView;
    }

    /**
     * The type of connector view that should be created for the diagram view.
     * The type provided for the connector view must extend the ConnectorRenderView
     * @override
     */
    protected get connectorViewType(): typeof ConnectorSVGView {
        return ConnectorSVGView;
    }

    /**
     * The type of image view that should be created for the diagram view.
     * The type provided for the image view must extend the ImageRenderView
     * @override
     */
    protected get imageViewType(): typeof ImageSVGView {
        return ImageSVGView;
    }

    /**
     * The method that populates the diagram view as per the diagram model.
     * TODO: these were piped to 'true' before, check whether it's needed.
     * @override
     */
    public populateDiagram(): Observable<unknown> {
        if ( !this.completes ) {
            return super.populateDiagram();
        }
        return this.locator.pipe(
            take( 1 ),
            switchMap( locator => locator.getCurrentShapes()),
            mergeMap( models => from( models )),
            mergeMap( model => this.handleCreate( model )),
            last(),
        );
    }


    /**
     * Returns the current populated diagram view as an SVG. This will have the SVG
     * of the diagram as populated by the diagram view at the time of this method call.
     *
     * To ensure the diagram has completed populating, wait for the observable return by
     * {@see populateDiagram} method to complete.
     *
     * Idealy, this function should return a SVGSVGElement.
     * It returns a string for the moment due to lack of functionality support
     * on a NodeJS environment. JSDom may be a good candidate to fix this problem on a non-browser
     * env but still it do not have a working implementation to get it working.
     *
     * If need to return a SVGSVGElement, make sure the functionality can be performed on a non-browser env
     * like NodeJS.
     */
    public toSvg( shapeIds?: string[]): Observable<string> {
        return this.locator.pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
            map( diagram => {
                const bounds = shapeIds ? diagram.getBounds( shapeIds ) : diagram.getBounds();
                const svgViewData: ISvgViewData = {
                    leftOffset: -5,
                    topOffset: -3,
                    widthOffset: 8,
                    heightOffset: 6,
                };
                return this.getSvg( bounds, svgViewData, shapeIds );
            }),
        );
    }

    /**
     * Returns the current populated diagram frames bound as an SVG.
     * And also if there are any shape ouside the frames will returns
     * the current populated diagram view as an SVG
     */
    public toSvgFrames(): Observable<string[]> {
        return this.locator.pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
            map( diagram => {
                const frames = diagram.getAllFrames();
                const svgFrames = frames.map( frame => {
                    const frameSvgViewData: ISvgViewData = {
                        leftOffset: 0,
                        topOffset: 0,
                        widthOffset: 0,
                        heightOffset: 0,
                        style: frame.style as IStyle,
                    };
                    const shapeIds = diagram.getAllShapesIdsByFrame( frame.id );
                    return this.getSvg( frame.bounds, frameSvgViewData, shapeIds );
                });
                if ( diagram.isShapeOutsideFrames()) {
                    const bounds = diagram.getBounds();
                    const svgViewData: ISvgViewData = {
                        leftOffset: -5,
                        topOffset: -3,
                        widthOffset: 8,
                        heightOffset: 6,
                    };
                    svgFrames.push( this.getSvg( bounds, svgViewData ));
                }
                return svgFrames;
            }),
        );
    }

    public getSlideFrame( slide ) {
        return this.locator.pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
            map( diagram => {
                const bounds: Rectangle = diagram.shapes[ slide.shapes[ 0 ]].bounds;
                slide.shapes.forEach( s => {
                    bounds.absorb( diagram.shapes[ s ].bounds );
                });
                const svgViewData: ISvgViewData = {
                    leftOffset: 0,
                    topOffset: 0,
                    widthOffset: 0,
                    heightOffset: 0,
                };
                const shapeIds = slide.shapes;
                return this.getSvg( bounds, svgViewData, shapeIds );
            }),
        );
    }

    /**
     * This function gets called whenever a shapes zIndex value changes.
     * @override
     */
    protected handleZIndexChange ( shapeId: string, zIndex: number ): void {
        // NOTE: Currently nothing to be done on the svg view when a zIndex changes
    }

    /**
     * This method gets called when a new shape view has to be added to the diagram.
     * Returned observable will complete without emitting when the shape view is ready.
     */
    protected handleCreate( model: AbstractShapeModel ): Observable<unknown> {
        const shape = this.shapes[ model.id ];
        if ( shape ) {
            throw new Error( 'unexpected error: shape already exists' );
        }
        return combineLatest(
            this.createShape( model ),
            this.locator.pipe(
                    take( 1 ),
                    switchMap( locator => locator.getDiagramOnce()),
                ),
        ).pipe(
            switchMap(([ view, diagram ]) => {
                view.updateModel( model );
                return merge(
                    fromPromise( this.addShape( view )),
                    of( this.render( view )),
                );
            }),
        );
    }

    /**
     * Adds a shape view to the diagram view and makes it
     * ready for rendering.
     * @param shape The shape view to add.
     * @param viewIndex - the order of this shape in the view
     */
    protected addShape( shape: AbstractShapeView ) {
        this.shapes[ shape.id ] = shape;
        const val = shape.initialize() as any;
        if ( val && val instanceof Promise ) {
            return val;
        }
        return Promise.resolve( true );
    }

    /**
     * Override createShape method to attach resources.
     */
    protected createShape( shape: AbstractShapeModel ): Observable<AbstractShapeView> {
        return super.createShape( shape ).pipe(
            switchMap(( view: ShapeSVGView ) => {
                if ( !( view instanceof ShapeSVGView ) || !Object.keys( shape.images ).length ) {
                    return of( view );
                }
                const observables = Object.keys( shape.images ).map( imageId => {
                    const image = shape.images[imageId];
                    const imageUrl = this.getImageUrl( image );
                    return this.resources.load( imageUrl ).pipe(
                        switchMap( data => (
                            this.getImageSize( data, shape.bounds.width * 1.4 ) // 1.4 is to maitain quality
                                .then(( value: any ) => {
                                    view.images[image.file] = { data: value.data, size: value };
                                })
                        )),
                        catchError( err => {
                            Logger.error( err );
                            return of( null );
                        }),
                    );
                });
                return forkJoin( ...observables ).pipe(
                    mapTo( view ),
                );
            }),
        );
    }

    /**
     * Retrieves the url of the image (applicable for image imports only)
     */
    private getImageUrl( imageModel: any ): string {
        if ( imageModel.fileType && imageModel.fileType === 'url' ) {
            return AppConfig.get( 'IMAGE_PROXY' ) + imageModel.file;
        }
        if ( imageModel.fileType && imageModel.fileType === 'shapesassets' ) {
            return './assets/images/shapes-assets/' + imageModel.file;
        }
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + imageModel.file;
    }

    /**
     * Gets the image width and height from the base64 image source.
     */
    private getImageSize( data: string, expectedWidth: number ): Promise<{ data, x, y, width, height }> {
        return new Promise(( resolve, reject ) => {
            const img = new Image();
            img.onerror = err => reject( err );
            img.onload = () => {
                // if the image width is way greater than the expoected size
                // scale down the image to expected width
                if ( img.width - expectedWidth  > 100  ) {
                    // Calculate the aspect ratio of the original image
                    const aspectRatio = img.width / img.height;

                    // Calculate the new height based on the expected width and aspect ratio
                    const expectedHeight = expectedWidth / aspectRatio;

                    // Create a canvas element with the new dimensions
                    const canvas = document.createElement( 'canvas' );
                    canvas.width = expectedWidth;
                    canvas.height = expectedHeight;

                    // Draw the original image onto the canvas with the new dimensions
                    const context = canvas.getContext( '2d' );
                    context.imageSmoothingEnabled = true;
                    context.drawImage( img, 0, 0, expectedWidth, expectedHeight );
                    // Get the new base64 string representation of the scaled-down image
                    const newBase64String = canvas.toDataURL();
                    resolve({ data: newBase64String, x: 0, y: 0, width: expectedWidth, height: expectedHeight });
                } else {
                    resolve({ data, x: 0, y: 0, width: img.width, height: img.height });
                }
            };
            img.src = data;
        });
    }

    /**
     * Populate a SVG string for given bounds and shape data
     */
    private getSvg(
        bounds: Rectangle, svgViewData: ISvgViewData, shapeIds?: string[]): string {
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" ` +
                `xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" ` +
                `height="${bounds.height}pt" width="${bounds.width}pt" ` +
                `viewBox="${bounds.left + svgViewData.leftOffset } ${bounds.top + svgViewData.topOffset } ` +
                `${bounds.width + svgViewData.widthOffset} ${bounds.height + svgViewData.heightOffset}" ` +
                `${ this.getBackgroundStyle( svgViewData.style ) }>`;
        const allShapeData = this.getAllShapeData( shapeIds );
        const fontFamilies = this.extractFontFamily( allShapeData );
        svg += SVGFont.fontFaces( fontFamilies );
        svg += `<defs>
        <style>${ this.getStyles() }</style>
        ${ this.getShadowFilters()}
        </defs>`;
        svg += allShapeData;
        return svg + '</svg>';
    }

    /**
     * This function returns the concatenated svg data for the given
     * list of shape Ids.
     * @returns - SVG string with Shape data.
     */
    private getAllShapeData( shapeIds?: string[]) {
        const shapes: AbstractShapeView[] =
                shapeIds ? this.getShapeViewsByShapeIds( shapeIds ) : values( this.getShapeViews());
        // Sort the shapes by zindex to make sure they are in correct order
        shapes.sort(( s1, s2 ) => s1.model.zIndex - s2.model.zIndex );
        let svg = '';
        for ( const shape of shapes ) {
            if ( !this.hasRichTexts && Object.values( shape.model.texts ).find(
                ( t: any ) => ( t.rendering === 'tiptapCanvas' || t.rendering === 'dom' ))
            ) {
                this.hasRichTexts = true;
            }
            svg += shape.renderedShape;
        }
        return svg;
    }

    /**
     * This function extracts the font-family names from the given html/svg string.
     * @param text - the font-family names to be extracted from.
     * @returns - a string array of font-family names.
     */
    private extractFontFamily( text: string ): string[] {
        let matches;
        const fontFamilyNames = [];
        while (( matches = this.regexFontFamily.exec( text ))) {
            fontFamilyNames.push( matches[0].replace( /\s|;|font-family|:|"|=/g, '' ));
        }
        return uniq( fontFamilyNames );
    }

    /**
     * Get background style from background shape and
     * add those style data into svg string
     * @param style shape style
     * @returns attribute string
     */
    private getBackgroundStyle( style?: IStyle ) {
        let backgroundStyle = style && style.fillColor ? `fillColor="${style.fillColor}" ` : ``;
        if ( style && style.fillGradient ) {
            backgroundStyle += `fillGradient:colors="${( style.fillGradient.colors ).join( ' ' )}" `;
            backgroundStyle += `fillGradient:ratios="${( style.fillGradient.ratios ).join( ' ' )}" `;
            backgroundStyle += `fillGradient:x0="${style.fillGradient.x0}" `;
            backgroundStyle += `fillGradient:x1="${style.fillGradient.x1}" `;
            backgroundStyle += `fillGradient:y0="${style.fillGradient.y0}" `;
            backgroundStyle += `fillGradient:y1="${style.fillGradient.y1}" `;
        }
        return backgroundStyle;
    }

    private getShadowFilters ( shapeIds?: string[]) {
        const shapes: AbstractShapeView[] =
        shapeIds ? this.getShapeViewsByShapeIds( shapeIds ) : values( this.getShapeViews());
        shapes.sort(( s1, s2 ) => s1.model.zIndex - s2.model.zIndex );
        let svg = '';
        for ( const shape of shapes ) {
                if ( shape.model.style && shape.model.style.shadow ) {
                    svg += ` <filter id="shadow${shape.model.id}"><feDropShadow dx="${shape.model.style.shadow.offsetX}" dy="${shape.model.style.shadow.offsetY}" stdDeviation="${shape.model.style.shadow.blur / 10}" flood-color="${shape.model.style.shadow.color}" /></filter>`;
                }
            }
        return svg;
    }

    /**
     * Common styles added to the svg
     */
     private getStyles() {
        return `
            span{ display: inline-block; }
            ${ this.hasRichTexts ? this.getRichtextStyles() : '' }
        `;
    }

    private getRichtextStyles() {
        return `
            /* Copied from tiptap.scss */
            ${tiptapCSS}

            /* Styles specific to shape text editor*/
            ${tiptapShapeTextCSS}
            `;
    }
}

/**
 * This hold data which use in svg export
 */
export interface ISvgViewData {

    /**
     * left offset value
     */
    leftOffset: number;

    /**
     * top offset value
     */
    topOffset: number;

    /**
     * width offset value
     */
    widthOffset: number;

    /**
     * height offset value
     */
    heightOffset: number;

    /**
     * background shape style
     */
    style?: IStyle;

}
