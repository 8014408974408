import { Injectable } from '@angular/core';
import { AbstractCommand, Command, CommandInterfaces, StateService } from 'flux-core';
import { StoredDiagramLocator } from 'flux-diagram-composer';
import { DataStore } from 'flux-store';
import { union } from 'lodash';
import { forkJoin, Observable, empty } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { IShapeData } from '../../shape/shape-data.i';
import { Clipboard } from '@creately/clipboard';
import { cloneDeep } from 'lodash';

/**
 * This command is used to copy the selected diagram shapes to the
 * system clipboard or local clipboard
 * data: {
 *  shapeIds: string[] - Shape id's that needs to be copied.
 *  selected: boolean - If false ignore selected items
 * }
 */
@Injectable()
@Command()
export class CopyShapes extends AbstractCommand {

    /**
     * This command is used to retrieve data. Therefore it only implements ICommand.
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    public static get dataDefinition(): {}  {
        return {
            shapeIds: true,
            selected: false,
            copyEdata: false,
        };
    }

    constructor( protected state: StateService<any, any>,
                 protected dataStore: DataStore,
                 protected ll: DiagramLocatorLocator,
                 protected clipboard: Clipboard  ) {
        super()/* istanbul ignore next */;
    }

    public prepareData(): Observable<any>  {
        if ( !this.data ) {
            this.data = {};
        }
        if ( !this.data.shapeIds ) {
            this.data.shapeIds = [];
        }
        if ( this.data.selected === false ) {
            return this.getShapesToBeCopied( this.data.shapeIds );
        }

        const shapeIds: string[] = this.state.get( 'Selected' );

        return this.getShapesToBeCopied( union( this.data.shapeIds, shapeIds ));
    }

    public execute(): Observable<any> {
        const data = JSON.stringify( this.data );
        this.clipboard.copy( data );
        return empty();
    }

    /**
     * Get the shapes raw data that needs to be copied and updates in the
     * command data.
     * @param list Shape id's that needs to be get the data.
     */
    protected getShapesToBeCopied( list: string[]): Observable<IShapeData[]> {
        const locator: StoredDiagramLocator<any, any> = this.ll.forCurrent( false ) as any;
        return forkJoin( locator.getRawDiagramDataOnce(), locator.getDiagramOnce()).pipe(
            tap(([ diagramRaw, diagram ]: [ any, DiagramModel ]) => {
                const dataDefs = {};
                let shapes = list.map( id => diagramRaw.shapes && diagramRaw.shapes[id]);
                shapes = shapes.map( shapeData => {
                    const type = diagram.shapes[shapeData.id].type;
                    const bounds = diagram.getBounds([ shapeData.id ]);
                    if ( !this.data.copyEdata ) {
                        const edataId = Object.keys( shapeData.eData || {})[0];
                        if ( edataId ) {
                            shapeData.eData = { [edataId]: null };
                            shapeData.triggerNewEData = true;
                            shapeData.entityDefId = diagram.shapes[ shapeData.id ].entityDefId;
                        }
                    }
                    if ( shapeData.entityDefId && diagram.dataDefs[ shapeData.entityDefId ]) {
                        dataDefs[ shapeData.entityDefId ] = diagram.dataDefs[ shapeData.entityDefId ];
                    }
                    // Data set id refers to the id of data defs that have not been synced to type
                    // Once synced, the dataSetId is redundant ( merged into the type def accessed via entityDefId )
                    if ( shapeData.dataSetId && diagram.dataDefs[ shapeData.dataSetId ]) {
                        dataDefs[ shapeData.dataSetId ] = diagram.dataDefs[ shapeData.dataSetId ];
                    }
                    return { data: shapeData, bounds, type };
                });
                this.data.shapesToCopy = shapes;
                this.data.dataDefs = cloneDeep( dataDefs );
                // FIXME: find a way to do this in cmd chain don't set result data if possible
                this.resultData = shapes;
            }),
        );
    }

}

Object.defineProperty( CopyShapes, 'name', {
    value: 'CopyShapes',
});
