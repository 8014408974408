import { DESCRIPTION_DATAITEM_ID } from './../../base/ui/shape-data-editor/data-items-renderer.cmp';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { EDATAREF_CONNECTOR } from 'flux-diagram-composer';
import { EntityLinkType, EDataReservedKeywords,
    EntityPortTransformers, IEntityDef, IEntityLink, IEntityDataMap, FormulaTypes, DataType, SystemType } from 'flux-definition';
import { Injectable } from '@angular/core';
import { EntityModel } from './../../base/edata/model/entity.mdl';
import { isEqual, cloneDeep, get } from 'lodash';
import { StateService, Random, Logger, MapOf } from 'flux-core';
import { ShapeModel } from '../../base/shape/model/shape.mdl';
import { DataItemLevel, EDataModel } from './../../base/edata/model/edata.mdl';
import { Proxied } from '@creately/sakota';
import { FormulaUtils } from '../../base/edata/formula-utils';
import { EDataRegistry } from '../../base/edata/edata-registry.svc';

// tslint:disable:member-ordering
// tslint:disable:max-file-line-count

/**
 * ModelChangeUtils
 * Contains methods to update both EData and Diagram change models
 * The first param of each method is the change model to be updated
 * by the data passed in the second param
 */
@Injectable()
export class ModelChangeUtils {

    private static _instance: ModelChangeUtils;

    static get instance() {
        return this._instance;
    }

    constructor(
        protected state: StateService<any, any>,
    ) {
        ModelChangeUtils._instance = this;
    }

    /************************************************************************************************
     *                                      EData Model change                                         *
     ************************************************************************************************/
    public updateEntityData(
        changeModel: Proxied<EDataModel>,
        data:  {
            entityId: string,
            shape: ShapeModel,
            diagram: DiagramModel,
        },
    ) {

        const entity = changeModel.entities[ data.entityId];
        if ( !entity ) {
            throw Error( 'invalid entity ID ' + data.entityId );
        }

        const dataMap = entity.getShapeDataMap( data.diagram.id, data.shape.id ) || [];
        const fieldMap = {};
        dataMap.forEach( m => {
            fieldMap[m.dataItemId] = m.eDataFieldId;
        });
        let entData = changeModel.entities[ data.entityId ].data;
        const shapeData = data.shape.data;

        Object.keys( shapeData ).forEach( dataItemId => {
            const dataItem = shapeData[dataItemId];
            // skip if the data item is a formula field as the value coming from shape data is outdated.
            const diDef = changeModel.getDataItemDef( dataItemId, data.entityId );

            if ( diDef && diDef.type === DataType.FORMULA ) {
                return;
            }
            if ( !entData ) {
                entData = {};
                changeModel.entities[ data.entityId ].data = entData;
            }
            const item = fieldMap[dataItemId];
            if ( item ) { // mapping found
                if (
                    ( !entData[ item ] && shapeData[dataItemId]) || // add
                    ( entData[ item ] &&  shapeData[dataItemId]
                        && !isEqual( entData[ item ], shapeData[dataItemId].value )) // update
                    ) {
                        entData[ item ] = cloneDeep( shapeData[dataItemId].value );
                }
            } else if ( !( dataItem as any ).systemData )  { // custom dataitem
                if (( entData[ dataItemId ] === undefined && dataItem !== undefined ) ||
                    ( entData[ dataItemId ] !== undefined &&  dataItem !== undefined
                        && !isEqual( entData[ dataItemId ], dataItem.value ))
                    ) {
                        entData[ dataItemId ] = cloneDeep( dataItem.value );
                }
            }

            /**
             * Have to store the primaryTextModel in the entity instance per shapeDef,
             * ( primaryTextBound is true for the dataItem which binds the primary text model )
             * because when a new shape instance is to created from the entity,
             * this text model data are required to add a new text model and bind it
             * to the dataitem.
             */
            const di = data.shape.getDataItems( data.diagram )[dataItemId];
            if ( di && di.primaryTextBound && data.shape.primaryTextModel ) {
                const txt = entity.texts.find( t => t.shapeDef === data.shape.defId );
                if ( !txt ) {
                    entity.texts = [ ...entity.texts, {
                        shapeDef: data.shape.defId,
                        text: cloneDeep( data.shape.primaryTextModel ),
                    }];
                } else if ( !isEqual({ ...txt.text.content }, { ...data.shape.primaryTextModel.content })) {
                    txt.text = cloneDeep( data.shape.primaryTextModel );
                    entity.texts = [ ...entity.texts ];
                }
            }
        });

        // Remove data items from entity - only custom database entity data items are considered
        // And remove from entity blueprint
        const coreDataItems = !changeModel.isCustom && entity.getDef()?.dataItems || {};
        Object.keys( entData ).forEach( key => {
            if ( coreDataItems[key]) { // skip if it's a core data item.
                return;
            }
            if (( shapeData[ key ] === undefined )) {
                delete entData[key];
                if (
                    changeModel.customEntityDefs[ entity.eDefId ] &&
                    changeModel.customEntityDefs[ entity.eDefId ].dataItems &&
                    changeModel.customEntityDefs[ entity.eDefId ].dataItems[ key ]) {
                        const dDef: any = Object.assign({},
                            changeModel.customEntityDefs[ entity.eDefId ].dataItems[ key ]);
                        delete changeModel.customEntityDefs[ entity.eDefId ].dataItems[ key ];
                        dDef.isTypeBound = false;
                        const entityIds = changeModel.getEntitiesByDefId( entity.eDefId ).map( e => e.id );
                        entityIds.filter( eId => eId !== data.entityId ).forEach( eId => {
                            if ( !changeModel.dataDefs[eId]) {
                                changeModel.dataDefs[eId] = {};
                            }
                            if ( !changeModel.dataDefs[eId][key]) {
                                changeModel.dataDefs[eId][key] = cloneDeep( dDef );
                            }
                        });
                }
            }
        });

        this.applyFormulas(
            changeModel,
            data,
        );
    }

    public addEntity(
        changeModel: Proxied<EDataModel>,
        data:  {
            entity: EntityModel,
            diagram: DiagramModel,
            shape: ShapeModel,
        },
        bindPrimaryText: boolean = true,
        updateDef = true ) {

        const currentDocId: string = this.state.get( 'CurrentDiagram' );

        // populate the entity with entityDef.dataItems ( entity blueprint )
        const entityDef = data.entity.getDef();
        if ( entityDef && entityDef.dataItems ) {
            const entity = data.entity;
            if ( !entity.data ) {
                entity.data = {};
            }
            const conditions = [
                key => entity.data[ key ] === undefined,
                key => key !== 'description',
            ];
            if ( !bindPrimaryText ) {
                conditions.push( key => !( entityDef.dataItems[ key ] as any ).primaryTextBound );
            }
            Object.keys( entityDef.dataItems ).forEach( key => {
                if ( conditions.every( condition => condition( key ))) {
                    const dataItem = entityDef.dataItems[ key ];
                    if ( dataItem.type === DataType.LOOKUP ) {
                        entity.data[ key ] = [];
                    } else if (( dataItem as any ).systemType === SystemType.Role ) {
                        entity.data[ key ] = {
                            source: {
                                id: 'collabs',
                                name: 'Role',
                            },
                            people: [],
                        };
                    } else if (( dataItem as any ).roleBound ) {
                        entity.data[ key ] = '';
                    } else {
                        entity.data[ key ] = ( dataItem as any ).value || dataItem.default;
                    }
                }
            });
        }

        // populate the entity with shapeData if a shape exists
        if ( data.shape ) {
            data.entity.addShape( currentDocId, data.shape, true );
        }

        if ( !changeModel.entities ) {
            changeModel.entities = {};
        }
        changeModel.entities[ data.entity.id ] = data.entity;
        if ( updateDef ) {
            this.updateDataDefs( changeModel, data.entity, data.shape || {}, data.diagram );
        }
        // update the entity with the datamapping?
    }

    /**
     * Updates the dataDefs property
     */
    protected updateDataDefs( changeModel: EDataModel, entityModel: EntityModel,
                              shapeModel: any, diagram: DiagramModel ) {
        const dataItems = diagram.getShapeDataItems( shapeModel.id );
        const def = entityModel.getDef();
        const shapeDef = def.shapeDefs[shapeModel.defId];
        const fieldMap = {};
        if ( shapeDef && shapeDef.dataMap ) {
            shapeDef.dataMap.forEach( m => {
                fieldMap[m.dataItemId] = m.eDataFieldId;
            });
        }
        for ( const key in dataItems ) {
            const data = dataItems[ key ];
            changeModel.addDataItemDef( fieldMap[key] || key, entityModel.id, data );
            // if ( !changeModel.dataDefs[ entityModel.id ]) {
            //     changeModel.dataDefs[ entityModel.id ] = {};
            // }
            // changeModel.dataDefs[ entityModel.id ][ key ] = data;
        }

        // const entityDef = entityModel.getDef();
        // if ( entityDef && entityDef.dataItems ) {
        //     for ( const key in entityDef.dataItems ) {
        //         const data = entityDef.dataItems[ key ];
        //         if ( !changeModel.dataDefs[ entityModel.id ]) {
        //             changeModel.dataDefs[ entityModel.id ] = {};
        //         }
        //         changeModel.dataDefs[ entityModel.id ][ key ] = data;
        //     }
        // }
    }

    public addShapeToEntity(
        changeModel: Proxied<EDataModel>,
        data:  {
            entityId: string,
            shapeId: string,
            shapeDefId: string,
            diagramId: string,
            isDelete?: boolean,
        },
    ) {
        if ( !changeModel.entities || !changeModel.entities[data.entityId]) {
            throw Error( 'given ModelID does not exist' );
        }
        const entity = changeModel.entities[data.entityId];
        if ( data.isDelete ) {
            if ( entity.shapes &&
                entity.shapes[data.diagramId] &&
                entity.shapes[data.diagramId][data.shapeId]) {
                delete entity.shapes[data.diagramId][data.shapeId];
                if ( !Object.keys( entity.shapes[data.diagramId]).length ) {
                    delete entity.shapes[data.diagramId];

                    // if that was the last shape delete this entity as well?
                    if ( !Object.keys( entity.shapes ).length ) {
                        delete changeModel.entities[ data.entityId ];
                        delete changeModel.dataDefs[ data.entityId ];
                    }
                }
            }
        } else {
            if ( !entity.shapes ) {
                entity.shapes = {};
            }
            if ( !entity.shapes[data.diagramId]) {
                entity.shapes[data.diagramId] = {} ;
            }
            if ( !entity.shapes[data.diagramId][data.shapeId]) {
                entity.shapes[data.diagramId][data.shapeId] = data.shapeDefId;
            }

        }
    }

    public updateEDateRefChanges(
        changeModel: Proxied<EDataModel>,
        data:  {
            entityIds?: string[], // entities to apply this change to
            sourceEDataId?: string, // where id it get updated
            sourceEntityId?: string,
            sourceDiagId?: string,
            sourceShapeId?: string,
            type: EntityLinkType,
            changeId: string,
            linkId?: string, // when marking resolved
            markResolved?: boolean,
        },
    ) {

        for ( let i = 0; i < data.entityIds.length; i++ ) {
            const eId = data.entityIds[i];
            const entity = changeModel.entities[ eId ];
            if ( !entity ) {
                throw Error( 'invalid entity ID ' + eId );
            }

            let linkId = data.linkId;
            if ( !linkId ) {
                linkId = entity.getLinkId(
                            EntityLinkType.REFERENCE,
                            EDATAREF_CONNECTOR.handshake[0],
                            data.sourceEDataId,
                            data.sourceEntityId,
                            data.sourceDiagId,
                            data.sourceShapeId );
            }

            if ( !linkId ) {
                throw Error( 'link not found, corrupt data' );
            }

            const link = entity.getLink( linkId );
            if ( link ) {
                if ( data.markResolved ) {
                    if ( link.refChanges ) {
                        delete link.refChanges;
                    }
                    entity.refChangeCount = 0;
                } else {
                    if ( !link.refChanges ) {
                        link.refChanges = [ data.changeId ];
                    } else {
                        link.refChanges = [ ...link.refChanges, data.changeId ];
                    }
                    entity.refChangeCount = link.refChanges.length;
                }
            }
        }
    }

    public updateEntityLinkValue(
        changeModel: Proxied<EDataModel>,
        data:  {
            entityId: string,
            sourceEDataId: string,
            sourceEntityId: string,
            type: EntityLinkType,
            handshake: string,
            identifier: any,
            linkId?: string,
        },
    ) {

        const entity = changeModel.entities[ data.entityId];
        if ( !entity ) {
            throw Error( 'invalid entity ID ' + data.entityId );
        }

        let linkId = data.linkId;
        if ( !linkId ) {
            const linkType = this.swapLinkType( data.type );
            linkId = entity.getLinkId(
                        linkType, data.handshake, data.sourceEDataId, data.sourceEntityId );
            if ( !linkId ) {
                throw Error( 'linkId not found in ' + data.entityId );
            }
        }

        if ( !isEqual( entity.links[linkId].identifier, data.identifier )) {
            entity.links[linkId].identifier = data.identifier;
        } else {
            // nothing to do here
            return;
        }


        // apply it to the correct port/dataitem
        const def = entity.getDef();
        const link = entity.links[linkId];

        let handshake;
        if ( link.type === EntityLinkType.PARENT ) {
            handshake = EDataReservedKeywords.containerParent;
        } else if ( link.type === EntityLinkType.CHILD ) {
            handshake = EDataReservedKeywords.containerChild;
        } else {
            handshake = link.handshake;
        }

        if ( def.ports && def.ports[handshake]) {
            const port = def.ports[handshake];
            if ( entity.data[port.mapTo]) {
                if ( port.transformer ) {
                    this.transform( entity.data[port.mapTo], port.transformer, data.identifier  );
                } else {
                    entity.data[port.mapTo].value = data.identifier;
                }
            }
        }

        this.applyFormulas(
            changeModel,
            data,
        );
    }
    /**
     * Transforms the dateItem on the entity based on the transform def with
     * the passed value
     * @param targetDataItem
     * @param transformer
     * @param value
     */
    /* istanbul ignore next */
    private transform( targetDataItem: any, transformer: string, value: any ) {
        if ( transformer === EntityPortTransformers.addToList ) {
            if ( targetDataItem && targetDataItem.value ) {
                targetDataItem.push( value ); // FIXME
            }
        } else if ( transformer === EntityPortTransformers.append ) {
            if ( targetDataItem && targetDataItem.value ) {
                targetDataItem.value = targetDataItem.value + value;
            }
        }
    }

    /**
     * the link type is inverse.
     * @param type
     */
    private swapLinkType( type: EntityLinkType ) {
        if ( type === EntityLinkType.CHILD ) {
            return EntityLinkType.PARENT;
        } else if ( type === EntityLinkType.PARENT ) {
            return EntityLinkType.CHILD;
        } else if ( type === EntityLinkType.CONNECTOR_IN ) {
            return EntityLinkType.CONNECTOR_OUT;
        } else if ( type === EntityLinkType.CONNECTOR_OUT ) {
            return EntityLinkType.CONNECTOR_IN;
        }

        return type;
    }


    public syncLinkedEData(
        changeModel: Proxied<EDataModel>,
        data:  {
            entityId: string,
            dataItemId: string,
            dataItem: any,
        },
    ) {

        const entity = changeModel.entities[ data.entityId];
        if ( !entity ) {
            throw Error( 'invalid entity ID ' + data.entityId );
        }
        // console.log( 'func. CMD.SyncLinkedEData', data.entityId, data.dataItemId, data.dataItem );

        if ( !isEqual( entity.data[ data.dataItemId ], data.dataItem )) {
            entity.data[ data.dataItemId ] = data.dataItem;
        }
        this.applyFormulas(
            changeModel,
            data,
        );
    }

    public updateEdataDefs(
        changeModel: Proxied<EDataModel>,
        data: {
            [entityId: string]: {
                [path: string]: any,
            },
        },
    ) {
        for ( const entityId in data ) {
            const dataItems = data[ entityId ];
            for ( const path in dataItems ) {
                const dataItem = dataItems[ path ];
                changeModel.updateDataDefs( entityId, path, dataItem );
            }
        }
    }

    public updateEntityLinks(
        changeModel: Proxied<EDataModel>,
        data:  {
            diagramId?: string,
            fromEntityId: string,
            toEDataId?: string,
            toEntityId?: string,
            type: EntityLinkType,
            isSet: boolean,
            connectorId?: string, // not there for containers
            connectorDefId?: string, // not there for containers
            handshake?: string, // not needed for remove
            shapeId?: string,
            shapeDefId?: string, // only for edataRef conns
            identifier?: string, // only for edataRef conns
        },
    ) {
        const fromEntity = changeModel.entities[ data.fromEntityId];
        if ( !fromEntity ) {
            throw Error( 'invalid entity ID ' + data.fromEntityId );
        }

        if ( data.fromEntityId === data.toEntityId ) {
            // we don't entertain that kind of link here. get out!
            return;
        }

        if ( data.isSet && data.handshake ) {

            if ( data.connectorId ) {
                // is this connection in another link? (happens when conn type is changed)
                const oldLink = fromEntity.getLinkByConnectorId( data.diagramId, data.connectorId );
                if ( oldLink ) {
                    this.deleteConnectorFromLink( fromEntity, oldLink, data.diagramId, data.connectorId );
                }
            }

            // does this link exist already? we'll just add the connector if so.
            const currId = fromEntity.getLinkId(
                            data.type,
                            data.handshake,
                            data.toEDataId,
                            data.toEntityId,
                            data.diagramId,
                            data.shapeId );

            if ( currId && data.connectorId ) {
                const currLink = fromEntity.getLink( currId );
                const currConns = currLink.connectors;
                if ( currConns ) {
                    if ( !currConns[data.diagramId]) {
                        currConns[data.diagramId] = {};
                    }
                    currConns[data.diagramId][data.connectorId]
                                = { defId: data.connectorDefId, shapeId: data.shapeId };
                } else {
                    const conns = {};
                    conns[ data.diagramId ] = {
                        [data.connectorId]: {
                                defId: data.connectorDefId,
                                shapeId: data.shapeId,
                                shapeDefId: data.shapeDefId,
                            },
                    };
                    currLink.connectors = conns;
                }
            } else { // no link as of now, we create a new link
                const conns = {};
                if ( data.connectorId ) {
                    conns[ data.diagramId ] = {
                        [data.connectorId]: {
                            defId: data.connectorDefId,
                            shapeId: data.shapeId,
                            shapeDefId: data.shapeDefId,
                        },
                    };
                }
                const link: IEntityLink = {
                    id: Random.linkId(),
                    eDataId: data.toEDataId,
                    entityId: data.toEntityId,
                    diagramId: data.diagramId,
                    shapeId: data.shapeId,
                    type: data.type,
                    handshake: data.handshake,
                    identifier: data.identifier,
                    connectors: conns,
                };
                fromEntity.addLink( link );
            }
        } else if ( !data.isSet ) {
            let linkId;
            if ( !data.handshake && data.connectorId ) {
                // happens when a connector type switches from a compatible HS to no HS.
                const link = fromEntity.getLinkByConnectorId( data.diagramId, data.connectorId );
                if ( link ) {
                    linkId = link.id;
                }
            } else {
                linkId = fromEntity.getLinkId(
                    data.type,
                    data.handshake,
                    data.toEDataId,
                    data.toEntityId,
                    data.diagramId,
                    data.shapeId );
            }

            if ( linkId ) {
                if ( data.connectorId ) {
                    const link = fromEntity.getLink( linkId );
                    // delete the connector as it's going away!
                    this.deleteConnectorFromLink( fromEntity, link, data.diagramId, data.connectorId );
                } else { // no connector ID passed, just get rid of it. Usually a
                    fromEntity.removeLink( linkId );
                }
            }
        }
    }

    private deleteConnectorFromLink( entity: EntityModel, link: IEntityLink, diagramId: string, connectorId: string ) {
        // if the connectorId is there in the conn list remove it
        if ( link.connectors[diagramId]
            && link.connectors[diagramId][connectorId]) {
            delete link.connectors[diagramId][connectorId];
            // if that's the last connector for that diagram in this link, remove the diagram
            if ( Object.keys( link.connectors[diagramId]).length === 0 ) {
                delete link.connectors[diagramId];

                // if that was the last connector for this link, the link is gone now.
                if ( Object.keys( link.connectors ).length === 0 ) {
                    entity.removeLink( link.id );
                }
            }
        }
    }

    public applyFormulas(
        changeModel: Proxied<EDataModel>,
        data: {
            entityId: string,
        },
    ) {
        const entity = changeModel.entities[ data.entityId];
        if ( !entity ) {
            throw Error( 'invalid entity ID ' + data.entityId );
        }

        const formulas = entity?.getDef()?.formulas;
        if ( !formulas ) {
            return;
        }

        formulas.forEach( f => {
            if ( f.type === FormulaTypes.string ) {
                FormulaUtils.applyStringFormula( f.expression, entity.data );
            } else {
                // dont do anything, not implemented yet
            }
        });
    }


    /************************************************************************************************
     *                                      Diagram Model change                                         *
     ************************************************************************************************/
    /**
     * Dispatch command to add the entity reference to the shape. Affects Document/Shape
     * @param changeModel DiagramModel
     * @param data data to apply
     */
    public addEDataRefToShape( changeModel, data: any ) {
        const shape = changeModel.shapes[data.shapeId];
        if ( !shape || !( shape instanceof ShapeModel )) {
            throw Error( 'Invalid Shape ID' );
        }

        if ( shape.eData ) {
            throw Error( 'Cannot add a ref when EData is already present.' );
        }

        if ( data.isSet ) {
            if ( !( shape as ShapeModel ).eDataRef ) {
                shape.eDataRef = {};
            }

            if ( !shape.eDataRef[data.eDataId]) {
                shape.eDataRef[data.eDataId] = [ data.entityId ];
            } else if ( shape.eDataRef[data.eDataId].indexOf( data.entityId ) === -1 ) {
                shape.eDataRef[data.eDataId].push( data.entityId );
            }

            // check if the doc has it
            if ( !changeModel.eData ) {
                changeModel.eData = [ data.eDataId ];
            } else if ( changeModel.eData.indexOf( data.eDataId ) === -1 ) {
                changeModel.eData.push( data.eDataId );
            }
        } else {
            if ( shape.eDataRef && shape.eDataRef[data.eDataId] &&
                    shape.eDataRef[data.eDataId].indexOf( data.entityId ) !== -1 ) {
                        if ( shape.eDataRef[data.eDataId].length <= 1 ) {
                            if ( Object.keys( shape.eDataRef ).length <= 1 ) {
                                delete shape.eDataRef;
                            } else {
                                delete shape.eDataRef[data.eDataId];
                            }
                        } else {
                            const idx = shape.eDataRef[data.eDataId].indexOf( data.entityId );
                            shape.eDataRef[data.eDataId].splice( idx, 1 );
                            // so that sakota likes it
                            shape.eDataRef[ data.eDataId ] = [ ...shape.eDataRef[data.eDataId] ];
                        }
            }
        }
        // return this.commandService.dispatch( DiagramCommandEvent.addEDataRefToShape, {
        //     shapeId: item.id,
        //     eDataId: eDataId,
        //     entityId: entity,
        //     isSet: isSet,
        // });
    }

    /**
     * @param changeModel DiagramModel
     * @param data data to apply
     */
    public syncEDataChanges(
        changeModel: Proxied<DiagramModel>,
        data: {
            shapeIds: string[],
            set: { path: string, value?: any, def?: any }[],
            entityDef: IEntityDef,
        },
    ) {
        const setProps = data.set;
        // if ( !setProps || setProps.length === 0 ) {
        //     return;
        // }
        const shapeIds = data.shapeIds;
        if ( !shapeIds || shapeIds.length === 0 ) {
            return;
        }
        for ( const shapeId of shapeIds ) {
            const shape = changeModel.shapes[shapeId] as ShapeModel;
            if ( !shape ) {
                Logger.warning( `SyncEDataChanges: Missing shape: ${shapeId} in document as per entity` );
                continue;
            }
            if ( !shape.eDataId ) {
                // skip if the shape is not linked to an entity.
                // this happens when a new entity type is created.
                continue;
            }
            if ( !shape.data ) {
                shape.data = {};
                // we need to set the initial values for the shape then.
            }

            const eShapeDef = data.entityDef.shapeDefs[shape.defId];
            let dataMap;
            if ( eShapeDef && eShapeDef.dataMap ) {
                dataMap = eShapeDef.dataMap;
            }
            const shapeMdl = ( changeModel.shapes[shapeId] as ShapeModel );
            for ( const prop of setProps ) {
                if ( prop.path === 'people.people' ) { // partial value update
                    shapeMdl.data.people.value.people = prop.value;
                    continue;
                }

                const targetItem = this.getShapeDataItem( dataMap, prop.path );
                const currVal = get( shape.data, prop.path );

                // if ( shapeMdl.data[ targetItem ] && ( !currVal ||
                //     ( prop.value && currVal && !isEqual( prop.value, currVal.value )))) {
                //     shapeMdl.data[ targetItem ].value = prop.value;
                // }

                if ( shapeMdl.data[ targetItem ] && ( currVal === undefined ||
                    ( prop.value !== undefined && currVal !== undefined &&
                        !isEqual( prop.value, currVal.value )))) {
                    shapeMdl.data[ targetItem ].value = prop.value;
                }
                if ( shapeMdl.data[ targetItem ] === undefined ) { // Should set new data item
                    shapeMdl.data[ targetItem ] = {} as any;
                    shapeMdl.data[ targetItem ].value = prop.value;
                }
                if ( prop.value === undefined ) { // Remove dataitem from shape
                    delete shapeMdl.data[ targetItem ];
                }
                if ( prop.def && prop.def.level === DataItemLevel.DataDef ) {
                    const { level, ...def } = prop.def;
                    changeModel.updateDataDefs( shapeMdl.id, targetItem, def, DataItemLevel.Any );
                }
            }
        }
    }

    /**
     * finds the mapped dataItem id from the entity dataItem name
     * @param dataMap
     * @param propName
     */
    protected getShapeDataItem( dataMap: IEntityDataMap[], propName: string ) {
        let dataItemId = propName;
        if ( dataMap && dataMap.length ) {
            for ( let i = 0; i < dataMap.length; i++ ) {
                if ( dataMap[i].eDataFieldId === propName ) {
                    dataItemId = dataMap[i].dataItemId;
                    break;
                }
            }
        }
        return dataItemId;
    }

    /**
     * @param changeModel DiagramModel
     * @param data data to apply
     */
    public updateShapeDataDefs(
        changeModel: Proxied<DiagramModel>,
        data: {
            [shapeId: string]: {
                [path: string]: any,
            },
        },
        level: DataItemLevel,
    ) {
        for ( const shapeId in data ) {
            const dataItems = data[ shapeId ];
            for ( const path in dataItems ) {
                const dataItem = dataItems[ path ];
                changeModel.updateDataDefs( shapeId, path, dataItem, level );
            }
        }
    }


    public addEntityToShape(
        changeModel: Proxied<DiagramModel>,
        data:  {
            shapeId: string,
            eDataId: string,
            entityId: string,
            entityDefId?: string,
            eDataDefId?: string,
            data?: any, // data items to be copied to the shape
            taskMap?: MapOf<string>, // data items to be copied to the shape
        }) {

            const shape = changeModel.shapes[data.shapeId];
            if ( !shape ) {
                return;
            }
            if ( !( shape as ShapeModel ).eData ) {
                shape.eData = {};
            }
            if ( !shape.eData[data.eDataId] ||
                    shape.eData[data.eDataId] !== data.entityId ) {
                shape.eData[data.eDataId] = data.entityId;
            }

            if ( !shape.dataSetId ) {
                shape.dataSetId = data.entityId;
            }

            // if there is a transaction data in the shape, we drop that
            if (( shape as any ).txn ) {
                delete ( shape as any ).txn;
            }

            // check if the doc has it
            if ( !changeModel.eData ) {
                changeModel.eData = [ data.eDataId ];
            } else if ( changeModel.eData.indexOf( data.eDataId ) === -1 ) {
                changeModel.eData.push( data.eDataId );
            }

            if ( data.entityDefId ) {
                shape.entityDefId = data.entityDefId;
            }

            if ( data.data ) {
                const fieldMap = {};
                if ( data.entityDefId ) {
                    const entityDef = EDataRegistry.instance.getEntityDefById( data.entityDefId, data.eDataDefId );
                    const shapeDef = entityDef?.shapeDefs && entityDef.shapeDefs[shape.defId];
                    if ( shapeDef && shapeDef.dataMap ) {
                        shapeDef.dataMap.forEach( m => {
                            fieldMap[m.eDataFieldId] = m.dataItemId;
                        });
                    }
                }
                if ( !shape.data ) {
                    shape.data = {};
                }
                Object.keys( data.data ).forEach( key => {
                    if ( data.data[ key ]) {
                        const targetItem = fieldMap[key] || key;

                        this.correctDescDataitems( data.data[ key ], shape.id );

                        shape.data[ targetItem ] = { value: data.data[ key ].value } as any;
                        // if level is not defined, it means upstream,
                        const level = data.data[ key ].level !== undefined ?
                            data.data[ key ].level : DataItemLevel.CustomDef;
                        if ( level !== DataItemLevel.Def ) {
                            changeModel.updateDataDefs( shape.id, targetItem, data.data[ key ], level );
                        }
                    }
                });
            }

            if ( data.taskMap && Object.keys( data.taskMap ).length > 0 ) {
                shape.taskMap = { ...data.taskMap };
            }
    }

    /**
     * If the Description data item has other dataitems, the shapeId attribute should be
     * replaced with the actual shapeid, the exsiting shapeid comes from the shape
     * where the type is created.
     */
    private correctDescDataitems( dataItem, shapeId ) {
        if ( dataItem?.id === DESCRIPTION_DATAITEM_ID ) {
            const div = document.createElement( 'div' );
            div.innerHTML = dataItem.value;
            div.querySelectorAll( 'data-item-node[shapeid]' ).forEach( el => el.setAttribute( 'shapeid', shapeId ));
            dataItem.value =  div.innerHTML;
        }
    }

}
