import { ShapeServices } from '../framework/shape-service';
import { ILogicDefinition } from 'flux-definition';

/**
 * This Factory creates Logic Class
 * @author mehdhi
 * @since 08/22/2019
 */
export class LogicClassFactory {
    /**
     * The singleton instance of the LogicClassFactory.
     */
    public static get instance(): LogicClassFactory {
        if ( !this._instance ) {
            this._instance = new LogicClassFactory();
        }
        return this._instance;
    }

    protected static _instance: LogicClassFactory;

    /**
     * Creates an instant of Logic Class using the type given
     */
    public create( classType: any ): ILogicDefinition {
        const instance: ILogicDefinition = new classType();
        // Setting shape sevices so it can be used shape logic classes
        instance.services = ShapeServices.instance;
        return instance;
    }
}
