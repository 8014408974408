import { AbstractCommand, CommandInterfaces, Command, StateService } from 'flux-core';
import { Observable, merge } from 'rxjs';
import { ModelSubscriptionManager } from 'flux-subscription';
import { Inject, Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { take, filter, switchMap } from 'rxjs/operators';
import { DiagramModel } from '../model/diagram.mdl';
import { UserInfoSub, UserPermInfoSub } from 'flux-user';
import { CollabModel, CollaboratorType } from 'flux-diagram';

/**
 * Command for starting diagram collab subscriptions.
 * Starts user info subscriptions for each collaborator of the diagram.
 *
 * @author  Ramishka
 * @since   2018-04-03
 */
@Injectable()
@Command()
export class StartDiagramCollabSubscriptions extends AbstractCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }

    constructor( protected modelSubManager: ModelSubscriptionManager,
                 protected dataStore: DataStore,
                 @Inject( StateService ) protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute (): Observable<any> {
        return this.dataStore.findOneRaw( DiagramModel, { id: this.resourceId }).pipe(
            take( 1 ),
            filter( data => !!( data && data.collabs && data.collabs.length > 0 )),
            switchMap( data =>
                merge(
                    ...data.collabs.map(( collab: CollabModel ) => {
                        // Start a UserPermInfo subscription for the document owner if it's not the
                        // current user, and UserInfoSub for everyone else
                        if ( collab.role === CollaboratorType.OWNER && collab.id !== this.state.get( 'CurrentUser' )) {
                            return this.modelSubManager.start( UserPermInfoSub, collab.id );
                        }
                        return this.modelSubManager.start( UserInfoSub, collab.id );
                    }),
                ),
            ),
        );
    }
}

Object.defineProperty( StartDiagramCollabSubscriptions, 'name', {
    value: 'StartDiagramCollabSubscriptions',
});
