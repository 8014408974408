import { Component, ChangeDetectionStrategy } from '@angular/core';


/**
 * Added temporarily as a placeholder for the redirect. If a outlet component
 * is added this can be removed OR if we find purpose for this we can keep it.
 *
 * @author Vinoch
 * @since 2020-02-20
 */

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RedirectRoutePlaceHolder {}
