import { ITextParser } from 'flux-definition';
import UMLClassStereoTypeSectionParser from './uml-class-stereotype-section-text-parser';

class UMLClassHeaderSectionParser implements ITextParser<{value: string, type?: string}> {
    static id = 'creately.umlclass.header';

    public static get instance(): UMLClassHeaderSectionParser {
        return this._instance;
    }

    private static _instance = new UMLClassHeaderSectionParser();

    parse( input: string ) {
        const trimmed = input.trim();
        const parseErrors = [];
        if ( trimmed.substr( 0, 2 ) === '<<' ) { // stereotype
            if ( trimmed.substr( -2 ) !== '>>' ) {
                parseErrors.push( 'stereotype declaration should end with ">>"' );
            }
            const parsedText = trimmed.replace( /[<>]/gm, '' );
            return {
                ast: {
                    type: 'stereo',
                    value: parseErrors.length > 0 ? '' : parsedText.trim(),
                },
                lexResult: {},
                parseErrors: parseErrors,
            };
        }
        if ( /^[a-zA-Z]\w*$/.test( input )) {
            return {
                ast: {
                    type: 'name',
                    value: input,
                },
                lexResult: {},
                parseErrors: [],
            };
        }
        return {
            ast: {
                value: input,
            },
            lexResult: {},
            parseErrors: [ 'neither a class name nor a stereotype' ],
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        return UMLClassStereoTypeSectionParser.instance.getContentAssistSuggestions( text, context );
    }
}

export default UMLClassHeaderSectionParser;
