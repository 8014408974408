import { Injectable, EventEmitter, NgZone, Inject } from '@angular/core';
import { Rectangle } from '../../geometry/rectangle';

/**
 * Values for the possible screen Orientations.
 */
export enum ScreenOrientation {
    Unknown,
    Portrait,
    PortraitUp, // Up side down
    LandscapeLeft, // Tilted Anti-Clock-wise
    LandscapeRight, // Tilted Clock-wise
}

/**
 * DeviceScreen
 * Provides details about the current device's screen.
 *
 * @author hiraash
 * @since 2016-06-19
 */

@Injectable()
export class DeviceScreen {

    /**
     * The device screen width when the device is in
     * portrait mode. This is set during first use of
     * the DeviceScreen service
     */
    protected _width: number;

    /**
     * The device screen height when the device is in
     * portrait mode. This is set during first use of
     * the DeviceScreen service
     */
    protected _height: number;

    /**
     * This is the event emitter that fires when
     * the screen sizes change.
     */
    protected resizeEventEmitter: EventEmitter<any>;

    protected webWindow: Window;

    /**
     * This is the event emitter that fires when
     * the screen orientation changes.
     */
    protected orientationEventEmitter: EventEmitter<ScreenOrientation>;

    constructor( @Inject( 'BrowserWindow' ) webWindow: any, private zone: NgZone ) {
        this.webWindow = webWindow;
        this.orientationEventEmitter = new EventEmitter<ScreenOrientation>();
        this.resizeEventEmitter = new EventEmitter<any>();
        this.webWindow.addEventListener( 'orientationchange', e => this.fireOrientationChange( e ));
        this.webWindow.addEventListener( 'resize', e => this.handleResizeEvent());

        this.recordDimentions();
    }

    /**
     * Returns the full width of the screen
     * in pixels
     */
    public get width(): number {
        if ( this.isLandscape ) {
            return this._height;
        } else {
            return this._width;
        }
    }

    /**
     * Returns the full height of the screen
     * in pixels
     */
    public get height(): number {
        if ( this.isLandscape ) {
            return this._width;
        } else {
            return this._height;
        }
    }

    /**
     * Returns the current bounds of the screen
     * as a Rectangle object.
     */
    public get bounds(): Rectangle {
        return new Rectangle( 0, 0, this.width, this.height );
    }

    /**
     * Returns the current screen orientation by the
     * ScreenOrientation value.
     */
    public get orientation(): ScreenOrientation {
        const orientation = this.getValidOrientation();
        if ( orientation === 90 ) {
            return ScreenOrientation.LandscapeRight;
        }
        if ( orientation === -90 ) {
            return ScreenOrientation.LandscapeLeft;
        }
        if ( orientation === 0 ) {
            return ScreenOrientation.Portrait;
        }
        if ( orientation === 180 ) {
            return ScreenOrientation.PortraitUp;
        }
        return ScreenOrientation.Unknown;
    }

    /**
     * Indicates if the device is currently in
     * landscape orientation
     */
    public get isLandscape(): boolean {
        const o = this.getValidOrientation();
        return o === 90 || o === -90;
    }

    /**
     * Indicates if the device is currently in
     * portrait orientation
     */
    public get isPortrait(): boolean {
        const o = this.getValidOrientation();
        return o === 0 || o === 180;
    }

    /**
     * The event emtter that fires when the screen orientation
     * changes.
     */
    public get orientationChange(): EventEmitter<ScreenOrientation> {
        return this.orientationEventEmitter;
    }

    /**
     * The event emtter that fires when the screen resizes.
     */
    public get resize(): EventEmitter<any> {
        return this.resizeEventEmitter;
    }

    /**
     * Records the inital width and height of the screen
     * and uses them throughout the lifetime of the app.
     */
    protected recordDimentions() {
        if ( this.isLandscape ) {
            this._width = this.webWindow.innerHeight;
            this._height = this.webWindow.innerWidth;
        } else {
            this._width = this.webWindow.innerWidth;
            this._height = this.webWindow.innerHeight;
        }
    }

    /**
     * This method will fire an orientation change
     * event to whoever is subscribed to the event emitter.
     * This is a handler for the window's orientationchange event
     */
    protected fireOrientationChange( e: Event ) {
        this.zone.run(() => this.orientationEventEmitter.emit( this.orientation ));
    }

    /**
     * Method returns the orientation in one of the angle values
     * 0, 90, 180, -90. If the orientation was not retrieved successfully
     * it will return -1. This method tries mutlple API's to get the
     * orientation angle to ensure it works on devices and browsers.
     */
    protected getValidOrientation(): number {
        // Check if window.orientation exists. Should work on devices OS's.
        let orientation: any = this.webWindow.orientation as number;
        if ( Number.isInteger( orientation )) {
            return orientation;
        }

        // Else check on the screen property. Should exist in browsers.
        orientation = ( <any> this.webWindow.screen ).orientation;
        if ( orientation && orientation.angle !== undefined ) {
            orientation = orientation.angle;
        }
        if ( Number.isInteger( orientation )) {
            return orientation;
        }

        return -1;
    }

    /**
     * This method will fire an resize event to whoever is subscribed to
     * the event emitter. This is a handler for the window's orientationchange event
     */
    protected fireResizeEvent() {
        this.zone.run(() => this.resizeEventEmitter.emit());
    }

    /**
     * This method will fire an resize event
     * event to whoever is subscribed to the event emitter.
     * This is a handler for the window's resize event
     */
    protected handleResizeEvent() {
        this.recordDimentions();
        this.fireResizeEvent();
    }
}
