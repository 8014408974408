import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import { ICanvasInstructionFactory, ICanvasInstruction } from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';
import { PathCanvasInstructionFactory } from './path-canvas-instruction-factory';

/**
 * class CircleCanvasInstructionFactory
 * Generate canvas instruction for svg circle element instructions
 */
export class CircleCanvasInstructionFactory
    extends AbstractCanvasInstructionFactory implements ICanvasInstructionFactory {

    public svgElementName: string = 'circle';

    /**
     * Return circle canvas instruction for given circle shape node
     * @param data
     */
    createInstruction( data: IShapeNode ): ICanvasInstruction[] {
        const cx = data.data.cx ? +data.data.cx : 0;
        const cy = data.data.cy ? +data.data.cy : 0;
        const r = +data.data.r;

        const circlePath = this.circleToPath( cx, cy, r );
        const pathFactory = new PathCanvasInstructionFactory();
        data.data.d = circlePath;
        return pathFactory.createInstruction( data );
    }

}
