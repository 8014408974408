import { Injectable } from '@angular/core';
import { CommandInterfaces, Command, StateService } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { of, Observable } from 'rxjs';
import { DataStore } from 'flux-store';
import { map, take } from 'rxjs/operators';
import { DiagramInfoModel } from '../model/diagram-info.mdl';

/**
 * This is the command to get a list of recent diagrams for a logged in user.
 * Responds with a list of {@link DiagramInfoModel}
 * objects with the necessary data.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetRecentDiagrams extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected stateService: StateService<any, any>,
                 protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 3;
    }

    public execute (): boolean {
       return true;
    }

    public executeResult( response: any ): Observable<any> {
        this.stateService.set( 'RecentDiagrams', response.recentDiagrams );
        return of( true );
    }

    /**
     * Retirieve diagrams from cache and sort with lastUpdated time.
     */
    public executeNetworkOffline(): Observable<any> {
        return this.dataStore.getModelStore( DiagramInfoModel ).find({}).pipe(
            take( 1 ),
            map(( diagrams: any ) => {
                diagrams = diagrams.sort(( diag1, diag2 ) => diag2.lastUpdated - diag1.lastUpdated );
                return { recentDiagrams: diagrams };
            }),
        );
    }
}

Object.defineProperty( GetRecentDiagrams, 'name', {
    value: 'GetRecentDiagrams',
});
