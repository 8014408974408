<div class="tags-input-container">
    <label class="body">{{label}}</label>

    <div>
        <tag-item class="tag-item" *ngFor="let tagItem of ( _items | async )" [text]="tagItem" [readonly]="readonly" (deleteTags)="deleteTag($event)"></tag-item>
        
        <input type="text" #inputElem
        class="text-input"
        *ngIf="!readonly"
        placeholder="{{ placeholder }}" 
        (blur)="emitTags()" 
        (keyup)="createTag($event)"/>
    </div>
</div>