import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { ImageDataURL } from '../import/image-data-url';
import { tap } from 'rxjs/operators';

/**
 * This command embeds an and imported image into a supporting shape.
 */
@Injectable()
@Command()
export class AddShapeImage extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        position: { shapeId: string, imageId: string },
        imageFile: { hash: string, data: string },
    };

    /**
     * Prepare command data
     */
    public prepareData() {
        const shape = this.changeModel.shapes[ this.data.position.shapeId ] as ShapeModel;
        const image = shape.images[ this.data.position.imageId ];
        image.file = this.data.imageFile.hash;
        if ( this.data.imageFile.data ) {
            const dataUrl = this.createImageDataURL( this.data.imageFile.data );
            return dataUrl.getBounds().pipe(
                tap( size => {
                    image.fileBound = { width: size.width, height: size.height };
                }),
            );
        }
    }
    /**
     * This function creates an instance of ImageDataURL
     * @param dataURL Image data in DataURL format
     */
    protected createImageDataURL( dataURL: string ) {
        return new ImageDataURL( dataURL );
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AddShapeImage, 'name', {
    value: 'AddShapeImage',
});
