import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommandInterfaces, Command } from 'flux-core';
import { Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';

/**
 * This is the command which changes the privacy of the project and send the
 * request to the server
 *
 * data: {
 *     privacy { level, role }
 * }
 */
@Injectable()
@Command()
export class ChangeProjectPrivacy extends AbstractMessageCommand  {

    public static get dataDefinition(): {}  {
        return {
            privacy: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.getPrivacy( this.resourceId ).pipe(
            tap( privacy => this.previousData.privacy = privacy ),
        );
    }

    /**
     * execute
     * change project privacy
     */
    public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return of( this.previousData.privacy ).pipe(
            map( privacy => {
                privacy.role = this.data.privacy.role;
                privacy.level = this.data.privacy.level;
                return privacy;
            }),
            switchMap( privacy => modelStore.storgaeUpdatePrivacy( this.resourceId, privacy )),
        );
    }

    /**
     * executeResult
     * Update the privacy deatils with the response
     */
    public executeResult( response: any ): Observable<any> {
           const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
           return modelStore
               .storgaeUpdatePrivacy( this.resourceId, response.privacy );
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore ;
        return modelStore.storgaeUpdatePrivacy( this.resourceId, this.previousData.privacy );
    }

}

Object.defineProperty( ChangeProjectPrivacy, 'name', {
    value: 'ChangeProjectPrivacy',
});
