import { Injectable } from '@angular/core';
import { StateService } from 'flux-core';
import { map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DataStore } from 'flux-store';
import { isEqual } from 'lodash';
import { UserLocator } from 'flux-user';
import { combineLatest, Observable, empty } from 'rxjs';
import { ProjectLocator } from './project-locator';
import { CollabModel } from '../../collab/model/collaborator.mdl';

/**
 * This is a locator that can be used to fetch information about a projects
 * collabotarors
 *
 * @author  Ramishka
 * @since   2019-06-22
 */
@Injectable()
export class ProjectCollabLocator {

    constructor(
        protected locator: ProjectLocator,
        protected state: StateService<any, any>,
        protected dataStore: DataStore,
        protected userLocator: UserLocator,
    ) {}

    /**
     * Returns an observable which emits collaborator data for a given
     * project. This observable will continuously emit as collaborators are
     * added and removed to the project.
     * The collab models emitted by this method will have complete information.
     * The array is sorted according to the roles, with collabs with highest
     * priviliges (owner) at the front.
     * @param projectId - Project id to fetch collaborators for.
     */
    public getCollabs( projectId: string ): Observable<CollabModel[]> {
        return this.locator.getProject( projectId ).pipe(
            map( project => project.collabs  || []),
            distinctUntilChanged( isEqual ),
            switchMap( collabs => {
                const observables = collabs.map( collab => (
                    this.userLocator.getUserInfo( collab.id ).pipe(
                        map( user => Object.assign( new CollabModel( collab.id ), user, collab )),
                    )));
                if ( observables.length > 0 ) {
                    return combineLatest( ...observables );
                }
                return empty();
            }),
            map(( collabs: any ) => this.sortCollabs( collabs )),
        );
    }

    /**
     * Sorts a collaborator list based on the role.
     * Highest priviliged role is at the front.
     * Online status is ignored.
     */
    protected sortCollabs( collabs: CollabModel[]): CollabModel[] {
        return collabs.slice().sort(( c1, c2 ) =>
            c1.roleSortOrderWithoutOnline - c2.roleSortOrderWithoutOnline );
    }
}
