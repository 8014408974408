import { find } from 'lodash';
import { AbstractModel, ComplexType, DateFNS, enumerable } from 'flux-core';
import { UserInfoModel } from 'flux-user';
import { CollaboratorType, CollabModel } from '../collab/model/collaborator.mdl';
import { Relationship } from 'flux-store';
import { PrivacyModel } from './privacy.mdl';
import { TeamShareModel } from './team-share.mdl';
import { GroupShareModel } from '../group-share/model/group-share.mdl';

/**
 * ResourceModel
 * Model for the general Resource in Creately.
 *
 * A resource is a diagram or a project or anything else
 * that shares a common set of features. Will contain common
 * functionality for models that have general information and
 * collaboraors.
 *
 * @deprecated DEPRECATED FIELDS: Note that there are fields in this model that
 * is part of old Creately system and they will not be available and
 * used in new systems. Be careful about using them in the correct
 * environment.
 *
 * @author  hiraash
 * @since   2015-11-07
 */
export class ResourceModel extends AbstractModel {
     /**
      * isAbstract returns whether the model type is an abstract type or
      * a concrete model type. Example: ResourceModel is an abstract type.
      */
    public static isAbstractType(): boolean {
        return this === ResourceModel;
    }

    /**
     * updateProcessedLastUpdatedTime creates the moment.js version of lastUpdated time.
     * This is cached in order to reduce the CPU used to calculate them repeatedly
     * @param docOrModel The ResourceModel or the js object of a resource model
     */
    protected static updateProcessedLastUpdatedTime( docOrModel: any ) {
        if ( !docOrModel || !docOrModel.lastUpdated ) {
            return;
        }
        const lastUpdatedTime = new Date( docOrModel.lastUpdated ).getTime();
        if ( docOrModel._lastUpdatedMomentString && lastUpdatedTime === docOrModel._lastUpdatedMomentTime ) {
            return;
        }
        docOrModel._lastUpdatedMomentTime = lastUpdatedTime;
        docOrModel._lastUpdatedMomentString = DateFNS.formatDistance( docOrModel.lastUpdated, new Date().getTime());
    }

    /**
     * Name of the instance of the model.
     */
    public name: string;

    /**
     * The last updated date/time of the model.
     */
    public lastUpdated: Date;

    /**
     * This holds the data about the updated user
     * of the diagram
     */
    // @ComplexType()
    // @Relationship( UserInfoModel )
    // public updatedBy: UserInfoModel;

    // TODO updatedBy should be a UserInfoModel as described above. This will be
    // handled as string Until the DiagramLocator refactored. Please remove this
    // string property as soon as relationship in DiagramLocator starts working.
    public updatedBy: string;

    /**
     * The created time of the model.
     */
    public createdTime: Date;


    /**
     * is this item archived
     */
     public archived: boolean;


    /**
     * Whether the resource is already shared with team or not
     * @deprecated DEPRECATED FIELD
     */
    public sharedWithTeam: boolean;

    /**
     * An array collaborators of this model in {@link CollabModel}
     * instances.
     */
    @ComplexType( CollabModel )
    @Relationship( UserInfoModel, CollabModel )
    public collabs: CollabModel[];

    /**
     * Privacy details of this model in instances
     */
    @ComplexType( PrivacyModel )
    public privacy: PrivacyModel;

    @ComplexType( TeamShareModel )
    public teamShare: TeamShareModel[];

    @ComplexType( GroupShareModel )
    public groupShare: GroupShareModel[];

    /**
     * The date lastUpdatedMoment was updated last and the processed string.
     * This is done to reduce calls to momentjs process which takes significant
     * amount of CPU when there are a large number of elements in the list.
     */
    protected _lastUpdatedMomentTime: number;
    protected _lastUpdatedMomentString: string;

    constructor( id: string, name: string, extension?: Object ) {
        super( id, extension );
        this.name = name;
    }

    /**
     * This getter returns the user instance representing the owner of this model
     * {@see UserInfoModel}
     */
    @enumerable( true )
    public get owner (): UserInfoModel {
        return find( this.collabs, { role: CollaboratorType.OWNER });
    }

    /**
     * Indicates if this model has any collaborators.
     * In addition to the owner of the diagram
     */
    public hasCollabs(): boolean {
        return this.collabs && this.collabs.length > 1;
    }

    /**
     * Returns the number of editors and reviewers this model has.
     */
    public numberOfCollabs(): number {
        return this.hasCollabs() ? this.collabs.length - 1 : 0;
    }

    /**
     * Returns the number of editors this model has.
     */
    public numberOfEditors(): number {
        return this.collabs ? this.collabs.filter( collab => collab.role === CollaboratorType.EDITOR ).length : 0;
    }

    /**
     * Returns the number of reviewers this model has.
     */
    public numberOfReviewers(): number {
        return this.collabs ? this.collabs.filter( collab => collab.role === CollaboratorType.REVIEWER ).length : 0;
    }

    /**
     * Returns the last updated time in momentJS style
     */
    public lastUpdatedMoment(): string {
        ResourceModel.updateProcessedLastUpdatedTime( this );
        return this._lastUpdatedMomentString || '';
    }
}

Object.defineProperty( ResourceModel, 'name', {
  writable: true,
  value: 'ResourceModel',
});
