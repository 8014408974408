import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    AppConfig,
    ModalController,
    PopupWindow,
    PostMessageAPI,
} from 'flux-core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

enum Setup2FAReceiveEvent {
    Close = 'phoenix:setup2FAClose',
    Done = 'phoenix:setup2FASuccess',
    Progress = 'phoenix:setup2FACurrentStep',
}

/**
 * Setup 2FA window.
 *
 * @author  Damith
 * @since   2023-12-07
 */
@Component({
    templateUrl: 'setup-2fa-window.cmp.html',
    selector: 'setup-2fa',
    styleUrls: [ './setup-2fa.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Setup2FAWindow extends PopupWindow implements OnInit, OnDestroy {
    @Input() public allowClose: boolean = true;
    @Input() public currentStep: string = 'step1';
    public _currentStep: Subject<string>;
    public iframeUrl: string = '';

    /**
     * The window overlay.
     */
    @ViewChild( 'container' ) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'window' ) protected containerInner;

    private subs: Array<Subscription> = [];

    constructor(
        protected modalController: ModalController,
        public postMessageSvc: PostMessageAPI,
    ) {
        super()/* istanbul ignore next */;
    }

    ngOnInit(): void {
        const siteUrl = AppConfig.get( 'SITE_URL' );
        this.iframeUrl = siteUrl + '2fa-setup/';
        if ( !this.allowClose ) {
            this.iframeUrl += '?disableClose=true';
            if ( this.currentStep === 'step2' ) {
                this.iframeUrl += '&step=2';
            }
        } else if ( this.currentStep === 'step2' ) {
            this.iframeUrl += '?step=2';
        }
        this.subs.push(
            this.postMessageSvc.recv().subscribe( message => this.handleIncomingMessages( message )),
        );
        this._currentStep = new BehaviorSubject<string>( this.currentStep );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Closes the window after animation is complete.
     */
    public closeWindow() {
        if ( this.container && this.containerInner ) {
            this.hideWindow( this.container, this.containerInner ).subscribe({
                complete: () => {
                    this.modalController.hide();
                },
            });
        }
    }

    protected handleIncomingMessages( message ) {
        if ( !message || typeof message !== 'object' ) {
            return;
        }
        const { event, data } = message as any;
        if ( event === Setup2FAReceiveEvent.Close || event === Setup2FAReceiveEvent.Done ) {
            this.closeWindow();
        } else if ( event === Setup2FAReceiveEvent.Progress ) {
            this._currentStep.next( data.step );
        }
    }

}
