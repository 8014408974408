import { MouseEvent } from '@creately/createjs-module';
import { RightClickEvent, RightClickEventType } from './right-click-event';

/**
 * The event is used for notifiying right click events on nucleus shapes ( Shape or Connector )
 * within the canvas.
 */
export class ShapeRightClickEvent extends RightClickEvent {
    /* istanbul ignore next */
    constructor( type: RightClickEventType, event: MouseEvent, bubbles = true, cancellable = true ) {
        super( type, event, bubbles, cancellable );
        this.setRelatedShape( event );
    }
}

