import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataType, IDataItemDef, SystemType } from 'flux-definition';
import { omit, mapValues } from 'lodash';

const dataItemsMap = {
    number: {
        id: 'number',
        type: DataType.NUMBER,
        visibility: [
            { type: 'editor' },
            {
                type: 'shape-ui',
                bindToData: true,
                renderContext: [ 'Card' ],
            },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.NUMBER.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'number',
    },
    string: {
        id: 'string',
        type: DataType.STRING,
        visibility: [
            { type: 'editor' },
            {
                type: 'shape-ui',
                bindToData: true,
                renderContext: [ 'Card' ],
            },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.STRING.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'text',
    },
    checkbox: {
        id: 'checkbox',
        type: DataType.BINARY,
        visibility: [
            { type: 'editor' },
            {
                type: 'shape-ui',
                bindToData: true,
                renderContext: [ 'Card' ],
            },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.CHECKBOX.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'checkbox',
    },
    singleSelectCombo: {
        id: 'singleSelectCombo',
        type: DataType.OPTION_LIST,
        visibility: [
            { type: 'editor' },
        ],
        value: '',

        labelEditable: true,
        optional: true,
        validationRules: {},
        label: 'SHAPE_DATA.DATA_ITEMS.SINGLE_SELECT_COMBO.LABEL',
        trackingId: 'dropdown',
    },
    entityLookup: {
        id: 'entityLookup',
        type: DataType.LOOKUP,
        visibility: [
            { type: 'editor' },
        ],
        value: '',
        labelEditable: true,
        optional: true,
        validationRules: {},
        label: 'SHAPE_DATA.DATA_ITEMS.ENTITY_LOOKUP.LABEL',
        trackingId: 'entity-lookup',
    },
    tags: {
        id: 'tags',
        type: DataType.TAGS,
        visibility: [
            { type: 'editor' },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.TAGS.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'tags',
    },
    date: {
        id: 'date',
        type: DataType.DATE,
        visibility: [
            { type: 'editor' },
            {
                type: 'shape-ui',
                bindToData: true,
                renderContext: [ 'Card' ],
            },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.DATE.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'date',
    },
    formula: {
        id: 'formula',
        type: DataType.FORMULA,
        visibility: [
            { type: 'editor' },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.FORMULA_FIELD.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        trackingId: 'formula',
    },
};

const roleItemsMap = {
    role: {
        id: 'role',
        type: DataType.USERS,
        systemType: SystemType.Role,
        visibility: [
            { type: 'editor' },
        ],
        value: {
            source: {
                id: 'collabs',
                name: 'Role',
            },
            people: [],
        },
        label: 'SHAPE_DATA.DATA_ITEMS.ROLE.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        roleBound: true,
    },
    estimate: {
        id: 'estimate',
        type: DataType.NUMBER,
        systemType: SystemType.EstimatePts,
        visibility: [
            { type: 'editor' },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.ESTIMATE.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        roleBound: true,
    },
    dueDate: {
        id: 'dueDate',
        type: DataType.DATE,
        systemType: SystemType.DueDate,
        visibility: [
            { type: 'editor' },
            {
                type: 'shape-ui',
                bindToData: true,
                bindTextId: 'endDate',
                renderContext: [ 'Card' ],
            },
            {
                type: 'shape-ui',
                bindToData: false,
                bindTextId: 'endDate',
                renderContext: [ 'XWithWidth' ],
            },
        ],
        value: '',
        label: 'SHAPE_DATA.DATA_ITEMS.DUE_DATE.LABEL',
        labelEditable: true,
        optional: true,
        validationRules: {},
        roleBound: true,
    },
};

interface ICoreDataDefs {
    tags: IDataItemDef<DataType.TAGS>;
    priority: IDataItemDef<DataType.NUMBER>;
    dueDate: IDataItemDef<DataType.DATE>;
    estimate: IDataItemDef<DataType.NUMBER>;
    owner: IDataItemDef<DataType.USERS>;
}

const coreDataDefs: {
    [dataDefId: string]: IDataItemDef<DataType>,
} = {};

coreDataDefs.dueDate = omit( roleItemsMap.dueDate, [ 'value' ]);
coreDataDefs.estimate = omit( roleItemsMap.estimate, [ 'value' ]);
coreDataDefs.owner = omit( roleItemsMap.role, [ 'value' ]);
coreDataDefs.owner.id = 'owner';
coreDataDefs.owner.label = 'SHAPE_DATA.DATA_ITEMS.OWNER.LABEL';
coreDataDefs.owner.roleBound = true;

coreDataDefs.tags = omit( dataItemsMap.tags, [ 'value' ]);
coreDataDefs.tags.showInNotes = true;
coreDataDefs.priority = omit( dataItemsMap.number, [ 'value' ]);
coreDataDefs.priority.id = 'priority';
coreDataDefs.priority.systemType = SystemType.Priority;
coreDataDefs.priority.label = 'SHAPE_DATA.DATA_ITEMS.PRIORITY.LABEL';
coreDataDefs.priority.showInNotes = true;

for ( const dataDefId in coreDataDefs ) {
    coreDataDefs[dataDefId].labelEditable = false;
    coreDataDefs[dataDefId].isPublic = true;
}

export {
    dataItemsMap,
    roleItemsMap,
    coreDataDefs,
    ICoreDataDefs,
};

@Injectable()
export class CoreDataFieldService {
    public static get instance(): CoreDataFieldService {
        return this._instance;
    }

    public static getCoreDataDefs(): ICoreDataDefs {
        if ( !this.compiledDefs ) {
            this.compiledDefs = mapValues( coreDataDefs, def => ({
                ...def,
                label: this.instance.translate.instant( def.label ),
            }));
        }
        return this.compiledDefs;
    }

    /**
     * This methods returns the default value for given core field
     * @param dataDefId
     * @returns string|any
     */
    public static getDefaultValue( dataDefId: string ): any {
        return '';
    }

    private static _instance = null;
    private static compiledDefs: ICoreDataDefs = null;
    constructor( private translate: TranslateService ) {
        CoreDataFieldService._instance = this;
    }
}
