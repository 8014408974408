import { timer, fromEvent, of, race, Observable } from 'rxjs';
import { tap, delay, switchMap, filter, take } from 'rxjs/operators';
import { forEach } from 'lodash';
import { Flags } from '../flags';

/**
 * This class defines and applies a transition to the
 * given HTML element.
 *
 * @author  gobiga
 * @since   2017-08-09
 */
export class Animation {

    /**
     * The element where the transition is applied to
     */
    protected _element: HTMLElement;

    constructor( protected options: ITransitionOptions ) {}

    /**
     * Set the element where the transition is applied to.
     */
    public set element( val: HTMLElement ) {
        this._element = val;
        this.setAnimationStyles( this.options.from );
    }

    /**
     * Applies the transition to the given element
     */
    public start(): Observable<any> {
        if ( !Flags.get( 'ENABLE_APP_ANIMATIONS' )) {
            this.setAnimationStyles( this.options.to );
            return of( null );
        }
        const filterFn = event => event.target === this._element;
        return of( this.options ).pipe(
            delay( 0 ),
            tap( options =>  {
                this.setAnimationStyles( options.to );
                this.setTransitionOptions( options );
            }),
            switchMap(() => race(
                fromEvent( this._element, 'transitionend' ).pipe( filter( filterFn  )),
                fromEvent( this._element, 'transitioncancel' ).pipe( filter( filterFn )),
                timer( this.options.duration + 100 ))),
            take( 1 ));
    }

    /**
     * Sets the starting or ending animation styles to
     * the element.
     * @param styles JSON object with the styles
     */
    protected setAnimationStyles( styles: { [styleName: string]: string | number }) {
        if ( styles ) {
            forEach( styles , ( style, styleName ) => {
                this._element.style[styleName] = style;
            });
        }
    }

    /**
     * Sets transition options to the element.
     * @param options - JSON object containing transition option values
     */
    protected setTransitionOptions( options: ITransitionOptions ) {
        this._element.style.transitionProperty = options.transitionProperty;
        this._element.style.transitionDuration = options.duration + 'ms';
        if ( options.easing ) {
            this._element.style.transitionTimingFunction = options.easing;
        }
    }

}

/**
 * Options that can be set to apply the transition
 * This must be an object that conforms to JSON standards.
 */
export interface ITransitionOptions {

    /**
     * Specifies the starting styles.
     * This must be a JSON object containing a field for each
     * style.
     * Style names when used as a key must use camel case  instead
     * of hyphen to seperate words so that they conform to JSON standards.
     * Example - max-height style would be defined as maxHeight.
     * <code>
     *   from: {
     *      opacity: 0,
     *      transform: 'translateY(0px)',
     *      maxHeight: 100px,
     *   }
     * </code>
     */
    from?: { [styleName: string]: string | number };

    /**
     * Specifies the end styles.
     * This must be a JSON object containing a field for each
     * style.
     * Style names when used as a key must use camel case instead
     * of hyphen to seperate words so that they conform to JSON standards.
     * Example - max-height style would be defined as maxHeight.
     * <code>
     *   from: {
     *      opacity: 0,
     *      transform: 'translateY(0px)',
     *      maxHeight: 100px,
     *   }
     * </code>
     */
    to: { [styleName: string]: string | number };

    /**
     * The name of the CSS property the transition effect is for.
     * If there are multiple properties being transitioned, they can
     * be added as a comma seperated list.
     * Example:
     *  transitionProperty: 'opacity'
     * OR
     *  transitionProperty: 'opacity, transform',...
     */
    transitionProperty: string;

    /**
     * Time that takes the transition effect to complete.
     * This value must be in milliseconds.
     */
    duration: number;

    /**
     * Specifies the speed curve of the transition effect
     */
    easing?: string;
}
