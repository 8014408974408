import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * Data item class for the OPTION_LIST_COMBO data type. Data items in shape models with the OPTION_LIST_COMBO
 * data type will be deserialized into instances of this class by the `DataItemFactory`.
 */
export class OptionListComboDataItem extends AbstractDataItem<DataType.OPTION_LIST_COMBO> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof OptionListComboDataItem.prototype.value ): IValidationError {
        return null;
    }
}
