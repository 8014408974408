import { Directive, OnInit, OnDestroy } from '@angular/core';
import { ToolTip } from '../tooltip/tooltip.dir';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Directive({
    selector: '[floatingContainer]',
})
export class FloatingContainer extends ToolTip implements OnInit, OnDestroy {
    private clickSubscription: Subscription;

    public ngOnInit() {
        if ( this.element ) {
            const content = this.element.querySelector( '.floating-content' );

            // Remove tooltip content span if deactivated
            if ( content && this.deactivated ) {
                this.element.querySelector( '.floating-content' ).remove();
                return;
            }

            if ( content ) {
                this.element.querySelector( '.floating-content' ).remove();
                this.createTooltip( content );
                if ( this.openOnHover === true ) {
                    this.mouseEnter.subscribe( event => this.show());
                    this.mouseExit.subscribe( event => this.hide());
                }
            }

            // Remove the floating container when clicked out of the container
            this.clickSubscription = fromEvent<MouseEvent>( document, 'click' ).pipe(
                filter( event => {
                  const clickInsideElement = content.contains( event.target as Node );
                  return !clickInsideElement;
                }),
            ).subscribe(() => {
                this.hide();
            });
        }
    }

    ngOnDestroy(): void {
        if ( this.clickSubscription ) {
            this.clickSubscription.unsubscribe();
        }
    }

    /**
     * This function arranges the tooltip elements when the placement
     * is set to the right.
     * @param elementBounds - bounds of the host elements
     * @param baseHeight - height of the tooltip base
     * @param pointerWidth - width of the pointer arrow
     */
    protected positionRight( elementBounds: ClientRect, baseHeight: number, pointerWidth: number ) {
        this.pointer.style.top = elementBounds.top + ( this.element.offsetHeight - pointerWidth ) / 4 + 'px';
        this.pointer.style.left = elementBounds.right + 'px';
        this.base.style.left = elementBounds.right + pointerWidth + 'px';
        const startY: number = elementBounds.top + ( this.element.offsetHeight - baseHeight ) / 4;
        this.base.style.top = startY - this.getYOffset( startY, baseHeight ) + 'px';
    }

    /**
     * This function creates the tooltip elements and
     * adds the relevant css classes to them
     */
    protected createTooltip( content ) {
        this.base = document.createElement( 'div' );
        this.base.className += 'floating';
        this.base.appendChild( content );

        this.pointer = document.createElement( 'div' );
        this.pointer.classList.add( 'floating-arrow' );
        this.pointer.classList.add( this.placement );
    }

}
