import { Injectable } from '@angular/core';
import { Curve, Line, Point } from 'flux-core';
import { IPoint2D } from 'flux-definition';
import { IConnectorEndPoint, IConnectorPoint } from 'flux-diagram-composer';
import { first, last } from 'lodash';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';

/**
 * Information about a single endpoint to use with pathing.
 */
export interface IEndpointInfoForPathing {
    /**
     * The endpoint data as stored in the database.
     */
    point: IConnectorEndPoint;

    /**
     * If the endpoint is connected to a shape, this property will
     * have the shape model attached to the endpoint.
     */
    shape: ShapeModel | null;

    /**
     * The angle value given here is different from the direction
     * property in endpoints. This value indicates the angle the
     * path should start from this endpoint.
     */
    angle: number | null;
}

/**
 * Information about a pair of endpoints to use with pathing
 */
export interface IEndpointPairInfoForPathing {
    pointA: IEndpointInfoForPathing;
    pointB: IEndpointInfoForPathing;
}

/**
 * Connector pather classes calculates paths for a specific path style.
 * All connector pather classes should use this class as the base.
 */
@Injectable()
export abstract class AbstractConnectorPather {
    /**
     * Returns an array of line/curve segments for each point on given connector path.
     */
    public abstract getSegments( points: IConnectorPoint[]): ( Line | Curve )[][];

    /**
     * Updates the connector path when one or more connector points get modified.
     * If one or both endpoints get modified, creates a new path from scratch.
     * In all other cases, adjust the path to make it match the draw style.
     * shouldIgnoreManuallyAdjustedPaths - When calculating a new path, it
     * should check two things.
     * 1. Should it consider the user adjusted paths.
     * 2. Whether the path has user adjusted lines.
     */
    public repath(
        connector: ConnectorModel,
        diagramModel: DiagramModel,
        modifiedPoints: IConnectorPoint[],
        shouldIgnoreManuallyAdjustedPaths: boolean,
    ): IConnectorPoint[] {
        const currentPath = connector.getPoints().map( point => Object.assign({}, point ));
        this.mergePoints( currentPath, modifiedPoints );
        const endpoints = [ first( currentPath ), last( currentPath ) ];
        const endpointInfo = this.getEndpointInfo( endpoints[0], endpoints[1], diagramModel );
        // NOTE: also do full repath when no modified points are given (draw style change)
        if (( shouldIgnoreManuallyAdjustedPaths ||  !this.hasPathContainsManuallyAdjustedPoints( currentPath )) &&
            this.hasEndpointChange( endpoints, modifiedPoints ) || !modifiedPoints.length ) {
            const createdPath = this.createPath( endpointInfo );
            const adjustedPath = this.adjustPath( endpointInfo, createdPath );
            this.setDirections( adjustedPath );
            return adjustedPath;
        } else {
            const adjustedPath = this.adjustPath( endpointInfo, currentPath );
            this.setDirections( adjustedPath );
            return adjustedPath;
        }
    }

    /**
     * Reverses all points (including endpoints) on the connector path and
     * makes necessary changes to control points to make sure the path would
     * not change visually.
     */
    public reverse(
        connector: ConnectorModel,
    ): IConnectorPoint[] {
        const currentPath = connector.getPoints().slice();
        const reversedPath = this.reversePath( currentPath );
        return reversedPath;
    }

    /**
     * Finds points where this connector draws over other connectors on the diagram.
     * TODO: Use a better algorithm ( try: http://page.mi.fu-berlin.de/panos/cg13/l03.pdf )
     */
    public getBumps( points: IConnectorPoint[], segmentsBelow: ( Line | Curve )[]): IPoint2D[][][] {
        const bumps: IPoint2D[][][] = [];
        const segments = this.getSegments( points );
        let lastAdded: IPoint2D = null;
        for ( const segmentsForPoint of segments ) {
            const bumpsForPoint = [];
            for ( const segment of segmentsForPoint ) {
                const bumpForSegment = [];
                const allSegmentBumps = this.getBumpsForSegment( segment, segmentsBelow );
                for ( const bump of allSegmentBumps ) {
                    if ( !lastAdded || !Point.isEqual( lastAdded, bump )) {
                        lastAdded = bump;
                        bumpForSegment.push( bump );
                    }
                }
                bumpsForPoint.push( bumpForSegment );
            }
            bumps.push( bumpsForPoint );
        }
        return bumps;
    }

    /**
     * Creates a path from one point to another with current draw style.
     */
    protected abstract createPath(
        endpoints: IEndpointPairInfoForPathing,
    ): IConnectorPoint[];

    /**
     * Corrects the given path so that it'll match the current draw style.
     * This can be used to correct errors in the path after modifications
     * and to convert the connector draw style from one to another.
     */
    protected abstract adjustPath(
        endpoints: IEndpointPairInfoForPathing,
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[];

    /**
     * Reverses all points (including endpoints) on the connector path and
     * makes necessary changes to control points to make sure the path would
     * not change visually.
     */
    protected abstract reversePath(
        currentPath: IConnectorPoint[],
    ): IConnectorPoint[];

    /**
     * Sets the 'direction' property on connector endpoints. This value will
     * be stored on connector endpoints which is used when drawing arrow heads.
     * The way this value is calculated changes for each connector type.
     */
    protected abstract setDirections(
        currentPath: IConnectorPoint[],
    ): void;

    /**
     * This function returns true if the given path contains
     * at least a single manually adjusted path by the user.
     */
    private hasPathContainsManuallyAdjustedPoints( path: IConnectorPoint[]): boolean {
        for ( const p of path ) {
            if ( p.pathAdjustedManually ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Finds points where this connector draws over other connectors on the diagram.
     * These bumps will be sorted by their distance along segmentA (segment where it should render).
     */
    private getBumpsForSegment( segmentA: ( Line | Curve ), segmentsBelow: ( Line | Curve )[]): IPoint2D[] {
        const bumpsAndDist: { bump: IPoint2D, dist: number }[] = [];
        for ( const segmentB of segmentsBelow ) {
            /* istanbul ignore else */
            if ( segmentA instanceof Line && segmentB instanceof Line ) {
                // NOTE: find Line-Line intersection points
                const bump = segmentA.intersection( segmentB, false );
                if ( bump ) {
                    const dist = segmentA.from.distanceTo( bump );
                    bumpsAndDist.push({ bump, dist });
                }
            }
            // NOTE: use the code below when ready
            // if ( segmentA instanceof Line ) {
            //     if ( segmentB instanceof Line ) {
            //         // TODO: find Line-Line intersection points
            //     } else {
            //         // TODO: find Line-Curve intersection points
            //     }
            // } else {
            //     if ( segmentB instanceof Line ) {
            //         // TODO: find Curve-Line intersection points
            //     } else {
            //         // TODO: find Curve-Curve intersection points
            //     }
            // }
        }
        bumpsAndDist.sort(( a, b ) => a.dist - b.dist );
        return bumpsAndDist.map(({ bump }) => bump );
    }

    /**
     * Checks whether one of the modified points are an endpoint.
     */
    private hasEndpointChange( endpoints: IConnectorPoint[], modifiedPoints: IConnectorPoint[]): boolean {
        for ( let i = 0; i < modifiedPoints.length; i++ ) {
            const modifiedPoint = modifiedPoints[i];
            if ( modifiedPoint.id === endpoints[0].id || modifiedPoint.id === endpoints[1].id ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Replaces a point in an array of points by matching it's id.
     */
    private mergePoints<T extends { id: string }>( points: T[], targets: T[]): void {
        const pointIndex = {};
        points.forEach( point => {
            pointIndex[point.id] = point;
        });
        for ( let i = 0; i < targets.length; i++ ) {
            const target = targets[i];
            if ( pointIndex[target.id]) {
                Object.assign( pointIndex[target.id], target );
            }
        }
    }

    /**
     * Returns endpoint pair with information required for pathing
     */
    private getEndpointInfo(
        pointA: IConnectorEndPoint,
        pointB: IConnectorEndPoint,
        diagramModel: DiagramModel,
    ): IEndpointPairInfoForPathing {
        const result = {
            pointA: { point: pointA, shape: null, angle: null },
            pointB: { point: pointB, shape: null, angle: null },
        };
        if ( pointA.shapeId && ( pointA.gluepointId || pointA.onShape )) {
            result.pointA.shape = diagramModel.shapes[pointA.shapeId];
            if ( pointA.gluepointId ) {
                result.pointA.angle = result.pointA.shape.getGluepointConnectingAngle( pointA.gluepointId );
            } else {
                result.pointA.angle = result.pointA.shape.getEndpointConnectingAngle( pointA );
            }
        } else {
            result.pointA.angle = Point.from( pointA ).angleTo( pointB );
        }
        if ( pointB.shapeId && diagramModel.shapes[pointB.shapeId] && ( pointB.gluepointId || pointB.onShape )) {
            result.pointB.shape = diagramModel.shapes[pointB.shapeId];
            if ( pointB.gluepointId ) {
                result.pointB.angle = result.pointB.shape.getGluepointConnectingAngle( pointB.gluepointId );
            } else {
                result.pointB.angle = result.pointB.shape.getEndpointConnectingAngle( pointB );
            }
        } else {
            result.pointB.angle = Point.from( pointB ).angleTo( pointA );
        }
        return result;
    }
}
