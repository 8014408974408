import { ModelChangeUtils } from './../../../framework/edata/model-change-utils';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { EDataChangeService } from '../edata-change.svc';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';

/**
 * Updates an entities data when passed via a link
 */
@Injectable()
@Command()
export class SyncLinkedEData extends AbstractEDataChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        entityId: string,
        dataItemId: string,
        dataItem: any,
    };

    constructor(
        protected es: EDataChangeService,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        this.modelChangeUtils.syncLinkedEData(
            this.changeModel,
            this.data,
        );
    }

}

Object.defineProperty( SyncLinkedEData, 'name', {
    value: 'SyncLinkedEData',
});

