import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NeutrinoConnection } from '../../connection/neutrino-connection.svc';
import { Message } from '../../message/message.mdl';
import { ICommandResult, SendExecutionStep } from './exec-step-send';
import { MessageResultError } from '../../message/message-error';
import { AbstractAuthentication } from '../../auth/abstract-auth.svc';

/**
 * SendWsExecutionStep is a command execution step which will send
 * the command data to the server via WebSocket.
 */
export class SendWsExecutionStep extends SendExecutionStep {
    /**
     * Implement the sendMessage abstract method.
     */
    protected sendMessage( message: Message ): Observable<ICommandResult> {
        const connection = this.injector.get( NeutrinoConnection );
        return connection.send( message ).pipe(
            map( res => res.ppld ),
            catchError( err => {
                if ( err instanceof MessageResultError && err.code === MessageResultError.ERROR_CODE_TOKEN_REVOKED ) {
                    ( this.injector.get( AbstractAuthentication ) as any ).logOut( false );
                }
                return throwError( err );
            }),
        );
    }
}

Object.defineProperty( SendWsExecutionStep, 'name', {
    value: 'SendWsExecutionStep',
});
