import { AbstractModel } from 'flux-core';

/**
 * This enum lists all the Team Setting User Access.
 */
export enum TeamSettingUserAccess {

    /**
     * Setting level no access user
     */
    NOACCEES = '0',

    /**
     * Setting level sepcific user
     */
    SPECIFIC = '1',

    /**
     * Setting level super admin
     */
    SUPERADMIN = '5',

    /**
     * Setting level admin
     */
    ADMIN = '10',

    /**
     * Setting level team
     */
    TEAM = '15',
}

export enum TeamSettingType {

    /**
     * The user, team setting type.
     */
    USER,

    /**
     * The role, team setting type.
     */
    ROLE,

    /**
     * The team, team setting type.
     */
    TEAM,

    /**
     * The group, team setting type.
     */
    GROUP,
}

/**
 * Team setting model
 * This holds information about a user team setting.
 *
 * @author  Sajeeva
 * @since   2022-07-05
 */

export class TeamSettingModel extends AbstractModel {

    /**
     * user group Plan Id
     */
    public groupPlanId: string;

    /**
     * setting Id
     */
    public settingId: string;

    /**
     * access level of the team setting
     */
    public userAccess: TeamSettingUserAccess;

    /**
     * description value of the setting
     */
    public setting: string;

    /**
     * team setting type
     */
    public teamSettingType: TeamSettingType;
}

Object.defineProperty( TeamSettingModel, 'name', {
  writable: true,
  value: 'TeamSettingModel',
});
