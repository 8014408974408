import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { from, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { EDataModel } from '../model/edata.mdl';

/**
 * Share an EData with the team, so that it is accessible to any other project in the Org.
 */
@Injectable()
@Command()
export class ShareEDataWithTeam extends AbstractMessageCommand {

    /**
     * This command should be sent to the server
     */
     public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IEDataCommand',
        ];
    }

    public static get dataDefinition(): {}  {
        return {
            role: true,
        };
    }

    constructor( protected dataStore: DataStore, protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.previousData = {};
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return modelStore.findOneRaw({ id: this.resourceId }).pipe(
            take( 1 ),
            tap( mdl => this.previousData.teamAccess = mdl.teamAccess ),
        );
    }

    /**
     * execute
     * change EData orgShare
     */
     public execute(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( EDataModel );
        let teamAccess = this.previousData.teamAccess;
        if ( !teamAccess ) {
            teamAccess = {};
        }
        teamAccess.role = this.data.role;
        teamAccess.sharedBy = this.state.get( 'CurrentUser' );
        return from( modelStore.update({ id: this.resourceId }, { $set: { teamAccess: teamAccess }}));
    }

    /**
     * executeResult
     * Update the privacy deatils with the response
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return from( modelStore.update({ id: this.resourceId }, { $set: { teamAccess: response }}));
    }

    public revert(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( EDataModel );
        return from( modelStore.update({ id: this.resourceId }, { $set: { teamAccess: this.previousData.privacy }}));
    }
}

Object.defineProperty( ShareEDataWithTeam, 'name', {
    value: 'ShareEDataWithTeam',
});
