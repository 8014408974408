import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';

/**
 * GetAllTeamSharedEDataInfo
 * Retrieve all team shared databases( EData ) and stores them to state service.
 */
@Injectable()
 @Command()
export class GetAllTeamSharedEDataInfo extends AbstractMessageCommand {
    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }
    private static readonly GLOBAL_DBS = 'GlobalDatabases';

    constructor( protected stateService: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public execute(): boolean {
        return true;
    }

    /**
     * Retrieve the notification data from response and add them to state service.
     * @param response
     * @returns
     */
    public executeResult( response: any ): boolean {
        this.stateService.set( GetAllTeamSharedEDataInfo.GLOBAL_DBS, response );
        return true;
    }
}

Object.defineProperty( GetAllTeamSharedEDataInfo, 'name', {
    value: 'GetAllTeamSharedEDataInfo',
});
