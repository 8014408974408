import { Component, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { ElementRef } from '@angular/core';
import { DropDownButton } from '../abstract-dropdown/dropdown-button.cmp';
import { ISimpleDropdownItem } from './simple-dropdown-item.cmp';

/**
 * SimpleDropdownButton
 * This component is the simple dropdown button which allows us to add
 * any type of html element as the dropdown's button.
 */

@Component({
    selector: 'simple-dropdown-button',
    template: `<div class="simple-dd-btn-container"><ng-content></ng-content></div>`,
    providers: [{ provide: DropDownButton, useExisting: forwardRef(
            /* istanbul ignore next */ () => SimpleDropdownButton ) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [ '.simple-dd-btn-container { display: flex; align-items: center; height: 36px; }' ],
})
export class SimpleDropdownButton extends DropDownButton<ISimpleDropdownItem> {
    constructor( el: ElementRef ) {
        super( el )/* istanbul ignore next */;
    }
}
