import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Injectable } from '@angular/core';

/**
 * PlusCreateStateSet command is an IStateChangeCommand the updates the
 * PlusCreateState
 */
@Injectable()
@Command()
export class PlusCreateStateSet extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            inProgress: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    constructor() {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            PlusCreateState: {
                inProgress: this.data.inProgress,
            },
        };
    }

    public execute() {
        return true;
    }

}

Object.defineProperty( PlusCreateStateSet, 'name', {
    value: 'PlusCreateStateSet',
});

/**
 * PlusCreateStateService is the representation of pluscreate state.
 */
export class PlusCreateStateService extends StateService< 'PlusCreateState', { inProgress: boolean }> {}
