<div #loader
  *ngIf="showLoader | async"
  class="main-loader-container fx-cover fx-center-all fx-center white">
  <img #logo id="logo" src="./assets/images/logo-on-white-200X80.svg" alt="Creately" class="logo" width="130" />
  <div class="fx-overflow-hidden">
    <div #spinner id="spinner" class="spinner paused" >
      <div class="bounce bounce-1"></div>
      <div class="bounce bounce-2"></div>
      <div class="bounce bounce-3"></div>
    </div>
    <div class="fx-margin-top-20" *ngIf="!!description">
      <span class="description">{{ description }}</span>
    </div>
  </div>
</div>