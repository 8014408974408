import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand } from 'flux-core';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will fetch the user's subscription status data from Creately API.
 *
 * @output  The user's subscription status data.
 * @author  Jerome
 * @since   04-08-2021
 */
@Injectable()
@Command()
export class FetchUserOnboardingStatus extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor() {
        super();
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': window.gravity.client.getCookie(),
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        // Not executing command if user is not authenticated
        if ( !window.gravity.client.hasValidSession()) {
            return;
        }
        return EMPTY;
    }

    public prepareData(): any {
        this.data = {};
        if ( !window.gravity.client.hasValidSession()) {
            return;
        }
        const uid = window.gravity.client.getCurrentUserID();
        const query = `{
            user(id:"${uid}"){
                onboardingProgress,
                onboardingLastUpdated
            }
        }`;
        this.data.query = query ;
    }
}

Object.defineProperty( FetchUserOnboardingStatus, 'name', {
    value: 'FetchUserOnboardingStatus',
});
