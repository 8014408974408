import { Injectable } from '@angular/core';
import { AbstractCommand, Command, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { TaskSubscriptionService } from '../task-subscription.svc';
import { TaskSub } from '../task.sub';

/**
 * Starts the task subscription for the current user.
 */
@Injectable()
@Command()
export class StartTaskSubscription extends AbstractCommand {

    static currentSub: TaskSub;

    constructor(
        protected taskSub: TaskSubscriptionService,
        protected datastore: DataStore,
        protected state: StateService<any, any>,

    ) {
        super()/* istanbul ignore next */;
    }

    public execute() {
        if ( !StartTaskSubscription.currentSub || !StartTaskSubscription.currentSub.isActive()) {
            const sub = new TaskSub( this.state.get( 'CurrentUser' ), this.datastore );
            this.taskSub.start( sub );
            StartTaskSubscription.currentSub = sub;
        }
        return true;
    }
}

Object.defineProperty( StartTaskSubscription, 'name', {
    value: 'StartTaskSubscription',
});
