import { IDataItem } from 'flux-definition';
import { IDataItemUIControl } from './data-items-uic.i';
import { map, filter, tap } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ElementRef, Input } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { Tracker } from 'flux-core';

/**
 * UI contorl for TextInput
 */
@Component({
    selector: 'tex-input-uic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <input type="text" class="text-input" [value]="valueSource | async">
    `,
})
export class TextInputUIC implements IDataItemUIControl<string> {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
     @Input()
     public context?: string;

    /**
     * A unique id for the input.
     * This must be set at all times.
     */
    public id: string;

    public valueSource: BehaviorSubject<string>;

    /**
     * Constructor
     */
    constructor( protected elementRef: ElementRef ) {
        this.valueSource = new BehaviorSubject( '' );
    }

    /**
     * An observable that emits when the button is clicked.
     * If an extenal component wants to listen to the button
     * click event, they need to subscribe to this observable.
     */
    public get change(): Observable<string> {
        const input  = ( this.elementRef.nativeElement as HTMLElement ).querySelector( 'input' );
        return fromEvent( input, 'blur' ).pipe(
            filter(() => input.value !== this.valueSource.value ),
            map(() => input.value ),
            tap(() => {
                /* istanbul ignore next */
                if ( this.context ) {
                    Tracker.track( `${this.context}.text.change` );
                }
            }),
        );
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        this.valueSource.next( data.value );
    }

}
