import { DisplayView } from './../../shape/view/display-view';
import { DisplayObject, Event, MouseEvent } from '@creately/createjs-module';

/**
 * The event is used for notifiying changes to easelJs shapes within the canvas.
 * This is dispatched on the canvas to indicate various events
 */
export class EaselEvent extends Event  {

    /**
     * The easelJs mouse event related to this
     * drag event
     */
    public relatedMouseEvent: MouseEvent;

    /**
     * The target shape of this event.
     */
    public relatedShape: DisplayObject;

    constructor( type: string, event: MouseEvent, bubbles = true, cancellable = true ) {
        super( type, bubbles, cancellable );

        this.relatedMouseEvent = event;
        this.relatedShape = event.target;
    }


    /**
     * This function sets the ShapeDisplayView associated with the event as the relatedShape.
     * This is expected to be called in Events related to Shape / Connector view
     * i.e. ShapeOverEvent, ShapeDragEvent, ShapeRightClickEvent
     * @param event easljs MouseEvent
     */
    protected setRelatedShape( event: MouseEvent ) {
        // FIXME: reset `relatedShape` and test
        // this.relatedShape = null;
        if ( event.target instanceof DisplayView ) {
            this.relatedShape = event.target;
        } else if ( event.currentTarget instanceof DisplayView ) {
            this.relatedShape = event.currentTarget;
        } else {
            let target: DisplayObject = event.target;
            while ( target && target.parent ) {
                if ( target.parent instanceof DisplayView ) {
                    this.relatedShape = target.parent;
                    break;
                }
                target = target.parent;
            }
        }
    }
}
