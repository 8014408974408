import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeviewI18nDefault } from '@creately/ngx-treeview';

/**
 * This class is used to override the default text in the TreeviewComponent component.
 * It inherits from the TreeviewI18nDefault class so that only
 * necessary methods can be overridden.
 */
@Injectable()
export class TempAddLibsTreeviewI18n extends TreeviewI18nDefault {
    constructor( protected translateService: TranslateService ) {
        super();
    }

    /**
     * Return the text displayed when no items are found by the filter function.
     */
    getFilterNoItemsFoundText(): string {
        return this.translateService.instant( 'LABELS.NO_LIBRARY_SEARCH_RESULTS' );
    }
}
