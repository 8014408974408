import { throwError, Observable } from 'rxjs';
import { clone } from 'lodash';
import { HttpExecutionStep } from './exec-step-http';
import { CommandInterfaces } from '../abstract.cmd';
import { CommandResultError } from '../error/command-result-error';
import { HttpRequest, HttpResponse } from '@angular/common/http';

/**
 * NeutrinoRestExecutionStep is an execution step which can be used
 * to fetch resources through neutrino rest api. Commands should extend the
 * AbstractHttpCommand to use this execution step.
 */
export class NeutrinoRestExecutionStep extends HttpExecutionStep {

    /**
     * relatedInterfaces property returns 'INeutrinoRestCommand' so only commands
     * which have this interface set will run this execution step.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'INeutrinoRestCommand' ];
    }

    /**
     * This method will get the resource id from the command
     * data.
     * @return  resourceId  id of the resource
     */
    protected get resourceId(): string {
        const data: any = this.command.data;
        if ( !data ) {
            return;
        }
        return data.rid;
    }

    /**
     * This method returns the key from the command
     * data.
     * @return  key  Auth key
     */
    protected get key(): string {
        const data: any = this.command.data;
        if ( !data ) {
            return;
        }
        return data.key;
    }

    /**
     * Returns the authentication key from command data
     * @return authentication token extracted from command data
     */
    protected get auth(): string {
        const data: any = this.command.data;
        if ( !data ) {
            return;
        }
        return data.auth;
    }

    /**
     * handleError handles failed responses from @angular/common/http or errors
     * send from the neutrino. If response is a CommandResultError then throw an
     * error observable with CommandResultError otherwise letting the HttpExecutionStep
     * to handle the error response.
     * @param res: The response object returned from @angular/common/http
     */
    protected handleError( req: HttpRequest<any>, res: HttpResponse<any> ): Observable<Error> {
        if ( res instanceof CommandResultError ) {
            return throwError( res );
        }
        return super.handleError( req, res );
    }

    /**
     * handleResponse handles successful responses from the neutrino
     * rest call and if it sends an error throw the CommandResultError
     * otherwise letting the HttpExecutionStep to handle the response
     * @param res: The response object returned from @angular/common/http
     * @param options: The options object used with @angular/common/http
     */
    protected handleResponse( res: HttpResponse<any> ): any {
        if ( res.body['error-code']) {
            throw new CommandResultError( res.body['error-code'], res.body['error-message']);
        }
        return super.handleResponse( res );
    }

    /**
     * @Override
     * Populates the request param with the command data
     */
    protected populateRequestParam(): any {
        const message: any = {};
        message.pld = this.getPayload();
        message.rid = this.resourceId;
        if ( this.key ) {
            message.key = this.key;
        }
        if ( this.auth ) {
            message.auth = this.auth;
        }
        return message;
    }

    /**
     * This method will get the data from command and return a data without the
     * resource id.
     *
     * @return  data  command data without resorceId.
     */
    protected getPayload(): any {
        const data: any = clone( this.command.data );

        if ( data ) {
            delete( data.rid );
            delete( data.key );
            delete( data.auth );
        }

        return data;
    }

}

Object.defineProperty( NeutrinoRestExecutionStep, 'name', {
    value: 'NeutrinoRestExecutionStep',
});
