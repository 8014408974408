import { EDataChangeService } from './../edata-change.svc';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractEDataChangeCommand } from './abstract-edata-change-command.cmd';
import { isEqual } from 'lodash';
import { IDataSource } from '../model/edata.mdl';

/**
 * Update external data source mappings.
 */
@Injectable()
@Command()
export class UpdateDataSourceMappings extends AbstractEDataChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        dataSource: IDataSource;
        mappingConfig: {
            eDefId: string;
            [prop: string]: any;
        };
    };

    constructor(
        protected es: EDataChangeService,
        protected stateSvc: StateService<any, any>,
    ) {
        super( es ) /* istanbul ignore next */;
    }

    public prepareData() {
        if ( !this.changeModel.dataSource ) {
            this.changeModel.dataSource = this.data.dataSource;
        } else if ( !isEqual( this.data.dataSource, this.changeModel.dataSource )) {
            throw new Error( 'dataSource did not match' );
        }
        if ( !this.changeModel.dataSourceMappings ) {
            this.changeModel.dataSourceMappings = {};
        }
        const updatedBy = this.stateSvc.get( 'CurrentUser' );
        const { eDefId, ...data } = this.data.mappingConfig;
        if ( this.changeModel.dataSourceMappings[eDefId]) {
            const { sheet, rowIdentifierField } = this.changeModel.dataSourceMappings[eDefId];
            if ( sheet.sheetId !== data.sheet.sheetId || rowIdentifierField !== data.rowIdentifierField ) {
                throw new Error( 'mapping already exists and differs from current mappings' );
            }
        } else {
            for ( const entityDefId in this.changeModel.dataSourceMappings ) {
                if ( this.changeModel.dataSourceMappings[entityDefId].sheet.sheetId === data.sheet.sheetId ) {
                    throw new Error( 'sheet is already mapped to a different object type' );
                }
            }
        }
        this.changeModel.dataSourceMappings[eDefId] = { ...data, updatedBy };
        this.changeModel.lastUpdatedFromSource = Date.now();
    }
}

Object.defineProperty( UpdateDataSourceMappings, 'name', {
    value: 'UpdateDataSourceMappings',
});
