import { UserInfoModel } from 'flux-user';
import { ComplexType, DateFNS } from 'flux-core';

/**
 * @deprecated
 */
export class CommentModel {

    /**
     * Comment Id.
     */
    public id: string;

    /**
     * Id of the diagram wich contains the comment.
     */
    public diagramId: string;

    /**
     * The comment content.
     */
    public content: string;

    /**
     * Id of the shape wich contains the comment.
     * This field is to be omitted  if the comment is
     * diagram comment
     */
    public shapeId: string;

    /**
     * The time at which the comment was created.
     */
    public timestamp: Date;

    /**
     * The author of the comment, only the id of
     * the user info model is sufficient.
     */
    @ComplexType()
    public author: UserInfoModel;

    /**
     * Revision of the diagram the comment was created in.
     */
    public diagramRevision: number;

     /**
      * Returns the comment created time time in momentJS style.
      */
    public get createdMoment(): string {
        if ( this.timestamp ) {
            return DateFNS.formatDistance( this.timestamp, new Date().getTime());
        } else {
            return '';
        }
    }

    /**
     * In order to determin whether the comment is a shape comment or not
     * @return boolean
     */
    public get isShapeComment(): boolean {
        return  ( this.shapeId && this.shapeId !== '' );
    }

    /**
     * This function generate a unique comment id concidering
     * following crieteria,
     * First character is c,
     * The current time appends to to c in radix 32 format,
     * Any optional number to append
     *
     * @param  number  Any number to append to the end of the generated id
     * @return string  Comment id generated
     */
    public generateCommentId( suffix?: number ) {

        let commentId: string;
        commentId = 'c' + Date.now().toString( 32 );
        if ( suffix ) {
            commentId = commentId + suffix;
        }
        this.id = commentId;
    }

}


Object.defineProperty( CommentModel, 'name', {
  writable: true,
  value: 'CommentModel',
});
