import { take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserLocator } from 'flux-user';
import { StateService, AbstractNotification, NotificationType, NotifierController,
    Command, CommandInterfaces } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { DataStore } from 'flux-store';
import { CommentModel } from '../../diagram/model/comment.mdl';
import { Observable, combineLatest, of } from 'rxjs';
import { ICommentThreadData } from './comment-thread.i';
import { Notifications } from '../../notifications/notification-messages';

/**
 * This command will resolve a comment thread on the diagram. Resolving
 * will remove the comment indicator from the diagram. It will not delete
 * any comment text or metadata.
 */
@Injectable()
@Command()
export class ResolveComment extends AbstractMessageCommand {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            commentId: true,
            userId: true,
        };
    }

    /**
     * List of commands which will be used by this class.
     */
    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    /**
     * Static method that sets the given comment thread to the CommentThreadData state.
     * @param comment a comment
     * @param state the state service to use
     */
    public static handleNotificationClick(
        comment: CommentModel,
        state: StateService<any, any>,
        dataStore: DataStore,
    ) {
        if ( !comment.parentId ) {
            const thread = { visible: true, threadId: comment.id, comments: [ comment ]};
            state.set( 'CommentThreadData', thread );
        } else {
            dataStore.findOneLatest( CommentModel, { id: comment.parentId }).pipe(
                tap(( model: CommentModel ) => {
                    const thread: ICommentThreadData = { visible: true, threadId: model.id, comments: [ model ]};
                    state.set( 'CommentThreadData', thread );
                }),
            ).subscribe();
        }
    }

    constructor(
        protected userLocator: UserLocator,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected state: StateService<any , any>,
        protected dataStore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    /**
     * This function update the datastore with the new comment model.
     */
    public execute() {
        return this.dataStore.update( CommentModel,
                { id: this.data.commentId, diagramId: this.resourceId },
                {
                    $set: { resolved: new Date().getTime(), resolvedBy: this.data.userId, comitted: false },
                });
    }

    /**
     * This function commits the changes to the datastore upon the success of
     * the execution.
     * @param response - Response data of the request.
     */
    public executeResult( response: any ) {
        return combineLatest(
            this.handleNotification(),
            this.dataStore.update( CommentModel,
                { id: this.resultData.comment.id, diagramId: this.resourceId },
                {
                    $set: { resolved: this.resultData.comment.resolved,
                        resolvedBy: this.resultData.comment.resolvedBy , comitted: true },
                }),
        );
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        return this.dataStore.update(
                CommentModel,
                { id: this.data.commentId, diagramId: this.resourceId },
                {
                    $unset: { resolved: '', resolvedBy: '' },
                });
    }

    /**
     * Show a notification when a comment is resolved by another collab
     */
    protected handleNotification() {
        if ( this.state.get( 'CurrentUser' ) !== this.resultData.comment.resolvedBy &&
            this.state.get( 'CurrentDiagram' ) === this.resultData.comment.diagramId
        ) {
            return this.userLocator.getUserInfo( this.resultData.comment.resolvedBy ).pipe(
                take( 1 ),
                tap( userData => {
                    const options = {
                        inputs: {
                            heading: userData.firstName,
                            description: this.translate.instant( 'NOTIFICATIONS.COMMENT.RESOLVED' ),
                            user: userData,
                            notificationAction: {
                                action: ResolveComment.handleNotificationClick,
                                args: [ this.resultData.comment, this.state, this.dataStore ],
                            },
                            autoDismiss: true,
                            dismissAfter: 3000,
                        },
                    };

                    this.notifierController.show( Notifications.COMMENT_RESOLVED, AbstractNotification,
                        NotificationType.Success, options );
                }),
            );
        }
        return of({});
    }
}

Object.defineProperty( ResolveComment, 'name', {
    value: 'ResolveComment',
});
