import { AbstractArrowHead, IDrawArrowheadOptions } from './arrow-head-abstract';
import { IGraphics } from 'flux-definition';

/**
 * PointerBarbedFilled arrow head entry class is one of the default arrow head types.
 *
 * @author  Mehdhi
 * @since   2018-08-21
 */
/* istanbul ignore next */
export class PointerBarbedFilled extends AbstractArrowHead {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void {
        const bounds = options.bounds;
        const width = bounds.width / 1.15;
        const height = bounds.height / 0.638889;
        const arrowTailGap = Math.round( width / 1.5 );

        graphics.beginFill( options.style.lineColor );
        this.moveTo( graphics, { x: bounds.x, y: bounds.y }, options.matrix );
        this.curveTo(
            graphics,
            { x: bounds.x - width / 2.5, y: bounds.y - height / 10 },
            { x: bounds.x - width, y: bounds.y - height / 2 },
            options.matrix,
        );
        this.curveTo(
            graphics,
            { x: bounds.x - width, y: bounds.y },
            { x: bounds.x - width + arrowTailGap, y: bounds.y },
            options.matrix,
        );
        this.curveTo(
            graphics,
            { x: bounds.x - width, y: bounds.y },
            { x: bounds.x - width , y: bounds.y + height / 2 },
            options.matrix,
        );
        this.curveTo(
            graphics,
            { x: bounds.x - width / 2.5, y: bounds.y + height / 10 },
            { x: bounds.x, y: bounds.y },
            options.matrix,
        );

        graphics.closePath();

    }
}

Object.defineProperty( PointerBarbedFilled, 'name', {
    value: 'PointerBarbedFilled',
});
