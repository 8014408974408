import { get, isFunction, isObject } from 'lodash';
import { ClassUtils } from '../data/class-utils';
import { Injectable } from '@angular/core';

/**
 * @Reference
 *
 * The @Reference decorator can be used to create internal references between
 * different parts of a model. If a reference is set, it will create getters
 * to other parts of the model.
 *
 * Example:
 *
 *  @Reference(( val, model ) => model.points[val])
 *  public head: Point; // string => Point
 *
 * In the above example, the "head" field will initially contain a string which
 * will be replaced with a getter. The getter function will receive 2 parameters
 * the value currently available on the field and the complete model. The value
 * returned by the getter function will be used as the value for the decorated field.
 *
 * This decorator can also be used to replace nested values.
 *
 * Example:
 *
 *  @Reference({
 *     key: ( val, model ) => model.values[val]
 *     'nested.key': ( val, model ) => model.values[val]
 *  })
 *  public head: Point; // object => Point
 *
 * In the above example, the "head" field will initialle have an object/model which may
 * have a field called 'key' and a nested field on 'nested.key'. In this case, each of
 * these paths will be replaced with getters. The value given to these getter functions
 * will be the values available on the model before creating the reference.
 *
 * When used with the DataStore service, it will get a third argument which will contain
 * the root model the DataStore service is processing. If references are used on the top
 * model itself, the second and third arguments will be the same.
 *
 * Example:
 *
 * class Diagram {
 *   public shapes: { [ id: string ]: Shape };
 * }
 *
 * class Shape {
 *   public id: string;
 *   public points: Point[];
 * }
 *
 * class Point {
 *   // Parameters: root -> Diagram, model -> Point
 *   @Reference(( val, model, root ) => root.shapes[val])
 *   public next: Shape;
 * }
 *
 * Reference decorators can be used with any type of objects as long as it has a prototype.
 *
 * TODO: support references inside items in arrays ( data store service ).
 * TODO: move code which implements references from data store service to this file.
 *
 */
// tslint:disable-next-line:only-arrow-functions
export function Reference( refs: any ) {
    return ( target: any, key: string ) => {
        if ( !isFunction( refs )) {
            if ( !isObject( refs )) {
                throw new Error( 'Unexpected Error: the @Reference decorator should be used with a getter ' +
                    'function or an object with keys as fields and getter functions.' );
            }
            const fields = Object.keys( refs );
            for ( let i = 0; i < fields.length; ++i ) {
                if ( !isFunction( refs[fields[i]])) {
                    throw new Error( 'Unexpected Error: if the @Reference is used with an object, all values ' +
                        'in the object should be getter functions.' );
                }
            }
        }
        if ( !target.referenceProperties ) {
            target.referenceProperties = {};
        }
        target.referenceProperties[ key ] = refs;
    };
}

/**
 * ReferenceBuilder
 * This is a stateless utllity class which can be used to build references in
 * objects. The input data may or may not be a model.
 *
 * TODO: change parameter names so that it won't mention model/models
 *
 */
@Injectable()
export class ReferenceBuilder {
    /**
     * add
     * add creates references between fields in a deserialized model.
     * This function will get called recursively in order to process references
     * in nested fields. Both models and non-premitive types can use references.
     *
     * The type given to this function can be a model type or a different class.
     * The root value will be pass down through to reference getter functions and
     * The "maybeModel" can be a model, a premitive value, an array or an object.
     */
    public add( type: any, root: any, maybeModel: any ) {
        const references = this.getReferenceProperties( type );
        this.addNestedReferences( root, maybeModel, references );
        this.addCurrentReferences( type, root, maybeModel, references );
        return maybeModel;
    }

    /**
     * addMany
     * addMany creates references between child models inside the root model
     */
    public addMany( type: any, root: any, models: any[]) {
        return models.map( model => this.add( type, root || model, model ));
    }

    /**
     * addReferenceField
     * addReferenceField creates references between child models inside the root model for a field
     */
    protected addReferenceField( type: any, root: any, model: any, references: any, key: string ) {
        // NOTE the reference can only be either a function or an object with functions
        //       this is enforced when the @Reference decorator is called.
        const reference = references[key];
        if ( isFunction( reference )) {
            this.addReferenceFieldForFunction( type, root, model, reference, key );
        } else {
            this.addReferenceFieldForObject( type, root, model, reference, key );
        }
    }

    /**
     * addReferenceFieldForFunction
     * addReferenceFieldForFunction creates references between child models inside the root model for a field
     *
     *  Example:
     *  @Reference(( val, model, root ) => model.values[val])
     *
     */
    protected addReferenceFieldForFunction(
        _type: any,
        root: any,
        model: any,
        ref: any,
        key: string,
    ) {
        const data = model[key];
        Object.defineProperty( model, key, {
            enumerable: false,
            get: () => ref( data, model, root ),
        });
    }

    /**
     * addReferenceFieldForObject
     * addReferenceFieldForObject creates references between child models inside the root model for a field
     *
     *  Example:
     *  @Reference({
     *     key: ( val, model, root ) => model.values[val]
     *     'nested.key': ( val, model, root ) => model.values[val]
     *  })
     *
     */
    protected addReferenceFieldForObject( type: any, root: any, model: any, ref: any, key: string ) {
        const data = model[key];
        if ( data === null || data === undefined ) {
            return;
        }
        if ( !isObject( data )) {
            throw new Error( `Unexpected Error: was expecting an object but received ${data} ` +
                `for ${type.name}: ${key}` );
        }
        Object.keys( ref ).forEach( refFieldKey => {
            const refFieldFn = ref[refFieldKey];
            const refFieldData = get( data, refFieldKey );
            const refFieldPath = refFieldKey.split( '.' );
            if ( refFieldPath.length === 1 ) {
                // Example:
                // @Reference({
                //    key: ( val, model, root ) => model.values[val]
                // })
                Object.defineProperty( data, refFieldKey, {
                    enumerable: false,
                    get: () => refFieldFn( refFieldData, model, root ),
                });
            } else {
                // Example:
                // @Reference({
                //    'nested.key': ( val, model, root ) => model.values[val]
                // })
                const parentPath = refFieldPath.slice( 0, refFieldPath.length - 1 );
                const childPath = refFieldPath[ refFieldPath.length - 1 ];
                const parentObject = get( data, parentPath );
                Object.defineProperty( parentObject, childPath, {
                    enumerable: false,
                    get: () => refFieldFn( refFieldData, model, root ),
                });
            }
        });
    }

    /**
     * Get @Reference properties stored in the type's prototype
     */
    private getReferenceProperties( type: any ) {
        return type && type.prototype && type.prototype.referenceProperties;
    }

    /**
     * Add references for child models of current model
     */
    private addNestedReferences( root: any, maybeModel: any, references: any ) {
        if ( !maybeModel || !isObject( maybeModel )) {
            return;
        }
        // TODO support arrays, the references argument is not valid in this case
        //      and it should be taken again from an item in the array instead.
        // if ( Array.isArray( maybeModel ) && maybeModel.length ) {
        //     maybeModel.forEach( model => {
        //         const refs = this.getReferenceProperties( model );
        //         this.addNestedReferences( root, model, refs );
        //     });
        //     return;
        // }
        Object.keys( maybeModel ).forEach( key => {
            const value = maybeModel[key];
            const hasRef = Boolean( references && references[key]);
            if ( value && !hasRef ) {
                const type = ClassUtils.getConstructor( value );
                this.add( type, root, value );
            }
        });
    }

    /**
     * Add references for the given model
     */
    private addCurrentReferences( type: any, root: any, model: any, references: any ) {
        if ( !references ) {
            return;
        }
        Object.keys( references ).forEach( key => {
            this.addReferenceField( type, root, model, references, key );
        });
    }
}
