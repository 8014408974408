
/**
 * This is a data structure that represents a SVG document.
 * It provides methods to extract information from the svg as well
 * as to alter the svg by setting various values.
 * It also allows for the svg document to be exported in various formats
 * i.e. xml string, data URL etc.
 *
 * @author  Ramishka
 * @since   2019-03-29
 */
export class SVG {

    /**
     * Holds the svg document created after parsing the
     * svg string.
     */
    protected doc: any;

    /**
     * Holds the root element of the svg document
     */
    protected documentElement: any;

    constructor ( svgString: string ) {
        const parser = this.getParser();
        svgString = this.validateSVG( svgString );
        this.doc = parser.parseFromString( svgString, 'image/svg+xml' );
        this.documentElement = this.doc.documentElement;
    }

    /**
     * Returns the svg view box. Undefined if the document has
     * no view box.
     */
    public get viewBox(): SVGRect {
        return this.documentElement.viewBox.baseVal;
    }

    /**
     * Returns the width attribute of the svg document.
     * Returns null if width is not set.
     */
    public get width(): number {
        return this.documentElement.width.baseVal.value;
    }

    /**
     * Returns the height attribute of the svg document.
     * Returns null if height is not set.
     */
    public get height(): number {
        return this.documentElement.height.baseVal.value;
    }

    /**
     * Updates the width of the svg document.
     */
    public set width( width: number ) {
        this.documentElement.setAttribute( 'width', width );
    }

    /**
     * Updates the height of the svg document.
     */
    public set height( height: number ) {
        this.documentElement.setAttribute( 'height', height );
    }

    /**
     * Returns true the width attribute is set on the svg document
     * with a valid value
     */
    public isWidthSet(): boolean {
        const width = this.width;
        return width && width > 0;
    }

    /**
     * Returns true if the height attribute is set on the svg document
     * with a valid value
     */
    public isHeightSet(): boolean {
        const height = this.height;
        return height && height > 0;
    }

    /**
     * Exports the entire svg document as a xml string.
     */
    public toString(): string {
        return this.getSerializer().serializeToString( this.doc );
    }

    /**
     * Exports the entire svg document as a data URL.
     */
    public toDataURL(): string {
        return 'data:image/svg+xml;base64,' + btoa( unescape( encodeURIComponent( this.toString())));
    }

    /**
     * Returnes the resulting the svg as string after .
     */
    public getSvg(): string {
        return this.documentElement.outerHTML;
    }

    /**
     * Removes text elements from the svg.
     */
    public removeText() {
        this.removeElements([ 'text' ]);
    }

    /**
     * Removes Creately Classic diagram data from the svg if they are available
     * FIXME: It is inefficient to use the removeElements function to remove the
     * Creately data. creately-doc is a root element and recursive check is not
     * required. Using this because the unit tests are failing on CI
     */
    public removeCreatelyClassicData() {
        this.removeElements([ 'creately-doc' ]);
    }

    /**
     * CHecks if the svg is Creately classic's diagram or not. This check is done by checking the
     * availability of 'id' and 'diagram-revision' properties of given svg.
     */
    public isCreatelyClassicDiagram(): boolean {
        return this.documentElement.hasAttribute( 'id' ) && this.documentElement.hasAttribute( 'diagram-revision' );
    }

    /**
     * Removes given list of elements form the svg.
     * @param elementNames List of elemennt names to remove.
     */
    protected removeElements( elementNames: string[]) {
        this.removeElementsRecursively( this.documentElement, elementNames );
    }

    /**
     * Recursively checks and removes the elements which are availabe in given list of element names.
     * @param element Element to check the children.
     * @param elementNames Elements to remove.
     */
    private removeElementsRecursively( element: Node, elementNames: string[]) {
        if ( element.hasChildNodes()) {
            let childNode: Node = element.firstChild;
            while ( childNode ) {
                const c = childNode;
                childNode = childNode.nextSibling;
                if ( elementNames.includes( c.nodeName )) {
                    element.removeChild( c );
                } else {
                    this.removeElementsRecursively( c, elementNames );
                }
            }
        }
    }

    /**
     * Returns a new DOMParser instance. This is used to parse the
     * svg string.
     */
    private getParser(): DOMParser {
        return new DOMParser();
    }

    /**
     * Returns a new XML serializer instance. This is used to
     * serialize and export the svg document.
     */
    private getSerializer(): XMLSerializer {
        return new XMLSerializer();
    }

    /**
     * This function adds the missing xmlns tag
     * to svg files and returns the updated svg
     * string.
     */
    private validateSVG( svgString: string ) {
        const parser = this.getParser();
        const doc = parser.parseFromString( svgString, 'text/html' );
        if ( !doc.querySelector( 'svg' ).getAttribute( 'xmlns' )) {
            svgString = svgString.replace( '<svg', '<svg xmlns="http://www.w3.org/2000/svg"' );
        }
        return svgString;
    }
}
