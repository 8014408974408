import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { forEach, values } from 'lodash';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';

/**
 * ChangeAttachments
 * This will change the imageGallery map  property of the diagram.
 */
@Injectable()
@Command()
export class ChangeAttachments extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: { [attachmentId: string]: {
        /**
         * To specify whether add or remove the attachment
         */
        action: 'add' | 'remove',
        type: string,
        shapeId: string,
        hash: string,
        name: string,
        link: string,
        downloadLink: string,
        downloadable: boolean,
        extension: string,
        /**
         * The added timestamp
         */
        added?: number,

    }};

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        forEach( this.data, ( value, key ) => {
            if ( value.action === 'add' ) {

                const attachment = values( this.changeModel.attachments ).find( a => a.link ===  value.link );
                if ( !attachment ) {
                    this.changeModel.attachments[ key ] = {
                        id: key,
                        fileId: value.fileId,
                        hash: value.hash,
                        name: value.name,
                        added: value.addedTime,
                        type: value.type,
                        link: value.link,
                        imgSrc: value.imgSrc,
                        source: value.source,
                        downloadable: value.downloadable,
                        downloadLink: value.downloadLink,
                    };
                }
                const shapeAttachments = this.changeModel.shapes[ value.shapeId ].attachmentIds;

                const id = attachment ? attachment.id : key;
                if ( value.shapeId && !shapeAttachments.includes( id )) {
                    // NOTE: Setting a new array since to suppport sakota
                    this.changeModel.shapes[ value.shapeId ].attachmentIds = [ ...shapeAttachments, id ];
                }
            } else {
                this.changeModel.shapes[ value.shapeId ].attachmentIds =
                    this.changeModel.shapes[ value.shapeId ].attachmentIds.filter( id => id !== key );

                const refFound =
                    values( this.changeModel.shapes ).find( s => s.attachmentIds.find( aId => aId === key ));
                if ( !refFound ) { // No reference anywhere
                    delete this.changeModel.attachments[ key ];
                }
            }
        });
    }
}

Object.defineProperty( ChangeAttachments, 'name', {
    value: 'ChangeAttachments',
});
