import { Siddi } from '@creately/siddi';
import { AppConfig } from '../app-config';
import { Flags } from '../flags';
import { Logger } from '../logger/logger.svc';

/**
 * This interface defines the event property required by the tracker
 * NOTE: only this structure is allowed to be stored on Snowplow
 * Database as of now. Need to expand these if more fields are required
 */
export interface ITrackingEventProperty {
    value1Type?: string;
    value1?: string;
    value2Type?: string;
    value2?: string;
    value3Type?: string;
    value3?: string;
    value4Type?: string;
    value4?: string;
}

/**
 * Class Tracker
 * This is a wrapper class for Siddi event tracker.
 *
 */
export class Tracker {

    /**
     * This returns all the globals
     */
    public static get globals() {
        return this._globals;
    }

    /**
     * Siddi track function wrapper. When passing the event name, application name must be ommited.
     * The function will add the relavent application namespace by default.
     * WARNING: eventProperties passed into this function will get modified
     * do.click not proton.do.click
     * @param eventName Event to track
     * @param eventProperties Event properties if required
     */
    public static track( eventName: string, eventProperties?: ITrackingEventProperty ) {
        try {
            if ( !this.siddi ) {
                this.siddi = new Siddi( AppConfig.get( 'EVENT_TRACKER_CONFIG' ));
            }
            const props = eventProperties ? eventProperties : {};
            const appEventName = AppConfig.get( 'APP_NAMESPACE' ) + '.' + eventName;
            const partialEventName = eventName.split( '.' );
            const mergedProperties = Object.assign( props, {
                url: window.location.pathname ,
                location: partialEventName[0],
                subLocation: partialEventName[1],
                ...this.globals,
            });
            this.siddi.track( appEventName, mergedProperties );
            /* istanbul ignore next */
            if ( Flags.get( 'DEBUG_APP_TRACKING' )) {
                Logger.debug( `Tracking:`, appEventName, JSON.stringify( mergedProperties ));
            }
        } catch ( error ) {
            // Siddi tracking is failing due to some reason
            // Ignoring the errors for the moment.
            /* istanbul ignore next */
            if ( Flags.get( 'DEBUG_APP_TRACKING' )) {
                Logger.error( `Tracking failed`, eventName, error, JSON.stringify( eventProperties ));
            }
        }
    }

    /**
     * Identify the user to associate tracking events. Ideally, this need to called
     * whenever the application is loaded or user is authenticated.
     * @param userId Creately user id
     * @param userProperties Additional user prolerties if required
     */
    public static setUser( userId: string, userProperties?: Object ) {
        try {
            if ( !this.siddi ) {
                this.siddi = new Siddi( AppConfig.get( 'EVENT_TRACKER_CONFIG' ));
            }
            this.siddi.identify( userId, userProperties );
            this.setGlobalProperty( 'userId', userId );
        } catch ( error ) {
            // Siddi tracking is failing due to some reason
            // Ignoring the errors for the moment.
        }
    }

    /**
     * Track the loading of the application
     */
    public static async trackLoad() {
        await this.setDomainUserIdFromSnowplow();
        this.setUserIdFromGravity();
        this.setUserPlanFromGravity();
        this.setUserRegionFromGravity();
        this.sessionStart = this.getTimeInMs();
        this.track( 'load' );
    }

    /**
     * Track the unloading of the application
     */
    public static trackExit() {
        const duration = this.getCurrentSessionTime();
        const sessionDuration = duration ? duration : 0;
        this.track( 'exit', { value1Type: 'duration', value1: `${sessionDuration}` });
    }

    public static getCurrentSessionTime() {
        return Math.round(( this.getTimeInMs() - this.sessionStart ) / 1000 );
    }

    /**
     * This function sets properties to globals
     * @param propertyName Name of the property
     * @param value
     */
    public static setGlobalProperty( propertyName: string, value: any ) {
        this.globals[propertyName] = value;
    }
    /**
     * Current Siddi instance
     */
    protected static siddi;

    /**
     * Time when the current app session started.
     */
    protected static sessionStart: number;

    /**
     * This holds all global properties required by trackers
     */
    protected static _globals: any = {};

    /**
     * This function sets user id using gravity if user id is not available
     */
    protected static setUserIdFromGravity() {
        if (( window as any ).gravity && ( window as any ).gravity.client.hasValidSession()) {
            this.setUser(( window as any ).gravity.client.getCurrentUserID());
        }
    }

    /**
     * This function sets user plan using gravity if user plan is not available
     */
    protected static setUserPlanFromGravity() {
        if (( window as any ).gravity && ( window as any ).gravity.client.hasValidSession()) {
            const planId = ( window as any ).gravity.client.getCurrentUserPlan();
            let planType = planId.split( '-' )[0].toLocaleUpperCase();
            if ( planType === 'PUBLIC' ) {
                planType = 'FREE';
            }
            this.setGlobalProperty( 'plan', planType );
        }
    }

    /**
     * This function sets user region using gravity if user region is not available
     */
    protected static setUserRegionFromGravity() {
        if (( window as any ).gravity && ( window as any ).gravity.client.hasValidSession()) {
            this.setGlobalProperty( 'region', ( window as any ).gravity.client.getCurrentUserRegion());
        }
    }

    /**
     * This function adds the domain userId to all events from snowplow
     */
    protected static setDomainUserIdFromSnowplow() {
        return new Promise<void>(  resolve => {
            if (( window as any ).snowplow ) {
                ( window as any ).snowplow( function () {
                    // tslint:disable-next-line: no-invalid-this
                    const cf = this.cf;
                    const domainUserId = cf.getDomainUserId();
                    Tracker.setGlobalProperty( 'domainUserId', domainUserId );
                    resolve();
                });
                return;
            }
            resolve();
        });
    }

    /**
     * Returns the current time in mili seconds
     */
    protected static getTimeInMs(): number {
        return ( new Date()).getTime();
    }

}
