import { IShapeNode } from './svg-node/shape-node.i';
import {
    ICanvasInstruction,
    ICanvasInstructionFactory,
    IComputedStyleDefition,
} from './canvas-instruction/canvas-instruction.i';
import { PathCanvasInstructionFactory } from './canvas-instruction/path-canvas-instruction-factory';
import { StyleCanvasInstructionFactory } from './canvas-instruction/style-canvas-instruction-factory';
import { EllipseCanvasInstructionFactory } from './canvas-instruction/ellipse-canvas-instruction-factory';
import { LineCanvasInstructionFactory } from './canvas-instruction/line-canvas-instruction-factory';
import { PolylineCanvasInstructionFactory } from './canvas-instruction/polyline-canvas-instruction-factory';
import { PolygonCanvasInstructionFactory } from './canvas-instruction/polygon-canvas-instruction-factory';
import { RectCanvasInstructionFactory } from './canvas-instruction/rect-canvas-instruction-factory';
import { CircleCanvasInstructionFactory } from './canvas-instruction/circle-canvas-instruction-factory';
import { IStyleDefinition } from 'flux-definition';
import { ImageCanvasInstructionFactory } from './canvas-instruction/image-canvas-instruction-factory';

/**
 * class CanvasDrawer
 * Handle creation of Graphics instructions based on svg instructions.
 */
export class CanvasDrawer {

    /**
     * Regiter all instruction factories once using current factories implemented
     */
    public static registerFactories() {
        if ( this.factories && this.styleFactory ) {
            return;
        } else {
            // Current factories, update the array when new factories are added.
            const currentFactories = [
                PathCanvasInstructionFactory,
                EllipseCanvasInstructionFactory,
                LineCanvasInstructionFactory,
                PolylineCanvasInstructionFactory,
                PolygonCanvasInstructionFactory,
                RectCanvasInstructionFactory,
                CircleCanvasInstructionFactory,
                ImageCanvasInstructionFactory,
            ];

            currentFactories.forEach( factory => {
                const factoryInstance = new factory();
                this.factories[ factoryInstance.svgElementName ] = factoryInstance;
            });

            this.styleFactory = new StyleCanvasInstructionFactory();
        }
    }
    /**
     * All instruction factory instances currently providing the translation functionalities.
     * When instructions are being translated, related factory instance MUST be taken from this.
     */
    private static factories: { [ type: string ]: ICanvasInstructionFactory } = {};

    /**
     * Style factory to process all node styles, each shape node MUST be passed to this
     * factory to generate its' style instructions
     */
    private static styleFactory: StyleCanvasInstructionFactory;

    /**
     * Holds all generated canvas instructions
     */
    private instructions: ICanvasInstruction[] = [];

    /**
     * Holds all generated gradient shape nodes. This will include gradients from svg defs elements also.
     * When a style factory needs a gradient to apply, it must process the gradient node and apply the
     * gradient style appropriately.
     */
    private gradients: { [ id: string ]: IShapeNode } = {};

    /**
     * Holds all the style definitions related to the current svg being processed.
     */
    private styleDefinitions: { [id: string]: IStyleDefinition } = {};

    /**
     * Create an instruction from given svg shape node.
     * @param shape shape node
     */
    public createInstructions( shape: IShapeNode ) {
        const instructionNodeType = shape.type;
        switch ( instructionNodeType ) {
            case 'linearGradient':
            case 'radialGradient':
                this.gradients[ shape.data.id ] = shape;
                break;
            case 'image':
                this.instructions.push(
                    ...CanvasDrawer.factories[ instructionNodeType ].createInstruction( shape ),
                );
                break;
            default:
                if ( !CanvasDrawer.factories[ instructionNodeType ]) {
                    throw new Error( 'SVG element \'' + instructionNodeType + '\' is not supported yet.' );
                } else {
                    const styleInstructions = CanvasDrawer.styleFactory.createInstruction( shape, this.gradients );
                    this.setStyleDefinitions( styleInstructions.styleDefinitions );
                    this.instructions.push( ...styleInstructions.start );
                    this.instructions.push(
                        ...CanvasDrawer.factories[ instructionNodeType ].createInstruction( shape ),
                    );
                    this.instructions.push( ...styleInstructions.end );
                }
                break;
        }
    }

    /**
     * Return current instruction set as a string array
     */
    public getInstructionStrings(): string[] {
        const insString = [];
        this.instructions.forEach( i => {
            insString.push( `${i.instruction}(${i.params.join( ',' )})` );
        });
        return insString;
    }

    /**
     * Return all the style definitions relate to the current shape
     */
    public getStyleDefinitions(): { [id: string]: IStyleDefinition } {
        return this.styleDefinitions;
    }

    /**
     * Return all the canvas instructions collected during the svg conversion process
     */
    public getInstructions(): ICanvasInstruction[] {
        return this.instructions;
    }

    /**
     * Store the style definitions collected from gradient and style factories.
     * When storing, duplicate definitions update the existing definition's priority.
     * @param definitions
     */
    private setStyleDefinitions( definitions: IComputedStyleDefition[]) {
        definitions.forEach( def => {
            if ( this.styleDefinitions[ def.id ]) {
                // Duplicate style def, increase the prority of the existing one
                this.styleDefinitions[ def.id ].priority += 1;
            } else {
                // New style def, store it
                this.styleDefinitions[ def.id ] = def.def;
            }
        });
    }
}
