import { Subject, Observable, merge, of } from 'rxjs';
import { map, skip, concat, filter, startWith, takeWhile } from 'rxjs/operators';
import { Logger } from '../logger/logger.svc';
import { StateService } from './state.svc';
import { Injectable } from '@angular/core';

/**
 * Stores values by user id. This is usually a list of values that are mapped to a state.
 */
export interface IUserStates<V> {
    [userId: string]: V;
}

/**
 * This is an extension of the {@link StateService} that manages states of users
 * other than the current user's. This service stores multiple values for a state type
 * each mapped to a user id. This service will not manage the state values specific to
 * the current user. Instead it will use the StateService to store and retrieve them.
 * The service depends of the 'CurrentUser' state to identify the current user Id.
 *
 * This enables an app to manage the states of all collaborators or peers during run time.
 * States can be used or changed by any part of the application. Different parts of the app
 * may be listening to the changes of a state. This service enables getting state values and
 * changes that happen to them at a user level or as a whole.
 *
 * IMPORTANT: The state service will be primarily managed by the IStateChangeCommand types to set states
 * and sync them across active realtime clients and recieve other client's states. This service must be
 * only used directly to get state values or to listen to state changes.
 */
@Injectable()
export class SharedStateService<K, V> {

    /**
     * stores each state by the given unique identifier as the key
     * and values mapping to each user (by user id). The key must be unique
     * across the application scope.
     */
    protected _states: Map<K, IUserStates<V>>;

    /**
     * stores the observable for each state that emits the state
     * changes for all users. Not every state has a associated observable. they
     * are created on demand.
     */
    protected _changes: Map<K, Subject<IUserStates<V>>>;

    /**
     * Constructor. Initializes the dictionaries used by the
     * state service.
     */
    constructor( protected stateService: StateService<K, V>, protected log: Logger ) {
        this._states = new Map();
        this._changes = new Map();
    }

    /**
     * Returns the current user's id based on the CurrentUser state.
     */
    private get currentUserId(): string {
        const id: string = <any>this.stateService.get( <any>'CurrentUser' );
        if ( !id ) {
            throw new Error(
                'The CurrentUser state is not set. It is required for the SharedStateService to function.' );
        }
        return id;
    }

    /**
     * Stores the given state value for the given user. This will trigger a change to
     * who ever is listing.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param userId The id of the user to whome the value belongs.
     * @param state The state data of any data type that will be set as the current state
     */
    public set( stateType: K, userId: string, state: V ) {
        if ( this.currentUserId === userId ) {
            this.stateService.set( stateType, state, true );
        } else {
            this.setState( stateType, userId, state );
            if ( this._changes.has( stateType )) {
                this._changes.get( stateType ).next({ [userId]: state });
            }
        }
    }

    /**
     * Returns all the values pertaining to all users for the given state
     * @param stateType an identifier for the state type.
     */
    public get( stateType: K ): IUserStates<V> {
        const users: IUserStates<V> = this.getShared( stateType );
        if ( this.stateService.has( stateType )) {
            users[ this.currentUserId ] = this.stateService.get( stateType );
        }
        return users;
    }

    /**
     * Returns the value specific to the given user and the given state
     * @param stateType an identifier for the state type.
     * @param userId The id of the user who's value is needed.
     */
    public getUser( stateType: K, userId: string ): V {
        if ( this.currentUserId === userId ) {
            return this.stateService.get( stateType );
        } else if ( this.hasShared( stateType, userId )) {
            return this.getShared( stateType )[ userId ];
        }
    }

    /**
     * Returns all the values pertaining to all shared users for the given state
     * Does not contain the current user's value.
     * @param stateType an identifier for the state type.
     */
    public getShared( stateType: K ): IUserStates<V> {
        if ( this.hasShared( stateType )) {
            const users: IUserStates<V> = this._states.get( stateType );
            return { ...users };
        } else {
            return {};
        }
    }

    /**
     * Indicates if there is a state set for any user (including current user)
     * for the given state type.
     * @param stateType an identifier for the state type.
     */
    public has( stateType: K ): boolean {
        return this.stateService.has( stateType ) || this.hasShared( stateType );
    }

    /**
     * Indicates if there is a state set for any of the shared users
     * for the given state type. If a user ID is provided, indicates if the state
     * is set for the given user only.
     * @param stateType an identifier for the state type.
     * @param userId The id of the user if the state must be checked for a specific user.
     */
    public hasShared( stateType: K, userId?: string ): boolean {
        if ( userId ) {
            return this._states.has( stateType ) && !!this._states.get( stateType )[ userId ];
        }
        return this._states.has( stateType );
    }

    /**
     * Returns a observable that will emit changes of given state for any of the users including
     * the current user. The observable will immideately emit the current set of values and then
     * emit changes of only speicifc users as they happen. Each emit may contain values of one or
     * more users. If the state is removed, the observable will completed. If a user state is removed, the
     * user state will be emited once with {@code null} value.
     * @param stateType an identifier for the state type
     */
    public changes( stateType: K ): Observable<IUserStates<V>> {
        return of( this.get( stateType )).pipe(
            concat( merge(
                this.getChanges( stateType ),
                this.stateService.changes( stateType ).pipe(
                    skip( 1 ),
                    map( value => ({ [this.currentUserId]: value })),
            ))));
    }

    /**
     * Returns a observable that will emit changes of given state for the given user.
     * The observable will immideately emit the current value and then emit changes to values
     * as they happen. If the state is removed for the user, the observable will complete.
     * @param stateType an identifier for the state type
     */
    public changesOfUser( stateType: K, userId: string ): Observable<V> {
        if ( this.currentUserId === userId ) {
            return this.stateService.changes( stateType );
        }
        return this.getChanges( stateType ).pipe( // Emits one or more user changes in a single emit.
            map( users => users[ userId ]), // Get the value for the specific user.
            takeWhile( value => value !== null ), // If it is null, user state has been deleted, so complete.
            startWith( this.getUser( stateType, userId )), // start with the current value.
            filter( value => value !== undefined )); // Emit only if the user had a valid value
    }

    /**
     * Returns a observable that will emit changes of given state for any of the users excluding
     * the current user. The observable will immideately emit the current set of values and then
     * emit changes of only speicifc users as they happen. Each emit may contain values of one or
     * more users. The observable will complete if the state is removed. If a user state is removed, the
     * user state will be emited once with {@code null} value.
     * @param stateType an identifier for the state type
     */
    public changesOfShared( stateType: K ): Observable<IUserStates<V>> {
        return this.getChanges( stateType ).pipe( startWith( this.getShared( stateType )));
    }

    /**
     * Removes the given state for all users unless a specific user is specified. If no user is specified
     * states for all users will be removed including the current user. Any streams specific to the user
     * or state will be completed as per the remove request.
     * @param stateType an identifier for the state type
     * @param userId The id of a user. If given will remove the state entry for that user only.
     */
    public remove( stateType: K, userId?: string ) {
        if ( !this.has( stateType )) {
            throw new Error( 'Requested state type is not set or does not exist' );
        }

        if ( userId ) { // Only remove for given user
            this.removeUser( stateType, userId );
        } else {
            this.removeUser( stateType, this.currentUserId );
        }
    }

    /**
     * Remove all the states set for a given user. Removes the user
     * essentially. All states will be deleted.
     * @param userId The user who's states have to be removed
     */
    public removeAll( userId: string ) {
        const states = Array.from( this._states.keys());
        states.forEach( stateType => {
            this.removeUser( stateType, userId );
        });
    }

    /**
     * Sets the given state as a shared state. This function validates the state type and the
     * given state, and sets the state passed into the method to the given user.
     * @param stateType an identifier for the state type. This must be unique to the
     *      application scope and can be of any type.
     * @param userId The id of the user to whome the value belongs.
     * @param state The state data of any data type that will be set as the current state
     */
    private setState( stateType: K, userId: string, state: V ) {
        if ( !stateType ) {
            throw new Error( 'Provide a valid identifier for the state which is unique.' );
        }
        if ( !userId ) {
            throw new Error( 'Provide a valid user id to set the state value to.' );
        }
        if ( state === undefined || state === null ) {
            throw new Error( 'Provide a valid value for the state.' );
        }

        let users: IUserStates<V>;
        if ( this.hasShared( stateType )) {
            users = this._states.get( stateType );
        } else {
            users = {};
        }

        users[ userId ] = state;
        this._states.set( stateType, users );
    }

    /**
     * Returns a subject that emits changes of any shared users for the given
     * state type. This creates a subject if one doesnt already exist.
     * @param stateType an identifier for the state type.
     */
    private getChanges( stateType: K ): Subject<IUserStates<V>> {
        if ( !this.hasShared( stateType )) {
            this.log.info( 'Shared state changes requested on a stateType that is currently unavialable ' +
                'but it may be available in future.' );
        }
        if ( !this._changes.has( stateType )) {
            this._changes.set( stateType, new Subject());
        }
        return this._changes.get( stateType );
    }

    /**
     * Removes a user's specific state for any given user (including current user). Removes
     * the state for the specific user and completes any changes streams that are active.
     * @param stateType an identifier for the state type.
     * @param userId The id of the user whos state needs to be removed.
     */
    private removeUser( stateType: K, userId: string ) {
        if ( userId === this.currentUserId ) {
            if ( this.stateService.has( stateType )) {
                this.stateService.remove( stateType );
            }
        } else {
            if ( this.getShared( stateType )[userId] !== undefined ) {
                const states = this._states.get( stateType );
                delete states[ userId ];

                if ( this._changes.has( stateType )) {
                    // Emit null to notify deletion. User specific streams will complete. Other
                    // streams (changes, changesOfShared) will emit null for the user.
                    this._changes.get( stateType ).next({ [userId]: null });
                }
            }
        }
    }
}
