import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';
import { ChangeResourceGroupShare } from '../../group-share/command/change-resource-group-share.cmd';

/**
 * This is the command to remove group shares from diagram.
 *
 * This extends {@link ChangeResourceGroupShare} to send a request to the server.
 *
 */
@Injectable()
@Command()
export class ChangeProjectGroupShare extends ChangeResourceGroupShare  {

    protected getModelStore() {
        return this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
    }
}

Object.defineProperty( ChangeProjectGroupShare, 'name', {
    value: 'ChangeProjectGroupShare',
});
