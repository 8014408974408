import { Injectable } from '@angular/core';
import { Command, AbstractCommand, StateService, CommandScenario } from 'flux-core';
import { tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';

/**
 * Gets ids of all selected shapes
 *
 *  data: {}
 *
 *  resultData: {
 *      [shapeId: string]: number
 *  }
 *
 */
@Injectable()
@Command()
export class GetSelectedShapeIndexes extends AbstractCommand {
    constructor(
        protected ll: DiagramLocatorLocator,
        protected stateService: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData() {
        this.resultData = {};
        const locator = this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
        return locator.getDiagramOnce().pipe(
            tap( diagram => {
                const shapeIds = this.stateService.get( 'Selected' );
                for ( const shapeId of shapeIds ) {
                    const shape = diagram.shapes[shapeId];
                    this.resultData[shapeId] = shape.zIndex;
                }
            }),
        );
    }

    public execute() {
        return true;
    }
}

Object.defineProperty( GetSelectedShapeIndexes, 'name', {
    value: 'GetSelectedShapeIndexes',
});
