/**
 * MapMap is similar to a regular map but it will first map the given
 * key and data with functions and stores the result value (val).
 *
 *   { key => function(key, data) }
 *
 * If the value with key.id already exists, it will be updated with the
 * onUpdate callback, otherwise a new value will be created.
 *
 *   Example:
 *
 *   const points = new MapMap({
 *     onCreate: (key, data) => {
 *       const point = new Point( data.x, data.y ),
 *       something.addPoint(point);
 *       return point;
 *     }
 *     onUpdate: (point, key, data) => {},
 *       point.setPos( data.x, data.y );
 *     },
 *     onDelete: (point, key) => {
 *       something.removePoint(point);
 *     },
 *   });
 *
 */
export class MapMap<V, D = any> {
    /**
     * Callback function which will be called when a value is updated.
     * It should return a value which will be stored in the map.
     */
    public onCreate: ( key: string, data: D ) => V;

    /**
     * Callback function which will be called when a value is updated.
     * It should return the updated value to replace the current value.
     */
    public onUpdate: ( val: V, key: string, data: D ) => V;

    /**
     * Callback function which will be called when a value is deleted.
     */
    public onDelete: ( val: V, key: string ) => void;

    /**
     * Values will be stored in this map with id fields of the key.
     */
    protected values: { [ key: string ]: V } = {};

    /**
     * Returns a boolean indicating whether the value currently exists or not.
     */
    public has( key: string ): boolean {
        return this.values.hasOwnProperty( key );
    }

    /**
     * Returns the item for the given key or null if it's not available.
     */
    public get( key: string ): V {
        if ( this.has( key )) {
            return this.values[key];
        }
        return null;
    }

    /**
     * Returns all the values
     */
    public getAll(): { [ key: string ]: V } {
        return this.values;
    }

    /**
     * Creates/Updates the value for the given key and stores the value.
     */
    public set( key: string, data: D ): V {
        if ( this.values[key]) {
            return this.values[key] = this.onUpdate( this.values[key], key, data );
        } else {
            return this.values[key] = this.onCreate( key, data );
        }
    }

    /**
     * Deletes a value from
     */
    public delete( key: string ) {
        if ( this.has( key )) {
            this.onDelete( this.values[key], key );
            delete this.values[key];
        }
    }

    /**
     * Removes all stored items from the map. This will not call any
     * callback functions. Use `map.reset([])` to also use callbacks.
     */
    public clear() {
        this.values = {};
    }

    /**
     * Resets resets stored items to match the given list of keys.
     */
    public reset( dataset: { [ key: string ]: D }): void {
        const values: { [ key: string ]: V } = {};
        // process inserted/updated items
        // will remove them from this.items
        for ( const key in dataset ) {
            if ( dataset.hasOwnProperty( key )) {
                const data = dataset[key];
                if ( this.has( key )) {
                    const val = this.values[key];
                    values[key] = this.onUpdate( val, key, data );
                } else {
                    values[key] = this.onCreate( key, data );
                }
                delete this.values[key];
            }
        }
        // handle removed items
        for ( const key in this.values ) {
            if ( this.values.hasOwnProperty( key )) {
                const val = this.values[key];
                this.onDelete( val, key );
            }
        }
        this.values = values;
    }
}
