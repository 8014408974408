<div class="desktop-auth-window-container modal-window-container fx-cover fx-center-all" #container>
    <div class="modal-window-inner" #window>
        <div class="modal-window-content">
            <div class="desktop-auth-content">
                <img class="fx-margin-bottom-30" src="./assets/images/logo-on-white-200X80.svg" width="150">
                <h1 class="fx-margin-bottom-25" *ngIf="!waitingForLogin">{{ translate.get( 'DESKTOP_SIGNIN.LABELS.SIGN_IN_TO_CREATELY' ) | async }}</h1>
                <h1 class="fx-margin-bottom-25" *ngIf="waitingForLogin">{{ translate.get( 'DESKTOP_SIGNIN.LABELS.WAITING_FOR_USER_MESSAGE' )  | async }}</h1>
                <p class="nu-text-primary fx-margin-bottom-20" *ngIf="waitingForLogin">{{ translate.get( 'DESKTOP_SIGNIN.LABELS.SIGN_IN_MESSAGE' )  | async }}</p>
                <loading-bubbles color="secondary" *ngIf="waitingForLogin"></loading-bubbles>
                <div class="fx-margin-bottom-10" *ngIf="otiExpired"><span class="crimson nu-text-primary">{{ translate.get( 'DESKTOP_SIGNIN.LABELS.SIGN_IN_EXPIRED_MESSAGE' )  | async }}</span></div>
                <button class="nu-btn-large primary" (click)="waitForLogin()" *ngIf="!waitingForLogin">{{ translate.get( 'DESKTOP_SIGNIN.BUTTONS.SIGN_IN' )  | async }}</button>
            </div>
            <div class="desktop-auth-image">
                <img src="./assets/images/desktop/desktop-signin-bg-diagram.svg">
            </div>
        </div>
    </div>
</div>