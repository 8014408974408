import { MultiSelectionInteractionState, SelectionInteractionState } from './../../../base/base-states';
import { Injectable } from '@angular/core';
import { AbstractCommand, Command, StateService } from 'flux-core';

/**
 * CheckInteractionState
 * Checks if the command should be continued or aborted depending on the
 * interaction sate. Interaction state can be shape selection interactions ( move / scale/ rotate shapes )
 * or multi selection ( press move on grid canvas to multi select shapes )
 *
 * When an interaction is started this command throws and error
 * to abort the command
 */
@Injectable()
@Command()
export class CheckInteractionState extends AbstractCommand {

    /**
     * Inject plan perm manager.
     */
    constructor(
        protected state: StateService<any, any>,
    ) {
        super() /* istanbul ignore next*/;
    }

    public execute() {
        const msi = this.state.get( 'MultiSelectionInteractionState' );
        const si = this.state.get( 'SelectionInteractionState' );
        if ( msi === MultiSelectionInteractionState.Started || si === SelectionInteractionState.Started ) {
            throw new Error( 'A canvas interaction is in progress, Try again after it\'s stopped.' );
        }
        return true;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( CheckInteractionState, 'name', {
    value: 'CheckInteractionState',
});
