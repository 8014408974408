import { Injectable } from '@angular/core';
import { CommandInterfaces, Command, CommandError, StateService } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { of, Observable } from 'rxjs';
import { DataStore } from 'flux-store';
import { map, take } from 'rxjs/operators';
import { DiagramInfoModel } from '../../diagram/model/diagram-info.mdl';

/**
 * This is the command to get a list of diagrams that belongs to
 * a particular project. Responds with a list of {@link DiagramInfoModel}
 * objects with the necessary data.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetProjectDiagrams extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {}; // The projectId should be the resource id
    }

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor( protected stateService: StateService<any, any>,
                 protected dataStore: DataStore ) {
    super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 3;
    }

    public execute(): boolean {
        return true;
    }

    public executeResult( response: any ): Observable<any> {
        const projectDiagrams = this.stateService.get( 'ProjectDiagrams' );
        projectDiagrams[ this.resourceId ] = response.projectDiagrams ;
        this.stateService.set( 'ProjectDiagrams', projectDiagrams );
        return of( true );
    }

    /**
     * Retrieve diagrams from cache and filter with the current project id.
     */
    public executeNetworkOffline(): Observable<any> {
        return this.dataStore.getModelStore( DiagramInfoModel ).find({}).pipe(
            take( 1 ),
            map(( diagrams: any ) => {
                diagrams = diagrams.filter( diagram => diagram.project === this.resourceId );
                return { projectDiagrams: diagrams };
            }),
        );
    }

    /**
     * If this command encounters an error, throw the error so invoker may
     * handle it as needed.
     * FIXME: This is a hack untill the proton errors are handled
     * by the initialization sequence.
     */
    public onError( error: CommandError ) {
        throw ( error );
    }

}

Object.defineProperty( GetProjectDiagrams, 'name', {
    value: 'GetProjectDiagrams',
});
