import { Command, StateService, CommandInterfaces } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { DataStore } from 'flux-store';
import { PresentationLocator } from '../presentation-locator.svc';
import { tap, take } from 'rxjs/operators';
import { EMPTY, Observable, concat, of } from 'rxjs';
import { UserLocator } from 'flux-user';
import { PresentationModel } from '../model/presentation.mdl';
import { PresentingService } from '../presenting.svc';

@Injectable()
@Command()
/**
 * This command will update a presentation. Receives the presentationId and the updated presentation model
 */
export class UpdatePresentation extends AbstractMessageCommand {
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    public data: {
        presentation: PresentationModel,
        presentationId: string,
    };

    constructor(
        protected userLocator: UserLocator,
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
        protected pl: PresentationLocator,
        protected presentingService: PresentingService,
    ) {
        super()/* istanbul ignore next */;
    }

    public prepareData () {
        if ( this.data.presentation ) {
            this.previousData = {};
            return this.dataStore.find( PresentationModel, { id: this.data.presentationId }).pipe(
                take( 1 ),
                tap( c => this.previousData.presentation = c ),
            );
        }
    }

    public execute() {
        this.resourceId = this.state.get( 'CurrentProject' );
        if ( this.data.presentation ) {
            return this.dataStore.update(
                PresentationModel, { id: this.data.presentationId },
                this.data.presentation,
            );
        }
    }

    public executeResult( response: any ) {
        if ( response.presentation ) {
            return concat(
                this.dataStore.update(
                    PresentationModel, { id: response.presentation.id },
                    { $set: response.presentation },
                ),
                this.presentingService.reloadSlide( response.presentation.id ),
            );
        }
        return EMPTY;
    }


    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
     public revert(): Observable<any> {
        if ( this.data && this.data.presentation ) {
            return this.dataStore.update( PresentationModel, { id: this.data.presentationId },
                { $set: this.previousData.presentation });
        }
        return of();
    }

}

Object.defineProperty( UpdatePresentation, 'name', {
    value: 'UpdatePresentation',
});
