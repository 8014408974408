import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'flux-core';

@Component({
    selector: 'not-found-in-region',
    templateUrl: './not-found-in-region.cmp.html',
    styleUrls: [ './fullscreen-message.scss' ],
})
export class NotFoundInRegion implements OnInit {
    constructor() { }

    get location() {
        return document.location;
    }

    public correctAppUrl() {
        const region = window.gravity.client.getCurrentUserRegion();
        if ( region && region !== 'us' ) {
            return AppConfig.get( `APP_${region.toUpperCase()}_URL` );
        }

        return AppConfig.get( 'APP_US_URL' );
    }

    ngOnInit() { }
}
