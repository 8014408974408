import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';

/**
 * This is the command to fetch team name for given team Ids.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetTeamNamesByIds extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor(
        protected dataStore: DataStore,
        protected modelSubManager: ModelSubscriptionManager,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute(): any {
        return true;
    }
}

Object.defineProperty( GetTeamNamesByIds, 'name', {
    value: 'GetTeamNamesByIds',
});
