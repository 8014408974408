import { UserPermModel } from '../model/user-perm.mdl';
import { Injectable } from '@angular/core';

/**
 * An interface which helps to check plan permission usage.
 */
export interface IPlanPermHandler {
    /**
     * Checks the usage of the permission.
     */
    check( userPermModel: UserPermModel, permData?: any );
}

/**
 * A general plan permission handler to check permissions which are validated using
 * allowed state, usaedCount and maxUsage.
 */
@Injectable()
export class GeneralPlanPermHandler implements IPlanPermHandler {

    /**
     * Checks if the permission is allowed to use by the user and it is not exceed
     * the maxUsage limit of the permission.
     * @param userPermModel User's permission data.
     * @param permData Any supporting data to evaluate the permission.
     * ( `permData` Not used in general perm handler but can be used in any other extending handlers )
     */
    public check( userPermModel: UserPermModel, permData?: any ) {
        if ( userPermModel.allowed ) {
            if ( userPermModel.maxUsage < 0 ) {
                return true;
            }
            return this.checkUsageExceed( userPermModel );
        }
        return false;
    }

    /**
     * Checks if the user exceed allowed maxUsage limit using "usedCount" property of
     * the permission.
     * @param userPermModel User's permission data.
     */
    protected checkUsageExceed( userPermModel: UserPermModel ) {
        if ( userPermModel.maxUsage > 0 && userPermModel.usedCount < userPermModel.maxUsage ) {
            return true;
        }
        return false;
    }
}
