import { CommandInterfaces, Command } from 'flux-core';
import { AbstractMessageCommand } from 'flux-connection';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataStore } from 'flux-store';
import { ProjectModel } from '../model/project.mdl';
import { ProjectModelStore } from '../../storage/project-model-store';
import { map, take } from 'rxjs/operators';

/**
 * This is the command to get all projects for a given user.
 * Responds with a list of {@link ProjectModel} which is accessible
 * to the user.
 *
 * This extends {@link AbstractCommand} to send a request to the server.
 */
@Injectable()
@Command()
export class GetUserProjects extends AbstractMessageCommand {

    public static get dataDefinition(): {} {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected dataStore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 2;
    }

    public execute(): boolean {
        return true;
    }

    /**
     * Executes once the response is retrieved.
     * Removes all projects that are inacessible to the user from the storage.
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.storageFilterProjects( response.projectIds );
    }

    /**
     * Retrieve the projects from the cached store while offline.
     */
    public executeNetworkOffline(): Observable<any> {
        const modelStore = this.dataStore.getModelStore( ProjectModel ) as ProjectModelStore;
        return modelStore.find({}).pipe(
            take( 1 ),
            map( projects => ({ projectIds: projects.map( p => p.id ) })),
        );
    }
}

Object.defineProperty( GetUserProjects, 'name', {
    value: 'GetUserProjects',
});
