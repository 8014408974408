import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import {  EMPTY, Observable, of } from 'rxjs';
import { TaskModel } from 'flux-diagram';
import { take, tap } from 'rxjs/operators';

@Injectable()
@Command()
/**
 * This command will align the task for the user
 * - check if the task exists for the given shape/context
 * - update the existing task with new assignes. if no assignees, delete the task
 * - update the tasks status/ owner details
 */
export class UpdateTask extends AbstractMessageCommand {

    public static get implements(): CommandInterfaces[] {
        return [
            'IMessageCommand',
            'IDiagramCommand',
            'ICollabCommand',
        ];
    }

    public data: {
        task: {
            id: string,
            title: string,
            diagramId: string,
            shapeId: string,
            entityId?: string,
            edataId?: string,
            creatorId: string,
            owners: any,
            dueDate: number,
            estimateHrs: number,
            estimatePts: number,
            isDelete: boolean,
            status: any,
        },
        taskId: string;
    };

    private updated = false;

    constructor(
        protected dataStore: DataStore,
        protected state: StateService<any, any>,
    ) {
        super()/* istanbul ignore next */;
    }

    public get version(): number {
        return 1;
    }

    public prepareData() {
        if ( this.data.task ) {
            this.previousData = {};
            return this.dataStore.findOne( TaskModel, { id: this.data.taskId }).pipe(
                take( 1 ),
                tap( c => this.previousData.task = c ),
            );
        }
    }


    public execute() {
        if ( this.data.task ) {
            return this.dataStore.update(
                TaskModel, { id: this.data.taskId },
                this.data.task,
            ).pipe(
                tap(() => {
                    this.updated = true;
                }),
            );

        }
    }

    public executeResult() {
        const task = this.resultData.task;
        const userIds = task.roles.map( r => r.userId );
        const currUserId = this.state.get( 'CurrentUser' );
        if ( !userIds.includes( currUserId )) {
            if ( task.diagramId !== this.state.get( 'CurrentDiagram' ) && !task.entityId ) {
                return this.dataStore.remove(
                    TaskModel, { id: task.id },
                );
            }
        }
        if ( !this.updated ) { // a collab change
            // inserting instead of updating since it works as upsert
            return this.dataStore.insert(
                TaskModel, task,
            );
        }
        return EMPTY;
    }

    /**
     * This function roll back the changes from the datastore upon the
     * failure of the execution.
     */
    public revert(): Observable<any> {
        if ( this.data && this.data.task ) {
            return this.dataStore.update( TaskModel, { id: this.data.taskId },
                { $set: this.previousData.task });
        }
        return of();
    }
}

Object.defineProperty( UpdateTask, 'name', {
    value: 'UpdateTask',
});
