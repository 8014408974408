import { Injectable, Injector } from '@angular/core';
import { filter } from 'rxjs/operators';
import { TeamSettingModel, TeamSettingUserAccess } from './model/team-setting.mdl';
import { UserLocator } from '../user/user-locator.svc';
import { TeamUserRole } from './model/team.mdl';

/**
 * A Team setting manager which has user's team settings and helps to check the
 * setting availability of the user for user's team plan.
 *
 * @author Sajeeva
 * @since 2023-07-12
 */

@Injectable()
export class TeamSettingManager {

    /**
     * Type of the user's plan.
     */
    protected planType: string;

    /**
     * User's team user role
     */
    protected teamUserRole: TeamUserRole;

    /**
     * User's team settings.
     */
    protected teamSettings: TeamSettingModel[] = [];

    /**
     * If the user has a team plan.
     */
    protected isTeamUser: boolean = false;

    constructor(
        protected userLocator: UserLocator,
        protected injector: Injector,
    ) {
        this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan && !!user.team ),
        ).subscribe( user => {
            this.planType = user.plan.type;
            this.teamSettings = user.team.teamSettings;
            this.teamUserRole = user.team.teamUserRole;
            this.isTeamUser = !!user.team?.id;
        });
    }

    /**
     * Checks the user's access to a specific team setting.
     *
     * @param settingId The ID of the team setting to check access for.
     * @returns A boolean value indicating whether the user has access to the team setting.
     * If the user's plan type is not an Enterprise plan, it returns true.
     * If the team setting exists, it checks the user's access level and returns true
     * if the user has access based on their role.
     * Otherwise, it returns false.
     */
    public check( settingId: string, returnDefault: boolean = true ) {

        // Always return true for personal plan users.
        if ( !this.isTeamUser ) {
            return true;
        }
        const currentTeamSetting = this.teamSettings?.find( setting =>
                    setting.settingId.toLowerCase() === settingId.toLowerCase(),
            );

        if ( currentTeamSetting ) {
            switch ( currentTeamSetting.userAccess ) {
                case TeamSettingUserAccess.TEAM:
                    return true;
                case TeamSettingUserAccess.ADMIN:
                    return ( this.teamUserRole === TeamUserRole.ADMIN )
                            || ( this.teamUserRole === TeamUserRole.SUPERADMIN );
                case TeamSettingUserAccess.SUPERADMIN:
                    return this.teamUserRole === TeamUserRole.SUPERADMIN;
                default:
                    return false;
            }
        }

        return returnDefault;
    }

}
