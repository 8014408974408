import { Lexer, createToken, CstParser, tokenMatcher } from 'chevrotain';
import { ITextParser } from 'flux-definition';
import { uniq, filter, startsWith, dropRight, last } from 'lodash';

const visibilityOperator = createToken({
    name: 'visibilityOperator',
    pattern: Lexer.NA,
});

const primitiveType = createToken({
    name: 'primitiveType',
    pattern: Lexer.NA,
});

const identifier = createToken({ name: 'identifier', pattern: /[a-zA-Z]\w*/ });
const plusToken = createToken({ name: 'plus', pattern: '+', categories: visibilityOperator });
const minusToken = createToken({ name: 'minus', pattern: '-', categories: visibilityOperator });
const hashToken = createToken({ name: 'hash', pattern: '#', categories: visibilityOperator });
const colon = createToken({ name: 'colon', pattern: ':' });
const openBracket = createToken({ name: 'leftBracket', pattern: '(' });
const closeBracket = createToken({ name: 'rightBracket', pattern: ')' });
const openSBracket = createToken({ name: 'leftSBracket', pattern: '[' });
const closeSBracket = createToken({ name: 'rightSBracket', pattern: ']' });
const pointer = createToken({ name: 'pointer', pattern: '*' });
const comma = createToken({ name: 'comma', pattern: ',' });

const intToken = createToken({ name: 'int', pattern: 'int', categories: primitiveType, longer_alt: identifier });
const booleanToken = createToken({
    name: 'boolean', pattern: 'boolean', categories: primitiveType, longer_alt: identifier });
const stringToken = createToken({
    name: 'String', pattern: 'String', categories: primitiveType, longer_alt: identifier });
const stringToken2 = createToken({
    name: 'String', pattern: 'string', categories: primitiveType, longer_alt: identifier });
const whiteSpace = createToken({
    name: 'whiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED,
});

const allTokens = [
    whiteSpace,
    plusToken,
    minusToken,
    hashToken,
    colon,
    openBracket,
    closeBracket,
    openSBracket,
    closeSBracket,
    pointer,
    comma,
    intToken,
    booleanToken,
    stringToken,
    stringToken2,
    visibilityOperator,
    primitiveType,
    // The identifier must appear after the keywords because all keywords are valid identifiers.
    identifier,
];

class StatementParser extends CstParser {
    constructor() {
        super( allTokens );
        const $ = this;

        $.RULE( 'statement', () => {
            $.OPTION(() => {
                $.CONSUME( visibilityOperator );
            });
            $.CONSUME( identifier );
            $.CONSUME( openBracket );
            $.SUBRULE(( $ as any ).paramList );
            $.CONSUME( closeBracket );
            $.OPTION1(() => {
                $.SUBRULE(( $ as any ).returnType );
            });
        });

        $.RULE( 'paramList', () => {
            $.MANY_SEP({
                SEP: comma,
                DEF: () => {
                    $.SUBRULE(( $ as any ).param );
                },
            });
        });

        $.RULE( 'param', () => {
            $.CONSUME( identifier );
            $.OPTION1(() => {
                $.SUBRULE(( $ as any ).returnType );
            });
        });

        $.RULE( 'returnType', () => {
            $.CONSUME( colon );
            $.SUBRULE(( $ as any ).CompositeType );
        });

        $.RULE( 'CompositeType', () => {
            $.OR([
                { ALT: () => $.CONSUME( primitiveType ) },
                { ALT: () => $.CONSUME( identifier ) },
            ]);
            $.OPTION(() => {
                $.SUBRULE(( $ as any ).typeModifier );
            });
        });

        $.RULE( 'typeModifier', () => {
            $.OR([
                { ALT: () => $.SUBRULE(( $ as any ).arrayModifier ) },
                { ALT: () => $.CONSUME( pointer ) },
            ]);
        });

        $.RULE( 'arrayModifier', () => {
            $.CONSUME( openSBracket );
            $.CONSUME( closeSBracket );
        });

        this.performSelfAnalysis();
    }
}

const parserInstance = new StatementParser();

const baseStatementVisitor = parserInstance.getBaseCstVisitorConstructor();

class StatementVisitor extends baseStatementVisitor {
    constructor() {
        super();
        this.validateVisitor();
    }

    statement( ctx ) {
        return {
            name: ctx.identifier[0].image,
            params: this.visit( ctx.paramList[0]),
            visibility: ctx.visibilityOperator ? ctx.visibilityOperator[0].image : undefined,
            returnType: ctx.returnType ? this.visit( ctx.returnType[0]) : undefined,
        };
    }

    paramList( ctx ) {
        return ctx.param ? ctx.param.map( param => this.visit( param )) : [];
    }

    returnType( ctx ) {
        return this.visit( ctx.CompositeType[0]);
    }

    CompositeType( ctx ) {
        let type = ctx[ctx.identifier ? 'identifier' : 'primitiveType'][0].image;
        if ( ctx.typeModifier ) {
            type += this.visit( ctx.typeModifier );
        }
        return type;
    }

    typeModifier( ctx ) {
        return ctx.arrayModifier ? this.visit( ctx.arrayModifier[0]) : ctx.pointer[0].image;
    }

    arrayModifier( ctx ) {
        return '[]';
    }

    param( ctx ) {
        return {
            name: ctx.identifier[0].image,
            type: ctx.returnType ? this.visit( ctx.returnType[0]) : undefined,
        };
    }
}

interface IParam {
    name: string;
    type?: string;
}

export interface IOperation {
    name: string;
    params: IParam[];
    returnType?: string;
    visibility?: string;
}

class UMLClassFunctionSectionParser implements ITextParser<IOperation> {
    static id = 'creately.umlclass.operation';

    public static get instance(): UMLClassFunctionSectionParser {
        return this._instance;
    }
    private static _instance = new UMLClassFunctionSectionParser();

    protected selectLexer = new Lexer( allTokens );

    parse( input: string ) {
        const lexResult = this.selectLexer.tokenize( input );

        const toAstVisitorInstance = new StatementVisitor();

        parserInstance.input = lexResult.tokens;

        // No semantic actions so this won't return anything yet.
        const cst = ( parserInstance as any ).statement();
        const ast = toAstVisitorInstance.visit( cst );

        return {
            ast,
            lexResult,
            parseErrors: parserInstance.errors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        const lexResult = this.selectLexer.tokenize( text.split( '\n' ).pop());

        if ( lexResult.errors.length > 0 ) {
            return {
                suggestions: [],
                searchTerm: '',
            };
        }

        const lastInputToken = last( lexResult.tokens );
        let partialSuggestionMode = false;
        let assistanceTokenVector = lexResult.tokens;

        // we have requested assistance while inside a Keyword or Identifier
        if (
            lastInputToken !== undefined &&
            ( tokenMatcher( lastInputToken, identifier ) ||
                tokenMatcher( lastInputToken, primitiveType )) &&
            /\w/.test( text[text.length - 1])
        ) {
            assistanceTokenVector = dropRight( assistanceTokenVector );
            partialSuggestionMode = true;
        }

        const syntacticSuggestions = parserInstance.computeContentAssist(
            'statement',
            assistanceTokenVector,
        );

        let finalSuggestions = [];

        for ( let i = 0; i < syntacticSuggestions.length; i++ ) {
            const currSyntaxSuggestion = syntacticSuggestions[i];
            const currTokenType = currSyntaxSuggestion.nextTokenType;

            // easy case where a keyword is suggested.
            if ( primitiveType === currTokenType ) {
                finalSuggestions = finalSuggestions.concat([ 'int', 'boolean', 'string' ]);
            } else if ( currTokenType === identifier ) {
                const currRuleStack = currSyntaxSuggestion.ruleStack;
                if ( currRuleStack.length > 2 && currRuleStack[currRuleStack.length - 1] === 'CompositeType'
                    && context.projectEDataModels ) {
                    let entities = [];
                    Object.values( context.projectEDataModels ).forEach(( model: any ) => {
                        entities = entities.concat( Object.values( model.entities ));
                    });
                    finalSuggestions = finalSuggestions.concat(
                        entities.filter( e => e.eDefId === 'creately.entity.uml.class' && !!e.data )
                        .map( e => e.data.name ),
                    );
                }
            }
        }

        // throw away any suggestion that is not a suffix of the last partialToken.
        if ( partialSuggestionMode ) {
            finalSuggestions = filter( finalSuggestions, currSuggestion =>
                startsWith( currSuggestion.toLowerCase(), lastInputToken.image.toLowerCase()));
        }

        return {
            suggestions: uniq( finalSuggestions ),
            searchTerm: partialSuggestionMode ? lastInputToken.image : '',
        };
    }
}

export default UMLClassFunctionSectionParser;
