import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AppConfig, CommandInterfaces, RequestMethod } from 'flux-core';
import { kebabCase } from 'lodash';
import { Observable } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { Message } from '../../message/message.mdl';
import { ICommandResult, SendExecutionStep } from './exec-step-send';

/**
 * SendHttpExecutionStep is a command execution step which will send
 * the command data to the server via HTTP.
 */
export class SendHttpExecutionStep extends SendExecutionStep {
    /**
     * Implement the sendMessage abstract method.
     */
    protected sendMessage( message: Message ): Observable<ICommandResult> {
        const request = this.createRequestObject( message );
        return this.fetchAndDeserialize( request );
    }

    /**
     * createRequestObject creates a fetch request object from the command
     * instance. Assumes the command instance is an AbstractHttpCommand.
     */
    private createRequestObject( message: Message ): HttpRequest<any> {
        const url = this.createRequestUrl( message );
        const body = this.createRequestBody( message );
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json',
            }),
        };
        return new HttpRequest<any>( RequestMethod.Post, url, body, options );
    }

    /**
     * Creates the request url using the command name.
     */
    private createRequestUrl( message: Message ): string {
        const baseUrl = AppConfig.get( 'REST_API_BASE_URL' );
        const msgProto = AppConfig.get( 'MESSAGE_PROTOCOL_VERSION' );
        const resourceType = this.getResourceType();
        const commandName = kebabCase( message.message );
        return `${baseUrl}/${msgProto}/1/${resourceType}/${commandName}`;
    }

    /**
     * Get the command's resource type.
     */
    private getResourceType(): string {
        const commandClass = this.getCommandClass();
        const interfaces = commandClass.implements as CommandInterfaces[];
        if ( interfaces.indexOf( 'IDiagramCommand' ) >= 0 ) {
            return 'diagram';
        }
        if ( interfaces.indexOf( 'IProjectCommand' ) >= 0 ) {
            return 'project';
        }
        if ( interfaces.indexOf( 'IUserCommand' ) >= 0 ) {
            return 'user';
        }
        throw new Error( `Please set the resource type for command ${commandClass.name}` );
    }

    /**
     * Creates the request body.
     */
    private createRequestBody( message: Message ): string {
        const msg = {
            auth: message.authToken,
            pld: message.payload,
            rid: message.resourceId,
            mver: message.messageVersion,
            ctx: message.contextData,
        };
        // FIXME: fix on neutrino
        if ( msg.mver === 1 ) {
            delete msg.mver;
        }
        return JSON.stringify( msg );
    }

    /**
     * fetchAndDeserialize uses window.fetch to fetch resources from an http url.
     * It also will deserialize the result if a valid resultType is configured.
     * @param request: the request object to use with window.fetch
     */
    private fetchAndDeserialize( req: HttpRequest<any> ): Observable<any> {
        const http = this.injector.get( HttpClient );
        return http.request( req.method, req.url, req ).pipe(
            last(),
            map(( res: any ) => res.result ),
        );
    }
}

Object.defineProperty( SendHttpExecutionStep, 'name', {
    value: 'SendHttpExecutionStep',
});
