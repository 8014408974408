import { Injectable } from '@angular/core';
import { Command, AbstractCommand, StateService } from 'flux-core';
import { ImportedFile, FileImportTypes } from '../../../framework/file/imported-file';
import { FileReceiver } from '../import/file-receiver.svc';
import { Stage } from '@creately/createjs-module';
import { Selection } from '../../../editor/selection/selection';

/**
 * PasteItems
 * This command used for adding the copied item (i.e images) onto canvas.
 * Converts the clipboard items into @ImportedFiles and call
 * the @FileReceiver , which will add the items onto canvas.
 *
 * @author  Vinoch
 * @since   2019-07-08
 *
 */
@Injectable()
@Command()
export class PasteItems extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: any;

    constructor( protected fileReceiver: FileReceiver,
                 protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Prepare command data and call the receiver.
     */
    public prepareData(): void {
        // NOTE: Blocking the paste command on the canvas while the shapes are on the move.
        // ** Move iteration includes rotation and scale also.
        const interactionCanvas: Stage = this.state.get( 'InteractionCanvas' );
        const selection: Selection = <Selection> interactionCanvas.getChildByName( 'Selection' );
        if ( !selection || !selection.interactionInProgress ) {
            const importedFiles: ImportedFile[] = [];
            const items = this.data;
            for ( let j = 0; j < items.length; j++ ) {
                if ( items[j]) {
                    const importedFile = new ImportedFile( items[j]);
                    importedFile.importType = FileImportTypes.PasteEvent;
                    importedFiles.push( importedFile );
                }
            }

            if ( !importedFiles || importedFiles.length < 1 ) {
                return;
            }

            // This command triggers the FileReceiver 'receiveFiles' command
            // by passing the files which needs to be imported onto Canvas.
            // Calling a command from another command is against the initial
            // design.
            this.fileReceiver.receiveFiles( importedFiles, { upload: true }).subscribe();
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( PasteItems, 'name', {
    value: 'PasteItems',
});
