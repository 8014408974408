import * as Snap from '@creately/snapsvg';
import { Rectangle } from './rectangle';

/**
 * Shape
 * Is a representation of the shape view. This wraps a Snap.Element and a HTMLElement
 * to provide the view capabilities of the shape.
 *
 * @author hiraash
 * @since 2016-06-14
 */
export class Shape {

    /**
     * Holds the default bounds rectable of this shape
     */
    protected _defaultBounds: Rectangle;


    /**
     * Holds the parent svg element of the shape
     */
    protected _parent: Element;

    constructor( protected element: Snap.Element ) {
        let parentRect;
        if ( this.parent ) {
            parentRect = this.parent.getBoundingClientRect();
        }
        this._defaultBounds = this.getBounds( parentRect );
    }
    /**
     * The default size of the shape when the
     * shape has not been transformed.
     */
    public get defaultBounds(): Rectangle {
        return this._defaultBounds;
    }

    /**
     * Id of the shape. The KobjectId
     */
    public get id(): string {
        return this.element.node.id;
    }

    /**
     * Return the parent element of the shape;
     */
    public get parent(): Element {
        let parent = this.element.node.parentElement;
        while ( parent ) {
            if ( parent.nodeName === 'svg' ) {
                this._parent = parent;
                break;
            }
            parent = parent.parentElement;
        }
        return this._parent;
    }

    /**
     * The bounds of the shape at given moment
     * with global transformation applied within the
     * viewport coordinate space (screeen).
     * @param base  Base Rectangle to get the bounds relative to
     */
    public getBounds( base?: ClientRect ): Rectangle {
        const rect = Rectangle.fromClientRect( this.element.node.getBoundingClientRect());
        if ( base ) {
            rect.left -= base.left;
            rect.top -= base.top;
        }
        return rect;
    }

    /**
     * Indicates if given viewport (screen) cordinates are within
     * the shape's bounds. Does not consider actual image.
     * @param x The x cordinate relative to screen
     * @param y The y cordinate relative to screen
     */
    public hitTest( x: number, y: number ): boolean {
        const bound: ClientRect = this.getBounds();
        return ( x > bound.left && x < bound.right && y > bound.top && y < bound.bottom );
    }

}
