import { AbstractShapeLibrary } from './abstract-shape-library';

/**
 * ShapeLibrary
 * Definition of the static library.
 * This extends the AbstractShapeLibrary.
 *
 * @author  gobiga
 * @since   2017-11-14
 */

export class ShapeLibrary extends AbstractShapeLibrary {

    /**
     * Identifier for the static library.
     */
    public set libId( id: string ) {
        this.id = id;
    }

    public get libId(): string {
        return this.id;
    }

}
