import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will be reset the current embed url token and
 * will create a new token and this will fetch the token and will return it.
 * This command should use when user need to disable or reset the current shared embed diagram image urls.
 * This command need diagram id to reset embed ur.
 * @author  Shashik
 * @since   2020-07-09
 */
@Injectable()
@Command()
export class ResetEmbedImageToken extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor( protected auth: NucleusAuthentication ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': this.auth.token,
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

  /**
   * Command execute
   */
    public execute(): Observable<any> {
        return empty();
    }

    public prepareData(): any {
        const query = `mutation{
            documentResetEmbed(id:"${this.data.resourceId}")
        }`;
        this.data.query =  query ;
    }
}

Object.defineProperty( ResetEmbedImageToken, 'name', {
    value: 'ResetEmbedImageToken',
});
