<div class="datasource-panel-container" [hidden]="( searchResult | async )?.length > 0">
    <div class="container">
        <div class="row">
            <mat-expansion-panel class="custom-search-expansion-panel" [ngStyle]="{'top': top }" id="custom-search-panel" [expanded]="true">
                <div class="header fx-margin-bottom-15">
                    <span class="title" translate>EDATA_PANEL.ADVANCED_FILTER</span>
                    <svg (click)="cancel()" class="nu-icon nu-icon-small nu-ic-close">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                    </svg>
                </div>
                <perfect-scrollbar class="perfect-scroll">
                <div>
                    <div class="enitiy-search-item-type">
                        <label class="medium-text" translate>EDATA_PANEL.OBJECT_TYPE</label>
                            <input class="form-control-input with-icon" type="text" matInput placeholder="Select Object Type" [formControl]="entityTypeSelectController"
                                [matAutocomplete]="dataTypeAuto" required>
                            <mat-autocomplete #dataTypeAuto="matAutocomplete" (optionSelected)="selectDataTypeSelector($event)">
                                <mat-option *ngFor="let option of dataTypesListObservable | async" [value]="option.name">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                    </div> 
                </div>
                <div>
                    <div class="entity-search-form">
                        <label class="medium-text" translate>EDATA_PANEL.WHICH_HAS</label>
                        <div #searchForms></div>
                    </div>
                </div>
                <div>
                    <div class="align-self-center button-raw">
                        <div class="search-button-row">
                            <button class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                                mat-stroked-button (click)="addAnd()" translate>EDATA_PANEL.AND</button>
                            <div class="medium-text"> | </div>
                            <button class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                                mat-stroked-button (click)="addOr()" translate>EDATA_PANEL.OR</button>
                        </div>
                    </div>
                    <label class="medium-text" translate>EDATA_PANEL.SORT_BY</label>
                    <div class="search-button-row">
                        <input class="form-control-input sortBy fx-hidden" type="text" matInput placeholder="SortBy" [formControl]="sortDataItemController"
                        [matAutocomplete]="sortByAuto" required>
                        <mat-autocomplete #sortByAuto="matAutocomplete" [displayWith]="dataItemListDisplayHelper">
                            <mat-option *ngFor="let option of sortDataItemListObservable | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-select class="form-control-input" [formControl]="sortByController" placeholder="None/ default">
                            <mat-option *ngFor="let sortBy of sortByList" [value]="sortBy.value">
                            {{sortBy.viewValue}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="align-self-center button-raw fx-margin-top-10">
                        <div class="search-button-row">
                            <button ngClass="{ 'disabled': !entityTypeSelectController.value || !this.instanceList[0].instance.searchForm.value.dataItemLabel || !this.instanceList[0].instance.searchForm.value.rules || !this.instanceList[0].instance.searchForm.value.value }"
                                [disabled]="!entityTypeSelectController.value || !this.instanceList[0].instance.searchForm.value.dataItemLabel || !this.instanceList[0].instance.searchForm.value.rules || !this.instanceList[0].instance.searchForm.value.value "
                                class="btn-medium btn-primary btn-submit fx-span-width fx-no-padding fx-margin-right-8"
                                mat-stroked-button (click)="onSubmit()" translate>EDATA_PANEL.FILTER</button>
                            <button class="btn-medium btn-secondary fx-span-width fx-no-padding fx-no-margin"
                                mat-stroked-button (click)="cancel()" translate>EDATA_PANEL.CANCEL</button>
                        </div>
                        <span *ngIf="displayEmptyResultsLabel | async" class="no-results" translate>
                            <svg class="nu-icon nu-icon-small nu-ic-close">
                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-solid"></use>
                            </svg>
                            EDATA_PANEL.NO_RESULTS_FOUND
                        </span>
                    </div>
                </div>
                </perfect-scrollbar>
            </mat-expansion-panel>
        </div>
    </div>
</div>
<!-- <div *ngIf="( searchResult | async )?.length > 0">
    <edata-search-results-panel [searchResult]="searchResult" [searchQuery]="searchQuery" [eDataModel]="eDataModel" (goBack)="emptyResult()"></edata-search-results-panel>
</div> -->