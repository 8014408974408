import { DataStore } from 'flux-store';
import { StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { DiagramInfoLocator } from '../../diagram/locator/diagram-info-locator';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';
import { DiagramInfoModel } from '../../diagram/model/diagram-info.mdl';

/**
 * A Role permission service which bypasses the storage and
 * reads all data through the state service.
 *
 * @author  Ramishka
 * @since   2019-07-19
 */
@Injectable()
export class RolePermService {

    constructor(
        protected dataStore: DataStore,
        protected stateService: StateService<any, any>,
        protected diagramInfoLocator: DiagramInfoLocator ) {}

    /**
     * Retrieves and returns the role of a user for a diagram.
     * @param diagramId Id of the diagram.
     * @param userId Id of the user.
     */
    public getUserRole( diagramId: string, userId: string ): Observable<CollaboratorType> {
        return this.getDiagram( diagramId ).pipe(
            switchMap(( diagram: DiagramInfoModel ) => {
                const collab = diagram.collabs.find( col => col.id === userId );
                return of( collab ? collab.role : null );
            }),
        );
    }

    /**
     * Retrieves and returns current user's role for a diagram.
     * @param diagramId Id of the diagram.
     */
    public getCurrentUserRole( diagramId: string ): Observable<CollaboratorType> {
        return this.getUserRole( diagramId, this.stateService.get( 'CurrentUser' ));
    }

    /**
     * Fetches the diagram model. This function attempts to retrieve the diagram model
     * from diagramInfoLocator which stores diagram model data in memory.
     * However, the diagramInfoLocator is currently only initialized
     * when the folder panel is opened at least once. Document info for the current project
     * is fetched at this point and stored in the info locator. If someone tries to use the
     * role perm service before this, diagram info wont be available. Therefore this function
     * tries to fetch diagram data from the storage in case in memory data is not available.
     *
     * The correct way to solve this issue is to initialize the info locator at start and ensuring
     * in memory data is available at all times. However Initializing this at diagram
     * load may freeze the app if the users projects have a large number of diagrams in them.
     * Until this performance isssue is fixed, this function will try to fallback to storage in
     * the event data is unavailable.
     * @param diagramId - diagram id to fetch info for
     * @return Diagram info model
     */
    protected getDiagram( diagramId: string ): Observable<DiagramInfoModel> {
        return this.diagramInfoLocator.getDiagram( diagramId ).pipe(
            take( 1 ),
            switchMap( diagram => {
                if ( !diagram ) {
                    return this.dataStore.findOne( DiagramInfoModel, { id: diagramId }).pipe(
                        take( 1 ),
                    );
                }
                return of( diagram );
            }),
        );
    }
}
