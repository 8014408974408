import { AbstractModel, DateFNS } from 'flux-core';
import { IPosition2D, IPoint2D } from 'flux-definition';

/**
 * Contains additional data attached to the comment. These data can be from Creately
 * or from a third party but curated by Creately (later). Not using a namespace
 * to keep things simple.
 */
export interface ICommentData {
    'reactions'?: { [userId: string]: string[] };
}

/**
 * The comment model represents a commend added on a diagram (or a shape).
 * Although the comment references a diagram, it is stored separate from it.
 *
 * Comments can be broken into these categories;
 *  - diagram comments: comments added on the diagram (at a specific point)
 *  - shape comments:   comments added on a shape (moves around with the shape)
 *  - reply comments:   comments which are replies to other comments.
 */
export class CommentModel extends AbstractModel {
    /**
     * The id of the diagram the comment belongs to.
     */
    public diagramId: string;

    /**
     * The id of the shape the comment is attached to (if any).
     * This property is only required for threads (top level comments).
     */
    public shapeId?: string;

    /**
     * The id of the thread this comment is attched to (if any).
     * This property is available on reply comments.
     */
    public parentId?: string;

    /**
     * The id of the user who creared the comment.
     */
    public userId: string;

    /**
     * Location of the comment relative to a shape. (without transformations).
     * This property is only required for shape comments which are threads.
     */
    public locOnShape?: IPosition2D;

    /**
     * Location of the comment relative to the diagram. (diagram coordinates).
     * This property is required for both diagram/shape comments which are threads.
     * NOTE: Although this is available for shape comments, it can have an outdated value.
     *       This property is updated only when the comment is created or modified.
     */
    public locOnDiagram: IPoint2D;

    /**
     * The timestamp when the comment was created.
     */
    public added: number;

    /**
     * The timestamp when the `comment` property was last changed. Only available after
     * the first change so it can be used to track whether a comment has been edited or not.
     */
    public edited?: number;

    /**
     * The timestamp when the `comment` is deleted by the commet owner. Only available after
     * the the comment is deleted so it can be used to track whether the comment is deleted.
     */
    public deleted?: number;

    /**
     * The timestamp when the comment was resolved if the comment is resolved.
     * This property is only required for threads (top level comments).
     */
    public resolved?: number;

    /**
     * The id of the user who resolved this comment if the comment is resolved.
     * This property is only required for threads (top level comments).
     */
    public resolvedBy?: string;

    /**
     * This property should be updated when there are any changes to the comment model.
     * NOTE: This property is different from `edited` and serves a different purpose.
     */
    public lastUpdated: number;

    /**
     * Tracks who has read the comment. Only tracks collaborators.
     */
    public seenBy: string[];

    /**
     * The comment text. The main payload of the comment.
     */
    public comment: string;

    /**
     * Contains additional data attached to the comment.
     */
    public data?: ICommentData;

    /**
     * Indicates whether the comment is deleted.
     */
    public isDeleted(): boolean {
        return !!this.deleted;
    }

    /**
     * Indicates whether the comment text is modified.
     */
    public isEdited(): boolean {
        return !!this.edited;
    }

    /**
     * Indicates whether the comment is added recently
     * or unread by the user.
     */
    public isNew( userId: string ): boolean {
        if ( this.isResolved() || this.userId === userId || ( this.seenBy && this.seenBy.includes( userId ))) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns the time difference between the current time
     * and comment added time.
     */
    public getTime(): string {
        return `${DateFNS.formatDistance( this.added, new Date().getTime())}`;
    }

    /**
     * Indicates whether the comment is resolved.
     */
    private isResolved(): boolean {
        return !!this.resolved;
    }
}

Object.defineProperty( CommentModel, 'name', {
    writable: true,
    value: 'CommentModel',
});
