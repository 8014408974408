import { Injectable } from '@angular/core';
import { FloatingToolbar } from './floating-toolbar.cmp';

/**
 * This service manages {@link FloatingToolbar} instances across the application.
 * There may be multiple floating toolbar instances used throughout the application
 * at a given time. The floating toolbar manager is aware of all such instances and
 * is able to provide other services with the correct toolbar instance whenever they
 * require it. Each floating toolbar has a unique id which other services may use to
 * pick the toolbar instance they need.
 *
 * A floating toolbar can be placed on a template using its selector. When placing the
 * toolbar in this manner a unique id for it must be defined. The toolbar instance will
 * be automatically registered with the toolbar manager undeer the specified id when it
 * instantiates, and unregistered from the manager when it is destroyed.
 *
 * To read more about the floating toolbar, refer to {@link FloatingToolbar}.
 * @since   2018-03-16
 * @author  Ramishka
 */
@Injectable()
export class FloatingToolbarManager {

    /**
     * This map holds the registered floating toolbar instances against their
     * ids.
     */
    protected toolbars: { [id: string]: FloatingToolbar };

    constructor() {
        this.toolbars = {};
    }

    /**
     * Registers a floating toolbar instance.
     * The toolbar instance which is passed into this method must
     * have a unique id set to its id field, as this is used as the
     * key to register the toolbar.
     * A registered toolbar instance can be accessed by external
     * services using the get method.
     * @param toolbar - toolbar instance
     */
    public register( toolbar: FloatingToolbar ) {
        if ( toolbar && toolbar.id ) {
            this.toolbars[toolbar.id] = toolbar;
        } else {
            throw new Error( 'The floating toolbar instance to be registered is not valid.' );
        }
    }

    /**
     * Unregisters a floating toolbar instance that was previously registered.
     * Any toolbar registered with the manager must be unregistered whenever
     * they are being destroyed.
     * @param id - id of the toolbar to be unregistered
     */
    public unregister ( id: string ) {
        delete this.toolbars [ id ];
    }

    /**
     * Returns a previously registered toolbar instance.
     * Any external service can use this method to fetch a toolbar
     * instance queried by its id.
     * @param id - id of the toolbar that needs to be retrieved.
     */
    public get( id: string ) {
        return this.toolbars[id];
    }
}
