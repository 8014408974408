import { Injectable } from '@angular/core';
import { AppConfig } from 'flux-core';
import { ContainerEnv } from 'flux-core';
import { Message } from './message.mdl';
import { MessageType } from './message-type';

/**
 * Message field values for MessageType.conn
 */
export enum ConnectionMessage {

    /**
     * Connection Initiation Message Value
     */
    init,

    /**
     * Connection Termination Message Value
     */
    end,
}

/**
 * MessageFactory
 *
 * This class is a temporary class to generate messages
 * for connection initiation and connection termination
 *
 * @author  mehdhi
 * @date    22-04-2016
 */
@Injectable()
export class MessageFactory {

    constructor( private container: ContainerEnv ) {}

    /**
     * This function creates and returns a connection initiation
     * message
     */
    public createInitHanshakeMessage(): Message {
        const handshakeMessage: Message = new Message(
            +AppConfig.messageProtocolVersion, MessageType.conn, ConnectionMessage[ ConnectionMessage.init ],
        );
        handshakeMessage.device = this.container.name;
        handshakeMessage.osName = this.container.os;
        handshakeMessage.application = AppConfig.appName;
        return handshakeMessage;
    }

    /**
     * This function creates and returns a connection termination
     * message
     */
    public createConnectionTerminationMessage( ): Message {
        const terminationMessage: Message = new Message(
            +AppConfig.messageProtocolVersion, MessageType.conn, ConnectionMessage[ ConnectionMessage.end ],
        );
        return terminationMessage;
    }

    /**
     * Creates a command message of the given type with the basic
     * message data constructed.
     * @param command The name of the command for which the message is.
     */
    public createCommandMessage( command: string ): Message {
        return new Message( +AppConfig.messageProtocolVersion, MessageType.cmd, command );
    }

    /**
     * Creates a subscription message with given message type
     * @param action The subscription message type (start/sync/end)
     */
    public createSubscriptionMessage( action: string, subscription: string, resourceId: string ): Message {
        const msg = new Message( +AppConfig.messageProtocolVersion, MessageType.sub, action );
        msg.subscription = subscription;
        msg.resourceId = resourceId;
        return msg;
    }
}
