/**
 * Holds helper functions for number related calculations.
 */
export class Number {
    /**
     * Compare two numbers for the given fraction digits
     * Can be used to ignore floating point precision for some calculations
     */
    public static isEqual( n1: number, n2: number, fractionDigits = 5 ): boolean {
        return parseFloat( n1.toFixed( fractionDigits )) === parseFloat( n2.toFixed( fractionDigits ));
    }
}
