import { ChangeDetectorRef, Inject, OnDestroy, TemplateRef, ViewContainerRef, Directive } from '@angular/core';
import { Observable, PartialObserver, Subscription } from 'rxjs';

/**
 * A base angular directive with some common and useful features.
 * When writing a new directive (or component) can extend this.
 */
@Directive()
export abstract class BaseDir implements OnDestroy {
    /**
     * RxJS subscriptions which should be unsubscribed.
     */
    private _subscriptions: Subscription[] = [];

    /**
     * A boolean to track whether the view is visible.
     * Initially true because the component will be available.
     * FIXME: check whether there's a better way for this.
     */
    private _isViewVisible: boolean = false;

    /**
     * Use the injector to get required resources.
     */
    constructor(
        @Inject( TemplateRef ) private _tplRef: TemplateRef<any>,
        @Inject( ViewContainerRef ) private _vcnRef: ViewContainerRef,
        @Inject( ChangeDetectorRef ) private _cdcRef: ChangeDetectorRef,
    ) {}

    /**
     * Hide the component.
     */
    public hideElement(): void {
        if ( !this._isViewVisible ) {
            return;
        }
        this._isViewVisible = false;
        this._vcnRef.clear();
        this._cdcRef.markForCheck();
    }

    /**
     * Show the component.
     */
    public showElement(): void {
        if ( this._isViewVisible ) {
            return;
        }
        this._isViewVisible = true;
        this._vcnRef.createEmbeddedView( this._tplRef );
        this._cdcRef.markForCheck();
    }

    /**
     * Subscribes to the given observable and track it.
     */
    public subscribe( observable: Observable<any>, observer?: PartialObserver<any> ): void {
        const sub = observable.subscribe( observer );
        this._subscriptions.push( sub );
    }

    /**
     * Use the destroy hook to remove pending subscriptions.
     */
    public ngOnDestroy(): void {
        for ( const sub of this._subscriptions ) {
            sub.unsubscribe();
        }
    }
}
