import { Logger } from 'libs/flux-core/src/logger/logger.svc';
import { isEqual, maxBy } from 'lodash';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { IConnectorPoint } from 'flux-diagram-composer';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { PathingService } from '../../diagram/pathing/pathing.svc';
import { ShapeBoundsLocator } from '../../diagram/containers/shape-bounds-locator';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';

/**
 * EditConnectorPoint
 * Applies transformations (scale, translate, rotate) on a given set of shapes.
 *
 * FIXME: fix on locator, when transform values are unset it should fallback to
 *        default values. This is not done for certain fields for performance.
 *        Because of this, if user Undo's after insert+transform the shape hides.
 *
 */
@Injectable()
@Command()
export class EditConnectorPoint extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        changes: {
            [shapeId: string]: IConnectorPoint[],
        },
    };

    /**
     * Inject pathing service.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected shapeBoundsLocator: ShapeBoundsLocator,
        protected pathingSvc: PathingService ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        for ( const shapeId in this.data.changes ) {
            const connector = this.changeModel.shapes[shapeId] as ConnectorModel;
            // FIXME: Remove this check when selection blocker is fixed
            if ( !connector ) {
                return;
            }
            const points = this.data.changes[shapeId];
            if ( connector.horizontal ) {
                points.forEach( p => {
                    p.y = points[ 0 ].y;
                });
            } else if ( connector.vertical ) {
                points.forEach( p => {
                    p.x = points[ 0 ].x;
                });
            }
            // FIXME: the pathing service should read the change model for data
            try {
                this.setShapeIdToEndpoints( points );
                const newPoints = this.pathingSvc.repath( shapeId, this.changeModel, points, true );
                const newPath = ConnectorModel.createPathFromPoints( newPoints );
                if ( !isEqual( connector.path, newPath )) {
                    connector.path = newPath;
                }
            } catch ( e ) {
                Logger.warning( 'Connector repathing failed due to', e );
            }
        }
    }

    protected setShapeIdToEndpoints( points ) {
        points.forEach( p => {
            const threshold = 10;
            const nodes: any [] = ( this.shapeBoundsLocator
                .searchShapes( p.x - threshold, p.x + threshold, p.y - threshold, p.y + threshold ) || [])
                .filter( n => n.type !== 'connector' );
            const node = maxBy( nodes, n => n.zIndex );
            if ( node && !p.gluepointId ) {
                p.shapeId = node.id;
                p.onShape = true;
                p.position = this.getRelativePosition( node.id, p.x, p.y );
            }
        });
    }

    protected getRelativePosition( shapeId, _x, _y  ) {
        const shape = this.changeModel.shapes[ shapeId ] as ShapeModel;
        const sc = shape.diagramToShapeCordinate({ x: _x, y: _y });
        const shapeBounds = shape.defaultBounds;
        const transform = shape.transform;
        const x = sc.x / ( shapeBounds.width * transform.scaleX );
        const y = sc.y / ( shapeBounds.height * transform.scaleY );
        return { x, y };
    }
}

Object.defineProperty( EditConnectorPoint, 'name', {
    value: 'EditConnectorPoint',
});
