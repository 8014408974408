import { Component, Input } from '@angular/core';

@Component({
    selector: 'horizontal-container',
    template: `
    <div [ngStyle]="style">
        <ng-content></ng-content>
    </div>
    `,
})
export class HorizontalContainer {
    @Input() public flexProps: any = {};
    private baseStyle: any = {
        'display': 'flex',
        'flex-direction': 'row',
        // 'align-items': 'flex-start',
        // 'gap': '8px',
    };

    public get style() {
        return {
            ...this.baseStyle,
            ...this.flexProps,
        };
    }
}
