import { IResponsibility, Logger, StateService, InitializationChainStatus, IChainOutcome } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataStore } from 'flux-store';
import { UserModel } from '../model/user.mdl';
import { AuthenticationStatus } from '../authentication.svc';
import { take, map } from 'rxjs/operators';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the exisnce of a user
 * in the local storage.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-12-15
 */
@Injectable()
export class UserResponsibility implements IResponsibility  {

    public name: string = 'UserResponsibility';

    constructor ( protected logger: Logger,
                  private dataStore: DataStore,
                  @Inject( StateService ) protected state: StateService<any, any> ) {}

    /**
     * Determines if the user exists in the local storage.
     * @param status - current status of the sequence
     * @return  Observable which emits true if a current user exists
     */
    public checkState( status: InitializationChainStatus ): Observable<boolean> {
        this.logger.debug( 'UserResponsibility checkState' );
        const currentUserId = this.state.get( 'CurrentUser' );
        if ( currentUserId ) {
            return this.dataStore
                .findOneRaw( UserModel, { id: currentUserId })
                .pipe(
                    take( 1 ),
                    map( user => !!user ),
                );
        } else {
            return of( false );
        }
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string[] {
        const s = this.getStates( status );
        if ( s.isAuthenticated ) {
            return [ 'UserSubscriptionResponsibility' ];
        }

        if ( !s.isUserRequired || ( s.isUserRequired && s.hasUser && s.hasAuthentication )) {
            return [ 'DiagramResponsibility' ];
        }
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        const s = this.getStates( status );
        if ( s.isUserRequired && !s.isAuthenticated ) {
            return { type: 'command', action: 'Authenticate', wait: true };
        }
    }

    /**
     * Derives the required values from responsibility states and
     * returns a map with them for easy access.
     * @param status chain status
     */
    protected getStates( status: InitializationChainStatus ) {
        return {
            isUserRequired: !!status.states.userRequired,
            hasUser: !!status.states.UserResponsibility,
            isAuthenticated: status.states.AuthResponsibility === AuthenticationStatus.AUTHENTICATED,
            hasAuthentication: status.states.AuthResponsibility !== AuthenticationStatus.NOT_AUTHENTICATED,
        };
    }
}
