import { AbstractModel } from 'flux-core';
import { DataStore } from 'flux-store';
import { AbstractSubscription } from 'flux-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentModel } from '../model/comment.mdl';

/**
 * Subscription to fetch comments for a diagram. This subscription will fetch
 * comment models but the resourceId will be the diagram's resource id.
 */
export class CommentSub extends AbstractSubscription {
    constructor ( public resourceId: string, protected datastore: DataStore ) {
        super()/* istanbul ignore next */;
    }

    /**
     * The subscription name (should be the same as in the server).
     */
    public get subscription(): string {
        return 'Comment';
    }

    /**
     * The model type associated with this subscription.
     */
    public get modelType(): typeof AbstractModel {
        return CommentModel;
    }

    /**
     * This timestamp will be used to identify which comments to fetch from the server.
     */
    public getLatestSyncedTime(): Observable<number> {
        const selector = { id: this.resourceId };
        const options: any = { sort: { lastUpdated: -1 }};
        return this.datastore.findOneLatest( CommentModel, selector, options ).pipe(
            map( model => ( model ? model.lastUpdated : null )),
        );
    }
}
