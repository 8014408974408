import { Stage } from '@creately/createjs-module';

/**
 * This class extends the createjs Stage and adds new functionality
 * for canvas size and zoom manipulation cosidering the devicePixelRatio property
 * that should account for Retina screens. Call the setup stage to enable all functionality.
 *
 * FIXME
 * The devicePixelRatio ( DPR ) values greater than 1 affect the viewport coordinates.
 * The retina displays have DPR values greater thant 1. Mouse events take the DPR into account
 * but the calculations throughout the application are done based on the event values related to DPR = 1.
 * So this correction should be done from a common place so that it doesn't need to worry about DPR anymore.
 *
 * @author thisun
 * @since 2017-10-17
 */

 // --------------------------------------------------------
// FIXME Rename after removing the other Retina Stage file.
// --------------------------------------------------------
export class RetinaStageSimplified extends Stage {

    /**
     * Holds the zoom level and initializes to 1
     */
    protected _zoom: number = 1;

    constructor( canvas: HTMLCanvasElement | string | Object ) {
        super( canvas );
    }

    /**
     * Need to do this to completely destroy a Stage
     * https://github.com/CreateJS/EaselJS/issues/913
     */
    public destroy() {
        this.canvas = null;
        ( this as any )._eventListeners = null;
    }

    /**
     * Scales the canvas according to the device pixel ratio.
     */
    public setupStage() {
        // Enable snapToPixelEnabled, increases frame rate
        this.snapToPixelEnabled = true;
        this.updateCanvasSize();
    }

    /**
     * Sets the canvas size to the parent's 100% size.
     * and updates the scaleX and scaleY on changes in zoom level
     * Makes sure the canvas is the viewport size.
     */
    protected updateCanvasSize() {
        const canvasElem: HTMLCanvasElement = <HTMLCanvasElement>this.canvas;
        const widthChanged: boolean = canvasElem.width !== canvasElem.offsetWidth * window.devicePixelRatio;
        const heighChanged: boolean = canvasElem.height !== canvasElem.offsetHeight * window.devicePixelRatio;

        if ( !widthChanged && !heighChanged ) {
            return;
        }
        if ( widthChanged && canvasElem.offsetWidth > 0 ) {
            canvasElem.width = canvasElem.offsetWidth * window.devicePixelRatio;
        }
        if ( heighChanged && canvasElem.offsetHeight > 0 ) {
            canvasElem.height = canvasElem.offsetHeight * window.devicePixelRatio;
        }
        this.setCanvasScale();
        this.update();
    }

    /**
     * Sets the scale of the canvas based on the pixel ratio
     */
    protected setCanvasScale() {
        this.scaleX = window.devicePixelRatio * this._zoom;
        this.scaleY = window.devicePixelRatio * this._zoom;
    }
}
