import { Input, Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * RadioButton Component
 * This let a user select ONE of a limited number of choices:
 */
@Component({
    selector: 'radio-button',
    templateUrl: './radio-button.cmp.html',
    styleUrls: [ './radio-button.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RadioButton {

    /**
     * This indicates the size of the radio button. It can be small, medium or large.
     */
    @Input()
    public size: string;

    /**
     * This indicates whether the button needs to be checked by default or not
     * Returns the checked state.
     */
    @Input()
    public checked: boolean;

    /**
     * This indicates whether this needs to be disable or not
     */
    @Input()
    public disabled: boolean;

    /**
     * Title to be shown in the button
     */
    @Input()
    public title: string;

    /**
     * Description to be shown
     */
    @Input()
    public description: string;

    /**
     * Sets or returns the value of the name attribute of a radio button
     */
    @Input()
    public name: string;

    /**
     * Sets or returns the value of the value attribute of the radio button
     */
    @Input()
    public value: any;

    /**
     * An observable which get triggered when the button is get checked
     */
    public onSelect: Subject<any>;

    constructor() {
        this.onSelect = new Subject();
    }

    /**
     * Handles the click event on the button
     */
    public handleChange() {
        this.onSelect.next( this );
    }

}
