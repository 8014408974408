import { switchMap, tap } from 'rxjs/operators';
import {
    Component,
    Inject,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import { Animation } from '../animation';
import { StateService } from '../../controller/state.svc';
import { Observable, Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { LoadingIndicatorState } from '../ui-states';

/**
 * This is the main loader component.
 *
 * @author
 * @since 2018-02-18
 */

@Component({
    selector: 'main-loader',
    templateUrl: 'main-loader.html',
    styleUrls: [ 'main-loader.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLoader implements AfterViewInit, OnDestroy {

    /**
     * A behavior subject that emits boolean values to show
     * and hide the loader.
     */
    public showLoader: BehaviorSubject<boolean>;

    /**
     * Optional description to show below loading indicator.
     */
    public description: string;

    /**
     * Spinner element.
     */
    @ViewChild( 'spinner' )
    protected spinner: ElementRef<HTMLDivElement>;

    /**
     * Logo element.
     */
    @ViewChild( 'logo' )
    protected logo: ElementRef<HTMLDivElement>;

    /**
     * Loader element.
     */
    @ViewChild( 'loader' )
    protected loader: ElementRef<HTMLDivElement>;

    /**
     * Subscriptions
     */
    protected subs: Subscription[] = [];

    /**
     * Constructor
     */
    constructor(
        @Inject( StateService ) protected state: LoadingIndicatorState,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {
        this.showLoader = new BehaviorSubject( true );
    }

    /**
     * Checks the LoadingIndicator state and shows the
     * indicator if the state data is true, and hides the
     * indicator if the state data is false.
     */
    public ngAfterViewInit() {
        const indicatorSub = this.state.changes( 'LoadingIndicatorState' ).pipe(
            switchMap( data => {
                this.description = data.description ? data.description : null;
                if ( data.main ) {
                    return this.show();
                }
                return this.hide();
            })).subscribe();
        this.subs.push( indicatorSub );
    }

    /**
     * Sets the showLoader to true and starts the animation
     * to fade in the logo, animate the logo upwards and
     * once logo animations are complete, fade in and
     * resume the spinner animation.
     */
    public show() {
        this.showLoader.next( true );
        // this.showLoader is updated async so run change detection
        // before attempting to access elements to prevent null reference errors
        this.changeDetectorRef.detectChanges();
        return this.fadeIn( this.logo.nativeElement ).start().pipe(
            switchMap(() => this.moveUpwards( this.logo.nativeElement ).start()),
            tap(() => this.spinner.nativeElement.classList.remove( 'paused' )),
            switchMap(() => this.fadeIn( this.spinner.nativeElement ).start()),
        );
    }

    /**
     * Sets the showLoader to false and returns an empty observable.
     */
    public hide(): Observable<any> {
        // Note:  When the window.location changes loader won't be available
        // e.g when Logout
        if ( !this.loader ) {
            return EMPTY;
        }
        return this.fadeOut( this.loader.nativeElement ).start().pipe(
            tap({
                complete: () => this.showLoader.next( false ),
            }),
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Fade out animation.
     */
    protected fadeOut( elem: HTMLElement ): Animation {
        const transition = new Animation({
            from: { opacity: '1' },
            to: { opacity: '0' },
            transitionProperty: 'opacity',
            duration: 500,
            easing: 'linear',
        });
        transition.element = elem;
        return transition;
    }

    /**
     * Fade in animation.
     */
    protected fadeIn( elem: HTMLElement ): Animation {
        const transition = new Animation({
            from: { opacity: '0' },
            to: { opacity: '1' },
            transitionProperty: 'opacity',
            duration: 1500,
            easing: 'linear',
        });
        transition.element = elem;
        return transition;
    }

    /**
     * Move the element upwards by 20px.
     */
    protected moveUpwards( elem: HTMLElement ): Animation {
        const transition = new Animation({
            from: { transform: 'translateY(0%)' },
            to: { transform: 'translateY(-20px)' },
            transitionProperty: 'transform',
            duration: 500,
            easing: 'ease-in-out',
        });
        transition.element = elem;
        return transition;
    }
}
