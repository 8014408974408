import { ITextParser } from 'flux-definition';

class DatabaseKeyParser implements ITextParser<any> {
    static id = 'creately.database.key';

    public static get instance(): DatabaseKeyParser {
        return this._instance;
    }

    private static _instance = new DatabaseKeyParser();

    parse( input: string ) {
        let parsedText;
        let shortenedText;
        const parseErrors = [];
        if ( !input ) {
            parseErrors.push( 'Error: input is undefined' );
        } else {
            const trimmed = input.trim();
            parsedText = trimmed.replace( /[<>]/gm, '' );
            if ( parsedText.toLowerCase().includes( 'primary' )) {
                shortenedText = 'PK';
            } else if ( parsedText.toLowerCase().includes( 'foreign' )) {
                shortenedText = 'FK';
            } else if ( parsedText.toLowerCase().includes( 'composite' )) {
                shortenedText = 'CK';
            } else if ([ 'FK', 'PK', 'CK' ].includes( parsedText.toUpperCase())) {
                shortenedText = parsedText;
            } else {
                parseErrors.push( 'Error: input not identified' );
            }
        }
        return {
            ast: {
                actualText: parsedText,
                shortenedText: shortenedText,
            },
            lexResult: {},
            parseErrors: parseErrors,
        };
    }

    getContentAssistSuggestions( text: string, context: any ) {
        return {
            suggestions: [
                'PK',  'FK',
                'CK',
            ],
            searchTerm: text,
        };
    }
}

export default DatabaseKeyParser;
