import { LinkService } from './../../../base/diagram/link.svc';
import { AbstractCommand, Command, Rectangle, StateService, CommandService, Logger } from 'flux-core';
import { Injectable } from '@angular/core';
import { ViewportService } from './../../../base/diagram/viewport.svc';
import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { Observable } from 'rxjs';
import { BaseDiagramCommandEvent } from '../../../base/diagram/command/base-diagram-command-event';
import { FOLDER_PANEL_SCREEN_SIZE } from '../../../left-sidebar/folder-panel/folder-panel-view.i';
import { tap, take, delay } from 'rxjs/operators';
import { values } from 'lodash';

const VIEWPORT_PADDING = 120;

/**
 * Focus canvas to area where a template has been added
 */
@Injectable()
@Command()
export class FocusOnTemplate extends AbstractCommand {
    public data: {
        frameId?: string;
        children?: [string];
    };

    constructor(
        protected linkService: LinkService,
        protected viewport: ViewportService,
        protected ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        protected commandService: CommandService,
    ) {
        super();
    }

    public prepareData(): void | Observable<any> {
        if ( !this.data.frameId ) {
            return;
        }
        this.linkService.navigateToShape( this.data.frameId );
        this.state.set( 'FocusTarget', {});
        this.commandService.dispatch(
            BaseDiagramCommandEvent.selectShapes,
            this.state.get( 'CurrentDiagram' ),
            { shapeIds: [ this.data.frameId  ], add: false },
        );
        this.state.set( 'DiagramZoomLevel', 1 );
        this.state.set( 'FolderPanelScreen', FOLDER_PANEL_SCREEN_SIZE.SIDE_SCREEN );

        // FIXME: Need to trigger reflow for styles to be applied properly on safari
        const element: any = document.querySelector( '.expanded' );
        element.style.display = 'unset';
        Logger.info( element.offsetHeight );
        element.style.display = '';

        return this.viewport.getShapeModels([ this.data.frameId ]).pipe(
            take( 1 ),
            delay( 1000 ),          // waiting for viewport changes to sync up after closing the expanded panel
            tap( data => {
                const bounds: Rectangle = values( data )[ 0 ].viewBounds;

                const viewport: Rectangle = this.state.get( 'DiagramViewPort' );

                const scaleX = ( viewport.width - 2 * VIEWPORT_PADDING ) / bounds.width;
                const scaleY = ( viewport.height - 2 * VIEWPORT_PADDING ) / bounds.height;
                const zoomLevel = Math.min( scaleX, scaleY );

                this.state.set( 'DiagramZoomLevel', zoomLevel );
                this.state.set( 'Selected', [ this.data.frameId, ...this.data.children ]);
            }),
            delay( 100 ),
            tap(() => {
                const currentPan = this.state.get( 'DiagramPan' );
                this.state.set( 'DiagramPan', { x: currentPan.x, y: currentPan.y - 60 });
            }),
        );
    }
}
