import { AbstractCommand, StateService, CommandInterfaces, Command } from 'flux-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Animation } from '../../../framework/ui/animation/tween-animation';
import { Ease, Point } from '@creately/createjs-module';
import { map } from 'rxjs/operators';

/**
 * Pan the diagram to a given position or by distance. This is a local state command
 */
@Injectable()
@Command()
export class PanDiagram extends AbstractCommand {

    public static get dataDefinition(): {}  {
        return {
            x: true, // Indicates the x position to pan to. (Used by state)
            y: true, // Indicates the y position to pan to. (Used by state)
            dx: false, // Indicates the delta x to pan to.
            dy: false, // Indicates the delta y to pan to.
            currentX: false, // Current x for interrim calculations.
            currentY: false, // Current y for interrim calculations.
            animate: false, // Indicates if to animate the change. Default is true
            type: false, // Indicates if the pan is auto or not
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IStateChangeCommand' ];
    }

    protected state: StateService<any, any>;

    constructor( state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
        this.state = state;
    }

    public get states(): { [ stateId: string ]: any } {
        return {
            DiagramPan: this.data.panStateData,
        };
    }

    /**
     * Prepares the data necessary for the pan state change.
     */
    public prepareData(): void {
        const pan = this.state.get( 'DiagramPan' );
        this.data.currentX = pan.x;
        this.data.currentY = pan.y;
        this.calculateXY();

        if ( this.data.animate === false ) {
            this.data.panStateData = this.extractStateData( this.data );
        } else {
            this.data.panStateData = this.simulateAnimation();
        }
    }

    public execute (): boolean {
        return true;
    }

    /**
     * Calculates x and y values from the available command data
     */
    protected calculateXY() {
        if ( this.data.dx !== undefined ) {
            this.data.x  = this.data.currentX + this.data.dx;
        }
        if ( this.data.dy !== undefined ) {
            this.data.y  = this.data.currentY + this.data.dy;
        }
    }

    /**
     * Creates a animation simulation for the zoom state change which
     * returns an observable that will emit the zoom change.
     */
    protected simulateAnimation(): Observable<Point> {
        return Animation
            .simulate({ x: this.data.currentX, y: this.data.currentY })
            .to({ x: this.data.x, y: this.data.y }, 300, Ease.sineOut )
            .changes()
            .pipe( map( change =>  this.extractStateData({ x: change.x, y: change.y })));
    }

    /**
     * Extracts the Point info from the command data
     * @param param0 Command Data
     */
    protected extractStateData({ x, y }): Point {
        const point = new Point( x, y );
        if ( this.data && this.data.type !== 'auto' ) {
            this.state.set( 'DiagramPanNonAuto', point );
        }
        return point;
    }
}

Object.defineProperty( PanDiagram, 'name', {
    value: 'PanDiagram',
});
