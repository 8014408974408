import { Point, Rectangle, Position } from 'flux-core';
import { IShapeText, TextAlignment, ITextPositionData, ITransform } from 'flux-definition';
import { ConnectorDataModel } from '../../shape/model/connector-data-mdl';
import { IConnectorText } from 'flux-definition';
import { IPoint2D, IPosition2D } from 'flux-definition';

/**
 * TextPostion Class contains functions to calculate the position
 * to place the text and the text editor on shpaes or connectors.
 */
export class TextPostion {
    /**
     * This function returns the point to place the text editor
     * for the shape. The value is calculated using the shape bounds, text bounds and shape text model
     * @param text ShapeTextModel  Shape text model to get positioning related data
     * @param shapeBounds Rectangle  The bounds of the shape
     * @param textBounds Rectangle  The bounds of the text
     * @param transform ITransform  Optional
     */
    public static forShape (
        text: IShapeText,
        shapeBounds: Rectangle,
        textBounds: Rectangle,
        transform?: ITransform ): Point {

        const pointOnShape = TextPostion.getPointOnShape( text.position, shapeBounds, transform );
        const pointOnText = TextPostion.getPointOnText( textBounds, text.alignX, text.alignY, transform );

        return new Point(( pointOnShape.x - pointOnText.x ) , ( pointOnShape.y - pointOnText.y )) ;
    }

    /**
     * This function returns the aligning point of the text relative to the text bounds
     * @param alignX TextAlignment  The horizontal text alignment
     * @param alignX TextAlignment  The vertical text alignment
     */
    public static getPointOnText(
        textBounds: Rectangle, alignX: TextAlignment, alignY: TextAlignment,
        transform?: ITransform, text?: IShapeText ): IPoint2D {
            const x = textBounds.width * ( 1 + alignX ) / 2;
            const y = textBounds.height * ( 1 + alignY ) / 2;
            const p = { x, y };

            // NOTE: When the shape is flipped, the text position should be corrected
            if ( transform ) {
                const angle: number = parseFloat( transform.angle.toFixed( 2 ));
                if ( transform.scaleX > 0 && transform.scaleY < 0 ) { // Flip Y axis
                    if ( alignY === -1 ) {
                        p.y = p.y + textBounds.height;
                    }
                    if ( alignY === 1 ) {
                        p.y = p.y - textBounds.height;
                    }
                }
                if ( transform.scaleX < 0 && transform.scaleY > 0 ) { // Flip X axis
                    if ( alignX === -1 ) {
                        p.x = p.x + textBounds.width;
                    }
                    if ( alignX === 1 ) {
                        p.x = p.x - textBounds.width;
                    }
                }
                if ( angle === 180 && transform.scaleX > 0 && transform.scaleY > 0 ) { // Flip x and y
                    if ( alignY === -1 ) {
                        p.y = p.y + textBounds.height;
                    }
                    if ( alignY === 1 ) {
                        p.y = p.y - textBounds.height;
                    }
                    if ( alignX === -1 ) {
                        p.x = p.x + textBounds.width;
                    }
                    if ( alignX === 1 ) {
                        p.x = p.x - textBounds.width;
                    }
                }
            }
            return p;
    }

    public static getTextAngle( transform: ITransform ) {
        // NOTE: Sometimes the angle becomes 0.00000000000001 instead of beign zero
        const angle: number = parseFloat( transform.angle.toFixed( 2 ));
        return angle;
    }

    /**
     * This funciton returns the point on shape to place the text
     * given point and transformation
     * @param xPos number  A ratio to define the x position on the shape
     * @param yPos number  A ratio to define the y position on the shape
     * @param bounds Rectangle  The default bounds of the shape
     * @param transform ITransform
     */
    public static getPointOnShape( pos: IPosition2D, bounds: Rectangle, transform: ITransform ): IPoint2D {
        return Position.onRect( pos, bounds, transform );
    }

    /**
     * This function returns the position to place the connector text or editor
     * The value is calculated using connectorModel, connector textmodel and textbounds
     * @param connectorModel ConnectorModel
     * @param textmodel ConnectorTextModel   Connector text model to get positioning related data
     */
    public static forConnector(
        connectorModel: ConnectorDataModel,
        textmodel: IConnectorText,
        textBounds: Rectangle ): IPoint2D {
            const point = TextPostion.getPointOnConnector( connectorModel, textmodel );
            const x = point.x - textBounds.width / 2;
            const y = point.y - textBounds.height / 2;
            return { x, y };
    }

    /**
     * This function returns a recangle on the connecotr that covers the area of the specified text
     * The value is calculated using the connector model, and connector text model
     * @param connectorModel ConnectorModel
     * @param textmodel ConnectorTextModel   Connector text model to get positioning related data
     * @param textBounds Rectangle
     */
    public static getRectangleOnConnector (
        connectorModel: ConnectorDataModel,
        textmodel: IConnectorText,
        textBounds: Rectangle ): Rectangle {
            const { x, y } = TextPostion.forConnector( connectorModel, textmodel, textBounds );
            return new Rectangle( x, y, textBounds.width, textBounds.height );
        }

    /**
     * This function returns a point on the connector to place the text or text editor
     * The value is calculated using the connector model, and connector text model
     * @param connectorModel ConnectorModel
     * @param textmodel ConnectorTextModel   Connector text model to get positioning related data
     */
    public static getPointOnConnector( connectorModel: ConnectorDataModel, textmodel: IConnectorText ): IPoint2D {
        let length;
        const totalLength = connectorModel.length;

        if ( totalLength <= this.textPadding * 2 ) {
            return connectorModel.splitByLength( totalLength * 0.5 );
        }

        if ( textmodel.posType === 'relative' ) {
            length = totalLength * textmodel.pos;
        } else if ( textmodel.posType === 'fixed-start' && totalLength >= textmodel.pos ) {
            length = textmodel.pos;
        } else if ( textmodel.posType === 'fixed-end' && totalLength >= textmodel.pos ) {
            length = totalLength - textmodel.pos;
        }

        if ( length < this.textPadding ) {
            length = this.textPadding;
        } else if ( length > totalLength - this.textPadding ) {
            length = totalLength - this.textPadding;
        }
        return connectorModel.splitByLength( length );
    }

    /**
     * Returns true if the text is inside the shape
     */
    public static isInside( model: ITextPositionData ) {
        const positionString = TextPostion.getTextPositionString( model );
        if ( positionString === 'none' ) {
            if ( model.y < 0  || model.x < 0 )  {
                return false;
            }
            return true;
        }
        return positionString.includes( 'in-' );
    }

    /**
     * Returns the position string according to the ITextPositionData
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public static getTextPositionString( data: ITextPositionData ): string {
        const { xType, yType, x, y, alignX, alignY } = data;

        if ( xType === 'fixed-start' && x >= 0 && yType === 'fixed-start' && y <= 0 &&
            alignX === -1 && alignY === 1 ) {
                return 'ex-top-left';
        }

        if ( xType === 'relative' && x === 0.5 && yType === 'fixed-start' && y <= 0 &&
            alignX === 0 && alignY === 1 ) {
                return 'ex-top-center';
        }

        if ( xType === 'fixed-end' && x >= 0 && yType === 'fixed-start' && y <= 0 &&
            alignX === 1 && alignY === 1 ) {
                return 'ex-top-right';
        }

        if ( xType === 'fixed-start' && x >= 0 && yType === 'fixed-start' && y >= 0 &&
            alignX === -1 && alignY === -1 ) {
                return 'in-top-left';
        }

        if ( xType === 'relative' && x === 0.5 && yType === 'fixed-start' && y >= 0  &&
            alignX === 0 && alignY === -1 ) {
                return 'in-top-center';
        }

        if ( xType === 'fixed-end' && x >= 0 && yType === 'fixed-start' && y >= 0 &&
            alignX === 1 && alignY === -1 ) {
                return 'in-top-right';
        }

        if ( xType === 'fixed-start' && x >= 0 && yType === 'relative' && y === 0.5 &&
            alignX === -1 && alignY === 0 ) {
                return 'in-center-left';
        }

        if ( xType === 'relative' && x === 0.5 && yType === 'relative' && y === 0.5  &&
            alignX === 0 && alignY === 0 ) {
                return 'in-center-center';
        }

        if ( xType === 'fixed-end' && x >= 0 && yType === 'relative' && y === 0.5 &&
            alignX === 1 && alignY === 0 ) {
                return 'in-center-right';
        }

        if ( xType === 'fixed-start' && x >= 0 && yType === 'fixed-end' && y >= 0 &&
            alignX === -1 && alignY === 1 ) {
                return 'in-bottom-left';
        }

        if ( xType === 'relative' && x === 0.5 && yType === 'fixed-end' && y >= 0 &&
            alignX === 0 && alignY === 1 ) {
            return 'in-bottom-center';
        }

        if ( xType === 'fixed-end' && x >= 0 && yType === 'fixed-end' && y >= 0 &&
            alignX === 1 && alignY === 1 ) {
            return 'in-bottom-right';
        }

        if ( xType === 'fixed-start' && x >= 0 && yType === 'fixed-end' && y <= 0 &&
            alignX === -1 && alignY === -1 ) {
                return 'ex-bottom-left';
        }

        if ( xType === 'relative' && x === 0.5 && yType === 'fixed-end' && y <= 0 &&
            alignX === 0 && alignY === -1 ) {
            return 'ex-bottom-center';
        }

        if ( xType === 'fixed-end' && x >= 0 && yType === 'fixed-end' && y <= 0 &&
            alignX === 1 && alignY === -1 ) {
            return 'ex-bottom-right';
        }

        if ( xType === 'fixed-start' && x <= 0 && yType === 'fixed-start' && y >= 0 &&
            alignX === 1 && alignY === -1 ) {
                return 'ex-left-top';
        }

        if ( xType === 'fixed-end' && x <= 0 && yType === 'fixed-start' && y >= 0 &&
            alignX === -1 && alignY === -1 ) {
                return 'ex-right-top';
        }

        if ( xType === 'fixed-start' && x <= 0 && yType === 'relative' && y === 0.5 &&
            alignX === 1 && alignY === 0 ) {
                return 'ex-left-center';
        }

        if ( xType === 'fixed-end' && x <= 0 && yType === 'relative' && y === 0.5 &&
            alignX === -1 && alignY === 0 ) {
                return 'ex-right-center';
        }

        if ( xType === 'fixed-start' && x <= 0 && yType === 'fixed-end' && y >= 0 &&
            alignX === 1 && alignY === 1 ) {
                return 'ex-left-bottom';
        }

        if ( xType === 'fixed-end' && x <= 0 && yType === 'fixed-end' && y >= 0 &&
            alignX === -1 && alignY === 1 ) {
                return 'ex-right-bottom';
        }

        return 'none';
    }

    /**
     * Sets paddingLeft for the parent of list items
     * @param el
     * @returns
     */
    public static setListPadding( el: string | HTMLElement ): string {
        let element;
        if ( typeof el === 'string' && el ) {
            element = document.createElement( 'div' );
            element.innerHTML = el;
        } else {
            element = el;
        }

        if ( element ) {
            const liElements = element.querySelectorAll( 'li' );
            const liElementsArray = Array.from( liElements );
            const li = liElementsArray[0] as HTMLElement;
            if ( !li ) {
                return element.innerHTML;
            }
            const span = li.querySelector( 'span' );
            if ( span && span.innerText.trim() !== '' ) {
                const fontSize = parseFloat( span.style.fontSize || '10' );
                li.parentElement.style.paddingLeft = `${ this.getListPaddingforFontSize( fontSize ) }rem`;
            } else {
                li.parentElement.style.paddingLeft = `${ this.getListPaddingforFontSize( 10 ) }rem`;
            }
            return element.innerHTML;
        }
        return '';
    }

    /**
     * Retuns the left padding for the list item based on the font size
     * y = mx + c function in this method was derrived from some sample data
     * @param size
     * @returns
     */
    public static getListPaddingforFontSize( size: number ) {
        const y = x => ( 0.138393 * x - 0.305357 );
        return y( size );
    }

    /**
     * A fixed value to add a buffer to the end/start of the text to make
     * sure text does not stick to the very edge.
     */

    protected static get textPadding(): number {
        return 30;
    }

}
