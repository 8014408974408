<div class="col-menu-item-container" [id]="id" [ngClass]="{ 'expanded': active, 'collapsed': !active }">
    <div>
        <div class="col-menu-head-container">
            <div class="col-menu-item-heading fx-no-selection btn-list-item btn-free-size" [class.selected]="( highlightSelected && active )" (click)="toggle($event)">
                <div class="col-menu-item-heading-inner">
                    <div class="col-menu-item-icon" [hidden]="(icon || !collapsable)">
                        <svg class="nu-icon nu-icon-small nu-ic-close-thin icon-collapse" [class.active]="active">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                        </svg>
                    </div>
                    <div class="col-menu-item-icon-custom" [hidden]="!icon" >
                        <svg class="nu-icon nu-ic-close-thin" [class.active]="active">
                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-'+ icon"></use>
                        </svg>
                    </div>
                    <div class="col-menu-item-icon-custom" [hidden]="!preTitleIcon" >
                        <svg class="nu-icon nu-ic-close-thin" [class.active]="active">
                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-'+ preTitleIcon"></use>
                        </svg>
                    </div>
                    <div *ngIf="!(titleEditable | async)" [attr.title]="title.length > 15 ? title: ''" class="col-menu-item-heading-title body fx-ellipsis">{{ title }}</div>
                    <editable-label *ngIf="(titleEditable | async)" class="col-menu-editable-label"
                        [value]="title"
                        [focus]="true"
                        [selectAll]="true"
                        (changedText)="titleChanged.emit($event);titleEditable.next( false );"
                        (focusout)="titleEditable.next( false )"
                    >
                    </editable-label>
                </div>
                <div class="col-menu-item-icon-right" [hidden]="!removable">
                    <button class="col-menu-item-close-icon fx-button fx-icon" (click)="detach()" tooltip placement="bottom">
                        <svg class="nu-icon nu-ic-close-thin medium-grey">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close-thin"></use>
                        </svg>

                        <div class="tooltip-content">
                            {{translate.get( 'TOOLTIPS.REMOVE_LIBRARY')|async}}
                        </div>
                    </button>
                    <div class="col-menu-item-icon-custom" [hidden]="!icon" >
                        <svg class="nu-icon nu-ic-close-thin" [class.active]="active">
                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-'+ icon"></use>
                        </svg>
                    </div>
                    <div *ngIf="!(titleEditable | async)" class="col-menu-item-heading-title body fx-ellipsis">{{ title }}</div>
                    <editable-label *ngIf="(titleEditable | async)" class="col-menu-editable-label"
                        [value]="title"
                        [focus]="true"
                        [selectAll]="true"
                        (changedText)="titleChanged.emit($event);titleEditable.next( false );"
                    >
                    </editable-label>
                </div>
                <div class="col-menu-item-icon-right" [hidden]="!removable">
                    <button class="col-menu-item-close-icon fx-button fx-icon" (click)="detach()" tooltip placement="bottom">
                        <svg class="nu-icon nu-ic-close-thin medium-grey">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close-thin"></use>
                        </svg>
        
                        <div class="tooltip-content">
                            {{translate.get( 'TOOLTIPS.REMOVE_LIBRARY')|async}}
                        </div>
                    </button>
                </div>
                <div *ngIf="hasFloatingTooltip" class="escalation-image" #floatingTooltip floatingContainer placement="right" openOnHover="false">
                    <span class="floating-content">
                        <div class="image-cover">
                            <img class="smart-into-image" src="./assets/tooltip-tour/database-filter.gif" alt="smartset">
                            <svg (click)="closeTooltip()" class="nu-icon nu-ic-close-btn">
                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                            </svg>
                        </div>
                        <div class="floating-tooltip-content">
                            <div class="floating-title fx-margin-bottom-5">
                                {{ tooltipTitle }}
                            </div>
                            <div class="floating-content fx-margin-bottom-5">
                                <span>{{ tooltipContent }}</span>
                            </div>
                            <div class="fx-margin-bottom-5 fx-margin-top-5">
                                <div class="form-inline row-item">
                                    <div class="input">
                                        <input type="checkbox" class="form-check-input" [checked]="checked | async" (click)="checked.next(!checked.value)" />
                                        <label class="form-check-label">
                                            <div class="floating-footer" translate>BUTTONS.DONT_SHOW_AGAIN</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <!-- style="position: relative; margin-top: -30px;" -->
            <div *ngIf="!(titleEditable | async)" [ngStyle]="active && {'position': 'relative' }">
                <div [ngStyle]="active && {'visibility': 'visible', 'opacity': '1' }" class="col-menu-item-icon-options" [ngClass]="{ 'selected': active }" *ngIf="options && options.length" >
                    <span *ngIf="plusButton" class="btn-container" tooltip placement="right">
                        <button (click)="plusButtonCallback($event)" class="btn-small btn-plus fx-whitespace-nowrap">
                            <svg class="nu-icon nu-icon-small"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-plus-large"></use></svg>
                        </button>
                        <span class="tooltip-content">{{ plusButtonTooltip }}</span>
                    </span>
                    <ng-container *ngIf="secondaryOptions && secondaryOptions.length">
                        <span *ngFor="let option of secondaryOptions">
                            <span *ngIf="option.tooltip" class="btn-container" tooltip placement="{{option.tooltipPlacement || 'top'}}">
                                <button class="btn-icon btn-secondary-option" (click)="$event.preventDefault(); option.callback()">
                                    <svg class="nu-icon search-icon">
                                      <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + option.icon"></use>
                                    </svg>
                                </button>
                                <span class="tooltip-content">{{ option.tooltip }}</span>
                            </span>
                            <button *ngIf="!option.tooltip" class="btn-icon btn-secondary-option" (click)="$event.preventDefault(); option.callback()">
                                <svg class="nu-icon upload-icon">
                                  <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-' + option.icon"></use>
                                </svg>
                            </button>
                        </span>
                    </ng-container>
                    <abs-dropdown #dropdown [settings]="{ closeOnItemClicked: true, openOnHover: false,
                        closeOnBlur: true, multiselectable: false }" direction="top" alignment="right">
                        <simple-dropdown-button ddbutton [items]="options">
                            <svg class="nu-icon more-icon btn-icon">
                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use>
                            </svg>
                        </simple-dropdown-button>
                        <simple-dropdown-item class="dropdown-item" dditem *ngFor="let option of options" [item]="option" [class.disabled]="option.disabled" (click)="option.callback()">
                            <div>
                                <svg class="nu-icon nu-icon-small opt-icon" *ngIf="option.icon"><use [attr.xlink:href]="iconURL(option.icon)"></use></svg>
                                <a class="opt-label">{{option.label}}</a>
                            </div>
                            <span *ngIf="option.disabled" class="disabled-icon" tooltip placement="right">
                                <svg class="nu-icon btn-icon">
                                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-info"></use>
                                </svg>
                                <span class="tooltip-content">{{ option.tooltip }}</span>
                            </span>
                        </simple-dropdown-item>
                    </abs-dropdown>
                </div>
            </div>
        </div>
        <div class="caption col-menu-item-sub-label" *ngIf="( subText | async ) as text">
            {{ text }}
        </div>
    </div>

    <div class="col-menu-item-content">
        <div class="fx-invisible-item" #componentContainer></div>
    </div>
</div>