import { Random } from 'flux-core';
import { BehaviorSubject } from 'rxjs';
import { Component, ChangeDetectionStrategy, ViewChild,
    ElementRef, ComponentFactoryResolver, Injector } from '@angular/core';

/**
 * TipTapEmojiListCmp is the component that renders list of emojis
 */
@Component({
    selector: 'tiptap-emoji-list-cmp',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
      <div data-id="{{id}}" #container class="tiptap-emoji-list-cmp-contianer">
        <tiptap-emoji-list-item
            [class.selected]="item.name === ( selectedId | async)"
            (click)="selectItem(item.name)"
            [data]="item"
            *ngFor="let item of items| async">
        </tiptap-emoji-list-item>
      </div>
    `,
    styleUrls: [ './tiptap-emoji-list.cmp.scss' ],
})
export class TipTapEmojiListCmp {

    public items = new BehaviorSubject([]);
    public selectedId = new BehaviorSubject( '' );

    public id;

    public maxHeight = 320;

    /**
     * The text editor container element
     */
    @ViewChild( 'container' )
    protected container: ElementRef;


    constructor(
        protected elementRef: ElementRef,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
            this.id = Random.base62( 4 );
    }

    protected get containerElement(): HTMLElement {
        return this.container.nativeElement as HTMLElement;
    }

    public command = ( item, component = null ) => {};

    public updateProps( props ) {
        this.items.next( props.items );
        this.selectedId.next( props.items[0] ? props.items[0].name : '' );
        this.command = props.command;
        try {
            const { x, y } = props.clientRect();
            this.position( x, y );
        } catch ( error ) {
        }
    }

    public onKeyDown({ event }) {
        if ( event.key === 'ArrowUp' ) {
            this.upHandler();
            return true;
        }

        if ( event.key === 'ArrowDown' ) {
            this.downHandler();
            return true;
        }

        if ( event.key === 'Enter' ) {
            this.enterHandler();
            return true;
        }
        return false;
    }

    public upHandler() {
        const selectedIndex = this.items.value.findIndex( v => v.name === this.selectedId.value );
        const newIndex = (( selectedIndex + this.items.value.length ) - 1 ) % this.items.value.length;
        if ( this.items.value[ newIndex ]) {
            this.selectedId.next( this.items.value[ newIndex ].name );
        }
    }

    public downHandler() {
        const selectedIndex = this.items.value.findIndex( v => v.name === this.selectedId.value );
        const newIndex = ( selectedIndex + 1 ) % this.items.value.length;
        if ( this.items.value[ newIndex ]) {
            this.selectedId.next( this.items.value[ newIndex ].name );
        }
    }

    public enterHandler() {
        this.selectItem( this.selectedId.value );
    }

    public destroy() {
    }

    public position( x, y ) {
        if ( this.container ) {
            const el = this.containerElement;
            el.style.position = 'absolute';
            el.style.visibility = 'hidden';
            setTimeout(() => {
                const thisElement = document.getElementById( this.id );
                if ( thisElement ) {
                    const parentBounds  = thisElement.parentElement.parentElement.getBoundingClientRect();
                    let top;
                    const left = x -  parentBounds.x;
                    const height = el.getBoundingClientRect().height || this.maxHeight;
                    // If 75% of the component is not visible, palce it on top
                    if ( y + ( height * 0.75 )   > window.innerHeight ) {
                        top = y - parentBounds.y - height - 10;
                    } else {
                        top = y - parentBounds.y + 30;
                    }
                    el.style.top = top + 'px';
                    el.style.left =  left + 'px';
                    el.style.visibility = 'visible';
                }
            }, 10 );
        }
    }

    public selectItem( id ) {
        const item = this.items.value.find( v => v.name === id );
        this.command( item );
    }

}
