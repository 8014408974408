import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StateService, Command } from 'flux-core';
import { AbstractInitializationCommand } from 'flux-core';
import { Authentication, AuthenticationStatus } from '../authentication.svc';
import { tap } from 'rxjs/operators';

/**
 * Command for authentication.
 * This is fired by the initialization sequence when authentication is required.
 */
@Injectable()
@Command()
export class Authenticate extends AbstractInitializationCommand {

    constructor( protected authentication: Authentication,
                 protected state: StateService<any, any>,
                 protected router: Router ) {
        super( router )/* istanbul ignore next */;
    }

    /**
     * Number of milliseconds to wait before execute step finishes.
     * Zero or negative means infinite wait.
     */
    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    /**
     * Uses the authentication service to show the login widget.
     * @return  Observable that emits when the login widget closes with
     * a sucessful or failed authentication.
     */
    public execute(): Observable<AuthenticationStatus> {
        return this.authentication.authenticate().pipe(
            tap({
                error: error => {
                    this.log.error( 'Authentication error occured', error );
                    this.navigateToRoute( this.data.status );
                },
                complete: () => {
                    this.log.debug( 'Authentication completed' );
                    this.navigateToRoute( this.data.status );
                },
            }),
        );
    }
}

Object.defineProperty( Authenticate, 'name', {
    writable: true,
    value: 'Authenticate',
});
