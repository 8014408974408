import { CommandInterfaces, Command, AppConfig } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';

/**
 * This is a command used to remove all shared states of a user from the current client.
 * This command is never executed on a client (dispatched from within the client), instead
 * it is only received from the server. Aagin this is only expected to be sent down from
 * Neutrino. The command resultData us supposed to hold the user id's of each user who's
 * states are to be removed.
 *
 * This will usually received when a collaborating client closes the subscription or goes offline.
 */
@Injectable()
@Command()
export class EndStateSync extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {};
    }

    public static get implements(): Array<CommandInterfaces> {
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
            return [ 'IStateChangeCommand' ];
        }
        return [ 'IStateChangeCommand', 'IMessageCommand' ];
    }

    public get states(): { [ stateId: string ]: any } {
        return {};
    }

    public execute (): boolean {
        // This command is never supposed to run on the execute scenario
        return false;
    }
}

Object.defineProperty( EndStateSync, 'name', {
    value: 'EndStateSync',
});
