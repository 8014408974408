import { Injectable } from '@angular/core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { forEach } from 'lodash';
import { Command } from 'flux-core';

/**
 * This command marks a shape as a 'isTemplateShape=true'. this is limited to hardcoded
 * users on the system only and triggered by the debugger.
 *
 */
@Injectable()
@Command()
export class MarkAsTemplateShape extends AbstractDiagramChangeCommand {

    /**
     * Input data format for this command.
     * The list of shape ids of which the zIndex is being altered in can be
     * passed in to the command. If this is not passed in, shapes in
     * the current selection will be modified.
     */
    public data: {
        shapeIds?: Array<string>,
        reset?: boolean, // resets everything in the workspace without isTemplateShape false.
    };

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
    }

    public prepareData() {
        let model: any;
        if ( this.data.shapeIds && this.data.shapeIds.length ) {
            this.data.shapeIds.forEach( shapeId => {
                model = this.changeModel.shapes[shapeId];
                if ( model ) {
                    model.isTemplateShape = true;
                }
            });
        } else if ( this.data.reset ) {
            forEach( this.changeModel.shapes, shape => delete shape.isTemplateShape );
        }
    }
 }

Object.defineProperty( MarkAsTemplateShape, 'name', {
    value: 'MarkAsTemplateShape',
});
