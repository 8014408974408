import { Injectable, Injector, Inject } from '@angular/core';
import { AppConfig } from '../../app-config';
import { StateService } from '../../controller/state.svc';

const SEPARATOR: string = '.';

/**
 * Returns the OS
 */
export enum OS {
    Mac,
    Windows,
}

/**
 * This holds the platform or kinds of Creately app.
 */
export enum AppPlatform {
    EMBEDDED = 'embedded',
    ONLINE = 'online',
    DESKTOP = 'desktop',
    PLUGIN = 'plugin',
}

/**
 * This service is an abstraction for the container the app is running.
 * This class populates basic environment details common to all apps while
 * different flavors of apps (i.e. mobile device, desktop ) will extend
 * this to add specific functionality.
 * To use this service as is simply add it as a provider in the module.
 * If this class is extended in an app , use the following notation in the app modules
 * providers:
 * <code>{ provide: EnvContainer, useExisting : NameOfExtendedClass }</code>
 * @author  Ramishka
 * @since   2017-08-15
 */

@Injectable()
export class ContainerEnv {

    protected browserWindow: Window;

    protected _name: string;

    protected _os: string;

    protected _browser: string;

    /**
     * Constructor function. Injector is injected here so that any extending
     * class can use it to get instances of any platform specific services.
     * @param injector - Injector
     */
    constructor( protected injector: Injector,
                 @Inject( 'BrowserWindow' ) browserWindow: any,
                 protected state: StateService<any, any> ) {
        this.browserWindow = browserWindow;
        this.initialize();
    }

    /**
     * Returns the current language of the browser without the culture code.
     */
    public get currentLang(): string {
        const browserLang = this.browserWindow.navigator.language;
        if ( browserLang ) {
            const dashPosition = this.browserWindow.navigator.language.indexOf( '-' );
            return browserLang.substring( 0, dashPosition !== -1 ? dashPosition : browserLang.length );
        } else {
            return 'en';
        }
    }

    /**
     * This returns the name of the environment the app is running.
     * i.e. For a desktop version this would likely have the format of 'vendor : appName'
     * @readonly
     * @type {string}
     */
    public get name(): string {
        return this._name;
    }

    /**
     * This returns the OS name with the OS version of the device.
     * eg. MacIntel
     *
     * @readonly
     * @type {string}
     */
    public get os(): string {
        return this._os;
    }

    /**
     * This returns the name of the browser the app is running on.
     */
    public get browser(): string {
        return this._browser;
    }

    /**
     * This returns the browser's specific name the app is running on.
     */
    public get browserName(): string {
        const browser = this.browser.toLowerCase();
        switch ( true ) {
            case browser.indexOf( 'edge' ) > -1: return 'edge';
            case browser.indexOf( 'edg' ) > -1: return 'chromium-edge';
            case browser.indexOf( 'opr' ) > -1: return 'opera';
            case browser.indexOf( 'chrome' ) > -1: return 'chrome';
            case browser.indexOf( 'trident' ) > -1: return 'ie';
            case browser.indexOf( 'firefox' ) > -1: return 'firefox';
            case browser.indexOf( 'safari' ) > -1: return 'safari';
            default: return 'other';
        }
    }

    /**
     * Returns if this is a desktop enviornment
     */
    public get isDesktop(): boolean {
        return this._browser && this._browser.toLowerCase().indexOf( 'electron' ) > -1;
    }

    /**
     * This returns the OS
     */
    public get osName(): OS {
        switch ( this._os ) {
            case 'Macintosh':
            case 'MacIntel':
            case 'MacPPC':
            case 'Mac68K':
                return OS.Mac;
            case 'Windows':
            case 'Win16':
            case 'Win32':
            case 'Win64':
                return OS.Windows;

            default: return;
        }
    }

    /**
     * Returns true if it's Macintosh
     */
    public get isMac(): boolean {
        return this.osName === OS.Mac;
    }

    /**
     * This returns the platform/kind of Creately app
     * currently running on.
     */
    public get getCurrentPlatform(): AppPlatform {
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            return AppPlatform.PLUGIN;
        }
        if ( this.isDesktop ) {
            return AppPlatform.DESKTOP;
        }
        if ( this.state.get( 'ApplicationIsEmbedded' )) {
            return AppPlatform.EMBEDDED;
        }
        return AppPlatform.ONLINE;
    }

    /**
     * This method isß invoked upon construction. It starts populating
     * environment details.
     * If an extending class needs to do extra work (i.e. wait for a platform specific
     * service to initialize) before starting to gather environmen details,
     * this function should be overridden.
     */
    protected initialize() {
        this.populateEnvDetails();
    }

    /**
     * This method populates basic environment details in a generic way.
     * This should be overridden if application/ platform specific environment
     * data is needed.
     */
    protected populateEnvDetails() {
        if ( this.browserWindow.navigator ) {
            this._name = this.browserWindow.navigator.vendor + SEPARATOR + this.browserWindow.navigator.appName;
            this._browser = this.browserWindow.navigator.userAgent;
            this._os = this.browserWindow.navigator.platform;
        }
    }

}
