import { isEmpty, clone } from 'lodash';
import { AbstractSVGTextView } from './abstract-svg-text-view';
import { IShapeText, ITransform, TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { TextPostion } from '../../framework/text/text-position';
import { Rectangle } from 'flux-core';
import { IShapeTextView } from '../../framework/text/text-view.i';
import * as Handlebars from 'handlebars';

/**
 * ShapeSVGTextView
 * Process and generate svg elements for the texts on a shape object
 */
export class ShapeSVGTextView extends AbstractSVGTextView implements IShapeTextView {

    /**
     * Name of the text view, This will be used as the instance identfier for text views.
     */
    public name: string;

    /**
     * SVG element which represents the texts on the shape.
     */
    public svgTextElement: string;

    /**
     * The horizontal text padding value, this amount will be added to the
     * both left and right of the text
     * FIXME: Move this to a configuration
     */
    protected horizontalTextPadding: number  = 10;

    /**
     * Constructor
     * @param textModel Shape text model
     */
    public constructor( textModel: IShapeText ) {
        super()/* istanbul ignore next */;
        this.name = textModel.id;
    }

    /**
     * Update current SVG shape text with given updated text model and properties.
     * @param model Shape text model
     * @param change Text changes
     * @param bounds bounds of the shape
     * @param transform Transformation applied to the shape
     */
     public update( textModel: IShapeText, change?: any, bounds?: Rectangle, transform?: ITransform ) {
        let transformString = '';
        let model = textModel;
        // Apply the handlebar changes
        if ( !isEmpty(( model as any ).handlebars )) {
            model = clone( textModel );
            const data = JSON.parse(( model as any ).handlebars );
            const modified = model.content.map( run => {
                // Square brackets [] Allow for spaces when replacing
                let newText = run.text.replace( /\{\{/g, '{{{[' );
                newText = newText.replace( /\}\}/g, ']}}}' );
                const source = newText;
                let result = source;
                try {
                    const template = Handlebars.compile( source );
                    result = template( data );
                } catch ( e ) {
                }
                return { ...run, text: result };
            });
            model.content = modified;
        }
        if (( model as any ).rendering as any === 'tiptapCanvas' || ( model as any ).rendering as any === 'dom' ) {
            const wrapWidth = bounds.width * ( transform?.scaleX || 1 ) - 2 * TEXT_PADDING_HORIZONTAL;
            const textBounds = new Rectangle( 0, 0, wrapWidth, model.height );
            const point = TextPostion.forShape( model, bounds, textBounds, transform );
            const element = this.createHTMLElement( model );
            if ( transform && transform.angle ) {
                const pointOnShape = TextPostion.getPointOnShape( model.position, bounds, transform );
                transformString = `transform="rotate(${transform.angle}, ${pointOnShape.x}, ${pointOnShape.y})" `;
            }
            this.svgTextElement = '<foreignObject ' +
                transformString +
                `font-family="noto_regular" x="${point.x}" y="${point.y}" width="${textBounds.width}" height="${textBounds.height}">`
                + this.prepareHTML( element, ( model as any ).html ) +
                '</foreignObject>';
        } else {
            const textBounds = new Rectangle( 0, 0, model.width, model.height );
            const point = TextPostion.forShape( model, bounds, textBounds, transform );
            if ( transform ) {
                const angle: number = TextPostion.getTextAngle( transform );
                const pointOnText = TextPostion.getPointOnText( textBounds, model.alignX, model.alignY, transform );
                transformString = `transform="` +
                    `translate(${point.x}, ${point.y}) ` +
                    `rotate(${angle || 0}, ${pointOnText.x}, ${pointOnText.y})" `;
            }
            this.svgTextElement = '<g ' +
                transformString +
                `width="${textBounds.width}" height="${textBounds.height}">`
                + this.getTextElements( model ) +
                '</g>';
        }

    }

    public destroy() {
        // Nothing to do
    }
}
