import { DataType, IValidationError } from 'flux-definition';
import { AbstractDataItem } from './data-item-abstract';

/**
 * This does validation of view data items.
 * For the moment no validation is required.
 */
export class UsersDataItem extends AbstractDataItem<DataType.USERS> {
    /**
     * Checks whether the given value is valid (returns the error if any)
     */
    public validate( value: typeof UsersDataItem.prototype.value ): IValidationError {
        return null;
    }
}
