import { IDialogBoxData } from 'flux-definition';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IErrorMessageHandler } from '../../system/app-error-handler';
import { DialogBoxError } from './dialog-box-error';

/**
 * A controller which provies the data for the dialog to show. It implements
 * the IErrorMessageHandler so that it can be used by error manager to handle
 * errors which should use the DialogBox to show the error.
 */
@Injectable()
export class DialogBoxController implements IErrorMessageHandler {

    /**
     * Message content subject. This should be listen by DialogBox to show.
     */
    protected messageContent: Subject<IDialogBoxData>;

    constructor() {
        this.messageContent = new Subject();
    }

    /**
     * Returns the stream which emits the dialogBox data.
     * IMPORTENT This stram will be subscribed by DialoxBox component and
     * no others should use this stream.
     */
    public get dataStream() {
        return this.messageContent;
    }

    /**
     * Shows the dialog box with given data.
     * @param dialogBoxData
     */
    public showDialog( dialogBoxData: IDialogBoxData ) {
        this.dataStream.next( dialogBoxData );
    }

    /**
     * Implementation of IErrorMessageHandler Shows the error using DialogBox
     * if the error is DialogBoxError otherwise returns false.
     * @param error Error tho handle.
     */
    public handle( error: Error ): boolean {
        if ( error instanceof DialogBoxError ) {
            this.showDialog( error.data );
            return true;
        }
        return false;
    }
}
