import { isEqual } from 'lodash';

export class FormulaUtils {

    /**
     * a rudimentary formula parser that works with string replacement
     * @param formula
     * @param data
     */
    public static applyStringFormula( expression: string, data: any, input?: string ) {
        // break it to statements
        // FIXME this should be done by the parser.
        const statements = expression.split( ';' );
        statements.forEach( statement => {
            const parts = statement.split( '=' );
            if ( parts.length === 2 ) {
                const target = parts[0].trim().replace( /{{|}}/g, '' );
                const exp = parts[1].trim();
                let result = exp;

                // get the var names
                const vars = exp.match( /{{\s*[\w\.]+\s*}}/g )
                        .map( x => x.match( /[\w\.]+/ )[0]);

                for ( let i = 0; i < vars.length; i++ ) {
                    const id = vars[i];
                    if ( data[id]) {
                        result = result.replace( `{{${id}}}`, data[id]);
                    } else {
                        const paranMatch = `\({{${id}}}[^\)]+\)`;
                        result = result.replace( new RegExp( paranMatch ), '' );
                        result = result.replace( `{{${id}}}`, '' );
                    }
                }

                // strip any parans
                result = result.replace( /\(|\)/g, '' );

                if ( input ) {
                    result = result.replace( '{{$input}}', input );
                }

                if ( !isEqual( data[target], result )) {
                    // console.log( 'FormulaUtils.applyStringFormula', data[target], result );
                    data[target] = result;
                }
            } else {
                throw Error( 'invalid expression format' );
            }
        });
    }


    /**
     * replaces handlebars like {{varName}}.
     * Returns everything as a string generally but when only a single item is given it returns the
     * original type
     * @param expression
     * @param data
     */
    public static replaceHandlebars( expression: string, data: any ): any {
        const match = expression.match( /^{{(\w*)}}$/ );
        if ( match ) { // if this is the only piece in the string
            if ( match[1] && data[match[1]]) {
                return data[match[1]];
            }
        } else {
            let res = expression;
            for ( const id in data ) {
                if ( expression.indexOf( `{{${id}}}` ) > -1  ) {
                    res = expression.replace( `{{${id}}}`, data[id]);
                }
            }
            return res;
        }
    }
}
