import { Directive, Input, HostListener } from '@angular/core';
import { Tracker } from './tracker';

/**
 * Representation of trackAction directive input.
 * event: Id if the event to track
 * data: Optional data object to be recorded with the event
 */
interface IEventData {
    event: string;
    data?: Object;
}

/**
 * Directive TrackAction
 * This directive is used to track certain UI interactions.
 * If the interaction happens becuase of a UI component based on HTML
 * this directive can be added to the HTML component directly to track such events.
 */
@Directive({
    selector: '[trackAction]',
})
export class TrackAction {

    @Input()
    public trackAction: IEventData;

    /**
     * Everytime the HTML component is clicked, we will track it.
     */
    @HostListener( 'click', [ '$event' ]) onClick() {
        Tracker.track( this.trackAction.event, this.trackAction.data );
    }
}
