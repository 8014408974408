import { IErrorMessageHandler } from '../app-error-handler';
import { Injectable } from '@angular/core';
import { Logger } from '../../logger/logger.svc';

/**
 * This is an error handler that handles SecurityErrors thrown in
 * the app.
 *
 * @author  Ramishka
 * @since   2019-03-04
 */
@Injectable()
export class SecurityErrorHandler implements IErrorMessageHandler {

    constructor( protected logger: Logger ) {}

    /**
     * Error handler function
     * @param error error
     * @return true if error was identified and handled. false otherwise
     */
    public handle( error: any ): boolean {
        // The error is specifically handled to prevent an oops error being thrown
        // when certain svg files are imported using Uppy. In this particular scenario
        // the functionality is not broken but Uppy throws the error nevertheless.
        // I've opened a GitHub issue https://github.com/transloadit/uppy/issues/1321
        // for this and when this is fixed, this handler can be removed. - RD
        if ( error && error.code === 18 ) {
            this.logger.warning( `A ${error.name} was handled. Error mesasge: ${error.message}` );
            return true;
        }
        return false;
    }
}
