import { PeopleLocator } from './people-locator';
import { Tracker } from 'flux-core';
import { values, cloneDeep, omit, mapValues, pickBy } from 'lodash';
import { DataType, IDataItem, SystemType } from 'flux-definition';
import { Random } from 'flux-core';
import { DynamicComponent } from 'flux-core/src/ui';
import { TranslateService } from '@ngx-translate/core';
import { take, map, switchMap } from 'rxjs/operators';
import { SingleSelectComboCreator } from './ui-component-creators/single-select-combo-creator.cmp';
import { FormulaFieldCreator } from './ui-component-creators/formula-field-creator.cmp';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { AbstractDropDown } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy, ViewChild, Output, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { ComponentFactoryResolver,
    Injector, ViewContainerRef, ComponentRef, Input } from '@angular/core';
// import { PlanPermManager } from 'flux-user';
// import generate from '@creately/namegen';
import { EntityLookupCreator } from './ui-component-creators/entity-lookup-creator.cmp';
import { CoreDataFieldService, dataItemsMap, coreDataDefs } from '../../data-defs';
import { TaskSummaryService } from '../../task/task-summary.svc';

/**
 * AddDataItems
 * This component is to add ui controllers for data items
 *
 * @author thisun
 * @since 2021-01-19
 */
@Component({
    selector: 'add-data-items',
    template: `
        <div class="add-data-items ftb-item ftb-item-text">
            <abs-dropdown #dd [settings]="{ alwaysOpen: contentOnly, closeOnItemClicked: true, openOnHover: false, closeOnBlur: true }"
            [autoPosition]="false">
                <simple-dropdown-button ddbutton [items]="itemsObs | async">
                <button [class.fx-hidden]="contentOnly" class="btn-medium btn-secondary" (click)="trackAddItemClick()">
                    <span>+ <span translate>SHAPE_DATA.TABS.INFO.ADD_NEW_DATA</span></span>
                </button>
                </simple-dropdown-button>
                <simple-dropdown-item dditem [item]="item" *ngFor="let item of itemsObs | async">
                    <div class='add-data-items-separator' *ngIf="item.id ==='separator'"></div>
                    <span *ngIf="item.id !=='separator'" class="body">{{item.label}}</span>
                </simple-dropdown-item>
            </abs-dropdown>
        </div>
        <div #comboCreator></div>
    `,
    styleUrls: [ './add-data-items.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddDataItems extends DynamicComponent implements OnInit, AfterViewInit, OnDestroy {

    /**
     * For tracking purpose only.
     * This property is to identify where this component is being used.
     */
    @Input()
    public context?: string;

    /**
     * Will show the dropdown list always and
     * Add data item button will be hidden
     */
    @Input()
    public contentOnly?: boolean = false;

    @Input()
    public hasEData: Observable<boolean> = of( false );

    @Input()
    public dataItems: Observable<{ [id: string]: IDataItem<DataType> }> = of({});


    public singleSelectComboCreator: ComponentRef<SingleSelectComboCreator>;

    @ViewChild( AbstractDropDown, { static: false })
    public absDropdown: AbstractDropDown;
    public _data: BehaviorSubject<any>;

    /**
     * UI controllers for custom fields / derived fields
     * TODO: this will come from the DB in the future as users are able to
     * define them.
     */

    /**
     * UI controllers
     */

    @Output()
    public change: Subject<any> = new Subject();

    @Output()
    public propChange: Subject<any> = new Subject();

    public itemsObs;

    protected subs: Subscription[] = [];

    @ViewChild( 'comboCreator', { read: ViewContainerRef, static: false })
    protected comboCreatorContainerRef: ViewContainerRef;

    private itemsMap: typeof dataItemsMap;

    constructor(
        protected pl: PeopleLocator,
        protected translate: TranslateService,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        // protected permManger: PlanPermManager,
    ) {
        super( componentFactoryResolver, injector );
        this.itemsMap = mapValues( dataItemsMap, dataItem => ({
            ...dataItem,
            label: translate.instant( dataItem.label ),
        }));
    }

    public ngOnInit(): void {
        this.itemsObs = this.getItems( '*' );
    }

    public ngAfterViewInit() {
        const s = this.itemsObs.pipe(
            switchMap( items => this.absDropdown.changed.pipe( map( ids => ({ ids, items })))),
        ).subscribe(( val: any ) => { // ids[0] is the selected item's id
            const item = val.items.find( i => i.id === val.ids[0]);
            Tracker.track( 'right.data.info.addField.item.click', { value1: item.trackingId });
            if ( val.ids[0] === 'singleSelectCombo' ) {
                this.singleSelectComboCreator = this.makeComponent( SingleSelectComboCreator );
                this.insert( this.comboCreatorContainerRef, this.singleSelectComboCreator );
                ( this.singleSelectComboCreator.instance as any ).context = this.context;
                this.singleSelectComboCreator.changeDetectorRef.detectChanges();
                this.singleSelectComboCreator.instance.show();
                this.singleSelectComboCreator.instance.change.pipe( take( 1 )).subscribe( v =>  {
                    this.change.next( v );
                    this.remove( this.comboCreatorContainerRef, this.singleSelectComboCreator );
                });
            } else if ( val.ids[0] === 'formula' ) {
                const formulaCreator = this.makeComponent( FormulaFieldCreator );
                this.insert( this.comboCreatorContainerRef, formulaCreator );
                ( formulaCreator.instance as any ).context = this.context;
                formulaCreator.changeDetectorRef.detectChanges();
                formulaCreator.instance.change.pipe( take( 1 )).subscribe( v =>  {
                    this.change.next( v );
                });
                formulaCreator.instance.closed.pipe( take( 1 )).subscribe( v =>  {
                    this.remove( this.comboCreatorContainerRef, formulaCreator );
                });
            } else if ( val.ids[0] === 'entityLookup' ) {
                const lookupCreator = this.makeComponent( EntityLookupCreator );
                ( lookupCreator.instance as any ).context = this.context;
                this.insert( this.comboCreatorContainerRef, lookupCreator );
                lookupCreator.changeDetectorRef.detectChanges();
                lookupCreator.instance.change.pipe( take( 1 )).subscribe( v =>  {
                    this.change.next( v );
                });
                lookupCreator.instance.closed.pipe( take( 1 )).subscribe( v =>  {
                    this.remove( this.comboCreatorContainerRef, lookupCreator );
                });
            } else {
                if ( Object.keys( coreDataDefs ).includes( val.ids[0])) {
                    // core data fields
                    const roleFields = [ 'owner', 'dueDate', 'estimate' ];
                    if ( roleFields.includes( val.ids[0])) {
                        // add role field
                        this.propChange.next( TaskSummaryService.getDefaultTaskPayload());
                    } else {
                        const coreDI = {
                            value: CoreDataFieldService.getDefaultValue( val.ids[0]),
                            isCore: true,
                        };
                        this.propChange.next({
                            [val.ids[0]]: coreDI,
                        });
                    }
                    return;
                }
                const itm = cloneDeep( item );
                delete itm.trackingId;
                itm.layout = 'block';
                itm.id = Random.dataItemId();
                // if ( val.ids[0] === 'role' ) {
                //     itm.label = 'Role ' + generate({ words: 1 }).spaced;
                // }
                this.change.next( itm );
            }
        });
        this.subs.push( s );
    }

    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public getItems( searchQuery: string ) {
        return combineLatest([ this.pl.getAllPeople( searchQuery ), this.hasEData, this.dataItems ]).pipe(
            map(([ peopleData, hasEData, dataItems ]) => {
                const data = peopleData.map( p => {
                    const item = {
                        id: p.source.id,
                        type: DataType.USERS,
                        systemType: SystemType.Role,
                        visibility: [
                            { type: 'editor' },
                        ],
                        value: {
                            source: p.source,
                            people: [],
                        },
                        label: p.source.name,
                        labelEditable: true,
                        optional: true,
                        validationRules: {},
                        trackingId: 'people',
                    };
                    return item;
                });

                const coreDataFields = pickBy(
                    CoreDataFieldService.getCoreDataDefs(), ( v, path ) => !dataItems[ path ]);

                const itemsMap = omit( this.itemsMap, hasEData ? [ 'tags' ] : [ 'tags', 'entityLookup', 'formula' ]);
                return [ ...values( itemsMap ), ...values( coreDataFields ), ...data ];
            }),
        );
    }


    /* istanbul ignore next */
    public trackAddItemClick() {
        if ( this.context ) {
            Tracker.track( `${this.context}.addField.click` );
        }
    }

}
