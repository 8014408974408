export interface ITreeNode<T> {
    id: string;
    value: T;
    children?: ITreeNode<T>[];
    parentId?: string;
}

/**
 * A tree data structure which can output a flat array structure.
 * It can take a param for to append to each node based on the level of the
 * nesting as well.
 */
export class ATree<T> {
    /**
     * The root level nodes of the tree. No parent.
     */
    protected _rootNodes: ITreeNode<T>[] = [];

    /**
     * All the nodes in the tree.
     */
    protected allNodes: { [key: string]: ITreeNode<T>} = {};

    /**
     * Internal structure to keep track of orphans while bulding the tree.
     */
    private orphans: { [parentId: string]: ITreeNode<T>[] } = {};

    protected get rootNodes() {
        const parentLessNodes = Object.values( this.allNodes )
            .filter( node => node.parentId && !this.allNodes[ node.parentId ]);
        return [ ...this._rootNodes, ...parentLessNodes ];
    }

    /**
     * adds an Item to the tree
     * @param id
     * @param value
     * @param parentId
     */
    public addItem( id: string, value: T, parentId?: string ) {
        const node: ITreeNode<T> = {
            value: value,
            id: id,
            parentId: parentId,
        };
        this.addNode( node, parentId );
    }

    /**
     * Returns a flattened array in the right order
     * @param addTo
     */
    public toArray( sortFunc: Function, addTo?: [ string, number ]): T[] {
        this.adoptAllOrphans();
        let retArr = [];
        const rootNodes = this.rootNodes;
        rootNodes.sort(( a, b ) => sortFunc( a.value, b.value ));
        rootNodes.forEach( root => {
            const ret = this.traverse( root, 1, sortFunc, addTo );
            retArr = [ ...retArr, ...ret ];
        });
        return retArr.map( item => item.value );
    }

    /**
     * Get the root
     */
    public getRootNodes() {
        return this.rootNodes;
    }

    public getNode( id: string ): ITreeNode<T> {
        return this.allNodes[id];
    }

    /**
     * Remove a node from the tree
     * @param id
     * @param deleteChildren
     * @returns
     */
    public removeItem ( id: string, deleteChildren: boolean = false ) {
        if ( !this.allNodes[ id ]) {
            return;
        }

        const node = this.getNode( id );
        this.adoptAllOrphans();

        const index = this._rootNodes.findIndex( item => item.id === id );
        if ( this._rootNodes[index]) {
            this._rootNodes.splice( index, 1 );
        }

        // Remove node from the children list of the parent
        if ( node.parentId && this.allNodes[ node.parentId ]) {
            const children = this.allNodes[ node.parentId ].children;
            this.allNodes[ node.parentId ].children = children.filter( item => item.id !== id );
        }

        // Remove children if needed
        if ( deleteChildren && node.children ) {
            node.children.forEach( child => {
                this.removeItem( child.id, true );
            });
        }

        // Finally deletes the node from the allNodes list
        delete this.allNodes[id];
    }

    /**
     * Remove node from existing parent and assign to new
     * @param nodeId
     * @param newParentId
     */
    public switchParent( id: string, newParentId: string ) {
        if ( !this.allNodes[ id ]) {
            return;
        }
        const node = this.getNode( id );
        this.removeItem( id );
        this.addItem( id, node.value, newParentId );
    }

    /**
     * Adds a node. if there is a parent, adds it to the parent's children list as well
     * If parent is not there, it keeps it in the orphan list of the parent.
     * @param node
     * @param parentId
     */
    protected addNode( node: ITreeNode<T>, parentId?: string ) {
        if ( !parentId ) {
            this._rootNodes.push( node );
            this.allNodes[node.id] = node;
            return;
        }

        if ( this.allNodes[ parentId ]) {
            if ( !this.allNodes[ parentId ].children ) {
                this.allNodes[ parentId ].children = [];
            }
            this.allNodes[ parentId ].children.push( node );
        } else {
            if ( !this.orphans[parentId]) {
                this.orphans[parentId] = [];
            }
            this.orphans[parentId].push( node );
        }
        this.allNodes[node.id] = node;
    }

    /**
     * completes the tree by attaching orphans to the right parent etc.
     */
    protected adoptAllOrphans() {
        for ( const parentId in this.orphans ) {
            const node = this.allNodes[parentId];
            if ( node ) {
                this.adoptOrphans( node );
            }
        }
    }

    /**
     * Adopts all the orphans for the
     * @param parentId
     */
    protected adoptOrphans( node: ITreeNode<T> ) {
        if ( this.orphans[ node.id ]) {
            if ( !node.children ) {
                node.children = [];
            }
            this.orphans[node.id].forEach( item => {
                node.children.push( item );
            });
        }
    }

    /**
     * Travese the tree from the given node down and return a flattened array.
     * increments whatever that's passed in addTo
     * @param node
     * @param lastAdd
     * @param addTo
     */
    protected traverse
        ( node: ITreeNode<T>, lastAdd: number, sortFunc: Function, addTo?: [ string, number ]): ITreeNode<T>[] {
        let retArr = [ node ];
        if ( addTo ) {
            node.value[addTo[0]] = lastAdd;
        }
        if ( node.children ) {
            node.children.sort(( a, b ) => sortFunc( a.value, b.value ));
            const add = lastAdd + addTo[1];
            node.children.forEach( child => {
                const childs = this.traverse( child, add, sortFunc, addTo );
                retArr = [ ...retArr, ...childs ];
            });
        }
        return retArr;
    }
}
