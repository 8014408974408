import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    NotificationType,
    NotifierController,
    StateService,
    DateFNS,
    AbstractNotification,
    AppConfig,
} from 'flux-core';
import { UserLocator } from 'flux-user';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../base/diagram/locator/diagram-locator-locator';
import { Notifications } from '../base/notifications/notification-messages';
import { INotification, IRichNotification } from '../base/ui/global-notification/global-notification-notifications/global-notification-notifications.cmp';
import { MarketingNotification } from '../base/ui/marketing-notification/marketing-notification.cmp';


/**
 * Global notification service to manage notifications which are stored in State service as a list.
 *
 * @author  Sajeeva
 * @since   2021-10-01
 */
@Injectable()
export class GlobalNotificationService {

    /**
     * GlobalNotification state name.
     */
    public GLOBAL_NOTIFICATIONS: string = 'GlobalNotifications';


    /**
     * GlobalNotification Marketing state name.
     */
    public MARKETING_GLOBAL_NOTIFICATION: string = 'MarketingGlobalNotification';

    /**
     * How long to show push notifications. ( in milliseconds )
     */
    private timeToShowPushNotifcation: number = 10000;

    constructor(
        protected stateService: StateService<any, any>,
        protected userLocator: UserLocator,
        protected translate: TranslateService,
        protected notifierController: NotifierController,
        protected dll: DiagramLocatorLocator,
    ) {
        this.stateService.set( this.GLOBAL_NOTIFICATIONS, []);
    }

    /**
     * Sets given array of notifications to state service. This will remove
     * any existing notifications from the state.
     * @param notifications
     */
    public setGlobalNotifications( notifications: any[]) {
        const cachedNotifications: INotification[] =
                this.stateService.get( this.GLOBAL_NOTIFICATIONS ) as INotification[] || [];
        if ( notifications && notifications.length > 0 ) {
            notifications.forEach( notif => {
                if ( notif.key.startsWith( 'app.marketing' )) {
                    this.setMarketingMessage( notif );
                } else if ( !cachedNotifications.find( cachedNot => cachedNot._id === notif._id )) {
                    cachedNotifications.push( notif );
                }
            });
            this.stateService.set( this.GLOBAL_NOTIFICATIONS, cachedNotifications );
        }
    }

    /**
     * Adds a new notification to existing notification list in the notification state.
     * @param notification
     */
    public addNewGlobalNotification( notification: any ) {
        if ( notification.key.startsWith( 'app.marketing' )) {
            this.setMarketingMessage( notification );
        } else {
            let notifications: any[] = this.stateService.get( this.GLOBAL_NOTIFICATIONS );
            if ( !notifications ) {
                notifications = [];
            }
            notifications.unshift( notification );
            this.stateService.set( this.GLOBAL_NOTIFICATIONS, notifications );
            this.pushNotification( notification );
        }
    }

    /**
     * Retrieves the notifications data from state service and returns as raw data without
     * any aditional data.
     * @returns
     */
    public getGlobalNotificationRawData(): Observable<INotification[]> {
        return this.stateService.changes( this.GLOBAL_NOTIFICATIONS ).pipe(
            map( notifications => notifications || []),
        );
    }

    /**
     * Retrieves the notifications data from state service and enrich the data with relavent
     * information and returns it.
     * @returns
     */
    public getGlobalNotifications(): Observable<IRichNotification[]> {
        return this.getGlobalNotificationRawData().pipe(
            switchMap(( notifications: INotification[]) => {
                const notificationsObs = notifications.map( notification => this.enrichNotification( notification ));
                return combineLatest( notificationsObs );
            }),
        );
    }

    /**
     * Retrieves the unread notification's ids and returns it.
     * @returns
     */
    public getUnreadNotificationIds(): Observable<string[]> {
        return this.getGlobalNotificationRawData().pipe(
            map( notifications => {
                if ( notifications && notifications.length > 0 ) {
                    return notifications.filter( notification => !notification.seen )
                                        .map( notification => notification._id );
                }
                return [];
            }),
        );
    }

    /**
     * Returns the number of notification available on the cache.
     * @returns Number of cached notifications.
     */
    public getNoOfGlobalNotifications() {
        return ( this.stateService.get( this.GLOBAL_NOTIFICATIONS ) as INotification[])?.length || 0;
    }

    /**
     * Returns the number of unread notifications.
     * @returns
     */
    public getNoOfUnreadGlobalNotification(): Observable<number> {
        return this.getUnreadNotificationIds().pipe(
            map( notificationIds => notificationIds ? notificationIds.length : 0 ),
        );
    }

    /**
     * Returns the boolean if there are one or more unread global notifications.
     * @returns
     */
    public hasUnreadGlobalNotifications(): Observable<boolean> {
        return this.getNoOfUnreadGlobalNotification().pipe(
            map( count => count > 0 ),
        );
    }

    /**
     * Update given array of global notification's seen status to given boolean.
     * @param notificationIds
     * @param isRead
     * @returns
     */
    public markGlobalNotificationRead( notificationIds: string[], isRead: boolean ): Observable<INotification[]> {
        return this.getGlobalNotificationRawData().pipe(
            take( 1 ),
            tap( notifications => {
                if ( !!notifications && notifications.length > 0
                    && !!notificationIds && notificationIds.length > 0 ) {
                    notifications.forEach( notification => {
                        if ( notificationIds.includes( notification._id )) {
                            notification.seen = isRead;
                        }
                    });
                    this.setGlobalNotifications( notifications );
                }
            }),
        );
    }

    protected setMarketingMessage( notification: INotification ) {
        const cachedNotification = this.stateService.get( this.MARKETING_GLOBAL_NOTIFICATION ) as INotification;
        if ( cachedNotification ) {
            this.showMarketingMessage( cachedNotification );
        } else {
            if ( !notification.seen && DateFNS.isPast( notification.data.expiresAt )) {
                this.stateService.set( 'SeenMarketingGlobalNotification', notification._id );
            } else if ( !notification.seen ) {
                this.stateService.set( this.MARKETING_GLOBAL_NOTIFICATION, notification );
                this.showMarketingMessage( notification );
            }
        }
    }

    protected showMarketingMessage( notification: INotification ) {
        const waitingTime = notification.data.waitingTime || 120;
        setTimeout(() => {
            this.showMarketingPushNotification( notification );
            this.stateService.set( this.MARKETING_GLOBAL_NOTIFICATION, '' );
        }, waitingTime * 1000 );
    }

    /**
     * Return formated time how long ago the action happened.
     */
    protected formatTime( notification: IRichNotification ): string {
        return `${DateFNS.formatDistance( notification.serverTime, new Date().getTime())}`;
    }

    /**
     * CHecks if current user is affected user or not. This check is done using affectedUsers property of data.
     */
    protected isAffectedUser( notification: IRichNotification ): boolean {
        if ( notification?.data?.affectedUsers ) {
            return !!( notification.data.affectedUsers as Array<any> )
                        .find( affectedUser => affectedUser.id === this.stateService.get( 'CurrentUser' ));
        }
        return false;
    }

    /**
     * Check the notification and push, if it is a push notification.
     * @param notification
     */
    protected pushNotification( notification: INotification ) {
        this.shouldPush( notification ).pipe(
            filter( push => push ),
            switchMap(() => this.enrichNotification( notification )),
            take( 1 ),
            tap( richNotification => this.showPushNotification( richNotification )),
        ).subscribe();
    }

    /**
     * Trigger the push notification with relavant data.
     * @param notification Notification to show.
     */
    protected showPushNotification( notification: IRichNotification ) {
        const actorInfo = notification.actorInfo;
        const options = {
            inputs: {
                heading: actorInfo?.fullName || actorInfo?.email,
                description: notification.translatedContentHTML,
                user: actorInfo,
                autoDismiss: true,
                dismissAfter: this.timeToShowPushNotifcation,
            },
        };

        this.notifierController.show( Notifications.PUSH_NOTIFICATION, AbstractNotification,
            NotificationType.Push, options );
    }

    protected showMarketingPushNotification( notification: INotification ) {
        const notificationData = {
            id: Notifications.SETUP_DATABASE,
            component: MarketingNotification,
            type: NotificationType.Neutral,
            collapsed: false,
            options: {
                inputs: this.getMarketingNotificationDataInput( notification ),
            },
        };
        this.stateService.set( 'SeenMarketingGlobalNotification', notification._id );
        this.notifierController.show( Notifications.SETUP_DATABASE, notificationData.component,
            notificationData.type, notificationData.options, notificationData.collapsed );
    }

    protected getMarketingNotificationDataInput( notification: INotification ) {
        const translate = this.translate.instant.bind( this.translate );
        const noticiationTypeForTranslate = notification.data.notificationType.toUpperCase();
        const input: any = {};

        const heading = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.HEADING` );
        if ( !heading.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.heading = heading;
        }

        const description = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.DESCRIPTION` );
        if ( !description.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.description = description;
        }

        const descriptionSub1 = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.DESCRIPTION_SUB_1` );
        if ( !descriptionSub1.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.descriptionSub1 = descriptionSub1;
        }

        const descriptionSub2 = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.DESCRIPTION_SUB_2` );
        if ( !descriptionSub2.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.descriptionSub2 = descriptionSub2;
        }

        const descriptionSub3 = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.DESCRIPTION_SUB_3` );
        if ( !descriptionSub3.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.descriptionSub3 = descriptionSub3;
        }

        const descriptionSub4 = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.DESCRIPTION_SUB_4` );
        if ( !descriptionSub4.startsWith( 'NOTIFICATIONS.MARKETING' )) {
            input.descriptionSub4 = descriptionSub4;
        }

        input.buttonOneText = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.BUTTON_ONE_TEXT` ),
        input.buttonTwoText = translate( `NOTIFICATIONS.MARKETING.${noticiationTypeForTranslate}.BUTTON_TWO_TEXT` ),
        input.buttonOneAction = () => {
            window.open( AppConfig.get( 'SITE_URL' ) + notification.data.siteUrlPath );
            this.notifierController.hide( Notifications.SETUP_DATABASE );
        };
        input.buttonTwoAction = () => {
            this.notifierController.hide( Notifications.SETUP_DATABASE );
        };

        return input;
    }

    /**
     * Checks if it should show this noticiation as pus notification.
     * @param pushType
     * @param resourceId
     * @param resourceType
     * @returns boolean
     */
    protected shouldPush( notification: INotification ): Observable<boolean> {
        if ( notification.push === 'any' ) {
            return of( true );
        } else if ( notification.push === 'resourceOpen' ) {
            if ( notification.resourceType === 'DIAGRAM' ) {
                return of( this.stateService.get( 'CurrentDiagram' ) === notification.resourceId );
            } else if ( notification.resourceType === 'PROJECT' ) {
                return this.dll.forCurrentObserver( false ).pipe(
                    switchMap ( locator => locator.getDiagramOnce()),
                    take( 1 ),
                    map( diagram => ( diagram as any ).project === notification.resourceId ),
                );
            }
        }
        return of( false );
    }

    /**
     * Add relavent information to given notification.
     * @param notification
     * @returns
     */
    protected enrichNotification( notification: INotification ): Observable<IRichNotification> {
        return this.userLocator.getUserInfo( notification.actor ).pipe(
            filter( userInfo => !!userInfo ),
            distinctUntilChanged(( prev, curr ) => prev.fullName === curr.fullName ),
            map( userInfo => {
                const richNotification: IRichNotification = notification as IRichNotification;
                richNotification.actorInfo = userInfo;
                return richNotification;
            }),
            switchMap( richNotification => this.enrichAffectedUsers( richNotification )),
            switchMap( richNotification => this.getContentHTMLFromTemplate( richNotification ).pipe(
                map( html => {
                    richNotification.translatedContentHTML = html;
                    return richNotification;
                }),
            )),
            map( richNotification => {
                richNotification.isAffectedUser = this.isAffectedUser( richNotification );
                richNotification.actionSVG = this.actionSvgPath( richNotification );
                richNotification.formatedTime = this.formatTime( richNotification );
                return richNotification;
            }),
        );
    }

    /**
     * Add relavent user information to given notification.
     * @param notification
     * @returns
     */
    protected enrichAffectedUsers( notification: IRichNotification ): Observable<IRichNotification> {
        if ( !!notification?.data?.affectedUsers && notification?.data?.affectedUsers.length > 0 ) {
            const obsArray = ( notification.data.affectedUsers as Array<any> ).map( user => this.getUserInfo( user ));
            return combineLatest( obsArray ).pipe(
                map( users => {
                    notification.data.affectedUsers = users;
                    return notification;
                }),
            );
        }
        return of( notification );
    }

    /**
     * Retrieve and assign additional user information to given user object.
     * @param user
     * @returns
     */
    protected getUserInfo( user: any ): Observable<any> {
        return this.userLocator.getUserInfo( user.id ).pipe(
            map( userInfo => {
                user.fullName = userInfo?.fullName || userInfo?.email;
                return user;
            }),
        );
    }

    /**
     * Notification tranlated string for given notification.
     */
    protected getContentHTMLFromTemplate( notification: IRichNotification ): Observable<string> {
        const templateId = 'GLOBAL_NOTIFICATION.'
                + ( this.isAffectedUser( notification ) ? 'AFFECTED_USER.' : 'OTHERS.' )
                + this.formatToContantString( notification.key );

        return this.affectedUsers( notification ).pipe(
            switchMap( affectedUsersTrans => this.translate.get( templateId, {
                resourceId: notification.resourceId,
                resourceName: notification.resourceName,
                actorName: notification.actorInfo.fullName,
                affectedUsers: affectedUsersTrans,
                data: notification.data,
            })),
        );
    }

    /**
     * Return the path for inner action icon. This path is retrieved based on the notification key.
     */
    protected actionSvgPath( notification: IRichNotification ) {
        const svgIdPart = notification.key.toLowerCase().split( '.' );
        svgIdPart.shift();
        const svgIdPartStr = svgIdPart.join( '-' ) + ( this.isAffectedUser( notification ) ? '-you' : '' );
        return `./assets/icons/symbol-defs.svg#nu-ic-notification-${svgIdPartStr}`;
    }

    /**
     * Returns formated user names as comma seperated strings.
     * If there are more than 3 users then it returns 3 username and the number of count on rest of the users.
     */
    protected affectedUsers( notification: IRichNotification ): Observable<String> {
        const affectedUsersArray = notification?.data?.affectedUsers;
        if ( affectedUsersArray && affectedUsersArray.length > 0 ) {
            const isAffected = this.isAffectedUser( notification );
            const firstUserName = affectedUsersArray[ 0 ].fullName;
            if ( affectedUsersArray.length === 1 ) {
                return isAffected ? this.translate.get( 'GLOBAL_NOTIFICATION.YOU' ) :
                                    ( firstUserName ? of( firstUserName ) :
                                                        this.translate.get( 'A_USER' ));
            } else {
                const templateId = isAffected ? 'GLOBAL_NOTIFICATION.YOU_AND_NO_OF_OTHERS' :
                                                firstUserName ? 'GLOBAL_NOTIFICATION.USER_NAME_AND_NO_OF_OTHERS' :
                                                                'FEW_USERS';
                return this.translate.get( templateId, {
                    userName: firstUserName,
                    noOfOthers: affectedUsersArray.length - 1,
                });
            }
        }
        return of( null );
    }

    /**
     * Retrieves the string to identify the translation name from given event key.
     * This is used to get the tranlated template string for the notification.
     * @param input Key of the event
     * @returns Retruns the corresponding translation name.
     */
    protected formatToContantString( input: string ) {
        return input.toUpperCase().replace( /\./g, '_' );
    }
}

export enum MarketingNotificationType {
    Sample = 'sample',
}
