import { Component } from '@angular/core';

@Component({
  selector: 'app-collapsible-menu',
  template: `
  <div class="col-menu-container">
    <ng-content></ng-content>
  </div>
`,
styleUrls: [ './collapsible-menu.scss' ],
})

/**
 * App Collapsible Menu is a component that allows us to generate a menu which has
 * items that can be expanded and collapsed.
 *
 * NOTE: This component needs to be always preferred over CollapsibleMenu since its light weight
 * This should be used for all scenario.
 */
export class AppCollapsibleMenu {}
