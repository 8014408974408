import { CUSTOM_SHAPE_LIB_ID_PREFIX, LibraryType } from './../../editor/library/abstract-shape-library';
import { ModelChangeUtils } from './model-change-utils';
import { Sakota, Proxied } from '@creately/sakota';
import { EntityModel } from './../../base/edata/model/entity.mdl';
import { ProjectCommandEvent } from 'flux-diagram';
import { ShapeModel } from '../../base/shape/model/shape.mdl';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { EDataLocatorLocator } from './../../base/edata/locator/edata-locator-locator';
import { DiagramLocatorLocator } from './../../base/diagram/locator/diagram-locator-locator';
import { EDataCommandEvent } from './../../base/edata/command/edata-command-event';
import { EDataSub } from './../../base/edata/edata.sub';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { ProjectDiagramCommandEvent } from '../../creator/project/command/project-diagram-command.event';

import { DialogBoxController } from 'flux-core/src/ui';
import { CommandService, StateService, Random, Logger } from 'flux-core';
import { Injectable } from '@angular/core';
import { switchMap, take, tap, last, mapTo, filter } from 'rxjs/operators';
import { of, forkJoin, EMPTY, Observable } from 'rxjs';
import { EDataModel } from '../../base/edata/model/edata.mdl';
import { DiagramModel } from '../../base/diagram/model/diagram.mdl';
import { EDataRegistry } from '../../base/edata/edata-registry.svc';
import { ShapeAddedBinder } from '../../editor/diagram/bindings/shape-added-binder';
import { AbstractShapeModel } from '../../../../../libs/flux-diagram-composer/src';
import { IEntityDef } from 'flux-definition';

// tslint:disable:member-ordering
/**
 * EDataManage service is to handle actions related to edata management.
 * The same edata manage command can be triggered in multiple places in different ways.
 * So this service is to dispatch the actual command while handling ui, i.e. show the comnfirmation box,
 * show success notification, show error notificaiton etc.
 * @author  thisun
 * @since  2021 / 09 / 20
 */
@Injectable()
export class EDataManage {

    constructor(
        protected commandService: CommandService,
        protected state: StateService<any, any>,
        protected dialogBoxController: DialogBoxController,
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        protected modelSubManager: ModelSubscriptionManager,
        protected modelChangeUtils: ModelChangeUtils,
    ) {
    }

    /**
     * Create a new custom database
     */
    public createCustomDB( eDataId: string, name: string ) {
        const pId = this.state.get( 'CurrentProject' );
        return this.commandService.dispatch( ProjectDiagramCommandEvent.createEData, pId,
        { id: eDataId, defId: 'creately.edata.custom', name }).pipe(
            last(),
            switchMap( results => {
                if ( results && results.resultData[0] && results.resultData[0][1] &&  results.resultData[0][1].model ) {
                    const newModel = results.resultData[0][1].model as EDataModel;
                    return this.commandService.dispatch( DiagramCommandEvent.addEDataModel, {
                        eDataModelId: newModel.id,
                    });
                }
                throw new Error( 'Failed add the db to the diagram' );
            }),
            last(),
            switchMap(() => this.modelSubManager.start( EDataSub, eDataId )),
        );
    }

    /**
     * Create a new entity and bind it to the specified shape
     */
    public bindNewEntityToShape( eDataId: string, shapeId: string, entityDefId: string, setPreferred = false ) {
        const diagramId = this.state.get( 'CurrentDiagram' );
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( l => l.getDiagramOnce()),
            switchMap(( diagram: DiagramModel ) => this.ell.getEDataModel( eDataId ).pipe( tap(  mdl => {
                const shape = diagram.shapes[ shapeId ];
                if ( shape.entityId ) { // Already bound to an entity
                    return of({});
                }
                if ( mdl ) {
                    const changeModel = Sakota.create( mdl );
                    const entity = this.addEntityToEData( changeModel, diagram, shape as any, entityDefId );
                    if ( setPreferred ) {
                        entity.preferredShapeDef = {
                            id: shape.defId,
                            version: shape.version,
                        };
                    }
                    this.modelChangeUtils.addShapeToEntity(
                        changeModel,
                        {
                            entityId: entity.id,
                            shapeId: shape.id,
                            shapeDefId: shape.defId,
                            diagramId,
                        },
                    );
                    const modifier = changeModel.__sakota__.getChanges();
                    this.commandService
                        .dispatch( EDataCommandEvent.applyModifierEData, changeModel.id, { modifier }).pipe(
                            last(),
                            switchMap(() =>
                                this.addEntityToShape(
                                    diagramId, shapeId, eDataId, entity.id,
                                    changeModel.getEntityDataItems( entity.id, true, true ), entityDefId, mdl.defId )),
                        ).subscribe();
                } else {
                    Logger.error( 'Edatamodel not found' );
                }
            }))),
        );
    }

    public bindNewEntitiesToShapes( eModel: EDataModel, shapes: AbstractShapeModel[],
                                    entityDef: IEntityDef, diagram: DiagramModel ) {
        const changeModel = Sakota.create( eModel );
        const entities = shapes.map( shape => {
            const entity = this.addEntityToEData( changeModel, diagram, shape as ShapeModel, entityDef );
            this.modelChangeUtils.addShapeToEntity(
                changeModel,
                {
                    entityId: entity.id,
                    shapeId: shape.id,
                    shapeDefId: shape.defId,
                    diagramId: diagram.id,
                },
            );
            return entity;
        });
        const modifier = changeModel.__sakota__.getChanges();
        this.commandService
            .dispatch( EDataCommandEvent.applyModifierEData, changeModel.id, { modifier }).pipe(
                last(),
                switchMap(() =>
                    this.addEntitiesToShapes(
                        diagram.id, shapes.map( s => s.id ),
                        eModel.id, entities.map( e => e.id ), entityDef.id )),
            ).subscribe();
        return EMPTY;
    }

    private addEntityToEData(
        changeModel: Proxied<EDataModel>,
        diagram: DiagramModel, shape: ShapeModel, entityDefOrId: string | IEntityDef ): EntityModel {
            let entityDefId: string = entityDefOrId as any;
            if ( typeof entityDefOrId !== 'string' ) {
                entityDefId = entityDefOrId.id;
            }
            const entity = new EntityModel( Random.entityId(), entityDefId );
            entity.defId = changeModel.defId;
            entity.style = {
                shape: { ...shape.style },
                bounds: {
                    width: shape.width,
                    height: shape.height,
                    angle: shape.angle,
                    defaultBounds: shape.defaultBounds,
                },
            };
            entity.defaultShapeContext = shape.shapeContext;
            const predefinedDef = entity.defId !== EDataRegistry.customEdataDefId;
            if ( predefinedDef ) {
                const entityDef = typeof entityDefOrId === 'string' ? entity.getDef() : entityDefOrId;
                if ( entityDef && entityDef.dataItems ) {
                    ShapeAddedBinder.initEntity( entity, entityDef );
                }
            }
            this.modelChangeUtils.addEntity( changeModel, {
                entity: entity,
                shape: shape,
                diagram: diagram,
            }, true, predefinedDef );
            return entity;
    }

    /**
     * Addeds entity to the shape
     */
    private addEntityToShape( diagramId, shapeId, eDataId, entityId, data, entityDefId, eDataDefId ): Observable<any> {
        return this.commandService
            .dispatch( DiagramCommandEvent.addEntityToShape, diagramId, {
                shapeId, eDataId, entityId, data, entityDefId, eDataDefId,
            });
    }

    private addEntitiesToShapes( diagramId, shapeIds, eDataId, entityIds, entityDefId ): Observable<any> {
        return this.commandService
            .dispatch( DiagramCommandEvent.addEntitiesToShapes, diagramId, {
                shapeIds, eDataId, entityIds, entityDefId,
            });
    }

    /**
     * Create a new type from the selected shape, in the given database
     * @param eDataId Database id
     * @param entityDefName Name of the type
     */
    public createType( eDataId: string, entityDefName: string ): Observable<string> {
        const shapeIds = this.state.get( 'Selected' );
        if ( shapeIds.length !== 1 ) {
            return EMPTY;
        }

        return this.ell.getEDataModel( eDataId ).pipe(
            switchMap( model => {
                if ( model ) {
                    const entityDefId = Random.entityId(); // TODO add new id generation
                    return this.commandService.dispatch( EDataCommandEvent.updateEntityDefs, eDataId, {
                        shapeId: shapeIds[0],
                        entityDefId,
                        entityDefName,
                    }).pipe(
                        last(),
                        mapTo( entityDefId ),
                        tap(() => {
                            // Automatically add custom types lib when a new type is created
                            // Always trigger a CurrentLib state change to open the FAB
                            // if not open and change library group [done in ].
                            let libs = [ ...this.state.get( 'CurrentLibraries' ) ];
                            libs = libs.filter( item => item.id !== CUSTOM_SHAPE_LIB_ID_PREFIX + eDataId );
                            libs.unshift(
                                {
                                    id: CUSTOM_SHAPE_LIB_ID_PREFIX + eDataId,
                                    type: LibraryType.Static,
                                    status: 'loading',
                                    libGroup: 'Shapes',
                                },
                            );
                            this.state.set( 'CurrentLibraries', libs );
                        }),
                    );
                } else {
                    throw new Error( 'No edata model found' );
                }
            }),
        );
    }

    /**
     * Update the Type of the selected shape ( Sync new fields )
     * @param name New name ( optional )
     */
    public updateType( name?: string ) {
        const shapeIds = this.state.get( 'Selected' );
        if ( shapeIds.length !== 1 ) {
            return EMPTY;
        }
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( l => l.getShapeOnce( shapeIds[0])),
            tap(( model: ShapeModel ) => {
                const eDataId = model.eDataId;
                if ( eDataId ) {
                    return this.commandService.dispatch(
                        EDataCommandEvent.updateEntityDefsAndCreateMirrorFields,
                        eDataId,
                        {
                            shapeId: shapeIds[0],
                            entityDefId: model.entityDefId,
                            entityDefName: name,
                        },
                    );
                }
            }),
        );
    }

    /**
     * Make the type of the selected shape editable
     * @param editable boolean
     */
    public makeTypeEditable( editable: boolean ) {
        const shapeIds = this.state.get( 'Selected' );
        if ( shapeIds.length !== 1 ) {
            return EMPTY;
        }
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( l => l.getShapeOnce( shapeIds[ 0 ])),
            tap(( model: ShapeModel ) => {
                const eDataId = model.eDataId;
                if ( eDataId ) {
                    return this.commandService.dispatch( EDataCommandEvent.switchTypeEditable, eDataId, {
                        editable,
                        entityDefId: model.entityDefId,
                    });
                }
            }),
        );
    }

    /**
     * Remove the specified Type
     */
    public removeType( eDataId: string, entityDefId: string, typeName: string, eDataName: string ) {
        const data: any = this.getDeleteTypeData( eDataId, entityDefId, typeName, eDataName );
        this.dialogBoxController.showDialog( data );
    }

    /**
     * Remove a EntityList
     */
    public removeEntityList( eDataModel: EDataModel, entityListId: string, eDataName: string, entityListName: string ) {
        const data: any = this.getDeleteEntityListData( eDataModel, entityListId, eDataName, entityListName );
        this.dialogBoxController.showDialog( data );
    }

    public createSavedSet( shapeData, addedShapes ) {
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( l => l.getDiagramOnce()),
            switchMap( diagram => {
                const shape = diagram.shapes[ Object.keys( addedShapes )[0] ];
                const eDataId = shape.eDataId;
                return this.modelSubManager.getFutureSub( eDataId );
            }),
            switchMap( sub => sub.status ),
            filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
            take( 1 ),
            switchMap(() => this.commandService.dispatch( EDataCommandEvent.createSavedSet, {
                shapes: shapeData, addedShapes })),
        );
    }

    /**
     * Raname the specified Type
     */
    public renameType( eDataId: string, entityDefId: string, entityDefName: string ) {
        this.commandService.dispatch( EDataCommandEvent.updateEntityDefs, eDataId, {
            entityDefId,
            entityDefName,
            action: 'rename',
        });
    }

    /**
     * Raname the database
     */
    public renameDatabase( eDataId: string, name: string ) {
        this.commandService.dispatch( EDataCommandEvent.applyModifierEData, eDataId, {
            modifier: { $set: { name }},
        });
    }

    /**
     * Raname the database
     */
     public renameEntityListModel( eDataId: string, entityListId: string, name: string ) {
        this.commandService.dispatch( EDataCommandEvent.applyModifierEData, eDataId, {
            modifier: { $set: { entityLists: {
                [entityListId]: {
                    name,
                },
            } }},
        });
    }
    /**
     * Copy shape with eData
     */
    public copyEntity() {
        this.commandService.dispatch( DiagramCommandEvent.copyShapes, { copyEdata: true });
    }

    /**
     * Opens a confirmation dialog box and delets the database
     * specified by the input on confirmaiton.
     * @param eDataID: string
     * @param navigate: should navigate to the previous project after delete
     */
    public deleteDatabase( eDataID: string ) {
        return this.ell.getEDataModel( eDataID ).pipe(
            take( 1 ),
            tap( eData => {
                const data: any = this.getDeleteDatabaseData( eDataID, eData.name );
                this.dialogBoxController.showDialog( data );
            }),
        );
    }

    private getDeleteTypeData(  eDataId: string, entityDefId: string, typeName: string, eDataName: string  ) {
        return {
            id: 'CustomTypeDeleteCustomDatabaseDelete',
            heading: 'DIALOG_BOX.DELETE_TYPE.HEADING',
            headingParams: { typeName },
            description: 'DIALOG_BOX.DELETE_TYPE.DESCRIPTION',
            descriptionParams: { eDataName },
            type: 'warning',
            icon: 'circle-warning',
            buttons: [
                {
                    type: 'cancel',
                    text: 'BUTTONS.CANCEL',
                    clickHandler: () => {},
                },
                {
                    type: 'ok',
                    text: 'BUTTONS.DELETE_TYPE',
                    clickHandler: () => {
                        this.commandService.dispatch( EDataCommandEvent.updateEntityDefs, eDataId, {
                            entityDefId,
                            action: 'delete',
                        });
                    },
                },
            ],
        };
    }

    private getDeleteEntityListData(  eDataModel: EDataModel , entityListId: string,
                                      eDataName: string, entityListName: string ) {
        return {
            id: 'SmartSetDeleteFromAnyDatabase',
            heading: 'DIALOG_BOX.DELETE_ENTITYLIST.HEADING',
            headingParams: { entityListName },
            description: 'DIALOG_BOX.DELETE_ENTITYLIST.DESCRIPTION',
            descriptionParams: { eDataName },
            type: 'warning',
            icon: 'warning',
            buttons: [
                {
                    type: 'ok',
                    text: 'BUTTONS.DELETE_ENTITYLIST',
                    clickHandler: () => {
                        const proxied = Sakota.create(  eDataModel );
                        this.processTheRemovedEntityList( proxied, entityListId );
                        const modifier = proxied.__sakota__.getChanges();
                        this.commandService.dispatch( EDataCommandEvent.applyModifierEData,
                            eDataModel.id,
                            { modifier });
                    },
                },
                {
                    type: 'cancel',
                    text: 'BUTTONS.CANCEL',
                    clickHandler: () => {},
                },
            ],
        };
    }

    private processTheRemovedEntityList( eDataModel: Proxied<EDataModel>, entityListId: string ) {
        eDataModel.entityLists[ entityListId ].entities.forEach( entityId => {
            eDataModel.entities[entityId].entityListsIds = eDataModel.entities[entityId].entityListsIds
            .filter( id => id !== entityListId );
        });
        const diagramId = this.state.get( 'CurrentDiagram' );
        const containerIds = Object.keys( eDataModel.entityLists[ entityListId ].containers[diagramId]);
        this.commandService.dispatch( DiagramCommandEvent.removeEntityListReferenceFromShapes, diagramId, {
            containerIds,
            entityListId,
        });
        delete eDataModel.entityLists[ entityListId ];
    }


    private getDeleteDatabaseData( id: string, name: string ) {
        return {
            id: 'CustomDatabaseDelete',
            heading: 'DIALOG_BOX.DELETE_DATABASE.HEADING',
            description: 'DIALOG_BOX.DELETE_DATABASE.DESCRIPTION',
            descriptionParams: { databaseName: name },
            type: 'warning',
            icon: 'circle-warning',
            buttons: [
                {
                    type: 'cancel',
                    text: 'BUTTONS.CANCEL',
                    clickHandler: () => {},
                },
                {
                    type: 'ok',
                    text: 'BUTTONS.DELETE_DATABASE',
                    clickHandler: () => this.deleteEdata( id ).subscribe(),
                },
            ],
        };
    }

    protected deleteEdata( eDataId: string ) {
        return this.ell.getEDataModel( eDataId ).pipe(
            switchMap(( model: EDataModel ) => {
                if ( !model ) {
                    throw new Error( 'Edata model not found' );
                }

                const diagramIds: Set<string> = new Set( model.getUsedDiagIds());
                diagramIds.add( this.state.get( 'CurrentDiagram' ));
                const obs: any = [ of({}) ];
                diagramIds.forEach( id => {
                    const o = this.commandService.dispatch( DiagramCommandEvent.removeEdata, id, {
                        eDataId,
                    }).pipe( last());
                    obs.push( o );
                });
                return forkJoin([ ...obs ]);
            }),
            switchMap(() =>
                this.commandService.dispatch( ProjectCommandEvent.deleteEData, eDataId, {
                    modelType: EDataModel,
                }).pipe( last()),
            ),
        );
    }

}
