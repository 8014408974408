import { empty, defer, Observable } from 'rxjs';
import { concat, filter } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { ExecutionStep } from './execution-step';
import { CommandError } from '../error/command-error';

/**
 * DataValidationExecutionStep
 * This class is a implementation of the {@link ExecutionStep} which handles
 * the prepareData method and then validates if the data is according the
 * dataDefinition of the command. For more details see {@link AbstractCommand}
 *
 * To see how this is used, see the {@link CommandExecutor}.prepareSequnce method.
 *
 * @author  hiraash
 * @since   2016-04-17
 */
export class DataValidationExecutionStep extends ExecutionStep {
    /**
     * Validates the command data and throws an exception in
     * case of a validation failure.
     */
    public process() {
        const result = this.command.prepareData();
        if ( result instanceof Observable ) {
            return result.pipe(
                filter(() => false ),
                concat( defer(() => {
                    this.validateData();
                    return empty();
                })));
        }
        this.validateData();
    }

    /**
     * This is to ensure that all necessary data are available to execute the command and if not
     * throws an exception.
     * This check for data definition and validates data based on that.
     */
    protected validateData() {
        // Access a static property from an instance
        const command = Object.getPrototypeOf( this.command ).constructor;
        const cmdName = this.command.name;
        const dataDefinition: {} = command.dataDefinition;
        const data: any = this.command.data;

        if ( !isEmpty( dataDefinition )) {
            if ( data ) {
                for ( const prop in dataDefinition ) {
                    if ( dataDefinition[prop] === true && !data.hasOwnProperty( prop )) {
                        const error = `Mandatory field ${prop} was missing. Unable to execute command ${cmdName}`;
                        throw new CommandError( error );
                    }
                }
            } else {
                const error = `Data must be defined as per the dataDefinition of the command ${cmdName}`;
                throw new CommandError( error );
            }
        }
    }
}

Object.defineProperty( DataValidationExecutionStep, 'name', {
    value: 'DataValidationExecutionStep',
});
