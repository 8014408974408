import { Injectable, Injector } from '@angular/core';
import { IPoint2D, ITransform } from 'flux-definition';
import { AbstractShapeModel, IConnectorPoint } from 'flux-diagram-composer';
import { isFunction, isNumber, isObject, without } from 'lodash';
import { DiagramToViewportCoordinate } from '../../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { IRestrictor } from '../../../framework/diagram/restriction/restrictor.i';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';

/**
 * The Restriction service is a central controller for managing all restrictions that can happen
 * for changes that occur on the canvas - Specifically on visual structure. This allows to tap
 * into a series of other services and manage the restrictions they all enforce through a single
 * operation. This controller tries to manage the restrictions enforced by all the related IRestrictors
 * to derive a sensible result.
 */
@Injectable()
export class Restriction {

    constructor(
        protected injector: Injector,
        protected vToD: ViewportToDiagramCoordinate,
        protected dToV: DiagramToViewportCoordinate,
    ) {}

    /**
     * Simply controls a point on the viewport. This is looked at as a generic
     * point that does not directly associate to a shape or connector.
     * @param point The point which needs to be validated.
     * @param restrictorTokens Tokens for injectable IRestrictor services to be used.
     */
    public pointOnViewport( point: IPoint2D, restrictorTokens: Array<string> ): IPoint2D {
        let x = this.vToD.x( point.x );
        let y = this.vToD.y( point.y );
        const restrictedPoint = this.restrict( restrictorTokens, 'point', { x, y });
        x = this.dToV.x( restrictedPoint.x );
        y = this.dToV.y( restrictedPoint.y );
        return { x, y };
    }

    /**
     * Simply controls a point on the surface of the canvas. This is looked at as a generic
     * point that does not directly associate to a shape or connector.
     * @param point The point which needs to be validated.
     * @param restrictorTokens Tokens for injectable IRestrictor services to be used.
     */
    public point( point: IPoint2D, restrictorTokens: Array<string> ): IPoint2D {
        return this.restrict( restrictorTokens, 'point', point );
    }

    /**
     * Any shape transformation change that occors on a shape.
     * @param shapeId The id of the shape
     * @param trasnform The transformation data. This can have only the properties that
     * are changing.
     */
    public shapeTransform(
        shape: ShapeModel | ConnectorModel,
        transform: ITransform,
        restrictorTokens?: Array<string>,
    ): ITransform {
        if ( !restrictorTokens || !restrictorTokens.length ) {
            restrictorTokens = [ 'GridService' ]; // TODO set the default restrictorTokens for this method
        }
        return this.restrictShape( restrictorTokens, 'shapeTransform', transform , shape );
    }

    /**
     * Any point changes that are happening on connectors.
     * @param connectorId The connector id
     * @param points The connector points. This can have only the points that
     * are changing.
     */
    public connectorPoints(
        shape: ShapeModel | ConnectorModel,
        points: Array<IConnectorPoint>,
        restrictorTokens?: Array<string>,
    ): IConnectorPoint[] {
        if ( !restrictorTokens || !restrictorTokens.length ) {
            restrictorTokens = [ 'GridService' ]; // TODO set the default restrictors for this method
        }
        return this.restrictShape( restrictorTokens, 'connectorPoints', points, shape );
    }

    /**
     * Runs the restrictions for the given method and given restrictors with the model for given shape id
     */
    private restrictShape(
        restrictorTokens: Array<string>,
        method: string,
        data: any,
        shape: ShapeModel | ConnectorModel,
    ): any {
        return this.restrict( restrictorTokens, method, data, shape );
    }

    /**
     * Runs the restrictions for the given method and given restrictors
     */
    private restrict(
        restrictorTokens: Array<string>, method: string, data: any, model?: AbstractShapeModel,
    ): any {
        const restrictors = this.injectRestrictors( restrictorTokens );
        let interrimData;
        if ( Array.isArray( data )) {
            interrimData =  data.map( value => this.convertToRestricted( value ));
        } else {
            interrimData = this.convertToRestricted( data );
        }

        for ( const restrictor of restrictors ) {
            if ( isFunction( restrictor[method])) {
                if ( model ) {
                    interrimData = restrictor[method]( model, interrimData );
                } else {
                    interrimData = restrictor[method]( interrimData );
                }
            }
        }

        if ( Array.isArray( interrimData )) {
            return interrimData.map( value => this.convertToNormal( value ));
        }
        return this.convertToNormal( interrimData );
    }

    /**
     * Injects the IRestrictor services for each token and returns the array
     * @param tokens Tokens for injecting restrictors
     */
    private injectRestrictors( tokens: Array<string> ): Array<IRestrictor> {
        return tokens.map( token => this.injector.get( token ));
    }

    /**
     * Converts a given data object's numberic properties to IRestrictedChange properties.
     * Runs deep and does not consider arrays.
     */
    private convertToRestricted( data: {}): {} {
        const newData = { ...data };
        Object.keys( newData ).forEach( key => {
            const value = newData[key];
            if ( isNumber( value )) {
                newData[key] = { value, low: 1, high: 1 };
            } else if ( isObject( value )) {
                newData[key] = this.convertToRestricted( value );
            }
        });
        return newData;
    }

    /**
     * Converts a given object's IRestrictedChange properties into numberic properties.
     * Runs deep and does not consider arrays.
     */
    private convertToNormal( data: {}): {} {
        const newData = { ...data };
        Object.keys( newData ).forEach( key => {
            const value = newData[key];
            if ( isObject( value )) {
                const oKeys = Object.keys( value );
                if ( oKeys.length === 3 && without( oKeys, 'value', 'low', 'high' ).length === 0 ) {
                    newData[key] = value.value;
                } else {
                    newData[key] = this.convertToNormal( value );
                }
            }
        });
        return newData;
    }
}
