import { get } from 'lodash';
import { LogLevel } from './logger/logger.svc';

/**
 * This class stores the actual instance of an application
 * configuration in a static variable. It also provides static getter
 * function that allow accessing values of the said configuration.
 * The actual configuration will be set to this class upon application
 * bootstrap.
 * Example usage of {@link AppConfig} to retrieve values from this class:
 *  If the configuration value is common and a getter is provided, use the getter :
 *      <code>AppConfig.buildDate</code>
 *  If a getter is not found on AppConfig, use string notation :
 *      <code>AppConfig.get( 'GRAVITY_API_URL’ )</code>
 *  If a getter is not found on AppConfig and the value is nested,
 *  use string notation with a path :
 *      <code>AppConfig.get( 'google.CLIENT_ID' )</code>
 *
 * FIXME: remove the lodash `get` dependency and the feature, we're not using it.
 * FIXME: AppConfig is in flux core but it has information related to Nucleus/Proton.
 * FIXME: The `get` method is public but it doesn't have any typings, can create bugs.
 *
 * @author  Ramishka
 * @since   2017-03-13
 */
export class AppConfig {

    /**
     * Environment of the application
     */
    public static get environment(): string {
        return this.get( 'ENV' );
    }

    /**
     * Name of the application
     */
    public static get appName(): string {
        return this.get( 'APP_NAME' );
    }

    /**
     * Version of the application
     */
    public static get version(): string {
        return this.get( 'VERSION' );
    }

    /**
     * Build date of the application
     */
    public static get buildDate(): string {
        return this.get( 'BUILD_DATE' );
    }

    /**
     * Application log level
     */
    public static get logLevel(): LogLevel {
        return LogLevel [ this.get( 'LOG_LEVEL' ) ] as any;
    }

    /**
     * Application domain
     */
    public static get appDomain(): string {
        return this.get( 'APP_DOMAIN' );
    }

    /**
     * Base URL of the API
     */
    public static get apiBaseURL(): string {
        return this.get( 'API_BASE_URL' );
    }

    /**
     * Version of the message protocol used in the application
     */
    public static get messageProtocolVersion(): number {
        return this.get( 'MESSAGE_PROTOCOL_VERSION' );
    }

    /**
     * Base URL for diagram thumbnails
     */
    public static get thumbnailBaseURL(): string {
        return this.get( 'THUMBNAIL_BASE_URL' );
    }

    /**
     * URL of terms and conditions document
     */
    public static get termsAndConditionsURL(): string {
        return this.get( 'TERMS_AND_CONDITIONS_URL' );
    }

    /**
     * Get configuration details for different sources
     */
    public static get sourceConfig(): string {
        return this.get( 'SOURCE_CONFIG' );
    }

    /**
     * This function allows setting an actual application configuration.
     * The configuration object is held in a static field.
     * @param actualConf - Instance of actual application configuration.
     */
    public static setConfig( actualConf: any ) {
        this._appConfig = actualConf;
    }

    /**
     * This is a nameless getter function that allows retrieving configuration
     * values.
     * Example usage:
     *  <code>AppConf.get('ENV')</code>
     *  <code>AppConf.get('Service.Name')</code>
     * @param config - The name or path of config for which the value is needed
     */
    public static get( path: string ): any {
        return get( this._appConfig, path );
    }

    /**
     * This static variables holds the actual object which has
     * configuration entries and their values.
     */
    protected static _appConfig: any;
}
