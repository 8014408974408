import { AbstractShapeModel } from '../model/abstract-shape.mdl';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { merge } from 'lodash';
import { Observable, of } from 'rxjs';
import { IDefinitionLocator } from '../definition/definition-locator.i';
import { filter, map } from 'rxjs/operators';
import { Logger } from 'flux-core';

/**
 * This is an abstract form of a stateless factory for creating all types of Shape Views. Any view
 * created must be of the type ShapeRenderView or anything that extends that.
 *
 * This creates the view and merges the definition entry class into the view. Makes
 * the shapes view definition accessible from within the view and vice-versa.
 *
 * @author hiraash
 * @since 2017-09-06
 */
export class AbstractShapeViewFactory {

    /**
     * The definition locator instance which will be used to
     * get/load definitions source for shapes.
     */
    protected defLocator: IDefinitionLocator;

    /**
     * Registeres the given Shape Definition Location once in the system's
     * lifetime. Must be done for the ShapeViewFactory to function gracefully.
     * @param locator An IDefinitionLocator type
     */
    public registerDefinitionLocator( locator: IDefinitionLocator ) {
        if ( this.defLocator ) {
            throw new Error( 'A valid Definition Locator was already regisrered.' );
        }
        if ( !locator ) {
            throw new Error( 'Provide a valid Definition Locator.' );
        }

        this.defLocator = locator;
    }

    /**
     * A factory method which will create the shape view from the model. Creates the view
     * of the given type which extends a ShapeRenderView.
     * @param model The shape model that is an extension of ShapeDataModel
     * @param type The type of the view class which extends ShapeRenderView
     */
    public createView( model: AbstractShapeModel, type: typeof AbstractShapeView ): Observable<AbstractShapeView> {
        try {
            const view: any = new type( model );
            return of( view );
        } catch ( error ) {
            const message = `Unknown Type to create view Type: ${type} Model: ${model?.type} Id: ${model?.id}`;
            Logger.error( message, error );
            throw new Error( message );
        }
    }

    /**
     * A factory method which will create the shape view from the model. Creates the view
     * of the given type which extends a ShapeRenderView.
     * @param model The shape model that is an extension of ShapeDataModel
     * @param type The type of the view class which extends ShapeRenderView
     */
    public create( model: AbstractShapeModel, type: typeof AbstractShapeView ): Observable<AbstractShapeView> {
        return this.defLocator.getClass( model.entryClass ).pipe(
            map(( entryClass: any ) => {
                try {
                    const view = new type( model );
                    const entry: any = new entryClass();
                    // Note: The loadash merge method would not merge the prototype chains of two es6 classes.
                    // If entry class output is changed to es6, this method cannot be used and has to change
                    merge( view, entry );
                    return view;
                } catch ( error ) {
                    /* istanbul ignore next */
                    Logger.error( `Unable to create shape view for`, model.entryClass, 'with id:', model.id, error );
                }
            }),
            filter( v => !!v ),
        );
    }
}
