import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

/**
 * This is an abstraction for the authentication. This class is just a skeleton
 * for the common functionality used across all authentication services among apps.
 * This is intended to be extended by a concrete service to provide the actual
 * implementation.
 * If this class is extended in an app , use the following notation in the app modules
 * providers:
 * <code>{ provide: AbstractAuthentication, useExisting : NameOfExtendedClass }</code>
 * @author  Ramishka
 * @since   2017-08-21
 */
@Injectable()
export class AbstractAuthentication {

    /**
     * Returns the current authentication token
     */
    public get token(): string {
        return null;
    }

    /**
     * Returns if the user is authenticated or not
     */
    public get isAuthenticated(): boolean {
        return false;
    }

    /**
     * Returns if the user token is authenticated or not.
     */
    public authenticate(): Observable<any> {
        return of ( false );
    }
}
