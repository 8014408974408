import { Injectable } from '@angular/core';
import { Command, ModalController, Random, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { PlanPermManager, UpgradeDialogWindow } from 'flux-user';
import { PLAN_PERM_ERROR_MESSAGE } from '../../../base/permission/plan-perm-error-message';
import { IDialogBoxData, PlanPermission } from 'flux-definition';
import { cloneDeep, merge } from 'lodash';

/**
 * PasteShapes
 * This command adds given shapes to the current diagram.
 */
@Injectable()
@Command()
export class PasteShapes extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapesToBeClone: any[],
        groupsToBeClone: any[];
        dataDefs: any;
    };

    private SHAPE_TYPE_IMAGE = 'image';

    constructor(
        protected ds: DiagramChangeService,
        protected planPermManager: PlanPermManager,
        protected modalController: ModalController,
        protected state: StateService<any, any>,
    ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public execute(): boolean {
        const projectModels = this.state.get( 'projectEDataModels' );
        const originalLinks = {};
        this.data.shapesToBeClone.filter( shape => shape.eData ).forEach( shape => {
            const eDataId = Object.keys( shape.eData )[0];
            const entityId = shape.eData[eDataId];
            if ( projectModels[eDataId]) {
                originalLinks[shape.id] = { eDataId, entityId };
            }
        });
        this.state.set( 'originalEDataLinks', originalLinks );
        this.state.set( 'pastedAs', 'newObject' );
        for ( const shape of this.data.shapesToBeClone ) {

            // FIXME CJ: this should move to the command level outside of this??? 7/5/23
            // Cancel command if no permission to paste images
            if ( shape.type === this.SHAPE_TYPE_IMAGE
                && !this.planPermManager.check([ PlanPermission.DIAGRAM_IMPORT_IMAGE ])) {

                const dialogData = {
                    ...PLAN_PERM_ERROR_MESSAGE[ PlanPermission.DIAGRAM_IMPORT_IMAGE ],
                        descriptionParams: {
                            maxUsage: this.planPermManager.getMaxUsage( PlanPermission.DIAGRAM_IMPORT_IMAGE ),
                        },
                        buttons: [
                            {
                                type: 'upgrade',
                                clickHandler: () => {},
                            },
                        ],
                        integrationContext: {
                            embedded: this.state.get( 'ApplicationIsEmbedded' ),
                            environment: this.state.get( 'PluginApp' ),
                        },
                    } as IDialogBoxData;

                this.modalController.show( UpgradeDialogWindow, {
                    inputs: {
                        dialogData: dialogData,
                    },
                });

                return false;
            }

            if ( shape?.connectorIds?.length ) {
                shape.connectorIds = [];
            }

            if ( shape.eData ) { // delete eData records if its coming from another edataModel thats not here.
                // Add eData if the pasted shape has edata from the same edata context
                if ( this.state.get( 'CurrentProject' ) === 'home' ) {
                    this.removeEDataProps( shape );
                } else {
                    const eDataModels = projectModels || {};
                    if ( Object.keys( eDataModels ).length > 0 ) {
                        const arr = this.changeModel.eData || [];
                        for ( const eDataId in shape.eData ) {
                            if ( eDataModels[eDataId]) {
                                if ( arr.indexOf( eDataId ) === -1 ) {
                                    arr.push( eDataId );
                                }
                            }
                        }
                        this.changeModel.eData = arr;
                    }
                    if ( this.changeModel.eData ) {
                        const retKeys = {};
                        for ( const eDataId in shape.eData ) {
                            if ( this.changeModel.eData.indexOf( eDataId ) > -1 ) {
                                // now default behavior is new entity with same data
                                retKeys[eDataId] = null;
                            }
                        }
                        if ( Object.keys( retKeys ).length > 0 ) {
                            shape.eData = retKeys;
                            shape.triggerNewEData = true;
                            if ( shape.entityDefId && !this.changeModel.dataDefs[shape.entityDefId]
                                && this.data.dataDefs[shape.entityDefId]) {
                                this.changeModel.dataDefs[shape.entityDefId] = this.data.dataDefs[shape.entityDefId];
                            }
                            if ( shape.dataSetId && this.data.dataDefs[shape.dataSetId]) {
                                const dataDefs = this.data.dataDefs[shape.dataSetId];
                                delete shape.dataSetId;
                                for ( const diId in dataDefs ) {
                                    if ( shape.data[ diId ]) {
                                        shape.data[ diId ] = merge( dataDefs[ diId ], shape.data[ diId ]);
                                    }
                                }
                            }
                        } else {
                            this.removeEDataProps( shape );
                        }
                    } else {
                        this.removeEDataProps( shape );
                    }
                }

                if ( !shape.eData ) { // we have deleted it, so this is a new drag drop
                    shape.txn = {
                        sessionId: this.state.get( 'SessionId' ),
                        uid: this.state.get( 'CurrentUser' ),
                    };
                }
            } else if ( shape.dataSetId ) {
                this.createNewDataSet( shape, this.changeModel.dataDefs[ shape.dataSetId ]);
            }

            // make sure template shapes are only added to canvas once.
            delete shape.isTemplateShape;

            this.changeModel.shapes[shape.id] = shape;
        }
        for ( const group of this.data.groupsToBeClone ) {
            this.changeModel.groups[group.id] = {
                shapes: group.shapes,
                groups: group.groups,
            };
        }
        return true;
    }

    protected removeEDataProps( shape ) {
        delete shape.eData;
        this.createNewDataSet( shape );
        delete shape.entityDefId;
        shape.triggerNewEData = false;
    }

    /**
     * Function to clone data sets in the diagram the shape is copied to
     * @param shape
     * @param dataDef to clone - if it doesn't exist on the diagram already, it is cloned
     * from the pasted data
     */
    protected createNewDataSet( shape, dataDef? ) {
        let currDataDef = dataDef || {};
        if ( !dataDef ) {
            if ( shape.entityDefId ) {
                currDataDef = { ...this.data.dataDefs[ shape.entityDefId ] };
            }
            if ( shape.dataSetId && this.data.dataDefs[ shape.dataSetId ]) {
                currDataDef = { ...currDataDef, ...this.data.dataDefs[ shape.dataSetId ] };
            }
        }
        const newDef = cloneDeep( currDataDef );
        const newId = Random.dataItemId();
        this.changeModel.dataDefs[ newId ] = newDef;
        shape.dataSetId = newId;
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( PasteShapes, 'name', {
    value: 'PasteShapes',
});
