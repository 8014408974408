import { Observable } from 'rxjs';
import { isArray, isString, isPlainObject } from 'lodash';
import { Injectable } from '@angular/core';
import { AbstractCommand, CommandInterfaces, AbstractModel, Command } from 'flux-core';
import { DataSync } from '../../model/data-sync.svc';

/**
 * SubRemoveChanges
 *
 * SubRemoveChanges command removes changes with given ids from the
 * data sync service available in command result data. The model type
 * is also taken from the result data which makes this different from
 * other commands which usually has a specific result data type.
 *
 * This command can be used by the subscription service to remove
 * changes which got rejected on subscription sync stage
 */
@Injectable()
@Command()
export class SubRemoveChanges extends AbstractCommand {
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICollabCommand' ];
    }

    constructor( protected dataSync: DataSync ) {
        super()/* istanbul ignore next */;
    }

    public executeResult(): Observable<any> {
        if ( !this.isValidResultData()) {
            throw new Error( 'invalid result data' );
        }
        const { changeIds, type } = this.resultData;
        return this.dataSync.removeChanges( type, changeIds );
    }

    protected isValidResultData(): boolean {
        if ( !isPlainObject( this.resultData )) {
            return false;
        }
        const { changeIds, type } = this.resultData;
        if ( !AbstractModel.isModelType( type )) {
            return false;
        }
        if ( !isArray( changeIds )) {
            return false;
        }
        for ( let i = 0; i < changeIds.length; ++i ) {
            if ( !isString( changeIds[i])) {
                return false;
            }
        }
        return true;
    }
}

Object.defineProperty( SubRemoveChanges, 'name', {
    writable: true,
    value: 'SubRemoveChanges',
});
