<tiptap-hyperlink-editor [editor] ="editor" #hle ></tiptap-hyperlink-editor>
<div data-keep-text-open="true" class="tiptap-table-toolbar" *ngIf="showTableToolbar" >
    <span class="tiptap-table-toolbar-container fx-center-vertical" *ngIf="selectionSubject | async as e">
        <a class="btn-list-item tiptap-table-addRowBefore" (click)="e.chain().focus().addRowBefore().run()" [class.fx-hidden]="!e?.can().addRowBefore()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-add-row-top"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-addRowAfter" (click)="e.chain().focus().addRowAfter().run()" [class.fx-hidden]="!e?.can().addRowAfter()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-add-row"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-addColumnBefore" (click)="e.chain().focus().addColumnBefore().run()" [class.fx-hidden]="!e?.can().addColumnBefore()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-add-column-left"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-addColumnAfter" (click)="e.chain().focus().addColumnAfter().run()" [class.fx-hidden]="!e?.can().addColumnAfter()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-add-column"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-deleteRow" (click)="e.chain().focus().deleteRow().run()" [class.fx-hidden]="!e?.can().deleteRow()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-table-deleteRow"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-deleteColumn" (click)="e.chain().focus().deleteColumn().run()" [class.fx-hidden]="!e?.can().deleteColumn()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-table-remove"></use></svg>
        </a>
        <a class="btn-list-item tiptap-table-deleteTable" (click)="e.chain().focus().deleteTable().run()" [class.fx-hidden]="!e?.can().deleteTable()">
            <svg ><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-trash-thin"></use></svg>
        </a>
    </span>
</div>
<div #editor class="tiptap-editor"></div>
<div #viewContainerRef ></div>
