import { MouseEvent } from '@creately/createjs-module';
import { EaselEvent } from './../../../base/interaction/event/easel-event';

export class RightClickEvent extends EaselEvent {

    /**
     * X position related to the event targeted shape
     */
    public offsetX: number;

    /**
     * Y position related to the event targeted shape
     */
    public offsetY: number;

    /**
     * X position related to the appliaction client area
     */
    public clientX: number;

    /**
     * Y position related to the appliaction client area
     */
    public clientY: number;

    constructor( type: RightClickEventType, event: MouseEvent, bubbles = true, cancellable = true ) {
        super( type, event, bubbles, cancellable );

        this.offsetX = event.nativeEvent.offsetX;
        this.offsetY = event.nativeEvent.offsetY;
        this.clientX = event.nativeEvent.clientX;
        this.clientY = event.nativeEvent.clientY;
    }
}

/**
 * Types of right click event on canvas
 */
export type RightClickEventType = 'shapeRightClick' | 'connectorRightClick' | 'canvasRightClick';
