import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';


/**
 * GetShapeContainer
 * This command will return ids of connected shapes with direction for given shapes and connectors
 *
 *  data: {
 *      shapeIds: string[],
 *  }
 *
 *  resultData: {
 *      [shapeId: string]: { shapeId: string, dir: 'IN'|'OUT' },
 *  }
 *
 */
@Injectable()
@Command()
export class GetConnectedShapes extends AbstractDiagramChangeCommand  {

    public data: {
        shapeIds: string[],
    };

    /**
     * Get connected shape for each shape or connector
     */
    public prepareData() {
        this.resultData = {};
        for ( const shapeId of this.data.shapeIds ) {
            const shape = this.changeModel.shapes[shapeId];
            if ( shape.type === 'connector' ) {
                const connected = [];
                const head = ( shape as ConnectorModel ).getFromEndpoint( this.changeModel );
                const tail = ( shape as ConnectorModel ).getToEndpoint( this.changeModel );
                if ( head?.shape?.id ) {
                    connected.push({ shapeId: head.shape.id, dir: 'IN' });
                }
                if ( tail?.shape?.id ) {
                    connected.push({ shapeId: tail.shape.id, dir: 'OUT' });
                }
                this.resultData[ shapeId ] = connected;
            } else {
                const connected = ( shape as ShapeModel ).getConnectedShapes( this.changeModel );
                if ( connected ) {
                    this.resultData[ shapeId ] = connected;
                }
            }
        }
    }
}

Object.defineProperty( GetConnectedShapes, 'name', {
    value: 'GetConnectedShapes',
});
