import { BehaviorSubject } from 'rxjs';
import { Component, ComponentFactoryResolver, ElementRef, Injector, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Random } from 'flux-core';
import { groupBy, values } from 'lodash';

/**
 * TiptapMentionsListCmp is the component for @mentions in tiptap
 */
@Component({
    template: '',
})
export abstract class TiptapPopCommandsListCmp {

    public items = new BehaviorSubject([]);
    public groupedItems = new BehaviorSubject([]);
    public selectedId = new BehaviorSubject( '' );
    public hideList = new BehaviorSubject( false );

    public id: string;
    public maxHeight: number;

    /**
     * Perfect Scrollbar component, this will be used to scroll the library panel.
     */
    @ViewChild( 'scrollbar', { static: true })
    public directiveScroll: PerfectScrollbarComponent;

    /**
     * The text editor container element
     */
    @ViewChild( 'container' )
    protected container: ElementRef;

    constructor(
        protected elementRef: ElementRef,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector ) {
        this.id = Random.base62( 4 );
    }

    protected get containerElement(): HTMLElement {
        return this.container.nativeElement as HTMLElement;
    }

    public command = ( item, component = null ) => {};

    public updateProps( props ) {
        if ( props.items?.length === 0 ) {
            this.hideList.next( true );
        }
        this.items.next( props.items );
        this.groupedItems.next( values( groupBy( props.items, 'section' )));

        this.selectedId.next( props.items[0] ? props.items[0].id : '' );
        this.command = props.command;
    }

    public updatePosition( props ) {
        try {
            const { x, y } = props.clientRect();
            this.position( x, y );
        } catch ( error ) {
        }
    }

    public onKeyDown({ event }) {
        if ( event.key === 'ArrowUp' ) {
            this.upHandler();
            return true;
        }

        if ( event.key === 'ArrowDown' ) {
            this.downHandler();
            return true;
        }

        if ( event.key === 'Enter' ) {
            this.enterHandler();
            return true;
        }
        return false;
    }

    public upHandler() {
        const selectedIndex = this.items.value.findIndex( v => v.id === this.selectedId.value );
        const newIndex = (( selectedIndex + this.items.value.length ) - 1 ) % this.items.value.length;
        if ( this.items.value[ newIndex ]) {
            this.selectedId.next( this.items.value[ newIndex ].id );
        }
        this.autoScroll();
    }

    public downHandler() {
        const selectedIndex = this.items.value.findIndex( v => v.id === this.selectedId.value );
        const newIndex = ( selectedIndex + 1 ) % this.items.value.length;
        if ( this.items.value[ newIndex ]) {
            this.selectedId.next( this.items.value[ newIndex ].id );
        }
        this.autoScroll();
    }

    public enterHandler() {
        this.selectItem( this.selectedId.value );
    }

    public position( x, y ) {}

    public selectItem( id ) {
        const item = this.items.value.find( v => v.id === id );
        if ( item ) {
            this.command( item );
        }
    }

    public setScrollY( y: number ) {
        this.directiveScroll?.directiveRef?.scrollToY( y, 0 );
    }
    public getScrollY() {
        return this.directiveScroll?.directiveRef?.elementRef.nativeElement.scrollTop;
    }

    protected autoScroll() {}
}
