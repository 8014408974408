import { ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, ModalController, NotificationType, NotifierController, PostMessageAPI,
    Tracker, AbstractNotification, PopupWindow } from 'flux-core';
import { Subscription } from 'rxjs';

/**
 * Post message receive event types for auth window.
 */
export enum TeamUserConfirmationWindowPMReceiveEvent {
    TEAM_CONFIRM_ACCEPT_SUCCESS = 'phoenix:teamConfirmAcceptSuccess',
    TEAM_CONFIRM_DECLINED_SUCCESS = 'phoenix:teamConfirmDeclineSuccess',
    TEAM_CONFIRM_ERROR = 'phoenix:teamConfirmError',
}

/**
 * Team user confirmation window component. This will handle the
 *  confirmation window popup and incoming post from the window
 *
 * @author  Sajeeva
 * @since   2021-06-10
 */
@Component({
    templateUrl: 'team-user-confirmation-window.cmp.html',
    selector: 'team-user-confirmation-window',
    styleUrls: [ './team-user-confirmation-window.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamUserConfirmationWindow extends PopupWindow implements OnDestroy, OnInit {

    /**
     * Team user confiramtion url
     */
    protected CONFIRMATION_URL = 'team-user-confirmation-in-app';

    /**
     * An array containing all subscriptions.
     */
    protected subs: Array<Subscription>;

    /**
     * The wrapper contaner.
     */
    @ViewChild( 'window', { static: true })
    protected window;

    /**
     * The team user confiramtion window container.
     */
    @ViewChild( 'container', { static: true })
    protected container;

    constructor(
        protected modalController: ModalController,
        protected notifierController: NotifierController,
        protected postMessage: PostMessageAPI,
        protected translator: TranslateService,
    ) {
        super();
        this.subs = [];
    }

    /**
     * Return the auth window URL with the current page to open.
     */
    public get confirmationWindowUrl(): string {
        return `${AppConfig.get( 'SITE_URL' )}${this.CONFIRMATION_URL}/`;
    }

    public ngOnInit(): void {
        Tracker.track( 'dialog.teamInvite.load' );
        this.subs.push(
            this.showWindow( this.container, this.window ).subscribe(),
            this.postMessage.recv().subscribe( message => this.handleIncomingMessages( message )),
        );
    }

    public closeWindow() {
        const sub = this.hideWindow( this.container, this.window ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /* istanbul ignore next */
    protected appReload() {
        window.location.reload();
    }

    /**
     * Handle incoming post messages from auth window.
     */
    private handleIncomingMessages( message: any ): void {
        if ( !message || typeof message !== 'object' ) {
            return;
        }
        const { event, data } = message as any;
        if ( event === TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_ACCEPT_SUCCESS ) {
            this.handleShowNotification(
                TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_ACCEPT_SUCCESS,
                this.translator.instant( 'TEAM_USER_CONFIRMATION.ACCEPT_SUCCESS' ),
                NotificationType.Success,
            );
            if ( data && data.plan ) {
                Tracker.track( 'dialog.teamInvite.accept.click', { value1: data.plan });
            }
            this.appReload();
        }
        if ( event === TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_DECLINED_SUCCESS ) {
            this.handleShowNotification(
                TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_DECLINED_SUCCESS,
                this.translator.instant( 'TEAM_USER_CONFIRMATION.DECLINED_SUCCESS' ),
                NotificationType.Success,
            );
            Tracker.track( 'dialog.teamInvite.decline.click' );
            this.handleClose();
        }
        if ( event === TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_ERROR ) {
            this.handleShowNotification(
                TeamUserConfirmationWindowPMReceiveEvent.TEAM_CONFIRM_ERROR,
                this.translator.instant( 'TEAM_USER_CONFIRMATION.CONFIRM_ERROR' ),
                NotificationType.Error,
            );
            this.handleClose();
        }
    }

    /**
     * Handle show notification events.
     * Shows notification with given parameters.
     * @param id the id of the notification to be shown
     * @param type the NotificationType type of notification
     * @param heading the heading for the notification
     */
     private handleShowNotification(
        id: string,
        heading: string,
        type: NotificationType,
    ): void {
        this.notifierController.show( id, AbstractNotification, type,
            { inputs: { heading, autoDismiss: true },
        });
    }

    /*
     * Handle close event
     */
    private handleClose(): void {
        this.closeWindow();
    }
}
