import { Subscription } from 'rxjs';
import { StateService } from './state.svc';
import { isArray } from 'lodash';
import {
    Directive,
    Input,
    Inject,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';

/**
 * IfState
 * This directive is used to control the presence of an element
 * depending on state, based on *ngIf source code
 *
 * Example:
 * <button *ifState="['InterfaceControlState','edit']" >
 * <button *ifState="['ZoomLevel', 2 ]" >
 *
 * @author  thisun
 * @since   2017-09-05
 */

@Directive({
    selector: '[ifState]',
})

export class IfState implements OnInit, OnDestroy {

    /**
     * Input property to hold state key and value pair as an array
     */
    @Input()
    public ifState: Array<any>;


    /**
     * Statge changes subscription, to be unscribed on ngOnDestroy
     */
    protected subscription: Subscription;

    protected hasView;

    constructor(
        @Inject( StateService ) protected state: StateService<any, any>,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private cdr: ChangeDetectorRef,
    ) {}


    public ngOnInit() {
        if ( !this.ifState || !isArray( this.ifState ) || this.ifState.length !== 2 ) {
            throw new Error( 'The ifState directive requires an array ' +
                'containing state name and value to be passed as an input' );
        }
        const stateKey = this.ifState[0];
        const stateValue = this.ifState[1];

        this.subscription = this.state.changes( stateKey ).subscribe( state =>  {
            if ( state === stateValue && !this.hasView ) {
                this.addElement();
            } else if ( state !== stateValue && this.hasView ) {
                this.removeElement();
            }
            this.cdr.markForCheck();
        });
    }

    public ngOnDestroy() {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }


    /**
     * Create Embedded View from the TemplateRef and insert to viewContainer
     */
    protected addElement() {
        this.viewContainer.createEmbeddedView( this.templateRef );
        this.hasView = true;
    }

    /**
     * Destroys all the views in the viewContainer
     */
    protected removeElement() {
        this.viewContainer.clear();
        this.hasView = false;
    }

}
