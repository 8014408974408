<div class="setup-database-dialog-container modal-window-container fx-cover fx-center-all" #window >
    <div class="setup-database-dialog-inner modal-window-inner" #windowInner>
        <div>
            <div class="setup-database-dialog-heading modal-window-heading">
                <h1 class="setup-database-dialog-header title">{{ 'SETUP_DATABASE_DIALOG.HEADINGS.MAIN' | translate }}</h1>
                <button class="btn-small nu-btn-icon setup-database-dialog-close"
                (click)="closeWindow()">
                <svg class="nu-icon nu-icon nu-icon-small nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
            </div>
            <span class="setup-database-dialog-description">{{ 'SETUP_DATABASE_DIALOG.HEADINGS.DESCRIPTION' |
                translate }}</span>
        </div>

        <div class="setup-database-dialog-content modal-window-content">
            <div *ngIf="defs.length > 1" class="convert-to-object-dialog-db-type-container">
                <label class="setup-database-dialog-name-lable" translate>SETUP_DATABASE_DIALOG.LABELS.DATABASE_TYPE</label>
                <abs-dropdown class="setup-database-db-type-dropdown" #dropdown
                    [settings]="{ closeOnItemClicked: true, openOnHover: false, closeOnBlur: true, multiselectable: false }"
                    direction="top" alignment="right">
                    <simple-dropdown-button class="setup-database-db-type btn-small secondary" ddbutton [items]="defs">
                        <span>{{(selectedDbType|async).name}}</span>
                        <svg class="nu-icon dropdown-arrow">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                        </svg>
                    </simple-dropdown-button>
                    <simple-dropdown-item dditem *ngFor="let def of defs" [item]="type"
                        (click)="selectedDbType.next(def)">
                        <div class="header-action-btns-dropdown-item fx-margin-bottom-15">
                            <span>{{def.name}}</span>
                        </div>
                    </simple-dropdown-item>
                </abs-dropdown>
                <!-- db type picker -->
            </div>
            <div class="setup-database-dialog-name-content"
                *ngIf="((selectedDbType | async).defId != 'customEDataDef') && defs.length != 1 && ((existingDb | async) == false )">
                <label class="setup-database-dialog-name-lable"
                    translate>SETUP_DATABASE_DIALOG.LABELS.DATABASE_NAME</label>
                <input type="text" [readonly]="readonly | async" class="setup-database-dialog-name-text text-input" [(ngModel)]="dbName" />
            </div>
        </div>

        <div class="setup-database-dialog-content modal-window-content">
            <div class="setup-database-dialog-name-content" *ngIf="((selectedDbType | async).defId == 'customEDataDef') || (existingDb | async)">
                <label class="setup-database-dialog-name-lable" translate>SETUP_DATABASE_DIALOG.LABELS.DATABASE</label>
                <mat-form-field  *ngIf="!(existingDb | async)" class="">
                    <input (change)="selectedDatabase=undefined" (keyup)="isDBValid.next( !!$event.target.value.trim())"
                        class="text-input" type="text" matInput [formControl]="dbControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedDatabase=$event.option.value; isDBValid.next( !!$event.option.value )"
                        [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <input *ngIf="existingDb | async" type="text" [readonly]="readonly | async" class="setup-database-dialog-name-text text-input" [(ngModel)]="dbName" />
            </div>
            <div class="setup-database-dialog-name-content" *ngIf="((selectedDbType | async).defId == 'customEDataDef') || (existingDb | async)">
                <label class="setup-database-dialog-name-lable"
                    translate>SETUP_DATABASE_DIALOG.LABELS.OBJECT_TYPE</label>
                <mat-form-field  *ngIf="!(existingDb | async)" class="">
                    <input (change)="selectedDef=undefined" (keyup)="isDefValid.next( !!$event.target.value.trim())"
                        class="text-input" type="text" matInput [formControl]="defControl" [matAutocomplete]="autoDef">
                    <mat-autocomplete #autoDef="matAutocomplete"
                        (optionSelected)="selectedDef=$event.option.value; isDefValid.next( !!$event.option.value )"
                        [displayWith]="displayDef">
                        <mat-option *ngFor="let option of filteredDefs | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <abs-dropdown *ngIf="existingDb | async" class="setup-database-db-type-dropdown" #dropdown
                    [settings]="{ closeOnItemClicked: true, openOnHover: false, closeOnBlur: true, multiselectable: false }"
                    direction="top" alignment="right">
                    <simple-dropdown-button class="setup-database-db-type btn-small secondary" ddbutton [items]="entityTypeOpts | async">
                        <span>{{(selectedEntityType|async).name}}</span>
                        <svg class="nu-icon dropdown-arrow">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                        </svg>
                    </simple-dropdown-button>
                    <simple-dropdown-item dditem *ngFor="let def of entityTypeOpts | async" [item]="type"
                        (click)="selectedEntityType.next(def)">
                        <div class="header-action-btns-dropdown-item fx-margin-bottom-15">
                            <span>{{def.name}}</span>
                        </div>
                    </simple-dropdown-item>
                </abs-dropdown>
            </div>
        </div>

        <div class="divider"></div>

        <div class="setup-database-dialog-btns">
            <button class="btn-medium btn-secondary fx-margin-right-5" (click)="closeWindow()">{{
                'SETUP_DATABASE_DIALOG.BUTTONS.CANCEL' | translate }}</button>
            <button class="btn-medium btn-primary fx-margin-right-5"
                [disabled]="( selectedDbType | async ).defId == 'customEDataDef' && (!(isDBValid | async) || !(isDefValid | async))"
                (click)="convertToObject()">{{ 'SETUP_DATABASE_DIALOG.BUTTONS.CREATE_DATABASE' | translate }}</button>
        </div>
    </div>
</div>