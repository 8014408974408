import { AbstractCanvasInstructionFactory } from './abstract-canvas-instruction-factory';
import {
    ICanvasInstruction, IComputedStyleDefition, IGradientInstructionFactory,
    GradientUsageType, GradientType, IGradientInstruction,
} from './canvas-instruction.i';
import { IShapeNode } from '../svg-node/shape-node.i';
import { VectorEffect, FillType } from '../svg-node/structural-node.i';
import { IStyle, FillStyle } from 'flux-definition';
import { LinearGradientCanvasInstructionFactory } from './linear-gradient-canvas-instruction-factory';
import { RadialGradientCanvasInstructionFactory } from './radial-gradient-canvas-instruction-factory';
import { isNumber } from 'lodash';

/**
 * Represents starting style instructions generated
 */
interface IStartingStyles {
    endFillNeeded: boolean;
    endStrokeNeeded: boolean;
    instructions: ICanvasInstruction[];
}

/**
 * When instructions are generated, this type of object will be returned
 */
interface IStyleInstructions {
    start: ICanvasInstruction[];
    end: ICanvasInstruction[];
    styleDefinitions: IComputedStyleDefition[];
}

/**
 * class StyleCanvasInstructionFactory
 * This is a special instruction factory which generates style instructions from given shape node.
 * This will generate starting and ending instructions for styles and return them separately.
 * The instructions given by this factory must be placed according to,
 * - start style instructions
 * - element draw instructions
 * - end style instructions
 */
export class StyleCanvasInstructionFactory extends AbstractCanvasInstructionFactory {

    public svgElementName: string = 'style';

    /**
     * Create style instructions for the given shape node. Unlike other factories, this returns an object
     * having both star and end style instructions. Caller is responsible to place the final instructions properly.
     * @param data IShapeNode
     * @param gradients Already processed gradients
     */
    public createInstruction( data: IShapeNode, gradients: { [ id: string ]: IShapeNode }): IStyleInstructions {
        this.styleDefinitions = [];
        const startStyles = this.createStartingStyleInstructions( data, gradients );
        const endStyles = this.createEndStyleInstructions( startStyles );
        return {
            start: startStyles.instructions,
            end: endStyles,
            styleDefinitions: this.styleDefinitions,
        };
    }

    /**
     * Create a beginFill graphics instruction with the given solid color
     * @param fillColor
     */
    private createBeginFillInstruction( fillColor: string ): ICanvasInstruction {
        const style: Partial<IStyle> = { fillColor, fillStyle: FillStyle.Solid };
        const styleDef = { locked: false, priority: 1, style };
        const styleId = this.generateStyleId( styleDef );
        this.styleDefinitions.push({ id: styleId, def: styleDef });
        return {
            instruction: 'beginFill',
            params: [ `${this.styleDefinitionsRefVar}['${styleId}'].style.fillColor` ],
        };
    }

    /**
     * Create an endFill instruction
     */
    private createEndFillInstruction(): ICanvasInstruction {
        return { instruction: 'endFill', params: []};
    }

    /**
     * Create a beginStroke instruction with given solid color
     * @param strokeColor
     */
    private createBeginStrokeInstruction( strokeColor: string ): ICanvasInstruction {
        const style: Partial<IStyle> = { lineColor: strokeColor, fillStyle: FillStyle.Solid };
        const styleDef = { locked: false, priority: 1, style };
        const styleId = this.generateStyleId( styleDef );
        this.styleDefinitions.push({ id: styleId, def: styleDef });
        return {
            instruction: 'beginStroke',
            params: [ `${this.styleDefinitionsRefVar}['${styleId}'].style.lineColor` ],
        };
    }

    /**
     * Create an endStroke instruction
     */
    private createEndStrokeInstruction(): ICanvasInstruction {
        return { instruction: 'endStroke', params: []};
    }

    /**
     * Create a setStrokeStyle instruction by examining the given shape node.
     * @param data
     */
    private createSetStrokeStyleInstruction( data: IShapeNode ): ICanvasInstruction {
        const params = [];
        params.push( isNumber( data.strokeWidth ) ? data.strokeWidth : 1 );
        params.push( data.strokeLinecap ? `'${data.strokeLinecap}'` : 'undefined' );
        params.push( data.strokeLinejoin ? `'${data.strokeLinejoin}'` : 'undefined' );
        params.push( isNumber( data.strokeMiterlimit ) ? data.strokeMiterlimit : 'undefined'  );
        params.push( data.vectorEffect && data.vectorEffect === VectorEffect[ 'non-scaling-stroke' ] ? true : false );
        return { instruction: 'setStrokeStyle', params: params };
    }

    /**
     * Create a setStrokeDash instruction from given shape node
     * @param data
     */
    private createSetStrokeDashInstruction( data: IShapeNode ): ICanvasInstruction {
        return {
            instruction: 'setStrokeDash',
            params: [ `[${data.strokeDasharray.join( ',' )}]`, data.strokeDashoffset ? data.strokeDashoffset : 0 ],
        };
    }

    /**
     * Create starting style instructions from given shape node and the gradient instructions.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    private createStartingStyleInstructions(
        data: IShapeNode,
        gradients: { [ id: string ]: IShapeNode },
    ): IStartingStyles {
        let endFillNeeded = false;
        let endStrokeneeded = false;

        const opacity = ( isNumber( data.opacity )) ? data.opacity : false;
        const fillOpacity = ( isNumber( data.fillOpacity )) ? data.fillOpacity : 1;
        const strokeOpacity = ( isNumber( data.strokeOpacity )) ? data.strokeOpacity : 1;

        const instructions: ICanvasInstruction[] = [];

        // Default fill for svg is black, if fill is not set, assume it is black
        if ( !data.fill ) {
            data.fill = '#000000';
            data.fillType = FillType.solid;
        }

        if ( data.fill !== 'none' ) {
            if ( data.fillType === FillType.solid ) {
                const fillColor = opacity ?
                    this.colorToRgba( data.fill, opacity ) : this.colorToRgba( data.fill, fillOpacity );
                instructions.push( this.createBeginFillInstruction( fillColor ));
            } else {
                // Fill with gradients
                if ( !gradients[ data.fill ]) {
                    throw new Error( 'Gradient instruction is missing for fill id ' + data.fill );
                }
                const gradientOutput = this.processGradient( gradients[ data.fill ], GradientUsageType.fill );
                instructions.push( gradientOutput.gradient );
                this.styleDefinitions.push( ...gradientOutput.styleDefinitions );
            }
            endFillNeeded = true;
        }

        if ( data.strokeWidth || data.strokeLinecap
            || data.strokeLinejoin || data.strokeMiterlimit || data.vectorEffect ) {
            instructions.push( this.createSetStrokeStyleInstruction( data ));
            endStrokeneeded = true;
        }
        if ( data.strokeDasharray ) {
            instructions.push( this.createSetStrokeDashInstruction( data ));
            endStrokeneeded = true;
        }
        if ( data.stroke && data.stroke !== 'none' ) {
            if ( data.strokeType === FillType.solid ) {
                const strokeColor = opacity ?
                    this.colorToRgba( data.stroke, opacity ) : this.colorToRgba( data.stroke, strokeOpacity );
                instructions.push( this.createBeginStrokeInstruction( strokeColor ));
            } else {
                if ( !gradients[ data.stroke ]) {
                    throw new Error( 'Gradient instruction is missing for stroke id ' + data.stroke );
                }
                const gradientOutput = this.processGradient( gradients[ data.stroke ], GradientUsageType.stroke );
                instructions.push( gradientOutput.gradient );
                this.styleDefinitions.push( ...gradientOutput.styleDefinitions );
            }
            endStrokeneeded = true;
        }

        return {
            endFillNeeded: endFillNeeded,
            endStrokeNeeded: endStrokeneeded,
            instructions: instructions,
        };
    }

    /**
     * Create style ending instructions for the current starting style instructions.
     * @param startingStyles
     */
    private createEndStyleInstructions( startingStyles: IStartingStyles ): ICanvasInstruction[] {
        const instructions: ICanvasInstruction[] = [];
        if ( startingStyles.endStrokeNeeded ) {
            instructions.push( this.createEndStrokeInstruction());
        }

        if ( startingStyles.endFillNeeded ) {
            instructions.push( this.createEndFillInstruction());
        }

        return instructions;
    }

    /**
     * Process and return the gradient instructions of a given gradient shape node based on the
     * usage type.
     * @param gradientNode shape node related to the gradient
     * @param type usage type
     */
    private processGradient( gradientNode: IShapeNode, type: GradientUsageType ): IGradientInstruction {
        const factory = this.getGradientFactory( GradientType[ gradientNode.type ]);
        return factory.createInstruction( gradientNode, type );
    }

    /**
     * Return a gradient factory instance based on the required factory type
     * @param type
     */
    private getGradientFactory( type: GradientType ): IGradientInstructionFactory {
        if ( type === GradientType.linearGradient ) {
            return new LinearGradientCanvasInstructionFactory();
        }
        if ( type === GradientType.radialGradient ) {
            return new RadialGradientCanvasInstructionFactory();
        }
    }
}
