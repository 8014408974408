import { of, fromEvent, fromEventPattern, Observable, Subscription } from 'rxjs';
import { takeUntil, delay, mergeMap } from 'rxjs/operators';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

/**
 * This enum holds all possible placement options for
 * the tooltip.
 */
export enum ToolTipPlacement {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
}

@Directive({
    selector: '[tooltip]',
})
/**
 * A directive to show tooltips. Can be used on any HTML element.
 * Tooltip can be placed in any of the supported locations.
 *
 * This tooltip can be shown on hover,
 * or can be triggered manually using show and hide methods.
 *
 * @since 2017-06-07
 * @author Ramishka.
 */
export class ToolTip implements OnInit, OnDestroy {
    /**
     * Input for tooltip placement option.
     * This could be any value in {@link TooltipPlacement} enum
     */
    @Input()
    public placement: ToolTipPlacement = ToolTipPlacement.top;

    /**
     * If true, the tooltip will be shown always.
     */
    @Input()
    public showAlways: boolean = false;

    /**
     * This will disable the tooltip from showing when hovering.
     */
    @Input()
    public deactivated: boolean = false;

    /**
     * Delay to start displaying the tooltip after
     * pointer moved on top of the host element.
     * The value is in milliseconds.
     */
    @Input()
    public delay: number = 100;

    /**
     * Show tooltip on-hover if the value is true
     * Show tooltip using manual-trigger if the value is false
     */
    @Input()
    public openOnHover: boolean = true;

    /**
     * The host element for which the tooltip is displayed for.
     * Tooltip placement is based on this element.
     */
    protected element: HTMLElement;

    /**
     * The base (rectangular area) of the tooltip containing the tooltip text.
     */
    protected base: HTMLElement;

    /**
     * Tooltip pointer.
     */
    protected pointer: HTMLElement;

    /**
     * Observable that emits when a mouse enter event is fired
     * by the host element
     */
    protected mouseEnter: Observable<MouseEvent>;

    /**
     * Observable that emits when a mouse leave or mouse down events
     * are fired by the host element
     */
    protected mouseExit: Observable<MouseEvent>;

    /**
     * The padding to add when tooltip is repositioned when its colliding
     * with a border.
     */
    protected borderPadding: number = 1;

    protected subs: Array<Subscription> = [];

    /**
     * Sets the host element and starts listening to mouse enter
     * and leave events.
     * @param elementRef Host element
     */
    constructor( elementRef: ElementRef ) {
        this.element = elementRef.nativeElement;
        if ( this.openOnHover === true ) {
            this.initializeMouseListeners();
        }
    }

    /**
     * Invoked on init. Creates the tooltip instances
     * and starts listening to events from the host element.
     */
    public ngOnInit() {
        if ( this.element ) {
            const content = this.element.querySelector( '.tooltip-content' );

            // Remove tooltip content span if deactivated
            if ( content && this.deactivated ) {
                this.element.querySelector( '.tooltip-content' ).remove();
                return;
            }

            if ( content ) {
                this.element.querySelector( '.tooltip-content' ).remove();
                this.createTooltip( content );
                if ( this.showAlways ) {
                    setTimeout(() => {
                        this.show();
                    }, this.delay );
                } else if ( this.openOnHover === true ) {
                    this.subs.push( this.mouseEnter.subscribe( event => this.show()));
                    this.subs.push( this.mouseExit.subscribe( event => this.hide()));
                }
            }
        }
    }

    public ngOnDestroy() {
        this.hide();
        this.subs.forEach( sub => sub.unsubscribe());
    }

    /**
     * This function will show the tooltips on mouse enter events.
     * If you set openOnHover to false, you can manually call show() to show the tooltip.
     */
    public show() {
        document.body.appendChild( this.base );
        document.body.appendChild( this.pointer );
        this.position();
    }

    /**
     * Hides the tooltip and cleans up any elements added
     * to the document.
     * This function will hide the tooltips on mouse exit events.
     * If you set openOnHover to false, you can manually call hide() to hide the tooltip.
     */
    public hide() {
        if ( this.base && document.body.contains( this.base )) {
            document.body.removeChild( this.base );
        }
        if ( this.pointer && document.body.contains( this.pointer )) {
            document.body.removeChild( this.pointer );
        }
    }

    /**
     * This method initializes the mouse listeners
     * and assigns the event handlers
     */
    protected initializeMouseListeners() {
        this.mouseExit = fromEventPattern( listener => {
            this.element.addEventListener( 'mouseleave', listener as EventListener );
            this.element.addEventListener( 'mousedown', listener as EventListener );
        });
        this.mouseEnter = ( fromEvent( this.element, 'mouseenter' ) as Observable<MouseEvent> ).pipe(
            mergeMap( event =>
                of( event ).pipe(
                    delay( this.delay ),
                    takeUntil( this.mouseExit )),
            ));
    }

    /**
     * This function creates the tooltip elements and
     * adds the relevant css classes to them
     */
    protected createTooltip( content ) {
        this.base = document.createElement( 'div' );
        this.base.className += 'tooltip';
        this.base.appendChild( content );

        this.pointer = document.createElement( 'div' );
        this.pointer.classList.add( 'tooltip-arrow' );
        this.pointer.classList.add( this.placement );
    }

    /**
     * Positionins the tooltip base and arrow.
     */
    protected position() {
        const elementBounds: ClientRect = this.element.getBoundingClientRect();
        const pointerWidth: number = this.pointer.offsetWidth || this.pointer.clientWidth;
        const pointerHeight: number = this.pointer.offsetHeight || this.pointer.clientHeight;
        const baseWidth: number = this.base.offsetWidth || this.base.clientWidth;
        const baseHeight: number = this.base.offsetHeight || this.base.clientHeight;

        switch ( this.placement ) {
            case ToolTipPlacement.top:
                this.positionTop( elementBounds, baseWidth, baseHeight, pointerWidth, pointerHeight );
                break;

            case ToolTipPlacement.bottom:
                this.positionBottom( elementBounds, baseWidth, pointerWidth, pointerHeight );
                break;

            case ToolTipPlacement.left:
                this.positionLeft( elementBounds, baseHeight, baseWidth, pointerWidth );
                break;

            case ToolTipPlacement.right:
                this.positionRight( elementBounds, baseHeight, pointerWidth );
                break;
        }

        // if ( this.placement === ToolTipPlacement.top ) {
        //     this.positionTop( elementBounds, baseWidth, baseHeight, pointerWidth, pointerHeight );
        // } else if ( this.placement === ToolTipPlacement.bottom ) {
        //     this.positionBottom( elementBounds, baseWidth, pointerWidth, pointerHeight );
        // } else if ( this.placement === ToolTipPlacement.left ) {
        //     this.positionLeft( elementBounds, baseHeight, baseWidth, pointerWidth );
        // } else if ( this.placement === ToolTipPlacement.right ) {
        //     this.positionRight( elementBounds, baseHeight, pointerWidth );
        // }
    }

    /**
     * This function arranges the tooltip elements when the placement
     * is at the top.
     * @param elementBounds - bounds of the host elements
     * @param baseWidth - width of the tooltip base
     * @param baseHeight - height of the tooltip base
     * @param pointerWidth - width of the pointer arrow
     * @param pointerHeight - height of the pointer arrow
     */
    protected positionTop( elementBounds: ClientRect,
                           baseWidth: number,
                           baseHeight: number,
                           pointerWidth: number,
                           pointerHeight: number ) {
        this.pointer.style.top = elementBounds.top - pointerHeight + 'px';
        this.pointer.style.left = elementBounds.left + ( this.element.offsetWidth - pointerWidth ) / 2 + 'px';
        this.base.style.top = elementBounds.top - ( baseHeight + pointerHeight ) + 'px';
        const startX: number = elementBounds.left + ( this.element.offsetWidth - baseWidth ) / 2;
        this.base.style.left = startX - this.getXOffset( startX, baseWidth ) + 'px';
    }

    /**
     * This function arranges the tooltip elements when the placement
     * is set to the bottom.
     * @param elementBounds - bounds of the host elements
     * @param baseWidth - width of the tooltip base
     * @param pointerWidth - width of the pointer arrow
     * @param pointerHeight - height of the pointer arrow
     */
    protected positionBottom( elementBounds: ClientRect,
                              baseWidth: number,
                              pointerWidth: number,
                              pointerHeight: number ) {
        this.pointer.style.top = elementBounds.bottom + 'px';
        this.pointer.style.left = elementBounds.left + ( this.element.offsetWidth - pointerWidth ) / 2 + 'px';
        this.base.style.top = elementBounds.bottom + pointerHeight + 'px';
        const startX: number = elementBounds.left + ( this.element.offsetWidth - baseWidth ) / 2;
        this.base.style.left = startX - this.getXOffset( startX, baseWidth ) + 'px';
    }

    /**
     * This function arranges the tooltip elements when the placement
     * is set to the left.
     * @param elementBounds - bounds of the host elements
     * @param baseWidth - width of the tooltip base
     * @param baseHeight - height of the tooltip base
     * @param pointerWidth - width of the pointer arrow
     */
    protected positionLeft( elementBounds: ClientRect,
                            baseHeight: number,
                            baseWidth: number,
                            pointerWidth: number ) {
        this.pointer.style.top = elementBounds.top + ( this.element.offsetHeight - pointerWidth ) / 2 + 'px';
        this.pointer.style.left = elementBounds.left - pointerWidth + 'px';
        this.base.style.left = elementBounds.left - ( baseWidth + pointerWidth ) + 'px';
        const startY: number = elementBounds.top + ( this.element.offsetHeight - baseHeight ) / 2;
        this.base.style.top = startY - this.getYOffset( startY, baseHeight ) + 'px';
    }

    /**
     * This function arranges the tooltip elements when the placement
     * is set to the right.
     * @param elementBounds - bounds of the host elements
     * @param baseHeight - height of the tooltip base
     * @param pointerWidth - width of the pointer arrow
     */
    protected positionRight( elementBounds: ClientRect,
                             baseHeight: number,
                             pointerWidth: number ) {
        this.pointer.style.top = elementBounds.top + ( this.element.offsetHeight - pointerWidth ) / 2 + 'px';
        this.pointer.style.left = elementBounds.right + 'px';
        this.base.style.left = elementBounds.right + pointerWidth + 'px';
        const startY: number = elementBounds.top + ( this.element.offsetHeight - baseHeight ) / 2;
        this.base.style.top = startY - this.getYOffset( startY, baseHeight ) + 'px';
    }

    /**
     * This function returns the offset along the x axis the tooltip
     * elements need to be shifted, if the tooltip is cutting left or
     * right window border.
     * @param startX- starting x position of the tooltip
     * @param tooltipWidth - width of the tooltip.
     */
    protected getXOffset( startX: number, tooltipWidth: number ): number {
        if ( startX + tooltipWidth > window.innerWidth ) {
            return ( startX + tooltipWidth ) - window.innerWidth + this.borderPadding;
        } else if ( startX < 0 ) {
            return startX - this.borderPadding;
        }
        return 0;
    }

    /**
     * This function returns the offset along the y axis the tooltip
     * elements need to be shifted when the tooltip is overflowing the top or
     * bottom border of the window.
     * @param startY- starting y position of the tooltip
     * @param tooltipHeight - height of the tooltip.
     */
    protected getYOffset( startY: number, tooltipHeight: number ): number {
        if ( startY + tooltipHeight > window.innerHeight ) {
            return ( startY + tooltipHeight ) - window.innerHeight + this.borderPadding;
        } else if ( startY < 0 ) {
            return startY - this.borderPadding;
        }
        return 0;
    }
}
