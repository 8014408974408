import { uniq, uniqBy } from 'lodash';
import { IShapeDefinition, KeyCode, PlanPermission } from 'flux-definition';
import { Component, ChangeDetectionStrategy, OnDestroy, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subscription, empty, Observable, forkJoin, of, fromEvent, concat, defer } from 'rxjs';
import { ModalController, CommandService, Random, Rectangle, StateService, Tracker } from 'flux-core';
import { SearchService, ISearchResultItem } from '../../search/search.svc';
import { switchMap, take, map, tap, catchError } from 'rxjs/operators';
import { IShapeSearchResultItem } from '../../../editor/shape/providers/shape-search-provider';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { AbstractSearch } from './abstract-search';
import { LibraryList } from '../../../editor/ui/temp-add-libs-menu/library-list';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { DataStore } from 'flux-store';
import { TemplateModel } from '../../../editor/diagram/templates/template.mdl';
import { TranslateService } from '@ngx-translate/core';
import { TempAddLibsMenu } from '../../../editor/ui/temp-add-libs-menu/temp-add-libs-menu.cmp';
import { TextInput } from 'flux-core/src/ui';
import { PlanPermManager } from 'flux-user';
import { StaticLibraryLoader } from '../../../editor/library/static-library-loader.svc';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
    selector: 'sidebar-search',
    templateUrl: './sidebar-search.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './sidebar-search.scss' ],
})

/**
 * SidebarSearch
 * This component is a specific dropdown component where it
 * shows a input as the dropdown button for entering search query.
 * Dropdown will show both matching shapes and libraries
 *
 * @author Shermin
 * @since 2019-08-06
 */
export class SidebarSearch extends AbstractSearch implements OnDestroy, OnInit  {

    /**
     * Behavoir subject that emits on closing and opening dropdown.
     */
    public isOpen: BehaviorSubject<any> = new BehaviorSubject( false );

    /**
     * Final searched results will be emitted
     */
    public searchResults: BehaviorSubject<ISearchResultItem[]> = new BehaviorSubject([]);

    /**
     * All the libraries with the shape definition ids
     */
    public allLibWithShapeDefs: ILibraryAndShapesDefs[] = [];

    /**
     * This subject is used to make the search sequencial,
     * a scenario where previous search result emits later
     */
    public onSearchQueryChange: BehaviorSubject<string>;

    @Input() public placeholder: string = 'PLACEHOLDERS.SHAPE_SEARCH';

    /**
     * The search text input element
     */
    @ViewChild( 'textInput' )
    protected textInput: TextInput;

    /**
     * Library list.
     * Loaded Initially one time only
     */
    protected childItems = [];

    /**
     * Holds all the subscriptions.
     */
    private subs: Subscription[] = [];

    /**
     * search results global state updated time
     */
    private lastSearchTime = 0;

    constructor(
        protected modalController: ModalController,
        protected state: StateService<any, any>,
        protected searchService: SearchService,
        protected defLocator: DefinitionLocator,
        public eRef: ElementRef,
        protected loader: StaticLibraryLoader,
        protected commandService: CommandService,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
        protected datastore: DataStore,
        protected translateService: TranslateService,
        protected planPermManager: PlanPermManager,
        protected libraryList: LibraryList,
    ) {
        super( state );
        this.onSearchQueryChange = new BehaviorSubject( '' );
    }

    /**
     * Creates the localized search result found label to be used in template
     * @returns a string observable emits only once
     */
    public getSearchResultsLabel(): Observable<string> {
        return this.translate( 'LABELS.FOUND_RESULTS', { noOfResults: this.searchResults.getValue().length });
    }

    /**
     * Loads the libraries and the shapes for those libraries
     */
    public ngOnInit() {
        this.subs.push(
            this.getMouseDownEventOnDocument().subscribe(),
            this.loadAllLibrariesWithShapes().pipe(
                switchMap(() =>
                    this.onSearchQueryChange.pipe(
                        switchMap( text => {
                            if ( text.length > 2 ) {
                                this.isOpen.next( true );
                                return this.doSearch( text );
                            } else {
                                this.isOpen.next( false );
                                return empty();
                                }
                            },
                        ),
                    )),
            ).subscribe(),
        );
    }

    /**
     * Handles the click out event of the component.
     * Search result will be closed when outside is clicked
     */
    public clickout( event ) {
        if ( this.eRef.nativeElement.contains( event.target )) {
            if (( event.target.type === 'text'
                && this.onSearchQueryChange.getValue().length  > 2 )
                || event.target.type !== 'text' ) {
                this.isOpen.next( true );
                return;
            }
        }
        this.isOpen.next( false );
    }

    /**
     * Handling Key down event
     * If enter pressed state will be set and routed to result component
     * @param event - Keyboard Event
     */
    public handleKeydown( event: KeyboardEvent ) {
        // Angular runs this handler twice where it suppose to run only once per enter key down.
        // to prevent against that we are checking if the search has been done after 1.5 seconds from the last search.
        if ( event.keyCode === KeyCode.Enter && ( Date.now() - this.lastSearchTime ) > 1500 ) {
            this.lastSearchTime = Date.now();
            this.filterShapesAndSetState();
        }
    }

    /**
     * Creates a thumbnail item for templates, load required info when needed.
     */
    public createShapeTemplateItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return {
            id: def.defId,
            title: def.name,
            type: 'shape',
            data: def,
            thumbnailType: 'image',
            thumbnailUrl: def.thumbnail,
        };
    }

    /**
     * Destroys and clears all the resources used by the
     * interaction handler
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Handle the drag start event on the library tile
     * Hide popover preview if it is open
     * @param event
     * @param data
     */
    public handleDragStart( event: any, data: any ) {
        Tracker.track( 'left.shapes.search.shape.drag', { value1Type: 'shapeDefId', value1: data.defId });
        const target = event.target as HTMLElement;
        const thumb: ( HTMLCanvasElement | HTMLImageElement ) = target.querySelector( '.shape-thumbnail-image' );
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData( 'Text', JSON.stringify( data ));
        event.dataTransfer.setData( 'TrackingInfo', JSON.stringify({
            location: 'SidebarSearch',
        }));
        event.dataTransfer.setDragImage( thumb, thumb.width / 2, thumb.height / 2 );
    }

    /**
     * Handles shape click event
     * When a shape is clicked it will be added to canvas
     * @param data - IShapeDefinition
     */
    public handleShapeClick( data: IShapeDefinition ) {
        Tracker.track( 'left.shapes.search.shape.click', { value1Type: 'shapeDefId', value1: data.defId });

        const diagramViewPort: Rectangle = this.state.get( 'DiagramViewPort' );
        if ( data.type === 'template' ) {
            this.addTemplate( data, diagramViewPort.centerX, diagramViewPort.centerY );
        } else {
            this.addShape( data, diagramViewPort.centerX, diagramViewPort.centerY );
        }
    }

    /**
     * Opens the library browser window.
     */
    public openLibBrowser() {
        Tracker.track( 'left.shapes.search.browseShapes' );
        this.modalController.show( TempAddLibsMenu );
    }

    /**
     * Updates the searched text and opens the Google Search panel
     */
    public openGoogleSearch() {
        Tracker.track( 'left.shapes.search.google' );
        this.state.set( 'ShapeSearchQuery', this.textInput.value );
        this.state.set( 'SelectedFloatingPanel', 'asset' );
    }

    /**
     * This method clear the search result when click on
     * shape Library
     */
    public handleLibraryClick() {
        this.searchResults.next([]);
    }

    /**
     * Returns data that's needed for tracking the user's interactions.
     */
    protected getDataForTracking( results: ISearchResultItem[]): {
        shapesCount: number,
        libsCount: number,
    } {
        if ( results ) {
            return {
                shapesCount: results.filter( result => result.type === 'shape' ).length,
                libsCount: results.filter( result => result.type === 'libs' ).length,
            };
        }
    }

    /**
     * This method will call relevant search methods
     * All the search methods will be called from here.
     * Returns a combination of all the results
     * @param searchQuery - Search Text
     * @returns an observable of ISearchResultItem array which emits only once
     */
    protected search( searchQuery: string ): Observable<ISearchResultItem[]> {
        return forkJoin(
            this.searchAllByName( searchQuery ),
            this.searchShapesByTags( searchQuery ),
        ).pipe(
            map(([ resultsFromName, resultsFromTags ]) => {
                const combineResults = resultsFromName.concat( resultsFromTags );
                return uniqBy( combineResults, 'id' );
            }),
        );
    }

    /**
     * Searching shapes by tags
     * Returns a combination of composed libraries with shapes
     * Libraries will be given a higher priority
     * @param searchQuery - Search Query
     * @returns an observable of ISearchResultItem array which emits only once.
     */
    protected searchShapesByTags( searchQuery: string ): Observable<ISearchResultItem[]> {
        return this.searchShapes( searchQuery, [ 'tags' ]).pipe(
            switchMap(( searchResults: ISearchResultItem[]) => {
                const arr = searchResults.map( searchResult => searchResult.id );
                const libsAndDefIds = this.getLibrariesAndNonMatchingDefs( arr );
                const defs = searchResults.filter( searchResult => libsAndDefIds.defIds.includes( searchResult.id ));
                const shapeObservables = defs.map( def =>
                     this.defLocator.getDefinition( def.id,
                        ( def as IShapeSearchResultItem ).version ).pipe(
                            map( loadedDef =>
                                this.createLibraryItem( loadedDef as IShapeDefinition ))),
                );
                if ( shapeObservables.length > 0 ) {
                    return forkJoin( ...shapeObservables ).pipe(
                        map( shapes => libsAndDefIds.libs.concat( shapes )),
                    );
                }
                if ( libsAndDefIds.libs.length > 0 ) {
                    return of( libsAndDefIds.libs );
                }
                return of([]);
            }),
        );
    }

    /**
     * Search All the providers by name.
     * Emits only once
     * @param searchQuery - Search Query
     * @returns An observable of ISearchResultItem array, emits only once.
     */
    protected searchAllByName( searchQuery: string ): Observable<ISearchResultItem[]> {
        return forkJoin(
            this.searchShapes( searchQuery, [ 'name' ]),
            this.searchLibs( searchQuery, [ 'label' ]),
        ).pipe(
            switchMap(([ shapes, libs ])  => {
                const searchResultSorted = this.sortResultsByScore( shapes.concat( libs ));
                return this.updateSearchResults( searchResultSorted );
            }),
        );
    }

    /**
     * Returns the ILibraryItem needed for rendering canvas based thumbnail items
     */
    protected createLibraryItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return ( def.type === 'template' )
            ? this.createShapeTemplateItem( def )
            : this.createShapeCanvasItem( def );
    }

    /**
     * Adds a basic type shape to canvas by dispatching addDiagramShape
     * @param data - IShapeDefinition
     * @param x - x position of shape
     * @param y - y position of shape
     */
    protected addShape( data: IShapeDefinition, x: number, y: number ) {
        const shape = {
            id: Random.shapeId(),
            defId: data.defId,
            version: data.version,
            x: this.vToDcoordinate.x( x - data.defaultBounds.width / 2 ),
            y: this.vToDcoordinate.y( y - data.defaultBounds.height / 2 ),
        };
        this.commandService.dispatch( DiagramCommandEvent.addDiagramShape, { shapes: { [shape.id]: shape }});
    }

    /**
     * Dispatch an addTemplate command to add multiple shapes into the canvas
     * @param def IShapeDefinition
     * @param x - x position of shape
     * @param y - y position of shape
     */
    protected addTemplate( def: any, x: number, y: number ) {
        const templateId = `${def.defId}:${def.version}`;
        concat(
            this.commandService.dispatch( DiagramCommandEvent.getTemplate,
                                            def.diagram, { templateId }),
            defer(() => this.datastore.findOneLatest( TemplateModel, { id: templateId }).pipe(
                switchMap(( tpl: TemplateModel ) => fromPromise( this.defLocator.getTemplateBounds( tpl )).pipe(
                    switchMap(( bounds: any ) => {
                        const clone = tpl.clone({
                            x: this.vToDcoordinate.x( x ) - bounds.width / 2,
                            y: this.vToDcoordinate.y( y ) - bounds.height / 2,
                        });
                        return this.commandService.dispatch( DiagramCommandEvent.addDiagramShape, {
                            cloned: true,
                            shapes: clone.shapes,
                            groups: clone.groups,
                            connections: clone.connections,
                            dataDefs: clone.dataDefs,
                        });
                    }),
                )),
            )),
        ).subscribe();
    }

    /**
     * Translates given string with parameters and returns an observable.
     * @param str Tranlation string Id
     * @param params Parameters.
     * @returns a string observable emits only once
     */
    private translate( str: string, params: any ): Observable<string> {
        return this.translateService.get( str, params ).pipe(
            take( 1 ),
        );
    }

    /**
     * Search is performed by entered search query and emits search results
     * @param searchQuery - Search Query
     * @return An empty observable which emits only once.
     */
    private doSearch( searchQuery: string ): Observable<any> {
        return this.search( searchQuery ).pipe(
            take( 1 ),
            map(( sortedResults: ISearchResultItem[]) => {
                const trackingData = this.getDataForTracking( sortedResults );
                Tracker.track( 'left.shapes.search.result.count', {
                    value1Type: 'keyword',
                    value1: searchQuery,
                    value2Type: 'shapeCount',
                    value2: `${trackingData.shapesCount }`,
                    value3Type: 'libsCount',
                    value3: `${trackingData.libsCount }`,
                });

                this.searchResults.next( sortedResults );
                return empty();
            }),
        );
    }

    /**
     * All the libraries will be loaded along with the relevant shapes
     * @returns An observable with libraries with shape def ids, emits only once.
     */
    private loadAllLibrariesWithShapes(): Observable<ILibraryAndShapesDefs[]> {
        const observables = [];
        this.libraryList.getAllGroups().forEach( group =>
            this.libraryList.getLibrariesInGroup( group ).map( lib =>
                observables.push(
                    this.loader.getLibrary( lib.id ).pipe(
                        map( shapeDefs => ({
                            shapeIds: this.removeVersionFromDefIds( shapeDefs.defs ),
                            libTitle: lib.label,
                            libId: lib.id,
                        })),
                        catchError(() => of( undefined )),
                    ),
                ),
            ),
        );
        return forkJoin( ...observables ).pipe(
            tap( libsWithShapes => {
            libsWithShapes = libsWithShapes.filter( libWithShapes => !!libWithShapes );
            this.allLibWithShapeDefs = libsWithShapes ;
        }));
    }

    /**
     * Removes version from defId
     * @param defsWithVersion - An array of defIds
     * @returns a string array
     */
    private removeVersionFromDefIds( defsWithVersion: string[]): string[] {
        return defsWithVersion.map( def => {
            const indexOfDot = def.lastIndexOf( '.' );
            return def.substring( 0, indexOfDot );
        });
    }

    /**
     * Registers mouse down event on document
     * @returns An Observable of Mouse event, emits as user clicks
     */
    private getMouseDownEventOnDocument(): Observable<MouseEvent> {
        return fromEvent( document, 'mousedown' ).pipe(
            tap(( e: MouseEvent ) => this.clickout( e )),
        );
    }

    /**
     * Composed Libs from the shape defs from search.
     * @param defIds - An array of defIds
     * @returns composed libraries and non matching shapes
     */
    private getLibrariesAndNonMatchingDefs( defIds: string[]): { libs: ISearchResultItem[], defIds: string[] } {
        let allMatchedDefIds = [];
        const libs = [];
        this.allLibWithShapeDefs.forEach( lib => {
            const currentMatchedIds = lib.shapeIds.filter( word =>  defIds.includes( word ));
            const matchedPercentage = ( currentMatchedIds.length / lib.shapeIds.length * 100 ).toFixed( 2 );
            if ( parseFloat( matchedPercentage ) > 80 ) {
                const index = libs.findIndex( l => l.id === lib.libId );
                if ( index === -1 ) {
                    libs.push(
                        {
                            id: lib.libId,
                            title: lib.libTitle,
                            type: 'libs',
                        },
                    );
                }
                allMatchedDefIds = allMatchedDefIds.concat( currentMatchedIds );
                allMatchedDefIds = uniq( allMatchedDefIds );
            }
        });
        return {
            libs: libs,
            defIds: defIds.filter( defId =>  !allMatchedDefIds.includes( defId )),
        };
    }

    /**
     * Update only shape results for previewing
     * @param sortedResults - sorted results array
     * @returns An observable of ISearchResultItem array, emits only once.
     */
    private updateSearchResults( sortedResults: ISearchResultItem[]): Observable<ISearchResultItem[]> {
        if ( sortedResults && sortedResults.length > 0 ) {
            const observables = sortedResults.map( result => {
                if ( result.type === 'shape' ) {
                    return this.defLocator.getDefinition( result.id,
                            ( result as IShapeSearchResultItem ).version ).pipe(
                        map( def => this.createLibraryItem( def as IShapeDefinition )),
                    );
                }
                return of( result );
            });
            return forkJoin( ...observables );
        }
        return of([]);
    }

    /**
     * Sort search result array by score
     * @param resultArray - search result array
     * @returns ISearchResultItem array
     */
    private sortResultsByScore( resultArray: ISearchResultItem[]): ISearchResultItem[] {
        return resultArray.sort(( a, b ) => {
                if ( a.score < b.score ) {
                    return 1;
                }
                if ( a.score > b.score ) {
                    return -1;
                }
                return 0;
            });
    }

    /**
     * Search shapes on shape definitions using by a text and options
     * @param searchQuery - Search Query
     * @param keys - keys to be searched by
     * @returns An observable of ISearchResultItem array
     */
    private searchShapes( searchQuery: string, keys: string[]): Observable<ISearchResultItem[]> {
        return this.searchService.search( searchQuery, [ 'shape' ],
                                { keys: keys, threshold: -10000 });
    }

    /**
     * Search libraries on library definitions
     * @param searchQuery - Search Query
     * @param keys - keys to be searched by
     * @returns An observable of ISearchResultItem array
     */
    private searchLibs( searchQuery: string, keys: string[]): Observable<ISearchResultItem[]> {
        return this.searchService.search( searchQuery, [ 'libs' ],
                                { keys: keys, threshold: -10000 });
    }

    /**
     * Filter shapes and set to state
     */
    private filterShapesAndSetState() {
        const filteredShapes = this.searchResults.getValue().filter(( defIdAndVer: ISearchResultItem ) =>
            defIdAndVer.type === 'shape' );
        this.setState( filteredShapes as any, this.onSearchQueryChange.getValue());
    }

    /**
     * Creates a search result for a shape which is compatible with thumbnail-canvas
     * @param def IShapeDefinition
     * @returns IShapeResultItem
     */
    private createShapeCanvasItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return {
            id: def.defId,
            title: def.name,
            type: 'shape',
            data: def,
            thumbnailType: 'canvas',
            canvasIdPrefix: 'thumb-search-drop',
        } as IShapeThumbnailItem;
    }
}

/**
 * Holds the data of a search result specifically for a shape
 */
export interface IShapeThumbnailItem extends ISearchResultItem {

    /**
     * Holds the title of the shape
     */
    title: string;

    /**
     * Indicates if the library item renders a image (png/svg) or has
     * code that can be used to draw the thubnail on a canvas.
     */
    thumbnailType: 'image' | 'canvas' | 'template';

    /**
     * Hold the permission id realated to current item
     */
    permission?: PlanPermission;

    /**
     * If the thumbnailType is 'image' this must contain the url for an image file.
     * If the thumbnailType is 'canvas' this field is not required.
     */
    thumbnailUrl?: string;

    /**
     * This holds the data of the item
     * This can be a model, definition or IDefinitionSummary
     */
    data: any;

    /**
     * Canvas Id prefix will set a unique value for canvas rendering
     */
    canvasIdPrefix?: string;

}

/**
 * Interface which defines the library definitions including
 * shape definitions for each library
 */
export interface ILibraryAndShapesDefs {

    /**
     * Holds library Id
     */
    libId: string;

    /**
     * Library title
     */
    libTitle: string;

    /**
     * Shapes of each library
     */
    shapeIds: string[];

    /**
     * List of group library belong
     */
    groups?: string[];

    /**
     * Main FAB display group library belongs to
     */
    libGroup?: string;

}
