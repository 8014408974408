import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [],
  bootstrap: [],
})
export class FluxDiagramComposer {
}
