import { Proxied } from '@creately/sakota';
import { DataType, ShapeType } from 'flux-definition';
import { uniq } from 'lodash';
import { Observable, forkJoin, from, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Random } from 'flux-core';
import { LOOKUP_CONNECTOR } from 'flux-diagram-composer';
import { DiagramModel } from '../diagram/model/diagram.mdl';
import { ConnectorModel } from '../shape/model/connector.mdl';
import { EDataModel } from './model/edata.mdl';
import { EDataRegistry } from './edata-registry.svc';

export class EntityLinkService {

    public static createLinkedConnectors(
        changeModel: Proxied<DiagramModel>, shape, entity,
        projectEDataModels, zIndex, def, createOptions: any = {}) {
        const connectorIds = [];
        const eData = changeModel.eData;
        const entityDef = entity.getDef();
        const lookupFields = ( Object.values( entityDef.dataItems ) as any )
            .filter( di => di.type === DataType.LOOKUP )
            .filter( di => eData.includes( this.getRefEDataId( di.options )));
        const bounds = entity.style?.bounds || def.defaultBounds;
        const { useMirrorHandshake = false, withinContainer = true, connectorConfig = {}} = createOptions;
        const { visible: isVisible, hidden, ...extraConnConfigTemp } = connectorConfig;
        let extraConnConfig = {};
        let visible = false;
        if ( isVisible !== undefined ) {
            visible = isVisible;
        } else if ( hidden !== undefined ) {
            visible = !hidden;
        } else {
            visible = Object.values( changeModel.shapes ).filter( s => s.type === ShapeType.Connector )
                .filter( c => c.defId === LOOKUP_CONNECTOR.defId )
                .some( c => !( c as ConnectorModel ).hidden );
        }
        lookupFields.forEach( di => {
            let handshake = di.id;
            const { isMirrorField = false } = di.options;
            const swapShapes = useMirrorHandshake !== isMirrorField; // logical XOR
            if ( swapShapes ) {
                const reversedId = di.id.split( '' ).reverse().join( '' );
                if ( entityDef.dataItems[reversedId]) {
                    handshake = reversedId;
                }
            }
            if ( entity.data[di.id] && entity.data[di.id].length > 0 ) {
                const eDataModel = projectEDataModels[this.getRefEDataId( di.options )];
                entity.data[di.id].forEach( entId => {
                    const linkedEntity = eDataModel.entities[entId];
                    if ( !linkedEntity.shapes[changeModel.id]) {
                        return;
                    }
                    let connectedShapeIds = Object.keys( linkedEntity.shapes[changeModel.id]);
                    if ( withinContainer && shape.containerId ) {
                        connectedShapeIds = connectedShapeIds.filter( sId => {
                            const s = changeModel.shapes[sId] as any;
                            return s.containerId === shape.containerId;
                        });
                    }
                    // FIXME: hard coded logic for now
                    if ( handshake === 'Ky12FugVBjq' ) { // secondary subordinates
                        extraConnConfig = {
                            entryClass: 'connectors.bundle.js#ConnectorCurved',
                        };
                    }
                    if ( handshake === 'eHbEzWF8CTo' ) {
                        extraConnConfig = extraConnConfigTemp;
                    }
                    connectedShapeIds.forEach( otherShapeId => {
                        const connId = Random.getIdDiff( otherShapeId, shape.id );
                        const connectedShape = changeModel.shapes[otherShapeId] as any;
                        let fromShape = shape;
                        let toShape = connectedShape;
                        let fromBounds = bounds;
                        let toBounds = connectedShape.width ? {
                            width: connectedShape.width,
                            height: connectedShape.height,
                        } : connectedShape.defaultBounds;
                        if ( swapShapes ) {
                            toShape = shape;
                            fromShape = connectedShape;
                            fromBounds = toBounds;
                            toBounds = bounds;
                        }
                        // currentSide === 'right'
                        let fromX = fromShape.x;
                        let fromY = fromShape.y + fromBounds.height / 2;
                        let toX = toShape.x + toBounds.width;
                        let toY = toShape.y + toBounds.height / 2;
                        if ( toShape.y > fromShape.y + fromBounds.height ) {
                            // currentSide === 'top'
                            fromY = fromShape.y + fromBounds.height;
                            toY = toShape.y;
                            fromX = fromShape.x + fromBounds.width / 2;
                            toX = toShape.x + toBounds.width / 2;
                        } else if ( fromShape.y > toShape.y + toBounds.height ) {
                            // currentSide === 'bottom'
                            fromY = fromShape.y;
                            toY = toShape.y + toBounds.height;
                            fromX = fromShape.x + fromBounds.width / 2;
                            toX = toShape.x + toBounds.width / 2;
                        } else if ( toShape.x > fromShape.x + fromBounds.width ) {
                            // currentSide === 'left'
                            fromX = fromShape.x + fromBounds.width;
                            toX = toShape.x;
                        }
                        changeModel.shapes[connId] = {
                            ...LOOKUP_CONNECTOR,
                            // entryClass: 'connectors.bundle.js#ConnectorSmoothAngled',
                            // ends: {
                            //     to: 'connectors.bundle.js#PointerFilled',
                            // },
                            ...extraConnConfig,
                            id: connId,
                            hidden: !visible,
                            handshake: [ handshake ],
                            texts: {},
                            zIndex,
                            path: {
                                headId: 'h',
                                h: {
                                    id: 'h',
                                    shapeId: fromShape.id,
                                    nextId: 't',
                                    prevId: null,
                                    x: fromX,
                                    y: fromY,
                                    direction: 0,
                                    bumps: [
                                        [],
                                    ],
                                    c1: null,
                                    c2: null,
                                },
                                tailId: 't',
                                t: {
                                    id: 't',
                                    nextId: null,
                                    prevId: 'h',
                                    x: toX,
                                    y: toY,
                                    direction: 180,
                                    shapeId: toShape.id,
                                    c2: null,
                                    c1: null,
                                    bumps: [
                                        [],
                                    ],
                                },
                            },
                            bumps: [],
                        } as any;
                        // FIXME: hard coded logic for now
                        if ( shape.defId === 'creately.people.employee' ) { // org chart shape
                            ( changeModel.shapes[connId] as any ).excludeInLayouting =
                                handshake !== 'eHbEzWF8CTo'; // handshake !== 'subordinates'
                        }
                        connectorIds.push( connId );
                    });
                });
            }
        });
        return connectorIds;
    }

    public static updateLinkConnectors(
        eDataModel: EDataModel,
        changeModel: DiagramModel,
        eDataGetter: ( eDataId: string ) => Observable<EDataModel>,
        entityId: string,
        item ) {
        const entity = eDataModel.entities[entityId];
        const entityDef = entity.getDef();
        if ( !entity.links || !item.connectorIds ) {
            return of( eDataModel );
        }
        const links = {};
        Object.values( entity.links ).forEach( l => {
            if ( !links[l.handshake]) {
                links[l.handshake] = {};
            }
            links[l.handshake][l.entityId] = l;
        });
        let eDataIds = [];
        const conns = item.connectorIds.map( cId => changeModel.shapes[cId]);
        const lookupConnectors = conns.filter( c => c.defId === LOOKUP_CONNECTOR.defId );
        const connectors: {
            [handshake: string]: {
                [entityId: string]: {
                    connectorId: string;
                    shapeId: string;
                    defId: string;
                    shapeDefId?: string;
                }[];
            };
        } = {};
        lookupConnectors.forEach( c => {
            const path = c.path;
            const fromShapeId = path[path.headId].shapeId;
            const toShapeId = path[path.tailId].shapeId;
            let handshake = c.handshake[0];
            const otherShape = changeModel.shapes[ fromShapeId === item.id ? toShapeId : fromShapeId ];
            if ( fromShapeId !== item.id && otherShape.entityDefId === item.entityDefId
                && this.getEDataId( otherShape ) === Object.keys( item.eData )[0]) {
                handshake = handshake.split( '' ).reverse().join( '' );
            }
            if ( !connectors[handshake]) {
                connectors[handshake] = {};
            }
            if ( !connectors[handshake][this.getEntityId( otherShape )]) {
                connectors[handshake][this.getEntityId( otherShape )] = [];
            }
            connectors[handshake][this.getEntityId( otherShape )].push({
                connectorId: c.id,
                shapeId: otherShape.id,
                defId: c.defId,
                shapeDefId: otherShape.defId,
            });
            eDataIds.push( this.getEDataId( otherShape ));
        });
        if ( lookupConnectors.length === 0 ) {
            return of( eDataModel );
        }
        eDataIds = uniq( eDataIds.filter( eId => eId !== eDataModel.id ));
        let obs1: Observable<any> = of( eDataModel );
        const changeModels = {
            [eDataModel.id]: eDataModel,
        };
        if ( eDataIds.length > 0 ) {
            obs1 = forkJoin( eDataIds.map( id => eDataGetter( id ))).pipe(
                tap( models => {
                    models.forEach( m => changeModels[m.id] = m );
                }),
            );
        }
        return obs1.pipe(
            tap(() => {
                for ( const handshake in connectors ) {
                    const reverseId = handshake.split( '' ).reverse().join( '' );
                    for ( const entId in connectors[ handshake ]) {
                        const link = links[ handshake ][ entId ];
                        if ( !link.connectors ) {
                            link.connectors = {};
                        }
                        if ( !link.connectors[changeModel.id]) {
                            link.connectors[changeModel.id] = {};
                        }
                        const mirrorHandshake = link.eDataId === eDataModel.id && entityDef.dataItems[reverseId] ?
                            reverseId : handshake;
                        const mirrorLink = Object.values(
                            changeModels[link.eDataId].entities[entId].links )
                            .find( l => l.handshake === mirrorHandshake && l.entityId === entityId );
                        if ( !mirrorLink.connectors ) {
                            mirrorLink.connectors = {};
                        }
                        if ( !mirrorLink.connectors[changeModel.id]) {
                            mirrorLink.connectors[changeModel.id] = {};
                        }
                        connectors[ handshake ][ entId ].forEach( conn => {
                            link.connectors[changeModel.id][conn.connectorId] = {
                                shapeId: conn.shapeId,
                                shapeDefId: conn.shapeDefId,
                                defId: conn.defId, // should be LOOKUP_CONNECTOR.defId
                            };
                            mirrorLink.connectors[changeModel.id][conn.connectorId] = {
                                shapeId: item.id,
                                shapeDefId: item.defId,
                                defId: conn.defId, // should be LOOKUP_CONNECTOR.defId
                            };
                        });
                    }
                }
            }),
            mergeMap(() => from( Object.values( changeModels ))),
        );
    }

    private static getRefEDataId( options ) {
        return options.eDataId || EDataRegistry.getEDataId( options.eDataDefId );
    }

    private static getEDataId( shape ) {
        return shape.eDataId || Object.keys( shape.eData )[0];
    }

    private static getEntityId( shape ) {
        return shape.entityId || Object.values( shape.eData )[0];
    }
}
