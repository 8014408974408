import { isObject, merge, findIndex } from 'lodash';
import { Observable, of, forkJoin, combineLatest } from 'rxjs';
import { modify } from '@creately/mungo';
import { Database, Collection, Selector, Modifier, FindOptions, DocumentChange } from '@creately/rxdata';
import { AbstractModel, Deserializer, IUnsavedModelChange, Flags, EventIdentifier, IEventLog } from 'flux-core';
import { take, map, switchMap } from 'rxjs/operators';
import { EventCollector } from 'flux-core';

/**
 * ModelStore
 * ModelStore classes are responsible for performing storage related
 * operations for a model type (typeof AbstractModel). DataStore service
 * will create instances of this class for each model type. If a model type
 * requires different behavior than this, it should create a new class
 * extending this class and register it with the DataStore service.
 *
 * @author: thanish
 * @since: 2017-08-24
 */
export class ModelStore {

    public number: any;
    /**
     * _modelLevel
     * _modelLevel is the cached value of the types level.
     */
    protected _modelLevel;
    /**
     * _collectionName
     * _collectionName is the cached value of the collection name.
     */
    protected _collectionName: string;

    /**
     * _changeCollectionName
     * _changeCollectionName is the cached value of the change collection name.
     */
    protected _changeCollectionName: string;

    /**
     * Cached value of the static collection name.
     */
    protected _staticCollectionName: string;

    /**
     * history metadata collection name.
     */
    protected _historyMetadataCollectionName: string;

    /**
     * collection
     * collection is the collection data for this model type
     * will be stored in. This field is set in the constructor.
     */
    protected _collection: Collection<any>;

    /**
     * _changeCollection
     * _changeCollection is the cached value of the change collection.
     */
    protected _changeCollection: Collection<any>;

    /**
     * Cached value of the static collection.
     */
    protected _staticCollection: Collection<any>;

    /**
     * Cached value of the history metadata collection.
     */
    protected _historyMetadataCollection: Collection<any>;


    /**
     * constructor
     * constructor creates a new collection instance. Also
     * stores the model type and the database instance.
     */
    constructor( protected type: typeof AbstractModel, protected database: Database ) {
        this._modelLevel = this.type.getModelLevel();
        this._collectionName = this.type.getRootModel().name;
        this._changeCollectionName = `${this._collectionName}.changes`;
        this._staticCollectionName = `${this._collectionName}.static`;
        this._historyMetadataCollectionName = `${this._collectionName}.history_metadata`;
        this._collection = this.database.collection( this._collectionName );
        this._changeCollection = this.database.collection( this._changeCollectionName );
        this._staticCollection = this.database.collection( this._staticCollectionName );
        if ( this.type.hasHistoryMetaInfo()) {
            this._historyMetadataCollection = this.database.collection( this._historyMetadataCollectionName );
        }
    }

    /**
     * collection
     * collection is the collection data for this model type
     * will be stored in. This field is set in the constructor.
     */
    public get collection(): Collection<any> {
        return this._collection;
    }

    /**
     * changeCollection
     * changeCollection is the collection changes for this model type
     * will be stored in. This field is set in the constructor.
     */
    public get changeCollection(): Collection<any> {
        return this._changeCollection;
    }

    /**
     * Returns the static collection for this model.
     * This is the collection where static data for the model
     * would be stored in.
     */
    public get staticCollection(): Collection<any> {
        return this._staticCollection;
    }

    /**
     * Returns the history metadata collection for this model.
     * This is the collection where history metadata for the model
     * would be stored in.
     */
    public get historyMetadataCollection(): Collection<any> {
        return this._historyMetadataCollection;
    }

    /**
     * modelLevel
     * modelLevel is the number of valid parent models of current model.
     * This is used to identify the amount of detail the model contains.
     *
     * Example:
     * A model with level 1 is a base model ( extends an abstract model )
     * A model with level 2 contains the base model plus additional info.
     *
     * When inserting a model, the current level should be stored as `modelLevel`.
     * When querying, any level >= to current level can be used to get data.
     *
     * FIXME: higher level documents will contain fields not available on current level.
     */
    public get modelLevel(): number {
        return this._modelLevel;
    }

    /**
     * reload
     * This function reloads the collection from the storage
     * to in-memory cache. Observers to the collection
     * will have the new emition with the collection changes.
     */
    public reload() {
        this.collection.reload();
    }

    /**
     * reloadStatic
     * This function reloads the staticCollection from the storage
     * to in-memory cache. Observers to the staticCollection
     * will have the new emition with the staticCollection changes.
     */
    public reloadStatic() {
        this.staticCollection.reload();
    }

    /**
     * findStatic
     * findStatic subscribes to matching documents in the static collection.
     * It will emit all matching documents when static data changes.
     */
    public findStatic( selector?: Selector, options?: FindOptions ): Observable<any[]> {
        return this.staticCollection.find( selector, options );
    }

    /**
     * findRawModel
     * findRawModel subscribes to matching documents in the collection.
     * It will emit all matching documents when it changes (without static data).
     */
    public findRawModel( selector?: Selector, options?: FindOptions ): Observable<any[]> {
        return this.collection.find( this.getSelectorWithLevel( selector ), options ).pipe(
            map( docs => docs.map( doc => this.removeMetadataFields( doc ))),
            switchMap( docs => this.applyUnsavedMany( docs )),
        );
    }

    /**
     * findRaw
     * findRaw subscribes to matching documents in the collection.
     * It will emit all matching documents when it changes.
     */
    public findRaw( selector?: Selector, options?: FindOptions ): Observable<any[]> {
        return this.findRawModel( selector, options ).pipe(
            switchMap( docs => this.mergeStaticMany( docs )),
        );
    }

    /**
     * find
     * find subscribes to matching documents in the collection.
     * It will emit all matching documents when it changes.
     *
     * TODO: listen to changes to avoid unnecessary deserialization
     */
    public find( selector?: Selector, options?: FindOptions ): Observable<any[]> {
        return this.findRaw( selector, options ).pipe(
            switchMap( docs => this.deserializeMany( docs )),
        );
    }

    /**
     * findOneStatic
     * findOneStatic subscribes to the first matching document in the static collection.
     * It will emit the matching document when static data changes.
     */
    public findOneStatic( selector?: Selector, options?: FindOptions ): Observable<any> {
        return this.staticCollection.findOne( selector, options );
    }

    /**
     * findOneHistoryMetadata
     * findOneHistoryMetadata subscribes to matching document in the history metadata collection.
     * It will emit the matching document when history metadata changes.
     */
    public findOneHistoryMetadata( selector?: Selector, options?: FindOptions ): Observable<any> {
        return this.historyMetadataCollection.findOne( selector, options );
    }

    /**
     * findOneRawModel
     * findOneRawModel subscribes to the first matching document in the collection.
     * It will emit the matching document when it changes (without static data).
     */
    public findOneRawModel( selector?: Selector, options?: FindOptions ): Observable<any> {
        return this.collection.findOne( this.getSelectorWithLevel( selector ), options ).pipe(
            map( doc => this.removeMetadataFields( doc )),
            switchMap( doc => this.applyUnsavedOne( doc )),
        );
    }

    /**
     * findOneRaw
     * findOneRaw subscribes to the first matching document in the collection.
     * It will emit the matching document when it changes.
     */
    public findOneRaw( selector?: Selector, options?: FindOptions ): Observable<any> {
        return this.findOneRawModel( selector, options ).pipe(
            switchMap( doc => this.mergeStaticOne( doc )),
        );
    }

    /**
     * findOne
     * find subscribes to the first matching document in the collection.
     * It will emit the matching document when it changes.
     *
     * TODO: listen to changes to avoid unnecessary deserialization
     */
    public findOne( selector?: Selector, options?: FindOptions ): Observable<any> {
        return this.findOneRaw( selector, options ).pipe(
            switchMap( doc => this.deserializeOne( doc )),
        );
    }

    /**
     * Finds changes matching the given selector from the models change collection.
     * @param selector - selection criteria
     * @return observable which continuously emits changes matching the selector
     */
    public findChanges( selector: Selector ): Observable<any[]> {
        return this.changeCollection.find( selector );
    }

    /**
     * watch
     * watch will emit rxdata collection changes for the given selector
     */
    public watch( selector?: Selector, options?: FindOptions ): Observable<DocumentChange<any>> {
        return this.collection.watch( this.getSelectorWithLevel( selector )).pipe(
            map( change => ({ ...change, docs: change.docs.map( doc => this.removeMetadataFields( doc )) })),
        );
    }

    /**
     * insert
     * insert inserts a new document into the collection.
     */
    public async insert( docOrDocs: any ): Promise<void> {
        const docsWithLevel = this.getDocumentWithLevel( docOrDocs );
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.collection.name}.insert`,
                JSON.stringify({ docs: docsWithLevel }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_ADDED,
            collection: this.collection.name,
            docs: docsWithLevel,
        });
        await this.collection.insert( docsWithLevel );
    }

    /**
     * update
     * update modifies all matching documents in the collection.
     */
    public async update( selector?: Selector, modifier?: Modifier ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.collection.name}.update`,
                JSON.stringify({ selector, modifier }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_UPDATED,
            collection: this.collection.name,
            selector, modifier,
        });
        await this.collection.update( selector, modifier );
    }

    /**
     * remove
     * remove removes all matching documents in the collection.
     */
    public async remove( selector?: Selector ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.collection.name}.remove`,
                JSON.stringify({ selector }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_REMOVED,
            collection: this.collection.name,
            selector,
        });
        await this.collection.remove( selector );
    }

    /**
     * insert
     * insert inserts a new document into the static collection.
     */
    public async insertStatic( docOrDocs: any ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.staticCollection.name}.insert`,
                JSON.stringify({ docs: docOrDocs }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_STATIC_ADDED,
            collection: this.staticCollection.name,
            docs: docOrDocs,
        });
        await this.staticCollection.insert(  docOrDocs );
    }

    /**
     * update
     * update modifies all matching documents in the static collection.
     */
    public async updateStatic( selector?: Selector, modifier?: Modifier ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.staticCollection.name}.update`,
                JSON.stringify({ selector, modifier }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_STATIC_UPDATED,
            collection: this.staticCollection.name,
            selector, modifier,
        });
        await this.staticCollection.update( selector, modifier );
    }

    /**
     * remove
     * remove removes all matching documents in the static collection.
     */
    public async removeStatic( selector?: Selector ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.staticCollection.name}.remove`,
                JSON.stringify({ selector }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_STATIC_REMOVED,
            collection: this.staticCollection.name,
            selector,
        });
        await this.staticCollection.remove( selector );
    }

    /**
     * insert
     * insert inserts a new document into the history metadata collection.
     */
    public async insertHistoryMetadata( docOrDocs: any ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.historyMetadataCollection.name}.insert`,
                JSON.stringify({ docs: docOrDocs }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_HISTORY_METADATA_ADDED,
            collection: this.historyMetadataCollection.name,
            docs: docOrDocs,
        });
        await this.historyMetadataCollection.insert(  docOrDocs );
    }

    /**
     * update
     * update modifies all matching documents in the history metadata collection.
     */
    public async updateHistoryMetadata( selector?: Selector, modifier?: Modifier ): Promise<void> {
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.historyMetadataCollection.name}.update`,
                JSON.stringify({ selector, modifier }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_HISTORY_METADATA_UPDATED,
            collection: this.historyMetadataCollection.name,
            selector, modifier,
        });
        await this.historyMetadataCollection.update( selector, modifier );
    }

    /**
     * insertChange
     * insertChange inserts documents into the changes collection.
     */
    public async insertChange( docOrDocs: any ): Promise<void> {
        const docs = Array.isArray( docOrDocs ) ? docOrDocs : [ docOrDocs ];
        // FIXME: update the locator instead of waiting for the write to complete
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.changeCollection.name}.insert`,
                JSON.stringify({ docs: docs }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_CHANGE_ADDED,
            collection: this.changeCollection.name,
            docs: docs,
        });
        await this.changeCollection.insert( docs );
    }

    /**
     * Removes matching changes from the change collection and updates listening subscribers.
     */
    public async removeChanges( selector?: Selector ): Promise<void> {
        const toRemove = await this.changeCollection.find( selector )
            .pipe( take( 1 ))
            .toPromise();
        const modelIds = new Set<string>();
        for ( const change of toRemove ) {
            modelIds.add( change.modelId );
        }
        if ( Flags.get( 'DEBUG_STORAGE_CHANGES' )) {
            // tslint:disable-next-line:no-console
            console.log(
                `Storage: ${this.changeCollection.name}.remove`,
                JSON.stringify({ docs: toRemove }, null, 2 ),
            );
        }
        EventCollector.log({
            message: EventIdentifier.STORAGE_CHANGE_REMOVED,
            collection: this.changeCollection.name,
            docs: toRemove,
        });
        await this.changeCollection.remove( selector );
    }

    /**
     * getDocumentWithLevel
     * getDocumentWithLevel adds the modelLevel field on document ( or documents )
     */
    protected getDocumentWithLevel( docOrDocs: any ): any {
        if ( Array.isArray( docOrDocs )) {
            return docOrDocs.map( doc => this.getDocumentWithLevel( doc ));
        }
        if ( !docOrDocs ) {
            return null;
        }
        return Object.assign({}, docOrDocs, { modelLevel: this.modelLevel });
    }

    /**
     * getSelectorWithLevel
     * getSelectorWithLevel adds the modelLevel filter to the selector
     */
    protected getSelectorWithLevel( selector: Selector ): Selector {
        const selectLevel = { modelLevel: { $gte: this.modelLevel }};
        if ( !selector || !Object.keys( selector ).length ) {
            return selectLevel;
        }
        return { $and: [ selector, selectLevel ]};
    }

    /**
     * Returns a selector which will select all documents matching
     * the ids of documents in the given array.
     * @param docs - documents array
     * @return selector
     */
    protected getSelectorForMultipleDocs( docs: any[]): Selector {
        if ( docs && docs.length > 0  ) {
            const idList = docs.map( doc => doc.id );
            return { id: { $in: idList }};
        }
        return {};
    }

    /**
     * removeMetadataFields
     * removeMetadataFields deletes metadata fields stored with documents.
     */
    protected removeMetadataFields( doc: any ) {
        if ( !doc ) {
            return doc;
        }
        const { modelLevel, ...withoutMeta } = doc;
        return withoutMeta;
    }

    /**
     * deserializeOne
     * deserializeOne deserializes a single document to a model.
     */
    protected deserializeOne( doc: any ): Observable<any> {
        if ( !doc || !isObject( doc )) {
            return of( doc );
        }
        const decoder = new Deserializer( doc );
        return decoder.structure( this.type ).build();
    }

    /**
     * deserializeMany
     * deserializeMany deserializes an array of documents to models.
     */
    protected deserializeMany( docs: any[]): Observable<any[]> {
        if ( !docs.length ) {
            return of([]);
        }
        const observables = docs.map( doc => this.deserializeOne( doc ));
        return forkJoin( observables );
    }

    /**
     * applyUnsavedOne
     * applyUnsavedOne applies unsaved changes to a single document.
     *
     * FIXME temporary implementation
     */
    protected applyUnsavedOne( doc: any ): Observable<any> {
        if ( !doc ) {
            return of( doc );
        }
        const selector = { modelId: doc.id };
        const selectUnsaved = { $and: [ selector, { status: 'unsaved' }]};
        return this.changeCollection.find( selectUnsaved )
            .pipe(
                take( 1 ),
                map(( changes: IUnsavedModelChange[]) => {
                    if ( changes.length ) {
                        // FIXME:
                        // Ideally doc should be cloned but here we directly modifiy the doc to improve preformance.
                        // doc = cloneDeep( doc );

                        // sort changes by clientTime field in descending order
                        changes.sort(( a, b ) => a.clientTime - b.clientTime );
                        for ( const data of changes ) {
                            modify( doc, data.modifier );
                        }
                    }
                    return doc;
                }),
            );
    }

    /**
     * applyUnsavedMany
     * applyUnsavedMany applies unsaved changes to multiple documents.
     *
     * FIXME temporary implementation
     */
    protected applyUnsavedMany( docs: any[]): Observable<any[]> {
        if ( !docs.length ) {
            return of([]);
        }
        const observables = docs.map( doc => this.applyUnsavedOne( doc ));
        return combineLatest( observables );
    }


    /**
     * Merges static data into multiple documents.
     * @param docs - documents to merge static data into
     * @return Observable which emits an array of documents with static data merged
     */
    protected mergeStaticMany( docs: any[]): Observable<any[]> {
        if ( !docs.length ) {
            return of([]);
        }
        return this.staticCollection.find( this.getSelectorForMultipleDocs( docs )).pipe(
            map( sDocs => {
                sDocs.forEach( sDoc => {
                    const index = findIndex( docs, { id: sDoc.id });
                    const doc = docs[index];
                    docs[index] = merge( Object.assign({}, doc ), sDoc );
                });
                return docs;
            }),
        );
    }

    /**
     * Merges static data into a single document
     * @param doc - document to merge static data into
     * @return Observable which emits the merged document
     */
    protected mergeStaticOne( doc: any ): Observable<any> {
        if ( !doc ) {
            return of( doc );
        }
        return this.staticCollection.find({ id: doc.id }).pipe(
            map( sDoc => {
                if ( sDoc && sDoc.length > 0 ) {
                    return merge( Object.assign({}, doc ), sDoc[0]);
                }
                return doc;
            }),
        );
    }
}

type StorageAddedEventIdentifier = EventIdentifier.STORAGE_ADDED
            | EventIdentifier.STORAGE_STATIC_ADDED
            | EventIdentifier.STORAGE_HISTORY_METADATA_ADDED
            | EventIdentifier.STORAGE_CHANGE_ADDED;

type StorageUpdatedEventIdentifier = EventIdentifier.STORAGE_UPDATED
| EventIdentifier.STORAGE_STATIC_UPDATED
| EventIdentifier.STORAGE_HISTORY_METADATA_UPDATED;

type StorageRemovedEventIdentifier = EventIdentifier.STORAGE_REMOVED
| EventIdentifier.STORAGE_STATIC_REMOVED
| EventIdentifier.STORAGE_CHANGE_REMOVED;

export interface IStorageAddEventLog extends IEventLog {
    message: StorageAddedEventIdentifier;
    collection: string;
    docs: any[];
}

export interface IStorageRemovedEventLog extends IEventLog {
    message: StorageRemovedEventIdentifier;
    collection: string;
    selector?: any;
    docs?: any;
}

export interface IStorageUpdatedEventLog extends IEventLog {
    message: StorageUpdatedEventIdentifier;
    collection: string;
    selector: any;
    modifier: any;
}

export type IStorageEventLog = IStorageAddEventLog | IStorageRemovedEventLog | IStorageUpdatedEventLog;

export const storageEventLogIds = [
    EventIdentifier.STORAGE_ADDED,
    EventIdentifier.STORAGE_STATIC_ADDED,
    EventIdentifier.STORAGE_HISTORY_METADATA_ADDED,
    EventIdentifier.STORAGE_CHANGE_ADDED,
    EventIdentifier.STORAGE_UPDATED,
    EventIdentifier.STORAGE_STATIC_UPDATED,
    EventIdentifier.STORAGE_HISTORY_METADATA_UPDATED,
    EventIdentifier.STORAGE_REMOVED,
    EventIdentifier.STORAGE_STATIC_REMOVED,
    EventIdentifier.STORAGE_CHANGE_REMOVED,
];
