import { Observable, of, forkJoin } from 'rxjs';
import { AbstractModel, ExecutionStep, CommandInterfaces } from 'flux-core';
import { DataStore } from '../../model/data-store.svc';
import { ignoreElements } from 'rxjs/operators';

export class StoreAfterReceiveExecutionStep extends ExecutionStep {
    /**
     * Indicates the interfaces a command must implement for this
     * ExecutionStep step to process during the command execution.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IHttpCommand', 'INeutrinoRestCommand' ];
    }

    /**
     * dataStore
     * dataStore is the injected DataStore service.
     */
    protected dataStore: DataStore;

    /**
     * Runs the storeAfterReceive method and returns an empty observable.
     */
    public process() {
        this.injectServices();
        const commandClass = Object.getPrototypeOf( this.command ).constructor;
        const resultType = commandClass.resultType;
        const resultData = this.command.resultData;
        if ( !this.isValidResultType( resultType )) {
            return of();
        }
        if ( !resultData ) {
            return of();
        }
        if ( !this.isValidResultData( resultData )) {
            // Result type is valid so there should be a valid object for resultData
            throw new Error( 'Unexpected error: server invalid server result data' );
        }
        return this.storeResult( resultData, resultType );
    }

    /**
     * storeResult stores data as described in resultType. Supports both models
     * and templates with model data. Only stores data if it's specified in the
     * resultType.
     */
    protected storeResult( resultData: any, resultType: any ): Observable<any> {
        const modelsMap = new Map<typeof AbstractModel, any[]>();
        const collected = this.collectModelData( resultData, resultType );
        collected.forEach(({ type, data }) => {
            if ( !modelsMap.has( type )) {
                modelsMap.set( type, [ data ]);
            } else {
                modelsMap.get( type ).push( data );
            }
        });
        const observables = Array.from( modelsMap.entries())
            .map(([ type, docs ]) => this.dataStore.insert( type, docs ));
        return forkJoin( observables ).pipe( ignoreElements());
    }

    /**
     * collectModelData collects storable models from result data. Returns an array
     * of objects with type and data: { type: resultType, data: resultData }
     */
    protected collectModelData( resultData: any, resultType: any ): any[] {
        const collected = [];
        if ( Array.isArray( resultData )) {
            resultData.forEach( resultDataItem => {
                collected.push( ...this.collectModelData( resultDataItem, resultType ));
            });
        } else if ( resultType && !( resultType.prototype instanceof AbstractModel )) {
            Object.keys( resultType ).forEach( key => {
                collected.push( ...this.collectModelData( resultData[key], resultType[key]));
            });
        } else if ( resultType && resultData ) {
            collected.push({ type: resultType, data: resultData });
        }
        return collected;
    }

    /**
     * Checks whether the result type can be used to store data.
     * The result type should a model for it to be stored.
     *
     * @reference http://stackoverflow.com/a/18939541
     */
    protected isValidResultType( resultType: any ): boolean {
        if ( !resultType || !( resultType instanceof Object )) {
            return false;
        }
        // It should use a model class which extends AbstractModel,
        // Not the AbstractModel class itself.
        if ( resultType === AbstractModel ) {
            return false;
        }
        // if resultType does not extend the AbstractModel class
        // test whether it is a valid template result type.
        if ( !( resultType.prototype instanceof AbstractModel )) {
            return this.isTemplateResultType( resultType );
        }
        return true;
    }

    /**
     * Checks whether the result type is a template with storable result fields
     */
    protected isTemplateResultType( resultType: any ): boolean {
        if ( !( resultType instanceof Object )) {
            return false;
        }
        const keys = Object.keys( resultType );
        for ( let i = 0; i < keys.length; ++i ) {
            const key = keys[i];
            if ( this.isValidResultType( resultType[key])) {
                return true;
            }
        }
        return false;
    }

    /**
     * Checks whether the result data can be stored. The result data
     * can be a plain javascript object or an array of objects.
     */
    protected isValidResultData( resultData: any ): boolean {
        if ( !resultData ) {
            return false;
        }
        /**
         * If the resultData is an array, ach item in the array should be
         * a valid result data type. Array elements should not be arrays.
         */
        if ( Array.isArray( resultData )) {
            return resultData.reduce(( result, data ) =>
                result && !Array.isArray( data ) && this.isValidResultData( data ), true );
        }
        return resultData instanceof Object;
    }

    /**
     * injectServices injects services required by this execution step.
     */
    protected injectServices(): void {
        this.dataStore = this.injector.get( DataStore );
    }
}

Object.defineProperty( StoreAfterReceiveExecutionStep, 'name', {
    value: 'StoreAfterReceiveExecutionStep',
});
