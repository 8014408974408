// tslint:disable:directive-selector
/**
 * Tooltip Tour Directive for anchor element
 * This custom directive is to be used when an HTML element
 * is supposed to anchor a tooltip corresponding to a step
 * in a tooltip tour.
 * a unique step id is required for each element.
 * @author kulathilake
 * @since Jun/2022
 */

import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TooltipTourService } from './tooltip-tour.svc';

@Directive({
    selector: '[tooltip-tour-step]',
})
export class TooltipTourDirective implements OnInit, OnDestroy {
    @Input()
    'tooltip-tour-step' = '';

    constructor( private el: ElementRef<HTMLElement>, private tourSvc: TooltipTourService ) {
    }

    ngOnInit(): void {
        if ( this.getIds().length ) {
            this.getIds().forEach( id => {
                this.tourSvc.registerElement( this.el, id );
            });
        }
    }

    ngOnDestroy(): void {
        if ( this.getIds().length ) {
            this.getIds().forEach( id => {
                this.tourSvc.unregisterElement( id );
            });
        }
    }


    /**
     * parses the directive value to get the IDs
     * of tooltip steps to attached to the component.
     * @returns
     */
    private getIds (): string[] {
        if ( this['tooltip-tour-step']) {
            return this['tooltip-tour-step'].split( ',' );
        } else {
            return [];
        }
    }
}
