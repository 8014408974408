import { Observable } from 'rxjs';
import { ExecutionStep } from './execution-step';
import { CommandInterfaces } from '../abstract.cmd';
import { CommandError } from '../error/command-error';

/**
 * RunNetworkOfflineStep
 * This class is an implementation of the {@link ExecutionStep} which handles
 * the executeNetworkOffline method of the command. For more details see {@link AbstractCommand}
 *
 * To see how this is used, see the {@link CommandExecutor}.prepareSequence method.
 *
 * @author  vinoch
 * @since   2021-06-01
 */
export class RunNetworkOfflineStep extends ExecutionStep {
    /**
     * Indicates the interfaces a command must implement for this
     * ExecutionStep step to process during the command execution.
     */
    public static get relatedInterfaces(): Array<CommandInterfaces> {
        return [ 'IMessageCommand', 'IHttpCommand', 'IGraphqlRestCommand', 'INeutrinoRestCommand', 'ICollabCommand' ];
    }

    /**
     * When execute network offline completes the result data must be emited if
     * it has changed.
     */
    public get emitResult(): boolean {
        return true;
    }

    /**
     * Runs the executeNetworkOffline method on the command and throws an exception in
     * case of a false is returned.
     */
    public process() {
        const result = this.command.executeNetworkOffline();
        if ( result instanceof Observable ) {
            return result;
        }
        if ( !result ) {
            throw new CommandError( 'Execution of command was cancelled from within ' +
            'the execute network offline method' );
        }
    }
}

Object.defineProperty( RunNetworkOfflineStep, 'name', {
    value: 'RunNetworkOfflineStep',
});

