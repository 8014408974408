import { Component, Input } from '@angular/core';

enum LoaderStyles {
    Spinner = 'spinner',
    Strip = 'strip',
    StripWithSpinner = 'strip-with-spinner',
}

@Component({
    templateUrl: './loader.cmp.html',
    selector: 'content-loader',
    styleUrls: [ './loader.scss' ],
})
export class Loader {

    /**
     * Define the type of loader
     */
    @Input() type: LoaderStyles;

    /**
     * Width of component in px or %
     */
    @Input() width: string;

    /**
     * Height of component in px or %
     */
    @Input() height: string;
}
