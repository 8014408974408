import { StateService, Rectangle } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { AbstractCoordinate } from './abstract-coordinate';
import { ICoordinateConverter } from './coordinate-converter.i';
import { Matrix2D } from '@creately/createjs-module';

/**
 * This service helps convert coordinates from diagram coordinate space
 * into coordinates of the viewport. It considers the diagram
 * zoom and diagram pan when calculating coordinates.
 *
 * @author  Ramishka
 * @since   2017-10-15
 */
@Injectable()
export class DiagramToViewportCoordinate extends AbstractCoordinate implements ICoordinateConverter {

    /**
     * Constructor. Subscribes to zoom level changes from the
     * state service.
     * @param state zoom diagram state service
     */
    constructor( @Inject( StateService ) protected state: StateService<any, any> ) {
        super( state );
    }

    /**
     * Converts an x coordinate of diagram space into viewport coordinate space
     * @param originalX value to convert
     */
    public x( originalX: number ): number {
        return ( originalX * this.zoomLevel ) + this.panX;
    }

    /**
     * Converts a y coordinate of diagram space into viewport coordinate space
     * @param originalY value to convert
     */
    public y( originalY: number ): number {
        return ( originalY * this.zoomLevel ) + this.panY;
    }

    /**
     * Converts an a width value of diagram space into viewport coordinate space
     * @param originalWidth value to convert
     */
    public width( originalWidth: number ): number {
        return originalWidth * this.zoomLevel;
    }

    /**
     * Converts an a height value of diagram space into viewport coordinate space
     * @param originalHeight value to convert
     */
    public height( originalHeight: number ): number {
        return originalHeight * this.zoomLevel;
    }

    /**
     * Converts a vertical scale value on diagram space into viewport coordinate space
     * @param originalScaleY value to convert
     */
    public scale( originalScale: number ): number {
        return originalScale * this.zoomLevel;
    }

    /**
     * Converts the diagram rectangle bounds to viewport bounds and
     * returns a new instance.
     */
    public rect( bounds: Rectangle ): Rectangle {
        return new Rectangle( this.x( bounds.x ), this.y( bounds.y ),
                         this.width( bounds.width ), this.height( bounds.height ));
    }

    /**
     * Converts a matrix from one coordinate space to another
     * @param matrix - matrix that will be converted
     */
    public matrix( matrix: Matrix2D ): Matrix2D {
        const clonedMatrix: Matrix2D = matrix.clone();
        clonedMatrix.scale( this.zoomLevel, this.zoomLevel );
        clonedMatrix.tx = this.x( matrix.tx );
        clonedMatrix.ty = this.y( matrix.ty );
        return clonedMatrix;
    }
}

