import { StateService, CommandInterfaces, Command, AppConfig } from 'flux-core';
import { Injectable } from '@angular/core';
import { InterfaceControlState } from '../../base-states';
import { AbstractMessageCommand } from 'flux-connection';

/**
 * This is a command used to send all shared states to Neutrino, so that they can be collaborated
 * with any current active clients. This ensures that the current user's states are
 * transfered across to Neutrino and other clients.
 *
 * This will usually be fired, when a diagram subscription is successfully created during
 * the application initialization sequence.
 */
@Injectable()
@Command()
export class BeginStateSync extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            status: true, // status of the initialization sequence
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
            return [ 'IStateChangeCommand' ];
        }
        return [ 'IStateChangeCommand', 'IMessageCommand' ];
    }

    constructor( protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    public get states(): { [ stateId: string ]: any } {
        return this.data;
    }

    public execute (): boolean {
        const currentAction = this.data.status.input.action;
        const diagramId = this.data.status.input.resourceId;
        this.data = { Realtime: { diagramId, action: '' }};
        if ( currentAction === InterfaceControlState.Edit ) {
            this.data.Realtime.action = 'ACTIVITIES.EDITING';
        }
        if ( currentAction === InterfaceControlState.View ) {
            this.data.Realtime.action = 'ACTIVITIES.VIEWING';
        }

        // FIXME: This is going to set existing states again. Which will case changes to trigger.
        // Here we only need to send the states.
        this.state.getShared().forEach( state => this.data[state] = this.state.get( state ));

        return true;
    }
}

Object.defineProperty( BeginStateSync, 'name', {
    value: 'BeginStateSync',
});
