// tslint:disable: max-file-line-count
import { ApplyModifierDocument } from './apply-modifier-document.cmd';
import { RemoveEdata } from './remove-edata.cmd';
import { UpdateShapeDataDefs } from './update-shape-data-defs.cmd';
import { ImportFile } from './import-file.cmd';
import { CopyShapeData } from './copy-shape-data.cmd';
import { RenameShape } from './rename-shape.cmd';
import { ChangeShapeDataItems } from './change-shape-data-items.cmd';
import { ChangeContainerData } from './change-container-data.cmd';
import { ChangeSnapState } from './change-snap-state.cmd';
import { CopyShapeLink } from './copy-shape-link.cmd';
import { EditShapelink } from './edit-shapelink.cmd';
import { ApplyShapelink } from './apply-shaplink.cmd';
import { UpdateTextBounds } from './update-text-bounds.cmd';
import { AutocorrectTextColor } from './autocorrect-text-color.cmd';
import { ChangeImageGallery } from './change-image-gallery.cmd';
import { PositionText } from './position-text.cmd';
import { ApplyTextEditorStyles } from './apply-text-editor-styles.cmd';
import { KeyboardSelectionInteraction } from './keyboard-selection-interaction.cmd';
import { ApplyText } from './apply-text.cmd';
import { EditText } from './edit-text.cmd';
import { AbstractDiagramCommandEvent, CommandMapper, EventSource, Random } from 'flux-core';
import { AddDiagramShape } from './add-diagram-shape.cmd';
import { ResetDiagramPreview } from './reset-diagram-preview.cmd';
import { ApplyShapeStyles } from './apply-shape-styles.cmd';
import { RemoveShape } from './remove-shape-cmd';
import { CopyShapes } from './copy-shapes.cmd';
import { ApplyTextShapeStyles } from './apply-text-styles.cmd';
import { PasteShapes } from './paste-shapes.cmd';
import { AddConnector } from './add-connector.cmd';
import { SelectShapes } from '../../../base/diagram/command/select-shapes.cmd';
import { ConvertViewportForPoints } from '../../selection/command/convert-viewport-for-points.cmd';
import { DuplicateShapes } from './duplicate-shapes.cmd';
import { CloneShapes } from './clone-shapes.cmd';
import { EditConnectorPoint } from '../../selection/command/edit-connector-point.cmd';
import { AddConnectorBumps } from '../../selection/command/add-connector-bumps.cmd';
import { GetRemovedShapeIds } from './get-removed-shape-ids.cmd';
import { GetConnectedConnectors } from './get-connected-connectors.cmd';
import { ChangeDrawStyle } from './change-draw-style.cmd';
import { UpdateDiagramInfo } from './update-diagram-info.cmd';
import { ChangeArrowHeads } from './change-arrow-heads.cmd';
import { RenameDiagram } from './rename-diagram.cmd';
import { StartMovingConnectorEnd } from '../../selection/command/start-moving-connector-end.cmd';
import { StartMovingShape } from '../../selection/command/start-moving-shape.cmd';
import { SetConnectorConnection } from './set-connector-connection.cmd';
import { UndoAction } from '../../../base/diagram/command/undo-action.cmd';
import { RedoAction } from '../../../base/diagram/command/redo-action.cmd';
import { UpdateDataItems } from './update-data-items.cmd';
import { DoShapeAction } from './do-shape-action.cmd';
import { BringToFront } from './bring-to-front.cmd';
import { SendToBack } from './send-to-back.cmd';
import { SendBackward } from './send-backward.cmd';
import { BringForward } from './bring-forward.cmd';
import { GetTemplate } from '../templates/get-template.cmd';
import { ImportImage } from './import-image.cmd';
import { CompressShapeImage } from './compress-shape-image.cmd';
import { AddImageShape } from './add-image-shape.cmd';
import { UploadImage } from './upload-image.cmd';
import { GetSelectedShapeIndexes } from '../../selection/command/get-selected-shape-indexes.cmd';
import { ToggleConnectorLineBumps } from '../../selection/command/toggle-connector-line-bumps.cmd';
import { ToggleConnectorVisibility } from '../../selection/command/toggle-connector-visibility.cmd';
import { AddShapeImage } from './add-shape-image.cmd';
import { ShowUploadWindow } from './show-upload-window.cmd';
import { PlusCreateStateSet } from '../../selection/command/plus-create-state.cmd';
import { PasteItems } from './paste-items.cmd';
import { EditHyperlink } from './edit-hyperlink.cmd';
import { GetRecentDiagrams } from 'flux-diagram';
import { AppStatusIndicator } from './app-status-indicator.cmd';
import { GetGoogleImages } from './get-google-images.cmd';
import { FetchUrl } from './fetch-url.cmd';
import { DocumentChange } from './document-change.cmd';
import { FetchDiagram } from '../../../base/diagram/command/fetch-diagram.cmd';
import { GetGoogleAccessToken } from './get-google-access-token.cmd';
import { ToggleShapeLock } from '../../selection/command/toggle-shape-lock.cmd';
import { CheckPermissions } from './check-permissions.cmd';
import { PlanPermission } from 'flux-definition';
import { ExecuteShapeLogicMethod } from './execute-shape-method.cmd';
import { UpdateLibraries } from './update-libraries.cmd';
import { UpdateRecentShapes } from './update-recent-shapes.cmd';
import { ChangeAttachments } from './change-attachments.cmd';
import { SyncEDataChanges } from './sync-edata-changes.cmd';
import { SwapShape } from './swap-shape.cmd';
import { StartShapeVoting } from './start-shape-voting.cmd';
import { AddEDataModel } from './add-edata-model.cmd';
import { AddEntityToShape } from './add-entity-to-shape';
import { StartEDataSubscription } from '../../../base/edata/command/start-edata-subscription.cmd';
import { FilterLockedOrUnlockedShapes } from './filter-locked-or-unlocked-shapes.cmd';
import { AddEDataRefToShape } from './add-edata-ref-to-shape';
import { PasteShapesAsNew } from './paste-shapes-as-new.cmd';
import { UpdateTask } from '../../../base/task/commands/update-task.cmd';
import { RemoveShapeTasks } from '../../../base/task/commands/remove-shape-task.cmd';
import { AddTask } from '../../../base/task/commands/add-task.cmd';
import { RemoveTask } from '../../../base/task/commands/remove-task.cmd';
import { AddFreehandShape } from './add-freehand-shape.cmd';
import { SwitchShape } from './switch-shape.cmd';
import { AddPredefinedQueryContainer } from './../../../base/diagram/command/pre-def-queries-container-add.cmd';
import { UpdateDisplayRules } from './update-display-rules.cmd';
import { DiagramContainersReLayout } from './diagram-containers-re-layout.cmd';
import { MigrateToNewRegionType } from './migrate-region-datatypes.cmd';
import { MigrateDataDefs } from './migrate-data-defs.cmd';
import { CheckPremiumShapeLimitations } from './check-premium-shape-limitations.cmd';
import { GetEntityHistory } from './get-entity-history.cmd';
import { RemoveShapeFromEdata } from '../../../base/debugger/command/remove-shape-from-edata.cmd';
import { CheckInteractionState } from './check-interaction-state.cmd';
import { RemoveDiagramEntityListReferences } from './remove-entityList.cmd';
import { ApplyTextBounds } from './apply-text-bounds.cmd';
import { LockGluepoints } from './lock-gluepoints.cmd';
import { KeyboardPlusCreateInteraction } from './keyboard-plus-create-interaction';
import { UploadScaledImage } from './upload-scaled-image.cmd';
import { MigrateTasks } from './migrate-tasks.cmd';
import { MarkAsTemplateShape } from './mark-as-template-shape.cmd';
import { UploadImages } from './upload-images.cmd';
import { ConvertLucidSVG } from './convert-lucid-svg.cmd';
import { ReplaceTextShape } from './replace-text-shape.cmd';
import { GetTemplateContext } from '../templates/get-template-context.cmd';
import { UpdateTemplateContext } from '../templates/update-template-context.cmd';
import { LayoutShapes } from './layout-shapes.cmd';
import { SetTemplateDef } from '../templates/set-template-def.cmd';
import { CheckTeamShareLimitation } from './check-team-share-limitation.cmd';
import { AdjustConnector } from './adjust-connector.cmd';
import { GetVizRequestId } from '../templates/get-viz-request-id.cmd';
import { GetVizData } from '../templates/get-viz-data.cmd';
import { CategorizeShapes } from './categorize-shapes.cmd';
import { ModifyTable } from './modify-table.cmd';
import { VizPrompt } from './viz-prompt.cmd';
import { ConvertToObject } from './convert-to-object.cmd';
import { AddEntitiesToShapes } from './add-entities-to-shapes.cmd';
import { ConvertShapesToObjects } from './convert-shapes-to-objects.cmd';
import { OpenConvertToObjectDialog } from '../../selection/command/open-convert-to-object-dialog.cmd';
import { PasteAsItem } from './paste-as-item.cmd';
import { UpdateShapeEditors } from './update-shape-editors';
import { ToggleDataFields } from './toggle-data-fields.cmd';
import { GetShapeContainer } from './get-shape-container.cmd';
import { GetConnectedShapes } from './get-connected-shapes.cmd';
import { GetNearestShape } from './get-nearest-shape.cmd';
import { OpenShapeDataEditor } from './open-shape-data-editor.cmd';
import { RemoveLinkDialog } from './remove-link-input-dialog.cmd';
import { ReplaceLinkDialog } from './replace-link-input-dialog.cmd';
import { UpdatePresentation } from '../../../base/presentation/command/update-presentation.cmd';
import { ExportPresentation } from '../../../base/presentation/command/export-presentation.cmd';
import { ChangeCollabsCursorsVisibility } from './change-collabs-cursors-visibility.cmd';
import { ShapeVoteState } from './shape-vote-state.cmd';
import { ChangeGridGuidesShowSnap } from './change-grid-guides-show-snap.cmd';
import { AddShapeByDefId } from './add-shape-by-defid.cmd';
import { AddTemplate } from './add-template.cmd';
import { UpdateShapeVoting } from './update-shape-voting.cmd';
import { FilterOngoingVotingShapes } from './filter-ongoing-voting-shapes.cmd';
import { CollapseExapndBranch } from './collapse-expand-branch.cmd';
import { RemoveEntityDialog } from './remove-entity-input-dialog.cmd';
import { FilterShapesToAdd } from './filter-shapes-to-add.cmd';
import { FocusOnTemplate } from './focus-on-template.cmd';
import { OpenQuickShapeSearch } from './open-quick-shape-search.cmd';
import { SwitchShapeAny } from './switch-shape-any.cmd';
import { AddEntityToWorkspace } from './add-entity-to-wrokspace.cmd';
import { AddEntityImages } from './add-entity-images.cmd';

/**
 * DiagramCommandEvent
 * ...
 */
// tslint:disable: member-ordering
export class DiagramCommandEvent extends AbstractDiagramCommandEvent {
    /*
     * Creates a new shape and adds it to the diagram.
     *
     *  Input Data: {
     *      clone: boolean,
     *      offset: { x, y },
     *      shapes: {
     *          [shapeId: string]: {
     *              id: string,
     *              defId: string,
     *              version: number,
     *              x: number,
     *              y: number,
     *          }
     *      }
     *  }
     */
    public static addDiagramShape = new DiagramCommandEvent( 'AddDiagramShape' );
    public static addDiagramShapeExternal = new DiagramCommandEvent( 'AddDiagramShape', EventSource.EXTERNAL );
    protected static registerAddDiagramShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addDiagramShape )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.eventData.shapes ?
                                Object.values( progress.eventData.shapes )
                                    .filter(( shape: any ) => !shape.isTemplateShape ).length : 0,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any )
            .add( AddDiagramShape as any )
            .add( ChangeContainerData as any, {
                transform( progress ) {
                    const shapeData = {};
                    let containerData = {};
                    if ( progress.eventData.frameId ) {
                        Object.keys( progress.eventData.shapes ).map( shapeId => {
                            shapeData[shapeId] = {
                                expand: false,
                                action: 'enter',
                                containerId: progress.eventData.frameId,
                                force: false,
                            };
                        });
                        containerData = { [progress.eventData.frameId]: { isContainer: true }};
                    }
                    return { childrenData: shapeData, containerData: containerData };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: Object.keys( progress.eventData.shapes ) };
                },
            })
            .add( FocusOnTemplate as any, {
                transform( progress ) {
                    return { frameId: progress.eventData.frameId, children: Object.keys( progress.eventData.shapes ) };
                },
            });
    }

    // tslint:disable-next-line: max-line-length
    public static removeEntityListReferenceFromShapes = new DiagramCommandEvent( 'removeEntityListReferenceFromShapes' );
    protected static registerRemoveEntityListReferenceFromShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.removeEntityListReferenceFromShapes )
            .add( RemoveDiagramEntityListReferences as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static addPredefinedQueryShapes = new DiagramCommandEvent( 'AddPredefinedQueryShapes' );
    protected static registerAddPredefinedQueryShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addPredefinedQueryShapes )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.eventData.shapes ?
                                Object.values( progress.eventData.shapes )
                                    .filter(( shape: any ) => !shape.isTemplateShape ).length : 0,
                    };
                    return data;
                },
            })
            .add( AddPredefinedQueryContainer as any, {
                transform( progress ) {
                    const data = {
                        shapes: progress.eventData.shapes,
                        containerOptions: progress.eventData.containerOptions,
                    };
                    return data;
                },
            })
            .add( FilterShapesToAdd as any, {
                transform( progress ) {
                    const data = {
                        shapes: progress.eventData.shapes,
                        containerOptions: progress.eventData.containerOptions,
                    };
                    return data;
                },
            })
            .add( AddDiagramShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * Creates a new shape from freehand svg and adds it to the diagram.
     */
    public static addFreehandShape = new DiagramCommandEvent( 'AddFreehandShape' );
    protected static registerAddFreehandShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addFreehandShape )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 1,
                    };
                    return data;
                },
            })
            .add( AddFreehandShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
        });
    }

    public static plusCreateDragStart = new DiagramCommandEvent( 'plusCreateDragStart' );
    protected static registerPlusCreateDragStart( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.plusCreateDragStart )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 2,
                    };
                    return data;
                },
            })
            .add( AddConnector as any, {
                transform( progress ) {
                    const data = progress.resultData[2];
                    const points = progress.eventData?.shapeData?.points;
                    const { connectorToCopyStylesFrom } = progress.eventData;
                    return { id: progress.eventData.shapeData.id, ...data, points, connectorToCopyStylesFrom };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.data[1].id ]};
                },
            })
            .add( StartMovingConnectorEnd as any,  {
                transform( progress ) {
                    return { connectorId: progress.data[1].id };
                },
            });
    }

    public static openEmojiList = new DiagramCommandEvent( 'openEmojiList' );
    protected static registerOpenEmojiList( mapper: CommandMapper ) {}

    public static startMovingShape = new DiagramCommandEvent( 'StartMovingShape' );
    protected static registerStartMovingShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.startMovingShape )
            .add( StartMovingShape as any,  {
                transform( progress ) {
                    return {
                        shapeId: progress.eventData.shapeData.id,
                        center: progress.eventData.shapeData.center,
                    };
                },
            });
    }
    /**
     * Creates a new shape and triggers the move interaction on the shape.
     *
     *  Input Data: {
     *      defId: string, // The defId of the shape to be created
     *      version: number, // The version of the definition of the shape to be created
     *  }
     */
    public static plusCreateConnectAndMove = new DiagramCommandEvent( 'PlusCreateConnectAndMove' );
    protected static registerPlusCreateConnectAndMove( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.plusCreateConnectAndMove )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 2,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any, {
                transform( progress ) {
                    const shapeData = { ...progress.eventData.shapeData };
                    const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                    return data;
                },
            })
            .add( AddDiagramShape as any , {
                transform( progress ) {
                    const shapeData = { ...progress.eventData.shapeData };
                    delete shapeData.points;
                    delete shapeData.format;
                    const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                    if ( progress.eventData.entity ) {
                        data.entity = { ...progress.eventData.entity };
                    }
                    return data;
                },
            })
            .add( AddConnector as any, {
                transform( progress ) {
                    const data = progress.resultData[3];
                    const points = progress?.eventData?.shapeData?.points;
                    const { connectorToCopyStylesFrom } = progress.eventData;
                    return { id: Random.shapeId(), ...data, points, connectorToCopyStylesFrom };
                },
            })
            .add( ApplyTextShapeStyles as any , {
                transform( progress ) {
                    return {
                        shapeIds: [ progress.eventData.shapeData.id ],
                        format: progress.eventData.shapeData.format,
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.eventData.shapeData.id ]};
                },
            })
            .add( PlusCreateStateSet as any , {
                transform( progress ) {
                    return { inProgress: true };
                },
            })
            .add( StartMovingShape as any,  {
                transform( progress ) {
                    return {
                        shapeId: progress.eventData.shapeData.id,
                        center: progress.eventData.shapeData.center,
                    };
                },
            })
            .add( PlusCreateStateSet as any , {
                transform( progress ) {
                    return { inProgress: false };
                },
            });
    }

    /**
     * Creates a new shape
     *
     *  Input Data: {
     *      defId: string, // The defId of the shape to be created
     *      version: number, // The version of the definition of the shape to be created
     *      x: number, // Shapes x position
     *      y: number, // Shapes y position
     *      points: [ IConnectorEndPoint x 2 ], // The end point locations for connector if it should connect
     *  }
     */
    public static plusCreateConnect = new DiagramCommandEvent( 'PlusCreateConnect' );
    protected static registerplusCreateConnect( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.plusCreateConnect )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 2,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any, {
                transform( progress ) {
                    const shapeData = { ...progress.eventData.shapeData };
                    const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                    return data;
                },
            })
            .add( AddDiagramShape as any , {
                transform( progress ) {
                    const shapeData = { ...progress.eventData.shapeData };
                    delete shapeData.points;
                    delete shapeData.format;
                    const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                    if ( progress.eventData.entity ) {
                        data.entity = { ...progress.eventData.entity };
                    }
                    return data;
                },
            })
             .add( AddConnector as any, {
                transform( progress ) {
                    const data = progress.resultData[3];
                    const points = progress?.eventData?.shapeData?.points;
                    const { connectorToCopyStylesFrom } = progress.eventData;
                    return { id: Random.shapeId(), ...data, points, connectorToCopyStylesFrom };
                },
            })
            .add( LockGluepoints as any, {
                transform( progress ) {
                    return({
                        id: progress.resultData[3].id,
                        endpoints: progress.eventData.shapeData.endpoints,
                        gluepoints: progress.eventData.shapeData.gluepoints,
                        fromGluepoints: progress.eventData.fromGluepoints,
                        // if we have 'gpeast' gluepoint, use gp{dir} instead of predefined IDs
                        isAlternativeGP: !!progress?.eventData?.shapeData?.gluepoints?.gpeast,
                    });
                },
            })
            .add( ApplyTextShapeStyles as any , {
                transform( progress ) {
                    return {
                        shapeIds: [ progress.eventData.shapeData.id ],
                        format: progress.eventData.shapeData.format,
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
             })
            .add( ChangeContainerData as any, {
                transform( progress ) {
                    return {
                        shapeIds: [ progress.eventData.shapeData.id ],
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.eventData.shapeData.id ]};
                },
            });
    }

    public static plusCreateDragConnect = new DiagramCommandEvent( 'PlusCreateDragConnect' );
    protected static registerPlusCreateDragConnect( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.plusCreateDragConnect )
             .add( AddConnector as any, {
                transform( progress ) {
                    const points = progress?.eventData?.points;
                    return { id: Random.shapeId(), points };
                },
            })
            .add( LockGluepoints as any, {
                transform( progress ) {
                    return({
                        id: progress.resultData[0].id,
                        endpoints: progress.eventData.endpoints,
                        gluepoints: progress.eventData.gluepoints,
                        fromGluepoints: progress.eventData.fromGluepoints,
                        // if we have 'gpeast' gluepoint, use gp{dir} instead of predefined IDs
                        isAlternativeGP: !!progress?.eventData?.gluepoints?.gpeast,
                    });
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.eventData.shapeToSelect ]};
                },
            });
    }

    /**
     * Creates a new shape and a connector. It would connect the connector to the current shape and new shape.
     *
     *  Input Data: {
     *      defId: string, // The defId of the shape to be created
     *      version: number, // The version of the definition of the shape to be created
     *      x: number, // Shapes x position
     *      y: number, // Shapes y position
     *      points: [ IConnectorEndPoint x 2 ], // The end point locations for connector if it should connect
     *  }
     */
     public static plusCreate = new DiagramCommandEvent( 'PlusCreate' );
     protected static registerplusCreate( mapper: CommandMapper ) {
         mapper.mapSequence( DiagramCommandEvent.plusCreate )
             .add( CheckPermissions as any, {
                 transform( progress ) {
                     const data = { ...progress.eventData };
                     data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                     data.permData = {
                        noOfShapesToAdd: 1,
                    };
                     return data;
                 },
             })
             .add( CheckPremiumShapeLimitations as any, {
                 transform( progress ) {
                     const shapeData = { ...progress.eventData.shapeData };
                     const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                     return data;
                 },
             })
             .add( AddDiagramShape as any , {
                 transform( progress ) {
                     const shapeData = { ...progress.eventData.shapeData };
                     delete shapeData.points;
                     delete shapeData.format;
                     const data: any = { shapes: { [ progress.eventData.shapeData.id ]: shapeData }};
                     if ( progress.eventData.entity ) {
                         data.entity = { ...progress.eventData.entity };
                     }
                     return data;
                 },
             })
             .add( ApplyTextShapeStyles as any , {
                 transform( progress ) {
                     return {
                         shapeIds: [ progress.eventData.shapeData.id ],
                         format: progress.eventData.shapeData.format,
                     };
                 },
             })
             .add( DocumentChange as any, {
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             })
             .add( SelectShapes as any, {
                 transform( progress ) {
                     return { shapeIds: [ progress.eventData.shapeData.id ]};
                 },
             });
     }

    /**
     * Adds a new connector and connects the first point to a gluepoint if possible.
     *
     *  Input Data: {
     *      id: string,
     *      points: [ IConnectorEndPoint x 2 ],
     *  }
     *
     */
    public static addConnector = new DiagramCommandEvent( 'AddConnector', EventSource.TRANSIENT );
    protected static registerAddConnector( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addConnector )
            .add( CheckInteractionState as any )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 1,
                    };
                    return data;
                },
            })
            .add( ConvertViewportForPoints as any )
            .add( AddConnector as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.data[1].id ]};
                },
            })
            .add( StartMovingConnectorEnd as any,  {
                transform( progress ) {
                    return { connectorId: progress.data[1].id };
                },
            });
    }

    public static adjustConnector = new DiagramCommandEvent( 'AdjustConnector', EventSource.SYSTEM );
    protected static registerAdjustConnector( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.adjustConnector )
            .add( AdjustConnector as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Changes the connector draw style and adjusts the connector path accordingly.
     *
     *  Input Data: {
     *      changes: {
     *          [ shapeId ]: {
     *              entryClass: string,
     *          },
     *      }
     *  }
     * Note that the entryClass which is set on the change command is not the shape class name itself.
     * It is a full shape identifier having js file name and the entryClass which follow entry class identifier format.
     * ie: a-shape.js#ShapeClass.
     */
    public static changeDrawStyle = new DiagramCommandEvent( 'ChangeDrawStyle' );
    protected static registerChangeDrawStyle( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeDrawStyle )
            .add( ChangeDrawStyle as any )
            .add( EditConnectorPoint as any, {
                transform( progress ) {
                    const data = progress.data[0];
                    const changes: any = {};
                    Object.keys( data.changes ).forEach( id => changes[id] = []);
                    return { changes };
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    const data = progress.data[0];
                    const changes: any = {};
                    Object.keys( data.changes ).forEach( id => changes[id] = {});
                    return { changes };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates the diagram description and tags.
     *
     *  Input Data: {
     *      description?: string,
     *      tags?: string,
     *  }
     */
    public static updateDiagramInfo = new DiagramCommandEvent( 'UpdateDiagramInfo' );
    protected static registerUpdateDiagramInfo( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateDiagramInfo )
            .add( UpdateDiagramInfo as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }


    /**
     * Updates the libraries
     *
     *  Input Data: {
     *      libraries: ILoadedLibrary[],
     *  }
     */
    public static updateLibraries = new DiagramCommandEvent( 'updateLibraries' );
    protected static registerUpdateLibraries( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateLibraries )
            .add( UpdateLibraries as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates the Recent in diagram
     *
     *  Input Data: {
     *      shapes: {
     *          defId: string;
     *          weight: number;
     *      }[],
     *  }
     */
    public static updateRecentShapes = new DiagramCommandEvent( 'UpdateRecentShapes', EventSource.SYSTEM );
    protected static registerUpdateRecentShapes( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateRecentShapes )
            .add( UpdateRecentShapes as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Update edata
     *
     *  Input Data: {
     *      edata: string[] // edataIds
     *  }
     */
    public static addEDataModel = new DiagramCommandEvent( 'addEDataModel', EventSource.SYSTEM );
    protected static registerUpdateEData( mapper: CommandMapper ) {
        mapper.mapSequence( this.addEDataModel )
            .add( AddEDataModel as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( <any>StartEDataSubscription );

    }


    public static addTask = new DiagramCommandEvent( 'addTask' );
    protected static registerAddTask( mapper: CommandMapper ) {
        mapper.mapSequence( this.addTask )
            .add( AddTask as any );
    }

    public static updateTask = new DiagramCommandEvent( 'updateTask' );
    protected static registerUpdateTask( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateTask )
            .add( UpdateTask as any );
    }


    public static removeTask = new DiagramCommandEvent( 'removeTask' );
    protected static registerRemoveTask( mapper: CommandMapper ) {
        mapper.mapSequence( this.removeTask )
            .add( RemoveTask as any );
    }

    /**
     * This command is used to attach a child to a container and also to detach a child from a container
     *  Input Data: {
     *      action: 'enter' | 'leave',
     *      childId: string,
     *      containerId: string,
     *  }
     */
    public static changeContainerData = new DiagramCommandEvent( 'ChangeContainerData', EventSource.SYSTEM );
    public static changeContainerDataExternal = new DiagramCommandEvent( 'ChangeContainerData', EventSource.EXTERNAL );
    protected static registerChangeContainerData( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeContainerData )
            .add( ChangeContainerData as any )
            .add( ChangeShapeDataItems as any , {
                transform( progress ) {
                    return progress.resultData[0];
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
                transform( progress ) {
                    return {
                        __origin__: progress.eventData?.__origin__,
                    };
                },
            });
    }

    /**
     * This command is used to attach a child to a container and also to detach a child from a container
     *  Input Data: {
     *      action: 'enter' | 'leave',
     *      childId: string,
     *      containerId: string,
     *  }
     */
    public static changeContainerDataAndUpdateBounds = new DiagramCommandEvent(
        'ContainerDataAndUpdateBounds', EventSource.SYSTEM );
    protected static registerChangeContainerDataAndUpdateBounds( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeContainerDataAndUpdateBounds )
            .add( UpdateTextBounds as any )
            .add( ChangeContainerData as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.eventData.shapeIds,
                        addIntoContainerId: progress.eventData.addIntoContainerId,
                    };
                },
            })
            .add( ChangeShapeDataItems as any , {
                transform( progress ) {
                    return progress.resultData[1];
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * This command is used to attach a child to a container and also to detach a child from a container
     *  Input Data: {
     *      action: 'enter' | 'leave',
     *      childId: string,
     *      containerId: string,
     *  }
     */
    public static changeShapeDataItems = new DiagramCommandEvent( 'ChangeShapeDataItems' );
    protected static registerChangeShapeDataItems( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeShapeDataItems )
            .add( ChangeShapeDataItems as any )
            .add( UpdateTextBounds as any, {
                transform( progress ) {
                    return {
                        shapeIds: Object.keys( progress.data?.[0] || {}),
                    };
                },
            })
            .add( UpdateShapeEditors as any, {
                transform( progress ) {
                    return {
                        shapeIds: Object.keys( progress.data?.[0] || {}),
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     *
     */
    public static copyShapeData = new DiagramCommandEvent( 'CopyShapeData' );
    protected static registerCopyShapeData( mapper: CommandMapper ) {
        mapper.mapSequence( this.copyShapeData )
            .add( CopyShapeData as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates the shape name.
     *
     *  Input Data: {
     *      name: string,
     *  }
     */
    public static renameShape = new DiagramCommandEvent( 'RenameShape' );
    protected static registerRenameShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.renameShape )
            .add( RenameShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Changes the connector draw style and adjusts the connector path accordingly.
     *
     *  Input Data: {
     *      changes: {
     *          [ shapeId ]: {
     *              from: string,
     *              to: string,
     *          },
     *      },
     *  }
     */
    public static changeArrowHeads = new DiagramCommandEvent( 'ChangeArrowHeads' );
    protected static registerChangeArrowHeads( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeArrowHeads )
            .add( ChangeArrowHeads as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Remove the entity reference from shape.
     */
    public static removeShapeFromEdata = new DiagramCommandEvent( 'RemoveShapeFromEdata' );
    protected static registerRemoveShapeFromEdata( mapper: CommandMapper ) {
        mapper.mapSequence( this.removeShapeFromEdata )
            .add( RemoveShapeFromEdata as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Removes shapes from the diagram by their ids. Optionally deletes all selected shapes.
     * If the shape has any connected connectors, disconnects them before removing the shape.
     *
     *  Input Data: {
     *      shapeIds: string[],
     *      selected: boolean,
     *  }
     *
     */
    public static removeShape = new DiagramCommandEvent( 'RemoveShape' );
    public static removeShapeExternal = new DiagramCommandEvent( 'RemoveShape', EventSource.EXTERNAL );
    protected static registerRemoveShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.removeShape )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( FilterOngoingVotingShapes as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                        selected: false,
                    };
                },
            })
            .add( GetRemovedShapeIds as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[1].shapeIds,
                        selected: false,
                    };
                },
            })
            .add( GetConnectedConnectors as any )
            .add( GetShapeContainer as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds };
                },
            })
            .add( GetConnectedShapes as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds };
                },
            })
            .add( GetNearestShape as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds };
                },
            })
            .add( RemoveLinkDialog as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[1].shapeIds;
                    return { shapeIds };
                },
            })
            .add( EditConnectorPoint as any, {
                transform( progress ) {
                    const connected = progress.resultData[3];
                    const removed = progress.resultData[2].shapeIds;
                    const changes: any = {};
                    Object.keys( connected ).forEach( connectorId => {
                        if ( removed.indexOf( connectorId ) >= 0 ) {
                            return;
                        }
                        changes[connectorId] = connected[connectorId].map( endpoint => ({
                            ...endpoint,
                            shapeId: null,
                            gluepointId: null,
                            gluepointLocked: null,
                        }));
                    });
                    return { changes };
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    const connected = progress.resultData[3];
                    const removed = progress.resultData[2].shapeIds;
                    const changes: any = {};
                    Object.keys( connected ).forEach( id => changes[id] = {});
                    return { changes, removed };
                },
            })
            .add( RemoveShape as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds };
                },
            })
            .add( RemoveEntityDialog as any )
            .add( RemoveShapeTasks as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds };
                },
            })
            // FIXME: If draw style changes by the type change, it won't get applied.
            .add( SetConnectorConnection as any, {
                transform( progress ) {
                    const connected = progress.resultData[3];
                    const removed = progress.resultData[2].shapeIds;
                    const changes: any = {};
                    Object.keys( connected ).forEach( connectorId => {
                        if ( removed.indexOf( connectorId ) >= 0 ) {
                            return;
                        }
                        changes[connectorId] = null;
                    });
                    return {
                        changes,
                        updateConnections: true,
                        updateDefinitions: true,
                    };
                },
            })
            .add( UpdateDisplayRules as any, {
                transform( progress ) {
                    return { removeShapeIds: progress.resultData[2].shapeIds };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[2].shapeIds;
                    return { shapeIds, remove: true };
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const containers = progress.resultData[4];
                    const connectedShapes = progress.resultData[5];
                    const nearestShapes = progress.resultData[6];
                    let focusParentShape = '';

                    // 1. trying to find not deleted container
                    Object.keys( containers ).find( shapeId => {
                        if (
                            containers[shapeId] &&
                            progress.resultData[2].shapeIds.indexOf( containers[shapeId]) === -1 ) {
                            focusParentShape = containers[shapeId];
                            return true;
                        }
                    });
                    if ( focusParentShape ) {
                        return { shapeIds: [ focusParentShape ], add: true };
                    }

                    // 2. trying to find connection to not deleted shape, IN has priority
                    let focusInConnectedShape = '';
                    let focusOutConnectedShape = '';
                    Object.keys( connectedShapes ).find( shapeId => connectedShapes[shapeId].find(
                        connectedShape => {
                            if ( connectedShape.dir === 'IN' &&
                                progress.resultData[2].shapeIds.indexOf( connectedShape.shapeId ) === -1 ) {
                                focusInConnectedShape = connectedShape.shapeId;
                                return true;
                            } else if ( !focusOutConnectedShape &&
                                progress.resultData[2].shapeIds.indexOf( connectedShape.shapeId ) === -1 ) {
                                focusOutConnectedShape = connectedShape.shapeId;
                            }
                        }));
                    if ( focusInConnectedShape ) {
                        return { shapeIds: [ focusInConnectedShape ], add: true };
                    }
                    if ( focusOutConnectedShape ) {
                        return { shapeIds: [ focusOutConnectedShape ], add: true };
                    }

                    // 3. Find nearest shape to any of removed
                    const nearest = Object.keys( nearestShapes )
                        .reduce(( res, shapeId ) => {
                            if ( nearestShapes[shapeId].distance < res.distance ) {
                                res = nearestShapes[shapeId];
                            }
                            return res;
                        }, { shape: null, distance: Infinity });

                    if ( nearest && nearest.shape ) {
                        return { shapeIds: [ nearest.shape.id ], add: true };
                    }

                    return { shapeIds: [], add: false };
                },
            });
    }

    /**
     * Resets the diagram preview data which is stored on the diagram locator.
     *
     *  Input Data: (none)
     *
     */
    public static resetDiagramPreview = new DiagramCommandEvent( 'ResetDiagramPreview' );
    protected static registerResetDiagramPreview( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.resetDiagramPreview )
            .add( ResetDiagramPreview as any );
    }

    /*
     * Applies styles to shapes.
     * The applied styles include the shapes styles plus styling for
     * any text in the shape.
     * Input data :
     *  {   shapeIds // shape ids array
     *      style // shape styles
     *      format //text formatting
     *      reset //reset existnig text styles before applying anew
     *  }
     */
    public static applyShapeStyle = new DiagramCommandEvent( 'ApplyShapeStyle' );
    protected static registerApplyShapeStyle( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.applyShapeStyle )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( ApplyShapeStyles as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.shapeIds = progress.resultData[0].shapeIds;
                    return data;
                },
            })
            .add( AutocorrectTextColor as any, {
                transform: progress => ({
                        style: progress.eventData.style,
                        format: progress.eventData.format, // piggy back on previous command changes
                        shapeIds: progress.resultData[0].shapeIds,
                }),
            })
            .add( ApplyTextShapeStyles as any, {
                transform: progress => ({
                    format: progress.resultData[2].format,
                    reset: progress.data[1].reset,
                    shapeIds: progress.resultData[0].shapeIds,
                    applyTextStyles: progress.eventData.processTextStyles,
                }),
            })
            .add( PositionText as any, {
                transform: progress => ({
                    shapeIds: progress.resultData[0].shapeIds,
                    format: progress.resultData[2].format,
                }),
            })
            .add( ToggleConnectorVisibility as any, {
                transform() {
                    const data = { hideConnector: false, selection: true };
                    return data;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * Change the text position of the texts in the selected shapes
     * Input data :
     *  {   shapeIds
     *      alignX
     *      alignY
     *      xType
     *      yType
     *      x
     *      y
     *  }
     */
    public static positionText = new DiagramCommandEvent( 'PositionText' );
    protected static registerPositionText( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.positionText )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( PositionText as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.shapeIds = progress.resultData[0].shapeIds;
                    return data;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static copyShapes = new DiagramCommandEvent( 'CopyShapes' );
    protected static registerCopyShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.copyShapes )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( CopyShapes as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                        selected: false,
                        copyEdata: progress.resultData[0].copyEdata,
                    };
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static cutShapes = new DiagramCommandEvent( 'cutShapes' );
    protected static registercutShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.cutShapes )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( CopyShapes as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                        selected: false,
                        copyEdata: progress.resultData[0].copyEdata,
                    };
                },
            })
            .add( GetRemovedShapeIds as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                        selected: false,
                    };
                },
            })
            .add( RemoveShape as any )
            .add( RemoveShapeTasks as any, {
                transform: progress => {
                    const shapeIds = progress.resultData[0].shapeIds;
                    return { shapeIds };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => ({
                    shapeIds: progress.resultData[2].shapeIds,
                    remove: true,
                }),
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static pasteShapes = new DiagramCommandEvent( 'PasteShapes' );
    protected static registerPasteShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.pasteShapes )
            .add( CloneShapes as any )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.resultData[0]?.shapeIds.length || 0,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any, {
                transform( progress ) {
                    const shapesObj = {};
                    const shapes = progress.resultData[0]?.shapesToBeClone;
                    shapes.forEach(( shape: any ) => {
                        shapesObj[ shape.id] = shape;
                    });
                    const data: any = { shapes: shapesObj };
                    return data;
                },
            })
            .add( PasteShapes as any,  {
                transform( progress ) {
                    return progress.resultData[0];
                },
            })
            .add( UpdateDisplayRules as any, {
                transform( progress ) {
                    return {
                        copyShapeIds:
                            {
                                original: progress.resultData[0].shapeIds,
                                pasted: progress.data[3].shapesToBeClone.map( item => item.id ),
                            },
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[0].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static generateShapes = new DiagramCommandEvent( 'GenerateShapes', EventSource.SYSTEM );
    protected static registerGenerateShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.generateShapes )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.eventData?.shapesData.shapeIds.length || 0,
                    };
                    return data;
                },
            })
            .add( RemoveShape as any , {
                transform( progress ) {
                    return {
                        shapeIds: progress.eventData.shapeIdsToRemove,
                    };
                },
            })
            .add( CloneShapes as any , {
                transform( progress ) {
                    return {
                        externalData: progress.eventData.shapesData,
                        // target: progress.eventData.target,
                        onSameLocation: true,
                    };
                },
            })
            .add( PasteShapes as any )
            .add( RenameDiagram as any, {
                transform( progress ) {
                    return {
                        name: progress.eventData.diagramName,
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[2].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            });
    }

    public static generateShapesAndLayout = new DiagramCommandEvent( 'GenerateShapesAndLayout', EventSource.SYSTEM );
    protected static registerGenerateShapesAndLayout( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.generateShapesAndLayout )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.eventData?.length || 0,
                    };
                    return data;
                },
            })
            .add( RemoveShape as any , {
                transform( progress ) {
                    return {
                        shapeIds: progress.eventData.shapeIdsToRemove,
                    };
                },
            })
            .add( CloneShapes as any , {
                transform( progress ) {
                    return {
                        externalData: progress.eventData.shapesData,
                        // target: progress.eventData.target,
                        onSameLocation: true,
                    };
                },
            })
            .add( PasteShapes as any )
            .add( RenameDiagram as any, {
                transform( progress ) {
                    return {
                        name: progress.eventData.diagramName,
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( LayoutShapes as any, {
                transform( progress ) {
                    const shapes = progress.resultData[2].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return {
                        layoutingId: progress.eventData.layoutingId,
                        bounds: progress.eventData.bounds,
                        shapeIds,
                    };
                },
            })
            .add( UpdateTextBounds as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[2].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            });
    }

    public static getTemplateContext = new DiagramCommandEvent( 'GetTemplateContext' );
    protected static registerGetTemplateContext( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.getTemplateContext )
            .add( GetTemplateContext as any );
    }

    public static getVizData = new DiagramCommandEvent( 'GetVizData' );
    protected static registerGetVizData( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.getVizData )
            .add( GetVizData as any );
    }

    public static getVizRequestId = new DiagramCommandEvent( 'GetVizRequestId' );
    protected static registerGetVizRequestId( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.getVizRequestId )
            .add( GetVizRequestId as any );
    }

    public static updateTemplateContext = new DiagramCommandEvent( 'UpdateTemplateContext' );
    protected static registerUpdateTemplateContext( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.updateTemplateContext )
            .add( UpdateTemplateContext as any );
    }

    // FIXME : Chandika: Noticed that this is SPECIFICALLY for db.table shape??? This should be removed 7/5/23
    public static generateNewShape = new DiagramCommandEvent( 'generateNewShape' );
    protected static registergenerateNewShape( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.generateNewShape )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 1,
                    };
                    return data;
                },
            })
            .add( CopyShapes as any, {
                transform: progress => ({ selected: true }),
            })
            .add( CloneShapes as any, {
                transform: progress => progress.resultData[1],
            })
            .add( PasteShapesAsNew as any  )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[2].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            });
    }

    public static addEntityToWorkspace = new DiagramCommandEvent( 'addEntityToWorkspace' );
    protected static registerAddEntityToWorkspace( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addEntityToWorkspace )
            .add( AddEntityToWorkspace as any );
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static duplicateShapesAndMove = new DiagramCommandEvent( 'duplicateShapesAndMove' );
    protected static registerDuplicateShapesAndMove( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.duplicateShapesAndMove )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( DuplicateShapes as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                    };
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.resultData[1].shapesToBeClone?.length || 0,
                    };
                    return data;
                },
            })
            .add( PasteShapes as any, {
                transform( progress ) {
                    return progress.resultData[1];
                },
            })
            .add( UpdateDisplayRules as any, {
                transform( progress ) {
                    return {
                        copyShapeIds:
                            {
                                original: progress.data[1].shapeIds,
                                pasted: progress.data[3].shapesToBeClone.map( item => item.id ),
                            },
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[1].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            })
            .add( StartMovingShape as any,  {
                transform( progress ) {
                    return {
                        shapeId: progress.data[6].shapeIds[0],
                    };
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static duplicateShapes = new DiagramCommandEvent( 'DuplicateShapes' );
    protected static registerDuplicateShapes( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.duplicateShapes )
            .add( FilterLockedOrUnlockedShapes as any )
            .add( DuplicateShapes as any, {
                transform( progress ) {
                    return {
                        shapeIds: progress.resultData[0].shapeIds,
                    };
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: progress.resultData[1].shapesToBeClone?.length || 0,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any, {
                transform( progress ) {
                    const shapesObj = {};
                    const shapes = progress.resultData[1]?.shapesToBeClone;
                    shapes.forEach(( shape: any ) => {
                        shapesObj[ shape.id] = shape;
                    });
                    const data: any = { shapes: shapesObj };
                    return data;
                },
            })
            .add( PasteShapes as any, {
                transform( progress ) {
                    return progress.resultData[1];
                },
            })
            .add( UpdateDisplayRules as any, {
                transform( progress ) {
                    return {
                        copyShapeIds:
                            {
                                original: progress.data[1].shapeIds,
                                pasted: progress.data[4].shapesToBeClone.map( item => item.id ),
                            },
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => {
                    const shapes = progress.resultData[1].shapesToBeClone;
                    const shapeIds = shapes.map( shape => shape.id );
                    return { shapeIds };
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static openTextEditor = new DiagramCommandEvent( 'OpenTextEditor' );
    public static openTextEditorSystem = new DiagramCommandEvent( 'OpenTextEditor', EventSource.SYSTEM );
    protected static registerOpenTextEditor( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.openTextEditor )
            .add( CheckInteractionState as any )
            .add( EditText as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    return data;
                },
            });
    }

    public static openQuickShapeSearch = new DiagramCommandEvent( 'OpenQuickShapeSearch' );
    protected static registerOpenQuickShapeSearch( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.openQuickShapeSearch )
            .add( OpenQuickShapeSearch as any );
    }

    public static closeTextEditor = new DiagramCommandEvent( 'CloseTextEditor' );
    protected static registerCloseTextEditor( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.closeTextEditor )
            .add( EditText as any );
    }

    /**
     * Opens / closes the ShapeDataEditor panel
     * Input Data: { open: boolean }
     */
    public static openShapeDataEditor = new DiagramCommandEvent( 'OpenShapeDataEditor' );
    protected static registerOpenShapeDataEditor( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.openShapeDataEditor )
            .add( OpenShapeDataEditor as any );
    }

    /*
     * Opens / closes the hyperlink editor
     *  Input Data: {
     *    open: boolean,            // To open or close the editor, ( Mandetory )
     *    origin: string,           // Extra data to determine how it's opned, ( Optional )
     *    link: string,             // The link to view / edit, ( Optional )
     *    state: 'view' | 'edit',   // When the editor opens, cosider this state, ( Optional )
     *  }
     *
     */
    public static openHyperlinkEditor = new DiagramCommandEvent( 'OpenHyperlinkEditor' );
    protected static registerOpenHyperlinkEditor( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.openHyperlinkEditor )
            .add( EditHyperlink as any );
    }

    /*
     * Opens / closes the shapelink editor
     *  Input Data: {
     *    open: boolean,            // To open or close the editor, ( Mandetory )
     *    origin: string,           // Extra data to determine how it's opned, ( Optional )
     *    link: string,             // The link to view / edit, ( Optional )
     *    state: 'view' | 'edit',   // When the editor opens, cosider this state, ( Optional )
     *  }
     *
     */
    public static openShapelinkEditor = new DiagramCommandEvent( 'OpenShapelinkEditor' );
    protected static registerOpenShapelinkEditor( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.openShapelinkEditor )
            .add( EditShapelink as any );
    }

    /*
     * Open the app status indicator tooltip.
     *  Input Data: {
     *    open: boolean,            // To open or close the tooltip
     *  }
     *
     */
    public static openAppStatusToolTip = new DiagramCommandEvent( 'openAppStatusToolTip' );
    protected static registerOpenAppStatusToolTip( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.openAppStatusToolTip )
            .add( AppStatusIndicator as any );
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static selectAndOpenTextEditor = new DiagramCommandEvent( 'SelectAndOpenTextEditor' );
    protected static registerSelectAndOpenTextEditor( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.selectAndOpenTextEditor )
            .add( SelectShapes as any )
            .add( EditText as any, {
                transform: progress => ({
                    open: progress.data[0].open,
                    textId: progress.data[0].textId,
                    text: progress.data[0].text,
                }),
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static applyText = new DiagramCommandEvent( 'ApplyText' );
    public static applyTextSystem = new DiagramCommandEvent( 'ApplyText', EventSource.SYSTEM );
    protected static registerApplyText( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.applyText )
            .add( ApplyText as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static applyTextBounds = new DiagramCommandEvent( 'ApplyTextBounds' );
    protected static registerApplyTextBounds( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.applyTextBounds )
            .add( ApplyTextBounds as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * Add / update/ remove 'link' property of the shape model
     *
     */
    public static applyShapelink = new DiagramCommandEvent( 'ApplyShapelink' );
    protected static registerApplyShapelink( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.applyShapelink )
            .add( ApplyShapelink as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static updateTextBounds = new DiagramCommandEvent( 'UpdateTextBounds', EventSource.SYSTEM );
    protected static registerUpdateTextBounds( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.updateTextBounds )
            .add( UpdateTextBounds as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static migrateToNewRegionType = new DiagramCommandEvent( 'MigrateToNewRegionType', EventSource.SYSTEM );
    protected static registerMigrateToNewRegionType( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.migrateToNewRegionType )
            .add( MigrateToNewRegionType as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static migrateDataDefs = new DiagramCommandEvent( 'MigrateDataDefs', EventSource.SYSTEM );
    protected static registerMigrateDataDefs( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.migrateDataDefs )
            .add( MigrateDataDefs as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static migrateTasks = new DiagramCommandEvent( 'MigrateTasks', EventSource.SYSTEM );
    protected static registerMigrateTasks( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.migrateTasks )
            .add( MigrateTasks as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static diagramContainersReLayout = new DiagramCommandEvent( 'DiagramContainersReLayout' );
    protected static registerDiagramContainersReLayout( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.diagramContainersReLayout )
            .add( DiagramContainersReLayout as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * TODO: add description
     *
     *  Input Data: {}
     *
     */
    public static applyTextAndCloseEditor = new DiagramCommandEvent( 'ApplyTextAndCloseEditor' );
    protected static registerApplyTextAndCloseEditor( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.applyTextAndCloseEditor )
            .add( ApplyText as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( EditText as any, {
                transform: progress => ({
                    open: false,
                    textId: progress.data[0].textId,
                    shapeId: progress.data[0].shapeId,
                }),
            });
    }

    /**
     * Subscribe to diagram and fetch data for other commands.
     *
     *  Input Data: {
     *      name: string,
     *  }
     */
    public static fetchDiagram = new DiagramCommandEvent( 'FetchDiagram' );
    protected static registerFetchDiagram( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.fetchDiagram )
            .add( FetchDiagram as any );
    }

    /**
     * Updates the diagram name.
     *
     *  Input Data: {
     *      name: string,
     *  }
     */
    public static renameDiagram = new DiagramCommandEvent( 'RenameDiagram' );
    protected static registerRenameDiagram( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.renameDiagram )
            .add( FetchDiagram as any )
            .add( RenameDiagram as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Changes the connector type.
     *
     *  Input Data: {
     *      changes: {
     *          [ shapeId ]: {
     *              defId: string,
     *              version: number,
     *          },
     *      }
     *  }
     */
    public static setConnectorConnection = new DiagramCommandEvent( 'SetConnectorConnection' );
    protected static registerSetConnectorConnection( mapper: CommandMapper ) {
        mapper.mapSequence( this.setConnectorConnection )
            .add( SetConnectorConnection as any )
            .add( ReplaceLinkDialog as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Keyboard interaction to move selected items or navigate to closest shapes.
     *  Input Data: {
     *      keyboardEvent: KeyboardEvent,
     *  }
     */
    public static keyboardSelectionInteraction = new DiagramCommandEvent( 'KeyboardSelectionInteraction' );
    protected static registerKeyboardInteraction( mapper: CommandMapper ) {
        mapper.mapSequence( this.keyboardSelectionInteraction )
            .add( KeyboardSelectionInteraction as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Keyboard interaction for plus create actions
     *  Input Data: {
     *      keyboardEvent: KeyboardEvent,
     *  }
     */
     public static keyboardPlusCreateInteraction = new DiagramCommandEvent( 'KeyboardPlusCreateInteraction' );
     protected static registerKeyboardPlusCreateInteraction( mapper: CommandMapper ) {
         mapper.mapSequence( this.keyboardPlusCreateInteraction )
             .add( KeyboardPlusCreateInteraction as any );
     }

    /**
     * Undo  the last undoable action
     */
    public static undoAction = new DiagramCommandEvent( 'UndoAction' );
    protected static registerUndoAction( mapper: CommandMapper ) {
        mapper.mapSequence( this.undoAction )
            .add( UndoAction as any )
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: null };
                },
            });
    }

    /**
     * Redo the last redoable action
     */
    public static redoAction = new DiagramCommandEvent( 'RedoAction' );
    protected static registerRedoAction( mapper: CommandMapper ) {
        mapper.mapSequence( this.redoAction )
            .add( RedoAction as any );
    }

    /**
     * Update one or more data items in a set of shapes
     */
    public static updateDataItems = new DiagramCommandEvent( 'UpdateDataItems', EventSource.SYSTEM );
    protected static registerUpdateDataItems( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateDataItems )
            .add( UpdateDataItems as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sync changes from edata
     */
    public static syncEDataChanges = new DiagramCommandEvent( 'SyncEDataChanges', EventSource.SYSTEM );
    protected static registerSyncEDataChanges( mapper: CommandMapper ) {
        mapper.mapSequence( this.syncEDataChanges )
            .add( SyncEDataChanges as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sync changes from edata
     */
    public static updateShapeDataDefs = new DiagramCommandEvent( 'UpdateShapeDataDefs' );
    protected static registerUpdateShapeDataDefs( mapper: CommandMapper ) {
        mapper.mapSequence( this.updateShapeDataDefs )
            .add( UpdateShapeDataDefs as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Apply given modier to the diagram change model
     */
     public static applyModifierDocument = new DiagramCommandEvent( 'ApplyModifierDocument', EventSource.SYSTEM );
     protected static registerApplyModifierDocument( mapper: CommandMapper ) {
         mapper.mapSequence( this.applyModifierDocument )
             .add( ApplyModifierDocument as any )
             .add( DocumentChange as any, {
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             });
     }

    /**
     * Add an entity to the shape
     */
    public static addEntityToShape = new DiagramCommandEvent( 'AddEntityToShape', EventSource.SYSTEM );
    protected static registerAddEntityToShape( mapper: CommandMapper ) {
        mapper.mapSequence( this.addEntityToShape )
            .add( AddEntityToShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Add an entities to the shapes
     */
    public static addEntitiesToShapes = new DiagramCommandEvent( 'AddEntitiesToShapes', EventSource.SYSTEM );
    protected static registerAddEntitiesToShapes( mapper: CommandMapper ) {
        mapper.mapSequence( this.addEntitiesToShapes )
            .add( AddEntitiesToShapes as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Add an entity reference to the shape
     */
     public static addEDataRefToShape = new DiagramCommandEvent( 'AddEDataRefToShape' );
     protected static registerAddEDataRefToShape( mapper: CommandMapper ) {
         mapper.mapSequence( this.addEDataRefToShape )
             .add( AddEDataRefToShape as any )
             .add( DocumentChange as any, {
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             });
     }

    /**
     * Swap shapes
     */
    public static swapShape = new DiagramCommandEvent( 'SwapShape' );
    protected static registerSwapShape( mapper: CommandMapper ) {
        mapper.mapSequence( this.swapShape )
            .add( SwapShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Performs an action on a shape by invoking a function in its logic class
     * Calls EditText command twice after with result data from shape action cmd
     * to close current text editor and open new editor on another text model where applicable
     */
    public static doShapeAction = new DiagramCommandEvent( 'DoShapeAction' );
    protected static registerDoShapeAction( mapper: CommandMapper ) {
        mapper.mapSequence( this.doShapeAction )
            .add( DoShapeAction as any )
            .add( EditText as any, {
                transform: progress =>
                    progress.resultData[0] ? progress.resultData[0][0] : { cancelEditTextCmd: true },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( EditText as any, {
                transform: progress =>
                    progress.resultData[0] ? progress.resultData[0][1] : { cancelEditTextCmd: true },
            });
    }

    /**
     * Switches the shape and recalculates PlusCreate positioning by updating selection
     */
     public static switchShape = new DiagramCommandEvent( 'SwitchShape' );
     protected static registerSwitchShape( mapper: CommandMapper ) {
         mapper.mapSequence( this.switchShape )
            .add( SwitchShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform: progress => ({ shapeIds: []}),
            })
            .add( SelectShapes as any, {
                transform: progress => ( progress.resultData[0]),
            });
     }

    /**
     * Switches the shape and recalculates PlusCreate positioning by updating selection
     */
    public static switchShapeAny = new DiagramCommandEvent( 'SwitchShapeAny' );
    protected static registerSwitchShapeAny( mapper: CommandMapper ) {
        mapper.mapSequence( this.switchShapeAny )
            .add( SwitchShapeAny as any, {
                transform( progress ) {
                    const data: any = { ...progress.eventData };
                    return data;
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 2,
                    };
                    return data;
                },
            })
            .add( CheckPremiumShapeLimitations as any, {
                transform( progress ) {
                    const data = { ...progress.resultData[ 0 ] };
                    return data;
                },
            })
            .add( DocumentChange as any, {
               alter( progress ) {
                   return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
               },
            })
            .add( SelectShapes as any, {
               transform: progress => ({ shapeIds: []}),
            })
            .add( SelectShapes as any, {
            transform: progress => ( progress.resultData[0]),
        });
    }

    /**
     * Switches the shape with Text shape and recalculates
     */
    public static replaceTextShape = new DiagramCommandEvent( 'ReplaceTextShape' );
    protected static registerReplaceTextShape( mapper: CommandMapper ) {
        mapper.mapSequence( this.replaceTextShape )
           .add( ReplaceTextShape as any )
           .add( DocumentChange as any, {
               alter( progress ) {
                   return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
               },
           })
           .add( SelectShapes as any, {
               transform: progress => ({ shapeIds: []}),
           })
           .add( SelectShapes as any, {
               transform: progress => ( progress.resultData[0]),
           });
    }

    /**
     * Calls a method in a shape logic class that requires a change model
     */
    public static executeShapeLogicMethod = new DiagramCommandEvent( 'ExecuteShapeLogicMethod', EventSource.SYSTEM );
    protected static registerExecuteShapeLogicMethod( mapper: CommandMapper ) {
        mapper.mapSequence( this.executeShapeLogicMethod )
            .add( ExecuteShapeLogicMethod as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Calls a method in a shape logic class that requires a change model after closing open text editor
     */
    public static commitTextAndExecuteShapeLogicMethod =
        new DiagramCommandEvent( 'CommitTextAndExecuteShapeLogicMethod', EventSource.SYSTEM );
    protected static registerCommitTextAndExecuteShapeLogicMethod( mapper: CommandMapper ) {
        mapper.mapSequence( this.commitTextAndExecuteShapeLogicMethod )
            .add( ApplyText as any )
            .add( ExecuteShapeLogicMethod as any )
            .add( UpdateShapeEditors as any, {
                transform( progress ) {
                    return {
                        shapeIds: [ progress.data?.[0].shapeId ],
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Applies the styles to the selected range of the shape text editor
     *  Input Data: {
     *      bold: null, // Commnad will decide whether apply bold or remove bold
     *      italic: false, // Remove italic
     *      size: 15.5, // Set font size to 15.5pt
     *  }
     */
    public static applyTextEditorStyles = new DiagramCommandEvent( 'ApplyTextEditorStyles' );
    protected static registerApplyTextEditorStyles( mapper: CommandMapper ) {
        mapper.mapSequence( this.applyTextEditorStyles )
            .add( ApplyTextEditorStyles as any );
    }

    /**
     * Copies the shape/group link to the clipbpard
     */
    public static copyShapeLink = new DiagramCommandEvent( 'CopyShapeLink' );
    protected static registerCopyShapeLink( mapper: CommandMapper ) {
        mapper.mapSequence( this.copyShapeLink )
            .add( CopyShapeLink as any );
    }

    /**
     * Brings a given shape or shapes on top of all existing shapes in the diagram.
     */
    public static bringToFront = new DiagramCommandEvent( 'BringToFront' );
    protected static registerBringToFront( mapper: CommandMapper ) {
        mapper.mapSequence( this.bringToFront )
            .add( GetSelectedShapeIndexes as any )
            .add( BringToFront as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    // Use resultData of GetSelectedShapeIndexes
                    const data = progress.resultData[0];
                    const minIndex = Math.min( ...Object.keys( data ).map( id => data[id]));
                    return { minIndex };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sends a given shape or shapes below all existing shapes of the diagram
     */
    public static sendToBack = new DiagramCommandEvent( 'SendToBack' );
    protected static registerSendToBack( mapper: CommandMapper ) {
        mapper.mapSequence( this.sendToBack )
            .add( GetSelectedShapeIndexes as any )
            .add( SendToBack as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    // Use resultData of GetSelectedShapeIndexes
                    const data = progress.resultData[0];
                    const minIndex = Math.min( ...Object.keys( data ).map( id => data[id]));
                    return { minIndex };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static sendBackward = new DiagramCommandEvent( 'SendBackward' );
    protected static registerSendBackward( mapper: CommandMapper ) {
        mapper.mapSequence( this.sendBackward )
            .add( GetSelectedShapeIndexes as any )
            .add( SendBackward as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    // Use resultData of GetSelectedShapeIndexes
                    const data = progress.resultData[0];
                    const minIndex = Math.min( ...Object.keys( data ).map( id => data[id]));
                    return { minIndex };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Brings a given shape or a set of shapes one step forward
     */
    public static bringForward = new DiagramCommandEvent( 'BringForward' );
    protected static registerBringForward( mapper: CommandMapper ) {
        mapper.mapSequence( this.bringForward )
            .add( GetSelectedShapeIndexes as any )
            .add( BringForward as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    // Use resultData of GetSelectedShapeIndexes
                    const data = progress.resultData[0];
                    const minIndex = Math.min( ...Object.keys( data ).map( id => data[id]));
                    return { minIndex };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static getTemplate = new DiagramCommandEvent( 'GetTemplate' );
    protected static registerGetTemplate( mapper: CommandMapper ) {
        mapper.mapSequence( this.getTemplate )
            .add( GetTemplate as any );
    }

    public static modifyTable = new DiagramCommandEvent( 'ModifyTable' );
    protected static registerModifyTable( mapper: CommandMapper ) {
        mapper.mapSequence( this.modifyTable )
            .add( ModifyTable as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static vizPrompt = new DiagramCommandEvent( 'VizPrompt' );
    protected static registerVizPrompt( mapper: CommandMapper ) {
        mapper.mapSequence( this.vizPrompt )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.CREATELY_VIZ_ACCESS;
                    return data;
                },
            })
            .add( VizPrompt as any , {
                transform( progress ) {
                    return progress.eventData;
                },
            });
    }

    public static categorizeShapes = new DiagramCommandEvent( 'CategorizeShapes' );
    protected static registerCategorizeShapes( mapper: CommandMapper ) {
        mapper.mapSequence( this.categorizeShapes )
            .add( CategorizeShapes as any )
            .add( UpdateTextBounds as any )
            .add( ChangeContainerData as any, {
                transform( progress ) {
                    const shapeIds = progress.eventData.shapes.map( shape => shape.id );
                    return {
                        shapeIds,
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static importImageAndUpload = new DiagramCommandEvent( 'ImportImageAndUpload' );
    protected static registerImportImageAndUpload( mapper: CommandMapper ) {
        mapper.mapSequence( this.importImageAndUpload )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_IMPORT_IMAGE;
                    return data;
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 1,
                    };
                    return data;
                },
            })
            .add( ImportImage as any )
            .add( AddImageShape as any, {
                transform( progress ) {
                    const data = progress.resultData[2];
                    data.position = progress.data[2].position;
                    return data;
                },
            })
            .add( ChangeImageGallery as any, {
                transform( progress ) {
                    // FIXME : Currently supports only one file at a time, change this to return
                    // multiple images in future
                    const file = progress.resultData[2].files[0];
                    const data = {};
                    data[ file.hash ] = {
                        action: 'add',
                        added: Date.now(),
                    };
                    return data;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    const shapeIds = [];
                    if ( progress.resultData && progress.resultData[2] && progress.resultData[2].change ) {
                        for ( const shape in progress.resultData[2].change.modifier.$set ) {
                            shapeIds.push( progress.resultData[2].change.modifier.$set[shape].id );
                        }
                    }
                    return { shapeIds: shapeIds };
                },
            })
            .add( UploadImage as any, {
                transform( progress ) {
                    // Upload only supports one file at a time for now
                    const file = progress.resultData[2].files[0];
                    return { ...file };
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static convertLucidSVG = new DiagramCommandEvent( 'ConvertLucidSVG' );
    protected static registerConvertLucidSVG( mapper: CommandMapper ) {
        mapper.mapSequence( this.convertLucidSVG )
            .add( ConvertLucidSVG as any );
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static importLucidSVGAndUpload = new DiagramCommandEvent( 'ImportLucidSVGAndUpload' );
    protected static registerImportLucidSVGAndUpload( mapper: CommandMapper ) {
        mapper.mapSequence( this.importLucidSVGAndUpload )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_IMPORT_IMAGE;
                    return data;
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    return data;
                },
            })
            .add( ImportImage as any )
            .add( AddImageShape as any, {
                transform( progress ) {
                    const files1 = progress.resultData[ 1 ].files;
                    const files2 = progress.resultData[ 2 ].files;
                    if ( files2.length === files1.length ) {
                        ( files2 as any[]).forEach(( val, index ) => {
                            val.position = files1[ index ].position;
                            val.replaceText = files1[ index ].replaceText;
                        });
                    }
                    const data = progress.resultData[2];
                    data.position = progress.data[2].position;
                    return data;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    const shapeIds = [];
                    if ( progress.resultData && progress.resultData[2] && progress.resultData[2].change ) {
                        for ( const shape in progress.resultData[2].change.modifier.$set ) {
                            shapeIds.push( progress.resultData[2].change.modifier.$set[shape].id );
                        }
                    }
                    return { shapeIds: shapeIds };
                },
            })
            .add( UploadImages as any, {
                transform( progress ) {
                    // Upload only supports one file at a time for now
                    const file = progress.resultData[2].files[0];
                    return { ...file };
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static uploadFile = new DiagramCommandEvent( 'UploadFileAndUpdateAttchements' );
    protected static registerUploadFileAndUpdateAttchements( mapper: CommandMapper ) {
        mapper.mapSequence( this.uploadFile )
            .add( ImportFile as any )
            .add( UploadImage as any, {
                transform( progress ) {
                    // Upload only supports one file at a time for now
                    const file = progress.resultData[0].files[0];
                    return { ...file };
                },
            });
    }


    /**
     * Scale the image and upload
     */
    public static uploadScaledImage = new DiagramCommandEvent( 'UploadScaledImage' );
    protected static registerUploadScaledImage( mapper: CommandMapper ) {
        mapper.mapSequence( this.uploadScaledImage )
            .add( UploadScaledImage as any )
            .add( ApplyModifierDocument as any, {
                transform( progress ) {
                    const data = progress.resultData?.[0]?.[0];
                    if ( data ) {
                        const path = `shapes.${data.shapeId}.hashScaled`;
                        return {
                            modifier: {
                                $set: {
                                    [path]: data.hash,
                                },
                            },
                        };
                    }
                    return { modifier: {}};
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Update the imageGallery property of the diagram model.
     */
    public static changeAttachments = new DiagramCommandEvent( 'ChangeAttachments' );
    protected static registerChangeAttachments( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeAttachments )
            .add( ChangeAttachments as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Sends a given shape or a set of shapes one step backwards
     */
    public static importImage = new DiagramCommandEvent( 'ImportImage' );
    protected static registerImportImage( mapper: CommandMapper ) {
        mapper.mapSequence( this.importImage )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_IMPORT_IMAGE;
                    return data;
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    data.permData = {
                        noOfShapesToAdd: 1,
                    };
                    return data;
                },
            })
            .add( ImportImage as any )
            .add( AddImageShape as any, {
                transform( progress ) {
                    const data = progress.resultData[2];
                    data.position = progress.data[2].position;
                    return data;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    const shapeIds = [];
                    if ( progress.resultData && progress.resultData[3] && progress.resultData[3].change ) {
                        for ( const shape in progress.resultData[3].change.modifier.$set ) {
                            shapeIds.push( progress.resultData[3].change.modifier.$set[shape].id );
                        }
                    }
                    return { shapeIds: shapeIds };
                },
            });
    }

    /**
     * Update the imageGallery property of the diagram model.
     */
    public static changeImageGallery = new DiagramCommandEvent( 'ChangeImageGallery' );
    protected static registerChangeImageGallery( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeImageGallery )
            .add( ChangeImageGallery as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Update the imageGallery property of the diagram model.
     */
    public static collapseExapndBranch = new DiagramCommandEvent( 'CollapseExapndBranch' );
    protected static registerCollapseExapndBranch( mapper: CommandMapper ) {
        mapper.mapSequence( this.collapseExapndBranch )
            .add( CollapseExapndBranch as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Uploads a file to the server and sets it as a shape image.
     */
    public static uploadShapeImage = new DiagramCommandEvent( 'UploadShapeImage' );
    protected static registerUploadShapeImage( mapper: CommandMapper ) {
        mapper.mapSequence( this.uploadShapeImage )
            .add( ShowUploadWindow as any )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_IMPORT_IMAGE;
                    return data;
                },
            })
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.DIAGRAM_ADD_SHAPES;
                    // TODO: CHeck if this perm check required. SHape image upload does not add additional shape.
                    return data;
                },
            })
            .add( CompressShapeImage as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.files = progress.resultData[0].files;
                    return data;
                },
            })
            .add( ImportImage as any, {
                transform( progress ) {
                    return progress.resultData[3];
                },
            })
            .add( UploadImage as any, {
                transform( progress ) {
                    // Upload only supports one file at a time for now
                    const file = progress.resultData[4].files[0];
                    return { ...file };
                },
            })
            .add( AddShapeImage as any, {
                transform( progress ) {
                    return {
                        position: progress.eventData.position,
                        imageFile: progress.resultData[4].files[0],
                    };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Uploads a file to the server and sets it as a shape image.
     */
    public static showUploadWindow = new DiagramCommandEvent( 'ShowUploadWindow' );
    protected static registerShowUploadWindow( mapper: CommandMapper ) {
        mapper.mapSequence( this.showUploadWindow )
            .add( ShowUploadWindow as any );
    }

    /*
     * Show or hide the connector line bumps in the diagram
     *
     *  Input Data: {
     *      showLineBumps: boolean,
    *       selection?: boolean;
     *  }
     */
    public static toggleConnectorLineBumps = new DiagramCommandEvent( 'ToggleConnectorLineBumps' );
    protected static registerToggleConnectorLineBumps( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.toggleConnectorLineBumps )
            .add( ToggleConnectorLineBumps as any )
            .add( AddConnectorBumps as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static toggleDataFields = new DiagramCommandEvent( 'toggleDataFields' );
    protected static registerToggleDataItems( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.toggleDataFields )
            .add( ToggleDataFields as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }
    /*
     * Paste images onto Canvas
     *
     *  Input Data: Files
     *
     */
    public static pasteItems = new DiagramCommandEvent( 'pasteItems' );
    protected static registerPasteItems( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.pasteItems )
            .add( PasteItems as any );
    }

    public static changeSnapState = new DiagramCommandEvent( 'changeSnapState' );
    protected static registerChangeSnapState( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.changeSnapState )
            .add( ChangeSnapState as any );
    }

    /**
     * Get all the recent diagram ids
     *
     *  Input Data: No input data required
     *
     */
    public static getRecentDiagrams = new DiagramCommandEvent( 'GetRecentDiagrams' );
    protected static registerGetRecentDiagrams( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.getRecentDiagrams ).add( GetRecentDiagrams as any );
    }

    /**
     * Get images from google based on the search query
     *
     * Input Data: {
     *      query: Search query term
     *      startIndex: This is pagination. Google will give always 10 images
     *                  for each search query. So if you want more than 10 images
     *                  start the second call with index 11.
     * Read More: https://developers.google.com/custom-search/v1/cse/list
     * }
     */
    public static getGoogleImages = new DiagramCommandEvent( 'GetGoogleImages' );
    protected static registerGetGoogleImages( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.getGoogleImages )
            .add( GetGoogleAccessToken as any )
            .add( GetGoogleImages as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            });
    }

    /**
     * Fetch the data in base64 format from the given URL
     *
     * Input Data: URL from which the data should be fetched
     */
    public static fetchUrl = new DiagramCommandEvent( 'FetchUrl' );
    protected static registerFetchUrl( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.fetchUrl ).add( FetchUrl as any );
    }

    /**
     * Upload the given image to the server.
     *
     * Input Data: {
     *      hash: image hash value
     *      data: Image base64,
     *      name: file name,
     *      type: content type or mime type,
     *      extension: file extension,
     * }
     */
    public static uploadImage = new DiagramCommandEvent( 'UploadImage' );
    protected static registerUploadImage( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.uploadImage ).add( UploadImage as any );
    }

    /*
     * Lock and unlock shapes in the diagram
     *
     *  Input Data: {
     *      shouldLock: boolean,
     *  }
     */
    public static toggleShapeLock = new DiagramCommandEvent( 'ToggleShapeLock' );
    protected static registerToggleShapeLock( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.toggleShapeLock )
            .add( ToggleShapeLock as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: progress.eventData.shapeIds };
                },
            });
    }

    /**
     * change the shape image.
     *
     *  Input Data: {
     *      position: { shapeId: string, imageId: string },
     *      imageFile: { hash: string, data: string },
     *  }
     */
    public static changeShapeImage = new DiagramCommandEvent( 'ChangeShapeImage' );
    protected static registerChangeShapeImage( mapper: CommandMapper ) {
        mapper.mapSequence( this.changeShapeImage )
        .add( AddShapeImage as any )
        .add( DocumentChange as any, {
            alter( progress ) {
                return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
            },
        });
    }

    /**
     * Remove edata from shapes
     */
    public static removeEdata = new DiagramCommandEvent( 'RemoveEdata' );
    protected static registerRemoveEdata( mapper: CommandMapper ) {
        mapper.mapSequence( this.removeEdata )
        .add( RemoveEdata as any )
        .add( DocumentChange as any, {
            alter( progress ) {
                return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
            },
        });
    }

    /**
     * Retrieves shape history items for the user and add them to state service.
     *
     * Input Data: eDataId, entityId
     */
    public static getEntityHistory = new DiagramCommandEvent( 'GetEntityHistory' );
    protected static registerGetEntityHistory( mapper: CommandMapper ) {
        mapper.map( DiagramCommandEvent.getEntityHistory ).add( GetEntityHistory as any );
    }

    /**
     * Updates the display rules for the diagram
     */
     public static updateDisplayRules = new DiagramCommandEvent( 'UpdateDisplayRules' );
     protected static registerUpdateDisplayRules( mapper: CommandMapper ) {
         mapper.mapSequence( this.updateDisplayRules )
         .add( UpdateDisplayRules as any )
         .add( DocumentChange as any, {
             alter( progress ) {
                 return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
             },
         });

     }


    /**
     * Marks the given shape as a template shape
     *
     *  Input Data: {
     *      shapeIds?: [string],
     *      reset?: boolean, // resets everything
     *  }
     */
    public static markAsTemplateShape = new DiagramCommandEvent( 'MarkAsTemplateShape' );
    protected static registerMarkAsTemplateShape( mapper: CommandMapper ) {
        mapper.mapSequence( this.markAsTemplateShape )
            .add( MarkAsTemplateShape as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /*
     * Show or hide the connectors
     *
     *  Input Data: {
     *      hideConnector: boolean,
    *       selection?: boolean;
     *  }
     */
    public static toggleConnectorVisibility =
        new DiagramCommandEvent( 'ToggleConnectorVisibility' );
    public static toggleConnectorVisibilitySystem =
        new DiagramCommandEvent( 'ToggleConnectorVisibility', EventSource.SYSTEM );
    protected static registerToggleConnectorVisibility( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.toggleConnectorVisibility )
            .add( ToggleConnectorVisibility as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static openConvertToObjectDialog =
        new DiagramCommandEvent( 'OpenConvertToObject' );
    protected static registerOpenConvertToObjectDialog( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.openConvertToObjectDialog )
            .add( OpenConvertToObjectDialog as any );
    }

    /*
     * set template def data
     *
     *  Input Data: {
     *      templateId: string,
     *      templateName: string,
     *  }
     */
    public static setTemplateDef = new DiagramCommandEvent( 'SetTemplateDef' );
    protected static registerSetTemplateDef( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.setTemplateDef )
            .add( SetTemplateDef as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    // CheckTeamShareLimitation
    public static checkTeamShareLimitation = new DiagramCommandEvent( 'CheckTeamShareLimitation' );
    protected static registerCheckTeamShareLimitation( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.checkTeamShareLimitation )
            .add( CheckTeamShareLimitation as any );
    }

    public static convertToObject = new DiagramCommandEvent( 'ConvertToObject' );
    protected static registerConvertToObject( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.convertToObject )
            .add( ConvertToObject as any );
    }

    public static convertShapesToObjects = new DiagramCommandEvent( 'ConvertShapesToObjects', EventSource.SYSTEM );
    protected static registerConvertShapesToObjects( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.convertShapesToObjects )
            .add( ConvertShapesToObjects as any );
    }

    public static pasteAsItem = new DiagramCommandEvent( 'PasteAsItem', EventSource.SYSTEM );
    protected static registerPasteAsItem( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.pasteAsItem )
            .add( PasteAsItem as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static changeCollabsCursorsVisibility = new DiagramCommandEvent( 'ChangeCollabsCursorsVisibility' );
    protected static registerChangeCollabsCursorsVisibility( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.changeCollabsCursorsVisibility )
            .add( ChangeCollabsCursorsVisibility as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    public static startShapeVoting = new DiagramCommandEvent( 'StartShapeVoting' );
    protected static registerStartShapeVoting( mapper: CommandMapper ) {
       mapper.mapSequence( DiagramCommandEvent.startShapeVoting )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.CREATELY_VOTING_ACCESS;
                    return data;
                },
            })
           .add( StartShapeVoting as any, {
               transform( progress ) {
                   return progress.data[0];
               },
           })
           .add( DocumentChange as any, {
               alter( progress ) {
                   return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
               },
           });
    }

    public static updateShapeVoting = new DiagramCommandEvent( 'UpdateShapeVoting' );
    protected static registerUpdateShapeVoting( mapper: CommandMapper ) {
       mapper.mapSequence( DiagramCommandEvent.updateShapeVoting )
           .add( UpdateShapeVoting as any, {
               transform( progress ) {
                   return progress.data[0];
               },
           })
           .add( DocumentChange as any, {
               alter( progress ) {
                   return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
               },
           });
    }

    public static shapeVoteState = new DiagramCommandEvent( 'ShapeVoteState' );
    protected static registerShapeVoteState( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.shapeVoteState )
            .add( CheckPermissions as any, {
                transform( progress ) {
                    const data = { ...progress.eventData };
                    data.permission = PlanPermission.CREATELY_VOTING_ACCESS;
                    return data;
                },
            })
            .add( ShapeVoteState as any );
    }

    public static changeGridGuidesShowSnap = new DiagramCommandEvent( 'ChangeGridGuidesShowSnap' );
    protected static registerGridGuidesShowSnap( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.changeGridGuidesShowSnap )
            .add( ChangeGridGuidesShowSnap as any );
    }

    public static addShapeByDefId = new DiagramCommandEvent( 'AddShapeByDefId' );
    protected static registerAddShapeByDefId( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addShapeByDefId )
            .add( AddShapeByDefId as any );
    }

    public static addTemplate = new DiagramCommandEvent( 'AddTemplate' );
    protected static registerAddTemplate( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.addTemplate )
            .add( AddTemplate as any );
    }

    public static updatePresentation = new DiagramCommandEvent( 'UpdatePresentation' );
    protected static registerUpdatePresentation( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.updatePresentation )
            .add( UpdatePresentation as any );
    }

    public static exportPresentation = new DiagramCommandEvent( 'ExportPresentation' );
    protected static registerExportPresentation( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.exportPresentation )
            .add( ExportPresentation as any );
    }

    /**
     * Bulk upload images and add to entities
     */
    public static uploadEntityImages = new DiagramCommandEvent( 'UploadEntityImages' );
    protected static registerUploadEntityImages( mapper: CommandMapper ) {
        mapper.mapSequence( DiagramCommandEvent.uploadEntityImages )
            .add( ImportImage as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    const files = Object.values( data.files );
                    return { files };
                },
            })
            .add( UploadImages as any, {
                transform( progress ) {
                    const files = progress.resultData[0].files;
                    return { files };
                },
            })
            .add( AddEntityImages as any, {
                transform( progress ) {
                    const data = progress.eventData;
                    const files = progress.resultData[1].files;
                    const entities = Object.keys( data.files );
                    return { entities, files, edataModel: data.edataModel };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

}
