import { CUSTOM_SHAPE_LIB_ID_PREFIX } from './../../editor/library/abstract-shape-library';
import { Sakota, Proxied } from '@creately/sakota';
import { Injectable } from '@angular/core';
import { AbstractNotification, CommandService, IModifier, Logger,
    MapOf,
    ModalController,
    NotificationType, NotifierController, StateService } from 'flux-core';
import { EMPTY, forkJoin, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, tap, map, pairwise } from 'rxjs/operators';
import { DiagramLocatorLocator, ITypedDiagramLocator } from '../diagram/locator/diagram-locator-locator';
import { ShapeModel } from '../shape/model/shape.mdl';
import { EDataModel } from './model/edata.mdl';
import { isEqual, pick } from 'lodash';
import { DiagramCommandEvent } from '../../editor/diagram/command/diagram-command-event';
import { find as _find, filter as _filter } from 'lodash';
import { EDataCommandEvent } from './command/edata-command-event';
import { EDataLocatorLocator } from './locator/edata-locator-locator';
import { DiagramModel } from '../diagram/model/diagram.mdl';
import { EntityLinkType, IEDataDef } from 'flux-definition';
import { ConnectorModel } from '../shape/model/connector.mdl';
import { EDATAREF_CONNECTOR } from 'flux-diagram-composer';
import { ProjectLocator } from 'flux-diagram';
import { BaseDiagramCommandEvent } from '../diagram/command/base-diagram-command-event';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { TranslateService } from '@ngx-translate/core';
import { Notifications } from '../notifications/notification-messages';
import { SetupDatabaseDialog } from '../../editor/view/setup-database-dialog/setup-database-dialog.cmp';
import { DefinitionLocator } from '../shape/definition/definition-locator.svc';
import { ObjectConvertor } from '../../framework/edata/object-convertor.svc';
import { SmartSetService } from './smart-set.svc';
import { SETUP_DATABASE_STATE } from '../../editor/ui/library/edata/edata-container.cmp';

// tslint:disable:member-ordering
/**
 * This service connects canvas shapes to the edata services.
 *
 *
 * @since 2020-11-14
 * @author chandika
 */
@Injectable()
export class EDataService {


    /**
     * Currently loaded edata models indexed by their unique ID.
     * This is used for content suggest on the parser.
     * TODO: Need to review its usage
     */
    private projectEDataModels: { [id: string]: EDataModel } = {};

    private candidateNotified: { [diagramId: string]: { [defId: string]: boolean } } = {};

    constructor( private state: StateService<any, any>,
                 private ll: DiagramLocatorLocator,
                 private commandService: CommandService,
                 private ell: EDataLocatorLocator,
                 private pl: ProjectLocator,
                 private subManager: ModelSubscriptionManager,
                 private notifierController: NotifierController,
                 private translate: TranslateService,
                 private modalController: ModalController,
                 private defLocator: DefinitionLocator,
                 private objectConvertor: ObjectConvertor,
                 private smartSetSvc: SmartSetService,
                 ) {
                 }

// add a project edata level thing
// project models need to be loaded
// initialize should not trigger diagramChange
// entity model should show an eror when in 2 packages together.

    /**
     * Initializes and starts the eData service.
     */
    public initialize() {
        this.listenToDiagramChange().subscribe();
        this.listenToEDataCandidateShapes().subscribe();
        this.listenToProjectEDataChanges().subscribe();
    }

    public getEdataForCurrentDiagram(): Observable<EDataModel[]> {
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramEData().pipe(
                take( 1 ),
                switchMap( eDataList => {
                    if ( !eDataList ) {
                        return EMPTY;
                    }
                    return forkJoin( eDataList.map( eId => this.ell.getEData( eId ).pipe(
                        switchMap( eLocator => eLocator.getEDataModelOnce()),
                    )));
                }),
            )),
        );
    }

    protected updateShapeLibrary( eDataList: string[]) {
        const libs = [ ...this.state.get( 'CurrentLibraries' ) ];
        const preFix = CUSTOM_SHAPE_LIB_ID_PREFIX;

        // Remove the db from CurrentLibraries
        const removedIds = libs
            .filter( lib => lib.id.includes( preFix ))
            .map( lib =>  lib.id.split( preFix )[1])
            .filter( libId => !eDataList.find( id => id === libId ));

        removedIds.forEach( eDataId => {
            const index = libs.findIndex( lib => lib.id === preFix + eDataId );
            if ( index > -1 ) {
                libs.splice( index, 1 );
                this.state.set( 'CurrentLibraries', libs );
            }
        });
    }

    /**
     * Starts listening to changing diagrams.
     */
    private listenToDiagramChange(): Observable<any> {
        return this.state.changes( 'CurrentDiagram' )
            .pipe(
                filter( val => Boolean( val )),
                distinctUntilChanged(),
                switchMap( val => this.onDiagramChange( val )),
            );
    }

    /**
     * making sure edata created by collaborators are also subscribed.
     */
    private listenToProjectEDataChanges() {
        return this.pl.getCurrentProjectObservable().pipe(
            map( p => p.eData ),
            filter( eDataList => !!eDataList && eDataList.length > 0 ),
            distinctUntilChanged( isEqual ),
            tap(() => this.commandService.dispatch( BaseDiagramCommandEvent.startEDataSubscription )),
        );
    }

   /**
    * When a diagram changes, subscribe to newShape adds and edata changes
    * @param diagramId
    */
    private onDiagramChange( diagramId: string ) {
        this.migrateDataDefs( diagramId );
        return this.listenToDiagramEDataChanges( diagramId );
    }

    private migrateDataDefs( diagramId: string ) {
        this.subManager.getFutureSub( diagramId ).pipe(
            switchMap( sub => sub.status ),
            filter( status => status.subStatus === SubscriptionStatus.started ),
            take( 1 ),
            tap(() => this.commandService.dispatch( DiagramCommandEvent.migrateDataDefs )),
        ).subscribe();
    }

    /**
     * Listens to changes in any EData Entity or a shape with eData.
     * If either triggers calls the relevant handlers.
     * Bound to the current diagram
     * @param diagramId
     */
    private  listenToDiagramEDataChanges( diagramId: string ) {

        const smartSetUpdateSub = ( locator: ITypedDiagramLocator ) => locator.getDiagramEData().pipe(
            switchMap( eDataList => {
                if ( !eDataList ) {
                    return EMPTY;
                }
                const eSubs = [];
                eDataList.forEach( eId => {
                    const eSub = this.ell.getEData( eId ).pipe(
                        switchMap( eLocator => eLocator.getEDataChangesWithCurrent().pipe(
                            tap(({ model, source, modifier }) => {
                                if ( source === 'init' || source === 'insert' ) {
                                    this.smartSetSvc.updateEntityLists( model, diagramId );
                                }
                                const regex = /entityLists\.([^\.]+)\.entities/;
                                if ( source === 'change' && modifier.$set &&
                                    Object.keys( modifier.$set ).some( key => regex.test( key ))) {
                                    const keys = Object.keys( modifier.$set ).filter( key => regex.test( key ));
                                    keys.forEach( key => {
                                        const [ , entityListId ] = key.split( '.' );
                                        const smartSet = model.entityLists[entityListId];
                                        this.smartSetSvc.updateEntityList( smartSet, model, diagramId );
                                    });
                                }
                            }),
                        )),
                    );
                    eSubs.push( eSub );
                });
                return merge( ...eSubs );
            }),
        );
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => {
                const updateSelected = locator.getRemovedShapes().pipe(
                    tap( shapes => {
                        const selected = this.state.get( 'Selected' );
                        if ( selected.length > 0 && shapes.some( s => selected.includes( s.id ))) {
                            const shapeIds = shapes.map( s => s.id );
                            this.state.set( 'Selected', selected.filter( sId => !shapeIds.includes( sId )));
                        }
                    }),
                );
                // listen to edata models being added to the doc
                // make sure we listen to them
                const edataListSub = locator.getDiagramEData().pipe(
                    switchMap( eDataList => {
                        if ( !eDataList ) {
                            this.state.set( 'projectEDataModels', {});
                            return EMPTY;
                        }
                        this.updateShapeLibrary( eDataList );
                        const eSubs = [];
                        eDataList.forEach( eId => {
                            const eSub = this.ell.getEData( eId ).pipe(
                                switchMap( eLocator => eLocator.getEDataModel().pipe(
                                    tap( model => {
                                        this.projectEDataModels[ model.id ] = model;
                                        this.state.set( 'projectEDataModels', this.projectEDataModels );
                                    }),
                                )),
                            );
                            eSubs.push( eSub );
                        });
                        return merge( ...eSubs );
                    }),
                );

                const edataAddedSub = locator.getDiagramEData().pipe(
                    pairwise(),
                    tap(([ prev, curr ]) => {
                        const prevIds = prev || [];
                        const currIds = curr || [];
                        const addedIds = currIds.filter( id => !prevIds.includes( id ));
                        if ( addedIds.length > 0 ) {
                            // manually subscribing so that even though new edata is added, prior to
                            // subscription start still we convert the candidate shapes.
                            merge( ...addedIds.map( id => this.subManager.getFutureSub( id ).pipe(
                                switchMap( sub => sub.status ),
                                filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
                                take( 1 ),
                                switchMap(() => this.ell.getEData( id )),
                                switchMap( eLocator => eLocator.getEDataModelOnce()),
                                switchMap( model => this.objectConvertor.convertCandidateShapes( model )),
                            ))).subscribe();
                        }
                    }),
                );


                // TODO: Move to ShapeEdataChangeBinder
                const connSub = locator.getDiagramLinkChanges().pipe(
                    switchMap( change => locator.getDiagramOnce().pipe( map( diagram => ({ diagram, change })))),
                    switchMap(({ diagram, change }) =>
                        this.updateConnectorLink(
                            diagram,
                            change.connector as ConnectorModel,
                            change.modifier,
                            false,
                            change.fromShape as ShapeModel,
                            change.toShape as ShapeModel,
                        ),
                    ),
                );
                return merge( connSub, edataListSub, smartSetUpdateSub( locator ), edataAddedSub, updateSelected );
            }));
    }

    private listenToEDataCandidateShapes() {
        const translate = this.translate.instant.bind( this.translate );
        return this.state.changes( 'LastAddedEDataCandidateShape' ).pipe(
            filter( defId => !!defId ), // skipping initial empty value
            tap( defId => {
                if ( this.state.get( 'CurrentProject' ) === 'home' ) {
                    return;
                }
                const diagramId = this.state.get( 'CurrentDiagram' );
                if ( !this.candidateNotified[ diagramId ]) {
                    this.candidateNotified[ diagramId ] = {};
                }
                if ( this.candidateNotified[ diagramId ][ defId ]) {
                    return;
                }
                const notificationData = {
                    id: Notifications.SETUP_DATABASE,
                    component: AbstractNotification,
                    type: NotificationType.Neutral,
                    collapsed: false,
                    options: {
                        inputs: {
                            heading: translate( 'NOTIFICATIONS.EDATA.SETUP_DATABASE.HEADING' ),
                            description: translate( 'NOTIFICATIONS.EDATA.SETUP_DATABASE.DESCRIPTION' ),
                            autoDismiss: true,
                            dismissAfter: 15000,
                            buttonOneText: translate( 'NOTIFICATIONS.EDATA.SETUP_DATABASE.BUTTON_ONE_TEXT' ),
                            buttonTwoText: translate( 'NOTIFICATIONS.EDATA.SETUP_DATABASE.BUTTON_TWO_TEXT' ),
                            buttonOneAction: () => {
                                this.notifierController.hide( Notifications.SETUP_DATABASE );
                                // setup database
                                this.getCandidateEDataDefs( defId ).pipe(
                                    tap( defs => {
                                        this.modalController.show( SetupDatabaseDialog, {
                                            inputs: {
                                                defs: defs,
                                                shapeDefId: defId,
                                            },
                                        });
                                    }),
                                ).subscribe();
                            },
                            buttonTwoAction: () => {
                                this.notifierController.hide( Notifications.SETUP_DATABASE );
                                // learn more, probably a link opened in new tab
                                this.commandService.dispatch( DiagramCommandEvent.openConvertToObjectDialog, {
                                    learnMore: true,
                                });
                            },
                        },
                    },
                };
                this.candidateNotified[diagramId][ defId ] = true;
                this.ll.forCurrentObserver( false ).pipe(
                    take( 1 ),
                    switchMap( locator => locator.getDiagramEData()),
                    take( 1 ),
                    switchMap( eDataList => this.getCandidateEDataDefs( defId ).pipe(
                        map( defs => ({ defs, eDataList })),
                    )),
                    tap(({ defs, eDataList }) => {
                        const defIds = defs.map( d => d.defId );
                        const eDataModels: MapOf<EDataModel> = pick( this.projectEDataModels, eDataList );
                        const eModel = Object.values( eDataModels ).find( e => defIds.includes( e.defId ));
                        if ( eModel ) {
                            return;
                        }
                        this.state.set( 'SetupDatabaseState', SETUP_DATABASE_STATE.EXPANDED );
                        return this.notifierController.show( Notifications.SETUP_DATABASE, notificationData.component,
                            notificationData.type, notificationData.options, notificationData.collapsed );
                    }),
                ).subscribe();
            }),
        );
    }

    public getCandidateEDataDefs( shapeDefId ) {
        const getDef: ( defId: string ) => Observable<IEDataDef> =
            this.defLocator.getDefinition.bind( this.defLocator );
        return getDef( shapeDefId ).pipe(
            switchMap( def => forkJoin((( def as any ).eDataCandidates as string[]).map( defId => getDef( defId )))),
        );
    }

    /**
     * Returns a diagram model wrapped by sakota to record changes
     */
    public getDocumentChangeModel( resourceId: string ): Observable<Proxied<DiagramModel>> {
        const locator = this.ll.forDiagram( resourceId, false );
        return locator.getDiagramOnce().pipe(
            map( model => Sakota.create( model )),
        );
    }


    /**
     * Dispatch command to add the entity reference to the shape. Affects Document/Shape
     * @param item
     * @param eData
     * @param entity
     */
     private addEDataRefToShape( item: ShapeModel, eDataId: string, entity: string, isSet: boolean ) {
        return this.commandService.dispatch( DiagramCommandEvent.addEDataRefToShape, {
            shapeId: item.id,
            eDataId: eDataId,
            entityId: entity,
            isSet: isSet,
        });
    }


    /************************************************************************************************
     *                                      Link related functionality                              *
     ************************************************************************************************/


    /**
     * creates a link between entities when its a connector relationship.
     */
    private updateConnectorLink( diagram: DiagramModel, connector: ConnectorModel, modifier: IModifier,
                                 isSet: boolean, fromShape?: ShapeModel, toShape?: ShapeModel, force = false  ) {
        // if its just the path or arrowheads, we ignore it. the DefID has to change for us to consider it an eData
        // change. Or an $unset.
        if ( !force &&
            modifier.$set && Object.keys( modifier.$set ).filter( key => /defId/.test( key )).length === 0 ) {
            return EMPTY;
        }

        if ( !fromShape ) {
            if ( connector && connector.getFromEndpoint( diagram ) && connector.getFromEndpoint( diagram ).shape ) {
                fromShape = connector.getFromEndpoint( diagram ).shape;
            } else {
                Logger.error( 'malformed link update request', connector.id );
            }
        }
        if ( !toShape ) {
            if ( connector && connector.getToEndpoint( diagram ) && connector.getToEndpoint( diagram ).shape ) {
                toShape = connector.getToEndpoint( diagram ).shape;
            } else {
                Logger.error( 'malformed link update request', connector.id );
            }
        }
        if ( !connector.handshake || modifier.$unset ) {
            isSet = false;
        }

        const obs: Observable<any>[] = [];
        // i dont trust these emitters.
        if ( fromShape.eData && toShape.eData ) {
            for ( const fEdataId in fromShape.eData ) {
                const fEntityId = fromShape.eData[fEdataId];
                for ( const tEdataId in toShape.eData ) {
                    const tEntityId = toShape.eData[tEdataId];
                    const o = this.getMatchingHandshake(
                        fEdataId, fEntityId, tEdataId, tEntityId, connector.handshake ).pipe(
                        // take( 1 ),
                        tap( handshake => {
                                // update both sides of the link
                                this.commandService.dispatch( EDataCommandEvent.updateEntityLinks, fEdataId, {
                                    diagramId: diagram.id,
                                    fromEntityId: fEntityId,
                                    toEDataId: tEdataId,
                                    toEntityId: tEntityId,
                                    type: EntityLinkType.CONNECTOR_BI,
                                    isSet: isSet,
                                    handshake: handshake,
                                    connectorId: connector.id,
                                    connectorDefId: connector.defId,
                                    shapeId: toShape.id,
                                    // identifier: this.getIdentifier( toShape ),
                                });
                                this.commandService.dispatch( EDataCommandEvent.updateEntityLinks, tEdataId, {
                                    diagramId: diagram.id,
                                    fromEntityId: tEntityId,
                                    toEDataId: fEdataId,
                                    toEntityId: fEntityId,
                                    type: EntityLinkType.CONNECTOR_BI,
                                    isSet: isSet,
                                    handshake: handshake,
                                    connectorId: connector.id,
                                    connectorDefId: connector.defId,
                                    shapeId: fromShape.id,
                                    // identifier: this.getIdentifier( fromShape ),
                                });
                        }),
                    );
                    obs.push( o );
                }
            }
        } else {
            // this could be a reference if its between an entity and a shape
            // not checking the type above as when its unset, the type is default.
            const eDataShape = fromShape.eData ? fromShape : toShape;
            if ( !eDataShape.eData ) { // neither has edata, so cant create a reference
                return EMPTY;
            }
            const plainShape = fromShape.eData ? toShape : fromShape;

            for ( const fEdataId in eDataShape.eData ) {
                const fEntityId = eDataShape.eData[fEdataId];

                const identifier = this.getIdentifier( plainShape );
                // make a note on the shape about this reference
                this.addEDataRefToShape( plainShape, fEdataId, fEntityId, isSet );

                // just create/delete the connector to the side where the entity is.
                this.commandService.dispatch( EDataCommandEvent.updateEntityLinks, fEdataId, {
                    diagramId: diagram.id,
                    fromEntityId: fEntityId,
                    type: EntityLinkType.REFERENCE,
                    isSet: isSet,
                    handshake: EDATAREF_CONNECTOR.handshake[0],
                    connectorId: connector.id,
                    connectorDefId: connector.defId,
                    shapeId: plainShape.id,
                    shapeDefId: plainShape.defId,
                    identifier: identifier,
                });
            }
        }

        if ( obs.length ) {
            return merge( ...obs );
        }
        return EMPTY;
    }

    private getIdentifier( plainShape ) {
        if ( plainShape.primaryTextModel && plainShape.primaryTextModel.plainText ) {
            return plainShape.primaryTextModel.plainText;
        }
    }

    /**
     * When given 2 entities and a connector, returns a handshake that matches all.
     * @param fromEDataId
     * @param fromEntityId
     * @param toEDataId
     * @param toEntityId
     * @param handshake
     */
    private getMatchingHandshake(
        fromEDataId: string,
        fromEntityId: string,
        toEDataId: string,
        toEntityId: string,
        handshake: string[]): Observable<string|undefined> {

        return forkJoin({
            fromEntity: this.ell.getEntityOnce( fromEDataId, fromEntityId ),
            toEntity: this.ell.getEntityOnce( toEDataId, toEntityId ),
        },
        ).pipe(
            switchMap( ents => {
                if ( ents.fromEntity && ents.toEntity && handshake ) {
                    const fPortsList = ents.fromEntity.getDef().ports;
                    const tPortsList = ents.toEntity.getDef().ports;
                    if ( fPortsList && tPortsList ) {
                        const fPorts = Object.keys( fPortsList );
                        const tPorts = Object.keys( tPortsList );
                        for ( let i = 0; i < handshake.length; i++ ) {
                            if ( fPorts.indexOf( handshake[i]) > -1 && tPorts.indexOf( handshake[i]) > -1 ) {
                                return of( handshake[i]);
                            }
                        }
                    }
                }
                return of( undefined );
            }),
        );
    }

}
