import { AbstractDeleteDiagram } from 'flux-diagram';
import { Observable } from 'rxjs';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';

/**
 * This command is implemented by extending the DeleteDiagram command. It deletes
 * the diagram data from the cache and redirect to dashboard.
 *
 * data: {}
 */
@Injectable()
@Command()
export class DeleteDiagram extends AbstractDeleteDiagram {

    constructor(
                protected state: StateService<any, any>,
                protected dataStore: DataStore,
                protected modelSubManager: ModelSubscriptionManager,
                protected router: Router ) {
        super( dataStore, modelSubManager )/* istanbul ignore next */;
    }

    /**
     * Delete the diagram from cache and stops diagram subscription. It also
     * redirects to dashboard.
     */
    public executeResult( response: any ): Observable<any> {
        return super.executeResult( response ).pipe(
            tap(() => {
                if ( this.resourceId === this.state.get( 'CurrentDiagram' )) {
                    this.router.navigateByUrl( '/' ); // navigate to create page
                }
            }));
    }
}

Object.defineProperty( DeleteDiagram, 'name', {
    value: 'DeleteDiagram',
});
