import { Injectable } from '@angular/core';
import { ExecutionStep } from '../execution/execution-step';
import { CommandScenario } from '../command-scenario.enum';
import { CommandError } from '../error/command-error';
/**
 * This class maps a sequence of command execution steps to a particular
 * {@link CommandScenario}.
 * It provides the following functionality:
 *  Register a sequence of command steps against a particular {@link CommandScenario}
 *  When given a {@link CommandScenario}, return the sequence of {@link ExecutionStep}
 *  for the given scenario.
 *
 * @author Ramishka
 * @since 2017-02-14
 */
@Injectable()
export class CommandStepMapper {

    /**
     * This object holds the list of execution steps for a given command scenario.
     */
    protected executionSteps: {[ key: number ]: Array<typeof ExecutionStep> };

    /**
     * Command Scenario offline key. Any scenario which
     * needs to be executed while app is offline, needs to
     * register the scneario with this key as suffix.
     */
    private offlineKey = '_Offline';

    /**
     * Constructor.
     * This initializes the execution steps map.
     */
    constructor() {
        this.executionSteps = {};
    }

    /**
     * Returns true if the app is offline.
     * NOTE: In here the ConnectionStatus is not used because
     * state is not initialized at this moment.
     */
    private get isOffline(): boolean {
        return !window.navigator.onLine;
    }

    /**
     * This method returns the sequence of execution steps for a given command scenario.
     * @param scenario - The command scenario to retrieve steps for
     * @return array of execution steps
     */
    public getSequence( scenario: CommandScenario ): Array<typeof ExecutionStep> {
        // NOTE: If the app is offline, it will execute the steps for the offline scenario.
        // If the offline scenario is not presented, then it will fallback to normal execution
        // steps.
        if ( this.isOffline && this.executionSteps[ scenario + this.offlineKey]) {
            return this.executionSteps[ scenario + this.offlineKey ];
        }
        return this.executionSteps[ scenario ];
    }

    /**
     * This method registers a set of execution steps against a given command scenario.
     * If a steps list already exists for the same scenario, an error will be thrown.
     * @param scenario - the command scenario
     * @param steps - array of execution steps
     * @return none
     */
    public registerSequence( scenario: CommandScenario, steps: Array<typeof ExecutionStep> ) {
        if ( steps && steps.length > 0 ) {
            if ( !this.executionSteps[ scenario ]) {
                this.executionSteps[ scenario ] = steps;
            } else {
                throw new CommandError ( 'An execution steps list is already registered'
                + ' for the given command scenario' ) ;
            }
        }
    }

    /**
     * This method adds a execution step to the list of execution steps registered for a given command scenario.
     * If there was no existing list found for the command scenario, this function will create a list,
     * add the step to it and register the sequence.
     * @param scenario - command scenario
     * @param step - Execution step to be added to the command scenario
     */
    public addToSequence( scenario: CommandScenario, step: typeof ExecutionStep ) {
        if ( step ) {
            const steps: Array<typeof ExecutionStep> = this.executionSteps[ scenario ];
            if ( !steps ) {
                this.registerSequence( scenario, [ step ]);
            } else {
                steps.push( step );
            }
        }
    }
}
