import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { Command, CommandInterfaces, AppConfig, AbstractHttpCommand, StateService } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';

/**
 * This command will be fetch subscription data.this data will be calculated and fetch from creately api.
 * Some of subscription related data will get from chargebeeAPI.
 * @output This command will return the user's subscription data.
 * @author  Shashik
 * @since   2020-11-03
 */
@Injectable()
@Command()
export class FetchUserSubscriptionData extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IGraphqlRestCommand' ];
    }

    constructor( protected auth: NucleusAuthentication,
                 protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
    public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': this.auth.token,
        });
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'GRAPHQL_API_BASE_URL' );
    }

  /**
   * Command execute
   */
    public execute(): Observable<any> {
        // NOTE: Avoid executing any qraphql queries related to
        // user, if the CurrentUser state is undefined. Applicable
        // for viewing public diagrams without login into Creately app.
        if ( !this.state.get( 'CurrentUser' )) {
            return;
        }
        return EMPTY;
    }

    public prepareData(): any {
        this.data = {};
        const query = `{
            user{
              organization{
                subscription{
                    cancelScheduled,
                    cancelled,
                    extendSubOfferStart,
                    extendSubOfferExpired,
                    extendSubOfferClaim,
                    paymentFailedCount,
                    planId,
                }
              }
            }
        }`;
        this.data.query =  query ;
    }
}

Object.defineProperty( FetchUserSubscriptionData, 'name', {
    value: 'FetchUserSubscriptionData',
});
