import { Injectable } from '@angular/core';
import { Command, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { UserModel } from 'flux-user';
import { combineLatest, Observable } from 'rxjs';
import { AbstractPluginCommand } from './abstract-plugin-cmd';

/**
 * Fetches user data from the server and saves it in indexed db.
 * When calling this, please set the resourceId to the user's id.
 */
@Injectable()
@Command()
export class LoadUser extends AbstractPluginCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ ...AbstractPluginCommand.implements, 'IUserCommand' ];
    }
    /**
     * Inject the data store service for local cache.
     */
    constructor( private store: DataStore ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Store the user info in the datastore.
     */
    public executeResult(): Observable<unknown> {
        const store = this.store.getModelStore( UserModel );
        return combineLatest(
            store.insert( this.resultData.model ),
            // NOTE: uncomment if there are static data for user model
            // store.insertStatic( this.resultData.modelStatic ),
        );
    }
}

Object.defineProperty( LoadUser, 'name', {
    writable: true,
    value: 'LoadUser',
});
