import { Random } from 'flux-core';
import { AbstractModelFactory } from 'flux-diagram-composer';
import { Observable, of } from 'rxjs';
import { EntityModel } from './entity.mdl';

/**
 * This is the stateless factory for creating all types of Entity Models. Any model
 * created must be of the type EntityModel or anything that extends that.
 *
 * This creates the models and merges the definitions into the model. This manages the
 * definition creation and conversion to accessible types. This does not manage Entity
 * model data. Does not have knowledge of ths Entity Model composition but only of the
 * IEntityDef.
 */
export class EntityModelFactory extends AbstractModelFactory {
    /**
     * The singleton instance of the EntityModelFactory.
     */
    public static get instance(): EntityModelFactory {
        if ( !this._instance ) {
            this._instance = new EntityModelFactory();
        }
        return this._instance;
    }

    protected static _instance: EntityModelFactory;

    /**
     * Factory function to create an entity from a definition id. Fetches the
     * definiton as per id and version and creates the Model as per the definition.
     * For certain shape types, a logic class is used to make decisions when updating
     * the shape model. If a shape has a logic class associated with it, its merged
     * to the model during creation.
     * @param defId The definition id for the def to use
     * @param version The version of the definition to use
     * @param type The type of the ShapeModel expected which is or extends EntityModel
     * @param id The unique shape id for the shape. If not given, will be generated.
     */
    public createByDef(
        defId: string,
        version: number,
        type: typeof EntityModel = EntityModel,
        id?: string,
        entityDefId?: string,
    ): Observable<EntityModel> {
        id = !id ? Random.entityId() : id;
        return of( new type( id, entityDefId ));
    }

    /**
     * Factory function to create a shape from a saved shape data. Fetches the
     * definiton as per id and version in the object, and creates the Model as per the definition.
     *
     * Important: This method only creates the Shape Model and does not merge the values from the
     * given data object into the model. It simply uses following fiels from the data.
     *      - defId
     *      - version
     *      - id
     *
     * @param data The shape data that was stored. Expected to have defId and version props
     * @param type The type of the ShapeModel expected which is or extends EntityModel
     */
    public createByData(
        data: any, type: typeof EntityModel = EntityModel,
    ): Observable<EntityModel> {
        return this.createByDef( data.defId, data.version, type, data.id, data.eDefId );
    }

}
