import { CommandMapper, CommandEvent } from 'flux-core';
import { GetGlobalNotifications } from './get-global-notifications.cmd';
import { MarkGlobalNotificationRead } from './mark-global-notification-read.cmd';
import { NewGlobalNotification } from './new-global-notification.cmd';

/**
 * This class holds all events that are defined for invoking notification commands.
 * An event can associate one or more commands.
 *
 * @author  rasekaran
 * @since   2021-10-14
 */
// tslint:disable: member-ordering
export class NotificationCommandEvent extends CommandEvent {

    public static newGlobalNotification: NotificationCommandEvent = new NotificationCommandEvent( 'NewGlobalNotification' );
    public static getGlobalNotifications = new NotificationCommandEvent( 'GetGlobalNotifications' );
    public static markGlobalNotificationRead = new NotificationCommandEvent( 'MarkGlobalNotificationRead' );

    /**
     * Register an event to a sequence of commands or parallel of commands
     * @param   mapper  CommandMapper instance which maps the commands to the
     *                  desired event
     */
    public static register( mapper: CommandMapper ) {
        mapper.map( NotificationCommandEvent.newGlobalNotification ).add( NewGlobalNotification as any );
        mapper.map( NotificationCommandEvent.getGlobalNotifications ).add( GetGlobalNotifications as any );
        mapper.map( NotificationCommandEvent.markGlobalNotificationRead ).add( MarkGlobalNotificationRead as any );
    }
}
