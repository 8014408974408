import { Observable } from 'rxjs';
import { switchMap, delay } from 'rxjs/operators';
import { StateService, Command } from 'flux-core';
import { Injectable } from '@angular/core';
import { StartMovingSelection } from './start-moving-selection.cmd';

/**
 * Starts the move transformation of the shape. Waits for the
 * selection to be available and then initates the movement. Requires the shape
 * id for the command to function.
 */
@Injectable()
@Command()
export class StartMovingShape extends StartMovingSelection {

    public static get dataDefinition(): {}  {
        return {
            shapeId: true, // The id of the shape which we need to start moving.
            center: false, // Specify whether the center of the shape should be the move starting point.
        };
    }

    constructor( state: StateService<any, any> ) {
        super( state ) /* istanbul ignore next */;
    }

    public get asynchronous(): boolean {
        return true;
    }

    public execute (): Observable<any> {
        return this.waitForConnectorSelection( this.data.shapeId ).pipe(
                switchMap( selection => selection.startShapeMovement( this.data.center )),
                /**
                 * FIXME : This delay is added because there's race condition between plus create end click
                 * and the selecte shape click, shape select event should be emitted before the plus create ends.
                 * so that it can be ommited
                 */
                delay( 200 ),
        );
    }

}

Object.defineProperty( StartMovingShape, 'name', {
    value: 'StartMovingShape',
});
