import { Injectable } from '@angular/core';
import { AbstractMessageCommand } from 'flux-connection';
import { Command, CommandInterfaces } from 'flux-core';
import { Observable } from 'rxjs';
import { INotification } from '../../base/ui/global-notification/global-notification-notifications/global-notification-notifications.cmp';
import { GlobalNotificationService } from '../global-notification.svc';

/**
 * MarkGlobalNotificationRead
 * Mark notifications as seen and updates global notification status.
 */
@Injectable()
@Command()
export class MarkGlobalNotificationRead extends AbstractMessageCommand {

    public static get dataDefinition(): {}  {
        return {
            notificationIds: true,
        };
    }

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IMessageCommand' ];
    }

    constructor( protected globalNotificationService: GlobalNotificationService ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Updates notification seen status on global notification state data and sends it to server.
     * @returns
     */
    public execute(): Observable<INotification[]> | boolean {
        if ( this.data.notificationIds && this.data.notificationIds.length > 0 ) {
            return this.globalNotificationService.markGlobalNotificationRead( this.data.notificationIds, true );
        }
        return false;
    }

    /**
     * Revert global notification seen status if the update failed.
     * @returns
     */
    public revert(): Observable<any> {
        const notificationIds = this.data.notificationIds;
        return this.globalNotificationService.markGlobalNotificationRead( notificationIds, false );
    }
}

Object.defineProperty( MarkGlobalNotificationRead, 'name', {
    value: 'MarkGlobalNotificationRead',
});
