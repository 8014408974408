import { Injectable } from '@angular/core';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from 'apps/nucleus/src/base/edata/locator/edata-locator-locator';
import { DefinitionLocator } from 'apps/nucleus/src/base/shape/definition/definition-locator.svc';
import { AbstractCommand, Command, CommandService, Rectangle, StateService } from 'flux-core';
import { IShapeDefinition, ShapeType } from 'flux-definition';
import { ModelSubscriptionManager } from 'flux-subscription';
import { of } from 'rxjs';
import { last, switchMap, tap } from 'rxjs/operators';
import { ShapeManageService } from '../../feature/shape-manage.svc';
import { DiagramCommandEvent } from './diagram-command-event';

/**
 * Adds an entity to a shape.
 * Also checks if the document has a reference to the model, if not adds that as well.
 *
 * This usually happens in one of the follwing situations
 * 1. an entity is drag dropped in to the canvas and a shape is created from that
 * entity
 *
 * 2. a eDataTrigger=true shape is dropped to the canvas and an edataModel is active
 *
 * 3. a shape with eData present is added to a container context which will add additional parameters
 * to the containing shape dataItems which in turn will reflect on an EData model. This is the situation when
 * a single shape will have multiple EData references.
 *
 * 4. A new entity type is created. We create a new entity and bind it to the shape that the
 * type created from.
 *
 */
@Injectable()
@Command()
export class AddEntityToWorkspace extends AbstractCommand {
    /**
     * Command input data format
     */
    public data: {
        eDataId: string,
        entityId: string,
    };

    /**
     * Inject state service.
     */
    constructor(
        protected ell: EDataLocatorLocator,
        protected ll: DiagramLocatorLocator,
        protected modelSubManager: ModelSubscriptionManager,
        protected commandSvc: CommandService,
        protected state: StateService<any, any>,
        protected defLocator: DefinitionLocator,
        protected shapeManage: ShapeManageService,
    ) {
        super() /** istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public execute() {
        return this.ell.getEntityOnce( this.data.eDataId, this.data.entityId ).pipe(
            switchMap( entity => {
                /* if ( Object.keys( entity.shapes ).length > 10000 ) {
                    // clone shape
                    const diagramId = Object.keys( entity.shapes )[0];
                    const diagramSub = this.modelSubManager.get( diagramId );
                    const subStarted = diagramSub && diagramSub.status.value.subStatus === SubscriptionStatus.started;
                    this.modelSubManager.start( DiagramSub, diagramId );
                    return this.modelSubManager.getFutureSub( diagramId ).pipe(
                        switchMap( sub => sub.status ),
                        filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
                        take( 1 ),
                        switchMap(() => forkJoin([
                            this.getDiagramLoctor( diagramId ).getRawDiagramDataOnce(),
                            this.getDiagramLoctor( diagramId ).getDiagramOnce(),
                        ])),
                        map(([ diagramRaw, diagram ]: [ any, DiagramModel ]) => {
                            const dataDefs = {};
                            const shapeData = diagramRaw.shapes[Object.keys( entity.shapes[diagramId])[0]];
                            const type = diagram.shapes[shapeData.id].type;
                            const bounds = diagram.getBounds([ shapeData.id ]);
                            const viewPort: Rectangle = this.state.get( 'DiagramViewPort' );
                            const centerX = viewPort.centerX - bounds.width / 2;
                            const centerY = viewPort.centerY - bounds.height / 2;
                            shapeData.x = centerX;
                            shapeData.y = centerY;
                            if ( shapeData.entityDefId && diagram.dataDefs[ shapeData.entityDefId ]) {
                                dataDefs[ shapeData.entityDefId ] = diagram.dataDefs[ shapeData.entityDefId ];
                            }
                            if ( shapeData.dataSetId && diagram.dataDefs[ shapeData.dataSetId ]) {
                                dataDefs[ shapeData.dataSetId ] = diagram.dataDefs[ shapeData.dataSetId ];
                            }
                            return {
                                shapeIds: [ shapeData.id ],
                                selected: false,
                                copyEData: true,
                                shapesToCopy: [{ data: shapeData, bounds, type }],
                                dataDefs: cloneDeep( dataDefs ),
                            };
                        }),
                        tap( data => {
                            this.commandSvc.dispatch( DiagramCommandEvent.pasteShapes, {
                                externalData: data,
                            });
                            this.ll.destroyById( diagramId );
                            if ( !subStarted ) {
                                this.modelSubManager.stop( diagramId );
                            }
                        }),
                    );
                } */
                this.commandSvc.dispatch( DiagramCommandEvent.addEDataModel, {
                    eDataModelId: this.data.eDataId,
                }).pipe(
                    last(),
                    switchMap(() => {
                        const def = entity.getShapeDefIdForContext();
                        return this.defLocator.getDefinition( def.id );
                    }),
                    tap(( def: IShapeDefinition ) => {
                        const data = Object.assign({}, def );
                        data.eData = {};
                        data.entityDefId = entity.eDefId;
                        data.eData[ this.data.eDataId ] = entity.id;
                        if ( !data.texts ) {
                            const textData = entity.texts.find( t => t.shapeDef === def.defId );
                            if ( textData && textData.text ) {
                                data.texts = { [textData.text.id]: textData.text };
                            }
                        }
                        ( data as any ).sidebarEntity = entity;
                        const eDataDef = entity.getDef();
                        if ( eDataDef.defaultShape ) {
                            data.drawCode = eDataDef.defaultShape.drawCode;
                            ( data as any ).textStyle = eDataDef.defaultShape.texts;
                        }
                        data.triggerNewEData = true;
                        if ( data.texts ) {
                            // Clearing the default text coming fro the def but keep the formatting
                            Object.keys(  data.texts ).forEach( id => {
                                const text = data.texts[ id ];
                                text.content.forEach( c => c.text = '\n' );
                            });
                        }
                        if ( data.drawCode ) {
                            data.type = ShapeType.Freehand;
                        }
                        const viewPort: Rectangle = this.state.get( 'DiagramViewPort' );
                        const centerX = viewPort.centerX - ( def.defaultBounds?.width || 0 ) / 2;
                        const centerY = viewPort.centerY - ( def.defaultBounds?.height || 0 ) / 2;
                        this.shapeManage.addShape( data, centerX , centerY, entity ).subscribe();
                    }),
                ).subscribe();
                return of( null );
            }),
        );
    }

    /* private getDiagramLoctor( diagramId: string ) {
        return this.ll.forDiagram( diagramId, false ) as StoredDiagramLocator<DiagramModel, any>;
    } */
}

Object.defineProperty( AddEntityToWorkspace, 'name', {
    value: 'AddEntityToShape',
});
