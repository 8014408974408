/* istanbul ignore file */

import { AbstractModel, DateFNS } from 'flux-core';
import { find, remove } from 'lodash';

/**
 * Status for the task.
 */
export enum TaskStatus {
    Active = 'active', // task is now active. you should work on it
    Next = 'next', // task is not active.
    Blocked = 'blocked', // its blocked
    Optional = 'optional', // optional task.
    Later = 'later', // later task.
    Completed = 'completed', // Completed task
}

/**
 * Tracks the status of a given owner
 */
export interface IRoleStatus {
    userId: string; // this is a CreatelyUID

    /**
     * status assigned by this owner. the grouping of
     * now / next / skip etc.
     */
    status?: string;

    /**
     * Time this user last changed the status of the task.
     */
    lastUpdated: number;

    /**
     * User marked it as complete
     */
    isComplete?: boolean;

}

/**
 * The task model represents a task to be done by a creately user
 * Tasks are created when any item (shape / entity) gets an assignee.
 * All tasks can be 'completed'.
 * Tasks are linked to an item.
 */
export class TaskModel extends AbstractModel {

    /**
     * The ID for the task.
     */
    public id: string;

    /**
     * The Role ID for the task. ie. the dataItemID of the role field that owns the task
     * OR the checklist line ID of the task
     */
    public roleId: string;

    /**
     * Is this task active for the defined role
     */
    public isActive: boolean = true;

    /**
     * The owners of the task and their respective statuses
     */
    public roles: IRoleStatus[] = [];


    /**
     * The sourceId of the task. This can be from creately or
     * external services like github, asana etc.
     * This maps to the entity, however is retained here for
     * easy fetching.
     * Used to show the icon / type in the task tile.
     */
    public sourceId: string;

    /**
     * The title text. The main payload the task.
     * Same as the primary text field/name of the entity/shape if its
     * an assignment or the text in the checklist item.
     *
     * Note: this is duplicate data, needs to be updated when the parent changes.
     */
    public title: string;

    /**
     * The folder the diagram, this task is belonging to.
     * In case of no folder or diagram, ie. coming from another system
     * will be indicated in the default list.
     */
    public folderId?: string;

    /**
     * The id of the diagram the task belongs to.
     */
    public diagramId?: string;

    /**
     * If this is from a shape (that has no entity to it),
     */
    public shapeId?: string;

    /**
     * If the task is from an entity, the eDataModel for the entity
     */
    public edataId?: string;

    /**
     * The entityId of the task
     */
    public entityId?: string;

    /**
     * if the task is part of a checklist, the checklist item ID
     * from the original text area.
     */
    public checklistId?: string;

    /**
     * If there is a parent taskId for this item
     */
    public parentId?: string;

    /**
     * If there is a parent, the shape/entities title
     * This needs to be synced with the parent changes.
     */
    public parentTitle?: string;


    /**
     * The id of the user who creared the task.
     * This can be created via an external system so this field may not always
     * be populated.
     */
    public creatorId?: string;


    /**
     * Status of the task
     */
    public status: TaskStatus;

    /**
     * Does one owner completing the task mean the entire task
     * is completed?
     *
     * If not, all owners must mark the task complete for it to be
     * completed.
     * Default is false.
     */
    public anyComplete?: boolean;


    /**
     * If there is a due date
     */
    public dueDate?: number;


    /**
     * If there is an estimate
     */
    public estimateHrs?: number;


    /**
     * If there is an estimate
     */
    public estimatePts?: number;


    /**
     * The timestamp when the task was created.
     */
    public added: number;

    /**
     * The timestamp when the `task` property was last changed. Only available after
     * the first change so it can be used to track whether a task has been edited or not.
     */
    public edited?: number;

    /**
     * The timestamp when the `task` is deleted by the task owner. Only available after
     * the the task is deleted so it can be used to track whether the task is deleted.
     */
    public deleted?: number;

    /**
     * The timestamp when the task was completed.
     */
    public completed?: number;

    /**
     * The id of the user who marked it as complete.
     */
    public completedBy?: string;

    /**
     * This property should be updated when there are any changes to the task model.
     * NOTE: This property is different from `edited` and serves a different purpose.
     */
    public lastUpdated: number;


    /**
     * Tracks who has read the task. Only tracks collaborators.
     */
    public seenBy: string[];


    /**
     * Indicates whether the task is resolved.
     */
    public isCompleted(): boolean {
        return !!this.completed;
    }

    /**
     * Indicates whether the task is deleted.
     */
    public isDeleted(): boolean {
        return !!this.deleted;
    }

    /**
     * Indicates whether the task text is modified.
     */
    public isEdited(): boolean {
        return !!this.edited;
    }

    /**
     * Indicates whether the task text is modified.
     */
    public isInactive(): boolean {
        return !this.isActive || this.status === TaskStatus.Blocked;
    }

    /**
     * Indicates whether the task is added recently
     * or unread by the user.
     */
    public isNew( userId: string ): boolean {
        if ( this.isCompleted() || this.creatorId === userId || ( this.seenBy && this.seenBy.includes( userId ))) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns the time difference between the current time
     * and task added time.
     */
    public getTime(): string {
        return `${DateFNS.formatDistance( this.added, new Date().getTime())}`;
    }

    public getDueDateFormatted() {
        if ( !this.dueDate ) {
            return '';
        }
        return `${DateFNS.format( this.dueDate, 'd LLL' )}`;
    }

    public isPassedDueDate() {
        if ( !this.dueDate ) {
            return false;
        }
        return this.dueDate < Date.now();
    }

    /**
     * Adds a user to this task
     * @param ownerId
     */
    public addUser( ownerId: string ) {
        if ( !find( this.roles, role => role.userId === ownerId )) {
            this.roles.push({
                userId: ownerId,
                lastUpdated: Date.now(),
            });
        }
    }

    /**
     * Removes a user from this task
     * @param ownerId
     */
    public removeUser( ownerId: string ) {
        remove( this.roles, role => role.userId === ownerId );
    }

    /**
     * Sets this task's complete status.
     * If this is done by a role user, you mark that as complete as well.
     * @param userId
     */
    public setComplete( userId: string, isComplete: boolean ) {
        const owner: IRoleStatus = find( this.roles, role => role.userId === userId );
        if ( owner ) {
            owner.isComplete = isComplete;
        }
        if ( isComplete ) {
            if ( this.anyComplete || ( !find( this.roles, role => !role.isComplete ))) {
                this.completed = Date.now();
                this.completedBy = userId;
            }
        } else if ( !this.anyComplete || ( !find( this.roles, role => !role.isComplete ))) {
            delete this.completed;
            delete this.completedBy;
        }
    }

    public setRoleStatus( userId: string, status: TaskStatus ) {
        const owner: IRoleStatus = find( this.roles, role => role.userId === userId );
        if ( owner ) {
            owner.status = status;
        }
    }
}

Object.defineProperty( TaskModel, 'name', {
    writable: true,
    value: 'TaskModel',
});
