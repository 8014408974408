
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EDataLibraryItem } from './edata-library-item.cmp';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edata-library-smartset-item',
    templateUrl: './edata-library-smartset-item.cmp.html',
    styleUrls: [ './edata-library-item.scss' ],
})

export class EdataSmartSetLibraryItem extends EDataLibraryItem {

    @Output() public titleChanged = new EventEmitter<string>();
    @Output() public deleteSmartSet = new EventEmitter<string>();

    public editing: BehaviorSubject <boolean> = new BehaviorSubject( false );


    public changeSmartSetName( name: string ) {

    }

    handleDragStart() {
        this.state.set( 'ImageOverlay', 'entity-group-import' );
    }

    public handleDragEnd() {
        this.state.set( 'ImageOverlay', '' );
    }
}
