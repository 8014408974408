import { ErrorHandler, Injectable } from '@angular/core';
import { Logger } from '../logger/logger.svc';
import { Subject, Observable, fromEvent } from 'rxjs';
import { StateService } from '../controller/state.svc';
import { Tracker } from '../tracker/tracker';
import { OTelManager } from '../logger/otel-manager';

/**
 * Error message handlers can handle specific errors based on the type and
 * the data in the errors. These handlers should be added to AppErrorHandler
 * to handle the errors.
 * This shoudl return true if the error is ahdned by this handler otherwise
 * it shiuld return true so that app error handler can deside to conntrnue
 * to process the error or not.
 */
export interface IErrorMessageHandler {
    /**
     * Handle method will be called to handle the error message. If the
     * message is handled by this handler then returns true otherwise returns
     * false.
     */
    handle( error: Error ): boolean;
}
/**
 * The app global error handler which will be used to handle and manage
 * errors at teh application level. At this point there is no clear strategy
 * to how this must be done.
 */
@Injectable()
export class AppErrorHandler implements ErrorHandler {

    /**
     * A subject that is used to emit the error content when
     * an error occurs in the application. When handleError()
     * is called, the new error message will be emitted by the subject.
     */
    protected errorContent: Subject<Error>;

    /**
     * List of error message handlers. All the errors captured by this
     * app erro handler will be sent this handles one by one  to handle
     * them.
     */
    protected handlers: IErrorMessageHandler[];

    constructor( private log: Logger, protected state: StateService<any, any> ) {
        this.errorContent = new Subject();
        // this.initDataDog();
        OTelManager.initialize();
        this.startSessionTracking();
        this.handlers = [];
    }

    /**
     * A getter that returns an observable containing the
     * error content.
     */
    public get errorStream(): Observable<Error> {
        return this.errorContent;
    }

    /**
     * Add a handler which can handle specific errors. This handler will be
     * added to handler list. It will be executed to handle errors whenever
     * errors are capyured by this app error handler.
     * @param handler
     */
    public addErrorMessageHandler( handler: IErrorMessageHandler ) {
        this.handlers.push( handler );
    }

    /**
     * Error handle provided by Angular ErrorHandler which captures the app
     * errors and suppresses them at the global level. We have to manage the
     * error properly at this point and ensure proper measures are taken.
     *
     * @param error The error throw.
     */
    public handleError( error: Error ) {
        for ( const handler of this.handlers ) {
            if ( handler.handle( error )) {
                // Handled errors should be logged as information.
                this.log.info( error );
                return;
            }
        }
        // Only unhandled errors should be logged as Error.
        this.log.unhandledError( error );
        this.errorContent.next( error );
    }

    /**
     * Record the app load event and sets up tracking for app exit event.
     * FIXME: This function does not belong here. Added since this is an entry point
     * of the application. Move this to a more relevant place.
     */
    protected startSessionTracking() {
        Tracker.trackLoad();
        fromEvent( window, 'beforeunload' ).subscribe(() => {
            Tracker.trackExit();
        });
    }
}
