/**
 * A map of flags available in the app.
 */
interface IAvailableFlags {
    DEBUG_COMMAND_PROGRESS: boolean;
    DEBUG_APP_TRACKING: boolean;
    DEBUG_STORAGE_CHANGES: boolean;
    DEBUG_STATE_CHANGES: boolean;
    DEBUG_OBSERVER_COUNTS: boolean;
    ENABLE_APP_ANIMATIONS: boolean;
    DEBUG_FRAME_RATE: boolean;
    DEBUG_PHOTON_PREVIEW_SYNC_EMIT: boolean;
    DEBUG_PHOTON_PREVIEW_SYNC_RECEIVE: boolean;
    DEBUG_PHOTON_POINTER_MOVE: boolean;
    DEBUG_ERROR_NOTIFICATIONS: boolean;
    LOG_COLLECTOR_ENABLED: boolean;
    LOG_PREVIEW_CHANGES: boolean;
}

/**
 * Key used to store config in storage.
 */
// const STORAGE_KEY = 'creately_flags';

/**
 * Flags class can be used to access feature flags of the app.
 * FIXME: Localstorage related functionality is desabled due to limitations
 * on NodeJs where window is not available. We can use JsDom to polyfill the
 * requirements but on a serverless environment bundling JSDom is unnecessary.
 * - NIROSHANA -
 */
export class Flags {

    /**
     * Initialize Flags class with given set of values.
     */
    public static init( values: Partial<IAvailableFlags> ): void {
        for ( const key in values ) {
            this.values[key] = values[key];
        }
    }

    /**
     * Returns whether a feature flag is enabled or not.
     */
    public static get( key: keyof IAvailableFlags ): boolean {
        return this.values[key];
    }

    /**
     * Enables or disables a feature flag, will be stored.
     */
    public static set( key: keyof IAvailableFlags, val: boolean ): void {
        this.values[key] = val;
        // window.localStorage.setItem( STORAGE_KEY, JSON.stringify( this.values ));
    }

    /**
     * Currently set flags will be stored in this map.
     */
    private static values: IAvailableFlags = {
        DEBUG_COMMAND_PROGRESS: false,
        DEBUG_STORAGE_CHANGES: false,
        DEBUG_APP_TRACKING: false,
        DEBUG_STATE_CHANGES: false,
        DEBUG_OBSERVER_COUNTS: false,
        ENABLE_APP_ANIMATIONS: true,
        DEBUG_FRAME_RATE: false,
        DEBUG_PHOTON_PREVIEW_SYNC_EMIT: true,
        DEBUG_PHOTON_PREVIEW_SYNC_RECEIVE: false,
        DEBUG_PHOTON_POINTER_MOVE: true,
        DEBUG_ERROR_NOTIFICATIONS: false,
        LOG_COLLECTOR_ENABLED: false,
        LOG_PREVIEW_CHANGES: false,
    };
}

/**
 * Initialize the Flags class with values from local storage.
 */
// Flags.init( JSON.parse( window.localStorage.getItem( STORAGE_KEY )) || {});
