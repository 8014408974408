import { Injectable } from '@angular/core';
import { AbstractCommand, Command } from 'flux-core';
import { DataStore } from 'flux-store';
import { CommentSubscriptionService } from '../subscription/comment-subscription.svc';
import { CommentSub } from '../subscription/comment.sub';

/**
 * Starts the comment subscription for the current diagram.
 */
@Injectable()
@Command()
export class StartDiagramCommentSubscription extends AbstractCommand {
    constructor(
        protected commentSub: CommentSubscriptionService,
        protected datastore: DataStore,
    ) {
        super()/* istanbul ignore next */;
    }

    public execute() {
        const sub = new CommentSub( this.resourceId, this.datastore );
        this.commentSub.start( sub );
        return true;
    }
}

Object.defineProperty( StartDiagramCommentSubscription, 'name', {
    value: 'StartDiagramCommentSubscription',
});
