import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';

@Injectable()
/**
 * This service can be used to dynamically load js during runtime.
 * JS is loaded via a HTMLScriptElement which is added to the body.
 * The script will be executed immediately after its fetched.
 * Once the script has loaded (and executed), the HTMLScriptElement
 * is removed from the body.
 *
 * @author  Ramishka
 * @since   2017-09-12
 */
export class ScriptLoader {

    /**
     * Constructor
     */
    constructor () {
    }

    /**
     * This function loads a given script by its url.
     * It loads the script, executes it and then removes it from the
     * document body.
     * @param src url of the script to load
     */
    public load( src: string ): Observable<string> {
        return new Observable<string>(( observer: Observer<string> ) => {
            const scriptElement: HTMLScriptElement = this.createScriptElement( src );
            scriptElement.onload = () => {
                document.body.removeChild( scriptElement );
                observer.next( src  );
                observer.complete();
            };
            scriptElement.onerror = ( error: any ) => {
                observer.error( 'Script loading failed for ' + src );
            };
            document.body.appendChild( scriptElement );
        });
    }

    /**
     * This function creates and returns a HTMLScriptElement.
     * @param src source to set for the HTMLScriptElement
     */
    protected createScriptElement( src: string ): HTMLScriptElement {
        const scriptElement: HTMLScriptElement = document.createElement( 'script' );
        scriptElement.type = 'text/javascript';
        scriptElement.src = src;
        return scriptElement;
    }
}
