import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { Command, CommandInterfaces, AbstractHttpCommand, StateService, RequestMethod, AppConfig } from 'flux-core';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
@Command()
export class SearchDiagram extends AbstractHttpCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [ 'IHttpCommand' ];
    }

    constructor(
        protected auth: NucleusAuthentication,
        protected state: StateService<any, any> ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Http Headers required for this API to be called.
     */
     public get httpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'creately-gravity-token': this.auth.token,
        });
    }

    public get httpMethod(): RequestMethod {
        return RequestMethod.Get;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get( 'CREATELY_SEARCH_BASE_URL' ) + '/search';
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        if ( !this.state.get( 'CurrentUser' )) {
            return;
        }
        return EMPTY;
    }
}

Object.defineProperty( SearchDiagram, 'name', {
    value: 'SearchDiagram',
});
