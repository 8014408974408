import * as md5 from 'md5';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig, Deserializer, MapOf, ResourceLoader } from 'flux-core';
import { DefinitionLocator } from '../../base/shape/definition/definition-locator.svc';
import { ShapeLibrary } from './shape-library';
import { ConnectorRegistry } from '../../base/shape/definition/connector-registry.svc';
import { IConnectorDefinition, IAbstractDefinition } from 'flux-definition';

/**
 * This is a loader service that can be used to load the staic library
 * from the serveing location.
 *
 * @since   2017-11-16
 * @author  gobiga
 */

interface ILibHash {
    libId: string;
    hash: string;
}

@Injectable()
export class StaticLibraryLoader {

    private libraryHashes: Observable<MapOf<string>>;

    constructor(
        protected resourceLoader: ResourceLoader,
        protected defLocator: DefinitionLocator,
        protected connectorRegistry: ConnectorRegistry,
    ) {}

    /**
     * URL to access libraries
     */
     protected get libraryLocation(): string {
        return AppConfig.get( 'RESOURCE_LOCATION' ) + AppConfig.get( 'LIBRARY_RESOURCE_BASE' );
    }

    /**
     * This function loads a given library definition by an id and
     * definitions and source files of shapes as specified
     * by skip and limit attached to this
     * library.
     *
     * @param id - lib id to be loaded
     * @param skip - shaped to skip loading
     * @param limit - number of shapes needed to be loaded
     * @return - an observable that resolves the fetched libraryData
     */
    public load( id: string, skip: number, limit: number ): Observable<ShapeLibrary> {
        return this.getLibrary( id ).pipe(
            switchMap( lib => {
                if ( !lib.defs.length ) {
                    return of( lib );
                }

                return this.loadShapeDefs( lib, skip, limit ).pipe(
                    map( defs => {
                        lib.defs = defs;
                        return lib;
                    }),
                );
            }),
        );
    }

    /**
     * This method will load the shape definitions of the library.
     * @param lib - library definition
     * @param skip - number of shapes to skip on library def list
     * @param limit - number of shapes need to load
     * @returns Observable shape definition array
     */
    public loadShapeDefs( lib: ShapeLibrary, skip: number, limit: number ): Observable<Array<IAbstractDefinition>> {
        const shapeDefs = ( limit < 0 ) ? lib.defs.slice( skip, lib.defs.length ) : lib.defs.slice( skip, limit );
        return this.loadShapeDefinitions( shapeDefs );
    }

    /**
     * This method will load the shape definitions of the library.
     * @param lib - library definition
     * @param skip - number of shapes to skip on library def list
     * @param limit - number of shapes need to load
     * @returns Observable shape definition array
     */
    public loadShapeDefinitions( shapeDefs: string[]): Observable<Array<IAbstractDefinition>> {
        const observables = shapeDefs.map( defIdAndVer => this.loadDefinition( defIdAndVer ));
        return forkJoin( observables ).pipe(
            tap( defs => {
                defs.forEach( def => this.registerConnector( def ));
            }),
        );
    }

    /**
     * Loads and creates the library by given library id.
     */
     public getLibrary( id: string ): Observable<ShapeLibrary> {
        const location = this.getLibDefLocation( id );
        return forkJoin([ this.resourceLoader.load( location ), this.getLibraryHash() ]).pipe(
            switchMap(([ def, hashes ]) => {
                if ( hashes[def.libId] && md5( JSON.stringify( def )) !== hashes[def.libId]) {
                    return this.resourceLoader.forceLoad( location );
                }
                return of( def );
            }),
            switchMap( def => this.createLibrary( def )),
        );
    }

    protected getLibraryHash() {
        if ( !this.libraryHashes ) {
            this.libraryHashes = this.resourceLoader.getServerResource( this.libraryLocation + 'libs.json' ).pipe(
                map(( res: { libs: ILibHash[]}) => res.libs.reduce(( hashById, pair ) => {
                    hashById[pair.libId] = pair.hash;
                    return hashById;
                }, {})),
                tap( hashes => this.libraryHashes = of( hashes )),
            );
        }
        return this.libraryHashes;
    }

    /**
     * Returns the library definition location by library id.
     */
     protected getLibDefLocation( id: string ): string {
        return this.libraryLocation + id + '.json';
    }

    /**
     * Creates a ShapeLibrary instance using given raw data.
     */
     protected createLibrary( data: any ): Observable<ShapeLibrary> {
        return Deserializer.create( data ).structure( ShapeLibrary ).build();
    }

    /**
     * Loads the definition json file associated with the definition summary.
     */
     protected loadDefinition( defIdAndVer: string ): Observable<IAbstractDefinition> {
        const parts = defIdAndVer.split( '.' );
        const version = parseInt( parts.pop(), 10 );
        const defId = parts.join( '.' );
        return this.defLocator.getDefinition( defId, version );
    }

    /**
     * Registers the given (connector) definition with the connector registry.
     */
    private registerConnector( def: IAbstractDefinition ): void {
        if ( def.type === 'connector' ) {
            this.connectorRegistry.register( def as IConnectorDefinition );
        }
    }
}
